angular.module('qualita.components', []);
/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.authorization', ['ngResource', 'checklist-model'])
    .config(
        ['$stateProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$urlMatcherFactoryProvider',
            function($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider, $urlMatcherFactoryProvider) {

                $stateProvider
                    .state('app.usuarios', {
                        url: '/usuarios',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Usuarios'
                        }
                    })
                    .state('app.usuarios.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'UsuariosIndexCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios',
                            label: 'Usuarios',
                            description: 'Listado de Usuarios',
                            skip: true
                        }
                    })
                    .state('app.usuarios.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/usuarios/form.html',
                        controller: 'UsuariosFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios',
                            label: 'Nuevo Usuario',
                            description: 'Creación de un nuevo usuario'
                        },
                        resolve: {
                            usuarioPrepService: function() { return; }
                        }
                    })
                    .state('app.usuarios.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/usuarios/form.html',
                        controller: 'UsuariosFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios',
                            label: 'Editar Usuario',
                            description: 'Edición del usuario #'
                        },
                        resolve: {
                            usuarioPrepService: usuarioPrepService
                        }
                    })
                    .state('app.usuarios.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/usuarios/form.html',
                        controller: 'UsuariosFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios',
                            label: 'Ver Usuario',
                            description: 'Ver usuario #'
                        },
                        resolve: {
                            usuarioPrepService: usuarioPrepService
                        }
                    })
                    .state('app.usuarios.changepassword', {
                        url: '/:id/changepass/:admin/:username',
                        templateUrl: 'qualita-components/authorization/views/usuarios/changepassword.html',
                        controller: 'UsuariosChangePasswordCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios',
                            label: 'Cambiar contraseña del usuario',
                            description: 'Cambio de contraseña del usuario #'
                        }
                    })
                    .state('app.roles', {
                        url: '/roles',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Roles'
                        }
                    })
                    .state('app.roles.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'RolesIndexCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.roles',
                            label: 'Roles',
                            description: 'Listado de Roles',
                            skip: true
                        }
                    })
                    .state('app.roles.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/roles/form.html',
                        controller: 'RolesFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.roles',
                            label: 'Nuevo Rol',
                            description: 'Creación de un nuevo rol'
                        },
                        resolve: {
                            rolPrepService: function() { return; }
                        }
                    })
                    .state('app.roles.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/roles/form.html',
                        controller: 'RolesFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.roles',
                            label: 'Editar Rol',
                            description: 'Edición del Rol #'
                        },
                        resolve: {
                            rolPrepService: rolPrepService
                        }
                    })
                    .state('app.roles.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/roles/form.html',
                        controller: 'RolesFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.roles',
                            label: 'Ver Rol',
                            description: 'Ver Rol #'
                        },
                        resolve: {
                            rolPrepService: rolPrepService
                        }
                    })
                    .state('app.roles.clone', {
                        url: '/:id/clone',
                        templateUrl: 'qualita-components/authorization/views/roles/form.html',
                        controller: 'RolesFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.roles',
                            label: 'Duplicar Rol',
                            description: 'Clon del rol#'
                        },
                        resolve: {
                            rolPrepService: rolPrepService
                        }
                    })
                    .state('app.sucursales', {
                        url: '/sucursales',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Sucursales'
                        }
                    })
                    .state('app.sucursales.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'SucursalesIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.sucursales',
                            label: 'Sucursales',
                            description: 'Listado de Sucursales',
                            skip: true
                        }
                    })
                    .state('app.sucursales.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/sucursales/form.html',
                        controller: 'SucursalesFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.sucursales',
                            label: 'Nueva Sucursal',
                            description: 'Creación de una nueva sucursal'
                        },
                        resolve: {
                            sucursalPrepService: function() { return; }
                        }
                    })
                    .state('app.sucursales.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/sucursales/form.html',
                        controller: 'SucursalesFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.sucursales',
                            label: 'Editar Sucursal',
                            description: 'Edición de la Sucursal #'
                        },
                        resolve: {
                            sucursalPrepService: sucursalPrepService
                        }
                    })
                    .state('app.sucursales.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/sucursales/form.html',
                        controller: 'SucursalesFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.sucursales',
                            label: 'Ver Sucursal',
                            description: 'Ver Sucursal #'
                        },
                        resolve: {
                            sucursalPrepService: sucursalPrepService
                        }
                    })
                    .state('app.unidadesNegocio', {
                        url: '/unidadesnegocio',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Unidades de Negocio'
                        }
                    })
                    .state('app.unidadesNegocio.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'UnidadesNegocioIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.unidadesNegocio',
                            label: 'Unidades de Negocio',
                            description: 'Listado de Unidades de Negocio',
                            skip: true
                        }
                    })
                    .state('app.unidadesNegocio.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/unidades_negocio/form.html',
                        controller: 'UnidadesNegocioFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.unidadesNegocio',
                            label: 'Nueva Unidad de Negocio',
                            description: 'Creación de una nueva unidad de negocio'
                        },
                        resolve: {
                            unidadNegocioPrepService: function() { return; }
                        }
                    })
                    .state('app.unidadesNegocio.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/unidades_negocio/form.html',
                        controller: 'UnidadesNegocioFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.unidadesNegocio',
                            label: 'Editar Unidad de Negocio',
                            description: 'Edición de la Unidad de Negocio #'
                        },
                        resolve: {
                            unidadNegocioPrepService: unidadNegocioPrepService
                        }
                    })
                    .state('app.unidadesNegocio.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/unidades_negocio/form.html',
                        controller: 'UnidadesNegocioFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.unidadesNegocio',
                            label: 'Ver Unidad de Negocio',
                            description: 'Ver Unidad de Negocio #'
                        },
                        resolve: {
                            unidadNegocioPrepService: unidadNegocioPrepService
                        }
                    })
                    .state('app.accesosSistema', {
                        url: '/accesossistema',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Accesos al Sistema'
                        }
                    })
                    .state('app.accesosSistema.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'AccesosSistemaIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.accesosSistema',
                            label: 'Accesos al Sistema',
                            description: 'Listado de Accesos al Sistema',
                            skip: true
                        }
                    })
                    .state('app.accesosSistema.new', {
                        url: '/new?idUsuario',
                        templateUrl: 'qualita-components/authorization/views/accesos_sistema/form.html',
                        controller: 'AccesosSistemaFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios.list',
                            label: 'Nuevo Acceso al Sistema',
                            description: 'Creación de un nuevo acceso al sistema'
                        }
                        /*,
                                resolve: {
                                  accesoSistemaPrepService: function() { return; }
                                }*/
                    })
                    .state('app.accesosSistema.edit', {
                        url: '/:id/edit?idUsuario',
                        templateUrl: 'qualita-components/authorization/views/accesos_sistema/form.html',
                        controller: 'AccesosSistemaFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.usuarios.list',
                            label: 'Editar Información Personal',
                            description: 'Edición de la Información Personal#'
                        }
                        /*,
                                resolve: {
                                  accesoSistemaPrepService: accesoSistemaPrepService
                                }*/
                    })
                    .state('app.accesosSistema.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/accesos_sistema/form.html',
                        controller: 'AccesosSistemaFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.accesosSistema',
                            label: 'Ver Acceso al Sistema',
                            description: 'Ver Acceso al Sistema #'
                        },
                        resolve: {
                            accesoSistemaPrepService: accesoSistemaPrepService
                        }
                    })
                    .state('app.periodoadministrativo', {
                        url: '/periodoadministrativo',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Periodos Administrativos'
                        }
                    })
                    .state('app.periodoadministrativo.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'PeriodoAdministrativoIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.periodoadministrativo',
                            label: 'Periodos Administrativos',
                            description: 'Listado de Periodos Administrativos',
                            skip: true
                        }
                    })
                    .state('app.periodoadministrativo.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/periodoadministrativo/form.html',
                        controller: 'PeriodoAdministartivoFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.periodoadministrativo',
                            label: 'Nuevo Periodo Administrativo',
                            description: 'Creación de una nuevo Periodo Administrativo'
                        },
                        resolve: {
                            periodoAdministrativoPrepService: function() { return; }
                        }
                    })
                    .state('app.periodoadministrativo.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/periodoadministrativo/form.html',
                        controller: 'PeriodoAdministartivoFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.periodoadministrativo',
                            label: 'Editar Periodo Administrativo',
                            description: 'Edición del Periodo Administrativo #'
                        },
                        resolve: {
                            periodoAdministrativoPrepService: periodoAdministrativoPrepService
                        }
                    })
                    .state('app.periodoadministrativo.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/periodoadministrativo/form.html',
                        controller: 'PeriodoAdministartivoFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.periodoadministrativo',
                            label: 'Ver Periodo Administrativo',
                            description: 'Ver Periodo Administrativo #'
                        },
                        resolve: {
                            periodoAdministrativoPrepService: periodoAdministrativoPrepService
                        }
                    })
                    .state('app.timbradoempresa', {
                        url: '/timbradoempresa',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Timbrado de Empresa'
                        }
                    })
                    .state('app.timbradoempresa.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'TimbradoEmpresaIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.timbradoempresa',
                            label: 'Timbrado de Empresa',
                            description: 'Listado de Timbrados de Empresa',
                            skip: true
                        }
                    })
                    .state('app.timbradoempresa.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/timbradoempresa/form.html',
                        controller: 'TimbradoEmpresaFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.timbradoempresa',
                            label: 'Nuevo Timbrado de Empresa',
                            description: 'Creación de Timbrado de Empresa'
                        },
                        resolve: {
                            timbradoEmpresaPrepService: function() { return; }
                        }
                    })
                    .state('app.timbradoempresa.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/timbradoempresa/form.html',
                        controller: 'TimbradoEmpresaFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.timbradoempresa',
                            label: 'Editar Timbrado de Empresa',
                            description: 'Edición de Timbrado de Empresa #'
                        },
                        resolve: {
                            timbradoEmpresaPrepService: timbradoEmpresaPrepService
                        }
                    })
                    .state('app.timbradoempresa.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/timbradoempresa/form.html',
                        controller: 'TimbradoEmpresaFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.timbradoempresa',
                            label: 'Ver Timbrado de Empresa',
                            description: 'Ver Timbrado de Empresa #'
                        },
                        resolve: {
                            timbradoEmpresaPrepService: timbradoEmpresaPrepService
                        }
                    })
                    .state('app.numeroactual', {
                        url: '/numeroactual',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Numeración'
                        }
                    })
                    .state('app.numeroactual.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'NumeroActualIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.numeroactual',
                            label: 'Número de Recibo',
                            description: 'Número de Recibo',
                            skip: true
                        }
                    })
                    .state('app.numeroactual.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/numeroactual/form.html',
                        controller: 'NumeroActualFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.numeroactual',
                            label: 'Editar Número de Recibo',
                            description: 'Edición de Número de Recibo #'
                        },
                        resolve: {
                            numeroActualPrepService: numeroActualPrepService
                        }
                    })
                    .state('app.entidades', {
                        url: '/entidades',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Entidades'
                        }
                    })
                    .state('app.entidades.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'EntidadIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.entidades',
                            label: 'Entidades',
                            description: 'Listado de Entidades',
                            skip: true
                        }
                    })
                    .state('app.licencias', {
                        url: '/licencias',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Licencias'
                        }
                    })
                    .state('app.licencias.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'LicenciaListCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.licencias',
                            label: 'Licencias',
                            description: 'Listado de Licencias',
                            skip: true
                        }
                    })
                    .state('app.licencias.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/licencias/form.html',
                        controller: 'LicenciaFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.licencias',
                            label: 'Nueva configuracion de licencia',
                            description: 'Configuración de Licencia'
                        },
                        resolve: {
                            licenciaPrepService: function() { return; }
                        }
                    })
                    .state('app.licencias.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/licencias/form.html',
                        controller: 'LicenciaFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.licencias',
                            label: 'Editar Configuración de licencias',
                            description: 'Edición de la configuración #'
                        },
                        resolve: {
                            licenciaPrepService: licenciaPrepService
                        }
                    })
                    .state('app.licencias.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/licencias/form.html',
                        controller: 'LicenciaFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.licencias',
                            label: 'Ver Licencia',
                            description: 'Ver Licencia #'
                        },
                        resolve: {
                            licenciaPrepService: licenciaPrepService
                        }
                    })
                    .state('app.grupocentrocostos', {
                        url: '/grupocentrocostos',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Grupo de centro de costos'
                        }
                    })
                    .state('app.grupocentrocostos.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'GrupoCentroCostosListCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.grupocentrocostos',
                            label: 'Grupo de centro de costos',
                            description: 'Listado de Grupo de centros de costos',
                            skip: true
                        }
                    })
                    .state('app.grupocentrocostos.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/grupocentrocostos/form.html',
                        controller: 'GrupoCentroCostosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.grupocentrocostos',
                            label: 'Nuevo Grupo de Centro de Costos',
                            description: 'Grupo de Centro de Costos'
                        },
                        resolve: {
                            grupocentrocostosPrepService: function() { return; }
                        }
                    })
                    .state('app.grupocentrocostos.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/grupocentrocostos/form.html',
                        controller: 'GrupoCentroCostosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.grupocentrocostos',
                            label: 'Editar Grupo de Centro de Costos',
                            description: 'Edición de Grupo de Centro de Costos #'
                        },
                        resolve: {
                            grupocentrocostosPrepService: grupocentrocostosPrepService
                        }
                    })
                    .state('app.grupocentrocostos.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/grupocentrocostos/form.html',
                        controller: 'GrupoCentroCostosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.grupocentrocostos',
                            label: 'Ver Grupo de Centro de Costos',
                            description: 'Ver Grupo de Centro de Costos #'
                        },
                        resolve: {
                            grupocentrocostosPrepService: grupocentrocostosPrepService
                        }
                    })

                    .state('app.centrocostos', {
                        url: '/centrocostos',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Centro de costos'
                        }
                    })
                    .state('app.centrocostos.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'CentroCostosListCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.centrocostos',
                            label: 'Centro de costos',
                            description: 'Listado de Centros de costos',
                            skip: true
                        }
                    })
                    .state('app.centrocostos.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/authorization/views/centrocostos/form.html',
                        controller: 'CentroCostosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.centrocostos',
                            label: 'Nuevo Centro de Costos',
                            description: 'Centro de Costos'
                        },
                        resolve: {
                            centrocostosPrepService: function() { return; }
                        }
                    })
                    .state('app.centrocostos.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/authorization/views/centrocostos/form.html',
                        controller: 'CentroCostosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.centrocostos',
                            label: 'Editar Centro de Costos',
                            description: 'Edición de Centro de Costos #'
                        },
                        resolve: {
                            centrocostosPrepService: centrocostosPrepService
                        }
                    })
                    .state('app.centrocostos.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/authorization/views/centrocostos/form.html',
                        controller: 'CentroCostosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.centrocostos',
                            label: 'Ver Centro de Costos',
                            description: 'Ver Centro de Costos #'
                        },
                        resolve: {
                            centrocostosPrepService: centrocostosPrepService
                        }
                    })
                    .state("app.logoempresa", {
                        url: '/logoempresa',
                        templateUrl: "views/template_base/index.html",
                        abstract: true,
                        ncyBreadcrumb: {
                            label: "Logo de Empresa"
                        }
                    })
                    .state("app.logoempresa.list", {
                        url: '',
                        templateUrl: "qualita-components/authorization/views/logoempresa/form.html",
                        // templateUrl: 'views/directives/vistas-qualita.html',
                        controller: "LogoEmpresaFormCtrl",
                        controllerAs: "vm",
                        ncyBreadcrumb: {
                            label: "Logo de Empresa",
                            description: "Add Logo de Empresa"
                        }
                    })
                    .state('app.importaciondatos', {
                        url: '/importaciondatos',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                          label: 'Importación de Datos'
                        }
                      })
                      .state('app.importaciondatos.list', {
                        url: '',
                        templateUrl: 'qualita-components/authorization/views/importaciondatos/importacion.html',
                        controller: 'ImportacionDatosIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                          parent: 'app.importaciondatos',
                          label: 'Importación de Datos',
                          description: 'Importación de Datos desde Archivos',
                          skip: true
                        }
                      })
            }
        ])
    .animation('.repeated-item', function() {
        return {
            enter: function(element, done) {
                element.css('opacity', 0);
                jQuery(element).animate({
                    opacity: 1
                }, done);

                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            },
            leave: function(element, done) {
                element.css('opacity', 1);
                jQuery(element).animate({
                    opacity: 0
                }, done);

                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            },
            move: function(element, done) {
                element.css('opacity', 0);
                jQuery(element).animate({
                    opacity: 1
                }, done);

                return function(isCancelled) {
                    if (isCancelled) {
                        jQuery(element).stop();
                    }
                }
            },

        }
    });

function accesoSistemaPrepService(AccesosSistemaFactory, $stateParams) {
    return AccesosSistemaFactory.get($stateParams.id, 'UsuarioForm').$promise;
}
accesoSistemaPrepService.$inject = ["AccesosSistemaFactory", "$stateParams"];

function periodoAdministrativoPrepService(PeriodoAdministrativoFactory, $stateParams) {
    return PeriodoAdministrativoFactory.get($stateParams.id, "PeriodoAdministrativoForm").$promise;
}
periodoAdministrativoPrepService.$inject = ["PeriodoAdministrativoFactory", "$stateParams"];

function rolPrepService(RolesFactory, $stateParams) {
    return RolesFactory.get($stateParams.id, 'RolForm').$promise;
}
rolPrepService.$inject = ["RolesFactory", "$stateParams"];

function sucursalPrepService(SucursalesFactory, $stateParams) {
    return SucursalesFactory.get($stateParams.id, 'SucursalForm').$promise;
}
sucursalPrepService.$inject = ["SucursalesFactory", "$stateParams"];

function unidadNegocioPrepService(UnidadesNegocioFactory, $stateParams) {
    return UnidadesNegocioFactory.get($stateParams.id, "UnidadNegocioForm").$promise;
}
unidadNegocioPrepService.$inject = ["UnidadesNegocioFactory", "$stateParams"];

function usuarioPrepService(UsuariosFactory, $stateParams) {
    return UsuariosFactory.get($stateParams.id, "AccesoSistemaForm").$promise;
}
usuarioPrepService.$inject = ["UsuariosFactory", "$stateParams"];

function timbradoEmpresaPrepService(timbradoEmpresaFactory, $stateParams) {
    return timbradoEmpresaFactory.get($stateParams.id, "TimbradoEmpresaForm").$promise;
}
timbradoEmpresaPrepService.$inject = ["timbradoEmpresaFactory", "$stateParams"];

function numeroActualPrepService(NumeroActualFactory, $stateParams) {
    return NumeroActualFactory.get($stateParams.id, 'NumeroActualForm').$promise;
}
numeroActualPrepService.$inject = ["NumeroActualFactory", "$stateParams"];

function licenciaPrepService(LicenciaFactory,$stateParams){
    return LicenciaFactory.get($stateParams.id, 'LicenciaForm').$promise;
}
licenciaPrepService.$inject = ["LicenciaFactory", "$stateParams"];

function grupocentrocostosPrepService(GrupoCentroCostosFactory,$stateParams){
    return GrupoCentroCostosFactory.get($stateParams.id, 'GrupoCentroCostosForm').$promise;
}
grupocentrocostosPrepService.$inject = ["GrupoCentroCostosFactory", "$stateParams"];

function centrocostosPrepService(CentroCostosFactory,$stateParams){
    return CentroCostosFactory.get($stateParams.id, 'CentroCostosForm').$promise;
}
centrocostosPrepService.$inject = ["CentroCostosFactory", "$stateParams"];

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module("qualita.authorization").factory("AuthorizationLangFactory", [
  "$resource",
  "RolesFactory",
  "$q",
  "filterFactory",
  "baseurl",
  "$translatePartialLoader",
  "$translate",
  function(
    $resource,
    RolesFactory,
    $q,
    filterFactory,
    baseurl,
    $translatePartialLoader,
    $translate
  ) {
    var translationsNeeded = [
      "ADMINISTRACION",
      "ID",
      "NOMBRE",
      "ESTADO",
      "OPERACIONES_PERIODO",
      "FECHA_DESDE",
      "FECHA_HASTA",
      "NOMBRES",
      "APELLIDOS",
      "CUENTA",
      "DESCRIPCION",
      "PAIS",
      "CIUDAD",
      "DIRECCION",
      "TELEFONO",
      "NOMBRE_USUARIO",
      "NUEVO_ACCESO_SISTEMA",
      "FOTO",
      "EDITAR_ACCESO_SISTEMA",
      "VER_ACCESO_SISTEMA",
      "MSG_ERROR_PERSISTIR",
      "GUARDADO_LOCALMENTE",
      "ASEGURECE_TENGA_PERMISO",
      "FORMULARIO_INVALIDO",
      "NUEVO_ROL",
      "EDITAR_ROL",
      "VER_ROL",
      "EDIT_SUCURSAL",
      "NUEVA_SUCURSAL",
      "EDITAR_SUCURSAL",
      "VER_SUCURSAL",
      "NEW_UNIDAD_NEGOCIO",
      "EDIT_UNIDAD_NEGOCIO",
      "VER_UNIDAD_NEGOCIO",
      "PASSWORD_INCORRECTO",
      "NEW_USUARIO",
      "EDIT_USUARIO",
      "VER_USUARIO",
      "PASWORD_NO_COINCIDE",
      "USUARIO_MISMO_NOMBRE",
      "USUARIO",
      "SUCURSALES_UNIDADES",
      "POR_DEFECTO",
      "AGREGAR_SUCURSAL",
      "ATRAS",
      "UNIDAD_NEGOCIO",
      "SUCURSALES",
      "SUURSAL_DEFECTO",
      "OPERATION",
      "DATE_FROM",
      "DATE_TO",
      "PERMISOS",
      "UNIDADES_NEGOCIO",
      "OPERATION",
      "DATE_FROM",
      "DATE_TO",
      "PERMISOS",
      "ACTIVE",
      "ROLS",
      "MODULO",
      "FILTRAR_POR",
      "FORMULARIO",
      "PERMISO",
      "APLICAR_TODOS",
      "LIMPIAR_TODOS",
      "DESPLIEGUE_CATEGORIA",
      "UNIDADES_NEGOCIO",
      "CURRENT_PASSWORD",
      "NEW_PASSWORD",
      "REPEAT_PASSWORD",
      "PASSWORD",
      "PERSONALIZAR_PERMISOS",
      "USUARIO_CONTARA",
      "NUEVO_PERIODO_ADMINISTRATIVO",
      "EDITAR_PERIODO_ADMINISTRATIVO",
      "VER_PERIODO_ADMINISTRATIVO",
      "CORREO",
      "PERIODO_ADMINISTRATIVO_MODAL_ABRIR_TITULO",
      "PERIODO_ADMINISTRATIVO_MODAL_CERRAR_TITULO",
      "PERIODO_ADMINISTRATIVO_MODAL_ABRIR_MENSAJE",
      "PERIODO_ADMINISTRATIVO_MODAL_CERRAR_MENSAJE",
      "BRANCH_OFFICE",
      "STAMPING_NUMBER",
      "STAMPING_CLASS",
      "NUMBER_START",
      "NUMBER_END",
      "STAMPING_TITLE",
      "STAMPING_DELETE_ERROR",
      "NEW_STAMPING",
      "EDIT_STAMPING",
      "VIEW_STAMPING",
      "CHANGE_PASSWORD",
      "PERSONAL_INFO",
      "UNIDAD_NEGOCIO_NAME_WARNING",
      "SUCURSAL_NAME_WARNING",
      "CLONAR",
      "INVALID_DATES",
      "INVALID_NUMERO_INICIAL_FINAL",
      "DELETE_USUARIO",
      "DELETE_CONFIRMATION",
      "DELETE_WARNING",
      "DELETE",
      "VIEW",
      "EDIT",
      "OK",
      "CANCEL",
      "CODE",
      "PUNTO_EMISION",
      "PUNTOS_EMISION",
      "TIPO_COMPROBANTE_ASOCIADO",
      "ALL_PUNTOS_EMISION_NUMERO_INICIAL_MENOR_NUMERO_FINAL",
      "PERIODO_ADMINISTRATIVO_DELETE_CHECK",
      "NUMERO_ACTUAL",
      "ENTIDAD",
      "EDIT_NUMERO_ACTUAL",
      "NUMERO_RECIBO_ADMINISTRACION",
      "ERROR_NUMERO_TIMBRADO_REPETIDO",
      "ERROR_NUMERO_TIMBRADO_CLASE_REPETIDO",
      "PERMISOS_NO_ASIGNADO",
      "MSG_ERROR_LOCACION",
      "ERROR_USERNAME",
      "FAIL_DELETE_SUCURSAL",
      "NEW_LICENCIA",
      "EDIT_LICENCIA",
      "VER_LICENCIA",
      "NEW_GRUPO_CENTRO_COSTOS",
      "EDIT_GRUPO_CENTRO_COSTOS",
      "VER_GRUPO_CENTRO_COSTOS",
      "NEW_CENTRO_COSTOS",
      "EDIT_CENTRO_COSTOS",
      "VER_CENTRO_COSTOS",

      "REENVIAR_SUCURSAL_Q",
      "REENVIAR_SUCURSAL_MSG",
      "REENVIAR_SUCURSAL_FAILED"
    ];

    return {
      getTranslations: function() {
        $translatePartialLoader.addPart("administracion");
        return $translate.refresh().then(function() {
          return $translate(translationsNeeded);
        });
      }
    };
  }
]);

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.authorization')
    .factory('UsuariosFactory', ['$resource', 'RolesFactory', '$q', 'filterFactory', 'baseurl', '$translatePartialLoader', '$translate',
        function($resource, RolesFactory, $q, filterFactory, baseurl, $translatePartialLoader, $translate) {

            var Usuario = $resource(baseurl.getBaseUrl() + '/usuarios/:id', { id: '@id' }, {
                update: {
                    method: 'PUT'
                },

                changePassword: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + '/usuarios/:id/changepassword'
                },

                changeMyPassword: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + '/usuarios/:id/changemypassword'
                }
            });

            var borrarRolesPersonalizados = function(usuario) {
                var deferred = $q.defer();
                if (usuario.id) {
                    var personalizadoRol = "Personalizado_" + usuario.username;
                    var filter = {};
                    filter.search = filterFactory.and([{
                        path: 'nombre',
                        equals: personalizadoRol
                    }, {
                        path: 'personalizado',
                        equals: true
                    }]).value();

                    RolesFactory.all(filter).$promise.then(function(data) {
                        if (data.length > 0) {
                            roles = data;
                            // console.log("ya existe un rol personalizado: ");
                            // console.log(roles);
                            _.forEach(roles, function(e) {
                                // console.log("rol actual: " + e.nombre + " " + e.id);
                                e.usuarios = [];
                                var oldRole = RolesFactory.create(e);
                                RolesFactory.save(oldRole).then(function() {
                                    RolesFactory.remove(oldRole).then(function() {
                                        deferred.resolve(true);
                                    });
                                });
                            });
                        } else {
                            // no existían roles personalizados anteriormente
                            deferred.resolve(true);
                        }
                    }, function() { deferred.resolve(true) });
                } else {
                    deferred.resolve(false);
                }
                return deferred.promise;
            };

            return {
                all: function(params) {
                    return Usuario.query(params);
                },

                get: function(id, view) {
                    return Usuario.get({ id: id, view: view != undefined ? view : 'base' });
                },

                create: function(attrs) {
                    return new Usuario(attrs);
                },

                save: function(usuario) {
                    return (usuario.id) ? usuario.$update() : usuario.$save();
                },

                changePassword: function(usuario) {
                    return usuario.$changePassword();
                },

                changeMyPassword: function(usuario) {
                    return usuario.$changeMyPassword();
                },

                remove: function(usuario) {
                    return usuario.$remove();
                },

                validarPermisos: function(usuario) {
                    if (!usuario.personalizado) {
                        if (usuario.roles) {
                            return usuario.roles.length !== 0;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                },

                usuarioSetting: function(usuario, permisos) {
                    var deferred = $q.defer();
                    if (usuario.personalizado) {
                        borrarRolesPersonalizados(usuario).then(function() {
                            //console.log("usuario personalizado");
                            usuario.roles = undefined;
                            var rol = {
                                nombre: "Personalizado_" + usuario.username,
                                personalizado: true,
                                permisos: _.clone(permisos)
                            };
                            var rolObject = RolesFactory.create(rol);
                            RolesFactory.save(rolObject).then(function(data) {
                                deferred.resolve(data);
                            });
                        });
                    } else {
                        //console.log("sin permisos personalizados");
                        deferred.resolve(true);
                    }
                    return deferred.promise;
                }
            };

        }
    ]);

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.authorization')
  .factory('RolesFactory', ['$resource', 'baseurl', function($resource, baseurl) {
    var Rol = $resource( baseurl.getBaseUrl() + '/roles/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params) {
        return Rol.query(params);
      },

      get: function(id, view) {
        //return Rol.get({id: id});
        return Rol.get({id: id, view: view!=undefined ? view : 'base'});
      },

      create: function(attrs) {
        return new Rol(attrs);
      },

      save: function(rol) {
        return (rol.id) ? rol.$update() : rol.$save();
      },

      remove: function(rol) {
        return rol.$remove();
      }
    };
  }
  ]);

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.authorization')
  .factory('PermisosFactory', ['$resource', 'baseurl', function($resource, baseurl) {
    var Permiso = $resource( baseurl.getBaseUrl() + '/permisos/:id', {id: '@id'});

    var categorizePermiso = function(permiso) {
      //var nombre = permiso.nombre;
      //var nombreSeparado = nombre.split("_");

      //Se asume como token de entidad el substring formado por los caracteres después del último guión bajo
      return permiso.formulario;
    };

    var unCamelCaser = function(input) {
      var breaker = input.replace( /([A-Z])/g, " $1");
      return breaker.charAt(0).toUpperCase() + breaker.slice(1);
    };


    return {
      all: function(params, view) {
        if(params){
          params.view = view!=undefined ? view : 'base';
        }
        return Permiso.query(params);
      },

      get: function(id) {
        return Permiso.get({id: id});
      },

      agruparPermisos: function(permisos) {
        var modulos = [];
        for(var i=0; i<permisos.length; i++) {
          var perm = permisos[i];
          var entityName = categorizePermiso(perm);
          var modulo = _.find(modulos, function(elem) {
            return elem.nombre === perm.modulo;
          });
          if(!modulo) {
            modulo = {nombre: perm.modulo, entidades: []};
            modulos.push(modulo);
          }
          var entidad = _.find(modulo.entidades, function(elem) {
            return elem.nombre === entityName;
          });
          if(!entidad) {
            entidad = {nombre: entityName,
              permisos: []};
            entidad.friendlyName = unCamelCaser(entidad.nombre);
            entidad.modulo = modulo.nombre;
            modulo.entidades.push(entidad);
          }
          perm.entidad = entidad.nombre;
          entidad.permisos.push(perm);
        }
        /*_.forEach(modulos, function (modulo) {
          _.forEach(modulo.entidades, function (entidad) {
            entidad.permisos = _.sortBy(entidad.permisos, function (o) { return o.id; });
          })
        })*/
        return modulos;
      }
    };


  }
  ]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.authorization')
  .factory('SucursalesFactory', SucursalesFactory);

SucursalesFactory.$inject = ['$resource', 'baseurl', '$http'];

function SucursalesFactory($resource, baseurl, $http) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    reenviar: reenviar,
  };

  var Sucursal = $resource(baseurl.getBaseUrl() + "/sucursales/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view != undefined ? view : 'BaseList';
    }
    return Sucursal.query(params);
  }

  function get(id, view) {
    return Sucursal.get({ id: id, view: view != undefined ? view : 'BaseList' });
  }

  function create(attrs) {
    return new Sucursal(attrs);
  }

  function save(sucursal) {
    return (sucursal.id) ? sucursal.$update() : sucursal.$save();
  }

  function remove(sucursal) {
    return sucursal.$remove();
  }

  function reenviar(idSucursal) {
    return $http({
        url: baseurl.getBaseUrl() + '/sucursales/reenviar/' + idSucursal,
        method: "PUT"
    });
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.authorization')
.factory('UnidadesNegocioFactory', UnidadesNegocioFactory);

UnidadesNegocioFactory.$inject = ['$resource', 'baseurl'];

function UnidadesNegocioFactory($resource, baseurl) {
   var UnidadesNegocio = $resource( baseurl.getBaseUrl() + "/unidadesNegocio/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  var schema = {
      type: 'object',
      title: 'Unidad de Negocios',
      properties: {
        nombre: {
          title: 'Nombre de la unidad de negocio',
          type: 'string',
          maxLength: 255,
          validationMessage: {
            201: 'El valor es muy largo, máximo permitido 255 caracteres'
          }
        },
        descripcion: {
          title: 'Descripción',
          type: 'string',
          maxLength: 255,
          validationMessage: {
            201: 'El valor es muy largo, máximo permitido 255 caracteres'
          },
        },
        pais: {
          title: 'País',
          type: 'string',
          maxLength: 255,
          validationMessage: {
            201: 'El valor es muy largo, máximo permitido 255 caracteres'
          }
        },
        ciudad: {
          title: 'Ciudad',
          type: 'string',
          maxLength: 255,
          validationMessage: {
            201: 'El valor es muy largo, máximo permitido 255 caracteres'
          }
        }
      },
      required: ['nombre']
    };

  var formNew = [
    'nombre',
    'descripcion',
    'pais',
    'ciudad'
  ];

  var formEdit = angular.copy(formNew);
  var formView = angular.copy(formNew);

  var forms = {
    'new': formNew,
    'edit': formEdit,
    'view': formView
  }

  return {
      all: function(params, view) {
        if (params) {
          params.view = view!=undefined ? view : 'base';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'base';
        }
        return UnidadesNegocio.query(params);
      },

      get: function(id, view) {
        return UnidadesNegocio.get({id: id, view: view!=undefined ? view : 'UnidadNegocioForm'});
      },

      create: function(attrs) {
        return new UnidadesNegocio(attrs);
      },

      save: function(unidadNegocio) {
        return (unidadNegocio.id) ? unidadNegocio.$update() : unidadNegocio.$save();
      },

      remove: function(unidadNegocio) {
        return unidadNegocio.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return forms[type];
      }
    };

}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.authorization')
.factory('PeriodoAdministrativoFactory', PeriodoAdministrativoFactory);

PeriodoAdministrativoFactory.$inject = ['$resource', 'baseurl', '$log','TiposFactory','$http'];

function PeriodoAdministrativoFactory($resource, baseurl, $log,TiposFactory,$http) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    abrir: abrir,
    cerrar: cerrar
  };

  var PeriodoAdministrativo = $resource( baseurl.getBaseUrl() + "/periodoadministrativo/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    
    if (params) {
      params.view = view!=undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'BaseList';
    }
    return PeriodoAdministrativo.query(params);
  }

  function get(id, view) {
    return PeriodoAdministrativo.get({id: id, view: view!=undefined ? view : 'base'});
    //return PeriodoAdministrativo.get({id: id});
  }

  function create(attrs) {
    return new PeriodoAdministrativo(attrs);
  }

  function save(periodoAdministrativo) {
    return (periodoAdministrativo.id) ? periodoAdministrativo.$update() : periodoAdministrativo.$save();
  }

  function remove(periodoAdministrativo) {
    return periodoAdministrativo.$remove();
  }

  function form(type) {
    return type === 'new' ? formNew : formEdit;
  }

  function abrir(id) {
    console.log(baseurl.getBaseUrl() + '/abrir/'+id);
    return $http({
      url: baseurl.getBaseUrl() + '/periodoadministrativo/abrir/'+id,
      method: "PUT"
    });
  }

  function cerrar(id) {
    console.log(baseurl.getBaseUrl() + '/cerrar/'+id);
    return $http({
      url: baseurl.getBaseUrl() + '/periodoadministrativo/cerrar/'+id,
      method: "PUT"
    });
  }


}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.authorization')
.factory('LocacionesFactory', LocacionesFactory);

LocacionesFactory.$inject = ['$resource', '$rootScope', 'baseurl', 'filterFactory', '$q'];

function LocacionesFactory($resource, $rootScope, baseurl, filterFactory, $q) {
  var service = {
    all: all,
    get: get,
    getBySucursal: getBySucursal,
    getByUnidadNegocio: getByUnidadNegocio,
    create: create,
    save: save,
    remove: remove,
    getCurrentLocacion: getCurrentLocacion
  };

  var Locacion = $resource( baseurl.getBaseUrl() + "/locaciones/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params) {
    return Locacion.query(params);
  }

  function get(id) {
    return Locacion.get({id: id});
  }

  function getBySucursal(sucursalId) {
    var params = {};
    params.search = filterFactory.single({
      path: 'sucursal.id',
      equals: sucursalId
    }).value();
    return all(params);
  }

  function getByUnidadNegocio(unidadNegocioId) {
    var params = {};
    params.search = filterFactory.single({
      path: 'unidadNegocio.id',
      equals: unidadNegocioId
    }).value();
    params.view = "UsuarioForm"
    return all(params);
  }

  function create(attrs) {
    return new Locacion(attrs);
  }

  function save(locacion) {
    return (locacion.id) ? locacion.$update() : locacion.$save();
  }

  function remove(locacion) {
    return locacion.$remove();
  }

  function getCurrentLocacion() {
    return $q(function(resolve, reject) {
      $rootScope.$watch("AuthParams.locacionSeleccionada", function(newValue) {
        if(!newValue) {
          return;
        }
        resolve(newValue);
      });
    });



  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.authorization')
.factory('AccesosSistemaFactory', AccesosSistemaFactory);

AccesosSistemaFactory.$inject = ['$resource', 'baseurl', '$log','$http','$state','$stateParams','$rootScope'];

function AccesosSistemaFactory($resource, baseurl, $log, $http, $state, $stateParams,$rootScope) {
  var service = {
    all: all,
    get: get,
    create: create,
    cantidadAccesos: cantidadAccesos,
    save: save,
    remove: remove,
    allUsersWithoutAccess: allUsersWithoutAccess,
    downloadMenuJson: downloadMenuJson
  };

  var AccesoSistema = $resource( baseurl.getBaseUrl() + "/accesosSistema/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    },
    usersWithoutAccess: {
      method: 'GET',
      url: baseurl.getBaseUrl() + "/accesosSistema/userswithoutaccess",
      isArray: true
    },
    incrCantidadAccesos: {
      method: 'PUT',
      url: baseurl.getBaseUrl() + "/accesosSistema/incrCantidadAccesos/:id"
      //isArray: true
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'AccesoSistemaList';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'AccesoSistemaList';
    }

    return AccesoSistema.query(params);
  }

  function allUsersWithoutAccess() {
    return AccesoSistema.usersWithoutAccess();
  }

  function get(id, view) {
    return AccesoSistema.get({id: id, view: view!=undefined ? view : 'base'});
  }

  function create(attrs) {
    return new AccesoSistema(attrs);
  }

  function cantidadAccesos(id) {
    return  AccesoSistema.incrCantidadAccesos({id: id});
  }

  function save(accesoSistema) {
    return (accesoSistema.id) ? accesoSistema.$update() : accesoSistema.$save();
  }

  function remove(accesoSistema) {
    return accesoSistema.$remove();
  }

  function downloadMenuJson(ids) {
    $http({
      url: baseurl.getBaseUrl() + '/menujson/getmenujson/',
      method: "GET"
    }).then(function (response) {
      $rootScope.query.menu = '';
      $rootScope.mainMenu = response.data;

      // Verifica que el estado actual esté definido y no sea abstracto
      if ($state.current && $state.current.name) {
        // Transición de estado solo si el estado actual está definido correctamente
        $state.transitionTo($state.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true
        });
      }
    });
  }
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.LicenciaFactory
 * @description
 */
angular.module('qualita.authorization')
  .factory('LicenciaFactory', ['$resource', 'baseurl',
    function ($resource, baseurl) {

      var Licencia = $resource(baseurl.getBaseUrl() + '/licencias/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined ? view : 'base';
          } else {
            params = [];
            params.view = view != undefined ? view : 'base';
          }
          return Licencia.query(params);
        },

        get: function (id, view) {
          return Licencia.get({ id: id, view: view != undefined ? view : 'LicenciaForm'});
        },

        create: function (attrs) {
          return new Licencia(attrs);
        },

        save: function (licencia) {
          return (licencia.id) ? licencia.$update() : licencia.$save();
        },

        remove: function (licencia) {
          return licencia.$remove();
        },

        schema: function () {
          return schema;
        },

        form: function (type) {
          return type === 'new' ? formNew : formEdit;
        }
       
      };
    }
  ]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.GrupoCentroCostosFactory
 * @description
 */
angular.module('qualita.authorization')
  .factory('GrupoCentroCostosFactory', ['$resource', 'baseurl','$http',
    function ($resource, baseurl, $http) {

      var GrupoCentroCostos = $resource(baseurl.getBaseUrl() + '/grupocentrocostos/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined ? view : 'BaseList';
          } else {
            params = [];
            params.view = view != undefined ? view : 'BaseList';
          }
          return GrupoCentroCostos.query(params);
        },

        get: function (id, view) {
          return GrupoCentroCostos.get({ id: id, view: view != undefined ? view : 'GrupoCentroCostosForm'});
        },

        create: function (attrs) {
          return new GrupoCentroCostos(attrs);
        },

        save: function (grupocentrocostos) {
          return (grupocentrocostos.id) ? grupocentrocostos.$update() : grupocentrocostos.$save();
        },

        remove: function (grupocentrocostos) {
          return grupocentrocostos.$remove();
        },

        schema: function () {
          return schema;
        },

        form: function (type) {
          return type === 'new' ? formNew : formEdit;
        },

        reenviar: function (grupoCentroCostos) {
          return $http({
            url: baseurl.getBaseUrl() + '/grupocentrocostos/reenviar/' + grupoCentroCostos.id,
            method: "PUT"
          });
        }
       
      };
    }
  ]);
'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:GrupoCentroCostosCtrl
 * @description
 */
angular.module('qualita.authorization')
  .controller('GrupoCentroCostosFormCtrl', GrupoCentroCostosFormCtrl);

GrupoCentroCostosFormCtrl.$inject = ['$rootScope', '$scope', 'GrupoCentroCostosFactory','$state', 'grupocentrocostosPrepService','AuthorizationLangFactory',
  'notify', 'filterFactory',
  'WatchLocacionChangeFactory'
];

function GrupoCentroCostosFormCtrl($rootScope, $scope, GrupoCentroCostosFactory,$state, grupocentrocostosPrepService,AuthorizationLangFactory,
  notify, filterFactory,
  WatchLocacionChangeFactory) {

  var vm = this;

  activate();

  vm.submit = submit;
  vm.cancel = cancel;

  function activate() {

    $rootScope.isProcessing = false;
    
    var filtroActivo = {};
    filtroActivo.search = filterFactory.and([{
      path: 'activo',
      like: 'true'
    }]).value();

    AuthorizationLangFactory.getTranslations().then(function (translations) {
      vm.translations = translations;

      if ($state.is("app.grupocentrocostos.new")) {
        activateNew();
      } else if ($state.is("app.grupocentrocostos.edit")) {
        activateEdit();
      } else if ($state.is("app.grupocentrocostos.view")) {
        activateView();
      } else {
        activateView();
      }
    });
  }

  function activateNew() {
    vm.title = vm.translations.NEW_GRUPO_CENTRO_COSTOS;
    vm.grupocentrocostos = {};
  }

  function activateView() {
    vm.title = vm.translations.VER_GRUPO_CENTRO_COSTOS;
    vm.grupocentrocostos = grupocentrocostosPrepService;
    vm.entidadId = vm.grupocentrocostos.id;
    vm.entidad = "GrupoCentroCostos";
    vm.view = true;
  }

  function activateEdit() {
    vm.title = vm.translations.EDIT_GRUPO_CENTRO_COSTOS;
    vm.grupocentrocostos = grupocentrocostosPrepService;
    vm.entidadId = vm.grupocentrocostos.id;
    vm.entidad = "GrupoCentroCostos";
  }

  function submit() {
    vm.submited = true;

    if ($scope.GrupoCentroCostosForm.$valid) {
      submitGrupoCentroCostos().then(function (response) {
        $state.go('app.grupocentrocostos.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        errorHandler(error);
      });
    } 
  }

  function errorHandler(e) {
    var msg = "";
    _.forEach(e.data, function (error) {
      msg += "\n\n" + error.message + ".";
    });

    notify({ message: msg, classes: "alert-danger", position: "right" });
  }

  function submitGrupoCentroCostos() {
    var resource = GrupoCentroCostosFactory.create(vm.grupocentrocostos);
    return GrupoCentroCostosFactory.save(resource);
  }

  function cancel() {
    if ($state.is("app.grupocentrocostos.new")) {
      $state.go('app.grupocentrocostos.list');
    } else if ($state.is("app.grupocentrocostos.edit")) {
      $state.go('app.grupocentrocostos.list');
    } else if ($state.is("app.grupocentrocostos.view")) {
      $state.go('app.grupocentrocostos.list');
    } else {
      $state.go('app.grupocentrocostos.list');
    }

  }

  $scope.resource = 'grupocentrocostos';
}

'use strict';

angular.module('qualita.authorization')
  .controller('GrupoCentroCostosListCtrl',
      ["$scope", "filterFactory", "AuthorizationLangFactory", "GrupoCentroCostosFactory", "ReportTicketFactory", "$window", "$filter", "UtilFactory", "CsvFactory", "baseurl", "$modal", "ParametrosFactory", "notify", "$state", function($scope, filterFactory,AuthorizationLangFactory, GrupoCentroCostosFactory,
          ReportTicketFactory, $window, $filter,
          UtilFactory, CsvFactory, baseurl, $modal, ParametrosFactory, notify, $state) {

  var vm = this;

  var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
  var enviarAContabilidad = '';

  promise1.then(function(response) {
    enviarAContabilidad = response.valorTipo.codigo;
  });

  AuthorizationLangFactory.getTranslations().then(function (translations) {
    var defaultColumnOrder = ['id', 'codigo', 'descripcion','activo'];
    vm.options = {
      'resource': 'grupocentrocostos',
      'title': 'Grupo de Centro de Costos',
      'factory': GrupoCentroCostosFactory,
      'view': 'GrupoCentroCostosList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false },
        { 'data': 'codigo', 'title': $filter('translate')('CODE') },
        { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION')},
        { 'data': 'activo', 'title': $filter('translate')('ACTIVO')}
      ],
      'hasOptions': true,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'extraMenuOptions':
      [
        {
          'title': 'Reporte',
          'icon': 'glyphicon glyphicon-file',
          'showCondition': function() { return true; },
          'action': function() {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoGrupoCentroCostos', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
        },
        {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                CsvFactory.csv("grupocentrocostos", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          }
      ],
      'extraRowOptions': [
        {
          templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
          functionName: 'reenviar',
          customAttribute: ['enviadoAContabilidad', 'id'],
          functionDef: function (atributo) {

            var idGrupoCentroCosto = atributo[1];

            GrupoCentroCostosFactory.get(idGrupoCentroCosto, "GrupoCentroCostosList").$promise.then(function (response) {

              $scope.tituloModal = $filter('translate')('REENVIAR_GRUPO_CENTRO_COSTO');
              $scope.mensajeModal = $filter('translate')('REENVIAR_GRUPO_CENTRO_MSG');
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              $scope.ok = function (id) {
                ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                  if (parametro.valorTipo.codigo == 'si') {
                    GrupoCentroCostosFactory.reenviar(response).then(function (responseReenviar) {
                      console.log('responseReenviar');
                      console.log(responseReenviar);
                      if (responseReenviar.data.enviadoAContabilidad == false) {
                        notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                      }
                      $state.go("app.grupocentrocostos.list", { id: responseReenviar.id }, { reload: true });
                    }, function (error) {
                      var msg = "";
                      if (error.data && error.data.length > 0 && error.data[0].message) {
                        msg = error.data[0].message;
                      } else {
                        msg = $filter('translate')('REENVIAR_GRUPO_CENTRO_FAILED');
                      }
                      notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    });
                  } else {
                    var msg = "";
                    msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  }
                });
                modalInstance.close();
              }
            });
          },
          conditionName: 'canReenviar',
          conditionDef: function (atributo) {
            var enviadoAContabilidad = atributo[0];
            if (enviarAContabilidad == 'si') {
              if (enviadoAContabilidad === "No") {
                return true;
              }
            }
          }
        }
      ]
    };
  });
  
  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }

}]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.CentroCostosFactory
 * @description
 */
angular.module('qualita.authorization')
  .factory('CentroCostosFactory', ['$resource', 'baseurl','$http',
    function ($resource, baseurl, $http) {

      var CentroCostos = $resource(baseurl.getBaseUrl() + '/centrocostos/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined ? view : 'base';
          } else {
            params = [];
            params.view = view != undefined ? view : 'base';
          }
          return CentroCostos.query(params);
        },

        get: function (id, view) {
          return CentroCostos.get({ id: id, view: view != undefined ? view : 'CentroCostosForm'});
        },

        create: function (attrs) {
          return new CentroCostos(attrs);
        },

        save: function (centrocostos) {
          return (centrocostos.id) ? centrocostos.$update() : centrocostos.$save();
        },

        remove: function (centrocostos) {
          return centrocostos.$remove();
        },

        schema: function () {
          return schema;
        },

        form: function (type) {
          return type === 'new' ? formNew : formEdit;
        },

        reenviar: function (centroCostos) {
          return $http({
            url: baseurl.getBaseUrl() + '/centrocostos/reenviar/' + centroCostos.id,
            method: "PUT"
          });
        }
       
      };
    }
  ]);
'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:CentroCostosCtrl
 * @description
 */
angular.module('qualita.authorization')
  .controller('CentroCostosFormCtrl', CentroCostosFormCtrl);

CentroCostosFormCtrl.$inject = ['$rootScope', '$scope', 'CentroCostosFactory','GrupoCentroCostosFactory','$state', 'centrocostosPrepService','AuthorizationLangFactory',
  'notify', 'filterFactory',
  'WatchLocacionChangeFactory','formFactory'
];

function CentroCostosFormCtrl($rootScope, $scope, CentroCostosFactory,GrupoCentroCostosFactory,$state, centrocostosPrepService,AuthorizationLangFactory,
  notify, filterFactory,
  WatchLocacionChangeFactory,formFactory) {

  var vm = this;

  activate();

  vm.submit = submit;
  vm.cancel = cancel;

  function activate() {

    $rootScope.isProcessing = false;
    
    var filtroActivo = {};
    filtroActivo.search = filterFactory.and([{
      path: 'activo',
      like: 'true'
    }]).value();

    
    vm.grupocentrocostoslist = GrupoCentroCostosFactory.all(filtroActivo,"CentroCostosForm");

    AuthorizationLangFactory.getTranslations().then(function (translations) {
      vm.translations = translations;
      if ($state.is("app.centrocostos.new")) {
        activateNew();
      } else if ($state.is("app.centrocostos.edit")) {
        activateEdit();
      } else if ($state.is("app.centrocostos.view")) {
        activateView();
      } else {
        activateView();
      }
    });
  }

  function activateNew() {
    vm.title = vm.translations.NEW_CENTRO_COSTOS;
    vm.centrocostos = {};
    vm.centrocostos.activo=true;
  }

  function activateView() {
    vm.title = vm.translations.VER_CENTRO_COSTOS;
    vm.centrocostos = centrocostosPrepService;
    vm.entidadId = vm.centrocostos.id;
    vm.entidad = "CentroCostos";
    vm.view = true;
  }

  function activateEdit() {
    vm.title = vm.translations.EDIT_CENTRO_COSTOS;
    vm.centrocostos = centrocostosPrepService;
    vm.entidadId = vm.centrocostos.id;
    vm.entidad = "CentroCostos";
  }

  /* function submit() {
    vm.submited = true;

    if ($scope.CentroCostosForm.$valid) {
      submitCentroCostos().then(function (response) {
        $state.go('app.centrocostos.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        errorHandler(error);
      });
    } 
  } */

  function submit () {
    vm.submited = true;
    formFactory.defaultNSFSubmit($scope.CentroCostosForm, CentroCostosFactory, vm.centrocostos, errorHandler).then(function (response) {
      $state.go('^.list');
    }, function (error) {
      return errorHandler(error);
    });
  };

  function errorHandler(e) {
    var msg = "";
    _.forEach(e.data, function (error) {
      msg += "\n\n" + error.message + ".";
    });

    notify({ message: msg, classes: "alert-danger", position: "right" });
  }

  function submitCentroCostos() {
    var resource = CentroCostosFactory.create(vm.centrocostos);
    return CentroCostosFactory.save(resource);
  }

  function cancel() {
    if ($state.is("app.centrocostos.new")) {
      $state.go('app.centrocostos.list');
    } else if ($state.is("app.centrocostos.edit")) {
      $state.go('app.centrocostos.list');
    } else if ($state.is("app.centrocostos.view")) {
      $state.go('app.centrocostos.list');
    } else {
      $state.go('app.centrocostos.list');
    }

  }

  $scope.resource = 'centrocostos';
}

'use strict';

angular.module('qualita.authorization')
  .controller('CentroCostosListCtrl',
      ["$scope", "filterFactory", "AuthorizationLangFactory", "CentroCostosFactory", "ReportTicketFactory", "$window", "$filter", "UtilFactory", "CsvFactory", "baseurl", "$modal", "ParametrosFactory", "notify", "$state", function($scope, filterFactory,AuthorizationLangFactory,CentroCostosFactory,
          ReportTicketFactory, $window,$filter,
          UtilFactory, CsvFactory, baseurl, $modal, ParametrosFactory, notify, $state) {

  var vm = this;

  var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
  var enviarAContabilidad = '';

  promise1.then(function(response) {
    enviarAContabilidad = response.valorTipo.codigo;
  });
  
  AuthorizationLangFactory.getTranslations().then(function (translations) {
    var defaultColumnOrder = ['id', 'codigo', 'descripcion','grupoCentroCostos.descripcion','activo'];
    vm.options = {
      'resource': 'centrocostos',
      'title': 'Centro de Costos',
      'factory': CentroCostosFactory,
      'view': 'CentroCostosList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false },
        { 'data': 'codigo', 'title': $filter('translate')('CODE') },
        { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION')},
        { 'data': 'grupoCentroCostos.descripcion', 'title': $filter('translate')('GRUPO_CENTRO_COSTOS') },
        { 'data': 'activo', 'title': $filter('translate')('ACTIVO')}
      ],
      'hasOptions': true,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'extraMenuOptions':
      [
        {
          'title': 'Reporte',
          'icon': 'glyphicon glyphicon-file',
          'showCondition': function() { return true; },
          'action': function() {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoCentroCostos', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
        },
        {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                CsvFactory.csv("centrocostos", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          }
      ],
      'extraRowOptions': [
        {
          templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
          functionName: 'reenviar',
          customAttribute: ['enviadoAContabilidad', 'id'],
          functionDef: function (atributo) {

            var idCentroCosto = atributo[1];

            CentroCostosFactory.get(idCentroCosto, "CentroCostosList").$promise.then(function (response) {

              $scope.tituloModal = $filter('translate')('REENVIAR_CENTRO_COSTO');
              $scope.mensajeModal = $filter('translate')('REENVIAR_CENTRO_COSTO_MSG');
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              $scope.ok = function (id) {
                ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                  if (parametro.valorTipo.codigo == 'si') {
                    CentroCostosFactory.reenviar(response).then(function (responseReenviar) {
                      console.log('responseReenviar');
                      console.log(responseReenviar);
                      if (responseReenviar.data.enviadoAContabilidad == false) {
                        notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                      }
                      $state.go("app.centrocostos.list", { id: responseReenviar.id }, { reload: true });
                    }, function (error) {
                      var msg = "";
                      if (error.data && error.data.length > 0 && error.data[0].message) {
                        msg = error.data[0].message;
                      } else {
                        msg = $filter('translate')('REENVIAR_CENTRO_COSTO_FAILED');
                      }
                      notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    });
                  } else {
                    var msg = "";
                    msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  }
                });
                modalInstance.close();
              }
            });
          },
          conditionName: 'canReenviar',
          conditionDef: function (atributo) {
            var enviadoAContabilidad = atributo[0];
            if (enviarAContabilidad == 'si') {
              if (enviadoAContabilidad === "No") {
                return true;
              }
            }
          }
        }
      ]
    };
  });
  
  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }

}]);

'use strict';


angular.module('qualita.authorization')
  .controller('UsuariosIndexCtrl', ["AccesosSistemaFactory", "$scope", "$state", "UsuariosFactory", "filterFactory", "notify", "ReportTicketFactory", "$window", "AuthorizationLangFactory", "AuthorizationService", "$modal", "CsvFactory", "UtilFactory", "baseurl", "$filter", function (AccesosSistemaFactory, $scope, $state, UsuariosFactory, filterFactory, notify, ReportTicketFactory,
    $window, AuthorizationLangFactory, AuthorizationService, $modal, CsvFactory, UtilFactory, baseurl, $filter) {

      var booleanRender = function (data) {
        if(data == true){
          data = "Si";
        }else{
          data = "No";
        }
        return data;
    };
    var renderEmptyData = function (data) {
      if (data == undefined)
          return "";
      else
          return data;
  };

    var defaultColumnOrder = ['id','usuario.username', 'nombres','apellidos', 'correo', 'usuario.clase.descripcion','activo'];
AuthorizationLangFactory.getTranslations().then(function(translations) {
    $scope.options = {
      //'resource': 'usuarios',
      'resource': 'accesosSistema',
      'title': 'Usuarios',
      //'factory': UsuariosFactory,
      'factory': AccesosSistemaFactory,
      'view': 'AccesoSistemaList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        {'data': 'id', 'title': $filter('translate')('CODE'), visible: false},
        {'data': 'usuario.username', 'title': $filter('translate')('NOMBRE_USUARIO'),renderWith: renderEmptyData},
        {'data': 'nombres', 'title': $filter('translate')('NOMBRES')},
        {'data': 'apellidos', 'title': $filter('translate')('APELLIDOS')},
        {'data': 'correo', 'title': $filter('translate')('CORREO')},
        {'data': 'usuario.clase.descripcion', 'title': $filter('translate')('CLASE'),renderWith: renderEmptyData},
        {'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'}
      ],
      //'detailRows': []
      'hasOptions' : true,
      'defaultOrderColumn' : 0,
      'defaultOrderDir' : "desc",
      'failedDeleteError': 'No se pudo borrar el usuario. Verifique si no hay Accesos al Sistema asociados.',
      'hideAddMenu' : true,
      'hideEditMenu' : true,
      'hideViewMenu' : true,
      'hideRemoveMenu':true,
      'extraMenuOptions':
        [
          {
            'title': $filter('translate')('NEW_USUARIO'),
            'icon': 'glyphicon glyphicon-plus',
            'showCondition': function() {
              var permission = AuthorizationService.hasPermission('create_usuarios');
              return permission
            },
            'action': function() { $state.go('app.usuarios.new'); }
          },
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function() { return true; },
            'action': function() {
              var filters = createFilters($scope.options.getFilters());
              ReportTicketFactory.ticket('listadoAccesosSistema', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("accesosSistema", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          }
        ],
      'extraRowOptions': [
        {
          templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
          customAttribute : 'estado',
          conditionName:"canBorrar",
          conditionDef: function (estado) {
            return false;//AuthorizationService.hasPermission('delete_usuarios');
          },
          functionName: "borrar",
          functionDef: function(itemId) {
            $scope.selectedItemId = itemId;
            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');

            AccesosSistemaFactory.get(itemId, 'AccesoSistemaList').$promise.then(function (data) {
              console.log(data.nombres);
              $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
              $scope.usuarioEliminar = $filter('translate')('USUARIO')+": "+data.nombres+" "+data.apellidos;
            });


            $scope.modalInstanceBorrar1 = $modal.open({
              template: '<div class="modal-header">' +
              '<h3 class="modal-title">{{::tituloModal}}</h3>' +
              '</div>' +
              '<div class="modal-body">{{::mensajeModal}}'+
                '<hr width="1%">'+
                '{{::usuarioEliminar}}'+
              '</div>'+
              //'<div class="modal-body">{{::usuarioEliminar}}</div>'+
              '<div class="modal-footer">' +
              '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
              '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
              '</div>',
              scope: $scope
            });

            $scope.cancel = function() {
              $scope.modalInstanceBorrar1.dismiss('cancel');
            };

            $scope.ok = function(itemId) {
              var model = AccesosSistemaFactory.create({id: itemId});
              AccesosSistemaFactory.remove(model).then(function() {
                $scope.modalInstanceBorrar1.close(itemId);
                $state.go($state.current, {}, {reload: true});
              }, function(error) {
                $scope.modalInstanceBorrar1.dismiss('cancel');
                $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                $scope.mensajeModal = vm.options.failedDeleteError;
                var modalInstance = $modal.open({
                  template: '<div class="modal-header">' +
                  '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                  '</div>' +
                  '<div class="modal-body">{{::mensajeModal}}</div>' +
                  '<div class="modal-footer">' +
                  '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                  '</div>',
                  scope: $scope
                });
                $scope.cancel = function() {
                  modalInstance.dismiss('cancel');
                };
                console.log("error al borrar: ");
                console.log(error);
              });
            }
          }
        },
        {
          templateToRender: "<button class='btn btn-success' style='margin-right: 5px;'   title='" + $filter('translate')('EDIT') + "' ng-click='editarUsuario(<%=dataCustom%>)' ng-class='{ hidden : !canEditar()}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          customAttribute: 'usuario',
          conditionName: 'canEditar',
          conditionDef: function() {
            var permission = AuthorizationService.hasPermission('update_usuarios_admin');
            return permission;
          },
          functionName: "editarUsuario",
          functionDef: function(usuario) {
            console.log("USUARIO: ");
            console.log(usuario);
            $state.go('app.usuarios.edit', {id: usuario.id});
          }
        },
        {
          templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataCustom%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
          conditionName: 'canView',
          customAttribute : 'usuario',
          conditionDef: function() {
            return AuthorizationService.hasPermission('index_usuarios');
          },
          functionName: "view",
          functionDef: function(usuario) {
            $state.go('app.usuarios.view', {id: usuario.id});
          }
        },
        {
        templateToRender: "<button class='btn btn-warning' title='" + $filter('translate')('CHANGE_PASSWORD') + "' ng-click='changePassword(<%=dataCustom%>)' ng-class='{ hidden : !canEditar()}'> <span class='glyphicon glyphicon-lock'></span> </button>",
        customAttribute: 'usuario',
        conditionName: 'canEditar',
        conditionDef: function() {
          var permission = AuthorizationService.hasPermission('update_usuarios_admin');
            return permission;
        },
        functionName: "changePassword",
        functionDef: function(usuario) {
          $state.go('app.usuarios.changepassword', {id: usuario.id, admin: true, username: usuario.username});
        }
      },
      {
        templateToRender: " <button class='btn btn-success' title='Información Personal' ng-show='canEditarAS()' ng-click='editAcceso(<%=dataCustom%>)'> <span class='glyphicon glyphicon-wrench'></span> </button>",
        functionName: "editAcceso",
        customAttribute: 'usuario',
        functionDef: function(usuario) {
          $state.go('app.accesosSistema.edit', {idUsuario: usuario.id});
        },
        conditionName: 'canEditarAS',
        conditionDef: function() {
          return AuthorizationService.hasPermission('update_accesosSistema');
        },
      }],
    };
});
    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        if(search === 'No' && data === 'activo'){
          search = 'false';
        }
        if(search === 'Sí' && data === 'activo'){
          search = 'true';
        }
        filtersArr.push({path: data, like: search})
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }
  }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('UsuariosFormCtrl',
    ["$rootScope", "$scope", "UsuariosFactory", "RolesFactory", "PermisosFactory", "TiposFactory", "$location", "filterFactory", "notify", "AuthenticationService", "usuarioPrepService", "$state", "$localForage", "AuthorizationLangFactory", "$filter", function ($rootScope, $scope, UsuariosFactory, RolesFactory, PermisosFactory, TiposFactory,
      $location, filterFactory, notify, AuthenticationService,usuarioPrepService, $state, $localForage, AuthorizationLangFactory, $filter) {

      activate();

      function activate() {
        AuthorizationLangFactory.getTranslations().then(function (translations) {
          $scope.translations = translations;
          $rootScope.isProcessing = false;

          var user = AuthenticationService.getCurrentUser();

          TiposFactory.clasesUsuario(user.accesoSistema.usuario.id).then(function (data) {
            $scope.clases = data.data;
          });

          if ($state.is('app.usuarios.new')) {
            activateNew();
          } else if ($state.is('app.usuarios.edit')) {
            activateEdit();
          } else if ($state.is('app.usuarios.view')) {
            activateView();
          }
        });
      }

      function activateNew() {
        $scope.title = $filter('translate')('NEW_USUARIO');
        $scope.usuario = { activo: true, personalizado: false };
        $scope.usuario.roles = [];
        $scope.newUser = true;
        $scope.rePassword = "";
      }

      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_USUARIO');
        $scope.usuario = usuarioPrepService;
        $scope.entidadId = $scope.usuario.id;
        $scope.entidad = "Usuario";
        $scope.usuario.password = undefined;
        $scope.isEditing = true;
      }

      function activateView() {
        $scope.title = $filter('translate')('VER_USUARIO');
        $scope.usuario = usuarioPrepService;
        $scope.entidadId = $scope.usuario.id;
        $scope.entidad = "Usuario";
        $scope.usuario.password = undefined;
        $scope.view = true;
      }

      $scope.resource = 'usuarios';

      $rootScope.isProcessing = false;
      var filterRoles = {};

      filterRoles.search = filterFactory.single({
        path: 'personalizado',
        equals: false
      }).value();

      $scope.roles = RolesFactory.all(filterRoles);

      $scope.permAsignados = { permisos: [] };
      var staticFilter = {};
      staticFilter.search = filterFactory.and([{
        path: 'descripcion',
        sortAsc: true
      }]).value();
      $scope.permisos = PermisosFactory.all(staticFilter);

      $scope.rolesAsignados = {};

      var backupPermRoles = { perm: [] };

      $scope.$watch('usuario.personalizado', function () {
        if (!$scope.usuario) { return; }
        if (!$scope.usuario.personalizado) {
          _.forEach($scope.usuario.roles, function (object) {
            var personalizadoRol = "Personalizado_" + $scope.usuario.username;
            if (object.nombre === personalizadoRol && object.personalizado) {
              $scope.usuario.roles = [];
            }
          });
          $scope.permAsignados.permisos = _.clone(backupPermRoles.perm);
        }
      });

      $scope.$watch('usuario.roles', function (newValue, oldValue) {

        if (!$scope.usuario) return;
        if (oldValue !== undefined) {
          $scope.permAsignados.permisos = [];
        }

        _.forEach($scope.usuario.roles, function (object) {
          /* Se convierte el array de objetos en array de ids, esto es necesario para utilizarlo dentro del HTML  */
          if (typeof $scope.usuario.roles[0] === "object") {
            object.permisos = object.permisos.map(function (value) { return value.id ? value.id : value })
            $scope.permAsignados.permisos = _.union($scope.permAsignados.permisos, object.permisos);
            backupPermRoles.perm = _.clone($scope.permAsignados.permisos);
            if (object.personalizado) {
              _.remove($scope.usuario.roles, function (e) { return e === object.id });
            }
          } else {
            var array = [
              { permisos: object }
            ]
            $scope.permAsignados.permisos = _.union($scope.permAsignados.permisos, array);
            backupPermRoles.perm = _.clone($scope.permAsignados.permisos);
          }
        });

      });

      function convertirListaRoles() {
        var rolesNorm = [];
        if ($scope.usuario.roles) {
          $scope.rolesAsignados = $scope.usuario.roles;
          _.forEach($scope.usuario.roles, function (object) {
            rolesNorm.push(object.id);
          });
        }
        $scope.usuario.roles = rolesNorm;
      }

      $scope.cancel = function () {
        $location.path("/usuarios");
      };

      $scope.submit = function () {
        if ($state.is("app.usuarios.new")) {
          submitNew();
        } else if ($state.is("app.usuarios.edit")) {
          submitEdit();
        }
      }

      function submitNew() {
        $scope.submited = true;
        if ($scope.permAsignados.permisos.length != 0) {
          if ($scope.usuarioForm.$valid) {
            var tieneEspacio = false;
            _.forEach($scope.usuario.username, function (object) {
              if (object == " ") {
                tieneEspacio = true;
              }
            });

            if ($scope.rePassword !== $scope.usuario.password) {
              notify({ message: $filter('translate')('PASWORD_NO_COINCIDE'), classes: 'alert-danger', position: 'right' });
            } else if (tieneEspacio != false) {
              notify({ message: $filter('translate')('ERROR_USERNAME'), classes: 'alert-danger', position: 'right' });
            } else if (UsuariosFactory.validarPermisos($scope.usuario)) {
              $rootScope.isProcessing = true;
              //convertirListaRoles();
              UsuariosFactory.usuarioSetting($scope.usuario, $scope.permAsignados.permisos).then(function (data) {
                if ($scope.usuario.personalizado) {
                  $scope.usuario.roles = [data.id];
                }
                var usuario = UsuariosFactory.create($scope.usuario);
                UsuariosFactory.save(usuario).then(function (resp) {
                  $state.go('app.accesosSistema.new', { idUsuario: resp.id });
                  //$location.path("/usuarios");
                }, function (data) {
                  $rootScope.isProcessing = false;
                  $scope.usuario.roles = $scope.rolesAsignados;
                  errorHandler(data.data);
                  adjustRolesArray(true);
                    $localForage.getItem('usuarios').then(function (value) {
                      value = value || [];
                      value.unshift($scope.usuario);
                      if (!$scope.usuario.id) $localForage.setItem('usuarios', value);
                    });
                });
              });
            } else {
              var msg = $filter('translate')('ASEGURECE_TENGA_PERMISO');
              notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
          }
        } else {
          var msg = $filter('translate')('PERMISOS_NO_ASIGNADO');
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
        }
      }

      function submitEdit() {
        $scope.submited = true;
        if ($scope.permAsignados.permisos != null) {
          if ($scope.usuarioForm.$valid) {
            var tieneEspacio = false;
            _.forEach($scope.usuario.username, function (object) {
              if (object == " ") {
                tieneEspacio = true;
              }
            });

            if (tieneEspacio != false) {
              notify({ message: $filter('translate')('ERROR_USERNAME'), classes: 'alert-danger', position: 'right' });
            }

            else if (UsuariosFactory.validarPermisos($scope.usuario)) {
              $rootScope.isProcessing = true;
              //convertirListaRoles();
              UsuariosFactory.usuarioSetting($scope.usuario, $scope.permAsignados.permisos).then(function (data) {
                if ($scope.usuario.personalizado) {
                  $scope.usuario.roles = [data.id];
                }
                var usuario = UsuariosFactory.create($scope.usuario);
                UsuariosFactory.save(usuario).then(function (resp) {
                  $location.path("/usuarios");
                }, function (data) {
                  $rootScope.isProcessing = false;
                  $scope.usuario.roles = $scope.rolesAsignados;
                  if (data.data && data.data[0].constraint === "username") {
                    errorHandler(data.data);
                  } else {
                    var msg = $filter('translate')('MSG_ERROR_PERSISTIR');
                    if (!$scope.usuario.id) msg += $filter('translate')('GUARDADO_LOCALMENTE')
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    $localForage.getItem('usuarios').then(function (value) {
                      value = value || [];
                      value.unshift($scope.usuario);
                      if (!$scope.usuario.id) $localForage.setItem('usuarios', value);
                    });
                  }
                });
              });
            } else {
              var msg = $filter('translate')('ASEGURECE_TENGA_PERMISO');
              notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
          }
        } else {
          var msg = $filter('translate')('PERMISOS_NO_ASIGNADO');
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
        }
      }

      function errorHandler(e) {
        var msg = '';
        _.forEach(e, function (error) {
          if (error.message == "Not unique field: username") {
            msg += '\n\n' + $filter('translate')('USUARIO_MISMO_NOMBRE') + "\n"
          } else {
            msg += '\n\n' + error.message + '.'
          }
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      }
    }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('UsuariosChangePasswordCtrl', UsuariosChangePasswordCtrl);

UsuariosChangePasswordCtrl.$inject = ['$rootScope', '$scope', 'UsuariosFactory', '$q', '$log', '$location', '$stateParams', 'notify', 'AuthorizationLangFactory',
'$filter'];

function UsuariosChangePasswordCtrl($rootScope, $scope, UsuariosFactory, $q, $log, $location, $stateParams, notify, AuthorizationLangFactory, $filter) {
  var vm = this;
  var id = parseInt($stateParams.id);
  UsuariosFactory.get(id).$promise.then(function(response){
    vm.usuario = {
      id: response.id,
      username: response.username
    };
  });
  
  vm.cancel = cancel;

  vm.submit = submit;

  vm.submited = false;

  AuthorizationLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      activate();
    });

  function activate() {
    $rootScope.isProcessing = false;
    //console.log($stateParams);
    vm.title = $filter('translate')('CHANGE_PASSWORD');
    if($stateParams.admin === "true") {
      vm.dontAskForPrevious = true;
    }
  }

  function submit() {
    vm.submited = true;
    if($scope.usuarioForm.$valid && (vm.newPasswordRepeated === vm.usuario.newPassword)) {
      $rootScope.isProcessing = true;
      submitChange().then(function (response) {
        //$log.info("cambio realizado");
        $location.path('/usuarios');
      }, function(response) {
        $rootScope.isProcessing = false;
        if(response.data.errorcode === "wrong_pass") {
          notify({ message: $filter('translate')('PASSWORD_INCORRECTO'), classes: 'alert-danger', position: 'right'});
        }
      });
    }

    function submitChange() {
      var usuario = UsuariosFactory.create(vm.usuario);
      if($stateParams.admin === "true") {
        return UsuariosFactory.changePassword(usuario);
      }
      else {
        return UsuariosFactory.changeMyPassword(usuario);
      }
    }
  }

  function cancel() {
    $location.path("/usuarios");
  }

  $scope.resource = 'usuarios'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

angular.module('qualita.authorization')
    .controller('RolesIndexCtrl', ["$scope", "RolesFactory", "$state", "filterFactory", "notify", "ReportTicketFactory", "$window", "AuthorizationLangFactory", "CsvFactory", "baseurl", "UtilFactory", "$filter", "$localForage", "AuthorizationService", "$modal", "AccesosSistemaFactory", function($scope, RolesFactory, $state, filterFactory,
        notify, ReportTicketFactory, $window, AuthorizationLangFactory, CsvFactory, baseurl, UtilFactory, $filter, $localForage, AuthorizationService, $modal, AccesosSistemaFactory) {
            
            var defaultColumnOrder = ['id', 'nombre', 'descripcion'];
            AuthorizationLangFactory.getTranslations().then(function(translations) {
                $scope.options = {
                    'resource': 'roles',
                    'title': 'Roles',
                    'view': 'RolList',
                    'factory': RolesFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'columns': [
                        { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false},
                        { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
                        { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION') }
                    ],
                    'hasOptions' : true,
                    'defaultOrderColumn' : 0,
                    'defaultOrderDir' : "desc",
                    'hideRemoveMenu':true,
                    'extraMenuOptions': 
                    [
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function() { return true; },
                            'action': function() {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoRoles', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                    $window.open($scope.pdfDownloadURL, '_blank');
                                });
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function() { return true; },
                            'action': function() {
                                var filters = UtilFactory.createFilters($scope.options.getFilters(), $scope.options.staticFilter);
                                CsvFactory.csv("roles", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                .then(function(response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                            }
                        }
                    ],
                    'extraRowOptions': 
                    [
                        {
                            templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                            customAttribute : 'estado',
                            conditionName:"canBorrar",
                            conditionDef: function (estado) {
                                return AuthorizationService.hasPermission('delete_roles');
                            },
                            functionName: "borrar",
                            functionDef: function(itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                                
                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: '<div class="modal-header">' +
                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                    '</div>' +
                                    '<div class="modal-body">{{::mensajeModal}}'+
                                    '<hr width="1%">'+
                                    '{{::usuarioEliminar}}'+
                                    '</div>'+
                                    '<div class="modal-footer">' +
                                    '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                    '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                    '</div>',
                                    scope: $scope
                                });
                                
                                $scope.cancel = function() {
                                     $scope.modalInstanceBorrar1.dismiss('cancel');
                                };
                                
                                $scope.ok = function(itemId) {
                                    var model = RolesFactory.create({id: itemId});

                                    RolesFactory.remove(model).then(function() {
                                        $scope.modalInstanceBorrar1.close(itemId);
                                        $state.go($state.current, {}, {reload: true});
                                    }, function(error) {
                                        RolesFactory.get(itemId).$promise.then(function (data) {
                                            var username = "";
                                            
                                            _.forEach(data.usuarios, function(element) {
                                                username += element.username + " - ";
                                            });
                                            
                                        $scope.modalInstanceBorrar1.dismiss('cancel');
                                        $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                                        $scope.mensajeModal = 'No se puede borrar, existen usuarios que tienen asignado este rol. ';
                                        $scope.mensajeModal2 = 'Usuario/s: ' + username;
                                        var modalInstance = $modal.open({
                                            template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}<br>{{::mensajeModal2}}</br> </div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                            scope: $scope
                                        });
                                    
                                        $scope.cancel = function() {
                                            modalInstance.dismiss('cancel');
                                        };
                                        console.log("error al borrar: ");
                                        console.log(error);
                                    });});
                                }
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='" + $filter('translate')('CLONAR') + "' ng-show='canEdit()' ng-click='clonar(<%=dataId%>)'> <span class='glyphicon glyphicon-duplicate'></span> </button>",
                            functionName: "clonar",
                            functionDef: function(itemId) {
                            $state.go('app.roles.clone', { id: itemId });
                            }
                        }
                    ],
                };
            });

            var createFilters = function(filters) {
                var filtersArr = [];
                _.each(filters, function(search, data) {
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('RolesFormCtrl', ["$rootScope", "$scope", "RolesFactory", "PermisosFactory", "$location", "notify", "rolPrepService", "$state", "ModelTrimmer", "AuthorizationLangFactory", "filterFactory", "$filter", function ($rootScope, $scope, RolesFactory, PermisosFactory, $location, notify,
  rolPrepService, $state, ModelTrimmer, AuthorizationLangFactory, filterFactory, $filter) {

    activate();

    function activate() {
      AuthorizationLangFactory.getTranslations().then(function(translations) {
        $scope.translations=translations;
      if($state.is("app.roles.new")) {
        activateNew();
      } else if($state.is("app.roles.edit")) {
        activateEdit();
      } else if($state.is("app.roles.clone")) {
        activateClone();
      } else if($state.is("app.roles.view")) {
        activateView();
      }
      });
    }

    function activateNew() {
      $scope.title = $filter('translate')('NUEVO_ROL');
      $scope.rol = { permisos: [] };
    }

    function activateEdit() {
      $scope.title = $filter('translate')('EDITAR_ROL');
      $scope.rol = rolPrepService;
      $scope.rol.permisos = $scope.rol.permisos.map(function(value){return value.id ? value.id : value})
      $scope.entidadId = $scope.rol.id;
      $scope.entidad = "Rol";
    }

    function activateView() {
      $scope.title = $filter('translate')('VER_ROL');
      $scope.rol = rolPrepService;
      $scope.rol.permisos = $scope.rol.permisos.map(function(value){return value.id ? value.id : value})
      $scope.entidadId = $scope.rol.id;
      $scope.entidad = "Rol";
      $scope.view = true;
    }

    function activateClone() {
      $scope.title = $filter('translate')('NUEVO_ROL');
      $scope.rol = rolPrepService;
      $scope.rol.id = undefined;
      $scope.rol.nombre = undefined;
      $scope.rol.descripcion = undefined;
    }

    $scope.resource = 'roles';

    $rootScope.isProcessing = false;
    var staticFilter = {};
    staticFilter.search = filterFactory.and([{
      path: 'descripcion',
      sortAsc: true
    }]).value();

    staticFilter.view = "RolForm";

    $scope.permisos = PermisosFactory.all(staticFilter);

    $scope.permisos.$promise.then(function(data) {
      $scope.gruposPermisos = PermisosFactory.agruparPermisos(data);
    });

    $scope.cancel = function() {
      $location.path("/roles");
    };

    $scope.submit = function() {
      $scope.submited = true;
      if($scope.RolesForm.$valid) {
        if($scope.rol.permisos && $scope.rol.permisos.length > 0) {
          $rootScope.isProcessing = true;
          $scope.rol.personalizado = false;
          var trimmedRol = ModelTrimmer.trimDetails($scope.rol);
          delete trimmedRol.usuarios;
          var rol = RolesFactory.create(trimmedRol);
          RolesFactory.save(rol).then(function(resp) {
            $location.path('/roles');
          }, function(data){
            $rootScope.isProcessing = false;
             if(data.data[0].message=="Not unique field: nombre"){
                errorHandler(data);
              }else{
            var msg = $filter('translate')('MSG_ERROR_PERSISTIR');
            if(!$scope.rol.id) msg += $filter('translate')('GUARDADO_LOCALMENTE')
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            $localForage.getItem('roles').then(function(value) {
              value = value || [];
              value.unshift($scope.rol);
              if(!$scope.rol.id) $localForage.setItem('roles', value);
            });
          }
          });
        }
        else {
          var msg = $filter('translate')('ASEGURECE_TENGA_PERMISO');
          notify({ message: msg, classes: 'alert-danger', position: 'right'});
        }
      }
    };

    function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if(error.message=="Not unique field: nombre"){
        msg += '\n\n' + "Ya existe un rol con el mismo nombre.\n"
      }
      else{
        msg += '\n\n' + error.message + '.'
      }
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }
  }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('RolesEditCtrl', ["$rootScope", "$scope", "RolesFactory", "PermisosFactory", "$location", "$stateParams", "notify", "AuthorizationLangFactory", "$filter", function ($rootScope, $scope, RolesFactory, PermisosFactory, $location, $stateParams, notify, AuthorizationLangFactory,
    $filter) {

    AuthorizationLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
    });

    $scope.resource = 'roles';

    $rootScope.isProcessing = false;

    RolesFactory.get($stateParams.id).$promise.then(function(resp) {
      $scope.rol = resp;
      $scope.entidadId=$scope.rol.id;
      $scope.entidad="Rol";
    });
    var staticFilter = {};
    staticFilter.search = filterFactory.and([{
      path: 'descripcion',
      sortAsc: true
    }]).value();
    $scope.permisos = PermisosFactory.all(staticFilter);

    $scope.permisos.$promise.then(function(data) {
      $scope.gruposPermisos = PermisosFactory.agruparPermisos(data);
    });

    $scope.cancel = function() {
      $location.path("/roles");
    };

    $scope.submit = function() {
      if($scope.RolesForm.$valid) {
        if($scope.rol.permisos && $scope.rol.permisos.length > 0) {
          $rootScope.isProcessing = true;
          $scope.rol.personalizado = false;
          var rol = RolesFactory.create($scope.rol);
          RolesFactory.save(rol).then(function (resp) {
            $location.path('/roles');
          }, function () {
            $rootScope.isProcessing = false;
            var msg = $filter('translate')('MSG_ERROR_PERSISTIR');
            if (!$scope.rol.id) msg += $filter('translate')('GUARDADO_LOCALMENTE')
            notify({message: msg, classes: 'alert-danger', position: 'right'});
            $localForage.getItem('roles').then(function (value) {
              value = value || [];
              value.unshift($scope.rol);
              if (!$scope.model.id) $localForage.setItem('roles', value);
            });
          });
        }
        else {
          var msg = $filter('translate')('ASEGURECE_TENGA_PERMISO');
          notify({ message: msg, classes: 'alert-danger', position: 'right'});
        }
      }
    };
  }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('RolesCloneCtrl', ["$rootScope", "$scope", "RolesFactory", "PermisosFactory", "$location", "$stateParams", "notify", "UsuariosFactory", "$filter", function ($rootScope, $scope, RolesFactory, PermisosFactory, $location, $stateParams, notify,UsuariosFactory,
                                          $filter) {
    UsuariosFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
    });

    $scope.resource = 'roles';

    $rootScope.isProcessing = false;

    RolesFactory.get($stateParams.id).$promise.then(function(resp) {
      $scope.rol = resp;
      $scope.rol.id = undefined;
      $scope.rol.nombre = undefined;
      $scope.rol.descripcion = undefined;
    });
    var staticFilter = {};
    staticFilter.search = filterFactory.and([{
      path: 'descripcion',
      sortAsc: true
    }]).value();
    $scope.permisos = PermisosFactory.all(staticFilter);

    $scope.permisos.$promise.then(function(data) {
      $scope.gruposPermisos = PermisosFactory.agruparPermisos(data);
    });

    $scope.cancel = function() {
      $location.path("/roles");
    };

    $scope.submit = function() {
      if($scope.RolesForm.$valid) {
        if($scope.rol.permisos && $scope.rol.permisos.length > 0) {
          $rootScope.isProcessing = true;
          $scope.rol.personalizado = false;
          var rol = RolesFactory.create($scope.rol);
          RolesFactory.save(rol).then(function (resp) {
            $location.path('/roles');
          }, function () {
            $rootScope.isProcessing = false;
            var msg = $filter('translate')('MSG_ERROR_PERSISTIR');
            if (!$scope.rol.id) msg += $filter('translate')('GUARDADO_LOCALMENTE')
            notify({message: msg, classes: 'alert-danger', position: 'right'});
            $localForage.getItem('roles').then(function (value) {
              value = value || [];
              value.unshift($scope.rol);
              if (!$scope.model.id) $localForage.setItem('roles', value);
            });
          });
        }
        else {
          var msg = $filter('translate')('ASEGURECE_TENGA_PERMISO');
          notify({ message: msg, classes: 'alert-danger', position: 'right'});
        }
      } else {
        var msg = $filter('translate')('FORMULARIO_INVALIDO');
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
      }
    };
  }]);

'use strict';


angular.module('qualita.authorization')
  .controller('SucursalesIndexCtrl', SucursalesIndexCtrl);

SucursalesIndexCtrl.$inject = ["SucursalesFactory", "$log", "$scope", "notify",
"filterFactory", "ReportTicketFactory", "$window", "AuthorizationLangFactory", "CsvFactory", "UtilFactory", "baseurl",
"$filter", "ParametrosFactory", "$modal", "$state"];

function SucursalesIndexCtrl(SucursalesFactory, $log, $scope, notify,
filterFactory, ReportTicketFactory, $window, AuthorizationLangFactory,
CsvFactory, UtilFactory, baseurl, $filter, ParametrosFactory, $modal, $state) {
  var vm = this;

  var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
  var enviarAContabilidad = '';

  promise1.then(function(response) {
      enviarAContabilidad = response.valorTipo.codigo;
  });

  var defaultColumnOrder = ['id','nombre', 'descripcion', 'pais', 'ciudad', 'direccion', 'telefono'];

  AuthorizationLangFactory.getTranslations().then(function(translations) {
  vm.options = {
    'resource': 'sucursales',
    'title': 'Sucursales',
    'factory': SucursalesFactory,
    'view': 'SucursalList',
    'defaultColumnOrder': defaultColumnOrder,
    'failedDeleteError': translations.FAIL_DELETE_SUCURSAL,
    'columns': [
      {'data': 'id', 'title': $filter('translate')('CODE'), visible: false},
      {'data': 'nombre', 'title': $filter('translate')('NOMBRE')},
      {'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION'), visible: false},
      {'data': 'pais', title: $filter('translate')('PAIS')},
      {'data': 'ciudad', title: $filter('translate')('CIUDAD')},
      {'data': 'telefono', title: $filter('translate')('TELEFONO'), 'class':'dt-right'},
      {'data': 'direccion', title: $filter('translate')('DIRECCION')}

    ],
    'hasOptions' : true,
    'defaultOrderColumn' : 0,
    'defaultOrderDir' : "desc",
    'extraMenuOptions':
      [
        {
          'title': 'Reporte',
          'icon': 'glyphicon glyphicon-file',
          'showCondition': function() { return true; },
          'action': function() {
              var filters = createFilters(vm.options.getFilters());
              console.log(filters);
              console.log(vm.options.tableAjaxParams);
              console.log(vm.options.currentColumnOrder);
              ReportTicketFactory.ticket('listadoSucursales', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
        },
        {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                CsvFactory.csv("sucursales", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          }
    ],
    extraRowOptions:[
      {
        templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
        functionName: 'reenviar',
        customAttribute: ['id', 'enviadoAContabilidad'],
        functionDef: function(atributo) {

            $scope.tituloModal = $filter('translate')('REENVIAR_SUCURSAL_Q');
            $scope.mensajeModal = $filter('translate')('REENVIAR_SUCURSAL_MSG');
            var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
            });

            $scope.cancel = function() {
                modalInstance.dismiss('cancel');
            };

            $scope.ok = function() {
                var idAtributo = atributo[0];
                ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                    if (parametro.valorTipo.codigo == 'si') {
                        
                      console.log("AQUI ID: ", idAtributo);
                      SucursalesFactory.reenviar(idAtributo).then(function(response) {

                          if(response.enviadoAContabilidad == false){
                            notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                          }
                          $state.go("app.sucursales.list", { id: response.id }, { reload: true });

                        }, function(error) {
                            var msg = "";
                            if (error.data && error.data.length > 0 && error.data[0].message) {
                                msg = error.data[0].message;
                            } else {
                                msg = $filter('translate')('REENVIAR_SUCURSAL_FAILED');
                            }
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        });
                    }else{
                        var msg = "";
                        msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' }); 
                    }
                });
                modalInstance.close();
            }
        },
        conditionName: 'canReenviar',
        conditionDef: function(atributo) {
          var enviadoAContabilidad = atributo[1];

          if(enviarAContabilidad == 'si'){
            if(enviadoAContabilidad === "No"){
                return true;
            }
          }
        }
      }
    ]
  };
});
  var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({path: data, like: search})
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }

  $log.debug("SucursalesIndexCtrl");
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('SucursalesFormCtrl', SucursalesFormCtrl);

SucursalesFormCtrl.$inject = ['$rootScope', '$scope', 'SucursalesFactory', 'UnidadesNegocioFactory', '$log', '$location', '$state', 'sucursalPrepService',
'$q', '$stateParams','AuthorizationLangFactory', 'notify', '$filter'];

function SucursalesFormCtrl($rootScope, $scope, SucursalesFactory, UnidadesNegocioFactory, $log, $location, $state, sucursalPrepService,
  $q, $stateParams,AuthorizationLangFactory, notify, $filter) {
  var vm = this;

  //vm.chosenUnidadesNegocio = [];

  vm.cancel = cancel;

  vm.submit = submit;

  activate();

  function activate() {
    AuthorizationLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      if($state.is("app.sucursales.new")) {
        activateNew();
      } else if($state.is("app.sucursales.edit")) {
        activateEdit();
      } else if($state.is("app.sucursales.view")) {
        activateView();
      }

      $rootScope.isProcessing = false;
    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NUEVA_SUCURSAL');
    vm.sucursal = {};
    vm.unidadesNegocio = [];
    getUnidadesNegocio();
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDITAR_SUCURSAL');
    vm.sucursal = sucursalPrepService;
    $scope.entidadId=vm.sucursal.id;
    $scope.entidad="Sucursal";
    vm.chosenUnidadesNegocio = _.map(vm.sucursal.locaciones, function(locacion) {
      return locacion.unidadNegocio;
    });
    getUnidadesNegocio();
  }

  function activateView() {
    vm.title = $filter('translate')('VER_SUCURSAL');
    vm.sucursal = sucursalPrepService;
    $scope.entidadId=vm.sucursal.id;
    $scope.entidad="Sucursal";
    vm.chosenUnidadesNegocio = _.map(vm.sucursal.locaciones, function(locacion) {
      return locacion.unidadNegocio;
    });
    getUnidadesNegocio();
    vm.view = true;
  }

  function getUnidadesNegocio() {
    var filter = {};
    return UnidadesNegocioFactory.all(filter, "UnidadNegocioForm").$promise.then(function(response) {
      vm.unidadesNegocio = response;
      return vm.unidadesNegocio;
    })
  }

  function submit() {
    vm.submited = true;
    if($scope.SucursalesForm.$valid) {
      $rootScope.isProcessing = true;
      submitSucursal().then(function (sucursal) {
        $log.info("sucursal almacenada");
        $location.path('/sucursales');
      }, function(error) {
        $rootScope.isProcessing = false;
        errorHandler(error);
      });
    }

    function submitSucursal() {
      // control de unidades de negocio repetidas
      for (var i=0; i<vm.chosenUnidadesNegocio.length-1; i++){
          var unidad = vm.chosenUnidadesNegocio[i];
          for (var j=i+1; j<vm.chosenUnidadesNegocio.length; j++){
            var unidad2 = vm.chosenUnidadesNegocio[j];
            if (unidad.id === unidad2.id){
              var msg = $filter('translate')('UNIDADES_NEGOCIO_REPETIDAS');
              notify({
                message: msg,
                classes: 'alert-danger',
                position: 'right'
              });
              vm.submited = false;
              $rootScope.isProcessing = false;
              return;
            }
          }
      }

      if($state.is("app.sucursales.new")) {
        vm.sucursal.locaciones = _.map(vm.chosenUnidadesNegocio, function(unId) {
          return {
            unidadNegocio: unId
          }
        });
      } else if($state.is("app.sucursales.edit")) {
        console.log("Locaciones previas: ");
        console.log(vm.sucursal.locaciones);
        vm.sucursal.locaciones = _.map(vm.chosenUnidadesNegocio, function(unId) {
          var locacion = _.find(vm.sucursal.locaciones, {'unidadNegocio': unId});
          return locacion ? locacion : {
            unidadNegocio: unId,
            sucursal: vm.sucursal.id
          }
        });
      }
      console.log("Locaciones a enviar: ");
      console.log(vm.sucursal.locaciones);
      var sucursal = SucursalesFactory.create(vm.sucursal);
      return SucursalesFactory.save(sucursal);
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if (error.constraint == 'nombre') {
        msg += '\n\n' + $filter('translate')('SUCURSAL_NAME_WARNING') + "\n";
      } else {
        msg += '\n\n' + error.message + '.';
      }
    });
    notify({
      message: msg,
      classes: 'alert-danger',
      position: 'right'
    });
  }

  function cancel() {
    $log.info("cancelar sucursal");
    $location.path("/sucursales");
  }

  $scope.resource = 'sucursales'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('SucursalesEditCtrl', SucursalesEditCtrl);

SucursalesEditCtrl.$inject = ['$rootScope', '$scope', 'SucursalesFactory', '$stateParams', '$q', 'filterFactory', 'UnidadesNegocioFactory', 
'$log', '$location','AuthorizationLangFactory', '$filter'];

function SucursalesEditCtrl($rootScope, $scope, SucursalesFactory, $stateParams, $q, filterFactory, UnidadesNegocioFactory, 
  $log, $location, AuthorizationLangFactory, $filter) {
  var vm = this;

  //vm.chosenUnidadesNegocio = [];

  vm.sucursal = {};

  AuthorizationLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
      vm.title = $filter('translate')('EDIT_SUCURSAL');
    });

  vm.unidadesNegocio = [];

  vm.cancel = cancel;

  vm.submit = submit;

  activate();

  function activate() {
    $rootScope.isProcessing = false;

  }

  function cancel() {
    $location.path("/sucursales");
  }

  function getUnidadesNegocio() {
    return UnidadesNegocioFactory.all().$promise.then(function(response) {
      vm.unidadesNegocio = response;
      return vm.unidadesNegocio;
    });
  }

  function submit() {
    vm.submited = true;
    if($scope.SucursalesForm.$valid) {
      $rootScope.isProcessing = true;
      submitSucursal().then(function (sucursal) {
        //$log.info("sucursal actualizada");
        $location.path('/sucursales');
      });
    }

    function submitSucursal() {

      var sucursal = SucursalesFactory.create(vm.sucursal);
      return SucursalesFactory.save(sucursal);
    }
  }

  $scope.resource = 'sucursales';
}

'use strict';


angular.module('qualita.authorization')
  .controller('UnidadesNegocioIndexCtrl', UnidadesNegocioIndexCtrl);

UnidadesNegocioIndexCtrl.$inject = ["UnidadesNegocioFactory", "filterFactory", "notify", "ReportTicketFactory",
"$window", "$scope", "AuthorizationLangFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter"];

function UnidadesNegocioIndexCtrl(UnidadesNegocioFactory, filterFactory, notify, ReportTicketFactory,
  $window, $scope, AuthorizationLangFactory, CsvFactory, UtilFactory, baseurl, $filter) {
  var vm = this;

  var defaultColumnOrder = ['id','nombre', 'descripcion', 'pais', 'ciudad'];
AuthorizationLangFactory.getTranslations().then(function(translations) {
  vm.options = {
    'resource': 'unidadesNegocio',
    'title': 'Unidades de Negocio',
    'factory': UnidadesNegocioFactory,
    'view': 'UnidadNegocioList',
    'defaultColumnOrder': defaultColumnOrder,
    'columns': [
      {'data': 'id', 'title': $filter('translate')('CODE'), visible: false},
      {'data': 'nombre', 'title': $filter('translate')('NOMBRE')},
      {'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION'), visible: false},
      {'data': 'pais', title: $filter('translate')('PAIS')},
      {'data': 'ciudad', title: $filter('translate')('CIUDAD')}
    ],
    'hasOptions' : true,
    'defaultOrderColumn' : 0,
    'defaultOrderDir' : "desc",
    'extraMenuOptions':
      [
        {
          'title': 'Reporte',
          'icon': 'glyphicon glyphicon-file',
          'showCondition': function() { return true; },
          'action': function() {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoUnidadesNegocio', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
        },
        {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                CsvFactory.csv("unidadesNegocio", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          }
      ]
  };
});
  var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({path: data, like: search})
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('UnidadesNegocioFormCtrl', UnidadesNegocioFormCtrl);

UnidadesNegocioFormCtrl.$inject = ['$rootScope', '$scope', 'UnidadesNegocioFactory', 'AuthorizationLangFactory', 'formFactory', '$location',
  'unidadNegocioPrepService', '$state', 'notify', '$filter'];

function UnidadesNegocioFormCtrl($rootScope, $scope, UnidadesNegocioFactory, AuthorizationLangFactory, formFactory, $location,
                                 unidadNegocioPrepService, $state, notify, $filter) {

  //$scope.resource = 'unidadesnegocio';

  activate();

  function activate() {
    AuthorizationLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
      $scope.schema = UnidadesNegocioFactory.schema();
      $scope.options = formFactory.defaultOptions();
      if($state.is("app.unidadesNegocio.new")) {
        activateNew();
      } else if($state.is("app.unidadesNegocio.edit")) {
        activateEdit();
      } else if($state.is("app.unidadesNegocio.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
    });
  }

  function activateNew() {
    $scope.title = $filter('translate')('NEW_UNIDAD_NEGOCIO');
    $scope.form = UnidadesNegocioFactory.form('new');
    $scope.model = {};
    $scope.view = false;
    $scope.schema.readonly = false;
  }

  function activateEdit() {
    $scope.title = $filter('translate')('EDIT_UNIDAD_NEGOCIO');
    $scope.form = UnidadesNegocioFactory.form('edit');
    $scope.model = unidadNegocioPrepService;
    $scope.entidadId=$scope.model.id;
    $scope.entidad="UnidadNegocio";
    $scope.view = false;
    $scope.schema.readonly = false;
  }

  function activateView() {
    $scope.title = $filter('translate')('VER_UNIDAD_NEGOCIO');
    $scope.form = UnidadesNegocioFactory.form('view');
    $scope.model = unidadNegocioPrepService;
    $scope.entidadId=$scope.model.id;
    $scope.entidad="UnidadNegocio";
    $scope.options = formFactory.defaultViewOptions();
    $scope.view = true;
    $scope.schema.readonly = true;
  }

  $scope.submit = function (form) {
    formFactory.defaultSubmit('unidadesnegocio', $scope, form, UnidadesNegocioFactory, undefined, errorHandler);
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if (error.constraint == 'nombre') {
        msg += '\n\n' + $filter('translate')('UNIDAD_NEGOCIO_NAME_WARNING') + "\n";
      } else {
        msg += '\n\n' + error.message + '.';
      }
    });
    notify({
      message: msg,
      classes: 'alert-danger',
      position: 'right'
    });
  }

  $scope.cancel = function () {
    $location.path("/unidadesnegocio");
  }
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:LicenciasCtrl
 * @description
 */
angular.module('qualita.authorization')
  .controller('LicenciaFormCtrl', LicenciaFormCtrl);

LicenciaFormCtrl.$inject = ['$rootScope', '$scope', 'LicenciaFactory','$state', 'licenciaPrepService','AuthorizationLangFactory',
  'notify', 'filterFactory',
  'WatchLocacionChangeFactory',
  'ClienteFactory'
];

function LicenciaFormCtrl($rootScope, $scope, LicenciaFactory,$state, licenciaPrepService,AuthorizationLangFactory,
  notify, filterFactory,
  WatchLocacionChangeFactory, ClienteFactory) {

  var vm = this;

  activate();

  vm.submit = submit;
  vm.cancel = cancel;

  function activate() {

    $rootScope.isProcessing = false;
    
    var filtroActivo = {};
    filtroActivo.search = filterFactory.and([{
      path: 'activo',
      like: 'true'
    }]).value();

    //vm.clientes = ClienteFactory.all(filtroActivo,"LicenciaForm");

    AuthorizationLangFactory.getTranslations().then(function (translations) {
      vm.translations = translations;

      if ($state.is("app.licencias.new")) {
        activateNew();
      } else if ($state.is("app.licencias.edit")) {
        activateEdit();
      } else if ($state.is("app.licencias.view")) {
        activateView();
      } else {
        activateView();
      }
    });
  }

  /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
  vm.staticFilterClientes = [{path: "activo", equals: true}];
  vm.clientesFactory = ClienteFactory;
  
  function activateNew() {
    vm.title = vm.translations.NEW_LICENCIA;
    vm.licencia = {};
  }

  function activateView() {
    vm.title = vm.translations.VER_LICENCIA;
    vm.licencia = licenciaPrepService;
    vm.entidadId = vm.licencia.id;
    vm.entidad = "Licencia";
    vm.view = true;
  }

  function activateEdit() {
    vm.title = vm.translations.EDIT_LICENCIA;
    vm.licencia = licenciaPrepService;
    vm.entidadId = vm.licencia.id;
    vm.entidad = "Licencia";
  }

  function submit() {
    vm.submited = true;

    if ($scope.LicenciaForm.$valid) {
      submitLicencia().then(function (response) {
        $state.go('app.licencias.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        errorHandler(error);
      });
    } 
  }

  function errorHandler(e) {
    var msg = "";
    _.forEach(e.data, function (error) {
      msg += "\n\n" + error.message + ".";
    });

    notify({ message: msg, classes: "alert-danger", position: "right" });
  }

  function submitLicencia() {
    var resource = LicenciaFactory.create(vm.licencia);
    return LicenciaFactory.save(resource);
  }

  function cancel() {
    if ($state.is("app.licencias.new")) {
      $state.go('app.licencias.list');
    } else if ($state.is("app.licencias.edit")) {
      $state.go('app.licencias.list');
    } else if ($state.is("app.licencias.view")) {
      $state.go('app.licencias.list');
    } else {
      $state.go('app.licencias.list');
    }

  }

  $scope.resource = 'licencias';
}

'use strict';

angular.module('qualita.authorization')
  .controller('LicenciaListCtrl',
      ["$scope", "filterFactory", "AuthorizationLangFactory", "LicenciaFactory", "ReportTicketFactory", "$window", "$filter", "UtilFactory", "CsvFactory", "baseurl", function($scope, filterFactory,AuthorizationLangFactory,LicenciaFactory, ReportTicketFactory, $window, 
        $filter, UtilFactory, CsvFactory, baseurl ) {

  var vm = this;
  
  AuthorizationLangFactory.getTranslations().then(function (translations) {
    var defaultColumnOrder = ['id', 'cliente.nombre', 'cantidadTotal'];
    vm.options = {
      'resource': 'licencias',
      'title': 'Licencias',
      'factory': LicenciaFactory,
      'view': 'LicenciaList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false },
        { 'data': 'cliente.nombre', 'title': $filter('translate')('CLIENTE') },
        { 'data': 'cantidadTotal', 'title': $filter('translate')('CANTIDAD_LICENCIAS')}
      ],
      'hasOptions': true,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'extraMenuOptions':[
        {
          'title': 'Reporte',
          'icon': 'glyphicon glyphicon-file',
          'showCondition': function() { return false; },
          'action': function() {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoLicencias', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
        },
        {
          'title': 'CSV',
          'icon': 'glyphicon glyphicon-export',
          'showCondition': function() { return true; },
          'action': function() {
              var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

              CsvFactory.csv("licencias", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
              .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
              });
          }
        }
      ]
    };
  });
  
  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }

}]);

'use strict';


angular.module('qualita.authorization')
  .controller('PeriodoAdministrativoIndexCtrl', PeriodoAdministrativoIndexCtrl);

PeriodoAdministrativoIndexCtrl.$inject = ["PeriodoAdministrativoFactory", "AuthorizationLangFactory",
"AuthorizationService", "$state", "filterFactory", "notify", "ReportTicketFactory",
"$window", "$scope","$modal", "CsvFactory", "UtilFactory", "baseurl", "$filter"];

function PeriodoAdministrativoIndexCtrl(PeriodoAdministrativoFactory, AuthorizationLangFactory,
AuthorizationService, $state, filterFactory, notify, ReportTicketFactory,
$window, $scope,$modal, CsvFactory, UtilFactory, baseurl, $filter) {
  var vm = this;

  var defaultColumnOrder = ['id','nombre', 'estado.descripcion','fechaDesde','fechaHasta'/*,'operaciones.descripcion'*/];
  AuthorizationLangFactory.getTranslations().then(function(translations) {
  vm.options = {
    'resource': 'periodoadministrativo',
    'title': 'Periodos Administrativos',
    'factory': PeriodoAdministrativoFactory,
    'defaultColumnOrder': defaultColumnOrder,
    'view': 'PeriodoAdministrativoList',
    'columns': [
      {'data': 'id', 'title': $filter('translate')('CODE'), visible: false },
      {'data': 'nombre', 'title': $filter('translate')('NOMBRE')},
      {'data': 'estado.descripcion', 'title':  $filter('translate')('ESTADO'),'class' : 'dt-left','type' : 'combo', 'filterUrl': 'tipos/filtro/periodo_administrativo_estado'},
      //{'data': 'operaciones.descripcion', 'title':  $filter('translate')('OPERACIONES_PERIODO'),'class' : 'dt-center','type' : 'combo', 'filterUrl': 'tipos/filtro/periodo_administrativo_operaciones'},
      {'data': 'fechaDesde', 'title': $filter('translate')('FECHA_DESDE'), renderWith: "dateRender", dateFormat: "DD/MM/YYYY", 'class' : 'dt-center', 'type' : 'date-range'},
      {'data': 'fechaHasta', 'title': $filter('translate')('FECHA_HASTA'), renderWith: "dateRender", dateFormat: "DD/MM/YYYY", 'class' : 'dt-center', 'type' : 'date-range'}
    ],
    'hasOptions' : true,
    'defaultOrderColumn' : 0,
    'defaultOrderDir' : "desc",
    'hideEditMenu': true,
    'hideDeleteMenu': true,
    'hideRemoveMenu': true,
    'extraMenuOptions':
      [
        {
          'title': 'Reporte',
          'icon': 'glyphicon glyphicon-file',
          'showCondition': function() { return true; },
          'action': function() {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoPeriodoAdministrativo', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
        },
        {
          'title': 'CSV',
          'icon': 'glyphicon glyphicon-export',
          'showCondition': function() { return true; },
          'action': function() {
              var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

              CsvFactory.csv("periodoadministrativo", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
              .then(function(response) {
                console.log("response de csv: ", response);
                var fileName = response.data.fileName;
                $window.open(baseurl.getPublicBaseUrl() + fileName);
              });
            }
        }
      ],
    'extraRowOptions': [
    {
      templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                        $filter("translate")("DELETE") +
                        "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                    customAttribute: "estado",
                    conditionName: "canBorrar",
                    conditionDef: function(estado) {
                            return( AuthorizationService.hasPermission("delete_periodoadministrativo") &&
                            estado.codigo!="per_adm_abierto" && estado.codigo!="per_adm_cerrado");
                    },
                    functionName: "borrar",
                    functionDef: function(itemId) {
                        $scope.selectedItemId = itemId;
                        $scope.tituloModal = $filter("translate")("DELETE_CONFIRMATION");
                        $scope.mensajeModal = $filter("translate")("DELETE_WARNING");
                        var modalInstance = $modal.open({
                            templateUrl: "views/directives/confirmation-modal.html",
                            scope: $scope
                        });

                        $scope.cancel = function() {
                            modalInstance.dismiss("cancel");
                        };

                        $scope.ok = function(itemId) {
                            var model = PeriodoAdministrativoFactory.create({ id: itemId });
                            PeriodoAdministrativoFactory.remove(model).then(
                                function() {
                                    modalInstance.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                },
                                function(error) {
                                    modalInstance.dismiss("cancel");
                                    $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                    $scope.mensajeModal = $filter("translate")(
                                        "PERIODO_ADMINISTRATIVO_DELETE_CHECK"
                                    );

                                    var modalInstance2 = $modal.open({
                                        templateUrl: "views/directives/information-modal.html",
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance2.dismiss("cancel");
                                    };
                                }
                            );
                        };
                    }

    },

    {
       templateToRender: "<button class='btn btn-success' title='" + $filter('translate')('EDIT') + "' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
            functionName: "editar",
            functionDef: function(itemId) {
                $state.go("app.periodoadministrativo.edit", {id: itemId});
            },
            conditionName:"canEditar",
            customAttribute : 'estado',
            conditionDef: function (estado) {
                return (AuthorizationService.hasPermission("update_periodoadministrativo") &&
                  estado.codigo!="per_adm_abierto" && estado.codigo!="per_adm_cerrado"
                  );
        }
    },

    {
          templateToRender: "<button class='btn btn-success' title='" +  $filter('translate')('PERIODO_ADMINISTRATIVO_MODAL_ABRIR_TITULO') + "' style='margin-right: 5px;' ng-click='abrir(<%=dataId%>)' ng-class='{ hidden : !canAbrir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ok'></span> </button>",
          customAttribute : 'estado',
          conditionName:"canAbrir",
          conditionDef: function (estado) {
              return (AuthorizationService.hasPermission("update_periodoadministrativo") && (estado.codigo=="per_adm_creado"||estado.codigo=="per_adm_cerrado"));
          },
          functionName: "abrir",
          functionDef: function(itemId) {

            $scope.tituloModal =  $filter('translate')('PERIODO_ADMINISTRATIVO_MODAL_ABRIR_TITULO');
            $scope.mensajeModal =  $filter('translate')('PERIODO_ADMINISTRATIVO_MODAL_ABRIR_MENSAJE');
            var modalInstance = $modal.open({
              templateUrl: 'views/directives/confirmation-modal.html',
              scope: $scope
            });
            $scope.cancel = function() {
              modalInstance.dismiss('cancel');
            };
            $scope.ok = function(id) {
              PeriodoAdministrativoFactory.abrir(itemId).then(function(){
                $state.go($state.current, {}, {reload: true});
              }, function(err){
                  var msg = null;
                  if(err.data != undefined){
                    msg = err.data[0].message;
                  }else{
                    msg = "No se puede abrir el periodo, verifique conflictos con otros periodos";
                  }
                  notify({ message: msg, classes: 'alert-danger', position: 'right'});
                  modalInstance.dismiss('cancel');
                });
            };

          }
        },
        {
          templateToRender: "<button class='btn btn-danger' title='" + $filter('translate')('PERIODO_ADMINISTRATIVO_MODAL_CERRAR_TITULO') + "' style='margin-right: 5px;' ng-click='cerrar(<%=dataId%>)' ng-class='{ hidden : !canCerrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
          customAttribute : 'estado',
          conditionName:"canCerrar",
          conditionDef: function (estado) {
              // condicion cerrar
              return (AuthorizationService.hasPermission("update_periodoadministrativo") && estado.codigo=="per_adm_abierto");
          },
          functionName: "cerrar",
          functionDef: function(itemId) {


            $scope.tituloModal =  $filter('translate')('PERIODO_ADMINISTRATIVO_MODAL_CERRAR_TITULO');
            $scope.mensajeModal =  $filter('translate')('PERIODO_ADMINISTRATIVO_MODAL_CERRAR_MENSAJE');
            var modalInstance = $modal.open({
              templateUrl: 'views/directives/confirmation-modal.html',
              scope: $scope
            });
            $scope.cancel = function() {
              modalInstance.dismiss('cancel');
            };
            $scope.ok = function(id) {
              PeriodoAdministrativoFactory.cerrar(itemId).then(function(){
                $state.go($state.current, {}, {reload: true});
              });
            };

          }
        }
        ],
  };

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
        search = search.split("~");
        if (search.length > 1) {
            /* El parámetro es un rago */

            var isDate = search[0].split("/");

            if (isDate.length > 1) {
                /* Es un rago de fechas */

                filtersArr.push({path: data, equalOrAfter: search[0]})
                filtersArr.push({path: data, equalOrBefore: search[1]})
            } else {
                /* El un rago numérco */

                filtersArr.push({path: data, greaterOrEqual: search[0]})
                filtersArr.push({path: data, lessOrEqual: search[1]})
            }
        } else {
            filtersArr.push({path: data, like: search[0]})
        }
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
  });
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('PeriodoAdministartivoFormCtrl', PeriodoAdministartivoFormCtrl);

PeriodoAdministartivoFormCtrl.$inject = ['$rootScope', '$scope', 'PeriodoAdministrativoFactory', 'formFactory', '$location','TiposFactory',
'$state', 'periodoAdministrativoPrepService', 'notify', "AuthorizationLangFactory", "$filter"];

function PeriodoAdministartivoFormCtrl($rootScope, $scope, PeriodoAdministrativoFactory, formFactory, $location,TiposFactory,
                                       $state, periodoAdministrativoPrepService, notify, AuthorizationLangFactory, $filter) {
  var vm = this;

  vm.resource = 'periodoadministrativo';

  vm.options = formFactory.defaultOptions();

  vm.curDate = new Date();

  vm.submit = submit;

  vm.cancel = cancel;

  vm.translations=undefined;
  activate();

  function activate() {
    AuthorizationLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations
    if($state.is("app.periodoadministrativo.new")) {
      activateNew();
    } else if($state.is("app.periodoadministrativo.edit")) {
      activateEdit();
    } else if($state.is("app.periodoadministrativo.view")) {
      activateView();
    }

    $rootScope.isProcessing = false;
    vm.submited=false;
    TiposFactory.estadosPeriodoAdministrativo().then(function (data) {
              vm.estados=data.data;
            });
    TiposFactory.operacionesPeriodoAdministrativo().then(function (data) {
              vm.operaciones=data.data;
            });
    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NUEVO_PERIODO_ADMINISTRATIVO');
    vm.model = {};
    TiposFactory.estadosPeriodoAdministrativo().then(function (data) {
      vm.estados=data.data;
      for(var i = 0; i < vm.estados.length; i++) {
        if(vm.estados[i].codigo=="per_adm_creado"){
          vm.model.estado=vm.estados[i];
        }
      }

    });

  }

  function activateEdit() {
    vm.title = $filter('translate')('EDITAR_PERIODO_ADMINISTRATIVO');
    vm.model = periodoAdministrativoPrepService;
    vm.model.fechaDesde = new Date(vm.model.fechaDesde);
    vm.model.fechaHasta = new Date(vm.model.fechaHasta);
    $scope.entidadId=vm.model.id;
    $scope.entidad="PeriodoAdministrativo";
  }

  function activateView() {
    vm.title = $filter('translate')('VER_PERIODO_ADMINISTRATIVO');
    vm.model = periodoAdministrativoPrepService;
    vm.model.fechaDesde = new Date(vm.model.fechaDesde);
    vm.model.fechaHasta = new Date(vm.model.fechaHasta);
    $scope.entidadId = vm.model.id;
    $scope.entidad = "PeriodoAdministrativo";
    vm.view = true;
  }

  function submit(form) {
    vm.submited=true;
    $rootScope.isProcessing = true;

    if(vm.model.fechaDesde < vm.model.fechaHasta){

      if($scope.periodoAdministrativoForm.$valid) {
        vm.periodos = PeriodoAdministrativoFactory.all().$promise.then(function(periodos){

          vm.banSolapamiento = false;
          for (var i=0; i< periodos.length; i++) {
            var fechaDesde = new Date(periodos[i].fechaDesde);
            var fechaHasta = new Date(periodos[i].fechaHasta);

            if( vm.model.fechaDesde >= fechaDesde && vm.model.fechaDesde <= fechaHasta
              || (vm.model.fechaHasta >= fechaDesde && vm.model.fechaHasta <= fechaHasta)
              || (vm.model.fechaHasta >= fechaHasta && vm.model.fechaDesde <= fechaDesde) ){

                vm.banSolapamiento = true;
                notify({ message: $filter('translate')('SOLAPAMIENTO_FECHAS'), classes: 'alert-danger', position: 'right'});
                $rootScope.isProcessing = false;
                return;
            }
          }

          if(!vm.banSolapamiento){
            submitPeriodo().then(function(response) {
              $location.path('/periodoadministrativo');
            }, function(error) {
              $rootScope.isProcessing = false;
              var msg = "Error al guardar el Periodo";
              notify({ message: msg, classes: 'alert-danger', position: 'right'});
            });
          }
        });
      }else{
        $rootScope.isProcessing = false;
      }
    }else{
        var msg = "La fecha de inicio es mayor a la fecha limite";
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
        $rootScope.isProcessing = false;
    }
  }

  function submitPeriodo() {
    var resource = PeriodoAdministrativoFactory.create(vm.model);
    return PeriodoAdministrativoFactory.save(resource);
  }

  function cancel() {
    $location.path("/periodoadministrativo");
  }
}

'use strict';


angular.module('qualita.authorization')
  .controller('AccesosSistemaIndexCtrl', AccesosSistemaIndexCtrl);

AccesosSistemaIndexCtrl.$inject = ["AccesosSistemaFactory", "$log", "notify", "filterFactory", "ReportTicketFactory", "$window", "$scope", "AuthorizationLangFactory", "$filter"];

function AccesosSistemaIndexCtrl(AccesosSistemaFactory, $log, notify, filterFactory, ReportTicketFactory, $window, $scope, AuthorizationLangFactory, $filter) {
  var vm = this;

  var defaultColumnOrder = ['id', 'usuario.username', 'nombres','apellidos', 'correo'];
AuthorizationLangFactory.getTranslations().then(function(translations) {
  vm.options = {
    'resource': 'accesosSistema',
    'title': 'Accesos al Sistema',
    'factory': AccesosSistemaFactory,
    'defaultColumnOrder': defaultColumnOrder,
    'columns': [
      {'data': 'id', 'title': $filter('translate')('ID'), visible: false },
      {'data': 'usuario.username', 'title': $filter('translate')('CUENTA')},
      {'data': 'nombres', 'title': $filter('translate')('NOMBRES')},
      {'data': 'apellidos', 'title': $filter('translate')('APELLIDOS')},
      {'data': 'correo', 'title': $filter('translate')('CORREO')},
      //{'data': 'roles.nombre', 'title': 'Roles'}
    ],
    'hasOptions' : true,
    'defaultOrderColumn' : 0,
    'defaultOrderDir' : "desc",
    'extraMenuOptions':
      [
        {
          'title': 'Reporte',
          'icon': 'glyphicon glyphicon-file',
          'showCondition': function() { return true; },
          'action': function() {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoAccesosSistema', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
        }
      ]
  };
});
  var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({path: data, like: search})
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }

}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
  .controller('AccesosSistemaFormCtrl', AccesosSistemaFormCtrl);

AccesosSistemaFormCtrl.$inject = ['$rootScope', 'AccesosSistemaFactory', '$scope', '$log', '$location', 'AuthorizationLangFactory',
  '$state', 'baseurl', 'ModelTrimmer', '$stateParams', 'UsuariosFactory', 'filterFactory', '$filter', 'notify'];

function AccesosSistemaFormCtrl($rootScope, AccesosSistemaFactory, $scope, $log, $location, AuthorizationLangFactory, $state,
  baseurl, ModelTrimmer, $stateParams, UsuariosFactory, filterFactory, $filter, notify) {
  var vm = this;

  vm.idUsuario = $stateParams.idUsuario;

  vm.cancel = cancel;
  vm.submit = submit;

  activate();

  function activate() {
    vm.isEdit = false;
    AuthorizationLangFactory.getTranslations().then(function (translations) {
      vm.translations = translations;
      if ($state.is("app.accesosSistema.new")) {
        activateNew();
      } else if ($state.is("app.accesosSistema.edit")) {
        activateEdit();
      } else if ($state.is("app.accesosSistema.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      //getUsuarios();
    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NUEVO_ACCESO_SISTEMA');
    vm.accesoSistema = { activo: true, incluyeProducto: false, incluyeServicio: false, esImportacion: false };
    vm.uploadOptions = { imageOnly: true, title: $filter('translate')('FOTO') };
    vm.accesoSistema.usuario = UsuariosFactory.get(vm.idUsuario, 'detail');
    vm.accesoSistema.fechaCreacion = new Date();
    vm.isNew = true;
    //Capturamos cambios de estado para guardar datos default
    $scope.$on("$stateChangeStart", function(event, toState, toParams) {
      console.log("$stateChangeStart triggered");
      //if(vm.isNew && !vm.hasCanceled) {
       // saveDefaultAS();
      //}
    });

  }

  function saveDefaultAS() {
      if(vm.hasSaved) {
      return;
      }
       vm.accesoSistema = {activo: true,
          nombres: "Sin Nombre",
          apellidos: "Sin Nombre",
          usuario: vm.accesoSistema.usuario,
          accesos: vm.accesoSistema.accesos,
          fechaCreacion: new Date()
          };
          submitAccesoSistema().then(function(accesoSistema) {
            $rootScope.updateAccesoSistema();
            $state.go('app.usuarios.list');
          });
  }

  function activateEdit() {
    vm.isAsync = true;
    vm.accesosLoaded = false;
    vm.title = $filter('translate')('EDITAR_ACCESO_SISTEMA');
    var staticFilter = {};
    staticFilter.search = filterFactory.and([{
      path: 'usuario.id',
      equals: vm.idUsuario
    }]).value();

    AccesosSistemaFactory.all(staticFilter, "UsuarioForm").$promise.then(function (response) {
      if (response[0]) {
        vm.isEdit = true;
        AccesosSistemaFactory.get(response[0].id, 'UsuarioForm').$promise.then(function (data) {
          vm.accesoSistema = data;
          vm.accesosLoaded = true;
          var imagePath = "images/placeholder.png";
          if (vm.accesoSistema.foto && vm.accesoSistema.foto !== false)
          imagePath = baseurl.getPublicBaseUrl() + vm.accesoSistema.foto
          vm.uploadOptions = { imageOnly: true, title: $filter('translate')('FOTO'), imagePath: imagePath };
          $scope.entidadId = vm.accesoSistema.id;
          $scope.entidad = "AccesoSistema";
        });
      } else {
        vm.isEdit = false;
        vm.accesoSistema = { activo: true };
        vm.uploadOptions = { imageOnly: true, title: $filter('translate')('FOTO') };
        vm.accesoSistema.usuario = UsuariosFactory.get(vm.idUsuario, 'detail');
      }
    });
  }

  function activateView() {
    vm.isAsync = true;
    vm.title = $filter('translate')('VER_ACCESO_SISTEMA');
    vm.accesoSistema = accesoSistemaPrepService;
    var imagePath = "images/placeholder.png";
    if (vm.accesoSistema.foto && vm.accesoSistema.foto !== false)
      imagePath = baseurl.getPublicBaseUrl() + vm.accesoSistema.foto
    vm.uploadOptions = { imageOnly: true, title: $filter('translate')('FOTO'), imagePath: imagePath };
    $scope.entidadId = vm.accesoSistema.id;
    $scope.entidad = "AccesoSistema";
    vm.view = true;
  }

  function getUsuarios() {
    //console.log('getUsuarios');
    return AccesosSistemaFactory.allUsersWithoutAccess().$promise.then(function (response) {
      vm.usuarios = response;
      return vm.usuarios;
    });
    /*return UsuariosFactory.all().$promise.then(function(response) {
      vm.usuarios = response;
      return vm.usuarios;
    });*/
  }

  function setFoto(accesoSistema) {
    var flow = vm.uploadOptions.flow;
    if (!flow || !flow.files) {
      return;
    }
    var flowFiles = flow.files;
    if (flowFiles.length > 0) {
      angular.forEach(flowFiles, function (file) {
        accesoSistema.foto = file.uniqueIdentifier + '-' + file.name;
      });
    }
    //console.log('acceso', accesoSistema);
  }

  function submit() {
    if(vm.accesoSistema.accesos.length != 0) {
      vm.submited = true;
      if ($scope.AccesosSistemaForm.$valid) {
        $rootScope.isProcessing = true;
        setFoto(vm.accesoSistema);
        if(vm.accesoSistema.activo == true){
          vm.accesoSistema.usuario.activo = true;
        }else{
          vm.accesoSistema.usuario.activo = false;
        }

        var usuario = UsuariosFactory.create(vm.accesoSistema.usuario);
        UsuariosFactory.save(usuario).then(function (resp) {
          $location.path("/usuarios");
        }, function (data) {
          $rootScope.isProcessing = false;
          $scope.usuario.roles = $scope.rolesAsignados;
          if(data.data && data.data[0].constraint === "username"){
            errorHandler(data.data);
          } else {
            var msg = $filter('translate')('MSG_ERROR_PERSISTIR');
            if (!$scope.usuario.id) msg += $filter('translate')('GUARDADO_LOCALMENTE')
            notify({message: msg, classes: 'alert-danger', position: 'right'});
            $localForage.getItem('usuarios').then(function (value) {
              value = value || [];
              value.unshift($scope.usuario);
              if (!$scope.usuario.id) $localForage.setItem('usuarios', value);
            });
          }
        });

        submitAccesoSistema().then(function (accesoSistema) {
          $rootScope.updateAccesoSistema();
          vm.hasSaved = true;
          $state.go('app.usuarios.list');
        });
      }
    }else{
      var msg = $filter('translate')('MSG_ERROR_LOCACION');
      notify({ message: msg, classes: 'alert-danger', position: 'right'});
    }
  }

  function cancel() {
    if(vm.isNew) {
      vm.hasCanceled = true;
      UsuariosFactory.remove(vm.accesoSistema.usuario);
      $state.go('app.usuarios.list');
    } else {
      $state.go('app.usuarios.list');
    }
  }

  function submitAccesoSistema() {
      console.log('vm.accesoSistema.foto 3' + vm.accesoSistema.foto);
      var trimmedModel = ModelTrimmer.trimDetails(vm.accesoSistema, ['accesos']);
      var accesoSistema = AccesosSistemaFactory.create(trimmedModel);
      return AccesosSistemaFactory.save(accesoSistema);
    }

  $scope.resource = 'accesosSistema'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

/**
 * Created by codiumsa on 2/10/15.
 */
angular.module('qualita.authorization')
.directive('authPermisos', ['PermisosFactory', '$modal', '$filter', function(PermisosFactory, $modal, $filter) {
    return {
      restrict: 'E',
      scope: {
        permisos: '=',
        model: '=',
        readOnly: '=',
        cantAMostrar: '=?cantInicial',
        mostrarForms: '=?mostrarFormularios'
      },
      templateUrl: 'qualita-components/authorization/views/directives/permisos.html',
      link: function(scope, el, attr) {
        scope.cantAMostrar = angular.isDefined(scope.cantAMostrar) ? scope.cantAMostar : 0;
        scope.mostrarForms = angular.isDefined(scope.mostrarForms) ? scope.mostrarForms : true;
        scope.showFiltros = false;
        scope.formsLimiter = scope.mostrarForms ? Infinity : 0;

        var permisosChunker = function(modulos) {
          _.forEach(modulos, function(modulo) {
            modulo.entidades = _.sortBy(modulo.entidades, function(entidad) { return - entidad.permisos.length; });
            modulo.entidadesFilas = _.chunk(modulo.entidades, 2);
          });
          modulos = _.sortBy(modulos, function(modulo) { return -modulo.entidades.length; })
          filas = _.chunk(modulos, 2);
          return filas;
        };

        scope.toggleAllPermisos = function(entidad) {
            entidad.cantMostrada = entidad.cantMostrada === scope.cantAMostrar ? Infinity : scope.cantAMostrar;
        };

        scope.toggleForms = function(modulo) {
          modulo.mostrarForms = modulo.mostrarForms ? false : true;
          modulo.formsLimiter = modulo.mostrarForms ? Infinity : 0;
        };

        scope.permisos.$promise.then(function(data) {
          scope.modulos = PermisosFactory.agruparPermisos(data);
          //le agregamos a cada grupo el atributo de cantMostrada
          _.forEach(scope.modulos, function(modulo) {
            modulo.entidades = _.map(modulo.entidades, function(elem) {
              return _.extend({}, elem, {cantMostrada: scope.cantAMostrar});
            });
          });
          _.forEach(scope.modulos, function(elem) {
            elem.mostrarForms = scope.mostrarForms;
            elem.formsLimiter = scope.formsLimiter;
          });
          scope.gruposPermisos = permisosChunker(scope.modulos);
        });

        var marcarManyGrupo = function(grupo) {
          var idsPermGrupo = [];
          _.forEach(grupo.entidades, function (entidad) {
            idsPermGrupo = _.union(idsPermGrupo, _.pluck(entidad.permisos, "id"));
          });
          scope.model.permisos = _.union(scope.model.permisos, idsPermGrupo);
        };

        var marcarManyEntidad = function(entidad) {
          var idsPermEntidad = _.pluck(entidad.permisos, "id");
          scope.model.permisos = _.union(scope.model.permisos, idsPermEntidad);
        };

        scope.marcarMany = function(grupo, $event) {
          if($event) $event.stopPropagation();
          if(grupo) {
            //HACK: Vemos si se trata efectivamente de un grupo o una entidad, ya que esta función admite ambos como
            //entrada. Hacemos la distinción viendo si el objeto entrada tiene la propiedad entidades o permisos.
            if(grupo.entidades) {
              scope.tituloModal = $filter('translate')('ADD_ALL_PERMISOS_Q');
              scope.mensajeModal = $filter('translate')('MARCARAN_ALL_PERMISOS_W');
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: scope
              });

              scope.cancel = function() {
                modalInstance.dismiss('cancel');
              };

              scope.ok = function(itemId) {
                marcarManyGrupo(grupo);
                modalInstance.close();
              };
            }
            else if(grupo.permisos){
              marcarManyEntidad(grupo);
            }
          } else {
            scope.selectedItemId = grupo;
            scope.tituloModal = $filter('translate')('ADD_ALL_PERMISOS_Q');
            scope.mensajeModal = $filter('translate')('MARCARAN_ALL_PERMISOS');
            var modalInstance = $modal.open({
              templateUrl: 'views/directives/confirmation-modal.html',
              scope: scope
            });

            scope.cancel = function() {
              modalInstance.dismiss('cancel');
            };

            scope.ok = function(itemId) {
              scope.marcarManyCallback();
              modalInstance.close();
            }
          }
        };

        scope.marcarManyCallback = function() {
          var idsPerm = _.pluck(scope.permisos, "id");
          scope.model.permisos = idsPerm;
        };

        var desmarcarManyGrupo = function(grupo) {
          var idsPermGrupo = [];
          _.forEach(grupo.entidades, function (entidad) {
            idsPermGrupo = _.union(idsPermGrupo, _.pluck(entidad.permisos, "id"));
          });
          scope.model.permisos = _.difference(scope.model.permisos, idsPermGrupo);
        };

        var desmarcarManyEntidad = function(entidad) {
          var idsPermEntidad = _.pluck(entidad.permisos, "id");
          scope.model.permisos = _.difference(scope.model.permisos, idsPermEntidad);
        };

        scope.desmarcarMany = function(grupo, $event) {
          if($event) $event.stopPropagation();
          if(grupo) {
            //HACK: Vemos si se trata efectivamente de un grupo o una entidad, ya que esta función admite ambos como
            //entrada. Hacemos la distinción viendo si el objeto entrada tiene la propiedad entidades o permisos.
            if(grupo.entidades) {
              scope.tituloModal = $filter('translate')('LIMPIAR_SELECTED_PERMISOS_Q');
              scope.mensajeModal = $filter('translate')('DESASIGNARAN_ALL_PERMISOS');
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: scope
              });

              scope.cancel = function() {
                modalInstance.dismiss('cancel');
              };

              scope.ok = function(itemId) {
                desmarcarManyGrupo(grupo);
                modalInstance.close();
              };
            }
            else if(grupo.permisos){
              desmarcarManyEntidad(grupo);
            }
          }
          else {
            scope.selectedItemId = grupo;
            scope.tituloModal = $filter('translate')('LIMPIAR_SELECTED_PERMISOS_Q');
            scope.mensajeModal = $filter('translate')('DESASIGNARAN_ALL_PERMISOS');
            var modalInstance = $modal.open({
              templateUrl: 'views/directives/confirmation-modal.html',
              scope: scope
            });

            scope.cancel = function() {
              modalInstance.dismiss('cancel');
            };

            scope.ok = function(itemId) {
              scope.desmarcarManyCallback();
              modalInstance.close();
            }
          }
        };

        scope.desmarcarManyCallback = function() {
          scope.model.permisos = null;
        };

        scope.toggleFiltros = function() {
          scope.showFiltros = !scope.showFiltros;
        }

        scope.filtroModulo = true;
        scope.filtroPermiso = true;
        scope.filtroFormulario = true;

      }
    }
  }]);

angular.module('qualita.authorization')
  .directive('countrySelect', ['$parse', function ($parse) {
    var countries = [
      "Afghanistan", "Aland Islands", "Albania", "Algeria", "American Samoa", "Andorra", "Angola",
      "Anguilla", "Antarctica", "Antigua And Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria",
      "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin",
      "Bermuda", "Bhutan", "Bolivia, Plurinational State of", "Bonaire, Sint Eustatius and Saba", "Bosnia and Herzegovina",
      "Botswana", "Bouvet Island", "Brazil",
      "British Indian Ocean Territory", "Brunei Darussalam", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia",
      "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Central African Republic", "Chad", "Chile", "China",
      "Christmas Island", "Cocos (Keeling) Islands", "Colombia", "Comoros", "Congo",
      "Congo, the Democratic Republic of the", "Cook Islands", "Costa Rica", "Cote d'Ivoire", "Croatia", "Cuba",
      "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt",
      "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Falkland Islands (Malvinas)",
      "Faroe Islands", "Fiji", "Finland", "France", "French Guiana", "French Polynesia",
      "French Southern Territories", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece",
      "Greenland", "Grenada", "Guadeloupe", "Guam", "Guatemala", "Guernsey", "Guinea",
      "Guinea-Bissau", "Guyana", "Haiti", "Heard Island and McDonald Islands", "Holy See (Vatican City State)",
      "Honduras", "Hong Kong", "Hungary", "Iceland", "India", "Indonesia", "Iran, Islamic Republic of", "Iraq",
      "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya",
      "Kiribati", "Korea, Democratic People's Republic of", "Korea, Republic of", "Kuwait", "Kyrgyzstan",
      "Lao People's Democratic Republic", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya",
      "Liechtenstein", "Lithuania", "Luxembourg", "Macao", "Macedonia, The Former Yugoslav Republic Of",
      "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique",
      "Mauritania", "Mauritius", "Mayotte", "Mexico", "Micronesia, Federated States of", "Moldova, Republic of",
      "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru",
      "Nepal", "Netherlands", "New Caledonia", "New Zealand", "Nicaragua", "Niger",
      "Nigeria", "Niue", "Norfolk Island", "Northern Mariana Islands", "Norway", "Oman", "Pakistan", "Palau",
      "Palestinian Territory, Occupied", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines",
      "Pitcairn", "Poland", "Portugal", "Puerto Rico", "Qatar", "Reunion", "Romania", "Russian Federation",
      "Rwanda", "Saint Barthelemy", "Saint Helena, Ascension and Tristan da Cunha", "Saint Kitts and Nevis", "Saint Lucia",
      "Saint Martin (French Part)", "Saint Pierre and Miquelon", "Saint Vincent and the Grenadines", "Samoa", "San Marino",
      "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore",
      "Sint Maarten (Dutch Part)", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
      "South Georgia and the South Sandwich Islands", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname",
      "Svalbard and Jan Mayen", "Swaziland", "Sweden", "Switzerland", "Syrian Arab Republic",
      "Taiwan, Province of China", "Tajikistan", "Tanzania, United Republic of", "Thailand", "Timor-Leste",
      "Togo", "Tokelau", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan",
      "Turks and Caicos Islands", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
      "United States", "United States Minor Outlying Islands", "Uruguay", "Uzbekistan", "Vanuatu",
      "Venezuela, Bolivarian Republic of", "Viet Nam", "Virgin Islands, British", "Virgin Islands, U.S.",
      "Wallis and Futuna", "Western Sahara", "Yemen", "Zambia", "Zimbabwe"
    ];

    return {
      restrict: 'E',
      scope: {
        val: '=ngModel'
      },
      template: '<select ng-model="val" class="form-control"> <option>' + countries.join('</option><option>') + '</option></select>',
      replace: false
    };
  }]);

/**
 * Created by codiumsa on 6/10/15.
 */
angular.module('qualita.authorization')
.filter('chunk', function() {
  return _.memoize(_.chunk);
});

'use strict';

angular.module('qualita.authorization')
    .controller('ImportacionDatosIndexCtrl',
        ["$rootScope", "$scope", "ImportacionDatosFactory", "ComprobanteVentaFactory", "$window", "baseurl", "formFactory", "LineasProductoFactory", "usSpinnerService", "notify", "TiposTipoFactory", "AlmacenFactory", "ProductosFactory", "ServicioFactory", "$state", "ProveedoresFactory", "ClienteFactory", "TransaccionStockFactory", "filterFactory", "ComprobanteFactory", "ClienteRetencionFactory", "AuthorizationService", function ($rootScope, $scope, ImportacionDatosFactory, ComprobanteVentaFactory, $window, baseurl,
            formFactory, LineasProductoFactory, usSpinnerService, notify, TiposTipoFactory, AlmacenFactory,
            ProductosFactory, ServicioFactory, $state, ProveedoresFactory, ClienteFactory, TransaccionStockFactory, 
            filterFactory, ComprobanteFactory, ClienteRetencionFactory, AuthorizationService) {

            $scope.title = 'Importación de Datos desde Archivos';

            $rootScope.isProcessing = false;

            $rootScope.ocultar = true;

            $scope.schema = ImportacionDatosFactory.schema();

            $scope.form = ImportacionDatosFactory.form();

            $scope.options = formFactory.defaultOptions();

            $scope.resource = 'importaciondatos';

            $scope.model = {};

            $scope.transaccionDisable = false;

            $scope.position = 0;

            $scope.submitButton = 'Actualizar datos';

            $scope.formSent = false;

            $scope.startSpin = function () {
                usSpinnerService.spin('spinner-1');
            }

            $scope.stopSpin = function () {
                usSpinnerService.stop('spinner-1');
            }
            var filterSucursal = {
                search: filterFactory.and([
                    {
                        path: "activo",
                        equals: true
                    }
                ]).value()
            };
            $scope.almacenes = AlmacenFactory.all(filterSucursal);

            $scope.tipoEntidades = TiposTipoFactory.get("entidad_importacion");
            var params2 = {};
            params2.search = filterFactory
                .and([{
                    path: "activo",
                    equals: true
                }, {
                    path: "usarParaImportacion",
                    equals: true
                }])
                .value();
            params2.view = "Base"
            $scope.transacciones = TransaccionStockFactory.all(params2);
            $scope.limpiarFiltros = function () {
                $scope.tipoEntidad = undefined;
                $scope.transaccion = undefined;
                $scope.fecha = undefined;
                $scope.hora = undefined;
                $scope.almacen = undefined;
                var flow = ImportacionDatosFactory.getFileFormProperty().uploader.flow;
                if (!flow) {
                    return;
                }
                flow.files = [];

            }
            $scope.$watch('tipoEntidad', function (newTipoEntidad, oldTipoEntidad) {
                console.log('Tipo de Entidad ha cambiado:', newTipoEntidad);
                changeTipoEntidad(newTipoEntidad);
            });

            // $scope.hora = moment(new Date()).format("HH:mm");
            // $scope.fecha = moment(new Date());

            function changeTipoEntidad(nuevoTipoEntidad) {
                if (nuevoTipoEntidad != undefined && nuevoTipoEntidad.codigo == 'entidad_saldo_inventario') {
                    $scope.transaccionDisable = true;
                } else {
                    $scope.transaccionDisable = false;
                }
            }

            $scope.submit = function () {

                var flow = ImportacionDatosFactory.getFileFormProperty().uploader.flow;
                var flowFile = flow.files[0];
                if (flowFile)
                    $scope.model.file = flowFile.name;
                $scope.$broadcast('schemaFormValidate');
                $scope.formSent = true;
                $scope.startSpin();

                $rootScope.isProcessing = true;

                if ($scope.tipoEntidad.codigo === 'entidad_productos') {
                    ProductosFactory.carga($scope.model.file,$rootScope.AuthParams.locacionSeleccionada.id).then(function (data) {
                        if (data.file === null || data.file === undefined) {
                            ImportacionDatosFactory.setData(data);
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        } else {
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        }
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        $scope.stopSpin();
                        $scope.formSent = false;
                        notify({
                            message: error.data[0].message,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                    });

                } else if ($scope.tipoEntidad.codigo === 'entidad_servicios') {
                    ServicioFactory.carga($scope.model.file).then(function (data) {
                        console.log("succes");
                        if (data.file === null || data.file === undefined) {
                            ImportacionDatosFactory.setData(data);
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        } else {
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        }
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        $scope.stopSpin();
                        $scope.formSent = false;
                        notify({
                            message: error.data[0].message,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                    });
                } else if ($scope.tipoEntidad.codigo === 'entidad_proveedores') {
                    ProveedoresFactory.carga($scope.model.file).then(function (data) {
                        console.log("succes");
                        if (data.file === null || data.file === undefined) {
                            ImportacionDatosFactory.setData(data);
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        } else {
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        }
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        $scope.stopSpin();
                        $scope.formSent = false;
                        notify({
                            message: error.data[0].message,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                    });
                } else if ($scope.tipoEntidad.codigo === 'entidad_clientes') {
                    ClienteFactory.carga($scope.model.file).then(function (data) {
                        ImportacionDatosFactory.setData(data);
                        $scope.stopSpin();
                        $scope.formSent = false;
                        $scope.limpiarFiltros();
                        if(data.data.filename){
                            notify({
                                message: 'Importación realizada con errores',
                                classes: 'alert-warning',
                                position: 'right'
                            });
                            var fileName = data.data.filename;
                            $window.open(baseurl.getPublicBaseUrl() + fileName);
                        }else{
                            notify({
                                message: 'Importación realizada con éxito',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        }
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        $scope.stopSpin();
                        $scope.formSent = false;
                        notify({
                            message: error.data[0].message,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                    });

                }else if ($scope.tipoEntidad.codigo === 'entidad_sucursales_clientes') {
                    ClienteFactory.cargaSucursales($scope.model.file).then(function (data) {
                        ImportacionDatosFactory.setData(data);
                        $scope.stopSpin();
                        $scope.formSent = false;
                        $scope.limpiarFiltros();
                        if(data.data.filename){
                            notify({
                                message: 'Importación realizada con errores',
                                classes: 'alert-warning',
                                position: 'right'
                            });
                            var fileName = data.data.filename;
                            $window.open(baseurl.getPublicBaseUrl() + fileName);
                        } else {
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Importación realizada con éxito',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        }
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        $scope.stopSpin();
                        $scope.formSent = false;
                        notify({
                            message: error.data[0].message,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                    });
                } else if ($scope.tipoEntidad.codigo === 'entidad_comprobantes_clientes') {
                    ComprobanteVentaFactory.importarCsv($scope.model.file, $rootScope.AuthParams.locacionSeleccionada.id).then(function (data) {
                        if (data.file === null || data.file === undefined) {
                            ImportacionDatosFactory.setData(data);
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        } else {
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        }
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        $scope.stopSpin();
                        $scope.formSent = false;
                        notify({
                            message: error.data[0].message,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                    });
                } else if ($scope.tipoEntidad.codigo === 'entidad_saldo_inventario') {
                    TransaccionStockFactory.carga($scope.model.file, $scope.fecha, $scope.hora, $scope.almacen.id, $scope.transaccion.id).then(function (data) {
                        console.log("succes");
                        if (data.file === null || data.file === undefined) {
                            ImportacionDatosFactory.setData(data);
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        } else {
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        }
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        $scope.stopSpin();
                        $scope.formSent = false;
                        notify({
                            message: error.data[0].message,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                    });
                }else if ($scope.tipoEntidad.codigo === 'entidad_comprobantes_proveedores') {
                    ComprobanteFactory.carga($scope.model.file, $rootScope.AuthParams.locacionSeleccionada.id).then(function (data) {
                        console.log("succes");
                        if (data.file === null || data.file === undefined) {
                            ImportacionDatosFactory.setData(data);
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        } else {
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        }
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        $scope.stopSpin();
                        $scope.formSent = false;
                        notify({
                            message: error.data[0].message,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                    });
                }
                else if ($scope.tipoEntidad.codigo === 'entidad_lineas') {
                    LineasProductoFactory.carga($scope.model.file, $rootScope.AuthParams.locacionSeleccionada.id).then(function (data) {
                        console.log("succes");
                        console.log("AQUI DATA: ", data);
                        if (data.data==null || data.data.filename === null || data.data.filename === undefined) {
                            ImportacionDatosFactory.setData(data);
                            $scope.stopSpin();
                            $scope.formSent = false;
                            $scope.limpiarFiltros();
                            notify({
                                message: 'Cargado con exito..',
                                classes: 'alert-success',
                                position: 'right'
                            });
                        }else {
                            $scope.stopSpin();
                            $scope.formSent = false;
                            notify({
                                message: 'Error en la importacion.',
                                classes: 'alert-danger',
                                position: 'right'
                            });
                            $window.open(baseurl.getPublicBaseUrl() + data.data.filename, "_blank");
                        }
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        $scope.stopSpin();
                        $scope.formSent = false;
                        notify({
                            message: error.data[0].message,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                    });
                }
                // Comp. Retencion (Ventas)
                else if ($scope.tipoEntidad.codigo === 'entidad_comp_retencion_ventas') {
                    if(AuthorizationService.hasPermission("create_clienteretenciones")){
                        ClienteRetencionFactory.carga($scope.model.file, $rootScope.AuthParams.locacionSeleccionada.id).then(function (data) {
                            console.log("succes");
                            console.log("AQUI DATA: ", data);
                            if (data.data==null || data.data.filename === null || data.data.filename === undefined) {
                                ImportacionDatosFactory.setData(data);
                                $scope.stopSpin();
                                $scope.formSent = false;
                                $scope.limpiarFiltros();
                                notify({
                                    message: 'Cargado con exito..',
                                    classes: 'alert-success',
                                    position: 'right'
                                });
                            }else {
                                $scope.stopSpin();
                                $scope.formSent = false;
                                notify({
                                    message: 'Error en la importacion.',
                                    classes: 'alert-danger',
                                    position: 'right'
                                });
                                $window.open(baseurl.getPublicBaseUrl() + data.data.filename, "_blank");
                            }
                            $state.go('app.clienteretenciones.list');
                        }, function (error) {
                            $rootScope.isProcessing = false;
                            $scope.stopSpin();
                            $scope.formSent = false;
                            notify({
                                message: error.data[0].message,
                                classes: 'alert-danger',
                                position: 'right'
                            });
                        });
                    }else{
                        $scope.stopSpin();
                        $scope.formSent = false;
                        notify({
                            message: 'No cuenta con permisos para realizar la operacion.',
                            classes: 'alert-danger',
                            position: 'right'
                        });
                    }
                }
            };

            $scope.cancel = function (form) {
                $scope.limpiarFiltros();
                $state.go('app.importaciondatos.list');
            }
        }]);

'use strict';

angular.module('qualita.authorization')
    .factory('ImportacionDatosFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

        var ImportacionDatos = $resource(baseurl.getBaseUrl() + '/importaciondatos/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            }
        });

        var schema = {
            type: 'object',
            title: 'Importacion de Datos',
            properties: {
                file: {
                    type: 'object',
                    format: 'fileupload',
                    title: 'Archivo'
                }
            },
            required: []
        };

        var fileFormProperty = {
            key: 'file',
            fileName: 'file',
            uploadOptions: {
                imageOnly: false,
                target: baseurl.getBaseUrl() + '/importacionUpload'
            },
            uploader: {}
        };

        var form = [
            fileFormProperty
        ];

        var savedData = {};

        return {
            all: function (params) {
                return ImportacionDatos.query(params);
            },

            get: function (id) {
                return ImportacionDatos.get({ id: id });
            },

            create: function (attrs) {
                return new ImportacionDatos(attrs);
            },

            save: function (importaciondatos) {
                return (importaciondatos.id) ? importaciondatos.$update() : importaciondatos.$save();
            },

            remove: function (importaciondatos) {
                return importaciondatos.$remove();
            },

            schema: function () {
                return schema;
            },

            form: function () {
                return form;
            },

            getFileFormProperty: function () {
                return fileFormProperty;
            },

            setData: function set(data) {
                //$window.alert("set");
                savedData = data;
            },

            getData: function get() {
                //$window.alert("get");
                return savedData;
            }
        };
    }]);

'use strict';

angular.module('qualita.authorization')
    .controller('TimbradoEmpresaIndexCtrl', TimbradoEmpresaIndexCtrl);

TimbradoEmpresaIndexCtrl.$inject = ['$scope', 'timbradoEmpresaFactory', 'filterFactory',
    'ReportTicketFactory', '$window', 'AuthorizationLangFactory', '$filter', 'UtilFactory', 'CsvFactory', 'baseurl'
];

function TimbradoEmpresaIndexCtrl($scope, timbradoEmpresaFactory, filterFactory, ReportTicketFactory,
    $window, AuthorizationLangFactory, $filter, UtilFactory, CsvFactory, baseurl) {

    var vm = this;
    var defaultColumnOrder = ['id', 'sucursal.nombre', 'numeroTimbrado', 'fechaDesde', 'fechaHasta', 'claseTimbrado.descripcion', 'activo'];

    var renderEmptyData = function(data) {
        if (data == undefined)
            return "";
        else
            return data;
    };

    var renderSucursalData = function(data) {
        if (data == undefined)
            return "Todas";
        else
            return data;
    };

    AuthorizationLangFactory.getTranslations().then(function(translations) {
        vm.options = {
            'resource': 'timbradoempresa',
            'title': $filter('translate')('STAMPING_TITLE'),
            'factory': timbradoEmpresaFactory,
            'view': 'TimbradoEmpresaList',
            'defaultColumnOrder': defaultColumnOrder,
            'failedDeleteError': $filter('translate')('STAMPING_DELETE_ERROR'),
            'columns': [
                { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false, 'renderWith': 'emptyRender'},
                { 'data': 'sucursal.nombre', 'title': $filter('translate')('BRANCH_OFFICE'), 'render': renderSucursalData },
                { 'data': 'numeroTimbrado', 'title': $filter('translate')('STAMPING_NUMBER'), 'renderWith': 'emptyRender', 'class': 'dt-right' },
                { 'data': 'fechaDesde', 'title': $filter('translate')('DATE_FROM'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'type': 'date-range', 'class': 'dt-center' },
                { 'data': 'fechaHasta', 'title': $filter('translate')('DATE_TO'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'type': 'date-range', 'class': 'dt-center' },
                { 'data': 'claseTimbrado.descripcion', 'title': $filter('translate')('STAMPING_CLASS'), 'renderWith': 'emptyRender', 'type': 'combo', 'filterUrl': 'tipos/filtro/clase_timbrado', 'class': 'dt-center' },
                { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'renderWith': 'emptyRender', 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'}
            ],
            'hasOptions': true,
            'defaultOrderColumn': 0,
            'defaultOrderDir': "desc",
            'extraMenuOptions': [{
                    'title': 'Reporte',
                    'icon': 'glyphicon glyphicon-file',
                    'showCondition': function() { return true; },
                    'action': function() {
                        var filters = createFilters(vm.options.getFilters());

                        console.log("filters " , filters);

                        ReportTicketFactory.ticket('listadoTimbradoEmpresa', filters, vm.options.tableAjaxParams,
                            vm.options.currentColumnOrder).then(function(genTicket) {
                            $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                            $window.open($scope.pdfDownloadURL, '_blank');
                        });
                    }
                },
                {
                    'title': 'CSV',
                    'icon': 'glyphicon glyphicon-export',
                    'showCondition': function() { return true; },
                    'action': function() {
                        var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                        CsvFactory.csv("timbradoempresa", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                            .then(function(response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                    }
                }
            ]
        };
    });
    var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            search = search.split("~");
            if (search.length > 1) {
                /* El parámetro es un rago */

                var isDate = search[0].split("/");

                if (isDate.length > 1) {
                    /* Es un rago de fechas */

                    filtersArr.push({path: data, equalOrAfter: search[0]})
                    filtersArr.push({path: data, equalOrBefore: search[1]})
                } else {
                    /* El un rago numérco */

                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                    filtersArr.push({path: data, lessOrEqual: search[1]})
                }
            } else {
                /* El parametro no es un rago */
                if(search[0] === 'No' && data === 'activo'){
                    search[0] = 'false';
                }
                if(search[0] === 'Sí' && data === 'activo'){
                    search[0] = 'true';
                }
    
                filtersArr.push({path: data, like: search[0]})
            }
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }
};
'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:AfeccionMedicaFormCtrl
 * @description
 * # tipoFaltaFormCtrl
 * Controller of the rasApp
 */
angular.module('qualita.authorization')
    .controller('TimbradoEmpresaFormCtrl', TimbradoEmpresaFormCtrl);

TimbradoEmpresaFormCtrl.$inject = ['$scope', '$rootScope', 'timbradoEmpresaFactory', 'SucursalesFactory', '$location', '$state', 'ModelTrimmer', 'timbradoEmpresaPrepService',
    'AuthorizationLangFactory', "notify", "$filter", "TiposFactory", "puntoEmisionFactory", "filterFactory", "ParametrosFactory", "ComprobanteVentaFactory", "RemisionFactory",
    "AuthorizationService"
];

function TimbradoEmpresaFormCtrl($scope, $rootScope, timbradoEmpresaFactory, SucursalesFactory, $location, $state,
    ModelTrimmer, timbradoEmpresaPrepService, AuthorizationLangFactory, notify, $filter, TiposFactory, puntoEmisionFactory, filterFactory,
    ParametrosFactory, ComprobanteVentaFactory, RemisionFactory, AuthorizationService) {

    var vm = this;

    vm.cancel = cancel;
    vm.submit = submit;
    vm.deshabilitarCampos = deshabilitarCampos;
    vm.changeSucursal = changeSucursal;
    vm.searchPuntoEmisionFilter = searchPuntoEmisionFilter;
    vm.agregarDetalle = agregarDetalle;
    vm.eliminarDetalle = eliminarDetalle;
    vm.changeClaseTimbrado = changeClaseTimbrado;
    vm.deshabilitar = false;

    activate();

    function makeAsyncRequests() {
        SucursalesFactory.all().$promise.then(function (response) {
            vm.sucursales = response;
            vm.sucursales.push({ nombre: "Todas" });
        });

        timbradoEmpresaFactory.tipos('clase_timbrado').then(function (data) {
            vm.clasesTimbrado = data.data;
        });

        TiposFactory.clasesTipoComprobante().then(function (data) {
            vm.tiposComprobantes = data.data;
        });
    }

    function activate() {
        AuthorizationLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;

            vm.modificarNumero = AuthorizationService.hasPermission("update_numero_timbrado");


            makeAsyncRequests();
            if ($state.is("app.timbradoempresa.new")) {
                activateNew();
            } else if ($state.is("app.timbradoempresa.edit")) {
                activateEdit();
            } else if ($state.is("app.timbradoempresa.view")) {
                activateView();
            }

            $rootScope.isProcessing = false;
            vm.submited = false;

            vm.form = $scope.TimbradoEmpresaForm;
            if (vm.timbradoEmpresa.fechaHasta === null || vm.timbradoEmpresa.fechaHasta === undefined) {
                vm.timbradoEmpresa.fechaHasta = null;
            } else {
                vm.timbradoEmpresa.fechaHasta = new Date(vm.timbradoEmpresa.fechaHasta);
            }
        });
    }

    function activateNew() {
        vm.curDate = new Date();
        vm.title = $filter('translate')('NEW_STAMPING');
        vm.timbradoEmpresa = { activo: true, timbradoPuntoEmision: [{}],factAgilDefault :false};
        vm.isNew = true;
    }

    function activateEdit() {
        vm.title = $filter('translate')('EDIT_STAMPING');
        vm.timbradoEmpresa = timbradoEmpresaPrepService;
        vm.isEdit = true;
        //obtener permiso de modificar cabecera timbrado
        if (AuthorizationService.hasPermission("modificar_cabecera_timbrado") === false) {
            vm.existenAsociados = false;
        } else {
            vm.existenAsociados = true;
        }

        vm.changeClaseTimbrado(false);
        if (vm.timbradoEmpresa.sucursal == undefined || vm.timbradoEmpresa.sucursal == null) {
            vm.timbradoEmpresa.sucursal = { nombre: "Todas" };
        }
        vm.timbradoEmpresa.fechaDesde = new Date(vm.timbradoEmpresa.fechaDesde);
        if (vm.timbradoEmpresa.fechaHasta === null) {
            vm.timbradoEmpresa.fechaHasta = null;
        } else {
            vm.timbradoEmpresa.fechaHasta = new Date(vm.timbradoEmpresa.fechaHasta);
        }
        $scope.entidad = 'timbradoEmpresa';
        $scope.entidadId = vm.timbradoEmpresa.id;
        changeSucursal();
    }

    function activateView() {
        vm.title = $filter('translate')('VIEW_STAMPING');
        vm.timbradoEmpresa = timbradoEmpresaPrepService;
        if (vm.timbradoEmpresa.sucursal == undefined || vm.timbradoEmpresa.sucursal == null) {
            vm.timbradoEmpresa.sucursal = { nombre: "Todas" };
        }
        vm.timbradoEmpresa.fechaDesde = new Date(vm.timbradoEmpresa.fechaDesde);
        if (vm.timbradoEmpresa.fechaHasta === null) {
            vm.timbradoEmpresa.fechaHasta = null;
        } else {
            vm.timbradoEmpresa.fechaHasta = new Date(vm.timbradoEmpresa.fechaHasta);
        }
        vm.entidadId = vm.timbradoEmpresa.id;
        vm.entidad = "timbradoEmpresa";
        $scope.view = true;
        changeSucursal();
    }

    function cancel() {
        $location.path("/timbradoempresa");
    }


    function deshabilitarCampos() {
        // if (vm.timbradoEmpresa.claseTimbrado.codigo == "timbrado_manual") {
        //     vm.deshabilitar = true;
        // } else {
        //     vm.deshabilitar = false;
        // }
    }
    function changeClaseTimbrado(isNew) {
        if (vm.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
            $scope.isVirtual = true;
            if (isNew) {
                vm.timbradoEmpresa.fechaHasta = new Date(2099, 11, 31);
            }
        } else {
            $scope.isVirtual = false;
        }
    }

    function changeSucursal() {
        console.log("AQUI CHANGE SUCURSAL");
        if (vm.timbradoEmpresa.sucursal.nombre != "Todas") {
            var filtersArray = [];
            filtersArray.push({
                path: 'puntoVenta.sucursal.id',
                equals: vm.timbradoEmpresa.sucursal.id
            });

            var filters = { search: filterFactory.and(filtersArray).value() };
            vm.puntosEmision = puntoEmisionFactory.all(filters, "TimbradoEmpresaForm");
        } else {
            var filter = {};
            filter.view = "TimbradoEmpresaForm";
            vm.puntosEmision = puntoEmisionFactory.all(filter);
        }
    }

    function agregarDetalle() {
        if (vm.timbradoEmpresa.activo == true) {
            vm.timbradoEmpresa.timbradoPuntoEmision.push({});
            vm.existenAsociados = true;
            vm.isNew = false;
        } else {
            var msg = 'El timbrado se encuentra inactivo. No puede agregar Puntos de emisión.';
            notify({
                message: msg,
                classes: 'alert-danger',
                position: 'right'
            });
            return;
        }
    }

    function eliminarDetalle(elemento) {
        if (elemento.puntoEmisionVenta != undefined) {
            var filtroCR = {};
            filtroCR.search = filterFactory
                .and([{
                    path: "timbradoPuntoEmision.id",
                    equals: elemento.id
                }])
                .value();

            ComprobanteVentaFactory.all(filtroCR).$promise.then(function (comprobante) {
                console.log("comprobante ", comprobante)

                if (comprobante.length > 0) {
                    $rootScope.isProcessing = false;
                    var msg = "El punto de emisión ya fue utilizado. No se puede eliminar";
                    notify({
                        message: msg,
                        classes: 'alert-danger',
                        position: 'right'
                    });
                    return;
                } else {
                    RemisionFactory.all(filtroCR).$promise.then(function (remisiones) {
                        if (remisiones.length > 0) {
                            $rootScope.isProcessing = false;
                            var msg = "El punto de emisión ya fue utilizado. No se puede eliminar";
                            notify({
                                message: msg,
                                classes: 'alert-danger',
                                position: 'right'
                            });
                            return;
                        } else {
                            _.remove(vm.timbradoEmpresa.timbradoPuntoEmision, function (item) {
                                return item === elemento;
                            });
                        }
                    });
                }
            });
        } else {
            _.remove(vm.timbradoEmpresa.timbradoPuntoEmision, function (item) {
                return item === elemento;
            });
        }
    }

    function submit() {
        vm.submited = true;
        $rootScope.isProcessing = true;
        // verificamos que el numero inicial sea menor al final
        if (_.find(vm.timbradoEmpresa.timbradoPuntoEmision, function (tepev) {
            if (!$scope.isVirtual) {
                return tepev.numeroInicial > tepev.numeroFinal;
            }
        })) {
            $rootScope.isProcessing = false;
            var msg = $filter('translate')('ALL_PUNTOS_EMISION_NUMERO_INICIAL_MENOR_NUMERO_FINAL');
            notify({
                message: msg,
                classes: 'alert-danger',
                position: 'right'
            });
            return;
        }
        // verificamos que no hayan dos Puntos de Emisión con mismo PV y Tipo de Comprobante

        var groupedPuntosEmision = _.groupBy(vm.timbradoEmpresa.timbradoPuntoEmision, function (tepev) {

            if (tepev.puntoEmisionVenta && tepev.tipoComprobante) {
                return "" + tepev.puntoEmisionVenta.id + " - " + tepev.tipoComprobante.descripcion;
            }
        });
        if (_.find(groupedPuntosEmision, function (gpe) {
            return gpe.length > 1;
        })) {
            $rootScope.isProcessing = false;
            var msg = $filter('translate')('MISMO_PUNTO_EMISION_TIPO_COMPROBANTE_ERROR');
            notify({
                message: msg,
                classes: 'alert-danger',
                position: 'right'
            });
            return;
        }

        if (vm.timbradoEmpresa.sucursal != null && vm.timbradoEmpresa.sucursal.nombre == "Todas") {
            vm.timbradoEmpresa.sucursal = null;
        }
        var trimmedTimbradoEmpresa = ModelTrimmer.trimDetails(vm.timbradoEmpresa, ["timbradoPuntoEmision"]);
        trimmedTimbradoEmpresa.timbradoPuntoEmision = _.map(trimmedTimbradoEmpresa.timbradoPuntoEmision,
            function (timbradoPuntoEmision) {
                return ModelTrimmer.trimDetails(timbradoPuntoEmision);
            });
        var timbradoEmpresas = timbradoEmpresaFactory.create(trimmedTimbradoEmpresa);

        if ($scope.TimbradoEmpresaForm.$valid) {

            if (!angular.isString(vm.timbradoEmpresa.fechaDesde)) {
                var desde = moment(vm.timbradoEmpresa.fechaDesde).format('DD/MM/YYYY');
            } else {
                var desde = $scope.model.fechaDesde;
            }
            if (!angular.isString(vm.timbradoEmpresa.fechaHasta)) {
                var hasta = moment(vm.timbradoEmpresa.fechaHasta).format('DD/MM/YYYY');
            } else {
                var hasta = vm.timbradoEmpresa.fechaHasta;
            }

            var d1 = parseInt(desde.substring(0, 2));
            var d2 = parseInt(desde.substring(3, 5));
            var d3 = parseInt(desde.substring(6, 10));
            var h1 = parseInt(hasta.substring(0, 2));
            var h2 = parseInt(hasta.substring(3, 5));
            var h3 = parseInt(hasta.substring(6, 10));

            if ($scope.isVirtual) {
                if (vm.timbradoEmpresa.activo === false) {
                    $rootScope.isProcessing = false;
                    notify({
                        message: $filter('translate')('ACTIVO_CAMPO_OBLIGATORIO'),
                        classes: 'alert-danger',
                        position: 'right'
                    });
                } else {
                    submitTimbradoEmpresas().then(function (timbradoEmpresas) {
                        ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                            if (parametro.valorTipo.codigo == 'si') {
                                console.log("AQUI PARAMETRO: ", parametro.valorTipo.codigo);
                                if (timbradoEmpresas.enviadoAContabilidad == false) {
                                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                }
                            }
                        });
                        $location.path('/timbradoempresa');
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        var msg = "";
                        if (error.data && error.data[0]) {
                            msg = error.data[0].message;
                        }
                        // TODO cambiar esto y usar solo el key del unique field
                        if (error.data[0].constraint == "numero_timbrado") {
                            msg = $filter('translate')('ERROR_NUMERO_TIMBRADO_REPETIDO');

                        } else if (error.data[0].constraint == "numero_timbrado, id_tipo_timbrado") {
                            msg = $filter('translate')('ERROR_NUMERO_TIMBRADO_CLASE_REPETIDO');

                        }
                        notify({
                            message: msg,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                        vm.timbradoEmpresa.sucursal = vm.timbradoEmpresa.sucursal || { nombre: "Todas " };
                    });
                }
            } else {
                if (d3 < h3 || (d2 < h2 && d3 === h3) || (d1 <= h1 && d2 === h2 && d3 === h3)) {
                    submitTimbradoEmpresas().then(function (timbradoEmpresas) {
                        ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                            if (parametro.valorTipo.codigo == 'si') {
                                console.log("AQUI PARAMETRO: ", parametro.valorTipo.codigo);
                                if (timbradoEmpresas.enviadoAContabilidad == false) {
                                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                }
                            }
                        });
                        $location.path('/timbradoempresa');
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        var msg = "";
                        if (error.data && error.data[0]) {
                            msg = error.data[0].message;
                        }
                        // TODO cambiar esto y usar solo el key del unique field
                        if (error.data[0].constraint == "numero_timbrado") {
                            msg = $filter('translate')('ERROR_NUMERO_TIMBRADO_REPETIDO');

                        } else if (error.data[0].constraint == "numero_timbrado, id_tipo_timbrado") {
                            msg = $filter('translate')('ERROR_NUMERO_TIMBRADO_CLASE_REPETIDO');

                        }
                        notify({
                            message: msg,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                        vm.timbradoEmpresa.sucursal = vm.timbradoEmpresa.sucursal || { nombre: "Todas " };
                    });
                } else {
                    $rootScope.isProcessing = false;
                    var msg = $filter('translate')('INVALID_DATES');
                    notify({
                        message: msg,
                        classes: 'alert-danger',
                        position: 'right'
                    });
                    vm.timbradoEmpresa.sucursal = vm.timbradoEmpresa.sucursal || { nombre: "Todas " };
                }
            }
        } else {
            $rootScope.isProcessing = false;
        }

        function submitTimbradoEmpresas() {
            console.log("TIMBRADO", timbradoEmpresas);
            return timbradoEmpresaFactory.save(timbradoEmpresas);
        }
    }

    function searchPuntoEmisionFilter(pattern) {
        return function (item) {
            if (!pattern) {
                return true;
            }
            var condition = (item.numero && item.numero.toLowerCase().indexOf(pattern.toLowerCase()) > -1) ||
                (item.descripcion && item.descripcion.toLowerCase().indexOf(pattern.toLowerCase()) > -1);
            return condition;
        }
    }

    $scope.resource = 'timbradoempresa'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}
'use strict';

/**
 * @ngdoc service
 * @name qualita.timbradoEmpresas
 * @description
 * # timbradoEmpresas
 * Factory in the qualita.
 */
angular.module('qualita.authorization')
  .factory('timbradoEmpresaFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var timbradoEmpresa = $resource(baseurl.getBaseUrl() + '/timbradoempresa/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return timbradoEmpresa.query(params);
      },
      get: function (id, view) {
        console.log(view);
        //if (typeof(view)==='undefined') view = "base";
        return timbradoEmpresa.get({ id: id, view: view != undefined ? view : 'base' });
      },
      create: function (attrs) {
        return new timbradoEmpresa(attrs);
      },
      save: function (timbradoEmpresa) {
        return (timbradoEmpresa.id) ? timbradoEmpresa.$update() : timbradoEmpresa.$save();
      },
      remove: function (timbradoEmpresa) {
        return timbradoEmpresa.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      }
    };
  }]);

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.authorization')
  .factory('NumeroActualFactory', ['$resource', 'baseurl', function($resource, baseurl) {
    var numeroActual = $resource( baseurl.getBaseUrl() + '/numeroactual/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params) {
        return numeroActual.query(params);
      },

      get: function(id, view) {
        return numeroActual.get({id: id, view: view!=undefined ? view : 'base'});
      },

      save: function(numeroActual) {
        return (numeroActual.id) ? numeroActual.$update() : numeroActual.$save();
      },


      create: function(attrs) {
        return new numeroActual(attrs);
      }
    };
  }
  ]);

'use strict';


angular.module('qualita.authorization')
  .controller('NumeroActualIndexCtrl', ["$scope", "NumeroActualFactory", "$state", "filterFactory", "notify", "ReportTicketFactory", "$window", "AuthorizationLangFactory", "CsvFactory", "baseurl", "UtilFactory", "$filter", function ($scope, NumeroActualFactory, $state, filterFactory,
  notify, ReportTicketFactory, $window, AuthorizationLangFactory, CsvFactory, baseurl, UtilFactory, $filter) {

    var defaultColumnOrder = ['id', 'entidad', 'pk'];
    AuthorizationLangFactory.getTranslations().then(function(translations) {
      $scope.options = {
        'resource': 'numeroactual',
        'title': $filter('translate')('NUMERO_RECIBO_ADMINISTRACION'),
        'factory': NumeroActualFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'view': 'NumeroActualList',
        'columns': [
          {'data': 'id', 'title': $filter('translate')('CODE'), visible: false }
          ,{'data': 'entidad', 'title': $filter('translate')('ENTIDAD')},
          {'data': 'pk', 'title': $filter('translate')('NUMERO_ACTUAL')}
        ],
        'hasOptions' : true,
        'hideEditMenu': false,
        'hideDeleteMenu': true,
        'hideViewMenu': true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoNumeroActual', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("numeroactual", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ]
      };
    });
    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({path: data, like: search})
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }
  }]);

'use strict';


angular.module('qualita.authorization')
  .controller('NumeroActualFormCtrl', NumeroActualFormCtrl);

NumeroActualFormCtrl.$inject = ['$scope', '$rootScope', 'NumeroActualFactory',
  '$location', '$state', 'ModelTrimmer', 'numeroActualPrepService', 'AuthorizationLangFactory', 'notify', 
  'formFactory', '$filter'];

function NumeroActualFormCtrl($scope, $rootScope, NumeroActualFactory, $location, $state,
                           ModelTrimmer, numeroActualPrepService, AuthorizationLangFactory, notify, formFactory,
                           $filter) {

  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;

  activate();

  function activate() {
    AuthorizationLangFactory.getTranslations().then(function(translations){
      vm.translations = translations;
      
      if ($state.is("app.numeroactual.edit")) {
        activateEdit();
      }

      $rootScope.isProcessing = false;
      vm.submited =false;
    });
  }


  function activateEdit() {
    vm.title = $filter('translate')('EDIT_NUMERO_ACTUAL');
    vm.numeroActual = numeroActualPrepService;
    vm.numeroActual.fecha = new Date(vm.numeroActual.fecha);
    $scope.entidad = 'numeroActual';
    $scope.entidadId = vm.numeroActual.id;

    console.log('%% : ' + vm.numeroActual.activo);
  }


  function cancel() {
    $location.path("/numeroactual");
  }

  function submit() {
    vm.submited = true;
    var trimmedSeccionesEmpresa = ModelTrimmer.trimDetails(vm.numeroActual);
    

    formFactory.defaultNSFSubmit($scope.NumeroActualForm, NumeroActualFactory, trimmedSeccionesEmpresa, errorHandler).then(function (response) {
      $location.path('/numeroactual');
    }, function(error) {
      console.log(error);
    });
  }

  function errorHandler(e) {
    _.forEach(e.data, function(error) {
      if(error!=null && error.message!=null){
        notify({
          message: error.message,
          classes: 'alert-danger',
          position: 'right'
        });
      }      
    });
  }

  $scope.resource = 'numeroactual'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}




/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.authorization')
  .factory('EntidadFactory', ['$resource', 'baseurl', function($resource, baseurl) {
    var entidad = $resource( baseurl.getBaseUrl() + '/entidades/:id', {id: '@id'}, {
      enviar: {
        method: 'GET',
        url: baseurl.getBaseUrl() + '/entidades/:id/enviar'
      }
    });

    return {
      all: function(params) {
        return entidad.query(params);
      },

      get: function(id, view) {
        return entidad.get({id: id, view: view!=undefined ? view : 'base'});
      },

      enviar: function(id) {
        console.log('funcion enviar a contabilidad '+id);
        return entidad.enviar({id: id});
      }
    };
  }
  ]);

'use strict';


angular.module('qualita.authorization')
  .controller('EntidadIndexCtrl', ["$scope", "EntidadFactory", "$state", "filterFactory", "notify", "ReportTicketFactory", "$window", "AuthorizationLangFactory", "CsvFactory", "baseurl", "UtilFactory", "$filter", "AuthorizationService", "$modal", "ParametrosFactory", function ($scope, EntidadFactory, $state, filterFactory,
  notify, ReportTicketFactory, $window, AuthorizationLangFactory, CsvFactory, baseurl, UtilFactory, $filter, AuthorizationService, $modal, ParametrosFactory) {

    var defaultColumnOrder = ['id', 'nombre','codigo'];



    AuthorizationLangFactory.getTranslations().then(function(translations) {
      $scope.options = {
        'resource': 'entidades',
        'title': 'Envio de Entidades a Contabilidad',
        'factory': EntidadFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'view': 'EntidadesList',
        'columns': [
          {'data': 'id', 'title': 'Codigo', visible: false }
          ,{'data': 'nombre', 'title': "Nombre"}
          ,{'data': 'codigo', 'title': "Código Envio", visible: false }
        ],
        'hasOptions' : true,
        'hideEditMenu': false,
        'hideDeleteMenu': true,
        'hideViewMenu': true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "asc",
        'extraRowOptions': [
          {
            templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='" + $filter('translate')('ENVIAR_A_CONTABILIDAD') + "' ng-click='enviar(<%=dataCustom%>)' ng-class='{ hidden : !canEnviar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-transfer'></span> </button>",
            customAttribute : ["id","codigo","nombre"],
            conditionName:"canEnviar",
            conditionDef: function (atributos) {
              return AuthorizationService.hasPermission('enviar_contabilidad');
            },
            functionName: "enviar",
            functionDef: function(atributos) {

              var idEntidad = atributos[0];
              var codigo = atributos[1];
              var nombre = atributos[2];

              $scope.selectedItemId = idEntidad;
              $scope.tituloModal = $filter('translate')('ENVIO_A_CONTABILIDAD_CONFIRMATION');
              $scope.mensajeModal = $filter('translate')('ENVIO_WARNING',{entidad:nombre});

              $scope.modalInstanceEnviarAContabilidad = $modal.open({
                  templateUrl: 'views/directives/confirmation-modal.html',
                  scope: $scope
              });

              $scope.cancel = function () {
                  $scope.modalInstanceEnviarAContabilidad.dismiss('cancel');
              }

              $scope.ok = function (idEntidad) {
                ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                  if (parametro.valorTipo.codigo == 'si') {
                    EntidadFactory.enviar(idEntidad).$promise.then(function (response) {
                      if(response.$resolved == true){
                        var msg = $filter('translate')('EMVIO_MESSAGE_SUCCESSFUL',{entidad:nombre});
                        notify({ message: msg, classes: 'alert-success', position: 'right' });
                      }
                      $state.go($state.current, {}, { reload: true });
                    },
                    function (error) {
                        var msg = $filter('translate')('EMVIO_MESSAGE_WARNING',{entidad:nombre}) || error;
                        notify({ message: msg, classes: 'alert-warning', position: 'right' });
                    });
                  }else{
                    var msg = "";
                    msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' }); 
                  }
                });
              }
            }
          }
        ]
      };
    });
    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({path: data, like: search})
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }
  }]);

'use strict';

angular.module('qualita.authorization')
  .controller('LogoEmpresaFormCtrl',
    ["$scope", "ProductoAlmacenFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "notify", "UtilFactory", "WatchLocacionChangeFactory", "$filter", "$rootScope", "CsvFactory", "baseurl", "TiposTipoFactory", "LogoFactory", function ($scope, ProductoAlmacenFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal,
      $window, $state, notify, UtilFactory, WatchLocacionChangeFactory, $filter, $rootScope, CsvFactory, baseurl, TiposTipoFactory, LogoFactory) {

    var vm = this;
    
    vm.uploadOptions1 = {
        imageOnly: true,
        title: 'Nuevo Logo'
      };

    vm.permisoModificar = AuthorizationService.hasPermission('update_logoempresa');  
    vm.permisoCrear = AuthorizationService.hasPermission('create_logoempresa');
    vm.permisoEliminar = AuthorizationService.hasPermission('delete_logoempresa');  
  


    var filtro = {};
          //   filtro.search = filterFactory.and([
          //       {
          //         path: 'id',
          //         equals: 2
          //       }
          // ]).value();
          filtro.search = filterFactory.and([]).value();
           $scope.valorEntidad = LogoFactory.all(filtro);
           $scope.valorEntidad.$promise.then(function(dat) {
            if(dat[0]){
              vm.descripcion = dat[0].descripcion; 
              $scope.base64Im = dat[0].tipo + "," + dat[0].imagen;
   
              //  base64ToFile(dat[0].imagen, "archivo", "image/png")
              // .then(file => {
              // // Utiliza el objeto de archivo

              //  console.log(file);
              // })
              // .catch(error => {
              // // Maneja el error
              // console.error(error);
              // });

            } else {
              // En caso de que no haya imagen guardada
              $scope.base64Im = "";
              
            }
          });
      
    //  function base64ToFile(base64String, filename, contentType) {
    //         return fetch(`data:${contentType};base64,${base64String}`)
    //           .then(response => response.blob())
    //           .then(blob => new File([blob], filename, { type: contentType }));
    //  }      

    $scope.guardar = function () {
      console.log(vm);
    if(vm.uploadOptions1.flow){ 
      if(!vm.permisoCrear || !vm.permisoModificar){
        notify({message: "No tiene permiso para Crear o Modificar Logo", classes: 'alert-danger', position: 'center'});
        $state.go($state.current, {}, { reload: true });
        return;
    }
      
      if(vm.descripcion == undefined || vm.descripcion == "" || vm.uploadOptions1.flow.files.length == 0){
        notify({message: "Los campos Descripción y Nuevo logo son obligatorios", classes: 'alert-danger', position: 'center'});
        $state.go($state.current, {}, { reload: true });
        return;
      }  
      if(vm.uploadOptions1.flow.files[0].file.size > 41000){
        notify({message: "La imagen es más grande del tamaño máximo permitido", classes: 'alert-danger', position: 'center'});
        $state.go($state.current, {}, { reload: true });
        return;
      }
       var file = vm.uploadOptions1.flow.files[0].file;
       var reader = new FileReader();
       reader.readAsDataURL(file);

       reader.onload = function(e) {
        var base64Image = e.target.result;
        var imagenDatos = base64Image.split(',');
        // base64Image = base64Image.split(',')[1];
            base64Image = imagenDatos[1];
        var tipoDato = imagenDatos[0];    
      
        var data = {
          file: base64Image,
          descripcion: vm.descripcion,
          tipo: tipoDato
        };
        LogoFactory.guardarLogo(data).then(function (response) {
        var resp = response.data;
        notify({message: "El logo fue guardado correctamente en la base de datos", classes: 'alert-success', position: 'center'});
        $state.go($state.current, {}, { reload: true });
       });
      };
     } else{
          notify({message: "Los campos Descripción y Nuevo logo son obligatorios", classes: 'alert-danger', position: 'center'});
          $state.go($state.current, {}, { reload: true });
          return;
     }
    }

    $scope.limpiar = function () {
      $state.go($state.current, {}, { reload: true });

    }

    $scope.eliminar = function () {
      if(!vm.permisoEliminar){
          notify({message: "No tiene permiso para eliminar Logo", classes: 'alert-danger', position: 'center'});
          $state.go($state.current, {}, { reload: true });
          return;
      }
      var data = {
        file: "",
        descripcion: "",
        tipo: ""
      };
      LogoFactory.guardarLogo(data).then(function (response) {
        var resp = response.data;
        notify({message: "El logo fue eliminado correctamente", classes: 'alert-success', position: 'center'});
        $state.go($state.current, {}, { reload: true });
       });

      $state.go($state.current, {}, { reload: true });

    }

  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module('qualita.authorization')
  .factory('LogoFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
    function($resource,  baseurl, filterFactory, TiposFactory, $http) {
      //console.log('dentro del factory ------------------------');

  	var logo = $resource( baseurl.getBaseUrl() + '/logoEmpresaService/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
      /*,getSaldos: {
        method: 'GET',
        url: baseurl.getBaseUrl() + "/saldos",
        isArray: true
      }*/
    });

    return {

      all: function(params, view) {
        if (params) {
            params.view = "BaseList";
          } else {
            params = [];
            params.view = "BaseList";
          }
        return logo.query(params);
      },

      get: function(id) {
        return logo.get({id: id});
      },

      create: function(attrs) {
        return new logo(attrs);
      },

      save: function(logo) {
        return (logo.id) ? logo.$update() : logo.$save();
      },

      remove: function(logo) {
        return logo.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      },

      guardarLogo: function(data) {
        $http.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
        return $http.post(baseurl.getBaseUrl() + "/logoEmpresaService/logo?", data);
      }
    };
  }]);

angular.module('qualita.parametros', [])
  .config(
    ['$stateProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$urlMatcherFactoryProvider',
      function ($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider, $urlMatcherFactoryProvider) {
        $urlMatcherFactoryProvider.strictMode(false);
        $urlRouterProvider.otherwise('/main');
        $stateProvider
          .state('app.parametros', {
            url: '/parametros',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Parámetros'
            }
          })
          .state('app.parametros.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'ParametrosIndexCtrl',
            ncyBreadcrumb: {
              label: 'Parámetros',
              description: 'Lista de parámetros'
            }
          })
          .state('app.parametros.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/parametros/views/form.html',
            controller: 'ParametrosFormController',
            ncyBreadcrumb: {
              parent: 'app.parametros',
              label: 'Editar Parámetro',
              description: 'Edición del parámetro #'
            },
            resolve: {
              parametroPrepService: parametroPrepService
            }
          })
          .state('app.parametros.view', {
            url: '/:id',
            templateUrl: 'qualita-components/parametros/views/form.html',
            controller: 'ParametrosFormController',
            ncyBreadcrumb: {
              parent: 'app.parametros',
              label: 'Ver Parámetro',
              description: 'Ver parámetro #'
            },
            resolve: {
              parametroPrepService: parametroPrepService
            }
          })

      }]);

function parametroPrepService(ParametrosFactory, $stateParams) {
  return ParametrosFactory.get($stateParams.id, "BaseForm").$promise;
}
parametroPrepService.$inject = ["ParametrosFactory", "$stateParams"];

/**
 * Created by codiumsa on 19/10/15.
 */
angular
  .module("qualita.parametros")
  .factory("ParametrosLangFactory", ParametrosLangFactory);

ParametrosLangFactory.$inject = [
  "$resource",
  "baseurl",
  "$translatePartialLoader",
  "$translate"
];

function ParametrosLangFactory(
  $resource,
  baseurl,
  $translatePartialLoader,
  $translate
) {
  var translationsNeeded = [
    "BACK",
    "CODE",
    "DESCRIPTION",
    "VALUE",
    "MODULO",
    "TIPO_DATO",
    "EDIT_PARAMETRO",
    "VIEW_PARAMETRO",
    "VALUE_TYPE",
    "PARAM"
  ];

  var service = {
    getTranslations: getTranslations
  };

  return service;

  function getTranslations() {
    $translatePartialLoader.addPart("parametros");
    return $translate.refresh().then(function() {
      return $translate(translationsNeeded);
    });
  }
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.parametros
 * @description
 * # parametros
 * Factory in the qualita.
 */
angular.module('qualita.parametros')
  .factory('ParametrosFactory', ['TiposFactory', 'filterFactory', '$resource', 'baseurl',
    function (TiposFactory, filterFactory, $resource, baseurl) {
      var bandera = false;
      var Parametro = $resource(baseurl.getBaseUrl() + '/parametros/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });
      var ParametroCheck = $resource(baseurl.getBaseUrl() + '/parametroscheck/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined ? view : 'BaseList';
          }
          return Parametro.query(params);
        },

        filterByCodigo: function (codigo, viewParam) {
          if (!viewParam) {
            viewParam = "Base";
          }
          var filter = {};
          filter.search = filterFactory.single({
            path: 'codigo',
            equals: codigo
          }).value();
          filter.view = viewParam
          return Parametro.query(filter);
        },

        getByCodigo: function (codigo) {
          var filter = {};
          filter.search = filterFactory.single({
            path: 'codigo',
            equals: codigo
          }).value();

          return Parametro.query(filter).$promise.then(function (response) {
            return response[0];
          });
        },

        filterByModulo: function (modulo) {
          var staticFilter = {};
          staticFilter.search = filterFactory.single({
            path: 'modulo.codigo',
            equals: modulo
          }).value();

          return Parametro.query(staticFilter);
        },

        get: function (id, view) {
          if (typeof (view) === 'undefined') view = "base";
          return Parametro.get({ id: id, view: view });
        },

        create: function (attrs) {
          return new Parametro(attrs);
        },

        createCheck: function (attrs) {
          return new ParametroCheck(attrs)
        },

        save: function (parametro) {
          return (parametro.id) ? parametro.$update() : parametro.$save();
        },

        remove: function (parametro) {
          return parametro.$remove();
        }
      };
    }]);

'use strict';


angular.module('qualita.parametros')
  .controller('ParametrosIndexCtrl', ["$scope", "ParametrosFactory", "filterFactory", "ReportTicketFactory", "$window", "ParametrosLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", function ($scope, ParametrosFactory, filterFactory, ReportTicketFactory, $window,
    ParametrosLangFactory, $filter, UtilFactory, CsvFactory, baseurl) {

    var defaultColumnOrder = ['id', 'codigo', 'descripcion', 'modulo.descripcion', 'tipoDato.descripcion', 'valor', 'valorTipo.descripcion'];

    ParametrosLangFactory.getTranslations().then(function (translations) {
      $scope.options = {
        'resource': 'parametros',
        'title': 'Parámetros',
        'factory': ParametrosFactory,
        'view': 'BaseList',
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false  },
          { 'data': 'codigo', 'title': $filter('translate')('PARAM'), visible: false },
          { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION') },
          { 'data': 'modulo.descripcion', 'title': $filter('translate')('MODULO'), 'type': 'combo', 'filterUrl': 'parametros/tipos' },
          { 'data': 'tipoDato.descripcion', 'title': $filter('translate')('TIPO_DATO') },
          {
            'data': 'valor', 'title': $filter('translate')('VALUE'), 'class': 'dt-center', 'render': function (data, type, row) {
              if (row.valorTipo != undefined) {
                return row.valorTipo.descripcion;
              } else {
                return data;
              }
            }
          },
          {
            'data': 'valorTipo.descripcion', 'title': 'Valor Tipo', 'class': 'dt-center', visible: false, 'render': function (data, type, row) {
              if (data == undefined)
                return "";
              else
                return data;
            }
          }
        ],
        //'detailRows': []
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'hideAddMenu': true,
        'hideRemoveMenu': true,
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoParametros', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("parametros", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ]
      };
    });

    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({ path: data, like: search })
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }
  }]);

'use strict';

angular.module('qualita.parametros')
  .controller('ParametrosFormController', ['$rootScope', '$scope', 'ParametrosFactory', 'formFactory', '$location',
    '$state', 'parametroPrepService', 'TiposTipoFactory', 'ModelTrimmer', 'ParametrosLangFactory', '$filter', 'notify',
    'filterFactory', 'CompraFactory','ListaPrecioVentaFactory',
    function($rootScope, $scope, ParametrosFactory, formFactory, $location,$state, parametroPrepService,
      TiposTipoFactory, ModelTrimmer, ParametrosLangFactory, $filter, notify, filterFactory, CompraFactory,ListaPrecioVentaFactory) {

      activate();
      var auxiliar;
      var auxiliar2;

      $scope.valueIsLock = false;
      var vm = this;

      function activate() {
        ParametrosLangFactory.getTranslations().then(function(translations) {
          $scope.translations = translations;
          if ($state.is("app.parametros.edit")) {
            activateEdit();
          } else if ($state.is("app.parametros.view")) {
            activateView();
          }
          $rootScope.isProcessing = false;
        });

      }

      function lockValor(){
        console.log("AQUI lockValor: ", $scope.model.codigo);
        if( ($scope.model.codigo == 'imc_requiere_embarque') ||
            ($scope.model.codigo == 'imc_requiere_proforma') ||
            ($scope.model.codigo == 'formato_nro_comprobante') ){
          $scope.valueIsLock = true;
        console.log("AQUI ENTRO TRUE");
        }

        //Parametro compras
        if($scope.model.codigo == 'generacion_codigo_compras'){

          var staticFilter = {};
          staticFilter.search = filterFactory.and([{
            path: 'estado.codigo',
            equals: 'compra_cerrada'
          }]).value();

          CompraFactory.all(staticFilter, "CompraList").$promise.then(function(compra){
              if(compra && compra.length>0){
                $scope.valueIsLock = true;
              }
          });

        }

      }

      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_PARAMETRO');
        $scope.model = parametroPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Parametro";
        if($scope.model.tipoDato) {
          if($scope.model.tipoDato.codigo == 'personalizado') {
            var tipotipo = $scope.model.tipoTipoValores;
            if (tipotipo) {
              $scope.opcionesTipo = TiposTipoFactory.get(tipotipo.codigo);
            }
            ListaPrecioVentaFactory.getListasActivas().then(function (response) {
              vm.listaPrecioVenta = response.data;
            });
            ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (response) {
              vm.valorParametroListaPrecioActual=response.valor;
            });
          } else if($scope.model.tipoDato.codigo == 'numerico') {
            if($scope.model.codigo == 'porcentaje_recepcion_pesables' || $scope.model.codigo == 'porcentaje_recepcion_unitarios'
                || $scope.model.codigo == 'porcentaje_bonificacion_familiar') {
              $scope.minValue = 0;
              $scope.maxValue = 100;
            } else if ($scope.model.codigo == 'max_dias_entrega_adelantada' || $scope.model.codigo == 'max_dias_entrega_atrasada') {
              $scope.minValue = 0;
            }
            $scope.model.valor = parseFloat($scope.model.valor);
          }
        }

        // Funcion para determinar si el campo valor debe bloquearse
        lockValor();
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_PARAMETRO');
        $scope.model = parametroPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Parametro";
        if($scope.model.tipoDato) {
          if($scope.model.tipoDato.codigo == 'personalizado') {
            var tipotipo = $scope.model.tipoTipoValores;
            if (tipotipo) {
              $scope.opcionesTipo = TiposTipoFactory.get(tipotipo.codigo);
            }
          } else if($scope.model.tipoDato.codigo == 'numerico') {
            $scope.model.valor = parseFloat($scope.model.valor);
          }
        }
        $scope.view = true;
      }

      $scope.submit = function(form) {
        if($scope.model.valorTipo != null){
          $scope.model.valor = $scope.model.valorTipo.descripcion;
        }

        var trimmedModel = ModelTrimmer.trimDetails($scope.model);
        if(trimmedModel.valor!=undefined) {
          trimmedModel.valor = trimmedModel.valor.toString();
        }


        if(trimmedModel.codigo == 'utilizar_precio_base' && vm.listaPrecioVenta.length >0){
            if(vm.valorParametroListaPrecioActual != trimmedModel.valor){
              var msg='';
              msg += '\n\n' + $filter('translate')('ERROR_UPDATE_PARAMETRO') + "\n";
              notify({ message: msg, classes: 'alert-danger', position: 'right' });
              return
            }
        }

        if(trimmedModel.codigo === 'cantidad_niveles_lineas_producto_activo_fijo' || trimmedModel.codigo === "cantidad_niveles_lineas_producto" ||
          trimmedModel.codigo === "dias_vencimiento_presupuesto_venta" || trimmedModel.codigo === "cantidad_factura_relacionar_nota_credito" ||
          trimmedModel.codigo === "controlar_vto_minimo_lote" || trimmedModel.codigo === "nivel_linea_producto_asociar_lista_precio") {
          var param = ParametrosFactory.createCheck(trimmedModel);
          ParametrosFactory.save(param).then(function(response) {
            $location.path('/parametros');
          }, function(e) {
            var msg = '';
            _.forEach(e.data, function(error) {
                if (error.constraint == 'nombre') {
                    msg += '\n\n' + $filter('translate')('SAME_NAME_PRODUCT_CLASS') + "\n"
                } else {
                    msg += '\n\n' + error.message + '.'
                }
            });
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
          });
          return;
        }

        formFactory.defaultNSFSubmit($scope.parametroForm, ParametrosFactory, trimmedModel).then(function(response) {
          $location.path('/parametros');
        }, function(e) {
          var msg = '';
          _.forEach(e.data, function(error) {
              if (error.constraint == 'nombre') {
                  msg += '\n\n' + $filter('translate')('SAME_NAME_PRODUCT_CLASS') + "\n"
              } else {
                  msg += '\n\n' + error.message + '.'
              }
          });
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
        });
      };

      $scope.cancel = function() {
        $location.path('/parametros');
      };

    }
  ]);

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.productos', ['ngResource', 'ui.tree', 'ngFileUpload'])
    .config(
        ['$stateProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$urlMatcherFactoryProvider', 'treeConfig',
            'laddaProvider',
            function ($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider, $urlMatcherFactoryProvider, treeConfig,
                laddaProvider) {

                $stateProvider
                    .state('app.marcas', {
                        url: '/marcas',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Marcas'
                        }
                    })
                    .state('app.marcas.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'MarcasIndexCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.marcas',
                            label: 'Marcas',
                            description: 'Listado de Marcas',
                            skip: true
                        }
                    })
                    .state('app.marcas.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/productos/views/marca/form.html',
                        controller: 'MarcasFormController',
                        ncyBreadcrumb: {
                            parent: 'app.marcas',
                            label: 'Nueva Marca',
                            description: 'Creación de una nueva marca'
                        },
                        resolve: {
                            marcaPrepService: function () { return; }
                        }
                    })
                    .state('app.marcas.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/productos/views/marca/form.html',
                        controller: 'MarcasFormController',
                        ncyBreadcrumb: {
                            parent: 'app.marcas',
                            label: 'Editar Marca',
                            description: 'Edición de la marca #'
                        },
                        resolve: {
                            marcaPrepService: marcaPrepService
                        }
                    })
                    .state('app.marcas.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/productos/views/marca/form.html',
                        controller: 'MarcasFormController',
                        ncyBreadcrumb: {
                            parent: 'app.marcas',
                            label: 'Ver Marca',
                            description: 'Ver la marca #'
                        },
                        resolve: {
                            marcaPrepService: marcaPrepService
                        }
                    })
                    .state('app.tipoimpuestos', {
                        url: '/tipoimpuestos',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Tipos de Impuestos'
                        }
                    })
                    .state('app.tipoimpuestos.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'TipoImpuestosIndexCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.tipoimpuestos',
                            label: 'Tipos de Impuestos',
                            description: 'Listado de Tipos de Impuestos',
                            skip: true
                        }
                    })
                    .state('app.tipoimpuestos.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/productos/views/tipoimpuestos/form.html',
                        controller: 'TipoImpuestosFormController',
                        ncyBreadcrumb: {
                            parent: 'app.tipoimpuestos',
                            label: 'Nuevo Tipo de Impuesto',
                            description: 'Creación de un nuevo tipo de impuesto'
                        },
                        resolve: {
                            tipoImpuestoPrepService: function () { return; }
                        }
                    })
                    .state('app.tipoimpuestos.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/productos/views/tipoimpuestos/form.html',
                        controller: 'TipoImpuestosFormController',
                        ncyBreadcrumb: {
                            parent: 'app.tipoimpuestos',
                            label: 'Editar Tipo de Impuesto',
                            description: 'Edición del Tipo Impuesto #'
                        },
                        resolve: {
                            tipoImpuestoPrepService: tipoImpuestoPrepService
                        }
                    })
                    .state('app.tipoimpuestos.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/productos/views/tipoimpuestos/form.html',
                        controller: 'TipoImpuestosFormController',
                        ncyBreadcrumb: {
                            parent: 'app.tipoimpuestos',
                            label: 'Ver Tipo de Impuesto',
                            description: 'Ver Tipo Impuesto #'
                        },
                        resolve: {
                            tipoImpuestoPrepService: tipoImpuestoPrepService
                        }
                    })
                    .state('app.lineasproducto', {
                        url: '/lineasproducto',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Líneas de Producto'
                        }
                    })
                    .state('app.lineasproducto.list', {
                        url: '',
                        templateUrl: 'qualita-components/productos/views/lineasproducto/list.html',
                        controller: 'LineasProductoIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.lineasproducto',
                            label: 'Líneas de Producto',
                            description: 'Listado de Líneas de Producto',
                            skip: true
                        }
                    })
                    .state('app.lineasproducto.new', {
                        url: '/new?padre',
                        templateUrl: 'qualita-components/productos/views/lineasproducto/form.html',
                        controller: 'LineasProductoFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.lineasproducto',
                            label: 'Nueva Línea de Producto',
                            description: 'Creación de una nueva línea de producto'
                        },
                        resolve: {
                            lineaProductoPrepService: function () { return; }
                        }
                    })
                    .state('app.lineasproducto.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/productos/views/lineasproducto/form.html',
                        controller: 'LineasProductoFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.lineasproducto',
                            label: 'Editar Línea de Producto',
                            description: 'Edición de la línea de producto #'
                        },
                        resolve: {
                            lineaProductoPrepService: lineaProductoPrepService
                        }
                    })
                    .state('app.lineasproducto.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/productos/views/lineasproducto/form.html',
                        controller: 'LineasProductoFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.lineasproducto',
                            label: 'Ver Línea de Producto',
                            description: 'Ver la línea de producto #'
                        },
                        resolve: {
                            lineaProductoPrepService: lineaProductoPrepService
                        }
                    })
                    .state('app.lineasproducto.details', {
                        url: '/:id/',
                        templateUrl: 'qualita-components/productos/views/lineasproducto/detalle.html',
                        controller: 'LineasProductoDetalleCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.lineasproducto',
                            label: 'Detalles de Línea de Producto',
                            description: 'Detalles de la línea de producto #'
                        },
                        resolve: {
                            lineaProductoPrepService: lineaProductoPrepService
                        }
                    })
                    .state('app.claseproductos', {
                        url: '/claseproductos',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Clases de Productos'
                        }
                    })
                    .state('app.claseproductos.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'ClaseProductosIndexCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.claseproductos',
                            label: 'Clases de Productos',
                            description: 'Listado de Clases de Productos',
                            skip: true
                        }
                    })
                    .state('app.claseproductos.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/productos/views/claseproducto/form.html',
                        controller: 'ClaseProductosFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.claseproductos',
                            label: 'Nueva Clase de Producto',
                            description: 'Creación de una nueva clase de producto'
                        },
                        resolve: {
                            claseProductoPrepService: function () { return; }
                        }
                    })
                    .state('app.claseproductos.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/productos/views/claseproducto/form.html',
                        controller: 'ClaseProductosFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.claseproductos',
                            label: 'Editar Clase de Producto',
                            description: 'Edición de la clase de producto #'
                        },
                        resolve: {
                            claseProductoPrepService: claseProductoPrepService
                        }
                    })
                    .state('app.claseproductos.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/productos/views/claseproducto/form.html',
                        controller: 'ClaseProductosFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.claseproductos',
                            label: 'Ver Clase de Producto',
                            description: 'Ver clase de producto #'
                        },
                        resolve: {
                            claseProductoPrepService: claseProductoPrepService
                        }
                    })
                    .state('app.unidadmedidas', {
                        url: '/unidadmedidas',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Unidades de Medida'
                        }
                    })
                    .state('app.unidadmedidas.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'UnidadMedidaIndexCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.unidadmedidas',
                            label: 'Unidades de Medida',
                            description: 'Listado de Unidades de Medida',
                            skip: true
                        }
                    })
                    .state('app.unidadmedidas.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/productos/views/unidadmedida/form.html',
                        controller: 'UnidadMedidaFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.unidadmedidas',
                            label: 'Nueva Unidad de Medida',
                            description: 'Creación de una nueva unidad de medida'
                        },
                        resolve: {
                            unidadMedidaPrepService: function () { return; }
                        }
                    })
                    .state('app.unidadmedidas.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/productos/views/unidadmedida/form.html',
                        controller: 'UnidadMedidaFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.unidadmedidas',
                            label: 'Editar Unidad de Medida',
                            description: 'Edición de la unidad de medida #'
                        },
                        resolve: {
                            unidadMedidaPrepService: unidadMedidaPrepService
                        }
                    })
                    .state('app.unidadmedidas.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/productos/views/unidadmedida/form.html',
                        controller: 'UnidadMedidaFormCtrl',
                        ncyBreadcrumb: {
                            parent: 'app.unidadmedidas',
                            label: 'Ver Unidad de Medida',
                            description: 'Ver unidad de medida #'
                        },
                        resolve: {
                            unidadMedidaPrepService: unidadMedidaPrepService
                        }
                    })
                    .state('app.tiposproveedores', {
                        url: '/tiposproveedores',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Tipos de Proveedores'
                        }
                    })
                    .state('app.tiposproveedores.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'TipoProveedoresIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.tiposproveedores',
                            label: 'Tipos de Proveedores',
                            description: 'Listado de Tipos de Proveedores',
                            skip: true
                        }
                    })
                    .state('app.tiposproveedores.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/productos/views/tiposproveedores/form.html',
                        controller: 'TipoProveedorFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.tiposproveedores',
                            label: 'Nuevo Tipo de Proveedor',
                            description: 'Creación de un nuevo tipo de proveedor'
                        },
                        resolve: {
                            tipoProveedorPrepService: function () { return; }
                        }
                    })
                    .state('app.tiposproveedores.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/productos/views/tiposproveedores/form.html',
                        controller: 'TipoProveedorFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.tiposproveedores',
                            label: 'Editar Tipo de Proveedor',
                            description: 'Edición del tipo de proveedor #'
                        },
                        resolve: {
                            tipoProveedorPrepService: tipoProveedorPrepService
                        }
                    })
                    .state('app.tiposproveedores.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/productos/views/tiposproveedores/form.html',
                        controller: 'TipoProveedorFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.tiposproveedores',
                            label: 'Ver Tipo de Proveedor',
                            description: 'Ver tipo de proveedor #'
                        },
                        resolve: {
                            tipoProveedorPrepService: tipoProveedorPrepService
                        }
                    })
                    .state('app.proveedores', {
                        url: '/proveedores',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Proveedores'
                        }
                    })
                    .state('app.proveedores.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'ProveedoresIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.proveedores',
                            label: 'Proveedores',
                            description: 'Listado de Proveedores',
                            skip: true
                        }
                    })
                    .state('app.proveedores.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/productos/views/proveedores/form.html',
                        controller: 'ProveedoresFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.proveedores',
                            label: 'Nuevo Proveedor',
                            description: 'Creación de un nuevo proveedor'
                        },
                        resolve: {
                            proveedorPrepService: function () { return; }
                        }
                    })
                    .state('app.proveedores.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/productos/views/proveedores/form.html',
                        controller: 'ProveedoresFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.proveedores',
                            label: 'Editar Proveedor',
                            description: 'Edición del proveedor #'
                        },
                        resolve: {
                            proveedorPrepService: proveedorPrepService
                        }
                    })
                    .state('app.proveedores.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/productos/views/proveedores/form.html',
                        controller: 'ProveedoresFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.proveedor',
                            label: 'Ver Proveedor',
                            description: 'Ver proveedor #'
                        },
                        resolve: {
                            proveedorPrepService: proveedorPrepService
                        }
                    })
                    .state('app.productos', {
                        url: '/productos',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Productos'
                        }
                    })
                    .state('app.productos.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'ProductosIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.productos',
                            label: 'Productos',
                            description: 'Listado de Productos',
                            skip: true
                        }
                    })
                    .state('app.productos.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/productos/views/producto/form.html',
                        controller: 'ProductosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.productos',
                            label: 'Nuevo Producto',
                            description: 'Creación de un nuevo producto'
                        },
                        resolve: {
                            productoPrepService: function () { return; }
                        }
                    })
                    .state('app.productos.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/productos/views/producto/form.html',
                        controller: 'ProductosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.productos',
                            label: 'Editar Producto',
                            description: 'Edición del producto #'
                        },
                        resolve: {
                            productoPrepService: productoPrepService
                        }
                    })
                    .state('app.productos.edit2', {
                        url: '/:id/edit2',
                        templateUrl: 'qualita-components/productos/views/producto/form2.html',
                        controller: 'ProductosFormDetailCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.productos',
                            label: 'Editar Producto',
                            description: 'Edición del producto #'
                        },
                        resolve: {
                            productoPrepService: productoPrepService
                        }
                    })
                    .state('app.productos.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/productos/views/producto/form.html',
                        controller: 'ProductosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.producto',
                            label: 'Ver Producto',
                            description: 'Ver producto #'
                        },
                        resolve: {
                            productoPrepService: productoPrepService
                        }
                    })
                    .state('app.relacionesproductos', {
                        url: '/relacionesproductos',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Relaciones entre Productos'
                        }
                    })
                    .state('app.relacionesproductos.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'RelacionesProductosIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.relacionesproductos',
                            label: 'Relaciones entre Productos',
                            description: 'Listado de Relaciones entre Productos',
                            skip: true
                        }
                    })
                    .state('app.relacionesproductos.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/productos/views/relacionesproductos/form.html',
                        controller: 'RelacionesProductosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.relacionesproductos',
                            label: 'Nueva Relación entre Productos',
                            description: 'Creación de una nueva Relación entre Productos'
                        },
                        resolve: {
                            relacionproductosPrepService: function () { return; }
                        }
                    })
                    .state('app.relacionesproductos.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/productos/views/relacionesproductos/form.html',
                        controller: 'RelacionesProductosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.relacionesproductos',
                            label: 'Editar Relación entre Productos',
                            description: 'Edición de la Relación entre Productos #'
                        },
                        resolve: {
                            relacionproductosPrepService: relacionproductosPrepService
                        }
                    })
                    .state('app.relacionesproductos.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/productos/views/relacionesproductos/form.html',
                        controller: 'RelacionesProductosFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.relacionesproductos',
                            label: 'Ver Relación entre Productos',
                            description: 'Ver la relación entre productos #'
                        },
                        resolve: {
                            relacionproductosPrepService: relacionproductosPrepService
                        }
                    })
                    .state('app.timbradoproveedor', {
                        url: '/timbradoproveedor',
                        templateUrl: 'views/template_base/index.html',
                        abstract: true,
                        ncyBreadcrumb: {
                            label: 'Timbrado de Proveedor'
                        }
                    })
                    .state('app.timbradoproveedor.list', {
                        url: '',
                        templateUrl: 'views/template_base/list.html',
                        controller: 'TimbadoProveedorIndexCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.timbradoproveedor',
                            label: 'Timbrado de Proveedor',
                            description: 'Listado de Timbrado de Proveedor',
                            skip: true
                        }
                    })
                    .state('app.timbradoproveedor.new', {
                        url: '/new',
                        templateUrl: 'qualita-components/productos/views/timbradoproveedor/form.html',
                        controller: 'TimbradoProveedorFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.timbradoproveedor',
                            label: 'Nuevo Timbrado de Proveedor',
                            description: 'Creación de una nuevo Timbrado de Proveedor'
                        },
                        resolve: {
                            timbradoProveedorPrepService: function () { return; }
                        }
                    })
                    .state('app.timbradoproveedor.edit', {
                        url: '/:id/edit',
                        templateUrl: 'qualita-components/productos/views/timbradoproveedor/form.html',
                        controller: 'TimbradoProveedorFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.timbradoproveedor',
                            label: 'Editar Timbrado de Proveedor',
                            description: 'Edición de el Timbrado de Proveedor #'
                        },
                        resolve: {
                            timbradoProveedorPrepService: timbradoProveedorPrepService
                        }
                    })
                    .state('app.timbradoproveedor.view', {
                        url: '/:id',
                        templateUrl: 'qualita-components/productos/views/timbradoproveedor/form.html',
                        controller: 'TimbradoProveedorFormCtrl',
                        controllerAs: 'vm',
                        ncyBreadcrumb: {
                            parent: 'app.timbradoproveedor',
                            label: 'Ver Timbrado de Proveedor',
                            description: 'Ver el timbrade de proveedor #'
                        },
                        resolve: {
                            timbradoProveedorPrepService: timbradoProveedorPrepService
                        }
                    })
                    .state("app.recursos", {
                        url: "/recursos",
                        templateUrl: "views/template_base/index.html",
                        abstract: true,
                        ncyBreadcrumb: {
                            label: "Recursos"
                        }
                    })
                    .state("app.recursos.list", {
                        url: "",
                        templateUrl: "views/template_base/list.html",
                        controller: "RecursoIndexCtrl",
                        controllerAs: "vm",
                        ncyBreadcrumb: {
                            parent: "app.recursos",
                            label: "Recursos",
                            description: "Listado de Recursos",
                            skip: true
                        }
                    })
                    .state("app.recursos.new", {
                        url: "/new",
                        templateUrl: "qualita-components/productos/views/recursos/form.html",
                        controller: "RecursoFormCtrl",
                        controllerAs: "vm",
                        ncyBreadcrumb: {
                            parent: "app.recursos",
                            label: "Nuevo Recurso",
                            description: "Creación de un nuevo Recurso"
                        },
                        resolve: {
                            recursoPrepService: function () {
                                return;
                            }
                        }
                    })
                    .state("app.recursos.edit", {
                        url: "/:id/edit",
                        templateUrl: "qualita-components/productos/views/recursos/form.html",
                        controller: "RecursoFormCtrl",
                        controllerAs: "vm",
                        ncyBreadcrumb: {
                            parent: "app.recursos",
                            label: "Editar Recursos",
                            description: "Edición del Recursos #"
                        },
                        resolve: {
                            recursoPrepService: recursoPrepService
                        }
                    })
                    .state("app.recursos.view", {
                        url: "/:id",
                        templateUrl: "qualita-components/productos/views/recursos/form.html",
                        controller: "RecursoFormCtrl",
                        controllerAs: "vm",
                        ncyBreadcrumb: {
                            parent: "app.recursos",
                            label: "Ver Recursos",
                            description: "Ver Recursos #"
                        },
                        resolve: {
                            recursoPrepService: recursoPrepService
                        }
                    });
            }
        ]);

function lineaProductoPrepService(LineasProductoFactory, $stateParams) {
    return LineasProductoFactory.get($stateParams.id, "LineaProductoForm").$promise;
}
lineaProductoPrepService.$inject = ["LineasProductoFactory", "$stateParams"];

function productoPrepService(ProductosFactory, $stateParams) {
    return ProductosFactory.get($stateParams.id, "ProductoForm").$promise;
}
productoPrepService.$inject = ["ProductosFactory", "$stateParams"];

function proveedorPrepService(ProveedoresFactory, $stateParams) {
    return ProveedoresFactory.get($stateParams.id, "ProveedorForm").$promise;
}
proveedorPrepService.$inject = ["ProveedoresFactory", "$stateParams"];

function relacionproductosPrepService(RelacionesProductosFactory, $stateParams) {
    return RelacionesProductosFactory.get($stateParams.id, "RelacionProductoForm").$promise;
}
relacionproductosPrepService.$inject = ["RelacionesProductosFactory", "$stateParams"];

function claseProductoPrepService(ClaseProductoFactory, $stateParams) {
    return ClaseProductoFactory.get($stateParams.id, "ClaseProductoForm").$promise;
}
claseProductoPrepService.$inject = ["ClaseProductoFactory", "$stateParams"];

function marcaPrepService(MarcasFactory, $stateParams) {
    return MarcasFactory.get($stateParams.id, "BaseForm").$promise;
}
marcaPrepService.$inject = ["MarcasFactory", "$stateParams"];

function tipoImpuestoPrepService(TipoImpuestosFactory, $stateParams) {
    return TipoImpuestosFactory.get($stateParams.id, "BaseForm").$promise;
}
tipoImpuestoPrepService.$inject = ["TipoImpuestosFactory", "$stateParams"];

function unidadMedidaPrepService(UnidadMedidaFactory, $stateParams) {
    return UnidadMedidaFactory.get($stateParams.id, "UnidadMedidaForm").$promise;
}
unidadMedidaPrepService.$inject = ["UnidadMedidaFactory", "$stateParams"];

function timbradoProveedorPrepService(TimbradoProveedorFactory, $stateParams) {
    return TimbradoProveedorFactory.get($stateParams.id, "TimbradoProveedorForm").$promise;
}
timbradoProveedorPrepService.$inject = ["TimbradoProveedorFactory", "$stateParams"];

function tipoProveedorPrepService(TipoProveedorFactory, $stateParams) {
    return TipoProveedorFactory.get($stateParams.id, "BaseForm").$promise;
}
tipoProveedorPrepService.$inject = ["TipoProveedorFactory", "$stateParams"];

function recursoPrepService(RecursoFactory, $stateParams) {
    return RecursoFactory.get($stateParams.id, "BaseForm").$promise;
}
recursoPrepService.$inject = ["RecursoFactory", "$stateParams"];



/**
 * Created by codiumsa on 19/10/15.
 */
angular
    .module("qualita.productos")
    .factory("ProductosLangFactory", ProductosLangFactory);

ProductosLangFactory.$inject = [
    "$resource",
    "baseurl",
    "$translatePartialLoader",
    "$translate"
];

function ProductosLangFactory(
    $resource,
    baseurl,
    $translatePartialLoader,
    $translate
) {
    var translationsNeeded = [
        "BACK",
        "PRODUCTO",
        "ID",
        "NOMBRE",
        "AUTOMATIC_EAN",
        "CALCULATE_DIGIT",
        "ACTIVE_QUESTION",
        "ADMINISTRATION_TYPE",
        "PRODUCT_TYPE",
        "NEW_PRODUCT_CLASS",
        "EDIT_PRODUCT_CLASS",
        "VIEW_PRODUCT_CLASS",
        "SAME_NAME_PRODUCT_CLASS",
        "WRONG_FIELDS",
        "PRODUCT_LINE_DETAIL",
        "PRODUCT_LINE_ERROR_MESSAGE_1",
        "PRODUCT_LINE_ERROR_MESSAGE_2",
        "CONFIRM_DELETE",
        "DELETE_QUESTION",
        "ERROR_DELETE_PRODUCT_LINE",
        "NEW_PRODUCT_LINE",
        "EDIT_PRODUCT_LINE",
        "VIEW_PRODUCT_LINE",
        "CLASS",
        "BRAND",
        "MARCA_ERROR_DELET",
        "NEW_MARCA",
        "EDIT_MARCA",
        "VIEW_MARCA",
        "MARCA_EXIST",
        "PROVEEDOR",
        "CHECK_EXPIRATION_DATE",
        "PURCHASE_ORDER",
        "MESSURE_UNIT",
        "CLASS",
        "LINE",
        "PRODUCT_DETAIL",
        "INTERNAL_CODE",
        "BAR_CODE",
        "CLASS",
        "PURCHASE_ITEM",
        "SALE_ITEM",
        "STOCK_CONTROL_Q",
        "SALE_CONTROL_Q",
        "NEW_PRODUCT",
        "EDIT_PRODUCT",
        "VIEW_PRODUCT",
        "PRODUCT_EXIST",
        "REPEATED_CODE_BAR",
        "ERROR_CODE_BAR",
        "WRONG_VERIFICATION_DIGIT",
        "NEW_PROVEEDOR",
        "EDIT_PROVEEDOR",
        "VIEW_PROVEEDOR",
        "PROVEEDOR_EXISTS",
        "PROVEEDOR_DOC_EXISTS",
        "NEW_PRODUCT_RELATION",
        "EDIT_PRODUCT_RELATION",
        "VIEW_PRODUCT_RELATION",
        "RELATION_TYPE",
        "PRINCIPAL_PRODUCT",
        "DETAIL_PRODUCT",
        "NEW_TIMBRADO_PROVEEDOR",
        "EDIT_TIMBRADO_PROVEEDOR",
        "VIEW_TIMBRADO_PROVEEDOR",
        "PORCENTAGE",
        "ERROR_DELETE_TAX",
        "NEW_TAX_TYPE",
        "EDIT_TAX_TYPE",
        "VIEW_TAX_TYPE",
        "TAXT_TYPE_EXISTS",
        "NEW_UNIT",
        "EDIT_UNIT",
        "VIEW_UNIT",
        "UNIT_NOT_SAVED",
        "AUTOMATIC_EAN_LABEL",
        "SECOND_DIGIT",
        "PRODUCT_LINE_DAUGTHERS",
        "CALCULATE_DIGIT_LABEL",
        "NEW",
        "SHOW_ALL_FAMILIS",
        "PRODUCT_LINE",
        "GO_BACK",
        "SEARCH_RESULTS",
        "SEARCH",
        "PRODUCT_LINE_DAUGTHERS",
        "SHOW_ALL_FAMILIS",
        "FATHER_LINE",
        "LAST_LEVEL_Q",
        "LAST_LEVEL_INVALID",
        "PRODUCT",
        "PRODUCT_CLASS",
        "BALANS_CODE",
        "PRINCIPAL_BAR_CODE",
        "PRINCIPAL_UNIT",
        "SECONDARY_UNIT",
        "TAX_TYPE",
        "PRINCIPAL_PROVEEDOR",
        "PRINCIPAL_PROVEEDOR_SUCURSAL",
        "BASE_UNIT",
        "CONTROL_CRITERIA",
        "EXPIRATION_DATE_CONTROL_Q",
        "IS_BUY_ITEM_Q",
        "CONTROL_STOCK_Q",
        "PURCHASE_ORDER_INCLUDE_Q",
        "IS_SALE_ITEM_Q",
        "IS_CONTROLED_SALE",
        "SUCURSAL_AND_BUSINESS_UNIT",
        "PRODUCT_COMPOSITION",
        "RELATED_PRODUCTS",
        "ASSOCIATIONS",
        "USES",
        "ADITIONALS",
        "ATRIBUTES_OF",
        "ATTACHMENTS",
        "GENERAL",
        "OBLIGATORY_FIELD",
        "FILE",
        "PROVEEDORES",
        "ADMINISTRATION_FORM",
        "PROVEEDORES",
        "ADMINISTRATION_FORM",
        "PROVEEDOR_CLASS",
        "PERSON_TYPE",
        "WEBSITE",
        "OBSERVATIONS",
        "SUCRSALES",
        "UBICACION GEOGRAFICA (SIFEN)",
        "EMAIL",
        "SUCURSAL",
        "ADD",
        "FANTASY_NAME",
        "DOCUMENT_TYPE",
        "DOCUMENT_NUMBER",
        "PROVEEDOR_GROUP",
        "CONTROL_TIMBRA_NUMBER_Q",
        "COD_SUC",
        "EMISSION_POINT",
        "RELATION_FACTOR",
        "ALLOWS_DECIMALS",
        "BASE",
        "CONTAINED_UNIT",
        "CONTAINED_QUANITY",
        "ABBREVIATION",
        "PERSON_TYPE",
        "DATE_FROM",
        "DATE_TO",
        "NUMBER_TIMBRADO",
        "STATUS",
        "PROVEEDOR_CLASS",
        "DIRECCION",
        "TELEFONO",
        "FAILED_DELETE_UNIDAD_MEDIDA",
        "DATE_FROM_DATE_TO_ERROR",
        "ADD_CHILDREN",
        "CODE",
        "ACTIVE",
        "DE_PESO",
        "LINEA_PRODUCTO_EXISTS",
        "FORBIDDEN",
        "PROVEEDOR_SUCURSAL",
        "SCALE_CODE_EXIST",
        "DESCRIPCION",
        "TIPO_PROVEEDOR",
        "FAILED_DELETE_TIPO_PROVEEDOR",
        "NEW_TIPO_PROVEEDOR",
        "EDIT_TIPO_PROVEEDOR",
        "VIEW_TIPO_PROVEEDOR",
        "NUMERO",
        "FECHA_DESDE",
        "FECHA_HASTA",
        "TIENE_DEPRECIACION_Q",
        "CUENTA_BANCARIA_EXISTS",
        "PRODUCTO_IMPORTACION",
        "ERROR_SIN_SUCURSALES",
        "TIMBRADO_VIRTUAL_ELECTRONICO"
    ];

    var service = {
        getTranslations: getTranslations
    };

    return service;

    function getTranslations() {
        $translatePartialLoader.addPart("productos");
        return $translate.refresh().then(function() {
            return $translate(translationsNeeded);
        });
    }
}
'use strict';


angular.module('qualita.productos')
  .controller('MarcasIndexCtrl', ["$scope", "MarcasFactory", "filterFactory", "ReportTicketFactory", "$window", "ProductosFactory", "ProductosLangFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter", function ($scope, MarcasFactory, filterFactory, ReportTicketFactory, $window,
    ProductosFactory, ProductosLangFactory, CsvFactory, UtilFactory, baseurl, $filter) {

    var defaultColumnOrder = ['id','descripcion'];
    ProductosLangFactory.getTranslations().then(function(translations) {
      $scope.options = {
        'resource': 'marcas',
        'title': 'Marcas',
        'view': 'BaseList',
        'factory': MarcasFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('MARCA_ERROR_DELETE'),
        'columns': [
          {'data': 'id', 'title': $filter('translate')('CODE'), visible: false },
          {'data': 'descripcion', 'title': $filter('translate')('NOMBRE')}
        ],
        'hasOptions' : true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = createFilters($scope.options.getFilters());
                  ReportTicketFactory.ticket('listadoMarcas', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                    $window.open($scope.pdfDownloadURL, '_blank');
                  });
                }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("marcas", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ]
        };
      });
      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({path: data, like: search})
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
  }]);

'use strict';

angular.module('qualita.productos')
  .controller('MarcasFormController', ['$rootScope', '$scope', 'MarcasFactory', 'formFactory', '$location',
    'marcaPrepService', '$state', 'notify','ProductosFactory', 'ProductosLangFactory', '$filter',
    function($rootScope, $scope, MarcasFactory, formFactory, $location,
      marcaPrepService, $state, notify,ProductosFactory, ProductosLangFactory, $filter) {

      activate();

      function activate() {
        ProductosLangFactory.getTranslations().then(function(translations) {
          $scope.translations = translations;
          $scope.schema = MarcasFactory.schema();
          $scope.options = formFactory.defaultOptions();

          if ($state.is("app.marcas.new")) {
            activateNew();
          } else if ($state.is("app.marcas.edit")) {
            activateEdit();
          } else if ($state.is("app.marcas.view")) {
            activateView();
          }
          $rootScope.isProcessing = false;
        });

      }

      function activateNew() {
        $scope.title = $filter('translate')('NEW_MARCA');
        $scope.form = MarcasFactory.form('new');
        $scope.model = {};
        $scope.view = false;
        $scope.schema.readonly = false;
      }


      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_MARCA');
        $scope.form = MarcasFactory.form('edit');
        $scope.model = marcaPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Marca";
        $scope.view = false;
        $scope.schema.readonly = false;
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_MARCA');
        $scope.form = MarcasFactory.form('view');
        $scope.options = formFactory.defaultViewOptions();
        $scope.model = marcaPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Marca";
        $scope.view = true;
        $scope.schema.readonly = true;
      }

      $scope.submit = function(form) {
        formFactory.defaultSubmit('marcas', $scope, form, MarcasFactory, undefined, errorHandler);
      };

      function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
          if (error.constraint == 'descripcion') {
            msg += '\n\n' + $filter('translate')('MARCA_EXIST')+"\n";
          } else {
            msg += '\n\n' + error.message + '.';
          }
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      }

      $scope.cancel = function() {
        $location.path('/marcas');
      };

    }
  ]);
'use strict';


angular.module('qualita.productos')
    .controller('TimbadoProveedorIndexCtrl', ["$scope", "TimbradoProveedorFactory", "ProductosFactory", "ProductosLangFactory", "$filter", "filterFactory", "ReportTicketFactory", "$window", "UtilFactory", "CsvFactory", "baseurl", "ParametrosFactory", "$modal", "$state", "notify", function ($scope, TimbradoProveedorFactory, ProductosFactory, ProductosLangFactory, $filter,
        filterFactory, ReportTicketFactory, $window, UtilFactory, CsvFactory, baseurl, ParametrosFactory, $modal, $state, notify) {

        var defaultColumnOrder = ['id','codigo','descripcion','abreviatura','afectaCosto','calculaBaseImponible'];

        var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
        var enviarAContabilidad = '';

        promise1.then(function(response) {
            enviarAContabilidad = response.valorTipo.codigo;
        });

        var defaultColumnOrder = ['id', 'proveedor.nombre', 'sucursalProveedor.descripcion', 'numero', 'fechaDesde', 'fechaHasta', 'activo'];
        ProductosLangFactory.getTranslations().then(function (translations) {
            $scope.options = {
                'resource': 'timbradoproveedor',
                'title': 'Timbrado de Proveedor',
                'factory': TimbradoProveedorFactory,
                'view': 'TimbradoProveedorList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': 'No se pudo borrar el Timbrado de Proveedor.',
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false  },
                    { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX') },
                    { 'data': 'sucursalProveedor.descripcion', 'title': $filter('translate')('PROVEEDOR_SUCURSAL') },
                    { 'data': 'numero', 'title': $filter('translate')('NUMBER_TIMBRADO'), 'class': 'dt-right' },
                    { 'data': 'fechaDesde', 'title': $filter('translate')('DATE_FROM'), renderWith: 'dateRender', dateFormat: 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'fechaHasta', 'title': $filter('translate')('DATE_TO'), renderWith: 'dateRender', dateFormat: 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraMenuOptions': [{
                    'title': 'Reporte',
                    'icon': 'glyphicon glyphicon-file',
                    'showCondition': function () { return true; },
                    'action': function () {
                        var filters = createFilters($scope.options.getFilters());
                        ReportTicketFactory.ticket('listadoTimbradoProveedor', filters, $scope.options.tableAjaxParams,
                            $scope.options.currentColumnOrder).then(function (genTicket) {
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open($scope.pdfDownloadURL, '_blank');
                            });
                    }
                },
                {
                    'title': 'CSV',
                    'icon': 'glyphicon glyphicon-export',
                    'showCondition': function () { return true; },
                    'action': function () {
                        var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                        CsvFactory.csv("timbradoproveedor", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                            .then(function (response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                    }
                }

                ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
                        functionName: 'reenviar',
                        customAttribute: ['enviadoAContabilidad', 'id'],
                        functionDef: function (atributo) {
            
                          var idTimbradoProveedor = atributo[1];
            
                          TimbradoProveedorFactory.get(idTimbradoProveedor, "TimbradoProveedorList").$promise.then(function (
                            response
                          ) {
                            $scope.tituloModal = $filter('translate')('REENVIAR_TIMBRADO_PROVEEDOR_Q');
                            $scope.mensajeModal = $filter('translate')('REENVIAR_TIMBRADO_PROVEEDOR_MSG');
                            var modalInstance = $modal.open({
                              templateUrl: 'views/directives/confirmation-modal.html',
                              scope: $scope
                            });
            
                            $scope.cancel = function () {
                              modalInstance.dismiss('cancel');
                            };
            
                            $scope.ok = function (id) {
                                TimbradoProveedorFactory.reenviar(response.id).then(function (response) {
                                //ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                                  if (enviarAContabilidad == 'si') {
                                    if (response.data.enviadoAContabilidad == false) {
                                      notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                    }
                                  }
                                //});
                                $state.go($state.current, {}, { reload: true });
                              }, function (error) {
                                var msg = "";
                                if (error.data && error.data.length > 0 && error.data[0].message) {
                                  msg = error.data[0].message;
                                } else {
                                  msg = $filter('translate')('REENVIAR_PLANILLA_FAILED');
                                }
            
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                              });
                              modalInstance.close();
                            }
                          });
                        },
                        conditionName: 'canReenviar',
                        conditionDef: function (atributo) {
                          var enviadoAContabilidad = atributo[0];

                          if (enviarAContabilidad == 'si') {
                            if (enviadoAContabilidad === "No") {
                              return true;
                            }
                          }
                        }
                    }
                ]
            };
        });

        var createFilters = function (filters) {
            var filtersArr = [];
            _.each(filters, function (search, data) {
                search = search.split("~");
                if (search.length > 1) {
                    /* El parámetro es un rago */

                    var isDate = search[0].split("/");

                    if (isDate.length > 1) {
                        /* Es un rago de fechas */

                        filtersArr.push({path: data, equalOrAfter: search[0]})
                        filtersArr.push({path: data, equalOrBefore: search[1]})
                    } else {
                        /* El un rago numérco */

                        filtersArr.push({path: data, greaterOrEqual: search[0]})
                        filtersArr.push({path: data, lessOrEqual: search[1]})
                    }
                } else {
                    /* El parametro no es un rago */
                    if(search[0] === 'No' && data === 'activo'){
                        search[0] = 'false';
                    }
                    if(search[0] === 'Sí' && data === 'activo'){
                        search[0] = 'true';
                    }
        
                    filtersArr.push({path: data, like: search[0]})
                }
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        }
    }]);
'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:TimbradoProveedorFormCtrl
 * @description
 * # TimbradoProveedorFormCtrl
 * Controller of the rasApp
 */
angular.module('qualita.productos')
  .controller('TimbradoProveedorFormCtrl', TimbradoProveedorFormCtrl);

TimbradoProveedorFormCtrl.$inject = ['$scope', '$rootScope', 'TimbradoProveedorFactory',
  '$location', '$state', 'ModelTrimmer', 'timbradoProveedorPrepService', 'ProductosLangFactory', 'notify', 'formFactory',
  '$filter', 'ProveedorSucursalesFactory', 'ProveedoresFactory', 'filterFactory', '$timeout',
  'ParametrosFactory'
];

function TimbradoProveedorFormCtrl($scope, $rootScope, TimbradoProveedorFactory, $location, $state,
  ModelTrimmer, timbradoProveedorPrepService, ProductosLangFactory, notify, formFactory, $filter,
  ProveedorSucursalesFactory, ProveedoresFactory, filterFactory, $timeout, ParametrosFactory) {

  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.sucursales;
  vm.sucursalPorProveedor = sucursalPorProveedor;

  activate();

  function activate() {
    ProductosLangFactory.getTranslations().then(function (translations) {
      vm.translations = translations;
      if ($state.is("app.timbradoproveedor.new")) {
        activateNew();
      } else if ($state.is("app.timbradoproveedor.edit")) {
        activateEdit();
      } else if ($state.is("app.timbradoproveedor.view")) {
        activateView();
      }

      $rootScope.isProcessing = false;
      vm.submited = false;
    });

    /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
    vm.staticFilterProv = [{path: "activo",equals: true}];
    vm.proveedoresFactory = ProveedoresFactory;

    $timeout(function () {
      $scope.$broadcast('formOpened');
    }, 20);

  }

  $scope.$watch("vm.timbradoProveedor.proveedor",function(newValue,oldValue) {
    if (newValue != undefined) {
      sucursalPorProveedor();
    }
  });

  function sucursalPorProveedor() {
    /* Se muestran solo las secciones activas y por departamento */
    //vm.timbradoProveedor = ""
    console.log("ENTRO SUCURSAL PROVEEDOR");
    var proveedorFilter = {};
    proveedorFilter.search = filterFactory.and([
      {
        path: 'proveedor.id',
        equals: vm.timbradoProveedor.proveedor.id
      }
    ]).value();
    proveedorFilter.view = 'BaseList';
    ProveedorSucursalesFactory.all(proveedorFilter).$promise.then(function (data) {
      vm.sucursales = data;
      //console.log(data);
    });

  }


  function activateNew() {
    vm.title = $filter('translate')('NEW_TIMBRADO_PROVEEDOR');
    vm.timbradoProveedor = { activo: true };
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_TIMBRADO_PROVEEDOR');
    vm.timbradoProveedor = timbradoProveedorPrepService;
    $scope.entidad = 'timbradoProveedor';
    $scope.entidadId = vm.timbradoProveedor.id;
    vm.timbradoProveedor.fechaDesde = new Date(vm.timbradoProveedor.fechaDesde);
    vm.timbradoProveedor.fechaHasta = new Date(vm.timbradoProveedor.fechaHasta);

  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_TIMBRADO_PROVEEDOR');
    vm.timbradoProveedor = timbradoProveedorPrepService;
    vm.entidadId = vm.timbradoProveedor.id;
    vm.entidad = "timbradoProveedor";
    $scope.view = true;

    vm.timbradoProveedor.fechaDesde = new Date(vm.timbradoProveedor.fechaDesde);
    vm.timbradoProveedor.fechaHasta = new Date(vm.timbradoProveedor.fechaHasta);
  }

  function cancel() {
    $location.path("/timbradoproveedor");
  }

  function submit() {
    vm.submited = true;
    var trimmedSeccionesEmpresa = ModelTrimmer.trimDetails(vm.timbradoProveedor);

    if (!angular.isString(vm.timbradoProveedor.fechaDesde)) {
      var desde = moment(vm.timbradoProveedor.fechaDesde).format('DD/MM/YYYY');
    } else {
      var desde = $scope.model.fechaDesde;
    }
    if (!angular.isString(vm.timbradoProveedor.fechaHasta)) {
      var hasta = moment(vm.timbradoProveedor.fechaHasta).format('DD/MM/YYYY');
    } else {
      var hasta = vm.timbradoProveedor.fechaHasta;
    }

    var d1 = parseInt(desde.substring(0, 2));
    var d2 = parseInt(desde.substring(3, 5));
    var d3 = parseInt(desde.substring(6, 10));
    var h1 = parseInt(hasta.substring(0, 2));
    var h2 = parseInt(hasta.substring(3, 5));
    var h3 = parseInt(hasta.substring(6, 10));


    if (d3 < h3 || (d2 < h2 && d3 === h3) || (d1 < h1 && d2 === h2 && d3 === h3)) {
      //formFactory.defaultSubmit('timbradoproveedor', $scope, form, TimbradoProveedorFactory, undefined, errorHandler);
      formFactory.defaultNSFSubmit($scope.TimbradoProveedorForm, TimbradoProveedorFactory, trimmedSeccionesEmpresa, errorHandler).then(function (response) {
        $location.path('/timbradoproveedor');
        ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
          if (parametro.valorTipo.codigo == 'si') {
              if(response.enviadoAContabilidad == false){
                  notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
              }
          }
        });
      }, function (error) {
        console.log(error);
      });
    } else {
      notify({
        message: $filter('translate')('DATE_FROM_DATE_TO_ERROR'),
        classes: 'alert-danger',
        position: 'right'
      });
    }


  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function (error) {
      msg += '\n\n' + error.message + '.';

      if(error.message == "Not unique field: id_proveedor, numero, id_sucursal_proveedor"){
        msg = "Ya existe un timbrado con el mismo proveedor, numero y sucursal"
      }
    });
    notify({
      message: msg,
      classes: 'alert-danger',
      position: 'right'
    });
  }

  $scope.resource = 'timbradoproveedor'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}




'use strict';

/**
 * @ngdoc service
 * @name qualita.marcas
 * @description
 * # marcas
 * Factory in the qualita.
 */
angular.module('qualita')
  .factory('UnidadMedidaFactory', ['$resource', 'baseurl', '$http', function($resource,  baseurl,$http) {

  	var UnidadMedida= $resource( baseurl.getBaseUrl() + '/unidadmedidas/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      },
      getByBase: {
        method: 'GET',
        url: baseurl.getBaseUrl() + '/unidadmedidas/frombase/:id',
        isArray: true
    }
    });

    return {
      all: function(params) {
        return UnidadMedida.query(params);
      },

      allView: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'base';
        } else {
          params = [];
          params.view = view != undefined ? view : 'base';
        }
        return UnidadMedida.query(params);
      },

      get: function(id,view) {
        if (typeof(view)==='undefined') view = "base";
        return UnidadMedida.get({id: id, view: view});
      },

      getByBase: function(id) {
        return UnidadMedida.getByBase({id: id});
      },

      create: function(attrs) {
        return new UnidadMedida(attrs);
      },

      save: function(unidadMedida) {
        console.log(unidadMedida);
        return (unidadMedida.id) ? unidadMedida.$update() : unidadMedida.$save();
      },

      remove: function(unidadMedida) {
        return unidadMedida.$remove();
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      }
    };
  }]);

'use strict';


angular.module('qualita.productos')
    .controller('UnidadMedidaIndexCtrl', ["$scope", "UnidadMedidaFactory", "filterFactory", "notify", "AuthorizationService", "ReportTicketFactory", "$window", "UtilFactory", "ProductosFactory", "ProductosLangFactory", "CsvFactory", "baseurl", "$filter", function ($scope, UnidadMedidaFactory, filterFactory, notify, AuthorizationService,
        ReportTicketFactory, $window, UtilFactory, ProductosFactory, ProductosLangFactory, CsvFactory, baseurl, $filter) {

        var defaultColumnOrder = ['id', 'descripcion', 'abreviatura', 'permiteDecimales', 'esBase', 'dePeso', 'cantidad', 'unidadContenida.abreviatura'];

        ProductosLangFactory.getTranslations().then(function (translations) {
            $scope.options = {
                'resource': 'unidadmedidas',
                'title': 'Unidades de Medida',
                'factory': UnidadMedidaFactory,
                'view': 'UnidadMedidaList',
                'defaultColumnOrder': defaultColumnOrder,
                //'staticFilter': staticFilter,
                'failedDeleteError': $filter('translate')('FAILED_DELETE_UNIDAD_MEDIDA'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false  },
                    { 'data': 'descripcion', 'title': $filter('translate')('NOMBRE') },
                    { 'data': 'abreviatura', 'title': $filter('translate')('ABBREVIATION') },
                    { 'data': 'permiteDecimales', 'title': $filter('translate')('ALLOWS_DECIMALS'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
                    { 'data': 'esBase', 'title': $filter('translate')('BASE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
                    { 'data': 'dePeso', 'title': $filter('translate')('DE_PESO'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
                    { 'data': 'cantidad', 'title': $filter('translate')('CONTAINED_QUANITY'), 'class': 'dt-right'  },
                    { 'data': 'unidadContenida.abreviatura', 'title': $filter('translate')('CONTAINED_UNIT'), 'renderWith': 'emptyRender' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraMenuOptions': [{
                    'title': 'Reporte',
                    'icon': 'glyphicon glyphicon-file',
                    'showCondition': function () { return true; },
                    'action': function () {
                        var filters = createFilters($scope.options.getFilters());
                        ReportTicketFactory.ticket('listadoUnidadesMedida', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                            $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                            $window.open($scope.pdfDownloadURL, '_blank');
                        });
                    }
                },
                {
                    'title': 'CSV',
                    'icon': 'glyphicon glyphicon-export',
                    'showCondition': function () { return true; },
                    'action': function () {
                        var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                        CsvFactory.csv("unidadmedidas", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                            .then(function (response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                    }
                }
                ],
                'extraRemoveConditions': function (item) {
                    console.log(item);
                    if(item != undefined){
                    if (AuthorizationService.hasPermission('update_unidadmedidas')) {
                        if (item.esBase != "No") {
                            if (AuthorizationService.hasPermission('admin_unidadmedidas')) {
                                if (item.descripcion === "kilogramos" || item.descripcion === "gramos") {
                                    return false;
                                }
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            if (item.descripcion === "kilogramos" || item.descripcion === "gramos") {
                                return false;
                            }
                            return true;
                        }
                    } else {
                        return false;
                    }
                }
                },
                'extraEditConditions': function (item) {
                    if(item != undefined){
                    if (AuthorizationService.hasPermission('update_unidadmedidas')) {
                        if (item.esBase != "No") {
                            if (AuthorizationService.hasPermission('admin_unidadmedidas')) {
                                if (item.descripcion === "kilogramos" || item.descripcion === "gramos") {
                                    return false;
                                }
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            if (item.descripcion === "kilogramos" || item.descripcion === "gramos") {
                                return false;
                            }
                            return true;
                        }
                    } else {
                        return false;
                    }
                }
                },
            };
        });
        var createFilters = function (filters) {
            var filtersArr = [];
            _.each(filters, function (search, data) {
                console.log("data ", data);
              if(search === 'No' && (data === 'permiteDecimales' || data === 'esBase' || data === 'dePeso')){
                search = 'false';
              }
              if(search === 'Sí' && (data === 'permiteDecimales' || data === 'esBase' || data === 'dePeso')){
                search = 'true';
              }
              filtersArr.push({path: data, like: search})
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
          }

    }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.productos')
        .controller('UnidadMedidaFormCtrl', UnidadMedidaFormCtrl);

UnidadMedidaFormCtrl.$inject = ['$rootScope', '$scope', 'UnidadMedidaFactory', 'filterFactory', '$q', '$log', '$location',
    '$stateParams', 'notify', 'AuthorizationService', '$state', 'unidadMedidaPrepService', 'ParametrosFactory', 'ProductosFactory',
    'ProductosLangFactory', '$filter'];

function UnidadMedidaFormCtrl($rootScope, $scope, UnidadMedidaFactory, filterFactory, $q, $log, $location, $stateParams,
        notify, AuthorizationService, $state, unidadMedidaPrepService, ParametrosFactory, ProductosFactory, ProductosLangFactory, $filter) {

    activate();

    function activate() {
        ProductosLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            $scope.permisobase = AuthorizationService.hasPermission('admin_unidadmedidas');
            $scope.valorTipo = "";
            ParametrosFactory.getByCodigo('generacion_factura_electronica').then(function (response) {
                $scope.valorTipo = response.valorTipo.codigo;
                if ($scope.valorTipo === "si") {
                    $scope.datoVirtual = true;
                } else {
                    $scope.datoVirtual = false;
                }
            });
            if ($state.is("app.unidadmedidas.new")) {
                activateNew();
            } else if ($state.is("app.unidadmedidas.edit")) {
                activateEdit();
            } else if ($state.is("app.unidadmedidas.view")) {
                activateView();
            }
            $scope.cancel = cancel;

            $scope.submit = submit;

            $scope.changeCheck = changeCheck;


            $rootScope.isProcessing = false;
        });

    }

    function activateNew() {
        $scope.title = $filter('translate')('NEW_UNIT');
        $scope.unidadmedida = {};

        var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
            path: 'esBase',
            like: 'true'
        }]).value();

        UnidadMedidaFactory.allView(filtroActivo, 'BaseList').$promise.then(function (data) {
            $scope.unidades = data;
            $scope.unidadmedida.unidadContenida = $scope.unidades[0];
        });
    }

    function activateEdit() {
        $scope.unidadmedida = unidadMedidaPrepService;
        $scope.entidadId = $scope.unidadmedida.id;
        $scope.entidad = "UnidadMedida";
        $scope.title = $filter('translate')('EDIT_UNIT');
        if ($scope.unidadmedida.esBase && !$scope.permisobase) {
            $location.path("/unidadmedidas");
        }
        var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
            path: 'esBase',
            like: 'true'
        }]).value();

        UnidadMedidaFactory.allView(filtroActivo, 'BaseList').$promise.then(function (data) {
            $scope.unidades = data;
            for (var i = 0; i < $scope.unidades.length; i++) {
                if ($scope.unidades[i].id === $scope.unidadmedida.id) {
                    $scope.unidades.splice(i, 1);
                    i = 0;
                }/*else {
                 if($scope.unidades[i].esBase===false){
                 $scope.unidades.splice(i,1);
                 i=0;
                 }}*/
            }
        });

        $scope.descripcionvacia = false;
        $scope.errorcantidad = false;
        $scope.errorabreviatura = false;
    }

    function activateView() {
        $scope.unidadmedida = unidadMedidaPrepService;
        $scope.entidadId = $scope.unidadmedida.id;
        $scope.entidad = "UnidadMedida";
        $scope.title = $filter('translate')('VIEW_UNIT');
        if ($scope.unidadmedida.esBase && !$scope.permisobase) {
            $location.path("/unidadmedidas");
        }
        UnidadMedidaFactory.all({view: 'BaseList'}).$promise.then(function (data) {
            $scope.unidades = data;
            for (var i = 0; i < $scope.unidades.length; i++) {
                if ($scope.unidades[i].id === $scope.unidadmedida.id) {
                    $scope.unidades.splice(i, 1);
                    i = 0;
                }
            }
        });

        $scope.descripcionvacia = false;
        $scope.errorcantidad = false;
        $scope.errorabreviatura = false;
        $scope.view = true;
    }


    function changeCheck() {
        console.log("changeCheck");
        if ($scope.unidadmedida.esBase) {
            $scope.unidadmedida.cantidad = undefined;
            $scope.unidadmedida.unidadContenida = undefined;
        } else {
            $scope.unidadmedida.unidadContenida = $scope.unidades[0];
        }
    }


    function submit() {
        $scope.submited = true;
        if ($scope.unidadmedida.codigo === null || $scope.unidadmedida.codigo === undefined) {
        } else if ($scope.unidadmedida.codigo >= 0) {
            $scope.unidadmedida.codigo = $scope.unidadmedida.codigo.toString()
        }
        if ($scope.UnidadMedidaForm.$valid) {
            $rootScope.isProcessing = true;
            submitUnidadMedida().then(function (unidadmedida) {
                $location.path('/unidadmedidas');
            })
        }

        function submitUnidadMedida() {
            var unidadMedida = UnidadMedidaFactory.create($scope.unidadmedida);
            return UnidadMedidaFactory.save(unidadMedida,
                    function (resp, headers) {
                        //success callback
                        console.log(resp);
                    },
                    function (err) {
                        // error callback
                        console.log(err);
                        notify({message: $filter('translate')('UNIT_NOT_SAVED'), classes: 'alert-info', position: 'right'})
                    });
        }
    }

    function cancel() {
        $location.path("/unidadmedidas");
    }

    $scope.resource = 'unidadmedidas'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';


angular.module('qualita.productos')
  .controller('TipoImpuestosIndexCtrl', ["$scope", "TipoImpuestosFactory", "filterFactory", "ReportTicketFactory", "$window", "ProductosFactory", "ProductosLangFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter", function ($scope, TipoImpuestosFactory, filterFactory, ReportTicketFactory,
    $window, ProductosFactory, ProductosLangFactory, CsvFactory, UtilFactory, baseurl, $filter) {

    var defaultColumnOrder = ['id', 'descripcion', 'porcentaje', 'activo', 'ProductosFactory'];
    ProductosLangFactory.getTranslations().then(function (translations) {

      var decimalRender = function (data, item, row) {
        if (data || data == 0) {
          return data.toString().replace(".", ",");
        }
      };

      $scope.options = {
        'resource': 'tipoimpuestos',
        'title': 'Tipos de Impuestos',
        'factory': TipoImpuestosFactory,
        'view': 'BaseList',
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false  },
          { 'data': 'descripcion', 'title': $filter('translate')('NOMBRE') },
          { 'data': 'porcentaje', 'title': $filter('translate')('PORCENTAGE') , 'class': 'dt-right', renderWith: decimalRender },
          { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), visible: false, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'failedDeleteError': $filter('translate')('ERROR_DELETE_TAX'),
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoTiposImpuestos', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("tipoimpuestos", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ]
      };
    });

    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        if(search === 'No' && data === 'activo'){
          search = 'false';
      }
      if(search === 'Sí' && data === 'activo'){
          search = 'true';
      }
        filtersArr.push({ path: data, like: search })
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }
  }]);

'use strict';

angular.module('qualita.productos')
  .controller('TipoImpuestosFormController', ['$rootScope', '$scope', 'TipoImpuestosFactory', 'formFactory', '$location',
    '$state', 'tipoImpuestoPrepService', 'notify','ProductosFactory', 'ProductosLangFactory', '$filter',
    function($rootScope, $scope, TipoImpuestosFactory, formFactory, $location, $state, tipoImpuestoPrepService, 
      notify, ProductosFactory, ProductosLangFactory, $filter) {

      activate();

      function activate() {
        ProductosLangFactory.getTranslations().then(function(translations) {
          $scope.translations=translations;
          $scope.schema = TipoImpuestosFactory.schema();
          $scope.options = formFactory.defaultOptions();

          if ($state.is("app.tipoimpuestos.new")) {
            activateNew();
          } else if ($state.is("app.tipoimpuestos.edit")) {
            activateEdit();
          } else if ($state.is("app.tipoimpuestos.view")) {
            activateView();
          }
          $rootScope.isProcessing = false;
        });
      }

      function activateNew() {
        $scope.title = $filter('translate')('NEW_TAX_TYPE');
        $scope.form = TipoImpuestosFactory.form('new');
        $scope.model = {};
        $scope.view = false;
        $scope.schema.readonly = false;
      }

      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_TAX_TYPE');
        $scope.form = TipoImpuestosFactory.form('edit');
        $scope.model = tipoImpuestoPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "TipoImpuesto";
        $scope.view = false;
        $scope.schema.readonly = false;
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_TAX_TYPE');
        $scope.form = TipoImpuestosFactory.form('view');
        $scope.model = tipoImpuestoPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "TipoImpuesto";
        $scope.view = true;
        $scope.options = formFactory.defaultViewOptions();
        $scope.schema.readonly = true;
      }

      $scope.submit = function(form) {
        formFactory.defaultSubmit('tipoimpuestos', $scope, form, TipoImpuestosFactory, undefined, errorHandler);
      };

      function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
          if (error.constraint == 'descripcion') {
            msg += '\n\n' + $filter('translate')('TAXT_TYPE_EXISTS')+"\n";
          } else {
            msg += '\n\n' + error.message + '.';
          }
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      }

      $scope.cancel = function() {
        $location.path('/tipoimpuestos');
      };

    }
  ]);
'use strict';

/**
 * @ngdoc service
 * @name qualita.marcas
 * @description
 * # marcas
 * Factory in the qualita.
 */
angular.module('qualita.productos')
  .factory('MarcasFactory', ['$resource', 'baseurl', function($resource,  baseurl) {

  	var Marca= $resource( baseurl.getBaseUrl() + '/marcas/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    var schema = {
      type: 'object',
      title: 'Marca',
      properties: {
        descripcion: {
          title: 'Nombre',
          type: 'string',
          maxLength: 255,
          validationMessage: {
            201: 'El valor es muy largo, máximo permitido 255 caracteres'
          },
        }
      },
      required: ['codigo', 'descripcion']
    };

    var formNew = [
      'descripcion'
    ];

    var formEdit = angular.copy(formNew);
    var formView = angular.copy(formNew);

    var forms = {
      'new': formNew,
      'edit': formEdit,
      'view': formView
    }

    return {
      all: function(params) {
        return Marca.query(params);
      },

      get: function(id,view) {
        if (typeof(view)==='undefined') view = "base";
        return Marca.get({id: id, view: view});
      },

      create: function(attrs) {
        return new Marca(attrs);
      },

      save: function(marca) {
        return (marca.id) ? marca.$update() : marca.$save();
      },

      remove: function(marca) {
        return marca.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return forms[type];
      }
    };
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.timbradoproveedor
 * @description
 * # timbradoproveedor
 * Factory in the qualita.
 */
angular.module('qualita.productos')
  .factory('TimbradoProveedorFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var TimbradoProveedor = $resource(baseurl.getBaseUrl() + '/timbradoproveedor/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });
    return {
      all: function (params) {
        return TimbradoProveedor.query(params);
      },
      get: function (id, view) {
        return TimbradoProveedor.get({ id: id, view: view });
      },
      create: function (attrs) {
        return new TimbradoProveedor(attrs);
      },
      save: function (timbradoProveedor) {
        return (timbradoProveedor.id) ? timbradoProveedor.$update() : timbradoProveedor.$save();
      },
      remove: function (timbradoProveedor) {
        return timbradoProveedor.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      },
      schema: function () {
        return schema;
      },
      form: function (type) {
        return forms[type];
      },
      reenviar: function (idTimbradoProveedor) {
        return $http({
          url: baseurl.getBaseUrl() + '/timbradoproveedor/reenviar/' + idTimbradoProveedor,
          method: "PUT"
        });
      } 
    };
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.marcas
 * @description
 * # marcas
 * Factory in the qualita.
 */
angular.module('qualita.productos')
  .factory('ClaseProductoFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var ClaseProducto = $resource(baseurl.getBaseUrl() + '/claseproductos/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'base';
        } else {
          params = [];
          params.view = view != undefined ? view : 'base';
        }
        return ClaseProducto.query(params);
      },

      get: function (id, view) {
        if (typeof (view) === 'undefined') view = "base";
        return ClaseProducto.get({ id: id, view: view });
      },

      create: function (attrs) {
        return new ClaseProducto(attrs);
      },

      save: function (claseProducto) {
        return (claseProducto.id) ? claseProducto.$update() : claseProducto.$save();
      },

      remove: function (claseProducto) {
        return claseProducto.$remove();
      },

      administraciones: function (codigo) {
        console.log(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      },

      tipoproductos: function () {
        return $http.get(baseurl.getBaseUrl() + '/tipoproductos?view=ClaseProductoForm');
      },

      reenviar: function (claseProducto) {
        return $http({
          url: baseurl.getBaseUrl() + '/claseproductos/reenviar/' + claseProducto.id,
          method: "PUT"
        });
      },

    };
  }]);
'use strict';


angular.module('qualita.productos')
  .controller('ClaseProductosIndexCtrl', ["$scope", "ClaseProductoFactory", "filterFactory", "notify", "ReportTicketFactory", "$window", "ProductosFactory", "ProductosLangFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter", "ParametrosFactory", "$modal", "$state", function ($scope, ClaseProductoFactory, filterFactory,
    notify, ReportTicketFactory, $window, ProductosFactory, ProductosLangFactory, CsvFactory, UtilFactory, baseurl,
    $filter, ParametrosFactory, $modal, $state) {

    var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
    var enviarAContabilidad = '';
  
    promise1.then(function(response) {
      enviarAContabilidad = response.valorTipo.codigo;
    });

    var defaultColumnOrder = ['id', 'descripcion', 'localEAN', 'calculoVerificador', 'activo', 'tipoAdministracion.descripcion', 'tipoProducto.descripcion'];
    ProductosLangFactory.getTranslations().then(function (translations) {
      $scope.options = {
        'resource': 'claseproductos',
        'title': 'Clases de Productos',
        'view': 'ClaseProductoList',
        'factory': ClaseProductoFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false  },
          { 'data': 'descripcion', 'title': $filter('translate')('NOMBRE') },
          { 'data': 'tipoProducto.descripcion', 'title': $filter('translate')('PRODUCT_TYPE'), 'type': 'combo', 'filterUrl': 'tipoproductos/filtro', 'class': 'dt-left' },
          { 'data': 'tipoAdministracion.descripcion', 'title': $filter('translate')('ADMINISTRATION_TYPE'), 'type': 'combo', 'filterUrl': 'claseproductos/administraciones', 'class': 'dt-left' },
          { 'data': 'localEAN', 'title': $filter('translate')('AUTOMATIC_EAN'), visible: false, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
          { 'data': 'calulcoVerificador', 'title': $filter('translate')('CALCULATE_DIGIT'), visible: false, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
          { 'data': 'activo', 'title': $filter('translate')('ACTIVE_QUESTION'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }

        ],
        //'detailRows': []
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'failedDeleteError': 'No se pudo borrar la clase de producto. Verifique si no hay productos asociados.',
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoClasesProductos', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("claseproductos", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ],
          'extraRowOptions': [
            {
              templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
              functionName: 'reenviar',
              customAttribute: ['enviadoAContabilidad', 'id'],
              functionDef: function (atributo) {
    
                var idClase = atributo[1];
    
                ClaseProductoFactory.get(idClase, "ClaseProductoList").$promise.then(function (response) {
    
                  $scope.tituloModal = $filter('translate')('REENVIAR_CLASE_PRODUCTO');
                  $scope.mensajeModal = $filter('translate')('REENVIAR_CLASE_PRODUCTO_MSG');
                  var modalInstance = $modal.open({
                    templateUrl: 'views/directives/confirmation-modal.html',
                    scope: $scope
                  });
    
                  $scope.cancel = function () {
                    modalInstance.dismiss('cancel');
                  };
    
                  $scope.ok = function (id) {
                    
                    ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
    
                      if (parametro.valorTipo.codigo == 'si') {
                        ClaseProductoFactory.reenviar(response).then(function (responseReenviar) {
    
                          if (responseReenviar.data.enviadoAContabilidad == false) {
                            notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                          }
                          $state.go("app.claseproductos.list", { id: responseReenviar.id }, { reload: true });
                        }, function (error) {
                          var msg = "";
                          if (error.data && error.data.length > 0 && error.data[0].message) {
                            msg = error.data[0].message;
                          } else {
                            msg = $filter('translate')('REENVIAR_CLASE_PRODUCTO_FAILED');
                          }
    
                          notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        });
                      } else {
                        var msg = "";
                        msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                      }
                    });
                    modalInstance.close();
                  }
                });
              },
              conditionName: 'canReenviar',
              conditionDef: function (atributo) {
                var enviadoAContabilidad = atributo[0];
                if (enviarAContabilidad == 'si') {
                  if (enviadoAContabilidad === "No") {
                    return true;
                  }
                }
              }
            }
          ]
      };
    });
    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        console.log("data ", data);
        if(search === 'No' && (data === 'activo' || data === 'localEAN' || data === 'calulcoVerificador')){
          search = 'false';
        }
        if(search === 'Sí' && (data === 'activo' || data === 'localEAN' || data === 'calulcoVerificador')){
          search = 'true';
        }
        filtersArr.push({ path: data, like: search })
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }

  }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.productos')
  .controller('ClaseProductosFormCtrl', ClaseProductosFormCtrl);

ClaseProductosFormCtrl.$inject = ['$rootScope', '$scope', 'ClaseProductoFactory', '$location', '$state', 
'claseProductoPrepService', 'notify', 'formFactory','ProductosFactory', 'ProductosLangFactory', '$filter',
'ParametrosFactory'];

function ClaseProductosFormCtrl($rootScope, $scope,ClaseProductoFactory, $location, $state, 
  claseProductoPrepService, notify, formFactory, ProductosFactory, ProductosLangFactory, $filter,
  ParametrosFactory) {


  activate();

  $scope.cancel = cancel;
  $scope.submit = submit;

  $scope.changeTipoProducto = changeTipoProducto;

  function activate() {
    ProductosLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
      if($state.is("app.claseproductos.new")) {
        activateNew();
      } else if($state.is("app.claseproductos.edit")) {
        activateEdit();
      }
      else if($state.is("app.claseproductos.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.descripcionvacia=false;
      $scope.errordigito=false;
      $scope.submited =false;
    });
  }

  function changeTipoProducto(){
      $scope.claseproducto.tipoAdministracion="";
      ClaseProductoFactory.administraciones('forma_administracion').then(function (data) {
        $scope.administraciones= data.data;
        $scope.claseproducto.tipoAdministracion="";
        if($scope.claseproducto.tipoProducto.descripcion === 'Activo Fijo'){

          // Se obtiene solo la administracion normal
          var adminNormal = _.find($scope.administraciones, function(detalle) {
                return detalle.codigo === "normales";
          });

          // Se setea como unica opcion, normal
          $scope.administraciones = [];
          $scope.administraciones.push(adminNormal);

        }else if($scope.claseproducto.tipoProducto.descripcion === 'Normal'){

          // Todas las opciones excepto 'bobina'
          _.remove($scope.administraciones, function(administracion) {
            return administracion.codigo === "bobinas";
          });
        }
      });
  }

  function activateNew() {
    $scope.title = $filter('translate')('NEW_PRODUCT_CLASS');
    $scope.claseproducto = { activo: true };
    ClaseProductoFactory.administraciones('forma_administracion').then(function (data) {
                $scope.administraciones= data.data;
               // $scope.claseproducto.tipoAdministracion= $scope.administraciones[0];
                console.log($scope.administraciones);
              });

    ClaseProductoFactory.tipoproductos().then(function (data) {
                $scope.tipoproductos= data.data;
                //$scope.claseproducto.tipoProducto= $scope.tipoproductos[0];
                //console.log($scope.tipoproductos[0]);
              });
  }

  function activateEdit() {
      ClaseProductoFactory.administraciones('forma_administracion').then(function (data) {
        $scope.administraciones= data.data;
        if($scope.claseproducto.tipoProducto.descripcion === 'Activo Fijo'){

          // Se obtiene solo la administracion normal
          var adminNormal = _.find($scope.administraciones, function(detalle) {
                return detalle.codigo === "normales";
          });

          // Se setea como unica opcion, normal
          $scope.administraciones = [];
          $scope.administraciones.push(adminNormal);

        }else if($scope.claseproducto.tipoProducto.descripcion === 'Normal'){

          // Todas las opciones excepto 'bobina'
          _.remove($scope.administraciones, function(administracion) {
            return administracion.codigo === "bobinas";
          });
        }
      });
    ClaseProductoFactory.administraciones('forma_administracion').then(function (data) {
//                $scope.administraciones= data.data;
                //$scope.claseproducto.tipoAdministracion= $scope.administraciones[0]
                //console.log($scope.administraciones);
              });

    ClaseProductoFactory.tipoproductos().then(function (data) {
                $scope.tipoproductos= data.data;
                //$scope.claseproducto.tipoProducto= $scope.tipoproductos[0];
                //console.log($scope.tipoproductos[0]);
              });
    $scope.title = $filter('translate')('EDIT_PRODUCT_CLASS');
    $scope.claseproducto = claseProductoPrepService;
    $scope.entidadId=$scope.claseproducto.id;
    $scope.entidad="ClaseProducto";
    esLocalEAN();
  }

  function activateView() {
    $scope.title = $filter('translate')('VIEW_PRODUCT_CLASS');
    $scope.claseproducto = claseProductoPrepService;
    $scope.entidadId=$scope.claseproducto.id;
    $scope.entidad="ClaseProducto";
    $scope.view = true;
    esLocalEAN();
  }

  function esLocalEAN() {
    if($scope.claseproducto.localEAN){
      $scope.disableDigito=true;
    }else{
      $scope.claseproducto.segundoDigito=null;
      $scope.disableDigito=false;
    }
  }

  function submit() {
    console.log("submit");
    $scope.submited = true;
    if(($scope.ClaseProductosForm.$error.maxlength || $scope.claseproducto.segundoDigito===undefined || $scope.claseproducto.segundoDigito===null || $scope.claseproducto.segundoDigito==='')&& $scope.claseproducto.localEAN){
      //notify({ message: 'La longitud del segundo dígito del prefijo de código de barras no puede superar 1', classes: 'alert-danger', position: 'right' });
      $scope.errordigito=true;
    }else{
      $scope.errordigito=false;
    }
    if($scope.claseproducto.descripcion===undefined || $scope.claseproducto.descripcion===''){
      //notify({ message: 'La descripcion no puede ser vacia', classes: 'alert-danger', position: 'right' });
      $scope.descripcionvacia=true;
    }else{
      $scope.descripcionvacia=false;
    }
    if(!($scope.errordigito) && !($scope.descripcionvacia) && $scope.ClaseProductosForm.$valid ) {
        formFactory.defaultNSFSubmit($scope.ClaseProductosForm, ClaseProductoFactory, $scope.claseproducto, errorHandler).then(function (response) {
          $location.path('/claseproductos');
          ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
            if (parametro.valorTipo.codigo == 'si') {
                if(response.enviadoAContabilidad == false){
                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                }
            }
         });
        }, function(error) {
          console.log(error);
        });
      }else{
         notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
      }

  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if(error.constraint=='descripcion'){
        msg += '\n\n' + $filter('translate')('SAME_NAME_PRODUCT_CLASS')+"\n"
      }else{
        msg += '\n\n' + error.message + '.'
      }
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  $scope.cambioEAN=function cambioEAN() {
    if($scope.claseproducto.localEAN){
      $scope.disableDigito=true;
    }else{
      $scope.claseproducto.segundoDigito=null;
      $scope.disableDigito=false;
    }
  }

  function cancel() {
    $location.path("/claseproductos");
  }

  $scope.resource = 'claseproductos'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.tipoimpuestos
 * @description
 * # tipoimpuestos
 * Factory in the qualita.
 */
angular.module('qualita.productos')
  .factory('TipoImpuestosFactory', ['$resource', 'baseurl', function($resource,  baseurl) {

  	var TipoImpuestos= $resource( baseurl.getBaseUrl() + '/tipoimpuestos/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    var schema = {
      type: 'object',
      title: 'Tipo Impuesto',
      properties: {
        descripcion: {
          title: 'Nombre',
          type: 'string',
          maxLength: 255,
          validationMessage: {
            201: 'El valor es muy largo, máximo permitido 255 caracteres'
          },
        },
        porcentaje: {
          title: 'Porcentaje',
          type: 'number',
          minimum: 0,
          maximum: 100,
          validationMessage: "El porcentaje debe ser un número entre 0 (cero) y 100 (cien)."
        },
        activo: {
          title: 'Activo',
          type: 'boolean',
          default:true
        }
      },
      required: ['descripcion', 'porcentaje']
    };

    var formNew = [
      'descripcion', { key: 'porcentaje', type : 'number', required: true }, { key: 'activo', type: 'customcheckbox' }
    ];

    var formEdit = angular.copy(formNew);
    var formView = angular.copy(formNew);

    var forms = {
      'new': formNew,
      'edit': formEdit,
      'view': formView
    }

    return {
      all: function(params, view) {
        if(params){
          params.view = view != undefined ? view : 'BaseList';
        }
        return TipoImpuestos.query(params);
      },

      get: function(id,view) {
        if (typeof(view)==='undefined') view = "base";
        return TipoImpuestos.get({id: id, view: view});
      },

      create: function(attrs) {
        return new TipoImpuestos(attrs);
      },

      save: function(tipoimpuesto) {
        return (tipoimpuesto.id) ? tipoimpuesto.$update() : tipoimpuesto.$save();
      },

      remove: function(tipoimpuesto) {
        return tipoimpuesto.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return forms[type];
      }
    };
  }]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('LineasProductoFactory', LineasProductosFactory);
LineasProductosFactory.$inject = ['$resource', 'baseurl', '$http'];

function LineasProductosFactory($resource, baseurl, $http) {
  var service = {
    all: all,
    fullTree: fullTree,
    getAllTree: getAllTree,
    get: get,
    create: create,
    save: save,
    remove: remove,
    reenviar: reenviar,
    carga: carga
  };

  var lineaProducto = $resource( baseurl.getBaseUrl() + "/lineasproducto/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    },
    fullTree: {
      method: 'GET',
      url: baseurl.getBaseUrl() + "/lineasproducto/arbolcompleto?view=LineaProductoForm",
      isArray: true
    },
    getAllTree: {
      method: 'GET',
      url: baseurl.getBaseUrl() + "/lineasproducto/getAllTree?view=BaseList",
      isArray: true
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view !== undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view !== undefined ? view : 'BaseList';
    }

    return lineaProducto.query(params);
  }

  function fullTree(params) {
    console.log("LineasProductoFactory - fullTree()");
    return lineaProducto.fullTree(params);
  }
  function getAllTree(params) {
    console.log("LineasProductoFactory - getAllTree()");
    return lineaProducto.getAllTree({search: params});
  }
  function get(id, view) {
    if (typeof(view)==='undefined') view = "base";
    return lineaProducto.get({id: id, view: view});
  }

  function create(attrs) {
    return new lineaProducto(attrs);
  }

  function save(lineaProducto) {
    return (lineaProducto.id) ? lineaProducto.$update() : lineaProducto.$save();
  }

  function remove(lineaProducto) {
    return lineaProducto.$remove();
  }

  function reenviar(lineaProducto) {
    return $http({
      url: baseurl.getBaseUrl() + '/lineasproducto/reenviar/' + lineaProducto.id,
      method: "PUT"
    });
  }
    
  function carga(name, locacion) {
    return $http({
      url: baseurl.getBaseUrl() + '/lineasproducto/carga',
      method: "POST",
      params: { filename: name, locacion: locacion}
    });
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('ProductosFactory', ProductosFactory);

ProductosFactory.$inject = ['$resource', 'baseurl', '$translatePartialLoader', '$translate', '$http'];

function ProductosFactory($resource, baseurl, $translatePartialLoader, $translate, $http) {

  var service = {
    all: all,
    allForSelect: allForSelect,
    get: get,
    create: create,
    save: save,
    remove: remove,
    reenviar: reenviar,
    carga: carga
  };

  var Producto = $resource(baseurl.getBaseUrl() + "/productos/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view != undefined ? view : 'BaseList';
    }
    return Producto.query(params);
  }

  function allForSelect(params, view) {
    if (params) {
      params.view = view || "BaseList";
    } else {
      params = [];
      params.view = view || "BaseList";
    }
    return Producto.query(params);
  }

  function get(id, view) {
    if (typeof (view) === 'undefined') view = "base";
    return Producto.get({ id: id, view: view });
  }

  function create(attrs) {
    return new Producto(attrs);
  }

  function save(producto) {
    return (producto.id) ? producto.$update() : producto.$save();
  }

  function remove(producto) {
    return producto.$remove();
  }

  function reenviar(producto) {
    return $http({
      url: baseurl.getBaseUrl() + '/productos/reenviar/' + producto.id,
      method: "PUT"
    });
  }

  function carga(name, locacion) {
    return $http({
      url: baseurl.getBaseUrl() + '/productos/carga',
      method: "POST",
      params: { filename: name, locacion: locacion}
    });
  }

}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('ProveedoresFactory', ProveedoresFactory);

ProveedoresFactory.$inject = ['$resource', 'baseurl', '$http'];

function ProveedoresFactory($resource, baseurl, $http) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    reenviar: reenviar,
    carga: carga
  };

  var proveedores = $resource( baseurl.getBaseUrl() + "/proveedores/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if(params){
      params.view = view != undefined ? view : 'BaseList';
    }
    return proveedores.query(params);
  }

  function get(id,view) {
    if (typeof(view)==='undefined') view = "BaseList";
    return proveedores.get({id: id, view: view});
  }

  function create(attrs) {
    return new proveedores(attrs);
  }

  function save(proveedor) {
    return (proveedor.id) ? proveedor.$update() : proveedor.$save();
  }

  function remove(proveedor) {
    return proveedor.$remove();
  }

  function reenviar(proveedor) {
    return $http({
      url: baseurl.getBaseUrl() + '/proveedores/reenviar/' + proveedor.id,
      method: "PUT"
    });
  }

  function carga(name) {
    return $http({
      url: baseurl.getBaseUrl() + '/proveedores/carga',
      method: "POST",
      params: { filename: name}
    });
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('ProveedorSucursalesFactory', ProveedoresSucursalesFactory);

ProveedoresSucursalesFactory.$inject = ['$resource', 'baseurl'];

function ProveedoresSucursalesFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var proveedorsucursales = $resource( baseurl.getBaseUrl() + "/proveedorsucursales/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'BaseList';
    }
    return proveedorsucursales.query(params);
  }

  function get(id,view) {
    if (typeof(view)==='undefined') view = "base";
    return proveedorsucursales.get({id: id, view: view});
  }

  function create(attrs) {
    return new proveedorsucursales(attrs);
  }

  function save(proveedorsucursal) {
    return (proveedorsucursal.id) ? proveedorsucursal.$update() : proveedorsucursal.$save();
  }

  function remove(proveedorsucursal) {
    return proveedorsucursal.$remove();
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('CriteriosControlProductoFactory', CriteriosControlProductoFactory);

CriteriosControlProductoFactory.$inject = ['$resource', 'baseurl'];

function CriteriosControlProductoFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get
  };

  var CriteriosControl = $resource( baseurl.getBaseUrl() + "/tipos/tipo/criterios_control");

  return service;

  function all(params) {
    return CriteriosControl.query(params);
  }

  function get(id,view) {
    if (typeof(view)==='undefined') view = "base";
    return CriteriosControl.get({id: id, view: view});
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('EstadosProductoFactory', EstadosProductoFactory);

EstadosProductoFactory.$inject = ['$resource', 'baseurl'];

function EstadosProductoFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get
  };

  var EstadosProducto = $resource( baseurl.getBaseUrl() + "/tipos/tipo/producto_estados");

  return service;

  function all(params) {
    return EstadosProducto.query(params);
  }

  function get(id,view) {
    if (typeof(view)==='undefined') view = "base";
    var estadosById = $resource( baseurl.getBaseUrl() + "/tipos/by-id/:id");
    return estadosById.get({id: id, view: view});
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('GruposProductosFactory', GruposProductosFactory);

GruposProductosFactory.$inject = ['$resource', 'baseurl'];

function GruposProductosFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var GrupoProducto = $resource( baseurl.getBaseUrl() + "/grupoproducto/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'BaseList';
    }
    return GrupoProducto.query(params);
  }

  function get(id,view) {
    if (typeof(view)==='undefined') view = "base";
    return GrupoProducto.get({id: id, view: view});
  }

  function create(attrs) {
    return new GrupoProducto(attrs);
  }

  function save(grupoproducto) {
    return (grupoproducto.id) ? grupoproducto.$update() : grupoproducto.$save();
  }

  function remove(grupoproducto) {
    return grupoproducto.$remove();
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('TiposRelacionProductosFactory', TiposRelacionProductosFactory);

TiposRelacionProductosFactory.$inject = ['$resource', 'baseurl'];

function TiposRelacionProductosFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get
  };

  var RelacionesProductos = $resource( baseurl.getBaseUrl() + "/tipos/tipo/tipo_relacion_productos");

  return service;

  function all(params) {
    return RelacionesProductos.query(params);
  }

  function get(id,view) {
    if (typeof(view)==='undefined') view = "base";
    var estadosById = $resource( baseurl.getBaseUrl() + "/tipos/by-id/:id");
    return estadosById.get({id: id, view: view});
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('RelacionesProductosFactory', RelacionesProductosFactory);

RelacionesProductosFactory.$inject = ['$resource', 'baseurl'];

function RelacionesProductosFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    saveArray: saveArray,
    remove: remove
  };

  var RelacionProducto = $resource( baseurl.getBaseUrl() + "/relacionesproductos/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    },
    saveArray: {
      method: 'POST',
      url: baseurl.getBaseUrl() + "/relacionesproductos/list"
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'base';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'base';
    }
    return RelacionProducto.query(params);
  }

  function get(id,view) {
    if (typeof(view)==='undefined') view = "base";
    return RelacionProducto.get({id: id, view: view});
  }

  function create(attrs) {
    return new RelacionProducto(attrs);
  }

  function save(relacionproducto) {
    return (relacionproducto.id) ? relacionproducto.$update() : relacionproducto.$save();
  }

  function saveArray(relacionesproducto) {
    return relacionesproducto.$saveArray();
  }
  function remove(relacionproducto) {
    return relacionproducto.$remove();
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('ClasesProveedoresFactory', ClasesProveedoresFactory);

ClasesProveedoresFactory.$inject = ['$resource', 'baseurl'];

function ClasesProveedoresFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get
  };

  var ClasesProveedores = $resource( baseurl.getBaseUrl() + "/tipos/tipo/proveedor_clases");

  return service;

  function all(params) {
    return ClasesProveedores.query(params);
  }

  function get(id,view) {
    if (typeof(view)==='undefined') view = "base";
    return ClasesProveedores.get({id: id, view: view});
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('TiposDocumentosFactory', TiposDocumentosFactory);

TiposDocumentosFactory.$inject = ['$resource', 'baseurl'];

function TiposDocumentosFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get
  };

  var TiposDocumentos = $resource( baseurl.getBaseUrl() + "/tipos/tipo/tipos_documento_id");

  return service;

  function all(params) {
    return TiposDocumentos.query(params);
  }

  function get(id,view) {
    if (typeof(view)==='undefined') view = "base";
    return TiposDocumentos.get({id: id, view: view});
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('TiposPersonaFactory', TiposPersonaFactory);

TiposPersonaFactory.$inject = ['$resource', 'baseurl'];

function TiposPersonaFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get
  };

  var TiposPersona = $resource( baseurl.getBaseUrl() + "/tipos/tipo/tipos_persona");

  return service;

  function all(params) {
    return TiposPersona.query(params);
  }

  function get(id,view) {
    if (typeof(view)==='undefined') view = "base";
    return TiposPersona.get({id: id, view: view});
  }
}

'use strict';


angular.module('qualita.productos')
    .controller('LineasProductoIndexCtrl', LineasProductoIndexCtrl);

LineasProductoIndexCtrl.$inject = ["LineasProductoFactory", "filterFactory", "$state", "$scope", "$modal",
    "ParametrosFactory", "ProductosLangFactory", "AuthorizationService", "notify", "$filter",
    'TiposTipoFactory', "ReportTicketFactory", "$window", "CsvFactory", "baseurl"];

function LineasProductoIndexCtrl(LineasProductoFactory, filterFactory, $state, $scope, $modal,
    ParametrosFactory, ProductosLangFactory, AuthorizationService, notify, $filter,
    TiposTipoFactory, ReportTicketFactory, $window, CsvFactory, baseurl) {
    var vm = this;

    vm.addChildren = addChildren;

    vm.buscarTexto = buscarTexto;

    vm.filterLineas = filterLineas;

    vm.desplegarTodas = desplegarTodas;

    vm.editLinea = editLinea;

    vm.reSendLinea = reSendLinea;

    vm.isNivelMaximo = isNivelMaximo;

    vm.loadChildren = loadChildren;

    vm.newLinea = newLinea;

    vm.removeLinea = removeLinea;

    vm.viewDetails = viewDetails;

    vm.clasesLineas = TiposTipoFactory.get("clase_linea_producto");

    vm.getReporte = getReporte;

    vm.getCsv = getCsv;


    activate();

    function activate() {
        
        ProductosLangFactory.getTranslations().then(function(translations) {
            if (!AuthorizationService.hasPermission("index_lineasproducto")) {
                $state.go("app.main");
                notify({ message: $filter('translate')('FORBIDDEN'), classes: 'alert-danger', position: 'right' });
            }
            getLineasProducto().then(function(lineas) {
                // agregamos propiedad collapsed a cada objeto
                collapseAll(vm.producto);
            });
            vm.parametroCantMaxima = getParametroCantMaxima();
            // desplegar todas las listas
            // desplegarTodas();
        });
    }

    function addChildren(padre) {
        $state.go("app.lineasproducto.new", { padre: padre.id });
    }

    function buscarTexto() {
        if (!vm.criterioBusqueda || vm.criterioBusqueda === "") {
            if (!vm.claseLineaProducto) {
                getLineasProducto().then(function(lineas) {
                    //agregamos propiedad collapsed a cada objeto
                    collapseAll(vm.producto);
                });
                return;
            } else {
                getLineasProductoByClase(vm.claseLineaProducto);
            }
        }
        getLineasProductoByText(vm.criterioBusqueda, vm.claseLineaProducto);
    }

    function filterLineas() {
        if (!vm.criterioBusqueda || vm.criterioBusqueda === "") {
            getLineasProductoByClase(vm.claseLineaProducto);
            // .then(function(lineas) {
            //     collapseAll(vm.producto);
            // });
        } else {
            buscarTexto();
        }
    }

    function collapseAll(arreglo) {
        for (var i = 0; i < arreglo.length; i++) {
            arreglo[i]["collapsed"] = true;
        }
    }


    function desplegarTodas() {
        vm.allItemsDeploying = true;
        LineasProductoFactory.fullTree().$promise.then(function(response) {
            //console.log("Response de fullTree(): ");
            //console.log(response);
            vm.producto = response;
            vm.allItemsDeployed = true;
            $scope.$broadcast('expandAll');
            expandAll(vm.producto);
            vm.allItemsDeploying = false;
            return response;
        });
    }

    function editLinea(linea) {
        $state.go("app.lineasproducto.edit", { id: linea.id });
    }

    function reSendLinea(linea) {

      $scope.tituloModal = $filter('translate')('REENVIAR_LINEA_PRODUCTO');
      $scope.mensajeModal = $filter('translate')('REENVIAR_LINEA_PRODUCTO_MSG');
      var modalInstance = $modal.open({
        templateUrl: 'views/directives/confirmation-modal.html',
        scope: $scope
      });

      $scope.cancel = function () {
        modalInstance.dismiss('cancel');
      };

      $scope.ok = function (id) {
        ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {

          if (parametro.valorTipo.codigo == 'si') {
            LineasProductoFactory.reenviar(linea).then(function (responseReenviar) {

              if (responseReenviar.data.enviadoAContabilidad == false) {
                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
              }
              $state.go("app.lineasproducto.list", { id: responseReenviar.id }, { reload: true });
            }, function (error) {
              var msg = "";
              if (error.data && error.data.length > 0 && error.data[0].message) {
                msg = error.data[0].message;
              } else {
                msg = $filter('translate')('REENVIAR_LINEA_PRODUCTO_FAILED');
              }

              notify({ message: msg, classes: 'alert-danger', position: 'right' });
            });
          } else {
            var msg = "";
            msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
          }
        });
        modalInstance.close();
      }
    }

    function expandAll(arreglo) {
        _.forEach(arreglo, function(linea) {
            linea["collapsed"] = false;
            if (linea.hijos && linea.hijos.length > 0) {
                expandAll(linea.hijos);
            }
        });
    }

    function getHijos(padre) {
        var params = {};
        params.search = filterFactory.and([{
                path: 'padre.id',
                equals: padre.id
            },
            {
                path: 'nivel',
                equals: padre.nivel + 1
            }
        ]).value();
        params.view = "LineaProductoList";
        return LineasProductoFactory.all(params, "LineaProductoList");
    }

    function isNivelMaximo(nodo) {
        if (!vm.parametroCantMaxima) {
            return true;
        }
        if (nodo.nivel >= parseInt(vm.parametroCantMaxima[0].valor)) {
            return true;
        }
        return false;
    }

    function getLineasProducto() {
        var params = {};
        params.search = filterFactory.and([{
                path: 'nivel',
                equals: 1
            },
            {
                path: 'ordenJerarquico',
                sortAsc: true
            }
        ]).value();
        params.view = "LineaProductoList";
        return LineasProductoFactory.all(params, "LineaProductoList").$promise.then(function(data) {
            vm.producto = data;
            //console.log("AQUI");
            //console.log(vm.producto);
            return vm.producto;
        });
    }

    function getLineasProductoByText(criterio, clase) {
        var params = {};
        var filters = [{
            path: 'nombre',
            like: criterio
        }, {
            path: 'ordenJerarquico',
            sortAsc: true
        }];
        if (clase) {
            filters.push({
                path: 'clase.codigo',
                equals: clase.codigo
            });
        }
        params.search = filterFactory.and(filters).value();
        params.view = "LineaProductoList";
        return LineasProductoFactory.all(params, "LineaProductoList").$promise.then(function(data) {
            // console.log("Resultados de busqueda del backend: ");
            // console.log(data);
            vm.producto = data;
            collapseAll(vm.producto);
            return vm.producto;
        });
    }

    function getLineasProductoByClase(clase) {
        var params = {};
        var filters = [{
            path: 'clase.codigo',
            equals: clase.codigo
        },{
            path: 'nivel',
            equals: 1
        },{
            path: 'ordenJerarquico',
            sortAsc: true
        }];
        params.search = filterFactory.and(filters).value();
        params.view = "LineaProductoList";
        return LineasProductoFactory.all(params, "LineaProductoList").$promise.then(function(data) {
            // console.log("Resultados de busqueda del backend: ");
            // console.log(data);
            vm.producto = data;
            collapseAll(vm.producto);
            return vm.producto;
        });
    }

    function getParametroCantMaxima() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'cantidad_niveles_lineas_producto'
        }).value();

        return ParametrosFactory.all(params);
    }

    function loadChildren(obj, post, modelo) {
        post(obj);
        if (!vm.allItemsDeployed) {
            // console.log("Llamada para traer los hijos");
            getHijos(modelo).$promise.then(function(response) {
                modelo.hijos = _.forEach(response, function(elem) {
                    if (elem.hijos && elem.hijos.length > 0) {
                        elem.collapsed = true;
                        elem.tieneHijos = true;
                    }
                    delete elem.hijos;
                });
                
                /* Se realiza ordenacion de los hijos */
                var auxModeloHijos = modelo.hijos;

                // Se crea campo 'codigoNumerico' en base al campo 'codigo' (String)
                _.forEach(auxModeloHijos, function(linea) {
                    var aux = linea.codigo;
                    aux = aux.replace(/\./g, "");
                    aux = parseInt(aux);
                    linea.codigoNumerico = aux;
                });

                // Se realiza la ordenacion en base al nuevo campo creado
                auxModeloHijos.sort(function(a, b) {
                    return a.codigoNumerico - b.codigoNumerico;
                });

                // Se asigna al modelo el listado de hijos ordenado
                modelo.hijos = auxModeloHijos;

            });
        }
        vm.allItemsDeployed = false;
    }

    function newLinea() {
        $state.go("app.lineasproducto.new");
    }

    function getReporte() {

        var defaultColumnOrder = ['codigo', 'nombre', 'nivel', 'ultimoNivel'];
        ReportTicketFactory.ticket('listadoLineasProductos', null, null, defaultColumnOrder).then(function(genTicket) {
            $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
            $window.open($scope.pdfDownloadURL, '_blank');
        });
    }

    function getCsv() {

        var defaultColumnOrder = ['codigo', 'nombre', 'nivel', 'ultimoNivel'];
        var currentColumnOrderNames = ['Codigo', 'Nombre', 'Nivel', 'Ultimo Nivel'];
        CsvFactory.csv("lineasproducto", null, null, defaultColumnOrder, currentColumnOrderNames)
            .then(function(response) {
                console.log("response de csv: ", response);
                var fileName = response.data.fileName;
                $window.open(baseurl.getPublicBaseUrl() + fileName);
        });
    }

    function removeLinea(item, post, obj) {
        ProductosLangFactory.getTranslations().then(function(translations) {
            var mensajeError = $filter('translate')('PRODUCT_LINE_ERROR_MESSAGE_1');
            if (item.ultimoNivel) {
                mensajeError = $filter('translate')('PRODUCT_LINE_ERROR_MESSAGE_2')
            }
            $scope.disableButton = false;
            var itemId = item.id;
            $scope.selectedItemId = itemId;
            $scope.tituloModal = $filter('translate')('CONFIRM_DELETE');
            $scope.mensajeModal = $filter('translate')('DELETE_QUESTION');
            var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
            });

            $scope.cancel = function() {
                modalInstance.dismiss('cancel');
            }

            $scope.ok = function(itemId) {
                $scope.disableButton = true;
                var model = LineasProductoFactory.create({ id: itemId });
                LineasProductoFactory.remove(model).then(function() {
                    post(obj);
                    modalInstance.close(itemId);
                }, function(error) {
                    modalInstance.close(itemId);
                    $scope.tituloModal = $filter('translate')('ERROR_DELETE_PRODUCT_LINE');
                    $scope.mensajeModal = mensajeError;
                    var modalInstance2 = $modal.open({
                        template: '<div class="modal-header">' +
                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                            '</div>' +
                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                            '<div class="modal-footer">' +
                            '<button class="btn btn-primary" ng-click="ok2()">Aceptar</button>' +
                            '</div>',
                        scope: $scope
                    });

                    $scope.ok2 = function() {
                        modalInstance2.dismiss('cancel');
                    }
                });
            }
        });
    }

    function viewDetails(linea) {
        $state.go("app.lineasproducto.details", { id: linea.id });
    }


}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.productos')
    .controller('LineasProductoFormCtrl', LineasProductoFormCtrl);

LineasProductoFormCtrl.$inject = ['$rootScope', '$scope', 'LineasProductoFactory', 'filterFactory', '$location',
    '$stateParams', '$state', 'lineaProductoPrepService', 'ProductosFactory', 'ProductosLangFactory', 'notify', '$filter',
    'TiposTipoFactory', 'ParametrosFactory'
];

function LineasProductoFormCtrl($rootScope, $scope, LineasProductoFactory, filterFactory, $location, $stateParams,
    $state, lineaProductoPrepService, ProductosFactory, ProductosLangFactory, notify, $filter, TiposTipoFactory,
    ParametrosFactory) {
    var vm = this;

    vm.cancel = cancel;

    vm.submit = submit;

    vm.lineaPadreSelected = lineaPadreSelected;
    vm.claseSelected = claseSelected;

    vm.clasesLineas = TiposTipoFactory.get("clase_linea_producto");
    vm.disableClase = false;
    activate();

    function activate() {
        ProductosLangFactory.getTranslations().then(function(translations) {
            vm.translations = translations;
            if ($state.is("app.lineasproducto.new")) {
                activateNew();
            } else if ($state.is("app.lineasproducto.edit")) {
                activateEdit();
            } else if ($state.is("app.lineasproducto.view")) {
                activateView();
            }
            $rootScope.isProcessing = false;
            if ($stateParams.padre) {
                var idPadre = parseInt($stateParams.padre);
                vm.lineaProducto.padre = LineasProductoFactory.get(idPadre, "LineaProductoList");
                vm.lineaProducto.padre.$promise.then(function(padre) {
                    lineaPadreSelected();
                });
            }
        });
    }

    function activateNew() {
        vm.title = $filter('translate')('NEW_PRODUCT_LINE');
        vm.lineaProducto = {};
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'ultimoNivel',
            like: 'false'
        }]).value();
        staticFilter.view = "LineaProductoForm";
        vm.lineasProducto = LineasProductoFactory.all(staticFilter);
    }

    function activateEdit() {
        vm.disableClase = true;
        vm.title = $filter('translate')('EDIT_PRODUCT_LINE');
        vm.lineaProducto = lineaProductoPrepService;
        $scope.entidadId = vm.lineaProducto.id;
        $scope.entidad = "LineaProducto";
        if (vm.lineaProducto.padre && !vm.lineaProducto.padre.id) {
            var padreID = vm.lineaProducto.padre;
            vm.lineaProducto.padre = LineasProductoFactory.get(padreID);
            vm.lineaProducto.padre.$promise.then(function(padre) {
                lineaPadreSelected();
            })
        } else if (vm.lineaProducto.padre) {
            lineaPadreSelected();
        }
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
                path: 'ultimoNivel',
                like: 'false'
            },
            {
                path: 'codigo',
                notLike: vm.lineaProducto.codigo + '%'
            }, {
                path: 'clase.codigo',
                like: vm.lineaProducto.clase.codigo
            }
        ]).value();
        staticFilter.view = "LineaProductoForm";
        vm.lineasProducto = LineasProductoFactory.all(staticFilter);
    }

    function activateView() {
        vm.title = $filter('translate')('VIEW_PRODUCT_LINE');
        vm.lineaProducto = lineaProductoPrepService;
        $scope.entidadId = vm.lineaProducto.id;
        $scope.entidad = "LineaProducto";
        if (vm.lineaProducto.padre && !vm.lineaProducto.padre.id) {
            var padreID = vm.lineaProducto.padre;
            vm.lineaProducto.padre = LineasProductoFactory.get(padreID);
            vm.lineaProducto.padre.$promise.then(function(padre) {
                lineaPadreSelected();
            })
        }
        $scope.view = true;
        // se cargan los productos que corresponden a esta linea
        vm.factoryProductos = ProductosFactory;
        var renderEmptyData = function(data) {
            if (data == undefined)
                return "";
            else
                return data;
        };
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'lineaProducto.id',
            equals: $scope.entidadId
        }]).value();
        var defaultColumnOrder = ['id', 'nombre', 'claseProducto.descripcion', 'estado.descripcion', 'marca.descripcion'];
        vm.options = {
            'resource': 'productos',
            'title': 'Productos asociados a esta Línea de Producto',
            'factory': ProductosFactory,
            'staticFilter': staticFilter,
            'defaultColumnOrder': defaultColumnOrder,
            'columns': [
                { 'data': 'id', 'title': $filter('translate')('ID'), visible: false  },
                { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
                { 'data': 'claseProducto.descripcion', 'title': $filter('translate')('CLASS') },
                { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
                { 'data': 'marca.descripcion', 'title': $filter('translate')('BRAND'), 'render': renderEmptyData },
            ],
            'hasOptions': false,
            'hideAddMenu': true,
            'defaultOrderColumn': 0,
            'defaultOrderDir': "desc",
        };
    }

    function lineaPadreSelected() {
        vm.clasesLineas.$promise.then(function(response) {
            vm.lineaProducto.clase = _.find(response, function(clase) {
                return clase.id === vm.lineaProducto.padre.clase.id;
            });
        });
    }

    function claseSelected() {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'ultimoNivel',
            like: 'false'
        }, {
            path: 'clase.codigo',
            like: vm.lineaProducto.clase.codigo
        }]).value();
        staticFilter.view = "LineaProductoForm";
        vm.lineasProducto = LineasProductoFactory.all(staticFilter);
    }

    function submit() {
        vm.submited = true;
        if ($scope.LineasProductoForm.$valid) {
            $rootScope.isProcessing = true;
            submitLineaProducto().then(function(lineaProducto) {
                $location.path('/lineasproducto');
                ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                    if (parametro.valorTipo.codigo == 'si') {
                        if(lineaProducto.enviadoAContabilidad == false){
                            notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                        }
                    }
                  });
            }, function(error) {
                $rootScope.isProcessing = false;
                console.log("Ha ocurrido un error");
                console.log(error);
                errorHandler(error);
                if (vm.lineaProducto.hijos && vm.lineaProducto.hijos.length > 0 && vm.lineaProducto.ultimoNivel) {
                    vm.ultimoNivelInvalido = true;
                }
            });
        }

        function submitLineaProducto() {
            var lineaProducto = LineasProductoFactory.create(vm.lineaProducto);
            return LineasProductoFactory.save(lineaProducto);
        }
    }

    function cancel() {
        $location.path("/lineasproducto");
    }

    $scope.resource = 'lineasproducto'; //MALA PRACTICA, necesario solo para que funciona offline-recovery

    function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
            if (error.message == "Not unique field: nombre") {
                msg += '\n\n' + $filter('translate')('LINEA_PRODUCTO_EXISTS') + "\n"
            } else {
                msg += '\n\n' + error.message + '.'
            }
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
    }
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.productos')
  .controller('LineasProductoDetalleCtrl', LineasProductoDetalleCtrl);

LineasProductoDetalleCtrl.$inject = ['$scope', 'LineasProductoFactory', 'lineaProductoPrepService', 'filterFactory', 
'$location','ProductosFactory', 'ProductosLangFactory', '$filter'];

function LineasProductoDetalleCtrl($scope, LineasProductoFactory, lineaProductoPrepService, filterFactory, $location, 
  ProductosFactory, ProductosLangFactory, $filter) {
  var vm = this;

  ProductosLangFactory.getTranslations().then(function(translations) {
    vm.title = $filter('translate')('PRODUCT_LINE_DETAIL');
  });
  vm.producto = lineaProductoPrepService;
  vm.volver = volver;

  activate();

  function activate() {
    if(vm.producto.hijos) {
      return getHijos();
    }
  }

  function getHijos() {
    var params = {};
    params.search = filterFactory.single({
      path: 'padre.id',
      equals: vm.producto.id
    }).value();
    return LineasProductoFactory.all(params).$promise.then(function(data) {
      vm.hijos = data;
      return vm.hijos;
    });
  }

  function volver() {
    $location.path("/lineasproducto");
  }
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.productos')
    .controller('ProveedoresFormCtrl', ProveedoresFormCtrl);

ProveedoresFormCtrl.$inject = ['$rootScope', '$scope', 'ProveedoresFactory', 'TiposDocumentosFactory',
    'ClasesProveedoresFactory', 'TiposPersonaFactory', 'TiposFactory', '$location', 'notify', 'proveedorPrepService',
    '$state', '$timeout', 'formFactory', 'ProductosFactory', 'ProveedorSucursalesFactory', 'ProductosLangFactory', '$filter',
    'TipoProveedorFactory', '$q', 'ParametrosFactory'
];

function ProveedoresFormCtrl($rootScope, $scope, ProveedoresFactory, TiposDocumentosFactory, ClasesProveedoresFactory,
    TiposPersonaFactory, TiposFactory, $location, notify, proveedorPrepService, $state, $timeout, formFactory,
    ProductosFactory, ProveedorSucursalesFactory, ProductosLangFactory, $filter, TipoProveedorFactory, $q, ParametrosFactory) {

    var vm = this;

    vm.agregarSucursal = agregarSucursal;
    vm.agregarContacto = agregarContacto;
    vm.cancel = cancel;
    vm.isAnySucursalEmpty = isAnySucursalEmpty;
    vm.isAnyContactoEmpty = isAnyContactoEmpty;
    vm.removeItemFromArray = removeItemFromArray;
    vm.submit = submit;
    vm.isInAnyContacto = isInAnyContacto;
    vm.verificarControlTimbradoyRuc = verificarControlTimbradoyRuc;
    vm.verificarRuc = verificarRuc;
    vm.changeNroTimbrado = changeNroTimbrado;
    vm.getDigitoVerificador = getDigitoVerificador;
    vm.changeClaseProveedor = changeClaseProveedor;

    activate();

    function activate() {
        
        vm.staticFilterPais = [{path: "tipoTipo.codigo", equals: "pais_pais"}];
        vm.TiposFactory = TiposFactory;
        ProductosLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            if ($state.is('app.proveedores.new')) {
                activateNew();
            } else if ($state.is('app.proveedores.edit')) {
                activateEdit();
            } else if ($state.is('app.proveedores.view')) {
                activateView();
            }
            vm.tiposDocumentos = TiposDocumentosFactory.all();
            vm.clasesProveedor = ClasesProveedoresFactory.all({ view: 'BaseList' });
            vm.tiposPersona = TiposPersonaFactory.all();
            vm.gruposProveedor = TipoProveedorFactory.all({}, "BaseList");
            $rootScope.isProcessing = false;
            $timeout(function () {
                $scope.$broadcast('formOpened');
            }, 20);
        });
    }

    function changeClaseProveedor(){
        if (vm.proveedor.claseProveedor.codigo != 'extranjero'){
            vm.proveedor.pais = null;
        }
    }

    /* Se invoca al seleccionar el tipo de documento */
    function verificarControlTimbradoyRuc() {

        /* Se inicializa numero de documento para evitar que pase la validación de RUC en la función verificarRuc() */
        vm.proveedor.numeroDocumento = ! null ? vm.proveedor.numeroDocumento = null : null

        /* Si se cambia a un tipo de documento que no corresponde al tipo RUC, setear el campo nuevamente a estado valido 
        (Cuando es de tipo RUC, se deja en invalido hasta que se introduzca el formato correcto) */
        if(vm.proveedor.tipoDocumento.codigo!="ruc"){
            $scope.ProveedorForm.numeroDocumento.$setValidity("error", true);
        }

        vm.banLocalContribuyente = false;
        if (vm.proveedor.claseProveedor && vm.proveedor.claseProveedor.codigo == "local" &&
            vm.proveedor.tipoDocumento && vm.proveedor.tipoDocumento.codigo == "ruc") {

            vm.banLocalContribuyente = true;
            vm.proveedor.controlTimbradoNumeracion = true;
        }

    }

    function getDigitoVerificador(codigo) {
        var sumaPares = 0;
        var sumaImpares = 0;

        for (var i = 0; i < (codigo.length - 1); i++) {
            var caracter = codigo.charAt(i);
            var c = parseInt(caracter);
            if (!c) {
                console.log("!c");
                continue;
            }
            if ((i + 1) % 2 == 0) {
                sumaPares += c;
            } else {
                sumaImpares += c;
            }
        }

        var multPares = sumaPares * 13;

        var sumaTotal = multPares + sumaImpares;

        var multParesStr = sumaTotal.toString();

        var lengthStr = multParesStr.length;

        var digito = multParesStr.charAt(lengthStr - 1);

        var digitoInt = 10 - parseInt(digito);

        var resultado = digitoInt == 10 ? 0 : digitoInt;

        return resultado;
    }

    function changeNroTimbrado() {
        //Eliminacion de los espacios en blanco
        if (vm.proveedor.numeroDocumento) {
            vm.proveedor.numeroDocumento = vm.proveedor.numeroDocumento.split(' ').join('');
        }
    }

    function verificarRuc() {
        if (vm.proveedor.tipoDocumento && vm.proveedor.tipoDocumento.codigo == "ruc" && vm.proveedor.numeroDocumento) {
            var split = vm.proveedor.numeroDocumento.split("-");
            var digito = getDigitoVerificador(vm.proveedor.numeroDocumento);

            // Mas de 1 digito luego del " - "
            if (split[1] && split[1].length > 1) {
                
                // Muestra el mensaje "El dato es invalido"
                $scope.ProveedorForm.numeroDocumento.$error.pattern = true;

                // Deja el campo en estado invalido para que no se pueda persistir
                $scope.ProveedorForm.numeroDocumento.$setValidity("error", false);
            }
            else {
                //Test para caracteres especiales
                var testCaracteresEspeciales = /^(\d*\-*)*$/.test(vm.proveedor.numeroDocumento);

                if (!testCaracteresEspeciales) {

                    // Muestra el mensaje "El dato es invalido"
                    $scope.ProveedorForm.numeroDocumento.$error.pattern = true;
                    
                    // Deja el campo en estado invalido para que no se pueda persistir
                    $scope.ProveedorForm.numeroDocumento.$setValidity("error", false);
                }
                else {

                    // No existe simbolo "-"
                    if (split[1] == undefined) {
                        vm.proveedor.numeroDocumento = vm.proveedor.numeroDocumento.concat("-" + digito);
                    }

                    // Existe simbolo "-" pero no tiene numero verificador delante
                    if (split[1] == "") {
                        vm.proveedor.numeroDocumento = vm.proveedor.numeroDocumento.concat(digito);
                    }

                    // Deja el campo en estado valido para que se pueda persistir. Esto se realiza aqui, considerando que  
                    // el campo no tiene caracteres especiales y antes se realiza un autocompletado.
                    $scope.ProveedorForm.numeroDocumento.$setValidity("error", true);
                }
            }
        }
    }

    function activateNew() {
        vm.proveedor = { activo: true, controlTimbradoNumeracion: true };
        vm.proveedor.sucursales = [{}];
        vm.proveedor.contactos = [{}];
        vm.title = $filter('translate')('NEW_PROVEEDOR');
        updateSucursalesDisponibles();
    }

    function activateEdit() {
        vm.proveedor = proveedorPrepService;
        $scope.entidadId = vm.proveedor.id;
        $scope.entidad = "Proveedor";
        vm.title = $filter('translate')('EDIT_PROVEEDOR');
        if (!vm.proveedor.sucursales) {
            vm.proveedor.sucursales = [{}];
        }
        if (!vm.proveedor.contactos) {
            vm.proveedor.contactos = [{}];
        }
        $scope.edit = true;
        vm.proveedor.sucursales = _.map(vm.proveedor.sucursales, function (sucursal) {
            return ProveedorSucursalesFactory.get(sucursal.id, "ProveedorForm");
        });
        updateSucursalesDisponibles();
    }

    function activateView() {
        vm.proveedor = proveedorPrepService;
        $scope.entidadId = vm.proveedor.id;
        $scope.entidad = "Proveedor";
        vm.title = $filter('translate')('VIEW_PROVEEDOR');
        if (!vm.proveedor.sucursales) {
            vm.proveedor.sucursales = [{}];
        }
        vm.proveedor.sucursales = _.map(vm.proveedor.sucursales, function (sucursal) {
            return ProveedorSucursalesFactory.get(sucursal.id, "ProveedorForm");
        });
        $scope.view = true;
    }

    function agregarContacto() {
        vm.proveedor.contactos.push({});
        $timeout(function () {
            $scope.$broadcast('newItemContactoAdded');
        }, 20);
    }

    function agregarSucursal() {
        vm.proveedor.sucursales.push({});
        updateSucursalesDisponibles();
        $timeout(function () {
            $scope.$broadcast('newItemSucursalAdded');
        }, 20);
    }

    function isAnySucursalEmpty(arreglo) {
        var broken = false;
        _.forEach(arreglo, function (item) {
            if (!item.descripcion) {
                broken = true;
            }

        });
        return broken;
    }

    function isAnyContactoEmpty(arreglo) {
        var broken = false;
        _.forEach(arreglo, function (item) {
            if (!item.nombre) {
                broken = true;
            }

        });
        return broken;
    }

    function removeItemFromArray(elemento, arreglo, esSucursal) {

        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        if (esSucursal) {
            // si hay algun contacto asociado a la sucursal, se borra
            _.forEach(vm.proveedor.contactos, function (contacto) {
                if (contacto.sucursal.descripcion === elemento.descripcion) {
                    contacto.sucursal = undefined;
                }
            })
        }
    }

    function isInAnyContacto(elemento) {
        var isAny = false;
        // si hay algun contacto asociado a la sucursal, se inhabilita la edicion de su nombre
        _.forEach(vm.proveedor.contactos, function (contacto) {
            if (contacto.sucursal != undefined && contacto.sucursal.descripcion === elemento.descripcion) {
                isAny = true;
            }
        });
        return isAny;
    }

    function updateSucursalesDisponibles() {
        vm.sucursalesDisponibles = vm.proveedor.sucursales;
    }

    function verificarRucSubmit(){
        return $q(function(response) {
            verificarRuc();
            response();
        });
    }

    function submit() {
        vm.submited = true;

        // Se realiza la verificacion de ruc
        verificarRucSubmit().then(function(response){

            // Luego de la verificacion de ruc, se realiza la comprobacion de formulario valido
            if ($scope.ProveedorForm.$valid) {

                if ($state.is('app.proveedores.edit')){
                    if (vm.proveedor.sucursales.length == 0 || vm.proveedor.sucursales[0].descripcion == undefined || vm.proveedor.sucursales[0].descripcion.trim() == ""){
                        notify({ message: $filter('translate')('ERROR_SIN_SUCURSALES'), classes: 'alert-danger', position: 'right'});
                        vm.submited = false;
                        return;
                    }
                }

                vm.proveedor.sucursales = _.filter(vm.proveedor.sucursales, function (elem) { return elem.descripcion; });
                vm.proveedor.contactos = _.filter(vm.proveedor.contactos, function (elem) { return elem.nombre; });
                formFactory.defaultNSFSubmit($scope.ProveedorForm, ProveedoresFactory, vm.proveedor, errorHandler).then(function (response) {
                    $location.path('/proveedores');
                    ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                        if (parametro.valorTipo.codigo == 'si') {
                            if(response.enviadoAContabilidad == false){
                                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                            }
                        }
                    });
                }, function (error) {
                    console.log(error);
                });
            }
        });
    }

    function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function (error) {
            if (error.constraint == 'nombre') {
                msg += '\n\n' + $filter('translate')('PROVEEDOR_EXISTS') + "\n"
            } else if (error.constraint == 'numero_documento') {
                msg += '\n\n' + $filter('translate')('PROVEEDOR_DOC_EXISTS') + "\n"
            } else if (error.constraint == 'cuenta_bancaria') {
                msg += '\n\n' + $filter('translate')('CUENTA_BANCARIA_EXISTS') + "\n"
            } else if(error.constraint == 'codigo_establecimiento, id_proveedor'){
                msg += '\n\n' + $filter('translate')('SUCURSAL_EXISTS') + "\n"
            }else {
                msg += '\n\n' + error.message + '.'
            }
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
    }

    function validateSucursal() {
        var broken = true;
        if (vm.proveedor.sucursales.length <= 0) {
            broken = false;
        }
        return broken;
    }

    function cancel() {
        $location.path("/proveedores");
    }

    $scope.resource = 'proveedores'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}
'use strict';


angular.module('qualita.productos')
  .controller('ProveedoresIndexCtrl', ProveedoresIndexCtrl);

ProveedoresIndexCtrl.$inject = ["ProveedoresFactory", "$log", "$scope", "notify", "filterFactory",
  "ReportTicketFactory", "$window", "ProductosFactory", "ProductosLangFactory", "CsvFactory", "UtilFactory", "baseurl",
  "$filter", "ParametrosFactory", "$modal", "$state"
];

function ProveedoresIndexCtrl(ProveedoresFactory, $log, $scope, notify, filterFactory, ReportTicketFactory,
  $window, ProductosFactory, ProductosLangFactory, CsvFactory, UtilFactory, baseurl, $filter, ParametrosFactory, $modal, $state) {
  var vm = this;

  var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
  var enviarAContabilidad = '';

  promise1.then(function(response) {
    enviarAContabilidad = response.valorTipo.codigo;
  });

  var defaultColumnOrder = ['id', 'tipoProveedor.descripcion', 'nombre', 'numeroDocumento', 'direccion', 'telefono',
    'nombreFantasia', 'activo', 'tipoDocumento.descripcion', 'claseProveedor.descripcion',
    'tipoPersona.descripcion', 'cuentaBancaria', 'mail', 'paginaWeb', 'calcularRetencion'
  ];
  ProductosLangFactory.getTranslations().then(function (translations) {
    vm.options = {
      'resource': 'proveedores',
      'title': 'Proveedores',
      'factory': ProveedoresFactory,
      'view': 'ProveedorList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false },
        { 'data': 'tipoProveedor.descripcion', 'title': $filter('translate')('TIPO_PROVEEDOR')},
        { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
        { 'data': 'numeroDocumento', 'title': $filter('translate')('DOCUMENT_NUMBER'), 'class': 'dt-right' },
        { 'data': 'direccion', 'title': $filter('translate')('DIRECCION') },
        { 'data': 'telefono', 'title': $filter('translate')('TELEFONO'), class: 'dt-right' },
        { 'data': 'nombreFantasia', 'title': $filter('translate')('FANTASY_NAME') },
        { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
        //{'data': 'grupoProveedor.descripcion', 'title': $filter('translate')('PROVEEDOR_GROUP'), 'type': 'combo', 'filterUrl': 'tipos/filtro/proveedor_grupos', 'class' : 'dt-center'},
        { 'data': 'tipoDocumento.descripcion', 'title': $filter('translate')('DOCUMENT_TYPE'), 'type': 'combo', 'filterUrl': 'tipos/filtro/tipos_documento_id', visible: false },
        { 'data': 'claseProveedor.descripcion', 'title': $filter('translate')('PROVEEDOR_CLASS'), 'type': 'combo', 'filterUrl': 'tipos/filtro/proveedor_clases', visible: false },
        { 'data': 'tipoPersona.descripcion', 'title': $filter('translate')('PERSON_TYPE'), 'type': 'combo', 'filterUrl': 'tipos/filtro/tipos_persona', 'class': 'dt-center' , visible: false },
        { 'data': 'cuentaBancaria', 'title': $filter('translate')('CUENTA_BANCARIA'), visible: false },
        { 'data': 'mail', 'title': $filter('translate')('EMAIL'), visible: false },
        { 'data': 'paginaWeb', 'title': $filter('translate')('WEBSITE'), visible: false },
        { 'data': 'calcularRetencion', 'title': $filter('translate')('CALCULAR_RETENCION'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values', visible: false }

      ],
      'hasOptions': true,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'failedDeleteError': 'No se pudo borrar el proveedor. Verifique si hay Productos asociados a este proveedor.',
      'extraMenuOptions': [{
        'title': 'Reporte',
        'icon': 'glyphicon glyphicon-file',
        'showCondition': function () { return true; },
        'action': function () {
          var filters = createFilters(vm.options.getFilters());
          ReportTicketFactory.ticket('listadoProveedores', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function (genTicket) {
            vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
            $window.open(vm.pdfDownloadURL, '_blank');
          });
        }
      },
      {
        'title': 'CSV',
        'icon': 'glyphicon glyphicon-export',
        'showCondition': function () { return true; },
        'action': function () {
          var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

          CsvFactory.csv("proveedores", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
            .then(function (response) {
              console.log("response de csv: ", response);
              var fileName = response.data.fileName;
              $window.open(baseurl.getPublicBaseUrl() + fileName);
            });
        }
      }
      ],
      'extraRowOptions': [
        {
          templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
          functionName: 'reenviar',
          customAttribute: ['enviadoAContabilidad', 'id'],
          functionDef: function (atributo) {

            var idProducto = atributo[1];

            ProveedoresFactory.get(idProducto, "ProveedorList").$promise.then(function (response) {

              $scope.tituloModal = $filter('translate')('REENVIAR_PROVEEDOR');
              $scope.mensajeModal = $filter('translate')('REENVIAR_PROVEEDOR_MSG');
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              $scope.ok = function (id) {

                ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {

                  if (parametro.valorTipo.codigo == 'si') {
                    ProveedoresFactory.reenviar(response).then(function (responseReenviar) {

                      if (responseReenviar.data.enviadoAContabilidad == false) {
                        notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                      }
                      $state.go("app.proveedores.list", { id: responseReenviar.id }, { reload: true });
                    }, function (error) {
                      var msg = "";
                      if (error.data && error.data.length > 0 && error.data[0].message) {
                        msg = error.data[0].message;
                      } else {
                        msg = $filter('translate')('REENVIAR_PROVEEDOR_FAILED');
                      }

                      notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    });
                  } else {
                    var msg = "";
                    msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  }
                });
                modalInstance.close();
              }
            });
          },
          conditionName: 'canReenviar',
          conditionDef: function (atributo) {
            var enviadoAContabilidad = atributo[0];
            if (enviarAContabilidad == 'si') {
              if (enviadoAContabilidad === "No") {
                return true;
              }
            }
          }
        }
      ]
    };
  });

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      if (search === 'No' && data === 'activo') {
        search = 'false';
      }
      if (search === 'Sí' && data === 'activo') {
        search = 'true';
      }
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}

angular
  .module('qualita.productos')
  .directive('lineasProductoTree', lineasProductoTree);

function lineasProductoTree() {
  var directive = {
    restrict: 'E',
    scope: {
      modelo: '='
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'qualita-components/productos/views/directives/lineasproductotree.directive.html',
    link: linkFunc,
    controller: LineasProductoTreeController,
  };

  function linkFunc(scope, elem, attr) {
  }

  return directive;
}

LineasProductoTreeController.$inject =  ['$log', '$scope', 'filterFactory', 'LineasProductoFactory', 'ParametrosFactory'];
function LineasProductoTreeController($log, $scope, filterFactory, LineasProductoFactory, ParametrosFactory) {
  var vm = this;

  vm.isNivelMaximo = isNivelMaximo;

  vm.loadChildren = loadChildren;

  vm.use = use;


  activate();

  function activate() {
    getLineasProducto();
    vm.parametroCantMaxima = getParametroCantMaxima();
  }

  function getHijos(padre) {
    var params = {};
    params.search = filterFactory.and([
      {
        path: 'padre.id',
        equals: padre.id
      },
      {
        path: 'nivel',
        equals: padre.nivel + 1
      },
      {
        path: 'ultimoNivel',
        equals: false
      }
    ]).value();
    return LineasProductoFactory.all(params);
  }

  function getLineasProducto() {
    var params = {};
    params.search = filterFactory.and([{
      path: 'nivel',
      equals: 1
    }]).value();
    return LineasProductoFactory.all(params).$promise.then(function(data) {
      vm.producto = data;
      return vm.producto;
    });
  }

  function getParametroCantMaxima() {
    var params = {};
    params.search = filterFactory.single({
      path: 'codigo',
      equals: 'cantidad_niveles_lineas_producto'
    }).value();

    return ParametrosFactory.all(params);
  }

  function isNivelMaximo(nodo) {
    if(!vm.parametroCantMaxima) {
      return true;
    }
    if (nodo.nivel >= parseInt(vm.parametroCantMaxima[0].valor)) {
      return true;
    }
    return false;
  }

  function loadChildren(obj, post, modelo) {
    post(obj);
    modelo.hijos = getHijos(modelo);
  }

  function use(linea) {
    vm.modelo = linea;
  }

}

angular
  .module('qualita.productos')
  .directive('comboMultiPicker', comboMultiPicker);

function comboMultiPicker() {
  var directive = {
    restrict: 'E',
    scope: {
      modelo: '=',
      factory: '=',
      params: '=',
      titulo: '@',
      campo: '@'
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'qualita-components/productos/views/directives/combomultipicker.directive.html',
    link: linkFunc,
    controller: comboMultiPickerController,
  };

  function linkFunc(scope, elem, attr) {
  }

  return directive;
}

comboMultiPickerController.$inject =  ['$log', '$scope'];
function comboMultiPickerController($log, $scope) {
  var vm = this;

  vm.canAdd = false;

  vm.addPicker = addPicker;

  vm.cambioOpcion = cambioOpcion;

  vm.defaultSelected = defaultSelected;

  activate();

  function activate() {
    if(!vm.modelo || vm.modelo.length === 0) {
      vm.modelo = [{principal: true}];
      vm.defaultSeleccion = 0;
    }
    else if(vm.modelo) {
      vm.canAdd = true;
      //chequemos principal establecido
      for(var i=0; i<vm.modelo.length; i++) {
        if(vm.modelo[i].principal) {
          console.log("defaultSeleccionado cambiado a " + i);
          vm.defaultSeleccion = i;
        }
      }
    }

    if(!vm.params) vm.params = {};

    vm.opciones = vm.factory.all(vm.params);
  }

  function addPicker() {
    if(vm.modelo) {
      vm.modelo.push({});
      vm.canAdd = false;
    }
  }

  function cambioOpcion(index) {
    if(vm.modelo[index]) {
      vm.canAdd = true;
    }

  }

  function defaultSelected(index) {
    _.forEach(vm.modelo, function(modelo) {
      modelo.principal = false;
    });
    vm.modelo[index].principal = true;
  }
}

angular.module('qualita.productos').directive('uiSelectRequired', function () {
  return {
    require: 'ngModel', link: function (scope, elm, attrs, ctrl) {
      ctrl.$validators.uiSelectRequired = function (modelValue, viewValue) {

        var determineVal;
        if (angular.isArray(modelValue)) {
          determineVal = modelValue;
        } else if (angular.isArray(viewValue)) {
          determineVal = viewValue;
        } else {
          return false;
        }

        return determineVal.length > 0;
      };
    }
  };
});

'use strict';


angular.module('qualita.productos')
  .controller('ProductosIndexCtrl', ProductosIndexCtrl);

ProductosIndexCtrl.$inject = ["ProductosFactory", "$log", "$scope", "notify", "filterFactory", "ReportTicketFactory",
  "$window", "AuthorizationService", "$state", "ProductosLangFactory", "$rootScope",
  "WatchLocacionChangeFactory", "UtilFactory", "baseurl", "CsvFactory", "$filter", "ParametrosFactory", "$modal"
];

function ProductosIndexCtrl(ProductosFactory, $log, $scope, notify, filterFactory, ReportTicketFactory,
  $window, AuthorizationService, $state, ProductosLangFactory, $rootScope, WatchLocacionChangeFactory, UtilFactory,
  baseurl, CsvFactory, $filter, ParametrosFactory, $modal) {
  var vm = this;

  var renderEmptyData = function (data) {
    //console.log(data);
    if (data == undefined)
      return "";
    else
      return data;
  };

  var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
  var enviarAContabilidad = '';

  promise1.then(function(response) {
    enviarAContabilidad = response.valorTipo.codigo;
  });


  var defaultColumnOrder = ['codigo', 'nombre', 'codigoBalanza', 'codigoBarrasPrincipal.codigoBarras', 'claseProducto.descripcion', 'estado.descripcion', 'lineaProducto.nombre', 'marca.descripcion', 'unidadMedidaBase.descripcion',
    'proveedorPrincipal.nombre', 'controlarFechaVencimiento', 'incluirEnOrdenesCompra', 'itemCompra', 'itemVenta', 'controlStock',
    'ventaControlada', 'impuestos','nivel1.nombre','nivel2.nombre','nivel3.nombre','nivel4.nombre','nivel5.nombre','nivel6.nombre','nivel7.nombre','nivel8.nombre','nivel9.nombre'
  ];
  ProductosLangFactory.getTranslations().then(function (translations) {
    vm.options = {
      'resource': 'productos',
      'title': 'Productos',
      'view': "ProductoList",
      'factory': ProductosFactory,
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        { 'data': 'codigo', 'title': $filter('translate')('INTERNAL_CODE') },
        { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
        { 'data': 'codigoBalanza', 'title': $filter('translate')('BALANS_CODE'), 'render': renderEmptyData, 'class': 'dt-right' },
        { 'data': 'criterioControl.descripcion', 'title': $filter('translate')('ADMINISTRATION_FORM'), 'render': renderEmptyData },
        { 'data': 'codigoBarrasPrincipal.codigoBarras', 'title': $filter('translate')('BAR_CODE'), 'render': renderEmptyData, 'class': 'dt-right' },
        { 'data': 'claseProducto.descripcion', 'title': $filter('translate')('CLASS') },
        { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/filtro/producto_estados', 'class': 'dt-center' },
        { 'data': 'lineaProducto.nombre', 'title': $filter('translate')('LINE'), 'name': "Linea de Producto" },
        { 'data': 'marca.descripcion', 'title': $filter('translate')('BRAND'), 'render': renderEmptyData },
        { 'data': 'unidadMedidaBase.descripcion', 'title': $filter('translate')('MESSURE_UNIT'), visible: true },
        { 'data': 'proveedorPrincipal.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX'), visible: true },
        { 'data': 'itemCompra', 'title': $filter('translate')('PURCHASE_ITEM'), 'class': 'dt-center', visible: false, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
        { 'data': 'itemVenta', 'title': $filter('translate')('SALE_ITEM'), 'class': 'dt-center', visible: false, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
        { 'data': 'controlStock', 'title': $filter('translate')('STOCK_CONTROL_Q'), visible: false, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
        { 'data': 'nivel1.nombre', 'title': "Nivel 1", 'name': "Nivel 1", visible: false, 'render': renderEmptyData},
        { 'data': 'nivel2.nombre', 'title': "Nivel 2", 'name': "Nivel 2", visible: false, 'render': renderEmptyData},
        { 'data': 'nivel3.nombre', 'title': "Nivel 3", 'name': "Nivel 3", visible: false, 'render': renderEmptyData},
        { 'data': 'nivel4.nombre', 'title': "Nivel 4", 'name': "Nivel 4", visible: false, 'render': renderEmptyData},
        { 'data': 'nivel5.nombre', 'title': "Nivel 5", 'name': "Nivel 5", visible: false, 'render': renderEmptyData},
        { 'data': 'nivel6.nombre', 'title': "Nivel 6", 'name': "Nivel 6", visible: false, 'render': renderEmptyData},
        { 'data': 'nivel7.nombre', 'title': "Nivel 7", 'name': "Nivel 7", visible: false, 'render': renderEmptyData},
        { 'data': 'nivel8.nombre', 'title': "Nivel 8", 'name': "Nivel 8", visible: false, 'render': renderEmptyData},
        { 'data': 'nivel9.nombre', 'title': "Nivel 9", 'name': "Nivel 9", visible: false, 'render': renderEmptyData},
        // {'data': 'controlarFechaVencimiento', 'title': $filter('translate')('CHECK_EXPIRATION_DATE'), visible: false, 'class' : 'dt-center'},
        // {'data': 'incluirEnOrdenesCompra', 'title': $filter('translate')('PURCHASE_ORDER'), visible: false, 'class' : 'dt-center'},
        // {'data': 'ventaControlada', 'title': $filter('translate')('SALE_CONTROL_Q'), visible: false, 'class' : 'dt-center'},
        { 'data': 'impuestos', 'title': $filter('translate')('TAX_TYPE'), visible: true }
      ],
      'hasOptions': true,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'failedDeleteError': 'No se pudo borrar el producto. Verifique si no hay operaciones asociadas.',
      'extraMenuOptions': [{
        'title': 'Reporte',
        'icon': 'glyphicon glyphicon-file',
        'showCondition': function () { return true; },
        'action': function () {
          var filters = createFilters(vm.options.getFilters());

          //var filters = createFilters(vm.options.getFilters());
          ReportTicketFactory.ticket('listadoProductos', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function (genTicket) {
            $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
            $window.open($scope.pdfDownloadURL, '_blank');
          });
        }
      },
      {
        'title': 'CSV',
        'icon': 'glyphicon glyphicon-export',
        'showCondition': function () { return true; },
        'action': function () {
          var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

          CsvFactory.csv("productos", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
            .then(function (response) {
              console.log("response de csv: ", response);
              var fileName = response.data.fileName;
              $window.open(baseurl.getPublicBaseUrl() + fileName);
            });
        }
      }
      ],
      'extraRowOptions': [
        {
          templateToRender: "<button title='Editar Detalles' class='btn btn-success' style='margin-right: 5px;' ng-click='editarDetalles(<%=dataId%>)' ng-class='{ hidden : !canEditarDetalles(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-tasks'></span> </button>",
          customAttribute: 'estado',
          conditionName: "canEditarDetalles",
          conditionDef: function () {
            return (AuthorizationService.hasPermission("update_productos"));
          },
          functionName: "editarDetalles",
          functionDef: function (itemId) {

            $state.go('app.productos.edit2', { id: itemId });

          }
        },
        {
          templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
          functionName: 'reenviar',
          customAttribute: ['enviadoAContabilidad', 'id'],
          functionDef: function (atributo) {

            var idProducto = atributo[1];

            ProductosFactory.get(idProducto, "ProductoList").$promise.then(function (response) {

              $scope.tituloModal = $filter('translate')('REENVIAR_PRODUCTO');
              $scope.mensajeModal = $filter('translate')('REENVIAR_PRODUCTO_MSG');
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              $scope.ok = function (id) {
                
                ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {

                  if (parametro.valorTipo.codigo == 'si') {
                    ProductosFactory.reenviar(response).then(function (responseReenviar) {

                      if (responseReenviar.data.enviadoAContabilidad == false) {
                        notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                      }
                      $state.go("app.productos.list", { id: responseReenviar.id }, { reload: true });
                    }, function (error) {
                      var msg = "";
                      if (error.data && error.data.length > 0 && error.data[0].message) {
                        msg = error.data[0].message;
                      } else {
                        msg = $filter('translate')('REENVIAR_PRODUCTO_FAILED');
                      }

                      notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    });
                  } else {
                    var msg = "";
                    msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  }
                });
                modalInstance.close();
              }
            });
          },
          conditionName: 'canReenviar',
          conditionDef: function (atributo) {
            var enviadoAContabilidad = atributo[0];
            if (enviarAContabilidad == 'si') {
              if (enviadoAContabilidad === "No") {
                return true;
              }
            }
          }
        }
      ]
    };

    WatchLocacionChangeFactory.watchWith(undefined, vm.options, "locaciones.id");
  });


  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      if (search === 'No' && (data === 'itemCompra' || data === 'itemVenta' || data === 'controlStock')) {
        search = 'false';
      }
      if (search === 'Sí' && (data === 'itemCompra' || data === 'itemVenta' || data === 'controlStock')) {
        search = 'true';
      }
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.productos')
  .controller('ProductosFormCtrl', ProductosFormCtrl);

ProductosFormCtrl.$inject = ['$scope', 'ProductosFactory', '$location', '$state', 'ProveedoresFactory',
  'CriteriosControlProductoFactory', 'EstadosProductoFactory', 'ClaseProductoFactory', 'MarcasFactory', 'ParametrosFactory',
  'filterFactory', 'TipoImpuestosFactory', 'LineasProductoFactory', 'UnidadMedidaFactory', '$rootScope', 'TiposTipoFactory',
  'AuthorizationService', 'notify', 'productoPrepService', 'baseurl', 'ProveedorSucursalesFactory', 'ModelTrimmer',
  'ProductosLangFactory', 'WatchLocacionChangeFactory', 'UtilFactory', '$filter', '$timeout', 'ComprobanteFactory', 'RecepcionFactory',
  'ComprobanteVentaFactory', 'RemisionFactory'];

function ProductosFormCtrl($scope, ProductosFactory, $location, $state, ProveedoresFactory,
   CriteriosControlProductoFactory, EstadosProductoFactory, ClaseProductoFactory, MarcasFactory,
   ParametrosFactory, filterFactory, TipoImpuestosFactory, LineasProductoFactory, UnidadMedidaFactory,
   $rootScope, TiposTipoFactory, AuthorizationService, notify, productoPrepService, baseurl,
   ProveedorSucursalesFactory, ModelTrimmer, ProductosLangFactory, WatchLocacionChangeFactory, UtilFactory, $filter, $timeout, ComprobanteFactory,
   RecepcionFactory, ComprobanteVentaFactory, RemisionFactory) {

  var vm = this;

  vm.codigoInternoManual = false;
  vm.adminFormNormal = true;
  vm.proveedoresFactory = ProveedoresFactory;
  vm.unidadesMedidaFactory = UnidadMedidaFactory;
  vm.agregar = agregar;
  vm.codigoBarrasAdded = codigoBarrasAdded;
  vm.cancel = cancel;
  vm.checkCodBalanzaMaxLength = checkCodBalanzaMaxLength;
  vm.checkCodInternoMaxLength = checkCodInternoMaxLength;
  vm.checkIfDefaultProveedorInitialized = checkIfDefaultProveedorInitialized;
  vm.claseProductoSelected = claseProductoSelected;
  vm.codigoBarrasRemoved = codigoBarrasRemoved;
  vm.codigoBarrasSelected = codigoBarrasSelected;
  vm.proveedorRemoved = proveedorRemoved;
  vm.setProveedorDefault = setProveedorDefault;
  vm.submit = submit;
  vm.unidadMedidaBaseSelected = unidadMedidaBaseSelected;
  vm.stockControlValidate = stockControlValidate;
  vm.criterioControlSelected = criterioControlSelected;
  vm.refreshReferencias = refreshReferencias;
  vm.delayRequestProveedores = delayRequestProveedores;
  vm.changeIncluirOrdenCompra = changeIncluirOrdenCompra;
  vm.unidadMedidaSelected = unidadMedidaSelected;
  vm.unidadMedidaUnSelected = unidadMedidaUnSelected;

  activate();

  function activate() {
    vm.canChangeUnidadMedida = true;
    WatchLocacionChangeFactory.killAllWatchers();
    ProductosLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      vm.estados = getEstados();
      vm.criteriosControl = getCriterioControl();
      vm.clasesProducto = getClaseProducto();

      ParametrosFactory.getByCodigo('nivel_linea_producto_asociar_lista_precio').then(function(parametro){
        vm.nivelLineaListaPrecio = parametro.valor
      });

      ParametrosFactory.getByCodigo('asignacion_lista_precios_linea_producto_por_segmento').then(function(parametro){
        vm.asignarlistalinea = parametro.valor
      });
      vm.countries = [
        {name: 'Afghanistan', code: 'AF'},
        {name: 'Åland Islands', code: 'AX'},
        {name: 'Albania', code: 'AL'},
        {name: 'Algeria', code: 'DZ'},
        {name: 'American Samoa', code: 'AS'},
        {name: 'Andorra', code: 'AD'},
        {name: 'Angola', code: 'AO'},
        {name: 'Anguilla', code: 'AI'},
        {name: 'Antarctica', code: 'AQ'},
        {name: 'Antigua and Barbuda', code: 'AG'}];

      if ($state.is("app.productos.new")) {
        activateNew();
      } else if ($state.is("app.productos.edit")) {
        activateEdit();
      } else if ($state.is("app.productos.view")) {
        activateView();
      }

      if(vm.watcher) { vm.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
        vm.watcher = watcher;
        vm.locacionFilter = locacionFilter
        //vm.productos = getProductos(locacionFilter);
      }, "locaciones.id");
      if ($state.is("app.productos.new")) {
        vm.lineasProducto = getLineasProducto();
      }
      vm.marcas = getMarcas();
      vm.tiposImpuesto = getTiposImpuesto();
      vm.unidadesMedidaBase = getUnidadesMedidaBase();
      vm.unidadMedidaPresentacion = getUnidadesMedidaPresentacion();
      vm.unidadMedidaContenida = getUnidadesMedidaContenida();
      vm.calcularPesoPorPresentacion = calcularPesoPorPresentacion;
      procesarParametros();
      $rootScope.isProcessing = false;

      //observamos la generacion de accesos para asociar a locaciones de nuestro modelo Producto
      $scope.$watchCollection('vm.accesos', function (newValue, oldValue) {
        if (!newValue || vm.accesos.length == 0) {
          return;
        }

        vm.producto.locaciones = _.map(newValue, function (acceso) {
          return acceso.locacion;
        })
      });
      $scope.$watchCollection('[vm.producto.lineaProducto, vm.nivelLineaListaPrecio]', function (newValue) {
        var linea = newValue[0];
        var nivel = newValue[1];
        if (linea && nivel) {
          if(linea.padre){
            validarNivel(linea)
          }
        }
      }, true);
      $timeout(function(){
        $scope.$broadcast('formOpened');
      }, 20);

    });
  }

  function changeIncluirOrdenCompra(){
    if(vm.producto.incluirEnOrdenesCompra === true){
        console.log("Incluir orden compra true");
        vm.producto.itemCompra = true;
    }
  }

  function validarNivel(linea){
    if(linea.nivel == vm.nivelLineaListaPrecio){
      vm.lineaListaPrecio = linea.codigo + " - " + linea.nombre
    } else {
      if(linea.padre){
        validarNivel(linea.padre)
      }
    }
  }

  function stockControlValidate(){

    vm.productUsed = false;

    // Si el producto existe en compras, con comprobante 'aprobado' o con recepcion 'aprobada'

    if(vm.producto.itemCompra){

        // FILTRO PARA COMPROBANTES DE COMPRA
        var filtroComprobantesCompra = {};
        filtroComprobantesCompra.search = filterFactory.and([
          {
            path: 'comprobanteDetalle.producto.id',
            equals: vm.producto.id
          },
          {
            path: 'estado.codigo',
            like: "comp_aprobado"
          }
        ]).value();

        //SE OBTIENEN LOS COMPROBANTES DE COMPRA FILTRADOS
        vm.comprobantes = ComprobanteFactory.all(filtroComprobantesCompra, "importacionView");
        var promise = vm.comprobantes.$promise.then(function(comprobantes) {
            if(comprobantes.length > 0){
              vm.productUsed = true;
            }

        });

        // FILTRO PARA RECEPCIONES
        var filtroRecepciones= {};
        filtroRecepciones.search = filterFactory.and([
          {
            path: 'recepcionDetalle.producto.id',
            equals: vm.producto.id
          },
          {
            path: 'estadoRecepcion.codigo',
            equals: "er_aprobado"
          }
        ]).value();

        //SE OBTIENEN LAS RECEPCIONES FILTRADAS
        vm.recepciones = RecepcionFactory.all(filtroRecepciones, "importacionView");
        var promise = vm.recepciones.$promise.then(function(recepciones) {
            if(recepciones.length > 0){
              vm.productUsed = true;
            }
        });
    }

    if(vm.producto.itemVenta){

    // Si el producto existe en ventas, con comprobante 'aprobado' o con remision 'aprobada'

        // FILTRO PARA COMPROBANTES DE VENTA
        var filtroComprobantesVenta = {};
        filtroComprobantesVenta.search = filterFactory.and([
          {
            path: 'comprobanteDetalle.producto.id',
            equals: vm.producto.id
          },
          {
            path: 'estado.codigo',
            equals: "comp_venta_emitido"
          }
        ]).value();

        //SE OBTIENEN LOS COMPROBANTES DE VENTA FILTRADOS
        vm.comprobantesVenta = ComprobanteVentaFactory.all(filtroComprobantesVenta, "importacionView");
        var promise = vm.comprobantesVenta.$promise.then(function(comprobantes) {
            if(comprobantes.length > 0){
              vm.productUsed = true;
            }
        });

        //FILTRO PARA REMISIONES
        var filtroRemisiones = {};
        filtroRemisiones.search = filterFactory.and([
          {
            path: 'remisionDetalle.producto.id',
            equals: vm.producto.id
          },
          {
            path: 'estado.codigo',
            equals: "estados_remision_emitido"
          }
        ]).value();

        //SE OBTIENEN LAS REMISIONES FILTRADAS
        vm.remisiones = RemisionFactory.all(filtroRemisiones);
        var promise = vm.remisiones.$promise.then(function(remisiones) {
            if(remisiones.length > 0){
              vm.productUsed = true;
            }
        });
      }
  }

  function activateNew() {
    vm.canChangeCriterioControl = true;
    vm.title = $filter('translate')('NEW_PRODUCT');
    vm.producto = {};
    vm.estados.$promise.then(function (estados) {
      var estadoActivo = _.find(vm.estados, function (estado) {
        return estado.codigo === "activo";
      });
      if (estadoActivo) {
        vm.producto.estado = estadoActivo;
      }
      return estados;
    });

    vm.criteriosControl.$promise.then(function (criterios) {
          vm.criteriosControl= criterios;
    });
    vm.uploadOptions1 = {
      imageOnly: true,
      title: 'Foto'
    };
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_PRODUCT');
    vm.producto = productoPrepService;
    $scope.entidadId = vm.producto.id;
    $scope.entidad = "Producto";
    //se obtiene la clase, si es bobina no se permite cambiar el criterio de control
    ClaseProductoFactory.get(vm.producto.claseProducto.id, "ClaseProductoList").$promise.then(function(clase) {
      vm.producto.claseProducto = clase;
      vm.canChangeCriterioControl = true;

      if(clase.tipoProducto.nombre === "Activo Fijo"){
        vm.canChangeCriterioControl = false;
      }
      if (clase.tipoAdministracion.codigo == 'bobinas') {
        vm.canChangeCriterioControl = false;
        vm.canChangeUnidadMedida = false;
      } 
      // else {
      //   vm.canChangeCriterioControl = true;
      // }
    });
    vm.criteriosControl.$promise.then(function (criterios) {
          vm.criteriosControl= criterios;
    });
    initCustomAtributos();
    var imagePath = "images/placeholder.png";
    if (vm.producto.imagen && vm.producto.imagen !== false)
      imagePath = baseurl.getPublicBaseUrl() + vm.producto.imagen
    vm.uploadOptions1 = {
      imageOnly: true,
      title: 'Foto',
      imagePath: imagePath
    };
    vm.uploadOptions2 = {
      imageOnly: false,
      //title: 'Adjunto'
    };
    adjustUnidadesMedidaArray(true);
    adjustCodigosBarrasArrayCustom();
    adjustTiposImpuestoArray(true);
    getUnidadesMedidaOptions();
    vm.sucursalesProveedor = getSucursalesProveedorPrincipal();
    vm.isEdit = true;

    stockControlValidate();
    criterioControlSelected();
    if(vm.producto.claseProducto.tipoProducto.nombre === "Activo Fijo") {
      vm.lineasProducto = getLineasProducto({
        path: "clase.codigo",
        equals: "activo_fijo"
      });
    } else {
      vm.lineasProducto = getLineasProducto({
        path: "clase.codigo",
        equals: "producto"
      });
    }
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_PRODUCT');
    vm.producto = productoPrepService;
    $scope.entidadId = vm.producto.id;
    $scope.entidad = "Producto";
    initCustomAtributos();
    var imagePath = "images/placeholder.png";
    if (vm.producto.imagen && vm.producto.imagen !== false)
      imagePath = baseurl.getPublicBaseUrl() + vm.producto.imagen
    vm.uploadOptions1 = {
      imageOnly: true,
      title: 'Foto',
      imagePath: imagePath
    };
    $scope.view = true;
    adjustUnidadesMedidaArray(true);
    //adjustCodigosBarrasArray(true);   // no permite visualizar el codigo de barras en modo view
    adjustTiposImpuestoArray(true);
    vm.isView = true;
  }


  function adjustTiposImpuestoArray(didFail) {
    //ATENCION: Se hace porque en el backend, tiposImpuesto es ManyToMany para dar soporte a futuro, pero actualmente
    //solo un tipoImpuesto puede estar asociado al producto
    if(!vm.producto.tiposImpuesto) {
      return;
    }

    if (didFail) {
      vm.producto.tiposImpuesto = vm.producto.tiposImpuesto[0];
    } else {
      vm.producto.tiposImpuesto = [vm.producto.tiposImpuesto];
    }
  }

  function adjustUnidadesMedidaArray(didFail) {
    if (didFail) {
      _.remove(vm.producto.unidadesMedida, function (unidadMedida) {
        return unidadMedida.id === vm.producto.unidadMedidaBase.id;
      });
    } else {
      if (vm.producto.unidadesMedida) {
        vm.producto.unidadesMedida.unshift(vm.producto.unidadMedidaBase);
      } else {
        vm.producto.unidadesMedida = [vm.producto.unidadMedidaBase];
      }
    }
  }

  // TODO algo anda mal con la recuperacion del codigo de barra al ingresar desde editar/ver
  function adjustCodigosBarrasArray(didFail) {
    if (didFail) {
      if (vm.producto.codigosBarras && vm.producto.codigosBarras.length > 0) {
        vm.producto.codigosBarras.unshift(vm.producto.codigoBarrasPrincipal);
      } else {
        if (vm.producto.codigoBarrasPrincipal)
          vm.producto.codigosBarras = [vm.producto.codigoBarrasPrincipal];
      }
    } else {
      /*_.remove(vm.producto.codigosBarras, function (codigoBarras) {
        if (codigoBarras.id) {
          return codigoBarras.id === vm.producto.codigoBarrasPrincipal.id;
        }
        return codigoBarras.codigoBarras == vm.producto.codigoBarrasPrincipal.codigoBarras;
      });*/
    }
  }

  function adjustCodigosBarrasArrayCustom() {
    vm.producto.codigosBarras = _.union(vm.producto.codigosBarras, vm.producto.codigoBarrasPrincipal);
  }

  function submit() {
    vm.submited = true;
    console.log("size: ", vm.producto.unidadesMedida);
    if(vm.producto.criterioControl.codigo == 'series' && vm.producto.unidadMedidaBase.descripcion.toLowerCase() != 'unidad'){
      var error = 'La forma de administracion series solo puede ser utilizada con unidad de medida base tipo Unidad';
      notify({message: error, classes: 'alert-danger', position: 'right'});
    }else if(vm.producto.criterioControl.codigo == 'series' && vm.producto.unidadMedidaBase.descripcion.toLowerCase() == 'unidad' && (vm.producto.unidadesMedida != undefined && vm.producto.unidadesMedida.length !=0)){
      var error = 'La forma de administracion series no debe tener Unidad Secundaria';
      notify({message: error, classes: 'alert-danger', position: 'right'});
    }else if(vm.producto.stockMinimo > vm.producto.stockMaximo) {
      notify({ message: "El stock máximo no puede ser menor al stock mínimo", classes: 'alert-danger', position: 'right' });
    }else if ($scope.ProductoForm.$valid && !vm.codigoBarrasIncorrectoMsgs ) {
      $rootScope.isProcessing = true;
      convertirAtributoAString();
      // se adjuntan archivos
      adjuntarArchivos(vm.producto);
      adjustUnidadesMedidaArray();
      adjustCodigosBarrasArray();
      adjustTiposImpuestoArray();
      submitProducto().then(function (producto) {
        clearAdjuntos();
        $location.path('/productos');
        ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
          if (parametro.valorTipo.codigo == 'si') {
              if(producto.enviadoAContabilidad == false){
                  notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
              }
          }
      });
      }, function (error) {
        $rootScope.isProcessing = false;
        console.log("Error al persistir entidad: ");
        console.log(error);
        var msg = error.data[0].message;
        _.forEach(error.data, function(e) {
          if(e.constraint=='nombre'){
            msg += '\n\n' + $filter('translate')('PRODUCT_EXIST')+"\n"
          }else if(e.constraint=='codigobarras'){
            msg += '\n\n' + $filter('translate')('REPEATED_CODE_BAR')+'\n'
          } else {
            if(e.constraint=='codigo_balanza'){
              msg += '\n\n' + $filter('translate')('SCALE_CODE_EXIST')+"\n"
            }
          }
        });
        notify({message: msg, classes: 'alert-danger', position: 'right'});
        adjustUnidadesMedidaArray(true);
        adjustCodigosBarrasArray(true);
        adjustTiposImpuestoArray(true);
      });
    } else {
      $rootScope.isProcessing = false;
    }

    function submitProducto() {
      var trimmedProducto = ModelTrimmer.trimDetails(vm.producto, ['atributos']);
      if(!vm.isEdit) {
        trimmedProducto.locaciones = [$rootScope.AuthParams.locacionSeleccionada.id];
      }
      var producto = ProductosFactory.create(trimmedProducto);
      return ProductosFactory.save(producto);
    }

    vm.agregar = agregar;
  }

  function getDigitoVerificador(codigo) {
    var sumaPares = 0;
    var sumaImpares = 0;

    for (var i = 0; i < (codigo.length - 1); i++) {
      var caracter = codigo.charAt(i);
      var c = parseInt(caracter);
      if (!c) {
        console.log("!c");
        continue;
      }
      if ((i + 1) % 2 == 0) {
        sumaPares += c;
      } else {
        sumaImpares += c;
      }
    }

    var multPares = sumaPares * 13;

    var sumaTotal = multPares + sumaImpares;

    var multParesStr = sumaTotal.toString();

    var lengthStr = multParesStr.length;

    var digito = multParesStr.charAt(lengthStr - 1);

    var digitoInt = 10 - parseInt(digito);

    var resultado = digitoInt == 10 ? 0 : digitoInt;

    return resultado;
  }

  function codigoBarrasAdded(tag) {
    calculoDigitoVerificador(tag);
    if (!vm.producto.codigoBarrasPrincipal) {
      setCodigoBarrasDefault(tag);
    }
  }

  function calculoDigitoVerificador(tag) {
    if (!vm.producto.claseProducto) {
      return;
    }

    if (!vm.producto.claseProducto.calulcoVerificador) {
      console.log("la clase de producto no cuenta con calculo de digito verificador");
      return;
    }

    var pad = "0000000000000"
    tag.codigoBarras = pad.substring(0, pad.length - tag.codigoBarras.length) + tag.codigoBarras;
    var codigo = tag.codigoBarras;
    var digitoVerificador = getDigitoVerificador(codigo);
    var digitoStr = digitoVerificador.toString();

    if (digitoStr !== codigo.charAt(codigo.length - 1)) {
      var mensaje = $filter('translate')('ERROR_CODE_BAR')+ codigo + ", "+$filter('translate')('WRONG_VERIFICATION_DIGIT') +digitoVerificador;
      var objeto = {mensaje: mensaje, codigo: codigo};

      if (!vm.codigoBarrasIncorrectoMsgs) {
        vm.codigoBarrasIncorrectoMsgs = [objeto];
      } else {
        vm.codigoBarrasIncorrectoMsgs.push(objeto);
      }
    }
  }

  function codigoBarrasRemoved(tag) {
    if (!vm.producto.claseProducto) {
      return;
    }

    var codigo = tag.codigoBarras;

    if (vm.producto.codigoBarrasPrincipal.codigoBarras === codigo) {
      if (vm.producto.codigosBarras.length > 0) {
        vm.producto.codigoBarrasPrincipal = vm.producto.codigosBarras[0];
      } else {
        delete vm.producto.codigoBarrasPrincipal;
      }
    }

    if (!vm.producto.claseProducto.calulcoVerificador) {
      console.log("la clase de producto no cuenta con calculo de digito verificador");
      return;
    }


    if (!vm.codigoBarrasIncorrectoMsgs) {
      return;
    }

    _.remove(vm.codigoBarrasIncorrectoMsgs, function (error) {
      return error.codigo === codigo;
    });

    if (vm.codigoBarrasIncorrectoMsgs.length === 0) {
      delete vm.codigoBarrasIncorrectoMsgs;
    }
  }

  function codigoBarrasSelected($tag) {
    var elementos = angular.element("tags-input .tags .tag-item");
    angular.element(elementos).removeClass("elem-selected");
    var elem = _.find(elementos, function(elemento) {
      var texto = elemento.innerText;
      //separamos el espacio con la x del texto
      var textoStr = texto.split(" ");
      if(!textoStr) {
        return false;
      }

      var codigo = textoStr[0];
      return codigo === $tag.codigoBarras;
    });
    if(elem) {
      console.log("Elemento obtenido: ");
      angular.element(elem).addClass("elem-selected");
      console.log(elem);
    }
    setCodigoBarrasDefault($tag);
  }

  function setCodigoBarrasDefault(item) {
    vm.producto.codigoBarrasPrincipal = item;
  }


  function cancel() {
    $location.path("/productos");
  }

  function checkCodBalanzaMaxLength() {
    var longitudCod = _.find(vm.parametros, function (parametro) {
      return parametro.codigo === "longitud_codigo_balanza";
    });

    if (!longitudCod) {
      console.log("longitud de codigo no presente");
      return;
    }
    var longMax = parseInt(longitudCod.valor);
    if (vm.producto.codigoBalanza) {
      var long = vm.producto.codigoBalanza.toString().length;
      if (long > longMax) {
        var codstr = vm.producto.codigoBalanza.toString().substring(0, longMax);
        vm.producto.codigoBalanza = parseInt(codstr);
      }
    }
  }

  function checkCodInternoMaxLength() {
    var longitudCod = _.find(vm.parametros, function (parametro) {
      return parametro.codigo === "longitud_codigo_interno";
    });

    if (!longitudCod) {
      return;
    }

    var longMax = parseInt(longitudCod.valor);
    if (vm.producto.codigo) {
      var long = vm.producto.codigo.toString().length;
      if (long > longMax) {
        var strCod = vm.producto.codigo.toString().substring(0, longMax);
        vm.producto.codigo = parseInt(strCod);
      }
    }
  }

  function claseProductoSelected(item) {
    /* Limpiar codigos, estos campos son dependientes de la clase */
    vm.producto.codigoBalanza = null;
    vm.producto.codigosBarras = [];
    vm.producto.codigoBarrasPrincipal = null;
    vm.canChangeUnidadMedida = true;

    /* Si es un producto pesable solo se recuperan unidades de medida pesables */
    (item.tipoAdministracion.codigo == "pesables") ? vm.unidadesMedidaBase = getUnidadesMedidaBaseDePeso() : vm.unidadesMedidaBase = getUnidadesMedidaBase()

    vm.producto.atributos = _.map(item.tipoProducto.atributos, function (atributo) {
      return {
        definicionAtributo: {id: atributo.id, nombre: atributo.nombre, codigo: atributo.codigo, tipoDato: atributo.tipoDato}
      }
    });

    delete vm.producto.lineaProducto;
    if(item.tipoProducto.nombre === "Activo Fijo") {
      vm.lineasProducto = getLineasProducto({
        path: "clase.codigo",
        equals: "activo_fijo"
      });
    } else {
      vm.lineasProducto = getLineasProducto({
        path: "clase.codigo",
        equals: "producto"
      });
    }

    vm.atributosOptions = {};
    _.forEach(vm.producto.atributos, function (atributo) {
      var td = atributo.definicionAtributo.tipoDato;
      var coincidencias = _.find(TiposTipoFactory.tiposValorDefault, function (elem) {
        return elem == td
      });
      if (!coincidencias) {
        console.log(td);
        vm.atributosOptions[td] = TiposTipoFactory.get(td);
      }
    });
    if(vm.producto.claseProducto.tipoProducto.nombre === "Activo Fijo") {
      vm.producto.criterioControl = _.find(vm.criteriosControl, function(elem) {
        return elem.codigo === 'series';
      });
      vm.canChangeCriterioControl = false;
    } else if (vm.producto.claseProducto.tipoAdministracion.codigo == 'bobinas') {
    // si el tipo de administracion es bobina se pone forma de administracion lote por defecto
      vm.producto.criterioControl = _.find(vm.criteriosControl, function (elem) {
        return elem.codigo == 'lotes';
      });
      vm.canChangeCriterioControl = false;

      vm.unidadesMedidaBase.$promise.then(function(unidades){
        vm.producto.unidadMedidaBase = _.find(unidades, function (elem) {
          return elem.descripcion == 'kilogramos';
        });
        vm.canChangeUnidadMedida = false;
      });

    } else {
      vm.canChangeCriterioControl = true;
    }

    //Si está el atributo gramaje se habilitan solo las unidades de medida de peso
    /*if(_.find(vm.producto.atributos, function(atributo) { return atributo.definicionAtributo.codigo == "gramaje"; })) {
      console.log("Está el atributo gramaje");
      vm.unidadesMedidaBase = getUnidadesMedidaBaseDePeso();
    } else {
      console.log("No está el atributo gramaje");
      vm.unidadesMedidaBase = getUnidadesMedidaBase();
    }*/
  }

  function convertirAtributoAString() {
    //convertimos en String el codigo de atributo
    _.forEach(vm.producto.atributos, function (atributo) {
      var coincidencias = _.find(TiposTipoFactory.tiposValorDefault, function (elem) {
        return elem == atributo.definicionAtributo.tipoDato;
      });
      if (!coincidencias && atributo.valor && atributo.valor.id) {
        atributo.valor = atributo.valor.id.toString();
      }
    });
  }

  function getLineasProducto(filtro) {
    var params = {};
    var filtros = [{
      path: 'ultimoNivel',
      equals: true
    }];
    if(filtro) {
      filtros.push(filtro);
    }
    params.search = filterFactory.and(filtros).value();
    params.view = "BaseList";

    return LineasProductoFactory.all(params);
  }

  function getParametros() {
    var params = {};
    params.search = filterFactory.single({
      path: 'modulo.codigo',
      equals: 'producto'
    }).value();

    return ParametrosFactory.all(params);
  }

  function getUnidadesMedidaBase() {
    var filtros = {};
    filtros.search = filterFactory.single({
      path: "esBase",
      equals: true
    }).value();
    filtros.view = "BaseList";

    return UnidadMedidaFactory.all(filtros);
  }

  function getUnidadesMedidaPresentacion(){
    var filtros = {};
    filtros.search = filterFactory.single({
      path: "esBase",
      equals: false
    }).value();
    filtros.view = "BaseList";

    return UnidadMedidaFactory.all(filtros);
  }

  function getUnidadesMedidaContenida(){
    var filtros = {};
    filtros.search = filterFactory.single({
      path: "esBase",
      equals: true
    }).value();
    filtros.view = "BaseList";

    return UnidadMedidaFactory.all(filtros);
  }

  function calcularPesoPorPresentacion(){
    console.log("UNIDAD BASE");
   if(vm.producto.conversion == null){
    notify({ message: 'Se requiere de la conversion para calcular el Peso por Presentación', classes: 'alert-info', position: 'right' })
    return;
   }
    vm.producto.pesoUnidadPresentacion = roundNumber(vm.producto.pesoUnidadBase * vm.producto.conversion,4);
    return;
  }

  function getUnidadesMedidaBaseDePeso() {

    var filtros = {};
    filtros.search = filterFactory.and([{
      path: "esBase",
      equals: true
    }, {
      path: "dePeso",
      equals: true
    }]).value();

    filtros.view = "BaseList";
    return UnidadMedidaFactory.all(filtros);
  }

  function roundNumber(num, scale) {
    if(!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale)  + "e-" + scale);
    } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if(+arr[1] + scale > 0) {
        sig = "+";
      }
      var i = +arr[0] + "e" + sig + (+arr[1] + scale);
      var j = Math.round(i);
      var k = +(j + "e-" + scale);
      return k;
    }
  }

//Funcion que verifica que todavia no se haya escogido proveedor por defecto. Si no hay proveedor,
//setea al elemento agregado
  function checkIfDefaultProveedorInitialized(item, model) {
    //Aprovechamos para cargar las opciones para el selector de sucursal
    if (vm.producto.proveedorPrincipal) {
      return;
    }

    setProveedorDefault(item);
  }


  function adjuntarArchivos(producto) {
    // se agregan los adjuntos de imagenes
    var flowImage = vm.uploadOptions1.flow;
    if (flowImage) {
      var flowFilesImage = flowImage.files;
      if (flowFilesImage.length > 0) {
        angular.forEach(flowFilesImage, function (file) {
          producto.imagen = file.uniqueIdentifier + '-' + file.name;
          //console.log("producto.imagen: %o", producto.imagen);
        });
      }
    }
    // se agregan los adjuntos de imagenes

  }

  function descargarAdjunto(adjunto) {
    return baseurl.getPublicBaseUrl() + adjunto;
  }


  function clearAdjuntos() {
    var flow = $rootScope.flow;
    if (!flow) {
      return;
    }
    flow.files = [];
  };

  function proveedorRemoved(item, model) {
    if (!item.id) {
      return;
    }

    if (item.id === vm.producto.proveedorPrincipal.id) {
      vm.producto.proveedorSucursal = undefined;
      if (vm.producto.proveedores && vm.producto.proveedores.length > 0) {
        setProveedorDefault(vm.producto.proveedores[0]);
      } else {
        vm.sucursalesProveedor = undefined;
        vm.producto.proveedorPrincipal = undefined;
      }
    }
  }

  function getSucursalesProveedorPrincipal() {
    var filtros = {};
    filtros.search = filterFactory.single({
      path: 'proveedor.id',
      equals: vm.producto.proveedorPrincipal.id
    }).value();
    filtros.view = "BaseList";
    return ProveedorSucursalesFactory.all(filtros);
  }

  function setProveedorDefault(item) {
    if (!item.id) {
      return;
    }

    vm.producto.proveedorPrincipal = item;
    vm.sucursalesProveedor = getSucursalesProveedorPrincipal();
    delete vm.producto.proveedorSucursal;
  }

  function getUnidadesMedidaOptions() {
    vm.unidadesMedida = UnidadMedidaFactory.getByBase(vm.producto.unidadMedidaBase.id);
    vm.unidadesMedida.$promise.then(function(unidades){

     _.forEach(vm.producto.unidadesMedida, function (unidadProducto) {
       var dato = _.find(unidades, function (elem) {
         return elem.id === unidadProducto.id;
       });
       //vm.unidadesMedida = vm.unidadesMedida.filter((i) => i.id !== dato.id);

         vm.unidadesMedida = _.filter(vm.unidadesMedida, function(unidad) {
             return unidad.id !== dato.id;
         });

       });
     });
   }

  function unidadMedidaBaseSelected() {
    if (!vm.producto.unidadMedidaBase) {
      return;
    }

    vm.unidadesMedida = UnidadMedidaFactory.getByBase(vm.producto.unidadMedidaBase.id);
    delete vm.producto.unidadesMedida;
  }

  /*function unidadMedidaSelected(value){
    vm.unidadesMedida = vm.unidadesMedida.filter((i) => i !== value);
  }*/
  function unidadMedidaSelected(value){
    vm.unidadesMedida = _.filter(vm.unidadesMedida, function(unidad) {
      return unidad !== value;
  });
  }

  function unidadMedidaUnSelected(value){
    vm.unidadesMedida.push(value);
  }

  function agregar() {
    vm.producto.codigosBarras.push({});
  }

  function initCustomAtributos() {
    //obtenemos las opciones disponibles para los tipos del claseProducto seleccionado
    //y convertimos los strings correspondientes a los ids de tipo al objeto correspondiente

    vm.atributosOptions = {};
    _.forEach(vm.producto.atributos, function (atributo) {
      var coincidencias = _.find(TiposTipoFactory.tiposValorDefault, function (elem) {
        return elem == atributo.definicionAtributo.tipoDato;
      });
      if (!coincidencias) {
        var td = atributo.definicionAtributo.tipoDato;
        atributo.valor = TiposTipoFactory.getById(parseInt(atributo.valor));
        vm.atributosOptions[td] = TiposTipoFactory.get(td);
      }
    });
  }

  function criterioControlSelected(){
    if((vm.producto.criterioControl.codigo == "lotes") || (vm.producto.criterioControl.codigo == "series")){
      vm.producto.controlStock = true;
      vm.adminFormNormal = false;
    }else{
      vm.producto.controlarFechaVencimiento = false;
      vm.adminFormNormal = true;
    }
    if((vm.producto.movimientoStockDetalle != null && vm.producto.movimientoStockDetalle.length >0) ||
    (vm.producto.comprobanteDetalleList != null && vm.producto.comprobanteDetalleList.length >0) ||
    (vm.producto.comprobanteVentaDetalleList != null && vm.producto.comprobanteVentaDetalleList.length >0) ||
    (vm.producto.remisionDetalleList != null && vm.producto.remisionDetalleList.length >0) ||
    (vm.producto.recepcionDetalleList != null && vm.producto.recepcionDetalleList.length >0)){
      vm.bloquearUnidadMedida = true;
    }else{
      vm.bloquearUnidadMedida = false;
    }
  }

  function getCriterioControl () {
    return CriteriosControlProductoFactory.all({view: "BaseTipo"})
  }

  function getClaseProducto () {
    var filterClassP = {search:
      filterFactory.single({
        path: 'activo',
        equals: true
      }).value()
    };
    return ClaseProductoFactory.all(filterClassP, 'ClaseProductoList');
  }

  function getEstados () {
    return EstadosProductoFactory.all();
  }

  function getProductos(locacionFilter) {
    return ProductosFactory.allForSelect(locacionFilter)
      .$promise.then(function (response) {
        _.remove(vm.productos, function (producto) {
          return producto.id == vm.producto.id;
      })
    });
  }

  function getMarcas () {
    return MarcasFactory.all({view: "BaseList"});
  }

  function getTiposImpuesto() {
    var tipoImpuestoFilter = {};
    tipoImpuestoFilter.search = filterFactory.and([{
        path: 'activo',
        equals: true
    }
    ]).value();
    tipoImpuestoFilter.view = "BaseList";
    return TipoImpuestosFactory.all(tipoImpuestoFilter)
  }

  function procesarParametros () {
    getParametros().$promise.then(function (data) {
      vm.parametros = data;
      //console.log("A punto de pedir estiloCodigoInterno");
      var estiloCodigoInterno = _.find(vm.parametros, function (parametro) {
        return parametro.codigo === "estilo_codigo_interno";
      });
      var tipoAdministracion = _.find(vm.parametros, function (parametro) {
        return parametro.codigo === "administracion_producto";
      });
      if (tipoAdministracion.valorTipo.descripcion === "Centralizado") {
        $scope.administracionSucursal = false;
      } else {
        $scope.administracionSucursal = true;
      }
      if (!estiloCodigoInterno) {
        //por defecto se tomará como generación automática de código interno
        return data;
      }
      vm.codigoInternoManual = estiloCodigoInterno.valorTipo.codigo === "manual";

      return data;
    });
  }

  function delayRequestProveedores (searchValue) {
      if (searchValue.length >= 3) {
        var paramsProv = {};
        paramsProv.search = filterFactory.and([
          {
            path: 'activo',
            equals: true
            },
          {
            path: 'nombre',
            like: searchValue
          }]
        ).value();
        paramsProv.view = "BaseList";
        vm.proveedores = ProveedoresFactory.all(paramsProv);
      } else {
        vm.proveedores = undefined
      }
  }


  function refreshReferencias () {
    //vm.lineasProducto = getLineasProducto();
    if (vm.producto.claseProducto) {claseProductoSelected(vm.producto.claseProducto)} // para recuperar la linea de producto dependiendo de la clase seleccionada
    vm.criteriosControl = getCriterioControl();
    vm.clasesProducto = getClaseProducto();
    vm.estados = getEstados()
    //vm.productos = getProductos(vm.locacionFilter)
    vm.marcas = getMarcas()
    vm.tiposImpuesto = getTiposImpuesto()
    vm.unidadesMedidaBase = getUnidadesMedidaBase()
    procesarParametros()

    notify({ message: 'Actualizando referencias', classes: 'alert-info', position: 'right' })
  }

  $scope.resource = 'productos'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.productos')
  .controller('ProductosFormDetailCtrl', ProductosFormDetailCtrl);

ProductosFormDetailCtrl.$inject = ['$scope', 'ProductosFactory', '$location', '$state', 'ProveedoresFactory',
  'ClaseProductoFactory', 'ParametrosFactory', 'filterFactory', 'TipoImpuestosFactory', 'LineasProductoFactory',
  'UnidadMedidaFactory', '$rootScope', 'TiposTipoFactory', 'AuthorizationService', 'notify', 'productoPrepService',
  'baseurl', 'ProveedorSucursalesFactory', 'ModelTrimmer', 'ProductosLangFactory', 'WatchLocacionChangeFactory', 'UtilFactory',
  '$filter'];

function ProductosFormDetailCtrl($scope, ProductosFactory, $location, $state, ProveedoresFactory, ClaseProductoFactory,
  ParametrosFactory, filterFactory, TipoImpuestosFactory, LineasProductoFactory, UnidadMedidaFactory,
 $rootScope, TiposTipoFactory, AuthorizationService, notify, productoPrepService, baseurl,
 ProveedorSucursalesFactory, ModelTrimmer, ProductosLangFactory, WatchLocacionChangeFactory, UtilFactory, $filter) {

  var vm = this;

  vm.proveedoresFactory = ProveedoresFactory;
  vm.unidadesMedidaFactory = UnidadMedidaFactory;
  vm.cancel = cancel;
  vm.submit = submit;
  vm.descargarAdjunto = descargarAdjunto;
  vm.removeItemFromArray = removeItemFromArray;
  vm.stringifyValue = stringifyValue;

  activate();

  function activate() {
    WatchLocacionChangeFactory.killAllWatchers();
    ProductosLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;

      vm.countries = [
        {name: 'Afghanistan', code: 'AF'},
        {name: 'Åland Islands', code: 'AX'},
        {name: 'Albania', code: 'AL'},
        {name: 'Algeria', code: 'DZ'},
        {name: 'American Samoa', code: 'AS'},
        {name: 'Andorra', code: 'AD'},
        {name: 'Angola', code: 'AO'},
        {name: 'Anguilla', code: 'AI'},
        {name: 'Antarctica', code: 'AQ'},
        {name: 'Antigua and Barbuda', code: 'AG'}];

      if ($state.is("app.productos.new")) {
        activateNew();
      } else if ($state.is('app.productos.edit2')) {
        activateEdit();
      } else if ($state.is("app.productos.view")) {
        activateView();
      }

      if(vm.watcher) { vm.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
        vm.watcher = watcher;
        vm.productos = ProductosFactory.allForSelect(locacionFilter);
        vm.productos.$promise.then(function (response) {
          _.remove(vm.productos, function (producto) {
            return producto.id == vm.producto.id;
          })
        });
      }, "locaciones.id");
      vm.canEditLocaciones = AuthorizationService.hasPermission('asociar_locacion_productos');

      $rootScope.isProcessing = false;

      getParametros().$promise.then(function (data) {
        vm.parametros = data;
        //console.log("A punto de pedir estiloCodigoInterno");
        var estiloCodigoInterno = _.find(vm.parametros, function (parametro) {
          return parametro.codigo === "estilo_codigo_interno";
        });
        var tipoAdministracion = _.find(vm.parametros, function (parametro) {
          return parametro.codigo === "administracion_producto";
        });
        if (tipoAdministracion.valorTipo.descripcion === "Centralizado") {
          $scope.administracionSucursal = false;
        } else {
          $scope.administracionSucursal = true;
        }
        if (!estiloCodigoInterno) {
          //por defecto se tomará como generación automática de código interno
          return data;
        }

        return data;
      });

      //observamos la generacion de accesos para asociar a locaciones de nuestro modelo Producto
      $scope.$watchCollection('vm.accesos', function (newValue, oldValue) {
        if (!newValue || vm.accesos.length == 0) {
          return;
        }

        vm.producto.locaciones = _.map(newValue, function (acceso) {
          return acceso.locacion;
        })
      });
    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NEW_PRODUCT');
    vm.producto = {};
    vm.uploadOptions2 = {
      imageOnly: false,
      //title: 'Adjunto'
    };
  }

  function activateEdit() {
    console.log("entro edit");
    vm.title = $filter('translate')('EDIT_PRODUCT');
    vm.producto = productoPrepService;

    var auxiliarAtributo = [];
    if(vm.producto.atributos != null && vm.producto.atributos.length > 0){
      for(var i = 0; i < vm.producto.atributos.length; i++){
        if(vm.producto.atributos[i].definicionAtributo.codigo == 'vida_util'){
          auxiliarAtributo.splice(0, 0, vm.producto.atributos[i]);
        }else if(vm.producto.atributos[i].definicionAtributo.codigo == 'vida_util_contable'){
          auxiliarAtributo.splice(1, 0, vm.producto.atributos[i]);
        }else{
          auxiliarAtributo.splice(2, 0, vm.producto.atributos[i]);
        }
      }
      vm.producto.atributos = auxiliarAtributo;
    }

    
    _.forEach(vm.producto.atributos, function(atributo) {
      if(atributo.definicionAtributo.codigo == "peso") {
        atributo.valorView = Number(atributo.valor);
      }
    });
    $scope.entidadId = vm.producto.id;
    $scope.entidad = "Producto";
    //se obtiene la clase, si es bobina no se permite cambiar el criterio de control
    generateAccesosFromLocaciones();
    initCustomAtributos();
    vm.uploadOptions2 = {
      imageOnly: false,
      //title: 'Adjunto'
    };
    vm.sucursalesProveedor = getSucursalesProveedorPrincipal();

  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_PRODUCT');
    vm.producto = productoPrepService;
    _.forEach(vm.producto.atributos, function(atributo) {
      if(atributo.definicionAtributo.codigo == "peso") {
        atributo.valorView = Number(atributo.valor);
      }
    });
    $scope.entidadId = vm.producto.id;
    $scope.entidad = "Producto";
    generateAccesosFromLocaciones();
    initCustomAtributos();
    $scope.view = true;
  }

  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function(item) {
      return item === elemento;
    });
  }

  function stringifyValue(attr) {
    //dividimos parte decimal
    var div = attr.valorView.split(",");

    if(div[1] && div[1].length > 6) {
      attr.valorView =div[0] + "," + div[1].substring(0, 6);
      attr.valor = div[0] + "." + div[1].substring(0, 6);
    } else {
      attr.valor = attr.valorView.replace(",", ".");
    }
  }

  function submit() {
    vm.submited = true;
    if ($scope.ProductoForm.$valid && !vm.codigoBarrasIncorrectoMsgs) {
      $rootScope.isProcessing = true;

      for (var i = 0; i < vm.producto.atributos.length; i++) {
        console.log("valor " + isNaN(vm.producto.atributos[i].valor));
        if(isNaN(vm.producto.atributos[i].valor) && vm.producto.claseProducto.tipoProducto.descripcion == "Activo Fijo") {
          notify({message: "El campo " + vm.producto.atributos[i].definicionAtributo.nombre + " debe ser numerico", classes: 'alert-danger', position: 'right'});
          var elemento = document.getElementById('controlNumerico');
          // Obtener el valor actual de la clase
          var valorClaseActual = elemento.getAttribute('class');
          // Reemplazar el valor de la clase con la variable nuevaClase
          elemento.setAttribute('class', "header bg-warning");
          $rootScope.isProcessing = false;
          return true;
        }
        if(vm.producto.atributos[i].valor != null){
          if(vm.producto.atributos[i].valor.toString().length > 2 && vm.producto.claseProducto.tipoProducto.descripcion == "Activo Fijo" && !(vm.producto.atributos[i].definicionAtributo.codigo == "valor_residual")) {
            notify({message: "El campo " + vm.producto.atributos[i].definicionAtributo.nombre + " solo puede tener hasta dos digitos", classes: 'alert-danger', position: 'right'});
            var elemento = document.getElementById('controlNumerico');
            // Obtener el valor actual de la clase
            var valorClaseActual = elemento.getAttribute('class');
            // Reemplazar el valor de la clase con la variable nuevaClase
            elemento.setAttribute('class', "header bg-warning");
            $rootScope.isProcessing = false;
            return;
          }  
        }
          
       } 

      convertirAtributoAString();
      // se adjuntan archivos
      adjuntarArchivos(vm.producto);

      submitProducto().then(function (producto) {
        clearAdjuntos();
        $location.path('/productos');
      }, function (error) {
        $rootScope.isProcessing = false;
        console.log("Error al persistir entidad: ");
        console.log(error);
        var msg = '';
        _.forEach(error.data, function(e) {
          if(e.constraint=='nombre'){
            msg += '\n\n' + $filter('translate')('PRODUCT_EXIST')+"\n"
          }else if(e.constraint=='codigobarras'){
            msg += '\n\n' + $filter('translate')('REPEATED_CODE_BAR')+'\n'
          } else {
            msg += '\n\n' + e.message + '.';
          }
        });
        notify({message: msg, classes: 'alert-danger', position: 'right'});
      });
    } else {
      $rootScope.isProcessing = false;
    }

    function submitProducto() {
      console.log(vm.producto);
      var trimmedProducto = ModelTrimmer.trimDetails(vm.producto, ['atributos']);
      var producto = ProductosFactory.create(trimmedProducto);
      return ProductosFactory.save(producto);
    }

  }

  function getDigitoVerificador(codigo) {
    var sumaPares = 0;
    var sumaImpares = 0;

    for (var i = 0; i < (codigo.length - 1); i++) {
      var caracter = codigo.charAt(i);
      var c = parseInt(caracter);
      if (!c) {
        console.log("!c");
        continue;
      }
      if ((i + 1) % 2 == 0) {
        sumaPares += c;
      } else {
        sumaImpares += c;
      }
    }

    var multPares = sumaPares * 13;

    var sumaTotal = multPares + sumaImpares;

    var multParesStr = sumaTotal.toString();

    var lengthStr = multParesStr.length;

    var digito = multParesStr.charAt(lengthStr - 1);

    var digitoInt = 10 - parseInt(digito);

    var resultado = digitoInt == 10 ? 0 : digitoInt;

    console.log("digito verificador: " + resultado);
    return resultado;
  }


  function calculoDigitoVerificador(tag) {
    if (!vm.producto.claseProducto) {
      return;
    }

    if (!vm.producto.claseProducto.calulcoVerificador) {
      console.log("la clase de producto no cuenta con calculo de digito verificador");
      return;
    }

    var codigo = tag.codigoBarras;
    var digitoVerificador = getDigitoVerificador(codigo);
    var digitoStr = digitoVerificador.toString();

    if (digitoStr !== codigo.charAt(codigo.length - 1)) {
      var mensaje = $filter('translate')('ERROR_CODE_BAR')+ codigo + ": "+$filter('translate')('WRONG_VERIFICATION_DIGIT');
      var objeto = {mensaje: mensaje, codigo: codigo};

      if (!vm.codigoBarrasIncorrectoMsgs) {
        vm.codigoBarrasIncorrectoMsgs = [objeto];
      } else {
        vm.codigoBarrasIncorrectoMsgs.push(objeto);
      }
    }
  }

  function setCodigoBarrasDefault(item) {
    console.log("setCodigoBarrasDefault()");
    vm.producto.codigoBarrasPrincipal = item;
  }


  function cancel() {
    $location.path("/productos");
  }

  function convertirAtributoAString() {
    //convertimos en String el codigo de atributo
    _.forEach(vm.producto.atributos, function (atributo) {
      var coincidencias = _.find(TiposTipoFactory.tiposValorDefault, function (elem) {
        return elem == atributo.definicionAtributo.tipoDato;
      });
      if (!coincidencias && atributo.valor && atributo.valor.id) {
        atributo.valor = atributo.valor.id.toString();
      }
    });
  }

  
  function getParametros() {
    var params = {};
    params.search = filterFactory.single({
      path: 'modulo.codigo',
      equals: 'producto'
    }).value();

    return ParametrosFactory.all(params);
  }

  function generateAccesosFromLocaciones() {
    vm.accesos = [];
    _.forEach(vm.producto.locaciones, function (loc) {
      vm.accesos.push({
        locacion: loc
      });
    });
  }

  function adjuntarArchivos(producto) {
    // se agregan los adjuntos de imagenes
    var flowAdjunto = vm.uploadOptions2.flow;
    if (flowAdjunto) {
      var flowFilesAdjunto = flowAdjunto.files;
      var index = 0;
      if (!producto.adjuntos) {
        producto.adjuntos = [];
      } else {
        index = producto.adjuntos.length;
      }
      if (flowFilesAdjunto.length > 0) {
        angular.forEach(flowFilesAdjunto, function (file) {
          producto.adjuntos[index] = {};
          producto.adjuntos[index].path = file.uniqueIdentifier + '-' + file.name;
          //console.log("producto.adjunto: %o", producto.adjuntos[index]);
          index++;
        });
      }
    }
  }

  function descargarAdjunto(adjunto) {
    return baseurl.getPublicBaseUrl() + adjunto;
  }


  function clearAdjuntos() {
    var flow = $rootScope.flow;
    if (!flow) {
      return;
    }
    flow.files = [];
  };

  function getSucursalesProveedorPrincipal() {
    var filtros = {};
    filtros.search = filterFactory.single({
      path: 'proveedor.id',
      equals: vm.producto.proveedorPrincipal.id
    }).value();
    return ProveedorSucursalesFactory.all(filtros);
  }

  function initCustomAtributos() {
    //obtenemos las opciones disponibles para los tipos del claseProducto seleccionado
    //y convertimos los strings correspondientes a los ids de tipo al objeto correspondiente

    vm.atributosOptions = {};
    _.forEach(vm.producto.atributos, function (atributo) {
      var coincidencias = _.find(TiposTipoFactory.tiposValorDefault, function (elem) {
        return elem == atributo.definicionAtributo.tipoDato;
      });
      if (!coincidencias) {
        var td = atributo.definicionAtributo.tipoDato;
        atributo.valor = TiposTipoFactory.getById(parseInt(atributo.valor));
        vm.atributosOptions[td] = TiposTipoFactory.get(td);
      }
    });

    var atributoPeso = _.find(vm.producto.atributos, function(atributo) {
      return atributo.definicionAtributo.codigo=='peso';
    });

    console.log("atributoPeso");
    console.log(atributoPeso);
    if(atributoPeso && atributoPeso.valor != null) {
      atributoPeso.valorView = atributoPeso.valor.toString().replace(/\./, ',');
    }
  }

  $scope.resource = 'productos'; //MALA PRACTICA, necesario solo para que funciona offline-recovery

}

'use strict';


angular.module('qualita.productos')
  .controller('RelacionesProductosIndexCtrl', RelacionesProductosIndexCtrl);

RelacionesProductosIndexCtrl.$inject = ["RelacionesProductosFactory", "$log", "$scope", "notify", "ProductosFactory",
  "ProductosLangFactory", "$filter", "filterFactory", "ReportTicketFactory", "$window", "UtilFactory", "CsvFactory", "baseurl"];

function RelacionesProductosIndexCtrl(RelacionesProductosFactory, $log, $scope, notify, ProductosFactory, ProductosLangFactory,
  $filter, filterFactory, ReportTicketFactory, $window, UtilFactory, CsvFactory, baseurl) {
  var vm = this;

  var defaultColumnOrder = ['id', 'nombre', 'tipoRelacion.descripcion', 'productoPrincipal.nombre'];
  ProductosLangFactory.getTranslations().then(function (translations) {
    vm.options = {
      'resource': 'relacionesproductos',
      'title': 'Relaciones entre Productos',
      'factory': RelacionesProductosFactory,
      'view': 'RelacionProductoList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false  },
        { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
        { 'data': 'tipoRelacion.descripcion', 'title': $filter('translate')('RELATION_TYPE'), 'type': 'combo', 'filterUrl': 'tipos/filtro/tipo_relacion_productos', 'class': 'dt-center' },
        { 'data': 'productoPrincipal.nombre', 'title': $filter('translate')('PRINCIPAL_PRODUCT') },
      ],
      'hasOptions': true,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'extraMenuOptions':
        [
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoRelacionesProductos', filters, vm.options.tableAjaxParams,
                vm.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

              CsvFactory.csv("relacionesproductos", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
        ]
    };

    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({ path: data, like: search })
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }

  });
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.productos')
  .controller('RelacionesProductosFormCtrl', RelacionesProductosFormCtrl);

RelacionesProductosFormCtrl.$inject = ['$rootScope', '$scope', 'ProductosFactory', '$location', 'TiposRelacionProductosFactory',
  'RelacionesProductosFactory', 'notify', '$state', 'relacionproductosPrepService', 'filterFactory', '$timeout', 'formFactory',
  '$q', 'UnidadMedidaFactory', 'ProductosLangFactory', "WatchLocacionChangeFactory", "UtilFactory", "$filter"];

function RelacionesProductosFormCtrl($rootScope, $scope, ProductosFactory, $location, TiposRelacionProductosFactory,
  RelacionesProductosFactory, notify, $state, relacionproductosPrepService, filterFactory, $timeout, formFactory,
  $q, UnidadMedidaFactory, ProductosLangFactory, WatchLocacionChangeFactory, UtilFactory, $filter) {

  var vm = this;

  vm.agregar = agregar;
  vm.cancel = cancel;
  vm.isAnyProductoEmpty = isAnyProductoEmpty;
  //vm.productoPrincipalSelected = productoPrincipalSelected;
  vm.removeItemFromArray = removeItemFromArray;
  vm.changeProductoPrincipal = changeProductoPrincipal;
  vm.changeProductoDetalle = changeProductoDetalle;
  vm.submit = submit;
  vm.tipoRelacionSelected = tipoRelacionSelected;

  activate();

  function activate() {
    WatchLocacionChangeFactory.killAllWatchers();
    $rootScope.isProcessing = false;
    ProductosLangFactory.getTranslations().then(function (translations) {
      vm.translations = translations;
      if ($state.is("app.relacionesproductos.new")) {
        activateNew();
      } else if ($state.is("app.relacionesproductos.edit")) {
        activateEdit();
      } else if ($state.is("app.relacionesproductos.view")) {
        activateView();
      }

      vm.tiposRelacion = TiposRelacionProductosFactory.all();
      if (vm.watcher) { vm.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
        vm.watcher = watcher;
        vm.productos = ProductosFactory.allForSelect(locacionFilter);
      }, "locaciones.id");

    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NEW_PRODUCT_RELATION');
    vm.relacionProductos = {};
    vm.relacionProductos.relacionProductosDetalles = [];
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_PRODUCT_RELATION');
    vm.relacionProductos = relacionproductosPrepService;

    $scope.entidadId = vm.relacionProductos.id;
    $scope.entidad = "RelacionProductos";

    //vm.productosPrincipales = getProductos('todo');

    WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
      vm.productosPrincipales = ProductosFactory.allForSelect(locacionFilter);
      vm.productosRelacionados = ProductosFactory.allForSelect(locacionFilter);
    }, "locaciones.id");

    /*if(vm.relacionProductos.tipoRelacion.codigo === "combo") {
      vm.productosRelacionados = ProductosFactory.allForSelect();
    } else if(vm.relacionProductos.tipoRelacion.codigo === "retornable") {
      vm.productosRelacionados = getProductos('parte');
      //vm.productosRelacionados = ProductosFactory.allForSelect(filtro);
    }*/

    vm.productos = _.filter(vm.productos, function (elem) { return elem.id !== vm.relacionProductos.productoPrincipal.id });
    var producto;
    var len = vm.relacionProductos.relacionProductosDetalles.length;
    var i = 0;
    for (; i < len; i++) {
      producto = vm.relacionProductos.relacionProductosDetalles[i];
      var len2 = producto.productoRelacionado.unidadesMedida.length;
      var j = 0;
      for (; j < len2; j++) {
        if (producto.productoRelacionado.unidadesMedida[j].id == undefined) {
          producto.productoRelacionado.unidadesMedida[j] = UnidadMedidaFactory.get(producto.productoRelacionado.unidadesMedida[j], "BaseList");
        }
      }
    }
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_PRODUCT_RELATION');
    vm.relacionProductos = relacionproductosPrepService;
    $scope.entidadId = vm.relacionProductos.id;
    $scope.entidad = "RelacionProductos";
    $scope.view = true;
  }


  function agregar() {
    vm.relacionProductos.relacionProductosDetalles.push({});
    $timeout(function () {
      $scope.$broadcast('newItemAdded');
    }, 20);
  }

  function submit() {

    vm.submited = true;
    //vm.relacionProductos.relacionProductosDetalles = _.filter(vm.relacionProductos.relacionProductosDetalles, function(elem) { return elem.productoRelacionado; });
    formFactory.defaultNSFSubmit($scope.RelacionProductosForm, RelacionesProductosFactory, vm.relacionProductos).then(function (response) {
      $location.path('/relacionesproductos');
    }, function (error) {
      console.log(error);
    });
  }

  function cancel() {
    $location.path("/relacionesproductos");
  }

  function isAnyProductoEmpty(arreglo) {
    var broken = false;
    _.forEach(arreglo, function (item) {
      if (vm.relacionProductos.tipoRelacion.codigo === 'fraccionamiento' && item.productoRelacionado) {
        broken = false;
      } else if (!item.productoRelacionado || !item.factorRelacion) {
        broken = true;
      }

    });
    return broken;
  }

  function changeProductoPrincipal() {
    vm.relacionProductos.relacionProductosDetalles = [{}];
    removeProductoPrincipalFromRelacionados();
    //vm.productos = _.filter(vm.productos, function(elem) {return elem.id !== vm.relacionProductos.productoPrincipal.id});
  }

  function removeProductoPrincipalFromRelacionados() {
    WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
      ProductosFactory.allForSelect(locacionFilter).$promise.then(function (result) {
        _.remove(result, function (resultado) {
          return resultado.id === vm.relacionProductos.productoPrincipal.id;
        });
        vm.productosRelacionados = result;
      });
    }, "locaciones.id");
  }

  function changeProductoDetalle(detalle) {
    //detalle.unidadMedida={};
    //console.log(detalle);
    var len2 = detalle.productoRelacionado.unidadesMedida.length;
    var j = 0;
    //console.log("detalle");

    for (; j < len2; j++) {
      //console.log("unidad medida numnero:");
      //console.log(detalle.productoRelacionado.unidadesMedida[j]);
      if (detalle.productoRelacionado.unidadesMedida[j].id == undefined) {
        detalle.productoRelacionado.unidadesMedida[j] = UnidadMedidaFactory.get(detalle.productoRelacionado.unidadesMedida[j], "BaseList");
      }
    }
    detalle.unidadMedida = detalle.productoRelacionado.unidadMedidaPrincipal;
  }

  /*function productoPrincipalSelected(item) {
    vm.relacionProductos.relacionProductosDetalles = [{}];
  }*/

  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function (item) {
      return item === elemento;
    });
  }

  function tipoRelacionSelected() {
    WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
      vm.watcher = watcher;
      vm.productosPrincipales = ProductosFactory.allForSelect(locacionFilter);
      vm.productosRelacionados = ProductosFactory.allForSelect(locacionFilter);
    }, "locaciones.id");
    delete vm.relacionProductos.productoPrincipal;
    vm.relacionProductos.relacionProductosDetalles = [];
    /*vm.productosPrincipales = getProductos('todo');
    delete vm.relacionProductos.productoPrincipal;
    vm.relacionProductos.relacionProductosDetalles = [];
    if(vm.relacionProductos.tipoRelacion.codigo === "combo") {
      vm.productosRelacionados = ProductosFactory.all();
    } else if(vm.relacionProductos.tipoRelacion.codigo === "retornable") {
      vm.productosRelacionados = getProductos('parte');
    } else if(vm.relacionProductos.tipoRelacion.codigo === "fraccionamiento") {
      vm.productosPrincipales = getProductosOr(["bobinas", "transformacion"]);
      getProductosWithPeso().then(function(response) {
        vm.productosRelacionados = response;
      });
    }*/

  }

  function getProductos(tipo) {
    var filtro = {};
    filtro.search = filterFactory.and([{
      path: 'claseProducto.tipoAdministracion.codigo',
      equals: tipo,
    }]).value();
    return ProductosFactory.allForSelect(filtro);
  }

  function getProductosOr(arreglo) {
    var filtro = {};
    var componentes = _.map(arreglo, function (tipo) {
      return {
        path: 'claseProducto.tipoAdministracion.codigo',
        equals: tipo
      }
    });
    filtro.search = filterFactory.or(componentes).value();
    return ProductosFactory.allForSelect(filtro);
  }

  function getProductosWithPeso() {
    // TODO deberia hacerse el filtro en el backend
    return ProductosFactory.all(null, "BaseList").$promise.then(function (response) {
      return _.filter(response, function (producto) {
        if (!producto.atributos || producto.atributos.length == 0) {
          return false;
        }
        return _.find(producto.atributos, function (atributo) {
          if (!atributo.definicionAtributo) {
            return false;
          }
          return atributo.definicionAtributo.codigo == "peso" && atributo.valor;
        });
      });
    });
  }

  $scope.resource = 'relacionesproductos'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

"use strict";

angular.module("qualita.productos").controller("TipoProveedorFormCtrl", [
  "TipoProveedorFactory",
  "filterFactory",
  "$modal",
  "$scope",
  "notify",
  "$state",
  "$rootScope",
  "tipoProveedorPrepService",
  "$controller",
  "formFactory",
  "ProductosLangFactory",
  "$location",
  "$filter",
  function (
    TipoProveedorFactory,
    filterFactory,
    $modal,
    $scope,
    notify,
    $state,
    $rootScope,
    tipoProveedorPrepService,
    $controller,
    formFactory,
    ProductosLangFactory,
    $location,
    $filter
  ) {
    console.log("Entro al controler");
    var self = this;
    ProductosLangFactory.getTranslations().then(function (translations) {
      $scope.resource = "tiposproveedores";
      $scope.prepService = tipoProveedorPrepService;
      $scope.submit = submit;
      $scope.cancel = cancel;
      $scope.submitTipoProveedor = submitTipoProveedor;
      $scope.translations = translations;

      $scope.newProperties = {
        state: "app.tiposproveedores.new",
        title: translations.NEW_TIPO_PROVEEDOR
      };
      $scope.editProperties = {
        state: "app.tiposproveedores.edit",
        title: translations.EDIT_TIPO_PROVEEDOR,
        entidad: "TipoProveedor"
      };

      $scope.viewProperties = {
        state: "app.tiposproveedores.view",
        title: translations.VIEW_TIPO_PROVEEDOR,
        entidad: "TipoProveedor"
      };



      $scope.activate = function () {
        console.log("Entro al controler");
        if ($state.is($scope.newProperties.state)) {
          activateNew();
        } else if ($state.is($scope.editProperties.state)) {
          activateEdit();
        } else if ($state.is($scope.viewProperties.state)) {
          activateView();
        }

        $rootScope.isProcessing = false;
      };

      function activateNew() {
        $scope.title = $scope.newProperties.title;
        $scope.tipoProveedor = {};
      }

      function activateEdit() {
        $scope.tipoProveedor = $scope.prepService;
        $scope.entidadId = $scope.tipoProveedor.id;
        $scope.entidad = $scope.editProperties.entidad;
        $scope.title = $scope.editProperties.title;
      }

      function activateView() {
        $scope.tipoProveedor = $scope.prepService;
        $scope.entidadId = $scope.tipoProveedor.id;
        $scope.entidad = $scope.viewProperties.entidad;
        $scope.title = $scope.viewProperties.title;
        $scope.view = true;
      }

      function cancel() {
        $location.path("/tiposproveedores");
      }

      $scope.activate();

      function submit() {
        $scope.submited = true;
        if ($scope.TipoProveedorForm.$valid) {
          $rootScope.isProcessing = true;
          submitTipoProveedor().then(function (tipoProveedor) {
            $location.path("/tiposproveedores");
          }, errorHandler);
        }
      }

      function submitTipoProveedor() {
        var tipoProveedor = TipoProveedorFactory.create($scope.tipoProveedor);
        return TipoProveedorFactory.save(tipoProveedor, function (resp, headers) {
          //success callback
          console.log("RESPONSE: ", resp);
        });
      }

      function errorHandler(err) {
        console.log("Error al guardar Tipo Proveedor: ", err);
        $rootScope.isProcessing = false;
        $scope.submited = false;
        var msg = "";
        _.forEach(err.data, function (error) {
          msg += "\n\n" + error.message + ".";
        });
        notify({
          message: msg,
          classes: "alert-danger",
          position: "right"
        });
      }
    });
  }
]);

'use strict';

angular.module('qualita.productos')
  .controller('TipoProveedoresIndexCtrl', ["AuthorizationService", "TipoProveedorFactory", "TiposFactory", "$log", "$modal", "$scope", "ProductosLangFactory", "$state", "UtilFactory", "CsvFactory", "$window", "baseurl", "filterFactory", "ReportTicketFactory", function TipoProveedoresIndexCtrl(AuthorizationService, TipoProveedorFactory,
    TiposFactory, $log, $modal, $scope, ProductosLangFactory, $state, UtilFactory, CsvFactory, $window, baseurl, filterFactory, ReportTicketFactory) {

    ProductosLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'descripcion'];

      $scope.options = {
        'resource': 'tiposproveedores',
        'title': translations.TIPO_PROVEEDOR,
        'factory': TipoProveedorFactory,
        'view': 'BaseList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': translations.FAILED_DELETE_TIPO_PROVEEDOR,
        'columns': [
          { 'data': 'id', 'title': translations.CODE, 'visible': false },
          { 'data': 'descripcion', 'title': translations.DESCRIPCION }
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'hideRemoveMenu': false,
        'hideViewMenu': false,
        'extraMenuOptions':
          [{
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = createFilters($scope.options.getFilters());
              ReportTicketFactory.ticket('listadoTiposProveedores', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

              CsvFactory.csv("tiposproveedores", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
          ]
        /*,
        'extraRowOptions' : [
          {
            templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + translations.DELETE + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
            customAttribute : 'activo',
            conditionName:"canBorrar",
            conditionDef: function (activo) {
              return activo == 'Sí';
            },
            functionName: "borrar",
            functionDef: function(itemId) {
              $scope.selectedItemId = itemId;
              $scope.tituloModal = translations.DELETE_CONFIRMATION;
              $scope.mensajeModal = translations.DELETE_WARNING;
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
              });
  
              $scope.cancel = function() {
                modalInstance.dismiss('cancel');
              };
  
              $scope.ok = function(itemId) {
                var model = TipoVentaFactory.create({id: itemId});
                TipoVentaFactory.remove(model).then(function() {
                  modalInstance.close(itemId);
                  $state.go($state.current, {}, {reload: true});
                }, function(error) {
                  modalInstance.dismiss('cancel');
                  $scope.tituloModal = translations.DELETE_FAILED;
                  $scope.mensajeModal = error.data[0].message;
  
                  var modalInstance2 = $modal.open({
                    templateUrl: 'views/directives/information-modal.html',
                    scope: $scope
                  });
                  $scope.cancel = function() {
                    modalInstance2.dismiss('cancel');
                  };
                });
              }
            }
          },
          {
            templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" + translations.VIEW + "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
            conditionName: 'canView',
            conditionDef: function() {
              return AuthorizationService.hasPermission('index_tiposventas');
            },
            functionName: "view",
            functionDef: function(itemId) {
              return $state.go("app.tiposventas.view", { id : itemId});
            }
          }
        ],
        'extraEditConditions': function(item) {
          return item.activo == 'Sí';
        },*/
      };
    });
    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({ path: data, like: search })
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }

  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.empleados
 * @description
 * # empleados
 * Factory in the qualita.
 */
angular.module('qualita.productos')
  .factory('TipoProveedorFactory', ['$resource', 'baseurl', '$http', function($resource,  baseurl, $http) {

    var tipoProveedor = $resource( baseurl.getBaseUrl() + '/tiposproveedores/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params, view) {
        if (params) {
          params.view = view!=undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'BaseList';
        }
        return tipoProveedor.query(params);
      },
      get: function(id,view) {
        return tipoProveedor.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function(attrs) {
        return new tipoProveedor(attrs);
      },
      save: function(TipoProveedor) {
        return (TipoProveedor.id) ? TipoProveedor.$update() : TipoProveedor.$save();
      },
      remove: function(TipoProveedor) {
        return TipoProveedor.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/'+codigo);
      }
    };
  }]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.productos')
  .factory('ValorfiscalcontableFactory', ValorfiscalcontableFactory);

  ValorfiscalcontableFactory.$inject = ['$resource', 'baseurl', '$http', 'filterFactory'];

function ValorfiscalcontableFactory($resource, baseurl, $http, filterFactory) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    guardar: guardar,
    getByCodigo: getByCodigo,
    obtener: obtener
  };

  var valorFiscalContable = $resource( baseurl.getBaseUrl() + "/mantenimientoactivofijo/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params,view) {
    if(params){
        params.view = view!=undefined ? view : 'BaseList';
    }
    return valorFiscalContable.query(params);
}

  function get(id,view) {
    if (typeof(view)==='undefined') view = "BaseList";
    return valorFiscalContable.get({id: id, view: view});
  }

  function create(attrs) {
    return new valorFiscalContable(attrs);
  }

  function save(valorFiscalContable) {
    return (valorFiscalContable.id) ? valorFiscalContable.$update() : valorFiscalContable.$save();
  }

  function remove(valorFiscalContable) {
    return valorFiscalContable.$remove();
  }

  function guardar(datos, idComprobante) {
    return $http({
      url: baseurl.getBaseUrl() + '/mantenimientoactivofijo/guardar/' + idComprobante,
      method: "PUT",
      params: { datos: datos }
    });
  }
  function obtener(idComprobante) {
    return $http.get(baseurl.getBaseUrl() + '/mantenimientoactivofijo/obtener/' + idComprobante);
  }
  function getByCodigo(id) {
    var filter = {};
    filter.search = filterFactory.single({
      path: 'comprobanteDetalle.id',
      equals: id
    }).value();

    return valorFiscalContable.query(filter).$promise.then(function (response) {
      return response[0];
    });
  }
}


angular.module('qualita.stock', ['ngResource'])
  .config(
    ['$stateProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$urlMatcherFactoryProvider',
      function ($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider, $urlMatcherFactoryProvider) {

        $stateProvider
          .state('app.almacenes', {
            url: '/almacen',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Almacenes'
            }
          })
          .state('app.almacenes.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'AlmacenIndexCtrl',
            ncyBreadcrumb: {
              parent: 'app.almacenes',
              label: 'Almacenes',
              description: 'Listado de Almacenes',
              skip: true
            }
          })
          .state('app.almacenes.new', {
            url: '/new',
            templateUrl: 'qualita-components/stock/views/almacen/form.html',
            controller: 'AlmacenFormController',
            ncyBreadcrumb: {
              parent: 'app.almacenes',
              label: 'Nuevo Almacén',
              description: 'Creación de un nuevo almacén'
            },
            resolve: {
              almacenPrepService: function () { return; }
            }
          })
          .state('app.almacenes.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/stock/views/almacen/form.html',
            controller: 'AlmacenFormController',
            ncyBreadcrumb: {
              parent: 'app.almacenes',
              label: 'Editar Almacén',
              description: 'Edición de almacén #'
            },
            resolve: {
              almacenPrepService: almacenPrepService
            }
          })
          .state('app.almacenes.view', {
            url: '/:id',
            templateUrl: 'qualita-components/stock/views/almacen/form.html',
            controller: 'AlmacenFormController',
            ncyBreadcrumb: {
              parent: 'app.almacenes',
              label: 'Ver Almacén',
              description: 'Ver Almacén #'
            },
            resolve: {
              almacenPrepService: almacenPrepService
            }
          })
          .state('app.tiporecepciones', {
            url: '/tiporecepciones',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Tipos de Recepción'
            }
          })
          .state('app.tiporecepciones.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'TipoRecepcionIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.tiporecepciones',
              label: 'Tipos de Recepción',
              description: 'Listado de Tipos de Recepción',
              skip: true
            }
          })
          .state('app.tiporecepciones.new', {
            url: '/new',
            templateUrl: 'qualita-components/stock/views/tiporecepcion/form.html',
            controller: 'TipoRecepcionFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.tiporecepciones',
              label: 'Nuevo Tipo de Recepción',
              description: 'Creación de un nuevo tipo de recepción'
            },
            resolve: {
              tipoRecepcionPrepService: function () { return; }
            }
          })
          .state('app.tiporecepciones.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/stock/views/tiporecepcion/form.html',
            controller: 'TipoRecepcionFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.tiporecepciones',
              label: 'Editar Tipo de Recepción',
              description: 'Edición de tipo de recepción #'
            },
            resolve: {
              tipoRecepcionPrepService: tipoRecepcionPrepService
            }
          })
          .state('app.tiporecepciones.view', {
            url: '/:id',
            templateUrl: 'qualita-components/stock/views/tiporecepcion/form.html',
            controller: 'TipoRecepcionFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.tiporecepciones',
              label: 'Ver Tipo de Recepción',
              description: 'Ver tipo de recepción #'
            },
            resolve: {
              tipoRecepcionPrepService: tipoRecepcionPrepService
            }
          })
          .state('app.transaccionstock', {
            url: '/transaccionstock',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Tipos de Transacciones de Stock'
            }
          })
          .state('app.transaccionstock.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'TransaccionStockIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.transaccionstock',
              label: 'Tipos de Transacciones de Stock',
              description: 'Listado de Tipos de Transacciones de Stock',
              skip: true
            }
          })
          .state('app.transaccionstock.new', {
            url: '/new',
            templateUrl: 'qualita-components/stock/views/transaccionstock/form.html',
            controller: 'TransaccionStockFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.transaccionstock',
              label: 'Nuevo Tipo de Transacción de Stock',
              description: 'Creación de una nueva transacción de stock'
            },
            resolve: {
              transaccionStockPrepService: function () { return; }
            }
          })
          .state('app.transaccionstock.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/stock/views/transaccionstock/form.html',
            controller: 'TransaccionStockFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.transaccionstock',
              label: 'Editar Tipo de Transacción de Stock',
              description: 'Edición de tipo de transacción de stock #'
            },
            resolve: {
              transaccionStockPrepService: transaccionStockPrepService
            }
          })
          .state('app.transaccionstock.view', {
            url: '/:id',
            templateUrl: 'qualita-components/stock/views/transaccionstock/form.html',
            controller: 'TransaccionStockFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.transaccionstock',
              label: 'Ver Tipo de Transacción de Stock',
              description: 'Ver Tipo de transacción de stock #'
            },
            resolve: {
              transaccionStockPrepService: transaccionStockPrepService
            }
          })
          .state('app.movimientostock', {
            url: '/movimientostock',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Transacciones de Stock'
            }
          })
          .state('app.movimientostock.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'MovimientoStockIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.movimientostock',
              label: 'Transacciones de Stock',
              description: 'Listado de Transacciones de Stock',
              skip: true
            }
          })
          .state('app.movimientostock.new', {
            url: '/new',
            templateUrl: 'qualita-components/stock/views/movimientostock/form.html',
            controller: 'MovimientoStockFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.movimientostock',
              label: 'Nueva Transacción de Stock',
              description: 'Creación de una nueva transacción de stock'
            },
            resolve: {
              movimientoStockPrepService: function () { return; }
            }
          })
          .state('app.movimientostock.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/stock/views/movimientostock/form.html',
            controller: 'MovimientoStockFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.movimientostock',
              label: 'Editar Transacción de Stock',
              description: 'Edición de transacción de stock #'
            },
            resolve: {
              movimientoStockPrepService: movimientoStockPrepService
            }
          })
          .state('app.movimientostock.view', {
            url: '/:id',
            templateUrl: 'qualita-components/stock/views/movimientostock/form.html',
            controller: 'MovimientoStockFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.movimientostock',
              label: 'Ver Transacción de Stock',
              description: 'Ver transacción de stock #'
            },
            resolve: {
              movimientoStockPrepService: movimientoStockPrepService
            }
          })
          .state('app.consultamovimiento', {
            url: '/movimientostockdetalle',
            //templateUrl: 'qualita-components/stock/views/consultamovimientos/consulta.html',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Consulta de Movimientos de Stock Valorizado'
            }
          })
          .state('app.consultamovimiento.list', {
            url: '',
            templateUrl: 'qualita-components/stock/views/consultamovimientos/consulta.html',
            //templateUrl: 'views/template_base/list.html',
            controller: 'MovimientoConsultaIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.consultamovimiento',
              label: 'Consulta de Movimientos de Stock Valorizado',
              description: 'Consulta de Movimientos de Stock Valorizado',
              skip: true
            }
          })
          .state('app.productoalmacen', {
            url: '/productoalmacen',
            //templateUrl: 'qualita-components/stock/views/consultamovimientos/consulta.html',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Consulta de Existencia de Productos'
            }
          })
          .state('app.productoalmacen.list', {
            url: '',
            templateUrl: 'qualita-components/stock/views/productoalmacen/consulta.html',
            //templateUrl: 'views/template_base/list.html',
            controller: 'ProductoConsultaIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.productoalmacen',
              label: 'Consulta de Existencia de Productos',
              description: 'Consulta de Existencia de Productos',
              skip: true
            }
          })
          .state('app.inventariovalorizado', {
            url: '/inventariovalorizado',
            //templateUrl: 'qualita-components/stock/views/consultamovimientos/consulta.html',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Consulta de Inventario Valorizado'
            }
          })
          .state('app.inventariovalorizado.list', {
            url: '',
            templateUrl: 'qualita-components/stock/views/inventariovalorizado/consulta.html',
            //templateUrl: 'views/template_base/list.html',
            controller: 'InventarioValorizadoIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.inventariovalorizado',
              label: 'Consulta de Inventario Valorizado',
              description: 'Consulta de Inventario Valorizado',
              skip: true
            }
          })
          .state('app.consultamovimiento2', {
            url: '/consultamovimiento-opmm',
            //templateUrl: 'qualita-components/stock/views/consultamovimientos/consulta.html',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Consulta de Movimientos por OP/MM'
            }
          })
          .state('app.consultamovimiento2.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'MovimientoConsulta2IndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.consultamovimiento2',
              label: 'Consulta de Movimientos por OP/MM',
              description: 'Consulta de Movimientos por OP/MM',
              skip: true
            }
          })
          .state('app.reprocesomovimiento', {
            url: '/reprocesomovimientos',
            //templateUrl: 'qualita-components/stock/views/reprocesomovimientos/consulta.html',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Reproceso de Movimientos'
            }
          })
          .state('app.reprocesomovimiento.list', {
            url: '',
            templateUrl: 'qualita-components/stock/views/reprocesomovimientos/consulta.html',
            //templateUrl: 'views/template_base/list.html',
            controller: 'ReprocesoConsultaIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.reprocesomovimiento',
              label: 'Reproceso de Movimientos',
              description: 'Reproceso de Movimientos',
              skip: true
            }
          })
          .state('app.disponibilidadinventario', {
            url: '/disponibilidadinventario',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Consulta Disponibilidad Inventario'
            }
          })
          .state('app.disponibilidadinventario.list', {
            url: '',
            templateUrl: 'qualita-components/stock/views/consultadisponibilidadinventario/form.html',
            controller: 'ConsultaDisponibilidadInventarioFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.disponibilidadinventario',
              label: 'Consulta Disponibilidad Inventario',
              description: 'Consulta Disponibilidad Inventario',
              skip: true
            }
          })
          .state("app.rotacionproductos", {
            url: "/rotacionproductos",
            templateUrl: "views/template_base/index.html",
            abstract: true,
            ncyBreadcrumb: {
              label: "Análisis de rotación de Productos"
            }
          })
          .state("app.rotacionproductos.list", {
            url: "",
            templateUrl:
              "qualita-components/stock/views/consultarotacionproductos/consulta.html",
            controller: "ConsultaRotacionProductoIndexCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.rotacionproductos",
              label: "Análisis de rotación de Productos",
              description: "Reporte de Análisis de rotación de Productos",
              skip: true
            }
          })
          .state("app.materiales", {
            url: "/materiales",
            templateUrl: "views/template_base/index.html",
            abstract: true,
            ncyBreadcrumb: {
              label: "Materiales"
            }
          })
          .state("app.materiales.list", {
            url: "",
            templateUrl: "views/template_base/list.html",
            controller: "MaterialesIndexCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.materiales",
              label: "Materiales",
              description: "Listado de Materiales",
              skip: true
            }
          })
          .state("app.materiales.new", {
            url: "/new",
            templateUrl: "qualita-components/stock/views/materiales/form.html",
            controller: "MaterialesFormCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.materiales",
              label: "Nuevo Material",
              description: "Creación de un Material"
            },
            resolve: {
              materialesPrepService: function () {
                return;
              }
            }
          })
          .state("app.materiales.edit", {
            url: "/:id/edit",
            templateUrl: "qualita-components/stock/views/materiales/form.html",
            controller: "MaterialesFormCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.materiales",
              label: "Editar Materiales",
              description: "Edición del Material"
            },
            resolve: {
              materialesPrepService: materialesPrepService
            }
          })
          .state("app.materiales.view", {
            url: "/:id",
            templateUrl: "qualita-components/stock/views/materiales/form.html",
            controller: "MaterialesFormCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.materiales",
              label: "Ver Materiales",
              description: "Ver Materiales"
            },
            resolve: {
              materialesPrepService: materialesPrepService
            }
          })
          .state("app.materiales.proceso", {
            url: "/proceso/:idMaterial",
            templateUrl: "qualita-components/stock/views/materiales/wizard.html",
            controller: "MaterialesProcesoCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.materiales",
              label: "Materiales"
            },
            resolve: {
              materialPrepService: materialPrepService
            }
          })
          .state("app.materiales.proceso.listamateriales", {
            url: "/listamateriales",
            templateUrl: "views/template_base/index.html",
            abstract: true,
            ncyBreadcrumb: {
              parent: "app.materiales.proceso",
              label: "Lista de Materiales"
            }
          })
          .state("app.materiales.proceso.listamateriales.list", {
            url: "/mode?modeAccess",
            templateUrl: "views/template_base/list.html",
            controller: "ListaMaterialesListCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.materiales.proceso.listamateriales",
              label: "Lista de Materiales",
              skip: true
            }
          })
          .state("app.materiales.proceso.listamateriales.new", {
            url: "/new",
            templateUrl: "qualita-components/stock/views/listamateriales/form.html",
            controller: "ListaMaterialesFormCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.materiales.proceso.listamateriales",
              label: "Nueva Lista de Materiales"
            },
            resolve: {
              listaMaterialesPrepService: function () {
                return;
              }
            }
          })
          .state("app.materiales.proceso.listamateriales.edit", {
            url: "/:id/edit",
            templateUrl: "qualita-components/stock/views/listamateriales/form.html",
            controller: "ListaMaterialesFormCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.materiales.proceso.listamateriales",
              label: "Editar Lista de Materiales"
            },
            resolve: {
              listaMaterialesPrepService: listaMaterialesPrepService
            }
          })
          .state("app.materiales.proceso.listamateriales.view", {
            url: "/:id",
            templateUrl: "qualita-components/stock/views/listamateriales/form.html",
            controller: "ListaMaterialesFormCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.materiales.proceso.listamateriales",
              label: "Ver Lista de Materiales"
            },
            resolve: {
              listaMaterialesPrepService: listaMaterialesPrepService
            }
          })
          .state("app.ordenproduccion", {
            url: "/ordenproduccion",
            templateUrl: "views/template_base/index.html",
            abstract: true,
            ncyBreadcrumb: {
              label: "Orden de Producción"
            }
          })
          .state("app.ordenproduccion.list", {
            url: "",
            templateUrl: "views/template_base/list.html",
            controller: "OrdenProduccionIndexCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Orden de Producción",
              description: "Listado de Orden de Producción",
              skip: true
            }
          })
          .state("app.ordenproduccion.new", {
            url: "/new",
            templateUrl: "qualita-components/stock/views/ordenproduccion/form.html",
            controller: "OrdenProduccionFormCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Nueva Orden de Producción",
              description: "Creación de una Orden de Producción"
            },
            resolve: {
              ordenProduccionPrepService: function () {
                return;
              }
            }
          })
          .state("app.ordenproduccion.edit", {
            url: "/:id/edit",
            templateUrl: "qualita-components/stock/views/ordenproduccion/form.html",
            controller: "OrdenProduccionFormCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Editar Orden de Producción",
              description: "Edición de la Orden de Producción"
            },
            resolve: {
              ordenProduccionPrepService: ordenProduccionPrepService
            }
          })
          .state("app.ordenproduccion.view", {
            url: "/:id",
            templateUrl: "qualita-components/stock/views/ordenproduccion/form.html",
            controller: "OrdenProduccionFormCtrl",
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Ver Orden de Producción",
              description: "Ver Orden de Producción"
            },
            resolve: {
              ordenProduccionPrepService: ordenProduccionPrepService
            }
          })
          .state("app.ordenproduccion.entregaadd", {
            url: "/:id/entrega/add?mode",
            templateUrl: "qualita-components/stock/views/ordenproduccion/entrega.html",
            controller: "EntregaFormCtrl",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Crear Entrega de Materia Prima"
            },
            resolve: {
              entregaPrepService: entregaPrepService
            }
          })
          .state("app.ordenproduccion.entregaedit", {
            url: "/:id/entrega/edit?mode",
            templateUrl: "qualita-components/stock/views/ordenproduccion/entrega.html",
            controller: "EntregaFormCtrl",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Editar Entrega de Materia Prima"
            },
            resolve: {
              entregaPrepService: entregaPrepService
            }
          })
          .state("app.ordenproduccion.entregaview", {
            url: "/:id/entrega/view?mode",
            templateUrl: "qualita-components/stock/views/ordenproduccion/entrega.html",
            controller: "EntregaFormCtrl",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Ver Entrega de Materia Prima"
            },
            resolve: {
              entregaPrepService: entregaPrepService
            }
          })
          .state("app.ordenproduccion.procesaradd", {
            url: "/:id/procesar/add?mode",
            templateUrl: "qualita-components/stock/views/ordenproduccion/entrega.html",
            controller: "ProcesarFormCtrl",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Crear Recepción de Producto Terminado"
            },
            resolve: {
              entregaPrepService: entregaPrepService
            }
          })
          .state("app.ordenproduccion.procesaredit", {
            url: "/:id/procesar/edit?mode",
            templateUrl: "qualita-components/stock/views/ordenproduccion/entrega.html",
            controller: "ProcesarFormCtrl",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Editar Recepción de Producto Terminado"
            },
            resolve: {
              entregaPrepService: entregaPrepService
            }
          })
          .state("app.ordenproduccion.procesarview", {
            url: "/:id/procesar/view?mode",
            templateUrl: "qualita-components/stock/views/ordenproduccion/entrega.html",
            controller: "ProcesarFormCtrl",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Ver Recepción de Producto Terminado"
            },
            resolve: {
              entregaPrepService: entregaPrepService
            }
          })
          .state("app.ordenproduccion.devolucionadd", {
            url: "/:id/devolucion/add?mode",
            templateUrl: "qualita-components/stock/views/ordenproduccion/entrega.html",
            controller: "DevolucionFormCtrl",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Crear Devolucion de Materia Prima"
            },
            resolve: {
              entregaPrepService: entregaPrepService
            }
          })
          .state("app.ordenproduccion.devolucionedit", {
            url: "/:id/devolucion/edit?mode",
            templateUrl: "qualita-components/stock/views/ordenproduccion/entrega.html",
            controller: "DevolucionFormCtrl",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Editar Devolucion de Materia Prima"
            },
            resolve: {
              entregaPrepService: entregaPrepService
            }
          })
          .state("app.ordenproduccion.devolucionview", {
            url: "/:id/devolucion/view?mode",
            templateUrl: "qualita-components/stock/views/ordenproduccion/entrega.html",
            controller: "DevolucionFormCtrl",
            ncyBreadcrumb: {
              parent: "app.ordenproduccion",
              label: "Ver Devolucion de Materia Prima"
            },
            resolve: {
              entregaPrepService: entregaPrepService
            }
          })
          .state('app.consultamovimientosimple', {
            url: '/movimientostocksimple',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Consulta de Movimientos de Stock'
            }
          })
          .state('app.consultamovimientosimple.list', {
            url: '',
            templateUrl: 'qualita-components/stock/views/consultamovimientosimple/consulta.html',
            //templateUrl: 'views/template_base/list.html',
            controller: 'MovimientoConsultaSimpleIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.consultamovimientosimple',
              label: 'Consulta de Movimientos de Stock',
              description: 'Consulta de Movimientos de Stock',
              skip: true
            }
          })
          .state('app.inventarioproductos', {
            url: '/inventarioproductos',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Consulta de Inventario de Productos'
            }
          })
          .state('app.inventarioproductos.list', {
            url: '',
            templateUrl: 'qualita-components/stock/views/inventarioproductos/consulta.html',
            //templateUrl: 'views/template_base/list.html',
            controller: 'InventarioProductosIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.inventarioproductos',
              label: 'Consulta de Inventario de Productos',
              description: 'Consulta de Inventario de Productos',
              skip: true
            }
          })
          .state('app.movimientostockmanual', {
            url: '/movimientostockmanual',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Movimiento de Stock Manual'
            }
          })
          .state('app.movimientostockmanual.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'MovimientoStockManualIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.movimientostockmanual',
              label: 'Movimiento de Stock Manual',
              description: 'Listado de Movimiento de Stock Manual',
              skip: true
            }
          })
          .state('app.movimientostockmanual.new', {
            url: '/new',
            templateUrl: 'qualita-components/stock/views/movimientostockmanual/form.html',
            controller: 'MovimientoStockManualFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.movimientostockmanual',
              label: 'Nuevo Movimiento de Stock Manual',
              description: 'Creación de una nuevo Movimiento de Stock Manual'
            },
            resolve: {
              movimientoStockManualPrepService: function () { return; }
            }
          })
          .state('app.movimientostockmanual.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/stock/views/movimientostockmanual/form.html',
            controller: 'MovimientoStockManualFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.movimientostockmanual',
              label: 'Editar Movimiento de Stock Manual',
              description: 'Edición de Movimiento de Stock Manual #'
            },
            resolve: {
              movimientoStockManualPrepService: movimientoStockManualPrepService
            }
          })
          .state('app.movimientostockmanual.view', {
            url: '/:id',
            templateUrl: 'qualita-components/stock/views/movimientostockmanual/form.html',
            controller: 'MovimientoStockManualFormController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.movimientostockmanual',
              label: 'Ver Movimiento de Stock Manual',
              description: 'Ver Movimiento de Stock Manual #'
            },
            resolve: {
              movimientoStockManualPrepService: movimientoStockManualPrepService
            }
          })
          
      }]);

function almacenPrepService(AlmacenFactory, $stateParams) {
  return AlmacenFactory.get($stateParams.id, 'AlmacenForm').$promise;
}
almacenPrepService.$inject = ["AlmacenFactory", "$stateParams"];

function tipoRecepcionPrepService(TipoRecepcionFactory, $stateParams) {
  return TipoRecepcionFactory.get($stateParams.id, 'TipoRecepcionForm').$promise;
}
tipoRecepcionPrepService.$inject = ["TipoRecepcionFactory", "$stateParams"];

function transaccionStockPrepService(TransaccionStockFactory, $stateParams) {
  return TransaccionStockFactory.get($stateParams.id, 'TransaccionStockForm').$promise;
}
transaccionStockPrepService.$inject = ["TransaccionStockFactory", "$stateParams"];

function movimientoStockPrepService(MovimientoStockFactory, $stateParams) {
  return MovimientoStockFactory.get($stateParams.id, 'OperacionManualForm').$promise;
}
movimientoStockPrepService.$inject = ["MovimientoStockFactory", "$stateParams"];

function materialesPrepService(MaterialesFactory, $stateParams) {
  return MaterialesFactory.get($stateParams.id, "MaterialesForm").$promise;
}
materialesPrepService.$inject = ["MaterialesFactory", "$stateParams"];

function listaMaterialesPrepService(ListaMaterialesFactory, $stateParams) {
  return ListaMaterialesFactory.get($stateParams.id, "ListaMaterialesForm").$promise;
}
listaMaterialesPrepService.$inject = ["ListaMaterialesFactory", "$stateParams"];

function materialPrepService($stateParams, MaterialesFactory) {
  console.log("$stateParams ", $stateParams)
  if ($stateParams.idMaterial) {
    var promise = MaterialesFactory.get($stateParams.idMaterial, "BaseList").$promise;
    promise.then(function (mat) {
      MaterialesFactory.setIdMaterial(mat.id);
      MaterialesFactory.setMaterial(mat);
    });
    return promise;
  }
}
materialPrepService.$inject = ["$stateParams", "MaterialesFactory"];

function ordenProduccionPrepService(OrdenProduccionFactory, $stateParams) {
  return OrdenProduccionFactory.get($stateParams.id, "OrdenProduccionForm").$promise;
}
ordenProduccionPrepService.$inject = ["OrdenProduccionFactory", "$stateParams"];

function entregaPrepService(OrdenProduccionFactory, $stateParams) {
  return OrdenProduccionFactory.get($stateParams.id, "EntregaForm").$promise;
}
entregaPrepService.$inject = ["OrdenProduccionFactory", "$stateParams"];

function movimientoStockManualPrepService(MovimientoStockManualFactory, $stateParams) {
  return MovimientoStockManualFactory.get($stateParams.id, 'MovimientoStockManualForm').$promise;
}
movimientoStockManualPrepService.$inject = ["MovimientoStockManualFactory", "$stateParams"];

"use strict";

/**
 * @ngdoc service
 * @name qualita.StockLangFactory
 * @description
 * # StockLangFactory
 * Factory in the qualita.
 */
angular.module("qualita.stock").factory("StockLangFactory", [
  "$resource",
  "baseurl",
  "$http",
  "$translatePartialLoader",
  "$translate",
  function($resource, baseurl, $http, $translatePartialLoader, $translate) {
    var translationsNeeded = [
      "ALMACEN_DELETE_FAILED_WARNING",
      "CODE",
      "NOMBRE",
      "ABBREVIATION",
      "ACTIVE",
      "NEW_ALMACEN",
      "EDIT_ALMACEN",
      "VIEW_ALMACEN",
      "SUCURSAL",
      "REQUIRES_CONFIRMATION_Q",
      "ACTION",
      "TRANSACCION_CONTRAPARTIDA",
      "TRANSACCIONES_STOCK",
      "TRANSACCION_STOCK_DELETE_FAILED_WARNING",
      "LONG_VALUE_WARNING",
      "NEW_TRANSACCION_STOCK",
      "EDIT_TRANSACCION_STOCK",
      "VIEW_TRANSACCION_STOCK",
      "REQUIRES_CONFIRMATION_Q",
      "TIPO_RECEPCION_DELETE_FAILED",
      "DESCRIPTION",
      "TRANSACCION_INGRESO",
      "TRANSACCION_EGRESO",
      "OBLIGATORY_FIELD",
      "NEW_TIPO_RECEPCION",
      "EDIT_TIPO_RECEPCION",
      "VIEW_TIPO_RECEPCION",
      "ALMACEN",
      "DATE",
      "STATUS",
      "ALMACEN",
      "ALMACEN_ORIGEN",
      "ALMACEN_DESTINO",
      "CONFIRMAR_OPERACION_MANUAL_STOCK",
      "CONFIRMAR_OPERACION_MANUAL_STOCK_Q",
      "CONFIRMAR_REVERSION_OPERACION_MANUAL_STOCK",
      "CONFIRMAR_REVERSION_OPERACION_MANUAL_STOCK_Q",
      "PRODUCT",
      "NEW_OPERACION_MANUAL_STOCK",
      "EDIT_OPERACION_MANUAL_STOCK",
      "VIEW_OPERACION_MANUAL_STOCK",
      "TRANSACCION",
      "USE_IN_MANUAL_OP",
      "EXISTENCIA",
      "EXISTENCIA_TRANSITO",
      "EXISTENCIA_TOTAL",
      "ULTIMO_COSTO",
      "COSTO_PROMEDIO",
      "ULTIMO_COSTO_IVA",
      "COSTO_PROMEDIO_IVA",
      "PRECIO_VENTA",
      "TOTAL_ULTIMO_COSTO",
      "TOTAL_COSTO_PROMEDIO",
      "TOTAL_PRECIO_VENTA",
      "CODIGO_PRODUCTO",
      "CODIGO_BARRA_PRODUCTO",
      "FECHA_DESDE_MAYOR_FECHA_HASTA",
      "FILTRO_ALMACEN_PRODUCTO_REQUERIDO",
      "FILTRO_GRUPO_REQUERIDO",
      "TIPO_MOVIMIENTO",
      "ENTRADA",
      "SALIDA",
      "SALDO",
      "ULTIMO_COSTO_UNITARIO",
      "NRO_COMPROBANTE",
      "SAVE_ERROR_OP_MANUAL_STOCK",
      "DETAILS_ERROR_OP_MANUAL_STOCK",
      "NRO_ORDEN_PRODUCCION",
      "NRO_MOV_MATERIALES",
      "NRO_GRUPO",
      "PRODUCT",
      "PRODUCT_GROUP",
      "UNIT",
      "DETALLES_MOV",
      "STATUS",
      "ALMACEN_SALIDA",
      "ALMACEN_DESTINO",
      "DESCRIPTION",
      "CONSULTA_MOV",
      "CODE_MOVIMIENTO",
      "INGRESO_COMPRA_DIRECTA",
      "NRO_APUNTE_CORTE",
      "DESCRIPCION_TRANSACCION_EXISTS",
      "TIPO_RECEPCION_DESCRIPCION_WARNING",
      "USE_IN_MANUAL_OP_BRIEF",
      "CONSULTA_MOVIMIENTO_STOCK",
      "CONSULTA_INVENTARIO_VALORIZADO",
      "CONSULTA_EXISTENCIA_PRODUCTOS",
      "GENERA_REMISION",
      "CLIENTE",
      "PUNTO_EMISION",
      "TIPO_COMPROBANTE",
      "DATOS_REMISION",
      "MOV_STOCK_NUMBER",
      "CODE_PRODUCTO",
      "TIPOS_TRANSACCION_STOCK",
      "NUEVO_TIPO_TRANSACCION_STOCK",
      "EDITAR_TIPO_TRANSACCION_STOCK",
      "VER_TIPO_TRANSACCION_STOCK",
      "PRODUCT_TYPE_CHARACTERS",
      "FAMILIA_PRODUCTO_FILTER",
      "CLASE_PRODUCTO",
      "LINEA_PRODUCTO_FILTER",
      "NRO_SERIE",
      "FECHA_OBLIGATORIO",
      "TOTAL_COSTO_UNITARIO",
      "COSTO_UNITARIO",
      "REPORTE_POR_PRODUCTO",
      "REPORTE_POR_SERIE",
      "REPROCESO_MOVIMIENTOS",
      "REPROCESAR",
      "REPROCESO_SUCCESS",
      "REPROCESO_ERROR",
      "INVALID_DATES",
      "TRANSACCION_INVALIDA",
      "VERIFIQUE_ALMACEN",
      "NO_EXISTE_GRUPO_CON_STOCK",
      "NUMERO_GRUPO_REPETIDO",
      "UNIDAD_MEDIDA_ABREVIADO",
      "MARCA",
      "HABILITADO_VENTAS",
      "USAR_DIIBIT",
      "CONSULTA_DISPONIBILIDAD_INVENTARIO",
      "PERMITIR_REVERTIR_CONTRAPARTIDA",
      "ANALISIS_ROTACION_PRODUCTOS",
      "CONSULTA_MOVIMIENTO_STOCK_SIMPLE",
      "CONSULTA_INVENTARIO_PRODUCTOS"
    ];

    return {
      getTranslations: function() {
        $translatePartialLoader.addPart("stock");
        return $translate.refresh().then(function() {
          return $translate(translationsNeeded);
        });
      }
    };
  }
]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.almacen
 * @description
 * # almacen
 * Factory in the qualita.
 */
angular.module('qualita.stock')
  .factory('AlmacenFactory', ['$resource', 'baseurl', '$http', 'SucursalesFactory', 'MovimientoStockFactory',
    'StockLangFactory', '$filter', 'TiposFactory',
    function ($resource, baseurl, $http, SucursalesFactory, MovimientoStockFactory, StockLangFactory, $filter, TiposFactory) {

      var Almacen = $resource(baseurl.getBaseUrl() + '/almacenes/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      var schema = undefined;
      StockLangFactory.getTranslations().then(function (translations) {
        schema = {
          type: 'object',
          title: $filter('translate')('ALMACEN'),
          properties: {
            descripcion: {
              title: $filter('translate')('NOMBRE'),
              type: 'string',
              maxLength: 255,
              validationMessage: {
                201: $filter('translate')('LONG_VALUE_WARNING')
              },
            },
            abreviatura: {
              title: $filter('translate')('ABBREVIATION'),
              type: 'string',
              maxLength: 255,
              validationMessage: {
                201: $filter('translate')('LONG_VALUE_WARNING')
              },
            },
            activo: {
              title: $filter('translate')('ACTIVE'),
              type: 'boolean',
              default: true
            },
            clase: {
              title: $filter('translate')('KIND'),
              type: 'number',
              validationMessage: {
                'default': $filter('translate')('OBLIGATORY_FIELD')
              }
            },
            habilitadoVenta: {
              title: $filter('translate')('HABILITADO_VENTAS'),
              type: 'boolean',
              default: false
            }
          },
          required: ['codigo', 'descripcion', 'abreviatura', 'clase']
        };
      });


      var formNew = [
        'codigo', 'descripcion', 'abreviatura',
        { key: 'activo', type: 'customcheckbox' },
        {
          key: 'clase',
          type: 'uiselect',
          options: {
            asyncCallback: function (options, search) {
              var params = {};
              if (search) {
                params.search = filterFactory.single({
                  path: 'descripcion',
                  like: search
                }).value();
              }
              return TiposFactory.clasesAlmacen().then(function (data) {
                return { data: data.data };
              });
            },
            map: { valueProperty: 'id', nameProperty: 'descripcion' },
            formName: 'almacenForm'
          }
        },
        { key: 'habilitadoVenta', type: 'customcheckbox' }
      ];

      var formEdit = angular.copy(formNew);
      var formView = angular.copy(formNew);

      var forms = {
        'new': formNew,
        'edit': formEdit,
        'view': formView
      }

      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined ? view : 'BaseList';
          } else {
            params = [];
            params.view = view != undefined ? view : 'BaseList';
          }
          return Almacen.query(params);
        },

        get: function (id, view) {
          return Almacen.get({ id: id, view: view != undefined ? view : 'BaseList' });
        },

        create: function (attrs) {
          return new Almacen(attrs);
        },

        save: function (almacen) {
          return (almacen.id) ? almacen.$update() : almacen.$save();
        },

        remove: function (almacen) {
          return almacen.$remove();
        },

        schema: function () {
          return schema;
        },

        form: function (type) {
          return forms[type];
        }
      };
    }]);

'use strict';


angular.module('qualita.stock')
  .controller('AlmacenIndexCtrl', ["$scope", "AlmacenFactory", "filterFactory", "ReportTicketFactory", "$window", "MovimientoStockFactory", "StockLangFactory", "WatchLocacionChangeFactory", "UtilFactory", "CsvFactory", "baseurl", "$filter", "$modal", "$state", function ($scope, AlmacenFactory, filterFactory, ReportTicketFactory, $window,
    MovimientoStockFactory, StockLangFactory, WatchLocacionChangeFactory, UtilFactory, CsvFactory, baseurl, $filter,
    $modal, $state) {
    StockLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'codigo', 'descripcion', 'abreviatura', 'activo', 'clase.descripcion'];
      $scope.options = {
        'resource': 'almacenes',
        'title': 'Almacenes',
        'factory': AlmacenFactory,
        'view': 'AlmacenList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('ALMACEN_DELETE_FAILED_WARNING'),
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false  },
          { 'data': 'descripcion', 'title': $filter('translate')('NOMBRE') },
          { 'data': 'abreviatura', 'title': $filter('translate')('ABBREVIATION') },
          { 'data': 'sucursal.nombre', 'title': $filter('translate')('SUCURSAL') },
          { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
          {
            data: "clase.descripcion",
            title: $filter("translate")("KIND"),
            renderWith: "emptyRender",
            class: "dt-center", 'type': 'combo', 'filterUrl': 'tipos/filtro/clase_grupo_compra'
          }
        ],
        'hasOptions': true,
        'hideRemoveMenu': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
               // var filters = UtilFactory.createFilters($scope.options.getFilters(), $scope.options.staticFilter);
                
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoAlmacenes', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("almacenes", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ],

        'extraRowOptions': [
          {
            templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
            customAttribute: 'estado',
            conditionName: "canBorrar",
            conditionDef: function (estado) {
              //return AuthorizationService.hasPermission('delete_usuarios');
              return true;
            },
            functionName: "borrar",
            functionDef: function (itemId) {
              $scope.selectedItemId = itemId;
              $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
              $scope.mensajeModal = $filter('translate')('DELETE_WARNING');

              $scope.modalInstanceBorrar1 = $modal.open({
                template: '<div class="modal-header">' +
                  '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                  '</div>' +
                  '<div class="modal-body">{{::mensajeModal}}' +
                  '</div>' +
                  //'<div class="modal-body">{{::usuarioEliminar}}</div>'+
                  '<div class="modal-footer">' +
                  '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                  '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                  '</div>',
                scope: $scope
              });

              $scope.cancel = function () {
                $scope.modalInstanceBorrar1.dismiss('cancel');
              };

              $scope.ok = function (itemId) {
                var model = AlmacenFactory.create({ id: itemId });
                AlmacenFactory.remove(model).then(function () {
                  $scope.modalInstanceBorrar1.close(itemId);
                  $state.go($state.current, {}, { reload: true });
                }, function (error) {
                  $scope.modalInstanceBorrar1.dismiss('cancel');
                  $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                  $scope.mensajeModal = $scope.options.failedDeleteError;
                  var modalInstance = $modal.open({
                    template: '<div class="modal-header">' +
                      '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                      '</div>' +
                      '<div class="modal-body">{{::mensajeModal}}</div>' +
                      '<div class="modal-footer">' +
                      '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                      '</div>',
                    scope: $scope
                  });
                  $scope.cancel = function () {
                    modalInstance.dismiss('cancel');
                  };
                  console.log("error al borrar: ");
                  console.log(error);
                });
              }
            }
          }]
      };
      WatchLocacionChangeFactory.watchWith(undefined, $scope.options, "locacion.id");
      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          if(search === 'No' && data === 'activo'){
            search = 'false';
        }
        if(search === 'Sí' && data === 'activo'){
            search = 'true';
        }
          filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
    });
  }]);

'use strict';

angular.module('qualita.stock')
  .controller('AlmacenFormController', ['$rootScope', '$scope', 'AlmacenFactory', 'formFactory', '$location', 'MovimientoStockFactory',
    '$state', 'almacenPrepService', '$controller', 'notify', 'StockLangFactory', '$filter', 'ParametrosFactory', 'AuthorizationService',
    function ($rootScope, $scope, AlmacenFactory, formFactory, $location, MovimientoStockFactory,
    $state, almacenPrepService, $controller, notify, StockLangFactory, $filter, ParametrosFactory, AuthorizationService) {

      /*$scope.resource = 'almacen';
      $scope.resources = 'almacenes';
      $scope.factory = AlmacenFactory;
      $scope.prepService = almacenPrepService;
      var self = this;
      //StockLangFactory.getTranslations().then(function(translations) {
        $scope.newProperties = {
          state: "app.almacenes.new",
          //title: $filter('translate')('NEW_ALMACEN'),
          title: "Nuevo Almacén",
        };
        $scope.editProperties = {
          state: "app.almacenes.edit",
          //title: $filter('translate')('EDIT_ALMACEN'),
          title: "Editar Almacén",
          entidad: "Almacen"
        };

        $scope.viewProperties = {
          state: "app.almacenes.view",
          //title: $filter('translate')('VIEW_ALMACEN'),
          title: "Ver Almacén",
          entidad: "Almacen"
        };
      //});

      angular.extend(this, $controller('BasicController', {$scope: $scope}));
      $scope.activate();*/

      activate();

      function activate() {
        StockLangFactory.getTranslations().then(function(translations) {
          $scope.translations = translations;
          $scope.schema = AlmacenFactory.schema();
          $scope.options = formFactory.defaultOptions();

          ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
              $scope.utilizarPrecioBase = false;
            } else {
              $scope.utilizarPrecioBase = true;
            }
          });

          //verificar si cuenta con parametro para diibit
          ParametrosFactory.getByCodigo('habilitar_check_diibit').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
              $scope.utilizarDiibit = false;
            } else {
              $scope.utilizarDiibit = true;
            }
          });

          if ($state.is("app.almacenes.new")) {
            activateNew();
          } else if ($state.is("app.almacenes.edit")) {
            activateEdit();
          } else if ($state.is("app.almacenes.view")) {
            activateView();
          }

          $rootScope.isProcessing = false;
        });
      }

      function activateNew() {
        $scope.title = $filter('translate')('NEW_ALMACEN');
        $scope.form = AlmacenFactory.form('new');
        $scope.model = {};

        $scope.model.activo = true;
        $scope.schema.readonly = false;
        if (AuthorizationService.hasPermission("asignar_solicitudes_nota_credito") === false) {
          $scope.permisoNC = false;
        } else {
          $scope.permisoNC = true;
        }
      }

      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_ALMACEN');
        $scope.form = AlmacenFactory.form('edit');
        $scope.model = almacenPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Almacen";
        $scope.schema.readonly = false;
        $scope.isEdit = true;
        if (AuthorizationService.hasPermission("asignar_solicitudes_nota_credito") === false) {
          $scope.permisoNC = false;
        } else {
          $scope.permisoNC = true;
        }
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_ALMACEN');
        $scope.form = AlmacenFactory.form('view');
        $scope.options = formFactory.defaultViewOptions();
        $scope.model = almacenPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Almacen";
        $scope.view = true;
        $scope.schema.readonly = true;
        if (AuthorizationService.hasPermission("asignar_solicitudes_nota_credito") === false) {
          $scope.permisoNC = false;
        } else {
          $scope.permisoNC = true;
        }
      }

      $scope.submit = function(form) {
        if(!$scope.isEdit) {
          $scope.model.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        }
        formFactory.defaultSubmit('almacen', $scope, form, AlmacenFactory, undefined, errorHandler);
      };

      $scope.cancel = function() {
        $location.path('/almacen');
      };

      function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
          if (error.constraint == 'abreviatura') {
            msg += '\n\n' + "Ya existe un almacén con la misma abreviatura.\n";
          } else if (error.constraint == 'descripcion') {
            msg += '\n\n' + "Ya existe un almacén con el mismo nombre.\n";
          } else {
            msg += '\n\n' + error.message + '.';
          }
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      }

  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.tiporecepcion
 * @description
 * # tiporecepcion
 * Factory in the qualita.
 */
angular.module('qualita.stock')
  .factory('TipoRecepcionFactory', ['$resource', 'baseurl', 'AlmacenFactory', 'MovimientoStockFactory',
    'TransaccionStockFactory', 'filterFactory', 'StockLangFactory', '$filter',
    function ($resource, baseurl, AlmacenFactory, MovimientoStockFactory, TransaccionImportacionFactory,
      filterFactory, StockLangFactory, $filter) {

      var TipoRecepcion = $resource(baseurl.getBaseUrl() + '/tiporecepciones/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      var schema = undefined;

      StockLangFactory.getTranslations().then(function (translations) {
        schema = {
          type: 'object',
          title: 'Tipo Recepcion',
          properties: {
            descripcion: {
              title: $filter('translate')('DESCRIPTION'),
              type: 'string',
              maxLength: 255,
              validationMessage: {
                201: $filter('translate')('LONG_VALUE_WARNING')
              },
            },
            abreviatura: {
              title: $filter('translate')('ABBREVIATION'),
              type: 'string',
              maxLength: 255,
              validationMessage: {
                201: $filter('translate')('LONG_VALUE_WARNING')
              },
            },
            almacen: {
              title: $filter('translate')('ALMACEN'),
              type: 'number',
              validationMessage: {
                'default': $filter('translate')('OBLIGATORY_FIELD')
              }
            },
            activo: {
              title: $filter('translate')('ACTIVE'),
              type: 'boolean',
              default: true
            }
          },
          required: ['descripcion', 'abreviatura', 'almacen']
        };
      });


      var formNew = [
        'descripcion',
        'abreviatura',
        {
          key: 'almacen',
          placeholder: ' ',
          type: 'uiselect',
          options: {
            asyncCallback: function (options, search) {
              var params = {};
              if (search) {
                params.search = filterFactory.single({
                  path: 'descripcion',
                  like: search
                }).value();
              }

              var filterActivo = {
                search: filterFactory.single({
                  path: "activo",
                  equals: true
                }).value()
              };

              return AlmacenFactory.all(filterActivo, "BaseList").$promise.then(function (data) {
                return { data: data };
              });
            },
            map: { valueProperty: 'id', nameProperty: 'descripcion' },
            formName: 'tipoRecepcionForm'
          }
        },
        { key: 'activo', type: 'customcheckbox' }
      ];

      var formEdit = angular.copy(formNew);
      var formView = angular.copy(formNew);

      var forms = {
        'new': formNew,
        'edit': formEdit,
        'view': formView
      }

      return {
        all: function (params) {
          return TipoRecepcion.query(params);
        },

        get: function (id, view) {
          return TipoRecepcion.get({ id: id, view: view != undefined ? view : 'base' });
        },

        create: function (attrs) {
          return new TipoRecepcion(attrs);
        },

        save: function (tipoRecepcion) {
          return (tipoRecepcion.id) ? tipoRecepcion.$update() : tipoRecepcion.$save();
        },

        remove: function (tipoRecepcion) {
          return tipoRecepcion.$remove();
        },

        schema: function () {
          return schema;
        },

        form: function (type) {
          return forms[type];
        }
      };
    }]);

'use strict';


angular.module('qualita.stock')
  .controller('TipoRecepcionIndexCtrl', ["$scope", "TipoRecepcionFactory", "MovimientoStockFactory", "filterFactory", "ReportTicketFactory", "$window", "StockLangFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter", function ($scope, TipoRecepcionFactory, MovimientoStockFactory, filterFactory,
    ReportTicketFactory, $window, StockLangFactory, CsvFactory, UtilFactory, baseurl, $filter) {

    var emptyRender = function (data) {
      //console.log(data);
      if (data == undefined)
        return "";
      else
        return data;
    };

    StockLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'descripcion', 'almacen.descripcion', 'transaccionIngreso.descripcion',/*'transaccionAnulacion.descripcion'*/, $filter('translate')('ACTIVE')];
      $scope.options = {
        'resource': 'tiporecepciones',
        'title': 'Tipos de Recepción',
        'factory': TipoRecepcionFactory,
        'view': 'TipoRecepcionList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('TIPO_RECEPCION_DELETE_FAILED'),
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('CODE')  },
          { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION') },
          { 'data': 'almacen.descripcion', 'title': $filter('translate')('ALMACEN') },
          { 'data': 'transaccionIngreso.descripcion', 'title': $filter('translate')('TRANSACCION_INGRESO'), 'render': emptyRender },
          //{'data': 'transaccionAnulacion.descripcion', 'title': $filter('translate')(''TRANSACCION_ANULACION),'render': emptyRender},
          { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
        ],
        'hasOptions': true,
        'defaultOrderColumn': 1,
        'defaultOrderDir': "asc",
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoTiposRecepcion', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("tiporecepciones", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ],
        'extraRemoveConditions': function (item) {
          return item.id != 3;
        },
        'extraEditConditions': function (item) {
          return item.id != 3;
        },
      };

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          if(search === 'No' && data === 'activo'){
            search = 'false';
        }
        if(search === 'Sí' && data === 'activo'){
            search = 'true';
        }
          filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
    });

  }]);

'use strict';

angular.module('qualita.stock')
  .controller('TipoRecepcionFormController', ['$rootScope', '$scope', 'TipoRecepcionFactory', 'MovimientoStockFactory', 'formFactory', '$location',
    '$state', 'tipoRecepcionPrepService', 'StockLangFactory', 'notify', '$filter',
    function($rootScope, $scope, TipoRecepcionFactory, MovimientoStockFactory, formFactory, $location,
      $state, tipoRecepcionPrepService, StockLangFactory, notify, $filter) {

      activate();

      function activate() {
        StockLangFactory.getTranslations().then(function(translations) {
          $scope.translations = translations;
          $scope.schema = TipoRecepcionFactory.schema();
          $scope.options = formFactory.defaultOptions();

          if ($state.is("app.tiporecepciones.new")) {
            activateNew();
          } else if ($state.is("app.tiporecepciones.edit")) {
            activateEdit();
          } else if ($state.is("app.tiporecepciones.view")) {
            activateView();
          }

          $rootScope.isProcessing = false;
        });
      }

      function activateNew() {
        $scope.title = $filter('translate')('NEW_TIPO_RECEPCION');
        $scope.form = TipoRecepcionFactory.form('new');
        $scope.model = {};

        $scope.model.activo = true;
        $scope.schema.readonly = false;
      }

      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_TIPO_RECEPCION');
        $scope.form = TipoRecepcionFactory.form('edit');
        $scope.model = tipoRecepcionPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "TipoRecepcion";
        $scope.schema.readonly = false;
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_TIPO_RECEPCION');
        $scope.form = TipoRecepcionFactory.form('view');
        $scope.options = formFactory.defaultViewOptions();
        $scope.model = tipoRecepcionPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "TipoRecepcion";
        $scope.view = true;
        $scope.schema.readonly = true;
      }


      $scope.submit = function(form) {
        formFactory.defaultSubmit('tiporecepciones', $scope, form, TipoRecepcionFactory, undefined, errorHandler);
      };

      $scope.cancel = function() {
        $location.path('/tiporecepciones');
      };

      function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
          if (error.constraint == 'descripcion') {
            msg += '\n\n' + $filter('translate')('TIPO_RECEPCION_DESCRIPCION_WARNING') + "\n";
          } else {
            msg += '\n\n' + error.message + '.';
          }
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      }

    }
  ]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module('qualita.stock')
  .factory('TransaccionStockFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory',
    'MovimientoStockFactory', 'StockLangFactory', '$filter', '$http',
    function ($resource, baseurl, filterFactory, TiposFactory, MovimientoStockFactory, StockLangFactory, $filter,$http) {

      var TransaccionStock = $resource(baseurl.getBaseUrl() + '/transaccionstock/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      var schema = undefined;

      StockLangFactory.getTranslations().then(function (translations) {
        schema = {
          type: 'object',
          title: 'Transacción de Stock',
          properties: {
            descripcion: {
              title: $filter('translate')('NOMBRE'),
              type: 'string',
              maxLength: 255,
              validationMessage: {
                201: $filter('translate')('LONG_VALUE_WARNING')
              },
            },
            ingresoCompraDirecta: {
              title: $filter('translate')('INGRESO_COMPRA_DIRECTA'),
              type: 'boolean',
              default: false
            },
            usarEnOperacionesManuales: {
              title: $filter('translate')('USE_IN_MANUAL_OP'),
              type: 'boolean',
              default: true
            },
            activo: {
              title: $filter('translate')('ACTIVE'),
              type: 'boolean',
              default: true
            },
            requiereConfirmacion: {
              title: $filter('translate')('REQUIRES_CONFIRMATION_Q'),
              type: 'boolean',
              default: true
            },
            accion: {
              title: $filter('translate')('ACTION'),
              type: 'number'
            },
            transaccionContrapartida: {
              title: $filter('translate')('TRANSACCION_CONTRAPARTIDA'),
              type: 'number'
            },
            clase: {
              title: $filter('translate')('KIND'),
              type: 'number',
              validationMessage: {
                'default': $filter('translate')('OBLIGATORY_FIELD')
              }
            }
          },
          required: ['descripcion']
        };
      });

      var filters = {};

      var formNew = [
        'descripcion',
        { key: 'activo', type: 'customcheckbox' },
        { key: 'ingresoCompraDirecta', type: 'customcheckbox' },
        { key: 'requiereConfirmacion', type: 'customcheckbox' },
        { key: 'usarEnOperacionesManuales', type: 'customcheckbox' },
        {
          key: 'accion',
          //placeholder: 'Seleccione una acción',
          type: 'uiselect',
          onChange: function (accionID) {
            filters.accion = accionID;
          },
          options: {
            asyncCallback: function (options, search) {
              var params = {};
              if (search) {
                params.search = filterFactory.single({
                  path: 'descripcion',
                  like: search
                }).value();
              }
              return TiposFactory.accionesTransaccion().then(function (data) {
                return { data: data.data };
              });
            },
            map: { valueProperty: 'id', nameProperty: 'descripcion' },
            formName: 'TransaccionStockForm'
          }
        },
        {
          key: 'transaccionContrapartida',
          //placeholder: 'Seleccione una transacción',
          type: 'uiselect',
          options: {
            asyncCallback: function (options, search) {
              //console.log($parent.model.accion);
              //console.log($model.accion);
              var params2 = {};
              if (search) {
                params2.search = filterFactory.single({
                  path: 'descripcion',
                  like: search
                }).value();
              }
              params2.view = "BaseList";
              return TransaccionStock.query(params2).$promise.then(function (data) {
                return { data: data };
              });
            },
            map: { valueProperty: 'id', nameProperty: 'descripcion' },
            formName: 'TransaccionStockForm',
            filterRelated: function (items) {
              if (filters.accion) {
                return _.filter(items, function (i) {
                  if (i.accion)
                    return i.accion.id !== filters.accion;
                });
              }
              return items;
            }
          }
        },
        {
          key: 'clase',
          type: 'uiselect',
          options: {
            asyncCallback: function (options, search) {
              var params = {};
              if (search) {
                params.search = filterFactory.single({
                  path: 'descripcion',
                  like: search
                }).value();
              }
              return TiposFactory.clasesTransaccion().then(function (data) {
                return { data: data.data };
              });
            },
            map: { valueProperty: 'id', nameProperty: 'descripcion' },
            formName: 'TransaccionStockForm'
          }
        }
      ];

      var formEdit = [
        'descripcion',
        { key: 'activo', type: 'customcheckbox' },
        { key: 'ingresoCompraDirecta', type: 'customcheckbox' },
        { key: 'requiereConfirmacion', type: 'customcheckbox' },
        { key: 'usarEnOperacionesManuales', type: 'customcheckbox' },
        {
          key: 'accion',
          //placeholder: 'Seleccione una acción',
          type: 'uiselect',
          onChange: function (accionID) {
            filters.accion = accionID;
          },
          options: {
            asyncCallback: function (options, search) {
              var params = {};
              if (search) {
                params.search = filterFactory.single({
                  path: 'descripcion',
                  like: search
                }).value();
              }
              return TiposFactory.accionesTransaccion().then(function (data) {
                return { data: data.data };
              });
            },
            map: { valueProperty: 'id', nameProperty: 'descripcion' },
            formName: 'TransaccionStockForm'
          }
        },
        {
          key: 'transaccionContrapartida',
          //placeholder: 'Seleccione una transacción',
          type: 'uiselect',
          options: {
            asyncCallback: function (options, search) {
              var params5 = {};
              if (search) {
                params5.search = filterFactory.single({
                  path: 'descripcion',
                  like: search
                }).value();
              }
              params5.view = "BaseList";
              return TransaccionStock.query(params5).$promise.then(function (data) {
                return { data: data };
              });
            },
            map: { valueProperty: 'id', nameProperty: 'descripcion' },
            formName: 'TransaccionStockForm',
            filterRelated: function (items) {
              if (filters.accion) {
                return _.filter(items, function (i) {
                  if (i.accion)
                    return i.accion.id !== filters.accion;
                });
              }
            }
          }
        },
        {
          key: 'clase',
          type: 'uiselect',
          options: {
            asyncCallback: function (options, search) {
              var params = {};
              if (search) {
                params.search = filterFactory.single({
                  path: 'descripcion',
                  like: search
                }).value();
              }
              return TiposFactory.clasesTransaccion().then(function (data) {
                return { data: data.data };
              });
            },
            map: { valueProperty: 'id', nameProperty: 'descripcion' },
            formName: 'TransaccionStockForm'
          }
        }
      ];

      var formView = angular.copy(formEdit);

      var forms = {
        'new': formNew,
        'edit': formEdit,
        'view': formView
      }

      return {
        all: function (params, view) {
          params = params ? params : [];
          params.view = view != undefined ? view : "BaseList";
          return TransaccionStock.query(params);
        },

        get: function (id, view) {
          return TransaccionStock.get({ id: id, view: view != undefined ? view : 'base' });
        },

        create: function (attrs) {
          return new TransaccionStock(attrs);
        },

        save: function (transaccionStock) {
          return (transaccionStock.id) ? transaccionStock.$update() : transaccionStock.$save();
        },

        remove: function (transaccionStock) {
          return transaccionStock.$remove();
        },

        schema: function () {
          return schema;
        },

        form: function (type) {
          return forms[type];
        },
        carga: function (name,fecha,hora,almacen,transaccion,criterio) {
          return $http({
            url: baseurl.getBaseUrl() + '/transaccionstock/carga',
            method: "POST",
            params: { filename: name,fecha:fecha,hora:hora,almacen:almacen,transaccion:transaccion}
          });
        }
      };
    }]);

'use strict';


angular.module('qualita.stock')
  .controller('TransaccionStockIndexCtrl',
    ["$scope", "TransaccionStockFactory", "MovimientoStockFactory", "filterFactory", "ReportTicketFactory", "$window", "StockLangFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter", function ($scope, TransaccionStockFactory, MovimientoStockFactory, filterFactory, ReportTicketFactory, $window,
      StockLangFactory, CsvFactory, UtilFactory, baseurl, $filter) {

      StockLangFactory.getTranslations().then(function (translations) {
        var defaultColumnOrder = ['id', 'descripcion', 'accion.descripcion', 'ingresoCompraDirecta',
          'usarEnOperacionesManuales', 'activo', 'clase.descripcion', 'accion.codigo', 'transaccionContrapartida.descripcion', 'generaRemision'];
        $scope.options = {
          'resource': 'transaccionstock',
          'title': $filter('translate')('TIPOS_TRANSACCION_STOCK'),
          'factory': TransaccionStockFactory,
          'view': 'TransaccionStockList',
          'defaultColumnOrder': defaultColumnOrder,
          'failedDeleteError': $filter('translate')('TRANSACCION_STOCK_DELETE_FAILED_WARNING'),
          'columns': [
            { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false  },
            { 'data': 'descripcion', 'title': $filter('translate')('NOMBRE') },
            {
              'data': 'accion.descripcion', 'title': $filter('translate')('ACTION'), 'type': 'combo', 'filterUrl': 'tipos/filtro/imc_accion', 'render': function (data) {
                if (data == undefined)
                  return "";
                else
                  return data;
              }
            },
            { 'data': 'requiereConfirmacion', 'title': $filter('translate')('REQUIRES_CONFIRMATION_Q'), 'class': 'dt-center', visible: false, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
            { 'data': 'ingresoCompraDirecta', 'title': $filter('translate')('INGRESO_COMPRA_DIRECTA'), 'class': 'dt-center', visible: false, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
            { 'data': 'usarEnOperacionesManuales', 'title': $filter('translate')('USE_IN_MANUAL_OP_BRIEF'), 'class': 'dt-center', visible: false, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
            { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
            {
              data: "clase.descripcion",
              title: $filter("translate")("KIND"),
              renderWith: "emptyRender",
              class: "dt-center", 'type': 'combo', 'filterUrl': 'tipos/filtro/clase_transaccion'
            },
            { 'data': 'transaccionContrapartida.descripcion', 'title': $filter('translate')('TRANSACCION_CONTRAPARTIDA'), renderWith: "emptyRender", 'class': 'dt-center', visible: false },
            { 'data': 'generaRemision', 'title': $filter('translate')('GENERA_REMISION'), 'class': 'dt-center', visible: false, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
          ],
          'hasOptions': true,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "desc",
          'extraMenuOptions':
            [
              {
                'title': 'Reporte',
                'icon': 'glyphicon glyphicon-file',
                'showCondition': function () { return true; },
                'action': function () {
                  var filters = createFilters($scope.options.getFilters());
                  ReportTicketFactory.ticket('listadoTransacciones', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                    $window.open($scope.pdfDownloadURL, '_blank');
                  });
                }
              },
              {
                'title': 'CSV',
                'icon': 'glyphicon glyphicon-export',
                'showCondition': function () { return true; },
                'action': function () {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("transaccionstock", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                    .then(function (response) {
                      console.log("response de csv: ", response);
                      var fileName = response.data.fileName;
                      $window.open(baseurl.getPublicBaseUrl() + fileName);
                    });
                }
              }
            ],
            /* CAMBIAR TAMBIEN ESTO */
          'extraEditConditions': function (item) {
            if ( (item) && (item.ingresoCompraDirecta === "Sí") ) {
              if(item.clase.codigo === "producto"){
                if ( item.accion.codigo === 'ingreso_almacen' || item.accion.codigo === 'egreso_almacen' ) {
                  return false;
                }
              }
            }
            return true;
          },

          'extraRemoveConditions': function (item) {
            if ( (item) && (item.ingresoCompraDirecta === "Sí") ) {
              if(item.clase.codigo === "producto"){
                if ( item.accion.codigo === 'ingreso_almacen' || item.accion.codigo === 'egreso_almacen' ) {
                  return false;
                }
              }
            }
            return true;
          },

        };
      });

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          if(search === 'No' && (data === 'requiereConfirmacion' || data === 'ingresoCompraDirecta' || data === 'usarEnOperacionesManuales' || data === 'activo' || data === 'generaRemision')){
            search = 'false';
        }
        if(search === 'Sí' && (data === 'requiereConfirmacion' || data === 'ingresoCompraDirecta' || data === 'usarEnOperacionesManuales' || data === 'activo' || data === 'generaRemision')){
            search = 'true';
        }
          filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
    }]);

'use strict';

angular.module('qualita.stock')
  .controller('TransaccionStockFormController', ['$rootScope', '$scope', 'TransaccionStockFactory', 'MovimientoStockFactory',
    'formFactory', '$location', '$state', 'transaccionStockPrepService', 'StockLangFactory', 'TiposFactory', 'notify', '$filter', 'ParametrosFactory',
    'TiposTipoFactory',
    function ($rootScope, $scope, TransaccionStockFactory, MovimientoStockFactory, formFactory, $location, $state,
      transaccionStockPrepService, StockLangFactory, TiposFactory, notify, $filter, ParametrosFactory, TiposTipoFactory) {

      activate();
      $scope.compraDirectaChange = function () {
        if ($scope.transaccion.ingresoCompraDirecta) {
          $scope.transaccion.usarEnOperacionesManuales = false;
          $scope.transaccion.requiereConfirmacion = false;
          $scope.transaccion.transaccionContrapartida = {};
          $scope.transaccion.accion = $scope.acionIngreso
        }
      }

      $scope.contrapartidaSelected = function () {
        $scope.transaccion.requiereConfirmacion = false;
      }

      function activate() {
        StockLangFactory.getTranslations().then(function (translations) {
          $scope.translations = translations;
          TransaccionStockFactory.all(null, "BaseList").
                  $promise.then(function (data) {
                  if ($state.is("app.transaccionstock.edit")) {
                        $scope.array = [];
                        _.forEach(data, function (datoResponse) {
                            if(datoResponse.transaccionContrapartida !== null) {
                                if ($scope.transaccion.id !== datoResponse.id && datoResponse.transaccionContrapartida.id !== $scope.transaccion.id) {
                                    $scope.array.push(datoResponse);
                                }
                            } else {
                                if ($scope.transaccion.id !== datoResponse.id) {
                                    $scope.array.push(datoResponse);
                                }
                            }
                        });
                        $scope.transaccionContrapartidas = $scope.array;
                  } else {
                     $scope.transaccionContrapartidas = data;
                  }
              });
          TiposFactory.accionesTransaccion().then(function (data) {
            $scope.accions = data.data;
            _.forEach($scope.accions, function (acion) {
              if ((acion.codigo === "ingreso_almacen") && ($state.is("app.transaccionstock.new"))) {
                $scope.acionIngreso = acion;
              }
            });
            if ($scope.transaccion.ingresoCompraDirecta) {
              $scope.transaccion.usarEnOperacionesManuales = false;
              $scope.transaccion.requiereConfirmacion = false;
              $scope.transaccion.transaccionContrapartida = {};
              $scope.transaccion.accion = $scope.acionIngreso
            }
          });
          TiposFactory.clasesTransaccion().then(function (data) {
            $scope.clases = data.data;
          });
          //verificar si cuenta con parametro para diibit
          ParametrosFactory.getByCodigo('habilitar_check_diibit').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
              $scope.utilizarDiibit = false;
            } else {
              $scope.utilizarDiibit = true;
            }
          });
          $scope.tipoEntidadRelacionadas = TiposTipoFactory.get("tipo_entidad_relacionada");

          if ($state.is("app.transaccionstock.new")) {
            activateNew();
          } else if ($state.is("app.transaccionstock.edit")) {
            activateEdit();
          } else if ($state.is("app.transaccionstock.view")) {
            activateView();
          }
          $rootScope.isProcessing = false;
        });
      }
      function activateNew() {
        $scope.title = $filter('translate')('NUEVO_TIPO_TRANSACCION_STOCK');
        $scope.transaccion = {};
        $scope.transaccion.activo = true;
        $scope.transaccion.ingresoCompraDirecta = false;
        $scope.transaccion.usarEnOperacionesManuales = false;
        $scope.transaccion.requiereConfirmacion = false;
        $scope.tipoEntidadRelacionadas.$promise.then(function(data){
          _.forEach(data, function(pp){
            if(pp.codigo == 'entidad_ninguna'){
              $scope.transaccion.tipoEntidadRelacionada = pp;
            };
          });
        })
      }
      function activateEdit() {
        $scope.title = $filter('translate')('EDITAR_TIPO_TRANSACCION_STOCK');
        $scope.transaccion = transaccionStockPrepService;

        $scope.entidadId = $scope.transaccion.id;
        $scope.entidad = "TransaccionStock";

        if ($scope.transaccion.transaccionContrapartida) {
          TransaccionStockFactory.get($scope.transaccion.transaccionContrapartida.id, "BaseList").$promise.then(function (response2) {
            $scope.transaccion.transaccionContrapartida = response2;
          });
        }
      }
      function activateView() {
        $scope.title = $filter('translate')('VER_TIPO_TRANSACCION_STOCK');
        $scope.transaccion = transaccionStockPrepService;
        $scope.entidadId = $scope.transaccion.id;
        $scope.entidad = "TransaccionStock";
        TransaccionStockFactory.get($scope.transaccion.transaccionContrapartida, "BaseList").$promise.then(function (response2) {
          $scope.transaccion.transaccionContrapartida = response2;
        });
        $scope.view = true;
      }

      function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function (error) {
          if (error.constraint == 'descripcion') {
            msg += '\n\n' + $filter('translate')('DESCRIPCION_TRANSACCION_EXISTS') + "\n"
          } else {
            msg += '\n\n' + error.message + '.'
          }

        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });


      }

      $scope.submit = function (form) {
        //formFactory.defaultSubmit('transaccionstock', $scope, form, TransaccionStockFactory);
        $scope.submited = true;
        if ($scope.TransaccionStockForm.$valid) {
          if ($scope.transaccion.accion.codigo == "ingreso_almacen"){
             if ($scope.transaccion.generaRemision){
                notify({ message: $filter('translate')('TRANSACCION_INVALIDA') + " Ingreso no debe permitir Generar Remisión", classes: 'alert-danger', position: 'right' });
                $scope.submited = false;
                return;
             }
            if ($scope.transaccion.transaccionContrapartida != null){
              notify({ message: $filter('translate')('TRANSACCION_INVALIDA') + " Ingreso no debe permitir Contrapartida", classes: 'alert-danger', position: 'right' });
              $scope.submited = false;
              return;
            }
          }
          if ($scope.transaccion.transaccionContrapartida != null){
            if ($scope.transaccion.transaccionContrapartida.transaccionContrapartida != undefined && $scope.transaccion.transaccionContrapartida.transaccionContrapartida != null){
              notify({ message: $filter('translate')('TRANSACCION_INVALIDA') + " Transacción contrapartida seleccionada tiene contrapartida", classes: 'alert-danger', position: 'right' });
              $scope.submited = false;
              return;
            }
          }

          formFactory.defaultNSFSubmit($scope.TransaccionStockForm, TransaccionStockFactory, $scope.transaccion, errorHandler).then(function (response) {
            $location.path('/transaccionstock');
          }, function (error) {
            console.log(error);
          });
        } else {
          //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
        }

      };
      $scope.cancel = function () {
        $location.path('/transaccionstock');
      };
    }
  ]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module('qualita.stock')
  .factory('MovimientoStockFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
  '$translatePartialLoader', '$translate',
    function($resource,  baseurl, filterFactory, TiposFactory, $http, $translatePartialLoader, $translate) {

  	var MovimientoStock= $resource( baseurl.getBaseUrl() + '/movimientostock/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params) {
        return MovimientoStock.query(params);
      },

      get: function(id, view) {
        return MovimientoStock.get({id: id, view: view!=undefined ? view : 'base'});
      },

      create: function(attrs) {
        return new MovimientoStock(attrs);
      },

      save: function(movimientoStock) {
        return (movimientoStock.id) ? movimientoStock.$update() : movimientoStock.$save();
      },

      remove: function(movimientoStock) {
        return movimientoStock.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      },

      confirmar: function (id){
        return $http({
                  url: baseurl.getBaseUrl() + '/movimientostock/'+id+'/confirmar',
                  method: "PUT"
               });
      },

      reversar: function (id){
        return $http({
              url: baseurl.getBaseUrl() + '/movimientostock/'+id+'/reversar',
              method: "PUT"
           });
      },

      generaRemision: function (movimientoRemision) {
        return $http({
          method: 'POST',
          url: baseurl.getBaseUrl() + "/movimientostock/remision",
          data: movimientoRemision
        });
      }
    };
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module('qualita.stock')
  .factory('MovimientoStockDetalleFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
    function($resource,  baseurl, filterFactory, TiposFactory, $http) {
      //console.log('dentro del factory ------------------------');

  	var MovimientoStock= $resource( baseurl.getBaseUrl() + '/movimientostockdetalle/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params) {
        return MovimientoStock.query(params);
      },

      get: function(id) {
        return MovimientoStock.get({id: id});
      },

      create: function(attrs) {
        return new MovimientoStock(attrs);
      },

      save: function(movimientoStock) {
        return (movimientoStock.id) ? movimientoStock.$update() : movimientoStock.$save();
      },

      remove: function(movimientoStock) {
        return movimientoStock.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      },

      getSaldoInicial: function(idProducto, idAlmacen, fecha) {
        return $http({
                url: baseurl.getBaseUrl() + '/movimientostockdetalle/saldoanterior', 
                method: "GET",
                params: {idProducto: idProducto,
                  idAlmacen:idAlmacen,
                  fecha:fecha}
              });
              /*.then(function (data) {
                //console.log(data);

                if(data.data === undefined){
                  return 0;
                }else{
                  return data.data;
                }
                //$route.reload();
              },
              function (status) {
                //console.log('error al marcar como importado');
                notify({ message: 'Ha ocurrido un error en el servidor', classes: 'alert-danger', position: 'right' });
              });*/
      },
      getSaldoInicialGrupo: function(idProducto, idAlmacen, fecha,grupo) {
        return $http({
                url: baseurl.getBaseUrl() + '/movimientostockdetalle/saldoanteriorgrupo', 
                method: "GET",
                params: {idProducto: idProducto,
                  idAlmacen:idAlmacen,
                  fecha:fecha,
                  grupo:grupo}
              });
              /*.then(function (data) {
                //console.log(data);

                if(data.data === undefined){
                  return 0;
                }else{
                  return data.data;
                }
                //$route.reload();
              },
              function (status) {
                //console.log('error al marcar como importado');
                notify({ message: 'Ha ocurrido un error en el servidor', classes: 'alert-danger', position: 'right' });
              });*/
      },
      reprocesar: function (fechaDesde, fechaHasta, idAlmacen, idProducto){

        console.log("fechaDesde: ", fechaDesde);
        console.log("fechaHasta: ", fechaHasta);

        var fechaDesdeFormat = (fechaDesde != undefined) ? moment.utc(fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined;
        var fechaHastaFormat = (fechaHasta != undefined) ? moment.utc(fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined;
        
        console.log("fechaDesdeFormat: ", fechaDesdeFormat);
        console.log("fechaHastaFormat: ", fechaHastaFormat);
        
        return $http({
              url: baseurl.getBaseUrl() + '/movimientostockdetalle/reprocesar',
              method: "PUT",
              params: {
                fechaDesde: fechaDesdeFormat,
                fechaHasta: fechaHastaFormat,
                idAlmacen:  idAlmacen,
                idProducto: idProducto
              }
           });
      }
    };
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module('qualita.stock')
  .factory('ProductoAlmacenFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
    function($resource,  baseurl, filterFactory, TiposFactory, $http) {
      //console.log('dentro del factory ------------------------');

  	var ProductoAlmacen = $resource( baseurl.getBaseUrl() + '/productoalmacen/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
      /*,getSaldos: {
        method: 'GET',
        url: baseurl.getBaseUrl() + "/saldos",
        isArray: true
      }*/
    });

    return {

      all: function(params, view) {
        if (params) {
          params.view = view != undefined || params.view != undefined ? (view || params.view) : 'BaseList';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'BaseList';
        }
        return ProductoAlmacen.query(params);
      },

      get: function(id) {
        return ProductoAlmacen.get({id: id});
      },

      create: function(attrs) {
        return new ProductoAlmacen(attrs);
      },

      save: function(productoalmacen) {
        return (productoalmacen.id) ? productoalmacen.$update() : productoalmacen.$save();
      },

      remove: function(productoalmacen) {
        return productoalmacen.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      },

      obtenerSaldos: function(ids, date) {
        return $http({
                url: baseurl.getBaseUrl() + '/productoalmacen/saldos',
                method: "GET",
                params: {listDetalles: ids, fecha: date, view: 'importacionView'}
              }).then(function (data) {
                return data;
              },
              function (status) {
                console.log("Error");
                //notify({ message: 'Ha ocurrido un error en el servidor', classes: 'alert-danger', position: 'right' });
              });
      },
      getRotacionProductoCSV: function(searchParams) {
        var params = {};
        if (searchParams) {
            params.query = decodeURIComponent($.param(searchParams));
         }
        return $http.post(baseurl.getBaseUrl() + "/productoalmacen/rotacionproducto/csv?" + params.query);
      },
      getExistencia: function(idProducto, idAlmacen){
        return $http({
          url: baseurl.getBaseUrl() + '/productoalmacen/recuperarexistencia',
          method: "GET",
          params: {
            idProducto: idProducto,
            idAlmacen:idAlmacen
          }
          }).then(function (data){
            return data;
          }, function(status){
            console.log("Error");
          });
      }
    };
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module('qualita.stock')
  .factory('GrupoProductoAlmacenFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
    function($resource,  baseurl, filterFactory, TiposFactory, $http) {
      //console.log('dentro del factory ------------------------');

  	var GrupoProductoAlmacen= $resource( baseurl.getBaseUrl() + '/grupoproductoalmacen/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params, view) {
        if (params) {
          params.view = view!=undefined ? view : 'base';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'base';
        }
        return GrupoProductoAlmacen.query(params);
      },

      get: function(id) {
        return GrupoProductoAlmacen.get({id: id});
      },

      create: function(attrs) {
        return new GrupoProductoAlmacen(attrs);
      },

      save: function(grupoproductoalmacen) {
        return (grupoproductoalmacen.id) ? grupoproductoalmacen.$update() : grupoproductoalmacen.$save();
      },

      remove: function(grupoproductoalmacen) {
        return grupoproductoalmacen.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      }
    };
  }]);

'use strict';


angular.module('qualita.stock')
  .controller('MovimientoStockIndexCtrl',
    ["$scope", "MovimientoStockFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "StockLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "RemisionFactory", "$q", "PeriodoAdministrativoFactory", "notify", function ($scope, MovimientoStockFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal, $window,
      $state, StockLangFactory, $filter, UtilFactory, CsvFactory, baseurl, RemisionFactory, $q, PeriodoAdministrativoFactory,
      notify) {

    var emptyDataRender = function (data) {
      //console.log(data);
      if (data == undefined)
        return "";
      else
        return data;
    };

    // filtro estático
    var staticFilter = {};
    staticFilter.search = filterFactory.or([{
      path: 'tipoReferencia.codigo',
      equals: 'ms_tipo_operacion_manual',
    },
    {
      path: 'tipoReferencia.codigo',
      equals: 'ms_tipo_comprobante_venta_diibit'}]).value();

    StockLangFactory.getTranslations().then(function(translations) {
      var defaultColumnOrder = ['numero', 'descripcion', 'transaccion.descripcion','fecha','estado.descripcion','depositoOrigen.descripcion',
      'depositoDestino.descripcion','remision.numero', 'movContrapartida.id', 'movReversion.id'];
      $scope.options = {
        'resource': 'movimientostock',
        'view': 'OperacionManualList',
        'title': $filter('translate')('TRANSACCIONES_STOCK'),
        'staticFilter': staticFilter,
        'factory': MovimientoStockFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          {'data': 'id', 'title': $filter('translate')('CODE'), visible: false},
          {'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class':'dt-center' },
          {'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION')},
          {'data': 'transaccion.descripcion', 'title': $filter('translate')('TRANSACCION')},
          {'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class' : 'dt-center', 'type' : 'date-range'},
          {'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'class' : 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/estados_movimiento_stock'},
          {'data': 'depositoOrigen.descripcion', 'title': $filter('translate')('ALMACEN_ORIGEN'), 'render': emptyDataRender},
          {'data': 'depositoDestino.descripcion', 'title': $filter('translate')('ALMACEN_DESTINO'), 'render': emptyDataRender},
          {'data': 'remision.numero', 'title': $filter('translate')('Nro Remision'), 'render': emptyDataRender},
          {'data': 'movContrapartida.id', 'title': 'Mov. Contrapartida', 'render': emptyDataRender, visible: false, 'class':'dt-center'},
          {'data': 'movReversion.id', 'title': 'Mov. Reversion', 'render': emptyDataRender, visible: false, 'class':'dt-center'}
        ],
        'hasOptions' : true,
        'hideEditMenu' : true,
        'hideRemoveMenu' : true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraMenuOptions':
          [
              {
                'title': 'Reporte',
                'icon': 'glyphicon glyphicon-file',
                'showCondition': function() { return true; },
                'action': function() {
                  var filters = createFilters($scope.options.getFilters());
                  ReportTicketFactory.ticket('listadoOperacionesStock', filters, $scope.options.tableAjaxParams,
                    $scope.options.currentColumnOrder).then(function(genTicket) {
                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                    $window.open($scope.pdfDownloadURL, '_blank');
                  });
                }
              },
              {
                'title': 'CSV',
                'icon': 'glyphicon glyphicon-export',
                'showCondition': function() { return true; },
                'action': function() {
                    //var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);
                    var filters = createFilters($scope.options.getFilters());
                    CsvFactory.csv("movimientostock", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                    .then(function(response) {
                      console.log("response de csv: ", response);
                      var fileName = response.data.fileName;
                      $window.open(baseurl.getPublicBaseUrl() + fileName);
                    });
                  }
              }
          ],
        'extraRowOptions': [{
          templateToRender: "<button title='" + $filter('translate')('EMITIR_REMISION') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataCustom%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
          functionName: "aprobar",
          conditionName: "canAprobar",
          customAttribute: ['estado', 'remision'],
          conditionDef: function(remision) {
              return RemisionFactory.hasPermisos("confirm", remision[0], remision[1]);
          },
          functionDef: function(remision) {
              var filtersArr = [];
              filtersArr.push('idremision=' + remision[1].id);
              $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('remision', 'pdf', filtersArr);
              $window.open($scope.pdfDownloadURL, '_blank');
              $state.go($state.current, {}, { reload: true });
            }
         },
         {
          templateToRender: "<button title='Emitir Transferencia' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='transaccion(<%=dataCustom%>)' ng-class='{ hidden : canTransaccion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
          functionName: "transaccion",
          conditionName: "canTransaccion",
          customAttribute: ['estado', 'remision', 'id'],
          conditionDef: function(remision) {
              return RemisionFactory.hasPermisos("confirm", remision[0], remision[1]);
          },
          functionDef: function(remision) {
              var filtersArr = [];
              filtersArr.push('idtransaccion=' + remision[2]);
              $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('transaccion', 'pdf', filtersArr);
              $window.open($scope.pdfDownloadURL, '_blank');
              $state.go($state.current, {}, { reload: true });
            }
        },
        {
          templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' ng-show='canEditar(<%=dataCustom%>)'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          customAttribute : 'estado',
          conditionName:"canEditar",
          conditionDef: function (estado) {
            return (AuthorizationService.hasPermission('update_movimientostock') && estado.codigo=="ms_transito");
          },
          functionName: "editar",
          functionDef: function(itemId) {
            $state.go("app.movimientostock.edit", {id: itemId});
          }
        },
        {
          templateToRender: "<button title='Confirmar' class='btn btn-primary btn-dt' style='margin-right: 5px;' ng-click='confirmar(<%=dataId%>)' ng-show='canConfirmar(<%=dataCustom%>)'> <span class='glyphicon glyphicon-ok'></span> </button>",
          customAttribute : 'estado',
          conditionName:"canConfirmar",
          conditionDef: function (estado) {
            return (AuthorizationService.hasPermission('update_movimientostock') && estado.codigo=="ms_transito");
          },
          functionName: "confirmar",
          functionDef: function(itemId) {
            $scope.selectedItemId = itemId;
            $scope.tituloModal = $filter('translate')('CONFIRMAR_OPERACION_MANUAL_STOCK');
            $scope.mensajeModal = $filter('translate')('CONFIRMAR_OPERACION_MANUAL_STOCK_Q');
            $scope.modalInstanceConfirmar = $modal.open({
              templateUrl: 'views/directives/confirmation-modal.html',
              scope: $scope
            });

            $scope.cancel = function() {
              $scope.modalInstanceConfirmar.dismiss('cancel');
            };

            $scope.ok = function(itemId) {
              if(AuthorizationService.hasPermission("generar_transaccionstockingresocontrapartida") === true){
                MovimientoStockFactory.confirmar(itemId).then(function() {
                  $scope.modalInstanceConfirmar.close(itemId);
                  $state.go($state.current, {}, {reload: true});
                }, function(error) {
                  console.log("error al confirmar ");
                  console.log(error);
                });
            }else{
              notify({ message: 'El usuario no cuenta con los permisos necesarios para realizar esta acción', classes: 'alert-danger', position: 'right'});
            }
            };
          }
        },
        {
          templateToRender: "<button title='Reversar' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='reversar(<%=dataId%>)' ng-show='canReversar(<%=dataCustom%>)'> <span class='glyphicon glyphicon-remove'></span> </button>",
          customAttribute : ['estado','contrapartida', 'esReversion', 'tipoReferencia', 'transaccion', 'movimientoManual'],
          conditionName:"canReversar",
          conditionDef: function (item) {
            var estado = item[0];
            var contrapartida = item[1];
            var esReversion = item[2];
            var tipoReferencia = item[3];
            var transaccion = item[4];
            var movimientoManual = item[5];

            return (estado.codigo=="ms_confirmado" && esReversion != 'Sí' 
            && (contrapartida != 'Sí' ||  transaccion.revertirContrapartida==true )
            && tipoReferencia.codigo != 'ms_tipo_comprobante_venta_diibit' && movimientoManual != 'Sí');
          },
          functionName: "reversar",
          functionDef: function(itemId) {
            if(AuthorizationService.hasPermission("reversar_transaccionstock") === true){
              $scope.selectedItemId = itemId;
              $scope.tituloModal = $filter('translate')('CONFIRMAR_REVERSION_OPERACION_MANUAL_STOCK');
              $scope.mensajeModal = $filter('translate')('CONFIRMAR_REVERSION_OPERACION_MANUAL_STOCK_Q');
              $scope.modalInstanceReversar = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
              });

              $scope.cancel = function() {
                $scope.modalInstanceReversar.dismiss('cancel');
              };

              $scope.ok = function(itemId) {
                MovimientoStockFactory.reversar(itemId).then(function() {
                  $scope.modalInstanceReversar.close(itemId);
                  $state.go($state.current, {}, {reload: true});
                }, function(error) {
                  var mensaje = "<span>";
                  _.forEach(error.data, function (err) {
                      mensaje = mensaje + err.message + "<br/>";
                  });
                  mensaje = mensaje + "</span>";
                  notify({
                      messageTemplate: mensaje,
                      classes: "alert-danger",
                      position: "right",
                      templateUrl: ""
                  });
                  console.log("error al reversar ");
                  console.log(error);
                });
              };
            }else{
              notify({ message: 'El usuario no cuenta con los permisos necesarios para realizar esta acción', classes: 'alert-danger', position: 'right'});
            }
          }
        }]
      };
    });



    var createFilters = function (filters) {
      var filtersArr = [];
      var filtersArr2 = [];
      _.each(filters, function (search, data) {
          search = search.split("~");
          if (search.length > 1) {
              /* El parámetro es un rago */

              var isDate = search[0].split("/");

              if (isDate.length > 1) {
                  /* Es un rago de fechas */

                  filtersArr.push({path: data, equalOrAfter: search[0]})
                  filtersArr.push({path: data, equalOrBefore: search[1]})

                  filtersArr2.push({path: data, equalOrAfter: search[0]})
                  filtersArr2.push({path: data, equalOrBefore: search[1]})
              } else {
                  /* El un rago numérco */

                  filtersArr.push({path: data, greaterOrEqual: search[0]})
                  filtersArr.push({path: data, lessOrEqual: search[1]})

                  filtersArr2.push({path: data, greaterOrEqual: search[0]})
                  filtersArr2.push({path: data, lessOrEqual: search[1]})
              }
          } else {
              /* El parametro no es un rago */
              filtersArr.push({path: data, like: search[0]})
              filtersArr2.push({path: data, like: search[0]})
          }
      });
      filtersArr.push({path: "tipoReferencia.codigo", like: "ms_tipo_operacion_manual"});
      filtersArr2.push({path: "tipoReferencia.codigo", like: "ms_tipo_comprobante_venta_diibit"});

      var filters = filterFactory.or([
        filterFactory.and(filtersArr).value(),
        filterFactory.and(filtersArr2).value()
      ]).value();

      return filters;
    }


    /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
    var checkPeriodoAdministrativo = function(fechaComprobante){
      return $q(function(resolve, reject) {

          var params = {};
          var fecha;

          if(fechaComprobante == null){
              fecha = new Date();
          }else{
              fecha = fechaComprobante;
          }

          params.search = filterFactory
          .and([{
                  path: "estado.codigo",
                  equals: "per_adm_abierto"
              },
              {
                  path: "fechaDesde",
                  equalOrBefore: moment(fecha).format("DD/MM/YYYY")
              },
              {
                  path: "fechaHasta",
                  equalOrAfter: moment(fecha).format("DD/MM/YYYY")
              }
          ])
          .value();

          PeriodoAdministrativoFactory.all(params).$promise.then(function(responsePeriodo) {
              if(responsePeriodo.length>0){
                  resolve(true);
              }else{
                  resolve(false);
              }
          })
      });
    }
  }]);

'use strict';

angular.module('qualita.stock')
    .controller('MovimientoStockFormController', ['$rootScope', '$scope', 'MovimientoStockFactory', 'ProductosFactory',
        'formFactory', 'TransaccionStockFactory', 'AlmacenFactory', 'TiposFactory', '$location', '$state', 'movimientoStockPrepService',
        '$timeout', 'notify', 'ModelTrimmer', 'filterFactory', 'StockLangFactory', 'ParametrosFactory', '$q',
        "WatchLocacionChangeFactory", "UtilFactory", "$filter", "ClienteFactory",
        "timbradoEmpresaFactory", "puntoEmisionFactory", "TipoComprobanteFactory", "TimbradoEmpresaPuntoEmisionVentaFactory",
        "ComprobanteVentaFactory", "GrupoProductoAlmacenFactory", "ConceptoMovimientoFactory","ProyectoFactory","RemisionFactory", "AuthorizationService",
        function ($rootScope, $scope, MovimientoStockFactory, ProductosFactory,
            formFactory, TransaccionStockFactory, AlmacenFactory, TiposFactory, $location, $state, movimientoStockPrepService,
            $timeout, notify, ModelTrimmer, filterFactory, StockLangFactory, ParametrosFactory, $q,
            WatchLocacionChangeFactory, UtilFactory, $filter, ClienteFactory, timbradoEmpresaFactory,
            puntoEmisionFactory, TipoComprobanteFactory, TimbradoEmpresaPuntoEmisionVentaFactory,
            ComprobanteVentaFactory, GrupoProductoAlmacenFactory, ConceptoMovimientoFactory,ProyectoFactory, RemisionFactory, AuthorizationService) {

            var vm = this;

            vm.uiBlockuiConfig = {
              'bloquear': false
            };

            activate();

            vm.agregarDetalle = agregarDetalle;
            vm.removeItemFromArray = removeItemFromArray;
            vm.submit = submit;
            vm.cancel = cancel;
            vm.changeProducto = changeProducto;
            vm.depositoOrigenChanged = depositoOrigenChanged;
            vm.depositoDestinoChanged = depositoDestinoChanged;
            vm.transaccionSelected = transaccionSelected;
            vm.getRemision = getRemision;
            vm.validarNroFactura = validarNroFactura;
            vm.searchTimbradoPuntoEmisionFilter = searchTimbradoPuntoEmisionFilter;
            vm.timbradoSelect = timbradoSelect;
            vm.puntoEmisionSelected = puntoEmisionSelected;
            $rootScope.isProcessing = false;
            vm.nroOrden = false;
            vm.nroMov = false;
            vm.changeCliente = changeCliente;
            vm.delayRequestCliente = delayRequestCliente;
            vm.searchClienteFilter = searchClienteFilter;
            vm.searchSucursalClienteFilter = searchSucursalClienteFilter;
            vm.curDate = new Date();
            vm.changeFecha = changeFecha;

            function changeFecha() {
                if(vm.movimientoStock.hora.split(':')[0] == '00' && vm.movimientoStock.hora.split(':')[1] == '00'){
                    var msg = "La hora no puede ser 00:00 porque esta reservado para Compras";
                    notify({ message: msg, classes: "alert-warning", position: "right" })
                    vm.movimientoStock.hora = moment(vm.curDate).format("HH:mm");
                    return;
                }
            }

            function searchSucursalClienteFilter(criteria) {
                return function (item) {
                    if (!criteria) {
                        return true;
                    }
                    return (
                        item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                        (item.codigoSucursal.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                        (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                        (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
                    );
                };
            }

            function changeCliente() {
                vm.sucursalesCliente = vm.movimientoStock.entidadPrincipal.sucursales;
                if (vm.sucursalesCliente.length == 1) {
                    vm.movimientoStock.sucursalEntidad = vm.sucursalesCliente[0];
                } else {
                    vm.movimientoStock.sucursalEntidad = null;
                }
            }

            function delayRequestCliente(searchValue) {
                var userInputFilter = {}
                if (searchValue.length >= 3) {
                    var filterNombre = [];
                    var filterNombreFantasia = [];
                    var filterCodigo = [];
                    var filterNumero = [];
                    var filterDireccion = [];
                    var filterCiudad = [];

                    filterNombre.push({
                        path: "nombre",
                        like: searchValue
                    })
                    filterNombreFantasia.push({
                        path: "nombreFantasia",
                        like: searchValue
                    })
                    filterCodigo.push({
                        path: "codigo",
                        like: searchValue
                    })
                    filterNumero.push({
                        path: "numero",
                        like: searchValue
                    })
                    filterDireccion.push({
                        path: "direccion",
                        like: searchValue
                    })
                    filterCiudad.push({
                        path: "ciudad.descripcion",
                        like: searchValue
                    })

                    userInputFilter = {
                        search: filterFactory.or([
                            filterFactory.and(filterNombre).value(),
                            filterFactory.and(filterNombreFantasia).value(),
                            filterFactory.and(filterCodigo).value(),
                            filterFactory.and(filterNumero).value(),
                            filterFactory.and(filterDireccion).value(),
                            filterFactory.and(filterCiudad).value()
                        ]).value()
                    }

                    recuperarCliente(userInputFilter)
                } else {
                    vm.clientes = undefined
                }
            }

            function recuperarCliente(userInputFilter) {
                var finalFilter = UtilFactory.createNestedFilters(
                    vm.finalFilters, userInputFilter)

                vm.clientes = ClienteFactory.allForSelect(finalFilter)
            }

            function searchClienteFilter(criteria) {
                return function (item) {
                    if (!criteria) {
                        return true;
                    }
                    return (
                        item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                        (item.nombreFantasia && item.nombreFantasia.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                        (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                        item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                        (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                        (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
                    );
                };
            }

            function activate() {
                WatchLocacionChangeFactory.killAllWatchers();

                ParametrosFactory.filterByCodigo("formato_nro_comprobante").$promise.then(function (response) {
                    vm.formatoComprobante = response[0].valor;
                });
                vm.proyectos = ProyectoFactory.all("", "OperacionManualForm");
                ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
                  vm.proyectoObligatorio = r.valorTipo.codigo == "si";
                });

                StockLangFactory.getTranslations().then(function (translations) {
                    vm.translations = translations;

                    var promise1 = ParametrosFactory.getByCodigo('incluir_nro_orden_produccion');
                    var promise2 = ParametrosFactory.getByCodigo('incluir_nro_mov_materiales');
                    var promises = [promise1, promise2];

                    $q.all(promises).then(function (responses) {
                        if (responses[0].valorTipo.codigo == 'si') {
                            vm.nroOrden = true;
                        }
                        if (responses[1].valorTipo.codigo == 'si') {
                            vm.nroMov = true;
                        }
                        vm.curDate = new Date();
                        var staticFilter = {};
                        staticFilter.search = filterFactory.and([{
                            path: 'usarEnOperacionesManuales',
                            like: 'true'
                        }, {
                            path: 'activo',
                            equals: true
                        }]).value();
                        staticFilter.view = "BaseList";
                        vm.transacciones = TransaccionStockFactory.all(staticFilter);
                        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcherAlmacen) {
                            //ClienteFactory.all().$promise.then(function (response) {
                             //   vm.clientes = response;
                          //  });
                            var staticFilterPE = {
                                search: filterFactory.and([{
                                    path: 'puntoVenta.sucursal.id',
                                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                                }]).value()
                            };
                            staticFilterPE.view = "ConsultaComprobanteVentaForm";
                            vm.puntosEmision = puntoEmisionFactory.all(staticFilterPE);
                            var staticFilterTC = {
                                search: filterFactory.or([
                                    filterFactory.and([{
                                        path: "clase.codigo",
                                        equals: "remision"
                                    },
                                    {
                                        path: "utilizarEnProcesosVenta",
                                        equals: true
                                    }
                                    ]).value()
                                ]).value()
                            };
                            staticFilterTC.view = "BaseList";
                            vm.tiposComprobante = TipoComprobanteFactory.all(staticFilterTC);                            
                        }, "locacion.id");

                        var staticFilterProductos = {};
                        staticFilterProductos.search = filterFactory.and([
                            {
                                path: 'controlStock',
                                like: 'true'
                            },
                            {
                                path: 'estado.codigo',
                                equals: 'activo'
                            }
                        ]).value();

                        if (vm.watcher) {
                            vm.watcher();
                        }
                        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
                            vm.watcher = watcher;
                            //vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProductos), "OperacionManualForm");
                        }, "locaciones.id");

                        vm.factoryProductos = ProductosFactory;

                        var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
                            'lineaProducto.descripcion', 'marca.descripcion'
                        ];

                        // TODO agregar locacionFilter
                        vm.opcionesProductosDt = {
                            'resource': 'productos',
                            'view': 'ProductosDatatableList',
                            'title': 'Productos',
                            'staticFilter': staticFilterProductos,
                            'factory': ProductosFactory,
                            'defaultColumnOrder': defaultColumnOrder,
                            'columns': [
                                { 'data': 'codigo', 'title': 'Código', visible: false },
                                { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
                                { 'data': 'claseProducto.descripcion', 'title': 'Clase' },
                                { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
                                { 'data': 'lineaProducto.descripcion', 'title': 'Línea' },
                                { 'data': 'marca.descripcion', 'title': 'Marca', 'renderWith': 'emptyRender' },
                            ],
                            'hasOptions': true,
                            'defaultOrderColumn': 0,
                            'defaultOrderDir': "desc"
                        };

                        if ($state.is("app.movimientostock.new")) {
                            activateNew();
                        } else if ($state.is("app.movimientostock.edit")) {
                            activateEdit();
                        } else if ($state.is("app.movimientostock.view")) {
                            activateView();
                        }
                    });
                });
            }


            /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
            vm.staticFilterClientes = [{ path: "activo", equals: true }];
            vm.clientesFactory = ClienteFactory;

            function activateNew() {
                vm.title = $filter('translate')('NEW_TRANSACCION_STOCK');
                vm.movimientoStock = {
                    movimientoDetalle: [{}],
                };
                vm.movimientoStock.fecha = vm.curDate;
                vm.movimientoStock.hora = moment(vm.curDate).format("HH:mm");
                TiposFactory.estadosMovimientoStock().then(function (data) {
                    vm.tiposEstado = data.data;
                    var estadoConfirmado = _.filter(vm.tiposEstado, function (value) {
                        return value.codigo === 'ms_confirmado';
                    });
                    vm.movimientoStock.estado = estadoConfirmado[0];
                });

                $timeout(function () {
                    $scope.$broadcast('formOpened');
                }, 20);

            }

            function activateView() {
                vm.title = $filter('translate')('VIEW_TRANSACCION_STOCK');
                vm.movimientoStock = movimientoStockPrepService;
                vm.movimientoStock.fecha =  new Date(vm.movimientoStock.fecha);
                vm.entidadId = vm.movimientoStock.id;
                vm.entidad = "MovimientoStock";
                vm.view = true;
                getRemision();
                console.log("vm.movimientoStock ", vm.movimientoStock)
                 //verificar si tiene el check de solicitud y si es cliente
                 vm.habilitarCampos = false;
                 if (vm.movimientoStock.transaccion.obligatorioSolicitud && vm.movimientoStock.transaccion.tipoEntidadRelacionada.codigo == 'entidad_cliente') {
                     vm.habilitarCampos = true;
                 }
                 vm.habilitarConcepto = false;
                 if (vm.movimientoStock.transaccion.requiereConcepto) {
                     vm.habilitarConcepto = true;
                 }
            }

            function activateEdit() {
                vm.title = $filter('translate')('EDIT_TRANSACCION_STOCK');
                vm.movimientoStock = movimientoStockPrepService;
                vm.movimientoStock.fecha = new Date(vm.movimientoStock.fecha);
                vm.entidadId = vm.movimientoStock.id;
                vm.entidad = "MovimientoStock";
                $timeout(function () {
                    $scope.$broadcast('formOpened');
                }, 20);
                getRemision();
            }

            $scope.delayRequestProducto = function (searchValue) {
                var userInputFilter = {};
                var staticFilter = [];
                if (searchValue.length >= 3) {

                    var filterNombre = staticFilter.slice()
                    var filterCodigoBarras = staticFilter.slice()
                    var filterCodigoInterno = staticFilter.slice()

                    filterNombre.push({
                        path: "nombre",
                        like: searchValue
                    },
                        {
                            path: "estado.codigo",
                            notEquals: 'inactivo'
                        })

                    filterCodigoBarras.push({
                        path: "codigoBarrasPrincipal.codigoBarras",
                        like: searchValue
                    },
                        {
                            path: "estado.codigo",
                            notEquals: 'inactivo'
                        })

                    filterCodigoInterno.push({
                        path: "codigo",
                        like: searchValue
                    },
                        {
                            path: "estado.codigo",
                            notEquals: 'inactivo'
                        })

                    userInputFilter = {
                        search: filterFactory.or([
                            filterFactory.and(filterNombre).value(),
                            filterFactory.and(filterCodigoBarras).value(),
                            filterFactory.and(filterCodigoInterno).value()
                        ]).value()
                    }

                    $scope.recuperarProducto(userInputFilter)
                } else if (searchValue.length > 0 && searchValue.length < 3) {

                    var filterCodigoInterno = staticFilter.slice()
                    filterCodigoInterno.push({
                        path: "codigo",
                        like: searchValue
                    })

                    userInputFilter = {
                        search: filterFactory.or([
                            filterFactory.and(filterCodigoInterno).value()
                        ]).value()
                    }
                    $scope.recuperarProducto(userInputFilter)
                } else {
                    vm.productos = undefined
                }
            }

            $scope.recuperarProducto = function (userInputFilter) {
                var finalFilter = UtilFactory.createNestedFilters(
                    $scope.finalFilters, userInputFilter)

                vm.productos = ProductosFactory.allForSelect(userInputFilter)
            };

            function getRemision() {
                if (!vm.movimientoStock.remision) {
                    return;
                }
                if (vm.movimientoStock.remision.cliente) {
                    vm.movimientoStock.cliente = vm.movimientoStock.remision.cliente;
                }

                if (vm.movimientoStock.remision.tipoComprobante) {
                    vm.movimientoStock.tipo = vm.movimientoStock.remision.tipoComprobante;
                }

                if (vm.movimientoStock.remision.timbradoPuntoEmision) {
                    vm.movimientoStock.timbradoPuntoEmision = vm.movimientoStock.remision.timbradoPuntoEmision;
                }

                if (vm.movimientoStock.remision.timbradoPuntoEmision) {
                    vm.movimientoStock.puntoEmision = vm.movimientoStock.remision.timbradoPuntoEmision.puntoEmisionVenta;
                }

                if (vm.movimientoStock.remision.timbradoPuntoEmision) {
                    vm.movimientoStock.puntoEmision = vm.movimientoStock.remision.timbradoPuntoEmision.puntoEmisionVenta;
                }

                if (vm.movimientoStock.remision.direccionOrigen) {
                    vm.movimientoStock.direccionOrigen = vm.movimientoStock.remision.direccionOrigen;
                }

                if (vm.movimientoStock.remision.direccionDestino) {
                    vm.movimientoStock.direccionDestino = vm.movimientoStock.remision.direccionDestino;
                }

                if (vm.movimientoStock.remision.fechaInicio) {
                    vm.movimientoStock.fechaInicio = vm.movimientoStock.remision.fechaInicio;
                }

                if (vm.movimientoStock.remision.fechaFin) {
                    vm.movimientoStock.fechaFin = vm.movimientoStock.remision.fechaFin;
                }

                if (vm.movimientoStock.remision.vehiculo) {
                    vm.movimientoStock.vehiculo = vm.movimientoStock.remision.vehiculo;
                }

                if (vm.movimientoStock.remision.nroMatricula) {
                    vm.movimientoStock.nroMatricula = vm.movimientoStock.remision.nroMatricula;
                }

                if (vm.movimientoStock.remision.transportista) {
                    vm.movimientoStock.transportista = vm.movimientoStock.remision.transportista;
                }

                if (vm.movimientoStock.remision.nroDocumentoTransportista) {
                    vm.movimientoStock.nroDocumentoTransportista = vm.movimientoStock.remision.nroDocumentoTransportista;
                }

                if (vm.movimientoStock.remision.conductor) {
                    vm.movimientoStock.conductor = vm.movimientoStock.remision.conductor;
                }

                if (vm.movimientoStock.remision.nroDocumentoConductor) {
                    vm.movimientoStock.nroDocumentoConductor = vm.movimientoStock.remision.nroDocumentoConductor;
                }

                if (vm.movimientoStock.remision.direccionConductor) {
                    vm.movimientoStock.direccionConductor = vm.movimientoStock.remision.direccionConductor;
                }

                if (vm.movimientoStock.remision.motivoTranslado) {
                    vm.movimientoStock.motivoTranslado = vm.movimientoStock.remision.motivoTranslado;
                }

                if (vm.movimientoStock.remision.numero) {
                    vm.movimientoStock.numero = vm.movimientoStock.remision.numero;
                }

            }

            vm.searchProductoFilter = function (criteria) {
                return function (item) {
                    if (!criteria) {
                        return true;
                    }
                    return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria) === 0);
                };
            }

            function agregarDetalle() {
                ParametrosFactory.getByCodigo("cantidad_items_remision").then(function (r) {
                    if (vm.movimientoStock.transaccion.generaRemision) {
                        if (parseInt(r.valor) > vm.movimientoStock.movimientoDetalle.length) {
                            if (vm.movimientoStock.movimientoDetalle != null) {
                                vm.movimientoStock.movimientoDetalle.push({});
                                var rowNumber = vm.movimientoStock.movimientoDetalle.length - 1;
                                $timeout(function () {
                                    $scope.$broadcast('newItemAdded');
                                }, 20);
                            } else {
                                vm.movimientoStock.movimientoDetalle = [];
                                vm.movimientoStock.movimientoDetalle.push({});
                            }
                        } else {
                            var msg = $filter('translate')('ERROR_CANTIDAD_ITEM_REMISION');
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            //                            alert("Ha superado la cantidad máxima de ítems por remisión");
                        }
                    } else {
                        if (vm.movimientoStock.movimientoDetalle != null) {
                            vm.movimientoStock.movimientoDetalle.push({});
                            var rowNumber = vm.movimientoStock.movimientoDetalle.length - 1;
                            $timeout(function () {
                                $scope.$broadcast('newItemAdded');
                            }, 20);
                        } else {
                            vm.movimientoStock.movimientoDetalle = [];
                            vm.movimientoStock.movimientoDetalle.push({});
                        }
                    }
                });
            }

            function removeItemFromArray(elemento, arreglo) {
                _.remove(arreglo, function (item) {
                    return item === elemento;
                });

                $timeout(function () {
                    $scope.$broadcast('newItemRemoved');
                }, 20);
            }

            function getStockSerieEgreso(detalle) {
                var filterGrupo = [];
                var filterNormal = [];
                vm.almacenesConStock = [];

                ProductosFactory.get(detalle.producto.id, 'BaseList').$promise.then(function (producto) {
                    detalle.producto = producto;
                    detalle.grupo = null;
                    _.forEach(detalle.producto.gruposProducto, function (grupo) {
                        var filter = [{
                            path: "grupoProducto.id",
                            equals: grupo.id
                        },
                        {
                            path: 'almacen.id',
                            equals: vm.movimientoStock.depositoOrigen.id
                        }];
                        filterGrupo.push(filterFactory.and(filter).value());
                    });

                    var filterFinal = {
                        search: filterFactory.or(filterGrupo).value()
                    }
                    GrupoProductoAlmacenFactory.all(filterFinal, 'BaseList').$promise.then(function (response) {
                        var grupoConStock = [];
                        if (response.length > 0) {
                            _.forEach(response, function (elem) {
                                var existente = _.find(vm.almacenesConStock, function (stock) {
                                    if (stock.grupoProducto != undefined && elem.grupoProducto.id == stock.grupoProducto.id) {
                                        return stock;
                                    }
                                });
                                if (existente != undefined && existente != null) {
                                    if (elem.dia > existente.dia) {
                                        existente.dia = elem.dia;
                                        existente.saldoReal = elem.saldoReal;
                                    }
                                } else {
                                    vm.almacenesConStock.push(elem);
                                }
                            });

                            _.forEach(detalle.producto.gruposProducto, function (grupo) {
                                var existe = false;
                                _.forEach(vm.almacenesConStock, function (stock) {
                                    if (grupo.id == stock.grupoProducto.id) {
                                        if (stock.saldoReal > 0) {
                                            existe = true;
                                        }
                                    }
                                });
                                if (existe) {
                                    grupoConStock.push(grupo);
                                }
                            });

                        }
                        detalle.producto.gruposProducto = grupoConStock;
                        if (grupoConStock.length == 0) {
                            var msg = $filter('translate')('NO_EXISTE_GRUPO_CON_STOCK') + " (Producto: " + detalle.producto.nombre + ")";
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        }
                    });

                });


            }

            function changeProducto(detalle) {
                detalle.unidadMedidaBase = detalle.producto.unidadMedidaBase;
                detalle.unidadMedidaReferencial = detalle.producto.unidadMedidaBase;
                detalle.ingreso = null;
                detalle.egreso = null;
                detalle.grupo = null;
                if (detalle.producto.claseProducto.tipoAdministracion.codigo == "bobinas") {
                    detalle.producto.gruposProducto = _.filter(detalle.producto.gruposProducto, function (gp) {
                        return gp.estado && gp.estado.codigo == "gp_clasificado" && gp.pesoReal > 0;
                    });
                }
                if (detalle.producto.criterioControl.codigo == "series") {
                    detalle.cantidadReferencial = 1;
                    if (vm.movimientoStock.depositoOrigen != null && vm.movimientoStock.transaccion != null) {
                        if (vm.movimientoStock.transaccion.accion.codigo == "egreso_almacen") {
                            getStockSerieEgreso(detalle);
                        } else {
                            var msg = $filter('translate')('INGRESO_SERIE_TRANSACCION_INVALIDA');
                            notify({ message: msg, classes: 'alert-warning', position: 'right' });
                            removeItemFromArray(detalle, vm.movimientoStock.movimientoDetalle);
                        }
                    } else {
                        detalle.producto.gruposProducto = null;
                        if (vm.movimientoStock.depositoOrigen == null) {
                            var msg = $filter('translate')('VERIFIQUE_ALMACEN');
                            notify({ message: msg, classes: 'alert-warning', position: 'right' });
                        }
                        if (vm.movimientoStock.transaccion == null) {
                            var msg = $filter('translate')('VERIFIQUE_TIPO_TRANSACCION');
                            notify({ message: msg, classes: 'alert-warning', position: 'right' });
                        }
                    }

                }
            }

            function submit() {
                vm.submited = true;
                if ($scope.MovimientoStockForm.$valid) {
                    if (vm.movimientoStock.movimientoDetalle.length >= 1) {
                        for (var i = 0; i < vm.movimientoStock.movimientoDetalle.length; i++) {
                            var elem = vm.movimientoStock.movimientoDetalle[i];
                            if (!elem.unidadMedidaReferencial.permiteDecimales) {

                                if ((elem.cantidadReferencial % 1) !== 0) {
                                    var msg = "Existen productos con unidad de medida que no permiten decimales";
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    return;
                                }
                            }
                        }

                        $rootScope.isProcessing = true;
                        vm.movimientoStock.movimientoDetalle = _.filter(vm.movimientoStock.movimientoDetalle, function (elem) {
                            return elem.producto;
                        });
                        // se quita el atributo grupo de los detalles cuyos productos son de tipo normal
                        vm.movimientoStock.movimientoDetalle = _.forEach(vm.movimientoStock.movimientoDetalle, function (elem) {
                            if (elem.producto.criterioControl.codigo !== 'series' && elem.producto.criterioControl.codigo !== 'lotes') {
                                delete elem.grupo;
                            }
                        });

                        var errorSerieRepetida = false;
                        for (var i = 0; i < vm.movimientoStock.movimientoDetalle.length; i++) {
                            var elem = vm.movimientoStock.movimientoDetalle[i];
                            var cantRegistros = 0;
                            if (elem.producto != null && elem.producto.criterioControl.codigo == 'series') {
                                _.forEach(vm.movimientoStock.movimientoDetalle, function (elem2) {
                                    if ((elem2.producto.criterioControl.codigo == 'series') && elem.producto.id == elem2.producto.id && elem.grupo.id == elem2.grupo.id) {
                                        cantRegistros++;
                                    }
                                });
                            }
                            if (cantRegistros > 1) {
                                var msg = $filter('translate')('NUMERO_GRUPO_REPETIDO') + " (Producto: " + elem.producto.nombre + ")";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                errorSerieRepetida = true;
                                break;
                            }
                        };
                        if (errorSerieRepetida) {
                            $rootScope.isProcessing = false;
                            return;
                        }

                        submitMovimientoStock().then(function (response) {
                            $state.go('app.movimientostock.list');
                        }, function (error) {
                            $rootScope.isProcessing = false;
                            var msg = $filter('translate')('SAVE_ERROR_OP_MANUAL_STOCK');
                            if (error.data && error.data[0]) {
                                if (error.data[0].constraint === "id_timbrado_punto_emision, numero") {
                                    msg = $filter('translate')('NUMERO_TIMBRADO_REMISION');
                                } else {
                                    msg = error.data[0].message;
                                }
                            }
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        });
                    } else {
                        var msg = $filter('translate')('DETAILS_ERROR_OP_MANUAL_STOCK');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    }
                }
            }

            function submitMovimientoStock() {
                var trimmedModel = ModelTrimmer.trimDetails(vm.movimientoStock, ["movimientoDetalle"]);
                var trimmedDetalles = _.map(vm.movimientoStock.movimientoDetalle, function (detalle) {
                    return ModelTrimmer.trimDetails(detalle);
                });
                trimmedModel.movimientoDetalle = trimmedDetalles;
                var resource = MovimientoStockFactory.create(trimmedModel);
                if (!vm.movimientoStock.transaccion.generaRemision) {
                    return MovimientoStockFactory.save(resource);
                } else {
                    //vm.movimientoStock.cliente = vm.cliente;
                    //vm.movimientoStock.timbradoEmpresa = vm.timbradoEmpresa;
                    //vm.movimientoStock.tiposComprobante = vm.modeltipocomprobante;
                    //vm.movimientoStock.puntoEmision = vm.puntoEmision;
                    return MovimientoStockFactory.generaRemision(vm.movimientoStock);
                }
            }

            function depositoOrigenChanged() {
                vm.depositosDestino = _.map(vm.depositosSinFiltro, _.clone);
                _.remove(vm.depositosDestino, function (deposito) {
                    return deposito.id == vm.movimientoStock.depositoOrigen.id;
                });
                _.forEach(vm.movimientoStock.movimientoDetalle, function (elem) {
                    if (elem.producto != null && elem.producto.criterioControl.codigo == 'series') {
                        if (vm.movimientoStock.transaccion.accion.codigo == "egreso_almacen") {
                            getStockSerieEgreso(elem);
                        } else {
                            var msg = $filter('translate')('INGRESO_SERIE_TRANSACCION_INVALIDA');
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            removeItemFromArray(elem, vm.movimientoStock.movimientoDetalle);
                        }
                    }
                });
            }

            function depositoDestinoChanged() {
                vm.depositosOrigen = _.map(vm.depositos, _.clone);
                _.remove(vm.depositosOrigen, function (deposito) {
                    return deposito.id == vm.movimientoStock.depositoDestino.id;
                });

            }

            function transaccionSelected() {
                //verificacion de permisos
                //si el tipo de transaccion tiene el check de Usar en Operaciones Manuales de Stock y la accion es Ingreso a un almacen y tiene el permiso dejar avanzar, si no lanzar mensaje
                //si el tipo de transaccion tiene el check de Usar en Operaciones Manuales de Stock y la accion es Egreso a un almacen y tiene el permiso dejar avanzar, si no lanzar mensaje
                //si el tipo de transaccion tiene el check de Usar en Operaciones Manuales de Stock, la accion es Ingreso a un almacén, tiene una transacción de contrapartida seleccionada y tiene el permiso dejar avanzar, si no lanzar mensaje
                //si el tipo de transaccion tiene el check de Usar en Operaciones Manuales de Stock, la accion es Egreso a un almacén, tiene una transacción de contrapartida seleccionada y tiene el permiso dejar avanzar, si no lanzar mensaje
                if((vm.movimientoStock.transaccion.usarEnOperacionesManuales && vm.movimientoStock.transaccion.accion.codigo == "ingreso_almacen" && vm.movimientoStock.transaccion.transaccionContrapartida == null && !AuthorizationService.hasPermission("generar_transaccionstockingreso")) || 
                (vm.movimientoStock.transaccion.usarEnOperacionesManuales && vm.movimientoStock.transaccion.accion.codigo == "egreso_almacen" && vm.movimientoStock.transaccion.transaccionContrapartida == null && !AuthorizationService.hasPermission("generar_transaccionstockegreso")) ||
                (vm.movimientoStock.transaccion.usarEnOperacionesManuales && vm.movimientoStock.transaccion.accion.codigo == "egreso_almacen" && vm.movimientoStock.transaccion.transaccionContrapartida != null && !vm.movimientoStock.transaccion.requiereConfirmacion && !AuthorizationService.hasPermission("generar_transaccionstockegresocontrapartida") && !AuthorizationService.hasPermission("generar_transaccionstockingresocontrapartida")) ||
                (vm.movimientoStock.transaccion.usarEnOperacionesManuales && vm.movimientoStock.transaccion.accion.codigo == "egreso_almacen" && vm.movimientoStock.transaccion.transaccionContrapartida != null && vm.movimientoStock.transaccion.requiereConfirmacion && !AuthorizationService.hasPermission("generar_transaccionstockegresocontrapartida")) ||
                (vm.movimientoStock.transaccion.usarEnOperacionesManuales && vm.movimientoStock.transaccion.accion.codigo == "egreso_almacen" && vm.movimientoStock.transaccion.transaccionContrapartida != null && !vm.movimientoStock.transaccion.requiereConfirmacion && !AuthorizationService.hasPermission("generar_transaccionstockegresocontrapartida")) ||
                (vm.movimientoStock.transaccion.usarEnOperacionesManuales && vm.movimientoStock.transaccion.accion.codigo == "egreso_almacen" && vm.movimientoStock.transaccion.transaccionContrapartida != null && !vm.movimientoStock.transaccion.requiereConfirmacion && AuthorizationService.hasPermission("generar_transaccionstockegresocontrapartida") && !AuthorizationService.hasPermission("generar_transaccionstockingresocontrapartida"))){
                    vm.movimientoStock.transaccion = null;
                    notify({ message: 'El usuario no cuenta con los permisos necesarios para realizar esta acción', classes: 'alert-danger', position: 'right'});
                }
                if(vm.movimientoStock.transaccion){
                    vm.movimientoStock.depositoOrigen = undefined;
                    vm.movimientoStock.depositoDestino = undefined;
                    if (!vm.movimientoStock.transaccion.transaccionContrapartida) {
                        delete vm.movimientoStock.depositoDestino;
                    }
                    if (vm.movimientoStock.depositoOrigen != null) {
                        _.forEach(vm.movimientoStock.movimientoDetalle, function (elem) {
                            if (elem.producto != null && elem.producto.criterioControl.codigo == 'series') {
                                if (vm.movimientoStock.transaccion.accion.codigo == "egreso_almacen") {
                                    getStockSerieEgreso(elem);
                                } else {
                                    var msg = $filter('translate')('INGRESO_SERIE_TRANSACCION_INVALIDA');
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    removeItemFromArray(elem, vm.movimientoStock.movimientoDetalle);
                                }
                            }
                        });
                    }
                    console.log("vm.movimientoStock ", vm.movimientoStock.transaccion)
                    //verificar si tiene el check de solicitud y si es cliente
                    vm.habilitarCampos = false;
                    if (vm.movimientoStock.transaccion.obligatorioSolicitud && vm.movimientoStock.transaccion.tipoEntidadRelacionada.codigo == 'entidad_cliente') {
                        vm.habilitarCampos = true;
                    }
                    vm.habilitarConcepto = false;
                    if (vm.movimientoStock.transaccion.requiereConcepto) {
                        vm.habilitarConcepto = true;
                        var filtroConcepto = {};
                        filtroConcepto.search = filterFactory.and([{
                            path: 'activo',
                            like: 'true'
                        }]).value();
                        filtroConcepto.view = "TransaccionStockForm";
                        vm.conceptoMovimientos = ConceptoMovimientoFactory.all(filtroConcepto);
                    }

                    //si es para nota credito
                    if(vm.habilitarCampos && vm.habilitarConcepto){
                        //filtrar almacenes por el check de almacenes solicitudes NC
                        var filterAlmacen = {
                            search: filterFactory.and([{
                                path: "sucursal.id",
                                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                            },
                            {
                                path: "activo",
                                equals: true
    
                            },
                            {
                                path: "clase.codigo",
                                equals: vm.movimientoStock.transaccion.clase.codigo
    
                            },
                            {
                                path: "asignarParaSolicitudesNotaCredito",
                                equals: true
    
                            }]).value()
                        };
                        filterAlmacen.view = "BaseList";
                        AlmacenFactory.all(filterAlmacen).$promise.then(function (response) {
                            vm.almacenNc = false;
                            if(response.length == 1){
                                vm.depositos = response;
                                vm.depositosOrigen = _.map(vm.depositos, _.clone);
                                vm.movimientoStock.depositoOrigen = response[0];
                                vm.almacenNc = true;
                            }else{
                                vm.depositos = response;
                                vm.depositosOrigen = _.map(vm.depositos, _.clone);
                                vm.almacenNc = false;
                            }
                        });
                    }else{
                        var filterAlmacen = {
                            search: filterFactory.and([{
                                path: "sucursal.id",
                                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                            },
                            {
                                path: "activo",
                                equals: true
    
                            },
                            {
                                path: "clase.codigo",
                                equals: vm.movimientoStock.transaccion.clase.codigo
    
                            }]).value()
                        };
                        filterAlmacen.view = "BaseList";
                        AlmacenFactory.all(filterAlmacen).$promise.then(function (response) {
                            console.log("Acaaa1 ", response)
                            vm.almacenNc = false;
                            vm.depositos = response;
                            vm.depositosOrigen = _.map(vm.depositos, _.clone);
                        });
    
                        var filterAlmacenEstado = {
                            search: filterFactory.and([{
                                path: "activo",
                                equals: true
                            },
                            {
                                path: "clase.codigo",
                                equals: vm.movimientoStock.transaccion.clase.codigo
    
                            }]).value()
                        };
                        filterAlmacenEstado.view = "BaseList";
                        AlmacenFactory.all(filterAlmacenEstado).$promise.then(function (response) {
                            console.log("Acaaa ", response)
                            vm.almacenNc = false;
                            vm.depositosSinFiltro = response;
                            vm.depositosDestino = _.map(vm.depositosSinFiltro, _.clone);
                        });
                    }
                }
            }

            function cancel() {
                $state.go('app.movimientostock.list');
            }

            function validarNroFactura() {
                vm.movimientoStock = ComprobanteVentaFactory.validateNroFactura($scope.MovimientoStockForm, vm.movimientoStock, null, vm.formatoComprobante);

            }

            function timbradoSelect() {
              vm.movimientoStock.numero = undefined;
              if(vm.movimientoStock.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo != 'timbrado_manual'){
                var numeroActual = fillStringWithLeftCharacters(vm.movimientoStock.timbradoPuntoEmision.numeroActual.toString(), 7, '0');;
                var codigoSucursal = vm.movimientoStock.timbradoPuntoEmision.puntoEmisionVenta.puntoVenta.codigoSucursal;
                var numeroPuntoEmision = vm.movimientoStock.timbradoPuntoEmision.puntoEmisionVenta.numero;

                var staticFilter = {};
                staticFilter.search = filterFactory.and([{
                  path: 'numero',
                  equals: codigoSucursal + "-" + numeroPuntoEmision + "-" + numeroActual
                }]).value();
                staticFilter.view = "BaseList";
                vm.uiBlockuiConfig.bloquear = true;
                RemisionFactory.all(staticFilter).$promise.then(function (response) {
                  vm.uiBlockuiConfig.bloquear = false;
                  if(response.length > 0){
                    var staticFilter2 = {};
                    staticFilter2.search = filterFactory.and([
                      {
                        path: 'timbradoPuntoEmision.id',
                        equals: vm.movimientoStock.timbradoPuntoEmision.id
                      },
                      {
                        path: 'numero',
                        sortDesc: 'true'
                      }]).value();
                    staticFilter2.view = "BaseList";
                    vm.uiBlockuiConfig.bloquear = true;
                    RemisionFactory.all(staticFilter2).$promise.then(function (response) {
                      vm.uiBlockuiConfig.bloquear = false;
                      var numeroSiguiente;
                      for(var i = 0; i < response.length; i++){
                        if(response[i].numero != null){
                          numeroSiguiente = obtenerSiguienteCorrelativo(response[i].numero);
                          break;
                        }
                      }
                      vm.movimientoStock.numero = numeroSiguiente;
                    });
                  } else {
                    vm.movimientoStock.numero = codigoSucursal + "-" + numeroPuntoEmision + "-" + numeroActual;
                  }
                });
              }
            }

            function searchTimbradoPuntoEmisionFilter(criteria) {
                return function (item) {
                    if (!criteria) {
                        return true;
                    }

                    return String(item.timbradoEmpresa.numeroTimbrado).toLowerCase().indexOf(criteria.toLowerCase()) > -1;

                };
            }

            function puntoEmisionSelected() {
                vm.movimientoStock.timbradoPuntoEmision = undefined;
                vm.movimientoStock.numero = undefined;
                var staticFilter2 = {};
                staticFilter2.search = filterFactory.and([{
                    path: 'tipoComprobante.codigo',
                    equals: 'remision'
                }, {
                    path: "timbradoEmpresa.fechaDesde",
                    equalOrBefore: moment(vm.movimientoStock.fecha).format("DD/MM/YYYY")
                }, {
                    path: "timbradoEmpresa.fechaHasta",
                    equalOrAfter: moment(vm.movimientoStock.fecha).format("DD/MM/YYYY"),
                }, {
                    path: "puntoEmisionVenta.id",
                    equals: vm.movimientoStock.puntoEmision.id
                }, {
                    path: "timbradoEmpresa.activo",
                    equals: true
                }
                //, {
                 //   path: "timbradoEmpresa.claseTimbrado.codigo",
                 //   equals: "timbrado_manual"
               // }
            ]).value();


                vm.timbrados = TimbradoEmpresaPuntoEmisionVentaFactory.all(staticFilter2, "ConsultaComprobanteVentaForm");
            }
            function fillStringWithLeftCharacters(str, desiredLength, character) {
              var currentLength = str.length;
              var diff = desiredLength - currentLength;
              var finalString = str;
              if (diff > 0) {
                for (var i = 0; i < diff; i++) {
                  finalString = character + finalString;
                }
              }
              return finalString;
            }

          function obtenerSiguienteCorrelativo(str) {
            // Dividir el string en partes usando el guion como separador
            var partes = str.split('-');

            // Obtener la última parte que contiene el número
            var numeroActual = parseInt(partes[2], 10);

            // Incrementar el número
            var siguienteNumero = numeroActual + 1;

            // Formatear el número para que tenga la misma longitud que la parte original
            var longitudOriginal = partes[2].length;
            var siguienteNumeroFormateado = fillStringWithLeftCharacters(siguienteNumero.toString(), longitudOriginal, '0');

            // Construir el nuevo string con el número correlativo siguiente
            var nuevoString = partes[0] + '-' + partes[1] + '-' + siguienteNumeroFormateado;

            return nuevoString;
          }
        }
    ]);

'use strict';


angular.module('qualita.stock')
  .controller('MovimientoConsultaIndexCtrl',
    ["$scope", "MovimientoStockDetalleFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "AlmacenFactory", "ProductosFactory", "notify", "TiposFactory", "UtilFactory", "MovimientoStockFactory", "StockLangFactory", "ParametrosFactory", "$q", "WatchLocacionChangeFactory", "$filter", "CsvFactory", "baseurl", function ($scope, MovimientoStockDetalleFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal,
      $window, $state, AlmacenFactory, ProductosFactory, notify, TiposFactory, UtilFactory, MovimientoStockFactory,
      StockLangFactory, ParametrosFactory, $q, WatchLocacionChangeFactory, $filter, CsvFactory, baseurl) {

      var dateRender = function (data, type, full) {
        if (data) {
          return moment.utc(data).format('DD/MM/YYYY');
        } else {
          return "";
        }
      };

      var emptyDataRender = function (data) {
        if (data == undefined)
          return "";
        else
          return data;
      };
      WatchLocacionChangeFactory.killAllWatchers();
      $scope.curDate = new Date();
      $scope.saldoInicial = 0;
      $scope.producto = undefined;
      $scope.almacen = undefined;
      $scope.numeroGrupo = undefined;

      $scope.almacenes = AlmacenFactory.all();

      $scope.mensajeSinGrupo = false;
      $scope.mensajeEsBase = true;


      if ($scope.watcher) { $scope.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
        $scope.watcher = watcher;
        //$scope.productos = ProductosFactory.allForSelect(locacionFilter);
      }, "locaciones.id");
      $scope.grupos = undefined;
      $scope.estados = undefined;
      $scope.estado = undefined;

      StockLangFactory.getTranslations().then(function (translations) {

        TiposFactory.estadosMovimientoStock().then(function (data) {
          $scope.estados = data.data;
          $scope.estados.push({ 'id': 0, 'codigo': '', 'descripcion': 'Todo' });
          $scope.estado = $scope.estados[$scope.estados.length - 1];
        });

        $scope.unidad = undefined;
        $scope.multiplicador = 1;
        var unidadMedidaRenderCantidad = function (data) {
          if (data != undefined) {

            var digitosDesdePunto = data.toString().substr(data.toString().indexOf('.'));
            //console.log("digitos desde el punto decimal: ", digitosDesdePunto);

            if(data != 0 && digitosDesdePunto != 0){
                data = parseFloat(data).toFixed(4);
            }

            // El calculo para convertir entre unidades consiste en la division por $scope.multiplicador
            data = data / $scope.multiplicador;
            data = Math.round(data * 100)/100;

            var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
            var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);

            // Si tiene parte entera
            if(parteEntera != ''){
                if(digitosDesdePunto != 0){
                    return (
                        parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                        ","+parteDecimal.toString()
                    );
                }else{
                    parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            }else{
                return (
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            }

          } else {
              return "";
          }
        };

        var unidadMedidaRenderCosto = function (data) {
          if (data == undefined)
            return "";
          else
            var dataAux = parseFloat(data * $scope.multiplicador).toFixed(2);
          return (dataAux).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        };

        var importeRenderUnitario = function (data, type, row) {
          if (data!=null) {
              var moneda = "Gs. ";
              data = parseFloat(data * $scope.multiplicador).toFixed(0);
              // TODO hacer esto mismo en todos los renders de moneda
              return (
                      moneda +
                      data
                      .toString()
                      .replace(".", ",")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      );
          } else {
              return "";
          }
      };

      var importeRenderTotal = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            data = parseFloat(row.ultimoCosto * row.saldo).toFixed(0);
            // TODO hacer esto mismo en todos los renders de moneda
            return (
                    moneda +
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
        } else {
            return "";
        }
    };

        var saldoRender = function (data) {
          if (data != undefined) {

            var digitosDesdePunto = data.toString().substr(data.toString().indexOf('.'));
            //console.log("digitos desde el punto decimal: ", digitosDesdePunto);

            if(data != 0 && digitosDesdePunto != 0){
                data = parseFloat(data).toFixed(4);
            }

            // data = data * $scope.multiplicador;

            // El calculo para convertir entre unidades consiste en la division por $scope.multiplicador
            data = data / $scope.multiplicador;
            data = Math.round(data * 100)/100;

            var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
            var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);

            // Si tiene parte entera
            if(parteEntera != ''){
                if(digitosDesdePunto != 0){
                    return (
                        parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                        ","+parteDecimal.toString()
                    );
                }else{
                    parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            }else{
                return (
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            }

          } else {
              return "";
          }
        };

        var almacenFiltro = undefined;
        var productoFiltro = undefined;
        var grupoFiltro = undefined;

        $scope.delayRequestProducto = function(searchValue){
          var userInputFilter = {};
          var staticFilter = [];
          if (searchValue.length >= 3) {

                  var filterNombre = staticFilter.slice()
                  var filterCodigoBarras = staticFilter.slice()
                  var filterCodigoInterno = staticFilter.slice()

                  filterNombre.push({
                      path: "nombre",
                      like: searchValue
                  })

                  filterCodigoBarras.push({
                      path: "codigoBarrasPrincipal.codigoBarras",
                      like: searchValue
                  })

                  filterCodigoInterno.push( {
                      path: "codigo",
                      like: searchValue
                  })

              userInputFilter = {
                  search: filterFactory.or([
                      filterFactory.and(filterNombre).value(),
                      filterFactory.and(filterCodigoBarras).value(),
                      filterFactory.and(filterCodigoInterno).value()
                  ]).value()
              }

              $scope.recuperarProducto(userInputFilter)
          } else if (searchValue.length > 0 && searchValue.length < 3)  {

              var filterCodigoInterno = staticFilter.slice()
              filterCodigoInterno.push( {
                  path: "codigo",
                  like: searchValue
              })

               userInputFilter = {
                  search:  filterFactory.or([
                       filterFactory.and(filterCodigoInterno).value()
                    ]).value()
              }
              $scope.recuperarProducto(userInputFilter)
          } else {
              $scope.productos = undefined
          }
        }

        $scope.recuperarProducto = function (userInputFilter) {
           var finalFilter = UtilFactory.createNestedFilters(
                $scope.finalFilters, userInputFilter)

            $scope.productos = ProductosFactory.allForSelect(userInputFilter)
        };

        $scope.changeAlmacen = function (almacen) {
          almacenFiltro = almacen;
        };

        $scope.changeProducto = function (producto) {
          productoFiltro = producto;
          $scope.grupos = _.sortBy(producto.gruposProducto, 'id');
          if ($scope.grupos) {
            $scope.grupos.push({ 'id': 0, 'codigo': '', 'numero': 'Sin Grupo' });
            $scope.numeroGrupo = $scope.grupos[$scope.grupos.length - 1];
          } else {
            $scope.numeroGrupo = undefined;
          }
          $scope.unidad = producto.unidadMedidaBase;
          $scope.multiplicador = 1;
        };

        $scope.changeGrupo = function (numeroGrupo) {
          grupoFiltro = numeroGrupo;
        };

        $scope.changeUnidad = function (unidadMedida) {
          var actual = unidadMedida;
          $scope.multiplicador = 1;
          if (!actual.esBase) {
            $scope.multiplicador = $scope.multiplicador * actual.cantidad;
            actual = actual.unidadContenida;
          }
        };

        $scope.searchProductoFilter = function (criteria) {
          return function (item) {
            if (!criteria) {
              return true;
            }

            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
          };
        }

        $scope.cambiarFiltro = function () {

          // Actualizacion de banderas para "observaciones"

          if($scope.numeroGrupo){
            if($scope.numeroGrupo.numero == "Sin Grupo"){
              $scope.mensajeSinGrupo = true;
            }else{
              $scope.mensajeSinGrupo = false;
            }
          }

          if($scope.unidad && $scope.unidad.esBase){
            $scope.mensajeEsBase = true;
          }else{
            $scope.mensajeEsBase = false;
          }

          if ($scope.fechaDesde != undefined && $scope.fechaHasta != undefined && $scope.fechaDesde > $scope.fechaHasta) {
            notify({ message: $filter('translate')('FECHA_DESDE_MAYOR_FECHA_HASTA'), classes: 'alert-danger', position: 'right' });
          } else {
            if ($scope.almacen == undefined){
              notify({ message: $filter('translate')('FILTRO_ALMACEN_REQUERIDO'), classes: 'alert-danger', position: 'right' });
            } else if ($scope.producto == undefined) {
              notify({ message: $filter('translate')('FILTRO_PRODUCTO_REQUERIDO'), classes: 'alert-danger', position: 'right' });
            } else {
              if ($scope.fechaDesde == null) $scope.fechaDesde = undefined;
              if ($scope.fechaHasta == null) $scope.fechaHasta = undefined;

              if ($scope.producto.criterioControl.codigo === "normal") {
                console.log("1");
                $scope.filtro = true;
                var staticFilter = {};

                if ($scope.producto.claseProducto.tipoAdministracion.codigo != 'bobinas') {
                  console.log("2");

                  staticFilter.search = filterFactory.or([
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoOrigen.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'false'
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value(),
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoDestino.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'true',
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value()
                  ]).value();
                } else {
                  console.log("3");

                  staticFilter.search = filterFactory.or([
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoOrigen.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'false'
                      },
                      {
                        path: 'grupo.numero',
                        like: $scope.numeroGrupo.numero
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value(),
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoDestino.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'true',
                      },
                      {
                        path: 'grupo.numero',
                        like: $scope.numeroGrupo.numero
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value()
                  ]).value();
                }
                $scope.options.staticFilter = staticFilter;
              } else {
                console.log("4");

                if ($scope.numeroGrupo == undefined) {
                  console.log("5");

                  notify({ message: $filter('translate')('FILTRO_GRUPO_REQUERIDO'), classes: 'alert-danger', position: 'right' });
                } else {
                  console.log("6");

                  $scope.filtro = true;
                  var staticFilter = {};

                  console.log($scope.numeroGrupo);

                  staticFilter.search = filterFactory.or([
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoOrigen.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'grupo.numero',
                        equals: $scope.numeroGrupo.numero
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'false'
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value(),
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoDestino.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'grupo.numero',
                        equals: $scope.numeroGrupo.numero
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'true'
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value()

                  ]).value();
                  $scope.options.staticFilter = staticFilter;
                }
              }
              if ($scope.fechaDesde != undefined) {
                if ($scope.producto.criterioControl.codigo === "normal" || $scope.numeroGrupo.numero === 'Sin Grupo') {
                  MovimientoStockDetalleFactory.getSaldoInicial($scope.producto.id, $scope.almacen.id, moment($scope.fechaDesde).format('DD/MM/YYYY')).then(function (resp) {
                    $scope.saldoInicial = resp.data;
                    if ($scope.saldoInicial === undefined) {
                      $scope.saldoInicial = 0;
                    }
                  });
                } else {
                  MovimientoStockDetalleFactory.getSaldoInicialGrupo($scope.producto.id, $scope.almacen.id, moment($scope.fechaDesde).format('DD/MM/YYYY'), $scope.numeroGrupo.id).then(function (resp) {
                    $scope.saldoInicial = resp.data;
                    if ($scope.saldoInicial === undefined) {
                      $scope.saldoInicial = 0;
                    }
                  });
                }
              } else {
                $scope.saldoInicial = 0;
              }
            }
          }
        };

        // TODO segun parametros, incluir o no columnas de produccion y movimiento de materiales
        var promise1 = ParametrosFactory.getByCodigo('incluir_nro_orden_produccion');
        var promise2 = ParametrosFactory.getByCodigo('incluir_nro_mov_materiales');
        var promises = [promise1, promise2];

        $q.all(promises).then(function (responses) {
          console.log(responses[0]);
          console.log(responses[1]);
          var defaultColumnOrder = ['id', 'producto.nombre', 'grupo.numero', 'movimientoStock.fecha', 'movimientoStock.depositoOrigen.descripcion', 'movimientoStock.depositoDestino.descripcion', 'descripcion', 'tipoMovimiento.descripcion',
            'movimientoStock.estado.descripcion', 'ingreso', 'egreso', 'saldo', 'ultimoCosto', 'costoPromedio', 'ultimoCostoIVA', 'costoPromedioIVA'
          ];
          if (responses[0].valorTipo.codigo == 'si') {
            defaultColumnOrder.push('nroOrdenProduccion');
            defaultColumnOrder.push('nroApunteCorte');
          }
          if (responses[1].valorTipo.codigo == 'si') {
            defaultColumnOrder.push('nroMovMateriales');
          }
          $scope.options = {
            //'staticFilter': staticFilter,
            'resource': 'movimientostockdetalle',
            'title': 'Movimientos de Stock Valorizado',
            'view': 'ConsultaMovimientoStockList',
            'factory': MovimientoStockDetalleFactory,
            'defaultColumnOrder': defaultColumnOrder,
            'columns': [
              { 'data': 'id', 'title': $filter('translate')('CODE'), 'searchable': false, 'orderable': false  },
              { 'data': 'producto.nombre', 'title': $filter('translate')('PRODUCT'), 'searchable': false, 'orderable': false },
              { 'data': 'grupo.numero', 'title': 'Grupo', 'render': emptyDataRender, 'class': 'dt-right', 'searchable': false, 'orderable': false  },
              { 'data': 'movimientoStock.fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'searchable': false, 'orderable': false, 'type': 'date-range' },
              { 'data': 'movimientoStock.depositoOrigen.descripcion', 'title': $filter('translate')('ALMACEN_ORIGEN'), 'class': 'dt-right', 'searchable': false, 'orderable': false, 'render': emptyDataRender, visible:false },
              { 'data': 'movimientoStock.depositoDestino.descripcion', 'title': $filter('translate')('ALMACEN_DESTINO'), 'class': 'dt-right', 'searchable': false, 'orderable': false, 'render': emptyDataRender, visible: false },
              { 'data': 'movimientoStock.numero', 'title': $filter('translate')('MOV_STOCK_NUMBER')  },
              { 'data': 'descripcion', 'title': $filter('translate')('NRO_COMPROBANTE'), 'searchable': false, 'class': 'dt-left', 'orderable': false },
              //{'data': 'movimientoStock.tipoReferencia.descripcion', 'title': 'Referencia'},
              { 'data': 'tipoMovimiento.descripcion', 'title': $filter('translate')('TIPO_MOVIMIENTO'), 'searchable': false, 'orderable': false },
              { 'data': 'movimientoStock.estado.descripcion', 'title': $filter('translate')('STATUS'), 'class': 'dt-center', 'searchable': false, 'orderable': false },
              { 'data': 'ingreso', 'title': $filter('translate')('ENTRADA'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'class': 'dt-right', 'searchable': false, 'orderable': false  },
              { 'data': 'egreso', 'title': $filter('translate')('SALIDA'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'class': 'dt-right', 'searchable': false, 'orderable': false  },
              { 'data': 'saldo', 'title': $filter('translate')('SALDO'), 'render': saldoRender, 'class': 'dt-right', 'searchable': false, 'class': 'dt-right', 'orderable': false  },
              { 'data': 'ultimoCosto', 'title': $filter('translate')('ULTIMO_COSTO_UNITARIO'), 'render': importeRenderUnitario, 'class': 'dt-right', 'class': 'dt-right', 'searchable': false, 'orderable': false  },
              { 'data': 'costoPromedio', 'title': $filter('translate')('COSTO_PROMEDIO'), 'render': importeRenderUnitario, 'class': 'dt-right', 'class': 'dt-right', 'searchable': false, 'orderable': false  },
              { 'data': 'ultimoCostoIVA', 'title': $filter('translate')('ULTIMO_COSTO_IVA'), 'render': importeRenderUnitario, 'class': 'dt-right', 'class': 'dt-right', visible: true, 'searchable': false, 'orderable': false  },
              { 'data': 'costoPromedioIVA', 'title': $filter('translate')('COSTO_PROMEDIO_IVA'), 'render': importeRenderUnitario, 'class': 'dt-right', 'class': 'dt-right', visible: true, 'searchable': false, 'orderable': false  },
              { 'data': 'nroOrdenProduccion', 'title': $filter('translate')('NRO_ORDEN_PRODUCCION'), 'class': 'dt-right', 'searchable': false, 'orderable': false, visible: false  },
              { 'data': 'nroMovMateriales', 'title': $filter('translate')('NRO_MOV_MATERIALES'), 'class': 'dt-right', 'searchable': false, 'orderable': false, visible: false },
              { 'data': 'nroApunteCorte', 'title': $filter('translate')('NRO_APUNTE_CORTE'), 'class': 'dt-right', 'searchable': false, 'orderable': false, visible: false }

            ],
            'hasOptions': false,
            'hideEditMenu': true,
            'hideRemoveMenu': true,
            'defaultOrderColumn': 3,
            'defaultOrderDir': "asc",
            'extraMenuOptions':
              [
                {
                  'title': 'Reporte',
                  'icon': 'glyphicon glyphicon-file',
                  'showCondition': function () { return true; },
                  'action': function () {
                    if($scope.producto.criterioControl.codigo !== "normal" && $scope.numeroGrupo.numero === 'Sin Grupo'){
                      console.log("aca");
                      var staticFilterSinGrupo = {};

                    staticFilterSinGrupo.search = filterFactory.or([
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoOrigen.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'grupo.id',
                        isNull: "true"
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: false
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value(),
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoDestino.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'grupo.id',
                        isNull: "true"
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: true
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value()

                  ]).value();
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), staticFilterSinGrupo);

                    }else{
                      var staticFilterReport = {};
                      staticFilterReport.search = filterFactory.or([
                        filterFactory.and([
                          {
                            path: 'movimientoStock.depositoOrigen.id',
                            equals: $scope.almacen.id
                          },
                          {
                            path: 'producto.id',
                            equals: $scope.producto.id,
                          },
                          {
                            path: 'movimientoStock.fecha',
                            equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                          }, {
                            path: 'movimientoStock.fecha',
                            equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                          },
                          {
                            path: 'movimientoStock.estado.codigo',
                            like: $scope.estado.codigo
                          },
                          {
                            path: 'movimientoStock.contrapartida',
                            equals: false
                          },
                          {
                            path: 'id',
                            sortAsc: 'true'
                          }
                        ]).value(),
                        filterFactory.and([
                          {
                            path: 'movimientoStock.depositoDestino.id',
                            equals: $scope.almacen.id
                          },
                          {
                            path: 'producto.id',
                            equals: $scope.producto.id,
                          },
                          {
                            path: 'movimientoStock.fecha',
                            equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                          }, {
                            path: 'movimientoStock.fecha',
                            equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                          },
                          {
                            path: 'movimientoStock.estado.codigo',
                            like: $scope.estado.codigo
                          },
                          {
                            path: 'movimientoStock.contrapartida',
                            equals: true,
                          },
                          {
                            path: 'id',
                            sortAsc: 'true'
                          }
                        ]).value()
                      ]).value();
                      var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), staticFilterReport);
                    }
                    ReportTicketFactory.ticket('consultaMovimientoStock', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                      $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                      $window.open($scope.pdfDownloadURL, '_blank');
                    });
                  }
                },
                {
                  'title': 'CSV',
                  'icon': 'glyphicon glyphicon-export',
                  'showCondition': function () { return true; },
                  'action': function () {
                    var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                    CsvFactory.csv("movimientostockdetalle", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                      .then(function (response) {
                        console.log("response de csv: ", response);
                        var fileName = response.data.fileName;
                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                      });
                  }
                }
              ]
          };

        });
      });
      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            search = search.split("~");
            if (search.length > 1) {
                /* El parámetro es un rago */

                var isDate = search[0].split("/");

                if (isDate.length > 1) {
                    /* Es un rago de fechas */

                    filtersArr.push({path: data, equalOrAfter: search[0]})
                    filtersArr.push({path: data, equalOrBefore: search[1]})
                } else {
                    /* El un rago numérco */

                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                    filtersArr.push({path: data, lessOrEqual: search[1]})
                }
            } else {
                /* El parametro no es un rago */
                filtersArr.push({path: data, like: search[0]})
            }
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }
    }]);

'use strict';


angular.module('qualita.stock')
  .controller('MovimientoConsulta2IndexCtrl',
    ["$scope", "MovimientoStockDetalleFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "AlmacenFactory", "ProductosFactory", "notify", "TiposFactory", "UtilFactory", "MovimientoStockFactory", "StockLangFactory", "ParametrosFactory", "$q", "baseurl", "$filter", "CsvFactory", function ($scope, MovimientoStockDetalleFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal,
      $window, $state, AlmacenFactory, ProductosFactory, notify, TiposFactory, UtilFactory, MovimientoStockFactory,
      StockLangFactory, ParametrosFactory, $q, baseurl, $filter, CsvFactory) {

      var linkRender = function (data, type, full) {
        console.log("window.location.host ", window.location.host);
        console.log("window.location.host2 ", window.location.host + "/" + baseurl.getServerName());

        if (window.location.hostname === 'localhost' || window.location.hostname === '0.0.0.0')
          return '<a href="http://' + window.location.host + '/#/movimientostock/' + data + '">' + data + '</a>';
        else
          return '<a href="https://' + window.location.host + '/#/movimientostock/' + data + '">' + data + '</a>';
      };

      StockLangFactory.getTranslations().then(function (translations) {
        var defaultColumnOrder = ['movimientoStock.id', 'producto.nombre', 'grupo.numero', 'movimientoStock.fecha', 'descripcion', 'tipoMovimiento.descripcion',
          'movimientoStock.estado.descripcion', 'movimientoStock.depositoOrigen.descripcion', 'movimientoStock.depositoDestino.descripcion',
          'nroOrdenProduccion', 'nroMovMateriales'];
        $scope.options = {
          //'staticFilter': staticFilter,
          'resource': 'movimientostockdetalle',
          'view': 'ConsultaMovimientoStockList',
          'title': $filter('translate')('CONSULTA_MOV'),
          'factory': MovimientoStockDetalleFactory,
          'defaultColumnOrder': defaultColumnOrder,
          'columns': [
            { 'data': 'movimientoStock.id', 'title': $filter('translate')('CODE_MOVIMIENTO'), 'render': linkRender  },
            { 'data': 'movimientoStock.fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
            { 'data': 'producto.nombre', 'title': $filter('translate')('PRODUCT') },
            { 'data': 'grupo.numero', 'title': $filter('translate')('PRODUCT_GROUP'), 'renderWith': 'emptyRender', 'class': 'dt-right'  },
            { 'data': 'movimientoStock.depositoOrigen.descripcion', 'title': $filter('translate')('ALMACEN_ORIGEN'), 'renderWith': 'emptyRender' },
            { 'data': 'movimientoStock.depositoDestino.descripcion', 'title': $filter('translate')('ALMACEN_DESTINO'), 'renderWith': 'emptyRender' },
            { 'data': 'movimientoStock.estado.descripcion', 'title': $filter('translate')('STATUS'), 'class': 'dt-center' },
            { 'data': 'nroOrdenProduccion', 'title': $filter('translate')('NRO_ORDEN_PRODUCCION'), 'class': 'dt-right'  },
            { 'data': 'nroMovMateriales', 'title': $filter('translate')('NRO_MOV_MATERIALES'), 'class': 'dt-right'  },
            { 'data': 'nroApunteCorte', 'title': $filter('translate')('NRO_APUNTE_CORTE'), 'class': 'dt-right'  }
          ],
          'hasOptions': true,
          'hideEditMenu': true,
          'hideRemoveMenu': true,
          'hideViewMenu': true,
          'defaultOrderColumn': 3,
          'defaultOrderDir': "asc",
          'extraMenuOptions':
            [
              {
                'title': 'Reporte',
                'icon': 'glyphicon glyphicon-file',
                'showCondition': function () { return true; },
                'action': function () {
                  var filters = createFilters($scope.options.getFilters());
                  ReportTicketFactory.ticket('listadoMovimientoOPMM', filters, $scope.options.tableAjaxParams,
                    $scope.options.currentColumnOrder).then(function (genTicket) {
                      $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                      $window.open($scope.pdfDownloadURL, '_blank');
                    });
                }
              },
              {
                'title': 'CSV',
                'icon': 'glyphicon glyphicon-export',
                'showCondition': function () { return true; },
                'action': function () {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("movimientostockdetalle", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                    .then(function (response) {
                      console.log("response de csv: ", response);
                      var fileName = response.data.fileName;
                      $window.open(baseurl.getPublicBaseUrl() + fileName);
                    });
                }
              }
            ],
            'extraRowOptions': [{
              templateToRender: "<button title='" +
                "Reporte Fraccionamiento" +
                "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='imprimir(<%=dataCustom%>)' ng-class='{ hidden : !canImprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-print'></span> </button>",
              functionName: "imprimir",
              conditionName: "canImprimir",
              customAttribute: ["movimientoStock", "producto", "grupo"],
              conditionDef: function (atributos) {
                return atributos[0].estado.codigo == 'ms_confirmado' && atributos[1].claseProducto.tipoAdministracion.codigo === 'bobinas';
              },
              functionDef: function (atributos) {
                var movimientoStock = atributos[0];
                var producto = atributos[1];
                var grupo = atributos[2];
    
                $scope.mensajeModal = "Desea imprimir el Reporte de Fraccionamiento?";
                $scope.tituloModal = "Fraccionamiento";
                var modalInstance = $modal.open({
                  templateUrl: "views/directives/confirmation-modal.html",
                  scope: $scope
                });
    
                $scope.cancel = function () {
                  modalInstance.dismiss("cancel");
                };
    
                $scope.ok = function (id) {
                  var filtersArr = [];
                  filtersArr.push("almacen=" + movimientoStock.depositoOrigen.id);
                  filtersArr.push("producto=" + producto.id);
                  var fechaCorrecta = moment(movimientoStock.fecha, "DD/MM/YYYY HH:mm").toDate();  // Interpretando como dd/MM/yyyy
                  var myDateD = $filter('date')(fechaCorrecta, "MM/dd/yyyy");
                  filtersArr.push("fechaDesde=" + myDateD);
                  var myDateH = $filter('date')(fechaCorrecta, "MM/dd/yyyy");
                  filtersArr.push("fechaHasta=" + myDateH);
                  filtersArr.push("nroRecepcion=" + parseInt(grupo.numero.split('-')[0], 10));
    
    
                  modalInstance.close();
                  $state.go($state.current, {}, { reload: true });
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                    "consultaMovimientosBobinas",
                    "pdf",
                    filtersArr
                  );
                  $window.open($scope.pdfDownloadURL, "_blank");
                  $state.go($state.current, {}, { reload: true });
                }
              }
            }
            ]
        };
      });
     
      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            search = search.split("~");
            if (search.length > 1) {
                /* El parámetro es un rago */

                var isDate = search[0].split("/");

                if (isDate.length > 1) {
                    /* Es un rago de fechas */

                    filtersArr.push({path: data, equalOrAfter: search[0]})
                    filtersArr.push({path: data, equalOrBefore: search[1]})
                } else {
                    /* El un rago numérco */

                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                    filtersArr.push({path: data, lessOrEqual: search[1]})
                }
            } else {
                /* El parametro no es un rago */
                filtersArr.push({path: data, like: search[0]})
            }
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }
    }]);

'use strict';


angular.module('qualita.stock')
    .controller('ProductoConsultaIndexCtrl',
        ["$scope", "ProductoAlmacenFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "AlmacenFactory", "ProductosFactory", "notify", "GrupoProductoAlmacenFactory", "UtilFactory", "MovimientoStockFactory", "StockLangFactory", "WatchLocacionChangeFactory", "$filter", "CsvFactory", "baseurl", "LineasProductoFactory", "TiposTipoFactory", "MarcasFactory", "SucursalesFactory", function ($scope, ProductoAlmacenFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal,
            $window, $state, AlmacenFactory, ProductosFactory, notify, GrupoProductoAlmacenFactory, UtilFactory,
            MovimientoStockFactory, StockLangFactory, WatchLocacionChangeFactory, $filter, CsvFactory, baseurl,
            LineasProductoFactory, TiposTipoFactory, MarcasFactory, SucursalesFactory) {

          var vm = this;
          vm.uiBlockuiConfig = {
            'bloquear': false,
            'message': 'Descargando Csv...'
          };
            var dateRender = function (data, type, full) {
                if (data) {
                    return moment.utc(data).format('DD/MM/YYYY');
                } else {
                    return "";
                }
            };

            var emptyDataRender = function (data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            //Banderas para deshabilitar filtro producto
            $scope.disableFamilyFilter = false;
            $scope.disableProductFilter = false;

            $scope.mensajeEsBase = true;

            $scope.curDate = new Date();
            $scope.producto = undefined;
            $scope.almacen = undefined;
            $scope.unidad = undefined;
            $scope.fecha = undefined;
            $scope.clasesProducto = TiposTipoFactory.get("clase_linea_producto");
            getAlmacenes();

            getMarcas();

            getSucursales();
            WatchLocacionChangeFactory.killAllWatchers();
            if ($scope.watcher) { $scope.watcher(); }
            WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
                $scope.watcher = watcher;
                //$scope.productos = ProductosFactory.allForSelect(locacionFilter);
            }, "locaciones.id");
            $scope.unidades = undefined;
            $scope.multiplicador = 1;

            var almacenFiltro = undefined;
            var productoFiltro = undefined;
            var unidadMedidaFiltro = undefined;

            var params = {};
            params.search = filterFactory.and([{
                path: 'clase.codigo',
                equals: 'producto'
            },
            {
                path: 'codigo',
                sortAsc: true
            }
            ]);
            LineasProductoFactory.getAllTree(params).$promise.then(function (response) {
                $scope.familias = response;
            });

            $scope.cleanFilters = function () {

                $scope.almacen = $scope.almacenes[$scope.almacenes.length - 1];

                $scope.familia = undefined;
                $scope.producto = undefined;
                $scope.unidad = undefined;
                $scope.fecha = undefined;
                $scope.clase = undefined;
                $scope.marca = $scope.marcas[$scope.marcas.length - 1];
                $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];

                $scope.disableFamilyFilter = false;
                $scope.disableProductFilter = false;
            };

            function getAlmacenes() {
                var params = {};
                var filtros = [];
                var filtro = {
                    path: "activo",
                    equals: true
                }
                filtros.push(filtro);

                params.search = filterFactory.and(filtros).value();
                params.view = "BaseList";

                AlmacenFactory.all(params).$promise.then(function (response) {
                    $scope.almacenes = response;
                    $scope.almacenes.push({ 'id': '', 'codigo': '', 'descripcion': 'Todos' });
                    $scope.almacen = $scope.almacenes[$scope.almacenes.length - 1];
                });
            }

            function getMarcas(){

              var params = {};
              /* var filtros = [];
               if(filtro) {
                 filtros.push(filtro);
               }*/

              // params.search = filterFactory.and(filtros).value();
              params.view = "BaseList";

              MarcasFactory.all(params).$promise.then(function (response) {
                $scope.marcas = response;
                $scope.marcas.push({ 'id': '', 'codigo': '', 'descripcion': 'Todos' });
                $scope.marca = $scope.marcas[$scope.marcas.length - 1];
              });
            }

            function getSucursales(){

              var params = {};

              params.view = "BaseList";

              SucursalesFactory.all(params).$promise.then(function (response) {
                $scope.listaSucursales = response;
                $scope.listaSucursales.push({ 'id': '', 'codigo': '', 'nombre': 'Todos' });
                $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];
              });
            }

            $scope.changeClase = function () {
                $scope.almacen = null;
                $scope.producto = null;

                // Volver a filtrar almacenes
                $scope.almacenes = getAlmacenes();

                // Volver a filtrar lineas
                var params = {};
                if ($scope.clase.codigo === "activo_fijo") {
                    params.search = filterFactory.and([{
                        path: 'clase.codigo',
                        equals: 'activo_fijo'
                    }]).value();
                } else {
                    params.search = filterFactory.and([{
                        path: 'clase.codigo',
                        notEquals: 'activo_fijo'
                    }]).value();
                }

                LineasProductoFactory.getAllTree(params).$promise.then(function (response) {
                    $scope.familias = response;
                });
                // Volver a filtrar productos
                $scope.delayRequestProducto = function (searchValue) {
                    var userInputFilter = {};
                    var staticFilter = [];
                    if (searchValue.length >= 3) {
                        var filterNombre = staticFilter.slice()
                        var filterCodigoBarras = staticFilter.slice()
                        var filterCodigoInterno = staticFilter.slice()

                        filterNombre.push({
                            path: "nombre",
                            like: searchValue
                        })

                        filterCodigoBarras.push({
                            path: "codigoBarrasPrincipal.codigoBarras",
                            like: searchValue
                        })

                        filterCodigoInterno.push({
                            path: "codigo",
                            like: searchValue
                        })

                        //Si existe filtro de clase
                        if ($scope.clase) {
                            var filterClaseSelected = {};

                            if ($scope.clase.codigo === "activo_fijo") {
                                filterClaseSelected = {
                                    path: "claseProducto.tipoProducto.nombre",
                                    equals: "Activo Fijo"
                                }
                            } else {
                                filterClaseSelected = {
                                    path: "claseProducto.tipoProducto.nombre",
                                    notEquals: "Activo Fijo"
                                }
                            }

                            // Se asigna a los filtros independientes OR, el filtro de clase
                            filterNombre.push(filterClaseSelected);
                            filterCodigoBarras.push(filterClaseSelected);
                            filterCodigoInterno.push(filterClaseSelected);

                        }

                        //Si existe filtro de Almacen
                        if ($scope.almacen) {
                            var filterAlmacenSelected = {};

                            filterAlmacenSelected = {
                                path: "almacenes.almacen.id",
                                equals: $scope.almacen.id
                            }

                            // Se asigna a los filtros independientes OR, el filtro de almacen
                            filterNombre.push(filterAlmacenSelected);
                            filterCodigoBarras.push(filterAlmacenSelected);
                            filterCodigoInterno.push(filterAlmacenSelected);

                        }


                        //Si existe filtro de Linea producto
                        if ($scope.linea) {
                            var filterLineaProductoSelected = {};

                            filterLineaProductoSelected = {
                                path: "lineaProducto.id",
                                equals: $scope.linea.id
                            }

                            // Se asigna a los filtros independientes OR, el filtro de linea
                            filterNombre.push(filterLineaProductoSelected);
                            filterCodigoBarras.push(filterLineaProductoSelected);
                            filterCodigoInterno.push(filterLineaProductoSelected);

                        }


                        userInputFilter = {
                            search: filterFactory.or([
                                filterFactory.and(filterNombre).value(),
                                filterFactory.and(filterCodigoBarras).value(),
                                filterFactory.and(filterCodigoInterno).value()

                            ]).value()
                        }

                        $scope.recuperarProducto(userInputFilter)

                    } else if (searchValue.length > 0 && searchValue.length < 3) {

                        var filterCodigoInterno = staticFilter.slice()
                        filterCodigoInterno.push({
                            path: "codigo",
                            like: searchValue
                        })

                        userInputFilter = {
                            search: filterFactory.or([
                                filterFactory.and(filterCodigoInterno).value()
                            ]).value()
                        }
                        $scope.recuperarProducto(userInputFilter)

                    } else {
                        $scope.productos = undefined;
                    }
                }
            }

            $scope.changeFamilia = function () {

                // Limpia filtro producto y lo deshabilita
                $scope.producto = undefined;
                $scope.disableProductFilter = true;
            };

            $scope.delayRequestProducto = function (searchValue) {
                var userInputFilter = {};
                var staticFilter = [];
                if (searchValue.length >= 3) {



                    var filterNombre = staticFilter.slice()
                    var filterCodigoBarras = staticFilter.slice()
                    var filterCodigoInterno = staticFilter.slice()

                    filterNombre.push({
                        path: "nombre",
                        like: searchValue
                    })

                    filterCodigoBarras.push({
                        path: "codigoBarrasPrincipal.codigoBarras",
                        like: searchValue
                    })

                    filterCodigoInterno.push({
                        path: "codigo",
                        like: searchValue
                    })

                    userInputFilter = {
                        search: filterFactory.or([
                            filterFactory.and(filterNombre).value(),
                            filterFactory.and(filterCodigoBarras).value(),
                            filterFactory.and(filterCodigoInterno).value()
                        ]).value()
                    }

                    $scope.recuperarProducto(userInputFilter)
                } else if (searchValue.length > 0 && searchValue.length < 3) {

                    var filterCodigoInterno = staticFilter.slice()
                    filterCodigoInterno.push({
                        path: "codigo",
                        like: searchValue
                    })

                    userInputFilter = {
                        search: filterFactory.or([
                            filterFactory.and(filterCodigoInterno).value()
                        ]).value()
                    }
                    $scope.recuperarProducto(userInputFilter)
                } else {
                    $scope.productos = undefined
                }
            }

            $scope.recuperarProducto = function (userInputFilter) {
                var finalFilter = UtilFactory.createNestedFilters(
                    $scope.finalFilters, userInputFilter)

                $scope.productos = ProductosFactory.allForSelect(userInputFilter)
            };

            $scope.changeAlmacen = function (almacen) {
                almacenFiltro = almacen;
            };

            $scope.changeProducto = function (producto) {
                productoFiltro = producto;
                $scope.unidades = producto.unidadesMedida;
                $scope.unidad = producto.unidadMedidaBase;
                $scope.multiplicador = 1;
                $scope.changeUnidad($scope.unidad);

                // Limpia filtro familia y lo deshabilita
                $scope.familia = undefined;
                $scope.disableFamilyFilter = true;

            };

            $scope.changeUnidad = function (unidadMedida) {
                var actual = unidadMedida;
                $scope.multiplicador = 1;
                if (!actual.esBase) {
                    $scope.multiplicador = $scope.multiplicador * actual.cantidad;
                    actual = actual.unidadContenida;
                }
            };

            var emptyRender = function (data) {
              if (data) {
                return data;
              } else {
                return '';
              }
            };

            /* unidadMedidaRenderCantidad = unidadMedidaRenderCosto */
            var unidadMedidaRenderCantidad = function (data) {
                if (data != undefined) {

                    var digitosDesdePunto = data.toString().substr(data.toString().indexOf('.'));
                    //console.log("digitos desde el punto decimal: ", digitosDesdePunto);

                    if (data != 0 && digitosDesdePunto != 0) {
                        data = parseFloat(data).toFixed(4);
                    }

                    // El calculo para convertir entre unidades consiste en la division por $scope.multiplicador
                    data = data / $scope.multiplicador;
                    data = Math.round(data * 100) / 100;

                    var parteEntera = data.toString().substr(0, data.toString().indexOf('.'));
                    var parteDecimal = data.toString().substr(data.toString().indexOf('.') + 1);

                    // Si tiene parte entera
                    if (parteEntera != '') {
                        if (digitosDesdePunto != 0) {
                            return (
                                parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
                                "," + parteDecimal.toString()
                            );
                        } else {
                            parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    } else {
                        return (
                            data
                                .toString()
                                .replace(".", ",")
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        );
                    }

                } else {
                    return "";
                }
            };


            /* unidadMedidaRenderCantidad = unidadMedidaRenderCosto */
            var unidadMedidaRenderCosto = function (data, type, row) {
                if (data) {
                    var moneda = "Gs. ";
                    data = parseFloat(data).toFixed(0);
                    return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return '';
                }
            };

            $scope.searchProductoFilter = function (criteria) {
                return function (item) {
                    if (!criteria) {
                        return true;
                    }
                    return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
                };
            }

            $scope.cambiarFiltro = function () {
                $scope.filtro = true;

                // Actualizacion de banderas para "observaciones"
                if ($scope.unidad != undefined) {
                    if ($scope.unidad.esBase) {
                        $scope.mensajeEsBase = true;
                    } else {
                        $scope.mensajeEsBase = false;
                    }
                } else {
                    // Si no eligio unidad de medida, usar unidad base
                    $scope.mensajeEsBase = true;
                }

                var staticFilter = [];

                // El filtro almacen siempre tiene un valor definido (al cargar la pagina es 'Todos')
                staticFilter.push(
                    {
                        path: 'almacen.id',
                        equals: $scope.almacen.id
                    },
                    {
                        path: 'almacen.descripcion',
                        sortAsc: 'true'
                    }
                )

                // Si tiene producto definido, se agrega el filtro
                if ($scope.producto != undefined) {
                    staticFilter.push(
                        {
                            path: 'producto.id',
                            equals: $scope.producto.id
                        },
                        {
                            path: 'producto.nombre',
                            sortAsc: 'true'
                        }
                    )
                }

                // Si tiene fecha definida, se agrega el filtro
                if ($scope.fecha != undefined) {

                    var fechaFormateada = moment($scope.fecha).format('DD/MM/YYYY');
                    staticFilter.push(
                        {
                            path: 'dia',
                            equalOrBefore: fechaFormateada
                        }
                    )
                }

                // Si tiene filtro familia, se agrega el filtro
                if ($scope.familia != undefined) {
                    staticFilter.push(
                        {
                            path: 'producto.lineaProducto.id',
                            equals: $scope.familia.id
                        }
                    )
                }

                //Si existe filtro de clase
                if ($scope.clase) {
                    var filterClaseSelected = {};

                    if ($scope.clase.codigo === "activo_fijo") {
                        filterClaseSelected = {
                            path: "producto.claseProducto.tipoProducto.nombre",
                            equals: "Activo Fijo"
                        }
                    } else {
                        filterClaseSelected = {
                            path: "producto.claseProducto.tipoProducto.nombre",
                            notEquals: "Activo Fijo"
                        }
                    }
                    staticFilter.push(filterClaseSelected);
                }

                if($scope.marca && $scope.marca.id){
                  var filter = {};

                  filter = {
                    path: "producto.marca.id",
                    equals: $scope.marca.id
                  }

                  staticFilter.push(filter);
                }

                if($scope.sucursal && $scope.sucursal.id){
                  var filter = {};

                  filter = {
                    path: "almacen.sucursal.id",
                    equals: $scope.sucursal.id
                  }

                  staticFilter.push(filter);
                }

                // Se define el filtro final
                var filtros = {
                    search: filterFactory.and(staticFilter).value()
                };

                $scope.options.staticFilter = filtros;
            };

            var renderCosto = function (data) {
                if (data == undefined)
                    return "";
                else
                    var moneda = "Gs. ";
                var dataAux = parseFloat(data * $scope.multiplicador).toFixed(2);
                return moneda + (dataAux).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            };

            var defaultColumnOrder = ['producto.codigo', 'producto.nombre', 'almacen.descripcion', 'saldoReal', 'saldoTransito', 'saldoTotal', 'ultimoCosto', 'costoPromedio', 'ultimoCostoIVA',
                'costoPromedioIVA', 'producto.lineaProducto.nombre', 'producto.unidadMedidaBase.abreviatura', 'producto.marca.descripcion'];
            StockLangFactory.getTranslations().then(function (translations) {
                $scope.options = {
                    'resource': 'productoalmacen/newdatatableexistencia',
                    'title': 'Existencia de Productos',
                    'view': 'ProductoAlmacenDatatableList',
                    'factory': ProductoAlmacenFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'columns': [
                        { 'data': 'producto.claseProducto.descripcion', 'title': $filter('translate')('CLASE_PRODUCTO'), 'searchable': false },
                        { 'data': 'producto.lineaProducto.nombre', 'title': $filter('translate')('PRODUCT_LINE'), visible: false, 'searchable': false },
                        { 'data': 'producto.codigo', 'title': $filter('translate')('CODE_PRODUCTO'), 'searchable': false, 'orderable': false },
                        { 'data': 'producto.codigoBarrasPrincipal.codigoBarras', 'title': $filter('translate')('CODIGO_BARRA_PRODUCTO'), 'searchable': false, 'orderable': false, 'class': 'dt-right', 'render':emptyRender},
                        { 'data': 'almacen.descripcion', 'title': $filter('translate')('ALMACEN'), 'searchable': false },
                        { 'data': 'producto.nombre', 'title': $filter('translate')('PRODUCT'), 'searchable': false },
                        { 'data': 'producto.unidadMedidaBase.abreviatura', 'title': $filter('translate')('UNIDAD_MEDIDA_ABREVIADO'), 'searchable': false, 'orderable': false, visible: false },
                        { 'data': 'producto.marca.descripcion', 'render': emptyDataRender, 'title': $filter('translate')('MARCA'), 'searchable': false, 'orderable': false, visible: false },
                        { 'data': 'saldoReal', 'title': $filter('translate')('EXISTENCIA'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'searchable': false, 'orderable': false },
                        { 'data': 'saldoTransito', 'title': $filter('translate')('EXISTENCIA_TRANSITO'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'searchable': false, 'orderable': false },
                        { 'data': 'saldoTotal', 'title': $filter('translate')('EXISTENCIA_TOTAL'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'searchable': false, 'orderable': false },
                        { 'data': 'ultimoCosto', 'title': $filter('translate')('ULTIMO_COSTO'), 'class': 'dt-right', 'renderWith': renderCosto, 'searchable': false, 'orderable': false },
                        { 'data': 'costoPromedio', 'title': $filter('translate')('COSTO_PROMEDIO'), 'class': 'dt-right', 'renderWith': renderCosto, 'searchable': false, 'orderable': false },
                        { 'data': 'ultimoCostoIVA', 'title': $filter('translate')('ULTIMO_COSTO_IVA'), 'class': 'dt-right', 'renderWith': renderCosto, 'searchable': false, 'orderable': false },
                        { 'data': 'costoPromedioIVA', 'title': $filter('translate')('COSTO_PROMEDIO_IVA'), 'class': 'dt-right', 'renderWith': renderCosto, 'searchable': false, 'orderable': false },
                    ],
                    'hasOptions': true,
                    'hideEditMenu': false,
                    'hideRemoveMenu': false,
                    'hideViewMenu': true,
                    'defaultOrderColumn': 4,
                    'defaultOrderDir': "asc",
                    'extraRowOptions': [{
                        templateToRender: "<button title='Nros. de Grupo' class='btn btn-info btn-dt' style='margin-right: 5px;' ng-class='{ hidden : !verModal(<%=dataCustom%>)}' ng-click='view(<%=dataCustom%>)'> <span class='glyphicon  glyphicon-list'></span> </button>",
                        customAttribute: ['producto', 'almacen'],
                        conditionName: 'verModal',
                        conditionDef: function (atributos) {
                            if (atributos[0].criterioControl.codigo == "normal") {
                                return false;
                            } else {
                                return true;
                            }
                        },
                        functionName: "view",
                        functionDef: function (atributos) {

                            var defaultColumnOrder = ['id', 'grupoProducto.numero', 'grupoProducto.fechaVencimiento', 'saldoReal', 'saldoTransito', 'ultimoCosto', 'costoPromedio'];

                            var staticFilter = [];

                            // El filtro almacen siempre tiene un valor definido (al cargar la pagina es 'Todos')
                            staticFilter.push(
                                {
                                    path: 'grupoProducto.producto.id',
                                    equals: atributos[0].id
                                }
                            )

                            staticFilter.push(
                                {
                                    path: 'almacen.id',
                                    equals: atributos[1].id
                                }
                            )

                            staticFilter.push(
                                {
                                    path: 'dia',
                                    sortDesc: 'true'
                                }
                            )

                            // Si tiene fecha definida, se agrega el filtro
                            if ($scope.fecha != undefined) {

                                var fechaFormateada = moment($scope.fecha).format('DD/MM/YYYY');
                                staticFilter.push(
                                    {
                                        path: 'dia',
                                        equalOrBefore: fechaFormateada
                                    }
                                )
                            }

                            var filter = {
                                search: filterFactory.and(staticFilter).value()
                            };

                            $scope.vm.options = {
                                'resource': 'grupoproductoalmacen/saldogrupoproducto',
                                'staticFilter': filter,
                                'title': 'Grupo Producto',
                                'view': 'BaseList',
                                'factory': GrupoProductoAlmacenFactory,
                                'defaultColumnOrder': defaultColumnOrder,
                                'columns': [
                                    { 'data': 'id', 'title': $filter('translate')('CODE'), 'class': 'dt-center', 'searchable': false },
                                    { 'data': 'grupoProducto.numero', 'title': 'Nro. Grupo', 'class': 'dt-center', 'searchable': false },
                                    { 'data': 'grupoProducto.fechaVencimiento','title': 'Fecha Vencimiento','renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'searchable': false},
                                    { 'data': 'saldoReal', 'title': 'Existencia (UM Base)', 'class': 'dt-center', 'render': unidadMedidaRenderCantidad, 'searchable': false },
                                    { 'data': 'saldoTransito', 'title': 'Existencia en tránsito (UM Base)', 'class': 'dt-center', 'render': unidadMedidaRenderCantidad, 'searchable': false },
                                    { 'data': 'ultimoCosto', 'title': $filter('translate')('COSTO_UNITARIO'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'searchable': false, 'orderable': false }
                                ],
                                'hasOptions': false,
                                'defaultOrderColumn': 0,
                                'defaultOrderDir': "desc",
                                'hideHeader': true,
                            };

                            $scope.modalInstance = $modal.open({
                                templateUrl: 'views/directives/datatables-modal.html',
                                scope: $scope,
                                size: 'lg'
                            });

                            $scope.vm.close = function () {
                                if ($scope.modalInstance) {
                                    $scope.modalInstance.close();
                                }
                            }
                        }
                    }],
                    'extraMenuOptions': [
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                              vm.uiBlockuiConfig.bloquear = true;
                                CsvFactory.csv("productoalmacen/existencia", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                      vm.uiBlockuiConfig.bloquear = false;
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    },function (response) {
                                      vm.uiBlockuiConfig.bloquear = false;
                                    })
                            }
                        }
                    ]
                };
            });

            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        }]);

'use strict';


angular.module('qualita.stock')
  .controller('InventarioValorizadoIndexCtrl',
    ["$scope", "ProductoAlmacenFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "AlmacenFactory", "ProductosFactory", "notify", "GrupoProductoAlmacenFactory", "UtilFactory", "MovimientoStockFactory", "StockLangFactory", "WatchLocacionChangeFactory", "$filter", "$rootScope", "CsvFactory", "baseurl", "TiposTipoFactory", "LineasProductoFactory", "MarcasFactory", "SucursalesFactory", "ParametrosFactory", function ($scope, ProductoAlmacenFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal,
      $window, $state, AlmacenFactory, ProductosFactory, notify, GrupoProductoAlmacenFactory, UtilFactory,
      MovimientoStockFactory, StockLangFactory, WatchLocacionChangeFactory, $filter, $rootScope,
      CsvFactory, baseurl, TiposTipoFactory, LineasProductoFactory, MarcasFactory, SucursalesFactory,ParametrosFactory) {

      var vm = this;
      vm.uiBlockuiConfig = {
        'bloquear': false,
        'message': 'Descargando Csv...'
      };

      var dateRender = function (data, type, full) {
        if (data) {
          return moment.utc(data).format('DD/MM/YYYY');
        } else {
          return "";
        }
      };

      var emptyDataRender = function (data) {
        if (data == undefined)
          return "";
        else
          return data;
      };

      $scope.curDate = new Date();
      $scope.producto = undefined;
      $scope.almacen = undefined;
      $scope.unidadMedida = undefined;

      $scope.filtroPersonalizado = false;
      $scope.mensajeEsBase = true;
      $scope.fecha = new Date();

      getAlmacenes();

      getMarcas();

      getSucursales();

      WatchLocacionChangeFactory.killAllWatchers();
      if ($scope.watcher) { $scope.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
        $scope.watcher = watcher;
        $scope.productos = ProductosFactory.allForSelect(locacionFilter);
      }, "locaciones.id");
      $scope.unidades = undefined;
      $scope.multiplicador = 1;

      var almacenFiltro = undefined;
      var productoFiltro = undefined;
      var unidadMedidaFiltro = undefined;

      //Banderas para deshabilitar filtro producto
      $scope.disableLineaFilter = false;
      $scope.disableProductFilter = false;

      //filtros para existencias
      $scope.existenciapositiva = false;
      $scope.existencianegativa = false;
      $scope.existenciacero = false;
      $scope.existenciatodos = true;

      $scope.clasesProducto = TiposTipoFactory.get("clase_linea_producto");

      var params = {};
      params.search = filterFactory.and([{
          path: 'clase.codigo',
          equals: 'producto'
        },{
          path: 'codigo',
          sortAsc: true
        }
      ]);

      LineasProductoFactory.getAllTree(params).$promise.then(function(response) {
        $scope.lineasProducto = response;
      });

      function getAlmacenes(){

        var params = {};
       /* var filtros = [];
        if(filtro) {
          filtros.push(filtro);
        }*/

       // params.search = filterFactory.and(filtros).value();
        params.view = "BaseList";

        AlmacenFactory.all(params).$promise.then(function (response) {
          $scope.almacenes = response;
          $scope.almacenes.push({ 'id': '', 'codigo': '', 'descripcion': 'Todos' });
          $scope.almacen = $scope.almacenes[$scope.almacenes.length - 1];
        });
      }

      function getMarcas(){

        var params = {};
       /* var filtros = [];
        if(filtro) {
          filtros.push(filtro);
        }*/

       // params.search = filterFactory.and(filtros).value();
        params.view = "BaseList";

        MarcasFactory.all(params).$promise.then(function (response) {
          $scope.marcas = response;
          $scope.marcas.push({ 'id': '', 'codigo': '', 'descripcion': 'Todos' });
          $scope.marca = $scope.marcas[$scope.marcas.length - 1];
        });
      }

      function getSucursales(){

        var params = {};

        params.view = "BaseList";

        SucursalesFactory.all(params).$promise.then(function (response) {
          $scope.listaSucursales = response;
          $scope.listaSucursales.push({ 'id': '', 'codigo': '', 'nombre': 'Todos' });
          $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];
        });
      }
      function getLineasProducto(filtro) {
        var params = {};

        var filtros = [{
          path: 'ultimoNivel',
          equals: true
        }];
        if(filtro) {
          filtros.push(filtro);
        }

        params.search = filterFactory.and(filtros).value();
        return LineasProductoFactory.all(params);
      }

      $scope.changeLinea = function(){
        $scope.producto = undefined;
        $scope.disableProductFilter = true;

      };

      $scope.changeClase = function(){
        $scope.producto = undefined;
        $scope.almacen = undefined;
        $scope.unidadMedida = undefined;

        // Volver a filtrar lineas
        var params = {};
        if($scope.clase.codigo === "activo_fijo") {
          params.search = filterFactory.and([{
            path: 'clase.codigo',
            equals: 'activo_fijo'
          }]).value();
        } else {
          params.search = filterFactory.and([{
            path: 'clase.codigo',
            notEquals: 'activo_fijo'
          }]).value();
        }

        LineasProductoFactory.getAllTree(params).$promise.then(function(response) {
          $scope.lineasProducto = response;
        });

        // Volver a filtrar almacenes
        $scope.almacenes = getAlmacenes();
       /* if($scope.clase.codigo === "activo_fijo") {
           = getAlmacenes({
            path: "clase.codigo",
            equals: "activo_fijo"
          });
        } else {
          $scope.almacenes = getAlmacenes({
            path: "clase.codigo",
            notEquals: "activo_fijo"
          });
        }*/
      }

      $scope.cleanFilters = function(){

        $scope.almacen = $scope.almacenes[$scope.almacenes.length - 1];
        $scope.linea = undefined;
        $scope.producto = undefined;
        $scope.unidad = undefined;
        $scope.fecha = undefined;
        $scope.clase = undefined;
        $scope.marca = $scope.marcas[$scope.marcas.length - 1];
        $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];

        $scope.disableLineaFilter = false;
        $scope.disableProductFilter = false;
      };


      $scope.delayRequestProducto = function(searchValue){
        var userInputFilter = {};
        var staticFilter = [];
        if (searchValue.length >= 3) {
                var filterNombre = staticFilter.slice()
                var filterCodigoBarras = staticFilter.slice()
                var filterCodigoInterno = staticFilter.slice()

                filterNombre.push({
                    path: "nombre",
                    like: searchValue
                })

                filterCodigoBarras.push({
                    path: "codigoBarrasPrincipal.codigoBarras",
                    like: searchValue
                })

                filterCodigoInterno.push( {
                    path: "codigo",
                    like: searchValue
                })

                //Si existe filtro de clase
                if($scope.clase){
                  var filterClaseSelected = {};

                  if($scope.clase.codigo === "activo_fijo"){
                    filterClaseSelected = {
                      path: "claseProducto.tipoProducto.nombre",
                      equals: "Activo Fijo"
                    }
                  }else{
                    filterClaseSelected = {
                      path: "claseProducto.tipoProducto.nombre",
                      notEquals: "Activo Fijo"
                    }
                  }

                  // Se asigna a los filtros independientes OR, el filtro de clase
                  filterNombre.push(filterClaseSelected);
                  filterCodigoBarras.push(filterClaseSelected);
                  filterCodigoInterno.push(filterClaseSelected);

                }

                //Si existe filtro de Almacen
                if($scope.almacen){
                  var filterAlmacenSelected = {};

                  filterAlmacenSelected = {
                    path: "almacenes.almacen.id",
                    equals: $scope.almacen.id
                  }

                  // Se asigna a los filtros independientes OR, el filtro de almacen
                  filterNombre.push(filterAlmacenSelected);
                  filterCodigoBarras.push(filterAlmacenSelected);
                  filterCodigoInterno.push(filterAlmacenSelected);

                }


                //Si existe filtro de Linea producto
                if($scope.linea){
                  var filterLineaProductoSelected = {};

                  filterLineaProductoSelected = {
                    path: "lineaProducto.id",
                    equals: $scope.linea.id
                  }

                  // Se asigna a los filtros independientes OR, el filtro de linea
                  filterNombre.push(filterLineaProductoSelected);
                  filterCodigoBarras.push(filterLineaProductoSelected);
                  filterCodigoInterno.push(filterLineaProductoSelected);

                }


            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()

                ]).value()
            }
            $scope.recuperarProducto(userInputFilter)

        }else if (searchValue.length > 0 && searchValue.length < 3)  {

          var filterCodigoInterno = staticFilter.slice()
          filterCodigoInterno.push( {
              path: "codigo",
              like: searchValue
          })

           userInputFilter = {
              search:  filterFactory.or([
                   filterFactory.and(filterCodigoInterno).value()
                ]).value()
          }
          $scope.recuperarProducto(userInputFilter)

        }else{
          $scope.productos = undefined;
        }
      }

      $scope.recuperarProducto = function (userInputFilter) {
         var finalFilter = UtilFactory.createNestedFilters(
              $scope.finalFilters, userInputFilter)

          $scope.productos = ProductosFactory.allForSelect(userInputFilter)
      };

      $scope.changeAlmacen = function (almacen) {
        almacenFiltro = almacen;
        $scope.producto = undefined;
      };

      $scope.changeProducto = function (producto) {

        productoFiltro = producto;
        $scope.unidades = producto.unidadesMedida;
        $scope.unidad = producto.unidadMedidaBase;
        $scope.multiplicador = 1;
        $scope.changeUnidad($scope.unidad);

        // Limpia filtro familia y lo deshabilita
        $scope.linea = undefined;
        $scope.disableLineaFilter=true;
      };

      $scope.changeUnidad = function (unidadMedida) {
        var actual = unidadMedida;
        $scope.multiplicador = 1;
        if (!actual.esBase) {
          $scope.multiplicador = $scope.multiplicador * actual.cantidad;
          actual = actual.unidadContenida;
        }
      };

      var unidadMedidaRenderCantidad = function (data) {
        if (data != undefined) {

          var digitosDesdePunto = data.toString().substr(data.toString().indexOf('.'));
          //console.log("digitos desde el punto decimal: ", digitosDesdePunto);

          if(data != 0 && digitosDesdePunto != 0){
              data = parseFloat(data).toFixed(4);
          }

          // El calculo para convertir entre unidades consiste en la division por $scope.multiplicador
          data = data / $scope.multiplicador;
          data = Math.round(data * 100)/100;

          var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
          var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);



          // Si tiene parte entera
          if(parteEntera != ''){
              if(digitosDesdePunto != 0){
                  return (
                      parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                      ","+parteDecimal.toString()
                  );
              }else{
                  parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              }
          }else{
              return (
                  data
                  .toString()
                  .replace(".", ",")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              );
          }

        } else {
            return "";
        }
      };

      var unidadMedidaRenderCantidad2 = function (data) {
        if (data != undefined) {

          var digitosDesdePunto = data.toString().substr(data.toString().indexOf('.'));
          //console.log("digitos desde el punto decimal: ", digitosDesdePunto);

          if(data != 0 && digitosDesdePunto != 0){
              data = parseFloat(data).toFixed(4);
          }

          // El calculo para convertir entre unidades consiste en la division por $scope.multiplicador
          data = Math.round(data * 100)/100;

          var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
          var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);



          // Si tiene parte entera
          if(parteEntera != ''){
              if(digitosDesdePunto != 0){
                  return (
                      parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                      ","+parteDecimal.toString()
                  );
              }else{
                  parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              }
          }else{
              return (
                  data
                  .toString()
                  .replace(".", ",")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              );
          }

        } else {
            return "";
        }
      };

      var unidadMedidaRenderCosto = function (data) {
        if (data != null) {
          var moneda = "Gs. ";
          data = parseFloat(data).toFixed(2);
          return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
          return '';
        }
      };

      var emptyRender = function (data) {
        if (data || data === 0) {
          return data;
        } else {
          return '';
        }
      };

      /*var costoTotalRender = function(data, type, row) {
        var moneda = "Gs. ";
        var dataAux = parseFloat(row.ultimoCosto * row.saldoReal * $scope.multiplicador).toFixed(2);
        return moneda + (dataAux).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      };*/

      var sumaTotalSaldos = function (data) {
        if (data == undefined)
          return "";
        else
          return data / $scope.multiplicador;
      };

      var renderCostoUnitario = function (data) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        var dataAux = parseFloat(data * $scope.multiplicador).toFixed(2);
        return moneda + (dataAux).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      var renderCostoTotal = function (data, type, row) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        var dataAux = parseFloat(row.ultimoCosto * row.saldoReal).toFixed(2);
        return moneda + (dataAux).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      var renderVentaTotal = function (data, type, row) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        return moneda + (data).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      var renderCostoPromedioTotal = function (data, type, row) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        return moneda + (data).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      var renderCostoUnitario2 = function (data) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        return moneda + (data).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      var renderCostoTotal2 = function (data, type, row) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        return moneda + (data).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };


      $scope.searchProductoFilter = function (criteria) {
        return function (item) {
          if (!criteria) {
            return true;
          }
          return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria ||
          (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
        };
      }

      $scope.cambiarFiltroPersonalizado = function () {

        // Se debe mostrar el datatable personalizado
        $scope.filtroPersonalizado = true;
        $scope.filtro = false;

        $scope.cambiarFiltro(false);
      };

      $scope.changeExistencia = function (value) {
        $scope.existenciapositiva = false;
        $scope.existencianegativa = false;
        $scope.existenciacero = false;
        $scope.existenciatodos = false;
        if(value === "positivo"){
          $scope.existenciapositiva = true;
        } else
        if(value === "negativo"){
          $scope.existencianegativa = true;
        } else
        if(value === "cero"){
          $scope.existenciacero = true;
        } else
        if(value === "todos"){
          $scope.existenciatodos = true;
        }
      };

      $scope.cambiarFiltro = function (byUser) {
          if($scope.fecha != undefined){
            $scope.filtro = true;
            // Si la funcion fue invocada por click del usuario, usar el datatable original
            if(byUser){
              $scope.filtro = true;
              $scope.filtroPersonalizado = false;
            }else{
              $scope.filtroPersonalizado = true;
              $scope.filtro = false;
            }

            // Actualizacion de banderas para "observaciones"
            if($scope.unidad && $scope.unidad.esBase){
              $scope.mensajeEsBase = true;
            }else{
              $scope.mensajeEsBase = false;
            }

            var staticFilter = [];

            if ($scope.almacen == undefined && $scope.producto == undefined) {
              staticFilter.push({
                path: $scope.filtro ? 'producto.lineaProducto.id' : 'dia',
                sortDesc: 'true'
              });
              if ($scope.linea) {
                staticFilter.push({
                  path: $scope.filtro ? "producto.lineaProducto.id" : "grupoProducto.producto.lineaProducto.id",
                  equals: $scope.linea.id
                });
              }
            } else if ($scope.almacen !== undefined && $scope.producto !== undefined) {

              staticFilter.push({
                path: $scope.filtro ? 'producto.id' : 'dia',
                sortDesc: 'true'
              },
                {
                  path: $scope.filtro ? 'producto.id' : 'grupoProducto.producto.id',
                  equals: $scope.producto.id
                });
              if ($scope.linea) {
                staticFilter.push({
                  path: $scope.filtro ? "producto.lineaProducto.id" : "grupoProducto.producto.lineaProducto.id",
                  equals: $scope.linea.id
                });
              }
            } else if (!$scope.almacen && $scope.producto) {

              staticFilter.push(
                {
                  path: $scope.filtro ? 'producto.id' : 'dia',
                  sortDesc: 'true'
                },
                {
                  path: 'producto.id',
                  equals: $scope.producto.id
                });
              if ($scope.linea) {
                staticFilter.push({
                  path: $scope.filtro ? "producto.lineaProducto.id" : "grupoProducto.producto.lineaProducto.id",
                  equals: $scope.linea.id
                });
              }
            } else if ($scope.almacen && !$scope.producto) {

              staticFilter.push({
                path: $scope.filtro ? 'producto.lineaProducto.nombre' : 'dia',
                sortDesc: 'true'
              });
              if ($scope.linea) {
                staticFilter.push({
                  path: $scope.filtro ? "producto.lineaProducto.id" : "grupoProducto.producto.lineaProducto.id",
                  equals: $scope.linea.id
                });
              }
            }

            if ($scope.fecha) {
              $scope.filtroPersonalizado ? staticFilter.push({
                path: 'dia',
                equalOrBefore: moment($scope.fecha).format('DD/MM/YYYY')
              }) : staticFilter.push({
                path: 'dia',
                equals: moment($scope.fecha).format('DD/MM/YYYY')
              });
            }
            //Si existe filtro de Clase
          if($scope.clase){

              var filterClaseSelected = {
                path: $scope.filtro ? "producto.claseProducto.tipoProducto.nombre" : "grupoProducto.producto.claseProducto.tipoProducto.nombre",
                equals: "Activo Fijo"
              }

              if($scope.clase.codigo === "activo_fijo"){
                filterClaseSelected = {
                    path: $scope.filtro ? "producto.claseProducto.tipoProducto.nombre" : "grupoProducto.producto.claseProducto.tipoProducto.nombre",
                      equals: "Activo Fijo"
                    }
              }else{
                $scope.filtroPersonalizado ? filterClaseSelected = {
                    path: $scope.filtro ? "producto.claseProducto.tipoProducto.nombre" : "grupoProducto.producto.claseProducto.tipoProducto.nombre",
                    notEquals: "Activo Fijo"
                } :
                filterClaseSelected = {
                  path: $scope.filtro ? "producto.claseProducto.tipoProducto.nombre" : "grupoProducto.producto.claseProducto.tipoProducto.nombre",
                  equals: "Producto"
                }
              }

              staticFilter.push(filterClaseSelected);
            }

            var existeFilterLineaProducto = _.find(staticFilter, function(filtro){
              return "producto.lineaProducto.id" != filtro.path;
            });

            //Si existe filtro de Linea producto
            if($scope.linea && !existeFilterLineaProducto){
              var filterLineaProductoSelected = {};

              filterLineaProductoSelected = {
                path: $scope.filtro ? "producto.lineaProducto.id" : "grupoProducto.producto.lineaProducto.id",
                equals: $scope.linea.id
              }

              staticFilter.push(filterLineaProductoSelected);
            }

            //Si existe filtro de Almacen
            if($scope.almacen){
              if($scope.almacen.id){
                var filterAlmacenSelected = {};

                filterAlmacenSelected = {
                  path: "almacen.id",
                  equals: $scope.almacen.id
                }

              /*if($scope.clase!=undefined){
                var filterAlmacenClase = {};

                if($scope.clase.codigo === "activo_fijo"){
                  filterAlmacenClase = {
                    path: "almacen.clase.codigo",
                    equals: "activo_fijo"
                  }
                }else{
                  filterAlmacenClase = {
                    path: "almacen.clase.codigo",
                    notEquals: "activo_fijo"
                  }
                }
                staticFilter.search.filters.push(filterAlmacenClase);
              }*/
                staticFilter.push(filterAlmacenSelected);
              }
            }

            //Si existe filtro unidad de medida
            if($scope.unidad){
              var filterUnidadSelected = {};

              filterUnidadSelected = {
                path: $scope.filtro ? "producto.unidadesMedida.id" : "grupoProducto.producto.unidadesMedida.id",
                equals: $scope.unidad.id
              }

              staticFilter.push(filterUnidadSelected);
            }

            //Si existe filtro unidad de medida
            if($scope.existenciapositiva){
              var filterPositivo = {};

              filterPositivo = {
                path: "saldoTotal",
                equals: "positivo"
              }

              staticFilter.push(filterPositivo);
            }

            //Si existe filtro unidad de medida
            if($scope.existencianegativa){
              var filterNegativo = {};

              filterNegativo = {
                path: "saldoTotal",
                equals: "negativo"
              }

              staticFilter.push(filterNegativo);
            }

            //Si existe filtro unidad de medida
            if($scope.existenciacero){
              var filterCero = {};

              filterCero = {
                path: "saldoTotal",
                equals: "cero"
              }

              staticFilter.push(filterCero);
            }

            if($scope.marca){
              if($scope.marca.id){
                var filter = {};
                if($scope.filtroPersonalizado){
                  filter = {
                    path: "grupoProducto.producto.marca.id",
                    equals: $scope.marca.id
                  }
                } else {
                  filter = {
                    path: "producto.marca.id",
                    equals: $scope.marca.id
                  }
                }

                staticFilter.push(filter);
              }
            }

            if($scope.sucursal){
              if($scope.sucursal.id){
                var filter = {};

                filter = {
                  path: "almacen.sucursal.id",
                  equals: $scope.sucursal.id
                }

                staticFilter.push(filter);
              }
            }

            // Se define el filtro final
            var filtros = {
              search: filterFactory.and(staticFilter).value()
            };
            $scope.options.staticFilter = filtros;
            $scope.optionsPersonalizado.staticFilter = filtros;
           // WatchLocacionChangeFactory.watchWith(staticFilter, $scope.options, "producto.locaciones.id");
           // WatchLocacionChangeFactory.watchWith(staticFilter, $scope.optionsPersonalizado, "grupoProducto.producto.locaciones.id");
          }else{
            var msg = $filter('translate')('FECHA_OBLIGATORIO');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
          }
      };

      /*****************************  Datatable Original  *******************************/

      var defaultColumnOrder = ['id', 'claseProducto', 'lineaProducto', 'almacen', 'codigoProducto', 'codigoBarras', 'descripcion',
      'producto.unidadMedidaBase.abreviatura', 'producto.marca.descripcion', 'existencia', 'existenciaTransito', 'existenciaTotal',
       'ultimoCosto','ultimoCostoIVA' ,'costoPromedio','costoPromedioIVA', 'precioVenta', 'totalUltimoCostoValorizado', 'totalPromedioValorizado', 'totalPrecioVenta',
      ];

      ParametrosFactory.getByCodigo('incluir_montos_iva_valorizado').then(function (response) {
        vm.mostrarIva = false;
        if (response.valorTipo.codigo === "si") {
          vm.mostrarIva = true;
        }
        StockLangFactory.getTranslations().then(function (translations) {
          $scope.options = {
            'resource': 'productoalmacen/newdatatable',
            'title': 'Inventario Valorizado de Productos',
            'factory': ProductoAlmacenFactory,
            'view': 'InventarioValorizadoList',
            'defaultColumnOrder': defaultColumnOrder,
            'columns': [
              { 'data': 'id', 'title': 'Código', 'searchable': false },
              { 'data': 'claseProducto', 'title': $filter('translate')('PRODUCT_CLASS'), 'searchable': false, 'orderable': false, visible: false },
              { 'data': 'lineaProducto', 'title': $filter('translate')('PRODUCT_LINE'), 'searchable': false, 'orderable': false, visible: false },
              { 'data': 'almacen', 'title': $filter('translate')('ALMACEN'), 'searchable': false, 'orderable': false },
              { 'data': 'codigoProducto', 'title': $filter('translate')('CODIGO_PRODUCTO'), 'searchable': false, 'orderable': true, 'class': 'dt-right' },
              { 'data': 'codigoBarras', 'title': $filter('translate')('CODIGO_BARRA_PRODUCTO'), 'searchable': false, 'orderable': false, 'class': 'dt-right', 'render': emptyRender, visible: false },
              { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION'), 'searchable': false, 'orderable': true },
              { 'data': 'unidadMedida', 'title': $filter('translate')('UNIDAD_MEDIDA_ABREVIADO'), 'searchable': false, 'orderable': false, visible: false },
              { 'data': 'marca', 'title': $filter('translate')('MARCA'), 'render': emptyRender, 'searchable': false, 'orderable': false, visible: false },
              { 'data': 'existencia', 'title': $filter('translate')('EXISTENCIA'), 'render': unidadMedidaRenderCantidad2, 'class': 'dt-right', 'searchable': false, 'orderable': false },
              { 'data': 'existenciaTransito', 'title': $filter('translate')('EXISTENCIA_TRANSITO'), 'render': unidadMedidaRenderCantidad2, 'class': 'dt-right', searchable: false, 'orderable': false },
              { 'data': 'existenciaTotal', 'title': $filter('translate')('EXISTENCIA_TOTAL'), 'render': unidadMedidaRenderCantidad2, 'class': 'dt-right', searchable: false, 'orderable': false },
              { 'data': 'ultimoCosto', 'title': $filter('translate')('ULTIMO_COSTO'), 'render': renderCostoUnitario2, 'class': 'dt-right', 'searchable': false, 'orderable': false },
              { 'data': 'ultimoCostoIVA', 'title': $filter('translate')('ULTIMO_COSTO_IVA'), visible: vm.mostrarIva, 'class': 'dt-right', 'renderWith': renderCostoUnitario, 'searchable': false, 'orderable': false },
              { 'data': 'costoPromedio', 'title': $filter('translate')('COSTO_PROMEDIO'), 'render': renderCostoUnitario2, 'class': 'dt-right', 'searchable': false, 'orderable': false },
              { 'data': 'costoPromedioIVA', 'title': $filter('translate')('COSTO_PROMEDIO_IVA'), visible: vm.mostrarIva, 'class': 'dt-right', 'renderWith': renderCostoUnitario, 'searchable': false, 'orderable': false },
              { 'data': 'precioVenta', 'title': $filter('translate')('PRECIO_VENTA'), 'render': unidadMedidaRenderCosto, 'class': 'dt-right', 'searchable': false, 'orderable': false },
              { 'data': 'totalUltimoCostoValorizado', 'title': $filter('translate')('TOTAL_ULTIMO_COSTO'), 'render': renderCostoTotal2, 'class': 'dt-right', 'searchable': false, 'orderable': false },
              { 'data': 'totalPromedioValorizado', 'title': $filter('translate')('TOTAL_COSTO_PROMEDIO'), 'render': renderCostoPromedioTotal, 'class': 'dt-right', 'searchable': false, 'orderable': false },
              { 'data': 'totalPrecioVenta', 'title': $filter('translate')('TOTAL_PRECIO_VENTA'), 'render': renderVentaTotal, 'class': 'dt-right', 'searchable': false, 'orderable': false }
            ],
            'extraMenuOptions':
              [
                {
                  'title': $filter('translate')('REPORT'),
                  'icon': 'glyphicon glyphicon-file',
                  'showCondition': function () { return true; },
                  'action': function () {
                    var filtersArr = [];
                    if ($scope.clase) {
                      filtersArr.push("clase=" + $scope.clase.descripcion);
                    }
                    if ($scope.almacen && $scope.almacen.id) {
                      filtersArr.push("idalmacen=" + $scope.almacen.id);
                    }
                    if ($scope.producto && $scope.producto.id) {
                      filtersArr.push("idproducto=" + $scope.producto.id);
                    }
                    if ($scope.unidad && $scope.unidad.id) {
                      filtersArr.push("idunidadmedida=" + $scope.unidad.id);
                    }
                    if ($scope.fecha) {
                      filtersArr.push("fecha=" + moment($scope.fecha).format("MM/DD/YYYY"));
                    }
                    if ($scope.existenciapositiva) {
                      filtersArr.push("existencia=positivo");
                    }
                    if ($scope.existencianegativa) {
                      filtersArr.push("existencia=negativo");
                    }
                    if ($scope.existenciacero) {
                      filtersArr.push("existencia=cero");
                    }
                    if ($scope.marca && $scope.marca.id) {
                      filtersArr.push("idmarca=" + $scope.marca.id);
                    }
                    if ($scope.sucursal && $scope.sucursal.id) {
                      filtersArr.push("idsucursal=" + $scope.sucursal.id);
                    }
                    if ($scope.linea && $scope.linea.id) {
                      filtersArr.push("idlineaproducto=" + $scope.linea.id);
                    }
                    var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("inventariovalorizado", 'pdf', filtersArr);
                    $window.open(pdfDownloadURL, '_blank');
                    console.log("report");
                  }
                },
                {
                  'title': 'CSV',
                  'icon': 'glyphicon glyphicon-export',
                  'showCondition': function () { return true; },
                  'action': function () {
                    var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);
                    vm.uiBlockuiConfig.bloquear = true;
                    CsvFactory.csv("productoalmacen", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                      .then(function (response) {
                        vm.uiBlockuiConfig.bloquear = false;
                        console.log("response de csv: ", response);
                        var fileName = response.data.fileName;
                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                      }, function (response) {
                        vm.uiBlockuiConfig.bloquear = false;
                      }
                      );
                  }
                }
              ],
            'hasOptions': false,
            'hideEditMenu': false,
            'hideRemoveMenu': false,
            'defaultOrderColumn': 0,
            'defaultOrderDir': "asc",
            'extraRowOptions': []
          };
        })
      });;


      /*****************************  Datatable Personalizado  *******************************/

      var defaultColumnOrderPersonalizado = ['id', 'grupoProducto.producto.proveedorPrincipal.nombre', 'grupoProducto.producto.codigo',
      'saldoReal', 'grupoProducto.producto.nombre', 'grupoProducto.numero', 'grupoProducto.fechaVencimiento', 'ultimoCosto', 'costoTotal', 'producto.unidadMedidaBase.abreviatura',
      'producto.marca.descripcion'];

      StockLangFactory.getTranslations().then(function (translations) {
        $scope.optionsPersonalizado = {
          'resource': 'grupoproductoalmacen/saldogrupoproducto',
          'title': 'Inventario Valorizado de Productos',
          'factory': GrupoProductoAlmacenFactory,
          'view': 'InventarioValorizadoList',
          'defaultColumnOrder': defaultColumnOrderPersonalizado,
          'columns': [
            { 'data': 'id', 'title': $filter('translate')('ID'), 'searchable': false, 'orderable': false, 'visible': false },
            { 'data': 'grupoProducto.producto.proveedorPrincipal.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX'), 'searchable': false, 'orderable': false },
            { 'data': 'grupoProducto.producto.codigo', 'title': $filter('translate')('CODIGO_PRODUCTO'), 'searchable': false, 'orderable': false },
            { 'data': 'grupoProducto.producto.nombre', 'title': $filter('translate')('DESCRIPTION'), 'searchable': false, 'orderable': false },
            { 'data': 'grupoProducto.producto.unidadMedidaBase.abreviatura', 'title': $filter('translate')('UNIDAD_MEDIDA_ABREVIADO'), 'searchable': false, 'orderable': false, visible: false},
            { 'data': 'grupoProducto.producto.marca.descripcion', 'title': $filter('translate')('MARCA'), 'render': emptyRender, 'searchable': false, 'orderable': false, visible: false},
            { 'data': 'grupoProducto.numero', 'title': $filter('translate')('NRO_SERIE'), 'searchable': false, 'orderable': false },
            { 'data': 'grupoProducto.fechaVencimiento', 'title': $filter('translate')('FECHA_VENCIMIENTO'), 'searchable': false, 'orderable': false, 'render': emptyRender},
            { 'data': 'saldoReal', 'title': $filter('translate')('EXISTENCIA'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'searchable': false, 'orderable': false  },
            { 'data': 'ultimoCosto', 'title': $filter('translate')('COSTO_UNITARIO'), 'render': renderCostoUnitario, 'class': 'dt-right', 'searchable': false, 'orderable': false  },
            { 'data': 'costoTotal', 'title': $filter('translate')('TOTAL_COSTO_UNITARIO'), 'searchable': false, 'orderable': false, 'render':renderCostoTotal }
          ],
          'extraMenuOptions':
            [{
                'title': 'CSV',
                'icon': 'glyphicon glyphicon-export',
                'showCondition': function () { return true; },
                'action': function () {
                  var filters = UtilFactory.createNestedFilters($scope.optionsPersonalizado.getFilters(), $scope.optionsPersonalizado.staticFilter);

                  CsvFactory.csv("grupoproductoalmacen", filters, $scope.optionsPersonalizado.tableAjaxParams, $scope.optionsPersonalizado.currentColumnOrder, $scope.optionsPersonalizado.currentColumnOrderNames)
                    .then(function (response) {
                      console.log("response de csv: ", response);
                      var fileName = response.data.fileName;
                      $window.open(baseurl.getPublicBaseUrl() + fileName);
                    });
                }
              }
            ],
          'hasOptions': false,
          'hideEditMenu': false,
          'hideRemoveMenu': false,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "desc",
          'extraRowOptions': []
        };
      });


      console.log($scope.options);

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }

    }]);

'use strict';


angular.module('qualita.stock')
  .controller('ReprocesoConsultaIndexCtrl',
    ["$scope", "MovimientoStockDetalleFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "AlmacenFactory", "ProductosFactory", "notify", "TiposFactory", "UtilFactory", "StockLangFactory", "ParametrosFactory", "$q", "WatchLocacionChangeFactory", "$filter", "CsvFactory", "baseurl", "$rootScope", function ($scope, MovimientoStockDetalleFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal,
      $window, $state, AlmacenFactory, ProductosFactory, notify, TiposFactory, UtilFactory,
      StockLangFactory, ParametrosFactory, $q, WatchLocacionChangeFactory, $filter, CsvFactory, baseurl, $rootScope) {
        
        $scope.producto = undefined;
        $scope.almacen = undefined;
        $scope.almacenes = AlmacenFactory.all();
        var vm = this;

        vm.uiBlockuiConfig = {
          'bloquear': false,
          'message': 'Reprocesando...Esto puede demorar varios minutos'
      };
        
        $scope.delayRequestProducto = function(searchValue){
          var userInputFilter = {};
          var staticFilter = [];
          if (searchValue.length >= 3) {
            var filterNombre = staticFilter.slice()
            var filterCodigoBarras = staticFilter.slice()
            var filterCodigoInterno = staticFilter.slice()
            
            filterNombre.push({
              path: "nombre",
              like: searchValue
            })
            
            filterCodigoBarras.push({
              path: "codigoBarrasPrincipal.codigoBarras",
              like: searchValue
            })
            
            filterCodigoInterno.push( {
              path: "codigo",
              like: searchValue
            })
            
            userInputFilter = {
              search: filterFactory.or([
                filterFactory.and(filterNombre).value(),
                filterFactory.and(filterCodigoBarras).value(),
                filterFactory.and(filterCodigoInterno).value()
              ]).value()
            }
            
            $scope.recuperarProducto(userInputFilter)
          
          } else if (searchValue.length > 0 && searchValue.length < 3)  {
            var filterCodigoInterno = staticFilter.slice()
            filterCodigoInterno.push( {
              path: "codigo",
              like: searchValue
            })
            
            userInputFilter = {
              search:  filterFactory.or([
                filterFactory.and(filterCodigoInterno).value()
              ]).value()
            }
            
            $scope.recuperarProducto(userInputFilter)
          } else {
            $scope.productos = undefined
          }
        }
        
        $scope.recuperarProducto = function (userInputFilter) {
          var finalFilter = UtilFactory.createNestedFilters(
            $scope.finalFilters, userInputFilter)
            $scope.productos = ProductosFactory.allForSelect(userInputFilter)
        };
        
        $scope.cambiarFiltro = function () {
          $rootScope.isProcessing  = true;
        /**************************  RESULTADOS PARA DATATABLE  *************************** */
        if ($scope.fechaDesde != undefined && $scope.fechaHasta != undefined && $scope.fechaDesde > $scope.fechaHasta) {
          $rootScope.isProcessing  = false;
          notify({ message: $filter('translate')('FECHA_DESDE_MAYOR_FECHA_HASTA'), classes: 'alert-danger', position: 'right' });
        } else {
          if ($scope.almacen == undefined) {
            $rootScope.isProcessing  = false;
            notify({ message: $filter('translate')('FILTRO_ALMACEN_PRODUCTO_REQUERIDO'), classes: 'alert-danger', position: 'right' });
          } /* else if($scope.producto == undefined){
            $rootScope.isProcessing  = false;
            notify({ message: 'Debe completar el filtro de Producto', classes: 'alert-danger', position: 'right' });
          }  */else if ($scope.fechaDesde == undefined) {
            $rootScope.isProcessing  = false;
            notify({ message: $filter('translate')('FILTRO_FECHA_DESDE_PRODUCTO_REQUERIDO'), classes: 'alert-danger', position: 'right' });
          } else if ($scope.fechaDesde.getUTCMonth() != $scope.fechaHasta.getUTCMonth() ||
            $scope.fechaDesde.getUTCFullYear() != $scope.fechaHasta.getUTCFullYear()) {
            notify({
              message: $filter("translate")("DATE_RANGE_ERROR"),
              classes: "alert-danger",
              position: "right"
            });
            $rootScope.isProcessing  = false;
            return;
          } else {
            if ($scope.fechaDesde == null)
              $scope.fechaDesde = undefined;
            if ($scope.fechaHasta == null) 
              $scope.fechaHasta = undefined;

            //=== Si no se elije producto se setea la variable producto a -1. Si no, toma el id del producto que se selecciono
            var producto = -1;
            if($scope.producto != undefined){
              producto = $scope.producto.id;
            }
            //=====

            vm.uiBlockuiConfig.bloquear = true;

            
            /**********************************   AQUI REPROCESO    *****************************/
            MovimientoStockDetalleFactory.reprocesar($scope.fechaDesde, $scope.fechaHasta, $scope.almacen.id, producto).then(
              function(data){
                vm.uiBlockuiConfig.bloquear = false;

                $scope.filtro = true;
                $rootScope.isProcessing  = false;
                notify({
                  message: $filter("translate")("REPROCESO_SUCCESS"),
                  classes: 'alert-success',
                  position: 'right'
                });
                $scope.options.reloadData();
              }, 
              function(error){
                $rootScope.isProcessing  = false;
                vm.uiBlockuiConfig.bloquear = false;

                notify({
                  message: error.data[0].message,
                  classes: 'alert-danger',
                  position: 'right'
                });
              }
            );
            
            // filtros, si hay producto agrega filtro de producto y si no hay solo filtra por almacen.
            var staticFilter = {};
            if(producto != -1){
              if ($scope.producto.claseProducto.tipoAdministracion.codigo != 'bobinas') {
                staticFilter.search = filterFactory.or([
                  filterFactory.and([
                    {
                      path: 'movimientoStock.depositoOrigen.id',
                      equals: $scope.almacen.id
                    },
                      
                    {
                      path: 'producto.nombre',
                      equals: $scope.producto.nombre,
                    },
                    
                    {
                      path: 'movimientoStock.fecha',
                      equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                    }, 
                      
                    {
                      path: 'movimientoStock.fecha',
                      equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                    },
                      
                    {
                      path: 'grupo.id',
                      isNull: "true"
                    },
                      
                    {
                      path: 'movimientoStock.contrapartida',
                      like: 'false'
                    },
                      
                    {
                      path: 'id',
                      sortAsc: 'true'
                    }
                  ]).value(),
                    
                  filterFactory.and([
                    {
                      path: 'movimientoStock.depositoDestino.id',
                      equals: $scope.almacen.id
                    },
                      
                    {
                      path: 'producto.nombre',
                      equals: $scope.producto.nombre,
                    },
                    
                    {
                      path: 'movimientoStock.fecha',
                      equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                    }, 
                      
                    {
                      path: 'movimientoStock.fecha',
                      equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                    },
                    
                    {
                      path: 'grupo.id',
                      isNull: "true"
                    },
                    
                    {
                      path: 'movimientoStock.contrapartida',
                      like: 'true',
                    },
                    
                    {
                      path: 'id',
                      sortAsc: 'true'
                    }
                  ]).value()
                ]).value();
              } else {
                staticFilter.search = filterFactory.or([
                  filterFactory.and([
                    {
                      path: 'movimientoStock.depositoOrigen.id',
                      equals: $scope.almacen.id
                    },
                    
                    {
                      path: 'producto.nombre',
                      equals: $scope.producto.nombre,
                    },
                    
                    {
                      path: 'movimientoStock.fecha',
                      equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                    }, 
                      
                    {
                      path: 'movimientoStock.fecha',
                      equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                    },
                    
                    {
                      path: 'grupo.id',
                      isNull: "true"
                    },
                    
                    {
                      path: 'movimientoStock.contrapartida',
                      like: 'false'
                    },
                    
                    {
                      path: 'id',
                      sortAsc: 'true'
                    }
                  ]).value(),
                  
                  filterFactory.and([
                    {
                      path: 'movimientoStock.depositoDestino.id',
                      equals: $scope.almacen.id
                    },
                    
                    {
                      path: 'producto.nombre',
                      equals: $scope.producto.nombre,
                    },
                    
                    {
                      path: 'movimientoStock.fecha',
                      equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                    }, 
                      
                    {
                      path: 'movimientoStock.fecha',
                      equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                    },
                    
                    {
                      path: 'grupo.id',
                      isNull: "true"
                    },
                    
                    {
                      path: 'movimientoStock.contrapartida',
                      like: 'true',
                    },
                    
                    {
                      path: 'id',
                      sortAsc: 'true'
                    }
                  ]).value()
                ]).value();
              }
              
              $scope.options.staticFilter = staticFilter;
            
            } else{
              staticFilter.search = filterFactory.or([
                filterFactory.and([
                  {
                    path: 'movimientoStock.depositoOrigen.id',
                    equals: $scope.almacen.id
                  },
                  
                  {
                    path: 'movimientoStock.fecha',
                    equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                  }, 
                    
                  {
                    path: 'movimientoStock.fecha',
                    equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                  },
                  
                  {
                    path: 'grupo.id',
                    isNull: "true"
                  },
                  
                  {
                    path: 'movimientoStock.contrapartida',
                    like: 'false'
                  },
                  
                  {
                    path: 'id',
                    sortAsc: 'true'
                  }
                ]).value(),
                
                filterFactory.and([
                  {
                    path: 'movimientoStock.depositoDestino.id',
                    equals: $scope.almacen.id
                  },
                  
                  {
                    path: 'movimientoStock.fecha',
                    equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                  }, 
                    
                  {
                    path: 'movimientoStock.fecha',
                    equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                  },
                  
                  {
                    path: 'grupo.id',
                    isNull: "true"
                  },
                  
                  {
                    path: 'movimientoStock.contrapartida',
                    like: 'true',
                  },
                  
                  {
                    path: 'id',
                    sortAsc: 'true'
                  }
                ]).value()
              ]).value();
            }
            
            $scope.options.staticFilter = staticFilter;
          
          } //fin else
          
          //=== Si no se elije producto se setea la variable producto a -1. Si no, toma el id del producto que se selecciono
          var producto = -1;
          if($scope.producto != undefined){
            producto = $scope.producto.id;
          }
          //=====
          
          /* if ($scope.fechaDesde != undefined) {
            MovimientoStockDetalleFactory.getSaldoInicial(producto, $scope.almacen.id, moment($scope.fechaDesde).format('DD/MM/YYYY')).then(function (resp) {
              $scope.saldoInicial = resp.data;
              if ($scope.saldoInicial === undefined) {
                $scope.saldoInicial = 0;
              }
            });
          } else {
            $scope.saldoInicial = 0;
          } */
        } //fin else
      }; //final

      var emptyDataRender = function (data) {
        if (data == undefined)
          return "";
        else
        return data;
      };

      $scope.unidad = undefined;
      $scope.multiplicador = 1;
      var unidadMedidaRenderCantidad = function (data) {
        if (data == undefined)
          return "";
        else
          var dataAux = parseFloat(data / $scope.multiplicador).toFixed(2);
          return dataAux.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      var importeRenderUnitario = function (data, type, row) {
        if (data!=null) {
          var moneda = "Gs. ";
          data = parseFloat(data * $scope.multiplicador).toFixed(0);
          // TODO hacer esto mismo en todos los renders de moneda
          return (
            moneda +
            data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else {
          return "";
        }
      };

      var saldoRender = function (data) {
        if (data == undefined)
          return "";
        else
          var dataAux = parseFloat(data / $scope.multiplicador).toFixed(2);
        
          return dataAux.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };
      
      $scope.changeProducto = function (producto) {
        $scope.filtro = false;
      };
      
      $scope.changeAlmacen = function (producto) {
        $scope.filtro = false;
      };
      
      $scope.fechaDesdeChanged = function () {
        $scope.filtro = false;
        if ($scope.fechaHasta != null) {
          if (!validarFechas()) {
            var msg = $filter("translate")("INVALID_DATES");
            notify({message: msg, classes: "alert-danger", position: "right"});
          }
        }
      }

      $scope.fechaHastaChanged = function () {
        $scope.filtro = false;
        if ($scope.fechaDesde != null) {
          if (!validarFechas()) {
            var msg = $filter("translate")("INVALID_DATES");
            notify({message: msg, classes: "alert-danger", position: "right"});
          } 
        }
      }

      function validarFechas() {
        if ($scope.fechaDesde <= $scope.fechaHasta) {
          return true;
        } else {
          return false;
        }
      }
      
      StockLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'producto.nombre', 'grupo.numero', 'movimientoStock.fecha', 'descripcion', 'tipoMovimiento.descripcion',
            'movimientoStock.estado.descripcion', 'ingreso', 'egreso', 'saldo', 'ultimoCosto', 'costoPromedio', 'ultimoCostoIVA', 'costoPromedioIVA',
          ];

      $scope.options = {
        //'staticFilter': staticFilter,
        'resource': 'movimientostockdetalle',
        'title': 'Movimientos de Stock',
        'view': 'ConsultaMovimientoStockList',
        'factory': MovimientoStockDetalleFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('CODE'), 'searchable': false, 'orderable': false  },
          { 'data': 'producto.nombre', 'title': $filter('translate')('PRODUCT'), 'searchable': false, 'orderable': false },
          { 'data': 'grupo.numero', 'title': 'Grupo', 'render': emptyDataRender, 'class': 'dt-right', 'searchable': false, 'orderable': false  },
          { 'data': 'movimientoStock.fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'searchable': false, 'orderable': false, 'type': 'date-range' },
          { 'data': 'movimientoStock.numero', 'title': $filter('translate')('MOV_STOCK_NUMBER')  },
          { 'data': 'descripcion', 'title': $filter('translate')('NRO_COMPROBANTE'), 'searchable': true, 'class': 'dt-left', 'orderable': false },
          //{'data': 'movimientoStock.tipoReferencia.descripcion', 'title': 'Referencia'},
          { 'data': 'tipoMovimiento.descripcion', 'title': $filter('translate')('TIPO_MOVIMIENTO'), 'searchable': false, 'orderable': false },
          { 'data': 'movimientoStock.estado.descripcion', 'title': $filter('translate')('STATUS'), 'class': 'dt-center', 'searchable': false, 'orderable': false },
          { 'data': 'ingreso', 'title': $filter('translate')('ENTRADA'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'class': 'dt-right', 'searchable': false, 'orderable': false  },
          { 'data': 'egreso', 'title': $filter('translate')('SALIDA'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'class': 'dt-right', 'searchable': false, 'orderable': false  },
          { 'data': 'saldo', 'title': $filter('translate')('SALDO'), 'render': saldoRender, 'class': 'dt-right', 'searchable': false, 'class': 'dt-right', 'orderable': false  },
          { 'data': 'ultimoCosto', 'title': $filter('translate')('ULTIMO_COSTO_UNITARIO'), 'render': importeRenderUnitario, 'class': 'dt-right', 'class': 'dt-right', 'searchable': false, 'orderable': false  },
          { 'data': 'costoPromedio', 'title': $filter('translate')('COSTO_PROMEDIO'), 'render': importeRenderUnitario, 'class': 'dt-right', 'class': 'dt-right', 'searchable': false, 'orderable': false  },
          { 'data': 'ultimoCostoIVA', 'title': $filter('translate')('ULTIMO_COSTO_IVA'), 'render': importeRenderUnitario, 'class': 'dt-right', 'class': 'dt-right', visible: true, 'searchable': false, 'orderable': false  },
          { 'data': 'costoPromedioIVA', 'title': $filter('translate')('COSTO_PROMEDIO_IVA'), 'render': importeRenderUnitario, 'class': 'dt-right', 'class': 'dt-right', visible: true, 'searchable': false, 'orderable': false  },
          { 'data': 'nroOrdenProduccion', 'title': $filter('translate')('NRO_ORDEN_PRODUCCION'), 'class': 'dt-right', 'searchable': false, 'orderable': false, visible: false,  },
          { 'data': 'nroMovMateriales', 'title': $filter('translate')('NRO_MOV_MATERIALES'), 'class': 'dt-right', 'searchable': false, 'orderable': false, visible: false, },
          { 'data': 'nroApunteCorte', 'title': $filter('translate')('NRO_APUNTE_CORTE'), 'class': 'dt-right', 'searchable': false, 'orderable': false, visible: false,  }
        ],
        'hasOptions': false,
        'hideEditMenu': true,
        'hideRemoveMenu': true,
        'defaultOrderColumn': 3,
        'defaultOrderDir': "asc",
        'extraMenuOptions':[
          {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("movimientostockdetalle", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                      console.log("response de csv: ", response);
                      var fileName = response.data.fileName;
                      $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
          }
      ]
      };

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            search = search.split("~");
            if (search.length > 1) {
                /* El parámetro es un rago */

                var isDate = search[0].split("/");

                if (isDate.length > 1) {
                    /* Es un rago de fechas */

                    filtersArr.push({path: data, equalOrAfter: search[0]})
                    filtersArr.push({path: data, equalOrBefore: search[1]})
                } else {
                    /* El un rago numérco */

                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                    filtersArr.push({path: data, lessOrEqual: search[1]})
                }
            } else {
                /* El parametro no es un rago */
                filtersArr.push({path: data, like: search[0]})
            }
        });

        if($scope.producto != undefined){
          filtersArr.push({  
              path: 'producto.nombre',
              equals: $scope.producto.nombre,
            });
          filtersArr.push({
            path: 'movimientoStock.depositoOrigen.id',
            equals: $scope.almacen.id
          });
          filtersArr.push({
            path: 'movimientoStock.fecha',
            equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
          });
          filtersArr.push({
            path: 'movimientoStock.fecha',
            equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
          });
          filtersArr.push({
            path: 'grupo.id',
            isNull: "true"
          });
        }else{
          filtersArr.push({
            path: 'movimientoStock.depositoOrigen.id',
            equals: $scope.almacen.id
          });
          filtersArr.push({
            path: 'movimientoStock.fecha',
            equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
          });
          filtersArr.push({
            path: 'movimientoStock.fecha',
            equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
          });
          filtersArr.push({
            path: 'grupo.id',
            isNull: "true"
          });
        }
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }
  });
}]);

'use strict';
angular.module('qualita.stock')
  .controller('ConsultaDisponibilidadInventarioFormController',
    ["$scope", "filterFactory", "$modal", "AlmacenFactory", "ProductosFactory", "notify", "UtilFactory", "StockLangFactory", "WatchLocacionChangeFactory", "$filter", "ConsultaDisponibilidadInventarioFactory", "ProductoAlmacenFactory", "GrupoProductoAlmacenFactory", function ($scope, filterFactory, $modal, AlmacenFactory, ProductosFactory, notify, UtilFactory, StockLangFactory, WatchLocacionChangeFactory,
      $filter, ConsultaDisponibilidadInventarioFactory, ProductoAlmacenFactory, GrupoProductoAlmacenFactory) {

      var emptyDataRender = function (data) {
        if (data == undefined)
          return "";
        else
          return data;
      };

      var dateRender = function (data, type, full) {
        if (data) {
          return moment.utc(data).format('DD/MM/YYYY');
        } else {
          return "";
        }
      };

      var unidadMedidaRenderCantidad = function (data) {
        if (data != undefined) {

          var digitosDesdePunto = data.toString().substr(data.toString().indexOf('.'));

          if (data != 0 && digitosDesdePunto != 0) {
            data = parseFloat(data).toFixed(4);
          }

          data = data / $scope.multiplicador;
          data = Math.round(data * 100) / 100;

          var parteEntera = data.toString().substr(0, data.toString().indexOf('.'));
          var parteDecimal = data.toString().substr(data.toString().indexOf('.') + 1);

          if (parteEntera != '') {
            if (digitosDesdePunto != 0) {
              return (
                parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
                "," + parteDecimal.toString()
              );
            } else {
              parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
          } else {
            return (
              data
                .toString()
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
          }

        } else {
          return "";
        }
      };
      WatchLocacionChangeFactory.killAllWatchers();
      $scope.existenciaActual = 0;
      $scope.producto = undefined;
      $scope.almacen = undefined;
      $scope.numeroGrupo = undefined;
      $scope.almacenes = AlmacenFactory.all();

      if ($scope.watcher) { $scope.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
        $scope.watcher = watcher;
      }, "locaciones.id");
      $scope.grupos = undefined;

      StockLangFactory.getTranslations().then(function (translations) {

        var almacenFiltro = undefined;
        var productoFiltro = undefined;
        var grupoFiltro = undefined;

        var defaultColumnOrder = ['pedidoVenta.id', 'pedidoVenta.fecha', 'pedidoVenta.cliente.nombre', 'pedidoVenta.sucursalCliente.descripcion', 'grupoProducto.numero',
          'cantidad', 'cantidadDisponible'];

        $scope.options = {
          'resource': 'reservastockpedido',
          'title': 'Consulta de Disponibilidad de Inventario',
          'view': 'ConsultaDisponibilidadInventario',
          'factory': ConsultaDisponibilidadInventarioFactory,
          'defaultColumnOrder': defaultColumnOrder,
          'columns': [
            { 'data': 'pedidoVenta.id', 'title': 'Número', 'class': 'dt-center', 'orderable': false, 'searchable': false },
            { 'data': 'pedidoVenta.fecha', 'title': $filter('translate')('DATE'), 'orderable': false,'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'searchable': false },
            { 'data': 'pedidoVenta.cliente.nombre', 'title': 'Cliente', 'orderable': false, 'searchable': false },
            { 'data': 'pedidoVenta.sucursalCliente.descripcion', 'title': 'Sucursal Cliente', renderWith: emptyDataRender, 'orderable': false, 'searchable': false },
            { 'data': 'grupoProducto.numero', 'title': 'Nro. Grupo/Lote', renderWith: emptyDataRender, 'orderable': false, 'searchable': false },
            { 'data': 'cantidad', 'title': 'Cantidad Comprometida', 'orderable': false, 'searchable': false,renderWith: unidadMedidaRenderCantidad},
            { 'data': 'cantidadDisponible', 'title': 'Cantidad Disponible', 'orderable': false, 'searchable': false,renderWith: unidadMedidaRenderCantidad}
          ],
          'hasOptions': false,
          'hideEditMenu': true,
          'hideRemoveMenu': true,
          'defaultOrderColumn': 1,
          'defaultOrderDir': "asc",
        };


        var defaultColumnOrder2 = ['numeroGrupo','fechaVencimiento','existencia','existenciaComprometida','existenciaDisponible'];
        $scope.options2 = {
          'resource': 'reservastockpedido/resumen',
          'title': 'Consulta de Disponibilidad de Inventario',
          'view': 'ConsultaDisponibilidadInventario',
          'factory': ConsultaDisponibilidadInventarioFactory,
          'defaultColumnOrder': defaultColumnOrder2,
          'columns': [
            { 'data': 'numeroGrupo', 'title': 'Nro. Grupo', 'class': 'dt-center', 'orderable': false, 'searchable': false },
            { 'data': 'fechaVencimiento', 'title': 'Fecha Vencimiento', 'orderable': false,'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'searchable': false },
            { 'data': 'existencia', 'title': 'Existencia', 'orderable': false, 'searchable': false },
            { 'data': 'existenciaComprometida', 'title': 'Existencia Comprometida', 'orderable': false, 'searchable': false },
            { 'data': 'existenciaDisponible', 'title': 'Existencia Disponible', 'orderable': false, 'searchable': false }
          ],
          'hasOptions': false,
          'hideEditMenu': true,
          'hideRemoveMenu': true,
          'defaultOrderColumn': 1,
          'defaultOrderDir': "asc",
        };


        $scope.delayRequestProducto = function (searchValue) {
          var userInputFilter = {};
          var staticFilter = [];
          if (searchValue.length >= 3) {

            var filterNombre = staticFilter.slice()
            var filterCodigoBarras = staticFilter.slice()
            var filterCodigoInterno = staticFilter.slice()

            filterNombre.push({
              path: "nombre",
              like: searchValue
            })

            filterCodigoBarras.push({
              path: "codigoBarrasPrincipal.codigoBarras",
              like: searchValue
            })

            filterCodigoInterno.push({
              path: "codigo",
              like: searchValue
            })

            userInputFilter = {
              search: filterFactory.or([
                filterFactory.and(filterNombre).value(),
                filterFactory.and(filterCodigoBarras).value(),
                filterFactory.and(filterCodigoInterno).value()
              ]).value()
            }

            $scope.recuperarProducto(userInputFilter)
          } else if (searchValue.length > 0 && searchValue.length < 3) {

            var filterCodigoInterno = staticFilter.slice()
            filterCodigoInterno.push({
              path: "codigo",
              like: searchValue
            })

            userInputFilter = {
              search: filterFactory.or([
                filterFactory.and(filterCodigoInterno).value()
              ]).value()
            }
            $scope.recuperarProducto(userInputFilter)
          } else {
            $scope.productos = undefined
          }
        }

        $scope.recuperarProducto = function (userInputFilter) {
          var finalFilter = UtilFactory.createNestedFilters(
            $scope.finalFilters, userInputFilter)

          $scope.productos = ProductosFactory.allForSelect(userInputFilter)
        };

        $scope.changeAlmacen = function (almacen) {
          almacenFiltro = almacen;
          getExistenciaActual();
        };

        $scope.changeProducto = function (producto) {
          grupoFiltro = undefined;
          productoFiltro = producto;
          $scope.grupos = _.sortBy(producto.gruposProducto, 'id');
          if ($scope.grupos && $scope.grupos.length > 0) {
            $scope.grupos.push({ 'id': 0, 'codigo': '', 'numero': 'Todos' });
            $scope.numeroGrupo = $scope.grupos[$scope.grupos.length - 1];
          } else {
            $scope.numeroGrupo = undefined;
          }
          $scope.unidad = producto.unidadMedidaBase;
          $scope.multiplicador = 1;
          getExistenciaActual();
        };

        $scope.changeGrupo = function (numeroGrupo) {
          grupoFiltro = numeroGrupo;
          getExistenciaActual();
        };

        $scope.changeUnidad = function (unidadMedida) {
          var actual = unidadMedida;
          $scope.multiplicador = 1;
          if (!actual.esBase) {
            $scope.multiplicador = $scope.multiplicador * actual.cantidad;
            actual = actual.unidadContenida;
          }
          getExistenciaActual();
        };

        $scope.searchProductoFilter = function (criteria) {
          return function (item) {
            if (!criteria) {
              return true;
            }
            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
          };
        }

        $scope.cambiarFiltro = function () {
          if ($scope.numeroGrupo) {
            if ($scope.numeroGrupo.numero == "Todos") {
              $scope.mensajeSinGrupo = true;
            } else {
              $scope.mensajeSinGrupo = false;
            }
          }

          if ($scope.unidad && $scope.unidad.esBase) {
            $scope.mensajeEsBase = true;
          } else {
            $scope.mensajeEsBase = false;
          }

          if ($scope.almacen == undefined) {
            notify({ message: $filter('translate')('FILTRO_ALMACEN_REQUERIDO'), classes: 'alert-danger', position: 'right' });
          } else if ($scope.producto == undefined) {
            notify({ message: $filter('translate')('FILTRO_PRODUCTO_REQUERIDO'), classes: 'alert-danger', position: 'right' });
          } else {

            if ($scope.producto.criterioControl.codigo === "normal") {
              $scope.filtro = true;
              var staticFilter = {};

              if ($scope.producto.claseProducto.tipoAdministracion.codigo != 'bobinas') {
                staticFilter.search = filterFactory.or([
                  filterFactory.and([
                    {
                      path: 'almacen.id',
                      equals: $scope.almacen.id
                    },
                    {
                      path: 'producto.id',
                      equals: $scope.producto.id
                    },
                    {
                      path: 'id',
                      sortAsc: 'true'
                    }
                  ]).value(),
                  filterFactory.and([
                    {
                      path: 'almacen.id',
                      equals: $scope.almacen.id
                    },
                    {
                      path: 'producto.id',
                      equals: $scope.producto.id
                    },
                    {
                      path: 'id',
                      sortAsc: 'true'
                    }
                  ]).value()
                ]).value();
              } else {
                staticFilter.search = filterFactory.or([
                  filterFactory.and([
                    {
                      path: 'almacen.id',
                      equals: $scope.almacen.id
                    },
                    {
                      path: 'producto.id',
                      equals: $scope.producto.id
                    },
                    {
                      path: 'numeroGrupo',
                      like: $scope.numeroGrupo.numero
                    },
                    {
                      path: 'id',
                      sortAsc: 'true'
                    }
                  ]).value(),
                  filterFactory.and([
                    {
                      path: 'almacen.id',
                      equals: $scope.almacen.id
                    },
                    {
                      path: 'producto.id',
                      equals: $scope.producto.id
                    },
                    {
                      path: 'numeroGrupo',
                      like: $scope.numeroGrupo.numero
                    },
                    {
                      path: 'id',
                      sortAsc: 'true'
                    }
                  ]).value()
                ]).value();
              }
              $scope.options.staticFilter = staticFilter;
            } else {
              if ($scope.numeroGrupo == undefined) {
                notify({ message: $filter('translate')('FILTRO_GRUPO_REQUERIDO'), classes: 'alert-danger', position: 'right' });
              } else {
                $scope.filtro = true;
                var staticFilter = {};
                staticFilter.search = filterFactory.or([
                  filterFactory.and([
                    {
                      path: 'almacen.id',
                      equals: $scope.almacen.id
                    },
                    {
                      path: 'producto.id',
                      equals: $scope.producto.id
                    },
                    {
                      path: 'numeroGrupo',
                      like: $scope.numeroGrupo.numero
                    },
                    {
                      path: 'id',
                      sortAsc: 'true'
                    }
                  ]).value(),
                  filterFactory.and([
                    {
                      path: 'almacen.id',
                      equals: $scope.almacen.id
                    },
                    {
                      path: 'producto.id',
                      equals: $scope.producto.id
                    },
                    {
                      path: 'numeroGrupo',
                      like: $scope.numeroGrupo.numero
                    },
                    {
                      path: 'id',
                      sortAsc: 'true'
                    }
                  ]).value()

                ]).value();
                $scope.options.staticFilter = staticFilter;
              }
            }
          }
        };

        function getExistenciaActual() {
          if ($scope.producto && $scope.almacen) {
            var filtersArray = [];
            var fecha = moment(new Date()).format('DD/MM/YYYY');

            filtersArray.push(
              {
                path: 'almacen.id',
                equals: $scope.almacen.id
              }, {
              path: 'dia',
              equalOrBefore: fecha
            }, {
              path: 'dia',
              sortDesc: 'true'
            });

            if (grupoFiltro && grupoFiltro.id > 0) {
              filtersArray.push({
                path: 'grupoProducto.numero',
                equals: grupoFiltro.numero
              });

              filtersArray.push({
                path: 'grupoProducto.producto.id',
                equals: $scope.producto.id
              });
              var filters = { search: filterFactory.and(filtersArray).value() };

              GrupoProductoAlmacenFactory.all(filters, "BaseList").$promise.then(function (grupoproductoAlmacen) {
                if (grupoproductoAlmacen != undefined && grupoproductoAlmacen.length > 0) {
                  $scope.existenciaActual = grupoproductoAlmacen[0].saldoReal;
                  if ($scope.unidad && !$scope.unidad.esBase){
                    $scope.existenciaActual = parseFloat(unidadMedidaRenderCantidad($scope.existenciaActual));
                  }
                }
              });
            } else {
              filtersArray.push({
                path: 'producto.id',
                equals: $scope.producto.id
              });
              var filters = { search: filterFactory.and(filtersArray).value() };

              ProductoAlmacenFactory.all(filters, "ProductoAlmacenDatatableList").$promise.then(function (productosAlmacen) {
                if (productosAlmacen != undefined && productosAlmacen.length > 0) {
                  $scope.existenciaActual = productosAlmacen[0].saldoReal;
                  if ($scope.unidad && !$scope.unidad.esBase){
                    $scope.existenciaActual = parseFloat(unidadMedidaRenderCantidad($scope.existenciaActual));
                  }                }
              });
            };
          }
        }

        $scope.resumen = function () {
          var staticFilter = [];
          staticFilter.push(
            {
              path: 'almacen.id',
              equals: $scope.almacen.id
            }
          )

          if ($scope.producto != undefined) {
            staticFilter.push(
              {
                path: 'producto.id',
                equals: $scope.producto.id
              }
            )
          }

          if (grupoFiltro && grupoFiltro.id > 0) {
            staticFilter.push({
              path: 'grupoProducto.numero',
              equals: grupoFiltro.numero
            });
          }

          var filtros = {
            search: filterFactory.and(staticFilter).value()
          };

          $scope.options2.staticFilter = filtros;

          $scope.tituloModal = 'Consulta de Disponibilidad de Inventario';

          var modalInstance = $modal.open({
            templateUrl: 'views/directives/resumen-disponibilidad-inventario.html',
            scope: $scope,
            backdrop: 'static',
            bindToController: true,
            size: 'lg'
          });

          $scope.cerrar = function () {
            modalInstance.dismiss('cancel');
          };
        }

        $scope.limpiarFiltros = function () {
          $scope.almacen = undefined;
          $scope.producto = undefined;
          $scope.numeroGrupo = undefined;
          $scope.existenciaActual = undefined;
        }


      });
      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          search = search.split("~");
          if (search.length > 1) {

            var isDate = search[0].split("/");

            if (isDate.length > 1) {

              filtersArr.push({ path: data, equalOrAfter: search[0] })
              filtersArr.push({ path: data, equalOrBefore: search[1] })
            } else {

              filtersArr.push({ path: data, greaterOrEqual: search[0] })
              filtersArr.push({ path: data, lessOrEqual: search[1] })
            }
          } else {
            filtersArr.push({ path: data, like: search[0] })
          }
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }


    }]
  );

'use strict';
angular.module('qualita.stock')
  .factory('ConsultaDisponibilidadInventarioFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
    function($resource,  baseurl, filterFactory, TiposFactory, $http) {

  	var DisponibilidadInventario= $resource( baseurl.getBaseUrl() + '/reservastockpedido/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params) {
        return DisponibilidadInventario.query(params);
      },

      get: function(id) {
        return DisponibilidadInventario.get({id: id});
      },

      create: function(attrs) {
        return new DisponibilidadInventario(attrs);
      },

      save: function(disponibilidadinventario) {
        return (disponibilidadinventario.id) ? disponibilidadinventario.$update() : disponibilidadinventario.$save();
      },

      remove: function(disponibilidadinventario) {
        return disponibilidadinventario.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      }
    };
  }]);

'use strict';


angular.module('qualita.stock')
  .controller('ConsultaRotacionProductoIndexCtrl',
    ["$scope", "ProductoAlmacenFactory", "ReportTicketFactory", "$window", "notify", "$filter", "baseurl", "MarcasFactory", "SucursalesFactory", "ProveedoresFactory", function ($scope, ProductoAlmacenFactory, ReportTicketFactory,
      $window, notify, $filter, baseurl, MarcasFactory, SucursalesFactory, ProveedoresFactory) {

      var vm = this;

      $scope.curDate = new Date();
      $scope.fechaDesde = new Date();
      $scope.fechaHasta = new Date();
      $scope.existencia = false;
      $scope.staticFilterProv = [{ path: "activo", equals: true }];
      $scope.proveedoresFactory = ProveedoresFactory;

      getMarcas();
      getSucursales();

      function getMarcas() {
        var params = {};
        params.view = "BaseList";

        MarcasFactory.all(params).$promise.then(function (response) {
          $scope.marcas = response;
          $scope.marcas.push({ 'id': '', 'codigo': '', 'descripcion': 'Todos' });
          $scope.marca = $scope.marcas[$scope.marcas.length - 1];
        });
      }

      function getSucursales() {
        var params = {};
        params.view = "BaseList";
        SucursalesFactory.all(params).$promise.then(function (response) {
          $scope.listaSucursales = response;
          $scope.listaSucursales.push({ 'id': '', 'codigo': '', 'nombre': 'Todos' });
          $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];
        });
      }

      $scope.limpiarFiltros = function () {
        $scope.fechaDesde = undefined;
        $scope.fechaHasta = undefined;
        $scope.marca = $scope.marcas[$scope.marcas.length - 1];
        $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];
        $scope.proveedor = undefined;
      };

      $scope.generarReporte = function () {
        if ($scope.fechaDesde == undefined || $scope.fechaHasta == undefined) {
          notify({ message: 'Los filtros Fecha desde y Fecha hasta son obligatorios.', classes: 'alert-danger', position: 'right' });
        } else if ($scope.fechaDesde > $scope.fechaHasta) {
          notify({ message: 'La fecha desde no puede ser mayor a la fecha hasta.', classes: 'alert-danger', position: 'right' });
        } else {
          console.log("suc ", $scope.sucursal)
          var filtersArr = [];
          //fecha desde
          if ($scope.fechaDesde) {
            var myDate = $filter('date')($scope.fechaDesde, "MM/dd/yyyy");
            filtersArr.push("fechaDesde=" + myDate);
          }
          //fecha hasta
          if ($scope.fechaHasta) {
            var myDate = $filter('date')($scope.fechaHasta, "MM/dd/yyyy");
            filtersArr.push("fechaHasta=" + myDate);
          }
          //sucursal
          if ($scope.sucursal) {
            filtersArr.push("sucursal=" + $scope.sucursal.id);
          }
          //proveedor
          if ($scope.proveedor) {
            filtersArr.push("proveedor=" + $scope.proveedor.id);
          }else{
            filtersArr.push("proveedor=" + null);
          }
          //marca
          if ($scope.marca) {
            filtersArr.push("marca=" + $scope.marca.id);
          }

          filtersArr.push("existencia=" + $scope.existencia);

          var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("rotacionproducto", 'pdf', filtersArr);
          $window.open(pdfDownloadURL, '_blank');
        }

      };

      $scope.generarCSV = function () {
        if ($scope.fechaDesde == undefined || $scope.fechaHasta == undefined) {
          notify({ message: 'Los filtros Fecha desde y Fecha hasta son obligatorios.', classes: 'alert-danger', position: 'right' });
        } else if ($scope.fechaDesde > $scope.fechaHasta) {
          notify({ message: 'La fecha desde no puede ser mayor a la fecha hasta.', classes: 'alert-danger', position: 'right' });
        } else {
          var filtersObj = {};
          filtersObj.fechaDesde = '';
          filtersObj.fechaHasta ='';
          filtersObj.sucursal ='';
          filtersObj.proveedor ='';
          filtersObj.marca ='';
          filtersObj.existencia ='';
          //fecha desde
          if ($scope.fechaDesde) {
            var myDate = $filter('date')($scope.fechaDesde, "MM/dd/yyyy");
            filtersObj.fechaDesde = myDate;
          }
          //fecha hasta
          if ($scope.fechaHasta) {
            var myDate = $filter('date')($scope.fechaHasta, "MM/dd/yyyy");
            filtersObj.fechaHasta = myDate;
          }
          //sucursal
          if ($scope.sucursal.id != "") {
            filtersObj.sucursal = $scope.sucursal.id;
          }else{
            filtersObj.sucursal = 0;
          }
          //proveedor
          if ($scope.proveedor != undefined) {
            filtersObj.proveedor = $scope.proveedor.id;
          }else{
            filtersObj.proveedor = 0;
          }
          //marca
          if ($scope.marca.id != "") {
            filtersObj.marca = $scope.marca.id;
          }else {
            filtersObj.marca = 0;
          }
          filtersObj.existencia = $scope.existencia;

          ProductoAlmacenFactory.getRotacionProductoCSV(filtersObj).then(function (response) {
            var fileName = response.data.fileName;
            $window.open(baseurl.getPublicBaseUrl() + fileName);
          });
        }
      };
    }]);

'use strict';

angular.module('qualita.productos')
  .controller('RecursoFormCtrl', RecursoFormCtrl);

RecursoFormCtrl.$inject = ['$rootScope', '$scope', 'RecursoFactory', '$location', '$state', 'filterFactory',
  'recursoPrepService', 'notify', 'formFactory', 'ProductosLangFactory', 'TiposFactory', 'TiposTipoFactory', 'UnidadMedidaFactory',
'$filter'];

function RecursoFormCtrl($rootScope, $scope, RecursoFactory, $location, $state, filterFactory,
  recursoPrepService, notify, formFactory, ProductosLangFactory, TiposFactory, TiposTipoFactory, UnidadMedidaFactory,
  $filter) {


  var vm = this;
  activate();
  vm.cancel = cancel;
  vm.submit = submit;
  vm.cambioTipoRecurso = cambioTipoRecurso;
  vm.actualizarTotalHombre = actualizarTotalHombre;
  vm.actualizarTotalRecurso = actualizarTotalRecurso;

  function activate() {
    vm.TiposFactory = TiposFactory;
    vm.recurso = recursoPrepService;

    ProductosLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;

      vm.estados = TiposTipoFactory.get("tipos_estado_recursos");
      vm.tiposRecursos = TiposTipoFactory.get("tipos_recursos");

      var filtros = {};
      filtros.search = filterFactory.single({
        path: "esBase",
        equals: true
      }).value();
      filtros.view = "BaseList";

      vm.unidadesMedida = UnidadMedidaFactory.all(filtros);

      if ($state.is("app.recursos.new")) {
        activateNew();
      } else if ($state.is("app.recursos.edit")) {
        activateEdit();
      } else if ($state.is("app.recursos.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });

  }

  function cambioTipoRecurso() {
    if (vm.recurso.tipoRecurso.codigo === 'tipo_recurso_mano_obra') {
      vm.hombre = true;
      vm.maquina = false;
    }
    if (vm.recurso.tipoRecurso.codigo === 'tipo_recurso_maquina') {
      vm.hombre = false;
      vm.maquina = true;
    }
  }

  function actualizarTotalHombre() {
    vm.recurso.costoTotalHombre = parseFloat(vm.recurso.costoEstandar2Hombre) + parseFloat(vm.recurso.costoEstandar3Hombre) 
    + parseFloat(vm.recurso.costoEstandar4Hombre) + parseFloat(vm.recurso.costoEstandar5Hombre) + parseFloat(vm.recurso.salario);
  }

  function actualizarTotalRecurso() {
    vm.recurso.costoTotalRecurso = parseFloat(vm.recurso.mantenimiento) + parseFloat(vm.recurso.energiaElectrica) 
    + parseFloat(vm.recurso.depreciacion) + parseFloat(vm.recurso.costoEstandar4Maquina) + parseFloat(vm.recurso.costoEstandar5Maquina);
  }

  function activateNew() {
    vm.title = 'Nuevo Recurso';
    vm.recurso = {};
    vm.recurso.salario = 0;
    vm.recurso.costoEstandar2Hombre = 0;
    vm.recurso.costoEstandar3Hombre = 0;
    vm.recurso.costoEstandar4Hombre = 0;
    vm.recurso.costoEstandar5Hombre = 0;
    vm.recurso.mantenimiento = 0;
    vm.recurso.energiaElectrica = 0;
    vm.recurso.depreciacion = 0;
    vm.recurso.costoEstandar4Maquina = 0;
    vm.recurso.costoEstandar5Maquina = 0;
    vm.recurso.costoTotalHombre = 0;
    vm.recurso.costoTotalRecurso = 0;
  }

  function activateEdit() {
    vm.title = 'Editar Recurso';
    vm.recurso = recursoPrepService;
    vm.entidadId = vm.recurso.id;
    vm.entidad = "Recurso";
    if (vm.recurso.tipoRecurso.codigo === 'tipo_recurso_mano_obra') {
      vm.hombre = true;
      vm.maquina = false;
    }
    if (vm.recurso.tipoRecurso.codigo === 'tipo_recurso_maquina') {
      vm.hombre = false;
      vm.maquina = true;
    }
  }

  function activateView() {
    vm.title = 'Ver Recurso';
    vm.recurso = recursoPrepService;
    vm.entidadId = vm.recurso.id;
    vm.entidad = "Recurso";
    vm.view = true;
    if (vm.recurso.tipoRecurso.codigo === 'tipo_recurso_mano_obra') {
      vm.hombre = true;
      vm.maquina = false;
    }
    if (vm.recurso.tipoRecurso.codigo === 'tipo_recurso_maquina') {
      vm.hombre = false;
      vm.maquina = true;
    }
  }

  function submit() {
    vm.submited = true;
    if ($scope.RecursoForm.$valid) {
      if (vm.recurso.fechaDesde != undefined && vm.recurso.fechaHasta != undefined && vm.recurso.fechaDesde > vm.recurso.fechaHasta) {
        notify({ message: 'La fecha desde no puede ser mayor a la fecha hasta.', classes: 'alert-danger', position: 'right' });
      } else {
        formFactory.defaultNSFSubmit($scope.RecursoForm, RecursoFactory, vm.recurso, errorHandler).then(function (response) {
          $location.path('/recursos');
        }, function (error) {
          console.log(error);
        });
      }
    } else {
      notify({ message: 'Campos Incorrectos', classes: 'alert-danger', position: 'right' });
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function (error) {
      _.forEach(e.data, function(error) {
        if (error.constraint == 'codigo') {
          msg += '\n\n' + 'Ya existe un Recurso con el mismo codigo' + "\n";
        } else {
          msg += '\n\n' + error.message + '.';
        }
      });
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function cancel() {
    $location.path("/recursos");
  }

  $scope.resource = 'recursos'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

angular.module('qualita.productos')
    .controller('RecursoIndexCtrl', ["$state", "$scope", "RecursoFactory", "filterFactory", "ReportTicketFactory", "$window", "ProductosLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "$modal", function ($state, $scope, RecursoFactory, filterFactory,
        ReportTicketFactory, $window, ProductosLangFactory, $filter, UtilFactory, CsvFactory, baseurl, $modal) {

        var dateRender = function (data, type, full) {
            if (data) {
                return moment.utc(data).format('DD/MM/YYYY');
            } else {
                return "";
            }
        };

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        var staticFilter = {};
        staticFilter = {
            search: filterFactory
                .and([
                {
                    path: "id",
                    sortDesc: "true"
                },
                {
                    path: "id",
                    isNotNull: "true"
                }])
                .value()
        };

        ProductosLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'codigo', 'descripcion', 'tipoRecurso.descripcion', 'estado.descripcion'];
            $scope.options = {
                'staticFilter': staticFilter,
                'resource': 'recursos',
                'title': 'Recurso',
                'factory': RecursoFactory,
                'view': 'BaseList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'id', 'title': translations.ID, visible: false },
                    { 'data': 'codigo', 'title': 'Código', 'class': 'dt-left' },
                    { 'data': 'descripcion', 'title': 'Descripción', 'class': 'dt-left' },
                    { 'data': 'tipoRecurso.descripcion', 'title': 'Tipo Recurso', 'render': renderEmptyData, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipos_recursos' },
                    { 'data': 'estado.descripcion', 'title': 'Estado', 'render': renderEmptyData, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/estados_recurso' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraMenuOptions':
                    [
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("recursos", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoRecursos', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }

                    ],
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);

'use strict';

angular.module('qualita.productos')
  .factory('RecursoFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var Recurso = $resource(baseurl.getBaseUrl() + '/recursos/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return Recurso.query(params);
      },

      get: function (id, view) {
        return Recurso.get({ id: id, view: view != undefined ? view : 'base' });
      },

      create: function (attrs) {
        return new Recurso(attrs);
      },

      save: function (recurso) {
        return (recurso.id) ? recurso.$update() : recurso.$save();
      },

      remove: function (recurso) {
        return recurso.$remove();
      }
    };
  }]);

'use strict';

/**
 * @ngdoc function
 * @name 
 * @description
 * # MaterialesFormCtrl
 * Controller of the rasApp
 */
angular.module('qualita.stock')
  .controller('MaterialesFormCtrl', MaterialesFormCtrl);

MaterialesFormCtrl.$inject = ['$scope', 'materialesPrepService', '$location', '$state', '$timeout',
  'MaterialesFactory', 'ModelTrimmer', '$rootScope', 'StockLangFactory', 'notify', 'filterFactory', 'TiposFactory',
  '$filter', 'TiposTipoFactory', 'ProductosFactory', 'UtilFactory'];

function MaterialesFormCtrl($scope, materialesPrepService, $location, $state, $timeout, MaterialesFactory,
  ModelTrimmer, $rootScope, StockLangFactory, notify, filterFactory, TiposFactory, $filter, TiposTipoFactory, ProductosFactory, UtilFactory) {

  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.delayRequestProducto = delayRequestProducto;

  activate();

  function activate() {
    StockLangFactory.getTranslations().then(function (translations) {
      vm.translations = translations;
      if ($state.is('app.materiales.new')) {
        activateNew();
      } else if ($state.is('app.materiales.edit')) {
        activateEdit();
      } else if ($state.is('app.materiales.view')) {
        activateView();
      }
      $rootScope.isProcessing = false;
      vm.utilizaciones = TiposTipoFactory.get("tipos_utilizacion");
      vm.estados = TiposTipoFactory.get("tipos_estado_materiales");
    });
  }

  function activateNew() {
    vm.title = 'Nuevo Material';
    vm.materiales = {};
    TiposFactory.utilizacionMateriales().then(function (data) {
      vm.materiales.utilizacion = data.data[0];
    });
    TiposFactory.estadoMateriales().then(function (data) {
      vm.materiales.estado = data.data[0];
    });
  }

  function activateView() {
    vm.title = 'Ver Material';
    vm.materiales = materialesPrepService;
    vm.entidadId = vm.materiales.id;
    vm.entidad = "Materiales";
    vm.view = true;
  }

  function activateEdit() {
    vm.title = 'Editar Material';
    vm.materiales = materialesPrepService;
    vm.entidadId = vm.materiales.id;
    vm.entidad = "Materiales";
  }

  function delayRequestProducto(searchValue) {
    var userInputFilter = {};
    var staticFilter = [];
    if (searchValue.length >= 3) {

      var filterNombre = staticFilter.slice()
      var filterCodigoBarras = staticFilter.slice()
      var filterCodigoInterno = staticFilter.slice()


      filterNombre.push({
        path: "nombre",
        like: searchValue
      },
        {
          path: "estado.codigo",
          notEquals: 'inactivo'
        }, {
        path: "tieneListaMateriales",
        equals: false
      }
      )

      filterCodigoBarras.push({
        path: "codigoBarrasPrincipal.codigoBarras",
        like: searchValue
      },
        {
          path: "estado.codigo",
          notEquals: 'inactivo'
        }, {
        path: "tieneListaMateriales",
        equals: false
      })

      filterCodigoInterno.push({
        path: "codigo",
        like: searchValue
      },
        {
          path: "estado.codigo",
          notEquals: 'inactivo'
        }, {
        path: "tieneListaMateriales",
        equals: false
      })  

      userInputFilter = {
        search: filterFactory.or([
          filterFactory.and(filterNombre).value(),
          filterFactory.and(filterCodigoBarras).value(),
          filterFactory.and(filterCodigoInterno).value()
        ]).value()
      }

      $scope.recuperarProducto(userInputFilter)
    } else if (searchValue.length > 0 && searchValue.length < 3) {

      var filterCodigoInterno = staticFilter.slice()
      filterCodigoInterno.push({
        path: "codigo",
        like: searchValue
      },{
        path: "tieneListaMateriales",
        equals: false
      })

     

      userInputFilter = {
        search: filterFactory.or([
          filterFactory.and(filterCodigoInterno).value()
        ]).value()
      }
      $scope.recuperarProducto(userInputFilter)
    } else {
      vm.productos = undefined
    }
  }

  $scope.recuperarProducto = function (userInputFilter) {
    var finalFilter = UtilFactory.createNestedFilters(
      $scope.finalFilters, userInputFilter);
    vm.productos = ProductosFactory.allForSelect(userInputFilter)
  };


  function submit() {
    vm.submited = true;
    if ($scope.MaterialesForm.$valid) {
      $rootScope.isProcessing = true;
      submitMateriales().then(function (response) {
        MaterialesFactory.get(response.id, "BaseList").$promise.then(function (material) {
          MaterialesFactory.setIdMaterial(material.id);
          MaterialesFactory.setMaterial(material);
          $state.go("app.materiales.proceso.listamateriales.list", {
            idMaterial: material.id,
            modeAccess: ""
          });
        });
      }, function (error) {
        $rootScope.isProcessing = false;
        var msg = 'Ha ocurrido un error';
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      });
    } else {
      notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right' });
    }

    function submitMateriales() {
      var materiales = MaterialesFactory.create(vm.materiales);
      return MaterialesFactory.save(materiales);
    }
  }

  function cancel() {
    $location.path("/materiales");
  }

  $scope.resource = 'materiales'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}
'use strict';

angular.module('qualita.stock')
  .controller('MaterialesIndexCtrl', MaterialesIndexCtrl);

MaterialesIndexCtrl.$inject = ["AuthorizationService", "$state",
  "filterFactory",
  "ReportTicketFactory",
  "$window",
  "StockLangFactory",
  "MaterialesFactory",
  "CsvFactory", "UtilFactory", "baseurl", "$filter", "$modal", "$scope", "notify"];

function MaterialesIndexCtrl(AuthorizationService, $state,
  filterFactory,
  ReportTicketFactory,
  $window,
  StockLangFactory, MaterialesFactory,
  CsvFactory, UtilFactory, baseurl, $filter, $modal, $scope, notify) {

  var vm = this;

  var defaultColumnOrder = ['id', 'producto.nombre', 'utilizacion.descripcion', 'estado.descripcion'];

  StockLangFactory.getTranslations().then(function (translations) {
    var staticFilter = undefined;
    staticFilter = {};
    staticFilter.search = filterFactory
      .and([
        {
          path: "id",
          sortDesc: "true"
        },
        {
          path: "id",
          isNotNull: "true"
        }
      ])
      .value();
    vm.options = {
      'staticFilter': staticFilter,
      'resource': 'materiales',
      'title': 'Materiales',
      'factory': MaterialesFactory,
      'view': 'MaterialesList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        { 'data': 'id', 'title': 'Código' },
        { 'data': 'producto.nombre', 'title': 'Producto' },
        { 'data': 'utilizacion.descripcion', 'title': 'Utilización', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipos_utilizacion' },
        { 'data': 'estado.descripcion', 'title': 'Estado', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipos_estado_materiales' }
      ],
      'hasOptions': true,
      'hideAddMenu': false,
      'hideEditMenu': true,
      'hideViewMenu': false,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'extraRowOptions': [
        {
          templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter("translate")("EDIT") +
            "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          customAttribute: ["estado"],
          conditionName: "canEditar",
          conditionDef: function (atributos) {
            var estado = atributos[0];
            return (AuthorizationService.hasPermission("update_materiales") && estado.codigo === 'tipo_estado_materiales_activado');
          },
          functionName: "editar",
          functionDef: function (itemId) {
            $state.go('app.materiales.edit', { id: itemId });
          }
        },
        {
          templateToRender: "<button title=' Lista de Materiales" +
            "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='agregarDetalle(<%=dataId%>)' ng-class='{ hidden : false}'> <span class='glyphicon glyphicon-plus'></span> </button>",
          functionName: "agregarDetalle",
          conditionName: "canCreateDetalle",
          conditionDef: function () {
            return true;
          },
          functionDef: function (itemId) {
            MaterialesFactory.get(itemId, "BaseList").$promise.then(function (material) {
              MaterialesFactory.setIdMaterial(material.id);
              MaterialesFactory.setMaterial(material);
              console.log("itemId ", itemId)
              $state.go("app.materiales.proceso.listamateriales.list", {
                idMaterial: itemId,
                modeAccess: ""
              });
            });
          }
        },
        {
          templateToRender: "<button title='" +
            "ACTIVAR" +
            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='activar(<%=dataId%>)' ng-class='{ hidden : !canActivar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ok'></span> </button>",
          functionName: "activar",
          conditionName: "canActivar",
          customAttribute: ["estado"],
          conditionDef: function (atributos) {
            var estado = atributos[0];

            if (AuthorizationService.hasPermission("activar_materiales") === true && estado.codigo === 'tipo_estado_materiales_desactivado') {
              return true;
            } else {
              return false;
            }
          },

          functionDef: function (itemId) {
            if (AuthorizationService.hasPermission("activar_materiales") === true) {
              MaterialesFactory.get(itemId, "MaterialesForm").$promise.then(function (response) {
                if (response) {
                  $scope.mensajeModal = 'Esta seguro de querer activar la Lista de Materiales?';
                  $scope.tituloModal = 'Activar';
                  var modalInstance = $modal.open({
                    templateUrl: "views/directives/confirmation-modal.html",
                    scope: $scope
                  });

                  $scope.cancel = function () {
                    modalInstance.dismiss("cancel");
                  };

                  $scope.ok = function (id) {
                    MaterialesFactory.activar(itemId).then(function (response) {
                      modalInstance.close();
                      $state.go($state.current, {}, { reload: true });
                    }, function (error) {
                      var mensaje = "<span>";
                      _.forEach(error.data, function (err) {
                        mensaje = mensaje + err.message + "<br/>";
                      });
                      mensaje = mensaje + "</span>";
                      notify({
                        messageTemplate: mensaje,
                        classes: "alert-danger",
                        position: "right",
                        templateUrl: ""
                      });
                    });
                  };
                }
              });
            } else {
              notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
            }
          }
        },
        {
          templateToRender: "<button title='" +
            'Desactivar' +
            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='desactivar(<%=dataId%>)' ng-class='{ hidden : !canDesactivar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
          functionName: "desactivar",
          conditionName: "canDesactivar",
          customAttribute: ["estado"],
          conditionDef: function (atributos) {
            var estado = atributos[0];
            if (AuthorizationService.hasPermission("desactivar_materiales") === true && estado.codigo === 'tipo_estado_materiales_activado') {
              return true;
            } else {
              return false;
            }
          },

          functionDef: function (itemId) {
            if (AuthorizationService.hasPermission("desactivar_materiales") === true) {
              MaterialesFactory.get(itemId, "MaterialesForm").$promise.then(function (response) {
                if (response) {
                  $scope.mensajeModal = 'Esta seguro de querer desactivar la Lista de Materiales?';
                  $scope.tituloModal = 'Desactivar';
                  var modalInstance = $modal.open({
                    templateUrl: "views/directives/confirmation-modal.html",
                    scope: $scope
                  });

                  $scope.cancel = function () {
                    modalInstance.dismiss("cancel");
                  };

                  $scope.ok = function (id) {
                    MaterialesFactory.desactivar(itemId).then(function (response) {
                      modalInstance.close();
                      $state.go($state.current, {}, { reload: true });
                    }, function (error) {
                      var mensaje = "<span>";
                      _.forEach(error.data, function (err) {
                        mensaje = mensaje + err.message + "<br/>";
                      });
                      mensaje = mensaje + "</span>";
                      notify({
                        messageTemplate: mensaje,
                        classes: "alert-danger",
                        position: "right",
                        templateUrl: ""
                      });
                    });
                  };
                }
              });
            } else {
              notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
            }
          }
        }
      ],
      'extraMenuOptions': [
        {
          'title': 'CSV',
          'icon': 'glyphicon glyphicon-export',
          'showCondition': function () { return true; },
          'action': function () {
            var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

            CsvFactory.csv("materiales", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
              .then(function (response) {
                console.log("response de csv: ", response);
                var fileName = response.data.fileName;
                $window.open(baseurl.getPublicBaseUrl() + fileName);
              });
          }
        }
      ]
    };
  });

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}

'use strict';

angular.module('qualita.stock')
  .factory('MaterialesFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var Materiales = $resource(baseurl.getBaseUrl() + '/materiales/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      },
      activar: {
        method: 'PUT',
        url: baseurl.getBaseUrl() + "/materiales/activar/:id"
      },
      desactivar: {
        method: 'PUT',
        url: baseurl.getBaseUrl() + "/materiales/desactivar/:id"
      }
    });
    var materiales = null;
    var idMaterial = -1;
    var enabledStates = [true];

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return Materiales.query(params);
      },
      get: function (id, view) {
        //if (typeof(view)==='undefined') view = "base";
        return Materiales.get({ id: id, view: view != undefined ? view : 'base' });
      },
      create: function (attrs) {
        return new Materiales(attrs);
      },
      save: function (mat) {
        return (mat.id) ? mat.$update() : mat.$save();
      },
      remove: function (mat) {
        return mat.$remove();
      },
      getIdMaterial: function () {
        return idMaterial;
      },

      setIdMaterial: function (id) {
        idMaterial = id;
      },

      getMaterial: function () {
        this.updateStates();
        return materiales;
      },

      setMaterial: function (mat) {
        if (mat.id) {
          materiales = mat;
          this.updateStates();
        }
      },
      updateStates: function () {
        enabledStates = [true]
      },
      getEnabledStates: function () {
        return enabledStates;
      },
      activar: function(id) {
        return Materiales.activar({ id: id }).$promise;
      },
      desactivar: function(id) {
        return Materiales.desactivar({ id: id }).$promise;
      }
    };
  }]);
'use strict';

angular.module('qualita.stock')
  .controller('ListaMaterialesFormCtrl', ListaMaterialesFormCtrl);

ListaMaterialesFormCtrl.$inject = ['$scope', 'listaMaterialesPrepService', '$location', '$state', '$timeout',
  'ListaMaterialesFactory', 'ModelTrimmer', '$rootScope', 'StockLangFactory', 'notify', 'filterFactory', 'TiposFactory',
  '$filter', 'TiposTipoFactory', 'ProductosFactory', 'UtilFactory', 'RecursoFactory', 'MaterialesFactory'];

function ListaMaterialesFormCtrl($scope, listaMaterialesPrepService, $location, $state, $timeout, ListaMaterialesFactory,
  ModelTrimmer, $rootScope, StockLangFactory, notify, filterFactory, TiposFactory, $filter, TiposTipoFactory, ProductosFactory, UtilFactory, RecursoFactory, MaterialesFactory) {

  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.tipoDetalleSelected = tipoDetalleSelected;
  vm.changeProducto = changeProducto;
  vm.delayRequestProducto = delayRequestProducto;
  vm.changeRecurso = changeRecurso;
  vm.removeItemFromArray = removeItemFromArray;
  vm.agregarDetalle = agregarDetalle;

  function agregarDetalle() {
    vm.listamateriales.listaMaterialesDetalles.push({
      listaMaterialesDetalles: [{}]
    });
    var rowNumber = vm.listamateriales.listaMaterialesDetalles.length - 1;
    $timeout(function () {
      $scope.$broadcast('newItemAdded');
    }, 20);
  }


  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function (item) {
      return item === elemento;
    });
    $timeout(function () {
      $scope.$broadcast('newItemRemoved');
    }, 20);
  }

  function changeRecurso(detalle) {
    detalle.unidadMedida = detalle.recurso.tipoUnidadMedida;
  }

  function delayRequestProducto(searchValue) {
    var userInputFilter = {}
    if (searchValue.length >= 3) {

      var filterNombre = [];
      var filterCodigoBarras = [];
      var filterCodigoInterno = [];

      filterNombre.push({
        path: "nombre",
        like: searchValue
      },
        {
          path: 'estado.codigo',
          equals: 'activo'
        },
        {
          path: "locaciones.id",
          equals: $rootScope.AuthParams.locacionSeleccionada.id
        },{
          path: 'controlStock',
          like: 'true'
      })

      filterCodigoBarras.push({
        path: "codigoBarrasPrincipal.codigoBarras",
        like: searchValue
      },
        {
          path: 'estado.codigo',
          equals: 'activo'
        },
        {
          path: "locaciones.id",
          equals: $rootScope.AuthParams.locacionSeleccionada.id
        },{
          path: 'controlStock',
          like: 'true'
      })

      filterCodigoInterno.push({
        path: "codigo",
        like: searchValue
      },
        {
          path: 'estado.codigo',
          equals: 'activo'
        },
        {
          path: "locaciones.id",
          equals: $rootScope.AuthParams.locacionSeleccionada.id
        },{
          path: 'controlStock',
          like: 'true'
      })

   

      userInputFilter = {
        search: filterFactory.or([
          filterFactory.and(filterNombre).value(),
          filterFactory.and(filterCodigoBarras).value(),
          filterFactory.and(filterCodigoInterno).value()
        ]).value()
      }

      recuperarProducto(userInputFilter)
    } else {
      vm.productos = undefined
    }
  }

  function recuperarProducto(userInputFilter) {
    var finalFilter = UtilFactory.createNestedFilters(
      vm.finalFilters, userInputFilter)

    vm.productos = ProductosFactory.allForSelect(userInputFilter)
  }

  function changeProducto(detalle) {
    detalle.unidadMedida = detalle.producto.unidadMedidaBase;
    //if (detalle.producto.criterioControl.codigo === "series") {
      //detalle.cantidad = 1;
    //}
  }

  function tipoDetalleSelected(detalle) {
    delete detalle.producto;
    delete detalle.recurso;
    delete detalle.unidadMedida;
  }

  activate();

  function activate() {
    StockLangFactory.getTranslations().then(function (translations) {
      vm.translations = translations;

      TiposFactory.estadoListaMateriales().then(function (data) {
        _.forEach(data.data, function (data) {
          if (data.codigo == 'tipo_estado_lista_materiales_activada') {
            vm.listamateriales.estado = data;
          }
        });
      });

      //vm.estados = TiposTipoFactory.get("tipos_estado_lista_materiales");
      vm.estadosDetalle = TiposTipoFactory.get("tipos_estado_lista_materiales_detalle");
      vm.tiposDetalles = TiposTipoFactory.get("tipos_detalle_materiales");
      var filtroRecursos = {
        search: filterFactory
            .and([
                {
                    path: "estado.codigo",
                    equals: "tipo_estado_activado"
                }
            ])
            .value()
      };
      vm.recursos = RecursoFactory.all(filtroRecursos);

      var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
        'lineaProducto.descripcion', 'marca.descripcion'
      ];
      vm.factoryProductos = ProductosFactory;

      var staticFilterProducto = {};

      staticFilterProducto = {
        search: filterFactory.and([{
          path: 'estado.codigo',
          equals: 'activo'
        },
        {
          path: "locaciones.id",
          equals: $rootScope.AuthParams.locacionSeleccionada.id
        }, {
          path: 'controlStock',
          like: 'true'
        }]).value()
      };

      vm.opcionesProductosDt = {
        'staticFilter': staticFilterProducto,
        'resource': 'productos',
        'title': 'Productos',
        'view': "ProductoList",
        'factory': ProductosFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'codigo', 'title': $filter('translate')('ID'), visible: false },
          { 'data': 'nombre', 'title': $filter('translate')('NAME') },
          { 'data': 'claseProducto.descripcion', 'title': $filter('translate')('PRODUCT_CLASS') },
          { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
          { 'data': 'lineaProducto.nombre', 'title': $filter('translate')('LINE') },
          { 'data': 'marca.descripcion', 'title': $filter('translate')('BRAND'), 'renderWith': 'emptyRender' },
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
      };

      var defaultColumnOrderRecurso = [
        "id",
        "codigo",
        "descripcion",
        "tipoRecurso.descripcion",
        "estado.descripcion"
      ];

      var staticFilterRecurso = {};

      staticFilterRecurso = {
        search: filterFactory.and([{
          path: "estado.codigo",
          equals: "tipo_estado_activado"
        }]).value()
      };

      vm.opcionesRecursoDt = {
        staticFilter: staticFilterRecurso,
        resource: "recursos",
        view: "RecursoList",
        title: "Recursos",
        factory: RecursoFactory,
        defaultColumnOrder: defaultColumnOrderRecurso,
        columns: [
          { data: "id", title: 'Código Interno', visible: false },
          { data: "codigo", title: 'Código' },
          { data: "descripcion", title: 'Descripción' },
          { data: "tipoRecurso.descripcion", title: 'Tipo Recurso' },
          { data: "estado.descripcion", title: 'Estado' }
        ],
        hasOptions: true,
        defaultOrderColumn: 0,
        defaultOrderDir: "desc"
      };


      if ($state.is('app.materiales.proceso.listamateriales.new')) {
        activateNew();
      } else if ($state.is('app.materiales.proceso.listamateriales.edit')) {
        activateEdit();
      } else if ($state.is('app.materiales.proceso.listamateriales.view')) {
        activateView();
      }
      $rootScope.isProcessing = false;
    });
  }

  function activateNew() {
    vm.title = 'Nueva Lista de Materiales';
    vm.listamateriales = { listaMaterialesDetalles: [{}] };
    vm.curDate = new Date();
  }

  function activateView() {
    vm.title = 'Ver Lista Material';
    vm.listamateriales = listaMaterialesPrepService;
    vm.entidadId = vm.listamateriales.id;
    vm.entidad = "ListaMateriales";
    vm.view = true;
    vm.listamateriales.validoDesde = new Date(vm.listamateriales.validoDesde);
    vm.listamateriales.validoHasta = new Date(vm.listamateriales.validoHasta);
  }

  function activateEdit() {
    vm.title = 'Editar Lista de Materiales';
    vm.listamateriales = listaMaterialesPrepService;
    vm.entidadId = vm.listamateriales.id;
    vm.entidad = "ListaMateriales";
    vm.listamateriales.validoDesde = new Date(vm.listamateriales.validoDesde);
    vm.listamateriales.validoHasta = new Date(vm.listamateriales.validoHasta);
  }

  function submit() {
    vm.submited = true;

    if ($scope.ListaMaterialesForm.$valid) {
      $rootScope.isProcessing = true;
      var fechasDetallesOk = true;
      angular.forEach(vm.listamateriales.listaMaterialesDetalles, function(detalle) {
        if (detalle.validoDesde != undefined && detalle.validoHasta != undefined && detalle.validoDesde > detalle.validoHasta) {
          fechasDetallesOk = false;
        }
      });
      if (vm.listamateriales.validoDesde != undefined && vm.listamateriales.validoHasta != undefined && vm.listamateriales.validoDesde > vm.listamateriales.validoHasta) {
        notify({ message: 'La fecha de válidez desde no puede ser mayor a la fecha de válidez hasta.', classes: 'alert-danger', position: 'right' });
        $rootScope.isProcessing = false;
      } else if(!fechasDetallesOk){
        notify({ message: 'La fecha de válidez desde no puede ser mayor a la fecha de válidez hasta(Detalles).', classes: 'alert-danger', position: 'right' });
        $rootScope.isProcessing = false;
      }else{
        vm.materiales = MaterialesFactory.getMaterial();
        vm.listamateriales.materiales = vm.materiales;

        submitListaMateriales().then(function () {
          $state.go('app.materiales.proceso.listamateriales.list');
        }, function (error) {
          $rootScope.isProcessing = false;
          console.log(error);
          var msg = "";
          _.forEach(error.data, function (e) {
            msg += '\n\n' + e.message + '.';
          });
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
        });
      }
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
      $rootScope.isProcessing = false;
    }
  }

  function submitListaMateriales() {
    var resource = ListaMaterialesFactory.create(vm.listamateriales);
    return ListaMaterialesFactory.save(resource);
  }

  function cancel() {
    $state.go("app.materiales.proceso.listamateriales.list");
  }

  $scope.resource = 'listamateriales'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

angular.module('qualita.stock')
  .controller('ListaMaterialesListCtrl', ListaMaterialesListCtrl);

ListaMaterialesListCtrl.$inject = ["AuthorizationService", "$state",
  "filterFactory",
  "ReportTicketFactory",
  "$window",
  "StockLangFactory",
  "ListaMaterialesFactory",
  "CsvFactory", "UtilFactory", "baseurl", "$filter", "$modal", "$scope", "MaterialesFactory","notify"];

function ListaMaterialesListCtrl(AuthorizationService, $state,
  filterFactory,
  ReportTicketFactory,
  $window,
  StockLangFactory, ListaMaterialesFactory,
  CsvFactory, UtilFactory, baseurl, $filter, $modal, $scope, MaterialesFactory,notify) {

  var vm = this;

  var defaultColumnOrder = ['id', 'descripcion', 'cantidadBase', 'validoDesde', 'validoHasta','estado.descripcion'];

  var monedaRender = function (data, type, row) {
    var campoMoneda = null;

    if (data != undefined) {
      data = parseFloat(data).toFixed(0);
      return (
        data
          .toString()
          .replace(".", ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      );
    } else {
      return "";
    }
  };

  var staticFilter = undefined;
  staticFilter = {};
  staticFilter.search = filterFactory.or([
    filterFactory.and([
      {
        path: "materiales.id",
        equals: MaterialesFactory.getIdMaterial()
      }
    ]).value()
  ]).value();


  StockLangFactory.getTranslations().then(function (translations) {
    vm.options = {
      'staticFilter': staticFilter,
      'resource': 'listamateriales',
      'title': 'Lista de Materiales',
      'factory': ListaMaterialesFactory,
      'view': 'ListaMaterialesList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        { 'data': 'id', 'title': 'Código', visible: true },
        { 'data': 'descripcion', 'title': 'Descripción' },
        { 'data': 'cantidadBase', 'title': 'Cantidad', 'Type': 'number-range', 'render': monedaRender, 'class': "dt-leftt" },
        { 'data': "validoDesde", 'title': 'Válido Desde', 'renderWith': "dateRender", 'dateFormat': "DD/MM/YYYY", 'class': "dt-left", 'type': "date-range",visible: false},
        { 'data': "validoHasta", 'title': 'Válido Hasta', 'renderWith': "dateRender", 'dateFormat': "DD/MM/YYYY", 'class': "dt-left", 'type': "date-range",visible: false},
        { 'data': 'estado.descripcion', 'title': 'Estado', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipos_estado_lista_materiales',visible: true,'renderWith': 'emptyRender'}
      ],
      'hasOptions': true,
      'hideAddMenu': true,
      'hideEditMenu': true,
      'hideViewMenu': true,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'extraMenuOptions':
        [
          {
            title: translations.NEW,
            icon: "glyphicon glyphicon-plus",
            showCondition: function () {
              return (AuthorizationService.hasPermission("create_listamateriales"));
            },
            action: function () {
              if (AuthorizationService.hasPermission("create_listamateriales")) {
                $state.go("app.materiales.proceso.listamateriales.new");
              } else {
                var msg = 'No posee permiso para crear Lista de Materiales';
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
              }
            }
          }
        ],
      'extraRowOptions': [
        {
          templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter("translate")("EDIT") +
            "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          customAttribute: "estado",
          conditionName: "canEditar",
          conditionDef: function() {
            return (AuthorizationService.hasPermission("update_listamateriales"));
          },
          functionName: "editar",
          functionDef: function (itemId) {
            console.log("item  ", itemId)
            $state.go('app.materiales.proceso.listamateriales.edit', { id: itemId });
          }
        },
        {
            templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                $filter("translate")("VIEW") +
                "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
            functionName: "ver",
            functionDef: function (itemId) {
                if (AuthorizationService.hasPermission("index_listamateriales")) {
                    $state.go("app.materiales.proceso.listamateriales.view", { id: itemId });
                } else {
                    notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                }
            },
            conditionName: "canVer",
            conditionDef: function () {
                return true;
            }
        }
      ]
    };
  });
}

'use strict';

angular.module('qualita.stock')
  .factory('ListaMaterialesFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var ListaMateriales = $resource(baseurl.getBaseUrl() + '/listamateriales/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });
    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return ListaMateriales.query(params);
      },
      get: function (id, view) {
        return ListaMateriales.get({ id: id, view: view != undefined ? view : 'base' });
      },
      create: function (attrs) {
        return new ListaMateriales(attrs);
      },
      save: function (listmat) {
        return (listmat.id) ? listmat.$update() : listmat.$save();
      },
      remove: function (listmat) {
        return listmat.$remove();
      }
    };
  }]);
'use strict';
angular.module('qualita.stock')
  .controller('MaterialesProcesoCtrl', MaterialesProcesoCtrl);

MaterialesProcesoCtrl.$inject = ["AuthorizationService", "MaterialesFactory", "TiposFactory", "$log", "$modal",
  "$scope", "notify", "StockLangFactory"];

function MaterialesProcesoCtrl(AuthorizationService, MaterialesFactory, TiposFactory, $log, $modal, $scope,
  notify, $stateParams, StockLangFactory) {

  var vm = this;
  vm.idMaterial = MaterialesFactory.getIdMaterial();
  vm.material = MaterialesFactory.getMaterial();
  vm.enabledStates = MaterialesFactory.getEnabledStates();

  $scope.$on("updateMaterialesStates", function () {
    console.log("updateMaterialesStates listener triggered");
    vm.enabledStates = MaterialesFactory.getEnabledStates();
  });
}
'use strict';

angular.module('qualita.stock')
  .controller('OrdenProduccionFormCtrl', OrdenProduccionFormCtrl);

OrdenProduccionFormCtrl.$inject = ['$scope', 'ordenProduccionPrepService', '$location', '$state', '$timeout',
  'OrdenProduccionFactory', 'ModelTrimmer', '$rootScope', 'StockLangFactory', 'notify', 'filterFactory', 'TiposFactory',
  '$filter', 'TiposTipoFactory', 'ProductosFactory', 'UtilFactory', 'RecursoFactory', 'AlmacenFactory', 'ClienteFactory',
  'PedidoVentaFactory', 'MaterialesFactory', 'ListaMaterialesFactory', 'ProductoAlmacenFactory','ParametrosFactory', 'AuthorizationService'];

function OrdenProduccionFormCtrl($scope, ordenProduccionPrepService, $location, $state, $timeout,
  OrdenProduccionFactory, ModelTrimmer, $rootScope, StockLangFactory, notify, filterFactory, TiposFactory,
  $filter, TiposTipoFactory, ProductosFactory, UtilFactory, RecursoFactory, AlmacenFactory, ClienteFactory,
  PedidoVentaFactory, MaterialesFactory, ListaMaterialesFactory, ProductoAlmacenFactory,ParametrosFactory, AuthorizationService) {

  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.delayRequestProducto = delayRequestProducto;
  vm.searchProductoFilter = searchProductoFilter;
  vm.delayRequestCliente = delayRequestCliente;
  vm.searchClienteFilter = searchClienteFilter;
  vm.searchPVFilter = searchPVFilter;
  vm.delayRequestPV = delayRequestPV;
  vm.changeProducto = changeProducto;
  vm.calcularCantidadRequerida = calcularCantidadRequerida;
  vm.changeAlmacen = changeAlmacen;

  ParametrosFactory.getByCodigo('asignar_lote_sin_criterio').then(function (parametro) {
    if (parametro.valorTipo.codigo === 'no') {
        vm.asignarLoteSinCriterio = false;
    } else {
        vm.asignarLoteSinCriterio = true;
    }
  });

  ParametrosFactory.getByCodigo('asignar_grupo_pv').then(function (parametro) {
    if (parametro.valorTipo.codigo === 'no') {
        vm.asignarGrupo = false;
    } else {
        vm.asignarGrupo = true;
    }
  });
  function calcularCantidadRequerida() {
    if (vm.ordenproduccion.ordenProduccionDetalle.length > 0) {
      _.forEach(vm.ordenproduccion.ordenProduccionDetalle, function (detalle) {
      //  if (detalle.producto && detalle.producto.criterioControl.codigo === "series") {
        //  detalle.cantidadRequerida = 1;
        //}else{
          detalle.cantidadRequerida = detalle.cantidadBase * vm.ordenproduccion.cantidadPlanificada;
        //}
      });
    }
    vm.cantidadProductoTerminado = vm.listaMateriales.cantidadBase * vm.ordenproduccion.cantidadPlanificada;
  }

  function changeAlmacen() {
    if (vm.ordenproduccion.ordenProduccionDetalle.length > 0) {
      _.forEach(vm.ordenproduccion.ordenProduccionDetalle, function (detalle) {
        detalle.almacen = vm.ordenproduccion.almacen;
        if (detalle.producto != null) {
          ProductoAlmacenFactory.getExistencia(detalle.producto.id, vm.ordenproduccion.almacen.id).then(function (rr) {
            detalle.stock = parseInt(rr.data);
          })
        } else {
          detalle.stock = 1;
        }
      });
    }
  }

  function changeProducto() {
    //preguntar si tiene el permiso para ver los costos
    vm.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      vm.verCosto = true;
    }

    vm.ordenproduccion.ordenProduccionDetalle = [];
    var idProducto = vm.ordenproduccion.producto.id;
    var filterMateriales = {
      search: filterFactory
        .and([
          {
            path: "estado.codigo",
            equals: "tipo_estado_materiales_activado"
          },
          {
            path: "producto.id",
            equals: idProducto
          }
        ]
        )
        .value()
    };
    MaterialesFactory.all(filterMateriales).$promise.then(function (response) {
      var idMateriales = response[0].id;
      var filterListaMateriales = {
        search: filterFactory
          .and([
            {
              path: "estado.codigo",
              equals: "tipo_estado_lista_materiales_activada"
            },
            {
              path: "materiales.id",
              equals: idMateriales
            }
          ]
          )
          .value()
      };
      ListaMaterialesFactory.all(filterListaMateriales).$promise.then(function (listamateriales) {
        vm.listaMateriales = listamateriales[0];
        listamateriales[0].listaMaterialesDetalles.sort(function (a, b) {
          return a.posicion - b.posicion;
        });
        _.forEach(listamateriales[0].listaMaterialesDetalles, function (detalle) {
          if(detalle.estado.codigo === 'tipo_estado_lis_mat_det_activado'){
            var newOrdenProduccionDetalle = {
              tipo: detalle.tipo,
              numeroLinea: detalle.posicion,
              producto: detalle.producto,
              recurso: detalle.recurso,
              cantidadBase: detalle.cantidad,
              cantidadRequerida: detalle.cantidad * vm.ordenproduccion.cantidadPlanificada,
              almacen: vm.ordenproduccion.almacen,
              unidadMedida : detalle.unidadMedida,
              listaMateriales: listamateriales[0]
            };
            if (detalle.producto != null) {
              ProductoAlmacenFactory.getExistencia(detalle.producto.id, vm.ordenproduccion.almacen.id).then(function (rr) {
                newOrdenProduccionDetalle.stock = parseInt(rr.data);
              })
              var filtroCosto = {};
              var fecha = moment(new Date()).format('DD/MM/YYYY');
  
              filtroCosto.search = filterFactory
                .and([{
                  path: 'producto.id',
                  equals: detalle.producto.id
                }, {
                  path: 'dia',
                  equalOrBefore: fecha
                }, {
                  path: 'dia',
                  sortDesc: 'true'
                }]).value();
  
              ProductoAlmacenFactory.all(filtroCosto).$promise.then(function (productosAlmacen) {
                if (productosAlmacen != undefined && productosAlmacen.length > 0) {
                  // Obtener el valor como número flotante
                  var costo = parseFloat(productosAlmacen[0].ultimoCostoIVA);
  
                  // Verificar si el número tiene decimales
                  if (!Number.isInteger(costo)) {
                    // Redondear a 8 decimales solo si no es un número entero
                    costo = parseFloat(costo.toFixed(8));
                  }
  
                  newOrdenProduccionDetalle.costo = costo;
                }
              });
            } else {
              newOrdenProduccionDetalle.stock = 1;
              if(detalle.recurso.tipoRecurso.codigo === 'tipo_recurso_maquina'){
                newOrdenProduccionDetalle.costo = detalle.recurso.costoEstandar4Maquina;
              }else if(detalle.recurso.tipoRecurso.codigo === 'tipo_recurso_mano_obra'){
                newOrdenProduccionDetalle.costo = detalle.recurso.costoEstandar2Hombre;
              }
            }
            vm.ordenproduccion.ordenProduccionDetalle.push(newOrdenProduccionDetalle);
          }
        });
      });

    });
  }

  function delayRequestPV(searchValue) {
    var userInputFilter = {}
    if (searchValue.length >= 3) {

      var filterClienteNombre = [];
      var filterFecha = [];
      var filterCodigoInterno = [];

      filterClienteNombre.push({
        path: "cliente.nombre",
        like: searchValue
      },
        {
          path: "estado.codigo",
          equals: "pv_aprobado"
        }, {
        path: "cliente.id",
        equals: vm.ordenproduccion.cliente.id
      })

      filterFecha.push({
        path: "fecha",
        like: searchValue
      },
        {
          path: "estado.codigo",
          equals: "pv_aprobado"
        }, {
        path: "cliente.id",
        equals: vm.ordenproduccion.cliente.id
      })

      filterCodigoInterno.push({
        path: "id",
        like: searchValue
      },
        {
          path: "estado.codigo",
          equals: "pv_aprobado"
        }, {
        path: "cliente.id",
        equals: vm.ordenproduccion.cliente.id
      })

      userInputFilter = {
        search: filterFactory.or([
          filterFactory.and(filterClienteNombre).value(),
          filterFactory.and(filterFecha).value(),
          filterFactory.and(filterCodigoInterno).value()
        ]).value()
      }

      recuperarPV(userInputFilter)
    } else {
      vm.pedidosVenta = undefined
    }
  }

  function recuperarPV(userInputFilter) {
    var finalFilter = UtilFactory.createNestedFilters(
      vm.finalFilters, userInputFilter)

    vm.pedidosVenta = PedidoVentaFactory.allForSelect(userInputFilter)
  }


  function searchPVFilter(criteria) {
    return function (item) {
      if (!criteria) {
        return true;
      }
      return (
        (item.id + "").indexOf(criteria.toLowerCase()) >
        -1 ||
        item.cliente.nombre
          .toLowerCase()
          .indexOf(criteria.toLowerCase()) > -1
      );
    };
  };

  function delayRequestCliente(searchValue) {
    var userInputFilter = {}
    if (searchValue.length >= 3) {
      var filterNombre = [];
      var filterNombreFantasia = [];
      var filterCodigo = [];
      var filterNumero = [];
      var filterDireccion = [];
      var filterCiudad = [];

      filterNombre.push({
        path: "nombre",
        like: searchValue
      })
      filterNombre.push({
        path: "activo",
        equals: true
      })
      filterNombreFantasia.push({
        path: "nombreFantasia",
        like: searchValue
      })
      filterNombreFantasia.push({
        path: "activo",
        equals: true
      })
      filterCodigo.push({
        path: "codigo",
        like: searchValue
      })
      filterCodigo.push({
        path: "activo",
        equals: true
      })
      filterNumero.push({
        path: "numero",
        like: searchValue
      })
      filterNumero.push({
        path: "activo",
        equals: true
      })
      filterDireccion.push({
        path: "direccion",
        like: searchValue
      })
      filterDireccion.push({
        path: "activo",
        equals: true
      })
      filterCiudad.push({
        path: "ciudad.descripcion",
        like: searchValue
      })
      filterCiudad.push({
        path: "activo",
        equals: true
      })

      userInputFilter = {
        search: filterFactory.or([
          filterFactory.and(filterNombre).value(),
          filterFactory.and(filterNombreFantasia).value(),
          filterFactory.and(filterCodigo).value(),
          filterFactory.and(filterNumero).value(),
          filterFactory.and(filterDireccion).value(),
          filterFactory.and(filterCiudad).value()
        ]).value()
      }

      recuperarCliente(userInputFilter)
    } else {
      vm.clientes = undefined
    }
  }

  function recuperarCliente(userInputFilter) {
    var finalFilter = UtilFactory.createNestedFilters(
      vm.finalFilters, userInputFilter)

    ClienteFactory.allForSelect(finalFilter).$promise.then(function (clientes) {
      var clie = [];
      for (var i = 0; i < clientes.length; i++) {
        if (clientes[i].bloquearVentas !== true) {
          clie.push(clientes[i])
        }
      }
      vm.clientes = clie;
    })
  }

  function searchClienteFilter(criteria) {
    return function (item) {
      if (!criteria) {
        return true;
      }
      return (
        item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
        (item.nombreFantasia && item.nombreFantasia.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
        (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
        item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
        (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
        (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
      );
    };
  }


  function searchProductoFilter(criteria) {
    return function (item) {
      if (!criteria) {
        return true;
      }
      return (
        item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
        item.codigo == criteria ||
        (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0
      );
    };
  }

  function delayRequestProducto(searchValue) {
    var userInputFilter = {}
    if (searchValue.length >= 3) {

      var filterNombre = [];
      var filterCodigoBarras = [];
      var filterCodigoInterno = [];

      filterNombre.push({
        path: "nombre",
        like: searchValue
      },
        {
          path: 'estado.codigo',
          equals: 'activo'
        },
        {
          path: "locaciones.id",
          equals: $rootScope.AuthParams.locacionSeleccionada.id
        }, {
        path: "tieneListaMateriales",
        equals: true
      })

      filterCodigoBarras.push({
        path: "codigoBarrasPrincipal.codigoBarras",
        like: searchValue
      },
        {
          path: 'estado.codigo',
          equals: 'activo'
        },
        {
          path: "locaciones.id",
          equals: $rootScope.AuthParams.locacionSeleccionada.id
        }, {
        path: "tieneListaMateriales",
        equals: true
      })

      filterCodigoInterno.push({
        path: "codigo",
        like: searchValue
      },
        {
          path: 'estado.codigo',
          equals: 'activo'
        },
        {
          path: "locaciones.id",
          equals: $rootScope.AuthParams.locacionSeleccionada.id
        }, {
        path: "tieneListaMateriales",
        equals: true
      })

      userInputFilter = {
        search: filterFactory.or([
          filterFactory.and(filterNombre).value(),
          filterFactory.and(filterCodigoBarras).value(),
          filterFactory.and(filterCodigoInterno).value()
        ]).value()
      }

      recuperarProducto(userInputFilter)
    } else {
      vm.productos = undefined
    }
  }

  function recuperarProducto(userInputFilter) {
    var finalFilter = UtilFactory.createNestedFilters(
      vm.finalFilters, userInputFilter)

    vm.productos = ProductosFactory.allForSelect(userInputFilter)
  }

  activate();

  function activate() {
    StockLangFactory.getTranslations().then(function (translations) {
      vm.translations = translations;

      vm.estados = TiposTipoFactory.get("tipos_estado_orden_produccion");
      vm.tipos = TiposTipoFactory.get("tipos_orden_produccion");
      var filterAlmacen = {
        search: filterFactory
          .and([
            {
              path: "activo",
              equals: true
            }]
          )
          .value()
      };
      vm.depositos = AlmacenFactory.all(filterAlmacen);

      var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
        'lineaProducto.descripcion', 'marca.descripcion'
      ];
      vm.factoryProductos = ProductosFactory;

      vm.opcionesProductosDt = {
        'resource': 'productos',
        'title': 'Productos',
        'view': "ProductoList",
        'factory': ProductosFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'codigo', 'title': $filter('translate')('ID'), visible: false },
          { 'data': 'nombre', 'title': $filter('translate')('NAME') },
          { 'data': 'claseProducto.descripcion', 'title': $filter('translate')('PRODUCT_CLASS') },
          { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
          { 'data': 'lineaProducto.nombre', 'title': $filter('translate')('LINE') },
          { 'data': 'marca.descripcion', 'title': $filter('translate')('BRAND'), 'renderWith': 'emptyRender' },
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
      };

      if ($state.is('app.ordenproduccion.new')) {
        activateNew();
      } else if ($state.is('app.ordenproduccion.edit')) {
        activateEdit();
      } else if ($state.is('app.ordenproduccion.view')) {
        activateView();
      }
      $rootScope.isProcessing = false;
    });
  }

  function activateNew() {
    vm.title = 'Nueva Orden de Producción';
    vm.ordenproduccion = { ordenProduccionDetalle: [{}] };
    vm.ordenproduccion.ordenProduccionDetalle = [];
    vm.ordenproduccion.fechaFabricacion = new Date();
    vm.ordenproduccion.fechaInicio = new Date();
    vm.ordenproduccion.fechaFinalizacion = new Date();

    vm.loading = true;
    //vm.ordenproduccion.numero = "El numero se asignará al guardar la Orden de Producción";
    TiposFactory.tipoOrdenProduccion().then(function (data) {
      _.forEach(data.data, function (data) {
        if (data.codigo == 'tipo_orden_normal') {
          vm.ordenproduccion.tipo = data;
        }
      });
    });
    TiposFactory.estadoOrdenProduccion().then(function (data) {
      _.forEach(data.data, function (data) {
        if (data.codigo == 'tipo_estado_orden_prod_ingresado') {
          vm.ordenproduccion.estado = data;
        }
      });
    });
    vm.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      vm.verCosto = true;
    }
  }

  function activateView() {
    vm.loading = true;
    vm.title = 'Ver Orden de Producción';
    vm.ordenproduccion = ordenProduccionPrepService;
    vm.entidadId = vm.ordenproduccion.id;
    vm.entidad = "OrdenProduccion";
    vm.view = true;
    vm.ordenproduccion.fechaFabricacion = new Date(vm.ordenproduccion.fechaFabricacion);
    vm.ordenproduccion.fechaInicio = new Date(vm.ordenproduccion.fechaInicio);
    vm.ordenproduccion.fechaFinalizacion = new Date(vm.ordenproduccion.fechaFinalizacion);
    listarMateriales();
    // Filtrar para mostrar solo los sin grupo
    if (vm.ordenproduccion.ordenProduccionDetalle.length > 0) {
      vm.ordenproduccion.ordenProduccionDetalle = vm.ordenproduccion.ordenProduccionDetalle.filter(function (detalle) {
          return !detalle.grupoProducto;
      });
    }
    vm.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      vm.verCosto = true;
    }
  }

  function activateEdit() {
    vm.loading = true;
    vm.title = 'Editar Orden de Producción';
    vm.ordenproduccion = ordenProduccionPrepService;
    vm.entidadId = vm.ordenproduccion.id;
    vm.entidad = "OrdenProduccion";
    vm.ordenproduccion.fechaFabricacion = new Date(vm.ordenproduccion.fechaFabricacion);
    vm.ordenproduccion.fechaInicio = new Date(vm.ordenproduccion.fechaInicio);
    vm.ordenproduccion.fechaFinalizacion = new Date(vm.ordenproduccion.fechaFinalizacion);
    //filtrar para mostrar solo los sin grupo
    // Filtrar para mostrar solo los sin grupo
    if (vm.ordenproduccion.ordenProduccionDetalle.length > 0) {
      vm.ordenproduccion.ordenProduccionDetalle = vm.ordenproduccion.ordenProduccionDetalle.filter(function (detalle) {
          return !detalle.grupoProducto;
      });
    }

    listarMateriales();
    vm.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      vm.verCosto = true;
    }
  }

  function submit() {
    vm.submited = true;

    if ($scope.OrdenProduccionForm.$valid) {
      $rootScope.isProcessing = true;

      if (vm.ordenproduccion.fechaInicio != undefined && vm.ordenproduccion.fechaFinalizacion != undefined && vm.ordenproduccion.fechaInicio > vm.ordenproduccion.fechaFinalizacion) {
        notify({ message: 'La fecha de inicio no puede ser mayor a la fecha fin.', classes: 'alert-danger', position: 'right' });

        $rootScope.isProcessing = false;
      } else {

        submitOrdenProduccion().then(function () {
          $state.go('app.ordenproduccion.list');
        }, function (error) {
          $rootScope.isProcessing = false;
          console.log(error);
          var msg = "";
          _.forEach(error.data, function (e) {
            msg += '\n\n' + e.message + '.';
          });
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
        });
      }
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function submitOrdenProduccion() {
    var resource = OrdenProduccionFactory.create(vm.ordenproduccion);
    return OrdenProduccionFactory.save(resource);
  }

  function cancel() {
    $state.go("app.ordenproduccion.list");
  }

  function listarMateriales() {
    var idProducto = vm.ordenproduccion.producto.id;
    var filterMateriales = {
      search: filterFactory
        .and([
            {
              path: "estado.codigo",
              equals: "tipo_estado_materiales_activado"
            },
            {
              path: "producto.id",
              equals: idProducto
            }
          ]
        )
        .value()
    };
    MaterialesFactory.all(filterMateriales).$promise.then(function (response) {
      var idMateriales = response[0].id;
      var filterListaMateriales = {
        search: filterFactory
          .and([
              {
                path: "estado.codigo",
                equals: "tipo_estado_lista_materiales_activada"
              },
              {
                path: "materiales.id",
                equals: idMateriales
              }
            ]
          )
          .value()
      };
      ListaMaterialesFactory.all(filterListaMateriales).$promise.then(function (listamateriales) {
        vm.listaMateriales = listamateriales[0];
        if(vm.ordenproduccion.cantidadPlanificada){
          vm.cantidadProductoTerminado = vm.listaMateriales.cantidadBase * vm.ordenproduccion.cantidadPlanificada;
        }
      });
    })
  }

  $scope.resource = 'ordenproduccion'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

angular.module('qualita.stock')
  .controller('OrdenProduccionIndexCtrl', OrdenProduccionIndexCtrl);

OrdenProduccionIndexCtrl.$inject = ["AuthorizationService", "$state",
  "filterFactory",
  "ReportTicketFactory",
  "$window",
  "StockLangFactory",
  "OrdenProduccionFactory",
  "CsvFactory", "UtilFactory", "baseurl", "$filter", "$modal", "$scope", "notify"];

function OrdenProduccionIndexCtrl(AuthorizationService, $state,
  filterFactory,
  ReportTicketFactory,
  $window,
  StockLangFactory, OrdenProduccionFactory,
  CsvFactory, UtilFactory, baseurl, $filter, $modal, $scope, notify) {

  var vm = this;

  var defaultColumnOrder = ['id', 'numero', 'tipo.descripcion', 'estado.descripcion'];

  StockLangFactory.getTranslations().then(function (translations) {
    vm.options = {
      'resource': 'ordenproduccion',
      'title': 'Orden de Producción',
      'factory': OrdenProduccionFactory,
      'view': 'OrdenProduccionList',
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        { 'data': 'id', 'title': 'Código', visible: false },
        { 'data': 'numero', 'title': 'Número', 'class': "dt-center" },
        { 'data': 'tipo.descripcion', 'title': 'Tipo', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipos_orden_produccion' },
        { 'data': 'estado.descripcion', 'title': 'Estado', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipos_estado_orden_produccion' }
      ],
      'hasOptions': true,
      'hideAddMenu': true,
      'hideEditMenu': true,
      'hideViewMenu': true,
      'hideRemoveMenu': true,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'extraMenuOptions':
        [
          {
            title: translations.NEW,
            icon: "glyphicon glyphicon-plus",
            showCondition: function () {
              if (AuthorizationService.hasPermission("create_ordenproduccion")) {
                return true;
              } else {
                return false;
              }
            },
            action: function () {
              if (AuthorizationService.hasPermission("create_ordenproduccion")) {
                $state.go("app.ordenproduccion.new");
              } else {
                var msg = 'No posee permiso para crear Orden de Produccion';
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
              }
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                CsvFactory.csv("ordenproduccion", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
        }
        ],
      'extraRowOptions': [
        {
          templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter("translate")("EDIT") +
            "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          customAttribute: "estado",
          conditionName: "canEditar",
          conditionDef: function (estado) {
            if (AuthorizationService.hasPermission("update_ordenproduccion") && estado.codigo === "tipo_estado_orden_prod_ingresado") {
              return true;
            } else {
              return false;
            }
          },
          functionName: "editar",
          functionDef: function (itemId) {
            if (AuthorizationService.hasPermission("update_ordenproduccion")) {
              $state.go('app.ordenproduccion.edit', { id: itemId });
            } else {
              notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
            }
          }
        },
        {
          templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
            $filter("translate")("VIEW") +
            "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
          functionName: "ver",
          functionDef: function (itemId) {
            if (AuthorizationService.hasPermission("index_ordenproduccion")) {
              $state.go("app.ordenproduccion.view", { id: itemId });
            } else {
              notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
            }
          },
          conditionName: "canVer",
          conditionDef: function () {
            if (AuthorizationService.hasPermission("index_ordenproduccion")) {
              return true;
            } else {
              return false;
            }
          }
        },
        {
          templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
            $filter("translate")("DELETE") +
            "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
          customAttribute: "estado",
          conditionName: "canBorrar",
          conditionDef: function (estado) {
            return (AuthorizationService.hasPermission("delete_ordenproduccion") && estado.codigo === "tipo_estado_orden_prod_ingresado");
          },
          functionName: "borrar",
          functionDef: function (itemId) {
            $scope.selectedItemId = itemId;
            $scope.tituloModal = $filter("translate")("DELETE_CONFIRMATION");
            $scope.mensajeModal = $filter("translate")("DELETE_WARNING");
            var modalInstanceBorrar1 = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });

            $scope.cancel = function () {
              modalInstanceBorrar1.dismiss("cancel");
            };

            $scope.ok = function (itemId) {
              var model = OrdenProduccionFactory.create({ id: itemId });
              OrdenProduccionFactory.remove(model).then(
                function () {
                  modalInstanceBorrar1.close(itemId);
                  $state.go($state.current, {}, { reload: true });
                },
                function (error) {
                  $scope.tituloModal = vm.translations.DELETE_FAILED;
                  $scope.mensajeModal = "No se pudo borrar el registro. Verifique si hay operaciones asociadas.";
                  var modalInstance = $modal.open({
                    template: '<div class="modal-header">' +
                      '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                      "</div>" +
                      '<div class="modal-body">{{::mensajeModal}}</div>' +
                      '<div class="modal-footer">' +
                      '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                      "</div>",
                    scope: $scope
                  });
                  $scope.cancel = function () {
                    modalInstance.dismiss("cancel");
                  };
                  console.log("error al borrar: ");
                  console.log(error);
                }
              );
            };
          }
        },
        {
          templateToRender: "<button class='btn btn-warning' title='" +
            'Entrega Materia Prima' +
            "' style='margin-right: 5px;' ng-click='entrega(<%=dataCustom%>)' ng-class='{ hidden : !canEntrega(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-shopping-cart'></span> </button>",
          customAttribute: ["id", "estado"],
          functionName: "entrega",
          functionDef: function (atributos) {
            var itemId = atributos[0];
            var estado = atributos[1];
            OrdenProduccionFactory.get(itemId, "EntregaForm").$promise.then(function (s) {
              if (estado.codigo === "tipo_estado_orden_prod_proceso") {
                $scope.tituloModal = 'Entrega ya realizada';
                $scope.mensajeModal = 'Desea realizar una entrega adicional de Materia Prima?';
                var templateTmp = '<div class="modal-header">' +
                  '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                  "</div>" +
                  '<div class="modal-body">{{::mensajeModal}}</div>' +
                  '<div class="modal-footer">' +
                  '<button class="btn btn-primary" ng-click="editarEntrega()">' +
                  'Editar' +
                  "</button>" +
                  '<button class="btn btn-warning" ng-click="cancel()">' +
                  $filter("translate")("CANCEL") +
                  "</button>";
                $scope.modalInstanceBorrar1 = $modal.open({
                  template: templateTmp,
                  scope: $scope
                });
                $scope.cancel = function () {
                  $scope.modalInstanceBorrar1.dismiss("cancel");
                };
                $scope.editarEntrega = function () {
                  $state.go("app.ordenproduccion.entregaedit", {
                    id: itemId,
                    mode: "edit"
                  });
                  $scope.modalInstanceBorrar1.dismiss();
                };
              } else if (estado.codigo === "tipo_estado_orden_prod_ingresado") {
                vm.isProcessing = true;
                OrdenProduccionFactory.distribuir(itemId).then(function (response) {
                  if(response.mensaje !== ""){
                    notify({ message: response.mensaje, classes: 'alert-success', position: 'right', templateUrl: "" });
                  }
                  vm.isProcessing = false;
                  $state.go("app.ordenproduccion.entregaadd", {
                    id: itemId,
                    mode: "add"
                  });
                }, function (error) {
                  //console.log(error);
                  vm.isProcessing = false;
                  var mensaje = "<span>";
                  _.forEach(error.data, function (err) {
                    mensaje = mensaje + err.message + "<br/>";
                  });
                  mensaje = mensaje + "</span>";
                  notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                });
              } else if (estado.codigo === "tipo_estado_orden_prod_terminado") {
                $state.go("app.ordenproduccion.entregaview", {
                  id: itemId,
                  mode: "view"
                });
              }
            });
          },
          conditionName: "canEntrega",
          conditionDef: function (atributos) {
            var estado = atributos[1];
            if (AuthorizationService.hasPermission("entrega_materiaprima") && estado.codigo !== 'tipo_estado_orden_prod_cancelado') {
              return true;
            } else {
              return false;
            }
          }
        },
        {
          templateToRender: "<button class='btn btn-danger' title='" +
            'Devolución Materia Prima' +
            "' style='margin-right: 5px;' ng-click='devolucion(<%=dataCustom%>)' ng-class='{ hidden : !canDevolucion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-shopping-cart'></span> </button>",
          customAttribute: ["id", "estado"],
          functionName: "devolucion",
          functionDef: function (atributos) {
            var itemId = atributos[0];
            var estado = atributos[1];
            OrdenProduccionFactory.get(itemId, "EntregaForm").$promise.then(function (s) {
              if (estado.codigo === "tipo_estado_orden_prod_proceso") {
                $scope.tituloModal = 'Devolución de Materia Prima';
                $scope.mensajeModal = 'Desea realizar una devolución de Materia Prima?';
                var templateTmp = '<div class="modal-header">' +
                  '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                  "</div>" +
                  '<div class="modal-body">{{::mensajeModal}}</div>' +
                  '<div class="modal-footer">' +
                  '<button class="btn btn-primary" ng-click="crearDevolucion()">' +
                  $filter('translate')('OK') +
                  "</button>" +
                  '<button class="btn btn-warning" ng-click="cancel()">' +
                  $filter("translate")("CANCEL") +
                  "</button>";
                $scope.modalInstanceBorrar1 = $modal.open({
                  template: templateTmp,
                  scope: $scope
                });
                $scope.cancel = function () {
                  $scope.modalInstanceBorrar1.dismiss("cancel");
                };
                $scope.crearDevolucion = function () {
                  $state.go("app.ordenproduccion.devolucionedit", {
                    id: itemId,
                    mode: "add"
                  });
                  $scope.modalInstanceBorrar1.dismiss();
                };
              } else if (estado.codigo === "tipo_estado_orden_prod_terminado") {
                $state.go("app.ordenproduccion.devolucionview", {
                  id: itemId,
                  mode: "view"
                });
              }
            });
          },
          conditionName: "canDevolucion",
          conditionDef: function (atributos) {
            var estado = atributos[1];
            if (estado.codigo === 'tipo_estado_orden_prod_proceso') {
              return true;
            } else {
              return false;
            }
          }
        },
        {
          templateToRender: "<button title='" +
            "Planilla de entrega de materia prima" +
            "' class='btn btn-info btn-dt' style='margin-right: 5px;' ng-click='imprimir(<%=dataCustom%>)' ng-class='{ hidden : !canImprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-print'></span> </button>",
          functionName: "imprimir",
          conditionName: "canImprimir",
          customAttribute: ["estado", "id"],
          conditionDef: function (atributos) {
            var estado = atributos[0]
            //if (estado.codigo !== 'tipo_estado_orden_prod_cancelado') {
              return true;
            //} else {
              //return false;
            //}
          },
          functionDef: function (atributos) {
            var itemId = atributos[1];

            $scope.mensajeModal = "Desea imprimir la Planilla de entrega de materia prima";
            $scope.tituloModal = "Imprimir Planilla de entrega de materia prima";
            var modalInstance = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });

            $scope.cancel = function () {
              modalInstance.dismiss("cancel");
            };

            $scope.ok = function (id) {
              var filtersArr = [];
              filtersArr.push("idordenproduccion=" + itemId);
              modalInstance.close();
              $state.go($state.current, {}, { reload: true });
              $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                "planillaentregamateriaprima",
                "pdf",
                filtersArr
              );
              $window.open($scope.pdfDownloadURL, "_blank");
              $state.go($state.current, {}, { reload: true });
            }
          }
        },
        {
          templateToRender: "<button class='btn btn-success' title='" +
            'Procesar Orden Producción' +
            "' style='margin-right: 5px;' ng-click='procesar(<%=dataCustom%>)' ng-class='{ hidden : !canProcesar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ok-circle'></span> </button>",
          customAttribute: ["id", "estado"],
          functionName: "procesar",
          functionDef: function (atributos) {
            var itemId = atributos[0];
            var estado = atributos[1];
            OrdenProduccionFactory.get(itemId, "ProcesarForm").$promise.then(function (s) {
              if (estado.codigo != "tipo_estado_orden_prod_terminado" && estado.codigo != 'tipo_estado_orden_prod_proceso') {
                $scope.tituloModal = 'Proceso ya existente';
                $scope.mensajeModal = 'Desea visualizar el Proceso?';
                var templateTmp = '<div class="modal-header">' +
                  '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                  "</div>" +
                  '<div class="modal-body">{{::mensajeModal}}</div>' +
                  '<div class="modal-footer">' +
                  '<button class="btn btn-primary" ng-click="editarProceso()">' +
                  'Ver' +
                  "</button>" +
                  '<button class="btn btn-warning" ng-click="cancel()">' +
                  $filter("translate")("CANCEL") +
                  "</button>";
                $scope.modalInstanceBorrar1 = $modal.open({
                  template: templateTmp,
                  scope: $scope
                });
                $scope.cancel = function () {
                  $scope.modalInstanceBorrar1.dismiss("cancel");
                };
                $scope.editarProceso = function () {
                  $state.go("app.ordenproduccion.procesarview", {
                    id: itemId,
                    mode: "view"
                  });
                  $scope.modalInstanceBorrar1.dismiss();
                };
              } else if (estado.codigo === "tipo_estado_orden_prod_proceso") {
                $state.go("app.ordenproduccion.procesaradd", {
                  id: itemId,
                  mode: "add"
                });
              } else if (estado.codigo === "tipo_estado_orden_prod_terminado") {
                $state.go("app.ordenproduccion.procesarview", {
                  id: itemId,
                  mode: "view"
                });
              }
            });
          },
          conditionName: "canProcesar",
          conditionDef: function (atributos) {
            var estado = atributos[1];
            if (AuthorizationService.hasPermission("procesar_ordenproduccion") && (estado.codigo === 'tipo_estado_orden_prod_terminado' || estado.codigo === 'tipo_estado_orden_prod_proceso')) {
              return true;
            } else {
              return false;
            }
          }
        },
        {
          templateToRender: "<button title='" +
            "Orden de Trabajo" +
            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='imprimirorden(<%=dataCustom%>)' ng-class='{ hidden : !canImprimirOrden(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-list-alt'></span> </button>",
          functionName: "imprimirorden",
          conditionName: "canImprimirOrden",
          customAttribute: ["estado", "id"],
          conditionDef: function (atributos) {
            var estado = atributos[0]
            //if (estado.codigo !== 'tipo_estado_orden_prod_cancelado') {
              return true;
            //} else {
              //return false;
            //}
          },
          functionDef: function (atributos) {
            var itemId = atributos[1];

            $scope.mensajeModal = "Desea imprimir la Orden de Trabajo";
            $scope.tituloModal = "Imprimir Orden de Trabajo";
            var modalInstance = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });

            $scope.cancel = function () {
              modalInstance.dismiss("cancel");
            };

            $scope.ok = function (id) {
              var filtersArr = [];
              filtersArr.push("idordenproduccion=" + itemId);
              modalInstance.close();
              $state.go($state.current, {}, { reload: true });
              $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                "ordentrabajo",
                "pdf",
                filtersArr
              );
              $window.open($scope.pdfDownloadURL, "_blank");
              $state.go($state.current, {}, { reload: true });
            }
          }
        },
        {
          templateToRender: "<button title='" + 'Cancelar' +
            "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='cancelarOrdenProduccion(<%=dataId%>)' ng-class='{ hidden : !canCancelarOrdenProduccion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
          functionName: "cancelarOrdenProduccion",
          conditionName: "canCancelarOrdenProduccion",
          customAttribute: "estado",
          conditionDef: function (estado) {
            return estado.codigo == 'tipo_estado_orden_prod_proceso';
          },
          functionDef: function (itemId) {
            $scope.mensajeModal = 'Desea realizar la cancelación de la orden de producción?';
            $scope.tituloModal = 'Cancelar Orden de Producción';
            var modalInstance = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });
            $scope.cancel = function () {
              modalInstance.dismiss("cancel");
            };
            $scope.ok = function () {
              console.log("item ", itemId)
              OrdenProduccionFactory.cancelar(itemId).then(function (response) {
                $state.go($state.current, {}, { reload: true });
              },
                function (error) {
                  notify({ message: error, classes: 'alert-danger', position: 'right' });
                });
            }
          }
        }
      ]
    };
  });
}

'use strict';

angular.module('qualita.stock')
  .factory('OrdenProduccionFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var OrdenProduccion = $resource(baseurl.getBaseUrl() + '/ordenproduccion/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      },
      distribuir: {
        method: 'PUT',
        url: baseurl.getBaseUrl() + "/ordenproduccion/distribuir/:id"
      },
      cancelar: {
        method: "PUT",
        url: baseurl.getBaseUrl() + "/ordenproduccion/cancelar/:id"
      }
    });
    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return OrdenProduccion.query(params);
      },
      get: function (id, view) {
        return OrdenProduccion.get({ id: id, view: view != undefined ? view : 'base' });
      },
      create: function (attrs) {
        return new OrdenProduccion(attrs);
      },
      save: function (ordenproducciones) {
        return (ordenproducciones.id) ? ordenproducciones.$update() : ordenproducciones.$save();
      },
      remove: function (ordenproducciones) {
        return ordenproducciones.$remove();
      },
      distribuir: function (id) {
          return OrdenProduccion.distribuir({ id: id }).$promise;
      },
      cancelar: function (id) {
        console.log("id ",id)
        return OrdenProduccion.cancelar({ id: id }).$promise;
      }
    };
  }]);


angular.module('qualita.stock')
  .factory('EntregaFactory', EntregaFactory);

EntregaFactory.$inject = ['$resource', 'baseurl', '$http', 'ParametrosFactory', '$q'];

function EntregaFactory($resource, baseurl, $http, ParametrosFactory, $q) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var Entrega = $resource(baseurl.getBaseUrl() + "/entrega/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params) {
    return Entrega.query(params);
  }

  function get(id, view) {
    return Entrega.get({ id: id, view: view ? view : "base" });
  }

  function create(attrs) {
    return new Entrega(attrs);
  }

  function save(entrega) {
    return (entrega.id) ? Entrega.update(entrega).$promise : Entrega.save(entrega).$promise;

  }

  function remove(entrega) {
    return entrega.$remove();
  }
}

"use strict";

angular
  .module("qualita.stock")
  .controller(
    "EntregaFormCtrl",
    EntregaFormCtrl
  );

EntregaFormCtrl.$inject = [
  "OrdenProduccionFactory",
  "EntregaFactory",
  "entregaPrepService",
  "filterFactory",
  "$scope",
  "notify",
  "$state",
  "$rootScope",
  "$timeout",
  "$log",
  "$translate",
  "$q",
  "$modal",
  "$stateParams",
  "$filter",
  "TiposTipoFactory",
  "ModelTrimmer",
  "baseurl",
  "StockLangFactory",
  "TransaccionStockFactory",
  "ProductoAlmacenFactory",
  "ParametrosFactory",
  "AuthorizationService",
  "GrupoProductoAlmacenFactory"
];

function EntregaFormCtrl(
  OrdenProduccionFactory,
  EntregaFactory,
  entregaPrepService,
  filterFactory,
  $scope,
  notify,
  $state,
  $rootScope,
  $timeout,
  $log,
  $translate,
  $q,
  $modal,
  $stateParams,
  $filter,
  TiposTipoFactory,
  ModelTrimmer,
  baseurl,
  StockLangFactory,
  TransaccionStockFactory,
  ProductoAlmacenFactory, ParametrosFactory,
  AuthorizationService,
  GrupoProductoAlmacenFactory
) {
  activate();

  $scope.submit = submit;
  $scope.cancel = cancel;
  $scope.changeNroGrupo = changeNroGrupo;

  function changeNroGrupo(detalle) {
    var fecha = moment(new Date()).format('DD/MM/YYYY');

    var params = {};
    params.search = filterFactory.and([{
      path: 'almacen.id',
      equals: detalle.almacen.id
    },
    {
      path: 'grupoProducto.id',
      equals: detalle.grupoProducto.id
    },
    {
      path: 'dia',
      equalOrBefore: fecha
    },
    {
      path: 'dia',
      sortDesc: 'true'
    }
    ]).value();
    GrupoProductoAlmacenFactory.all(params, "BaseList").$promise.then(function (response) {
      if (response.length > 0) {
        var newEntregaDetalle = {};
        newEntregaDetalle = {
          codigo: 4,
          descripcion: detalle.producto.nombre,
          cantidadRequerida: detalle.cantidadRequerida,
          cantidadEntregada: detalle.cantidadRequerida,
          almacen: detalle.almacen,
          producto: detalle.producto,
          almacenproductosterminados: detalle.almacenproductosterminados,
          grupoProducto: detalle.grupoProducto,
          unidadMedida: detalle.unidadMedida,
          cantidadAdicional: 0,
          cantidadAdicionalTotal: 0,
          cantidadDevolucion: 0,
          cantidadDevolucionTotal: 0,
          stock: detalle.stock
        }

        $scope.entrega.entregaMateriaPrimaDetalle.push(newEntregaDetalle);

      }
    })
  }

  ParametrosFactory.getByCodigo('asignar_lote_sin_criterio').then(function (parametro) {
    if (parametro.valorTipo.codigo === 'no') {
      $scope.asignarLoteSinCriterio = false;
    } else {
      $scope.asignarLoteSinCriterio = true;
    }
  });

  // ParametrosFactory.getByCodigo('asignar_grupo_pv').then(function (parametro) {
  //   if (parametro.valorTipo.codigo === 'no') {
  //     $scope.asignarGrupo = false;
  //   } else {
  //     $scope.asignarGrupo = true;
  //   }
  // });

  function activate() {
    StockLangFactory.getTranslations().then(function (translations) {

      $scope.translations = translations;
      $rootScope.isProcessing = false;

      var staticFilter = {};
      staticFilter.search = filterFactory.and([{
        path: 'usarProduccion',
        like: 'true'
      }, {
        path: 'activo',
        equals: true
      }]).value();
      staticFilter.view = "BaseList";
      $scope.transacciones = TransaccionStockFactory.all(staticFilter);

      // $scope.tipoOperaciones = TiposTipoFactory.get("tipo_operacion");

      if ($state.is('app.ordenproduccion.entregaadd')) {
        activateNew();
      } else if ($state.is('app.ordenproduccion.entregaedit')) {
        activateEdit();
      } else if ($state.is('app.ordenproduccion.entregaview')) {
        activateView();
      }
    });
    $scope.submited = false;
  }

  function activateNew() {
    $scope.new = true;
    $scope.title = 'Crear Entrega de Materia Prima';
    $scope.entregaMp = true;
    $scope.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      $scope.verCosto = true;
    }
    var idOrdenProduccion = $stateParams.id;
    OrdenProduccionFactory.get(idOrdenProduccion, "OrdenProduccionForm").$promise.then(function (elemento) {
      var ordenproduccion = elemento;
      $scope.entrega = {
        transaccion: getTransaccion($scope.transacciones),
        almacen: ordenproduccion.almacen,
        almacenproductosterminados: ordenproduccion.almacenproductosterminados,
        entregaMateriaPrimaDetalle: [],
        entrega: true,
        procesar: false,
        devolucion: false
      };
      elemento.ordenProduccionDetalle.sort(function (a, b) {
        return a.numeroLinea - b.numeroLinea;
      });
      _.forEach(elemento.ordenProduccionDetalle, function (detalle) {
        var newEntregaDetalle = {};
        if (detalle.tipo.codigo === 'tipo_producto') {
          if (detalle.grupoProducto || detalle.producto.criterioControl.codigo === 'normal') {

            newEntregaDetalle = {
              codigo: detalle.numeroLinea,
              descripcion: detalle.producto.nombre,
              cantidadRequerida: detalle.cantidadRequerida,
              cantidadEntregada: detalle.cantidadRequerida,
              almacen: detalle.almacen,
              producto: detalle.producto,
              almacenproductosterminados: detalle.almacenproductosterminados,
              grupoProducto: detalle.grupoProducto,
              unidadMedida: detalle.unidadMedida,
              cantidadAdicional: 0,
              cantidadAdicionalTotal: 0,
              cantidadDevolucion: 0,
              cantidadDevolucionTotal: 0
            }

            var filtroCosto = {};
            var fecha = moment(new Date()).format('DD/MM/YYYY');

            filtroCosto.search = filterFactory
              .and([{
                path: 'producto.id',
                equals: detalle.producto.id
              }, {
                path: 'dia',
                equalOrBefore: fecha
              }, {
                path: 'dia',
                sortDesc: 'true'
              }]).value();

            ProductoAlmacenFactory.all(filtroCosto).$promise.then(function (productosAlmacen) {
              if (productosAlmacen != undefined && productosAlmacen.length > 0) {
                var costo = parseFloat(productosAlmacen[0].ultimoCostoIVA);
                var costoUnitario = parseFloat(productosAlmacen[0].ultimoCostoIVA);
                if (!Number.isInteger(costo)) {
                  costo = parseFloat(costo.toFixed(8));
                }
                if (!Number.isInteger(costoUnitario)) {
                  costoUnitario = parseFloat(costo.toFixed(8));
                }
                newEntregaDetalle.costo = costo;
                newEntregaDetalle.costoUnitario = costoUnitario;
                var stock = productosAlmacen[0].saldoReal;
                if (stock != null && !Number.isInteger(stock)) {
                  stock = parseFloat(stock.toFixed(8));
                }
                newEntregaDetalle.stock = stock;
              }
            });
            $scope.entrega.entregaMateriaPrimaDetalle.push(newEntregaDetalle);
          }
        } else {
          newEntregaDetalle = {
            codigo: detalle.numeroLinea,
            descripcion: detalle.recurso.descripcion,
            cantidadRequerida: detalle.cantidadRequerida,
            cantidadEntregada: detalle.cantidadRequerida,
            //almacen: detalle.almacen,
            recurso: detalle.recurso,
            cantidadAdicional: 0,
            cantidadAdicionalTotal: 0,
            cantidadDevolucion: 0,
            cantidadDevolucionTotal: 0
            // grupoProducto: detalle.grupoProducto,
            // unidadMedida: detalle.unidadMedida,
            //stock: detalle.stock
          }
          if (detalle.recurso.tipoRecurso.codigo === 'tipo_recurso_maquina') {
            newEntregaDetalle.costo = detalle.recurso.costoEstandar4Maquina;
          } else if (detalle.recurso.tipoRecurso.codigo === 'tipo_recurso_mano_obra') {
            newEntregaDetalle.costo = detalle.recurso.costoEstandar2Hombre;
          }
          $scope.entrega.entregaMateriaPrimaDetalle.push(newEntregaDetalle);
        }
      });
      $scope.entrega.ordenProduccion = ordenproduccion;
    });
  }

  function getTransaccion(tipos) {
    var tipo = _.filter(tipos, function (elem) {
      return elem.usarProduccion === true && elem.accion.codigo === 'egreso_almacen';
    });
    return tipo[0];
  }

  function activateEdit() {
    $scope.title = 'Editar Entrega de Materia Prima';
    $scope.entregaMp = true;
    $scope.devolucion = false;
    $scope.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      $scope.verCosto = true;
    }
    var idOrdenProduccion = $stateParams.id;
    OrdenProduccionFactory.get(idOrdenProduccion, "OrdenProduccionForm").$promise.then(function (elemento) {
      var ordenproduccion = elemento;
      $scope.entrega = ordenproduccion.entregaMateriaPrima[0];
      $scope.entrega.transaccion = getTransaccion($scope.transacciones);
      $scope.entrega.entregaMateriaPrimaDetalle.sort(function (a, b) {
        return a.codigo - b.codigo;
      });
      _.forEach($scope.entrega.entregaMateriaPrimaDetalle, function (detalle) {
        detalle.cantidadDevolucion = null;
        detalle.cantidadAdicional = null;
        if (detalle.producto) {
          var filtroCosto = {};
          var fecha = moment(new Date()).format('DD/MM/YYYY');

          filtroCosto.search = filterFactory
            .and([{
              path: 'producto.id',
              equals: detalle.producto.id
            }, {
              path: 'dia',
              equalOrBefore: fecha
            }, {
              path: 'dia',
              sortDesc: 'true'
            }]).value();

          ProductoAlmacenFactory.all(filtroCosto).$promise.then(function (productosAlmacen) {
            if (productosAlmacen != undefined && productosAlmacen.length > 0) {
              var costo = parseFloat(productosAlmacen[0].ultimoCostoIVA);
              var costoUnitario = parseFloat(productosAlmacen[0].ultimoCostoIVA);
              if (!Number.isInteger(costo)) {
                costo = parseFloat(costo.toFixed(8));
              }
              if (!Number.isInteger(costoUnitario)) {
                costoUnitario = parseFloat(costo.toFixed(8));
              }
              detalle.costo = costo;
              detalle.costoUnitario = costoUnitario;
              var stock = productosAlmacen[0].saldoReal;
              if (stock != null && !Number.isInteger(stock)) {
                stock = parseFloat(stock.toFixed(8));
              }
              detalle.stock = stock;
            }
          });
        }
      });
      $scope.entidadId = $scope.entrega.id;
      $scope.entidad = "EntregaMateriaPrima";
      $scope.isEditing = true;
    });
  }

  function activateView() {
    $scope.view = true;
    $scope.entregaMp = true;
    $scope.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      $scope.verCosto = true;
    }
    $scope.title = 'Ver Entrega de Materia Prima';
    var idOrdenProduccion = $stateParams.id;
    OrdenProduccionFactory.get(idOrdenProduccion, "OrdenProduccionForm").$promise.then(function (elemento) {
      var ordenproduccion = elemento;
      $scope.entrega = ordenproduccion.entregaMateriaPrima[0];
      $scope.entrega.transaccion = getTransaccion($scope.transacciones);
      _.forEach($scope.entrega.entregaMateriaPrimaDetalle, function (detalle) {
        //detalle.cantidadAdicional = null;
        // detalle.cantidadDevolucion = null;
        if (detalle.producto) {
          var filtroCosto = {};
          var fecha = moment(new Date()).format('DD/MM/YYYY');

          filtroCosto.search = filterFactory
            .and([{
              path: 'producto.id',
              equals: detalle.producto.id
            }, {
              path: 'dia',
              equalOrBefore: fecha
            }, {
              path: 'dia',
              sortDesc: 'true'
            }]).value();

          ProductoAlmacenFactory.all(filtroCosto).$promise.then(function (productosAlmacen) {
            if (productosAlmacen != undefined && productosAlmacen.length > 0) {
              var costo = parseFloat(productosAlmacen[0].ultimoCostoIVA);
              var costoUnitario = parseFloat(productosAlmacen[0].ultimoCostoIVA);
              if (!Number.isInteger(costo)) {
                costo = parseFloat(costo.toFixed(8));
              }
              if (!Number.isInteger(costoUnitario)) {
                costoUnitario = parseFloat(costo.toFixed(8));
              }
              detalle.costo = costo;
              detalle.costoUnitario = costoUnitario;
              var stock = productosAlmacen[0].saldoReal;
              if (stock != null && !Number.isInteger(stock)) {
                stock = parseFloat(stock.toFixed(8));
              }
              detalle.stock = stock;
            }
          });
        }
      });
      $scope.entidadId = $scope.entrega.id;
      $scope.entidad = "EntregaMateriaPrima";
    });
  }

  function submit() {
    $scope.submited = true;
    if ($scope.EntregaForm.$valid) {
      $rootScope.isProcessing = true;

      submitEntrega().then(function (response) {
        $state.go('app.ordenproduccion.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        errorHandler(error);
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function errorHandler(e) {
    var msg = "";
    _.forEach(e.data, function (error) {
      msg += "\n\n" + error.message + ".";
    });

    notify({ message: msg, classes: "alert-danger", position: "right" });
  }

  function submitEntrega() {
    var trimmedModel = ModelTrimmer.trimDetails($scope.entrega, [
      "entregaMateriaPrimaDetalle"]);

    if (trimmedModel.entregaMateriaPrimaDetalle) {
      trimmedModel.entregaMateriaPrimaDetalle = _.map(trimmedModel.entregaMateriaPrimaDetalle, function (
        detalle
      ) {
        return ModelTrimmer.trimDetails(detalle);
      });
    }

    return EntregaFactory.save(trimmedModel);
  }


  function cancel() {
    $state.go("app.ordenproduccion.list");
  }

  $scope.resource = 'entrega';
}

"use strict";

angular
  .module("qualita.stock")
  .controller(
    "ProcesarFormCtrl",
    ProcesarFormCtrl
  );

ProcesarFormCtrl.$inject = [
  "OrdenProduccionFactory",
  "EntregaFactory",
  "filterFactory",
  "$scope",
  "notify",
  "$state",
  "$rootScope",
  "$stateParams",
  "ModelTrimmer",
  "StockLangFactory",
  "MaterialesFactory",
  "ListaMaterialesFactory",
  "TransaccionStockFactory",
  "$modal", "ParametrosFactory", "GruposProductosFactory",
  "ReportTicketFactory", "$window", "AuthorizationService"
];

function ProcesarFormCtrl(
  OrdenProduccionFactory,
  EntregaFactory,
  filterFactory,
  $scope,
  notify,
  $state,
  $rootScope,
  $stateParams,
  ModelTrimmer,
  StockLangFactory,
  MaterialesFactory,
  ListaMaterialesFactory,
  TransaccionStockFactory,
  $modal, ParametrosFactory, GruposProductosFactory,
  ReportTicketFactory, $window, AuthorizationService
) {
  activate();

  $scope.submit = submit;
  $scope.cancel = cancel;
  $scope.costoChanged = costoChanged;
  $scope.verificarExistencia = verificarExistencia;
  $scope.changeFecha = changeFecha;
  $scope.imprimirMovimientos = imprimirMovimientos;

  function imprimirMovimientos() {
    var ordenProduccionId = $stateParams.id;
    $scope.mensajeModal = "Desea imprimir el Resumen de Movimientos";
    $scope.tituloModal = "Resumen de Movimientos";
    var modalInstance = $modal.open({
      templateUrl: "views/directives/confirmation-modal.html",
      scope: $scope
    });

    $scope.cancel = function () {
      modalInstance.dismiss("cancel");
    };

    $scope.ok = function (id) {
      $scope.verCosto = false;
      if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
        $scope.verCosto = true;
      }
      var filtersArr = [];
      filtersArr.push("idordenproduccion=" + ordenProduccionId);
      filtersArr.push("cantidadFinal="+ $scope.entrega.entregaMateriaPrimaDetalle[0].cantidadProductoTerminado);
      filtersArr.push("costoUnitarioFinal="+$scope.entrega.entregaMateriaPrimaDetalle[0].costoUnitario);
      filtersArr.push("costoFinalTotal="+Math.round($scope.entrega.entregaMateriaPrimaDetalle[0].cantidadProductoTerminado*$scope.entrega.entregaMateriaPrimaDetalle[0].costoUnitario));
      filtersArr.push("verCosto="+$scope.verCosto);
      modalInstance.close();
      $state.go($state.current, {}, { reload: true });
      $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
        "resumenmovimientoordenproduccion",
        "pdf",
        filtersArr
      );
      $window.open($scope.pdfDownloadURL, "_blank");
      $state.go($state.current, {}, { reload: true });
    }
  }

  function changeFecha(detalle) {
    var params = {};
    params.search = filterFactory.and([{
      path: 'numero',
      equals: detalle.numeroGrupo
    },
    {
      path: 'producto.id',
      equals: detalle.producto.id
    }
    ]).value();
    GruposProductosFactory.all(params).$promise.then(function (response) {
      //buscar si existe en grupo productos
      if (response.length > 0) {
        notify({
          message: "El nro. grupo ya cuenta con fecha de vencimiento, no puede cambiar.",
          classes: "alert-warning",
          position: "right"
        });
        detalle.fechaVencimientoGrupo = moment(response[0].fechaVencimiento, "DD/MM/YYYY").toDate();
      }
    })
  }

  function verificarExistencia(detalle) {
    if (detalle.numeroGrupo != null) {
      var params = {};
      params.search = filterFactory.and([{
        path: 'numero',
        equals: detalle.numeroGrupo
      },
      {
        path: 'producto.id',
        equals: detalle.producto.id
      }
      ]).value();
      GruposProductosFactory.all(params).$promise.then(function (response) {
        //buscar si existe en grupo productos
        if (response.length > 0) {
          detalle.fechaVencimientoGrupo = moment(response[0].fechaVencimiento, "DD/MM/YYYY").toDate();
          // vm.existeGrupo = true;
        } else {
          // vm.existeGrupo = false;
          detalle.fechaVencimientoGrupo = null;
        }
      })
    } else {
      // vm.existeGrupo = false;
      detalle.fechaVencimientoGrupo = null;
    }
  }

  ParametrosFactory.getByCodigo('asignar_lote_sin_criterio').then(function (parametro) {
    if (parametro.valorTipo.codigo === 'no') {
      $scope.asignarLoteSinCriterio = false;
    } else {
      $scope.asignarLoteSinCriterio = true;
    }
  });

  function activate() {
    StockLangFactory.getTranslations().then(function (translations) {

      $scope.translations = translations;
      $rootScope.isProcessing = false;

      var staticFilter = {};
      staticFilter.search = filterFactory.and([{
        path: 'usarProduccion',
        like: 'true'
      }, {
        path: 'activo',
        equals: true
      }]).value();
      staticFilter.view = "BaseList";
      $scope.transacciones = TransaccionStockFactory.all(staticFilter);

      if ($state.is('app.ordenproduccion.procesaradd')) {
        activateNew();
      } else if ($state.is('app.ordenproduccion.procesaredit')) {
        activateEdit();
      } else if ($state.is('app.ordenproduccion.procesarview')) {
        activateView();
      }
    });
  }

  function activateNew() {
    $scope.title = 'Crear Recepción de Producto Terminado';
    $scope.procesarMp = true;
    $scope.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      $scope.verCosto = true;
    }
    var idOrdenProduccion = $stateParams.id;
    OrdenProduccionFactory.get(idOrdenProduccion, "OrdenProduccionForm").$promise.then(function (elemento) {
      var ordenproduccion = elemento;
      $scope.entrega = {
        transaccion: getTransaccion($scope.transacciones),
        almacen: ordenproduccion.almacen,
        almacenproductosterminados: ordenproduccion.almacenproductosterminados,
        entregaMateriaPrimaDetalle: [],
        entrega: false,
        procesar: true,
        devolucion: false
      };
      var newEntregaDetalle = {
        codigo: parseFloat(ordenproduccion.numero),
        descripcion: ordenproduccion.producto.nombre,
        cantidadRequerida: ordenproduccion.cantidadPlanificada,
        almacen: ordenproduccion.almacen,
        almacenproductosterminados: ordenproduccion.almacenproductosterminados,
        producto: ordenproduccion.producto
      };
      //calculo del costo del producto nuevo final
      //sumatoria de la cantidad por el costo de los productos + costo de los recursos
      var costoProductos = 0;
      _.forEach(ordenproduccion.entregaMateriaPrima, function (materia) {
        if (materia.entrega) {
        //  if (materia.transaccion.accion.codigo === 'egreso_almacen') {
            _.forEach(materia.entregaMateriaPrimaDetalle, function (matdet) {
              costoProductos += matdet.cantidadEntregada * matdet.costo;

            });
          //}
         // _.forEach(materia.ordenProduccion.ordenProduccionDetalle, function (recdet) {
           // if (recdet.tipo.codigo === 'tipo_recurso') {
             // costoProductos += recdet.cantidadRequerida * recdet.costo;
            //}
          //});
        }
      });
      var costoDevolucion = 0;
      _.forEach(ordenproduccion.entregaMateriaPrima, function (materia) {
        if (materia.devolucion) {
          _.forEach(materia.entregaMateriaPrimaDetalle, function (matdet) {

            if (matdet.cantidadDevolucion != null) {
              costoDevolucion += matdet.cantidadDevolucion * matdet.costo;
            }
          });
        }
      });
      newEntregaDetalle.costo = costoProductos - costoDevolucion;

      listarMateriales(ordenproduccion.producto.id, function (listamateriales) {
        newEntregaDetalle.cantidadPlanificada = listamateriales[0].cantidadBase * newEntregaDetalle.cantidadRequerida
        newEntregaDetalle.cantidadProductoTerminado = listamateriales[0].cantidadBase * newEntregaDetalle.cantidadRequerida
        newEntregaDetalle.costoUnitario = Math.round((newEntregaDetalle.costo / newEntregaDetalle.cantidadProductoTerminado) * 100000000) / 100000000;
      });
      newEntregaDetalle.costo = Math.round((newEntregaDetalle.costo) * 100000000) / 100000000;
      $scope.entrega.entregaMateriaPrimaDetalle.push(newEntregaDetalle);
      $scope.entrega.ordenProduccion = ordenproduccion;
    });
  }

  function getTransaccion(tipos) {
    var tipo = _.filter(tipos, function (elem) {
      return elem.usarProduccion === true && elem.accion.codigo === 'ingreso_almacen';
    });
    return tipo[0];
  }

  function activateEdit() {
    $scope.title = 'Editar Recepción de Producto Terminado';
    $scope.procesarMp = true;
    $scope.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      $scope.verCosto = true;
    }
    var idOrdenProduccion = $stateParams.id;
    OrdenProduccionFactory.get(idOrdenProduccion, "OrdenProduccionForm").$promise.then(function (elemento) {
      var ordenproduccion = elemento;
      _.forEach(ordenproduccion.entregaMateriaPrima, function (materia) {
        if (materia.transaccion.accion.codigo === 'ingreso_almacen') {
          $scope.entrega = materia;
          _.forEach(materia.entregaMateriaPrimaDetalle, function (entregaMateriaPrimaDetalle) {
            listarMateriales(entregaMateriaPrimaDetalle.producto.id, function (listamateriales) {
              entregaMateriaPrimaDetalle.cantidadPlanificada = listamateriales[0].cantidadBase * entregaMateriaPrimaDetalle.cantidadRequerida
              entregaMateriaPrimaDetalle.cantidadProductoTerminado = listamateriales[0].cantidadBase * entregaMateriaPrimaDetalle.cantidadRequerida
              entregaMateriaPrimaDetalle.costoUnitario = Math.round((entregaMateriaPrimaDetalle.costo / entregaMateriaPrimaDetalle.cantidadProductoTerminado) * 100000000) / 100000000;
            });
            entregaMateriaPrimaDetalle.costo = Math.round((entregaMateriaPrimaDetalle.costo) * 100000000) / 100000000;
          })
        }
      });
      $scope.entidadId = $scope.entrega.id;
      $scope.entidad = "EntregaMateriaPrima";
      $scope.isEditing = true;
    });
  }

  function activateView() {
    $scope.view = true;
    $scope.procesarMp = true;
    $scope.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      $scope.verCosto = true;
    }
    $scope.title = 'Ver Recepción de Producto Terminado';
    var idOrdenProduccion = $stateParams.id;
    OrdenProduccionFactory.get(idOrdenProduccion, "OrdenProduccionForm").$promise.then(function (elemento) {
      var ordenproduccion = elemento;
      _.forEach(ordenproduccion.entregaMateriaPrima, function (materia) {
        if (materia.transaccion.accion.codigo === 'ingreso_almacen') {
          $scope.entrega = materia;
        }
      });
      $scope.entidadId = $scope.entrega.id;
      $scope.entidad = "EntregaMateriaPrima";
    });
  }

  function submit() {
    $scope.submited = true;
    if ($scope.EntregaForm.$valid) {
      _.forEach($scope.entrega.entregaMateriaPrimaDetalle, function (materia) {
        if (materia.cantidadPlanificada != materia.cantidadProductoTerminado) {
          $scope.tituloModal = 'Diferencia';
          $scope.mensajeModal = 'Existen diferencias entre lo planificado y terminado, desea continuar?';
          var templateTmp = '<div class="modal-header">' +
            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
            "</div>" +
            '<div class="modal-body">{{::mensajeModal}}</div>' +
            '<div class="modal-footer">' +
            '<button class="btn btn-primary" ng-click="guardar()">' +
            'Si' +
            "</button>" +
            '<button class="btn btn-warning" ng-click="cancel()">' +
            'No' +
            "</button>";
          $scope.modalInstanceBorrar1 = $modal.open({
            template: templateTmp,
            scope: $scope
          });
          $scope.cancel = function () {
            $rootScope.isProcessing = false;
            $scope.modalInstanceBorrar1.dismiss("cancel");
          };
          $scope.guardar = function () {
            materia.diferenciaPlanificadaTerminado = materia.cantidadPlanificada - materia.cantidadProductoTerminado;
            $rootScope.isProcessing = true;
            submitEntrega().then(function (response) {
              $state.go('app.ordenproduccion.list');
            }, function (error) {
              $rootScope.isProcessing = false;
              errorHandler(error);
            });
            $scope.modalInstanceBorrar1.dismiss();
          };

        } else {
          $rootScope.isProcessing = true;
          submitEntrega().then(function (response) {
            $state.go('app.ordenproduccion.list');
          }, function (error) {
            $rootScope.isProcessing = false;
            errorHandler(error);
          });
        }
      });
    }
  }

  function costoChanged(detalle) {
    detalle.costoUnitario = parseFloat((detalle.costo / detalle.cantidadProductoTerminado).toFixed(4));
  }

  function errorHandler(e) {
    var msg = "";
    _.forEach(e.data, function (error) {
      msg += "\n\n" + error.message + ".";
    });

    notify({ message: msg, classes: "alert-danger", position: "right" });
  }

  function submitEntrega() {
    var trimmedModel = ModelTrimmer.trimDetails($scope.entrega, [
      "entregaMateriaPrimaDetalle"]);

    if (trimmedModel.entregaMateriaPrimaDetalle) {
      trimmedModel.entregaMateriaPrimaDetalle = _.map(trimmedModel.entregaMateriaPrimaDetalle, function (
        detalle
      ) {
        return ModelTrimmer.trimDetails(detalle);
      });
    }

    return EntregaFactory.save(trimmedModel);
  }

  function listarMateriales(idProducto, callback) {
    var filterMateriales = {
      search: filterFactory
        .and([
          {
            path: "estado.codigo",
            equals: "tipo_estado_materiales_activado"
          },
          {
            path: "producto.id",
            equals: idProducto
          }
        ]
        )
        .value()
    };
    MaterialesFactory.all(filterMateriales).$promise.then(function (response) {
      var idMateriales = response[0].id;
      var filterListaMateriales = {
        search: filterFactory
          .and([
            {
              path: "estado.codigo",
              equals: "tipo_estado_lista_materiales_activada"
            },
            {
              path: "materiales.id",
              equals: idMateriales
            }
          ]
          )
          .value()
      };
      ListaMaterialesFactory.all(filterListaMateriales).$promise.then(function (listamateriales) {
        return callback(listamateriales);
      });
    })
  }

  function cancel() {
    $state.go("app.ordenproduccion.list");
  }

  $scope.resource = 'entrega';
}

"use strict";

angular
  .module("qualita.stock")
  .controller(
    "DevolucionFormCtrl",
    DevolucionFormCtrl
  );

DevolucionFormCtrl.$inject = [
  "OrdenProduccionFactory",
  "EntregaFactory",
  "entregaPrepService",
  "filterFactory",
  "$scope",
  "notify",
  "$state",
  "$rootScope",
  "$timeout",
  "$log",
  "$translate",
  "$q",
  "$modal",
  "$stateParams",
  "$filter",
  "TiposTipoFactory",
  "ModelTrimmer",
  "baseurl",
  "StockLangFactory",
  "TransaccionStockFactory",
  "ProductoAlmacenFactory", "ParametrosFactory",
  "AuthorizationService"
];

function DevolucionFormCtrl(
  OrdenProduccionFactory,
  EntregaFactory,
  entregaPrepService,
  filterFactory,
  $scope,
  notify,
  $state,
  $rootScope,
  $timeout,
  $log,
  $translate,
  $q,
  $modal,
  $stateParams,
  $filter,
  TiposTipoFactory,
  ModelTrimmer,
  baseurl,
  StockLangFactory,
  TransaccionStockFactory,
  ProductoAlmacenFactory,ParametrosFactory,
  AuthorizationService
) {
  activate();

  $scope.submit = submit;
  $scope.cancel = cancel;

  ParametrosFactory.getByCodigo('asignar_lote_sin_criterio').then(function (parametro) {
    if (parametro.valorTipo.codigo === 'no') {
      $scope.asignarLoteSinCriterio = false;
    } else {
      $scope.asignarLoteSinCriterio = true;
    }
  });

  function activate() {
    StockLangFactory.getTranslations().then(function (translations) {

      $scope.translations = translations;
      $rootScope.isProcessing = false;

      var staticFilter = {};
      staticFilter.search = filterFactory.and([{
        path: 'usarDevolucionProduccion',
        like: 'true'
      }, {
        path: 'activo',
        equals: true
      }]).value();
      staticFilter.view = "BaseList";
      $scope.transacciones = TransaccionStockFactory.all(staticFilter);


      if ($state.is('app.ordenproduccion.devolucionadd')) {
        activateNew();
      } else if ($state.is('app.ordenproduccion.devolucionedit')) {
        activateEdit();
      } else if ($state.is('app.ordenproduccion.devolucionview')) {
        activateView();
      }
    });
  }

  function activateNew() {
    $scope.new = true;
    $scope.title = 'Crear Devolucion de Materia Prima';
    $scope.entregaMp = false;
    $scope.devolucion = true;
    $scope.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      $scope.verCosto = true;
    }
    var idOrdenProduccion = $stateParams.id;
    OrdenProduccionFactory.get(idOrdenProduccion, "OrdenProduccionForm").$promise.then(function (elemento) {
      var ordenproduccion = elemento;
      $scope.entrega = {
        transaccion: getTransaccion($scope.transacciones),
        almacen: ordenproduccion.almacen,
        almacenproductosterminados: ordenproduccion.almacenproductosterminados,
        entregaMateriaPrimaDetalle: [],
        entrega: false,
        procesar: false,
        devolucion: true
      };
      elemento.ordenProduccionDetalle.sort(function (a, b) {
        return a.codigo - b.codigo;
      });
      _.forEach(elemento.entregaMateriaPrima, function (cabecera) {
        if (cabecera.entrega === true) {
          _.forEach(cabecera.entregaMateriaPrimaDetalle, function (detalle) {
            var newEntregaDetalle = {};
            if(detalle.producto){
              newEntregaDetalle = {
                codigo: detalle.codigo,
                descripcion: detalle.producto.nombre,
                cantidadRequerida: detalle.cantidadRequerida,
                cantidadEntregada: detalle.cantidadEntregada,
                almacen: detalle.almacen,
                almacenproductosterminados: detalle.almacenproductosterminados,
                producto: detalle.producto,
                grupoProducto : detalle.grupoProducto,
                unidadMedida:detalle.unidadMedida,
                cantidadAdicional: 0,
                cantidadAdicionalTotal: 0,
                cantidadDevolucion: 0,
                cantidadDevolucionTotal: 0
              };
              var filtroCosto = {};
              var fecha = moment(new Date()).format('DD/MM/YYYY');

              filtroCosto.search = filterFactory
                .and([{
                  path: 'producto.id',
                  equals: detalle.producto.id
                }, {
                  path: 'dia',
                  equalOrBefore: fecha
                }, {
                  path: 'dia',
                  sortDesc: 'true'
                }]).value();

              ProductoAlmacenFactory.all(filtroCosto).$promise.then(function (productosAlmacen) {
                if (productosAlmacen != undefined && productosAlmacen.length > 0) {
                  var costo = parseFloat(productosAlmacen[0].ultimoCostoIVA);
                  var costoUnitario = parseFloat(productosAlmacen[0].ultimoCostoIVA);
                  if (!Number.isInteger(costo)) {
                    costo = parseFloat(costo.toFixed(8));
                  }
                  if (!Number.isInteger(costoUnitario)) {
                    costoUnitario = parseFloat(costo.toFixed(8));
                  }
                  newEntregaDetalle.costo = costo;
                  newEntregaDetalle.costoUnitario = costoUnitario;
                  var stock = productosAlmacen[0].saldoReal;
                  if (stock != null && !Number.isInteger(stock)) {
                    stock = parseFloat(stock.toFixed(8));
                  }
                  newEntregaDetalle.stock = stock;
                }
              });
            }else{
              newEntregaDetalle = {
                codigo: detalle.codigo,
                descripcion: detalle.recurso.descripcion,
                cantidadRequerida: detalle.cantidadRequerida,
                cantidadEntregada: detalle.cantidadEntregada,
                //almacen: detalle.almacen,
                recurso: detalle.recurso,
                cantidadAdicional: 0,
                cantidadAdicionalTotal: 0,
                cantidadDevolucion: 0,
                cantidadDevolucionTotal: 0
                //grupoProducto : detalle.grupoProducto,
               // unidadMedida:detalle.unidadMedida
              };
              if(detalle.recurso.tipoRecurso.codigo === 'tipo_recurso_maquina'){
                newEntregaDetalle.costo = detalle.recurso.costoEstandar4Maquina;
              }else if(detalle.recurso.tipoRecurso.codigo === 'tipo_recurso_mano_obra'){
                newEntregaDetalle.costo = detalle.recurso.costoEstandar2Hombre;
              }
            }


            $scope.entrega.entregaMateriaPrimaDetalle.push(newEntregaDetalle);
          });

        }
      });

      $scope.entrega.ordenProduccion = ordenproduccion;
    });
  }

  function getTransaccion(tipos) {
    var tipo = _.filter(tipos, function (elem) {
      return elem.usarDevolucionProduccion === true && elem.accion.codigo === 'ingreso_almacen';
    });
    return tipo[0];
  }

  function activateEdit() {
    $scope.title = 'Editar Devolucion de Materia Prima';
    $scope.entregaMp = false;
    $scope.devolucion = true;
    $scope.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      $scope.verCosto = true;
    }
    var idOrdenProduccion = $stateParams.id;
    OrdenProduccionFactory.get(idOrdenProduccion, "OrdenProduccionForm").$promise.then(function (elemento) {
      var ordenproduccion = elemento;
      $scope.entrega = ordenproduccion.entregaMateriaPrima[0];
      $scope.entrega.transaccion = getTransaccion($scope.transacciones);

      $scope.entrega.entregaMateriaPrimaDetalle.sort(function (a, b) {
        return a.codigo - b.codigo;
      });
      _.forEach($scope.entrega.entregaMateriaPrimaDetalle, function (detalle) {
        detalle.cantidadDevolucion = null;
        detalle.cantidadAdicional = null;
        if(detalle.producto){
          var filtroCosto = {};
          var fecha = moment(new Date()).format('DD/MM/YYYY');

          filtroCosto.search = filterFactory
            .and([{
              path: 'producto.id',
              equals: detalle.producto.id
            }, {
              path: 'dia',
              equalOrBefore: fecha
            }, {
              path: 'dia',
              sortDesc: 'true'
            }]).value();

          ProductoAlmacenFactory.all(filtroCosto).$promise.then(function (productosAlmacen) {
            if (productosAlmacen != undefined && productosAlmacen.length > 0) {
              var costo = parseFloat(productosAlmacen[0].ultimoCostoIVA);
              var costoUnitario = parseFloat(productosAlmacen[0].ultimoCostoIVA);
              if (!Number.isInteger(costo)) {
                costo = parseFloat(costo.toFixed(8));
              }
              if (!Number.isInteger(costoUnitario)) {
                costoUnitario = parseFloat(costo.toFixed(8));
              }
              detalle.costo = costo;
              detalle.costoUnitario = costoUnitario;
              var stock = productosAlmacen[0].saldoReal;
              if (stock != null && !Number.isInteger(stock)) {
                stock = parseFloat(stock.toFixed(8));
              }
              detalle.stock = stock;
            }
          });
        }
      });
      $scope.entidadId = $scope.entrega.id;
      $scope.entidad = "EntregaMateriaPrima";
      $scope.isEditing = true;
    });
  }

  function activateView() {
    $scope.view = true;
    $scope.entregaMp = false;
    $scope.devolucion = true;
    $scope.verCosto = false;
    if (AuthorizationService.hasPermission("index_costoordenproduccion")) {
      $scope.verCosto = true;
    }
    $scope.title = 'Ver Devolucion de Materia Prima';
    var idOrdenProduccion = $stateParams.id;
    OrdenProduccionFactory.get(idOrdenProduccion, "OrdenProduccionForm").$promise.then(function (elemento) {
      var ordenproduccion = elemento;
      $scope.entrega = ordenproduccion.entregaMateriaPrima[0];
      _.forEach($scope.entrega.entregaMateriaPrimaDetalle, function (detalle) {
        detalle.cantidadDevolucion = null;
        detalle.cantidadAdicional = null;
    });
      $scope.entidadId = $scope.entrega.id;
      $scope.entidad = "EntregaMateriaPrima";
    });
  }

  function submit() {
    $scope.submited = true;
    if ($scope.EntregaForm.$valid) {
      $rootScope.isProcessing = true;

      submitEntrega().then(function (response) {
        $state.go('app.ordenproduccion.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        errorHandler(error);
      });
    }
  }

  function errorHandler(e) {
    var msg = "";
    _.forEach(e.data, function (error) {
      msg += "\n\n" + error.message + ".";
    });

    notify({ message: msg, classes: "alert-danger", position: "right" });
  }

  function submitEntrega() {
    var trimmedModel = ModelTrimmer.trimDetails($scope.entrega, [
      "entregaMateriaPrimaDetalle"]);

    if (trimmedModel.entregaMateriaPrimaDetalle) {
      trimmedModel.entregaMateriaPrimaDetalle = _.map(trimmedModel.entregaMateriaPrimaDetalle, function (
        detalle
      ) {
        return ModelTrimmer.trimDetails(detalle);
      });
    }

    return EntregaFactory.save(trimmedModel);
  }


  function cancel() {
    $state.go("app.ordenproduccion.list");
  }

  $scope.resource = 'entrega';
}

'use strict';

angular.module('qualita.stock')
  .factory('MovimientoStockManualFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
  '$translatePartialLoader', '$translate',
    function($resource,  baseurl, filterFactory, TiposFactory, $http, $translatePartialLoader, $translate) {

  	var MovimientoStockManual= $resource( baseurl.getBaseUrl() + '/movimientostockmanual/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params) {
        return MovimientoStockManual.query(params);
      },

      get: function(id, view) {
        return MovimientoStockManual.get({id: id, view: view!=undefined ? view : 'base'});
      },

      create: function(attrs) {
        return new MovimientoStockManual(attrs);
      },

      save: function(movimientoStockManual) {
        return (movimientoStockManual.id) ? movimientoStockManual.$update() : movimientoStockManual.$save();
      },

      remove: function(movimientoStockManual) {
        return movimientoStockManual.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      },

      confirmar: function (id){
        return $http({
                  url: baseurl.getBaseUrl() + '/movimientostockmanual/confirmar/'+id,
                  method: "PUT"
               });
      },

      reversar: function (id){
        return $http({
              url: baseurl.getBaseUrl() + '/movimientostockmanual/'+id+'/reversar',
              method: "PUT"
           });
      }
    };
  }]);

'use strict';

angular.module('qualita.stock')
    .controller('MovimientoStockManualFormController', ['$rootScope', '$scope', 'MovimientoStockManualFactory', 'ProductosFactory',
        'formFactory', 'TransaccionStockFactory', 'AlmacenFactory', 'TiposFactory', '$location', '$state', 'movimientoStockManualPrepService',
        '$timeout', 'notify', 'ModelTrimmer', 'filterFactory', 'StockLangFactory', 'ParametrosFactory', '$q',
        "WatchLocacionChangeFactory", "UtilFactory", "$filter", "ClienteFactory",
        "timbradoEmpresaFactory", "puntoEmisionFactory", "TipoComprobanteFactory", "TimbradoEmpresaPuntoEmisionVentaFactory",
        "ComprobanteVentaFactory", "GrupoProductoAlmacenFactory", "ConceptoMovimientoFactory", "ProyectoFactory", "RemisionFactory", "AuthorizationService",
        function ($rootScope, $scope, MovimientoStockManualFactory, ProductosFactory,
            formFactory, TransaccionStockFactory, AlmacenFactory, TiposFactory, $location, $state, movimientoStockManualPrepService,
            $timeout, notify, ModelTrimmer, filterFactory, StockLangFactory, ParametrosFactory, $q,
            WatchLocacionChangeFactory, UtilFactory, $filter, ClienteFactory, timbradoEmpresaFactory,
            puntoEmisionFactory, TipoComprobanteFactory, TimbradoEmpresaPuntoEmisionVentaFactory,
            ComprobanteVentaFactory, GrupoProductoAlmacenFactory, ConceptoMovimientoFactory, ProyectoFactory, RemisionFactory, AuthorizationService) {

            var vm = this;

            vm.uiBlockuiConfig = {
                'bloquear': false
            };

            activate();

            vm.agregarDetalle = agregarDetalle;
            vm.removeItemFromArray = removeItemFromArray;
            vm.submit = submit;
            vm.cancel = cancel;
            vm.changeProducto = changeProducto;
            vm.depositoOrigenChanged = depositoOrigenChanged;
            vm.depositoDestinoChanged = depositoDestinoChanged;
            vm.transaccionSelected = transaccionSelected;
            vm.getRemision = getRemision;
            vm.validarNroFactura = validarNroFactura;
            vm.searchTimbradoPuntoEmisionFilter = searchTimbradoPuntoEmisionFilter;
            vm.timbradoSelect = timbradoSelect;
            vm.puntoEmisionSelected = puntoEmisionSelected;
            $rootScope.isProcessing = false;
            vm.nroOrden = false;
            vm.nroMov = false;
            vm.changeCliente = changeCliente;
            vm.delayRequestCliente = delayRequestCliente;
            vm.searchClienteFilter = searchClienteFilter;
            vm.searchSucursalClienteFilter = searchSucursalClienteFilter;
            vm.curDate = new Date();
            vm.changeFecha = changeFecha;

            function changeFecha() {
                if (vm.movimientoStockManual.hora.split(':')[0] == '00' && vm.movimientoStockManual.hora.split(':')[1] == '00') {
                    var msg = "La hora no puede ser 00:00 porque esta reservado para Compras";
                    notify({ message: msg, classes: "alert-warning", position: "right" })
                    vm.movimientoStockManual.hora = moment(vm.curDate).format("HH:mm");
                    return;
                }
            }

            function searchSucursalClienteFilter(criteria) {
                return function (item) {
                    if (!criteria) {
                        return true;
                    }
                    return (
                        item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                        (item.codigoSucursal.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                        (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                        (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
                    );
                };
            }

            function changeCliente() {
                vm.sucursalesCliente = vm.movimientoStockManual.entidadPrincipal.sucursales;
                if (vm.sucursalesCliente.length == 1) {
                    vm.movimientoStockManual.sucursalEntidad = vm.sucursalesCliente[0];
                } else {
                    vm.movimientoStockManual.sucursalEntidad = null;
                }
            }

            function delayRequestCliente(searchValue) {
                var userInputFilter = {}
                if (searchValue.length >= 3) {
                    var filterNombre = [];
                    var filterNombreFantasia = [];
                    var filterCodigo = [];
                    var filterNumero = [];
                    var filterDireccion = [];
                    var filterCiudad = [];

                    filterNombre.push({
                        path: "nombre",
                        like: searchValue
                    })
                    filterNombreFantasia.push({
                        path: "nombreFantasia",
                        like: searchValue
                    })
                    filterCodigo.push({
                        path: "codigo",
                        like: searchValue
                    })
                    filterNumero.push({
                        path: "numero",
                        like: searchValue
                    })
                    filterDireccion.push({
                        path: "direccion",
                        like: searchValue
                    })
                    filterCiudad.push({
                        path: "ciudad.descripcion",
                        like: searchValue
                    })

                    userInputFilter = {
                        search: filterFactory.or([
                            filterFactory.and(filterNombre).value(),
                            filterFactory.and(filterNombreFantasia).value(),
                            filterFactory.and(filterCodigo).value(),
                            filterFactory.and(filterNumero).value(),
                            filterFactory.and(filterDireccion).value(),
                            filterFactory.and(filterCiudad).value()
                        ]).value()
                    }

                    recuperarCliente(userInputFilter)
                } else {
                    vm.clientes = undefined
                }
            }

            function recuperarCliente(userInputFilter) {
                var finalFilter = UtilFactory.createNestedFilters(
                    vm.finalFilters, userInputFilter)

                vm.clientes = ClienteFactory.allForSelect(finalFilter)
            }

            function searchClienteFilter(criteria) {
                return function (item) {
                    if (!criteria) {
                        return true;
                    }
                    return (
                        item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                        (item.nombreFantasia && item.nombreFantasia.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                        (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                        item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                        (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                        (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
                    );
                };
            }

            function activate() {
                WatchLocacionChangeFactory.killAllWatchers();

                ParametrosFactory.filterByCodigo("formato_nro_comprobante").$promise.then(function (response) {
                    vm.formatoComprobante = response[0].valor;
                });
                vm.proyectos = ProyectoFactory.all("", "MovimientoStockManualForm");
                ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
                    vm.proyectoObligatorio = r.valorTipo.codigo == "si";
                });

                StockLangFactory.getTranslations().then(function (translations) {
                    vm.translations = translations;

                    var promise1 = ParametrosFactory.getByCodigo('incluir_nro_orden_produccion');
                    var promise2 = ParametrosFactory.getByCodigo('incluir_nro_mov_materiales');
                    var promises = [promise1, promise2];

                    $q.all(promises).then(function (responses) {
                        if (responses[0].valorTipo.codigo == 'si') {
                            vm.nroOrden = true;
                        }
                        if (responses[1].valorTipo.codigo == 'si') {
                            vm.nroMov = true;
                        }
                        vm.curDate = new Date();
                        var staticFilter = {};
                        staticFilter.search = filterFactory.and([{
                            path: 'usarEnOperacionesManuales',
                            like: 'true'
                        }, {
                            path: 'activo',
                            equals: true
                        }]).value();
                        staticFilter.view = "BaseList";
                        vm.transacciones = TransaccionStockFactory.all(staticFilter);
                        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcherAlmacen) {
                            var staticFilterPE = {
                                search: filterFactory.and([{
                                    path: 'puntoVenta.sucursal.id',
                                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                                }]).value()
                            };
                            staticFilterPE.view = "ConsultaComprobanteVentaForm";
                            vm.puntosEmision = puntoEmisionFactory.all(staticFilterPE);
                            var staticFilterTC = {
                                search: filterFactory.or([
                                    filterFactory.and([{
                                        path: "clase.codigo",
                                        equals: "remision"
                                    },
                                    {
                                        path: "utilizarEnProcesosVenta",
                                        equals: true
                                    }
                                    ]).value()
                                ]).value()
                            };
                            staticFilterTC.view = "BaseList";
                            vm.tiposComprobante = TipoComprobanteFactory.all(staticFilterTC);
                        }, "locacion.id");

                        var staticFilterProductos = {};
                        staticFilterProductos.search = filterFactory.and([
                            {
                                path: 'controlStock',
                                like: 'true'
                            },
                            {
                                path: 'estado.codigo',
                                equals: 'activo'
                            }
                        ]).value();

                        if (vm.watcher) {
                            vm.watcher();
                        }
                        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
                            vm.watcher = watcher;
                        }, "locaciones.id");

                        vm.factoryProductos = ProductosFactory;

                        var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
                            'lineaProducto.descripcion', 'marca.descripcion'
                        ];

                        vm.opcionesProductosDt = {
                            'resource': 'productos',
                            'view': 'ProductosDatatableList',
                            'title': 'Productos',
                            'staticFilter': staticFilterProductos,
                            'factory': ProductosFactory,
                            'defaultColumnOrder': defaultColumnOrder,
                            'columns': [
                                { 'data': 'codigo', 'title': 'Código', visible: false },
                                { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
                                { 'data': 'claseProducto.descripcion', 'title': 'Clase' },
                                { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
                                { 'data': 'lineaProducto.descripcion', 'title': 'Línea' },
                                { 'data': 'marca.descripcion', 'title': 'Marca', 'renderWith': 'emptyRender' },
                            ],
                            'hasOptions': true,
                            'defaultOrderColumn': 0,
                            'defaultOrderDir': "desc"
                        };

                        if ($state.is("app.movimientostockmanual.new")) {
                            activateNew();
                        } else if ($state.is("app.movimientostockmanual.edit")) {
                            activateEdit();
                        } else if ($state.is("app.movimientostockmanual.view")) {
                            activateView();
                        }
                    });
                });
            }


            /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
            vm.staticFilterClientes = [{ path: "activo", equals: true }];
            vm.clientesFactory = ClienteFactory;

            function activateNew() {
                vm.title = 'Nuevo Movimiento Stock Manual';
                vm.movimientoStockManual = {
                    movimientoManualDetalle: [{}],
                };
                vm.movimientoStockManual.fecha = vm.curDate;
                vm.movimientoStockManual.hora = moment(vm.curDate).format("HH:mm");
                TiposFactory.estadosMovimientoStockManual().then(function (data) {
                    vm.tiposEstado = data.data;
                    var estadoIngresado = _.filter(vm.tiposEstado, function (value) {
                        return value.codigo === 'estado_movimiento_stock_manual_ingresado';
                    });
                    vm.movimientoStockManual.estado = estadoIngresado[0];
                });

                $timeout(function () {
                    $scope.$broadcast('formOpened');
                }, 20);

            }

            function activateView() {
                vm.title = 'Ver Movimiento Stock Manual';
                vm.movimientoStockManual = movimientoStockManualPrepService;
                vm.movimientoStockManual.fecha = new Date(vm.movimientoStockManual.fecha);
                vm.entidadId = vm.movimientoStockManual.id;
                vm.entidad = "MovimientoStockManual";
                vm.view = true;
                getRemision();
                console.log("vm.movimientoStockManual ", vm.movimientoStockManual)
                //verificar si tiene el check de solicitud y si es cliente
                vm.habilitarCampos = false;
                if (vm.movimientoStockManual.transaccion.obligatorioSolicitud && vm.movimientoStockManual.transaccion.tipoEntidadRelacionada.codigo == 'entidad_cliente') {
                    vm.habilitarCampos = true;
                }
                vm.habilitarConcepto = false;
                if (vm.movimientoStockManual.transaccion.requiereConcepto) {
                    vm.habilitarConcepto = true;
                }
            }

            function activateEdit() {
                vm.title = 'Editar Movimiento Stock Manual';
                vm.movimientoStockManual = movimientoStockManualPrepService;
                vm.movimientoStockManual.fecha = new Date(vm.movimientoStockManual.fecha);
                vm.entidadId = vm.movimientoStockManual.id;
                vm.entidad = "MovimientoStockManual";
                $timeout(function () {
                    $scope.$broadcast('formOpened');
                }, 20);
                getRemision();
            }

            $scope.delayRequestProducto = function (searchValue) {
                var userInputFilter = {};
                var staticFilter = [];
                if (searchValue.length >= 3) {

                    var filterNombre = staticFilter.slice()
                    var filterCodigoBarras = staticFilter.slice()
                    var filterCodigoInterno = staticFilter.slice()

                    filterNombre.push({
                        path: "nombre",
                        like: searchValue
                    },
                        {
                            path: "estado.codigo",
                            notEquals: 'inactivo'
                        })

                    filterCodigoBarras.push({
                        path: "codigoBarrasPrincipal.codigoBarras",
                        like: searchValue
                    },
                        {
                            path: "estado.codigo",
                            notEquals: 'inactivo'
                        })

                    filterCodigoInterno.push({
                        path: "codigo",
                        like: searchValue
                    },
                        {
                            path: "estado.codigo",
                            notEquals: 'inactivo'
                        })

                    userInputFilter = {
                        search: filterFactory.or([
                            filterFactory.and(filterNombre).value(),
                            filterFactory.and(filterCodigoBarras).value(),
                            filterFactory.and(filterCodigoInterno).value()
                        ]).value()
                    }

                    $scope.recuperarProducto(userInputFilter)
                } else if (searchValue.length > 0 && searchValue.length < 3) {

                    var filterCodigoInterno = staticFilter.slice()
                    filterCodigoInterno.push({
                        path: "codigo",
                        like: searchValue
                    })

                    userInputFilter = {
                        search: filterFactory.or([
                            filterFactory.and(filterCodigoInterno).value()
                        ]).value()
                    }
                    $scope.recuperarProducto(userInputFilter)
                } else {
                    vm.productos = undefined
                }
            }

            $scope.recuperarProducto = function (userInputFilter) {
                var finalFilter = UtilFactory.createNestedFilters(
                    $scope.finalFilters, userInputFilter)

                vm.productos = ProductosFactory.allForSelect(userInputFilter)
            };

            function getRemision() {
                if (!vm.movimientoStockManual.remision) {
                    return;
                }
                if (vm.movimientoStockManual.remision.cliente) {
                    vm.movimientoStockManual.cliente = vm.movimientoStockManual.remision.cliente;
                }

                if (vm.movimientoStockManual.remision.tipoComprobante) {
                    vm.movimientoStockManual.tipo = vm.movimientoStockManual.remision.tipoComprobante;
                }

                if (vm.movimientoStockManual.remision.timbradoPuntoEmision) {
                    vm.movimientoStockManual.timbradoPuntoEmision = vm.movimientoStockManual.remision.timbradoPuntoEmision;
                }

                if (vm.movimientoStockManual.remision.timbradoPuntoEmision) {
                    vm.movimientoStockManual.puntoEmision = vm.movimientoStockManual.remision.timbradoPuntoEmision.puntoEmisionVenta;
                }

                if (vm.movimientoStockManual.remision.timbradoPuntoEmision) {
                    vm.movimientoStockManual.puntoEmision = vm.movimientoStockManual.remision.timbradoPuntoEmision.puntoEmisionVenta;
                }

                if (vm.movimientoStockManual.remision.direccionOrigen) {
                    vm.movimientoStockManual.direccionOrigen = vm.movimientoStockManual.remision.direccionOrigen;
                }

                if (vm.movimientoStockManual.remision.direccionDestino) {
                    vm.movimientoStockManual.direccionDestino = vm.movimientoStockManual.remision.direccionDestino;
                }

                if (vm.movimientoStockManual.remision.fechaInicio) {
                    vm.movimientoStockManual.fechaInicio = vm.movimientoStockManual.remision.fechaInicio;
                }

                if (vm.movimientoStockManual.remision.fechaFin) {
                    vm.movimientoStockManual.fechaFin = vm.movimientoStockManual.remision.fechaFin;
                }

                if (vm.movimientoStockManual.remision.vehiculo) {
                    vm.movimientoStockManual.vehiculo = vm.movimientoStockManual.remision.vehiculo;
                }

                if (vm.movimientoStockManual.remision.nroMatricula) {
                    vm.movimientoStockManual.nroMatricula = vm.movimientoStockManual.remision.nroMatricula;
                }

                if (vm.movimientoStockManual.remision.transportista) {
                    vm.movimientoStockManual.transportista = vm.movimientoStockManual.remision.transportista;
                }

                if (vm.movimientoStockManual.remision.nroDocumentoTransportista) {
                    vm.movimientoStockManual.nroDocumentoTransportista = vm.movimientoStockManual.remision.nroDocumentoTransportista;
                }

                if (vm.movimientoStockManual.remision.conductor) {
                    vm.movimientoStockManual.conductor = vm.movimientoStockManual.remision.conductor;
                }

                if (vm.movimientoStockManual.remision.nroDocumentoConductor) {
                    vm.movimientoStockManual.nroDocumentoConductor = vm.movimientoStockManual.remision.nroDocumentoConductor;
                }

                if (vm.movimientoStockManual.remision.direccionConductor) {
                    vm.movimientoStockManual.direccionConductor = vm.movimientoStockManual.remision.direccionConductor;
                }

                if (vm.movimientoStockManual.remision.motivoTranslado) {
                    vm.movimientoStockManual.motivoTranslado = vm.movimientoStockManual.remision.motivoTranslado;
                }

                if (vm.movimientoStockManual.remision.numero) {
                    vm.movimientoStockManual.numero = vm.movimientoStockManual.remision.numero;
                }

            }

            vm.searchProductoFilter = function (criteria) {
                return function (item) {
                    if (!criteria) {
                        return true;
                    }
                    return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria) === 0);
                };
            }

            function agregarDetalle() {
                ParametrosFactory.getByCodigo("cantidad_items_remision").then(function (r) {
                    if (vm.movimientoStockManual.transaccion.generaRemision) {
                        if (parseInt(r.valor) > vm.movimientoStockManual.movimientoManualDetalle.length) {
                            if (vm.movimientoStockManual.movimientoManualDetalle != null) {
                                vm.movimientoStockManual.movimientoManualDetalle.push({});
                                var rowNumber = vm.movimientoStockManual.movimientoManualDetalle.length - 1;
                                $timeout(function () {
                                    $scope.$broadcast('newItemAdded');
                                }, 20);
                            } else {
                                vm.movimientoStockManual.movimientoManualDetalle = [];
                                vm.movimientoStockManual.movimientoManualDetalle.push({});
                            }
                        } else {
                            var msg = $filter('translate')('ERROR_CANTIDAD_ITEM_REMISION');
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        }
                    } else {
                        if (vm.movimientoStockManual.movimientoManualDetalle != null) {
                            vm.movimientoStockManual.movimientoManualDetalle.push({});
                            var rowNumber = vm.movimientoStockManual.movimientoManualDetalle.length - 1;
                            $timeout(function () {
                                $scope.$broadcast('newItemAdded');
                            }, 20);
                        } else {
                            vm.movimientoStockManual.movimientoManualDetalle = [];
                            vm.movimientoStockManual.movimientoManualDetalle.push({});
                        }
                    }
                });
            }

            function removeItemFromArray(elemento, arreglo) {
                _.remove(arreglo, function (item) {
                    return item === elemento;
                });

                $timeout(function () {
                    $scope.$broadcast('newItemRemoved');
                }, 20);
            }

            function getStockSerieEgreso(detalle) {
                var filterGrupo = [];
                var filterNormal = [];
                vm.almacenesConStock = [];

                ProductosFactory.get(detalle.producto.id, 'BaseList').$promise.then(function (producto) {
                    detalle.producto = producto;
                    detalle.grupo = null;
                    _.forEach(detalle.producto.gruposProducto, function (grupo) {
                        var filter = [{
                            path: "grupoProducto.id",
                            equals: grupo.id
                        },
                        {
                            path: 'almacen.id',
                            equals: vm.movimientoStockManual.depositoOrigen.id
                        }];
                        filterGrupo.push(filterFactory.and(filter).value());
                    });

                    var filterFinal = {
                        search: filterFactory.or(filterGrupo).value()
                    }
                    GrupoProductoAlmacenFactory.all(filterFinal, 'BaseList').$promise.then(function (response) {
                        var grupoConStock = [];
                        if (response.length > 0) {
                            _.forEach(response, function (elem) {
                                var existente = _.find(vm.almacenesConStock, function (stock) {
                                    if (stock.grupoProducto != undefined && elem.grupoProducto.id == stock.grupoProducto.id) {
                                        return stock;
                                    }
                                });
                                if (existente != undefined && existente != null) {
                                    if (elem.dia > existente.dia) {
                                        existente.dia = elem.dia;
                                        existente.saldoReal = elem.saldoReal;
                                    }
                                } else {
                                    vm.almacenesConStock.push(elem);
                                }
                            });

                            _.forEach(detalle.producto.gruposProducto, function (grupo) {
                                var existe = false;
                                _.forEach(vm.almacenesConStock, function (stock) {
                                    if (grupo.id == stock.grupoProducto.id) {
                                        if (stock.saldoReal > 0) {
                                            existe = true;
                                        }
                                    }
                                });
                                if (existe) {
                                    grupoConStock.push(grupo);
                                }
                            });

                        }
                        detalle.producto.gruposProducto = grupoConStock;
                        if (grupoConStock.length == 0) {
                            var msg = $filter('translate')('NO_EXISTE_GRUPO_CON_STOCK') + " (Producto: " + detalle.producto.nombre + ")";
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        }
                    });

                });
            }

            function changeProducto(detalle) {
                detalle.unidadMedidaBase = detalle.producto.unidadMedidaBase;
                detalle.unidadMedidaReferencial = detalle.producto.unidadMedidaBase;
                detalle.ingreso = null;
                detalle.egreso = null;
                detalle.grupo = null;
                if (detalle.producto.claseProducto.tipoAdministracion.codigo == "bobinas") {
                    detalle.producto.gruposProducto = _.filter(detalle.producto.gruposProducto, function (gp) {
                        return gp.estado && gp.estado.codigo == "gp_clasificado" && gp.pesoReal > 0;
                    });
                }
                if (detalle.producto.criterioControl.codigo == "series") {
                    detalle.cantidadReferencial = 1;
                    if (vm.movimientoStockManual.depositoOrigen != null && vm.movimientoStockManual.transaccion != null) {
                        if (vm.movimientoStockManual.transaccion.accion.codigo == "egreso_almacen") {
                            getStockSerieEgreso(detalle);
                        } else {
                            var msg = $filter('translate')('INGRESO_SERIE_TRANSACCION_INVALIDA');
                            notify({ message: msg, classes: 'alert-warning', position: 'right' });
                            removeItemFromArray(detalle, vm.movimientoStockManual.movimientoManualDetalle);
                        }
                    } else {
                        detalle.producto.gruposProducto = null;
                        if (vm.movimientoStockManual.depositoOrigen == null) {
                            var msg = $filter('translate')('VERIFIQUE_ALMACEN');
                            notify({ message: msg, classes: 'alert-warning', position: 'right' });
                        }
                        if (vm.movimientoStockManual.transaccion == null) {
                            var msg = $filter('translate')('VERIFIQUE_TIPO_TRANSACCION');
                            notify({ message: msg, classes: 'alert-warning', position: 'right' });
                        }
                    }

                }
            }

            function submit() {
                vm.submited = true;
                if ($scope.MovimientoStockManualForm.$valid) {
                    if (vm.movimientoStockManual.movimientoManualDetalle.length >= 1) {
                        for (var i = 0; i < vm.movimientoStockManual.movimientoManualDetalle.length; i++) {
                            var elem = vm.movimientoStockManual.movimientoManualDetalle[i];
                            if (!elem.unidadMedidaReferencial.permiteDecimales) {

                                if ((elem.cantidadReferencial % 1) !== 0) {
                                    var msg = "Existen productos con unidad de medida que no permiten decimales";
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    return;
                                }
                            }
                        }

                        $rootScope.isProcessing = true;
                        vm.movimientoStockManual.movimientoManualDetalle = _.filter(vm.movimientoStockManual.movimientoManualDetalle, function (elem) {
                            return elem.producto;
                        });
                        // se quita el atributo grupo de los detalles cuyos productos son de tipo normal
                        vm.movimientoStockManual.movimientoManualDetalle = _.forEach(vm.movimientoStockManual.movimientoManualDetalle, function (elem) {
                            if (elem.producto.criterioControl.codigo !== 'series' && elem.producto.criterioControl.codigo !== 'lotes') {
                                delete elem.grupo;
                            }
                        });

                        var errorSerieRepetida = false;
                        for (var i = 0; i < vm.movimientoStockManual.movimientoManualDetalle.length; i++) {
                            var elem = vm.movimientoStockManual.movimientoManualDetalle[i];
                            var cantRegistros = 0;
                            if (elem.producto != null && elem.producto.criterioControl.codigo == 'series') {
                                _.forEach(vm.movimientoStockManual.movimientoManualDetalle, function (elem2) {
                                    if ((elem2.producto.criterioControl.codigo == 'series') && elem.producto.id == elem2.producto.id && elem.grupo.id == elem2.grupo.id) {
                                        cantRegistros++;
                                    }
                                });
                            }
                            if (cantRegistros > 1) {
                                var msg = $filter('translate')('NUMERO_GRUPO_REPETIDO') + " (Producto: " + elem.producto.nombre + ")";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                errorSerieRepetida = true;
                                break;
                            }
                        };
                        if (errorSerieRepetida) {
                            $rootScope.isProcessing = false;
                            return;
                        }

                        submitMovimientoStockManual().then(function (response) {
                            $state.go('app.movimientostockmanual.list');
                        }, function (error) {
                            $rootScope.isProcessing = false;
                            var msg = $filter('translate')('SAVE_ERROR_OP_MANUAL_STOCK');
                            if (error.data && error.data[0]) {
                                if (error.data[0].constraint === "id_timbrado_punto_emision, numero") {
                                    msg = $filter('translate')('NUMERO_TIMBRADO_REMISION');
                                } else {
                                    msg = error.data[0].message;
                                }
                            }
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        });
                    } else {
                        var msg = $filter('translate')('DETAILS_ERROR_OP_MANUAL_STOCK');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    }
                }
            }

            function submitMovimientoStockManual() {
                var trimmedModel = ModelTrimmer.trimDetails(vm.movimientoStockManual, ["movimientoManualDetalle"]);
                var trimmedDetalles = _.map(vm.movimientoStockManual.movimientoManualDetalle, function (detalle) {
                    return ModelTrimmer.trimDetails(detalle);
                });
                trimmedModel.movimientoManualDetalle = trimmedDetalles;
                var resource = MovimientoStockManualFactory.create(trimmedModel);
                if (!vm.movimientoStockManual.transaccion.generaRemision) {
                    return MovimientoStockManualFactory.save(resource);
                } else {
                    return MovimientoStockManualFactory.generaRemision(vm.movimientoStockManual);
                }
            }

            function depositoOrigenChanged() {
                vm.depositosDestino = _.map(vm.depositosSinFiltro, _.clone);
                _.remove(vm.depositosDestino, function (deposito) {
                    return deposito.id == vm.movimientoStockManual.depositoOrigen.id;
                });
                _.forEach(vm.movimientoStockManual.movimientoManualDetalle, function (elem) {
                    if (elem.producto != null && elem.producto.criterioControl.codigo == 'series') {
                        if (vm.movimientoStockManual.transaccion.accion.codigo == "egreso_almacen") {
                            getStockSerieEgreso(elem);
                        } else {
                            var msg = $filter('translate')('INGRESO_SERIE_TRANSACCION_INVALIDA');
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            removeItemFromArray(elem, vm.movimientoStockManual.movimientoManualDetalle);
                        }
                    }
                });
            }

            function depositoDestinoChanged() {
                vm.depositosOrigen = _.map(vm.depositos, _.clone);
                _.remove(vm.depositosOrigen, function (deposito) {
                    return deposito.id == vm.movimientoStockManual.depositoDestino.id;
                });

            }

            function transaccionSelected() {
                //verificacion de permisos
                //si el tipo de transaccion tiene el check de Usar en Operaciones Manuales de Stock y la accion es Ingreso a un almacen y tiene el permiso dejar avanzar, si no lanzar mensaje
                //si el tipo de transaccion tiene el check de Usar en Operaciones Manuales de Stock y la accion es Egreso a un almacen y tiene el permiso dejar avanzar, si no lanzar mensaje
                //si el tipo de transaccion tiene el check de Usar en Operaciones Manuales de Stock, la accion es Ingreso a un almacén, tiene una transacción de contrapartida seleccionada y tiene el permiso dejar avanzar, si no lanzar mensaje
                //si el tipo de transaccion tiene el check de Usar en Operaciones Manuales de Stock, la accion es Egreso a un almacén, tiene una transacción de contrapartida seleccionada y tiene el permiso dejar avanzar, si no lanzar mensaje
                if ((vm.movimientoStockManual.transaccion.usarEnOperacionesManuales && vm.movimientoStockManual.transaccion.accion.codigo == "ingreso_almacen" && vm.movimientoStockManual.transaccion.transaccionContrapartida == null && !AuthorizationService.hasPermission("generar_transaccionstockingreso")) ||
                    (vm.movimientoStockManual.transaccion.usarEnOperacionesManuales && vm.movimientoStockManual.transaccion.accion.codigo == "egreso_almacen" && vm.movimientoStockManual.transaccion.transaccionContrapartida == null && !AuthorizationService.hasPermission("generar_transaccionstockegreso")) ||
                    (vm.movimientoStockManual.transaccion.usarEnOperacionesManuales && vm.movimientoStockManual.transaccion.accion.codigo == "egreso_almacen" && vm.movimientoStockManual.transaccion.transaccionContrapartida != null && !vm.movimientoStockManual.transaccion.requiereConfirmacion && !AuthorizationService.hasPermission("generar_transaccionstockegresocontrapartida") && !AuthorizationService.hasPermission("generar_transaccionstockingresocontrapartida")) ||
                    (vm.movimientoStockManual.transaccion.usarEnOperacionesManuales && vm.movimientoStockManual.transaccion.accion.codigo == "egreso_almacen" && vm.movimientoStockManual.transaccion.transaccionContrapartida != null && vm.movimientoStockManual.transaccion.requiereConfirmacion && !AuthorizationService.hasPermission("generar_transaccionstockegresocontrapartida")) ||
                    (vm.movimientoStockManual.transaccion.usarEnOperacionesManuales && vm.movimientoStockManual.transaccion.accion.codigo == "egreso_almacen" && vm.movimientoStockManual.transaccion.transaccionContrapartida != null && !vm.movimientoStockManual.transaccion.requiereConfirmacion && !AuthorizationService.hasPermission("generar_transaccionstockegresocontrapartida")) ||
                    (vm.movimientoStockManual.transaccion.usarEnOperacionesManuales && vm.movimientoStockManual.transaccion.accion.codigo == "egreso_almacen" && vm.movimientoStockManual.transaccion.transaccionContrapartida != null && !vm.movimientoStockManual.transaccion.requiereConfirmacion && AuthorizationService.hasPermission("generar_transaccionstockegresocontrapartida") && !AuthorizationService.hasPermission("generar_transaccionstockingresocontrapartida"))) {
                    vm.movimientoStockManual.transaccion = null;
                    notify({ message: 'El usuario no cuenta con los permisos necesarios para realizar esta acción', classes: 'alert-danger', position: 'right' });
                }
                if (vm.movimientoStockManual.transaccion) {
                    vm.movimientoStockManual.depositoOrigen = undefined;
                    vm.movimientoStockManual.depositoDestino = undefined;
                    if (!vm.movimientoStockManual.transaccion.transaccionContrapartida) {
                        delete vm.movimientoStockManual.depositoDestino;
                    }
                    if (vm.movimientoStockManual.depositoOrigen != null) {
                        _.forEach(vm.movimientoStockManual.movimientoManualDetalle, function (elem) {
                            if (elem.producto != null && elem.producto.criterioControl.codigo == 'series') {
                                if (vm.movimientoStockManual.transaccion.accion.codigo == "egreso_almacen") {
                                    getStockSerieEgreso(elem);
                                } else {
                                    var msg = $filter('translate')('INGRESO_SERIE_TRANSACCION_INVALIDA');
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    removeItemFromArray(elem, vm.movimientoStockManual.movimientoManualDetalle);
                                }
                            }
                        });
                    }
                    console.log("vm.movimientoStockManual ", vm.movimientoStockManual.transaccion)
                    //verificar si tiene el check de solicitud y si es cliente
                    vm.habilitarCampos = false;
                    if (vm.movimientoStockManual.transaccion.obligatorioSolicitud && vm.movimientoStockManual.transaccion.tipoEntidadRelacionada.codigo == 'entidad_cliente') {
                        vm.habilitarCampos = true;
                    }
                    vm.habilitarConcepto = false;
                    if (vm.movimientoStockManual.transaccion.requiereConcepto) {
                        vm.habilitarConcepto = true;
                        var filtroConcepto = {};
                        filtroConcepto.search = filterFactory.and([{
                            path: 'activo',
                            like: 'true'
                        }]).value();
                        filtroConcepto.view = "TransaccionStockForm";
                        vm.conceptoMovimientos = ConceptoMovimientoFactory.all(filtroConcepto);
                    }

                    //si es para nota credito
                    if (vm.habilitarCampos && vm.habilitarConcepto) {
                        //filtrar almacenes por el check de almacenes solicitudes NC
                        var filterAlmacen = {
                            search: filterFactory.and([{
                                path: "sucursal.id",
                                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                            },
                            {
                                path: "activo",
                                equals: true

                            },
                            {
                                path: "clase.codigo",
                                equals: vm.movimientoStockManual.transaccion.clase.codigo

                            },
                            {
                                path: "asignarParaSolicitudesNotaCredito",
                                equals: true

                            }]).value()
                        };
                        filterAlmacen.view = "BaseList";
                        AlmacenFactory.all(filterAlmacen).$promise.then(function (response) {
                            vm.almacenNc = false;
                            if (response.length == 1) {
                                vm.depositos = response;
                                vm.depositosOrigen = _.map(vm.depositos, _.clone);
                                vm.movimientoStockManual.depositoOrigen = response[0];
                                vm.almacenNc = true;
                            } else {
                                vm.depositos = response;
                                vm.depositosOrigen = _.map(vm.depositos, _.clone);
                                vm.almacenNc = false;
                            }
                        });
                    } else {
                        var filterAlmacen = {
                            search: filterFactory.and([{
                                path: "sucursal.id",
                                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                            },
                            {
                                path: "activo",
                                equals: true

                            },
                            {
                                path: "clase.codigo",
                                equals: vm.movimientoStockManual.transaccion.clase.codigo

                            }]).value()
                        };
                        filterAlmacen.view = "BaseList";
                        AlmacenFactory.all(filterAlmacen).$promise.then(function (response) {
                            console.log("Acaaa1 ", response)
                            vm.almacenNc = false;
                            vm.depositos = response;
                            vm.depositosOrigen = _.map(vm.depositos, _.clone);
                        });

                        var filterAlmacenEstado = {
                            search: filterFactory.and([{
                                path: "activo",
                                equals: true
                            },
                            {
                                path: "clase.codigo",
                                equals: vm.movimientoStockManual.transaccion.clase.codigo

                            }]).value()
                        };
                        filterAlmacenEstado.view = "BaseList";
                        AlmacenFactory.all(filterAlmacenEstado).$promise.then(function (response) {
                            console.log("Acaaa ", response)
                            vm.almacenNc = false;
                            vm.depositosSinFiltro = response;
                            vm.depositosDestino = _.map(vm.depositosSinFiltro, _.clone);
                        });
                    }
                }
            }

            function cancel() {
                console.log("$state ", $state)
                $state.go('app.movimientostockmanual.list');
            }

            function validarNroFactura() {
                vm.movimientoStockManual = ComprobanteVentaFactory.validateNroFactura($scope.MovimientoStockManualForm, vm.movimientoStockManual, null, vm.formatoComprobante);

            }

            function timbradoSelect() {
                vm.movimientoStockManual.numero = undefined;
                if (vm.movimientoStockManual.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo != 'timbrado_manual') {
                    var numeroActual = fillStringWithLeftCharacters(vm.movimientoStockManual.timbradoPuntoEmision.numeroActual.toString(), 7, '0');;
                    var codigoSucursal = vm.movimientoStockManual.timbradoPuntoEmision.puntoEmisionVenta.puntoVenta.codigoSucursal;
                    var numeroPuntoEmision = vm.movimientoStockManual.timbradoPuntoEmision.puntoEmisionVenta.numero;

                    var staticFilter = {};
                    staticFilter.search = filterFactory.and([{
                        path: 'numero',
                        equals: codigoSucursal + "-" + numeroPuntoEmision + "-" + numeroActual
                    }]).value();
                    staticFilter.view = "BaseList";
                    vm.uiBlockuiConfig.bloquear = true;
                    RemisionFactory.all(staticFilter).$promise.then(function (response) {
                        vm.uiBlockuiConfig.bloquear = false;
                        if (response.length > 0) {
                            var staticFilter2 = {};
                            staticFilter2.search = filterFactory.and([
                                {
                                    path: 'timbradoPuntoEmision.id',
                                    equals: vm.movimientoStockManual.timbradoPuntoEmision.id
                                },
                                {
                                    path: 'numero',
                                    sortDesc: 'true'
                                }]).value();
                            staticFilter2.view = "BaseList";
                            vm.uiBlockuiConfig.bloquear = true;
                            RemisionFactory.all(staticFilter2).$promise.then(function (response) {
                                vm.uiBlockuiConfig.bloquear = false;
                                var numeroSiguiente;
                                for (var i = 0; i < response.length; i++) {
                                    if (response[i].numero != null) {
                                        numeroSiguiente = obtenerSiguienteCorrelativo(response[i].numero);
                                        break;
                                    }
                                }
                                vm.movimientoStockManual.numero = numeroSiguiente;
                            });
                        } else {
                            vm.movimientoStockManual.numero = codigoSucursal + "-" + numeroPuntoEmision + "-" + numeroActual;
                        }
                    });
                }
            }

            function searchTimbradoPuntoEmisionFilter(criteria) {
                return function (item) {
                    if (!criteria) {
                        return true;
                    }

                    return String(item.timbradoEmpresa.numeroTimbrado).toLowerCase().indexOf(criteria.toLowerCase()) > -1;

                };
            }

            function puntoEmisionSelected() {
                vm.movimientoStockManual.timbradoPuntoEmision = undefined;
                vm.movimientoStockManual.numero = undefined;
                var staticFilter2 = {};
                staticFilter2.search = filterFactory.and([{
                    path: 'tipoComprobante.codigo',
                    equals: 'remision'
                }, {
                    path: "timbradoEmpresa.fechaDesde",
                    equalOrBefore: moment(vm.movimientoStockManual.fecha).format("DD/MM/YYYY")
                }, {
                    path: "timbradoEmpresa.fechaHasta",
                    equalOrAfter: moment(vm.movimientoStockManual.fecha).format("DD/MM/YYYY"),
                }, {
                    path: "puntoEmisionVenta.id",
                    equals: vm.movimientoStockManual.puntoEmision.id
                }, {
                    path: "timbradoEmpresa.activo",
                    equals: true
                }
                    //, {
                    //   path: "timbradoEmpresa.claseTimbrado.codigo",
                    //   equals: "timbrado_manual"
                    // }
                ]).value();


                vm.timbrados = TimbradoEmpresaPuntoEmisionVentaFactory.all(staticFilter2, "ConsultaComprobanteVentaForm");
            }
            function fillStringWithLeftCharacters(str, desiredLength, character) {
                var currentLength = str.length;
                var diff = desiredLength - currentLength;
                var finalString = str;
                if (diff > 0) {
                    for (var i = 0; i < diff; i++) {
                        finalString = character + finalString;
                    }
                }
                return finalString;
            }

            function obtenerSiguienteCorrelativo(str) {
                // Dividir el string en partes usando el guion como separador
                var partes = str.split('-');

                // Obtener la última parte que contiene el número
                var numeroActual = parseInt(partes[2], 10);

                // Incrementar el número
                var siguienteNumero = numeroActual + 1;

                // Formatear el número para que tenga la misma longitud que la parte original
                var longitudOriginal = partes[2].length;
                var siguienteNumeroFormateado = fillStringWithLeftCharacters(siguienteNumero.toString(), longitudOriginal, '0');

                // Construir el nuevo string con el número correlativo siguiente
                var nuevoString = partes[0] + '-' + partes[1] + '-' + siguienteNumeroFormateado;

                return nuevoString;
            }
        }
    ]);

'use strict';

angular.module('qualita.stock')
  .controller('MovimientoStockManualIndexCtrl',
    ["$scope", "MovimientoStockManualFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "StockLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "RemisionFactory", "$q", "PeriodoAdministrativoFactory", "notify", function ($scope, MovimientoStockManualFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal, $window,
      $state, StockLangFactory, $filter, UtilFactory, CsvFactory, baseurl, RemisionFactory, $q, PeriodoAdministrativoFactory,
      notify) {

      var emptyDataRender = function (data) {
        if (data == undefined)
          return "";
        else
          return data;
      };

      var staticFilter = undefined;
      staticFilter = {};
      staticFilter.search = filterFactory
        .and([
          {
            path: "id",
            sortDesc: "true"
          },
          {
            path: "id",
            isNotNull: "true"
          }
        ])
        .value();

      StockLangFactory.getTranslations().then(function (translations) {
        var defaultColumnOrder = ['numero', 'transaccion.descripcion', 'fecha', 'estado.descripcion', 'depositoOrigen.descripcion',
          'depositoDestino.descripcion'];
        $scope.options = {
          'resource': 'movimientostockmanual',
          'view': 'MovimientoStockManualList',
          'title': 'Movimiento de Stock Manual',
          'staticFilter': staticFilter,
          'factory': MovimientoStockManualFactory,
          'defaultColumnOrder': defaultColumnOrder,
          'columns': [
            { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false },
            { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-center' },
            { 'data': 'transaccion.descripcion', 'title': $filter('translate')('TRANSACCION') },
            { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
            { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/estados_movimiento_stock_manual' },
            { 'data': 'depositoOrigen.descripcion', 'title': $filter('translate')('ALMACEN_ORIGEN'), 'render': emptyDataRender },
            { 'data': 'depositoDestino.descripcion', 'title': $filter('translate')('ALMACEN_DESTINO'), 'render': emptyDataRender }
          ],
          'hasOptions': true,
          'hideEditMenu': true,
          'hideRemoveMenu': true,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "desc",
          'extraMenuOptions':
            [
              {
                'title': 'Reporte',
                'icon': 'glyphicon glyphicon-file',
                'showCondition': function () { return true; },
                'action': function () {
                  var filters = createFilters($scope.options.getFilters());
                  ReportTicketFactory.ticket('listadoMovimientoStockManual', filters, $scope.options.tableAjaxParams,
                    $scope.options.currentColumnOrder).then(function (genTicket) {
                      $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                      $window.open($scope.pdfDownloadURL, '_blank');
                    });
                }
              },
              {
                'title': 'CSV',
                'icon': 'glyphicon glyphicon-export',
                'showCondition': function () { return true; },
                'action': function () {
                  var filters = createFilters($scope.options.getFilters());
                  CsvFactory.csv("movimientostockmanual", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                    .then(function (response) {
                      console.log("response de csv: ", response);
                      var fileName = response.data.fileName;
                      $window.open(baseurl.getPublicBaseUrl() + fileName);
                    });
                }
              }
            ],
          'extraRowOptions': [
            {
              templateToRender: "<button title='Imprimir' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='movimiento(<%=dataId%>)' ng-class='{ hidden : canMovimiento(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
              functionName: "movimiento",
              conditionName: "canMovimiento",
              customAttribute: ['estado', 'id'],
              conditionDef: function (mov) {
                return false;
              },
              functionDef: function (mov) {
                console.log("mov ", mov)
                var filtersArr = [];
                filtersArr.push('idtransaccionmanual=' + mov);
                $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('transaccionmanual', 'pdf', filtersArr);
                $window.open($scope.pdfDownloadURL, '_blank');
                $state.go($state.current, {}, { reload: true });
              }
            },
            {
              templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' ng-show='canEditar(<%=dataCustom%>)'> <span class='glyphicon glyphicon-pencil'></span> </button>",
              customAttribute: 'estado',
              conditionName: "canEditar",
              conditionDef: function (estado) {
                return (AuthorizationService.hasPermission('update_movimientostockmanual') && estado.codigo == "estado_movimiento_stock_manual_ingresado");
              },
              functionName: "editar",
              functionDef: function (itemId) {
                $state.go("app.movimientostockmanual.edit", { id: itemId });
              }
            },
            {
              templateToRender: "<button title='Confirmar' class='btn btn-primary btn-dt' style='margin-right: 5px;' ng-click='confirmar(<%=dataId%>)' ng-show='canConfirmar(<%=dataCustom%>)'> <span class='glyphicon glyphicon-ok'></span> </button>",
              customAttribute: 'estado',
              conditionName: "canConfirmar",
              conditionDef: function (estado) {
                return (estado.codigo == "estado_movimiento_stock_manual_ingresado");
              },
              functionName: "confirmar",
              functionDef: function (itemId) {
                $scope.selectedItemId = itemId;
                $scope.tituloModal = 'Confirmación de Movimiento de Stock Manual';
                $scope.mensajeModal = 'Esta operación realizara un Movimiento de Stock y cambiará el estado del movimiento a confirmado. ¿Desea continuar?';
                $scope.modalInstanceConfirmar = $modal.open({
                  templateUrl: 'views/directives/confirmation-modal.html',
                  scope: $scope
                });

                $scope.cancel = function () {
                  $scope.modalInstanceConfirmar.dismiss('cancel');
                };

                $scope.ok = function (itemId) {
                  if (AuthorizationService.hasPermission("confirmar_movimientostockmanual") === true) {
                    MovimientoStockManualFactory.confirmar(itemId).then(function () {
                      $scope.modalInstanceConfirmar.close(itemId);
                      $state.go($state.current, {}, { reload: true });
                    }, function (error) {
                      console.log("error al confirmar ");
                      console.log(error);
                    });
                  } else {
                    notify({ message: 'El usuario no cuenta con los permisos necesarios para realizar esta acción', classes: 'alert-danger', position: 'right' });
                  }
                };
              }
            },
            {
              templateToRender: "<button title='Reversar' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='reversar(<%=dataId%>)' ng-show='canReversar(<%=dataCustom%>)'> <span class='glyphicon glyphicon-remove'></span> </button>",
              customAttribute: ['estado'],
              conditionName: "canReversar",
              conditionDef: function (item) {
                var estado = item[0];
                return (estado.codigo == "estado_movimiento_stock_manual_confirmado");
              },
              functionName: "reversar",
              functionDef: function (itemId) {
                if (AuthorizationService.hasPermission("anular_movimientostockmanual") === true) {
                  $scope.selectedItemId = itemId;
                  $scope.tituloModal = 'Confirmación de Reversión de Movimiento Stock Manual';
                  $scope.mensajeModal = 'Esta operación realizara un Movimiento de Stock y cambiará el estado del movimiento a reversado. ¿Desea continuar?';
                  $scope.modalInstanceReversar = $modal.open({
                    templateUrl: 'views/directives/confirmation-modal.html',
                    scope: $scope
                  });

                  $scope.cancel = function () {
                    $scope.modalInstanceReversar.dismiss('cancel');
                  };

                  $scope.ok = function (itemId) {
                    MovimientoStockManualFactory.reversar(itemId).then(function () {
                      $scope.modalInstanceReversar.close(itemId);
                      $state.go($state.current, {}, { reload: true });
                    }, function (error) {
                      var mensaje = "<span>";
                      _.forEach(error.data, function (err) {
                        mensaje = mensaje + err.message + "<br/>";
                      });
                      mensaje = mensaje + "</span>";
                      notify({
                        messageTemplate: mensaje,
                        classes: "alert-danger",
                        position: "right",
                        templateUrl: ""
                      });
                      console.log("error al reversar ");
                      console.log(error);
                    });
                  };
                } else {
                  notify({ message: 'El usuario no cuenta con los permisos necesarios para realizar esta acción', classes: 'alert-danger', position: 'right' });
                }
              }
            }]
        };
      });

      var createFilters = function (filters) {
        var filtersArr = [];
        var filtersArr2 = [];
        _.each(filters, function (search, data) {
          search = search.split("~");
          if (search.length > 1) {
            var isDate = search[0].split("/");

            if (isDate.length > 1) {
              filtersArr.push({ path: data, equalOrAfter: search[0] })
              filtersArr.push({ path: data, equalOrBefore: search[1] })

              filtersArr2.push({ path: data, equalOrAfter: search[0] })
              filtersArr2.push({ path: data, equalOrBefore: search[1] })
            } else {
              filtersArr.push({ path: data, greaterOrEqual: search[0] })
              filtersArr.push({ path: data, lessOrEqual: search[1] })

              filtersArr2.push({ path: data, greaterOrEqual: search[0] })
              filtersArr2.push({ path: data, lessOrEqual: search[1] })
            }
          } else {
            filtersArr.push({ path: data, like: search[0] })
            filtersArr2.push({ path: data, like: search[0] })
          }
        });

        var filters = filterFactory.or([
          filterFactory.and(filtersArr).value(),
          filterFactory.and(filtersArr2).value()
        ]).value();

        return filters;
      }
    }]);

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.importacion', ['ngResource', 'ui.tree', 'ngFileUpload'])
  .config(
  ['$stateProvider',
    function($stateProvider) {
      $stateProvider
        .state('app.gruposcompra', {
          url: '/gruposcompra',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Grupos de Compra'
          }
        })
        .state('app.gruposcompra.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'GruposCompraImpIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.gruposcompra',
            label: 'Grupos de Compra',
            description: 'Listado de Grupos de Compra',
            skip: true
          }
        })
        .state('app.gruposcompra.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/gruposcompra/form.html',
          controller: 'GruposCompraImpFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.gruposcompra',
            label: 'Nuevo Grupo de Compra',
            description: 'Creación de un nuevo Grupo de Compra'
          },
          resolve: {
            grupoCompraPrepService: function() {return;}
          }
        })
        .state('app.gruposcompra.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/gruposcompra/form.html',
          controller: 'GruposCompraImpFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.gruposcompra',
            label: 'Editar Grupo de Compra',
            description: 'Edición del grupo de compra #'
          },
          resolve: {
            grupoCompraPrepService: grupoCompraImportacionPrepService
          }
        })
        .state('app.gruposcompra.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/gruposcompra/form.html',
          controller: 'GruposCompraImpFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.gruposcompra',
            label: 'Ver Grupo de Compra',
            description: 'Ver grupo de compra #'
          },
          resolve: {
            grupoCompraPrepService: grupoCompraImportacionPrepService
          }
        })
        .state('app.tipoimpuestoaduanas', {
          url: '/tipoimpuestoaduanas',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Impuestos de Aduana'
          }
        })
        .state('app.tipoimpuestoaduanas.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'TipoImpuestoAduanaIndexCtrl',
          ncyBreadcrumb: {
            parent: 'app.tipoimpuestoaduanas',
            label: 'Impuestos de Aduana',
            description: 'Listado de Impuestos de Aduanas',
            skip: true
          }
        })
        .state('app.tipoimpuestoaduanas.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/tipoimpuestoaduana/form.html',
          controller: 'TipoImpuestoAduanaFormController',
          ncyBreadcrumb: {
            parent: 'app.tipoimpuestoaduanas',
            label: 'Nuevo Impuesto de Aduana',
            description: 'Creación de un nuevo impuesto de aduana'
          },
            resolve: {
              tipoImpuestoAduanaPrepService: function() { return; }
            }
        })
        .state('app.tipoimpuestoaduanas.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/tipoimpuestoaduana/form.html',
          controller: 'TipoImpuestoAduanaFormController',
          ncyBreadcrumb: {
            parent: 'app.tipoimpuestoaduanas',
            label: 'Editar Impuesto de Aduana',
            description: 'Edición de impuesto de aduana #'
          },
          resolve: {
            tipoImpuestoAduanaPrepService: tipoImpuestoAduanaImportacionPrepService
          }
        })
        .state('app.tipoimpuestoaduanas.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/tipoimpuestoaduana/form.html',
          controller: 'TipoImpuestoAduanaFormController',
          ncyBreadcrumb: {
            parent: 'app.tipoimpuestoaduanas',
            label: 'Ver Impuesto de Aduana',
            description: 'Ver impuesto de aduana #'
          },
          resolve: {
            tipoImpuestoAduanaPrepService: tipoImpuestoAduanaImportacionPrepService
          }
        })
        .state('app.condicionimportaciones', {
          url: '/condicionimportaciones',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Condiciones de Importación'
          }
        })
        .state('app.condicionimportaciones.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'CondicionImportacionIndexCtrl',
          ncyBreadcrumb: {
            parent: 'app.condicionimportaciones',
            label: 'Condiciones de Importación',
            description: 'Listado de Condiciones de Importación',
            skip: true
          }
        })
        .state('app.condicionimportaciones.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/condicionimportacion/form.html',
          controller: 'CondicionImportacionFormController',
          ncyBreadcrumb: {
            parent: 'app.condicionimportaciones',
            label: 'Nueva Condición de Importación',
            description: 'Creación de una nueva condición de importación'
          },
            resolve: {
              condicionImportacionPrepService: function() { return; }
            }
        })
        .state('app.condicionimportaciones.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/condicionimportacion/form.html',
          controller: 'CondicionImportacionFormController',
          ncyBreadcrumb: {
            parent: 'app.condicionimportaciones',
            label: 'Editar Condición de Importación',
            description: 'Edición de condición de importación #'
          },
          resolve: {
            condicionImportacionPrepService: condicionImportacionPrepService
          }
        })
        .state('app.condicionimportaciones.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/condicionimportacion/form.html',
          controller: 'CondicionImportacionFormController',
          ncyBreadcrumb: {
            parent: 'app.condicionimportaciones',
            label: 'Ver Condición de Importación',
            description: 'Ver condición de importación #'
          },
          resolve: {
            condicionImportacionPrepService: condicionImportacionPrepService
          }
        })
        .state('app.regimenaduanas', {
          url: '/regimenaduanas',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Regímenes de Aduana'
          }
        })
        .state('app.regimenaduanas.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'RegimenAduanasIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.regimenaduanas',
            label: 'Regímenes de Aduanas',
            description: 'Listado de Regímenes de Aduanas',
            skip: true
          }
        })
        .state('app.regimenaduanas.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/regimenaduanas/form.html',
          controller: 'RegimenAduanasFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.regimenaduanas',
            label: 'Nuevo Régimen de Aduana',
            description: 'Creación de un nuevo régimen de aduana'
          },
          resolve: {
            regimenAduanaPrepService: function() { return; }
          }
        })
        .state('app.regimenaduanas.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/regimenaduanas/form.html',
          controller: 'RegimenAduanasFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.regimenaduanas',
            label: 'Editar Régimen de Aduana',
            description: 'Edición del régimen de aduana #'
          },
          resolve: {
            regimenAduanaPrepService: regimenAduanaImportacionPrepService
          }
        })
        .state('app.regimenaduanas.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/regimenaduanas/form.html',
          controller: 'RegimenAduanasFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.regimenaduanas',
            label: 'Editar Régimen de Aduana',
            description: 'Edición del régimen de aduana #'
          },
          resolve: {
            regimenAduanaPrepService: regimenAduanaImportacionPrepService
          }
        })
        .state('app.aduanas', {
          url: '/aduanas',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Aduanas'
          }
        })
        .state('app.aduanas.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'AduanasIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.aduanas',
            label: 'Aduanas',
            description: 'Listado de Aduanas',
            skip: true
          }
        })
        .state('app.aduanas.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/aduanas/form.html',
          controller: 'AduanasFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.aduanas',
            label: 'Nueva Aduana',
            description: 'Creación de una nueva aduana'
          }, resolve: {
            aduanaPrepService: function() { return; }
          }
        })
        .state('app.aduanas.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/aduanas/form.html',
          controller: 'AduanasFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.aduanas',
            label: 'Editar Aduana',
            description: 'Edición de aduana #'
          }, resolve: {
            aduanaPrepService: aduanaImportacionPrepService
          }
        })
        .state('app.aduanas.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/aduanas/form.html',
          controller: 'AduanasFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.aduanas',
            label: 'Ver Aduana',
            description: 'Ver aduana #'
          }, resolve: {
            aduanaPrepService: aduanaImportacionPrepService
          }
        })
        .state('app.tipocomprobantes', {
          url: '/tipocomprobantes',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Tipos de Comprobantes'
          }
        })
        .state('app.tipocomprobantes.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'TipoComprobantesIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.tipocomprobantes',
            label: 'Tipos de Comprobantes',
            description: 'Listado de Tipos de Comprobantes',
            skip: true
          }
        })
        .state('app.tipocomprobantes.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/tipocomprobante/form.html',
          controller: 'TipoComprobantesFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.tipocomprobantes',
            label: 'Nuevo Tipo de Comprobante',
            description: 'Creación de un nuevo tipo de comprobante'
          },
          resolve: {
            tipoComprobantePrepService: function() { return; }
          }
        })
        .state('app.tipocomprobantes.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/tipocomprobante/form.html',
          controller: 'TipoComprobantesFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.tipocomprobantes',
            label: 'Editar Tipo de Comprobante',
            description: 'Edición de tipo de comprobante #'
          },
          resolve: {
            tipoComprobantePrepService: tipoComprobanteImportacionPrepService
          }
        })
        .state('app.tipocomprobantes.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/tipocomprobante/form.html',
          controller: 'TipoComprobantesFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.tipocomprobantes',
            label: 'Ver Tipo de Comprobante',
            description: 'Ver tipo de comprobante #'
          },
          resolve: {
            tipoComprobantePrepService: tipoComprobanteImportacionPrepService
          }
        })
        .state('app.cotizacionmonedas', {
          url: '/cotizacionmonedas',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Cotización de Monedas'
          }
        })
        .state('app.cotizacionmonedas.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'CotizacionMonedaIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.cotizacionmonedas',
            label: 'Cotización de Monedas',
            description: 'Listado de Cotización de Monedas',
            skip: true
          }
        })
        .state('app.cotizacionmonedas.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/cotizacionmoneda/form.html',
          controller: 'CotizacionMonedaFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.cotizacionmonedas',
            label: 'Nueva Cotización de Moneda',
            description: 'Creación de una nueva cotización de moneda'
          },
          resolve: {
            cotizacionMonedaPrepService: function() { return; }
          }
        })
        .state('app.cotizacionmonedas.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/cotizacionmoneda/form.html',
          controller: 'CotizacionMonedaFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.cotizacionmonedas',
            label: 'Editar Cotización de Moneda',
            description: 'Edición de cotización de moneda #'
          },
          resolve: {
            cotizacionMonedaPrepService: cotizacionMonedaImportacionPrepService
          }
        })
        .state('app.cotizacionmonedas.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/cotizacionmoneda/form.html',
          controller: 'CotizacionMonedaFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.cotizacionmonedas',
            label: 'Ver Cotización de Moneda',
            description: 'Ver cotización de moneda #'
          },
          resolve: {
            cotizacionMonedaPrepService: cotizacionMonedaImportacionPrepService
          }
        })
        .state('app.servicios', {
          url: '/servicios',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Servicios'
          }
        })
        .state('app.servicios.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'ServicioIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.servicios',
            label: 'Servicios',
            description: 'Listado de Servicios',
            skip: true
          }
        })
        .state('app.servicios.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/servicio/form.html',
          controller: 'ServicioFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.servicios',
            label: 'Nuevo Servicio',
            description: 'Creación de un nuevo servicio'
          },
          resolve: {
            servicioPrepService: function() { return; }
          }
        })
        .state('app.servicios.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/servicio/form.html',
          controller: 'ServicioFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.servicios',
            label: 'Editar Servicio',
            description: 'Edición de servicio #'
          },
          resolve: {
            servicioPrepService: servicioImportacionPrepService
          }
        })
        .state('app.servicios.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/servicio/form.html',
          controller: 'ServicioFormController',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.servicios',
            label: 'Ver Servicio',
            description: 'Ver servicio #'
          },
          resolve: {
            servicioPrepService: servicioImportacionPrepService
          }
        })
        .state('app.importaciones', {
          url: '/importaciones',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Importaciones'
          }
        })
        .state('app.importaciones.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'ImportacionIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones',
            label: 'Importaciones',
            description: 'Listado de Importaciones',
            skip: true
          }
        })
        .state('app.importaciones.proceso', {
          url : '/proceso/:codigoImportacion',
          templateUrl: 'qualita-components/importacion/views/importacion/wizard.html',
          controller: 'ImportacionProcesoCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones',
            label: 'Proceso'
          },
          resolve: {
            importacionPrepService: importacionPrepService
          }
        })
        .state('app.importaciones.proceso.ordenescompra', {
          url: '/ordenescompra',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso',
            label: 'Ordenes de Compra'
          }
        })
        .state('app.importaciones.proceso.ordenescompra.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'OrdenesCompraListCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.ordenescompra',
            label: 'Ordenes de Compra',
            skip: true
          }
        })
        .state('app.importaciones.proceso.ordenescompra.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/ordenescompra/form.html',
          controller: 'OrdenesCompraFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.ordenescompra',
            label: 'Nueva Orden de Compra'
          },
          resolve: {
            ordenCompraPrepService: function() {return;}
          }
        })
        .state('app.importaciones.proceso.ordenescompra.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/ordenescompra/form.html',
          controller: 'OrdenesCompraFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.ordenescompra',
            label: 'Editar Orden de Compra'
          },
          resolve: {
            ordenCompraPrepService: ordenCompraImportacionPrepService
          }
        })
        .state('app.importaciones.proceso.ordenescompra.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/ordenescompra/form.html',
          controller: 'OrdenesCompraFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.ordenescompra',
            label: 'Ver Orden de Compra'
          },
          resolve: {
            ordenCompraPrepService: ordenCompraImportacionPrepService
          }
        })
        .state('app.importaciones.proceso.proformas', {
          url: '/proformas',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso',
            label: 'Proformas'
          }
        })
        .state('app.importaciones.proceso.proformas.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'ProformaListCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.proformas',
            label: 'Proformas',
            skip: true
          }
        })
        .state('app.importaciones.proceso.proformas.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/proforma/form.html',
          controller: 'ProformaFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.proformas',
            label: 'Nueva Proforma'
          },
          resolve: {
            proformaPrepService: function() {return;}
          }
        })
        .state('app.importaciones.proceso.proformas.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/proforma/form.html',
          controller: 'ProformaFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.proformas',
            label: 'Editar Proforma'
          },
          resolve: {
            proformaPrepService: proformaImportacionPrepService
            }
        })
        .state('app.importaciones.proceso.proformas.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/proforma/form.html',
          controller: 'ProformaFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.proformas',
            label: 'Ver Proforma'
          },
          resolve: {
            proformaPrepService: proformaImportacionPrepService
          }
        })
        .state('app.importaciones.proceso.embarques', {
          url: '/embarques',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso',
            label: 'Embarques'
          }
        })
        .state('app.importaciones.proceso.embarques.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'EmbarqueListCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.embarques',
            label: 'Embarques',
            skip: true
          }
        })
        .state('app.importaciones.proceso.embarques.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/embarque/form.html',
          controller: 'EmbarqueFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.embarques',
            label: 'Nuevo Embarque'
          },
          resolve: {
            embarquePrepService: function() {return;}
          }
        })
        .state('app.importaciones.proceso.embarques.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/embarque/form.html',
          controller: 'EmbarqueFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.embarques',
            label: 'Editar Embarque'
          },
          resolve: {
            embarquePrepService: embarqueImportacionPrepService
            }
        })
        .state('app.importaciones.proceso.embarques.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/embarque/form.html',
          controller: 'EmbarqueFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.embarques',
            label: 'Ver Embarque'
          },
          resolve: {
            embarquePrepService: embarqueImportacionPrepService
          }
        })
        .state('app.importaciones.proceso.solicitudes', {
          url: '/solicitudes',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso',
            label: 'Solicitudes de Pago'
          }
        })
        .state('app.importaciones.proceso.solicitudes.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'SolicitudPagoListCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.solicitudes',
            label: 'Solicitudes de Pago',
            skip: true
          }
        })
        .state('app.importaciones.proceso.solicitudes.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/solicitudpago/form.html',
          controller: 'SolicitudPagoFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.solicitudes',
            label: 'Nueva Solicitud de Pago'
          },
          resolve: {
            solicitudPrepService: function() {return;}
          }
        })
        .state('app.importaciones.proceso.solicitudes.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/solicitudpago/form.html',
          controller: 'SolicitudPagoFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.solicitudes',
            label: 'Editar Solicitud de Pago'
          },
          resolve: {
            solicitudPrepService: solicitudImportacionPrepService
          }
        })
        .state('app.importaciones.proceso.solicitudes.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/solicitudpago/form.html',
          controller: 'SolicitudPagoFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.solicitudes',
            label: 'Ver Solicitud de Pago'
          },
          resolve: {
            solicitudPrepService: solicitudImportacionPrepService
          }
        })
        .state('app.importaciones.proceso.recepcionimportacion', {
          url: '/recepcionesimportacion',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso',
            label: 'Recepciones de Importación'
          }
        })
        .state('app.importaciones.proceso.recepcionimportacion.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'RecepcionImportacionListCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.recepcionimportacion',
            label: 'Recepciones de Importación',
            skip: true
          }
        })
        .state('app.importaciones.proceso.recepcionimportacion.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/recepcionimportacion/form.html',
          controller: 'RecepcionImportacionFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.recepcionimportacion',
            label: 'Nueva Recepción de Importación'
          },
          resolve: {
            recepcionPrepService: function() { return; }
          }
        })
        .state('app.importaciones.proceso.recepcionimportacion.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/recepcionimportacion/form.html',
          controller: 'RecepcionImportacionFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.recepcionimportacion',
            label: 'Editar Recepción de Importación'
          },
          resolve: {
            recepcionPrepService: recepcionImportacionPrepService
            }
        })
        .state('app.importaciones.proceso.recepcionimportacion.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/recepcionimportacion/form.html',
          controller: 'RecepcionImportacionFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.recepcionimportacion',
            label: 'Ver Recepción de Importación'
          },
          resolve: {
            recepcionPrepService: recepcionImportacionPrepService
          }
        })
        .state('app.importaciones.proceso.comprobante', {
          url: '/comprobantes',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso',
            label: 'Comprobantes'
          }
        })
        .state('app.importaciones.proceso.comprobante.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'ComprobanteListCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.comprobante',
            label: 'Comprobantes',
            skip: true
          }
        })
        .state('app.importaciones.proceso.comprobante.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/comprobante/form.html',
          controller: 'ComprobanteFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.comprobante',
            label: 'Nuevo Comprobante'
          },
          resolve: {
            comprobantePrepService: function() {return;}
          }
        })
        .state('app.importaciones.proceso.comprobante.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/comprobante/form.html',
          controller: 'ComprobanteFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.comprobante',
            label: 'Editar Comprobante'
          },
          resolve: {
            comprobantePrepService: comprobanteImportacionPrepService
            }
        })
        .state('app.importaciones.proceso.comprobante.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/comprobante/form.html',
          controller: 'ComprobanteFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.comprobante',
            label: 'Ver Comprobante'
          },
          resolve: {
            comprobantePrepService: comprobanteImportacionPrepService
          }
        })
        .state('app.importaciones.proceso.comprobante.apply', {
          url: '/:id/apply?mode',
          templateUrl: 'qualita-components/importacion/views/comprobante/aplicacion.html',
          controller: 'AplicacionComprobanteFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.comprobante',
            label: 'Aplicar Comprobante'
          },
          resolve: {
            comprobantePrepService: comprobanteImportacionAplicarPrepService
          }
        })
		    .state('app.importaciones.proceso.despacho', {
          url: '/despacho',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso',
            label: 'Despacho'
          }
        })
        .state('app.importaciones.proceso.despacho.list', {
          url: '',
          templateUrl: 'qualita-components/importacion/views/despacho/form.html',
          controller: 'DespachoIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.despacho',
            label: 'Despacho',
            skip: true
          }
        })
        .state('app.importaciones.proceso.cierre', {
          url: '/cierre',
          templateUrl: 'qualita-components/importacion/views/importacion/cierre.html',
          controller: 'CierreImportacionCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso',
            label: 'Cierre de la Importación',
          }
        })
        .state('app.importaciones.proceso.comprobante.planpagos', {
          url: '/:idComprobante/planpagos/:idPlanPago/cuotas',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.comprobante',
            label: 'Plan de Pagos'
          }
        })
        .state('app.importaciones.proceso.comprobante.planpagos.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'PlanPagosDetalleIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.comprobante.planpagos',
            label: 'Plan de Pagos',
            skip: true
          }
        })
        .state('app.importaciones.proceso.comprobante.planpagos.new', {
          url: '/new',
          templateUrl: 'qualita-components/cuentasapagar/views/planpagosdetalle/form.html',
          controller: 'PlanPagosDetalleFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.comprobante.planpagos',
            label: 'Plan de Pagos'
          },
          resolve: {
            planPagosDetallePrepService: function() {return;}
          }
        })
        .state('app.importaciones.proceso.comprobante.planpagos.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/cuentasapagar/views/planpagosdetalle/form.html',
          controller: 'PlanPagosDetalleFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.comprobante.planpagos',
            label: 'Editar Plan de Pago'
          },
          resolve: {
            planPagosDetallePrepService: planPagosImportacionPrepService
          }
        })
        .state('app.importaciones.proceso.comprobante.planpagos.view', {
          url: '/:id',
          templateUrl: 'qualita-components/cuentasapagar/views/planpagosdetalle/form.html',
          controller: 'PlanPagosDetalleFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.importaciones.proceso.comprobante.planpagos',
            label: 'Ver Plan de Pago'
          },
          resolve: {
            planPagosDetallePrepService: planPagosImportacionPrepService
          }
        })
        .state('app.orden_compra_importacion', {
          url: '/ordenescompraimportacion',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Órdenes de Compra'
          }
        })
        .state('app.orden_compra_importacion.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'OrdenesCompraListCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.orden_compra_importacion',
            label: 'Órdenes de Compra',
            skip: true
          }
        })
        .state('app.orden_compra_importacion.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/ordenescompra/form.html',
          controller: 'OrdenesCompraFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.orden_compra_importacion',
            label: 'Nueva Orden de Compra'
          },
          resolve: {
            ordenCompraPrepService: function() {return;}
          }
        })
        .state('app.orden_compra_importacion.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/ordenescompra/form.html',
          controller: 'OrdenesCompraFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.orden_compra_importacion',
            label: 'Editar Orden de Compra'
          },
          resolve: {
            ordenCompraPrepService: ordenCompraImportacionPrepService
          }
        })
        .state('app.orden_compra_importacion.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/ordenescompra/form.html',
          controller: 'OrdenesCompraFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.orden_compra_importacion',
            label: 'Ver Orden de Compra'
          },
          resolve: {
            ordenCompraPrepService: ordenCompraImportacionPrepService
          }
        })
        .state('app.solicitud_compra_importacion', {
          url: '/solicitudescompra',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Solicitudes de Compra'
          }
        })
        .state('app.solicitud_compra_importacion.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'SolicitudesCompraListCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.solicitud_compra_importacion',
            label: 'Solicitudes de Compra',
            skip: true
          }
        })
        .state('app.solicitud_compra_importacion.new', {
          url: '/new',
          templateUrl: 'qualita-components/importacion/views/solicitudcompra/form.html',
          controller: 'SolicitudesCompraFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.solicitud_compra_importacion',
            label: 'Solicitudes de Compra'
          },
          resolve: {
            solicitudCompraPrepService: function() {return;}
          }
        })
        .state('app.solicitud_compra_importacion.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/importacion/views/solicitudcompra/form.html',
          controller: 'SolicitudesCompraFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.solicitud_compra_importacion',
            label: 'Editar Solicitud de Compra'
          },
          resolve: {
            solicitudCompraPrepService: solicitudCompraImportacionPrepService
          }
        })
        .state('app.solicitud_compra_importacion.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/solicitudcompra/form.html',
          controller: 'SolicitudesCompraFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.solicitud_compra_importacion',
            label: 'Ver Solicitud de Compra'
          },
          resolve: {
            solicitudCompraPrepService: solicitudCompraImportacionPrepService
          }
        })
        .state('app.consultadespacho', {
          url: '/consultasdespacho',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            //parent: 'app.consultadespacho',
            label: 'Consulta de Despachos'
          }
        })
        .state('app.consultadespacho.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'ConsultaDespachoIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.consultadespacho',
            label: 'Consulta de despacho',
            skip: true
          }
        })
        .state('app.consultadespacho.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/consultadespacho/form.html',
          controller: 'ConsultaDespachoFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.consultadespacho',
            label: 'Ver Consulta de despacho',
            description: 'Ver Consulta de despacho #'
          },
          resolve: {
            consultaDespachoPrepService: consultaDespachoPrepService
          }
        })
        .state('app.consultaproforma', {
          url: '/consultaproformas',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Consulta de Proformas'
          }
        })
        .state('app.consultaproforma.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'ConsultaProformaIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.consultaproforma',
            label: 'Consulta de Proformas',
            skip: true
          }
        })
        .state('app.consultaproforma.view', {
          url: '/:id',
          templateUrl: 'qualita-components/importacion/views/consultaproforma/form.html',
          controller: 'ConsultaProformaFormCtrl',
          //controller: 'ProformaFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.consultaproforma',
            label: 'Ver Proforma'
          },
          resolve: {
            proformaPrepService: consultaProformaPrepService
          }
        });
    }]);


function grupoCompraImportacionPrepService(GruposCompraFactory, $stateParams) {
  return GruposCompraFactory.get($stateParams.id, 'GrupoCompraForm').$promise;
}
grupoCompraImportacionPrepService.$inject = ["GruposCompraFactory", "$stateParams"];

function importacionPrepService(ImportacionFactory, $stateParams) {
  var promise = ImportacionFactory.get($stateParams.codigoImportacion, "ImportacionList").$promise;
  promise.then(function(importacion) {
    ImportacionFactory.setIdImportacion(importacion.id);
    ImportacionFactory.setCodigoImportacion(importacion.codigo);
    ImportacionFactory.setImportacion(importacion);
  });
  return promise;
}
importacionPrepService.$inject = ["ImportacionFactory", "$stateParams"];

function ordenCompraImportacionPrepService(OrdenesCompraFactory, $stateParams) {
  return OrdenesCompraFactory.get($stateParams.id, "OrdenCompraForm").$promise;
}
ordenCompraImportacionPrepService.$inject = ["OrdenesCompraFactory", "$stateParams"];

function comprobanteImportacionPrepService(ComprobanteFactory, $stateParams) {
  return ComprobanteFactory.get($stateParams.id, "ComprobanteForm").$promise;
}
comprobanteImportacionPrepService.$inject = ["ComprobanteFactory", "$stateParams"];

function proformaImportacionPrepService(ProformaFactory, $stateParams) {
  return ProformaFactory.get($stateParams.id, "ProformaForm").$promise;
}
proformaImportacionPrepService.$inject = ["ProformaFactory", "$stateParams"];

function consultaProformaPrepService(ProformaFactory, $stateParams) {
  return ProformaFactory.get($stateParams.id, "ConsultaProformaForm").$promise;
}
consultaProformaPrepService.$inject = ["ProformaFactory", "$stateParams"];

function solicitudImportacionPrepService(SolicitudFactory, $stateParams) {
  return SolicitudFactory.get($stateParams.id, "SolicitudPagoForm").$promise;
}
solicitudImportacionPrepService.$inject = ["SolicitudFactory", "$stateParams"];

function embarqueImportacionPrepService(EmbarqueFactory, $stateParams) {
  return EmbarqueFactory.get($stateParams.id, "EmbarqueForm").$promise;
}
embarqueImportacionPrepService.$inject = ["EmbarqueFactory", "$stateParams"];

// function comprobanteImportacionPrepService(ComprobanteFactory, $stateParams) {
//   return ComprobanteFactory.get($stateParams.id, "comprobanteView").$promise;
// }

function comprobanteImportacionAplicarPrepService(ComprobanteFactory, $stateParams) {
  return ComprobanteFactory.get($stateParams.id, "aplicarComprobanteView").$promise;
}
comprobanteImportacionAplicarPrepService.$inject = ["ComprobanteFactory", "$stateParams"];

function recepcionImportacionPrepService(RecepcionFactory, $stateParams) {
  return RecepcionFactory.get($stateParams.id, "RecepcionForm").$promise;
}
recepcionImportacionPrepService.$inject = ["RecepcionFactory", "$stateParams"];

function aduanaImportacionPrepService(AduanasFactory, $stateParams) {
  return AduanasFactory.get($stateParams.id, 'AduanaForm').$promise;
}
aduanaImportacionPrepService.$inject = ["AduanasFactory", "$stateParams"];

function condicionImportacionPrepService(CondicionImportacionFactory, $stateParams) {
  return CondicionImportacionFactory.get($stateParams.id, "CondicionImportacionForm").$promise;
}
condicionImportacionPrepService.$inject = ["CondicionImportacionFactory", "$stateParams"];

function regimenAduanaImportacionPrepService(RegimenAduanasFactory, $stateParams) {
  return RegimenAduanasFactory.get($stateParams.id, 'RegimenAduanaForm').$promise;
}
regimenAduanaImportacionPrepService.$inject = ["RegimenAduanasFactory", "$stateParams"];

function tipoImpuestoAduanaImportacionPrepService(TipoImpuestoAduanaFactory, $stateParams) {
  return TipoImpuestoAduanaFactory.get($stateParams.id, 'TipoImpuestoAduanaForm').$promise;
}
tipoImpuestoAduanaImportacionPrepService.$inject = ["TipoImpuestoAduanaFactory", "$stateParams"];

function cotizacionMonedaImportacionPrepService(CotizacionMonedaFactory, $stateParams) {
  return CotizacionMonedaFactory.get($stateParams.id, 'CotizacionMonedaForm').$promise;
}
cotizacionMonedaImportacionPrepService.$inject = ["CotizacionMonedaFactory", "$stateParams"];

function servicioImportacionPrepService(ServicioFactory, $stateParams) {
  return ServicioFactory.get($stateParams.id, "ServicioForm").$promise;
}
servicioImportacionPrepService.$inject = ["ServicioFactory", "$stateParams"];

function tipoComprobanteImportacionPrepService(TipoComprobanteFactory, $stateParams) {
  return TipoComprobanteFactory.get($stateParams.id, 'TipoComprobanteForm').$promise;
}
tipoComprobanteImportacionPrepService.$inject = ["TipoComprobanteFactory", "$stateParams"];

function solicitudCompraImportacionPrepService(SolicitudCompraFactory, $stateParams) {
  return SolicitudCompraFactory.get($stateParams.id, 'SolicitudCompraForm').$promise;
}
solicitudCompraImportacionPrepService.$inject = ["SolicitudCompraFactory", "$stateParams"];

function planPagosImportacionPrepService(PlanPagosDetalleFactory, $stateParams) {
  return PlanPagosDetalleFactory.get($stateParams.id, "PlanPagoDetalleForm").$promise;
}
planPagosImportacionPrepService.$inject = ["PlanPagosDetalleFactory", "$stateParams"];

function consultaDespachoPrepService(DespachoFactory, $stateParams) {
  return DespachoFactory.get($stateParams.id, "DespachoForm").$promise;
}
consultaDespachoPrepService.$inject = ["DespachoFactory", "$stateParams"];

/**
 * Created by codiumsa on 19/10/15.
 */
angular
  .module("qualita.importacion")
  .factory("ImportacionLangFactory", ImportacionLangFactory);

ImportacionLangFactory.$inject = [
  "$resource",
  "baseurl",
  "$http",
  "$translatePartialLoader",
  "$translate"
];

function ImportacionLangFactory(
  $resource,
  baseurl,
  $http,
  $translatePartialLoader,
  $translate
) {
  var translationsNeeded = [
    "IMPORTACION",
    "IMPORTACIONES",
    "ERROR_IMPORTACION_DELETE",
    "CODE",
    "STATUS",
    "NEW_IMPORTACION",
    "PROCESS",
    "DELETE_CONFIRMATION",
    "DELETE_WARNING",
    "DELETE_FAILED",
    "ADUANA_DELETE_FAILED",
    "ABBREVIATION",
    "REPORT",
    "NOMBRE",
    "REGIMENES_ADUANA",
    "REGIMEN_ADUANA_DELETE_FAILED",
    "LONG_VALUE_WARNING",
    "CONDICIONES_IMPORTACION",
    "CONDICION_IMPORTACION_DELETE_FAILED",
    "NEW_CONDICION_IMPORTACION",
    "EDIT_CONDICION_IMPORTACION",
    "VIEW_CONDICION_IMPORTACION",
    "NEW_REGIMEN_ADUANA",
    "EDIT_REGIMEN_ADUANA",
    "VIEW_REGIMEN_ADUANA",
    "NUEVA_ADUANA",
    "EDIT_ADUANA",
    "VIEW_ADUANA",
    "IMPUESTOS_ADUANAS",
    "IMPUESTO_ADUANA_DELETE_FAILED",
    "OK",
    "CANCEL",
    "DESCRIPTION",
    "VALUE",
    "AFECTA_COSTO",
    "CALCULA_BASE_IMPONIBLE",
    "NUEVO_IMPUESTO_ADUANA",
    "EDIT_IMPUESTO_ADUANA",
    "VIEW_IMPUESTO_ADUANA",
    "TIPOS_COMPROBANTES",
    "ACTION",
    "KIND",
    "TIPOS_COMPROBANTES",
    "TIPO_COMPROBANTE",
    "TIPOS_COMPROBANTES_DELETE_FAILED",
    "NUEVO_TIPO_COMPROBANTE",
    "EDIT_TIPO_COMPROBANTE",
    "VIEW_TIPO_COMPROBANTE",
    "REQUIRED_FIELD",
    "GENERAR_CUOTAS",
    "LIBRO_IVA_COMPRAS",
    "LIBRO_IVA_VENTAS",
    "ESTADO_CUENTA_PROVEEDORES",
    "ESTADO_CUENTA_CLIENTES",
    "GRUPOS_COMPRA",
    "GRUPO_COMPRA",
    "GRUPO_COMPRA_DELETE_FAILED",
    "GRUPO_COMPRA_SAVE_ERROR",
    "VIEW_GRUPO_COMPRA",
    "EDIT_GRUPO_COMPRA",
    "NEW_GRUPO_COMPRA",
    "ACTIVO_QUESTION",
    "PERMITE_PRODUCTOS_QUESTION",
    "PERMITE_SERVICIOS_QUESTION",
    "SAVE",
    "COTIZACION_MONEDAS",
    "COTIZACION_MONEDA",
    "NEW_COTIZACION_MONEDAS",
    "EDIT_COTIZACION_MONEDAS",
    "VIEW_COTIZACION_MONEDAS",
    "REQUIRED_AND_DIGITS_ONLY_FIELD",
    "INVALID_DATE",
    "SERVICIOS",
    "SERVICIO",
    "SERVICIO_DELETE_FAILED",
    "TIPO_IMPUESTO",
    "NEW_SERVICIO",
    "EDIT_SERVICIO",
    "DELETE_SERVICIO",
    "ORDENES_COMPRA",
    "ORDEN_COMPRA",
    "ORDENES_COMPRA_DELETE_FAILED",
    "PROVEEDOR",
    "CURRENCY",
    "EMITIR_ORDEN_COMPRA_PREGUNTA",
    "ORDEN_COMPRA_EMITIDA",
    "EMISION_ORDEN_COMPRA_FALLIDA",
    "NEW",
    "NUMBER",
    "DATE",
    "LINE",
    "BRAND",
    "NEW_ORDEN_COMPRA",
    "EDIT_ORDEN_COMPRA",
    "VIEW_ORDEN_COMPRA",
    "ERROR_SAVING",
    "SHOW_ALL_ITEMS",
    "DETALLES_ORDEN_COMPRA",
    "ITEM",
    "MEASURE_UNIT",
    "AMOUNT",
    "PRICE",
    "ITEM_TOTAL",
    "CODIGO_BARRAS_PRINCIPAL",
    "ADD",
    "PROFORMAS",
    "PROFORMA",
    "SOLICITUDES_PAGO",
    "SOLICITUD_PAGO",
    "EMBARQUES",
    "EMBARQUE",
    "COMPROBANTES",
    "COMPROBANTE",
    "RECEPCION",
    "DESPACHO",
    "CIERRE",
    "CODIGO_IMPORTACION",
    "NEW_PROFORMA",
    "EDIT_PROFORMA",
    "VIEW_PROFORMA",
    "NEW_SOLICITUD_PAGO",
    "EDIT_SOLICITUD_PAGO",
    "VIEW_SOLICITUD_PAGO",
    "TOTAL_AMOUNT",
    "PROFORMA_DETALLES",
    "PERMANENT_OPERATION_WARNING",
    "MONTO",
    "PIRI_NUMBER",
    "USER",
    "VIA",
    "FECHA_EMBARQUE",
    "FECHA_LLEGADA_ESTIMADA",
    "PUERTO_SALIDA",
    "PUERTO_LLEGADA",
    "NEW_EMBARQUE",
    "EDIT_EMBARQUE",
    "VIEW_EMBARQUE",
    "EMBARQUE_DETALLES",
    "CONTAINER_NUMERO",
    "PESO_KG",
    "COMPROBANTE_DELETE_FAILED_WARNING",
    "TAX_KIND",
    "NEW_COMPROBANTE",
    "EDIT_COMPROBANTE",
    "VIEW_COMPROBANTE",
    "TIMBRADO_NO_ASOCIADO_PROVEEDOR_WARNING",
    "DIGITOS_COMPROBANTE_PUNTO_EMISION_WARNING",
    "DIGITOS_COMPROBANTE_SUCURSAL_WARNING",
    "FORMATO_NRO_COMPROBANTE_WARNING",
    "MONTOS_TOTALES_SUM_DETALLES_WARNING",
    "NUMERO_GRUPO_PRODUCTO_WARNING",
    "SUCURSAL_PROVEEDOR",
    "TIPO_CAMBIO",
    "TOTAL",
    "BASE_IMPONIBLE",
    "TAX",
    "TAX_VALUE",
    "TIPO_RECEPCION",
    "ALMACEN",
    "RECEPCION_IMPORTACION",
    "NEW_RECEPCION",
    "EDIT_RECEPCION",
    "VIEW_RECEPCION",
    "SHOW_UNIDAD_MEDIDA_BASE",
    "DETALLES_RECEPCION",
    "CANTIDAD_RECEPCIONADA",
    "NUMERO_GRUPO",
    "UNIDADES_RECEPCIONADAS",
    "CANTIDAD_UNIDAD_BASE",
    "ADUANA",
    "DESPACHANTE",
    "VALOR_FACTURA",
    "VALOR_FLETE",
    "VALOR_SEGURO",
    "VALOR_IMPONIBLE",
    "TIPO_CAMBIO_FACTURA",
    "TIPO_CAMBIO_DOLARES",
    "TOTAL_COMPROBANTES",
    "TIPO_COSTO_DESPACHO",
    "COMPROBANTES_IMPORTACION",
    "IMPORTE_TOTAL",
    "LIQUIDACION_IMPUESTOS",
    "IMPORTE",
    "ERROR_CIERRE_IMPORTACION",
    "CIERRE_IMPORTACION",
    "TOTAL_GASTOS_DESPACHO",
    "TOTAL_FACTURAS_IMPORTACION",
    "TOTAL_FLETE",
    "TOTAL_SEGURO",
    "TOTAL_COSTO_IMPORTACION",
    "DIFFERENCES",
    "COSTO_PRODUCTOS",
    "GASTOS_DESPACHO",
    "CANTIDAD_FACTURADA",
    "ULTIMO_COSTO",
    "EXISTENCIA_RESULTANTE",
    "COSTO_PROMEDIO",
    "FACTURA",
    "TIPO_CAMBIO",
    "ITEM_CODE",
    "TOTALS",
    "IMPONIBLE",
    "IMPUESTO",
    "EXENTO",
    "COEFICIENTE",
    "COEFICIENTE_IMPORTACION",
    "ANULAR_CONFIRMATION",
    "ANULAR_WARNING",
    "ANULAR_FAILED",
    "ORDENES_COMPRA_ANULAR_FAILED",
    "ES_APLICABLE",
    "APLICABLE_TYPES",
    "PROFORMA_PLAN_PAGO",
    "PROFORMA_CANTIDAD_PAGOS",
    "PROFORMA_PERIODO_PAGOS",
    "PROFORMA_ENTREGA_INICIAL",
    "PROVEEDOR_DESPACHANTE",
    "USERS_TO_NOTIFY",
    "MONTO_TOTAL_ERROR",
    "DATE_FROM",
    "DATE_TO",
    "CUOTA",
    "MONTO_APLICADO",
    "IMPUESTO_APLICADO",
    "COMPROBANTE_SIN_SALDO",
    "PRODUCTO_SERVICIO",
    "MONTO",
    "APLICACION_COMPROBANTE",
    "DELETE",
    "PREVISUALIZAR_APLICACION",
    "APLICACION_MAYOR_TOTAL_ERROR",
    "DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS",
    "SOLICITUDES_COMPRA",
    "NEW_SOLICITUD_COMPRA",
    "EDIT_SOLICITUD_COMPRA",
    "VIEW_SOLICITUD_COMPRA",
    "SOLICITUD_COMPRA_DELETE_FAILED",
    "SOLICITUD_COMPRA",
    "CERRAR_CONFIRMATION",
    "CERRAR_WARNING",
    "NO_COMPROBANTES_APLICABLES",
    "SALDO",
    "APLICACION_YA_EXISTENTE",
    "QUE_DESEA_Q",
    "EDITAR_APLICACION",
    "AGREGAR_APLICACION",
    "PRIMER_VTO",
    "SALDO_PENDIENTE",
    "FECHA_VENCIMIENTO",
    "CUOTAS_COMPROBANTE",
    "CUOTA_DELETE_FAILED",
    "NEW_CUOTA",
    "EDIT_CUOTA",
    "VIEW_CUOTA",
    "ULTIMO_COSTO_MONEDA_ORIGEN",
    "COSTO",
    "COSTO_NACIONALIZADO",
    "COSTO_ML",
    "COSTO_TOTAL_ML",
    "COSTO_PRIMEDIO_ML",
    "GASTOS_IMPORTACION",
    "FOB",
    "TIMBRADO_PROVEEDOR",
    "CANTIDAD_CUOTAS",
    "PLAZO_CUOTAS",
    "MONTO_INICIAL",
    "VIEW",
    "EDIT",
    "PLAN_PAGOS",
    "CONFIRMAR_RECEPCION",
    "ANULAR",
    "IMPRIMIR_RECEPCION",
    "GENERAR_ETIQUETAS",
    "RECHAZAR",
    "APROBAR",
    "TIPO_ORDEN_COMPRA",
    "COMPRA_MAYOR_VENTA_ERROR",
    "ERROR_COMPRA_VENTA_NEGATIVO",
    "SERVICIO_DESCRIPCION_WARNING",
    "ACTIVE",
    "IMPUESTOS_TOTALES_SUM_DETALLES_WARNING",
    "FOB_INVALID_WARNING",
    "OC_IMP_INVALID_MONTO",
    "MONEDA_DESDE",
    "MONEDA_HASTA",
    "VALOR_COMPRA",
    "VALOR_VENTA",
    "SELECT_ORDEN_COMPRA",
    "GRUPO_COMPRA_SAVE_ERROR_NO_CHECK",
    "CON_PRECIO",
    "SIN_PRECIO",
    "IMPRIMIR_SC",
    "IMPRIMIR_SC_MSG",
    "IMPRIMIR_FAILED",
    "RECHAZAR_SC",
    "RECHAZAR_SC_MSG",
    "APROBAR_SC",
    "APROBAR_SC_MSG",
    "CERRAR_SC",
    "CERRAR_SC_Q",
    "CERRAR_SC_MSG",
    "GENERAR_ETIQUETAS_ERROR_1",
    "GENERAR_ETIQUETAS_ERROR_2",
    "UTILIZAR_PROCESOS_VENTA",
    "UTILIZAR_PROCESOS_COMPRA",
    "CONFIRMACION_RECHAZO",
    "RECHAZAR_SOLICITUD_WARNING",
    "CONFIRMACION_APROBACION",
    "APROBAR_SOLICITUD_WARNING",
    "MONTO_MAYOR_A_PROFROMA_ERROR",
    "TOTAL_GENERAL",
    "NUMERO_COMPROBANTE",
    "INVALID_DATES",
    "NROCOMP_PROVEEDOR_TIMBRADO_TIPO_REPETIDO",
    "CODIGO_IMPORTACION",
    "DESPACHOS",
    "VALOR_FACTURA_MONEDA",
    "VALOR_FLETE_MONEDA",
    "VALOR_SEGURO_MONEDA",
    "VALOR_IMPONIBLE_MONEDA",
    "TOTAL_COMPROBANTES_MONEDA",
    "TOTAL_LIQUIDACION_MONEDA",
    "MONEDA",
    "SALDO_IMPUESTO",
    "NRO_GRUPO_REPEAT_ERROR",
    "VALOR_FLETE_MONEDA_ORIGINAL",
    "VALOR_SEGURO_MONEDA_ORIGINAL",
    "SERVICIO_NO_DEFINED_ERROR",
    "FORMA_PAGO",
    "FECHA_ESPERADA",
    "COMPRADOR",
    "ID_IMPORTACION",
    "DATE_APERTURA",
    "DATE_CIERRE",
    "TIENE_DIFERENCIA",
    "ORDENES_COMPRA_CERRAR_FAILED",
    "CERRAR_FAILED",
    "TIPO_COMPROBANTE_NO_DEFINED_ERROR",
    "PROVEEDOR_SINGLE",
    "EDIT_APLICACION_COMPROBANTE",
    "AJUSTES_REALIZAR",
    "CERRAR_IMPORTACION_TITLE",
    "CERRAR_IMPORTACION_MESSAGE",
    "DESCONFIRMAR_COMPROBANTE",
    "DESCONFIRMAR_COMPROBANTE_MESSAGE",
    "NO_OPEN_PERIODS_NOW",
    "AJUSTES_REALIZAR",
    "ANTICIPO_IRACIS",
    "CONTROLAR_TIMBRADO",
    "INFORME_IMPOSITIVO",
    "NO_CUMPLE_CONDICION_IMPUESTOS",
    "UTILIZAR_VENTAS_DEFECTO",
    "GENERAR_PAGARE",
    "PLANTILLA_PAGARE",
    "PROVEEDOR",
    "REENVIAR_COTIZACION",
    "REENVIAR_COTIZACION_MSG",
    "REENVIAR_COTIZACION_FAILED",
    "PARAMETRO_ENVIO_CONTABILIDAD_MSG",
    "REENVIAR_SERVICIO",
    "REENVIAR_SERVICIO_MSG",
    "REENVIAR_SERVICIO_FAILED"
  ];

  var service = {
    getTranslations: getTranslations
  };

  return service;

  function getTranslations() {
    $translatePartialLoader.addPart("importaciones");
    return $translate.refresh().then(function() {
      return $translate(translationsNeeded);
    });
  }
}

angular.module('qualita.importacion').directive('uiSrefActiveIf', ['$state', function($state) {
  return {
    restrict: "A",
    controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
      var state = $attrs.uiSrefActiveIf;

      function update() {
        if ( $state.includes(state) || $state.is(state) ) {
          $element.addClass("active");
        } else {
          $element.removeClass("active");
        }
      }

      $scope.$on('$stateChangeSuccess', update);
      update();
    }]
  };
}]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.importacion')
  .factory('GruposCompraFactory', GruposCompraFactory);

GruposCompraFactory.$inject = ['$resource', 'baseurl'];

function GruposCompraFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var GruposCompra = $resource( baseurl.getBaseUrl() + "/gruposcompra/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if(params){
      params.view = view != undefined ? view : 'BaseList';
    }
    return GruposCompra.query(params);
  }

  function get(id, view) {
    return GruposCompra.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new GruposCompra(attrs);
  }

  function save(grupocompra) {
    return (grupocompra.id) ? grupocompra.$update() : grupocompra.$save();
  }

  function remove(grupocompra) {
    return grupocompra.$remove();
  }
}

'use strict';


angular.module('qualita.importacion')
  .controller('GruposCompraImpIndexCtrl', GruposCompraImpIndexCtrl);

GruposCompraImpIndexCtrl.$inject = ["GruposCompraFactory", "ImportacionLangFactory", "filterFactory",
"ReportTicketFactory", "$window","$modal","$state", "CsvFactory", "UtilFactory", "baseurl", "$filter"];

function GruposCompraImpIndexCtrl(GruposCompraFactory, ImportacionLangFactory, filterFactory,
ReportTicketFactory, $window,$modal,$state, CsvFactory, UtilFactory, baseurl, $filter) {
  var vm = this;

  var defaultColumnOrder = ['id','descripcion', 'deposito.descripcion', 'permiteProducto', 'permiteServicio', 'codigoContable.descripcion', 'activo','clase.descripcion'];

  ImportacionLangFactory.getTranslations().then(function(translations) {
    vm.options = {
      'resource': 'gruposcompra',
      'title': $filter('translate')('GRUPOS_COMPRA'),
      'factory': GruposCompraFactory,
      'view': 'GrupoCompraList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('GRUPO_COMPRA_DELETE_FAILED'),
      'columns': [
        {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': true },
        {'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION')},
        {
          data: "clase.descripcion",
          title: $filter("translate")("KIND"),
          renderWith: "emptyRender",
          class: "dt-center",'type' : 'combo', 'filterUrl': 'tipos/filtro/clase_almacen'
        },
        {'data': 'permiteProducto', 'title': $filter('translate')('PERMITE_PRODUCTOS_QUESTION'), 'class' : 'dt-center', 'visible': true, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
        {'data': 'permiteServicio', 'title': $filter('translate')('PERMITE_SERVICIOS_QUESTION'), 'class' : 'dt-center', 'visible': true, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
        {
          data: "codigoContable.descripcion",
          title: $filter("translate")("CODIGO_CONTABLE"),
          renderWith: "emptyRender",
          class: "dt-center",'type' : 'combo', 'filterUrl': 'tipos/filtro/codigos_contables_grupos_compras'
        },
        {'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class' : 'dt-center', 'visible': true, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'}
      ],
      'hasOptions' : true,
      'defaultOrderColumn' : 0,
      'defaultOrderDir' : "desc",
      'extraMenuOptions':
        [
          {
            'title': $filter('translate')('REPORT'),
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function() { return true; },
            'action': function() {
              var filters = createFilters(vm.options.getFilters());
                ReportTicketFactory.ticket('listadoGruposCompra', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
                vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open(vm.pdfDownloadURL, '_blank');
              });
            }
          },
          {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                  CsvFactory.csv("gruposcompra", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
        ],
      'extraRemoveConditions': function(item) {
        if(item.codigo=='comprobante_importacion' || item.codigo=='gasto_importacion'){
          return false;
        }else{
          return true;
        }
      },
      'extraEditConditions': function(item) {
        if(item.codigo=='comprobante_importacion' || item.codigo=='gasto_importacion'){
          return false;
        }else{
          return true;
        }
      },
    };
  });


  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      if(search === 'No' && (data === 'permiteProducto' || data === 'permiteServicio' || data === 'activo')){
        search = 'false';
    }
    if(search === 'Sí' && (data === 'permiteProducto' || data === 'permiteServicio' || data === 'activo')){
        search = 'true';
    }
      filtersArr.push({path: data, like: search})
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.importacion')
  .controller('GruposCompraImpFormCtrl', GruposCompraImpFormCtrl);

GruposCompraImpFormCtrl.$inject = ['$rootScope', '$scope', 'GruposCompraFactory', 'grupoCompraPrepService',
  'AlmacenFactory', '$location', 'notify', '$state', "ImportacionLangFactory", "WatchLocacionChangeFactory", "formFactory",
  "filterFactory", "$filter","TiposFactory"];

function GruposCompraImpFormCtrl($rootScope, $scope, GruposCompraFactory, grupoCompraPrepService,
                                 AlmacenFactory, $location, notify, $state, ImportacionLangFactory,
                                WatchLocacionChangeFactory, formFactory, filterFactory, $filter,TiposFactory) {

  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;

  $rootScope.isProcessing = false;

  activate();

  function activate() {
    TiposFactory.clasesGrupoCompra().then(function (data) {
      vm.clases=data.data;
    });
    TiposFactory.codigosContables().then(function (data) {
      vm.codigosContables=data.data;
    });
    ImportacionLangFactory.getTranslations().then(function(translations) {
      $scope.translations = translations;
      if(vm.watcherAlmacen) { vm.watcherAlmacen(); } //hacemos unwatch de la expresion anterior
      WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcherAlmacen) {
        vm.watcherAlmacen = watcherAlmacen;
        var filterSucursal = {
            search: filterFactory.single({
              path: "sucursal.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
            }).value()
          };
        vm.almacenes = AlmacenFactory.all(filterSucursal);
      }, "locacion.id");

      if($state.is("app.gruposcompra.new")) {
        activateNew();
      } else if($state.is("app.gruposcompra.edit")) {
        activateEdit();
      } else if($state.is("app.gruposcompra.view")) {
        activateView();
      }
    });
  }

  function activateNew() {
    vm.grupoCompra = {activo: true};
    vm.title = $filter('translate')('NEW_GRUPO_COMPRA');
  }

  function activateEdit() {
    vm.grupoCompra = grupoCompraPrepService;
    vm.title = $filter('translate')('EDIT_GRUPO_COMPRA');
    vm.entidadId = vm.grupoCompra.id;
    vm.entidad = "GrupoCompra"
  }

  function activateView() {
    vm.grupoCompra = grupoCompraPrepService;
    vm.title = $filter('translate')('VIEW_GRUPO_COMPRA');
    vm.entidadId = vm.grupoCompra.id;
    vm.entidad = "GrupoCompra"
    vm.view = true;
  }
  function submit() {
    vm.submited = true;
    if($scope.GrupoCompraForm.$valid) {
      $rootScope.isProcessing = true;
      if(vm.grupoCompra.codigoContable == undefined){
        vm.grupoCompra.codigoContable = null;
      }
      if(vm.grupoCompra.permiteProducto || vm.grupoCompra.permiteServicio){
        submitGrupoCompra().then(function(response) {
          $location.path('/gruposcompra');
        }, function(error) {
          $rootScope.isProcessing = false;
          errorHandler(error);
        });
      }else{
        $rootScope.isProcessing = false;
        var msg = $filter('translate')('GRUPO_COMPRA_SAVE_ERROR_NO_CHECK');
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
      }

    }
  }

  function submitGrupoCompra() {
    var resource = GruposCompraFactory.create(vm.grupoCompra);
    return GruposCompraFactory.save(resource);
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if (e.data[0].constraint == 'descripcion') {
        msg += '\n\n' + "Ya existe un grupo de compra con el mismo nombre.\n";
      } else {
        msg += '\n\n' + error.message + '.';
      }
    });
    notify({
      message: msg,
      classes: 'alert-danger',
      position: 'right'
    });
  }

  function cancel() {
    $location.path("/gruposcompra");
  }


  $scope.resource = 'gruposcompra'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.condiconimportacion
 * @description
 * # condicionimportacion
 * Factory in the qualita.
 */
angular.module('qualita.importacion')
  .factory('CondicionImportacionFactory', ['$resource', 'baseurl', 'ImportacionLangFactory', '$filter', function($resource,  baseurl,
                                                                                                  ImportacionLangFactory, $filter) {

  	var CondicionImportacion= $resource( baseurl.getBaseUrl() + '/condicionimportaciones/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    var schema = undefined;

    ImportacionLangFactory.getTranslations().then(function(translations) {
      schema = {
        type: 'object',
        title: $filter('translate')('CONDICION_IMPORTACION'),
        properties: {
          /*codigo: {
           title: 'Código',
           type: 'string'
           },*/
          descripcion: {
            title: $filter('translate')('NOMBRE'),
            type: 'string',
            maxLength: 255,
            validationMessage: {
              201: $filter('translate')('LONG_VALUE_WARNING')
            },
          },
          abreviatura: {
            title: $filter('translate')('ABBREVIATION'),
            type: 'string',
            maxLength: 255,
            validationMessage: {
              201: $filter('translate')('LONG_VALUE_WARNING')
            },
          }
        },
        required: ['descripcion','abreviatura']
      };
    });

    var formNew = [
      'descripcion','abreviatura'
    ];

    var formEdit = angular.copy(formNew);
    var formView = angular.copy(formNew);

    var forms = {
      'new': formNew,
      'edit': formEdit,
      'view': formView
    }


    return {
      all: function(params) {
        return CondicionImportacion.query(params);
      },

      get: function(id, view) {
        return CondicionImportacion.get({id: id, view: view ? view : "base"});
      },

      create: function(attrs) {
        return new CondicionImportacion(attrs);
      },

      save: function(condicionImportaicon) {
        return (condicionImportaicon.id) ? condicionImportaicon.$update() : condicionImportaicon.$save();
      },

      remove: function(condicionImportaicon) {
        return condicionImportaicon.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return forms[type];
      }
    };
  }]);

'use strict';


angular.module('qualita.importacion')
  .controller('CondicionImportacionIndexCtrl', ["$scope", "ImportacionLangFactory", "CondicionImportacionFactory", "filterFactory", "ReportTicketFactory", "$window", "CsvFactory", "UtilFactory", "baseurl", "$filter", function ($scope, ImportacionLangFactory,
  CondicionImportacionFactory, filterFactory, ReportTicketFactory, $window,
  CsvFactory, UtilFactory, baseurl, $filter) {


    var defaultColumnOrder = ['id','codigo','descripcion','abreviatura'];

    ImportacionLangFactory.getTranslations().then(function(translations) {
      $scope.options = {
        'resource': 'condicionimportaciones',
        'title': $filter('translate')('CONDICIONES_IMPORTACION'),
        'factory': CondicionImportacionFactory,
        'view': 'CondicionImportacionList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('CONDICION_IMPORTACION_DELETE_FAILED'),
        'columns': [
          {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
          {'data': 'descripcion', 'title': $filter('translate')('NOMBRE')},
          {'data': 'abreviatura', 'title': $filter('translate')('ABBREVIATION')}
        ],
        'hasOptions' : true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraMenuOptions':
          [
            {
              'title': $filter('translate')('REPORT'),
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoCondicionesImportacion', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("condicionimportaciones", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ]
      };
    });


      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({path: data, like: search})
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
  }]);

'use strict';

angular.module('qualita.importacion')
  .controller('CondicionImportacionFormController', ['$rootScope', '$scope', 'CondicionImportacionFactory', 'ImportacionFactory', 'formFactory',
    '$location', 'condicionImportacionPrepService', '$state', 'notify', 'ImportacionLangFactory', '$filter',
    function ($rootScope, $scope, CondicionImportacionFactory, ImportacionFactory, formFactory,
      $location, condicionImportacionPrepService, $state, notify, ImportacionLangFactory, $filter) {

      activate();

      function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations) {
          $scope.translations = translations;
          $scope.schema = CondicionImportacionFactory.schema();
          $scope.options = formFactory.defaultOptions();

          if($state.is('app.condicionimportaciones.new')) {
            activateNew();
          } else if($state.is('app.condicionimportaciones.edit')) {
            activateEdit();
          } else if($state.is('app.condicionimportaciones.view')) {
            activateView();
          }

          $rootScope.isProcessing = false;
        });
      }

      function activateNew() {
        $scope.title = $filter('translate')('NEW_CONDICION_IMPORTACION');
        $scope.form = CondicionImportacionFactory.form('new');
        $scope.model = {};
        $scope.schema.readonly = false;
      }

      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_CONDICION_IMPORTACION');
        $scope.form = CondicionImportacionFactory.form('edit');
        $scope.model = condicionImportacionPrepService;
        $scope.entidad = 'CondicionImportacion';
        $scope.entidadId = $scope.model.id;
        $scope.schema.readonly = false;
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_CONDICION_IMPORTACION');
        $scope.form = CondicionImportacionFactory.form('view');
        $scope.options = formFactory.defaultViewOptions();
        $scope.model = condicionImportacionPrepService;
        $scope.entidad = 'CondicionImportacion';
        $scope.entidadId = $scope.model.id;
        $scope.view = true;
        $scope.schema.readonly = true;
      }

    $scope.submit = function (form) {
      formFactory.defaultSubmit('condicionimportaciones', $scope, form, CondicionImportacionFactory, undefined, errorHandler);
    };

    function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
          if (error.constraint == 'abreviatura') {
            msg += '\n\n' + "Ya existe una condición de importación con la misma abreviatura.\n";
          } else if (error.constraint == 'descripcion') {
            msg += '\n\n' + "Ya existe una condición de importación con el mismo nombre.\n";
          } else {
            msg += '\n\n' + error.message + '.';
          }
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      }

    $scope.cancel = function () {
      $location.path('/condicionimportaciones');
    };

  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.tipoimpuestoaduana
 * @description
 * # tipoimpuestoaduana
 * Factory in the qualita.
 */
angular.module('qualita.importacion')
  .factory('TipoImpuestoAduanaFactory', ['$resource', 'baseurl', 'ImportacionLangFactory', '$filter', '$http', function($resource,  baseurl,
                                                                                                ImportacionLangFactory, $filter, $http) {

  	var TipoImpuestoAduana= $resource( baseurl.getBaseUrl() + '/tipoimpuestoaduanas/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    var schema = undefined;

    ImportacionLangFactory.getTranslations().then(function(translations) {
      schema = {
        type: 'object',
        title: $filter('translate')('TIPO_IMPUESTO_ADUANA'),
        properties: {
          descripcion: {
            title: $filter('translate')('DESCRIPTION'),
            type: 'string',
            maxLength: 255,
            validationMessage: {
              201: $filter('translate')('LONG_VALUE_WARNING')
            },
          },
          afectaCosto: {
            title: $filter('translate')('AFECTA_COSTO'),
            type: 'boolean'
          },
          calculaBaseImponible: {
            title: $filter('translate')('CALCULA_BASE_IMPONIBLE'),
            type: 'boolean'
          },
          anticipoIracis: {
            title: $filter('translate')('ANTICIPO_IRACIS'),
            type: 'boolean'
          }
        },
        required: ['codigo', 'descripcion', 'abreviatura']
      };
    });


    var formNew = [
      'descripcion','abreviatura',{ key: 'afectaCosto', type: 'customcheckbox' },
      { key: 'calculaBaseImponible', type: 'customcheckbox' },
      { key: 'anticipoIracis', type: 'customcheckbox' }
    ];

    var formEdit = angular.copy(formNew);
    var formView = angular.copy(formNew);

    var forms = {
      'new': formNew,
      'edit': formEdit,
      'view': formView
    };


    return {
      all: function(params) {
        console.log("llamo a all");
        return TipoImpuestoAduana.query(params);
      },

      get: function(id, view) {
        return TipoImpuestoAduana.get({id: id, view: view ? view : "base"});
      },

      create: function(attrs) {
        return new TipoImpuestoAduana(attrs);
      },

      save: function(tipoImpuestoAduana) {
        return (tipoImpuestoAduana.id) ? tipoImpuestoAduana.$update() : tipoImpuestoAduana.$save();
      },

      remove: function(tipoImpuestoAduana) {
        return tipoImpuestoAduana.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return forms[type];
      },

      reenviar: function (idTipoImpuestoAduana) {
        return $http({
          url: baseurl.getBaseUrl() + '/tipoimpuestoaduanas/reenviar/' + idTipoImpuestoAduana,
          method: "PUT"
        });
      }      
    };
  }]);

'use strict';


angular.module('qualita.importacion')
  .controller('TipoImpuestoAduanaIndexCtrl', ["$scope", "ImportacionLangFactory", "TipoImpuestoAduanaFactory", "filterFactory", "ReportTicketFactory", "$window", "CsvFactory", "UtilFactory", "baseurl", "$filter", "ParametrosFactory", "$modal", "$state", "notify", function ($scope, ImportacionLangFactory, TipoImpuestoAduanaFactory, filterFactory,
                                                       ReportTicketFactory, $window, CsvFactory, UtilFactory, baseurl, $filter, ParametrosFactory, $modal, $state, notify) {

    var defaultColumnOrder = ['id','codigo','descripcion','abreviatura','afectaCosto','calculaBaseImponible'];

    var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
    var enviarAContabilidad = '';

    promise1.then(function(response) {
        enviarAContabilidad = response.valorTipo.codigo;
    });

    ImportacionLangFactory.getTranslations().then(function(translations) {
      $scope.options = {
        'resource': 'tipoimpuestoaduanas',
        'title': $filter('translate')('IMPUESTOS_ADUANAS'),
        'factory': TipoImpuestoAduanaFactory,
        'view': 'TipoImpuestoAduanaList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('IMPUESTO_ADUANA_DELETE_FAILED'),
        'columns': [
          {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
          {'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION')},
          {'data': 'afectaCosto', 'title': $filter('translate')('AFECTA_COSTO'), 'class' : 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
          {'data': 'calculaBaseImponible', 'title': $filter('translate')('CALCULA_BASE_IMPONIBLE'), 'class' : 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'}
        ],
        'hasOptions' : true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraMenuOptions':
          [
            {
              'title': $filter('translate')('REPORT'),
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoTipoImpuestoAduana', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("tipoimpuestoaduanas", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ],
          'extraRowOptions': [
            {
              templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
              functionName: 'reenviar',
              customAttribute: ['enviadoAContabilidad', 'id'],
              functionDef: function (atributo) {
  
                var idTipoImpAduana = atributo[1];
  
                TipoImpuestoAduanaFactory.get(idTipoImpAduana, "TipoImpuestoAduanaList").$promise.then(function (
                  response
                ) {
                  $scope.tituloModal = $filter('translate')('REENVIAR_IMPUESTO_ADUANA_Q');
                  $scope.mensajeModal = $filter('translate')('REENVIAR_IMPUESTO_ADUANA_MSG');
                  var modalInstance = $modal.open({
                    templateUrl: 'views/directives/confirmation-modal.html',
                    scope: $scope
                  });
  
                  $scope.cancel = function () {
                    modalInstance.dismiss('cancel');
                  };
  
                  $scope.ok = function (id) {
                    TipoImpuestoAduanaFactory.reenviar(response.id).then(function (response) {
                      //ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                        if (enviarAContabilidad == 'si') {
                          if (response.data.enviadoAContabilidad == false) {
                            notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                          }
                        }
                      //});
                      $state.go($state.current, {}, { reload: true });
                    }, function (error) {
                      var msg = "";
                      if (error.data && error.data.length > 0 && error.data[0].message) {
                        msg = error.data[0].message;
                      } else {
                        msg = $filter('translate')('REENVIAR_PLANILLA_FAILED');
                      }
  
                      notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    });
                    modalInstance.close();
                  }
                });
              },
              conditionName: 'canReenviar',
              conditionDef: function (atributo) {
                var enviadoAContabilidad = atributo[0];
  
                if (enviarAContabilidad == 'si') {
                  if (enviadoAContabilidad === "No") {
                    return true;
                  }
                }
              }
            }
          ]
      };
    });

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          if(search === 'No' && (data === 'afectaCosto' || data === 'calculaBaseImponible')){
            search = 'false';
        }
        if(search === 'Sí' && (data === 'afectaCosto' || data === 'calculaBaseImponible')){
            search = 'true';
        }
          filtersArr.push({path: data, like: search})
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
  }]);

'use strict';

angular.module('qualita.importacion')
  .controller('TipoImpuestoAduanaFormController', ['$rootScope', '$scope', 'TipoImpuestoAduanaFactory', 'ImportacionLangFactory', 'formFactory', '$location',
    "tipoImpuestoAduanaPrepService", "$state", "notify", "$filter",
    function($rootScope, $scope, TipoImpuestoAduanaFactory, ImportacionLangFactory, formFactory, $location,
      tipoImpuestoAduanaPrepService, $state, notify, $filter) {

      activate();

      function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations) {
          $scope.translations = translations;
          $scope.schema = TipoImpuestoAduanaFactory.schema();
          $scope.options = formFactory.defaultOptions();

          if ($state.is("app.tipoimpuestoaduanas.new")) {
            activateNew();
          } else if ($state.is("app.tipoimpuestoaduanas.edit")) {
            activateEdit();
          } else if ($state.is("app.tipoimpuestoaduanas.view")) {
            activateView();
          }

          $rootScope.isProcessing = false;
        });
      }

      function activateNew() {
        $scope.title = $filter('translate')('NUEVO_IMPUESTO_ADUANA');
        $scope.form = TipoImpuestoAduanaFactory.form('new');
        $scope.model = {};
        $scope.model.afectaCosto = false;
        $scope.model.calculaBaseImponible = false;
        $scope.schema.readonly = false;
      }


      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_IMPUESTO_ADUANA');
        $scope.form = TipoImpuestoAduanaFactory.form('edit');
        $scope.model = tipoImpuestoAduanaPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "TipoImpuestoAduana";
        if ($scope.model.afectaCosto === undefined) {
          $scope.model.afectaCosto = false;
        }
        if ($scope.model.calculaBaseImponible === undefined) {
          $scope.model.calculaBaseImponible = false;
        }
        $scope.schema.readonly = false;
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_IMPUESTO_ADUANA');
        $scope.form = TipoImpuestoAduanaFactory.form('view');
        $scope.options = formFactory.defaultViewOptions();
        $scope.model = tipoImpuestoAduanaPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "TipoImpuestoAduana";
        if ($scope.model.afectaCosto === undefined) {
          $scope.model.afectaCosto = false;
        }
        if ($scope.model.calculaBaseImponible === undefined) {
          $scope.model.calculaBaseImponible = false;
        }
        $scope.view = true;
        $scope.schema.readonly = true;
      }

      $scope.submit = function(form) {
        formFactory.defaultSubmit('tipoimpuestoaduanas', $scope, form, TipoImpuestoAduanaFactory, undefined, errorHandler);
      };

      function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
          if (error.constraint == 'descripcion') {
            msg += '\n\n' + "Ya existe un tipo de impuesto de aduana con la misma descripción.\n";
          } else {
            msg += '\n\n' + error.message + '.';
          }
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      }

      $scope.cancel = function() {
        $location.path('/tipoimpuestoaduanas');
      };

    }
  ]);
'use strict';

/**
 * @ngdoc service
 * @name qualita.regimenaduanas
 * @description
 * # regimenaduanas
 * Factory in the qualita.
 */
angular.module('qualita.importacion')
  .factory('RegimenAduanasFactory', ['$resource', 'baseurl', 'ImportacionLangFactory', '$filter', function($resource,  baseurl,
                                                                                            ImportacionLangFactory, $filter) {

  	var RegimenAduana = $resource( baseurl.getBaseUrl() + '/regimenaduanas/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    var schema = undefined;

    ImportacionLangFactory.getTranslations().then(function(translations) {
      schema = {
        type: 'object',
        title: $filter('translate')('REGIMEN_ADUANA'),
        properties: {
          descripcion: {
            title: $filter('translate')('NOMBRE'),
            type: 'string',
            maxLength: 255,
            validationMessage: {
              201: $filter('translate')('LONG_VALUE_WARNING')
            },
          },
          abreviatura: {
            title: $filter('translate')('ABBREVIATION'),
            type: 'string',
            maxLength: 255,
            validationMessage: {
              201: $filter('translate')('LONG_VALUE_WARNING')
            },
          }
        },
        required: ['descripcion', 'abreviatura']
      };
    });


    var formNew = [
      'descripcion', 'abreviatura'
    ];

    var formEdit = angular.copy(formNew);
    var formView = angular.copy(formNew);

    var forms = {
      'new': formNew,
      'edit': formEdit,
      'view': formView
    }

    return {
      all: function(params) {
        return RegimenAduana.query(params);
      },

      get: function(id, view) {
        return RegimenAduana.get({id: id, view: view ? view : "base"});
      },

      create: function(attrs) {
        return new RegimenAduana(attrs);
      },

      save: function(regimen) {
        return (regimen.id) ? regimen.$update() : regimen.$save();
      },

      remove: function(regimen) {
        return regimen.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return forms[type];
      }
    };
  }]);

'use strict';


angular.module('qualita.importacion')
  .controller('RegimenAduanasIndexCtrl', ["$scope", "ImportacionLangFactory", "RegimenAduanasFactory", "filterFactory", "ReportTicketFactory", "$window", "CsvFactory", "UtilFactory", "baseurl", "$filter", function ($scope, ImportacionLangFactory, RegimenAduanasFactory,
  filterFactory, ReportTicketFactory, $window, CsvFactory, UtilFactory, baseurl, $filter) {
    ImportacionLangFactory.getTranslations().then(function(translations) {
      var defaultColumnOrder = ['id','descripcion','abreviatura'];
      $scope.options = {
        'resource': 'regimenaduanas',
        'title': $filter('translate')('REGIMENES_ADUANA'),
        'factory': RegimenAduanasFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'view': 'RegimenAduanaList',
        'failedDeleteError': $filter('translate')('REGIMEN_ADUANA_DELETE_FAILED'),
        'columns': [
          {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
          {'data': 'descripcion', 'title': $filter('translate')('NOMBRE')},
          {'data': 'abreviatura', 'title': $filter('translate')('ABBREVIATION')}
        ],
        'hasOptions' : true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraMenuOptions':
          [
            {
              'title': $filter('translate')('REPORT'),
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoRegimenesAduana', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("regimenaduanas", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ]
      };
    });

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({path: data, like: search})
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
  }]);

'use strict';

angular.module('qualita.importacion')
  .controller('RegimenAduanasFormController', ['$rootScope', '$scope', 'ImportacionLangFactory', 'RegimenAduanasFactory', 'formFactory', '$location',
    'regimenAduanaPrepService', '$state', 'notify', '$filter',
    function($rootScope, $scope, ImportacionLangFactory, RegimenAduanasFactory, formFactory, $location,
      regimenAduanaPrepService, $state, notify, $filter) {

      activate();

      function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations) {
          $scope.translations = translations;
          $scope.schema = RegimenAduanasFactory.schema();
          $scope.options = formFactory.defaultOptions();

          if ($state.is("app.regimenaduanas.new")) {
            activateNew();
          } else if ($state.is("app.regimenaduanas.edit")) {
            activateEdit();
          } else if ($state.is("app.regimenaduanas.view")) {
            activateView();
          }

          $rootScope.isProcessing = false;
        });
      }

      function activateNew() {
        $scope.title = $filter('translate')('NEW_REGIMEN_ADUANA');
        $scope.form = RegimenAduanasFactory.form('new');
        $scope.model = {};
        $scope.schema.readonly = false;
      }

      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_REGIMEN_ADUANA');
        $scope.form = RegimenAduanasFactory.form('edit');
        $scope.model = regimenAduanaPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "RegimenAduana";
        $scope.schema.readonly = false;
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_REGIMEN_ADUANA');
        $scope.form = RegimenAduanasFactory.form('view');
        $scope.options = formFactory.defaultViewOptions();
        $scope.model = regimenAduanaPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "RegimenAduana";
        $scope.schema.readonly = true;
        $scope.view = true;
      }

      $scope.submit = function(form) {
        formFactory.defaultSubmit('regimenaduanas', $scope, form, RegimenAduanasFactory, undefined, errorHandler);
      };

      function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
          if (error.constraint == 'abreviatura') {
            msg += '\n\n' + "Ya existe un regimen de aduana con la misma abreviatura.\n";
          } else if (error.constraint == 'descripcion') {
            msg += '\n\n' + "Ya existe un regimen de aduana con el mismo nombre.\n";
          } else {
            msg += '\n\n' + error.message + '.';
          }
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      }

      $scope.cancel = function() {
        $location.path('/regimenaduanas');
      };

    }
  ]);
'use strict';

/**
 * @ngdoc service
 * @name qualita.aduana
 * @description
 * # tipoimpuestos
 * Factory in the qualita.
 */
angular.module('qualita.importacion')
  .factory('AduanasFactory', ['$resource', 'baseurl', 'ImportacionLangFactory', '$filter',
    function($resource, baseurl, ImportacionLangFactory, $filter) {

      var Aduana = $resource(baseurl.getBaseUrl() + '/aduanas/:id', {
        id: '@id'
      }, {
        update: {
          method: 'PUT'
        }
      });

      var schema = undefined;
      ImportacionLangFactory.getTranslations().then(function(translations) {
        schema = {
          type: 'object',
          title: 'Aduana',
          properties: {
            descripcion: {
              title: $filter('translate')('NOMBRE'),
              type: 'string',
              maxLength: 255,
              validationMessage: {
                201: $filter('translate')('LONG_VALUE_WARNING')
              },
            },
            abreviatura: {
              title: $filter('translate')('ABBREVIATION'),
              type: 'string',
              maxLength: 255,
              validationMessage: {
                201: $filter('translate')('LONG_VALUE_WARNING')
              },
            }
          },
          required: ['descripcion', 'abreviatura']
        };
      });

      var formNew = [
        'descripcion', 'abreviatura'
      ];

      var formEdit = angular.copy(formNew);
      var formView = angular.copy(formNew);

      var forms = {
        'new': formNew,
        'edit': formEdit,
        'view': formView
      }


      return {
        all: function(params) {
          return Aduana.query(params);
        },

        get: function(id, view) {
          return Aduana.get({
            id: id,
            view: view ? view : "base"
          });
        },

        create: function(attrs) {
          return new Aduana(attrs);
        },

        save: function(aduana) {
          return (aduana.id) ? aduana.$update() : aduana.$save();
        },

        remove: function(aduana) {
          return aduana.$remove();
        },

        schema: function() {
          return schema;
        },

        form: function(type) {
          return forms[type];
        }
      };
    }
  ]);

'use strict';


angular.module('qualita.importacion')
  .controller('AduanasIndexCtrl', ["$scope", "AduanasFactory", "filterFactory", "ImportacionLangFactory", "ReportTicketFactory", "$window", "CsvFactory", "UtilFactory", "baseurl", "$filter", function ($scope, AduanasFactory, filterFactory, ImportacionLangFactory,
  ReportTicketFactory, $window, CsvFactory, UtilFactory, baseurl, $filter) {
    ImportacionLangFactory.getTranslations().then(function(translations) {
      var defaultColumnOrder = ['id','descripcion','abreviatura'];
      $scope.options = {
        'resource': 'aduanas',
        'title': 'Aduanas',
        'factory': AduanasFactory,
        'view': 'AduanaList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('ADUANA_DELETE_FAILED'),
        'columns': [
          {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
          {'data': 'descripcion', 'title': $filter('translate')('NOMBRE')},
          {'data': 'abreviatura', 'title': $filter('translate')('ABBREVIATION')},
        ],
        'hasOptions' : true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraMenuOptions':
          [
            {
              'title': $filter('translate')('REPORT'),
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoAduanas', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("aduanas", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ]
      };
    });


      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({path: data, like: search})
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
  }]);

'use strict';

angular.module('qualita.importacion')
  .controller('AduanasFormController', ['$rootScope', '$scope', '$state', 'AduanasFactory', 'ImportacionLangFactory', 'formFactory', '$location',
    'aduanaPrepService', 'notify', '$filter',
    function($rootScope, $scope, $state, AduanasFactory, ImportacionLangFactory, formFactory, $location, aduanaPrepService, notify,
      $filter) {

      activate();

      function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations) {
          $scope.translations = translations;
          $scope.schema = AduanasFactory.schema();
          $scope.options = formFactory.defaultOptions();

          if ($state.is("app.aduanas.new")) {
            activateNew();
          } else if ($state.is("app.aduanas.edit")) {
            activateEdit();
          } else if ($state.is("app.aduanas.view")) {
            activateView();
          }

          $rootScope.isProcessing = false;
        });
      }

      function activateNew() {
        $scope.title = $filter('translate')('NUEVA_ADUANA');
        $scope.form = AduanasFactory.form('new');
        $scope.model = {};
        $scope.schema.readonly = false;
      }

      function activateEdit() {
        $scope.form = AduanasFactory.form('edit');
        $scope.title = $filter('translate')('EDIT_ADUANA');
        $scope.model = aduanaPrepService;
        $scope.entidad = "Aduana";
        $scope.entidadId = $scope.model.id;
        $scope.schema.readonly = false;
      }

      function activateView() {
        $scope.form = AduanasFactory.form('view');
        $scope.title = $filter('translate')('VIEW_ADUANA');
        $scope.options = formFactory.defaultViewOptions();
        $scope.model = aduanaPrepService;
        $scope.entidad = "Aduana";
        $scope.entidadId = $scope.model.id;
        $scope.view = true;
        $scope.schema.readonly = true;
      }


      $scope.submit = function(form) {
        formFactory.defaultSubmit('aduanas', $scope, form, AduanasFactory, undefined, errorHandler);
      };

      function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
          if (error.constraint == 'abreviatura') {
            msg += '\n\n' + "Ya existe una aduana con la misma abreviatura.\n";
          } else if (error.constraint == 'descripcion') {
            msg += '\n\n' + "Ya existe una aduana con el mismo nombre.\n";
          } else {
            msg += '\n\n' + error.message + '.';
          }
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      }

      $scope.cancel = function() {
        $location.path('/aduanas');
      };

    }
  ]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.cotizacionmonedas
 * @description
 * # cotizacionmonedas
 * Factory in the qualita.
 */
angular.module('qualita.importacion')
    .factory('CotizacionMonedaFactory', ['$resource', 'baseurl', 'ParametrosFactory', 'filterFactory', '$http',
        function ($resource, baseurl, ParametrosFactory, filterFactory, $http) {

            var CotizacionMoneda = $resource(baseurl.getBaseUrl() + '/cotizacionmonedas/:id', { id: '@id' }, {
                update: {
                    method: 'PUT'
                }
            });

            return {
                all: function (params, view) {
                    if (params) {
                        params.view = view != undefined ? view : 'BaseList';
                    }
                    return CotizacionMoneda.query(params);
                },

                get: function (id, view) {
                    return CotizacionMoneda.get({ id: id, view: view ? view : "base" });
                },

                create: function (attrs) {
                    return new CotizacionMoneda(attrs);
                },

                save: function (cotizacionMoneda) {
                    return (cotizacionMoneda.id) ? cotizacionMoneda.$update() : cotizacionMoneda.$save();
                },

                remove: function (cotizacionMoneda) {
                    return cotizacionMoneda.$remove();
                },

                getMonedaLocal: function () {
                    var params = {};
                    params.search = filterFactory.single({
                        path: 'codigo',
                        equals: 'moneda_local'
                    }).value();
                    return ParametrosFactory.all(params).$promise.then(function (data) {
                        return data[0];
                    });
                },

                getCotizacionDia: function (monedaDesdeId, monedaHastaId, fecha) {
                    var params = {};
                    params.search = filterFactory.and([{
                        path: 'monedaDesde.id',
                        equals: monedaDesdeId
                    },
                    {
                        path: 'monedaHasta.id',
                        equals: monedaHastaId
                    },
                    {
                        path: 'fecha',
                        like: moment(fecha).format('DD/MM/YYYY')
                    }
                    ]).value();
                    return CotizacionMoneda.query(params).$promise.then(function (response) {
                        if (response[0]) {
                            console.log("RESPONSE: ");
                            console.log(response[0].valorVenta);
                            return response[0].valorVenta;
                        }
                    });
                },

                getCotizacionBefore: function (monedaDesdeId, monedaHastaId, fecha) {
                    var params = {};
                    params.search = filterFactory.and([{
                        path: 'monedaDesde.id',
                        equals: monedaDesdeId
                    },
                    {
                        path: 'monedaHasta.id',
                        equals: monedaHastaId
                    },
                    {
                        path: 'fecha',
                        equalOrBefore: moment(fecha).format('DD/MM/YYYY'),
                        sortDesc: true
                    }
                    ]).value();
                    return CotizacionMoneda.query(params).$promise.then(function (response) {
                        if (response[0]) {
                            console.log("RESPONSE: ");
                            console.log(response[0].valorVenta);
                            return response[0].valorVenta;
                        }
                    });
                },

                getCotizacionDiaOr: function (monedaDesdeId, monedaHastaId, fecha) {
                    var params = {};

                    var and1, and2 = {};
                    var ands = [];
                    and1 = filterFactory.and([{
                        path: 'monedaDesde.id',
                        equals: monedaDesdeId
                    },
                    {
                        path: 'monedaHasta.id',
                        equals: monedaHastaId
                    },
                    {
                        path: 'fecha',
                        like: moment(fecha).format('DD/MM/YYYY')
                    }
                    ]).value();
                    ands.push(and1);
                    and2 = filterFactory.and([{
                        path: 'monedaDesde.id',
                        equals: monedaHastaId
                    },
                    {
                        path: 'monedaHasta.id',
                        equals: monedaDesdeId
                    },
                    {
                        path: 'fecha',
                        like: moment(fecha).format('DD/MM/YYYY')
                    }
                    ]).value();
                    ands.push(and2);

                    params.search = filterFactory.or(ands).value();
                    params.view = "BaseList";
                    return CotizacionMoneda.query(params).$promise.then(function (response) {
                        if (response[0]) {

                            /* Si la moneda de la orden de pago es en guaranies recuperar el valor de venta de la cotización.
                               Si la moneda de la orden de pago es en moneda extrangera utilizar el valor compra.
                               el id 57 corresponde a moneda guaranies, este id es invariable.
                            */
                            if (monedaDesdeId == 57) {
                                return response[0].valorVenta;

                            } else {
                                return response[0].valorCompra;
                            }
                        }
                    });
                },

                reenviar: function(idCotizacion) {
                    return $http({
                      url: baseurl.getBaseUrl() + '/cotizacionmonedas/reenviar/' + idCotizacion,
                      method: "PUT"
                    });
                  }
            };
        }
    ]);

'use strict';


angular.module('qualita.importacion')
    .controller('CotizacionMonedaIndexCtrl', ["$scope", "CotizacionMonedaFactory", "filterFactory", "ReportTicketFactory", "$window", "ImportacionLangFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter", "ParametrosFactory", "$modal", "notify", "$state", function($scope, CotizacionMonedaFactory, filterFactory,
        ReportTicketFactory, $window, ImportacionLangFactory, CsvFactory, UtilFactory, baseurl, $filter,
        ParametrosFactory, $modal, notify, $state) {

        var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
        var enviarAContabilidad = '';

        promise1.then(function(response) {
        enviarAContabilidad = response.valorTipo.codigo;
        });

        //TIP Importante: Al sacar un elemento de defaultColumnOrder el mismo desaparece del listado inicial, pero queda
        //disponible para hacer visible.
        var defaultColumnOrder = ['id', 'monedaDesde.descripcion', 'monedaHasta.descripcion', 'valorCompra', 'valorVenta', 'fecha'];
        ImportacionLangFactory.getTranslations().then(function(translations) {
            console.log(translations);
            $scope.options = {
                'resource': 'cotizacionmonedas',
                'title': $filter('translate')('COTIZACION_MONEDAS'),
                'factory': CotizacionMonedaFactory,
                'view': 'CotizacionMonedaList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': 'No se pudo borrar la cotización. Verifique si hay operaciones asociadas.',
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false  },
                    { 'data': 'monedaDesde.descripcion', 'title': 'Moneda Desde', 'type': 'combo', 'filterUrl': 'tipos/filtro/monedas' },
                    { 'data': 'monedaHasta.descripcion', 'title': 'Moneda Hasta', 'type': 'combo', 'filterUrl': 'tipos/filtro/monedas' },
                    { 'data': 'valorCompra', 'title': 'Valor Compra', 'class': 'dt-right', 'render': renderMonto  },
                    { 'data': 'valorVenta', 'title': 'Valor Venta', 'class': 'dt-right', 'render': renderMonto  },
                    { 'data': 'fecha', 'title': $filter('translate')('DATE'), renderWith: "dateRender", dateFormat: 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraRowOptions':[{
                    templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
                    functionName: 'reenviar',
                    customAttribute: ['enviadoAContabilidad', 'id'],
                    functionDef: function (atributo) {
      
                        var idCotizacion = atributo[1];
      
                        $scope.tituloModal = $filter('translate')('REENVIAR_COTIZACION');
                        $scope.mensajeModal = $filter('translate')('REENVIAR_COTIZACION_MSG');
                        var modalInstance = $modal.open({
                          templateUrl: 'views/directives/confirmation-modal.html',
                          scope: $scope
                        });
      
                        $scope.cancel = function () {
                          modalInstance.dismiss('cancel');
                        };
      
                        $scope.ok = function () {
      
                          ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
      
                            if (parametro.valorTipo.codigo == 'si') {
                              CotizacionMonedaFactory.reenviar(idCotizacion).then(function (responseReenviar) {
      
                                if (responseReenviar.data.enviadoAContabilidad == false) {
                                  notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                }
                                $state.go("app.cotizacionmonedas.list", { id: responseReenviar.id }, { reload: true });
                              }, function (error) {
                                var msg = "";
                                if (error.data && error.data.length > 0 && error.data[0].message) {
                                  msg = error.data[0].message;
                                } else {
                                  msg = $filter('translate')('REENVIAR_COTIZACION_FAILED');
                                }
      
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                              });
                              
                            } else {
                              var msg = "";
                              msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                              notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                          });
                          modalInstance.close();
                        }
                    },
                    conditionName: 'canReenviar',
                    conditionDef: function (atributo) {
                      var enviadoAContabilidad = atributo[0];
                      if (enviarAContabilidad == 'si') {
                        if (enviadoAContabilidad === "No") {
                          return true;
                        }
                      }
                    }
                
                }],
                'extraMenuOptions': [{
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = createFilters($scope.options.getFilters());
                            console.log("filters", filters.filters);
                            filters.filters[5].sortDesc = "true";
                            console.log("filters", filters);
                            ReportTicketFactory.ticket('listadoCotizacionMonedas', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open($scope.pdfDownloadURL, '_blank');
                            });
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("cotizacionmonedas", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                .then(function(response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    }
                ]
            };
        });

        
        var createFilters = function (filters) {
            var filtersArr = [];
            _.each(filters, function (search, data) {
                search = search.split("~");
                if (search.length > 1) {
                    /* El parámetro es un rago */

                    var isDate = search[0].split("/");

                    if (isDate.length > 1) {
                        /* Es un rago de fechas */

                        filtersArr.push({path: data, equalOrAfter: search[0]})
                        filtersArr.push({path: data, equalOrBefore: search[1]})
                    } else {
                        /* El un rago numérco */

                        filtersArr.push({path: data, greaterOrEqual: search[0]})
                        filtersArr.push({path: data, lessOrEqual: search[1]})
                    }
                } else {
                    /* El parametro no es un rago */
                   filtersArr.push({path: data, like: search[0]})
                }
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        }

        var renderMonto = function(data) {
            if (data == undefined)
                return "";
            else
                var dataAux = parseFloat(data).toFixed(2);
            return dataAux.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    }]);
'use strict';

angular.module('qualita.importacion')
  .controller('CotizacionMonedaFormController', ['$rootScope', '$scope', '$stateParams', 'CotizacionMonedaFactory', 'formFactory', '$location',
    'cotizacionMonedaPrepService', '$state', 'ImportacionLangFactory','notify', 'TiposFactory', '$filter',
    function($rootScope, $scope, $stateParams, CotizacionMonedaFactory, formFactory,
      $location, cotizacionMonedaPrepService, $state, ImportacionLangFactory,notify, TiposFactory, $filter) {
      activate();

      function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations) {
          $scope.translations = translations;
          TiposFactory.monedas().then(function (data) {
            $scope.monedas = data.data;

            if ($state.is("app.cotizacionmonedas.new")){
                _.forEach($scope.monedas,function(moneda){
                  if(moneda.codigo == 'guaranies'){
                    $scope.model.monedaHasta = moneda;
                  }
              });
            }

          });

          if ($state.is("app.cotizacionmonedas.new")) {
            activateNew();
          } else if ($state.is("app.cotizacionmonedas.edit")) {
            activateEdit();
          } else if ($state.is("app.cotizacionmonedas.view")) {
            activateView();
          }

          $rootScope.isProcessing = false;
        });
      }

      function activateNew() {
        $scope.title = $filter('translate')('NEW_COTIZACION_MONEDAS');
        $scope.model = {};
      }

      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_COTIZACION_MONEDAS');
        $scope.model = cotizacionMonedaPrepService;
        $scope.model.fecha = new Date($scope.model.fecha);
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "CotizacionMoneda";
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_COTIZACION_MONEDAS');
        $scope.model = cotizacionMonedaPrepService;
        $scope.model.fecha = new Date($scope.model.fecha);
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "CotizacionMoneda";
        $scope.view = true;
      }

      $scope.submit = function(form) {
        $scope.submited=true;
        if(($scope.model.valorCompra>0) && ($scope.model.valorVenta>0)){
          if($scope.model.valorCompra<=$scope.model.valorVenta){
            formFactory.defaultNSFSubmit($scope.cotizacionMonedaForm, CotizacionMonedaFactory, $scope.model, errorHandler).then(function(response) {
              $state.go('^.list');
              if(response.enviadoAContabilidad == false){
                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
              }
            }, function(error) {
              return errorHandler(error);
            });
          }else{
            notify({ message: $filter('translate')('COMPRA_MAYOR_VENTA_ERROR'), classes: 'alert-danger', position: 'right'});
          }
        }
        else{
          notify({ message: $filter('translate')('ERROR_COMPRA_VENTA_NEGATIVO'), classes: 'alert-danger', position: 'right'});
        }
      };

      function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
          /*if (error.constraint == 'nombre') {
            msg += '\n\n' + $filter('translate')('UNIDAD_NEGOCIO_NAME_WARNING') + "\n";
          } else {
            msg += '\n\n' + error.message + '.';
          }*/
          console.log(error.message);
          msg=error.message;
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      }

      $scope.cancel = function() {
        $location.path('/cotizacionmonedas');
      };

    }
  ]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.tipoComprobante
 * @description
 * # tipoComprobante
 * Factory in the qualita.
 */
angular.module('qualita.importacion')
  .factory('TipoComprobanteFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', 'ImportacionFactory','$http',
    function ($resource, baseurl, filterFactory, TiposFactory, ImportacionFactory,$http) {

      var TipoComprobante = $resource(baseurl.getBaseUrl() + '/tipocomprobantes/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined ? view : 'BaseList';
          }
          return TipoComprobante.query(params);
        },

        get: function (id, view) {
          return TipoComprobante.get({ id: id, view: view ? view : "base" });
        },

        create: function (attrs) {
          return new TipoComprobante(attrs);
        },

        save: function (tipoComprobante) {
          return (tipoComprobante.id) ? tipoComprobante.$update() : tipoComprobante.$save();
        },

        remove: function (tipoComprobante) {
          return tipoComprobante.$remove();
        },

        schema: function () {
          return schema;
        },

        form: function (type) {
          return forms[type];
        },
        getTiposComprobantes: function (ventaid) {
          return $http({
            url: baseurl.getBaseUrl() + '/tipocomprobantes/all/' + ventaid,
            method: "GET"
          });
        },
      };
    }]);

'use strict';


angular.module('qualita.importacion')
  .controller('TipoComprobantesIndexCtrl', ["$scope", "TipoComprobanteFactory", "ImportacionLangFactory", "filterFactory", "ReportTicketFactory", "$window", "CsvFactory", "UtilFactory", "baseurl", "$filter", function ($scope, TipoComprobanteFactory, ImportacionLangFactory,
  filterFactory, ReportTicketFactory, $window, CsvFactory, UtilFactory, baseurl, $filter) {

    var defaultColumnOrder = ['id','codigo','descripcion'/*,'accion.descripcion'*/,'clase.descripcion',
     'generarCuotas', 'aplicable', '$filter', 'informeImpositivo', 'activo'];


    ImportacionLangFactory.getTranslations().then(function(translations) {
      $scope.options = {
        'resource': 'tipocomprobantes',
        'title': $filter('translate')('TIPOS_COMPROBANTES'),
        'factory': TipoComprobanteFactory,
        'view': 'TipoComprobanteList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('TIPOS_COMPROBANTES_DELETE_FAILED'),
        'columns': [
          {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
          {'data': 'descripcion', 'title': $filter('translate')('NOMBRE')},
          /*{'data': 'accion.descripcion', 'title': $filter('translate')('ACTION'), 'type': 'combo', 'filterUrl': 'tipos/tipo/fnd_accion_comprobante', 'class' : 'dt-center'},*/
          {'data': 'clase.descripcion', 'title': $filter('translate')('KIND'), 'type': 'combo', 'filterUrl': 'tipos/filtro/fnd_clase_comprobante'},
          {'data': 'generarCuotas', 'title': $filter('translate')('GENERAR_CUOTAS'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
          {'data': 'aplicable', 'title': $filter('translate')('ES_APLICABLE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
          {'data': 'utilizarEnProcesosVenta', 'title': $filter('translate')('UTILIZAR_PROCESOS_VENTA'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
          {'data': 'utilizarEnProcesosCompra', 'title': $filter('translate')('UTILIZAR_PROCESOS_COMPRA'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
          {'data': 'condicion.descripcion', 'title': $filter('translate')('CONDICION'), 'class': 'dt-center', 'visible': false, 'renderWith': 'emptyRender'},
          {'data': 'informeImpositivo', 'title': $filter('translate')('INFORME_IMPOSITIVO'), 'class': 'dt-center', 'visible': false, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
          {'data': 'activo', 'title': $filter('translate')('ACTIVO'), 'class': 'dt-center', 'visible': true, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'}
        ],
        'hasOptions' : true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraMenuOptions':
          [
            {
              'title': $filter('translate')('REPORT'),
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoTiposComprobante', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("tipocomprobantes", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ]
      };
    });


      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          if(search === 'No' && (data === 'generarCuotas' || data === 'aplicable' || data === 'utilizarEnProcesosVenta' || data === 'utilizarEnProcesosCompra')){
            search = 'false';
        }
        if(search === 'Sí' && (data === 'generarCuotas' || data === 'aplicable' || data === 'utilizarEnProcesosVenta' || data === 'utilizarEnProcesosCompra')){
            search = 'true';
        }
          filtersArr.push({path: data, like: search})
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
  }]);

'use strict';

angular.module('qualita.importacion')
    .controller('TipoComprobantesFormController', ['$rootScope', '$scope', 'TipoComprobanteFactory', 'ImportacionLangFactory', 'TiposFactory', 'formFactory', '$location',
        'tipoComprobantePrepService', '$state', 'notify', '$filter', 'baseurl', 'ParametrosFactory',

        function ($rootScope, $scope, TipoComprobanteFactory, ImportacionLangFactory, TiposFactory, formFactory,
            $location, tipoComprobantePrepService, $state, notify, $filter, baseurl, ParametrosFactory) {

            activate();
            $scope.submit = submit;
            $scope.cancel = cancel;
            $scope.esAplicableChanged = esAplicableChanged;
            $scope.filtrarClase = filtrarClase;
            $scope.isGenerarCuotas = isGenerarCuotas;
            $scope.disableCampoCodigo = true;
            //$scope.tildarBloquear = tildarBloquear;
            $scope.utilizarProcesoVentaChanged = utilizarProcesoVentaChanged;

            var vm = this;

            function activate() {
                ImportacionLangFactory.getTranslations().then(function (translations) {
                    $scope.translations = translations;
                    $rootScope.isProcessing = false;
                    //$scope.schema = TipoComprobanteFactory.schema();
                    $scope.options = formFactory.defaultOptions();

                    //obtener parametro de control de limite de credito
                    ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response) {
                        $scope.disabledLimiteCredito = true;
                        if (response.valorTipo.codigo === "si") {
                            $scope.disabledLimiteCredito = false;
                        }
                    });


                    var filter = {}
                    filter.view = "TipoComprobanteForm";
                    $scope.tiposComprobante = TipoComprobanteFactory.all(filter);

                    TiposFactory.clasesTipoComprobante().then(function (data) {
                        $scope.clases = data.data;
                    });

                    if ($state.is("app.tipocomprobantes.new")) {
                        activateNew();
                    } else if ($state.is("app.tipocomprobantes.edit")) {
                        activateEdit();
                    } else if ($state.is("app.tipocomprobantes.view")) {
                        console.log('activateView');
                        activateView();
                    }

                });
            }

            function adjuntarArchivos(tipoComprobante) {
                console.log('AQUI: ', flowImage);
                // se agregan los adjuntos de imagenes
                var flowImage = vm.uploadOptions1.flow;
                if (flowImage) {
                    var flowFilesImage = flowImage.files;
                    //console.log('AQUI1: ', flowImage);
                    if (flowFilesImage.length > 0) {
                        angular.forEach(flowFilesImage, function (file) {
                            tipoComprobante.imagen = file.uniqueIdentifier + '-' + file.name;
                            //console.log("tipoComprobante.imagen: %o", tipoComprobante.imagen);
                        });
                    }
                }
                // se agregan los adjuntos de imagenes

            }

            function clearAdjuntos() {
                var flow = $rootScope.flow;
                if (!flow) {
                    return;
                }
                flow.files = [];
            };

            function activateNew() {
                $scope.isFactura = false;
                $scope.isNotaCredito = false;
                $scope.disableCampoCodigo = false;
                $scope.title = $filter('translate')('NUEVO_TIPO_COMPROBANTE');
                //$scope.form = TipoComprobanteFactory.form('new');
                $scope.tipoComprobante = {factAgilDefault :false};
                //$scope.schema.readonly = false;
                TiposFactory.condicionTipoComprobante().then(function (data) {
                    $scope.condiciones = data.data;
                });


                vm.uploadOptions1 = {
                    imageOnly: true,
                    title: 'Foto'
                };
            }

            function activateEdit() {
                $scope.disableCampoCodigo = true;
                $scope.title = $filter('translate')('EDIT_TIPO_COMPROBANTE');
                //$scope.form = TipoComprobanteFactory.form('edit');
                $scope.tipoComprobante = tipoComprobantePrepService;
                $scope.entidadId = $scope.tipoComprobante.id;
                $scope.entidad = "TipoComprobante";
                TiposFactory.condicionTipoComprobante().then(function (data) {
                    $scope.condiciones = data.data;
                });
                //$scope.schema.readonly = false;
                $scope.tiposComprobante.$promise.then(function (data) {
                    $scope.tiposComprobante = _.filter(data, function (value) {
                        if (value.id != $scope.tipoComprobante.id)
                            return value;
                    })
                });
                $scope.isFactura = false;
                if ($scope.tipoComprobante.clase.codigo === "factura") {
                    $scope.isFactura = true;
                }
                $scope.isNotaCredito = false;
                if ($scope.tipoComprobante.clase.codigo === "nota_credito") {
                    $scope.isNotaCredito = true;
                }
                if (!$scope.isNotaCredito) {
                    $scope.tildarBloquear = true;
                }
                if ($scope.tipoComprobante.clase.codigo === "retencion_absorbida") {
                    $scope.tipoComprobante.estadoCuenta = false;
                    $scope.disabledEstadoCuenta = true;
                }

                //obtener parametro de control de limite de credito
                ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response) {
                    if (response.valorTipo.codigo === "no") {
                        $scope.mostrar = false;
                    } else if (response.valorTipo.codigo === "si" && $scope.tipoComprobante.condicion && $scope.tipoComprobante.condicion.codigo === "tipo_comprobante_credito") {
                        $scope.mostrar = true;
                    } else if (response.valorTipo.codigo === "si" && $scope.tipoComprobante.condicion && $scope.tipoComprobante.condicion.codigo === "tipo_comprobante_contado") {
                        $scope.mostrar = false;
                    } else if (response.valorTipo.codigo === "si" && $scope.tipoComprobante.clase.codigo === "remision") {
                        $scope.mostrar = true;
                    }
                });
                //isGenerarCuotas();

                var imagePath = "images/placeholder.png";
                if ($scope.tipoComprobante.imagen && $scope.tipoComprobante.imagen !== false)
                    imagePath = baseurl.getPublicBaseUrl() + $scope.tipoComprobante.imagen

                vm.uploadOptions1 = {
                    imageOnly: true,
                    title: 'Foto',
                    imagePath: imagePath
                };
            }

            function activateView() {
                $scope.title = $filter('translate')('VIEW_TIPO_COMPRBOANTE');
                //$scope.form = TipoComprobanteFactory.form('view');
                $scope.options = formFactory.defaultViewOptions();
                $scope.tipoComprobante = tipoComprobantePrepService;
                $scope.entidadId = $scope.tipoComprobante.id;
                $scope.entidad = "TipoComprobante";
                $scope.view = true;
                $scope.disableCampoCodigo = true;
                //$scope.schema.readonly = true;


                var imagePath = "images/placeholder.png";
                if ($scope.tipoComprobante.imagen && $scope.tipoComprobante.imagen !== false)
                    imagePath = baseurl.getPublicBaseUrl() + $scope.tipoComprobante.imagen

                vm.uploadOptions1 = {
                    imageOnly: true,
                    title: 'Foto',
                    imagePath: imagePath
                };

                //obtener parametro de control de limite de credito
                ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response) {
                    if (response.valorTipo.codigo === "no") {
                        $scope.mostrar = false;
                    } else if (response.valorTipo.codigo === "si" && $scope.tipoComprobante.condicion && $scope.tipoComprobante.condicion.codigo === "tipo_comprobante_credito") {
                        $scope.mostrar = true;
                    } else if (response.valorTipo.codigo === "si" && $scope.tipoComprobante.condicion && $scope.tipoComprobante.condicion.codigo === "tipo_comprobante_contado") {
                        $scope.mostrar = false;
                        $scope.tipoComprobante.controlLimiteCredito = false;
                    } else if (response.valorTipo.codigo === "si" && $scope.tipoComprobante.clase.codigo === "remision") {
                        $scope.mostrar = true;
                    }
                });
            }

            function esAplicableChanged() {
                if (!$scope.tipoComprobante.aplicable) {
                    $scope.tipoComprobante.tiposComprobante = [];
                }
            }

            function utilizarProcesoVentaChanged() {
                if($scope.tipoComprobante.utilizarEnProcesosVenta == true){
                    $scope.tipoComprobante.utilizarVentasDefecto = true;
                }else if($scope.tipoComprobante.utilizarEnProcesosVenta == false){
                    $scope.tipoComprobante.utilizarVentasDefecto = false;
                }
            }

            function filtrarClase() {
                $scope.isFactura = false;
                $scope.disabledEstadoCuenta = false;
                if ($scope.tipoComprobante.clase.codigo === "factura") {
                    $scope.isFactura = true;
                } else {
                    $scope.tipoComprobante.condicion = null;
                }

                $scope.isNotaCredito = false;
                if ($scope.tipoComprobante.clase.codigo === "nota_credito") {
                    $scope.isNotaCredito = true;
                }
                if ($scope.tipoComprobante.clase.codigo === "retencion_absorbida") {
                    $scope.tipoComprobante.estadoCuenta = false;
                    $scope.disabledEstadoCuenta = true;
                }
                isGenerarCuotas();
            }

            function isGenerarCuotas() {
                $scope.tipoComprobante.generarPagare = false;
                $scope.tipoComprobante.plantillaPagare = "";
                if ($scope.tipoComprobante.clase.codigo === "nota_credito") {
                    $scope.tildarBloquear = false;
                    $scope.tipoComprobante.generarCuotas = false;
                } else if ($scope.tipoComprobante.condicion != null && $scope.tipoComprobante.condicion.codigo === "tipo_comprobante_credito") {
                    $scope.tildarBloquear = false;
                    $scope.tipoComprobante.generarCuotas = true;
                } else {
                    $scope.tildarBloquear = true;
                    $scope.tipoComprobante.generarCuotas = false;
                }

                //obtener parametro de control de limite de credito
                ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response) {
                    if (response.valorTipo.codigo === "no") {
                        $scope.mostrar = false;
                    } else if (response.valorTipo.codigo === "si" && $scope.tipoComprobante.condicion && $scope.tipoComprobante.condicion.codigo === "tipo_comprobante_credito") {
                        $scope.mostrar = true;
                    } else if (response.valorTipo.codigo === "si" && $scope.tipoComprobante.condicion && $scope.tipoComprobante.condicion.codigo === "tipo_comprobante_contado") {
                        $scope.mostrar = false;
                        $scope.tipoComprobante.controlLimiteCredito = false;
                    } else if (response.valorTipo.codigo === "si" && $scope.tipoComprobante.clase.codigo === "remision") {
                        $scope.mostrar = true;
                    }
                });
            }

            function submit(form) {
                console.log("submit");
                $scope.submited = true;
                if ($scope.TipoComprobanteForm.$valid) {
                    if (!$scope.tipoComprobante.utilizarEnProcesosVenta && !$scope.tipoComprobante.utilizarEnProcesosCompra) {
                        var msg = $filter('translate')('TIPO_COMPROBANTE_NO_DEFINED_ERROR');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        return;
                    }

                    // se adjuntan archivos
                    adjuntarArchivos($scope.tipoComprobante);

                    formFactory.defaultNSFSubmit($scope.TipoComprobanteForm, TipoComprobanteFactory, $scope.tipoComprobante, errorHandler).then(function (response) {
                        clearAdjuntos();
                        $location.path('/tipocomprobantes');
                    }, function (error) {
                        console.log(error);
                    });
                } else {
                    //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
                }
            }
            ;

            function errorHandler(e) {
                var msg = '';
                _.forEach(e.data, function (error) {
                    if (error.constraint == 'descripcion') {
                        msg += '\n\n' + "Ya existe un tipo de comprobante con el mismo nombre.\n";
                    } else {
                        msg += '\n\n' + error.message + '.';
                    }
                });
                notify({
                    message: msg,
                    classes: 'alert-danger',
                    position: 'right'
                });
            }

            function cancel() {
                $location.path('/tipocomprobantes');
            }
            ;

            $scope.changeGenerarPagare = function () {
              $scope.tipoComprobante.plantillaPagare = "";
            }

        }
    ]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.importacion')
    .factory('ImportacionFactory', ImportacionFactory);

ImportacionFactory.$inject = ['$resource', 'baseurl', '$http', 'ParametrosFactory', '$translatePartialLoader', '$translate'];

function ImportacionFactory($resource, baseurl, $http, ParametrosFactory, $translatePartialLoader, $translate) {

    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove,
        getIdImportacion: getIdImportacion,
        setIdImportacion: setIdImportacion,
        getCodigoImportacion: getCodigoImportacion,
        setCodigoImportacion: setCodigoImportacion,
        getImportacion: getImportacion,
        setImportacion: setImportacion,

        getEnabledStates: getEnabledStates,
        setEnabledStates: setEnabledStates,
        checkPermissionForState: checkPermissionForState,
        cerrar: cerrar,
        aprobarRecepcion: aprobarRecepcion,
        anularRecepcion: anularRecepcion,
        getCoeficiente: getCoeficiente,
        getUltimosMovimientos: getUltimosMovimientos,
        getUltimosHistoricos: getUltimosHistoricos,
        reenviar: reenviar
    };

    var Importaciones = $resource(baseurl.getBaseUrl() + "/importaciones/:id", { id: '@id' }, {
        update: {
            method: 'PUT'
        },
        // cerrar: {
        //   method: 'PUT',
        //   url: baseurl.getBaseUrl() + "/importaciones/cerrar/:id?cerrarOC=:cerrarOC"
        // },
        getCoeficiente: {
            method: 'GET',
            url: baseurl.getBaseUrl() + "/importaciones/coeficiente/:id"
        }
    });

    var idImportacion = 1;
    var codigoImportacion;
    var importacion;
    var enabledStates;
    var flujoAbierto;
    var requiereSolicitudPago;

    ParametrosFactory.getByCodigo('confirmacion_recepcion_importacion').then(function (response) {
      flujoAbierto = response.valorTipo.codigo === "si";
    });

    ParametrosFactory.getByCodigo('imc_solicitud_pago').then(function (response) {
        requiereSolicitudPago = response.valorTipo.codigo === "si";
      });
    return service;

    function all(params) {
        return Importaciones.query(params);
    }

    function get(id, view) {
        return Importaciones.get({ id: id, view: view});
    }

    function create(attrs) {
        return new Importaciones(attrs);
    }

    function cerrar(id, cerrarOCParam, tieneDiferenciaParam) {
        // return Importaciones.cerrar({id: id, cerrarOC: cerrarOCParam});
        return $http({
            url: baseurl.getBaseUrl() + '/importaciones/cerrar/' + id,
            params: { cerrarOC: cerrarOCParam, tieneDiferencia: tieneDiferenciaParam},
            method: "PUT"
        });
    }

    function save(importacion) {
        return (importacion.id) ? importacion.$update() : importacion.$save();
    }

    function remove(importacion) {
        return importacion.$remove();
    }

    function aprobarRecepcion(id) {
      // return Importaciones.cerrar({id: id, cerrarOC: cerrarOCParam});
      return $http({
        url: baseurl.getBaseUrl() + '/importaciones/aprobarRecepcion/' + id,
        method: "PUT"
      });
    }
    function anularRecepcion(id) {
      // return Importaciones.cerrar({id: id, cerrarOC: cerrarOCParam});
      return $http({
        url: baseurl.getBaseUrl() + '/importaciones/anularRecepcion/' + id,
        method: "PUT"
      });
    }

    function getIdImportacion() {
        return idImportacion;
    }

    function setIdImportacion(id) {
        idImportacion = id;
    }

    function getCodigoImportacion() {
        return codigoImportacion;
    }

    function setCodigoImportacion(codigo) {
        codigoImportacion = codigo;
    }

    function getImportacion() {
        updateStates();
        return importacion;
    }

    function getEnabledStates() {
        return enabledStates;
    }

    function setEnabledStates(states) {
        enabledStates = states;
    }

    function setImportacion(imp) {
        if (imp.id) {
            importacion = imp;
        } else {
            importacion = get(imp).$promise;
        }
        updateStates();
    }

    function checkPermissionForState(estadoIndex) {
        // console.log("estadoIndex",estadoIndex);
        // console.log("enabledStates[estadoIndex]",enabledStates[estadoIndex]);
        return enabledStates[estadoIndex];
    }

    function getCoeficiente(id) {
        return Importaciones.getCoeficiente({ id: id });
    }

    function updateStates() {
        if (importacion.estado && importacion.estado.codigo) {
            switch (importacion.estado.codigo) {
                case "abierta":
                    enabledStates = [true, false, false, false, false, false, false, false];
                    break;
                case "con_orden_compra":
                    enabledStates = [true, true, false, false, false, false, false, false];
                    break;
                case "con_proforma":
                    if(requiereSolicitudPago){
                        enabledStates = [true, true, true, false, false, false, false, false];
                    }else{
                        enabledStates = [true, true, false, true, false, false, false, false];
                    }
                    break;
                case "con_solicitud":
                    enabledStates = [true, true, true, true, false, false, false, false];
                    break;
                case "con_embarque":
                    enabledStates = [true, true, true, true, true, flujoAbierto, false, false];
                    break;
                case "con_comprobante":
                case "con_recepcion":
                    enabledStates = [true, true, true, true, true, true, true, false];
                    break;
                default:
                    enabledStates = [true, true, true, true, true, true, true, true];
                    break;
            }
        }
    }

    function getUltimosMovimientos(id) {
        $http({
            method: "GET",
            url: baseurl.getBaseUrl() + "/movimientostock/ultimosmovimientos/" + id
        }).then(function mySucces(response) {
            return response.data;
        });
    }


    function getUltimosHistoricos(id) {
        $http({
            method: "GET",
            url: baseurl.getBaseUrl() + "/historicocosto/ultimoshistoricos/" + id
        }).then(function mySucces(response) {
            return response.data;
        });
    }

    function reenviar(importacion) {
        return $http({
            url: baseurl.getBaseUrl() + '/importaciones/reenviar/' + importacion.id,
            method: "PUT"
        });
    }
}

'use strict';


angular.module('qualita.importacion')
    .controller('ImportacionIndexCtrl', ImportacionIndexCtrl);

ImportacionIndexCtrl.$inject = ["AuthorizationService", "ImportacionFactory", "TiposFactory", "PeriodoAdministrativoFactory",
    "OrdenesCompraFactory", "filterFactory", "$log", "$modal", "$scope", "notify", "$state", "ImportacionLangFactory",
    "ParametrosFactory", "WatchLocacionChangeFactory", "UtilFactory", "$filter", "$window", "CsvFactory", "baseurl", "$q"
];

function ImportacionIndexCtrl(AuthorizationService, ImportacionFactory, TiposFactory, PeriodoAdministrativoFactory,
    OrdenesCompraFactory, filterFactory, $log, $modal, $scope, notify, $state, ImportacionLangFactory, ParametrosFactory,
    WatchLocacionChangeFactory, UtilFactory, $filter, $window, CsvFactory, baseurl, $q) {
    var vm = this;

    vm.addNewImportacion = addNewImportacion;
    vm.autoGenerateCode = ParametrosFactory.getByCodigo("generacion_codigo_importacion");

    var defaultColumnOrder = ['codigo', 'estado.descripcion', 'proveedor.nombre', 'id', 'fechaApertura', 'fechaCierre', 'diferencia',
    'ordenesCompra[0].numero', 'despacho[0].numero', 'despacho[0].fecha'];


    obtenerEnviarAContabilidad().then(function (enviarAContabilidad) {
        ImportacionLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;
            vm.options = {
                'resource': 'importaciones',
                'view': 'ImportacionList',
                'title': $filter('translate')('IMPORTACIONES'),
                'factory': ImportacionFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('ERROR_IMPORTACION_DELETE'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('ID_IMPORTACION'), visible:false },
                    { 'data': 'codigo', 'title': $filter('translate')('CODE') },
                    { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'),'type' : 'combo', 'filterUrl': 'tipos/filtro/importacion_estados'},
                    { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_SINGLE') },
                    { 'data': 'ordenesCompra[0].numero', 'title': $filter('translate')('ORDEN_COMPRA')},
                    { 'data': 'fechaApertura', 'title': $filter('translate')('DATE_APERTURA'), visible:true, renderWith: "dateRender", dateFormat: "DD/MM/YYYY", type: 'date-range'},
                    { 'data': 'fechaCierre', 'title': $filter('translate')('DATE_CIERRE'), visible:true, renderWith: "dateRender", dateFormat: "DD/MM/YYYY", type: 'date-range' },
                    { 'data': 'diferencia', 'title': $filter('translate')('TIENE_DIFERENCIA'), visible:true, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
                    { 'data': 'despacho[0].numero', 'title': 'Nro. Despacho'},
                    { 'data': 'despacho[0].fecha', 'title': 'Fecha de Despacho', visible:true, renderWith: "dateRender", dateFormat: "DD/MM/YYYY", type: 'date-range'}
    
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions': [
                    {
                        'title': $filter('translate')('NEW_IMPORTACION'),
                        'icon': 'glyphicon glyphicon-plus',
                        'showCondition': function() {
                            var permission = AuthorizationService.hasPermission('create_importaciones');
                            return permission
                        },
                        'action': function() { vm.addNewImportacion() }
                    },
                    {
                      'title': 'CSV',
                      'icon': 'glyphicon glyphicon-export',
                      'showCondition': function() { return true; },
                      'action': function() {
                          var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);
    
                          CsvFactory.csv("importaciones", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                          .then(function(response) {
                            console.log("response de csv: ", response);
                            var fileName = response.data.fileName;
                            $window.open(baseurl.getPublicBaseUrl() + fileName);
                          });
                        }
                    }
                ],
                'extraRowOptions': [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;'   title=" + $filter('translate')('PROCESS') + " ng-click='editarProceso(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: 'canEditar',
                        conditionDef: function(estado) {
                            return (estado.codigo != "cerrada");
                        },
                        functionName: "editarProceso",
                        functionDef: function(itemId) {
                            checkPeriodoAdministrativo(null).then(function(resultado) {
                                if(resultado == true){
                                    redirectImportacion(itemId);
                                }else if(resultado == false){
                                    var msg= $filter('translate')('NO_OPEN_PERIODS_NOW');
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            });;
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canBorrar",
                        conditionDef: function(estado) {
                            return (estado.codigo != "cerrada");
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });
    
                            $scope.cancel = function() {
                                $scope.modalInstanceBorrar1.dismiss('cancel');
                            };
    
                            $scope.ok = function(itemId) {
                                var model = ImportacionFactory.create({ id: itemId });
                                ImportacionFactory.remove(model).then(function() {
                                    $scope.modalInstanceBorrar1.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                    $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                                    $scope.mensajeModal = vm.options.failedDeleteError;
                                    var modalInstance = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };
                                    console.log("error al borrar: ");
                                    console.log(error);
                                });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        conditionName: 'canView',
                        conditionDef: function() {
                            return AuthorizationService.hasPermission('index_importaciones');
                        },
                        functionName: "view",
                        functionDef: function(itemId) {
                            redirectImportacion(itemId);
                        }
                    },{
                        templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
                        functionName: 'reenviar',
                        customAttribute: ['estado', 'enviadoAContabilidad', 'id'],
                        functionDef: function(atributo) {
    
                            var idImportacion = atributo[2];
    
                            ImportacionFactory.get(idImportacion, "ImportacionList").$promise.then(function(response) {
                                checkPeriodoAdministrativo(response.fecha).then(function(checkPeriodo) {
                                    if(checkPeriodo == true){
                                        $scope.tituloModal = $filter('translate')('REENVIAR_IMPORTACION_Q');
                                        $scope.mensajeModal = $filter('translate')('REENVIAR_IMPORTACION_MSG');
                                        var modalInstance = $modal.open({
                                            templateUrl: 'views/directives/confirmation-modal.html',
                                            scope: $scope
                                        });
    
                                        $scope.cancel = function() {
                                            modalInstance.dismiss('cancel');
                                        };
    
                                        $scope.ok = function(id) {
                                            ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                                if (parametro.valorTipo.codigo == 'si') {
                                                    ImportacionFactory.reenviar(response).then(function(response) {
                                                      $state.go("app.importaciones.list", { id: response.id }, { reload: true });
                                                    }, function(error) {
                                                        var msg = "";
                                                        if (error.data && error.data.length > 0 && error.data[0].message) {
                                                            msg = error.data[0].message;
                                                        } else {
                                                            msg = $filter('translate')('REENVIAR_IMPORTACION_FAILED');
                                                        }
    
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    });
                                                }else{
                                                    var msg = "";
                                                    msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                }
                                            });
                                            modalInstance.close();
                                        }
                                    }else{
                                        var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            });
                        },
                        conditionName: 'canReenviar',
                        conditionDef: function (atributo) {
                            var estado = atributo[0];
                            var enviadoAContabilidad = atributo[1];

                            // Aquí puedes utilizar la variable enviarAContabilidad
                            if (enviarAContabilidad == 'si') {
                                if(estado.codigo === 'cerrada'){
                                    if (enviadoAContabilidad === "No") {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                ]
            };
        });
    }).catch(function (error) {
        // Manejar cualquier error que pueda ocurrir
        console.error('Error al obtener el valor de enviarAContabilidad:', error);
    });
   

    /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
    var checkPeriodoAdministrativo = function(fechaComprobante){

        return $q(function(resolve, reject) {

            var params = {};
            var fecha;

            if(fechaComprobante == null){
                fecha = new Date();
            }else{
                fecha = fechaComprobante;
            }

            params.search = filterFactory
            .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                }
            ])
            .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function(responsePeriodo) {
                if(responsePeriodo.length>0){
                    resolve(true);
                }else{
                    resolve(false);
                }
            })
        });
    }

    function addNewImportacion() {
        $scope.tituloModal = $filter('translate')('NEW_IMPORTACION');
        // agregar filtro de ordenes de compra de importacion
        var staticFilterOC = {};
        staticFilterOC.search = filterFactory.and([{
                path: 'estado.codigo',
                notEquals: 'ocl_cerrada'
            },{
                path: 'estado.codigo',
                notEquals: 'ocl_ingresada'
            },{
                path: 'estado.codigo',
                notEquals: 'ocl_anulada'
            },{
                path: 'estado.codigo',
                notEquals: 'ocl_vencida'
            },{
                path: 'tipoOrdenCompra.esImportacion',
                like: 'true'
            },{
                path: 'id',
                sortDesc: 'true'
            }
        ]).value();
        if (vm.watchOC) { vm.watcherOC(); }
        WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
            vm.watcherOC = watcher;
            OrdenesCompraFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterOC, "BaseList")).$promise.then(function(response) {
                $scope.ordenesCompra = response;
                _.forEach($scope.ordenesCompra, function(oc) {
                    oc.fecha = moment.utc(oc.fecha).format("DD/MM/YYYY");
                });
            });
        }, "locacion.id");
        // $scope.ordenCompra = {};
        // buscar periodo que coincida con la fecha actual
        var params = {};
        params.search = filterFactory.and([{
            path: 'estado.codigo',
            equals: 'per_adm_abierto'
        }]).value();
        params.view = "BaseList";

        var flagPeriodo = false;

        PeriodoAdministrativoFactory.all(params).$promise.then(function(response) {
            var periodos = response;
            _.forEach(periodos, function(value) {
                var desde = new Date(value.fechaDesde);
                var hasta = new Date(value.fechaHasta);
                var hoy = new Date().setHours(0,0,0,0);

                if (hoy >= desde && hoy <= hasta) {
                    flagPeriodo = true;
                }
            })

            if (flagPeriodo) {
                vm.autoGenerateCode.then(function(response) {
                    if (response.valorTipo.codigo == "si") {
                        var modalInstance = $modal.open({
                            templateUrl: 'views/directives/importacion-auto-modal.html',
                            scope: $scope
                        });

                        $scope.oc = {};

                        $scope.searchOCFilter = function(criteria) {
                            return function(item) {
                                if (!criteria) {
                                    return true;
                                }
                                return item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.proveedor.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1;
                            }
                        };

                        $scope.cancel = function() {
                            modalInstance.dismiss('cancel');
                        };

                        $scope.ok = function(ordenCompra) {
                            //console.log(ordenCompra);
                            sendNewImportacion(ordenCompra).then(function(data) {
                                modalInstance.close();
                                ImportacionFactory.setIdImportacion(data.id);
                                ImportacionFactory.setCodigoImportacion(data.codigo);
                                ImportacionFactory.setImportacion(data);
                                redirectImportacion(data.id);
                            });

                        };
                    } else {
                        var modalInstance = $modal.open({
                            templateUrl: 'views/directives/importacion-manual-modal.html',
                            scope: $scope
                        });

                        $scope.oc = {};

                        $scope.searchOCFilter = function(criteria) {
                            return function(item) {
                                if (!criteria) {
                                    return true;
                                }
                                return item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.proveedor.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1;
                            }
                        };

                        $scope.cancel = function() {
                            modalInstance.dismiss('cancel');
                        };

                        $scope.ok = function(ordenCompra, codigo) {
                            //generamos nueva importacion indicando el codigo
                            sendNewImportacion(ordenCompra, codigo).then(function(data) {
                                modalInstance.close();
                                ImportacionFactory.setIdImportacion(data.id);
                                ImportacionFactory.setCodigoImportacion(data.codigo);
                                ImportacionFactory.setImportacion(data);
                                redirectImportacion(data.id);
                            });
                        };
                    }
                });
            } else {
                var msg = "No hay un periodo administrativo abierto.";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
        });
    }

    function sendNewImportacion(ordenCompra, codigo) {
        //console.log("En sendNewImportacion()");
        return TiposFactory.estadosImportacion().then(function(response) {
            var estados = response.data;

            if (!estados) {
                console.log("No se pudo guardar importación debido a que no se pudo traer los estados");
                return;
            }
            //console.log("Se obtuvieron los estados");
            //console.log(estados);
            var estadoActivo = _.find(estados, function(estado) {
                return estado.codigo === "abierta";
            });
            if (!estadoActivo) {
                return;
            }
            //console.log(estadoActivo);
            var importacion = {
                estado: estadoActivo.id,
                activo: true,
                ordenesCompra: [ordenCompra],
                proveedor: ordenCompra.proveedor
            };
            if (codigo) {
                importacion.codigo = codigo;
            }
            var imp = ImportacionFactory.create(importacion);
            return ImportacionFactory.save(imp);

        }, function(error) {
            console.log("Error a traer los estados de importacion: ");
            console.log(error);
        }).then(function(data) {
            console.log("Se ha guardado la importacion.");
            console.log(data);
            return data;
        }, function(error) {
            console.log("Error al guardar la importacion.");
            console.log(error);
        });;
    }

    function redirectImportacion(itemId) {
        ImportacionFactory.get(itemId, "ImportacionList").$promise.then(function(imp) {
            ImportacionFactory.setIdImportacion(imp.id);
            ImportacionFactory.setCodigoImportacion(imp.codigo);
            ImportacionFactory.setImportacion(imp);
            switch (imp.estado.codigo) {
                case "abierta":
                    $state.go('app.importaciones.proceso.ordenescompra.list', { codigoImportacion: itemId });
                    break;
                case "con_orden_compra":
                    $state.go('app.importaciones.proceso.proformas.list', { codigoImportacion: itemId });
                    break;
                case "con_proforma":
                    $state.go('app.importaciones.proceso.solicitudes.list', { codigoImportacion: itemId });
                    break;
                case "con_solicitud":
                    console.log('con solicitud');
                    $state.go('app.importaciones.proceso.embarques.list', { codigoImportacion: itemId });
                    break;
                case "con_embarque":
                    console.log('con embarque');
                    $state.go("app.importaciones.proceso.comprobante.list", { codigoImportacion: itemId });
                    break;
                case "con_comprobante":
                    console.log('con comprobante');
                    $state.go("app.importaciones.proceso.comprobante.list", { codigoImportacion: itemId });
                    break;
                case "con_recepcion":
                    console.log('con recepcion');
                    $state.go("app.importaciones.proceso.recepcionimportacion.list", { codigoImportacion: itemId });
                    break;
                case "con_despacho":
                    console.log('con despacho');
                    $state.go("app.importaciones.proceso.cierre", { codigoImportacion: itemId });
                    break;
                case "cerrada":
                    $state.go("app.importaciones.proceso.cierre", { codigoImportacion: itemId });
                    break;
                default:
                    console.log("Estado distinto")
            }
        });
    }

    // Llama a la función getByCodigo y retorna la promesa
    function obtenerEnviarAContabilidad() {
        return ParametrosFactory.getByCodigo('enviar_a_contabilidad')
            .then(function (response) {
                return response.valorTipo.codigo;
            });
    }
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.servicio
 * @description
 * # servicio
 * Factory in the qualita.
 */
angular.module('qualita.importacion')
  .factory('ServicioFactory', ['$resource', 'baseurl','filterFactory','TipoImpuestosFactory', 'ImportacionLangFactory', '$filter', '$http',
    function($resource, baseurl, filterFactory, TipoImpuestosFactory, ImportacionLangFactory, $filter, $http) {

  	var Servicio= $resource( baseurl.getBaseUrl() + '/servicios/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

      var schema = undefined;

      ImportacionLangFactory.getTranslations().then(function(translations) {
        schema = {
          type: 'object',
          title: $filter('translate')('SERVICIO'),
          properties: {
            descripcion: {
              title: $filter('translate')('DESCRIPTION'),
              type: 'string',
              maxLength: 255,
              validationMessage: {
                201: $filter('translate')('LONG_VALUE_WARNING')
              },
            },
            tipoImpuesto: {
              validationMessage: $filter('translate')('REQUIRED_FIELD'),
              title: $filter('translate')('TIPO_IMPUESTO'),
              type: 'number'
            },
            compra: {
              title: $filter('translate')('ES_COMPRA'),
              type: 'boolean'
            },
            venta: {
              title: $filter('translate')('ES_VENTA'),
              type: 'boolean'
            },
            controlarcentrocostos: {
              title: $filter('translate')('CONTROLAR_CENTRO_COSTOS'),
              type: 'boolean'
            },
          },
          required: ['descripcion','tipoImpuesto']
        };
      });


    var formNew = [
      'descripcion',
        {
          key: 'tipoImpuesto',
          type: 'uiselect',
          options: {
            asyncCallback: function (options, search) {
              var params = {};
              if (search) {
                params.search = filterFactory.single({
                  path: 'descripcion',
                  like: search
                }).value();
              }
              return TipoImpuestosFactory.all(params).$promise.then(function (data) {
                return {data: data};
              });
            },
            map: {valueProperty: 'id', nameProperty: 'descripcion'},
            formName: 'servicioForm'
          }
        }
        ,{ key: 'compra', type: 'customcheckbox' }
        ,{ key: 'venta', type: 'customcheckbox' }
        ,{ key: 'controlarcentrocostos', type: 'customcheckbox' }
    ];

    var formEdit = angular.copy(formNew);
    var formView = angular.copy(formNew);

    var forms = {
      'new': formNew,
      'edit': formEdit,
      'view': formView
    }

    return {
      all: function(params, view) {
        if(params){
          params.view = view != undefined ? view : 'BaseList';
        }
        return Servicio.query(params);
      },

      get: function(id, view) {
        return Servicio.get({id: id, view: view != undefined ? view : 'BaseForm'});
      },

      create: function(attrs) {
        return new Servicio(attrs);
      },

      save: function(servicio) {
        return (servicio.id) ? servicio.$update() : servicio.$save();
      },

      remove: function(servicio) {
        return servicio.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return forms[type];
      },
      carga: function(name) {
        return $http({
                url: baseurl.getBaseUrl() + '/servicios/carga', 
                method: "POST",
                params: { filename:name }
              });
      },
      reenviar: function (idServicio) {
        return $http({
          method: "PUT",
          url: baseurl.getBaseUrl() + "/servicios/reenviar/"+idServicio,
        });
      }
    };
  }]);

'use strict';


angular.module('qualita.importacion')
  .controller('ServicioIndexCtrl', ["$scope", "ServicioFactory", "filterFactory", "ReportTicketFactory", "$window", "ImportacionLangFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter", "$modal", "notify", "$state", "ParametrosFactory", function ($scope, ServicioFactory, filterFactory, ReportTicketFactory,
  $window, ImportacionLangFactory, CsvFactory, UtilFactory, baseurl, $filter, $modal, notify, $state,
  ParametrosFactory) {

    var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
    var enviarAContabilidad = '';

    promise1.then(function(response) {
        enviarAContabilidad = response.valorTipo.codigo;
    });

    var defaultColumnOrder = ['id','codigo','descripcion','tipoImpuesto.descripcion','compra','venta'];
    ImportacionLangFactory.getTranslations().then(function(translations) {
      $scope.options = {
        'resource': 'servicios',
        'title': $filter('translate')('SERVICIOS'),
        'factory': ServicioFactory,
        'view': 'ServicioList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('SERVICIO_DELETE_FAILED'),
        'columns': [
          {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
          {'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION')},
          {'data': 'tipoImpuesto.descripcion', 'title': $filter('translate')('TIPO_IMPUESTO')},
          {'data': 'compra', 'title': $filter('translate')('ES_COMPRA'), 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
          {'data': 'venta', 'title': $filter('translate')('ES_VENTA'), 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'}
        ],
        'hasOptions' : true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraMenuOptions':
          [
            {
              'title': $filter('translate')('REPORT'),
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoServicios', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("servicios", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
        ],
        extraRowOptions: [
          {
              templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
              functionName: 'reenviar',
              customAttribute: ['enviadoAContabilidad', 'id'],
              functionDef: function(atributo) {

                  var idServicio = atributo[1];
                  $scope.tituloModal = $filter('translate')('REENVIAR_SERVICIO');
                  $scope.mensajeModal = $filter('translate')('REENVIAR_SERVICIO_MSG');
                  var modalInstance = $modal.open({
                      templateUrl: 'views/directives/confirmation-modal.html',
                      scope: $scope
                  });

                  $scope.cancel = function() {
                      modalInstance.dismiss('cancel');
                  };

                  $scope.ok = function(id) {
                    ServicioFactory.reenviar(idServicio).then(function (response) {
                          $state.go($state.current, {}, {reload: true});
                      }, function(error) {
                          var msg = "";
                          if (error.data && error.data.length > 0 && error.data[0].message) {
                              msg = error.data[0].message;
                          } else {
                              msg = $filter('translate')('REENVIAR_SERVICIO_FAILED');
                          }

                          notify({ message: msg, classes: 'alert-danger', position: 'right' });
                      });
                      modalInstance.close();
                  }
              },
              conditionName: 'canReenviar',
              conditionDef: function(atributo) {
                  var enviadoAContabilidad = atributo[0];

                  if(enviarAContabilidad == 'si'){
                      if(enviadoAContabilidad === "No"){
                          return true;
                      }
                  }
              }
          }   
        ]
      };
    });

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          if(search === 'No' && (data === 'compra' || data === 'venta')){
            search = 'false';
        }
        if(search === 'Sí' && (data === 'compra' || data === 'venta')){
            search = 'true';
        }
          filtersArr.push({path: data, like: search})
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
  }]);

'use strict';

angular.module('qualita.importacion')
  .controller('ServicioFormController', ['$rootScope', '$scope', 'ServicioFactory', 'formFactory', '$location',
    'servicioPrepService', '$state', 'ImportacionLangFactory', 'notify', '$filter',
    function($rootScope, $scope, ServicioFactory, formFactory, $location,
      servicioPrepService, $state, ImportacionLangFactory, notify, $filter) {

      activate();

      function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations){
          $scope.translations = translations;
          $scope.schema = ServicioFactory.schema();
          $scope.options = formFactory.defaultOptions();

          if ($state.is("app.servicios.new")) {
            activateNew();
          } else if ($state.is("app.servicios.edit")) {
            activateEdit();
          } else if ($state.is("app.servicios.view")) {
            activateView();
          }

          $rootScope.isProcessing = false;
        });
      }

      function activateNew() {
        $scope.title = $filter('translate')('NEW_SERVICIO');
        $scope.form = ServicioFactory.form('new');
        $scope.model = {};
        $scope.schema.readonly = false;
      }

      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_SERVICIO');
        $scope.form = ServicioFactory.form('edit');
        $scope.model = servicioPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Servicio";
        $scope.schema.readonly = false;
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_SERVICIO');
        $scope.form = ServicioFactory.form('view');
        $scope.options = formFactory.defaultViewOptions();
        $scope.model = servicioPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Servicio";
        $scope.view = true;
        $scope.schema.readonly = true;
      }

      $scope.submit = function(form) {

        if(!$scope.model.compra && !$scope.model.venta){
          var msg = $filter('translate')('SERVICIO_NO_DEFINED_ERROR');
          notify({ message: msg, classes: 'alert-danger', position: 'right'});
          return;
        }

        formFactory.defaultSubmit('servicios', $scope, form, ServicioFactory, undefined, errorHandler);
      };

      $scope.cancel = function() {
        //$location.path('/servicios');
        $state.go('app.servicios.list', {}, {
          reload: true
        });
      };

      function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
          if (error.constraint == 'descripcion') {
            msg += '\n\n' + $filter('translate')('SERVICIO_DESCRIPCION_WARNING') + "\n";
          } else {
            msg += '\n\n' + error.message + '.';
          }
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      }

    }
  ]);

'use strict';


angular.module('qualita.importacion')
  .controller('ImportacionProcesoCtrl', ImportacionProcesoCtrl);

ImportacionProcesoCtrl.$inject = ["AuthorizationService", "ImportacionFactory", "TiposFactory", "$log", "$modal", 
"$scope", "notify", "ImportacionLangFactory"];

function ImportacionProcesoCtrl(AuthorizationService, ImportacionFactory, TiposFactory, $log, $modal, $scope, 
  notify, $stateParams, ImportacionLangFactory) {
  $scope.importacionId = ImportacionFactory.getIdImportacion();
  $scope.importacionCodigo = ImportacionFactory.getCodigoImportacion();
  $scope.importacion = ImportacionFactory.getImportacion();
  $scope.enabledStates = ImportacionFactory.getEnabledStates();

  $scope.$on("updateImportacionStates", function() {
    console.log("updateImportacionStates listener triggered");
    $scope.enabledStates = ImportacionFactory.getEnabledStates();
  });

  //ImportacionLangFactory.getTranslations();

}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.importacion')
  .factory('OrdenesCompraFactory', OrdenesCompraFactory);

OrdenesCompraFactory.$inject = ['$resource', 'baseurl', '$http', 'notify', '$state', '$stateParams'];

function OrdenesCompraFactory($resource, baseurl, $http, notify, $state, $stateParams) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    emitir: emitir,
    anular: anular,
    cerrar: cerrar,
    cerrarMultiple: cerrarMultiple,
    consolidar: consolidar,
    getDiferencia: getDiferencia,
    importacionAnular:importacionAnular
  };

  var OrdenesCompra = $resource( baseurl.getBaseUrl() + "/orden_compra/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined || params.view != undefined ? (view || params.view) : 'base';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'base';
    }
    return OrdenesCompra.query(params);
  }

  function get(id, view) {
    return OrdenesCompra.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new OrdenesCompra(attrs);
  }

  function save(ordencompra) {
    return (ordencompra.id) ? ordencompra.$update() : ordencompra.$save();
  }

  function remove(ordencompra) {
    return ordencompra.$remove();
  }

  function emitir(id){
    return $http({
              url: baseurl.getBaseUrl() + '/orden_compra/emitir/'+id,
              method: "PUT"
           });
  }

  function anular(id){
    return $http({
      url: baseurl.getBaseUrl() + '/orden_compra/anular/'+id,
      method: "PUT"
    });
  }

  function cerrar(id){
    return $http({
      url: baseurl.getBaseUrl() + '/orden_compra/cerrar/'+id,
      method: "PUT"
    });
  }

  function importacionAnular(id){
    return $http({
      url: baseurl.getBaseUrl() + '/orden_compra/importacionanular/'+id,
      method: "PUT"
    });
  }

  function consolidar(ids) {
    $http({
      url: baseurl.getBaseUrl() + '/orden_compra/consolidar',
      method: "POST",
      params: {ordenes: ids}
    }).then(function () {
        $state.transitionTo($state.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true
        });
        //$route.reload();
      },
      function (e) {
        //console.log('error al marcar como consolidado');
          //se establecen los errores del backend
          if(e && e.status === 500) {
            var msg = '';
            _.forEach(e.data, function (error) {
              msg += '\n\n' + error.message + ''
            });
            notify({message: msg, classes: 'alert-danger', position: 'right'});
            // guardar en local storage
          }
      });
  }

  function cerrarMultiple(ids) {
    $http({
      url: baseurl.getBaseUrl() + '/orden_compra/cerrar',
      method: "POST",
      params: {ordenes: ids}
    }).then(function () {
        $state.transitionTo($state.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true
        });
        //$route.reload();
      },
      function (e) {
        //console.log('error al marcar como consolidado');
          //se establecen los errores del backend
          if(e && e.status === 500) {
            var msg = '';
            _.forEach(e.data, function (error) {
              msg += '\n\n' + error.message + ''
            });
            notify({message: msg, classes: 'alert-danger', position: 'right'});
            // guardar en local storage
          }
      });
  }

  function getDiferencia(id){
    return $http({
      url: baseurl.getBaseUrl() + '/orden_compra/diferencias/'+id,
      method: "GET"
    });
  }
}

'use strict';

angular.module('qualita.importacion')
    .controller('OrdenesCompraListCtrl', OrdenesCompraListCtrl);

OrdenesCompraListCtrl.$inject = ["AuthorizationService", "OrdenesCompraFactory",
    "TiposFactory", "ImportacionFactory", "ReportTicketFactory", "$log", "$modal",
    "$scope", "notify", "$state", "filterFactory", "$window",
    "ImportacionLangFactory", "WatchLocacionChangeFactory", "$filter"
];

function OrdenesCompraListCtrl(AuthorizationService, OrdenesCompraFactory,
    TiposFactory, ImportacionFactory, ReportTicketFactory, $log, $modal, $scope,
    notify, $state, filterFactory, $window, ImportacionLangFactory, WatchLocacionChangeFactory,
    $filter) {
    var vm = this;

    activate();
    vm.customResource = "importaciones.proceso.ordenescompra";

    function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;
            vm.canUpdate = AuthorizationService.hasPermission('update_orden_compra');
            // filtro estático
            vm.isProcesoImportacion = false;
            var staticFilter = {};
            if ($state.is("app.importaciones.proceso.ordenescompra.list")) {
                //console.log("----------------- lista de ordenes de compra de importacion ");
                var importacion = ImportacionFactory.getImportacion();
                if (!importacion) {
                    $state.go("app.main");
                }
                var isStateEnabled = ImportacionFactory.checkPermissionForState(0);
                if (!isStateEnabled) {
                    console.log("No tiene permiso para revisar esta seccion");
                    $state.go("app.main");
                }
                vm.isProcesoImportacion = true;
                staticFilter.search = filterFactory.and([{
                    path: 'importaciones.id',
                    equals: ImportacionFactory.getIdImportacion(),
                }]).value();
            } else {
                vm.customResource = "orden_compra_importacion";
                //console.log("----------------- lista normal de ordenes de compra ");
                staticFilter.search = filterFactory.and([{
                    path: 'tipoOrdenCompra.esImportacion',
                    like: 'true',
                }]).value();
            }

            var defaultColumnOrder = ['id', 'numero', 'fecha', 'proveedor.nombre', 'moneda.descripcion', 'estado.descripcion'];

            vm.options = {
                'staticFilter': staticFilter,
                'resource': 'orden_compra',
                'customResource': vm.customResource,
                'title': $filter('translate')('ORDENES_COMPRA'),
                'factory': OrdenesCompraFactory,
                'view': 'OrdenCompraList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('ORDENES_COMPRA_DELETE_FAILED'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('CODE'), 'class': 'dt-center', 'visible': false },
                    { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-center' },
                    { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_SINGLE'), 'class': 'dt-center' },
                    { 'data': 'moneda.descripcion', 'title': $filter('translate')('CURRENCY'), 'class': 'dt-center' },
                    { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'class': 'dt-center' },
                    {'data': 'fecha', 'title': $filter('translate')('DATE'), renderWith: "dateRender", dateFormat: 'DD/MM/YYYY', 'class' : 'dt-center', 'type' : 'date-range'}
                ],
                'hasOptions': true,
                'hideAddMenu': true,
                'defaultOrderColumn': 1,
                'defaultOrderDir': "desc",
                'extraRowOptions': [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canEditar",
                        conditionDef: function(estado) {
                            if (vm.isProcesoImportacion)
                                return (vm.canUpdate && estado.codigo == "ocl_ingresada" && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                            else
                                return (vm.canUpdate && estado.codigo == "ocl_ingresada");
                        },
                        functionName: "editar",
                        functionDef: function(itemId) {
                            if (vm.isProcesoImportacion)
                                $state.go("app.importaciones.proceso.ordenescompra.edit", { id: itemId });
                            else
                                $state.go("app.orden_compra_importacion.edit", { id: itemId });
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canBorrar",
                        conditionDef: function(estado) {
                            if (vm.isProcesoImportacion)
                                return (AuthorizationService.hasPermission('delete_orden_compra') && estado.codigo == "ocl_ingresada" && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                            else
                                return (AuthorizationService.hasPermission('delete_orden_compra') && estado.codigo == "ocl_ingresada");
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceBorrar1.dismiss('cancel');
                            }

                            $scope.ok = function(itemId) {
                                var model = OrdenesCompraFactory.create({ id: itemId });
                                OrdenesCompraFactory.remove(model).then(function() {
                                    $scope.modalInstanceBorrar1.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                    $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                                    $scope.mensajeModal = $scope.options.failedDeleteError;
                                    var modalInstance = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };
                                    console.log("error al borrar: ");
                                    console.log(error);
                                });
                            }
                        }
                    },
                    {
                        templateToRender: "<button title='Emitir OC' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='emitir(<%=dataId%>)' ng-class='{ hidden : !canEmitir()}'> <span class='glyphicon glyphicon-file'></span> </button>",
                        functionName: "emitir",
                        conditionName: "canEmitir",
                        conditionDef: function(estado) {
                            if (vm.isProcesoImportacion)
                                return (vm.canUpdate && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                            else
                                return (vm.canUpdate);
                        },
                        functionDef: function(itemId) {
                            OrdenesCompraFactory.get(itemId, "OrdenCompraImportacionList").$promise.then(function(data) {
                                if (data.ordenCompraDetalle.length > 0) {
                                    $scope.tituloModal = $filter('translate')('EMITIR_ORDEN_COMPRA_PREGUNTA');
                                    $scope.mensajeModal = $filter('translate')('PERMANENT_OPERATION_WARNING');

                                    var modalInstance = $modal.open({
                                        templateUrl: 'views/directives/confirmation-modal.html',
                                        scope: $scope
                                    });

                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };

                                    $scope.ok = function(id) {
                                        var filters = {};
                                        var filtersArr = [];
                                        var config = "CON_PRECIO";
                                        filtersArr.push("idordencompra=" + itemId);
                                        filtersArr.push("config=" + config);
                                        filters = filterFactory.and(filtersArr).value();
                                        OrdenesCompraFactory.emitir(itemId).then(function(response) {
                                            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('ordencompra', 'pdf', filtersArr);
                                            $window.open(vm.pdfDownloadURL, '_blank');
                                            modalInstance.close();
                                            $state.go($state.current, {}, { reload: true });
                                        }, function(error) {
                                            var msg = $filter('translate')('EMITIR_FAILED');
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        });

                                    };
                                } else {
                                    var msg = "No se pueden emitir Órdenes de Compra sin items.";
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            });
                        }
                    },
                    {
                        templateToRender: "<button title='Anular OC' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataId%>)' ng-class='{hidden : !canAnular(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-remove'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canAnular",
                        conditionDef: function(estado) {
                            if (vm.isProcesoImportacion)
                                return false;
                            else
                                return (estado.codigo == "ocl_emitida");
                        },
                        functionName: "anular",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('ANULAR_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('ANULAR_WARNING');
                            $scope.modalInstanceAnular = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceAnular.dismiss('cancel');
                            }

                            $scope.ok = function(itemId) {
                                //var model = OrdenesCompraFactory.create({id: itemId});
                                OrdenesCompraFactory.importacionAnular(itemId).then(function() {
                                    $scope.modalInstanceAnular.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceAnular.dismiss('cancel');
                                    $scope.tituloModal = $filter('translate')('ANULAR_FAILED');
                                    $scope.mensajeModal = $filter('translate')('ORDENES_COMPRA_ANULAR_FAILED');
                                    var modalInstance = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };
                                });
                            }
                        }
                    },
                    {
                        templateToRender: "<button title='Cerrar OC' class='btn btn-primary btn-dt' style='margin-right: 5px;' ng-click='cerrar(<%=dataId%>)' ng-class='{hidden : !canCerrar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-check'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canCerrar",
                        conditionDef: function(estado) {
                            if (vm.isProcesoImportacion)
                                return (estado.codigo == "ocl_emitida" && ImportacionFactory.getImportacion().estado.codigo == "cerrada");
                            else
                                return (estado.codigo == "ocl_emitida");
                        },
                        functionName: "cerrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('CERRAR_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('CERRAR_WARNING');
                            $scope.modalInstanceAnular = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceAnular.dismiss('cancel');
                            }

                            $scope.ok = function(itemId) {
                                //var model = OrdenesCompraFactory.create({id: itemId});
                                OrdenesCompraFactory.cerrar(itemId).then(function() {
                                    $scope.modalInstanceAnular.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceAnular.dismiss('cancel');
                                    $scope.tituloModal = $filter('translate')('CERRAR_FAILED');
                                    $scope.mensajeModal = $filter('translate')('ORDENES_COMPRA_CERRAR_FAILED');
                                    var modalInstance = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };
                                });
                            }
                        }
                    }
                ],
                'hideEditMenu': true,
                'hideRemoveMenu': true
                //'hideViewMenu': true,
            };
            WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");
        });
    }

    var getIDs = function() {
        var ids = [];
        _.forEach(vm.options.selection, function(n, key) {
            if (n)
                ids.push(key);
        });
        return ids;
    }
}
'use strict';

angular.module('qualita.importacion')
  .controller('OrdenesCompraFormCtrl', OrdenesCompraFormCtrl);

OrdenesCompraFormCtrl.$inject = ["OrdenesCompraFactory", "ProductosFactory", "TiposFactory",
  "ProveedoresFactory", "ImportacionFactory", "ordenCompraPrepService", "ReportTicketFactory", "filterFactory",
  "$modal", "$scope", "notify", "$state", "$rootScope", "$window", "$timeout", "ServicioFactory", "SolicitudCompraFactory",
  "ImportacionLangFactory", "TipoOrdenesCompraFactory", "WatchLocacionChangeFactory", "UtilFactory",
  "$filter"];

function OrdenesCompraFormCtrl(OrdenesCompraFactory, ProductosFactory, TiposFactory,
  ProveedoresFactory, ImportacionFactory, ordenCompraPrepService, ReportTicketFactory, filterFactory,
  $modal, $scope, notify, $state, $rootScope, $window, $timeout, ServicioFactory, SolicitudCompraFactory,
  ImportacionLangFactory, TipoOrdenesCompraFactory, WatchLocacionChangeFactory, UtilFactory, $filter) {
  var vm = this;

  activate();

  vm.agregarDetalle = agregarDetalle;
  vm.calcularImpuesto = calcularImpuesto;
  vm.calcularTotalDetalle = calcularTotalDetalle;
  vm.searchProductoFilter = searchProductoFilter;
  vm.removeItemFromArray = removeItemFromArray;
  vm.submit = submit;
  vm.cancel = cancel;
  vm.changeProducto = changeProducto;
  vm.changeProveedor = changeProveedor;
  vm.changeServicio = changeServicio;
  vm.tipoDetalleSelected = tipoDetalleSelected;
  vm.mostrarTodosProductos = mostrarTodosProductos;
  vm.tipoOrdenCompraChanged = tipoOrdenCompraChanged;
  vm.totalItemChanged = totalItemChanged;

  $rootScope.isProcessing = false;
  vm.isProcesoImportacion = false;

  function activate() {
    WatchLocacionChangeFactory.killAllWatchers();
    ImportacionLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;

      vm.curDate = new Date();
      var paramsProv = {};
      paramsProv.search = filterFactory.single({
        path: 'activo',
        equals: true,
        view: "BaseList"
      }).value();
      vm.proveedores = ProveedoresFactory.all(paramsProv);

      var filtroServicios = {};
      filtroServicios.search = filterFactory.and([{
        path: 'compra',
        equals: true
      }]).value();
      vm.servicios = ServicioFactory.all(filtroServicios);

      var staticFilterSC = {};
      staticFilterSC.search = filterFactory.and([{
        path: 'estado.codigo',
        equals: 'sc_aprobada'
      },
      {
        path: 'compraLocal',
        like: 'false'
      }]).value();

      if (vm.watcherSC) { vm.watcherSC(); } //hacemos unwatch de la expresion anterior
      WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcherSC) {
        vm.watcherSC = watcherSC;
        vm.solicitudesCompra = SolicitudCompraFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterSC, "BaseList"));
      }, "locacion.id");

      vm.factoryProductos = ProductosFactory;

      var renderEmptyData = function (data) {
        if (data == undefined)
          return "";
        else
          return data;
      };

      var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
        'lineaProducto.descripcion', 'marca.descripcion'];

      vm.opcionesProductosDt = {
        'resource': 'productos',
        'title': 'Productos',
        'factory': ProductosFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'codigo', 'title': $filter('translate')('ID'), visible: false  },
          { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
          { 'data': 'claseProducto.descripcion', 'title': $filter('translate')('CLASE') },
          { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
          { 'data': 'lineaProducto.descripcion', 'title': $filter('translate')('LINE') },
          { 'data': 'marca.descripcion', 'title': $filter('translate')('BRAND'), 'render': renderEmptyData },
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
      };

      var staticFilterProd = {};
      staticFilterProd.search = filterFactory.and([
        {
          path: 'itemCompra',
          like: 'true'
        },
        {
          path: 'estado.codigo',
          equals: 'activo'
        }
      ]).value();

      if (vm.watcher) { vm.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
        vm.watcher = watcher;
        vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProd));
        vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProd);
      }, "locaciones.id");

      vm.tiposDetalles = [{ id: 1, nombre: "Producto" }, { id: 2, nombre: "Servicio" }];

      if ($state.includes("app.importaciones.proceso")) {
        console.log("importacion");
        setImportacion();
        vm.isProcesoImportacion = true;
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
          path: 'esImportacion',
          like: 'true'
        }, {
          path: 'activo',
          like: 'true'
        }]
        ).value();
        vm.tiposOrdenCompra = TipoOrdenesCompraFactory.all(staticFilter);
      }
      if ($state.includes("app.importaciones.proceso.ordenescompra.new") || $state.includes("app.orden_compra_importacion.new")) {
        activateNew();
      } else if ($state.is("app.importaciones.proceso.ordenescompra.edit") || $state.includes("app.orden_compra_importacion.edit")) {
        activateEdit();
      } else if ($state.is("app.importaciones.proceso.ordenescompra.view") || $state.includes("app.orden_compra_importacion.view")) {
        activateView();
      }
      vm.monedas = {};
      TiposFactory.monedas().then(function (data) {
        vm.monedas = data.data;
      });

    });
  }

  function setImportacion() {
    var importacion = ImportacionFactory.getImportacion();
    if (!importacion) {
      $state.go("app.main");
    }
    var isStateEnabled = ImportacionFactory.checkPermissionForState(0);
    if (!isStateEnabled) {
      console.log("No tiene permiso para revisar esta seccion");
      $state.go("app.main");
    }
  }

  function activateNew() {
    vm.title = $filter('translate')('NEW_ORDEN_COMPRA');
    vm.ordenCompra = {
      ordenCompraDetalle: [{}],
      //importacion: ImportacionFactory.getIdImportacion(),
    };
    if (vm.isProcesoImportacion) {
      vm.ordenCompra.importaciones = [ImportacionFactory.getIdImportacion()];
    }
    $timeout(function () {
      $scope.$broadcast('formOpened');
    }, 20);

    TiposFactory.estadosOrdenCompra().then(function (data) {
      var estadoIngresado = _.filter(data.data, function (elem) { return elem.codigo === 'oc_ingresada'; });
      vm.ordenCompra.estado = estadoIngresado[0];
    });
    vm.totalGeneral = 0;
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_ORDEN_COMPRA');
    vm.ordenCompra = ordenCompraPrepService;
    vm.entidadId = vm.ordenCompra.id;
    vm.entidad = "OrdenCompra";
    vm.view = true;
    setTiposEntidades();
    vm.ordenCompra.fecha = new Date(vm.ordenCompra.fecha);
    vm.totalGeneral = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) { return sum + detalle.totalItem; }, 0);
    vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(4));
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_ORDEN_COMPRA');
    vm.ordenCompra = ordenCompraPrepService;
    vm.entidadId = vm.ordenCompra.id;
    vm.entidad = "OrdenCompra";
    vm.ordenCompra.fecha = new Date(vm.ordenCompra.fecha);
    var staticFilter = {};
    staticFilter.search = filterFactory.and([{
      path: 'proveedores.codigo',
      equals: vm.ordenCompra.proveedor.codigo
    },
    {
      path: 'itemCompra',
      like: 'true'
    },
    {
      path: 'estado.codigo',
      equals: 'activo'
    }]).value();

    if (vm.watcher) { vm.watcher(); }
    WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
      vm.watcher = watcher;
      vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter));
      vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter);
    }, "locaciones.id");
    vm.totalGeneral = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) { return sum + detalle.totalItem; }, 0);
    vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(4));
    setTiposEntidades();
    $timeout(function () {
      $scope.$broadcast('formOpened');
    }, 20);
  }

  function changeProveedor() {
    vm.ordenCompra.ordenCompraDetalle = [{}];
    filterProductos();
  }

  function setTiposEntidades() {
    if (!vm.ordenCompra || !vm.ordenCompra.ordenCompraDetalle) {
      return;
    }

    _.forEach(vm.ordenCompra.ordenCompraDetalle, function (detalle) {
      if (detalle.producto) {
        detalle.tipo = { id: 1, nombre: "Producto" };
      }
      if (detalle.servicio) {
        detalle.tipo = { id: 2, nombre: "Servicio" };
      }
      tipoOrdenCompraChanged();
    });
  }

  function mostrarTodosProductos() {
    if (vm.todosProductos) {
      var staticFilter = {};
      staticFilter.search = filterFactory.and([
        {
          path: 'itemCompra',
          like: 'true'
        },
        {
          path: 'estado.codigo',
          equals: 'activo'
        }]).value();
      if (vm.watcher) { vm.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
        vm.watcher = watcher;
        vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter));
        m.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter);
      }, "locaciones.id");
    } else {
      filterProductos();
    }
  }

  function filterProductos() {
    vm.todosProductos = false;
    var staticFilter = {};
    staticFilter.search = filterFactory.and([{
      path: 'proveedores.codigo',
      equals: vm.ordenCompra.proveedor.codigo
    },
    {
      path: 'itemCompra',
      like: 'true'
    },
    {
      path: 'estado.codigo',
      equals: 'activo'
    }]).value();

    vm.opcionesProductosDt.staticFilter = staticFilter;
    if (vm.watcher) { vm.watcher(); }
    WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
      vm.watcher = watcher;
      vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter));
    }, "locaciones.id");
  }

  function agregarDetalle() {
    vm.ordenCompra.ordenCompraDetalle.push({
      montosImponibles: [{}]
    });
    var rowNumber = vm.ordenCompra.ordenCompraDetalle.length - 1;
    $timeout(function () {
      $scope.$broadcast('newItemAdded');
    }, 20);
  }

  function tipoOrdenCompraChanged() {
    if (!vm.ordenCompra.tipoOrdenCompra) {
      return;
    }

    vm.tiposDetalles = [];

    if (vm.ordenCompra.tipoOrdenCompra.incluyeProducto || vm.ordenCompra.tipoOrdenCompra.esImportacion) {
      vm.tiposDetalles.push({ id: 1, nombre: $filter('translate')('ITEM') });
    }

    if (vm.ordenCompra.tipoOrdenCompra.incluyeServicio || vm.ordenCompra.tipoOrdenCompra.esImportacion) {
      vm.tiposDetalles.push({ id: 2, nombre: $filter('translate')('SERVICE') })
    }
  }

  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function (item) {
      return item === elemento;
    });
    $timeout(function () {
      $scope.$broadcast('newItemRemoved');
    }, 20);
    vm.totalGeneral = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) { return sum + detalle.totalItem; }, 0);
    vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(4));
  }

  function calcularImpuesto(detalle, accordingTo) {
    if (accordingTo === "valor") {
      detalle.totalItemExento = detalle.totalItem - detalle.montosImponibles[0].valor;
    } else if (accordingTo === "exento") {
      detalle.montosImponibles[0].valor = detalle.totalItem - detalle.totalItemExento;
    }
  }

  function tipoDetalleSelected(detalle) {
    delete detalle.producto;
    delete detalle.servicio;
    delete detalle.unidadMedida;
  }

  function calcularTotalDetalle(detalle) {
    if (detalle.cantidad == undefined) {
      detalle.totalItem = 0;
      return;
    }
    if (detalle.ultimoPrecio == undefined) {
      detalle.totalItem = 0;
      return;
    }
    var mult = detalle.cantidad * detalle.ultimoPrecio;
    detalle.totalItem = parseFloat(mult.toFixed(4));
    detalle.totalItemExento = parseFloat(mult.toFixed(4));
    updateTotalGeneral();
  }

  function updateTotalGeneral() {
    vm.totalGeneral = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) { return sum + detalle.totalItem; }, 0);
    vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(4));
  }

  function totalItemChanged(detalle) {
    if (detalle.cantidad == undefined) {
      detalle.cantidad = 1;
    }

    detalle.ultimoPrecio = detalle.totalItem / detalle.cantidad;
    detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(5));
    updateTotalGeneral();
  }

  function changeProducto(detalle) {
    detalle.unidadMedida = detalle.producto.unidadMedidaPrincipal;
    detalle.cantidad = 0;
    detalle.ultimoPrecio = 0;
    detalle.totalItemExento = 0;
    detalle.totalItem = 0;
  }

  function changeServicio(detalle) {
    detalle.cantidad = 0;
    detalle.ultimoPrecio = 0;
    detalle.totalItemExento = 0;
    detalle.totalItem = 0;
  }

  function searchProductoFilter(criteria) {
    return function (item) {
      console.log("item ", item);
      if (!criteria) {
        return true;
      }
      return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
    };
  }

  function submit() {
    vm.submited = true;
    //console.log($scope.OrdenCompraForm.$error);
    if ($scope.OrdenCompraForm.$valid) {
      vm.ordenCompra.ordenCompraDetalle = _.filter(vm.ordenCompra.ordenCompraDetalle, function (elem) { return elem.producto || elem.servicio; });
      var montosOrdenCompra = _.filter(vm.ordenCompra.ordenCompraDetalle, function (detalle) {
        return detalle.ultimoPrecio == 0;
      });
      if (montosOrdenCompra.length == 0) {
        $rootScope.isProcessing = true;
        submitOrdenCompra().then(function (response) {
          if (vm.isProcesoImportacion) {
            // TODO verificar si es siempre la primera importacion
            ImportacionFactory.setIdImportacion(response.importaciones[0].id);
            ImportacionFactory.setCodigoImportacion(response.importaciones[0].codigo);
            ImportacionFactory.setImportacion(response.importaciones[0]);
            $scope.$emit("updateImportacionStates");
            $state.go('app.importaciones.proceso.ordenescompra.list', { codigoImportacion: ImportacionFactory.getIdImportacion() });
          } else {
            $state.go('app.orden_compra_importacion.list');
          }
          return response;
        }, function (error) {
          $rootScope.isProcessing = false;
          var msg = $filter('translate')('ERROR_SAVING');
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
        });
      } else {
        var msg = $filter('translate')('OC_IMP_INVALID_MONTO');

        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      }
    }
  }

  function submitOrdenCompra() {
    var resource = OrdenesCompraFactory.create(vm.ordenCompra);
    _.forEach(resource.ordenCompraDetalle, function (detalle) {
      delete detalle.tipo;
    });
    resource.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
    return OrdenesCompraFactory.save(resource);
  }

  function cancel() {
    if (vm.isProcesoImportacion)
      $state.go('app.importaciones.proceso.ordenescompra.list', { codigoImportacion: ImportacionFactory.getIdImportacion() });
    else
      $state.go('app.orden_compra_importacion.list');
  }

}

angular.module('qualita.importacion')
  .factory('ProformaFactory', ProformaFactory);

ProformaFactory.$inject = ['$resource', 'baseurl'];

function ProformaFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var Proforma = $resource( baseurl.getBaseUrl() + "/proforma/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    console.log(view);
    if (params) {
      params.view = view !== undefined ? view : 'base';
    } else {
      params = [];
      params.view = view !== undefined ? view : 'base';
    }
    return Proforma.query(params);
  }

  function get(id, view) {
    return Proforma.get({id: id, view: view!=undefined ? view : 'base'});
  }

  function create(attrs) {
    return new Proforma(attrs);
  }

  function save(proforma) {
    return (proforma.id) ? proforma.$update() : proforma.$save();
  }

  function remove(proforma) {
    return proforma.$remove();
  }
}

'use strict';

angular.module('qualita.importacion')
    .controller('ProformaListCtrl', ProformaListCtrl);

ProformaListCtrl.$inject = ["AuthorizationService", "ProformaFactory", "ImportacionFactory", "$modal", "$scope", "$state",
    "filterFactory", "ImportacionLangFactory", "WatchLocacionChangeFactory", "$filter"
];

function ProformaListCtrl(AuthorizationService, ProformaFactory, ImportacionFactory, $modal, $scope, $state, filterFactory,
    ImportacionLangFactory, WatchLocacionChangeFactory, $filter) {

    var vm = this;

    activate();

    function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;
            var importacion = ImportacionFactory.getImportacion();
            if (!importacion) {
                $state.go("app.main");
            }
            var isStateEnabled = ImportacionFactory.checkPermissionForState(1);
            if (!isStateEnabled) {
                console.log("No tiene permiso para revisar esta seccion");
                $state.go("app.main");
            }

            var monedaRender = function(data, type, row) {
                if (data) {
                    var moneda = "Gs. ";
                    if (row.monedaOC.codigo === 'dolares') {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.monedaOC.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.monedaOC.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.monedaOC.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    }
                    return (
                        moneda +
                        data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                } else {
                    return '';
                }
            };

            // filtro estático
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'importacion.codigo',
                equals: ImportacionFactory.getCodigoImportacion(),
            }]).value();

            var defaultColumnOrder = [ /*'id',*/ 'numero', 'proveedorOC.nombre', 'monedaOC.descripcion', 'ordenCompra.numero', 'fecha',
                'tipoCostoDespacho.descripcion', 'puertoLlegada', 'montoTotal', 'cantidadPagos', 'periodoPagos', 'entregaInicial'
            ];
            vm.options = {
                'staticFilter': staticFilter,
                'resource': 'proforma',
                'view': 'ProformaList',
                'title': 'Proformas',
                'factory': ProformaFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': 'No se pudo borrar la proforma. Verifique si hay operaciones asociadas.',
                'columns': [

                    /*{'data': 'id', 'title': $filter('translate')('CODE'), 'class' : 'dt-center'},*/
                    { 'data': 'ordenCompra.numero', 'title': $filter('translate')('ORDEN_COMPRA'), 'class': 'dt-center'  },
                    { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-center'  },
                    { 'data': 'proveedorOC.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX'), 'class': 'dt-center' },
                    { 'data': 'monedaOC.descripcion', 'title': 'Moneda', 'class': 'dt-center', visible: false },
                    { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'puertoLlegada', 'title': $filter('translate')('PUERTO_LLEGADA'), 'class': 'dt-center', visible: false },
                    { 'data': 'tipoCostoDespacho.descripcion', 'title': $filter('translate')('TIPO_COSTO_DESPACHO'), 'class': 'dt-center', visible: false },
                    { 'data': 'montoTotal', 'title': $filter('translate')('TOTAL_AMOUNT'), 'render': monedaRender, 'class': 'dt-center'  },
                    { 'data': 'cantidadPagos', 'title': $filter('translate')('CANTIDAD_CUOTAS'), 'class': 'dt-center', visible: false  },
                    { 'data': 'periodoPagos', 'title': $filter('translate')('PLAZO_CUOTAS'), 'class': 'dt-center', visible: false  },
                    { 'data': 'entregaInicial', 'title': $filter('translate')('MONTO_INICIAL'), 'class': 'dt-center', visible: false, 'render': monedaRender  }
                ],
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions': [{
                    'title': 'Nuevo',
                    'icon': 'glyphicon glyphicon-plus',
                    'showCondition': function() {
                        return AuthorizationService.hasPermission('create_proforma') && ImportacionFactory.getImportacion().estado.codigo != "cerrada";
                    },
                    'action': function() {
                        $state.go("app.importaciones.proceso.proformas.new");
                    }
                }, ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraRowOptions': [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar()}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        functionName: "editar",
                        functionDef: function(itemId) {
                            $state.go("app.importaciones.proceso.proformas.edit", { id: itemId });
                        },
                        conditionName: "canEditar",
                        conditionDef: function() {
                            return (AuthorizationService.hasPermission("update_proforma") && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                        }
                    },

                    /**/
                    /*{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" +
                            $filter("translate")("EDIT") +
                            "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canView(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        functionName: "editar",
                        functionDef: function(itemId) {
                            if (vm.fromOutside) {
                                $state.go("app.consultacomprobantes.edit", { id: itemId });
                            } else {
                                $state.go("app.compraslocales.proceso.comprobante.edit", {
                                    id: itemId
                                });
                            }
                        },
                        conditionName: "canView",
                        customAttribute: ["estado", "compra", "importacion"],
                        conditionDef: function(atributos) {
                            var estado = atributos[0];
                            var compra = atributos[1];
                            var importacion = atributos[2];
                            return (
                                (vm.fromOutside &&
                                    AuthorizationService.hasPermission("update_comprobante") &&
                                    !compra &&
                                    !importacion &&
                                    estado.codigo != "comp_aprobado") ||
                                (!vm.fromOutside &&
                                    AuthorizationService.hasPermission("update_comprobante") &&
                                    CompraFactory.getCompra().estado.codigo != "compra_cerrada" &&
                                    CompraFactory.getCompra().estado.codigo != "compra_anulada" &&
                                    estado.codigo != "comp_aprobado")
                            );
                        }
                    },*/
                    /**/

                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar()}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        conditionName: "canBorrar",
                        conditionDef: function() {
                            return (AuthorizationService.hasPermission('delete_proforma') && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceBorrar1.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                var model = ProformaFactory.create({ id: itemId });
                                ProformaFactory.remove(model).then(function() {
                                    $scope.modalInstanceBorrar1.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                    $scope.tituloModal = $filter('translate')('DELETE_FAILED')
                                    $scope.mensajeModal = vm.options.failedDeleteError;
                                    var modalInstance = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };
                                    console.log("error al borrar: ");
                                    console.log(error);
                                });
                            };
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function(itemId) {
                            $state.go("app.importaciones.proceso.proformas.view", { id: itemId });
                        },
                        conditionName: "canVer",
                        conditionDef: function() {
                            return (AuthorizationService.hasPermission('index_proforma'));
                        },
                    }
                ],
            };
            WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");
        });
    }

}

'use strict';

angular.module('qualita.importacion')
    .controller('ProformaFormCtrl', ProformaFormCtrl);

ProformaFormCtrl.$inject = ["ProformaFactory", "proformaPrepService", "OrdenesCompraFactory", "ProductosFactory", "ImportacionFactory",
    "filterFactory", "TiposFactory", "ServicioFactory", "ParametrosFactory", "$scope", "notify", "$state", "$rootScope", "$timeout",
    "ImportacionLangFactory", "WatchLocacionChangeFactory", "UtilFactory", "$filter", "CondicionImportacionFactory"
];

function ProformaFormCtrl(ProformaFactory, proformaPrepService, OrdenesCompraFactory, ProductosFactory, ImportacionFactory,
    filterFactory, TiposFactory, ServicioFactory, ParametrosFactory, $scope, notify, $state, $rootScope, $timeout,
    ImportacionLangFactory, WatchLocacionChangeFactory, UtilFactory, $filter, CondicionImportacionFactory) {

    var vm = this;

    vm.agregarDetalle = agregarDetalle;
    vm.calcularImpuesto = calcularImpuesto;
    vm.calcularTotalDetalle = calcularTotalDetalle;
    vm.removeItemFromArray = removeItemFromArray;
    vm.searchProductoFilter = searchProductoFilter;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.changeProducto = changeProducto;
    vm.changeServicio = changeServicio;
    vm.changeOC = changeOC;
    vm.tipoDetalleSelected = tipoDetalleSelected;
    vm.mostrarTodosProductos = mostrarTodosProductos;
    vm.deleteDetail = deleteDetail;
    vm.totalItemChanged = totalItemChanged;
    vm.delayRequestProducto = delayRequestProducto;

    vm.ordenCompra = {};
    activate();

    $rootScope.isProcessing = false;

    function activate() {
        WatchLocacionChangeFactory.killAllWatchers();
        ImportacionLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;
            var importacion = ImportacionFactory.getImportacion();
            vm.importacion = importacion;
            if (!importacion) {
                $state.go("app.main");
            }
            var isStateEnabled = ImportacionFactory.checkPermissionForState(1);
            if (!isStateEnabled) {
                console.log("No tiene permiso para revisar esta seccion");
                $state.go("app.main");
            }

            vm.curDate = new Date();

            var filtroServicios = {};
            filtroServicios.search = filterFactory.and([{
                path: 'compra',
                equals: true
            }]).value();
            filtroServicios.view = "BaseList";
            vm.servicios = ServicioFactory.all(filtroServicios);
            // TiposFactory.tipoCostoDespacho().then(function (data) {
            //   vm.tiposCostoDespacho = data.data;
            // });
            var param = { view: "CondicionImportacionList" };
            vm.tiposCostoDespacho = CondicionImportacionFactory.all(param);

            var staticFilterOC = {};
            staticFilterOC.search = filterFactory.and([{
                path: 'importaciones.codigo',
                equals: ImportacionFactory.getCodigoImportacion(),
            }]).value();

            if (vm.watchOC) { vm.watcherOC(); }
            WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
                vm.watcherOC = watcher;
                OrdenesCompraFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterOC, "OrdenCompraImportacionList")).$promise.then(function(response) {
                    vm.ordenesCompra = response;
                });
            }, "locacion.id");

            vm.factoryProductos = ProductosFactory;
            var renderEmptyData = function(data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            var defaultColumnOrder = ['codigo','nombre', 'claseProducto.descripcion', 'estado.descripcion',
                'lineaProducto.descripcion', 'marca.descripcion'
            ];

            vm.opcionesProductosDt = {
                'resource': 'productos',
                'title': 'Productos',
                'view': 'ProductosDatatableList',
                'factory': ProductosFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'codigo', 'title': $filter('translate')('ID'), visible: false  },
                    { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
                    { 'data': 'claseProducto.descripcion', 'title': $filter('translate')('KIND') },
                    { 'data': 'estado.descripcion', 'title': $filter('translate')('DESCRIPTION'), 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
                    { 'data': 'lineaProducto.descripcion', 'title': $filter('translate')('LINE') },
                    { 'data': 'marca.descripcion', 'title': $filter('translate')('BRAND'), 'render': renderEmptyData },
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
            };

            if (vm.watcher) { vm.watcher(); }
            WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
                vm.watcher = watcher;
                vm.productos = ProductosFactory.allForSelect(locacionFilter);
                vm.opcionesProductosDt.staticFilter = locacionFilter;
            }, "locaciones.id");

            vm.tiposDetalles = [{ id: 1, nombre: "Producto" }, { id: 2, nombre: "Servicio" }];

            getMonedaLocal();
            getParamDiferenciaMonedaLocal();
            getParamDiferenciaMonedaExtranjera();

            if ($state.is("app.importaciones.proceso.proformas.new")) {
                activateNew();
            } else if ($state.is("app.importaciones.proceso.proformas.edit")) {
                activateEdit();
            } else if ($state.is("app.importaciones.proceso.proformas.view")) {
                activateView();
            }
        });
    }

    function delayRequestProducto(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {

            var filterNombre = [];
            var filterCodigoBarras = [];
            var filterCodigoInterno = [];

            filterNombre.push({
                path: "nombre",
                like: searchValue
            })

            filterCodigoBarras.push({
                path: "codigoBarrasPrincipal.codigoBarras",
                like: searchValue
            })

            filterCodigoInterno.push({
                path: "codigo",
                like: searchValue
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }

            recuperarProducto(userInputFilter)
        } else if (searchValue.length > 0 && searchValue.length < 3 && vm.recepcion.proveedor) {

            var filterCodigoInterno = vm.staticFilter.slice()
            filterCodigoInterno.push({
                path: "codigo",
                like: searchValue
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }
            recuperarProducto(userInputFilter)
        } else {
            vm.productos = undefined
        }
    }

    function recuperarProducto(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
                vm.finalFilters, userInputFilter)

        vm.productos = ProductosFactory.allForSelect(userInputFilter)
    }

    function activateNew() {
        var isClosed = vm.importacion.estado.codigo === "cerrada";
        if (isClosed) {
            $state.go("app.main");
        }
        vm.title = $filter('translate')('NEW_PROFORMA');
        vm.proforma = {
            importacion: ImportacionFactory.getIdImportacion()
        };
        vm.proforma.proformaDetalle = [{}];
        vm.proforma.montoTotal = 0;
        $timeout(function() {
            $scope.$broadcast('formOpened');
        }, 20);
        vm.totalGeneral = 0;
    }

    function activateEdit() {
        var isClosed = vm.importacion.estado.codigo === "cerrada";
        if (isClosed) {
            $state.go("app.main");
        }
        vm.title = $filter('translate')('EDIT_PROFORMA');
        vm.proforma = proformaPrepService;
        vm.entidadId = vm.proforma.id;
        vm.entidad = "Proforma";
        vm.proforma.fecha = new Date(vm.proforma.fecha);
        $timeout(function() {
            $scope.$broadcast('formOpened');
        }, 20);
        setTiposEntidades();
        vm.totalGeneral = _.reduce(vm.proforma.proformaDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);
        if (vm.proforma.ordenCompra.moneda.codigo == 'guaranies') {
            vm.totalGeneral = vm.totalGeneral.toFixed(0);
        } else {
            vm.totalGeneral = vm.totalGeneral.toFixed(2);
        }
        vm.isEdit = true;
    }

    function activateView() {
        vm.title = $filter('translate')('VIEW_PROFORMA');
        vm.proforma = proformaPrepService;
        vm.entidadId = vm.proforma.id;
        vm.entidad = "Proforma";
        vm.proforma.fecha = new Date(vm.proforma.fecha);
        vm.view = true;
        setTiposEntidades();
        vm.totalGeneral = _.reduce(vm.proforma.proformaDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);
        if (vm.proforma.ordenCompra.moneda.codigo == 'guaranies') {
            vm.totalGeneral = vm.totalGeneral.toFixed(0);
        } else {
            vm.totalGeneral = vm.totalGeneral.toFixed(2);
        }
    }

    function getMonedaLocal() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'moneda_local'
        }).value();
        vm.monedaLocal = ParametrosFactory.all(params);
    }

    function getParamDiferenciaMonedaLocal() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'maximo_diferencia_cabecera_detalle_moneda_local'
        }).value();
        vm.diferenciaMonedaLocal = ParametrosFactory.all(params);
    }

    function getParamDiferenciaMonedaExtranjera() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'maximo_diferencia_cabecera_detalle_moneda_extranjera'
        }).value();
        vm.diferenciaMonedaExtranjera = ParametrosFactory.all(params);
    }

    function changeOC() {
        OrdenesCompraFactory.get(vm.proforma.ordenCompra.id, "OrdenCompraImportacionList").$promise.then(function(response) {
            vm.proforma.ordenCompra = response;
            vm.proforma.proveedorOC = _.cloneDeep(vm.proforma.ordenCompra.proveedor);
            vm.proforma.monedaOC = _.cloneDeep(vm.proforma.ordenCompra.moneda);
            vm.proforma.proformaDetalle = _.cloneDeep(vm.proforma.ordenCompra.ordenCompraDetalle);
            _.forEach(vm.proforma.proformaDetalle, function(value) {
                delete value.ordenCompra;
                delete value.id;
                delete value.totalGravado5;
                delete value.totalGravado10;
                delete value.totalDescuento;
                delete value.porcentajeDescuento;
                //delete value.servicio;
                if (value.producto) {
                    value.tipo = { id: 1, nombre: "Producto" };
                }
                if (value.servicio) {
                    value.tipo = { id: 2, nombre: "Servicio" };
                }
                _.forEach(value.montosImponibles, function(data) {
                    delete data.id;
                    delete data.ordenCompraDetalle;
                    delete data.proformaDetalle;
                });
            });

            //vm.selectProducto = true;
            filterProductos();
            vm.totalGeneral = _.reduce(vm.proforma.proformaDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);

            if (vm.proforma.ordenCompra.moneda.codigo == 'guaranies') {
                vm.totalGeneral = vm.totalGeneral.toFixed(0);
            } else {
                vm.totalGeneral = vm.totalGeneral.toFixed(2);
            }
        });
    }

    function setTiposEntidades() {
        if (!vm.proforma || !vm.proforma.proformaDetalle) {
            return;
        }
        _.forEach(vm.proforma.proformaDetalle, function(detalle) {
            if (detalle.producto) {
                detalle.tipo = { id: 1, nombre: "Producto" };
            }
            if (detalle.servicio) {
                detalle.tipo = { id: 2, nombre: "Servicio" };
            }
        });
    }

    function mostrarTodosProductos() {
        if (vm.todosProductos) {
            if (vm.watcher) { vm.watcher(); }
            WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
                vm.watcher = watcher;
                vm.productos = ProductosFactory.allForSelect(locacionFilter);
                vm.opcionesProductosDt.staticFilter = locacionFilter;
            }, "locaciones.id");
        } else {
            filterProductos();
        }
    }

    function tipoDetalleSelected(detalle) {
        delete detalle.producto;
        delete detalle.servicio;
        delete detalle.unidadMedida;
    }

    function filterProductos() {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
                path: 'proveedores.codigo',
                equals: vm.proforma.proveedorOC.codigo
            },
            {
                path: 'itemCompra',
                like: 'true'
            }
        ]).value();

        if (vm.watcher) { vm.watcher(); }
        WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
            vm.watcher = watcher;
            vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter));
            vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter);
        }, "locaciones.id");
    }

    function agregarDetalle() {
        vm.proforma.proformaDetalle.push({ montosImponibles: [{}] });
        $timeout(function() {
            $scope.$broadcast('newItemAdded');
        }, 20);
    }

    function deleteDetail(elemento) {
        removeItemFromArray(elemento, vm.proforma.proformaDetalle);
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function(item) {
            return item === elemento;
        });
        vm.totalGeneral = _.reduce(vm.proforma.proformaDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);
        vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(2));
    }

    function calcularImpuesto(detalle, accordingTo) {
        if (accordingTo === "valor") {
            detalle.totalItemExento = detalle.totalItem - detalle.montosImponibles[0].valor;
        } else if (accordingTo === "exento") {
            detalle.montosImponibles[0].valor = detalle.totalItem - detalle.totalItemExento;
        }
    }

    function updateTotalGeneral() {
        vm.totalGeneral = _.reduce(vm.proforma.proformaDetalle, function(sum, detalle) {

            return sum + detalle.totalItem;
        }, 0);
        vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(2));
    }

    function updateImpuestoDetalle(detalle) {
        if (detalle.montosImponibles && detalle.montosImponibles[0]) {
            detalle.montosImponibles[0].valor = 0;
        }
        detalle.totalItemExento = detalle.cantidad * detalle.ultimoPrecio;
    }

    function calcularTotalDetalle(detalle) {
        if (detalle.cantidad == undefined) {
            detalle.totalItem = 0;
            return;
        }
        if (detalle.ultimoPrecio == undefined) {
            detalle.totalItem = 0;
            return;
        }

        detalle.totalItem = detalle.cantidad * detalle.ultimoPrecio;
        detalle.totalItem = parseFloat(detalle.totalItem.toFixed(2));
        updateImpuestoDetalle(detalle);
        updateTotalGeneral();
    }

    function totalItemChanged(detalle) {
        if (detalle.cantidad == undefined) {
            detalle.cantidad = 1;
        }

        detalle.ultimoPrecio = detalle.totalItem / detalle.cantidad;
        detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(5));
        updateImpuestoDetalle(detalle);
        updateTotalGeneral();
    }

    function changeProducto(detalle) {
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        detalle.cantidad = 0;
        detalle.ultimoPrecio = 0;
        detalle.totalItem = 0;
    }

    function changeServicio(detalle) {
        detalle.cantidad = 0;
        //detalle.totalDescuento = 0;
        //detalle.porcentajeDescuento = 0;
        detalle.ultimoPrecio = 0;
        detalle.totalItemExento = 0;
        detalle.totalItem = 0;
    }

    function searchProductoFilter(criteria) {
        return function(item) {
            if (!criteria) {
                return true;
            }

            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
        };
    }

    function submit() {
        vm.submited = true;
        if ($scope.ProformaForm.$valid) {
            if (validarMontos()) {
                $rootScope.isProcessing = true;
                vm.proforma.proformaDetalle = _.filter(vm.proforma.proformaDetalle, function(elem) { return elem.producto || elem.servicio; });
                submitProforma().then(function(response) {
                    ImportacionFactory.setIdImportacion(response.importacion.id);
                    ImportacionFactory.setCodigoImportacion(response.importacion.codigo);
                    ImportacionFactory.setImportacion(response.importacion);
                    $scope.$emit("updateImportacionStates");
                    $state.go('app.importaciones.proceso.proformas.list', { codigoImportacion: ImportacionFactory.getIdImportacion() });
                }, function(error) {
                    $rootScope.isProcessing = false;
                    var msg = $filter('translate')('ERROR_SAVING');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                });
            } else {
                notify({ message: $filter('translate')('MONTO_TOTAL_ERROR'), classes: 'alert-danger', position: 'right' });
            }
        }
    }

    function validarMontos() {
        var diferenciaPermitida = 0;
        if (vm.proforma.monedaOC.id == vm.monedaLocal[0].valorTipo.id) {
            diferenciaPermitida = vm.diferenciaMonedaLocal[0].valor;
        } else {
            diferenciaPermitida = vm.diferenciaMonedaExtranjera[0].valor;
        }
        var sumaTotal = 0;
        $.each(vm.proforma.proformaDetalle, function() {
            sumaTotal += this.totalItem;
        });
        var diff = Math.abs(sumaTotal - vm.proforma.montoTotal);
        if (vm.proforma.montoTotal === undefined || diff > diferenciaPermitida) {
            return false;
        }
        return true;
    }

    function submitProforma() {
        var resource = ProformaFactory.create(vm.proforma);
        _.forEach(resource.proformaDetalle, function(detalle) {
            delete detalle.tipo;
        });
        if (!vm.isEdit) {
            resource.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        }
        return ProformaFactory.save(resource);
    }

    function cancel() {
        $state.go('app.importaciones.proceso.proformas.list', { codigoImportacion: ImportacionFactory.getIdImportacion() });
    }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.importacion')
  .factory('EmbarqueFactory', EmbarqueFactory);

EmbarqueFactory.$inject = ['$resource', 'baseurl'];

function EmbarqueFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var Embarque = $resource( baseurl.getBaseUrl() + "/embarque/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params) {
    return Embarque.query(params);
  }

  function get(id, view) {
    return Embarque.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new Embarque(attrs);
  }

  function save(embarque) {
    return (embarque.id) ? embarque.$update() : embarque.$save();
  }

  function remove(embarque) {
    return embarque.$remove();
  }
}

'use strict';

angular.module('qualita.importacion')
    .controller('EmbarqueListCtrl', EmbarqueListCtrl);

EmbarqueListCtrl.$inject = ["AuthorizationService", "EmbarqueFactory", "TiposFactory", "ImportacionFactory", "filterFactory",
    "$log", "$modal", "$scope", "notify", "$state", "ImportacionLangFactory", "WatchLocacionChangeFactory", "$filter"
];

function EmbarqueListCtrl(AuthorizationService, EmbarqueFactory, TiposFactory, ImportacionFactory, filterFactory,
    $log, $modal, $scope, notify, $state, ImportacionLangFactory, WatchLocacionChangeFactory, $filter) {
    var vm = this;

    activate();

    function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations) {
            var importacion = ImportacionFactory.getImportacion();
            if (!importacion) {
                $state.go("app.main");
            }
            var isStateEnabled = ImportacionFactory.checkPermissionForState(2);
            if (!isStateEnabled) {
                console.log("No tiene permiso para revisar esta seccion");
                $state.go("app.main");
            }

            // filtro estático
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'importacion.codigo',
                equals: ImportacionFactory.getCodigoImportacion(),
            }]).value();

            var defaultColumnOrder = [ /*'id',*/ 'numero', 'via.descripcion', 'fechaEmbarque', 'fechaLlegada', 'puertoSalida', 'puertoLlegada', 'descripcion', 'proforma.numero'];

            vm.options = {
                'staticFilter': staticFilter,
                'resource': 'embarque',
                'view': 'EmbarqueList',
                'title': 'Embarques',
                'factory': EmbarqueFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': 'No se pudo borrar el embarque. Verifique si hay operaciones asociadas.',
                'columns': [
                    /*{'data': 'id', 'title': $filter('translate')('CODE'), 'class' : 'dt-center'},*/
                    { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-center'  },
                    { 'data': 'via.descripcion', 'title': $filter('translate')('VIA'), 'class': 'dt-center' },
                    { 'data': 'fechaEmbarque', 'title': $filter('translate')('FECHA_EMBARQUE'), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'fechaLlegada', 'title': $filter('translate')('FECHA_LLEGADA_ESTIMADA'), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'puertoSalida', 'title': $filter('translate')('PUERTO_SALIDA'), 'class': 'dt-center' },
                    { 'data': 'puertoLlegada', 'title': $filter('translate')('PUERTO_LLEGADA'), 'class': 'dt-center' },
                    { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION'), 'class': 'dt-center', visible: false },
                    //{'data': 'proforma.numero', 'title': $filter('translate')('PROFORMA'), 'class' : 'dt-center'}
                ],
                'hasOptions': true,
                'hideAddMenu': true,
                'extraMenuOptions': [{
                    'title': 'Nuevo',
                    'icon': 'glyphicon glyphicon-plus',
                    'showCondition': function() {
                        var permission = AuthorizationService.hasPermission('create_embarque') && ImportacionFactory.getImportacion().estado.codigo != "cerrada";
                        return permission;
                    },
                    'action': function() {
                        $state.go("app.importaciones.proceso.embarques.new");
                    }
                }],
                'hideEditMenu': true,
                'hideViewMenu': true,
                'hideRemoveMenu': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraRowOptions': [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar()}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        functionName: "editar",
                        functionDef: function(itemId) {
                            $state.go("app.importaciones.proceso.embarques.edit", { id: itemId });
                        },
                        conditionName: "canEditar",
                        conditionDef: function() {
                            return (AuthorizationService.hasPermission("update_embarque") && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar()}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        conditionName: "canBorrar",
                        conditionDef: function() {
                            return (AuthorizationService.hasPermission('delete_embarque') && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceBorrar1.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                var model = EmbarqueFactory.create({ id: itemId });
                                EmbarqueFactory.remove(model).then(function() {
                                    $scope.modalInstanceBorrar1.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                    $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                                    $scope.mensajeModal = error.data.message;
                                    var modalInstance = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };
                                    console.log("error al borrar: ");
                                    console.log(error);
                                });
                            };
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function(itemId) {
                            $state.go("app.importaciones.proceso.embarques.view", { id: itemId });
                        },
                        conditionName: "canVer",
                        conditionDef: function() {
                            return (AuthorizationService.hasPermission('index_embarque'));
                        }
                    }
                ]
            };
            WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");
        });
    }
}

'use strict';

angular.module('qualita.importacion')
    .controller('EmbarqueFormCtrl', EmbarqueFormCtrl);

EmbarqueFormCtrl.$inject = ["ImportacionFactory", "EmbarqueFactory", "embarquePrepService", "TiposFactory",
    "ProformaFactory", "filterFactory", "$scope", "notify", "$state", "$rootScope", "$timeout", "ProductosFactory",
    "ParametrosFactory", "ServicioFactory", "ImportacionLangFactory", "WatchLocacionChangeFactory", "UtilFactory",
    "$filter"
];

function EmbarqueFormCtrl(ImportacionFactory, EmbarqueFactory, embarquePrepService, TiposFactory,
    ProformaFactory, filterFactory, $scope, notify, $state, $rootScope, $timeout, ProductosFactory, ParametrosFactory,
    ServicioFactory, ImportacionLangFactory, WatchLocacionChangeFactory, UtilFactory, $filter) {
    var vm = this;

    vm.agregarDetalle = agregarDetalle;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.removeItemFromArray = removeItemFromArray;
    vm.changeProducto = changeProducto;
    vm.changeServicio = changeServicio;
    vm.calcularTotalDetalle = calcularTotalDetalle;
    vm.deleteDetail = deleteDetail;
    vm.totalItemChanged = totalItemChanged;
    vm.searchProductoFilter = searchProductoFilter;
    vm.controlFechaEmbarque = controlFechaEmbarque;
    vm.controlFechaLlegada = controlFechaLlegada;


    function agregarDetalle() {
        vm.embarque.embarqueDetalle.push({ montosImponibles: [{}] });
        $timeout(function() {
            $scope.$broadcast('newItemAdded');
        }, 20);
    }

    function deleteDetail(elemento) {
        removeItemFromArray(elemento, vm.embarque.embarqueDetalle);
        vm.totalGeneral = _.reduce(vm.embarque.embarqueDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);
    }

    activate();

    function activate() {
        WatchLocacionChangeFactory.killAllWatchers();
        ImportacionLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;
            $rootScope.isProcessing = false;

            var importacion = ImportacionFactory.getImportacion();
            vm.importacion = importacion;
            if (!importacion) {
                $state.go("app.main");
            }
            var isStateEnabled = ImportacionFactory.checkPermissionForState(3);
            if (!isStateEnabled) {
                console.log("No tiene permiso para revisar esta seccion");
                $state.go("app.main");
            }

            if ($state.is("app.importaciones.proceso.embarques.new")) {
                activateNew();
            } else if ($state.is("app.importaciones.proceso.embarques.edit")) {
                activateEdit();
            } else if ($state.is("app.importaciones.proceso.embarques.view")) {
                activateView();
            }

            vm.curDate = new Date();
            vm.curDate2 = new Date();
            TiposFactory.vias().then(function(data) {
                vm.via = data.data;
            });

            var staticFilterProforma = {};
            staticFilterProforma.search = filterFactory.and([{
                path: 'proveedorOC.id',
                equals: importacion.proveedor.id
            }]).value();

            if (vm.watcherProforma) { vm.watcherProforma(); }
            WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcherProforma) {
                vm.watcherProforma = watcherProforma;
                vm.proformasAux = ProformaFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProforma), 'ProformaImportacionList');
                vm.proformas = [];
                vm.proformasAux.$promise.then(function(response) {
                    _.forEach(response, function(proforma) {
                        var hasPendientes = false;
                        _.forEach(proforma.proformaDetalle, function(detalle) {
                            if (detalle.cantidad !== detalle.cantidadRecibida) {
                                hasPendientes = true;
                            }
                        });
                        if (hasPendientes === true) {
                            vm.proformas.push(proforma);
                        }
                    });
                });
            }, "locacion.id");

            vm.curDate = new Date();

            vm.factoryProductos = ProductosFactory;

            var renderEmptyData = function(data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
                'lineaProducto.descripcion', 'marca.descripcion'];

            vm.opcionesProductosDt = {
                'resource': 'productos',
                'title': 'Productos',
                'factory': ProductosFactory,
                'view': 'ProductosDatatableList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'codigo', 'title': $filter('translate')('ID'), visible: false },
                    { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
                    { 'data': 'claseProducto.descripcion', 'title': $filter('translate')('KIND') },
                    { 'data': 'estado.descripcion', 'title': $filter('translate')('DESCRIPTION'), 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
                    { 'data': 'lineaProducto.descripcion', 'title': $filter('translate')('LINE') },
                    { 'data': 'marca.descripcion', 'title': $filter('translate')('BRAND'), 'render': renderEmptyData },
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
            };

            if (vm.watcher) { vm.watcher(); }
            WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
                vm.watcher = watcher;
                vm.productos = ProductosFactory.allForSelect(locacionFilter);
                vm.opcionesProductosDt.staticFilter = locacionFilter;
            }, "locaciones.id");

            vm.tiposDetalles = [{ id: 1, nombre: "Producto" }, { id: 2, nombre: "Servicio" }];

            getMonedaLocal();
            getParamDiferenciaMonedaLocal();
            getParamDiferenciaMonedaExtranjera();

            var filtroServicios = {};
            filtroServicios.search = filterFactory.and([{
                path: 'compra',
                equals: true
            }]).value();
            vm.servicios = ServicioFactory.all(filtroServicios);
            TiposFactory.tipoCostoDespacho().then(function(data) {
                vm.tiposCostoDespacho = data.data;
            });

        });
    }

    function activateNew() {
        var isClosed = vm.importacion.estado.codigo === "cerrada";
        if (isClosed) {
            $state.go("app.main");
        }
        vm.title = $filter('translate')('NEW_EMBARQUE');
        vm.embarque = {
            embarqueDetalle: [{}],
            importacion: ImportacionFactory.getIdImportacion()
        };
        $timeout(function() {
            $scope.$broadcast('formOpened');
        }, 20);
        vm.totalGeneral = 0;
    }

    function activateEdit() {
        var isClosed = vm.importacion.estado.codigo === "cerrada";
        if (isClosed) {
            $state.go("app.main");
        }
        vm.title = $filter('translate')('EDIT_EMBARQUE');
        vm.embarque = embarquePrepService;
        vm.entidad = "Embarque";
        vm.entidadId = vm.embarque.id;
        vm.embarque.fechaEmbarque = new Date(vm.embarque.fechaEmbarque);
        vm.embarque.fechaLlegada = new Date(vm.embarque.fechaLlegada);
        $timeout(function() {
            $scope.$broadcast('formOpened');
        }, 20);
        setTiposEntidades();
        vm.totalGeneral = _.reduce(vm.embarque.embarqueDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);
        vm.isEdit = true;
        updateTotalGeneral();
    }

    function setTiposEntidades() {
        if (!vm.embarque || !vm.embarque.embarqueDetalle) {
            return;
        }
        _.forEach(vm.embarque.embarqueDetalle, function(detalle) {
            if (detalle.producto) {
                detalle.tipo = { id: 1, nombre: "Producto" };
            }
            if (detalle.servicio) {
                detalle.tipo = { id: 2, nombre: "Servicio" };
            }

        });
    }

    function activateView() {
        vm.title = $filter('translate')('VIEW_EMBARQUE');
        vm.embarque = embarquePrepService;
        vm.entidad = "Embarque";
        vm.entidadId = vm.embarque.id;
        vm.view = true;
        setTiposEntidades();
        vm.embarque.fechaEmbarque = new Date(vm.embarque.fechaEmbarque);
        vm.embarque.fechaLlegada = new Date(vm.embarque.fechaLlegada);
        vm.totalGeneral = _.reduce(vm.embarque.embarqueDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);
        updateTotalGeneral();
    }

    function getMonedaLocal() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'moneda_local'
        }).value();
        vm.monedaLocal = ParametrosFactory.all(params);
    }

    function getParamDiferenciaMonedaLocal() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'maximo_diferencia_cabecera_detalle_moneda_local'
        }).value();
        vm.diferenciaMonedaLocal = ParametrosFactory.all(params);
    }

    function getParamDiferenciaMonedaExtranjera() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'maximo_diferencia_cabecera_detalle_moneda_extranjera'
        }).value();
        vm.diferenciaMonedaExtranjera = ParametrosFactory.all(params);
    }

    $scope.onSelectedProforma = function() {
        var i = 0;
        var len = vm.embarque.proforma.length;
        vm.embarque.embarqueDetalle = [];

        //vm.proformaNumero = [];

        var listaCllonada = undefined;
        _.forEach(vm.embarque.proforma, function(proforma) {
            vm.embarque.puertoLlegada = vm.embarque.puertoLlegada || proforma.puertoLlegada;

            if (proforma.proformaDetalle != undefined /*&& proforma.proformaDetalle[0].id==undefined*/ ) {

                // ProformaFactory.get(proforma.id, "ProformaImportacionList").$promise.then(function(response) {
                    // proforma = response;

                    listaCllonada = _.cloneDeep(proforma.proformaDetalle);
                    _.forEach(listaCllonada, function(value) {
                        delete value.proforma;

                        _.forEach(proforma.proformaDetalle, function(value2) {
                            if (value.id === value2.id) {
                                value.proformaDetalle = value2;

                                value.proformaDetalle.proformaNumero = null;
                                value.proformaDetalle.proformaNumero = proforma.numero;

                                value.proformaDetalle.proforma = {'numero':proforma.numero};
                            }

                        });
                        delete value.id;
                        //delete value.servicio;

                        if (value.producto) {
                            value.tipo = { id: 1, nombre: "Producto" };
                        }
                        if (value.servicio) {
                            value.tipo = { id: 2, nombre: "Servicio" };
                        }
                        _.forEach(value.montosImponibles, function(data) {
                            delete data.id;
                            delete data.ordenCompraDetalle;
                            delete data.proformaDetalle;
                        });
                        if (value.cantidad != value.cantidadRecibida) {
                            delete value.cantidadRecibida;
                            vm.embarque.embarqueDetalle.push(value);
                        }

                    });
                    vm.totalGeneral = _.reduce(vm.embarque.embarqueDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);
                    if (proforma.monedaOC.codigo == 'guaranies') {
                        vm.totalGeneral = vm.totalGeneral.toFixed(0);
                    } else {
                        vm.totalGeneral = vm.totalGeneral.toFixed(2);
                    }

                // });

            } else {
                var listaCllonada = _.cloneDeep(proforma.proformaDetalle);
                _.forEach(listaCllonada, function(value) {
                    if (value.cantidad != value.cantidadRecibida) {
                        vm.embarque.embarqueDetalle.push(value);
                    }
                });
                vm.totalGeneral = _.reduce(vm.embarque.embarqueDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);
                if (proforma.monedaOC.codigo == 'guaranies') {
                    vm.totalGeneral = vm.totalGeneral.toFixed(0);
                } else {
                    vm.totalGeneral = vm.totalGeneral.toFixed(2);
                }
            }
        });


    };

    function mostrarTodosProductos() {
        if (vm.todosProductos) {
            if (vm.watcher) { vm.watcher(); }
            WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
                vm.watcher = watcher;
                vm.productos = ProductosFactory.allForSelect(locacionFilter);
                vm.opcionesProductosDt.staticFilter = locacionFilter;
            }, "locaciones.id");
        } else {
            filterProductos();
        }
    }

    function tipoDetalleSelected(detalle) {
        delete detalle.producto;
        delete detalle.servicio;
        delete detalle.unidadMedida;
    }

    function filterProductos() {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
                path: 'proveedores.codigo',
                equals: vm.embarque.proforma.proveedorOC.codigo
            },
            {
                path: 'itemCompra',
                like: 'true'
            }
        ]).value();

        if (vm.watcher) { vm.watcher(); }
        WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
            vm.watcher = watcher;
            vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter));
            vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter);
        }, "locaciones.id");
    }

    function calcularImpuesto(detalle, accordingTo) {
        if (accordingTo === "valor") {
            detalle.totalItemExento = detalle.totalItem - detalle.montosImponibles[0].valor;
        } else if (accordingTo === "exento") {
            detalle.montosImponibles[0].valor = detalle.totalItem - detalle.totalItemExento;
        }
    }

    function updateImpuestoDetalle(detalle) {
        detalle.totalItemExento = detalle.cantidad * detalle.ultimoPrecio;
        if (detalle.montosImponibles && detalle.montosImponibles[0]) {
            detalle.montosImponibles[0].valor = 0;
        }
    }

    function updateTotalGeneral() {
        vm.totalGeneral = _.reduce(vm.embarque.embarqueDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);
        vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(2));
    }

    function calcularTotalDetalle(detalle) {
        if (detalle.cantidad == undefined) {
            detalle.totalItem = 0;
            return;
        }
        if (detalle.ultimoPrecio == undefined) {
            detalle.totalItem = 0;
            return;
        }
        detalle.totalItem = detalle.cantidad * detalle.ultimoPrecio;
        detalle.totalItem = parseFloat(detalle.totalItem.toFixed(2));
        updateImpuestoDetalle(detalle);
        updateTotalGeneral();
    }

    function totalItemChanged(detalle) {
        if (detalle.cantidad == undefined) {
            detalle.cantidad = 1;
        }

        detalle.ultimoPrecio = detalle.totalItem / detalle.cantidad;
        detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(5));
        updateImpuestoDetalle(detalle);
        updateTotalGeneral();
    }

    function changeProducto(detalle) {
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        detalle.cantidad = 0;
        detalle.ultimoPrecio = 0;
        detalle.totalItem = 0;
    }

    function changeServicio(detalle) {
        detalle.cantidad = 0;
        //detalle.totalDescuento = 0;
        //detalle.porcentajeDescuento = 0;
        detalle.ultimoPrecio = 0;
        detalle.totalItemExento = 0;
        detalle.totalItem = 0;
    }

    function searchProductoFilter(criteria) {
        return function(item) {
            if (!criteria) {
                return true;
            }

            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
        };
    }

    function submit() {
        vm.submited = true;
        if ($scope.EmbarqueForm.$valid) {
            $rootScope.isProcessing = true;
            vm.embarque.embarqueDetalle = _.filter(vm.embarque.embarqueDetalle, function(elem) { return elem.producto || elem.servicio; });
            submitEmbarque().then(function(response) {
                ImportacionFactory.setIdImportacion(response.importacion.id);
                ImportacionFactory.setCodigoImportacion(response.importacion.codigo);
                ImportacionFactory.setImportacion(response.importacion);
                console.log("emitiendo evento updateImportacionStates");
                $scope.$emit("updateImportacionStates");
                $state.go('app.importaciones.proceso.embarques.list', { codigoImportacion: ImportacionFactory.getIdImportacion() });
            }, function(error) {
                $rootScope.isProcessing = false;
                console.log("Error al persistir entidad: ");
                console.log(error);
                var msg = $filter('translate')('ERROR_SAVING');
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            });
        }
    }

    function validarMontos() {
        var diferenciaPermitida = 0;
        if (vm.embarque.monedaOC.id == vm.monedaLocal[0].valorTipo.id) {
            diferenciaPermitida = vm.diferenciaMonedaLocal[0].valor;
        } else {
            diferenciaPermitida = vm.diferenciaMonedaExtranjera[0].valor;
        }
        var sumaTotal = 0;
        $.each(vm.embarque.embarqueDetalle, function() {
            sumaTotal += this.totalItem;
        });
        var diff = Math.abs(sumaTotal - vm.embarque.montoTotal);
        if (vm.embarque.montoTotal === undefined || diff > diferenciaPermitida) {
            return false;
        }
        return true;
    }

    function submitEmbarque() {
        var resource = EmbarqueFactory.create(vm.embarque);
        _.forEach(resource.embarqueDetalle, function(detalle) {
            delete detalle.tipo;
        });
        if (!vm.isEdit) {
            resource.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        }
        return EmbarqueFactory.save(resource);
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function(item) {
            return item === elemento;
        });
    }

    function cancel() {
        $state.go('app.importaciones.proceso.embarques.list');
    }

    function changeProducto(detalle) {
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        detalle.cantidad = 0;
        detalle.ultimoPrecio = 0;
        detalle.totalItem = 0;
    }

    function changeServicio(detalle) {
        detalle.cantidad = 0;
        //detalle.totalDescuento = 0;
        //detalle.porcentajeDescuento = 0;
        detalle.ultimoPrecio = 0;
        detalle.totalItemExento = 0;
        detalle.totalItem = 0;
    }

    function controlFechaEmbarque(fechaEmbarque) {
        if(!validacionFechas()){
            vm.embarque.fechaEmbarque = undefined;
            var msg = "La fecha de embarque no puede ser mayor a la fecha de llegada estimada";
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
        };
    }

    function controlFechaLlegada(fechaLlegada) {
        if(!validacionFechas()){
            vm.embarque.fechaLlegada = undefined;
            var msg = "La fecha de Llegada estimada no puede ser menor a la fecha de embarque";
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
        };
        
    }

    function validacionFechas() {
        if(vm.embarque.fechaEmbarque != undefined && vm.embarque.fechaLlegada != undefined){
            if(vm.embarque.fechaEmbarque > vm.embarque.fechaLlegada){
                return false;
             }
        }
        return true;
    }
}

angular.module('qualita.importacion')
  .factory('SolicitudFactory', SolicitudFactory);

SolicitudFactory.$inject = ['$resource', 'baseurl','$http'];

function SolicitudFactory($resource, baseurl,$http) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    aprobar: aprobar,
    rechazar: rechazar,
    sendMail: sendMail
  };

  var Solicitud = $resource( baseurl.getBaseUrl() + "/solicitud_pago/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    },
    sendMail: {
      method: 'POST',
      url: baseurl.getBaseUrl() + "/mails/solicitud_pago",
    }
  });

  return service;

  function all(params) {
    return Solicitud.query(params);
  }

  function get(id, view) {
    return Solicitud.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new Solicitud(attrs);
  }

  function save(solicitud) {
    return (solicitud.id) ? solicitud.$update() : solicitud.$save();
  }

  function remove(solicitud) {
    return solicitud.$remove();
  }

  function sendMail(solicitud) {
    return solicitud.$sendMail();
  }

  function aprobar(id){
    return $http({
              url: baseurl.getBaseUrl() + '/solicitud_pago/aprobar/'+id,
              method: "PUT"
           });
  }

  function rechazar(id){
    return $http({
              url: baseurl.getBaseUrl() + '/solicitud_pago/rechazar/'+id,
              method: "PUT"
           });
  }


}

'use strict';

angular.module('qualita.importacion')
    .controller('SolicitudPagoListCtrl', SolicitudPagoListCtrl);

SolicitudPagoListCtrl.$inject = ["AuthorizationService", "SolicitudFactory", "ImportacionFactory", "$modal", "$scope",
    "$state", "filterFactory", "ImportacionLangFactory", "WatchLocacionChangeFactory", "$filter"
];

function SolicitudPagoListCtrl(AuthorizationService, SolicitudFactory, ImportacionFactory, $modal, $scope, $state,
    filterFactory, ImportacionLangFactory, WatchLocacionChangeFactory, $filter) {
    var vm = this;

    activate();

    function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;
            vm.canUpdate = AuthorizationService.hasPermission('update_solicitud_pago');
            var importacion = ImportacionFactory.getImportacion();
            if (!importacion) {
                $state.go("app.main");
            }
            var isStateEnabled = ImportacionFactory.checkPermissionForState(2);
            if (!isStateEnabled) {
                console.log("No tiene permiso para revisar esta seccion");
                $state.go("app.main");
            }

            // filtro estático
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'importacion.codigo',
                equals: ImportacionFactory.getCodigoImportacion(),
            }]).value();

            var monedaRender = function(data, type, row) {
                if (data) {
                    var moneda = "Gs. ";
                    if (row.moneda.codigo === 'dolares') {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    }
                    return moneda + data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return '';
                }
            };

            var defaultColumnOrder = ['id', 'ordenCompra.numero', 'proforma.numero', 'proveedor.nombre', 'fecha', 'estado.descripcion', 'cantidadPagos',
                'periodoPagos', 'entregaInicial', 'ordenCompra.fechaVencimiento', 'descripcion', 'moneda.descripcion', 'monto', 'usuario.username'
            ];

            vm.options = {
                'staticFilter': staticFilter,
                'resource': 'solicitud_pago',
                'view': 'SolicitudPagoList',
                'title': $filter('translate')('SOLICITUDES_PAGO'),
                'factory': SolicitudFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('SOLICITUD_PAGO_DELETE_FAILED'),
                'columns': [

                    { 'data': 'ordenCompra.numero', 'title': $filter('translate')('ORDEN_COMPRA'), 'class': 'dt-center'  },
                    { 'data': 'proforma.numero', 'title': $filter('translate')('PROFORMA'), 'class': 'dt-center'  },
                    { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_DESPACHANTE'), 'class': 'dt-center' },
                    { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/tipo/estado_solicitud_pago' },
                    { 'data': 'cantidadPagos', 'title': $filter('translate')('PROFORMA_CANTIDAD_PAGOS'), 'class': 'dt-center', visible: false  },
                    { 'data': 'periodoPagos', 'title': $filter('translate')('PROFORMA_PERIODO_PAGOS'), 'class': 'dt-center', visible: false  },
                    { 'data': 'entregaInicial', 'title': $filter('translate')('PROFORMA_ENTREGA_INICIAL'), 'class': 'dt-center', visible: false, 'render': monedaRender  },
                    //{'data': 'ordenCompra.fechaVencimiento', 'title': $filter('translate')('FECHA_VENCIMIENTO'), 'class' : 'dt-center'},
                    { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION'), 'class': 'dt-center', visible: false },
                    { 'data': 'moneda.descripcion', 'title': $filter('translate')('CURRENCY'), 'class': 'dt-center', visible: false },
                    { 'data': 'monto', 'title': $filter('translate')('MONTO'), 'render': monedaRender, 'class': 'dt-center'  },
                    { 'data': 'usuario.username', 'title': $filter('translate')('USER'), 'class': 'dt-center', visible: false },

                ],
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions': [{
                    'title': 'Nuevo',
                    'icon': 'glyphicon glyphicon-plus',
                    'showCondition': function() {
                        var permission = AuthorizationService.hasPermission('create_solicitud_pago') && ImportacionFactory.getImportacion().estado.codigo != "cerrada";
                        return permission;
                    },
                    'action': function() {
                        $state.go("app.importaciones.proceso.solicitudes.new");
                    }
                }, ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraRowOptions': [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canEditar",
                        conditionDef: function(estado) {
                            return (vm.canUpdate && estado.codigo == "sp_ingresada" && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                        },
                        functionName: "editar",
                        functionDef: function(itemId) {
                            $state.go("app.importaciones.proceso.solicitudes.edit", { id: itemId });
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canBorrar",
                        conditionDef: function(estado) {
                            return (AuthorizationService.hasPermission('delete_solicitud_pago') && (estado.codigo == "sp_ingresada" || estado.codigo == "sp_rechazada") && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceBorrar1.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                var model = SolicitudFactory.create({ id: itemId });
                                SolicitudFactory.remove(model).then(function() {
                                    $scope.modalInstanceBorrar1.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                    $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                                    $scope.mensajeModal = $scope.options.failedDeleteError;
                                    var modalInstance = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };
                                    console.log("error al borrar: ");
                                    console.log(error);
                                });
                            };
                        }
                    },
                    {
                        templateToRender: "<button title='Aprobar' class='btn btn-primary btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataId%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ok'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canAprobar",
                        conditionDef: function(estado) {
                            return (AuthorizationService.hasPermission('aprobar_solicitud_pago') && estado.codigo == "sp_ingresada" && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                        },
                        functionName: "aprobar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('CONFIRMACION_APROBACION');
                            $scope.mensajeModal = $filter('translate')('APROBAR_SOLICITUD_WARNING');
                            $scope.modalInstanceAprobar = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceAprobar.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                SolicitudFactory.aprobar(itemId).then(function() {
                                    $scope.modalInstanceAprobar.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    console.log("error al aprobar ");
                                    console.log(error);
                                });
                            };
                        }
                    },
                    {
                        templateToRender: "<button title='Rechazar' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='rechazar(<%=dataId%>)' ng-class='{ hidden : !canRechazar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canRechazar",
                        conditionDef: function(estado) {
                            return (AuthorizationService.hasPermission('rechazar_solicitud_pago') && (estado.codigo == "sp_ingresada" || estado.codigo == "sp_aprobada") && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                        },
                        functionName: "rechazar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('CONFIRMACION_RECHAZO');
                            $scope.mensajeModal = $filter('translate')('RECHAZAR_SOLICITUD_WARNING');
                            $scope.modalInstanceRechazar = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceRechazar.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                SolicitudFactory.rechazar(itemId).then(function() {
                                    $scope.modalInstanceRechazar.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    console.log("error al rechazar ");
                                    console.log(error);
                                });
                            };
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function(itemId) {
                            $state.go("app.importaciones.proceso.solicitudes.view", { id: itemId });
                        },
                        conditionName: "canVer",
                        conditionDef: function() {
                            return (AuthorizationService.hasPermission('index_solicitud_pago'));
                        },
                    }
                ],
            };
            WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");
        });
    }

}

'use strict';

angular.module('qualita.importacion')
  .controller('SolicitudPagoFormCtrl', SolicitudPagoFormCtrl);

SolicitudPagoFormCtrl.$inject = ["UsuariosFactory", "SolicitudFactory", "solicitudPrepService", "OrdenesCompraFactory",
"ProveedoresFactory", "AccesosSistemaFactory", "ImportacionFactory", "filterFactory", "$log", "$modal", "$scope", "notify",
  "$state", "$rootScope", "$timeout", "ModelTrimmer", "ProformaFactory", "ImportacionLangFactory", "TiposFactory", "WatchLocacionChangeFactory",
  "UtilFactory", "$filter"];

function SolicitudPagoFormCtrl(UsuariosFactory, SolicitudFactory, solicitudPrepService,
  OrdenesCompraFactory, ProveedoresFactory, AccesosSistemaFactory, ImportacionFactory,
  filterFactory, $log, $modal, $scope, notify, $state, $rootScope, $timeout, ModelTrimmer,
  ProformaFactory, ImportacionLangFactory, TiposFactory, WatchLocacionChangeFactory, UtilFactory,
  $filter) {
  var vm = this;

  vm.submit = submit;
  vm.cancel = cancel;

  vm.ordenCompra = {};
  activate();

  $rootScope.isProcessing = false;

  function activate() {
    ImportacionLangFactory.getTranslations().then(function(translations) {
      vm.translations = translations;
      var importacion = ImportacionFactory.getImportacion();
      vm.importacion = importacion;
      if(!importacion) {
        $state.go("app.main");
      }
      var isStateEnabled = ImportacionFactory.checkPermissionForState(2);
      if(!isStateEnabled) {
        console.log("No tiene permiso para revisar esta seccion");
        $state.go("app.main");
      }

      vm.curDate = new Date();

      var paramsProv = {};
      paramsProv.search = filterFactory.single({
        path: 'activo',
        equals: true
      }).value();

      vm.proveedores = ProveedoresFactory.all(paramsProv);
      TiposFactory.monedas().then(function (data) {
        vm.monedas=data.data;
      });
      //vm.usuarios = AccesosSistemaFactory.all();
      vm.usuarios = [];
      vm.accesos = [];
      AccesosSistemaFactory.all({view: "AccesoSistemaList"}).$promise.then(function(response) {
        _.forEach(response, function(acceso){
          vm.usuarios.push(acceso.usuario);
          vm.accesos.push(acceso);
        });
      });
      vm.usuariosNotificables = [];
      if($state.is("app.importaciones.proceso.solicitudes.new")) {
        activateNew();
      } else if($state.is("app.importaciones.proceso.solicitudes.edit")) {
        activateEdit();
      } else if($state.is("app.importaciones.proceso.solicitudes.view")) {
        activateView();
      }

      var staticFilterOC = {};
      staticFilterOC.search = filterFactory.and([{
        path: 'importaciones.codigo',
        equals: ImportacionFactory.getCodigoImportacion(),
      }]).value();
      if(vm.watchOC) { vm.watcherOC(); }
      WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
        vm.watcherOC = watcher;
        OrdenesCompraFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterOC, "OrdenCompraImportacionList")).$promise.then(function(response) {
          vm.ordenesCompra = _.filter(response,function(o) {
          if(o.estado.codigo == "ocl_emitida"){
            return true;
          } else {
            return false;
          }
         });
        });
      }, "locacion.id");
    });
  }

  $scope.onSelectedOC = function () {
    vm.solicitud.proveedor=vm.solicitud.ordenCompra.proveedor;
    vm.solicitud.moneda=vm.solicitud.ordenCompra.moneda;
    // traer todas las proformas asociadas a esta orden de compra
    vm.proformas = _.map(vm.solicitud.ordenCompra.proformas, function(proforma) {
      return ProformaFactory.get(proforma.id, "BaseList");
    });
  };

  $scope.onSelectedProforma = function () {
    vm.solicitud.cantidadPagos=vm.solicitud.proforma.cantidadPagos;
    vm.solicitud.periodoPagos=vm.solicitud.proforma.periodoPagos;
    vm.solicitud.entregaInicial=vm.solicitud.proforma.entregaInicial;
    $scope.onChangePeriodo();
  };

  $scope.onChangePeriodo = function () {
    vm.primerVto = moment().add(vm.solicitud.periodoPagos, 'days').format("DD/MM/YYYY");
  }

  function addUsuarioParam(solicitud) {
    $rootScope.$watch('AuthParams.accesoSistema', function(newValue, oldValue) {
      if(newValue != undefined) {
        var usuario = $rootScope.AuthParams.accesoSistema.usuario;
        if(!usuario.id) {
         usuario = UsuariosFactory.get($rootScope.AuthParams.accesoSistema.usuario);
        }
        vm.solicitud.usuario = usuario;
      }
    });
  }

  function activateNew() {
    var isClosed = vm.importacion.estado.codigo === "cerrada";
        if(isClosed) {
            $state.go("app.main");
        }
    vm.title = $filter('translate')('NEW_SOLICITUD_PAGO');
    vm.solicitud = {
      estado: {id:96},
      importacion: ImportacionFactory.getIdImportacion()
    };
    addUsuarioParam(vm.solicitud);
    $timeout(function(){
      $scope.$broadcast('newItemAdded');
    }, 20);
  }

  function activateEdit() {
    var isClosed = vm.importacion.estado.codigo === "cerrada";
        if(isClosed) {
            $state.go("app.main");
        }
    vm.title = $filter('translate')('EDIT_SOLICITUD_PAGO');
    vm.solicitud = solicitudPrepService;
    //addUsuarioParam(vm.solicitud);
    vm.solicitud.fecha = new Date(vm.solicitud.fecha);
    _.forEach(vm.solicitud.usuariosNotificables, function(acceso) {
      vm.usuariosNotificables.push(acceso.usuario);
    });
    vm.entity = "SolicitudPago";
    vm.entityId = vm.solicitud.id;
    $timeout(function(){
      $scope.$broadcast('newItemAdded');
    }, 20);
    vm.primerVto = moment().add(vm.solicitud.periodoPagos, 'days').format("DD/MM/YYYY");
    vm.isEdit = true;
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_SOLICITUD_PAGO');
    vm.solicitud = solicitudPrepService;
    //addUsuarioParam(vm.solicitud);
    vm.solicitud.fecha = new Date(vm.solicitud.fecha);
    _.forEach(vm.solicitud.usuariosNotificables, function(acceso) {
      vm.usuariosNotificables.push(acceso.usuario);
    });
    vm.entity = "SolicitudPago";
    vm.entityId = vm.solicitud.id;
    vm.primerVto = moment().add(vm.solicitud.periodoPagos, 'days').format("DD/MM/YYYY");
    vm.view = true;
  }

  function submit() {
    vm.submited = true;
    if($scope.SolicitudForm.$valid) {
      if(vm.solicitud.proforma.montoTotal < vm.solicitud.monto) {
        var msg = $filter('translate')('MONTO_MAYOR_A_PROFROMA_ERROR');;
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
        return;
      }
      $rootScope.isProcessing = true;
      vm.solicitud.usuariosNotificables = UtilFactory.getAccesosFromUsuarios(vm.accesos, vm.usuariosNotificables);
      submitSolicitudPago().then(function(response) {
        //console.log(response.importacion);
        ImportacionFactory.setIdImportacion(response.importacion.id);
        ImportacionFactory.setCodigoImportacion(response.importacion.codigo);
        ImportacionFactory.setImportacion(response.importacion);
        console.log("emitiendo evento updateImportacionStates");
        $scope.$emit("updateImportacionStates");
        $state.go('app.importaciones.proceso.solicitudes.list', {codigoImportacion: ImportacionFactory.getIdImportacion()});
        return response;
      }, function(error) {
        $rootScope.isProcessing = false;
        console.log("Error al persistir entidad: ");
        console.log(error);
        var msg = "Error al guardar la Solicitud";
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
      });
    }
  }

  function submitSolicitudPago() {
    //var trimmedModel = ModelTrimmer.trimDetails(vm.solicitud);
    var resource = SolicitudFactory.create(vm.solicitud);
    if(!vm.isEdit) {
      resource.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
    }
    return SolicitudFactory.save(resource);
  }

  function cancel() {
    $state.go('app.importaciones.proceso.solicitudes.list', {codigoImportacion: ImportacionFactory.getIdImportacion()});
  }
}

'use strict';

angular.module('qualita.importacion')
    .controller('RecepcionImportacionListCtrl', RecepcionImportacionListCtrl);

RecepcionImportacionListCtrl.$inject = ["AuthorizationService", "RecepcionFactory",
    "ImportacionFactory", "ReportTicketFactory", "$modal", "$scope", "$state",
    "filterFactory", "ParametrosFactory", "$window", "ImportacionLangFactory", "WatchLocacionChangeFactory", "notify",
    "$filter", "PeriodoAdministrativoFactory", "$q"
];

function RecepcionImportacionListCtrl(AuthorizationService, RecepcionFactory,
    ImportacionFactory, ReportTicketFactory, $modal, $scope, $state, filterFactory, ParametrosFactory,
    $window, ImportacionLangFactory, WatchLocacionChangeFactory, notify, $filter, PeriodoAdministrativoFactory,$q) {
    var vm = this;
    var flujoAbierto;

    ParametrosFactory.getByCodigo('confirmacion_recepcion_importacion').then(function (response) {
      flujoAbierto = response.valorTipo.codigo === "si";
    });
    activate();

    function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations) {
            var importacion = ImportacionFactory.getImportacion();
            if (!importacion) {
                $state.go("app.main");
            }
            // filtro estático
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'embarque.importacion.codigo',
                equals: ImportacionFactory.getCodigoImportacion(),
            }]).value();

            var defaultColumnOrder = ['id', 'tipoRecepcion.descripcion', 'tipoRecepcion.almacen.descripcion', 'estadoRecepcion.descripcion',
                'fecha', 'embarque.numero', 'proyecto.nombre'
            ];
            vm.options = {
                'staticFilter': staticFilter,
                'resource': 'recepcion',
                'view': 'RecepcionList',
                'title': 'Recepciones de Importación',
                'factory': RecepcionFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': 'No se pudo borrar la recepción. Verifique si hay operaciones asociadas.',
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('NUMBER'), 'class': 'dt-center'  },
                    { 'data': 'embarque.numero', 'title': $filter('translate')('EMBARQUE'), 'class': 'dt-center', visible: false  },
                    { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'tipoRecepcion.descripcion', 'title': $filter('translate')('TIPO_RECEPCION'), 'class': 'dt-center' },
                    { 'data': 'almacen.descripcion', 'title': $filter('translate')('ALMACEN'), 'class': 'dt-center' },

                    { 'data': 'estadoRecepcion.descripcion', 'title': $filter('translate')('STATUS'), 'class': 'dt-center' },
                    { 'data': 'proyecto.nombre', 'title': $filter('translate')('PROYECTO'), 'class': 'dt-center', 'renderWith': 'emptyRender'}
                ],
                'hideAddMenu': true,
                'extraMenuOptions': [{
                    'title': 'Nuevo',
                    'icon': 'glyphicon glyphicon-plus',
                    'showCondition': function() {
                        return AuthorizationService.hasPermission('create_recepcion') && ImportacionFactory.getImportacion().estado.codigo != "cerrada";
                    },
                    'action': function() {
                        checkPeriodoAdministrativo(null).then(function(resultado) {
                            if(resultado == true){
                                $state.go("app.importaciones.proceso.recepcionimportacion.new");
                            }else if(resultado == false){
                                var msg= $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        });
                    }
                }, ],
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraRowOptions': [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        functionName: "editar",
                        functionDef: function(itemId) {
                            $state.go("app.importaciones.proceso.recepcionimportacion.edit", { id: itemId });
                        },
                        conditionName: "canEditar",
                        customAttribute: 'estadoRecepcion',
                        conditionDef: function(estado) {
                            return (AuthorizationService.hasPermission("update_recepcion") && estado.codigo == "er_ingresado" && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        conditionName: "canBorrar",
                        customAttribute: 'estadoRecepcion',
                        conditionDef: function(estado) {
                            return (AuthorizationService.hasPermission('delete_recepcion') && estado.codigo == "er_ingresado" && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceBorrar1.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                var model = RecepcionFactory.create({ id: itemId });
                                RecepcionFactory.remove(model).then(function() {
                                    $scope.modalInstanceBorrar1.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                    $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                                    $scope.mensajeModal = vm.options.failedDeleteError;
                                    var modalInstance = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };
                                    console.log("error al borrar: ");
                                    console.log(error);
                                });
                            };
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function(itemId) {
                            $state.go("app.importaciones.proceso.recepcionimportacion.view", { id: itemId });
                        },
                        conditionName: "canVer",
                        conditionDef: function() {
                            return (AuthorizationService.hasPermission('index_recepcion'));
                        },
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('IMPRIMIR_RECEPCION') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='imprimir(<%=dataId%>)' ng-class='{ hidden : !canImprimir()}'> <span class='glyphicon glyphicon-file'></span> </button>",
                        functionName: "imprimir",
                        conditionName: "canImprimir",
                        conditionDef: function(estado) {
                            return AuthorizationService.hasPermission('update_recepcion');
                        },
                        functionDef: function(itemId) {
                            var filters = {};
                            var filtersArr = [];
                            filtersArr.push('idrecepcion=' + itemId);
                            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('recepcion', 'pdf', filtersArr);
                            $window.open(vm.pdfDownloadURL, '_blank');

                            $state.go("app.importaciones.proceso.recepcionimportacion.list", { id: itemId }, { reload: true });
                        }
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('GENERAR_ETIQUETAS') + "' class='btn btn-primary btn-dt' style='margin-right: 5px;' ng-click='generarEtiquetas(<%=dataId%>)' ng-class='{ hidden : !canGenerarEtiquetas(<%=dataId%>)}'> <span class='glyphicon glyphicon-barcode'></span> </button>",
                        functionName: "generarEtiquetas",
                        conditionName: "canGenerarEtiquetas",
                        conditionDef: function(itemId) {
                            return AuthorizationService.hasPermission('index_recepcion');
                        },
                        functionDef: function(itemId) {
                            RecepcionFactory.get(itemId).$promise.then(function(recepcion) {
                                if (recepcion.recepcionDetalle.length > 0) {
                                    RecepcionFactory.generarEtiquetas(itemId).then(function(data) {
                                        //var blob = new Blob([ data ], { type : 'text/plain' });
                                        //$scope.url = (window.URL || window.webkitURL).createObjectURL(blob);
                                        console.log(data);
                                        if (data.data && data.data != "") {
                                            $scope.saveToPc(data.data, "etiquetas.txt");
                                        } else {
                                            var msg = $filter('translate')('GENERAR_ETIQUETAS_ERROR_1');
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    }, function(error) {
                                        notify({ message: error, classes: 'alert-danger', position: 'right' });
                                    });
                                } else {
                                    var msg = $filter('translate')('GENERAR_ETIQUETAS_ERROR_2');
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            });
                        }
                    },
                    {
                      templateToRender: "<button ng-disabled='disableAprobar' title='" + $filter('translate')('CONFIRMAR_RECEPCION') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataId%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ok'></span> </button>",
                      functionName: "aprobar",
                      conditionName: "canAprobar",
                      customAttribute: 'estadoRecepcion',
                      conditionDef: function(estado) {
                        return !vm.fromOutside && AuthorizationService.hasPermission('update_recepcion') && estado.codigo != "er_anulado" && estado.codigo != "er_aprobado" && flujoAbierto;
                      },
                      functionDef: function(itemId) {
                        $scope.aprobar = this;
                        $scope.aprobar.$$childHead.$parent.disableAprobar = true;
                        RecepcionFactory.get(itemId, "RecepcionForm").$promise.then(function(recepcion) {
                          checkPeriodoAdministrativo(recepcion.fechaIngresoStock).then(function(resultado) {
                            $scope.aprobar.$$childHead.$parent.disableAprobar = false;
                            if(resultado == true){

                              var filtersArr = [];
                              filtersArr.push('idrecepcion=' + itemId);

                              $scope.tituloModal = $filter("translate")(
                                "CONFIRMAR_RECEPCION"
                              );
                              var modalInstance = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                              });
                              $scope.cancel = function() {
                                modalInstance.dismiss("cancel");
                              };

                              $scope.ok = function(id) {
                                $scope.aprobar.$$childHead.$parent.disableAprobar = true;
                                ImportacionFactory.aprobarRecepcion(itemId).then(function(response) {
                                  $scope.aprobar.$$childHead.$parent.disableAprobar = false;
                                  vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('recepcion', 'pdf', filtersArr);
                                  $window.open(vm.pdfDownloadURL, '_blank');
                                  $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                  $scope.aprobar.$$childHead.$parent.disableAprobar = false;
                                  if (error && error.data && error.data.length > 0) {
                                    var msg = "<span>";
                                    _.forEach(error.data, function(err) {
                                      if (err.constraint == 'id_producto, numero') {
                                        msg = msg + $filter('translate')('NUMERO_GRUPO_REPETIDO_PRODUCTO') + "<br/>";
                                      } else {
                                        msg = msg + err.message + "<br/>";
                                      }
                                    });
                                    msg = msg + "</span>";
                                    notify({ messageTemplate: msg, classes: 'alert-danger', position: 'right' });
                                    // console.log("Mensaje: ", msg);
                                  }
                                });
                              };
                            }else if(resultado == false){
                              $scope.aprobar.$$childHead.$parent.disableAprobar = false;
                              var msg= $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                              notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                          });
                        });
                      }
                    },
                    {
                      templateToRender: "<button ng-disabled='disableAnular' title='" + $filter('translate')('ANULAR') + "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataId%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                      customAttribute: 'estadoRecepcion',
                      conditionName: "canAnular",
                      conditionDef: function(estado) {
                        return !vm.fromOutside && AuthorizationService.hasPermission('update_recepcion') && estado.codigo == "er_aprobado" && flujoAbierto;
                      },
                      functionName: "anular",
                      functionDef: function(itemId) {
                        $scope.anular = this;
                        $scope.anular.$$childHead.$parent.disableAnular = true;
                        RecepcionFactory.get(itemId, "RecepcionForm").$promise.then(function(recepcion) {

                          checkPeriodoAdministrativo(recepcion.fechaIngresoStock).then(function(resultado) {
                            $scope.anular.$$childHead.$parent.disableAnular = false;
                            if(resultado == true){

                              $scope.selectedItemId = itemId;
                              $scope.tituloModal = $filter('translate')('ANULAR_CONFIRMATION');
                              $scope.mensajeModal = $filter('translate')('ANULAR_RECEPCION_WARNING');
                              $scope.modalInstanceRechazar = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                              });

                              $scope.cancel = function() {
                                $scope.modalInstanceRechazar.dismiss('cancel');
                              };

                              $scope.ok = function(itemId) {
                                $scope.anular.$$childHead.$parent.disableAnular = true;
                                ImportacionFactory.anularRecepcion(itemId).then(function() {
                                  $scope.anular.$$childHead.$parent.disableAnular = false;
                                  $scope.modalInstanceRechazar.close(itemId);
                                  $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                  $scope.anular.$$childHead.$parent.disableAnular = false;
                                  if (error && error.data && error.data.length > 0) {
                                    var msg = "";
                                    _.forEach(error.data, function(err) {
                                      msg += err.message + "\n\n";
                                    });
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    console.log("Mensaje: ", msg);
                                  }
                                });
                              };
                            }else if(resultado == false){
                              $scope.anular.$$childHead.$parent.disableAnular = false;
                              var msg= $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                              notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                          });
                        });
                      }
                    }
                ],
            };
            WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");
        });

        $scope.saveToPc = function(data, filename) {

            if (!data) {
                console.error('No data');
                return;
            }

            if (!filename) {
                filename = 'download.txt';
            }

            var blob = new Blob([data], { type: 'text/json' });

            // FOR IE:

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                var e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');

                a.download = filename;
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
                e.initEvent('click', true, false, window,
                    0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
            }
        };
    }

     /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
     var checkPeriodoAdministrativo = function(fechaComprobante){
        return $q(function(resolve, reject) {

            var params = {};
            var fecha;

            if(fechaComprobante == null){
                fecha = new Date();
            }else{
                fecha = fechaComprobante;
            }

            params.search = filterFactory
            .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                }
            ])
            .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function(responsePeriodo) {
                if(responsePeriodo.length>0){
                    resolve(true);
                }else{
                    resolve(false);
                }
            })
        });
    }
}

'use strict';

angular.module('qualita.importacion')
  .controller('RecepcionImportacionFormCtrl', RecepcionImportacionFormCtrl);

RecepcionImportacionFormCtrl.$inject = ["recepcionPrepService", "EmbarqueFactory", "ProductosFactory", "AlmacenFactory",
  "ImportacionFactory", "RecepcionFactory", "$scope", "notify", "$state", "$rootScope", "filterFactory",
  "TipoRecepcionFactory", "ReportTicketFactory", "$window", "$timeout", "ProformaFactory", "ImportacionLangFactory",
  "WatchLocacionChangeFactory", "UtilFactory", "$filter", "$translate", "$q", "PeriodoAdministrativoFactory",
  "ProyectoFactory","ParametrosFactory","GruposProductosFactory"];

function RecepcionImportacionFormCtrl(recepcionPrepService, EmbarqueFactory, ProductosFactory, AlmacenFactory, ImportacionFactory,
                                      RecepcionFactory, $scope, notify, $state, $rootScope, filterFactory,
                                      TipoRecepcionFactory, ReportTicketFactory, $window, $timeout, ProformaFactory,
                                      ImportacionLangFactory, WatchLocacionChangeFactory, UtilFactory, $filter, $translate, $q, 
                                      PeriodoAdministrativoFactory, ProyectoFactory,ParametrosFactory, GruposProductosFactory) {
  var vm = this;

  activate();

  vm.agregarDetalle = agregarDetalle;
  vm.embarqueSelected = embarqueSelected;
  vm.productoSeleccionado = productoSeleccionado;
  vm.removeItemFromArray = removeItemFromArray;
  vm.searchProductoFilter = searchProductoFilter;
  vm.mostrarEnBase=mostrarEnBase;
  vm.actualizarTotalGeneralUnidades = actualizarTotalGeneralUnidades;
  vm.changeTipoRecepcion=changeTipoRecepcion;
  vm.submit = submit;
  vm.cancel = cancel;
  vm.verificarExistencia = verificarExistencia;
  vm.changeFecha = changeFecha;
  vm.compararAbreviaturas = compararAbreviaturas;
  vm.delayRequestProducto = delayRequestProducto;

  function delayRequestProducto(searchValue) {
    var userInputFilter = {}
    if (searchValue.length >= 3) {

        var filterNombre = vm.opcionesProductosDt.staticFilter.search.filters.slice()
        var filterCodigoBarras = vm.opcionesProductosDt.staticFilter.search.filters.slice()
        var filterCodigoInterno = vm.opcionesProductosDt.staticFilter.search.filters.slice()

        filterNombre.push({
            path: "nombre",
            like: searchValue
        },
            {
                path: 'estado.codigo',
                equals: 'activo'
            })

        filterCodigoBarras.push({
            path: "codigoBarrasPrincipal.codigoBarras",
            like: searchValue
        },
            {
                path: 'estado.codigo',
                equals: 'activo'
            })

        filterCodigoInterno.push({
            path: "codigo",
            like: searchValue
        },
            {
                path: 'estado.codigo',
                equals: 'activo'
            })

        userInputFilter = {
            search: filterFactory.or([
                filterFactory.and(filterNombre).value(),
                filterFactory.and(filterCodigoBarras).value(),
                filterFactory.and(filterCodigoInterno).value()
            ]).value()
        }

        recuperarProducto(userInputFilter)
    } else {
        vm.productos = undefined
    }
}

function recuperarProducto(userInputFilter) {
    var finalFilter = UtilFactory.createNestedFilters(
        vm.finalFilters, userInputFilter)

    vm.productos = ProductosFactory.allForSelect(finalFilter)
}


  $rootScope.isProcessing = false;

  function activate() {
    var filterProyecto = {};
    filterProyecto.view = "BaseList"
    vm.proyectos = ProyectoFactory.all(filterProyecto, "ProyectoList");

    WatchLocacionChangeFactory.killAllWatchers();
    ImportacionLangFactory.getTranslations().then(function(translations) {
      $scope.translations = translations;
      vm.mostrarBase=false;
      var importacion = ImportacionFactory.getImportacion();
      vm.importacion = importacion;
      if(!importacion) {
        $state.go("app.main");
      }

      var isStateEnabled = ImportacionFactory.checkPermissionForState(5);
      if(!isStateEnabled) {
        //console.log("No tiene permiso para revisar esta seccion");
        $state.go("app.main");
      }

      if($state.is("app.importaciones.proceso.recepcionimportacion.new")) {
        activateNew();
      } else if($state.is("app.importaciones.proceso.recepcionimportacion.edit")) {
        activateEdit();
      } else if($state.is("app.importaciones.proceso.recepcionimportacion.view")) {
        activateView();
      }

      vm.curDate = new Date();
      var staticFilter = {};
      staticFilter.search = filterFactory.and([{
        path: 'importacion.codigo',
        equals: ImportacionFactory.getCodigoImportacion(),
      }]).value();
      staticFilter.view = "EmbarqueList";
      EmbarqueFactory.all(staticFilter).$promise.then(function (response) {

      });

      if(vm.watcherEmbarque) { vm.watcherEmbarque(); } //hacemos unwatch de la expresion anterior
      WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcherEmbarque) {
        vm.watcherEmbarque = watcherEmbarque;
        EmbarqueFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter, "EmbarqueList")).$promise.then(function (response) {
          vm.embarques= _.filter(response,function(o) {
            return true;
          });
        });
      }, "locacion.id");

      //vm.tiposRecepcion = TipoRecepcionFactory.all({view: "TipoRecepcionList"});
      var filterRecepcion = {}
      filterRecepcion.view = "TipoRecepcionList"

      TipoRecepcionFactory.all(filterRecepcion).$promise.then(function (response) {
        vm.tiposRecepcion = response;
       });

      if(vm.watcherProf) { vm.watcherProf(); }
      WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcherProf) {
        vm.watcherProf = watcherProf;
        vm.proformas = ProformaFactory.all(locacionFilter);
      }, "locacion.id");

      if(vm.watcherAlmacenes) { vm.watcherAlmacenes(); }
      WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcherAlmacenes) {
        vm.watcherAlmacenes = watcherAlmacenes;
        var filterSucursal = {
            search: filterFactory.single({
              path: "sucursal.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
            }).value()
          };
        var almacenes = AlmacenFactory.all(filterSucursal);
        vm.almacenes = almacenes.sort(compararAbreviaturas)

      }, "locacion.id");


      var renderEmptyData = function (data) {
        if (data == undefined)
          return "";
        else
          return data;
      };

      var defaultColumnOrder = ['codigo','nombre', 'claseProducto.descripcion', 'estado.descripcion',
        'lineaProducto.descripcion','marca.descripcion'];

      vm.opcionesProductosDt = {
        'resource': 'productos',
        'title': 'Productos',
        'view': 'ProductosDatatableList',
        'factory': ProductosFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          {'data': 'codigo', 'title': $filter('translate')('ID'), visible: false },
          {'data': 'nombre', 'title': $filter('translate')('NOMBRE')},
          {'data': 'claseProducto.descripcion', 'title': $filter('translate')('KIND')},
          {'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type' : 'combo', 'filterUrl': 'tipos/tipo/producto_estados'},
          {'data': 'lineaProducto.descripcion', 'title': $filter('translate')('LINE')},
          {'data': 'marca.descripcion', 'title': $filter('translate')('BRAND'), 'render': renderEmptyData},
        ],
        'hasOptions' : true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
      };
      vm.factoryProductos = ProductosFactory;

      if(vm.watcher) { vm.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
        vm.watcher = watcher;
        //vm.productos = ProductosFactory.allForSelect(locacionFilter);
        vm.opcionesProductosDt.staticFilter = locacionFilter;
      }, "locaciones.id");
    });
  }
  
  function compararAbreviaturas(a, b) {
    var aAbreviatura = a.abreviatura.toLowerCase();
    var bAbreviatura = b.abreviatura.toLowerCase();

    return aAbreviatura < bAbreviatura ? -1 : (aAbreviatura > bAbreviatura ? 1 : 0);
  }

  function activateNew() {
    var isClosed = vm.importacion.estado.codigo === "cerrada";
        if(isClosed) {
            $state.go("app.main");
        }
    vm.title = $filter('translate')('NEW_RECEPCION');
    vm.recepcion = {
      recepcionDetalle: [{}],
      compraLocal: false
    };
    vm.totalCantidadRecibida=0;
    vm.totalUnidadesRecibidas=0;
    $timeout(function(){
      $scope.$broadcast('formOpened');
    }, 20);
  }

  function activateEdit() {
    var isClosed = vm.importacion.estado.codigo === "cerrada";
        if(isClosed) {
            $state.go("app.main");
        }
    vm.title = $filter('translate')('EDIT_RECEPCION');
    vm.recepcion = recepcionPrepService;
    vm.recepcion.numero = vm.recepcion.id;
    vm.recepcion.fecha = new Date(vm.recepcion.fecha);
    vm.curDate=vm.recepcion.fecha;
    vm.entidad = "Recepcion";
    vm.entidadId = vm.recepcion.id;
    _.forEach(vm.recepcion.recepcionDetalle, function(detalle) {
      if(detalle.proformaDetalle)
        //detalle.proformaDetalle.proforma = ProformaFactory.get(detalle.proformaDetalle.proforma);
      setUnidadMedidaPrincipal(detalle);
    });
    vm.totalCantidadRecibida = _.reduce(vm.recepcion.recepcionDetalle, function(sum, detalle) { return sum + detalle.cantidadRecepcionada; }, 0);
    vm.totalUnidadesRecibidas = _.reduce(vm.recepcion.recepcionDetalle, function(sum, detalle) { return sum + detalle.unidadesRecepcionadas; }, 0);
    $timeout(function(){
      $scope.$broadcast('formOpened');
    }, 20);
    vm.isEdit = true;
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_RECEPCION');
    vm.recepcion = recepcionPrepService;
    vm.recepcion.numero = vm.recepcion.id;
    vm.recepcion.fecha = new Date(vm.recepcion.fecha);
    vm.entidad = "Recepcion";
    vm.entidadId = vm.recepcion.id;
    _.forEach(vm.recepcion.recepcionDetalle, function(detalle) {
      if(detalle.proformaDetalle)
        detalle.proformaDetalle.proforma = ProformaFactory.get(detalle.proformaDetalle.proforma);
      setUnidadMedidaPrincipal(detalle);
    });
    vm.totalCantidadRecibida = _.reduce(vm.recepcion.recepcionDetalle, function(sum, detalle) { return sum + detalle.cantidadRecepcionada; }, 0);
    vm.totalUnidadesRecibidas = _.reduce(vm.recepcion.recepcionDetalle, function(sum, detalle) { return sum + detalle.unidadesRecepcionadas; }, 0);
    vm.extraMenuOptions = [{
      'title': 'Generar Reporte Recepción',
      'icon': 'glyphicon glyphicon-file',
      'showCondition': function() { return true; },
      'action': function() {
        var filters = {};
        var filtersArr = [];
        filtersArr.push({path: 'id', equals: vm.recepcion.id});
        filters = filterFactory.and(filtersArr).value();
        ReportTicketFactory.ticket('recepcion', filters, vm.opcionesProductosDt.currentDataOrder).then(function(genTicket) {
          vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
          $window.open(vm.pdfDownloadURL, '_blank');
        });
      }
    }];
    vm.view = true;
  }

  function agregarDetalle() {
    vm.recepcion.recepcionDetalle.push({});
    $timeout(function(){
      $scope.$broadcast('newItemAdded');
    }, 20);
  }

  function submit() {
    $rootScope.isProcessing = true;
    vm.submited = true;
    if($scope.RecepcionarImpForm.$valid) {
      ParametrosFactory.getByCodigo("controlar_vto_minimo_lote").then(function (response) {
        var diferencia, diasDif;
        var lote = "";
        for (var i = 0; i < vm.recepcion.recepcionDetalle.length; i++) {
          if (vm.recepcion.recepcionDetalle[i].fechaVencimientoGrupo) {
            diferencia = vm.recepcion.recepcionDetalle[i].fechaVencimientoGrupo.getTime() - vm.recepcion.fecha.getTime();
            diasDif = Math.ceil(diferencia / (1000 * 3600 * 24))
            if (diasDif < response.valor) {
              if (!lote) {
                lote = vm.recepcion.recepcionDetalle[i].numeroGrupo;
              } else {
                lote += ", " + vm.recepcion.recepcionDetalle[i].numeroGrupo;
              }
            }
          }
        }
        if (lote) {
          $rootScope.isProcessing = false;
          vm.showSpinner = false;
          var msg = "La fecha de vencimiento del Lote [" + lote + "] es menor a lo permitido. (" + response.valor + " días)"
          notify({ message: msg, classes: "alert-danger", position: "right" });
          return;
        }
        evaluatePeriodoAdministrativo(vm.recepcion.fecha).then(function (response) {
          if (response) {
            // Verificacion de producto-serie unico
            var i, j, banEncontrado = false;

            /* 
              Se carga todo en una lista clonada para agregar el campo 'repite' por cada registro.
              La idea es comparar cada item con los posteriores, si se encuentran repetidos se marcan
              como repetidos (repite: true), aunque el mensaje se muestra una sola vez por cada id 
              de producto. Los marcados como repetidos ya no se comparan con los items siguientes (
              iteracion principal salta a sgte item).
            */
            var listaClonada = [];
            listaClonada = _.cloneDeep(vm.recepcion.recepcionDetalle);

            var idRepetido = null;
            if (listaClonada && listaClonada.length > 0) {
              for (i = 0; i < listaClonada.length - 1; i++) {
                if (listaClonada[i].repite == undefined) {
                  listaClonada[i].repite = false;
                }
                for (j = i + 1; j < listaClonada.length; j++) {

                  if (listaClonada[i].repite == false) {

                    if ((listaClonada[i].producto.id == listaClonada[j].producto.id) &&
                      (listaClonada[i].numeroGrupo == listaClonada[j].numeroGrupo)) {

                      if (idRepetido != listaClonada[i].producto.id) {

                        idRepetido = listaClonada[i].producto.id;
                        $translate("NRO_GRUPO_REPEAT_ERROR", {
                          descipcionProducto: listaClonada[i].producto.nombre,
                          codigoProducto: listaClonada[i].producto.codigo
                        }).then(function (mensaje) {
                          $rootScope.isProcessing = false;
                          notify({ message: mensaje, classes: 'alert-danger', position: 'right' });
                        });

                      }

                      banEncontrado = true;
                      listaClonada[j].repite = true;
                    }
                  } else {
                    break;
                  }
                }
              }
            }
            if (banEncontrado) {
              return false;
            }


            vm.recepcion.recepcionDetalle = _.filter(vm.recepcion.recepcionDetalle, function (elem) { return elem.producto; });
            _.forEach(vm.recepcion.recepcionDetalle, function (detalle) {
              delete detalle.producto.unidadMedidaPrincipal;
              delete detalle.unidadesEnBase;
              delete detalle.recepcion;
            });

            // Se guardara al crear la recepcion (tendra usuario null), al editar no se modificara.
            if (vm.recepcion.usuario == null || vm.recepcion.usuario == undefined) {
              vm.recepcion.usuario = {};
              vm.recepcion.usuario.id = $rootScope.AuthParams.accesoSistema.usuario.id;
            }

            if (vm.recepcion.proyecto == undefined) {
              vm.recepcion.proyecto = null;
            }

            submitRecepcion().then(function (response) {
              //$scope.$emit("updateImportacionStates");
              redirectImportacion(ImportacionFactory.getIdImportacion());
              return response;
            }, function (error) {
              $rootScope.isProcessing = false;
              console.log("Error al persistir entidad: ");
              console.log(error);
              var msg = $filter('translate')('ERROR_SAVING');
              notify({ message: msg, classes: 'alert-danger', position: 'right' });
            });
          }
          else {
            var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            $rootScope.isProcessing = false;
          }
        })
      })
    }else{
      $rootScope.isProcessing = false;
    }
  }

  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function(item) {
      return item === elemento;
    });
    vm.totalCantidadRecibida = _.reduce(vm.recepcion.recepcionDetalle, function(sum, detalle) { return sum + detalle.cantidadRecepcionada; }, 0);
    vm.totalUnidadesRecibidas = _.reduce(vm.recepcion.recepcionDetalle, function(sum, detalle) { return sum + detalle.unidadesRecepcionadas; }, 0);
  }

  // Carga mal para cuando el embarque tiene servicios
   function embarqueSelected() {
    EmbarqueFactory.get(vm.recepcion.embarque.id, "EmbarqueForm").$promise.then(function(response) {
      vm.recepcion.embarque = response;
      vm.recepcion.recepcionDetalle =[];
      for(var i = 0; i < vm.recepcion.embarque.embarqueDetalle.length; i++) {
        if(vm.recepcion.embarque.embarqueDetalle[i].producto !== undefined) {
          // vm.recepcion.embarque.embarqueDetalle[i].producto.grupoProducto = { producto: vm.recepcion.embarque.embarqueDetalle[i].producto.id };
          if (vm.recepcion.embarque.embarqueDetalle[i].cantidad >= 1) {
            var arrayElementos = [];
            if (vm.recepcion.embarque.embarqueDetalle[i].producto.criterioControl.codigo === 'series' &&
              vm.recepcion.embarque.embarqueDetalle[i].producto.claseProducto.tipoAdministracion.codigo === "normales" &&
              vm.recepcion.embarque.embarqueDetalle[i].cantidad >= 100) {
              var msg = "Cuando el criterio de control del producto es por lote o serie y el tipo de administración es distinto de 'bobinas', se generan tantos detalles como indique la cantidad del embarque. " +
                "El producto que está intentando agregar tiene criterio de control por lote y tipo de administración normal, sin embargo la cantidad es " +
                vm.recepcion.embarque.embarqueDetalle[i].cantidad + ". El sistema no es capaz de renderizar esta cantidad de detalles en la vista." +
                "Atención: Si el producto corresponde a bobinas, el tipo de administración del producto debe ser 'bobinas' en lugar de 'normales'. "
              notify({ message: msg, classes: 'alert-danger', position: 'right' });
            } else if (vm.recepcion.embarque.embarqueDetalle[i].producto.criterioControl.codigo === 'series' &&
              vm.recepcion.embarque.embarqueDetalle[i].producto.claseProducto.tipoAdministracion.codigo !== "bobinas") {
              // var proforma = ProformaFactory.get(vm.recepcion.embarque.embarqueDetalle[i].proformaDetalle.proforma);
              for (var j = 0; j < vm.recepcion.embarque.embarqueDetalle[i].cantidad; j++) {
                var obj = {
                  key: j,
                  producto: vm.recepcion.embarque.embarqueDetalle[i].producto,
                  unidadMedida: vm.recepcion.embarque.embarqueDetalle[i].unidadMedida,
                  proformaDetalle: vm.recepcion.embarque.embarqueDetalle[i].proformaDetalle,
                  cantidadRecepcionada: 1
                };
                // obj.proformaDetalle.proforma = vm.recepcion.embarque.proforma;
                vm.recepcion.recepcionDetalle.push(obj);
              }
            } else if (vm.recepcion.embarque.embarqueDetalle[i].producto.criterioControl.codigo === 'lotes' && vm.recepcion.embarque.embarqueDetalle[i].producto.claseProducto.tipoAdministracion.codigo === "normales") {
              var obj = {
                producto: vm.recepcion.embarque.embarqueDetalle[i].producto,
                unidadMedida: vm.recepcion.embarque.embarqueDetalle[i].unidadMedida,
                proformaDetalle: vm.recepcion.embarque.embarqueDetalle[i].proformaDetalle,
                cantidadRecepcionada: vm.recepcion.embarque.embarqueDetalle[i].cantidad
              };
              vm.recepcion.recepcionDetalle.push(obj);

            } else {
              var obj = {
                producto: vm.recepcion.embarque.embarqueDetalle[i].producto,
                unidadMedida: vm.recepcion.embarque.embarqueDetalle[i].unidadMedida,
                proformaDetalle: vm.recepcion.embarque.embarqueDetalle[i].proformaDetalle,
              };
              // obj.proformaDetalle.proforma = ProformaFactory.get(vm.recepcion.embarque.embarqueDetalle[i].proformaDetalle.proforma);
              // obj.proformaDetalle.proforma = vm.recepcion.embarque.proforma;
              vm.recepcion.recepcionDetalle.push(obj);
            }
          }
        }
      }
      _.forEach(vm.recepcion.recepcionDetalle, function(detalle) {
        setUnidadMedidaPrincipal(detalle);
      });
    });
  }

  function mostrarEnBase() {
    if(vm.mostrarBase){
     for(var i=0;i<vm.recepcion.recepcionDetalle.length; i++){
        if(vm.recepcion.recepcionDetalle[i].producto!=undefined){
          var multiplicador=1;
          var unidadActual =vm.recepcion.recepcionDetalle[i].unidadMedida;
          while(!unidadActual.esBase){
            multiplicador=multiplicador*unidadActual.cantidad;
            unidadActual=unidadActual.unidadContenida;
          }
          if(vm.recepcion.recepcionDetalle[i].cantidadRecepcionada!=undefined){
            vm.recepcion.recepcionDetalle[i].unidadesEnBase=vm.recepcion.recepcionDetalle[i].cantidadRecepcionada*multiplicador;
          }
        }
      }
    }
    vm.totalCantidadRecibida = _.reduce(vm.recepcion.recepcionDetalle, function(sum, detalle) { return sum + detalle.cantidadRecepcionada; }, 0);
  }

  function actualizarTotalGeneralUnidades() {
    vm.totalUnidadesRecibidas = _.reduce(vm.recepcion.recepcionDetalle, function(sum, detalle) { return sum + detalle.unidadesRecepcionadas; }, 0);
  }

  function productoSeleccionado(detalle) {
    detalle.fechaVencimientoGrupo = null;
    detalle.numeroGrupo = null;
    if(detalle.producto.criterioControl.codigo == "series"){
        detalle.cantidadRecepcionada = 1;
    }
    detalle.unidadMedida=detalle.producto.unidadMedidaBase;
  }

  function updateDetalle(detalle) {
    if (detalle.producto.criterioControl.codigo === 'series' || detalle.producto.criterioControl.codigo === 'lotes') {
      detalle.gruposProducto=[{}];
    }
  }

  function changeTipoRecepcion() {

    
    var almacenTipoRecepcion = _.filter(vm.almacenes, function (detalle) {
        if (detalle.id == vm.recepcion.tipoRecepcion.almacen.id) {
            return detalle;
        }
    });
  
    if(almacenTipoRecepcion.length == 0){
      vm.recepcion.almacen = vm.almacenes[0];
    }else{
      vm.recepcion.almacen = almacenTipoRecepcion[0];
    }
    
  }

  function setUnidadMedidaPrincipal(detalle) {
    detalle.producto.unidadMedidaBase = _.find(detalle.producto.unidadesMedida, function(um) {
      return um.principal === true;
    });
  }

  function searchProductoFilter(criteria) {
    return function(item) {
      if(!criteria) {
        return true;
      }
      return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
    };
  }

  function submitRecepcion() {
    var resource = RecepcionFactory.create(vm.recepcion);
    delete resource.numero;
    if(!vm.isEdit) {
      resource.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
    }
    return RecepcionFactory.save(resource);
  }

  function cancel() {
    $state.go('app.importaciones.proceso.recepcionimportacion.list', {codigoImportacion: ImportacionFactory.getIdImportacion()});
  }

  function redirectImportacion(itemId) {
    ImportacionFactory.get(itemId, "ImportacionList").$promise.then(function(imp) {
      ImportacionFactory.setIdImportacion(imp.id);
      ImportacionFactory.setCodigoImportacion(imp.codigo);
      ImportacionFactory.setImportacion(imp);
      $scope.$emit("updateImportacionStates");
      switch(imp.estado.codigo) {
        case "abierta":
          $state.go('app.importaciones.proceso.ordenescompra.list', {codigoImportacion: itemId});
          break;
        case "con_orden_compra":
          $state.go('app.importaciones.proceso.proformas.list', {codigoImportacion: itemId});
          break;
        case "con_proforma":
          $state.go('app.importaciones.proceso.solicitudes.list', {codigoImportacion: itemId});
          break;
        case "con_solicitud":
          console.log('con solicitud');
          $state.go('app.importaciones.proceso.embarques.list', {codigoImportacion: itemId});
          break;
        case "con_embarque":
          console.log('con embarque');
          $state.go("app.importaciones.proceso.comprobante.list", {codigoImportacion: itemId});
          break;
        case "con_comprobante":
          console.log('con comprobante');
          $state.go("app.importaciones.proceso.recepcionimportacion.list", {codigoImportacion: itemId});
          break;
        case "con_recepcion":
          console.log('con recepcion');
          $state.go("app.importaciones.proceso.recepcionimportacion.list", {codigoImportacion: itemId});
          break;
        case "con_despacho":
          console.log('con despacho');
          $state.go("app.importaciones.proceso.cierre", {codigoImportacion: itemId});
          break;
        case "cerrada":
          $state.go("app.importaciones.proceso.cierre", {codigoImportacion: itemId});
          break;
        default:
          console.log("Estado distinto")
      }
    });
  }

  function evaluatePeriodoAdministrativo(fecha){
    return $q(function(resolve, reject) {
        var params = {};
        params.search = filterFactory
        .and([{
                path: "estado.codigo",
                equals: "per_adm_abierto"
            },
            {
                path: "fechaDesde",
                equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
            },
            {
                path: "fechaHasta",
                equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
            }
        ])
        .value();

        PeriodoAdministrativoFactory.all(params).$promise.then(function(responsePeriodo) {

            if(responsePeriodo.length>0){
                resolve(true);
            }else{
                resolve(false);
            }
        })
    });
}

function verificarExistencia(detalle) {
  if (detalle.numeroGrupo != null) {
      var params = {};
      params.search = filterFactory.and([{
          path: 'numero',
          equals: detalle.numeroGrupo
      },
      {
          path: 'producto.id',
          equals: detalle.producto.id
      }
      ]).value();
      GruposProductosFactory.all(params).$promise.then(function (response) {
          console.log("response ", response)
          //buscar si existe en grupo productos
          if (response.length > 0) {
              detalle.fechaVencimientoGrupo = moment(response[0].fechaVencimiento, "DD/MM/YYYY").toDate();
              vm.existeGrupo = true;
          } else {
              vm.existeGrupo = false;
              detalle.fechaVencimientoGrupo = null;
          }
      })
  } else {
      vm.existeGrupo = false;
      detalle.fechaVencimientoGrupo = null;
  }
}

function changeFecha(detalle) {
  var params = {};
  params.search = filterFactory.and([{
      path: 'numero',
      equals: detalle.numeroGrupo
  },
  {
      path: 'producto.id',
      equals: detalle.producto.id
  }
  ]).value();
  GruposProductosFactory.all(params).$promise.then(function (response) {
      //buscar si existe en grupo productos
      if (response.length > 0) {
          notify({
              message: "El nro. grupo ya cuenta con fecha de vencimiento, no puede cambiar.",
              classes: "alert-warning",
              position: "right"
          });
          detalle.fechaVencimientoGrupo = moment(response[0].fechaVencimiento, "DD/MM/YYYY").toDate();
      }
  })
}

}

angular.module('qualita.importacion')
  .factory('RecepcionFactory', RecepcionFactory);

RecepcionFactory.$inject = ['$resource', 'baseurl', '$http'];

function RecepcionFactory($resource, baseurl, $http) {
  var service = {
    all: all,
    get: get,
    getByImportacion: getByImportacion,
    create: create,
    save: save,
    remove: remove,
    aprobar: aprobar,
    anular: anular,
    getDiferencia: getDiferencia,
    generarEtiquetas: generarEtiquetas
  };

  var Recepcion = $resource( baseurl.getBaseUrl() + "/recepcion/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    },
    getByImportacion: {
      url: baseurl.getBaseUrl() + '/recepcion/importacion/:id',
      method: 'GET',
      isArray: true
    }
  });

  return service;

  function all(params, view) {
    params = params || [];
    params.view =  view || "base";
    return Recepcion.query(params);
  }

  function get(id, view) {
    return Recepcion.get({id: id, view: view ? view : "base"});
  }

  function getByImportacion(id) {
    return Recepcion.getByImportacion({id: id});
  }

  function create(attrs) {
    return new Recepcion(attrs);
  }

  function save(recepcion) {
    return (recepcion.id) ? recepcion.$update() : recepcion.$save();
  }

  function remove(recepcion) {
    return recepcion.$remove();
  }

  function aprobar(recepcionId) {
    return $http({
      url: baseurl.getBaseUrl() + '/recepcion/aprobar/'+recepcionId,
      method: "PUT"
    });
  }

  function anular(recepcionId) {
    return $http({
      url: baseurl.getBaseUrl() + '/recepcion/anular/'+recepcionId,
      method: "PUT"
    });
  }

  function getDiferencia(recepcionId){
    return $http({
      url: baseurl.getBaseUrl() + '/recepcion/diferencias/'+recepcionId,
      method: "GET"
    });
  }

  function generarEtiquetas(recepcionId){
    return $http({
      url: baseurl.getBaseUrl() + '/recepcion/etiquetas/'+recepcionId,
      method: "GET"
    });
  }
}

angular.module('qualita.importacion')
  .factory('RecepcionDetalleFactory', RecepcionDetalleFactory);

RecepcionDetalleFactory.$inject = ['$resource', 'baseurl'];

function RecepcionDetalleFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var RecepcionDetalle = $resource( baseurl.getBaseUrl() + "/recepciondetalle/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params) {
    return RecepcionDetalle.query(params);
  }

  function get(id, view) {
    return RecepcionDetalle.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new RecepcionDetalle(attrs);
  }

  function save(recepcion) {
    return (recepcion.id) ? recepcion.$update() : recepcion.$save();
  }

  function remove(recepcion) {
    return recepcion.$remove();
  }
}
/**
 * Created by codiumsa on 1/2/16.
 */

angular.module('qualita.importacion')
    .factory('DespachoFactory', DespachoFactory);

DespachoFactory.$inject = ['$resource', 'baseurl', '$http'];

function DespachoFactory($resource, baseurl, $http) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove
    };

    var Despacho = $resource(baseurl.getBaseUrl() + "/despacho/:id", { id: '@id' }, {
        update: {
            method: 'PUT'
        }
    });

    return service;

    function all(params, view) {
        if (params) {
            params.view = view != undefined || params.view != undefined ? (view || params.view) : 'base';
        } else {
            params = [];
            params.view = view != undefined ? view : 'base';
        }
        return Despacho.query(params);
    }

    function get(id, view) {
        return Despacho.get({ id: id, view: view ? view : "base" });
    }

    function create(attrs) {
        return new Despacho(attrs);
    }

    function save(despacho) {
        return (despacho.id) ? despacho.$update() : despacho.$save();
    }

    function remove(despacho) {
        return despacho.$remove();
    }

}
'use strict';

angular.module('qualita.importacion')
    .controller('DespachoIndexCtrl', DespachoIndexCtrl);

DespachoIndexCtrl.$inject = ["AuthorizationService", "ImportacionFactory", "DespachoFactory",
    "OrdenesCompraFactory", "TiposFactory", "RegimenAduanasFactory", "AduanasFactory", "TipoImpuestoAduanaFactory",
    "filterFactory", "ProveedoresFactory", "CondicionImportacionFactory", "ComprobanteFactory", "ProformaFactory", "$log", "$modal",
    "$scope", "notify", "$state", "$rootScope", "$timeout", "ImportacionLangFactory", "WatchLocacionChangeFactory",
    "UtilFactory", "$filter", "TipoComprobanteFactory", "$q", "PeriodoAdministrativoFactory", "RubroIvaFactory", "ParametrosFactory"
];

function DespachoIndexCtrl(AuthorizationService, ImportacionFactory, DespachoFactory,
    OrdenesCompraFactory, TiposFactory, RegimenAduanasFactory, AduanasFactory, TipoImpuestoAduanaFactory,
    filterFactory, ProveedoresFactory, CondicionImportacionFactory, ComprobanteFactory, ProformaFactory, $log, $modal,
    $scope, notify, $state, $rootScope, $timeout, ImportacionLangFactory, WatchLocacionChangeFactory, UtilFactory,
    $filter, TipoComprobanteFactory, $q, PeriodoAdministrativoFactory, RubroIvaFactory, ParametrosFactory) {
    var vm = this;
    vm.embarque = {
        embarqueDetalle: [{}],
        importacion: ImportacionFactory.getIdImportacion()
    };
    vm.agregarDetalle = agregarDetalle;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.removeItemFromArray = removeItemFromArray;
    vm.actualizarTotalLiquidacion = actualizarTotalLiquidacion;
    vm.curDate = new Date();
    activate();
    $rootScope.isProcessing = false;
    vm.totalGeneralImpuestos = 0;

    vm.changeTipoCambio = changeTipoCambio;

    function activate() {
        ImportacionLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            vm.title = $filter('translate')('DESPACHO');
            vm.curDate = new Date();

            var staticFilterTC = {
                search: filterFactory
                    .and([{
                        path: "clase.codigo",
                        like: "despacho"
                    }])
                    .value()
            };
            vm.tiposComprobante = TipoComprobanteFactory.all(staticFilterTC);
            var importacion = ImportacionFactory.getImportacion();
            var paramsRubroIva = {};
            paramsRubroIva.search = filterFactory
                .single({
                    path: "descripcion",
                    sortAsc: true
                })
                .value();

            paramsRubroIva.view = "BaseList"

            vm.rubrosIva = RubroIvaFactory.all(paramsRubroIva);
            ParametrosFactory.filterByCodigo("imputa_iva").$promise.then(function (response) {
                if (response[0].valor == "Sí") {
                    vm.despacho.imputarIvaGeneral = true;
                } else if (response[0].valor == "No") {
                    vm.despacho.imputarIvaGeneral = false;
                }
            })
            ParametrosFactory.filterByCodigo("imputa_ire").$promise.then(function (response) {
                if (response[0].valor == "Sí") {
                    vm.despacho.imputarIre = true;
                } else if (response[0].valor == "No") {
                    vm.despacho.imputarIre = false;
                }
            })
            ParametrosFactory.filterByCodigo("imputa_irp_rsp").$promise.then(function (response) {
                if (response[0].valor == "Sí") {
                    vm.despacho.imputarIrpRsp = true;
                } else if (response[0].valor == "No") {
                    vm.despacho.imputarIrpRsp = false;
                }
            })

            // console.log('obtener importacion');

            if (!importacion) {
                $state.go("app.main");
            }
            var isStateEnabled = ImportacionFactory.checkPermissionForState(6);
            if (!isStateEnabled) {
                $state.go("app.main");
            }

            // var staticFilterOC = {};
            // staticFilterOC.search = filterFactory.and([{
            //     path: 'importaciones.codigo',
            //     equals: ImportacionFactory.getCodigoImportacion(),
            // }]).value();

            var staticFilterImportacion = {};
            staticFilterImportacion.search = filterFactory.and([{
                path: 'importacion.codigo',
                equals: ImportacionFactory.getCodigoImportacion(),
            },
            {
                path: 'tipo.clase.codigo',
                notEquals: "despacho"
            }]).value();

            if (!importacion.estado) {
                importacion.then(function (data) {
                    if (!data.despacho || data.despacho.length == 0) {
                        vm.despacho = {
                            importacion: ImportacionFactory.getIdImportacion()
                        };
                        vm.despacho.valorFactura = 0;
                        vm.despacho.valorFlete = 0;
                        vm.despacho.valorSeguro = 0;
                        vm.despacho.despachoDetalle = [{}];

                        // Recuperar Condicion de Importacion de la Proforma
                        if (importacion.proformas && importacion.proformas.length == 1) {
                            ProformaFactory.get(importacion.proformas[0], "ProformaImportacionList").$promise.then(function (proforma) {
                                vm.despacho.condicionImportacion = proforma.tipoCostoDespacho;
                            });
                        }

                    } else {
                        vm.despacho = DespachoFactory.get(data.despacho[0], "DespachoForm");
                        vm.despacho.fecha = new Date(vm.despacho.fecha);
                        vm.extraTopButtons = [{
                            showCondition: function () { return true; },
                            action: deleteDespacho,
                            title: $filter('translate')('DELETE'),
                            icon: 'glyphicon glyphicon-trash'
                        }];
                    }
                });
            } else {
                if (!importacion.despacho || importacion.despacho.length == 0) {
                    vm.despacho = {
                        importacion: ImportacionFactory.getIdImportacion()
                    };
                    vm.despacho.valorFactura = 0;
                    vm.despacho.valorFlete = 0;
                    vm.despacho.valorSeguro = 0;
                    vm.despacho.despachoDetalle = [{}];

                    // Recuperar Condicion de Importacion de la Proforma
                    if (importacion.proformas && importacion.proformas.length == 1) {
                        ProformaFactory.get(importacion.proformas[0], "ProformaImportacionList").$promise.then(function (proforma) {
                            vm.despacho.condicionImportacion = proforma.tipoCostoDespacho;
                        });
                    }

                } else {
                    vm.despacho = DespachoFactory.get(importacion.despacho[0].id, "DespachoForm");
                    vm.despacho.$promise.then(function (despacho) {
                        vm.despacho.fecha = new Date(despacho.fecha);
                        // vm.despacho = {
                        //     importacion: ImportacionFactory.getIdImportacion()
                        // };

                        // Calculo Moneda Origen
                        if (vm.despacho.tipoCambioFactura && vm.despacho.tipoCambioFactura > 0) {
                            vm.despacho.valorFleteOriginal = vm.despacho.valorFlete / vm.despacho.tipoCambioFactura;
                            vm.despacho.valorSeguroOriginal = vm.despacho.valorSeguro / vm.despacho.tipoCambioFactura;

                            vm.despacho.valorFleteOriginal = parseFloat(vm.despacho.valorFleteOriginal).toFixed(2);
                            vm.despacho.valorSeguroOriginal = parseFloat(vm.despacho.valorSeguroOriginal).toFixed(2);

                            //AQUI
                        }

                    });
                    vm.extraTopButtons = [{
                        showCondition: function () { return true; },
                        action: deleteDespacho,
                        title: $filter('translate')('DELETE'),
                        icon: 'glyphicon glyphicon-trash'
                    }];
                }
            }

            var importacion = ImportacionFactory.getImportacion();
            if (importacion.estado.codigo == "cerrada") {
                vm.view = true;
            }
            if (vm.watcherOC) { vm.watcherOC(); }
            // WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
            //     vm.watcherOC = watcher;
            //     vm.ordenesCompra = OrdenesCompraFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterOC), "OrdenCompraList");
            // }, "locacion.id");

            vm.regimenesAduanas = RegimenAduanasFactory.all({ view: "RegimenAduanaList" });
            vm.aduanas = AduanasFactory.all({ view: "AduanaList" });

            var staticFilterProveedor = {};
            staticFilterProveedor.search = filterFactory.and([{
                path: 'claseProveedor.codigo',
                equals: 'despachante',
            }]).value();
            vm.despachantes = ProveedoresFactory.all(staticFilterProveedor);
            vm.condiciones = CondicionImportacionFactory.all({ view: "CondicionImportacionList" });
            vm.tiposImpuestosAduana = TipoImpuestoAduanaFactory.all({ view: "TipoImpuestoAduanaList" });

            if (vm.watcherComprob) { vm.watcherComprob(); }

            WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
                vm.watcherComprob = watcher;
                vm.comprobantes = ComprobanteFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterImportacion), "ComprobanteDespachoList");

                // seleccionar de todos los comprobantes aquellos que pertenecen al grupo de compra "Comprobante de Importación"
                vm.comprobantes.$promise.then(function (comprobantes) {
                    vm.comprobantesImportacion = _.filter(vm.comprobantes, function (elem) { return elem.grupoCompra.codigo === 'comprobante_importacion'; });
                    vm.gastosImportacion = _.filter(vm.comprobantes, function (elem) { return elem.grupoCompra.codigo === 'gasto_importacion'; });

                    vm.despacho.valorFactura = _.reduce(vm.comprobantesImportacion, function (sum, value) {
                        return sum + value.montoTotal;
                    }, 0);

                    vm.despacho.valorFactura = parseFloat(vm.despacho.valorFactura.toFixed(2));

                    // calcular totales
                    vm.despacho.totalComprobantes = 0;
                    var flete = 0;
                    var seguro = 0;

                    var fleteOriginal = 0;
                    var seguroOriginal = 0;

                    _.forEach(comprobantes, function (comprobante) {
                        vm.despacho.totalComprobantes += comprobante.montoTotal * comprobante.cambio;
                        flete += comprobante.valorFlete * comprobante.cambio;
                        seguro += comprobante.valorSeguro * comprobante.cambio;

                        fleteOriginal += comprobante.valorFlete;
                        seguroOriginal += comprobante.valorSeguro;
                    });

                    // Redondeo a moneda extranjera
                    fleteOriginal = parseFloat(fleteOriginal.toFixed(2));
                    seguroOriginal = parseFloat(seguroOriginal.toFixed(2));

                    vm.despacho.totalComprobantes = parseFloat(vm.despacho.totalComprobantes.toFixed(0));
                    vm.despacho.valorFlete = parseFloat(flete.toFixed(0));
                    vm.despacho.valorSeguro = parseFloat(seguro.toFixed(0));

                    vm.despacho.valorFleteOriginal = fleteOriginal;
                    vm.despacho.valorSeguroOriginal = seguroOriginal;

                    changeTipoCambio();

                });
            }, "locacion.id");

            // inicializar a cero el total liquidacion,
            // se debera actualizar con cualquier modificacion de la lista de liquidacion de impuestos
            if (vm.despacho.totalLiquidacion == undefined)
                vm.despacho.totalLiquidacion = 0;

            var staticFilterComprobanteImportacion = {};
            staticFilterComprobanteImportacion.search = filterFactory
                .and([
                    {
                        path: 'importacion.codigo',
                        equals: ImportacionFactory.getCodigoImportacion(),
                    },
                    {
                        path: 'grupoCompra.codigo',
                        equals: "comprobante_importacion"
                    }
                ]).value();

            ComprobanteFactory.all(staticFilterComprobanteImportacion, "ComprobanteMonedaList").$promise.then(function (comprobantes) {
                // Se toma la moneda del primer comprobante
                vm.despacho.moneda = comprobantes[0].moneda1;
            });

        });


    }

    function changeTipoCambio() {

        vm.despacho.valorImponible = vm.despacho.valorFactura - (vm.despacho.valorFleteOriginal + vm.despacho.valorSeguroOriginal);
        vm.despacho.valorImponible = vm.despacho.valorImponible * vm.despacho.tipoCambioFactura;
        vm.despacho.valorImponible = parseFloat(vm.despacho.valorImponible.toFixed(0));
    }

    function deleteDespacho() {
        $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
        $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
        $scope.modalInstanceBorrar1 = $modal.open({
            templateUrl: 'views/directives/confirmation-modal.html',
            scope: $scope
        });

        $scope.cancel = function () {
            $scope.modalInstanceBorrar1.dismiss('cancel');
        }

        $scope.ok = function (itemId) {
            var model = DespachoFactory.create(vm.despacho);
            DespachoFactory.remove(model).then(function () {
                $scope.modalInstanceBorrar1.close();
                $state.go($state.current, {}, { reload: true });
            }, function (error) {
                $scope.modalInstanceBorrar1.dismiss('cancel');
                $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                $scope.mensajeModal = $scope.options.failedDeleteError;
                var modalInstance = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        '</div>' +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                        '</div>',
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance.dismiss('cancel');
                };
                console.log("error al borrar: ");
                console.log(error);
            });
        };
    }

    function submit() {
        vm.submited = true;
        if ($scope.DespachoForm.$valid) {
            if (vm.despacho.imputar === true) {
                if (vm.despacho.imputarIvaGeneral != true && vm.despacho.imputarIre != true &&
                    vm.despacho.imputarIrpRsp != true) {

                    var msg = $filter('translate')('ERROR_NO_IMPUTA');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    return;
                }
            }
            evaluatePeriodoAdministrativo(vm.despacho.fecha).then(function (response) {
                if (response) {
                    $rootScope.isProcessing = true;
                    vm.despacho.despachoDetalle = _.filter(vm.despacho.despachoDetalle, function (elem) { return elem.tipoImpuesto; });
                    vm.despacho.importacion = {
                        id: ImportacionFactory.getIdImportacion()
                    };
                    submitDespacho().then(function (response) {
                        ImportacionFactory.setIdImportacion(response.importacion.id);
                        ImportacionFactory.setCodigoImportacion(response.importacion.codigo);
                        ImportacionFactory.setImportacion(response.importacion);
                        $scope.$emit("updateImportacionStates");
                        redirectImportacion(response.importacion.id);
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        var msg = $filter('translate')('ERROR_SAVING');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    });
                } else {
                    var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }
            })
        }
    }

    function agregarDetalle() {
        vm.despacho.despachoDetalle.push({});
        $timeout(function () {
            $scope.$broadcast('newItemAdded');
        }, 20);
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        vm.despacho.totalLiquidacion = _.reduce(vm.despacho.despachoDetalle, function (sum, value) { return sum + value.importe; }, 0);
        vm.despacho.totalLiquidacion = parseFloat(vm.despacho.totalLiquidacion.toFixed(0));
    }

    function actualizarTotalLiquidacion(detalle) {
        vm.despacho.totalLiquidacion = _.reduce(vm.despacho.despachoDetalle, function (sum, value) { return sum + value.importe; }, 0);
        vm.despacho.totalLiquidacion = parseFloat(vm.despacho.totalLiquidacion.toFixed(0));

        // Redondear 'importe' del detalle modificado
        detalle.importe = parseFloat(detalle.importe.toFixed(0));
    }

    function submitDespacho() {
        vm.despacho.locacion = $rootScope.AuthParams.locacionSeleccionada;
        var resource = DespachoFactory.create(vm.despacho);
        return DespachoFactory.save(resource);
    }

    function cancel() {
        $state.go('app.importaciones.list');
    }

    function redirectImportacion(itemId) {
        ImportacionFactory.get(itemId, "ImportacionList").$promise.then(function (imp) {
            ImportacionFactory.setIdImportacion(imp.id);
            ImportacionFactory.setCodigoImportacion(imp.codigo);
            ImportacionFactory.setImportacion(imp);
            switch (imp.estado.codigo) {
                case "abierta":
                    $state.go('app.importaciones.proceso.ordenescompra.list', { codigoImportacion: itemId });
                    break;
                case "con_orden_compra":
                    $state.go('app.importaciones.proceso.proformas.list', { codigoImportacion: itemId });
                    break;
                case "con_proforma":
                    $state.go('app.importaciones.proceso.solicitudes.list', { codigoImportacion: itemId });
                    break;
                case "con_solicitud":
                    console.log('con solicitud');
                    $state.go('app.importaciones.proceso.embarques.list', { codigoImportacion: itemId });
                    break;
                case "con_embarque":
                    console.log('con embarque');
                    $state.go("app.importaciones.proceso.comprobante.list", { codigoImportacion: itemId });
                    break;
                case "con_comprobante":
                    console.log('con comprobante');
                    $state.go("app.importaciones.proceso.recepcionimportacion.list", { codigoImportacion: itemId });
                    break;
                case "con_recepcion":
                    console.log('con recepcion');
                    $state.go("app.importaciones.proceso.recepcionimportacion.list", { codigoImportacion: itemId });
                    break;
                case "con_despacho":
                    console.log('con despacho');
                    $state.go("app.importaciones.proceso.cierre", { codigoImportacion: itemId });
                    break;
                case "cerrada":
                    $state.go("app.importaciones.proceso.cierre", { codigoImportacion: itemId });
                    break;
                default:
                    console.log("Estado distinto")
            }
        });
    }

    function evaluatePeriodoAdministrativo(fecha) {
        return $q(function (resolve, reject) {
            var params = {};
            params.search = filterFactory
                .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                }
                ])
                .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {

                if (responsePeriodo.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        });
    }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular
    .module("qualita.importacion")
    .factory("ComprobanteFactory", ComprobanteFactory);

ComprobanteFactory.$inject = [
    "$resource",
    "baseurl",
    "$http",
    "ParametrosFactory",
    "$q",
    "notify",
    "$window",
    "$filter",
    "$rootScope",
    "ProveedorSucursalesFactory",
    "UtilFactory",
    "$translate"
];

function ComprobanteFactory(
    $resource,
    baseurl,
    $http,
    ParametrosFactory,
    $q,
    notify,
    $window,
    $filter,
    $rootScope,
    ProveedorSucursalesFactory,
    UtilFactory,
    $translate
) {
    var service = {
        all: all,
        get: get,
        getCombos: getCombos,
        create: create,
        save: save,
        saveWithPlanPago: saveWithPlanPago,
        aplicar: aplicar,
        remove: remove,
        aprobar: aprobar,
        desaprobar: desaprobar,
        desaplicar: desaplicar,
        reenviar:reenviar,
        getRetenciones: getRetenciones,
        actualizarStockComprobante: actualizarStockComprobante,
        actualizarStockPorComprobante: actualizarStockPorComprobante,
        validateNumeroComprobante: validateNumeroComprobante,
        getDiferencia: getDiferencia,
        validateSucursalProveedorFromNumeroComprobante: validateSucursalProveedorFromNumeroComprobante,
        validatePuntoEmisionSucursalFromNumeroComprobante: validatePuntoEmisionSucursalFromNumeroComprobante,
        getMontosMes: getMontosMes,
        validateMontos: validateMontos,
        igualarTipoImpuestoCabeceraADetealle: igualarTipoImpuestoCabeceraADetealle,
        downloadCSVHechaukaCompras: downloadCSVHechaukaCompras,
        completeNroFactura: completeNroFactura,
        validateNroFactura: validateNroFactura,
        aprobarImportacion: aprobarImportacion,
        desaprobarImportacion: desaprobarImportacion,
        getEstaAsentado: getEstaAsentado,
        getSaldo: getSaldo,
        downloadCSVArchivoCompras: downloadCSVArchivoCompras,/*,
        downloadCSVSaldoProveedores:downloadCSVSaldoProveedores*/
        carga: carga,
        saveIgnoreEstado: saveIgnoreEstado,
    };

    var Comprobante = $resource(
        baseurl.getBaseUrl() + "/comprobante/:id", { id: "@id" }, {
            update: {
                method: "PUT"
            },
            aplicar: {
                method: "POST",
                url: baseurl.getBaseUrl() + "/comprobante/:id/aplicar"
            },
            montosMes: {
                method: "GET",
                url: baseurl.getBaseUrl() + "/comprobante/montos/:month/:year/:idProveedor"
            },
            getCombosQuery: {
                method: "GET",
                url: baseurl.getBaseUrl() + "/comprobante/getCombos",
                isArray: true
            },
            getRetenciones: {
                method: "GET",
                url: baseurl.getBaseUrl() + "/comprobante/getRetenciones",
                isArray: true
            },
            aprobar: {
                method: "PUT",
                url: baseurl.getBaseUrl() + "/comprobante/aprobar/:id"
            },
            actualizarStockComprobante: {
                method: "GET",
                url: baseurl.getBaseUrl() + "/comprobante/actualizarStockComprobante/:id"
            },
            actualizarStockPorComprobante: {
                method: "GET",
                url: baseurl.getBaseUrl() + "/comprobante/actualizarStockPorComprobante/:id"
            },
            desaprobar: {
                method: "PUT",
                url: baseurl.getBaseUrl() + "/comprobante/desaprobar/:id"
            },
            desaplicar: {
                method: "PUT",
                url: baseurl.getBaseUrl() + "/comprobante/desaplicar/:id"
            },
            reenviar: {
                method: "PUT",
                url: baseurl.getBaseUrl() + "/comprobante/reenviar/:id"
            },
            aprobarImportacion: {
                method: "PUT",
                url: baseurl.getBaseUrl() + "/comprobante/aprobar/importacion/:id"
            },
            desaprobarImportacion: {
                method: "PUT",
                url: baseurl.getBaseUrl() + "/comprobante/desaprobar/importacion/:id"
            },


        }
    );

    return service;

    function all(params, view) {
        params = params ? params : [];
        params.view = view || "ComprobanteList";
        return Comprobante.query(params);
    }

    function getCombos(params) {
        params = params ? params : [];
        return Comprobante.getCombosQuery(params);
    }

    function getRetenciones(params, view) {
        params = params ? params : [];
        params.view = view != undefined ? view : "detail";
        return Comprobante.getRetenciones(params);
    }

    function actualizarStockComprobante(id) {
        return Comprobante.actualizarStockComprobante({ id: id }).$promise;
    }
    function actualizarStockPorComprobante(id) {
        return Comprobante.actualizarStockPorComprobante({ id: id }).$promise;
    }

    function get(id, view) {
        return Comprobante.get({ id: id, view: view ? view : "BaseList" });
    }

    function create(attrs) {
        return new Comprobante(attrs);
    }

    function save(comprobante) {
        return comprobante.id ? comprobante.$update() : comprobante.$save();
    }

    function aplicar(comprobante, modo) {
        return comprobante.$aplicar({ mode: modo });
    }

    function getMontosMes(comprobante, month, year, idProveedor) {
        return comprobante.$montosMes({
            month: month,
            year: year,
            idProveedor: idProveedor
        });
    }

    function aprobar(id) {
        return Comprobante.aprobar({ id: id }).$promise;
    }

    function desaprobar(id) {
        return Comprobante.desaprobar({ id: id }).$promise;
    }

    function desaprobarImportacion(id) {
        return Comprobante.desaprobarImportacion({ id: id }).$promise;
    }

    function desaplicar(id) {
        return Comprobante.desaplicar({ id: id }).$promise;
    }

    function reenviar(id) {
        return Comprobante.reenviar({ id: id }).$promise;
    }

    function saveWithPlanPago(
        comprobante,
        cantcuotas,
        plazocuotas,
        montoinicial
    ) {
        if (cantcuotas != undefined && plazocuotas != undefined) {
            var obj = {
                cantcuotas: cantcuotas,
                plazocuotas: plazocuotas,
                montoinicial: montoinicial
            };
            return comprobante.id ? comprobante.$update(obj) : comprobante.$save(obj);
        }
    }

    function saveIgnoreEstado(
        comprobante,
        hasPermisoEditAprobado
    ) {
        var obj = {
            editaprobado: hasPermisoEditAprobado
        };
        return comprobante.id ? comprobante.$update(obj) : comprobante.$save(obj);
    }

    function remove(comprobante) {
        return comprobante.$remove();
    }

    function getSaldo(idProv, idCurrency, idSuc) {

        return $http({
            url: baseurl.getBaseUrl() + '/comprobante/saldo',
            method: "GET",
            params: {
              idProveedor: idProv,
              idSucursal:idSuc,
              idMoneda: idCurrency
            }
        });
      }

    function validateNroFactura(form, comprobante, monedaLocal, formatoComprobante, sucursalProveedor) {

        form.numero.$setValidity("error", true);
        completeNroFactura(comprobante);

        if (!comprobante.tipo) {
            var msg = $filter("translate")(
                "REQUIRED_FIELDS_TO_VALIDATE_NUMBER_1"
            );
            notify({
                message: msg,
                classes: "alert-info",
                position: "right"
            });
        }else if (comprobante.tipo.clase.codigo != 'comprobante_interno' &&
                comprobante.tipo.clase.codigo != 'factura_exterior' &&
                comprobante.tipo.clase.codigo != "despacho" &&
                !(comprobante.tipo.clase.codigo == "nota_credito" && !comprobante.tipo.controlarTimbrado)) {
            if (!comprobante.tipo || !comprobante.timbradoProveedor || !comprobante.fecha) {
                $rootScope.isProcessing = false;
                var msg = $filter("translate")(
                    "REQUIRED_FIELDS_TO_VALIDATE_NUMBER_2"
                );
                notify({
                    message: msg,
                    classes: "alert-danger",
                    position: "right"
                });
                // El campo queda invalido.
                form.numero.$error.pattern = true;
                form.numero.$setValidity("error", false);
            } else{
                if (!validateNumeroComprobante(comprobante, formatoComprobante)) {
                    $rootScope.isProcessing = false;

                    ParametrosFactory.filterByCodigo("formato_nro_comprobante").$promise.then(function(formato){

                        // Separacion de numeros de comprobante
                        var nroComprobante = comprobante.numero;
                        var nroArr = nroComprobante.split("-");

                        // Separacion de numeros parametro
                        var nroArrParametro = formato[0].valor.split("-");

                        $translate("FORMATO_NRO_COMPROBANTE_WARNING", {
                            formato: formato[0].valor
                        }).then(function(mensaje) {
                            notify({
                                message: mensaje,
                                classes: "alert-danger",
                                position: "right"
                            });
                            return;
                        });

                        // El campo queda invalido.
                        form.numero.$error.pattern = true;
                        form.numero.$setValidity("error", false);
                        return comprobante;
                    });
                }

                if (!validateSucursalProveedorFromNumeroComprobante(comprobante, formatoComprobante)) {
                    $rootScope.isProcessing = false;
                    var msg = $filter("translate")(
                        "DIGITOS_COMPROBANTE_SUCURSAL_WARNING"
                    );
                    notify({
                        message: msg,
                        classes: "alert-danger",
                        position: "right"
                    });
                    // El campo queda invalido.
                    form.numero.$error.pattern = true;
                    form.numero.$setValidity("error", false);
                    return comprobante;
                }

                // se obtiene la sucursal completa
                comprobante.sucursalProveedor = sucursalProveedor;
                if (
                    validatePuntoEmisionSucursalFromNumeroComprobante(
                        comprobante,
                        formatoComprobante
                    )
                ) {
                    form.numero.$setValidity("error", true);

                } else {
                    $rootScope.isProcessing = false;
                    var msg = $filter("translate")(
                        "DIGITOS_COMPROBANTE_PUNTO_EMISION_WARNING"
                    );
                    notify({
                        message: msg,
                        classes: "alert-danger",
                        position: "right"
                    });
                    // El campo queda invalido.
                    form.numero.$error.pattern = true;
                    form.numero.$setValidity("error", false);
                    return comprobante;
                }
            }
        }
        return comprobante;
    }

    function completeNroFactura(comprobante) {
        var nro = comprobante.numero;
        if (nro) {
            var nroArr = nro.split("-");
            if (nroArr.length == 3) {
                if (nroArr[0].length < 3) {
                    var cantCeros = 3 - nroArr[0].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[0] = ceros + nroArr[0];
                }
                if (nroArr[1].length < 3) {
                    var cantCeros = 3 - nroArr[1].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[1] = ceros + nroArr[1];
                }
                if (nroArr[2].length < 7) {
                    var cantCeros = 7 - nroArr[2].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[2] = ceros + nroArr[2];
                }
            } else if (nroArr.length == 2) {
                if (nroArr[0].length < 3) {
                    var cantCeros = 3 - nroArr[0].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[0] = ceros + nroArr[0];
                }
                if (nroArr[1].length < 3) {
                    var cantCeros = 3 - nroArr[1].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[1] = ceros + nroArr[1];
                }
            } else if (nroArr.length == 1) {
                if (nroArr[0].length < 3) {
                    var cantCeros = 3 - nroArr[0].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[0] = ceros + nroArr[0];
                }
            }
            if (nroArr.length == 3) {
                comprobante.numero = nroArr[0] + "-" + nroArr[1] + "-" + nroArr[2];
            } else if (nroArr.length == 2) {
                comprobante.numero = nroArr[0] + "-" + nroArr[1];
            } else if (nroArr.length == 1) {
                comprobante.numero = nroArr[0];
            } else {
                comprobante.numero = "";
            }
        }
        return comprobante.numero;
    }

    function validateNumeroComprobante(comprobante, formatString) {
        var input = comprobante.numero;
        /*
        Cambio debido a la incidencia qual-3130


        if (comprobante.tipo && comprobante.tipo.clase.codigo == "nota_credito") {
            console.log(
                "se salta validación de formato de nro. de comprobante por ser nota de crédito"
            );
            return true; //no se valida numero comprobante
        }*/
        // var sinCaracteresEspeciales = /^\d+$/.test(input);
        var sinCaracteresEspeciales = /^(\d*\-*)*$/.test(input);

        if (!sinCaracteresEspeciales) {
            // formatString = formatString.replace(/-/g, "");
            // return input.length == formatString.length;
            return false;
        }

        var inputSegments = input.split("-");
        var formatSegments = formatString.split("-");

        if (inputSegments.length != formatSegments.length) {
            return false;
        }

        for (var i = 0; i < inputSegments.length; i++) {
            if (inputSegments[i].length != formatSegments[i].length) {
                return false;
            }

            if (!parseInt(inputSegments[i])) {
                return false;
            }
        }
        return true;
    }

    function validateSucursalProveedorFromNumeroComprobante(
        comprobante,
        formatoString
    ) {
        var sucursal = comprobante.sucursalProveedor;
        var numero = comprobante.numero;
        var numeroSucursalFormat = formatoString.split("-")[0];

        var numeroSucursal = numero.substring(0, numeroSucursalFormat.length);
        return numeroSucursal == sucursal.codigoEstablecimiento;
    }

    function getDiferencia(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/comprobante/diferencias/" + id,
            method: "GET"
        });
    }

    function validatePuntoEmisionSucursalFromNumeroComprobante(
        comprobante,
        formatoString
    ) {
        var sucursal = comprobante.sucursalProveedor;
        var numero = comprobante.numero;
        var splittedFormat = formatoString.split("-");
        var lengthNumeroSucursal = splittedFormat[0].length;
        numero = numero.replace(/-/g, "");
        var numeroPuntoEmision = numero.substring(
            lengthNumeroSucursal,
            lengthNumeroSucursal + splittedFormat[1].length
        );
        //console.log("numero punto de emisión: %o ", numeroPuntoEmision);
        return _.find(sucursal.puntosEmisionSucursal, function (puntoEmision) {
            return puntoEmision.puntoEmision == numeroPuntoEmision;
        });
    }

    //vm.comprobante, vm.monedaLocal[0], vm.diferenciaMonedaLocal[0], vm.diferenciaMonedaExtranjera[0]
    function validateMontos(
        comprobante,
        monedaLocal,
        diffMonedaLocal,
        diffMonedaExtranjera
    ) {
        var diffPermitida = 0;

        if (comprobante.moneda1.id == monedaLocal.valorTipo.id) {
            diffPermitida = Number(diffMonedaLocal.valor);
        } else {
            diffPermitida = Number(diffMonedaExtranjera.valor);
        }
        // console.log("diffPermitida", diffPermitida);
        if (
            comprobante.grupoCompra &&
            comprobante.grupoCompra.codigo === "comprobante_importacion" &&
            comprobante.fob <= 0
        ) {
            return [false, "fob_invalid"];
        }
        sumaImponible = 0;
        var sumaTotal = _.reduce(
            comprobante.comprobanteDetalle,
            function (suma, valor) {
                return suma + valor.totalItem;
            },
            0
        );
        var diffAux = Math.abs(parseFloat(sumaTotal.toFixed(8)) - comprobante.montoTotal);
        var diff = 0;

        if (comprobante.moneda1.id == monedaLocal.valorTipo.id) {
            diff = diffAux;
        } else {
            diff = parseFloat(diffAux.toFixed(2));
            console.log("diff1", diff);
            diffPermitida = parseFloat(diffPermitida.toFixed(2));
        }
        // console.log("diff", diff);
        //validación de impuestos
        var tiposImpuestoDetalleID, tiposImpuestoCabeceraID;

        if (comprobante.comprobanteDetalle.length > 0) {
            // montos imponibles de los detalles
            var comprobantesTipoImpuesto = _.groupBy(
                comprobante.comprobanteDetalle,
                function (detalle) {
                    return detalle.montosImponibles[0].tipoImpuesto.id;
                }
            );
            // montos imponibles de la cabecera
            var idExento;
            var montosImpuestos = _.groupBy(comprobante.montosImponibles, function (
                mi
            ) {
                if(mi.tipoImpuesto.descripcion === "Exento"){
                  idExento = mi.tipoImpuesto.id
                }
                return mi.tipoImpuesto.id;
            });

            _.find(comprobante.comprobanteDetalle, function (detalle) {
              if(detalle.montosImponibles[0].valorExento){
                comprobantesTipoImpuesto[idExento] = [detalle]
                return true;
              }
            })

            // totales de los detalles
            var totalesDetalles = _.map(comprobantesTipoImpuesto, function (
                valor,
                clave
            ) {
                var obj = {};
                if(clave == idExento){
                  obj[clave] = 0
                } else {
                  obj[clave] = _.reduce(
                    valor,
                    function (total, v) {
                      return total + v.montosImponibles[0].valor;
                    },
                    0
                  );
                }
                return obj;
            });
            // totales de la cabecera
            var totalesImpuestos = _.map(montosImpuestos, function (valor, clave) {
                var obj = {};
                obj[clave] = _.reduce(
                    valor,
                    function (total, v) {
                        return total + v.valor;
                    },
                    0
                );
                return obj;
            });

            tiposImpuestoDetalleID = Object.keys(comprobantesTipoImpuesto);
            tiposImpuestoCabeceraID = Object.keys(montosImpuestos);

            var diffAcumulada = 0;
            for (x in totalesDetalles) {
                var keysA = Object.keys(totalesDetalles[x])[0];
                for (y in totalesImpuestos) {
                    var keysB = Object.keys(totalesImpuestos[y])[0];
                    if (keysA === keysB) {
                        diffAcumulada += Math.abs(
                            totalesDetalles[x][keysA] - totalesImpuestos[y][keysB]
                        );
                    }
                }
            }
        } else {
            // no hay comprobante detalles
            console.log("NO COINCIDEN LOS IMPUESTOS. 1");
            return [false, "impuestos_mismatch"];
        }

        // console.log("diffAcumulada", diffAcumulada);

        console.log("diff permitida ", diffPermitida);
        console.log("tiposImpuestoDetalleID.sort() ", tiposImpuestoDetalleID.sort());


        if (!_.isEqual(
            tiposImpuestoDetalleID.sort(),
            tiposImpuestoCabeceraID.sort()
        ) ||
            totalesDetalles.length != totalesImpuestos.length ||
            diffAcumulada > diffPermitida
        ) {
            console.log("NO COINCIDEN LOS IMPUESTOS. 2");
            return [false, "impuestos_mismatch"];
        }

        //comprobamos montos totales
        if (diff <= diffPermitida) {
            console.log("MONTOS MATCH");
            return [true];
        } else {
            console.log("MONTOS MISMATCH");
            console.log("diff", diff);
            console.log("diffPermitida", diffPermitida);
            return [false, "montos_mismatch"];
        }
    }

    function igualarTipoImpuestoCabeceraADetealle(comprobante) {
        //validación de impuestos
        var moneda = comprobante.moneda1;
        var comprobantesTipoImpuesto = _.groupBy(
            comprobante.comprobanteDetalle,
            function (detalle) {
                return detalle.montosImponibles[0].tipoImpuesto.id;
            }
        );

        var montosImpuestos = _.groupBy(comprobante.montosImponibles, function (mi) {
            return mi.tipoImpuesto.id;
        });

        var totalesDetalles = _.map(comprobantesTipoImpuesto, function (
            valor,
            clave
        ) {
            var obj = {};
            obj[clave] = _.reduce(
                valor,
                function (total, v) {
                    return (
                        total +
                            v.montosImponibles[0].valor +
                            v.montosImponibles[0].baseImponible
                    );
                },
                0
            );
            return obj;
        });
        // console.log("totalesDetalles", totalesDetalles);

        var totalesBases = _.map(comprobantesTipoImpuesto, function (valor, clave) {
            var obj = {};
            obj[clave] = _.reduce(
                valor,
                function (total, v) {
                    return total + v.montosImponibles[0].baseImponible;
                },
                0
            );
            return obj;
        });
        // console.log("totalesBases", totalesBases);

        var totalesImpuestos = _.map(comprobantesTipoImpuesto, function (
            valor,
            clave
        ) {
            var obj = {};
            obj[clave] = _.reduce(
                valor,
                function (total, v) {
                    return total + v.montosImponibles[0].valor;
                },
                0
            );
            return obj;
        });
        // console.log("totalesImpuestos", totalesImpuestos);

        var diffAcumulada = 0;
        for (x in totalesDetalles) {
            var keysA = Object.keys(totalesDetalles[x])[0];
            for (y in comprobante.montosImponibles) {
                if (keysA === comprobante.montosImponibles[y].tipoImpuesto.id + "") {
                    comprobante.montosImponibles[y].total =
                      parseFloat(totalesDetalles[x][keysA].toFixed(8));
                }
            }
        }
        for (x in totalesBases) {
            var keysA = Object.keys(totalesDetalles[x])[0];
            for (y in comprobante.montosImponibles) {
                if (keysA === comprobante.montosImponibles[y].tipoImpuesto.id + "") {
                    comprobante.montosImponibles[y].baseImponible =
                    parseFloat(totalesBases[x][keysA].toFixed(8));
                }
            }
        }
        for (x in totalesImpuestos) {
            var keysA = Object.keys(totalesDetalles[x])[0];
            for (y in comprobante.montosImponibles) {
                if (keysA === comprobante.montosImponibles[y].tipoImpuesto.id + "") {
                    comprobante.montosImponibles[y].valor =
                      parseFloat(totalesImpuestos[x][keysA].toFixed(8));
                }
            }
        }

        comprobante.montoTotal = 0;
        for (x in totalesDetalles) {
            var keysA = Object.keys(totalesDetalles[x])[0];
            for (y in comprobante.montosImponibles) {
                if (keysA === comprobante.montosImponibles[y].tipoImpuesto.id + "") {
                    comprobante.montoTotal +=
                      parseFloat(totalesDetalles[x][keysA].toFixed(8));
                }
            }
        }
        return comprobante;
    }

    function downloadCSVHechaukaCompras(mes, anio, tiporeporte, error) {
        $http({
            url: baseurl.getBaseUrl() + "/hechauka/compra/csv",
            method: "GET",
            params: { mes: mes, anio: anio, tiporeporte: tiporeporte }
        }).then(
            function (response) {
                if (response.data.filename != undefined) {
                    $window.open(
                        baseurl.getPublicBaseUrl() + response.data.filename,
                        "_blank"
                    );
                } else {
                    notify({
                        message: "Error al generar el archivo.",
                        classes: "alert-danger",
                        position: "right"
                    });
                }
            },
            function (response) {
                var mensaje = "<span>";
                _.forEach(response.data.error, function (err) {
                    mensaje = mensaje + "<br/>" + err;
                });
                mensaje = mensaje + "</span>";
                notify({
                    messageTemplate: mensaje,
                    classes: "alert-danger",
                    position: "right",
                    templateUrl: ""
                });
            }
        );
    }

    function aprobarImportacion(id) {
        return Comprobante.aprobarImportacion({ id: id }).$promise;
    }

    function getEstaAsentado(id) {
        return $http({
            url: baseurl.getBaseUrl() + '/comprobante/estaAsentado/' + id,
            method: "GET"
        });
    }

    function downloadCSVArchivoCompras(mes, anio, error) {
        $http({
            url: baseurl.getBaseUrl() + "/comprobante/archivocompra/csv",
            method: "GET",
            params: { mes: mes, anio: anio }
        }).then(
            function (response) {
                if (response.data.filename != undefined) {
                    $window.open(
                        baseurl.getPublicBaseUrl() + response.data.filename,
                        "_blank"
                    );
                } else {
                    notify({
                        message: "Error al generar el archivo.",
                        classes: "alert-danger",
                        position: "right"
                    });
                }
            },
            function (response) {
                var mensaje = "<span>";
                _.forEach(response.data.error, function (err) {
                    mensaje = mensaje + "<br/>" + err;
                });
                mensaje = mensaje + "</span>";
                notify({
                    messageTemplate: mensaje,
                    classes: "alert-danger",
                    position: "right",
                    templateUrl: ""
                });
            }
        );
    }

    /* function downloadCSVSaldoProveedores(fecha, error, vm) {
      $http({
          url: baseurl.getBaseUrl() + "/comprobante/saldo-proveedores/csv",
          method: "GET",
          params: { fecha: fecha}
      }).then(
          function (response) {
              if (response.data.filename != undefined) {
                  $window.open(
                      baseurl.getPublicBaseUrl() + response.data.filename,
                      "_blank"
                  );
              } else {
                  notify({
                      message: "Error al generar el archivo.",
                      classes: "alert-danger",
                      position: "right"
                  });
              }
          },
          function (response) {
              var mensaje = "<span>";
              _.forEach(response.data.error, function (err) {
                  mensaje = mensaje + "<br/>" + err;
              });
              mensaje = mensaje + "</span>";
              notify({
                  messageTemplate: mensaje,
                  classes: "alert-danger",
                  position: "right",
                  templateUrl: ""
              });
          }
      );
  } */
  function carga(name, locacion) {
    return $http({
      url: baseurl.getBaseUrl() + '/comprobante/carga',
      method: "POST",
      params: { filename: name, locacion: locacion}
    });
  }
}

'use strict';

angular.module('qualita.importacion')
  .controller('ComprobanteListCtrl', ComprobanteListCtrl);

ComprobanteListCtrl.$inject = ["AuthorizationService", "ComprobanteFactory",
  "ImportacionFactory", "$modal", "$scope", "$state",
  "filterFactory", "ImportacionLangFactory",
  "WatchLocacionChangeFactory", "$filter", "$q", "PeriodoAdministrativoFactory", "notify",
  "ParametrosFactory"
];

function ComprobanteListCtrl(AuthorizationService, ComprobanteFactory,
  ImportacionFactory, $modal, $scope, $state, filterFactory,
  ImportacionLangFactory, WatchLocacionChangeFactory, $filter, $q, PeriodoAdministrativoFactory,
  notify, ParametrosFactory) {
  var vm = this;

  activate();

  function activate() {
    ImportacionLangFactory.getTranslations().then(function (translations) {
      vm.translations = translations;
      var importacion = ImportacionFactory.getImportacion();
      if (!importacion) {
        $state.go("app.main");
      }
      var isStateEnabled = ImportacionFactory.checkPermissionForState(0);
      if (!isStateEnabled) {
        console.log("No tiene permiso para revisar esta seccion");
        $state.go("app.main");
      }

      var monedaRender = function (data, type, row) {
        if (data) {
          var moneda = "Gs. ";
          if (row.moneda1.codigo === 'dolares') {
            moneda = "Usd. ";
            data = parseFloat(data).toFixed(2);
          } else if (row.moneda1.codigo === 'pesos') {
            moneda = "Pes. ";
            data = parseFloat(data).toFixed(2);
          } else if (row.moneda1.codigo === 'real') {
            moneda = "Rel. ";
            data = parseFloat(data).toFixed(2);
          } else if (row.moneda1.codigo === 'euro') {
            moneda = "Eur. ";
            data = parseFloat(data).toFixed(2);
          }
          return (
            moneda +
            data
              .toString()
              .replace(".", ",")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          );
        } else {
          return '';
        }
      };

      var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
      var enviarAContabilidad = '';

      promise1.then(function(response) {
          enviarAContabilidad = response.valorTipo.codigo;
      });

      var cambioRender = function (data, type, row) {
        var moneda = "Gs. ";
        if (data) {
          return moneda + data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
          return '';
        }
      };

      var defaultColumnOrder = ['numero', 'fecha', 'grupoCompra.descripcion', 'proveedor.nombre', 'tipo.descripcion', 'proyecto.nombre', 'estado.descripcion', 'montoTotal', 'saldo',
        'ordenCompra.numero', 'embarque.numero', /*'proforma.numero',*/ 'moneda1.descripcion', 'fob', 'valorSeguro', 'sucursalProveedor.descripcion',
        'timbradoProveedor.numero', 'cambio', 'valorFlete'
      ];

      // filtro estático
      var staticFilter = {};
      staticFilter.search = filterFactory.and([{
        path: 'importacion.codigo',
        equals: ImportacionFactory.getCodigoImportacion(),
      },
      {
        path: 'tipo.clase.codigo',
        notEquals: "despacho"
      }]).value();

      var renderEmptyData = function (data) {
        if (data == undefined)
          return "";
        else
          return data;
      };

      vm.options = {
        'staticFilter': staticFilter,
        'resource': 'comprobante',
        'view': 'ComprobanteList',
        'title': 'Comprobante',
        'factory': ComprobanteFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('COMPROBANTE_DELETE_FAILED_WARNING'),
        'columns': [
          { 'data': 'grupoCompra.descripcion', 'title': $filter('translate')('GRUPO_COMPRA'), 'class': 'dt-center', 'render': renderEmptyData },
          { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
          { 'data': 'ordenCompra.numero', 'title': $filter('translate')('ORDEN_COMPRA'), 'class': 'dt-center', visible: false, 'render': renderEmptyData },
          { 'data': 'embarque.numero', 'title': $filter('translate')('EMBARQUE'), 'class': 'dt-center', visible: false, 'render': renderEmptyData },
          { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_SINGLE') },
          { 'data': 'sucursalProveedor.descripcion', 'title': $filter('translate')('SUCURSAL_PROVEEDOR'), visible: false, 'renderWith': 'emptyRender' },
          { 'data': 'tipo.descripcion', 'title': $filter('translate')('TIPO_COMPROBANTE') },
          { 'data': 'proyecto.nombre', 'title': $filter('translate')('PROYECTO'), 'class': 'dt-center', 'renderWith': 'emptyRender' },
          //{'data': 'proforma.numero', 'title': $filter('translate')('PROFORMA'), 'class' : 'dt-center'},
          { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-center' }, //deber numero de comprobante
          { 'data': 'timbradoProveedor.numero', 'title': $filter('translate')('TIMBRADO_PROVEEDOR'), 'class': 'dt-center', 'renderWith': 'emptyRender' },
          { 'data': 'moneda1.descripcion', 'title': $filter('translate')('CURRENCY'), 'class': 'dt-center', visible: false },
          { 'data': 'cambio', 'title': $filter('translate')('TIPO_CAMBIO'), 'class': 'dt-right', visible: false, renderWith: cambioRender },
          { 'data': 'valorSeguro', 'title': $filter('translate')('VALOR_SEGURO'), 'class': 'dt-right', visible: false, renderWith: monedaRender },
          { 'data': 'valorFlete', 'title': $filter('translate')('VALOR_FLETE'), 'class': 'dt-right', visible: false, renderWith: monedaRender },
          { 'data': 'fob', 'title': $filter('translate')('FOB'), 'class': 'dt-right', visible: false, renderWith: monedaRender },
          { 'data': 'montoTotal', 'title': $filter('translate')('MONTO'), 'class': 'dt-right', renderWith: monedaRender }
          //{'data': 'saldo', 'title': $filter('translate')('SALDO_PENDIENTE'), 'class' : 'dt-center', renderWith: monedaRender}
        ],
        'hasOptions': true,
        'hideAddMenu': true,
        'extraMenuOptions': [{
          'title': 'Nuevo',
          'icon': 'glyphicon glyphicon-plus',
          'showCondition': function () {
            var permission = AuthorizationService.hasPermission('create_comprobante') && ImportacionFactory.getImportacion().estado.codigo != "cerrada";
            return permission;
          },
          'action': function () {
            checkPeriodoAdministrativo(null).then(function (resultado) {
              if (resultado == true) {
                $state.go("app.importaciones.proceso.comprobante.new")
              } else if (resultado == false) {
                var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
              }
            });
          }
        }],
        'defaultOrderColumn': 1,
        'defaultOrderDir': "desc",
        'extraRowOptions': [{
          templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          functionName: "editar",
          functionDef: function (attributes) {
            var itemId = attributes[0];
            $state.go("app.importaciones.proceso.comprobante.edit", { id: itemId });
          },
          conditionName: "canEditar",
          customAttribute: ["id", "tipo", "estado"],
          conditionDef: function (attributes) {
            var tipo = attributes[1];
            var estado = attributes[2];

            return (AuthorizationService.hasPermission("update_comprobante") &&
              ImportacionFactory.getImportacion().estado.codigo != "cerrada" && tipo.clase && tipo.clase.codigo != "despacho") &&
              estado.codigo === "comp_ingresado";

          }
        },
        {
          templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataCustom%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
          functionName: "borrar",
          functionDef: function (attributes) {
            var itemId = attributes[0];
            var estado = attributes[1];

            $scope.selectedItemId = itemId;
            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
            $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
            $scope.modalInstanceBorrar1 = $modal.open({
              templateUrl: 'views/directives/confirmation-modal.html',
              scope: $scope
            });

            $scope.cancel = function () {
              $scope.modalInstanceBorrar1.dismiss('cancel');
            }

            $scope.ok = function (itemId) {
              var model = ComprobanteFactory.create({ id: itemId });
              ComprobanteFactory.remove(model).then(function () {
                $scope.modalInstanceBorrar1.close(itemId);
                $state.go($state.current, {}, { reload: true });
              }, function (error) {
                $scope.modalInstanceBorrar1.dismiss('cancel');
                $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                $scope.mensajeModal = $filter('translate')('DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS');
                var modalInstance = $modal.open({
                  template: '<div class="modal-header">' +
                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                    '</div>' +
                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                    '<div class="modal-footer">' +
                    '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                    '</div>',
                  scope: $scope
                });
                $scope.cancel = function () {
                  modalInstance.dismiss('cancel');
                };
                console.log("error al borrar: ");
                console.log(error);
              });
            }
          },
          conditionName: "canBorrar",
          customAttribute: ["id", "estado"],
          conditionDef: function (attributes) {
            var estado = attributes[1];
            return (AuthorizationService.hasPermission('delete_comprobante') &&
              ImportacionFactory.getImportacion().estado.codigo != "cerrada") &&
              estado.codigo === "comp_ingresado";
          },
        },
        {
          templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
          functionName: "ver",
          functionDef: function (itemId) {
            $state.go("app.importaciones.proceso.comprobante.view", { id: itemId });
          },
          conditionName: "canVer",
          conditionDef: function () {
            return (AuthorizationService.hasPermission('index_comprobante'));
          }
        },
        {
          templateToRender: "<button class='btn btn-primary' style='margin-right: 5px;' title='" + $filter('translate')('PLAN_PAGOS') + "' ng-click='planPagos(<%=dataId%>)' ng-class='{ hidden : !canViewPlanPago(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-tasks'></span> </button>",
          customAttribute: 'tipo',
          functionName: "planPagos",
          functionDef: function (itemId) {
            ComprobanteFactory.get(itemId, "ComprobanteList").$promise.then(function (c) {
              $state.go("app.importaciones.proceso.comprobante.planpagos.list", { idComprobante: itemId, idPlanPago: c.planPagos.id });
            });
          },
          conditionName: "canViewPlanPago",
          conditionDef: function (tipoComprobante) {
            return (AuthorizationService.hasPermission("update_comprobante") &&
              tipoComprobante.generarCuotas == true);
          }
        },
        {
          templateToRender: "<button title='" +
            $filter("translate")("CONFIRMAR_COMPROBANTE") +
            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataCustom%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
          functionName: "aprobar",
          conditionName: "canAprobar",
          customAttribute: ["id", "estado"],
          conditionDef: function (attributes) {

            var estado = attributes[1];
            return (AuthorizationService.hasPermission("aprobar_comprobante") &&
              estado.codigo == "comp_ingresado");
          },
          functionDef: function (attributes) {

            var itemId = attributes[0];
            $scope.selectedItemId = itemId;
            $scope.tituloModal = $filter('translate')('CONFIRMAR_COMPROBANTE');
            $scope.mensajeModal = $filter('translate')('CONFIRMAR_COMPROBANTE_MESSAGE');

            var modalInstance = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });

            $scope.cancel = function () {
              modalInstance.dismiss("cancel");
            };

            $scope.ok = function (itemId) {
              ComprobanteFactory.aprobarImportacion(itemId).then(
                function (response) {
                  // ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                  //   if (parametro.valorTipo.codigo == 'si') {
                  //     if (response.enviadoAContabilidad == false) {
                  //       notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                  //     }
                  //   }
                  // });
                  modalInstance.close();
                  $state.go($state.current, {}, { reload: true });
                },
                function (error) {
                  if (error.data) {
                    var msg = error.data[0].message;
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  }
                }
              );
            };
          }
        },
        {
          templateToRender: "<button title='" +
            $filter("translate")("DESAPROBAR_COMPROBANTE") +
            "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='desaprobar(<%=dataId%>)' ng-class='{ hidden : !canDesaprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
          functionName: "desaprobar",
          conditionName: "canDesaprobar",
          customAttribute: ["estado"],
          conditionDef: function (atributos) {
            var estado = atributos[0];

            return AuthorizationService.hasPermission("aprobar_comprobante") &&
              estado.codigo == "comp_aprobado";
          },
          functionDef: function (itemId) {

            $scope.selectedItemId = itemId;
            $scope.tituloModal = $filter('translate')('DESCONFIRMAR_COMPROBANTE');
            $scope.mensajeModal = $filter('translate')('DESCONFIRMAR_COMPROBANTE_MESSAGE');

            var modalInstance = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });

            $scope.cancel = function () {
              modalInstance.dismiss("cancel");
            };

            $scope.ok = function (itemId) {
              ComprobanteFactory.desaprobarImportacion(itemId).then(
                function (response) {
                  modalInstance.close();
                  $state.go($state.current, {}, { reload: true });
                },
                function (error) {
                  if (error.data) {
                    var msg = error.data[0].message;
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  }
                }
              );
            };
          }
        },
        {
          templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
          functionName: 'reenviar',
          customAttribute: ['estado', 'enviadoAContabilidad', 'id'],
          functionDef: function (atributo) {

            var idVenta = atributo[2];

            ComprobanteFactory.get(idVenta, "ComprobanteList").$promise.then(function (
              response
            ) {
              $scope.tituloModal = $filter('translate')('REENVIAR_COMPRA_Q');
              $scope.mensajeModal = $filter('translate')('REENVIAR_COMPRA_MSG');
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              $scope.ok = function (id) {
                ComprobanteFactory.reenviar(response.id).then(function (response) {
                  // ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                  //   if (parametro.valorTipo.codigo == 'si') {
                  //     if (response.enviadoAContabilidad == false) {
                  //       notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                  //     }
                  //     if (response.listaArchivosLogs != null) {
                  //       if (response.listaArchivosLogs.length != 0) {
                  //         /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                  //             console.log('response.listaArchivosLogs: ');
                  //             console.log(response.listaArchivosLogs[i]);
                  //             $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                  //         }*/
                  //         notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                  //       }
                  //     }
                  //   }
                  // });
                  $state.go($state.current, {}, { reload: true });
                }, function (error) {
                  var msg = "";
                  if (error.data && error.data.length > 0 && error.data[0].message) {
                    msg = error.data[0].message;
                  } else {
                    msg = $filter('translate')('REENVIAR_COMPRA_FAILED');
                  }

                  notify({ message: msg, classes: 'alert-danger', position: 'right' });
                });
                modalInstance.close();
              }
            });
          },
          conditionName: 'canReenviar',
          conditionDef: function (atributo) {
            var estado = atributo[0];
            var enviadoAContabilidad = atributo[1];
            var id = atributo[2];

            if (enviarAContabilidad == 'si') {
              if (estado.codigo === 'comp_aprobado') {
                if (enviadoAContabilidad === "No") {
                  return true;
                }
              }
            }
          }
        }
        ],
        'hideEditMenu': true,
        'hideRemoveMenu': true,
        'hideViewMenu': true
      };
      WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");
      var getIDs = function () {
        var ids = [];
        _.forEach(vm.options.selection, function (n, key) {
          if (n)
            ids.push(key);
        });
        return ids;
      }
    });
  }

  /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
  var checkPeriodoAdministrativo = function (fechaComprobante) {
    return $q(function (resolve, reject) {

      var params = {};
      var fecha;

      if (fechaComprobante == null) {
        fecha = new Date();
      } else {
        fecha = fechaComprobante;
      }

      params.search = filterFactory
        .and([{
          path: "estado.codigo",
          equals: "per_adm_abierto"
        },
        {
          path: "fechaDesde",
          equalOrBefore: moment(fecha).format("DD/MM/YYYY")
        },
        {
          path: "fechaHasta",
          equalOrAfter: moment(fecha).format("DD/MM/YYYY")
        }
        ])
        .value();

      PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {
        if (responsePeriodo.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
    });
  }


}


"use strict";

angular
        .module("qualita.importacion")
        .controller("ComprobanteFormCtrl", ComprobanteFormCtrl);

ComprobanteFormCtrl.$inject = [
    "ComprobanteFactory",
    "comprobantePrepService",
    "ProductosFactory",
    "TiposFactory",
    "ProveedoresFactory",
    "ImportacionFactory",
    "TipoComprobanteFactory",
    "GruposCompraFactory",
    "CotizacionMonedaFactory",
    "ServicioFactory",
    "filterFactory",
    "ParametrosFactory",
    "OrdenesCompraFactory",
    "$scope",
    "notify",
    "$state",
    "$rootScope",
    "$timeout",
    "formFactory",
    "TipoImpuestosFactory",
    "TimbradoProveedorFactory",
    "ProveedorSucursalesFactory",
    "ProformaFactory",
    "SucursalesFactory",
    "EmbarqueFactory",
    "ImportacionLangFactory",
    "WatchLocacionChangeFactory",
    "UtilFactory",
    "ComprobanteDetalleFactory",
    "ProyectoFactory",
    "$filter",
    "$q",
    "PeriodoAdministrativoFactory"
];

function ComprobanteFormCtrl(
        ComprobanteFactory,
        comprobantePrepService,
        ProductosFactory,
        TiposFactory,
        ProveedoresFactory,
        ImportacionFactory,
        TipoComprobanteFactory,
        GruposCompraFactory,
        CotizacionMonedaFactory,
        ServicioFactory,
        filterFactory,
        ParametrosFactory,
        OrdenesCompraFactory,
        $scope,
        notify,
        $state,
        $rootScope,
        $timeout,
        formFactory,
        TipoImpuestosFactory,
        TimbradoProveedorFactory,
        ProveedorSucursalesFactory,
        ProformaFactory,
        SucursalesFactory,
        EmbarqueFactory,
        ImportacionLangFactory,
        WatchLocacionChangeFactory,
        UtilFactory,
        ComprobanteDetalleFactory,
        ProyectoFactory,
        $filter,
        $q,
        PeriodoAdministrativoFactory) {
    var vm = this;

    activate();

    vm.agregarDetalle = agregarDetalle;
    vm.agregarDetalleServicio = agregarDetalleServicio;
    vm.agregarDetalleProducto = agregarDetalleProducto;
    vm.agregarMontoImponible = agregarMontoImponible;
    vm.calcularTotalDetalle = calcularTotalDetalle;
    vm.removeItemFromArray = removeItemFromArray;
    vm.searchProductoFilter = searchProductoFilter;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.changeOC = changeOC;
    vm.changeTipo = changeTipo;
    vm.changeProducto = changeProducto;
    vm.changeServicio = changeServicio;
    vm.changeCotizacion = changeCotizacion;
    vm.changeGrupoCompra = changeGrupoCompra;
    vm.mostrarTodosProductos = mostrarTodosProductos;
    vm.totalImpuestoChanged = totalImpuestoChanged;
    vm.tipoImpuestoChanged = tipoImpuestoChanged;
    vm.changeProveedor = changeProveedor;
    vm.proformaSelected = proformaSelected;
    vm.changeEmbarque = changeEmbarque;
    vm.totalItemChanged = totalItemChanged;
    vm.validarNroFactura = validarNroFactura;
    vm.tipoComprobanteSelected = tipoComprobanteSelected;
    vm.actualizarMontoImponibleImp = actualizarMontoImponibleImp;
    vm.changeSucursalProveedor = changeSucursalProveedor;
    vm.changeMoneda = changeMoneda
    vm.changeFecha = changeFecha


    var sumaImponible;
    var savedTime = undefined;

    $rootScope.isProcessing = false;

    function activate() {
        vm.requerirTimbrado = true;
        ParametrosFactory.filterByCodigo("formato_nro_comprobante").$promise.then(function (response) {
            vm.formatoComprobante = response[0].valor;
        })
        WatchLocacionChangeFactory.killAllWatchers();
        ImportacionLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;

            // Se inicializa con undefined para que no bloquee el campo 'tipo cambio'
            vm.cotizacionRequerida = undefined;

            vm.valorFOBRequerido = false;
            var filterProyecto = {};
            filterProyecto.view = "BaseList"
            vm.proyectos = ProyectoFactory.all(filterProyecto, "ProyectoList");
            getMonedaLocal();
            getParamDiferenciaMonedaLocal();
            getParamDiferenciaMonedaExtranjera();

            var importacion = ImportacionFactory.getImportacion();
            vm.importacion = importacion;

            if (!importacion) {
                $state.go("app.main");
            }
            var isStateEnabled = ImportacionFactory.checkPermissionForState(0);
            if (!isStateEnabled) {
                //console.log("No tiene permiso para revisar esta seccion");
                $state.go("app.main");
            }

            vm.curDate = new Date();
            if ($state.is("app.importaciones.proceso.comprobante.new")) {
                activateNew();
            } else if ($state.is("app.importaciones.proceso.comprobante.edit")) {
                activateEdit();
            } else if ($state.is("app.importaciones.proceso.comprobante.view")) {
                activateView();
            }

            var paramsProv = {};
            paramsProv.search = filterFactory
                    .single({
                        path: "activo",
                        equals: true
                    })
                    .value();

            vm.proveedores = ProveedoresFactory.all(paramsProv);

            if (vm.watcherProforma) {
                vm.watcherProforma();
            }
            console.clear();
            /*WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcherProforma
                    ) {
                      console.log(locacionFilter);
                vm.watcherProforma = watcherProforma;
                var filtroProforma = {};
                filtroProforma.search = filterFactory.and([
                        {
                          path: "proveedorOC.id",
                          equals: vm.importacion.proveedor.id
                        }
                ]).value();
                vm.proformas = ProformaFactory.all(filtroProforma, "ProformaImportacionList");
            }, "locacion.id");*/
            console.log(vm.importacion.proveedor.id);
            var filtroProforma = {};
            filtroProforma.search = filterFactory.and([
                    {
                      path: "proveedorOC.id",
                      equals: vm.importacion.proveedor.id
                    }
            ]).value();
            console.log(filtroProforma);
            vm.proformas = ProformaFactory.all(filtroProforma, "ProformaImportacionList");

            var filtroServicios = {};
            filtroServicios.search = filterFactory.and([{
                    path: 'compra',
                    equals: true
                }]).value();

            // filtro estático
            var filtroCompra = {};
            filtroCompra.search = filterFactory
                    .and([{
                            path: 'compra',
                            like: 'true'
                        }]).value();


            var filtroTipoComprobante = {};
            filtroTipoComprobante.search = filterFactory
                    .and([
                        {
                            path: 'utilizarEnProcesosCompra',
                            like: 'true'
                        },
                        {
                            path: "activo",
                            equals: true
                        }
                    ]).value();

            vm.servicios = ServicioFactory.all(filtroServicios);
            vm.tipos = TipoComprobanteFactory.all(filtroTipoComprobante);
            vm.gruposCompra = getGruposCompraImportacion();
            vm.tiposImpuesto = TipoImpuestosFactory.all({view: "BaseList"});

            var staticFilterEmb = {};
            staticFilterEmb.search = filterFactory
                    .and([{
                            path: "importacion.codigo",
                            equals: ImportacionFactory.getCodigoImportacion()
                        }])
                    .value();

            if (vm.watcherEmbarque) {
                vm.watcherEmbarque();
            } //hacemos unwatch de la expresion anterior
            WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcherEmbarque
                    ) {
                vm.watcherEmbarque = watcherEmbarque;
                EmbarqueFactory.all(
                        UtilFactory.createNestedFilters(
                                locacionFilter.search.filters,
                                staticFilterEmb,
                                "EmbarqueList"
                                )
                        ).$promise.then(function (response) {
                    vm.embarques = response;
                });
            }, "locacion.id");

            var staticFilterOC = {};
            staticFilterOC.search = filterFactory
                    .and([{
                            path: "importaciones.codigo",
                            equals: ImportacionFactory.getCodigoImportacion()
                        }])
                    .value();

            if (vm.watchOC) {
                vm.watcherOC();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcher
                    ) {
                vm.watcherOC = watcher;
                OrdenesCompraFactory.all(
                        UtilFactory.createNestedFilters(
                                locacionFilter.search.filters,
                                staticFilterOC,
                                "OrdenCompraImportacionList"
                                )
                        ).$promise.then(function (response) {
                    vm.ordenesCompra = _.filter(response, function (o) {
                        if (o.estado.codigo == "ocl_emitida") {
                            return true;
                        } else {
                            return false;
                        }
                    });
                });
            }, "locacion.id");

            vm.monedas = {};
            TiposFactory.monedas().then(function (data) {
                vm.monedas = data.data;
            });

            vm.factoryProductos = ProductosFactory;

            var defaultColumnOrderProducto = [
                "codigo",
                "nombre",
                "claseProducto.descripcion",
                "estado.descripcion",
                "lineaProducto.descripcion",
                "marca.descripcion"
            ];

            vm.opcionesProductosDt = {
                resource: "productos",
                title: "ProductosComprobantes",
                view: 'ProductosDatatableList',
                factory: ProductosFactory,
                defaultColumnOrder: defaultColumnOrderProducto,
                columns: [
                    {data: "codigo", title: $filter("translate")("ID"), visible: false},
                    {data: "nombre", title: $filter("translate")("NOMBRE")},
                    {
                        data: "claseProducto.descripcion",
                        title: $filter("translate")("KIND")
                    },
                    {
                        data: "estado.descripcion",
                        title: $filter("translate")("STATUS"),
                        type: "combo",
                        filterUrl: "tipos/tipo/producto_estados"
                    },
                    {
                        data: "lineaProducto.descripcion",
                        title: $filter("translate")("LINE")
                    },
                    {
                        data: "marca.descripcion",
                        title: $filter("translate")("BRAND"),
                        renderWith: "emptyRender"
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc"
            };

            if (vm.watcher) {
                vm.watcher();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcher
                    ) {
                vm.watcher = watcher;
                vm.productos = ProductosFactory.allForSelect(locacionFilter);
                vm.opcionesProductosDt.staticFilter = locacionFilter;
            }, "locaciones.id");

            vm.factoryServicios = ServicioFactory;
            var defaultColumnOrderServicio = [
                "id",
                "codigo",
                "descripcion",
                "tipoImpuesto.descripcion"
            ];

            vm.opcionesServiciosDt = {
                staticFilter: filtroCompra,
                resource: "servicios",
                view: 'ServiciosDatatableList',
                title: "Servicio",
                factory: ServicioFactory,
                defaultColumnOrder: defaultColumnOrderServicio,
                columns: [
                    {data: "id", title: $filter("translate")("CODE")},
                    {data: "descripcion", title: $filter("translate")("DESCRIPTION")},
                    {
                        data: "tipoImpuesto.descripcion",
                        title: $filter("translate")("TAX_KIND")
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc"
            }
            var filtroServiciosDt = {};
            filtroServiciosDt.search = filterFactory
                    .and([{
                            path: 'compra',
                            like: 'true'
                        }])
                    .value();
            vm.opcionesServiciosDt.staticFilter = filtroServiciosDt;
        });
        vm.staticFilterProv = [{path: "activo",equals: true}];
        vm.proveedoresFactory = ProveedoresFactory;
    }

    function activateNew() {
        var isClosed = vm.importacion.estado.codigo === "cerrada";
        if (isClosed) {
            $state.go("app.main");
        }
        vm.isNew = true;

        vm.title = $filter("translate")("NEW_COMPROBANTE");
        vm.tiposDetalle = {};
        vm.totalGeneral = 0;
        vm.totalGeneralProductos = 0;
        vm.totalGeneralServicios = 0;
        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;
            vm.comprobante = {
                comprobanteDetalle: [],
                importacion: ImportacionFactory.getIdImportacion(),
                cambio: 1
            };
            //vm.comprobante.cambio=1;
            vm.comprobantesProductos = [{
                    tipo: getTipoProducto(vm.tiposDetalle),
                    montosImponibles: [{}],
                }];
            vm.comprobantesServicios = [{
                    tipo: getTipoServicio(vm.tiposDetalle),
                    montosImponibles: [{}],
                }];
        });
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);


    }

    function getTipoServicio(tipos) {
        var tipoServicio = _.filter(tipos, function (elem) {
            return elem.codigo === "detalle_comprobante_servicio";
        });
        return tipoServicio[0];
    }

    function getTipoProducto(tipos) {
        var tipoProducto = _.filter(tipos, function (elem) {
            return elem.codigo === "detalle_comprobante_producto";
        });
        return tipoProducto[0];
    }

    function activateEdit() {
        var isClosed = vm.importacion.estado.codigo === "cerrada";
        if (isClosed) {
            $state.go("app.main");
        }

        vm.title = $filter("translate")("EDIT_COMPROBANTE");
        vm.tiposDetalle = {};

        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;
            vm.comprobante = comprobantePrepService;

            vm.entidad = "ComprobanteImportacion";
            vm.entidadId = vm.comprobante.id;

            vm.comprobante.fecha = new Date(vm.comprobante.fecha);
            getPlanPagos();
            if (vm.comprobante.timbradoProveedor) {
                vm.comprobante.numeroTimbrado = vm.comprobante.timbradoProveedor.numero;
            }
            vm.curDate = vm.comprobante.fecha;
            if (vm.comprobante.grupoCompra.codigo === "comprobante_importacion") {
                vm.comprobante.moneda1 = vm.comprobante.ordenCompra.moneda;
            }

            if (vm.comprobante.moneda1.codigo != "guaranies") {
                vm.cantDigitos = 4;
            }

            if (vm.comprobante.comprobanteDetalle) {
                _.forEach(vm.comprobante.comprobanteDetalle, function (detalle) {
                    ComprobanteDetalleFactory.get(
                            detalle.id,
                            "ComprobanteForm"
                            ).$promise.then(function (response) {
                        detalle.montosImponibles = response.montosImponibles;
                    });
                });
            }

            splitDetalles();
            vm.isNew = false;
            tipoComprobanteSelected(true);
            changeOC();

            // vm.totalGeneralProductos = _.reduce(
            //     vm.comprobantesProductos,
            //     function(sum, detalle) {
            //         var res = (parseFloat(sum) + parseFloat(detalle.totalItem)).toFixed(4);
            //         return res;
            //     },
            //     0
            // );
            // vm.totalGeneralServicios = _.reduce(
            //     vm.comprobantesServicios,
            //     function(sum, detalle) {
            //         var res = (parseFloat(sum) + parseFloat(detalle.totalItem)).toFixed(4);
            //         return res;
            //     },
            //     0
            // );
            updateTotalGeneral();
        });
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);
    }

    function activateView() {
        vm.title = $filter("translate")("VIEW_COMPROBANTE");
        vm.tiposDetalle = {};
        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;
            vm.comprobante = comprobantePrepService;
            vm.entidad = "ComprobanteImportacion";
            vm.entidadId = vm.comprobante.id;
            getPlanPagos();
            vm.comprobante.fecha = new Date(vm.comprobante.fecha);
            if (vm.comprobante.timbradoProveedor) {
                vm.comprobante.numeroTimbrado = vm.comprobante.timbradoProveedor.numero;
            }

            if (vm.comprobante.grupoCompra.codigo === "comprobante_importacion") {
                vm.comprobante.moneda1 = vm.comprobante.ordenCompra.moneda;
            }

            if (vm.comprobante.moneda1.codigo != "guaranies") {
                vm.cantDigitos = 4;
            }

            splitDetalles();
            vm.isNew = false;
            changeOC();

            // vm.totalGeneralProductos = _.reduce(
            //     vm.comprobantesProductos,
            //     function(sum, detalle) {
            //         var res = (parseFloat(sum) + parseFloat(detalle.totalItem)).toFixed(4);
            //         return res;
            //     },
            //     0
            // );
            // vm.totalGeneralServicios = _.reduce(
            //     vm.comprobantesServicios,
            //     function(sum, detalle) {
            //         var res = (parseFloat(sum) + parseFloat(detalle.totalItem)).toFixed(4);
            //         return res;
            //     },
            //     0
            // );
            updateTotalGeneral();

        });
        vm.view = true;
    }

    function getGruposCompraImportacion() {
        var params = {};
        params.search = filterFactory
                .or([filterFactory.and([{
                            path: "codigo",
                            equals: "comprobante_importacion"
                        }, {
                            path: "activo",
                            equals: true
                        }
                    ]).value(),
                    filterFactory.and([{
                            path: "codigo",
                            equals: "gasto_importacion"
                        }, {
                            path: "activo",
                            equals: true
                        }
                    ]).value()
                ]).value();
        params.view = "GrupoCompraList";
        return GruposCompraFactory.all(params);
    }

    function getMonedaLocal() {
        var params = {};
        params.search = filterFactory
                .single({
                    path: "codigo",
                    equals: "moneda_local"
                })
                .value();
        vm.monedaLocal = ParametrosFactory.all(params);
    }

    function getParamDiferenciaMonedaLocal() {
        var params = {};
        params.search = filterFactory
                .single({
                    path: "codigo",
                    equals: "maximo_diferencia_cabecera_detalle_moneda_local"
                })
                .value();
        vm.diferenciaMonedaLocal = ParametrosFactory.all(params);
    }

    function getParamDiferenciaMonedaExtranjera() {
        var params = {};
        params.search = filterFactory
                .single({
                    path: "codigo",
                    equals: "maximo_diferencia_cabecera_detalle_moneda_extranjera"
                })
                .value();
        vm.diferenciaMonedaExtranjera = ParametrosFactory.all(params);
    }

    function splitDetalles() {
        vm.comprobantesServicios = [];
        vm.comprobantesProductos = [];
        _.forEach(vm.comprobante.comprobanteDetalle, function (elem) {
            if (elem.tipo.codigo === "detalle_comprobante_servicio") {
                vm.comprobantesServicios.push(elem);
            } else {
                vm.comprobantesProductos.push(elem);
            }
        });
    }

    function changeGrupoCompra() {
        if (vm.comprobante.grupoCompra.codigo == "comprobante_importacion") {
            vm.valorFOBRequerido = true;
            vm.tipoImpuestoExento = _.filter(vm.tiposImpuesto, function (data) {
                return data.porcentaje == 0;
            });
            vm.comprobante.montosImponibles = [{
                    tipoImpuesto: vm.tipoImpuestoExento[0],
                    porcentaje: vm.tipoImpuestoExento[0].porcentaje,
                    valor: 0
                }];
        } else {
            vm.valorFOBRequerido = false;
            vm.comprobante.montosImponibles = [{}];
        }
        vm.comprobante.ordenCompra = undefined;
        vm.comprobante.proveedor = undefined;
        vm.comprobante.sucursalProveedor = undefined;
        vm.comprobante.embarque = undefined;
        vm.comprobante.proforma = undefined;
        vm.comprobante.fob = 0;
        var filtroProforma = {};
        filtroProforma.search = filterFactory.and([
                {
                  path: "proveedorOC.id",
                  equals: vm.importacion.proveedor.id
                }
        ]).value();
        console.log(filtroProforma);
        vm.proformas = ProformaFactory.all(filtroProforma, "ProformaImportacionList");
    }

    function changeOC() {
        if (vm.comprobante.grupoCompra.codigo === "comprobante_importacion") {
            vm.comprobante.proveedor = vm.comprobante.ordenCompra.proveedor;
            vm.comprobante.moneda1 = vm.comprobante.ordenCompra.moneda;
            var params = {};

            params.search = filterFactory
                    .single({
                        path: "proveedorOC.id",
                        equals: vm.comprobante.ordenCompra.proveedor.id
                    })
                    .value();

            if (vm.watcherProforma) {
                vm.watcherProforma();
            }

            WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcherProforma
                    ) {
                vm.watcherProforma = watcherProforma;
                vm.proformas = ProformaFactory.all(
                        UtilFactory.createNestedFilters(locacionFilter.search.filters, params), "ProformaImportacionList"
                        );
            }, "locacion.id");
        }
        filterProductos();

        changeCotizacion(vm.isNew);

        if (vm.comprobante.proveedor) {
            var staticFilter = {};
            staticFilter.search = filterFactory
                    .and([{
                            path: "proveedor.codigo",
                            equals: vm.comprobante.proveedor.codigo
                        }])
                    .value();

            ProveedorSucursalesFactory.all(staticFilter).$promise.then(function (sucursales) {
                if (sucursales.length == 1) {
                    vm.comprobante.sucursalProveedor = sucursales[0];
                    changeSucursalProveedor(false);
                }
                vm.sucursalesProveedor = sucursales;
            });


            if (vm.comprobante.tipo && vm.requerirTimbrado) {
                filterTimbrado(vm.isNew);
            }
            if (vm.isNew === undefined) {
                // console.log("entro aca?");
                vm.comprobante.sucursalProveedor = null;
            }
        }
        //changeProveedor(vm.isNew);
        //Setear con tipo de cambio de la OC

        if (vm.isNew) {
            vm.comprobante.cambio = vm.comprobante.ordenCompra.tipoCambio;
        }

    }



    function changeEmbarque() {
        if (vm.comprobante.grupoCompra.codigo == "gasto_importacion") {
            return;
        }
        EmbarqueFactory.get(
                vm.comprobante.embarque.id,
                "EmbarqueForm"
                ).$promise.then(function (response) {
            vm.comprobante.embarque = response;
            vm.listaClonada = _.cloneDeep(vm.comprobante.embarque.embarqueDetalle);
            vm.comprobantesProductos = [];
            vm.comprobantesServicios = [];

            _.forEach(vm.listaClonada, function (detalle) {
                if (detalle.servicio == undefined || detalle.servicio == null) {
                    delete detalle.id;
                    delete detalle.embarque;
                    delete detalle.proformaDetalle;
                    delete detalle.totalItemExento;
                    delete detalle.totalImpuesto;
                    detalle.tipo = getTipoProducto(vm.tiposDetalle);
                    detalle.montosImponibles = [{}];
                    if (vm.comprobante.grupoCompra.codigo != "comprobante_importacion") {
                        detalle.montosImponibles[0].tipoImpuesto =
                                detalle.producto.tiposImpuesto[0];
                        detalle.montosImponibles[0].porcentaje =
                                detalle.producto.tiposImpuesto[0].porcentaje;
                        detalle.montosImponibles[0].valor = 0;
                    } else {
                        // se debe guardar con el tipo de impuesto exento por defecto
                        vm.tipoImpuestoExento = _.filter(vm.tiposImpuesto, function (data) {
                            return data.porcentaje == 0;
                        });
                        detalle.montosImponibles[0].tipoImpuesto = vm.tipoImpuestoExento[0];
                        detalle.montosImponibles[0].porcentaje =
                                vm.tipoImpuestoExento[0].porcentaje;
                        detalle.montosImponibles[0].valor = 0;
                    }
                    detalle.totalItem = 0;

                    var arrayElementos = [];
                    if (vm.comprobante.grupoCompra.codigo != "comprobante_importacion") {
                        if (detalle.producto.criterioControl) {
                            if (detalle.producto.criterioControl.codigo === "lotes") {
                                detalle.grupoProducto = {producto: detalle.producto.id};
                            } else if (detalle.producto.criterioControl.codigo === "series") {
                                detalle.grupoProducto = {producto: detalle.producto.id};
                                if (detalle.cantidad > 1) {
                                    var arrayElementos = [];
                                    for (i = 0; i < detalle.cantidad - 1; i++) {
                                        var newObject = jQuery.extend(true, {}, detalle);
                                        newObject.cantidad = 1;
                                        arrayElementos.push(newObject);
                                    }
                                    detalle.cantidad = 1;
                                }
                                //detalle.cantidad = 1;
                            }
                        }
                    }
                    calcularTotalDetalle(detalle);
                    if (arrayElementos) {
                        for (i = 0; i < arrayElementos.length; i++) {
                            vm.comprobantesProductos.push(arrayElementos[i]);
                            calcularTotalDetalle(arrayElementos[i]);
                        }
                    }
                    vm.comprobantesProductos.push(detalle);
                } else {
                    delete detalle.id;
                    delete detalle.embarque;
                    delete detalle.proformaDetalle;
                    delete detalle.totalItemExento;
                    delete detalle.totalImpuesto;
                    detalle.tipo = getTipoServicio(vm.tiposDetalle);
                    detalle.montosImponibles = [{}];
                    detalle.montosImponibles[0].tipoImpuesto =
                            detalle.servicio.tipoImpuesto;
                    detalle.montosImponibles[0].porcentaje =
                            detalle.servicio.tipoImpuesto.porcentaje;
                    detalle.montosImponibles[0].valor = 0;
                    detalle.totalItem = 0;
                    calcularTotalDetalle(detalle);
                    vm.comprobantesServicios.push(detalle);
                }
            });
            // vm.totalGeneralProductos = _.reduce(
            //     vm.comprobantesProductos,
            //     function(sum, detalle) {
            //         var res = (parseFloat(sum) + parseFloat(detalle.totalItem)).toFixed(4);
            //         return res;
            //     },
            //     0
            // );
            // vm.totalGeneralServicios = _.reduce(
            //     vm.comprobantesServicios,
            //     function(sum, detalle) {
            //         var res = (parseFloat(sum) + parseFloat(detalle.totalItem)).toFixed(4);
            //         return res;
            //     },
            //     0
            // );
            updateTotalGeneral();
        });
    }

    function getPlanPagos() {
        if (vm.comprobante.planPagos) {
            vm.cantidadCuotas = vm.comprobante.planPagos.cantidadCuotas;
            vm.montoInicial = vm.comprobante.planPagos.montoInicial;
            vm.plazoCuotas = vm.comprobante.planPagos.plazoCuotas;
        }
    }

    function proformaSelected() {
        vm.cantidadCuotas = vm.comprobante.proforma.cantidadPagos;
        vm.plazoCuotas = vm.comprobante.proforma.periodoPagos;
        vm.montoInicial = vm.comprobante.proforma.entregaInicial;
    }

    function tipoComprobanteSelected(activateEdit) {
        vm.requerirTimbrado = true;
        if (!activateEdit) {
            // fixme: Eso es un parche para evitar que el numero de comprobante se ponga nulo al editar
            // deberia solucionarse de otra forma.
            vm.comprobante.numero = null;
            vm.comprobante.timbradoProveedor = null;
        }
        if (!vm.comprobante.tipo.generarCuotas) {
            vm.cantidadCuotas = undefined;
            vm.plazoCuotas = undefined;
            vm.montoInicial = undefined;
            vm.comprobante.planPagos = undefined;
        } else {
            vm.cantidadCuotas = 1;
            vm.plazoCuotas = 1;
            vm.montoInicial = 0;

        }

        if (vm.comprobante.tipo.clase.codigo == 'comprobante_interno' ||
                vm.comprobante.tipo.clase.codigo == 'factura_exterior' ||
                vm.comprobante.tipo.clase.codigo == "despacho" || 
                (vm.comprobante.tipo.clase.codigo == "nota_credito" && !vm.comprobante.tipo.controlarTimbrado ) ) {
            vm.requerirTimbrado = false;
            vm.comprobante.timbradoProveedor = null;
        }

        if (vm.requerirTimbrado) {
            filterTimbrado();
        }
    }

    function mostrarTodosProductos() {
        if (vm.todosProductos) {
            vm.opcionesProductosDt.staticFilter = null;
            if (vm.watcher) {
                vm.watcher();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcher
                    ) {
                vm.watcher = watcher;
                vm.productos = ProductosFactory.allForSelect(locacionFilter);
                vm.opcionesProductosDt.staticFilter = locacionFilter;
            }, "locaciones.id");
        } else {
            filterProductos();
        }
    }

    function filterProductos() {
        var staticFilter = {};
        staticFilter.search = filterFactory
                .and([{
                        path: "proveedores.codigo",
                        equals: vm.comprobante.proveedor.codigo
                    },
                    {
                        path: "itemCompra",
                        like: "true"
                    }
                ])
                .value();

        if (vm.watcher) {
            vm.watcher();
        }
        WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
                ) {
            vm.watcher = watcher;
            vm.productos = ProductosFactory.allForSelect(
                    UtilFactory.createNestedFilters(
                            locacionFilter.search.filters,
                            staticFilter
                            )
                    );
            vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(
                    locacionFilter.search.filters,
                    staticFilter
                    );
        }, "locaciones.id");
    }

    function agregarDetalle() {
        vm.comprobante.comprobanteDetalle.push({
            montosImponibles: [{}],
            tipo: getTipoServicio(vm.tiposDetalle)
        });
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function agregarDetalleProducto() {
        vm.comprobantesProductos.push({
            montosImponibles: [{}],
            tipo: getTipoProducto(vm.tiposDetalle)
        });
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function agregarDetalleServicio() {
        vm.comprobantesServicios.push({
            montosImponibles: [{}],
            tipo: vm.tiposDetalle[1]
        });
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function agregarMontoImponible() {
        vm.comprobante.montosImponibles.push({esTotal: true});
        $timeout(function () {
            $scope.$broadcast("newImpuestoAdded");
        }, 20);
    }

    function calcularTotalDetalle(detalle) {
        if (detalle.cantidad == undefined) {
            detalle.totalItem = 0;
            return;
        }

        if (detalle.ultimoPrecio == undefined) {
            detalle.totalItem = 0;
            return;
        }

        detalle.totalItem = detalle.cantidad * detalle.ultimoPrecio;
        detalle.totalItem = parseFloat(detalle.totalItem.toFixed(4));

        if (detalle.montosImponibles) {
            if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0) {

                if (vm.comprobante.moneda1.codigo == "guaranies") {
                    detalle.montosImponibles[0].baseImponible = Math.round((100 * detalle.totalItem) / (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje));
                } else {
                    detalle.montosImponibles[0].baseImponible = (100 * detalle.totalItem) / (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje);
                }
            } else {
                detalle.montosImponibles[0].baseImponible = detalle.totalItem;
            }

            detalle.montosImponibles[0].baseImponible = parseFloat(
                    detalle.montosImponibles[0].baseImponible.toFixed(4)
                    );

            if (vm.comprobante.moneda1.codigo == "guaranies") {
                detalle.montosImponibles[0].valor = parseFloat((detalle.totalItem - detalle.montosImponibles[0].baseImponible).toFixed(4));
            } else {
                detalle.montosImponibles[0].valor = parseFloat((detalle.totalItem - detalle.montosImponibles[0].baseImponible).toFixed(4));
            }
        }

        updateTotalGeneral();
    }

    function totalItemChanged(detalle) {
        if (detalle.cantidad == undefined) {
            detalle.cantidad = 1;
        }

        if (detalle.cantidad == 0) {
            detalle.cantidad = 1;
        }

        detalle.ultimoPrecio = detalle.totalItem / detalle.cantidad;
        detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(4));
        if (detalle.montosImponibles) {
            if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                if (vm.comprobante.moneda1.codigo == "guaranies") {
                    detalle.montosImponibles[0].baseImponible = Math.round((100 * detalle.totalItem) / (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje));
                } else {
                    detalle.montosImponibles[0].baseImponible = (100 * detalle.totalItem) / (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje);
                }
            } else {
                detalle.montosImponibles[0].baseImponible = detalle.totalItem;
            }
            detalle.montosImponibles[0].baseImponible = parseFloat(
                    detalle.montosImponibles[0].baseImponible.toFixed(4)
                    );
            detalle.montosImponibles[0].valor =
                    detalle.totalItem - detalle.montosImponibles[0].baseImponible;
        }
        updateTotalGeneral();
    }

    function updateTotalGeneral() {

        if(vm.comprobante.montoTotal != undefined){
            // El redondeo es diferente para los montos totales
            if (vm.comprobante.moneda1.codigo == "guaranies"){
                vm.cantDigitos = 0;
                vm.comprobante.montoTotal = parseFloat(vm.comprobante.montoTotal.toFixed(0));
            }else{
                vm.cantDigitos = 2;
                vm.comprobante.montoTotal = parseFloat(vm.comprobante.montoTotal.toFixed(4));
            }
        }

        vm.totalGeneralProductos = _.reduce(
                vm.comprobantesProductos,
                function (sum, detalle) {
                    var res = (parseFloat(sum) + parseFloat(detalle.totalItem)).toFixed(vm.cantDigitos);
                    return res;
                },
                0
                );
        vm.totalGeneralServicios = _.reduce(
                vm.comprobantesServicios,
                function (sum, detalle) {
                    var res = (parseFloat(sum) + parseFloat(detalle.totalItem)).toFixed(vm.cantDigitos);
                    return res;
                },
                0
                );
    }

    function changeTipo(detalle) {
        if (detalle.tipo.codigo == "detalle_comprobante_producto") {
            detalle.servicio = null;
            detalle.cantidad = 0;
            detalle.ultimoPrecio = 0;
            detalle.montosImponibles[0].tipoImpuesto = {};
            detalle.montosImponibles[0].valor = 0;
            detalle.totalItem = 0;
        } else if (detalle.tipo.codigo == "detalle_comprobante_servicio") {
            detalle.producto = null;
            detalle.cantidad = 0;
            detalle.ultimoPrecio = 0;
            detalle.montosImponibles[0].tipoImpuesto = {};
            detalle.montosImponibles[0].valor = 0;
            detalle.totalItem = 0;
        }
    }

    function changeProducto(detalle) {
        detalle.servicio = null;
        detalle.cantidad = 0;
        detalle.ultimoPrecio = 0;
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        if (vm.comprobante.grupoCompra.codigo != "comprobante_importacion") {
            detalle.montosImponibles[0].tipoImpuesto =
                    detalle.producto.tiposImpuesto[0];
            detalle.montosImponibles[0].porcentaje =
                    detalle.producto.tiposImpuesto[0].porcentaje;
            detalle.montosImponibles[0].valor = 0;
        } else {
            // NOTA: se debe guardar con el tipo de impuesto exento por defecto
            vm.tipoImpuestoExento = _.filter(vm.tiposImpuesto, function (data) {
                return data.porcentaje == 0;
            });
            detalle.montosImponibles[0].tipoImpuesto = vm.tipoImpuestoExento[0];
            detalle.montosImponibles[0].porcentaje =
                    vm.tipoImpuestoExento[0].porcentaje;
            detalle.montosImponibles[0].valor = 0;
        }
        detalle.totalItem = 0;
        if (detalle.producto.criterioControl) {
            if (detalle.producto.criterioControl.codigo === "lotes") {
                detalle.grupoProducto = {producto: detalle.producto.id};
            } else if (detalle.producto.criterioControl.codigo === "series") {
                detalle.grupoProducto = {producto: detalle.producto.id};
                detalle.cantidad = 1;
            }
        }
    }

    function changeServicio(detalle) {
        detalle.producto = null;
        detalle.cantidad = 1;
        detalle.ultimoPrecio = 0;
        detalle.montosImponibles[0].tipoImpuesto = detalle.servicio.tipoImpuesto;
        detalle.montosImponibles[0].porcentaje =
                detalle.servicio.tipoImpuesto.porcentaje;
        detalle.montosImponibles[0].valor = 0;
        detalle.totalItem = 0;
    }

    function changeMoneda(isNew) {
        changeCotizacion(isNew);
        updateCabeceraMontosImponibles();
        updateTotalGeneral();
    }

    function changeFecha(isNew) {
        changeCotizacion(isNew);
        updateCabeceraMontosImponibles();
        updateTotalGeneral();
        if (vm.comprobante.proveedor) {
            filterTimbrado(isNew);
        }
    }

    function changeCotizacion(isNew) {

        if (vm.comprobante.moneda1 && vm.comprobante.moneda1.codigo == "guaranies") {
            vm.cantDigitos = 0;
        } else {
            vm.cantDigitos = 4;
        }
        if (isNew) {
            vm.monedaLocal.$promise.then(function (data) {
                if (
                        vm.comprobante.moneda1 &&
                        vm.comprobante.moneda1.descripcion != data[0].valorTipo.descripcion
                        ) {
                    vm.cotizacionRequerida = true;
                    var params = {};
                    params.search = filterFactory
                            .and([{
                                    path: "monedaDesde.id",
                                    equals: vm.comprobante.moneda1.id
                                },
                                {
                                    path: "monedaHasta.id",
                                    equals: vm.monedaLocal[0].valorTipo.id
                                },
                                {
                                    path: "fecha",
                                    like: moment(vm.comprobante.fecha).format("DD/MM/YYYY")
                                            //equals:vm.comprobante.fecha
                                }
                            ])
                            .value();
                    CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                        if (response && response[0]) {
                            vm.comprobante.cambio = response[0].valorVenta;
                        }
                        else {
                            vm.comprobante.cambio = null;
                            vm.comprobante.cambioView = null;
                        }
                    });
                } else {
                    vm.cotizacionRequerida = false;
                    vm.comprobante.cambio = 1;
                }
            });
        }
    }

    function updateCabeceraMontosImponibles() {
        if (vm.comprobante.montoTotal) {
            _.forEach(vm.comprobante.montosImponibles, function (montoImponible) {
                totalImpuestoChanged(montoImponible);
            });
        }
    }

    function searchProductoFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                    item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                    item.codigo == criteria ||
                    (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0
                    );
        };
    }

    function actualizarMontoImponibleImp(comprobante) {
        vm.comprobante.montosImponibles[0].total = comprobante.montoTotal;
        vm.comprobante.montosImponibles[0].baseImponible = comprobante.montoTotal;
    }

    function totalImpuestoChanged(montoImponible) {


        if (vm.comprobante.moneda1.codigo == "guaranies") {
            if (montoImponible.total) {
                montoImponible.total = parseFloat(montoImponible.total.toFixed(0));
            }
        }

        checkIfImpuestoSet(montoImponible);
        updateTotalImpuestos();
    }

    function tipoImpuestoChanged(montoImponible) {
        checkIfImpuestoSet(montoImponible);
        updateTotalImpuestos();
    }

    function checkIfImpuestoSet(montoImponible) {
        if (!montoImponible.tipoImpuesto) {
            delete montoImponible.valor;
            delete montoImponible.total;
            delete montoImponible.baseImponible;
            return false;
        }
        var porc = montoImponible.tipoImpuesto.porcentaje;

        if (porc != 0) {
            if (vm.comprobante.moneda1.codigo == "guaranies")
                montoImponible.baseImponible = Math.round((100 * montoImponible.total) / (100 + montoImponible.tipoImpuesto.porcentaje));
            else
                montoImponible.baseImponible = (100 * montoImponible.total) / (100 + montoImponible.tipoImpuesto.porcentaje);
        } else {
            montoImponible.baseImponible = montoImponible.total;
        }
        if (montoImponible.baseImponible)
            montoImponible.baseImponible = parseFloat(
                    montoImponible.baseImponible.toFixed(8)
                    );
        // TODO cambiar por moneda local
        if (vm.comprobante.moneda1.codigo == "guaranies")
            montoImponible.valor = Math.round(montoImponible.total - montoImponible.baseImponible);
        else
            montoImponible.valor = parseFloat((montoImponible.total - montoImponible.baseImponible).toFixed(8));

        return true;
    }

    function updateTotalImpuestos() {
        if (vm.comprobante.grupoCompra.codigo !== 'comprobante_importacion') {
            var auxiliar = vm.comprobante.montoTotal;
            vm.totalGeneral = _.reduce(
                    vm.comprobante.montosImponibles,
                    function (sum, monto) {
                        return sum + monto.total;
                    },
                    0
                    );
            if (vm.totalGeneral) {
                vm.comprobante.montoTotal = vm.totalGeneral;
            } else {
                vm.comprobante.montoTotal = auxiliar ? auxiliar : 0;
            }

            //Redondeo de montoTotal
            if (vm.comprobante.moneda1.codigo == "guaranies"){
                vm.comprobante.montoTotal = Math.round(vm.comprobante.montoTotal);
            }else{
                vm.comprobante.montoTotal = parseFloat(vm.comprobante.montoTotal.toFixed(4));
            }
        }
    }

    function changeSucursalProveedor(isNew) {

        ProveedorSucursalesFactory.get(
                vm.comprobante.sucursalProveedor.id,
                "BaseList"
                ).$promise.then(function (sucursal) {
            vm.sucursalProveedor = sucursal;

        })
        if (vm.comprobante.tipo && vm.requerirTimbrado) {
            filterTimbrado();
        }
        if (isNew === undefined) {
            vm.comprobante.timbradoProveedor = null;
            vm.comprobante.numero = null;
        }
    }

    function changeProveedor(isNew) {

        if (isNew === undefined || isNew == true) {
            vm.comprobante.timbradoProveedor = null;
            vm.comprobante.sucursalProveedor = null;
        }

        if (vm.comprobante.proveedor) {
            var staticFilter = {};
            staticFilter.search = filterFactory
                    .and([{
                            path: "proveedor.codigo",
                            equals: vm.comprobante.proveedor.codigo
                        }])
                    .value();

            ProveedorSucursalesFactory.all(staticFilter).$promise.then(function (sucursales) {
                if (sucursales.length == 1) {
                    vm.comprobante.sucursalProveedor = sucursales[0];
                    changeSucursalProveedor(false);
                }
                vm.sucursalesProveedor = sucursales;
            });


            if (vm.comprobante.tipo && vm.requerirTimbrado) {
                filterTimbrado(isNew);
            }
            if (isNew === undefined) {
                // console.log("entro aca?");
                vm.comprobante.sucursalProveedor = null;
            }
        }

        if (vm.comprobante.grupoCompra.codigo === "gasto_importacion") {
            var params = {};
            params.search = filterFactory
                    .single({
                        path: "proveedorOC.id",
                        equals: vm.comprobante.proveedor.id
                    })
                    .value();
            if (vm.watcherProforma) {
                vm.watcherProforma();
            }

            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcherProforma
                            ) {
                vm.watcherProforma = watcherProforma;
                vm.proformas = ProformaFactory.all(params, "ProformaImportacionList");
                }, "locacion.id");
        }

        mostrarTodosProductos();
    }

    function filterTimbrado(isNew) {

        var filtersArray = [];

        if (vm.comprobante.proveedor) {
            filtersArray.push({
                path: "proveedor.id",
                equals: vm.comprobante.proveedor.id
            });
        }
        if (vm.comprobante.fecha) {
            filtersArray.push({
                path: "fechaDesde",
                equalOrBefore: moment(vm.comprobante.fecha).format("DD/MM/YYYY")
            });

            filtersArray.push({
                path: "fechaHasta",
                equalOrAfter: moment(vm.comprobante.fecha).format("DD/MM/YYYY")
            });
        }
        if (vm.comprobante.sucursalProveedor) {
            filtersArray.push({
                path: "sucursalProveedor.id",
                equals: vm.comprobante.sucursalProveedor.id
            });
        }

        // Filtro de estado
        filtersArray.push({
            path: "activo",
            equals: true
        });


        var filters = {search: filterFactory.and(filtersArray).value()};
        filters.view = "BaseList"
        vm.timbrados = TimbradoProveedorFactory.all(filters);

        if(vm.importacion.estado.codigo != "cerrada" && vm.view != true){
            vm.timbrados.$promise.then(function (response) {
                if (vm.requerirTimbrado &&
                        !response.length
                        ) {
                    if (savedTime === undefined || ((new Date() - savedTime) / 1000) > 12) {
                        var msg = $filter("translate")("NO_TIMBRADO_PROVEEDOR_FOUND");
                        notify({message: msg, classes: "alert-danger", position: "right"});
                        savedTime = new Date();
                    }
                }
            });
        }

        vm.comprobante.numeroTimbrado = undefined;
        if (isNew) {
            vm.comprobante.timbradoProveedor = null;
        }
    }

    function validarNroFactura() {
        if (!vm.requerirTimbrado) vm.comprobante.timbradoProveedor = null;
        vm.comprobante = ComprobanteFactory.validateNroFactura($scope.ComprobanteForm, vm.comprobante, vm.monedaLocal[0],
                vm.formatoComprobante, vm.sucursalProveedor);
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        updateTotalImpuestos();
        // vm.totalGeneralProductos = _.reduce(
        //     vm.comprobantesProductos,
        //     function(sum, detalle) {
        //         var res = (parseFloat(sum) + parseFloat(detalle.totalItem)).toFixed(4);
        //         return res;
        //     },
        //     0
        // );
        // vm.totalGeneralServicios = _.reduce(
        //     vm.comprobantesServicios,
        //     function(sum, detalle) {
        //         var res = (parseFloat(sum) + parseFloat(detalle.totalItem)).toFixed(4);
        //         return res;
        //     },
        //     0
        // );
        updateTotalGeneral();
    }

    function submit() {
        vm.submited = true;
        if ($scope.ComprobanteForm.$valid) {
           // $rootScope.isProcessing = true;
            evaluatePeriodoAdministrativo(vm.comprobante.fecha).then(function (response) {
                if (response) {
                    copiarComprobantes();
                    var validationMontos = ComprobanteFactory.validateMontos(
                            vm.comprobante,
                            vm.monedaLocal[0],
                            vm.diferenciaMonedaLocal[0],
                            vm.diferenciaMonedaExtranjera[0]
                            );
                    if (validationMontos[0]) {
                        sendSubmitComprobante();
                    } else {
                        var code = validationMontos[1];
                        switch (code) {
                            case "fob_invalid":
                                notify({
                                    message: $filter("translate")("FOB_INVALID_WARNING"),
                                    classes: "alert-danger",
                                    position: "right"
                                });
                                break;
                            case "montos_mismatch":
                                notify({
                                    message: $filter("translate")(
                                            "MONTOS_TOTALES_SUM_DETALLES_WARNING"
                                            ),
                                    classes: "alert-danger",
                                    position: "right"
                                });
                                break;
                            case "impuestos_mismatch":
                                notify({
                                    message: $filter("translate")(
                                            "IMPUESTOS_TOTALES_SUM_DETALLES_WARNING"
                                            ),
                                    classes: "alert-danger",
                                    position: "right"
                                });
                                break;
                        }
                    }
                } else {
                    //$rootScope.isProcessing = false;
                    var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                }
            })
        }
    }

    function sendSubmitComprobante() {
        
        if(vm.comprobante.proyecto == undefined){
            vm.comprobante.proyecto = null;
        }

        return submitComprobante().then(
                function (response) {
                    redirectImportacion(response.importacion.id);
                    return response;
                },
                function (error) {
                   // $rootScope.isProcessing = false;
                    errorHandler(error);
                    //var msg = $filter("translate")("ERROR_SAVING");
                    //notify({message: msg, classes: "alert-danger", position: "right"});
                }
        );
    }

    function copiarComprobantes() {
        vm.comprobante.comprobanteDetalle = [];
        vm.comprobantesProductos = _.filter(vm.comprobantesProductos, function (
                elem
                ) {
            return elem.producto;
        });
        vm.comprobantesServicios = _.filter(vm.comprobantesServicios, function (
                elem
                ) {
            return elem.servicio;
        });
        _.forEach(vm.comprobantesProductos, function (elem) {
            // si es producto se quita el atributo grupo de los detalles cuyos productos son de tipo normal o bobina
            if (
                    (elem.producto !== undefined &&
                            elem.producto.claseProducto.tipoAdministracion.codigo ===
                            "bobinas") ||
                    (elem.producto.criterioControl.codigo !== "series" &&
                            elem.producto.criterioControl.codigo !== "lotes")
                    ) {
                delete elem.grupo;
            }
            vm.comprobante.comprobanteDetalle.push(elem);
        });
        _.forEach(vm.comprobantesServicios, function (elem) {
            vm.comprobante.comprobanteDetalle.push(elem);
        });
    }

    function cancel() {
        $state.go("app.importaciones.proceso.comprobante.list");
    }

    function submitComprobante() {
        delete vm.comprobante.numeroTimbrado;
        if (!vm.isEdit) {
            vm.comprobante.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        }
        if (vm.plazoCuotas && vm.cantidadCuotas) {
            $rootScope.isProcessing = true;
            var resource = ComprobanteFactory.create(vm.comprobante);
            var montoInicial = vm.montoInicial ? vm.montoInicial : 0;
            return ComprobanteFactory.saveWithPlanPago(
                    resource,
                    vm.cantidadCuotas,
                    vm.plazoCuotas,
                    montoInicial
                    );
        } else {
            return formFactory.defaultNSFSubmit(
                    $scope.ComprobanteForm,
                    ComprobanteFactory,
                    vm.comprobante,
                    errorHandler
                    );
        }
    }

    function errorHandler(e) {
        var msg = "";
        _.forEach(e.data, function (error) {
            if (error.constraint == "id_producto, numero") {
                msg +=
                        "\n\n" + $filter("translate")("NUMERO_GRUPO_PRODUCTO_WARNING") + "\n";
            }
            if (error.constraint ==
                    "numero, id_proveedor, id_numero_timbrado") {
                msg +=
                        "\n\n" + $filter("translate")("NROCOMP_PROVEEDOR_TIMBRADO_TIPO_REPETIDO") + "\n";
            } else {
                msg += "\n\n" + error.message + ".";
            }
        });
        notify({message: msg, classes: "alert-danger", position: "right"});
    }

    function cancel() {
        $state.go("app.importaciones.proceso.comprobante.list", {
            codigoImportacion: ImportacionFactory.getIdImportacion()
        });
    }

    function redirectImportacion(itemId) {
        ImportacionFactory.get(itemId, "ImportacionList").$promise.then(function (imp) {
            ImportacionFactory.setIdImportacion(imp.id);
            ImportacionFactory.setCodigoImportacion(imp.codigo);
            ImportacionFactory.setImportacion(imp);
            $scope.$emit("updateImportacionStates");
            switch (imp.estado.codigo) {
                case "abierta":
                    $state.go(
                            "app.importaciones.proceso.ordenescompra.list", {codigoImportacion: itemId}, {reload: true}
                    );
                    break;
                case "con_orden_compra":
                    $state.go(
                            "app.importaciones.proceso.proformas.list", {codigoImportacion: itemId}, {reload: true}
                    );
                    break;
                case "con_proforma":
                    $state.go(
                            "app.importaciones.proceso.solicitudes.list", {codigoImportacion: itemId}, {reload: true}
                    );
                    break;
                case "con_solicitud":
                    $state.go(
                            "app.importaciones.proceso.embarques.list", {codigoImportacion: itemId}, {reload: true}
                    );
                    break;
                case "con_embarque":
                    $state.go(
                            "app.importaciones.proceso.comprobante.list", {codigoImportacion: itemId}, {reload: true}
                    );
                    break;
                case "con_comprobante":
                    $state.go(
                            "app.importaciones.proceso.recepcionimportacion.list", {codigoImportacion: itemId}, {reload: true}
                    );
                    break;
                case "con_recepcion":
                    $state.go(
                            "app.importaciones.proceso.recepcionimportacion.list", {codigoImportacion: itemId}, {reload: true}
                    );
                    break;
                case "con_despacho":
                    $state.go(
                            "app.importaciones.proceso.cierre", {codigoImportacion: itemId}, {reload: true}
                    );
                    break;
                case "cerrada":
                    $state.go(
                            "app.importaciones.proceso.cierre", {codigoImportacion: itemId}, {reload: true}
                    );
                    break;
                default:
                    console.log("Estado distinto");
            }
        });
    }
    function evaluatePeriodoAdministrativo(fecha) {
        return $q(function (resolve, reject) {
            var params = {};
            params.search = filterFactory
                    .and([{
                            path: "estado.codigo",
                            equals: "per_adm_abierto"
                        },
                        {
                            path: "fechaDesde",
                            equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                        },
                        {
                            path: "fechaHasta",
                            equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                        }
                    ])
                    .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {

                if (responsePeriodo.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        });
    }

}

"use strict";

angular
        .module("qualita.importacion")
        .controller("AplicacionComprobanteFormCtrl", AplicacionComprobanteFormCtrl);

AplicacionComprobanteFormCtrl.$inject = [
    "ComprobanteFactory",
    "comprobantePrepService",
    "ImportacionFactory",
    "CotizacionMonedaFactory",
    "CompraLocalLangFactory",
    "filterFactory",
    "ParametrosFactory",
    "$scope",
    "notify",
    "$state",
    "$rootScope",
    "$timeout",
    "$log",
    "$translate",
    "$q",
    "$modal",
    "$stateParams",
    "ImportacionLangFactory",
    "CompraFactory",
    "$filter",
    "observeOnScope",
    "PlanPagosDetalleFactory",
    "ComprobanteRetencionFactory"
];

function AplicacionComprobanteFormCtrl(
        ComprobanteFactory,
        comprobantePrepService,
        ImportacionFactory,
        CotizacionMonedaFactory,
        CompraLocalLangFactory,
        filterFactory,
        ParametrosFactory,
        $scope,
        notify,
        $state,
        $rootScope,
        $timeout,
        $log,
        $translate,
        $q,
        $modal,
        $stateParams,
        ImportacionLangFactory,
        CompraFactory,
        $filter,
        observeOnScope,
        PlanPagosDetalleFactory
        ) {
    var vm = this;

    vm.mode = $stateParams.mode ? $stateParams.mode : "add";

    vm.compraLocal = false;
    activate();

    var filtrarAgregados = function () {
        _.forEach(vm.comprobante.comprobanteAplicaciones, function (detalleComprobante) {
            var newFilter;
            newFilter = {path: "id", notEquals: detalleComprobante.comprobanteAplicado.id};
            _.forEach(vm.opcionesComprobantesDt.staticFilter.search.filters, function (filtroAnd) {
                filtroAnd.filters.push(newFilter);
            });
        });
    };

    var filtrarAgregadosPlanPago = function(){
        _.forEach(vm.comprobante.comprobanteAplicaciones, function (detalleComproabte) {
            var newFilter;
            newFilter = {path: "id", notEquals: detalleComproabte.cuota.id};
            _.forEach(vm.opcionesPlanPagosDetallesDt.staticFilter.search.filters,function(filtroAnd){
                filtroAnd.filters.push(newFilter);
            });
        });
    };

    vm.submit = submit;
    vm.cancel = cancel;
    vm.buscarComprobantes = buscarComprobantes;
    vm.buscarCuotas = buscarCuotas;
    vm.clearFiltrosComprobantes = clearFiltrosComprobantes;
    vm.montoAplicadoChanged = montoAplicadoChanged;
    vm.previewAplicacion = previewAplicacion;
    vm.enableSave = false;
    vm.removeItemFromArray = removeItemFromArray;
    vm.fechaDesdeChanged = fechaDesdeChanged;
    vm.fechaHastaChanged = fechaHastaChanged;
    vm.tipoComprobanteChanged = tipoComprobanteChanged;
    vm.factoryComprobantes = ComprobanteFactory;
    vm.factoryPlanPagoDetalle = PlanPagosDetalleFactory;

    $rootScope.isProcessing = false;

    function activate() {
        vm.showSpinner = false;
        vm.monedaLocal = CotizacionMonedaFactory.getMonedaLocal();
        vm.comprobanteAplicaciones = [];

        var monedaRender = function (data, type, row) {

            var comprobante;
            // Caso contado, la moneda se recupera directamente
            if (row.moneda1 != undefined) {
                comprobante = row;
            }
            else { // Caso credito, la moneda se recupera del plan de pago
                comprobante = row.planPagos.comprobante;
            }

            if (data) {
                var moneda = "Gs. ";
                if (comprobante.moneda1.codigo === "dolares") {
                    moneda = "Usd. ";
                    data = parseFloat(data).toFixed(2);
                } else if (comprobante.moneda1.codigo === "pesos") {
                    moneda = "Pes. ";
                    data = parseFloat(data).toFixed(2);
                } else if (comprobante.moneda1.codigo === "real") {
                    moneda = "Rel. ";
                    data = parseFloat(data).toFixed(2);
                } else if (comprobante.moneda1.codigo === "euro") {
                    moneda = "Eur. ";
                    data = parseFloat(data).toFixed(2);
                }
                return (
                        moneda +
                        data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        );
            } else
                return "";
        };

        var defaultColumnOrder = [
            "fecha",
            "numero",
            "proveedor.nombre",
            "montoTotal",
            "saldo"
        ];
        vm.opcionesComprobantesDt = {
            resource: "comprobante",
            title: "Comprobante",
            view: "ComprobanteDatatableList",
            factory: ComprobanteFactory,
            defaultColumnOrder: defaultColumnOrder,
            columns: [{
                    data: "fecha",
                    title: $filter("translate")("DATE"),
                    renderWith: "dateRender",
                    dateFormat: "DD/MM/YYYY",
                    class: "dt-center",
                    type: "date-range"
                },
                {
                    data: "id",
                    title: $filter("translate")("CODE"),
                    class: "dt-center",
                    type: "number-range"
                },
                {
                    data: "numero",
                    title: $filter("translate")("NUMBER"),
                    class: "dt-center"

                },
                {
                    data: "proveedor.nombre",
                    title: $filter("translate")("PROVEEDOR_INDEX"),
                    class: "dt-center"
                },
                {
                    data: "estado.descripcion",
                    title: $filter("translate")("STATUS"),
                    class: "dt-center"
                },
                {
                    data: "montoTotal",
                    title: $filter("translate")("TOTAL_AMOUNT"),
                    class: "dt-right",
                    render: monedaRender,
                    type: "number-range"
                },
                {
                    data: "saldo",
                    title: $filter("translate")("SALDO"),
                    class: "dt-right",
                    render: monedaRender,
                    type: "number-range"
                }
            ],
            hasOptions: false,
            defaultOrderColumn: 1,
            defaultOrderDir: "desc"
        };

        var defaultColumnOrderPlanPagoDetalle = [
            "id",
            "numero",
            "tipo.descripcion",
            "monto",
            "saldo",
            "estado.descripcion",
            "fechaVencimiento",
            "planPagos.comprobante.numero"
        ];

        vm.opcionesPlanPagosDetallesDt = {
            resource: "planpagosdetalle",
            title: $filter("translate")("CUOTAS_COMPROBANTE"),
            view: "PlanPagoDetalleDatatableList",
            factory: PlanPagosDetalleFactory,
            defaultColumnOrder: defaultColumnOrderPlanPagoDetalle,
            failedDeleteError: $filter("translate")("CUOTA_DELETE_FAILED"),
            columns: [
                {data: "id", title: $filter("translate")("CODE"), visible: false},
                {data: "numero", title: $filter("translate")("NUMBER")},
                {data: "planPagos.comprobante.numero", title: $filter("translate")("NUMERO_COMPROBANTE")},
                {data: "tipo.descripcion", title: $filter("translate")("TYPE")},
                {
                    data: "monto",
                    title: $filter("translate")("MONTO"),
                    render: monedaRender,
                    type: "number-range"
                },
                {
                    data: "saldo",
                    title: $filter("translate")("SALDO"),
                    render: monedaRender,
                    type: "number-range"
                },
                {
                    data: "estado.descripcion",
                    title: $filter("translate")("STATUS")
                },
                {
                    data: "fechaVencimiento",
                    title: $filter("translate")("FECHA_VENCIMIENTO"),
                    renderWith: "dateRender",
                    dateFormat: "DD/MM/YYYY",
                    class: "dt-center",
                    type: "date-range"
                }
            ],
            hasOptions: false,
            defaultOrderColumn: 1,
            defaultOrderDir: "desc",
        };

        if ($state.is("app.importaciones.proceso.comprobante.apply")) {
            activateComprobanteImportacion();
        } else if (
                $state.is("app.compraslocales.proceso.comprobante.apply") ||
                $state.is("app.consultacomprobantes.apply")
                ) {
            activateComprobanteLocal();
        }
    }

    function activateComprobanteImportacion() {
        ImportacionLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            commonActivate();
        });
    }

    var redondeo = function (data, moneda) {
        if (!data) {
            data = 0;
        }
        if (moneda === 'dolares') {
            data = parseFloat(data).toFixed(2);
        } else if (moneda === 'pesos') {
            data = parseFloat(data).toFixed(2);
        } else if (moneda === 'real') {
            data = parseFloat(data).toFixed(2);
        } else if (moneda === 'euro') {
            data = parseFloat(data).toFixed(2);
        } else if (moneda === 'guaranies') {
            data = parseFloat(data).toFixed(0);
        }
        return data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    function activateComprobanteLocal() {
        vm.compraLocal = true;
        if ($state.is("app.compraslocales.proceso.comprobante.apply")) {
            vm.isProcesoCompra = true;
        }
        ImportacionLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            commonActivate();
        });
    }

    vm.detalleAdded = false;

    function commonActivate() {
        vm.traerComprobantes = false;
        vm.tiposComprobantes = [{
                id: 1,
                nombre: "Contado"
            }, {
                id: 2,
                nombre: "Crédito"
            }]

        var params = {};
        params.search = filterFactory
                .single({
                    path: "codigo",
                    equals: "moneda_local"
                })
                .value();
        vm.monedaLocal = ParametrosFactory.all(params);
        vm.curDate = new Date();

        vm.comprobante = comprobantePrepService;
        vm.tipoComprobante = {
            id: 1,
            nombre: "Contado"
        };
        if (vm.mode == "edit") {
            getOriginalSaldo();
            vm.title = $filter("translate")("EDIT_APLICACION_COMPROBANTE");
        } else {
            vm.title = $filter("translate")("APLICACION_COMPROBANTE");
        }
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);
        observeComprobanteSelected();
        observeCuotasSelected();
        $scope.array = [];
        _.forEach(vm.comprobante.comprobanteAplicaciones, function (value) {
            value.impuestoAplicado = redondeo(value.impuestoAplicado, value.comprobanteAplicado.moneda1.codigo);
            $scope.array.push(value);
        });
        vm.comprobante.comprobanteAplicaciones = [];
        vm.comprobante.comprobanteAplicaciones = ($scope.array);

        // Redondeo del saldo de la NC
        if (vm.comprobante.moneda1.codigo == "dolares") {
            vm.comprobante.saldo = parseFloat(vm.comprobante.saldo.toFixed(2));
        } else if (vm.comprobante.moneda1.codigo == "guaranies") {
            vm.comprobante.saldo = parseFloat(vm.comprobante.saldo.toFixed(0));
        }
    }

    function getOriginalSaldo() {
        var comprobanteAplicaciones = vm.comprobante.comprobanteAplicaciones;
        vm.comprobante.saldo = vm.comprobante.saldo;
        vm.comprobante.saldoImpuestos = vm.comprobante.totalImpuestos;
        var group = _.groupBy(comprobanteAplicaciones, function (
                comprobanteAplicacion
                ) {
            return comprobanteAplicacion.comprobanteAplicado.id;
        });
        _.forOwn(group, function (value, key) {
            var suma = 0;
            _.forEach(value, function (comprobanteAplicacion) {
                suma += comprobanteAplicacion.montoAplicado;
                if (comprobanteAplicacion.cuota) {
                    comprobanteAplicacion.cuota.saldo =
                            comprobanteAplicacion.cuota.saldo +
                            comprobanteAplicacion.montoAplicado;
                }
            });
            var comprobanteAplicacion = value[0];
            comprobanteAplicacion.comprobanteAplicado.saldo =
                    comprobanteAplicacion.comprobanteAplicado.saldo + suma;

            comprobanteAplicacion.comprobanteAplicado.saldoImpuestos =
                    comprobanteAplicacion.comprobanteAplicado.saldoImpuestos +
                    comprobanteAplicacion.impuestoAplicado;
        });
    }

    function fechaDesdeChanged() {
        if (vm.fechaHasta != null) {
            if (!validarFechas()) {
                var msg = $filter("translate")("INVALID_DATES");
                notify({message: msg, classes: "alert-danger", position: "right"});
                vm.fechaDesde = null;
            }else{
                getComprobantes();
                getCuotas();
            }
        }

    }

    function validarFechas() {
        if (vm.fechaDesde <= vm.fechaHasta) {
            return true;
        } else {
            return false;
        }
    }


    function fechaHastaChanged() {
        if (vm.fechaDesde != null) {
            if (!validarFechas()) {
                var msg = $filter("translate")("INVALID_DATES");
                notify({message: msg, classes: "alert-danger", position: "right"});
                vm.fechaHasta = null;
            }else{
                getComprobantes();
                getCuotas();
            }
        }
    }

    function tipoComprobanteChanged() {
        if (vm.fechaDesde != null && vm.fechaHasta != null) {
            if (!validarFechas()) {
                var msg = $filter("translate")("INVALID_DATES");
                notify({message: msg, classes: "alert-danger", position: "right"});
                vm.fechaHasta = null;
            }else{
                getComprobantes();
                getCuotas();
            }
        }
    }

    function buscarComprobantes() {
        if (vm.fechaDesde && vm.fechaHasta && vm.tipoComprobante) {
            getComprobantes();
        }
    }

    function buscarCuotas() {
        if (vm.fechaDesde && vm.fechaHasta && vm.tipoComprobante) {
            getCuotas();
        }
    }

    function observeComprobanteSelected() {
        var obs1 = observeOnScope($scope, "vm.comprobantesFiltrados")
                .filter(function (data) {
                    return data.newValue;
                })
                .map(function (data) {
                    return data.newValue;
                });

        obs1.subscribe(function (change) {
            vm.showSpinner = true;
            vm.detalleAdded = true;

            _.forEach(change, function (val) {
                val.$promise.then(function (comprobante) {
                    addComprobante(comprobante);
                });
            });
            $q.all(change).then(function () {
                vm.traerComprobantes = true;
                if (vm.mode === "add") {
                    vm.comprobante.comprobanteAplicaciones = vm.comprobanteAplicaciones;
                }
                vm.showSpinner = false;
            });
        });
    }

    function observeCuotasSelected() {
        var obs1 = observeOnScope($scope, "vm.cuotasFiltradas")
                .filter(function (data) {
                    return data.newValue;
                })
                .map(function (data) {
                    return data.newValue;
                });

        obs1.subscribe(function (change) {
            vm.showSpinner = true;
            vm.detalleAdded = true;
            _.forEach(change, function (val) {
                val.$promise.then(function (cuota) {
                    addCuota(cuota);
                });
            });
            $q.all(change).then(function () {
                vm.traerComprobantes = true;
                if (vm.mode === "add") {
                    vm.comprobante.comprobanteAplicaciones = vm.comprobanteAplicaciones;
                }
                vm.showSpinner = false;
            });
        });
    }

    function filterSaldoCuotaPositivo(cuota) {
        return cuota.saldo > 0;
    }

    function clearFiltrosComprobantes() {
        vm.fechaDesde = null;
        vm.fechaHasta = null;
        if (vm.comprobante && vm.comprobante.comprobanteAplicaciones)
            vm.comprobante.comprobanteAplicaciones = null;
    }

    function addComprobante(comprobanteA) {
        // armar una estructura donde ir metiendo los comprobantes
        // si el tipo del comprobante admite cuotas, meter un registro por cada cuota con su nro correspondiente
        // sino meter un unico registro con nro cuota = 1
        $log.debug("procesando comprobantes en comprobanteAplicacion");
        // probar comprobantes con cuota, por ahora no esta generando
        if (comprobanteA.tipo.generarCuotas == true) {
            _.forEach(
                    _.filter(comprobanteA.planPagos.planPagosDetalles,
                            filterSaldoCuotaPositivo),
                    function (cuotaA) {
                        var comprobanteAplicacion = {};
                        comprobanteAplicacion.comprobanteAplicado = {};
                        comprobanteAplicacion.cuota = {};
                        comprobanteAplicacion.comprobanteAplicado.id = comprobanteA.id;
                        comprobanteAplicacion.comprobanteAplicado.numero =
                                comprobanteA.numero;
                        comprobanteAplicacion.comprobanteAplicado.tipo =
                                comprobanteA.tipo;
                        comprobanteAplicacion.comprobanteAplicado.saldoImpuestos =
                                comprobanteA.saldoImpuestos;
                        comprobanteAplicacion.comprobanteAplicado.totalImpuestos =
                                comprobanteA.totalImpuestos;
                        comprobanteAplicacion.cuota.id = cuotaA.id;
                        comprobanteAplicacion.cuota.numero = cuotaA.numero;
                        comprobanteAplicacion.cuota.saldo = cuotaA.saldo;
                        comprobanteAplicacion.comprobanteAplicado.saldo =
                                comprobanteA.saldo;
                        comprobanteAplicacion.comprobanteAplicado.montosImponibles = comprobanteA.montosImponibles;
                        vm.comprobanteAplicaciones.push(comprobanteAplicacion);
                    });
        } else {
            var comprobanteAplicacion = {};
            comprobanteAplicacion.comprobanteAplicado = {};
            comprobanteAplicacion.comprobanteAplicado.id = comprobanteA.id;
            comprobanteAplicacion.comprobanteAplicado.numero =
                    comprobanteA.numero;
            comprobanteAplicacion.comprobanteAplicado.tipo = comprobanteA.tipo;
            comprobanteAplicacion.comprobanteAplicado.saldo =
                    comprobanteA.saldo;
            comprobanteAplicacion.comprobanteAplicado.saldoImpuestos =
                    comprobanteA.saldoImpuestos;
            comprobanteAplicacion.comprobanteAplicado.totalImpuestos =
                    comprobanteA.totalImpuestos;
            comprobanteAplicacion.comprobanteAplicado.montosImponibles = comprobanteA.montosImponibles;
            vm.comprobanteAplicaciones.push(comprobanteAplicacion);
        }
    }

    function addCuota(cuotaA) {
        // armar una estructura donde ir metiendo los comprobantes
        // si el tipo del comprobante admite cuotas, meter un registro por cada cuota con su nro correspondiente
        // sino meter un unico registro con nro cuota = 1
        $log.debug("procesando comprobantes en comprobanteAplicacion");
        // probar comprobantes con cuota, por ahora no esta generando

        var comprobanteAplicacion = {};
        comprobanteAplicacion.comprobanteAplicado = {};
        comprobanteAplicacion.cuota = {};
        comprobanteAplicacion.comprobanteAplicado.id = cuotaA.planPagos.comprobante.id;
        comprobanteAplicacion.comprobanteAplicado.numero =
                cuotaA.planPagos.comprobante.numero;
        comprobanteAplicacion.comprobanteAplicado.tipo =
                cuotaA.planPagos.comprobante.tipo;
        comprobanteAplicacion.comprobanteAplicado.saldoImpuestos =
                cuotaA.planPagos.comprobante.saldoImpuestos;
        comprobanteAplicacion.comprobanteAplicado.totalImpuestos =
                cuotaA.planPagos.comprobante.totalImpuestos;
        comprobanteAplicacion.cuota.id = cuotaA.id;
        comprobanteAplicacion.cuota.numero = cuotaA.numero;
        comprobanteAplicacion.cuota.saldo = cuotaA.saldo;
        comprobanteAplicacion.comprobanteAplicado.saldo =
                cuotaA.planPagos.comprobante.saldo;
        comprobanteAplicacion.comprobanteAplicado.montosImponibles = cuotaA.planPagos.comprobante.montosImponibles;
        vm.comprobanteAplicaciones.push(comprobanteAplicacion);
    }

    function getComprobantes() {
        if (!vm.mode == "add") {
            return;
        }

        // vm.comprobantesOriginales = vm.comprobante.comprobanteAplicaciones;
        // delete vm.comprobante.comprobanteAplicaciones;
        $log.debug("llamada a getComprobantes()");
        var tiposComprobantesAsociados = vm.comprobante.tipo.tiposComprobante;
        if (!tiposComprobantesAsociados ||
                tiposComprobantesAsociados.length == 0
                ) {
            $translate("NO_COMPROBANTES_APLICABLES", {
                comprobante: vm.comprobante.tipo.descripcion
            }).then(function (response) {
                $scope.tituloModal = response;
            });

            $scope.modalInstanceBorrar1 = $modal.open({
                template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="ok()">' +
                        $filter("translate")("OK") +
                        "</button>" +
                        "</div>",
                scope: $scope
            });

            $scope.ok = function () {
                $scope.modalInstanceBorrar1.dismiss("");
                if (vm.isProcesoCompra) {
                    $state.go("app.compraslocales.proceso.comprobante.list", {
                        codigoCompra: CompraFactory.getIdCompra()
                    });
                } else if (vm.compraLocal) {
                    $state.go("app.consultacomprobantes.list");
                } else {
                    $state.go("app.importaciones.proceso.comprobante.list", {
                        codigoImportacion: ImportacionFactory.getIdImportacion()
                    });
                }
            };

            return;
        }


        $log.debug("vm.traerComprobantes == true");
        var staticFilter = {};
        var filters = [];
        _.forEach(tiposComprobantesAsociados, function (tipoComprobante) {

            var filter = filterFactory
                    .and([{
                            path: "tipo.id",
                            equals: tipoComprobante.id
                        },
                        {
                            path: "fecha",
                            equalOrAfter: vm.fechaDesde != undefined ?
                                    moment(vm.fechaDesde).format("DD/MM/YYYY") : undefined
                        },
                        {
                            path: "fecha",
                            equalOrBefore: vm.fechaHasta != undefined ?
                                    moment(vm.fechaHasta).format("DD/MM/YYYY") : undefined
                        },
                        {
                            path: "moneda1.id",
                            equals: vm.comprobante.moneda1.id
                        },
                        {
                            path: "proveedor.id",
                            equals: vm.comprobante.proveedor.id
                        },
                        {
                            path: "estado.codigo",
                            equals: "comp_aprobado"
                        },
                        {
                            path: "tipo.generarCuotas",
                            like: "false"
                        },
                        {
                            path: "tipo.estadoCuenta",
                            like: "true"
                        },
                        {
                            path: "saldo",
                            notEquals: "0"
                        }
                    ])
                    .value();
            filters.push(filter);
        });
        staticFilter.search = filterFactory.or(filters).value();
        vm.opcionesComprobantesDt.staticFilter = staticFilter;
        filtrarAgregados();
    }

    function getCuotas() {

        if (!vm.mode == "add") {
            return;
        }

        // vm.comprobantesOriginales = vm.comprobante.comprobanteAplicaciones;
        // delete vm.comprobante.comprobanteAplicaciones;
        $log.debug("llamada a getCuotas()");
        var tiposComprobantesAsociados = vm.comprobante.tipo.tiposComprobante;
        if (!tiposComprobantesAsociados ||
                tiposComprobantesAsociados.length == 0
                ) {
            $translate("NO_COMPROBANTES_APLICABLES", {
                comprobante: vm.comprobante.tipo.descripcion
            }).then(function (response) {
                $scope.tituloModal = response;
            });

            $scope.modalInstanceBorrar1 = $modal.open({
                template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="ok()">' +
                        $filter("translate")("OK") +
                        "</button>" +
                        "</div>",
                scope: $scope
            });

            $scope.ok = function () {
                $scope.modalInstanceBorrar1.dismiss("");
                if (vm.isProcesoCompra) {
                    $state.go("app.compraslocales.proceso.comprobante.list", {
                        codigoCompra: CompraFactory.getIdCompra()
                    });
                } else if (vm.compraLocal) {
                    $state.go("app.consultacomprobantes.list");
                } else {
                    $state.go("app.importaciones.proceso.comprobante.list", {
                        codigoImportacion: ImportacionFactory.getIdImportacion()
                    });
                }
            };

            return;
        }

        var staticFilter = {};
        var filters = [];
        _.forEach(tiposComprobantesAsociados, function (tipoComprobante) {

            var filter = filterFactory
                    .and([{
                            path: "planPagos.comprobante.tipo.id",
                            equals: tipoComprobante.id
                        },
                        {
                            path: "planPagos.comprobante.fecha",
                            equalOrAfter: vm.fechaDesde != undefined ?
                                    moment(vm.fechaDesde).format("DD/MM/YYYY") : undefined
                        },
                        {
                            path: "planPagos.comprobante.fecha",
                            equalOrBefore: vm.fechaHasta != undefined ?
                                    moment(vm.fechaHasta).format("DD/MM/YYYY") : undefined
                        },
                        {
                            path: "planPagos.comprobante.moneda1.id",
                            equals: vm.comprobante.moneda1.id
                        },
                        {
                            path: "planPagos.comprobante.proveedor.id",
                            equals: vm.comprobante.proveedor.id
                        },
                        {
                            path: "planPagos.comprobante.estado.codigo",
                            equals: "comp_aprobado"
                        },
                        {
                            path: "planPagos.comprobante.tipo.generarCuotas",
                            like: "true"
                        },
                        {
                            path: "planPagos.comprobante.tipo.estadoCuenta",
                            like: "true"
                        },
                        {
                            path: "saldo",
                            notEquals: "0"
                        }
                    ])
                    .value();
            filters.push(filter);
        });
        staticFilter.search = filterFactory.or(filters).value();
        vm.opcionesPlanPagosDetallesDt.staticFilter = staticFilter;
        filtrarAgregadosPlanPago();
    }

    function montoAplicadoChanged(detalle) {
        vm.enableSave = false;
    }

    function submit() {
        $rootScope.isProcessing = true;
        vm.submited = true;

        if (vm.comprobante.saldo >= 0) {
            //Control de tipos de impuesto
            if (vm.descontarNcRetencion){
                var impuesto5 = false;
                var impuesto10 = false;
                _.forEach(vm.comprobante.montosImponibles, function(monto){
                    if (monto.tipoImpuesto.porcentaje === 10){
                        impuesto10 = true;
                    }
                    if (monto.tipoImpuesto.porcentaje === 5){
                        impuesto5 = true;
                    }
                });
                // Controlar por cada comprobante aplicado
                var cumpleCondicionImpuestos = true;
                var numeroComprobante = "";
                _.forEach(vm.comprobante.comprobanteAplicaciones, function(comprobanteAplicacion){
                    var aplicacionImpuesto5 = false;
                    var aplicacionImpuesto10 = false;
                    _.forEach(comprobanteAplicacion.comprobanteAplicado.montosImponibles, function(monto){
                        if (monto.tipoImpuesto.porcentaje === 10){
                            aplicacionImpuesto10 = true;
                        }
                        if (monto.tipoImpuesto.porcentaje === 5){
                            aplicacionImpuesto5 = true;
                        }
                    });
                    if (impuesto5 != aplicacionImpuesto5 || impuesto10 != aplicacionImpuesto10){
                        cumpleCondicionImpuestos = false;
                        numeroComprobante = comprobanteAplicacion.comprobanteAplicado.numero;
                        return;
                    }
                });
                if (!cumpleCondicionImpuestos){
                    var msg = $filter("translate")("NO_CUMPLE_CONDICION_IMPUESTOS");
                    notify({message: msg + " Número: " + numeroComprobante, classes: "alert-danger", position: "right"});
                    $rootScope.isProcessing = false;
                    vm.submited = false;
                    return;
                }
            }
            // guardar el comprobante con su lista de comprobantes aplicados
            vm.comprobante.comprobanteAplicaciones = _.filter(
                    vm.comprobante.comprobanteAplicaciones,
                    function (elem) {
                        return elem.montoAplicado;
                    }
            );
            ComprobanteFactory.aplicar(vm.comprobante, vm.mode).then(
                    function (data) {
                        if (vm.isProcesoCompra) {
                            $state.go("app.compraslocales.proceso.comprobante.list", {
                                codigoCompra: CompraFactory.getIdCompra()
                            });
                        } else if (vm.compraLocal) {
                            $state.go("app.consultacomprobantes.list");
                        } else {
                            $scope.$emit("updateImportacionStates");
                            $state.go("app.importaciones.proceso.comprobante.list", {
                                codigoImportacion: ImportacionFactory.getIdImportacion()
                            });
                        }
                    },
                    function (error) {
                        $rootScope.isProcessing = false;
                        var msgArray = _.map(error.data, function (e) {
                            return e.message;
                        });
                        var msg = msgArray.join("\n");
                        notify({message: msg, classes: "alert-danger", position: "right"});
                        vm.comprobante.comprobanteAplicaciones = _.filter(
                                vm.comprobante.comprobanteAplicaciones,
                                function (ca) {
                                    return !ca.id;
                                }
                        );
                    }
            );
        } else {
            var msg = $filter("translate")("COMPROBANTE_SIN_SALDO");
            notify({message: msg, classes: "alert-danger", position: "right"});
            vm.view = true;
        }
    }

    function previewAplicacion() {
        if (vm.comprobante.comprobanteAplicaciones) {
            var saldoOriginal = vm.comprobante.saldo;
            var saldoImpuesto = vm.comprobante.saldoImpuestos;
            _.forEach(vm.comprobante.comprobanteAplicaciones, function (comprobanteAplicado) {
                comprobanteAplicado.impuestoAplicado = 0;
            });
            vm.monedaLocal.$promise
                    .then(function () {
                        return checkAmounts();
                    })
                    .then(function () {
                        vm.enableSave = true;
                    })
                    .catch(function (error) {
                        notify({
                            messageTemplate: "<span>" + error.join("<br/><br/>") + "</span>",
                            classes: "alert-danger",
                            position: "right"
                        });
                        vm.enableSave = false;
                    })
                    .finally(function () {
                        vm.comprobante.saldo = saldoOriginal;
                        vm.comprobante.saldoImpuestos = saldoImpuesto;
                    });
        }
    }

    function checkAvailability() {
        //var mensajesPromises = [];
        _.forEach(vm.comprobante.comprobanteAplicaciones, function (comprobanteAplicacion) {
            var filterComproabante = {};
            filterComproabante.search = filterFactory
                    .and([{
                            path: "id",
                            equals: comprobanteAplicacion.comprobanteAplicado.id
                        }]).value();

            ComprobanteFactory.getRetenciones(
                    filterComproabante,
                    "ComprobanteList"
                    ).$promise.then(function (response) {
                _.forEach(response, function (value) {
                    if (value.estado.codigo != 'comprobante_retencion_anulado') {
                        console.log("entre aca?");
                    }
                })
            });
        });
    }
    ;

    function checkAmounts() {
        return $q(function (resolve, reject) {
            var saldoAplicacion = vm.comprobante.saldo;
            if (vm.mode == "edit") {
                saldoAplicacion = vm.comprobante.montoTotal;
            }
            var saldoImpuesto = vm.comprobante.saldoImpuestos;
            var mensajesPromises = [];

            var group = _.groupBy(vm.comprobante.comprobanteAplicaciones, function (
                    comprobanteAplicacion
                    ) {
                return comprobanteAplicacion.comprobanteAplicado.id;
            });
            _.forOwn(group, function (value, key) {
                if (value[0].cuota) {
                    group[key] = _.sortBy(value, function (ca) {
                        return ca.cuota.numero;
                    });
                }
            });

            _.forOwn(group, function (value, key) {
                var saldoInicial = saldoAplicacion;
                var sumaAplicacion = 0;
                // value = _.filter(value, function(v) {
                //     return v.montoAplicado;
                // });
                _.forEach(value, function (comprobanteAplicacion) {

                    var montoAplicado = comprobanteAplicacion.montoAplicado ?
                            comprobanteAplicacion.montoAplicado :
                            0;
                    if (montoAplicado <= 0) {
                        mensajesPromises.push(
                                $translate("MONTO_MENOR_IGUAL_CERO", {
                                    comprobante: comprobanteAplicacion.comprobanteAplicado.numero
                                })
                                );
                    }
                    if (!comprobanteAplicacion.cuota) {
                        var saldo = comprobanteAplicacion.comprobanteAplicado.saldo;
                        if (montoAplicado > saldo) {
                            mensajesPromises.push(
                                    $translate("MONTO_MAYOR_SALDO_COMPROBANTE", {
                                        comprobante: comprobanteAplicacion.comprobanteAplicado.numero
                                    })
                                    );
                        }
                    } else {
                        var saldo = comprobanteAplicacion.cuota.saldo;
                        if (montoAplicado > saldo) {
                            mensajesPromises.push(
                                    $translate("MONTO_MAYOR_SALDO_CUOTA", {
                                        comprobante: comprobanteAplicacion.comprobanteAplicado.numero,
                                        cuota: comprobanteAplicacion.cuota.numero
                                    })
                                    );
                        }
                    }
                    var saldoPadre = saldoAplicacion;
                    if (montoAplicado > saldoPadre) {
                        mensajesPromises.push(
                                $translate("MONTO_MAYOR_SALDO_ACTUAL", {
                                    comprobante: comprobanteAplicacion.comprobanteAplicado.numero
                                })
                                );
                    }

                    saldoAplicacion -= montoAplicado;
                    sumaAplicacion += montoAplicado;

                    if (vm.comprobante.moneda1.codigo === "guaranies") {
                        saldoAplicacion = parseFloat(saldoAplicacion.toFixed(0))
                    } else if (vm.comprobante.moneda1.codigo === "dolares") {
                        saldoAplicacion = parseFloat(saldoAplicacion.toFixed(2))
                    }

                });

                var comprobanteAplicacion = value[0];

                var saldoInicialTrue = saldoInicial;
                var porcentajeAplicado = 100 * sumaAplicacion / saldoInicialTrue;

                if (porcentajeAplicado > 100) {
                    mensajesPromises.push(
                            $translate("APLICACION_MAYOR_TOTAL_ERROR", {
                                comprobante: comprobanteAplicacion.comprobanteAplicado.numero
                            })
                            );
                }

                var impuestoAplicado = 0;
                if (
                        comprobanteAplicacion &&
                        comprobanteAplicacion.comprobanteAplicado.totalImpuestos != 0 &&
                        comprobanteAplicacion.comprobanteAplicado.totalImpuestos != undefined
                        ) {
                    impuestoAplicado = porcentajeAplicado * saldoImpuesto / 100;
                    if (
                            vm.comprobante.moneda1 &&
                            vm.comprobante.moneda1.descripcion ==
                            vm.monedaLocal[0].valorTipo.descripcion
                            ) {
                        impuestoAplicado = Math.round(impuestoAplicado);
                    }
                    var sImpuesto =
                            comprobanteAplicacion.comprobanteAplicado.saldoImpuestos;
                    var dif = impuestoAplicado - sImpuesto;
                    if (dif > 1) {
                        mensajesPromises.push(
                                $translate("APLICACION_IMPUESTO_MAYOR_SALDO", {
                                    comprobante: comprobanteAplicacion.comprobanteAplicado.numero
                                })
                                );
                    } else if (dif == 1) {
                        impuestoAplicado = sImpuesto;
                    }
                }
                saldoImpuesto -= impuestoAplicado;

                if (comprobanteAplicacion) {
                    if (vm.comprobante.moneda1.codigo === "guaranies") {
                        comprobanteAplicacion.impuestoAplicado = parseFloat(impuestoAplicado.toFixed(0));
                    } else if (vm.comprobante.moneda1.codigo === "dolares") {
                        comprobanteAplicacion.impuestoAplicado = parseFloat(impuestoAplicado.toFixed(2));
                    }
                }

                var filterComproabante = {};
                filterComproabante.search = filterFactory
                        .and([{
                                path: "id",
                                equals: comprobanteAplicacion.comprobanteAplicado.id
                            }]).value();
                ComprobanteFactory.getRetenciones(
                        filterComproabante,
                        //"base"
                        //"ComprobanteList"
                        "ComprobanteRetencionList"
                        ).$promise.then(function (response) {
                    _.forEach(response, function (value) {
                        // if (value.estado.codigo != 'comprobante_retencion_anulado') {
                        //     mensajesPromises.push(
                        //             $translate("ERROR_RETENCION", {
                        //                 comprobante: comprobanteAplicacion.comprobanteAplicado.numero
                        //             })
                        //             );
                        // }
                    })
                    if (mensajesPromises.length > 0) {
                        $q.all(mensajesPromises).then(function (response) {
                            reject(response);
                        });
                    } else {
                        resolve();
                    }
                });
            });
        });
    }

    function removeItemFromArray(elemento, arreglo) {
       /* filtrarAgregados();
        filtrarAgregadosPlanPago();*/
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
    }

    function cancel() {
        if (vm.isProcesoCompra) {
            $state.go("app.compraslocales.proceso.comprobante.list", {
                codigoCompra: CompraFactory.getIdCompra()
            });
        } else if (vm.compraLocal) {
            $state.go("app.consultacomprobantes.list");
        } else {
            $state.go("app.importaciones.proceso.comprobante.list", {
                codigoImportacion: ImportacionFactory.getIdImportacion()
            });
        }
    }
}

'use strict';


angular.module('qualita.importacion')
    .controller('CierreImportacionCtrl', CierreImportacionCtrl);

CierreImportacionCtrl.$inject = ["ImportacionFactory", "OrdenesCompraFactory", "ProformaFactory",
    "EmbarqueFactory", "ComprobanteFactory", "DespachoFactory", "RecepcionFactory", "ProductoAlmacenFactory", "filterFactory", "ParametrosFactory",
    "$scope", "notify", "$state", "$q", "$rootScope", "ImportacionLangFactory", "Util", "$filter", "$modal", "ReportTicketFactory", "$window"
];

function CierreImportacionCtrl(ImportacionFactory, OrdenesCompraFactory, ProformaFactory,
    EmbarqueFactory, ComprobanteFactory, DespachoFactory, RecepcionFactory, ProductoAlmacenFactory, filterFactory,
    ParametrosFactory, $scope, notify, $state, $q, $rootScope, ImportacionLangFactory, Util, $filter, $modal, ReportTicketFactory, $window) {
    var vm = this;
    vm.cancel = cancel;
    vm.submit = submit;
    vm.submitQuestion = submitQuestion;
    vm.sumItemsImponibles = sumItemsImponibles;
    vm.sumItemsImpuestos = sumItemsImpuestos;
    vm.calcularUltimoCostoMonedaOrigen = calcularUltimoCostoMonedaOrigen;
    vm.coeficiente = 0;
    vm.totalCosto = 0;
    vm.totalGastos = 0;
    vm.totalGastosImponibles = 0;
    vm.totalGastosImpuestos = 0;
    vm.totalAnticipoIracis = 0;
    vm.redondeoMonedaLocal = redondeoMonedaLocal;
    vm.generateReport = generateReport;

    activate();
    $rootScope.isProcessing = false;

    function activate() {
        $scope.cerrarOC = false;
        ImportacionLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;
        });
        vm.cierre = {};
        vm.cierre.totalGastosDespacho = 0;
        vm.cierre.totalLiqImpuestos = 0;

        var staticFilterOC = {};
        staticFilterOC.search = filterFactory.and([{
            path: 'importaciones.codigo',
            equals: ImportacionFactory.getCodigoImportacion(),
        }]).value();
        staticFilterOC.view = "ImportacionCierreList";
        vm.ordenesCompra = OrdenesCompraFactory.all(staticFilterOC);

        var promise4 = vm.ordenesCompra.$promise.then(function(ordenesCompra) {
            _.forEach(ordenesCompra, function(ordenCompra) {
                addMontosSum(ordenCompra);
                ordenCompra.fecha = moment(ordenCompra.fecha, "YYYY/MM/DD").format("DD/MM/YYYY");
            });
            return ordenesCompra;
        });

        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'importacion.codigo',
            equals: ImportacionFactory.getCodigoImportacion(),
        }]).value();
        // staticFilter.view = "ImportacionCierreList";

        vm.proformas = ProformaFactory.all(staticFilter, "ImportacionCierreList");
        vm.proformas.$promise.then(function(proformas) {
            _.forEach(vm.proformas, function(proforma) {
                proforma.fecha = moment(proforma.fecha, "YYYY/MM/DD").format("DD/MM/YYYY");
            });
        });

        vm.embarques = EmbarqueFactory.all(staticFilter, "ImportacionCierreList");
        vm.embarques.$promise.then(function(embarques) {
            _.forEach(embarques, function(embarque) {
                embarque.fechaEmbarque = moment(embarque.fechaEmbarque, "YYYY/MM/DD").format("DD/MM/YYYY");
                embarque.fechaLlegada = moment(embarque.fechaLlegada, "YYYY/MM/DD").format("DD/MM/YYYY");
            });
        });


        var staticFilterCG = {};
        staticFilterCG.search = filterFactory.and([{
                path: 'grupoCompra.codigo',
                equals: 'gasto_importacion'
            },
            {
                path: 'importacion.codigo',
                equals: ImportacionFactory.getCodigoImportacion(),
            }
        ]).value();
        vm.comprobantesImportacion = ComprobanteFactory.all(staticFilterCG);
        var promise1 = vm.comprobantesImportacion.$promise.then(function(comprobantes) {
            addTotalGastosDespacho(comprobantes);
            return comprobantes;
        });

        vm.despacho = DespachoFactory.all(staticFilter, "DespachoForm");
        var promise2 = vm.despacho.$promise.then(function(despacho) {
            addTotalLiqImpuestos(despacho[0]);
            addTotalesAdicionales(despacho[0]);

            //Calculo del 'Anticipo IRACIS'
            _.forEach(despacho[0].despachoDetalle, function(detalle) {
                if(detalle.tipoImpuesto.anticipoIracis == true){
                    vm.totalAnticipoIracis += detalle.importe;
                }
            });

            return despacho;
        });

        var staticFilterCI = {};
        staticFilterCI.search = filterFactory.and([{
            path: 'grupoCompra.codigo',
            equals: 'comprobante_importacion'
        }, {
            path: 'importacion.codigo',
            equals: ImportacionFactory.getCodigoImportacion(),
        }]).value();
        vm.comprobantesImportacion2 = ComprobanteFactory.all(staticFilterCI, "ImportacionCierreList");
        var promise3 = vm.comprobantesImportacion2.$promise.then(function(comprobantes) {
            addTotalFactImponible(comprobantes);
            return comprobantes;
        });

        vm.recepciones = RecepcionFactory.getByImportacion(ImportacionFactory.getIdImportacion());


        var staticFilterComp = {};
        staticFilterComp.search = filterFactory.and([{
            path: 'importacion.codigo',
            equals: ImportacionFactory.getCodigoImportacion()},
            {
            path: 'tipo.clase.codigo',
            notLike: 'despacho'
            }]).value();

        vm.comprobantes = ComprobanteFactory.all(staticFilterComp, "ImportacionCierreList");
        vm.comprobantes.$promise.then(function(comprobantes) {
            _.forEach(comprobantes, function(comprobante) {
                comprobante.fecha = moment(comprobante.fecha, "YYYY/MM/DD").format("DD/MM/YYYY");
            });
        });
        var importacion = ImportacionFactory.getImportacion();

        var monedaSearchParam = {};
        monedaSearchParam.search = filterFactory.single({
            path: 'codigo',
            equals: 'moneda_local'
        }).value();
        vm.monedaLocal = ParametrosFactory.all(monedaSearchParam);

        ImportacionFactory.getCoeficiente(ImportacionFactory.getIdImportacion()).$promise.then(function(response) {
            vm.coeficiente = response.coeficiente;
            var promises = [promise1, promise2, promise3, promise4, vm.recepciones.$promise, vm.monedaLocal];
            //var promises = [promise1, promise2, promise3, promise4, vm.recepciones.$promise, promise5,promise6];
            $q.all(promises).then(function(responses) {
                addMontoTotal();
                getAllProductos();
                return responses;
            });
        });

        if (importacion.estado.codigo == "cerrada") {
            vm.view = true;
        }
    }

    function cancel() {
        $state.go('app.importaciones.list');
    }

    function generateReport() {
        var importacion = ImportacionFactory.getImportacion();
        ParametrosFactory.getByCodigo("nombre_empresa").then(function(data) {
            $scope.nombreEmpresa = data;

            var filtersArr = [];
            filtersArr.push("nombre_empresa=" + $scope.nombreEmpresa.valor);
            filtersArr.push("id_importacion=" + importacion.id);
            filtersArr.push("coefic_calculado=" + vm.coeficiente);
            filtersArr.push("id_despacho=" + vm.despacho[0].id);
            filtersArr.push("id_orden_compra=" + vm.ordenesCompra[0].id);

            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                "cierreimportacion",
                "pdf",
                filtersArr
            );
            $window.open(vm.pdfDownloadURL, "_blank");
            $state.go($state.current, {}, { reload: true });

        });

    }

    function submitQuestion(){

        $scope.tituloModal = $filter("translate")("CERRAR_IMPORTACION_TITLE");
        $scope.mensajeModal = $filter("translate")("CERRAR_IMPORTACION_MESSAGE");
        $scope.si_no = true;
        var modalInstance = $modal.open({
            template: '<div class="modal-header">' +
                '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                '</div>' +
                '<div class="modal-body">{{::mensajeModal}}' +
                '</div>' +
                '<div class="modal-footer">' +
                '<button class="btn btn-primary" ng-click="ok(selectedItemId);">' + $filter('translate')('OK') + '</button>' +
                '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                '</div>',
            scope: $scope
        });

        $scope.cancel = function() {
            modalInstance.dismiss("cancel");
        };

        $scope.ok = function(itemId) {
            modalInstance.close();
            submitQuestionOrdenCompra();
        };
    }

    function submitQuestionOrdenCompra() {
        $scope.tituloModal = $filter('translate')('CERRAR_IMPORTACION_CON_COMPRA');
        $scope.mensajeModal = $filter('translate')('CERRAR_IMPORTACION_CON_COMPRA_MSG');
        $scope.si_no = true;
        var modalInstance = $modal.open({
            //templateUrl: 'views/directives/confirmation-modal.html',
             template: '<div class="modal-header">' +
                '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                '</div>' +
                '<div class="modal-body">{{::mensajeModal}}</div>' +
                '<div class="modal-footer">' +
                '<button class="btn btn-primary" ng-click="ok(selectedItemId);">{{si_no == true ? "Si" : "Aceptar"}}</button>' +
                '<button class="btn btn-warning" ng-click="cancel()">{{si_no == true ? "No" : "Cancelar"}}</button>' +
                '</div>',
            scope: $scope
        });

        $scope.cancel = function() {
            // console.log("cancel");
            if($scope.cerrarOC !==true){
                modalInstance.dismiss('cancel');
                $scope.cerrarOC = false;
                submit();
            }
        };

        $scope.ok = function(itemId) {
            // console.log("ok");
            $scope.cerrarOC = true;
            modalInstance.close();
            submit();
        }
    }

    function submit() {
        vm.submited = true;
        if ($scope.CierreImportacionForm.$valid) {
            $rootScope.isProcessing = true;

            //Se realiza la verificacion de diferencias
            _.forEach(vm.productos, function(producto) {

                if( producto.cantidadOrdenCompra == producto.cantidadProforma && producto.cantidadProforma == producto.cantidadRecepcion &&
                    producto.cantidadRecepcion == producto.cantidadFactura ){
                    $scope.tieneDiferencia = false;
                }else{
                    $scope.tieneDiferencia = true;
                }

            });

            submitCierre().then(function(response) {
                if (response.data.listaArchivosLogs.length != 0) {
                  /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                      console.log('response.listaArchivosLogs: ');
                      console.log(response.listaArchivosLogs[i]);
                      $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                  }*/
                  notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                }
                $state.go('app.importaciones.list');
                return response;
            }, function(error) {
                $rootScope.isProcessing = false;
                errorHandler(error);
            });
        } else {
            $rootScope.isProcessing = false;
            notify({ messageTemplate: errors, classes: 'alert-danger', position: 'right' });
        }
    }

    function errorHandler(e) {
        var msg = $filter('translate')('ERROR_CIERRE_IMPORTACION');
        _.forEach(e.data, function(error) {
            if(error.message){
                msg = "\n\n" + error.message + ".";
            }
        });
        notify({ message: msg, classes: "alert-danger", position: "right" });
    }

    function submitCierre() {
        return ImportacionFactory.cerrar(ImportacionFactory.getIdImportacion(), $scope.cerrarOC, $scope.tieneDiferencia);
    }

    function addMontoTotal() {
        vm.cierre.totalCosto = vm.cierre.totalGastosDespacho + vm.cierre.totalFactImp +
            vm.cierre.totalFlete + vm.cierre.totalSeguro;

        vm.cierre.totalCosto = parseFloat(vm.cierre.totalCosto).toFixed(0);
    }

    function addMontosSum(ordenCompra) {
        ordenCompra.montoTotal = _.reduce(ordenCompra.ordenCompraDetalle, function(sum, val) { return sum + val.totalItem; }, 0);
    }

    function addTotalGastosDespacho(comprobantes) {
        _.forEach(comprobantes, function(comprobante) {
            var sumAux = 0;
            var sumAuxNC = 0;
            var tipoCambio = comprobante.cambio;
            if(comprobante.tipo.clase.codigo != "nota_credito"){
                _.forEach(comprobante.montosImponibles, function(montoImponible) {
                    sumAux = sumAux + montoImponible.baseImponible;
                    vm.cierre.totalLiqImpuestos = vm.cierre.totalLiqImpuestos + (montoImponible.valor * tipoCambio);
                });
            }else{
                _.forEach(comprobante.montosImponibles, function(montoImponible) {
                    sumAuxNC = sumAuxNC + montoImponible.baseImponible;
                    vm.cierre.totalLiqImpuestos = vm.cierre.totalLiqImpuestos - (montoImponible.valor * tipoCambio);
                });
            }
        
            vm.cierre.totalGastosDespacho = vm.cierre.totalGastosDespacho + (sumAux * comprobante.cambio) - (sumAuxNC * comprobante.cambio);
        });
    }

    function addTotalFactImponible(comprobantes) {
        vm.cierre.totalFactImp = _.reduce(comprobantes, function(sum, val) {
            return sum + val.fob * val.cambio;
        }, 0);
        vm.cierre.totalFactImp2 = parseFloat(vm.cierre.totalFactImp).toFixed(0);
    }

    function addTotalLiqImpuestos(despacho) {
        var impuestosNoAfectanCosto = 0;
        _.forEach(despacho.despachoDetalle, function(val) {
            if (!val.tipoImpuesto.afectaCosto) {
                impuestosNoAfectanCosto = impuestosNoAfectanCosto + val.importe;
            } else {
                vm.cierre.totalGastosDespacho = vm.cierre.totalGastosDespacho + val.importe;
            }
        });
        vm.cierre.totalLiqImpuestos = vm.cierre.totalLiqImpuestos + impuestosNoAfectanCosto;
    }

    function addTotalesAdicionales(despacho) {
        vm.cierre.totalFlete = despacho.valorFlete;
        vm.cierre.totalSeguro = despacho.valorSeguro;
    }

    function sumItemsImponibles(comprobante) {
        if (comprobante.tipo.descripcion == "Comprobante de Despacho") {
            return comprobante.imponible
        }
        
        if (comprobante.tipo.clase.codigo === "nota_credito") {
            // Si es una Nota de Crédito, restar en lugar de sumar
            return _.reduce(comprobante.montosImponibles, function(sum, val) {
                if (val.porcentaje !== 0) {
                    return sum - (val.baseImponible * comprobante.cambio);
                }
                return sum - (val.baseImponible * comprobante.cambio);
            }, 0);
        }

        return _.reduce(comprobante.montosImponibles, function(sum, val) {
            if (val.porcentaje != 0) {
                return sum + (val.baseImponible * comprobante.cambio);
            }
            return sum + (val.baseImponible * comprobante.cambio);
        }, 0);
    }

    function sumItemsImpuestos(comprobante) {

        if (comprobante.tipo.descripcion == "Comprobante de Despacho") {
            return comprobante.impuesto
        }

        if (comprobante.tipo.clase.codigo === "nota_credito") {
            // Si es una Nota de Crédito, restar en lugar de sumar
            return _.reduce(comprobante.montosImponibles, function(sum, val) {
                if (val.porcentaje !== 0) {
                    return sum - (val.valor * comprobante.cambio);
                }
                return sum - (val.valor * comprobante.cambio);
            }, 0);
        }

        return _.reduce(comprobante.montosImponibles, function(sum, val) {
            if (val.porcentaje != 0) {
                return sum + (val.valor * comprobante.cambio);
            }
            return sum + (val.valor * comprobante.cambio);
        }, 0);
    }

    function calcularUltimoCostoMonedaOrigen(producto) {
        vm.despacho.$promise.then(function(despacho) {
            return producto.costoUnitarioFactura / despacho.tipoCambioFactura;
        });
    }

    function redondeoMonedaLocal(monto){

        if(vm.monedaLocal[0].valorTipo.codigo == "guaranies"){
            return parseFloat(monto).toFixed(0);
        }else if(vm.monedaLocal[0].valorTipo.codigo == "dolares"){
            return parseFloat(monto).toFixed(4);
        }
    }

    function getAllProductos() {
        vm.productos = [];

        vm.despacho.$promise.then(function(despacho) {
            _.forEach(vm.ordenesCompra, function(ordenCompra) {
                _.forEach(ordenCompra.ordenCompraDetalle, function(detalle) {
                    if (!detalle.producto) {
                        return;
                    }
                    var val = _.find(vm.productos, function(producto) {
                        return producto.id == detalle.producto.id;
                    });
                    // console.log(detalle.producto);
                    var multUM = getMultiplicador(detalle.unidadMedida);
                    if (!val) {
                        var val = detalle.producto;
                        val.cantidadOrdenCompra = detalle.cantidad * multUM;
                        val.importeOrdenCompra = detalle.totalItem;
                        val.cantidadProforma = 0;
                        val.importeProforma = 0;
                        val.cantidadRecepcion = 0;
                        val.cantidadFactura = 0;
                        val.importeFactura = 0;
                        val.costoUnitarioFactura = 0;
                        val.costoSincoeficiente = 0;
                        val.unidadMedidaFactura = detalle.unidadMedida;
                        vm.productos.push(detalle.producto);
                    } else {
                        val.cantidadOrdenCompra = val.cantidadOrdenCompra + detalle.cantidad * multUM;
                        val.importeOrdenCompra = val.importeOrdenCompra + detalle.totalItem;
                    }
                });

            });

            _.forEach(vm.proformas, function(proforma) {

                _.forEach(proforma.proformaDetalle, function(detalle) {
                    if (!detalle.producto) {
                        return;
                    }
                    var val = _.find(vm.productos, function(producto) {
                        return producto.id == detalle.producto.id;
                    });
                    var multUM = getMultiplicador(detalle.unidadMedida);
                    if (!val) {
                        var val = detalle.producto;
                        val.cantidadOrdenCompra = 0;
                        val.importeOrdenCompra = 0;
                        val.cantidadProforma = detalle.cantidad * multUM;
                        val.importeProforma = detalle.totalItem;
                        val.cantidadRecepcion = 0;
                        val.cantidadFactura = 0;
                        val.importeFactura = 0;
                        val.costoUnitarioFactura = 0;
                        val.costoSincoeficiente = 0;
                        val.unidadMedidaFactura = detalle.unidadMedida;
                        vm.productos.push(detalle.producto);
                    } else {
                        val.cantidadProforma = val.cantidadProforma + detalle.cantidad * multUM;
                        val.importeProforma = val.importeProforma + detalle.totalItem;
                    }
                });

            });

            _.forEach(vm.recepciones, function(recepciones) {
                _.forEach(recepciones.recepcionDetalle, function(detalle) {
                    if (!detalle.producto) {
                        return;
                    }
                    var val = _.find(vm.productos, function(producto) {
                        return producto.id == detalle.producto.id;
                    });
                    var multUM = getMultiplicador(detalle.unidadMedida);
                    if (!val) {
                        val = detalle.producto;
                        val.cantidadOrdenCompra = 0;
                        val.importeOrdenCompra = 0;
                        val.cantidadProforma = 0;
                        val.importeProforma = 0;
                        val.cantidadFactura = 0;
                        val.importeFactura = 0;
                        val.costoUnitarioFactura = 0;
                        val.costoSincoeficiente = 0;
                        val.cantidadRecepcion = recepciones.estadoRecepcion.codigo !== "er_anulado" ? detalle.cantidadRecepcionada * multUM : 0;
                        val.unidadMedidaFactura = detalle.unidadMedida;
                        vm.productos.push(val);
                    } else {
                        var suma = 0;
                        if (val.cantidadRecepcion) {
                            suma = val.cantidadRecepcion;
                        }
                        val.cantidadRecepcion = (recepciones.estadoRecepcion.codigo !== "er_anulado" ? detalle.cantidadRecepcionada * multUM : 0) + suma;
                    }

                });
            });

            _.forEach(vm.comprobantesImportacion2, function(comprobantes) {
                _.forEach(comprobantes.comprobanteDetalle, function(detalle) {
                    if (!detalle.producto) {
                        return;
                    }
                    var val = _.find(vm.productos, function(producto) {
                        return producto.id == detalle.producto.id;
                    });
                    var multUM = getMultiplicador(detalle.unidadMedida);
                    if (!val) {

                        // console.log("cuando hay uno solo: ", detalle.producto);
                        val = detalle.producto;
                        vm.productos.push(val);
                        val.cantidadOrdenCompra = 0;
                        val.importeOrdenCompra = 0;
                        val.cantidadProforma = 0;
                        val.importeProforma = 0;
                        val.cantidadRecepcion = detalle.cantidadRecepcionada * multUM;
                        val.cantidadFactura = detalle.cantidad * multUM;
                        val.importeFactura = detalle.totalItem;
                        var tipoCambio = comprobantes.cambio;
                        if (tipoCambio == undefined || tipoCambio == 0) {
                            tipoCambio = 1;
                        }
                        val.costoUnitarioFactura = val.importeFactura * vm.coeficiente / val.cantidadFactura;

                        val.costoSincoeficiente = val.importeFactura / val.cantidadFactura;
                        val.costoUnitarioMonedaOrigen = val.costoUnitarioFactura / despacho[0].tipoCambioFactura;
                        val.unidadMedidaFactura = detalle.unidadMedida;
                    } else {
                        var suma = 0;
                        suma = val.importeFactura ? val.importeFactura : 0;
                        val.importeFactura = detalle.totalItem + suma;

                        if (val.cantidadFactura) {
                            suma = val.cantidadFactura;
                        }
                        val.cantidadFactura = detalle.cantidad * multUM + suma;

                        var tipoCambio = comprobantes.cambio;
                        if (tipoCambio == undefined || tipoCambio == 0) {
                            tipoCambio = 1;
                        }
                        val.costoUnitarioFactura = val.importeFactura * vm.coeficiente / val.cantidadFactura;

                        val.costoSincoeficiente = val.importeFactura / val.cantidadFactura;
                        val.costoUnitarioMonedaOrigen = val.costoUnitarioFactura / despacho[0].tipoCambioFactura;
                        val.unidadMedidaFactura = detalle.unidadMedida;
                    }
                });
            });

            _.forEach(vm.productos, function(producto) {
                vm.totalCosto = vm.totalCosto + (producto.costoUnitarioFactura * producto.cantidadFactura);
            });

            // obtener y agregar los saldos de los productos
            var productoIDs = [];
            _.forEach(vm.productos, function(value) {
                return productoIDs.push(value.id);
            });
            // console.log(productoIDs);

            ProductoAlmacenFactory.obtenerSaldos(productoIDs, moment(despacho[0].fecha, "YYYY/MM/DD").format("DD/MM/YYYY")).then(function(data) {
                var saldos = data.data;
                vm.productos = _.forEach(vm.productos, function(producto) {
                    // para los calculos se usann el costoUnitario de la factura y la cantidad de la factura
                    var saldoActual = _.find(saldos, function(o) { return o.producto.id == producto.id; });
                    if (ImportacionFactory.getImportacion().estado.codigo == 'cerrada') {
                        if (saldoActual) {
                            producto.existenciaResultante = saldoActual.saldoReal;
                            producto.costoPromedio = saldoActual.costoPromedio;
                        } else {
                            // esto no deberia pasar nunca, porque una vez que se cerro ya se almaceno el saldo/costo real
                            producto.existenciaResultante = producto.cantidadRecepcion;
                            producto.costoPromedio = producto.costoUnitarioFactura;
                        }
                    } else {
                        if (saldoActual) {
                            producto.existenciaResultante = producto.cantidadRecepcion + saldoActual.saldoReal;
                            producto.costoPromedio = ((producto.costoUnitarioFactura * producto.cantidadFactura) + (saldoActual.saldoFacturado * saldoActual.costoPromedio)) / (producto.cantidadFactura + saldoActual.saldoFacturado);
                        } else {
                            producto.existenciaResultante = producto.cantidadRecepcion;
                            producto.costoPromedio = producto.costoUnitarioFactura;
                        }
                    }
                    producto.costoPromedio = redondeoMonedaLocal(producto.costoPromedio);

                })
                return vm.productos;

            });

            // crear comprobante de despacho, se calculan los impuestos y la base imponible ya que
            // el comprobante de despacho generado al guardar el despacho solo tiene el monto total.

             var imponibleSum = 0;
             var impuestoSum = 0;
             _.forEach(despacho[0].despachoDetalle, function(impuesto) {
                if(impuesto.tipoImpuesto.anticipoIracis != true){
                    if (impuesto.tipoImpuesto.afectaCosto) {
                        imponibleSum = imponibleSum + impuesto.importe;
                    } else {
                        impuestoSum = impuestoSum + impuesto.importe;
                    }
                }
             });
             vm.totalDespachoImponible = imponibleSum;
             vm.totalDespachoImpuesto = impuestoSum;

            // Se recupera la moneda para poder generar 'comprobanteDespacho'
            vm.monedaLocal.$promise.then(function(moneda){

                var comprobanteDespacho = {
                     tipo: { descripcion: 'Comprobante de Despacho' },
                     grupoCompra: { descripcion: 'Gastos de despacho' },
                     numero: despacho[0].numero,
                     fecha: moment(despacho[0].fecha, "YYYY/MM/DD").format("DD/MM/YYYY"),
                     cambio: 1,
                     imponible: imponibleSum,
                     impuesto: impuestoSum,
                     montoTotal: imponibleSum + impuestoSum,
                      // Se utiliza la moneda recuperada
                     moneda1: moneda[0].valorTipo,
                     iracis: vm.totalAnticipoIracis
                };
                vm.comprobantes.$promise.then(function(comprobantes) {
                    comprobantes.push(comprobanteDespacho);
                     _.forEach(comprobantes, function(comprobante) {

                        // Calculo de impuesto del comprobante
                        comprobante.impuesto = sumItemsImpuestos(comprobante);

                        // Redondeo del calculo anterior
                        // Se multiplica por 1 ya que la funcion redondeoMonedaLocal devuelve texto, asi lo convertimos a numero
                        comprobante.impuesto = redondeoMonedaLocal(comprobante.impuesto) * 1;


                        //Calculo de monto imponible del comprobante
                        comprobante.imponible = sumItemsImponibles(comprobante);

                        // Redondeo del calculo anterior
                        comprobante.imponible = redondeoMonedaLocal(comprobante.imponible) * 1;

                        if(comprobante.iracis != undefined){
                            vm.totalGastos = vm.totalGastos + ((comprobante.montoTotal * comprobante.cambio) + comprobante.iracis);
                        }else{
                            vm.totalGastos = comprobante.tipo.clase.codigo != 'nota_credito' ? vm.totalGastos + (comprobante.montoTotal * comprobante.cambio) : vm.totalGastos - (comprobante.montoTotal * comprobante.cambio);
                        }

                        vm.totalGastosImponibles += (sumItemsImponibles(comprobante));
                        vm.totalGastosImpuestos += comprobante.impuesto;
                    });
                
                    vm.totalGastos = redondeoMonedaLocal(vm.totalGastos);
                });
            });
        });

    }

    function getMultiplicador(unidadMedida) {
        var actual = unidadMedida;
        var multiplicador = 1;
        while (!actual.esBase) {
            multiplicador = multiplicador * actual.cantidad;
            actual = actual.unidadContenida;
        }
        return multiplicador;
    };

    function groupRecepcionesByProductos() {
        vm.productosRecepcion = [];

        _.forEach(vm.recepciones, function(recepcion) {
            _.forEach(recepcion.recepcionDetalle, function(detalle) {
                if (!detalle.producto) {
                    return;
                }
                var val = _.find(vm.productos, function(producto) {
                    return producto.id == detalle.producto.id;
                });
                if (!val) {
                    val = detalle.producto;
                    vm.productosRecepcion.push(val);
                    val.cantidad = detalle.cantidadRecepcionada;
                    if (!val.cantidad) {
                        val.cantidad = detalle.unidadesRecepcionadas;
                    }
                    val.unidadMedida = _.find(val.unidadesMedidaProducto, function(ump) {
                        return ump.principal == true;
                    });
                }
            });
        });
    }

}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.importacion')
  .factory('AplicacionComprobanteFactory', AplicacionComprobanteFactory);

AplicacionComprobanteFactory.$inject = ['$resource', 'baseurl', '$http', 'ParametrosFactory', '$q'];

function AplicacionComprobanteFactory($resource, baseurl, $http, ParametrosFactory, $q) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var AplicacionComprobante = $resource( baseurl.getBaseUrl() + "/comprobanteaplicacion/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params) {
    return AplicacionComprobante.query(params);
  }

  function get(id, view) {
    return AplicacionComprobante.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new AplicacionComprobante(attrs);
  }

  function save(aplicacionComprobante) {
    return (aplicacionComprobante.id) ? aplicacionComprobante.$update() : aplicacionComprobante.$save();
  }

  function remove(aplicacionComprobante) {
    return aplicacionComprobante.$remove();
  }

}

'use strict';

/**
 * @ngdoc service
 * @name qualita.tipoComprobante
 * @description
 * # tipoComprobante
 * Factory in the qualita.
 */
angular.module('qualita.importacion')
  .factory('SolicitudCompraFactory', ['$resource', 'baseurl','filterFactory', 'TiposFactory', 'ImportacionFactory', '$http',
    function($resource,  baseurl, filterFactory, TiposFactory, ImportacionFactory, $http) {

  	var SolicitudCompra = $resource( baseurl.getBaseUrl() + '/solicitudescompra/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params) {
        return SolicitudCompra.query(params);
      },

      get: function(id, view) {
        return SolicitudCompra.get({id: id, view: view ? view : "base"});
      },

      create: function(attrs) {
        return new SolicitudCompra(attrs);
      },

      save: function(solicitudCompra) {
        return (solicitudCompra .id) ? solicitudCompra .$update() : solicitudCompra .$save();
      },

      remove: function(solicitudCompra ) {
        return solicitudCompra.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return forms[type];
      },

      aprobar: function (id, idAcceso){
        return $http({
                  url: baseurl.getBaseUrl() + '/solicitudescompra/aprobar/'+id,
                  method: "PUT",
                  params: { user :  idAcceso }
               });
      },

      rechazar: function (id, idAcceso){
        return $http({
                  url: baseurl.getBaseUrl() + '/solicitudescompra/rechazar/'+id,
                  method: "PUT",
                  params: { user :  idAcceso }
               });
      },

      cerrar: function (id){
        return $http({
                  url: baseurl.getBaseUrl() + '/solicitudescompra/cerrar/'+id,
                  method: "PUT"
               });
      }
    };
  }]);

'use strict';

angular.module('qualita.importacion')
    .controller('SolicitudesCompraListCtrl', SolicitudesCompraListCtrl);

SolicitudesCompraListCtrl.$inject = ["AuthorizationService", "SolicitudCompraFactory", "ImportacionLangFactory", "$modal",
    "$scope", "$state", "filterFactory", "WatchLocacionChangeFactory", "ReportTicketFactory", "$window", "$rootScope", "$filter",
    "UtilFactory", "CsvFactory", "baseurl"
];

function SolicitudesCompraListCtrl(AuthorizationService, SolicitudCompraFactory, ImportacionLangFactory, $modal,
    $scope, $state, filterFactory, WatchLocacionChangeFactory, ReportTicketFactory, $window, $rootScope, $filter,
    UtilFactory, CsvFactory, baseurl) {
    var vm = this;

    activate();

    function activate() {
        ImportacionLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;
            vm.canUpdate = AuthorizationService.hasPermission('update_solicitudescompra');
            // filtro estático
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'compraLocal',
                like: 'false',
            }]).value();

            var defaultColumnOrder = ['numero', 'fecha', 'descripcion', 'estado.descripcion' /*, 'usuario.username'*/ ];

            vm.options = {
                'staticFilter': staticFilter,
                'resource': 'solicitudescompra',
                'title': $filter('translate')('SOLICITUDES_COMPRA'),
                'factory': SolicitudCompraFactory,
                'view' : 'SolicitudCompraList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('SOLICITUD_COMPRA_DELETE_FAILED'),
                'columns': [
                    { 'data': 'numero', 'title': $filter('translate')('CODE'), 'visible': false  },
                    { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'type': 'date-range', 'class': 'dt-center' },
                    { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION') },
                    { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'class': 'dt-center', 'type' : 'combo', 'filterUrl': 'tipos/filtro/estados_solicitud_compra' },
                    //{'data': 'usuario.username', 'title': $filter('translate')('USER') }
                ],
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions': [{
                        'title': 'Nuevo',
                        'icon': 'glyphicon glyphicon-plus',
                        'showCondition': function() {
                            var permission = AuthorizationService.hasPermission('create_solicitudescompra');
                            return permission;
                        },
                        'action': function() {
                            $state.go("app.solicitud_compra_importacion.new");
                        }
                    },
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = createFilters(vm.options.getFilters());
                            ReportTicketFactory.ticket('listadoSolicitudCompra', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open($scope.pdfDownloadURL, '_blank');
                            });
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                            CsvFactory.csv("solicitudescompra", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                                .then(function(response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraRowOptions': [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canEditar",
                        conditionDef: function(estado) {
                            return (vm.canUpdate && estado.codigo == "sc_ingresada");
                        },
                        functionName: "editar",
                        functionDef: function(itemId) {
                            $state.go("app.solicitud_compra_importacion.edit", { id: itemId });
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canBorrar",
                        conditionDef: function(estado) {
                            return (AuthorizationService.hasPermission('delete_solicitudescompra')
                                && (estado.codigo == "sc_ingresada"));
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('DELETE_WARNING');

                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceBorrar1.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                var model = SolicitudCompraFactory.create({ id: itemId });
                                SolicitudCompraFactory.remove(model).then(function() {
                                    $scope.modalInstanceBorrar1.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                    $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                                    $scope.mensajeModal = error.data.message;
                                    var modalInstance = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };
                                    console.log("error al borrar: ");
                                    console.log(error);
                                });
                            };
                        }
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('APROBAR') + "' class='btn btn-primary btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataId%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ok'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canAprobar",
                        conditionDef: function(estado) {
                            return (vm.canUpdate && estado.codigo == "sc_ingresada");
                        },
                        functionName: "aprobar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('APROBAR_SC');
                            $scope.mensajeModal = $filter('translate')('APROBAR_SC_MSG');
                            var modalInstance = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                SolicitudCompraFactory.aprobar(itemId, $rootScope.AuthParams.accesoSistema.id).then(function() {
                                    modalInstance.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    console.log("error al aprobar ");
                                    console.log(error);
                                });
                            };
                        }
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('RECHAZAR') + "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='rechazar(<%=dataId%>)' ng-class='{ hidden : !canRechazar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canRechazar",
                        conditionDef: function(estado) {
                            return (vm.canUpdate && estado.codigo == "sc_ingresada");
                        },
                        functionName: "rechazar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('RECHAZAR_SC');
                            $scope.mensajeModal = $filter('translate')('RECHAZAR_SC_MSG');
                            var modalInstance = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                SolicitudCompraFactory.rechazar(itemId, $rootScope.AuthParams.accesoSistema.id).then(function() {
                                    modalInstance.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    console.log("error al rechazar ");
                                    console.log(error);
                                });
                            };
                        }
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('CERRAR_SC') + "' class='btn btn-default btn-dt' style='margin-right: 5px;' ng-click='cerrar(<%=dataId%>)' ng-class='{ hidden : !canCerrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-check'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canCerrar",
                        conditionDef: function(estado) {
                            return (vm.canUpdate &&  estado.codigo == "sc_aprobada");
                        },
                        functionName: "cerrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('CERRAR_SC_Q');
                            $scope.mensajeModal = $filter('translate')('CERRAR_SC_MSG');
                            var modalInstance = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                SolicitudCompraFactory.cerrar(itemId).then(function() {
                                    modalInstance.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    console.log("error al cerrar ");
                                    console.log(error);
                                });
                            };
                        }
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('IMPRIMIR_SC') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='emitir(<%=dataId%>)' ng-class='{ hidden : !canEmitir(<%=dataId%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
                        functionName: "emitir",
                        conditionName: "canEmitir",
                        conditionDef: function(estado) {
                            return AuthorizationService.hasPermission('update_solicitudescompra');
                        },
                        functionDef: function(itemId) {
                            $scope.tituloModal = $filter('translate')('IMPRIMIR_SC');
                            $scope.mensajeModal = $filter('translate')('IMPRIMIR_SC_MSG');
                            var modalInstance = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function(id) {
                                var filtersArr = [];
                                filtersArr.push("idsolcompra=" + itemId);
                                console.log("filtersArr: ", filtersArr);
                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('solicitudcompra', 'pdf', filtersArr);
                                $window.open(vm.pdfDownloadURL, '_blank');
                                $state.go($state.current, {}, { reload: true });
                                modalInstance.close();
                            };
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title=' " + $filter('translate')('VIEW') + " ' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function(itemId) {
                            $state.go("app.solicitud_compra_importacion.view", { id: itemId });
                        },
                        conditionName: "canVer",
                        conditionDef: function() {
                            return (AuthorizationService.hasPermission('index_solicitudescompra'));
                        },
                    }
                ],
            };
            WatchLocacionChangeFactory.watchWith(undefined, vm.options, "locacion.id");
        });
    }

    
    var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            search = search.split("~");
            if (search.length > 1) {
                /* El parámetro es un rago */

                var isDate = search[0].split("/");

                if (isDate.length > 1) {
                    /* Es un rago de fechas */

                    filtersArr.push({path: data, equalOrAfter: search[0]})
                    filtersArr.push({path: data, equalOrBefore: search[1]})
                } else {
                    /* El un rago numérco */

                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                    filtersArr.push({path: data, lessOrEqual: search[1]})
                }
            } else {
                /* El parametro no es un rago */
                filtersArr.push({path: data, like: search[0]})
            }
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }


}
'use strict';

angular.module('qualita.importacion')
  .controller('SolicitudesCompraFormCtrl', SolicitudesCompraFormCtrl);

SolicitudesCompraFormCtrl.$inject = ["UsuariosFactory", "SolicitudCompraFactory", "solicitudCompraPrepService", "OrdenesCompraFactory",
  "AccesosSistemaFactory", "ImportacionLangFactory", "filterFactory", "$log", "$modal", "$scope", "notify",
  "$state", "$rootScope", "$timeout", "ModelTrimmer", "UtilFactory", "$filter"];

function SolicitudesCompraFormCtrl(UsuariosFactory, SolicitudCompraFactory, solicitudCompraPrepService, OrdenesCompraFactory,
  AccesosSistemaFactory, ImportacionLangFactory, filterFactory, $log, $modal, $scope, notify, $state, $rootScope, $timeout, ModelTrimmer, 
  UtilFactory, $filter) {
  var vm = this;

  vm.submit = submit;
  vm.cancel = cancel;

  activate();

  $rootScope.isProcessing = false;

  function activate() {
    ImportacionLangFactory.getTranslations().then(function(translations) {
      vm.translations = translations;
      vm.curDate = new Date();
      vm.usuarios = [];
      vm.accesos = [];
      AccesosSistemaFactory.all(null, "SolicitudCompraForm").$promise.then(function(response) {
        _.forEach(response, function(acceso){
          if(acceso.usuario.activo){
            vm.usuarios.push(acceso.usuario);
            vm.accesos.push(acceso);
          }
        });
      });
      vm.usuariosNotificables = [];
      if($state.is("app.solicitud_compra_importacion.new")) {
        activateNew();
      } else if($state.is("app.solicitud_compra_importacion.edit")) {
        activateEdit();
      } else if($state.is("app.solicitud_compra_importacion.view")) {
        activateView();
      }
    });
  }

  function addUsuarioParam(solicitud) {
    $rootScope.$watch('AuthParams.accesoSistema', function(newValue, oldValue) {
      if(newValue != undefined) {
        var usuario = $rootScope.AuthParams.accesoSistema.usuario;
        if(!usuario.id) {
         usuario = UsuariosFactory.get($rootScope.AuthParams.accesoSistema.usuario);
        }
        vm.solicitud.usuario = usuario;
      }
    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NEW_SOLICITUD_COMPRA');
    vm.solicitud = { compraLocal : false, fecha: new Date() };
    addUsuarioParam(vm.solicitud);
    $timeout(function(){
      $scope.$broadcast('newItemAdded');
    }, 20);
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_SOLICITUD_COMPRA');
    vm.solicitud = solicitudCompraPrepService;
    //addUsuarioParam(vm.solicitud);
    vm.solicitud.fecha = new Date(vm.solicitud.fecha);
    _.forEach(vm.solicitud.usuariosNotificables, function(acceso) {
      vm.usuariosNotificables.push(acceso.usuario);
    });
    vm.entity = "SolicitudCompra";
    vm.entityId = vm.solicitud.id;
    $timeout(function(){
      $scope.$broadcast('newItemAdded');
    }, 20);
    vm.isEdit = true;
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_SOLICITUD_COMPRA');
    vm.solicitud = solicitudCompraPrepService;
    vm.solicitud.fecha = new Date(vm.solicitud.fecha);
    //addUsuarioParam(vm.solicitud);
    _.forEach(vm.solicitud.usuariosNotificables, function(acceso) {
      vm.usuariosNotificables.push(acceso.usuario);
    });
    vm.entity = "SolicitudCompra";
    vm.entityId = vm.solicitud.id;
    vm.view = true;
  }

  function submit() {
    vm.submited = true;
    if($scope.SolicitudCompraForm.$valid) {
      vm.solicitud.usuariosNotificables = UtilFactory.getAccesosFromUsuarios(vm.accesos, vm.usuariosNotificables);
      $rootScope.isProcessing = true;
      submitSolicitudCompra().then(function(response) {
        $state.go('app.solicitud_compra_importacion.list');
        return response;
      }, function(error) {
        $rootScope.isProcessing = false;
        var msg = "Error al guardar la Solicitud";
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
      });
    }
  }

  function submitSolicitudCompra() {
    //var trimmedModel = ModelTrimmer.trimDetails(vm.solicitud);
    var resource = SolicitudCompraFactory.create(vm.solicitud);
    if(!vm.isEdit) {
      resource.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
    }
    return SolicitudCompraFactory.save(resource);
  }

  function cancel() {
    $state.go('app.solicitud_compra_importacion.list');
  }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.importacion')
  .factory('OrdenesCompraDetalleFactory', OrdenesCompraDetalleFactory);

OrdenesCompraDetalleFactory.$inject = ['$resource', 'baseurl', '$http', 'notify', '$state', '$stateParams'];

function OrdenesCompraDetalleFactory($resource, baseurl, $http, notify, $state, $stateParams) {
  var service = {
    all: all,
    get: get,
    getUltimosCosto:getUltimosCosto,
    getUltimosCosto2:getUltimosCosto2
  };

  var OrdenesCompraDetalle = $resource( baseurl.getBaseUrl() + "/ordencompradetalles/:id", {id: '@id'});

  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'base';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'base';
    }
    return OrdenesCompraDetalle.query(params);
  }

  function get(id, view) {
    return OrdenesCompraDetalle.get({id: id, view: view ? view : "base"});
  }

  function getUltimosCosto(id){
    return $http({
        method : "GET",
        url : baseurl.getBaseUrl()+"/historicocosto/ultimosprecio/"+id
    });
  }

  function getUltimosCosto2(id, moneda){
    return $http({
        method : "GET",
        url : baseurl.getBaseUrl()+"/historicocosto/ultimospreciomoneda/"+id,
        params: { "codigoMoneda": moneda }
    });
  }

}
/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.importacion')
  .factory('ComprobanteDetalleFactory', ComprobanteDetalleFactory);

ComprobanteDetalleFactory.$inject = ['$resource', 'baseurl', '$http', 'ParametrosFactory', '$q'];

function ComprobanteDetalleFactory($resource, baseurl, $http, ParametrosFactory, $q) {
  var service = {
    all: all,
    get: get
  };

  var ComprobanteDetalle = $resource( baseurl.getBaseUrl() + "/comprobante_detalle/:id", {id: '@id'});

  return service;

  function all(params, view) {
    params = params ? params : [];
    params.view = view != undefined ? view : "base";
    return ComprobanteDetalle.query(params);
  }

  function get(id, view) {
    return ComprobanteDetalle.get({id: id, view: view ? view : "base"});
  }
}

'use strict';

angular.module('qualita.importacion')
  .controller('ConsultaDespachoIndexCtrl', ["$scope", "DespachoFactory", "filterFactory", "ReportTicketFactory", "$window", "ImportacionLangFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter", "$state", function ($scope, DespachoFactory, filterFactory,
    ReportTicketFactory, $window, ImportacionLangFactory, CsvFactory, UtilFactory, baseurl, $filter, $state) {

    var defaultColumnOrder = ['id', 'numero', 'despachante.nombre', 'fecha', 'condicionImportacion.descripcion', 'regimenAduana.descripcion',
    'valorFactura', 'valorFlete', 'totalComprobantes', 'totalLiquidacion', 'importacion.codigo', 'moneda.descripcion', 'valorSeguro'];

    var renderEmptyData = function (data) {
      if (data == undefined)
        return "";
      else
        return data;
    };

    var monedaLocalRender = function(data, type, row) {

        // Se asume que la moneda a mostrar sera siempre guaranies
        var moneda = "Gs. ";
        data = parseFloat(data).toFixed(0);

        return (
            moneda +
            data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        );
    }

    var monedaRender = function(data, type, row) {

        if (row.moneda) {
            var moneda = "Gs. ";
            if (row.moneda.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }else{
                data = parseFloat(data).toFixed(0);
            }
            return (
                moneda +
                data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else return data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    ImportacionLangFactory.getTranslations().then(function(translations){
      $scope.options = {
        'resource': 'despacho',
        'title': $filter('translate')('DESPACHOS'),
        'factory': DespachoFactory,
        'view': 'DespachoList',
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          {'data': 'id', 'title': $filter('translate')('ID'), visible: false,'render': renderEmptyData },
          {'data': 'numero', 'title': $filter('translate')('NUMBER'), 'render': renderEmptyData},
          {'data': 'importacion.codigo', 'title': $filter('translate')('CODIGO_IMPORTACION'), 'render': renderEmptyData},
          {'data': 'despachante.nombre', 'title': $filter('translate')('DESPACHANTE'), 'render': renderEmptyData},
          {'data': 'condicionImportacion.descripcion', 'title': $filter('translate')('CONDICION_IMPORTACION'), 'render': renderEmptyData, visible:false},
          {'data': 'regimenAduana.descripcion', 'title': $filter('translate')('REGIMEN_ADUANA'), 'render': renderEmptyData},
          {'data': 'moneda.descripcion', 'title': $filter('translate')('MONEDA'), 'render': renderEmptyData},
          {'data': 'valorFactura', 'title': $filter('translate')('VALOR_FACTURA_ME'),'render': monedaRender, class:"dt-right"},
          {'data': 'valorFlete', 'title': $filter('translate')('VALOR_FLETE_ML'), 'render': monedaLocalRender, visible:false, class:"dt-right"},
          {'data': 'valorSeguro', 'title': $filter('translate')('VALOR_SEGURO_ML'), 'render': monedaLocalRender, visible:false, class:"dt-right"},
          {'data': 'fecha', 'title': $filter('translate')('DATE'), renderWith: "dateRender", dateFormat: "DD/MM/YYYY", class:"dt-center", 'type': 'date-range'},
          {'data': 'totalComprobantes', 'title': $filter('translate')('TOTAL_COMPROBANTES'),'render': monedaLocalRender, class:"dt-right"},
          {'data': 'totalLiquidacion', 'title': $filter('translate')('TOTAL_LIQUIDACION'),'render': monedaLocalRender, class:"dt-right"}

          //,{'data': 'activo', 'title': $filter('translate')('ACTIVE'),'render': renderEmptyData, 'class':'dt-center', 'type' : 'combo', 'filterUrl': 'tipos/filtro/boolean_values'}
        ],
        'hasOptions':true,
        'hideAddMenu': true,
        'hideEditMenu': true,
        'hideViewMenu': true,
        'hideRemoveMenu': true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraRowOptions':
          [{
              templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" +
                  $filter("translate")("VIEW") +
                  "' ng-click='view(<%=dataId%>)' ng-class='{ hidden : !canView(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
              functionName: "view",
              functionDef: function(itemId) {

                  if ($state.is("app.consultadespacho.list")) {
                    $state.go("app.consultadespacho.view", { id: itemId });
                  }

              },
              conditionName: "canView",
              //customAttribute: ["estado", "compra", "importacion"],
              conditionDef: function(atributos) {
                  return true
              }
          }],
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoDespachos', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("despacho", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ]
      };
    });
    
    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
          search = search.split("~");
          if (search.length > 1) {
              /* El parámetro es un rago */

              var isDate = search[0].split("/");

              if (isDate.length > 1) {
                  /* Es un rago de fechas */

                  filtersArr.push({path: data, equalOrAfter: search[0]})
                  filtersArr.push({path: data, equalOrBefore: search[1]})
              } else {
                  /* El un rago numérco */

                  filtersArr.push({path: data, greaterOrEqual: search[0]})
                  filtersArr.push({path: data, lessOrEqual: search[1]})
              }
          } else {
              /* El parametro no es un rago */
              filtersArr.push({path: data, like: search[0]})
          }
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }
  }]);

'use strict';

angular.module('qualita.importacion')
    .controller('ConsultaDespachoFormCtrl', ConsultaDespachoFormCtrl);

ConsultaDespachoFormCtrl.$inject = ["AuthorizationService", "ImportacionFactory", "DespachoFactory",
    "filterFactory", "CondicionImportacionFactory", "ComprobanteFactory", "$log", "$modal",
    "$scope", "notify", "$state", "$rootScope", "$timeout", "ImportacionLangFactory", 
    "UtilFactory", "$filter", "consultaDespachoPrepService"
];

function ConsultaDespachoFormCtrl(AuthorizationService, ImportacionFactory, DespachoFactory,
    filterFactory, CondicionImportacionFactory, ComprobanteFactory, $log, $modal,
    $scope, notify, $state, $rootScope, $timeout, ImportacionLangFactory, UtilFactory,
    $filter, consultaDespachoPrepService) {
    var vm = this;

    vm.cancel = cancel;
    vm.curDate = new Date();
    
    activateView();

    $rootScope.isProcessing = false;
    vm.totalGeneralImpuestos = 0;

    function activateView() {
        vm.title = $filter('translate')('DESPACHOS');
        vm.despacho = consultaDespachoPrepService;
        vm.entidadId = vm.despacho.id;
        //vm.entidad = "Despacho";
        vm.despacho.fecha = new Date(vm.despacho.fecha);
        vm.view = true;

        var staticFilterImportacion = {};
        staticFilterImportacion.search = filterFactory.and([{
            path: 'importacion.codigo',
            equals: vm.despacho.importacion.codigo
        }]).value();
        
        vm.comprobantes = ComprobanteFactory.all(staticFilterImportacion, "ComprobanteDespachoList");
    }

    function cancel() {
        $state.go('app.consultadespacho.list');
    }

}
'use strict';

angular.module('qualita.importacion')
  .controller('ConsultaProformaIndexCtrl', ["$scope", "ProformaFactory", "filterFactory", "ReportTicketFactory", "$window", "ImportacionLangFactory", "CsvFactory", "UtilFactory", "baseurl", "$filter", "$state", function ($scope, ProformaFactory, filterFactory,
    ReportTicketFactory, $window, ImportacionLangFactory, CsvFactory, UtilFactory, baseurl, $filter, $state) {

    var defaultColumnOrder = ['id', 'fecha', 'montoTotal','numero','ordenCompra.numero', 'proveedorOC.nombre', 'tipoCostoDespacho.descripcion',
    'entregaInicial', 'importacion.codigo'];

    var renderEmptyData = function (data) {
      if (data == undefined)
        return "";
      else
        return data;
    };

    var monedaRender = function(data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.monedaOC.codigo === 'dolares') {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.monedaOC.codigo === 'pesos') {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.monedaOC.codigo === 'real') {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.monedaOC.codigo === 'euro') {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }
            return moneda + data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }else {
            return '';
        }
    };

    ImportacionLangFactory.getTranslations().then(function(translations){
      $scope.options = {
        'resource': 'proforma',
        'title': 'Proformas',
        'factory': ProformaFactory,
        'view': 'ConsultaProformaList',
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-left'  },
          { 'data': 'ordenCompra.numero', 'title': $filter('translate')('ORDEN_COMPRA'), 'class': 'dt-right'  },
          { 'data': 'importacion.codigo', 'title': $filter('translate')('CODIGO_IMPORTACION'), 'class': 'dt-left'  },
          { 'data': 'proveedorOC.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX'), 'class': 'dt-left' },
          { 'data': 'monedaOC.descripcion', 'title': 'Moneda', 'class': 'dt-center', visible: false },
          { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
          { 'data': 'puertoLlegada', 'title': $filter('translate')('PUERTO_LLEGADA'), 'class': 'dt-center', visible: false },
          { 'data': 'tipoCostoDespacho.descripcion', 'title': $filter('translate')('TIPO_COSTO_DESPACHO'), 'class': 'dt-left'},
          { 'data': 'montoTotal', 'title': $filter('translate')('TOTAL_AMOUNT'), 'render': monedaRender, 'class': 'dt-right'  },
          { 'data': 'cantidadPagos', 'title': $filter('translate')('CANTIDAD_CUOTAS'), 'class': 'dt-center', visible: false  },
          { 'data': 'periodoPagos', 'title': $filter('translate')('PLAZO_CUOTAS'), 'class': 'dt-center', visible: false  },
          { 'data': 'entregaInicial', 'title': $filter('translate')('MONTO_INICIAL'), 'class': 'dt-right', 'render': monedaRender  }
        ],
        'hasOptions':true,
        'hideAddMenu': true,
        'hideEditMenu': true,
        'hideViewMenu': true,
        'hideRemoveMenu': true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraRowOptions':
          [{
              templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" +
                  $filter("translate")("VIEW") +
                  "' ng-click='view(<%=dataId%>)' ng-class='{ hidden : !canView(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
              functionName: "view",
              functionDef: function(itemId) {

                  if ($state.is("app.consultaproforma.list")) {
                    $state.go("app.consultaproforma.view", { id: itemId });
                  }

              },
              conditionName: "canView",
              customAttribute: ["estado", "compra", "importacion"],
              conditionDef: function(atributos) {
                  return true
              }
          }],
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoConsultaProforma', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("proforma", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ]
      };
    });
    
    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
          search = search.split("~");
          if (search.length > 1) {
              /* El parámetro es un rago */

              var isDate = search[0].split("/");

              if (isDate.length > 1) {
                  /* Es un rago de fechas */

                  filtersArr.push({path: data, equalOrAfter: search[0]})
                  filtersArr.push({path: data, equalOrBefore: search[1]})
              } else {
                  /* El un rago numérco */

                  filtersArr.push({path: data, greaterOrEqual: search[0]})
                  filtersArr.push({path: data, lessOrEqual: search[1]})
              }
          } else {
              /* El parametro no es un rago */
              filtersArr.push({path: data, like: search[0]})
          }
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
  }
  }]);

'use strict';

angular.module('qualita.importacion')
    .controller('ConsultaProformaFormCtrl', ConsultaProformaFormCtrl);

ConsultaProformaFormCtrl.$inject = ["ProformaFactory", "proformaPrepService", "ProductosFactory", "ImportacionFactory",
    "filterFactory", "TiposFactory", "ServicioFactory", "ParametrosFactory", "$scope", "notify", "$state", "$rootScope", "$timeout",
    "ImportacionLangFactory", "WatchLocacionChangeFactory", "UtilFactory", "$filter", "CondicionImportacionFactory", "OrdenesCompraFactory"
];

function ConsultaProformaFormCtrl(ProformaFactory, proformaPrepService, ProductosFactory, ImportacionFactory,
    filterFactory, TiposFactory, ServicioFactory, ParametrosFactory, $scope, notify, $state, $rootScope, $timeout,
    ImportacionLangFactory, WatchLocacionChangeFactory, UtilFactory, $filter, CondicionImportacionFactory, OrdenesCompraFactory) {

    var vm = this;

    vm.agregarDetalle = agregarDetalle;
    vm.calcularImpuesto = calcularImpuesto;
    vm.calcularTotalDetalle = calcularTotalDetalle;
    vm.removeItemFromArray = removeItemFromArray;
    vm.searchProductoFilter = searchProductoFilter;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.changeProducto = changeProducto;
    vm.changeServicio = changeServicio;
    vm.changeOC = changeOC;
    vm.tipoDetalleSelected = tipoDetalleSelected;
    vm.mostrarTodosProductos = mostrarTodosProductos;
    vm.totalItemChanged = totalItemChanged;

    vm.ordenCompra = {};
    activate();

    $rootScope.isProcessing = false;

    function activate() {
        WatchLocacionChangeFactory.killAllWatchers();
        ImportacionLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;

            vm.curDate = new Date();

            var filtroServicios = {};
            filtroServicios.search = filterFactory.and([{
                path: 'compra',
                equals: true
            }]).value();

            var staticFilterOC = {};
            staticFilterOC.search = filterFactory.and([{
                path: 'importaciones.codigo',
                equals: ImportacionFactory.getCodigoImportacion(),
            }]).value();



            vm.factoryProductos = ProductosFactory;
            var renderEmptyData = function(data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            var defaultColumnOrder = ['id', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
                'lineaProducto.descripcion', 'marca.descripcion'
            ];

            activateView();
            changeOC();

            vm.opcionesProductosDt = {};

            //vm.tiposDetalles = [{ id: 1, nombre: "Producto" }, { id: 2, nombre: "Servicio" }];

            /*getMonedaLocal();
            getParamDiferenciaMonedaLocal();
            getParamDiferenciaMonedaExtranjera();*/
        });
    }

    function activateView() {
        vm.title = $filter('translate')('VIEW_PROFORMA');
        vm.proforma = proformaPrepService;
        vm.entidadId = vm.proforma.id;
        vm.entidad = "Proforma";
        vm.proforma.fecha = new Date(vm.proforma.fecha);
        vm.view = true;
        setTiposEntidades();
        vm.totalGeneral = _.reduce(vm.proforma.proformaDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);
    }

    function getMonedaLocal() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'moneda_local'
        }).value();
        vm.monedaLocal = ParametrosFactory.all(params);
    }

    function getParamDiferenciaMonedaLocal() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'maximo_diferencia_cabecera_detalle_moneda_local'
        }).value();
        vm.diferenciaMonedaLocal = ParametrosFactory.all(params);
    }

    function getParamDiferenciaMonedaExtranjera() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'maximo_diferencia_cabecera_detalle_moneda_extranjera'
        }).value();
        vm.diferenciaMonedaExtranjera = ParametrosFactory.all(params);
    }

    function changeOC() {
        OrdenesCompraFactory.get(vm.proforma.ordenCompra.id, "OrdenCompraProformaForm").$promise.then(function(response) {
            vm.proforma.ordenCompra = response;
            vm.proforma.proveedorOC = _.cloneDeep(vm.proforma.ordenCompra.proveedor);
            vm.proforma.monedaOC = _.cloneDeep(vm.proforma.ordenCompra.moneda);
            vm.proforma.proformaDetalle = _.cloneDeep(vm.proforma.ordenCompra.ordenCompraDetalle);
            _.forEach(vm.proforma.proformaDetalle, function(value) {
                delete value.ordenCompra;
                delete value.id;
                delete value.totalGravado5;
                delete value.totalGravado10;
                delete value.totalDescuento;
                delete value.porcentajeDescuento;
                //delete value.servicio;
                if (value.producto) {
                    value.tipo = { id: 1, nombre: "Producto" };
                }
                if (value.servicio) {
                    value.tipo = { id: 2, nombre: "Servicio" };
                }
                _.forEach(value.montosImponibles, function(data) {
                    delete data.id;
                    delete data.ordenCompraDetalle;
                    delete data.proformaDetalle;
                });
            });

            //vm.selectProducto = true;
            filterProductos();
            vm.totalGeneral = _.reduce(vm.proforma.proformaDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);

            if(vm.proforma.ordenCompra.moneda.codigo == 'guaranies'){
                vm.totalGeneral = vm.totalGeneral.toFixed(0);
            }else{
                vm.totalGeneral = vm.totalGeneral.toFixed(2);
            }
        });
    }

    function setTiposEntidades() {
        if (!vm.proforma || !vm.proforma.proformaDetalle) {
            return;
        }
        _.forEach(vm.proforma.proformaDetalle, function(detalle) {
            if (detalle.producto) {
                detalle.tipo = { id: 1, nombre: "Producto" };
            }
            if (detalle.servicio) {
                detalle.tipo = { id: 2, nombre: "Servicio" };
            }
        });
    }

    function mostrarTodosProductos() {
        if (vm.todosProductos) {
            if (vm.watcher) { vm.watcher(); }
            WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
                vm.watcher = watcher;
                vm.productos = ProductosFactory.allForSelect(locacionFilter);
                vm.opcionesProductosDt.staticFilter = locacionFilter;
            }, "locaciones.id");
        } else {
            filterProductos();
        }
    }

    function tipoDetalleSelected(detalle) {
        delete detalle.producto;
        delete detalle.servicio;
        delete detalle.unidadMedida;
    }

    function filterProductos() {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
                path: 'proveedores.codigo',
                equals: vm.proforma.proveedorOC.codigo
            },
            {
                path: 'itemCompra',
                like: 'true'
            }
        ]).value();

        if (vm.watcher) { vm.watcher(); }
        WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
            vm.watcher = watcher;
            vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter));
            vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter);
        }, "locaciones.id");
    }

    function agregarDetalle() {
        vm.proforma.proformaDetalle.push({ montosImponibles: [{}] });
        $timeout(function() {
            $scope.$broadcast('newItemAdded');
        }, 20);
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function(item) {
            return item === elemento;
        });
        vm.totalGeneral = _.reduce(vm.proforma.proformaDetalle, function(sum, detalle) { return sum + detalle.totalItem; }, 0);
    }

    function calcularImpuesto(detalle, accordingTo) {
        if (accordingTo === "valor") {
            detalle.totalItemExento = detalle.totalItem - detalle.montosImponibles[0].valor;
        } else if (accordingTo === "exento") {
            detalle.montosImponibles[0].valor = detalle.totalItem - detalle.totalItemExento;
        }
    }

    function updateTotalGeneral() {
        vm.totalGeneral = _.reduce(vm.proforma.proformaDetalle, function(sum, detalle) {

            return sum + detalle.totalItem;
        }, 0);
        vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(2));
    }

    function updateImpuestoDetalle(detalle) {
        if (detalle.montosImponibles && detalle.montosImponibles[0]) {
            detalle.montosImponibles[0].valor = 0;
        }
        detalle.totalItemExento = detalle.cantidad * detalle.ultimoPrecio;
    }

    function calcularTotalDetalle(detalle) {
        if (detalle.cantidad == undefined) {
            detalle.totalItem = 0;
            return;
        }
        if (detalle.ultimoPrecio == undefined) {
            detalle.totalItem = 0;
            return;
        }

        detalle.totalItem = detalle.cantidad * detalle.ultimoPrecio;
        detalle.totalItem = parseFloat(detalle.totalItem.toFixed(2));
        updateImpuestoDetalle(detalle);
        updateTotalGeneral();
    }

    function totalItemChanged(detalle) {
        if (detalle.cantidad == undefined) {
            detalle.cantidad = 1;
        }

        detalle.ultimoPrecio = detalle.totalItem / detalle.cantidad;
        detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(5));
        updateImpuestoDetalle(detalle);
        updateTotalGeneral();
    }

    function changeProducto(detalle) {
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        detalle.cantidad = 0;
        detalle.ultimoPrecio = 0;
        detalle.totalItem = 0;
    }

    function changeServicio(detalle) {
        detalle.cantidad = 0;
        //detalle.totalDescuento = 0;
        //detalle.porcentajeDescuento = 0;
        detalle.ultimoPrecio = 0;
        detalle.totalItemExento = 0;
        detalle.totalItem = 0;
    }

    function searchProductoFilter(criteria) {
        return function(item) {
            if (!criteria) {
                return true;
            }

            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
        };
    }

    function submit() {
        vm.submited = true;
        if ($scope.ProformaForm.$valid) {
            if (validarMontos()) {
                $rootScope.isProcessing = true;
                vm.proforma.proformaDetalle = _.filter(vm.proforma.proformaDetalle, function(elem) { return elem.producto || elem.servicio; });
                submitProforma().then(function(response) {
                    ImportacionFactory.setIdImportacion(response.importacion.id);
                    ImportacionFactory.setCodigoImportacion(response.importacion.codigo);
                    ImportacionFactory.setImportacion(response.importacion);
                    $scope.$emit("updateImportacionStates");
                    $state.go('app.importaciones.proceso.proformas.list', { codigoImportacion: ImportacionFactory.getIdImportacion() });
                }, function(error) {
                    $rootScope.isProcessing = false;
                    var msg = $filter('translate')('ERROR_SAVING');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                });
            } else {
                notify({ message: $filter('translate')('MONTO_TOTAL_ERROR'), classes: 'alert-danger', position: 'right' });
            }
        }
    }

    function validarMontos() {
        var diferenciaPermitida = 0;
        if (vm.proforma.monedaOC.id == vm.monedaLocal[0].valorTipo.id) {
            diferenciaPermitida = vm.diferenciaMonedaLocal[0].valor;
        } else {
            diferenciaPermitida = vm.diferenciaMonedaExtranjera[0].valor;
        }
        var sumaTotal = 0;
        $.each(vm.proforma.proformaDetalle, function() {
            sumaTotal += this.totalItem;
        });
        var diff = Math.abs(sumaTotal - vm.proforma.montoTotal);
        if (vm.proforma.montoTotal === undefined || diff > diferenciaPermitida) {
            return false;
        }
        return true;
    }

    function submitProforma() {
        var resource = ProformaFactory.create(vm.proforma);
        _.forEach(resource.proformaDetalle, function(detalle) {
            delete detalle.tipo;
        });
        if (!vm.isEdit) {
            resource.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        }
        return ProformaFactory.save(resource);
    }

    function cancel() {
        $state.go('app.consultaproforma.list');
    }
}
/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.bobinas', ['ngResource', 'ui.tree', 'ngFileUpload', 'qualita.importacion', 'qualita.productos'])
  .config(
    ['$stateProvider',
      function ($stateProvider) {

        $stateProvider
          .state('app.clasificacionbobinas', {
            url: '/clasificacion_bobinas',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Clasificación de Bobinas'
            }
          })
          .state('app.clasificacionbobinas.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'RecepcionBobinasListCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.clasificacionbobinas',
              label: 'Recepciones de Bobinas',
              description: 'Listado de Recepciones de Bobinas',
              skip: true
            }
          })
          .state('app.clasificacionbobinas.new', {
            url: '/:id/clasificar',
            templateUrl: 'qualita-components/bobinas/views/clasificacion/form.html',
            controller: 'ClasificacionBobinasCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.clasificacionbobinas',
              label: 'Clasificar Bobina',
              description: 'Clasificar Bobina'
            },
            resolve: {
              detalleRecepcionPrepService: detalleRecepcionPrepService
            }
          })
          .state('app.clasificacionbobinas.view', {
            url: '/:id',
            templateUrl: 'qualita-components/bobinas/views/clasificacion/form.html',
            controller: 'ClasificacionBobinasCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.clasificacionbobinas',
              label: 'Ver Clasificación de Bobina',
              description: 'Ver Clasificación de Bobina'
            },
            resolve: {
              detalleRecepcionPrepService: detalleRecepcionPrepService
            }
          })
          .state('app.fraccionamientobobinas', {
            url: '/fraccionamiento_bobinas',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Fraccionamiento de Bobinas'
            }
          })
          .state('app.fraccionamientobobinas.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'FraccionamientoBobinasListCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.fraccionamientobobinas',
              label: 'Fraccionamiento de Bobinas',
              description: 'Listado de Bobinas Clasificadas'
            }
          })
          .state('app.fraccionamientobobinas.new', {
            url: '/new',
            templateUrl: 'qualita-components/bobinas/views/fraccionamiento/form.html',
            controller: 'FraccionamientoBobinasFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.fraccionamientobobinas',
              label: 'Nuevo Fraccionamiento de Bobina',
              description: 'Nuevo Fraccionamiento de Bobina'
            },
            resolve: {
              fraccionamientoPrepService: function () { return; }
            }
          })
          .state('app.fraccionamientobobinas.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/bobinas/views/fraccionamiento/form.html',
            controller: 'FraccionamientoBobinasFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.fraccionamientobobinas',
              label: 'Editar Fraccionar de Bobina',
              description: 'Editar Fraccionamiento de Bobina'
            },
            resolve: {
              fraccionamientoPrepService: fraccionamientoPrepService
            }
          })
          .state('app.fraccionamientobobinas.view', {
            url: '/:id',
            templateUrl: 'qualita-components/bobinas/views/fraccionamiento/form.html',
            controller: 'FraccionamientoBobinasFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.fraccionamientobobinas',
              label: 'Ver Fraccionamiento de Bobina',
              description: 'Ver fraccionamiento de Bobina'
            },
            resolve: {
              fraccionamientoPrepService: fraccionamientoPrepService
            }
          })
          .state('app.maquinaria', {
            url: '/maquinarias',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Maquinarias'
            }
          })
          .state('app.maquinaria.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'MaquinariaListCtrl',
            ncyBreadcrumb: {
              parent: 'app.maquinaria',
              label: 'Maquinarias',
              description: 'Listado de Maquinarias'
            }
          })
          .state('app.maquinaria.new', {
            url: '/new',
            templateUrl: 'qualita-components/bobinas/views/maquinaria/form.html',
            controller: 'MaquinariaFormCtrl',
            ncyBreadcrumb: {
              parent: 'app.maquinaria',
              label: 'Nueva Maquinaria',
              description: 'Nueva Maquinaria'
            },
            resolve: {
              maquinariaPrepService: function () { return; }
            }
          })
          .state('app.maquinaria.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/bobinas/views/maquinaria/form.html',
            controller: 'MaquinariaFormCtrl',
            ncyBreadcrumb: {
              parent: 'app.maquinaria',
              label: 'Editar Maquinaria',
              description: 'Editar Maquinaria'
            },
            resolve: {
              maquinariaPrepService: maquinariaPrepService
            }
          })
          .state('app.maquinaria.view', {
            url: '/:id',
            templateUrl: 'qualita-components/bobinas/views/maquinaria/form.html',
            controller: 'MaquinariaFormCtrl',
            ncyBreadcrumb: {
              parent: 'app.maquinaria',
              label: 'Ver Maquinaria',
              description: 'Ver Maquinaria'
            },
            resolve: {
              maquinariaPrepService: maquinariaPrepService
            }
          })
          .state('app.consultamsbobinas', {
            url: '/consultamsbobinas',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Consulta de Movimientos de Stock de Bobinas'
            }
          })
          .state('app.consultamsbobinas.list', {
            url: '',
            templateUrl: 'qualita-components/bobinas/views/consultamsbobina/consulta.html',
            controller: 'MovimientoConsultaStockBobinaIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.consultamsbobinas',
              label: 'Consulta de Movimientos de Stock de Bobinas',
              description: 'Consulta de Movimientos de Stock de Bobinas',
              skip: true
            }
          })
          .state('app.consultamovproductos', {
            url: '/consultamovproductos',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Consultas de Movimientos de Productos con Resumen por Fecha'
            }
          })
          .state('app.consultamovproductos.list', {
            url: '',
            templateUrl: 'qualita-components/bobinas/views/consultamovproductos/consulta.html',
            controller: 'ConsultaMovimientoProductosIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.consultamovproductos',
              label: 'Consultas de Movimientos de Productos con Resumen por Fecha',
              description: 'Consultas de Movimientos de Productos con Resumen por Fecha',
              skip: true
            }
          })
      }]);

function detalleRecepcionPrepService(RecepcionDetalleFactory, $stateParams) {
  return RecepcionDetalleFactory.get($stateParams.id, 'ClasificacionBobinasForm').$promise;
}
detalleRecepcionPrepService.$inject = ["RecepcionDetalleFactory", "$stateParams"];

// function grupoProductoPrepService(GruposProductosFactory, $stateParams) {
//   return GruposProductosFactory.get($stateParams.id, 'fraccionamientoView').$promise;
// }

function fraccionamientoPrepService(FraccionamientoBobinasFactory, $stateParams) {
  return FraccionamientoBobinasFactory.get($stateParams.id, 'FraccionamientoBobinasForm').$promise;
}
fraccionamientoPrepService.$inject = ["FraccionamientoBobinasFactory", "$stateParams"];

function maquinariaPrepService(MaquinariaFactory, $stateParams) {
  return MaquinariaFactory.get($stateParams.id, 'BaseForm').$promise;
}
maquinariaPrepService.$inject = ["MaquinariaFactory", "$stateParams"];

angular
  .module("qualita.bobinas")
  .factory("BobinasLangFactory", BobinasLangFactory);

BobinasLangFactory.$inject = [
  "$resource",
  "baseurl",
  "$translate",
  "$translatePartialLoader",
  "$http"
];

function BobinasLangFactory(
  $resource,
  baseurl,
  $translate,
  $translatePartialLoader,
  $http
) {
  var translationsNeeded = [
    "CODE",
    "RECEPTION_NUMBER",
    "DESCRIPTION",
    "ALMACEN",
    "ITEM",
    "MEASURE_UNIT",
    "TOTAL_WEIGHT",
    "RECEIVED_UNITS",
    "REMAINING_WEIGHT",
    "RECEIVED_AMOUNT",
    "REAL_WEIGHT",
    "GROUP",
    "TITLE_NEW_CLASIFICATION",
    "TITLE_VIEW_CLASIFICATION",
    "TITLE_NEW_DIVISION",
    "TITLE_VIEW_DIVISION",
    "STATUS",
    "KIND",
    "BRAND",
    "LINE",
    "NOMBRE",
    "NRO_ORDEN_PRODUCCION",
    "NRO_MOVIMIENTO_MATERIALES",
    "DATE",
    "CLASIFICAR",
    "VIEW",
    "WEIGHT_GR",
    "OUT_WEIGHT",
    "TOTAL_CALCULADO",
    "PRODUCT",
    "NRO_APUNTE_CORTE",
    "CALCULATED_WEIGHT_TOTAL",
    "OUT_WEIGHT_TOTAL",
    "DESPERDICIO_TOTAL",
    "STOCK_ERROR_FRACCIONAMIENTO",
    "NEW_MAQUINARIA",
    "EDIT_MAQUINARIA",
    "VIEW_MAQUINARIA",
    "MAQUINARIA_NOT_SAVED",
    "FAILED_DELETE_MAQUINARIA",
    "MAQUINARIA",
    "OPERADOR",
    "TURNO",
    "BLOQUEO_BOBINAS",
    "ACTIVE",
    "ORIGINAL_WAREHOUSE",
    "DESTINY_WAREHOUSE",
    "NAME_EMPLOYE",
    "STOCK_TRANSACTION",
    "ID",
    "NOMBRE_DUPLICADO",
    "TOTAL_GENERAL",
    "PROYECTO"
  ];

  var service = {
    getTranslations: getTranslations
  };

  function getTranslations() {
    $translatePartialLoader.addPart("bobinas");
    return $translate.refresh().then(function() {
      return $translate(translationsNeeded);
    });
  }

  return service;
}

angular.module('qualita.bobinas')
  .factory('ClasificacionBobinasFactory', ClasificacionBobinasFactory);

ClasificacionBobinasFactory.$inject = ['$resource', 'baseurl', '$translate', '$translatePartialLoader', '$http'];

function ClasificacionBobinasFactory($resource, baseurl, $translate, $translatePartialLoader, $http) {

  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var ClasificacionBobinasFactory = $resource( baseurl.getBaseUrl() + "/clasificacion_bobinas/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params) {
    return ClasificacionBobinasFactory.query(params);
  }

  function get(id) {
    return ClasificacionBobinasFactory.get({id: id});
  }

  function create(attrs) {
    return new ClasificacionBobinasFactory(attrs);
  }

  function save(gruposProducto) {
    return $http({
      method: 'POST',
      url: baseurl.getBaseUrl() + "/clasificacion_bobinas",
      data: gruposProducto
    });
  }

  function remove(recepcion) {
    return recepcion.$remove();
  }
}

angular.module('qualita.bobinas')
  .factory('FraccionamientoBobinasFactory', FraccionamientoBobinasFactory);

FraccionamientoBobinasFactory.$inject = ['$resource', 'baseurl', '$translate', '$translatePartialLoader', '$http'];

function FraccionamientoBobinasFactory($resource, baseurl, $translate, $translatePartialLoader, $http) {

  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    reversar: reversar,
    getCSV: getCSV
  };

  var FraccionamientoBobinasFactory = $resource(baseurl.getBaseUrl() + "/fraccionamiento/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params) {
    return FraccionamientoBobinasFactory.query(params);
  }

  function get(id, view) {
    if (typeof (view) === 'undefined') view = "base";
    return FraccionamientoBobinasFactory.get({ id: id, view: view });
  }

  function create(attrs) {
    return new FraccionamientoBobinasFactory(attrs);
  }

  function save(fraccionamiento) {
    return (fraccionamiento.id) ? fraccionamiento.$update() : fraccionamiento.$save();
  }

  function remove(fraccionamiento) {
    return fraccionamiento.$remove();
  }

  function reversar(id) {
    return $http({
      url: baseurl.getBaseUrl() + '/fraccionamiento/' + id + '/reversar',
      method: "PUT"
    });
  }

  function getCSV(searchParams) {
    var params = {};
    if (searchParams) {
        params.query = decodeURIComponent($.param(searchParams));
    }

    return $http.post(baseurl.getBaseUrl() + "/fraccionamiento/csv?" + params.query);
}
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.bobinas')
  .controller('ClasificacionBobinasCtrl', ClasificacionBobinasFormCtrl);

ClasificacionBobinasFormCtrl.$inject = ['$rootScope', '$scope', '$location',
  'detalleRecepcionPrepService', 'formFactory', 'ClasificacionBobinasFactory', 'RecepcionDetalleFactory',
  '$state', 'filterFactory', 'GruposProductosFactory', 'BobinasLangFactory', 'notify', '$filter'];

function ClasificacionBobinasFormCtrl($rootScope, $scope, $location,
  detalleRecepcionPrepService,formFactory, ClasificacionBobinasFactory, RecepcionDetalleFactory,
  $state, filterFactory, GruposProductosFactory, BobinasLangFactory, notify, $filter) {
  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.pesoRealChanged = pesoRealChanged;
  $rootScope.isProcessing = false;

  vm.uiBlockuiConfig = {
    'bloquear': false,
    'message': 'Clasificando bobinas...'
  };

  activate();

  function activate() {
    if($state.is("app.clasificacionbobinas.new")) {
      activateNew();
    } else if ($state.is("app.clasificacionbobinas.view")) {
      activateView();
    }
  }

  function activateNew() {
    BobinasLangFactory.getTranslations().then(function(translations) {
      vm.recepcionDetalle = detalleRecepcionPrepService;
      vm.recepcionDetalle.gruposProducto = _.sortBy(vm.recepcionDetalle.gruposProducto, 'id');
      if(vm.recepcionDetalle.gruposProducto) {
        vm.recepcionDetalle.gruposProducto = _.map(vm.recepcionDetalle.gruposProducto, function(grupo) {
          if(grupo.pesoReal != 0) {
            grupo.bloqueado = true;
          } else {
            grupo.bloqueado = false;
          }
          return grupo;
        });
      }
      vm.totalClasificado = _.reduce(vm.recepcionDetalle.gruposProducto, function(sum, grupo) {
        return sum + grupo.pesoReal;
      }, 0);
      vm.totalCalculado = _.reduce(vm.recepcionDetalle.gruposProducto, function(sum, grupo) {
        return sum + grupo.pesoEstimado;
      }, 0);
      vm.totalClasificado = parseFloat(vm.totalClasificado.toFixed(2));
      vm.totalCalculado = parseFloat(vm.totalCalculado.toFixed(2));

      $scope.entidadId=vm.recepcionDetalle.id;
      $scope.entidad="DetalleRecepcion";
      vm.title = $filter('translate')('TITLE_NEW_CLASIFICATION');
    });
  }

  function activateView() {
    vm.view = true;
    BobinasLangFactory.getTranslations().then(function(translations) {
      vm.recepcionDetalle = detalleRecepcionPrepService;
      vm.totalClasificado = _.reduce(vm.recepcionDetalle.gruposProducto, function(sum, grupo) {
        return sum + grupo.pesoReal;
      }, 0);
      vm.totalCalculado = _.reduce(vm.recepcionDetalle.gruposProducto, function(sum, grupo) {
        return sum + grupo.pesoEstimado;
      }, 0);
      vm.totalClasificado = parseFloat(vm.totalClasificado.toFixed(2));
      vm.totalCalculado = parseFloat(vm.totalCalculado.toFixed(2));
      $scope.entidadId=vm.recepcionDetalle.id;
      $scope.entidad="DetalleRecepcion";
      vm.title = $filter('translate')('TITLE_VIEW_CLASIFICATION');
    });
  }

  function submit() {
    vm.submited = true;
    vm.uiBlockuiConfig.bloquear = true;
    ClasificacionBobinasFactory.save(vm.recepcionDetalle.gruposProducto).then(function (response) {
      console.log(response);
      vm.uiBlockuiConfig.bloquear = false;
      $location.path('/clasificacion_bobinas');
    }, function(error) {
      vm.uiBlockuiConfig.bloquear = false;
      console.log(error);
      notify({ message: error.data[0].message, classes: 'alert-danger', position: 'right' });
    });
  }

  function pesoRealChanged() {
    if(!vm.recepcionDetalle || !vm.recepcionDetalle.gruposProducto) {
      return;
    }
    vm.totalClasificado = _.reduce(vm.recepcionDetalle.gruposProducto, function(sum, grupo) {
      return sum + grupo.pesoReal;
    }, 0);
    vm.totalClasificado = parseFloat(vm.totalClasificado.toFixed(2));
  }

  function cancel() {
    $location.path("/clasificacion_bobinas");
  }


}

'use strict';

angular.module('qualita.bobinas')
  .controller('FraccionamientoBobinasListCtrl', FraccionamientoBobinasListCtrl);

FraccionamientoBobinasListCtrl.$inject = ["RecepcionDetalleFactory", "FraccionamientoBobinasFactory",
  "ClasificacionBobinasFactory", "filterFactory", "$state", "AuthorizationService", "BobinasLangFactory", "$filter",
  "ReportTicketFactory", "$window", "$scope", "UtilFactory", "CsvFactory", "$modal", "notify", "baseurl"];

function FraccionamientoBobinasListCtrl(RecepcionDetalleFactory, FraccionamientoBobinasFactory, ClasificacionBobinasFactory,
  filterFactory, $state, AuthorizationService, BobinasLangFactory, $filter,
  ReportTicketFactory, $window, $scope, UtilFactory, CsvFactory, $modal, notify, baseurl) {
  var vm = this;

  activate();

  function activate() {
    BobinasLangFactory.getTranslations().then(function (translations) {

      var defaultColumnOrder = ['id', 'fecha', 'grupoProducto.numero', 'grupoProducto.producto.nombre',
        'nroOrdenProduccion', 'nroMovimientoMaterial', 'nroApunteCorte', 'depositoOrigen.descripcion',
        'depositoDestino.descripcion', 'maquinaria.nombre', 'operador.nombre', 'turnoTrabajo.nombre',
        'transaccionStock.descripcion', 'grupoProducto.producto.unidadMedidaBase.descripcion',
        'grupoProducto.pesoReal', 'grupoProducto.pesoRestante', 'estado.descripcion', 'proyecto'];

      vm.options = {
        'resource': 'fraccionamiento',
        'view': 'FraccionamientoBobinasList',
        'customResource': 'fraccionamientobobinas',
        'title': 'Fraccionamiento de Bobinas',
        'factory': FraccionamientoBobinasFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('CODE'), 'class': 'dt-left', visible: true },
          { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'class': 'dt-center', 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'type': 'date-range' },
          { 'data': 'grupoProducto.producto.nombre', 'title': $filter('translate')('PRODUCT'), 'class': 'dt-left' },
          { 'data': 'grupoProducto.numero', 'title': $filter('translate')('GROUP'), 'class': 'dt-center' },/* LOTE */
          { 'data': 'nroOrdenProduccion', 'title': $filter('translate')('NRO_ORDEN_PRODUCCION'), 'class': 'dt-center', 'renderWith': 'emptyRender' },
          { 'data': 'nroApunteCorte', 'title': $filter('translate')('NRO_APUNTE_CORTE'), 'class': 'dt-center', 'renderWith': 'emptyRender' },
          { 'data': 'proyecto', 'title': $filter('translate')('PROYECTO'), 'class': 'dt-center', 'renderWith': 'emptyRender' },
          { 'data': 'depositoOrigen.descripcion', 'title': $filter('translate')('ORIGINAL_WAREHOUSE'), 'class': 'dt-center', visible: true },
          { 'data': 'depositoDestino.descripcion', 'title': $filter('translate')('DESTINY_WAREHOUSE'), 'class': 'dt-center', visible: true },
          { 'data': 'maquinaria.nombre', 'title': $filter('translate')('MAQUINARIA'), 'class': 'dt-center', visible: false, 'renderWith': 'emptyRender' },
          { 'data': 'operador.nombre', 'title': $filter('translate')('NAME_EMPLOYE'), 'class': 'dt-center', visible: false, 'renderWith': 'emptyRender' },
          { 'data': 'turnoTrabajo.nombre', 'title': $filter('translate')('TURNO'), 'class': 'dt-center', visible: false, 'renderWith': 'emptyRender' },
          { 'data': 'transaccionStock.descripcion', 'title': $filter('translate')('STOCK_TRANSACTION'), 'class': 'dt-center', visible: true },
          { 'data': 'grupoProducto.producto.unidadMedidaBase.descripcion', 'title': $filter('translate')('MEASURE_UNIT'), 'class': 'dt-center', visible: false },
          { 'data': 'grupoProducto.pesoReal', 'title': $filter('translate')('REAL_WEIGHT'), 'class': 'dt-center', visible: true },
          { 'data': 'grupoProducto.pesoRestante', 'title': $filter('translate')('REMAINING_WEIGHT'), 'class': 'dt-center', visible: true },
          { 'data': 'estado.descripcion', 'title': 'Estado', 'class': 'dt-center', visible: true, 'renderWith': 'emptyRender', type: 'combo', filterUrl: 'tipos/filtro/estado_fraccionamiento_bobinas' }
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        //"hideAddMenu": true,
        'hideEditMenu': true,
        //"hideViewMenu": true,
        "hideRemoveMenu": true,
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = createFilters(vm.options.getFilters());
                ReportTicketFactory.ticket('listadoFraccionamientoBobinas', filters, vm.options.tableAjaxParams,
                  vm.options.currentColumnOrder).then(function (genTicket) {
                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                    $window.open($scope.pdfDownloadURL, '_blank');
                  });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                CsvFactory.csv("fraccionamiento", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ],
        'extraRowOptions': [{
          templateToRender: "<button title='Reversar' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='reversar(<%=dataId%>)' ng-show='canReversar(<%=dataCustom%>)'> <span class='glyphicon glyphicon-remove'></span> </button>",
          customAttribute: ['estado'],
          conditionName: "canReversar",
          conditionDef: function (atributos) {
            var estado = atributos[0];
            return estado.codigo == "fracc_bobina_confirmado";
          },
          functionName: "reversar",
          functionDef: function (itemId) {
            $scope.selectedItemId = itemId;
            $scope.tituloModal = 'Confirmar reversión';
            $scope.mensajeModal = 'Esta operación cambiará el estado del movimiento a reversado. ¿Desea continuar?';
            $scope.modalInstanceReversar = $modal.open({
              templateUrl: 'views/directives/confirmation-modal.html',
              scope: $scope
            });

            $scope.cancel = function () {
              $scope.modalInstanceReversar.dismiss('cancel');
            };

            $scope.ok = function (itemId) {
              FraccionamientoBobinasFactory.reversar(itemId).then(function () {
                $scope.modalInstanceReversar.close(itemId);
                $state.go($state.current, {}, { reload: true });
              }, function (error) {
                var mensaje = "<span>";
                _.forEach(error.data, function (err) {
                  mensaje = mensaje + err.message + "<br/>";
                });
                mensaje = mensaje + "</span>";
                notify({
                  messageTemplate: mensaje,
                  classes: "alert-danger",
                  position: "right",
                  templateUrl: ""
                });
                console.log("error al reversar ");
                console.log(error);
              });
            };
          }
        },
        {
          templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          customAttribute: ["id", "estado"],
          conditionName: 'canEditar',
          conditionDef: function (atributos) {
            var estado = atributos[1];
            if (AuthorizationService.hasPermission("update_fraccionamiento") === true && estado.codigo === 'fracc_bobina_confirmado') {
              return true;

            } else {
              return false;
            }
          },
          functionName: "editar",
          functionDef: function (atributos) {
            var itemId = atributos[0];
            if (AuthorizationService.hasPermission("update_fraccionamiento") === true) {
              $state.go('app.fraccionamientobobinas.edit', { id: itemId });
            } else {
              notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
            }
          }
        },
        {
          templateToRender: "<button title='" +
            "Reporte Fraccionamiento" +
            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='imprimir(<%=dataCustom%>)' ng-class='{ hidden : !canImprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-print'></span> </button>",
          functionName: "imprimir",
          conditionName: "canImprimir",
          customAttribute: ["id", "fecha", "grupoProducto", "depositoOrigen" , "estado"],
          conditionDef: function (atributos) {
            var estado = atributos[4];
            return estado.codigo != 'fracc_bobina_reversado';
          },
          functionDef: function (atributos) {
            var itemId = atributos[0];
            var fecha = atributos[1];
            var grupoProducto = atributos[2];
            var depositoOrigen = atributos[3];

            $scope.mensajeModal = "Desea imprimir el Reporte de Fraccionamiento?";
            $scope.tituloModal = "Fraccionamiento";
            var modalInstance = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });

            $scope.cancel = function () {
              modalInstance.dismiss("cancel");
            };

            $scope.ok = function (id) {
              var filtersArr = [];
              filtersArr.push("almacen=" + depositoOrigen.id);
              filtersArr.push("producto=" + grupoProducto.producto.id);
              var fechaCorrecta = moment(fecha, "DD/MM/YYYY HH:mm").toDate();  // Interpretando como dd/MM/yyyy
              var myDateD = $filter('date')(fechaCorrecta, "MM/dd/yyyy");
              filtersArr.push("fechaDesde=" + myDateD);
              var myDateH = $filter('date')(fechaCorrecta, "MM/dd/yyyy");
              filtersArr.push("fechaHasta=" + myDateH);
              filtersArr.push("nroRecepcion=" + parseInt(grupoProducto.numero.split('-')[0], 10));


              modalInstance.close();
              $state.go($state.current, {}, { reload: true });
              $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                "consultaMovimientosBobinas",
                "pdf",
                filtersArr
              );
              $window.open($scope.pdfDownloadURL, "_blank");
              $state.go($state.current, {}, { reload: true });
            }
          }
        }
      ]
      };
    }, function (error) {
      console.log("error al traer translations: ");
      console.log(error);
    });
  }
  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      search = search.split("~");
      if (search.length > 1) {
        /* El parámetro es un rago */

        var isDate = search[0].split("/");

        if (isDate.length > 1) {
          /* Es un rago de fechas */

          filtersArr.push({ path: data, equalOrAfter: search[0] })
          filtersArr.push({ path: data, equalOrBefore: search[1] })
        } else {
          /* El un rago numérco */

          filtersArr.push({ path: data, greaterOrEqual: search[0] })
          filtersArr.push({ path: data, lessOrEqual: search[1] })
        }
      } else {
        /* El parametro no es un rago */
        filtersArr.push({ path: data, like: search[0] })
      }
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }

}

'use strict';

angular.module('qualita.bobinas')
    .controller('RecepcionBobinasListCtrl', RecepcionBobinasListCtrl);

RecepcionBobinasListCtrl.$inject = ["RecepcionDetalleFactory", "ClasificacionBobinasFactory", "filterFactory", "$state",
    "AuthorizationService", "BobinasLangFactory", "$filter", "ReportTicketFactory", "$scope", "$window", "UtilFactory",
    "CsvFactory", "baseurl"
];

function RecepcionBobinasListCtrl(RecepcionDetalleFactory, ClasificacionBobinasFactory, filterFactory, $state,
    AuthorizationService, BobinasLangFactory, $filter, ReportTicketFactory, $scope, $window, UtilFactory,
    CsvFactory, baseurl) {
    var vm = this;

    activate();

    var importeRender = function (data) {
        if (data == undefined)
            return "";
        else
            //var dataAux=parseFloat(data).toFixed(4);
            return (data).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    function activate() {
        BobinasLangFactory.getTranslations().then(function (translations) {
            // filtro estático
            var and1 = filterFactory.and([{
                path: 'producto.claseProducto.tipoAdministracion.codigo',
                equals: 'bobinas'
            },
            {
                path: 'recepcion.embarque.importacion.estado.codigo',
                equals: 'cerrada'
            }
            ]).value();

            var and2 = filterFactory.and([{
                path: 'producto.claseProducto.tipoAdministracion.codigo',
                equals: 'bobinas'
            },
            {
                path: 'recepcion.compra.estado.codigo',
                equals: 'compra_cerrada'
            }
            ]).value();

            var filtersOr = [and1, and2];
            var staticFilter = { search: filterFactory.or(filtersOr).value() };

            var defaultColumnOrder = ['recepcion.id', 'recepcion.fecha', 'recepcion.almacen.descripcion',
                'producto.nombre', 'unidadMedida.descripcion', 'cantidadRecepcionada', 'unidadesRecepcionadas', 
                'recepcion.embarque.importacion.estado.codigo', 'recepcion.compra.estado.codigo',
                'proyecto'
            ];

            vm.options = {
                'staticFilter': staticFilter,
                'resource': 'recepciondetalle',
                'view': 'RecepcionBobinasList',
                'title': 'Recepciones de Bobinas',
                'factory': RecepcionDetalleFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'extraMenuOptions':
                    [
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters(vm.options.getFilters());
                                ReportTicketFactory.ticket('listadoClasificacionBobinas', filters, vm.options.tableAjaxParams,
                                    vm.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                                CsvFactory.csv("recepciondetalle", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        }
                    ],
                'columns': [
                    { 'data': 'recepcion.id', 'title': $filter('translate')('RECEPTION_NUMBER'), 'class': 'dt-center', 'type': "number-range" },
                    { 'data': 'producto.nombre', 'title': $filter('translate')('ITEM'), 'class': 'dt-left' },
                    { 'data': 'recepcion.fecha', 'title': $filter('translate')('DATE'), 'class': 'dt-center', 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'type': 'date-range' },
                    { 'data': 'recepcion.almacen.descripcion', 'title': $filter('translate')('ALMACEN'), 'class': 'dt-left' },
                    { 'data': 'unidadMedida.descripcion', 'title': $filter('translate')('MEASURE_UNIT'), 'class': 'dt-center' },
                    { 'data': 'cantidadRecepcionada', 'title': $filter('translate')('TOTAL_WEIGHT'), 'class': 'dt-right', 'render': importeRender, 'type': "number-range" },
                    { 'data': 'unidadesRecepcionadas', 'title': $filter('translate')('RECEIVED_AMOUNT'), 'class': 'dt-right', 'render': importeRender, 'type': "number-range" },
                    { 'data': 'proyecto', 'title': $filter('translate')('PROYECTO'), 'class': 'dt-left' }
                    /*          {'data': 'recepcion.embarque.importacion.estado.codigo', 'title': $filter('translate')('XXXX'), 'class' : 'dt-right'},
                              {'data': 'recepcion.compra.estado.codigo', 'title': $filter('translate')('XXXX'), 'class' : 'dt-right'}*/

                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraRowOptions': [{
                    templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' ng-click='clasificar(<%=dataId%>)' title='Clasificar'> <span class='glyphicon glyphicon-list'></span> </button>",
                    functionName: "clasificar",
                    functionDef: function (itemId) {
                        $state.go("app.clasificacionbobinas.new", { id: itemId });
                    }
                },
                {
                    templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                    functionName: "ver",
                    functionDef: function (itemId) {
                        $state.go("app.clasificacionbobinas.view", { id: itemId });
                    },
                    conditionName: "canVer",
                    conditionDef: function () {
                        return AuthorizationService.hasPermission('index_recepciondetalle');
                    },
                }
                ]
            };
        }, function (error) {
            console.log("error al traer translations: ");
            console.log(error);
        });
    }

    var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            search = search.split("~");
            if (search.length > 1) {
                /* El parámetro es un rago */

                var isDate = search[0].split("/");

                if (isDate.length > 1) {
                    /* Es un rago de fechas */

                    filtersArr.push({path: data, equalOrAfter: search[0]})
                    filtersArr.push({path: data, equalOrBefore: search[1]})
                } else {
                    /* El un rago numérco */

                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                    filtersArr.push({path: data, lessOrEqual: search[1]})
                }
            } else {
                /* El parametro no es un rago */
                filtersArr.push({path: data, like: search[0]})
            }
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.bobinas')
    .controller('FraccionamientoBobinasFormCtrl', FraccionamientoBobinasFormCtrl);

FraccionamientoBobinasFormCtrl.$inject = ['$rootScope', '$scope', '$location', 'formFactory', 'ClasificacionBobinasFactory',
    "fraccionamientoPrepService", "ProductosFactory", "TransaccionStockFactory", "FraccionamientoBobinasFactory", "GrupoProductoAlmacenFactory", "$timeout",
    "ModelTrimmer", "AlmacenFactory", "RelacionesProductosFactory", "filterFactory", "Util", "$state", "BobinasLangFactory", "GruposProductosFactory",
    "ParametrosFactory", "$q", "notify", "WatchLocacionChangeFactory", "UtilFactory", "MaquinariaFactory", "$filter",
    "ProyectoFactory", "TiposFactory", "$modal"
];

function FraccionamientoBobinasFormCtrl($rootScope, $scope, $location, formFactory, ClasificacionBobinasFactory,
    fraccionamientoPrepService, ProductosFactory, TransaccionStockFactory, FraccionamientoBobinasFactory, GrupoProductoAlmacenFactory,
    $timeout, ModelTrimmer, AlmacenFactory, RelacionesProductosFactory, filterFactory, Util, $state, BobinasLangFactory, GruposProductosFactory,
    ParametrosFactory, $q, notify, WatchLocacionChangeFactory, UtilFactory, MaquinariaFactory, $filter,
    ProyectoFactory, TiposFactory, $modal) {

    var vm = this;
    vm.cancel = cancel;
    vm.submit = submit;
    vm.removeItemFromArray = removeItemFromArray;
    vm.agregarDetalle = agregarDetalle;
    vm.changeProducto = changeProducto;
    vm.changeGrupoProducto = changeGrupoProducto;
    vm.pesoCalculadoChanged = pesoCalculadoChanged;
    vm.unidadMedidaChanged = unidadMedidaChanged;
    vm.searchGrupoFilter = searchGrupoFilter;
    vm.searchProductoFilter = searchProductoFilter;
    vm.factoryProductos = ProductosFactory;
    vm.pesoReferenciaChanged = pesoReferenciaChanged;
    vm.delayRequestGrupo = delayRequestGrupo;
    vm.totalDesperdicio = 0;
    vm.totalCalculado = 0;
    vm.totalEgresado = 0;

    activate();

    function activate() {

        var filterProyecto = {};
        filterProyecto.view = "BaseList"
        vm.proyectos = ProyectoFactory.all(filterProyecto, "ProyectoList");

        WatchLocacionChangeFactory.killAllWatchers();
        BobinasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            var promise1 = ParametrosFactory.getByCodigo('generar_stock_negativo');

            promise1.then(function (response) {
                // parametro
                vm.permiteStockNegativo = response.valorTipo.codigo;

                var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
                    'lineaProducto.descripcion', 'marca.descripcion'
                ];
                vm.totalFraccionado = 0;
                vm.opcionesProductosDt = {
                    'resource': 'productos',
                    'title': 'Productos',
                    'view': 'ProductosDatatableList',
                    'factory': ProductosFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'columns': [
                        { 'data': 'codigo', 'title': $filter('translate')('CODE'), visible: false },
                        { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
                        { 'data': 'claseProducto.descripcion', 'title': $filter('translate')('KIND') },
                        { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
                        { 'data': 'lineaProducto.descripcion', 'title': $filter('translate')('LINE') },
                        { 'data': 'marca.descripcion', 'title': $filter('translate')('BRAND'), 'renderWith': 'emptyRender' },
                    ],
                    'hasOptions': true,
                    'defaultOrderColumn': 0,
                    'defaultOrderDir': "desc",
                };
                vm.factoryProductos = ProductosFactory;

                vm.atributosProducto = {};
                vm.detallesAdicionales = {};
                getTransaccionesStock().then(function (data) {
                    vm.transaccionesStock = data;
                });

                TiposFactory.estadosFraccionamientoBobinas().then(function (data) {
                    vm.estados = data.data;
                });

                vm.title = $filter('translate')('TITLE_NEW_DIVISION');
                if ($state.is("app.fraccionamientobobinas.new")) {
                    activateNew();
                } else if ($state.is("app.fraccionamientobobinas.edit")) {
                    activateEdit();
                } else if ($state.is("app.fraccionamientobobinas.view")) {
                    vm.title = $filter('translate')('TITLE_VIEW_DIVISION');
                    activateView();
                }
                $rootScope.isProcessing = false;
                if (vm.watcherAlmacen) { vm.watcherAlmacen(); } //hacemos unwatch de la expresion anterior
                WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcherAlmacen) {
                    var filterSucursal = {
                        search: filterFactory.single({
                            path: "sucursal.id",
                            equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                        }).value()
                    };
                    vm.watcherAlmacen = watcherAlmacen;
                    vm.almacenes = AlmacenFactory.all(filterSucursal, "BaseList");
                }, "locacion.id");

                //TODO que esto sea un datatables
                var staticFilter = {};
                staticFilter.search = filterFactory.or([
                    filterFactory.and([
                        {
                            path: 'producto.claseProducto.tipoAdministracion.codigo',
                            equals: 'bobinas'
                        },
                        {
                            path: 'pesoReal',
                            greaterOrEqual: 1
                        },
                        {
                            path: 'pesoRestante',
                            greaterOrEqual: 1
                        }
                    ]).value(),
                    filterFactory.and([
                        {
                            path: 'producto.claseProducto.tipoAdministracion.codigo',
                            equals: 'transformacion'
                        },
                        {
                            path: 'pesoReal',
                            greaterOrEqual: 1
                        },
                        {
                            path: 'pesoRestante',
                            greaterOrEqual: 1
                        }
                    ]).value()
                ]).value();
                vm.gruposProducto = GruposProductosFactory.all(staticFilter, "FraccionamientoBobinasList");

                var filtroMaquinarias = {};
                filtroMaquinarias.search = filterFactory.and([{
                    path: 'activo',
                    like: 'true'
                }]).value();
                filtroMaquinarias.view = "BaseList";
                vm.maquinarias = MaquinariaFactory.all(filtroMaquinarias);

                /* var filtroEmpleados = {};
                 filtroEmpleados.search = filterFactory.and([{
                     path: 'activo',
                     like: 'true'
                 }]).value();
                 filtroEmpleados.view = "FraccionamientoBobinasList";
                 vm.empleados = empleadoFactory.all(filtroEmpleados);*/

                // vm.turnosTrabajo = turnoTrabajoFactory.all({ view: "FraccionamientoBobinasList" });

                // var defaultColumnOrderGrupo = ['id', 'numero', 'producto.nombre', 'pesoReal', 'pesoRestante'];
                // vm.opcionesGruposProductosDt = {
                //     'resource': 'grupoproducto',
                //     'title': 'Grupos Producto',
                //     'factory': GruposProductosFactory,
                //     'defaultColumnOrder': defaultColumnOrderGrupo,
                //     'columns': [
                //         { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false },
                //         { 'data': 'numero', 'title': $filter('translate')('NUMBER') },
                //         { 'data': 'producto.nombre', 'title': $filter('translate')('PRODUCT') },
                //         { 'data': 'pesoReal', 'title': $filter('translate')('REAL_WEIGHT') },
                //         { 'data': 'persoRestante', 'title': $filter('translate')('REMAINING_WEIGHT') },
                //     ],
                //     'hasOptions': true,
                //     'defaultOrderColumn': 0,
                //     'defaultOrderDir': "desc",
                // };
                // vm.factoryGrupos = GruposProductosFactory;
            });

        });
    }

    function activateNew() {
        console.log("venis aca ")
        vm.fraccionamiento = {
            fecha: new Date(),
            fraccionamientoDetalles: [{}],
            //grupoProducto: vm.grupoProducto
        };
        TiposFactory.estadosFraccionamientoBobinas().then(function (data) {
            vm.estados = data.data;
            _.forEach(vm.estados, function (elem) {
                if (elem.codigo == 'fracc_bobina_confirmado') {
                    vm.fraccionamiento.estado = elem;
                }
            });
        });
    }

    function activateEdit() {
        vm.edit = true;
        vm.fraccionamiento = fraccionamientoPrepService;
        vm.fraccionamiento.fecha = new Date(vm.fraccionamiento.fecha);
        initDetails();
        vm.totalFraccionado = _.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.cantidadFraccionada;
        }, 0);
        vm.totalCalculado = _.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.pesoCalculado;
        }, 0);
        vm.totalEgresado = _.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.pesoReferencia;
        }, 0);
        vm.totalDesperdicio = _.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.desperdicio;
        }, 0);
    }

    function activateView() {
        vm.view = true;
        vm.fraccionamiento = fraccionamientoPrepService;
        vm.fraccionamiento.fecha = new Date(vm.fraccionamiento.fecha);
        initDetails();
        vm.totalFraccionado = _.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.cantidadFraccionada;
        }, 0);
        vm.totalCalculado = _.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.pesoCalculado;
        }, 0);
        vm.totalEgresado = _.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.pesoReferencia;
        }, 0);
        vm.totalDesperdicio = _.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.desperdicio;
        }, 0);
        //pesoCalculadoChanged();
    }

    function changeGrupoProducto() {

        if (vm.fraccionamiento.grupoProducto.proyecto != null) {
            vm.fraccionamiento.proyecto = vm.fraccionamiento.grupoProducto.proyecto;
        } else {
            vm.fraccionamiento.proyecto = null;
        }

        getAtributosProducto(vm.fraccionamiento.grupoProducto.producto, vm.atributosProducto);
        /*getProductosDestino().then(function(response) {
          vm.productosDestino = response;
        });*/
        vm.productosDestino = ProductosFactory.allForSelect();
        getAlmacenes().then(function (response) {
            vm.almacenesConStock = response;
        });
        if (vm.watcherAlmacenOrig) { vm.watcherAlmacenOrig(); } //hacemos unwatch de la expresion anterior
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcherAlmacen) {
            vm.watcherAlmacenOrig = watcherAlmacen;
            AlmacenFactory.all(locacionFilter, "FraccionamientoAlmacen").$promise.then(function (response) {
                vm.almacenesOrigen = response;
            });
        }, "locacion.id");
    }

    function getTransaccionesStock() {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'accion.codigo',
            equals: "egreso_almacen"
        }, {
            path: 'requiereConfirmacion',
            equals: false
        },
        {
            path: 'activo',
            equals: true
        }
        ]).value();
        return TransaccionStockFactory.all(staticFilter).$promise.then(function (response) {
            var filtrado = _.filter(response, function (transaccion) {
                return transaccion.transaccionContrapartida;
            });
            return filtrado;
        });
    }

    // DEPRECATED
    function getProductosDestino() {
        if (vm.watcher) { $rootScope.watcher(); }
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
            vm.watcher = watcher;
            var filter = {};
            filter.search = filterFactory.and([{
                path: 'productoPrincipal.id',
                equals: vm.fraccionamiento.grupoProducto.producto.id
            }]).value();
            // filtro sobre advanced datatables
            var dtFilter = {};
            dtFilter.search = filterFactory.and({
                path: 'relacionesProductosDetalle.relacionProductos.productoPrincipal.id',
                equals: vm.fraccionamiento.grupoProducto.producto.id
            }).value();
            var filtroFinal = UtilFactory.createNestedFilters(locacionFilter.search.filters, dtFilter);
            vm.opcionesProductosDt.staticFilter = filtroFinal;

            return RelacionesProductosFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, filter, "RelacionProductoList")).$promise.then(function (response) {
                var detalles = _.flatten(_.map(response, function (resp) {
                    return resp.relacionProductosDetalles;
                }));
                var productos = [];
                _.forEach(detalles, function (detalle) {
                    productos.push(detalle.productoRelacionado);
                });
                vm.productosDestino = _.uniq(productos, function (producto) { return producto.id; });

                return vm.productosDestino;
            });
        }, "relacionProductosDetalles.productoRelacionado.locaciones.id");
    }

    function getAlmacenes() {
        var filter = filterFactory.single({
            path: 'grupoProducto.id',
            equals: vm.fraccionamiento.grupoProducto.id
        }).value();

        return GrupoProductoAlmacenFactory.all(filter, 'BaseList').$promise.then(function (response) {
            var u = {},
                almacenes = [];
            _.forEach(response, function (grupoProductoAlmacen) {
                if (u.hasOwnProperty(grupoProductoAlmacen.almacen.id)) { } else {
                    almacenes.push(grupoProductoAlmacen.almacen);
                    u[grupoProductoAlmacen.almacen.id] = 1;
                }
            });
            return almacenes;
        });
    }

    function submit() {
        vm.submited = true;
        if (validarStock() === true) {
            var trimmedModel = ModelTrimmer.trimDetails(vm.fraccionamiento, ["fraccionamientoDetalles"]);
            //hacemos un trim de los detalles del fraccionamiento
            var trimmedDetalles = _.map(vm.fraccionamiento.fraccionamientoDetalles, function (detalle) {
                return ModelTrimmer.trimDetails(detalle);
            });
            trimmedModel.fraccionamientoDetalles = trimmedDetalles;
            formFactory.defaultNSFSubmit($scope.FraccionamientoForm, FraccionamientoBobinasFactory, trimmedModel).then(function (response) {
                //$location.path('/fraccionamiento_bobinas');
                //aca abrir nueva ventana preguntando si quiere realizar otro fraccionamiento
                $scope.tituloModal = 'Fraccionamiento';
                $scope.mensajeModal = 'Desea Fraccionar otra Bobina?';
                console.log("vm.gruposProducto ", vm.gruposProducto)
                $scope.modalInstanceFraccionamiento = $modal.open({
                    templateUrl: 'views/directives/confirmation-modal-virtual.html',
                    scope: $scope
                });

                $scope.cancel = function () {
                    $scope.modalInstanceFraccionamiento.dismiss('cancel');
                    $state.go('app.fraccionamientobobinas.list');
                };

                $scope.ok = function (itemId) {
                    var fraccionamientoAnterior = vm.fraccionamiento;

                    // Incrementar el índice del grupo actual
                    vm.indiceGrupoActual = (vm.indiceGrupoActual || 0) + 1;

                    if (vm.indiceGrupoActual < vm.gruposProducto.length) {
                        var grupoActual = vm.gruposProducto[vm.indiceGrupoActual];
                        //recuperar siguiente grupo
                        vm.fraccionamiento = {
                            grupoProducto: grupoActual,
                            estado: fraccionamientoAnterior.estado,
                            fecha: fraccionamientoAnterior.fecha,
                            transaccionStock: fraccionamientoAnterior.transaccionStock,
                            producto: grupoActual.producto,
                            lote: grupoActual.numero,
                            nroOrdenProduccion: fraccionamientoAnterior.nroOrdenProduccion,
                            nroApunteCorte: fraccionamientoAnterior.nroApunteCorte,
                            depositoOrigen: fraccionamientoAnterior.depositoOrigen,
                            depositoDestino: fraccionamientoAnterior.depositoDestino,
                            peso: grupoActual.pesoReal,
                            pesoRestante: grupoActual.pesoRestante,
                            unidadMedida: grupoActual.producto.unidadMedidaBase.descripcion,
                            maquinaria: fraccionamientoAnterior.maquinaria,
                            proyecto: fraccionamientoAnterior.proyecto,
                            fraccionamientoDetalles: [],
                        };
                        _.forEach(fraccionamientoAnterior.fraccionamientoDetalles, function (detalle) {
                            var newFraccionamientoDetalle = {
                                producto: detalle.producto,
                                unidadMedida: detalle.unidadMedida,
                                peso: detalle.peso,
                                cantidadFraccionada: 0
                            };
                            vm.fraccionamiento.fraccionamientoDetalles.push(newFraccionamientoDetalle);
                        });
                        $rootScope.isProcessing = false;
                        $scope.modalInstanceFraccionamiento.close(itemId);
                        $state.go('app.fraccionamientobobinas.new');
                    } else {
                        console.log("No hay más grupos disponibles.");
                        $scope.modalInstanceFraccionamiento.close(itemId);
                        $state.go('app.fraccionamientobobinas.list');
                    }
                };
            }, function (error) {
                console.log(error);
            });
        } else {
            var msg = $filter('translate')('STOCK_ERROR_FRACCIONAMIENTO');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
        }
    }

    function validarStock() {
        if (vm.permiteStockNegativo === 'si') {
            return true;
        } else {
            var almacen = _.filter(vm.almacenesConStock, function (o) { return o.id == vm.fraccionamiento.depositoOrigen.id; });
            if (almacen.length >= 1) {
                return true;
            } else {
                return false;
            }
        }
    }

    function agregarDetalle() {
        vm.fraccionamiento.fraccionamientoDetalles.push({});
        $timeout(function () {
            $scope.$broadcast('newItemAdded');
        }, 20);
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
    }

    function cancel() {
        $location.path("/fraccionamiento_bobinas");
    }

    function initDetails() {
        var i = 0;
        _.forEach(vm.fraccionamiento.fraccionamientoDetalles, function (value) {
            changeProducto(vm.fraccionamiento.fraccionamientoDetalles[i], i);
            i++;
        });
        /*for(var i = 0; i < vm.fraccionamiento.fraccionamientoDetalles.length; i++) {
          changeProducto(vm.fraccionamiento.fraccionamientoDetalles[i], i);
        }*/
        //pesoCalculadoChanged();
    }

    function changeProducto(detalle, index) {
        if (vm.view !== true && vm.edit !== true) {
            detalle.unidadMedida = undefined;
            detalle.peso = undefined;
        }
        if (detalle.producto.id) {
            ProductosFactory.get(detalle.producto.id, 'FraccionamientoBobinasForm').$promise.then(function (producto) {
                //detalle.producto = producto;
                detalle.producto.unidadesMedida = producto.unidadesMedida;
                detalle.producto.atributos = producto.atributos;
                if (detalle.producto.unidadMedidaBase)
                    detalle.unidadMedida = detalle.producto.unidadMedidaBase;
                vm.detallesAdicionales[index] = {};
                if (!detalle.peso) {
                    getAtributosProducto(detalle.producto, detalle);
                    delete detalle.gramaje;
                }
                //unidadMedidaChanged(detalle, index);
                //pesoCalculadoChanged();
            });
        } else {
            detalle.producto.$promise.then(function (p) {
                ProductosFactory.get(p.id, 'FraccionamientoBobinasForm').$promise.then(function (producto) {
                    //detalle.producto = producto;
                    detalle.producto.unidadesMedida = producto.unidadesMedida;
                    detalle.producto.atributos = producto.atributos;
                    if (detalle.producto.unidadMedidaBase)
                        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
                    vm.detallesAdicionales[index] = {};
                    if (!detalle.peso) {
                        getAtributosProducto(detalle.producto, detalle);
                        delete detalle.gramaje;
                    }
                    //unidadMedidaChanged(detalle, index);
                    //pesoCalculadoChanged();
                });
            })
        }
    }

    function unidadMedidaChanged(detalle, index) {
        getAtributosProducto(detalle.producto, detalle);
        delete detalle.gramaje;
        detalle.peso = Util.fromUnidadMedidaBase(detalle.peso, detalle.unidadMedida);
        //detalle.peso=Util.fromUnidadMedidaBase(detalle.peso, detalle.unidadMedida);
        //pesoCalculadoChanged();
    }

    function pesoCalculadoChanged() {
        if (!vm.fraccionamiento || !vm.fraccionamiento.fraccionamientoDetalles) {
            return;
        }

        vm.totalFraccionado = 0;
        for (var i = 0; i < vm.fraccionamiento.fraccionamientoDetalles.length; i++) {
            var detalle = vm.fraccionamiento.fraccionamientoDetalles[i];
            if (detalle.producto) {
                detalle.pesoCalculado = parseFloat((detalle.cantidadFraccionada * detalle.peso).toFixed(2));
                detalle.pesoReferencia = parseFloat(detalle.pesoCalculado.toFixed(2));
                detalle.desperdicio = 0;
                vm.totalFraccionado = parseFloat((vm.totalFraccionado + detalle.cantidadFraccionada).toFixed(2));
                //detalle.peso = vm.detallesAdicionales[i].peso;
            }
        }

        vm.totalCalculado = parseFloat((_.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.pesoCalculado;
        }, 0)).toFixed(2));
        vm.totalEgresado = parseFloat((_.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.pesoReferencia;
        }, 0)).toFixed(2));
    }

    function pesoReferenciaChanged(detalle) {
        detalle.desperdicio = parseFloat(Math.abs(detalle.pesoReferencia - detalle.pesoCalculado).toFixed(2));

        vm.totalFraccionado = parseFloat((_.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.cantidadFraccionada;
        }, 0)).toFixed(2));

        vm.totalEgresado = parseFloat((_.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.pesoReferencia;
        }, 0)).toFixed(2));
        vm.totalDesperdicio = parseFloat((_.reduce(vm.fraccionamiento.fraccionamientoDetalles, function (sum, det) {
            return sum + det.desperdicio;
        }, 0)).toFixed(2));
    }

    function getAtributosProducto(producto, destino) {
        var gramaje = _.find(producto.atributos, function (atributo) {
            return atributo.definicionAtributo.codigo == "gramaje";
        });
        if (gramaje) {
            destino.gramaje = gramaje.valor;
        }
        var peso = _.find(producto.atributos, function (atributo) {
            return atributo.definicionAtributo.codigo == "peso";
        });
        if (peso) {
            destino.peso = peso.valor;
            destino.peso = Util.fromUnidadMedidaBase(destino.peso, destino.unidadMedida);
        }
    }

    function searchGrupoFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.producto.codigo == criteria ||
                item.producto.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.proyecto.toLowerCase().indexOf(criteria.toLowerCase()) > -1;
        };
    }

    function searchProductoFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;

        };
    }



    function delayRequestGrupo(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 2) {
            var filterNumero = [];
            var filterNumeroT = [];
            var filterDescripcion = [];
            var filterDescripcionT = [];
            var filterCodigoProducto = [];
            var filterCodigoProductoT = [];


            filterNumero.push({
                path: 'producto.claseProducto.tipoAdministracion.codigo',
                equals: 'bobinas'
            },
                {
                    path: 'pesoReal',
                    greaterOrEqual: 1
                },
                {
                    path: 'pesoRestante',
                    greaterOrEqual: 1
                }, {
                path: 'numero',
                like: searchValue
            })

            filterNumeroT.push({
                path: 'producto.claseProducto.tipoAdministracion.codigo',
                equals: 'transformacion'
            },
                {
                    path: 'pesoReal',
                    greaterOrEqual: 1
                },
                {
                    path: 'pesoRestante',
                    greaterOrEqual: 1
                }, {
                path: 'numero',
                like: searchValue
            })

            filterDescripcion.push({
                path: 'producto.claseProducto.tipoAdministracion.codigo',
                equals: 'bobinas'
            },
                {
                    path: 'pesoReal',
                    greaterOrEqual: 1
                },
                {
                    path: 'pesoRestante',
                    greaterOrEqual: 1
                }, {
                path: 'producto.nombre',
                like: searchValue
            })

            filterDescripcionT.push({
                path: 'producto.claseProducto.tipoAdministracion.codigo',
                equals: 'transformacion'
            },
                {
                    path: 'pesoReal',
                    greaterOrEqual: 1
                },
                {
                    path: 'pesoRestante',
                    greaterOrEqual: 1
                }, {
                path: 'producto.nombre',
                like: searchValue
            })

            filterCodigoProducto.push({
                path: 'producto.claseProducto.tipoAdministracion.codigo',
                equals: 'bobinas'
            },
                {
                    path: 'pesoReal',
                    greaterOrEqual: 1
                },
                {
                    path: 'pesoRestante',
                    greaterOrEqual: 1
                }, {
                path: 'producto.codigo',
                like: searchValue
            })

            filterCodigoProductoT.push({
                path: 'producto.claseProducto.tipoAdministracion.codigo',
                equals: 'transformacion'
            },
                {
                    path: 'pesoReal',
                    greaterOrEqual: 1
                },
                {
                    path: 'pesoRestante',
                    greaterOrEqual: 1
                }, {
                path: 'producto.codigo',
                like: searchValue
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNumero).value(),
                    filterFactory.and(filterNumeroT).value(),
                    filterFactory.and(filterDescripcion).value(),
                    filterFactory.and(filterDescripcionT).value(),
                    filterFactory.and(filterCodigoProducto).value(),
                    filterFactory.and(filterCodigoProductoT).value()
                ]).value()
            }

            recuperarGrupo(userInputFilter)
        } else {
            var staticFilter = {};
            staticFilter.search = filterFactory.or([
                filterFactory.and([
                    {
                        path: 'producto.claseProducto.tipoAdministracion.codigo',
                        equals: 'bobinas'
                    },
                    {
                        path: 'pesoReal',
                        greaterOrEqual: 1
                    },
                    {
                        path: 'pesoRestante',
                        greaterOrEqual: 1
                    }
                ]).value(),
                filterFactory.and([
                    {
                        path: 'producto.claseProducto.tipoAdministracion.codigo',
                        equals: 'transformacion'
                    },
                    {
                        path: 'pesoReal',
                        greaterOrEqual: 1
                    },
                    {
                        path: 'pesoRestante',
                        greaterOrEqual: 1
                    }
                ]).value()
            ]).value();
            vm.gruposProducto = GruposProductosFactory.all(staticFilter, "FraccionamientoBobinasList");
        }
    }

    function recuperarGrupo(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)

        GruposProductosFactory.all(finalFilter, "FraccionamientoBobinasList").$promise.then(function (grupos) {
            var grups = [];
            for (var i = 0; i < grupos.length; i++) {
                if (grupos[i].bloquearVentas !== true) {
                    grups.push(grupos[i])
                }
            }
            vm.gruposProducto = grups;
        })
    }

}

angular.module('qualita.bobinas')
  .factory('MaquinariaFactory', MaquinariaFactory);

MaquinariaFactory.$inject = ['$resource', 'baseurl'];

function MaquinariaFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var Maquinaria = $resource(baseurl.getBaseUrl() + "/maquinaria/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view != undefined ? view : 'BaseList';
    }
    return Maquinaria.query(params);
  }

  function get(id, view) {
    return Maquinaria.get({ id: id, view: view != undefined ? view : 'base' });
  }

  function create(attrs) {
    return new Maquinaria(attrs);
  }

  function save(maquinaria) {
    return (maquinaria.id) ? maquinaria.$update() : maquinaria.$save();
  }

  function remove(maquinaria) {
    return maquinaria.$remove();
  }
}

'use strict';


angular.module('qualita.bobinas')
  .controller('MaquinariaListCtrl', ["$scope", "MaquinariaFactory", "filterFactory", "notify", "AuthorizationService", "ReportTicketFactory", "$window", "UtilFactory", "ProductosFactory", "BobinasLangFactory", "$filter", "CsvFactory", "baseurl", function ($scope, MaquinariaFactory, filterFactory, notify, AuthorizationService,
    ReportTicketFactory, $window, UtilFactory, ProductosFactory, BobinasLangFactory, $filter, CsvFactory, baseurl) {

    var defaultColumnOrder = ['id', 'nombre', 'activo'];

    BobinasLangFactory.getTranslations().then(function (translations) {
      $scope.options = {
        'resource': 'maquinaria',
        'title': 'Maquinarias',
        'factory': MaquinariaFactory,
        'view': 'BaseList',
        'defaultColumnOrder': defaultColumnOrder,
        //'staticFilter': staticFilter,
        'failedDeleteError': $filter('translate')('FAILED_DELETE_MAQUINARIA'),
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('ID'), visible: false  },
          { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
          { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoMaquinarias', filters, $scope.options.tableAjaxParams,
                  $scope.options.currentColumnOrder).then(function (genTicket) {
                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                    $window.open($scope.pdfDownloadURL, '_blank');
                  });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("maquinaria", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ]
      };
    });

    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        if(search === 'No' && data === 'activo'){
          search = 'false';
      }
      if(search === 'Sí' && data === 'activo'){
          search = 'true';
      }
        filtersArr.push({ path: data, like: search })
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }

  }]);

'use strict';

angular.module('qualita.bobinas')
  .controller('MaquinariaFormCtrl', MaquinariaFormCtrl);

MaquinariaFormCtrl.$inject = ['$rootScope', '$scope', 'MaquinariaFactory', 'filterFactory', '$q', '$log', '$location',
  '$stateParams','notify','AuthorizationService', '$state', 'BobinasLangFactory', 'maquinariaPrepService', '$filter'];

function MaquinariaFormCtrl($rootScope, $scope, MaquinariaFactory, filterFactory, $q, $log, $location, $stateParams,
  notify, AuthorizationService, $state, BobinasLangFactory, maquinariaPrepService, $filter) {

  activate();

  function activate() {
    BobinasLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;

      if($state.is("app.maquinaria.new")) {
        activateNew();
      } else if($state.is("app.maquinaria.edit")) {
        activateEdit();
      } else if($state.is("app.maquinaria.view")) {
        activateView();
      }
      $scope.cancel = cancel;

      $scope.submit = submit;

      $rootScope.isProcessing = false;
    });

  }

  function activateNew() {
    $scope.title = $filter('translate')('NEW_MAQUINARIA');
    $scope.maquinaria = {activo: true};
  }

  function activateEdit() {
    $scope.maquinaria = maquinariaPrepService;
    $scope.entidadId=$scope.maquinaria.id;
    $scope.entidad="maquinaria";
    $scope.title = $filter('translate')('EDIT_MAQUINARIA');
  }

  function activateView() {
    $scope.maquinaria = maquinariaPrepService;
    $scope.entidadId=$scope.maquinaria.id;
    $scope.entidad="maquinaria";
    $scope.title = $filter('translate')('VIEW_MAQUINARIA');

    $scope.view = true;
  }

  function submit() {
    $scope.submited = true;
      if ($scope.MaquinariaForm.$valid) {
        $rootScope.isProcessing = true;
        submitMaquinaria().then(function (maquinaria) {
          $location.path('/maquinarias');
        },
      function(err){
        // error callback
        $scope.submited = false;
        $rootScope.isProcessing = false;
        if(err.data[0].constraint==="nombre"){
          notify({ message: $filter('translate')('NOMBRE_DUPLICADO'), classes: 'alert-danger', position: 'right' })
        }else{
         notify({ message: $filter('translate')('MAQUINARIA_NOT_SAVED'), classes: 'alert-info', position: 'right' })
        }
      })
      }

    function submitMaquinaria() {
      console.log("submitMaquinaria");
      var maquinaria = MaquinariaFactory.create($scope.maquinaria);
      return MaquinariaFactory.save(maquinaria,
      function(resp, headers){
        //success callback
        console.log("resp",resp);
      },
      function(err){
        // error callback
        console.log("error =",err);
        notify({ message: $filter('translate')('MAQUINARIA_NOT_SAVED'), classes: 'alert-info', position: 'right' })
      });
    }
  }

  function cancel() {
    $location.path("/maquinarias");
  }

  $scope.resource = 'maquinaria'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';


angular.module('qualita.bobinas')
  .controller('MovimientoConsultaStockBobinaIndexCtrl',
    ["$scope", "BobinasLangFactory", "filterFactory", "ReportTicketFactory", "$window", "AlmacenFactory", "ProductosFactory", "notify", "TiposFactory", "UtilFactory", "ParametrosFactory", "$q", "WatchLocacionChangeFactory", "$filter", "CsvFactory", "baseurl", "AuthorizationService", function ($scope, BobinasLangFactory, filterFactory, ReportTicketFactory,
      $window, AlmacenFactory, ProductosFactory, notify, TiposFactory, UtilFactory,
      ParametrosFactory, $q, WatchLocacionChangeFactory, $filter, CsvFactory, baseurl, AuthorizationService) {

      var emptyDataRender = function (data) {
        if (data == undefined)
          return "";
        else
          return data;
      };
      var importeRender = function (data) {
        if (data == undefined)
          return "";
        else
          //var dataAux=parseFloat(data).toFixed(4);
          return (data).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      WatchLocacionChangeFactory.killAllWatchers();
      $scope.curDate = new Date();
      $scope.producto = undefined;
      $scope.almacen = undefined;
      $scope.nroRecepcion = undefined;
      var filterAlmacen = {
        search: filterFactory.and([
          {
            path: "activo",
            equals: true
          }
        ]).value()
      };
      $scope.almacenes = AlmacenFactory.all(filterAlmacen);

      BobinasLangFactory.getTranslations().then(function (translations) {

        var almacenFiltro = undefined;
        var productoFiltro = undefined;

        $scope.delayRequestProducto = function (searchValue) {
          var userInputFilter = {};
          var staticFilter = [];
          if (searchValue.length >= 3) {

            var filterNombre = staticFilter.slice()
            var filterCodigoBarras = staticFilter.slice()
            var filterCodigoInterno = staticFilter.slice()

            filterNombre.push({
              path: "nombre",
              like: searchValue
            },
              {
                path: 'claseProducto.tipoAdministracion.codigo',
                equals: 'bobinas'
              })

            filterCodigoBarras.push({
              path: "codigoBarrasPrincipal.codigoBarras",
              like: searchValue
            }, {
              path: 'claseProducto.tipoAdministracion.codigo',
              equals: 'bobinas'
            })

            filterCodigoInterno.push({
              path: "codigo",
              like: searchValue
            }, {
              path: 'claseProducto.tipoAdministracion.codigo',
              equals: 'bobinas'
            })

            userInputFilter = {
              search: filterFactory.or([
                filterFactory.and(filterNombre).value(),
                filterFactory.and(filterCodigoBarras).value(),
                filterFactory.and(filterCodigoInterno).value()
              ]).value()
            }
            $scope.recuperarProducto(userInputFilter)
          } else if (searchValue.length > 0 && searchValue.length < 3) {

            var filterCodigoInterno = staticFilter.slice()
            filterCodigoInterno.push({
              path: "codigo",
              like: searchValue
            }, {
              path: 'claseProducto.tipoAdministracion.codigo',
              equals: 'bobinas'
            })

            userInputFilter = {
              search: filterFactory.or([
                filterFactory.and(filterCodigoInterno).value()
              ]).value()
            }
            $scope.recuperarProducto(userInputFilter)
          } else {
            $scope.productos = undefined
          }
        }

        $scope.recuperarProducto = function (userInputFilter) {
          var finalFilter = UtilFactory.createNestedFilters(
            $scope.finalFilters, userInputFilter)

          $scope.productos = ProductosFactory.allForSelect(userInputFilter)
        };

        $scope.changeAlmacen = function (almacen) {
          almacenFiltro = almacen;
        };

        $scope.changeProducto = function (producto) {
          productoFiltro = producto;
        };

        $scope.searchProductoFilter = function (criteria) {
          return function (item) {
            if (!criteria) {
              return true;
            }

            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
          };
        }

        $scope.limpiarFiltros = function () {
          $scope.almacen = undefined;
          $scope.fechaDesde = undefined;
          $scope.fechaHasta = undefined;
          $scope.producto = undefined;
          $scope.nroRecepcion = undefined;
        }

        $scope.cambiarFiltro = function () {
          if ($scope.fechaDesde != undefined && $scope.fechaHasta != undefined && $scope.fechaDesde > $scope.fechaHasta) {
            notify({ message: $filter('translate')('FECHA_DESDE_MAYOR_FECHA_HASTA'), classes: 'alert-danger', position: 'right' });
          } else {
            if ($scope.almacen == undefined) {
              notify({ message: $filter('translate')('FILTRO_ALMACEN_REQUERIDO'), classes: 'alert-danger', position: 'right' });
            } else if ($scope.producto == undefined) {
              notify({ message: $filter('translate')('FILTRO_PRODUCTO_REQUERIDO'), classes: 'alert-danger', position: 'right' });
            } else if ($scope.fechaDesde == undefined) {
              notify({ message: 'Debe completar el filtro de Fecha Desde', classes: 'alert-danger', position: 'right' });
            } else if ($scope.fechaHasta == undefined) {
              notify({ message: 'Debe completar el filtro de Fecha Hasta', classes: 'alert-danger', position: 'right' });
            }else if ($scope.nroRecepcion == undefined) {
              notify({ message: 'Debe completar el filtro de Recepcion', classes: 'alert-danger', position: 'right' });
            } else {
              var filtersArr = [];
              if ($scope.almacen) {
                filtersArr.push("almacen=" + $scope.almacen.id);
              }
              if ($scope.producto) {
                filtersArr.push("producto=" + $scope.producto.id);
              }
              if ($scope.fechaDesde) {
                var myDate = $filter('date')($scope.fechaDesde, "MM/dd/yyyy");
                filtersArr.push("fechaDesde=" + myDate);
              }
              if ($scope.fechaHasta) {
                var myDate = $filter('date')($scope.fechaHasta, "MM/dd/yyyy");
                filtersArr.push("fechaHasta=" + myDate);
              }
              console.log("$scope.nroRecepcion ", $scope.nroRecepcion)
              if ($scope.nroRecepcion != null && $scope.nroRecepcion != "") {
                filtersArr.push("nroRecepcion=" + $scope.nroRecepcion);
              }
              var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("consultaMovimientosBobinas", 'pdf', filtersArr);
              $window.open(pdfDownloadURL, '_blank');
            }
          }
        }
      }, function (error) {
        console.log("error al traer translations: ");
        console.log(error);
      });
    }]);

'use strict';


angular.module('qualita.bobinas')
  .controller('ConsultaMovimientoProductosIndexCtrl',
    ["$scope", "BobinasLangFactory", "filterFactory", "ReportTicketFactory", "$window", "AlmacenFactory", "ProductosFactory", "notify", "TiposFactory", "UtilFactory", "FraccionamientoBobinasFactory", "ParametrosFactory", "$q", "WatchLocacionChangeFactory", "$filter", "CsvFactory", "baseurl", "AuthorizationService", function ($scope, BobinasLangFactory, filterFactory, ReportTicketFactory,
      $window, AlmacenFactory, ProductosFactory, notify, TiposFactory, UtilFactory, FraccionamientoBobinasFactory,
      ParametrosFactory, $q, WatchLocacionChangeFactory, $filter, CsvFactory, baseurl, AuthorizationService) {

      WatchLocacionChangeFactory.killAllWatchers();
      $scope.curDate = new Date();
      $scope.producto = undefined;
      $scope.almacen = undefined;
      var filterAlmacen = {
        search: filterFactory.and([
          {
            path: "activo",
            equals: true
          }
        ]).value()
      };
      $scope.almacenes = AlmacenFactory.all(filterAlmacen);

      BobinasLangFactory.getTranslations().then(function (translations) {

        var almacenFiltro = undefined;
        var productoFiltro = undefined;

        $scope.delayRequestProducto = function (searchValue) {
          var userInputFilter = {};
          var staticFilter = [];
          if (searchValue.length >= 3) {

            var filterNombre = staticFilter.slice()
            var filterCodigoBarras = staticFilter.slice()
            var filterCodigoInterno = staticFilter.slice()

            filterNombre.push({
              path: "nombre",
              like: searchValue
            },
            {
              path: 'claseProducto.descripcion',
              like: 'PLIEGOS'
            })

            filterCodigoBarras.push({
              path: "codigoBarrasPrincipal.codigoBarras",
              like: searchValue
            }, {
              path: 'claseProducto.descripcion',
              like: 'PLIEGOS'
            })

            filterCodigoInterno.push({
              path: "codigo",
              like: searchValue
            }, {
              path: 'claseProducto.descripcion',
              like: 'PLIEGOS'
            })

            userInputFilter = {
              search: filterFactory.or([
                filterFactory.and(filterNombre).value(),
                filterFactory.and(filterCodigoBarras).value(),
                filterFactory.and(filterCodigoInterno).value()
              ]).value()
            }

            $scope.recuperarProducto(userInputFilter)
          } else if (searchValue.length > 0 && searchValue.length < 3) {

            var filterCodigoInterno = staticFilter.slice()
            filterCodigoInterno.push({
              path: "codigo",
              like: searchValue
            }, {
              path: 'claseProducto.descripcion',
              like: 'PLIEGOS'
            })

            userInputFilter = {
              search: filterFactory.or([
                filterFactory.and(filterCodigoInterno).value()
              ]).value()
            }
            $scope.recuperarProducto(userInputFilter)
          } else {
            $scope.productos = undefined
          }
        }

        $scope.recuperarProducto = function (userInputFilter) {
          $scope.productos = ProductosFactory.allForSelect(userInputFilter)
        };

        $scope.changeAlmacen = function (almacen) {
          almacenFiltro = almacen;
        };

        $scope.changeProducto = function (producto) {
          productoFiltro = producto;
          $scope.unidad = producto.unidadMedidaBase;
        };

        $scope.changeUnidad = function (unidadMedida) {
          var actual = unidadMedida;
          $scope.multiplicador = 1;
          if (!actual.esBase) {
            $scope.multiplicador = $scope.multiplicador * actual.cantidad;
            actual = actual.unidadContenida;
          }
        };

        $scope.searchProductoFilter = function (criteria) {
          return function (item) {
            if (!criteria) {
              return true;
            }

            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
          };
        }

        $scope.limpiarFiltros = function () {
          $scope.almacen = undefined;
          $scope.fechaDesde = undefined;
          $scope.fechaHasta = undefined;
          $scope.producto = undefined;
          $scope.unidad = undefined;
        }

        $scope.tiposReporte = [
          { id: -1, codigo: "formato_detallado", descripcion: "Formato Detallado" },
          { id: 0, codigo: "formato_resumido", descripcion: "Formato Resumido" }
        ];
        $scope.tipoReporte = {
          codigo: "formato_resumido",
          descripcion: "Formato Resumido"
        };

        $scope.cambiarFiltro = function () {
          if ($scope.fechaDesde != undefined && $scope.fechaHasta != undefined && $scope.fechaDesde > $scope.fechaHasta) {
            notify({ message: $filter('translate')('FECHA_DESDE_MAYOR_FECHA_HASTA'), classes: 'alert-danger', position: 'right' });
          } else {
            if ($scope.almacen == undefined) {
              notify({ message: $filter('translate')('FILTRO_ALMACEN_REQUERIDO'), classes: 'alert-danger', position: 'right' });
            } else if ($scope.producto == undefined) {
              notify({ message: $filter('translate')('FILTRO_PRODUCTO_REQUERIDO'), classes: 'alert-danger', position: 'right' });
            } else if ($scope.fechaDesde == undefined) {
              notify({ message: 'Debe completar el filtro de Fecha Desde', classes: 'alert-danger', position: 'right' });
            } else if ($scope.fechaHasta == undefined) {
              notify({ message: 'Debe completar el filtro de Fecha Hasta', classes: 'alert-danger', position: 'right' });
            } else {
              var filtersArr = [];
              if ($scope.almacen) {
                filtersArr.push("almacen=" + $scope.almacen.id);
              }
              if ($scope.producto) {
                filtersArr.push("producto=" + $scope.producto.id);
              }
              if ($scope.fechaDesde) {
                var myDate = $filter('date')($scope.fechaDesde, "MM/dd/yyyy");
                filtersArr.push("fechaDesde=" + myDate);
              }
              if ($scope.fechaHasta) {
                var myDate = $filter('date')($scope.fechaHasta, "MM/dd/yyyy");
                filtersArr.push("fechaHasta=" + myDate);
              }
              if ($scope.unidad) {
                filtersArr.push("unidadMedida=" + $scope.unidad.id);
              }

              filtersArr.push("tipoReporte=" + $scope.tipoReporte.codigo);

              var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("consultaMovimientosProductos", 'pdf', filtersArr);
              $window.open(pdfDownloadURL, '_blank');
            }
          }
        }

        $scope.generarCSV = function () {

          if ($scope.FiltrosForm.$valid) {
            var filtersObj = {};
            if ($scope.almacen) {
              filtersObj.almacen = $scope.almacen.id;
            }
            if ($scope.producto) {
              filtersObj.producto = $scope.producto.id;
            }
            if ($scope.unidad) {
              filtersObj.unidad = $scope.unidad.id;
            }
            if ($scope.fechaDesde) {
              var myDate = $filter('date')($scope.fechaDesde, "MM/dd/yyyy");
              filtersObj.fechaDesde = myDate;
            }
            if ($scope.fechaHasta) {
              var myDate = $filter('date')($scope.fechaHasta, "MM/dd/yyyy");
              filtersObj.fechaHasta = myDate;
            }
            if ($scope.tipoReporte) {
              filtersObj.tipoReporte = $scope.tipoReporte.codigo;
            }

            FraccionamientoBobinasFactory.getCSV(filtersObj).then(function (response) {
              var fileName = response.data.fileName;
              $window.open(baseurl.getPublicBaseUrl() + fileName);
            });
          } else {
            var msg = $filter('translate')('ERROR_FILTERING_LIBRO_VENTA');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
          }
        };
      }, function (error) {
        console.log("error al traer translations: ");
        console.log(error);
      });
    }]);

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.notificaciones', ['ngResource', 'ui.tree', 'ngFileUpload'])
  .config(
  ['$stateProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$urlMatcherFactoryProvider',
    function($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider, $urlMatcherFactoryProvider) {
      $stateProvider
        .state('app.notificaciones', {
          url: '/notificaciones',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Notificaciones'
          }
        })
        .state('app.notificaciones.list', {
          url: '',
          templateUrl: 'qualita-components/notificaciones/views/list.html',
          controller: 'NotificacionesIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.notificaciones',
            label: 'Notificaciones',
            description: 'Listado de Notificaciones',
            skip: true
          }
        });
    }]);



'use strict';


angular.module('qualita.productos')
  .controller('NotificacionesIndexCtrl', NotificacionesIndexCtrl);

NotificacionesIndexCtrl.$inject = ["NotificacionesWSFactory", "$log", "$scope", "notify", "filterFactory", "ReportTicketFactory", "$window", "$rootScope"];

function NotificacionesIndexCtrl(NotificacionesWSFactory, $log, $scope, notify, filterFactory, ReportTicketFactory, $window, $rootScope) {
  var vm = this;

  vm.offset = 0;

  vm.loadMore = loadMore;

  vm.markAsRead = markAsRead;

  vm.toggleMarkSelectedButton = toggleMarkSelectedButton;

  activate();

  function activate() {
    NotificacionesWSFactory.registerMessageObserver(onNotificacionesRecibidas);
    NotificacionesWSFactory.getLatest(0, 20);
    vm.moreNotificationsPending = true;
  }

  function countNotificaciones() {
    var conteo = 0;
    _.forEach(vm.notificaciones, function(clave) {
      conteo = conteo + clave.length;
    });
    return conteo;
  }

  function onNotificacionesRecibidas(mensaje) {
    //$log.info("Notificaciones lista recibidas: ");
    //$log.debug("Se hizo trigger a onNotificacionRecibida de NotificacionesIndex");
    var msg = JSON.parse(mensaje.data);
    if(msg.tipo && msg.tipo === "list") {
      //$log.info("es una lista");
      if(msg.offset === 0) {
        vm.notificacionesArray = msg.notificaciones;
      }
      else if(msg.notificaciones.length > 0) {
        _.forEach(msg.notificaciones, function(notif) {
          vm.notificacionesArray.push(notif);
        })
      }
      else {
        //ya no se trajeron mas notificaciones, ocultar boton de anteriores
        vm.moreNotificationsPending = false;
      }
      groupNotificaciones();
    }
  }

  function groupNotificaciones() {
    //agrupamos las notificaciones por fecha
    vm.notificaciones =  _.groupBy(vm.notificacionesArray, function(notif) {
      return notif.fecha;
    });
  }

  function markAsRead(notificacion) {
    if(notificacion.estado.codigo !== "notificacion_leida") {
      NotificacionesWSFactory.sendAction("did_read", notificacion);
      notificacion.estado.codigo = "notificacion_leida";
    }

    /* Actualizar valor del $rootScope.notificaciones utilizado en el navbar*/

    for (var i = 0; i < $rootScope.notificaciones.length; i++) {
      if ($rootScope.notificaciones[i].id == notificacion.id) {
        $rootScope.notificaciones[i].estado.codigo = "notificacion_leida"
      }
    }
  }

  function loadMore() {
    vm.offset = countNotificaciones();
    NotificacionesWSFactory.getLatest(vm.offset, 20);
  }

  function toggleMarkSelectedButton(notificacion, valor) {
    if(valor==true && notificacion.estado.codigo==='notificacion_leida') {
      return;
    }
    notificacion.readButtonVisible = valor;
  }
}

/**
 * Created by codiumsa on 30/9/15.
 */
angular
  .module("qualita.compra", ["ngResource", "ui.tree", "ngFileUpload"])
  .config([
    "$stateProvider",
    function($stateProvider) {
      $stateProvider
        .state("app.tipoordencompra", {
          url: "/tipoordencompra",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Tipos de Orden de Compra"
          }
        })
        .state("app.tipoordencompra.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "TipoOrdenesCompraListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipoordencompra",
            label: "Tipo de Orden de Compra",
            description: "Listado de Tipos de Orden de Compra",
            skip: true
          }
        })
        .state("app.tipoordencompra.new", {
          url: "/new",
          templateUrl:
            "qualita-components/compra/views/tipoordencompra/form.html",
          controller: "TipoOrdenesCompraFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipoordencompra",
            label: "Nuevo Tipo de Orden de Compra",
            description: "Creación de un nuevo Tipo Orden de Compra"
          },
          resolve: {
            tipoOrdenCompraPrepService: function() {
              return;
            }
          }
        })
        .state("app.tipoordencompra.edit", {
          url: "/:id/edit",
          templateUrl:
            "qualita-components/compra/views/tipoordencompra/form.html",
          controller: "TipoOrdenesCompraFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipoordencompra",
            label: "Editar Tipo de Orden de Compra",
            description: "Edición del Tipo de Orden de Compra #"
          },
          resolve: {
            tipoOrdenCompraPrepService: tipoOrdenCompraCompraPrepService
          }
        })
        .state("app.tipoordencompra.view", {
          url: "/:id",
          templateUrl:
            "qualita-components/compra/views/tipoordencompra/form.html",
          controller: "TipoOrdenesCompraFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipoordencompra",
            label: "Ver Tipo de Orden de Compra",
            description: "Ver Tipo de Orden de Compra #"
          },
          resolve: {
            tipoOrdenCompraPrepService: tipoOrdenCompraCompraPrepService
          }
        })
        .state("app.comprador", {
          url: "/comprador",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Compradores"
          }
        })
        .state("app.comprador.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "CompradorListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.comprador",
            label: "Compradores",
            description: "Listado de Compradores",
            skip: true
          }
        })
        .state("app.comprador.new", {
          url: "/new",
          templateUrl: "qualita-components/compra/views/comprador/form.html",
          controller: "CompradorFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.comprador",
            label: "Nuevo Comprador",
            description: "Creación de un nuevo Comprador"
          },
          resolve: {
            compradorPrepService: function() {
              return;
            }
          }
        })
        .state("app.comprador.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/compra/views/comprador/form.html",
          controller: "CompradorFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.comprador",
            label: "Editar Comprador",
            description: "Edición del Comprador #"
          },
          resolve: {
            compradorPrepService: compradorCompraPrepService
          }
        })
        .state("app.comprador.view", {
          url: "/:id",
          templateUrl: "qualita-components/compra/views/comprador/form.html",
          controller: "CompradorFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.comprador",
            label: "Ver Comprador",
            description: "Ver Comprador #"
          },
          resolve: {
            compradorPrepService: compradorCompraPrepService
          }
        })
        .state("app.orden_compra", {
          url: "/ordenescompra",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Órdenes de Compra"
          }
        })
        .state("app.orden_compra.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "OrdenesCompraLocalListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.orden_compra",
            label: "Órdenes de Compra",
            description: "Listado de Órdenes de Compra",
            skip: true
          }
        })
        .state("app.orden_compra.new", {
          url: "/new",
          templateUrl:
            "qualita-components/compra/views/ordenescompra/form.html",
          controller: "OrdenesCompraLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.orden_compra",
            label: "Nueva Orden de Compra",
            description: "Creación de una nueva Orden de Compra"
          },
          resolve: {
            ordenCompraPrepService: function() {
              return;
            }
          }
        })
        .state("app.orden_compra.edit", {
          url: "/:id/edit",
          templateUrl:
            "qualita-components/compra/views/ordenescompra/form.html",
          controller: "OrdenesCompraLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.orden_compra",
            label: "Editar Orden de Compra",
            description: "Edición de la Orden de Compra #"
          },
          resolve: {
            ordenCompraPrepService: ordenCompraCompraPrepService
          }
        })
        .state("app.orden_compra.view", {
          url: "/:id",
          templateUrl:
            "qualita-components/compra/views/ordenescompra/form.html",
          controller: "OrdenesCompraLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.orden_compra",
            label: "Ver Orden de Compra",
            description: "Ver Orden de Compra #"
          },
          resolve: {
            ordenCompraPrepService: ordenCompraCompraPrepService
          }
        })
        .state("app.consultarecepciones", {
          url: "/recepciones",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Consulta de Recepciones"
          }
        })
        .state("app.consultarecepciones.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "RecepcionLocalListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.consultarecepciones",
            label: "Consulta de Recepciones",
            description: "Listado de Recepciones",
            skip: true
          }
        })
        .state("app.consultarecepciones.view", {
          url: "/:id",
          templateUrl: "qualita-components/compra/views/recepcion/form.html",
          controller: "RecepcionLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.consultarecepciones",
            label: "Ver Recepción Local",
            description: "Ver Recepción Local #"
          },
          resolve: {
            recepcionCompraPrepService: recepcionCompraPrepService
          }
        })
        .state("app.consultacomprobantes", {
          url: "/comprobantes",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Comprobantes"
          }
        })
        .state("app.consultacomprobantes.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "ComprobanteLocalListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.consultacomprobantes",
            label: "Consulta de Comprobantes",
            description: "Listado de Comprobantes",
            skip: true
          }
        })
        .state("app.consultacomprobantes.new", {
          url: "/new",
          templateUrl: "qualita-components/compra/views/comprobante/form.html",
          controller: "ComprobanteLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.consultacomprobantes",
            label: "Nuevo Comprobante",
            description: "Creación de un nuevo comprobante"
          },
          resolve: {
            comprobantePrepService: function() {
              return;
            }
          }
        })
        .state("app.consultacomprobantes.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/compra/views/comprobante/form.html",
          controller: "ComprobanteLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.consultacomprobantes",
            label: "Editar Comprobante",
            description: "Edición del comprobante #"
          },
          resolve: {
            comprobantePrepService: comprobanteCompraPrepService
          }
        })
        .state("app.consultacomprobantes.view", {
          url: "/:id",
          templateUrl: "qualita-components/compra/views/comprobante/form.html",
          controller: "ComprobanteLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.consultacomprobantes",
            label: "Ver Comprobante",
            description: "Ver Comprobante #"
          },
          resolve: {
            comprobantePrepService: comprobanteCompraPrepService
          }
        })
        .state("app.consultacomprobantes.apply", {
          url: "/:id/apply?mode",
          templateUrl:
            "qualita-components/importacion/views/comprobante/aplicacion.html",
          controller: "AplicacionComprobanteFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.consultacomprobantes",
            label: "Aplicar Comprobante"
          },
          resolve: {
            comprobantePrepService: comprobanteAplicarPrepService
          }
        })
        .state("app.consultacomprobantes.editAprobado", {
          url: "/:id/editAprobado",
          templateUrl: "qualita-components/compra/views/comprobante/form.html",
          controller: "ComprobanteLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.consultacomprobantes",
            label: "Editar Comprobante Aprobado",
            description: "Edición del comprobante Aprobado #"
          },
          resolve: {
            comprobantePrepService: comprobanteCompraPrepService
          }
        })
        .state("app.mensajedifusion", {
          url: "/mensajedifusion",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Mensajes de Difusión General a Proveedores"
          }
        })
        .state("app.mensajedifusion.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "MensajeDifusionListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.mensajedifusion",
            label: "Mensajes de Difusión General a Proveedores",
            description:
              "Listado de Mensajes de Difusión General a Proveedores",
            skip: true
          }
        })
        .state("app.mensajedifusion.new", {
          url: "/new",
          templateUrl:
            "qualita-components/compra/views/mensajedifusion/form.html",
          controller: "MensajeDifusionFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.mensajedifusion",
            label: "Nuevo Mensaje de Difusión General a Proveedores",
            description:
              "Creación de un nuevo Mensaje de Difusión General a Proveedores"
          },
          resolve: {
            mensajeDifusionPrepService: function() {
              return;
            }
          }
        })
        .state("app.mensajedifusion.edit", {
          url: "/:id/edit",
          templateUrl:
            "qualita-components/compra/views/mensajedifusion/form.html",
          controller: "MensajeDifusionFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.mensajedifusion",
            label: "Editar Mensaje de Difusión General a Proveedores",
            description:
              "Edición del Mensaje de Difusión General a Proveedores #"
          },
          resolve: {
            mensajeDifusionPrepService: mensajeDifusionCompraPrepService
          }
        })
        .state("app.mensajedifusion.view", {
          url: "/:id",
          templateUrl:
            "qualita-components/compra/views/mensajedifusion/form.html",
          controller: "MensajeDifusionFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.mensajedifusion",
            label: "Ver Mensaje de Difusión General a Proveedores",
            description: "Ver Mensaje de Difusión General a Proveedores #"
          },
          resolve: {
            mensajeDifusionPrepService: mensajeDifusionCompraPrepService
          }
        })
        .state("app.rubroiva", {
          url: "/rubroiva",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Rubros de Iva"
          }
        })
        .state("app.rubroiva.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "RubroIvaListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.rubroiva",
            label: "Rubros de Iva",
            description: "Listado de Rubros de Iva",
            skip: true
          }
        })
        .state("app.rubroiva.new", {
          url: "/new",
          templateUrl: "qualita-components/compra/views/rubroiva/form.html",
          controller: "RubroIvaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.rubroiva",
            label: "Nuevo Rubro de Iva",
            description: "Creación de un nuevo Rubro de Iva"
          },
          resolve: {
            rubroIvaPrepService: function() {
              return;
            }
          }
        })
        .state("app.rubroiva.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/compra/views/rubroiva/form.html",
          controller: "RubroIvaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.rubroiva",
            label: "Editar Rubro de Iva",
            description: "Edición del Rubro de Iva #"
          },
          resolve: {
            rubroIvaPrepService: rubroIvaCompraPrepService
          }
        })
        .state("app.rubroiva.view", {
          url: "/:id",
          templateUrl: "qualita-components/compra/views/rubroiva/form.html",
          controller: "RubroIvaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.rubroiva",
            label: "Ver Rubro de Iva",
            description: "Ver Rubro de Iva #"
          },
          resolve: {
            rubroIvaPrepService: rubroIvaCompraPrepService
          }
        })
        .state("app.formapago", {
          url: "/formapago",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Formas de Pago"
          }
        })
        .state("app.formapago.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "FormaPagoListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.formapago",
            label: "Formas de Pago",
            description: "Listado de Formas de Pago",
            skip: true
          }
        })
        .state("app.formapago.new", {
          url: "/new",
          templateUrl: "qualita-components/compra/views/formapago/form.html",
          controller: "FormaPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.formapago",
            label: "Nueva Forma de Pago",
            description: "Creación de una nueva Forma de Pago"
          },
          resolve: {
            formaPagoPrepService: function() {
              return;
            }
          }
        })
        .state("app.formapago.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/compra/views/formapago/form.html",
          controller: "FormaPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.formapago",
            label: "Editar Forma de Pago",
            description: "Edición de la Forma de Pago #"
          },
          resolve: {
            formaPagoPrepService: formaPagoCompraPrepService
          }
        })
        .state("app.formapago.view", {
          url: "/:id",
          templateUrl: "qualita-components/compra/views/formapago/form.html",
          controller: "FormaPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.formapago",
            label: "Ver Forma de Pago",
            description: "Ver Forma de Pago #"
          },
          resolve: {
            formaPagoPrepService: formaPagoCompraPrepService
          }
        })
        .state("app.diferencia", {
          url: "/diferencia",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Consulta Libro Compra"
          }
        })
        .state("app.librocompra", {
          url: "/librocompra",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Consulta Libro Compra"
          }
        })
        .state("app.librocompra.list", {
          url: "",
          templateUrl:
            "qualita-components/compra/views/consultalibrocompra/consulta.html",
          controller: "ConsultaLibroCompraIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.diferencia",
            label: "Consulta Libro Compra",
            description: "Generación de Reporte de Libro Compra",
            skip: true
          }
        })
        .state("app.compraslocales", {
          url: "/compras",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Compras"
          }
        })
        .state("app.compraslocales.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "CompraIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales",
            label: "Compras",
            description: "Listado de Compras",
            skip: true
          }
        })
        .state("app.compraslocales.proceso", {
          url: "/proceso/:codigoCompra",
          templateUrl: "qualita-components/compra/views/compra/wizard.html",
          controller: "CompraProcesoCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales",
            label: "Proceso"
          },
          resolve: {
            compraPrepService: compraPrepService
          }
        })
        .state("app.compraslocales.proceso.ordenescompra", {
          url: "/ordenescompra",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso",
            label: "Ordenes de Compra"
          }
        })
        .state("app.compraslocales.proceso.ordenescompra.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "OrdenesCompraLocalListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.ordenescompra",
            label: "Ordenes de Compra",
            skip: true
          }
        })
        .state("app.compraslocales.proceso.ordenescompra.new", {
          url: "/new",
          templateUrl:
            "qualita-components/compra/views/ordenescompra/form.html",
          controller: "OrdenesCompraLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.ordenescompra",
            label: "Nueva Orden de Compra"
          },
          resolve: {
            ordenCompraPrepService: function() {
              return;
            }
          }
        })
        .state("app.compraslocales.proceso.ordenescompra.edit", {
          url: "/:id/edit",
          templateUrl:
            "qualita-components/compra/views/ordenescompra/form.html",
          controller: "OrdenesCompraLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.ordenescompra",
            label: "Editar Orden de Compra"
          },
          resolve: {
            ordenCompraPrepService: ordenCompraCompraPrepService
          }
        })
        .state("app.compraslocales.proceso.ordenescompra.view", {
          url: "/:id",
          templateUrl:
            "qualita-components/compra/views/ordenescompra/form.html",
          controller: "OrdenesCompraLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.ordenescompra",
            label: "Ver Orden de Compra"
          },
          resolve: {
            ordenCompraPrepService: ordenCompraCompraPrepService
          }
        })
        .state("app.compraslocales.proceso.comprobante", {
          url: "/comprobantes",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso",
            label: "Comprobantes"
          }
        })
        .state("app.compraslocales.proceso.comprobante.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "ComprobanteLocalListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.comprobante",
            label: "Comprobantes",
            skip: true
          }
        })
        .state("app.compraslocales.proceso.comprobante.new", {
          url: "/new",
          templateUrl: "qualita-components/compra/views/comprobante/form.html",
          controller: "ComprobanteLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.comprobante",
            label: "Nuevo Comprobante"
          },
          resolve: {
            comprobantePrepService: function() {
              return;
            }
          }
        })
        .state("app.compraslocales.proceso.comprobante.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/compra/views/comprobante/form.html",
          controller: "ComprobanteLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.comprobante",
            label: "Editar Comprobante"
          },
          resolve: {
            comprobantePrepService: comprobanteCompraPrepService
          }
        })
        .state("app.compraslocales.proceso.comprobante.view", {
          url: "/:id",
          templateUrl: "qualita-components/compra/views/comprobante/form.html",
          controller: "ComprobanteLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.comprobante",
            label: "Ver Comprobante"
          },
          resolve: {
            comprobantePrepService: comprobanteCompraPrepService
          }
        })
        .state("app.compraslocales.proceso.comprobante.apply", {
          url: "/:id/apply?mode",
          templateUrl:
            "qualita-components/importacion/views/comprobante/aplicacion.html",
          controller: "AplicacionComprobanteFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.comprobante",
            label: "Aplicar Comprobante"
          },
          resolve: {
            comprobantePrepService: comprobanteAplicarPrepService
          }
        })
        .state("app.compraslocales.proceso.comprobante.planpagos", {
          url: "/:idComprobante/planpagos/:idPlanPago/cuotas",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.comprobante",
            label: "Plan de Pagos"
          }
        })
        .state("app.compraslocales.proceso.comprobante.planpagos.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "PlanPagosDetalleIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.comprobante.planpagos",
            label: "Plan de Pagos",
            skip: true
          }
        })
        .state("app.compraslocales.proceso.comprobante.planpagos.new", {
          url: "/new",
          templateUrl:
            "qualita-components/cuentasapagar/views/planpagosdetalle/form.html",
          controller: "PlanPagosDetalleFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.comprobante.planpagos",
            label: "Plan de Pagos"
          },
          resolve: {
            planPagosDetallePrepService: function() {
              return;
            }
          }
        })
        .state("app.compraslocales.proceso.comprobante.planpagos.edit", {
          url: "/:id/edit",
          templateUrl:
            "qualita-components/cuentasapagar/views/planpagosdetalle/form.html",
          controller: "PlanPagosDetalleFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.comprobante.planpagos",
            label: "Editar Plan de Pago"
          },
          resolve: {
            planPagosDetallePrepService: planPagosCompraLocalPrepService
          }
        })
        .state("app.compraslocales.proceso.comprobante.planpagos.view", {
          url: "/:id",
          templateUrl:
            "qualita-components/cuentasapagar/views/planpagosdetalle/form.html",
          controller: "PlanPagosDetalleFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.comprobante.planpagos",
            label: "Ver Plan de Pago"
          },
          resolve: {
            planPagosDetallePrepService: planPagosCompraLocalPrepService
          }
        })
        .state("app.compraslocales.proceso.recepcion", {
          url: "/recepciones",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso",
            label: "Recepciones"
          }
        })
        .state("app.compraslocales.proceso.recepcion.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "RecepcionLocalListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.recepcion",
            label: "Recepciones",
            skip: true
          }
        })
        .state("app.compraslocales.proceso.recepcion.new", {
          url: "/new",
          templateUrl: "qualita-components/compra/views/recepcion/form.html",
          controller: "RecepcionLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.recepcion",
            label: "Nueva Recepción"
          },
          resolve: {
            recepcionCompraPrepService: function() {
              return;
            }
          }
        })
        .state("app.compraslocales.proceso.recepcion.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/compra/views/recepcion/form.html",
          controller: "RecepcionLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.recepcion",
            label: "Editar Recepción"
          },
          resolve: {
            recepcionCompraPrepService: recepcionCompraPrepService
          }
        })
        .state("app.compraslocales.proceso.recepcion.view", {
          url: "/:id",
          templateUrl: "qualita-components/compra/views/recepcion/form.html",
          controller: "RecepcionLocalFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.recepcion",
            label: "Ver Recepción"
          },
          resolve: {
            recepcionCompraPrepService: recepcionCompraPrepService
          }
        })
        .state("app.compraslocales.proceso.cierre", {
          url: "/cierre",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso",
            label: "Cierre"
          }
        })
        .state("app.compraslocales.proceso.cierre.list", {
          url: "",
          templateUrl: "qualita-components/compra/views/compra/cierre.html",
          controller: "ConsultaDiferenciaIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.compraslocales.proceso.cierre",
            label: "Cierre",
            description: "Cierre",
            skip: true
          }
        })
        .state("app.proyecto", {
          url: "/proyecto",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Proyecto"
          }
        })
        .state("app.proyecto.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "ProyectoListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.proyecto",
            label: "Proyecto",
            description: "Listado de Proyectos",
            skip: true
          }
        })
        .state("app.proyecto.new", {
          url: "/new",
          templateUrl: "qualita-components/compra/views/proyecto/form.html",
          controller: "ProyectoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.proyecto",
            label: "Nueva Proyecto",
            description: "Creación de un nuevo Proyecto"
          },
          resolve: {
            proyectoPrepService: function() {
              return;
            }
          }
        })
        .state("app.proyecto.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/compra/views/proyecto/form.html",
          controller: "ProyectoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.proyecto",
            label: "Editar Proyecto",
            description: "Edición de Proyecto #"
          },
          resolve: {
            proyectoPrepService: proyectoPrepService
          }
        })
        .state("app.proyecto.view", {
          url: "/:id",
          templateUrl: "qualita-components/compra/views/proyecto/form.html",
          controller: "ProyectoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.proyecto",
            label: "Ver Proyecto",
            description: "Ver Proyecto #"
          },
          resolve: {
            proyectoPrepService: proyectoPrepService
          }
        })
        .state("app.tiposcompras", {
          url: "/tipocompra",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Tipos de Compra"
          }
        })
        .state("app.tiposcompras.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "TipoCompraListCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tiposcompras",
            label: "Tipo de Compra",
            description: "Listado de Tipos de Compra",
            skip: true
          }
        })
        .state("app.tiposcompras.new", {
          url: "/new",
          templateUrl: "qualita-components/compra/views/tipocompra/form.html",
          controller: "TipoCompraFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tiposcompras",
            label: "Nuevo Tipo de Compra",
            description: "Creación de un nuevo Tipo Compra"
          },
          resolve: {
            tipoCompraPrepService: function() {
              return;
            }
          }
        })
        .state("app.tiposcompras.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/compra/views/tipocompra/form.html",
          controller: "TipoCompraFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tiposcompras",
            label: "Editar Tipo de Compra",
            description: "Edición del Tipo de Compra #"
          },
          resolve: {
            tipoCompraPrepService: tipoCompraPrepService
          }
        })
        .state("app.tiposcompras.view", {
          url: "/:id",
          templateUrl: "qualita-components/compra/views/tipocompra/form.html",
          controller: "TipoCompraFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tiposcompras",
            label: "Ver Tipo de Compra",
            description: "Ver Tipo de Compra #"
          },
          resolve: {
            tipoCompraPrepService: tipoCompraPrepService
          }
        })
        .state("app.consultacompras", {
          url: "/consultacompras",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Consulta de Compras"
          }
        })
        .state("app.consultacompras.list", {
          url: "",
          templateUrl:
            "qualita-components/compra/views/consultacompraproducto/consulta.html",
          controller: "ConsultaCompraIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.consultacompras",
            label: "Consulta de Compras",
            description: "Listado de Compras",
            skip: true
          }
        });
    }
  ]);

function tipoCompraPrepService(TipoCompraFactory, $stateParams) {
  return TipoCompraFactory.get($stateParams.id, 'TipoCompraForm').$promise;
}
tipoCompraPrepService.$inject = ["TipoCompraFactory", "$stateParams"];

function tipoOrdenCompraCompraPrepService(
  TipoOrdenesCompraFactory,
  $stateParams
) {
  return TipoOrdenesCompraFactory.get($stateParams.id, 'TipoOrdenCompraForm').$promise;
}
tipoOrdenCompraCompraPrepService.$inject = ["TipoOrdenesCompraFactory", "$stateParams"];

function compradorCompraPrepService(CompradorFactory, $stateParams) {
  return CompradorFactory.get($stateParams.id, "CompradorForm").$promise;
}
compradorCompraPrepService.$inject = ["CompradorFactory", "$stateParams"];

function mensajeDifusionCompraPrepService(
  MensajeDifusionFactory,
  $stateParams
) {
  return MensajeDifusionFactory.get($stateParams.id, 'MensajeDifusionForm').$promise;
}
mensajeDifusionCompraPrepService.$inject = ["MensajeDifusionFactory", "$stateParams"];

function ordenCompraCompraPrepService(OrdenesCompraFactory, $stateParams) {
  return OrdenesCompraFactory.get($stateParams.id, "OrdenCompraForm").$promise;
}
ordenCompraCompraPrepService.$inject = ["OrdenesCompraFactory", "$stateParams"];

function rubroIvaCompraPrepService(RubroIvaFactory, $stateParams) {
  return RubroIvaFactory.get($stateParams.id, 'RubroIvaForm').$promise;
}
rubroIvaCompraPrepService.$inject = ["RubroIvaFactory", "$stateParams"];

function formaPagoCompraPrepService(FormaPagoFactory, $stateParams) {
  return FormaPagoFactory.get($stateParams.id, 'FormaPagoForm').$promise;
}
formaPagoCompraPrepService.$inject = ["FormaPagoFactory", "$stateParams"];

function proyectoPrepService(ProyectoFactory, $stateParams) {
  return ProyectoFactory.get($stateParams.id, 'ProyectoForm').$promise;
}
proyectoPrepService.$inject = ["ProyectoFactory", "$stateParams"];

function comprobanteCompraPrepService(ComprobanteFactory, $stateParams) {
  return ComprobanteFactory.get($stateParams.id, "ComprobanteForm").$promise;
}
comprobanteCompraPrepService.$inject = ["ComprobanteFactory", "$stateParams"];

function recepcionCompraPrepService(RecepcionFactory, $stateParams) {
  return RecepcionFactory.get($stateParams.id, "RecepcionForm").$promise;
}
recepcionCompraPrepService.$inject = ["RecepcionFactory", "$stateParams"];

function comprobanteAplicarPrepService(ComprobanteFactory, $stateParams) {

  return ComprobanteFactory.get($stateParams.id, "AplicacionCompraComprobanteForm")
  .$promise;
}
comprobanteAplicarPrepService.$inject = ["ComprobanteFactory", "$stateParams"];

function planPagosCompraLocalPrepService(
  PlanPagosDetalleFactory,
  $stateParams
) {
  return PlanPagosDetalleFactory.get($stateParams.id, "detail").$promise;
}
planPagosCompraLocalPrepService.$inject = ["PlanPagosDetalleFactory", "$stateParams"];

function compraPrepService(CompraFactory, TipoCompraFactory, $stateParams) {
  var promise = CompraFactory.get($stateParams.codigoCompra, "CompraList")
    .$promise;
  promise.then(function(compra) {
    CompraFactory.setIdCompra(compra.id);
    CompraFactory.setCodigoCompra(compra.codigo);
    CompraFactory.setCompra(compra);
  });
  return promise;
}
compraPrepService.$inject = ["CompraFactory", "TipoCompraFactory", "$stateParams"];

"use strict";

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module("qualita.compra").factory("CompraLocalLangFactory", [
  "$resource",
  "baseurl",
  "$http",
  "$translatePartialLoader",
  "$translate",
  function($resource, baseurl, $http, $translatePartialLoader, $translate) {
    var translationsNeeded = [
      "TIPOS_ORDEN_COMPRA",
      "TIPO_ORDEN_COMPRA",
      "FAIL_DELETE_TIPO_ORDEN_COMPRA",
      "INCLUYE_SERVICIO",
      "INCLUYE_PRODUCTO",
      "NOMBRE",
      "CODE",
      "NEW_TIPO_ORDEN_COMPRA",
      "EDIT_TIPO_ORDEN_COMPRA",
      "VIEW_TIPO_ORDEN_COMPRA",
      "TIPO_ORDEN_COMPRA_ALREADY_EXISTS",
      "INCLUDES_ITEM",
      "INCLUDES_SERVICE",
      "DELETE_FAILED",
      "ACTIVE",
      "LONG_VALUE_WARNING",
      "MONTO_MAXIMO_COMPRA",
      "CANTIDAD_MAXIMA_COMPRA",
      "PERIOD",
      "DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS",
      "REQUIRED_FIELD",
      "REQUIRED_FIELDS",
      "NEW_COMPRADOR",
      "EDIT_COMPRADOR",
      "VIEW_COMPRADOR",
      "MONTO_MAXIMO_COMPRA_PERIODO",
      "CANTIDAD_MAXIMA_COMPRA_OC",
      "PERIOD_IN_DAYS",
      "RUBROS_IVA",
      "RUBRO_IVA",
      "NEW_RUBRO_IVA",
      "EDIT_RUBRO_IVA",
      "VIEW_RUBRO_IVA",
      "DESCRIPTION",
      "RUBRO_IVA_MISMA_DESCRIPCION_WARNING",
      "NEW_FORMA_PAGO",
      "EDIT_FORMA_PAGO",
      "VIEW_FORMA_PAGO",
      "FORMAS_PAGO",
      "FORMA_PAGO",
      "FORMA_PAGO_MISMA_DESCRIPCION_WARNING",
      "MENSAJES_DIFUSION_PROVEEDORES",
      "MENSAJE_DIFUSION_PROVEEDORES",
      "NEW_MENSAJE_DIFUSION_PROVEEDORES",
      "EDIT_MENSAJE_DIFUSION_PROVEEDORES",
      "VIEW_MENSAJE_DIFUSION_PROVEEDORES",
      "MESSAGE",
      "DATE_FROM",
      "DATE_TO",
      "ORDENES_COMPRA",
      "ORDEN_COMPRA",
      "NEW_ORDEN_COMPRA",
      "EDIT_ORDEN_COMPRA",
      "VIEW_ORDEN_COMPRA",
      "NUMBER",
      "TYPE",
      "DATE",
      "PROVEEDOR",
      "SUCURSAL",
      "STATUS",
      "CURRENCY",
      "NEW",
      "CONSOLIDAR",
      "SELECCIONAR_ORDENES_COMPRA_WARNING",
      "CONSOLIDAR_ORDENES_COMPRA_Q",
      "PERMANENT_OPERATION_WARNING",
      "CONFIRMACION_ANULACION",
      "CAMBIO_ESTADO_ORDEN_COMPRA_ANULADA_WARNING",
      "OK",
      "CANCEL",
      "ID",
      "LINE",
      "BRAND",
      "ITEM",
      "SERVICE",
      "DESCOUNT",
      "ERROR_SAVING",
      "BACK",
      "SUCURSAL_PROVEEDOR",
      "TIPO_CAMBIO",
      "FECHA_ESPERADA",
      "ALMACEN",
      "SHOW_ALL_ITEMS",
      "DETALLES_ORDEN_COMPRA",
      "ITEM_SLASH_SERVICE",
      "MEASURE_UNIT",
      "AMOUNT",
      "PRICE",
      "PORCENTAJE_DESCUENTO",
      "TOTAL_DESCUENTO",
      "TOTAL_ITEM",
      "CODIGO_INTERNO",
      "CODIGO_BARRAS_PRINCIPAL",
      "ADD",
      "DELETE_CONFIRMATION",
      "NEW_RECEPCION_LOCAL",
      "EDIT_RECEPCION_LOCAL",
      "VIEW_RECEPCION_LOCAL",
      "GENERAR_REPORTE_RECEPCION",
      "PEDIDO_FUERA_ORDEN_COMPRA_WARNING",
      "FECHA_NO_EN_RANGO_WARNING",
      "PORCENTAJE_RECEPCION_INFERIOR_LIMITE_WARNING",
      "CANTIDAD_RECEPCION_MAYOR_QUE_ORDEN_COMPRA_WARNING",
      "CANTIDAD_RECEPCION_MENOR_QUE_ORDEN_COMPRA_WARNING",
      "TIPO_RECEPCION",
      "MOSTRAR_UNIDAD_BASE",
      "RECEPCION_DETALLES",
      "TIPO_IMPUESTO",
      "CANTIDAD_RECEPCIONADA",
      "CANTIDAD_BONIFICACION",
      "DESCOUNT",
      "NUMERO_GRUPO",
      "UNIDADES_RECEPCIONADAS",
      "CANTIDAD_UNIDAD_BASE",
      "CONFIRMAR_COMPROBANTE",
      "CONFIRMAR_COMPROBANTE_Q",
      "CONFIRMAR_COMPROBANTE_SERVICIOS_Q",
      "NUMERO_TIMBRADO_NO_ASOCIADO_PROVEEDOR",
      "DIGITOS_COMPROBANTE_NUMERO_EMISION_MAL",
      "DIGITOS_COMPROBANTE_SUURSAL_MAL",
      "FORMATO_NUMERO_COMPROBANTE_INCORRECTO",
      "NUMERO_GRUPO_REPETIDO_PRODUCTO",
      "GRUPO_COMPRA",
      "RECEPCION",
      "TIPO_COMPROBANTE",
      "NUMERO_COMPROBANTE",
      "NUMERO_TIMBRADO",
      "TRANSACCION",
      "CANTIDAD_CUOTAS",
      "PLAZO_CUOTAS",
      "ENTREGA_INICIAL",
      "TOTAL",
      "BASE_IMPONIBLE",
      "TAX",
      "TOTAL_AMOUNT",
      "FECHA_VENCIMIENTO",
      "VALOR_IMPUESTO",
      "CONSULTA_MOVIMIENTOS_STOCK",
      "COMPROBANTE",
      "CODIGO_PRODUCTO",
      "ORDEN_COMPRA_CANTIDAD",
      "ORDEN_COMPRA_IMPORTE",
      "COMPROBANTE_CANTIDAD",
      "COMPROBANTE_IMPORTE",
      "NEW_COMPROBANTE",
      "VIEW_COMPROBANTE",
      "EDIT_COMPROBANTE",
      "MONTOS_TOTALES_SUM_DETALLES_WARNING",
      "CONSULTA_LIBRO_COMPRA",
      "FECHA_INICIO",
      "FECHA_FIN",
      "GENERAR_LIBRO_COMPRA",
      "GENERAR_CSV",
      "ERROR_FILTERING",
      "ERROR_FILTERING_LIBRO_COMPRA",
      "KIND",
      "INVALID_DATE",
      "APLICACION_COMPROBANTE",
      "PREVISUALIZAR_APLICACION",
      "APLICACION_MAYOR_TOTAL_ERROR",
      "APLICACION_IMPUESTO_MAYOR_SALDO",
      "MONTO_MAYOR_SALDO_COMPROBANTE",
      "MONTO_MENOR_IGUAL_CERO",
      "MONTO_MAYOR_SALDO_ACTUAL",
      "MONTO_MAYOR_SALDO_CUOTA",
      "MONTO_MAYOR_SALDO_ACTUAL",
      "NO_COMPROBANTES_APLICABLES",
      "APLICACION_YA_EXISTENTE",
      "EDITAR_APLICACION",
      "AGREGAR_APLICACION",
      "QUE_DESEA_Q",
      "INVALID_DATES",
      "MONTO",
      "SALDO_PENDIENTE",
      "EMITIR_ORDEN_COMPRA_Q",
      "OC_LOCAL_INVALID_DATES",
      "DEBE_INDICAR_NUMERO_VALIDO",
      "COMPROBANTES",
      "RECEPCIONES",
      "CODIGO_COMPRA",
      "ES_IMPORTACION",
      "NEW_COMPRA",
      "CIERRE_COMPRA",
      "COMPRAS",
      "EDIT",
      "DELETE",
      "VIEW",
      "APLICAR_COMPROBANTE",
      "PLAN_PAGOS",
      "ANULAR",
      "CONFIRMAR_RECEPCION",
      "DEBE_INDICAR_ENTERO_VALIDO",
      "COMPRADOR",
      "COMPRADOR_NOT_FOUND",
      "CERRAR_OC_WARNING",
      "CERRAR_CONFIRMATION",
      "DELETE_WARNING",
      "NO_TRANSACCION_INGRESO_DIRECTO_FOUND",
      "RELACIONES_ORDEN_COMPRA_PRESENTES",
      "RECEP_COMP_APROBADO",
      "TODAS_RECEPCIONES_MISMO_PROVEEDOR_WARNING",
      "MONTOS_TOTALES_SUM_DETALLES_WARNING_COMPRA_LOCAL",
      "TIMBRADO_PROVEEDOR",
      "NAME",
      "IMPUESTOS_TOTALES_SUM_DETALLES_WARNING_COMPRA_LOCAL",
      "OC_LOCAL_INVALID_MONTO",
      "NO_TIMBRADO_PROVEEDOR_FOUND",
      "RECEPCION_CANTIDAD",
      "COMPRA_DELETE_CHECK_COMPROBANTES",
      "CERRAR_OC",
      "CERRAR_ORDENES_COMPRA_Q",
      "NEW_PROYECTO",
      "EDIT_PROYECTO",
      "VIEW_PROYECTO",
      "PROYECTO",
      "DATE_APERTURA",
      "DATE_CIERRE",
      "IMPUESTO_CABECERA_REPETIDO",
      "FECHA_INGRESO_STOCK",
      "CON_PRECIO",
      "SIN_PRECIO",
      "IMPRIMIR_SC",
      "IMPRIMIR_SC_MSG",
      "IMPRIMIR_FAILED",
      "RECHAZAR_SC",
      "RECHAZAR_SC_MSG",
      "APROBAR_SC",
      "APROBAR_SC_MSG",
      "ESPECIFICAR_PARAMETROS_TIPO_OC_W",
      "ANULAR_COMPRA",
      "ANULAR_COMPRA_Q",
      "ANULAR_COMPRA_MSG",
      "ANULAR_COMPRA_FAILED",
      "CERRAR_OC_CON_COMPRA",
      "CERRAR_OC_CON_COMPRA_MSG",
      "TODAS_RECEPCIONES_MISMO_ALMACEN_WARNING",
      "CONSULTA_PAGOS",
      "CONSULTA_FF",
      "CONSULTA_APLICACIONES",
      "DESAPROBAR_COMPROBANTE",
      "DESAPROBAR_COMPROBANTE_Q",
      "DESAPROBAR_COMPROBANTE_SERVICIOS_Q",
      "TIPO_ORDEN_PAGO",
      "IMPORTE",
      "NROCOMP_PROVEEDOR_TIMBRADO_TIPO_REPETIDO",
      "NO_ADMINISTRATIVE_PERIOD",
      "HECHAUKA_COMPRAS",
      "EXPORTAR_HECHAUKA_COMPRAS",
      "SELECCION_RANGO_HECHAUKA",
      "DATE_FROM",
      "DATE_TO",
      "EXPORTAR_HECHUAKA_ERROR",
      "DUPLICATED_PRODUCT_ERROR",
      "COMPROBANTE_APROBADO_EXIST",
      "DESCRIPCION",
      "COMPRA_REL",
      "IMPORTACION_REL",
      "TIPOS_COMPRA",
      "FAIL_DELETE_TIPO_COMPRA",
      "FECHA_INICIO_VIGENCIA",
      "FECHA_FIN_VIGENCIA",
      "NEW_TIPO_COMPRA",
      "EDIT_TIPO_COMPRA",
      "VIEW_TIPO_COMPRA",
      "ERROR_NINGUN_COMP_GENERA_MOVIMIENTO",
      "TIPO_COMPRA_ALREADY_EXISTS",
      "TIPO_COMPRA",
      "TIENE_DIFERENCIA",
      "NRO_COMPROBANTE",
      "CANTIDAD",
      "PRECIO_TOTAL_ITEM",
      "CODE_COMPRA",
      "CODE_IMPORTACION",
      "ESTADO",
      "MENSAJE_NO_PRODUCTO",
      "MENSAJE_NO_TIPO",
      "TOTAL_GENERAL",
      "CONSULTA_COMPRAS_PRODUCTO",
      "UTILIZAR_EN_COBROS",
      "MONTO_INICIAL_MAYOR_MONTO_TOTAL",
      "MONEDA",
      "ERROR_NOTA_DE_CREDICO_BOBINA",
      "MONTO_APLICADO",
      "CLASE_PRODUCTO",
      "DESAPLICAR",
      "DESAPLICAR_Q",
      "FILTRO_SELECCIONAR_PROVEEDOR",
      "ERROR_FECHA_PASADA",
      "ERROR_FECHA_FUTURA",
      "PRECIO",
      "TOTAL_IMPUESTO",
      "TIPO_FONDO_FIJO",
      "TOTAL_DESCUENTO_FINAL",
      "CANT_MAYOR_OC_DISABLE",
      "CANT_MENOR_OC_DISABLE",
      "PORCENTAJE_RECIBIDO_SUPERADO",
      "ITEM_TIPO_PRODUCTO",
      "CAMBIO_ESTADO_RECEPCION_ANULADA_WARNING",
      "CONFIRMAR_COMPROBANTE_RECEPCION,",
      "CONFIRMAR_COMPROBANTE_RECEPCION_TITLE",
      "IMPORTE_RETENIDO_CONSULTA",
      "NO_DETAILS_ERROR",
      "CERRAR_COMPRA_TITLE",
      "CERRAR_COMPRA_MESSAGE",
      "PRECIO_ERROR",
      "ERROR_TIPO_CAMBIO_COMPRAS",
      "DESAPROBAR_COMPROBANTE_RECEPCION",
      "DESCRIPCION_COMPROBANTE",
      "REIMPRIMIR_COMPROBANTE",
      "REIMPRIMIR_COMPROBANTE_Q",
      "CONSULTA_COMPROBANTE_PENDIENTE",
      "NO_CUMPLE_CONDICION_IMPUESTOS",
      "IMPUTA_IVA_GENERAL",
      "IMPUTA_IRE",
      "IMPUTA_IRP_RSP",
      "NO_IMPUTAR",
      "IMPUTACION_OBLIGACIONES_ASOCIADAS",
      "EXPORTAR_ARCHIVO_COMPRAS",
      "ARCHIVO_COMPRAS",
      "ERROR_NO_IMPUTA",
      "VIEW_DESCRIPCION_IN_OC",
      "ERROR_NRO_RUBRICA",
      "NRO_SOLICITUD_COMPRA",
      "CERRAR_SOL_COMRPRA_ASOCIADA_TITLE",
      "CERRAR_SOL_COMRPRA_ASOCIADA_MESSAGE"
    ];

    return {
      getTranslations: function() {
        $translatePartialLoader.addPart("compra");
        return $translate.refresh().then(function() {
          return $translate(translationsNeeded);
        });
      }
    };
  }
]);
'use strict';

angular.module('qualita.compra')
  .controller('TipoOrdenesCompraListCtrl', ["AuthorizationService", "TipoOrdenesCompraFactory", "TiposFactory", "$log", "$modal", "$scope", "CompraLocalLangFactory", "$filter", "filterFactory", "ReportTicketFactory", "$window", "UtilFactory", "CsvFactory", "baseurl", function TipoOrdenesCompraListCtrl(AuthorizationService, TipoOrdenesCompraFactory,
    TiposFactory, $log, $modal, $scope, CompraLocalLangFactory, $filter, filterFactory, ReportTicketFactory, $window,
    UtilFactory, CsvFactory, baseurl) {

  var dateRender =  function(data, type, full) {
    if(data) {
        return moment.utc(data).format('DD/MM/YYYY');
    } else {
      return "";
    }
    };

  CompraLocalLangFactory.getTranslations().then(function(translations) {
    var defaultColumnOrder = ['id','nombre','incluyeProducto','incluyeServicio', 'esImportacion','activo'];

    $scope.options = {
      'resource': 'tipoordencompra',
      'title': $filter('translate')('TIPOS_ORDEN_COMPRA'),
      'factory': TipoOrdenesCompraFactory,
      'view': 'TipoOrdenCompraList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('FAIL_DELETE_TIPO_ORDEN_COMPRA'),
      'columns': [
        {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
        {'data': 'nombre', 'title': $filter('translate')('NOMBRE')},
        {'data': 'incluyeProducto', 'title': $filter('translate')('INCLUYE_PRODUCTO'), 'class' : 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
        {'data': 'incluyeServicio', 'title': $filter('translate')('INCLUYE_SERVICIO'), 'class' : 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
        {'data': 'esImportacion', 'title': $filter('translate')('ES_IMPORTACION'), 'class' : 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
        {'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class' : 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
      ],
      'hasOptions' : true,
      'defaultOrderColumn' : 0,
      'defaultOrderDir' : "desc",
      'extraMenuOptions':
        [
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoTipoOrdenCompra', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("tipoordencompra", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          }
        ]
    };
  });
  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      if(search === 'No' && (data === 'incluyeProducto' || data === 'incluyeServicio' || data === 'esImportacion' || data === 'activo')){
        search = 'false';
    }
    if(search === 'Sí' && (data === 'incluyeProducto' || data === 'incluyeServicio' || data === 'esImportacion' || data === 'activo')){
        search = 'true';
    }
      filtersArr.push({path: data, like: search})
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}]);

'use strict';

angular.module('qualita.compra')
    .controller('TipoOrdenesCompraFormCtrl', ["TipoOrdenesCompraFactory", "filterFactory",
        "$modal", "$scope", "notify", "$state", "$rootScope", "tipoOrdenCompraPrepService", "$controller", "formFactory",
        "CompraLocalLangFactory", '$location', '$filter',
        function(TipoOrdenesCompraFactory, filterFactory, $modal, $scope, notify, $state, $rootScope,
            tipoOrdenCompraPrepService, $controller, formFactory, CompraLocalLangFactory, $location, $filter) {

            var self = this;
            CompraLocalLangFactory.getTranslations().then(function(translations) {
                $scope.resource = 'tipoordencompra';
                $scope.resources = 'tipoordencompra';
                $scope.prepService = tipoOrdenCompraPrepService;
                $scope.submit = submit;
                $scope.cancel = cancel;
                $scope.submitTipoOrdenCompra = submitTipoOrdenCompra;
                $scope.importacionCheckChanged = importacionCheckChanged;
                $scope.translations = translations;

                $scope.newProperties = {
                    state: "app.tipoordencompra.new",
                    title: $filter('translate')('NEW_TIPO_ORDEN_COMPRA'),
                };
                $scope.editProperties = {
                    state: "app.tipoordencompra.edit",
                    title: $filter('translate')('EDIT_TIPO_ORDEN_COMPRA'),
                    entidad: "TipoOrdenCompra"
                };

                $scope.viewProperties = {
                    state: "app.tipoordencompra.view",
                    title: $filter('translate')('VIEW_TIPO_ORDEN_COMPRA'),
                    entidad: "TipoOrdenCompra"
                };

                $scope.activate = function() {
                    if ($state.is($scope.newProperties.state)) {
                        activateNew();
                    } else if ($state.is($scope.editProperties.state)) {
                        activateEdit();
                    } else if ($state.is($scope.viewProperties.state)) {
                        activateView();
                    }

                    $rootScope.isProcessing = false;
                }

                function activateNew() {
                    $scope.title = $scope.newProperties.title;
                    $scope.tipoOrdenCompra = { activo: true };
                }

                function activateEdit() {
                    $scope.tipoOrdenCompra = $scope.prepService;
                    $scope.entidadId = $scope.tipoOrdenCompra.id;
                    $scope.entidad = $scope.editProperties.entidad;
                    $scope.title = $scope.editProperties.title;
                }

                function activateView() {
                    $scope.tipoOrdenCompra = $scope.prepService;
                    $scope.entidadId = $scope.tipoOrdenCompra.id;
                    $scope.entidad = $scope.viewProperties.entidad;
                    $scope.title = $scope.viewProperties.title;
                    $scope.view = true;
                }

                function cancel() {
                    $location.path('/tipoordencompra');
                };

                $scope.activate();

                function submit() {
                    $scope.submited = true;
                    if ($scope.TipoOrdenCompraForm.$valid) {
                        $rootScope.isProcessing = true;
                        if (!$scope.tipoOrdenCompra.incluyeProducto &&
                            !$scope.tipoOrdenCompra.incluyeServicio &&
                            !$scope.tipoOrdenCompra.esImportacion) {
                            var msg = $filter('translate')('ESPECIFICAR_PARAMETROS_TIPO_OC_W');
                            notify({
                                message: msg,
                                classes: 'alert-danger',
                                position: 'right'
                            });
                            $rootScope.isProcessing = false;
                            return;
                        }
                        submitTipoOrdenCompra().then(function(tipoOrdenCompra) {
                            $location.path('/tipoordencompra');
                        }, errorHandler);
                    }
                };

                function submitTipoOrdenCompra() {
                    var tipoOrdenCompra = TipoOrdenesCompraFactory.create($scope.tipoOrdenCompra);
                    return TipoOrdenesCompraFactory.save(tipoOrdenCompra,
                        function(resp, headers) {
                            //success callback
                            console.log("RESPONSE: ", resp);

                            console.log(resp);
                        });
                };

                function importacionCheckChanged() {
                    if ($scope.tipoOrdenCompra.esImportacion) {
                        $scope.tipoOrdenCompra.incluyeProducto = true;
                        $scope.tipoOrdenCompra.incluyeServicio = true;
                    } else {
                        $scope.tipoOrdenCompra.incluyeProducto = false;
                        $scope.tipoOrdenCompra.incluyeServicio = false;
                    }
                }

                function errorHandler(err) {
                    console.log("Error al guardar Tipo OC: ", err);

                    $rootScope.isProcessing = false;
                    $scope.submited = false;
                    var msg = '';
                    _.forEach(err.data, function(error) {
                        if (error.constraint == 'nombre') {
                            msg += '\n\n' + $filter('translate')('TIPO_ORDEN_COMPRA_ALREADY_EXISTS') + "\n";
                        } else {
                            msg += '\n\n' + error.message + '.';
                        }
                    });
                    notify({
                        message: msg,
                        classes: 'alert-danger',
                        position: 'right'
                    });
                }
            });
        }
    ]);
/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.compra')
  .factory('TipoOrdenesCompraFactory', TipoOrdenesCompraFactory);

TipoOrdenesCompraFactory.$inject = ['$resource', 'baseurl', '$http', 'CompraLocalLangFactory'];

function TipoOrdenesCompraFactory($resource, baseurl, $http, CompraLocalLangFactory) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var TipoOrdenesCompra = $resource( baseurl.getBaseUrl() + "/tipoordencompra/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });


  return service;

  function all(params) {
    return TipoOrdenesCompra.query(params);
  }

  function get(id, view) {
    return TipoOrdenesCompra.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new TipoOrdenesCompra(attrs);
  }

  function save(tipoordencompra) {
    return (tipoordencompra.id) ? tipoordencompra.$update() : tipoordencompra.$save();
  }

  function remove(tipoordencompra) {
    return tipoordencompra.$remove();
  }
}

'use strict';

angular.module('qualita.compra')
    .controller('CompradorListCtrl', ["AuthorizationService", "CompradorFactory", "TiposFactory", "$log", "$modal", "$scope", "CompraLocalLangFactory", "$filter", "filterFactory", "ReportTicketFactory", "$window", "UtilFactory", "CsvFactory", "baseurl", function(AuthorizationService, CompradorFactory, TiposFactory,
        $log, $modal, $scope, CompraLocalLangFactory, $filter, filterFactory, ReportTicketFactory, $window,
        UtilFactory, CsvFactory, baseurl) {

        var defaultColumnOrder = ['id', 'usuario.username', 'montoMaximoCompra', 'periodo', 'activo'];

        var monedaRender = function(data, type, row) {
            if (data) {
                var moneda = "Gs. ";
                if (row.montoMaximoCompra.codigo === 'dolares') {
                    moneda = "Usd. ";
                    data = parseFloat(data).toFixed(2);
                } else if (row.montoMaximoCompra.codigo === 'pesos') {
                    moneda = "Pes. ";
                    data = parseFloat(data).toFixed(2);
                } else if (row.montoMaximoCompra.codigo === 'real') {
                    moneda = "Rel. ";
                    data = parseFloat(data).toFixed(2);
                } else if (row.montoMaximoCompra.codigo === 'euro') {
                    moneda = "Eur. ";
                    data = parseFloat(data).toFixed(2);
                }
                // TODO hacer esto mismo en todos los renders de moneda
                return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return '';
            }
        };

        CompraLocalLangFactory.getTranslations().then(function(translations) {
            $scope.options = {
                'resource': 'comprador',
                'title': 'Compradores',
                'factory': CompradorFactory,
                'view': 'CompradorList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false  },
                    { 'data': 'usuario.username', 'title': $filter('translate')('NOMBRE') },
                    { 'data': 'montoMaximoCompra', 'title': $filter('translate')('MONTO_MAXIMO_COMPRA'), 'render': monedaRender, 'class': 'dt-right'  },
                    { 'data': 'periodo', 'title': $filter('translate')('PERIOD'), 'class': 'dt-right' },
                    { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraMenuOptions':
                    [
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function() { return true; },
                            'action': function() {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoComprador', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                  $window.open($scope.pdfDownloadURL, '_blank');
                                });
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function() { return true; },
                            'action': function() {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("comprador", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                .then(function(response) {
                                  console.log("response de csv: ", response);
                                  var fileName = response.data.fileName;
                                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                            }
                        }
                    ]
            };
        });

        var createFilters = function (filters) {
            var filtersArr = [];
            _.each(filters, function (search, data) {
                if(search === 'No' && data === 'activo'){
                    search = 'false';
                }
                if(search === 'Sí' && data === 'activo'){
                    search = 'true';
                }
              filtersArr.push({path: data, like: search})
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        }

    }]);
'use strict';

angular.module('qualita.compra')
  .controller('CompradorFormCtrl', ["CompradorFactory", "filterFactory",
    "$modal", "$scope", "notify", "$state", "$rootScope", "compradorPrepService", "$controller", "CompraLocalLangFactory",
    "UsuariosFactory", "formFactory", "$filter",
    function (CompradorFactory, filterFactory, $modal, $scope, notify, $state, $rootScope,
      compradorPrepService, $controller, CompraLocalLangFactory, UsuariosFactory, formFactory, $filter) {
      activate();

      function activate() {
        var self = this;
        $scope.submited = false;
        CompraLocalLangFactory.getTranslations().then(function (translations) {
          $scope.resource = 'comprador';
          $scope.resources = 'comprador';
          $scope.factory = CompradorFactory;
          $scope.prepService = compradorPrepService;
          $scope.translations = translations;

          var usersFilter = {};
          usersFilter.search = filterFactory.and([{
              path: 'activo',
              equals: true
            }]).value();
          usersFilter.view = "BaseList"
          UsuariosFactory.all(usersFilter).$promise.then(function (data) {
            $scope.usuarios = data;
          });

          if ($state.is("app.comprador.new")) {
            activateNew();
          } else if ($state.is("app.comprador.edit")) {
            activateEdit();
          } else if ($state.is("app.comprador.view")) {
            activateView();
          }

          $rootScope.isProcessing = false;
        });
      }

      function activateNew() {
        $scope.title = $filter('translate')('NEW_COMPRADOR');
        $scope.model = {activo: true};
      }

      function activateEdit() {
        $scope.title = $filter('translate')('EDIT_COMPRADOR');
        $scope.model = compradorPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Comprador";
      }

      function activateView() {
        $scope.title = $filter('translate')('VIEW_COMPRADOR');
        $scope.model = compradorPrepService;
        $scope.entidadId = $scope.model.id;
        $scope.entidad = "Comprador";
        $scope.view = true;
      }

      $scope.submit = function () {
        $scope.submited = true;
        formFactory.defaultNSFSubmit($scope.compradorForm, CompradorFactory, $scope.model, errorHandler).then(function (response) {
          $state.go('^.list');
        }, function (error) {
          return errorHandler(error);
        });
      };

      $scope.cancel = function () {
          $state.go('^.list');
      };

    function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function (error) {
          msg += '\n\n' + error.message + '.';
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      };
    }]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.compra')
  .factory('CompradorFactory', CompradorFactory);

CompradorFactory.$inject = ['$resource', 'baseurl', '$http', 'UsuariosFactory',
'CompraLocalLangFactory', 'filterFactory', '$rootScope'];

function CompradorFactory($resource, baseurl, $http, UsuariosFactory,
  CompraLocalLangFactory, filterFactory, $rootScope) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    schema: schema,
    form: form,
    getByUserActual: getByUserActual
  };

  var Comprador = $resource( baseurl.getBaseUrl() + "/comprador/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params) {
    return Comprador.query(params);
  }

  function get(id, view) {
    return Comprador.get({id: id, view: view ? view : "base"});
  }

  function getByUserActual() {
    var filters = {};
    filters.search = filterFactory.single({
      path: 'usuario.username',
      equals: $rootScope.AuthParams.username
    }).value();
    filters.view = 'CompradorForm'
    return all(filters).$promise.then(function(c) {
      return c[0];
    });
  }

  function create(attrs) {
    return new Comprador(attrs);
  }

  function save(comprador) {
    return (comprador.id) ? comprador.$update() : comprador.$save();
  }

  function remove(comprador) {
    return comprador.$remove();
  }


 function schema() {
    return schema;
  }

  function  form(type) {
    return forms[type];
  }

}

'use strict';

angular.module('qualita.compra')
  .controller('MensajeDifusionListCtrl', ["AuthorizationService", "MensajeDifusionFactory", "TiposFactory", "$log", "$modal", "$scope", "CompraLocalLangFactory", "$filter", "UtilFactory", "$window", "CsvFactory", "baseurl", function (AuthorizationService, MensajeDifusionFactory, TiposFactory,
  $log, $modal, $scope, CompraLocalLangFactory, $filter, UtilFactory, $window, CsvFactory, baseurl) {

  var defaultColumnOrder = ['id', 'mensaje', 'fechaDesde', 'fechaHasta'];

  CompraLocalLangFactory.getTranslations().then(function(translations) {
    $scope.options = {
      'resource': 'mensajedifusion',
      'title': $filter('translate')('MENSAJES_DIFUSION_PROVEEDORES'),
      'factory': MensajeDifusionFactory,
      'view': 'MensajeDifusionList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS'),
      'columns': [
        {'data': 'id', 'title': $filter('translate')('CODE') },
        {'data': 'mensaje', 'title': $filter('translate')('MESSAGE')},
        {'data': 'fechaDesde', 'title': $filter('translate')('DATE_FROM'), 'renderWith' : 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class' : 'dt-center', 'type' : 'date-range'},
        {'data': 'fechaHasta', 'title': $filter('translate')('DATE_TO'), 'renderWith' : 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class' : 'dt-center', 'type' : 'date-range'}
      ],
      'hasOptions' : true,
      'defaultOrderColumn' : 0,
      'defaultOrderDir' : "asc",
      'extraMenuOptions':[
          {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("mensajedifusion", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
          }
      ]
    };
  });
}]);

'use strict';

angular.module('qualita.compra')
    .controller('MensajeDifusionFormCtrl', ["MensajeDifusionFactory", "filterFactory",
        "$modal", "$scope", "notify", "$state", "$rootScope", "mensajeDifusionPrepService", "$controller",
        "CompraLocalLangFactory", "formFactory", "$location", "$filter",
        function(MensajeDifusionFactory, filterFactory, $modal, $scope, notify, $state, $rootScope,
            mensajeDifusionPrepService, $controller, CompraLocalLangFactory, formFactory, $location, $filter) {

            activate();

            function activate() {
                CompraLocalLangFactory.getTranslations().then(function(translations) {
                    $scope.translations = translations;
                    $scope.schema = MensajeDifusionFactory.schema();
                    $scope.options = formFactory.defaultOptions();

                    if ($state.is("app.mensajedifusion.new")) {
                        activateNew();
                    } else if ($state.is("app.mensajedifusion.edit")) {
                        activateEdit();
                    } else if ($state.is("app.mensajedifusion.view")) {
                        activateView();
                    }
                    $rootScope.isProcessing = false;
                });

            }

            function activateNew() {
                $scope.title = $filter('translate')('NEW_MENSAJE_DIFUSION_PROVEEDORES');
                $scope.form = MensajeDifusionFactory.form('new');
                $scope.model = {};
                $scope.view = false;
                $scope.schema.readonly = false;
            }


            function activateEdit() {
                $scope.title = $filter('translate')('EDIT_MENSAJE_DIFUSION_PROVEEDORES');
                $scope.form = MensajeDifusionFactory.form('edit');
                $scope.model = mensajeDifusionPrepService;
                $scope.model.fechaDesde = moment($scope.model.fechaDesde, 'YYYY/MM/DD');
                $scope.model.fechaHasta = moment($scope.model.fechaHasta, 'YYYY/MM/DD');
                $scope.entidadId = $scope.model.id;
                $scope.entidad = "MensajeDifusion";
                $scope.view = false;
                $scope.schema.readonly = false;
            }

            function activateView() {
                $scope.title = $filter('translate')('VIEW_MENSAJE_DIFUSION_PROVEEDORES');
                $scope.form = MensajeDifusionFactory.form('view');
                $scope.options = formFactory.defaultViewOptions();
                $scope.model = mensajeDifusionPrepService;
                $scope.entidadId = $scope.model.id;
                $scope.entidad = "MensajeDifusion";
                $scope.view = true;
                $scope.schema.readonly = true;
            }

            $scope.submit = function(form) {
                // validar los campos antes de validar la fecha
                $scope.$broadcast('schemaFormValidate');
                if (form.$valid) {
                    if (!angular.isString($scope.model.fechaDesde)) {
                        var desde = moment($scope.model.fechaDesde).format('DD/MM/YYYY');
                    } else {
                        var desde = $scope.model.fechaDesde;
                    }
                    if (!angular.isString($scope.model.fechaHasta)) {
                        var hasta = moment($scope.model.fechaHasta).format('DD/MM/YYYY');
                    } else {
                        var hasta = $scope.model.fechaHasta;
                    }

                    var d1 = parseInt(desde.substring(0, 2));
                    var d2 = parseInt(desde.substring(3, 5));
                    var d3 = parseInt(desde.substring(6, 10));
                    var h1 = parseInt(hasta.substring(0, 2));
                    var h2 = parseInt(hasta.substring(3, 5));
                    var h3 = parseInt(hasta.substring(6, 10));

                    if (d3 < h3 || (d2 < h2 && d3 === h3) || (d1 <= h1 && d2 === h2 && d3 === h3)) {
                        var date = new Date();
                        $scope.fechaActual = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
                        // console.log("Fecha actual: "+$scope.fechaActual);
                        // console.log("Fecha desde: "+$scope.model.fechaDesde);

                        if ($scope.model.fechaDesde >= $scope.fechaActual) {
                            formFactory.defaultSubmit('mensajedifusion', $scope, form, MensajeDifusionFactory);
                        } else {
                            var msg = $filter('translate')('INVALID_DATES');
                            notify({
                                message: "Fecha Desde no debe ser menor a la fecha de hoy",
                                classes: 'alert-danger',
                                position: 'right'
                            });
                        }
                    } else {
                        var msg = $filter('translate')('INVALID_DATES');
                        notify({
                            message: msg,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                    }
                }

            };

            $scope.cancel = function() {
                $location.path('/mensajedifusion');
            };

        }
    ]);
/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.compra')
  .factory('MensajeDifusionFactory', MensajeDifusionFactory);

MensajeDifusionFactory.$inject = ['$resource', 'baseurl', '$http', 'CompraLocalLangFactory', '$filter'];

function MensajeDifusionFactory($resource, baseurl, $http, CompraLocalLangFactory, $filter) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    schema: schema,
    form: form
  };

  var MensajeDifusion = $resource( baseurl.getBaseUrl() + "/mensajedifusion/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  var schema = undefined;

  CompraLocalLangFactory.getTranslations().then(function(translations) {
    schema = {
      type: 'object',
      title: $filter('translate')('MENSAJE_DIFUSION_PROVEEDORES'),
      properties: {
        mensaje: {
          title: $filter('translate')('MESSAGE'),
          type: 'string',
          maxLength: 255,
          validationMessage: {
            201: $filter('translate')('LONG_VALUE_WARNING')
          },
        },
        fechaDesde: {
          title: $filter('translate')('DATE_FROM'),
          type: 'string',
          format: 'date',
          formatDate: 'DD/MM/YYYY',
          validationMessage: $filter('translate')('INVALID_DATE')
        },
        fechaHasta: {
          title: $filter('translate')('DATE_TO'),
          type: 'string',
          format: 'date',
          formatDate: 'DD/MM/YYYY',
          validationMessage: $filter('translate')('INVALID_DATE')
        }
      },
      required: ['mensaje', 'fechaDesde', 'fechaHasta']
    };
  });


    var formNew = [
      'mensaje',
      {
        key: 'fechaDesde',
        type: 'datepicker'
      },
      {
        key: 'fechaHasta',
        type: 'datepicker'
      }
    ];

    var formEdit = angular.copy(formNew);
    var formView = angular.copy(formNew);

    var forms = {
      'new': formNew,
      'edit': formEdit,
      'view': formView
    }

  return service;

  function all(params) {
    return MensajeDifusion.query(params);
  }

  function get(id, view) {
    return MensajeDifusion.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new MensajeDifusion(attrs);
  }

  function save(mensajedifusion) {
    return (mensajedifusion.id) ? mensajedifusion.$update() : mensajedifusion.$save();
  }

  function remove(mensajedifusion) {
    return mensajedifusion.$remove();
  }

  function schema() {
    return schema;
  }

  function  form(type) {
    return forms[type];
  }

}

'use strict';

angular.module('qualita.compra')
    .controller('OrdenesCompraLocalListCtrl', OrdenesCompraLocalListCtrl);

OrdenesCompraLocalListCtrl.$inject = ["AuthorizationService", "OrdenesCompraFactory",
    "ReportTicketFactory", "$modal", "$scope", "notify", "$state", "filterFactory",
    "$window", "CompraLocalLangFactory", "CompraFactory", "ImportacionFactory", "SolicitudCompraFactory",
    "CompradorFactory", "WatchLocacionChangeFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "$q", "PeriodoAdministrativoFactory", "$rootScope"
];

function OrdenesCompraLocalListCtrl(AuthorizationService, OrdenesCompraFactory,
    ReportTicketFactory, $modal, $scope,
    notify, $state, filterFactory, $window,
    CompraLocalLangFactory, CompraFactory,
    ImportacionFactory, SolicitudCompraFactory, CompradorFactory, WatchLocacionChangeFactory,
    $filter, UtilFactory, CsvFactory, baseurl, $q, PeriodoAdministrativoFactory, $rootScope) {
    var vm = this;

    activate();

    function activate() {
        vm.customResource = "compraslocales.proceso.ordenescompra";

        CompraLocalLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;

            vm.isProcesoImportacion = false;
            vm.isProcesoCompra = false;


            if ($state.includes("app.importaciones.proceso")) {
                vm.isProcesoImportacion = true;
            } else if ($state.includes("app.compraslocales.proceso")) {
                vm.isProcesoCompra = true;
            }

            var staticFilter = undefined;
            if (vm.isProcesoCompra) {
                var compra = CompraFactory.getCompra();
                if (!compra) {
                    $state.go("app.main");
                }
                var isStateEnabled = CompraFactory.checkPermissionForState(0);
                if (!isStateEnabled) {
                    console.log("No tiene permiso para revisar esta seccion");
                    $state.go("app.main");
                }
                staticFilter = {
                    search: filterFactory.and([{
                        path: 'compras.id',
                        equals: CompraFactory.getIdCompra(),
                    }]).value()
                };
            } else if (vm.isProcesoImportacion) {
                vm.customResource = "importaciones.proceso.ordenescompra";
                var importacion = ImportacionFactory.getImportacion();
                if (!importacion) {
                    $state.go("app.main");
                }
                var isStateEnabled = ImportacionFactory.checkPermissionForState(0);
                if (!isStateEnabled) {
                    console.log("No tiene permiso para revisar esta seccion");
                    $state.go("app.main");
                }
                staticFilter = {
                    search: filterFactory.and([{
                        path: 'importaciones.id',
                        equals: ImportacionFactory.getIdImportacion(),
                    }]).value()
                };

            } else {
                vm.customResource = "orden_compra";
            }

            var defaultColumnOrder = ['id', 'numero', 'tipoOrdenCompra.nombre', 'fecha', 'proveedor.nombre', 'estado.descripcion', 'moneda.descripcion', 'fechaVencimiento', 'fechaConsolidacion', 'consolidado', 'destino.numero', 'proyecto.nombre', 
            'montoTotal', 'solicitudCompra.numero', 'comprador.usuario.username'];
            vm.options = {
                'resource': 'orden_compra',
                'customResource': vm.customResource,
                'title': $filter('translate')('ORDENES_COMPRA'),
                'factory': OrdenesCompraFactory,
                'view': 'OrdenCompraList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false },
                    { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-center'},
                    { 'data': 'tipoOrdenCompra.nombre', 'title': $filter('translate')('TYPE'), 'renderWith': 'emptyRender' },
                    { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX') },
                    { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'orden_compra/estados' },
                    { 'data': 'moneda.descripcion', 'title': $filter('translate')('CURRENCY'), 'class': 'dt-center' },
                    { 'data': 'fechaVencimiento', 'title': $filter('translate')('FECHA_VENCIMIENTO'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'fechaConsolidacion', 'title': $filter('translate')('FECHA_CONSOLIDACION'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'consolidado', 'title': $filter('translate')('CONSOLIDADO_POR'), 'renderWith': 'emptyRender' },
                    { 'data': 'destino.numero', 'title': $filter('translate')('NUMBER_OC_DESTINO'), 'class': 'dt-center','renderWith': 'emptyRender'},
                    { 'data': 'proyecto.nombre', 'title': $filter('translate')('PROYECTO'), 'class': 'dt-center','renderWith': 'emptyRender'},
                    { 'data': 'montoTotal', 'title': $filter('translate')('PRECIO_TOTAL_ITEM'), 'class': 'dt-center','renderWith': 'emptyRender'},
                    { 'data': 'solicitudCompra.numero', 'title': $filter('translate')('NRO_SOLICITUD_COMPRA'), 'class': 'dt-center','renderWith': 'emptyRender', visible: false},
                    { 'data': 'comprador.usuario.username', 'title': $filter('translate')('COMPRADOR'), 'class': 'dt-center','renderWith': 'emptyRender', visible: false}
                ],
                'isSelectable': true,
                'selection': {},
                'hasOptions': true,
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'defaultOrderColumn': 1,
                'defaultOrderDir': "desc",
                'extraMenuOptions': [{
                        'title': $filter('translate')('NEW'),
                        'icon': 'glyphicon glyphicon-plus',
                        'showCondition': function() {
                            if (vm.isProcesoImportacion) {
                                return AuthorizationService.hasPermission('create_orden_compra') && ImportacionFactory.getImportacion().estado.codigo != "cerrada";
                            } else if (vm.isProcesoCompra) {
                                return false;
                            } else {
                                return AuthorizationService.hasPermission('create_orden_compra');
                            }
                        },
                        'action': function() {
                            var hoy = new Date();
                            hoy.setHours(0, 0, 0, 0);

                            evaluatePeriodoAdministrativo(hoy).then(function(response) {
                                if(response){
                                    if (vm.isProcesoImportacion) {
                                        $state.go("app.importaciones.proceso.ordenescompra.new");
                                    } else if (vm.isProcesoCompra) {
                                        $state.go("app.compraslocales.proceso.ordenescompra.new");
                                    } else {
                                        $state.go("app.orden_compra.new");
                                    }
                                } else {
                                    var msg = ' ' + $filter("translate")("NO_ADMINISTRATIVE_PERIOD");
                                    notify({ message: msg, classes: "alert-danger", position: "right" });
                                }
                            });
                        }
                    },
                    {
                        'title': $filter('translate')('CONSOLIDAR'),
                        'icon': 'glyphicon glyphicon-resize-small',
                        'showCondition': function() {
                            return !vm.isProcesoImportacion && !vm.isProcesoCompra && AuthorizationService.hasPermission('update_orden_compra');
                        },
                        'action': function() {
                            var ids = getIDs();
                            if (ids.length < 2) {
                                notify({ message: $filter('translate')('SELECCIONAR_ORDENES_COMPRA_WARNING'), classes: 'alert-warning', position: 'right' });
                            } else {
                                $scope.tituloModal = $filter('translate')('CONSOLIDAR_ORDENES_COMPRA_Q');
                                $scope.mensajeModal = $filter('translate')('PERMANENT_OPERATION_WARNING');
                                var modalInstance = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.cancel = function() {
                                    modalInstance.dismiss('cancel');
                                };

                                $scope.ok = function(id) {
                                    modalInstance.dismiss('cancel');
                                    OrdenesCompraFactory.consolidar(ids);
                                }
                            }
                        }
                    },
                    {
                        'title': $filter('translate')('CERRAR_OC'),
                        'icon': 'glyphicon glyphicon-check',
                        'showCondition': function() {
                            return !vm.isProcesoImportacion && !vm.isProcesoCompra 
                            && AuthorizationService.hasPermission('update_orden_compra');
                        },
                        'action': function() {
                            var ids = getIDs();
                            $scope.tituloModal = $filter('translate')('CERRAR_ORDENES_COMPRA_Q');
                            $scope.mensajeModal = $filter('translate')('PERMANENT_OPERATION_WARNING');
                            var modalInstance = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function(id) {
                                modalInstance.dismiss('cancel');
                                console.log(ids);
                                if (ids.length > 0) {
                                    OrdenesCompraFactory.cerrarMultiple(ids);
                                } else {
                                    var msg = $filter('translate')('SELECCIONAR_ORDENES_COMPRA_WARNING');
                                    notify({ message: msg, classes: 'alert-warning', position: 'right' });
                                }

                            }

                        }
                    },
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = createFilters(vm.options.getFilters());
                            ReportTicketFactory.ticket('listadoOrdenCompra', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open($scope.pdfDownloadURL, '_blank');
                            });
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                            CsvFactory.csv("orden_compra", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                                .then(function(response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    }
                ],
                'extraRowOptions': [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' title='" + $filter('translate')('EDIT') + "' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canEditar",
                        conditionDef: function(estado) {
                            if (vm.isProcesoImportacion) {
                                return (AuthorizationService.hasPermission('update_orden_compra') && estado.codigo == "ocl_ingresada" && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                            } else if (vm.isProcesoCompra) {
                                return (AuthorizationService.hasPermission('update_orden_compra') && estado.codigo == "ocl_ingresada" && CompraFactory.getCompra().estado.codigo != "compra_cerrada" &&
                                    CompraFactory.getCompra().estado.codigo != "compra_anulada");
                            } else {
                                return (AuthorizationService.hasPermission('update_orden_compra') && estado.codigo == "ocl_ingresada");
                            }
                        },
                        functionName: "editar",
                        functionDef: function(itemId) {
                            
                            if (vm.isProcesoImportacion) {
                                $state.go("app.importaciones.proceso.ordenescompra.edit", { id: itemId });
                            } else if (vm.isProcesoCompra) {
                                $state.go("app.compraslocales.proceso.ordenescompra.edit", { id: itemId });
                            } else {
                                $state.go("app.orden_compra.edit", { id: itemId });

                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canBorrar",
                        conditionDef: function(estado) {
                            if (vm.isProcesoImportacion) {
                                return (AuthorizationService.hasPermission('delete_orden_compra') && estado.codigo == "ocl_ingresada" && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                            } else if (vm.isProcesoCompra) {
                                return (AuthorizationService.hasPermission('delete_orden_compra') && estado.codigo == "ocl_ingresada" && CompraFactory.getCompra().estado.codigo != "compra_cerrada" &&
                                    CompraFactory.getCompra().estado.codigo != "compra_anulada");
                            } else {
                                return (AuthorizationService.hasPermission('delete_orden_compra') && estado.codigo == "ocl_ingresada");
                            }
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceBorrar1.dismiss('cancel');
                            }

                            $scope.ok = function(itemId) {
                                var model = OrdenesCompraFactory.create({ id: itemId });
                                OrdenesCompraFactory.remove(model).then(function() {
                                    $scope.modalInstanceBorrar1.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                    $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                                    $scope.mensajeModal = $scope.options.failedDeleteError;
                                    var modalInstance = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };
                                    console.log("error al borrar: ");
                                    console.log(error);
                                });
                            }
                        }
                    },
                    {
                        templateToRender: "<button title='Emitir OC' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='emitir(<%=dataCustom%>)' ng-class='{ hidden : !canEmitir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
                        functionName: "emitir",
                        customAttribute: ['id', 'estado', 'solicitudCompra'],
                        conditionName: "canEmitir",
                        conditionDef: function(atributo) {
                            
                            var estado = atributo[1];

                            if (vm.isProcesoImportacion) {
                                return (AuthorizationService.hasPermission('update_orden_compra') && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                            } else if (vm.isProcesoCompra) {
                                return (AuthorizationService.hasPermission('update_orden_compra') && CompraFactory.getCompra().estado.codigo != "compra_cerrada" &&
                                    CompraFactory.getCompra().estado.codigo != "compra_anulada");
                            } else {
                                return (AuthorizationService.hasPermission('update_orden_compra') && (estado.codigo != "ocl_anulada"));
                            }
                        },
                        functionDef: function(atributo) {

                            $scope.itemId = atributo[0];
                            $scope.estadoOC = atributo[1];
                            $scope.solCompra = atributo[2];

                            $scope.tituloModal = $filter('translate')('EMITIR_ORDEN_COMPRA_Q');
                            $scope.mensajeModal = $filter('translate')('PERMANENT_OPERATION_WARNING');
                            $scope.config = "CON_PRECIO";
                            var modalInstance = $modal.open({
                                templateUrl: 'qualita-components/compra/views/ordenescompra/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function(id, config) {
                                var filtersArr = [];
                                filtersArr.push("idordencompra=" + $scope.itemId);
                                filtersArr.push("config=" + config);
                                filtersArr.push("sucursalUsuario=" + $rootScope.AuthParams.locacionSeleccionada.sucursal.id);
                                console.log("filtersArr: ", filtersArr);
                                OrdenesCompraFactory.emitir($scope.itemId).then(function(response) {

                                    if($scope.solCompra != null && $scope.estadoOC.codigo == "ocl_ingresada"){

                                        // 2do modal, para consultar si se debe cerrar Sol. Compra asociada
                                        $scope.tituloModal = $filter('translate')('CERRAR_SOL_COMRPRA_ASOCIADA_TITLE');
                                        $scope.mensajeModal = $filter('translate')('CERRAR_SOL_COMRPRA_ASOCIADA_MESSAGE');
                                        var modalInstanceCerrarSol = $modal.open({
                                            templateUrl: 'views/directives/confirmation-modal.html',
                                            scope: $scope
                                        });

                                        $scope.cancel = function() {
                                            modalInstanceCerrarSol.dismiss('cancel');
                                            $state.go($state.current, {}, { reload: true });
                                        };

                                        $scope.ok = function() {
                                            SolicitudCompraFactory.cerrar($scope.solCompra.id).then(function() {
                                                modalInstanceCerrarSol.close($scope.solCompra.id);
                                                $state.go($state.current, {}, { reload: true });
                                            }, function(error) {
                                                $state.go($state.current, {}, { reload: true });
                                                console.log("error al cerrar ");
                                                console.log(error);
                                            });
                                        };
                                    }else{
                                     $state.go($state.current, {}, { reload: true });
                                    }

                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('ordencompra', 'pdf', filtersArr);
                                    $window.open(vm.pdfDownloadURL, '_blank');

                                }, function(error) {
                                    var msg = $filter('translate')('EMITIR_FAILED');
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                });
                                modalInstance.close();
                            };
                        }
                    },
                    {
                        templateToRender: "<button title='Anular' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataId%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                        customAttribute: "estado",
                        conditionName: "canAnular",
                        conditionDef: function(estado) {
                            if (vm.isProcesoImportacion) {
                                return (AuthorizationService.hasPermission('update_orden_compra') && estado.codigo == "ocl_emitida" && ImportacionFactory.getImportacion().estado.codigo != "cerrada");
                            } else if (vm.isProcesoCompra) {
                                return false;
                            } else {
                                return (AuthorizationService.hasPermission('update_orden_compra') && estado.codigo == "ocl_emitida");
                            }
                        },
                        functionName: "anular",
                        functionDef: function(itemId) {
                            OrdenesCompraFactory.get(itemId).$promise.then(function(response) {
                                if ((response.comprobantes && response.comprobantes.length > 0) ||
                                    (response.recepciones && response.recepciones.length > 0) ||
                                    (response.solicitudPago && response.solicitudPago.length > 0) ||
                                    (response.proformas && response.proformas.length > 0)) {
                                    notify({ message: $filter('translate')('RELACIONES_ORDEN_COMPRA_PRESENTES'), classes: 'alert-danger', position: 'right' });
                                } else {
                                    $scope.selectedItemId = itemId;
                                    $scope.tituloModal = $filter('translate')('CONFIRMACION_ANULACION');
                                    $scope.mensajeModal = $filter('translate')('CAMBIO_ESTADO_ORDEN_COMPRA_ANULADA_WARNING');
                                    $scope.modalInstanceRechazar = $modal.open({
                                        templateUrl: 'views/directives/confirmation-modal.html',
                                        scope: $scope
                                    });

                                    $scope.cancel = function() {
                                        $scope.modalInstanceRechazar.dismiss('cancel');
                                    };

                                    $scope.ok = function(itemId) {
                                        if (vm.isProcesoImportacion) {
                                            OrdenesCompraFactory.importacionAnular(itemId).then(function() {
                                                $scope.modalInstanceAnular.close(itemId);
                                                $state.go($state.current, {}, { reload: true });
                                            }, function(error) {
                                                $scope.modalInstanceAnular.dismiss('cancel');
                                                $scope.tituloModal = $filter('translate')('ANULAR_FAILED');
                                                $scope.mensajeModal = $filter('translate')('ORDENES_COMPRA_ANULAR_FAILED');
                                                var modalInstance = $modal.open({
                                                    template: '<div class="modal-header">' +
                                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                        '</div>' +
                                                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                        '<div class="modal-footer">' +
                                                        '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                                        '</div>',
                                                    scope: $scope
                                                });
                                                $scope.cancel = function() {
                                                    modalInstance.dismiss('cancel');
                                                };
                                            });
                                        } else {
                                            OrdenesCompraFactory.anular(itemId).then(function() {
                                                $scope.modalInstanceRechazar.close(itemId);
                                                $state.go($state.current, {}, { reload: true });
                                            }, function(error) {
                                                $scope.modalInstanceRechazar.close(itemId);
                                                notify({ message: error.data[0].message, classes: 'alert-danger', position: 'right' });
                                            });
                                        }
                                    };
                                }
                            });
                        }
                    },
                    {
                        templateToRender: "<button title='Cerrar OC' class='btn btn-primary btn-dt' style='margin-right: 5px;' ng-click='cerrar(<%=dataId%>)' ng-class='{hidden : !canCerrar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-check'></span> </button>",
                        customAttribute: 'estado',
                        conditionName: "canCerrar",
                        conditionDef: function(estado) {
                            if (vm.isProcesoImportacion) {
                                return ((estado.codigo == "ocl_emitida" || estado.codigo == "ocl_recepcionada" || estado.codigo == "ocl_parcialmente_recepcionada") && ImportacionFactory.getImportacion().estado.codigo == "cerrada");
                            } else 
                                if (vm.isProcesoCompra) {
                                    return ((estado.codigo == "ocl_emitida" || estado.codigo == "ocl_recepcionada" || estado.codigo == "ocl_parcialmente_recepcionada") && CompraFactory.getCompra().estado.codigo == "compra_cerrada" &&
                                        CompraFactory.getCompra().estado.codigo != "compra_anulada");
                                } else {
                                    return (estado.codigo == "ocl_emitida" || estado.codigo == "ocl_parcialmente_recepcionada" || estado.codigo == "ocl_recepcionada");
                                }
                        },
                        functionName: "cerrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('CERRAR_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('CERRAR_OC_WARNING');
                            $scope.modalInstanceCerrar = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceCerrar.dismiss('cancel');
                            }

                            $scope.ok = function(itemId) {
                                OrdenesCompraFactory.cerrar(itemId).then(function() {
                                    $scope.modalInstanceCerrar.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceCerrar.close(itemId);
                                    notify({ message: error.data[0].message, classes: 'alert-danger', position: 'right' });
                                });
                            }
                        }
                    }
                ]
            };
            WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");
        });
    }

    //function evaluatePeriodoAdministrativo(fecha){
    var evaluatePeriodoAdministrativo = function(fecha) {
        return $q(function(resolve, reject) {
            var params = {};
            params.search = filterFactory
            .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                }
            ])
            .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function(responsePeriodo) {

                if(responsePeriodo.length>0){
                    resolve(true);
                }else{
                    resolve(false);
                }
            })
        });
    }

    var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            search = search.split("~");
            if (search.length > 1) {
                /* El parámetro es un rago */

                var isDate = search[0].split("/");

                if (isDate.length > 1) {
                    /* Es un rago de fechas */

                    filtersArr.push({path: data, equalOrAfter: search[0]})
                    filtersArr.push({path: data, equalOrBefore: search[1]})
                } else {
                    /* El un rago numérco */

                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                    filtersArr.push({path: data, lessOrEqual: search[1]})
                }
            } else {
                /* El parametro no es un rago */
                filtersArr.push({path: data, like: search[0]})
            }
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }

    var getIDs = function() {
        var ids = [];
        _.forEach(vm.options.selection, function(n, key) {
            if (n)
                ids.push(key);
        });
        return ids;
    };
}
'use strict';

angular.module('qualita.compra')
        .controller('OrdenesCompraLocalFormCtrl', OrdenesCompraLocalFormCtrl);

OrdenesCompraLocalFormCtrl.$inject = ["OrdenesCompraFactory", "ProductosFactory", "TiposFactory",
    "ProveedoresFactory", "ordenCompraPrepService", "filterFactory",
    "$scope", "notify", "$state", "$rootScope", "$timeout", "TipoOrdenesCompraFactory", "ProveedorSucursalesFactory",
    "ParametrosFactory", "AlmacenFactory", "LocacionesFactory", "ServicioFactory", "ModelTrimmer", "$location",
    "CompraLocalLangFactory", "FormaPagoFactory", "SolicitudCompraFactory", "ImportacionFactory",
    "CompraFactory", "WatchLocacionChangeFactory", "UtilFactory", "CompradorFactory", "$q", "CotizacionMonedaFactory",
    "OrdenesCompraDetalleFactory", "$filter", "PeriodoAdministrativoFactory","ProyectoFactory"
];

function OrdenesCompraLocalFormCtrl(OrdenesCompraFactory, ProductosFactory, TiposFactory,
        ProveedoresFactory, ordenCompraPrepService, filterFactory,
        $scope, notify, $state, $rootScope, $timeout, TipoOrdenesCompraFactory, ProveedorSucursalesFactory,
        ParametrosFactory, AlmacenFactory, LocacionesFactory, ServicioFactory, ModelTrimmer, $location,
        CompraLocalLangFactory, FormaPagoFactory, SolicitudCompraFactory, ImportacionFactory,
        CompraFactory, WatchLocacionChangeFactory, UtilFactory, CompradorFactory, $q, CotizacionMonedaFactory,
        OrdenesCompraDetalleFactory, $filter, PeriodoAdministrativoFactory,ProyectoFactory) {

    var vm = this;

    activate();

    vm.agregarDetalle = agregarDetalle;
    vm.calcularImpuesto = calcularImpuesto;
    vm.calcularTotalDetalle = calcularTotalDetalle;
    vm.searchProductoFilter = searchProductoFilter;
    vm.searchSolicitudCompraFilter = searchSolicitudCompraFilter;
    vm.removeItemFromArray = removeItemFromArray;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.changeProducto = changeProducto;
    vm.delayRequestProducto = delayRequestProducto;
    vm.changeProveedor = changeProveedor;
    vm.changeServicio = changeServicio;
    vm.changeMoneda = changeMoneda;
    vm.mostrarTodosProductos = mostrarTodosProductos;
    vm.tipoDetalleSelected = tipoDetalleSelected;
    vm.tipoOrdenCompraChanged = tipoOrdenCompraChanged;
    vm.totalItemChanged = totalItemChanged;
    vm.getCotizacion = getCotizacion;

    $rootScope.isProcessing = false;
    vm.flagPeriod = false;

    function activate() {
        WatchLocacionChangeFactory.killAllWatchers();
        vm.curDate = new Date();
        var paramsProv = {};
        paramsProv.search = filterFactory.single({
            path: 'activo',
            equals: true
        }).value();
        paramsProv.view = "BaseList";
        vm.proyectos = ProyectoFactory.all("", "OrdenCompraForm");
        vm.proveedores = ProveedoresFactory.all(paramsProv);
        var filtroServicios = {};
        filtroServicios.search = filterFactory.and([{
                path: 'compra',
                equals: true
            }]).value();
        filtroServicios.view = "BaseList";
        vm.servicios = ServicioFactory.all(filtroServicios);

        var filterFormaPago = {};
        filterFormaPago.view = "BaseList";
        filterFormaPago.search = filterFactory.single({
            path: 'clase.codigo',
            notEquals: "forma_pago_referencia"
        }).value();
        vm.formasPago = FormaPagoFactory.all(filterFormaPago);
        vm.factoryProductos = ProductosFactory;
        vm.factoryServicios = ServicioFactory;
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
                path: 'estado.codigo',
                equals: 'sc_aprobada'
            },
            {
                path: 'compraLocal',
                like: 'false'
            }
        ]).value();

        if (vm.watcherSC) {
            vm.watcherSC();
        } //hacemos unwatch de la expresion anterior
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcherSC) {
            vm.watcherSC = watcherSC;
            SolicitudCompraFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter))
                    .$promise.then(function (response) {
                        vm.solicitudesCompra = response;
                        _.each(vm.solicitudesCompra, function (solicitudCompra) {
                            solicitudCompra.fecha = moment.utc(solicitudCompra.fecha).format('DD/MM/YYYY');
                        });
                    });

        }, "locacion.id");

        CompraLocalLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
                'lineaProducto.descripcion', 'marca.descripcion'
            ];

            vm.opcionesProductosDt = {
                'resource': 'productos',
                'title': 'ProductosOC',
                'view': "ProductoList",
                'factory': ProductosFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    {'data': 'codigo', 'title': $filter('translate')('ID'), visible: false},
                    {'data': 'nombre', 'title': $filter('translate')('NAME')},
                    {'data': 'claseProducto.descripcion', 'title': $filter('translate')('PRODUCT_CLASS')},
                    {'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados'},
                    {'data': 'lineaProducto.nombre', 'title': $filter('translate')('LINE')},
                    {'data': 'marca.descripcion', 'title': $filter('translate')('BRAND'), 'renderWith': 'emptyRender'},
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
            };

            var filtroCompra = {};
            filtroCompra.search = filterFactory
                    .single({
                        path: 'compra',
                        like: 'true'
                    })
                    .value();

            var defaultColumnOrderServicio = [
                "id",
                "codigo",
                "descripcion",
                "tipoImpuesto.descripcion"
            ];

            vm.opcionesServiciosDt = {
                staticFilter: filtroCompra,
                resource: "servicios",
                view: "ServicioList",
                title: "Servicio",
                factory: ServicioFactory,
                defaultColumnOrder: defaultColumnOrderServicio,
                columns: [
                    {data: "id", title: $filter("translate")("CODE")},
                    {data: "descripcion", title: $filter("translate")("DESCRIPTION")},
                    {
                        data: "tipoImpuesto.descripcion",
                        title: $filter("translate")("TAX_KIND")
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc"
            };

            var staticFilterProd = {};
            staticFilterProd.search = filterFactory.and([
                {
                    path: 'itemCompra',
                    like: 'true'
                },
                {
                    path: 'estado.codigo',
                    equals: "activo"
                }
            ]).value();
            WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
                vm.watcher = watcher;
//                vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProd));
            }, "locaciones.id");


            if ($state.includes("app.compraslocales.proceso")) {
                console.log("En Proceso de Compra Local");
                setCompra();
                vm.isProcesoCompra = true;
                var staticFilter = {};
                staticFilter.search = filterFactory.and([{
                        path: 'esImportacion',
                        like: 'false'
                    }, {
                        path: 'activo',
                        like: 'true'
                    }]).value();
                staticFilter.view = "BaseList";
                vm.tiposOrdenCompra = TipoOrdenesCompraFactory.all(staticFilter);
            } else if ($state.includes("app.importaciones.proceso")) {
                console.log("En Proceso de Importacion");
                setImportacion();
                vm.isProcesoImportacion = true;
                var staticFilter = {};
                staticFilter.search = filterFactory.and([{
                        path: 'esImportacion',
                        like: 'true'
                    }, {
                        path: 'activo',
                        like: 'true'
                    }]).value();
                staticFilter.view = "BaseList";
                vm.tiposOrdenCompra = TipoOrdenesCompraFactory.all(staticFilter);
            } else {
                var staticFilter = {};
                staticFilter.search = filterFactory.and([{
                        path: 'activo',
                        like: 'true'
                    }]).value();
                staticFilter.view = "BaseList";
                vm.tiposOrdenCompra = TipoOrdenesCompraFactory.all(staticFilter);
            }

            if ($state.includes("app.orden_compra.new") || $state.includes("app.importaciones.proceso.ordenescompra.new") ||
                    $state.includes("app.compraslocales.proceso.ordenescompra.new")) {
                activateNew();
            } else if ($state.is("app.orden_compra.edit") || $state.includes("app.importaciones.proceso.ordenescompra.edit") ||
                    $state.includes("app.compraslocales.proceso.ordenescompra.edit")) {
                activateEdit();
            } else if ($state.is("app.orden_compra.view") || $state.includes("app.importaciones.proceso.ordenescompra.view") ||
                    $state.includes("app.compraslocales.proceso.ordenescompra.view")) {
                activateView();
            }

        });

        //Consulta de parametros
        ParametrosFactory.getByCodigo("habilitar_oc_fecha_pasada").then(function (r) {
            vm.permitirOCFechaPasada = r.valorTipo.codigo == "si";
        });
        ParametrosFactory.getByCodigo("habilitar_oc_fecha_futura").then(function (r) {
            vm.permitirOCFechaFutura = r.valorTipo.codigo == "si";
        });

        ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
          vm.proyectoObligatorio = r.valorTipo.codigo == "si";
        });

        /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
        vm.staticFilterProv = [{path: "activo", equals: true}];
        vm.proveedoresFactory = ProveedoresFactory

        vm.fechaEsperadaRequired = false;
        ParametrosFactory.filterByCodigo("fecha_esperada_OC_requerida").$promise.then(function (data) {
            if (data[0].valorTipo.codigo == "si") {
                vm.fechaEsperadaRequired = true;
            }
        })

    }

    function activateNew() {
        vm.isNew = true;
        vm.title = $filter('translate')('NEW_ORDEN_COMPRA');
        vm.ordenCompra = {
            ordenCompraDetalle: [{}],
            tipoCambio: 1
        };

        vm.ordenCompra.numero = "El numero se asignará al guardar la OC";
        $timeout(function () {
            $scope.$broadcast('formOpened');
        }, 20);
        if (vm.isProcesoImportacion) {
            vm.ordenCompra.importaciones = [ImportacionFactory.getIdImportacion()];
        } else if (vm.isProcesoCompra) {
            vm.ordenCompra.compras = [CompraFactory.getIdCompra()];
        }
        CompradorFactory.getByUserActual().then(function (c) {
            vm.ordenCompra.comprador = c;
            setTiposEntidades();
        });
        getMonedaDefault().then(function (monedaDefault) {
            vm.monedas = {};
            TiposFactory.monedas().then(function (data) {
                vm.monedas = data.data;
                changeMoneda();
            });
        });
        getAlmacenesCurrentSucursal();

        TiposFactory.estadosOrdenCompraLocal().then(function (data) {
            var estadoIngresado = _.filter(data.data, function (elem) {
                return elem.codigo === 'ocl_ingresada';
            });
            vm.ordenCompra.estado = estadoIngresado[0];
        });
        vm.totalGeneral = 0;
        vm.totalDescuento = 0;


        var params = {};
        /* Se fuerza a que las horas del dia esten en cero para poder realizar
         la compraración sin errores.
         */
        // vm.ordenCompra.fecha = new Date();
        var hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        vm.ordenCompra.fecha = hoy;
    }

    function getCotizacion() {

        var paramsMoneda = {};
        paramsMoneda.search = filterFactory
                .single({
                    path: "codigo",
                    equals: "moneda_local"
                })
                .value();
        //vm.esMonedaLocal = true;
        vm.monedaLocal = ParametrosFactory.all(paramsMoneda);
        vm.monedaLocal.$promise.then(function (data) {

            // Seteo de bandera para bloqueo de campo
            if (vm.ordenCompra.moneda && vm.ordenCompra.moneda.descripcion != data[0].valorTipo.descripcion) {
                //vm.esMonedaLocal = false;
                vm.cotizacionRequerida = true;
            }else{
                vm.cotizacionRequerida = false;
                vm.ordenCompra.tipoCambio =1;
            }

            // Si tiene fecha y moneda distinta a la moneda local
            if (
                    vm.ordenCompra.moneda &&
                    vm.ordenCompra.fecha != undefined &&
                    vm.ordenCompra.moneda.descripcion != data[0].valorTipo.descripcion
                    ) {
                var params = {};
                params.search = filterFactory
                        .and([{
                                path: "monedaDesde.id",
                                equals: vm.ordenCompra.moneda.id
                            },
                            {
                                path: "monedaHasta.id",
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: "fecha",
                                like: moment(vm.ordenCompra.fecha).format("DD/MM/YYYY")
                            }
                        ])
                        .value();
                CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                    if (response && response[0]) {
                        // si hay cotización
                        vm.ordenCompra.tipoCambio = response[0].valorVenta;
                    }
                    else {
                        // si no encuentra cotizacion
                        notify({message: $filter("translate")("ERROR_TIPO_CAMBIO_COMPRAS"), classes: "alert-danger", position: "right"});

                        // si esta en modo NEW, no hay valor guardado, entonces se puede vaciar el tipo de cambio
                        if(vm.isNew){
                            vm.ordenCompra.tipoCambio = null;
                            vm.ordenCompra.tipoCambioView = null;
                        }
                        // si esta en modo edit o ver, siempre hay un valor guardado, simplemente se debe quedar con ese valor

                    }
                });
            } else {
                if (vm.ordenCompra.fecha != undefined && vm.ordenCompra.moneda != undefined) {
                    vm.ordenCompra.tipoCambio = 1;
                } else {
                    vm.ordenCompra.tipoCambio = null;
                }
            }
        });
    }

    function evaluatePeriodoAdministrativo(fecha) {
        return $q(function (resolve, reject) {
            var params = {};
            params.search = filterFactory
                    .and([{
                            path: "estado.codigo",
                            equals: "per_adm_abierto"
                        },
                        {
                            path: "fechaDesde",
                            equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                        },
                        {
                            path: "fechaHasta",
                            equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                        }
                    ])
                    .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {

                if (responsePeriodo.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        });
    }

    function validate() {
        return $q(function (resolve, reject) {

            validateFechas().then(function (response) {
                var msg = response;
                console.log("MENSAJE TRAS VALIDATEAMOUNTS 1: ");
                if (msg) {
                    console.log("aqui mensaje: ", msg);
                    resolve(msg);
                    return;
                }
                console.log("AQUI LLEGO validate: ");
                resolve(undefined);
            });
        });
    }

    function activateView() {
        vm.title = $filter('translate')('VIEW_ORDEN_COMPRA');
        vm.ordenCompra = ordenCompraPrepService;
        vm.entidadId = vm.ordenCompra.id;
        vm.entidad = "OrdenCompra";
        vm.ordenCompra.fecha = new Date(vm.ordenCompra.fecha);
        if (vm.ordenCompra.fechaEsperada) {
            vm.ordenCompra.fechaEsperada = new Date(vm.ordenCompra.fechaEsperada);
        }
        if (vm.ordenCompra.solicitudCompra !== null) {
            $scope.fec = moment.utc(vm.ordenCompra.solicitudCompra.fecha).format('DD/MM/YYYY');
            vm.ordenCompra.solicitudCompra.fecha = $scope.fec;
        }
        vm.view = true;

        var staticFilterProd = {};
        staticFilterProd.search = filterFactory.and([
            {
                path: 'proveedores.codigo',
                equals: vm.ordenCompra.proveedor.codigo
            },
            {
                path: 'itemCompra',
                like: 'true'
            }
        ]).value();

        if (!vm.ordenCompra.comprador) {
            CompradorFactory.getByUserActual().then(function (c) {
                console.log(c);
                vm.ordenCompra.comprador = c;
                setTiposEntidades();
            });
        }

        if (vm.watcher) {
            vm.watcher();
        } //hacemos unwatch de la expresion anterior
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
            vm.watcher = watcher;
//            vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProd));
            vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProd);
        }, "locaciones.id");
        getMonedaDefault().then(function (monedaDefault) {
            vm.monedas = {};
            TiposFactory.monedas().then(function (data) {
                vm.monedas = data.data;
            });
        });
        setTiposEntidades();

        //Redondeo de montos en detalles
        if (vm.ordenCompra.moneda.codigo != "guaranies") {
            vm.totalGeneral = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
                return sum + parseFloat(detalle.totalItem.toFixed(4));
            }, 0);
            vm.totalDescuento = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
                return sum + parseFloat(detalle.totalDescuento.toFixed(4));
            }, 0);
            // vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(4));
            //vm.totalDescuento = parseFloat(vm.totalDescuento.toFixed(4));
        } else {
            vm.totalGeneral = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
                detalle.totalItem = parseFloat(detalle.totalItem.toFixed(4));
                return sum + detalle.totalItem;
            }, 0);
            vm.totalDescuento = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
                detalle.totalDescuento = parseFloat(detalle.totalDescuento.toFixed(4));
                $scope.sumaDatos = sum + detalle.totalDescuento;
                return parseFloat($scope.sumaDatos.toFixed(4));
            }, 0);
        }
        vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(4));
        vm.totalDescuento = parseFloat(vm.totalDescuento.toFixed(4));
    }

    function activateEdit() {
        vm.title = $filter('translate')('EDIT_ORDEN_COMPRA');
        vm.ordenCompra = ordenCompraPrepService;
        vm.entidadId = vm.ordenCompra.id;
        vm.entidad = "OrdenCompra";
        vm.ordenCompra.fecha = new Date(vm.ordenCompra.fecha);
        if (vm.ordenCompra.fechaEsperada) {
            vm.ordenCompra.fechaEsperada = new Date(vm.ordenCompra.fechaEsperada);
        }
        if (vm.ordenCompra.solicitudCompra !== null) {
            $scope.fec = moment.utc(vm.ordenCompra.solicitudCompra.fecha).format('DD/MM/YYYY');
            vm.ordenCompra.solicitudCompra.fecha = $scope.fec;
        }

        var staticFilterProd = {};
        staticFilterProd.search = filterFactory.and([
            {
                path: 'proveedores.id',
                equals: vm.ordenCompra.proveedor.id
            },
            {
                path: 'itemCompra',
                like: 'true'
            },
            {
                path: 'estado.codigo',
                equals: "activo"
            }
        ]).value();

        if (!vm.ordenCompra.comprador) {
            CompradorFactory.getByUserActual().then(function (c) {
                console.log(c);
                vm.ordenCompra.comprador = c;
                setTiposEntidades();
            });
        }


        if (vm.watcher) {
            vm.watcher();
        } //hacemos unwatch de la expresion anterior
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
            if (!vm.watcher) {
                vm.watcher();
            }
//            vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProd));
            vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProd);
        }, "locaciones.id");
        getMonedaDefault().then(function (monedaDefault) {
            vm.monedas = {};
            TiposFactory.monedas().then(function (data) {
                vm.monedas = data.data;
            });
        });
        getAlmacenesCurrentSucursal();
        setTiposEntidades();

        //Redondeo de montos en detalles
        if (vm.ordenCompra.moneda.codigo != "guaranies") {
            vm.totalGeneral = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
                return sum + parseFloat(detalle.totalItem.toFixed(4));
            }, 0);
            vm.totalDescuento = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
                return sum + parseFloat(detalle.totalDescuento.toFixed(4));
            }, 0);
           // vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(4));
           // vm.totalDescuento = parseFloat(vm.totalDescuento.toFixed(4));
        } else {
            vm.totalGeneral = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
                detalle.totalItem = parseFloat(detalle.totalItem.toFixed(4));
                return sum + detalle.totalItem;
            }, 0);
            vm.totalDescuento = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
                detalle.totalDescuento = parseFloat(detalle.totalDescuento.toFixed(4));
                $scope.sumaDatos = sum + detalle.totalDescuento;
                return parseFloat($scope.sumaDatos.toFixed(4));
            }, 0);
        }

        vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(4));
        vm.totalDescuento = parseFloat(vm.totalDescuento.toFixed(4));

        $timeout(function () {
            $scope.$broadcast('formOpened');
        }, 20);
        vm.isEdit = true;
        vm.tiposDetalles = [];

        if (vm.ordenCompra.tipoOrdenCompra.incluyeProducto || vm.ordenCompra.tipoOrdenCompra.esImportacion) {
            vm.tiposDetalles.push({id: 1, nombre: $filter('translate')('ITEM_TIPO_PRODUCTO')});
        }

        if (vm.ordenCompra.tipoOrdenCompra.incluyeServicio || vm.ordenCompra.tipoOrdenCompra.esImportacion) {
            vm.tiposDetalles.push({id: 2, nombre: $filter('translate')('SERVICE')})
        }

        var fechaEnviar = new Date(vm.ordenCompra.fecha);
        fechaEnviar.setHours(0, 0, 0, 0);

        evaluatePeriodoAdministrativo(fechaEnviar);

        $scope.$watch("vm.ordenCompra.tipoOrdenCompra", function (newValue, oldValue) {
            if (newValue === oldValue) {
                if (newValue.esImportacion) {
                    vm.monedas = {};
                    TiposFactory.monedas().then(function (data) {
                        vm.monedas = data.data;
                        ParametrosFactory.getByCodigo('moneda_local').then(function (data) {
                            _.remove(vm.monedas, function (m) {
                                return (data.valorTipo.id == m.id);
                            });
                        });
                    });
                } else {
                    getMonedaDefault().then(function (monedaDefault) {
                        vm.monedas = {};
                        TiposFactory.monedas().then(function (data) {
                            vm.monedas = data.data;
                            changeMoneda();
                        });
                    });
                }
            }
        });
    }

    function setImportacion() {
        var importacion = ImportacionFactory.getImportacion();
        if (!importacion) {
            $state.go("app.main");
        }
        var isStateEnabled = ImportacionFactory.checkPermissionForState(0);
        if (!isStateEnabled) {
            console.log("No tiene permiso para revisar esta seccion");
            $state.go("app.main");
        }
    }

    function setCompra() {
        var compra = CompraFactory.getCompra();
        if (!compra) {
            $state.go("app.main");
        }
        var isStateEnabled = CompraFactory.checkPermissionForState(0);
        if (!isStateEnabled) {
            console.log("No tiene permiso para revisar esta seccion");
            $state.go("app.main");
        }
    }


    function getMonedaDefault() {
        return $q(function (resolve, reject) {
            var moneda = ParametrosFactory.filterByCodigo("moneda_local");
            moneda.$promise.then(function (data) {
                if (data && data.length > 0) {
                    if (!vm.ordenCompra.moneda) {
                        vm.ordenCompra.moneda = data[0].valorTipo;
                    }
                    vm.monedaDefault = data[0].valorTipo;
                    resolve(vm.monedaDefault);
                } else {
                    reject();
                }
            }, reject);
        });
    }

    function changeMoneda(manualBoolean) {
        getCotizacion();
        if(manualBoolean) {
            vm.ordenCompra.ordenCompraDetalle = [{}];
        }
    }

    function getAlmacenesCurrentSucursal() {
        if (vm.watcherAlmacen) {
            vm.watcherAlmacen();
        } //hacemos unwatch de la expresion anterior
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcherAlmacen) {
            vm.watcherAlmacen = watcherAlmacen;
            var filterSucursal = {
                search: filterFactory.and([
                    {
                        path: "activo",
                        equals: true
                    },
                    {
                        path: "sucursal.id",
                        equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                    }
                ]).value()
            };
            vm.almacenes = AlmacenFactory.all(filterSucursal);
        }, "locacion.id");
    }

    function setTiposEntidades() {
        if (!vm.ordenCompra || !vm.ordenCompra.ordenCompraDetalle) {
            return;
        }

        _.forEach(vm.ordenCompra.ordenCompraDetalle, function (detalle) {
            if (detalle.producto) {
                detalle.tipo = {id: 1, nombre: $filter('translate')('ITEM_TIPO_PRODUCTO')};
            }
            if (detalle.servicio) {
                detalle.tipo = {id: 2, nombre: $filter('translate')('SERVICE')};
            }
        });
    }

    function changeProveedor() {
        delete vm.ordenCompra.proveedorSucursal;
        var filter = {};
        filter.search = filterFactory.single({
            path: 'proveedor.id',
            equals: vm.ordenCompra.proveedor.id
        }).value();
        filter.view = "BaseList"
        ProveedorSucursalesFactory.all(filter).$promise.then(function (proveedores) {
            vm.sucursalesProveedor = proveedores;
            if (proveedores.length == 1) {
                vm.ordenCompra.proveedorSucursal = proveedores[0];
            }

        });
        filterProductos();
    }

    function mostrarTodosProductos() {
        if (vm.todosProductos == true) {
            var staticFilterProd = {};
            staticFilterProd.search = filterFactory.and([
                {
                    path: 'incluirEnOrdenesCompra',
                    like: 'true'
                },
                {
                    path: 'estado.codigo',
                    equals: "activo"
                }
            ]).value();
            if (vm.watcher) {
                vm.watcher();
            } //hacemos unwatch de la expresion anterior
            WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
                vm.watcher = watcher;
//                vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProd));
                vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProd);
            }, "locaciones.id");
        } else {
            console.log("En el else, se hace filterProductos()")
            filterProductos();
        }
    }

    function filterProductos() {
        var staticFilterProd = {};
        staticFilterProd.search = filterFactory.and([
            {
                path: 'proveedores.codigo',
                equals: vm.ordenCompra.proveedor.codigo
            },
            {
                path: 'incluirEnOrdenesCompra',
                like: 'true'
            },
            {
                path: 'estado.codigo',
                equals: "activo"
            }
        ]).value();

        if (vm.watcher) {
            vm.watcher();
        } //hacemos unwatch de la expresion anterior
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
            vm.watcher = watcher;
//            vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProd));
            vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProd);
        }, "locaciones.id");
    }

    function agregarDetalle() {
        vm.ordenCompra.ordenCompraDetalle.push({
            montosImponibles: [{}]
        });
        var rowNumber = vm.ordenCompra.ordenCompraDetalle.length - 1;
        $timeout(function () {
            $scope.$broadcast('newItemAdded');
        }, 20);
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        vm.totalGeneral = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
            return sum + detalle.totalItem;
        }, 0);
        vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(4));
        vm.totalDescuento = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
            return sum + detalle.totalDescuento;
        }, 0);
        vm.totalDescuento = parseFloat(vm.totalDescuento.toFixed(4));
        $timeout(function () {
            $scope.$broadcast('newItemRemoved');
        }, 20);
    }

    function searchSolicitudCompraFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.numero == criteria;
        };
    }

    function searchProductoFilter(criteria) {
        if (criteria.length >= 3) {
            return function (item) {
                if (!criteria) {
                    return true;
                }

                return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
            };
        }
    }

    function calcularImpuesto(detalle, accordingTo) {
        if (accordingTo === "valor") {
            detalle.totalItemExento = detalle.totalItem - detalle.montosImponibles[0].valor;
        } else if (accordingTo === "exento") {
            detalle.montosImponibles[0].valor = detalle.totalItem - detalle.totalItemExento;
        }
    }

    function calcularTotalDetalle(detalle) {

        if (detalle.cantidad == undefined) {
            detalle.totalItem = 0;
            detalle.totalDescuento = 0;
            return;
        }
        if (detalle.ultimoPrecio == undefined) {
            detalle.totalItem = 0;
            detalle.totalDescuento = 0;
            return;
        }
        // Total item sin descuento
        detalle.totalItem = parseFloat((detalle.cantidad * detalle.ultimoPrecio).toFixed(4));

        // Calculo descuento
        if (detalle.porcentajeDescuento != null && detalle.porcentajeDescuento != undefined) {
            detalle.totalDescuento = parseFloat((detalle.totalItem * (detalle.porcentajeDescuento / 100)).toFixed(4));
        } else {
            detalle.totalDescuento = 0;
            detalle.porcentajeDescuento = 0;
        }

        // Total item con descuento
        detalle.totalItem = parseFloat((detalle.totalItem - detalle.totalDescuento).toFixed(4));

        //detalle.montosImponibles[0].valor = 0;
        detalle.totalItemExento = detalle.cantidad * detalle.ultimoPrecio;
        vm.totalGeneral = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
            return sum + detalle.totalItem;
        }, 0);
        vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(4));
        vm.totalDescuento = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
            return sum + detalle.totalDescuento;
        }, 0);
        vm.totalDescuento = parseFloat(vm.totalDescuento.toFixed(4));
    }

    function changeProducto(detalle) {
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        detalle.cantidad = 0;
        detalle.totalDescuento = 0;
        detalle.porcentajeDescuento = 0;
        detalle.ultimoPrecio = null;
        detalle.ultimoPrecioView = null;

        OrdenesCompraDetalleFactory.getUltimosCosto2(detalle.producto.id, vm.ordenCompra.moneda.codigo).then(function (r) {
            if (r.data) {
                if(vm.monedaLocal){

                    // Si la moneda de la OC es igual a la moneda local
                    if(r.data.moneda.id == vm.monedaLocal.id){
                        detalle.ultimoPrecio = r.data.ultimoPrecio
                    }else{
                        detalle.ultimoPrecio = r.data.valor;
                    }
                }
            }
        });

        detalle.totalItemExento = 0;
        detalle.totalItem = 0;
    }

    function recuperarProducto(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
                vm.finalFilters, userInputFilter)

        vm.productos = ProductosFactory.allForSelect(userInputFilter)
    }

    function delayRequestProducto(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {

            var filterNombre = [];
            var filterCodigoBarras = [];
            var filterCodigoInterno = [];

            filterNombre.push({
                path: "nombre",
                like: searchValue
            },
            {
              path: 'estado.codigo',
              equals: 'activo'
            },
            {
              path: "locaciones.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            filterCodigoBarras.push({
                path: "codigoBarrasPrincipal.codigoBarras",
                like: searchValue
            },
            {
              path: 'estado.codigo',
              equals: 'activo'
            },
            {
              path: "locaciones.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            filterCodigoInterno.push({
                path: "codigo",
                like: searchValue
            },
            {
              path: 'estado.codigo',
              equals: 'activo'
            },
            {
              path: "locaciones.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }

            recuperarProducto(userInputFilter)
        } else if (searchValue.length > 0 && searchValue.length < 3 && vm.recepcion.proveedor) {

            var filterCodigoInterno = vm.staticFilter.slice()
            filterCodigoInterno.push({
                path: "codigo",
                like: searchValue
            },
            {
              path: 'estado.codigo',
              equals: 'activo'
            },
            {
              path: "locaciones.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }
            recuperarProducto(userInputFilter)
        } else {
            vm.productos = undefined
        }
    }

    function changeServicio(detalle) {
        detalle.cantidad = 1;
        detalle.totalDescuento = 0;
        detalle.porcentajeDescuento = 0;
        detalle.ultimoPrecio = 0;
        detalle.totalItemExento = 0;
        detalle.totalItem = 0;
    }

    function tieneDetallesDuplicados() {
        var tieneDup = false;
        _.forEach(vm.ordenCompra.ordenCompraDetalle, function (val, idx) {
            _.forEach(vm.ordenCompra.ordenCompraDetalle, function (val2, idx2) {
                if (val.producto && val2.producto && val.producto.id == val2.producto.id && idx != idx2) {
                    tieneDup = true;
                    return false;
                } else if (val.servicio && val2.servicio && val.servicio.id == val2.servicio.id && idx != idx2) {
                    tieneDup = true;
                    return false;
                }
            });
        });
        return tieneDup;
    }

    function submit() {
        vm.submited = true;
        var newDate = new Date();
        newDate.setHours(0, 0, 0, 0);

        $rootScope.isProcessing = true;

        evaluatePeriodoAdministrativo(vm.ordenCompra.fecha).then(function (response) {

            if (response) {
                if ($scope.OrdenCompraForm.$valid) {
                    //vm.compradores = CompradorFactory.all();

                    //Verificacion Fecha pasada
                    if (vm.permitirOCFechaPasada === false) {
                        if (vm.ordenCompra.fecha.getTime() < newDate.getTime()) {
                            $rootScope.isProcessing = false;
                            notify({message: $filter("translate")("ERROR_FECHA_PASADA"), classes: "alert-danger", position: "right"});
                            return;
                        }
                    }

                    //Verificacion Fecha futura
                    if (vm.permitirOCFechaFutura === false) {
                        if (vm.ordenCompra.fecha.getTime() > newDate.getTime()) {
                            $rootScope.isProcessing = false;
                            notify({message: $filter("translate")("ERROR_FECHA_FUTURA"), classes: "alert-danger", position: "right"});
                            return;
                        }
                    }

                    CompradorFactory.getByUserActual().then(function (c) {
                        vm.ordenCompra.comprador = c;

                        var fechaCreacion = new Date(vm.ordenCompra.fecha);
                        var fechaEsperada = vm.ordenCompra.fechaEsperada ? new Date(vm.ordenCompra.fechaEsperada) : undefined;
                        var montosOrdenCompra = _.filter(vm.ordenCompra.ordenCompraDetalle, function (detalle) {
                            return detalle.totalItem == 0;
                        });

                        if (tieneDetallesDuplicados()) {
                            $rootScope.isProcessing = false;
                            var msg = $filter('translate')('DUPLICATED_PRODUCT_ERROR');
                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                        } else {
                            // console.log("Montos Orden Compra: ", montosOrdenCompra);
                            if (((fechaEsperada === undefined || fechaEsperada === null || fechaCreacion <= fechaEsperada) ||
                                    (vm.ordenCompra.tipoOrdenCompra && vm.ordenCompra.tipoOrdenCompra.esImportacion)) && montosOrdenCompra.length == 0) {
                                $rootScope.isProcessing = true;
                                vm.ordenCompra.ordenCompraDetalle = _.filter(vm.ordenCompra.ordenCompraDetalle, function (elem) {
                                    return elem.producto || elem.servicio;
                                });
                                submitOrdenCompra().then(function (response) {
                                    if (vm.isProcesoImportacion) {
                                        // console.log("Es Proceso de Importacion");
                                        // TODO verificar si es siempre la primera importacion
                                        ImportacionFactory.setIdImportacion(response.importaciones[0].id);
                                        ImportacionFactory.setCodigoImportacion(response.importaciones[0].codigo);
                                        ImportacionFactory.setImportacion(response.importaciones[0]);
                                        $scope.$emit("updateImportacionStates");
                                        $state.go('app.importaciones.proceso.ordenescompra.list', {codigoImportacion: ImportacionFactory.getIdImportacion()});
                                    } else if (vm.isProcesoCompra) {
                                        // console.log("Es proceso de Compra");
                                        CompraFactory.setIdCompra(response.compras[0].id);
                                        CompraFactory.setCodigoCompra(response.compras[0].codigo);
                                        CompraFactory.setCompra(response.compras[0]);
                                        $scope.$emit("updateCompraStates");
                                        $state.go('app.compraslocales.proceso.ordenescompra.list', {codigoCompra: CompraFactory.getIdCompra()});
                                    } else {
                                        // console.log("No está en ningún proceso.")
                                        $state.go("app.orden_compra.list");
                                    }
                                    return response;
                                }, function (error) {
                                    $rootScope.isProcessing = false;
                                    var msg = $filter('translate')('ERROR_SAVING');
                                    if (error.data.length > 0 && error.data[0].message) {
                                        var msg = error.data[0].message;
                                    }
                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                });
                            } else {
                                var msg = "";
                                $rootScope.isProcessing = false;
                                if (montosOrdenCompra.length != 0) {
                                    msg = $filter('translate')('OC_LOCAL_INVALID_MONTO');
                                } else {
                                    msg = $filter('translate')('OC_LOCAL_INVALID_DATES');
                                }
                                notify({message: msg, classes: 'alert-danger', position: 'right'});
                            }
                        }
                    });

                } else {
                    $rootScope.isProcessing = false;
                }
            } else {
                var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                $rootScope.isProcessing = false;
                notify({message: msg, classes: 'alert-danger', position: 'right'});

            }
        });
    }

    function tipoDetalleSelected(detalle) {
        delete detalle.producto;
        delete detalle.servicio;
        delete detalle.unidadMedida;
    }

    function tipoOrdenCompraChanged() {

        if (!vm.ordenCompra.tipoOrdenCompra) {
            return;
        }

        vm.tiposDetalles = [];

        if (vm.ordenCompra.tipoOrdenCompra.incluyeProducto || vm.ordenCompra.tipoOrdenCompra.esImportacion) {
            vm.tiposDetalles.push({id: 1, nombre: $filter('translate')('ITEM_TIPO_PRODUCTO')});
        }

        if (vm.ordenCompra.tipoOrdenCompra.incluyeServicio || vm.ordenCompra.tipoOrdenCompra.esImportacion) {
            vm.tiposDetalles.push({id: 2, nombre: $filter('translate')('SERVICE')})
        }

        if (!vm.ordenCompra.tipoOrdenCompra.esImportacion && !vm.ordenCompra.comprador) {
            var msg = $filter('translate')('COMPRADOR_NOT_FOUND');
            notify({message: msg, classes: 'alert-danger', position: 'right'});
        }
        //vm.ordenCompra.tipoOrdenCompra.incluyeProducto = false;
        //vm.ordenCompra.tipoOrdenCompra.incluyeServicio = false;
        console.log(vm.ordenCompra.tipoOrdenCompra);

        // Si tiene producto y servicio
        if ((vm.ordenCompra.tipoOrdenCompra.incluyeProducto && vm.ordenCompra.tipoOrdenCompra.incluyeServicio)) {
            vm.ordenCompra.ordenCompraDetalle[0].tipo = {};
        } else {
            // Si tiene producto y no tiene servicio
            if ((vm.ordenCompra.tipoOrdenCompra.incluyeProducto && !vm.ordenCompra.tipoOrdenCompra.incluyeServicio)) {
                vm.ordenCompra.ordenCompraDetalle[0].tipo = {id: 1, nombre: $filter('translate')('ITEM_TIPO_PRODUCTO')};
            }
            // Si tiene servicio y no tiene producto
            if ((!vm.ordenCompra.tipoOrdenCompra.incluyeProducto && vm.ordenCompra.tipoOrdenCompra.incluyeServicio)) {
                vm.ordenCompra.ordenCompraDetalle[0].tipo = {id: 2, nombre: $filter('translate')('SERVICE')};
            }
        }

        if (vm.ordenCompra.tipoOrdenCompra.esImportacion) {
            ParametrosFactory.getByCodigo('moneda_local').then(function (data) {
                _.remove(vm.monedas, function (m) {
                    return (data.valorTipo.id == m.id);
                });
            });
            vm.ordenCompra.moneda = null;
        } else {
            getMonedaDefault().then(function (monedaDefault) {
                vm.monedas = {};
                TiposFactory.monedas().then(function (data) {
                    vm.monedas = data.data;
                    changeMoneda();
                });
            });
        }
    }


    function submitOrdenCompra() {
        var trimmedModel = ModelTrimmer.trimDetails(vm.ordenCompra, ["ordenCompraDetalle"]);
        var trimmedDetails = _.map(vm.ordenCompra.ordenCompraDetalle, function (detalle) {
            var trimmedDetail = ModelTrimmer.trimDetails(detalle);
            delete trimmedDetail.tipo;
            return trimmedDetail;
        });
        trimmedModel.ordenCompraDetalle = trimmedDetails;
        if (!vm.isEdit) {
            trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        }
        var resource = OrdenesCompraFactory.create(trimmedModel);
        return OrdenesCompraFactory.save(resource);
    }

    function cancel() {
        if (vm.isProcesoImportacion) {
            $state.go('app.importaciones.proceso.ordenescompra.list', {codigoImportacion: ImportacionFactory.getIdImportacion()});
        } else if (vm.isProcesoCompra) {
            $state.go('app.compraslocales.proceso.ordenescompra.list', {codigoCompra: CompraFactory.getIdCompra()});
        } else {
            $location.path("/ordenescompra");
        }
    }

    function totalItemChanged(detalle) {

        if (detalle.cantidad == undefined) {
            detalle.cantidad = 1;
        }

        detalle.ultimoPrecio = detalle.totalItem / detalle.cantidad;
        detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(4));
        detalle.porcentajeDescuento = 0;
        detalle.totalDescuento = 0;

        vm.totalGeneral = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
            return sum + detalle.totalItem;
        }, 0);
        vm.totalGeneral = parseFloat(vm.totalGeneral.toFixed(4));

        vm.totalDescuento = _.reduce(vm.ordenCompra.ordenCompraDetalle, function (sum, detalle) {
            return sum + detalle.totalDescuento;
        }, 0);
        vm.totalDescuento = parseFloat(vm.totalDescuento.toFixed(4));
    }

}

'use strict';


angular.module('qualita.compra')
  .controller('RubroIvaListCtrl', ["AuthorizationService", "RubroIvaFactory", "TiposFactory", "$log", "$modal", "$scope", "CompraLocalLangFactory", "$filter", "UtilFactory", "$window", "CsvFactory", "baseurl", function (AuthorizationService, RubroIvaFactory, TiposFactory,
  $log, $modal, $scope, CompraLocalLangFactory, $filter, UtilFactory, $window, CsvFactory, baseurl) {

  var defaultColumnOrder = ['id','descripcion','tipoOperacion.descripcion'];

  CompraLocalLangFactory.getTranslations().then(function(translations) {
    $scope.options = {
      'resource': 'rubroiva',
      'title': $filter('translate')('RUBROS_IVA'),
      'factory': RubroIvaFactory,
      'view': 'RubroIvaList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS'),
      'columns': [
        {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
        {'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION'), 'renderWith': 'emptyRender'},
        {'data': 'tipoOperacion.descripcion', 'title': $filter('translate')('TIPO_OPERACION'), 'renderWith': 'emptyRender'}
      ],
      'hasOptions' : true,
      'defaultOrderColumn' : 0,
      'defaultOrderDir' : "asc",
      'extraMenuOptions':[
        {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("rubroiva", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
        }
      ]
    };
  });

}]);

'use strict';

angular.module('qualita.compra')
  .controller('RubroIvaFormCtrl', ["RubroIvaFactory", "filterFactory",
  "$modal", "$scope", "notify", "$state", "$rootScope","rubroIvaPrepService", "$controller", "formFactory",
    "CompraLocalLangFactory", "$filter",
  function (RubroIvaFactory, filterFactory, $modal, $scope, notify, $state, $rootScope,
    rubroIvaPrepService, $controller, formFactory, CompraLocalLangFactory, $filter) {

      $scope.resource = 'rubroiva';
      $scope.resources = 'rubroiva';
      $scope.factory = RubroIvaFactory;
      $scope.prepService = rubroIvaPrepService;
      var self = this;
      CompraLocalLangFactory.getTranslations().then(function(translations) {
        $scope.newProperties = {
          state: "app.rubroiva.new",
          title: $filter('translate')('NEW_RUBRO_IVA'),
        };
        $scope.editProperties = {
          state: "app.rubroiva.edit",
          title: $filter('translate')('EDIT_RUBRO_IVA'),
          entidad: "Rubro de Iva"
        };

        $scope.viewProperties = {
          state: "app.rubroiva.view",
          title: $filter('translate')('VIEW_RUBRO_IVA'),
          entidad: "Rubro de Iva"
        };

        angular.extend(self, $controller('BasicController', {$scope: $scope}));
        $scope.activate();

        $scope.submit = function(form) {
          formFactory.defaultSubmit('rubroiva', $scope, form, RubroIvaFactory, undefined, errorHandler);
        };

        function errorHandler(e) {
          var msg = '';
          _.forEach(e.data, function(error) {
            if (error.constraint == 'descripcion') {
              msg += '\n\n' + $filter('translate')('RUBRO_IVA_MISMA_DESCRIPCION_WARNING') + "\n";
            } else {
              msg += '\n\n' + error.message + '.';
            }
          });
          notify({
            message: msg,
            classes: 'alert-danger',
            position: 'right'
          });
        }
      });
}]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.compra')
  .factory('RubroIvaFactory', RubroIvaFactory);

RubroIvaFactory.$inject = ['$resource', 'baseurl', '$http', 'UsuariosFactory', 'CompraLocalLangFactory', '$filter', 'TiposFactory'];

function RubroIvaFactory($resource, baseurl, $http, UsuariosFactory, CompraLocalLangFactory, $filter, TiposFactory) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    schema: schema,
    form: form
  };

  var RubroIva = $resource( baseurl.getBaseUrl() + "/rubroiva/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  var schema = undefined;

  CompraLocalLangFactory.getTranslations().then(function(translations) {
    schema = {
      type: 'object',
      title: $filter('translate')('RUBRO_IVA'),

      properties: {
        descripcion: {
          title: $filter('translate')('DESCRIPTION'),
          type: 'string',
          maxLength: 255,
          validationMessage: {
            201: $filter('translate')('LONG_VALUE_WARNING')
          },
        },
        tipoOperacion: {
          title: $filter('translate')('TIPO_OPERACION'),
          type: 'number',
          validationMessage: $filter('translate')('REQUIRED_FIELD')
        }
      },

      required: ['descripcion', 'tipoOperacion']
    };
  });


    var formNew = [
      'descripcion',
      {
          key: 'tipoOperacion',
          //placeholder: 'Seleccione una moneda',
          type: 'uiselect',
          options: {
              asyncCallback: function(options, search) {
                  var params = {};
                  if (search) {
                      params.search = filterFactory.single({
                          path: 'descripcion',
                          like: search
                      }).value();
                  }
                  return TiposFactory.rubroTipoOperacion().then(function(data) {
                      return { data: data.data };
                  });
              },
              map: { valueProperty: 'id', nameProperty: 'descripcion' },
              formName: 'rubroivaForm'
          }
      }
    ];

    var formEdit = angular.copy(formNew);
    var formView = angular.copy(formNew);

    var forms = {
      'new': formNew,
      'edit': formEdit,
      'view': formView
    }

  return service;

  function all(params) {
    return RubroIva.query(params);
  }

  function get(id, view) {
    return RubroIva.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new RubroIva(attrs);
  }

  function save(rubroiva) {
    return (rubroiva.id) ? rubroiva.$update() : rubroiva.$save();
  }

  function remove(rubroiva) {
    return rubroiva.$remove();
  }


 function schema() {
    return schema;
  }

  function  form(type) {
    return forms[type];
  }

}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.compra')
  .factory('TipoCompraFactory', TipoCompraFactory);

TipoCompraFactory.$inject = ['$resource', 'baseurl', '$http', 'CompraLocalLangFactory'];

function TipoCompraFactory($resource, baseurl, $http, CompraLocalLangFactory) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var TipoCompras = $resource( baseurl.getBaseUrl() + "/tiposcompras/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });


  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'base';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'base';
    }
    return TipoCompras.query(params);
  }

  function get(id, view) {
    return TipoCompras.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new TipoCompras(attrs);
  }

  function save(tipocompra) {
    return (tipocompra.id) ? tipocompra.$update() : tipocompra.$save();
  }

  function remove(tipocompra) {
    return tipocompra.$remove();
  }
}

"use strict";

angular
    .module("qualita.compra")
    .controller("RecepcionLocalFormCtrl", RecepcionLocalFormCtrl);

RecepcionLocalFormCtrl.$inject = [
    "recepcionCompraPrepService",
    "OrdenesCompraFactory",
    "ProductosFactory",
    "AlmacenFactory",
    "RecepcionFactory",
    "$scope",
    "notify",
    "$state",
    "$rootScope",
    "filterFactory",
    "TipoRecepcionFactory",
    "ReportTicketFactory",
    "$window",
    "$timeout",
    "ProveedoresFactory",
    "ParametrosFactory",
    "Util",
    "CompraLocalLangFactory",
    "$translate",
    "$q",
    "ClaseProductoFactory",
    "TipoImpuestosFactory",
    "CompraFactory",
    "ComprobanteFactory",
    "WatchLocacionChangeFactory",
    "UtilFactory",
    "GruposProductosFactory",
    "$filter",
    "PeriodoAdministrativoFactory",
    "RecepcionDetalleFactory",
    "ComprobanteDetalleFactory",
    "AuthorizationService",
    "ProyectoFactory"
];

function RecepcionLocalFormCtrl(
    recepcionCompraPrepService,
    OrdenesCompraFactory,
    ProductosFactory,
    AlmacenFactory,
    RecepcionFactory,
    $scope,
    notify,
    $state,
    $rootScope,
    filterFactory,
    TipoRecepcionFactory,
    ReportTicketFactory,
    $window,
    $timeout,
    ProveedoresFactory,
    ParametrosFactory,
    Util,
    CompraLocalLangFactory,
    $translate,
    $q,
    ClaseProductoFactory,
    TipoImpuestosFactory,
    CompraFactory,
    ComprobanteFactory,
    WatchLocacionChangeFactory,
    UtilFactory,
    GruposProductosFactory,
    $filter,
    PeriodoAdministrativoFactory,
    RecepcionDetalleFactory,
    ComprobanteDetalleFactory,
    AuthorizationService,
    ProyectoFactory
) {
    var vm = this;
    vm.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    activate();

    vm.agregarDetalle = agregarDetalle;
    vm.ordenCompraSelected = ordenCompraSelected;
    vm.comprobanteSelected = comprobanteSelected;
    vm.productoSelected = productoSelected;
    vm.removeItemFromArray = removeItemFromArray;
    vm.searchProductoFilter = searchProductoFilter;
    vm.mostrarEnBase = mostrarEnBase;
    vm.actualizarTotalGeneralUnidades = actualizarTotalGeneralUnidades;
    vm.changeTipoRecepcion = changeTipoRecepcion;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.recepcionGeneraMovimiento = false;
    vm.evaluateNroGrupo = evaluateNroGrupo;
    vm.validacionCantidadRecibida = validacionCantidadRecibida;
    vm.delayRequestProducto = delayRequestProducto;
    vm.tieneOc = false;
    vm.verificarExistencia = verificarExistencia;
    vm.changeFecha = changeFecha;
    $rootScope.isProcessing = false;

    function changeFecha(detalle) {
        var params = {};
        params.search = filterFactory.and([{
            path: 'numero',
            equals: detalle.numeroGrupo
        },
        {
            path: 'producto.id',
            equals: detalle.producto.id
        }
        ]).value();
        GruposProductosFactory.all(params).$promise.then(function (response) {
            //buscar si existe en grupo productos
            if (response.length > 0) {
                notify({
                    message: "El nro. grupo ya cuenta con fecha de vencimiento, no puede cambiar.",
                    classes: "alert-warning",
                    position: "right"
                });
                detalle.fechaVencimientoGrupo = moment(response[0].fechaVencimiento, "DD/MM/YYYY").toDate();
            }
        })
    }

    function verificarExistencia(detalle) {
        if (detalle.numeroGrupo != null) {
            var params = {};
            params.search = filterFactory.and([{
                path: 'numero',
                equals: detalle.numeroGrupo
            },
            {
                path: 'producto.id',
                equals: detalle.producto.id
            }
            ]).value();
            GruposProductosFactory.all(params).$promise.then(function (response) {
                console.log("response ", response)
                //buscar si existe en grupo productos
                if (response.length > 0) {
                    detalle.fechaVencimientoGrupo = moment(response[0].fechaVencimiento, "DD/MM/YYYY").toDate();
                    vm.existeGrupo = true;
                } else {
                    vm.existeGrupo = false;
                    detalle.fechaVencimientoGrupo = null;
                }
            })
        } else {
            vm.existeGrupo = false;
            detalle.fechaVencimientoGrupo = null;
        }
    }

    function activate() {
        vm.modificarFechaStock = AuthorizationService.hasPermission('modificar_fecha_stock');
        vm.showSpinner = false;
        /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
        vm.staticFilterProv = [{ path: "activo", equals: true }];
        vm.proveedoresFactory = ProveedoresFactory;
        vm.proyectos = ProyectoFactory.all("", "RecepcionForm");
        ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
          vm.proyectoObligatorio = r.valorTipo.codigo == "si";
        });

        WatchLocacionChangeFactory.killAllWatchers();
        CompraLocalLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            vm.mostrarBase = false;

            var compra = undefined;
            var staticFilter = undefined;
            if ($state.includes("app.compraslocales.proceso.recepcion")) {
                vm.isProcesoCompra = true;
                var compra = CompraFactory.getCompra();

                vm.compra = compra;
                if (!compra) {
                    $state.go("app.main");
                }

                var tipo = compra.tipo;
                var tipoCompraDetalle = _.find(compra.tipo.tipoCompraDetalles, function (
                    detalle
                ) {
                    return detalle.componente.codigo == "comp_tipo_compra_recepcion";
                });
                if (tipoCompraDetalle.realizaIngresoStock == true) {
                    vm.recepcionGeneraMovimiento = true;
                }
                if (!tipoCompraDetalle) {
                    $state.go("app.main");
                }
                var ordenOc = 0;
                _.forEach(compra.tipo.tipoCompraDetalles, function (detalle) {
                    if (detalle.componente.codigo == "comp_tipo_compra_orden_compra") {
                        ordenOc = detalle.orden;
                    }
                });
                if (ordenOc != 0) {
                    vm.tieneOc = true;
                }
                var isStateEnabled = CompraFactory.checkPermissionForState(
                    tipoCompraDetalle.orden - 1
                );
                if (!isStateEnabled) {
                    $state.go("app.main");
                }
                vm.parametros = ParametrosFactory.filterByModulo("compras_locales");
                var staticFilterOC = {};
                staticFilterOC.search = filterFactory
                    .or([
                        filterFactory
                            .and([{
                                path: "tipoOrdenCompra.esImportacion",
                                like: "false"
                            },
                            {
                                path: "estado.codigo",
                                equals: "ocl_emitida"
                            },
                            {
                                path: "compras.id",
                                equals: compra.id
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "tipoOrdenCompra.esImportacion",
                                like: "false"
                            },
                            {
                                path: "estado.codigo",
                                equals: "ocl_parcialmente_recepcionada"
                            },
                            {
                                path: "compras.id",
                                equals: compra.id
                            }
                            ])
                            .value()
                    ])
                    .value();

                checkIfComprobanteIfBefore();
            } else if ($state.is("app.consultarecepciones.view")) {
                vm.fromOutside = true;
            }

            if ($state.is("app.compraslocales.proceso.recepcion.new")) {
                activateNew();
            } else if ($state.is("app.compraslocales.proceso.recepcion.edit")) {
                activateEdit();
            } else if (
                $state.is("app.compraslocales.proceso.recepcion.view") ||
                $state.is("app.consultarecepciones.view")
            ) {
                activateView();
            }

            vm.curDate = new Date();
            if (vm.watchOC) {
                vm.watcherOC();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcherOC = watcher;
                OrdenesCompraFactory.all(
                    UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        staticFilterOC
                    ),
                    "OrdenCompraList"
                ).$promise.then(function (response) {
                    vm.ordenesCompra = _.filter(response, function (o) {
                        return !o.comprobantes || o.comprobantes.length == 0;
                    });
                });
            }, "locacion.id");

            if (vm.isProcesoCompra) {
                var staticFilter = {};
                staticFilter.search = filterFactory
                    .and([{
                        path: "compra.id",
                        equals: compra.id
                    },
                    {
                        path: "estado.codigo",
                        equals: "comp_aprobado"
                    }
                    ])
                    .value();
                if (vm.watcherComprob) {
                    vm.watcherComprob();
                }
                WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcher
                ) {
                    vm.watcherComprob = watcher;
                    ComprobanteFactory.all(
                        UtilFactory.createNestedFilters(
                            locacionFilter.search.filters,
                            staticFilter
                        ),
                        "ComprobanteList"
                    ).$promise.then(function (response) {
                        vm.comprobantes = response;
                        var compAprobados = _.find(vm.comprobantes, function (comprobante) {
                            return comprobante.estado.codigo == "comp_aprobado";
                        });
                        if (compAprobados && (vm.isEdit || vm.isNew)) {
                            notify({
                                message: $filter("translate")("COMPROBANTE_APROBADO_EXIST"),
                                classes: "alert-warning",
                                position: "right"
                            });
                        }
                    });
                }, "locacion.id");
            }


            var filterRecepcion = {}
            filterRecepcion.view = "TipoRecepcionList"

            TipoRecepcionFactory.all(filterRecepcion).$promise.then(function (response) {
              if(vm.isMobile){
                for(var i = 0; i < response.length; i++){
                  if(response[i].descripcion === "RECEPCION NORMAL"){
                    vm.recepcion.tipoRecepcion = response[i];
                  }
                }
              }
              vm.tiposRecepcion = response;
            });

            if (vm.watcher) {
                vm.watcher();
            }

            var staticFilterProd = {};
            staticFilterProd.search = filterFactory
                .and([{
                    path: "activo",
                    equals: true
                }])
                .value();

            staticFilterProd.view = "ProveedorList"
            vm.proveedores = ProveedoresFactory.all(staticFilterProd);
            if (vm.watcherAlmacen) {
                vm.watcherAlmacen();
            } //hacemos unwatch de la expresion anterior
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcherAlmacen
            ) {
                vm.watcherAlmacen = watcherAlmacen;
                var filterSucursal = {
                    search: filterFactory
                        .and([{
                            path: "sucursal.id",
                            equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                        }, {
                            path: "activo",
                            equals: true
                        }
                        ])
                        .value()
                };
                AlmacenFactory.all(filterSucursal).$promise.then(function (response) {
                    vm.almacenes = response;
                    if (vm.recepcion.ordenCompra && vm.recepcion.ordenCompra.almacen != null && vm.recepcion.ordenCompra.almacen.id != null) {
                        vm.recepcion.almacen = _.filter(vm.almacenes, function (detalle) {
                            if (detalle.id == vm.recepcion.ordenCompra.almacen.id) {
                                return detalle;
                            }
                        });
                      vm.recepcion.almacen = vm.recepcion.almacen[0];
                    }
                    if(vm.isMobile){
                      vm.recepcion.almacen = response[0]
                    }
                });
            }, "locacion.id");


            vm.recibirSinOC = false;
            if (!vm.fromOutside) {
                setDefaultParametros();
            }
            var defaultColumnOrder = [
                "codigo",
                "nombre",
                "claseProducto.descripcion",
                "estado.descripcion",
                "lineaProducto.descripcion",
                "marca.descripcion"
            ];

            vm.opcionesProductosDt = {
                resource: "productos",
                title: "Productos",
                factory: ProductosFactory,
                view: "ProductoList",
                defaultColumnOrder: defaultColumnOrder,
                columns: [
                    { data: "codigo", title: $filter("translate")("ID"), visible: false },
                    { data: "nombre", title: $filter("translate")("NOMBRE") },
                    {
                        data: "claseProducto.descripcion",
                        title: $filter("translate")("KIND")
                    },
                    {
                        data: "estado.descripcion",
                        title: $filter("translate")("STATUS"),
                        type: "combo",
                        filterUrl: "tipos/tipo/producto_estados"
                    },
                    {
                        data: "lineaProducto.descripcion",
                        title: $filter("translate")("LINE")
                    },
                    {
                        data: "marca.descripcion",
                        title: $filter("translate")("BRAND"),
                        renderWith: "emptyRender"
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc"
            };
            vm.factoryProductos = ProductosFactory;

            vm.staticFilterProductos = [];
            vm.staticFilterProductos.push(
                {
                    path: "itemCompra",
                    like: "true"
                },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                },
                {
                  path: "locaciones.id",
                  equals: $rootScope.AuthParams.locacionSeleccionada.id
                }
            )

            if (!vm.fromOutside) {
                var filterStock = {
                    path: "controlStock",
                    like: "true"
                };
                vm.staticFilterProductos.push(filterStock);
            }

            if (vm.watcher) {
                vm.watcher();
            }

            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcher = watcher;

                vm.finalFilters = {
                    search: filterFactory.and(vm.staticFilterProductos).value()
                };
                var filteredStructure = UtilFactory.createNestedFilters(
                    locacionFilter,
                    vm.finalFilters
                );
                //vm.productos = ProductosFactory.allForSelect(locacionFilter);
                vm.opcionesProductosDt.staticFilter = filteredStructure;
            }, "locaciones.id");
        });


    }

    function delayRequestProducto(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3 && vm.recepcion.proveedor) {

            var filterNombre = vm.staticFilterProductos.slice()
            var filterCodigoBarras = vm.staticFilterProductos.slice()
            var filterCodigoInterno = vm.staticFilterProductos.slice()

            filterNombre.push({
                path: "nombre",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                },
                {
                  path: "locaciones.id",
                  equals: $rootScope.AuthParams.locacionSeleccionada.id
                })

            filterCodigoBarras.push({
                path: "codigoBarrasPrincipal.codigoBarras",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                },
                {
                  path: "locaciones.id",
                  equals: $rootScope.AuthParams.locacionSeleccionada.id
                })

            filterCodigoInterno.push({
                path: "codigo",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                },
                {
                  path: "locaciones.id",
                  equals: $rootScope.AuthParams.locacionSeleccionada.id
                })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }

            recuperarProducto(userInputFilter)
        } else if (searchValue.length > 0 && searchValue.length < 3 && vm.recepcion.proveedor) {

            var filterCodigoInterno = vm.staticFilter.slice()
            filterCodigoInterno.push({
                path: "codigo",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }
            recuperarProducto(userInputFilter)
        } else {
            vm.productos = undefined
        }
    }

    function recuperarProducto(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)

        vm.productos = ProductosFactory.allForSelect(userInputFilter)
    }

    function activateNew() {




        abortRouteIfNecessary();
        vm.title = $filter("translate")("NEW_RECEPCION_LOCAL");
        vm.recepcion = {
            recepcionDetalle: [{}],
            compra: CompraFactory.getIdCompra(),
            fecha: new Date()
        };

        vm.isNew = true;

        if (vm.compra.ordenCompra) {
            vm.recepcion.ordenCompra = vm.compra.ordenCompra;
            ordenCompraSelected();
        } else if (vm.compra.proveedor) {
            vm.recepcion.proveedor = vm.compra.proveedor;
        }
        vm.totalCantidadRecibida = 0;
        vm.totalUnidadesRecibidas = 0;
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);


        evaluateNroGrupo();

        //Compra con OC, ingreso al stock por recepción
        var compra = CompraFactory.getCompra();

        if ((compra.tipo) && (compra.tipo.nombre != "Compra con OC, ingreso al stock por recepción")) {
            vm.recepcion.fechaIngresoStock = new Date();
        }

    }

    function evaluateNroGrupo() {
        vm.bloquearNroGrupo = true;
        _.forEach(vm.compra.tipo.tipoCompraDetalles, function (tipoCompraDetalle) {
            if ((tipoCompraDetalle.componente.codigo == "comp_tipo_compra_recepcion") && (tipoCompraDetalle.realizaIngresoStock)) {
                vm.bloquearNroGrupo = false;
                return;
            }
        });
    }

    function activateEdit() {
        abortRouteIfNecessary();
        vm.title = $filter("translate")("EDIT_RECEPCION_LOCAL");
        vm.recepcion = recepcionCompraPrepService;
        vm.recepcion.numero = vm.recepcion.id;
        vm.recepcion.fecha = new Date(vm.recepcion.fecha);

        if (vm.compra.ordenCompra) {
            vm.recepcion.ordenCompra = vm.compra.ordenCompra;
            ordenCompraSelected();
        }

        if (vm.recepcion.fechaIngresoStock) {
            vm.recepcion.fechaIngresoStock = new Date(vm.recepcion.fechaIngresoStock);
        }
        vm.entidad = "Recepcion";
        vm.entidadId = vm.recepcion.id;
        _.forEach(vm.recepcion.recepcionDetalle, function (detalle) {



            getGruposProductoDetails(detalle);
            setUnidadMedidaPrincipal(detalle);
            detalle.producto.tiposImpuesto = [
                TipoImpuestosFactory.get(detalle.producto.tiposImpuesto[0])
            ];
        });
        var isDecimal = false;
        vm.totalCantidadRecibida = _.reduce(
            vm.recepcion.recepcionDetalle,
            function (sum, detalle) {
                if (!isDecimal && detalle.unidadMedida.permiteDecimales) {
                    isDecimal = true;
                }
                //                var val = redondeo(detalle.cantidadRecepcionada, )
                return sum + detalle.cantidadRecepcionada;
            },
            0
        );

        if (isDecimal) {
            vm.totalCantidadRecibida = redondeo(vm.totalCantidadRecibida, isDecimal);
        }

        //        vm.totalCantidadRecibida = _.reduce(
        //            vm.recepcion.recepcionDetalle,
        //            function(sum, detalle) {
        //                return sum + detalle.cantidadRecepcionada;
        //            },
        //            0
        //        );
        vm.totalUnidadesRecibidas = _.reduce(
            vm.recepcion.recepcionDetalle,
            function (sum, detalle) {
                return sum + detalle.unidadesRecepcionadas;
            },
            0
        );
        vm.recepcion.$promise.then(function (response) {
            if (response.ordenCompra) {
                cargarOC(response);
            }
        });
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);
        vm.isEdit = true;

        evaluateNroGrupo();
    }

    function activateView() {
        vm.title = $filter("translate")("VIEW_RECEPCION_LOCAL");
        vm.recepcion = recepcionCompraPrepService;
        vm.recepcion.numero = vm.recepcion.id;
        vm.recepcion.fecha = new Date(vm.recepcion.fecha);
        if (vm.recepcion.fechaIngresoStock) {
            vm.recepcion.fechaIngresoStock = new Date(vm.recepcion.fechaIngresoStock);
        }
        vm.entidad = "Recepcion";
        vm.entidadId = vm.recepcion.id;



        _.forEach(vm.recepcion.recepcionDetalle, function (detalle) {
            getGruposProductoDetails(detalle);
            setUnidadMedidaPrincipal(detalle);
            detalle.producto.tiposImpuesto = [
                TipoImpuestosFactory.get(detalle.producto.tiposImpuesto[0])
            ];
        });

        var isDecimal = false;
        vm.totalCantidadRecibida = _.reduce(
            vm.recepcion.recepcionDetalle,
            function (sum, detalle) {
                if (!isDecimal && detalle.unidadMedida.permiteDecimales) {
                    isDecimal = true;
                }
                return sum + detalle.cantidadRecepcionada;
            },
            0
        );
        if (isDecimal) {
            vm.totalCantidadRecibida = redondeo(vm.totalCantidadRecibida, isDecimal);
        }
        vm.totalUnidadesRecibidas = _.reduce(
            vm.recepcion.recepcionDetalle,
            function (sum, detalle) {
                return sum + detalle.unidadesRecepcionadas;
            },
            0
        );
        if (!vm.fromOutside) {
            vm.recepcion.$promise.then(function (response) {
                cargarOC(response);
            });
        }
        vm.extraMenuOptions = [{
            title: $filter("translate")("GENERAR_REPORTE_RECEPCION"),
            icon: "glyphicon glyphicon-file",
            showCondition: function () {
                return true;
            },
            action: function () {
                var filters = {};
                var filtersArr = [];
                filtersArr.push({ path: "id", equals: vm.recepcion.id });
                filters = filterFactory.and(filtersArr).value();
                ReportTicketFactory.ticket(
                    "recepcion",
                    filters,
                    vm.opcionesProductosDt.currentDataOrder
                ).then(function (genTicket) {
                    vm.pdfDownloadURL = ReportTicketFactory.downloadURL(
                        genTicket.ticket,
                        "pdf"
                    );
                    $window.open(vm.pdfDownloadURL, "_blank");
                });
            }
        }];
        vm.view = true;
    }

    function abortRouteIfNecessary() {
        if (vm.isProcesoCompra === true && vm.compra.estado.codigo === "compra_cerrada" &&
            CompraFactory.getCompra().estado.codigo != "compra_anulada") {
            $state.go("app.main");
        }
    }

    function getGruposProductoDetails(detalle) {
        if (detalle.gruposProducto.length == 1) {
            GruposProductosFactory.get(
                detalle.gruposProducto[0]
            ).$promise.then(function (gp) {
                detalle.gruposProducto[0] = gp;
                detalle.numeroGrupo = gp.numero;
            });
        }
    }

    function agregarDetalle() {
        vm.recepcion.recepcionDetalle.push({});
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
        $timeout(function(){
          $('html,body').animate({scrollTop: $("#codigo_"+(vm.recepcion.recepcionDetalle.length-1)).offset().top},"slow");
        }, 200)
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        var isDecimal = false;
        vm.totalCantidadRecibida = _.reduce(
            vm.recepcion.recepcionDetalle,
            function (sum, detalle) {
                if (!isDecimal && detalle.unidadMedida.permiteDecimales) {
                    isDecimal = true;
                }
                return sum + detalle.cantidadRecepcionada;
            },
            0
        );
        if (isDecimal) {
            vm.totalCantidadRecibida = redondeo(vm.totalCantidadRecibida, isDecimal);
        }
        vm.totalUnidadesRecibidas = _.reduce(
            vm.recepcion.recepcionDetalle,
            function (sum, detalle) {
                return sum + detalle.unidadesRecepcionadas;
            },
            0
        );
    }

    function cargarOC(response) {
        if (!response.ordenCompra || vm.recepcion.ordenCompra) {
            return;
        }
        if (vm.compra.ordenCompra) {
            vm.recepcion.ordenCompra = vm.compra.ordenCompra;
            return;
        }
        OrdenesCompraFactory.get(
            response.ordenCompra.id,
            "OrdenCompraList"
        ).$promise.then(function (response) {
            vm.recepcion.ordenCompra = response;
        });
    }

    function checkIfComprobanteIfBefore() {
        var tiposCodigos = _.map(
            _.sortBy(vm.compra.tipo.tipoCompraDetalles,
                function (d) {
                    return d.orden;
                }), function (d) {
                    return d.componente.codigo;
                });
        var index = _.findIndex(tiposCodigos, function (d) {
            return d === "comp_tipo_compra_recepcion";
        });
        var tiposRestantes = tiposCodigos.slice(index + 1);
        if (_.find(tiposRestantes, function (d) {
            return d === "comp_tipo_compra_comprobante";
        })) {
            // Se bloquea el Campo 'Comprobante' si la Recepción está antes en el flujo
            vm.blockComprobante = true;
        }
    }

    function ordenCompraSelected() {
        CompraFactory.getSugerenciasOC(
            vm.recepcion.ordenCompra.id,
            "comp_tipo_compra_recepcion"
        ).then(function (response) {
            var filterOCProductos = _.filter(response.data, function (detalle) {
                if (detalle.cantidad > 0 && detalle.producto != null) {
                    return detalle.producto;
                }
            });

            _.forEach(vm.compra.compraDetalles, function (compDetalle) {
                compDetalle.cantidadRecepcionEsperada = 0;
            });

            _.forEach(filterOCProductos, function (data) {
                var compraDetalle = _.find(vm.compra.compraDetalles, function (detalle) {
                    if (detalle.producto != null) {
                        return detalle.producto.id == data.producto.id;
                    }
                });
                compraDetalle.cantidadRecepcionEsperada += data.cantidad;
            });



            // console.log("vm.parametros: ", vm.parametros);
            vm.parametros.$promise.then(function (parametros) {
                var param = _.find(parametros, function (parametro) {
                    return parametro.codigo == "recuperar_items_oc";
                });

                if (!param) {
                    return;
                }

                if (param.valorTipo.codigo == "si" && vm.isNew) {
                    vm.recepcion.recepcionDetalle = _.flatten(_.map(filterOCProductos, function (
                        detalle
                    ) {
                        if (detalle.producto != undefined) {
                            if (detalle.producto.criterioControl.codigo === "series" && !vm.bloquearNroGrupo) {
                                var seriesArr = [];
                                for (var i = 0; i < detalle.cantidad; i++) {
                                    seriesArr.push({
                                        producto: detalle.producto,
                                        unidadMedida: detalle.unidadMedida,
                                        cantidadRecepcionada: 1
                                    });
                                }
                                return seriesArr;
                            } else {
                                return {
                                    producto: detalle.producto,
                                    unidadMedida: detalle.unidadMedida,
                                    cantidadRecepcionada: detalle.cantidad
                                };
                            }
                        }
                    }));
                    _.forEach(vm.recepcion.recepcionDetalle, function (detalle) {
                        setUnidadMedidaPrincipal(detalle);
                    });
                    // Se actualizan los totales al recuperar de la OC
                    var isDecimal = false;
                    vm.totalCantidadRecibida = _.reduce(
                        vm.recepcion.recepcionDetalle,
                        function (sum, detalle) {
                            if (!isDecimal && detalle.unidadMedida.permiteDecimales) {
                                isDecimal = true;
                            }
                            return sum + detalle.cantidadRecepcionada;
                        },
                        0
                    );

                    if (isDecimal) {
                        vm.totalCantidadRecibida = redondeo(vm.totalCantidadRecibida, isDecimal);
                    }

                    vm.totalUnidadesRecibidas = _.reduce(
                        vm.recepcion.recepcionDetalle,
                        function (sum, detalle) {
                            return sum + detalle.unidadesRecepcionadas;
                        },
                        0
                    );

                }
                vm.recepcion.proveedor = vm.compra.proveedor;
                //vm.recepcion.almacen = vm.compra.almacen;
            });
        });
    }

    // NOTE no hay parametro recuperar desde comprobante
    function comprobanteSelected() {
        ComprobanteFactory.get(
            vm.recepcion.comprobante.id,
            "ComprobanteList"
        ).$promise.then(function (response) {
            vm.recepcion.comprobante = response;
            vm.recepcion.proveedor = vm.recepcion.comprobante.proveedor;
            CompraFactory.getSugerenciasComprobante(
                vm.recepcion.comprobante.id
            ).then(function (response) {
                var filterProductos = _.filter(response.data, function (detalle) {
                    return detalle.producto;
                });

                vm.recepcion.recepcionDetalle = _.flatten(_.map(filterProductos, function (
                    detalle
                ) {
                    if (detalle.producto != undefined) {
                        if (detalle.producto.criterioControl.codigo === "series" && !vm.bloquearNroGrupo) {
                            var seriesArr = [];
                            for (var i = 0; i < detalle.cantidad; i++) {
                                seriesArr.push({
                                    producto: detalle.producto,
                                    unidadMedida: detalle.unidadMedida,
                                    cantidadRecepcionada: 1
                                });
                            }
                            return seriesArr;
                        } else {
                            return {
                                producto: detalle.producto,
                                unidadMedida: detalle.unidadMedida,
                                cantidadRecepcionada: detalle.cantidad
                            };
                        }
                    }
                }));

                _.forEach(vm.recepcion.recepcionDetalle, function (detalle) {
                    setUnidadMedidaPrincipal(detalle);
                });
            });
        });
    }

    var redondeo = function (data, isDecimal) {
        if (!data) {
            data = 0;
        }
        if (isDecimal) {
            data = parseFloat(data).toFixed(2);
        } else {
            data = parseFloat(data).toFixed(0);
        }
        return data;
    };

    function mostrarEnBase() {
        if (vm.mostrarBase) {
            for (var i = 0; i < vm.recepcion.recepcionDetalle.length; i++) {
                if (vm.recepcion.recepcionDetalle[i].producto != undefined) {
                    var multiplicador = 1;
                    var unidadActual = vm.recepcion.recepcionDetalle[i].unidadMedida;
                    while (!unidadActual.esBase) {
                        multiplicador = multiplicador * unidadActual.cantidad;
                        unidadActual = unidadActual.unidadContenida;
                    }
                    if (
                        vm.recepcion.recepcionDetalle[i].cantidadRecepcionada != undefined
                    ) {
                        vm.recepcion.recepcionDetalle[i].unidadesEnBase =
                            vm.recepcion.recepcionDetalle[i].cantidadRecepcionada *
                            multiplicador;
                    }
                }
            }
        }
        var isDecimal = false;
        vm.totalCantidadRecibida = _.reduce(
            vm.recepcion.recepcionDetalle,
            function (sum, detalle) {
                if (!isDecimal && detalle.unidadMedida.permiteDecimales) {
                    isDecimal = true;
                }
                //                var val = redondeo(detalle.cantidadRecepcionada, )
                return sum + detalle.cantidadRecepcionada;
            },
            0
        );
        if (isDecimal) {
            vm.totalCantidadRecibida = redondeo(vm.totalCantidadRecibida, isDecimal);
        }
    }

    function actualizarTotalGeneralUnidades() {
        vm.totalUnidadesRecibidas = _.reduce(
            vm.recepcion.recepcionDetalle,
            function (sum, detalle) {
                return sum + detalle.unidadesRecepcionadas;
            },
            0
        );
    }

    function productoSelected(detalle, index) {
        if(vm.isMobile){
          $timeout(function (){
            $('#cantidadRecepcionada_' + index).focus();
          },200)
        }
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        detalle.numeroGrupo = null;
        if (detalle.producto.criterioControl.codigo === "series" && vm.recepcionGeneraMovimiento
        ) {
            detalle.cantidadRecepcionada = 1;
            var isDecimal = false;
            vm.totalCantidadRecibida = _.reduce(
                vm.recepcion.recepcionDetalle,
                function (sum, detalle) {
                    if (!isDecimal && detalle.unidadMedida.permiteDecimales) {
                        isDecimal = true;
                    }
                    return sum + detalle.cantidadRecepcionada;
                },
                0
            );
            if (isDecimal) {
                vm.totalCantidadRecibida = redondeo(vm.totalCantidadRecibida, isDecimal);
            }
        }
    }

    function updateDetalle(detalle) {
        if (
            detalle.producto.criterioControl.codigo === "series" ||
            detalle.producto.criterioControl.codigo === "lotes"
        ) {
            detalle.gruposProducto = [{}];
        }
    }

    function changeTipoRecepcion() {
        if (!vm.recepcion.ordenCompra) {
            vm.recepcion.almacen = vm.recepcion.tipoRecepcion.almacen;
        }
    }

    function setUnidadMedidaPrincipal(detalle) {
        detalle.producto.unidadMedidaPrincipal = detalle.producto.unidadMedidaBase;

    }

    function searchProductoFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return (
                item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.codigo == criteria ||
                (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0
            );
        };
    }

    function setDefaultParametros() {
        setRecibirItemsSinOC();
        setMaxDiasEntrega();
        setCantidadesOC();
        setPorcentajes();
    }

    function setRecibirItemsSinOC() {
        return vm.parametros.$promise.then(function (parametros) {
            var param = _.find(parametros, function (parametro) {
                return parametro.codigo == "recibir_items_sin_oc";
            });

            if (!param) {
                return;
            }

            vm.recibirSinOC = param.valorTipo.codigo == "si";
            return vm.recibirSinOC;
        });
    }

    function setMaxDiasEntrega() {
        return vm.parametros.$promise.then(function (parametros) {
            var param = _.find(parametros, function (parametro) {
                return parametro.codigo == "max_dias_entrega_adelantada";
            });
            if (param) {
                vm.maxDiasEntregaAdelantada = parseInt(param.valor);
            }

            param = undefined;

            param = _.find(parametros, function (parametro) {
                return parametro.codigo == "max_dias_entrega_atrasada";
            });

            if (param) {
                vm.maxDiasEntregaAtrasada = parseInt(param.valor);
            }
        });
    }

    function setCantidadesOC() {
        return vm.parametros.$promise.then(function (parametros) {
            var param = _.find(parametros, function (parametro) {
                return parametro.codigo == "recibir_cantidad_mayor_a_oc";
            });

            if (param) {
                vm.recibirCantidadMayorOC = param.valorTipo.codigo == "si";
            }

            param = undefined;

            param = _.find(parametros, function (parametro) {
                return parametro.codigo == "recibir_cantidad_menor_a_oc";
            });

            if (param) {
                vm.recibirCantidadMenorOC = param.valorTipo.codigo == "si";
            }
        });
    }

    function setPorcentajes() {
        return vm.parametros.$promise.then(function (parametros) {
            var param = _.find(parametros, function (parametro) {
                return parametro.codigo == "porcentaje_recepcion_pesables";
            });

            if (param) {
                vm.porcentajePesables = parseInt(param.valor);
            }

            param = undefined;

            param = _.find(parametros, function (parametro) {
                return parametro.codigo == "porcentaje_recepcion_unitarios";
            });

            if (param) {
                vm.porcentajeUnitarios = parseInt(param.valor);
            }
        });
    }

    function validateAmounts() {
        return $q(function (resolve, reject) {

            if (!vm.recepcion.ordenCompra) {
                resolve(false);
                return;
            }

            var promises = [];
            var comprasDetalles = _.filter(vm.compra.compraDetalles, function (
                cdetalle
            ) {
                return cdetalle.cantidadOC;
            });
            for (var i = 0; i < comprasDetalles.length; i++) {
                var producto = comprasDetalles[i].producto;
                if (producto && !producto.claseProducto.id) {
                    producto.claseProducto = ClaseProductoFactory.get(
                        producto.claseProducto,
                        "ClaseProductoList"
                    );
                    promises.push(producto.claseProducto.$promise);
                }
            }
            $q.all(promises).then(function (responses) {
                var didResolve = false;
                for (var i = 0; i < vm.recepcion.recepcionDetalle.length; i++) {
                    var detalle = vm.recepcion.recepcionDetalle[i];
                    var producto = detalle.producto;
                    var indexOC = _.findIndex(comprasDetalles, function (cd) {
                        if (!cd.producto) {
                            return false;
                        }
                        return cd.producto.id == producto.id;
                    });

                    if (indexOC < 0) {
                        if (!vm.recibirSinOC) {
                            resolve(
                                $filter("translate")("PEDIDO_FUERA_ORDEN_COMPRA_WARNING")
                            );
                            didResolve = true;
                            return;
                        }
                        continue;
                    }

                    var productoOC = comprasDetalles[indexOC].producto;
                    var tipoAdm = productoOC.claseProducto.tipoAdministracion.codigo;
                    var cantidadOC = comprasDetalles[indexOC].cantidadOC;
                    var cantidadRecepcionEsperada = comprasDetalles[indexOC].cantidadRecepcionEsperada;

                    var cantidadDetalle = _.reduce(
                        vm.recepcion.recepcionDetalle,
                        function (suma, detalle) {
                            if (detalle.producto.id == producto.id) {
                                suma += detalle.cantidadRecepcionada;

                                if (detalle.cantidadBonificacion) {
                                    suma += detalle.cantidadBonificacion;
                                }
                            }

                            return suma;
                        },
                        0
                    );

                    cantidadDetalle = Util.toUnidadMedidaBase(
                        cantidadDetalle,
                        detalle.unidadMedida
                    );

                    if (
                        cantidadDetalle >
                        cantidadRecepcionEsperada
                    ) {
                        if (!vm.recibirCantidadMayorOC) {
                            if (tipoAdm == "pesables") {
                                var porc = cantidadDetalle * 100 / cantidadOC;
                                if (porc > 100 && porc - 100 > vm.porcentajePesables) {
                                    didResolve = true;
                                    $translate("PORCENTAJE_RECEPCION_SUPERA_LIMITE_WARNING", {
                                        item: productoOC.nombre
                                    }).then(function (mensaje) {
                                        resolve(mensaje);
                                        return;
                                    });
                                } else { // agregado
                                    resolve(false);
                                    didResolve = true;
                                }
                            } else if (tipoAdm == "unitario") {
                                var porc = cantidadDetalle * 100 / cantidadOC;
                                if (porc > 100 && porc - 100 > vm.porcentajeUnitarios) {
                                    didResolve = true;
                                    $translate("PORCENTAJE_RECEPCION_SUPERA_LIMITE_WARNING", {
                                        item: productoOC.nombre
                                    }).then(function (mensaje) {
                                        resolve(mensaje);
                                        return;
                                    });
                                } else { // agregado
                                    resolve(false);
                                    didResolve = true;
                                }
                            } else {
                                didResolve = true;
                                return $translate(
                                    "CANTIDAD_RECEPCION_MAYOR_QUE_ORDEN_COMPRA_WARNING", { item: productoOC.nombre }
                                ).then(function (mensaje) {
                                    resolve(mensaje);
                                    return;
                                });
                            }
                        } else { // agregado
                            resolve(false);
                            didResolve = true;
                        }
                    } else if (cantidadDetalle <
                        cantidadRecepcionEsperada) {
                        if (!vm.recibirCantidadMenorOC) {
                            if (tipoAdm == "pesables") {
                                var porc = cantidadDetalle * 100 / cantidadOC;
                                if (porc < 100 && 100 - porc > vm.porcentajePesables) {
                                    didResolve = true;
                                    $translate("PORCENTAJE_RECEPCION_INFERIOR_LIMITE_WARNING", {
                                        item: productoOC.nombre
                                    }).then(function (mensaje) {
                                        resolve(mensaje);
                                        return;
                                    });
                                } else { // agregado
                                    resolve(false);
                                    didResolve = true;
                                }
                            } else if (tipoAdm == "unitario") {
                                var porc = cantidadDetalle * 100 / cantidadOC;
                                if (porc < 100 && 100 - porc > vm.porcentajeUnitarios) {
                                    didResolve = true;
                                    $translate("PORCENTAJE_RECEPCION_INFERIOR_LIMITE_WARNING", {
                                        item: productoOC.nombre
                                    }).then(function (mensaje) {
                                        resolve(mensaje);
                                        return;
                                    });
                                } else { // agregado
                                    resolve(false);
                                    didResolve = true;
                                }
                            } else {
                                didResolve = true;
                                return $translate(
                                    "CANTIDAD_RECEPCION_MENOR_QUE_ORDEN_COMPRA_WARNING", { item: productoOC.nombre }
                                ).then(function (mensaje) {
                                    resolve(mensaje);
                                    return;
                                });
                            }
                        } else { // agregado
                            resolve(false);
                            didResolve = true;
                        }
                    } else {
                        continue;
                    }
                }
                if (!didResolve) {
                    resolve(false);
                }
            });
        });
    }

    function validateFechas(fechaEsperada, fechaIngresoStock) {
        return $q(function (resolve, reject) {
            ParametrosFactory.getByCodigo("fecha_esperada_OC_requerida").then(function (r) {
                if (r.valorTipo.codigo == "si") {
                    var fechaOC = moment(new Date(fechaEsperada));
                    var fechaRecepcion = moment(fechaIngresoStock);
                    var difFechas = fechaOC.diff(fechaRecepcion, "days");

                    // si la diff entre el dia de
                    var banError = false;
                    if (difFechas > 0) {
                        if (difFechas > vm.maxDiasEntregaAdelantada) {
                            banError = true;
                        }
                    } else if (difFechas < 0) {
                        if (Math.abs(difFechas) > vm.maxDiasEntregaAtrasada) {
                            banError = true;
                        }
                    }

                    if (banError) {
                        $translate("FECHA_NO_EN_RANGO_WARNING").then(function (mensaje) {
                            resolve(mensaje);
                            return;
                        });
                    } else {
                        resolve(undefined);
                    }
                } else {
                    resolve(undefined);
                }
            });
        });
    }

    function validacionCantidadRecibida(ordenesCompraDetalle) {

        return $q(function (resolve, reject) {

            //Obtener parametros
            ParametrosFactory.filterByCodigo("porcentaje_recepcion_pesables").$promise.then(function (data) {
                vm.porcentajeCantRecibidaPesable = parseInt(data[0].valor);

                ParametrosFactory.filterByCodigo("porcentaje_recepcion_unitarios").$promise.then(function (data) {
                    vm.porcentajeCantRecibidaUnitaria = parseInt(data[0].valor);

                    ParametrosFactory.filterByCodigo("recibir_cantidad_mayor_a_oc").$promise.then(function (data) {
                        vm.recibirMasOrdenCompra = data[0].valorTipo.codigo;

                        ParametrosFactory.filterByCodigo("recibir_cantidad_menor_a_oc").$promise.then(function (data) {
                            vm.recibirMenosOrdenCompra = data[0].valorTipo.codigo;

                            //Comprobacion de porcentaje de cantidad recibida (OC vs Recepcion)
                            var banError = false;
                            _.forEach(vm.recepcion.recepcionDetalle, function (recepcionDetalle) {
                                _.forEach(ordenesCompraDetalle, function (ordenCompraDetalle) {
                                    if (ordenCompraDetalle.producto && (ordenCompraDetalle.producto.id == recepcionDetalle.producto.id)) {

                                        // Solo si es pesable o unitario tiene sentido realizar el control
                                        if (recepcionDetalle.producto.claseProducto.tipoAdministracion.codigo == "pesables" ||
                                            recepcionDetalle.producto.claseProducto.tipoAdministracion.codigo == "unitario") {

                                            if (recepcionDetalle.producto.claseProducto.tipoAdministracion.codigo == "pesables") {
                                                vm.porcentajeCantRecibida = vm.porcentajeCantRecibidaPesable;

                                            } else if (recepcionDetalle.producto.claseProducto.tipoAdministracion.codigo == "unitario") {
                                                vm.porcentajeCantRecibida = vm.porcentajeCantRecibidaUnitaria;
                                            }
                                            if (recepcionDetalle.cantidadRecepcionada > ordenCompraDetalle.cantidad) {
                                                if (vm.recibirMasOrdenCompra == "no") {
                                                    banError = true;
                                                    resolve(false);
                                                    var msg = $filter("translate")("CANT_MAYOR_OC_DISABLE");
                                                    notify({
                                                        message: msg,
                                                        classes: "alert-danger",
                                                        position: "right"
                                                    });
                                                    return;
                                                } else {
                                                    var porcentajeDiferencia = ((recepcionDetalle.cantidadRecepcionada - ordenCompraDetalle.cantidad) / ordenCompraDetalle.cantidad) * 100
                                                    if (porcentajeDiferencia > vm.porcentajeCantRecibida) {
                                                        banError = true;
                                                        resolve(false);
                                                        var msg = $filter("translate")("PORCENTAJE_RECIBIDO_SUPERADO");
                                                        notify({
                                                            message: msg,
                                                            classes: "alert-danger",
                                                            position: "right"
                                                        });
                                                        return;
                                                    }
                                                }
                                            } else if (recepcionDetalle.cantidadRecepcionada < ordenCompraDetalle.cantidad) {
                                                if (vm.recibirMenosOrdenCompra == "no") {
                                                    banError = true;
                                                    resolve(false);
                                                    var msg = $filter("translate")("CANT_MENOR_OC_DISABLE");
                                                    notify({
                                                        message: msg,
                                                        classes: "alert-danger",
                                                        position: "right"
                                                    });
                                                    return;
                                                } else {
                                                    var porcentajeDiferencia = ((ordenCompraDetalle.cantidad - recepcionDetalle.cantidadRecepcionada) / ordenCompraDetalle.cantidad) * 100
                                                    if (porcentajeDiferencia > vm.porcentajeCantRecibida) {
                                                        banError = true;
                                                        resolve(false);
                                                        var msg = $filter("translate")("PORCENTAJE_RECIBIDO_SUPERADO");
                                                        notify({
                                                            message: msg,
                                                            classes: "alert-danger",
                                                            position: "right"
                                                        });
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })
                                if (banError) {
                                    $rootScope.isProcessing = false;
                                    resolve(false);
                                    return;
                                }
                            });
                            if (!banError) {
                                resolve(true);
                            }
                        });
                    });
                });
            });
        });
    }

    function evaluatePeriodoAdministrativo(fecha) {
        if (!fecha) {
            var msg = $filter('translate')('NO_FECHA_STOCK');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            $rootScope.isProcessing = false;
            return;
        }

        return $q(function (resolve, reject) {
            var params = {};
            params.search = filterFactory
                .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                }
                ])
                .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {

                if (responsePeriodo.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        });
    }

    function submit() {
        vm.submited = true;
        vm.showSpinner = true;
        $rootScope.isProcessing = true;

        if (vm.recepcion.recepcionDetalle.length == 0) {

            $rootScope.isProcessing = false;
            vm.showSpinner = false;
            var msg = $filter('translate')('NO_DETAILS_ERROR')
            notify({ message: msg, classes: "alert-danger", position: "right" });
            return

        }

        var errorSerieRepetida = false;
        if (vm.recepcionGeneraMovimiento) {

            for (var i = 0; i < vm.recepcion.recepcionDetalle.length; i++) {
                var elem = vm.recepcion.recepcionDetalle[i];
                var cantRegistros = 0;
                if (elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') {
                    _.forEach(vm.recepcion.recepcionDetalle, function (elem2) {
                        if ((elem2.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') && elem.producto.id == elem2.producto.id && elem.numeroGrupo == elem2.numeroGrupo) {
                            cantRegistros++;
                        }
                    });
                }
                if (cantRegistros > 1) {
                    var msg = $filter('translate')('NUMERO_GRUPO_REPETIDO_PRODUCTO') + " (Producto: " + elem.producto.nombre + ")";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    errorSerieRepetida = true;
                    break;
                }
            };

        }

        if (errorSerieRepetida) {
            $rootScope.isProcessing = false;
            return;
        }

        ParametrosFactory.getByCodigo("controlar_vto_minimo_lote").then(function (response) {
            //Math.ceil(diferencia / (1000 * 3600 * 24))
            var diferencia, diasDif;
            var lote = "";
            for (var i = 0; i < vm.recepcion.recepcionDetalle.length; i++) {
                if (vm.recepcion.recepcionDetalle[i].fechaVencimientoGrupo) {
                    diferencia = vm.recepcion.recepcionDetalle[i].fechaVencimientoGrupo.getTime() - vm.recepcion.fechaIngresoStock.getTime();
                    diasDif = Math.ceil(diferencia / (1000 * 3600 * 24))
                    if (diasDif < response.valor) {
                        if (!lote) {
                            lote = vm.recepcion.recepcionDetalle[i].numeroGrupo;
                        } else {
                            lote += ", " + vm.recepcion.recepcionDetalle[i].numeroGrupo;
                        }
                    }
                }
            }
            if (lote) {
                $rootScope.isProcessing = false;
                vm.showSpinner = false;
                var msg = "La fecha de vencimiento del Lote [" + lote + "] es menor a lo permitido. (" + response.valor + " días)"
                notify({ message: msg, classes: "alert-danger", position: "right" });
                return;
            }
            // Se hace control de periodo administrativo con la fecha de ingreso a stock
            evaluatePeriodoAdministrativo(vm.recepcion.fechaIngresoStock).then(function (response) {
                if (response) {
                    if ($scope.RecepcionarLocalForm.$valid) {
                        validateAmounts().then(function (errorMessage) {

                            if (!errorMessage) {

                                if (vm.recepcion.ordenCompra) {
                                    //Recuperando OC (detalles)
                                    OrdenesCompraFactory.get(vm.recepcion.ordenCompra.id, "OrdenCompraForm").$promise.then(function (ordenCompra) {

                                        validateFechas(ordenCompra.fechaEsperada, vm.recepcion.fechaIngresoStock).then(function (errorMessage) {
                                            if (!errorMessage) {
                                                vm.recepcion.recepcionDetalle = _.filter(
                                                    vm.recepcion.recepcionDetalle,
                                                    function (elem) {
                                                        return elem.producto;
                                                    }
                                                );
                                                _.forEach(vm.recepcion.recepcionDetalle, function (detalle) {
                                                    delete detalle.producto.unidadMedidaPrincipal;
                                                    delete detalle.unidadesEnBase;
                                                });

                                                validacionCantidadRecibida(ordenCompra.ordenCompraDetalle).then(function (resultado) {
                                                    if (resultado == true) {
                                                        submitRecepcion().then(
                                                            function (response) {
                                                                $scope.$emit("updateCompraStates");
                                                                $state.go("app.compraslocales.proceso.recepcion.list");
                                                                return response;
                                                            },
                                                            function (error) {
                                                                $rootScope.isProcessing = false;
                                                                vm.showSpinner = false;
                                                                var msg = "Error al guardar la Recepción";
                                                                notify({
                                                                    message: msg,
                                                                    classes: "alert-danger",
                                                                    position: "right"
                                                                });
                                                            }
                                                        );
                                                    }
                                                })
                                            } else {
                                                $rootScope.isProcessing = false;
                                                vm.showSpinner = false;
                                                var msg = errorMessage;
                                                notify({ message: msg, classes: "alert-danger", position: "right" });
                                            }
                                        });
                                    });
                                } else {
                                    submitRecepcion().then(
                                        function (response) {
                                            $scope.$emit("updateCompraStates");
                                            $state.go("app.compraslocales.proceso.recepcion.list");
                                            return response;
                                        },
                                        function (error) {
                                            $rootScope.isProcessing = false;
                                            vm.showSpinner = false;
                                            var msg = "Error al guardar la Recepción";
                                            notify({
                                                message: msg,
                                                classes: "alert-danger",
                                                position: "right"
                                            });
                                        }
                                    );
                                }
                                if(vm.isMobile){
                                  localStorage.setItem("againNewRecepcion", true)
                                }

                            } else {
                                $rootScope.isProcessing = false;
                                vm.showSpinner = false;
                                var msg = errorMessage;
                                notify({ message: msg, classes: "alert-danger", position: "right" });
                            }
                        })
                    } else {
                        $rootScope.isProcessing = false;
                        vm.showSpinner = false;
                    }

                } else {
                    var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }
            });

        });
    }

    function submitRecepcion() {
        var resource = RecepcionFactory.create(vm.recepcion);
        delete resource.numero;
        resource.usuario = $rootScope.AuthParams.accesoSistema.usuario.id;
        if (!vm.isEdit) {
            resource.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        }
        return RecepcionFactory.save(resource);
    }

    function cancel() {
        if (vm.isProcesoCompra) {
            $state.go("app.compraslocales.proceso.recepcion.list");
        } else if (vm.fromOutside) {
            $state.go("app.consultarecepciones.list");
        }
    }
}

'use strict';

angular.module('qualita.compra')
    .controller('RecepcionLocalListCtrl', RecepcionLocalListCtrl);

RecepcionLocalListCtrl.$inject = ["AuthorizationService", "RecepcionFactory", "ImportacionFactory", "ReportTicketFactory",
    "$modal", "$scope", "$state", "filterFactory", "$window", "CompraLocalLangFactory", "CompraFactory",
    "WatchLocacionChangeFactory", "notify", "$filter", "UtilFactory", "CsvFactory", "baseurl", "$q", "PeriodoAdministrativoFactory"
];

function RecepcionLocalListCtrl(AuthorizationService, RecepcionFactory, ImportacionFactory,
    ReportTicketFactory, $modal, $scope, $state,
    filterFactory, $window, CompraLocalLangFactory,
    CompraFactory, WatchLocacionChangeFactory, notify, $filter,
    UtilFactory, CsvFactory, baseurl, $q, PeriodoAdministrativoFactory) {
    var vm = this;
    vm.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    activate();

    function activate() {
        var defaultColumnOrder = ['id', 'tipoRecepcion.descripcion', 'almacen.descripcion', 'estadoRecepcion.descripcion', 'fecha',
        'fechaIngresoStock'];
        CompraLocalLangFactory.getTranslations().then(function(translations) {
            
            /* Si recibe fechaComprobante!=null verifica la fecha de ingreso a stock, sino fecha actual */
            var checkPeriodoAdministrativo = function(fechaComprobante){
                return $q(function(resolve, reject) {

                    var params = {};
                    var fecha;

                    if(fechaComprobante == null){
                        fecha = new Date();
                    }else{
                        fecha = fechaComprobante;
                    }

                    params.search = filterFactory
                    .and([{
                            path: "estado.codigo",
                            equals: "per_adm_abierto"
                        },
                        {
                            path: "fechaDesde",
                            equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                        },
                        {
                            path: "fechaHasta",
                            equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                        }
                    ])
                    .value();

                    PeriodoAdministrativoFactory.all(params).$promise.then(function(responsePeriodo) {
                        if(responsePeriodo.length>0){
                            resolve(true);
                        }else{
                            resolve(false);
                        }
                    })
                });
            }

            vm.translations = translations;
            var compra = undefined;
            var staticFilter = undefined;

            if ($state.is("app.consultarecepciones.list")) {
                vm.fromOutside = true;
            } else if ($state.is("app.compraslocales.proceso.recepcion.list")) {
                var compra = CompraFactory.getCompra();
                if(vm.isMobile && localStorage.getItem("againNewRecepcion")){
                  var filter = {
                    search: filterFactory.and([{
                      path: "compraLocal",
                      like: "true"
                    }, {
                      path: "compra.id",
                      equals: compra.id
                    }]).value()
                  };
                  RecepcionFactory.all(filter, "RecepcionList").$promise.then(function (response) {
                    var listRecepcion = response.sort(function(a,b) {
                      return b.id - a.id;
                    });
                    $scope.mensajeModal = "Está seguro de que desea confirmar el comprobante?. Una vez confirmado, se generaran los movimientos de stock correspondientes.";
                    $scope.tituloModal = $filter("translate")("CONFIRMAR_COMPROBANTE_RECEPCION_TITLE");
                    var modalInstance = $modal.open({
                      templateUrl: "views/directives/confirmation-modal.html",
                      scope: $scope,
                      backdrop: 'static'
                    });
                    $scope.cancel = function() {
                      if(!localStorage.getItem("againNewRecepcion")){
                        modalInstance.dismiss("cancel");
                        return
                      }
                      localStorage.removeItem("againNewRecepcion")
                      $state.go("app.compraslocales.proceso.recepcion.edit", { id: listRecepcion[0].id });
                      modalInstance.dismiss("cancel");
                    };
                    $scope.ok = function() {
                      localStorage.removeItem("againNewRecepcion");
                      RecepcionFactory.aprobar(listRecepcion[0].id).then(function(response) {
                        var filtersArr = [];
                        filtersArr.push('idrecepcion=' + listRecepcion[0].id);
                        vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('recepcion', 'pdf', filtersArr);
                        $window.open(vm.pdfDownloadURL, '_blank');
                        $state.go($state.current, {}, { reload: true });
                      }, function(error) {
                        if (error && error.data && error.data.length > 0) {
                          var msg = "<span>";
                          _.forEach(error.data, function(err) {
                            if (err.constraint == 'id_producto, numero') {
                              msg = msg + $filter('translate')('NUMERO_GRUPO_REPETIDO_PRODUCTO') + "<br/>";
                            } else {
                              msg = msg + err.message + "<br/>";
                            }
                          });
                          msg = msg + "</span>";
                          notify({ messageTemplate: msg, classes: 'alert-danger', position: 'right' });
                          // console.log("Mensaje: ", msg);
                        }
                      });
                    }
                  });
                } else {
                  if(vm.isMobile && localStorage.getItem("newRecepcion")){
                    localStorage.removeItem("newRecepcion")
                    checkPeriodoAdministrativo(null).then(function(resultado) {
                      if(resultado == true){
                        $state.go("app.compraslocales.proceso.recepcion.new");
                      }else if(resultado == false){
                        var msg= $filter('translate')('NO_OPEN_PERIODS_NOW');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                      }
                    });
                  }
                }
                vm.isProcesoCompra = true;
                if (!compra) {
                    $state.go("app.main");
                }
                var tipo = compra.tipo;
                var tipoCompraDetalle = _.find(compra.tipo.tipoCompraDetalles, function(detalle) {
                    return detalle.componente.codigo == "comp_tipo_compra_recepcion";
                });
                if (!tipoCompraDetalle) {
                    console.log("No tiene permiso para revisar esta seccion");
                    $state.go("app.main");
                }
                var isStateEnabled = CompraFactory.checkPermissionForState(tipoCompraDetalle.orden - 1);
                if (!isStateEnabled) {
                    console.log("No tiene permiso para revisar esta seccion");
                    $state.go("app.main");
                }
                // filtro estático
                staticFilter = {
                    search: filterFactory.and([{
                        path: "compraLocal",
                        like: "true"
                    }, {
                        path: "compra.id",
                        equals: compra.id
                    }]).value()
                };
            }

            if (vm.fromOutside) {
                defaultColumnOrder.push('compra.codigo');
                defaultColumnOrder.push('embarque.importacion.codigo');
            }

            vm.options = {
                'staticFilter': staticFilter,
                'resource': 'recepcion',
                'title': $filter('translate')('RECEPCIONES'),
                'view': "RecepcionList",
                'factory': RecepcionFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('NUMBER'), 'class': 'dt-center'  },
                    { 'data': 'tipoRecepcion.descripcion', 'title': $filter('translate')('TIPO_RECEPCION') },
                    { 'data': 'almacen.descripcion', 'title': $filter('translate')('ALMACEN') },
                    { 'data': "fecha", 'title': $filter("translate")("DATE"), 'renderWith': "dateRender", 'dateFormat': "DD/MM/YYYY", 'class': "dt-center", 'type': "date-range"},
                    { 'data': "fechaIngresoStock", 'title': $filter("translate")("FECHA_INGRESO_STOCK"), 'renderWith': "dateRender", 'dateFormat': "DD/MM/YYYY", 'class': "dt-center", 'type': "date-range"},
                    { 'data': 'estadoRecepcion.descripcion', 'title': $filter('translate')('STATUS'), 'class': 'dt-center','type' : 'combo', 'filterUrl': 'tipos/filtro/estado_recepcion' },
                    { 'data': 'compra.codigo', 'title': $filter('translate')('COMPRA_REL'), 'renderWith': 'emptyRender', 'class': 'dt-left' },
                    { 'data': 'embarque.importacion.codigo', 'title': $filter('translate')('IMPORTACION_REL'), 'renderWith': 'emptyRender', 'class': 'dt-left'}
                ],
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions': [{
                    'title': $filter('translate')('NEW'),
                    'icon': 'glyphicon glyphicon-plus',
                    'showCondition': function() {
                        return !vm.fromOutside && AuthorizationService.hasPermission('create_recepcion') && CompraFactory.getCompra().estado.codigo != "compra_cerrada"&&
                                    CompraFactory.getCompra().estado.codigo != "compra_anulada";
                    },
                    'action': function() {

                        checkPeriodoAdministrativo(null).then(function(resultado) {
                            if(resultado == true){
                                console.log("Yendo a ruta app.compraslocales.proceso.recepcion.new");
                                $state.go("app.compraslocales.proceso.recepcion.new");
                            }else if(resultado == false){
                                var msg= $filter('translate')('NO_OPEN_PERIODS_NOW');
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        });
                    }

                    },

                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function() { return true; },
                        'action': function() {
                          //var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);
                          var filters = createFilters(vm.options.getFilters());
                          ReportTicketFactory.ticket('listadoRecepciones', filters, vm.options.tableAjaxParams,
                            vm.options.currentColumnOrder).then(function(genTicket) {
                            $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                            $window.open($scope.pdfDownloadURL, '_blank');
                          });
                        }
                    },
                    {
                      'title': 'CSV',
                      'icon': 'glyphicon glyphicon-export',
                      'showCondition': function() { return true; },
                      'action': function() {
                          var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                          CsvFactory.csv("recepcion", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                          .then(function(response) {
                            console.log("response de csv: ", response);
                            var fileName = response.data.fileName;
                            $window.open(baseurl.getPublicBaseUrl() + fileName);
                          });
                        }
                    }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraRowOptions': [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        functionName: "editar",
                        customAttribute: 'estadoRecepcion',
                        functionDef: function(itemId) {
                            $state.go("app.compraslocales.proceso.recepcion.edit", { id: itemId });
                        },
                        conditionName: "canEditar",
                        conditionDef: function(estado) {
                            return !vm.fromOutside && (AuthorizationService.hasPermission("update_recepcion") && estado.codigo == "er_ingresado" && CompraFactory.getCompra().estado.codigo != "compra_cerrada"&&
                                    CompraFactory.getCompra().estado.codigo != "compra_anulada");
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        conditionName: "canBorrar",
                        customAttribute: 'estadoRecepcion',
                        conditionDef: function(estado) {
                            return !vm.fromOutside && (AuthorizationService.hasPermission('delete_recepcion') && estado.codigo == "er_ingresado" && CompraFactory.getCompra().estado.codigo != "compra_cerrada"&&
                                    CompraFactory.getCompra().estado.codigo != "compra_anulada");
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('PERMANENT_OPERATION_WARNING');
                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                $scope.modalInstanceBorrar1.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                var model = RecepcionFactory.create({ id: itemId });
                                RecepcionFactory.remove(model).then(function() {
                                    $scope.modalInstanceBorrar1.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                    $scope.tituloModal = "No se pudo borrar el registro";
                                    $scope.mensajeModal = $scope.options.failedDeleteError;
                                    var modalInstance = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };
                                    console.log("error al borrar: ");
                                    console.log(error);
                                });
                            };
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function(itemId) {
                            if (vm.isProcesoCompra) {
                                $state.go("app.compraslocales.proceso.recepcion.view", { id: itemId });
                            } else if (vm.fromOutside) {
                                $state.go("app.consultarecepciones.view", { id: itemId });
                            }
                        },
                        conditionName: "canVer",
                        conditionDef: function() {
                            return (AuthorizationService.hasPermission('index_recepcion'));
                        },
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('CONFIRMAR_RECEPCION') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataId%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
                        functionName: "aprobar",
                        conditionName: "canAprobar",
                        customAttribute: 'estadoRecepcion',
                        conditionDef: function(estado) {
                            return !vm.fromOutside && AuthorizationService.hasPermission('update_recepcion') && estado.codigo != "er_anulado" && estado.codigo != "er_aprobado";
                        },
                        functionDef: function(itemId) {
                            RecepcionFactory.get(itemId, "RecepcionForm").$promise.then(function(recepcion) {

                                checkPeriodoAdministrativo(recepcion.fechaIngresoStock).then(function(resultado) {
                                    if(resultado == true){

                                        var filtersArr = [];
                                        filtersArr.push('idrecepcion=' + itemId);

                                        if (
                                            compra.grupoCompra &&
                                            compra.grupoCompra.permiteServicio &&
                                            !compra.grupoCompra.permiteProducto
                                        ) {
                                            $scope.mensajeModal = $filter("translate")(
                                                "CONFIRMAR_COMPROBANTE_RECEPCION"
                                            );
                                        } else {
                                            $scope.mensajeModal = $filter("translate")(
                                                "CONFIRMAR_COMPROBANTE_RECEPCION"
                                            );
                                            if(compra){
                                                _.forEach(compra.tipo.tipoCompraDetalles, function(tipoCompraDetalle){

                                                    if((tipoCompraDetalle.realizaIngresoStock === true) &&
                                                        (tipoCompraDetalle.componente.codigo==="comp_tipo_compra_recepcion")){

                                                        $scope.mensajeModal = $filter("translate")(
                                                            "CONFIRMAR_COMPROBANTE_Q"
                                                        );
                                                    }
                                                });
                                            }
                                        }
                                        $scope.tituloModal = $filter("translate")(
                                            "CONFIRMAR_COMPROBANTE_RECEPCION_TITLE"
                                        );
                                        var modalInstance = $modal.open({
                                            templateUrl: "views/directives/confirmation-modal.html",
                                            scope: $scope
                                        });

                                        $scope.cancel = function() {
                                            modalInstance.dismiss("cancel");
                                        };

                                        $scope.ok = function(id) {

                                            RecepcionFactory.aprobar(itemId).then(function(response) {
                                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('recepcion', 'pdf', filtersArr);
                                                $window.open(vm.pdfDownloadURL, '_blank');
                                                $state.go($state.current, {}, { reload: true });
                                            }, function(error) {
                                                if (error && error.data && error.data.length > 0) {
                                                    var msg = "<span>";
                                                    _.forEach(error.data, function(err) {
                                                        if (err.constraint == 'id_producto, numero') {
                                                            msg = msg + $filter('translate')('NUMERO_GRUPO_REPETIDO_PRODUCTO') + "<br/>";
                                                        } else {
                                                            msg = msg + err.message + "<br/>";
                                                        }
                                                    });
                                                    msg = msg + "</span>";
                                                    notify({ messageTemplate: msg, classes: 'alert-danger', position: 'right' });
                                                    // console.log("Mensaje: ", msg);
                                                }
                                            });
                                        };
                                    }else if(resultado == false){
                                        var msg= $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            });
                        }
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('ANULAR') + "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataId%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                        customAttribute: 'estadoRecepcion',
                        conditionName: "canAnular",
                        conditionDef: function(estado) {
                            return !vm.fromOutside && AuthorizationService.hasPermission('update_recepcion') && estado.codigo == "er_aprobado" && CompraFactory.getCompra().estado.codigo != "compra_cerrada"&&
                                    CompraFactory.getCompra().estado.codigo != "compra_anulada";
                        },
                        functionName: "anular",
                        functionDef: function(itemId) {

                            RecepcionFactory.get(itemId, "RecepcionForm").$promise.then(function(recepcion) {

                                checkPeriodoAdministrativo(recepcion.fechaIngresoStock).then(function(resultado) {
                                    if(resultado == true){

                                        var comp = _.filter(recepcion.comprobantes, function(c) {
                                            return c.estado.codigo;
                                        });
                                        if (comp && comp.length > 0) {
                                            notify({ message: $filter('translate')('RECEP_COMP_APROBADO'), classes: 'alert-danger', position: 'right' });
                                        } else {
                                            $scope.selectedItemId = itemId;
                                            $scope.tituloModal = $filter('translate')('CONFIRMACION_ANULACION');
                                            $scope.mensajeModal = $filter('translate')('CAMBIO_ESTADO_ORDEN_COMPRA_ANULADA_WARNING');
                                            $scope.modalInstanceRechazar = $modal.open({
                                                templateUrl: 'views/directives/confirmation-modal.html',
                                                scope: $scope
                                            });

                                            $scope.cancel = function() {
                                                $scope.modalInstanceRechazar.dismiss('cancel');
                                            };

                                            $scope.ok = function(itemId) {
                                                RecepcionFactory.anular(itemId).then(function() {
                                                    $scope.modalInstanceRechazar.close(itemId);
                                                    $state.go($state.current, {}, { reload: true });
                                                }, function(error) {
                                                    if (error && error.data && error.data.length > 0) {
                                                        var msg = "";
                                                        _.forEach(error.data, function(err) {
                                                            msg += err.message + "\n\n";
                                                        });
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                        console.log("Mensaje: ", msg);
                                                    }
                                                });
                                            };
                                        }
                                    }else if(resultado == false){
                                    var msg= $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            });
                        }
                    },
                    {
                        templateToRender: "<button title='" +
                                $filter("translate")("REIMPRIMIR_COMPROBANTE") +
                                "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reimprimir(<%=dataCustom%>)' ng-class='{ hidden : !canReimprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-repeat'></span> </button>",

                        conditionName: "canReimprimir",
                        customAttribute: ["estadoRecepcion","id",],
                        conditionDef: function(atributos) {
                            console.log('vm from outside', vm.fromOutside)
                            var estado = atributos[0];
                            return !vm.fromOutside && AuthorizationService.hasPermission('update_recepcion')
                                    && estado.codigo == "er_aprobado" && CompraFactory.getCompra().estado.codigo != "compra_cerrada"&&
                                    CompraFactory.getCompra().estado.codigo != "compra_anulada";
                        },
                        functionName: "reimprimir",
                        functionDef: function (atributos) {
                            var itemId = atributos[1];
                            CompraFactory.recuperarPorId(itemId).success(function (response) {
                            CompraFactory.get(itemId).$promise.then(function (response ) {

                            $scope.mensajeModal = vm.translations.REIMPRIMIR_COMPROBANTE_Q
                            $scope.tituloModal = vm.translations.REIMPRIMIR_COMPROBANTE

                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                            modalInstance.dismiss("cancel");
                            };

                            $scope.ok = function (id) {
                                var filtersArr = [];
                                filtersArr.push("idrecepcion=" + itemId);
                                modalInstance.close();
                                $state.go($state.current, {}, {reload: true});
                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                        "recepcion",
                                        "pdf",
                                        filtersArr
                                        );
                                $window.open(vm.pdfDownloadURL, "_blank");
                                $state.go($state.current, {}, {reload: true});
                            }
                            });
                           });
                        }
                    },
                ],
            };
            WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");
        });
    }

    var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            search = search.split("~");
            if (search.length > 1) {
                /* El parámetro es un rago */

                var isDate = search[0].split("/");

                if (isDate.length > 1) {
                    /* Es un rago de fechas */

                    filtersArr.push({path: data, equalOrAfter: search[0]})
                    filtersArr.push({path: data, equalOrBefore: search[1]})
                } else {
                    /* El un rago numérco */

                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                    filtersArr.push({path: data, lessOrEqual: search[1]})
                }
            } else {
                /* El parametro no es un rago */
                filtersArr.push({path: data, like: search[0]})
            }
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }
}

"use strict";

angular
    .module("qualita.compra")
    .controller("ComprobanteLocalFormCtrl", ComprobanteLocalFormCtrl);

ComprobanteLocalFormCtrl.$inject = [
    "ComprobanteFactory",
    "comprobantePrepService",
    "ProductosFactory",
    "TiposFactory",
    "ProveedoresFactory",
    "TipoComprobanteFactory",
    "GruposCompraFactory",
    "CotizacionMonedaFactory",
    "ServicioFactory",
    "filterFactory",
    "ParametrosFactory",
    "OrdenesCompraFactory",
    "$scope",
    "notify",
    "$state",
    "$rootScope",
    "$timeout",
    "formFactory",
    "TipoImpuestosFactory",
    "RecepcionFactory",
    "ProveedorSucursalesFactory",
    "RubroIvaFactory",
    "ModelTrimmer",
    "AlmacenFactory",
    "TransaccionStockFactory",
    "TimbradoProveedorFactory",
    "CompraLocalLangFactory",
    "CompraFactory",
    "WatchLocacionChangeFactory",
    "UtilFactory",
    "ComprobanteDetalleFactory",
    "OrdenesCompraDetalleFactory",
    "GruposProductosFactory",
    "$q",
    "SolicitudCompraFactory",
    "ProyectoFactory",
    "$filter",
    "PeriodoAdministrativoFactory",
    "SucursalesFactory",
    "CentroCostosFactory",
    "AuthorizationService",
    "$location",
    "RecepcionDetalleFactory"
];

function ComprobanteLocalFormCtrl(
    ComprobanteFactory,
    comprobantePrepService,
    ProductosFactory,
    TiposFactory,
    ProveedoresFactory,
    TipoComprobanteFactory,
    GruposCompraFactory,
    CotizacionMonedaFactory,
    ServicioFactory,
    filterFactory,
    ParametrosFactory,
    OrdenesCompraFactory,
    $scope,
    notify,
    $state,
    $rootScope,
    $timeout,
    formFactory,
    TipoImpuestosFactory,
    RecepcionFactory,
    ProveedorSucursalesFactory,
    RubroIvaFactory,
    ModelTrimmer,
    AlmacenFactory,
    TransaccionStockFactory,
    TimbradoProveedorFactory,
    CompraLocalLangFactory,
    CompraFactory,
    WatchLocacionChangeFactory,
    UtilFactory,
    ComprobanteDetalleFactory,
    OrdenesCompraDetalleFactory,
    GruposProductosFactory,
    $q,
    SolicitudCompraFactory,
    ProyectoFactory,
    $filter,
    PeriodoAdministrativoFactory,
    SucursalesFactory,
    CentroCostosFactory,
    AuthorizationService,
    $location
) {
    var vm = this;

    activate();
    var savedTime = undefined;
    vm.agregarDetalle = agregarDetalle;
    vm.agregarDetalleServicio = agregarDetalleServicio;
    vm.agregarDetalleProducto = agregarDetalleProducto;
    vm.agregarMontoImponible = agregarMontoImponible;
    vm.calcularTotalDetalle = calcularTotalDetalle;
    vm.removeItemFromArray = removeItemFromArray;
    vm.searchProductoFilter = searchProductoFilter;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.changeOC = changeOC;
    vm.changeTipo = changeTipo;
    vm.changeProducto = changeProducto;
    vm.changeServicio = changeServicio;
    vm.changeCotizacion = changeCotizacion;
    vm.changeGrupoCompra = changeGrupoCompra;
    vm.changeRecepciones = changeRecepciones;
    vm.mostrarTodosProductos = mostrarTodosProductos;
    vm.totalImpuestoChanged = totalImpuestoChanged;
    vm.tipoImpuestoChanged = tipoImpuestoChanged;
    vm.changeProveedor = changeProveedor;
    vm.changeSucursalProveedor = changeSucursalProveedor;
    vm.requireDeposito = requireDeposito;
    vm.totalItemChanged = totalItemChanged;
    vm.searchSolicitudCompraFilter = searchSolicitudCompraFilter;
    vm.addProductoGrupo = addProductoGrupo;
    vm.comprobanteGeneraMovimiento = false;
    vm.validarNroFactura = validarNroFactura;
    vm.changeAlmacen = changeAlmacen;
    vm.changeTipoComprobante = changeTipoComprobante;
    vm.updateTipoImpuestoOptions = updateTipoImpuestoOptions;
    vm.evaluateNroGrupo = evaluateNroGrupo;
    vm.delayRequestProducto = delayRequestProducto;
    vm.changeProducto = changeProducto;
    vm.checkProveedor = checkProveedor;
    vm.verificarExistencia = verificarExistencia;
    vm.changeFecha = changeFecha;

    vm.numeroLlamado = 0;

    function changeFecha(detalle) {
        var params = {};
        params.search = filterFactory.and([{
            path: 'numero',
            equals: detalle.numeroGrupo
        },
        {
            path: 'producto.id',
            equals: detalle.producto.id
        }
        ]).value();
        GruposProductosFactory.all(params).$promise.then(function (response) {
            //buscar si existe en grupo productos
            if (response.length > 0) {
                notify({
                    message: "El nro. grupo ya cuenta con fecha de vencimiento, no puede cambiar.",
                    classes: "alert-warning",
                    position: "right"
                });
                detalle.fechaVencimientoGrupo = moment(response[0].fechaVencimiento,"DD/MM/YYYY").toDate();
            }
        })
    }

    function verificarExistencia(detalle) {
        if (detalle.numeroGrupo != null) {
            var params = {};
            params.search = filterFactory.and([{
                path: 'numero',
                equals: detalle.numeroGrupo
            },
            {
                path: 'producto.id',
                equals: detalle.producto.id
            }
            ]).value();
            GruposProductosFactory.all(params).$promise.then(function (response) {
                //buscar si existe en grupo productos
                if (response.length > 0) {
                    detalle.fechaVencimientoGrupo = moment(response[0].fechaVencimiento,"DD/MM/YYYY").toDate();
                    vm.existeGrupo = true;
                } else {
                    vm.existeGrupo = false;
                    detalle.fechaVencimientoGrupo = null;
                }
            })
        } else {
            vm.existeGrupo = false;
            detalle.fechaVencimientoGrupo = null;
        }
    }

    function changeProducto(detalle) {
        //console.log("changeProducto");
        ProductosFactory.get(detalle.producto.id, 'BaseList').$promise.then(function (producto) {
            detalle.producto = producto;
            detalle.unidadMedidaBase = detalle.producto.unidadMedidaBase;
            detalle.unidadMedidaReferencial = null;
            detalle.total = 0;
            if (detalle.producto.criterioControl.codigo === 'series') {
                detalle.cantidad = 1;
            }

            detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        });

    }

    function changeTipoComprobante(activateEdit) {
        vm.requerirTimbrado = true;
        vm.isNotaCreditoNoControl = false;
        if (!activateEdit) {
            // fixme: Eso es un parche para evitar que el numero de comprobante se ponga nulo al editar
            // deberia solucionarse de otra forma.
            vm.comprobante.numero = null;
            vm.comprobante.timbradoProveedor = null;
        }
        updateTipoImpuestoOptions();
        mostrarTodosProductos();
        filterServicios();
        if (!vm.comprobante.tipo.generarCuotas) {
            vm.cantidadCuotas = undefined;
            vm.plazoCuotas = undefined;
            vm.montoInicial = undefined;
            vm.comprobante.planPagos = undefined;
        } else {
            vm.cantidadCuotas = 1;
            vm.montoInicial = 0;

        }

        if (vm.comprobante.tipo.clase.codigo == 'comprobante_interno' ||
            vm.comprobante.tipo.clase.codigo == 'factura_exterior' ||
            vm.comprobante.tipo.clase.codigo == "despacho" ||
            (vm.comprobante.tipo.clase.codigo == "nota_credito" && !vm.comprobante.tipo.controlarTimbrado) ||
            vm.comprobante.tipo.clase.codigo == "retencion_absorbida"
        ) {
            vm.requerirTimbrado = false;
            vm.comprobante.timbradoProveedor = null;
        }

        if (vm.requerirTimbrado) {
            filterTimbrado();
        }
    }

    function updateTipoImpuestoOptions() {
        if (vm.comprobante.tipo.clase.codigo == "autofactura") {
            var paramsTipoImpuesto = {};
            paramsTipoImpuesto.search = filterFactory
                .and([{
                    path: "porcentaje",
                    equals: 0
                },
                {
                    path: "activo",
                    equals: true
                }])
                .value();
            paramsTipoImpuesto.view = "BaseList"
            vm.tiposImpuesto = TipoImpuestosFactory.all(paramsTipoImpuesto);
        } else {
            var paramsTipoImpuesto = {
                search: filterFactory
                    .and([{
                        path: "activo",
                        equals: true
                    }])
                    .value()
            };
            paramsTipoImpuesto.view = "BaseList";
            vm.tiposImpuesto = TipoImpuestosFactory.all(paramsTipoImpuesto);
        }
    }


    var sumaImponible;

    $rootScope.isProcessing = false;

    function activate() {
        vm.recepcionObligatoria = false;
        vm.requerirTimbrado = true;
        vm.modificarSucursalItems = AuthorizationService.hasPermission('modificar_sucursal_en_items');
        vm.modificarImputacion = AuthorizationService.hasPermission('update_comprobantes_aprobados');
        ParametrosFactory.filterByCodigo("formato_nro_comprobante").$promise.then(function (response) {
            vm.formatoComprobante = response[0].valor;
        })

        ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
          vm.proyectoObligatorio = r.valorTipo.codigo == "si";
        });

        WatchLocacionChangeFactory.killAllWatchers();
        CompraLocalLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            vm.cotizacionRequerida = false;
            vm.valorFOBRequerido = false;
            var compra = undefined;
            var staticFilter = undefined;
            if ($state.includes("app.compraslocales.proceso.comprobante")) {
                vm.isProcesoCompra = true;
                compra = CompraFactory.getCompra();
                vm.compra = compra;

                if (!compra) {
                    $state.go("app.main");
                }

                var tipo = compra.tipo;

                var tipoCompraDetalle = _.find(tipo.tipoCompraDetalles, function (
                    detalle
                ) {
                    return detalle.componente.codigo == "comp_tipo_compra_comprobante";
                });

                // Si el tipo de compra tiene recepción y la recepción esta antes del comprobante
                // el campo recepción en el comprobante debe ser obligatorio.

                var ordenRecepcion = 9999;
                var ordenComprobante = 0;
                tipo.tipoCompraDetalles.forEach(function (value) {
                    if (value.componente.codigo == "comp_tipo_compra_recepcion") {
                        ordenRecepcion = value.orden;
                    }
                    if (value.componente.codigo == "comp_tipo_compra_comprobante") {
                        ordenComprobante = value.orden;
                    }
                })

                if (ordenRecepcion < ordenComprobante) {
                    vm.recepcionObligatoria = true;
                }

                if (ordenRecepcion == 9999) {
                    // no se modifico valor inicializado, no existe recepcion en tipo de compra.
                    vm.bloquearRecepcion = true;
                }

                if (tipoCompraDetalle.realizaIngresoStock == true) {
                    vm.comprobanteGeneraMovimiento = true;
                }
                if (!tipoCompraDetalle) {
                    $state.go("app.main");
                }
                var isStateEnabled = CompraFactory.checkPermissionForState(
                    tipoCompraDetalle.orden - 1
                );
                if (!isStateEnabled) {
                    $state.go("app.main");
                }

                staticFilter = {};
                var staticFilterOC = {};
                staticFilterOC.search = filterFactory
                    .or([
                        filterFactory
                            .and([{
                                path: "tipoOrdenCompra.esImportacion",
                                like: "false"
                            },
                            {
                                path: "estado.codigo",
                                equals: "ocl_emitida"
                            },
                            {
                                path: "compras.id",
                                equals: compra.id
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "tipoOrdenCompra.esImportacion",
                                like: "false"
                            },
                            {
                                path: "estado.codigo",
                                equals: "ocl_recepcionada"
                            },
                            {
                                path: "compras.id",
                                equals: compra.id
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "tipoOrdenCompra.esImportacion",
                                like: "false"
                            },
                            {
                                path: "estado.codigo",
                                equals: "ocl_parcialmente_recepcionada"
                            },
                            {
                                path: "compras.id",
                                equals: compra.id
                            }
                            ])
                            .value()
                    ])
                    .value();
            } else if (
                $state.is("app.consultacomprobantes.view") ||
                $state.is("app.consultacomprobantes.new") ||
                $state.is("app.consultacomprobantes.edit") ||
                $state.is("app.consultacomprobantes.editAprobado")
            ) {
                vm.fromOutside = true;
            }

            var params2 = {};
            params2.search = filterFactory
                .and([{
                    path: "activo",
                    equals: true
                }])
                .value();

            params2.view = "Base"

            vm.monedaLocal = ParametrosFactory.filterByCodigo("moneda_local");

            vm.transacciones = TransaccionStockFactory.all(params2);

            var staticFilterSC = {};
            staticFilterSC.search = filterFactory
                .and([{
                    path: "estado.codigo",
                    equals: "sc_aprobada"
                }])
                .value();
            staticFilterSC.view = "BaseList"
            if (vm.watcherSC) {
                vm.watcherSC();
            } //hacemos unwatch de la expresion anterior
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcherSC
            ) {
                vm.watcherSC = watcherSC;
                SolicitudCompraFactory.all(
                    UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        staticFilterSC
                    )
                ).$promise.then(function (response) {
                    vm.solicitudesCompra = response;
                    _.each(vm.solicitudesCompra, function (solicitudCompra) {
                        solicitudCompra.fecha = moment
                            .utc(solicitudCompra.fecha)
                            .format("DD/MM/YYYY");
                    });
                });
            }, "locacion.id");

            if (
                $state.is("app.compraslocales.proceso.comprobante.new") ||
                $state.is("app.consultacomprobantes.new")
            ) {
                activateNew();
            } else if (
                $state.is("app.compraslocales.proceso.comprobante.edit") ||
                $state.is("app.consultacomprobantes.edit")
            ) {
                activateEdit();
            } else if (
                $state.is("app.compraslocales.proceso.comprobante.view") ||
                $state.is("app.consultacomprobantes.view")
            ) {
                activateView();
            } else if (
                $state.is("app.consultacomprobantes.editAprobado")
            ) {
                activateEditAprobado();
            }
            if (vm.watchOC) {
                vm.watcherOC();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcherOC = watcher;
                vm.ordenesCompra = OrdenesCompraFactory.all(
                    UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        staticFilterOC
                    ),
                    "OrdenCompraList"
                );
            }, "locacion.id");

            vm.curDate = new Date();

            /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
            vm.staticFilterProv = [{ path: "activo", equals: true }];
            vm.proveedoresFactory = ProveedoresFactory;

            vm.factoryProductos = ProductosFactory;

            var renderEmptyData = function (data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            var defaultColumnOrderProducto = [
                "codigo",
                "nombre",
                "claseProducto.descripcion",
                "estado.descripcion",
                "lineaProducto.descripcion",
                "marca.descripcion"
            ];

            /* Al definir filtros aqui, estos no se aplican, ver mostrarTodosProductos() y filterProductos()
             Se usan los filtros de mostrarTodosProductos(), cuando se marca la casilla "Mostrar todos los productos")
             Se usan los filtros de filterProductos() por defecto (casilla desmarcada)
             */
            vm.opcionesProductosDt = {
                resource: "productos",
                title: "Productos",
                factory: ProductosFactory,
                defaultColumnOrder: defaultColumnOrderProducto,
                view: "ProductoList",
                columns: [
                    { data: "codigo", title: $filter("translate")("ID"), visible: false },
                    { data: "nombre", title: $filter("translate")("NOMBRE") },
                    {
                        data: "claseProducto.descripcion",
                        title: $filter("translate")("KIND")
                    },
                    {
                        data: "estado.descripcion",
                        title: $filter("translate")("STATUS"),
                        type: "combo",
                        filterUrl: "tipos/tipo/producto_estados"
                    },
                    {
                        data: "lineaProducto.nombre",
                        title: $filter("translate")("LINE")
                    },
                    {
                        data: "marca.descripcion",
                        title: $filter("translate")("BRAND"),
                        render: renderEmptyData
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc"
            };

            vm.factoryServicios = ServicioFactory;
            var defaultColumnOrderServicio = [
                "id",
                "codigo",
                "descripcion",
                "tipoImpuesto.descripcion"
            ];

            vm.opcionesServiciosDt = {
                resource: "servicios",
                title: "Servicio",
                factory: ServicioFactory,
                view: "ServicioList",
                defaultColumnOrder: defaultColumnOrderServicio,
                columns: [
                    { data: "id", title: $filter("translate")("CODE") },
                    { data: "descripcion", title: $filter("translate")("DESCRIPTION") },
                    {
                        data: "tipoImpuesto.descripcion",
                        title: $filter("translate")("TIPO_IMPUESTO")
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc"
            };
            var filtroServiciosDt = {};
            filtroServiciosDt.search = filterFactory
                .and([{
                    path: 'compra',
                    like: 'true'
                }])
                .value();
            vm.opcionesServiciosDt.staticFilter = filtroServiciosDt;

            if (vm.watcher) {
                vm.watcher();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcher = watcher;

                var estadoFilter = {
                    search: filterFactory
                        .single({
                            path: "estado.codigo",
                            equals: "activo"
                        })
                        .value()
                };

                var filter = UtilFactory.createNestedFilters(
                    locacionFilter.search.filters,
                    estadoFilter
                );

                if (
                    vm.fromOutside &&
                    vm.comprobante &&
                    vm.comprobante.tipo &&
                    vm.comprobante.tipo.clase.codigo != "nota_credito"
                ) {
                    var filterStock = {
                        search: filterFactory
                            .single({
                                path: "controlStock",
                                like: "false"
                            },
                                {
                                    path: 'estado.codigo',
                                    equals: 'activo'
                                })
                            .value()
                    };

                    var filter = UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        filterStock
                    );
                }

                filter.view = "BaseList"

                //vm.productos = ProductosFactory.allForSelect(filter);
                vm.opcionesProductosDt.staticFilter = filter;
            }, "locaciones.id");


            var filtroServicios = {};
            filtroServicios.search = filterFactory.and([{
                path: 'compra',
                equals: true
            }]).value();

            vm.servicios = ServicioFactory.all(filtroServicios);

            var staticFilterTC = {
                search: filterFactory
                    .and([{
                        path: "utilizarEnProcesosCompra",
                        equals: true
                    },
                    {
                        path: "activo",
                        equals: true
                    }])
                    .value()
            };
            vm.tipos = TipoComprobanteFactory.all(staticFilterTC);
            var paramsGC = {};
            paramsGC.search = filterFactory
                .single({
                    path: "activo",
                    equals: true
                })
                .value();

            paramsGC.view = "BaseList"

            GruposCompraFactory.all(paramsGC).$promise.then(function (response) {
                vm.gruposCompra = _.filter(response, function (grupoCompra) {
                    return !(
                        grupoCompra.codigo == "gasto_importacion" ||
                        grupoCompra.codigo == "comprobante_importacion"
                    );
                });
            });

            var paramsTipoImpuesto = {
                search: filterFactory
                    .and([{
                        path: "activo",
                        equals: true
                    }])
                    .value()
            };
            paramsTipoImpuesto.view = "BaseList"
            vm.tiposImpuesto = TipoImpuestosFactory.all(paramsTipoImpuesto);

            var paramsRubroIva = {};
            paramsRubroIva.search = filterFactory
                .single({
                    path: "descripcion",
                    sortAsc: true
                })
                .value();

            paramsRubroIva.view = "BaseList"

            vm.rubrosIva = RubroIvaFactory.all(paramsRubroIva);

            //*** sucursales servicios */
            var paramsSucursales = {};
            paramsSucursales.search = filterFactory
                .single({
                    path: "nombre",
                    sortAsc: true
                })
                .value();

            paramsSucursales.view = "BaseList"

            vm.sucursalesServicio = SucursalesFactory.all(paramsSucursales);
            //***sucursales servicios */

            //*** centro de costos */
            var paramsCentroCostos = {};
            paramsCentroCostos.search = filterFactory
                .and({
                    path: "activo",
                    equals: true
                }, {
                    path: "descripcion",
                    sortAsc: true
                }).value();

            paramsCentroCostos.view = "BaseList"

            vm.centrocostosList = CentroCostosFactory.all(paramsCentroCostos);
            //*** centro de costos */

            if (vm.watcherAlmacen) {
                vm.watcherAlmacen();
            } //hacemos unwatch de la expresion anterior
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcherAlmacen
            ) {
                vm.watcherAlmacen = watcherAlmacen;
                var filterSucursal = {
                    search: filterFactory
                        .and([
                            {
                                path: "sucursal.id",
                                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                        ])
                        .value()
                };
                vm.depositos = AlmacenFactory.all(filterSucursal);
            }, "locacion.id");


            var filterProyecto = {};
            filterProyecto.view = "BaseList"

            vm.proyectos = ProyectoFactory.all(filterProyecto, "ProyectoList");

            if (vm.isProcesoCompra) {
                if (vm.watchOC) {
                    vm.watcherOC();
                }
                WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcher
                ) {
                    vm.watcherOC = watcher;
                    vm.ordenesCompra = OrdenesCompraFactory.all(
                        UtilFactory.createNestedFilters(
                            locacionFilter.search.filters,
                            staticFilterOC
                        ),
                        "OrdenCompraList"
                    );
                }, "locacion.id");

                if (vm.compra.tipo.codigo != "tipo_oc") {
                    var staticFilterRecepcion = {};

                    staticFilterRecepcion.search = filterFactory
                        .and([{
                            path: "estadoRecepcion.codigo",
                            equals: "er_aprobado"
                        },
                        {
                            path: "compra.id",
                            equals: compra.id
                        }
                        ])
                        .value();

                    if (vm.watcherRecepcion) {
                        vm.watcherRecepcion();
                    }
                    WatchLocacionChangeFactory.executeWatching(function (
                        locacionFilter,
                        watcherRecepcion
                    ) {
                        vm.watcherRecepcion = watcherRecepcion;
                        vm.recepciones = RecepcionFactory.all(
                            UtilFactory.createNestedFilters(
                                locacionFilter.search.filters,
                                staticFilterRecepcion
                            ),
                            "RecepcionList"
                        );
                    }, "locacion.id");
                }
            } else {
                var staticFilterRecepcion = {};
                staticFilterRecepcion.search = filterFactory
                    .and([{
                        path: "estadoRecepcion.codigo",
                        equals: "er_aprobado"
                    }])
                    .value();
                if (vm.watcherRecepcion) {
                    vm.watcherRecepcion();
                }
                WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcherRecepcion
                ) {
                    vm.watcherRecepcion = watcherRecepcion;
                    vm.recepciones = RecepcionFactory.all(
                        UtilFactory.createNestedFilters(
                            locacionFilter.search.filters,
                            staticFilterRecepcion
                        ),
                        "RecepcionList"
                    );
                }, "locacion.id");
            }

            var staticFilter = {};
            staticFilter.search = filterFactory
                .and([{
                    path: "accion.codigo",
                    equals: "ingreso_almacen"
                }])
                .value();

            vm.monedas = {};
            TiposFactory.monedas().then(function (data) {
                vm.monedas = data.data;
            });
            getParamDiferenciaMonedaLocal();
            getParamDiferenciaMonedaExtranjera();
        });
    }

    function evaluateNroGrupo() {
        vm.bloquearNroGrupo = false;
        if (vm.compra) {
            _.forEach(vm.compra.tipo.tipoCompraDetalles, function (tipoCompraDetalle) {
                if (tipoCompraDetalle.componente.codigo == "comp_tipo_compra_comprobante") {
                    vm.realizaIngresoStock = tipoCompraDetalle.realizaIngresoStock;
                    if (!vm.realizaIngresoStock) {
                        vm.bloquearNroGrupo = true;
                    }
                    return;
                }
            });
        }
    }

    function activateNew() {
        abortRouteIfNecessary();
        vm.title = $filter("translate")("NEW_COMPROBANTE");
        vm.tiposDetalle = {};
        vm.totalImpuestosGeneral = 0;

        vm.comprobante = {};

        vm.hasPermisoObligaciones = AuthorizationService.hasPermission("update_obligaciones_asociadas");

        ParametrosFactory.filterByCodigo("imputa_iva").$promise.then(function (response) {
            if (response[0].valor == "Sí") {
                vm.comprobante.imputarIvaGeneral = true;
                //vm.comprobante.imputar = true;
            } else if (response[0].valor == "No") {
                vm.comprobante.imputarIvaGeneral = false;
            }
        })
        ParametrosFactory.filterByCodigo("imputa_ire").$promise.then(function (response) {
            if (response[0].valor == "Sí") {
                vm.comprobante.imputarIre = true;
                //vm.comprobante.imputar = true;
            } else if (response[0].valor == "No") {
                vm.comprobante.imputarIre = false;
            }
        })
        ParametrosFactory.filterByCodigo("imputa_irp_rsp").$promise.then(function (response) {
            if (response[0].valor == "Sí") {
                vm.comprobante.imputarIrpRsp = true;
                // vm.comprobante.imputar = true;
            } else if (response[0].valor == "No") {
                vm.comprobante.imputarIrpRsp = false;
            }
        })
        //vm.comprobante.noImputar


        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;

            vm.comprobante.comprobanteDetalle = [];
            if (vm.isProcesoCompra) {
                vm.comprobante.compra = CompraFactory.getIdCompra();
            }
            vm.comprobante.montosImponibles = [{}];

            if (vm.compra && vm.compra.ordenCompra) {
                OrdenesCompraFactory.get(
                    vm.compra.ordenCompra.id,
                    "OrdenCompraForm"
                ).$promise.then(function (response) {
                    vm.comprobante.ordenCompra = response;
                    if (!vm.comprobante.ordenCompra.tipoCambio) {
                        vm.comprobante.ordenCompra.tipoCambio = 1;
                    }

                    changeOC();
                });
            } else if (vm.compra && vm.compra.proveedor) {
                vm.comprobante.proveedor = vm.compra.proveedor;
                changeProveedor();

                vm.comprobante.cambio = 1;
            }
            vm.comprobantesProductos = []

            /*[{
             montosImponibles: [{}],
             tipo: getTipoProducto(vm.tiposDetalle)
             }];*/
            vm.comprobantesServicios = []

            /*
             [{
             montosImponibles: [{}],
             tipo: vm.tiposDetalle[1]
             }]; */


            getMonedaLocal();
        });
        vm.totalGeneralProductos = 0;
        vm.totalGeneralServicios = 0;
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);

        evaluateNroGrupo();
    }

    function activateEdit() {
        abortRouteIfNecessary();
        vm.title = $filter("translate")("EDIT_COMPROBANTE");
        vm.tiposDetalle = {};

        vm.hasPermisoObligaciones = AuthorizationService.hasPermission("update_obligaciones_asociadas");

        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;
            vm.comprobante = comprobantePrepService;
            vm.comprobante.fecha = new Date(vm.comprobante.fecha);
            changeProveedor(false);
            changeTipoComprobante(true);

            if (vm.comprobante.timbradoProveedor) {
                vm.comprobante.numeroTimbrado = vm.comprobante.timbradoProveedor.numero;
            }

            getPlanPagos();
            vm.entidad = "Comprobante";
            vm.entidadId = vm.comprobante.id;

            if (vm.comprobante.moneda1.codigo != "guaranies") {
                vm.cantDigitos = 2;
            }

            if (vm.comprobante.comprobanteDetalle) {
                _.forEach(vm.comprobante.comprobanteDetalle, function (detalle) {
                    ComprobanteDetalleFactory.get(
                        detalle.id,
                        "ComprobanteForm"
                    ).$promise.then(function (response) {
                        detalle.montosImponibles = response.montosImponibles;
                        if (detalle.producto != null) {
                            detalle.grupoProductoSelect = _.find(detalle.producto.gruposProducto, function (grupo) {
                                if (detalle.numeroGrupo == grupo.numero) {
                                    return grupo;
                                }
                            });
                        }
                    });
                });
            }
            splitDetalles();
            vm.totalGeneralProductos = _.reduce(
                vm.comprobantesProductos,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            vm.totalGeneralServicios = _.reduce(
                vm.comprobantesServicios,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            redondeoTotales();
        });
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);
        vm.isEdit = true;
        evaluateNroGrupo();
    }

    function activateView() {
        vm.title = $filter("translate")("VIEW_COMPROBANTE");
        vm.tiposDetalle = {};
        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;
            vm.comprobante = comprobantePrepService;
            vm.comprobante.fecha = new Date(vm.comprobante.fecha);
            if (vm.comprobante.timbradoProveedor) {
                vm.comprobante.numeroTimbrado = vm.comprobante.timbradoProveedor.numero;
            }
            getPlanPagos();
            vm.entidad = "ComprobanteImportacion";
            vm.entidadId = vm.comprobante.id;
            if (vm.comprobante.comprobanteDetalle) {
                _.forEach(vm.comprobante.comprobanteDetalle, function (detalle) {
                    ComprobanteDetalleFactory.get(
                        detalle.id,
                        "ComprobanteForm"
                    ).$promise.then(function (response) {
                        detalle.montosImponibles = response.montosImponibles;
                        detalle.grupoProductoSelect = _.find(detalle.producto.gruposProducto, function (grupo) {
                            if (detalle.numeroGrupo == grupo.numero) {
                                return grupo;
                            }
                        });
                    });
                });
            }
            splitDetalles();
            vm.totalGeneralProductos = _.reduce(
                vm.comprobantesProductos,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            vm.totalGeneralServicios = _.reduce(
                vm.comprobantesServicios,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            redondeoTotales();
        });
        vm.view = true;
    }

    function activateEditAprobado() {
        abortRouteIfNecessary();
        vm.title = $filter("translate")("EDIT_COMPROBANTE_APROBADO");
        vm.comprobante = comprobantePrepService;
        vm.comprobante.fecha = new Date(vm.comprobante.fecha);
        vm.hasPermisoObligaciones = true;
        getPlanPagos();
        vm.entidad = "Comprobante";
        vm.entidadId = vm.comprobante.id;

        if (vm.comprobante.moneda1.codigo != "guaranies") {
            vm.cantDigitos = 2;
        }

        if (vm.comprobante.comprobanteDetalle) {
            _.forEach(vm.comprobante.comprobanteDetalle, function (detalle) {
                ComprobanteDetalleFactory.get(
                    detalle.id,
                    "ComprobanteForm"
                ).$promise.then(function (response) {
                    detalle.montosImponibles = response.montosImponibles;
                    if (detalle.producto != null) {
                        detalle.grupoProductoSelect = _.find(detalle.producto.gruposProducto, function (grupo) {
                            if (detalle.numeroGrupo == grupo.numero) {
                                return grupo;
                            }
                        });
                    }
                });
            });
        }
        splitDetalles();
        vm.totalGeneralProductos = _.reduce(
            vm.comprobantesProductos,
            function (sum, detalle) {
                return sum + detalle.totalItem;
            },
            0
        );
        vm.totalGeneralServicios = _.reduce(
            vm.comprobantesServicios,
            function (sum, detalle) {
                return sum + detalle.totalItem;
            },
            0
        );
        redondeoTotales();
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);
        vm.isEditAprob = true;
        if (vm.comprobante.tipo.clase.codigo == 'comprobante_interno' ||
            vm.comprobante.tipo.clase.codigo == 'factura_exterior' ||
            vm.comprobante.tipo.clase.codigo == "despacho" ||
            (vm.comprobante.tipo.clase.codigo == "nota_credito" && !vm.comprobante.tipo.controlarTimbrado) ||
            vm.comprobante.tipo.clase.codigo == "retencion_absorbida" ) {
              vm.requerirTimbrado = false;
              // vm.comprobante.timbradoProveedor = null;
            }
    }

    function redondeoTotales() {
        if (vm.comprobante.moneda1.codigo == "guaranies") {
            vm.totalGeneralProductos = parseFloat(vm.totalGeneralProductos.toFixed(0));
            vm.totalGeneralServicios = parseFloat(vm.totalGeneralServicios.toFixed(0));
        } else {
            vm.totalGeneralProductos = parseFloat(vm.totalGeneralProductos.toFixed(4));
            vm.totalGeneralServicios = parseFloat(vm.totalGeneralServicios.toFixed(4));
        }
    }

    function abortRouteIfNecessary() {
        if (vm.isProcesoCompra === true && vm.compra.estado.codigo === "compra_cerrada" &&
            CompraFactory.getCompra().estado.codigo != "compra_anulada") {
            $state.go("app.main");
        }
    }

    // INFO: ya no se usa porque se guarda solo el numero y al aprobar recién se genera el grupo
    function addProductoGrupo(detalle) {
        detalle.grupoProducto.producto = detalle.producto.id;
    }

    function getPlanPagos() {
        if (vm.comprobante.planPagos) {
            vm.cantidadCuotas = vm.comprobante.planPagos.cantidadCuotas;
            vm.montoInicial = vm.comprobante.planPagos.montoInicial;
            vm.plazoCuotas = vm.comprobante.planPagos.plazoCuotas;
        }
    }

    function getTipoServicio(tipos) {
        var tipoServicio = _.filter(tipos, function (elem) {
            return elem.codigo === "detalle_comprobante_servicio";
        });
        return tipoServicio[0];
    }

    function getTipoProducto(tipos) {
        var tipoProducto = _.filter(tipos, function (elem) {
            return elem.codigo === "detalle_comprobante_producto";
        });
        return tipoProducto[0];
    }

    function getMonedaLocal() {
        var moneda = ParametrosFactory.filterByCodigo("moneda_local");
        moneda.$promise.then(function (data) {
            if (data && data.length > 0) {
                if (vm.comprobante.moneda1 == undefined) {
                    vm.comprobante.moneda1 = data[0].valorTipo;
                }
                vm.monedaDefault = data[0].valorTipo;
            }
        });
    }

    function recuperaAsinc(numeroLlamado) {

        vm.comprobantesProductos = [];
        if (vm.comprobante.recepciones && vm.comprobante.recepciones.length > 0) {
            for (var i = 0; i < vm.comprobante.recepciones.length; i++) {
                var recepcion = vm.comprobante.recepciones[i];
                CompraFactory.getSugerenciasRecepcion(recepcion.id).then(function (
                    response
                ) {

                    //console.log("AQUI SUGRENCIAS: ", response);

                    //Aqui recupera detalles recepcion

                    if (numeroLlamado < vm.numeroLlamado) {
                        return
                    }

                    if (response.data && response.data.length > 0) {
                        var tieneRecepcion = false;
                        var tieneComprobante = false;
                        var tieneOC = false;
                        var ordenRecepcion = 0;
                        var ordenComprobante = 9999;
                        var ordenOC = 9999;
                        var tipoRecepcionComprobante = false;

                        vm.compra.tipo.tipoCompraDetalles.forEach(function (value) {
                          if (value.componente.codigo == "comp_tipo_compra_recepcion") {
                            tieneRecepcion = true;
                            ordenRecepcion = value.orden;
                          }
                          if (value.componente.codigo == "comp_tipo_compra_comprobante") {
                            tieneComprobante= true;
                            ordenComprobante = value.orden;
                          }
                          if (value.componente.codigo == "comp_tipo_compra_orden_compra") {
                            tieneOC= true;
                            ordenOC = value.orden;
                          }
                        })
                        if (tieneRecepcion && tieneComprobante && (ordenRecepcion < ordenComprobante)) {
                          tipoRecepcionComprobante = true;
                        }
                        if(!tieneOC){
                            _.forEach(response.data, function (detalle) {
                                // _.forEach(recepcion.ordenCompra.ordenCompraDetalle, function (detalle1) {
                                    // if (detalle1.producto.id == detalle.producto.id && detalle1.unidadMedida.id == detalle.unidadMedida.id) {
                                    //     detalle.ultimoPrecio = detalle1.ultimoPrecio;
                                    //     detalle.descuento = detalle1.porcentajeDescuento;
                                    //     detalle.totalItem = detalle1.totalItem;
                                    //     if (vm.comprobante.moneda1.codigo == "guaranies") {
                                    //         detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(0));
                                    //         detalle.totalItem = parseFloat(detalle.totalItem.toFixed(0));
                                    //     } else {
                                    //         detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(4));
                                    //         detalle.totalItem = parseFloat(detalle.totalItem.toFixed(0));
                                    //     }

                                        if (detalle.producto) {
                                            ProductosFactory.get(
                                                detalle.producto.id,
                                                "ProductoList"
                                            ).$promise.then(function (response) {
                                                // Solo para casos en los que la operación desde la que se recupera permite serie / lote != 1
                                                // Como recepción solo deja poner 1 simplemente se recupera

                                                //Aqui recupera detalles de los productos

                                                if (numeroLlamado < vm.numeroLlamado) {
                                                    return
                                                }

                                                if (detalle.producto.criterioControl.codigo === "series" && !vm.bloquearNroGrupo) {
                                                    for (var i = 0; i < detalle.cantidadRecepcionada; i++) {
                                                        var newProducto = {
                                                            montosImponibles: [{
                                                                tipoImpuesto: response.tiposImpuesto[0],
                                                                porcentaje: response.tiposImpuesto[0].porcentaje,
                                                                valor: 0
                                                            }],
                                                            producto: detalle.producto,
                                                            unidadMedida: detalle.unidadMedida,
                                                            cantidad: 1,
                                                            ultimoPrecio: detalle.ultimoPrecio,
                                                            tipo: getTipoProducto(vm.tiposDetalle),
                                                            numeroGrupo: detalle.numeroGrupo,
                                                            fechaVencimientoGrupo: detalle.fechaVencimientoGrupo,
                                                            proporcionGravadaIva: 100,
                                                            descuento: detalle.descuento,
                                                            totalItem: detalle.totalItem
                                                        };
                                                        vm.comprobantesProductos.push(newProducto);
                                                    }
                                                } else {
                                                    vm.comprobantesProductos.push({
                                                        montosImponibles: [{
                                                            tipoImpuesto: response.tiposImpuesto[0],
                                                            porcentaje: response.tiposImpuesto[0].porcentaje,
                                                            valor: 0
                                                        }],
                                                        producto: detalle.producto,
                                                        unidadMedida: detalle.unidadMedida,
                                                        cantidad: detalle.cantidadRecepcionada,
                                                        ultimoPrecio: detalle.ultimoPrecio,
                                                        tipo: getTipoProducto(vm.tiposDetalle),
                                                        numeroGrupo: detalle.numeroGrupo,
                                                        fechaVencimientoGrupo: detalle.fechaVencimientoGrupo,
                                                        proporcionGravadaIva: 100,
                                                        descuento: detalle.descuento,
                                                        totalItem: detalle.totalItem
                                                    });
                                                }
                                            });
                                        }
                                    // }
                                // });
                            });
                        }else{
                            _.forEach(response.data, function (detalle) {
                                _.forEach(recepcion.ordenCompra.ordenCompraDetalle, function (detalle1) {
                                  if(detalle1.producto != null){
                                    if (detalle1.producto.id == detalle.producto.id && detalle1.unidadMedida.id == detalle.unidadMedida.id) {
                                      detalle.ultimoPrecio = detalle1.ultimoPrecio;
                                      detalle.descuento = detalle1.porcentajeDescuento;
                                      detalle.totalItem = detalle1.totalItem;
                                      if (vm.comprobante.moneda1.codigo == "guaranies") {
                                          detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(0));
                                          detalle.totalItem = parseFloat(detalle.totalItem.toFixed(0));
                                      } else {
                                          detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(4));
                                          detalle.totalItem = parseFloat(detalle.totalItem.toFixed(0));
                                      }

                                      if (detalle.producto) {
                                          ProductosFactory.get(
                                              detalle.producto.id,
                                              "ProductoList"
                                          ).$promise.then(function (response) {
                                              // Solo para casos en los que la operación desde la que se recupera permite serie / lote != 1
                                              // Como recepción solo deja poner 1 simplemente se recupera

                                              //Aqui recupera detalles de los productos

                                              if (numeroLlamado < vm.numeroLlamado) {
                                                  return
                                              }

                                              if (detalle.producto.criterioControl.codigo === "series" && !vm.bloquearNroGrupo) {
                                                  for (var i = 0; i < detalle.cantidadRecepcionada; i++) {
                                                      var newProducto = {
                                                          montosImponibles: [{
                                                              tipoImpuesto: response.tiposImpuesto[0],
                                                              porcentaje: response.tiposImpuesto[0].porcentaje,
                                                              valor: 0
                                                          }],
                                                          producto: detalle.producto,
                                                          unidadMedida: detalle.unidadMedida,
                                                          cantidad: 1,
                                                          ultimoPrecio: detalle.ultimoPrecio,
                                                          tipo: getTipoProducto(vm.tiposDetalle),
                                                          numeroGrupo: detalle.numeroGrupo,
                                                          fechaVencimientoGrupo: detalle.fechaVencimientoGrupo,
                                                          proporcionGravadaIva: 100,
                                                          descuento: detalle.descuento,
                                                          totalItem: detalle.totalItem
                                                      };
                                                      vm.comprobantesProductos.push(newProducto);
                                                  }
                                              } else {
                                                  vm.comprobantesProductos.push({
                                                      montosImponibles: [{
                                                          tipoImpuesto: response.tiposImpuesto[0],
                                                          porcentaje: response.tiposImpuesto[0].porcentaje,
                                                          valor: 0
                                                      }],
                                                      producto: detalle.producto,
                                                      unidadMedida: detalle.unidadMedida,
                                                      cantidad: detalle.cantidadRecepcionada,
                                                      ultimoPrecio: detalle.ultimoPrecio,
                                                      tipo: getTipoProducto(vm.tiposDetalle),
                                                      numeroGrupo: detalle.numeroGrupo,
                                                      fechaVencimientoGrupo: detalle.fechaVencimientoGrupo,
                                                      proporcionGravadaIva: 100,
                                                      descuento: detalle.descuento,
                                                      totalItem: detalle.totalItem
                                                  });
                                              }
                                          });
                                      }
                                    }
                                  }

                                });
                            });
                        }

                    }
                });
            }
        }
    }

    function recuperarItemsRecepcion() {
        /* La idea de utilizar esta variable es: permitir modificar los detalles (cuando se resuelva la promesa), solo si
         no hay una peticion posterior, es decir, modificar solo si se trata de la ultima promesa.
         Con la variable 'numeroLlamado' se puede determinar si se trata o no de la ultima promesa*/

        vm.numeroLlamado++;
        recuperaAsinc(vm.numeroLlamado);
    }

    function getParamDiferenciaMonedaLocal() {
        vm.diferenciaMonedaLocal = ParametrosFactory.filterByCodigo("maximo_diferencia_cabecera_detalle_moneda_local");
    }

    function getParamDiferenciaMonedaExtranjera() {
        var params = {};
        params.search = filterFactory
            .single({
                path: "codigo",
                equals: "maximo_diferencia_cabecera_detalle_moneda_extranjera"
            })
            .value();
        vm.diferenciaMonedaExtranjera = ParametrosFactory.all(params);
    }

    function splitDetalles() {
        vm.comprobantesServicios = [];
        vm.comprobantesProductos = [];
        _.forEach(vm.comprobante.comprobanteDetalle, function (elem) {
            if (vm.comprobante.moneda1.codigo == "guaranies") {
                elem.ultimoPrecio = parseFloat(elem.ultimoPrecio.toFixed(0));
                elem.totalItem = parseFloat(elem.totalItem.toFixed(0));
            } else {
                elem.ultimoPrecio = parseFloat(elem.ultimoPrecio.toFixed(4));
                elem.totalItem = parseFloat(elem.totalItem.toFixed(4));
            }
            if (elem.tipo.codigo === "detalle_comprobante_servicio") {
                vm.comprobantesServicios.push(elem);
            } else {
                vm.comprobantesProductos.push(elem);
            }
        });
    }

    function searchSolicitudCompraFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return (
                item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.numero == criteria
            );
        };
    }

    $scope.$watch("vm.comprobante.grupoCompra", function (newValue, oldValue) {

        // Solo al editar, al cargar la pagina, es cuando no se debe especificar que no
        // es un cambio hecho por el usuario (false) y por ende no se deben borrar
        // otro campos, desde la vista siempre se envia true (on-select).
        if (newValue != undefined && oldValue == undefined && vm.isEdit == true) {
            changeGrupoCompra(false);
        }
    });


    function changeGrupoCompra(isChange) {

        if (isChange) {
            if (vm.comprobante.grupoCompra.codigo == "comprobante_importacion") {
                vm.valorFOBRequerido = true;
                delete vm.comprobante.montosImponibles;
                vm.comprobante.montoTotal = 0;
                vm.comprobante.deposito = undefined;
            } else if (!vm.isProcesoCompra && !vm.isEditAprob) {
                vm.comprobante.montosImponibles = [{}];
                vm.valorFOBRequerido = false;
                vm.comprobante.fob = 0;
                vm.comprobante.proveedor = undefined;
                vm.comprobante.ordenCompra = undefined;
                vm.comprobante.montoTotal = 0;
                vm.comprobante.deposito = undefined;
            } else if (!vm.isProcesoCompra && vm.isEditAprob) {
                _.forEach(vm.comprobante.comprobanteDetalle, function (detalle) {
                    if (detalle.producto == null) {
                        if (vm.comprobante.grupoCompra.permiteServicio == false) {
                            notify({ message: "El grupo seleccionado no permite servicios", classes: "alert-danger", position: "right" });
                            vm.comprobante.grupoCompra = null;
                            return;
                        }
                    }

                    if (detalle.servicio == null) {
                        if (vm.comprobante.grupoCompra.permiteProducto == false) {
                            notify({ message: "El grupo seleccionado no permite productos", classes: "alert-danger", position: "right" });
                            vm.comprobante.grupoCompra = null;
                            return;
                        }
                    }
                })

            } else if (vm.isProcesoCompra) {
                vm.comprobante.montosImponibles = [{}];
                vm.comprobante.montoTotal = 0;
                vm.comprobante.deposito = undefined;
            }
        }

        //var claseEq = "clase_almacen_producto_producto";
        // if (vm.comprobante.grupoCompra.clase && vm.comprobante.grupoCompra.clase.codigo == "clase_grupo_compra_producto_activo_fijo") {
        //     claseEq = "clase_almacen_producto_activo_fijo";
        //}
        if (!vm.isEditAprob) {
            var filterAlmacenes = {
                search: filterFactory
                    .and([{
                        path: "sucursal.id",
                        equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                    },
                    {
                        path: "clase.codigo",
                        equals: vm.comprobante.grupoCompra.clase.codigo
                    },
                    {
                        path: "activo",
                        equals: true
                    }
                    ])
                    .value()
            };

            vm.depositos = AlmacenFactory.all(filterAlmacenes);
        }

        if (!vm.comprobante.grupoCompra.permiteProducto) {
            vm.comprobantesProductos = [];
            if (vm.comprobante.comprobanteDetalle) {
                // nos quedamos solo con los detalles que tienen servicios
                vm.comprobante.comprobanteDetalle = _.filter(vm.comprobante.comprobanteDetalle, function (detalle) {
                    return detalle.servicio;
                });
            }

        }
        if (!vm.comprobante.grupoCompra.permiteServicio) {
            vm.comprobantesServicios = [];
            if (vm.comprobante.comprobanteDetalle) {
                // nos quedamos solo con los detalles que tienen productos
                vm.comprobante.comprobanteDetalle = _.filter(vm.comprobante.comprobanteDetalle, function (detalle) {
                    return detalle.producto;
                });
            }
        }

    }

    function changeOC() {

        vm.comprobante.proveedor = vm.comprobante.ordenCompra.proveedor;
        vm.comprobante.deposito =
            vm.comprobante.deposito || vm.comprobante.ordenCompra.almacen;
        changeProveedor();
        filterProductos();
        vm.comprobante.moneda1 = vm.comprobante.ordenCompra.moneda;

        changeCotizacion(true);
        getRecepcionesCurrentOC();
        vm.comprobante.solicitudCompra = vm.comprobante.ordenCompra.solicitudCompra;
        ParametrosFactory.filterByModulo("compras_locales").$promise.then(function (
            params
        ) {
            var param = _.find(params, function (parametro) {
                return parametro.codigo == "recuperar_items_oc";
            });

            if (!param) {
                return;
            }

            if (param.valorTipo.codigo == "si") {
                recuperarItemsOC();
            }
        });
        vm.comprobante.sucursalProveedor =
            vm.comprobante.ordenCompra.proveedorSucursal;
    }

    function changeAlmacen() {
        if (
            vm.comprobante.deposito && vm.comprobante.recepciones && vm.comprobante.recepciones[0] &&
            vm.comprobante.deposito.id != vm.comprobante.recepciones[0].almacen.id
        ) {
            var msg = $filter("translate")(
                "TODAS_RECEPCIONES_MISMO_ALMACEN_WARNING"
            );
            notify({ message: msg, classes: "alert-danger", position: "right" });
            vm.comprobante.recepciones = null;
            return;
        }
    }

    function changeRecepciones() {
        var newRecepcion =
            vm.comprobante.recepciones[vm.comprobante.recepciones.length - 1];

        ProveedoresFactory.get(newRecepcion.proveedor.id).$promise.then(function (
            response
        ) {
            if (
                vm.comprobante.proveedor &&
                vm.comprobante.proveedor.id != response.id
            ) {
                var msg = $filter("translate")(
                    "TODAS_RECEPCIONES_MISMO_PROVEEDOR_WARNING"
                );
                notify({ message: msg, classes: "alert-danger", position: "right" });
                vm.comprobante.recepciones.splice(
                    vm.comprobante.recepciones.length - 1,
                    1
                );
                return;
            }
            if (
                vm.comprobante.deposito &&
                vm.comprobante.deposito.id != newRecepcion.almacen.id
            ) {
                var msg = $filter("translate")(
                    "TODAS_RECEPCIONES_MISMO_ALMACEN_WARNING"
                );
                notify({ message: msg, classes: "alert-danger", position: "right" });
                vm.comprobante.recepciones.splice(
                    vm.comprobante.recepciones.length - 1,
                    1
                );
                return;
            }
            vm.comprobante.deposito = newRecepcion.almacen || vm.comprobante.deposito;
            if (!vm.comprobante.ordenCompra) {
                vm.comprobante.proveedor = response;
                changeProveedor(false);
            }
            filterProductos();
            //  changeCotizacion();
            //if (!vm.comprobante.ordenCompra) {
            recuperarItemsRecepcion();
            // }
        });
    }

    function getRecepcionesCurrentOC() {
        vm.recepciones = [];
        var staticFilter = {};
        var filters = [];

        filters = [{
            path: "ordenCompra.id",
            equals: vm.comprobante.ordenCompra.id
        },
        {
            path: "estadoRecepcion.codigo",
            equals: "er_aprobado"
        }
        ];

        if (vm.isProcesoCompra) {
            filters.push({
                path: "compra.id",
                equals: vm.compra.id
            });
        }

        staticFilter.search = filterFactory.and(filters).value();
        if (vm.watcherRecepcion) {
            vm.watcherRecepcion();
        }
        WatchLocacionChangeFactory.executeWatching(function (
            locacionFilter,
            watcherRecepcion
        ) {
            vm.watcherRecepcion = watcherRecepcion;
            vm.recepciones = RecepcionFactory.all(
                UtilFactory.createNestedFilters(
                    locacionFilter.search.filters,
                    staticFilter
                ),
                "RecepcionList"
            );
        }, "locacion.id");
    }

    function recuperarItemsOC() {
        CompraFactory.getSugerenciasOC(
            vm.comprobante.ordenCompra.id,
            "comp_tipo_compra_comprobante"
        ).then(function (response) {
            if (response.data && response.data.length > 0) {
                vm.comprobantesProductos = [];
                vm.comprobantesServicios = [];

                _.forEach(response.data, function (detalle) {
                    if (detalle.producto) {
                        ProductosFactory.get(
                            detalle.producto.id,
                            "ProductoList"
                        ).$promise.then(function (response) {
                            if (detalle.producto.criterioControl.codigo === "series" && !vm.bloquearNroGrupo) {
                                for (var i = 0; i < detalle.cantidad; i++) {
                                    var newProducto = {
                                        montosImponibles: [{
                                            tipoImpuesto: response.tiposImpuesto[0],
                                            porcentaje: response.tiposImpuesto[0].porcentaje,
                                            valor: 0
                                        }],
                                        producto: detalle.producto,
                                        unidadMedida: detalle.unidadMedida,
                                        descuento: detalle.porcentajeDescuento,
                                        cantidad: 1,
                                        ultimoPrecio: detalle.ultimoPrecio,
                                        tipo: getTipoProducto(vm.tiposDetalle)
                                    };
                                    vm.comprobantesProductos.push(newProducto);
                                    vm.calcularTotalDetalle(newProducto);
                                }
                            } else {
                                var newProducto = {
                                    montosImponibles: [{
                                        tipoImpuesto: response.tiposImpuesto[0],
                                        porcentaje: response.tiposImpuesto[0].porcentaje,
                                        valor: 0
                                    }],
                                    producto: detalle.producto,
                                    unidadMedida: detalle.unidadMedida,
                                    cantidad: detalle.cantidad,
                                    descuento: detalle.porcentajeDescuento,
                                    ultimoPrecio: detalle.ultimoPrecio,
                                    tipo: getTipoProducto(vm.tiposDetalle)
                                };
                                vm.comprobantesProductos.push(newProducto);
                                vm.calcularTotalDetalle(newProducto);
                            }

                        });
                    } else if (detalle.servicio) {
                        ServicioFactory.get(
                            detalle.servicio.id,
                            "ServicioList"
                        ).$promise.then(function (response) {
                            var newServicio = {
                                montosImponibles: [{
                                    tipoImpuesto: response.tipoImpuesto,
                                    porcentaje: response.tipoImpuesto.porcentaje,
                                    valor: 0
                                }],
                                servicio: detalle.servicio,
                                cantidad: detalle.cantidad,
                                tipo: vm.tiposDetalle[1],
                                ultimoPrecio: detalle.ultimoPrecio,
                                descuento: detalle.porcentajeDescuento
                            };
                            vm.comprobantesServicios.push(newServicio);
                            vm.calcularTotalDetalle(newServicio);
                        });
                    }
                });
            }
        });
    }

    function mostrarTodosProductos() {

        if (vm.todosProductos) {
            vm.opcionesProductosDt.staticFilter = null;
            if (vm.watcher) {
                vm.watcher();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcher = watcher;
                vm.staticFilter = [];

                if (!vm.fromOutside) {
                    var filterStock = {
                        path: "controlStock",
                        like: "true"
                    };
                    vm.staticFilter.push(filterStock);
                }

                if(vm.fromOutside && vm.comprobante.cargaImportacion){
                    var filterStock = {
                        path: "controlStock",
                        like: "true"
                    };
                    vm.staticFilter.push(filterStock);
                }

                vm.staticFilter.push(locacionFilter.search.filters[0]);
                if (vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo == "autofactura") {
                    var tipoImpuestoFilter = {
                        path: 'tiposImpuesto.porcentaje',
                        equals: 0
                    }
                    vm.staticFilter.push(tipoImpuestoFilter);
                }
                ;
                if (
                    vm.fromOutside &&
                    vm.comprobante &&
                    vm.comprobante.tipo
                ) {
                    if (vm.comprobante.tipo.clase.codigo != "nota_credito" && !vm.comprobante.cargaImportacion) {
                        var stockFilter = {
                            path: "controlStock",
                            like: "false"
                        };
                        vm.staticFilter.push(stockFilter);
                    } else {
                        var stockFilter = {
                            path: "controlStock",
                            like: "true"
                        };
                        vm.staticFilter.push(stockFilter);
                    }
                }

                // Se agrega filtro de estado
                var estadoFilter = {
                    path: "estado.codigo",
                    equals: "activo"
                };
                vm.staticFilter.push(estadoFilter);

                var locacionFilter = {
                  path: "locaciones.id",
                  equals: $rootScope.AuthParams.locacionSeleccionada.id
                };
                vm.staticFilter.push(locacionFilter);

                var filtros = {
                    search: filterFactory.and(vm.staticFilter).value()
                };
                console.log("filtros ",filtros)
                // vm.productos = ProductosFactory.allForSelect(filtros);
                vm.opcionesProductosDt.staticFilter = filtros;
            }, "locaciones.id");
        } else {
            filterProductos();
        }
    }

    function filterServicios() {
        var filtroServiciosDt = {};
        if (vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo == "autofactura") {
            filtroServiciosDt.search = filterFactory
                .and([{
                    path: 'compra',
                    like: 'true'
                },
                {
                    path: 'tipoImpuesto.porcentaje',
                    equals: '0'
                }])
                .value();
        } else {
            filtroServiciosDt.search = filterFactory
                .and([{
                    path: 'compra',
                    like: 'true'
                }])
                .value();
        }
        vm.opcionesServiciosDt.staticFilter = filtroServiciosDt;
    }

    function filterProductos() {
        vm.staticFilter = [];

        if (vm.comprobante.proveedor) {
            vm.staticFilter.push(
                {
                    path: "proveedores.codigo",
                    equals: vm.comprobante.proveedor.codigo
                }
            )
        }

        vm.staticFilter.push(
            {
                path: "itemCompra",
                like: "true"
            }, {
            path: "estado.codigo",
            equals: "activo"
        }
        )

        if (!vm.fromOutside) {
            var filterStock = {
                path: "controlStock",
                like: "true"
            };
            vm.staticFilter.push(filterStock);
        }

        if (vm.fromOutside && vm.comprobante.cargaImportacion) {
            var filterStock = {
                path: "controlStock",
                like: "true"
            };
            vm.staticFilter.push(filterStock);
        }

        if (vm.watcher) {
            vm.watcher();
        }
        WatchLocacionChangeFactory.executeWatching(function (
            locacionFilter,
            watcher
        ) {
            vm.watcher = watcher;
            if (
                vm.fromOutside &&
                vm.comprobante &&
                vm.comprobante.tipo
            ) {
                if (vm.comprobante.tipo.clase.codigo != "nota_credito" && !vm.comprobante.cargaImportacion) {
                    var stockFilter = {
                        path: "controlStock",
                        like: "false"
                    };
                    vm.staticFilter.push(stockFilter);
                } else {
                    var stockFilter = {
                        path: "controlStock",
                        like: "true"
                    };
                    vm.staticFilter.push(stockFilter);
                }
            }

            /* Cuando estamos cargando una autofactura no se deben recuperar productos gravados */
            if (vm.comprobante.tipo &&
                vm.comprobante.tipo.clase.codigo == "autofactura") {

                var filterImpuesto = {
                    path: 'tiposImpuesto.porcentaje',
                    equals: 0
                };
                vm.staticFilter.push(filterImpuesto);
            }

            vm.finalFilters = {
                search: filterFactory.and(vm.staticFilter).value()
            };

            var filteredStructure = UtilFactory.createNestedFilters(
                locacionFilter.search.filters,
                vm.finalFilters,
                filterImpuesto
            );
            // vm.productos = ProductosFactory.allForSelect(filteredStructure);
            vm.opcionesProductosDt.staticFilter = filteredStructure;
            console.log("filtro aca entonces ", filteredStructure)

        }, "locaciones.id");
    }


    function delayRequestProducto(searchValue, $event) {
        vm.productos = undefined;
        realizarBusqueda(searchValue);
    }

    function realizarBusqueda(searchValue){
        var userInputFilter = {};
        if (searchValue.length >= 3 && (vm.todosProductos || vm.comprobante.proveedor)) {

            var filterNombre = vm.staticFilter.slice()
            var filterCodigoBarras = vm.staticFilter.slice()
            var filterCodigoInterno = vm.staticFilter.slice()

            filterNombre.push({
                path: "nombre",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                },
                {
                path: "locaciones.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.id
                })

            filterNombre.push({
                path: "itemCompra",
                equals: true
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                })

            filterCodigoBarras.push({
                path: "codigoBarrasPrincipal.codigoBarras",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                },
                {
                path: "locaciones.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.id
                })

            filterCodigoInterno.push({
                path: "codigo",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                },
                {
                path: "locaciones.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.id
                })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }

            recuperarProducto(userInputFilter)
        }
    }

    function recuperarProducto(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)

        vm.productos = ProductosFactory.allForSelect(userInputFilter)
    }
    function agregarDetalle() {
        vm.comprobante.comprobanteDetalle.push({
            montosImponibles: [{}],
            tipo: getTipoServicio(vm.tiposDetalle)
        });
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function agregarDetalleProducto() {
        if (vm.comprobante.tipo == undefined || vm.comprobante.tipo == null) {
            var msg = $filter("translate")(
                "TIPO_COMPROBANTE_NO_DEFINIDO"
            );
            notify({ message: msg, classes: "alert-danger", position: "right" });
            return;
        }
        vm.comprobantesProductos.push({
            montosImponibles: [{}],
            tipo: getTipoProducto(vm.tiposDetalle),
            proporcionGravadaIva: 100
        });
        // Desplazar el foco al final de la página
        var endOfPageElement = document.getElementById('endOfPage');
        if (endOfPageElement) {
            endOfPageElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 5);
    }

    function agregarDetalleServicio() {
        vm.comprobantesServicios.push({
            montosImponibles: [{}],
            tipo: vm.tiposDetalle[1]
        });
        // Desplazar el foco al final de la página
        var endOfPageElement = document.getElementById('endOfPage');
        if (endOfPageElement) {
            endOfPageElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 5);
    }

    function agregarMontoImponible() {
        vm.comprobante.montosImponibles.push({ esTotal: true });
        $timeout(function () {
            $scope.$broadcast("newImpuestoAdded");
        }, 20);
    }

    function changeTipo(detalle) {
        if (detalle.tipo.codigo == "detalle_comprobante_producto") {
            detalle.servicio = null;
            detalle.cantidad = 0;
            detalle.ultimoPrecio = 0;
            detalle.montosImponibles[0].tipoImpuesto = {};
            detalle.montosImponibles[0].valor = 0;
            detalle.montosImponibles[0].valorExento = 0;
            detalle.totalItem = 0;
        } else if (detalle.tipo.codigo == "detalle_comprobante_servicio") {
            detalle.producto = null;
            detalle.cantidad = 0;
            detalle.ultimoPrecio = 0;
            detalle.montosImponibles[0].tipoImpuesto = {};
            detalle.montosImponibles[0].valor = 0;
            detalle.totalItem = 0;
        }
    }

    function changeSucursalProveedor(isNew) {
        ProveedorSucursalesFactory.get(
            vm.comprobante.sucursalProveedor.id,
            "BaseList"
        ).$promise.then(function (sucursal) {
            vm.sucursalProveedor = sucursal;

        })
        if (vm.comprobante.tipo && vm.requerirTimbrado) {
            filterTimbrado();
        }
        if (isNew === undefined) {
            vm.comprobante.timbradoProveedor = null;
            vm.comprobante.numero = null;
        }
    }

    function changeProveedor(isNew) {
        if (isNew === undefined || isNew == true) {
            vm.comprobante.timbradoProveedor = null;
            vm.comprobante.sucursalProveedor = null;
        }

        if (vm.comprobante.proveedor) {
            var staticFilter = {};
            staticFilter.search = filterFactory
                .and([{
                    path: "proveedor.id",
                    equals: vm.comprobante.proveedor.id
                }])
                .value();

            ProveedorSucursalesFactory.all(staticFilter).$promise.then(function (sucursales) {
                if (sucursales.length == 1) {
                    vm.comprobante.sucursalProveedor = sucursales[0];
                    changeSucursalProveedor(false);
                }
                vm.sucursalesProveedor = sucursales;
                if (vm.comprobante.tipo && vm.requerirTimbrado) {
                    filterTimbrado(isNew);
                }
            });

            if (isNew == true) {
                vm.comprobante.sucursalProveedor = null;
            }
        }
        mostrarTodosProductos();
    }

    function filterTimbrado(isNew) {

        var filtersArray = [];

        if (vm.comprobante.proveedor) {
            filtersArray.push({
                path: "proveedor.id",
                equals: vm.comprobante.proveedor.id
            });
        }
        if (vm.comprobante.fecha) {
            filtersArray.push({
                path: "fechaDesde",
                equalOrBefore: moment(vm.comprobante.fecha).format("DD/MM/YYYY")
            });

            filtersArray.push({
                path: "fechaHasta",
                equalOrAfter: moment(vm.comprobante.fecha).format("DD/MM/YYYY")
            });
        }
        if (vm.comprobante.sucursalProveedor) {
            filtersArray.push({
                path: "sucursalProveedor.id",
                equals: vm.comprobante.sucursalProveedor.id
            });
        }

        // Filtro de estado
        filtersArray.push({
            path: "activo",
            equals: true
        });


        var filters = { search: filterFactory.and(filtersArray).value() };
        filters.view = "BaseList"
        vm.timbrados = TimbradoProveedorFactory.all(filters);


        vm.timbrados.$promise.then(function (response) {

            if (
                vm.requerirTimbrado &&
                !response.length
            ) {
                if (savedTime === undefined || ((new Date() - savedTime) / 1000) > 12) {
                    var msg = $filter("translate")("NO_TIMBRADO_PROVEEDOR_FOUND");
                    notify({ message: msg, classes: "alert-danger", position: "right" });
                    savedTime = new Date();
                }
            }
        });

        vm.comprobante.numeroTimbrado = undefined;
        if (isNew) {
            vm.comprobante.timbradoProveedor = null;
            vm.comprobante.numero = null;
        }
    }

    function checkProveedor() {

        if ((vm.comprobante.proveedor == undefined) && !vm.todosProductos) {
            vm.productos = null;
            var msg = $filter("translate")("FILTRO_SELECCIONAR_PROVEEDOR");
            notify({ message: msg, classes: "alert-danger", position: "right" });
            return
        }
    }

    function changeProducto(detalle) {
        detalle.servicio = null;
        detalle.cantidad = 0;
        detalle.ultimoPrecio = 0;
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        detalle.numeroGrupo = null;
        detalle.descuento = 0;
        detalle.fechaVencimientoGrupo = null;

        // Si tiene OC, no cambiar precio (ya recupera de OC)
        if (!vm.comprobante.ordenCompra) {

            // Recupera ultimo precio
            OrdenesCompraDetalleFactory.getUltimosCosto2(detalle.producto.id, vm.comprobante.moneda1.codigo).then(function (r) {
                if (r.data != null) {
                    detalle.ultimoPrecio = r.data.valor;
                    if (vm.comprobante.moneda1.codigo == "guaranies") {
                        detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(0));
                    } else {
                        detalle.ultimoPrecio = parseFloat(detalle.ultimoPrecio.toFixed(4));
                    }
                    if (detalle.producto.criterioControl &&
                        detalle.producto.criterioControl.codigo === "series"
                    ) {
                        detalle.cantidad = 1;
                        detalle.totalItem = 1 * detalle.ultimoPrecio;
                        vm.totalGeneralProductos += detalle.totalItem;

                        _.forEach(vm.comprobantesProductos, function (detalle) {
                            calcularTotalDetalle(detalle)
                        });
                    }
                }
                if (
                    detalle.producto.criterioControl &&
                    detalle.producto.criterioControl.codigo === "series"
                ) {
                    detalle.cantidad = 1;
                }

                detalle.totalItem = detalle.ultimoPrecio * detalle.cantidad;
            });
        } else {
            if (
                detalle.producto.criterioControl &&
                detalle.producto.criterioControl.codigo === "series"
            ) {
                detalle.cantidad = 1;
            }
        }

        if (!vm.comprobante.grupoCompra ||
            vm.comprobante.grupoCompra.codigo != "comprobante_importacion"
        ) {
            detalle.montosImponibles[0].tipoImpuesto =
                detalle.producto.tiposImpuesto[0];
            detalle.montosImponibles[0].porcentaje =
                detalle.producto.tiposImpuesto[0].porcentaje;
            detalle.montosImponibles[0].valor = 0;
            detalle.montosImponibles[0].valorExento = 0;
        }
        detalle.totalItem = 0;
        // Desplazar el foco al final de la página
        var endOfPageElement = document.getElementById('endOfPage');
        if (endOfPageElement) {
            endOfPageElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    }

    function changeServicio(detalle) {
        detalle.producto = null;
        detalle.cantidad = 1;
        detalle.ultimoPrecio = 0;
        detalle.montosImponibles[0].tipoImpuesto = detalle.servicio.tipoImpuesto;
        detalle.montosImponibles[0].porcentaje =
            detalle.servicio.tipoImpuesto.porcentaje;
        detalle.montosImponibles[0].valor = 0;
        detalle.totalItem = 0;

        if (detalle.servicio.controlarcentrocostos == true) {
            detalle.sucursalServicio = $rootScope.AuthParams.locacionSeleccionada.sucursal;
        } else {
            detalle.sucursalServicio = null;
        }
        // Desplazar el foco al final de la página
        var endOfPageElement = document.getElementById('endOfPage');
        if (endOfPageElement) {
            endOfPageElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    }

    function changeCotizacion(isNew) {

        if (vm.comprobante.moneda1 && vm.comprobante.moneda1.codigo == "guaranies") {
            vm.cantDigitos = 0;
        } else {
            vm.cantDigitos = 2;
        }

        _.forEach(vm.comprobantesProductos, function (detalle) {
            calcularTotalDetalle(detalle)
        });

        _.forEach(vm.comprobantesServicios, function (detalle) {
            calcularTotalDetalle(detalle)
        });

        vm.monedaLocal.$promise.then(function (data) {
            if (
                vm.comprobante.moneda1 &&
                vm.comprobante.moneda1.descripcion != data[0].valorTipo.descripcion
            ) {
                vm.cotizacionRequerida = true;

                var params = {};
                params.search = filterFactory
                    .or([
                        filterFactory
                            .and([{
                                path: "monedaDesde.id",
                                equals: vm.comprobante.moneda1.id
                            },
                            {
                                path: "monedaHasta.id",
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: "fecha",
                                like: moment(vm.comprobante.fecha).format("DD/MM/YYYY")
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "monedaDesde.id",
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: "monedaHasta.id",
                                equals: vm.comprobante.moneda1.id
                            },
                            {
                                path: "fecha",
                                like: moment(vm.comprobante.fecha).format("DD/MM/YYYY")
                            }
                            ])
                            .value()
                    ])
                    .value();
                CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                    if (response.length > 0) {
                        vm.comprobante.cambio = response[0].valorVenta;
                    } else {
                        if (
                            vm.comprobante.ordenCompra != null &&
                            vm.comprobante.ordenCompra != undefined
                        ) {
                            vm.comprobante.cambio = vm.comprobante.cambio || vm.comprobante.ordenCompra.tipoCambio;

                        } else {
                            vm.comprobante.cambio = null;
                        }
                    }
                });
            } else {
                vm.cotizacionRequerida = false;
                vm.comprobante.cambio = 1;
            }
        });
        filterTimbrado(isNew);
        updateCabeceraMontosImponibles();
    }

    function updateCabeceraMontosImponibles() {
        // TODO verificar
        _.forEach(vm.comprobante.montosImponibles, function (montoImponible) {
            totalImpuestoChanged(montoImponible);
        });
    }

    function searchProductoFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.codigo == criteria ||
                (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0
            );
        };
    }

    function calcularTotalDetalle(detalle) {

        if (detalle.cantidad == undefined) {
            detalle.cantidad = 0;
            detalle.totalItem = 0;
        }

        if (detalle.ultimoPrecio == undefined) {
            detalle.ultimoPrecio = 0;
            detalle.totalItem = 0;
        }

        if (detalle.descuento == undefined) {
            detalle.descuento = 0
        }

        if (detalle.proporcionGravadaIva == undefined) {
            detalle.proporcionGravadaIva = 100
        }

        var totalNeto

        if (detalle.descuento && detalle.descuento > 0) {
            totalNeto = detalle.ultimoPrecio - (detalle.ultimoPrecio * (detalle.descuento / 100))
        } else {
            totalNeto = detalle.ultimoPrecio;
        }

        detalle.totalItem = detalle.cantidad * totalNeto;

        if (vm.comprobante.moneda1.codigo === "guaranies") {
            detalle.totalItem = Math.round(detalle.totalItem);
        } else {
            detalle.totalItem = Math.round(detalle.totalItem * 10000) / 10000;
        }

        /* Calculo de base imponible por línea */
        if (detalle.montosImponibles[0].porcentaje && detalle.montosImponibles[0].porcentaje != 0) {
            if (vm.comprobante.moneda1.codigo == "guaranies") {
                detalle.montosImponibles[0].baseImponible = (detalle.totalItem) / (100 + detalle.montosImponibles[0].porcentaje) * 100 * (detalle.proporcionGravadaIva ? ((detalle.proporcionGravadaIva) / 100) : 1);
            } else {
                detalle.montosImponibles[0].baseImponible = (detalle.totalItem) / (100 + detalle.montosImponibles[0].porcentaje) * 100 * (detalle.proporcionGravadaIva ? ((detalle.proporcionGravadaIva) / 100) : 1);
            }
        } else {
            detalle.montosImponibles[0].baseImponible = detalle.totalItem;
        }

        if (vm.comprobante.moneda1.codigo == "guaranies") {
            detalle.montosImponibles[0].valor = detalle.montosImponibles[0].baseImponible * detalle.montosImponibles[0].porcentaje / 100;
            // detalle.montosImponibles[0].baseImponible = parseFloat(detalle.montosImponibles[0].baseImponible.toFixed(8));
            detalle.montosImponibles[0].valor = detalle.totalItem - detalle.montosImponibles[0].baseImponible;
            detalle.montosImponibles[0].valorExento = detalle.totalItem - (detalle.montosImponibles[0].baseImponible + detalle.montosImponibles[0].valor);
        } else {
            detalle.montosImponibles[0].baseImponible = detalle.montosImponibles[0].baseImponible;
            detalle.montosImponibles[0].valor = detalle.montosImponibles[0].baseImponible * detalle.montosImponibles[0].porcentaje / 100;
            detalle.montosImponibles[0].valorExento = detalle.totalItem - parseFloat((detalle.montosImponibles[0].baseImponible + detalle.montosImponibles[0].valor).toFixed(8));
        }

        vm.totalGeneralProductos =
            _.reduce(
                vm.comprobantesProductos,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
        vm.totalGeneralServicios =
            _.reduce(
                vm.comprobantesServicios,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );

        if (vm.comprobante.moneda1.codigo == "guaranies") {
            vm.totalGeneralProductos = Math.round(vm.totalGeneralProductos);
            vm.totalGeneralServicios = Math.round(vm.totalGeneralServicios);
        } else {
            vm.totalGeneralProductos = Math.round(vm.totalGeneralProductos * 10000) / 10000;
            vm.totalGeneralServicios = Math.round(vm.totalGeneralServicios * 10000) / 10000;
        }

    }

    function totalItemChanged(detalle) {
        if (detalle.cantidad == undefined) {
            detalle.cantidad = 1;
        }

        detalle.ultimoPrecio = detalle.totalItem / detalle.cantidad;
        if (vm.comprobante.moneda1.codigo == "guaranies") {
            detalle.ultimoPrecio = Math.round(detalle.ultimoPrecio);
        } else {
            detalle.ultimoPrecio = Math.round(detalle.ultimoPrecio * 10000) / 10000;
        }

        if (detalle.montosImponibles) {
            if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                if (vm.comprobante.moneda1.codigo == "guaranies") {
                    detalle.montosImponibles[0].baseImponible = Math.round((100 * detalle.totalItem) / (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje));
                } else {
                    detalle.montosImponibles[0].baseImponible = (100 * detalle.totalItem) / (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje);
                }
            } else {
                detalle.montosImponibles[0].baseImponible = detalle.totalItem;
            }
            detalle.montosImponibles[0].baseImponible = parseFloat(
                detalle.montosImponibles[0].baseImponible.toFixed(4)
            );
            detalle.montosImponibles[0].valor =
                detalle.totalItem - detalle.montosImponibles[0].baseImponible;
        }
        vm.totalGeneralProductos =
            _.reduce(
                vm.comprobantesProductos,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
        vm.totalGeneralServicios =
            _.reduce(
                vm.comprobantesServicios,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
        if (vm.comprobante.moneda1.codigo == "guaranies") {
            vm.totalGeneralProductos = Math.round(vm.totalGeneralProductos);
            vm.totalGeneralServicios = Math.round(vm.totalGeneralServicios);
        } else {
            vm.totalGeneralProductos = Math.round(vm.totalGeneralProductos * 10000) / 10000;
            vm.totalGeneralServicios = Math.round(vm.totalGeneralServicios * 10000) / 10000;
        }

    }

    function totalImpuestoChanged(montoImponible) {

        if (vm.comprobante.moneda1.codigo == "guaranies") {
            if (montoImponible.total) {
                montoImponible.total = parseFloat(montoImponible.total.toFixed(0));
            }
        }

        checkIfImpuestoSet(montoImponible);
        updateTotalImpuestos();
    }

    function tipoImpuestoChanged(montoImponible) {
        checkIfImpuestoSet(montoImponible);
        updateTotalImpuestos();
    }

    function checkIfImpuestoSet(montoImponible) {
        if (!montoImponible.tipoImpuesto) {
            delete montoImponible.valor;
            delete montoImponible.total;
            delete montoImponible.baseImponible;
            return false;
        }

        var porc = montoImponible.tipoImpuesto.porcentaje;
        if (porc != 0) {
                montoImponible.baseImponible = Math.round((100 * montoImponible.total) / (100 + montoImponible.tipoImpuesto.porcentaje) * 100000000) / 100000000;
        } else {
            montoImponible.baseImponible = montoImponible.total;
        }

        if (montoImponible.baseImponible) {
            montoImponible.baseImponible = Math.round(montoImponible.baseImponible * 100000000) / 100000000;
            montoImponible.valor = Math.round((montoImponible.total - montoImponible.baseImponible) * 100000000) / 100000000;
        }

        return true;
    }

    function updateTotalImpuestos() {
        vm.totalImpuestosGeneral = _.reduce(
            vm.comprobante.montosImponibles,
            function (sum, monto) {
                return sum + monto.total;
            },
            0
        );
        vm.totalImpuestosGeneral = parseFloat(vm.totalImpuestosGeneral.toFixed(4));
        vm.comprobante.montoTotal = vm.totalImpuestosGeneral;
    }

    function requireDeposito() {

        if (vm.comprobante && vm.comprobante.grupoCompra &&
            !vm.comprobante.grupoCompra.permiteProducto &&
            vm.comprobante.grupoCompra.permiteServicio) {
            return false;
        }

        if (
            vm.fromOutside &&
            vm.comprobante &&
            vm.comprobante.tipo &&
            vm.comprobante.tipo.clase.codigo == "nota_credito"
        ) {
            return true;
        } else if (vm.fromOutside) {
            return false;
        }
        if (vm.comprobante && vm.comprobante.grupoCompra) {
            if (
                vm.comprobante.grupoCompra.permiteServicio === true &&
                vm.comprobante.grupoCompra.permiteProducto === false
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    function validarNroFactura() {
        if (vm.comprobante.tipo != undefined && vm.comprobante.tipo.clase.codigo === "retencion_absorbida") return;
        if (!vm.requerirTimbrado) vm.comprobante.timbradoProveedor = null;
        if (vm.comprobante.ordenCompra) {
            // Si tiene OC se debe recuperar la sucursal completa (detail), de lo contrario enviar como siempre vm.sucursalProveedor
            ProveedorSucursalesFactory.get(vm.comprobante.sucursalProveedor.id, "BaseList").$promise.then(function (sucursalOC) {
                vm.comprobante = ComprobanteFactory.validateNroFactura($scope.ComprobanteForm, vm.comprobante, vm.monedaLocal[0], vm.formatoComprobante, sucursalOC);
            });
        } else {
            vm.comprobante = ComprobanteFactory.validateNroFactura($scope.ComprobanteForm, vm.comprobante, vm.monedaLocal[0], vm.formatoComprobante, vm.sucursalProveedor);
        }
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        updateTotalImpuestos();
        vm.totalGeneralProductos = _.reduce(
            vm.comprobantesProductos,
            function (sum, detalle) {
                return sum + detalle.totalItem;
            },
            0
        );
        vm.totalGeneralServicios = _.reduce(
            vm.comprobantesServicios,
            function (sum, detalle) {
                return sum + detalle.totalItem;
            },
            0
        );
    }

    function evaluatePeriodoAdministrativo(fecha) {
        return $q(function (resolve, reject) {
            var params = {};
            params.search = filterFactory
                .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                }
                ])
                .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {

                if (responsePeriodo.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        });
    }

    function submit() {

        //  debugger;

        vm.submited = true;

        if (vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo === "nota_credito") {
            var prueba = _.filter(
                vm.comprobantesProductos,
                function (elem) {
                    return elem.producto.claseProducto.tipoAdministracion.codigo === "bobinas";
                }
            );
            if (prueba.length !== 0) {
                var msg = $filter('translate')('ERROR_NOTA_DE_CREDICO_BOBINA');
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                return;
            }

        }
        vm.comprobante.comprobanteDetalle = _.filter(
            vm.comprobante.comprobanteDetalle,
            function (elem) {
                return elem.producto || elem.servicio;
            }
        );

        /* Comprobacion de 'Almacen requerido' */
        vm.tieneProducto = false;
        _.forEach(vm.comprobante.comprobanteDetalle, function (detalle) {
            detalle.proporcionGravadaIva = parseFloat(detalle.proporcionGravadaIva, 2)
            if (detalle.producto && detalle.producto.id) {
                vm.tieneProducto = true;
                return;
            }
        });
        if ($scope.ComprobanteForm.$valid) {

            if (vm.comprobante.imputar === true) {
                if (vm.comprobante.imputarIvaGeneral != true && vm.comprobante.imputarIre != true &&
                    vm.comprobante.imputarIrpRsp != true) {

                    var msg = $filter('translate')('ERROR_NO_IMPUTA');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    return;
                }
            }

            ParametrosFactory.getByCodigo("controlar_vto_minimo_lote").then(function (response) {
                //Math.ceil(diferencia / (1000 * 3600 * 24))
                var diferencia, diasDif;
                var lote = "";
                for (var i = 0; i < vm.comprobantesProductos.length; i++) {
                    if (vm.comprobantesProductos[i].fechaVencimientoGrupo) {
                        diferencia = vm.comprobantesProductos[i].fechaVencimientoGrupo.getTime() - vm.comprobante.fecha.getTime();
                        diasDif = Math.ceil(diferencia / (1000 * 3600 * 24))
                        if (diasDif < response.valor) {
                            if (!lote) {
                                lote = vm.comprobantesProductos[i].numeroGrupo;
                            } else {
                                lote += ", " + vm.comprobantesProductos[i].numeroGrupo;
                            }
                        }
                    }
                }
                if (lote) {
                    $rootScope.isProcessing = false;
                    vm.showSpinner = false;
                    var msg = "La fecha de vencimiento del Lote [" + lote + "] es menor a lo permitido. (" + response.valor + " días)"
                    notify({ message: msg, classes: "alert-danger", position: "right" });
                    return;
                } else {
                    evaluatePeriodoAdministrativo(vm.comprobante.fecha).then(function (response) {
                        if (response) {

                            if (
                                vm.comprobante.grupoCompra &&
                                vm.comprobante.grupoCompra.codigo === "comprobante_importacion"
                            ) {
                                delete vm.comprobante.moneda1;
                            }

                            if (vm.montoInicial > vm.comprobante.montoTotal) {
                                var msg = $filter('translate')('MONTO_INICIAL_MAYOR_MONTO_TOTAL');
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                return;
                            }

                            copiarComprobantes();


                            /* Comprobacion de 'Precio' mayor a cero */

                            var msg = $filter("translate")("PRECIO_ERROR");
                            var precioMenorError = false;
                            for (var i = 0; i < vm.comprobante.comprobanteDetalle.length; i++) {

                                if (vm.comprobante.comprobanteDetalle[i].ultimoPrecio <= 0) {
                                    if (precioMenorError) {
                                        msg = msg + ",";
                                    }
                                    precioMenorError = true;
                                }
                            }

                            if (precioMenorError) {
                                msg = msg + ".";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                return;
                            }

                            vm.comprobante.comprobanteDetalle = _.filter(
                                vm.comprobante.comprobanteDetalle,
                                function (elem) {
                                    return elem.producto || elem.servicio;
                                }
                            );
                            // se quita el atributo grupo de los detalles cuyos productos son de tipo normal
                            vm.comprobante.comprobanteDetalle = _.forEach(
                                vm.comprobante.comprobanteDetalle,
                                function (elem) {
                                    if (elem.producto) {
                                        if (elem.producto.criterioControl.codigo !== "series" &&
                                            elem.producto.criterioControl.codigo !== "lotes") {
                                            delete elem.grupo;
                                        } else if (vm.comprobante.tipo.clase.codigo == "nota_credito") {
                                            elem.numeroGrupo = elem.grupoProductoSelect.numero;
                                        }
                                    }
                                }
                            );
                            var checkImpuestos = {};
                            var tieneImpuestoRepetido = false;
                            for (var i = 0; i < vm.comprobante.montosImponibles.length; i++) {
                                if (
                                    checkImpuestos[
                                    vm.comprobante.montosImponibles[i].tipoImpuesto.porcentaje
                                    ] === undefined
                                ) {
                                    checkImpuestos[
                                        vm.comprobante.montosImponibles[i].tipoImpuesto.porcentaje
                                    ] = true;
                                } else {
                                    tieneImpuestoRepetido = true;
                                }
                            }
                            if (tieneImpuestoRepetido) {
                                $rootScope.isProcessing = false;
                                var msg = $filter("translate")("IMPUESTO_CABECERA_REPETIDO");
                                notify({ message: msg, classes: "alert-danger", position: "right" });
                            } else {
                                var validationMontos = [];
                                if (vm.comprobante.tipo.codigo != 'DE') {
                                    validationMontos = ComprobanteFactory.validateMontos(
                                        vm.comprobante,
                                        vm.monedaLocal[0],
                                        vm.diferenciaMonedaLocal[0],
                                        vm.diferenciaMonedaExtranjera[0]
                                    );
                                } else {
                                    validationMontos[0] = true;
                                }
                                if (validationMontos[0]) {
                                    if (vm.comprobante.tipo.codigo != 'DE' && !vm.isEditAprob) {
                                        vm.comprobante = ComprobanteFactory.igualarTipoImpuestoCabeceraADetealle(
                                            vm.comprobante
                                        );
                                    }

                                    sendSubmitComprobante();
                                } else {
                                    var code = validationMontos[1];
                                    if (code == "montos_mismatch") {
                                        notify({
                                            message: $filter("translate")(
                                                "MONTOS_TOTALES_SUM_DETALLES_WARNING_COMPRA_LOCAL"
                                            ),
                                            classes: "alert-danger",
                                            position: "right"
                                        });
                                    } else if (code == "impuestos_mismatch") {
                                        notify({
                                            message: $filter("translate")(
                                                "IMPUESTOS_TOTALES_SUM_DETALLES_WARNING_COMPRA_LOCAL"
                                            ),
                                            classes: "alert-danger",
                                            position: "right"
                                        });
                                    }
                                }
                            }
                        }else {
                            var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        }
                    });
                }
            });
        }
    }

    function sendSubmitComprobante() {
        return submitComprobante().then(
            function (response) {
                if (vm.fromOutside) {
                    $state.go("app.consultacomprobantes.list");
                } else {
                    $state.go("app.compraslocales.proceso.comprobante.list");
                }
                return response;
            },
            function (error) {
                errorHandler(error);
            }
        );
    }

    function copiarComprobantes() {
        vm.comprobante.comprobanteDetalle = [];
        _.forEach(vm.comprobantesProductos, function (elem) {
            if ((elem.totalItem == null) || (elem.totalItem == undefined)) {
                return;
            }
            vm.comprobante.comprobanteDetalle.push(elem);
        });
        _.forEach(vm.comprobantesServicios, function (elem) {
            if ((elem.totalItem == null) || (elem.totalItem == undefined)) {
                return;
            }
            vm.comprobante.comprobanteDetalle.push(elem);
        });
    }

    function submitComprobante() {
        delete vm.comprobante.numeroTimbrado;
        var trimmedModel = ModelTrimmer.trimDetails(vm.comprobante, [
            "comprobanteDetalle",
            "montosImponibles"
        ]);
        var trimmedDetails = _.map(vm.comprobante.comprobanteDetalle, function (
            detalle
        ) {
            var result = ModelTrimmer.trimDetails(detalle, ["montosImponibles"]);
            var montosImponiblesTrimmed = _.map(result.montosImponibles, function (
                monto
            ) {
                return ModelTrimmer.trimDetails(monto);
            });
            result.montosImponibles = montosImponiblesTrimmed;
            return result;
        });

        trimmedModel.comprobanteDetalle = trimmedDetails;
        var trimmedMontos = _.map(vm.comprobante.montosImponibles, function (monto) {
            return ModelTrimmer.trimDetails(monto);
        });
        trimmedModel.montosImponibles = trimmedMontos;
        if (!vm.isEdit) {
            trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        }
        var resource = ComprobanteFactory.create(trimmedModel);

        if(vm.isEditAprob){
            return ComprobanteFactory.saveIgnoreEstado(
                resource,
                vm.isEditAprob
            );
        }else if(vm.plazoCuotas != undefined && vm.cantidadCuotas != undefined){
            var montoInicial = vm.montoInicial ? vm.montoInicial : 0;
            return ComprobanteFactory.saveWithPlanPago(
                resource,
                vm.cantidadCuotas,
                vm.plazoCuotas,
                montoInicial,
                vm.hasPermisoEditAprobado
            );
        }else{
            return formFactory.defaultNSFSubmit(
                $scope.ComprobanteForm,
                ComprobanteFactory,
                trimmedModel,
                errorHandler
            );
        }
    }

    function errorHandler(e) {
        var msg = "";
        _.forEach(e.data, function (error) {
            if (error.constraint == "id_producto, numero") {
                msg +=
                    "\n\n" +
                    $filter("translate")("NUMERO_GRUPO_REPETIDO_PRODUCTO") +
                    ".\n";
            } else if (
                error.constraint ==
                "numero, id_proveedor, id_numero_timbrado, id_tipo_comprobante"
            ) {
                msg +=
                    "\n\n" +
                    $filter("translate")("NROCOMP_PROVEEDOR_TIMBRADO_TIPO_REPETIDO") +
                    ".\n";
            } else if (
                error.constraint ==
                "numero, id_proveedor, id_numero_timbrado"
            ) {
                msg +=
                    "\n\n" +
                    $filter("translate")("NROCOMP_PROVEEDOR_TIMBRADO_TIPO_REPETIDO") +
                    ".\n";
            } else if (error.codigoError == 300) {
                msg +=
                    "\n\n" +
                    $filter("translate")("NROCOMP_PROVEEDOR_TIMBRADO_TIPO_REPETIDO") +
                    ".\n";
            } else {
                msg += "\n\n" + error.message + ".";
            }
        });
        notify({ message: msg, classes: "alert-danger", position: "right" });
    }

    function cancel() {
        if (vm.fromOutside) {
            //$state.go("app.consultacomprobantes.list");
            $location.path("/comprobantes");
        } else if (vm.isProcesoCompra) {
            $state.go("app.compraslocales.proceso.comprobante.list");
            //$location.path("/comprobantes");
        }
    }
}

"use strict";

angular
        .module("qualita.compra")
        .controller("ComprobanteLocalListCtrl", ComprobanteLocalListCtrl);

ComprobanteLocalListCtrl.$inject = [
    "AuthorizationService",
    "AuthenticationService",
    "ComprobanteFactory",
    "CompraFactory",
    "$modal",
    "$scope",
    "$state",
    "filterFactory",
    "notify",
    "CompraLocalLangFactory",
    "WatchLocacionChangeFactory",
    "$filter",
    "AplicacionComprobanteFactory",
    "UtilFactory",
    "CsvFactory",
    "$window",
    "baseurl",
    "ReportTicketFactory",
    "PlanillaFondoFijoFactory",
    "PeriodoAdministrativoFactory",
    "$q",
    "ParametrosFactory",
    "CONFIGURATION",
    "$http"
];

function ComprobanteLocalListCtrl(
        AuthorizationService,
        AuthenticationService,
        ComprobanteFactory,
        CompraFactory,
        $modal,
        $scope,
        $state,
        filterFactory,
        notify,
        CompraLocalLangFactory,
        WatchLocacionChangeFactory,
        $filter,
        AplicacionComprobanteFactory,
        UtilFactory,
        CsvFactory,
        $window,
        baseurl,
        ReportTicketFactory,
        PlanillaFondoFijoFactory,
        PeriodoAdministrativoFactory,
        $q,
        ParametrosFactory,
        CONFIGURATION,
        $http
        ) {
    var vm = this;

    vm.uiBlockuiConfig = {
        'bloquear': false,
        'message': 'Descargando reporte...'
      };

    activate();

    function activate() {

        var monedaRenderMiles = function (data, type, row) {
            if (data != undefined) {
                return (
                        data
                        .toString()
                        .replace(".", ",")

                        );
            } else {
                return "";
            }
        }

        var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
        var enviarAContabilidad = '';

        promise1.then(function(response) {
            enviarAContabilidad = response.valorTipo.codigo;
        });

        var monedaRender = function (data, type, row) {
            var campoMoneda = null;
            if (row.moneda1 != undefined) {
                campoMoneda = row.moneda1;
            } else if (row.ordenPago != null && row.ordenPago.moneda) {
                campoMoneda = row.ordenPago.moneda;
            } else if (row.comprobante != null && row.comprobante.moneda1) {
                campoMoneda = row.comprobante.moneda1;
            }else{
                campoMoneda = row.comprobanteAplicado.moneda1;
            }

            if (data != undefined) {
                var moneda = "Gs. ";
                if (campoMoneda.codigo === "dolares") {
                    moneda = "Usd. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "pesos") {
                    moneda = "Pes. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "real") {
                    moneda = "Rel. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "euro") {
                    moneda = "Eur. ";
                    data = parseFloat(data).toFixed(2);
                } else {
                    data = parseFloat(data).toFixed(0);
                }
                return (
                        moneda +
                        data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        );
            } else {
                return "";
            }
        };

        var moneda2Render = function (data, type, row) {
            var campoMoneda = null;
            if (row.moneda1 != undefined) {
                campoMoneda = row.moneda1;
            } else if (row.comprobante != null && row.comprobante.moneda1) {
                campoMoneda = row.comprobante.moneda1;
            } else {
                campoMoneda = row.ordenPago.moneda;
            }

            if (data != undefined) {
                var moneda = "Gs. ";
                if (campoMoneda.codigo === "dolares") {
                    moneda = "Usd. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "pesos") {
                    moneda = "Pes. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "real") {
                    moneda = "Rel. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "euro") {
                    moneda = "Eur. ";
                    data = parseFloat(data).toFixed(2);
                } else {
                    data = parseFloat(data).toFixed(0);
                }
                return (
                        moneda +
                        data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        );
            } else {
                return "";
            }
        };

        var renderOrigen = function (data) {
            if (data == undefined)
                return "No";
            else
                return data;
        };

        var defaultColumnOrder = [
            "id",
            "numero",
            "fecha",
            "proveedor.nombre",
            "tipo.descripcion",
            "proyecto.nombre",
            "estado.descripcion",
            "timbradoProveedor.numero",
            "montoTotal",
            "saldo",
            "moneda1.descripcion",
            "totalImpuestos",
            "rubroIva.descripcion",
            "sucursalProveedor.descripcion",
            "cambio"
        ];

        CompraLocalLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            var compra = undefined;
            var staticFilter = undefined;

            if ($state.is("app.consultacomprobantes.list")) {
                vm.fromOutside = true;
            } else if ($state.is("app.compraslocales.proceso.comprobante.list")) {
                vm.procesoCompra = true;
                compra = CompraFactory.getCompra();
                if (!compra) {
                    $state.go("app.main");
                }
                var tipo = compra.tipo;
                var tipoCompraDetalle = _.find(compra.tipo.tipoCompraDetalles, function (
                        detalle
                        ) {
                    return detalle.componente.codigo == "comp_tipo_compra_comprobante";
                });
                if (!tipoCompraDetalle) {
                    $state.go("app.main");
                }
                var isStateEnabled = CompraFactory.checkPermissionForState(
                        tipoCompraDetalle.orden - 1
                        );
                if (!isStateEnabled) {
                    $state.go("app.main");
                }

                // filtro estático
                staticFilter = {};
                staticFilter.search = filterFactory
                        .and([{
                                path: "importacion.codigo",
                                isNull: "true"
                            },
                            {
                                path: "compra.id",
                                equals: compra.id
                            },{
                                path: "id",
                                sortDesc: "true"
                            },
                            {
                                path: "id",
                                isNotNull: "true"
                            }
                        ])
                        .value();
            }

            if (vm.fromOutside) {
                defaultColumnOrder.push("compra.codigo");
                defaultColumnOrder.push("importacion.codigo");
                defaultColumnOrder.push("cargaImportacion");
            }

            var renderEmptyData = function (data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            vm.options = {
                staticFilter: staticFilter,
                resource: "comprobante",
                title: $filter("translate")("COMPROBANTES"),
                view: "ComprobanteList",
                factory: ComprobanteFactory,
                defaultColumnOrder: defaultColumnOrder,
                failedDeleteError: $filter("translate")(
                        "DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS"
                        ),
                columns: [
                    {data: "id", title: $filter("translate")("CODE"), visible: false},
                    {
                        data: "numero",
                        title: $filter("translate")("NUMBER"),
                        class: "dt-center"
                    },
                    {
                        data: "fecha",
                        title: $filter("translate")("DATE"),
                        renderWith: "dateRender",
                        dateFormat: "DD/MM/YYYY",
                        class: "dt-center",
                        type: "date-range"
                    },
                    {
                        data: "proveedor.nombre",
                        title: $filter("translate")("PROVEEDOR_INDEX")
                    },
                    {
                        data: "tipo.descripcion",
                        title: $filter("translate")("TIPO_COMPROBANTE")
                    },
                    {
                        data: "compra.codigo",
                        title: $filter("translate")("COMPRA_REL"),
                        renderWith: "emptyRender",
                        class: "dt-left"
                    },
                    {
                        data: "importacion.codigo",
                        title: $filter("translate")("IMPORTACION_REL"),
                        renderWith: "emptyRender",
                        class: "dt-left"
                    },
                    {
                        data: "proyecto.nombre",
                        title: $filter("translate")("PROYECTO"),
                        class: "dt-center",
                        renderWith: "emptyRender"
                    },
                    {
                        data: "estado.descripcion",
                        title: $filter("translate")("STATUS"),
                        renderWith: "emptyRender",
                        class: "dt-center",
                        'type': 'combo',
                        'filterUrl': 'tipos/filtro/estados_comprobante'
                    },
                    {
                        data: "timbradoProveedor.numero",
                        title: $filter("translate")("TIMBRADO_PROVEEDOR"),
                        class: "dt-right",
                        renderWith: "emptyRender"
                    },
                    {
                        data: "cambio",
                        title: $filter("translate")("TIPO_CAMBIO"),
                        class: "dt-right",
                        render: monedaRenderMiles,
                        type: "number-range",
                        visible: false
                    },
                    {
                        data: "montoTotal",
                        title: $filter("translate")("MONTO"),
                        class: "dt-right",
                        type: "number-range",
                        render: monedaRender
                    },
                    {
                        data: "saldo",
                        title: $filter("translate")("SALDO_PENDIENTE"),
                        class: "dt-right",
                        type: "number-range",
                        render: monedaRender
                    },
                    {
                        data: "moneda1.descripcion",
                        title: $filter("translate")("MONEDA"),
                        renderWith: "emptyRender",
                        class: "dt-center",
                        visible: false
                    },
                    {
                        data: "totalImpuestos",
                        title: $filter("translate")("TOTAL_IMPUESTO"),
                        class: "dt-right",
                        render: monedaRender,
                        type: "number-range",
                        visible: false
                    },
                    {
                        data: "rubroIva.descripcion",
                        title: $filter("translate")("RUBRO_IVA"),
                        renderWith: "emptyRender",
                        class: "dt-center",
                        visible: false
                    },
                    {
                        data: "sucursalProveedor.descripcion",
                        title: $filter("translate")("SUCURSAL_PROVEEDOR"),
                        renderWith: "emptyRender",
                        class: "dt-center",
                        visible: false
                    },
                    {'data': 'grupoCompra.descripcion', 'title': $filter('translate')('GRUPO_COMPRA'), 'class': 'dt-center', 'render': renderEmptyData, visible: false},
                    {'data': 'cargaImportacion', 'title': 'Carga Importacion', 'class' : 'dt-center', visible: false, 'render': renderOrigen}
                ],
                hasOptions: true,
                hideAddMenu: true,
                extraMenuOptions: [{
                    title: $filter("translate")("NEW"),
                    icon: "glyphicon glyphicon-plus",
                    showCondition: function () {
                        var permission =
                                vm.fromOutside ||
                                (AuthorizationService.hasPermission("create_comprobante") &&
                                        CompraFactory.getCompra().estado.codigo != "compra_cerrada" &&
                                        CompraFactory.getCompra().estado.codigo != "compra_anulada");
                        return permission;
                    },
                    action: function () {
                        checkPeriodoAdministrativo(null).then(function (resultado) {
                            if (resultado == true) {
                                if (vm.fromOutside) {
                                    $state.go("app.consultacomprobantes.new");
                                } else {
                                    $state.go("app.compraslocales.proceso.comprobante.new");
                                }
                            } else if (resultado == false) {
                                var msg = $filter('translate')('NO_OPEN_PERIODS_NOW');
                                notify({message: msg, classes: 'alert-danger', position: 'right'});
                            }
                        });
                    }
                },
                {
                  data: $filter("translate")("ARCHIVO_SALDO"),
                  title: $filter("translate")("EXPORTAR_SALDO_COMPRAS"),
                  icon: "glyphicon glyphicon-check",
                  showCondition: function () {
                      return vm.fromOutside;
                  },
                  action: function (itemId) {
                      $scope.selectedItemId = itemId;
                      $scope.obj = {};
                      // TODO translations
                      $scope.meses = [
                          {id: 1, nombre: "Enero"},
                          {id: 2, nombre: "Febrero"},
                          {id: 3, nombre: "Marzo"},
                          {id: 4, nombre: "Abril"},
                          {id: 5, nombre: "Mayo"},
                          {id: 6, nombre: "Junio"},
                          {id: 7, nombre: "Julio"},
                          {id: 8, nombre: "Agosto"},
                          {id: 9, nombre: "Septiembre"},
                          {id: 10, nombre: "Octubre"},
                          {id: 11, nombre: "Noviembre"},
                          {id: 12, nombre: "Diciembre"}
                      ];
                      $scope.tituloModal = $filter("translate")(
                              "EXPORTAR_SALDO_COMPRAS"
                              );
                      $scope.mensajeModal = "Indicar la fecha para generar el archivo";
                      var modalInstance = $modal.open({
                          templateUrl: "views/directives/archivo-compras-saldo-proveedores-modal.html",
                          scope: $scope
                      });

                      $scope.cancel = function () {
                          modalInstance.dismiss("cancel");
                      };

                      $scope.ok = function (fecha) {
                          if (fecha) {
                            fecha = moment(fecha).format("YYYY/MM/DD");
                            vm.uiBlockuiConfig.bloquear = true;
                            $http({
                                url: baseurl.getBaseUrl() + "/comprobante/saldo-proveedores/csv",
                                method: "GET",
                                params: { fecha: fecha}
                            }).then(
                                function (response) {
                                    if (response.data.filename != undefined) {
                                        $window.open(
                                            baseurl.getPublicBaseUrl() + response.data.filename,
                                            "_blank"
                                        );
                                        vm.uiBlockuiConfig.bloquear = false;
                                        modalInstance.dismiss("cancel");

                                    } else {
                                        notify({
                                            message: "Error al generar el archivo.",
                                            classes: "alert-danger",
                                            position: "right"
                                        });
                                        vm.uiBlockuiConfig.bloquear = false;
                                        modalInstance.dismiss("cancel");
                                    }
                                },
                                function (response) {

                                    var mensaje = "<span>";
                                    _.forEach(response.data.error, function (err) {
                                        mensaje = mensaje + "<br/>" + err;
                                    });
                                    mensaje = mensaje + "</span>";
                                    notify({
                                        messageTemplate: mensaje,
                                        classes: "alert-danger",
                                        position: "right",
                                        templateUrl: ""
                                    });
                                    vm.uiBlockuiConfig.bloquear = false;
                                    modalInstance.dismiss("cancel");

                                }
                            );
                             /*  ComprobanteFactory.downloadCSVSaldoProveedores(
                                      fecha,
                                      $filter("translate")("EXPORTAR_HECHUAKA_ERROR"), vm
                                      ); */
                          }
                      };
                  }
              },
              /**************************************** */

              {
                    data: $filter("translate")("HECHAUKA_COMPRAS"),
                    title: $filter("translate")("EXPORTAR_HECHAUKA_COMPRAS"),
                    icon: "glyphicon glyphicon-check",
                    showCondition: function () {
                        return vm.fromOutside;
                    },
                        action: function (itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.obj = {};
                            $scope.obj.tipoReporte = {id: 1, nombre: "Original"};
                            // TODO translations
                            $scope.meses = [
                                {id: 1, nombre: "Enero"},
                                {id: 2, nombre: "Febrero"},
                                {id: 3, nombre: "Marzo"},
                                {id: 4, nombre: "Abril"},
                                {id: 5, nombre: "Mayo"},
                                {id: 6, nombre: "Junio"},
                                {id: 7, nombre: "Julio"},
                                {id: 8, nombre: "Agosto"},
                                {id: 9, nombre: "Septiembre"},
                                {id: 10, nombre: "Octubre"},
                                {id: 11, nombre: "Noviembre"},
                                {id: 12, nombre: "Diciembre"}
                            ];
                            $scope.tiposReporte = [
                                {id: 1, nombre: "Original"},
                                {id: 2, nombre: "Rectificativa"}
                            ];
                            $scope.tituloModal = $filter("translate")(
                                    "EXPORTAR_HECHAUKA_COMPRAS"
                                    );
                            $scope.mensajeModal = $filter("translate")(
                                    "SELECCION_RANGO_HECHAUKA"
                                    );
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/hechauka-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss("cancel");
                            };

                            $scope.ok = function (mes, anio, tipoReporte) {
                                if (mes && anio) {
                                    ComprobanteFactory.downloadCSVHechaukaCompras(
                                            mes.id,
                                            anio,
                                            tipoReporte.id,
                                            $filter("translate")("EXPORTAR_HECHUAKA_ERROR")
                                            );
                                    modalInstance.dismiss("cancel");
                                }
                            };
                        }
                    },

                    /**************************************** */
                    {
                        data: $filter("translate")("ARCHIVO_COMPRAS"),
                        title: $filter("translate")("EXPORTAR_ARCHIVO_COMPRAS"),
                        icon: "glyphicon glyphicon-check",
                        showCondition: function () {
                            return vm.fromOutside;
                        },
                        action: function (itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.obj = {};
                            // TODO translations
                            $scope.meses = [
                                {id: 1, nombre: "Enero"},
                                {id: 2, nombre: "Febrero"},
                                {id: 3, nombre: "Marzo"},
                                {id: 4, nombre: "Abril"},
                                {id: 5, nombre: "Mayo"},
                                {id: 6, nombre: "Junio"},
                                {id: 7, nombre: "Julio"},
                                {id: 8, nombre: "Agosto"},
                                {id: 9, nombre: "Septiembre"},
                                {id: 10, nombre: "Octubre"},
                                {id: 11, nombre: "Noviembre"},
                                {id: 12, nombre: "Diciembre"}
                            ];
                            $scope.tituloModal = $filter("translate")(
                                    "EXPORTAR_ARCHIVO_COMPRAS"
                                    );
                            $scope.mensajeModal = $filter("translate")(
                                    "SELECCION_RANGO_HECHAUKA"
                                    );
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/archivo-compras-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss("cancel");
                            };

                            $scope.ok = function (mes, anio) {
                                if (mes && anio) {
                                    ComprobanteFactory.downloadCSVArchivoCompras(
                                            mes.id,
                                            anio,
                                            $filter("translate")("EXPORTAR_HECHUAKA_ERROR")
                                            );
                                    modalInstance.dismiss("cancel");
                                }
                            };
                        }
                    },
                    /**************************************** */
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function () {
                            return true;
                        },
                        'action': function () {
                            var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                            CsvFactory.csv("comprobante", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                        }
                    },
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function () {
                            return true;
                        },
                        'action': function () {
                            var filters;
                                if(vm.procesoCompra){
                                    filters = createFilters(vm.options.getFilters(), vm.options.staticFilter);
                                }else{
                                    filters = createFilters(vm.options.getFilters());
                            }
                            ReportTicketFactory.ticket('listadoComprobanteCompra', filters, vm.options.tableAjaxParams,
                                    vm.options.currentColumnOrder).then(function (genTicket) {
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open($scope.pdfDownloadURL, '_blank');
                            });
                        }
                    }
                ],
                hideViewMenu: true,
                hideEditMenu: true,
                hideRemoveMenu: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc",
                extraRowOptions: [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" +
                                $filter("translate")("EDIT") +
                                "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        functionName: "editar",
                        functionDef: function (itemId) {
                            if (vm.fromOutside) {
                                $state.go("app.consultacomprobantes.edit", {id: itemId});
                            } else {
                                $state.go("app.compraslocales.proceso.comprobante.edit", {
                                    id: itemId
                                });
                            }
                        },
                        conditionName: "canEditar",
                        customAttribute: ["estado", "compra", "importacion", "tipo", "cargaImportacion"],
                        conditionDef: function (atributos) {
                            var estado = atributos[0];
                            var compra = atributos[1];
                            var importacion = atributos[2];
                            var cargaImportacion = atributos[4];
                            return (
                                    (vm.fromOutside &&
                                            AuthorizationService.hasPermission("update_comprobante") &&
                                            !compra &&
                                            !importacion &&
                                            estado.codigo != "comp_aprobado" && (cargaImportacion == 'No' || cargaImportacion == null)) ||
                                    (!vm.fromOutside &&
                                            AuthorizationService.hasPermission("update_comprobante") &&
                                            CompraFactory.getCompra().estado.codigo != "compra_cerrada" &&
                                            CompraFactory.getCompra().estado.codigo != "compra_anulada" &&
                                            estado.codigo != "comp_aprobado")
                                    );
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                                $filter("translate")("DELETE") +
                                "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        functionName: "borrar",
                        functionDef: function (itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter("translate")("DELETE_CONFIRMATION");
                            $scope.mensajeModal = $filter("translate")(
                                    "PERMANENT_OPERATION_WARNING"
                                    );
                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                $scope.modalInstanceBorrar1.dismiss("cancel");
                            };

                            $scope.ok = function (itemId) {
                                var model = ComprobanteFactory.create({id: itemId});
                                ComprobanteFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, {reload: true});
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                            $scope.mensajeModal = $filter("translate")(
                                                    "DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS"
                                                    );
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                        "</div>" +
                                                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                        '<div class="modal-footer">' +
                                                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                        "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                        }
                                );
                            };
                        },
                        conditionName: "canBorrar",

                        customAttribute: ["estado", "compra", "importacion", "hasOrdenPagoDetalles"],
                        conditionDef: function(atributos) {

                            var estado = atributos[0];
                            var compra = atributos[1];
                            var importacion = atributos[2];
                            var hasOrdenPagoDetalles = atributos[3];

                            return (
                                (vm.fromOutside &&
                                    AuthorizationService.hasPermission("delete_comprobante") &&
                                    !compra &&
                                    !importacion &&
                                    estado.codigo != "comp_aprobado"
                                    && hasOrdenPagoDetalles == "No"
                                    ) ||
                                (!vm.fromOutside &&
                                    AuthorizationService.hasPermission("delete_comprobante") &&
                                    CompraFactory.getCompra().estado.codigo != "compra_cerrada" &&
                                    CompraFactory.getCompra().estado.codigo != "compra_anulada" &&
                                    estado.codigo != "comp_aprobado")
                            );
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                                $filter("translate")("VIEW") +
                                "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function (itemId) {
                            if (vm.fromOutside) {
                                $state.go("app.consultacomprobantes.view", {id: itemId});
                            } else if (vm.procesoCompra) {
                                $state.go("app.compraslocales.proceso.comprobante.view", {
                                    id: itemId
                                });
                            }
                        },
                        conditionName: "canVer",
                        conditionDef: function () {
                            return AuthorizationService.hasPermission("index_comprobante");
                        }
                    },
                    {
                        templateToRender: "<button title='" +
                                $filter("translate")("CONFIRMAR_COMPROBANTE") +
                                "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataId%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
                        functionName: "aprobar",
                        conditionName: "canAprobar",
                        customAttribute: ["estado", "compra", "importacion", "cargaImportacion"],
                        conditionDef: function (atributos) {
                            var estado = atributos[0];
                            var compra = atributos[1];
                            var importacion = atributos[2];
                            var cargaImportacion = atributos[3];

                            return (
                                    // Condiciones FUERA del flujo:
                                            // si tiene permiso de aprobar
                                                    // si no tiene compra ni importacion
                                                            // si el comprobante tiene estado ingresado

                                                                    (AuthorizationService.hasPermission("aprobar_comprobante") &&
                                                                            compra == null && importacion == null &
                                                                            estado.codigo == "comp_ingresado" && (cargaImportacion == 'No' || cargaImportacion == null)) ||
                                                                    // Condiciones en un flujo
                                                                    // si tiene permiso de aprobar
                                                                    // si esta en un flujo
                                                                    // si el comprobante tiene estado ingresado

                                                                    AuthorizationService.hasPermission("aprobar_comprobante") &&
                                                                    vm.fromOutside == undefined &&
                                                                    estado.codigo == "comp_ingresado"
                                                                    );
                                                },
                                                functionDef: function (itemId) {
                                                    ComprobanteFactory.get(itemId, "ComprobanteList").$promise.then(function (response) {
                                                        if (response) {
                                                            checkPeriodoAdministrativo(response.fecha).then(function (checkPeriodo) {
                                                                if (checkPeriodo == true) {
                                                                    if (
                                                                            response.grupoCompra &&
                                                                            response.grupoCompra.permiteServicio &&
                                                                            !response.grupoCompra.permiteProducto
                                                                            ) {
                                                                        $scope.mensajeModal = $filter("translate")(
                                                                                "CONFIRMAR_COMPROBANTE_SERVICIOS_Q"
                                                                                );
                                                                    } else {

                                                                        $scope.mensajeModal = $filter("translate")(
                                                                                "CONFIRMAR_COMPROBANTE_SERVICIOS_Q"
                                                                                );

                                                                        if (compra) {
                                                                            _.forEach(compra.tipo.tipoCompraDetalles, function (tipoCompraDetalle) {

                                                                                if ((tipoCompraDetalle.realizaIngresoStock === true) &&
                                                                                        (tipoCompraDetalle.componente.codigo === "comp_tipo_compra_comprobante")) {

                                                                                    $scope.mensajeModal = $filter("translate")(
                                                                                            "CONFIRMAR_COMPROBANTE_Q"
                                                                                            );
                                                                                }
                                                                            });
                                                                        }
                                                                    }
                                                                    $scope.tituloModal = $filter("translate")(
                                                                            "CONFIRMAR_COMPROBANTE"
                                                                            );
                                                                    var modalInstance = $modal.open({
                                                                        templateUrl: "views/directives/confirmation-modal.html",
                                                                        scope: $scope
                                                                    });

                                                                    $scope.cancel = function () {
                                                                        modalInstance.dismiss("cancel");
                                                                    };

                                                                    $scope.ok = function (id) {
                                                                        ComprobanteFactory.aprobar(itemId).then(
                                                                                function (response) {
                                                                                    // ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                                                                    //     if (parametro.valorTipo.codigo == 'si') {
                                                                                    //       if(response.enviadoAContabilidad == false){
                                                                                    //           notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                                                    //       }
                                                                                    //       if(response.listaArchivosLogs != null){
                                                                                    //         if(response.listaArchivosLogs.length != 0){
                                                                                    //           /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                                                    //               console.log('response.listaArchivosLogs: ');
                                                                                    //               console.log(response.listaArchivosLogs[i]);
                                                                                    //               $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                                                    //           }*/
                                                                                    //           notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                                                    //         }
                                                                                    //       }
                                                                                    //     }
                                                                                    // });
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, {reload: true});
                                                                                },
                                                                                function (error) {
                                                                                    // console.log(error);
                                                                                    var mensaje = "<span>";
                                                                                    _.forEach(error.data, function (err) {
                                                                                        if (err.constraint == "id_producto, numero") {
                                                                                            mensaje =
                                                                                                    mensaje +
                                                                                                    $filter("translate")(
                                                                                                    "NUMERO_GRUPO_REPETIDO_PRODUCTO"
                                                                                                    ) +
                                                                                                    "<br/>";
                                                                                        } else {
                                                                                            mensaje = mensaje + err.message + "<br/>";
                                                                                        }
                                                                                    });
                                                                                    mensaje = mensaje + "</span>";
                                                                                    notify({
                                                                                        messageTemplate: mensaje,
                                                                                        classes: "alert-danger",
                                                                                        position: "right",
                                                                                        templateUrl: ""
                                                                                    });
                                                                                }
                                                                        );
                                                                    };
                                                                } else {
                                                                    var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                                }
                                                            });
                                                        }
                                                    });
                                                }
                                            },
                                            {
                                                templateToRender: "<button ng-disabled='disableAnular' title='" +
                                                        $filter("translate")("DESAPROBAR_COMPROBANTE") +
                                                        "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='desaprobar(<%=dataId%>)' ng-class='{ hidden : !canDesaprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                                                functionName: "desaprobar",
                                                conditionName: "canDesaprobar",
                                                customAttribute: ["estado", "compra", "importacion"],
                                                conditionDef: function (atributos) {
                                                    var estado = atributos[0];
                                                    var compra = atributos[1];
                                                    var importacion = atributos[2];
                                                    return (
                                                            /* Condiciones:
                                                             si tiene permiso de aprobar
                                                             si tiene compra y no esta cerrada
                                                             si no tiene ni compra ni importacion
                                                             si el comprobante tiene estado aprobado */
                                                            AuthorizationService.hasPermission("aprobar_comprobante") &&
                                                            estado.codigo == "comp_aprobado" &&
                                                            ((CompraFactory.getCompra() && CompraFactory.getCompra().estado.codigo != "compra_cerrada" && !vm.fromOutside) ||
                                                                    ((compra === null || compra === undefined) && (importacion == null || importacion === undefined) && vm.fromOutside)
                                                                    )
                                                            );
                                                },
                                                functionDef: function (itemId) {
                                                  $scope.anular = this;
                                                  $scope.anular.$$childHead.$parent.disableAnular = true;
                                                    ComprobanteFactory.get(itemId, "ComprobanteForm").$promise.then(function (response) {
                                                      $scope.anular.$$childHead.$parent.disableAnular = false;
                                                        var numeroComprobante = response.numero;
                                                        if (response) {
                                                          $scope.anular.$$childHead.$parent.disableAnular = true;
                                                            checkPeriodoAdministrativo(response.fecha).then(function (checkPeriodo) {
                                                              $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                if (checkPeriodo == true) {
                                                                    if (
                                                                            response.grupoCompra &&
                                                                            response.grupoCompra.permiteServicio &&
                                                                            !response.grupoCompra.permiteProducto
                                                                            ) {
                                                                        $scope.mensajeModal = $filter("translate")(
                                                                                "DESAPROBAR_COMPROBANTE_SERVICIOS_Q"
                                                                                );
                                                                    } else {
                                                                        $scope.mensajeModal = $filter("translate")(
                                                                                "DESAPROBAR_COMPROBANTE_Q"
                                                                                );
                                                                        if(response.recepciones!=null && response.recepciones.length>0){

                                                                            for(var i=0; i< compra.tipo.tipoCompraDetalles.length; i++){
                                                                                if(compra.tipo.tipoCompraDetalles[i].componente.codigo==="comp_tipo_compra_recepcion" &&
                                                                                    compra.tipo.tipoCompraDetalles[i].realizaIngresoStock===true){

                                                                                        $scope.mensajeModal = $filter("translate")(
                                                                                            "DESAPROBAR_COMPROBANTE_RECEPCION"
                                                                                            );
                                                                                        break;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    $scope.tituloModal = $filter("translate")(
                                                                            "DESAPROBAR_COMPROBANTE"
                                                                            );
                                                                    var modalInstance = $modal.open({
                                                                        templateUrl: "views/directives/confirmation-modal.html",
                                                                        scope: $scope
                                                                    });

                                                                    $scope.cancel = function () {
                                                                        modalInstance.dismiss("cancel");
                                                                    };

                                                                    $scope.ok = function (id) {

                                                                      $scope.anular.$$childHead.$parent.disableAnular = true;
                                                                        ComprobanteFactory.getEstaAsentado(itemId).then(
                                                                            function (response) {
                                                                              $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                if(response.data == true){
                                                                                    var msg = $filter("translate")("ERROR_ESTA_ASENTADO", { numero: numeroComprobante });
                                                                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                                                }else{
                                                                                  $scope.anular.$$childHead.$parent.disableAnular = true;
                                                                                    ComprobanteFactory.desaprobar(itemId).then(
                                                                                        function (response) {
                                                                                          $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                            modalInstance.close();
                                                                                            $state.go($state.current, {}, {reload: true});
                                                                                        },
                                                                                        function (error) {
                                                                                          $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                            // console.log(error);
                                                                                            var mensaje = "<span>";
                                                                                            _.forEach(error.data, function (err) {
                                                                                                mensaje = mensaje + err.message + "<br/>";
                                                                                            });
                                                                                            mensaje = mensaje + "</span>";
                                                                                            notify({
                                                                                                messageTemplate: mensaje,
                                                                                                classes: "alert-danger",
                                                                                                position: "right",
                                                                                                templateUrl: ""
                                                                                            });
                                                                                        }
                                                                                    );
                                                                                }
                                                                            },
                                                                            function (error) {
                                                                              $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                console.log('error');
                                                                                console.log(error);
                                                                                notify({
                                                                                    messageTemplate: "Error el verificar si esta asentado.",
                                                                                    classes: "alert-danger",
                                                                                    position: "right",
                                                                                    templateUrl: ""
                                                                                });
                                                                            }
                                                                        );
                                                                    };
                                                                } else {
                                                                    var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                                }
                                                            });
                                                        }
                                                    });
                                                }
                                            },
                                            {
                                                templateToRender: "<button class='btn btn-default' title='" +
                                                        $filter("translate")("APLICAR_COMPROBANTE") +
                                                        "' style='margin-right: 5px;' ng-click='aplicarComprobante(<%=dataId%>)' ng-class='{ hidden : !canAplicarComprobante(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                                                customAttribute: ["tipo", "estado"],
                                                functionName: "aplicarComprobante",
                                                functionDef: function (itemId) {
                                                    ComprobanteFactory.get(itemId, "AplicacionCompraComprobanteForm").$promise.then(function (c) {
                                                        var saldo = c.saldo;
                                                        var montoTotal = c.montoTotal;
                                                        if (saldo != montoTotal) {
                                                            $scope.tituloModal = $filter("translate")(
                                                                    "APLICACION_YA_EXISTENTE"
                                                                    );
                                                            var templateTmp = '<div class="modal-header">' +
                                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                                    "</div>" +
                                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                                    '<div class="modal-footer">' +
                                                                    '<button class="btn btn-primary" ng-click="editarAplicacion()">' +
                                                                    $filter("translate")("EDITAR_APLICACION") +
                                                                    "</button>";
                                                            if (saldo > 0) {
                                                                templateTmp += '<button class="btn btn-secondary" ng-click="agregarAplicacion()">' +
                                                                        $filter("translate")("AGREGAR_APLICACION ") +
                                                                        "</button>";
                                                            }
                                                            templateTmp += '<button class="btn btn-danger" ng-click="desaplicar(' + itemId + ')">' +
                                                                    $filter("translate")("DESAPLICAR") +
                                                                    "</button>";
                                                            templateTmp += '<button class="btn btn-warning" ng-click="cancel()">' +
                                                                    $filter("translate")("CANCEL ") +
                                                                    "</button>" +
                                                                    "</div>";
                                                            $scope.mensajeModal = $filter("translate")("QUE_DESEA_Q");
                                                            $scope.modalInstanceBorrar1 = $modal.open({
                                                                template: templateTmp,
                                                                scope: $scope
                                                            });

                                                            $scope.cancel = function () {
                                                                $scope.modalInstanceBorrar1.dismiss("cancel");
                                                            };

                                                            $scope.editarAplicacion = function () {
                                                                if (vm.fromOutside) {
                                                                    $state.go("app.consultacomprobantes.apply", {
                                                                        id: itemId,
                                                                        mode: "edit"
                                                                    });
                                                                    $scope.modalInstanceBorrar1.dismiss();
                                                                } else {
                                                                    $state.go(
                                                                            "app.compraslocales.proceso.comprobante.apply", {
                                                                                id: itemId,
                                                                                mode: "edit"
                                                                            }
                                                                    );
                                                                    $scope.modalInstanceBorrar1.dismiss();
                                                                }
                                                            };

                                                            $scope.desaplicar = function (itemId) {
                                                                $scope.modalInstanceBorrar1.dismiss();
                                                                $scope.mensajeModal = $filter("translate")("DESAPLICAR_Q");

                                                                $scope.tituloModal = $filter("translate")("DESAPLICAR");
                                                                var modalInstance = $modal.open({
                                                                    templateUrl: "views/directives/confirmation-modal.html",
                                                                    scope: $scope
                                                                });

                                                                $scope.cancel = function () {
                                                                    modalInstance.dismiss("cancel");
                                                                };

                                                                $scope.ok = function (id) {
                                                                    ComprobanteFactory.desaplicar(itemId).then(
                                                                            function (response) {
                                                                                modalInstance.close();
                                                                                $state.go($state.current, {}, {reload: true});
                                                                            },
                                                                            function (error) {
                                                                                // console.log(error);
                                                                                var mensaje = "<span>";
                                                                                _.forEach(error.data, function (err) {
                                                                                    if (err.constraint == "id_producto, numero") {
                                                                                        mensaje =
                                                                                                mensaje +
                                                                                                $filter("translate")(
                                                                                                "NUMERO_GRUPO_REPETIDO_PRODUCTO"
                                                                                                ) +
                                                                                                "<br/>";
                                                                                    } else {
                                                                                        mensaje = mensaje + err.message + "<br/>";
                                                                                    }
                                                                                });
                                                                                mensaje = mensaje + "</span>";
                                                                                notify({
                                                                                    messageTemplate: mensaje,
                                                                                    classes: "alert-danger",
                                                                                    position: "right",
                                                                                    templateUrl: ""
                                                                                });
                                                                            }
                                                                    );
                                                                }
                                                            }

                                                            $scope.agregarAplicacion = function () {
                                                                if (vm.fromOutside) {
                                                                    $state.go("app.consultacomprobantes.apply", {
                                                                        id: itemId,
                                                                        mode: "add"
                                                                    });
                                                                    $scope.modalInstanceBorrar1.dismiss();
                                                                } else {
                                                                    $state.go(
                                                                            "app.compraslocales.proceso.comprobante.apply", {
                                                                                id: itemId,
                                                                                mode: "add"
                                                                            }
                                                                    );
                                                                    $scope.modalInstanceBorrar1.dismiss();
                                                                }
                                                            };
                                                        } else {
                                                            if (vm.fromOutside) {
                                                                $state.go("app.consultacomprobantes.apply", {
                                                                    id: itemId,
                                                                    mode: "add"
                                                                });
                                                            } else {
                                                                // Con el id del item seleccionado se pasa al formulario de aplicacion
                                                                $state.go("app.compraslocales.proceso.comprobante.apply", {
                                                                    id: itemId,
                                                                    mode: "add"
                                                                });
                                                            }
                                                        }
                                                    });
                                                },
                                                conditionName: "canAplicarComprobante",
                                                conditionDef: function (atributos) {
                                                    var tipoComprobante = atributos[0];
                                                    var estado = atributos[1];
                                                    return (
                                                            AuthorizationService.hasPermission("update_comprobante") &&
                                                            tipoComprobante.aplicable == true &&
                                                            estado.codigo == "comp_aprobado"
                                                            );
                                                }
                                            },
                                            {
                                                templateToRender: "<button class='btn btn-primary' title='" +
                                                        $filter("translate")("PLAN_PAGOS") +
                                                        "' style='margin-right: 5px;' ng-click='planPagos(<%=dataId%>)' ng-class='{ hidden : !canViewPlanPago(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-tasks'></span> </button>",
                                                customAttribute: "tipo",
                                                functionName: "planPagos",
                                                functionDef: function (itemId) {
                                                    ComprobanteFactory.get(itemId, "ComprobanteList").$promise.then(function (c) {
                                                            $state.go("app.planpagos.detalles.list", {
                                                                idPlanPago: c.planPagos.id
                                                            });
                                                    });
                                                },
                                                conditionName: "canViewPlanPago",
                                                conditionDef: function (tipoComprobante) {
                                                    return (
                                                            AuthorizationService.hasPermission("update_comprobante") &&
                                                            tipoComprobante.generarCuotas == true
                                                            );
                                                }
                                            },
                                            {
                                                templateToRender: "<button class='btn btn-primary' title='" +
                                                        $filter("translate")("CONSULTA_FF") +
                                                        "' style='margin-right: 5px;' ng-click='consultaFF(<%=dataId%>)' ng-class='{ hidden : !canViewConsultaPagos(<%=dataCustom%>)}'> <span class='glyphicon glyphicon glyphicon-minus'></span> </button>",
                                                customAttribute: "hasPlanillaFondoFijo",
                                                view: "datatables",
                                                functionName: "consultaFF",
                                                functionDef: function (itemId) {
                                                    var defaultColumnOrder = [
                                                        "planillaFondoFijo.numero",
                                                        "planillaFondoFijo.tipoFondoFijo.nombre",
                                                        "planillaFondoFijo.fecha",
                                                        "planillaFondoFijo.estado.descripcion",
                                                        "montoPlanilla"
                                                    ];

                                                    vm.optionsDTModal = {
                                                        resource: "planillafondofijodetalle",
                                                        title: $filter("translate")("CONSULTA_FF"),
                                                        factory: PlanillaFondoFijoFactory,
                                                        view: "PlanillaFondoFijoDetalleList",
                                                        defaultColumnOrder: defaultColumnOrder,
                                                        columns: [{
                                                                data: "planillaFondoFijo.numero",
                                                                title: $filter("translate")("NUMBER"),
                                                                type: "number-range"
                                                            },
                                                            {
                                                                data: "planillaFondoFijo.tipoFondoFijo.nombre",
                                                                title: $filter("translate")("TIPO_FONDO_FIJO")
                                                            },
                                                            {
                                                                data: "planillaFondoFijo.fecha",
                                                                title: $filter("translate")("DATE"),
                                                                renderWith: "dateRender",
                                                                dateFormat: "DD/MM/YYYY",
                                                                type: "date-range"
                                                            },
                                                            {
                                                                data: "planillaFondoFijo.estado.descripcion",
                                                                title: $filter("translate")("ESTADO")
                                                            },
                                                            {
                                                                data: "montoPlanilla",
                                                                title: $filter("translate")("IMPORTE"),
                                                                renderWith: monedaRender,
                                                                type: "number-range"
                                                            }
                                                        ],
                                                        hasOptions: true,
                                                        defaultOrderColumn: 3,
                                                        defaultOrderDir: "desc",
                                                        hideAddMenu: true,
                                                        hideEditMenu: true,
                                                        hideRemoveMenu: true,
                                                        hideViewMenu: true,
                                                        hideHeader: true,
                                                        staticFilter: {
                                                            search: filterFactory
                                                                    .and([{
                                                                            path: "comprobante.id",
                                                                            equals: itemId
                                                                        }])
                                                                    .value()
                                                        },
                                                        extraRowOptions: [{
                                                                templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' ng-click='visualizarPlanilla(<%=dataCustom%>)' ng-class='{ hidden : !canVisualizarPlanilla(<%=dataId%>)}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                                                                customAttribute: "planillaFondoFijo",
                                                                conditionName: "canVisualizarPlanilla",
                                                                conditionDef: function (itemId) {
                                                                    var permission = hasPermission("index_planillafondofijo");
                                                                    return permission;
                                                                },
                                                                functionName: "visualizarPlanilla",
                                                                functionDef: function (ff) {
                                                                    $state.go("app.planillafondofijo.view", {id: ff.id});
                                                                }
                                                            }]
                                                    };

                                                    vm.modalInstance = $modal.open({
                                                        templateUrl: "views/directives/datatables-custom-modal.html",
                                                        scope: $scope,
                                                        size: "lg"
                                                    });
                                                },
                                                conditionName: "canViewConsultaPlanilla",
                                                conditionDef: function (opd) {
                                                    //console.log("hasOrdenPagoDetalles: ", opd);
                                                    return (
                                                            vm.fromOutside &&
                                                            AuthorizationService.hasPermission("index_comprobante") &&
                                                            opd == "Sí"
                                                            );
                                                }
                                            },
                                            {
                                                templateToRender: "<button class='btn btn-primary' title='" +
                                                        $filter("translate")("CONSULTA_PAGOS") +
                                                        "' style='margin-right: 5px;' ng-click='consultaPagos(<%=dataId%>)' ng-class='{ hidden : !canViewConsultaPagos(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-transfer'></span> </button>",
                                                customAttribute: "hasOrdenPagoDetalles",
                                                view: "datatables",
                                                functionName: "consultaPagos",
                                                functionDef: function (itemId) {
                                                    var defaultColumnOrder = [
                                                        "ordenPago.numero",
                                                        "cuota.numero",
                                                        "ordenPago.tipoOrdenPago.nombre",
                                                        "ordenPago.fecha",
                                                        "ordenPago.estado.descripcion",
                                                        "ordenPago.proveedor.nombre",
                                                        "importePagar",
                                                        "importeAretener"
                                                    ];

                                                    vm.optionsDTModal = {
                                                        resource: "ordenpagodetalle",
                                                        title: $filter("translate")("ORDENES_PAGO"),
                                                        factory: OrdenPagoFactory,
                                                        view: "OrdenPagoDetalleList",
                                                        defaultColumnOrder: defaultColumnOrder,
                                                        columns: [{
                                                                data: "ordenPago.numero",
                                                                title: $filter("translate")("NUMBER"),
                                                                type: "number-range"
                                                            },
                                                            {
                                                                data: "cuota.numero",
                                                                title: $filter("translate")("CUOTA"),
                                                                renderWith: "emptyRender"
                                                            },
                                                            {
                                                                data: "ordenPago.fecha",
                                                                title: $filter("translate")("DATE"),
                                                                renderWith: "dateRender",
                                                                dateFormat: "DD/MM/YYYY",
                                                                type: "date-range"
                                                            },
                                                            {
                                                                data: "ordenPago.tipoOrdenPago.nombre",
                                                                title: $filter("translate")("TIPO_ORDEN_PAGO")
                                                            },
                                                            {
                                                                data: "ordenPago.estado.descripcion",
                                                                title: $filter("translate")("ESTADO")
                                                            },
                                                            {
                                                                data: "ordenPago.proveedor.nombre",
                                                                title: $filter("translate")("PROVEEDOR_SIN_CARACTER"),
                                                                renderWith: "emptyRender"
                                                            },
                                                            {
                                                                data: "importePagar",
                                                                title: $filter("translate")("IMPORTE"),
                                                                renderWith: monedaRender,
                                                                type: "number-range"
                                                            },
                                                            {
                                                                data: "importeAretener",
                                                                title: $filter("translate")("IMPORTE_RETENIDO_CONSULTA"),
                                                                renderWith: moneda2Render,
                                                                type: "number-range"
                                                            }
                                                        ],
                                                        hasOptions: true,
                                                        defaultOrderColumn: 3,
                                                        defaultOrderDir: "desc",
                                                        hideAddMenu: true,
                                                        hideEditMenu: true,
                                                        hideRemoveMenu: true,
                                                        hideViewMenu: true,
                                                        hideHeader: true,
                                                        staticFilter: {
                                                            search: filterFactory
                                                                    .and([{
                                                                            path: "comprobante.id",
                                                                            equals: itemId
                                                                        }])
                                                                    .value()
                                                        },
                                                        extraRowOptions: [{
                                                                templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' ng-click='visualizar(<%=dataCustom%>)' ng-class='{ hidden : !canVisualizar(<%=dataId%>)}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                                                                customAttribute: "ordenPago",
                                                                conditionName: "canVisualizar",
                                                                conditionDef: function (itemId) {
                                                                    var permission = hasPermission("index_ordenpago");
                                                                    return permission;
                                                                },
                                                                functionName: "visualizar",
                                                                functionDef: function (op) {
                                                                    // console.log("Orden Pago: ", op);
                                                                    $state.go("app.ordenpago.view", {id: op.id});
                                                                }
                                                            }]
                                                    };

                                                    vm.modalInstance = $modal.open({
                                                        templateUrl: "views/directives/datatables-custom-modal.html",
                                                        scope: $scope,
                                                        size: "lg"
                                                    });
                                                },
                                                conditionName: "canViewConsultaPagos",
                                                conditionDef: function (opd) {
                                                    //console.log("hasOrdenPagoDetalles: ", opd);
                                                    return (
                                                            vm.fromOutside &&
                                                            AuthorizationService.hasPermission("index_comprobante") &&
                                                            opd == "Sí"
                                                            );
                                                }
                                            },
                                            {
                                                templateToRender: "<button class='btn btn-warning' title='" +
                                                        $filter("translate")("CONSULTA_APLICACIONES") +
                                                        "' style='margin-right: 5px;' ng-click='consultaAplicaciones(<%=dataCustom%>)' ng-class='{ hidden : !canViewConsultaAplicaciones(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-modal-window'></span> </button>",
                                                customAttribute: ["hasAplicaciones", "hasComprobanteAplicaciones", "id"],
                                                functionName: "consultaAplicaciones",
                                                functionDef: function (attributes) {
                                                    var hasAplicaciones = attributes[0];
                                                    var hasComprobanteAplicaciones = attributes[1];
                                                    var itemId = attributes[2];

                                                    var columns = undefined;
                                                    var defaultColumnOrder = undefined;
                                                    var filters = undefined;

                                                    if (hasAplicaciones === "Sí") {
                                                        filters = {
                                                            search: filterFactory
                                                                    .single({
                                                                        path: "comprobanteAplicado.id",
                                                                        equals: itemId
                                                                    })
                                                                    .value()
                                                        };

                                                        defaultColumnOrder = [
                                                            "comprobante.id",
                                                            "comprobante.numero",
                                                            "comprobante.fecha",
                                                            "comprobante.proveedor.nombre",
                                                            "comprobante.tipo.descripcion",
                                                            "comprobante.proyecto.nombre",
                                                            "comprobante.estado.descripcion",
                                                            "comprobante.timbradoProveedor.numero",
                                                            "comprobante.montoTotal",
                                                            "comprobante.saldo",
                                                            "montoAplicado"
                                                        ];

                                                        columns = [{
                                                                data: "comprobante.id",
                                                                title: $filter("translate")("CODE"),
                                                                visible: false,
                                                                type: "number-range"
                                                            },
                                                            {
                                                                data: "comprobante.numero",
                                                                title: $filter("translate")("NUMBER"),
                                                                class: "dt-center"
                                                            },
                                                            {
                                                                data: "comprobante.fecha",
                                                                title: $filter("translate")("DATE"),
                                                                renderWith: "dateRender",
                                                                dateFormat: "DD/MM/YYYY",
                                                                class: "dt-center",
                                                                type: "date-range"
                                                            },
                                                            {
                                                                data: "comprobante.proveedor.nombre",
                                                                title: $filter("translate")("PROVEEDOR_INDEX")
                                                            },
                                                            {
                                                                data: "comprobante.tipo.descripcion",
                                                                title: $filter("translate")("TIPO_COMPROBANTE")
                                                            },
                                                            {
                                                                data: "comprobante.proyecto.nombre",
                                                                title: $filter("translate")("PROYECTO"),
                                                                class: "dt-center",
                                                                renderWith: "emptyRender"
                                                            },
                                                            {
                                                                data: "comprobante.estado.descripcion",
                                                                title: $filter("translate")("STATUS"),
                                                                renderWith: "emptyRender",
                                                                class: "dt-center"
                                                            },
                                                            {
                                                                data: "comprobante.timbradoProveedor.numero",
                                                                title: $filter("translate")("TIMBRADO_PROVEEDOR"),
                                                                class: "dt-center",
                                                                renderWith: "emptyRender",
                                                                type: "number-range"
                                                            },
                                                            {
                                                                data: "comprobante.montoTotal",
                                                                title: $filter("translate")("MONTO"),
                                                                class: "dt-right",
                                                                renderWith: monedaRender,
                                                                type: "number-range"
                                                            },
                                                            {
                                                                data: "comprobante.saldo",
                                                                title: $filter("translate")("SALDO_PENDIENTE"),
                                                                class: "dt-right",
                                                                renderWith: monedaRender,
                                                                type: "number-range"
                                                            },
                                                            {
                                                                data: "montoAplicado",
                                                                title: $filter("translate")("MONTO_APLICADO"),
                                                                class: "dt-right",
                                                                renderWith: monedaRender,
                                                                type: "number-range"
                                                            }
                                                        ]
                                                    } else if (hasComprobanteAplicaciones === "Sí") {
                                                        filters = {
                                                            search: filterFactory
                                                                    .single({
                                                                        path: "comprobante.id",
                                                                        equals: itemId
                                                                    })
                                                                    .value()
                                                        }

                                                        defaultColumnOrder = [
                                                            "comprobanteAplicado.id",
                                                            "comprobanteAplicado.numero",
                                                            "comprobanteAplicado.fecha",
                                                            "comprobanteAplicado.proveedor.nombre",
                                                            "comprobanteAplicado.tipo.descripcion",
                                                            "comprobanteAplicado.proyecto.nombre",
                                                            "comprobanteAplicado.estado.descripcion",
                                                            "comprobanteAplicado.timbradoProveedor.numero",
                                                            "comprobanteAplicado.montoTotal",
                                                            "comprobanteAplicado.saldo",
                                                            "montoAplicado"
                                                        ];
                                                        columns = [{
                                                                data: "comprobanteAplicado.id",
                                                                title: $filter("translate")("CODE"),
                                                                visible: false,
                                                                type: "number-range"
                                                            },
                                                            {
                                                                data: "comprobanteAplicado.numero",
                                                                title: $filter("translate")("NUMBER"),
                                                                class: "dt-center"
                                                            },
                                                            {
                                                                data: "comprobanteAplicado.fecha",
                                                                title: $filter("translate")("DATE"),
                                                                renderWith: "dateRender",
                                                                dateFormat: "DD/MM/YYYY",
                                                                class: "dt-center",
                                                                type: "date-range"
                                                            },
                                                            {
                                                                data: "comprobanteAplicado.proveedor.nombre",
                                                                title: $filter("translate")("PROVEEDOR_INDEX")
                                                            },
                                                            {
                                                                data: "comprobanteAplicado.tipo.descripcion",
                                                                title: $filter("translate")("TIPO_COMPROBANTE")
                                                            },
                                                            {
                                                                data: "comprobanteAplicado.proyecto.nombre",
                                                                title: $filter("translate")("PROYECTO"),
                                                                class: "dt-center",
                                                                renderWith: "emptyRender"
                                                            },
                                                            {
                                                                data: "comprobanteAplicado.estado.descripcion",
                                                                title: $filter("translate")("STATUS"),
                                                                renderWith: "emptyRender",
                                                                class: "dt-center"
                                                            },
                                                            {
                                                                data: "comprobanteAplicado.timbradoProveedor.numero",
                                                                title: $filter("translate")("TIMBRADO_PROVEEDOR"),
                                                                class: "dt-center",
                                                                renderWith: "emptyRender"
                                                            },
                                                            {
                                                                data: "comprobanteAplicado.montoTotal",
                                                                title: $filter("translate")("MONTO"),
                                                                class: "dt-right",
                                                                renderWith: monedaRender,
                                                                type: "number-range"
                                                            },
                                                            {
                                                                data: "comprobanteAplicado.saldo",
                                                                title: $filter("translate")("SALDO_PENDIENTE"),
                                                                class: "dt-right",
                                                                renderWith: monedaRender,
                                                                type: "number-range"
                                                            },
                                                            {
                                                                data: "montoAplicado",
                                                                title: $filter("translate")("MONTO_APLICADO"),
                                                                class: "dt-right",
                                                                renderWith: monedaRender,
                                                                type: "number-range"
                                                            }
                                                        ]
                                                    }

                                                    vm.optionsDTModal = {
                                                        resource: "comprobanteaplicacion",
                                                        title: $filter("translate")("COMPROBANTES"),
                                                        factory: AplicacionComprobanteFactory,
                                                        defaultColumnOrder: defaultColumnOrder,
                                                        columns: columns,
                                                        hasOptions: true,
                                                        defaultOrderColumn: 1,
                                                        defaultOrderDir: "asc",
                                                        hideAddMenu: true,
                                                        hideEditMenu: true,
                                                        hideRemoveMenu: true,
                                                        hideViewMenu: true,
                                                        hideHeader: true,
                                                        view: "ComprobanteAplicacionDatatableList",
                                                        staticFilter: filters,
                                                        extraRowOptions: [{
                                                                templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                                                                        $filter("translate")("VIEW") +
                                                                        "' ng-click='ver(<%=dataCustom%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                                                                functionName: "ver",
                                                                customAttribute: ["comprobante", "comprobanteAplicado"],
                                                                functionDef: function (data) {
                                                                    var comprobante = data[0];
                                                                    var comprobanteAplicado = data[1];
                                                                    var itemId = undefined;
                                                                    if (hasAplicaciones === "Sí") {
                                                                        itemId = comprobante.id;
                                                                    } else {
                                                                        itemId = comprobanteAplicado.id;
                                                                    }
                                                                    if (vm.fromOutside) {
                                                                        $state.go("app.consultacomprobantes.view", {
                                                                            id: itemId
                                                                        });
                                                                    } else if (vm.procesoCompra) {
                                                                        $state.go(
                                                                                "app.compraslocales.proceso.comprobante.view", {id: itemId}
                                                                        );
                                                                    }
                                                                },
                                                                conditionName: "canVer",
                                                                conditionDef: function () {
                                                                    return AuthorizationService.hasPermission(
                                                                            "index_comprobante"
                                                                            );
                                                                }
                                                            }]
                                                    };

                                                    vm.modalInstance = $modal.open({
                                                        templateUrl: "views/directives/datatables-custom-modal.html",
                                                        scope: $scope,
                                                        size: "lg"
                                                    });
                                                },
                                                conditionName: "canViewConsultaAplicaciones",
                                                conditionDef: function (attributes) {
                                                    var hasAplicaciones = attributes[0];
                                                    var hasComprobanteAplicaciones = attributes[1];

                                                    return (
                                                            vm.fromOutside &&
                                                            AuthorizationService.hasPermission("index_comprobante") &&
                                                            (hasAplicaciones == "Sí" || hasComprobanteAplicaciones == "Sí")
                                                            );
                                                }
                                            },
                                            {
                                                templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
                                                functionName: 'reenviar',
                                                customAttribute: ['estado', 'enviadoAContabilidad', 'id'],
                                                functionDef: function(atributo) {

                                                    var idVenta = atributo[2];

                                                    ComprobanteFactory.get(idVenta, "ComprobanteList").$promise.then(function (
                                                        response
                                                        ) {
                                                        $scope.tituloModal = $filter('translate')('REENVIAR_COMPRA_Q');
                                                        $scope.mensajeModal = $filter('translate')('REENVIAR_COMPRA_MSG');
                                                        var modalInstance = $modal.open({
                                                            templateUrl: 'views/directives/confirmation-modal.html',
                                                            scope: $scope
                                                        });

                                                        $scope.cancel = function() {
                                                            modalInstance.dismiss('cancel');
                                                        };

                                                        $scope.ok = function(id) {
                                                            ComprobanteFactory.reenviar(response.id).then(function (response) {

                                                                console.log('-----response------');
                                                                console.log(response);

                                                                // ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                                                //     if (parametro.valorTipo.codigo == 'si') {
                                                                //       if(response.enviadoAContabilidad == false){
                                                                //         notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                                //       }
                                                                //       if(response.listaArchivosLogs != null){
                                                                //         if(response.listaArchivosLogs.length != 0){
                                                                //           /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                                //               console.log('response.listaArchivosLogs: ');
                                                                //               console.log(response.listaArchivosLogs[i]);
                                                                //               $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                                //           }*/
                                                                //           notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                                //         }
                                                                //       }
                                                                //     }
                                                                // });
                                                                $state.go($state.current, {}, {reload: true});
                                                            }, function(error) {
                                                                var msg = "";
                                                                if (error.data && error.data.length > 0 && error.data[0].message) {
                                                                    msg = error.data[0].message;
                                                                } else {
                                                                    msg = $filter('translate')('REENVIAR_COMPRA_FAILED');
                                                                }

                                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                            });
                                                            modalInstance.close();
                                                        }
                                                    });
                                                },
                                                conditionName: 'canReenviar',
                                                conditionDef: function(atributo) {
                                                    var estado = atributo[0];
                                                    var enviadoAContabilidad = atributo[1];
                                                    var id = atributo[2];

                                                    if(enviarAContabilidad == 'si'){
                                                        if(estado.codigo === 'comp_aprobado'){
                                                            if(enviadoAContabilidad === "No"){
                                                                return true;
                                                            }
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='" +
                                                        "Modificación de Comprobantes Aprobados" +
                                                        "' ng-click='editarAprobados(<%=dataId%>)' ng-class='{ hidden : !canEditarAprobados(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                                                functionName: "editarAprobados",
                                                functionDef: function (itemId) {
                                                    ComprobanteFactory.get(itemId, "ComprobanteForm").$promise.then(function (response) {
                                                        if (response) {
                                                            checkPeriodoAdministrativo(response.fecha).then(function (checkPeriodo) {
                                                                if (checkPeriodo == true) {
                                                                    $state.go("app.consultacomprobantes.editAprobado", {id: itemId});
                                                                }else{
                                                                    var msg = $filter('translate')('NO_OPEN_PERIODS_COM');
                                                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                                }
                                                            })
                                                        }
                                                    })
                                                },
                                                conditionName: "canEditarAprobados",
                                                customAttribute: ["estado"],
                                                conditionDef: function (atributos) {
                                                    var estado = atributos[0];
                                                    return (
                                                            (vm.fromOutside && AuthorizationService.hasPermission("update_comprobantes_aprobados") &&
                                                                    estado.codigo == "comp_aprobado")
                                                            );
                                                }
                                            }





                                        ]
                                    };
                                    WatchLocacionChangeFactory.watchWith(
                                            staticFilter,
                                            vm.options,
                                            "locacion.id"
                                            );

                                    /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
                                    var checkPeriodoAdministrativo = function (fechaComprobante) {
                                        return $q(function (resolve, reject) {

                                            var params = {};
                                            var fecha;

                                            if (fechaComprobante == null) {
                                                fecha = new Date();
                                            } else {
                                                fecha = fechaComprobante;
                                            }

                                            params.search = filterFactory
                                                    .and([{
                                                            path: "estado.codigo",
                                                            equals: "per_adm_abierto"
                                                        },
                                                        {
                                                            path: "fechaDesde",
                                                            equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                                                        },
                                                        {
                                                            path: "fechaHasta",
                                                            equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                                                        }
                                                    ])
                                                    .value();

                                            PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {
                                                if (responsePeriodo.length > 0) {
                                                    resolve(true);
                                                } else {
                                                    resolve(false);
                                                }
                                            })
                                        });
                                    }
                                });

                        var getIDs = function () {
                            var ids = [];
                            _.forEach(vm.options.selection, function (n, key) {
                                if (n)
                                    ids.push(key);
                            });
                            return ids;
                        };


                    }

                    function hasPermission(permission) {
                        var user = AuthenticationService.getCurrentUser();
                        var permissions = [];

                        if (user) {
                            permissions = user.permissions || [];
                        }
                        return permissions.indexOf(permission) >= 0;
                    }

                    var createFilters = function (filters, staticFilters) {
                        var filtersArr = [];
                        var filtersC = _.cloneDeep(filters);
                        var staticFiltersC = _.cloneDeep(staticFilters);
                        _.each(filters, function (search, data) {
                            search = search.split("~");
                            if (search.length > 1) {
                                /* El parámetro es un rago */

                                var isDate = search[0].split("/");

                                if (isDate.length > 1) {
                                    /* Es un rago de fechas */

                                    filtersArr.push({path: data, equalOrAfter: search[0]})
                                    filtersArr.push({path: data, equalOrBefore: search[1]})
                                } else {
                                    /* El un rago numérco */

                                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                                    filtersArr.push({path: data, lessOrEqual: search[1]})
                                }
                            } else {
                                /* El parametro no es un rago */
                                filtersArr.push({path: data, like: search[0]})
                            }
                        });
                        if (staticFiltersC) {
                            // entrar al OR y por cada AND agregar todos los filtros de datatable
                            var entro = false;
                            var staticFiltersAnds = staticFiltersC.search.filters;
                            _.each(staticFiltersAnds, function(staticFilter) {
                                if (staticFilter.filters) {
                                    _.each(filtersArr, function(dtFilter) {
                                        staticFilter.filters.push(dtFilter);
                                    });
                                } else {
                                    if (staticFilter.equals != undefined) {
                                        filtersArr.push({ path: staticFilter.path, equals: staticFilter.equals });
                                    } else if (staticFilter.like != undefined) {
                                        filtersArr.push({ path: staticFilter.path, like: staticFilter.like });
                                    } else if (staticFilter.equalOrBefore != undefined) {
                                        filtersArr.push({ path: staticFilter.path, equalOrBefore: staticFilter.equalOrBefore });
                                    } else if (staticFilter.equalOrAfter != undefined) {
                                        filtersArr.push({ path: staticFilter.path, equalOrAfter: staticFilter.equalOrAfter });
                                    } else if (staticFilter.greaterThan != undefined) {
                                        filtersArr.push({ path: staticFilter.path, greaterThan: staticFilter.greaterThan });
                                    } else if (staticFilter.lessThan != undefined) {
                                        filtersArr.push({ path: staticFilter.path, lessThan: staticFilter.lessThan });
                                    } else if (staticFilter.sortDesc != undefined) {
                                        filtersArr.push({ path: staticFilter.path, sortDesc: staticFilter.sortDesc });
                                    } else if (staticFilter.sortAsc != undefined) {
                                        filtersArr.push({ path: staticFilter.path, sortAsc: staticFilter.sortAsc });
                                    } else if (staticFilter.isNull != undefined) {
                                        filtersArr.push({ path: staticFilter.path, isNull: staticFilter.isNull });
                                    } else if (staticFilter.isNotNull != undefined) {
                                        filtersArr.push({ path: staticFilter.path, isNotNull: staticFilter.isNotNull });
                                    } else if (staticFilter.notEquals != undefined) {
                                        filtersArr.push({ path: staticFilter.path, notEquals: staticFilter.notEquals });
                                    } else if (staticFilter.greaterOrEqual != undefined) {
                                        filtersArr.push({ path: staticFilter.path, greaterOrEqual: staticFilter.greaterOrEqual });
                                    } else if (staticFilter.lessOrEqual != undefined) {
                                        filtersArr.push({ path: staticFilter.path, lessOrEqual: staticFilter.lessOrEqual });
                                    }
                                    entro = true;
                                }
                            });
                            if (entro == true) {
                                filtersC = filterFactory.and(filtersArr).value();
                            } else {
                                filtersC = filterFactory.or(staticFiltersAnds).value();
                            }
                        } else {
                            filtersC = filterFactory.and(filtersArr).value();
                        }
                        return filtersC;
                    }
                }

'use strict';

angular.module('qualita.compra')
  .controller('FormaPagoListCtrl', ["AuthorizationService", "FormaPagoFactory", "TiposFactory", "$log", "$modal", "$scope", "CompraLocalLangFactory", "$filter", "filterFactory", "ReportTicketFactory", "$window", "UtilFactory", "CsvFactory", "baseurl", function (AuthorizationService, FormaPagoFactory, TiposFactory,
  $log, $modal, $scope, CompraLocalLangFactory, $filter, filterFactory, ReportTicketFactory, $window,
  UtilFactory, CsvFactory, baseurl) {

  var defaultColumnOrder = ['id','descripcion','clase.descripcion', 'utilizarEnCobros'];
  CompraLocalLangFactory.getTranslations().then(function(translations) {
    $scope.options = {
      'resource': 'formapago',
      'title': 'Formas de Pago',
      'view': 'FormaPagoList',
      'factory': FormaPagoFactory,
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS'),
      'columns': [
        {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
        {'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION')},
        {'data': 'clase.descripcion', 'title': $filter('translate')('KIND')},
        {'data': 'utilizarEnCobros', 'title': $filter('translate')('UTILIZAR_EN_COBROS'), 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'}
      ],
      'hasOptions' : true,
      'defaultOrderColumn' : 0,
      'defaultOrderDir' : "desc",
      'extraMenuOptions':
        [
          {
          'title': 'Reporte',
          'icon': 'glyphicon glyphicon-file',
          'showCondition': function() { return true; },
          'action': function() {
              var filters = createFilters($scope.options.getFilters());
              ReportTicketFactory.ticket('listadoFormaPago', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
          },

          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("formapago", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          }
        ]
    };
  });

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      if(search === 'No' && data === 'utilizarEnCobros'){
        search = 'false';
    }
    if(search === 'Sí' && data === 'utilizarEnCobros'){
        search = 'true';
    }
      filtersArr.push({path: data, like: search})
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }

}]);

'use strict';

angular.module('qualita.compra')
  .controller('FormaPagoFormCtrl', FormaPagoFormCtrl);

FormaPagoFormCtrl.$inject = ['$rootScope', '$scope', 'FormaPagoFactory', '$state', 'filterFactory',
  'formaPagoPrepService', 'notify', 'VentasLangFactory', 'TiposFactory', '$filter', 'TiposTipoFactory', 'CobroDetalleFactory'];

function FormaPagoFormCtrl($rootScope, $scope, FormaPagoFactory, $state, filterFactory,
  formaPagoPrepService, notify, VentasLangFactory, TiposFactory, $filter, TiposTipoFactory, CobroDetalleFactory) {


  var vm = this;

  activate();

  vm.cancel = cancel;
  vm.submit = submit;
  vm.changeClase = changeClase;
  vm.changeCantidadCuotas = changeCantidadCuotas;
  vm.changeTipoTarjeta = changeTipoTarjeta;
  vm.changeTipoOperacion = changeTipoOperacion;

  function activate() {
    TiposFactory.clasesFormaDePago().then(function (data) {
      vm.clasesFormasPagos = data.data;
    });
    vm.tiposTarjeta = TiposTipoFactory.get("tipos_tarjetas");

    vm.tipoOperacion = [{
      id: "1",
      codigo: "contado",
      descripcion: "Contado"
    },{
      id: "2",
      codigo: "credito",
      descripcion: "Crédito"
    }]
    VentasLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      if ($state.is("app.formapago.new")) {
        activateNew();
      } else if ($state.is("app.formapago.edit")) {
        activateEdit();
      } else if ($state.is("app.formapago.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateNew() {
    vm.title = 'Nueva Forma de Pago';
    vm.formaPago = {};
    vm.formaPago.utilizarEnCobros = false;
  }

  function activateEdit() {
    vm.edit = true;
    vm.title = 'Editar Forma de Pago';
    vm.formaPago = formaPagoPrepService;
    vm.formaPago.tipoOperacion = vm.formaPago.cantidadCuotas ? vm.tipoOperacion[1] : vm.tipoOperacion[0];
    vm.entidadId = vm.formaPago.id;
    vm.entidad = "formaPago";
    if (vm.formaPago.clientes != undefined && vm.formaPago.clientes.length > 0 && vm.formaPago.clase.codigo == 'forma_pago_referencia') {
      vm.isClienteAsociado = true;
    } else {
      vm.isClienteAsociado = false;
    }
    if (vm.formaPago.utilizarEnCobros == false) {
      vm.isClaseReferencia = true;
    } else {
      vm.isClaseReferencia = false;
    }

    if (vm.formaPago.clase.codigo == 'forma_pago_tarjeta') {
      vm.esTarjeta = true;
    } else {
      vm.esTarjeta = false;
    }
  }

  function activateView() {
    vm.title = 'Ver Forma de Pago';
    vm.formaPago = formaPagoPrepService;
    if(vm.formaPago.cantidadCuotas){
      vm.formaPago.tipoOperacion = vm.tipoOperacion[1]
    }
    vm.entidadId = vm.formaPago.id;
    vm.entidad = "formaPago";
    vm.view = true;
  }

  function submit() {
    vm.submited = true;

    if ($scope.FormaPagoForm.$valid) {
      $rootScope.isProcessing = true;

      submitFormaPago().then(function () {
        $state.go('app.formapago.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        console.log(error);
        var msg = "";
        _.forEach(error.data, function (e) {
          if (e.constraint == 'descripcion') {
            msg += '\n\n' + $filter('translate')('FORMA_PAGO_MISMA_DESCRIPCION_WARNING') + "\n";
          } else {
            msg += '\n\n' + e.message + '.';
          }
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function submitFormaPago() {
    var resource = FormaPagoFactory.create(vm.formaPago);
    return FormaPagoFactory.save(resource);
  }

  function cancel() {
    $state.go('app.formapago.list');
  }

  function changeClase() {
    if (vm.formaPago.clase.codigo == 'forma_pago_referencia') {
      vm.formaPago.utilizarEnCobros = false;
      vm.isClaseReferencia = true;
      vm.formaPago.cantidadCuotas = null;
      vm.formaPago.plazoDias = null;
      vm.formaPago.tipoOperacion = vm.tipoOperacion[1];
    } else {
      vm.isClaseReferencia = false;
    }
    if (vm.formaPago.clase.codigo == 'forma_pago_tarjeta') {
      vm.esTarjeta = true;
    } else {
      vm.esTarjeta = false;
    }
  }

  function changeCantidadCuotas() {
    if (vm.formaPago.cantidadCuotas != null && vm.formaPago.cantidadCuotas != undefined && vm.formaPago.cantidadCuotas > 0) {
      vm.isCantidadCuotasNotNull = true;
    } else {
      vm.isCantidadCuotasNotNull = false;
    }
  }

  function changeTipoTarjeta() {
    if (vm.edit) {
      var filtroCobroDetalle = {};
      filtroCobroDetalle.search = filterFactory
        .and([
          {
            path: "formaPago.id",
            equals: vm.formaPago.id
          }
        ])
        .value();
      CobroDetalleFactory.all(filtroCobroDetalle, "FormaPagoForm").$promise.then(function (response) {
        console.log("pp ", response)
        if (response.length > 0) {
          notify({ message: "No se puede modificar tipo de tarjeta. La forma de pago esta asociada a cobros", classes: 'alert-danger', position: 'right' });
          FormaPagoFactory.get(vm.formaPago.id, "BaseList").$promise.then(function (fp) {
            vm.formaPago.tipoTarjeta = fp.tipoTarjeta;
          });
        }
      });
    }
  }

  function changeTipoOperacion() {
    if(vm.formaPago.tipoOperacion.codigo == 'contado'){
      vm.isCantidadCuotasNotNull = false;
      vm.formaPago.cantidadCuotas = null;
      vm.formaPago.plazoDias = null;
    }
  }


  $scope.resource = 'formapago';
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.compra')
    .factory('FormaPagoFactory', FormaPagoFactory);

FormaPagoFactory.$inject = ['$resource', 'baseurl', '$http', 'TiposFactory', 'CompraLocalLangFactory', '$filter'];

function FormaPagoFactory($resource, baseurl, $http, TiposFactory, CompraLocalLangFactory, $filter) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove,
        schema: schema,
        form: form
    };

    var FormaPago = $resource(baseurl.getBaseUrl() + "/formapago/:id", { id: '@id' }, {
        update: {
            method: 'PUT'
        }
    });

    var schema = undefined;

    CompraLocalLangFactory.getTranslations().then(function(translations) {
        schema = {
            type: 'object',
            title: 'Forma de Pago',
            properties: {
                descripcion: {
                    title: $filter('translate')('DESCRIPTION'),
                    type: 'string',
                    maxLength: 255,
                    validationMessage: {
                        201: $filter('translate')('LONG_VALUE_WARNING')
                    },
                },
                clase: {
                    title: translations.KIND,
                    type: 'number',
                    validationMessage: translations.REQUIRED_FIELD
                },
                utilizarEnCobros: {
                    title: $filter('translate')('UTILIZAR_EN_COBROS'),
                    type: 'boolean'
                }
            },
            required: ['descripcion', 'clase']
        };
    });

    var formNew = [
        'descripcion',
        {
            key: 'clase',
            //placeholder: 'Seleccione una moneda',
            type: 'uiselect',
            options: {
                asyncCallback: function(options, search) {
                    var params = {};
                    if (search) {
                        params.search = filterFactory.single({
                            path: 'descripcion',
                            like: search
                        }).value();
                    }
                    return TiposFactory.claseFormasPago().then(function(data) {
                        return { data: data.data };
                    });
                },
                map: { valueProperty: 'id', nameProperty: 'descripcion' },
                formName: 'formapagoForm'
            }
        },
        {
            key: 'utilizarEnCobros',
            type: 'customcheckbox'
        }
    ];

    var formEdit = angular.copy(formNew);
    var formView = angular.copy(formNew);

    var forms = {
        'new': formNew,
        'edit': formEdit,
        'view': formView
    }

    return service;

    function all(params, view) {
        if (params) {
            params.view = view != undefined ? view : 'BaseList';
          } else {
            params = [];
            params.view = view != undefined ? view : 'BaseList';
          }
        return FormaPago.query(params);
    }

    function get(id, view) {
        return FormaPago.get({ id: id, view: view ? view : "base" });
    }

    function create(attrs) {
        return new FormaPago(attrs);
    }

    function save(formapago) {
        return (formapago.id) ? formapago.$update() : formapago.$save();
    }

    function remove(formapago) {
        return formapago.$remove();
    }


    function schema() {
        return schema;
    }

    function form(type) {
        return forms[type];
    }

}
'use strict';

angular.module('qualita.compra')
  .controller('ProyectoListCtrl', ["AuthorizationService", "ProyectoFactory", "TiposFactory", "$log", "$modal", "$scope", "CompraLocalLangFactory", "$filter", "filterFactory", "ReportTicketFactory", "$window", "UtilFactory", "CsvFactory", "baseurl", function (AuthorizationService, ProyectoFactory, TiposFactory,
  $log, $modal, $scope, CompraLocalLangFactory, $filter, filterFactory, ReportTicketFactory, $window,
  UtilFactory, CsvFactory, baseurl) {

  var defaultColumnOrder = ['id','nombre','descripcion'];
  CompraLocalLangFactory.getTranslations().then(function(translations) {
    $scope.options = {
      'resource': 'proyecto',
      'title': 'Proyecto',
      'factory': ProyectoFactory,
      'view': 'ProyectoList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS'),
      'columns': [
        {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
        {'data': 'nombre', 'title': $filter('translate')('NAME')},
        {'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION')},
      ],
      'hasOptions' : true,
      'defaultOrderColumn' : 0,
      'defaultOrderDir' : "asc",
      'extraMenuOptions':
        [
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function() { return true; },
            'action': function() {
              var filters = createFilters($scope.options.getFilters());
              ReportTicketFactory.ticket('listadoProyecto', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("proyecto", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          }
        ]
    };
  });

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({path: data, like: search})
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }

}]);

'use strict';

angular.module('qualita.compra')
  .controller('ProyectoFormCtrl', ["ProyectoFactory", "filterFactory",
  "$modal", "$scope", "notify", "$state", "$rootScope","proyectoPrepService", "$controller", "formFactory",
    "CompraLocalLangFactory", "$filter",
  function (ProyectoFactory, filterFactory, $modal, $scope, notify, $state, $rootScope,
    proyectoPrepService, $controller, formFactory, CompraLocalLangFactory, $filter) {

      $scope.resource = 'proyecto';
      $scope.resources = 'proyecto';
      $scope.factory = ProyectoFactory;
      $scope.prepService =  proyectoPrepService;
      var self = this;
      CompraLocalLangFactory.getTranslations().then(function(translations) {
        $scope.newProperties = {
          state: "app.proyecto.new",
          title: $filter('translate')('NEW_PROYECTO'),
        };
        $scope.editProperties = {
          state: "app.proyecto.edit",
          title: $filter('translate')('EDIT_PROYECTO'),
          entidad: "Proyecto"
        };

        $scope.viewProperties = {
          state: "app.proyecto.view",
          title: $filter('translate')('VIEW_PROYECTO'),
          entidad: "Proyecto"
        };

        angular.extend(self, $controller('BasicController', {$scope: $scope}));
        $scope.activate();

        $scope.submit = function(form) {
          formFactory.defaultSubmit('proyecto', $scope, form, ProyectoFactory, undefined, errorHandler);
        };

        function errorHandler(e) {
          var msg = '';
          _.forEach(e.data, function(error) {
            msg += '\n\n' + error.message + '.';
          });
          notify({
            message: msg,
            classes: 'alert-danger',
            position: 'right'
          });
        }
      });
}]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.compra')
  .factory('ProyectoFactory', ProyectoFactory);

ProyectoFactory.$inject = ['$resource', 'baseurl', '$http','TiposFactory', 'CompraLocalLangFactory', '$filter'];

function ProyectoFactory($resource, baseurl, $http,TiposFactory, CompraLocalLangFactory, $filter) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    schema: schema,
    form: form
  };

  var Proyecto = $resource( baseurl.getBaseUrl() + "/proyecto/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  var schema = undefined;

  CompraLocalLangFactory.getTranslations().then(function(translations) {
    schema = {
      type: 'object',
      title: 'Proyecto',
      properties: {
        nombre: {
          title: $filter('translate')('NAME'),
          type: 'string',
          maxLength: 255,
          validationMessage: {
            201: $filter('translate')('LONG_VALUE_WARNING')
          },
        },
        descripcion: {
          title: $filter('translate')('DESCRIPTION'),
          type: 'string',
          maxLength: 255,
          validationMessage: {
            201: $filter('translate')('LONG_VALUE_WARNING')
          },
        },
        mostrarDescripcionEnOC: {
          title: $filter('translate')('VIEW_DESCRIPCION_IN_OC'),
          type: 'boolean'
        }
      },
      required: ['nombre', 'descripcion']
    };
  });

    var formNew = [
      'nombre',
      'descripcion',
      { key: 'mostrarDescripcionEnOC', type: 'customcheckbox' }
    ];

    var formEdit = angular.copy(formNew);
    var formView = angular.copy(formNew);

    var forms = {
      'new': formNew,
      'edit': formEdit,
      'view': formView
    }

  return service;

  function all(params, view) {

    if (params) {
      params.view = view!=undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'BaseList';
    }

    return Proyecto.query(params);
  }

  function get(id, view) {
    return Proyecto.get({id: id, view: view ? view : "BaseList"});
  }

  function create(attrs) {
    return new Proyecto(attrs);
  }

  function save(proyecto) {
    return (proyecto.id) ? proyecto.$update() : proyecto.$save();
  }

  function remove(proyecto) {
    return proyecto.$remove();
  }


 function schema() {
    return schema;
  }

  function  form(type) {
    return forms[type];
  }

}

angular.module('qualita.compra')
    .factory('CompraFactory', CompraFactory);

CompraFactory.$inject = ['$resource', 'baseurl', '$http', '$q'];

function CompraFactory($resource, baseurl, $http, $q) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove,
        getIdCompra: getIdCompra,
        setIdCompra: setIdCompra,
        getCodigoCompra: getCodigoCompra,
        setCodigoCompra: setCodigoCompra,
        getCompra: getCompra,
        setCompra: setCompra,
        getEnabledStates: getEnabledStates,
        setEnabledStates: setEnabledStates,
        checkPermissionForState: checkPermissionForState,
        getDiferencia: getDiferencia,
        getSugerenciasOC: getSugerenciasOC,
        getSugerenciasRecepcion: getSugerenciasRecepcion,
        getSugerenciasComprobante: getSugerenciasComprobante,
        cerrar: cerrar,
        anular: anular,
        diferencia: diferencia,
        getTotales: getTotales,
        recuperarPorId: recuperarPorId,
        getLibroCompraCSV: getLibroCompraCSV
    };

    var Compra = $resource(baseurl.getBaseUrl() + "/compra/:id", { id: '@id' }, {
        update: {
            method: 'PUT'
        },
        anular: {
            method: 'PUT',
            url: baseurl.getBaseUrl() + "/compra/anular/:id"
        }
    });

    var idCompra = 1;
    var codigoCompra;
    var compra;
    var enabledStates;

    return service;

    function all(params, view) {
        if (params) {
          params.view = view!=undefined ? view : 'base';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'base';
        }
        return Compra.query(params);
    }

    function recuperarPorId(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/recepcion/recuperar/" + id,
            method: "GET"
        });
    }

    function get(id, view) {
        return Compra.get({ id: id, view: view ? view : "Base" });
    }

    function create(attrs) {
        return new Compra(attrs);
    }

    function save(compra) {


        console.log("esta compra es la que vino ",  compra)

        return (compra.id) ? compra.$update() : compra.$save();
    }

    function remove(compra) {
        return compra.$remove();
    }

    function cerrar(id, cerrarOCParam) {
        return $http({
            url: baseurl.getBaseUrl() + '/compra/cerrar/' + id,
            params: { cerrarOC: cerrarOCParam },
            method: "PUT"
        });
    }

    function diferencia(id) {
        return $http({
            url: baseurl.getBaseUrl() + '/compra/diferencia/' + id,
            params: { view: "CompraList" },
            method: "PUT"
        });
    }

    function anular(id) {
        return Compra.anular({ id: id });
    }

    function getDiferencia(id) {
        return $http({
            url: baseurl.getBaseUrl() + '/compra/diferencias/' + id,
            method: "GET"
        });
    }

    function getSugerenciasOC(id, componente) {
        return $http({
            url: baseurl.getBaseUrl() + '/compra/sugerenciasoc/' + id,
            method: "GET",
            params: { componente: componente }
        });
    }

    function getSugerenciasRecepcion(id) {
        return $http({
            url: baseurl.getBaseUrl() + '/compra/sugerenciasrecepcion/' + id,
            method: "GET"
        });
    }

    function getSugerenciasComprobante(id) {
        return $http({
            url: baseurl.getBaseUrl() + '/compra/sugerenciascomprobante/' + id,
            method: "GET"
        });
    }

    function getIdCompra() {
        return idCompra;
    }

    function setIdCompra(id) {
        idCompra = id;
    }

    function getCodigoCompra() {
        return codigoCompra;
    }

    function setCodigoCompra(codigo) {
        codigoCompra = codigo;
    }

    function getCompra() {
        updateStates();
        return compra;
    }

    function getEnabledStates() {
        return enabledStates;
    }

    function setEnabledStates(states) {
        enabledStates = states;
    }

    function setCompra(cmp) {
        if (cmp.id) {
            compra = cmp;
        } else {
            compra = get(cmp, "CompraList").$promise;
        }
        updateStates();
    }

    function checkPermissionForState(estadoIndex) {
        return enabledStates[estadoIndex];
    }

    function updateStates() {
        if (compra && compra.estado && compra.estado.codigo && compra.tipo) {
            var tipo = compra.tipo;
            enabledStates = _.map(tipo.tipoCompraDetalles, function(detalle) {
                return false;
            });
            var tipoCompraDetalle = undefined;
            if (compra.estado.codigo == "compra_abierta" || compra.estado.codigo == "compra_anulada") {
                enabledStates[0] = true;
            } else if (compra.estado.codigo == "compra_con_oc") {
                var tipoCompraDetalle = _.find(tipo.tipoCompraDetalles, function(detalle) {
                    return detalle.componente.codigo == "comp_tipo_compra_orden_compra";
                });
            } else if (compra.estado.codigo == "compra_con_recepcion") {
                var tipoCompraDetalle = _.find(tipo.tipoCompraDetalles, function(detalle) {
                    return detalle.componente.codigo == "comp_tipo_compra_recepcion";
                });
            } else if (compra.estado.codigo == "compra_con_comprobante") {
                var tipoCompraDetalle = _.find(tipo.tipoCompraDetalles, function(detalle) {
                    return detalle.componente.codigo == "comp_tipo_compra_comprobante";
                });
            }
            if (tipoCompraDetalle) {
                enabledStates[tipoCompraDetalle.orden] = true;
                for (var i = 0; i < tipoCompraDetalle.orden; i++) {
                    enabledStates[i] = true;
                }
            } else if ((compra.estado.codigo == "compra_cerrada") || (compra.estado.codigo == "compra_anulada")){
                enabledStates = _.map(enabledStates, function(e) { return true; });
                enabledStates[enabledStates.length] = true;
            }
        }
    }

    function getLibroCompraCSV(searchParams) {
        var params = {};
        if (searchParams) {
            params.query = decodeURIComponent($.param(searchParams));
         }

        return $http.post(baseurl.getBaseUrl() + "/librocompras/csv?" + params.query);
      }

    function getTotales(filtro) {
        return $http({
            url: baseurl.getBaseUrl() + '/compra/totalcantidad/',
            method: "GET",
            params: { filtros: filtro }
        });
    }
}
"use strict";

angular
    .module("qualita.compra")
    .controller("ConsultaDiferenciaIndexCtrl", ["$scope", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "OrdenesCompraFactory", "ComprobanteFactory", "RecepcionFactory", "CompraLocalLangFactory", "CompraFactory", "$rootScope", "$filter", "notify", function (
        $scope,
        filterFactory,
        AuthorizationService,
        ReportTicketFactory,
        $modal,
        $window,
        $state,
        OrdenesCompraFactory,
        ComprobanteFactory,
        RecepcionFactory,
        CompraLocalLangFactory,
        CompraFactory,
        $rootScope,
        $filter,
        notify
    ) {
        // console.log("ConsultaDiferenciaIndexCtrl");
        CompraLocalLangFactory.getTranslations();
        var vm = this;
        vm.cancel = cancel;
        vm.submit = submit;
        vm.submitQuestion = submitQuestion;
        vm.generateReport = generateReport;
        vm.tieneDiferencias = tieneDiferencias;
        vm.diferencia = false;
        vm.submitQuestionOrdenCompra = submitQuestionOrdenCompra;

        vm.compra = CompraFactory.getCompra();
        if (!vm.compra) {
            $state.go("app.main");
        }
        var tipo = vm.compra.tipo;

        function tieneDiferencias(detalle) {

            var tieneRecepcion = false;
            var tieneOC = false;
            var detalles = tipo.tipoCompraDetalles;
            _.forEach(tipo.tipoCompraDetalles, function (tipo) {
                if (tipo.componente.codigo === 'comp_tipo_compra_recepcion') {
                    tieneRecepcion = true;
                }
                if (tipo.componente.codigo === 'comp_tipo_compra_orden_compra') {
                    tieneOC = true;
                }
            });
            if (!tieneRecepcion &&
                !tieneOC &&
                vm.compra.tipo.tipoCompraDetalles.length == 1
            ) {
                return false;
            } else if (!tieneRecepcion &&
                !tieneOC &&
                vm.compra.tipo.tipoCompraDetalles.length > 1
            ) {
                return true;
            } else if (tieneRecepcion && tieneOC) {
                if (
                    detalle.cantidadRecepcion != undefined &&
                    detalle.servicio == undefined &&
                    detalle.cantidadRecepcion != detalle.cantidadOC
                ) {
                    vm.diferencia = true;
                    return true;
                } else if (
                    detalle.cantidadRecepcion != undefined &&
                    detalle.servicio == undefined &&
                    detalle.cantidadRecepcion != detalle.cantidadComprobante
                ) {
                    vm.diferencia = true;
                    return true;
                } else if (detalle.cantidadOC != detalle.cantidadComprobante) {
                    vm.diferencia = true;
                    return true; // cuando es con OC solo verifica la igual entre comprobante y OC
                    // no recepcion porque no es obligatoria
                } else if (detalle.costoOC != detalle.costoComprobante) {
                    vm.diferencia = true;
                    return true;
                }
                return false;
            } else if (tieneRecepcion && !tieneOC) {
                if (
                    detalle.servicio == undefined &&
                    detalle.cantidadComprobante != detalle.cantidadRecepcion
                ) {
                    vm.diferencia = true;
                    return true;
                }
                return false;
            } else if (!tieneRecepcion && tieneOC) {

                //Comparacion de cantidades entre comprobante y OC
                if (
                    //detalle.servicio == undefined &&
                    detalle.cantidadComprobante != detalle.cantidadOC
                ) {
                    vm.diferencia = true;
                    return true;
                }

                //Comparacion de costos entre comprobante y OC
                if (
                    detalle.costoComprobante != detalle.costoOC
                ) {
                    vm.diferencia = true;
                    return true;
                }

                return false;
            } else {
                return false;
            }
        }

        $rootScope.isProcessing = false;

        var dateRender = function (data, type, full) {
            if (data) {
                return moment.utc(data).format("DD/MM/YYYY");
            } else {
                return "";
            }
        };

        var emptyDataRender = function (data) {
            if (data == undefined) return "";
            else return data;
        };

        var tipoCompraDetalle = _.max(tipo.tipoCompraDetalles, function (detalle) {
            return detalle.orden;
        });
        if (!tipoCompraDetalle) {
            $state.go("app.main");
        }
        var isStateEnabled = CompraFactory.checkPermissionForState(
            tipoCompraDetalle.orden
        );
        if (!isStateEnabled) {
            $state.go("app.main");
        }

        $scope.totalOC = 0;
        $scope.totalComp = 0;

        CompraFactory.getDiferencia(vm.compra.id).then(function (response) {
            $scope.list = response.data;
            _.forEach($scope.list, function (detalle) {
                $scope.totalOC = $scope.totalOC + detalle.costoOC;
                $scope.totalComp = $scope.totalComp + detalle.costoComprobante;
            });
        });


        var ordenCompraFiltro = undefined;
        var comprobanteFiltro = undefined;
        var recepcionFiltro = undefined;

        var createFilters = function (filters) {
            var filtersArr = [];
            _.each(filters, function (search, data) {
                filtersArr.push({ path: data, like: search });
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        };

        function cancel() {
            $state.go("app.compraslocales.list");
        }

        function generateReport() {
            var filtersArr = [];
            filtersArr.push("id_compra=" + vm.compra.id);
            filtersArr.push(
                "id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id
            );
            filtersArr.push("id_un=" + $rootScope.AuthParams.un.id);
            filtersArr.push("id_s=" + $rootScope.AuthParams.suc.id);
            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                "cierrecompra",
                "pdf",
                filtersArr
            );
            $window.open(vm.pdfDownloadURL, "_blank");
            $state.go($state.current, {}, { reload: true });
        }

        function submitQuestion() {

            $scope.tituloModal = $filter("translate")("CERRAR_COMPRA_TITLE");
            $scope.mensajeModal = $filter("translate")("CERRAR_COMPRA_MESSAGE");
            $scope.si_no = true;
            var modalInstance = $modal.open({
                template: '<div class="modal-header">' +
                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                    '</div>' +
                    '<div class="modal-body">{{::mensajeModal}}' +
                    '</div>' +
                    '<div class="modal-footer">' +
                    '<button class="btn btn-primary" ng-click="ok(selectedItemId);">' + $filter('translate')('OK') + '</button>' +
                    '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                    '</div>',
                scope: $scope
            });

            $scope.cancel = function () {
                modalInstance.dismiss("cancel");
            };

            $scope.ok = function (itemId) {
                modalInstance.close();
                submitQuestionOrdenCompra();
            };
        }

        function submitQuestionOrdenCompra() {
            if (
                _.find(vm.compra.tipo.tipoCompraDetalles, function (tcdetalle) {
                    return tcdetalle.componente.codigo == "comp_tipo_compra_orden_compra";
                })
            ) {
                $scope.tituloModal = $filter("translate")("CERRAR_OC_CON_COMPRA");
                $scope.mensajeModal = $filter("translate")("CERRAR_OC_CON_COMPRA_MSG");
                $scope.si_no = true;
                var modalInstance = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        '</div>' +
                        '<div class="modal-body">{{::mensajeModal}}' +
                        '</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="ok(selectedItemId);">' + $filter('translate')('OK') + '</button>' +
                        '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                        '</div>',
                    scope: $scope
                });

                $scope.cancel = function () {
                    modalInstance.dismiss("cancel");
                    $scope.cerrarOC = false;
                    submit();
                };

                $scope.ok = function (itemId) {
                    $scope.cerrarOC = true;
                    modalInstance.close();
                    submit();
                };

            } else {
                $scope.cerrarOC = false;
                submit();
            }
        }

        function submit() {
            vm.submited = true;
            if ($scope.CierreCompraForm.$valid) {
                $rootScope.isProcessing = true;
                submitCierre().then(
                    function (response) {
                        $state.go("app.compraslocales.list");
                        return response;
                    },
                    function (error) {
                        $rootScope.isProcessing = false;
                        notify({
                            message: error.data[0].message,
                            classes: "alert-danger",
                            position: "right"
                        });
                    }
                );
            } else {
                $rootScope.isProcessing = false;
                notify({
                    messageTemplate: errors,
                    classes: "alert-danger",
                    position: "right"
                });
            }
        }

        function submitCierre() {
            return CompraFactory.cerrar(CompraFactory.getIdCompra(), $scope.cerrarOC);
        }
    }]);
'use strict';


angular.module('qualita.compra')
    .controller('ConsultaLibroCompraIndexCtrl',
        ["$scope", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "CompraLocalLangFactory", "ProveedoresFactory", "ProveedorSucursalesFactory", "$filter", "notify", "$rootScope", "CompraFactory", "baseurl", "TiposFactory", "TipoComprobanteFactory", function($scope, filterFactory, AuthorizationService, ReportTicketFactory, $modal, $window, $state, CompraLocalLangFactory,
            ProveedoresFactory, ProveedorSucursalesFactory, $filter, notify, $rootScope, CompraFactory, baseurl, TiposFactory, TipoComprobanteFactory) {

            CompraLocalLangFactory.getTranslations().then(function(translations) {
                $scope.translations = translations;
            });

            // Inicializacion de variables
            $scope.mostrarCabecera = true;
            $scope.mostrarNroRubrica = false;
            this.tiposReporte = [
            { id:-1, codigo: "formato_simple", descripcion: "Formato Simple" },
            { id:0, codigo: "formato_impositivo", descripcion: "Formato Impositivo" }
            ];
            $scope.tipoReporte = {
            codigo: "formato_simple",
            descripcion: "Formato Simple"
            };

            $scope.delayRequestProveedores = function(searchValue) {
                if (searchValue.length >= 3) {
                  var paramsProv = {};
                  paramsProv.search = filterFactory.and([
                    {
                      path: 'activo',
                      equals: true
                      },
                    {
                      path: 'nombre',
                      like: searchValue
                    }]
                  ).value();
                  paramsProv.view = "BaseList";
                  $scope.proveedores = ProveedoresFactory.all(paramsProv, "LibroIvaCompraList");
                } else {
                    $scope.proveedores = undefined
                }
            }

            TiposFactory.monedas().then(function(response) {
                $scope.monedas = response.data;
            });

            var staticFilter2 = {};
            staticFilter2.search = filterFactory.and([
                {
                    path: 'utilizarEnProcesosCompra',
                    equals: true
                },
                {
                    path: 'informeImpositivo',
                    equals: true
                }
            ]).value();
            $scope.tiposComprobante = TipoComprobanteFactory.all(staticFilter2, "LibroIvaCompraList");

            $scope.filtros = {};

            $scope.changeProveedor = function(isNew) {
                //console.log('changeProveedor');
                var staticFilter = {};
                staticFilter.search = filterFactory.and([{
                    path: 'proveedor.codigo',
                    equals: $scope.filtros.proveedor.codigo
                }]).value();

                ProveedorSucursalesFactory.all(staticFilter, "LibroIvaCompraList").$promise.then(function (data) {
                    $scope.sucursalesProveedor = data;
                    if($scope.sucursalesProveedor.length == 1){
                        $scope.filtros.sucursal = $scope.sucursalesProveedor[0];
                    }else{
                        $scope.filtros.sucursal = null;
                    }
                });


                 if (isNew && isNew != false)
                    vm.comprobante.sucursalProveedor = null;
            }


            $scope.limpiarFiltros = function() {
                $scope.filtros.fechaDesde = undefined;
                $scope.filtros.fechaHasta = undefined;
                $scope.filtros.proveedor = undefined;
                $scope.filtros.sucursal = undefined;
                $scope.filtros.moneda = undefined;
                $scope.filtros.tipoComprobante = undefined;
                $scope.filtros.fechaCargadesde = undefined;
                $scope.filtros.fechaCargaHasta = undefined;
              }

            $scope.generarReporte = function() {
                if($scope.filtros.fechaDesde == undefined || $scope.filtros.fechaHasta == undefined){
                    var msg = $filter('translate')('ERROR_FILTERING_LIBRO_COMPRA');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                } else if ($scope.filtros.fechaDesde > $scope.filtros.fechaHasta) {
                  notify({ message: $filter('translate')('FECHA_DESDE_MAYOR_FECHA_HASTA'), classes: 'alert-danger', position: 'right' });
                } else if ($scope.filtros.fechaCargaDesde > $scope.filtros.fechaCargaHasta) {
                  notify({ message: $filter('translate')('FECHA_CARGA_DESDE_MAYOR_FECHA_CARGA_HASTA'), classes: 'alert-danger', position: 'right' });
                } else{

                    if ( this.mostrarNroRubrica && (angular.isUndefined($scope.nroInicialRubrica) || $scope.nroInicialRubrica == null)) {
                        notify({ message: $filter('translate')('ERROR_NRO_RUBRICA'), classes: 'alert-danger', position: 'right' });
                        return;
                    }

                    var filtersArr = [];
                    if ($scope.filtros.proveedor) {
                        filtersArr.push("proveedor=" + $scope.filtros.proveedor.id);
                    }
                    if ($scope.filtros.sucursal) {
                        filtersArr.push("sucursal=" + $scope.filtros.sucursal.id);
                    }
                    if ($scope.filtros.fechaDesde) {
                        var myDate = $filter('date')($scope.filtros.fechaDesde, "MM/dd/yyyy");
                        filtersArr.push("fechaDesde=" + myDate);
                    }
                    if ($scope.filtros.fechaHasta) {
                        var myDate = $filter('date')($scope.filtros.fechaHasta, "MM/dd/yyyy");
                        filtersArr.push("fechaHasta=" + myDate);
                    }
                    if ($rootScope.AuthParams.locacionSeleccionada) {
                        filtersArr.push("unidadNegocioUsuario=" + $rootScope.AuthParams.locacionSeleccionada.unidadNegocio.id);
                        filtersArr.push("locacion=" + $rootScope.AuthParams.locacionSeleccionada.id);
                        filtersArr.push("sucursalUsuario=" + $rootScope.AuthParams.locacionSeleccionada.sucursal.id);
                    }
                    if ($scope.filtros.moneda) {
                        filtersArr.push("moneda=" + $scope.filtros.moneda.id);
                    }
                    if ($scope.filtros.tipoComprobante) {
                        filtersArr.push("tipocomprobante=" + $scope.filtros.tipoComprobante.id);
                    }
                    if ($scope.filtros.fechaCargaDesde) {
                        var myDate = $filter('date')($scope.filtros.fechaCargaDesde, "MM/dd/yyyy");
                        filtersArr.push("fechaCargaDesde=" + myDate);
                    }
                    if ($scope.filtros.fechaCargaHasta) {
                        var myDate = $filter('date')($scope.filtros.fechaCargaHasta, "MM/dd/yyyy");
                        filtersArr.push("fechaCargaHasta=" + myDate);
                    }

                    filtersArr.push("todas_sucu_unidades=" + $scope.filtros.todas_sucu_unidades_com);

                    if($scope.mostrarNroRubrica){
                        filtersArr.push("nroInicialRubrica="+$scope.nroInicialRubrica);
                    }
              
                    filtersArr.push("tipoReporte="+$scope.tipoReporte.codigo);

                    var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("librocompra", 'pdf', filtersArr);
                    $window.open(pdfDownloadURL, '_blank');
                }

            };

            $scope.generarCSV = function() {
                if($scope.filtros.fechaDesde == undefined || $scope.filtros.fechaHasta == undefined){
                    var msg = $filter('translate')('ERROR_FILTERING_LIBRO_COMPRA');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }else{
                    //console.log($scope.filtros);
                    var filtersObj = {};
                    if ($scope.filtros.proveedor) {
                        filtersObj.proveedor = $scope.filtros.proveedor.id;
                        // filtersArr.push("proveedor=" + $scope.filtros.proveedor.id);
                    }
                    if ($scope.filtros.sucursal) {
                        filtersObj.sucursal = $scope.filtros.sucursal.id;
                        // filtersArr.push("sucursal=" + $scope.filtros.sucursal.id);
                    }
                    if ($scope.filtros.fechaDesde) {
                        var myDate = $filter('date')($scope.filtros.fechaDesde, "MM/dd/yyyy");
                        filtersObj.fechaDesde = myDate;
                        // filtersArr.push("fechaDesde=" + myDate);
                    }
                    if ($scope.filtros.fechaHasta) {
                        var myDate = $filter('date')($scope.filtros.fechaHasta, "MM/dd/yyyy");
                        filtersObj.fechaHasta = myDate;
                        // filtersArr.push("fechaHasta=" + myDate);
                    }

                    if ($scope.filtros.fechaCargaDesde) {
                        var myDate = $filter('date')($scope.filtros.fechaCargaDesde, "MM/dd/yyyy");
                        filtersObj.fechaCargaDesde = myDate;
                        // filtersArr.push("fechaDesde=" + myDate);
                    }
                    if ($scope.filtros.fechaCargaHasta) {
                        var myDate = $filter('date')($scope.filtros.fechaCargaHasta, "MM/dd/yyyy");
                        filtersObj.fechaCargaHasta = myDate;
                        // filtersArr.push("fechaHasta=" + myDate);
                    }
                    if ($scope.filtros.moneda) {
                        filtersObj.moneda = $scope.filtros.moneda.id;
                    }
                    if ($scope.filtros.tipoComprobante) {
                        filtersObj.tipocomprobante = $scope.filtros.tipoComprobante.id;
                    }

                    if ($rootScope.AuthParams.locacionSeleccionada) {
                        filtersObj.unidadNegocioUsuario = $rootScope.AuthParams.locacionSeleccionada.id;
                        filtersObj.sucursalUsuario = $rootScope.AuthParams.locacionSeleccionada.sucursal.id;
                    }

                    filtersObj.todas_sucu_unidades = $scope.filtros.todas_sucu_unidades_com;

                    CompraFactory.getLibroCompraCSV(filtersObj).then(function(response) {
                        var fileName = response.data.fileName;
                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                    });
                }

            };

            $scope.validarNroRubrica = function() {
                if (
                  $scope.mostrarCabecera &&
                  $scope.tipoReporte !== null &&
                  $scope.tipoReporte.codigo === "formato_impositivo"
                ) {
                  $scope.mostrarNroRubrica = true;
                } else {
                  $scope.mostrarNroRubrica = false;
                  $scope.nroInicialRubrica = null;
                }
            };

        }]);

"use strict";

angular.module("qualita.compra").controller("CompraIndexCtrl", CompraIndexCtrl);

CompraIndexCtrl.$inject = [
    "AuthorizationService",
    "ComprobanteFactory",
    "CompraFactory",
    "TiposFactory",
    "PeriodoAdministrativoFactory",
    "OrdenesCompraFactory",
    "filterFactory",
    "$log",
    "$modal",
    "$scope",
    "notify",
    "$state",
    "CompraLocalLangFactory",
    "ParametrosFactory",
    "TiposTipoFactory",
    "WatchLocacionChangeFactory",
    "UtilFactory",
    "ProveedoresFactory",
    "$rootScope",
    "TipoCompraFactory",
    "$filter",
    "CsvFactory",
    "baseurl",
    "$window"
];

function CompraIndexCtrl(
        AuthorizationService,
        ComprobanteFactory,
        CompraFactory,
        TiposFactory,
        PeriodoAdministrativoFactory,
        OrdenesCompraFactory,
        filterFactory,
        $log,
        $modal,
        $scope,
        notify,
        $state,
        CompraLocalLangFactory,
        ParametrosFactory,
        TiposTipoFactory,
        WatchLocacionChangeFactory,
        UtilFactory,
        ProveedoresFactory,
        $rootScope,
        TipoCompraFactory,
        $filter,
        CsvFactory,
        baseurl,
        $window
        ) {
    var vm = this;
    vm.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    vm.addNewCompra = addNewCompra;
    vm.autoGenerateCode = ParametrosFactory.getByCodigo(
            "generacion_codigo_compras"
            );
    ParametrosFactory.getByCodigo(
                "recibir_cantidad_mayor_a_oc"
                ).then(function(response){
                    vm.recibirMayorOC = response.valor;
                });

    var defaultColumnOrder = [
        "codigo",
        "estado.descripcion",
        "tipo.nombre",
        "ordenCompra.numero",
        "fechaApertura",
        "fechaCierre",
        "diferencia"
    ];

    CompraLocalLangFactory.getTranslations().then(function (translations) {
        if(vm.isMobile){
          vm.addNewCompra();
        }
        $scope.translations = translations;
        vm.options = {
            resource: "compra",
            title: $filter("translate")("COMPRAS"),
            factory: CompraFactory,
            view: "CompraList",
            defaultColumnOrder: defaultColumnOrder,
            failedDeleteError: $filter("translate")("ERROR_COMPRA_DELETE"),
            columns: [
                {data: "codigo", title: $filter("translate")("CODE")},
                {
                    data: "estado.descripcion",
                    title: $filter("translate")("STATUS"),
                    'type': 'combo',
                    'filterUrl': 'compra/estados'
                },
                {data: "tipo.nombre", title: $filter("translate")("TIPO_COMPRA")},
                {
                    data: "ordenCompra.numero",
                    title: $filter("translate")("ORDEN_COMPRA"),
                    renderWith: "emptyRender"
                },
                {
                    data: "fechaApertura",
                    title: $filter("translate")("DATE_APERTURA"),
                    renderWith: "dateRender",
                    dateFormat: "DD/MM/YYYY",
                    class: "dt-center",
                    type: "date-range"
                },
                {
                    data: "fechaCierre",
                    title: $filter("translate")("DATE_CIERRE"),
                    renderWith: "dateRender",
                    dateFormat: "DD/MM/YYYY",
                    class: "dt-center",
                    type: "date-range"
                },
                {
                    data: "diferencia",
                    title: $filter("translate")("TIENE_DIFERENCIA"),
                    class: "dt-center",
                    type: "combo",
                    filterUrl: "tipos/filtro/boolean_values"
                }
            ],
            hasOptions: true,
            defaultOrderColumn: 4,
            defaultOrderDir: "desc",
            hideAddMenu: true,
            hideEditMenu: true,
            hideRemoveMenu: true,
            hideViewMenu: true,
            extraMenuOptions: [
                {
                    title: $filter("translate")("NEW_COMPRA"),
                    icon: "glyphicon glyphicon-plus",
                    showCondition: function () {
                        var permission = AuthorizationService.hasPermission(
                                "create_compra"
                                );
                        return permission;
                    },
                    action: function () {
                        vm.addNewCompra();
                    },
                },
                {
                    'title': 'CSV',
                    'icon': 'glyphicon glyphicon-export',
                    'showCondition': function () {
                        return true;
                    },
                    'action': function () {
                        var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                        CsvFactory.csv("compra", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                                .then(function (response) {
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                    }
                }
            ],
            extraRowOptions: [{
                    templateToRender: "<button class='btn btn-success' style='margin-right: 5px;'   title=" +
                            $filter("translate")("EDIT") +
                            " ng-click='editarProceso(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                    customAttribute: "estado",
                    conditionName: "canEditar",
                    conditionDef: function (estado) {
                        return (
                                estado.codigo != "compra_cerrada" &&
                                estado.codigo != "compra_anulada"
                                );
                    },
                    functionName: "editarProceso",
                    functionDef: function (itemId) {
                        redirectCompra(itemId);
                    }
                },
                {
                    templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                            $filter("translate")("DELETE") +
                            "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                    customAttribute: "estado",
                    conditionName: "canBorrar",
                    conditionDef: function (estado) {
                        return (
                                estado.codigo != "compra_cerrada" &&
                                estado.codigo != "compra_anulada"
                                );
                    },
                    functionName: "borrar",
                    functionDef: function (itemId) {
                        $scope.selectedItemId = itemId;
                        $scope.tituloModal = $filter("translate")("DELETE_CONFIRMATION");
                        $scope.mensajeModal = $filter("translate")("DELETE_WARNING");
                        var modalInstance = $modal.open({
                            templateUrl: "views/directives/confirmation-modal.html",
                            scope: $scope
                        });

                        $scope.cancel = function () {
                            modalInstance.dismiss("cancel");
                        };

                        $scope.ok = function (itemId) {
                            var model = CompraFactory.create({id: itemId});
                            CompraFactory.remove(model).then(
                                    function () {
                                        modalInstance.close(itemId);
                                        $state.go($state.current, {}, {reload: true});
                                    },
                                    function (error) {
                                        modalInstance.dismiss("cancel");
                                        $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                        $scope.mensajeModal = $filter("translate")(
                                                "COMPRA_DELETE_CHECK_COMPROBANTES"
                                                );

                                        var modalInstance2 = $modal.open({
                                            templateUrl: "views/directives/information-modal.html",
                                            scope: $scope
                                        });
                                        $scope.cancel = function () {
                                            modalInstance2.dismiss("cancel");
                                        };
                                    }
                            );
                        };
                    }
                },
                {
                    templateToRender: "<button title='" +
                            $filter("translate")("ANULAR_COMPRA") +
                            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataId%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                    customAttribute: "estado",
                    conditionName: "canAnular",
                    conditionDef: function (estado) {
                        return (
                                AuthorizationService.hasPermission("update_compra") &&
                                estado.codigo != "compra_cerrada" &&
                                estado.codigo != "compra_anulada"
                                );
                    },
                    functionName: "anular",
                    functionDef: function (itemId) {
                        $scope.selectedItemId = itemId;
                        $scope.tituloModal = $filter("translate")("ANULAR_COMPRA_Q");
                        $scope.mensajeModal = $filter("translate")("ANULAR_COMPRA_MSG");
                        var modalInstance = $modal.open({
                            templateUrl: "views/directives/confirmation-modal.html",
                            scope: $scope
                        });

                        $scope.cancel = function () {
                            modalInstance.dismiss("cancel");
                        };

                        $scope.ok = function (itemId) {
                            CompraFactory.anular(itemId).$promise.then(
                                    function () {
                                        modalInstance.close(itemId);
                                        $state.go($state.current, {}, {reload: true});
                                    },
                                    function (error) {
                                        modalInstance.dismiss("cancel");
                                        $scope.tituloModal = $filter("translate")(
                                                "ANULAR_COMPRA_FAILED"
                                                );
                                        $scope.mensajeModal = error.data[0].message + ".";

                                        var modalInstance2 = $modal.open({
                                            templateUrl: "views/directives/information-modal.html",
                                            scope: $scope
                                        });
                                        $scope.cancel = function () {
                                            modalInstance2.dismiss("cancel");
                                        };
                                    }
                            );
                        };
                    }
                },
                {
                    templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" +
                            $filter("translate")("VIEW") +
                            "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                    conditionName: "canView",
                    conditionDef: function () {
                        return AuthorizationService.hasPermission("index_compra");
                    },
                    functionName: "view",
                    functionDef: function (itemId) {
                        redirectCompra(itemId);
                    }
                }
            ]
        };
        WatchLocacionChangeFactory.watchWith(undefined, vm.options, "locacion.id");
    });

    function addNewCompra() {
        $scope.tituloModal = $filter("translate")("NEW_COMPRA");
        // agregar filtro de ordenes de compra de importacion
        var staticFilterOC = {};

        staticFilterOC.search = filterFactory
                .and([{
                        path: "estado.codigo",
                        notEquals: "ocl_cerrada"
                    },
                    {
                        path: "id",
                        sortAsc: "true"
                    },
                    {
                        path: "estado.codigo",
                        notEquals: "ocl_vencida"
                    },
                    {
                        path: "estado.codigo",
                        notEquals: "ocl_consolidada"
                    },
                    {
                        path: "estado.codigo",
                        notEquals: "ocl_ingresada"
                    },
                    {
                        path: "estado.codigo",
                        notEquals: "ocl_anulada"
                    },
                    {
                        path: "tipoOrdenCompra.esImportacion",
                        like: "false"
                    }
                ])
                .value();
        if (vm.watchOC) {
            vm.watcherOC();
        }
        WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
                ) {
            vm.watcherOC = watcher;
            OrdenesCompraFactory.all(
                    UtilFactory.createNestedFilters(
                            locacionFilter.search.filters,
                            staticFilterOC
                            ), "OrdenCompraList"
                    ).$promise.then(function (response) {
                if (vm.recibirMayorOC === "No"){
                    var ordenesCompraList = response;
                    $scope.ordenesCompra = [];
                    _.forEach(ordenesCompraList, function (oc) {
                        if (oc.estado.codigo != "ocl_recepcionada"){
                            $scope.ordenesCompra.push(oc);
                        }
                    });

                }else{
                    $scope.ordenesCompra = response;
                }

                _.forEach($scope.ordenesCompra, function (oc) {
                    oc.fecha = moment(oc.fecha, "YYYY/MM/DD").format("DD/MM/YYYY");
                });
            });
            ProveedoresFactory.all(
                    locacionFilter.search.filters,
                    "ProveedorList"
                    ).$promise.then(function (response) {
                $scope.proveedores = response;
            });
        }, "locacion.id");
        var filtersTC = {
            search: filterFactory.single({path: "activo", equals: true}).value()
        };

        TipoCompraFactory.all(filtersTC, "TipoCompraList").$promise.then(function (
                response
                ) {
            $scope.tiposCompra = _.map(response, function (tc) {
                if (
                        _.find(tc.tipoCompraDetalles, function (detalle) {
                            return detalle.componente.codigo == "comp_tipo_compra_orden_compra";
                        })
                        ) {
                    tc.tieneOC = true;
                } else if (
                        _.find(tc.tipoCompraDetalles, function (detalle) {
                            return detalle.componente.codigo == "comp_tipo_compra_recepcion";
                        })
                        ) {
                    tc.tieneRecepcionAndNotOC = true;
                }
                return tc;
            });
        });
        // buscar periodo que coincida con la fecha actual
        var params = {};
        params.search = filterFactory
                .and([{
                        path: "estado.codigo",
                        equals: "per_adm_abierto"
                    }])
                .value();

        params.view = "PeriodoAdministrativoList"

        var flagPeriodo = false;

        PeriodoAdministrativoFactory.all(params).$promise.then(function (response) {
            var periodos = response;
            _.forEach(periodos, function (value) {
                var desde = new Date(value.fechaDesde);
                var hasta = new Date(value.fechaHasta);
                var hoy = new Date();

                /* Se fuerza a que las horas del dia esten en cero para poder realizar
                 la compraración sin errores.
                 */
                hoy.setHours(0, 0, 0, 0);

                if (hoy >= desde && hoy <= hasta) {
                    flagPeriodo = true;
                }
            });

            if (flagPeriodo) {
                vm.autoGenerateCode.then(function (response) {
                    if (response.valorTipo.codigo == "si") {
                        $scope.oc = {};
                        var modalInstance = $modal.open({
                            templateUrl: "views/directives/compra-auto-modal.html",
                            scope: $scope
                        });

                        $scope.searchOCFilter = function (criteria) {
                            return function (item) {
                                if (!criteria) {
                                    return true;
                                }
                                return (
                                        item.numero.toLowerCase().indexOf(criteria.toLowerCase()) >
                                        -1 ||
                                        item.proveedor.nombre
                                        .toLowerCase()
                                        .indexOf(criteria.toLowerCase()) > -1
                                        );
                            };
                        };

                        $scope.searchProveedorFilter = function (criteria) {
                            return function (item) {
                                if (!criteria) {
                                    return true;
                                }
                                return (
                                        item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1
                                        );
                            };
                        };

                        $scope.cancel = function () {
                            modalInstance.dismiss("cancel");
                        };

                        $scope.ok = function (tipoCompra, ordenCompra, proveedor) {
                            if (!tipoCompra) {
                                return;
                            }
                            var containsOC = _.find(tipoCompra.tipoCompraDetalles, function (
                                    detalle
                                    ) {
                                return (
                                        detalle.componente.codigo == "comp_tipo_compra_orden_compra"
                                        );
                            });

                            if (containsOC && !ordenCompra) {
                                console.error(
                                        "El tipo es Orden de Compra y no se especificó Orden de Compra"
                                        );
                                return;
                            }

                            if (!containsOC && ordenCompra) {
                                console.error(
                                        "Hay Orden de Compra y el tipo no es de OrdenCompra"
                                        );
                                return;
                            }

                            var containsRecepcion = _.find(
                                    tipoCompra.tipoCompraDetalles,
                                    function (detalle) {
                                        return (
                                                detalle.componente.codigo == "comp_tipo_compra_recepcion"
                                                );
                                    }
                            );

                            if (containsRecepcion && !containsOC && !proveedor) {
                                console.error(
                                        "El tipo es Con Recepción y no se especificó el Proveedor"
                                        );
                                return;
                            }

                            sendNewCompra(tipoCompra, ordenCompra, undefined, proveedor)
                                    .then(function (data) {
                                        modalInstance.close();
                                        return CompraFactory.get(data.id, "CompraList").$promise;
                                    })
                                    .then(function (data) {
                                        CompraFactory.setIdCompra(data.id);
                                        CompraFactory.setCodigoCompra(data.codigo);
                                        CompraFactory.setCompra(data);
                                        if(vm.isMobile){
                                          localStorage.setItem("newRecepcion", true);
                                        }
                                        redirectCompra(data.id);
                                    });
                        };
                    } else {
                        var modalInstance = $modal.open({
                            templateUrl: "views/directives/compra-manual-modal.html",
                            scope: $scope
                        });

                        $scope.oc = {};

                        $scope.searchOCFilter = function (criteria) {
                            return function (item) {
                                if (!criteria) {
                                    return true;
                                }
                                return (
                                        item.numero.toLowerCase().indexOf(criteria.toLowerCase()) >
                                        -1 ||
                                        item.proveedor.nombre
                                        .toLowerCase()
                                        .indexOf(criteria.toLowerCase()) > -1
                                        );
                            };
                        };

                        $scope.searchProveedorFilter = function (criteria) {
                            return function (item) {
                                if (!criteria) {
                                    return true;
                                }
                                return (
                                        item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1
                                        );
                            };
                        };

                        $scope.cancel = function () {
                            modalInstance.dismiss("cancel");
                        };

                        $scope.ok = function (codigo, tipo, ordenCompra) {
                            console.log("AQUI TIPO: ", tipo);
                            if (!tipo) {
                                console.error("No hay tipoCompra");
                                return;
                            }
                            if (tipo.tieneOC == true && !ordenCompra) {
                                console.error(
                                        "El tipo es Orden de Compra y no se especificó Orden de Compra"
                                        );
                                return;
                            }
                            if (tipo.tieneOC && tipo.tieneOC != true && ordenCompra) {
                                console.error(
                                        "Hay Orden de Compra y el tipo no es de OrdenCompra"
                                        );
                                return;
                            }

                            sendNewCompra(tipo, ordenCompra, codigo).then(function (data) {
                                modalInstance.close();
                                CompraFactory.setIdCompra(data.id);
                                CompraFactory.setCodigoCompra(data.codigo);
                                console.log("AQUI ANTES: ", data.tipo);
                                data.tipo = TipoCompraFactory.get(data.tipo.id, "detail");
                                CompraFactory.setCompra(data);
                                redirectCompra(data.id);
                            });
                        };
                    }
                });
            } else {
                var msg = ' ' + $filter("translate")("NO_ADMINISTRATIVE_PERIOD");
                notify({message: msg, classes: "alert-danger", position: "right"});
            }
        });
    }

    function sendNewCompra(tipoCompra, ordenCompra, codigo, proveedor) {
        return TiposFactory.estadosCompra()
                .then(
                        function (response) {
                            var estados = response.data;

                            if (!estados) {
                                console.log(
                                        "No se pudo guardar compra debido a que no se pudo traer los estados"
                                        );
                                return;
                            }

                            var estadoActivo = _.find(estados, function (estado) {
                                return estado.codigo === "compra_abierta";
                            });
                            if (!estadoActivo) {
                                return;
                            }
                            var compra = {
                                estado: estadoActivo.id,
                                activo: true,
                                tipo: tipoCompra
                            };
                            if (codigo) {
                                compra.codigo = codigo;
                            }

                            if (ordenCompra) {
                                compra.ordenCompra = ordenCompra;
                                compra.proveedor = ordenCompra.proveedor;
                            }
                            if (proveedor) {
                                compra.proveedor = proveedor;
                            }
                            compra.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
                            var cmp = CompraFactory.create(compra);
                            return CompraFactory.save(cmp);
                        },
                        function (error) {
                            console.log("Error a traer los estados de compra: ");
                            console.log(error);
                        }
                )
                .then(
                        function (data) {
                            console.log("Se ha guardado la compra.");
                            console.log(data);
                            return data;
                        },
                        function (error) {
                            console.log("Error al guardar la compra.");
                            console.log(error);
                        }
                );
    }

    function redirectCompra(itemId) {
        CompraFactory.get(itemId, "CompraList").$promise.then(function (cmp) {
            CompraFactory.setIdCompra(cmp.id);
            CompraFactory.setCodigoCompra(cmp.codigo);
            CompraFactory.setCompra(cmp);
            var tipo = cmp.tipo;
            console.log("Tipo: ", tipo);
            console.log("cmp.estado.codigo", cmp.estado.codigo);
            if (cmp.estado.codigo == "compra_abierta" || cmp.estado.codigo == "compra_anulada") {
                var primerTipo = _.find(tipo.tipoCompraDetalles, function (detalle) {
                    return detalle.orden == 1;
                });
                if (
                        primerTipo &&
                        primerTipo.componente.codigo == "comp_tipo_compra_orden_compra"
                        ) {
                    console.log("app.compraslocales.proceso.ordenescompra.list");
                    $state.go("app.compraslocales.proceso.ordenescompra.list", {
                        codigoCompra: itemId
                    });
                }

                if (
                        primerTipo &&
                        primerTipo.componente.codigo == "comp_tipo_compra_recepcion"
                        ) {
                    console.log("app.compraslocales.proceso.recepcion.list");
                    $state.go("app.compraslocales.proceso.recepcion.list", {
                        codigoCompra: itemId
                    });
                }

                if (
                        primerTipo &&
                        primerTipo.componente.codigo == "comp_tipo_compra_comprobante"
                        ) {
                    console.log("app.compraslocales.proceso.comprobante.list");
                    $state.go("app.compraslocales.proceso.comprobante.list", {
                        codigoCompra: itemId
                    });
                }
            }
            if (cmp.estado.codigo == "compra_con_oc") {
                var tipoOC = _.find(tipo.tipoCompraDetalles, function (detalle) {
                    return detalle.componente.codigo == "comp_tipo_compra_orden_compra";
                });

                var tipoActual = _.find(tipo.tipoCompraDetalles, function (detalle) {
                    return detalle.orden == tipoOC.orden + 1;
                });

                if (!tipoActual) {
                    $state.go("app.compraslocales.proceso.cierre.list", {
                        codigoCompra: itemId
                    });
                } else if (
                        tipoActual.componente.codigo == "comp_tipo_compra_recepcion"
                        ) {
                    $state.go("app.compraslocales.proceso.recepcion.list", {
                        codigoCompra: itemId
                    });
                } else if (
                        tipoActual.componente.codigo == "comp_tipo_compra_comprobante"
                        ) {
                    $state.go("app.compraslocales.proceso.comprobante.list", {
                        codigoCompra: itemId
                    });
                }
            }
            if (cmp.estado.codigo == "compra_con_recepcion") {
                var tipoRecepcion = _.find(tipo.tipoCompraDetalles, function (detalle) {
                    return detalle.componente.codigo == "comp_tipo_compra_recepcion";
                });

                var tipoActual = _.find(tipo.tipoCompraDetalles, function (detalle) {
                    return detalle.orden == tipoRecepcion.orden + 1;
                });

                if (!tipoActual) {
                    $state.go("app.compraslocales.proceso.cierre.list", {
                        codigoCompra: itemId
                    });
                } else if (
                        tipoActual.componente.codigo == "comp_tipo_compra_orden_compra"
                        ) {
                    $state.go("app.compraslocales.proceso.ordenescompra.list", {
                        codigoCompra: itemId
                    });
                } else if (
                        tipoActual.componente.codigo == "comp_tipo_compra_comprobante"
                        ) {
                    $state.go("app.compraslocales.proceso.comprobante.list", {
                        codigoCompra: itemId
                    });
                }
            }
            if (cmp.estado.codigo == "compra_con_comprobante") {
                var tipoComprobante = _.find(tipo.tipoCompraDetalles, function (
                        detalle
                        ) {
                    return detalle.componente.codigo == "comp_tipo_compra_comprobante";
                });

                var tipoActual = _.find(tipo.tipoCompraDetalles, function (detalle) {
                    return detalle.orden == tipoComprobante.orden + 1;
                });

                if (!tipoActual) {
                    $state.go("app.compraslocales.proceso.cierre.list", {
                        codigoCompra: itemId
                    });
                } else if (
                        tipoActual.componente.codigo == "comp_tipo_compra_recepcion"
                        ) {
                    $state.go("app.compraslocales.proceso.recepcion.list", {
                        codigoCompra: itemId
                    });
                } else if (
                        tipoActual.componente.codigo == "comp_tipo_compra_orden_compra"
                        ) {
                    $state.go("app.compraslocales.proceso.ordenescompra.list", {
                        codigoCompra: itemId
                    });
                }
            }
            if (cmp.estado.codigo == "compra_cerrada") {
                $state.go("app.compraslocales.proceso.cierre.list", {
                    codigoCompra: itemId
                });
            }

        });
    }
}

'use strict';


angular.module('qualita.compra')
  .controller('CompraProcesoCtrl', CompraProcesoCtrl);

CompraProcesoCtrl.$inject = ["AuthorizationService", "CompraFactory", "TiposFactory", "$log", "$modal",
"$scope", "notify", "CompraLocalLangFactory"];

function CompraProcesoCtrl(AuthorizationService, CompraFactory, TiposFactory, $log, $modal, $scope,
  notify, $stateParams, CompraLocalLangFactory) {

    var vm = this;
  console.log("En CompraProcesoCtrl");
  vm.compraId = CompraFactory.getIdCompra();
  vm.compraCodigo = CompraFactory.getCodigoCompra();
  vm.compra = CompraFactory.getCompra();
  vm.enabledStates = CompraFactory.getEnabledStates();
  console.log("vm.enabledStates: ", vm.enabledStates);

  vm.statesMap = {
    comp_tipo_compra_orden_compra: {
      state: ".ordenescompra.list",
      parentState: "app.compraslocales.proceso.ordenescompra",
      enabledStatesIndex: 0
    },
    comp_tipo_compra_recepcion: {
      state: ".recepcion.list",
      parentState: "app.compraslocales.proceso.recepcion",
      enabledStatesIndex: 2
    },
    comp_tipo_compra_comprobante: {
      state: ".comprobante.list",
      parentState: "app.compraslocales.proceso.comprobante",
      enabledStatesIndex: 1
    }
  }

  $scope.$on("updateCompraStates", function() {
    console.log("updateCompraStates listener triggered");
    vm.enabledStates = CompraFactory.getEnabledStates();
  });
}
'use strict';

angular.module('qualita.compra')
    .controller('TipoCompraListCtrl', ["AuthorizationService", "TipoCompraFactory", "TiposFactory", "$log", "$modal", "$scope", "CompraLocalLangFactory", "$state", "$filter", "filterFactory", "ReportTicketFactory", "$window", "UtilFactory", "CsvFactory", "baseurl", function TipoCompraListCtrl(AuthorizationService, TipoCompraFactory,
        TiposFactory, $log, $modal, $scope, CompraLocalLangFactory, $state, $filter, filterFactory, ReportTicketFactory,
        $window, UtilFactory, CsvFactory, baseurl) {

        CompraLocalLangFactory.getTranslations().then(function(translations) {
            var defaultColumnOrder = ['id', 'nombre', 'fechaInicioVigencia', 'fechaFinVigencia', 'activo'];

            $scope.options = {
                'resource': 'tiposcompras',
                'title': $filter('translate')('TIPOS_COMPRA'),
                'factory': TipoCompraFactory,
                'view': 'TipoCompraList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('FAIL_DELETE_TIPO_COMPRA'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false  },
                    { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
                    { 'data': 'fechaInicioVigencia', 'title': $filter('translate')('FECHA_INICIO_VIGENCIA'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type' : 'date-range' },
                    { 'data': 'fechaFinVigencia', 'title': $filter('translate')('FECHA_FIN_VIGENCIA'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type' : 'date-range' },
                   { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                    [
                        {
                          'title': 'Reporte',
                          'icon': 'glyphicon glyphicon-file',
                          'showCondition': function() { return true; },
                          'action': function() {
                              var filters = createFilters($scope.options.getFilters());
                              ReportTicketFactory.ticket('listadoTipoCompra', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open($scope.pdfDownloadURL, '_blank');
                              });
                            }
                        },
                        {
                          'title': 'CSV',
                          'icon': 'glyphicon glyphicon-export',
                          'showCondition': function() { return true; },
                          'action': function() {
                              var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                              CsvFactory.csv("tiposcompras", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                              .then(function(response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                              });
                            }
                        }
                    ],
                'extraRowOptions': [{
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: 'activo',
                        conditionName: "canBorrar",
                        conditionDef: function(activo) {
                            return activo == 'Sí';
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                            $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                            var modalInstance = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function(itemId) {
                                var model = TipoCompraFactory.create({ id: itemId });
                                TipoCompraFactory.remove(model).then(function() {
                                    modalInstance.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                }, function(error) {
                                    modalInstance.dismiss('cancel');
                                    $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                                    $scope.mensajeModal = error.data[0].message;

                                    var modalInstance2 = $modal.open({
                                        templateUrl: 'views/directives/information-modal.html',
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance2.dismiss('cancel');
                                    };
                                });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        conditionName: 'canView',
                        conditionDef: function() {
                            return AuthorizationService.hasPermission('index_tiposcompras');
                        },
                        functionName: "view",
                        functionDef: function(itemId) {
                            return $state.go("app.tiposcompras.view", { id: itemId });
                        }
                    }
                ],
                'extraEditConditions': function(item) {
                    if(item != undefined){
                    return item.activo == 'Sí';
                    }
                },
            };
        });

        var createFilters = function (filters) {
            var filtersArr = [];
            _.each(filters, function (search, data) {
                search = search.split("~");
                if (search.length > 1) {
                    /* El parámetro es un rago */

                    var isDate = search[0].split("/");

                    if (isDate.length > 1) {
                        /* Es un rago de fechas */

                        filtersArr.push({path: data, equalOrAfter: search[0]})
                        filtersArr.push({path: data, equalOrBefore: search[1]})
                    } else {
                        /* El un rago numérco */

                        filtersArr.push({path: data, greaterOrEqual: search[0]})
                        filtersArr.push({path: data, lessOrEqual: search[1]})
                    }
                } else {
                    /* El parametro no es un rago */
                    if(search[0] === 'No' && data === 'activo'){
                        search[0] = 'false';
                    }
                    if(search[0] === 'Sí' && data === 'activo'){
                        search[0] = 'true';
                    }
        
                    filtersArr.push({path: data, like: search[0]})
                }
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        }

       

    }]);
'use strict';

angular.module('qualita.compra')
  .controller('TipoCompraFormCtrl', ["TipoCompraFactory", "filterFactory",
    "$modal", "$scope", "notify", "$state", "$rootScope", "tipoCompraPrepService", "$controller", "formFactory",
    "CompraLocalLangFactory", '$location', 'TiposFactory', '$filter',
    function (TipoCompraFactory, filterFactory, $modal, $scope, notify, $state, $rootScope,
      tipoCompraPrepService, $controller, formFactory, CompraLocalLangFactory, $location, TiposFactory, $filter) {

      var self = this;
      CompraLocalLangFactory.getTranslations().then(function (translations) {
        $scope.resource = 'tiposcompras';
        // $scope.resources = 'tipocompra';
        $scope.prepService = tipoCompraPrepService;
        $scope.submit = submit;
        $scope.cancel = cancel;
        $scope.submitTipoCompra = submitTipoCompra;
        $scope.translations = translations;

        $scope.newProperties = {
          state: "app.tiposcompras.new",
          title: $filter('translate')('NEW_TIPO_COMPRA'),
        };
        $scope.editProperties = {
          state: "app.tiposcompras.edit",
          title: $filter('translate')('EDIT_TIPO_COMPRA'),
          entidad: "TipoCompra"
        };

        $scope.viewProperties = {
          state: "app.tiposcompras.view",
          title: $filter('translate')('VIEW_TIPO_COMPRA'),
          entidad: "TipoCompra"
        };

        $scope.restoreList = function() {
          $scope.tipoCompra.tipoCompraDetalles = [];
          _.forEach($scope.componentes, function(val) {
            var detalle = {};
            if (val.codigo == 'comp_tipo_compra_comprobante') {
              detalle.realizaIngresoStock = true;
            } else {
              detalle.realizaIngresoStock = false;
            }
            detalle.componente = val;
            $scope.tipoCompra.tipoCompraDetalles.push(detalle);
          });
        }

        $scope.select = function(node) {
          _.forEach($scope.tipoCompra.tipoCompraDetalles, function(anode) {
            anode.realizaIngresoStock = false;
          });
          node.realizaIngresoStock = true;
        }

        $scope.activate = function () {
          if ($state.is($scope.newProperties.state)) {
            activateNew();
          } else if ($state.is($scope.editProperties.state)) {
            activateEdit();
          } else if ($state.is($scope.viewProperties.state)) {
            activateView();
          }

          $rootScope.isProcessing = false;
        }

        function activateNew() {
          $scope.title = $scope.newProperties.title;
          $scope.tipoCompra = {activo: true, fechaInicioVigencia: new Date()};
          TiposFactory.componentesCompra().then(function(response) {
            $scope.componentes = $filter('orderBy')(response.data, 'id');
            $scope.restoreList();
          });
        }

        function activateEdit() {
          $scope.tipoCompra = $scope.prepService;
          $scope.entidadId = $scope.tipoCompra.id;
          $scope.entidad = $scope.editProperties.entidad;
          $scope.title = $scope.editProperties.title;
          $scope.tipoCompra.tipoCompraDetalles = $filter('orderBy')($scope.tipoCompra.tipoCompraDetalles, 'orden');
          TiposFactory.componentesCompra().then(function(response) {
            $scope.componentes = $filter('orderBy')(response.data, 'id');
          });
        }

        function activateView() {
          $scope.tipoCompra = $scope.prepService;
          $scope.entidadId = $scope.tipoCompra.id;
          $scope.entidad = $scope.viewProperties.entidad;
          $scope.title = $scope.viewProperties.title;
          $scope.tipoCompra.tipoCompraDetalles = $filter('orderBy')($scope.tipoCompra.tipoCompraDetalles, 'orden');
          $scope.view = true;
        }

        function cancel() {
          $location.path('/tipocompra');
        };

        $scope.activate();

        function submit() {
          $scope.submited = true;
          if ($scope.TipoCompraForm.$valid) {
            var detalles = [];
            var tieneGenerarMovimiento;
            _.forEach($scope.tipoCompra.tipoCompraDetalles, function(value, index) {
                if (value.realizaIngresoStock == true) {
                  tieneGenerarMovimiento = value.realizaIngresoStock;
                }
                value.orden = index + 1;
            });
            if (tieneGenerarMovimiento) {
              $rootScope.isProcessing = true;
              submitTipoCompra().then(function (tipoCompra) {
                $location.path('/tipocompra');
              }, errorHandler);
            } else {
              var msg = $filter('translate')('ERROR_NINGUN_COMP_GENERA_MOVIMIENTO');
              notify({
                  message: msg,
                  classes: 'alert-danger',
                  position: 'right'
                });
            }
          }
        };

        function submitTipoCompra() {
            var tipoCompra = TipoCompraFactory.create($scope.tipoCompra);
            return TipoCompraFactory.save(tipoCompra,
              function (resp, headers) {
                //success callback
                console.log("RESPONSE: ", resp);
              });
          };

          function errorHandler(err) {
            console.log("Error al guardar Tipo Compra: ", err);
            $rootScope.isProcessing = false;
            $scope.submited = false;
            var msg = '';
            _.forEach(err.data, function (error) {
              if (error.constraint == 'orden, id_tipo_compra') { // TODO CAMBIAR esta condicion
                msg += '\n\n' + $filter('translate')('TIPO_COMPRA_ALREADY_EXISTS') + "\n";
              } else {
                msg += '\n\n' + error.message + '.';
              }
            });
            notify({
              message: msg,
              classes: 'alert-danger',
              position: 'right'
            });
          }
      });
    }]);


'use strict';


angular.module('qualita.compra')
  .controller('ConsultaCompraIndexCtrl', ConsultaCompraIndexCtrl);

ConsultaCompraIndexCtrl.$inject = ["AuthorizationService", "CompraFactory", "TiposFactory", "PeriodoAdministrativoFactory",
"OrdenesCompraFactory", "filterFactory", "$log", "$modal", "$scope", "notify", "$state", "CompraLocalLangFactory",
"ParametrosFactory", "TiposTipoFactory", "WatchLocacionChangeFactory", "UtilFactory", "ProveedoresFactory",
"$rootScope", "TipoCompraFactory","ProductosFactory","ServicioFactory","ComprobanteDetalleFactory", "$filter",
"CsvFactory","$window","baseurl", "ReportTicketFactory"];

function ConsultaCompraIndexCtrl(AuthorizationService, CompraFactory, TiposFactory, PeriodoAdministrativoFactory,
  OrdenesCompraFactory, filterFactory, $log, $modal, $scope, notify, $state, CompraLocalLangFactory,
  ParametrosFactory, TiposTipoFactory, WatchLocacionChangeFactory, UtilFactory, ProveedoresFactory, $rootScope,
  TipoCompraFactory,ProductosFactory,ServicioFactory,ComprobanteDetalleFactory,$filter,
  CsvFactory,$window,baseurl, ReportTicketFactory) {


  var vm = this;

  activate();

  var renderEmptyData = function (data) {
    if (data == undefined)
      return "";
    else
      return data;
  };

  var monedaRender = function ( data, type, row ){
      if (data) {
        var moneda = "Gs. ";
        if (row.comprobante.moneda1.codigo==='dolares') {
          moneda = "Usd. ";
          data = parseFloat(data).toFixed(2);
        } else if(row.comprobante.moneda1.codigo==='pesos') {
          moneda = "Pes. ";
          data = parseFloat(data).toFixed(2);
        } else if (row.comprobante.moneda1.codigo==='real') {
          moneda = "Rel. ";
          data = parseFloat(data).toFixed(2);
        } else if (row.comprobante.moneda1.codigo==='euro') {
          moneda = "Eur. ";
          data = parseFloat(data).toFixed(2);
        }
        // TODO hacer esto mismo en todos los renders de moneda
        return moneda + data.toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }else {
        return '';
      }
    };

  function activate() {

    /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
    vm.staticFilterProv = [{path: "activo",equals: true}];
    vm.proveedoresFactory = ProveedoresFactory;
    vm.delayRequestProducto = delayRequestProducto;

    vm.filtro=false;

    vm.totalCantidad=0;
    vm.totaImporte=0;

    vm.curDate = new Date();
    vm.fechaHasta=vm.curDate;
    vm.fechaDesde=vm.curDate;

    TiposFactory.tiposDetalleComprobante().then(function (data) {
      vm.tipos = data.data;
      console.log('vm.tipos',vm.tipos);
    });
    //vm.tipos=[{nombre:"Producto"},{nombre:"Servicio"}];
    //console.log("vm.tipos",vm.tipos);

    if(vm.watcher) { vm.watcher(); }
    WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
      vm.watcher = watcher;
      var filter = locacionFilter;
      if(vm.proveedor!=undefined){
        var filterProveedor = {
          search: filterFactory.single({
            path: "proveedores.id",
            equals: vm.proveedor.id
          }).value()
        };
        var filter = UtilFactory.createNestedFilters(locacionFilter.search.filters, filterProveedor);
      }
      var filtroServicios = {};
        filtroServicios.search = filterFactory.and([{
          path: 'compra',
          equals: true
        }]).value();
      vm.servicios = ServicioFactory.all(filtroServicios);
      vm.productos = ProductosFactory.allForSelect(filter);
      //vm.opcionesProductosDt.staticFilter = filter;
    }, "locaciones.id");

    var defaultColumnOrder = ['comprobante.importacion.codigo','comprobante.compra.codigo','comprobante.proveedor.nombre','comprobante.estado.descripcion',
                                'comprobante.fecha','comprobante.numero','cantidad','totalItem','ultimoPrecio'];

    CompraLocalLangFactory.getTranslations().then(function(translations) {
      $scope.translations = translations;
      vm.options = {
        'resource': 'comprobante_detalle',
        'title': $filter('translate')('COMPRAS'),
        'factory': ComprobanteDetalleFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'view': 'ConsultaCompraDetalleList',
        'failedDeleteError': $filter('translate')('ERROR_COMPRA_DELETE'),
        'columns': [
          {'data': 'comprobante.importacion.codigo', 'title': $filter('translate')('CODE_IMPORTACION'),'render': renderEmptyData},
          {'data': 'comprobante.compra.codigo', 'title': $filter('translate')('CODE_COMPRA'),'render': renderEmptyData},
          {'data': 'comprobante.proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_SIN_CARACTER')},
          {'data': 'comprobante.estado.descripcion', 'title': $filter('translate')('ESTADO'),'render': renderEmptyData, 'type' : 'combo', 'filterUrl': 'tipos/filtro/estados_comprobante'},
          {'data': 'comprobante.fecha', 'title': $filter('translate')('DATE'), 'renderWith' : 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class' : 'dt-center', 'type' : 'date-range'},
          {'data': 'comprobante.numero', 'title': $filter('translate')('NRO_COMPROBANTE')},
          {'data': 'cantidad', 'title': $filter('translate')('CANTIDAD') },
          {'data': 'ultimoPrecio', 'title': $filter('translate')('PRECIO') , 'render':monedaRender},
          {'data': 'totalItem', 'title': $filter('translate')('PRECIO_TOTAL_ITEM'),'render':monedaRender },
        ],
        'hasOptions' : false,
        'defaultOrderColumn' : 5,
        'defaultOrderDir' : "desc",
        'hideAddMenu' : true,
        'hideEditMenu' : true,
        'hideRemoveMenu' : true,
        'hideViewMenu' : true,
        'extraMenuOptions':
          [
            {
              'title': $filter('translate')('REPORT'),
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                console.log("Reporte");
                var filtersArr = [];
                if (vm.proveedor) {
                    filtersArr.push("idproveedor=" + vm.proveedor.id);
                }
                if (vm.fechaDesde) {
                    filtersArr.push("fechaDesde=" + $filter('date')(vm.fechaDesde, "MM/dd/yyyy"));
                }
                if (vm.fechaHasta) {
                    filtersArr.push("fechaHasta=" + $filter('date')(vm.fechaHasta, "MM/dd/yyyy"));
                }
                if (vm.producto) {
                    filtersArr.push("idproducto=" + vm.producto.id);
                }
                if (vm.servicio) {
                    filtersArr.push("idservicio=" + vm.servicio.id);
                }
                filtersArr.push("id_un=" + $rootScope.AuthParams.un.id);
                filtersArr.push("id_s=" + $rootScope.AuthParams.suc.id);
                var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("consultacompra", 'pdf', filtersArr);
                $window.open(pdfDownloadURL, '_blank');
                console.log("report");
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                  CsvFactory.csv("comprobante_detalle", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ]
      };
      //WatchLocacionChangeFactory.watchWith(undefined, vm.options, "locacion.id");
    });

  }
  vm.changeProveedor = changeProveedor;

  function changeProveedor() {

  }
  vm.changeTipo = changeTipo;
  function changeTipo() {
    vm.servicio=undefined;
    vm.producto=undefined;
  }
  vm.searchProductoFilter = searchProductoFilter;
  function searchProductoFilter(criteria) {
    return function(item) {
      if(!criteria) {
        return true;
      }
      return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
    };
  }
  vm.searchServicioFilter = searchServicioFilter;
  function searchServicioFilter(criteria) {
    return function(item) {
      if(!criteria) {
        return true;
      }
      return item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ;
    };
  }

  vm.changeProducto = changeProducto;

  function changeProducto() {
    vm.servicio=undefined;
  }

  vm.changeServicio = changeServicio;

  function changeServicio() {
    vm.producto=undefined;
  }

  vm.cambiarFiltro=cambiarFiltro;

  function cambiarFiltro(){
    if(vm.tipo===undefined){
      notify({ message: $filter('translate')('MENSAJE_NO_TIPO'), classes: 'alert-danger', position: 'right'});
      return;
    }else if(vm.producto===undefined && vm.servicio===undefined){
      notify({ message: $filter('translate')('MENSAJE_NO_PRODUCTO'), classes: 'alert-danger', position: 'right'});
      return;
    }
    vm.filtro=true;
    if(vm.proveedor!=undefined){
      console.log("vm.proveedor.id",vm.proveedor.id);
      if(vm.producto!=undefined){
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
          path: 'comprobante.proveedor.id',
          equals: vm.proveedor.id
        },
        {
        path: 'comprobante.fecha',
        equalOrBefore: moment(vm.fechaHasta).format('DD/MM/YYYY')
        },
        {
          path: 'comprobante.fecha',
          equalOrAfter: moment(vm.fechaDesde).format('DD/MM/YYYY')
        },
        {
          path: 'producto.id',
          equals: vm.producto.id
        }
      ]).value();
      }else{
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
          path: 'comprobante.proveedor.id',
          equals: vm.proveedor.id
        },
        {
        path: 'comprobante.fecha',
        equalOrBefore: moment(vm.fechaHasta).format('DD/MM/YYYY')
        },
        {
          path: 'comprobante.fecha',
          equalOrAfter: moment(vm.fechaDesde).format('DD/MM/YYYY')
        },
        {
          path: 'servicio.id',
          equals: vm.servicio.id
        }
        ]).value();
      }

    }else{
      if(vm.producto!=undefined){
        var staticFilter = {};
        staticFilter.search = filterFactory.and([
        {
        path: 'comprobante.fecha',
        equalOrBefore: moment(vm.fechaHasta).format('DD/MM/YYYY')
        },
        {
          path: 'comprobante.fecha',
          equalOrAfter: moment(vm.fechaDesde).format('DD/MM/YYYY')
        },
          {
            path: 'producto.id',
            equals: vm.producto.id
          }
        ]).value();
      }else{
        var staticFilter = {};
        staticFilter.search = filterFactory.and([
        {
        path: 'comprobante.fecha',
        equalOrBefore: moment(vm.fechaHasta).format('DD/MM/YYYY')
        },
        {
          path: 'comprobante.fecha',
          equalOrAfter: moment(vm.fechaDesde).format('DD/MM/YYYY')
        },
          {
            path: 'servicio.id',
            equals: vm.servicio.id
          }
        ]).value();
      }
    }
    vm.options.staticFilter = staticFilter;
    if(vm.options.getFilters!=undefined){
      var filtersTotales={};
      filtersTotales.search = UtilFactory.createFilters(vm.options.getFilters(), vm.options.staticFilter);
    }else{
      var filtersTotales =vm.options.staticFilter;
    }
    CompraFactory.getTotales(filtersTotales).then(function(response) {
      //console.log("response",response.data);
      vm.totalCantidad=response.data.cantidad;
      vm.totaImporte=response.data.importe;
    });
  }

  function delayRequestProducto(searchValue) {
    var userInputFilter = {};
    var staticFilter = [];
    if (searchValue.length >= 3) {

      var filterNombre = staticFilter.slice()
      var filterCodigoBarras = staticFilter.slice()
      var filterCodigoInterno = staticFilter.slice()

      filterNombre.push({
        path: "nombre",
        like: searchValue
      })

      filterCodigoBarras.push({
        path: "codigoBarrasPrincipal.codigoBarras",
        like: searchValue
      })

      filterCodigoInterno.push({
        path: "codigo",
        like: searchValue
      })

      userInputFilter = {
        search: filterFactory.or([
          filterFactory.and(filterNombre).value(),
          filterFactory.and(filterCodigoBarras).value(),
          filterFactory.and(filterCodigoInterno).value()
        ]).value()
      }

      recuperarProducto(userInputFilter)
    } else if (searchValue.length > 0 && searchValue.length < 3) {

      var filterCodigoInterno = staticFilter.slice()
      filterCodigoInterno.push({
        path: "codigo",
        like: searchValue
      })

      userInputFilter = {
        search: filterFactory.or([
          filterFactory.and(filterCodigoInterno).value()
        ]).value()
      }
      recuperarProducto(userInputFilter)
    } else {
      vm.productos = undefined
    }
  }

  function recuperarProducto(userInputFilter) {
    var finalFilter = UtilFactory.createNestedFilters(
    vm.finalFilters, userInputFilter)
    vm.productos = ProductosFactory.allForSelect(userInputFilter)
  }
}

angular
  .module("qualita.tesoreria", [
    "ngResource",
    "ui.tree",
    "ngFileUpload",
    "ngAnimate",
    "rx",
    "angularMoment"
  ])
  .config([
    "$stateProvider",
    function ($stateProvider) {
      $stateProvider
        .state("app.tiposretencion", {
          url: "/tiporetencion",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Tipos de Retención"
          }
        })
        .state("app.tiposretencion.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "TipoRetencionIndexCtrl",
          ncyBreadcrumb: {
            parent: "app.tiposretencion",
            label: "Tipos de Retención",
            description: "Listado de Tipos de Retención",
            skip: true
          }
        })
        .state("app.tiposretencion.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/tiporetencion/form.html",
          controller: "TipoRetencionFormCtrl",
          ncyBreadcrumb: {
            parent: "app.tiposretencion",
            label: "Nuevo Tipo de Retención",
            description: "Creación de un nuevo tipo de retención"
          },
          resolve: {
            tipoRetencionPrepService: function () {
              return;
            }
          }
        })
        .state("app.tiposretencion.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/tiporetencion/form.html",
          controller: "TipoRetencionFormCtrl",
          ncyBreadcrumb: {
            parent: "app.tiposretencion",
            label: "Editar Tipo de Retención",
            description: "Edición de tipo de retención #"
          },
          resolve: {
            tipoRetencionPrepService: tipoRetencionPrepService
          }
        })
        .state("app.tiposretencion.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/tiporetencion/form.html",
          controller: "TipoRetencionFormCtrl",
          ncyBreadcrumb: {
            parent: "app.tiposretencion",
            label: "Editar Tipo de Retención",
            description: "Edición de tipo de retención #"
          },
          resolve: {
            tipoRetencionPrepService: tipoRetencionPrepService
          }
        })
        .state("app.comprobanteretencion", {
          url: "/comprobanteretencion",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Comprobantes de Retención"
          }
        })
        .state("app.comprobanteretencion.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "ComprobanteRetencionIndexCtrl",
          ncyBreadcrumb: {
            parent: "app.comprobanteretencion",
            label: "Comprobantes de Retención",
            description: "Listado de Comprobantes de Retención",
            skip: true
          }
        })
        .state("app.comprobanteretencion.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/comprobanteretencion/form.html",
          controller: "ComprobanteRetencionFormCtrl",
          ncyBreadcrumb: {
            parent: "app.comprobanteretencion",
            label: "Nuevo Comprobante de Retención",
            description: "Creación de un nuevo Comprobante de retención"
          },
          resolve: {
            comprobanteRetencionPrepService: function () {
              return;
            }
          }
        })
        .state("app.comprobanteretencion.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/comprobanteretencion/form.html",
          controller: "ComprobanteRetencionFormCtrl",
          ncyBreadcrumb: {
            parent: "app.comprobanteretencion",
            label: "Editar Comprobante de Retención",
            description: "Edición de Comprobante de retención #"
          },
          resolve: {
            comprobanteRetencionPrepService: comprobanteRetencionPrepService
          }
        })
        .state("app.comprobanteretencion.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/comprobanteretencion/form.html",
          controller: "ComprobanteRetencionFormCtrl",
          ncyBreadcrumb: {
            parent: "app.comprobanteretencion",
            label: "Ver Comprobante de Retención",
            description: "Ver Comprobante de retención #"
          },
          resolve: {
            comprobanteRetencionPrepService: comprobanteRetencionPrepService
          }
        })
        .state("app.importarcomprobante", {
          url: "/importar",
          templateUrl: "qualita-components/tesoreria/views/comprobanteretencion/importar.html",
          controller: "ComprobanteRetencionCargaCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            label: "Importar Comprobante de Retención de la SET",
            description: "Importar un nuevo Comprobante de retención de la SET"
          }
        })
        .state("app.comprobanteretencion.erroresjson", {
          url: "/errores-json",
          templateUrl: "qualita-components/tesoreria/views/comprobanteretencion/errores-json.html",
          controller: "ComprobanteRetencionErroresCtrl",
          ncyBreadcrumb: {
            parent: "app.comprobanteretencion",
            label: "Errores",
            description: "Errores al importar archivo de retenciones desde el Sistema Tesaka de la SET"
          }
        })
        .state("app.chequeras", {
          url: "/chequeras",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Chequeras"
          }
        })
        .state("app.chequeras.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "ChequeraIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.chequeras",
            label: "Chequeras",
            description: "Listado de Chequeras",
            skip: true
          }
        })
        .state("app.chequeras.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/chequera/form.html",
          controller: "ChequeraFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.chequeras",
            label: "Nueva Chequera",
            description: "Creación de una nueva chequera"
          },
          resolve: {
            chequeraPrepService: function () {
              return;
            }
          }
        })
        .state("app.chequeras.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/chequera/form.html",
          controller: "ChequeraFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.chequeras",
            label: "Editar Chequera",
            description: "Edición de chequera #"
          },
          resolve: {
            chequeraPrepService: chequeraPrepService
          }
        })
        .state("app.chequeras.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/chequera/form.html",
          controller: "ChequeraFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.chequeras",
            label: "Ver Chequera",
            description: "Ver chequera #"
          },
          resolve: {
            chequeraPrepService: chequeraPrepService
          }
        })
        .state("app.cheques", {
          url: "/cheques",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Cheques"
          }
        })
        .state("app.cheques.list", {
          url: "",
          templateUrl: 'qualita-components/tesoreria/views/cheque/consultar.html',
          // templateUrl: "views/template_base/list.html",
          controller: "ChequeIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.cheques",
            label: "Cheques",
            description: "Listado de Cheques",
            skip: true
          }
        })
        .state("app.cheques.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/cheque/form.html",
          controller: "ChequeFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.cheques",
            label: "Ver Cheque",
            description: "Ver cheque #"
          },
          resolve: {
            chequePrepService: chequePrepService
          }
        })
        .state("app.cuentabancaria", {
          url: "/cuentabancaria",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Cuentas Bancarias"
          }
        })
        .state("app.cuentabancaria.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "CuentaBancariaIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.cuentabancaria",
            label: "Cuentas Bancarias",
            description: "Listado de Cuentas Bancarias",
            skip: true
          }
        })
        .state("app.cuentabancaria.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/cuentabancaria/form.html",
          controller: "CuentaBancariaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.cuentabancaria",
            label: "Nueva Cuenta Bancaria",
            description: "Creación de una nueva cuenta bancaria"
          },
          resolve: {
            cuentaBancariaPrepService: function () {
              return;
            }
          }
        })
        .state("app.cuentabancaria.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/cuentabancaria/form.html",
          controller: "CuentaBancariaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.cuentabancaria",
            label: "Editar Cuenta Bancaria",
            description: "Edición de cuenta bancaria #"
          },
          resolve: {
            cuentaBancariaPrepService: cuentaBancariaPrepService
          }
        })
        .state("app.cuentabancaria.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/cuentabancaria/form.html",
          controller: "CuentaBancariaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.cuentabancaria",
            label: "Ver Cuenta Bancaria",
            description: "Ver cuenta bancaria #"
          },
          resolve: {
            cuentaBancariaPrepService: cuentaBancariaPrepService
          }
        })
        .state("app.entidadesbancarias", {
          url: "/entidadesbancarias",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Entidades Bancarias"
          }
        })
        .state("app.entidadesbancarias.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "EntidadBancariaIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.entidadesbancarias",
            label: "Entidad Bancaria",
            description: "Listado de Entidades Bancarias",
            skip: true
          }
        })
        .state("app.entidadesbancarias.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/entidadbancaria/form.html",
          controller: "EntidadBancariaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.entidadesbancarias",
            label: "Nueva Entidad Bancaria",
            description: "Creación de una nueva entidad bancaria"
          },
          resolve: {
            entidadBancariaPrepService: function () {
              return;
            }
          }
        })
        .state("app.entidadesbancarias.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/entidadbancaria/form.html",
          controller: "EntidadBancariaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.entidadesbancarias",
            label: "Editar Entidad Bancaria",
            description: "Edición de entidad bancaria #"
          },
          resolve: {
            entidadBancariaPrepService: entidadBancariaPrepService
          }
        })
        .state("app.entidadesbancarias.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/entidadbancaria/form.html",
          controller: "EntidadBancariaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.entidadesbancarias",
            label: "Ver Entidad Bancaria",
            description: "Ver entidad bancaria #"
          },
          resolve: {
            entidadBancariaPrepService: entidadBancariaPrepService
          }
        })
        .state("app.tipofondofijo", {
          url: "/tipofondofijo",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Tipo Fondo Fijo"
          }
        })
        .state("app.tipofondofijo.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "TipoFondoFijoIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipofondofijo",
            label: "Tipo Fondo Fijo",
            description: "Listado de Tipos de Fondo Fijos",
            skip: true
          }
        })
        .state("app.tipofondofijo.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/tipofondofijo/form.html",
          controller: "TipoFondoFijoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipofondofijo",
            label: "Nuevo Tipo Fondo Fijo",
            description: "Creación de un nuevo tipo de fondo fijo"
          },
          resolve: {
            tipoFondoFijoPrepService: function () {
              return;
            }
          }
        })
        .state("app.tipofondofijo.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/tipofondofijo/form.html",
          controller: "TipoFondoFijoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipofondofijo",
            label: "Editar Tipo de Fondo Fijo",
            description: "Edición de tipo de fondo fijo #"
          },
          resolve: {
            tipoFondoFijoPrepService: tipoFondoFijoPrepService
          }
        })
        .state("app.tipofondofijo.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/tipofondofijo/form.html",
          controller: "TipoFondoFijoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipofondofijo",
            label: "Ver Tipo de Fondo Fijo",
            description: "Ver tipo de fondo fijo #"
          },
          resolve: {
            tipoFondoFijoPrepService: tipoFondoFijoPrepService
          }
        })
        .state("app.tipomovimientobancario", {
          url: "/tipomovimientobancario",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Tipo Movimiento Bancario"
          }
        })
        .state("app.tipomovimientobancario.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "TipoMovimientoBancarioIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipomovimientobancario",
            label: "Tipo Movimiento Bancario",
            description: "Listado de Tipos de Movimientos Bancario",
            skip: true
          }
        })
        .state("app.tipomovimientobancario.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/tipomovimientobancario/form.html",
          controller: "TipoMovimientoBancarioFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipomovimientobancario",
            label: "Nuevo Tipo Movimiento Bancario",
            description: "Creación de un nuevo tipo de movimiento bancario"
          },
          resolve: {
            tipoMovimientoBancarioPrepService: function () {
              return;
            }
          }
        })
        .state("app.tipomovimientobancario.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/tipomovimientobancario/form.html",
          controller: "TipoMovimientoBancarioFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipomovimientobancario",
            label: "Editar Tipo de Movimiento Bancario",
            description: "Edición de tipo de movimiento bancario #"
          },
          resolve: {
            tipoMovimientoBancarioPrepService: tipoMovimientoBancarioPrepService
          }
        })
        .state("app.tipomovimientobancario.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/tipomovimientobancario/form.html",
          controller: "TipoMovimientoBancarioFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipomovimientobancario",
            label: "Ver Tipo de Movimiento Bancario",
            description: "Ver tipo de movimiento bancario #"
          },
          resolve: {
            tipoMovimientoBancarioPrepService: tipoMovimientoBancarioPrepService
          }
        })
        .state("app.tipoordenpago", {
          url: "/tipoordenpago",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Tipo Orden Pago"
          }
        })
        .state("app.tipoordenpago.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "TipoOrdenPagoIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipoordenpago",
            label: "Tipo Orden Pago",
            description: "Listado de Tipos de Orden de Pago",
            skip: true
          }
        })
        .state("app.tipoordenpago.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/tipoordenpago/form.html",
          controller: "TipoOrdenPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipoordenpago",
            label: "Nuevo Tipo Orden de Pago",
            description: "Creación de un nuevo tipo orden de pago"
          },
          resolve: {
            tipoOrdenPagoPrepService: function () {
              return;
            }
          }
        })
        .state("app.tipoordenpago.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/tipoordenpago/form.html",
          controller: "TipoOrdenPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipoordenpago",
            label: "Editar Tipo Orden de Pago",
            description: "Edición de tipo orden de pago #"
          },
          resolve: {
            tipoOrdenPagoPrepService: tipoOrdenPagoPrepService
          }
        })
        .state("app.tipoordenpago.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/tipoordenpago/form.html",
          controller: "TipoOrdenPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.tipoordenpago",
            label: "Ver Tipo Orden de Pago",
            description: "Ver tipo de orden de pago #"
          },
          resolve: {
            tipoOrdenPagoPrepService: tipoOrdenPagoPrepService
          }
        })
        .state("app.ordenpago", {
          url: "/ordenpago",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Orden Pago"
          }
        })
        .state("app.ordenpago.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "OrdenPagoIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.ordenpago",
            label: "Orden Pago",
            description: "Listado de Ordenes de Pago",
            skip: true
          }
        })
        .state("app.ordenpago.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/ordenpago/form.html",
          controller: "OrdenPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.ordenpago",
            label: "Nueva Orden de Pago",
            description: "Creación de una nueva orden de pago"
          },
          resolve: {
            ordenPagoPrepService: function () {
              return;
            }
          }
        })
        .state("app.ordenpago.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/ordenpago/form.html",
          controller: "OrdenPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.ordenpago",
            label: "Editar Orden de Pago",
            description: "Edición de orden de pago #"
          },
          resolve: {
            ordenPagoPrepService: ordenPagoPrepService
          }
        })
        .state("app.ordenpago.edit2", {
          url: "/rrhh/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/ordenpago/form.rrhh.html",
          controller: "OrdenPagoRRHHFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.ordenpago",
            label: "Editar Orden de Pago",
            description: "Edición de orden de pago #"
          },
          resolve: {
            ordenPagoPrepService: ordenPagoPrepService
          }
        })
        .state("app.ordenpago.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/ordenpago/form.html",
          controller: "OrdenPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.ordenpago",
            label: "Ver Orden de Pago",
            description: "Ver orden de pago #"
          },
          resolve: {
            ordenPagoPrepService: ordenPagoPrepService
          }
        })
        .state("app.ordenpago.view2", {
          url: "/rrhh/:id",
          templateUrl: "qualita-components/tesoreria/views/ordenpago/form.rrhh.html",
          controller: "OrdenPagoRRHHFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.ordenpago",
            label: "Ver Orden de Pago",
            description: "Ver orden de pago #"
          },
          resolve: {
            ordenPagoPrepService: ordenPagoPrepService
          }
        })
        .state("app.ordenpago.aplicaranticipo", {
          url: "/:id/aplicar?mode",
          templateUrl: "qualita-components/tesoreria/views/ordenpago/form.html",
          controller: "OrdenPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.ordenpago",
            label: "Aplicación de Anticipo",
            description: "Aplicación de Anticipo #"
          },
          resolve: {
            ordenPagoPrepService: ordenPagoPrepService
          }
        })
        .state("app.movimientobancario", {
          url: "/movimientobancario",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Movimientos Bancarios"
          }
        })
        .state("app.movimientobancario.list", {
          url: "",
          //templateUrl: 'views/template_base/list.html',
          templateUrl: "qualita-components/tesoreria/views/movimientobancario/index.html",
          controller: "MovimientoBancarioIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.movimientobancario",
            label: "Movimientos Bancarios",
            description: "Listado de Movimientos Bancarios",
            skip: true
          }
        })
        .state("app.movimientobancario.consulta", {
          url: "/consulta",
          templateUrl: "qualita-components/tesoreria/views/movimientobancario/consulta.html",
          controller: "MovimientoBancarioConsultaCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.movimientobancario",
            label: "Movimiento Bancario",
            description: "Consulta Movimiento Bancario"
          }
        })
        .state("app.movimientobancario.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/movimientobancario/form.html",
          controller: "MovimientoBancarioFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.movimientobancario",
            label: "Nuevo Movimiento Bancario",
            description: "Creación de un nuevo Movimiento Bancario"
          },
          resolve: {
            movimientoBancarioPrepService: function () {
              return;
            }
          }
        })
        .state("app.movimientobancario.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/movimientobancario/form.html",
          controller: "MovimientoBancarioFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.movimientobancario",
            label: "Editar Movimiento Bancario",
            description: "Edición de Movimiento Bancario #"
          },
          resolve: {
            movimientoBancarioPrepService: movimientoBancarioPrepService
          }
        })
        .state("app.movimientobancario.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/movimientobancario/form.html",
          controller: "MovimientoBancarioFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.movimientobancario",
            label: "Ver Movimiento Bancario",
            description: "Ver tipo de Movimiento Bancario #"
          },
          resolve: {
            movimientoBancarioPrepService: movimientoBancarioPrepService
          }
        })
        .state("app.planillafondofijo", {
          url: "/planillafondofijo",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Planilla de Fondo Fijo"
          }
        })
        .state("app.planillafondofijo.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "PlanillaFondoFijoIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.planillafondofijo",
            label: "Planilla de Fondo Fijo",
            description: "Listado de Planillas de Fondo Fijo",
            skip: true
          }
        })
        .state("app.planillafondofijo.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/planillafondofijo/form.html",
          controller: "PlanillaFondoFijoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.planillafondofijo",
            label: "Nuevo Planilla de Fondo Fijo",
            description: "Creación de una Planilla de Fondo Fijo"
          },
          resolve: {
            planillaFondoFijoPrepService: function () {
              return;
            }
          }
        })
        .state("app.planillafondofijo.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/planillafondofijo/form.html",
          controller: "PlanillaFondoFijoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.planillafondofijo",
            label: "Editar Planilla de Fondo Fijo",
            description: "Edición de Planilla de Fondo Fijo #"
          },
          resolve: {
            planillaFondoFijoPrepService: planillaFondoFijoPrepService
          }
        })
        .state("app.planillafondofijo.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/planillafondofijo/form.html",
          controller: "PlanillaFondoFijoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.planillafondofijo",
            label: "Ver Planilla de Fondo Fijo",
            description: "Ver Planilla de Fondo Fijo #"
          },
          resolve: {
            planillaFondoFijoPrepService: planillaFondoFijoPrepService
          }
        })
        .state("app.desembolsoordenpago", {
          url: "/desembolsoordenpago",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Desembolso Orden de Pago"
          }
        })
        .state("app.desembolsoordenpago.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "DesembolsoOrdenPagoIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.desembolsoordenpago",
            label: "Desembolso de Orden de Pago",
            description: "Listado de Desembolso de Orden de Pago",
            skip: true
          }
        })
        .state("app.desembolsoordenpago.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/desembolsoordenpago/form.html",
          controller: "DesembolsoOrdenPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.desembolsoordenpago",
            label: "Nuevo Desembolso de Orden de Pago",
            description: "Creación de un nuevo Desembolso de Orden de Pago"
          },
          resolve: {
            desembolsoOrdenPagoPrepService: function () {
              return;
            }
          }
        })
        .state("app.desembolsoordenpago.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/desembolsoordenpago/form.html",
          controller: "DesembolsoOrdenPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.desembolsoordenpago",
            label: "Editar Desembolso de Orden de Pago",
            description: "Edición de Desembolso de Orden de Pago #"
          },
          resolve: {
            desembolsoOrdenPagoPrepService: desembolsoOrdenPagoPrepService
          }
        })
        .state("app.desembolsoordenpago.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/desembolsoordenpago/form.html",
          controller: "DesembolsoOrdenPagoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.desembolsoordenpago",
            label: "Ver Desembolso de Orden de Pago",
            description: "Ver Desembolso de Orden de Pago #"
          },
          resolve: {
            desembolsoOrdenPagoPrepService: desembolsoOrdenPagoPrepService
          }
        })
        .state("app.estadocuentaproveedor", {
          url: "/estadocuentaproveedor",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Estado Cuenta Proveedores"
          }
        })
        .state("app.estadocuentaproveedor.list", {
          url: "",
          templateUrl: "qualita-components/tesoreria/views/estadocuentaproveedor/consulta.html",
          controller: "EstadoCuentaProveedorIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.estadocuentaproveedor",
            label: "Estado Cuenta Proveedores",
            description: "Estado Cuenta Proveedores",
            skip: true
          }
        })
        .state("app.desembolsoordenpago2", {
          url: "/desembolsoordenpago2",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Desembolso Orden de Pago"
          }
        })
        .state('app.consultacheque', {
          url: '/consultacheque',
          //templateUrl: 'qualita-components/stock/views/consultamovimientos/consulta.html',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Consulta de Cheques Cobrados'
          }
        })
        .state('app.consultacheque.list', {
          url: '',
          templateUrl: 'qualita-components/tesoreria/views/consultacheque/consulta.html',
          //templateUrl: 'views/template_base/list.html',
          controller: 'ConsultaChequeIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.consultacheque',
            label: 'Consulta de Cheques Cobrados',
            description: 'Consulta de Cheques Cobrados',
            skip: true
          }
        })
        .state('app.consultacobro', {
          url: '/consultacobro',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Consulta de Cobro'
          }
        })
        .state('app.consultacobro.list', {
          url: '',
          templateUrl: 'qualita-components/tesoreria/views/consultacobro/consulta.html',
          //templateUrl: 'views/template_base/list.html',
          controller: 'ConsultaCobroCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.consultacobro',
            label: 'Consulta de Cobros',
            description: 'Consulta de Cobros',
            skip: true
          }
        })
        .state('app.comprobantespendientes', {
          url: '/comprobantespendientes',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Consulta de Comprobantes Pendientes'
          }
        })
        .state('app.comprobantespendientes.list', {
          url: '',
          templateUrl: 'qualita-components/tesoreria/views/comprobantespendientes/consulta.html',
          controller: 'ComprobantePendienteIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.comprobantespendientes',
            label: 'Consulta de Comprobantes Pendientes',
            description: 'Consulta de Comprobantes Pendientes',
            skip: true
          }
        })
        .state("app.consultasaldoproveedor", {
          url: "/consultasaldoproveedor",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Consulta de Saldo de Proveedores"
          }
        })
        .state("app.consultasaldoproveedor.list", {
          url: "",
          templateUrl: "qualita-components/tesoreria/views/consultasaldoproveedor/consulta.html",
          controller: "ConsultaSaldoProveedorIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.consultasaldoproveedor",
            label: "Consulta de Saldo de Proveedores",
            description: "Consulta de Saldo de Proveedores",
            skip: true
          }
        })
        .state("app.denominaciontarjeta", {
          url: "/denominaciontarjeta",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Denominaciones de Tarjetas"
          }
        })
        .state("app.denominaciontarjeta.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "DenominacionTarjetaIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.denominaciontarjeta",
            label: "Denominaciones de Tarjetas",
            description: "Listado de Denominaciones de Tarjetas",
            skip: true
          }
        })
        .state("app.denominaciontarjeta.new", {
          url: "/new",
          templateUrl: "qualita-components/tesoreria/views/denominaciontarjeta/form.html",
          controller: "DenominacionTarjetaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.denominaciontarjeta",
            label: "Nueva Denominaciones de Tarjeta",
            description: "Creación de una nueva Denominaciones de Tarjeta"
          },
          resolve: {
            denominacionTarjetaPrepService: function () {
              return;
            }
          }
        })
        .state("app.denominaciontarjeta.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/tesoreria/views/denominaciontarjeta/form.html",
          controller: "DenominacionTarjetaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.denominaciontarjeta",
            label: "Editar Denominaciones de Tarjetas",
            description: "Edición de Denominaciones de Tarjetas #"
          },
          resolve: {
            denominacionTarjetaPrepService: denominacionTarjetaPrepService
          }
        })
        .state("app.denominaciontarjeta.view", {
          url: "/:id",
          templateUrl: "qualita-components/tesoreria/views/denominaciontarjeta/form.html",
          controller: "DenominacionTarjetaFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.denominaciontarjeta",
            label: "Ver Denominaciones de Tarjetas",
            description: "Ver Denominaciones de Tarjetas #"
          },
          resolve: {
            denominacionTarjetaPrepService: denominacionTarjetaPrepService
          }
        })
    }
  ])
  .animation(".extra-details", function () {
    return {
      enter: function (element, done) {
        element.css("opacity", 0);
        jQuery(element).animate(
          {
            opacity: 1
          },
          done
        );

        // optional onDone or onCancel callback
        // function to handle any post-animation
        // cleanup operations
        return function (isCancelled) {
          if (isCancelled) {
            jQuery(element).stop();
          }
        };
      },
      leave: function (element, done) {
        element.css("opacity", 1);
        jQuery(element).animate(
          {
            opacity: 0
          },
          done
        );

        // optional onDone or onCancel callback
        // function to handle any post-animation
        // cleanup operations
        return function (isCancelled) {
          if (isCancelled) {
            jQuery(element).stop();
          }
        };
      },
      move: function (element, done) {
        element.css("opacity", 0);
        jQuery(element).animate(
          {
            opacity: 1
          },
          done
        );

        // optional onDone or onCancel callback
        // function to handle any post-animation
        // cleanup operations
        return function (isCancelled) {
          if (isCancelled) {
            jQuery(element).stop();
          }
        };
      }
    };
  });

function tipoRetencionPrepService(TipoRetencionFactory, $stateParams) {
  return TipoRetencionFactory.get($stateParams.id, "TipoRetencionForm").$promise;
}
tipoRetencionPrepService.$inject = ["TipoRetencionFactory", "$stateParams"];

function chequeraPrepService(ChequeraFactory, $stateParams) {
  return ChequeraFactory.get($stateParams.id, "ChequeraForm").$promise;
}
chequeraPrepService.$inject = ["ChequeraFactory", "$stateParams"];

function chequePrepService(ChequeFactory, $stateParams) {
  return ChequeFactory.get($stateParams.id, "ChequeForm").$promise;
}
chequePrepService.$inject = ["ChequeFactory", "$stateParams"];

function cuentaBancariaPrepService(cuentaBancariaFactory, $stateParams) {
  return cuentaBancariaFactory.get($stateParams.id, "CuentaBancariaForm").$promise;
}
cuentaBancariaPrepService.$inject = ["cuentaBancariaFactory", "$stateParams"];

function entidadBancariaPrepService(EntidadBancariaFactory, $stateParams) {
  return EntidadBancariaFactory.get($stateParams.id, "BaseForm").$promise;
}
entidadBancariaPrepService.$inject = ["EntidadBancariaFactory", "$stateParams"];

function tipoFondoFijoPrepService(TipoFondoFijoFactory, $stateParams) {
  return TipoFondoFijoFactory.get($stateParams.id, "BaseForm").$promise;
}
tipoFondoFijoPrepService.$inject = ["TipoFondoFijoFactory", "$stateParams"];

function tipoMovimientoBancarioPrepService(TipoMovimientoBancarioFactory, $stateParams) {
  return TipoMovimientoBancarioFactory.get($stateParams.id, "TipoMovimientoBancarioForm").$promise;
}
tipoMovimientoBancarioPrepService.$inject = ["TipoMovimientoBancarioFactory", "$stateParams"];

function tipoOrdenPagoPrepService(TipoOrdenPagoFactory, $stateParams) {
  return TipoOrdenPagoFactory.get($stateParams.id, "TipoOrdenPagoForm").$promise;
}
tipoOrdenPagoPrepService.$inject = ["TipoOrdenPagoFactory", "$stateParams"];

function movimientoBancarioPrepService(
  MovimientoBancarioFactory,
  $stateParams
) {
  return MovimientoBancarioFactory.get($stateParams.id, "MovimientoBancarioForm").$promise;
}

function planillaFondoFijoPrepService(PlanillaFondoFijoFactory, $stateParams) {
  return PlanillaFondoFijoFactory.get($stateParams.id, "PlanillaFondoFijoForm").$promise;
}

function comprobanteRetencionPrepService(
  ComprobanteRetencionFactory,
  $stateParams
) {
  return ComprobanteRetencionFactory.get($stateParams.id, "ComprobanteRetencionForm").$promise;
}
comprobanteRetencionPrepService.$inject = ["ComprobanteRetencionFactory", "$stateParams"];

function ordenPagoPrepService(OrdenPagoFactory, $stateParams) {
  return OrdenPagoFactory.get($stateParams.id, "OrdenPagoForm").$promise;
}
ordenPagoPrepService.$inject = ["OrdenPagoFactory", "$stateParams"];

function desembolsoOrdenPagoPrepService(
  DesembolsoOrdenPagoFactory,
  $stateParams
) {
  return DesembolsoOrdenPagoFactory.get($stateParams.id, "DesembolsoOrdenPagoForm").$promise;
}
desembolsoOrdenPagoPrepService.$inject = ["DesembolsoOrdenPagoFactory", "$stateParams"];

function denominacionTarjetaPrepService(DenominacionTarjetaFactory, $stateParams) {
  return DenominacionTarjetaFactory.get($stateParams.id, "BaseForm").$promise;
}
denominacionTarjetaPrepService.$inject = ["DenominacionTarjetaFactory", "$stateParams"];

'use strict';

/**
 * @ngdoc service
 * @name qualita.cuentabancaria
 * @description
 * # cuentabancaria
 * Factory in the qualita.
 */
angular.module('qualita.tesoreria')
  .factory('cuentaBancariaFactory', ['$resource', 'baseurl', function ($resource, baseurl) {

    var CuentaBancaria = $resource(baseurl.getBaseUrl() + '/cuentabancaria/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = params.view == undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = 'BaseList';
        }
        return CuentaBancaria.query(params);
      },

      get: function (id, view) {
        return CuentaBancaria.get({ id: id, view: view != undefined ? view : 'base' });
      },

      create: function (attrs) {
        return new CuentaBancaria(attrs);
      },

      save: function (cuentaBancaria) {
        return (cuentaBancaria.id) ? cuentaBancaria.$update() : cuentaBancaria.$save();
      },

      remove: function (cuentaBancaria) {
        return cuentaBancaria.$remove();
      }
    };
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.
 * @description
 * # 
 * Factory in the qualita.
 */
angular.module('qualita.tesoreria')
  .factory('EntidadBancariaFactory', ['$resource', 'baseurl', function ($resource, baseurl) {

    var EntidadBancaria = $resource(baseurl.getBaseUrl() + '/entidadesbancarias/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params) {
        if (params) {
          params.view = "BaseList";
        } else {
          params = [];
          params.view = "BaseList";
        }
        return EntidadBancaria.query(params);
      },

      get: function (id, view) {
        if (typeof (view) === 'undefined') view = "base";
        return EntidadBancaria.get({ id: id, view: view });
      },

      create: function (attrs) {
        return new EntidadBancaria(attrs);
      },

      save: function (entidadBancaria) {
        return (entidadBancaria.id) ? entidadBancaria.$update() : entidadBancaria.$save();
      },

      remove: function (entidadBancaria) {
        return entidadBancaria.$remove();
      }
    };
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.
 * @description
 * # 
 * Factory in the qualita.
 */
angular.module('qualita.tesoreria')
  .factory('TipoFondoFijoFactory', ['$resource', 'baseurl', function ($resource, baseurl) {

    var TipoFondoFijo = $resource(baseurl.getBaseUrl() + '/tipofondofijo/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params) {
        if (params) {
          params.view = params.view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = 'BaseList';
        }
        return TipoFondoFijo.query(params);
      },

      get: function (id, view) {
        return TipoFondoFijo.get({ id: id, view: view != undefined ? view : 'base' });
      },

      create: function (attrs) {
        return new TipoFondoFijo(attrs);
      },

      save: function (tipoFondoFijo) {
        return (tipoFondoFijo.id) ? tipoFondoFijo.$update() : tipoFondoFijo.$save();
      },

      remove: function (tipoFondoFijo) {
        return tipoFondoFijo.$remove();
      }
    };
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.
 * @description
 * # 
 * Factory in the qualita.
 */
angular.module('qualita.tesoreria')
  .factory('TipoMovimientoBancarioFactory', ['$resource', '$http', 'baseurl', function ($resource, $http, baseurl) {

    var TipoMovimientoBancario = $resource(baseurl.getBaseUrl() + '/tipomovimientobancario/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });



    return {
      all: function (params) {
        if (params) {
          params.view = params.view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = 'BaseList';
        }
        return TipoMovimientoBancario.query(params);
      },

      get: function (id, view) {
        return TipoMovimientoBancario.get({ id: id, view: view != undefined ? view : 'base' });
      },

      create: function (attrs) {
        return new TipoMovimientoBancario(attrs);
      },

      save: function (tipoMovimientoBancario) {
        return (tipoMovimientoBancario.id) ? tipoMovimientoBancario.$update() : tipoMovimientoBancario.$save();
      },

      remove: function (tipoMovimientoBancario) {
        return tipoMovimientoBancario.$remove();
      },

      recuperarParche: function (id, view) {
        return $http({
          url: baseurl.getBaseUrl() + '/tipomovimientobancario/parche/' + id + '?view=' + view != undefined ? view : 'TipoMovimientoBancarioForm',
          method: "GET"
        });
      }
    };
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.
 * @description
 * #
 * Factory in the qualita.
 */
angular.module('qualita.tesoreria')
  .factory('MovimientoBancarioFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var MovimientoBancario = $resource(baseurl.getBaseUrl() + '/movimientobancario/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = params.view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = 'BaseList';
        }
        return MovimientoBancario.query(params);
      },

      get: function (id, view) {
        console.log(view);
        return MovimientoBancario.get({ id: id, view: view != undefined ? view : 'base' });
      },

      create: function (attrs) {
        return new MovimientoBancario(attrs);
      },

      save: function (movimientobancario) {
        return (movimientobancario.id) ? movimientobancario.$update() : movimientobancario.$save();
      },

      remove: function (movimientobancario) {
        return movimientobancario.$remove();
      },

      reenviar: function (id) {
        return $http({
          url: baseurl.getBaseUrl() + '/movimientobancario/reenviar/' + id,
          method: "PUT"
        });
      },

      confirmarMovimento: function (id) {
        return $http({
          url: baseurl.getBaseUrl() + '/movimientobancario/confirmarmovimiento/' + id,
          method: "PUT"
        });
      },

      desconfirmarMovimento: function (id) {
        return $http({
          url: baseurl.getBaseUrl() + '/movimientobancario/desconfirmarmovimiento/' + id,
          method: "PUT"
        });
      },


      getSaldoInicial: function (idCuenta, fecha) {
        return $http({
          url: baseurl.getBaseUrl() + '/movimientobancario/saldoanterior',
          method: "GET",
          params: { fecha: fecha, idCuenta: idCuenta }
        });
      },

      recalcularSaldo: function(idCuenta) {
        return $http({
                url: baseurl.getBaseUrl() + '/movimientobancario/recalcularsaldo',
                method: "PUT",
                params: {idCuenta: idCuenta}
              });
      },

    }
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.
 * @description
 * #
 * Factory in the qualita.
 */
angular.module('qualita.tesoreria')
  .factory('TipoOrdenPagoFactory', ['$resource', 'baseurl', function ($resource, baseurl) {

    var TipoOrdenPago = $resource(baseurl.getBaseUrl() + '/tipoordenpago/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });



    return {
      all: function (params) {
        if (!params.view) {
          params.view = 'BaseList';
        }
        return TipoOrdenPago.query(params);
      },
      get: function (id, view) {
        return TipoOrdenPago.get({ id: id, view: view != undefined ? view : 'base' });
      },

      create: function (attrs) {
        return new TipoOrdenPago(attrs);
      },

      save: function (tipoOrdenPago) {
        return (tipoOrdenPago.id) ? tipoOrdenPago.$update() : tipoOrdenPago.$save();
      },

      remove: function (tipoOrdenPago) {
        return tipoOrdenPago.$remove();
      }
    };
  }]);

"use strict";

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module("qualita.tesoreria").factory("TesoreriaLangFactory", [
    "$resource",
    "baseurl",
    "$http",
    "$translatePartialLoader",
    "$translate",
    function($resource, baseurl, $http, $translatePartialLoader, $translate) {
        var translationsNeeded = [
            "CURRENCY",
            "WRONG_FIELDS",
            "BACK",
            "NUMBER",
            "ACCOUNT_NUMBER",
            "ACCOUNT_NAME",
            "ENTITY_NAME",
            "CHEQUERA_TYPE",
            "FROM_NUMBER",
            "TO_NUMBER",
            "SERIE_NUMBER",
            "COUNT",
            "NAME",
            "STATUS",
            "RETENTION_TYPE",
            "IVA_RETENTION",
            "IVA_RETENTION_CONSOLIDADA",
            "IVA_RETENTION_10",
            "IVA_RETENTION_5",
            "RENT_RETENTION",
            "RETENTION_CONCEPT",
            "IVA_RETENTION_TYPE",
            "RENT_RETENTION_TYPE",
            "IVA_RETENTION_TYPE_LABEL",
            "RENT_RETENTION_TYPE_LABEL",
            "NEW_RETENTION_TYPE",
            "EDIT_RETENTION_TYPE",
            "VIEW_RETENTION_TYPE",
            "CHEQUE_DIFERIDO",
            "COUNT_LABEL",
            "RECEPTION_RESPONSABLE",
            "CHEQUERA",
            "NEW_CHEQUERA",
            "EDIT_CHEQUERA",
            "VIEW_CHEQUERA",
            "ACCOUNT_NUMBER_LABEL",
            "ACCOUNT_NAME_LABEL",
            "DATE_OPENING",
            "DATE_INITIAL_BALANCE",
            "INITIAL_BALANCE",
            "PLANTILLA_CHEQUE_DIA",
            "PLANTILLA_CHEQUE_DIFERIDO",
            "RUC",
            "FAIL_DELET_CUENTA",
            "FAIL_DELET_ENTIDAD",
            "TYPE_FONDO_FIJO_NAME",
            "MAX_FACTURA",
            "MAX_TOTAL",
            "FAIL_DELET_TYPE_FONDO_FIJO",
            "TYPE_BANK_MOVEMENT_NAME",
            "TYPE",
            "EDITABLE",
            "ID",
            "NOMBRE",
            "DIRECCION",
            "TELEFONO",
            "EMAIL",
            "ACTIVE",
            "NO_EXISTE_TIPO_RETENCION",
            "UTILIZAR_EN_OP",
            "CLASS",
            "BANK_MOVEMENT",
            "MOVEMENT_TYPE",
            "TRANSACTION_ACCOUNT",
            "ENTRADA",
            "SALIDA",
            "CONFIRMED",
            "TYPE_BANK_MOVEMENT_NAME",
            "TYPE",
            "EDITABLE",
            "ID",
            "NOMBRE",
            "DIRECCION",
            "TELEFONO",
            "EMAIL",
            "CLASS",
            "NEW_TIPO_FONDO_FIJO",
            "EDIT_TIPO_FONDO_FIJO",
            "VIEW_TIPO_FONDO_FIJO",
            "NEW_BANK_ACCOUNT",
            "EDIT_BANK_ACCOUNT",
            "VIEW_BANK_ACCOUNT",
            "TYPES_PAY_ORDER",
            "BANK_ACCOUNTS",
            "TYPE",
            "NEW_TIPO_OP",
            "EDIT_TIPO_OP",
            "VIEW_TIPO_OP",
            "NEW_TIPO_MOV_BANCARIO",
            "EDIT_TIPO_MOV_BANCARIO",
            "VIEW_TIPO_MOV_BANCARIO",
            "BANK_ENTITIES",
            "NEW_BANK_ENTITY",
            "EDIT_BANK_ENTITY",
            "VIEW_BANK_ENTITY",
            "ENTRADA",
            "SALIDA",
            "PLANILLA_FONDO_FIJO",
            "PLANILLAS_FONDO_FIJO",
            "NEW_PLANILLA_FONDO_FIJO",
            "EDIT_PLANILLA_FONDO_FIJO",
            "VIEW_PLANILLA_FONDO_FIJO",
            "DATE",
            "PROVEEDOR",
            "TIPO_FONDO_FIJO",
            "COMMENT",
            "BUSQUEDA_COMPROBANTES",
            "SUCURSAL_PROVEEDOR",
            "TIPO_COMPROBANTE",
            "GRUPO_COMPRA",
            "DATE_FROM",
            "DATE_TO",
            "DETALLES_PLANILLA_FONDO_FIJO",
            "NUMERO_FACTURA",
            "IMPORTE",
            "SALDO",
            "BANK_DEPOSIT",
            "FAIL_DELETE_BANK_DEPOSIT",
            "TOTAL_EFECTIVO",
            "TOTAL_CHEQUE_ESTE",
            "TOTAL_CHEQUE_OTRO",
            "EMITIR_PLANILLA_FONDO_FIJO",
            "EMITIR_PLANILLA_FONDO_FIJO_MSG",
            "PLANILLA_FONDO_FIJO_EMITIDA",
            "SUCURSAL",
            "SELECCIONAR_TIPO_FONDO_FIJO",
            "NEW_BANK_MOVEMENT",
            "EDIT_BANK_MOVEMENT",
            "VIEW_BANK_MOVEMENT",
            "BANK_MOVEMENTS",
            "NEW_BANK_DEPOSIT",
            "EDIT_BANK_DEPOSIT",
            "VIEW_BANK_DEPOSIT",
            "KIND",
            "SAME_NAME_TIPO_FONDO_FIJO",
            "RETENTION_CLASS",
            "IMPORTE_RETENCION_IVA",
            "IMPORTE_RETENCION_RENTA",
            "NUMERO_COMPROBANTE",
            "MONTO_GRAVADO",
            "MONTO_IMPUESTO",
            "MONTO_TOTAL",
            "NEW_COMPROBANTE_RETENCION",
            "COMPROBANTE_RETENCION",
            "EDIT_COMPROBANTE_RETENCION",
            "VIEW_COMPROBANTE_RETENCION",
            "ORDEN_PAGO",
            "ORDENES_PAGO",
            "FAIL_DELETE_ORDEN_PAGO",
            "NEW_ORDEN_PAGO",
            "EDIT_ORDEN_PAGO",
            "VIEW_ORDEN_PAGO",
            "TIPO_ORDEN_PAGO",
            "CALCULAR_RETENCION",
            "BENEFICIARIO",
            "FORMA_PAGO",
            "CODIGO_PAGO",
            "CUENTA_CONTABLE",
            "DETALLES_ORDEN_PAGO",
            "DETALLES_FONDO_FIJO",
            "EXPIRATION_DATE_FROM",
            "EXPIRATION_DATE_TO",
            "CUOTA",
            "NUMERO_COMPROBANTE_SHORT",
            "TIPO_CAMBIO",
            "INGRESE_PROVEEDOR_WARNING",
            "CODE",
            "ESTADO_CUENTA_PROVEEDOR",
            "CONFIRM_MOV",
            "CONFIRM_MOV_DETAIL",
            "CONFIRM_MOV_FAILED",
            "CONFIRM_MOV_FAILED_DETAIL",
            "OK",
            "CANCEL",
            "PROVEEDOR_REQUIRED",
            "WRONG_IMPORTE_PAGAR",
            "RETENTION_CONCEPT_IVA",
            "VIEW_CHEQUE",
            "MONTO_CHEQUE",
            "SELECCIONAR_MONEDA",
            "CONFIRMAR_ORDEN_PAGO",
            "CONFIRMAR_ORDEN_PAGO_FAILED",
            "CONFIRMAR_ORDEN_PAGO_Q",
            "CONFIRMAR_ORDEN_PAGO_MSG",
            "APLICAR_ANTICIPO",
            "ERRORES_EN_ARCHIVO",
            "MONTO_TOTAL_APLICADO",
            "SALDO_APLICAR",
            "NUMERO_DESPACHO",
            "TIPO_CHEQUE",
            "DIFERIDO",
            "AL_DIA",
            "EMITIR_CHEQUE",
            "ANULAR_CONFIRMATION",
            "PERMANENT_OPERATION_WARNING",
            "SEARCH",
            "CLEAN_FILTERS",
            "CONFIRMAR",
            "EMITIR_CHEQUE_Q",
            "EMITIR_CHEQUE_MSG",
            "GENERAR_DEBITO",
            "GENERAR_DEBITO_Q",
            "GENERAR_DEBITO_FAILED",
            "CONFIRMAR_TRANSFERENCIA",
            "CONFIRMAR_TRANSFERENCIA_Q",
            "CONFIRMAR_TRANSFERENCIA_FAILED",
            "CONFIRMAR_EMISION_CHEQUE_Q",
            "CONFIRMAR_EMISION_CHEQUE_WARNING",
            "SAME_NAME_RECORD_FOUND",
            "DATE_FROM_DATE_TO_ERROR",
            "COMPROBANTES_NEEDED",
            "ORIGIN_ACCOUNT_NAME",
            "DESTINY_ACCOUNT_NAME",
            "DESCRIPTION_ACCOUNT_MOV",
            "PAYMENTS_ORDERS",
            "DATE_DIF",
            "CHECK_CONCEPT",
            "COMMERCIAL_CONTACT",
            "SAME_NAME_BANK_ENTITY",
            "SAME_NAME_MOVEMENT_TYPE",
            "CONFIRMAR_DEPOSITO_Q",
            "CONFIRMAR_DEPOSITO_WARNING",
            "RUC_NOT_VALID",
            "SAME_NAME_BANK_ACCOUNT",
            "SAME_CHEQUERA",
            "SAME_NAME_TIPO_RETENCION",
            "IMPORTE_TOTAL",
            "CHECKOUT_FROM_LEFT_TO",
            "ACCOUNT_TYPE",
            "ERROR_FILTERING_ESTADO_CUENTA_PROVEEDOR",
            "DESEMBOLSO_ORDENES_PAGO",
            "TOTAL_AMOUNT",
            "PROVEEDOR_CLASS",
            "COMPROBANTE_REQUIRED",
            "ESTADO_RETENCION",
            "CONCEPTO",
            "FECHA_EMISION",
            "ERROR_MODIFICACION",
            "HECHAUKA_RETENCIONES",
            "EXPORTAR_HECHAUKA_RETENCIONES",
            "SELECCION_RANGO_HECHAUKA",
            "EXPORTAR_HECHUAKA_ERROR",
            "ANULAR_RETENCIONES",
            "ANULAR_RETENCIONES_Q",
            "ANULAR_RETENCIONES_MSG",
            "ANULAR_RETENCIONES_TITLE",
            "PRESENTAR_RETENCIONES",
            "PRESENTAR_RETENCIONES_Q",
            "PRESENTAR_RETENCIONES_MSG",
            "PRESENTAR_RETENCIONES_TITLE",
            "EXPORTAR_SET",
            "EXPORTAR_SET_TITLE",
            "IMPORTAR_SET",
            "IMPORTAR_SET_TITLE",
            "DETALLE_REQUERIDO",
            "ERROR_TIPO_RETENCION",
            "ERROR_FECHA_APERTURA_MAYOR",
            "ERROR_MONTO_CERO",
            "IMPORTE_RETENIDO_OP",
            "IMPORTE_PAGADO",
            "NEW_DESEMBOLSO_ORDEN_PAGO",
            "EDIT_DESEMBOLSO_ORDEN_PAGO",
            "VIEW_DESEMBOLSO_ORDEN_PAGO",
            "EMITIR_PLANILLA_MENSAJE",
            "TOTAL_IMPORTE_OC",
            "MENSAJE_MODAL_ANULAR",
            "PROVEEDORES",
            "MONTO",
            "COMENTARIO",
            "SUCURSAL",
            "CHEQUES",
            "GENERAR_DETALLE_DESEMBOLSO",
            "OPS_NOT_SELECTED",
            "NOT_ENOUGH_CHEQUES",
            "VARIOS_PROVEEDORES_UN_CHEQUE_WARNING",
            "ESTADO_CUENTA_PROVEEDOR",
            "PROVEEDOR",
            "MONEDA",
            "FECHA_DESDE",
            "FECHA_HASTA",
            "FILTRAR",
            "DELETE_WARNING",
            "NUMERO",
            "DELETE_WARNING_MOV_CONTRAPARTIDA",
            "NUMERO_MOVIMIENTO",
            "NUMERO_CONTRAPARTIDA",
            "CONSULTA_MOVIMIENTOS_BANCARIOS",
            "COBRO",
            "ORDEN_PAGO",
            "ORDEN_PAGO_TITLE",
            "ORDEN_PAGO_MESSAGE",
            "GRUPO_PROVEEDOR",
            "DESCONFIRMAR_ORDEN_PAGO",
            "DESCONFIRMAR_ORDEN_PAGO_FAILED",
            "APLICACION_YA_EXISTENTE",
            "EDITAR_APLICACION",
            "AGREGAR_APLICACION",
            "DESAPLICAR",
            "DESAPLICAR_Q",
            "DESCONFIRMAR_ORDEN_PAGO_Q",
            "DESCONFIRMAR_ORDEN_PAGO_MSG",
            "DESCONFIRMAR",
            "DESCONFIRM_MOV",
            "DESCONFIRM_MOV_DETAIL",
            "DESCONFIRM_MOV_FAILED",
            "DESCONFIRM_MOV_FAILED_DETAIL",
            "EMITIR_DESEMBOLSO",
            "EMITIR_DESEMBOLSO_Q",
            "EMITIR_DESEMBOLSO_MSG",
            "EMITIR_DESEMBOLSO_FAILED",
            "GRUPO_PROVEEDOR",
            "ANULAR",
            "ERROR_FECHA_PASADA",
            "ERROR_FECHA_FUTURA",
            "IMPRIMIR_ORDEN_PAGO",
            "FAIL_DELET_TYPE_PAY_ORDER",
            "TIPO_PROVEEDOR",
            "TIPO_COMPROBANTE_REQUIRED",
            "ERROR_TIPO__CAMBIO_FECHA",
            "ERROR_TIPO_CAMBIO",
            "ERROR_TIPO_CAMBIO_CUOTA",
            "OBSERVACION",
            "QUOTA_NUMBER",
            "DESCRIPCION",
            "IMPORTE_NETO",
            "DESCONFIRMAR_DESEMBOLSO",
            "NUMERO_OP",
            "ERROR_FECHA_PAGO",
            "CONSULTA_DESEMBOLSOS",
            "NO_ADMINISTRATIVE_PERIOD",
            "ENTITY_NAME_FROM",
            "ENTITY_NAME_TO",
            "CURRENCY_FROM",
            "CURRENCY_TO",
            "IMPORTE_CONVERTIDO",
            "NUMERO_OP",
            "FORMA_PAGO_ERROR",
            "CHEQUERA_VACIA",
            "EMITIR_CHEQUE_FAILED",
            "DELETE_WARNING_DESEMBOLSO",
            "COD_DESEMBOLSO",
            "FECHA_APLICACION_FAIL",
            "PROVEEDOR_APLICACIONES_PENDIENTES",
            "ERROR_EMISION_CHEQUE_PDF",
            "DETALLES_NOT_LOADED",
            "DETALLES_NOT_LOADED_ANTICIPO",
            "PROVEEDOR_APLICACIONES_PENDIENTES",
            "OP_RANGE_TITLE",
            "OP_RANGE",
            "SALDO_INICIAL",
            "NO_OPEN_PERIODS_NOW",
            "ENTRADA_MOVIMIENTO_PROVEEDOR",
            "SALIDA_MOVIMIENTO_PROVEEDOR",
            "NRO_CUENTA_ORIGEN",
            "NRO_CUENTA_DESTINO",
            "PERMITE_SOBREGIRO",
            "CONSULTA_CHEQUES",
            "CLIENTES",
            "FECHA_DIFERIDO_DESDE",
            "FECHA_DIFERIDO_HASTA",
            "PROVEEDOR_SINGLE",
            "ERROR_MISMA_CUENTA",
            "PROVEEDOR_INGRESE_CARACTERES",
            "PROCESO_COBRO",
            "FILTRAR",
            "INVALID_DATES_DIFERIDO",
            "FECHA_MOVIMIENTO",
            "CONSTRAINT_ERROR",
            "MISMO_MOVIMIENTO_ERROR",
            "NO_CHEQUES_GENERADOS",
            "COTIZACION_NO_EXISTE",
            "ELIMINAR_MOV",
            "CODIGO_COBRO",
            "CODIGO_PROC_COBRO",
            "REIMPRIMIR_CHEQUE",
            "ERROR_INCONSISTENCIA_APLICAR_SOBRE",
            "CONSULTA_SALDO_PROVEEDORES",
            "SALDO_PROVEEDORES",
            "PROYECTO",
            "TIPO_CAMBIO_SHORT",
            "CONSULTA_CHEQUES_EMITIDOS",
            "ENTITY_NAME_CHEQUE_COB",
            "NEW_DENOMINACION_TARJETA",
            "EDIT_DENOMINACION_TARJETA",
            "VIEW_DENOMINACION_TARJETA",
            "ERROR_EXTENSION_ADJUNTO_OP",
            "ERROR_TAMAÑO_ADJUNTO_OP",
            "ADJUNTOS_CON_RESTRICCIONES",
            "EXTENSIONES_SOPORTADAS",
            "REENVIAR_CHEQUERA",
            "REENVIAR_CHEQUERA_MSG",
            "REENVIAR_CHEQUERA_FAILED",
            "CONSULTA_COBROS",
            "ANULAR_PLANILLA_FONDO_FIJO",
            "ANULAR_PLANILLA_FONDO_FIJO_MSG",
            "ANULAR_PLANILLA_FAILED",
            "MONTO_EN_GUARANIES"
        ];
        return {
            getTranslations: function() {
                $translatePartialLoader.addPart("tesoreria");
                return $translate.refresh().then(function() {
                    return $translate(translationsNeeded);
                });
            }
        };
    }
]);
'use strict';


angular.module('qualita.tesoreria')
    .controller('TipoRetencionIndexCtrl',
        ["$scope", "TipoRetencionFactory", "TesoreriaLangFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "$filter", "UtilFactory", "CsvFactory", "baseurl", function ($scope, TipoRetencionFactory, TesoreriaLangFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal, $window, $state,
            $filter, UtilFactory, CsvFactory, baseurl) {

            var dateRender = function (data, type, full) {
                if (data) {
                    return moment.utc(data).format('DD/MM/YYYY');
                } else {
                    return "";
                }
            };

            var emptyDataRender = function (data) {
                //console.log(data);
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            TesoreriaLangFactory.getTranslations().then(function (translations) {
                var defaultColumnOrder = ['nombre', /* 'claseRetencion.descripcion'*/, 'retencionIva', 'retencionIva10', 'retencionIva5', 'conceptoIva.descripcion', 'retencionRenta' /*, 'concepto'*/,
                    'conceptoRenta.descripcion', 'activo', 'utilizarEnOrdenPago'
                ];
                $scope.options = {
                    'resource': 'tiposretencion',
                    'title': 'Tipos de Retención',
                    'view': 'TipoRetencionList',
                    //'staticFilter': staticFilter,
                    'factory': TipoRetencionFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'failedDeleteError': $filter('translate')('FAIL_DELETE_TIPO_RETENCION'),
                    'columns': [
                        //{'data': 'id', 'title': $filter('translate')('CODE')},
                        { 'data': 'nombre', 'title': $filter('translate')('NAME'), 'renderWith': 'emptyRender' },
                        //{'data': 'claseRetencion.descripcion', 'title': $filter('translate')('RETENTION_TYPE'), 'renderWith': 'emptyRender' },
                        { 'data': 'retencionIva', 'title': $filter('translate')('IVA_RETENTION_CONSOLIDADA'), 'class': 'dt-center', renderWith: monedaRender },
                        { 'data': 'retencionIva10', 'title': $filter('translate')('IVA_RETENTION_10'), 'class': 'dt-center', renderWith: monedaRender },
                        { 'data': 'retencionIva5', 'title': $filter('translate')('IVA_RETENTION_5'), 'class': 'dt-center', renderWith: monedaRender },
                        //{ 'data': 'tipoRetencionIva.descripcion', 'title': $filter('translate')('IVA_RETENTION_TYPE'), 'renderWith': 'emptyRender' },
                        { 'data': 'conceptoIva.descripcion', 'title': $filter('translate')('IVA_RETENTION_TYPE'), 'renderWith': 'emptyRender' },
                        { 'data': 'retencionRenta', 'title': $filter('translate')('RENT_RETENTION'), 'class': 'dt-center', renderWith: monedaRender },
                        { 'data': 'conceptoRenta.descripcion', 'title': $filter('translate')('RENT_RETENTION_TYPE'), 'renderWith': 'emptyRender' },
                        //{'data': 'concepto', 'title': $filter('translate')('RETENTION_CONCEPT') , 'renderWith': 'emptyRender'},
                        { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
                        { 'data': 'utilizarEnOrdenPago', 'title': $filter('translate')('UTILIZAR_EN_OP'), 'class': 'dt-center','renderWith': 'emptyRender', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
                    ],
                    'hasOptions': true,
                    'defaultOrderColumn': 0,
                    'defaultOrderDir': "asc",
                    'extraMenuOptions':
                        [
                            {
                                'title': 'Reporte',
                                'icon': 'glyphicon glyphicon-file',
                                'showCondition': function () { return true; },
                                'action': function () {
                                    var filters = createFilters($scope.options.getFilters());
                                    ReportTicketFactory.ticket('listadoTipoRetencion', filters, $scope.options.tableAjaxParams,
                                        $scope.options.currentColumnOrder).then(function (genTicket) {
                                            $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                            $window.open($scope.pdfDownloadURL, '_blank');
                                        });
                                }
                            },
                            {
                                'title': 'CSV',
                                'icon': 'glyphicon glyphicon-export',
                                'showCondition': function() { return true; },
                                'action': function() {
                                    var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);
        
                                    CsvFactory.csv("tiposretencion", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function(response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                                }
                            }
                        ]
                    //'extraRowOptions': []
                };
            });

            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if(search === 'No' && data === 'activo'){
                        search = 'false';
                    }
                    if(search === 'Sí' && data === 'activo'){
                        search = 'true';
                    }
        
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }

            var monedaRender = function (data, type, row) {
                if (data != undefined) {
                    // TODO hacer esto mismo en todos los renders de moneda
                    return data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return '';
                }
            };
        }]);
'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.tesoreria')
  .controller('TipoRetencionFormCtrl', TipoRetencionFormCtrl);

TipoRetencionFormCtrl.$inject = ['$rootScope', '$scope', 'TipoRetencionFactory', '$location', '$state', 
'tipoRetencionPrepService', 'notify', 'formFactory', 'TesoreriaLangFactory', 'TiposFactory', '$filter',
'ParametrosFactory'];

function TipoRetencionFormCtrl($rootScope, $scope, TipoRetencionFactory, $location, $state, 
  tipoRetencionPrepService, notify, formFactory, TesoreriaLangFactory, TiposFactory, $filter,
  ParametrosFactory) {

  activate();
  //var $scope = this;

  $scope.cancel = cancel;
  $scope.submit = submit;
  $scope.changeTipoRetencion = changeTipoRetencion;

  function activate() {
    TesoreriaLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
      if($state.is("app.tiposretencion.new")) {
        activateNew();
      } else if($state.is("app.tiposretencion.edit")) {
        activateEdit();
      } else if($state.is("app.tiposretencion.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited =false;

      TiposFactory.tiposRetencion().then(function (data) {
                $scope.tiposRetencion = data.data;
              });

      TiposFactory.conceptosIva().then(function (data) {
                $scope.conceptosIva = data.data;
              });

      TiposFactory.conceptosRenta().then(function (data) {
                $scope.conceptosRenta = data.data;
              });

      TiposFactory.clasesRetencion().then(function (data) {
                $scope.clasesRetencion = data.data;
              });
    });
  }

  function activateNew() {
    $scope.title = $filter('translate')('NEW_RETENTION_TYPE');
    $scope.tipoRetencion = {};
    $scope.tipoRetencion.activo = true;
    
  }

  function activateEdit() {
    $scope.title = $filter('translate')('EDIT_RETENTION_TYPE');
    $scope.tipoRetencion = tipoRetencionPrepService;
    $scope.entidadId=$scope.tipoRetencion.id;
    $scope.entidad="TipoRetencion";
  }

  function activateView() {
    $scope.title = $filter('translate')('VIEW_RETENTION_TYPE');
    $scope.tipoRetencion = tipoRetencionPrepService;
    $scope.entidadId=$scope.tipoRetencion.id;
    $scope.entidad="TipoRetencion";
    $scope.view = true;
  }

  function changeTipoRetencion() {
    $scope.tipoRetencion.retencionRenta = null;
    $scope.tipoRetencion.retencionIva = null;
    $scope.tipoRetencion.tipoRetencionRenta = undefined;
    $scope.tipoRetencion.tipoRetencionIva = undefined;
  }

  function submit() {
    console.log("submit");
    $scope.submited = true;
    if($scope.TipoRetencionForm.$valid) {
      if($scope.tipoRetencion.retencionIva <=0 && $scope.tipoRetencion.retencionRenta <=0 && $scope.tipoRetencion.retencionIva10 <=0 && $scope.tipoRetencion.retencionIva5 <=0){
        notify({ message: $filter('translate')('ERROR_TIPO_RETENCION'), classes: 'alert-danger', position: 'right' });
        $scope.submited = false;

      }else if (($scope.tipoRetencion.retencionIva10 > 0 || $scope.tipoRetencion.retencionIva5 > 0) && $scope.tipoRetencion.tipoRetencionIva.codigo === "tipo_ret_total" ){
        notify({ message: $filter('translate')('ERROR_INCONSISTENCIA_APLICAR_SOBRE'), classes: 'alert-danger', position: 'right' });
        $scope.submited = false;
      }else{
        formFactory.defaultNSFSubmit($scope.TipoRetencionForm, TipoRetencionFactory, $scope.tipoRetencion, errorHandler).then(function (response) {
          ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
            if (parametro.valorTipo.codigo == 'si') {
                if(response.enviadoAContabilidad == false){
                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                }
            }
          });
          $location.path('/tiporetencion');
        }, function(error) {
          console.log(error);
        });
      }
    } else {
      notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      console.log("print");
      console.log(error.constraint);
      if(error.constraint=='nombre'){
        console.log("entro");
        msg += '\n\n' + $filter('translate')('SAME_NAME_TIPO_RETENCION')+"\n"
        //notify({ message: $filter('translate')('SAME_NAME_TIPO_RETENCION'), classes: 'alert-danger', position: 'right' });
        //return;
      }else if(error.message!=undefined){
        console.log("entro2");
        console.log(error.message);
        msg += '\n\n' + error.message + '.'
      }
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function cancel() {
    $location.path("/tiporetencion");
  }

  $scope.resource = 'tiporetencion'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.TipoRetencionFactory
 * @description
 * # TipoRetencionFactory
 * Factory in the qualita.
 */
angular.module('qualita.tesoreria')
  .factory('TipoRetencionFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
    '$translatePartialLoader', '$translate',
    function ($resource, baseurl, filterFactory, TiposFactory, $http, $translatePartialLoader, $translate) {

      var TipoRetencionFactory = $resource(baseurl.getBaseUrl() + '/tiposretencion/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      return {
        all: function (params, view) {
          if (params) {
            params.view = params.view != undefined ? view : 'BaseList';
          } else {
            params = [];
            params.view = 'BaseList';
          }
          return TipoRetencionFactory.query(params);
        },

        get: function (id, view) {
          return TipoRetencionFactory.get({ id: id, view: view != undefined ? view : 'base' });
        },

        create: function (attrs) {
          return new TipoRetencionFactory(attrs);
        },

        save: function (tipoRetencion) {
          return (tipoRetencion.id) ? tipoRetencion.$update() : tipoRetencion.$save();
        },

        remove: function (tipoRetencion) {
          return tipoRetencion.$remove();
        },

        schema: function () {
          return schema;
        },

        form: function (type) {
          return type === 'new' ? formNew : formEdit;
        },

        /*getTranslations: function() {
          $translatePartialLoader.addPart('stock');
          return $translate.refresh().then(function () {
            return $translate(translationsNeeded);
          });
        }*/
      };
    }]);

'use strict';


angular.module('qualita.tesoreria')
    .controller('ComprobanteRetencionIndexCtrl',
        ["$scope", "$rootScope", "ComprobanteRetencionFactory", "TesoreriaLangFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "notify", "WatchLocacionChangeFactory", "$filter", "$stateParams", "UtilFactory", "CsvFactory", "baseurl", "$q", "PeriodoAdministrativoFactory", function ($scope, $rootScope, ComprobanteRetencionFactory, TesoreriaLangFactory,
            filterFactory, AuthorizationService, ReportTicketFactory, $modal,
            $window, $state, notify, WatchLocacionChangeFactory, $filter, $stateParams,
            UtilFactory, CsvFactory, baseurl, $q, PeriodoAdministrativoFactory) {

            var dateRender = function (data, type, full) {
                if (data) {
                    return moment.utc(data).format('DD/MM/YYYY');
                } else {
                    return "";
                }
            };

            var emptyDataRender = function (data) {
                //console.log(data);
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            var importeRender = function (data, type, row) {
                if (data) {
                    var moneda = "Gs. ";
                    return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return '';
                }
            };

            var importeComprobanteRender = function (data, type, row) {
                var campoMoneda = row.comprobante.moneda1;
                if (data != undefined) {
                    var moneda = "Gs. ";
                    if (campoMoneda.codigo === "dolares") {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "pesos") {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "real") {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "euro") {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    } else {
                        data = parseFloat(data).toFixed(0);
                    }
                    // TODO hacer esto mismo en todos los renders de moneda
                    return (
                        moneda +
                        data
                            .toString()
                            .replace(".", ",")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                } else {
                    return "";
                }
            };

            var tipoCambioRender = function (data, type, row) {

                var campoMoneda = row.comprobante.moneda1;
                if (data != undefined) {

                    if (campoMoneda.codigo === "guaranies") {
                        data = parseFloat(data).toFixed(0);
                    }else{
                        data = parseFloat(data).toFixed(2);
                    }

                    return (
                        data
                            .toString()
                            .replace(".", ",")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                } else {
                    return "";
                }
            };

            var importeGuaraniesRender = function (data, type, row) {
                var campoMoneda = row.comprobante.moneda1;
                if (data != undefined) {
                    // Monto sin decimales (esta en guaranies)
                    if (campoMoneda.codigo === "guaranies") {
                        data = parseFloat(data).toFixed(0);
                    }else{
                        data = parseFloat(data).toFixed(2);
                    }

                    // TODO hacer esto mismo en todos los renders de moneda
                    return (
                        "Gs. " +
                        data
                            .toString()
                            .replace(".", ",")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                } else {
                    return "";
                }
            };

            // var renderOrigen = function(data) {
            //     if (data==undefined)
            //         return "MANUAL";
            //     else
            //         return data;
            // };


            TesoreriaLangFactory.getTranslations().then(function (translations) {
                var defaultColumnOrder = ['fecha', 'comprobante.fecha', 'comprobante.montoTotal', 'proveedor.nombre', 'tipoRetencion.nombre',
                    'numero', 'tipoRetencion.retencionIva', 'tipoRetencion.retencionRenta','importeRetencionIVA', 'importeRetencionRenta', 
                    'importe', 'comprobante.numero', 'estado.descripcion', 'numeroComprobanteSET', 'origen.descripcion', 'tipoCambio',
                    'importeMonedaLocal'
                ];
                $scope.options = {
                    'resource': 'comprobanteretencion',
                    'title': $filter('translate')('COMPROBANTE_RETENCION'),
                    //'staticFilter': staticFilter,
                    'view': "ComprobanteRetencionList",
                    'factory': ComprobanteRetencionFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'columns': [
                        { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false },
                        { 'data': 'tipoRetencion.nombre', 'title': $filter('translate')('RETENTION_TYPE'), 'renderWith': 'emptyRender' },
                        { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
                        { 'data': 'tipoRetencion.claseRetencion.descripcion', 'title': $filter('translate')('RETENTION_CLASS'), 'renderWith': 'emptyRender' },
                        { 'data': 'tipoRetencion.retencionIva', 'title': $filter('translate')('IVA_RETENTION'), 'renderWith': 'emptyRender', 'class': 'dt-center' },
                        { 'data': 'tipoRetencion.retencionRenta', 'title': $filter('translate')('RENT_RETENTION'), 'renderWith': 'emptyRender', 'class': 'dt-center' },
                        { 'data': 'tipoRetencion.tipoRetencionIva.descripcion', 'title': $filter('translate')('IVA_RETENTION_TYPE'), 'renderWith': 'emptyRender' },
                        { 'data': 'tipoRetencion.tipoRetencionRenta.descripcion', 'title': $filter('translate')('RENT_RETENTION_TYPE'), 'renderWith': 'emptyRender' },
                        { 'data': 'tipoRetencion.conceptoRenta.descripcion', 'title': $filter('translate')('RETENTION_CONCEPT'), 'renderWith': 'emptyRender' },
                        { 'data': 'tipoRetencion.conceptoIva.descripcion', 'title': $filter('translate')('RETENTION_CONCEPT_IVA'), 'renderWith': 'emptyRender' },
                        { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX'), 'renderWith': 'emptyRender' },
                        { 'data': 'proveedor.numeroDocumento', 'title': $filter('translate')('RUC'), 'renderWith': 'emptyRender' },
                        { 'data': 'comprobante.moneda1.descripcion', 'title': $filter('translate')('CURRENCY'), 'renderWith': 'emptyRender' },
                        { 'data': 'importeRetencionIVA', 'title': $filter('translate')('IMPORTE_RETENCION_IVA'), 'class': 'dt-right', 'render': importeComprobanteRender, 'type': "number-range" },
                        { 'data': 'importeRetencionRenta', 'title': $filter('translate')('IMPORTE_RETENCION_RENTA'), 'class': 'dt-right', 'render': importeComprobanteRender, 'type': "number-range" },
                        { 'data': 'importe', 'title': $filter('translate')('IMPORTE_TOTAL'), 'class': 'dt-right', 'render': importeComprobanteRender, 'type': "number-range" },
                        { 'data': 'tipoCambio', 'title': $filter('translate')('TIPO_CAMBIO'), 'class': 'dt-right', 'render': tipoCambioRender, 'type': "number-range" },

                        { 'data': 'importeMonedaLocal', 'title': $filter('translate')('MONTO_EN_GUARANIES'), 'class': 'dt-right', 'render': importeGuaraniesRender, 'type': "number-range" },

                        { 'data': 'comprobante.tipo.descripcion', 'title': $filter('translate')('TIPO_COMPROBANTE'), 'renderWith': 'emptyRender', 'class': 'dt-right' },
                        { 'data': 'comprobante.numero', 'title': $filter('translate')('NUMERO_COMPROBANTE'), 'renderWith': 'emptyRender', 'class': 'dt-right' },
                        {
                            'data': 'estado.descripcion', 'title': $filter('translate')('ESTADO_RETENCION'), 'renderWith': 'emptyRender', 'class': 'dt-center',
                            'type': 'combo', 'filterUrl': 'comprobanteretencion/estados'
                        },
                        { 'data': 'comprobante.fecha', 'title': $filter('translate')('FECHA_COMPROBANTE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
                        { 'data': 'comprobante.montoTotal', 'title': $filter('translate')('MONTO_COMPROBANTE'), 'renderWith': importeComprobanteRender, 'class': 'dt-center', 'type': "number-range" },
                        { 'data': 'numeroComprobanteSET', 'title': $filter('translate')('NUMERO_RETENCION'), 'renderWith': 'emptyRender', 'class': 'dt-center' },
                        { 'data': 'numeroTimbradoRetencion', 'title': $filter('translate')('NUMERO_TIMBRADO'), 'renderWith': 'emptyRender', 'class': 'dt-center' },
                        {
                            data: "origen.descripcion",
                            title: "Origen",
                            type: "combo",
                            renderWith: 'emptyRender',
                            class: "dt-center",
                            filterUrl: "tipos/filtro/origen_comprobante_retencion"
                        }
                    ],
                    'isSelectable': true,
                    'selection': {},
                    'hasOptions': true,
                    'defaultOrderColumn': 1,
                    'hideEditMenu': true,
                    'hideAddMenu': true,
                    'defaultOrderDir': "desc",
                    'extraRemoveConditions': function (item) {
                        return item.estado.codigo !== "comprobante_retencion_presentado" &&
                            item.estado.codigo !== "comprobante_retencion_anulado";
                    },


                    extraRowOptions: [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;'   title=" +
                            $filter("translate")("EDIT") +
                            " ng-click='editarProceso(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: "estado",
                        conditionName: "canEditar",
                        conditionDef: function (estado) {
                            return (
                                estado.codigo !== "comprobante_retencion_presentado" &&
                                estado.codigo !== "comprobante_retencion_anulado"
                            );
                        },
                        functionName: "editarProceso",
                        functionDef: function (itemId) {
                            $state.go('app.comprobanteretencion.edit', { id: itemId });
                        }
                    }],


                    'extraMenuOptions': [
                        {
                            title: $filter("translate")("NEW"),
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function() {
                                var permission = AuthorizationService.hasPermission("create_comprobanteretencion");
                                return permission;
                            },
                            action: function() {
                                checkPeriodoAdministrativo(null).then(function(resultado) {
                                    if(resultado == true){
                                        $state.go("app.comprobanteretencion.new");
                                    }else if(resultado == false){
                                        var msg= $filter('translate')('NO_OPEN_PERIODS_NOW');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            }
                        },
                        {
                            'data': $filter('translate')('EXPORTAR_SET'),
                            'title': $filter('translate')('EXPORTAR_SET_TITLE'),
                            'icon': 'glyphicon glyphicon-download-alt',
                            'showCondition': function () { return true; },
                            'action': function () {
                                if (getIDs().length === 0)
                                    notify({ message: $filter('translate')('DETALLE_REQUERIDO'), classes: 'alert-warning', position: 'right' });
                                else
                                    ComprobanteRetencionFactory.downloadJson(getIDs());
                            }
                        },
                        {
                            'data': $filter('translate')('IMPORTAR_SET'),
                            'title': $filter('translate')('IMPORTAR_SET_TITLE'),
                            'icon': 'glyphicon glyphicon-download-alt',
                            'showCondition': function () { return true; },
                            'action': function () {
                                $state.go("app.importarcomprobante");
                            }
                        },
                        {
                            'data': $filter('translate')('ANULAR_RETENCIONES'),
                            'title': $filter('translate')('ANULAR_RETENCIONES_TITLE'),
                            'icon': 'glyphicon glyphicon-check',
                            'showCondition': function () { return true; },
                            'action': function () {
                                if (getIDs().length === 0)
                                    notify({ message: $filter('translate')('DETALLE_REQUERIDO'), classes: 'alert-warning', position: 'right' });
                                else {
                                    $scope.tituloModal = $filter('translate')('ANULAR_RETENCIONES_Q');
                                    $scope.mensajeModal = $filter('translate')('ANULAR_RETENCIONES_MSG');
                                    var modalInstance = $modal.open({
                                        templateUrl: 'views/directives/confirmation-modal.html',
                                        scope: $scope
                                    });

                                    $scope.cancel = function () {
                                        modalInstance.dismiss('cancel');
                                    };

                                    $scope.ok = function (itemId) {
                                        var ids = getIDs();
                                        ComprobanteRetencionFactory.marcarAnulado(getIDs()).then(function (data) {
                                            if (data && data.data.mensajes === undefined) {
                                                $state.transitionTo($state.current, $stateParams, {
                                                    reload: true,
                                                    inherit: false,
                                                    notify: true
                                                });
                                            } else {
                                                var mensaje = "";
                                                $.each(data && data.data.mensajes, function (index, value) {
                                                    mensaje = mensaje + "\n" + value;
                                                });
                                                if (mensaje) {
                                                    notify({ message: mensaje, classes: 'alert-danger', position: 'right' });
                                                }
                                            }
                                        })
                                        modalInstance.close();
                                    };
                                }
                            }
                        },

                        // NOTE: Metodo TEMPORAL para simular presentación en TESAKA de la SET
                        {
                            'data': $filter('translate')('PRESENTAR_RETENCIONES'),
                            'title': $filter('translate')('PRESENTAR_RETENCIONES_TITLE'),
                            'icon': 'glyphicon glyphicon-check',
                            'showCondition': function () { return true; },
                            'action': function () {
                                if (getIDs().length === 0)
                                    notify({ message: $filter('translate')('DETALLE_REQUERIDO'), classes: 'alert-warning', position: 'right' });
                                else {
                                    $scope.tituloModal = $filter('translate')('PRESENTAR_RETENCIONES_Q');
                                    $scope.mensajeModal = $filter('translate')('PRESENTAR_RETENCIONES_MSG');
                                    var modalInstance = $modal.open({
                                        templateUrl: 'views/directives/confirmation-modal.html',
                                        scope: $scope
                                    });

                                    $scope.cancel = function () {
                                        modalInstance.dismiss('cancel');
                                    };

                                    $scope.ok = function (itemId) {
                                        var ids = getIDs();
                                        ComprobanteRetencionFactory.marcarPresentado(getIDs()).then(function (data) {
                                            if (data.data.mensajes === undefined) {
                                                $state.transitionTo($state.current, $stateParams, {
                                                    reload: true,
                                                    inherit: false,
                                                    notify: true
                                                });
                                            } else {
                                                var mensaje = "";
                                                $.each(data.data.mensajes, function (index, value) {
                                                    mensaje = mensaje + "\n" + value;
                                                });
                                                //$window.alert(mensaje);
                                                notify({ message: mensaje, classes: 'alert-danger', position: 'right' });
                                            }
                                        })
                                        modalInstance.close();
                                    };
                                }
                            }
                        },
                        {
                            'data': $filter('translate')('HECHAUKA_RETENCIONES'),
                            'title': $filter('translate')('EXPORTAR_HECHAUKA_RETENCIONES'),
                            'icon': 'glyphicon glyphicon-check',
                            'showCondition': function () { return true },
                            'action': function (itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.obj = {};
                                $scope.obj.tipoReporte = { id: 1, nombre: "Original" };
                                // TODO translations
                                $scope.meses = [{ id: 1, nombre: "Enero" }, { id: 2, nombre: "Febrero" }, { id: 3, nombre: "Marzo" },
                                { id: 4, nombre: "Abril" }, { id: 5, nombre: "Mayo" }, { id: 6, nombre: "Junio" },
                                { id: 7, nombre: "Julio" }, { id: 8, nombre: "Agosto" }, { id: 9, nombre: "Septiembre" },
                                { id: 10, nombre: "Octubre" }, { id: 11, nombre: "Noviembre" }, { id: 12, nombre: "Diciembre" }
                                ];
                                $scope.tiposReporte = [{ id: 1, nombre: "Original" }, { id: 2, nombre: "Rectificativa" }];
                                $scope.tituloModal = $filter('translate')('EXPORTAR_HECHAUKA_RETENCIONES');
                                $scope.mensajeModal = $filter('translate')('SELECCION_RANGO_HECHAUKA');
                                console.log($scope.tituloModal);
                                var modalInstance = $modal.open({
                                    templateUrl: 'views/directives/hechauka-modal.html',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss('cancel');
                                }

                                $scope.ok = function (mes, anio, tipoReporte) {
                                    if (mes && anio) {
                                        ComprobanteRetencionFactory.downloadCSVHechaukaRetenciones(mes.id, anio, tipoReporte.id, $filter('translate')('EXPORTAR_HECHUAKA_ERROR'));
                                        modalInstance.dismiss('cancel');
                                    }
                                }
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("comprobanteretencion", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                              var filtersArr = [];
                              var filters = $scope.options.getFilters();
                              console.log(filters)
                              var filtersKeys = Object.keys(filters);
                              for (var i = 0; i < filtersKeys.length; i++) {
                                if(filters[filtersKeys[i]]){
                                  filtersArr.push(filtersKeys[i]+ "=" + filters[filtersKeys[i]]);
                                }
                              }
                              filtersArr.push("locacion.id=" + $rootScope.AuthParams.locacionSeleccionada.id);
                              var orderColumnID = $scope.options.tableAjaxParams.order[0].column;
                              var orderColumn = $scope.options.tableAjaxParams.columns[orderColumnID].data;
                              var orderDir = $scope.options.tableAjaxParams.order[0].dir;
                              filtersArr.push("orderBy=" + orderColumn);
                              filtersArr.push("orderDir=" + orderDir);
                              var pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                "comprobanteretencion",
                                "pdf",
                                filtersArr
                              );
                              $window.open(pdfDownloadURL, "_blank");
                            }
                        }
                    ]
                };
                WatchLocacionChangeFactory.watchWith(undefined, $scope.options, "locacion.id");
            });


            var getIDs = function () {
                var ids = [];
                _.forEach($scope.options.selection, function (n, key) {
                    if (n)
                        ids.push(key);
                });
                return ids;
            };



            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    search = search.split("~");
                    if (search.length > 1) {
                        /* El parámetro es un rago */

                        var isDate = search[0].split("/");

                        if (isDate.length > 1) {
                            /* Es un rago de fechas */

                            filtersArr.push({path: data, equalOrAfter: search[0]})
                            filtersArr.push({path: data, equalOrBefore: search[1]})
                        } else {
                            /* El un rago numérco */

                            filtersArr.push({path: data, greaterOrEqual: search[0]})
                            filtersArr.push({path: data, lessOrEqual: search[1]})
                        }
                    } else {
                        /* El parametro no es un rago */
                        filtersArr.push({path: data, like: search[0]})
                    }
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }

            /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
            var checkPeriodoAdministrativo = function(fechaComprobante){
                return $q(function(resolve, reject) {

                    var params = {};
                    var fecha;

                    if(fechaComprobante == null){
                        fecha = new Date();
                    }else{
                        fecha = fechaComprobante;
                    }

                    params.search = filterFactory
                    .and([{
                            path: "estado.codigo",
                            equals: "per_adm_abierto"
                        },
                        {
                            path: "fechaDesde",
                            equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                        },
                        {
                            path: "fechaHasta",
                            equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                        }
                    ])
                    .value();

                    PeriodoAdministrativoFactory.all(params).$promise.then(function(responsePeriodo) {
                        if(responsePeriodo.length>0){
                            resolve(true);
                        }else{
                            resolve(false);
                        }
                    })
                });
            }

        }]);

'use strict';

angular.module('qualita.tesoreria')
  .controller('ComprobanteRetencionCargaCtrl', ComprobanteRetencionCargaCtrl);


ComprobanteRetencionCargaCtrl.$inject = ['$scope', 'ComprobanteRetencionFactory', '$location', '$state', 'TesoreriaLangFactory','notify',
'$filter'];

function ComprobanteRetencionCargaCtrl($scope, ComprobanteRetencionFactory, $location, $state, TesoreriaLangFactory, notify, $filter) {

	var vm = this;

	vm.uploadOptions = {
      imageOnly: false
    };

    TesoreriaLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
    });

  $scope.cancel = function() {
    $location.path("/comprobanteretencion");
  }

	$scope.submit = function() {
	  //console.log(vm.uploadOptions);
      var flow = vm.uploadOptions.flow;
      var flowFile = flow.files[0];
      if (flowFile)
        //$scope.$broadcast('schemaFormValidate');
        //$rootScope.isProcessing = true;

        ComprobanteRetencionFactory.carga(flowFile.uniqueIdentifier+"-"+flowFile.name).then(function(data){
          console.log(data.data.mensajes);

          if(data.data.mensajes !== null && data.data.mensajes != undefined) {
            ComprobanteRetencionFactory.setData(data.data);
            //$state.go("app.comprobanteretencion.erroresjson");
            for (var i=0 ; i< data.data.mensajes.length; i++){
              notify({ message: data.data.mensajes[i], classes: 'alert-danger', position: 'right'});
            }
            
          } else {
            $state.go("app.comprobanteretencion.list");
          }
          
        }, function(){
          //$rootScope.isProcessing = false;
          console.log('error al importar el archivo');
        });
    };

};

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.tesoreria')
    .controller('ComprobanteRetencionFormCtrl', ComprobanteRetencionFormCtrl);

ComprobanteRetencionFormCtrl.$inject = ['$rootScope', '$scope', 'ComprobanteRetencionFactory', '$location', '$state',
    'comprobanteRetencionPrepService', 'notify', 'formFactory', 'TesoreriaLangFactory', 'TiposFactory', 'ProveedoresFactory',
    'TipoRetencionFactory', 'ComprobanteFactory', 'filterFactory', 'WatchLocacionChangeFactory', 'UtilFactory', '$filter',
    "CotizacionMonedaFactory", "PeriodoAdministrativoFactory", "$q", "ParametrosFactory"
];

function ComprobanteRetencionFormCtrl($rootScope, $scope, ComprobanteRetencionFactory, $location, $state,
    comprobanteRetencionPrepService, notify, formFactory, TesoreriaLangFactory, TiposFactory, ProveedoresFactory,
    TipoRetencionFactory, ComprobanteFactory, filterFactory, WatchLocacionChangeFactory, UtilFactory, $filter,
    CotizacionMonedaFactory, PeriodoAdministrativoFactory, $q, ParametrosFactory) {

    activate();
    //var $scope = this;

    $scope.cancel = cancel;
    $scope.submit = submit;
    $scope.changeTipoRetencion = changeTipoRetencion;
    $scope.changeProveedor = changeProveedor;
    $scope.changeComprobante = changeComprobante;
    $scope.searchComprobanteFilter = searchComprobanteFilter;

    function activate() {
        
        ParametrosFactory.getByCodigo('descontar_nc_calculo_retencion').then(function(parametro){
            if (parametro.valorTipo.codigo === "si"){
                $scope.descontarNcRetencion = true;
            }else{
                $scope.descontarNcRetencion = false;
            }
            
        });
        TesoreriaLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            if ($state.is("app.comprobanteretencion.new")) {
                activateNew();
            } else if ($state.is("app.comprobanteretencion.edit")) {
                activateEdit();
            } else if ($state.is("app.comprobanteretencion.view")) {
                activateView();
            }
            $rootScope.isProcessing = false;
            $scope.submited = false;
            makeAsyncRequests();

            $scope.comprobantes = undefined;
        });

        /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
        $scope.staticFilterProv = [{path: "activo",equals: true}];
        $scope.proveedoresFactory = ProveedoresFactory;
    }

    function makeAsyncRequests() {
        var filterActivo = {
            search: filterFactory.single({
                path: "activo",
                equals: true
            }).value()
        };

        $scope.tiposRetencion = TipoRetencionFactory.all(filterActivo, "BaseList");
    }

    function changeTipoRetencion() {
        //console.log($scope.comprobanteRetencion.tipoRetencion);
        if ($scope.comprobanteRetencion.tipoRetencion.conceptoRenta !== undefined &&
            $scope.comprobanteRetencion.tipoRetencion.conceptoRenta !== null) {
            $scope.concepto = $scope.comprobanteRetencion.tipoRetencion.conceptoRenta.descripcion;
        } else if ($scope.comprobanteRetencion.tipoRetencion.conceptoIva !== undefined &&
            $scope.comprobanteRetencion.tipoRetencion.conceptoIva !== null) {
            $scope.concepto = $scope.comprobanteRetencion.tipoRetencion.conceptoIva.descripcion;
        }
        cerarMontos();
        changeComprobante();
    }

    function changeProveedor() {
        var staticFilter = createFilterComprobantes();
        $scope.comprobanteRetencion.comprobante = undefined;
        cerarMontos();
        if ($scope.watcherComprob) { $scope.watcherComprob(); }
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
            $scope.watcherComprob = watcher;
            ComprobanteFactory.getCombos(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter, "ComprobanteRetencionForm")).$promise.then(function (response) {
                console.log("response ", response);
                $scope.comprobantes = response;
            });
        }, "locacion.id");
    }

    function changeComprobante() {
        if ($scope.comprobanteRetencion.comprobante != undefined) {
            var codigoMoneda = $scope.comprobanteRetencion.comprobante.moneda1.codigo;
            var toFixedAmount = codigoMoneda === "guaranies" ? 0 : 2;
            $scope.montoGravado = $scope.comprobanteRetencion.comprobante.montoTotal - $scope.comprobanteRetencion.comprobante.saldoImpuestos;
            $scope.montoGravado = parseFloat($scope.montoGravado.toFixed(toFixedAmount));

            if ($scope.comprobanteRetencion.tipoRetencion.tipoRetencionIva != undefined && $scope.comprobanteRetencion.tipoRetencion.tipoRetencionIva.codigo == "tipo_ret_total") {
                if ($scope.descontarNcRetencion){
                    $scope.comprobanteRetencion.importeRetencionIVA = parseFloat(($scope.comprobanteRetencion.tipoRetencion.retencionIva *
                        $scope.comprobanteRetencion.comprobante.saldo / 100).toFixed(toFixedAmount));
                }else{
                    $scope.comprobanteRetencion.importeRetencionIVA = parseFloat(($scope.comprobanteRetencion.tipoRetencion.retencionIva *
                        $scope.comprobanteRetencion.comprobante.montoTotal / 100).toFixed(toFixedAmount));
                }
            } else if ($scope.comprobanteRetencion.tipoRetencion.tipoRetencionIva != undefined && $scope.comprobanteRetencion.tipoRetencion.tipoRetencionIva.codigo == "tipo_ret_impuesto") {
                if ($scope.descontarNcRetencion){
                    $scope.comprobanteRetencion.importeRetencionIVA = parseFloat(($scope.comprobanteRetencion.tipoRetencion.retencionIva *
                        $scope.comprobanteRetencion.comprobante.saldoImpuestos / 100).toFixed(toFixedAmount));
                }else{
                    $scope.comprobanteRetencion.importeRetencionIVA = parseFloat(($scope.comprobanteRetencion.tipoRetencion.retencionIva *
                        $scope.comprobanteRetencion.comprobante.totalImpuestos / 100).toFixed(toFixedAmount));
                }
            }
            if ($scope.comprobanteRetencion.tipoRetencion.tipoRetencionRenta != undefined && $scope.comprobanteRetencion.tipoRetencion.tipoRetencionRenta.codigo == "tipo_ret_total") {
                $scope.comprobanteRetencion.importeRetencionRenta = parseFloat(($scope.comprobanteRetencion.tipoRetencion.retencionRenta *
                    $scope.comprobanteRetencion.comprobante.montoTotal / 100).toFixed(toFixedAmount));
            } else if ($scope.comprobanteRetencion.tipoRetencion.tipoRetencionRenta != undefined && $scope.comprobanteRetencion.tipoRetencion.tipoRetencionRenta.codigo == "tipo_ret_impuesto") {
                $scope.comprobanteRetencion.importeRetencionRenta = parseFloat(($scope.comprobanteRetencion.tipoRetencion.retencionRenta *
                    $scope.comprobanteRetencion.comprobante.saldoImpuestos / 100).toFixed(toFixedAmount));
            }
            $scope.comprobanteRetencion.importe = parseFloat(($scope.comprobanteRetencion.importeRetencionRenta + $scope.comprobanteRetencion.importeRetencionIVA).toFixed(toFixedAmount));
            $scope.comprobanteRetencion.comprobante.totalImpuestos = parseFloat($scope.comprobanteRetencion.comprobante.saldoImpuestos.toFixed(toFixedAmount));
            $scope.comprobanteRetencion.comprobante.montoTotal = parseFloat($scope.comprobanteRetencion.comprobante.montoTotal.toFixed(toFixedAmount));
            // obtenemos la cotización del día si es posible
            $scope.comprobanteRetencion.fecha = $scope.comprobanteRetencion.fecha != undefined? $scope.comprobanteRetencion.fecha:new Date();
            if ($scope.comprobanteRetencion.comprobante.moneda1.codigo !== "guaranies") {
                var filterCotization = {
                    search: filterFactory.and([{
                        path: "monedaDesde.codigo",
                        equals: $scope.comprobanteRetencion.comprobante.moneda1.codigo
                    }, {
                        path: "monedaHasta.codigo",
                        equals: "guaranies"
                    }, {
                        path: "fecha",
                        like: moment($scope.comprobanteRetencion.fecha).format("DD/MM/YYYY")
                    }]).value()
                }
                CotizacionMonedaFactory.all(filterCotization).$promise.then(function (response) {
                    if (!response || response.length === 0) {
                        //mostramos el tipo de cambio del comprobante
                        notify({ message: $filter('translate')('COTIZACION_NO_EXISTE'), classes: 'alert-danger', position: 'right'});
                        $scope.comprobanteRetencion.tipoCambio = null;
                        return
                    }
                    var cotizacion = response[0];
                    $scope.comprobanteRetencion.tipoCambio = cotizacion.valorVenta;
                });
            } else {
                $scope.comprobanteRetencion.tipoCambio = 1;
            }
        } else {
            cerarMontos();
        }

    }

    function cerarMontos() {
        $scope.comprobanteRetencion.importeRetencionIVA = 0;
        $scope.comprobanteRetencion.importeRetencionRenta = 0;
        $scope.montoGravado = 0;
        $scope.comprobanteRetencion.importe = 0;
    }

    function activateNew() {
        TiposFactory.origenesCompronbantesRetencion().then(function(datos){
            var origenes = datos.data;
            var origenManual = _.find(origenes, function(origen){
            if(origen.codigo === 'comp_retencion_manual'){
                $scope.comprobanteRetencion.origen = origen;

            }    
          });
            
        })

        $scope.title = $filter('translate')('NEW_COMPROBANTE_RETENCION');
        $scope.comprobanteRetencion = {};
    }

    function activateEdit() {
        $scope.title = $filter('translate')('EDIT_COMPROBANTE_RETENCION');
        $scope.comprobanteRetencion = comprobanteRetencionPrepService;
        if($scope.comprobanteRetencion.fecha){
            $scope.comprobanteRetencion.fecha = new Date($scope.comprobanteRetencion.fecha);
        }
        $scope.entidadId = $scope.comprobanteRetencion.id;
        $scope.entidad = "ComprobanteRetencion";
        var codigoMoneda = $scope.comprobanteRetencion.comprobante.moneda1.codigo;
        var toFixedAmount = codigoMoneda === "guaranies" ? 0 : 2;
        $scope.montoGravado = parseFloat(($scope.comprobanteRetencion.comprobante.montoTotal - $scope.comprobanteRetencion.comprobante.saldoImpuestos).toFixed(toFixedAmount));
        $scope.isEdit = true;
        if ($scope.comprobanteRetencion.tipoRetencion.conceptoRenta !== undefined &&
            $scope.comprobanteRetencion.tipoRetencion.conceptoRenta !== null) {
            $scope.concepto = $scope.comprobanteRetencion.tipoRetencion.conceptoRenta.descripcion;
        } else if ($scope.comprobanteRetencion.tipoRetencion.conceptoIva !== undefined &&
            $scope.comprobanteRetencion.tipoRetencion.conceptoIva !== null) {
            $scope.concepto = $scope.comprobanteRetencion.tipoRetencion.conceptoIva.descripcion;
        }
        var staticFilter = createFilterComprobantes();
        if ($scope.watcherComprob) { $scope.watcherComprob(); }
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
            $scope.watcherComprob = watcher;
            ComprobanteFactory.getCombos(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter), "comboComprobante").$promise.then(function (response) {
                $scope.comprobantes = response;
            });
        }, "locacion.id");
    }

    function activateView() {
        $scope.title = $filter('translate')('VIEW_COMPROBANTE_RETENCION');
        $scope.comprobanteRetencion = comprobanteRetencionPrepService;
        if($scope.comprobanteRetencion.fecha){
            $scope.comprobanteRetencion.fecha = new Date($scope.comprobanteRetencion.fecha);
        }
        var codigoMoneda = $scope.comprobanteRetencion.comprobante.moneda1.codigo;
        var toFixedAmount = codigoMoneda === "guaranies" ? 0 : 2;
        $scope.montoGravado = parseFloat(($scope.comprobanteRetencion.comprobante.montoTotal - $scope.comprobanteRetencion.comprobante.saldoImpuestos).toFixed(toFixedAmount));
        if ($scope.comprobanteRetencion.tipoRetencion.conceptoRenta !== undefined &&
            $scope.comprobanteRetencion.tipoRetencion.conceptoRenta !== null) {
            $scope.concepto = $scope.comprobanteRetencion.tipoRetencion.conceptoRenta.descripcion;
        } else if ($scope.comprobanteRetencion.tipoRetencion.conceptoIva !== undefined &&
            $scope.comprobanteRetencion.tipoRetencion.conceptoIva !== null) {
            $scope.concepto = $scope.comprobanteRetencion.tipoRetencion.conceptoIva.descripcion;
        }
        $scope.entidadId = $scope.comprobanteRetencion.id;
        $scope.entidad = "ComprobanteRetencion";
        $scope.view = true;
    }

    function createFilterComprobantes() {
        var staticFilter = {};
        staticFilter.search = filterFactory.or([
            filterFactory.and([{
                path: 'proveedor.id',
                equals: $scope.comprobanteRetencion.proveedor.id
            },
            {
                path: 'tipo.clase.codigo',
                equals: 'autofactura'
            },
            {
                path: 'saldo',
                notEquals: 0
            },
            {
                path: 'estado.codigo',
                equals: 'comp_aprobado'
            }
            ]).value(),
            filterFactory.and([{
                path: 'proveedor.id',
                equals: $scope.comprobanteRetencion.proveedor.id
            },
            {
                path: 'tipo.clase.codigo',
                equals: 'retencion_absorbida'
            },
            {
                path: 'saldo',
                notEquals: 0
            },
            {
                path: 'estado.codigo',
                equals: 'comp_aprobado'
            }
            ]).value(),
            filterFactory.and([{
                path: 'proveedor.id',
                equals: $scope.comprobanteRetencion.proveedor.id
            },
            {
                path: 'tipo.clase.codigo',
                equals: 'factura'
            },
            {
                path: 'saldo',
                notEquals: 0
            },
            {
                path: 'estado.codigo',
                equals: 'comp_aprobado'
            },
            {
                path: 'ordenPagoDetalles.id',
                isNull: 'true'
            }
            ]).value(), // Recuperar comprobantes que tengan ordenes que tengan saldo y que tengas ordenes de pago anuladas.
            filterFactory.and([{
                path: 'proveedor.id',
                equals: $scope.comprobanteRetencion.proveedor.id
            },
            {
                path: 'tipo.clase.codigo',
                equals: 'factura'
            },
            {
                path: 'saldo',
                notEquals: 0
            },
            {
                path: 'estado.codigo',
                equals: 'comp_aprobado'
            },
            {
                path: 'ordenPagoDetalles.ordenPago.estado.codigo',
                equals: 'opago_anulada'
            }
            ]).value(),
        ]).value();

        return staticFilter;
    }

    function evaluatePeriodoAdministrativo(fecha){
        return $q(function(resolve, reject) {
            var params = {};
            params.search = filterFactory
            .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                }
            ])
            .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function(responsePeriodo) {

                if(responsePeriodo.length>0){
                    resolve(true);
                }else{
                    resolve(false);
                }
            })
        });
    }

    function searchComprobanteFilter(criteria){
        return function(item) {
            if (!criteria) {
                return true;
            }
            return item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1
        };
    }

    function submit() {
        $scope.submited = true;
        if ($scope.ComprobanteRetencionForm.$valid) {

            var params = {search: filterFactory.single({
            path: "estado.codigo",
            equals: "per_adm_abierto"
            }).value()};

            var flagPeriod = false;
            $scope.comprobanteRetencion.fecha.setHours(0, 0, 0, 0);

            evaluatePeriodoAdministrativo($scope.comprobanteRetencion.fecha).then(function(response) {
                if (response) {
                    if (!$scope.isEdit) {
                        $scope.comprobanteRetencion.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
                    }
                    formFactory.defaultNSFSubmit($scope.ComprobanteRetencionForm, ComprobanteRetencionFactory, $scope.comprobanteRetencion, errorHandler).then(function (response) {
                        ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                            if (parametro.valorTipo.codigo == 'si') {
                                if(response.enviadoAContabilidad == false){
                                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                }
                            }
                        });
                        $location.path('/comprobanteretencion');
                    }, function (error) {
                        console.log(error);
                    });
                }else{
                    notify({
                        message: $filter("translate")("NO_ADMINISTRATIVE_PERIOD"),
                        classes: "alert-danger",
                        position: "right"
                    });
                }
            });

        }else {
            //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
        }
    }

    function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function (error) {
            if (error.constraint == 'nombre') {
                msg += '\n\n' + $filter('translate')('SAME_NAME_PRODUCT_CLASS') + "\n"
            } else {
                msg += '\n\n' + error.message + '.'
            }
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
    }

    function cancel() {
        $location.path("/comprobanteretencion");
    }

    $scope.resource = 'comprobanteretencion'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}
'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.tesoreria')
  .controller('ComprobanteRetencionErroresCtrl', ["$http", "$rootScope", "$location", "$scope", "ComprobanteRetencionFactory", "$window", function ($http ,$rootScope, $location,$scope, ComprobanteRetencionFactory, $window) {
    
    $scope.resultados=ComprobanteRetencionFactory.getData();

    var tamanio=$scope.resultados.mensajes.length;

    $scope.classe ="error-list";
    $scope.titulo="Errores";

    if($scope.resultados.mensajes[tamanio-1]==="Los registros han sido importados correctamente"){
	    $scope.classe ="warning-list";
		$scope.titulo="Advertencia";
    }else{
		$scope.classe ="error-list";
		$scope.titulo="Errores";
    }
    

  }]);

angular.module('qualita.tesoreria')
    .factory('ComprobanteRetencionFactory', ComprobanteRetencionFactory);

ComprobanteRetencionFactory.$inject = ['$resource', 'baseurl', '$http', 'notify', '$window', '$state', '$stateParams'];

function ComprobanteRetencionFactory($resource, baseurl, $http, notify, $window, $state, $stateParams) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove,
        downloadJson: downloadJson,
        marcarAnulado: marcarAnulado,
        marcarPresentado: marcarPresentado,
        carga: carga,
        setData: setData,
        getData: getData,
        downloadCSVHechaukaRetenciones: downloadCSVHechaukaRetenciones
    };

    var ComprobanteRetencion = $resource(baseurl.getBaseUrl() + "/comprobanteretencion/:id", { id: '@id' }, {
        update: {
            method: 'PUT'
        }
    });

    var savedData = {};

    return service;



    function setData(data) {
        //$window.alert("set");
        savedData = data;
    }

    function getData() {
        //$window.alert("get");
        return savedData;
    }

    function downloadJson(ids) {
        $http({
            url: baseurl.getBaseUrl() + '/comprobanteretencion/json',
            method: "GET",
            params: { listDetalles: ids }
        }).then(function (data) {
            if (data.data.mensajes === undefined) {
                console.log('antes 1');
                $window.open(baseurl.getPublicBaseUrl() + data.data.filename, '_self');
                console.log('despues 1');
                $state.transitionTo($state.current, $stateParams, {
                    reload: true,
                    inherit: false,
                    notify: true
                });
            } else {
                /*var mensaje = "";
                $.each(data.data.mensajes,function(index, value){
                  mensaje = mensaje + "\n" + value;
                });
                //$window.alert(mensaje);
                notify({ message: mensaje, classes: 'alert-danger', position: 'right' });*/
                console.log("data", data);
                var mensaje = "<span>";
                _.forEach(data.data.mensajes, function (err) {
                    mensaje = mensaje + "<br/>" + err;
                });
                mensaje = mensaje + "</span>";
                console.log("mensaje", mensaje);
                notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
            }
            /*$state.transitionTo($state.current, $stateParams, {
              reload: true,
              inherit: false,
              notify: true
            });*/
            //$location.path('/tesaka');
        },
            function (status) {
                //console.log('error al exportar el JSON');
                notify({ message: 'Ha ocurrido un error al exportar el archivo', classes: 'alert-danger', position: 'right' });
            });
    }

    function marcarGenerado(ids) {
        $http({
            url: baseurl.getBaseUrl() + '/comprobanteretencion/marcargenerado',
            method: "GET",
            params: { listDetalles: ids }
        }).then(function (data) {
            if (data.data.mensajes === undefined) {
                //$window.alert("todobien");
            } else {
                var mensaje = "";
                $.each(data.data.mensajes, function (index, value) {
                    mensaje = mensaje + "\n" + value;
                });
                //$window.alert(mensaje);
                notify({ message: mensaje, classes: 'alert-danger', position: 'right' });
            }
            $state.transitionTo($state.current, $stateParams, {
                reload: true,
                inherit: false,
                notify: true
            });
        },
            function (status) {
                notify({ message: 'Ha ocurrido un error en el servidor', classes: 'alert-danger', position: 'right' });
            });
    }

    function marcarAnulado(ids) {
        return $http({
            url: baseurl.getBaseUrl() + '/comprobanteretencion/marcaranulado',
            method: "GET",
            params: { listDetalles: ids }
        }).then(function (data) {
            return data;
        },
        function (status) {
            notify({ message: status.data.error, classes: 'alert-danger', position: 'right' });
        });
    }

    function marcarPresentado(ids) {
        return $http({
            url: baseurl.getBaseUrl() + '/comprobanteretencion/marcarpresentado',
            method: "GET",
            params: { listDetalles: ids }
        }).then(function (data) {
            return data;
        },
            function (status) {
                notify({ message: 'Ha ocurrido un error en el servidor', classes: 'alert-danger', position: 'right' });
            });
    }

    function all(params, view) {
        if (params) {
            params.view = view != undefined ? view : 'BaseList';
        }
        return ComprobanteRetencion.query(params);
    }

    function get(id, view) {
        if (typeof (view) === 'undefined') view = "base";
        return ComprobanteRetencion.get({ id: id, view: view });
    }

    function create(attrs) {
        return new ComprobanteRetencion(attrs);
    }

    function save(comprobanteretencion) {
        return (comprobanteretencion.id) ? comprobanteretencion.$update() : comprobanteretencion.$save();
    }

    function remove(comprobanteretencion) {
        return comprobanteretencion.$remove();
    }

    function carga(archivo) {
        return $http({
            url: baseurl.getBaseUrl() + '/comprobanteretencion/cargar',
            method: "GET",
            params: { filename: archivo }
        });
    }

    function downloadCSVHechaukaRetenciones(mes, anio, tiporeporte, error) {
        $http({
            url: baseurl.getBaseUrl() + '/hechauka/retencion/csv',
            method: "GET",
            params: { mes: mes, anio: anio, tiporeporte: tiporeporte }
        }).then(function (response) {
            if (response.data.filename != undefined) {
                $window.open(baseurl.getPublicBaseUrl() + response.data.filename, '_self');
            } else {
                notify({ message: "Error al generar el archivo.", classes: 'alert-danger', position: 'right' });
            }
        },
            function (response) {
                var mensaje = "<span>";
                _.forEach(response.data.error, function (err) {
                    mensaje = mensaje + err + "<br/>";
                });
                mensaje = mensaje + "</span>";
                notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
            });
    }

}
'use strict';


angular.module('qualita.tesoreria')
  .controller('ChequeraIndexCtrl',
    ["$scope", "ChequeraFactory", "TesoreriaLangFactory", "filterFactory", "ReportTicketFactory", "$modal", "$window", "$state", "ParametrosFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "notify", function ($scope, ChequeraFactory, TesoreriaLangFactory, filterFactory,
      ReportTicketFactory, $modal, $window, $state,ParametrosFactory, $filter, 
      UtilFactory, CsvFactory, baseurl, notify) {

      var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
      var enviarAContabilidad = '';

      promise1.then(function(response) {
        enviarAContabilidad = response.valorTipo.codigo;
      });

      var dateRender = function (data, type, full) {
        if (data) {
          return moment.utc(data).format('DD/MM/YYYY');
        } else {
          return "";
        }
      };

      var emptyDataRender = function (data) {
        //console.log(data);
        if (data == undefined)
          return "";
        else
          return data;
      };

      TesoreriaLangFactory.getTranslations().then(function (translations) {
        var defaultColumnOrder = ['id', 'nombre', 'cuentaBancaria.numeroCuenta', 'cuentaBancaria.nombreCuenta',
          'cuentaBancaria.entidad.nombre', 'tipoCuenta.descripcion', 'moneda.descripcion', 'numeroSerie',
          'numeroDesde', 'numeroHasta', 'cantidad', 'responsableRecepcion', 'chequeDiferido'];
        $scope.options = {
          'resource': 'chequeras',
          'title': 'Chequeras',
          //'staticFilter': staticFilter,
          'view': 'ChequeraList',
          'factory': ChequeraFactory,
          'defaultColumnOrder': defaultColumnOrder,
          'columns': [
            { 'data': 'id', 'title': $filter('translate')('CODE'), visible: false  },
            { 'data': 'nombre', 'title': $filter('translate')('NAME') },
            { 'data': 'cuentaBancaria.numeroCuenta', 'title': $filter('translate')('ACCOUNT_NUMBER'), 'class': 'dt-right' },
            { 'data': 'cuentaBancaria.nombreCuenta', 'title': $filter('translate')('ACCOUNT_NAME') },
            { 'data': 'cuentaBancaria.entidad.nombre', 'title': $filter('translate')('ENTITY_NAME'), 'render': emptyDataRender },
            { 'data': 'tipoChequera.descripcion', 'title': $filter('translate')('CHEQUERA_TYPE'), 'class': 'dt-center' },
            { 'data': 'moneda.descripcion', 'title': $filter('translate')('CURRENCY'), 'class': 'dt-center' },
            { 'data': 'numeroSerie', 'title': $filter('translate')('SERIE_NUMBER') },
            { 'data': 'numeroDesde', 'title': $filter('translate')('FROM_NUMBER'), 'class': 'dt-right'  },
            { 'data': 'numeroHasta', 'title': $filter('translate')('TO_NUMBER'), 'class': 'dt-right'  },
            { 'data': 'cantidad', 'title': $filter('translate')('COUNT'), 'class': 'dt-right'  },
            { 'data': 'responsableRecepcion', 'title': $filter('translate')('RECEPTION_RESPONSABLE') },
            { 'data': 'chequeDiferido', 'title': $filter('translate')('CHEQUE_DIFERIDO'), 'class': 'dt-right', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
          ],
          'hasOptions': true,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "desc",
          'extraMenuOptions':
            [
              {
                'title': 'Reporte',
                'icon': 'glyphicon glyphicon-file',
                'showCondition': function () { return true; },
                'action': function () {
                  var filters = createFilters($scope.options.getFilters());
                  ReportTicketFactory.ticket('listadoChequeras', filters, $scope.options.tableAjaxParams,
                    $scope.options.currentColumnOrder).then(function (genTicket) {
                      $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                      $window.open($scope.pdfDownloadURL, '_blank');
                    });
                }
              },
              {
                'title': 'CSV',
                'icon': 'glyphicon glyphicon-export',
                'showCondition': function () { return true; },
                'action': function () {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("chequeras", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                    .then(function (response) {
                      console.log("response de csv: ", response);
                      var fileName = response.data.fileName;
                      $window.open(baseurl.getPublicBaseUrl() + fileName);
                    });
                }
              }
            ],
          'extraRowOptions': [
            {
              templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
              functionName: 'reenviar',
              customAttribute: ['enviadoAContabilidad', 'id'],
              functionDef: function (atributo) {

                  var idChequera = atributo[1];

                  $scope.tituloModal = $filter('translate')('REENVIAR_CHEQUERA');
                  $scope.mensajeModal = $filter('translate')('REENVIAR_CHEQUERA_MSG');
                  var modalInstance = $modal.open({
                    templateUrl: 'views/directives/confirmation-modal.html',
                    scope: $scope
                  });

                  $scope.cancel = function () {
                    modalInstance.dismiss('cancel');
                  };

                  $scope.ok = function () {

                    ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {

                      if (parametro.valorTipo.codigo == 'si') {
                        ChequeraFactory.reenviar(idChequera).then(function (responseReenviar) {

                          if (responseReenviar.data.enviadoAContabilidad == false) {
                            notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                          }
                          $state.go("app.chequeras.list", { id: responseReenviar.id }, { reload: true });
                        }, function (error) {
                          var msg = "";
                          if (error.data && error.data.length > 0 && error.data[0].message) {
                            msg = error.data[0].message;
                          } else {
                            msg = $filter('translate')('REENVIAR_CHEQUERA_FAILED');
                          }
                          notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        });
                        
                      } else {
                        var msg = "";
                        msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                      }
                    });
                    modalInstance.close();
                  }
              },
              conditionName: 'canReenviar',
              conditionDef: function (atributo) {
                var enviadoAContabilidad = atributo[0];
                if (enviarAContabilidad == 'si') {
                  if (enviadoAContabilidad === "No") {
                    return true;
                  }
                }
              }
          
          }]
        };
       // WatchLocacionChangeFactory.watchWith(undefined, $scope.options, "locacion.id");
      });


      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          if(search === 'No' && data === 'chequeDiferido'){
            search = 'false';
        }
        if(search === 'Sí' && data === 'chequeDiferido'){
            search = 'true';
        }
          filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
    }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.tesoreria')
        .controller('ChequeraFormCtrl', ChequeraFormCtrl);

ChequeraFormCtrl.$inject = ['$rootScope', '$scope', 'ChequeraFactory', '$location', '$state',
    'chequeraPrepService', 'notify', 'formFactory', 'TesoreriaLangFactory', 'TiposFactory', 'cuentaBancariaFactory',
    'filterFactory', 'ChequeFactory', 'AuthorizationService', '$filter', 'ParametrosFactory'];

function ChequeraFormCtrl($rootScope, $scope, ChequeraFactory, $location, $state,
        chequeraPrepService, notify, formFactory, TesoreriaLangFactory, TiposFactory, cuentaBancariaFactory,
        filterFactory, ChequeFactory, AuthorizationService, $filter, ParametrosFactory) {

    activate();

    $scope.cancel = cancel;
    $scope.submit = submit;
    $scope.changechequera = changechequera;
    $scope.actualizarCantidad = actualizarCantidad;
    $scope.valor = 0;
    $scope.arraySerie = [];

    function activate() {
        TesoreriaLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            if ($state.is("app.chequeras.new")) {
                activateNew();
            } else if ($state.is("app.chequeras.edit")) {
                activateEdit();
            } else if ($state.is("app.chequeras.view")) {
                activateView();
            }
            $rootScope.isProcessing = false;
            $scope.submited = false;

            TiposFactory.tiposChequera().then(function (data) {
                $scope.tiposChequera = data.data;
            });

            //$scope.cuentasBancarias = cuentaBancariaFactory.all();
            cuentaBancariaFactory.all().$promise.then(function (response) {
                console.log("response", response);
                for (var i = 0; i < response.length; i++) {
                    //console.log("response[i]",response[i]);
                    response[i].numeroNombre = response[i].numeroCuenta + "-" + response[i].nombreCuenta;
                }
                $scope.cuentasBancarias = response;
                //console.log("cuentas",$scope.cuentas);
            });
            $scope.$watch('chequera.numeroDesde', function(newVal){
              if(newVal || newVal === 0){
                actualizarCantidad();
              }
            })
        });
    }

    function activateNew() {
        $scope.title = $filter('translate')('NEW_CHEQUERA');
        $scope.chequera = {};

    }

    function activateEdit() {
        actualizarCantidad();
        $scope.title = $filter('translate')('EDIT_CHEQUERA');
        $scope.chequera = chequeraPrepService;
        $scope.chequera.cuentaBancaria.numeroNombre = $scope.chequera.cuentaBancaria.numeroCuenta + "-" + $scope.chequera.cuentaBancaria.nombreCuenta;

        $scope.entidadId = $scope.chequera.id;
        $scope.entidad = "Chequera";
        $scope.isEdit = true;
        createChequesDatatable();
    }

    function activateView() {
        actualizarCantidad();
        $scope.title = $filter('translate')('VIEW_CHEQUERA');
        $scope.chequera = chequeraPrepService;
        $scope.entidadId = $scope.chequera.id;
        $scope.entidad = "Chequera";
        $scope.view = true;
        $scope.chequera.cuentaBancaria.numeroNombre = $scope.chequera.cuentaBancaria.numeroCuenta + "-" + $scope.chequera.cuentaBancaria.nombreCuenta;
        createChequesDatatable();
    }

    function createChequesDatatable() {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
                path: 'chequera.id',
                equals: $scope.entidadId
            }]).value();
        var defaultColumnOrder = ['numero', 'estado.descripcion', 'beneficiario', 'concepto', 'fecha_emision', 'fechaPago', 'monto'];
        $scope.options = {
            'resource': 'cheques',
            'title': 'Cheques asociados a esta Chequera',
            'view': 'ChequeList',
            'factory': ChequeFactory,
            'staticFilter': staticFilter,
            'defaultColumnOrder': defaultColumnOrder,
            'columns': [
                {'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-center'},
                {'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/tipo/estado_cheque'},
                {'data': 'beneficiario', 'title': $filter('translate')('BENEFICIARY'), 'class': 'dt-center', 'renderWith': 'emptyRender'},
                {'data': 'concepto', 'title': $filter('translate')('CONCEPT'), 'class': 'dt-center', 'renderWith': 'emptyRender'},
                {'data': 'fecha_emision', 'title': $filter('translate')('ISSUE'), 'renderWith' : 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class' : 'dt-center', 'type' : 'date-range'},
                {'data': 'fechaPago', 'title': $filter('translate')('PAYMENT'), 'renderWith' : 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class' : 'dt-center', 'type' : 'date-range'},
                {'data': 'monto', 'title': $filter('translate')('RODE'), 'class': 'dt-center', 'renderWith': 'emptyRender'},
            ],
            'hasOptions': true,
            'hideAddMenu': true,
            'hideRemoveMenu': true,
            'hideEditMenu': true,
            'hideViewMenu': false,
            'defaultOrderColumn': 0,
            'defaultOrderDir': "desc"
        }
    }
    ;

    function actualizarCantidad() {
        $scope.valor = 1;
    }

    function submit() {
        console.log("submit");
        $scope.submited = true;
        if ($scope.chequera.numeroHasta > $scope.chequera.numeroDesde) {
            if ($scope.ChequeraForm.$valid) {
                var value = false;
                for (var i = 0; i < $scope.arraySerie.length; i++) {
                    for (var j = $scope.chequera.numeroDesde; j <= $scope.chequera.numeroHasta; j++) {
                        if ($scope.arraySerie[i] === j) {
                            value = true;
                            break;
                        }
                    }
                }

                if (value) {
                    notify({message: $filter('translate')('WRONG_CUENTA_CHEQUERA'), classes: 'alert-danger', position: 'right'});
                } else {
                    $scope.chequera.moneda = $scope.chequera.cuentaBancaria.moneda;
                    $scope.chequera.cantidad = $scope.chequera.numeroHasta - $scope.chequera.numeroDesde + 1;
                    if (!$scope.isEdit) {
                        $scope.chequera.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
                    }
                    formFactory.defaultNSFSubmit($scope.ChequeraForm, ChequeraFactory, $scope.chequera, errorHandler).then(function (response) {
                        ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                            if (parametro.valorTipo.codigo == 'si') {
                                if(response.enviadoAContabilidad == false){
                                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                }
                            }
                        });
                        $location.path('/chequeras');
                    }, function (error) {

                    });
                }
            } else {
                notify({message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
            }
        } else {
            notify({message: $filter('translate')('CHECKOUT_FROM_LEFT_TO'), classes: 'alert-danger', position: 'right'});
        }
    }

    function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function (error) {
            if (error.constraint == 'nombre, id_cuenta') {
                msg += '\n\n' + $filter('translate')('SAME_CHEQUERA') + "\n"
            } else {
                console.log("error", error.message);
                if (error.message == undefined && $state.is("app.chequeras.edit")) {
                    notify({message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
                }
                ;
                msg += '\n\n' + error.message + '.'
            }
        });
        notify({message: msg, classes: 'alert-danger', position: 'right'});
    }

    function cancel() {
        $location.path("/chequeras");
    }

    function changechequera() {
        ChequeraFactory.recuperarCuenta($scope.chequera.cuentaBancaria.id).$promise.then(function (response) {
            for (var i = 0; i < response.length; i++) {
                for (var j = response[i].numeroDesde; j <= response[i].numeroHasta; j++) {
                    $scope.arraySerie.push(j);
                }
            }
            console.log("ESTO",$scope.arraySerie)
        });
    }


    $scope.resource = 'chequeras'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.ChequeraFactory
 * @description
 * # ChequeraFactory
 * Factory in the qualita.
 */
angular.module('qualita.tesoreria')
        .factory('ChequeraFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
            '$translatePartialLoader', '$translate',
            function ($resource, baseurl, filterFactory, TiposFactory, $http, $translatePartialLoader, $translate) {

                var ChequeraFactory = $resource(baseurl.getBaseUrl() + '/chequeras/:id', {id: '@id'}, {
                    update: {
                        method: 'PUT'
                    }
                });
                var ChequeraFactoryLocal = $resource(baseurl.getBaseUrl() + '/chequeras/recuperarCuenta/:id', {
                });

                return {
                    all: function (params, view) {
                        if (params) {
                            params.view = view != undefined ? view : 'BaseList';
                        } else {
                            params = [];
                            params.view = view != undefined ? view : 'BaseList';
                        }
                        return ChequeraFactory.query(params);
                    },
                    get: function (id, view) {
                        if (typeof (view) === 'undefined')
                            view = "base";
                        return ChequeraFactory.get({id: id, view: view});
                    },
                    create: function (attrs) {
                        return new ChequeraFactory(attrs);
                    },
                    save: function (chequera) {
                        return (chequera.id) ? chequera.$update() : chequera.$save();
                    },
                    remove: function (chequera) {
                        return chequera.$remove();
                    },
                    schema: function () {
                        return schema;
                    },
                    form: function (type) {
                        return type === 'new' ? formNew : formEdit;
                    },
                    recuperarCuenta: function (cuenta) {
                        return ChequeraFactoryLocal.query({id: cuenta});
                    },
                    reenviar: function(idChequera) {
                        return $http({
                          url: baseurl.getBaseUrl() + '/chequeras/reenviar/' + idChequera,
                          method: "PUT"
                        });
                    }
                    /*getTranslations: function() {
                     $translatePartialLoader.addPart('stock');
                     return $translate.refresh().then(function () {
                     return $translate(translationsNeeded);
                     });
                     }*/
                };
            }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.tesoreria')
  .controller('CuentaBancariaFormCtrl', CuentaBancariaFormCtrl);

CuentaBancariaFormCtrl.$inject = ['$rootScope', 'filterFactory', '$scope', 'cuentaBancariaFactory', '$location', '$state',
  'cuentaBancariaPrepService', 'notify', 'formFactory', 'TesoreriaLangFactory', 'TiposFactory', 'EntidadBancariaFactory',
  '$filter', 'ParametrosFactory'];

function CuentaBancariaFormCtrl($rootScope, filterFactory, $scope, cuentaBancariaFactory, $location, $state,
  cuentaBancariaPrepService, notify, formFactory, TesoreriaLangFactory, TiposFactory, EntidadBancariaFactory,
  $filter, ParametrosFactory) {

  activate();
  //var $scope = this;

  $scope.cancel = cancel;
  $scope.submit = submit;

  function activate() {
    TesoreriaLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      if ($state.is("app.cuentabancaria.new")) {
        activateNew();
      } else if ($state.is("app.cuentabancaria.edit")) {
        activateEdit();
      } else if ($state.is("app.cuentabancaria.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;

      TiposFactory.monedas().then(function (data) {
        $scope.monedas = data.data;
      });

      var staticFilterEntidadesBancarias = {
        search: filterFactory.single({
          path: "activo",
          equals: true
        }).value()
      };

      $scope.entidades = EntidadBancariaFactory.all(staticFilterEntidadesBancarias);


      TiposFactory.tiposCuenta().then(function (data) {
        $scope.tiposCuenta = data.data;
        console.log($scope.tiposCuenta);
      });
    });
  }

  function activateNew() {
    $scope.title = $filter('translate')('NEW_BANK_ACCOUNT');
    $scope.cuentaBancaria = {};
    $scope.cuentaBancaria.plantillaChequeDiferido = "cheque-template";
    $scope.cuentaBancaria.plantillaChequeDia = "cheque-template";
    $scope.cuentaBancaria.permitirSobregiro = false;

  }

  function activateEdit() {
    $scope.title = $filter('translate')('EDIT_BANK_ACCOUNT');
    $scope.cuentaBancaria = cuentaBancariaPrepService;
    $scope.cuentaBancaria.fechaSaldoInicial = new Date($scope.cuentaBancaria.fechaSaldoInicial);
    $scope.cuentaBancaria.fechaApertura = new Date($scope.cuentaBancaria.fechaApertura);
    $scope.entidadId = $scope.cuentaBancaria.id;
    $scope.entidad = "CuentaBancaria";
    $scope.edit = true;
  }

  function activateView() {
    $scope.title = $filter('translate')('VIEW_BANK_ACCOUNT');
    $scope.cuentaBancaria = cuentaBancariaPrepService;
    $scope.cuentaBancaria.fechaSaldoInicial = new Date($scope.cuentaBancaria.fechaSaldoInicial);
    $scope.cuentaBancaria.fechaApertura = new Date($scope.cuentaBancaria.fechaApertura);
    $scope.entidadId = $scope.cuentaBancaria.id;
    $scope.entidad = "CuentaBancaria";
    $scope.view = true;
  }

  function submit() {
    console.log("submit");
    $scope.submited = true;
    if ($scope.CuentaBancariaForm.$valid) {
      if ($scope.cuentaBancaria.fechaApertura > $scope.cuentaBancaria.fechaSaldoInicial) {
        notify({ message: $filter('translate')('ERROR_FECHA_APERTURA_MAYOR'), classes: 'alert-danger', position: 'right' });
      } else {
        formFactory.defaultNSFSubmit($scope.CuentaBancariaForm, cuentaBancariaFactory, $scope.cuentaBancaria, errorHandler).then(function (response) {
          ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
            if (parametro.valorTipo.codigo == 'si') {
                if(response.enviadoAContabilidad == false){
                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                }
            }
        });
          $location.path('/cuentabancaria');
        }, function (error) {
          console.log(error);
        });
      }
    } else {
      //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function (error) {
      if (error.constraint == 'numero_cuenta, id_entidad') {
        msg += '\n\n' + $filter('translate')('SAME_NAME_BANK_ACCOUNT') + "\n"
      } else {
        msg += '\n\n' + error.message + '.'
      }
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function cancel() {
    $location.path("/cuentabancaria");
  }

  $scope.resource = 'cuentabancaria'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.tesoreria')
  .controller('EntidadBancariaFormCtrl', EntidadBancariaFormCtrl);

EntidadBancariaFormCtrl.$inject = ['$rootScope', '$scope', 'EntidadBancariaFactory', '$location', '$state',
'entidadBancariaPrepService', 'notify', 'formFactory', 'TesoreriaLangFactory', 'TiposFactory','$timeout',
'$filter'];

function EntidadBancariaFormCtrl($rootScope, $scope, EntidadBancariaFactory, $location, $state,
  entidadBancariaPrepService, notify, formFactory, TesoreriaLangFactory, TiposFactory,$timeout,
  $filter) {

  activate();
  //var $scope = this;

  $scope.agregarContacto = agregarContacto;
  $scope.cancel = cancel;
  $scope.isAnyContactoEmpty = isAnyContactoEmpty;
  $scope.removeItemFromArray = removeItemFromArray;
  $scope.submit = submit;

  function agregarContacto() {
    $scope.entidadBancaria.contactos.push({});
    $timeout(function(){
      $scope.$broadcast('newItemContactoAdded');
    }, 20);
  }

   function isAnyContactoEmpty(arreglo) {
    var broken = false;
    _.forEach(arreglo, function(item) {
      if(!item.nombre) {
        broken = true;
      }

    });
    return broken;
  }

  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function(item) {
      return item === elemento;
    });
  }


  function activate() {
    TesoreriaLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
      if($state.is("app.entidadesbancarias.new")) {
        activateNew();
      } else if($state.is("app.entidadesbancarias.edit")) {
        activateEdit();
      } else if($state.is("app.entidadesbancarias.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited =false;

      TiposFactory.monedas().then(function (data) {
        $scope.monedas = data.data;
      });

      $scope.entidades=EntidadBancariaFactory.all();

      TiposFactory.tiposCuenta().then(function (data) {
        $scope.tiposCuenta = data.data;
      });
    });
  }

  function activateNew() {
    $scope.title = $filter('translate')('NEW_BANK_ENTITY');
    $scope.entidadBancaria = {};
    $scope.entidadBancaria.contactos=[{}];
    $scope.entidadBancaria.activo=true;

  }

  function activateEdit() {
    $scope.title = $filter('translate')('EDIT_BANK_ENTITY');
    $scope.entidadBancaria = entidadBancariaPrepService;
    $scope.entidadId=$scope.entidadBancaria.id;
    $scope.entidad="EntidadBancaria";
  }

  function activateView() {
    $scope.title = $filter('translate')('VIEW_BANK_ENTITY');
    $scope.entidadBancaria = entidadBancariaPrepService;
    $scope.entidadId=$scope.entidadBancaria.id;
    $scope.entidad="EntidadBancaria";
    $scope.view = true;
  }

  function submit() {
    $scope.submited = true;
    if (!$scope.entidadBancaria.ruc || rucIsValid()) {
      if($scope.EntidadBancariaForm.$valid) {
        $scope.entidadBancaria.contactos = _.filter($scope.entidadBancaria.contactos, function(elem) { return elem.nombre; });
          formFactory.defaultNSFSubmit($scope.EntidadBancariaForm, EntidadBancariaFactory, $scope.entidadBancaria, errorHandler).then(function (response) {
            $location.path('/entidadesbancarias');
          }, function(error) {
            console.log(error);
          });
      }
    } else {
      notify({ message: $filter('translate')('RUC_NOT_VALID'), classes: 'alert-danger', position: 'right'});
    }
  }

  function rucIsValid() {
    var p_numero = $scope.entidadBancaria.ruc.split('-')[0];
    var p_digit = $scope.entidadBancaria.ruc.split('-')[1];
    var p_basemax = 11;
    var v_total, v_resto, k, v_numero_aux, v_digit;
    var i;

    k = 2;
    v_total = 0;

    for(i = p_numero.length - 1; i >= 0; i--) {
      k = k > p_basemax ? 2 : k;
      v_numero_aux = p_numero.charAt(i);
      v_total += v_numero_aux * k++;
    }

    v_resto = v_total % 11;
    v_digit = v_resto > 1 ? 11 - v_resto : 0;

    return v_digit == p_digit;
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if(error.constraint=='nombre'){
        msg += '\n\n' + $filter('translate')('SAME_NAME_BANK_ENTITY')+"\n"
      }
      // En caso de error en ciertas validaciones de negocio. Desde el backend se envia un json con el formato {'error' : '$DESCRIPCION_ERROR'}
      if(e.data.error){
        msg += '\n\n' +e.data.error+"\n"
      }else{
        msg += '\n\n' + error.message + '.'
      }
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function cancel() {
    $location.path("/entidadesbancarias");
  }

  $scope.resource = 'entidadesbancarias'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.tesoreria')
  .controller('TipoFondoFijoFormCtrl', TipoFondoFijoFormCtrl);

TipoFondoFijoFormCtrl.$inject = ['$rootScope', '$scope', 'TipoFondoFijoFactory', '$location', '$state', 
'tipoFondoFijoPrepService', 'notify', 'formFactory', 'TesoreriaLangFactory', 'TiposFactory', '$filter',
'ParametrosFactory'];

function TipoFondoFijoFormCtrl($rootScope, $scope, TipoFondoFijoFactory, $location, $state, 
  tipoFondoFijoPrepService, notify, formFactory, TesoreriaLangFactory, TiposFactory, $filter,
  ParametrosFactory) {

  activate();
  //var $scope = this;

  $scope.cancel = cancel;
  $scope.submit = submit;
  

  function activate() {
    TesoreriaLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
      if($state.is("app.tipofondofijo.new")) {
        activateNew();
      } else if($state.is("app.tipofondofijo.edit")) {
        activateEdit();
      } else if($state.is("app.tipofondofijo.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited =false;

    });
  }

  function activateNew() {
    $scope.title = $filter('translate')('NEW_TIPO_FONDO_FIJO');
    $scope.tipofondofijo = {};
    
  }

  function activateEdit() {
    $scope.title = $filter('translate')('EDIT_TIPO_FONDO_FIJO');
    $scope.tipoFondoFijo = tipoFondoFijoPrepService;
    $scope.entidadId=$scope.tipoFondoFijo.id;
    $scope.entidad="TipoFondoFijo";
  }

  function activateView() {
    $scope.title = $filter('translate')('VIEW_TIPO_FONDO_FIJO');
    $scope.tipoFondoFijo = tipoFondoFijoPrepService;
    $scope.entidadId=$scope.tipoFondoFijo.id;
    $scope.entidad="TipoFondoFijo";
    $scope.view = true;
  }

  function submit() {
    console.log("submit");
    $scope.submited = true;
    if($scope.TipoFondoFijoForm.$valid) {
        formFactory.defaultNSFSubmit($scope.TipoFondoFijoForm, TipoFondoFijoFactory, $scope.tipoFondoFijo, errorHandler).then(function (response) {
          ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
            if (parametro.valorTipo.codigo == 'si') {
                if(response.enviadoAContabilidad == false){
                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                }
            }
          });
          $location.path('/tipofondofijo');
        }, function(error) {
          console.log(error);
        });
    } else {
      //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }
  }

  function errorHandler(e) {
    console.log(e.data);
    var msg = '';
    _.forEach(e.data, function(error) {
      if(error.constraint=='nombre'){
        msg += '\n\n' + $filter('translate')('SAME_NAME_TIPO_FONDO_FIJO')+"\n"
      }else{
        msg += '\n\n' + error.message + '.'
      }
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function cancel() {
    $location.path("/tipofondofijo");
  }

  $scope.resource = 'tipofondofijo'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.tesoreria')
  .controller('TipoMovimientoBancarioFormCtrl', TipoMovimientoBancarioFormCtrl);

TipoMovimientoBancarioFormCtrl.$inject = ['$rootScope', '$scope', 'TipoMovimientoBancarioFactory', '$location', '$state',
  'tipoMovimientoBancarioPrepService', 'notify', 'formFactory', 'TesoreriaLangFactory', 'TiposFactory', '$filter'];

function TipoMovimientoBancarioFormCtrl($rootScope, $scope, TipoMovimientoBancarioFactory, $location, $state,
  tipoMovimientoBancarioPrepService, notify, formFactory, TesoreriaLangFactory, TiposFactory, $filter) {

  activate();
  //var $scope = this;

  $scope.cancel = cancel;
  $scope.submit = submit;


  function activate() {
    TesoreriaLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      TiposFactory.tiposMovimientoBancrio().then(function (data) {
        $scope.tipos = data.data;
      });

      if ($state.is("app.tipomovimientobancario.new")) {
        activateNew();
      } else if ($state.is("app.tipomovimientobancario.edit")) {
        activateEdit();
      } else if ($state.is("app.tipomovimientobancario.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;

    });
  }

  function activateNew() {
    $scope.title = $filter('translate')('NEW_TIPO_MOV_BANCARIO');
    $scope.tipoMovimientoBancario = {};
    $scope.tipoMovimientoBancario.activo = true;

  }

  function activateEdit() {
    $scope.title = $filter('translate')('EDIT_TIPO_MOV_BANCARIO');
    $scope.tipoMovimientoBancario = tipoMovimientoBancarioPrepService;
    TipoMovimientoBancarioFactory.recuperarParche($scope.tipoMovimientoBancario.id, "TipoMovimientoBancarioForm").then(function (response) {
      $scope.tipoMovimientoBancario = response.data.data;
    });

    $scope.entidadId = $scope.tipoMovimientoBancario.id;
    $scope.entidad = "TipoMovimientoBancario";
    $scope.view = false;
    $scope.editMode = true;
  }

  function activateView() {
    $scope.title = $filter('translate')('VIEW_TIPO_MOV_BANCARIO');
    $scope.tipoMovimientoBancario = tipoMovimientoBancarioPrepService;
    $scope.entidadId = $scope.tipoMovimientoBancario.id;
    $scope.entidad = "TipoMovimientoBancario";
    $scope.view = true;
  }

  function submit() {
    //console.log("submit");
    $scope.submited = true;
    if ($scope.TipoMovimientoBancarioForm.$valid) {
      formFactory.defaultNSFSubmit($scope.TipoMovimientoBancarioForm, TipoMovimientoBancarioFactory, $scope.tipoMovimientoBancario, errorHandler).then(function (response) {
        $location.path('/tipomovimientobancario');
      }, function (error) {
        console.log(error);
      });
    } else {
      //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function (error) {
      if (error.constraint == 'nombre') {
        msg += '\n\n' + $filter('translate')('SAME_NAME_MOVEMENT_TYPE') + "\n"
      } else {
        msg += '\n\n' + error.message + '.'
      }
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function cancel() {
    $location.path("/tipomovimientobancario");
  }

  $scope.resource = 'tipomovimientobancario'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

"use strict";
/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular
    .module("qualita.tesoreria")
    .controller("MovimientoBancarioFormCtrl", MovimientoBancarioFormCtrl);

MovimientoBancarioFormCtrl.$inject = [
    "$rootScope",
    "$scope",
    "MovimientoBancarioFactory",
    "$location",
    "$state",
    "movimientoBancarioPrepService",
    "notify",
    "formFactory",
    "TesoreriaLangFactory",
    "TiposFactory",
    "cuentaBancariaFactory",
    "TipoMovimientoBancarioFactory",
    "filterFactory",
    "$filter",
    "CobroFactory",
    "ProcesoCobroFactory",
    "VentasLangFactory",
    "$q",
    "PeriodoAdministrativoFactory",
    "CuentaContableFactory",
    "ParametrosFactory",
    "CobroDetalleFactory",
    "UtilFactory",
    "$timeout",
    "ModelTrimmer",
    "SucursalesFactory",
    "EntidadBancariaFactory",
    "FormaPagoFactory"
];

function MovimientoBancarioFormCtrl(
    $rootScope,
    $scope,
    MovimientoBancarioFactory,
    $location,
    $state,
    movimientoBancarioPrepService,
    notify,
    formFactory,
    TesoreriaLangFactory,
    TiposFactory,
    cuentaBancariaFactory,
    TipoMovimientoBancarioFactory,
    filterFactory,
    $filter,
    CobroFactory,
    ProcesoCobroFactory,
    VentasLangFactory,
    $q,
    PeriodoAdministrativoFactory,
    CuentaContableFactory,
    ParametrosFactory,
    CobroDetalleFactory,
    UtilFactory,
    $timeout,
    ModelTrimmer,
    SucursalesFactory,
    EntidadBancariaFactory,
    FormaPagoFactory
) {
    activate();
    //var $scope = this;
    $scope.cancel = cancel;
    $scope.submit = submit;
    $scope.changeMonto = changeMonto;
    $scope.changeTipo = changeTipo;
    $scope.changeImporteConvertido = changeImporteConvertido;
    $scope.recuperarCobros = recuperarCobros;
    $scope.evaluatePeriodoAdministrativo = evaluatePeriodoAdministrativo;
    $scope.factoryCobroDetalle = CobroDetalleFactory;
    $scope.clearFiltrosComprobantes = clearFiltrosComprobantes;
    $scope.buscarCheques = buscarCheques;
    $scope.clickCheckCheque = clickCheckCheque;
    $scope.clickCheckEfectivo = clickCheckEfectivo;
    $scope.clickCheckTodo = clickCheckTodo;

    var monedaRender = function (data, type, row) {
        var campoMoneda = row.moneda;

        if (data != undefined) {
            var moneda = "Gs. ";
            if (campoMoneda.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (campoMoneda.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (campoMoneda.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (campoMoneda.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            } else {
                data = parseFloat(data).toFixed(0);
            }
            // TODO hacer esto mismo en todos los renders de moneda
            return (
                moneda +
                data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else {
            return "";
        }
    };

    function clickCheckCheque() {

        // Se desmarcan los demas campos
        $scope.movimientoBancario.esEfectivo = false;
        $scope.movimientoBancario.esOtro = false;

        // Se oculta grilla con datos viejos (debe ocultarse porque se esta cambiando un filtro, por ende dicho datatable ya no corresponde)
        $scope.showDtPedidosVentas=false;

        // limpieza de totalizadores
        $scope.movimientoBancario.totalCheque = 0;
        $scope.movimientoBancario.totalEfectivo = 0;
        $scope.movimientoBancario.totalOtro = 0;
        $scope.movimientoBancario.totalImportes = 0;

        $scope.movimientoBancario.monto = 0;

        definirCamposDatatable();
    }

    function clickCheckEfectivo() {

        // Se desmarcan los demas campos
        $scope.movimientoBancario.esCheque = false;
        $scope.movimientoBancario.esOtro = false;

        // Se oculta grilla con datos viejos (debe ocultarse porque se esta cambiando un filtro, por ende dicho datatable ya no corresponde)
        $scope.showDtPedidosVentas=false;

        // limpieza de totalizadores
        $scope.movimientoBancario.totalCheque = 0;
        $scope.movimientoBancario.totalEfectivo = 0;
        $scope.movimientoBancario.totalOtro = 0;
        $scope.movimientoBancario.totalImportes = 0;

        $scope.movimientoBancario.monto = 0;

        definirCamposDatatable();
    }

    function clickCheckTodo() {

        // Se desmarcan los demas campos
        $scope.movimientoBancario.esEfectivo = false;
        $scope.movimientoBancario.esCheque = false;

        // Se oculta grilla con datos viejos (debe ocultarse porque se esta cambiando un filtro, por ende dicho datatable ya no corresponde)
        $scope.showDtPedidosVentas=false;

        // limpieza de totalizadores
        $scope.movimientoBancario.totalCheque = 0;
        $scope.movimientoBancario.totalEfectivo = 0;
        $scope.movimientoBancario.totalOtro = 0;
        $scope.movimientoBancario.totalImportes = 0;

        $scope.movimientoBancario.monto = 0;

        definirCamposDatatable();
    }

    function definirCamposDatatable(){
        if($scope.movimientoBancario.esCheque == true){
            // Se definen los campos a visualizar con el check de Cheque y Todos
            $scope.opcionesCobroDt.columns= [
                { 'data': 'id', 'title': 'Código Interno', renderWith: 'emptyRender', visible: false},
                { 'data': 'cobro.numeroCobro', 'title': $filter('translate')('CODIGO_COBRO'), renderWith: 'emptyRender','class': 'dt-center'},
                { 'data': 'cobro.numeroRecibo', 'title': 'Nro. Recibo', renderWith: 'emptyRender','class': 'dt-center' },
                { 'data': 'cobro.fecha', 'title': 'Cobro Fecha', 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range'},
                { 'data': 'formaPago.descripcion', 'title': 'Forma de Pago', renderWith: 'emptyRender', 'class': 'dt-left'},
                { 'data': 'entidadBancaria.nombre', 'title': $filter('translate')('ENTITY_NAME'), renderWith: 'emptyRender'},
                { 'data': 'numeroCheque', 'title': $filter('translate')('NUMBER'), renderWith: 'emptyRender', 'class': 'dt-center'},
                { 'data': 'fecha', 'title': 'Fecha Cheque', 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range'},
                { 'data': 'fechaPagoDiferido', 'title': 'Fecha Diferido', 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range'},
                { 'data': 'importe', 'title': 'Importe', renderWith: monedaRender, 'class': 'dt-right'},
                { 'data': 'depositado', 'title': 'Depósitado', renderWith: 'emptyRender', 'class': 'dt-center'}
            ];

        }else if($scope.movimientoBancario.esEfectivo == true){
            // Se definen los campos a visualizar con el check de Efectivo
            $scope.opcionesCobroDt.columns= [
                { 'data': 'id', 'title': 'Código Interno', renderWith: 'emptyRender', visible: false},
                { 'data': 'cobro.numeroCobro', 'title': $filter('translate')('CODIGO_COBRO'), renderWith: 'emptyRender','class': 'dt-center'},
                { 'data': 'cobro.numeroRecibo', 'title': 'Nro. Recibo', renderWith: 'emptyRender','class': 'dt-center'},
                { 'data': 'cobro.fecha', 'title': 'Cobro Fecha', 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range'},
                { 'data': 'formaPago.descripcion', 'title': 'Forma de Pago', renderWith: 'emptyRender', 'class': 'dt-lefts'},
                { 'data': 'entidadBancaria.nombre', 'title': $filter('translate')('ENTITY_NAME'), renderWith: 'emptyRender', visible: false},
                { 'data': 'numeroCheque', 'title': $filter('translate')('NUMBER'), renderWith: 'emptyRender', 'class': 'dt-right', visible: false},
                { 'data': 'fecha', 'title': 'Fecha Cheque', 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range', visible: false},
                { 'data': 'fechaPagoDiferido', 'title': 'Fecha Diferido', 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range', visible: false},
                { 'data': 'importe', 'title': 'Importe', renderWith: monedaRender, 'class': 'dt-right'},
                { 'data': 'depositado', 'title': 'Depósitado', renderWith: 'emptyRender', 'class': 'dt-center'}
            ];
        }else {
             $scope.opcionesCobroDt.columns= [
                { 'data': 'id', 'title': 'Código Interno', renderWith: 'emptyRender', visible: false},
                { 'data': 'cobro.numeroCobro', 'title': $filter('translate')('CODIGO_COBRO'), renderWith: 'emptyRender','class': 'dt-center'},
                { 'data': 'cobro.numeroRecibo', 'title': 'Nro. Recibo', renderWith: 'emptyRender','class': 'dt-center' },
                { 'data': 'cobro.fecha', 'title': 'Cobro Fecha', 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range'},
                { 'data': 'formaPago.descripcion', 'title': 'Forma de Pago', renderWith: 'emptyRender', 'class': 'dt-left'},
                { 'data': 'entidadBancaria.nombre', 'title': $filter('translate')('ENTITY_NAME'), renderWith: 'emptyRender'},
                { 'data': 'numeroCheque', 'title': 'Número de Cheque', renderWith: 'emptyRender', 'class': 'dt-center'},
                { 'data': 'numeroTransaccion', 'title': 'Número de Transacción', renderWith: 'emptyRender', 'class': 'dt-center'},
                { 'data': 'fecha', 'title': 'Fecha Cheque', 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range'},
                { 'data': 'fechaPagoDiferido', 'title': 'Fecha Diferido', 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range'},
                { 'data': 'importe', 'title': 'Importe', renderWith: monedaRender, 'class': 'dt-right'},
                { 'data': 'depositado', 'title': 'Depósitado', renderWith: 'emptyRender', 'class': 'dt-center'}
            ];
        }
    }

    function buscarCheques(filtro) {

        if (!$scope.search) {
            $scope.search = {};
        }
        var filtersArray = [];
        var filtersArray2 = [];

        filtersArray.push(
            {
                path: 'depositado',
                like: "false"
            }
        );
        filtersArray2.push(
            {
                path: 'depositado',
                isNull: "true"
            }
        );

        filtersArray.push(
            {
                path: 'formaPago.clase.codigo',
                notEquals: "forma_pago_retención"
            }
        );
        filtersArray2.push(
            {
                path: 'formaPago.clase.codigo',
                notEquals: "forma_pago_retención"
            }
        );

         filtersArray.push(
            {
                path: 'formaPago.clase.codigo',
                notEquals: "forma_pago_referencia"
            }
        );
        filtersArray2.push(
            {
                path: 'formaPago.clase.codigo',
                notEquals: "forma_pago_referencia"
            }
        );

        // Filtro forma pago cuenta contable
        filtersArray.push(
            {
                path: 'formaPago.clase.codigo',
                notEquals: "forma_pago_cuenta_contable"
            }
        );
        filtersArray2.push(
            {
                path: 'formaPago.clase.codigo',
                notEquals: "forma_pago_cuenta_contable"
            }
        );

        if ($scope.search.fechaDesde) {
            filtersArray.push({
                path: "cobro.fecha",
                equalOrAfter: $scope.search.fechaDesde != undefined ?
                    moment($scope.search.fechaDesde).format("DD/MM/YYYY") : undefined
            });
            filtersArray2.push({
                path: "cobro.fecha",
                equalOrAfter: $scope.search.fechaDesde != undefined ?
                    moment($scope.search.fechaDesde).format("DD/MM/YYYY") : undefined
            });
        }
        if ($scope.search.fechaHasta) {
            filtersArray.push({
                path: "cobro.fecha",
                equalOrBefore: $scope.search.fechaHasta != undefined ?
                    moment($scope.search.fechaHasta).format("DD/MM/YYYY") : undefined
            });
            filtersArray2.push({
                path: "cobro.fecha",
                equalOrBefore: $scope.search.fechaHasta != undefined ?
                    moment($scope.search.fechaHasta).format("DD/MM/YYYY") : undefined
            });
        }
        if ($scope.search.sucursal) {
            filtersArray.push({
                path: "cobro.sucursal.id",
                equals: $scope.search.sucursal.id
            });
            filtersArray2.push({
                path: "cobro.sucursal.id",
                equals: $scope.search.sucursal.id
            });
        }
        filtersArray.push(
            {
                path: 'cobro.estado.codigo',
                notEquals: 'estado_cobro_anulado'
            }
        )
        filtersArray2.push(
            {
                path: 'cobro.estado.codigo',
                notEquals: 'estado_cobro_anulado'
            }
        )
        filtersArray.push(
            {
                path: 'cobro.estado.codigo',
                notEquals: 'estado_cobro_ingresado'
            }
        )
        filtersArray2.push(
            {
                path: 'cobro.estado.codigo',
                notEquals: 'estado_cobro_ingresado'
            }
        )
        if($scope.movimientoBancario.cuenta){
             filtersArray.push(
                {
                    path: 'cobro.moneda.id',
                    equals: $scope.movimientoBancario.cuenta.moneda.id
                }
            );
            filtersArray2.push(
                {
                    path: 'cobro.moneda.id',
                    equals: $scope.movimientoBancario.cuenta.moneda.id
                }
            ); 
        }else{
            notify({ message: "Primero debe seleccionar la Cuenta Bancaria", classes: 'alert-danger', position: 'right' });
            return;
        }

        // Filtro para traer detalles de cobros sin movimiento bancario
        filtersArray.push(
            {
                path: 'movimientoBancario.id',
                isNull: true
            }
        );
        filtersArray2.push(
            {
                path: 'movimientoBancario.id',
                isNull: true
            }
        );

        /************ Filtro dinamico (dependiendo del check seleccionado) */

        if($scope.movimientoBancario.esCheque == true){
            // Filtro 1 ........... Esto es para check de cheque
            filtersArray.push(
                {
                    path: 'numeroCheque',
                    notEquals: 'null'
                }
            );
            filtersArray2.push(
                {
                    path: 'numeroCheque',
                    notEquals: 'null'
                }
            );

            filtersArray.push(
                {
                    path: 'formaPago.clase.codigo',
                    equals: 'forma_pago_cheque'
                }
            );
            filtersArray2.push(
                {
                    path: 'formaPago.clase.codigo',
                    equals: 'forma_pago_cheque'
                }
            );

            if ($scope.search.banco) {
                filtersArray.push({
                    path: "entidadBancaria.id",
                    equals: $scope.search.banco.id
                });
                filtersArray2.push({
                    path: "entidadBancaria.id",
                    equals: $scope.search.banco.id
                });
            }
        }else if($scope.movimientoBancario.esEfectivo == true){
            // Filtro 2 ........... Esto es para check de efectivo
            filtersArray.push(
                {
                    path: 'formaPago.clase.codigo',
                    equals: 'forma_pago_efectivo'
                }
            );
            filtersArray2.push(
                {
                    path: 'formaPago.clase.codigo',
                    equals: 'forma_pago_efectivo'
                }
            );
        
        }else if($scope.movimientoBancario.esOtro == true){
            // Filtro 3 ........... Esto es para check de todo
            if($scope.search.formaPago != null && $scope.search.formaPago != undefined){
                filtersArray.push(
                    {
                        path: 'formaPago.id',
                        equals: $scope.search.formaPago.id
                    }
                );
                filtersArray2.push(
                    {
                        path: 'formaPago.id',
                        equals: $scope.search.formaPago.id
                    }
                );
            }
            if ($scope.search.banco) {
                filtersArray.push({
                    path: "entidadBancaria.id",
                    equals: $scope.search.banco.id
                });
                filtersArray2.push({
                    path: "entidadBancaria.id",
                    equals: $scope.search.banco.id
                });
            }
        }
        
        var staticFilter = {};
        staticFilter.search = filterFactory.or([
            filterFactory.and(filtersArray).value(),filterFactory.and(filtersArray2).value()
        ]).value();

        $scope.opcionesCobroDt.staticFilter = staticFilter;
        $scope.showDtPedidosVentas = true;
    }

    function clearFiltrosComprobantes() {
        $scope.search = {};
    }

    function changeTipo() {
        $scope.movimientoBancario.importeEfectivo = 0;
        $scope.movimientoBancario.chequeEsteBanco = 0;
        $scope.movimientoBancario.chequeOtroBanco = 0;
        $scope.movimientoBancario.monto = 0;
    }

    function changeMonto() {
        $scope.movimientoBancario.monto =
            $scope.movimientoBancario.importeEfectivo +
            $scope.movimientoBancario.chequeEsteBanco +
            $scope.movimientoBancario.chequeOtroBanco;
    }

    function changeImporteConvertido() {
        // $scope.movimientoBancario.cuenta: CUENTRA BANCARIA ORIGEN
        // $scope.movimientoBancario.cuentaTransaccion: CUENTA BANCARIA DESTINO
        var cantDecimales;
        $scope.bloquearTipoCambio = false;

        if ($scope.movimientoBancario.tipoMovimiento.tipo.codigo == 'tipo_movimiento_transferencia') {

            $scope.movimientoBancario.importeEfectivo = 0;

            // Se verifica que se hayan seleccionado cuentas bancarias
            if ($scope.movimientoBancario.cuenta != null && $scope.movimientoBancario.cuentaTransaccion != null) {

                // Si las monedas son iguales setear campo 'Tipo Cambio'
                if ($scope.movimientoBancario.cuenta.moneda.codigo == $scope.movimientoBancario.cuentaTransaccion.moneda.codigo) {
                    $scope.movimientoBancario.tipoCambio = 1;
                    $scope.bloquearTipoCambio = true;
                }

                if ($scope.movimientoBancario.monto != null && $scope.movimientoBancario.tipoCambio != null && $scope.movimientoBancario.tipoCambio != 0) {

                    //Guaranies a Dolares
                    if ($scope.movimientoBancario.cuentaTransaccion.moneda.codigo == "dolares") {
                        cantDecimales = 2;
                        $scope.movimientoBancario.importeEfectivoConvertido = parseFloat(($scope.movimientoBancario.monto / $scope.movimientoBancario.tipoCambio).toFixed(cantDecimales));
                    }

                    //Dolares a Guaranies
                    if ($scope.movimientoBancario.cuentaTransaccion.moneda.codigo == "guaranies") {
                        cantDecimales = 0;
                        $scope.movimientoBancario.importeEfectivoConvertido = parseFloat(($scope.movimientoBancario.monto * $scope.movimientoBancario.tipoCambio).toFixed(cantDecimales));
                    }
                }

            } else {
                $scope.movimientoBancario.importeEfectivoConvertido = 0;
            }
        }
    }

    function activate() {

        var staticFilter = {};

        var defaultColumnOrder = ['id', 'cobro.numeroCobro', 'cobro.numeroRecibo', 'cobro.fecha', 'entidadBancaria.nombre',
            'numeroCheque','numeroTransaccion', 'fecha', 'fechaPagoDiferido', 'depositado', 'importe', 'formaPago.descripcion'];

        TesoreriaLangFactory.getTranslations().then(function (translations) {

            $scope.opcionesCobroDt = {
                'resource': 'cobrosdetalles',
                'title': 'Listado de Cobros',
                'view': 'CobroDetalleList',
                'factory': CobroDetalleFactory,
                'staticFilter': staticFilter,
                'defaultColumnOrder': defaultColumnOrder,
                // La propiedad columns se definira dependiendo del check seleccionado, ya que se deben mostrar campos diferentes para cada check.
                'isSelectable': true,
                'selection': {},
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'defaultOrderColumn': 4,
                'defaultOrderDir': "desc",
            };
        });

        var filterFormaPago = {};
        filterFormaPago.search = filterFactory.and([{
            path: 'clase.codigo',
            notEquals: "forma_pago_referencia"
        },{
            path: 'clase.codigo',
            notEquals: "forma_pago_retención"
        },{
            path: 'clase.codigo',
            notEquals: "forma_pago_cuenta_contable"
        },{
            path: 'clase.codigo',
            notEquals: "forma_pago_cuenta_contable"
        }]).value();
        $scope.listaFormasPago = FormaPagoFactory.all(filterFormaPago);

        
        $scope.cuentasContables = CuentaContableFactory.all();
        $scope.search = {};
        SucursalesFactory.all().$promise.then(function (response) {
            $scope.listaSucursales = response;
            $scope.listaSucursales.push({ 'id': '', 'nombre': 'Todos' });
            $scope.search.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];
        });

        EntidadBancariaFactory.all().$promise.then(function (response) {
            $scope.listaBancos = response;
            $scope.listaBancos.push({ 'id': '', 'nombre': 'Todos' });
            $scope.search.banco = $scope.listaBancos[$scope.listaBancos.length - 1];
        });

        if ($state.includes("app.procesoscobro.proceso.depositocobranza")) {
            $scope.isProcesoCobro = true;
            $scope.procesoCobro = ProcesoCobroFactory.getProcesoCobro();
        }
        var translationsPromise = undefined;
        if ($scope.isProcesoCobro) {
            translationsPromise = VentasLangFactory.getTranslations();
        } else {
            translationsPromise = TesoreriaLangFactory.getTranslations();
        }
        var staticFilterTMB = {
            search: filterFactory.and([{ path: "activo", equals: true }]).value()
        };
        translationsPromise.then(function (translations) {
            $scope.translations = translations;
            $scope.tiposMovimientos = TipoMovimientoBancarioFactory.all(
                staticFilterTMB
            );

            if (
                $state.is("app.movimientobancario.new") ||
                $state.is("app.procesoscobro.proceso.depositocobranza.new")
            ) {
                activateNew();
            } else if (
                $state.is("app.movimientobancario.edit") ||
                $state.is("app.procesoscobro.proceso.depositocobranza.edit")
            ) {
                activateEdit();
            } else if (
                $state.is("app.movimientobancario.view") ||
                $state.is("app.procesoscobro.proceso.depositocobranza.view") ||
                $state.is("app.procesoscobro.verproceso.depositocobranza.view")
            ) {
                activateView();
            }
            $rootScope.isProcessing = false;
            $scope.submited = false;

            cuentaBancariaFactory.all(null, "MovimientoBancarioForm").$promise.then(function (response) {
                //console.log("response",response);
                for (var i = 0; i < response.length; i++) {
                    //console.log("response[i]",response[i]);
                    response[i].numeroNombre =
                        response[i].numeroCuenta + "-" + response[i].nombreCuenta;
                }
                $scope.cuentas = response;
                //console.log("cuentas",$scope.cuentas);
            });
        });
    };


    function activateNew() {
        $scope.title = $filter("translate")("NEW_BANK_MOVEMENT");
        $scope.movimientoBancario = {
            cobroDeposito: [{}]
        };

        $scope.movimientoBancario.importeEfectivo = 0;
        $scope.movimientoBancario.totalEfectivo = 0;
        $scope.movimientoBancario.totalCheque = 0;
        $scope.movimientoBancario.totalOtro = 0;
        $scope.movimientoBancario.totalImportes = 0;
        $scope.movimientoBancario.chequeEsteBanco = 0;
        $scope.movimientoBancario.chequeOtroBanco = 0;
        $scope.movimientoBancario.monto = 0;
        $scope.movimientoBancario.esEfectivo = false;
        $scope.movimientoBancario.esCheque = false;
        if ($scope.isProcesoCobro) {
            $scope.movimientoBancario.procesoCobro = $scope.procesoCobro;
            $scope.tiposMovimientos.$promise.then(function (response) {
                _.forEach(response, function(pp){
                    if(pp.nombre == 'Crédito'){
                        $scope.movimientoBancario.tipoMovimiento  = pp;
                    };
                  });               
            });
            recuperarCobros();
        }
    }

    $scope.$watch('opcionesCobroDt.selection', function (newValue, oldValue) {
        if ( $scope.movimientoBancario && !$scope.view && ($scope.movimientoBancario.esCheque ||
            $scope.movimientoBancario.esEfectivo || $scope.movimientoBancario.esOtro)) {
            $scope.movimientoBancario.monto = 0;
            $scope.movimientoBancario.totalCheque = 0;
            $scope.movimientoBancario.totalEfectivo = 0;
            $scope.movimientoBancario.totalOtro = 0;
            $scope.movimientoBancario.totalImportes = 0;
            _.forEach(newValue, function (n, key) {
                if (n) {
                    CobroDetalleFactory.get(
                        key,
                        "CobroDetalleList"
                    ).$promise.then(function (response) {
                        if (response) {
                            var cobroDetalle = response;
                            $scope.movimientoBancario.monto += cobroDetalle.importe;

                            if ($scope.movimientoBancario.esCheque == true) {
                                $scope.movimientoBancario.totalCheque += cobroDetalle.importe;

                            } else if ($scope.movimientoBancario.esEfectivo == true) {
                                $scope.movimientoBancario.totalEfectivo += cobroDetalle.importe;

                            } else if ($scope.movimientoBancario.esOtro == true) {
                                if(cobroDetalle.formaPago.clase.codigo == 'forma_pago_efectivo'){
                                    $scope.movimientoBancario.totalEfectivo += cobroDetalle.importe;
                                }else if (cobroDetalle.formaPago.clase.codigo == 'forma_pago_cheque'){
                                    $scope.movimientoBancario.totalCheque += cobroDetalle.importe;
                                }else{
                                    $scope.movimientoBancario.totalOtro += cobroDetalle.importe;
                                }
                            }
                            $scope.movimientoBancario.totalImportes += cobroDetalle.importe;
                        }
                    });
                }
            });
        }

    }, true);

    function recuperarCobros() {

        if ($scope.procesoCobro) {

            var filterCobro = {
                search: filterFactory
                    .and([
                        {
                            path: "procesoCobro.id",
                            equals: $scope.procesoCobro.id
                        },
                        {
                            path: "estado.codigo",
                            equals: "estado_cobro_emitido"
                        }
                    ])
                    .value()
            }
        };
        CobroFactory.all(filterCobro, "MovimientoBancarioForm").$promise.then(function (cobros) {
            $scope.cobros = cobros;

            _.forEach(cobros, function (itemCobro) {
                $scope.cobros = [];
                var filterMovimiento = {
                    search: filterFactory
                        .and([
                            {
                                path: "cobro.id",
                                equals: itemCobro.id
                            },
                            {
                                path: "confirmado",
                                equals: true
                            }
                        ])
                        .value()
                };

                MovimientoBancarioFactory.all(filterMovimiento, "MovimientoBancarioForm").$promise.then(function (movimiento) {
                    if (movimiento.length == 0) {
                        $scope.cobros.push(itemCobro);
                    }
                });

            });
        });
    }

    function activateEdit() {

        if ($scope.isProcesoCobro) {
            recuperarCobros();
        }

        $scope.title = $filter("translate")("EDIT_BANK_MOVEMENT");
        $scope.movimientoBancario = movimientoBancarioPrepService;
        $scope.movimientoBancario.fecha = new Date($scope.movimientoBancario.fecha);
        $scope.movimientoBancario.cuenta.numeroNombre =
            $scope.movimientoBancario.cuenta.numeroCuenta +
            "-" +
            $scope.movimientoBancario.cuenta.nombreCuenta;

        $scope.entidadId = $scope.movimientoBancario.id;
        $scope.entidad = "MovimientoBancario";

        $scope.movimientoBancario.monto = $scope.movimientoBancario.importeEfectivo +
            $scope.movimientoBancario.chequeOtroBanco + $scope.movimientoBancario.chequeEsteBanco;

        if ($scope.movimientoBancario.tipoMovimiento.tipo.codigo == 'tipo_movimiento_transferencia') {

            $scope.movimientoBancario.cuentaTransaccion.numeroNombre =
                $scope.movimientoBancario.cuentaTransaccion.numeroCuenta +
                "-" +
                $scope.movimientoBancario.cuentaTransaccion.nombreCuenta;
        }

        if (
            $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
            "tipo_movimiento_debito"
        ) {
            $scope.movimientoBancario.monto = $scope.movimientoBancario.salida;
        } else if (
            $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
            "tipo_movimiento_credito"
        ) {
            $scope.movimientoBancario.monto = $scope.movimientoBancario.entrada;
        } else if (
            $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
            "tipo_movimiento_transferencia"
        ) {
            $scope.movimientoBancario.monto = $scope.movimientoBancario.salida;
        } else if (
            $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
            "tipo_movimiento_transferencia"
        ) {
            if ($scope.movimientoBancario.salida) {
                $scope.movimientoBancario.monto = $scope.movimientoBancario.salida;
            } else {
                $scope.movimientoBancario.monto = $scope.movimientoBancario.entrada;
            }
        }

        // Redondeo acorde a moneda
        if ($scope.movimientoBancario.cuenta.moneda) {
            if ($scope.movimientoBancario.cuenta.moneda.codigo === 'guaranies') {
                $scope.movimientoBancario.monto = parseFloat($scope.movimientoBancario.monto.toFixed(0));
            } else if ($scope.movimientoBancario.cuenta.moneda.codigo === 'dolares') {
                $scope.movimientoBancario.monto = parseFloat($scope.movimientoBancario.monto.toFixed(2));
            }
        }
    }

    function activateView() {
        $scope.title = $filter("translate")("VIEW_BANK_MOVEMENT");
        $scope.movimientoBancario = movimientoBancarioPrepService;
        $scope.movimientoBancario.fecha = new Date($scope.movimientoBancario.fecha);
        $scope.entidadId = $scope.movimientoBancario.id;
        $scope.entidad = "MovimientoBancario";
        $scope.view = true;
        $scope.search = {};

        $scope.movimientoBancario.monto = $scope.movimientoBancario.importeEfectivo +
            $scope.movimientoBancario.chequeOtroBanco + $scope.movimientoBancario.chequeEsteBanco;

        $scope.movimientoBancario.cuenta.numeroNombre = $scope.movimientoBancario.cuenta.numeroCuenta +
            "-" + $scope.movimientoBancario.cuenta.nombreCuenta;

        if ($scope.movimientoBancario.tipoMovimiento.tipo.codigo == 'tipo_movimiento_transferencia') {
            $scope.movimientoBancario.cuentaTransaccion.numeroNombre =
                $scope.movimientoBancario.cuentaTransaccion.numeroCuenta + "-" +
                $scope.movimientoBancario.cuentaTransaccion.nombreCuenta;
        }

        if ($scope.movimientoBancario.tipoMovimiento.tipo.codigo == "tipo_movimiento_debito") {
            if ($scope.movimientoBancario.desembolsoOrdenPago != null) {
                if ($scope.movimientoBancario.desembolsoOrdenPago.estado.codigo != "estado_desembolso_anulado") {
                    $scope.movimientoBancario.monto = $scope.movimientoBancario.salida;
                } else {
                    $scope.movimientoBancario.monto = $scope.movimientoBancario.entrada;
                }
            } else {
                $scope.movimientoBancario.monto = $scope.movimientoBancario.salida;
            }
        } else if ($scope.movimientoBancario.tipoMovimiento.tipo.codigo == "tipo_movimiento_credito") {
            $scope.movimientoBancario.monto = $scope.movimientoBancario.entrada;
        } else if ($scope.movimientoBancario.tipoMovimiento.tipo.codigo == "tipo_movimiento_transferencia") {
            if ($scope.movimientoBancario.salida) {
                $scope.movimientoBancario.monto = $scope.movimientoBancario.salida;
            } else {
                $scope.movimientoBancario.monto = $scope.movimientoBancario.entrada;
            }
        }

        // Redondeo acorde a moneda
        if ($scope.movimientoBancario.cuenta.moneda) {
            if ($scope.movimientoBancario.cuenta.moneda.codigo === 'guaranies') {
                $scope.movimientoBancario.monto = parseFloat($scope.movimientoBancario.monto.toFixed(0));
            } else if ($scope.movimientoBancario.cuenta.moneda.codigo === 'dolares') {
                $scope.movimientoBancario.monto = parseFloat($scope.movimientoBancario.montotoFixed(2));
            }
        }

        // Condicion para mostrar grilla
        if ($scope.movimientoBancario.cobroDetalles.length>0) {
            
            // Siempre se debe definir 1ero los campos de la grilla
            definirCamposDatatable();
            
            var filters = {
                search: filterFactory.single({
                    path: 'movimientoBancario.id',
                    equals: $scope.movimientoBancario.id
                }).value()
            };
            $scope.opcionesCobroDt.staticFilter = filters;
            $scope.showDtPedidosVentas = true;
            formSelectionsArray();
        }

    }

    function evaluatePeriodoAdministrativo() {
        return $q(function (resolve, reject) {
            var params = {
                search: filterFactory.single({
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                }).value()
            };
            params.view = "BaseList"

            PeriodoAdministrativoFactory.all(params).$promise.then(function (response) {
                var periodos = response;

                var banExistePeriodo = false;
                _.forEach(periodos, function (value) {
                    var desde = new Date(value.fechaDesde);
                    var hasta = new Date(value.fechaHasta);
                    var fecha = new Date($scope.movimientoBancario.fecha);

                    if (fecha >= desde && fecha <= hasta) {

                        banExistePeriodo = true;
                        return;
                    }
                })
                resolve(banExistePeriodo);
            });
        });
    }

    function submit() {

        $scope.submited = true;
        $rootScope.isProcessing = true;

        if ($scope.MovimientoBancarioForm.$valid) {

            if(($scope.movimientoBancario.esCheque || $scope.movimientoBancario.esEfectivo || $scope.movimientoBancario.esOtro) && $scope.movimientoBancario.totalImportes != $scope.movimientoBancario.monto){
                notify({ message: "El importe del movimiento no coincide con el importe total de los detalles", classes: 'alert-danger', position: 'right' });
                $scope.submited = false;
                $rootScope.isProcessing = false;
                return;
            }

            if(!/^\d+$/.test($scope.movimientoBancario.numero)){
                notify({ message: "El campo Número debe ser solo numerico", classes: 'alert-danger', position: 'right' });
                $scope.submited = false;
                $rootScope.isProcessing = false;
                return;
            }

            if ($scope.movimientoBancario.esEfectivo == true && $scope.movimientoBancario.esCheque == true) {
                $rootScope.isProcessing = false;
                notify({
                    message: "Solo puede marcar un tipo de Forma de Deposito",
                    classes: "alert-danger",
                    position: "right"
                });
                return;
            }

            if ($scope.movimientoBancario.esCheque == true || $scope.movimientoBancario.esOtro == true) {
                var idsDetails = getIDs();
                if (!$scope.showDtPedidosVentas || !idsDetails || idsDetails.length == 0) {
                    notify({ message: "El detalle de cheques no debe quedar vacía.", classes: 'alert-danger', position: 'right' });
                    $rootScope.isProcessing = false;
                    return;
                }
            }

            if ($scope.movimientoBancario.cuenta && $scope.movimientoBancario.cuentaTransaccion) {
                if ($scope.movimientoBancario.cuenta.id === $scope.movimientoBancario.cuentaTransaccion.id) {
                    $rootScope.isProcessing = false;
                    notify({
                        message: $filter("translate")("ERROR_MISMA_CUENTA"),
                        classes: "alert-danger",
                        position: "right"
                    });
                    return;
                }
            }

            if ($scope.movimientoBancario.monto <= 0) {
                $rootScope.isProcessing = false;
                notify({
                    message: $filter("translate")("ERROR_MONTO_CERO"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else {
                if (
                    $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
                    "tipo_movimiento_debito" ||
                    $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
                    "tipo_movimiento_emision_checke"
                ) {
                    $scope.movimientoBancario.salida = $scope.movimientoBancario.monto;
                    delete $scope.movimientoBancario.monto;
                } else if (
                    $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
                    "tipo_movimiento_credito" ||
                    $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
                    "tipo_movimiento_deposito"
                ) {
                    $scope.movimientoBancario.entrada = $scope.movimientoBancario.monto;
                    delete $scope.movimientoBancario.monto;
                } else if (
                    $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
                    "tipo_movimiento_transferencia"
                ) {
                    $scope.movimientoBancario.salida = $scope.movimientoBancario.monto;
                    delete $scope.movimientoBancario.monto;
                }

                evaluatePeriodoAdministrativo().then(function (response) {
                    if (!response) {
                        $rootScope.isProcessing = false;
                        var msg = ' ' + $filter("translate")("NO_ADMINISTRATIVE_PERIOD");
                        notify({ message: msg, classes: "alert-danger", position: "right" });
                    } else {
                        submitMovimiento().then(function (response) {
                            ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                                if (parametro.valorTipo.codigo == 'si') {
                                    if (response.enviadoAContabilidad == false) {
                                        notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                    }
                                }
                                if (response.listaArchivosLogs != null) {
                                    if (response.listaArchivosLogs.length != 0) {
                                        /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                            console.log('response.listaArchivosLogs: ');
                                            console.log(response.listaArchivosLogs[i]);
                                            $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                        }*/
                                        notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                    }
                                }
                            });
                            $location.path('/movimientobancario');
                            if ($scope.isProcesoCobro) {
                                $state.go("app.procesoscobro.proceso.depositocobranza.list");
                            } else {
                                $state.go("app.movimientobancario.list");
                            }
                        }, function (error) {
                            $rootScope.isProcessing = false;
                            console.log(error);

                            if (error.data[0] && error.data[0].constraint == "numero, id_cuenta, id_tipo_movimiento") {
                                notify({ message: $filter('translate')('CONSTRAINT_ERROR'), classes: 'alert-danger', position: 'right' });
                            }

                            if (
                                $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
                                "tipo_movimiento_debito" ||
                                $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
                                "tipo_movimiento_emision_checke"
                            ) {
                                $scope.movimientoBancario.monto =
                                    $scope.movimientoBancario.salida;
                            } else if (
                                $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
                                "tipo_movimiento_credito" ||
                                $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
                                "tipo_movimiento_deposito"
                            ) {
                                $scope.movimientoBancario.monto =
                                    $scope.movimientoBancario.entrada;
                            } else if (
                                $scope.movimientoBancario.tipoMovimiento.tipo.codigo ==
                                "tipo_movimiento_transferencia"
                            ) {
                                $scope.movimientoBancario.monto =
                                    $scope.movimientoBancario.salida;
                            }
                        });
                    }
                });
            }
        } else {
            //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
            $rootScope.isProcessing = false;
        }
    }

    function submitMovimiento() {
        var trimmedModel = ModelTrimmer.trimDetails($scope.movimientoBancario);
        var idsDetails = getIDs();
        trimmedModel.cobroDetalles = _.map(idsDetails, function (id) {
            var idVal = parseInt(id);

            if (!idVal) {
                console.error("No se pudo parsear Id");
                return;
            }
            return idVal;
        });
        var resource = MovimientoBancarioFactory.create(trimmedModel);
        if ($scope.movimientoBancario.cuentaContable == undefined) {
            resource.cuentaContable = null;
        }
        return MovimientoBancarioFactory.save(resource);
    }

    var getIDs = function () {
        var ids = [];
        _.forEach($scope.opcionesCobroDt.selection, function (n, key) {
            if (n)
                ids.push(key);
        });
        return ids;
    };

    var formSelectionsArray = function () {
        console.log("forming selections array");
        $scope.$watch('opcionesCobroDt.selection', function (newValue, oldValue) {
            if (!$scope.opcionesCobroDt.selection || $scope.selectedChecked) {
                return;
            }
            $scope.selectedChecked = true;
            $scope.movimientoBancario.monto = 0;
            _.forEach($scope.movimientoBancario.cobroDetalles, function (op) {
                if (op.id) {
                    $scope.opcionesCobroDt.selection[op.id] = true;
                    $scope.movimientoBancario.monto += op.importe;
                } else {
                    $scope.opcionesCobroDt.selection[op] = true;
                }
            });
        });
    };

    function errorHandler(e) {
        var msg = "";
        _.forEach(e.data, function (error) {
            if (error.constraint == "nombre") {
                msg += "\n\n" + $filter("translate")("SAME_NAME_PRODUCT_CLASS") + "\n";
            } else {
                msg += "\n\n" + error.message + ".";
            }
        });
        notify({ message: msg, classes: "alert-danger", position: "right" });
    }

    function cancel() {
        if ($scope.isProcesoCobro) {
            $state.go("app.procesoscobro.proceso.depositocobranza.list");
        } else {
            $state.go("app.movimientobancario.list");
        }
    }

    $scope.resource = "movimientobancario"; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.tesoreria')
  .controller('TipoOrdenPagoFormCtrl', TipoOrdenPagoFormCtrl);

TipoOrdenPagoFormCtrl.$inject = ['$rootScope', '$scope', 'TipoOrdenPagoFactory', '$location', '$state',
'tipoOrdenPagoPrepService', 'notify', 'formFactory', 'TesoreriaLangFactory', 'TiposFactory', '$filter',
'ParametrosFactory'];

function TipoOrdenPagoFormCtrl($rootScope, $scope, TipoOrdenPagoFactory, $location, $state,
  tipoOrdenPagoPrepService, notify, formFactory, TesoreriaLangFactory, TiposFactory, $filter,
  ParametrosFactory) {

  activate();
  //var $scope = this;

  $scope.cancel = cancel;
  $scope.submit = submit;


  function activate() {
    TesoreriaLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
      if($state.is("app.tipoordenpago.new")) {
        activateNew();
      } else if($state.is("app.tipoordenpago.edit")) {
        activateEdit();
      } else if($state.is("app.tipoordenpago.view")) {
        activateView();
      }
      TiposFactory.clasesOrdenPago().then(function (data) {
        $scope.clasesOrdenPago = data.data;
      });
      $rootScope.isProcessing = false;
      $scope.submited =false;

    });
  }

  function activateNew() {
    $scope.title = $filter('translate')('NEW_TIPO_OP');
    $scope.tipoOrdenPago = {activo: true};

  }

  function activateEdit() {
    $scope.title = $filter('translate')('EDIT_TIPO_OP');
    $scope.tipoOrdenPago = tipoOrdenPagoPrepService;
    $scope.entidadId=$scope.tipoOrdenPago.id;
    $scope.entidad="TipoOrdenPago";
  }

  function activateView() {
    $scope.title = $filter('translate')('VIEW_TIPO_OP');
    $scope.tipoOrdenPago = tipoOrdenPagoPrepService;
    $scope.entidadId=$scope.tipoOrdenPago.id;
    $scope.entidad="TipoOrdenPago";
    $scope.view = true;
  }

  function submit() {
    console.log("submit");
    $scope.submited = true;
    if($scope.TipoOrdenPagoForm.$valid) {
        formFactory.defaultNSFSubmit($scope.TipoOrdenPagoForm, TipoOrdenPagoFactory, $scope.tipoOrdenPago, errorHandler).then(function (response) {
          ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
            if (parametro.valorTipo.codigo == 'si') {
                if(response.enviadoAContabilidad == false){
                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                }
            }
          });
          $location.path('/tipoordenpago');
        }, function(error) {
          console.log(error);
        });
    } else {
      //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
      if(error.constraint=='nombre'){
        msg += '\n\n' + $filter('translate')('SAME_NAME_RECORD_FOUND')+"\n"
      }else{
        msg += '\n\n' + error.message + '.'
      }
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function cancel() {
    $location.path("/tipoordenpago");
  }

  $scope.resource = 'tipoordenpago'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';


angular.module('qualita.tesoreria')
  .controller('CuentaBancariaIndexCtrl', ["$state", "$scope", "cuentaBancariaFactory", "filterFactory", "ReportTicketFactory", "$window", "TesoreriaLangFactory", "MovimientoBancarioFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", function ($state, $scope, cuentaBancariaFactory, filterFactory, ReportTicketFactory, $window, TesoreriaLangFactory, MovimientoBancarioFactory,
    $filter, UtilFactory, CsvFactory, baseurl, AuthorizationService) {

    var dateRender = function (data, type, full) {
      if (data) {
        return moment.utc(data).format('DD/MM/YYYY');
      } else {
        return "";
      }
    };

    var monedaRender = function (data, type, row) {
      if (data) {
        var moneda = "Gs. ";
        if (row.moneda.codigo === 'dolares') {
          moneda = "Usd. ";
          data = parseFloat(data).toFixed(2);
        } else if (row.moneda.codigo === 'pesos') {
          moneda = "Pes. ";
          data = parseFloat(data).toFixed(2);
        } else if (row.moneda.codigo === 'real') {
          moneda = "Rel. ";
          data = parseFloat(data).toFixed(2);
        } else if (row.moneda.codigo === 'euro') {
          moneda = "Eur. ";
          data = parseFloat(data).toFixed(2);
        }
        // TODO hacer esto mismo en todos los renders de moneda
        return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return '';
      }
    };

    TesoreriaLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'numeroCuenta', 'nombreCuenta', 'entidad.nombre', 'tipoCuenta.descripcion', 'moneda.descripcion',
        'fechaApertura', 'plantillaChequeDia', 'plantillaChequeDiferido', 'permitirSobregiro', 'tasaSobregiro'];
      $scope.options = {
        'resource': 'cuentabancaria',
        'title': $filter('translate')('BANK_ACCOUNTS'),
        'factory': cuentaBancariaFactory,
        'view': 'CuentaBancariaList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('FAIL_DELET_CUENTA'),
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('ID'), visible: false  },
          { 'data': 'numeroCuenta', 'title': $filter('translate')('ACCOUNT_NUMBER'), 'class': 'dt-right' },
          { 'data': 'nombreCuenta', 'title': $filter('translate')('NOMBRE') },
          { 'data': 'entidad.nombre', 'title': $filter('translate')('ENTITY_NAME') },
          { 'data': 'tipoCuenta.descripcion', 'title': $filter('translate')('ACCOUNT_TYPE') },
          { 'data': 'moneda.descripcion', 'title': $filter('translate')('CURRENCY') },
          { 'data': 'fechaApertura', 'title': $filter('translate')('DATE_OPENING'), renderWith: "dateRender", dateFormat: 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
          { 'data': 'plantillaChequeDia', 'title': $filter('translate')('PLANTILLA_CHEQUE_DIA'), visible: false },
          { 'data': 'plantillaChequeDiferido', 'title': $filter('translate')('PLANTILLA_CHEQUE_DIFERIDO'), visible: false },
          { 'data': 'permitirSobregiro', 'title': $filter('translate')('PERMITE_SOBREGIRO'), visible: false, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
          { 'data': 'tasaSobregiro', 'title': $filter('translate')('TASA_SOBREGIRO'), visible: false, 'class': 'dt-right', render: monedaRender}
        ],
        'hasOptions': true,
        'hideEditMenu': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoCuentasBancarias', filters, $scope.options.tableAjaxParams,
                  $scope.options.currentColumnOrder).then(function (genTicket) {
                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                    $window.open($scope.pdfDownloadURL, '_blank');
                  });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("cuentabancaria", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ],
        'extraRowOptions': [
          {
            templateToRender: "<button class='btn btn-success' style='margin-right: 5px;'   title=" + $filter('translate')('EDIT') + " ng-click='editarProceso(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
            customAttribute: 'editable',
            conditionName: 'canEditar',
            conditionDef: function (editable) {
              return AuthorizationService.hasPermission("update_cuentabancaria");
            },
            functionName: "editarProceso",
            functionDef: function (itemId) {
              $state.go("app.cuentabancaria.edit", { id: itemId });
            }
          }]
      };


      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            search = search.split("~");
            if (search.length > 1) {
                /* El parámetro es un rago */

                var isDate = search[0].split("/");

                if (isDate.length > 1) {
                    /* Es un rago de fechas */

                    filtersArr.push({path: data, equalOrAfter: search[0]})
                    filtersArr.push({path: data, equalOrBefore: search[1]})
                } else {
                    /* El un rago numérco */

                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                    filtersArr.push({path: data, lessOrEqual: search[1]})
                }
            } else {
                /* El parametro no es un rago */
                if(search[0] === 'No' && data === 'permitirSobregiro'){
                    search[0] = 'false';
                }
                if(search[0] === 'Sí' && data === 'permitirSobregiro'){
                    search[0] = 'true';
                }
    
                filtersArr.push({path: data, like: search[0]})
            }
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
    });
  }]);

'use strict';


angular.module('qualita.tesoreria')
    .controller('EntidadBancariaIndexCtrl', ["$scope", "EntidadBancariaFactory", "filterFactory", "ReportTicketFactory", "$window", "TesoreriaLangFactory", "$filter", "$modal", "UtilFactory", "CsvFactory", "baseurl", function ($scope, EntidadBancariaFactory, filterFactory, ReportTicketFactory, $window, TesoreriaLangFactory, $filter, $modal, UtilFactory, CsvFactory, baseurl) {
        TesoreriaLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'nombre', 'ruc', 'direccion', 'telefono', 'email',
                'activo', 'plantillaChequeDia', 'plantillaChequeDiferido'];
            $scope.options = {
                'resource': 'entidadesbancarias',
                'title': $filter('translate')('BANK_ENTITIES'),
                'factory': EntidadBancariaFactory,
                'view': 'BaseList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('FAIL_DELET_ENTIDAD'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('ID'), visible: false  },
                    { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
                    { 'data': 'ruc', 'title': $filter('translate')('RUC'), 'class': 'dt-right' },
                    { 'data': 'direccion', 'title': $filter('translate')('DIRECCION') },
                    { 'data': 'telefono', 'title': $filter('translate')('TELEFONO'), 'class': 'dt-right' },
                    { 'data': 'email', 'title': $filter('translate')('EMAIL') },
                    { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideRemoveMenu': true,
                'extraMenuOptions':
                    [
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoEntidadesBancarias', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("entidadesbancarias", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        }
                    ],

                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                            $filter("translate")("DELETE") +
                            "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: "estado",
                        conditionName: "canBorrar",
                        conditionDef: function (estado) {
                            return true;
                        },
                        functionName: "borrar",
                        functionDef: function (itemId) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = $filter("translate")("DELETE_CONFIRMATION");
                            $scope.mensajeModal = $filter("translate")("DELETE_WARNING");
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss("cancel");
                            };

                            $scope.ok = function (itemId) {
                                var model = EntidadBancariaFactory.create({ id: itemId });
                                EntidadBancariaFactory.remove(model).then(
                                    function () {
                                        modalInstance.close(itemId);
                                        $state.go($state.current, {}, { reload: true });
                                    },
                                    function (error) {
                                        modalInstance.dismiss("cancel");
                                        $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                        $scope.mensajeModal = $filter("translate")(
                                            "FAIL_DELET_ENTIDAD"
                                        );

                                        var modalInstance2 = $modal.open({
                                            templateUrl: "views/directives/information-modal.html",
                                            scope: $scope
                                        });
                                        $scope.cancel = function () {
                                            modalInstance2.dismiss("cancel");
                                        };
                                    }
                                );
                            };
                        }
                    }
                ]

            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if(search === 'No' && data === 'activo'){
                        search = 'false';
                    }
                    if(search === 'Sí' && data === 'activo'){
                        search = 'true';
                    }
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);

'use strict';


angular.module('qualita.tesoreria')
  .controller('TipoFondoFijoIndexCtrl', ["$scope", "TipoFondoFijoFactory", "filterFactory", "ReportTicketFactory", "$window", "TesoreriaLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", function ($scope, TipoFondoFijoFactory, filterFactory, ReportTicketFactory, $window, TesoreriaLangFactory,
    $filter, UtilFactory, CsvFactory, baseurl) {
    TesoreriaLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'nombre', 'maximoFactura', 'maximoTotal'];

      var monedaRender = function (data, type, row) {
        if (data) {
          // TODO hacer esto mismo en todos los renders de moneda
          return data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
          return '';
        }
      };

      $scope.options = {
        'resource': 'tipofondofijo',
        'title': $filter('translate')('TYPE_FONDO_FIJO_NAME'),
        'factory': TipoFondoFijoFactory,
        'view': 'BaseList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('FAIL_DELET_TYPE_FONDO_FIJO'),
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('ID'), visible: false  },
          { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
          { 'data': 'maximoFactura', 'title': $filter('translate')('MAX_FACTURA'), 'class': 'dt-right', 'render': monedaRender  },
          { 'data': 'maximoTotal', 'title': $filter('translate')('MAX_TOTAL'), 'class': 'dt-right', 'render': monedaRender  },
        ],
        'hasOptions': true,
        'defaultOrderColumn': 1,
        'defaultOrderDir': "asc",
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoTipoFondoFijo', filters, $scope.options.tableAjaxParams,
                  $scope.options.currentColumnOrder).then(function (genTicket) {
                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                    $window.open($scope.pdfDownloadURL, '_blank');
                  });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("tipofondofijo", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ]
      };
      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
    });
  }]);

'use strict';


angular.module('qualita.tesoreria')
  .controller('TipoMovimientoBancarioIndexCtrl', ["$scope", "TipoMovimientoBancarioFactory", "filterFactory", "ReportTicketFactory", "$window", "TesoreriaLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", function ($scope, TipoMovimientoBancarioFactory, filterFactory, ReportTicketFactory, $window, TesoreriaLangFactory, $filter, UtilFactory, CsvFactory, baseurl) {
    TesoreriaLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'nombre', 'tipo.descripcion', 'imprimirMovimiento', 'activo'];
      $scope.options = {
        'resource': 'tipomovimientobancario',
        'title': $filter('translate')('TYPE_BANK_MOVEMENT_NAME'),
        'factory': TipoMovimientoBancarioFactory,
        'view': 'TipoMovimientoBancarioList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('FAIL_DELET_TYPE_BANK_MOVEMENT'),
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('ID'), visible: false  },
          { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
          { 'data': 'tipo.descripcion', 'title': $filter('translate')('TYPE') },
          { 'data': 'imprimirMovimiento', 'title': $filter('translate')('IMPRIMIR_MOVIMIENTO_BANCARIO'), 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
          { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoTipoMovimientoBancario', filters, $scope.options.tableAjaxParams,
                  $scope.options.currentColumnOrder).then(function (genTicket) {
                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                    $window.open($scope.pdfDownloadURL, '_blank');
                  });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("tipomovimientobancario", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ],
        'extraRemoveConditions': function (item) {
          return item.editable == 'Sí';
          
        },
        'extraEditConditions': function(item) {
          if(item.nombre === 'Débito por transferencia directa a proveedores'){
            return false;
          }else{
            return true;
          }
        }
      };

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          if(search === 'No' && (data === 'activo' || data === 'imprimirMovimiento')){
            search = 'false';
        }
        if(search === 'Sí' && (data === 'activo' || data === 'imprimirMovimiento')){
            search = 'true';
        }
          filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
    });
  }]);

"use strict";

angular
        .module("qualita.tesoreria")
        .controller("MovimientoBancarioIndexCtrl", ["$scope", "MovimientoBancarioFactory", "filterFactory", "ReportTicketFactory", "$window", "TesoreriaLangFactory", "$modal", "$state", "cuentaBancariaFactory", "notify", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "ProcesoCobroFactory", "VentasLangFactory", "PeriodoAdministrativoFactory", "$filter", "$q", "ParametrosFactory", function (
                $scope,
                MovimientoBancarioFactory,
                filterFactory,
                ReportTicketFactory,
                $window,
                TesoreriaLangFactory,
                $modal,
                $state,
                cuentaBancariaFactory,
                notify,
                UtilFactory,
                CsvFactory,
                baseurl,
                AuthorizationService,
                ProcesoCobroFactory,
                VentasLangFactory,
                PeriodoAdministrativoFactory,
                $filter,
                $q,
                ParametrosFactory
                ) {
            var vm = this;
            //$scope.boolean=false;
            var translationsPromise = undefined;
            if ($state.includes("app.procesoscobro.proceso.depositocobranza")) {
                $scope.isProcesoCobro = true;
                $scope.procesoCobro = ProcesoCobroFactory.getProcesoCobro();
                vm.soloLectura = false
            }
            if ($state.includes("app.procesoscobro.verproceso.depositocobranza")) {
                $scope.isProcesoCobro = true;
                $scope.procesoCobro = ProcesoCobroFactory.getProcesoCobro();
                vm.soloLectura = true
            }
            if ($scope.isProcesoCobro) {
                translationsPromise = VentasLangFactory.getTranslations();
            } else {
                translationsPromise = TesoreriaLangFactory.getTranslations();
            }

            var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
            var enviarAContabilidad = '';

            promise1.then(function(response) {
                enviarAContabilidad = response.valorTipo.codigo;
            });

            translationsPromise.then(function (translations) {
                var emptyDataRender = function (data) {
                    //console.log(data);
                    if (data == undefined)
                        return "";
                    else
                        return data;
                };

                var dateRender = function (data, type, full) {
                    if (data) {
                        return moment.utc(data).format("DD/MM/YYYY");
                    } else {
                        return "";
                    }
                };

                var importeRender = function (data, type, row) {
                    if (data) {
                        var moneda = "Gs. ";
                        if (row.cuenta.moneda.codigo === "dolares") {
                            moneda = "Usd. ";
                            data = parseFloat(data).toFixed(2);
                        } else if (row.cuenta.moneda.codigo === "pesos") {
                            moneda = "Pes. ";
                            data = parseFloat(data).toFixed(2);
                        } else if (row.cuenta.moneda.codigo === "real") {
                            moneda = "Rel. ";
                            data = parseFloat(data).toFixed(2);
                        } else if (row.cuenta.moneda.codigo === "euro") {
                            moneda = "Eur. ";
                            data = parseFloat(data).toFixed(2);
                        }
                        // TODO hacer esto mismo en todos los renders de moneda
                        return (
                                moneda +
                                data
                                .toString()
                                .replace(".", ",")
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                );
                    } else {
                        return "";
                    }
                };

                if ($scope.isProcesoCobro) {
                    if (!$scope.procesoCobro) {
                        $state.go("app.main");
                    }
                } else {
                    console.log("NO EN PROCESO COBRO");
                }

                $scope.staticFilter = {};
                // FIXME: Qué lo que es esto?
                // var filters = [
                //   {
                //     path: "cuenta.id",
                //     equals: 0
                //   }
                // ];
                var filters = [];

                if ($scope.isProcesoCobro) {
                    filters.push({
                        path: "procesoCobro.id",
                        equals: $scope.procesoCobro.id
                    });
                }
                $scope.staticFilter.search = filterFactory.and(filters).value();

                var defaultColumnOrder = [
                    "id",
                    "cuenta.nombreCuenta",
                    "numero",
                    "tipoMovimiento.nombre",
                    "descripcion",
                    "cuentaTransaccion.nombreCuenta",
                    "entrada",
                    "salida",
                    "cheque.fecha_emision",
                    "fecha",
                    "cheque.fechaPago",
                    "confirmado",
                    "desembolsoOrdenPago.id",
                    "cuenta.numeroCuenta",
                    "cuentaTransaccion.numeroCuenta",
                    "cobro.numeroCobro",
                    "concepto"
                ];
                $scope.options = {
                    resource: "movimientobancario",
                    title: $filter("translate")("BANK_MOVEMENTS"),
                    factory: MovimientoBancarioFactory,
                    view: "MovimientoBancarioList",
                    defaultColumnOrder: defaultColumnOrder,
                    failedDeleteError: $filter("translate")("FAIL_DELET_ENTIDAD"),
                    columns: [
                        {data: "id", title: $filter("translate")("ID"), visible: false},
                        {
                            data: "numero",
                            title: $filter("translate")("NUMBER"),
                            class: "dt-right",
//                            type: "number-range"
                        },
                        {
                            data: "tipoMovimiento.nombre",
                            title: $filter("translate")("MOVEMENT_TYPE"),
                            renderWith: "emptyRender"
                        },
                        {
                            data: "concepto",
                            title: $filter("translate")("CHECK_CONCEPT"),
                            renderWith: "emptyRender"
                        },
                        {
                            data: "descripcion",
                            title: $filter("translate")("DESCRIPTION_ACCOUNT_MOV"),
                            renderWith: "emptyRender"
                        },
                        {
                            data: "cuenta.numeroCuenta",
                            title: $filter("translate")("NRO_CUENTA_ORIGEN"),
                            class: "dt-right",
                            render: emptyDataRender
                        },
                        {
                            data: "cuenta.nombreCuenta",
                            title: $filter("translate")("ORIGIN_ACCOUNT_NAME"),
                            render: emptyDataRender
                        },
                        {
                            data: "cuentaTransaccion.numeroCuenta",
                            title: $filter("translate")("NRO_CUENTA_DESTINO"),
                            class: "dt-right",
                            render: emptyDataRender
                        },
                        {
                            data: "cuentaTransaccion.nombreCuenta",
                            title: $filter("translate")("DESTINY_ACCOUNT_NAME"),
                            render: emptyDataRender
                        },
                        { 'data': 'cheque.fecha_emision', 'title': $filter('translate')('FECHA_EMISION'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
                        {
                            data: "fecha",
                            title: $filter("translate")("DATE"),
                            class: "dt-center",
                            renderWith: "dateRender",
                            dateFormat: 'DD/MM/YYYY',
                            type: "date-range"
                        },
                        { 'data': 'cheque.fechaPago', 'title': $filter('translate')('DATE_DIF'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
                        {
                            data: "entrada",
                            title: $filter("translate")("ENTRADA"),
                            render: importeRender,
                            class: "dt-right",
                            type: "number-range"
                        },
                        {
                            data: "salida",
                            title: $filter("translate")("SALIDA"),
                            render: importeRender,
                            class: "dt-right",
                            type: "number-range"
                        },
                        {
                            data: "confirmado",
                            title: $filter("translate")("CONFIRMED"),
                            class: "dt-center",
                            type: "combo",
                            filterUrl: "tipos/filtro/boolean_values"
                        },
                        {
                            data: "desembolsoOrdenPago.id",
                            title: $filter("translate")("COD_DESEMBOLSO"),
                            class: "dt-right",
//                            type: "number-range",
                            renderWith: 'emptyRender'
                        },
                        {
                            data: "cobro.numeroCobro",
                            title: $filter("translate")("CODIGO_COBRO"),
                            renderWith: "emptyRender",
                            visible: true
                        }
                    ],
                    hasOptions: true,
                    defaultOrderColumn: 0,
                    defaultOrderDir: "desc",
                    hideRemoveMenu: true,
                    hideAddMenu: true,
                    hideEditMenu: true,
                    extraMenuOptions: [
                        {
                            title: $filter("translate")("NEW"),
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function () {
                                var permission =
                                        AuthorizationService.hasPermission("create_movimientobancario")
                                return permission;
                            },
                            action: function () {
                                checkPeriodoAdministrativo(null).then(function (resultado) {
                                    if (resultado == true) {
                                        $state.go("app.movimientobancario.new");
                                    } else if (resultado == false) {
                                        var msg = $filter('translate')('NO_OPEN_PERIODS_NOW');
                                        notify({message: msg, classes: 'alert-danger', position: 'right'});
                                    }
                                });
                            }
                        },
                        {
                            title: "Reporte",
                            icon: "glyphicon glyphicon-file",
                            showCondition: function () {
                                return true;
                            },
                            action: function () {

                                var filters;
                                if($scope.isProcesoCobro){
                                    filters = createFilters($scope.options.getFilters(), $scope.staticFilter);
                                }else{
                                    filters = createFilters($scope.options.getFilters());
                                }
                                ReportTicketFactory.ticket('libroBanco', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.staticFilter).then(function (genTicket) {
                                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                    $window.open($scope.pdfDownloadURL, '_blank');
                                });
                            }
                        },
                        {
                            title: "CSV",
                            icon: "glyphicon glyphicon-export",
                            showCondition: function () {
                                return true;
                            },
                            action: function () {
                                var filters = UtilFactory.createNestedFilters(
                                        $scope.options.getFilters(),
                                        $scope.options.staticFilter
                                        );

                                CsvFactory.csv(
                                        "movimientobancario",
                                        filters,
                                        $scope.options.tableAjaxParams,
                                        $scope.options.currentColumnOrder,
                                        $scope.options.currentColumnOrderNames
                                        ).then(function (response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                            }
                        }
                    ],
                    extraRemoveConditions: function (item) {
                        if (item)
                            return item.confirmado == "No";
                    },
                    extraEditConditions: function (item) {
                        if (item)
                            return item.confirmado === "No" && item.isContrapartida === "No" && !item.cobro;
                    },
                    extraRowOptions: [{
                            templateToRender: "<button class='btn btn-primary btn-dt' title='" +
                                    $filter("translate")("CONFIRMAR") +
                                    "' style='margin-right: 5px;' ng-click='confirmar(<%=dataCustom%>)' ng-class='{hidden : !canConfirmar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-ok'></span> </button>",
                            customAttribute: ["confirmado", "isContrapartida", "id", "tipoMovimiento", "fecha","cobro"],
                            conditionName: "canConfirmar",
                            conditionDef: function (item) {
                                if ($scope.isProcesoCobro) {
                                    return (
                                        AuthorizationService.hasPermission(
                                            "confirmar_depositobancario"
                                        ) && item[0] == "No" &&
                                        ProcesoCobroFactory.getProcesoCobro().estado.codigo !=
                                        "estado_procesocobro_cerrado" && !vm.soloLectura
                                    );
                                }
                                return AuthorizationService.hasPermission(
                                    "confirmar_movimientobancario"
                                ) && item[0] == "No" && item[1] == "No"  && item[5] == null;
                            },
                            functionName: "confirmar",
                            functionDef: function (itemId) {
                                $scope.selectedItemId = itemId[2];
                                $scope.imprimirMovimiento = itemId[3].imprimirMovimiento;
                                $scope.tituloModal = $filter("translate")("CONFIRM_MOV");
                                $scope.mensajeModal = $filter("translate")("CONFIRM_MOV_DETAIL");
                                $scope.modalInstanceAnular = $modal.open({
                                    templateUrl: "views/directives/confirmation-modal.html",
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    $scope.modalInstanceAnular.dismiss("cancel");
                                };

                                $scope.ok = function (itemId) {

                                    MovimientoBancarioFactory.confirmarMovimento(itemId).then(
                                            function (response) {

                                              ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                                if (parametro.valorTipo.codigo == 'si') {
                                                    if(response.data.enviadoAContabilidad == false){
                                                        notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                    }
                                                }
                                                if(response.data.listaArchivosLogs != null){
                                                  if(response.data.listaArchivosLogs.length != 0){
                                                    /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                        console.log('response.listaArchivosLogs: ');
                                                        console.log(response.listaArchivosLogs[i]);
                                                        $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                    }*/
                                                    notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                  }
                                                }
                                              });

                                                if ($scope.imprimirMovimiento) {
                                                    var filtersArr = [];
                                                    filtersArr.push('idMovimiento=' + itemId);
                                                    $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('movimientobancariorep', 'pdf', filtersArr);
                                                    $window.open($scope.pdfDownloadURL, '_blank');
                                                }

                                                $scope.modalInstanceAnular.close(itemId);
                                                $state.go($state.current, {}, {reload: true});
                                            },
                                            function (error) {
                                                $scope.modalInstanceAnular.dismiss("cancel");
                                                $scope.tituloModal = $filter("translate")(
                                                        "CONFIRM_MOV_FAILED"
                                                        );
                                                $scope.mensajeModal = error.data[0].message
                                                var modalInstance = $modal.open({
                                                    template: '<div class="modal-header">' +
                                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                            "</div>" +
                                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                            '<div class="modal-footer">' +
                                                            '<button class="btn btn-primary" ng-click="cancel()">' +
                                                            $filter("translate")("OK") +
                                                            "</button>" +
                                                            "</div>",
                                                    scope: $scope
                                                });
                                                $scope.cancel = function () {
                                                    modalInstance.dismiss("cancel");
                                                };
                                            }
                                    );
                                };
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-danger btn-dt' title='" +
                                    $filter("translate")("DESCONFIRMAR") +
                                    "' style='margin-right: 5px;' ng-click='desconfirmar(<%=dataId%>)' ng-class='{hidden : !canDesconfirmar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-remove'></span> </button>",
                            customAttribute: ["confirmado", "isContrapartida", "desembolsoOrdenPago","cobro"],
                            conditionName: "canDesconfirmar",
                            conditionDef: function (param) {

                                var confirmado = param[0];
                                var isContrapartida = param[1];
                                var desembolso = param[2];
                                var cobro = param[3];

                                if ($scope.isProcesoCobro) {
                                    return (
                                        AuthorizationService.hasPermission(
                                            "desconfirmar_depositobancario"
                                        ) && confirmado == "Sí" &&
                                        ProcesoCobroFactory.getProcesoCobro().estado.codigo !=
                                        "estado_procesocobro_cerrado" && !vm.soloLectura
                                    );
                                }
                                return AuthorizationService.hasPermission(
                                            "desconfirmar_movimientobancario"
                                        ) && cobro == null && confirmado == "Sí" && !desembolso && isContrapartida != "Sí";
                            },
                            functionName: "desconfirmar",
                            functionDef: function (itemId) {

                                MovimientoBancarioFactory.get(
                                        itemId,
                                        "MovimientoBancarioForm"
                                        ).$promise.then(function (result) {
                                    $scope.selectedItemId = itemId;
                                    $scope.tituloModal = $filter("translate")("DESCONFIRM_MOV");
                                    var monto = 0;
                                    if (result.salida > 0) {
                                        monto = result.salida;
                                    } else {
                                        monto = result.entrada;
                                    }
                                    var montoFormateado = monto.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    var descrip = "";
                                    if(result.descripcion !== null && result.descripcion !== undefined){
                                        descrip = result.descripcion;
                                    }
                                    var siguienteMensaje = result.numero + " | " + result.tipoMovimiento.nombre + " | " + descrip  + " | " + result.cuenta.numeroCuenta + "-"+ result.cuenta.nombreCuenta +" | " + moment(result.fecha).format("DD/MM/YYYY") + " | Gs. " + montoFormateado;
                                    $scope.mensajeModal = $filter("translate")("DESCONFIRM_MOV_DETAIL") + " Movimiento: " + siguienteMensaje;
                                    //$scope.tituloModal = "titulo desaprobar";
                                    //$scope.mensajeModal = "desaprobar";
                                    $scope.modalInstanceAnular = $modal.open({
                                        templateUrl: "views/directives/confirmation-modal.html",
                                        scope: $scope
                                    });

                                    $scope.cancel = function () {
                                        $scope.modalInstanceAnular.dismiss("cancel");
                                    };

                                    $scope.ok = function (itemId) {
                                        MovimientoBancarioFactory.desconfirmarMovimento(itemId).then(
                                                function () {
                                                    $scope.modalInstanceAnular.close(itemId);
                                                    $state.go($state.current, {}, {reload: true});
                                                },
                                                function (error) {
                                                    $scope.modalInstanceAnular.dismiss("cancel");
                                                    $scope.tituloModal = $filter("translate")(
                                                            "CONFIRM_MOV_FAILED"
                                                            );
                                                    $scope.mensajeModal = error.data[0].message
                                                    //$scope.mensajeModal = $filter("translate")(
                                                      //      "CONFIRM_MOV_FAILED_DETAIL"
                                                        //    );
                                                    var modalInstance = $modal.open({
                                                        template: '<div class="modal-header">' +
                                                                '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                                "</div>" +
                                                                '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                                '<div class="modal-footer">' +
                                                                '<button class="btn btn-primary" ng-click="cancel()">' +
                                                                $filter("translate")("OK") +
                                                                "</button>" +
                                                                "</div>",
                                                        scope: $scope
                                                    });
                                                    $scope.cancel = function () {
                                                        modalInstance.dismiss("cancel");
                                                    };
                                                }
                                        );
                                    };
                                });
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                                    $filter("translate")("DELETE") +
                                    "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                            customAttribute: ["confirmado", "isContrapartida", "cobro"],
                            conditionName: "canBorrar",
                            conditionDef: function (item) {
                                if ($scope.isProcesoCobro) {
                                    return (
                                            AuthorizationService.hasPermission(
                                                    "delete_movimientobancario"
                                                    ) &&
                                            item[0] === "No" &&
                                            ProcesoCobroFactory.getProcesoCobro().estado.codigo !=
                                            "estado_procesocobro_cerrado" && !vm.soloLectura
                                            ) ;
                                }
                                return (
                                        AuthorizationService.hasPermission(
                                                "delete_movimientobancario"
                                                ) && item[0] === "No" && item[1] == "No" && item[2] === null
                                        );
                            },
                            functionName: "borrar",
                            functionDef: function (itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.tituloModal = "Confirmación de Borrado";
                                $scope.mensajeModal = '';

                                MovimientoBancarioFactory.get(
                                        itemId,
                                        "MovimientoBancarioForm"
                                        ).$promise.then(function (data) {
                                    if (
                                            data.contrapartida === undefined ||
                                            data.contrapartida === null
                                            ) {
                                                var descrip = "";
                                    if(data.descripcion !== null && data.descripcion !== undefined){
                                        descrip = data.descripcion;
                                    }

                                    var montoFormateado
                                    if(data.entrada>0){
                                        montoFormateado = data.entrada.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    }else if(data.salida > 0){
                                        montoFormateado = data.salida.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    }


                                        $scope.mensajeModal = $filter("translate")("ELIMINAR_MOV") + " Movimiento: "
                                        + data.numero + " | " + data.tipoMovimiento.nombre + " | " + descrip  + " | " + data.cuenta.numeroCuenta + "-" +data.cuenta.nombreCuenta+ " | " + moment(data.fecha).format("DD/MM/YYYY") + " | Gs. " + montoFormateado + "?";
                                    } else {
                                        $scope.mensajeModal = $filter("translate")(
                                                "DELETE_WARNING_MOV_CONTRAPARTIDA"
                                                );
                                        $scope.movimientoEliminar1 =
                                                $filter("translate")("NUMERO_MOVIMIENTO") + " " +
                                                data.id;

                                        $scope.movimientoEliminar2 =
                                                $filter("translate")("NUMERO_CONTRAPARTIDA") + " " +
                                                data.contrapartida;
                                    }

                                    $scope.modalInstanceBorrar1 = $modal.open({
                                        template: '<div class="modal-header">' +
                                                '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                "</div>" +
                                                '<div class="modal-body">{{::mensajeModal}}' +
                                                '<hr width="1%">' +
                                                "{{::movimientoEliminar1}}" + "<br>" +
                                                "{{::movimientoEliminar2}}" +
                                                "</div>" +
                                                //'<div class="modal-body">{{::usuarioEliminar}}</div>'+
                                                '<div class="modal-footer">' +
                                                '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' +
                                                $filter("translate")("OK") +
                                                "</button>" +
                                                '<button class="btn btn-warning" ng-click="cancel()">' +
                                                $filter("translate")("CANCEL") +
                                                "</button>" +
                                                "</div>",
                                        scope: $scope
                                    });

                                    $scope.cancel = function () {
                                        $scope.modalInstanceBorrar1.dismiss("cancel");
                                    };

                                    $scope.ok = function (itemId) {
                                        var model = MovimientoBancarioFactory.create({id: itemId});
                                        MovimientoBancarioFactory.remove(model).then(
                                                function () {
                                                    $scope.modalInstanceBorrar1.close(itemId);
                                                    $state.go($state.current, {}, {reload: true});
                                                },
                                                function (error) {
                                                    $scope.modalInstanceBorrar1.dismiss("cancel");
                                                    $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                                    if (error.data && error.data.length > 0 && error.data[0].message) {
                                                        $scope.mensajeModal = error.data[0].message;
                                                    }
                                                    var modalInstance = $modal.open({
                                                        template: '<div class="modal-header">' +
                                                                '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                                "</div>" +
                                                                '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                                '<div class="modal-footer">' +
                                                                '<button class="btn btn-primary" ng-click="cancel()">' +
                                                                $filter("translate")("OK") +
                                                                "</button>" +
                                                                "</div>",
                                                        scope: $scope
                                                    });
                                                    $scope.cancel = function () {
                                                        modalInstance.dismiss("cancel");
                                                    };
                                                    console.log("error al borrar: ");
                                                    console.log(error);
                                                }
                                        );
                                    };
                                });
                            }
                        },
                        {
                            templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
                            functionName: 'reenviar',
                            customAttribute: ['confirmado', 'enviadoAContabilidad', 'id'],
                            functionDef: function(atributo) {

                                var idMovimiento = atributo[2];

                                MovimientoBancarioFactory.get(idMovimiento, "MovimientoBancarioList").$promise.then(function (
                                    response
                                    ) {
                                    $scope.tituloModal = $filter('translate')('REENVIAR_MOV_BANCARIO_Q');
                                    $scope.mensajeModal = $filter('translate')('REENVIAR_MOV_BANCARIO_MSG');
                                    var modalInstance = $modal.open({
                                        templateUrl: 'views/directives/confirmation-modal.html',
                                        scope: $scope
                                    });

                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };

                                    $scope.ok = function(id) {
                                        MovimientoBancarioFactory.reenviar(response.id).then(function (response) {
                                            ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                                if (parametro.valorTipo.codigo == 'si') {
                                                    if(response.enviadoAContabilidad == false){
                                                        notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                    }
                                                }
                                            });
                                            $state.go($state.current, {}, {reload: true});
                                        }, function(error) {
                                            var msg = "";
                                            if (error.data && error.data.length > 0 && error.data[0].message) {
                                                msg = error.data[0].message;
                                            } else {
                                                msg = $filter('translate')('REENVIAR_MOV_BANCARIO_FAILED');
                                            }

                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        });
                                        modalInstance.close();
                                    }
                                });
                            },
                            conditionName: 'canReenviar',
                            conditionDef: function(atributo) {
                                var enviadoAContabilidad = atributo[1];
                                if(enviarAContabilidad == 'si'){
                                    if(enviadoAContabilidad === "No"){
                                        return true;
                                    }
                                }
                            }
                        }
                    ]
                };
                if ($scope.isProcesoCobro) {
                    $scope.options.staticFilter = $scope.staticFilter;
                    $scope.options.hideEditMenu = true;
                    $scope.options.hideViewMenu = true;
                    $scope.options.hideAddMenu = true;

                    $scope.options.extraMenuOptions[0] = {};

                    $scope.options.extraRowOptions.push({
                        templateToRender: "<button class='btn btn-success btn-dt' style='margin-right: 5px;' title='" +
                                $filter("translate")("EDIT") +
                                "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: "confirmado",
                        conditionName: "canEditar",
                        conditionDef: function (confirmado) {
                            return false;//(
                                    //AuthorizationService.hasPermission("update_movimientobancario") &&
                                    //confirmado === "No" &&
                                    //ProcesoCobroFactory.getProcesoCobro().estado.codigo !=
                                    //"estado_procesocobro_cerrado" && !vm.soloLectura
                                    //);
                        },
                        functionName: "editar",
                        functionDef: function (itemId) {
                            $state.go("app.procesoscobro.proceso.depositocobranza.edit", {
                                id: itemId
                            });
                        }
                    });
                    $scope.options.extraRowOptions.push({
                        templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" +
                                $filter("translate")("VIEW") +
                                "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer(<%=dataId%>)}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        conditionName: "canVer",
                        conditionDef: function (confirmado) {
                            return true;
                        },
                        functionName: "ver",
                        functionDef: function (itemId) {
                            if (vm.soloLectura){
                                $state.go("app.procesoscobro.verproceso.depositocobranza.view", {
                                    id: itemId
                                });
                            }else{
                                $state.go("app.procesoscobro.proceso.depositocobranza.view", {
                                    id: itemId
                                });
                            }
                        }
                    });
                    $scope.options.extraMenuOptions.push({
                        title: $filter("translate")("NEW"),
                        icon: "glyphicon glyphicon-plus",
                        showCondition: function () {
                            var permission =
                                    AuthorizationService.hasPermission("create_movimientobancario") &&
                                    ProcesoCobroFactory.getProcesoCobro().estado.codigo !=
                                    "estado_procesocobro_cerrado";
                            return permission && !vm.soloLectura;
                        },
                        action: function () {
                            checkPeriodoAdministrativo(null).then(function (resultado) {
                                if (resultado == true) {
                                    $state.go("app.procesoscobro.proceso.depositocobranza.new");
                                } else if (resultado == false) {
                                    var msg = $filter('translate')('NO_OPEN_PERIODS_NOW');
                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                }
                            });

                        }
                    });
                    console.log("Se cambia staticFilter");
                }

                //$scope.boolean=true;
                var createFilters = function (filters,staticFilters) {
                    var filtersArr = [];
                    var filtersC = _.cloneDeep(filters);
                    var staticFiltersC = _.cloneDeep(staticFilters);
                    _.each(filters, function (search, data) {
                        search = search.split("~");
                        if (search.length > 1) {
                            /* El parámetro es un rago */

                            var isDate = search[0].split("/");

                            if (isDate.length > 1) {
                                /* Es un rago de fechas */

                                filtersArr.push({path: data, equalOrAfter: search[0]})
                                filtersArr.push({path: data, equalOrBefore: search[1]})
                            } else {
                                /* El un rago numérco */

                                filtersArr.push({path: data, greaterOrEqual: search[0]})
                                filtersArr.push({path: data, lessOrEqual: search[1]})
                            }
                        } else {
                            /* El parametro no es un rago */
                            if(search[0] === 'No' && data === 'confirmado'){
                                search[0] = 'false';
                            }
                            if(search[0] === 'Sí' && data === 'confirmado'){
                                search[0] = 'true';
                            }

                            filtersArr.push({path: data, like: search[0]})
                        }
                    });
                    if (staticFiltersC) {
                        // entrar al OR y por cada AND agregar todos los filtros de datatable
                        var entro = false;
                        var staticFiltersAnds = staticFiltersC.search.filters;
                        _.each(staticFiltersAnds, function(staticFilter) {
                            if (staticFilter.filters) {
                                _.each(filtersArr, function(dtFilter) {
                                    staticFilter.filters.push(dtFilter);
                                });
                            } else {
                                if (staticFilter.equals != undefined) {
                                    filtersArr.push({ path: staticFilter.path, equals: staticFilter.equals });
                                } else if (staticFilter.like != undefined) {
                                    filtersArr.push({ path: staticFilter.path, like: staticFilter.like });
                                } else if (staticFilter.equalOrBefore != undefined) {
                                    filtersArr.push({ path: staticFilter.path, equalOrBefore: staticFilter.equalOrBefore });
                                } else if (staticFilter.equalOrAfter != undefined) {
                                    filtersArr.push({ path: staticFilter.path, equalOrAfter: staticFilter.equalOrAfter });
                                } else if (staticFilter.greaterThan != undefined) {
                                    filtersArr.push({ path: staticFilter.path, greaterThan: staticFilter.greaterThan });
                                } else if (staticFilter.lessThan != undefined) {
                                    filtersArr.push({ path: staticFilter.path, lessThan: staticFilter.lessThan });
                                } else if (staticFilter.sortDesc != undefined) {
                                    filtersArr.push({ path: staticFilter.path, sortDesc: staticFilter.sortDesc });
                                } else if (staticFilter.sortAsc != undefined) {
                                    filtersArr.push({ path: staticFilter.path, sortAsc: staticFilter.sortAsc });
                                } else if (staticFilter.isNull != undefined) {
                                    filtersArr.push({ path: staticFilter.path, isNull: staticFilter.isNull });
                                } else if (staticFilter.isNotNull != undefined) {
                                    filtersArr.push({ path: staticFilter.path, isNotNull: staticFilter.isNotNull });
                                } else if (staticFilter.notEquals != undefined) {
                                    filtersArr.push({ path: staticFilter.path, notEquals: staticFilter.notEquals });
                                } else if (staticFilter.greaterOrEqual != undefined) {
                                    filtersArr.push({ path: staticFilter.path, greaterOrEqual: staticFilter.greaterOrEqual });
                                } else if (staticFilter.lessOrEqual != undefined) {
                                    filtersArr.push({ path: staticFilter.path, lessOrEqual: staticFilter.lessOrEqual });
                                }
                                entro = true;
                            }
                        });
                        if (entro == true) {
                            filtersC = filterFactory.and(filtersArr).value();
                        } else {
                            filtersC = filterFactory.or(staticFiltersAnds).value();
                        }
                    } else {
                        filtersC = filterFactory.and(filtersArr).value();
                    }
                    return filtersC;
                }


                /* Si recibe fechaParam=null verifica la fecha actual */
                var checkPeriodoAdministrativo = function (fechaParam) {
                    return $q(function (resolve, reject) {

                        var params = {};
                        var fecha;

                        if (fechaParam == null) {
                            fecha = new Date();
                        } else {
                            fecha = fechaParam;
                        }

                        params.search = filterFactory
                                .and([{
                                        path: "estado.codigo",
                                        equals: "per_adm_abierto"
                                    },
                                    {
                                        path: "fechaDesde",
                                        equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                                    },
                                    {
                                        path: "fechaHasta",
                                        equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                                    }
                                ])
                                .value();

                        PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {
                            if (responsePeriodo.length > 0) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        })
                    });
                }
            });
        }]);

'use strict';


angular.module('qualita.tesoreria')
  .controller('MovimientoBancarioConsultaCtrl', ["$scope", "MovimientoBancarioFactory", "filterFactory", "ReportTicketFactory", "$window", "TesoreriaLangFactory", "$modal", "$state", "cuentaBancariaFactory", "notify", "UtilFactory", "$filter", "CsvFactory", "baseurl", "AuthorizationService", function ($scope, MovimientoBancarioFactory, filterFactory, ReportTicketFactory,
    $window, TesoreriaLangFactory, $modal, $state, cuentaBancariaFactory, notify, UtilFactory, $filter,
    CsvFactory, baseurl, AuthorizationService) {

    TesoreriaLangFactory.getTranslations().then(function(translations){

      //Verificacion de permiso para recalculo
      $scope.recalculoPermission = AuthorizationService.hasPermission("recalcular_movimientobancario");

      $scope.searchCuentaBancariaFilter = function(criteria) {
      return function(item) {
        if (!criteria) {
          return true;
        }

        return item.nombreCuenta.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
          item.numeroCuenta.toLowerCase().indexOf(criteria.toLowerCase()) > -1;
      };
    }

    var emptyDataRender = function (data) {
        //console.log(data);
        if (data == undefined)
          return "";
        else
          return data;
    };

     var importeRender  = function ( data, type, row ) {
      if (data) {
        var moneda = "Gs. ";
        if (row.cuenta.moneda.codigo==='dolares') {
          moneda = "Usd. ";
          data = parseFloat(data).toFixed(2);
          $scope.saldoInicial= parseFloat($scope.saldoInicial).toFixed(2);
        } else if(row.cuenta.moneda.codigo==='pesos') {
          moneda = "Pes. ";
          data = parseFloat(data).toFixed(2);
          $scope.saldoInicial= parseFloat($scope.saldoInicial).toFixed(2);
        } else if (row.cuenta.moneda.codigo==='real') {
          moneda = "Rel. ";
          data = parseFloat(data).toFixed(2);
          $scope.saldoInicial= parseFloat($scope.saldoInicial).toFixed(2);
        } else if (row.cuenta.moneda.codigo==='euro') {
          moneda = "Eur. ";
          data = parseFloat(data).toFixed(2);
          $scope.saldoInicial= parseFloat($scope.saldoInicial).toFixed(2);
        } else {
          data = parseFloat(data).toFixed(0);
          $scope.saldoInicial= parseFloat($scope.saldoInicial).toFixed(0);
        }
        // TODO hacer esto mismo en todos los renders de moneda
        //$scope.saldoInicial=moneda+$scope.saldoInicial.toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return moneda + data.toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return '0';
      }
    };
    var staticFilter = {};
    var filtersPdf = [];
      var defaultColumnOrder = ['id', 'orden', 'cuenta.nombreCuenta', 'cuentaTransaccion.nombreCuenta', 'numero', 'tipoMovimiento.nombre', 'descripcion', 'ordenesPagoString',
      'cheque.fechaPago', 'concepto','entrada', 'salida','fecha', 'saldo', 'confirmado', 'cheque.fecha_emision', 'cobro.numeroCobro'];
      $scope.options = {
        'resource': 'movimientobancario',
        'title': $filter('translate')('BANK_MOVEMENTS'),
        'factory': MovimientoBancarioFactory,
        'view': 'ConsultaMovimientoBancarioList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('FAIL_DELET_ENTIDAD'),
        'columns': [
          {'data':'id', 'title':$filter('translate')('ID'), visible: false, 'searchable': false, 'orderable': false },
          {'data':'orden', 'title':$filter('translate')('ORDEN'), visible: false, 'searchable': false, 'orderable': false },
          {'data':'numero', 'title':$filter('translate')('NUMBER'), 'class':'dt-right', 'searchable': false, 'orderable': false },
          {'data':'cuenta.nombreCuenta', 'title':$filter('translate')('ORIGIN_ACCOUNT_NAME'), 'searchable': false, 'orderable': false },
          {'data':'cuentaTransaccion.nombreCuenta', 'title':$filter('translate')('DESTINY_ACCOUNT_NAME'), 'render': emptyDataRender, 'searchable': false, 'orderable': false },
          {'data':'tipoMovimiento.nombre', 'title':$filter('translate')('MOVEMENT_TYPE'), 'searchable': false, 'orderable': false },
          {'data':'descripcion', 'title':$filter('translate')('DESCRIPTION_ACCOUNT_MOV'), 'searchable': false, 'orderable': false },
          {'data':'ordenesPagoString', 'title':$filter('translate')('PAYMENTS_ORDERS'), 'searchable': false, 'orderable': false },
          {'data':'fecha', 'title':$filter('translate')('FECHA_MOVIMIENTO'),'class' : 'dt-right','renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'searchable': false, 'orderable': false},
          {'data':'cheque.fecha_emision', 'title':$filter('translate')('FECHA_EMISION'),'class' : 'dt-right','renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', orderable: false, searchable: false},
          {'data':'cheque.fechaPago', 'title':$filter('translate')('DATE_DIF'),'class' : 'dt-right','renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'searchable': false, 'orderable': false },
          {'data':'concepto', 'title':$filter('translate')('CHECK_CONCEPT'), 'render': emptyDataRender, 'searchable': false, 'orderable': false },
          {'data':'salida', 'title':$filter('translate')('SALIDA'), 'render': importeRender,'class' : 'dt-right','searchable': false, 'orderable': false },
          {'data':'entrada', 'title':$filter('translate')('ENTRADA'), 'render': importeRender,'class' : 'dt-right', 'searchable': false, 'orderable': false },
          {'data':'saldo', 'title':$filter('translate')('SALDO'), 'render': importeRender,'class' : 'dt-right', 'searchable': false, 'orderable': false },
          {'data':'confirmado', 'title':$filter('translate')('CONFIRMED'), 'class': 'dt-center', 'searchable': false, 'orderable': false },
          {'data': 'cobro.numeroCobro', 'title': $filter('translate')('CODIGO_COBRO'), 'renderWith': 'emptyRender', 'searchable': false, 'orderable': false }
        ],
        'hasOptions' : false,
        'hideAddMenu': true,
        'defaultOrderColumn': 1,
        'defaultOrderDir': "asc",
        'extraMenuOptions':
          [
            {
              title: "Reporte",
              icon: "glyphicon glyphicon-file",
              showCondition: function() {
                  return true;
              },
              action: function() {
                var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("librobanco", 'pdf', $scope.options.filtersPdf);
                $window.open(pdfDownloadURL, '_blank');
              }
          },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("movimientobancario", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ]
      };

      //$scope.boolean=true;
      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({path: data, like: search})
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }

      $scope.saldoInicial=0;
      $scope.cuenta=undefined;
      $scope.fechaDesde=undefined;
      $scope.fechaHasta=undefined;
      $scope.cuentas=cuentaBancariaFactory.all();

      $scope.cambiarFiltro=function(){
        $scope.submited = true;
        if ($scope.FiltrosForm.$valid) {
          if($scope.fechaDesde!=undefined && $scope.fechaHasta!=undefined && $scope.fechaDesde>$scope.fechaHasta){
            notify({ message: "La fecha desde de búsqueda no puede ser mayor a la fecha hasta.", classes: 'alert-danger', position: 'right'});
          }else{
            if($scope.cuenta==undefined) {
              notify({ message: "El filtro cuenta es obligatorio.", classes: 'alert-danger', position: 'right'});
            } else {
              if($scope.fechaDesde==null){
                $scope.fechaDesde=undefined;
                if($scope.fechaHasta==null){
                  $scope.fechaHasta=undefined;
                  $scope.filtro = true;
                  var staticFilter = {}
                  var filtersPdf = []

                  filtersPdf.push("idcuenta=" + $scope.cuenta.id);
                  staticFilter.search =filterFactory.and([
                    {
                      path: 'cuenta.id',
                      equals: $scope.cuenta.id
                    },
                    {
                      path: 'orden',
                      sortAsc: 'true'
                    }
                  ]).value();
                }else{
                  $scope.filtro = true;
                  var staticFilter = {}
                  var filtersPdf = []

                  filtersPdf.push("idcuenta=" + $scope.cuenta.id);
                  filtersPdf.push("fechahasta=" + moment.utc(moment($scope.fechaHasta).toDate()).format('MM/DD/YYYY'));
                  staticFilter.search =filterFactory.and([
                    {
                      path: 'cuenta.id',
                      equals: $scope.cuenta.id
                    },
                    {
                      path: 'fecha',
                      equalOrBefore: moment.utc(
                        moment($scope.fechaHasta).set({ hour: 23, minute: 59 }).toDate()
                        ).format('DD/MM/YYYY')
                    },
                    {
                      path: 'orden',
                      sortAsc: 'true'
                    }
                  ]).value();
                }
              }else if($scope.fechaHasta==null){
                $scope.fechaHasta=undefined;
                $scope.filtro = true;
                var staticFilter = {}
                var filtersPdf = []

                filtersPdf.push("idcuenta=" + $scope.cuenta.id);
                filtersPdf.push("fechadesde=" + moment.utc(moment($scope.fechaDesde).toDate()).format('MM/DD/YYYY'));
                staticFilter.search =filterFactory.and([
                  {
                    path: 'cuenta.id',
                    equals: $scope.cuenta.id
                  },
                  {
                    path: 'fecha',
                    equalOrAfter: moment.utc($scope.fechaDesde).format('DD/MM/YYYY')
                  },
                  {
                    path: 'orden',
                    sortAsc: 'true'
                  }
                ]).value();
              }else{
                $scope.filtro = true;
                var staticFilter = {}
                var filtersPdf = []

                filtersPdf.push("idcuenta=" + $scope.cuenta.id);
                filtersPdf.push("fechadesde=" + moment.utc(moment($scope.fechaDesde).toDate()).format('MM/DD/YYYY'));
                filtersPdf.push("fechahasta=" + moment.utc(moment($scope.fechaHasta).toDate()).format('MM/DD/YYYY'));
                staticFilter.search =filterFactory.and([
                  {
                    path: 'cuenta.id',
                    equals: $scope.cuenta.id
                  },
                  {
                    path: 'fecha',
                    equalOrAfter: moment.utc($scope.fechaDesde).format('DD/MM/YYYY')
                  },{
                    path: 'fecha',
                    equalOrBefore: moment.utc($scope.fechaHasta).format('DD/MM/YYYY')
                  },
                  {
                    path: 'orden',
                    sortAsc: 'true'
                  }
                ]).value();
              }

              $scope.options.staticFilter=staticFilter;
              //$scope.options.filtersPdf=filtersPdf;

            if($scope.fechaDesde!=undefined){
              MovimientoBancarioFactory.getSaldoInicial($scope.cuenta.id,moment.utc($scope.fechaDesde).format('DD/MM/YYYY')).then(function(resp) {
              $scope.saldoInicial=resp.data;

              filtersPdf.push("saldoInicial=" +  $scope.saldoInicial);
              $scope.options.filtersPdf=filtersPdf;

              if($scope.saldoInicial===undefined) {
                $scope.saldoInicial=0;}
              }

              );
            }else{
              $scope.saldoInicial=0;
            }
          }
          }
        }
    };

    $scope.limpiarFiltros = function() {
      $scope.saldoInicial = 0;
      $scope.cuenta = undefined;
      $scope.fechaDesde = undefined;
      $scope.fechaHasta = undefined;
    }

    $scope.recalcularSaldos = function () {

      $scope.mensajeModal = $filter('translate')('WARNING_RECALCULO_SALDO');
      $scope.tituloModal = $filter('translate')('WARNING_HEADER_RECALCULO');
      var modalInstance = $modal.open({
          templateUrl: "views/directives/confirmation-modal.html",
          scope: $scope
      });

      $scope.cancel = function() {
          modalInstance.dismiss("cancel");
      };

      $scope.ok = function() {
          if ($scope.cuenta) {

              $scope.mensajeModal = $filter('translate')('PROCESS');
              var modalInstance1 = $modal.open({
                  templateUrl: "views/directives/information-modal.html",
                  scope: $scope
              });

              MovimientoBancarioFactory.recalcularSaldo($scope.cuenta.id).then(function(){
                  // success
                  modalInstance1.close();

                  $scope.mensajeModal = "Proceso finalizado con éxito";
                  $scope.ok = function() {
                      modalInstance.close();
                  }

                  var modalInstance = $modal.open({
                      templateUrl: "views/directives/information-modal.html",
                      scope: $scope
                  });

              }, function (errorMessage) {

                  //error
                  modalInstance1.close();

                  $scope.mensajeModal = "ERROR al recalcular saldo, " + errorMessage.data.error;
                  $scope.ok = function() {
                      modalInstance.close();
                  }

                  var modalInstance = $modal.open({
                      templateUrl: "views/directives/information-modal.html",
                      scope: $scope
                  });
              })
              return
          }

          var msg = $filter('translate')('ERROR_FILTER_CUENTA');
          notify({message: msg, classes: 'alert-danger', position: 'right' });
      }
  }

  })
}]);

'use strict';


angular.module('qualita.tesoreria')
  .controller('TipoOrdenPagoIndexCtrl', ["$scope", "TipoOrdenPagoFactory", "filterFactory", "ReportTicketFactory", "$window", "TesoreriaLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", function ($scope, TipoOrdenPagoFactory, filterFactory, ReportTicketFactory, $window, TesoreriaLangFactory,
    $filter, UtilFactory, CsvFactory, baseurl) {
    TesoreriaLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'nombre', 'clase.descripcion', 'activo'];
      $scope.options = {
        'resource': 'tipoordenpago',
        'title': $filter('translate')('TYPES_PAY_ORDER'),
        'factory': TipoOrdenPagoFactory,
        'view': 'TipoOrdenPagoList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('FAIL_DELET_TYPE_PAY_ORDER'),
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('ID'), visible: false  },
          { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
          { 'data': 'clase.descripcion', 'title': $filter('translate')('CLASS') },
          { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'extraMenuOptions': [
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

              CsvFactory.csv("tipoordenpago", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          },
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = createFilters($scope.options.getFilters());
              ReportTicketFactory.ticket('listadoTipoOrdenPago', filters, $scope.options.tableAjaxParams,
                $scope.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
            }
          }

        ]


      };
      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          if(search === 'No' && data === 'activo'){
            search = 'false';
        }
        if(search === 'Sí' && data === 'activo'){
            search = 'true';
        }
          filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
    });
  }]);

'use strict';


angular.module('qualita.tesoreria')
  .controller('PlanillaFondoFijoIndexCtrl', ["$scope", "PlanillaFondoFijoFactory", "filterFactory", "ReportTicketFactory", "$window", "TesoreriaLangFactory", "$modal", "$state", "WatchLocacionChangeFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "PeriodoAdministrativoFactory", "ParametrosFactory", "notify", "$q", function ($scope, PlanillaFondoFijoFactory, filterFactory,
    ReportTicketFactory, $window, TesoreriaLangFactory,
    $modal, $state, WatchLocacionChangeFactory, $filter, UtilFactory, CsvFactory, baseurl, AuthorizationService,
    PeriodoAdministrativoFactory, ParametrosFactory, notify, $q) {

    TesoreriaLangFactory.getTranslations().then(function (translations) {

      var monedaRender = function (data, type, row) {
        if (data) {
          var moneda = "Gs. ";
          if (row.moneda.codigo === 'dolares') {
            moneda = "Usd. ";
            data = parseFloat(data).toFixed(2);
          } else if (row.moneda.codigo === 'pesos') {
            moneda = "Pes. ";
            data = parseFloat(data).toFixed(2);
          } else if (row.moneda.codigo === 'real') {
            moneda = "Rel. ";
            data = parseFloat(data).toFixed(2);
          } else if (row.moneda.codigo === 'euro') {
            moneda = "Eur. ";
            data = parseFloat(data).toFixed(2);
          }
          // TODO hacer esto mismo en todos los renders de moneda
          return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
          return '';
        }
      };

      var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
      var enviarAContabilidad = '';

      promise1.then(function (response) {
        enviarAContabilidad = response.valorTipo.codigo;
      });

      var defaultColumnOrder = ['id', 'numero', 'fecha', 'tipoFondoFijo.nombre', 'importe', 'estado.descripcion', 'sucursal.nombre', 'comentario'
      ];
      $scope.options = {
        'resource': 'planillafondofijo',
        'title': $filter('translate')('PLANILLAS_FONDO_FIJO'),
        'factory': PlanillaFondoFijoFactory,
        'view': "PlanillaFondoFijoList",
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('FAIL_DELETE_PLANILLA_FONDO_FIJO'),
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('ID'), visible: false },
          { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-right' },
          { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', dateFormat: 'DD/MM/YYYY', class: 'dt-center', 'type': 'date-range' },
          { 'data': 'tipoFondoFijo.nombre', 'title': $filter('translate')('TIPO_FONDO_FIJO') },
          { 'data': 'importe', 'title': $filter('translate')('MONTO'), 'class': 'dt-right', 'renderWith': monedaRender },
          { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'renderWith': 'emptyRender', 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/estados_planilla_fondo_fijo' },
          { 'data': 'sucursal.nombre', 'title': $filter('translate')('SUCURSAL'), 'renderWith': 'emptyRender', 'class': 'dt-center' },
          { 'data': 'comentario', 'title': $filter('translate')('COMMENT'), 'renderWith': 'emptyRender', 'class': 'dt-center' }
        ],
        'hasOptions': true,
        'hideAddMenu': true,
        'hideRemoveMenu': true,
        'defaultOrderColumn': 1,
        'defaultOrderDir': "desc",
        'extraMenuOptions': [
          {
            title: translations.NEW_VENTA,
            icon: "glyphicon glyphicon-plus",
            showCondition: function () {
              var permission = AuthorizationService.hasPermission("create_planillafondofijo");
              return permission;
              //return true;
            },
            action: function () {
              checkPeriodoAdministrativo(null).then(function (resultado) {
                if (resultado == true) {
                  $scope.addNewVenta();
                } else if (resultado == false) {
                  var msg = $filter('translate')('NO_OPEN_PERIODS_NOW');
                  notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }
              });
            }
          },
          {
            'title': 'Reporte Resumen de Fondos Fijos',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function () { return true; },
            'action': function () {
              /*var filters = createFilters($scope.options.getFilters());
              ReportTicketFactory.ticket('listadoPlanillaFondoFijo', filters, $scope.options.tableAjaxParams,
                $scope.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });*/
              $scope.tituloModal = "Reporte Resumen de Fondos Fijos";
              $scope.mensajeModal = "";
              $scope.config = "CON_DETALLE";
              var modalInstance = $modal.open({
                templateUrl: 'qualita-components/tesoreria/views/planillafondofijo/confirmation-modal-resumen.html',
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              $scope.ok = function (id, config, fechaDesde, fechaHasta) {
                var filtersArr = [];
                if(fechaDesde != undefined){
                  filtersArr.push("fechaDesde=" + $filter('date')(fechaDesde, "MM/dd/yyyy"));
                }else{
                  filtersArr.push("fechaDesde=" + null);
                }
                if(fechaHasta != undefined){
                  filtersArr.push("fechaHasta=" + $filter('date')(fechaHasta, "MM/dd/yyyy"));
                }else{
                  filtersArr.push("fechaHasta=" + null);
                }
                filtersArr.push("config=" + config);
                if (config == "CON_DETALLE") {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('fondofijodetalle', 'pdf', filtersArr);
                  $window.open($scope.pdfDownloadURL, '_blank');
                  $state.go($state.current, {}, { reload: true });
                } else {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('fondofijosindetalle', 'pdf', filtersArr);
                  $window.open($scope.pdfDownloadURL, '_blank');
                  $state.go($state.current, {}, { reload: true });
                }

                modalInstance.close();
              };
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

              CsvFactory.csv("planillafondofijo", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
        ],
        'extraEditConditions': function (item) {
          if (item) {
            if (item.estado.codigo === 'epf_confirmado' || item.estado.codigo === 'epf_aprobado' || item.estado.codigo === 'epf_anulado') {
              return false;
            } else {
              return true;
            }
          }
        },
        'extraRowOptions': [
          {
            templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataCustom%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
            customAttribute: ["estado", "id", "numero"],
            conditionName: "canBorrar",
            conditionDef: function (atributo) {
              var estado = atributo[0];
              var idtiem = atributo[1];
              if (estado.codigo === 'epf_ingresado') {
                return true;
              } else {
                return false;
              }
            },
            functionName: "borrar",
            functionDef: function (atributo) {
              var itemId = atributo[1];
              var numeroPlanilla = atributo[2];
              $scope.selectedItemId = atributo[1];
              $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
              $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              $scope.ok = function (id) {
                PlanillaFondoFijoFactory.getEstaAsentado(itemId).then(
                  function (response) {
                    console.log('respuesta');
                    console.log(response);
                    if (response.data == "true") {
                      var msg = $filter("translate")("ERROR_ESTA_ASENTADO_PLANILLA", { numero: numeroPlanilla });
                      notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    } else {
                      var model = PlanillaFondoFijoFactory.create({ id: itemId });
                      PlanillaFondoFijoFactory.remove(model).then(function () {
                        modalInstance.close(itemId);
                        $state.go($state.current, {}, { reload: true });
                      }, function (error) {
                        modalInstance.dismiss('cancel');
                        $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                        $scope.mensajeModal = error.data[0].message;

                        var modalInstance2 = $modal.open({
                          templateUrl: 'views/directives/information-modal.html',
                          scope: $scope
                        });
                        $scope.cancel = function () {
                          modalInstance2.dismiss('cancel');
                        };
                      });
                    }
                  }
                );
              }
            }
          },
          {
            templateToRender: "<button title='" + $filter('translate')('EMITIR_PLANILLA_FONDO_FIJO') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='emitir(<%=dataCustom%>)' ng-class='{hidden : !canEmitir(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-file'></span> </button>",
            functionName: "emitir",
            customAttribute: ["id", "estado"],
            functionDef: function (atributos) {

              var itemId = atributos[0];
              var itemEstado = atributos[1];

              $scope.tituloModal = $filter('translate')('EMITIR_PLANILLA_FONDO_FIJO');
              PlanillaFondoFijoFactory.get(itemId, 'PlanillaFondoFijoList').$promise.then(function (data) {
                $scope.mensajeModal = $filter('translate')('EMITIR_PLANILLA_MENSAJE') + itemId + " | " + moment(new Date(data.fecha)).format("DD/MM/YYYY") + " | " + data.tipoFondoFijo.nombre;

              });
              $scope.config = "CON_DETALLE";
              var modalInstance = $modal.open({
                templateUrl: 'qualita-components/tesoreria/views/planillafondofijo/confirmation-modal.html',
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              $scope.ok = function (id, config) {

                // La aprobacion debe realizarse solo si tiene estado INGRESADO
                if(itemEstado.codigo == 'epf_ingresado'){

                  // Aprobacion de la planilla
                  PlanillaFondoFijoFactory.aprobar(itemId).then(function(response) {

                    // Generacion del reporte
                    var filtersArr = [];
                    if (config === 'CON_DETALLE') {
                      filtersArr = [];
                      filtersArr.push("numeroPlanilla=" + itemId);
                      modalInstance.close();
                      $state.go($state.current, {}, { reload: true });
                      var pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                        "planillafondofijodetallado",
                        "pdf",
                        filtersArr
                      );
                      $window.open(pdfDownloadURL, "_blank");
                      $state.go($state.current, {}, { reload: true });
                    } else {
                      filtersArr = [];
                      filtersArr.push("numeroPlanilla=" + itemId);
                      modalInstance.close();
                      $state.go($state.current, {}, { reload: true });
                      var pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                        "planillafondofijo",
                        "pdf",
                        filtersArr
                      );
                      $window.open(pdfDownloadURL, "_blank");
                      $state.go($state.current, {}, { reload: true });
                    }

                    $state.go($state.current, {}, { reload: true });
                  }, function(error) {
                      var msg = "";
                      if (error.data && error.data.length > 0 && error.data[0].message) {
                          msg = error.data[0].message;
                      } else {
                          msg = $filter('translate')('APROBAR_PLANILLA_FAILED');
                      }

                      notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  });
                }else{
                  // Aqui la planilla NO tiene estado INGRESADO

                  // Generacion del reporte
                  var filtersArr = [];
                  if (config === 'CON_DETALLE') {
                    filtersArr = [];
                    filtersArr.push("numeroPlanilla=" + itemId);
                    modalInstance.close();
                    $state.go($state.current, {}, { reload: true });
                    var pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                      "planillafondofijodetallado",
                      "pdf",
                      filtersArr
                    );
                    $window.open(pdfDownloadURL, "_blank");
                    $state.go($state.current, {}, { reload: true });
                  } else {
                    filtersArr = [];
                    filtersArr.push("numeroPlanilla=" + itemId);
                    modalInstance.close();
                    $state.go($state.current, {}, { reload: true });
                    var pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                      "planillafondofijo",
                      "pdf",
                      filtersArr
                    );
                    $window.open(pdfDownloadURL, "_blank");
                    $state.go($state.current, {}, { reload: true });
                  }
                }
              };
            },
            conditionName: 'canEmitir',
            conditionDef: function(atributos) {
                var itemEstado = atributos[1];
                return AuthorizationService.hasPermission("update_planillafondofijo") && itemEstado.codigo != "epf_anulado";
            }
          },
          {
            templateToRender: "<button title='" + $filter('translate')('ANULAR_PLANILLA_FONDO_FIJO') + "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataId%>)' ng-class='{hidden : !canAnular(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-remove'></span> </button>",
            functionName: "anular",
            functionDef: function (itemId) {

              $scope.tituloModal = $filter('translate')('ANULAR_PLANILLA_FONDO_FIJO');
              $scope.mensajeModal = $filter('translate')('ANULAR_PLANILLA_FONDO_FIJO_MSG');

              var modalInstance = $modal.open({
                templateUrl: "views/directives/confirmation-modal.html",
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              $scope.ok = function (id, config) {
                PlanillaFondoFijoFactory.anular(itemId).then(function(response) {
                  $state.go($state.current, {}, { reload: true });
                }, function(error) {
                    var msg = "";
                    if (error.data && error.data.length > 0 && error.data[0].message) {
                        msg = error.data[0].message;
                    } else {
                        msg = $filter('translate')('ANULAR_PLANILLA_FAILED');
                    }

                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                });
                modalInstance.close();
                };
            },
            conditionName: 'canAnular',
            customAttribute: 'estado',
            conditionDef: function(estado) {
                return AuthorizationService.hasPermission("update_planillafondofijo") && estado.codigo == "epf_aprobado";
            }
          },
          {
            templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
            functionName: 'reenviar',
            customAttribute: ['estado', 'enviadoAContabilidad', 'id'],
            functionDef: function (atributo) {

              var idPlanilla = atributo[2];

              PlanillaFondoFijoFactory.get(idPlanilla, "PlanillaFondoFijoList").$promise.then(function (
                response
              ) {
                $scope.tituloModal = $filter('translate')('REENVIAR_PLANILLA_Q');
                $scope.mensajeModal = $filter('translate')('REENVIAR_PLANILLA_MSG');
                var modalInstance = $modal.open({
                  templateUrl: 'views/directives/confirmation-modal.html',
                  scope: $scope
                });

                $scope.cancel = function () {
                  modalInstance.dismiss('cancel');
                };

                $scope.ok = function (id) {
                  PlanillaFondoFijoFactory.reenviar(response.id).then(function (response) {
                    //ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                    if (enviarAContabilidad == 'si') {
                      if (response.data.enviadoAContabilidad == false) {
                        notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                      }
                      if (response.listaArchivosLogs != null) {
                        if (response.listaArchivosLogs.length != 0) {
                          /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                              console.log('response.listaArchivosLogs: ');
                              console.log(response.listaArchivosLogs[i]);
                              $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                          }*/
                          notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                        }
                      }
                    }
                    //});
                    $state.go($state.current, {}, { reload: true });
                  }, function (error) {
                    var msg = "";
                    if (error.data && error.data.length > 0 && error.data[0].message) {
                      msg = error.data[0].message;
                    } else {
                      msg = $filter('translate')('REENVIAR_PLANILLA_FAILED');
                    }

                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  });
                  modalInstance.close();
                }
              });
            },
            conditionName: 'canReenviar',
            conditionDef: function (atributo) {
              var estado = atributo[0];
              var enviadoAContabilidad = atributo[1];

              if (enviarAContabilidad == 'si') {
                if (estado.codigo === 'epf_confirmado' || estado.codigo === 'epf_aprobado') {
                  if (enviadoAContabilidad === "No") {
                    return true;
                  }
                }
              }
            }
          }
        ]
      };

      $scope.addNewVenta = function () {
        var params = {};
        params.search = filterFactory
          .and([{
            path: "estado.codigo",
            equals: "per_adm_abierto"
          },
          {
            path: "fechaDesde",
            equalOrBefore: moment(new Date()).format("DD/MM/YYYY")
          },
          {
            path: "fechaHasta",
            equalOrAfter: moment(new Date()).format("DD/MM/YYYY")
          }
          ])
          .value();

        PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {
          if (responsePeriodo.length > 0) {
            $state.go('app.planillafondofijo.new');
          } else {
            var msg = $filter('translate')('NO_OPEN_PERIODS_NOW');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
          }
        })
      };

      WatchLocacionChangeFactory.watchWith(undefined, $scope.options, "locacion.id");

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          search = search.split("~");
          if (search.length > 1) {
            /* El parámetro es un rago */

            var isDate = search[0].split("/");

            if (isDate.length > 1) {
              /* Es un rago de fechas */

              filtersArr.push({ path: data, equalOrAfter: search[0] })
              filtersArr.push({ path: data, equalOrBefore: search[1] })
            } else {
              /* El un rago numérco */

              filtersArr.push({ path: data, greaterOrEqual: search[0] })
              filtersArr.push({ path: data, lessOrEqual: search[1] })
            }
          } else {
            /* El parametro no es un rago */
            filtersArr.push({ path: data, like: search[0] })
          }
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }

      /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
      var checkPeriodoAdministrativo = function (fechaComprobante) {
        return $q(function (resolve, reject) {

          var params = {};
          var fecha;

          if (fechaComprobante == null) {
            fecha = new Date();
          } else {
            fecha = fechaComprobante;
          }

          params.search = filterFactory
            .and([{
              path: "estado.codigo",
              equals: "per_adm_abierto"
            },
            {
              path: "fechaDesde",
              equalOrBefore: moment(fecha).format("DD/MM/YYYY")
            },
            {
              path: "fechaHasta",
              equalOrAfter: moment(fecha).format("DD/MM/YYYY")
            }
            ])
            .value();

          PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {
            if (responsePeriodo.length > 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
        });
      }

    });
  }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.tesoreria')
    .controller('PlanillaFondoFijoFormCtrl', PlanillaFondoFijoFormCtrl)

PlanillaFondoFijoFormCtrl.$inject = ['$rootScope', '$scope', 'PlanillaFondoFijoFactory', '$location', '$state',
    'planillaFondoFijoPrepService', 'notify', 'formFactory', 'TesoreriaLangFactory', 'ComprobanteFactory', 'filterFactory',
    'ProveedoresFactory', 'ProveedorSucursalesFactory', 'TipoComprobanteFactory', 'GruposCompraFactory', 'ModelTrimmer',
    'TipoFondoFijoFactory', 'TiposTipoFactory', 'ParametrosFactory', 'SucursalesFactory', 'WatchLocacionChangeFactory',
    'observeOnScope', '$filter', '$q', 'PeriodoAdministrativoFactory', 'CompraFactory','UtilFactory'];

function PlanillaFondoFijoFormCtrl($rootScope, $scope, PlanillaFondoFijoFactory, $location, $state,
    planillaFondoFijoPrepService, notify, formFactory, TesoreriaLangFactory, ComprobanteFactory,
    filterFactory, ProveedoresFactory, ProveedorSucursalesFactory, TipoComprobanteFactory,
    GruposCompraFactory, ModelTrimmer, TipoFondoFijoFactory, TiposTipoFactory, ParametrosFactory,
    SucursalesFactory, WatchLocacionChangeFactory, observeOnScope, $filter, $q, PeriodoAdministrativoFactory, CompraFactory, UtilFactory) {

    var vm = this;
    vm.cancel = cancel;
    vm.submit = submit;
    vm.proveedorSelected = proveedorSelected;
    vm.clearFiltrosComprobantes = clearFiltrosComprobantes;
    vm.buscando = false;
    vm.totalSeleccionado = 0;
    vm.totalRetenciones = 0;
    vm.itemSelecionado = itemSelecionado
    vm.opcionesComprobantesDt = {}
    vm.planillaFondoFijo = {}
    vm.fechaDesdeChanged = fechaDesdeChanged
    vm.fechaHastaChanged = fechaHastaChanged
    vm.proveedorChanged = proveedorChanged
    vm.SucursalProveedorChanged = SucursalProveedorChanged
    vm.tipoComprobanteChanged = tipoComprobanteChanged
    vm.grupoCompraChanged = grupoCompraChanged
    vm.monedaChanged = monedaChanged
    vm.tipoPlanillaFondoFijoChanged = tipoPlanillaFondoFijoChanged
    vm.factoryComprobantes = ComprobanteFactory
    vm.compraChanged = compraChanged
    vm.searchComprasFilter = searchComprasFilter;
    vm.delayRequestCompras = delayRequestCompras;

    function delayRequestCompras(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
            var filterCodigo =[];

            filterCodigo.push({
            path: "codigo",
            like: searchValue
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterCodigo).value()
                ]).value()
            }

            recuperarCompras(userInputFilter)
        } else {
            vm.compras = undefined
        }
    }

    function recuperarCompras(userInputFilter) {
    var finalFilter = UtilFactory.createNestedFilters(
        vm.finalFilters, userInputFilter)

        vm.compras = CompraFactory.all(finalFilter, 'ComprobanteList');;
    }

    function searchComprasFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.codigo.toLowerCase().indexOf(criteria.toLowerCase()) > -1
            );
        };
    }

    activate();

    function activate() {
        TesoreriaLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;

            getProveedores();
            getTiposComprobante();
            getGruposCompra();
            getTiposFondoFijo();
            getTiposFondoFijo();
            getMonedas();
            getSucursales();
            setDataTableSearch();
            getCompras();

            if ($state.is("app.planillafondofijo.new")) {
                activateNew();
            } else if ($state.is("app.planillafondofijo.edit")) {
                activateEdit();
            } else if ($state.is("app.planillafondofijo.view")) {
                activateView();
            }

            $rootScope.isProcessing = false;
            vm.submited = false;
        });
    }

    /* Este observer se activa cuando seleccionamos comprobanes desde el DataTableSearch */
    var obs1 = observeOnScope($scope, "vm.comprobantesFiltrados")
        .filter(function (data) {
            return data.newValue;
        })
        .map(function (data) {
            return data.newValue;
        });

    obs1.subscribe(function (change) {

        /* Al re-filtrar los comprobantes perviamente seleccionados no se borran */
        if (!vm.comprobantes) {
            vm.comprobantes = []
        }
        _.forEach(change, function (value) {
            value.$promise.then(function (value) {
                monedaRender(value.moneda1);
                value.fecha = new Date(value.fecha)
                value.montoTotal = parseFloat(value.saldo),
                    value.seleccionado = true;
                itemSelecionado(value)
                value.retencion = sumaRetenciones(value.comprobanteRetenciones)
                vm.comprobantes.push(value)
                totalRetenciones();
                /* Esto es para que en la siguiente consulta ya no incluya los comprobantes
                ya seleccionados. */
                filtrarSeleccionados()
            })
        });

    });

  function sumaRetenciones(retenciones) {
    var total = 0;
    if (retenciones.length) {
      for (var i = 0; i < retenciones.length; i++) {
          if(retenciones[i].origen.codigo !== "comp_retencion_automatico"){
            total += retenciones[i].importe
          }
      }
      return total
    } else {
      return ""
    }
  }

  function totalRetenciones() {
    vm.totalRetenciones = 0;
    for (var i = 0; i < vm.comprobantes.length; i++) {
      if (vm.comprobantes[i].retencion) {
        vm.totalRetenciones += vm.comprobantes[i].retencion;
      }
    }
  }

    function setDataTableSearch() {
        /* Buscador de comprobantes */

        var defaultColumnOrder = [
            "fecha",
            "numero",
            "proveedor.nombre",
            "sucursalProveedor.descripcion",
            "grupoCompra.descripcion",
            "tipo.descripcion",
            "montoTotal",
            "saldo",
            "importacion.codigo",
            "compra.codigo"
        ];

        WatchLocacionChangeFactory.executeWatching(function (
            locacionFilter,
            watcher
        ) {
            vm.watcherComprob = watcher;
            /*TODO
                Agregar filtros de locación.
            */

            var filtros = cargarfiltrosGenerales()

            vm.opcionesComprobantesDt = {
                view: "ComprobanteList",
                staticFilter: filtros,
                resource: "comprobante",
                title: "Comprobante",
                factory: ComprobanteFactory,
                defaultColumnOrder: defaultColumnOrder,
                columns: [
                    {
                        data: "fecha",
                        title: $filter("translate")("DATE"),
                        renderWith: "dateRender",
                        dateFormat: "DD/MM/YYYY",
                        class: "dt-center",
                        type: "date-range"
                    },
                    {
                        data: "id",
                        title: $filter("translate")("CODE"),
                        class: "dt-center",
                        type: "number-range"
                    },
                    {
                        data: "numero",
                        title: $filter("translate")("NUMBER"),
                        class: "dt-center"
                    },
                    {
                        data: "proveedor.nombre",
                        title: $filter("translate")("PROVEEDOR_INDEX"),
                        class: "dt-center"
                    },
                    {
                        data: "sucursalProveedor.descripcion",
                        title: $filter("translate")("PROVEEDOR_SUCURSAL"),
                        class: "dt-center"
                    },
                    {
                        data: "grupoCompra.descripcion",
                        title: $filter("translate")("GRUPO_COMPRA"),
                        renderWith: "emptyRender",
                        class: "dt-center"
                    },
                    {
                        data: "tipo.descripcion",
                        title: $filter("translate")("TIPO_COMPROBANTE"),
                        class: "dt-center"
                    },
                    {
                        data: "estado.descripcion",
                        title: $filter("translate")("STATUS"),
                        class: "dt-center"
                    },
                    {
                        data: "montoTotal",
                        title: $filter("translate")("TOTAL_AMOUNT"),
                        class: "dt-right",
                        render: monedaRenderDataTable
                    },
                    {
                        data: "saldo",
                        title: $filter("translate")("SALDO"),
                        class: "dt-right",
                        render: monedaRenderDataTable
                    },
                    {
                        data: "importacion.codigo",
                        title: $filter("translate")("CODIGO_IMPORTACION"),
                        renderWith: "emptyRender",
                        class: "dt-center"
                    },
                    {
                        data: "compra.codigo",
                        title: "Compra",
                        renderWith: "emptyRender",
                        class: "dt-center"
                    }
                ],
                hasOptions: false,
                defaultOrderColumn: 1,
                defaultOrderDir: "desc"
            };
        })
    }

    function cargarfiltrosGenerales() {

        vm.staticFilterComprobantes = []

        if (vm.planillaFondoFijo && vm.planillaFondoFijo.tipoFondoFijo) {
            vm.staticFilterComprobantes.push({
                path: "montoTotal",
                lessOrEqual: vm.planillaFondoFijo.tipoFondoFijo.maximoFactura
            });
        }

        if (vm.planillaFondoFijo && vm.planillaFondoFijo.moneda) {
            vm.staticFilterComprobantes.push({
                path: "moneda1.id",
                equals: vm.planillaFondoFijo.moneda.id
            });
        }

        vm.staticFilterComprobantes.push({
            path: "estado.codigo",
            equals: "comp_aprobado"
        });

        vm.staticFilterComprobantes.push({
            path: 'tipo.clase.codigo',
            notEquals: 'nota_credito'
        });

        vm.staticFilterComprobantes.push({
            path: 'saldo',
            greaterThan: 0
        });

        vm.staticFilterComprobantes.push({
            path: 'hasPlanillaFondoFijoValida',
            equals: false
        })

       /*  vm.staticFilterComprobantes.push({
            path: 'planillaFondoFijoDetalles.id',
            isNull: 'true'
        }); */

        vm.staticFilterComprobantes.push({
            path: "tipo.generarCuotas",
            like: 'false'
        });

        return { search: filterFactory.and(vm.staticFilterComprobantes).value() }

    }

    function activateNew() {
        vm.edit = false;
        vm.title = $filter('translate')('NEW_PLANILLA_FONDO_FIJO');
        setEstadoInicialPlanilla();
        getMonedaDefault();
    }

    function activateEdit() {
        vm.title = $filter('translate')('EDIT_PLANILLA_FONDO_FIJO');
        vm.planillaFondoFijo = planillaFondoFijoPrepService;
        clearFiltrosComprobantes();
        if (vm.planillaFondoFijo.fecha) { vm.planillaFondoFijo.fecha = new Date(vm.planillaFondoFijo.fecha) };
        if (vm.planillaFondoFijo.fechaDesde) { vm.planillaFondoFijo.fechaDesde = new Date(vm.planillaFondoFijo.fechaDesde) };
        if (vm.planillaFondoFijo.fechaHasta) { vm.planillaFondoFijo.fechaHasta = new Date(vm.planillaFondoFijo.fechaHasta) };
        vm.totalSeleccionado = vm.planillaFondoFijo.importe
        vm.planillaFondoFijo.montoTotalPlanilla = vm.totalSeleccionado;
        vm.entidadId = vm.planillaFondoFijo.id;
        vm.entidad = "PlanillaFondoFijo";
        getCurrentComprobantes();
        totalRetenciones();
        monedaRender(vm.planillaFondoFijo.moneda);
        vm.edit = true;
    }

    function activateView() {
        vm.title = $filter('translate')('VIEW_PLANILLA_FONDO_FIJO');
        vm.planillaFondoFijo = planillaFondoFijoPrepService;
        clearFiltrosComprobantes();
        if (vm.planillaFondoFijo.fecha) { vm.planillaFondoFijo.fecha = new Date(vm.planillaFondoFijo.fecha) };
        if (vm.planillaFondoFijo.fechaDesde) { vm.planillaFondoFijo.fechaDesde = new Date(vm.planillaFondoFijo.fechaDesde) };
        if (vm.planillaFondoFijo.fechaHasta) { vm.planillaFondoFijo.fechaHasta = new Date(vm.planillaFondoFijo.fechaHasta) };
        vm.totalSeleccionado = vm.planillaFondoFijo.importe;
        vm.planillaFondoFijo.montoTotalPlanilla = vm.totalSeleccionado;
        vm.entidadId = vm.planillaFondoFijo.id;
        vm.entidad = "PlanillaFondoFijo";
        getCurrentComprobantes();
        totalRetenciones()
        monedaRender(vm.planillaFondoFijo.moneda);
        vm.view = true;
    }

    function getTiposComprobante() {

        var filter = {
            search: filterFactory
                .and([{
                    path: 'generarCuotas',
                    equals: false
                },
                {
                    path: 'estadoCuenta',
                    like: "true"
                },
                {
                    path: 'utilizarEnProcesosCompra',
                    equals: true
                },
                {
                    path: 'clase.codigo',
                    notEquals: 'nota_credito'
                }]).value()
        }
        vm.tiposComprobante = TipoComprobanteFactory.all(filter);
    }

    function getProveedores() {
        var filter = {
            search: filterFactory.single({
                path: 'activo',
                equals: true
            }).value()
        };
        vm.proveedores = ProveedoresFactory.all(filter);
    }

    function getCompras() {
        var filter = {};
        vm.compras = CompraFactory.all(filter, 'ComprobanteList');
    }

    function getGruposCompra() {
        var filter = {
            search: filterFactory.single({
                path: 'activo',
                equals: true
            }).value()
        };

        vm.gruposCompra = GruposCompraFactory.all(filter);
    }

    function getTiposFondoFijo() {
        vm.tiposFondoFijo = TipoFondoFijoFactory.all();
    }

    function getMonedas() {
        vm.monedas = TiposTipoFactory.get("monedas");
    }

    function getSucursales() {
        vm.sucursales = SucursalesFactory.all();
    }

    function setEstadoInicialPlanilla() {
        TiposTipoFactory.get("estados_planilla_fondo_fijo").$promise.then(function (response) {
            var ingresado = _.find(response, function (estado) {
                return estado.codigo == "epf_ingresado";
            });
            vm.planillaFondoFijo.estado = ingresado;
        });
    }

    function getCurrentComprobantes() {
        vm.comprobantes = _.map(vm.planillaFondoFijo.planillaFondoFijoDetalles, function (detalle) {
            monedaRender(detalle.comprobante.moneda1);
            return {
                id: parseInt(detalle.comprobante.id),
                numero: detalle.comprobante.numero,
                fecha: new Date(detalle.comprobante.fecha),
                proveedor: detalle.comprobante.proveedor,
                saldo: parseFloat(detalle.comprobante.saldo),
                montoTotal: parseFloat(detalle.montoPlanilla),
                tipo: detalle.comprobante.tipo,
                retencion: sumaRetenciones(detalle.comprobante.comprobanteRetenciones),
                seleccionado: true
            };
        });
    }

    var monedaRenderDataTable = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.moneda1.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda1.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda1.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda1.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }
            return (
                moneda +
                data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else return "";
    };

    function monedaRender(moneda) {
        vm.monedaRender = "Gs."
        vm.cantidadDecimalesMondeda = 0
        if (moneda.codigo == "dolares") {
            vm.monedaRender = "Usd."
            vm.cantidadDecimalesMondeda = 2
        } else if (moneda.codigo == "pesos") {
            vm.monedaRender = "Pes."
            vm.cantidadDecimalesMondeda = 2
        } else if (moneda.codigo == "real") {
            vm.monedaRender = "Rel."
            vm.cantidadDecimalesMondeda = 2
        } else if (moneda.codigo == "euro") {
            vm.monedaRender = "Eur."
            vm.cantidadDecimalesMondeda = 2
        }
    }

    function itemSelecionado(comprobanteSeleccionado) {

        /* Validaciones por linea de comprobante seleccionado */
        if (!comprobanteSeleccionado.montoTotal) {
            notify({ message: 'Debe ingresar un importe valido para el comprobante ' + comprobanteSeleccionado.numero, classes: 'alert-danger', position: 'right' });
            comprobanteSeleccionado.seleccionado = false
            return;
        } else {
            if (parseFloat(comprobanteSeleccionado.montoTotal) <= 0) {
                notify({ message: 'El importe ingresado debe ser mayor a cero ' + comprobanteSeleccionado.numero, classes: 'alert-danger', position: 'right' });
                comprobanteSeleccionado.seleccionado = false
                return;
            }

            if (parseFloat(comprobanteSeleccionado.montoTotal) > parseFloat(comprobanteSeleccionado.saldo)) {
                notify({ message: 'El importe ingresado no puede se mayor al saldo ' + comprobanteSeleccionado.numero, classes: 'alert-danger', position: 'right' });
                comprobanteSeleccionado.seleccionado = false
                return;
            }

        }

        var comprobanteRetenciones = comprobanteSeleccionado.comprobanteRetenciones ?
          comprobanteSeleccionado.comprobanteRetenciones.length : []

        var retenciones = comprobanteRetenciones.length ?
          sumaRetenciones(comprobanteRetenciones) :
          (comprobanteSeleccionado.retencion ? comprobanteSeleccionado.retencion : 0)
        /* Totalizar item seleccionado. */
        if (comprobanteSeleccionado.seleccionado) {
            vm.totalRetenciones += retenciones;
            vm.totalSeleccionado += comprobanteSeleccionado.montoTotal;
            vm.planillaFondoFijo.montoTotalPlanilla = vm.totalSeleccionado;
            if (vm.totalSeleccionado != 0) {
              vm.planillaFondoFijo.saldoDisponible = vm.planillaFondoFijo.montoTipoFondoFijo - vm.planillaFondoFijo.montoTotalPlanilla;
            } else {
                vm.planillaFondoFijo.saldoDisponible = 0;
            }
        } else {
            vm.totalRetenciones -= retenciones;
            vm.totalSeleccionado -= comprobanteSeleccionado.montoTotal;
            vm.planillaFondoFijo.montoTotalPlanilla = vm.totalSeleccionado;
            if (vm.totalSeleccionado != 0) {
              vm.planillaFondoFijo.saldoDisponible = vm.planillaFondoFijo.montoTipoFondoFijo - vm.planillaFondoFijo.montoTotalPlanilla;
            } else {
                vm.planillaFondoFijo.saldoDisponible = 0;
            }
        }

        /* Validar total maximo de planilla de fondo fijo */
        if (vm.totalSeleccionado > vm.planillaFondoFijo.tipoFondoFijo.maximoTotal) {
            vm.totalSeleccionado -= parseFloat(comprobanteSeleccionado.montoTotal);
            vm.planillaFondoFijo.montoTotalPlanilla = vm.totalSeleccionado;
            if (vm.totalSeleccionado != 0) {
                vm.planillaFondoFijo.saldoDisponible = vm.planillaFondoFijo.montoTipoFondoFijo - vm.planillaFondoFijo.montoTotalPlanilla;
            } else {
                vm.planillaFondoFijo.saldoDisponible = 0;
            }
            comprobanteSeleccionado.seleccionado = false;
            notify({
                message: 'El monto total de una planilla del tipo: ' + vm.planillaFondoFijo.tipoFondoFijo.nombre + ' no puede ser mayor a ' +
                    vm.planillaFondoFijo.tipoFondoFijo.maximoTotal, classes: 'alert-danger', position: 'right'
            });
        }
    }

    function proveedorSelected() {
        var filters = {
            search: filterFactory.single({
                path: 'proveedor.id',
                equals: vm.planillaFondoFijo.proveedor.id
            }).value()
        };
        vm.sucursalesProveedor = ProveedorSucursalesFactory.all(filters);
    }

    function clearFiltrosComprobantes() {

        delete vm.planillaFondoFijo.proveedor;
        delete vm.planillaFondoFijo.sucursalProveedor;
        delete vm.planillaFondoFijo.tipoComprobante;
        delete vm.planillaFondoFijo.grupoCompra;
        delete vm.planillaFondoFijo.fechaDesde;
        delete vm.planillaFondoFijo.fechaHasta;

        vm.search = {};

        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filtro) {
                var result = !(
                    filtro.path == "fecha" ||
                    filtro.path == "tipo.id" ||
                    filtro.path == "grupoCompra.id" ||
                    filtro.path == "sucursalProveedor.id" ||
                    filtro.path == "proveedor.id" ||
                    filtro.path == "compra.id"
                );
                return result;
            }
        )

        var filtros = { search: filterFactory.and(vm.staticFilterComprobantes).value() }
        vm.opcionesComprobantesDt.staticFilter = filtros;
    }

    function fechaDesdeChanged() {
        validarRangoFecha()

        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return (
                    filter.path != "fecha" ||
                    (filter.path == "fecha" && !filter.equalOrAfter)
                );
            }
        );
        if (vm.planillaFondoFijo) {
            vm.staticFilterComprobantes.push({
                path: "fecha",
                equalOrAfter: vm.planillaFondoFijo.fechaDesde != undefined ?
                    moment(vm.planillaFondoFijo.fechaDesde).format('DD/MM/YYYY') : undefined
            });
        }

        var filtros = { search: filterFactory.and(vm.staticFilterComprobantes).value() }
        vm.opcionesComprobantesDt.staticFilter = filtros
    }

    function fechaHastaChanged() {
        validarRangoFecha()

        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return (
                    filter.path != "fecha" ||
                    (filter.path == "fecha" && !filter.equalOrBefore)
                );
            }
        );

        if (vm.planillaFondoFijo) {
            vm.staticFilterComprobantes.push({
                path: "fecha",
                equalOrBefore: vm.planillaFondoFijo.fechaHasta != undefined ?
                    moment(vm.planillaFondoFijo.fechaHasta).format('DD/MM/YYYY') : undefined
            });
        }

        var filtros = { search: filterFactory.and(vm.staticFilterComprobantes).value() }
        vm.opcionesComprobantesDt.staticFilter = filtros
    }

    function proveedorChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "proveedor.id";
            }
        );

        if (vm.planillaFondoFijo) {
            vm.staticFilterComprobantes.push({
                path: "proveedor.id",
                equals: vm.planillaFondoFijo.proveedor.id
            });
        }

        var filtros = { search: filterFactory.and(vm.staticFilterComprobantes).value() }
        vm.opcionesComprobantesDt.staticFilter = filtros
    }

    function compraChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "compra.id";
            }
        );

        if (vm.planillaFondoFijo) {
            vm.staticFilterComprobantes.push({
                path: "compra.id",
                equals: vm.planillaFondoFijo.comprobante.compra.id
            });
        }

        var filtros = { search: filterFactory.and(vm.staticFilterComprobantes).value() }
        vm.opcionesComprobantesDt.staticFilter = filtros
    }

    function SucursalProveedorChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "sucursalProveedor.id";
            }
        );

        if (vm.planillaFondoFijo) {
            vm.staticFilterComprobantes.push({
                path: "sucursalProveedor.id",
                equals: vm.planillaFondoFijo.sucursalProveedor.id
            });
        }

        var filtros = { search: filterFactory.and(vm.staticFilterComprobantes).value() }
        vm.opcionesComprobantesDt.staticFilter = filtros
    }

    function tipoComprobanteChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "tipo.id";
            }
        );

        if (vm.planillaFondoFijo) {
            vm.staticFilterComprobantes.push({
                path: "tipo.id",
                equals: vm.planillaFondoFijo.tipoComprobante.id
            });
        }

        var filtros = { search: filterFactory.and(vm.staticFilterComprobantes).value() }
        vm.opcionesComprobantesDt.staticFilter = filtros
    }

    function grupoCompraChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "grupoCompra.id";
            }
        );

        if (vm.planillaFondoFijo) {
            vm.staticFilterComprobantes.push({
                path: "grupoCompra.id",
                equals: vm.planillaFondoFijo.grupoCompra.id
            });
        }

        var filtros = { search: filterFactory.and(vm.staticFilterComprobantes).value() }
        vm.opcionesComprobantesDt.staticFilter = filtros
    }


    function tipoPlanillaFondoFijoChanged() {
        vm.comprobantes = []
        vm.totalSeleccionado = 0;
        vm.totalRetenciones = 0;

        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "montoTotal";
            }
        );

        if (vm.planillaFondoFijo) {
            vm.staticFilterComprobantes.push({
                path: "montoTotal",
                lessOrEqual: vm.planillaFondoFijo.tipoFondoFijo.maximoFactura
            });
        }

        var filtros = { search: filterFactory.and(vm.staticFilterComprobantes).value() }
        vm.opcionesComprobantesDt.staticFilter = filtros

        vm.planillaFondoFijo.montoTipoFondoFijo = vm.planillaFondoFijo.tipoFondoFijo.maximoTotal;
    }

    function monedaChanged() {
        if (vm.planillaFondoFijo.moneda) {
            monedaRender(vm.planillaFondoFijo.moneda);
            vm.comprobantes = []
            vm.totalSeleccionado = 0;
            vm.totalRetenciones = 0;

            vm.staticFilterComprobantes = _.filter(
                vm.staticFilterComprobantes,
                function (filter) {
                    return filter.path != "moneda1.id";
                }
            );

            if (vm.planillaFondoFijo) {
                vm.staticFilterComprobantes.push({
                    path: "moneda1.id",
                    equals: vm.planillaFondoFijo.moneda.id
                });
            }

            var filtros = { search: filterFactory.and(vm.staticFilterComprobantes).value() }
            vm.opcionesComprobantesDt.staticFilter = filtros
        }
    }


    function filtrarSeleccionados() {

        /* Cuando ya existen comprobantes seleccionados en el detalle filtramos a estos para evitar duplicidad */


        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "id";
            }
        );

        _.forEach(vm.comprobantes, function (value) {
            vm.staticFilterComprobantes.push({
                path: 'id',
                notEquals: value.id
            });
        });

        var filtros = { search: filterFactory.and(vm.staticFilterComprobantes).value() }
        vm.opcionesComprobantesDt.staticFilter = filtros
    };

    function validarRangoFecha() {
        if (vm.planillaFondoFijo.fechaDesde && vm.planillaFondoFijo.fechaDesde.getTime() >
            vm.planillaFondoFijo.fechaHasta && vm.planillaFondoFijo.fechaHasta.getTime()) {
            notify({ message: $filter('translate')('DATE_FROM_DATE_TO_ERROR'), classes: 'alert-danger', position: 'right' });
            return;
        }
    }

    function submit() {
        vm.submited = true;
        if ($scope.PlanillaFondoFijoForm.$valid) {
            evaluatePeriodoAdministrativo(vm.planillaFondoFijo.fecha).then(function (response) {
                if (response) {
                    var trimmedModel = ModelTrimmer.trimDetails(vm.planillaFondoFijo);
                    trimmedModel.planillaFondoFijoDetalles = []
                    _.forEach(vm.comprobantes, function (comprobante) {
                        if (comprobante.seleccionado) {
                            trimmedModel.planillaFondoFijoDetalles.push({ comprobante: parseInt(comprobante.id), montoPlanilla: comprobante.montoTotal });
                        }
                    });

                    if (!trimmedModel.planillaFondoFijoDetalles || trimmedModel.planillaFondoFijoDetalles.length == 0) {
                        notify({ message: $filter('translate')('COMPROBANTES_NEEDED'), classes: 'alert-danger', position: 'right' });
                        return;
                    }

                    if (!vm.isEdit) {
                        trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
                    }

                    formFactory.defaultNSFSubmit($scope.PlanillaFondoFijoForm, PlanillaFondoFijoFactory, trimmedModel, errorHandler).then(function (response) {
                        ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                            if (parametro.valorTipo.codigo == 'si') {
                                if (response.enviadoAContabilidad == false) {
                                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                }
                                if (response.listaArchivosLogs != null) {
                                    if (response.listaArchivosLogs.length != 0) {
                                        /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                            console.log('response.listaArchivosLogs: ');
                                            console.log(response.listaArchivosLogs[i]);
                                            $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                        }*/
                                        notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                    }
                                }
                            }
                        });
                        $location.path('/planillafondofijo');
                    }, function (error) {
                        console.log(error);
                    });
                } else {
                    var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }
            });

        } else {
            notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right' });
        }
    }

    function evaluatePeriodoAdministrativo(fecha) {
        return $q(function (resolve, reject) {
            var params = {};
            params.search = filterFactory
                .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                }
                ])
                .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {

                if (responsePeriodo.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        });
    }

    function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function (error) {
            if (error.constraint == 'nombre') {
                msg += '\n\n' + $filter('translate')('SAME_NAME_PRODUCT_CLASS') + "\n"
            } else {
                msg += '\n\n' + error.message + '.'
            }
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
    }

    function cancel() {
        $location.path("/planillafondofijo");
    }

    function getMonedaDefault() {
        var moneda = ParametrosFactory.filterByCodigo("moneda_local");
        moneda.$promise.then(function (data) {
            if (data && data.length > 0) {
                vm.planillaFondoFijo.moneda = data[0].valorTipo;
                vm.monedaDefault = data[0].valorTipo;
            }
            monedaChanged()
        });

    }

    $scope.resource = 'planillafondofijo'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.ChequeFactory
 * @description
 * # ChequeFactory
 * Factory in the qualita.
 */
angular.module('qualita.tesoreria')
  .factory('ChequeFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
  '$translatePartialLoader', '$translate',
    function($resource,  baseurl, filterFactory, TiposFactory, $http, $translatePartialLoader, $translate) {

  	var ChequeFactory= $resource( baseurl.getBaseUrl() + '/cheques/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      },
      anular: {
        method: 'POST',
        url: baseurl.getBaseUrl() + '/cheques/:id/anular'
      },
      recuperar: {
        method: 'POST',
        url: baseurl.getBaseUrl() + '/cheques/:id/recuperar'
      }
    });

    return {
      all: function(params, view) {
        if (params) {
            params.view = view != undefined ? view : 'BaseList';
          } else {
            params = [];
            params.view = view != undefined ? view : 'BaseList';
          }
          return ChequeFactory.query(params);
        },

      get: function(id, view) {
        return ChequeFactory.get({id: id, view: view ? view : "base"});
      },

      anular: function(id) {
        return ChequeFactory.anular({id: id});
      },

      recuperar: function(id){
        return ChequeFactory.recuperar({id: id});
      },

      create: function(attrs) {
        return new ChequeFactory(attrs);
      },

      save: function(chequera) {
        return (chequera.id) ? chequera.$update() : chequera.$save();
      },

      remove: function(chequera) {
        return chequera.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      },

    };
  }]);

angular.module('qualita.tesoreria')
    .factory('PlanillaFondoFijoFactory', PlanillaFondoFijoFactory);

    PlanillaFondoFijoFactory.$inject = ['$resource', 'baseurl', '$http'];

    function PlanillaFondoFijoFactory($resource, baseurl, $http) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove,
        reenviar: reenviar,
        emitir: emitir,
        getEstaAsentado:getEstaAsentado,
        aprobar: aprobar,
        anular: anular,
    };

    var PlanillaFondoFijo = $resource( baseurl.getBaseUrl() + "/planillafondofijo/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
    });

    return service;

  function all(params) {
    return PlanillaFondoFijo.query(params);
  }

  function get(id, view) {
    if (typeof(view)==='undefined') view = "base";
    return PlanillaFondoFijo.get({id: id, view: view});
  }

  function create(attrs) {
    return new PlanillaFondoFijo(attrs);
  }

  function save(planillafondofijo) {
    return (planillafondofijo.id) ? planillafondofijo.$update() : planillafondofijo.$save();
  }

  function remove(planillafondofijo) {
    return planillafondofijo.$remove();
  }

  function reenviar(idplanillafondofijo) {
    return $http({
      url: baseurl.getBaseUrl() + '/planillafondofijo/reenviar/'+idplanillafondofijo,
      method: "PUT"
    });
  }

  function emitir(id) {
    return $http({
      url: baseurl.getBaseUrl() + '/planillafondofijo/emitir/'+id,
      method: "PUT"
    });
  }

  function aprobar(id) {
    return $http({
      url: baseurl.getBaseUrl() + '/planillafondofijo/aprobar/'+id,
      method: "PUT"
    });
  }

  function anular(id) {
    return $http({
      url: baseurl.getBaseUrl() + '/planillafondofijo/anular/'+id,
      method: "PUT"
    });
  }

  function getEstaAsentado(id) {
    return $http({
        url: baseurl.getBaseUrl() + '/planillafondofijo/estaAsentado/' + id,
        method: "GET"
    });
  }
}

angular.module('qualita.tesoreria')
    .factory('OrdenPagoFactory', OrdenPagoFactory);

OrdenPagoFactory.$inject = ['$resource', 'baseurl', '$http'];

function OrdenPagoFactory($resource, baseurl, $http) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove,
        confirmar: confirmar,
        reenviar: reenviar,
        desconfirmar: desconfirmar,
        createOPRecursosHumanos: createOPRecursosHumanos,
        getEstaAsentado: getEstaAsentado
    };

    var OrdenPago = $resource(baseurl.getBaseUrl() + "/ordenpago/:id", { id: '@id' }, {
        update: {
            method: 'PUT'
        }
    });

    return service;

    function all(params) {
        return OrdenPago.query(params);
    }

    function get(id, view) {
        if (typeof(view) === 'undefined') view = "base";
        return OrdenPago.get({ id: id, view: view });
    }

    function create(attrs) {
        return new OrdenPago(attrs);
    }

    function save(ordenpago) {
        return (ordenpago.id) ? ordenpago.$update() : ordenpago.$save();
    }

    function remove(ordenpago) {
        return ordenpago.$remove();
    }

    function confirmar(id) {
        return $http({
            url: baseurl.getBaseUrl() + '/ordenpago/confirmar/' + id,
            method: "PUT"
        });
    }

    function reenviar(ordenpago) {
        return $http({
            url: baseurl.getBaseUrl() + '/ordenpago/reenviar/' + ordenpago.id,
            method: "PUT"
        });
    }

    function desconfirmar(id, clonar) {
        return $http({
            url: baseurl.getBaseUrl() + '/ordenpago/desconfirmar/' + id + '?clonar=' + clonar,
            method: "PUT"
        });
    }

    function createOPRecursosHumanos(idReferencia, idTipoOP, idLocacion) {
        return $http({
            url: baseurl.getBaseUrl() + '/ordenpago/rrhh?referencia_id=' + idReferencia +
                "&tipo_op_id=" + idTipoOP + "&locacion_id=" + idLocacion,
            method: "POST"
        });
    }

    function getEstaAsentado(id) {
        return $http({
            url: baseurl.getBaseUrl() + '/ordenpago/estaAsentado/' + id,
            method: "GET"
        });
    }
}
'use strict';


angular.module('qualita.tesoreria')
    .controller('OrdenPagoIndexCtrl', ["$scope", "OrdenPagoFactory", "filterFactory", "ReportTicketFactory", "$window", "TesoreriaLangFactory", "TipoOrdenPagoFactory", "AuthorizationService", "WatchLocacionChangeFactory", "notify", "$filter", "$modal", "$state", "$rootScope", "UtilFactory", "CsvFactory", "baseurl", "ParametrosFactory", "$q", "PeriodoAdministrativoFactory", function($scope, OrdenPagoFactory,
        filterFactory, ReportTicketFactory, $window, TesoreriaLangFactory, TipoOrdenPagoFactory,
        AuthorizationService, WatchLocacionChangeFactory, notify, $filter, $modal, $state, $rootScope,
        UtilFactory, CsvFactory, baseurl, ParametrosFactory, $q, PeriodoAdministrativoFactory) { // TODO agregar factory de salarios (cuando exista)

        var vm = this

        TesoreriaLangFactory.getTranslations().then(function(translations) {
            var staticFilter = undefined;
            staticFilter = {};
            staticFilter.search = filterFactory
                        .and([
                            {
                                path: "id",
                                sortDesc: "true"
                            },
                            {
                                path: "id",
                                isNotNull: "true"
                            }
                        ])
                        .value();
            var unidadMedidaRenderCosto = function(data, type, row) {
                if (data) {
                    var moneda = "Gs. ";
                    if (row.moneda.codigo === 'dolares') {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'guaranies') {
                        data = parseFloat(data).toFixed(0);
                    } else if (row.moneda.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    }
                    // TODO hacer esto mismo en todos los renders de moneda
                    return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return '';
                }
            };

            var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
            var enviarAContabilidad = '';

            promise1.then(function(response) {
                enviarAContabilidad = response.valorTipo.codigo;
            });

            var monedaRender = function (data, type, row) {
              if (data || data ==0) {
                var moneda = "Gs. ";
                if (row.moneda.codigo === 'dolares') {
                  moneda = "Usd. ";
                  data = parseFloat(data).toFixed(2);
                } else if (row.moneda.codigo === 'pesos') {
                  moneda = "Pes. ";
                  data = parseFloat(data).toFixed(2);
                } else if (row.moneda.codigo === 'real') {
                  moneda = "Rel. ";
                  data = parseFloat(data).toFixed(2);
                } else if (row.moneda.codigo === 'euro') {
                  moneda = "Eur. ";
                  data = parseFloat(data).toFixed(2);
                }
                // TODO hacer esto mismo en todos los renders de moneda
                return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              } else {
                return '';
              }
            }

            var saldoImporteRender = function (data, type, row) {

                // Esto para el caso de anticipos anulados
                if(row.estado.codigo == 'opago_anulada'){
                    return '';
                }else{

                    if (data || data ==0) {
                        var moneda = "Gs. ";
                        if (row.moneda.codigo === 'dolares') {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                        } else if (row.moneda.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                        } else if (row.moneda.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                        } else if (row.moneda.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                        }
                        // TODO hacer esto mismo en todos los renders de moneda
                        return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    } else {
                        return '';
                    }
                }
            }

            var defaultColumnOrder = ['numero', 'tipoOrdenPago.nombre','proyecto.nombre','concepto','moneda.descripcion','tipoCambio','fecha', 'proveedor.nombre', 'estado.descripcion', 'formaPago.descripcion','planillaFondoFijo.numero',
             'cuentaContable.descripcion','codigoPago','importe', 'importeRetencion', 'importeMasRetencion', 'saldoImporte'
            ];
            vm.options = {
                'resource': 'ordenpago',
                'view': 'GrillaOP',
                'title': $filter('translate')('ORDENES_PAGO'),
                'staticFilter': staticFilter,
                'factory': OrdenPagoFactory,
                'view': "OrdenPagoList",
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('FAIL_DELETE_ORDEN_PAGO'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('ID'), visible:false},
                    { 'data': 'numero', 'title': $filter('translate')('NUMERO_OP') },
                    { 'data': 'fecha', 'title': $filter('translate')('DATE'), renderWith: "dateRender", dateFormat: "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'tipoOrdenPago.nombre', 'title': $filter('translate')('TIPO_ORDEN_PAGO') },
                    { 'data': 'proyecto.nombre', 'title': $filter('translate')('PROYECTO'),renderWith: "emptyRender" },
                    { 'data': 'concepto', 'title': $filter('translate')('CONCEPTO'),renderWith: "emptyRender" },
                    { 'data': 'moneda.descripcion', 'title': $filter('translate')('MONEDA'),renderWith: "emptyRender" },
                    { 'data': 'tipoCambio', 'title': $filter('translate')('TIPO_CAMBIO_SHORT'),renderWith: "emptyRender" },
                    { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX'), renderWith: "emptyRender" },
                    { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/estado_orden_pago' },
                    { 'data': 'formaPago.descripcion', 'title': $filter('translate')('FORMA_PAGO'), 'class': 'dt-center' },
                    { 'data': 'planillaFondoFijo.numero', 'title': $filter('translate')('PLANILLA_FONDO_FIJO'), 'class': 'dt-center',renderWith: "emptyRender" },
                    { 'data': 'cuentaContable.descripcion', 'title': $filter('translate')('CUENTA_CONTABLE'), 'class': 'dt-center',renderWith: "emptyRender" },
                    { 'data': 'codigoPago', 'title': $filter('translate')('CODIGO_PAGO'), 'class': 'dt-center',renderWith: "emptyRender" },
                    { 'data': 'importe', 'title': $filter('translate')('IMPORTE_NETO'), 'class': 'dt-right', 'render': unidadMedidaRenderCosto  },
                    { 'data': 'importeRetencion', 'title': $filter('translate')('IMPORTE_RETENCION'), 'class': 'dt-right', 'render': unidadMedidaRenderCosto  },
                    { 'data': 'importeMasRetencion', 'title': $filter('translate')('IMPORTE_TOTAL'), 'class': 'dt-right', 'render': unidadMedidaRenderCosto  },
                    { 'data': 'saldoImporte', 'title': $filter('translate')('Saldo anticipo'), 'class': 'dt-right', type: "number-range", 'render': saldoImporteRender  }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions': [
                    {
                        title: $filter("translate")("NEW"),
                        icon: "glyphicon glyphicon-plus",
                        showCondition: function() {
                            var permission = AuthorizationService.hasPermission('update_ordenpago');
                            return permission;
                        },
                        action: function() {
                            checkPeriodoAdministrativo(null).then(function(resultado) {
                                if(resultado == true){
                                    return $state.go('app.ordenpago.new');
                                }else if(resultado == false){
                                    var msg= $filter('translate')('NO_OPEN_PERIODS_NOW');
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            });
                        }
                    },
                    {
                        title: $filter("translate")("OP_RANGE"),
                        icon: "glyphicon glyphicon-list-alt",
                        'showCondition': function() {
                            return true;
                        },
                        'action': function() {

                            $scope.obj = {};

                            $scope.tituloModal = $filter("translate")("OP_RANGE_TITLE");
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/op-rango-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                modalInstance.dismiss("cancel");
                            };

                            $scope.ok = function(numeroDesde, numeroHasta) {
                                if (numeroDesde && numeroHasta) {
                                    //Se recupera el nombre de la empresa de un parametro del sistema
                                    ParametrosFactory.getByCodigo("nombre_empresa").then(function(data) {
                                        $scope.nombreEmpresa = data;

                                        var filtersArr = [];
                                        filtersArr.push("nombre=" + $rootScope.AuthParams.accesoSistema.nombres + " " + $rootScope.AuthParams.accesoSistema.apellidos);
                                        filtersArr.push("nombre_empresa=" + $scope.nombreEmpresa.valor);
                                        filtersArr.push("numero_orden_pago_desde=" + numeroDesde);
                                        filtersArr.push("numero_orden_pago_hasta=" + numeroHasta);

                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('ordenpago', 'pdf', filtersArr);
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                        $state.go($state.current, {}, { reload: true });
                                    });
                                    modalInstance.close();
                                }
                            };
                        }
                    },
                    {
                        'title': 'Generar OP RRHH',
                        'icon': 'glyphicon glyphicon-import',
                        'showCondition': function() {
                            var permission = AuthorizationService.hasPermission('create_compra');
                            return permission;
                        },
                        'action': function() {
                            $scope.addNewOP();
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                            CsvFactory.csv("ordenpago", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                                .then(function(response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    },
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function() { return true; },
                        'action': function() {
                          var filters = createFilters(vm.options.getFilters());
                          ReportTicketFactory.ticket('listadoOrdenPago', filters, vm.options.tableAjaxParams,
                            vm.options.currentColumnOrder).then(function(genTicket) {
                            $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                            $window.open($scope.pdfDownloadURL, '_blank');
                          });
                        }
                    }
                ],
                // TODO agregar editar para que no salga desordenado
                'extraRemoveConditions': function(item) {
                    if(item){
                        return item.estado.codigo == 'opago_ingresada';
                    }
                },

                'extraRowOptions': [{
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title=" + $filter('translate')('EDIT') + " ng-click='editarProceso(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: ['estado', 'tipoOrdenPago', 'id'],
                        conditionName: 'canEditar',
                        conditionDef: function(attributes) {
                            return AuthorizationService.hasPermission('update_ordenpago') && attributes && attributes[0].codigo == "opago_ingresada";
                        },
                        functionName: "editarProceso",
                        functionDef: function(attributes) {
                            if (attributes[1].clase.codigo == "clase_orden_pago_vacaciones" ||
                                attributes[1].clase.codigo == "clase_orden_pago_adelanto" ||
                                attributes[1].clase.codigo == "clase_orden_pago_salario") {
                                return $state.go('app.ordenpago.edit2', { id: attributes[2] });
                            }
                            return $state.go('app.ordenpago.edit', { id: attributes[2] });
                        },
                    },
                    {
                        templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataCustom%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        customAttribute: ['estado', 'tipoOrdenPago', 'id'],
                        conditionName: 'canView',
                        conditionDef: function() {
                            return AuthorizationService.hasPermission('index_ordenpago');
                        },
                        functionName: "view",
                        functionDef: function(attributes) {
                            if (attributes[1].clase.codigo == "clase_orden_pago_vacaciones" ||
                                attributes[1].clase.codigo == "clase_orden_pago_adelanto" ||
                                attributes[1].clase.codigo == "clase_orden_pago_salario") {
                                return $state.go('app.ordenpago.view2', { id: attributes[2] });
                            }
                            return $state.go('app.ordenpago.view', { id: attributes[2] });
                        },
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('CONFIRMAR_ORDEN_PAGO') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='confirmar(<%=dataId%>)' ng-class='{hidden : !canConfirmar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-file'></span> </button>",
                        functionName: 'confirmar',
                        functionDef: function(itemId) {
                            OrdenPagoFactory.get(itemId, "OrdenPagoList").$promise.then(function(response) {
                                checkPeriodoAdministrativo(response.fecha).then(function(checkPeriodo) {
                                    if(checkPeriodo == true){
                                        $scope.tituloModal = $filter('translate')('CONFIRMAR_ORDEN_PAGO_Q');
                                        $scope.mensajeModal = $filter('translate')('CONFIRMAR_ORDEN_PAGO_MSG');
                                        var modalInstance = $modal.open({
                                            templateUrl: 'views/directives/confirmation-modal.html',
                                            scope: $scope
                                        });

                                        $scope.cancel = function() {
                                            modalInstance.dismiss('cancel');
                                        };

                                        $scope.ok = function(id) {
                                            OrdenPagoFactory.confirmar(itemId).then(function(response) {
                                                // ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                                //     if (parametro.valorTipo.codigo == 'si') {
                                                //       if(response.enviadoAContabilidad == false){
                                                //         notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                //       }
                                                //       if(response.data.listaArchivosLogs != null){
                                                //         if(response.data.listaArchivosLogs.length != 0){
                                                //           /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                //               console.log('response.listaArchivosLogs: ');
                                                //               console.log(response.listaArchivosLogs[i]);
                                                //               $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                //           }*/
                                                //           notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                //         }
                                                //       }
                                                //     }
                                                // });
                                                $state.go("app.ordenpago.list", { id: itemId }, { reload: true });
                                            }, function(error) {
                                                var msg = "";
                                                if (error.data && error.data.length > 0 && error.data[0].message) {
                                                    msg = error.data[0].message;
                                                } else {
                                                    msg = $filter('translate')('CONFIRMAR_ORDEN_PAGO_FAILED');
                                                }

                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            });
                                            modalInstance.close();
                                        }
                                    }else{
                                        var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            });
                        },
                        conditionName: 'canConfirmar',
                        customAttribute: 'estado',
                        conditionDef: function(estado) {
                            return AuthorizationService.hasPermission("update_ordenpago") && estado.codigo == "opago_ingresada";
                        }
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('DESCONFIRMAR_ORDEN_PAGO') + "' class='btn btn-default btn-dt' style='margin-right: 5px;' ng-click='desconfirmar(<%=dataId%>)' ng-class='{hidden : !canDesconfirmar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-remove'></span> </button>",
                        functionName: 'desconfirmar',
                        functionDef: function(itemId) {
                            OrdenPagoFactory.get(itemId, "OrdenPagoList").$promise.then(function(response) {
                                var numeroOp = response.numero;
                                checkPeriodoAdministrativo(response.fecha).then(function(checkPeriodo) {
                                    if(checkPeriodo == true){
                                        $scope.tituloModal = $filter('translate')('DESCONFIRMAR_ORDEN_PAGO_Q');
                                        $scope.mensajeModal = $filter('translate')('DESCONFIRMAR_ORDEN_PAGO_MSG');
                                        var modalInstance = $modal.open({
                                            templateUrl: 'views/directives/anular-op-modal.html',
                                            scope: $scope
                                        });

                                        $scope.cancel = function() {
                                            modalInstance.dismiss('cancel');
                                        };

                                        $scope.ok = function(id, clonar) {
                                            OrdenPagoFactory.getEstaAsentado(itemId).then(
                                                function (response) {
                                                    if(response.data == "true"){
                                                        var msg = $filter("translate")("ERROR_ESTA_ASENTADO_OP", { numero: numeroOp });
                                                        notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                    }else{
                                                        // pasar parametro extra que indique si se quiere clonar o no la OP
                                                        OrdenPagoFactory.desconfirmar(itemId, clonar).then(function(response) {
                                                            $state.go("app.ordenpago.list", { id: itemId }, { reload: true });
                                                        }, function(error) {
                                                            var msg = "";
                                                            if (error.data && error.data.length > 0 && error.data[0].message) {
                                                                msg = error.data[0].message;
                                                            } else {
                                                                msg = $filter('translate')('DESCONFIRMAR_ORDEN_PAGO_FAILED');
                                                            }
                                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                        });
                                                        modalInstance.close();
                                                    }
                                                }
                                            );
                                        }
                                    }else{
                                        var msg= $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            });
                        },
                        conditionName: 'canDesconfirmar',
                        customAttribute: 'estado',
                        conditionDef: function(estado) {
                            return AuthorizationService.hasPermission("update_ordenpago") && estado.codigo == "opago_confirmada";
                        }
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('APLICAR_ANTICIPO') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aplicarAnticipo(<%=dataId%>)' ng-class='{hidden : !canAplicarAnticipo(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-piggy-bank'></span> </button>",
                        functionName: 'aplicarAnticipo',
                        functionDef: function(itemId) {
                            OrdenPagoFactory.get(itemId, 'ControlSaldoOP').$promise.then (function(op) {
                                if (op.importe != op.saldoImporte) {
                                    $scope.tituloModal = $filter("translate")(
                                        "APLICACION_YA_EXISTENTE"
                                    );
                                    var templateTmp = '<div class="modal-header">' +
                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                        "</div>" +
                                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                                        '<div class="modal-footer">' +
                                        '<button class="btn btn-primary" ng-click="editarAplicacion()">' +
                                        $filter("translate")("EDITAR_APLICACION") +
                                        "</button>";
                                    if (op.saldoImporte > 0) {
                                        templateTmp += '<button class="btn btn-secondary" ng-click="agregarAplicacion()">' +
                                            $filter("translate")("AGREGAR_APLICACION ") +
                                            "</button>";
                                    }
                                    templateTmp += '<button class="btn btn-warning" ng-click="cancel()">' +
                                        $filter("translate")("CANCEL ") +
                                        "</button>" +
                                        "</div>";
                                    $scope.mensajeModal = $filter("translate")("QUE_DESEA_Q");
                                    $scope.modalInstanceBorrar1 = $modal.open({
                                        template: templateTmp,
                                        scope: $scope
                                    });

                                    $scope.cancel = function() {
                                        $scope.modalInstanceBorrar1.dismiss("cancel");
                                    };

                                    $scope.editarAplicacion = function() {
                                        $state.go(
                                            "app.ordenpago.aplicaranticipo", {
                                                id: itemId,
                                                mode: "edit"
                                            }
                                        );
                                        $scope.modalInstanceBorrar1.dismiss();
                                    };

                                    $scope.agregarAplicacion = function() {
                                        $state.go(
                                            "app.ordenpago.aplicaranticipo", {
                                                id: itemId,
                                                mode: "add"
                                            }
                                        );
                                        $scope.modalInstanceBorrar1.dismiss();
                                    };
                                } else {
                                    $state.go("app.ordenpago.aplicaranticipo", { id: itemId });
                                }
                            })

                        },
                        conditionName: 'canAplicarAnticipo',
                        customAttribute: ['tipoOrdenPago', 'estado'],
                        conditionDef: function(atributos) {
                            var tipoOrdenPago = atributos[0];
                            var estado = atributos[1];
                            return ((tipoOrdenPago.clase.codigo == "clase_orden_pago_anticipo" || tipoOrdenPago.clase.codigo == "multiples_proveedores_anticipo")
                             && (estado.codigo == "opago_pagada" || estado.codigo == 'opago_confirmada_anticipo'));
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-warning btn-dt' style='margin-right: 5px;' title='" +
                            translations.IMPRIMIR_ORDEN_PAGO +
                            "' ng-class='{ hidden : !canImprimir(<%=dataCustom%>)}' ng-click='imprimir(<%=dataId%>)'> <span class='glyphicon glyphicon-export''></span> </button>",
                        functionName: "imprimir",
                        //customAttribute: ['estado', 'tipoOrdenPago', 'id'],
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            OrdenPagoFactory.get(itemId).$promise.then(function(value) {
                                $scope.tituloModal = $filter('translate')('ORDEN_PAGO_TITLE');
                                $scope.mensajeModal = $filter('translate')('ORDEN_PAGO_MESSAGE', { id: value.numero });

                                var modalInstance = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.cancel = function() {
                                    modalInstance.dismiss('cancel');
                                };

                                $scope.ok = function(id, config) {

                                    //Se recupera el nombre de la empresa de un parametro del sistema
                                    ParametrosFactory.getByCodigo("nombre_empresa").then(function(data) {
                                        $scope.nombreEmpresa = data;

                                        var filtersArr = [];
                                        filtersArr.push("id_orden_pago=" + itemId);
                                        filtersArr.push("nombre=" + $rootScope.AuthParams.accesoSistema.nombres + " " + $rootScope.AuthParams.accesoSistema.apellidos);
                                        filtersArr.push("nombre_empresa=" + $scope.nombreEmpresa.valor);

                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('ordenpago', 'pdf', filtersArr);
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                        $state.go($state.current, {}, { reload: true });
                                    });



                                    modalInstance.close();
                                };

                            });
                        },
                        conditionName: 'canImprimir',
                        customAttribute: 'estado',
                        conditionDef: function(estado) {
                            return estado.codigo == "opago_confirmada" || estado.codigo == "opago_confirmada_anticipo" ||
                                estado.codigo == "opago_pagada";
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-primary' title='" +
                            $filter("translate")("CONSULTA_DESEMBOLSOS") +
                            "' style='margin-right: 5px;' ng-click='consultaDesembolsos(<%=dataId%>)' ng-class='{ hidden : !canViewConsultaDesembolsos(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-transfer'></span> </button>",
                        view: "datatables",
                        functionName: "consultaDesembolsos",
                        functionDef: function(itemId) {
                            var defaultColumnOrder = [
                                "id",
                                "formaPago.descripcion",
                                "estado.descripcion",
                                "totalImporte",
                                "observacion"
                            ];

                            vm.optionsDTModal = {
                                resource: "desembolsoordenpago",
                                title: $filter("translate")("DESEMBOLSO_ORDENES_PAGO"),
                                factory: DesembolsoOrdenPagoFactory,
                                view: "DesembolsoOrdenPagoList",
                                defaultColumnOrder: defaultColumnOrder,
                                columns: [{
                                        data: "id",
                                        title: $filter("translate")("ID"),
                                        type: "number-range"
                                    },
                                    {
                                        data: "formaPago.descripcion",
                                        title: $filter("translate")("FORMA_PAGO")
                                    },
                                    {
                                        data: "estado.descripcion",
                                        title: $filter('translate')('STATUS'),
                                        type: 'combo',
                                        filterUrl: 'tipos/filtro/estados_desembolso_orden_pago'
                                    },
                                    {
                                        data: "totalImporte",
                                        title: $filter("translate")("TOTAL_IMPORTE_OC"),
                                        render: monedaRender
                                    },
                                    {
                                        data: "observacion",
                                        title: $filter('translate')('OBSERVACION')
                                    }
                                ],
                                hasOptions: true,
                                hideAddMenu: true,
                                hideEditMenu: true,
                                hideViewMenu: true,
                                hideRemoveMenu: true,
                                defaultOrderColumn: 0,
                                defaultOrderDir: "desc",
                                staticFilter: {
                                    search: filterFactory
                                        .and([{
                                            path: "ordenesPago.id",
                                            equals: itemId
                                        }])
                                        .value()
                                },
                                extraRowOptions: [{
                                    templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' ng-click='visualizar(<%=dataCustom%>)' ng-class='{ hidden : !canVisualizar(<%=dataId%>)}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                                    customAttribute: "id",
                                    conditionName: "canVisualizar",
                                    conditionDef: function(itemId) {
                                        var permission = AuthorizationService.hasPermission("index_desembolsoordenpago");
                                        return permission;
                                    },
                                    functionName: "visualizar",
                                    functionDef: function(desembolso) {
                                        $state.go("app.desembolsoordenpago.view", { id: desembolso });
                                    }
                                }]
                            };

                            vm.modalInstance = $modal.open({
                                templateUrl: "views/directives/datatables-custom-modal.html",
                                scope: $scope,
                                size: "lg"
                            });
                        },
                        conditionName: "canViewConsultaDesembolsos",
                        customAttribute: 'estado',
                        conditionDef: function(estado) {
                            return estado.codigo == "opago_pagada" || estado.codigo == "opago_confirmada_anticipo"
                        }
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
                        functionName: 'reenviar',
                        customAttribute: ['estado', 'enviadoAContabilidad', 'id'],
                        functionDef: function(atributo) {

                            var idOrden = atributo[2];

                            OrdenPagoFactory.get(idOrden, "OrdenPagoForm").$promise.then(function(response) {
                                checkPeriodoAdministrativo(response.fecha).then(function(checkPeriodo) {
                                    if(checkPeriodo == true){
                                        $scope.tituloModal = $filter('translate')('REENVIAR_ORDEN_PAGO_Q');
                                        $scope.mensajeModal = $filter('translate')('REENVIAR_ORDEN_PAGO_MSG');
                                        var modalInstance = $modal.open({
                                            templateUrl: 'views/directives/confirmation-modal.html',
                                            scope: $scope
                                        });

                                        $scope.cancel = function() {
                                            modalInstance.dismiss('cancel');
                                        };

                                        $scope.ok = function(id) {
                                            ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                                if (parametro.valorTipo.codigo == 'si') {
                                                    OrdenPagoFactory.reenviar(response).then(function(response) {
                                                      // if(response.enviadoAContabilidad == false){
                                                      //   notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                      // }
                                                      // if(response.data.listaArchivosLogs != null){
                                                      //   if(response.data.listaArchivosLogs.length != 0){
                                                      //     /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                      //         console.log('response.listaArchivosLogs: ');
                                                      //         console.log(response.listaArchivosLogs[i]);
                                                      //         $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                      //     }*/
                                                      //     notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                      //   }
                                                      // }
                                                      $state.go("app.ordenpago.list", { id: response.id }, { reload: true });
                                                    }, function(error) {
                                                        var msg = "";
                                                        if (error.data && error.data.length > 0 && error.data[0].message) {
                                                            msg = error.data[0].message;
                                                        } else {
                                                            msg = $filter('translate')('REENVIAR_ORDEN_PAGO_FAILED');
                                                        }

                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    });
                                                }else{
                                                    var msg = "";
                                                    msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                }
                                            });
                                            modalInstance.close();
                                        }
                                    }else{
                                        var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            });
                        },
                        conditionName: 'canReenviar',
                        conditionDef: function(atributo) {
                            var estado = atributo[0];
                            var enviadoAContabilidad = atributo[1];
                            var id = atributo[2];
                            if(enviarAContabilidad == 'si'){
                                if(estado.codigo === 'opago_anulada' || estado.codigo === 'opago_confirmada' || estado.codigo === 'opago_pagada'){
                                    if(enviadoAContabilidad === "No"){
                                        return true;
                                    }
                                }
                            }
                        }
                    },

                    // Boton para adjuntar en OP Aprobada
                    {
                        templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='Modificar OP'" +
                            " ng-click='adjuntarOP(<%=dataId%>)' ng-class='{ hidden : !canAdjuntarOP(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        functionName: "adjuntarOP",
                        functionDef: function (itemId) {
                            localStorage.setItem('habilitarAdjunto', AuthorizationService.hasPermission("adjuntar_archivo_op_aprobadas"));
                            $state.go("app.ordenpago.view", {
                                id: itemId,
                                habilitarAdjunto: AuthorizationService.hasPermission("adjuntar_archivo_op_aprobadas")
                            });
                        },
                        conditionName: "canAdjuntarOP",
                        customAttribute: ["estado"],
                        conditionDef: function (atributos) {
    
                            var estado = atributos[0];
                            
                            // Si no tiene estado PAGADA o CONFIRMADA, no mostrar boton
                            if(!(estado.codigo == 'opago_pagada' ||  estado.codigo == 'opago_confirmada')){
                                return false;
                            }
    
                            // Validacion de permiso para adjuntar
                            if(AuthorizationService.hasPermission("adjuntar_archivo_op_aprobadas")){
                                return true;
                            }else{
                                return false;
                            }
                        }
                    }
                ]
            };
            WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");

            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    search = search.split("~");
                    if (search.length > 1) {
                        /* El parámetro es un rago */

                        var isDate = search[0].split("/");

                        if (isDate.length > 1) {
                            /* Es un rago de fechas */

                            filtersArr.push({path: data, equalOrAfter: search[0]})
                            filtersArr.push({path: data, equalOrBefore: search[1]})
                        } else {
                            /* El un rago numérco */

                            filtersArr.push({path: data, greaterOrEqual: search[0]})
                            filtersArr.push({path: data, lessOrEqual: search[1]})
                        }
                    } else {
                        /* El parametro no es un rago */
                       filtersArr.push({path: data, like: search[0]})
                    }
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }

            /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
            var checkPeriodoAdministrativo = function(fechaComprobante){
                return $q(function(resolve, reject) {

                    var params = {};
                    var fecha;

                    if(fechaComprobante == null){
                        fecha = new Date();
                    }else{
                        fecha = fechaComprobante;
                    }

                    params.search = filterFactory
                    .and([{
                            path: "estado.codigo",
                            equals: "per_adm_abierto"
                        },
                        {
                            path: "fechaDesde",
                            equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                        },
                        {
                            path: "fechaHasta",
                            equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                        }
                    ])
                    .value();

                    PeriodoAdministrativoFactory.all(params).$promise.then(function(responsePeriodo) {
                        if(responsePeriodo.length>0){
                            resolve(true);
                        }else{
                            resolve(false);
                        }
                    })
                });
            }

            $scope.addNewOP = function() {
                $scope.tituloModal = $filter('translate')('NEW_ORDEN_PAGO');
                var modalInstance = $modal.open({
                    templateUrl: 'views/directives/op-rrhh-modal.html',
                    scope: $scope
                });

                $scope.op = {};
                $scope.adelanto = {};
                $scope.vacaciones = {};
                $scope.salario = {};
                // TODO cargar solo cuando elige
                var paramsAdelanto = {};
                paramsAdelanto.search = filterFactory.single({
                    path: 'estado.codigo',
                    like: '%aprobado'
                }).value();
                var paramsVacaciones = {};
                paramsVacaciones.search = filterFactory.and([{
                    path: 'estado.codigo',
                    like: '%aprobado'
                }, {
                    path: 'generarDesembolso',
                    like: 'true'
                }]).value();

                var paramsSalario = {};
                paramsSalario.search = filterFactory.and([{
                    path: 'estado.codigo',
                    like: '%aprobado'
                }]).value();
                //$scope.adelantosList = adelantoFactory.all(paramsAdelanto);
                //$scope.vacacionesList = solicitudVacacionesFactory.all(paramsVacaciones);
                //$scope.salarios = salarioFactory.all(paramsSalario);

                var paramsTipo = {};
                paramsTipo.search = filterFactory.single({
                    path: 'activo',
                    equals: true
                }).value();
                // solo OPs de tipo tesoreria (salario, adelanto y vacaciones)
                /*var tiposOP = TipoOrdenPagoFactory.all(paramsTipo);
                tiposOP.$promise.then(function(response) {
                    $scope.tiposOrdenPago = _.filter(response, function(filter) {
                        return filter.clase.codigo == "clase_orden_pago_vacaciones" ||
                            filter.clase.codigo == "clase_orden_pago_adelanto" ||
                            filter.clase.codigo == "clase_orden_pago_salario";
                    });
                });*/

                $scope.searchAdelantoFilter = function(criteria) {
                    return function(item) {
                        if (!criteria) {
                            return true;
                        }
                        return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1;
                    }
                };

                $scope.cancel = function() {
                    modalInstance.dismiss('cancel');
                };

                $scope.ok = function(oc) {
                    if (!oc.tipoOrdenPago) {
                        console.error("No hay tipoOP");
                        return;
                    }
                    var idReferencia;
                    if (oc.tipoOrdenPago.clase.codigo == 'clase_orden_pago_adelanto') {
                        idReferencia = oc.adelanto.id;
                    } else if (oc.tipoOrdenPago.clase.codigo == 'clase_orden_pago_vacaciones') {
                        idReferencia = oc.vacaciones.id;
                    } else if (oc.tipoOrdenPago.clase.codigo == 'clase_orden_pago_salario') {
                        idReferencia = oc.salario.id;
                    }
                    var locacionId = $rootScope.AuthParams.locacionSeleccionada.id;
                    OrdenPagoFactory.createOPRecursosHumanos(idReferencia, oc.tipoOrdenPago.id, locacionId).then(function(data) {
                        modalInstance.close();
                    }, function(error) {
                        notify({ message: error.data.error, classes: 'alert-danger', position: 'right' });
                    });
                };
            }
        });
    }]);

angular
        .module("qualita.tesoreria")
        .controller("OrdenPagoFormCtrl", OrdenPagoFormCtrl);

OrdenPagoFormCtrl.$inject = [
    "$rootScope",
    "$scope",
    "OrdenPagoFactory",
    "TipoOrdenPagoFactory",
    "ordenPagoPrepService",
    "TesoreriaLangFactory",
    "$state",
    "ProveedoresFactory",
    "CotizacionMonedaFactory",
    "TiposTipoFactory",
    "FormaPagoFactory",
    "ParametrosFactory",
    "ModelTrimmer",
    "formFactory",
    "$location",
    "notify",
    "ComprobanteFactory",
    "ProyectoFactory",
    "filterFactory",
    "observeOnScope",
    "$q",
    "$translate",
    "CuentaContableFactory",
    "PlanillaFondoFijoFactory",
    "WatchLocacionChangeFactory",
    "UtilFactory",
    "TipoRetencionFactory",
    "$filter",
    "DespachoFactory",
    "PlanPagosDetalleFactory",
    "ComprobanteRetencionFactory",
    "PeriodoAdministrativoFactory",
    "$stateParams",
    "baseurl",
    "AuthorizationService"
];

function OrdenPagoFormCtrl(
        $rootScope,
        $scope,
        OrdenPagoFactory,
        TipoOrdenPagoFactory,
        ordenPagoPrepService,
        TesoreriaLangFactory,
        $state,
        ProveedoresFactory,
        CotizacionMonedaFactory,
        TiposTipoFactory,
        FormaPagoFactory,
        ParametrosFactory,
        ModelTrimmer,
        formFactory,
        $location,
        notify,
        ComprobanteFactory,
        ProyectoFactory,
        filterFactory,
        observeOnScope,
        $q,
        $translate,
        CuentaContableFactory,
        PlanillaFondoFijoFactory,
        WatchLocacionChangeFactory,
        UtilFactory,
        TipoRetencionFactory,
        $filter,
        DespachoFactory,
        PlanPagosDetalleFactory,
        ComprobanteRetencionFactory,
        PeriodoAdministrativoFactory,
        $stateParams,
        baseurl,
        AuthorizationService
        ) {
    var vm = this;
    vm.cancel = cancel;
    vm.submit = submit;
    vm.tipoOrdenPagoSelected = tipoOrdenPagoSelected;
    vm.clearFiltrosComprobantes = clearFiltrosComprobantes;
    vm.fechaDesdeChanged = fechaDesdeChanged;
    vm.fechaHastaChanged = fechaHastaChanged;
    vm.fechaVencimientoDesdeChanged = fechaVencimientoDesdeChanged;
    vm.fechaVencimientoHastaChanged = fechaVencimientoHastaChanged;
    vm.tipoComprobanteChanged = tipoComprobanteChanged;
    vm.monedaFilterChanged = monedaFilterChanged;
    vm.proveedorSelected = proveedorSelected;
    vm.planillaFondoFijoSeleted = planillaFondoFijoSeleted;
    vm.monedaSelected = monedaSelected;
    vm.removeItemFromArray = removeItemFromArray;
    vm.deleteDetalle = deleteDetalle;
    vm.searchPlanillaFondoFijo = searchPlanillaFondoFijo;
    vm.onChangeCalcularRentecion = onChangeCalcularRentecion;
    vm.importePagarDetalleChanged = importePagarDetalleChanged;
    vm.calcularMontoTotal = calcularMontoTotal;
    vm.changeImporte = changeImporte;
    vm.monedaChanged = monedaChanged;
    vm.tipoCambioChanged = tipoCambioChanged;
    vm.fechaChanged = fechaChanged;
    vm.descargarAdjunto = descargarAdjunto;

    vm.puedeVerAdjunto = false;
    vm.puedeAdjuntar = false;
    vm.puedeEliminar = false;
    vm.puedeAjuntarEnAprobadas = false;

    vm.puedeVerAdjunto = AuthorizationService.hasPermission("ver_adjunto_op");
    vm.puedeAdjuntar = AuthorizationService.hasPermission("adjuntar_archivo_op");
    vm.puedeEliminar = AuthorizationService.hasPermission("eliminar_archivo_op");

    function fechaChanged(){
        if (vm.ordenPago.moneda.codigo != "guaranies"){
            CotizacionMonedaFactory.getMonedaLocal().then(function(monedaLocal){
                CotizacionMonedaFactory.getCotizacionDiaOr(
                    vm.ordenPago.moneda.id,
                    monedaLocal.valorTipo.id,
                    vm.ordenPago.fecha
                    ).then(function (valor) {
                        if (valor != undefined){
                            vm.ordenPago.tipoCambio = valor;
                            tipoCambioChanged();
                        }else{
                            notify({message: $filter("translate")("ERROR_TIPO_CAMBIO_FECHA"), classes: "alert-warning", position: "right"});
                            vm.ordenPago.tipoCambio = null;
                            vm.ordenPago.tipoCambioView = "";
                        }
                });
            });
        }
    }

    function tipoCambioChanged(){
        if (vm.ordenPago.tipoCambio != undefined && vm.ordenPago.tipoCambio != 0){
            _.forEach(vm.ordenPago.ordenPagoDetalles, function (detalle) {
                if (detalle.comprobante.moneda1.codigo === "guaranies"){

                    detalle.tipoCambio = vm.ordenPago.tipoCambio;
                    var saldo = detalle.cuota ? detalle.cuota.saldo : detalle.comprobante.saldo;

                    cantDecimales = 2;
                    operacion = "div";

                    detalle.saldoConvertido = Math.round((saldo / detalle.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                    if (!vm.view) {
                        detalle.importePagar = detalle.saldoConvertido - Math.round(((detalle.importeAretener + detalle.montoRetenido) / detalle.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                    }

                    detalle.importePagar = Math.round(detalle.importePagar * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                }
            })
            calcularMontoTotal();
        }
    }

    $rootScope.isProcessing = false;

    vm.deshabilitarMoneda = false;
    vm.disableCheckRetencion = false;

    function monedaChanged(){
        if (vm.ordenPago.moneda.codigo != "guaranies"){
            if (!vm.ordenPago.fecha){
                vm.ordenPago.fecha = new Date();
            }
            CotizacionMonedaFactory.getMonedaLocal().then(function(monedaLocal){
                CotizacionMonedaFactory.getCotizacionDiaOr(
                    vm.ordenPago.moneda.id,
                    monedaLocal.valorTipo.id,
                    vm.ordenPago.fecha
                    ).then(function (valor) {
                        if (valor != undefined){
                            vm.ordenPago.tipoCambio = valor;
                            tipoCambioChanged();
                        }else{
                            vm.ordenPago.tipoCambio = null;
                            vm.ordenPago.tipoCambioView = "";
                            notify({message: $filter("translate")("ERROR_TIPO_CAMBIO_FECHA"), classes: "alert-warning", position: "right"});

                        }

                });
            });
        }else{
            vm.ordenPago.tipoCambio = null;
            vm.ordenPago.tipoCambioView = "";
        }
    }

    var filtrarAgregados = function () {
        var filtrosRemover = [];
        _.forEach(vm.opcionesComprobantesDt.staticFilter.search.filters, function (filtro) {
            if (filtro.path === "id") {
                filtrosRemover.push(filtro);
            }
        });
        _.forEach(filtrosRemover, function (filtro) {
            _.remove(vm.opcionesComprobantesDt.staticFilter.search.filters, function (filtro2) {
                return filtro2 === filtro;
            });
        });

        var filtrosRemover2 = [];
        _.forEach(vm.opcionesPlanPagoDetalleDt.staticFilter.search.filters, function (filtro) {
            if (filtro.path === "id") {
                filtrosRemover2.push(filtro);
            }
        });
        _.forEach(filtrosRemover2, function (filtro) {
            _.remove(vm.opcionesPlanPagoDetalleDt.staticFilter.search.filters, function (filtro2) {
                return filtro2 === filtro;
            });
        });

        _.forEach(vm.ordenPago.ordenPagoDetalles, function (detalle) {
            var newFilter
            detalle.cuota ? newFilter = {path: "id", notEquals: detalle.cuota.id} : newFilter = {path: "id", notEquals: detalle.comprobante.id}
            vm.opcionesComprobantesDt.staticFilter.search.filters.push(newFilter);
            vm.opcionesPlanPagoDetalleDt.staticFilter.search.filters.push(newFilter);
        });
    };

    var removeFiltrarAgregados = function () {
        var bandera1 = true;
        _.forEach(vm.ordenPago.ordenPagoDetalles, function (detalle) {
            bandera1 = false;
            var newFilter = {path: "id", notEquals: detalle.comprobante.id}
            var bandera = true;
            _.forEach(vm.opcionesComprobantesDt.staticFilter.search.filters, function (filtro) {
                if (filtro.notEquals && filtro.notEquals === detalle.comprobante.id) {
                    bandera = false;
                }
            });
            if (bandera) {
                vm.opcionesComprobantesDt.staticFilter.search.filters.push(newFilter);
                vm.opcionesPlanPagoDetalleDt.staticFilter.search.filters.push(newFilter);
            }
        });
        if (bandera1) {

        }
    };

    var monedaRender = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.moneda1.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda1.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda1.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda1.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda1.codigo === "guaranies") {
                moneda = "Gs. ";
                data = parseFloat(data).toFixed(0);
            }
            return (
                    moneda +
                    data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
        } else
            return "";
    };

    var monedaCuotaRender = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.planPagos.comprobante.moneda1.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.planPagos.comprobante.moneda1.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.planPagos.comprobante.moneda1.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.planPagos.comprobante.moneda1.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.planPagos.comprobante.moneda1.codigo === "guaranies") {
                moneda = "Gs. ";
                data = parseFloat(data).toFixed(0);
            }
            return (
                    moneda +
                    data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
        } else
            return "";
    };

    function removeItemFromArray(elemento, arreglo) {
        filtrarAgregados();
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
    }

    activate();

    function activate() {
        vm.mode = $stateParams.mode ? $stateParams.mode : "normal";
        WatchLocacionChangeFactory.killAllWatchers();
        vm.search = {};
        vm.opcionesComprobantesDt = {};
        vm.opcionesPlanPagoDetalleDt = {};
        vm.ordenPago = {};
        ParametrosFactory.getByCodigo('descontar_nc_calculo_retencion').then(function(parametro){
            if (parametro.valorTipo.codigo === "si"){
                vm.descontarNcRetencion = true;
            }else{
                vm.descontarNcRetencion = false;
            }
        });

        ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
          vm.proyectoObligatorio = r.valorTipo.codigo == "si";
        });
        vm.proyectos = ProyectoFactory.all("", "OrdenPagoForm");

        ParametrosFactory.getByCodigo("monto_minimo_retencion").then(
            function (parametro) {
                vm.montoMinimoRetencion = parametro.valor;
            });

        /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
        vm.staticFilterProv = [{path: "activo", equals: true}];
        vm.proveedoresFactory = ProveedoresFactory;

        var paramsTipo = {};
        paramsTipo.search = filterFactory.single({
            path: "activo",
            equals: true
        }).value();
        paramsTipo.view = 'BaseList';
        // solo OPs de tipo tesoreria (normal, fondo fijo, cuenta contable, anticipo)
        var tiposOP = TipoOrdenPagoFactory.all(paramsTipo);
        tiposOP.$promise.then(function (response) {
            vm.tiposOrdenPago = _.filter(response, function (filter) {
                return (
                        filter.clase.codigo == "clase_orden_pago_normal" ||
                        filter.clase.codigo == "clase_orden_pago_anticipo" ||
                        filter.clase.codigo == "clase_orden_pago_fondo_fijo" ||
                        filter.clase.codigo == "clase_orden_pago_cuenta_contable" ||
                        filter.clase.codigo == "multiples_proveedores" ||
                        filter.clase.codigo == "multiples_proveedores_anticipo"
                        );
            });
        });

        var paramsProv = {};
        paramsProv.search = filterFactory
                .single({
                    path: "activo",
                    equals: true
                })
                .value();
        vm.proveedores = ProveedoresFactory.all(paramsProv);

        var tipoRetencionFilter = {
            search: filterFactory
                    .and([
                        {
                            path: "utilizarEnOrdenPago",
                            equals: true
                        }
                    ])
                    .value()
        };
        vm.retencionOP = null;

        vm.monedaLocal = CotizacionMonedaFactory.getMonedaLocal();
        vm.calcularRetencion = ParametrosFactory.getByCodigo("calcular_retencion");
        vm.modificarCambio = ParametrosFactory.getByCodigo("calcular_retencion");

        vm.tiposRetencion = TipoRetencionFactory.all(tipoRetencionFilter).$promise.then(function(response){
            //console.log(response);
            if (response.length > 0){
                vm.retencionOP = response[0];
            }else{
                vm.calcularRetencion.then(function(response){
                    if (response.valor === "Sí"){
                        notify({message: $filter("translate")("NO_EXISTE_TIPO_RETENCION"), classes: "alert-warning", position: "right"});
                    }
                });

            }
        });

        var paramsDespacho = {};
        paramsDespacho.view = "base";
        vm.despachos = DespachoFactory.all(paramsDespacho);

        ParametrosFactory.getByCodigo("habilitar_op_fecha_pasada").then(function (r) {
            vm.permitirOPFechaPasada = r.valorTipo.codigo == "si";
        });
        ParametrosFactory.getByCodigo("habilitar_op_fecha_futura").then(function (r) {
            vm.permitirOPFechaFutura = r.valorTipo.codigo == "si";
        });

        vm.monedas = TiposTipoFactory.get("monedas");
        vm.estados = TiposTipoFactory.get("estado_orden_pago");

        var filterFormaPago = {};
        filterFormaPago.search = filterFactory.single({
            path: 'clase.codigo',
            notEquals: "forma_pago_referencia"
        }).value();
        vm.formasPago = FormaPagoFactory.all(filterFormaPago);

        vm.tiposComprobante = [{"id": 1, "descripcion": "Sin Cuotas", "generarCuotas": false}, {"id": 2, "descripcion": "Con Cuotas", "generarCuotas": true}]

        vm.cuentasContables = CuentaContableFactory.all();
        vm.OrdenPagoForm = $scope.OrdenPagoForm;

        vm.ordenPago = ordenPagoPrepService;
        getPlanillasFondoFijo();
        getPermisoCalcularRetencion();
        TesoreriaLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;

            vm.staticFilterComprobantes = [];
            vm.staticFilterPlanPagoDetalle = [];
            vm.staticFilterComprobantes.push({
                path: "estado.codigo",
                equals: "comp_aprobado"
            });
            vm.staticFilterComprobantes.push({
                path: "saldo",
                greaterThan: 0.0
            });
            vm.staticFilterComprobantes.push({
                path: "tipo.clase.codigo",
                notEquals: "nota_credito"
            });
            vm.staticFilterComprobantes.push({
                path: "tipo.estadoCuenta",
                equals: true
            });

            vm.staticFilterPlanPagoDetalle.push({
                path: "planPagos.comprobante.estado.codigo",
                equals: "comp_aprobado"
            });
            vm.staticFilterPlanPagoDetalle.push({
                path: "saldo",
                greaterThan: 0.0
            });
            vm.staticFilterPlanPagoDetalle.push({
                path: "planPagos.comprobante.tipo.clase.codigo",
                notEquals: "nota_credito"
            });
            vm.staticFilterPlanPagoDetalle.push({
                path: "planPagos.comprobante.tipo.estadoCuenta",
                equals: true
            });
            vm.staticFilterComprobantes.push({
                path: "tipo.generarCuotas",
                equals: false
            });

            vm.staticFilterComprobantes.push({
                path: 'hasPlanillaFondoFijoValida',
                equals: false
            })
           /*  vm.staticFilterComprobantes.push({
                path: 'planillaFondoFijoDetalles.id',
                isNull: 'true'
            }); */

            if (vm.ordenPago != undefined && vm.ordenPago.proveedor != undefined){
                vm.staticFilterComprobantes.push({
                    path: 'proveedor.id',
                    equals: vm.ordenPago.proveedor.id
                 });

                 vm.staticFilterPlanPagoDetalle.push({
                    path: "planPagos.comprobante.proveedor.id",
                    equals: vm.ordenPago.proveedor.id
                });
            }



            if (vm.watcherComprob) {
                vm.watcherComprob();
            }

            var defaultColumnOrder = [
                "fecha",
                "numero",
                "proveedor.nombre",
                "montoTotal",
                "saldo",
                "importacion.codigo",
                "compra.codigo"
            ];
            WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcher
                    ) {
                vm.watcherComprob = watcher;
                //vm.ordenesCompra = OrdenesCompraFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterOC));
                var staticFilterFinal = {
                    search: filterFactory.and(vm.staticFilterComprobantes).value()
                };
                var staticFilter = UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        staticFilterFinal
                        );

                var staticFilterPlanPagoDetalleFinal = {
                    search: filterFactory.and(vm.staticFilterPlanPagoDetalle).value()
                };
                var staticFilterPlanPagoDetalle = UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        staticFilterPlanPagoDetalleFinal
                        );
                vm.opcionesComprobantesDt = {
                    staticFilter: staticFilter,
                    view: "ComprobanteList",
                    resource: "comprobante",
                    title: "Comprobante",
                    factory: ComprobanteFactory,
                    defaultColumnOrder: defaultColumnOrder,
                    columns: [
                        {
                            data: "fecha",
                            title: $filter("translate")("DATE"),
                            renderWith: "dateRender",
                            dateFormat: "DD/MM/YYYY",
                            class: "dt-center",
                            type: "date-range"
                        },
                        {
                            data: "id",
                            title: $filter("translate")("CODE"),
                            class: "dt-center",
                            type: "number-range"
                        },
                        {
                            data: "numero",
                            title: $filter("translate")("NUMBER"),
                            class: "dt-center"
                        },
                        {
                            data: "proveedor.nombre",
                            title: $filter("translate")("PROVEEDOR_INDEX"),
                            class: "dt-center"
                        },
                        {
                            data: "estado.descripcion",
                            title: $filter("translate")("STATUS"),
                            class: "dt-center"
                        },
                        {
                            data: "montoTotal",
                            title: $filter("translate")("TOTAL_AMOUNT"),
                            class: "dt-right",
                            render: monedaRender,
                            type: "number-range"
                        },
                        {
                            data: "saldo",
                            title: $filter("translate")("SALDO"),
                            class: "dt-right",
                            render: monedaRender,
                            type: "number-range"
                        },
                        {
                            data: "compra.codigo",
                            title: "Cod Compra",
                            renderWith: "emptyRender",
                            class: "dt-center"
                        },
                        {
                            data: "importacion.codigo",
                            title: $filter("translate")("CODIGO_IMPORTACION"),
                            renderWith: "emptyRender",
                            class: "dt-center"
                        }

                    ],
                    hasOptions: false,
                    defaultOrderColumn: 1,
                    defaultOrderDir: "desc"
                };

                var defaultColumnOrderPlanPagoDetalle = [
                    "id",
                    "numero",
                    "planPagos.comprobante.proveedor.nombre",
                    "monto",
                    "saldo",
                    "fechaVencimiento",
                    "planPagos.comprobante.numero",
                    "planPagos.comprobante.importacion.codigo",
                    "planPagos.comprobante.proveedor.nombre",
                    "planPagos.comprobante.compra.codigo"
                ];

                vm.opcionesPlanPagoDetalleDt = {
                    staticFilter: staticFilterPlanPagoDetalle,
                    resource: "planpagosdetalle",
                    title: $filter("translate")("CUOTAS_COMPROBANTE"),
                    view: "PlanPagoDetalleList",
                    factory: PlanPagosDetalleFactory,
                    defaultColumnOrder: defaultColumnOrderPlanPagoDetalle,
                    columns: [
                        {data: "id", title: $filter("translate")("CODE"), visible: false},
                        {data: "numero", title: $filter("translate")("QUOTA_NUMBER")},
                        {data: "planPagos.comprobante.numero", title: $filter("translate")("NUMERO_COMPROBANTE")},
                        {data: "planPagos.comprobante.proveedor.nombre", title: $filter("translate")("PROVEEDOR_INDEX")},
                        {data: "tipo.descripcion", title: $filter("translate")("TYPE")},
                        {
                            data: "monto",
                            title: $filter("translate")("MONTO"),
                            renderWith: monedaCuotaRender,
                            type: "number-range"
                        }, //actualizar
                        {
                            data: "saldo",
                            title: $filter("translate")("SALDO"),
                            renderWith: monedaCuotaRender,
                            type: "number-range"
                        },
                        {
                            data: "estado.descripcion",
                            title: $filter("translate")("STATUS")
                        },
                        {
                            data: "fechaVencimiento",
                            title: $filter("translate")("FECHA_VENCIMIENTO"),
                            renderWith: "dateRender",
                            dateFormat: "DD/MM/YYYY",
                            class: "dt-center",
                            type: "date-range"
                        },
                        {
                            data: "planPagos.comprobante.compra.codigo",
                            title: "Cod Compra",
                            renderWith: "emptyRender",
                            class: "dt-center"
                        },
                        {
                            data: "planPagos.comprobante.importacion.codigo",
                            title: $filter("translate")("CODIGO_IMPORTACION"),
                            renderWith: "emptyRender",
                            class: "dt-center"
                        }
                    ],
                    hasOptions: false,
                    defaultOrderColumn: 1,
                    defaultOrderDir: "desc"
                };
                if ($state.is("app.ordenpago.new")) {
                    activateNew();
                } else if ($state.is("app.ordenpago.edit")) {
                    activateEdit();
                } else if ($state.is("app.ordenpago.view")) {
                    activateView();
                } else if ($state.is("app.ordenpago.aplicaranticipo")) {
                    activateAnticipo();
                }
                $rootScope.isProcessing = false;
                vm.submited = false;

            }, "locacion.id");


        });

        function activateEdit() {
            vm.disableCheckRetencion = true;
            vm.isEdit = true;
            vm.title = $filter("translate")("EDIT_ORDEN_PAGO");
            vm.ordenPago = ordenPagoPrepService;
            vm.uploadOptions2 = {
                imageOnly: false
            };

            tipoOrdenPagoSelected();
            validadParametroMoneda(); // Verificar si se permiten diferentes monedas en la OP.
            if (vm.ordenPago.moneda.codigo == "guaranies") {
                vm.ordenPago.importe = parseFloat(vm.ordenPago.importe.toFixed(0));
                if (vm.ordenPago.importeRetencion) {
                    vm.ordenPago.importeRetencion = parseFloat(vm.ordenPago.importeRetencion.toFixed(0));
                } else {
                    vm.ordenPago.importeRetencion = 0;
                }
            } else {
                vm.ordenPago.importe = parseFloat(vm.ordenPago.importe.toFixed(4));
                if (vm.ordenPago.importeRetencion) {
                    vm.ordenPago.importeRetencion = parseFloat(vm.ordenPago.importeRetencion.toFixed(4));
                } else {
                    vm.ordenPago.importeRetencion = 0;
                }
            }
            _.forEach(vm.ordenPago.ordenPagoDetalles, function (detalle) {
                var saldo = detalle.cuota != undefined ? detalle.cuota.saldo:detalle.comprobante.saldo;
                if (detalle.retencion != null){
                    detalle.tieneRetencion = true;
                }
                if (vm.ordenPago.moneda.codigo != detalle.comprobante.moneda1.codigo){
                    detalle.monedaHastaTranslation = {moneda: vm.ordenPago.moneda.descripcion};
                    detalle.monedaDesdeTranslation = {moneda: detalle.comprobante.moneda1.descripcion};
                }
                if (detalle.comprobante.moneda1.codigo == "guaranies") {
                    if (detalle.comprobante.saldo)
                        detalle.comprobante.saldo = Math.round(detalle.comprobante.saldo * 100) / 100;;
                        if (vm.ordenPago.moneda.codigo === "dolares"){
                            detalle.saldoConvertido = Math.round((saldo / detalle.tipoCambio) * Math.pow(10, 2)) / Math.pow(10, 2);
                        }

                    if (detalle.comprobante.montoTotal)
                        detalle.comprobante.montoTotal = parseFloat(detalle.comprobante.montoTotal.toFixed(0));
                    if (detalle.comprobante.monto)
                        detalle.comprobante.monto = parseFloat(detalle.comprobante.monto.toFixed(0));
                }
                else {
                    if (detalle.comprobante.saldo)
                        detalle.comprobante.saldo = parseFloat(detalle.comprobante.saldo.toFixed(4));
                        if (vm.ordenPago.moneda.codigo === "guaranies"){
                            detalle.saldoConvertido = Math.round((saldo * detalle.tipoCambio) * Math.pow(10, 0)) / Math.pow(10, 0);
                        }
                    if (detalle.comprobante.montoTotal)
                        detalle.comprobante.montoTotal = parseFloat(detalle.comprobante.montoTotal.toFixed(4));
                    if (detalle.comprobante.monto)
                        detalle.comprobante.monto = parseFloat(detalle.comprobante.monto.toFixed(4));

                }
            });
            vm.ordenPago.fecha = new Date(vm.ordenPago.fecha);

            vm.hasPermisoAdjuntar = AuthorizationService.hasPermission("adjuntar_archivo_op_aprobadas");

            addProveedorFilter();
            activateOldDetails();
        }

        function activateView() {
            vm.view = true;
            vm.title = $filter("translate")("VIEW_ORDEN_PAGO");
            vm.ordenPago = ordenPagoPrepService;
            vm.ordenPago.fecha = new Date(vm.ordenPago.fecha);
            vm.uploadOptions2 = {
                imageOnly: false
            };
            if (vm.ordenPago.ordenPagoDetalles.length > 0) {
                $scope.array = [];
                _.forEach(vm.ordenPago.ordenPagoDetalles, function (value) {
                    var numRedondo = redondear(value.comprobante.saldo, value.comprobante.moneda1.codigo);
                    var saldo = value.cuota != undefined ? value.cuota.saldo:value.comprobante.saldo;
                    if (value.retencion != null){
                        value.tieneRetencion = true;
                    }
                    //acá agregar la conversion
                    if (vm.ordenPago.moneda.codigo != value.comprobante.moneda1.codigo){
                        value.monedaHastaTranslation = {moneda: vm.ordenPago.moneda.descripcion};
                        value.monedaDesdeTranslation = {moneda: value.comprobante.moneda1.descripcion};
                    }
                    value.comprobante.saldo = numRedondo;
                    if (value.cuota != null){
                        value.cuota.saldo = redondear(value.cuota.saldo, value.comprobante.moneda1.codigo);
                        value.cuota.monto = redondear(value.cuota.monto, value.comprobante.moneda1.codigo);
                    }
                    $scope.array.push(value);
                });
                vm.ordenPago.ordenPagoDetalles = [];
                vm.ordenPago.ordenPagoDetalles = ($scope.array);

            }
            if (vm.ordenPago.moneda.codigo == "guaranies") {
                vm.ordenPago.importe = parseFloat(vm.ordenPago.importe.toFixed(0));
                if (vm.ordenPago.importeRetencion) {
                    vm.ordenPago.importeRetencion = parseFloat(vm.ordenPago.importeRetencion.toFixed(0));
                } else {
                    vm.ordenPago.importeRetencion = 0;
                }
            } else {
                vm.ordenPago.importe = parseFloat(vm.ordenPago.importe.toFixed(4));
                if (vm.ordenPago.importeRetencion) {
                    vm.ordenPago.importeRetencion = parseFloat(vm.ordenPago.importeRetencion.toFixed(4));
                } else {
                    vm.ordenPago.importeRetencion = 0;
                }
            }
            _.forEach(vm.ordenPago.ordenPagoDetalles, function (detalle) {
                if (detalle.comprobante.moneda1.codigo == "guaranies") {
                    if (detalle.comprobante.saldo)
                        detalle.comprobante.saldo = parseFloat(detalle.comprobante.saldo.toFixed(0));
                    if (detalle.comprobante.montoTotal)
                        detalle.comprobante.montoTotal = parseFloat(detalle.comprobante.montoTotal.toFixed(0));
                    if (detalle.comprobante.monto)
                        detalle.comprobante.monto = parseFloat(detalle.comprobante.monto.toFixed(0));
                }
                else {
                    if (detalle.comprobante.saldo)
                        detalle.comprobante.saldo = parseFloat(detalle.comprobante.saldo.toFixed(4));
                    if (detalle.comprobante.montoTotal)
                        detalle.comprobante.montoTotal = parseFloat(detalle.comprobante.montoTotal.toFixed(4));
                    if (detalle.comprobante.monto)
                        detalle.comprobante.monto = parseFloat(detalle.comprobante.monto.toFixed(4));

                }
            });

            vm.view = true;
            vm.hasPermisoAdjuntar = !!localStorage.getItem('habilitarAdjunto');
            localStorage.removeItem("habilitarAdjunto");

            tipoOrdenPagoSelected();
            activateOldDetails();
        }

        var redondear = function (data, moneda) {
            if (!data) {
                data = 0;
            }
            if (moneda === 'dolares') {
                data = parseFloat(data).toFixed(2);
            } else if (moneda === 'pesos') {
                data = parseFloat(data).toFixed(2);
            } else if (moneda === 'real') {
                data = parseFloat(data).toFixed(2);
            } else if (moneda === 'euro') {
                data = parseFloat(data).toFixed(2);
            } else if (moneda === 'guaranies') {
                data = parseFloat(data).toFixed(0);
            }
            return parseFloat(data);//.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        };

        function activateAnticipo() {
            vm.title = $filter("translate")("APLICAR_ANTICIPO");
            vm.ordenPago = ordenPagoPrepService;
            vm.aplicarAnticipo = true;
            var ordenPagoTemp = vm.ordenPago.formaPago;
            vm.uploadOptions2 = {
              imageOnly: false
            };
            tipoOrdenPagoSelected();
            vm.ordenPago.formaPago = ordenPagoTemp;
            addProveedorFilter();
            activateOldDetails();

            /* Cuando es anticipo no se permiten operaciones multimoneda por complicaciones con
             manejo de estado de cuenta de proveedores */
            vm.search.moneda = vm.ordenPago.moneda;
            monedaFilterChanged();

            if (vm.ordenPago.moneda.codigo == "guaranies") {
                vm.ordenPago.saldoImporte = parseFloat(vm.ordenPago.saldoImporte.toFixed(0));
                vm.montoTotalAplicado = parseFloat(((vm.ordenPago.importe + vm.ordenPago.importeRetencion) - vm.ordenPago.saldoImporte).toFixed(0));
            } else {
                vm.ordenPago.saldoImporte = parseFloat(vm.ordenPago.saldoImporte.toFixed(4));
                vm.montoTotalAplicado = parseFloat(((vm.ordenPago.importe + vm.ordenPago.importeRetencion) - vm.ordenPago.saldoImporte).toFixed(4));
            }

        }

        vm.factoryComprobantes = ComprobanteFactory;
        vm.factoryPlanPagoDetalle = PlanPagosDetalleFactory;

        var obs1 = observeOnScope($scope, "vm.comprobantesFiltrados")
                .filter(function (data) {
                    return data.newValue;
                })
                .map(function (data) {
                    return data.newValue;
                });

        obs1.subscribe(function (change) {
            if (!vm.ordenPago.proveedor) {
                notify({
                    message: $filter("translate")("PROVEEDOR_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else if (change.length <= 0) {
                notify({
                    message: $filter("translate")("COMPROBANTE_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else if (!vm.search.tipoComprobante) {
                notify({
                    message: $filter("translate")("TIPO_COMPROBANTE_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else {
                vm.ordenPago.ordenPagoDetalles = _.reduce(
                        change,
                        function (response, val) {
                            val.$promise.then(storeComprobante(response));
                            return response;
                        }, vm.ordenPago.ordenPagoDetalles || []
                        );
            }
        });

        var obs1 = observeOnScope($scope, "vm.cuotasFiltradas")
                .filter(function (data) {
                    return data.newValue;
                })
                .map(function (data) {
                    return data.newValue;
                });

        obs1.subscribe(function (change) {
            if (!vm.ordenPago.proveedor) {
                notify({
                    message: $filter("translate")("PROVEEDOR_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else if (change.length <= 0) {
                notify({
                    message: $filter("translate")("COMPROBANTE_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else if (!vm.search.tipoComprobante) {
                notify({
                    message: $filter("translate")("TIPO_COMPROBANTE_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else {
                /* ordenar */
                var cont = 0; var cantidad = change.length;
                vm.listaOrdenadaCuotas = [];
                var listaNoCuotas = [];
                /* Excepto los que no tienen cuotas */
                _.forEach(vm.ordenPago.ordenPagoDetalles, function(anterior){
                    if (anterior.cuota != undefined){
                        vm.listaOrdenadaCuotas.push(anterior.cuota);
                    }else{
                        listaNoCuotas.push(anterior);
                    }
                })
                vm.listaComprobantesConRetencion = [];
                vm.ordenPago.ordenPagoDetalles = [];
                _.forEach(listaNoCuotas, function(comprobante){
                    vm.ordenPago.ordenPagoDetalles.push(comprobante);
                })
                _.forEach(change, function(detalle) {
                    detalle.$promise.then(function(response){
                        vm.listaOrdenadaCuotas.push(response);
                        cont++;
                        if (cont === cantidad){
                            var numeroComprobanteJ, numeroComprobanteJ1;
                            for (var i=0; i<vm.listaOrdenadaCuotas.length; i++){
                                for (var j=vm.listaOrdenadaCuotas.length-1; j>i; j--){
                                    numeroComprobanteJ = vm.listaOrdenadaCuotas[j].planPagos.comprobante.numero;
                                    numeroComprobanteJ1 = vm.listaOrdenadaCuotas[j-1].planPagos.comprobante.numero;
                                    if (numeroComprobanteJ < numeroComprobanteJ1 ||
                                        ((numeroComprobanteJ === numeroComprobanteJ1 && vm.listaOrdenadaCuotas[j].numero < vm.listaOrdenadaCuotas[j-1].numero))){
                                        var aux = vm.listaOrdenadaCuotas[j];
                                        vm.listaOrdenadaCuotas[j] = vm.listaOrdenadaCuotas[j-1];
                                        vm.listaOrdenadaCuotas[j-1] = aux;
                                    }
                                }
                            }

                            vm.ordenPago.ordenPagoDetalles = _.reduce(
                                vm.listaOrdenadaCuotas,
                                function (response, val) {
                                    val.$promise.then(storeCuota(response));
                                    return response;
                                }, vm.ordenPago.ordenPagoDetalles || []
                            );
                        }
                    });
                });
            }
        });
    }

    function monedaSelected() {
        validadParametroMoneda(); // / Verificar si se permiten diferentes monedas en la OP.

        if (!vm.ordenPago.ordenPagoDetalles) {
            return;
        }
        var comprobantes = [];
        var cuotas = [];
        _.forEach(vm.ordenPago.ordenPagoDetalles, function (val) {
            if (val.cuota) {
                cuotas.push({data: val.cuota, cuota: true});
            } else {
                comprobantes.push({data: val.comprobante, cuota: false});
            }
        });

        var todos = comprobantes.concat(cuotas);
        vm.ordenPago.ordenPagoDetalles = _.reduce(
                todos,
                function (response, val) {
                    if (val.cuota) {
                        val.data.$promise.then(storeCuota(response));
                    } else {
                        val.data.$promise.then(storeComprobante(response));
                    }
                    return response;
                }, []
                );
    }

    function validadParametroMoneda() {

        // Verificar si se permiten diferentes monedas en la OP.

        ParametrosFactory.getByCodigo("op_diferente_moneda").then(function (r) {

            if (vm.ordenPago.tipoCambio == null || vm.ordenPago.tipoCambio == undefined) {
                vm.ordenPago.tipoCambio = 1;
            }

            if (r.valorTipo.codigo == "no") {
                vm.search.moneda = {};
                vm.search.moneda.codigo = vm.ordenPago.moneda.codigo
                vm.search.moneda.id = vm.ordenPago.moneda.id;
                vm.search.moneda.descripcion = vm.ordenPago.moneda.descripcion
                vm.deshabilitarMoneda = true;

                monedaFilterChanged();
            }

        });
    }
    function getPlanillasFondoFijo() {
        var filter = {
            search: filterFactory
                    .single({
                        path: "estado.codigo",
                        equals: "epf_aprobado"
                    })
                    .value()
        };
        if (vm.watcherPFF) {
            vm.watcherPFF();
        } //hacemos unwatch de la expresion anterior
        WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcherPFF
                ) {
            vm.watcherPFF = watcherPFF;
            vm.planillasFondoFijo = PlanillaFondoFijoFactory.all(
                    UtilFactory.createNestedFilters('', filter, 'PlanillaFondoFijoList')
                    );
        }, "locacion.id");
    }

    function getPermisoCalcularRetencion() {
        var obs2 = observeOnScope($rootScope, "AuthParams.permissions")
                .filter(function (data) {
                    return data.newValue;
                })
                .map(function (data) {
                    return data.newValue;
                });

        obs2.subscribe(function (change) {
            vm.canChangeCalcularRetencion =
                    _.find(change, function (elem) {
                        return elem == "calcular_retencion";
                    }) != undefined;
            vm.canChangeCambio =
                    _.find(change, function (elem) {
                        return elem == "modificar_tipo_cambio";
                    }) != undefined;
        });
    }

    function storeComprobante(response) {
        return function(val) {
            hasToRetenerRetencion(val).then(function(retenerRetencion) {
                if (val.tipo.generarCuotas == true) {
                    _.forEach(
                            _.filter(val.planPagos.planPagosDetalles, function (cuotaA) {
                                var formato = "YYYY/MM/DD";
                                var fechaVencimientoCuota = moment(
                                        cuotaA.fechaVencimiento
                                        ).format(formato);
                                var fechaVencimientoDesde = vm.search.fechaVencimientoDesde;
                                var fechaVencimientoHasta = vm.search.fechaVencimientoHasta;
                                if (fechaVencimientoDesde && fechaVencimientoHasta) {
                                    return moment(fechaVencimientoCuota).isBetween(
                                            fechaVencimientoDesde,
                                            fechaVencimientoHasta,
                                            null,
                                            "[]"
                                            );
                                }
                                return true;
                            }),
                            val.data.$promise.then(storeCuota(response, val, retenerRetencion)));
                } else {
                    var obj = {
                        comprobante: val,
                        importeRetencionIVAOriginal: 0
                    };
                    //Calculo de retenciones.
                    if (vm.retencionOP != undefined && vm.retencionOP != null){
                        if (vm.retencionOP.retencionIva!= null && vm.retencionOP.retencionIva!= 0){
                            if (vm.retencionOP.tipoRetencionIva.codigo === "tipo_ret_total"){
                                obj.importeRetencionIVAOriginal= retenerRetencion ?
                                (val.moneda1.codigo == "guaranies" ?
                                    Math.round(val.saldo * vm.retencionOP.retencionIva / 100):
                                    Math.round(val.saldo * vm.retencionOP.retencionIva) / 100) : 0
                            }else{
                                if (vm.descontarNcRetencion){
                                    obj.importeRetencionIVAOriginal= retenerRetencion ?
                                        (val.moneda1.codigo == "guaranies" ?
                                        Math.round(val.saldoImpuestos * vm.retencionOP.retencionIva / 100):
                                        Math.round(val.saldoImpuestos * vm.retencionOP.retencionIva) / 100) : 0
                                }else{
                                    obj.importeRetencionIVAOriginal= retenerRetencion ?
                                        (val.moneda1.codigo == "guaranies" ?
                                        Math.round(val.totalImpuestos * vm.retencionOP.retencionIva / 100):
                                        Math.round(val.totalImpuestos * vm.retencionOP.retencionIva) / 100) : 0
                                }
                            }
                        }else{
                            var retencion5 = 0;
                            var retencion10 = 0;
                            var saldoImpuesto5 = _.find(val.montosImponibles, function(montos){
                                return montos.tipoImpuesto.porcentaje === 5;
                            });
                            var saldoImpuesto10 = _.find(val.montosImponibles, function(montos){
                                return montos.tipoImpuesto.porcentaje === 10;
                            });
                            if (vm.descontarNcRetencion){
                                var montoNC5 = null;
                                var montoNC10 = null;
                                if (val.aplicaciones.length > 0){
                                    montoNC5 = _.find(val.aplicaciones[0].montosImponibles, function(montos){
                                        return montos.tipoImpuesto.porcentaje === 5;
                                    });
                                    montoNC10 = _.find(val.aplicaciones[0].montosImponibles, function(montos){
                                        return montos.tipoImpuesto.porcentaje === 10;
                                    });
                                }
                                if (montoNC5 == null) montoNC5 = {valor:0};
                                if (montoNC10 == null) montoNC10 = {valor:0};

                                if (saldoImpuesto5 != null){
                                    retencion5 = retenerRetencion ?
                                    (val.moneda1.codigo == "guaranies" ?
                                        Math.round((saldoImpuesto5.valor - montoNC5.valor) * vm.retencionOP.retencionIva5 / 100) :
                                        Math.round((saldoImpuesto5.valor - montoNC5.valor) * vm.retencionOP.retencionIva5) / 100) : 0
                                }
                                if (saldoImpuesto10 != null){
                                    retencion10 = retenerRetencion ?
                                    (val.moneda1.codigo == "guaranies" ?
                                        Math.round((saldoImpuesto10.valor - montoNC10.valor) * vm.retencionOP.retencionIva10 / 100) :
                                        Math.round((saldoImpuesto10.valor - montoNC10.valor) * vm.retencionOP.retencionIva10 ) / 100) : 0
                                }
                            }else{
                                if (saldoImpuesto5 != null){
                                    retencion5 = retenerRetencion ?
                                    (val.moneda1.codigo == "guaranies" ?
                                        Math.round(saldoImpuesto5.valor * vm.retencionOP.retencionIva5 / 100) :
                                        Math.round(saldoImpuesto5.valor * vm.retencionOP.retencionIva5) / 100) : 0
                                }
                                if (saldoImpuesto10 != null){
                                    retencion10 = retenerRetencion ?
                                    (val.moneda1.codigo == "guaranies" ?
                                        Math.round(saldoImpuesto10.valor * vm.retencionOP.retencionIva10 / 100) :
                                        Math.round(saldoImpuesto10.valor * vm.retencionOP.retencionIva10 ) / 100) : 0
                                }
                            }

                            obj.importeRetencionIVAOriginal = retencion5 + retencion10;
                        }
                    }

                    setAdditionalDetailProperties(obj, val);

                    var cantDecimales = 0;
                    var operacion = "mult";
                    if (vm.ordenPago.moneda.codigo != 'guaranies') {
                        cantDecimales = 2;
                        if (val.moneda1.codigo == 'guaranies') {
                            operacion = "div";
                        }
                    }

                    if (val.moneda1.codigo == vm.ordenPago.moneda.codigo) {
                        obj.tipoCambio = 1;
                        obj.importeAretener = obj.importeRetencionIVAOriginal;
                        obj.importePagar = obj.comprobante.saldo - (obj.importeAretener + obj.montoRetenido);
                        if (obj.importeAretener >0 || obj.montoRetenido > 0){
                            obj.tieneRetencion = true;
                        }

                        if (val.moneda1.codigo === 'dolares'){
                            obj.importePagar = Math.round(obj.importePagar * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                        }
                        // obj.comprobante.saldo = parseFloat((obj.comprobante.saldo).toFixed(cantDecimales));
                        // obj.comprobante.montoTotal = parseFloat((obj.comprobante.montoTotal).toFixed(cantDecimales));
                        response.push(obj);
                        calcularMontoTotal();
                    } else {
                        if (vm.ordenPago.tipoCambio != undefined && vm.ordenPago.tipoCambio != 1){
                            var tipoCambio = vm.ordenPago.tipoCambio;

                            obj.tipoCambio = tipoCambio;

                            // obj.importeAretener = parseFloat(
                            //     (obj.importeRetencionIVAOriginal).toFixed(cantDecimales)
                            // );
                            obj.importeAretener = obj.importeRetencionIVAOriginal;
                            if (tipoCambio != 0) {
                                if (operacion == "mult") { // Math.round((montoRetenido * detalle.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                    obj.saldoConvertido = Math.round((obj.comprobante.saldo * tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                    obj.importePagar = obj.saldoConvertido - Math.round(((obj.importeAretener + obj.montoRetenido) * tipoCambio)* Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                } else {
                                    obj.saldoConvertido = Math.round((obj.comprobante.saldo / tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                    obj.importePagar = obj.saldoConvertido - Math.round(((obj.importeAretener + obj.montoRetenido) / tipoCambio)* Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                }
                                obj.importePagar = Math.round(obj.importePagar * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                            } else {
                                obj.saldoConvertido = 0;
                                obj.importePagar = 0;
                            }

                            // obj.comprobante.saldo = parseFloat((obj.comprobante.saldo).toFixed(cantDecimales));
                            // obj.comprobante.montoTotal = parseFloat((obj.comprobante.montoTotal).toFixed(cantDecimales));
                            response.push(obj);
                            calcularMontoTotal();
                        }else{
                            obj.monedaDesdeTranslation = {moneda: val.moneda1.descripcion};
                        CotizacionMonedaFactory.getCotizacionDiaOr(
                                val.moneda1.id,
                                vm.ordenPago.moneda.id,
                                vm.ordenPago.fecha
                                ).then(function (valor) {
                            var tipoCambio = valor;

                            if (!valor) {
                                // valor = 1;
                                // si no hay cotización retornar error y no cargar la lista
                                notify({message: $filter("translate")("ERROR_TIPO_CAMBIO", {comprobante: val.numero}), classes: "alert-danger", position: "right"});
                                //return;
                                tipoCambio = 0;
                            }

                            obj.tipoCambio = tipoCambio;

                            // obj.importeAretener = parseFloat(
                            //     (obj.importeRetencionIVAOriginal).toFixed(cantDecimales)
                            // );
                            obj.importeAretener = obj.importeRetencionIVAOriginal;
                            if (tipoCambio != 0) {
                                if (operacion == "mult") { // Math.round((montoRetenido * detalle.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                    obj.saldoConvertido = Math.round((obj.comprobante.saldo * valor) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                    obj.importePagar = obj.saldoConvertido - Math.round(((obj.importeAretener + obj.montoRetenido) * valor)* Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                } else {
                                    obj.saldoConvertido = Math.round((obj.comprobante.saldo / valor) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                    obj.importePagar = obj.saldoConvertido - Math.round(((obj.importeAretener + obj.montoRetenido) / valor)* Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                }
                                obj.importePagar = Math.round(obj.importePagar * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                            } else {
                                obj.saldoConvertido = 0;
                                obj.importePagar = 0;
                            }

                            // obj.comprobante.saldo = parseFloat((obj.comprobante.saldo).toFixed(cantDecimales));
                            // obj.comprobante.montoTotal = parseFloat((obj.comprobante.montoTotal).toFixed(cantDecimales));
                            response.push(obj);
                            calcularMontoTotal();
                        });
                        }

                    }
                }
            });
        };
    }

    function setAdditionalDetailProperties(obj, val) {
        if ((vm.ordenPago.tipoOrdenPago.clase.codigo === "clase_orden_pago_anticipo") ||
                (vm.ordenPago.tipoOrdenPago.clase.codigo === "multiples_proveedores_anticipo")) {
            vm.isAnticipo = "anticipo";
        }

        var monedaComprobante = obj.comprobante.moneda1;
        if (obj.cuota) {
            obj.montoPagado = monedaComprobante.codigo == "guaranies" ?
                    parseFloat((obj.cuota.monto - obj.cuota.saldo).toFixed(0)) :
                    parseFloat((obj.cuota.monto - obj.cuota.saldo).toFixed(4));
        } else {
            obj.montoPagado = monedaComprobante.codigo == "guaranies" ?
                    parseFloat((val.montoTotal - val.saldo).toFixed(0)) :
                    parseFloat((val.montoTotal - val.saldo).toFixed(4));
        }
        obj.montoAplicado = _.reduce(
                val.aplicaciones,
                function (val, aplicacion) {
                    return val + aplicacion.montoAplicado;
                },
                0
                );

        obj.montoRetenido = _.reduce(
                val.comprobanteRetenciones,
                function (val, retencion) {
                    var suma = val;
                    if (retencion.estado.codigo != "comprobante_retencion_anulado") {
                        if (retencion.importe) {
                            suma += retencion.importe;
                        }
                    }
                    return suma;
                },
                0
                );

        //obj.montoPagado = 0;

        if (vm.mode == "edit") {

            /* Se esta intentando actualizar una aplicación de anticipo. Se setea el saldo del comproante
             sumando el importe pagado en ESTA op, esto es para que los montos se muestren consistentes. */

            obj.comprobante.saldo =
            monedaComprobante.codigo == "guaranies" ?
                    parseFloat((obj.comprobante.saldo + obj.importePagar).toFixed(0)) :
                    parseFloat((obj.comprobante.saldo + obj.importePagar).toFixed(4));

            /* Se muestra el monto pagado del comprobante restando lo pagado en ESTA op. */

            obj.montoPagado = 0;

            /* El saldo convertido es igual al saldo del comprobante, en anticipo no existem multimoneda */

            obj.saldoConvertido = obj.comprobante.saldo;


            /* Mismo procedimiento con cuotas */

            if (obj.cuota) {
                obj.cuota.saldo =
            monedaComprobante.codigo == "guaranies" ?
                    parseFloat((obj.cuota.saldo + obj.importePagar).toFixed(0)) :
                    parseFloat((obj.cuota.saldo + obj.importePagar).toFixed(4));
                obj.saldoConvertido = obj.cuota.saldo;
                obj.montoPagado = 0;
            }
        } else {

            calcularMontoTotal();
            obj.monedaHastaTranslation = {moneda: vm.ordenPago.moneda.descripcion};
            obj.monedaDesdeTranslation = {moneda: val.moneda1.descripcion};
            obj.retencion = _.find(val.comprobanteRetenciones, function(ret){
                return ret.estado.codigo != 'comprobante_retencion_anulado'
            })
        }
    }

    vm.listaComprobantesConRetencion = [];

    function storeCuota(response) {

        // Si es anticipo setear variable utilizada por directiva para mostrar campo 'Fecha Aplicacion'
        if ((vm.ordenPago.tipoOrdenPago.clase.codigo === "clase_orden_pago_anticipo") ||
                (vm.ordenPago.tipoOrdenPago.clase.codigo === "multiples_proveedores_anticipo")) {
            vm.isAnticipo = "anticipo";
        }

        return function(val) {
            hasToRetenerRetencionCuota(val).then(function(retenerRetencion){
                if(val.planPagos.comprobante.moneda1.codigo == "guaranies"){
                    val.monto = Math.round(val.monto);
                    val.saldo = Math.round(val.saldo);
                }else if(val.planPagos.comprobante.moneda1.codigo == "dolares"){
                    val.saldo = Math.round(val.saldo * 100) / 100;
                    val.monto = Math.round(val.monto * 100) / 100;
                }

                if (val.saldo != 0) {
                    var retencion = _.find(val.planPagos.comprobante.comprobanteRetenciones, function(ret){
                        return "comprobante_retencion_anulado" != ret.estado.codigo;
                    });
                    if (retencion != undefined && retencion.estadoOP == null){
                        vm.listaComprobantesConRetencion.push(val.planPagos.comprobante.numero);
                    }
                    var existeRetencion = _.find(vm.listaComprobantesConRetencion, function (numero) {
                        return numero === val.planPagos.comprobante.numero;
                    });
                    var esLaMenorCuota = false;
                    var menor = _.find(vm.listaOrdenadaCuotas, function(cuota){
                        if (val.planPagos.comprobante.numero === cuota.planPagos.comprobante.numero
                            && cuota.numero < val.numero){
                                return cuota;
                        }
                    });
                    if (!menor && (retencion == undefined || retencion.estadoOP == null || (retencion.estadoOP != "opago_confirmada" && retencion.estadoOP != "opago_pagada")) ){
                        esLaMenorCuota = true;
                    }


                    var obj = {
                        comprobante: val.planPagos.comprobante,
                        cuota: val,
                        montoPagado: parseFloat((val.monto - val.saldo).toFixed(4)),
                        montoAplicado: _.reduce(
                                val.aplicaciones,
                                function (val, aplicacion) {
                                    return val + aplicacion.montoAplicado;
                                },
                                0
                                ),
                        monedaHastaTranslation: {moneda: vm.ordenPago.moneda.descripcion},
                        monedaDesdeTranslation: {moneda: val.planPagos.comprobante.moneda1.descripcion},
                        retenerRetencion: retenerRetencion,
                        tieneRetencion: false,
                        importeRetencionIVAOriginal: 0
                    };


                    //Calculo de retenciones.
                    if (!existeRetencion && vm.retencionOP != undefined && vm.retencionOP != null){
                        if (vm.retencionOP.retencionIva!= null && vm.retencionOP.retencionIva!= 0){
                            if (vm.retencionOP.tipoRetencionIva.codigo === "tipo_ret_total"){
                                obj.importeRetencionIVAOriginal= retenerRetencion ?
                                (obj.comprobante.moneda1.codigo == "guaranies" ?
                                    Math.round(obj.comprobante.saldo * vm.retencionOP.retencionIva / 100) :
                                    Math.round(obj.comprobante.saldo * vm.retencionOP.retencionIva ) / 100) : 0
                            }else{
                                if (vm.descontarNcRetencion){
                                    obj.importeRetencionIVAOriginal= retenerRetencion ?
                                        (obj.comprobante.moneda1.codigo == "guaranies" ?
                                        Math.round(obj.comprobante.saldoImpuestos * vm.retencionOP.retencionIva / 100) :
                                        Math.round(obj.comprobante.saldoImpuestos * vm.retencionOP.retencionIva ) / 100) : 0
                                }else{
                                    obj.importeRetencionIVAOriginal= retenerRetencion ?
                                        (obj.comprobante.moneda1.codigo == "guaranies" ?
                                        Math.round(obj.comprobante.totalImpuestos * vm.retencionOP.retencionIva / 100) :
                                        Math.round(obj.comprobante.totalImpuestos * vm.retencionOP.retencionIva ) / 100) : 0
                                }
                            }
                        }else{
                            var retencion5 = 0;
                            var retencion10 = 0;
                            var saldoImpuesto5 = _.find(obj.comprobante.montosImponibles, function(montos){
                                return montos.tipoImpuesto.porcentaje === 5;
                            });
                            var saldoImpuesto10 = _.find(obj.comprobante.montosImponibles, function(montos){
                                return montos.tipoImpuesto.porcentaje === 10;
                            });
                            if (vm.descontarNcRetencion){
                                var montoNC5 = null;
                                var montoNC10 = null;
                                if (val.aplicaciones.length > 0){
                                    montoNC5 = _.find(val.aplicaciones[0].montosImponibles, function(montos){
                                        return montos.tipoImpuesto.porcentaje === 5;
                                    });
                                    montoNC10 = _.find(val.aplicaciones[0].montosImponibles, function(montos){
                                        return montos.tipoImpuesto.porcentaje === 10;
                                    });
                                }
                                if (montoNC5 == null) montoNC5 = {valor:0};
                                if (montoNC10 == null) montoNC10 = {valor:0};

                                if (saldoImpuesto5 != null){
                                    retencion5 = retenerRetencion ?
                                    (obj.comprobante.moneda1.codigo == "guaranies" ?
                                        Math.round((saldoImpuesto5.valor - montoNC5.valor) * vm.retencionOP.retencionIva5 / 100) :
                                        Math.round((saldoImpuesto5.valor - montoNC5.valor) * vm.retencionOP.retencionIva5) / 100) : 0
                                }
                                if (saldoImpuesto10 != null){
                                    retencion10 = retenerRetencion ?
                                    (obj.comprobante.moneda1.codigo == "guaranies" ?
                                        Math.round((saldoImpuesto10.valor - montoNC10.valor) * vm.retencionOP.retencionIva10 / 100) :
                                        Math.round((saldoImpuesto10.valor - montoNC10.valor) * vm.retencionOP.retencionIva10 ) / 100) : 0
                                }
                            }else{
                                if (saldoImpuesto5 != null){
                                    retencion5 = retenerRetencion ?
                                    (obj.comprobante.moneda1.codigo == "guaranies" ?
                                        Math.round(saldoImpuesto5.valor * vm.retencionOP.retencionIva5 / 100) :
                                        Math.round(saldoImpuesto5.valor * vm.retencionOP.retencionIva5) / 100) : 0
                                }
                                if (saldoImpuesto10 != null){
                                    retencion10 = retenerRetencion ?
                                    (obj.comprobante.moneda1.codigo == "guaranies" ?
                                        Math.round(saldoImpuesto10.valor * vm.retencionOP.retencionIva10 / 100) :
                                        Math.round(saldoImpuesto10.valor * vm.retencionOP.retencionIva10 ) / 100 ) : 0
                                }
                            }
                            obj.importeRetencionIVAOriginal = retencion5 + retencion10;
                        }
                    }else{
                        obj.importeRetencionIVAOriginal = 0;
                    }
                    var cantDecimales = 0;
                    var operacion = "mult";
                    if (vm.ordenPago.moneda.codigo != 'guaranies') {
                        cantDecimales = 2;
                        if (val.planPagos.comprobante.moneda1.codigo == 'guaranies') {
                            operacion = "div";
                        }
                    }

                    if (val.planPagos.comprobante.moneda1.codigo == vm.ordenPago.moneda.codigo) {
                        obj.tipoCambio = 1;
                        if (esLaMenorCuota) {
                            obj.importeAretener = obj.importeRetencionIVAOriginal;
                            obj.tieneRetencion = true;
                            obj.montoRetenido = _.reduce(
                                    val.planPagos.comprobante.comprobanteRetenciones,
                                    function (val, retencion) {
                                        var suma = val;
                                        if (retencion.importe && retencion.estado.codigo != "comprobante_retencion_anulado") {
                                            suma += retencion.importe;
                                        }
                                        return suma;
                                    },
                                    0
                                    );
                            obj.retencion = _.find(val.planPagos.comprobante.comprobanteRetenciones, function(ret){
                                return ret.estado.codigo != "comprobante_retencion_anulado";
                            });

                            vm.listaComprobantesConRetencion.push(val.planPagos.comprobante.numero);
                        }else{
                            obj.importeAretener = 0;
                            obj.montoRetenido = 0;
                        }
                        if (operacion == "mult") {
                            obj.saldoConvertido = parseFloat((obj.cuota.saldo * obj.tipoCambio).toFixed(cantDecimales));
                            obj.importePagar = parseFloat(
                                    (obj.saldoConvertido - (obj.importeAretener + obj.montoRetenido) * obj.tipoCambio).toFixed(cantDecimales)
                                    );
                        } else {
                            obj.saldoConvertido = parseFloat((obj.cuota.saldo / obj.tipoCambio).toFixed(cantDecimales));
                            obj.importePagar = parseFloat(
                                    (obj.saldoConvertido - (obj.importeAretener + obj.montoRetenido) / obj.tipoCambio).toFixed(cantDecimales)
                                    );
                        }
                        response.push(obj);
                        calcularMontoTotal();
                    } else {
                        if (vm.ordenPago.tipoCambio != undefined && vm.ordenPago.tipoCambio != 1){
                            obj.tipoCambio = vm.ordenPago.tipoCambio;
                            obj.montoRetenido = _.reduce(
                                val.planPagos.comprobante.comprobanteRetenciones,
                                function (val, retencion) {
                                    var suma = val;
                                    if (retencion.importe && retencion.estado.codigo != "comprobante_retencion_anulado") {
                                        suma += retencion.importe;
                                    }
                                    return suma;
                                },
                                0
                                );

                            obj.retencion = _.find(val.planPagos.comprobante.comprobanteRetenciones, function(ret){
                                return ret.estado.codigo != "comprobante_retencion_anulado";
                            });

                            if (esLaMenorCuota) {
                                obj.importeAretener = obj.importeRetencionIVAOriginal;
                                obj.tieneRetencion = true;
                            } else {
                                obj.importeAretener = 0;
                                obj.montoRetenido = 0;
                            }

                            if (obj.tipoCambio != 0) {
                                if (operacion == "mult") { // Math.round((montoRetenido * detalle.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                    obj.saldoConvertido = Math.round((obj.cuota.saldo * obj.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                    obj.importePagar = obj.saldoConvertido - Math.round(((obj.importeAretener + obj.montoRetenido) * obj.tipoCambio)* Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                } else {
                                    obj.saldoConvertido = Math.round((obj.cuota.saldo / obj.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                    obj.importePagar = obj.saldoConvertido - Math.round(((obj.importeAretener + obj.montoRetenido) / obj.tipoCambio)* Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                }
                                obj.importePagar = Math.round(obj.importePagar * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                            } else {
                                obj.saldoConvertido = 0;
                                obj.importePagar = 0;
                            }
                            if (!existeRetencion && esLaMenorCuota) {
                                obj.tieneRetencion = true;
                                obj.montoRetenido = _.reduce(
                                        val.planPagos.comprobante.comprobanteRetenciones,
                                        function (val, retencion) {
                                            var suma = val;
                                            if (retencion.importe && retencion.estado.codigo != "comprobante_retencion_anulado") {
                                                suma += retencion.importe;
                                            }
                                            return suma;
                                        },
                                        0
                                        );
                                obj.numeroRetencion = _.map(val.planPagos.comprobante.comprobanteRetenciones, function (
                                        retencion
                                        ) {
                                    return retencion.numero;
                                }).join(", ");
                                vm.listaComprobantesConRetencion.push(val.planPagos.comprobante.numero);
                            }
                            response.push(obj);
                            calcularMontoTotal();
                        }else{
                            CotizacionMonedaFactory.getCotizacionDiaOr(
                                    val.planPagos.comprobante.moneda1.id,
                                    vm.ordenPago.moneda.id,
                                    vm.ordenPago.fecha
                                    ).then(function (valor) {
                                var tipoCambio = valor;
                                if (!valor) {
                                    // si no hay cotización retornar error y no cargar la lista
                                    notify({message: $filter("translate")("ERROR_TIPO_CAMBIO_CUOTA", {cuota: val.numero, comprobante: val.planPagos.comprobante.numero}), classes: "alert-danger", position: "right"});
                                    tipoCambio = 0;
                                }

                                obj.tipoCambio = tipoCambio;
                                obj.montoRetenido = _.reduce(
                                    val.planPagos.comprobante.comprobanteRetenciones,
                                    function (val, retencion) {
                                        var suma = val;
                                        if (retencion.importe && retencion.estado.codigo != "comprobante_retencion_anulado") {
                                            suma += retencion.importe;
                                        }
                                        return suma;
                                    },
                                    0
                                    );

                                obj.numeroRetencion = _.map(val.planPagos.comprobante.comprobanteRetenciones, function (
                                    retencion
                                    ) {
                                    return retencion.numero;
                                }).join(", ");

                                if (esLaMenorCuota) {
                                    obj.importeAretener = obj.importeRetencionIVAOriginal;
                                    obj.tieneRetencion = true;
                                } else {
                                    obj.importeAretener = 0;
                                }

                                if (tipoCambio != 0) {
                                    if (operacion == "mult") { // Math.round((montoRetenido * detalle.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                        obj.saldoConvertido = Math.round((obj.cuota.saldo * valor) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                        obj.importePagar = obj.saldoConvertido - Math.round(((obj.importeAretener + obj.montoRetenido) * valor)* Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                    } else {
                                        obj.saldoConvertido = Math.round((obj.cuota.saldo / valor) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                        obj.importePagar = obj.saldoConvertido - Math.round(((obj.importeAretener + obj.montoRetenido) / valor)* Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                    }
                                    obj.importePagar = Math.round(obj.importePagar * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                                } else {
                                    obj.saldoConvertido = 0;
                                    obj.importePagar = 0;
                                }
                                if (!existeRetencion && esLaMenorCuota) {
                                    obj.tieneRetencion = true;
                                    obj.montoRetenido = _.reduce(
                                            val.planPagos.comprobante.comprobanteRetenciones,
                                            function (val, retencion) {
                                                var suma = val;
                                                if (retencion.importe && retencion.estado.codigo != "comprobante_retencion_anulado") {
                                                    suma += retencion.importe;
                                                }
                                                return suma;
                                            },
                                            0
                                            );
                                    obj.numeroRetencion = _.map(val.planPagos.comprobante.comprobanteRetenciones, function (
                                            retencion
                                            ) {
                                        return retencion.numero;
                                    }).join(", ");
                                    vm.listaComprobantesConRetencion.push(val.planPagos.comprobante.numero);
                                }
                                response.push(obj);
                                calcularMontoTotal();
                            });
                        }
                    }
                }
            });

        }

    }

    function onChangeCalcularRentecion() {
        if (!vm.retencionOP){
            notify({message: $filter("translate")("NO_EXISTE_TIPO_RETENCION"), classes: "alert-danger", position: "right"});
            vm.ordenPago.calcularRetencion = false;
            return;
        }
        vm.disableCheckRetencion = false;

        vm.listaOrdenadaCuotas = [];
        var listaNoCuotas = [];
        /* Excepto los que no tienen cuotas */
        _.forEach(vm.ordenPago.ordenPagoDetalles, function(anterior){
            if (anterior.cuota != undefined){
                vm.listaOrdenadaCuotas.push(anterior.cuota);
            }else{
                listaNoCuotas.push(anterior.comprobante);
            }
        })
        vm.listaComprobantesConRetencion = [];
        vm.ordenPago.ordenPagoDetalles = [];

        vm.ordenPago.ordenPagoDetalles = _.reduce(
            listaNoCuotas,
            function (response, val) {
                val.$promise.then(storeComprobante(response));
                return response;
            }, vm.ordenPago.ordenPagoDetalles || []
        );


        var numeroComprobanteJ, numeroComprobanteJ1;
        for (var i=0; i<vm.listaOrdenadaCuotas.length; i++){
            for (var j=vm.listaOrdenadaCuotas.length-1; j>i; j--){
                numeroComprobanteJ = vm.listaOrdenadaCuotas[j].planPagos.comprobante.numero;
                numeroComprobanteJ1 = vm.listaOrdenadaCuotas[j-1].planPagos.comprobante.numero;
                if (numeroComprobanteJ < numeroComprobanteJ1 ||
                    ((numeroComprobanteJ === numeroComprobanteJ1 && vm.listaOrdenadaCuotas[j].numero < vm.listaOrdenadaCuotas[j-1].numero))){
                    var aux = vm.listaOrdenadaCuotas[j];
                    vm.listaOrdenadaCuotas[j] = vm.listaOrdenadaCuotas[j-1];
                    vm.listaOrdenadaCuotas[j-1] = aux;
                }
            }
        }

        vm.ordenPago.ordenPagoDetalles = _.reduce(
            vm.listaOrdenadaCuotas,
            function (response, val) {
                val.$promise.then(storeCuota(response));
                return response;
            }, vm.ordenPago.ordenPagoDetalles || []
        );

    }

    function hasToRetenerRetencion(comprobante) {
        return $q(function (resolve, reject) {
            var contador = 0;
            _.forEach(comprobante.comprobanteRetenciones, function (ret) {
                // si no tiene estado se asume que es una cuota, y se va a pedir en hasToRetenerRetencionCuota
                if (ret.estado && ret.estado.codigo !== "comprobante_retencion_anulado") {
                    contador++;
                }
            });
            if (contador > 0) {
                /** aca tengo que asignarle la retencion al detalle de la OP. */
                resolve(false); //si ya tiene retenciones no retenemos
                return;
            }
            if (vm.ordenPago.calcularRetencion && comprobante.proveedor.calcularRetencion) {
                ParametrosFactory.getByCodigo("monto_minimo_retencion").then(
                        function (parametro) {

                            var montoMinimo = parametro.valor;
                            //Procedemos a buscar comprobantes del mes del proveedor y ver si llegan al monto mínimo
                            var fechaComprobante = moment(comprobante.fecha, "YYYY/MM/DD");
                            var month = fechaComprobante.month() + 1;
                            var year = fechaComprobante.year();
                            var proveedorId = comprobante.proveedor.id;
                            var comp = ComprobanteFactory.create();
                            var montoComprobantes = ComprobanteFactory.getMontosMes(
                                    comp,
                                    month,
                                    year,
                                    proveedorId
                                    );
                            montoComprobantes.then(function (response) {
                                var montos = response.monto;
                                if (montos >= montoMinimo) {
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            });
                        },
                        function (error) {
                            reject(error);
                        }
                );
            } else {
                resolve(false);
            }
        });
    }

    function importePagarDetalleChanged(detalle) {
        calcularMontoTotal();
    }

    function hasToRetenerRetencionCuota(cuota) {

        return $q(function (resolve, reject) {
            var contador = 0;

            if (cuota.planPagos.comprobante.comprobanteRetenciones.length > 0) {
                // se obtienen las retenciones relacionadas
                _.forEach(cuota.planPagos.comprobante.comprobanteRetenciones, function (ret) {
                    if (ret.estado.codigo != "comprobante_retencion_anulado") {
                        contador++;
                    }
                });
                if (contador > 0) {
                    resolve(false);
                    return;
                }
                if (vm.ordenPago.calcularRetencion && cuota.planPagos.comprobante.proveedor.calcularRetencion) {
                    //Procedemos a buscar comprobantes del mes del proveedor y ver si llegan al monto mínimo
                    var proveedorId = undefined;
                    var fechaComprobante = undefined;
                    if (cuota.planPagos.comprobante) {
                        fechaComprobante = moment(cuota.planPagos.comprobante.fecha, "YYYY/MM/DD");
                        proveedorId = cuota.planPagos.comprobante.proveedor.id;
                        var month = fechaComprobante.month() + 1;
                        var year = fechaComprobante.year();
                        var comp = ComprobanteFactory.create();
                        return ComprobanteFactory.getMontosMes(
                                comp,
                                month,
                                year,
                                proveedorId
                                ).then(function (response) {
                            var montos = response.monto;
                            if (montos >= vm.montoMinimoRetencion) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        });
                    }

                } else {
                    resolve(false);
                }
            } else {
                if (vm.ordenPago.calcularRetencion && cuota.planPagos.comprobante.proveedor.calcularRetencion) {
                    //Procedemos a buscar comprobantes del mes del proveedor y ver si llegan al monto mínimo
                    var proveedorId = undefined;
                    var fechaComprobante = undefined;
                    if (cuota.planPagos.comprobante) {
                        fechaComprobante = moment(cuota.planPagos.comprobante.fecha, "YYYY/MM/DD");
                        proveedorId = cuota.planPagos.comprobante.proveedor.id;
                        var month = fechaComprobante.month() + 1;
                        var year = fechaComprobante.year();
                        var comp = ComprobanteFactory.create();
                        return ComprobanteFactory.getMontosMes(
                                comp,
                                month,
                                year,
                                proveedorId
                                ).then(function (response) {
                            var montos = response.monto;
                            if (montos >= vm.montoMinimoRetencion) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        });
                    }
                } else {
                    resolve(false);
                }
            }
        });
    }

    var redondeo = function (data, moneda) {
        if (!data) {
            data = 0;
        }
        if (moneda === 'dolares') {
            data = parseFloat(data).toFixed(2);
        } else if (moneda === 'pesos') {
            data = parseFloat(data).toFixed(2);
        } else if (moneda === 'real') {
            data = parseFloat(data).toFixed(2);
        } else if (moneda === 'euro') {
            data = parseFloat(data).toFixed(2);
        } else if (moneda === 'guaranies') {
            data = parseFloat(data).toFixed(0);
        }
        return parseFloat(data);//.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    // TODO controlar la generacion de la retencion
    function planillaFondoFijoSeleted() {
        PlanillaFondoFijoFactory.get(vm.ordenPago.planillaFondoFijo.id, "PlanillaFondoFijoForm")
                .$promise.then(function (response) {
                    vm.ordenPago.moneda = response.moneda;
                    vm.ordenPago.proveedor = response.proveedor;

                    if (vm.ordenPago.moneda.codigo == "guaranies") {
                        vm.ordenPago.importe = parseFloat(response.importe.toFixed(0));
                    } else if (vm.ordenPago.moneda.codigo == "dolares") {
                        vm.ordenPago.importe = parseFloat(response.importe.toFixed(4));
                    }
                    return (vm.ordenPago.ordenPagoDetalles = _.map(
                            response.planillaFondoFijoDetalles,
                            function (detalle) {
                                detalle.comprobante.saldo = redondeo(detalle.comprobante.saldo, detalle.comprobante.moneda1.codigo);
                                detalle.comprobante.saldoImpuestos = redondeo(detalle.comprobante.saldoImpuestos, detalle.comprobante.moneda1.codigo);
                                return {
                                    comprobante: ComprobanteFactory.get(
                                            detalle.comprobante.id,
                                            "ComprobanteList"
                                            ),
                                    montoPlanilla: detalle.montoPlanilla,
                                    montoSaldo: detalle.comprobante.saldo
                                };
                            }
                    ));

                })
                .then(function (detalles) {
                    _.forEach(detalles, function (detalle) {
                        detalle.comprobante.$promise
                                .then(function (c) {
                                    detalle.comprobante = c;
                                    c.saldo = redondeo(c.saldo, detalle.comprobante.moneda1.codigo);
                                    c.saldoImpuestos = redondeo(c.saldoImpuestos, c.moneda1.codigo);
                                    detalle.comprobante.saldo = detalle.montoSaldo;
                                    return hasToRetenerRetencion(c);
                                })
                                .then(function (retenerRetencion) {
                                    // detalle.retencion = { importeRetencionIVA: retenerRetencion ? parseFloat((detalle.comprobante.saldoImpuestos * 0.3).toFixed(4)) : 0 };
                                    // detalle.importeARetener....

                                    if (vm.retencionOP != undefined && vm.retencionOP != null){
                                        if (vm.retencionOP.retencionIva!= null && vm.retencionOP.retencionIva!= 0){
                                            if (vm.retencionOP.tipoRetencionIva.codigo === "tipo_ret_total"){
                                                detalle.importeAretener = retenerRetencion && detalle.comprobante.proveedor.calcularRetencion ?
                                                (detalle.comprobante.moneda1.codigo == "guaranies" ?
                                                    Math.round(detalle.comprobante.saldo * vm.retencionOP.retencionIva / 100):
                                                    Math.round(detalle.comprobante.saldo * vm.retencionOP.retencionIva) / 100) : 0
                                            }else{
                                                if (vm.descontarNcRetencion){
                                                    detalle.importeAretener= retenerRetencion && detalle.comprobante.proveedor.calcularRetencion ?
                                                        (detalle.comprobante.moneda1.codigo == "guaranies" ?
                                                        Math.round(detalle.comprobante.saldoImpuestos * vm.retencionOP.retencionIva / 100):
                                                        Math.round(detalle.comprobante.saldoImpuestos * vm.retencionOP.retencionIva) / 100) : 0
                                                }else{
                                                    detalle.importeRetencionIVAOriginal= retenerRetencion ?
                                                        (detalle.comprobante.moneda1.codigo == "guaranies" ?
                                                        Math.round(detalle.comprobante.totalImpuestos * vm.retencionOP.retencionIva / 100):
                                                        Math.round(detalle.comprobante.totalImpuestos * vm.retencionOP.retencionIva) / 100) : 0
                                                }
                                            }
                                        }else{
                                            var retencion5 = 0;
                                            var retencion10 = 0;
                                            var saldoImpuesto5 = _.find(detalle.comprobante.montosImponibles, function(montos){
                                                return montos.tipoImpuesto.porcentaje === 5;
                                            });
                                            var saldoImpuesto10 = _.find(detalle.comprobante.montosImponibles, function(montos){
                                                return montos.tipoImpuesto.porcentaje === 10;
                                            });
                                            if (vm.descontarNcRetencion){
                                                var montoNC5 = null;
                                                var montoNC10 = null;
                                                if (detalle.comprobante.aplicaciones.length > 0){
                                                    montoNC5 = _.find(detalle.comprobante.aplicaciones[0].montosImponibles, function(montos){
                                                        return montos.tipoImpuesto.porcentaje === 5;
                                                    });
                                                    montoNC10 = _.find(detalle.comprobante.aplicaciones[0].montosImponibles, function(montos){
                                                        return montos.tipoImpuesto.porcentaje === 10;
                                                    });
                                                }
                                                if (montoNC5 == null) montoNC5 = {valor:0};
                                                if (montoNC10 == null) montoNC10 = {valor:0};

                                                if (saldoImpuesto5 != null){
                                                    retencion5 = retenerRetencion && detalle.comprobante.proveedor.calcularRetencion ?
                                                    (detalle.comprobante.moneda1.codigo == "guaranies" ?
                                                        Math.round((saldoImpuesto5.valor - montoNC5.valor) * vm.retencionOP.retencionIva5 / 100) :
                                                        Math.round((saldoImpuesto5.valor - montoNC5.valor) * vm.retencionOP.retencionIva5) / 100) : 0
                                                }
                                                if (saldoImpuesto10 != null){
                                                    retencion10 = retenerRetencion && detalle.comprobante.proveedor.calcularRetencion ?
                                                    (detalle.comprobante.moneda1.codigo == "guaranies" ?
                                                        Math.round((saldoImpuesto10.valor - montoNC10.valor) * vm.retencionOP.retencionIva10 / 100) :
                                                        Math.round((saldoImpuesto10.valor - montoNC10.valor) * vm.retencionOP.retencionIva10 ) / 100) : 0
                                                }
                                            }else{
                                                if (saldoImpuesto5 != null){
                                                    retencion5 = retenerRetencion && detalle.comprobante.proveedor.calcularRetencion ?
                                                    (detalle.comprobante.moneda1.codigo == "guaranies" ?
                                                        Math.round(saldoImpuesto5.valor * vm.retencionOP.retencionIva5 / 100) :
                                                        Math.round(saldoImpuesto5.valor * vm.retencionOP.retencionIva5) / 100) : 0
                                                }
                                                if (saldoImpuesto10 != null){
                                                    retencion10 = retenerRetencion && detalle.comprobante.proveedor.calcularRetencion ?
                                                    (detalle.comprobante.moneda1.codigo == "guaranies" ?
                                                        Math.round(saldoImpuesto10.valor * vm.retencionOP.retencionIva10 / 100) :
                                                        Math.round(saldoImpuesto10.valor * vm.retencionOP.retencionIva10 ) / 100) : 0
                                                }
                                            }

                                            detalle.importeAretener = retencion5 + retencion10;
                                        }
                                    }else{
                                        detalle.importeAretener = 0;
                                    }

                                    // detalle.importePagar = detalle.comprobante.saldo - detalle.retencion.importeRetencionIVA;
                                    detalle.montoRetenido = _.reduce(
                                            detalle.comprobante.comprobanteRetenciones,
                                            function (val, retencion) {
                                                var suma = val;
                                                if (retencion.estado.codigo != "comprobante_retencion_anulado") {
                                                    if (retencion.importe) {
                                                        suma += retencion.importe;
                                                    }
                                                }
                                                return suma;
                                            },
                                            0
                                            );

                                    if (detalle.importeAretener>0 || detalle.montoRetenido>0) detalle.tieneRetencion = true;
//                                    detalle.importePagar = detalle.montoPlanilla - (detalle.importeAretener + detalle.montoRetenido);
                                    detalle.importePagar = detalle.montoPlanilla - (detalle.importeAretener + detalle.montoRetenido);
                                    detalle.importePagar = redondeo(detalle.importePagar, detalle.comprobante.moneda1.codigo);
                                    detalle.tipoCambio = 1;
                                    setAdditionalDetailProperties(detalle, detalle.comprobante);
                                    calcularMontoTotal();
                                });
                    });
                });
    }

    function activateOldDetails() {
        if (vm.mode == "add") {
            /* Se esta intentando agregar una nueva aplicación de anticipo, se inicializa detalle, el detalle que esta
             siendo inicializado se vuelve a agregar en el backend */

            vm.ordenPago.ordenPagoDetalles = []

        } else {
            _.forEach(vm.ordenPago.ordenPagoDetalles, function (detalle) {
                console.log("detalle ", detalle)
                setAdditionalDetailProperties(detalle, detalle.comprobante);
            });
        }
    }

    function activateNew() {
        vm.title = $filter("translate")("NEW_ORDEN_PAGO");
        vm.ordenPago = {};
        vm.uploadOptions2 = {
            imageOnly: false
        };
        getValueParametroRetencion();
        vm.monedaLocal.then(function (response) {
            vm.ordenPago.moneda = response.valorTipo;
            monedaSelected();
        });
        vm.estados.$promise.then(function (response) {
            vm.ordenPago.estado = _.find(response, function (estado) {
                return estado.codigo == "opago_ingresada";
            });
        });
    }

    function getValueParametroRetencion() {
        if (vm.isEdit || vm.view) {
            return;
        }

        if (vm.retencionOP != null){
            vm.calcularRetencion.then(function (calcularRetencion) {
                vm.ordenPago.calcularRetencion =
                        calcularRetencion.valorTipo.codigo == "si";
            });
        }

    }

    function cleanUnused(modelo) {
        if (!modelo.ordenPagoDetalles) {
            return;
        }
        _.forEach(modelo.ordenPagoDetalles, function (detalle) {
            delete detalle.montoPagado;
            delete detalle.montoRetenido;
            delete detalle.montoAplicado;
            delete detalle.numeroRetencion;
            delete detalle.importeRetencionIVAOriginal;
            delete detalle.saldoConvertido;
            delete detalle.monedaDesdeTranslation;
            delete detalle.monedaHastaTranslation;
            delete detalle.retenerRetencion;
        });
    }

    function changeImporte() {
        if (vm.ordenPago.moneda.codigo == "guaranies") {
            vm.ordenPago.importe = parseFloat((vm.ordenPago.importe).toFixed(0));

        } else if (vm.ordenPago.moneda.codigo == "dolares") {
            vm.ordenPago.importe = parseFloat((vm.ordenPago.importe).toFixed(4))
        }
    }

    function submit() {

        vm.submited = true;
        var newDate = new Date();
        newDate.setHours(0, 0, 0, 0);

        if ($scope.OrdenPagoForm.$valid) {

            if (vm.mode == "add" && vm.ordenPago.ordenPagoDetalles.length == 0) {
                notify({message: $filter("translate")("DETALLES_NOT_LOADED_ANTICIPO"), classes: "alert-danger", position: "right"});
                return;
            }

            if (vm.ordenPago.tipoOrdenPago.clase.codigo == "clase_orden_pago_fondo_fijo" && (vm.ordenPago.ordenPagoDetalles == undefined ||
                    (vm.ordenPago.ordenPagoDetalles && vm.ordenPago.ordenPagoDetalles.length == 0))) {
                notify({message: $filter("translate")("DETALLES_NOT_LOADED"), classes: "alert-danger", position: "right"});
                return;
            }

            /****** Validaciones de archivos adjuntos ******* */
            var pasaValidacion = true;
            var flowAdjunto = vm.uploadOptions2.flow;
            if (flowAdjunto) {
                var flowFilesAdjunto = flowAdjunto.files;

                angular.forEach(flowFilesAdjunto, function (file) {
                    if (flowAdjunto) {
                        // Validar la extensión del archivo (ejemplo: solo permitir archivos de imagen)
                        var allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'xls', 'xlsx', 'ods'];
                        var fileExtension = file.name.split('.').pop().toLowerCase();

                        if (allowedExtensions.indexOf(fileExtension) === -1) {
                            notify({
                                message: $filter("translate")("ERROR_EXTENSION_ADJUNTO_OP")+": "+fileExtension,
                                classes: "alert-danger",
                                position: "right"
                            });
                            pasaValidacion = false;
                        }

                        // Validar el tamaño del archivo (ejemplo: permitir archivos de hasta 10 MB)
                        var cantMegabytes = 10;
                        var maxFileSizeInBytes = cantMegabytes * 1024 * 1024; // 10 MB

                        // Calcular el tamaño del archivo en MB
                        var fileSizeInMB = file.size / (1024 * 1024);
                        fileSizeInMB = parseFloat(fileSizeInMB.toFixed(2));     // redondeo a 2 decimales

                        if (file.size > maxFileSizeInBytes) {
                            notify({
                                message: $filter('translate')('ERROR_TAMAÑO_ADJUNTO_OP', { cant: fileSizeInMB }),
                                classes: "alert-danger",
                                position: "right"
                            });
                            pasaValidacion = false;
                        }

                        // Continuar con la lógica para archivos válidos
                        /*[index] = {};
                        ordenPago.adjuntos[index].path = file.uniqueIdentifier + '-' + file.name;
                        ordenPago.adjuntos[index].nombreArchivo = file.name;
                        index++;*/
                    }
                });

                if(!pasaValidacion){
                    return;
                }
            }

            adjuntarArchivos(vm.ordenPago);

            // Las comprobaciones solo se hacen fuera del modo VER, si esta guardando en modo VER, es porque esta agregando 
            // adjuntos a la OP (tiene el permiso para hacerlo) en ese caso deberian saltarse las comprobaciones.
            if( !vm.view ) {    
                // Verificacion del valor de parametro
                ParametrosFactory.getByCodigo("habilitar_creacion_anticipo_con_existencia_anticipos_pendientes").then(function (r) {

                    var filter = {};

                    //Solo cuando es anticipo deberia utilizar los filtros para consultar pendientes
                    if (vm.ordenPago.tipoOrdenPago.clase.codigo == "clase_orden_pago_anticipo") {
                        filter.search = filterFactory.and([
                            {
                                path: 'proveedor.id',
                                equals: vm.ordenPago.proveedor.id
                            }, {
                                path: 'tipoOrdenPago.clase.codigo',
                                equals: "clase_orden_pago_anticipo"
                            }, {
                                path: 'estado.codigo',
                                notEquals: "opago_anulada"
                            }, {
                                path: 'id',
                                notEquals: vm.ordenPago.id
                            }, {
                                path: 'saldoImporte',
                                greaterThan: 0.0
                            }]).value();
                    } else {

                        /* La idea de este filtro es que no recupere registros */
                        filter.search = filterFactory.single({
                            path: 'proveedor.id',
                            equals: -1
                        }).value();

                    }

                    OrdenPagoFactory.all(filter).$promise.then(function (response) {
                        var pasoVerificacion = true;

                        if (response && response.length > 0) {

                            // Se hace la verificacion del parametro aqui, solo por la cuestion del $promise (asincrono)
                            if (r.valorTipo.codigo == "no") {
                                notify({message: $filter("translate")("PROVEEDOR_APLICACIONES_PENDIENTES"), classes: "alert-danger", position: "right"});
                                pasoVerificacion = false;
                            }
                        }
                        if (pasoVerificacion) {

                            if (!vm.aplicarAnticipo) {
                                if (vm.permitirOPFechaPasada === false) {
                                    if (vm.ordenPago.fecha.getTime() < newDate.getTime()) {
                                        notify({message: $filter("translate")("ERROR_FECHA_PASADA"), classes: "alert-danger", position: "right"});
                                        return;
                                    }
                                }

                                if (vm.permitirOPFechaFutura === false) {
                                    if (vm.ordenPago.fecha.getTime() > newDate.getTime()) {
                                        notify({message: $filter("translate")("ERROR_FECHA_FUTURA"), classes: "alert-danger", position: "right"});
                                        return;
                                    }
                                }
                            }

                            //Evaluacion de Periodo Administrativo
                            var params = {search: filterFactory.single({
                                    path: "estado.codigo",
                                    equals: "per_adm_abierto"
                                }).value()};

                            vm.flagPeriod = false;
                            vm.ordenPago.fecha.setHours(0, 0, 0, 0);
                            PeriodoAdministrativoFactory.all(params).$promise.then(function (response) {
                                var periodos = response;
                                _.forEach(periodos, function (value) {
                                    var desde = new Date(value.fechaDesde);
                                    var hasta = new Date(value.fechaHasta);

                                    if (vm.ordenPago.fecha >= desde && vm.ordenPago.fecha <= hasta) {
                                        vm.flagPeriod = true;
                                    }
                                })
                                if (!vm.flagPeriod) {
                                    notify({
                                        message: $filter("translate")("NO_ADMINISTRATIVE_PERIOD"),
                                        classes: "alert-danger",
                                        position: "right"
                                    });
                                } else {
                                    checkAmounts().then(
                                            function () {
                                                var trimmedModel = ModelTrimmer.trimDetails(vm.ordenPago, [
                                                    "ordenPagoDetalles", "desembolsoOrdenPago"
                                                ]);
                                                if (trimmedModel.ordenPagoDetalles) {
                                                    trimmedModel.ordenPagoDetalles = _.map(
                                                            trimmedModel.ordenPagoDetalles,
                                                            function (detalle) {
                                                                return ModelTrimmer.trimDetails(detalle);
                                                            }
                                                    );
                                                }
                                                if (trimmedModel.desembolsoOrdenPago) {
                                                    trimmedModel.desembolsoOrdenPago = _.map(
                                                            trimmedModel.desembolsoOrdenPago,
                                                            function (detalle) {
                                                                return ModelTrimmer.trimDetails(detalle);
                                                            }
                                                    );
                                                }
                                                if (!vm.isEdit) {
                                                    trimmedModel.locacion =
                                                            $rootScope.AuthParams.locacionSeleccionada.id;
                                                }
                                                cleanUnused(trimmedModel);
                                                formFactory
                                                        .defaultNSFSubmit(
                                                                $scope.OrdenPagoForm,
                                                                OrdenPagoFactory,
                                                                trimmedModel
                                                                )
                                                        .then(
                                                                function (response) {
                                                                if(response.listaArchivosLogs != null){
                                                                    if(response.listaArchivosLogs.length != 0){
                                                                    /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                                        console.log('response.listaArchivosLogs: ');
                                                                        console.log(response.listaArchivosLogs[i]);
                                                                        $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                                    }*/
                                                                    notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                                    }
                                                                }
                                                                $location.path("/ordenpago");
                                                                },
                                                                function (error) {
                                                                    console.log(error);
                                                                }
                                                        );
                                            },
                                            function (error) {
                                                notify({
                                                    message: error,
                                                    classes: "alert-danger",
                                                    position: "right"
                                                });
                                            }
                                    );
                                }
                            });
                        }
                    });
                });
            }else{

                vm.ordenPago.actualizandoAdjuntos = true;

                var trimmedModel = ModelTrimmer.trimDetails(vm.ordenPago, [
                    "ordenPagoDetalles", "desembolsoOrdenPago"
                ]);
                if (trimmedModel.ordenPagoDetalles) {
                    trimmedModel.ordenPagoDetalles = _.map(
                            trimmedModel.ordenPagoDetalles,
                            function (detalle) {
                                return ModelTrimmer.trimDetails(detalle);
                            }
                    );
                }
                if (trimmedModel.desembolsoOrdenPago) {
                    trimmedModel.desembolsoOrdenPago = _.map(
                            trimmedModel.desembolsoOrdenPago,
                            function (detalle) {
                                return ModelTrimmer.trimDetails(detalle);
                            }
                    );
                }
                if (!vm.isEdit) {
                    trimmedModel.locacion =
                            $rootScope.AuthParams.locacionSeleccionada.id;
                }
                cleanUnused(trimmedModel);
                formFactory.defaultNSFSubmit(
                        $scope.OrdenPagoForm,
                        OrdenPagoFactory,
                        trimmedModel
                        )
                .then(
                        function (response) {
                        if(response.listaArchivosLogs != null){
                            if(response.listaArchivosLogs.length != 0){
                            /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                console.log('response.listaArchivosLogs: ');
                                console.log(response.listaArchivosLogs[i]);
                                $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                            }*/
                            notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                            }
                        }
                        $location.path("/ordenpago");
                        },
                        function (error) {
                            console.log(error);
                        }
                );
            }
        } else {
            notify({
                message: $filter("translate")("WRONG_FIELDS"),
                classes: "alert-danger",
                position: "right"
            });
        }
    }

    function cancel() {
        $location.path("/ordenpago");
    }

    function checkAmounts() {
        return $q(function (resolve, reject) {
            if (!vm.ordenPago.ordenPagoDetalles || vm.ordenPago.ordenPagoDetalles.length == 0) {
                resolve();
                return;
            }
            var cant = vm.ordenPago.ordenPagoDetalles.length;

            for (var i = 0; i < cant; i++) {
                var detalle = vm.ordenPago.ordenPagoDetalles[i];

                var saldo = detalle.saldoConvertido;
                var monto = detalle.importePagar;
                var montoRetenido = detalle.importeAretener!=null? detalle.importeAretener:detalle.montoRetenido;

                var operacion = "mult";
                cantDecimales = 0;
                if (vm.ordenPago.moneda.codigo != 'guaranies') {
                    cantDecimales = 2;
                    if (detalle.comprobante.moneda1.codigo == 'guaranies') {
                        operacion = "div";
                    }
                }

                if (operacion == "mult") {
                    montoRetenido = Math.round((montoRetenido * detalle.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                } else {
                    montoRetenido = Math.round((montoRetenido / detalle.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
                }

                if (!saldo) {
                    if (detalle.cuota) {
                        saldo = detalle.cuota.saldo;
                    } else {
                        saldo = detalle.comprobante.saldo;
                    }
                }

                var sumaCheck = Math.round((monto + montoRetenido) * Math.pow(10, cantDecimales))/Math.pow(10, cantDecimales);

                if (sumaCheck > saldo) {
                    $translate("WRONG_IMPORTE_PAGAR", {
                        comprobante: detalle.comprobante.numero
                    }).then(function (value) {
                        //Monto mayor a saldo. Rechazar
                        reject(value);
                    });
                    return;
                }

                if (monto == 0 && saldo > 0) {
                    $translate("WRONG_IMPORTE_PAGAR_CERO", {
                        comprobante: detalle.comprobante.numero
                    }).then(function (value) {
                        reject(value);
                    });
                    return;
                }

                if (monto < 0) {
                    $translate("WRONG_IMPORTE_PAGAR_CERO", {
                        comprobante: detalle.comprobante.numero
                    }).then(function (value) {
                        reject(value);
                    });
                    return;
                }

            //Aprobado.
            resolve();
            }
        });
    }

    function tipoOrdenPagoSelected() {
        if (vm.firstSelected == undefined) {
            vm.firstSelected = true;
        }
        if (!vm.ordenPago.tipoOrdenPago ||
                !vm.ordenPago.tipoOrdenPago.clase ||
                !vm.ordenPago.tipoOrdenPago.clase.codigo
                ) {
            return;
        }

        switch (vm.ordenPago.tipoOrdenPago.clase.codigo) {
            case "clase_orden_pago_anticipo":
                anticipoSelected();
                break;
            case "clase_orden_pago_fondo_fijo":
                fondoFijoSelected();
                break;
            case "clase_orden_pago_cuenta_contable":
                cuentaContableSelected();
                break;
            case "clase_orden_pago_normal":
                normalSelected();
                break;
            case "multiples_proveedores":
                multiplesProveedoresSelected();
                break;
            case "multiples_proveedores_anticipo":
                multiplesProveedoresSelectedAnticipo();
                break;
        }

        getFormasPagos();
        vm.firstSelected = false;
    }

    function getFormasPagos() {
        //vm.ordenPago.formaPago = null;
        switch (vm.ordenPago.tipoOrdenPago.clase.codigo) {
            case "clase_orden_pago_fondo_fijo":
                    var filters = {
                        search: filterFactory.or([
                            {
                                path: "clase.codigo",
                                equals: "forma_pago_efectivo"
                            },
                            {
                                path: "clase.codigo",
                                equals: "forma_pago_cheque"
                            },
                            {
                                path: "clase.codigo",
                                equals: "forma_pago_transferencia_bancaria"
                            },
                            {
                                path: 'clase.codigo',
                                notEquals: "forma_pago_referencia"
                            }
                         ]).value()
                    }
                    FormaPagoFactory.all(filters).$promise.then(function(response){
                        vm.formasPago = response;
                    });
                break;
            default:

                var filterFormaPago = {};
                filterFormaPago.search = filterFactory.single({
                    path: 'clase.codigo',
                    notEquals: "forma_pago_referencia"
                }).value();

                FormaPagoFactory.all(filterFormaPago).$promise.then(function(response){
                    vm.formasPago = response;
                });
                break;
        }


    }

    function anticipoSelected() {
        vm.isPlanillaFondoFijoRequired = false;
        vm.isCuentaContableRequired = false;
        vm.isProveedorRequired = true;
        if (
                vm.aplicarAnticipo ||
                vm.ordenPago.estado.codigo == "opago_confirmada_anticipo"
                ) {
            vm.isItemsRequired = true;
            vm.isItemsEditable = true;
        } else {
            vm.isItemsRequired = false;
            vm.isItemsEditable = false;
        }
        vm.isCalcularRetencionRequired = false;
        vm.ordenPago.calcularRetencion = false;
        vm.isImporteRequired = true;
        vm.isImporteDisabled = false;
        vm.isMonedaDisabled = false;
        vm.multiplesproveedores = false;
        if (!vm.firstSelected) {
            delete vm.ordenPago.cuentaContable;
            delete vm.ordenPago.planillaFondoFijo;
            delete vm.ordenPago.calcularRetencion;
        }
    }

    function fondoFijoSelected() {
        vm.isPlanillaFondoFijoRequired = true;
        vm.isCuentaContableRequired = false;
        vm.isProveedorRequired = false;
        vm.isItemsRequired = true;
        vm.isItemsEditable = false;
        vm.isCalcularRetencionRequired = true;
        vm.isImporteDisabled = true;
        vm.isMonedaDisabled = true;
        if (!vm.firstSelected) {
            delete vm.ordenPago.moneda;
            delete vm.ordenPago.cuentaContable;
            delete vm.ordenPago.proveedor;
            delete vm.ordenPago.ordenPagoDetalles;
        }
        vm.multiplesproveedores = false;
        getValueParametroRetencion();
    }

    function cuentaContableSelected() {
        vm.isPlanillaFondoFijoRequired = false;
        vm.isCuentaContableRequired = true;
        vm.isItemsRequired = false;
        vm.isItemsEditable = false;
        vm.isProveedorRequired = true;
        vm.isCalcularRetencionRequired = false;
        vm.isImporteDisabled = false;
        vm.isMonedaDisabled = false;
        vm.multiplesproveedores = false;
        if (!vm.firstSelected) {
            delete vm.ordenPago.planillaFondoFijo;
            delete vm.ordenPago.calcularRetencion;
        }
    }

    function normalSelected() {
        vm.isPlanillaFondoFijoRequired = false;
        vm.isCuentaContableRequired = false;
        vm.isProveedorRequired = true;
        vm.isItemsRequired = true;
        vm.isItemsEditable = true;
        vm.isImporteDisabled = true;
        vm.isCalcularRetencionRequired = true;
        vm.isMonedaDisabled = false;
        if (!vm.firstSelected) {
            delete vm.ordenPago.cuentaContable;
            delete vm.ordenPago.planillaFondoFijo;
        }
        vm.multiplesproveedores = false;
        getValueParametroRetencion();
    }

    function multiplesProveedoresSelected() {
        vm.isPlanillaFondoFijoRequired = false;
        vm.isCuentaContableRequired = false;
        vm.isProveedorRequired = true;
        vm.isItemsRequired = true;
        vm.isItemsEditable = true;
        vm.isImporteDisabled = true;
        vm.isCalcularRetencionRequired = true;
        vm.isMonedaDisabled = false;
        if (!vm.firstSelected) {
            delete vm.ordenPago.cuentaContable;
            delete vm.ordenPago.planillaFondoFijo;
        }
        vm.multiplesproveedores = true;
        getValueParametroRetencion();
    }

    function multiplesProveedoresSelectedAnticipo() {
        vm.isPlanillaFondoFijoRequired = false;
        vm.isCuentaContableRequired = false;
        vm.isProveedorRequired = true;
        if (
                vm.aplicarAnticipo ||
                vm.ordenPago.estado.codigo == "opago_confirmada_anticipo"
                ) {
            vm.isItemsRequired = true;
            vm.isItemsEditable = true;
        } else {
            vm.isItemsRequired = false;
            vm.isItemsEditable = false;
        }
        vm.isCalcularRetencionRequired = false;
        vm.ordenPago.calcularRetencion = false;
        vm.isImporteRequired = true;
        vm.isImporteDisabled = false;
        vm.isMonedaDisabled = false;
        vm.multiplesproveedores = true;
        if (!vm.firstSelected) {
            delete vm.ordenPago.cuentaContable;
            delete vm.ordenPago.planillaFondoFijo;
            delete vm.ordenPago.calcularRetencion;
        }

    }

    function proveedorSelected() {
        if (vm.ordenPago.proveedor) {
            vm.ordenPago.beneficiario = vm.ordenPago.proveedor.nombre;
            if (!vm.ordenPago.proveedor.calcularRetencion){
                vm.ordenPago.calcularRetencion = false;
            }
        }
        addProveedorFilter();
    }

    /* Esta funcion se encarga de agregar el filtro proveedor. Fue intencionalmente separada de proveedorSelected por la necesidad
     de separar el seteo del beneficiario de la tarea de agregar el filtro */
    function addProveedorFilter() {

        vm.staticFilterComprobantes = _.filter(
                vm.staticFilterComprobantes,
                function (filter) {
                    return filter.path != "proveedor.id";
                }
        );
        vm.staticFilterPlanPagoDetalle = _.filter(
                vm.staticFilterPlanPagoDetalle,
                function (filter) {
                    return filter.path != "planPagos.comprobante.proveedor.id";
                }
        );

        if (!vm.multiplesproveedores) {
            if (vm.ordenPago.proveedor) {
                vm.staticFilterComprobantes.push({
                    path: "proveedor.id",
                    equals: vm.ordenPago.proveedor.id
                });
                vm.staticFilterPlanPagoDetalle.push({
                    path: "planPagos.comprobante.proveedor.id",
                    equals: vm.ordenPago.proveedor.id
                });
            }
        }

        var filtros;
        var filtrosPlanPagosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanPagosDetalle = prepareFilterVencimientoPlanPagoDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanPagosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanPagoDetalle).value()
            };
        }

        vm.opcionesComprobantesDt.staticFilter = filtros;
        vm.opcionesPlanPagoDetalleDt.staticFilter = filtrosPlanPagosDetalle;

        filtrarAgregados();
        if (vm.ordenPago.proveedor && !vm.multiplesproveedores) {
            if (vm.ordenPago.ordenPagoDetalles) {
                vm.ordenPago.ordenPagoDetalles = _.filter(
                        vm.ordenPago.ordenPagoDetalles,
                        function (detalle) {
                            return detalle.comprobante.proveedor.id == vm.ordenPago.proveedor.id;
                        }
                );
            }
        }
    }

    function fechaDesdeChanged() {
        vm.staticFilterComprobantes = _.filter(
                vm.staticFilterComprobantes,
                function (filter) {
                    return (
                            filter.path != "fecha" ||
                            (filter.path == "fecha" && !filter.equalOrAfter)
                            );
                }
        );
        vm.staticFilterPlanPagoDetalle = _.filter(
                vm.staticFilterPlanPagoDetalle,
                function (filter) {
                    return (
                            filter.path != "planPagos.comprobante.fecha" ||
                            (filter.path == "planPagos.comprobante.fecha" && !filter.equalOrAfter)
                            );
                }
        );
        vm.staticFilterComprobantes.push({
            path: "fecha",
            equalOrAfter: vm.search.fechaDesde != undefined ?
                    moment(vm.search.fechaDesde).format("DD/MM/YYYY") : undefined
        });
        vm.staticFilterPlanPagoDetalle.push({
            path: "planPagos.comprobante.fecha",
            equalOrAfter: vm.search.fechaDesde != undefined ?
                    moment(vm.search.fechaDesde).format("DD/MM/YYYY") : undefined
        });
        var filtros;
        var filtrosPlanPagosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanPagosDetalle = prepareFilterVencimientoPlanPagoDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanPagosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanPagoDetalle).value()
            };
        }
        vm.opcionesComprobantesDt.staticFilter = filtros;
        vm.opcionesPlanPagoDetalleDt.staticFilter = filtrosPlanPagosDetalle;
        filtrarAgregados();
    }

    function fechaHastaChanged() {
        vm.staticFilterComprobantes = _.filter(
                vm.staticFilterComprobantes,
                function (filter) {
                    return (
                            filter.path != "fecha" ||
                            (filter.path == "fecha" && !filter.equalOrBefore)
                            );
                }
        );
        vm.staticFilterPlanPagoDetalle = _.filter(
                vm.staticFilterPlanPagoDetalle,
                function (filter) {
                    return (
                            filter.path != "planPagos.comprobante.fecha" ||
                            (filter.path == "planPagos.comprobante.fecha" && !filter.equalOrBefore)
                            );
                }
        );
        vm.staticFilterComprobantes.push({
            path: "fecha",
            equalOrBefore: vm.search.fechaHasta ?
                    moment(vm.search.fechaHasta).format("DD/MM/YYYY") : undefined
        });

        vm.staticFilterPlanPagoDetalle.push({
            path: "planPagos.comprobante.fecha",
            equalOrBefore: vm.search.fechaHasta ?
                    moment(vm.search.fechaHasta).format("DD/MM/YYYY") : undefined
        });

        var filtros;
        var filtrosPlanPagosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanPagosDetalle = prepareFilterVencimientoPlanPagoDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanPagosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanPagoDetalle).value()
            };
        }

        vm.opcionesComprobantesDt.staticFilter = filtros;
        vm.opcionesPlanPagoDetalleDt.staticFilter = filtrosPlanPagosDetalle;
        filtrarAgregados();
    }

    function fechaVencimientoDesdeChanged() {
        vm.staticFilterComprobantes = _.filter(
                vm.staticFilterComprobantes,
                function (filter) {
                    return (
                            filter.path != "planPagos.planPagosDetalles.fechaVencimiento" ||
                            (filter.path == "planPagos.planPagosDetalles.fechaVencimiento" &&
                                    !filter.equalOrBefore)
                            );
                }
        );

        vm.staticFilterPlanPagoDetalle = _.filter(
                vm.staticFilterPlanPagoDetalle,
                function (filter) {
                    return (
                            filter.path != "fechaVencimiento" ||
                            (filter.path == "fechaVencimiento" &&
                                    !filter.equalOrBefore)
                            );
                }
        );

        vm.staticFilterComprobantes.push({
            path: "planPagos.planPagosDetalles.fechaVencimiento",
            equalOrAfter: vm.search.fechaVencimientoDesde != undefined ?
                    moment(vm.search.fechaVencimientoDesde).format("DD/MM/YYYY") : undefined
        });

        vm.staticFilterPlanPagoDetalle.push({
            path: "fechaVencimiento",
            equalOrAfter: vm.search.fechaVencimientoDesde != undefined ?
                    moment(vm.search.fechaVencimientoDesde).format("DD/MM/YYYY") : undefined
        });

        var filtros = prepareFilterVencimiento();
        var filtrosPlanPagosDetalle = prepareFilterVencimientoPlanPagoDetalle();
        vm.opcionesComprobantesDt.staticFilter = filtros;
        vm.opcionesPlanPagoDetalleDt.staticFilter = filtros;
        filtrarAgregados();
    }

    function fechaVencimientoHastaChanged() {
        vm.staticFilterComprobantes = _.filter(
                vm.staticFilterComprobantes,
                function (filter) {
                    return (
                            filter.path != "planPagos.planPagosDetalles.fechaVencimiento" ||
                            (filter.path == "planPagos.planPagosDetalles.fechaVencimiento" &&
                                    !filter.equalOrAfter)
                            );
                }
        );
        vm.staticFilterPlanPagoDetalle = _.filter(
                vm.staticFilterPlanPagoDetalle,
                function (filter) {
                    return (
                            filter.path != "fechaVencimiento" ||
                            (filter.path == "fechaVencimiento" &&
                                    !filter.equalOrAfter)
                            );
                }
        );
        vm.staticFilterComprobantes.push({
            path: "planPagos.planPagosDetalles.fechaVencimiento",
            equalOrBefore: vm.search.fechaVencimientoHasta != undefined ?
                    moment(vm.search.fechaVencimientoHasta).format("DD/MM/YYYY") : undefined
        });
        vm.staticFilterPlanPagoDetalle.push({
            path: "fechaVencimiento",
            equalOrBefore: vm.search.fechaVencimientoHasta != undefined ?
                    moment(vm.search.fechaVencimientoHasta).format("DD/MM/YYYY") : undefined
        });
        var filtros = prepareFilterVencimiento();
        var filtrosPlanPagosDetalle = prepareFilterVencimientoPlanPagoDetalle();

        vm.opcionesComprobantesDt.staticFilter = filtros;
        vm.opcionesPlanPagoDetalleDt.staticFilter = filtrosPlanPagosDetalle;
        filtrarAgregados();
    }

    function prepareFilterVencimiento() {
        vm.otherFilters = _.filter(vm.staticFilterComprobantes, function (filter) {
            return filter.path != "planPagos.planPagosDetalles.fechaVencimiento";
        });

        vm.otherFilters.push({
            path: "planPagos.id",
            isNull: "true"
        });

        var filtros = {
            search: filterFactory
                    .or([
                        filterFactory.and(vm.staticFilterComprobantes).value(),
                        filterFactory.and(vm.otherFilters).value()
                    ])
                    .value()
        };
        return filtros;
    }

    function prepareFilterVencimientoPlanPagoDetalle() {
        vm.otherFilters = _.filter(vm.staticFilterPlanPagoDetalle, function (filter) {
            return filter.path != "fechaVencimiento";
        });

        vm.otherFilters.push({
            path: "planPagos.id",
            isNull: "true"
        });

        var filtros = {
            search: filterFactory
                    .or([
                        filterFactory.and(vm.staticFilterPlanPagoDetalle).value(),
                        filterFactory.and(vm.otherFilters).value()
                    ])
                    .value()
        };
        return filtros;
    }

    function tipoComprobanteChanged() {
        vm.staticFilterComprobantes = _.filter(
                vm.staticFilterComprobantes,
                function (filter) {
                    return filter.path != "tipo.id";
                }
        );
        vm.staticFilterPlanPagoDetalle = _.filter(
                vm.staticFilterPlanPagoDetalle,
                function (filter) {
                    return filter.path != "planPagos.comprobante.tipo.id";
                }
        );
        vm.staticFilterComprobantes.push({
            path: "tipo.generarCuotas",
            like: "false"
        });

        var filtros;
        var filtrosPlanPagosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanPagosDetalle = prepareFilterVencimientoPlanPagoDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanPagosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanPagoDetalle).value()
            };
        }
        vm.opcionesComprobantesDt.staticFilter = filtros;
        vm.opcionesPlanPagoDetalleDt.staticFilter = filtrosPlanPagosDetalle;
        filtrarAgregados();
    }

    function monedaFilterChanged() {
        vm.staticFilterComprobantes = _.filter(
                vm.staticFilterComprobantes,
                function (filter) {
                    return filter.path != "moneda1.id";
                }
        );
        vm.staticFilterPlanPagoDetalle = _.filter(
                vm.staticFilterPlanPagoDetalle,
                function (filter) {
                    return filter.path != "planPagos.comprobante.moneda1.id";
                }
        );
        vm.staticFilterComprobantes.push({
            path: "moneda1.id",
            equals: vm.search.moneda.id
        });
        vm.staticFilterPlanPagoDetalle.push({
            path: "planPagos.comprobante.moneda1.id",
            equals: vm.search.moneda.id
        });

        var filtros;
        var filtrosPlanPagosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanPagosDetalle = prepareFilterVencimientoPlanPagoDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanPagosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanPagoDetalle).value()
            };
        }
        vm.opcionesComprobantesDt.staticFilter = filtros;
        vm.opcionesPlanPagoDetalleDt.staticFilter = filtrosPlanPagosDetalle;
        filtrarAgregados();
    }

    function clearFiltrosComprobantes() {
        vm.search = {};
        vm.staticFilterComprobantes = _.filter(
                vm.staticFilterComprobantes,
                function (filtro) {
                    var result = !(
                            filtro.path == "fecha" ||
                            filtro.path == "planPagos.planPagosDetalles.fechaVencimiento" ||
                            filtro.path == "tipo.id" ||
                            filtro.path == "moneda1.id" ||
                            filtro.path == "importacion.despacho.numero"
                            );
                    return result;
                }
        );
        vm.staticFilterPlanPagoDetalle = _.filter(
                vm.staticFilterPlanPagoDetalle,
                function (filtro) {
                    var result = !(
                            filtro.path == "planPagos.comprobante.fecha" ||
                            filtro.path == "fechaVencimiento" ||
                            filtro.path == "planPagos.comprobante.tipo.id" ||
                            filtro.path == "planPagos.comprobante.moneda1.id" ||
                            filtro.path == "planPagos.comprobante.importacion.despacho.numero"
                            );
                    return result;
                }
        );
        filtros = {
            search: filterFactory.and(vm.staticFilterComprobantes).value()
        };
        filtrosPlanPagosDetalle = {
            search: filterFactory.and(vm.staticFilterPlanPagoDetalle).value()
        };
        vm.opcionesComprobantesDt.staticFilter = filtros;
        vm.opcionesPlanPagoDetalleDt.staticFilter = filtrosPlanPagosDetalle;
        filtrarAgregados();
        vm.montoTotal = 0;
    }

    function deleteDetalle(detalle) {
        if (vm.ordenPago.tipoOrdenPago.clase.codigo === 'clase_orden_pago_fondo_fijo') {
            // No se pueden borrar detalles de Planillas de Fondo Fijo
            return;
        }

        _.remove(vm.ordenPago.ordenPagoDetalles, function (d) {
            if (detalle.cuota && d.cuota) {
                return d.cuota.id == detalle.cuota.id;
            }
            return d.comprobante.id == detalle.comprobante.id;
        });
        filtrarAgregados();

        var saldo = detalle.cuota != undefined? detalle.cuota.saldo:detalle.comprobante.saldo;
        if (saldo > detalle.importePagar){
            /* ordenar */
            vm.listaOrdenadaCuotas = [];
            var listaOtros = [];
            var listaNoCuotas = [];

            _.forEach(vm.ordenPago.ordenPagoDetalles, function(anterior){
                if (anterior.cuota != undefined){
                    if (detalle.cuota.planPagos.comprobante.numero === anterior.cuota.planPagos.comprobante.numero){
                        vm.listaOrdenadaCuotas.push(anterior.cuota);
                    }else{
                        listaOtros.push(anterior);
                    }
                }else{
                    listaNoCuotas.push(anterior);
                }
            })
            vm.listaComprobantesConRetencion = [];
            vm.ordenPago.ordenPagoDetalles = [];

            _.forEach(listaNoCuotas, function(comprobante){
                vm.ordenPago.ordenPagoDetalles.push(comprobante);
            })
            _.forEach(listaOtros, function(cuota){
                vm.ordenPago.ordenPagoDetalles.push(cuota);
            })
            var numeroComprobanteJ, numeroComprobanteJ1;
            for (var i=0; i<vm.listaOrdenadaCuotas.length; i++){
                for (var j=vm.listaOrdenadaCuotas.length-1; j>i; j--){
                    numeroComprobanteJ = vm.listaOrdenadaCuotas[j].planPagos.comprobante.numero;
                    numeroComprobanteJ1 = vm.listaOrdenadaCuotas[j-1].planPagos.comprobante.numero;
                    if (numeroComprobanteJ < numeroComprobanteJ1 ||
                        ((numeroComprobanteJ === numeroComprobanteJ1 && vm.listaOrdenadaCuotas[j].numero < vm.listaOrdenadaCuotas[j-1].numero))){
                        var aux = vm.listaOrdenadaCuotas[j];
                        vm.listaOrdenadaCuotas[j] = vm.listaOrdenadaCuotas[j-1];
                        vm.listaOrdenadaCuotas[j-1] = aux;
                    }
                }
            }

            vm.ordenPago.ordenPagoDetalles = _.reduce(
                vm.listaOrdenadaCuotas,
                function (response, val) {
                    val.$promise.then(storeCuota(response));
                    return response;
                }, vm.ordenPago.ordenPagoDetalles || []
            );
        }

    }

    function calcularMontoTotal() {
        filtrarAgregados();
        var cantDecimales = 0;
        if (vm.ordenPago.moneda.codigo != 'guaranies') {
            cantDecimales = 2;
        }
        vm.montoTotal = _.reduce(vm.ordenPago.ordenPagoDetalles, function (sum, opDetalle) {
            var importeRetencion = 0;
            // se modifica el importe segun la retencion
            var operacion = "mult";
            if (vm.ordenPago.moneda.codigo != 'guaranies') {
                if (opDetalle.comprobante.moneda1.codigo == 'guaranies') {
                    operacion = "div";
                }
            }
            if (vm.view || vm.isEdit){
                if (opDetalle.cuota && opDetalle.importePagar+opDetalle.montoRetenido != opDetalle.cuota.saldo){
                    importeRetencion = 0;
                }else{
                    if (opDetalle.importeAretener!=0){
                        importeRetencion = opDetalle.importeAretener;
                    }else{
                        importeRetencion = opDetalle.montoRetenido;
                    }

                }
            }else{
                importeRetencion = opDetalle.importeAretener + opDetalle.montoRetenido;
            }

            // la moneda de la retencion es la moneda del comprobante, por tanto es la operacion contraria a importe a pagar
            if (operacion == "mult") {
                importeRetencion = parseFloat(importeRetencion * opDetalle.tipoCambio);
            } else {
                importeRetencion = parseFloat(importeRetencion / opDetalle.tipoCambio);
            }
            return parseFloat(sum) + parseFloat(opDetalle.importePagar) + parseFloat(importeRetencion);
        }, 0);
        vm.montoTotal = parseFloat(vm.montoTotal).toFixed(cantDecimales);
    }

    function searchPlanillaFondoFijo(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                    item.numero == criteria ||
                    item.tipoFondoFijo.nombre.indexOf(criteria) === 0
                    );
        };
    }

    function descargarAdjunto(adjunto) {

        return baseurl.getPublicBaseUrl() + adjunto;
    }

    function clearAdjuntos() {
        var flow = $rootScope.flow;
        if (!flow) {
          return;
        }
        flow.files = [];
    };

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function(item) {
          return item === elemento;
        });
    }

    function adjuntarArchivos(ordenPago) {
        // se agregan los adjuntos de imagenes
        var flowAdjunto = vm.uploadOptions2.flow;
        if (flowAdjunto) {
          var flowFilesAdjunto = flowAdjunto.files;
          var index = 0;
          if (!ordenPago.adjuntos) {
            ordenPago.adjuntos = [];
          } else {
            index = ordenPago.adjuntos.length;
          }
          if (flowFilesAdjunto.length > 0) {
            angular.forEach(flowFilesAdjunto, function (file) {

                // Continuar con la lógica para archivos válidos
                ordenPago.adjuntos[index] = {};
                ordenPago.adjuntos[index].path = file.uniqueIdentifier + '-' + file.name;
                ordenPago.adjuntos[index].nombreArchivo = file.name;
                index++;
            });
          }
        }
    }

    $scope.resource = "ordenpago"; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

angular.module('qualita.tesoreria')
    .controller('OrdenPagoRRHHFormCtrl', OrdenPagoRRHHFormCtrl);

OrdenPagoRRHHFormCtrl.$inject = ['$rootScope', '$scope', 'OrdenPagoFactory', 'TipoOrdenPagoFactory', 'ordenPagoPrepService',
    'TesoreriaLangFactory', '$state', 'CotizacionMonedaFactory', 'TiposTipoFactory', 'FormaPagoFactory',
    'ParametrosFactory', 'ModelTrimmer', 'formFactory', '$location', 'notify', 'filterFactory', 'observeOnScope',
    '$q', '$translate', 'WatchLocacionChangeFactory', 'UtilFactory', 'TipoRetencionFactory', '$filter', 'empleadoFactory'
];

function OrdenPagoRRHHFormCtrl($rootScope, $scope, OrdenPagoFactory, TipoOrdenPagoFactory, ordenPagoPrepService,
    TesoreriaLangFactory, $state, CotizacionMonedaFactory, TiposTipoFactory, FormaPagoFactory,
    ParametrosFactory, ModelTrimmer, formFactory, $location, notify, filterFactory, observeOnScope,
    $q, $translate, WatchLocacionChangeFactory, UtilFactory, TipoRetencionFactory, $filter, empleadoFactory) {

    var vm = this;

    vm.cancel = cancel;
    vm.submit = submit;
    vm.removeItemFromArray = removeItemFromArray;
    vm.onChangeEmpleado = onChangeEmpleado;

    $rootScope.isProcessing = false;

    function removeItemFromArray(elemento) {
        _.remove(vm.ordenPago.ordenPagoDetalles, function(item) {
            return item === elemento;
        });
    }

    activate();

    function activate() {
        WatchLocacionChangeFactory.killAllWatchers();

        var paramsTipo = {};
        paramsTipo.search = filterFactory.single({
            path: 'activo',
            equals: true
        }).value();
        // solo OPs de tipo tesoreria (normal, fondo fijo, cuenta contable, anticipo)
        var tiposOP = TipoOrdenPagoFactory.all(paramsTipo);
        tiposOP.$promise.then(function(response) {
            vm.tiposOrdenPago = _.filter(response, function(filter) {
                return filter.clase.codigo != "clase_orden_pago_normal" &&
                    filter.clase.codigo != "clase_orden_pago_anticipo" &&
                    filter.clase.codigo != "clase_orden_pago_fondo_fijo" &&
                    filter.clase.codigo != "clase_orden_pago_cuenta_contable"
            });
        });

        vm.estados = TiposTipoFactory.get("estado_orden_pago");
        vm.formasPago = FormaPagoFactory.all();
        vm.empleados = empleadoFactory.all();
        vm.OrdenPagoForm = $scope.OrdenPagoForm;

        TesoreriaLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;
            if ($state.is("app.ordenpago.edit2")) {
                activateEdit();
            } else if ($state.is("app.ordenpago.view2")) {
                activateView();
            }
            $rootScope.isProcessing = false;
            vm.submited = false;

        });
    }

    function activateEdit() {
        vm.isEdit = true;
        vm.title = $filter('translate')('EDIT_ORDEN_PAGO');
        vm.ordenPago = ordenPagoPrepService;
        vm.ordenPago.fecha = new Date(vm.ordenPago.fecha);
        _.forEach(vm.ordenPago.ordenPagoDetalles, function(opDet) {
            // opDet tiene empleadoId como referencia, guardar en empleado para mostrar en el combo
            opDet.empleado = empleadoFactory.get(opDet.empleadoId);
        });
    }

    function activateView() {
        vm.view = true;
        vm.title = $filter('translate')('VIEW_ORDEN_PAGO');
        vm.ordenPago = ordenPagoPrepService;
        vm.ordenPago.fecha = new Date(vm.ordenPago.fecha);
        _.forEach(vm.ordenPago.ordenPagoDetalles, function(opDet) {
            // opDet tiene empleadoId como referencia, guardar en empleado para mostrar en el combo
            opDet.empleado = empleadoFactory.get(opDet.empleadoId);
        });
    }

    function onChangeEmpleado(detalle) {
        console.log("onChangeEmpleado");
        detalle.empleadoId = detalle.empleado.id;
    }

    function submit() {
        vm.submited = true;
        if ($scope.OrdenPagoForm.$valid) {
            // var trimmedModel = ModelTrimmer.trimDetails(vm.ordenPago, ["ordenPagoDetalles"]);
            // if (trimmedModel.ordenPagoDetalles) {
            //     trimmedModel.ordenPagoDetalles = _.map(trimmedModel.ordenPagoDetalles, function(detalle) {
            //         return ModelTrimmer.trimDetails(detalle);
            //     });
            // }
            // if (!vm.isEdit) {
            //     trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
            // }
            formFactory.defaultNSFSubmit($scope.OrdenPagoForm, OrdenPagoFactory, vm.ordenPago).then(function(response) {
                ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                    if (parametro.valorTipo.codigo == 'si') {
                        if(response.enviadoAContabilidad == false){
                            notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                        }
                    }
                });
                $location.path('/ordenpago');
            }, function(error) {
                console.log(error);
            });
        } else {
            console.log("Form invalid");
            console.log($scope.OrdenPagoForm);
            notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right' });
        }
    }

    function cancel() {
        $location.path('/ordenpago');
    }

    $scope.resource = 'ordenpago'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}
angular
    .module('qualita.tesoreria')
    .directive('detalleOrdenPago', detalleOrdenPago);

function detalleOrdenPago() {
    var directive = {
        restrict: 'E',
        scope: {
            modelo: '=',
            moneda: '=',
            edit: '=',
            view: '=',
            modoAnticipo: '=',
            submited: '=',
            areItemsEditable: '=',
            canDeleteItems: '=',
            viewModel: '=',
            onDelete: '&',
            onImportePagarChanged: '&',
            form: '=',
            canChangeCambio: '=',
            isAnticipo: '=',
            arrayPosition: '='      // Agregado para diferenciar detalles
        },
        controllerAs: 'vm',
        bindToController: true,
        templateUrl: 'qualita-components/tesoreria/views/directives/detalleordenpago.directive.html',
        link: linkFunc,
        controller: DetalleOrdenPagoController
    };

    function linkFunc(scope, elem, attr) {}

    return directive;
}

DetalleOrdenPagoController.$inject = ['TesoreriaLangFactory', '$log', '$scope', '$timeout'];

function DetalleOrdenPagoController(TesoreriaLangFactory, $log, $scope, $timeout) {
    var vm = this;
    vm.incluir = false;
    vm.importePagarChanged = importePagarChanged;
    vm.plusClicked = plusClicked;
    vm.tipoCambioChanged = tipoCambioChanged;
    vm.deleteClicked = deleteClicked;

    activate();

    function activate() {
        // TODO: Recuperar cotización del día si existe
        // TODO: Cuando es dolares no está calculando la retención
        // TODO: No está redondeando importe retenido e importe pagado (view)
        TesoreriaLangFactory.getTranslations().then(function(translations) {
            vm.translations = translations;
        });
        vm.mostrarAdicionales = false;
        if (!vm.modelo.tipoCambio) {
            if (vm.modelo.comprobante.$promise) {
                vm.modelo.comprobante.$promise.then(function(comprobante) {
                    vm.modelo.tipoCambio = (vm.moneda.codigo == comprobante.moneda1.codigo) ? 1 : undefined;
                });
            } else {
                vm.modelo.tipoCambio = (vm.moneda.codigo == vm.modelo.comprobante.moneda1.codigo) ? 1 : undefined;
            }
        }
    }

    function importePagarChanged() {
        //TODO: Validar mayor a 0 y menor a saldo
        vm.incluir = true;
        $timeout(vm.onImportePagarChanged, 0);
    }

    function plusClicked() {
        vm.mostrarAdicionales = !vm.mostrarAdicionales;
    }

    function deleteClicked() {
        $timeout(vm.onDelete, 0);
        $timeout(vm.onImportePagarChanged, 0);
    }

    function tipoCambioChanged() {
        var saldo = vm.modelo.cuota ? vm.modelo.cuota.saldo : vm.modelo.comprobante.saldo;

        var cantDecimales = 0;
        var operacion = "mult";
        if (vm.moneda.codigo != 'guaranies') {
            cantDecimales = 2;
            if (vm.modelo.comprobante.moneda1.codigo == 'guaranies') {
                operacion = "div";
            }
        }
        // Observacion: los montos de la retención NO se deben convertir, van siempre en la moneda del comprobante.
        if (operacion == "mult") {
            //vm.viewModel.saldoConvertido = parseFloat((saldo * vm.modelo.tipoCambio).toFixed(cantDecimales));
            vm.viewModel.saldoConvertido = Math.round((saldo * vm.modelo.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
            if (!vm.view) {
                vm.modelo.importePagar = vm.modelo.saldoConvertido - Math.round(((vm.modelo.importeAretener + vm.modelo.montoRetenido) * vm.modelo.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
            
            }

        } else {
            vm.viewModel.saldoConvertido = Math.round((saldo / vm.modelo.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
            if (!vm.view) {
                vm.modelo.importePagar = vm.modelo.saldoConvertido - Math.round(((vm.modelo.importeAretener + vm.modelo.montoRetenido) / vm.modelo.tipoCambio) * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
            }
        }
        vm.modelo.importePagar = Math.round(vm.modelo.importePagar * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales);
        $timeout(vm.onImportePagarChanged, 0);
    }
}
'use strict';


angular.module('qualita.tesoreria')
    .controller('EstadoCuentaProveedorIndexCtrl', ["$scope", "MovimientoCtaCteFactory", "filterFactory", "$window", "TesoreriaLangFactory", "$modal", "$state", "TiposFactory", "ProveedoresFactory", "notify", "UtilFactory", "ReportTicketFactory", "$filter", "CsvFactory", "baseurl", "TipoProveedorFactory", "AuthorizationService", function($scope, MovimientoCtaCteFactory, filterFactory,
        $window, TesoreriaLangFactory, $modal, $state, TiposFactory, ProveedoresFactory, notify, UtilFactory,
        ReportTicketFactory, $filter, CsvFactory, baseurl, TipoProveedorFactory, AuthorizationService) {
        $scope.boolean = false;
        $scope.tiposProveedor = TipoProveedorFactory.all(undefined, "BaseList");

        /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
        $scope.staticFilterProv = [{path: "activo",equals: true}];
        $scope.proveedoresFactory = ProveedoresFactory;

        //Verificacion de permiso para recalculo
        $scope.recalculoPermission = AuthorizationService.hasPermission("recalcular_movimientobancario");

        TesoreriaLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;
            var importeRender = function(data, type, row) {
                if (data) {
                    var moneda = "Gs. ";
                    if (row.moneda.codigo === 'dolares') {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    } else {
                        // si es guaranies no debe tener comas (,)
                        data = parseFloat(data).toFixed(0);
                    }
                    return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return 0;
                }
            };

            // TODO mas adelante agregar filtro para que traiga solo los de proveedores y  no clientes
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'proveedor.id',
                equals: 0
            }]).value();
            var defaultColumnOrder = ['id', 'descripcion', 'proveedor.nombre', 'proveedor.claseProveedor.descripcion', 'moneda.descripcion', 'tipoMovimiento.descripcion', 'fecha', 'entrada', 'salida', 'saldo'
            ];
            $scope.options = {
                'staticFilter': staticFilter,
                'resource': 'movimientoctacte',
                'view': 'EstadoCuentaProveedorList',
                'title': $filter('translate')('ESTADO_CUENTA_PROVEEDOR'),
                'factory': MovimientoCtaCteFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('FAIL_DELET_ENTIDAD'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('ID'), visible: false, orderable: false  },
                    { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION'), 'renderWith': 'emptyRender', orderable: false },
                    { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX'), orderable: false },
                    { 'data': 'proveedor.claseProveedor.descripcion', 'title': $filter('translate')('PROVEEDOR_CLASS'), 'type': 'combo', 'filterUrl': 'tipos/filtro/proveedor_clases', 'class': 'dt-center', orderable: false },
                    { 'data': 'moneda.descripcion', 'title': $filter('translate')('CURRENCY'), 'renderWith': 'emptyRender', orderable: false },
                    { 'data': 'tipoMovimiento.descripcion', 'title': $filter('translate')('MOVEMENT_TYPE'), orderable: false },
                    { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', class: 'dt-center', 'type': 'date-range' },
                    { 'data': 'entrada', 'title': $filter('translate')('ENTRADA_MOVIMIENTO_PROVEEDOR'), 'renderWith': importeRender, 'class': 'dt-right', orderable: false  },
                    { 'data': 'salida', 'title': $filter('translate')('SALIDA_MOVIMIENTO_PROVEEDOR'), 'renderWith': importeRender, 'class': 'dt-right', orderable: false  },
                    { 'data': 'saldo', 'title': $filter('translate')('SALDO'), 'renderWith': importeRender, 'class': 'dt-right', orderable: false  }
                ],
                'hasOptions': false,
                'hideAddMenu': true,
                'defaultOrderColumn': 6,
                'defaultOrderDir': "asc",
                'extraMenuOptions': [/*{
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function() { return true; },
                        'action': function() {
                            if ($scope.FiltrosForm.$valid) {
                                var filtersArr = [];
                                if ($scope.proveedor) {
                                    filtersArr.push("idProveedor=" + $scope.proveedor.id);
                                }
                                if ($scope.fechaDesde) {
                                    filtersArr.push("fechaDesde=" + $filter('date')($scope.fechaDesde, "MM/dd/yyyy"));
                                }
                                if ($scope.fechaHasta) {
                                    filtersArr.push("fechaHasta=" + $filter('date')($scope.fechaHasta, "MM/dd/yyyy"));
                                }
                                if ($scope.moneda) {
                                    filtersArr.push("moneda=" + $scope.moneda.id);
                                }
                                var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("estadocuentaproveedor", 'pdf', filtersArr);
                                $window.open(pdfDownloadURL, '_blank');
                            } else {
                                var msg = $filter('translate')('ERROR_FILTERING_ESTADO_CUENTA_PROVEEDOR');
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },*/
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("movimientoctacte", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                .then(function(response) {
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    }
                ]
            };

            $scope.boolean = true;
            var createFilters = function(filters) {
                var filtersArr = [];
                _.each(filters, function(search, data) {
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }

            $scope.saldoInicial = 0;
            $scope.proveedor = undefined;
            $scope.moneda = undefined;
            $scope.fechaDesde = undefined;
            $scope.fechaHasta = undefined;
            TiposFactory.monedas().then(function(response) {
                $scope.monedas = response.data;
            });

            $scope.limpiarFiltros = function() {
                $scope.saldoInicial = 0;
                $scope.tipoProveedor = undefined;
                $scope.proveedor = undefined;
                $scope.moneda = undefined;
                $scope.fechaDesde = undefined;
                $scope.fechaHasta = undefined;
            }

            $scope.recalcularSaldos = function () {

                $scope.mensajeModal = $filter('translate')('WARNING_RECALCULO_SALDO');
                $scope.tituloModal = $filter('translate')('WARNING_HEADER_RECALCULO');
                var modalInstance = $modal.open({
                    templateUrl: "views/directives/confirmation-modal.html",
                    scope: $scope
                });

                $scope.cancel = function() {
                    modalInstance.dismiss("cancel");
                };

                $scope.ok = function() {
                    if ($scope.proveedor) {

                        $scope.mensajeModal = $filter('translate')('PROCESS');
                        var modalInstance1 = $modal.open({
                            templateUrl: "views/directives/information-modal.html",
                            scope: $scope
                        });

                        MovimientoCtaCteFactory.recalcularSaldo($scope.proveedor.id,"proveedor").then(function(){
                            // success
                            modalInstance1.close();

                            $scope.mensajeModal = "Proceso finalizado con éxito";
                            $scope.ok = function() {
                                modalInstance.close();
                            }

                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/information-modal.html",
                                scope: $scope
                            });

                        }, function () {

                            //error
                            modalInstance1.close();

                            $scope.mensajeModal = "ERROR al recalcular saldo";
                            $scope.ok = function() {
                                modalInstance.close();
                            }

                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/information-modal.html",
                                scope: $scope
                            });
                        })
                        return
                    }

                    var msg = $filter('translate')('ERROR_FILTER_PROVEEDOR');
                    notify({message: msg, classes: 'alert-danger', position: 'right' });
                }
            }

            $scope.cambiarFiltros = function() {
                if ($scope.fechaDesde != undefined && $scope.fechaHasta != undefined && $scope.fechaDesde > $scope.fechaHasta) {
                    notify({ message: "La fecha desde de búsqueda no puede ser mayor a la fecha hasta.", classes: 'alert-danger', position: 'right' });
                } else {
                    if ($scope.fechaDesde == undefined || $scope.fechaHasta == undefined || ($scope.proveedor == undefined && $scope.grupoProveedor == undefined) || $scope.moneda == undefined) {
                        notify({ message: $filter('translate')('ERROR_FILTERING_ESTADO_CUENTA_PROVEEDOR'), classes: 'alert-danger', position: 'right' });
                    } else {
                        if ($scope.fechaDesde == null) $scope.fechaDesde = undefined;
                        if ($scope.fechaHasta == null) $scope.fechaHasta = undefined;

                        $scope.filtro = true;
                        var staticFilter = {};
                        var andFilters = [{
                            path: 'fecha',
                            equalOrAfter: ($scope.fechaDesde != undefined) ? moment($scope.fechaDesde).format('DD/MM/YYYY') : undefined
                        }, {
                            path: 'fecha',
                            equalOrBefore: ($scope.fechaHasta != undefined) ? moment($scope.fechaHasta).format('DD/MM/YYYY') : undefined
                        }, {
                            path: 'orden',
                            sortAsc: 'true'
                          }];

                        if ($scope.proveedor) {
                            andFilters.push({
                                path: 'proveedor.id',
                                equals: $scope.proveedor.id
                            });
                        } else if ($scope.grupoProveedor) {
                            andFilters.push({
                                path: 'proveedor.tipoProveedor.id',
                                equals: $scope.grupoProveedor.id
                            });
                        }

                        if ($scope.moneda) {
                            andFilters.push({
                                path: 'moneda.id',
                                equals: $scope.moneda.id
                            });
                        }

                        staticFilter.search = filterFactory.and(andFilters).value();
                        $scope.options.staticFilter = staticFilter;
                    }
                }


                // Calculo de saldo
                if($scope.fechaDesde!=undefined){
                    MovimientoCtaCteFactory.getSaldoInicial($scope.proveedor.id,moment.utc($scope.fechaDesde).format('DD/MM/YYYY'), 'proveedor', $scope.moneda.id).then(function(resp) {
                    $scope.saldoInicial=resp.data;

                    // Se redondea a guaranies el calculo del backend
                    $scope.saldoInicial = parseFloat($scope.saldoInicial).toFixed(0);

                    if($scope.saldoInicial===undefined) {
                      $scope.saldoInicial=0;}
                    });

                  }else{
                    $scope.saldoInicial=0;
                  }
            };

            $scope.changeTipo = function() {
                $scope.staticFilterProv = [{path: "activo",equals: true}];
                if ($scope.tipoProveedor.descripcion === "Todos") {
                    $scope.tipoProveedor = undefined;
                } else {
                    $scope.staticFilterProv.push({
                        path: 'tipoProveedor.id',
                        equals: $scope.tipoProveedor.id
                    });
                    $scope.proveedores = ProveedoresFactory.all($scope.staticFilterProv);
                }
            };
        })
    }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.
 * @description
 * #
 * Factory in the qualita.
 */
angular.module('qualita.tesoreria')
  .factory('MovimientoCtaCteFactory', ['$resource', 'baseurl', '$http', function($resource,  baseurl, $http) {

  	var MovimientoCtaCte = $resource( baseurl.getBaseUrl() + '/movimientoctacte/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params) {
        return MovimientoCtaCte.query(params);
      },

      get: function(id) {
        return MovimientoCtaCte.get({id: id});
      },

      create: function(attrs) {
        return new MovimientoCtaCte(attrs);
      },

      save: function(movimientoctacte) {
        return (movimientoctacte.id) ? movimientoctacte.$update() : movimientoctacte.$save();
      },

      remove: function(movimientoctacte) {
        return movimientoctacte.$remove();
      },

      confirmarMovimento: function(id){
        return $http({
          url: baseurl.getBaseUrl() + '/movimientoctacte/confirmarmovimiento/'+id,
          method: "PUT"
        });
      },


      getSaldoInicial: function(idCuenta, fecha, entidad, monedaId) {
        return $http({
                url: baseurl.getBaseUrl() + '/movimientoctacte/saldoanterior',
                method: "GET",
                params: {idCuenta: idCuenta,
                  fecha:fecha,
                  entidad: entidad,
                  monedaId: monedaId}
              });
      },

      recalcularSaldo: function(idCuenta, entidad) {
        return $http({
                url: baseurl.getBaseUrl() + '/movimientoctacte/recalcularsaldo',
                method: "PUT",
                params: {idCuenta: idCuenta,
                        entidad: entidad}
              });
      },

  }

}]);
angular.module('qualita.tesoreria')
    .factory('CuentaContableFactory', CuentaContableFactory);

    CuentaContableFactory.$inject = ['$resource', 'baseurl'];

    function CuentaContableFactory($resource, baseurl) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove
    };

    var CuentaContable = $resource( baseurl.getBaseUrl() + "/cuentacontable/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
    });

    return service;

  function all(params, view) {
    params = params ? params : [];
    params.view = view || "CuentaContableList";
    return CuentaContable.query(params);
  }

  function get(id) {
    return CuentaContable.get({id: id});
  }

  function create(attrs) {
    return new CuentaContable(attrs);
  }

  function save(cuentacontable) {
    return (cuentacontable.id) ? cuentacontable.$update() : cuentacontable.$save();
  }

  function remove(cuentacontable) {
    return cuentacontable.$remove();
  }
}

'use strict';


angular.module('qualita.tesoreria')
    .controller('ChequeIndexCtrl',
        ["$scope", "ChequeFactory", "TesoreriaLangFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "$filter", "UtilFactory", "CsvFactory", "baseurl", "ParametrosFactory", "notify", "TiposTipoFactory", "cuentaBancariaFactory", function ($scope, ChequeFactory, TesoreriaLangFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal, $window, $state, $filter, UtilFactory, CsvFactory, baseurl, ParametrosFactory, notify,
            TiposTipoFactory, cuentaBancariaFactory) {
            var renderEmptyData = function (data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };
            $scope.monedas = TiposTipoFactory.get("monedas");
            $scope.entidadBancarias=cuentaBancariaFactory.all();
            $scope.estados = TiposTipoFactory.get("estado_cheque");
            var vm = this;
            vm.searchCuentasFilter = searchCuentasFilter;
            vm.auxFiltro = false;
            vm.uiBlockuiConfig = {
              'bloquear': false,
              'message': 'Anulando Cheque...'
            };
            $scope.checkChequesDiferidos = false;
            // $scope.verTodos = true;

            function searchCuentasFilter(criteria) {
                return function (item) {
                  if (!criteria) {
                    return true;
                  }
                  return (
                    (item.numeroCuenta + "").indexOf(criteria.toLowerCase()) >
                    -1 ||
                    item.entidad.nombre
                      .toLowerCase()
                      .indexOf(criteria.toLowerCase()) > -1
                  );
                };
            };

            var monedaRender = function (data, type, row) {
                if (data) {
                    var moneda = "Gs. ";
                    if (row.chequera.moneda.codigo === 'dolares') {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.chequera.moneda.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.chequera.moneda.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.chequera.moneda.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    }
                    // TODO hacer esto mismo en todos los renders de moneda
                    return data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return '';
                }
            };

            $scope.getCheques = function () {
                vm.auxFiltro = true;

                /* definir como filtrar por cliente */
                var staticFilter = [];

                // Verificacion de 'fecha desde' menor a 'fecha hasta
                if ($scope.fechaDiferidoDesde > $scope.fechaDiferidoHasta) {
                    var msg = '';
                    msg += '\n\n' + $filter('translate')('INVALID_DATES_DIFERIDO') + "\n";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    return
                }

                // Verificacion de 'fecha emision desde' menor a 'fecha emision hasta
                if ($scope.fechaEmisionDesde > $scope.fechaEmisionHasta) {
                    var msg = '';
                    msg += '\n\n' + 'La fecha de inicio (Fecha Emision Desde) debe ser menor o igual a la fecha de fin (Fecha Emision Hasta).' + "\n";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    return
                }

                $scope.filtro = true;

                //Filtro de clientes
                // if ($scope.cliente) {
                //     staticFilter.push(
                //         {
                //             path: 'cobro.cliente.id',
                //             equals: $scope.cliente.id
                //         }
                //     )
                // }

                if ($scope.moneda) {
                    staticFilter.push(
                        {
                            path: 'chequera.moneda.id',
                            equals: $scope.moneda.id
                        }
                    )
                }

                if (vm.cuentaBancaria) {
                    staticFilter.push(
                        {
                            path: 'chequera.cuentaBancaria.id',
                            equals: vm.cuentaBancaria.id
                        }
                    )
                }

                if ($scope.fechaDiferidoDesde) {
                    staticFilter.push(
                        {
                            path: 'fechaPago',
                            equalOrAfter: moment($scope.fechaDiferidoDesde).format('DD/MM/YYYY')
                        }
                    )
                }

                if ($scope.fechaDiferidoHasta) {
                    staticFilter.push(
                        {
                            path: 'fechaPago',
                            equalOrBefore: moment($scope.fechaDiferidoHasta).format('DD/MM/YYYY')
                        }
                    )
                }

                if ($scope.fechaEmisionDesde) {
                    staticFilter.push(
                        {
                            path: 'fecha_emision',
                            equalOrAfter: moment($scope.fechaEmisionDesde).format('DD/MM/YYYY')
                        }
                    )
                }

                if ($scope.fechaEmisionHasta) {
                    staticFilter.push(
                        {
                            path: 'fecha_emision',
                            equalOrBefore: moment($scope.fechaEmisionHasta).format('DD/MM/YYYY')
                        }
                    )
                }

                // if ($scope.numeroCuenta) {
                //     staticFilter.push(
                //         {
                //             path: 'numeroCuenta',
                //             like: $scope.numeroCuenta
                //         }
                //     )
                // }

            //    if(!$scope.verTodos){

                 if ($scope.checkChequesDiferidos) {
                    staticFilter.push(
                        {
                            path: 'fechaPago',
                            isNull: "true"
                        }
                    )
                 }
                //  else{
                //     staticFilter.push(
                //         {
                //             path: 'fechaPago',
                //             isNotNull: "true"
                //         }
                //     )
                // }
            //    }
                if ($scope.nroCheque) {
                    staticFilter.push(
                        {
                            path: 'numero',
                            like: $scope.nroCheque
                        }
                    )
                }

                if ($scope.estado) {
                    staticFilter.push(
                        {
                            path: 'estado.descripcion',
                            like: $scope.estado.descripcion
                        }
                    )
                }

                if ($scope.beneficiario) {
                    staticFilter.push(
                        {
                            path: 'beneficiario',
                            like: $scope.beneficiario
                        }
                    )
                }

                // Se define el filtro final
                var filtros = {
                    search: filterFactory.and(staticFilter).value()
                };

                console.log("filtros ", filtros);

                $scope.options.staticFilter = filtros;
            };


            // Filtrar solo por cheques ya usados???
            TesoreriaLangFactory.getTranslations().then(function (translations) {
                var defaultColumnOrder = ['numero', 'estado.descripcion', 'fecha_emision', 'fechaPago', 'chequera.cuentaBancaria.nombreCuenta', 'chequera.cuentaBancaria.numeroCuenta', 'monto', 'beneficiario', 'concepto','chequera.moneda.descripcion'];
                $scope.options = {
                    'resource': 'cheques',
                    'title': 'Cheques',
                    'view': 'ChequeList',
                    'factory': ChequeFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'columns': [
                        { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-right', searchable: false  },
                        { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'filterUrl': 'tipos/filtro/estado_cheque', 'class': 'dt-center', searchable: false },
                        { 'data': 'fecha_emision', 'title': $filter('translate')('FECHA_EMISION'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', searchable: false },
                        { 'data': 'fechaPago', 'title': $filter('translate')('DATE_DIF'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', searchable: false },
                        { 'data': 'chequera.cuentaBancaria.numeroCuenta', 'title': $filter('translate')('ACCOUNT_NUMBER'), 'render': renderEmptyData, searchable: false},
                        { 'data': 'chequera.cuentaBancaria.nombreCuenta', 'title': $filter('translate')('ACCOUNT_NAME'), 'render': renderEmptyData, searchable: false },
                        { 'data': 'beneficiario', 'title': $filter('translate')('BENEFICIARIO'), 'render': renderEmptyData, searchable: false },
                        { 'data': 'concepto', 'title': $filter('translate')('CONCEPTO'), searchable: false },
                        { 'data': 'chequera.moneda.descripcion', 'title': $filter('translate')('CURRENCY'), 'class': 'dt-center', searchable: false },
                        { 'data': 'monto', 'title': $filter('translate')('MONTO_TOTAL'), 'class': 'dt-right', 'render': monedaRender, searchable: false  }

                    ],
                    'hasOptions': true,
                    'hideAddMenu': true,
                    'hideRemoveMenu': true,
                    'hideEditMenu': true,
                    'defaultOrderColumn': 0,
                    'defaultOrderDir': "asc",
                    'extraMenuOptions':
                        [

                            {
                                'title': 'Reporte',
                                'icon': 'glyphicon glyphicon-file',
                                'showCondition': function () { return true; },
                                'action': function () {
                                    // var filters = createFilters($scope.options.getFilters());
                                    // ReportTicketFactory.ticket('listadoCheques', filters, $scope.options.tableAjaxParams,
                                    //     $scope.options.currentColumnOrder).then(function (genTicket) {
                                    //         $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                    //         $window.open($scope.pdfDownloadURL, '_blank');
                                    //     });

                                 if(vm.auxFiltro){
                                     vm.filtersArr = [];
                                    //beneficiario
                                    if ($scope.beneficiario != undefined) {
                                        vm.filtersArr.push("beneficiario=" + $scope.beneficiario);
                                    } else {
                                        vm.filtersArr.push("beneficiario=" + null);
                                    }
                                    //fecha diferido desde
                                    if ($scope.fechaDiferidoDesde != undefined) {
                                        vm.filtersArr.push("fechaDesdeDiferido=" + $filter('date')($scope.fechaDiferidoDesde, "MM/dd/yyyy"));
                                    } else {
                                        vm.filtersArr.push("fechaDesdeDiferido=" + null);
                                    }
                                    //fecha diferido hasta
                                    if ($scope.fechaDiferidoHasta != undefined) {
                                        vm.filtersArr.push("fechaHastaDiferido=" + $filter('date')($scope.fechaDiferidoHasta, "MM/dd/yyyy"));
                                    } else {
                                        vm.filtersArr.push("fechaHastaDiferido=" + null);
                                    }
                                    //moneda
                                    if ($scope.moneda != undefined) {
                                        vm.filtersArr.push("idMoneda=" + $scope.moneda.id);
                                    } else {
                                        vm.filtersArr.push("idMoneda=" + null);
                                    }
                                    //fecha emision desde
                                    if ($scope.fechaEmisionDesde != undefined) {
                                        vm.filtersArr.push("fechaEmisionDesde=" + $filter('date')($scope.fechaEmisionDesde, "MM/dd/yyyy"));
                                    } else {
                                        vm.filtersArr.push("fechaEmisionDesde=" + null);
                                    }
                                    //fecha emision hasta
                                    if ($scope.fechaEmisionHasta != undefined) {
                                        vm.filtersArr.push("fechaEmisionHasta=" + $filter('date')($scope.fechaEmisionHasta, "MM/dd/yyyy"));
                                    } else {
                                        vm.filtersArr.push("fechaEmisionHasta=" + null);
                                    }
                                    //entidad bancaria
                                    if (vm.cuentaBancaria != undefined) {
                                        vm.filtersArr.push("cuentaBancariaId=" + vm.cuentaBancaria.id);
                                    } else {
                                        vm.filtersArr.push("cuentaBancariaId=" + null);
                                    }

                                    //nro cheque
                                    if ($scope.nroCheque != undefined) {
                                        vm.filtersArr.push("nroCheque=" + $scope.nroCheque);
                                    } else {
                                        vm.filtersArr.push("nroCheque=" + null);
                                    }

                                    //nro estado
                                    if ($scope.estado != undefined) {
                                        vm.filtersArr.push("estado=" + $scope.estado.id);
                                    } else {
                                        vm.filtersArr.push("estado=" + null);
                                    }
                                }
                                    vm.auxFiltro = false;

                                    $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('chequesEmitidos', 'pdf', vm.filtersArr);
                                    $window.open($scope.pdfDownloadURL, '_blank');


                                }
                            },
                            {
                                'title': 'CSV',
                                'icon': 'glyphicon glyphicon-export',
                                'showCondition': function () { return true; },
                                'action': function () {
                                    var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                    CsvFactory.csv("cheques", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                        .then(function (response) {
                                            var fileName = response.data.fileName;
                                            $window.open(baseurl.getPublicBaseUrl() + fileName);
                                        });
                                }
                            }
                        ],
                     'extraRowOptions': [
                        {
                            templateToRender: "<button class='btn btn-danger' title='" + $filter('translate')('ANULAR') + "' style='margin-right: 5px;' ng-click='anular(<%=dataCustom%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                            customAttribute: ['id','estado','numero','beneficiario','chequera','monto'],
                            functionName: "anular",
                            functionDef: function (atributo) {
                                var vIdCheque = atributo[0];
                                var vNrocheque = atributo[2];
                                var vBeneficiario = atributo[3];
                                var vChequera = atributo[4];
                                var vMonto = 0;
                                    if (vChequera) {
                                        var moneda = "Gs. ";
                                        var data = atributo[5];
                                        if (vChequera.moneda.codigo === 'dolares') {
                                            moneda = "Usd. ";
                                            data = parseFloat(data).toFixed(2);
                                        } else if (vChequera.moneda.codigo === 'pesos') {
                                            moneda = "Pes. ";
                                            data = parseFloat(data).toFixed(2);
                                        } else if (vChequera.moneda.codigo === 'real') {
                                            moneda = "Rel. ";
                                            data = parseFloat(data).toFixed(2);
                                        } else if (vChequera.moneda.codigo === 'euro') {
                                            moneda = "Eur. ";
                                            data = parseFloat(data).toFixed(2);
                                        }
                                        vMonto = moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    }
                                $scope.selectedItemId = vIdCheque;
                                $scope.tituloModal = $filter('translate')('ANULAR_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('CANCELLATION_OPERATION_WARNING',{numero:vNrocheque,beneficiario:vBeneficiario,monto:vMonto});

                                $scope.modalInstanceBorrar1 = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });
                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                }
                                $scope.ok = function (vIdCheque) {
                                  vm.uiBlockuiConfig.bloquear = true;
                                    ChequeFactory.anular(vIdCheque).$promise.then(
                                        function (response) {
                                          vm.uiBlockuiConfig.bloquear = false;
                                          console.log('response anular cheques');
                                          console.log(response);
                                          ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                            if (parametro.valorTipo.codigo == 'si') {
                                              if(response.enviadoAContabilidad == false){
                                                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                              }
                                              if(response.listaArchivosLogs != null){
                                                if(response.listaArchivosLogs.length != 0){
                                                  /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                    console.log('response.listaArchivosLogs: ');
                                                    console.log(response.listaArchivosLogs[i]);
                                                    $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                  }*/
                                                  notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                }
                                              }
                                            }
                                          });
                                          $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            vm.uiBlockuiConfig.bloquear = false;
                                            console.log("Hubo un error", error.data[0].message);
                                            var msg = error.data[0].message;
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        });
                                }
                            },
                            conditionName: "canAnular",
                            conditionDef: function (atributo) {
                                var vEstado = atributo[1];
                                return AuthorizationService.hasPermission("delete_cheques") && vEstado.codigo == "estado_cheque_emitido";
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-warning' title='" + $filter('translate')('Recuperar cheque') +
                            "' style='margin-right: 5px;' ng-click='recuperar(<%=dataCustom%>)' ng-class='{ hidden : !canRecuperar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-refresh'></span> </button>",
                           // customAttribute: 'estado',
                            customAttribute: ['id','estado','numero','fecha_emision','concepto','monto','chequera'],
                            functionName: "recuperar",
                            functionDef: function (atributo) {
                                var vIdCheque = atributo[0];
                                var vNrocheque = atributo[2];
                                var vFecha =  moment(atributo[3], 'DD/MM/YYYY HH:mm').format("DD/MM/YYYY");
                                var vConcepto = atributo[4];
                                var vMonto = 0;
                                var vChequera = atributo[6];
                                    if (vChequera) {
                                        var moneda = "Gs. ";
                                        var data = atributo[5];
                                        if (vChequera.moneda.codigo === 'dolares') {
                                            moneda = "Usd. ";
                                            data = parseFloat(data).toFixed(2);
                                        } else if (vChequera.moneda.codigo === 'pesos') {
                                            moneda = "Pes. ";
                                            data = parseFloat(data).toFixed(2);
                                        } else if (vChequera.moneda.codigo === 'real') {
                                            moneda = "Rel. ";
                                            data = parseFloat(data).toFixed(2);
                                        } else if (vChequera.moneda.codigo === 'euro') {
                                            moneda = "Eur. ";
                                            data = parseFloat(data).toFixed(2);
                                        }
                                        vMonto = moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    }
                                $scope.selectedItemId = vIdCheque;
                                $scope.tituloModal = $filter('translate')('Recuperar cheque');
                                $scope.mensajeModal = $filter('translate')('RETRIEVE_OPERATION_WARNING',{numero:vNrocheque,fecha:vFecha,concepto:vConcepto,monto:vMonto});

                                $scope.modalInstanceBorrar1 = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });
                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                }
                                $scope.ok = function (vIdCheque) {


                                    ChequeFactory.recuperar(vIdCheque).$promise.then(
                                        function (response) {
                                          console.log('response recuperar cheques');
                                          console.log(response);
                                          ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                            if (parametro.valorTipo.codigo == 'si') {
                                              if(response.enviadoAContabilidad == false){
                                                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                              }
                                              if(response.listaArchivosLogs != null){
                                                if(response.listaArchivosLogs.length != 0){
                                                  /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                    console.log('response.listaArchivosLogs: ');
                                                    console.log(response.listaArchivosLogs[i]);
                                                    $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                  }*/
                                                  notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                }
                                              }
                                            }
                                          });
                                          $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            var msg = error.data.error;
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        });
                                }
                            },
                            conditionName: "canRecuperar",
                            conditionDef: function (atributo) {
                                var vEstado = atributo[1];
                                return AuthorizationService.hasPermission("recover_cheques") && vEstado.codigo == "estado_cheque_anulado";
                            }
                        }]
                };
            });

            $scope.limpiarFiltros = function() {
                $scope.fechaDiferidoDesde = undefined;
                $scope.fechaDiferidoHasta = undefined;
                $scope.moneda = undefined;
                $scope.fechaEmisionDesde = undefined;
                $scope.fechaEmisionHasta = undefined;
                $scope.nroCheque = undefined;
                $scope.beneficiario = undefined;
                $scope.estado = undefined;
                vm.cuentaBancaria = undefined;
                $scope.checkChequesDiferidos = false;
                // $scope.verTodos = true;

              }

            // var createFilters = function (filters) {
            //     var filtersArr = [];
            //     _.each(filters, function (search, data) {
            //         search = search.split("~");
            //         if (search.length > 1) {
            //             /* El parámetro es un rago */

            //             var isDate = search[0].split("/");

            //             if (isDate.length > 1) {
            //                 /* Es un rago de fechas */

            //                 filtersArr.push({path: data, equalOrAfter: search[0]})
            //                 filtersArr.push({path: data, equalOrBefore: search[1]})
            //             } else {
            //                 /* El un rago numérco */

            //                 filtersArr.push({path: data, greaterOrEqual: search[0]})
            //                 filtersArr.push({path: data, lessOrEqual: search[1]})
            //             }
            //         } else {
            //             /* El parametro no es un rago */
            //            filtersArr.push({path: data, like: search[0]})
            //         }
            //     });
            //     var filters = filterFactory.and(filtersArr).value();
            //     return filters;
            // }

        }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.tesoreria')
  .controller('ChequeFormCtrl', ChequeFormCtrl);

ChequeFormCtrl.$inject = ['$rootScope', '$scope', 'ChequeraFactory', '$location', '$state',
'chequePrepService', 'notify', 'formFactory', 'TesoreriaLangFactory', 'TiposFactory', 'cuentaBancariaFactory',
'filterFactory', 'ChequeFactory', '$filter'];

function ChequeFormCtrl($rootScope, $scope, ChequeraFactory, $location, $state,
  chequePrepService, notify, formFactory, TesoreriaLangFactory, TiposFactory, cuentaBancariaFactory,
  filterFactory, ChequeFactory, $filter) {

  activate();

  $scope.cancel = cancel;
  $scope.submit = submit;

  function activate() {
    TesoreriaLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
      if($state.is("app.cheques.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateView() {
    $scope.title = $filter('translate')('VIEW_CHEQUE');
    $scope.cheque = chequePrepService;
    $scope.entidadId=$scope.cheque.id;
    $scope.entidad="Cheque";
    $scope.view = true;

    if ($scope.cheque.fecha_emision){
      $scope.cheque.fecha_emision_no_format = $scope.cheque.fecha_emision;
      $scope.cheque.fecha_emision = moment($scope.cheque.fecha_emision, "YYYY/MM/DD").format("DD/MM/YYYY");
    }
    else{
      $scope.cheque.fecha_emision = undefined;
    }
    
    for (var i = 0; i < $scope.cheque.ordenesPago.length; i++) {
      $scope.cheque.ordenesPago[i].fecha = moment($scope.cheque.fecha_emision_no_format, "YYYY/MM/DD").format("DD/MM/YYYY");
    }
  }

  function submit() {
    $scope.submited = true;
    if($scope.ChequeForm.$valid) {
        formFactory.defaultNSFSubmit($scope.ChequeForm, ChequeFactory, $scope.cheque).then(function (response) {
          $location.path('/cheques');
        }, function(error) {
          console.log(error);
        });
    } else {
       //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }
  }

  function cancel() {
    $location.path("/cheques");
  }

  $scope.resource = 'cheques'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';


angular.module('qualita.tesoreria')
        .controller('DesembolsoOrdenPagoIndexCtrl', ["$scope", "DesembolsoOrdenPagoFactory", "filterFactory", "ReportTicketFactory", "$window", "TesoreriaLangFactory", "$filter", "$modal", "$state", "AuthorizationService", "WatchLocacionChangeFactory", "notify", "baseurl", "UtilFactory", "CsvFactory", "$resource", "ParametrosFactory", function ($scope, DesembolsoOrdenPagoFactory,
                filterFactory, ReportTicketFactory, $window, TesoreriaLangFactory, $filter,
                $modal, $state, AuthorizationService, WatchLocacionChangeFactory, notify, baseurl,
                UtilFactory, CsvFactory, $resource, ParametrosFactory) {
            TesoreriaLangFactory.getTranslations().then(function (translations) {
                var monedaRender = function (data, type, row) {
                    var campoMoneda = row.moneda;
                    if (data != undefined) {
                        var moneda = "Gs. ";
                        if (campoMoneda.codigo === "dolares") {
                            moneda = "Usd. ";
                            data = parseFloat(data).toFixed(2);
                        } else if (campoMoneda.codigo === "pesos") {
                            moneda = "Pes. ";
                            data = parseFloat(data).toFixed(2);
                        } else if (campoMoneda.codigo === "real") {
                            moneda = "Rel. ";
                            data = parseFloat(data).toFixed(2);
                        } else if (campoMoneda.codigo === "euro") {
                            moneda = "Eur. ";
                            data = parseFloat(data).toFixed(2);
                        } else {
                            data = parseFloat(data).toFixed(0);
                        }
                        // TODO hacer esto mismo en todos los renders de moneda
                        return (
                                moneda +
                                data
                                .toString()
                                .replace(".", ",")
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                );
                    } else {
                        return "";
                    }
                };

                var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
                var enviarAContabilidad = '';

                promise1.then(function(response) {
                    enviarAContabilidad = response.valorTipo.codigo;
                });

                var renderObservacion = function (data) {

                    if (data != undefined) {
                        // Los elementos separados por comas se mueven a un array
                        var separate = data.split(", ");

                        // Convierto array en un set para evitar repeticiones
                        var noRepeat = new Set(separate);

                        // Convierto nuevamente el set a un array
                        var arrayNoRepeat = Array.from(noRepeat);

                        return arrayNoRepeat;
                    }
                    return '';

                };

                var defaultColumnOrder = ['id', 'fechaCreacion', 'formaPago.descripcion', 'estado.descripcion', 'totalImporte', 'cuentaBancaria.numeroCuenta','cuentaBancaria.nombreCuenta', 'observacion'];
                $scope.options = {
                    'resource': 'desembolsoordenpago',
                    'title': $filter('translate')('DESEMBOLSO_ORDENES_PAGO'),
                    'factory': DesembolsoOrdenPagoFactory,
                    'view': 'DesembolsoOrdenPagoList',
                    'defaultColumnOrder': defaultColumnOrder,
                    'failedDeleteError': $filter('translate')('FAIL_DELETE_DESEMBOLSO_ORDEN_PAGO'),
                    'columns': [
                        {'data': 'id', 'title': $filter('translate')('ID')},
                        {'data': 'fechaCreacion', 'title': $filter('translate')('DATE_CREATED'), renderWith: "dateRender", emptyRender: true, dateFormat: "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                        {'data': 'formaPago.descripcion', 'title': $filter('translate')('FORMA_PAGO')},
                        {
                            'data': 'estado.descripcion',
                            'title': $filter('translate')('STATUS'),
                            'type': 'combo',
                            'filterUrl': 'tipos/filtro/estados_desembolso_orden_pago'
                        },
                        {'data': 'totalImporte', 'title': $filter('translate')('TOTAL_IMPORTE_OC'), 'class': 'dt-right', 'renderWith': monedaRender},
                        {'data': 'cuentaBancaria.numeroCuenta', 'title': $filter('translate')('ACCOUNT_NUMBER_LABEL'), 'renderWith': 'emptyRender'},
                        {'data': 'cuentaBancaria.nombreCuenta', 'title': $filter('translate')('ACCOUNT_NAME_LABEL'), 'renderWith': 'emptyRender'},
                        {'data': 'observacion', 'title': $filter('translate')('OBSERVACION'), 'render': renderObservacion}
                    ],
                    'hasOptions': true,
                    'hideRemoveMenu': true,
                    'defaultOrderColumn': 0,
                    'defaultOrderDir': "desc",
                    'extraEditConditions': function (item) {
                        if (item != undefined){
                            if (item.formaPago.clase.codigo === "forma_pago_cheque" || item.estado.codigo != "estado_desembolso_generado")  {
                                return false;
                            }else {
                              return true;
                            }
                        }else{
                            return false;
                        }

                    },
                    'extraMenuOptions': [
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () {
                                return true;
                            },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("desembolsoordenpago", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                        .then(function (response) {
                                            console.log("response de csv: ", response);
                                            var fileName = response.data.fileName;
                                            $window.open(baseurl.getPublicBaseUrl() + fileName);
                                        });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () {
                                return true;
                            },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoDesembolsoOrdenPago', filters, $scope.options.tableAjaxParams,
                                        $scope.options.currentColumnOrder).then(function (genTicket) {
                                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                    $window.open($scope.pdfDownloadURL, '_blank');
                                });
                            }
                        }
                    ],
                    'extraRowOptions': [{
                            templateToRender: "<button title='" + $filter('translate')('EMITIR_CHEQUE') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='emitir(<%=dataCustom%>)' ng-class='{hidden : !canEmitir(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-file'></span> </button>",
                            functionName: 'emitir',
                            // La funcion recibe los customAttribute de la funcion 'conditionDef'
                            // customAttribute: ['formaPago', 'estado', 'id', 'totalImporte'],
                            functionDef: function (attributes) {

                                var codigo = attributes[2];
                                var moneda = attributes[4];
                                var montoFormateado, redondeado, tipoMoneda;

                                if (moneda.codigo == 'guaranies') {
                                    redondeado = parseFloat(attributes[3].toFixed(0));
                                    tipoMoneda = "Gs. ";
                                } else if (moneda.codigo == 'dolares') {
                                    redondeado = parseFloat(attributes[3].toFixed(2));
                                    tipoMoneda = "Usd. ";
                                }
                                montoFormateado = redondeado.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                                $scope.tituloModal = $filter('translate')('EMITIR_CHEQUE_Q');
                                $scope.mensajeModal = $filter('translate')('EMITIR_CHEQUE_MSG');

                                $scope.mensajeModal = $filter('translate')('EMITIR_CHEQUE_MSG') + " " + codigo + " - Monto: " + tipoMoneda + montoFormateado + " ?";

                                var modalInstance = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss('cancel');
                                };

                                $scope.ok = function (id) {
                                    var filtersArr = [];
                                    filtersArr.push("idDesembolso=" + codigo);



                                    var childScope = $scope.$new();
                                        childScope.tituloModal = $filter('translate')('CONFIRMAR_EMISION_CHEQUE_Q');
                                        childScope.mensajeModal = $filter('translate')('CONFIRMAR_EMISION_CHEQUE_WARNING');

                                        var confirmationModalInstance = $modal.open({
                                            templateUrl: 'views/directives/confirmation-modal.html',
                                            scope: childScope
                                        });

                                        childScope.cancel = function () {
                                            confirmationModalInstance.dismiss('cancel');
                                        }

                                        childScope.ok = function (id) {
                                            DesembolsoOrdenPagoFactory.emitirCheque(codigo).$promise.then(function (response) {
                                                ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                                    if (parametro.valorTipo.codigo == 'si') {
                                                      if(response.enviadoAContabilidad == false){
                                                        notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                      }
                                                    }

                                                    console.log('response.listaArchivosLogs: ', response.listaArchivosLogs);

                                                    if(response.listaArchivosLogs != null){
                                                            var keys = Object.keys(response.listaArchivosLogs);
                                                            console.log(keys.length);
                                                            if(keys.length > 0){
                                                              /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                                  console.log('response.listaArchivosLogs: ');
                                                                  console.log(response.listaArchivosLogs[i]);
                                                                  $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                              }*/
                                                              notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                            }
                                                    }
                                                });
                                                var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("emitircheque", 'pdf', filtersArr);
                                                var resource = $resource(pdfDownloadURL);
                                                resource.get().$promise.then(function (value) {
                                                    console.log("Genero PDF!: ");

                                                    $window.open(pdfDownloadURL, '_blank');

                                                    modalInstance.close();


                                                }, function (error) {
                                                    console.log("Error al generar PDF: ", error);
                                                    var msg = $filter('translate')('ERROR_EMISION_CHEQUE_PDF');
                                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                });
                                                $state.go("app.desembolsoordenpago.list", {}, {reload: true});
                                            }, function (error) {
                                              console.log('error emitir cheque');
                                              console.log(error);
                                                var msg = msg = error.data[0].message
                                                if(msg==='Not unique field: numero, id_cuenta, id_tipo_movimiento'){
                                                    msg = $filter('translate')('MISMO_MOVIMIENTO_ERROR');
                                                }
                                                notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                confirmationModalInstance.close();
                                            });
                                        }




                                }
                            },
                            conditionName: 'canEmitir',
                            customAttribute: ['formaPago', 'estado', 'id', 'totalImporte', 'moneda'],
                            conditionDef: function (atributos) {
                                var formaPago = atributos[0];
                                var estado = atributos[1];

                                return AuthorizationService.hasPermission("update_desembolsoordenpago") && formaPago.clase.codigo == 'forma_pago_cheque' && estado.codigo == 'estado_desembolso_generado';
                            }
                        },
                        {
                            templateToRender: "<button title='" + $filter('translate')('REIMPRIMIR_CHEQUE') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reimprimirCheque(<%=dataCustom%>)' ng-class='{hidden : !canReimprimirCheque(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-print'></span> </button>",
                            functionName: 'reimprimirCheque',
                            // La funcion recibe los customAttribute de la funcion 'conditionDef'
                            // customAttribute: ['formaPago', 'estado', 'id', 'totalImporte'],
                            functionDef: function (attributes) {
                                var codigo = attributes[2];
                                var filtersArr = [];
                                filtersArr.push("idDesembolso=" + codigo);
                                var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("emitircheque", 'pdf', filtersArr);
                                var resource = $resource(pdfDownloadURL);
                                resource.get().$promise.then(function (value) {
                                    console.log("Genero PDF!: ");

                                    $window.open(pdfDownloadURL, '_blank');

                                    modalInstance.close();


                                }, function (error) {
                                    console.log("Error al generar PDF: ", error);
                                    var msg = $filter('translate')('ERROR_EMISION_CHEQUE_PDF');
                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                });
                            },
                            conditionName: 'canReimprimirCheque',
                            customAttribute: ['formaPago', 'estado', 'id', 'totalImporte', 'moneda'],
                            conditionDef: function (atributos) {
                                var formaPago = atributos[0];
                                var estado = atributos[1];

                                return AuthorizationService.hasPermission("update_desembolsoordenpago") && formaPago.clase.codigo == 'forma_pago_cheque' && estado.codigo == 'estado_desembolso_emitido';
                            }
                        },
                        {
                            templateToRender: "<button title='" + $filter('translate')('GENERAR_DEBITO') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='generarDebito(<%=dataCustom%>)' ng-class='{hidden : !canGenerarDebito(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-credit-card'></span> </button>",
                            functionName: 'generarDebito',
                            customAttribute: ['formaPago', 'estado', 'id', 'cuentaBancaria', 'totalImporte', 'moneda'],
                            functionDef: function (atributos) {
                                $scope.tituloModal = $filter('translate')('GENERAR_DEBITO_Q');
                                //$scope.mensajeModal = $filter('translate')('GENERAR_DEBITO_Q');
                                if (atributos[3] != null) {
                                    //AQUI
                                    var valorFormateado = atributos[4].toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    if (atributos[5].codigo == 'guaranies') {
                                        $scope.mensajeModal = "" + atributos[2] + "|" + atributos[3].nombreCuenta + "|Gs. " + valorFormateado;
                                    } else if (atributos[5].codigo == 'dolares') {
                                        $scope.mensajeModal = "" + atributos[2] + "|" + atributos[3].nombreCuenta + "|Usd. " + valorFormateado;
                                    }
                                } else {
                                    if (atributos[5].codigo == 'guaranies') {
                                        $scope.mensajeModal = "" + atributos[2] + "|Gs. " + atributos[4];
                                    } else if (atributos[5].codigo == 'dolares') {
                                        $scope.mensajeModal = "" + atributos[2] + "|Usd. " + atributos[4];
                                    }
                                }
                                var modalInstance = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss('cancel');
                                };

                                $scope.ok = function (id) {
                                    DesembolsoOrdenPagoFactory.generarDebito(atributos[2]).$promise.then(function (response) {
                                        var fileName = response.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                        $state.go("app.desembolsoordenpago.list", {}, {reload: true});
                                    }, function (error) {
                                        var msg = $filter('translate')('GENERAR_DEBITO_FAILED');
                                        if (error.data[0] && error.data[0].constraint == "numero, id_cuenta, id_tipo_movimiento") {
                                            notify({ message: $filter('translate')('CONSTRAINT_ERROR'), classes: 'alert-danger', position: 'right' });
                                        }else{
                                            msg = error.data[0].message;
                                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                                        }
                                        notify({message: msg, classes: 'alert-danger', position: 'right'});
                                    });
                                    modalInstance.close();
                                }
                            },
                            conditionName: 'canGenerarDebito',
                            conditionDef: function (atributos) {
                                var formaPago = atributos[0];
                                var estado = atributos[1];

                                return AuthorizationService.hasPermission("update_desembolsoordenpago") && formaPago.clase.codigo == 'forma_pago_debito' && estado.codigo == 'estado_desembolso_generado';
                            }
                        },
                        {
                            templateToRender: "<button title='" + $filter('translate')('CONFIRMAR_TRANSFERENCIA') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='confirmarTransferencia(<%=dataCustom%>)' ng-class='{hidden : !canConfirmarTransferencia(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-transfer'></span> </button>",
                            functionName: 'confirmarTransferencia',
                            customAttribute: ['formaPago', 'estado', 'id', 'cuentaBancaria', 'totalImporte', 'moneda'],
                            functionDef: function (atributos) {
                                $scope.tituloModal = $filter('translate')('CONFIRMAR_TRANSFERENCIA_Q');
                                //$scope.mensajeModal = $filter('translate')('GENERAR_DEBITO_Q');
                                if (atributos[3] != null) {
                                    //AQUI
                                    var valorFormateado = atributos[4].toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    if (atributos[5].codigo == 'guaranies') {
                                        $scope.mensajeModal = "" + atributos[2] + "|" + atributos[3].nombreCuenta + "|Gs. " + valorFormateado;
                                    } else if (atributos[5].codigo == 'dolares') {
                                        $scope.mensajeModal = "" + atributos[2] + "|" + atributos[3].nombreCuenta + "|Usd. " + valorFormateado;
                                    }
                                } else {
                                    if (atributos[5].codigo == 'guaranies') {
                                        $scope.mensajeModal = "" + atributos[2] + "|Gs. " + atributos[4];
                                    } else if (atributos[5].codigo == 'dolares') {
                                        $scope.mensajeModal = "" + atributos[2] + "|Usd. " + atributos[4];
                                    }
                                }
                                var modalInstance = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss('cancel');
                                };

                                $scope.ok = function (id) {
                                    DesembolsoOrdenPagoFactory.generarDebito(atributos[2]).$promise.then(function (response) {
                                        $state.go("app.desembolsoordenpago.list", {}, {reload: true});
                                    }, function (error) {
                                        var msg = $filter('translate')('CONFIRMAR_TRANSFERENCIA_FAILED');
                                        if (error.data[0] && error.data[0].constraint == "numero, id_cuenta, id_tipo_movimiento") {
                                            notify({ message: $filter('translate')('CONSTRAINT_ERROR'), classes: 'alert-danger', position: 'right' });
                                        }else{
                                            msg = error.data[0].message;
                                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                                        }
                                    });
                                    modalInstance.close();
                                }
                            },
                            conditionName: 'canConfirmarTransferencia',
                            conditionDef: function (atributos) {
                                var formaPago = atributos[0];
                                var estado = atributos[1];

                                return AuthorizationService.hasPermission("update_desembolsoordenpago") && formaPago.clase.codigo == 'forma_pago_transferencia_bancaria' && estado.codigo == 'estado_desembolso_generado';
                            }
                        },
                        {
                            // dataId: permite recibir solo el id del registro seleccionado
                            // dataCustom: permite recibir otros atributos ademas del id

                            templateToRender: "<button title='" + $filter('translate')('EMITIR_DESEMBOLSO') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='emitirNormal(<%=dataCustom%>)' ng-class='{hidden : !canEmitirNormal(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-ok'></span> </button>",
                            functionName: 'emitirNormal',
                            // La funcion recibe los customAttribute de la funcion 'conditionDef'
                            // customAttribute: ['formaPago', 'estado', 'id', 'totalImporte'],
                            functionDef: function (attributes) {

                                var codigo = attributes[2];
                                var montoFormateado = attributes[3].toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                                $scope.tituloModal = $filter('translate')('EMITIR_DESEMBOLSO_Q');
                                if (attributes[4].codigo == 'guaranies') {
                                    $scope.mensajeModal = $filter('translate')('EMITIR_DESEMBOLSO_MSG') + " " + codigo + " - Monto: Gs. " + montoFormateado + " ?";
                                } else if (attributes[4].codigo == 'dolares') {
                                    $scope.mensajeModal = $filter('translate')('EMITIR_DESEMBOLSO_MSG') + " " + codigo + " - Monto: Usd. " + montoFormateado + " ?";
                                }
                                var modalInstance = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss('cancel');
                                };

                                $scope.ok = function (id) {
                                    DesembolsoOrdenPagoFactory.emitir(codigo).$promise.then(function (response) {


                                        console.log("Emitiendo desembolso ", response.listaArchivosLogs )


                                        ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                          if (parametro.valorTipo.codigo == 'si') {
                                            if(response.enviadoAContabilidad == false){
                                              notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                            }

                                            console.log("Archivos de log ", response.listaArchivosLogs )
                                            console.log("Archivos de log ", response.listaArchivosLogs.length )

                                            if(response.listaArchivosLogs != null){
                                              if(response.listaArchivosLogs.length > 0){
                                                /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                    console.log('response.listaArchivosLogs: ');
                                                    console.log(response.listaArchivosLogs[i]);
                                                    $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                }*/
                                                notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                              }
                                            }
                                          }
                                        });
                                        $state.go("app.desembolsoordenpago.list", {}, {reload: true});
                                    }, function (error) {
                                        if (error.data != undefined && error.data != null){
                                            var msg =error.data[0].message;
                                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                                            confirmationModalInstance.close();
                                        }else{
                                            var msg = $filter('translate')('EMITIR_DESEMBOLSO_FAILED');
                                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                                            confirmationModalInstance.close();
                                        }

                                    });
                                }
                            },
                            conditionName: 'canEmitirNormal',
                            customAttribute: ['formaPago', 'estado', 'id', 'totalImporte', 'moneda'],
                            conditionDef: function (atributos) {
                                var formaPago = atributos[0];
                                var estado = atributos[1];

                                return AuthorizationService.hasPermission("update_desembolsoordenpago") && formaPago.clase.codigo != 'forma_pago_cheque' && formaPago.clase.codigo != 'forma_pago_debito' && formaPago.clase.codigo != 'forma_pago_transferencia_bancaria' && estado.codigo == 'estado_desembolso_generado';
                            }
                        },
                        {
                            templateToRender: "<button title='" + $filter('translate')('ANULAR_RETENCIONES') + "'class='btn btn-danger' style='margin-right: 5px;' ng-click='anular(<%=dataId%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                            customAttribute: 'estado',
                            functionName: "anular",
                            functionDef: function (itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.tituloModal = $filter('translate')('ANULAR_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('MENSAJE_MODAL_ANULAR');
                                $scope.modalInstanceBorrar1 = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                }

                                $scope.ok = function (itemId) {
                                    DesembolsoOrdenPagoFactory.anular(itemId).$promise.then(function (response) {
                                        $state.go($state.current, {}, {reload: true});
                                    },
                                            function (error) {
                                                console.log("Ha ocurrido un error");

                                                var msg = error.data[0].message;
                                                notify({message: msg, classes: 'alert-danger', position: 'right'});

                                            });
                                }
                            },
                            conditionName: "canAnular",
                            conditionDef: function (estado) {
                                return AuthorizationService.hasPermission("delete_desembolsoordenpago") && estado.codigo == 'estado_desembolso_emitido';
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataCustom%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                            conditionName: "canBorrar",
                            customAttribute: ["id", "numero", "estado"],
                            conditionDef: function (attributes) {
                                $scope.selectedEstado = attributes[2];
                                return AuthorizationService.hasPermission('delete_desembolsoordenpago') && $scope.selectedEstado.codigo == "estado_desembolso_generado";
                            },
                            functionName: "borrar",
                            functionDef: function (attributes) {
                                $scope.selectedItemId = attributes[0];
                                $scope.selectedNumero = attributes[1];

                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING_DESEMBOLSO', {desembolso: $scope.selectedNumero});
                                $scope.modalInstanceBorrar1 = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                };

                                $scope.ok = function (attributes) {
                                    var model = DesembolsoOrdenPagoFactory.create({id: $scope.selectedItemId});
                                    DesembolsoOrdenPagoFactory.remove(model).then(function () {
                                        $scope.modalInstanceBorrar1.close($scope.selectedItemId);
                                        $state.go($state.current, {}, {reload: true});

                                    }, function (error) {
                                        var msg = error.data[0].message;
                                        notify({message: msg, classes: 'alert-danger', position: 'right'});

                                    });
                                };
                            }
                        },
                        {
                            templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
                            functionName: 'reenviar',
                            customAttribute: ['estado', 'enviadoAContabilidad', 'id'],
                            functionDef: function(atributo) {

                                var idDesembolso = atributo[2];

                                DesembolsoOrdenPagoFactory.get(idDesembolso, "DesembolsoOrdenPagoForm").$promise.then(function(response) {
                                    $scope.tituloModal = $filter('translate')('REENVIAR_DESEMBOLSO_Q');
                                    $scope.mensajeModal = $filter('translate')('REENVIAR_DESEMBOLSO_MSG');
                                    var modalInstance = $modal.open({
                                        templateUrl: 'views/directives/confirmation-modal.html',
                                        scope: $scope
                                    });

                                    $scope.cancel = function() {
                                        modalInstance.dismiss('cancel');
                                    };

                                    $scope.ok = function(id) {
                                        ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                                            if (parametro.valorTipo.codigo == 'si') {
                                                DesembolsoOrdenPagoFactory.reenviar(idDesembolso).$promise.then(function (response) {
                                                  if(response.enviadoAContabilidad == false){
                                                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                  }

                                                  console.log('response.listaArchivosLogs: ', response.listaArchivosLogs);

                                                  if(response.listaArchivosLogs != null){
                                                    if(response.listaArchivosLogs.length != 0){
                                                      /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                          console.log('response.listaArchivosLogs: ');
                                                          console.log(response.listaArchivosLogs[i]);
                                                          $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                      }*/
                                                      notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                                                    }
                                                  }
                                                  $state.go($state.current, {}, {reload: true});
                                                }, function(error) {
                                                    var msg = "";
                                                    if (error.data && error.data.length > 0 && error.data[0].message) {
                                                        msg = error.data[0].message;
                                                    } else {
                                                        msg = $filter('translate')('REENVIAR_DESEMBOLSO_FAILED');
                                                    }

                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                });
                                            }else{
                                                var msg = "";
                                                msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        });
                                        modalInstance.close();
                                    }
                                });
                            },
                            conditionName: 'canReenviar',
                            conditionDef: function(atributo) {
                                var estado = atributo[0];
                                var enviadoAContabilidad = atributo[1];
                                var id = atributo[2];
                                if(enviarAContabilidad == 'si'){
                                    if(estado.codigo === 'estado_desembolso_emitido' || estado.codigo === 'estado_desembolso_anulado' ){
                                        if(enviadoAContabilidad === "No"){
                                            return true;
                                        }
                                    }
                                }
                            }
                        }
                    ]
                };
                WatchLocacionChangeFactory.watchWith(undefined, $scope.options, "locacion.id");
                var createFilters = function (filters) {
                    var filtersArr = [];
                    _.each(filters, function (search, data) {
                        filtersArr.push({path: data, like: search})
                    });
                    var filters = filterFactory.and(filtersArr).value();
                    return filters;
                }
            });
        }]);

angular.module('qualita.tesoreria')
    .controller('DesembolsoOrdenPagoFormCtrl', DesembolsoOrdenPagoFormCtrl);

DesembolsoOrdenPagoFormCtrl.$inject = ['$rootScope', '$scope', 'desembolsoOrdenPagoPrepService',
    'TesoreriaLangFactory', '$state', 'ProveedoresFactory', 'TiposTipoFactory', 'FormaPagoFactory',
    'ParametrosFactory', 'ModelTrimmer', 'formFactory', '$location', 'notify',
    'filterFactory', '$q', 'CuentaContableFactory', 'PlanillaFondoFijoFactory', '$rootScope',
    'DesembolsoOrdenPagoFactory', 'cuentaBancariaFactory', 'ChequeraFactory', 'OrdenPagoFactory', 'ChequeFactory',
    'WatchLocacionChangeFactory', 'UtilFactory', '$filter', 'PeriodoAdministrativoFactory', '$modal'
];

function DesembolsoOrdenPagoFormCtrl($rootScope, $scope, desembolsoOrdenPagoPrepService,
    TesoreriaLangFactory, $state, ProveedoresFactory, TiposTipoFactory,
    FormaPagoFactory, ParametrosFactory, ModelTrimmer, formFactory, $location, notify,
    filterFactory, $q, CuentaContableFactory,
    PlanillaFondoFijoFactory, $rootScope, DesembolsoOrdenPagoFactory, cuentaBancariaFactory,
    ChequeraFactory, OrdenPagoFactory, ChequeFactory, WatchLocacionChangeFactory, UtilFactory,
    $filter, PeriodoAdministrativoFactory, $modal) {

    var vm = this;

    vm.cancel = cancel;
    vm.submit = submit;
    vm.buscarOrdenesPago = buscarOrdenesPago;
    vm.clearFiltrosOrdenesPago = clearFiltrosOrdenesPago;
    vm.formaPagoSelected = formaPagoSelected;
    vm.chequeraSelected = chequeraSelected;
    vm.searchOrdenPagoFilter = searchOrdenPagoFilter;
    vm.cuentaSelected = cuentaSelected;
    vm.searchCuentaBancariaFilter = searchCuentaBancariaFilter;
    vm.generarDetalleDesembolso = generarDetalleDesembolso;
    vm.searchChequeraFilter = searchChequeraFilter;
    vm.getOrdenesPago = getOrdenesPago;
    vm.searchPlanillaFondoFijo = searchPlanillaFondoFijo;
    $rootScope.isProcessing = false;
    vm.comprobacionFormaPago = vm.comprobacionFormaPago;
    vm.listaDistribucionIngresada = [];
    vm.fromModal = false;
    vm.chequesFraccionados = [];
    vm.ultimoNroCheque = 0;
    vm.cantidadTotalCheques = 0;
    vm.ordenesPagoFraccionados = [];
    vm.numerosChequesFraccionados = [];
    vm.asignarNroMovimientoBancario = asignarNroMovimientoBancario;

    activate();

    function activate() {
        var filterFormaPago = {};
        filterFormaPago.search = filterFactory.single({
            path: 'clase.codigo',
            notEquals: "forma_pago_referencia"
        }).value();
        vm.formasPago = FormaPagoFactory.all(filterFormaPago);
        vm.cuentasBancarias = cuentaBancariaFactory.all();
        var paramsProv = {};
        paramsProv.search = filterFactory.single({
            path: 'activo',
            equals: true
        }).value();
        vm.monedas = TiposTipoFactory.get("monedas");

        $('form[name=DesembolsoOrdenPagoForm]').on('keydown', 'input', function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
            }
        });

        vm.cuentasContables = CuentaContableFactory.all();
        WatchLocacionChangeFactory.killAllWatchers();

        /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
        vm.staticFilterProv = [{ path: "activo", equals: true }];
        vm.proveedoresFactory = ProveedoresFactory;

        TesoreriaLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            getOrdenesPago();
            getPlanillasFondoFijo();
            vm.tiposCheques = [{
                id: 1,
                descripcion: $filter('translate')('DIFERIDO'),
                value: true
            }, {
                id: 2,
                descripcion: $filter('translate')('AL_DIA'),
                value: false
            }];

            var defaultColumnOrder = ['id', 'numero', 'fecha', 'tipoOrdenPago.nombre', 'proveedor.nombre',
                'importe', 'importeRetencion', 'estado.descripcion', 'codigoPago', 'formaPago.descripcion'
            ];
            if ($state.is("app.desembolsoordenpago.new")) {
                vm.isSelectableOP = true;
            } else {
                vm.isSelectableOP = false;
            }
            vm.optionsDtOrdenesPago = {
                'resource': 'ordenpago',
                'title': $filter('translate')('ORDENES_PAGO'),
                'factory': OrdenPagoFactory,
                'view': "OrdenPagoList",
                'defaultColumnOrder': defaultColumnOrder,
                'colReorder': true,
                'columns': [
                    { 'data': 'numero', 'title': $filter('translate')('NUMERO_OP') },
                    { 'data': 'fecha', 'title': $filter('translate')('DATE'), renderWith: "dateRender", dateFormat: "DD/MM/YYYY", 'type': 'date-range', 'class': 'dt-center' },
                    { 'data': 'tipoOrdenPago.nombre', 'title': $filter('translate')('TIPO_ORDEN_PAGO') },
                    { 'data': 'codigoPago', 'title': $filter('translate')('CODIGO_PAGO') },
                    { 'data': 'formaPago.descripcion', 'title': $filter('translate')('FORMA_PAGO') },
                    { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX'), renderWith: "emptyRender" },
                    { 'data': 'estado.descripcion', 'title': $filter('translate')('STATUS') },
                    //{'data':'proveedores', 'title':$filter('translate')('STATUS'), 'render': renderEmptyData},
                    { 'data': 'importe', 'title': $filter('translate')('MONTO'), 'class': 'dt-right', 'render': monedaRender },
                    { 'data': 'importeRetencion', 'title': $filter('translate')('IMPORTE_RETENCION'), 'class': 'dt-right', 'render': monedaRender }

                ],
                'isSelectable': true,
                'selection': {},
                'hasOptions': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'defaultOrderColumn': 1,
                'defaultOrderDir': "asc",
                'hideAddMenu': true,
                'filters': {},
                'extraRowOptions': [{
                    templateToRender: "<button class='btn btn-primary' style='margin-right: 5px;' title='Fraccionar Cheque' ng-click='fraccionarCheques(<%=dataCustom%>)'> <span class='glyphicon glyphicon-tasks'></span> </button>",
                    functionName: "fraccionarCheques",
                    customAttribute: ['numero', 'importe', 'moneda', 'id'],
                    functionDef: function (atributos) {
                        var numero = atributos[0];
                        var importe = atributos[1];
                        var moneda = atributos[2];
                        var idOrdenPago = atributos[3];
                        vm.fromModal = true;
                        var redondeado;
                        var isDolares = false;

                        if (moneda.codigo == 'guaranies') {
                            redondeado = parseFloat(importe.toFixed(0));
                        } else if (moneda.codigo == 'dolares') {
                            redondeado = parseFloat(importe.toFixed(2));
                            isDolares = true;
                        }

                        $scope.tituloModal = $filter('translate')('FRACCIONAR_CHEQUE_TITULO_MODAL');
                        $scope.numeroOP = numero;
                        $scope.montoOP = redondeado;
                        $scope.showChequesDistribucion = false;
                        $scope.idOrdenPago = idOrdenPago;

                        var modalInstance = $modal.open({
                            templateUrl: 'views/directives/distribuirCheque-modal.html',
                            scope: $scope
                        });

                        $scope.cancel = function () {
                            vm.fromModal = false;
                            modalInstance.dismiss('cancel');
                        };

                        $scope.ok = function (data) {
                            var montoTotalIngresado = 0;
                            vm.listaDistribucionIngresada = [];

                            if (data == undefined) {
                                notify({ message: 'La cantidad debe ser mayor a cero', classes: 'alert-danger', position: 'right' });
                                return;
                            }
                            for (var i = 0; i < data.length; i++) {
                                if (data[i].importeIngresado > 0) {
                                    montoTotalIngresado = parseFloat(montoTotalIngresado) + parseFloat(data[i].importeIngresado);
                                }
                            }
                            if (isDolares) {
                                montoTotalIngresado = parseFloat(montoTotalIngresado.toFixed(2));
                            }
                            if (montoTotalIngresado > 0) {
                                /*Validar los montos ingresados*/
                                if (montoTotalIngresado < $scope.montoOP || montoTotalIngresado > $scope.montoOP) {
                                    notify({ message: "El monto total de distribución de cheques debe ser igual al monto total de la OP", classes: 'alert-danger', position: 'right' });
                                    return;
                                } else { /*si pasa la validacion*/

                                    for (var i = 0; i < data.length; i++) {
                                        vm.listaDistribucionIngresada.push({ data: data });
                                    }

                                    modalInstance.close();
                                }
                            } else { ///*Si no se utilizaron los montos ingresados ,entonces usar el monto sugerido

                                for (var i = 0; i < data.length; i++) {
                                    vm.listaDistribucionIngresada.push({ data: data });
                                }

                                modalInstance.close();
                            }
                        };

                        $scope.calcularDistribucion = function (data) {
                            $scope.distribucionLista = [];

                            if (data || data !== 0) {
                                var montoChequeDistribucion = $scope.montoOP / data;
                                var numeroItem = 0;

                                for (var i = 0; i < data; i++) {
                                    numeroItem++;
                                    $scope.distribucionLista.push({
                                        idOrdenPago: $scope.idOrdenPago,
                                        nroItem: numeroItem,
                                        nroOp: $scope.numeroOP,
                                        importeSugerido: parseFloat(montoChequeDistribucion.toFixed(2)),
                                        importeIngresado: 0
                                    });
                                }
                                $scope.showChequesDistribucion = true;
                            } else {

                                notify({ message: "La cantidad debe ser mayor a cero", classes: 'alert-danger', position: 'right' });
                                $scope.showChequesDistribucion = false;
                                return;
                            }

                        }
                    },

                }],
            };

            vm.estados = TiposTipoFactory.get("estados_desembolso_orden_pago");

            $scope.$watchCollection('vm.optionsDtOrdenesPago.selection', function (newValue, oldValue) {
                // Si el antiguo valor no es vacio (length>0), no esta cargando los datos por primera vez
                // Por ende el usuario esta modificando desde la vista y deben borrarse los cheques
                if (Object.keys(oldValue).length > 0) {
                    vm.desembolsoOrdenPago.cheques = [];
                    vm.desembolsoOrdenPago.ordenesPagosNro = [];
                }
            });

            if ($state.is("app.desembolsoordenpago.new")) {
                activateNew();
            } else if ($state.is("app.desembolsoordenpago.edit")) {
                activateEdit();
            } else if ($state.is("app.desembolsoordenpago.view")) {
                activateView();
            }
            $rootScope.isProcessing = false;
            vm.submited = false;

            vm.form = $scope.DesembolsoOrdenPagoForm;
        });
    }

    var renderEmptyData = function (data) {
        if (data == undefined)
            return "";
        else
            return data;
    };

    var monedaRender = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.moneda.codigo === 'dolares') {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === 'pesos') {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === 'real') {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === 'euro') {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === 'guaranies') {
                data = parseFloat(data).toFixed(0);
            }
            // TODO hacer esto mismo en todos los renders de moneda
            return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
            return '';
        }
    };

    function activateNew() {
        vm.editar = false;
        vm.search = {};
        vm.title = $filter('translate')('NEW_DESEMBOLSO_ORDEN_PAGO');
        vm.desembolsoOrdenPago = {
            generarUnSoloCheque: true
        };
        vm.desembolsoOrdenPago.numero = "El numero se asignará al guardar el desembolso.";
        vm.primerCheque = {};
        vm.estados.$promise.then(function (response) {
            vm.desembolsoOrdenPago.estado = _.find(response, function (estado) {
                return estado.codigo == "estado_desembolso_generado";
            });
        });
    }

    function activateEdit() {
        vm.editar = true;
        vm.search = {};
        vm.title = $filter('translate')('EDIT_DESEMBOLSO_ORDEN_PAGO');
        vm.desembolsoOrdenPago = desembolsoOrdenPagoPrepService;
        vm.primerCheque = vm.desembolsoOrdenPago.cheques[0];
        if (vm.primerCheque) {
            vm.chequeraEnabled = true;
            vm.showCheques = true;
        }
        vm.isEdit = true;
        if ("forma_pago_transferencia_bancaria" === vm.desembolsoOrdenPago.formaPago.clase.codigo) {
            vm.cuentaBancariaEnabled = true;
        }
        var filters = {
            search: filterFactory.single({
                path: 'desembolsoOrdenPago.id',
                equals: vm.desembolsoOrdenPago.id
            }).value()
        };
        console.log("filters to use: ");
        console.log(filters);
        vm.optionsDtOrdenesPago.staticFilter = filters;
        vm.showDtOrdenesPago = true;
        formSelectionsArray();

        // Recupera moneda de la primera OP
        vm.search.moneda = vm.desembolsoOrdenPago.ordenesPago[0].moneda;

        // Redondeo de monto
        if (vm.primerCheque) {
            if (vm.search.moneda.codigo == "dolares") {
                vm.primerCheque.monto = parseFloat(vm.primerCheque.monto.toFixed(2));
            } else if (vm.search.moneda.codigo == "guaranies") {
                vm.primerCheque.monto = parseFloat(vm.primerCheque.monto.toFixed(0));
            }
        }
        //controlar si parametro esta activado
        ParametrosFactory.getByCodigo('asignar_nro_mov_bancario_desembolso').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.asignarNroBancario = false;
            } else {
                if ("forma_pago_transferencia_bancaria" === vm.desembolsoOrdenPago.formaPago.clase.codigo || "forma_pago_debito" === vm.desembolsoOrdenPago.formaPago.clase.codigo) {
                    vm.asignarNroBancario = true;
                    vm.showAsignarNroOperacion = true;
                    var idsDetails = getIDs();
                    var opsFilters = _.map(idsDetails, function (id) {
                        return {
                            path: 'id',
                            equals: id
                        };
                    });

                    var filters = {
                        search: filterFactory.or(opsFilters).value(),
                        view: 'OrdenPagoChequeList'
                    };

                    OrdenPagoFactory.all(filters).$promise.then(function (response) {
                        vm.desembolsoOrdenPago.ordenesPagosNro = response;
                    });
                } else {
                    vm.asignarNroBancario = false;
                    vm.showAsignarNroOperacion = false;
                }
            }
        })
    }

    function activateView() {
        vm.editar = false;
        vm.title = $filter('translate')('VIEW_DESEMBOLSO_ORDEN_PAGO');
        vm.desembolsoOrdenPago = desembolsoOrdenPagoPrepService;
        vm.view = true;
        vm.desembolsoOrdenPago.cheques = _.sortBy(vm.desembolsoOrdenPago.cheques, function (op) { return op.numero; });

        vm.primerCheque = vm.desembolsoOrdenPago.cheques[0];
        if (vm.primerCheque) {
            vm.chequeraEnabled = true;
            vm.showCheques = true;
        }
        var filters = {
            search: filterFactory.single({
                path: 'desembolsoOrdenPago.id',
                equals: vm.desembolsoOrdenPago.id
            }).value()
        };
        console.log("filters to use: ");
        console.log(filters);
        vm.optionsDtOrdenesPago.staticFilter = filters;
        vm.showDtOrdenesPago = true;

        formSelectionsArray();


        vm.search = {};

        // Recupera moneda de la primera OP
        vm.search.moneda = vm.desembolsoOrdenPago.ordenesPago[0].moneda;

        // Redondeo de monto
        if (vm.primerCheque) {
            if (vm.search.moneda.codigo == "dolares") {
                vm.primerCheque.monto = parseFloat(vm.primerCheque.monto.toFixed(2));
            } else if (vm.search.moneda.codigo == "guaranies") {
                vm.primerCheque.monto = parseFloat(vm.primerCheque.monto.toFixed(0));
            }
        }
        //controlar si parametro esta activado
        ParametrosFactory.getByCodigo('asignar_nro_mov_bancario_desembolso').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.asignarNroBancario = false;
            } else {
                if ("forma_pago_transferencia_bancaria" === vm.desembolsoOrdenPago.formaPago.clase.codigo || "forma_pago_debito" === vm.desembolsoOrdenPago.formaPago.clase.codigo) {
                    vm.asignarNroBancario = true;
                    vm.showAsignarNroOperacion = true;
                    var idsDetails = getIDs();
                    var opsFilters = _.map(idsDetails, function (id) {
                        return {
                            path: 'id',
                            equals: id
                        };
                    });

                    var filters = {
                        search: filterFactory.or(opsFilters).value(),
                        view: 'OrdenPagoChequeList'
                    };

                    OrdenPagoFactory.all(filters).$promise.then(function (response) {
                        vm.desembolsoOrdenPago.ordenesPagosNro = response;
                    });
                } else {
                    vm.asignarNroBancario = false;
                    vm.showAsignarNroOperacion = false;
                }
            }
        })
    }

    function searchOrdenPagoFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return item.fecha.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.id == criteria || item.tipoOrdenPago.nombre.indexOf(criteria) === 0;
        };
    }

    function cuentaSelected() {
        //traemos las chequeras correspondientes
        if (vm.desembolsoOrdenPago.cuentaBancaria) {
            if ("forma_pago_cheque" === vm.desembolsoOrdenPago.formaPago.clase.codigo) {
                console.log("changemoneda", vm.desembolsoOrdenPago.cuentaBancaria.moneda);
                vm.monedaCuenta = vm.desembolsoOrdenPago.cuentaBancaria.moneda;
                vm.search = { 'moneda': '' }
                vm.search.moneda = vm.desembolsoOrdenPago.cuentaBancaria.moneda;
                vm.disableMoneda = true;
                var chequeraFilters = {
                    search: filterFactory.and([
                        {
                            path: "cuentaBancaria.id",
                            equals: vm.desembolsoOrdenPago.cuentaBancaria.id
                        }
                    ]).value()
                };
                delete vm.desembolsoOrdenPago.chequera;
                //if (vm.watcherCheq) { vm.watcherCheq(); } //hacemos unwatch de la expresion anterior
                //WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcherCheq) {
                //  vm.watcherCheq = watcherCheq;

                // vm.chequeras = ChequeraFactory.all(chequeraFilters);

                ChequeraFactory.all(chequeraFilters, "BaseList").$promise.then(function (chequeras) {

                    if (vm.desembolsoOrdenPago.formaPago.clase.codigo == "forma_pago_cheque") {

                        if (chequeras.length == 0) {
                            notify({ message: $filter('translate')('CHEQUERA_VACIA'), classes: 'alert-danger', position: 'right' });
                        } else {
                            var chequerasConCheques = [];

                            var chequeraPromises = chequeras.map(function (chequera) {
                                var filters = {
                                    search: filterFactory.and([
                                        {
                                            path: 'estado.codigo',
                                            equals: 'estado_cheque_disponible'
                                        },
                                        {
                                            path: 'chequera.id',
                                            equals: chequera.id
                                        }
                                    ]).value(),
                                    view: 'ChequeList'
                                };

                                return ChequeFactory.all(filters).$promise.then(function (cheques) {
                                    if (cheques.length > 0) {
                                        chequerasConCheques.push(chequera);
                                    }
                                });
                            });

                            // Esperamos a que todas las promesas terminen
                            Promise.all(chequeraPromises).then(function () {
                                vm.chequeras = chequerasConCheques;
                            });

                        }
                    }
                });

                //}, "locacion.id");
                console.log("vm.editar", vm.editar);
                if ((!vm.editar) && (vm.chequeras)) {
                    console.log("vm.editar2", vm.editar);
                    vm.desembolsoOrdenPago.ordenesPago = [];
                    vm.desembolsoOrdenPago.cheques = [];
                    vm.buscarOrdenesPago();
                }
                vm.editar = false;
            } else {
                if (vm.monedaCuenta != null && vm.monedaCuenta.codigo != vm.desembolsoOrdenPago.cuentaBancaria.moneda.codigo) {
                    //ERRROR
                }
                vm.monedaCuenta = vm.desembolsoOrdenPago.cuentaBancaria.moneda;
            }
        }
    }

    function getOrdenesPago() {
        if (vm.search !== undefined && vm.search.proveedor !== null) {
            vm.search.ordenPago = undefined;
            var filters = {
                search: filterFactory.and([{
                    path: 'estado.codigo',
                    equals: "opago_confirmada"
                },
                {
                    path: 'proveedor.id',
                    equals: vm.search.proveedor.id
                }
                ]).value()
            };
        } else {
            var filters = {
                search: filterFactory.single({
                    path: 'estado.codigo',
                    equals: "opago_confirmada"
                }).value()
            };
        }


        if (vm.watcherOP) { vm.watcherOP(); } //hacemos unwatch de la expresion anterior
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcherOP) {
            vm.watcherOP = watcherOP;
            OrdenPagoFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, filters))
                .$promise
                .then(function (response) {
                    vm.desembolsoOrdenPago.ordenesPagosNro = response;
                });
        }, "locacion.id");
    }

    function getPlanillasFondoFijo() {
        var filter = {
            search: filterFactory.single({
                path: 'estado.codigo',
                equals: 'epf_confirmado'
            }).value()
        };
        if (vm.watcherPFF) { vm.watcherPFF(); } //hacemos unwatch de la expresion anterior
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcherPFF) {
            vm.watcherPFF = watcherPFF;
            vm.planillasFondoFijo = PlanillaFondoFijoFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, filter, 'PlanillaFondoFijoList'));
        }, "locacion.id");
    }

    function buscarOrdenesPago() {
        if (!vm.search) {
            vm.search = {};
        }
        //se arma el filtro
        /*

        */
        var filtersArray = [];

        if (vm.desembolsoOrdenPago.formaPago) {
            filtersArray.push({
                path: 'formaPago.id',
                equals: vm.desembolsoOrdenPago.formaPago.id
            });
        }
        else {
            notify({ message: "Debe definir la forma de pago", classes: 'alert-danger', position: 'right' });
            return;
        }

        if (vm.search.proveedor) {
            filtersArray.push({
                path: 'proveedor.id',
                equals: vm.search.proveedor.id
            });
        }

        if (vm.search.moneda) {
            filtersArray.push({
                path: 'moneda.id',
                equals: vm.search.moneda.id
            })
        } else {
            notify({ message: "Debe completar el filtro de moneda", classes: 'alert-danger', position: 'right' });
            return;
        }
        /*else{
                  notify({ message: "debe completar el filtro de moneda", classes: 'alert-danger', position: 'right'});
                  return;
                }*/

        if (vm.search.ordenPago) {
            filtersArray.push({
                path: 'id',
                equals: vm.search.ordenPago.id
            })
        }

        if (vm.search.planillaFondoFijo) {
            filtersArray.push({
                path: 'planillaFondoFijo.id',
                equals: vm.search.planillaFondoFijo.id
            })
        }

        if (vm.search.codigoPago) {
            filtersArray.push({
                path: 'codigoPago',
                equals: vm.search.codigoPago
            })
        }

        filtersArray.push({
            path: 'desembolso',
            like: 'false'
        })

        var topConditions = [{
            path: 'estado.codigo',
            equals: 'opago_confirmada'
        }
        ];

        var filtersOr = _.map(topConditions, function (condition) {
            return filterFactory.and(_.union([condition], filtersArray)).value();
        });

        if (vm.desembolsoOrdenPago && vm.desembolsoOrdenPago.id) {
            filtersOr.push({
                path: 'desembolsoOrdenPago.id',
                equals: vm.desembolsoOrdenPago.id
            });
        }

        var filters = { search: filterFactory.or(filtersOr).value() };
        vm.optionsDtOrdenesPago.staticFilter = filters;
        vm.showDtOrdenesPago = true;
    }

    function clearFiltrosOrdenesPago() {
        delete vm.search;
        vm.search = {};
        vm.search.moneda = vm.desembolsoOrdenPago.cuentaBancaria.moneda;
    }

    function formaPagoSelected() {

        vm.showDtOrdenesPago = false;

        vm.desembolsoOrdenPago.cuentaBancaria = null;
        comprobacionFormaPago();
        switch (vm.desembolsoOrdenPago.formaPago.clase.codigo) {
            case "forma_pago_efectivo":
                formaPagoEfectivoSelected();
                break;
            case "forma_pago_cheque":
                formaPagoChequeSelected();
                break;
            case "forma_pago_debito":
                formaPagoDebitoSelected();
                break;
            case "forma_pago_cuenta_contable":
                formaPagoCuentaContableSelected();
                break;
            case "forma_pago_transferencia_bancaria":
                formaPagoTransferenciaSelected();
                break;
        }
    }

    function formaPagoEfectivoSelected() {
        vm.cuentaBancariaEnabled = false;
        vm.desembolsoOrdenPago.cheques = null;
        vm.desembolsoOrdenPago.ordenesPagosNro = null;

        delete vm.desembolsoOrdenPago.cuentaBancaria;
        vm.disableMoneda = false;
        vm.chequeraEnabled = false;
        vm.asignarNroBancario = false;
        vm.showAsignarNroOperacion = false;
        delete vm.desembolsoOrdenPago.chequera;
        vm.primerChequeEnabled = false;
        delete vm.primerCheque;
        vm.generarUnSoloChequeEnabled = false;
        delete vm.desembolsoOrdenPago.generarUnSoloCheque;
        vm.cuentaContableEnabled = false;
        delete vm.desembolsoOrdenPago.cuentaContable;
    }

    function formaPagoChequeSelected() {
        vm.cuentaBancariaEnabled = true;
        vm.chequeraEnabled = true;
        vm.primerChequeEnabled = true;
        vm.generarUnSoloChequeEnabled = true;
        vm.desembolsoOrdenPago.generarUnSoloCheque = true;
        vm.cuentaContableEnabled = false;
        delete vm.desembolsoOrdenPago.cuentaContable;
        vm.asignarNroBancario = false;
        vm.showAsignarNroOperacion = false;
    }

    function formaPagoDebitoSelected() {
        vm.cuentaBancariaEnabled = true;
        vm.chequeraEnabled = false;
        delete vm.desembolsoOrdenPago.chequera;
        vm.primerChequeEnabled = false;
        delete vm.primerCheque;
        vm.generarUnSoloChequeEnabled = false;
        vm.showCheques = false;
        delete vm.desembolsoOrdenPago.generarUnSoloCheque;
        vm.cuentaContableEnabled = false;
        delete vm.desembolsoOrdenPago.cuentaContable;
        //controlar si parametro esta activado
        ParametrosFactory.getByCodigo('asignar_nro_mov_bancario_desembolso').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.asignarNroBancario = false;
            } else {
                vm.asignarNroBancario = true;
            }
        })
    }

    function formaPagoTransferenciaSelected() {
        vm.cuentaBancariaEnabled = true;
        vm.chequeraEnabled = false;
        delete vm.desembolsoOrdenPago.chequera;
        vm.primerChequeEnabled = false;
        delete vm.primerCheque;
        vm.showCheques = false;
        vm.generarUnSoloChequeEnabled = false;
        delete vm.desembolsoOrdenPago.generarUnSoloCheque;
        vm.cuentaContableEnabled = false;
        delete vm.desembolsoOrdenPago.cuentaContable;
        //controlar si parametro esta activado
        ParametrosFactory.getByCodigo('asignar_nro_mov_bancario_desembolso').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.asignarNroBancario = false;
            } else {
                vm.asignarNroBancario = true;
            }
        })
    }

    function formaPagoCuentaContableSelected() {
        vm.cuentaBancariaEnabled = false;
        delete vm.desembolsoOrdenPago.cuentaBancaria;
        vm.disableMoneda = false;
        vm.chequeraEnabled = false;
        delete vm.desembolsoOrdenPago.chequera;
        vm.primerChequeEnabled = false;
        delete vm.primerCheque;
        vm.generarUnSoloChequeEnabled = false;
        delete vm.desembolsoOrdenPago.generarUnSoloCheque;
        vm.cuentaContableEnabled = true;
        vm.asignarNroBancario = false;
        vm.showAsignarNroOperacion = false;
    }

    function chequeraSelected() {
        delete vm.primerCheque;
        delete vm.desembolsoOrdenPago.cheques;
        delete vm.desembolsoOrdenPago.ordenesPagosNro;
        var filters = {
            search: filterFactory.and([{
                path: 'estado.codigo',
                equals: 'estado_cheque_disponible'
            },
            {
                path: 'chequera.id',
                equals: vm.desembolsoOrdenPago.chequera.id
            }
            ]).value()
        };

        filters.view = 'ChequeList'
        ChequeFactory.all(filters).$promise.then(function (cheques) {
            vm.cheques = cheques;
            if (cheques.length == 0) {
                notify({ message: "La chequera seleccionada no cuenta con cheques disponibles.", classes: 'alert-danger', position: 'right' });
            }
        });
    }

    function searchCuentaBancariaFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return item.nombreCuenta.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.numeroCuenta.toLowerCase().indexOf(criteria.toLowerCase()) > -1;
        };
    }

    function searchChequeraFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.numeroSerie.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.numeroDesde.toString().toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.numeroHasta.toString().toLowerCase().indexOf(criteria.toLowerCase()) > -1;
        };
    }


    // Verifica si la forma de pago del desembolso coincide con las formas de pago de los detalles
    function comprobacionFormaPago() {

        vm.banCoincide = true;
        var filters = {
            search: filterFactory.single({
                path: 'desembolsoOrdenPago.id',
                equals: vm.desembolsoOrdenPago.id
            }).value()
        };

        if (vm.desembolsoOrdenPago.id != null && vm.desembolsoOrdenPago.ordenesPago != null) {

            _.forEach(vm.desembolsoOrdenPago.ordenesPago, function (op) {
                if (op.formaPago.id != vm.desembolsoOrdenPago.formaPago.id) {
                    var msg = $filter('translate')('FORMA_PAGO_ERROR');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.banCoincide = false;
                    return false;
                }
            });
        }
        if (vm.banCoincide) { return true }
        else { return false; }
    }

    function evaluatePeriodoAdministrativo(ordenesPago) {
        return $q(function (resolve, reject) {
            var params = {
                search: filterFactory.single({
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                }).value()
            };
            params.view = "BaseList"

            PeriodoAdministrativoFactory.all(params).$promise.then(function (response) {
                var periodos = response;
                vm.ban = false;
                _.forEach(ordenesPago, function (op) {
                    vm.ban = false;
                    _.forEach(periodos, function (value) {
                        var desde = new Date(value.fechaDesde);
                        var hasta = new Date(value.fechaHasta);
                        var fecha = new Date(op.fecha);

                        if (fecha >= desde && fecha <= hasta) {
                            vm.ban = true;
                            return;
                        }
                    })
                    // Si no encuentra periodo, no continuar iteracion y salir
                    if (!vm.ban) {
                        return;
                    }
                });
                resolve(vm.ban);
            });
        });
    }

    /* TODO: Utilizar esta misma funcion en generarDetalleDesembolso */
    function getOrdenesPagoSelected() {

        console.log("LLAMANDO DETALLE SELECTED");
        return $q(function (resolve, reject) {

            var idsDetails = getIDs();
            if (!vm.showDtOrdenesPago || !idsDetails || idsDetails.length == 0) {
                notify({ message: $filter('translate')('OPS_NOT_SELECTED'), classes: 'alert-danger', position: 'right' });
                resolve(null);
                return;
            }

            var opsFilters = _.map(idsDetails, function (id) {
                return {
                    path: 'id',
                    equals: id
                };
            });

            var filters = {
                search: filterFactory.or(opsFilters).value(),
                view: 'OrdenPagoChequeList'
            };


            OrdenPagoFactory.all(filters).$promise.then(function (response) {
                resolve(response);
            });
        });
    }


    function submit() {

        if (vm.fromModal == true) {
            console.log("ES DEL MODAL NO HAGAS SUBMIT")
            return;
        }

        vm.submited = true;

        if ($scope.DesembolsoOrdenPagoForm.$valid) {


            if (vm.desembolsoOrdenPago.ordenesPagosNro.length > 0) {
                for (var i = 0; i < vm.desembolsoOrdenPago.ordenesPagosNro.length; i++) {
                    if (!/^\d+$/.test(vm.desembolsoOrdenPago.ordenesPagosNro[i].numeroOperacion)) {
                        notify({ message: "El numero de operacion debe ser solo numerico", classes: 'alert-danger', position: 'right' });
                        return;
                    }
                    var numeroComoTexto = vm.desembolsoOrdenPago.ordenesPagosNro[i].numeroOperacion.toString();

                    if (numeroComoTexto.length > 19) {
                        notify({ message: "El numero de operacion puede tener solo hasta 19 digitos", classes: 'alert-danger', position: 'right' });
                        return;
                    }
                }

            }

            // Validacion fecha de pago mayor a fecha de emision

            if (vm.desembolsoOrdenPago.chequera && vm.desembolsoOrdenPago.chequera.chequeDiferido) {
                vm.banFecha = false;
                _.forEach(vm.desembolsoOrdenPago.cheques, function (cheque) {
                    if (cheque.fechaPago < cheque.fecha_emision) {
                        notify({ message: $filter('translate')('ERROR_FECHA_PAGO', { cheque: cheque.numero }), classes: 'alert-danger', position: 'right' });
                        vm.banFecha = true;
                    }
                })
                if (vm.banFecha) {
                    return;
                }
            }

            /* Se realiza comprobacion de Forma de Pago (filtro vs ordenes seleccionadas) */
            if (!comprobacionFormaPago()) {
                return;
            }

            if (vm.isEdit) {

                var filtersCheque = {
                    search: filterFactory.and([
                        {
                            path: "desembolsoOrdenPago.id",
                            equals: vm.desembolsoOrdenPago.id
                        }
                    ]).value()
                };
            }

            if (vm.desembolsoOrdenPago.cheques == undefined) {
                vm.desembolsoOrdenPago.cheques = [];
            }

            if (vm.desembolsoOrdenPago.ordenesPagosNro == undefined) {
                vm.desembolsoOrdenPago.ordenesPagosNro = [];
            }

            vm.desembolsoOrdenPago.moneda = vm.search.moneda;
            console.log("vm.desembolsoOrdenPago.ordenesPagosNro ", vm.desembolsoOrdenPago.ordenesPagosNro)
            var trimmedModel = ModelTrimmer.trimDetails(vm.desembolsoOrdenPago, ["cheques", "ordenesPagosNro"]);
            var idsDetails = getIDs();
            if (!vm.showDtOrdenesPago || !idsDetails || idsDetails.length == 0) {
                notify({ message: $filter('translate')('OPS_NOT_SELECTED'), classes: 'alert-danger', position: 'right' });
                return;
            }
            trimmedModel.ordenesPago = _.map(idsDetails, function (id) {
                var idVal = parseInt(id);

                if (!idVal) {
                    console.error("No se pudo parsear Id");
                    return;
                }

                return idVal;
            });

            if (!vm.isEdit) {
                trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
            }

            // Dependiendo de la forma de pago se realiza la comprobacion de periodo administrativo
            if (vm.desembolsoOrdenPago.formaPago.clase.codigo == "forma_pago_cheque" ||
                vm.desembolsoOrdenPago.formaPago.clase.codigo == "forma_pago_debito" ||
                vm.desembolsoOrdenPago.formaPago.clase.codigo == "forma_pago_transferencia_bancaria") {

                // Si es forma pago debito, se debe recuperar OP's (simulacion de presionar generar detalle desembolso)
                if (vm.desembolsoOrdenPago.formaPago.clase.codigo == "forma_pago_debito" ||
                    vm.desembolsoOrdenPago.formaPago.clase.codigo == "forma_pago_transferencia_bancaria") {
                    getOrdenesPagoSelected().then(function (ordenesPago) {

                        if (ordenesPago != null) {
                            if (vm.asignarNroBancario && (vm.desembolsoOrdenPago.ordenesPagosNro == undefined || vm.desembolsoOrdenPago.ordenesPagosNro.length == 0 || vm.desembolsoOrdenPago.ordenesPagosNro == null)) {
                                var msg = "No se han asignado ningun Nro de Operacion a las OP";
                                notify({ message: msg, classes: "alert-danger", position: "right" });
                                return;
                            }

                            // Al recuperar las OP, se realiza la comprobacion de periodo administrativo
                            evaluatePeriodoAdministrativo(ordenesPago).then(function (response) {
                                if (!response) {
                                    var msg = ' ' + $filter("translate")("NO_ADMINISTRATIVE_PERIOD");
                                    notify({ message: msg, classes: "alert-danger", position: "right" });
                                } else {
                                    console.log("ppptrimer ", trimmedModel)
                                    formFactory.defaultNSFSubmit($scope.DesembolsoOrdenPagoForm, DesembolsoOrdenPagoFactory, trimmedModel).then(function (response) {
                                        $location.path('/desembolsoordenpago');
                                    }, function (error) {
                                        console.log(error);
                                    });
                                }
                            });
                        }
                    })
                    // Si no es forma pago debito, se realiza la comprobacion de periodo directamente
                } else {
                    console.log(" vm.desembolsoOrdenPago.cheques YYYYYY " + vm.desembolsoOrdenPago.cheques.length);
                    if ((vm.desembolsoOrdenPago.cheques == undefined || vm.desembolsoOrdenPago.cheques.length == 0 || vm.desembolsoOrdenPago.cheques == null) && (vm.desembolsoOrdenPago.ordenesPago == undefined || vm.desembolsoOrdenPago.ordenesPago == null || vm.desembolsoOrdenPago.ordenesPago.length == 0)) {
                        var msg = $filter("translate")("NO_CHEQUES_GENERADOS");
                        notify({ message: msg, classes: "alert-danger", position: "right" });
                        return;
                    }
                    evaluatePeriodoAdministrativo(vm.desembolsoOrdenPago.ordenesPago.length !== 0 ? vm.desembolsoOrdenPago.ordenesPago : vm.ordenesPagoFraccionados).then(function (response) {
                        if (!response) {
                            var msg = ' ' + $filter("translate")("NO_ADMINISTRATIVE_PERIOD");
                            notify({ message: msg, classes: "alert-danger", position: "right" });
                        } else {
                            formFactory.defaultNSFSubmit($scope.DesembolsoOrdenPagoForm, DesembolsoOrdenPagoFactory, trimmedModel).then(function (response) {
                                ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                                    if (parametro.valorTipo.codigo == 'si') {
                                        if (response.enviadoAContabilidad == false) {
                                            notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                        }
                                    }
                                });
                                $location.path('/desembolsoordenpago');
                            }, function (error) {
                                console.log(error);
                            });
                        }
                    });
                }
            } else {
                formFactory.defaultNSFSubmit($scope.DesembolsoOrdenPagoForm, DesembolsoOrdenPagoFactory, trimmedModel).then(function (response) {
                    ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                        if (parametro.valorTipo.codigo == 'si') {
                            if (response.enviadoAContabilidad == false) {
                                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                            }
                        }
                    });
                    $location.path('/desembolsoordenpago');
                }, function (error) {
                    console.log(error);
                });
            }
        } else {
            notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right' });
        }
    }

    function cancel() {
        $location.path('/desembolsoordenpago');
    }

    var getIDs = function () {
        var ids = [];
        _.forEach(vm.optionsDtOrdenesPago.selection, function (n, key) {
            if (n)
                ids.push(key);
        });
        return ids;
    };

    var formSelectionsArray = function () {
        console.log("forming selections array");
        $scope.$watch('vm.optionsDtOrdenesPago.selection', function (newValue, oldValue) {
            if (!vm.optionsDtOrdenesPago.selection || vm.selectedChecked) {
                return;
            }
            vm.selectedChecked = true;
            _.forEach(vm.desembolsoOrdenPago.ordenesPago, function (op) {
                if (op.id) {
                    vm.optionsDtOrdenesPago.selection[op.id] = true;
                } else {
                    vm.optionsDtOrdenesPago.selection[op] = true;
                }
            });
        });
    };

    function searchPlanillaFondoFijo(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.numero == criteria ||
                item.tipoFondoFijo.nombre.indexOf(criteria) === 0
            );
        };
    }

    function generarDetalleDesembolso() {
        vm.desembolsoOrdenPago.cheques = [];
        var idsDetails = getIDs();
        if (!vm.showDtOrdenesPago || !idsDetails || idsDetails.length == 0) {
            notify({ message: $filter('translate')('OPS_NOT_SELECTED'), classes: 'alert-danger', position: 'right' });
            return;
        }

        var opsFilters = _.map(idsDetails, function (id) {
            return {
                path: 'id',
                equals: id
            };
        });

        var filters = {
            search: filterFactory.or(opsFilters).value(),
            view: 'OrdenPagoChequeList'
        };



        OrdenPagoFactory.all(filters).$promise.then(function (response) {

            response = _.sortBy(response, function (op) { return op.numero; });

            vm.desembolsoOrdenPago.ordenesPago = response;

            vm.ordenesPagoFraccionados = [];
            /*Filtrar ordenes de pago con cheques fraccionados*/
            _.forEach(vm.listaDistribucionIngresada, function (object) {
                var found = _.find(vm.desembolsoOrdenPago.ordenesPago, function (detalle) {
                    if (detalle.id == object.data[0].idOrdenPago) {
                        vm.ordenesPagoFraccionados.push(detalle);
                    }
                    return detalle.id == object.data[0].idOrdenPago;
                });
                var i = vm.desembolsoOrdenPago.ordenesPago.indexOf(found);
                if (i != -1) {
                    vm.desembolsoOrdenPago.ordenesPago.splice(i, 1);
                }
            });

            if (vm.desembolsoOrdenPago.ordenesPago.length === 0) {
                vm.ultimoNroCheque = vm.primerCheque.numero - 1;
                generarChequesFraccionados();
                return;
            }
            console.log("ASI QUEDO LA LISTA : " + vm.desembolsoOrdenPago.ordenesPago.length)
            var proveedores = _.map(response, function (op) {
                if (op.proveedor) {
                    return op.proveedor.id;
                } else {
                    return -1; // -1 va a significar la ausencia de proveedor
                }
            });

            var areAllSameProveedor = !_.without(proveedores, proveedores[0]).length;
            if (vm.desembolsoOrdenPago.generarUnSoloCheque && areAllSameProveedor) {
                vm.primerCheque.monto = _.reduce(vm.desembolsoOrdenPago.ordenesPago, function (sum, op) {
                    return sum + op.importe;
                }, 0);

                // Redondeo de monto
                if (vm.search.moneda.codigo == "dolares") {
                    vm.primerCheque.monto = parseFloat(vm.primerCheque.monto.toFixed(2));
                } else if (vm.search.moneda.codigo == "guaranies") {
                    vm.primerCheque.monto = parseFloat(vm.primerCheque.monto.toFixed(0));
                }

                // Si se tiene una unica OP asociada, se toma su fecha
                if (vm.desembolsoOrdenPago.ordenesPago.length == 1) {
                    vm.primerCheque.fecha_emision = new Date(vm.desembolsoOrdenPago.ordenesPago[0].fecha);
                    vm.primerCheque.concepto = vm.desembolsoOrdenPago.ordenesPago[0].concepto;
                }

                // Si tiene mas de una OP asociada, se toma la fecha mas reciente
                if (vm.desembolsoOrdenPago.ordenesPago.length > 1) {
                    var mayor = vm.desembolsoOrdenPago.ordenesPago[0].fecha;
                    var conceptoOP = "";

                    for (var i = 0; i < vm.desembolsoOrdenPago.ordenesPago.length; i++) {
                        if (vm.desembolsoOrdenPago.ordenesPago[i].fecha > mayor) {
                            mayor = vm.desembolsoOrdenPago.ordenesPago[i].fecha;
                        }
                        //if(vm.desembolsoOrdenPago.ordenesPago[i].concepto != null){
                        conceptoOP += vm.desembolsoOrdenPago.ordenesPago[i].numero + ", ";
                        //}
                    }
                    vm.primerCheque.fecha_emision = new Date(mayor);
                    vm.primerCheque.concepto = conceptoOP;
                }

                vm.primerCheque.beneficiario = response[0].beneficiario;
                var numeros = _.map(response, function (op) { return op.numero });

                //console.log("AQUI NUMEROs: ", numeros);
                if (vm.primerCheque.concepto == null) {
                    var joinedNumeros = numeros.join();
                    if (!vm.primerCheque.concepto) vm.primerCheque.concepto = "OP: " + joinedNumeros;
                }

                vm.desembolsoOrdenPago.cheques = [vm.primerCheque];

            } else {
                var primerOP = response[0];
                if (vm.desembolsoOrdenPago.generarUnSoloCheque) {
                    notify({ message: $filter('translate')('VARIOS_PROVEEDORES_UN_CHEQUE_WARNING'), classes: 'alert-warning', position: 'right' });
                }
                if (primerOP.concepto) {
                    vm.primerCheque.concepto = primerOP.concepto;
                } else {
                    vm.primerCheque.concepto = "OP: " + primerOP.numero
                }
                var amount = vm.desembolsoOrdenPago.ordenesPago.length;
                vm.primerCheque.monto = primerOP.importe;
                vm.primerCheque.beneficiario = primerOP.beneficiario;
                var numeros = _.map(response, function (op) { return op.numero });
                var joinedNumeros = numeros.join();

                //AQUI CASO VARIOS CHEQUES
                vm.primerCheque.fecha_emision = new Date(vm.desembolsoOrdenPago.ordenesPago[0].fecha);


                vm.desembolsoOrdenPago.cheques = [vm.primerCheque];
                var lastNumero = vm.primerCheque.numero;
                var i = 1;
                for (var next = lastNumero + 1; next < lastNumero + amount; next++) {
                    /*if (!getAndAddCheque(next, i)) {
                        notify({ message: $filter('translate')('NOT_ENOUGH_CHEQUES'), classes: 'alert-danger', position: 'right' });
                        delete vm.desembolsoOrdenPago.cheques;
                        vm.showCheques = false;
                        return;
                        i = i + 1;
                    }*/
                    var next = 0;
                    if (amount > vm.cheques.length) {
                        notify({ message: $filter('translate')('NOT_ENOUGH_CHEQUES'), classes: 'alert-danger', position: 'right' });
                        delete vm.desembolsoOrdenPago.cheques;
                        vm.showCheques = false;
                        return;
                    } else {
                        next = lastNumero;
                        for (var index = 1; index < amount; index++) {
                            next++;
                            while (!getAndAddCheque(next, i)) {
                                next++;
                            }
                            i = i + 1;
                        }
                    }
                }
            }
            vm.ultimoNroCheque = vm.desembolsoOrdenPago.cheques[vm.desembolsoOrdenPago.cheques.length - 1].numero;
            vm.cantidadTotalCheques = vm.desembolsoOrdenPago.cheques.length;
            generarChequesFraccionados();
            vm.showCheques = true;
        });
    }

    function generarChequesFraccionados() {

        /*si no tenemos cheques para fraccionar,retornar*/
        if (vm.ordenesPagoFraccionados.length == 0) {
            return;
        }

        /* si ya no contamos con nros de cheque disponibles entonces retornar*/
        if (vm.desembolsoOrdenPago.cheques.length == vm.cheques.length) {
            notify({ message: $filter('translate')('NOT_ENOUGH_CHEQUES'), classes: 'alert-danger', position: 'right' });
            return;
        }
        vm.chequesFraccionados = [];
        _.forEach(vm.ordenesPagoFraccionados, function (ordenPago) {
            console.log("orden ", ordenPago)
            var found = _.find(vm.listaDistribucionIngresada, function (distribucion) {
                if (distribucion.data[0].idOrdenPago == ordenPago.id) {
                    _.forEach(distribucion.data, function (configuracion) {
                        var chequeFracc = {
                            concepto: "OP: " + ordenPago.numero + " - " + configuracion.nroItem,
                            beneficiario: ordenPago.beneficiario,
                            monto: configuracion.importeIngresado ? configuracion.importeIngresado : configuracion.importeSugerido,
                            fecha_emision: new Date(ordenPago.fecha),
                            codigoCheque: ordenPago.numero + "-" + configuracion.nroItem

                        }
                        vm.chequesFraccionados.push(chequeFracc);
                    });
                }
                return distribucion.data[0].idOrdenPago == ordenPago.id;
            });
        });
        getNroCheque();
        var cantNumeros = 0;
        _.forEach(vm.chequesFraccionados, function (chequeF) {
            var numero_cheque = vm.numerosChequesFraccionados[cantNumeros];
            /*Agregar datos de cheques originales con datos a completar*/
            var cheque = _.find(vm.cheques, function (cheque) {
                return numero_cheque === cheque.numero;
            });

            if (!cheque) {
                return false;
            }
            cheque.concepto = chequeF.concepto
            cheque.beneficiario = chequeF.beneficiario
            cheque.monto = chequeF.monto
            cheque.fecha_emision = chequeF.fecha_emision
            cheque.numero = numero_cheque
            cheque.codigoCheque = chequeF.codigoCheque

            if (vm.desembolsoOrdenPago.cheques !== undefined) {
                vm.desembolsoOrdenPago.cheques.push(cheque);
            }

            cantNumeros = cantNumeros + 1;

        });

        vm.fromModal = false;
        vm.showCheques = true;
    }

    /*Obtener Nro. de cheque para fraccionamiento de cheques*/
    function getNroCheque() {
        var cantidadTotalCheques = 0;
        vm.numerosChequesFraccionados = [];

        if (vm.chequesFraccionados !== undefined) {
            cantidadTotalCheques = vm.chequesFraccionados.length + vm.cantidadTotalCheques;
        }

        if (cantidadTotalCheques > vm.cheques.length) {
            vm.listaDistribucionIngresada = [];
            vm.chequesFraccionados = [];
            delete vm.desembolsoOrdenPago.cheques;
            vm.showCheques = false;
            notify({ message: 'La cantidad de cheques disponibles es insuficiente', classes: 'alert-danger', position: 'right' });
            return;
        } else {
            var nroNuevo = 0;
            for (var i = 0; i < vm.cheques.length; i++) {

                if (i == 0) {
                    nroNuevo = vm.ultimoNroCheque + 1
                } else { nroNuevo = nroNuevo + 1; }

                var cheque = _.find(vm.cheques, function (cheque) {
                    return nroNuevo === cheque.numero;
                });

                if (cheque) {
                    if (vm.numerosChequesFraccionados.length == cantidadTotalCheques) {
                        return;
                    }

                    vm.numerosChequesFraccionados.push(cheque.numero);
                }
            }

        }

    }


    function getAndAddCheque(next, i) {
        console.log("NEXT..." + next);
        var cheque = _.find(vm.cheques, function (cheque) {
            return cheque.numero == next;
        });
        if (!cheque) {
            return false;
        }
        var op = vm.desembolsoOrdenPago.ordenesPago[i];
        console.log("Se obtiene op con indice: ", i);
        cheque.monto = op.importe;
        cheque.beneficiario = op.beneficiario;
        if (op.concepto) {
            cheque.concepto = op.concepto;
        } else {
            cheque.concepto = "OP: " + op.numero;
        }

        //AQUI VARIOS CHEQUES
        cheque.fecha_emision = new Date(vm.desembolsoOrdenPago.ordenesPago[i].fecha);

        vm.desembolsoOrdenPago.cheques.push(cheque);
        return true;
    }

    function asignarNroMovimientoBancario() {
        vm.desembolsoOrdenPago.ordenesPagosNro = [];
        var idsDetails = getIDs();
        if (!vm.showDtOrdenesPago || !idsDetails || idsDetails.length == 0) {
            notify({ message: $filter('translate')('OPS_NOT_SELECTED'), classes: 'alert-danger', position: 'right' });
            return;
        }
        vm.showAsignarNroOperacion = true;

        var opsFilters = _.map(idsDetails, function (id) {
            return {
                path: 'id',
                equals: id
            };
        });

        var filters = {
            search: filterFactory.or(opsFilters).value(),
            view: 'OrdenPagoChequeList'
        };

        OrdenPagoFactory.all(filters).$promise.then(function (response) {
            vm.desembolsoOrdenPago.ordenesPagosNro = response;
        });
    }

    $scope.resource = 'desembolsoordenpago'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

angular.module('qualita.tesoreria')
    .factory('DesembolsoOrdenPagoFactory', DesembolsoOrdenPagoFactory);

    DesembolsoOrdenPagoFactory.$inject = ['$resource', 'baseurl', '$http'];

    function DesembolsoOrdenPagoFactory($resource, baseurl, $http) {
      var service = {
          all: all,
          get: get,
          create: create,
          save: save,
          remove: remove,
          anular: anular,
          desconfirmar: desconfirmar,
          reenviar: reenviar,
          generarDebito: generarDebito,
          emitirCheque: emitirCheque,
          emitir: emitir
      };

      var DesembolsoOrdenPago = $resource( baseurl.getBaseUrl() + "/desembolsoordenpago/:id", {id: '@id'}, {
        update: {
          method: 'PUT'
        },
        anular: {
          method: 'POST',
          url: baseurl.getBaseUrl() + '/desembolsoordenpago/:id/anular'
        },
        desconfirmar: {
          method: 'POST',
          url: baseurl.getBaseUrl() + '/desembolsoordenpago/:id/desconfirmar'
        },
        reenviar: {
          method: 'POST',
          url: baseurl.getBaseUrl() + '/desembolsoordenpago/:id/reenviar'
        },
        generarDebito: {
          method: 'POST',
          url: baseurl.getBaseUrl() + '/desembolsoordenpago/:id/generardebito'
        },
        emitirCheque: {
          method: 'POST',
          url: baseurl.getBaseUrl() + '/desembolsoordenpago/:id/emitircheque'
        },
        emitir: {
          method: 'POST',
          url: baseurl.getBaseUrl() + '/desembolsoordenpago/:id/emitir'
        }
      });

      return service;

  function all(params) {
    return DesembolsoOrdenPago.query(params);
  }

  function get(id, view) {
    if (typeof(view)==='undefined') view = "base";
    return DesembolsoOrdenPago.get({id: id, view: view});
  }

  function create(attrs) {
    return new DesembolsoOrdenPago(attrs);
  }

  function save(ordenpago) {
    return (ordenpago.id) ? ordenpago.$update() : ordenpago.$save();
  }

  function remove(ordenpago) {
    return ordenpago.$remove();
  }

  function anular(id) {
    return DesembolsoOrdenPago.anular({id: id});
  }

  function desconfirmar(id) {
    return DesembolsoOrdenPago.desconfirmar({id: id});
  }

  function reenviar(id) {
    return DesembolsoOrdenPago.reenviar({id: id});
  }

  function generarDebito(id) {
    return DesembolsoOrdenPago.generarDebito({id: id});
  }

  function emitirCheque(id) {
    return DesembolsoOrdenPago.emitirCheque({id: id});
  }

  function emitir(id) {
    return DesembolsoOrdenPago.emitir({id: id});
  }
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.CobroDetalleFactory
 * @description
 * # CobroDetalleFactory
 * Factory in the qualita.
 */
angular.module('qualita.tesoreria')
  .factory('CobroDetalleFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
    '$translatePartialLoader', '$translate',
    function ($resource, baseurl, filterFactory, TiposFactory, $http, $translatePartialLoader, $translate) {

      var CobroDetalleFactory = $resource(baseurl.getBaseUrl() + '/cobrosdetalles/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined ? view : 'BaseList';
          } else {
            params = [];
            params.view = view != undefined ? view : 'BaseList';
          }
          return CobroDetalleFactory.query(params);
        },

        get: function (id, view) {
          if (typeof (view) === 'undefined') view = "base";
          return CobroDetalleFactory.get({ id: id, view: view });
        }

      };
    }]);

'use strict';


angular.module('qualita.tesoreria')
  .controller('ConsultaSaldoProveedorIndexCtrl',
    ["$scope", "TesoreriaLangFactory", "filterFactory", "ReportTicketFactory", "$window", "notify", "UtilFactory", "$filter", "baseurl", "TiposTipoFactory", "ProveedoresFactory", "SaldoProveedorFactory", function ($scope, TesoreriaLangFactory, filterFactory, ReportTicketFactory,
      $window, notify, UtilFactory, $filter, baseurl, TiposTipoFactory, ProveedoresFactory,
      SaldoProveedorFactory) {

        var vm = this;
        $scope.monedas = TiposTipoFactory.get("monedas");
        $scope.monedas.$promise.then(function() {
            $scope.monedas.unshift({
                "id" : null,
                "codigo" : null,
                "descripcion" : "Todas"
              });
        });

        /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
        vm.staticFilterProv = [{path: "activo",equals: true}];
        vm.proveedoresFactory = ProveedoresFactory;

        //$scope.filtro = true;

        $scope.filtrar = function (producto) {

            var idProveedor=null, idMoneda=null, fecha=null;

            var staticFilter = {};
            var andFilters = [];

            if ($scope.proveedor != undefined) {
                andFilters.push(
                        {
                            path: "proveedor.id",
                            equals: $scope.proveedor.id
                        }
                )
                idProveedor = $scope.proveedor.id;
            }

            if ($scope.moneda != undefined && $scope.moneda.id) {
                andFilters.push(
                        {
                            path: "moneda.id",
                            equals: $scope.moneda.id
                        }
                )
                idMoneda = $scope.moneda.id;
            }

            if ($scope.fecha != undefined) {
                andFilters.push(
                        {
                            path: "fecha",
                            equalOrBefore: moment(new Date($scope.fecha)).format("DD/MM/YYYY")
                        }
                )
                fecha = $scope.fecha;
            }


            staticFilter.search = filterFactory.and(andFilters).value();
            if ($scope.fecha == null || $scope.fecha == undefined) {
                var msg = "Se requiere el filtro de fecha.";
                notify({ message: msg, classes: 'alert-warning', position: 'right' });
            } else {
                $scope.options.staticFilter = staticFilter;
                $scope.filtro = true;
            }

        };

        var monedaRender = function (data, type, row) {
          var campoMoneda = null;
          if (row.moneda != undefined) {
              campoMoneda = row.moneda;
          }

          if (data != undefined) {
              var moneda = "Gs. ";
              if (campoMoneda.codigo === "dolares") {
                  moneda = "Usd. ";
                  data = parseFloat(data).toFixed(2);
              } else if (campoMoneda.codigo === "pesos") {
                  moneda = "Pes. ";
                  data = parseFloat(data).toFixed(2);
              } else if (campoMoneda.codigo === "real") {
                  moneda = "Rel. ";
                  data = parseFloat(data).toFixed(2);
              } else if (campoMoneda.codigo === "euro") {
                  moneda = "Eur. ";
                  data = parseFloat(data).toFixed(2);
              } else {
                  data = parseFloat(data).toFixed(0);
              }
              return (
                      moneda +
                      data
                      .toString()
                      .replace(".", ",")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      );
          } else {
              return "";
          }
        };

        var fechaHastaRender = function (data, type, row) {
            return moment(new Date($scope.fecha)).format("DD/MM/YYYY");
        };

        TesoreriaLangFactory.getTranslations().then(function (translations) {
            
            var defaultColumnOrder = ['proveedor.id', 'proveedor.nombre', 'moneda.descripcion', 'saldo', 'fecha'];

            $scope.options = {
                'resource': 'saldoproveedores/ranking',
                'title': $filter('translate')('SALDO_PROVEEDORES'),
                'factory': SaldoProveedorFactory,
                'view': 'SaldoProveedoresList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                { 'data': 'proveedor.id', 'title': $filter('translate')('CODIGO'), 'class': 'dt-left' , "searchable": false, "sortable": false, "visible":false},
                  { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDORES'), 'class': 'dt-left' , "searchable": false, "sortable": false},
                  { 'data': 'moneda.descripcion', 'title': $filter('translate')('MONEDA') , "searchable": false, "sortable": false},
                  { 'data': 'saldo', 'title': $filter('translate')('SALDO'), 'class': 'dt-right', render: monedaRender, "searchable": false, "sortable": false},
                  { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'class': 'dt-center', render: fechaHastaRender, "searchable": false, "sortable": false}
                ],
                'hasOptions': false,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraMenuOptions':[
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);
                            SaldoProveedorFactory.getRankingSaldoProveedoresCSV($scope.options.staticFilter, filters).then(function (response) {
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                        }
                    }
                ]
            };
        });
    }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.SaldoProveedorFactory
 * @description
 * # SaldoProveedorFactory
 * Factory in the qualita.
 */

angular.module('qualita.tesoreria')
    .factory('SaldoProveedorFactory', SaldoProveedorFactory);

    SaldoProveedorFactory.$inject = ['$resource', 'baseurl', '$http', 'filterFactory', 'TiposFactory', '$translatePartialLoader', '$translate'];

function SaldoProveedorFactory($resource, baseurl, $http, $q) {

    var service = {
      all: all,
      get: get,
      getSaldo: getSaldo,
      getRankingSaldoProveedoresCSV: getRankingSaldoProveedoresCSV
    }

    var SaldoProveedorFactory = $resource(baseurl.getBaseUrl() + "/saldoproveedores/",  { id: '@id' }, {
      update: {
          method: 'PUT'
      }
    });
    
    return service;

    function all(params, view) {
      if (params) {
        params.view = view != undefined ? view : 'BaseList';
      }
      return SaldoProveedorFactory.query(params);
    }

    function get(params, view) {
      return SaldoProveedorFactory.get({id: id, view: view ? view : "base"});
    }

    function getSaldo(idProv, date, idCurrency) {

      return $http({
          url: baseurl.getBaseUrl() + '/saldoproveedores/a-la-fecha',
          method: "GET",
          params: {
            idProveedor: idProv,
            fecha:date,
            idMoneda: idCurrency
          }
      });
    }

    function getRankingSaldoProveedoresCSV(search, filters) {
      return $http({
          url: baseurl.getBaseUrl() + '/saldoproveedores/ranking/csv',
          method: "GET",
          params: {
            search: search,
            filters: filters
          }
      }); 
    }
 }

'use strict';


angular.module('qualita.tesoreria')
    .controller('ComprobantePendienteIndexCtrl',
        ["$scope", "filterFactory", "ReportTicketFactory", "$window", "ProveedoresFactory", "UtilFactory", "CompraLocalLangFactory", "$filter", "CsvFactory", "baseurl", "TiposTipoFactory", "ComprobanteFactory", "notify", function ($scope, filterFactory, ReportTicketFactory, $window, ProveedoresFactory,
            UtilFactory, CompraLocalLangFactory, $filter, CsvFactory, baseurl,
            TiposTipoFactory, ComprobanteFactory,notify) {

            //Inicio

            var vm =this;

            /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
            vm.staticFilterProv = [{path: "activo",equals: true}];
            vm.proveedoresFactory = ProveedoresFactory;

            $scope.monedas = TiposTipoFactory.get("monedas");

            $scope.clean = function () {
                $scope.proveedor = undefined;
                $scope.moneda = undefined;
                $scope.sucursal = undefined;
            };

            $scope.filtrar = function () {

                var idProveedor=null, idMoneda=null, idSucursal=null;

                /* definir como filtrar por cliente */
                var staticFilter = [];

                if($scope.moneda){
                    staticFilter.push(
                        {
                            path: 'moneda1.id',
                            equals: $scope.moneda.id
                        }
                    )
                    idMoneda = $scope.moneda.id;
                }else{
                    return notify({ message: "Debe ingresar la moneda", classes: "alert-danger", position: "right" });
                }

                //Filtro de clientes
                if($scope.proveedor){
                    staticFilter.push(
                        {
                            path: 'proveedor.id',
                            equals: $scope.proveedor.id
                        }
                    )
                    idProveedor = $scope.proveedor.id;
                }

                if($scope.sucursal){
                    staticFilter.push(
                        {
                            path: 'locacion.sucursal.id',
                            equals: $scope.sucursal.id
                        }
                    )
                    idSucursal = $scope.sucursal.id;
                }


                // Recuperar comprobantes con saldo
                staticFilter.push(
                    {
                        path: 'saldo',
                        greaterThan: 0.0
                    }
                )

                // Recuperar comprobantes aprobados
                staticFilter.push(
                    {
                        path: 'estado.codigo',
                        equals: "comp_aprobado"
                    }
                )

                // Descartar comprobantes NC
                staticFilter.push(
                    {
                        path: 'tipo.clase.codigo',
                        notEquals: 'nota_credito'
                    }
                )

                // Descartar comprobantes NC
                staticFilter.push(
                    {
                        path: 'tipo.clase.codigo',
                        notEquals: 'retencion_absorbida'
                    }
                )

                // Invocacion del service para calculo del campo "Saldo"
                ComprobanteFactory.getSaldo(idProveedor, idMoneda, idSucursal).then(function(data){
                    $scope.saldo = data.data;
                });

                // Se define el filtro final
                var filtros = {
                    search: filterFactory.and(staticFilter).value()
                };

                console.log("AQUI FILTROS: ", filtros);

                $scope.options.staticFilter = filtros;
                $scope.filtro = true;
            };

            /*************** NUEVO ******************** */

            var monedaRender = function(data, type, row) {

                var campoMoneda = null;

                if (row.moneda1 != undefined) {
                    campoMoneda = row.moneda1;
                }

                if (data != undefined) {
                    var moneda = "Gs. ";
                    if (campoMoneda.codigo === "dolares") {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "pesos") {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "real") {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "euro") {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    } else {
                        data = parseFloat(data).toFixed(0);
                    }
                    return (
                        moneda +
                        data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                } else {
                    return "";
                }
            };

            var defaultColumnOrder = ['proveedor.nombre', 'tipo.descripcion', 'numero', 'fecha', 'saldo', 'locacion.sucursal.nombre'];

            CompraLocalLangFactory.getTranslations().then(function (translations) {
                $scope.options = {
                    'resource': 'comprobante',
                    'view': 'ComprobanteList',
                    'title': 'Comprobante',
                    'factory': ComprobanteFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'columns': [
                        { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR_SINGLE'), searchable: false },
                        { 'data': 'tipo.descripcion', 'title': $filter('translate')('TIPO_COMPROBANTE'), searchable: false },
                        { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-right', searchable: false},
                        { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', searchable:false },
                        { 'data': 'saldo', 'title': $filter('translate')('SALDO_PENDIENTE'), 'class' : 'dt-right', renderWith: monedaRender, searchable: false},
                        { 'data': 'locacion.sucursal.nombre', 'title': $filter('translate')('SUCURSAL'), 'renderWith': 'emptyRender', searchable: false }

                    ],
                    'hasOptions': false,
                    'hideAddMenu':true,
                    'defaultOrderColumn': 0,
                    'defaultOrderDir': "asc",
                    'extraMenuOptions': [
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function() { return true; },
                            'action': function() {
                                //var filters = createFilters($scope.options.getFilters());
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);
                                ReportTicketFactory.ticket('listadoComprobantePendiente', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                    $window.open($scope.pdfDownloadURL, '_blank');
                                });
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);
                                CsvFactory.csv("comprobante", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        }
                    ]
                };
            });


            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                  filtersArr.push({path: data, like: search})
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
              }
        }]);

'use strict';

angular.module('qualita.tesoreria')
  .controller('DenominacionTarjetaFormCtrl', DenominacionTarjetaFormCtrl);

DenominacionTarjetaFormCtrl.$inject = ['$rootScope', '$scope', 'DenominacionTarjetaFactory', '$location', '$state',
  'denominacionTarjetaPrepService', 'notify', 'formFactory', 'TesoreriaLangFactory', 'TiposFactory', '$timeout',
  '$filter'];

function DenominacionTarjetaFormCtrl($rootScope, $scope, DenominacionTarjetaFactory, $location, $state,
  denominacionTarjetaPrepService, notify, formFactory, TesoreriaLangFactory, TiposFactory, $timeout,
  $filter) {

  activate();
  $scope.submit = submit;
  $scope.cancel = cancel;

  function activate() {
    TesoreriaLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      if ($state.is("app.denominaciontarjeta.new")) {
        activateNew();
      } else if ($state.is("app.denominaciontarjeta.edit")) {
        activateEdit();
      } else if ($state.is("app.denominaciontarjeta.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateNew() {
    $scope.title = $filter('translate')('NEW_DENOMINACION_TARJETA');
    $scope.denominaciontarjeta = {};
    $scope.denominaciontarjeta.activo = true;

  }

  function activateEdit() {
    $scope.title = $filter('translate')('EDIT_DENOMINACION_TARJETA');
    $scope.denominaciontarjeta = denominacionTarjetaPrepService;
    $scope.entidadId = $scope.denominaciontarjeta.id;
    $scope.entidad = "DenominacionTarjeta";
  }

  function activateView() {
    $scope.title = $filter('translate')('VIEW_DENOMINACION_TARJETA');
    $scope.denominaciontarjeta = denominacionTarjetaPrepService;
    $scope.entidadId = $scope.denominaciontarjeta.id;
    $scope.entidad = "DenominacionTarjeta";
    $scope.view = true;
  }

  function submit() {
    $scope.submited = true;
    if ($scope.DenominacionTarjetaForm.$valid) {
      formFactory.defaultNSFSubmit($scope.DenominacionTarjetaForm, DenominacionTarjetaFactory, $scope.denominaciontarjeta, errorHandler).then(function (response) {
        $location.path('/denominaciontarjeta');
      }, function (error) {
        console.log(error);
      });
    }

  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function (error) {
        msg += '\n\n' + error.message + '.'
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function cancel() {
    $location.path("/denominaciontarjeta");
  }

  $scope.resource = 'denominaciontarjeta'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

angular.module('qualita.tesoreria')
    .controller('DenominacionTarjetaIndexCtrl', ["$scope", "DenominacionTarjetaFactory", "filterFactory", "ReportTicketFactory", "$window", "TesoreriaLangFactory", "$filter", "$modal", "UtilFactory", "CsvFactory", "baseurl", function ($scope, DenominacionTarjetaFactory, filterFactory, ReportTicketFactory, $window, TesoreriaLangFactory, $filter, $modal, UtilFactory, CsvFactory, baseurl) {
        TesoreriaLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'codigo', 'descripcion', 'activo'];
            $scope.options = {
                'resource': 'denominaciontarjeta',
                'title': 'Denominaciones de Tarjetas',
                'factory': DenominacionTarjetaFactory,
                'view': 'BaseList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('ID'), visible: false  },
                    { 'data': 'codigo', 'title': 'Código' },
                    { 'data': 'descripcion', 'title': 'Descripción', 'class': 'dt-right' },
                    { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraMenuOptions':
                    [
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoDenominacionTarjeta', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("denominaciontarjeta", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        }
                    ]

            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if(search === 'No' && data === 'activo'){
                        search = 'false';
                    }
                    if(search === 'Sí' && data === 'activo'){
                        search = 'true';
                    }
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);

'use strict';

angular.module('qualita.tesoreria')
  .factory('DenominacionTarjetaFactory', ['$resource', 'baseurl', function ($resource, baseurl) {

    var DenominacionTarjeta = $resource(baseurl.getBaseUrl() + '/denominaciontarjeta/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params) {
        if (params) {
          params.view = "BaseList";
        } else {
          params = [];
          params.view = "BaseList";
        }
        return DenominacionTarjeta.query(params);
      },

      get: function (id, view) {
        if (typeof (view) === 'undefined') view = "base";
        return DenominacionTarjeta.get({ id: id, view: view });
      },

      create: function (attrs) {
        return new DenominacionTarjeta(attrs);
      },

      save: function (denominaciontarjeta) {
        return (denominaciontarjeta.id) ? denominaciontarjeta.$update() : denominaciontarjeta.$save();
      },

      remove: function (denominaciontarjeta) {
        return denominaciontarjeta.$remove();
      }
    };
  }]);

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.inventario', ['ngResource', 'ui.tree', 'ngFileUpload', 'qualita.productos'])
  .config(
    ['$stateProvider',
      function ($stateProvider) {

        $stateProvider
          .state('app.inventario', {
            url: '/inventario',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Inventarios'
            }
          })
          .state('app.inventario.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'InventarioIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.inventario',
              label: 'Inventarios',
              description: 'Listado de Inventarios',
              skip: true
            }
          })
          .state('app.inventario.proceso', {
            url: '/proceso/:codigoInventario',
            templateUrl: 'qualita-components/inventario/views/inventario/wizard.html',
            controller: 'InventarioProcesoCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.inventario',
              label: 'Proceso'
            },
            resolve: {
              inventarioPrepService: inventarioPrepService
            }
          })
          .state('app.inventario.proceso.apertura', {
            url: '/apertura',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              parent: 'app.inventario.proceso',
              label: 'Apertura Inventario'
            }
          })
          .state('app.inventario.proceso.apertura.list', {
            url: '',
            templateUrl: 'qualita-components/inventario/views/inventario/form.html',
            controller: 'InventarioFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.inventario.proceso.apertura',
              label: 'Apertura Inventario',
              skip: true
            }
          })
          .state('app.inventario.proceso.apertura.view', {
            url: '/:id',
            templateUrl: 'qualita-components/inventario/views/inventario/form.html',
            controller: 'InventarioFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.inventario.proceso.apertura',
              label: 'Ver Inventario'
            },
            resolve: {
              conteoPrepServicio: conteoPrepService
            }
          })
          .state('app.inventario.proceso.cierre', {
            url: '/cierre',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              parent: 'app.inventario.proceso',
              label: 'Cierre Inventario'
            }
          })
          .state('app.inventario.proceso.cierre.list', {
            url: '',
            templateUrl: 'qualita-components/inventario/views/cierre/cierre.html',
            controller: 'CierreInventarioCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.inventario.proceso.cierre',
              label: 'Cierre Inventario',
              skip: true
            }
          })

          .state('app.inventario.proceso.conteo', {
            url: '/conteo',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              parent: 'app.inventario.proceso',
              label: 'Conteos'
            }
          })
          .state('app.inventario.proceso.conteo.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'ConteoIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.inventario.proceso.conteo',
              label: 'Conteos',
              skip: true
            }
          })
          .state('app.inventario.proceso.conteo.new', {
            url: '/new',
            templateUrl: 'qualita-components/inventario/views/conteo/form.html',
            controller: 'ConteoFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.inventario.proceso.conteo',
              label: 'Nuevo Conteo'
            },
            resolve: {
              conteoPrepService: function () { return; }
            }
          })
          .state('app.inventario.proceso.conteo.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/inventario/views/conteo/form.html',
            controller: 'ConteoFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.inventario.proceso.conteo',
              label: 'Editar Conteo'
            },
            resolve: {
              conteoPrepService: conteoPrepService
            }
          })
          .state('app.inventario.proceso.conteo.view', {
            url: '/:id',
            templateUrl: 'qualita-components/inventario/views/conteo/form.html',
            controller: 'ConteoFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.inventario.proceso.conteo',
              label: 'Ver Conteo'
            },
            resolve: {
              conteoPrepService: conteoPrepService
            }
          })
          .state('app.conteos', {
            url: '/conteo',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Conteos'
            }
          })
          .state('app.conteos.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'ConteoIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.conteos',
              label: 'Conteos',
              skip: true
            }
          })
          .state('app.conteos.new', {
            url: '/new',
            templateUrl: 'qualita-components/inventario/views/conteo/form.html',
            controller: 'ConteoFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.conteos',
              label: 'Nuevo Conteo'
            },
            resolve: {
              conteoPrepService: function () { return; }
            }
          })
          .state('app.conteos.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/inventario/views/conteo/form.html',
            controller: 'ConteoFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.conteos',
              label: 'Editar Conteo'
            },
            resolve: {
              conteoPrepService: conteoPrepService
            }
          })
          .state('app.conteos.view', {
            url: '/:id',
            templateUrl: 'qualita-components/inventario/views/conteo/form.html',
            controller: 'ConteoFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.conteos',
              label: 'Ver Conteo'
            },
            resolve: {
              conteoPrepService: conteoPrepService
            }
          })
          .state('app.contadores', {
            url: '/contador',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Contadores'
            }
          })
          .state('app.contadores.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'ContadorIndexCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.contadores',
              label: 'Contadores',
              skip: true
            }
          })
          .state('app.contadores.new', {
            url: '/new',
            templateUrl: 'qualita-components/inventario/views/contador/form.html',
            controller: 'ContadorFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.contadores',
              label: 'Nuevo Contador'
            },
            resolve: {
              contadorPrepService: function () { return; }
            }
          })
          .state('app.contadores.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/inventario/views/contador/form.html',
            controller: 'ContadorFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.contadores',
              label: 'Editar Contador'
            },
            resolve: {
              contadorPrepService: contadorPrepService
            }
          })
          .state('app.contadores.view', {
            url: '/:id',
            templateUrl: 'qualita-components/inventario/views/contador/form.html',
            controller: 'ContadorFormCtrl',
            controllerAs: 'vm',
            ncyBreadcrumb: {
              parent: 'app.contadores',
              label: 'Ver Contador'
            },
            resolve: {
              contadorPrepService: contadorPrepService
            }
          });
      }]);

function inventarioCierrePrepService() {
  return InventarioFactory.get($stateParams.id, 'detail').$promise;
}

function inventarioPrepService(InventarioFactory, $stateParams) {
  var promise = InventarioFactory.get($stateParams.codigoInventario, "InventarioForm").$promise;
  promise.then(function (inv) {
    InventarioFactory.setIdInventario(inv.id);
    InventarioFactory.setCodigoInventario(inv.codigo);
    InventarioFactory.setInventario(inv);
  });
  return promise;
}
inventarioPrepService.$inject = ["InventarioFactory", "$stateParams"];

function conteoPrepService(ConteoFactory, $stateParams) {
  return ConteoFactory.get($stateParams.id, 'ConteoForm').$promise;
}
conteoPrepService.$inject = ["ConteoFactory", "$stateParams"];

function cierrePrepService(CierreInventarioFactory, $stateParams) {
  return CierreInventarioFactory.get($stateParams.id, 'detail').$promise;
}

function contadorPrepService(ContadorFactory, $stateParams) {
  return ContadorFactory.get($stateParams.id, 'BaseForm').$promise;
}
contadorPrepService.$inject = ["ContadorFactory", "$stateParams"];
'use strict';


angular.module('qualita.inventario')
  .controller('InventarioIndexCtrl', InventarioIndexCtrl);

InventarioIndexCtrl.$inject = ["AuthorizationService", "InventarioFactory", "TiposFactory", "PeriodoAdministrativoFactory",
  "filterFactory", "$log", "$modal", "$scope", "notify", "$state", "InventarioLangFactory",
  "ParametrosFactory", "TiposTipoFactory", "$filter", "ReportTicketFactory", "$window", "UtilFactory", "CsvFactory",
  "baseurl"];

function InventarioIndexCtrl(AuthorizationService, InventarioFactory, TiposFactory, PeriodoAdministrativoFactory,
  filterFactory, $log, $modal, $scope, notify, $state, InventarioLangFactory,
  ParametrosFactory, TiposTipoFactory, $filter, ReportTicketFactory, $window, UtilFactory, CsvFactory, baseurl) {
  var vm = this;
  vm.addNewInventario = addNewInventario;
  vm.sendNewInventario = sendNewInventario;

  var defaultColumnOrder = ['id', 'estado.descripcion', 'deposito.descripcion', 'fecha', 'clase.descripcion'];

  InventarioLangFactory.getTranslations().then(function (translations) {
    $scope.translations = translations;
    vm.options = {
      'resource': 'inventarios',
      'title': $filter('translate')('INVENTARIO'),
      'factory': InventarioFactory,
      'view': 'InventarioList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('ERROR_INVENTARIO_DELETE'),
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('CODE'), 'renderWith': 'emptyRender' },
        { 'data': 'deposito.descripcion', 'title': $filter('translate')('ALMACEN'), 'renderWith': 'emptyRender' },
        {
          'data': 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'renderWith': 'emptyRender'
          , 'type': 'combo', 'filterUrl': 'inventarios/estados'
        },
        { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
        {
          data: "clase.descripcion",
          title: $filter("translate")("KIND"),
          renderWith: "emptyRender",
          class: "dt-center", 'type': 'combo', 'filterUrl': 'tipos/filtro/clase_inventario'
        }
      ],
      'hasOptions': true,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'hideAddMenu': true,
      'hideEditMenu': true,
      'hideRemoveMenu': true,
      'hideViewMenu': true,
      'extraMenuOptions':
        [
          {
            'title': $filter('translate')('NEW_INVENTARIO'),
            'icon': 'glyphicon glyphicon-plus',
            'showCondition': function () {
              var permission = AuthorizationService.hasPermission('create_inventarios');
              return permission;
            },
            'action': function () { vm.addNewInventario() }
          },
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoInventario', filters, vm.options.tableAjaxParams,
                vm.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

              CsvFactory.csv("inventarios", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
        ],
      'extraRowOptions': [
        {
          templateToRender: "<button class='btn btn-success' style='margin-right: 5px;'   title=" + $filter('translate')('EDIT') + " ng-click='editarProceso(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          customAttribute: 'estado',
          conditionName: 'canEditar',
          conditionDef: function (estado) {
            return (estado.codigo != "inventario_cerrado" && estado.codigo != "inventario_cancelado");
          },
          functionName: "editarProceso",
          functionDef: function (itemId) {
            //console.log("Editar proceso: ");
            //console.log(itemId);
            redirectInventario(itemId);
          }
        },
        {
          templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title=" + $filter('translate')('VIEW') + " ' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
          conditionName: 'canView',
          conditionDef: function () {
            return AuthorizationService.hasPermission('index_inventarios');
          },
          functionName: "view",
          functionDef: function (itemId) {
            redirectInventario2(itemId);
          }
        },{
          templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('CANCEL') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
          customAttribute: 'estado',
          conditionName: "canBorrar",
          conditionDef: function (estado) {
              if (estado.codigo === "inventario_cerrado" || estado.codigo === "inventario_cancelado") {
                  return false;
              }
              return true;
          },
          functionName: "borrar",
          functionDef: function (itemId) {
              $scope.selectedItemId = itemId;
              $scope.tituloModal = $filter('translate')('CONFIRMACION');
              $scope.mensajeModal = $filter('translate')('CANCEL_INVENTARIO');
              var modalInstance = $modal.open({
                  templateUrl: 'views/directives/confirmation-modal.html',
                  scope: $scope
              });

              $scope.cancel = function () {
                  modalInstance.dismiss('cancel');
              };

              $scope.ok = function (itemId) {
                  var model = InventarioFactory.create({id: itemId});
                  InventarioFactory.remove(model).then(function () {
                      modalInstance.close(itemId);
                      //InventarioFactory.generarConteo($scope.inventario.id);
                      $state.go($state.current, {}, {reload: true});
                  }, function (error) {
                      modalInstance.dismiss('cancel');
                      $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                      $scope.mensajeModal = (error != null && error.data.length > 0 ) ? error.data[0].message :  $filter('translate')('CONTEO_DELETE_ERROR') ;

                      var modalInstance2 = $modal.open({
                          templateUrl: 'views/directives/information-modal.html',
                          scope: $scope
                      });
                      $scope.cancel = function () {
                          modalInstance2.dismiss('cancel');
                      };
                  });
              }
          }
      },
      ]
    };
  });

  function cancelar(itemId) {
    InventarioFactory.remove(itemId).then(
      function (response) {
        modalInstance.close();
        $state.go($state.current, {}, { reload: true });
      },
      function (error) {
        console.log(error);
        notify({ message: error, classes: 'alert-danger', position: 'right' });
      }
    );
    /*
    InventarioFactory.remove(itemId).then(function(){
      $state.go($state.current, {}, {reload: true});
    }, function(error){
      notify({ message: error, classes: 'alert-danger', position: 'right'});
    });*/
    //InventarioFactory.remove(itemId);
    //$state.go($state.current, {}, { reload: true });

  }

  function addNewInventario() {
    $scope.tituloModal = $filter('translate')('NEW_INVENTARIO');
    console.log("addNewInventario");

    var flagPeriodo = false;

    var params = {};
    params.search = filterFactory
      .and([{
        path: "estado.codigo",
        equals: "per_adm_abierto"
      },
      {
        path: "fechaDesde",
        equalOrBefore: moment(new Date()).format("DD/MM/YYYY")
      },
      {
        path: "fechaHasta",
        equalOrAfter: moment(new Date()).format("DD/MM/YYYY")
      }
      ])
      .value();

    PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {

      if (responsePeriodo && responsePeriodo.length > 0) {
        var modalInstance = $modal.open({
          templateUrl: 'views/directives/inventario-auto-modal.html',
          scope: $scope
        });

        $scope.cancel = function () {
          modalInstance.dismiss('cancel');
        };

        $scope.ok = function () {
          sendNewInventario().then(function (data) {
            modalInstance.close();
            InventarioFactory.setIdInventario(data.id);
            InventarioFactory.setCodigoInventario(data.codigo);
            InventarioFactory.setInventario(data, 4);
            redirectInventario(data.id);
          });

        };
      } else {
        var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      }
    })

  }

  function sendNewInventario() {
    return TiposFactory.estadosInventario().then(function (response) {
      var estados = response.data;

      if (!estados) {
        console.log("No se pudo guardar compra debido a que no se pudo traer los estados");
        return;
      }

      var estadoActivo = _.find(estados, function (estado) {
        return estado.codigo === "inventario_creado";
      });
      if (!estadoActivo) {
        return;
      }
      var inventario =
      {
        estado: estadoActivo.id,
        //activo: true,
        //tipo: tipoCompra
      };
      inventario.fecha = new Date();
      var inv = InventarioFactory.create(inventario);
      return InventarioFactory.save(inv);

    }, function (error) {
      console.log("Error a traer los estados de inventario: ");
      console.log(error);
    }).then(function (data) {
      console.log("Se ha guardado el inventario.");
      console.log(data);
      return data;
    }, function (error) {
      console.log("Error al guardar el inventario.");
      console.log(error);
    });
  }

  function redirectInventario(itemId) {
    InventarioFactory.get(itemId, "InventarioForm").$promise.then(function (inventario) {
      localStorage.setItem('INVENTARIO_ABIERTO', JSON.stringify(inventario));
      InventarioFactory.setIdInventario(inventario.id);
      InventarioFactory.setCodigoInventario(inventario.codigo);

      console.log("inventario 5 ", inventario);

      InventarioFactory.setInventario(inventario, 5);
      switch (inventario.estado.codigo) {
        case "inventario_creado":
          $state.go('app.inventario.proceso.apertura.list', { codigoInventario: itemId });
          break;
        case "inventario_abierto":
          $state.go('app.inventario.proceso.conteo.list', { codigoInventario: itemId });
          break;
        case "inventario_proceso":
          $state.go('app.inventario.proceso.conteo.list', { codigoInventario: itemId });
          break;
        case "inventario_cerrado":
          $state.go('app.inventario.proceso.cierre.list', { codigoInventario: itemId });
          break;
        default:
          $state.go('app.inventario.proceso.cierre.list', { codigoInventario: itemId });
          break;
      }
    });
  }

  function redirectInventario2(itemId) {
    InventarioFactory.get(itemId).$promise.then(function (inventario) {
      localStorage.setItem('INVENTARIO_ABIERTO', JSON.stringify(inventario));
      InventarioFactory.setIdInventario(inventario.id);
      InventarioFactory.setCodigoInventario(inventario.codigo);
      InventarioFactory.setInventario(inventario, 6);
      $state.go('app.inventario.proceso.apertura.list', { codigoInventario: itemId });
      /*switch(inventario.estado.codigo) {
        case "inventario_creado":
          $state.go('app.inventario.proceso.apertura.list', {codigoInventario: itemId});
          break;
        case "inventario_abierto":
          $state.go('app.inventario.proceso.conteo.list', {codigoInventario: itemId});
          break;
        case "inventario_proceso":
          $state.go('app.inventario.proceso.conteo.list', {codigoInventario: itemId});
          break;
        case "inventario_cerrado":
          $state.go('app.inventario.proceso.cierre.list', {codigoInventario: itemId});
          break;
        default:
          $state.go('app.inventario.proceso.cierre.list', {codigoInventario: itemId});
          break;
      }*/
    });
  }

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      search = search.split("~");
      if (search.length > 1) {
        /* El parámetro es un rago */

        var isDate = search[0].split("/");

        if (isDate.length > 1) {
          /* Es un rago de fechas */

          filtersArr.push({ path: data, equalOrAfter: search[0] })
          filtersArr.push({ path: data, equalOrBefore: search[1] })
        } else {
          /* El un rago numérco */

          filtersArr.push({ path: data, greaterOrEqual: search[0] })
          filtersArr.push({ path: data, lessOrEqual: search[1] })
        }
      } else {
        /* El parametro no es un rago */

        filtersArr.push({ path: data, like: search[0] })
      }
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}

'use strict';
angular.module('qualita.inventario')
  .controller('InventarioFormCtrl', InventarioFormCtrl);

InventarioFormCtrl.$inject = ["InventarioFactory", "AlmacenFactory",
"TiposFactory", "filterFactory", "$scope", "notify","$state", "$rootScope", "InventarioLangFactory",
"LineasProductoFactory", "ProveedoresFactory","WatchLocacionChangeFactory","ContadorFactory",
"ModelTrimmer","ProductosLangFactory", "$filter"];

function InventarioFormCtrl(InventarioFactory, AlmacenFactory,
  TiposFactory, filterFactory, $scope, notify, $state, $rootScope, InventarioLangFactory,
  LineasProductoFactory, ProveedoresFactory,WatchLocacionChangeFactory,ContadorFactory,
  ModelTrimmer,ProductosLangFactory, $filter) {

  var vm = this;
  vm.agregarDetalle=agregarDetalle;
  vm.submit = submit;
  vm.cancel = cancel;
  vm.removeItemFromArray = removeItemFromArray;
  vm.curDate = new Date();
  activate();
  vm.changeFamilias = changeFamilias;
  vm.changeProveedores = changeProveedores;
  vm.changeAlmacen = changeAlmacen;
  vm.changeClase = changeClase;
  vm.cambiarFiltro = cambiarFiltro;
  $rootScope.isProcessing = false;

  function changeClase(){
    var clase = vm.inventario.clase;
    var filterClase = {
      search: filterFactory.single({
        path: "clase.codigo",
        equals: clase.codigo
      }).value()
    };

    vm.familias = LineasProductoFactory.all(filterClase);
    vm.familiasFilter=undefined;
  }

  function activate() {
    vm.familiasFilter=[];
    vm.proveedoresFilter=[];
    vm.options = {};
    var staticFilter = {};


    WatchLocacionChangeFactory.killAllWatchers();

    InventarioLangFactory.getTranslations().then(function(translations) {
      $scope.translations = translations;
      vm.title = $filter('translate')('APERTURA');
      vm.curDate = new Date();
      vm.inventario = InventarioFactory.getInventario();

      if(vm.inventario !== null && vm.inventario !== undefined && vm.inventario.clase !== undefined
        && vm.inventario.clase !== null){
        var clase = vm.inventario.clase;
        var filterClase = {
          search: filterFactory.single({
            path: "clase.codigo",
            equals: clase.codigo
          }).value()
        };
        vm.familias = LineasProductoFactory.all(filterClase);
      }

      /* por defecto el resource del datatable del detalle es la tabla de producto */
      var resoruce = "productos/newdatatable";
      var factory = "ProductosFactory"
      var view = "ProductoList";
      var prefix = "" // esta variable se usa porque son diferntes resources (producto e inventarioDetalle)

      generarDetalleInventarioDT(prefix, resoruce, factory, view);

      if(!vm.inventario.fecha) {
        vm.inventario.fecha=vm.curDate;
        TiposFactory.estadosInventario().then(function(response) {
          vm.estados = response;
          vm.inventario.productos = [];
        });
      }else {
        InventarioFactory.get(vm.inventario.id, "InventarioForm").$promise.then(function(inv) {
          vm.inventario = inv;
          vm.inventario.fecha = new Date(vm.inventario.fecha);
          vm.proveedoresFilter=vm.inventario.proveedor;
          vm.familiasFilter=vm.inventario.lineaProducto;

          TiposFactory.clasesInventario().then(function (data) {
              vm.clases=data.data;
              if(vm.inventario.clase == null){
                vm.inventario.clase = vm.clases[1];
                changeClase();
              }
          });

          if (vm.inventario.deposito) {
            vm.options.staticFilter = createMixFilter();
          }

          if (vm.inventario.estado.codigo != 'inventario_creado') {

          //if(vm.inventario.inventarioDetalle && vm.inventario.inventarioDetalle.length > 0){
            /* Si el inventario ya tiene productos asociados ya no se puede editar, entonces seteamos el modo view
            y ademas setamos el resource del datatable a inventariodetalle */

            vm.view = true;
            resoruce = "inventariodetalle/viewdetalleinv";
            factory = "InventarioDetalleFactory";
            prefix = "producto."

            /* cada vez que algun filtro se cambie esta variable pasa a false y no se muestra el detalle del inventario hasta que
            se vuelta a filtrar
            */
            vm.showDetail = true;

            /* Si el inventario ya tiene productos se filtra solo por el id del inventario */
            staticFilter.search = filterFactory
            .and([{
              path: "inventario.id",
              equals: vm.inventario.id
            }]).value();

          }

          generarDetalleInventarioDT(prefix, resoruce, factory, view);
        });
      }

      generarDetalleInventarioDT(prefix, resoruce, factory, view);

      if(vm.watcherAlmacen) { vm.watcherAlmacen(); } //hacemos unwatch de la expresion anterior

      WatchLocacionChangeFactory.executeWatching(function(watcherAlmacen) {
        vm.watcherAlmacen = watcherAlmacen;
        var filterSucursal = {
            search: filterFactory.and([
              {
                path: "sucursal.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
              },
              {
                path:"activo",
                equals:true
              }
            ]).value()
          };
        vm.almacenes = AlmacenFactory.all(filterSucursal);
      }, "locacion.id");


      if(vm.watcherProducto) { vm.watcherProducto(); } //hacemos unwatch de la expresion anterior

      WatchLocacionChangeFactory.executeWatching(function(watcherProducto) {
        vm.watcherProducto = watcherProducto;
        if (vm.inventario.deposito) {
          createMixFilter();
        }
      }, "locaciones.id");

      if(vm.inventario!==null && vm.inventario!==undefined &&
        vm.inventario.clase!==undefined && vm.inventario.clase!==null){

        var clase=vm.inventario.clase;
        var filterClase = {
          search: filterFactory.single({
            path: "clase.codigo",
            equals: clase.codigo
          }).value()
        };
        vm.familias = LineasProductoFactory.all(filterClase);
      }else{
        vm.familias = LineasProductoFactory.all();
      }
      vm.contadores=ContadorFactory.all();

      var paramsProv = {};
      paramsProv.search = filterFactory.single({
        path: 'activo',
        equals: true
      }).value();
      vm.proveedores = ProveedoresFactory.all(paramsProv);

    });
    vm.form = $scope.AperturaInventarioForm;

    function generarDetalleInventarioDT(prefix, resoruce, factory, view) {
      var defaultColumnOrder = [prefix + 'codigo', prefix + 'codigoBarrasPrincipal.codigoBarras', prefix + 'nombre',
      prefix + 'criterioControl.descripcion', prefix + 'claseProducto.descripcion', prefix + 'lineaProducto.nombre', prefix + 'marca.descripcion',
      prefix + 'unidadMedidaBase.descripcion', prefix + 'proveedorPrincipal.nombre'];
      ProductosLangFactory.getTranslations().then(function () {
        vm.options = {
          'resource': resoruce,
          'title': 'Productos',
          'factory': factory,
          'view': view,
          'defaultColumnOrder': defaultColumnOrder,
          'columns': [
            { 'data': prefix + 'codigo', 'title': $filter('translate')('INTERNAL_CODE') },
            { 'data': prefix + 'codigoBarrasPrincipal.codigoBarras', 'title': $filter('translate')('BAR_CODE'), 'render': renderEmptyData },
            { 'data': prefix + 'nombre', 'title': $filter('translate')('NOMBRE') },
            { 'data': prefix + 'criterioControl.descripcion', 'title': $filter('translate')('ADMINISTRATION_FORM'), 'render': renderEmptyData, visible: true },
            { 'data': prefix + 'claseProducto.descripcion', 'title': $filter('translate')('CLASS') },
            { 'data': prefix + 'estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/filtro/producto_estados', 'class': 'dt-center' },
            { 'data': prefix + 'lineaProducto.nombre', 'title': $filter('translate')('LINE') },
            { 'data': prefix + 'marca.descripcion', 'title': $filter('translate')('BRAND'), 'render': renderEmptyData },
            { 'data': prefix + 'unidadMedidaBase.descripcion', 'title': $filter('translate')('MESSURE_UNIT'), visible: true },
            { 'data': prefix + 'proveedorPrincipal.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX'), visible: true },
          ],
          'hasOptions': false,
          'hideAddMenu': true,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "asc",
          'failedDeleteError': 'No se pudo borrar el producto. Verifique si no hay operaciones asociadas.'
        };
        vm.options.staticFilter = staticFilter;
      });
    }
  }

  function submiInventario(inventario){
        return InventarioFactory.save(inventario);
    }

  function submit() {
    /* todo Verificar como enviar un filtro para que retorne paginado con un limit */
    var filtroCosto = {};
    filtroCosto.search = filterFactory
        .and([{
            path: 'almacen.id',
            equals: vm.inventario.deposito.id
        }]).value();

    vm.submited = true;
    if($scope.AperturaInventarioForm.$valid) {
      vm.inventario.proveedor=vm.proveedoresFilter;
      vm.inventario.lineaProducto=vm.familiasFilter;
      if(vm.inventario.ajustarNoContado===undefined) vm.inventario.ajustarNoContado=false;
      var trimedInventario=ModelTrimmer.trimDetails(vm.inventario);
      var inventario = InventarioFactory.create(trimedInventario);

      submiInventario(inventario).then(function (inventario) {
          vm.inventario=inventario;
          /*
          *Al retornar vuelve el valor en string de la base de datos, se vuelve a castear * del tipo date
          */
          vm.inventario.fecha = new Date(inventario.fecha);
          InventarioFactory.setIdInventario(vm.inventario.id);
          InventarioFactory.setCodigoInventario(vm.inventario.codigo);
          InventarioFactory.setInventario(vm.inventario);

          $scope.$emit("updateInventarioStates");
          var filter =createMixFilter2();
          InventarioFactory.aperturaInventario(vm.inventario,filter).then(function() {
            redirectInventario(vm.inventario.id);
          },function(err) {
            notify({ message: err.data[0].message , classes: 'alert-danger', position: 'right'});
            return;
          });
    }, function(error) {
          $rootScope.isProcessing = false;
          console.log("Ha ocurrido un error");
          console.log(error);
          var msg = error.data[0].message;
          notify({ message: msg, classes: 'alert-danger', position: 'right'});
      });
      vm.submited=false;
    }
  }

  /* cada vez que algun filtro se cambie esta variable pasa a false y no se muestra el detalle
  del inventario hasta que se vuelta a filtrar*/

  function changeAlmacen() {
    vm.showDetail = false;
  }

  function changeFamilias() {
    vm.showDetail = false;
  }

  function cambiarFiltro(){
    if(!vm.showDetail){
      vm.options.staticFilter = createMixFilter();
      vm.showDetail = true;
    }
  }

  function changeProveedores() {
    vm.showDetail = false;
  }

  function agregarDetalle() {
    if(vm.inventario.deposito!==null && vm.inventario.deposito!==undefined){
      vm.inventario.productos.push({});
      $timeout(function(){
        $scope.$broadcast('newItemAdded');
      }, 20);
    }else{
      var msg = "Debe seleccionar un almacen para seleccionar el producto.";
      notify({ message: msg, classes: 'alert-danger', position: 'right'});
    }

  }
  var renderEmptyData = function (data) {
    if (data == undefined)
      return "";
    else
      return data;
  };

  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function(item) {
      return item === elemento;
    });
    vm.inventario.totalLiquidacion = _.reduce(vm.inventario.productos, function(sum, value) { return sum + value.importe; }, 0);
  }

  function cancel() {
    $state.go('app.inventario.list');
  }

  function redirectInventario(itemId) {
    InventarioFactory.get(itemId).$promise.then(function(inventario) {

      InventarioFactory.setIdInventario(inventario.id);
      InventarioFactory.setCodigoInventario(inventario.codigo);
      InventarioFactory.setInventario(inventario);

      switch(inventario.estado.codigo) {
        case "inventario_creado":
          $state.go('app.inventario.proceso.apertura.list', {codigoInventario: itemId});
          break;
        case "inventario_abierto":
          $state.go('app.inventario.proceso.conteo.list', {codigoInventario: itemId});
          break;
        case "inventario_proceso":
        case "inventario_cerrado":
          $state.go('app.inventario.proceso.cierre.list', {codigoInventario: itemId});
          break;
        default:
          $state.go('app.inventario.proceso.cierre.list', {codigoInventario: itemId});
          break;
      }
    });
  }

  function createMixFilter(){
    var staticFilter={};
    var arrayAnds=[];
    var nodeControlStock=createFilterNode("controlStock",'true');
    var nodeEstado=createFilterNode("estado.codigo",'activo');

    if (vm.inventario.deposito ==null || vm.inventario.deposito ==undefined) {
      $rootScope.isProcessing = false;
      var msg = "Seleccione un almacen";
      notify({ message: msg, classes: 'alert-danger', position: 'right'});
      return;
    }

    var nodeAlmacen=createFilterNode("almacenes.almacen.id", vm.inventario.deposito.id);
    if (!vm.view) {
      if((vm.proveedoresFilter===undefined || vm.proveedoresFilter.length===0)&&(vm.familiasFilter===undefined || vm.familiasFilter.length===0)){
        var nodeLocacion=createFilterNode("locaciones.id",$rootScope.AuthParams.locacionSeleccionada.id);
        arrayAnds.push(createAndFilterFromList([nodeLocacion,nodeControlStock, nodeEstado, nodeAlmacen]));
      }else if(vm.proveedoresFilter===undefined || vm.proveedoresFilter.length===0){
        for(var i=0;i<vm.familiasFilter.length;i++){
          var node1=createFilterNode("lineaProducto.id",vm.familiasFilter[i].id);
          var nodeLocacion=createFilterNode("locaciones.id",$rootScope.AuthParams.locacionSeleccionada.id);
          arrayAnds.push(createAndFilterFromList([node1,nodeLocacion,nodeControlStock, nodeEstado, nodeAlmacen]));
        }
      }else if(vm.familiasFilter===undefined || vm.familiasFilter.length===0){
        for(var i=0;i<vm.proveedoresFilter.length;i++){
          var node1=createFilterNode("proveedores.id",vm.proveedoresFilter[i].id);
          var nodeLocacion=createFilterNode("locaciones.id",$rootScope.AuthParams.locacionSeleccionada.id);
          arrayAnds.push(createAndFilterFromList([node1,nodeLocacion,nodeControlStock, nodeEstado, nodeAlmacen]));
        }
      }else{
        for(var i=0;i<vm.familiasFilter.length;i++){
          var node1=createFilterNode("lineaProducto.id",vm.familiasFilter[i].id);
          for(var j=0;j<vm.proveedoresFilter.length;j++){
            var node2=createFilterNode("proveedores.id",vm.proveedoresFilter[j].id);
            var nodeLocacion=createFilterNode("locaciones.id",$rootScope.AuthParams.locacionSeleccionada.id);
            arrayAnds.push(createAndFilterFromList([node1,node2,nodeLocacion,nodeControlStock, nodeEstado, nodeAlmacen]));
          }
        }
      }
    }

    staticFilter.search=createOrFilterFromList(arrayAnds);
    return staticFilter;
  }

  function createMixFilter2(){
    var arrayAnds=[];
    var nodeEstado=createFilterNode("estado.codigo",'activo');
    var nodeControlStock=createFilterNode("controlStock",'true');
    var nodeAlmacen=createFilterNode("almacenes.almacen.id", vm.inventario.deposito.id);

    if((vm.proveedoresFilter===undefined || vm.proveedoresFilter.length===0) &&
      (vm.familiasFilter===undefined || vm.familiasFilter.length===0)){
      var nodeLocacion=createFilterNode("locaciones.id",$rootScope.AuthParams.locacionSeleccionada.id);
      arrayAnds.push(createAndFilterFromList([nodeLocacion, nodeControlStock, nodeEstado, nodeAlmacen]));
    }else if((vm.proveedoresFilter===undefined|| vm.proveedoresFilter.length===0)){
      for(var i=0;i<vm.familiasFilter.length;i++){

        var node1=createFilterNode("lineaProducto.id",vm.familiasFilter[i].id);
        var nodeLocacion=createFilterNode("locaciones.id",$rootScope.AuthParams.locacionSeleccionada.id);

        var arrayOfNodes=[node1, nodeLocacion, nodeControlStock, nodeEstado];

        filters=vm.options.getFilters()
        _.each(filters, function (search, data) {
          if(search!="")
            arrayOfNodes.push({path: data, like: search})
        });

        arrayAnds.push(createAndFilterFromList([node1, nodeLocacion, nodeControlStock, nodeEstado, nodeAlmacen]));
      }
    }else if(vm.familiasFilter===undefined|| vm.familiasFilter.length===0){

      for(var i=0;i<vm.proveedoresFilter.length;i++){
        var node1=createFilterNode("proveedores.id",vm.proveedoresFilter[i].id);
        var nodeLocacion=createFilterNode("locaciones.id",$rootScope.AuthParams.locacionSeleccionada.id);

        var arrayOfNodes=[node1,nodeLocacion, nodeControlStock, nodeEstado, nodeAlmacen];

        filters=vm.options.getFilters()
        _.each(filters, function (search, data) {
          if(search!="")
            arrayOfNodes.push({path: data, like: search})
        });
        arrayAnds.push(createAndFilterFromList([node1, nodeLocacion, nodeControlStock, nodeEstado, nodeAlmacen]));
      }
    }else{
      for(var i=0;i<vm.familiasFilter.length;i++){

        var node1=createFilterNode("lineaProducto.id",vm.familiasFilter[i].id);
        for(var j=0;j<vm.proveedoresFilter.length;j++){
          var node2=createFilterNode("proveedores.id",vm.proveedoresFilter[j].id);
          var nodeLocacion=createFilterNode("locaciones.id",$rootScope.AuthParams.locacionSeleccionada.id);

          var arrayOfNodes=[node1,node2,nodeLocacion, nodeControlStock, nodeEstado, nodeAlmacen];

          var filters=vm.options.getFilters()
          _.each(filters, function (search, data) {
            if(search!="")
             arrayOfNodes.push({path: data, like: search})
          });

          arrayAnds.push(createAndFilterFromList(arrayOfNodes));
        }
      }
    }
    return createOrFilterFromList(arrayAnds);
  }

  function createFilterNode(field, value){
    if (value == 'true' || value == 'false') {
      return {
        path: field,
        like: value
      };
    }
    return {
        path: field,
        equals: value
      };
  }
  function createAndFilterFromList(listOfNodes){
  return filterFactory.and(listOfNodes).value();
  }
  function createOrFilterFromList(listOfAndNodes){
    return filterFactory.or(listOfAndNodes).value();
  }
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.inventario')
    .controller('CierreInventarioCtrl', CierreInventarioCtrl);

CierreInventarioCtrl.$inject = ['$scope','$state', 'notify', 'InventarioLangFactory', 'filterFactory',
    'InventarioFactory', 'WatchLocacionChangeFactory', '$filter', 'AuthorizationService', '$window', 'baseurl', '$modal'];

function CierreInventarioCtrl($scope, $state, notify, InventarioLangFactory, filterFactory,
    InventarioFactory, WatchLocacionChangeFactory, $filter, AuthorizationService, $window, baseurl, $modal) {

    var vm = this;
    vm.cancel = cancel;
    vm.submit = submit;
    vm.selectAll = selectAll;
    vm.downloadCSV = downloadCSV;
    vm.submitQuestion = submitQuestion;
    vm.generarResumen = generarResumen;

    activate();

    vm.uiBlockuiConfig = {
      'bloquear': false,
      'message': 'Procesando cierre ...'
    };
    function activate() {
        if(!AuthorizationService.hasPermission('index_cierreinventarios')){
            $state.go('app.main');
        }
        vm.checked = false;
        vm.permisoAjuste = AuthorizationService.hasPermission('ajustar_productos_no_contados');
        WatchLocacionChangeFactory.killAllWatchers();
        vm.title = $filter('translate')('CIERRE');
        vm.curDate = new Date();
        vm.inventario = InventarioFactory.getInventario();

        InventarioFactory.recuperarInventarioCierre(vm.inventario.id).success(function (data) {
            vm.inventario = data;
            vm.inventario.fecha = moment(new Date(vm.inventario.fecha)).format("DD/MM/YYYY");
            if (vm.inventario.fechaCierre) {
                vm.inventario.fechaCierre = moment(new Date(vm.inventario.fechaCierre)).format("DD/MM/YYYY");
            }

            if (vm.inventario.estado.codigo === "inventario_cerrado") {
                vm.view = true;
            }
        });

        InventarioLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            $scope.verTabla();
        });
    }

    $scope.verTabla = function () {
        var defaultColumnOrder = [
            'producto.codigo',
            'producto.codigoBarrasPrincipal.codigoBarras',
            'producto.nombre',
            'grupo.numero',
            'producto.unidadMedidaBase.descripcion',
            'saldoReal',
            'conteo',
            'montoAjuste'
        ]

        vm.optionsAjuste = {
            'resource': 'productos/viewDataInventario/' + vm.inventario.id,
            'title': 'Resumen de inventario',
            'factory': InventarioFactory,
            'defaultColumnOrder': defaultColumnOrder,
            'columns': [
                {'data': 'producto.codigo', 'title': 'Código Producto', visible: true},
                {'data': 'producto.codigoBarrasPrincipal.codigoBarras', 'title': $filter('translate')('CODIGO_BARRAS_PRODUCTO')},
                {'data': 'producto.nombre', 'title': $filter('translate')('NOMBRE_PRODUCTO')},
                {'data': 'grupo.numero', 'title': $filter('translate')('GRUPO_PRODUCTO'), renderWith: 'emptyRender'},
                {'data': 'producto.unidadMedidaBase.descripcion', 'title': $filter('translate')('UNIDAD_MEDIDA')},
                {
                    'data': 'saldoReal',
                    'title': $filter('translate')('EXISTENCIA_LOGICA'),
                    renderWith: renderDecimal
                },
                {
                    'data': 'conteo', 'title': $filter('translate')('CONTEO'),
                    renderWith: renderDecimal
                },
                {
                    'data': 'montoAjuste',
                    'title': $filter('translate')('AJUSTES_REALIZAR'),
                    renderWith: renderDecimal
                }
            ],
            'hasOptions': false,
            'hideAddMenu': true,
            'defaultOrderColumn': 0,
            'defaultOrderDir': "desc"
        };
        vm.optionsAjuste.staticFilter = createFilter(vm.inventario);
        formSelectionsArray();
    }

    var formSelectionsArray = function() {
            _.forEach(vm.inventario.productosStock, function(op) {
                if (op.id) {
                    vm.options.selection[op] = true;
                } else {
                    vm.options.selection[op] = true;
                }
            });
    };

    var renderDecimal = function (data) {
        if (data != undefined) {
            return montoRender( (parseFloat(data).toFixed(2)) );
        } else {
            return "";
        }
    }

    var montoRender = function (data) {
        if (data) {
            var moneda = "";
            return (
                moneda +
                data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else
            return "0";
    };

    function createFilter(inventario) {
        var staticFilter = {};

        var staticFilter = {
            search: filterFactory.single({
                path: "inventario.id",
                equals: inventario.id
            }).value()
        };

        return staticFilter;
    }

    function submitQuestion() {
        $scope.tituloModal = $filter("translate")("CERRAR_INVENTARIO_TITLE");
        $scope.mensajeModal = $filter("translate")("CERRAR_INVENTARIO_MSG");
        $scope.si_no = true;
        var modalInstance = $modal.open({
            template: '<div class="modal-header">' +
                '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                '</div>' +
                '<div class="modal-body">{{::mensajeModal}}' +
                '</div>' +
                '<div class="modal-footer">' +
                '<button class="btn btn-primary" ng-click="ok(selectedItemId);">' + $filter('translate')('OK') + '</button>' +
                '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                '</div>',
            scope: $scope
        });

        $scope.cancel = function () {
            modalInstance.dismiss("cancel");
        };

        $scope.ok = function () {
            modalInstance.close();
            submit();
        };
    }

    function submit() {
        if (vm.inventario.conteos.length === 0) {
            var msg = $filter('translate')('INVENTARIO_SIN_CONTEO');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            return;
        } else {

            vm.uiBlockuiConfig.bloquear = true;
            InventarioFactory.cerrar(vm.inventario.id).then(function (data) {
                vm.uiBlockuiConfig.bloquear = false;

                var modalInstance2 = $modal.open({
                    templateUrl: "views/directives/notification-modal.html",
                    scope: $scope
                });

                $scope.mensajeModal = "Proceso finalizado con éxito";
                $scope.ok = function() {
                    modalInstance2.close();
                    $state.go('app.inventario.list');
                }

            }, function (error) {
                console.log("error ", error);

                console.log("error ", error);

                var mensaje = "";
                _.forEach(error.data, function (err) {
                    mensaje = mensaje + err.message + " ";
                });

                // se deberia de capturar el error y mostrar

                var modalInstance3 = $modal.open({
                    templateUrl: "views/directives/notification-error-modal.html",
                    scope: $scope
                });
                $scope.mensajeModal = "ERROR! No se pudo realizar el cierre de inventario: " + mensaje;

                $scope.ok = function() {
                    modalInstance3.close();
                }
            });
        }
    }

    function downloadCSV() {
        InventarioFactory.getInventarioCSV(vm.inventario.id).then(function (response) {
            var fileName = response.data.fileName;
            console.log("response FileName", response);
            $window.open(baseurl.getPublicBaseUrl() + fileName);
        });
    }


    function generarResumen() {

        $scope.tituloModal = "Generar resumen de conteo";
        $scope.mensajeModal = "¿Esta seguro de que desea generar el resumen de conteo? Este proceso puede tomar mucho tiempo" ;
        $scope.si_no = true;
        var modalInstance = $modal.open({
            template: '<div class="modal-header">' +
                '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                '</div>' +
                '<div class="modal-body">{{::mensajeModal}}' +
                '</div>' +
                '<div class="modal-footer">' +
                '<button class="btn btn-primary" ng-click="ok(selectedItemId);">' + $filter('translate')('OK') + '</button>' +
                '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                '</div>',
            scope: $scope
        });


        $scope.cancel = function () {
            modalInstance.dismiss("cancel");
        };

        $scope.ok = function () {
            modalInstance.close();

            $scope.mensajeModal = "Procesando ...";
            var modalInstance1 = $modal.open({
                templateUrl: "views/directives/information-modal.html",
                scope: $scope
            });

            InventarioFactory.generarResumen(vm.inventario.id).then(function (response) {
                modalInstance1.close();

                var modalInstance3 = $modal.open({
                    templateUrl: "views/directives/notification-modal-refresh.html",
                    scope: $scope
                });

                $scope.mensajeModal = "Proceso finalizado con éxito";
                $scope.ok = function() {
                    modalInstance3.close();
                }

               // location.reload();
            },  function (error) {
                modalInstance1.close();

                console.log("error ", error);
                // se deberia de capturar el error y mostrar

                var modalInstance2 = $modal.open({
                    templateUrl: "views/directives/notification-error-modal.html",
                    scope: $scope
                });

                var mensaje = "";
                _.forEach(error.data, function (err) {
                    mensaje = mensaje + err.message + " ";
                });

                $scope.mensajeModal = "ERROR! No se pudo realizar el resumen de conteo: " + mensaje;
                $scope.ok = function() {
                    modalInstance2.close();
                }
            });
        };
    }

    function cancel() {
        $state.go('app.inventario.list');
    }

    function selectAll() {
        console.log("selectAll");
        _.forEach(vm.inventario.productosStock, function (registro) {
            registro.ajustar = vm.checked;
        });
    }

    vm.resource = 'cierre'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.inventario')
  .controller('CierreFormCtrl', CierreFormCtrl);

CierreFormCtrl.$inject = ['$rootScope', '$scope', 'ConteoFactory', '$location', '$state',
/*'cierrePrepService',*/ 'notify', 'formFactory', 'InventarioLangFactory', 'TiposFactory','filterFactory','$timeout',
'ProductosFactory','InventarioFactory','ContadorFactory','CierreInventarioFactory', '$filter'];

function CierreFormCtrl($rootScope, $scope, ConteoFactory, $location, $state,
/*cierrePrepService,*/ notify, formFactory, InventarioLangFactory, TiposFactory,filterFactory,$timeout,
ProductosFactory,InventarioFactory,ContadorFactory,CierreInventarioFactory, $filter) {

  var vm = this;
  vm.agregar = agregar;
  vm.cancel = cancel;
  vm.removeItemFromArray = removeItemFromArray;
  vm.submit = submit;
  vm.selectAll=selectAll;

  activate();


  function activate() {
    vm.checked=true;
    InventarioLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      vm.productos=ProductosFactory.allForSelect();
      vm.inventarios=InventarioFactory.all();
      vm.contadores=ContadorFactory.all();
      if($state.is("app.cierreinventarios.new")) {
        activateNew();
      }else if($state.is("app.cierreinventarios.edit")) {
        activateEdit();
      } else if($state.is("app.cierreinventarios.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      vm.submited = false;
    });
  }

  function agregar() {
   //console.log(vm.conteo);
   vm.conteo.conteoDetalle.push({});
    $timeout(function(){
      $scope.$broadcast('newItemAdded');
    }, 20);
  }

  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function(item) {
      return item === elemento;
    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NEW_CIERRE');
    vm.cierre = {};
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_CIERRE');
    //vm.cierre = cierrePrepService;
    $scope.entidad = "CierreInventario";
    $scope.entidadId = vm.cierre.id;
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_CIERRE');
    //vm.cierre = cierrePrepService;
    $scope.entidad = "CierreInventario";
    $scope.entidadId = vm.cierre.id;
    vm.view = true;
  }

  function submit() {
      vm.submited = true;
      //var trimmedEmpleado = ModelTrimmer.trimDetails(vm.empleado);
      var cierres = CierreInventarioFactory.create(vm.cierre);
      submitCierre().then(function (cierres) {
          $location.path('/cierre');
      }, function(error) {
          $rootScope.isProcessing = false;
          console.log("Ha ocurrido un error");
          console.log(error);
      });

      function submitCierre(){
          return CierreInventarioFactory.save(cierres);
      }
    }

  function cancel() {
    $location.path("/cierre");
  }

  function selectAll() {
    console.log("selectAll");
    vm.checked = !vm.checked;
    _.forEach(vm.inventario.productosStock, function(registro) {
      registro.ajustar = vm.checked;
    });
  }

  vm.resource = 'cierre'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';


angular.module('qualita.inventario')
  .controller('InventarioProcesoCtrl', InventarioProcesoCtrl);

InventarioProcesoCtrl.$inject = ["InventarioFactory", "$scope"];

function InventarioProcesoCtrl(InventarioFactory, $scope) {

  $scope.inventarioId = InventarioFactory.getIdInventario();
  $scope.inventarioCodigo = InventarioFactory.getCodigoInventario();
  $scope.inventario = InventarioFactory.getInventario();
  $scope.enabledStates = InventarioFactory.getEnabledStates();

  $scope.$on("updateInventarioStates", function() {
    console.log("updateInventarioStates listener triggered");
    $scope.enabledStates = InventarioFactory.getEnabledStates();
  });
}

'use strict';


angular.module('qualita.inventario')
        .controller('ConteoIndexCtrl', ConteoIndexCtrl);

ConteoIndexCtrl.$inject = ["AuthorizationService", "InventarioFactory", "filterFactory", "$modal", "$scope", "$state", "InventarioLangFactory",
 "ConteoFactory", "$filter", "ReportTicketFactory", "$window", "UtilFactory",
    "CsvFactory", "baseurl"];

function ConteoIndexCtrl(AuthorizationService, InventarioFactory, filterFactory, $modal, $scope, $state,
    InventarioLangFactory, ConteoFactory, $filter, ReportTicketFactory, $window, UtilFactory,
        CsvFactory, baseurl) {

    var vm = this;
    var defaultColumnOrder = ['id', 'inventario.fecha', 'contador.contador', 'inventario.id'];

    InventarioLangFactory.getTranslations().then(function (translations) {
        $scope.translations = translations;
        vm.options = {
            'resource': 'conteos',
            'title': $filter('translate')('CONTEOS'),
            'factory': ConteoFactory,
            'view': 'ConteoList',
            'defaultColumnOrder': defaultColumnOrder,
            'failedDeleteError': $filter('translate')('ERROR_CONTEO_DELETE'),
            'columns': [
                {'data': 'id', 'title': $filter('translate')('CODE'), 'renderWith': 'emptyRender'},
                {'data': 'inventario.fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'type': 'date-range', 'class': 'dt-center'},
                {'data': 'contador.contador', 'title': $filter('translate')('CONTADOR'), 'renderWith': 'emptyRender'},
                {'data': 'inventario.id', 'title': $filter('translate')('INVENTARIO'), 'renderWith': 'emptyRender', class: "dt-right"}
            ],
            'hasOptions': true,
            'defaultOrderColumn': 0,
            'defaultOrderDir': "desc",
            'hideAddMenu': true,
            'hideEditMenu': true,
            'hideRemoveMenu': true,
            'hideViewMenu': true,
            'extraMenuOptions':
                    [
                        {
                            'title': $filter('translate')('NEW_CONTEO'),
                            'icon': 'glyphicon glyphicon-plus',
                            'showCondition': function () {
                                var permission = AuthorizationService.hasPermission('create_conteos');
                                $scope.inventario = InventarioFactory.getInventario();
                                if ($state.is("app.inventario.proceso.conteo.list")) {
                                    // Se controla estado del inventario solo dentro del flujo
                                    return permission &&
                                        $scope.inventario.estado.codigo != 'inventario_cerrado' && 
                                        $scope.inventario.estado.codigo != 'inventario_cancelado';
                                } else {
                                    /* cuando NO estamos dentro del flujo de inventario siempre
                                     podemos crear un nuevo conteo*/
                                    return true;
                                }

                            },
                            'action': function () {
                                if ($state.is("app.inventario.proceso.conteo.list")) {
                                    $state.go('app.inventario.proceso.conteo.new');
                                } else {
                                    $state.go('app.conteos.new');
                                }
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () {
                                return true;
                            },
                            'action': function () {
                                var filters = createFilters(vm.options.getFilters());
                                ReportTicketFactory.ticket('listadoConteo', filters, vm.options.tableAjaxParams,
                                        vm.options.currentColumnOrder).then(function (genTicket) {
                                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                    $window.open($scope.pdfDownloadURL, '_blank');
                                });
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () {
                                return true;
                            },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                                CsvFactory.csv("conteos", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                                        .then(function (response) {
                                            console.log("response de csv: ", response);
                                            var fileName = response.data.fileName;
                                            $window.open(baseurl.getPublicBaseUrl() + fileName);
                                        });
                            }
                        }
                    ],
            'extraRowOptions': [
                {
                    templateToRender: "<button class='btn btn-success' style='margin-right: 5px;'   title=" + $filter('translate')('PROCESS') + " ng-click='editarProceso(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                    customAttribute: 'inventario',
                    conditionName: 'canEditar',
                    conditionDef: function (inventario) {
                        var permission = AuthorizationService.hasPermission('update_conteos');
                        if (inventario.estado.codigo === "inventario_cerrado" || inventario.estado.codigo === 'inventario_cancelado') {
                            return false;
                        }
                        return permission;
                    },
                    functionName: "editarProceso",
                    functionDef: function (itemId) {
                        if ($state.is("app.inventario.proceso.conteo.list")) {
                            $state.go('app.inventario.proceso.conteo.edit', {id: itemId});
                        } else {
                            $state.go('app.conteos.edit', {id: itemId});
                        }
                    }
                },
                {
                    templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                    customAttribute: 'inventario',
                    conditionName: "canBorrar",
                    conditionDef: function (inventario) {
                        var permission = AuthorizationService.hasPermission('delete_conteos');
                        if (inventario.estado.codigo === "inventario_cerrado" || inventario.estado.codigo === 'inventario_cancelado') {
                            return false;
                        }
                        return permission;
                    },
                    functionName: "borrar",
                    functionDef: function (itemId) {
                        $scope.selectedItemId = itemId;
                        $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                        $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                        var modalInstance = $modal.open({
                            templateUrl: 'views/directives/confirmation-modal.html',
                            scope: $scope
                        });

                        $scope.cancel = function () {
                            modalInstance.dismiss('cancel');
                        };

                        $scope.ok = function (itemId) {
                            var model = ConteoFactory.create({id: itemId});
                            ConteoFactory.remove(model).then(function () {
                                modalInstance.close(itemId);
                                InventarioFactory.generarConteo($scope.inventario.id);
                                $state.go($state.current, {}, {reload: true});
                            }, function () {
                                modalInstance.dismiss('cancel');
                                $scope.tituloModal = $filter('translate')('DELETE_FAILED');
                                $scope.mensajeModal = $filter('translate')('CONTEO_DELETE_ERROR');

                                var modalInstance2 = $modal.open({
                                    templateUrl: 'views/directives/information-modal.html',
                                    scope: $scope
                                });
                                $scope.cancel = function () {
                                    modalInstance2.dismiss('cancel');
                                };
                            });
                        }
                    }
                },
                {
                    templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                    conditionName: 'canView',
                    conditionDef: function () {
                        return AuthorizationService.hasPermission('index_conteos');
                    },
                    functionName: "view",
                    functionDef: function (itemId) {
                        if ($state.is("app.inventario.proceso.conteo.list")) {
                            $state.go('app.inventario.proceso.conteo.view', {id: itemId});
                        } else {
                            $state.go('app.conteos.view', {id: itemId});
                        }
                    }
                }

            ]
        };
        /* Filtramos por inventario cuando estamos dentro del flujo */
        if ($state.is("app.inventario.proceso.conteo.list")) {
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                    path: 'inventario.id',
                    equals: InventarioFactory.getIdInventario(),
                }]).value();
            vm.options.staticFilter = staticFilter;
        }
    });
    
    var createFilters = function (filters) {

        var filtersArr = [];
        _.each(filters, function (search, data) {
            //console.log("data: ", data);
            //console.log("search: ", search);

            // Se determina si el filtro es un rango
            search = search.split("~");
            if (search.length > 1){
                /* El parametro es un rango */

                var isDate = search[0].split("/");

                if (isDate.length > 1) {
                    /* Es un rango de fechas */

                    filtersArr.push({path: data, equalOrAfter: search[0]})
                    filtersArr.push({path: data, equalOrBefore: search[1]})
                } else {
                    /* El un rango numérco */

                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                    filtersArr.push({path: data, lessOrEqual: search[1]})
                }
            }else{
                /* El parametro no es un rango */

                // Si estamos dentro de un flujo, y se trata del filtro inventario.id
                if($state.is("app.inventario.proceso.conteo.list") && data === 'inventario.id'){
                    // Se setea el filtro de inventario.id
                    filtersArr.push({path: 'inventario.id', equals: vm.options.staticFilter.search.filters[0].equals});
                }else{
                    filtersArr.push({path: data, like: search[0]});
                }
            }
        });

        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }

}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.inventario')
        .controller('ConteoFormCtrl', ConteoFormCtrl);

ConteoFormCtrl.$inject = ['$rootScope', '$scope', 'ConteoFactory', '$state',
    'conteoPrepService', 'notify', 'InventarioLangFactory', 'filterFactory', '$timeout',
    'InventarioFactory', '$filter', 'InventarioDetalleFactory', 'GruposProductosFactory', 'ProductosFactory'];

function ConteoFormCtrl($rootScope, $scope, ConteoFactory, $state,
        conteoPrepService, notify, InventarioLangFactory, filterFactory, $timeout,
        InventarioFactory, $filter, InventarioDetalleFactory, GruposProductosFactory, ProductosFactory) {

    var vm = this;
    vm.agregar = agregar;
    vm.cancel = cancel;
    vm.removeItemFromArray = removeItemFromArray;
    vm.submit = submit;
    vm.changeInventario = changeInventario;
    vm.changeProducto = changeProducto;
    vm.searchProductoFilter = searchProductoFilter;
    vm.delayRequestProducto = delayRequestProducto;
    vm.staticFilter = null;
    vm.codigoBarrasAdded = codigoBarrasAdded;
    vm.codigoBarrasRemoved = codigoBarrasRemoved;
    vm.codigoBarrasSelected = codigoBarrasSelected;

    activate();

    function codigoBarrasAdded(tag, index) {

        var pad = "0000000000000"
        tag.codigoBarras = pad.substring(0, pad.length - tag.codigoBarras.length) + tag.codigoBarras;
        var filterCodigoBarras = [];

        filterCodigoBarras.push({
                path: "codigoBarrasPrincipal.codigoBarras",
                like: tag.codigoBarras
            })

        var filter = {
                search: filterFactory.or([
                    filterFactory.and(filterCodigoBarras).value()
                ]).value()
             }

        ProductosFactory.all(filter, "ConteoForm").$promise.then(function(response){
            if (response.length >0){

                vm.conteo.conteoDetalle[index].producto = response[0];
                $("#conteo_" + index).focus();   
                var listaDetalles = [];
                for (var i = 0; i < vm.conteo.conteoDetalle.length; i++) {
                    vm.conteo.conteoDetalle[i].cantidad = 1;
                    if (vm.conteo.conteoDetalle[i].producto !== undefined && vm.conteo.conteoDetalle[i].producto !== null) {
                        listaDetalles.push(vm.conteo.conteoDetalle[i]);
                    }
                }
                vm.conteo.conteoDetalle = listaDetalles;
                //vm.conteo.conteoDetalle.push({});
            }else{
                var msg = $filter('translate')('CODIGO_BARRAS_NO_ENCONTRADO') + " -> " + tag.codigoBarras;
                notify({ message: msg, classes: 'alert-danger', position: 'right'});
                codigoBarrasRemoved("", index)
                return;
            }
            
        })
      }
    

    function getDigitoVerificador(codigo) {
        var sumaPares = 0;
        var sumaImpares = 0;
    
        for (var i = 0; i < (codigo.length - 1); i++) {
          var caracter = codigo.charAt(i);
          var c = parseInt(caracter);
          if (!c) {
            console.log("!c");
            continue;
          }
          if ((i + 1) % 2 == 0) {
            sumaPares += c;
          } else {
            sumaImpares += c;
          }
        }
    }
    
      function codigoBarrasRemoved(tag, index) {

        vm.conteo.conteoDetalle[index].codigoBarras = [];
            
      }
    
      function codigoBarrasSelected($tag) {
        var elementos = angular.element("tags-input .tags .tag-item");
        angular.element(elementos).removeClass("elem-selected");
        var elem = _.find(elementos, function(elemento) {
          var texto = elemento.innerText;
          //separamos el espacio con la x del texto
          var textoStr = texto.split(" ");
          if(!textoStr) {
            return false;
          }
    
          var codigo = textoStr[0];
          return codigo === $tag.codigoBarras;
        });
        if(elem) {
          console.log("Elemento obtenido: ");
          angular.element(elem).addClass("elem-selected");
          console.log(elem);
        }
        setCodigoBarrasDefault($tag);
      }
    
      function setCodigoBarrasDefault(item) {
        vm.producto.codigoBarrasPrincipal = item;
      }

    function searchProductoFilter(criteria) {
        /* filtro utilizado en ui-select de productos*/
        return function (item) {
            if (!criteria) {
                return true;
            }

            return (
                item.producto.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.producto.codigo == criteria ||
                (item.producto.codigoBarrasPrincipal && item.producto.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0
            );
        };
    }

    function delayRequestProducto(searchValue) {

        var userInputFilter = {}
        var filterNombre = [];
        var filterCodigoBarras =  [];
        var filterCodigoInterno = [];
        var filterIventario= [];

        if (searchValue.length > 0) {

            filterCodigoInterno.push({
                path: "producto.codigo",
                like: searchValue
            }, {
                path: "inventario.id",
                equals: vm.conteo.inventario.id
            })

            filterIventario.push();

            if (searchValue.length >= 3) {

                filterNombre.push({
                    path: "producto.nombre",
                    like: searchValue
                }, {
                    path: "inventario.id",
                    equals: vm.conteo.inventario.id
                })

                filterCodigoBarras.push({
                    path: "producto.codigoBarrasPrincipal.codigoBarras",
                    like: searchValue
                }, {
                    path: "inventario.id",
                    equals: vm.conteo.inventario.id
                })
            }

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
             }

            vm.conteo.inventario.productos = InventarioDetalleFactory.all(userInputFilter, "InventarioForm")

        } else {
            vm.conteo.inventario.productos = null;
        }
    }

    function activate() {

        /* Eso se usa en el delayRequest de la directiva validate-uiselet-input de grupo de productos*/
        vm.staticFilterGrupo = [];
        vm.gruposProductosFactory = GruposProductosFactory;

        InventarioLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;

            vm.factoryProductos = InventarioDetalleFactory;
            vm.curDate = new Date();
            vm.bloquearSeleccion = false;
            vm.bloquearCantidad = false;

            recuperarIventarios();

            if ($state.is("app.conteos.new") || $state.is("app.inventario.proceso.conteo.new")) {
                activateNew();
            } else if ($state.is("app.conteos.edit") || $state.is("app.inventario.proceso.conteo.edit")) {
                activateEdit();
            } else if ($state.is("app.conteos.view") || $state.is("app.inventario.proceso.conteo.view")) {
                activateView();
            }
            $rootScope.isProcessing = false;
            vm.submited = false;
        });
    }

    function recuperarIventarios() {
        /* recuperamos todos los inventarios que no esten cerrados */

        var staticFilter = {};
        staticFilter.search = filterFactory.or([{
            path: 'estado.codigo',
            equals: 'inventario_abierto'
        },
        {
            path: 'estado.codigo',
            equals: 'inventario_proceso'
        },

        filterFactory.and([
            {
                path: 'id',
                sortAsc: 'true'
            }]).value()
        ]).value();

        staticFilter.view = "InventarioForm";

        InventarioFactory.all(staticFilter).$promise.then(function (response) {
            vm.inventarios = response;
        });
    }

    function changeProducto(detalle) {
        /* Seleccionando desde el advaced-datatables-search */
        /* asi esta retornando el advaced-datatables-search (detalle.producto.producto)*/

        vm.bloquearCantidad = false
        if (detalle.producto) {
            
            if (detalle.producto.producto) {
                detalle.producto = detalle.producto.producto;
            }
            detalle.codigoBarras = [detalle.producto.codigoBarrasPrincipal.codigoBarras];
            if (detalle.producto.criterioControl.codigo == "series") {
                vm.bloquearCantidad = true;
                detalle.cantidad = 1;
                vm.idProductoActual = detalle.producto.id;

                /*
                vaciamos los grupos y seteamos filtro.
                solo le mostrara el usuario los grupos con 3 caracteres coincidentes.
                */
                //detalle.producto.gruposProducto = [];
                vm.staticFilterGrup = [];
                vm.staticFilterGrupo = [{path: "producto.id", equals: detalle.producto.id}];

            }
            
        }
    }

    function agregar() {
        var listaDetalles = [];
        for (var i = 0; i < vm.conteo.conteoDetalle.length; i++) {
            if (vm.conteo.conteoDetalle[i].producto !== undefined && vm.conteo.conteoDetalle[i].producto !== null) {
                listaDetalles.push(vm.conteo.conteoDetalle[i]);
            }
        }
        vm.conteo.conteoDetalle = listaDetalles;
        vm.conteo.conteoDetalle.push({});
        $timeout(function () {
            $scope.$broadcast('newItemAdded');
        }, 20);
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
    }

    function activateNew() {
        vm.title = $filter('translate')('NEW_CONTEO');
        vm.conteo = {conteoDetalle: []};
        vm.conteo.inventario = {};

        if ($state.is("app.inventario.proceso.conteo.new")) {
            vm.conteo.inventario.id = InventarioFactory.getIdInventario();

            /* recuperar todos los datos del inventario con vm.conteo.inventario.id */
            changeInventario();
            vm.bloquearSeleccion = true;
            vm.conteo.fecha = new Date();
            genDTBusquedaProductos();
        } else {
            /* Se ingresa al formulario de conteo fuera del flujo de inventario */
            changeInventario();
        }
    }

    function activateEdit() {
        vm.isEdit = true;
        vm.title = $filter('translate')('EDIT_CONTEO');
        vm.conteo = conteoPrepService;
        $scope.entidad = "conteo";
        $scope.entidadId = vm.conteo.id;
        vm.conteo.inventario.fecha = new Date(vm.conteo.inventario.fecha);

        if ($state.is("app.inventario.proceso.conteo.edit")) {
            vm.bloquearSeleccion = true;
            vm.conteo.inventario = InventarioFactory.getInventario();
            vm.conteo.inventario.fecha = new Date(vm.conteo.inventario.fecha);
            genDTBusquedaProductos();
            _.forEach(vm.conteo.conteoDetalle, function (detalle) {
                changeProducto(detalle);
            });
        } else {
            /* Se ingresa al formulario de conteo fuera del flujo de inventario */
            vm.conteo.inventario.fecha = new Date(vm.conteo.inventario.fecha);
            genDTBusquedaProductos();
            _.forEach(vm.conteo.conteoDetalle, function (detalle) {
                changeProducto(detalle);
            });
        }
    }

    function activateView() {
        vm.title = $filter('translate')('VIEW_CONTEO');
        vm.conteo = conteoPrepService;
        vm.conteo.inventario.fecha = new Date(vm.conteo.inventario.fecha);
        $scope.entidad = "conteo";
        $scope.entidadId = vm.conteo.id;
        vm.view = true;
        genDTBusquedaProductos();
        _.forEach(vm.conteo.conteoDetalle, function (detalle) {
            changeProducto(detalle);
        });
    }

    function submit() {
        vm.submited = true;

        if(vm.conteo.conteoDetalle.length < 1){
            var msg = $filter('translate')('CONTEO_SIN_PRODUCTO');
            notify({ message: msg, classes: 'alert-danger', position: 'right'});
            return;
        }

        // Validacion de cantidades decimales en los detalles
        var banValidacion = true;
        _.forEach(vm.conteo.conteoDetalle, function(detalle){
            if(detalle.producto.unidadMedidaBase != null && detalle.cantidad != null){

                if(detalle.producto.unidadMedidaBase.permiteDecimales != true && detalle.cantidad % 1 != 0 ){
                    notify({
                        message: $filter('translate')('VALIDACION_CANTIDAD_ERROR', {nombreProd:detalle.producto.nombre}),
                        classes: 'alert-danger',
                        position: 'right'
                    });
                    banValidacion = false;
                    return false;
                }
            }
        });
        if(!banValidacion){
            return false;
        }

        if ($scope.conteoForm.$valid) {
            vm.conteo.fecha = vm.conteo.inventario.fecha;
            var conteos = ConteoFactory.create(vm.conteo);

            submitConteo().then(function (conteos) {
                InventarioFactory.setIdInventario(conteos.inventario.id);
                InventarioFactory.setCodigoInventario(conteos.inventario.codigo);
                InventarioFactory.setInventario(conteos.inventario);

                $scope.$emit("updateInventarioStates");
                if ($state.is("app.inventario.proceso.conteo.new") || $state.is("app.inventario.proceso.conteo.edit")) {
                    $state.go('app.inventario.proceso.conteo.list');
                } else {
                    $state.go('app.conteos.list');
                }
            }, function (error) {
                $rootScope.isProcessing = false;
                if (error.data[0].message) {
                    notify({
                        message: error.data[0].message,
                        classes: 'alert-danger',
                        position: 'right'
                    });
                }
            });

            function submitConteo(){
                return ConteoFactory.save(conteos);
            }
        }
    }

    function cancel() {
        if ($state.current.ncyBreadcrumb.parent == "app.inventario.proceso.conteo") {
            $state.go('app.inventario.proceso.conteo.list');
        } else {
            $state.go('app.conteos.list');
        }
    }

    function changeInventario() {
        InventarioFactory.get(vm.conteo.inventario.id, "InventarioForm").$promise.then(function (inv) {
            vm.conteo.inventario = inv;
            vm.conteo.inventario.fecha = new Date(vm.conteo.inventario.fecha);
            vm.conteo.conteoDetalle = [];
            genDTBusquedaProductos();
         });
    }

    function genDTBusquedaProductos() {

            var defaultColumnOrder = ['producto.id', 'producto.nombre', 'producto.claseProducto.descripcion', 'producto.estado.descripcion',
                'producto.lineaProducto.nombre', 'producto.marca.descripcion'];

            vm.opcionesProductosDt = {
                'resource': 'inventariodetalle/viewdetalleinv',
                'title': 'Productos',
                'factory': InventarioDetalleFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'producto.id', 'title': $filter('translate')('ID'), visible: false },
                    { 'data': 'producto.nombre', 'title': $filter('translate')('NAME') },
                    { 'data': 'producto.claseProducto.descripcion', 'title': $filter('translate')('PRODUCT_CLASS') },
                    { 'data': 'producto.estado.descripcion', 'title': $filter('translate')('STATUS'), 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
                    { 'data': 'producto.lineaProducto.nombre', 'title': $filter('translate')('LINE') },
                    { 'data': 'producto.marca.descripcion', 'title': $filter('translate')('BRAND'), 'renderWith': 'emptyRender' },
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
            };

            var filterDT = {};
            filterDT.search = filterFactory
                    .and([{
                            path: "inventario.id",
                            equals: vm.conteo.inventario.id
                        }
                    ])
                    .value();
            vm.opcionesProductosDt.staticFilter = filterDT;
    }

    vm.resource = 'conteo'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';


angular.module('qualita.inventario')
  .controller('ContadorIndexCtrl', ContadorIndexCtrl);

ContadorIndexCtrl.$inject = ["AuthorizationService", "UsuariosFactory",
  "filterFactory", "$log", "$modal", "$scope", "notify", "$state", "InventarioLangFactory",
  "ParametrosFactory", "TiposTipoFactory", "ContadorFactory", "$filter", "ReportTicketFactory", "$window",
  "UtilFactory", "CsvFactory", "baseurl"];

function ContadorIndexCtrl(AuthorizationService, UsuariosFactory,
  filterFactory, $log, $modal, $scope, notify, $state, InventarioLangFactory,
  ParametrosFactory, TiposTipoFactory, ContadorFactory, $filter, ReportTicketFactory,
  $window, UtilFactory, CsvFactory, baseurl) {
  var vm = this;

  var defaultColumnOrder = ['id', 'contador', 'usuario.username'];

  InventarioLangFactory.getTranslations().then(function (translations) {

    /*var staticFilter = {};
    staticFilter.search = filterFactory.and([{
      path: 'producto.claseProducto.tipoAdministracion.codigo',
      equals: 'bobinas'
    }]).value();*/

    $scope.translations = translations;
    vm.options = {
      'resource': 'contadores',
      'title': $filter('translate')('CONTADORES'),
      'factory': ContadorFactory,
      'view': 'BaseList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('ERROR_CONTADOR_DELETE'),
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('CODE'), 'renderWith': 'emptyRender'  },
        { 'data': 'contador', 'title': $filter('translate')('CONTADOR'), 'renderWith': 'emptyRender' },
        { 'data': 'usuario.username', 'title': $filter('translate')('USUARIO'), 'renderWith': 'emptyRender' }
      ],
      'hasOptions': true,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'extraMenuOptions':
        [
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoContador', filters, vm.options.tableAjaxParams,
                vm.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

              CsvFactory.csv("contadores", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
        ]
    };

  });

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }

}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.inventario')
  .controller('ContadorFormCtrl', ContadorFormCtrl);

ContadorFormCtrl.$inject = ['$rootScope', '$scope', 'UsuariosFactory', '$location', '$state',
'contadorPrepService', 'notify', 'formFactory', 'InventarioLangFactory', 'TiposFactory','filterFactory','$timeout',
'ProductosFactory','InventarioFactory','ContadorFactory','ModelTrimmer','ParametrosFactory', '$filter'];

function ContadorFormCtrl($rootScope, $scope, UsuariosFactory, $location, $state,
contadorPrepService, notify, formFactory, InventarioLangFactory, TiposFactory,filterFactory,$timeout,
ProductosFactory,InventarioFactory,ContadorFactory,ModelTrimmer,ParametrosFactory,$filter) {

  var vm = this;
  vm.cancel = cancel;
  vm.submit = submit;
  activate();

  function activate() {
    var params = {};
    InventarioLangFactory.getTranslations().then(function(translations) {
      vm.translations=translations;
      UsuariosFactory.all().$promise.then(function (data) {
        vm.usuarios = data;
      });
      if($state.is("app.contadores.new")) {
        activateNew();
      }else if($state.is("app.contadores.edit")) {
        activateEdit();
      } else if($state.is("app.contadores.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      vm.submited = false;
      

    });
  }

  function activateNew() {
    vm.title = $filter('translate')('NEW_CONTADOR');
    vm.contador = {};
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_CONTADOR');
    vm.contador = contadorPrepService;
    $scope.entidad = "contador";
    $scope.entidadId = vm.contador.id;
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_CONTADOR');
    vm.contador = contadorPrepService;
    $scope.entidad = "contador";
    $scope.entidadId = vm.contador.id;
    vm.view = true;
  }

  function submit() {
      vm.submited = true;
      //var trimmedEmpleado = ModelTrimmer.trimDetails(vm.empleado);
      if($scope.contadorForm.$valid) {

        var filter={};
        filter.search=filterFactory.and([
            {
              path: 'usuario.id',
              equals: vm.contador.usuario.id
            },
            {
              path: 'contador',
              equals: vm.contador.contador
            }
          ]).value();
        ContadorFactory.all(filter).$promise.then(function(response) {
          if(response.length===0){
            var trimedModel=ModelTrimmer.trimDetails(vm.contador);
            var conteos = ContadorFactory.create(trimedModel);
            submitContador().then(function (conteos) {
              $state.go('app.contadores.list');
            }, function(error) {
                $rootScope.isProcessing = false;
            });
            function submitContador(){
              return ContadorFactory.save(conteos);
            }
          }else{
            var msg = "Ya existe un contador con ese nombre asignado a ese usuario.";
            notify({ message: msg, classes: 'alert-danger', position: 'right'});
          }
        });
        

        
      }
    }

  function cancel() {
    $state.go('app.contadores.list');
  }

  vm.resource = 'contador'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

angular.module('qualita.inventario')
        .factory('InventarioFactory', InventarioFactory);

InventarioFactory.$inject = ['$resource', 'baseurl', '$http'];

function InventarioFactory($resource, baseurl, $http) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove,
        getIdInventario: getIdInventario,
        setIdInventario: setIdInventario,
        getCodigoInventario: getCodigoInventario,
        setCodigoInventario: setCodigoInventario,
        getInventario: getInventario,
        setInventario: setInventario,
        getEnabledStates: getEnabledStates,
        setEnabledStates: setEnabledStates,
        checkPermissionForState: checkPermissionForState,
        aperturaInventario: aperturaInventario,
        getProductosInventario: getProductosInventario,
        getInventarioCSV: getInventarioCSV,
        generarConteo: generarConteo,
        cerrar: cerrar,
        recuperarInventarioCierre: recuperarInventarioCierre,
        generarResumen: generarResumen
    };

    var Inventario = $resource(baseurl.getBaseUrl() + "/inventarios/:id", {id: '@id'}, {
        update: {
            method: 'PUT'
        },
        getInventarioCSV: {
            method: 'POST',
            url: baseurl.getBaseUrl() + "/inventarios/csvinventario/:id"
        }
    });

    var codigoInventario;
    var inventario;
    var enabledStates;
    var idInventario;

    return service;

    function all(params) {
        return Inventario.query(params);
    }

    function get(id, view) {
        return Inventario.get({id: id, view: view ? view : "InventarioList"});
    }

    function create(attrs) {
        return new Inventario(attrs);
    }

    function save(inventario) {
        return (inventario.id) ? inventario.$update() : inventario.$save();
    }

    function remove(inventario) {
        return inventario.$remove();
    }

    function getIdInventario() {
        return idInventario;
    }

    function setIdInventario(id) {
        idInventario = id;
    }

    function getCodigoInventario() {
        return codigoInventario;
    }

    function setCodigoInventario(codigo) {
        codigoInventario = codigo;
    }

    function getInventario() {
        updateStates();
        return inventario;
    }

    function getEnabledStates() {
        return enabledStates;
    }

    function setEnabledStates(states) {
        enabledStates = states;
    }

    function setInventario(cmp) {
        if (cmp.id) {
            inventario = cmp;
        } else {
            inventario = get(cmp, 'InventarioForm').$promise;
        }
        updateStates();
    }
    function checkPermissionForState(estadoIndex) {
        return enabledStates[estadoIndex];
    }

    function updateStates() {
        if (inventario && inventario.estado && inventario.estado.codigo) {
            switch (inventario.estado.codigo) {
                case "inventario_creado":
                    enabledStates = [true, false, false];
                    break;
                case "inventario_abierto":
                    enabledStates = [true, true, false];
                    break;
                case "inventario_proceso":
                    enabledStates = [true, true, true];
                    break;
                case "inventario_cerrado":
                    enabledStates = [true, true, true];
                    break;
                default:
                    enabledStates = [true, true, true];
                    break;
            }
        }
    }

    function aperturaInventario(inventario, search) {
        return $http({
            method: 'POST',
            url: baseurl.getBaseUrl() + '/inventarios/apertura/' + inventario.id,
            data: search
        });
    }

    function getProductosInventario(id, view) {
        return $http({
            url: baseurl.getBaseUrl() + '/inventarios/productos/' + id,
            method: "GET",
            params: {view: view},
        });
    }

    function  getInventarioCSV(id) {
        return $http({
            method: 'POST',
            url: baseurl.getBaseUrl() + '/inventarios/csvinventario/' + id
        });
    }

    function generarConteo(id) {
        return $http({
            method: 'GET',
            url: baseurl.getBaseUrl() + '/inventarios/generarconteo/' + id
        });
    }

    function cerrar(id, arrayId) {
        return $http({
            method: 'GET',
            url: baseurl.getBaseUrl() + '/inventarios/cerrar/' + id + "/" + arrayId
        });
    }
    function  recuperarInventarioCierre(id) {
        return $http({
            method: 'GET',
            url: baseurl.getBaseUrl() + '/inventarios/recuperarInventarioCierre/' + id
        });
    }

    function generarResumen(id) {
        return $http({
            method: 'POST',
            url: baseurl.getBaseUrl() + '/inventarios/resumenconteo/' + id
        });
    }


}

angular.module('qualita.inventario')
    .factory('CierreInventarioFactory', CierreInventarioFactory);

    CierreInventarioFactory.$inject = ['$resource', 'baseurl', '$http'];

    function CierreInventarioFactory($resource, baseurl, $http) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove
    };

    var Inventario = $resource( baseurl.getBaseUrl() + "/cierreinventarios/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
    });

    return service;

  function all(params) {
    return Inventario.query(params);
  }

  function get(id, view) {
    return Inventario.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new Inventario(attrs);
  }

  function save(inventario) {
    return (inventario.id) ? inventario.$update() : inventario.$save();
  }

  function remove(inventario) {
    return inventario.$remove();
  }

}

angular.module('qualita.inventario')
    .factory('ConteoFactory', ConteoFactory);

  ConteoFactory.$inject = ['$resource', 'baseurl'];

  function ConteoFactory($resource, baseurl) {
  var service = {
      all: all,
      get: get,
      create: create,
      save: save,
      remove: remove
  };

  var Inventario = $resource( baseurl.getBaseUrl() + "/conteos/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params) {
    return Inventario.query(params);
  }

  function get(id, view) {
    return Inventario.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new Inventario(attrs);
  }

  function save(inventario) {
    return (inventario.id) ? inventario.$update() : inventario.$save();
  }

  function remove(inventario) {
    return inventario.$remove();
  }

}

angular.module('qualita.inventario')
  .factory('ContadorFactory', ContadorFactory);

ContadorFactory.$inject = ['$resource', 'baseurl', '$http'];

function ContadorFactory($resource, baseurl, $http) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var Inventario = $resource(baseurl.getBaseUrl() + "/contadores/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined || params.view != undefined ? (view || params.view) : 'BaseList';
    } else {
      params = [];
      params.view = view != undefined ? view : 'BaseList';
    }
    return Inventario.query(params);
  }

  function get(id, view) {
    return Inventario.get({ id: id, view: view ? view : "base" });
  }

  function create(attrs) {
    return new Inventario(attrs);
  }

  function save(inventario) {
    return (inventario.id) ? inventario.$update() : inventario.$save();
  }

  function remove(inventario) {
    return inventario.$remove();
  }

}

"use strict";

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module("qualita.inventario").factory("InventarioLangFactory", [
  "$resource",
  "baseurl",
  "$http",
  "$translatePartialLoader",
  "$translate",
  function($resource, baseurl, $http, $translatePartialLoader, $translate) {
    var translationsNeeded = [
      "INVENTARIO",
      "CODIGO_INVENTARIO",
      "CODE",
      "STATUS",
      "DATE",
      "ALMACEN",
      "ERROR_INVENTARIO_DELETE",
      "DELETE_CONFIRMATION",
      "DELETE_WARNING",
      "OK",
      "CANCEL",
      "NEW_INVENTARIO",
      "CONTEOS",
      "NEW_CONTEO",
      "EDIT_CONTEO",
      "VIEW_CONTEO",
      "ERROR_CONTEO_DELETE",
      "CONTADOR",
      "APERTURA",
      "CONTEO",
      "CIERRE",
      "CONTADORES",
      "FAMILIA_PRODUCTO_FILTER",
      "PROVEDOR_PRODUCTO_FILTER",
      "PRODUCTOS_LIST",
      "NEW_CIERRE",
      "EDIT_CIERRE",
      "VIEW_CIERRE",
      "CODIGO_BARRAS_PRODUCTO",
      "NOMBRE_PRODUCTO",
      "UNIDAD_MEDIDA",
      "EXISTENCIA_LOGICA",
      "AJUSTE_A_REALIZAR",
      "VALOR_ULTIMO_COSTO",
      "VALOR_COSTO_PROMEDIO",
      "VALOR_PRECIO_VENTA",
      "TOTAL_VALORES",
      "COSTO_PROMEDIO_SIN_IVA",
      "PRECIO_VENTA",
      "STOCK_LOGICO",
      "MOVIMIENTO_AJUSTE",
      "REALIZAR_AJUSTE",
      "GRUPO_PRODUCTO",
      "USUARIO",
      "NEW_CONTADOR",
      "EDIT_CONTADOR",
      "VIEW_CONTADOR",
      "CONTEO_DELETE_ERROR",
      "HOUR_START",
      "HOUR_END",
      "CODIGO_BARRAS_PRINCIPAL",
      "NAME",
      "INTERNAL_CODE",
      "BALANS_CODE",
      "BAR_CODE",
      "INVENTARIO_SIN_CONTEO",
      "NO_ADMINISTRATIVE_PERIOD",
      "FECHA_APERTURA",
      "FECHA_CIERRE",
      "ERROR_CONTADOR_DELETE",
      "CERRAR_INVENTARIO_TITLE",
      "CERRAR_INVENTARIO_MSG",
      "RECUPERAR_PRODUCTOS",
      "VALIDACION_CANTIDAD_ERROR"
      ];
      return {
        getTranslations: function() {
          $translatePartialLoader.addPart('inventario');
          return $translate.refresh().then(function () {
            return $translate(translationsNeeded);
          });
        }
      };
    }]);


angular.module('qualita.inventario')
    .factory('InventarioDetalleFactory', InventarioDetalleFactory);

    InventarioDetalleFactory.$inject = ['$resource', 'baseurl', '$http'];

    function InventarioDetalleFactory($resource, baseurl, $http) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove
    };

    var Inventario = $resource( baseurl.getBaseUrl() + "/inventariodetalle/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
    });

    return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'BaseList';
    } else {
      params = [];
      params.view = view != undefined ? view : 'BaseList';
    }
    return Inventario.query(params);
  }

  function get(id, view) {
    return Inventario.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new Inventario(attrs);
  }

  function save(inventario) {
    return (inventario.id) ? inventario.$update() : inventario.$save();
  }

  function remove(inventario) {
    return inventario.$remove();
  }

}

/**
 * Created by codiumsa on 30/9/15.
 */
angular.module('qualita.cuentasapagar', ['ngResource', 'ui.tree', 'ngFileUpload'])
  .config(
  ['$stateProvider',
    function($stateProvider) {
      $stateProvider
        .state('app.planpagos', {
          url: '/planpagos',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Cuentas a Pagar'
          }
        })
        .state('app.planpagos.list', {
          url: '',
          templateUrl: 'views/template_base/list.html',
          controller: 'PlanPagosIndexCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.planpagos',
            label: 'Cuentas a Pagar',
            description: 'Listado de Cuentas a Pagar',
            skip: true
          }
        })
        .state('app.planpagos.detalles', {
          url: '/:idPlanPago/cuotas',
          templateUrl: 'views/template_base/index.html',
          abstract: true,
          ncyBreadcrumb: {
            label: 'Cuotas'
          }
        })
        .state('app.planpagos.detalles.list', {
          url: '',
          templateUrl: 'qualita-components/cuentasapagar/views/planpagosdetalle/list.html',
          controller: 'PlanPagosDetalleIndexCtrl',
          ncyBreadcrumb: {
            parent: 'app.planpagos',
            label: 'Ver Cuotas',
            description: 'Ver cuotas del plan de pago #'
          }
        })
        .state('app.planpagos.detalles.new', {
          url: '/new',
          templateUrl: 'qualita-components/cuentasapagar/views/planpagosdetalle/form.html',
          controller: 'PlanPagosDetalleFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.planpagos.detalles',
            label: 'Cuota'
          },
          resolve: {
            planPagosDetallePrepService: function() {return;}
          }
        })
        .state('app.planpagos.detalles.edit', {
          url: '/:id/edit',
          templateUrl: 'qualita-components/cuentasapagar/views/planpagosdetalle/form.html',
          controller: 'PlanPagosDetalleFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.planpagos.detalles',
            label: 'Editar Cuota'
          },
          resolve: {
            planPagosDetallePrepService: cuotasPrepService
          }
        })
        .state('app.planpagos.detalles.view', {
          url: '/:id',
          templateUrl: 'qualita-components/cuentasapagar/views/planpagosdetalle/form.html',
          controller: 'PlanPagosDetalleFormCtrl',
          controllerAs: 'vm',
          ncyBreadcrumb: {
            parent: 'app.planpagos.detalles',
            label: 'Ver Cuota'
          },
          resolve: {
            planPagosDetallePrepService: cuotasPrepService
          }
        });
    }]);

/*function planPagosPrepService(PlanPagosFactory, $stateParams) {
  return PlanPagosFactory.get($stateParams.id, "detail").$promise;
}*/

function cuotasPrepService(PlanPagosDetalleFactory, $stateParams) {
  return PlanPagosDetalleFactory.get($stateParams.id, 'PlanPagoDetalleForm').$promise;
}
cuotasPrepService.$inject = ["PlanPagosDetalleFactory", "$stateParams"];

angular
  .module("qualita.cuentasapagar")
  .factory("CuentasAPagarLangFactory", CuentasAPagarLangFactory);

CuentasAPagarLangFactory.$inject = [
  "$resource",
  "baseurl",
  "$translate",
  "$translatePartialLoader",
  "$http"
];

function CuentasAPagarLangFactory(
  $resource,
  baseurl,
  $translate,
  $translatePartialLoader,
  $http
) {
  var translationsNeeded = [
    "COMPROBANTE_NUMERO",
    "COMPROBANTE_ESTADO",
    "ID",
    "PLAN_PAGO_DELETE_FAILED",
    "CANTIDAD_CUOTAS",
    "PLAZO_CUOTAS",
    "MONTO_INICIAL",
    "PLAN_PAGOS",
    "CODE",
    "DATE",
    "SALDO",
    "MONTO",
    "STATUS",
    "VER_CUOTAS",
    "FECHA_VENCIMIENTO",
    "FECHA_COMPROBANTE",
    "TOTAL_ACTUAL",
    "CODE",
    "NUMBER",
    "CUOTAS_COMPROBANTE",
    "MONTO_TOTAL_COMPROBANTE",
    "COMPROBANTE_PROVEEDOR",
    "MONEDA"
  ];

  var service = {
    getTranslations: getTranslations
  };

  function getTranslations() {
    $translatePartialLoader.addPart("cuentasapagar");
    return $translate.refresh().then(function() {
      return $translate(translationsNeeded);
    });
  }

  return service;
}

"use strict";

angular
    .module("qualita.cuentasapagar")
    .controller("PlanPagosIndexCtrl", ["$scope", "AuthorizationService", "ServicioFactory", "filterFactory", "ReportTicketFactory", "$window", "CuentasAPagarLangFactory", "ComprobanteFactory", "$state", "$stateParams", "$modal", "PlanPagosFactory", "$filter", "CsvFactory", "UtilFactory", "baseurl", function(
        $scope,
        AuthorizationService,
        ServicioFactory,
        filterFactory,
        ReportTicketFactory,
        $window,
        CuentasAPagarLangFactory,
        ComprobanteFactory,
        $state,
        $stateParams,
        $modal,
        PlanPagosFactory,
        $filter,
        CsvFactory,
        UtilFactory,
        baseurl
    ) {

        var defaultColumnOrder = [
            "code",
            "fecha",
            "comprobante.numero",
            "comprobante.proveedor.nombre",
            "comprobante.estado.descripcion",
            "comprobante.montoTotal",
            "comprobante.moneda1.descripcion",
            "fechaVencimiento",
            "montoInicial",
            "saldo",
            "estado.descripcion",
            "cantidadCuotas",
            "plazoCuotas"
        ];

        CuentasAPagarLangFactory.getTranslations().then(function(translations) {
            var monedaRender = function(data, type, row) {
                if (data) {

                    if (!row.comprobante) {
                        return '';
                    }
                    var moneda = "Gs. ";
                    if (row.comprobante.moneda1.codigo === 'dolares') {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobante.moneda1.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobante.moneda1.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobante.moneda1.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    }
                    // TODO hacer esto mismo en todos los renders de moneda
                    return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return '';
                }
            };

            var monedaRenderSaldo = function(data, type, row) {
                if (data) {

                    //data += row.montoInicial;

                    if (!row.comprobante) {
                        return '';
                    }
                    var moneda = "Gs. ";
                    if (row.comprobante.moneda1.codigo === 'dolares') {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobante.moneda1.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobante.moneda1.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobante.moneda1.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    }
                    // TODO hacer esto mismo en todos los renders de moneda
                    return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return '';
                }
            };




            $scope.options = {
                resource: "planpagos",
                title: $filter("translate")("PLAN_PAGOS"),
                view: 'PlanPagoList',
                factory: PlanPagosFactory,
                defaultColumnOrder: defaultColumnOrder,
                failedDeleteError: $filter("translate")("PLAN_PAGO_DELETE_FAILED"),
                columns: [
                    { data: "id", title: $filter("translate")("CODE"), visible: false },
                    {
                        data: "fecha",
                        title: $filter("translate")("FECHA_COMPROBANTE"),
                        renderWith: "dateRender",
                        dateFormat: "DD/MM/YYYY",
                        type: "date-range",
                        class: "dt-center"
                    },
                    {
                        data: "comprobante.numero",
                        title: $filter("translate")("COMPROBANTE_NUMERO"),
                        renderWith: "emptyRender"
                    },
                    {
                        data: "comprobante.proveedor.nombre",
                        title: $filter("translate")("COMPROBANTE_PROVEEDOR"),
                        renderWith: "emptyRender"
                    },
                    {
                        data: "comprobante.montoTotal",
                        title: $filter("translate")("MONTO_TOTAL_COMPROBANTE"),
                        renderWith: monedaRender,
                        class: "dt-right",
                        type: "number-range"
                    },
                    {
                        data: "comprobante.estado.descripcion",
                        title: $filter("translate")("COMPROBANTE_ESTADO"),
                        renderWith: "emptyRender",
                        type : 'combo',
                        filterUrl: 'tipos/filtro/estados_comprobante'
                    },
                    {
                        data: "montoInicial",
                        title: $filter("translate")("MONTO_INICIAL"),
                        renderWith: monedaRender,
                        class: "dt-right",
                        type: "number-range"
                    },
                    {
                        data: "fechaVencimiento",
                        title: $filter("translate")("FECHA_VENCIMIENTO"),
                        renderWith: "dateRender",
                        dateFormat: "DD/MM/YYYY",
                        type: "date-range",
                        class: "dt-center"
                    },
                    {
                        data: "estado.descripcion",
                        title: $filter("translate")("STATUS"),
                        type : 'combo',
                        filterUrl: 'tipos/filtro/estados_plan_pagos'
                    },
                    {
                        data: "saldo",
                        title: $filter("translate")("SALDO"),
                        renderWith: monedaRenderSaldo,
                        class: "dt-right",
                        type: "number-range"
                    },
                    {
                        data: "comprobante.moneda1.descripcion",
                        title: $filter("translate")("MONEDA"),
                        class: "dt-right",
                        visible: false
                        //type: "number-range"
                    },
                    {
                        data: "cantidadCuotas",
                        title: $filter("translate")("CANTIDAD_CUOTAS"),
                        class: "dt-right",
                        type: "number-range"
                    },
                    {
                        data: "plazoCuotas",
                        title: $filter("translate")("PLAZO_CUOTAS"),
                        class: "dt-right",
                        type: "number-range"
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc",
                hideAddMenu: true,
                hideEditMenu: true,
                hideRemoveMenu: true,
                hideViewMenu: true,
                extraMenuOptions: [{
                        title: "CSV",
                        icon: "glyphicon glyphicon-export",
                        showCondition: function() {
                            return true;
                        },
                        action: function() {
                            var filters = UtilFactory.createNestedFilters(
                                $scope.options.getFilters(),
                                $scope.options.staticFilter
                            );

                            CsvFactory.csv(
                                "planpagos",
                                filters,
                                $scope.options.tableAjaxParams,
                                $scope.options.currentColumnOrder,
                                $scope.options.currentColumnOrderNames
                            ).then(function(response) {
                                // console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                        }
                    },

                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = createFilters($scope.options.getFilters());
                            filters.filters.push({"path":"comprobante.estado.codigo","equals":"comp_aprobado"})
                            ReportTicketFactory.ticket('listadoPlanPagos', filters, $scope.options.tableAjaxParams,
                                $scope.options.currentColumnOrder).then(function(genTicket) {
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open($scope.pdfDownloadURL, '_blank');
                            });
                        }
                    }
                ],
                extraRowOptions: [{
                    templateToRender: "<button class='btn btn-primary' title='" +
                        $filter("translate")("VER_CUOTAS") +
                        "' style='margin-right: 5px;' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-tasks'></span> </button>",
                    customAttribute: "estado",
                    functionName: "ver",
                    functionDef: function(itemId) {
                        // redireccionar a plan de pago detalle
                        $state.go("^.detalles.list", { idPlanPago: itemId });
                    },
                    conditionName: "canVer",
                    conditionDef: function(estado) {
                        return AuthorizationService.hasPermission(
                            "index_planpagosdetalle"
                        );
                    }
                }]
            };
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'comprobante.estado.codigo',
                equals: "comp_aprobado"
            }]).value();
            $scope.options.staticFilter = staticFilter;
        });

        var createFilters = function(filters) {
            var filtersArr = [];
            _.each(filters, function(search, data) {
                search =  search.split("~");
                if (search.length > 1) {
                    /* El parámetro es un rago */

                    var isDate =  search[0].split("/");

                    if (isDate.length > 1) {
                       /* Es un rago de fechas */

                       filtersArr.push({ path: data, equalOrAfter: search[0] })
                       filtersArr.push({ path: data, equalOrBefore: search[1] })
                    } else {
                       /* El un rago numérco */

                        filtersArr.push({ path: data, greaterOrEqual: search[0] })
                        filtersArr.push({ path: data, lessOrEqual: search[1] })
                    }
                } else {
                    /* El parametro no es un rago */

                    filtersArr.push({ path: data, like: search[0] })
                }
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        }

    }]);
"use strict";

angular
    .module("qualita.cuentasapagar")
    .controller("PlanPagosDetalleIndexCtrl", ["$scope", "AuthorizationService", "ServicioFactory", "filterFactory", "ReportTicketFactory", "$window", "CuentasAPagarLangFactory", "ComprobanteFactory", "$state", "$stateParams", "$modal", "PlanPagosDetalleFactory", "PlanPagosFactory", "$filter", "notify", function(
        $scope,
        AuthorizationService,
        ServicioFactory,
        filterFactory,
        ReportTicketFactory,
        $window,
        CuentasAPagarLangFactory,
        ComprobanteFactory,
        $state,
        $stateParams,
        $modal,
        PlanPagosDetalleFactory,
        PlanPagosFactory,
        $filter,
        notify
    ) {
        // console.log("detalles");
        var defaultColumnOrder = [
            "id",
            "numero",
            "tipo.descripcion",
            "monto",
            "saldo",
            "estado.descripcion",
            "fechaVencimiento"
        ];

        $scope.totalPlanPago = 0;
        $scope.totalActual = 0;
        var staticFilter = {};

        var staticFilterPath, staticFilterId;
        if ($stateParams.idPlanPago) {
            staticFilterPath = "planPagos.id";
            staticFilterId = $stateParams.idPlanPago;
            PlanPagosFactory.get(
                $stateParams.idPlanPago,
                "CuotaMontoList"
            ).$promise.then(function(p) {
                $scope.monedaPlan = p.comprobante.moneda1;
                $scope.nroComprobante = p.comprobante.numero;
                $scope.totalPlanPago = p.comprobante.montoTotal;
                _.forEach(p.planPagosDetalles, function(d) {
                    $scope.totalActual = $scope.totalActual + d.monto;
                });

                loadDatatable();
            });
        } else if ($stateParams.idComprobante) {
            staticFilterPath = "planPagos.comprobante.id";
            staticFilterId = $stateParams.idComprobante;
            ComprobanteFactory.get(
                $stateParams.idComprobante,
                "ComprobanteList"
            ).$promise.then(function(c) {

                $scope.monedaPlan = c.moneda1;
                $scope.nroComprobante = c.numero;
                $scope.totalPlanPago = c.montoTotal;
                _.forEach(c.planPagos.planPagosDetalles, function(d) {
                    $scope.totalActual = $scope.totalActual + d.monto;
                });

                loadDatatable();
            });
        }

        staticFilter.search = filterFactory
            .and([{
                path: staticFilterPath,
                equals: staticFilterId || 0
            }])
            .value();

        function loadDatatable() {
            CuentasAPagarLangFactory.getTranslations().then(function(translations) {

                var monedaRender = function(data, type, row) {
                    if (!data) {
                        data = 0;
                    }

                    var moneda = "Gs. ";
                    if ($scope.monedaPlan.codigo === 'dolares') {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if ($scope.monedaPlan.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if ($scope.monedaPlan.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if ($scope.monedaPlan.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    } else if ($scope.monedaPlan.codigo === 'guaranies') {
                        data = parseFloat(data).toFixed(0);
                    }
                    return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                };

                $scope.options = {
                    resource: "planpagosdetalle",
                    staticFilter: staticFilter,
                    view: "datatables",
                    title: $filter("translate")("CUOTAS_COMPROBANTE"),
                    factory: PlanPagosDetalleFactory,
                    view: 'PlanPagoDetalleList',
                    defaultColumnOrder: defaultColumnOrder,
                    failedDeleteError: $filter("translate")("CUOTA_DELETE_FAILED"),
                    columns: [
                        { data: "id", title: $filter("translate")("CODE"), visible: false },
                        { data: "numero", title: $filter("translate")("NUMBER") },
                        { data: "tipo.descripcion", title: $filter("translate")("TYPE") },
                        {
                            data: "monto",
                            title: $filter("translate")("MONTO"),
                            renderWith: monedaRender,
                            type: "number-range",
                            class: "dt-right"
                        }, //actualizar
                        {
                            data: "saldo",
                            title: $filter("translate")("SALDO"),
                            renderWith: monedaRender,
                            class: "dt-right",
                            type: "number-range"
                        },
                        {
                            data: "estado.descripcion",
                            title: $filter("translate")("STATUS")
                        },
                        {
                            data: "fechaVencimiento",
                            title: $filter("translate")("FECHA_VENCIMIENTO"),
                            renderWith: "dateRender",
                            dateFormat: "DD/MM/YYYY",
                            class: "dt-center",
                            type: "date-range"
                        }
                    ],
                    hasOptions: true,
                    defaultOrderColumn: 0,
                    defaultOrderDir: "asc",
                    hideAddMenu: true,
                    hideEditMenu: true,
                    hideRemoveMenu: true,
                    hideViewMenu: true,
                    extraMenuOptions: [
                        // agregar condicion de cuando agregar nuevas cuotas
                        {
                            title: $filter("translate")("NEW"),
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function() {
                                return AuthorizationService.hasPermission("create_planpagos");
                            },
                            action: function() {
                                $state.go("^.new");
                            }
                        }
                    ],
                    extraRowOptions: [{
                            templateToRender: "<button class='btn btn-success' title='" +
                                $filter("translate")("EDIT") +
                                "' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                            // customAttribute: "estado",
                            customAttribute: ["estado", "monto", "saldo", "planPagos"],
                            functionName: "editar",
                            functionDef: function(itemId) {
                                $state.go("^.edit", { id: itemId });
                            },
                            conditionName: "canEditar",
                            conditionDef: function(atributos) {
                                var estado = atributos[0];
                                var monto = atributos[1];
                                var saldo = atributos[2];
                                var estadoComprobante = atributos[3].comprobante.estado;

                                return (
                                    AuthorizationService.hasPermission(
                                        "update_planpagosdetalle"
                                    ) &&
                                    estado.codigo == "planpag_generado" &&
                                    monto == saldo &&
                                    estadoComprobante.codigo == "comp_aprobado"
                                );
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-danger btn-dt' title='" +
                                $filter("translate")("DELETE") +
                                "' style='margin-right: 5px;' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                            customAttribute: ["estado", "monto", "saldo", "planPagos"],
                            functionName: "borrar",
                            functionDef: function(itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.tituloModal = $filter("translate")(
                                    "DELETE_CONFIRMATION"
                                );
                                $scope.mensajeModal = $filter("translate")("DELETE_WARNING");
                                $scope.modalInstanceBorrar1 = $modal.open({
                                    templateUrl: "views/directives/confirmation-modal.html",
                                    scope: $scope
                                });

                                $scope.cancel = function() {
                                    $scope.modalInstanceBorrar1.dismiss("cancel");
                                };

                                $scope.ok = function(itemId) {
                                    var model = PlanPagosDetalleFactory.create({ id: itemId });
                                    PlanPagosDetalleFactory.remove(model).then(
                                        function() {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function(error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            var msg = error.data[0].message;
                                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                                            console.log("error al borrar: ");
                                            console.log(error);
                                        }
                                    );
                                };
                            },
                            conditionName: "canBorrar",
                            conditionDef: function(atributos) {
                                var estado = atributos[0];
                                var monto = atributos[1];
                                var saldo = atributos[2];
                                var estadoComprobante = atributos[3].comprobante.estado;

                                return (
                                    AuthorizationService.hasPermission(
                                        "delete_planpagosdetalle"
                                    ) &&
                                    estado.codigo == "planpag_generado" &&
                                    monto == saldo &&
                                    estadoComprobante.codigo == "comp_aprobado"
                                );
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-info' title='" +
                                $filter("translate")("VIEW") +
                                "' style='margin-right: 5px;' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                            customAttribute: "estado",
                            functionName: "ver",
                            functionDef: function(itemId) {
                                $state.go("^.view", { id: itemId });
                            },
                            conditionName: "canVer",
                            conditionDef: function(estado) {
                                return AuthorizationService.hasPermission(
                                    "index_planpagosdetalle"
                                );
                            }
                        }
                    ]
                };
            });
        }
    }]);
"use strict";

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular
  .module("qualita.cuentasapagar")
  .controller("PlanPagosDetalleFormCtrl", PlanPagosDetalleFormCtrl);

PlanPagosDetalleFormCtrl.$inject = [
  "$rootScope",
  "$scope",
  "PlanPagosDetalleFactory",
  "$location",
  "$state",
  "$stateParams",
  "planPagosDetallePrepService",
  "notify",
  "formFactory",
  "ImportacionLangFactory",
  "TiposFactory",
  "$timeout",
  "$filter"
];

function PlanPagosDetalleFormCtrl(
  $rootScope,
  $scope,
  PlanPagosDetalleFactory,
  $location,
  $state,
  $stateParams,
  planPagosDetallePrepService,
  notify,
  formFactory,
  ImportacionLangFactory,
  TiposFactory,
  $timeout,
  $filter
) {
  activate();

  $scope.cancel = cancel;
  $scope.submit = submit;
  $scope.changeMonto = changeMonto;

  function activate() {
    $scope.curDate = new Date();
    ImportacionLangFactory.getTranslations().then(function(translations) {
      $scope.translations = translations;
      if ($state.is("^.new")) {
        activateNew();
      } else if ($state.is("^.edit")) {
        activateEdit();
      } else if ($state.is("^.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateNew() {
    $scope.title = $filter("translate")("NEW_CUOTA");
    $scope.planPagoDetalle = { planPagos: parseInt($stateParams.idPlanPago) };
    TiposFactory.estadosCuota().then(function(data) {
      var estadoGenerado = _.filter(data.data, function(elem) {
        return elem.codigo === "planpag_generado";
      });
      $scope.planPagoDetalle.estado = estadoGenerado[0];
    });
  }

  function activateEdit() {
    $scope.title = $filter("translate")("EDIT_CUOTA");
    $scope.planPagoDetalle = planPagosDetallePrepService;
    if ($scope.planPagoDetalle.monto != $scope.planPagoDetalle.saldo) {
      $state.go("app.main");
    }
    $scope.planPagoDetalle.fechaVencimiento = new Date(
      $scope.planPagoDetalle.fechaVencimiento
    );
    $scope.entidadId = $scope.planPagoDetalle.id;
    $scope.entidad = "Cuota";

    if($scope.planPagoDetalle.saldo!=undefined && $scope.planPagoDetalle.saldo!=null){
      if($scope.planPagoDetalle.planPagos.comprobante.moneda1.codigo == 'guaranies'){
        $scope.planPagoDetalle.saldo = parseFloat($scope.planPagoDetalle.saldo.toFixed(0));
      }else if($scope.planPagoDetalle.planPagos.comprobante.moneda1.codigo == 'dolares'){
        $scope.planPagoDetalle.saldo = parseFloat($scope.planPagoDetalle.saldo.toFixed(2));
      }
    }
  }

  function activateView() {
    $scope.title = $filter("translate")("VIEW_CUOTA");
    $scope.planPagoDetalle = planPagosDetallePrepService;
    $scope.planPagoDetalle.fechaVencimiento = new Date(
      $scope.planPagoDetalle.fechaVencimiento
    );
    $scope.entidadId = $scope.planPagoDetalle.id;
    $scope.entidad = "Cuota";
    $scope.view = true;

    if($scope.planPagoDetalle.saldo!=undefined && $scope.planPagoDetalle.saldo!=null){
      if($scope.planPagoDetalle.planPagos.comprobante.moneda1.codigo == 'guaranies'){
        $scope.planPagoDetalle.saldo = parseFloat($scope.planPagoDetalle.saldo.toFixed(0));
      }else if($scope.planPagoDetalle.planPagos.comprobante.moneda1.codigo == 'dolares'){
        $scope.planPagoDetalle.saldo = parseFloat($scope.planPagoDetalle.saldo.toFixed(2));
      }
    }
  }

  function changeMonto() {
    $scope.planPagoDetalle.saldo = $scope.planPagoDetalle.monto;
  }

  function submit() {
    $scope.submited = true;
    if ($scope.PlanPagosDetalleForm.$valid) {
      formFactory
        .defaultNSFSubmit(
          $scope.PlanPagosDetalleForm,
          PlanPagosDetalleFactory,
          $scope.planPagoDetalle,
          errorHandler
        )
        .then(
          function(response) {
            $state.go("^.list");
          },
          function(error) {
            console.log(error);
          }
        );
    } else {
      //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }
  }

  function errorHandler(e) {
    var msg = "";
    _.forEach(e.data, function(error) {
      if (error.constraint == "nombre") {
        msg += "\n\n" + $filter("translate")("SAME_NAME_PRODUCT_CLASS") + "\n";
      } else {
        msg += "\n\n" + error.message + ".";
      }
    });
    notify({ message: msg, classes: "alert-danger", position: "right" });
  }

  function cancel() {
    $state.go("^.list");
  }

  $scope.resource = "planpagos"; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

angular.module('qualita.cuentasapagar')
  .factory('PlanPagosFactory', PlanPagosFactory);

PlanPagosFactory.$inject = ['$resource', 'baseurl'];

function PlanPagosFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var PlanPago = $resource( baseurl.getBaseUrl() + "/planpagos/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'base';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'base';
    }
    return PlanPago.query(params);
  }

  function get(id, view) {
    return PlanPago.get({id: id, view: view!=undefined ? view : 'base'});
  }

  function create(attrs) {
    return new PlanPago(attrs);
  }

  function save(planPago) {
    return (planPago.id) ? planPago.$update() : planPago.$save();
  }

  function remove(planPago) {
    return planPago.$remove();
  }
}

angular.module('qualita.cuentasapagar')
  .factory('PlanPagosDetalleFactory', PlanPagosDetalleFactory);

PlanPagosDetalleFactory.$inject = ['$resource', 'baseurl'];

function PlanPagosDetalleFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var PlanPago = $resource( baseurl.getBaseUrl() + "/planpagosdetalle/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'base';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'base';
    }
    return PlanPago.query(params);
  }

  function get(id, view) {
    return PlanPago.get({id: id, view: view!=undefined ? view : 'base'});
  }

  function create(attrs) {
    return new PlanPago(attrs);
  }

  function save(planPago) {
    return (planPago.id) ? planPago.$update() : planPago.$save();
  }

  function remove(planPago) {
    return planPago.$remove();
  }
}

angular
    .module("qualita.venta", ["ngResource", "ui.tree", "ngFileUpload"])
    .config([
        "$stateProvider",
        function ($stateProvider) {
            $stateProvider
                .state("app.cajas", {
                    url: "/cajas",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Caja"
                    }
                })
                .state("app.cajas.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "CajaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cajas",
                        label: "Cajas",
                        description: "Listado de Cajas",
                        skip: true
                    }
                })
                .state("app.cajas.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/caja/form.html",
                    controller: "CajaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cajas",
                        label: "Nueva Caja",
                        description: "Creación de una nueva caja"
                    },
                    resolve: {
                        cajaPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.cajas.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/caja/form.html",
                    controller: "CajaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cajas",
                        label: "Editar Caja",
                        description: "Edición de la Caja #"
                    },
                    resolve: {
                        cajaPrepService: cajaPrepService
                    }
                })
                .state("app.cajas.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/caja/form.html",
                    controller: "CajaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cajas",
                        label: "Ver Caja",
                        description: "Ver Caja #"
                    },
                    resolve: {
                        cajaPrepService: cajaPrepService
                    }
                })
                .state('app.tiposclientes', {
                    url: '/tiposclientes',
                    templateUrl: 'views/template_base/index.html',
                    abstract: true,
                    ncyBreadcrumb: {
                        label: 'Tipos de Lista de Precios'
                    }
                })
                .state('app.tiposclientes.list', {
                    url: '',
                    templateUrl: 'views/template_base/list.html',
                    controller: 'TipoClientesIndexCtrl',
                    controllerAs: 'vm',
                    ncyBreadcrumb: {
                        parent: 'app.tiposclientes',
                        label: 'Tipos de Clientes',
                        description: 'Listado de Tipos de Clientes',
                        skip: true
                    }
                })
                .state('app.tiposclientes.new', {
                    url: '/new',
                    templateUrl: 'qualita-components/ventas/views/tipocliente/form.html',
                    controller: 'TipoClienteFormCtrl',
                    controllerAs: 'vm',
                    ncyBreadcrumb: {
                        parent: 'app.tiposclientes',
                        label: 'Nuevo Tipo de Lista de Precios',
                        description: 'Creación de un nuevo tipo de c;oemte'
                    },
                    resolve: {
                        tipoClientePrepService: function () { return; }
                    }
                })
                .state('app.tiposclientes.edit', {
                    url: '/:id/edit',
                    templateUrl: 'qualita-components/ventas/views/tipocliente/form.html',
                    controller: 'TipoClienteFormCtrl',
                    controllerAs: 'vm',
                    ncyBreadcrumb: {
                        parent: 'app.tiposclientes',
                        label: 'Editar Tipo de Lista de Precios',
                        description: 'Edición del tipo de clinete #'
                    },
                    resolve: {
                        tipoClientePrepService: tipoClientePrepService
                    }
                })
                .state('app.tiposclientes.view', {
                    url: '/:id',
                    templateUrl: 'qualita-components/ventas/views/tipocliente/form.html',
                    controller: 'TipoClienteFormCtrl',
                    controllerAs: 'vm',
                    ncyBreadcrumb: {
                        parent: 'app.tiposclientes',
                        label: 'Ver Tipo de Lista de Precios',
                        description: 'Ver tipo de cliente #'
                    },
                    resolve: {
                        tipoClientePrepService: tipoClientePrepService
                    }
                })
                .state("app.clientes", {
                    url: "/clientes",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Cliente"
                    }
                })
                .state("app.clientes.carga", {
                    url: "/carga",
                    templateUrl: "qualita-components/ventas/views/cliente/carga.html",
                    controller: "ClienteCargaCtrl",
                    ncyBreadcrumb: {
                        label: "Importar archivo",
                        description: "Importar archivo de clientes"
                    }
                })
                .state("app.clientes.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ClienteIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.clientes",
                        label: "Clientes",
                        description: "Listado de Clientes",
                        skip: true
                    }
                })
                .state("app.clientes.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/cliente/form.html",
                    controller: "ClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.clientes",
                        label: "Nuevo Cliente",
                        description: "Creación de un nuevo cliente"
                    },
                    resolve: {
                        clientePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.clientes.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/cliente/form.html",
                    controller: "ClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.clientes",
                        label: "Editar Cliente",
                        description: "Edición del cliente #"
                    },
                    resolve: {
                        clientePrepService: clientePrepService
                    }
                })
                .state("app.clientes.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/cliente/form.html",
                    controller: "ClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.clientes",
                        label: "Ver Cliente",
                        description: "Ver Cliente #"
                    },
                    resolve: {
                        clientePrepService: clientePrepService
                    }
                })
                .state('app.comprobantespendientesclientes', {
                    url: '/comprobantespendientesclientes',
                    templateUrl: 'views/template_base/index.html',
                    abstract: true,
                    ncyBreadcrumb: {
                      label: 'Consulta de Comprobantes Pendientes'
                    }
                })
                .state('app.comprobantespendientesclientes.list', {
                    url: '',
                    templateUrl: 'qualita-components/ventas/views/comprobantespendientes/consulta.html',
                    controller: 'ComprobantePendienteClienteIndexCtrl',
                    controllerAs: 'vm',
                    ncyBreadcrumb: {
                      parent: 'app.comprobantespendientesclientes',
                      label: 'Consulta de Comprobantes Pendientes',
                      description: 'Consulta de Comprobantes Pendientes',
                      skip: true
                    }
                  })
                .state("app.puntosventa", {
                    url: "/puntosventa",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Puntos de Venta"
                    }
                })
                .state("app.puntosventa.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "PuntoVentaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.puntosventa",
                        label: "Puntos de Venta",
                        description: "Listado de Puntos de Venta",
                        skip: true
                    }
                })
                .state("app.puntosventa.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/puntoventa/form.html",
                    controller: "PuntoVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.puntosventa",
                        label: "Nuevo Punto de Venta",
                        description: "Creación de un Punto de Venta"
                    },
                    resolve: {
                        puntoVentaPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.puntosventa.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/puntoventa/form.html",
                    controller: "PuntoVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.puntosventa",
                        label: "Editar Punto de Venta",
                        description: "Edición del Punto de Venta"
                    },
                    resolve: {
                        puntoVentaPrepService: puntoVentaPrepService
                    }
                })
                .state("app.puntosventa.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/puntoventa/form.html",
                    controller: "PuntoVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.puntosventa",
                        label: "Ver Punto de Venta",
                        description: "Ver Punto de Venta"
                    },
                    resolve: {
                        puntoVentaPrepService: puntoVentaPrepService
                    }
                })
                .state("app.consultacomprobantesventa", {
                    url: "/comprobantesventa",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Consulta de Comprobantes de Venta"
                    }
                })
                .state("app.comprobanteventaagil", {
                    url: "/comprobanteventaagil",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Consulta de Comprobantes de Venta"
                    }
                })
                .state("app.consultacomprobantesventa.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ComprobanteVentaListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.consultacomprobantesventa",
                        label: "Consulta de Comprobantes",
                        description: "Listado de Comprobantes",
                        skip: true
                    }
                })
                .state("app.consultacomprobantesventa.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/comprobante/form.html",
                    controller: "ComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.consultacomprobantesventa",
                        label: "Nuevo Comprobante de Venta",
                        description: "Creación de un nuevo comprobante de Venta"
                    },
                    resolve: {
                        comprobantePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.comprobanteventaagil.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/comprobante/form-agil.html",
                    controller: "ComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.consultacomprobantesventa",
                        label: "Nuevo Comprobante de Venta Ágil",
                        description: "Creación de un nuevo comprobante de Venta"
                    },
                    resolve: {
                        comprobantePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.consultacomprobantesventa.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/comprobante/form.html",
                    controller: "ComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.consultacomprobantesventa",
                        label: "Editar Comprobante de Venta",
                        description: "Edición del comprobante de Venta #"
                    },
                    resolve: {
                        comprobantePrepService: comprobanteVentaPrepService
                    }
                })
                .state("app.consultacomprobantesventa.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/comprobante/form.html",
                    controller: "ComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.consultacomprobantesventa",
                        label: "Ver Comprobante de Venta",
                        description: "Ver Comprobante de Venta #"
                    },
                    resolve: {
                        comprobantePrepService: comprobanteVentaPrepService
                    }
                })
                .state("app.consultacomprobantesventa.applyadd", {
                    url: "/:id/apply/add?mode",
                    templateUrl: "qualita-components/ventas/views/comprobante/aplicacion.html",
                    controller: "AplicacionComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.consultacomprobantesventa",
                        label: "Nueva Aplicacion"
                    },
                    resolve: {
                        comprobantePrepService: comprobanteVentaAplicarPrepService
                    }
                })
                .state("app.consultacomprobantesventa.applyedit", {
                    url: "/:id/apply/edit?mode",
                    templateUrl: "qualita-components/ventas/views/comprobante/aplicacion.html",
                    controller: "AplicacionComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.consultacomprobantesventa",
                        label: "Editar Aplicacion"
                    },
                    resolve: {
                        comprobantePrepService: comprobanteVentaAplicarPrepService
                    }
                })
                .state("app.tiposventas", {
                    url: "/tipoventa",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Tipos de Venta"
                    }
                })
                .state("app.tiposventas.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "TipoVentaListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tiposventas",
                        label: "Tipo de Venta",
                        description: "Listado de Tipos de Venta",
                        skip: true
                    }
                })
                .state("app.tiposventas.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/tipoventa/form.html",
                    controller: "TipoVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tiposventas",
                        label: "Nuevo Tipo de Venta",
                        description: "Creación de un nuevo Tipo Venta"
                    },
                    resolve: {
                        tipoVentaPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.tiposventas.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/tipoventa/form.html",
                    controller: "TipoVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tiposventas",
                        label: "Editar Tipo de Venta",
                        description: "Edición del Tipo de Venta #"
                    },
                    resolve: {
                        tipoVentaPrepService: tipoVentaPrepService
                    }
                })
                .state("app.tiposventas.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/tipoventa/form.html",
                    controller: "TipoVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tiposventas",
                        label: "Ver Tipo de Venta",
                        description: "Ver Tipo de Venta #"
                    },
                    resolve: {
                        tipoVentaPrepService: tipoVentaPrepService
                    }
                })
                .state("app.ventas", {
                    url: "/ventas",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Ventas"
                    }
                })
                .state("app.ventas.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "VentaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas",
                        label: "Ventas",
                        description: "Listado de Ventas",
                        skip: true
                    }
                })
                .state("app.ventas.proceso", {
                    url: "/proceso/:codigoVenta",
                    templateUrl: "qualita-components/ventas/views/venta/wizard.html",
                    controller: "VentaProcesoCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas",
                        label: "Proceso"
                    },
                    resolve: {
                        ventaPrepService: ventaPrepService
                    }
                })
                .state("app.ventas.proceso.pedidosventa", {
                    url: "/pedidosventa",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso",
                        label: "Pedidos de Venta"
                    }
                })
                .state("app.ventas.proceso.pedidosventa.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "PedidosVentaListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.pedidosventa",
                        label: "Pedidos de Venta",
                        skip: true
                    }
                })
                .state("app.ventas.proceso.pedidosventa.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/pedidoventa/form.html",
                    controller: "PedidoVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.pedidosventa",
                        label: "Nuevo Pedido de Venta"
                    },
                    resolve: {
                        pedidoVentaPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.ventas.proceso.pedidosventa.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/pedidoventa/form.html",
                    controller: "PedidoVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.pedidosventa",
                        label: "Editar Pedido de Venta"
                    },
                    resolve: {
                        pedidoVentaPrepService: pedidoVentaPrepService
                    }
                })
                .state("app.ventas.proceso.pedidosventa.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/pedidoventa/form.html",
                    controller: "PedidoVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.pedidosventa",
                        label: "Ver Pedido de Venta"
                    },
                    resolve: {
                        pedidoVentaPrepService: pedidoVentaPrepService
                    }
                })
                .state("app.ventas.proceso.comprobanteventa", {
                    url: "/comprobantes",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso",
                        label: "Comprobantes"
                    }
                })
                .state("app.ventas.proceso.comprobanteventa.list", {
                    url: "/mode?modeAccess",
                    templateUrl: "views/template_base/list.html",
                    controller: "ComprobanteVentaListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.comprobanteventa",
                        label: "Comprobantes",
                        skip: true
                    }
                })
                .state("app.ventas.proceso.comprobanteventa.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/comprobante/form.html",
                    controller: "ComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.comprobanteventa",
                        label: "Nuevo Comprobante"
                    },
                    resolve: {
                        comprobantePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.ventas.proceso.comprobanteventa.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/comprobante/form.html",
                    controller: "ComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.comprobanteventa",
                        label: "Editar Comprobante"
                    },
                    resolve: {
                        comprobantePrepService: comprobanteVentaPrepService
                    }
                })
                .state("app.ventas.proceso.comprobanteventa.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/comprobante/form.html",
                    controller: "ComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.comprobanteventa",
                        label: "Ver Comprobante"
                    },
                    resolve: {
                        comprobantePrepService: comprobanteVentaPrepService
                    }
                })
                .state("app.ventas.proceso.comprobanteventa.apply", {
                    url: "/:id/apply?mode",
                    templateUrl: "qualita-components/ventas/views/comprobante/aplicacion.html",
                    controller: "AplicacionComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.comprobanteventa",
                        label: "Aplicar Comprobante"
                    },
                    resolve: {
                        comprobantePrepService: comprobanteVentaAplicarPrepService
                    }
                })
                .state("app.ventas.proceso.comprobanteventa.plancobros", {
                    url: "/:idComprobante/plancobros/:idPlanCobros/cuotas",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.comprobanteventa",
                        label: "Plan de Cobros"
                    }
                })
                .state("app.ventas.proceso.comprobanteventa.plancobros.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "PlanCobrosDetalleIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.comprobanteventa.plancobros",
                        label: "Plan de Cobros",
                        skip: true
                    }
                })
                .state("app.ventas.proceso.comprobanteventa.plancobros.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/plancobrosdetalle/form.html",
                    controller: "PlanCobrosDetalleFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.comprobante.plancobros",
                        label: "Plan de Cobros"
                    },
                    resolve: {
                        planCobrosDetallePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.ventas.proceso.comprobanteventa.plancobros.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/plancobrosdetalle/form.html",
                    controller: "PlanCobrosDetalleFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.comprobante.plancobros",
                        label: "Editar Plan de Cobros"
                    },
                    resolve: {
                        planCobrosDetallePrepService: planCobrosVentasPrepService
                    }
                })
                .state("app.ventas.proceso.comprobanteventa.plancobros.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/plancobrosdetalle/form.html",
                    controller: "PlanCobrosDetalleFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.comprobante.plancobros",
                        label: "Ver Plan de Cobros"
                    },
                    resolve: {
                        planCobrosDetallePrepService: planCobrosVentasPrepService
                    }
                })
                .state("app.ventas.proceso.remision", {
                    url: "/remisiones",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso",
                        label: "Remisiones"
                    }
                })
                .state("app.ventas.proceso.remision.list", {
                    url: "/mode?modeAccess",
                    templateUrl: "views/template_base/list.html",
                    controller: "RemisionListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.remision",
                        label: "Remisiones",
                        skip: true
                    }
                })
                .state("app.ventas.proceso.remision.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/remision/form.html",
                    controller: "RemisionFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.remision",
                        label: "Nueva Remisión"
                    },
                    resolve: {
                        remisionPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.ventas.proceso.remision.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/remision/form.html",
                    controller: "RemisionFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.remision",
                        label: "Editar Remisión"
                    },
                    resolve: {
                        remisionPrepService: remisionPrepService
                    }
                })
                .state("app.ventas.proceso.remision.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/remision/form.html",
                    controller: "RemisionFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.remision",
                        label: "Ver Remisión"
                    },
                    resolve: {
                        remisionPrepService: remisionPrepService
                    }
                })
                .state("app.ventas.proceso.cierre", {
                    url: "/cierre",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso",
                        label: "Cierre"
                    }
                })
                .state("app.ventas.proceso.cierre.list", {
                    url: "",
                    templateUrl: "qualita-components/ventas/views/venta/cierre.html",
                    controller: "ConsultaDiferenciaVentaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ventas.proceso.cierre",
                        label: "Cierre",
                        description: "Cierre",
                        skip: true
                    }
                })
                .state("app.procesoscobro", {
                    url: "/procesoscobro",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Procesos de Cobro"
                    }
                })
                .state("app.procesoscobro.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ProcesoCobroIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro",
                        label: "Procesos de Cobro",
                        description: "Listado de Procesos de Cobro",
                        skip: true
                    }
                })
                .state("app.procesoscobro.proceso", {
                    url: "/proceso/:codigoProcesoCobro",
                    templateUrl: "qualita-components/ventas/views/procesocobro/wizard.html",
                    controller: "ProcesoCobroProcesoCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro",
                        label: "Proceso"
                    },
                    resolve: {
                        procesoCobroPrepService: procesoCobroPrepService
                    }
                })
                .state("app.procesoscobro.verproceso", {
                    url: "/verproceso/:codigoProcesoCobro",
                    templateUrl: "qualita-components/ventas/views/procesocobro/wizardview.html",
                    controller: "ProcesoCobroProcesoCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro",
                        label: "Proceso"
                    },
                    resolve: {
                        procesoCobroPrepService: procesoCobroPrepService
                    }
                })
                .state("app.procesoscobro.proceso.cobro", {
                    url: "/cobro",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.proceso",
                        label: "Cobro de Factura"
                    }
                })
                .state("app.procesoscobro.verproceso.cobro", {
                    url: "/cobro",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.verproceso",
                        label: "Cobro de Factura"
                    }
                })
                .state("app.procesoscobro.proceso.cobro.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "CobrosListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.proceso.cobro",
                        label: "Cobro de Factura",
                        skip: true
                    }
                })
                .state("app.procesoscobro.verproceso.cobro.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "CobrosListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.verproceso.cobro",
                        label: "Cobro de Factura",
                        skip: true
                    }
                })
                .state("app.procesoscobro.proceso.cobro.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/cobro/form.html",
                    controller: "CobrosFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.proceso.cobro",
                        label: "Nuevo Cobro de Factura"
                    },
                    resolve: {
                        cobroPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.procesoscobro.proceso.cobro.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/cobro/form.html",
                    controller: "CobrosFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.proceso.cobro",
                        label: "Editar Cobro de Factura"
                    },
                    resolve: {
                        cobroPrepService: cobroPrepService
                    }
                })
                .state("app.procesoscobro.proceso.cobro.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/cobro/form.html",
                    controller: "CobrosFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.proceso.cobro",
                        label: "Ver Procesos de Cobro"
                    },
                    resolve: {
                        cobroPrepService: cobroPrepService
                    }
                })
                .state("app.procesoscobro.verproceso.cobro.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/cobro/form.html",
                    controller: "CobrosFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.verproceso.cobro",
                        label: "Ver Procesos de Cobro"
                    },
                    resolve: {
                        cobroPrepService: cobroPrepService
                    }
                })
                .state("app.procesoscobro.proceso.depositocobranza", {
                    url: "/deposito",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.proceso",
                        label: "Depósito de Cobranza"
                    }
                })
                .state("app.procesoscobro.verproceso.depositocobranza", {
                    url: "/deposito",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.verproceso",
                        label: "Depósito de Cobranza"
                    }
                })
                .state("app.procesoscobro.proceso.depositocobranza.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "MovimientoBancarioIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.proceso.deposito",
                        label: "Depósito de Cobranza",
                        skip: true
                    }
                })
                .state("app.procesoscobro.verproceso.depositocobranza.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "MovimientoBancarioIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.verproceso.depositocobranza",
                        label: "Depósito de Cobranza",
                        skip: true
                    }
                })
                .state("app.procesoscobro.proceso.depositocobranza.new", {
                    url: "/new",
                    templateUrl: "qualita-components/tesoreria/views/movimientobancario/form.html",
                    controller: "MovimientoBancarioFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.proceso.deposito",
                        label: "Nuevo Depósito de Cobranza"
                    },
                    resolve: {
                        movimientoBancarioPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.procesoscobro.proceso.depositocobranza.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/tesoreria/views/movimientobancario/form.html",
                    controller: "MovimientoBancarioFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.proceso.deposito",
                        label: "Editar Depósito de Cobranza"
                    },
                    resolve: {
                        // depositoPrepService: depositoPrepService
                        movimientoBancarioPrepService: movimientoBancarioPrepService
                    }
                })
                .state("app.procesoscobro.proceso.depositocobranza.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/tesoreria/views/movimientobancario/form.html",
                    controller: "MovimientoBancarioFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.proceso.deposito",
                        label: "Ver Depósito de Cobranza"
                    },
                    resolve: {
                        // depositoPrepService: depositoPrepService
                        movimientoBancarioPrepService: movimientoBancarioPrepService
                    }
                })
                .state("app.procesoscobro.verproceso.depositocobranza.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/tesoreria/views/movimientobancario/form.html",
                    controller: "MovimientoBancarioFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.verproceso.depositocobranza",
                        label: "Ver Depósito de Cobranza"
                    },
                    resolve: {
                        // depositoPrepService: depositoPrepService
                        movimientoBancarioPrepService: movimientoBancarioPrepService
                    }
                })
                .state("app.procesoscobro.proceso.cierre", {
                    url: "/cierre",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro",
                        label: "Cierre de Caja"
                    }
                })
                .state("app.procesoscobro.verproceso.cierre", {
                    url: "/cierre",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.verproceso",
                        label: "Cierre de Caja"
                    }
                })
                .state("app.procesoscobro.proceso.cierre.list", {
                    url: "",
                    templateUrl: "qualita-components/ventas/views/procesocobro/cierre.html",
                    controller: "CierreCobroIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.proceso.cierre",
                        label: "Cierre",
                        description: "Cierre",
                        skip: true
                    }
                })
                .state("app.procesoscobro.verproceso.cierre.list", {
                    url: "",
                    templateUrl: "qualita-components/ventas/views/procesocobro/cierre.html",
                    controller: "CierreCobroIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.procesoscobro.verproceso.cierre",
                        label: "Cierre",
                        description: "Cierre",
                        skip: true
                    }
                })
                .state("app.pedidoventas", {
                    url: "/pedidoventas",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Pedido Venta"
                    }
                })
                .state("app.pedidoventas.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "PedidosVentaListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.pedidoventas",
                        label: "Pedido Venta",
                        description: "Listado de Pedido Venta",
                        skip: true
                    }
                })
                .state("app.pedidoventas.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/pedidoventa/form.html",
                    controller: "PedidoVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.pedidoventas",
                        label: "Nuevo Pedido Venta",
                        description: "Creación de un nuevo pedido venta"
                    },
                    resolve: {
                        pedidoVentaPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.pedidoventas.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/pedidoventa/form.html",
                    controller: "PedidoVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.pedidoventas",
                        label: "Editar Pedido Venta",
                        description: "Edición del pedido venta #"
                    },
                    resolve: {
                        pedidoVentaPrepService: pedidoVentaPrepService
                    }
                })
                .state("app.pedidoventas.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/pedidoventa/form.html",
                    controller: "PedidoVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.pedidoventas",
                        label: "Ver Pedido Venta",
                        description: "Ver Pedido Venta #"
                    },
                    resolve: {
                        pedidoVentaPrepService: pedidoVentaPrepService
                    }
                })
                .state("app.presupuestoventas", {
                    url: "/presupuestoventas",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Presupuesto de Ventas"
                    }
                })
                .state("app.presupuestoventas.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "PresupuestoVentaListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.presupuestoventas",
                        label: "Presupuesto de Ventas",
                        skip: true
                    }
                })
                .state("app.presupuestoventas.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/presupuestoventa/form.html",
                    controller: "PresupuestoVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.presupuestoventas",
                        label: "Nuevo Presupuesto de Venta"
                    },
                    resolve: {
                        presupuestoVentaPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.presupuestoventas.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/presupuestoventa/form.html",
                    controller: "PresupuestoVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.presupuestoventas",
                        label: "Editar Presupuesto de Venta"
                    },
                    resolve: {
                        presupuestoVentaPrepService: presupuestoVentaPrepService
                    }
                })
                .state("app.presupuestoventas.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/presupuestoventa/form.html",
                    controller: "PresupuestoVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.presupuestoventas",
                        label: "Ver Presupuesto de Venta"
                    },
                    resolve: {
                        presupuestoVentaPrepService: presupuestoVentaPrepService
                    }
                })
                .state("app.plancobros", {
                    url: "/plancobros",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Cuentas a Cobrar"
                    }
                })
                .state("app.plancobros.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "PlanCobrosIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.plancobros",
                        label: "Cuentas a Cobrar",
                        description: "Listado de Cuentas a Cobrar",
                        skip: true
                    }
                })
                .state("app.plancobros.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/plancobros/form.html",
                    controller: "PlanCobrosFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.plancobros",
                        label: "Editar Plan de Cobros"
                    },
                    resolve: {
                        planCobrosPrepService: planCobrosPrepService
                    }
                })
                .state("app.plancobros.detalles", {
                    url: "/:idPlanCobros/cuotas",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Cuotas"
                    }
                })
                .state("app.plancobros.detalles.list", {
                    url: "",
                    templateUrl: "qualita-components/ventas/views/plancobrosdetalle/list.html",
                    controller: "PlanCobrosDetalleIndexCtrl",
                    ncyBreadcrumb: {
                        parent: "app.plancobros",
                        label: "Ver Cuotas",
                        description: "Ver cuotas del plan de cobros #"
                    }
                })
                .state("app.plancobros.detalles.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/plancobrosdetalle/form.html",
                    controller: "PlanCobrosDetalleFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.plancobros.detalles",
                        label: "Cuota"
                    },
                    resolve: {
                        planCobrosDetallePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.plancobros.detalles.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/plancobrosdetalle/form.html",
                    controller: "PlanCobrosDetalleFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.plancobros.detalles",
                        label: "Editar Cuota"
                    },
                    resolve: {
                        planCobrosDetallePrepService: planCobrosVentasPrepService
                    }
                })
                .state("app.plancobros.detalles.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/plancobrosdetalle/form.html",
                    controller: "PlanCobrosDetalleFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.plancobros.detalles",
                        label: "Ver Cuota"
                    },
                    resolve: {
                        planCobrosDetallePrepService: planCobrosVentasPrepService
                    }
                })
                .state("app.estadocuentacliente", {
                    url: "/estadocuentacliente",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Estado Cuenta Clientes"
                    }
                })
                .state("app.estadocuentacliente.list", {
                    url: "",
                    templateUrl: "qualita-components/ventas/views/estadocuentacliente/consulta.html",
                    controller: "EstadoCuentaClienteIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.estadocuentacliente",
                        label: "Estado Cuenta Clientes",
                        description: "Estado Cuenta Clientes",
                        skip: true
                    }
                })
                .state("app.libroventa", {
                    url: "/libroventa",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: " Libro IVA Ventas"
                    }
                })
                .state("app.libroventa.list", {
                    url: "",
                    templateUrl:
                        "qualita-components/ventas/views/consultalibroventa/consulta.html",
                    controller: "ConsultaLibroVentaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.libroventa",
                        label: "Consulta Libro Venta",
                        description: "Generación de Reporte de Libro Venta",
                        skip: true
                    }
                })
                .state("app.cobro", {
                    url: "/cobros",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Cobro de Factura"
                    }
                })
                .state("app.cobro.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ConsultaCobrosListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cobro",
                        label: "Cobro de Factura",
                        skip: true
                    }
                })
                .state("app.vendedores", {
                    url: "/vendedores",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Vendedores / Supervisores / Cobradores / Gestores"
                    }
                })
                .state("app.vendedores.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "VendedoresIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.vendedores",
                        label: "Vendedores / Supervisores / Cobradores / Gestores",
                        skip: true
                    }
                })
                .state("app.vendedores.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/vendedor/form.html",
                    controller: "VendedorFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.vendedores.list",
                        label: "Nuevo Vendedor / Supervisor / Cobrador / Gestor"
                    },
                    resolve: {
                        vendedorPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.vendedores.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/vendedor/form.html",
                    controller: "VendedorFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.vendedores.list",
                        label: "Editar Vendedor / Supervisor / Cobrador / Gestor"
                    },
                    resolve: {
                        vendedorPrepService: vendedorPrepService
                    }
                })
                .state("app.vendedores.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/vendedor/form.html",
                    controller: "VendedorFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.vendedores.list",
                        label: "Ver Vendedor / Supervisor / Cobrador / Gestor"
                    },
                    resolve: {
                        vendedorPrepService: vendedorPrepService
                    }
                })
                .state("app.cobro.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/cobro/form.html",
                    controller: "CobrosFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cobro",
                        label: "Ver Procesos de Cobro"
                    },
                    resolve: {
                        cobroPrepService: cobroPrepService
                    }
                })
                .state("app.cobro.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/cobro/form.html",
                    controller: "CobrosFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cobro",
                        label: "Editar Procesos de Cobro"
                    },
                    resolve: {
                        cobroPrepService: cobroPrepService
                    }
                })
                .state("app.cobro.aplicaranticipo", {
                    url: "/:id/aplicar?mode",
                    templateUrl: "qualita-components/ventas/views/cobro/form.html",
                    controller: "CobrosFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cobro",
                        label: "Aplicación de Anticipo",
                        description: "Aplicación de Anticipo #"
                    },
                    resolve: {
                        cobroPrepService: cobroPrepService
                    }
                })
                .state("app.remision", {
                    url: "/remisiones",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Consulta de Remisiones"

                    }
                })
                .state("app.remision.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ConsultaRemisionListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.remision",
                        label: "Remisiones",
                        skip: true
                    }
                })
                .state("app.remision.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/remision/form.html",
                    controller: "RemisionFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.remision",
                        label: "Ver Remisión"
                    },
                    resolve: {
                        remisionPrepService: remisionPrepService
                    }
                })
                .state("app.cambioprecio", {
                    url: "/cambioprecio",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Precios de Productos"
                    }
                })
                .state("app.cambioprecio.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "CambioPrecioListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cambioprecio",
                        label: "Precios de Productos",
                        description: "Listado de Precios de Productos",
                        skip: true
                    }
                })
                .state("app.cambioprecio.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/cambioprecio/form.html",
                    controller: "CambioPrecioFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cambioprecio",
                        label: "Nuevo Precio de Producto",
                        description: "Creación de Precio de Producto"
                    },
                    resolve: {
                        cambioPrecioPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.cambioprecio.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/cambioprecio/form.html",
                    controller: "CambioPrecioFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cambioprecio",
                        label: "Editar Precio de Productos",
                        description: "Edición de Precio de Productos"
                    },
                    resolve: {
                        cambioPrecioPrepService: cambioPrecioPrepService
                    }
                })
                .state("app.cambioprecio.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/cambioprecio/form.html",
                    controller: "CambioPrecioFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cambioprecio",
                        label: "Ver Precio de Productos",
                        description: "Ver Precio de Productos"
                    },
                    resolve: {
                        cambioPrecioPrepService: cambioPrecioPrepService
                    }
                })
                .state("app.consultacambioprecio", {
                    url: "/consultacambioprecio",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Histórico de Precios de Productos"
                    }
                })
                .state("app.consultacambioprecio.list", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/consultacambioprecio/form.html",
                    controller: "ConsultaCambioPrecioIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.consultacambioprecio",
                        label: "Histórico de Precios de Productos",
                        description: "Histórico de Precios de Productos",
                        skip: true
                    }
                })
                .state("app.cambioprecioservicio", {
                    url: "/cambioprecioservicio",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Precios de Servicios"
                    }
                })
                .state("app.cambioprecioservicio.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "CambioPrecioServicioListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cambioprecioservicio",
                        label: "Precios de Servicios",
                        description: "Listado de Precios de Servicios",
                        skip: true
                    }
                })
                .state("app.cambioprecioservicio.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/cambioprecioservicio/form.html",
                    controller: "CambioPrecioServicioFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cambioprecioservicio",
                        label: "Nuevo Precio de Servicio",
                        description: "Creación Precio de Servicio"
                    },
                    resolve: {
                        cambioPrecioServicioPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.cambioprecioservicio.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/cambioprecioservicio/form.html",
                    controller: "CambioPrecioServicioFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cambioprecioservicio",
                        label: "Editar Precio de Servicio",
                        description: "Edición Precio de Servicio"
                    },
                    resolve: {
                        cambioPrecioServicioPrepService: cambioPrecioServicioPrepService
                    }
                })
                .state("app.cambioprecioservicio.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/cambioprecioservicio/form.html",
                    controller: "CambioPrecioServicioFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cambioprecioservicio",
                        label: "Ver Precio de Servicio",
                        description: "Ver Precio de Servicio"
                    },
                    resolve: {
                        cambioPrecioServicioPrepService: cambioPrecioServicioPrepService
                    }
                })
                .state("app.consultacambioprecioservicio", {
                    url: "/consultacambioprecioservicio",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Histórico de Precios de Servicios"
                    }
                })
                .state("app.consultacambioprecioservicio.list", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/consultacambioprecioservicio/form.html",
                    controller: "ConsultaCambioPrecioServicioIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.consultacambioprecioservicio",
                        label: "Histórico de Precios de Servicios",
                        description: "Histórico de Precios de Servicios",
                        skip: true
                    }
                })
                .state("app.reporteproducto", {
                    url: "/reporteproducto",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Reporte de Productos"
                    }
                })
                .state("app.reporteproducto.list", {
                    url: "",
                    templateUrl:
                        "qualita-components/ventas/views/reporteproducto/form.html",
                    controller: "ReporteProductoCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.reporteproducto",
                        label: "Reporte de Productos",
                        description: "Reporte de Productos",
                        skip: true
                    }
                })
                .state("app.reportefamiliaproducto", {
                    url: "/reportefamiliaproducto",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Reporte de Familia de Productos"
                    }
                })
                .state("app.reportefamiliaproducto.list", {
                    url: "",
                    templateUrl:
                        "qualita-components/ventas/views/reportefamiliaproducto/form.html",
                    controller: "ReporteFamiliaProductoCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.reportefamiliaproducto",
                        label: "Reporte de Familia de Productos",
                        description: "Reporte de Familia de Productos",
                        skip: true
                    }
                })

                .state("app.reportecliente", {
                    url: "/reportecliente",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Reporte por Cliente"
                    }
                })
                .state("app.reportecliente.list", {
                    url: "",
                    templateUrl: "qualita-components/ventas/views/reportecliente/form.html",
                    controller: "ReporteClienteCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.reportecliente",
                        label: "Reporte por Sucursal",
                        description: "Reporte por Sucursal",
                        skip: true
                    }
                })

                .state("app.reportesucursal", {
                    url: "/reportesucursal",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Reporte por Sucursal"
                    }
                })
                .state("app.reportesucursal.list", {
                    url: "",
                    templateUrl: "qualita-components/ventas/views/reportesucursal/form.html",
                    controller: "ReporteSucursalCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.reportesucursal",
                        label: "Reporte por Sucursal",
                        description: "Reporte por Sucursal",
                        skip: true
                    }
                }).state("app.listaprecioventa", {
                    url: "/listaprecioventa",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Lista de Precios de Ventas"
                    }
                }).state("app.listaprecioventa.carga", {
                    url: "/:id/carga",
                    templateUrl: "qualita-components/ventas/views/listaprecioventa/carga.html",
                    controller: "ProductosCargaCtrl",
                    ncyBreadcrumb: {
                        label: "Importar archivo",
                        description: "Importar archivo de productos"
                    },
                    resolve: {
                        listaPrecioVentaPrepService: listaPrecioVentaPrepService,
                        PreviousState: PreviousState
                    }
                })
                .state("app.listaprecioventa.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ListaPrecioVentaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.listaprecioventa",
                        label: "Clientes",
                        description: "Listado de Clientes",
                        skip: true
                    }
                }).state("app.listaprecioventa.new", {
                    url: "/:id/new",
                    templateUrl: "qualita-components/ventas/views/listaprecioventa/form.html",
                    controller: "ListaPrecioVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.listaprecioventa",
                        label: "Nueva Lista de Precios de Ventas",
                        description: "Creación de una nueva lista de precios de ventas"
                    },
                    resolve: {
                        listaPrecioVentaPrepService: listaPrecioVentaPrepService
                    }

                }).state("app.listaprecioventa.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/listaprecioventa/form.html",
                    controller: "ListaPrecioVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.listaprecioventa",
                        label: "Editar Lista de Precios de Ventas",
                        description: "Edición de la Lista de Precios #"
                    },
                    resolve: {
                        listaPrecioVentaPrepService: listaPrecioVentaPrepService
                    }
                }).state("app.listaprecioventa.view", {
                    url: "/:id/view",
                    templateUrl: "qualita-components/ventas/views/listaprecioventa/form.html",
                    controller: "ListaPrecioVentaFormController",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.listaprecioventa",
                        label: "Ver Lista de Precios de Ventas",
                        description: "Ver Lista de Precios de Ventas #"
                    },
                    resolve: {
                        listaPrecioVentaPrepService: listaPrecioVentaPrepService
                    }
                })
                .state("app.configuracionautorizaciones", {
                    url: "/configuracionautorizaciones",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Configuración Autorizaciones de Límite de Crédito"
                    }
                })
                .state("app.configuracionautorizaciones.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ConfiguracionAutorizacionesIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.configuracionautorizaciones",
                        label: "Configuración Autorizaciones de Límite de Crédito",
                        description: "Listado de Configuración Autorizaciones de Límite de Crédito",
                        skip: true
                    }
                })
                .state("app.configuracionautorizaciones.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/configuracion_autorizaciones/form.html",
                    controller: "ConfiguracionAutorizacionesFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.configuracionautorizaciones",
                        label: "Nueva Configuración Autorizaciones de Límite de Crédito",
                        description: "Creación de nueva Configuración Autorizaciones de Límite de Crédito"
                    },
                    resolve: {
                        configuracionAutorizacionesPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.configuracionautorizaciones.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/configuracion_autorizaciones/form.html",
                    controller: "ConfiguracionAutorizacionesFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.configuracionautorizaciones",
                        label: "Editar Configuración Autorizaciones de Límite de Crédito",
                        description: "Edición de la Configuración Autorizaciones de Límite de Crédito #"
                    },
                    resolve: {
                        configuracionAutorizacionesPrepService: configuracionAutorizacionesPrepService
                    }
                })
                .state("app.configuracionautorizaciones.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/configuracion_autorizaciones/form.html",
                    controller: "ConfiguracionAutorizacionesFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.configuracionautorizaciones",
                        label: "Ver Configuración Autorizaciones de Límite de Crédito",
                        description: "Ver Configuración Autorizaciones de Límite de Crédito #"
                    },
                    resolve: {
                        configuracionAutorizacionesPrepService: configuracionAutorizacionesPrepService
                    }
                })
                .state("app.solicitudautorizacion", {
                    url: "/solicitudautorizacion",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Solicitud de Autorización de Límite de Crédito"
                    }
                })
                .state("app.solicitudautorizacion.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "SolicitudAutorizacionIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.solicitudautorizacion",
                        label: "Solicitud de Autorización de Límite de Crédito",
                        description: "Listado de Solicitud de Autorización de Límite de Crédito",
                        skip: true
                    }
                })
                .state("app.solicitudautorizacion.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/solicitud_autorizacion/form.html",
                    controller: "SolicitudAutorizacionFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.solicitudautorizacion",
                        label: "Nueva Solicitud de Autorización de Límite de Crédito",
                        description: "Creación de un nueva Solicitud de Autorización de Límite de Crédito"
                    },
                    resolve: {
                        solicitudAutorizacionPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.solicitudautorizacion.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/solicitud_autorizacion/form.html",
                    controller: "SolicitudAutorizacionFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.solicitudautorizacion",
                        label: "Editar Solicitud de Autorización de Límite de Crédito",
                        description: "Edición de la Solicitud de Autorización de Límite de Crédito #"
                    },
                    resolve: {
                        solicitudAutorizacionPrepService: solicitudAutorizacionPrepService
                    }
                })
                .state("app.solicitudautorizacion.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/solicitud_autorizacion/form.html",
                    controller: "SolicitudAutorizacionFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.solicitudautorizacion",
                        label: "Ver Solicitud de Autorización de Límite de Crédito",
                        description: "Ver Solicitud de Autorización de Límite de Crédito #"
                    },
                    resolve: {
                        solicitudAutorizacionPrepService: solicitudAutorizacionPrepService
                    }
                })

                .state("app.tipocobro", {
                    url: "/tipocobro",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Tipo de cobro entidad"
                    }
                })
                .state("app.tipocobro.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "TipoCobroIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipocobro",
                        label: "Tipo de cobro entidad",
                        description: "Listado de tipos de cobro entidad",
                        skip: true
                    }
                })
                .state("app.tipocobro.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/tipocobro/form.html",
                    controller: "TipoCobroFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipocobro",
                        label: "Nuevo tipo de cobro entidad",
                        description: "Creación de tipo de cobro entidad"
                    },
                    resolve: {
                        tipoCobroPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.tipocobro.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/tipocobro/form.html",
                    controller: "TipoCobroFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipocobro",
                        label: "Editar tipo de cobro entidad",
                        description: "Edición del tipo de cobro entidad"
                    },
                    resolve: {
                        tipoCobroPrepService: tipoCobroPrepService
                    }
                })
                .state("app.tipocobro.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/tipocobro/form.html",
                    controller: "TipoCobroFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipocobro",
                        label: "Ver tipo de cobro entidad",
                        description: "Ver tipo de cobro entidad"
                    },
                    resolve: {
                        tipoCobroPrepService: tipoCobroPrepService
                    }
                })

                .state("app.camiones", {
                    url: "/camiones",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Camiones"
                    }
                })
                .state("app.camiones.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "CamionIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.camiones",
                        label: "Camiones",
                        description: "Listado de Camiones",
                        skip: true
                    }
                })
                .state("app.camiones.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/camion/form.html",
                    controller: "CamionesFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.camiones",
                        label: "Nuevo Camión",
                        description: "Creación de Camión"
                    },
                    resolve: {
                        camionesPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.camiones.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/camion/form.html",
                    controller: "CamionesFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.camiones",
                        label: "Editar Camión",
                        description: "Edición del Camión #"
                    },
                    resolve: {
                        camionesPrepService: camionesPrepService
                    }
                })
                .state("app.camiones.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/camion/form.html",
                    controller: "CamionesFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.camiones",
                        label: "Ver Camión",
                        description: "Ver Camión"
                    },
                    resolve: {
                        camionesPrepService: camionesPrepService
                    }
                })

                .state("app.choferes", {
                    url: "/choferes",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Choferes"
                    }
                })
                .state("app.choferes.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ChoferIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.choferes",
                        label: "Choferes",
                        description: "Listado de Choferes",
                        skip: true
                    }
                })
                .state("app.choferes.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/chofer/form.html",
                    controller: "ChoferFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.choferes",
                        label: "Nuevo Chófer",
                        description: "Creación de Chófer"
                    },
                    resolve: {
                        choferesPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.choferes.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/chofer/form.html",
                    controller: "ChoferFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.choferes",
                        label: "Editar Chófer",
                        description: "Edición del Chófer #"
                    },
                    resolve: {
                        choferesPrepService: choferesPrepService
                    }
                })
                .state("app.choferes.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/chofer/form.html",
                    controller: "ChoferFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.choferes",
                        label: "Ver Chófer",
                        description: "Ver Chófer"
                    },
                    resolve: {
                        choferesPrepService: choferesPrepService
                    }
                })

                .state("app.zonas", {
                    url: "/zonas",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Zonas"
                    }
                })
                .state("app.zonas.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ZonaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.zonas",
                        label: "Zonas",
                        description: "Listado de Zonas",
                        skip: true
                    }
                })
                .state("app.zonas.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/zona/form.html",
                    controller: "ZonaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.zonas",
                        label: "Nueva Zona",
                        description: "Creación de Zona"
                    },
                    resolve: {
                        zonasPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.zonas.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/zona/form.html",
                    controller: "ZonaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.zonas",
                        label: "Editar Zona",
                        description: "Edición de la Zona #"
                    },
                    resolve: {
                        zonasPrepService: zonasPrepService
                    }
                })
                .state("app.zonas.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/zona/form.html",
                    controller: "ZonaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.zonas",
                        label: "Ver Zona",
                        description: "Ver Zona"
                    },
                    resolve: {
                        zonasPrepService: zonasPrepService
                    }
                })

                .state("app.rutas", {
                    url: "/rutas",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Rutas"
                    }
                })
                .state("app.rutas.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "RutaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.rutas",
                        label: "Rutas",
                        description: "Listado de Rutas",
                        skip: true
                    }
                })
                .state("app.rutas.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/ruta/form.html",
                    controller: "RutaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.rutas",
                        label: "Nueva Ruta",
                        description: "Creación de Ruta"
                    },
                    resolve: {
                        rutasPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.rutas.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/ruta/form.html",
                    controller: "RutaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.rutas",
                        label: "Editar Ruta",
                        description: "Edición de la Ruta #"
                    },
                    resolve: {
                        rutasPrepService: rutasPrepService
                    }
                })
                .state("app.rutas.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/ruta/form.html",
                    controller: "RutaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.rutas",
                        label: "Ver Ruta",
                        description: "Ver Ruta"
                    },
                    resolve: {
                        rutasPrepService: rutasPrepService
                    }
                })
                .state("app.archivobalanzas", {
                    url: "/archivobalanzas",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Archivos para Balanzas"
                    }
                })
                .state("app.archivobalanzas.list", {
                    url: "",
                    templateUrl: "qualita-components/ventas/views/archivos_balanzas/form.html",
                    controller: "ArchivosBalanzasIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.archivobalanzas",
                        label: "Archivos para Balanzas",
                        description: "Generación de archivos para balanza",
                        skip: true
                    }
                })
                .state("app.planillasrepartos", {
                    url: "/planillasrepartos",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Planillas de Repartos"
                    }
                })
                .state("app.planillasrepartos.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "PlanillaRepartoIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.planillasrepartos",
                        label: "Planillas de Reparto",
                        description: "Listado de Planillas de Reparto",
                        skip: true
                    }
                })
                .state("app.planillasrepartos.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/planillareparto/form.html",
                    controller: "PlanillaRepartoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.planillasrepartos",
                        label: "Nueva Planilla de Reparto",
                        description: "Creación de Planilla de Reparto"
                    },
                    resolve: {
                        planillasRepartosPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.planillasrepartos.edit", {
                    url: "/:id/edit?editChofer",
                    templateUrl: "qualita-components/ventas/views/planillareparto/form.html",
                    controller: "PlanillaRepartoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.planillasrepartos",
                        label: "Editar Planilla de Reparto",
                        description: "Edición de la Planilla de Reparto #"
                    },
                    resolve: {
                        planillasRepartosPrepService: planillasRepartosPrepService
                    }
                })
                .state("app.planillasrepartos.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/planillareparto/form.html",
                    controller: "PlanillaRepartoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.planillasrepartos",
                        label: "Ver Planilla de Reparto",
                        description: "Ver Planilla de Reparto"
                    },
                    resolve: {
                        planillasRepartosPrepService: planillasRepartosPrepService
                    }
                })
                .state("app.referencias", {
                    url: "/referencias",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Actualización de Referencias"
                    }
                })
                .state("app.referencias.list", {
                    url: "",
                    templateUrl:
                        "qualita-components/ventas/views/referencias/form.html",
                    controller: "ReferenciasIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.referencias",
                        label: "Actualización de Referencias",
                        description: "Actualización de Referencias",
                        skip: true
                    }
                })
                .state("app.cajero", {
                    url: "/cajero",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Cajero"
                    }
                })
                .state("app.cajero.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "CajeroIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cajero",
                        label: "Cajero",
                        description: "Listado de Cajeros",
                        skip: true
                    }
                })
                .state("app.cajero.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/cajero/form.html",
                    controller: "CajeroFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cajero",
                        label: "Nuevo Cajero",
                        description: "Creación de un nuevo Cajero"
                    },
                    resolve: {
                        cajeroPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.cajero.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/cajero/form.html",
                    controller: "CajeroFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cajero",
                        label: "Editar Cajero",
                        description: "Edición del Cajero #"
                    },
                    resolve: {
                        cajeroPrepService: cajeroPrepService
                    }
                })
                .state("app.cajero.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/cajero/form.html",
                    controller: "CajeroFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.cajero",
                        label: "Ver Cajero",
                        description: "Ver Cajero #"
                    },
                    resolve: {
                        cajeroPrepService: cajeroPrepService
                    }
                })
                .state("app.impresionflejesprecios", {
                    url: "/impresionflejesprecios",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Impresión Flejes y Etiquetas de Productos"
                    }
                })
                .state("app.impresionflejesprecios.list", {
                    url: "",
                    templateUrl: "qualita-components/ventas/views/impresion_flejes_precios/form.html",
                    controller: "ImpresionFlejesPreciosIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.impresionflejesprecios",
                        label: "Impresión Flejes de Precios y Etiquetas de Productos",
                        description: "Impresión Flejes y Etiquetas de Productos",
                        skip: true
                    }
                })
                .state("app.tipoactividadramocliente", {
                    url: "/tipoactividadramocliente",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Tipo de Actividad o Ramo de Clientes"
                    }
                })
                .state("app.tipoactividadramocliente.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "TipoActividadRamoClienteIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipoactividadramocliente",
                        label: "Tipo de Actividad o Ramo de Clientes",
                        description: "Listado de Tipos de Actividades o Ramos de Clientes",
                        skip: true
                    }
                })
                .state("app.tipoactividadramocliente.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/tipoactividadramocliente/form.html",
                    controller: "TipoActividadRamoClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipoactividadramocliente",
                        label: "Nuevo Tipo de Actividad o Ramo de Cliente",
                        description: "Creación de Tipo de Actividad o Ramo de Cliente"
                    },
                    resolve: {
                        tipoActividadRamoClientePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.tipoactividadramocliente.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/tipoactividadramocliente/form.html",
                    controller: "TipoActividadRamoClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipoactividadramocliente",
                        label: "Editar Tipo de Actividad o Ramo de Cliente",
                        description: "Edición de Tipo de Actividad o Ramo de Cliente #"
                    },
                    resolve: {
                        tipoActividadRamoClientePrepService: tipoActividadRamoClientePrepService
                    }
                })
                .state("app.tipoactividadramocliente.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/tipoactividadramocliente/form.html",
                    controller: "TipoActividadRamoClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipoactividadramocliente",
                        label: "Ver Tipo de Actividad o Ramo de Cliente",
                        description: "Ver Tipo de Actividad o Ramo de Cliente"
                    },
                    resolve: {
                        tipoActividadRamoClientePrepService: tipoActividadRamoClientePrepService
                    }
                })

                .state("app.ordencarga", {
                    url: "/ordencarga",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Orden de Carga"
                    }
                })
                .state("app.ordencarga.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "OrdenCargaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ordencarga",
                        label: "Orden de Carga",
                        description: "Listado de Orden de Carga",
                        skip: true
                    }
                })
                .state("app.ordencarga.new", {
                    url: "/new/:id",
                    templateUrl: "qualita-components/ventas/views/ordencarga/form.html",
                    controller: "OrdenCargaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ordencarga",
                        label: "Nueva Orden de Carga",
                        description: "Creación de Orden de Carga"
                    },
                    resolve: {
                        ordenCargaPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.ordencarga.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/ordencarga/form.html",
                    controller: "OrdenCargaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ordencarga",
                        label: "Editar Orden de Carga",
                        description: "Edición de la Orden de Carga #"
                    },
                    resolve: {
                        ordenCargaPrepService: ordenCargaPrepService
                    }
                })
                .state("app.ordencarga.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/ordencarga/form.html",
                    controller: "OrdenCargaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.ordencarga",
                        label: "Ver Orden de Carga",
                        description: "Ver Orden de Carga"
                    },
                    resolve: {
                        ordenCargaPrepService: ordenCargaPrepService
                    }
                }).state("app.categoriacliente", {
                    url: "/categoriacliente",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Categoría de Cliente"
                    }
                })
                .state("app.categoriacliente.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "CategoriaClienteIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.categoriacliente",
                        label: "Categoría de Cliente",
                        description: "Listado de Categorías de Clientes",
                        skip: true
                    }
                })
                .state("app.categoriacliente.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/categoriacliente/form.html",
                    controller: "CategoriaClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.categoriacliente",
                        label: "Nueva Categoria de Cliente",
                        description: "Creación de Categoría de Cliente"
                    },
                    resolve: {
                        categoriaClientePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.categoriacliente.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/categoriacliente/form.html",
                    controller: "CategoriaClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.categoriacliente",
                        label: "Editar Categoría de Cliente",
                        description: "Edición de Categoría de Cliente #"
                    },
                    resolve: {
                        categoriaClientePrepService: categoriaClientePrepService
                    }
                })
                .state("app.categoriacliente.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/categoriacliente/form.html",
                    controller: "CategoriaClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.categoriacliente",
                        label: "Ver Categoría de Cliente",
                        description: "Ver Categoría de Cliente"
                    },
                    resolve: {
                        categoriaClientePrepService: categoriaClientePrepService
                    }
                })
                .state("app.comprobantesventaplanilla", {
                    url: "/consultacomprobantesplanilla",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Consulta comprobantes por planilla"
                    }
                })
                .state("app.comprobantesventaplanilla.list", {
                    url: "",
                    templateUrl:
                        "qualita-components/ventas/views/consultaporplanilla/form.html",
                    controller: "ConsultaPlanillaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.comprobantesventaplanilla",
                        label: "Consulta comprobantes por planilla",
                        description: "Consulta comprobantes por planilla",
                        skip: true
                    }
                })
                .state("app.planillacaja", {
                    url: "/planillacaja",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Reporte de Planilla Caja"
                    }
                })
                .state("app.planillacaja.list", {
                    url: "",
                    templateUrl: "qualita-components/ventas/views/planillacaja/form.html",
                    controller: "PlanillaCajaCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.planillacaja",
                        label: "Reporte de Planilla Caja",
                        description: "Reporte de Planilla Caja",
                        skip: true
                    }
                })

                .state("app.segmentos", {
                    url: "/segmentocliente",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Segmento de cliente"
                    }
                })
                .state("app.segmentos.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "SegmentoClienteIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.segmentos",
                        label: "Segmento de cliente",
                        description: "Listado de segmento de cliente",
                        skip: true
                    }
                })
                .state("app.segmentos.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/segmentocliente/form.html",
                    controller: "SegmentoClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.segmentos",
                        label: "Nuevo Segmento de cliente",
                        description: "Creación de segmento de cliente"
                    },
                    resolve: {
                        segmentoClientePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.segmentos.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/segmentocliente/form.html",
                    controller: "SegmentoClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.segmentos",
                        label: "Editar segmento de cliente",
                        description: "Edición de segmento de cliente #"
                    },
                    resolve: {
                        segmentoClientePrepService: segmentoClientePrepService
                    }
                })
                .state("app.segmentos.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/segmentocliente/form.html",
                    controller: "SegmentoClienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.segmentos",
                        label: "Ver segmento de cliente",
                        description: "Ver segmento de cliente"
                    },
                    resolve: {
                        segmentoClientePrepService: segmentoClientePrepService
                    }
                })
                .state("app.comprobantediibit", {
                    url: "/comprobantediibit",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Consulta de Comprobantes Diibit"
                    }
                })
                .state("app.comprobantediibit.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ComprobanteDiibitListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.comprobantediibit",
                        label: "Consulta de Comprobantes Diibit",
                        description: "Listado de Comprobantes Diibit",
                        skip: true
                    }
                })
                .state("app.conceptomovimiento", {
                    url: "/conceptomovimiento",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Definición de Conceptos de Movimientos"
                    }
                })
                .state("app.conceptomovimiento.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ConceptoMovimientoIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.conceptomovimiento",
                        label: "Definición de Conceptos de Movimientos",
                        description: "Listado de Definición de Conceptos de Movimientos",
                        skip: true
                    }
                })
                .state("app.conceptomovimiento.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/conceptomovimiento/form.html",
                    controller: "ConceptoMovimientoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.conceptomovimiento",
                        label: "Nueva Definición de Conceptos de Movimientos",
                        description: "Creación de nueva Definición de Conceptos de Movimientos"
                    },
                    resolve: {
                        conceptoMovimientoPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.conceptomovimiento.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/conceptomovimiento/form.html",
                    controller: "ConceptoMovimientoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.conceptomovimiento",
                        label: "Editar Definición de Conceptos de Movimientos",
                        description: "Edición de la Definición de Conceptos de Movimientos #"
                    },
                    resolve: {
                        conceptoMovimientoPrepService: conceptoMovimientoPrepService
                    }
                })
                .state("app.conceptomovimiento.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/conceptomovimiento/form.html",
                    controller: "ConceptoMovimientoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.conceptomovimiento",
                        label: "Ver Definición de Conceptos de Movimientos",
                        description: "Ver Definición de Conceptos de Movimientos #"
                    },
                    resolve: {
                        conceptoMovimientoPrepService: conceptoMovimientoPrepService
                    }
                })
                .state("app.contribuyentes", {
                    url: "/contribuyentes",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Contribuyentes"
                    }
                })
                .state("app.contribuyentes.carga", {
                    url: "/carga",
                    templateUrl: "qualita-components/ventas/views/contribuyente/carga.html",
                    controller: "ContribuyenteCargaCtrl",
                    ncyBreadcrumb: {
                        label: "Importar archivo",
                        description: "Importar archivo de contribuyente"
                    }
                })
                .state("app.contribuyentes.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ContribuyenteIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.contribuyentes",
                        label: "Contribuyente",
                        description: "Listado de Contribuyentes",
                        skip: true
                    }
                })
                .state("app.contribuyentes.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/contribuyente/form.html",
                    controller: "ContribuyenteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.contribuyentes",
                        label: "Nuevo Contribuyente",
                        description: "Creación de un nuevo Contribuyente"
                    },
                    resolve: {
                        contribuyentePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.contribuyentes.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/contribuyente/form.html",
                    controller: "ContribuyenteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.contribuyentes",
                        label: "Editar Contribuyente",
                        description: "Edición del contribuyente #"
                    },
                    resolve: {
                        contribuyentePrepService: contribuyentePrepService
                    }
                })
                .state("app.contribuyentes.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/contribuyente/form.html",
                    controller: "ContribuyenteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.contribuyentes",
                        label: "Ver Contribuyente",
                        description: "Ver Contribuyente #"
                    },
                    resolve: {
                        contribuyentePrepService: contribuyentePrepService
                    }
                })

                .state("app.tipopresupuesto", {
                    url: "/tipopresupuesto",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Tipo de Presupuesto de Venta"
                    }
                })
                .state("app.tipopresupuesto.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "TipoPresupuestoIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipopresupuesto",
                        label: "Tipo de Presupuesto de Venta",
                        description: "Listado de Tipo de Presupuesto de Venta",
                        skip: true
                    }
                })
                .state("app.tipopresupuesto.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/tipopresupuesto/form.html",
                    controller: "TipoPresupuestoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipopresupuesto",
                        label: "Nuevo Tipo de Presupuesto de Venta",
                        description: "Creación de un nuevo Tipo de Presupuesto de Venta"
                    },
                    resolve: {
                        tipoPresupuestoPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.tipopresupuesto.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/tipopresupuesto/form.html",
                    controller: "TipoPresupuestoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipopresupuesto",
                        label: "Editar Tipo de Presupuesto de Venta",
                        description: "Edición del tipo de Presupuesto de Venta #"
                    },
                    resolve: {
                        tipoPresupuestoPrepService: tipoPresupuestoPrepService
                    }
                })
                .state("app.tipopresupuesto.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/tipopresupuesto/form.html",
                    controller: "TipoPresupuestoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.tipopresupuesto",
                        label: "Ver Tipo de Presupuesto de Venta",
                        description: "Ver Tipo de Presupuesto de Venta #"
                    },
                    resolve: {
                        tipoPresupuestoPrepService: tipoPresupuestoPrepService
                    }
                })
                .state("app.denominacionmoneda", {
                    url: "/denominacionmoneda",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Denominacion Moneda"
                    }
                })
                .state("app.denominacionmoneda.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "DenominacionMonedaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.denominacionmoneda",
                        label: "Denominacion Moneda",
                        description: "Listado de Denominaciones de Moneda",
                        skip: true
                    }
                })
                .state("app.denominacionmoneda.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/denominacionmoneda/form.html",
                    controller: "DenominacionMonedaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.denominacionmoneda",
                        label: "Nueva Denominacion de Moneda",
                        description: "Creación de una Denominacion de moneda"
                    },
                    resolve: {
                        denominacionMonedaPrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.denominacionmoneda.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/denominacionmoneda/form.html",
                    controller: "DenominacionMonedaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.denominacionmoneda",
                        label: "Editar Denominacion Moneda",
                        description: "Edición de la Denominacion de Moneda"
                    },
                    resolve: {
                        denominacionMonedaPrepService: denominacionMonedaPrepService
                    }
                })
                .state("app.denominacionmoneda.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/denominacionmoneda/form.html",
                    controller: "DenominacionMonedaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.denominacionmoneda",
                        label: "Ver Denominacion Moneda",
                        description: "Ver Denominacion Moneda"
                    },
                    resolve: {
                        denominacionMonedaPrepService: denominacionMonedaPrepService
                    }
                })
                .state("app.asignacionmoneda", {
                    url: "/asignacionmoneda",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Oden de Asignacion de Monedas"
                    }
                })
                .state("app.asignacionmoneda.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "AsignacionMonedaIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.asignacionmoneda",
                        label: "Orden de Asignacion de Monedas",
                        description: "Listado de Asignacion de Monedas",
                        skip: true
                    }
                })
                .state("app.asignacionmoneda.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/asignacionmoneda/form.html",
                    controller: "AsignacionMonedaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.asignacionmoneda",
                        label: "Nuevo Orden de Asignacion de Monedas",
                        description: "Creación de orden de Monedas"
                    },
                    resolve: {
                        asignacionMonedaPrepService: function () {
                            return;
                        }
                    }

                })
                .state("app.asignacionmoneda.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/asignacionmoneda/form.html",
                    controller: "AsignacionMonedaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.asignacionmoneda",
                        label: "Editar Orden de Asignacion de Monedas",
                        description: "Edición de Asignacion de Monedas"
                    },
                    resolve: {
                        asignacionMonedaPrepService: asignacionMonedaPrepService
                    }
                })
                .state("app.asignacionmoneda.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/asignacionmoneda/form.html",
                    controller: "AsignacionMonedaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.asignacionmoneda",
                        label: "Ver Asignacion de Monedas",
                        description: "Ver Asignacion de Monedas"
                    },
                    resolve: {
                        asignacionMonedaPrepService: asignacionMonedaPrepService
                    }
                })
                .state("app.conceptoarqueo", {
                    url: "/conceptoarqueo",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Definición de Concepto de Arqueo"
                    }
                })
                .state("app.conceptoarqueo.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ConceptoArqueoIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.conceptoarqueo",
                        label: "Definición de Conceptos de Arqueo",
                        description: "Listado de Definiciones de Concepto de Arqueo",
                        skip: true
                    }
                })
                .state("app.conceptoarqueo.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/conceptoarqueo/form.html",
                    controller: "ConceptoArqueoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.conceptoarqueo",
                        label: "Nueva Definición de Concepto de Arqueo",
                        description: "Creación de Definición de Concepto de Arqueo"
                    },
                    resolve: {
                        conceptoArqueoPrepService: function () {
                            return;
                        }
                    }

                })
                .state("app.conceptoarqueo.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/conceptoarqueo/form.html",
                    controller: "ConceptoArqueoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.conceptoarqueo",
                        label: "Editar Definicion de Concepto de Arqueo",
                        description: "Edición de Definiciones de Concepto de Arqueo"
                    },
                    resolve: {
                        conceptoArqueoPrepService: conceptoArqueoPrepService
                    }
                })
                .state("app.conceptoarqueo.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/conceptoarqueo/form.html",
                    controller: "ConceptoArqueoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.conceptoarqueo",
                        label: "Ver Definición de Concepto de Arqueo",
                        description: "Ver Definición de Concepto de Arqueo"
                    },
                    resolve: {
                        conceptoArqueoPrepService: conceptoArqueoPrepService
                    }
                })
                .state("app.arqueocajaventa", {
                  url: "/arqueocajaventa",
                  templateUrl: "views/template_base/index.html",
                  abstract: true,
                  ncyBreadcrumb: {
                      label: "Arqueo Caja"
                  }
              })
              .state("app.arqueocajaventa.list", {
                  url: "",
                  templateUrl: "views/template_base/list.html",
                  controller: "ArqueoCajaVentaIndexCtrl",
                  controllerAs: "vm",
                  ncyBreadcrumb: {
                      parent: "app.arqueocajaventa",
                      label: "Arqueo Caja",
                      description: "Listado de Arqueos Caja",
                      skip: true
                  }
              })
              .state("app.arqueocajaventa.new", {
                  url: "/new",
                  templateUrl: "qualita-components/ventas/views/arqueocajaventa/form.html",
                  controller: "ArqueoCajaVentaFormCtrl",
                  controllerAs: "vm",
                  ncyBreadcrumb: {
                      parent: "app.arqueocajaventa",
                      label: "Nuevo Arqueo Arqueo",
                      description: "Creación Arqueo caja"
                  },
                  resolve: {
                    arqueoCajaVentaPrepService: function () {
                          return;
                      }
                  }

              })
              .state("app.arqueocajaventa.edit", {
                  url: "/:id/edit",
                  templateUrl: "qualita-components/ventas/views/arqueocajaventa/form.html",
                  controller: "ArqueoCajaVentaFormCtrl",
                  controllerAs: "vm",
                  ncyBreadcrumb: {
                      parent: "app.arqueocajaventa",
                      label: "Editar Arqueo Caja",
                      description: "Edición de Arqueo Caja"
                  },
                  resolve: {
                      arqueoCajaVentaPrepService: arqueoCajaVentaPrepService
                  }
              })
              .state("app.arqueocajaventa.view", {
                  url: "/:id",
                  templateUrl: "qualita-components/ventas/views/arqueocajaventa/form.html",
                  controller: "ArqueoCajaVentaFormCtrl",
                  controllerAs: "vm",
                  ncyBreadcrumb: {
                      parent: "app.arqueocajaventa",
                      label: "Ver Concepto Arqueo",
                      description: "Ver Arqueo Caja"
                  },
                  resolve: {
                    arqueoCajaVentaPrepService: arqueoCajaVentaPrepService
                  }
              })


                .state("app.suscripcionrecurrente", {
                    url: "/suscripcionrecurrente",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Suscripcion Recurrente"
                    }
                })
                .state("app.suscripcionrecurrente.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "SuscripcionRecurrenteIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente",
                        label: "Suscripcion Recurrente",
                        description: "Listado de Suscripciones Recurrentes",
                        skip: true
                    }
                })
                .state("app.suscripcionrecurrente.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/suscripcionrecurrente/form.html",
                    controller: "SuscripcionRecurrenteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente",
                        label: "Nueva Suscripcion Recurrente",
                        description: "Creación de una Suscripcion Recurrente"
                    },
                    resolve: {
                        suscripcionRecurrentePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.suscripcionrecurrente.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/suscripcionrecurrente/form.html",
                    controller: "SuscripcionRecurrenteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente",
                        label: "Editar Suscripcion Recurrente",
                        description: "Edición de la Suscripcion Recurrente"
                    },
                    resolve: {
                        suscripcionRecurrentePrepService: suscripcionRecurrentePrepService
                    }
                })
                .state("app.suscripcionrecurrente.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/suscripcionrecurrente/form.html",
                    controller: "SuscripcionRecurrenteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente",
                        label: "Ver Suscripcion Recurrente",
                        description: "Ver Suscripcion Recurrente"
                    },
                    resolve: {
                        suscripcionRecurrentePrepService: suscripcionRecurrentePrepService
                    }
                })
                .state("app.suscripcionrecurrente.proceso", {
                    url: "/proceso/:idSuscripcion",
                    templateUrl: "qualita-components/ventas/views/suscripcionrecurrente/wizard.html",
                    controller: "SuscripcionProcesoCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente",
                        label: "Suscripcion Recurrente"
                    },
                    resolve: {
                        ventaPrepService: ventaPrepService
                    }
                })
                .state("app.suscripcionrecurrente.proceso.comprobanteventa", {
                    url: "/comprobantes",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente.proceso",
                        label: "Plantillas"
                    }
                })
                .state("app.suscripcionrecurrente.proceso.comprobanteventa.list", {
                    url: "/mode?modeAccess",
                    templateUrl: "views/template_base/list.html",
                    controller: "ComprobanteVentaListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente.proceso.comprobanteventa",
                        label: "Plantillas",
                        skip: true
                    }
                })
                .state("app.suscripcionrecurrente.proceso.comprobanteventa.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/comprobante/form.html",
                    controller: "ComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente.proceso.comprobanteventa",
                        label: "Nueva Plantilla"
                    },
                    resolve: {
                        comprobantePrepService: function () {
                            return;
                        }
                    }
                })
                .state("app.suscripcionrecurrente.proceso.comprobanteventa.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/comprobante/form.html",
                    controller: "ComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente.proceso.comprobanteventa",
                        label: "Editar Plantilla"
                    },
                    resolve: {
                        comprobantePrepService: comprobanteVentaPrepService
                    }
                })
                .state("app.suscripcionrecurrente.proceso.comprobanteventa.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/comprobante/form.html",
                    controller: "ComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente.proceso.comprobanteventa",
                        label: "Ver Plantilla"
                    },
                    resolve: {
                        comprobantePrepService: comprobanteVentaPrepService
                    }
                })
                .state("app.suscripcionrecurrente.proceso.comprobanteventa.apply", {
                    url: "/:id/apply?mode",
                    templateUrl: "qualita-components/ventas/views/comprobante/aplicacion.html",
                    controller: "AplicacionComprobanteVentaFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente.proceso.comprobanteventa",
                        label: "Aplicar"
                    },
                    resolve: {
                        comprobantePrepService: comprobanteVentaAplicarPrepService
                    }
                })
                .state("app.suscripcionrecurrente.proceso.agendamiento", {
                    url: "/agendamiento",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente.proceso",
                        label: "Agendamiento Suscripcion Recurrente"
                    }
                })
                .state("app.suscripcionrecurrente.proceso.agendamiento.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "AgendamientoSuscripcionRecurrenteIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente.proceso.agendamiento",
                        label: "Agendamiento Suscripcion Recurrente",
                        skip: true
                    }
                })
                .state("app.suscripcionrecurrente.proceso.procesado", {
                    url: "/procesado",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente.proceso",
                        label: "Agendamiento Procesado"
                    }
                })
                .state("app.suscripcionrecurrente.proceso.procesado.list", {
                    url: "/mode?modeAccess",
                    templateUrl: "views/template_base/list.html",
                    controller: "ComprobanteVentaListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.suscripcionrecurrente.proceso.procesado",
                        label: "Comprobantes Procesados",
                        skip: true
                    }
                })
                .state("app.importarprecio", {
                    url: "/importarprecio",
                    templateUrl: "qualita-components/ventas/views/cambioprecio/importarprecio.html",
                    controller: "CargaPrecioCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                      label: "Importar Precios",
                      description: "Importar un nuevo Precio"
                    }
                })
                .state('app.reportedetalleventaporvendedor', {
                    url: '/reportedetalleventaporvendedor',
                    templateUrl: 'views/template_base/index.html',
                    abstract: true,
                    ncyBreadcrumb: {
                      label: 'Informe Detalle de Ventas por Vendedor'
                    }
                })
                .state('app.reportedetalleventaporvendedor.list', {
                    url: '',
                    templateUrl: 'qualita-components/ventas/views/reportedetalleventaporvendedor/consulta.html',
                    controller: 'ReporteDetalleVentaPorVendedorIndexCtrl',
                    controllerAs: 'vm',
                    ncyBreadcrumb: {
                      parent: 'app.reportedetalleventaporvendedor',
                      label: 'Consulta de Informe Detalle de Ventas por Vendedor',
                      description: 'Consulta de Informe Detalle de Ventas por Vendedor',
                      skip: true
                    }
                })
                .state("app.clienteretenciones", {
                    url: "/clienteretenciones",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Retenciones de Clientes"
                    }
                })
                .state("app.clienteretenciones.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ClienteRetencionIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.clienteretenciones",
                        label: "Comp. Retencion Importados",
                        description: "Listado de Retenciones de Clientes",
                        skip: true
                    }
                })
                .state("app.comprobantesventapendientesnominacion", {
                    url: "/comprobantesventapendientesnominacion",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Consulta de Comprobantes de Venta Pendientes de Nominación"
                    }
                })
                .state("app.comprobantesventapendientesnominacion.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "ComprobanteVentaPendienteListCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.comprobantesventapendientesnominacion",
                        label: "Consulta de Comprobantes de Venta Pendientes de Nominación",
                        description: "Listado de Comprobantes de Venta Pendientes de Nominación",
                        skip: true
                    }
                })
                .state("app.comprobantesventapendientesnominacion.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/comprobantesventapendientesnominacion/form.html",
                    controller: "ComprobanteVentaPendienteFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.comprobantesventapendientesnominacion",
                        label: "Ver Comp de Vta Pendientes de Nominación",
                        description: "Ver Comp de Vta Pendientes de Nominación #"
                    },
                    resolve: {
                        comprobantePrepService: comprobanteVentaPrepService
                    }
                })
                .state("app.catalogos", {
                    url: "/catalogos",
                    templateUrl: "views/template_base/index.html",
                    abstract: true,
                    ncyBreadcrumb: {
                        label: "Catálogos de Clientes"
                    }
                })
                .state("app.catalogos.list", {
                    url: "",
                    templateUrl: "views/template_base/list.html",
                    controller: "CatalogoIndexCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.catalogos",
                        label: "Catálogos de Clientes",
                        description: "Listado de Catálogos de Clientes",
                        skip: true
                    }
                })
                .state("app.catalogos.new", {
                    url: "/new",
                    templateUrl: "qualita-components/ventas/views/catalogo/form.html",
                    controller: "CatalogoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.catalogos",
                        label: "Nuevo Catálogo de Cliente",
                        description: "Creación de Catálogos de Clientes"
                    },
                    resolve: {
                        catalogoPrepService: function () {
                            return;
                        }
                    }

                })
                .state("app.catalogos.edit", {
                    url: "/:id/edit",
                    templateUrl: "qualita-components/ventas/views/catalogo/form.html",
                    controller: "CatalogoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.catalogos",
                        label: "Editar Catálogo de Cliente",
                        description: "Edición de Catálogos de Clientes"
                    },
                    resolve: {
                        catalogoPrepService: catalogoPrepService
                    }
                    
                })
                .state("app.catalogos.view", {
                    url: "/:id",
                    templateUrl: "qualita-components/ventas/views/catalogo/form.html",
                    controller: "CatalogoFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                        parent: "app.catalogos",
                        label: "Ver Catálogo de Cliente",
                        description: "Ver Catálogos de Clientes"
                    },
                    resolve: {
                        catalogoPrepService: catalogoPrepService
                    }
                })
                .state("app.importaremision", {
                    url: "/importaremision",
                    templateUrl: "qualita-components/ventas/views/remision/importar.html",
                    controller: "ImportarRemisionFormCtrl",
                    controllerAs: "vm",
                    ncyBreadcrumb: {
                      //parent: "app.remision",
                      label: "Importar Remisiones"
                    }
                })
            }
    ]);

function listaPrecioVentaPrepService(ListaPrecioVentaFactory, $stateParams) {
    var promise = ListaPrecioVentaFactory.get($stateParams.id, "ListaPrecioVentaList").$promise;
    promise.then(function (listPreVnt) {
        ListaPrecioVentaFactory.setIdListaPrecioVenta(listPreVnt.id);
        ListaPrecioVentaFactory.setListaPrecioVenta(listPreVnt);
    });
    return promise;
}
listaPrecioVentaPrepService.$inject = ["ListaPrecioVentaFactory", "$stateParams"];

function PreviousState($state) {
    var currentStateData = {
        Name: $state.current.name,
        Params: angular.copy($state.params),
        URL: $state.href($state.current.name, $state.params)
    };
    return currentStateData;
}
PreviousState.$inject = ["$state"];

function cajaPrepService(cajaFactory, $stateParams) {
    return cajaFactory.get($stateParams.id, "CajaForm").$promise;
}
cajaPrepService.$inject = ["cajaFactory", "$stateParams"];

function tipoVentaPrepService(TipoVentaFactory, $stateParams) {
    return TipoVentaFactory.get($stateParams.id, "BaseForm").$promise;
}
tipoVentaPrepService.$inject = ["TipoVentaFactory", "$stateParams"];

function clientePrepService(ClienteFactory, $stateParams) {
    return ClienteFactory.get($stateParams.id, "BaseForm").$promise;
}
clientePrepService.$inject = ["ClienteFactory", "$stateParams"];

function puntoVentaPrepService(puntoVentaFactory, $stateParams) {
    return puntoVentaFactory.get($stateParams.id, "PuntoVentaForm").$promise;
}
puntoVentaPrepService.$inject = ["puntoVentaFactory", "$stateParams"];

function ventaPrepService(VentaFactory, TipoVentaFactory, $stateParams, suscripcionRecurrenteFactory) {
    console.log("Se obtendrá venta");
    console.log("$stateParams.codigoVenta", $stateParams);
    if ($stateParams.codigoVenta) {
        console.log("entro aca");
        var promise = VentaFactory.get($stateParams.codigoVenta, "BaseList")
            .$promise;
        promise.then(function (venta) {
            VentaFactory.setIdVenta(venta.id);
            VentaFactory.setCodigoVenta(venta.codigo);
            VentaFactory.setVenta(venta);
        });
        return promise;
    }

    if ($stateParams.idSuscripcion) {
        var promise = suscripcionRecurrenteFactory.get($stateParams.idSuscripcion, "BaseList").$promise;
        
        promise.then(function(suscripcion) {
            suscripcionRecurrenteFactory.setIdSuscripcion(suscripcion.id);
            suscripcionRecurrenteFactory.setSuscripcion(suscripcion);
        });
        return promise;
    }
    
}
ventaPrepService.$inject = ["VentaFactory", "TipoVentaFactory", "$stateParams", "suscripcionRecurrenteFactory"];

function comprobanteVentaPrepService(ComprobanteVentaFactory, $stateParams) {

    return ComprobanteVentaFactory.get($stateParams.id, "ConsultaComprobanteVentaForm").$promise;

}
comprobanteVentaPrepService.$inject = ["ComprobanteVentaFactory", "$stateParams"];

function comprobanteVentaAplicarPrepService(
    ComprobanteVentaFactory,
    $stateParams
) {
    return ComprobanteVentaFactory.get($stateParams.id, "AplicacionComprobanteForm")
        .$promise;
}
comprobanteVentaAplicarPrepService.$inject = ["ComprobanteVentaFactory", "$stateParams"];

function pedidoVentaPrepService(PedidoVentaFactory, $stateParams) {
    return PedidoVentaFactory.get($stateParams.id, "PedidoVentaForm").$promise;
}
pedidoVentaPrepService.$inject = ["PedidoVentaFactory", "$stateParams"];

function presupuestoVentaPrepService(PresupuestoVentaFactory, $stateParams) {
    return PresupuestoVentaFactory.get($stateParams.id, "PresupuestoVentaForm").$promise;
}
presupuestoVentaPrepService.$inject = ["PresupuestoVentaFactory", "$stateParams"];

function remisionPrepService(RemisionFactory, $stateParams) {
    return RemisionFactory.get($stateParams.id, "RemisionForm").$promise;
}
remisionPrepService.$inject = ["RemisionFactory", "$stateParams"];

function procesoCobroPrepService(ProcesoCobroFactory, $stateParams) {
    console.log("Se obtendrá Proceso Cobro");
    var promise = ProcesoCobroFactory.get(
        $stateParams.codigoProcesoCobro,
        "BaseList"
    ).$promise;
    promise.then(function (procesoCobro) {
        ProcesoCobroFactory.setIdProcesoCobro(procesoCobro.id);
        ProcesoCobroFactory.setProcesoCobro(procesoCobro);
    });
    return promise;
}
procesoCobroPrepService.$inject = ["ProcesoCobroFactory", "$stateParams"];

function procesoCobroPrepService2(ProcesoCobroFactory, $stateParams) {
    console.log("Se obtendrá Proceso Cobro2");
    //return ProcesoCobroFactory.updateStates();
}

function cobroPrepService(CobroFactory, $stateParams) {
    return CobroFactory.get($stateParams.id, "CobroForm").$promise;
}
cobroPrepService.$inject = ["CobroFactory", "$stateParams"];

function movimientoBancarioPrepService(
    MovimientoBancarioFactory,
    $stateParams
) {
    return MovimientoBancarioFactory.get($stateParams.id, "MovimientoBancarioForm").$promise;
}
movimientoBancarioPrepService.$inject = ["MovimientoBancarioFactory", "$stateParams"];

function planillaFondoFijoPrepService(PlanillaFondoFijoFactory, $stateParams) {
    return PlanillaFondoFijoFactory.get($stateParams.id, "PlanillaFondoFijoForm").$promise;
}
planillaFondoFijoPrepService.$inject = ["PlanillaFondoFijoFactory", "$stateParams"];

function cuotasCobroPrepService(PlanCobrosDetalleFactory, $stateParams) {
    return PlanCobrosDetalleFactory.get($stateParams.id, "detail").$promise;
}

function planCobrosVentasPrepService(PlanCobrosDetalleFactory, $stateParams) {
    return PlanCobrosDetalleFactory.get($stateParams.id, "PlanCobrosDetalleForm").$promise;
}
planCobrosVentasPrepService.$inject = ["PlanCobrosDetalleFactory", "$stateParams"];

function tipoClientePrepService(TipoClienteFactory, $stateParams) {
    return TipoClienteFactory.get($stateParams.id, "BaseForm").$promise;
}
tipoClientePrepService.$inject = ["TipoClienteFactory", "$stateParams"];

function cambioPrecioPrepService(CambioPrecioFactory, $stateParams) {
    return CambioPrecioFactory.get($stateParams.id, "CambioPrecioForm").$promise;
}
cambioPrecioPrepService.$inject = ["CambioPrecioFactory", "$stateParams"];

function cambioPrecioServicioPrepService(CambioPrecioServicioFactory, $stateParams) {
    return CambioPrecioServicioFactory.get($stateParams.id, "CambioPrecioServicioForm").$promise;
}
cambioPrecioServicioPrepService.$inject = ["CambioPrecioServicioFactory", "$stateParams"];

function configuracionAutorizacionesPrepService(ConfiguracionAutorizacionesFactory, $stateParams) {
    return ConfiguracionAutorizacionesFactory.get($stateParams.id, "ConfiguracionAutorizacionesForm").$promise;
}
configuracionAutorizacionesPrepService.$inject = ["ConfiguracionAutorizacionesFactory", "$stateParams"];

function solicitudAutorizacionPrepService(SolicitudAutorizacionFactory, $stateParams) {
    return SolicitudAutorizacionFactory.get($stateParams.id, "SolicitudAutorizacionForm").$promise;
}
solicitudAutorizacionPrepService.$inject = ["SolicitudAutorizacionFactory", "$stateParams"];

function tipoCobroPrepService(TipoCobroFactory, $stateParams) {
    return TipoCobroFactory.get($stateParams.id, "BaseForm").$promise;
}
tipoCobroPrepService.$inject = ["TipoCobroFactory", "$stateParams"];

function vendedorPrepService(VendedorFactory, $stateParams) {
    return VendedorFactory.get($stateParams.id, "VendedorForm").$promise;
}
vendedorPrepService.$inject = ["VendedorFactory", "$stateParams"];

function camionesPrepService(CamionFactory, $stateParams) {
    return CamionFactory.get($stateParams.id, "CamionForm").$promise;
}
camionesPrepService.$inject = ["CamionFactory", "$stateParams"];

function choferesPrepService(ChoferFactory, $stateParams) {
    return ChoferFactory.get($stateParams.id, "ChoferForm").$promise;
}
choferesPrepService.$inject = ["ChoferFactory", "$stateParams"];

function zonasPrepService(ZonaFactory, $stateParams) {
    return ZonaFactory.get($stateParams.id, "ZonaForm").$promise;
}
zonasPrepService.$inject = ["ZonaFactory", "$stateParams"];

function rutasPrepService(RutaFactory, $stateParams) {
    return RutaFactory.get($stateParams.id, "RutaForm").$promise;
}
rutasPrepService.$inject = ["RutaFactory", "$stateParams"];

function planillasRepartosPrepService(PlanillaRepartoFactory, $stateParams) {
    return PlanillaRepartoFactory.get($stateParams.id, "PlanillaRepartoForm").$promise;
}
planillasRepartosPrepService.$inject = ["PlanillaRepartoFactory", "$stateParams"];

function cajeroPrepService(CajeroFactory, $stateParams) {
    return CajeroFactory.get($stateParams.id, "CajeroForm").$promise;
}
cajeroPrepService.$inject = ["CajeroFactory", "$stateParams"];

function tipoActividadRamoClientePrepService(TipoActividadRamoClienteFactory, $stateParams) {
    return TipoActividadRamoClienteFactory.get($stateParams.id, "TipoActividadRamoClienteForm").$promise;
}
tipoActividadRamoClientePrepService.$inject = ["TipoActividadRamoClienteFactory", "$stateParams"];

function ordenCargaPrepService(OrdenCargaFactory, $stateParams) {
    return OrdenCargaFactory.get($stateParams.id, "OrdenCargaForm").$promise;
}
ordenCargaPrepService.$inject = ["OrdenCargaFactory", "$stateParams"];

function categoriaClientePrepService(CategoriaClienteFactory, $stateParams) {
    return CategoriaClienteFactory.get($stateParams.id, "CategoriaClienteForm").$promise;
}
categoriaClientePrepService.$inject = ["CategoriaClienteFactory", "$stateParams"];

function segmentoClientePrepService(SegmentoClienteFactory, $stateParams) {
    return SegmentoClienteFactory.get($stateParams.id, "BaseForm").$promise;
}
segmentoClientePrepService.$inject = ["SegmentoClienteFactory", "$stateParams"];

function conceptoMovimientoPrepService(ConceptoMovimientoFactory, $stateParams) {
    return ConceptoMovimientoFactory.get($stateParams.id, "ConceptoMovimientoForm").$promise;
}
conceptoMovimientoPrepService.$inject = ["ConceptoMovimientoFactory", "$stateParams"];

function contribuyentePrepService(ContribuyenteFactory, $stateParams) {
    return ContribuyenteFactory.get($stateParams.id, "BaseForm").$promise;
}
contribuyentePrepService.$inject = ["ContribuyenteFactory", "$stateParams"];

function tipoPresupuestoPrepService(TipoPresupuestoFactory, $stateParams) {
    return TipoPresupuestoFactory.get($stateParams.id, "BaseForm").$promise;
}
tipoPresupuestoPrepService.$inject = ["TipoPresupuestoFactory", "$stateParams"];

function denominacionMonedaPrepService(denominacionMonedaFactory, $stateParams) {
    return denominacionMonedaFactory.get($stateParams.id, "DenominacionMonedaForm").$promise;
}
denominacionMonedaPrepService.$inject = ["denominacionMonedaFactory", "$stateParams"];

function asignacionMonedaPrepService(asignacionMonedaFactory, $stateParams) {
    return asignacionMonedaFactory.get($stateParams.id, "AsignacionMonedaForm").$promise;
}
asignacionMonedaPrepService.$inject = ["asignacionMonedaFactory", "$stateParams"];

function conceptoArqueoPrepService(conceptoArqueoFactory, $stateParams) {
    return conceptoArqueoFactory.get($stateParams.id, "ConceptoArqueoForm").$promise;
}
conceptoArqueoPrepService.$inject = ["conceptoArqueoFactory", "$stateParams"];

function arqueoCajaVentaPrepService(arqueoCajaVentaFactory, $stateParams) {
  return arqueoCajaVentaFactory.get($stateParams.id, "ArqueoCajaVentaForm").$promise;
}
arqueoCajaVentaPrepService.$inject = ["arqueoCajaVentaFactory", "$stateParams"];
function suscripcionRecurrentePrepService(suscripcionRecurrenteFactory, $stateParams) {
    return suscripcionRecurrenteFactory.get($stateParams.id, "SuscripcionRecurrenteForm").$promise;

}
suscripcionRecurrentePrepService.$inject = ["suscripcionRecurrenteFactory", "$stateParams"];

function agendamientoSuscripcionRecurrentePrepService(agendaSuscripcionRecurrenteFactory, $stateParams) {

    return agendaSuscripcionRecurrenteFactory.get($stateParams.id, "AgendaSuscripcionRecurrenteForm").$promise;

}

function agendamientoSuscripcionRecurrentePrepService(agendaSuscripcionRecurrenteFactory, $stateParams) {

    return agendaSuscripcionRecurrenteFactory.get($stateParams.id, "AgendaSuscripcionRecurrenteForm").$promise;

}

function catalogoPrepService(CatalogoFactory, $stateParams) {
    return CatalogoFactory.get($stateParams.id, "CatalogoForm").$promise;
}
catalogoPrepService.$inject = ["CatalogoFactory", "$stateParams"];

function planCobrosPrepService(PlanCobrosFactory, $stateParams) {
    return PlanCobrosFactory.get($stateParams.id, "PlanCobrosForm").$promise;
}
planCobrosPrepService.$inject = ["PlanCobrosFactory", "$stateParams"];
"use strict";

/**
 * @ngdoc service
 * @name qualita.venta
 * @description
 * # ventas
 * Factory in the qualita.
 */
angular.module("qualita.venta").factory("VentasLangFactory", [
    "$resource",
    "baseurl",
    "$translatePartialLoader",
    "$translate",
    function ($resource, baseurl, $translatePartialLoader, $translate) {
        var translationsNeeded = [
            "TITLE_VENTA",
            "NEW_VENTA",
            "EDIT_VENTA",
            "DELETE_VENTA",
            "VIEW_VENTA",
            "ACTIVE",
            "CLIENTE",
            "FAIL_DELETE_CLIENTE",
            "TIPO_PERSONA",
            "NOMBBRE_RAZON",
            "TIPO_DOCUMENTO",
            "NUMERO_DOCUMENTO",
            "DIRECCION",
            "CORRE_ELECTRONICO",
            "NEW_CLIENTE",
            "EDIT_CLIENTE",
            "VIEW_CLIENTE",
            "NUMBER",
            "NAME",
            "PUNTO_EMISION",
            "ID",
            "CAJAS_TITLE",
            "CAJAS_DELETE_ERROR",
            "NEW_PUNTO_VENTA",
            "EDIT_PUNTO_VENTA",
            "VIEW_PUNTO_VENTA",
            "PUNTO_VENTA_DELETE_ERROR",
            "PUNTO_VENTA_TITLE",
            "PUNTO_VENTA_ERROR",
            "PUNTO_VENTA_SUCURSAL",
            "PUNTO_VENTA_COD_SUCURSAL",
            "PUNTO_VENTA_PUNTOS_EMISION",
            "PUNTO_VENTA_PUNTO_EMISION",
            "PUNTO_EMISION_FACTURA",
            "PUNTO_EMISION_NOTA_REMISION",
            "PUNTO_EMISION_NOTA_CREDITO",
            "NEW_TIPO_VENTA",
            "EDIT_TIPO_VENTA",
            "VIEW_TIPO_VENTA",
            "TIPO_VENTA_ALREADY_EXISTS",
            "CONFIGURACION_PREDETERMINADA",
            "CONFIGURAR_TIPO_VENTA",
            "COMP_COMPROBANTE_OBLIGATORIO",
            "ACTIVE",
            "TIPO_VENTA",
            "VENTA_DELETE_CHECK_COMPROBANTES",
            "ANULAR_VENTA",
            "VENTAS",
            "CODIGO_VENTA",
            "CIERRE_VENTA",
            "PEDIDO_VENTA_CANTIDAD",
            "PEDIDO_VENTA_IMPORTE",
            "COMPROBANTE_CANTIDAD",
            "COMPROBANTE_IMPORTE",
            "REMISION_CANTIDAD",
            "SELECT_TIPO_VENTA",
            "SELECT_CLIENTE",
            "SELECT_PEDIDO_VENTA",
            "VENTA_REL",
            "REMISION",
            "CIERRE",
            "PEDIDO_VENTA",
            "CONDICION_COBRO",
            "CANTIDAD_CUOTAS",
            "DETALLE",
            "PRODUCTO",
            "IMPUESTO",
            "UNIDAD_MEDIDA",
            "NUMERO_GRUPO",
            "CANTIDAD",
            "CANTIDAD_FACTURADA",
            "CANTIDAD_PEDIDO",
            "PRECIO_VENTA",
            "TOTAL_ITEM",
            "NUMERO_RODEN_PRODUCCION",
            "NUMERO_ORDEN_COMPRA_CLIENTE",
            "FECHA_VALIDEZ",
            "NEW_PEDIDO_VENTA",
            "EDIT_PEDIDO_VENTA",
            "VIEW_PEDIDO_VENTA",
            "NEW_PRESUPUESTO_VENTA",
            "EDIT_PRESUPUESTO_VENTA",
            "VIEW_PRESUPUESTO_VENTA",
            "SELECT_PRESUPUESTO_VENTA",
            "APROBAR_PRESUPUESTO",
            "ANULAR_PRESUPUESTO",
            "CERRAR_PRESUPUESTO",
            "ANULAR_PRESUPUESTO_MENSAJE",
            "APROBAR_PRESUPUESTO_MENSAJE",
            "CERRAR_PRESUPUESTO_MENSAJE",
            "CONFIRMAR_COMPROBANTE",
            "REIMPRIMIR_COMPROBANTE",
            "CONFIRMAR_COMPROBANTE_Q",
            "REIMPRIMIR_COMPROBANTE_Q",
            "CODIGO_PRODUCTO_SERVICIO",
            "ITEM_SERVICIO",
            "ANULAR_COMPROBANTE",
            "ANULAR_COMPROBANTE_Q",
            "EDIT",
            "VIEW",
            "DELETE",
            "APROBAR_PEDIDO",
            "ANULAR_PEDIDO",
            "CERRAR_PEDIDO",
            "ANULAR_PEDIDO_MENSAJE",
            "APROBAR_PEDIDO_MENSAJE",
            "CERRAR_PEDIDO_MENSAJE",
            "NUMERO_PEDIDO",
            "NUMERO_FACTURA",
            "NUMERO_TIMBRADO",
            "ALMACEN",
            "TIPO_COMPROBANTE",
            "CODIGO_SUCURSAL",
            "PUNTO_EMISION",
            "ESTADO",
            "DATO_ENVIO",
            "RAZON_SOCIAL",
            "NUMERO_DOCUMENTO",
            "DIRECCION_ORIGEN",
            "DIRECCION_DESTINO",
            "FECHA_INICIO",
            "FECHA_FIN",
            "VEHICULO",
            "NRO_MATRICULA",
            "TRANSPORTISTA",
            "NRO_TRANSPORTISTA",
            "CONDUCTOR",
            "NRO_CONDUCTOR",
            "DIRECCION_CONDUCTOR",
            "MOTIVO_TRANSLADO",
            "DATE",
            "COBRANZA_FACTURA",
            "DEPOSITO_COBRANZA",
            "CIERRE",
            "FECHA_INICIO",
            "FECHA_FIN",
            "MONTO_BASE_EFECTIVO",
            "NEW_PROCESO_COBRO",
            "EDIT_PROCESO_COBRO",
            "VIEW_PROCESO_COBRO",
            "CODE",
            "FACTURAS",
            "REMISION_DETALLES",
            "NUMERO_PEDIDO_VENTA",
            "ANULAR_REMISION_MENSAJE",
            "EMITIR_REMISION_MENSAJE",
            "ELIMINAR_REMISION_MENSAJE",
            "ANULAR_REMISION",
            "EMITIR_REMISION",
            "ELIMINAR_REMISION",
            "FECHA_INICIO_VIGENCIA",
            "FECHA_FIN_VIGENCIA",
            "TIPOS_VENTA",
            "PROCESOS_COBRO",
            "STATUS",
            "CURRENCY",
            "TOTAL_GENERAL",
            "NUMERO_RECIBO",
            "CAJA",
            "EMITIR_RECIBO_COBRO",
            "EMITIR_COBRO_MENSAJE",
            "CODIGO_PROCESO_COBRO",
            "DETALLES_COBRO",
            "CAJERO",
            "DETALLES_COMPROBANTES",
            "BUSQUEDA_COMPROBANTES",
            "CLIENTE_REQUIRED",
            "TIPO_CAMBIO_SHORT",
            "SALDO_EN_MONEDA",
            "TIPO_COMPROBANTE_SHORT",
            "TIPO_CAMBIO_SHORT",
            "IMPORTE_COBRAR",
            "MONTO_TOTAL_FACTURA",
            "MONTO_TOTAL_CUOTA",
            "MONTO_COBRADO_A_FECHA",
            "IMPORTE_NOTAS_CREDITO",
            "IMPORTE_COBRADO",
            "NRO_COMPROBANTE",
            "FORMA_PAGO",
            "ENTIDAD_BANCARIA",
            "CHEQUE",
            "NUMERO_CUENTA",
            "FECHA_PAGO_DIFERIDO",
            "NUMERO_RETENCION",
            "NUMERO_TIMBRADO",
            "NUMERO_TARJETA",
            "TIPO_TARJETA",
            "NUMERO_VOUCHER",
            "NUMERO_CUENTA_CONTABLE",
            "FORMA_PAGO_SHORT",
            "FECHA_PAGO_DIFERIDO_SHORT",
            "EDIT_COBRO",
            "VIEW_COBRO",
            "BANCO",
            "NUMERO_CHEQUE",
            "CIERRE_PROCESO_COBRO",
            "DATE_APERTURA",
            "DATE_CIERRE",
            "TIENE_DIFERENCIA",
            "TIMBRADO_EMPRESA",
            "MONTO",
            "SALDO_PENDIENTE",
            "NEW_COMPROBANTE_VENTA",
            "EDIT_COMPROBANTE_VENTA",
            "VIEW_COMPROBANTE_VENTA",
            "IMPUESTO_CABECERA_REPETIDO",
            "MONTOS_TOTALES_SUM_DETALLES_WARNING_COMPRA_LOCAL",
            "IMPUESTOS_TOTALES_SUM_DETALLES_WARNING_COMPRA_LOCAL",
            "NRO_COBRO_AUTOGENERADO",
            "NEW_COBRO",
            "NEW_BANK_MOVEMENT",
            "EDIT_BANK_MOVEMENT",
            "VIEW_BANK_MOVEMENT",
            "MOVEMENT_TYPE",
            "COBRO",
            "ORIGIN_ACCOUNT_NAME",
            "ENTITY_NAME",
            "BANK_MOVEMENTS",
            "DESTINY_ACCOUT_NAME",
            "CONFIRMED",
            "NUMERO_BOLETA",
            "PLAN_COBROS",
            "FECHA_COMPROBANTE",
            "COMPROBANTE_NUMERO",
            "COMPROBANTE_ESTADO",
            "TOTAL_ACTUAL",
            "HECHAUKA_VENTAS",
            "EXPORTAR_HECHAUKA_VENTAS",
            "EXPORTAR_HECHAUKA_ERROR",
            "SELECCION_RANGO_HECHAUKA",
            "APLICACION_COMPROBANTE",
            "PREVISUALIZAR_APLICACION",
            "APLICACION_MAYOR_TOTAL_ERROR",
            "APLICACION_IMPUESTO_MAYOR_SALDO",
            "MONTO_MAYOR_SALDO_COMPROBANTE",
            "MONTO_MENOR_IGUAL_CERO",
            "MONTO_MAYOR_SALDO_ACTUAL",
            "MONTO_MAYOR_SALDO_CUOTA",
            "MONTO_MAYOR_SALDO_ACTUAL",
            "NO_COMPROBANTES_APLICABLES",
            "APLICACION_YA_EXISTENTE",
            "EDITAR_APLICACION",
            "AGREGAR_APLICACION",
            "ANULAR_VENTA_Q",
            "ANULAR_VENTA_MSG",
            "ANULAR_VENTA_FAILED",
            "DELETE_FAILED",
            "DELETE_CONFIRMATION",
            "DELETE_WARNING",
            "IMPORT_CLIENTS",
            "REMISIONES",
            "COMPROBANTES",
            "NEW_REMISION",
            "EDIT_REMISION",
            "VIEW_REMISION",
            "ERROR_NO_DETALLES",
            "ERROR_MONTO_CERO",
            "CONFIRM_MOV",
            "CONFIRM_MOV_DETAIL",
            "CONFIRM_MOV_FAILED",
            "CONFIRM_MOV_FAILED_DETAIL",
            "ERROR_FILTERING_ESTADO_CUENTA_PROVEEDOR",
            "ERROR_DIGITO_VERIFICADOR",
            "DESCRIPCION",
            "TIPO_CLIENTE",
            "FAILED_DELETE_TIPO_CLIENTE",
            "NEW_TIPO_CLIENTE",
            "EDIT_TIPO_CLIENTE",
            "VIEW_TIPO_CLIENTE",
            "REGISTRAR_CAJA",
            "SELECT_PUNTO_EMISION_VENTA",
            "REGISTRAR_CAJA_Q",
            "DESREGISTRAR_CAJAS",
            "DESREGISTRAR_SELECTED_CAJAS_Q",
            "CAJA_REGISTRADA",
            "CAJAS_DESREGISTRADAS",
            "NO_PEV_CAJA_MESSAGE",
            "NO_COMPROBANTE_ERROR",
            "CERRAR_PEDIDOS_VENTAS_Q",
            "SELECCIONAR_PEIDOS_VENTA_WARNING",
            "CERRAR_PEDIDO_VENTA",
            "CERRAR_PEDIDO_VENTA_MSG",
            "PUNTO_EMISION_NUMERO",
            "NUMERO_REMISION",
            "NO_ADMINISTRATIVE_PERIOD",
            "DEBE_SELECCIONAR_CAJA",
            "VER_CUOTAS",
            "KIND",
            "LINE",
            "BRAND",
            "NOMBRE",
            "DESCRIPTION",
            "TIPO_IMPUESTO",
            "CODIGO_BARRAS_PRINCIPAL",
            "NOMBRE_SUCURSAL",
            "ASOCIAR_CAJA",
            "NUMERO_COMPROBANTE_FUERA_RANGO",
            "EMITIR_COBRO",
            "EMITIR_COBRO_Q",
            "CAJA_EXIST",
            "COMPROBANTE_REQUIRED",
            "PUNTO_EMISION_VACIO",
            "ANULAR_COBRO",
            "ANULAR_COBRO_Q",
            "DESCONFIRMAR",
            "ERROR_FILTERING_ESTADO_CUENTA_CLIENTE",
            "DESAPLICAR",
            "DESAPLICAR_Q",
            "QUOTA_NUMBER",
            "APLICAR_COMPROBANTE",
            "EXISTENCIA_RESULTANTE",
            "CONSULTA_LIBRO_VENTA",
            "ERROR_FILTERING_LIBRO_VENTA",
            "GENERAR_CSV",
            "CONSULTA_COBROS",
            "ERROR_TIPO_CAMBIO_CUOTA",
            "ERROR_TIPO_CAMBIO",
            "ID_VENTA",
            "COD_COBRO",
            "DETALLE_VACIO",
            "NUMERO_TIMBRADO_TIPO_COMP_REPETIDO",
            "ENTITY_NAME_FROM",
            "CURRENCY_FROM",
            "COD_DESEMBOLSO",
            "ACCOUNT_NAME",
            "TOTAL_COBRAR",
            "CONSULTA_APLICACIONES",
            "ERROR_NINGUN_COMP_GENERA_MOVIMIENTO",
            "COMPROBANTE_CLIENTE",
            "NO_OPEN_PERIODS_NOW",
            "DIFERENCIA",
            "DEPOSITOS",
            "TOTAL_FORMA_PAGO",
            "COBROS",
            "DIFERENCIA_TITLE",
            "DIFERENCIA",
            "NUMERO_TRANSACCION_STOCK",
            "CERRAR_COBRO_TITLE",
            "CERRAR_COBRO_MSG",
            "CERRAR_VENTA_TITLE",
            "CERRAR_VENTA_MESSAGE",
            "COD_SUCURSAL_REPEAT",
            "FECHA_PAGO_ERROR",
            "NRO_CUENTA_ORIGEN",
            "NRO_CUENTA_DESTINO",
            "PRECIO_ACTUAL",
            "PRECIO_COSTO",
            "HORA_MODIFICACION",
            "CODIGO_INTERNO",
            "CAMBIO_PRECIO",
            "CODIGO_BARRAS",
            "HISTORICO_CAMBIO_PRECIO",
            "PRECIO_ANTERIOR",
            "PRECIO_NUEVO",
            "USUARIO",
            "CAMBIO_PRECIO_REPEAT_ERROR",
            "PRODUCT_TYPE_CHARACTERS",
            "CAMBIO_PRECIO_DETALLES",
            "COSTO",
            "ES_VENTA",
            "EDIT_CAMBIO_PRECIO_SERVICIO",
            "VIEW_CAMBIO_PRECIO_SERVICIO",
            "NEW_CAMBIO_PRECIO_SERVICIO",
            "CAMBIO_PRECIO_SERVICIO",
            "PRECIO_ERROR",
            "ERROR_TIPO_IMPUESTO_DIFIERE",
            "ERROR_PRECIO_INCONSISTENTE",
            "EXISTE_COMPROBANTE_CERO",
            "EXISTE_COBRO_CERO",
            "SUCURSAL_CLIENTE",
            "SUCURSAL_ORIGEN",
            "SUCURSAL_EXISTS",
            "NUMERO_GRUPO_REPETIDO",
            "NO_EXISTE_PRODUCTO_EN_ALMACEN",
            "NUMERO_GRUPO_REPETIDO",
            "NO_EXISTE_GRUPO_CON_STOCK",
            "VERIFIQUE_ALMACEN",
            "CONSTRAINT_ERROR",
            "NO_EXISTE_GRUPO_SIN_STOCK",
            "VERIFIQUE_TIPO_COMPROBANTE",
            "EXISTE_TOTAL_CERO",
            "TIPO_OPERACION_VENTA",
            "TIPO_TRANSACCION",
            "MOTIVO_EMISION",
            "CAJA_DESACTIVADA",
            "HISTORICO_PRECIO_SERVICIOS",
            "FECHA_DES_MAYOR_FECHA_HAS",
            "FECHA_MOD",
            "ULTIMO_COSTO_IVA",
            "EDIT_CAMBIO_PRECIO_PROD",
            "VIEW_CAMBIO_PRECIO_PROD",
            "NEW_CAMBIO_PRECIO_PROD",
            "FECHA_MODIFICACION",
            "EDIT_CAMBIO_PRECIO_PRODUCTO",
            "VIEW_CAMBIO_PRECIO_PRODUCTO",
            "NEW_CAMBIO_PRECIO_PRODUCTO",
            "DESCONFIRM_MOV",
            "DESCONFIRM_MOV_DETAIL",
            "ELIMINAR_MOV",
            "CLASE_TIMBRADO",
            "NUMERO_TELEFONO",
            "TIPO_TRANSACCION_WARNING",
            "CLIENTE_NO_TIPO_OPERACION",
            "RANKING_PRODUCTO_TITLE",
            "RANKING_FAMILIA_TITLE",
            "REPORTE_CLIENTE_TITLE",
            "REPORTE_SUCURSAL_TITLE",
            "TIPO_REPORTE_VENTA",
            "APLICAR_ANTICIPO_COBRO",
            "IMPORTE_ANTICIPO",
            "DESCRIPCION_ANTICIPO",
            "APLICAR_ANTICIPO_COBRO",
            "MONTO_TOTAL_ANTICIPO",
            "MONTO_TOTAL_APLICADO",
            "SALDO_APLICAR",
            "FECHA_APLICACION",
            "DETALLES_NOT_LOADED_ANTICIPO",
            "NRO_REMISION",
            "REABRIR_FLUJO",
            "REOPEN_CONFIRMATION",
            "REOPEN_WARNING",
            "REOPEN_ERROR",
            "CONSOLIDAR_PEDIDO_VENTA",
            "UNIDAD_MEDIDA_ABREVIADO",
            "GRUPO",
            "CONSOLIDAR",
            "SELECCIONAR_PEDIDO_VENTA_WARNING",
            "ERROR_NO_FECHA_FUTURA",
            "ENVIO_NOTA_CREDITO_VIRTUAL_Q",
            "ENVIO_NOTA_CREDITO_VIRTUAL",
            "CANT_MAYOR_PEDIDO_WARNING",
            "CODIGO_COBRO",
            "CODIGO_PROC_COBRO",
            "ESTADO_INCORRECTO_COMP_VENTA",
            "LISTA_PRECIO_VENTA",
            "TIPOS_COBROS",
            "TIPO_CONDICION",
            "NUEVO_TIPO_COBRO",
            "EDIT_TIPO_COBRO",
            "VIEW_TIPO_COBRO",
            "IMPRIME_RECIBO",
            "ACTIVO",
            "TIPOS_COBROS_DELETE_FAILED",
            "LISTA_PRECIO_VENTA_DELETE_ERROR",
            "LISTA_PRECIO_VENTA_DESACTIVATE_FAILED",
            "DESACTIVATE_FAILED",
            "PRESUPUESTO_VENTA_CANTIDAD",
            "PRESUPUESTO_VENTA_IMPORTE",
            "TIPO_COBRO_ENTIDAD",
            "FECHA_MAYOR_FECHA_VEN",
            "NUMERO",
            "FECHA",
            "FECHA_VENCIMIENTO",
            "MOTIVO_RECHAZO",
            "MOTIVO_CIERRE",
            "MOTIVO_ANULACION",
            "IMPRIMIR_PRESUPUESTO",
            "IMPRIMIR_PRESUPUESTO_MSG",
            "NOMBRES",
            "APELLIDOS",
            "VENDEDORES",
            "NEW_VENDEDOR",
            "EDIT_VENDEDOR",
            "VIEW_VENDEDOR",
            "GRUPO_VENDEDOR",
            "MAX_PORC_DESC_PERMITIDO",
            "PORCENTAJE_COMISION",
            "MONTO_META",
            "PERIODO_MONTO_META",
            "GRUPO_REPETITIVO",
            "ERROR_LOCACIONES_OBLIGATORIO",
            "EXPORTAR_ARCHIVO_VENTAS",
            "FECHA_ENTREGA_MENOR",
            "DISTRIBUIR_LOTES",
            "ARCHIVO_VENTAS",
            "DEFINIR_ESTADO_RENDICION",
            "ESTADO_RENDICION",
            "NO_NUMERO_FACTURA_ACTUAL",
            "CLIENTE_CARACTERES",
            "TIPO_LISTA_PRECIO",
            "CONSULTA_COMPROBANTES_PLANILLA",
            "NRO_PLANILLA",
            "FILTRO_ERROR",
            "CODIGO_VENDEDOR",
            "CODIGO_EXISTS",
            "TIPOS_LISTA_PRECIOS",
            "NEW_TIPO_LISTA_PRECIOS",
            "EDIT_TIPO_LISTA_PRECIOS",
            "VIEW_TIPO_LISTA_PRECIOS",
            "CATEGORIA_CLIENTE",
            "CODIGO_CLIENTE",
            "NOMBRE_FANTASIA",
            "TIPO_ACTIVIDAD_RAMO_CLIENTE",
            "LISTA_SEGMENTOS_CLIENTES",
            "FAILED_DELETE_SEGMENTOS_CLIENTES",
            "CODIGO_SEGMENTO",
            "NEW_SEGMENTO_CLIENTE",
            "EDIT_SEGMENTO_CLIENTE",
            "VIEW_SEGMENTO_CLIENTE",
            "BLOQUEAR_VENTAS",
            "BLOQUEAR_COND_VENTAS",
            "EXONERADO_IVA",
            "MODO_EXONERACION_IVA",
            "SEGMENTO",
            "CIUDAD",
            "KILOS",
            "CANTIDAD_UM_PRINCIPAL",
            "ID_COMPROBANTE",
            "ID_ITEMS",
            "NOMBRE_CLIENTE",
            "CATEGORIA",
            "CODIGO_PRODUCTO",
            "CATIDAD",
            "PRECIO_UNITARIO",
            "DESCUENTO",
            "IVA",
            "MONTO_NETO",
            "PROCESADORA",
            "DESCUENTO_PROCESADORA",
            "DESCUENTO_COMISION",
            "DIRECCION_TRANSPORTISTA",
            "MARCA",
            "ERROR_ENVIO_A_CONTABILIDAD_VERIFICAR",
            "PASSWORD_POS",
            "USUARIO_POS",
            "EXPORTAR_SALDO_VENTAS",
            "ARCHIVO_SALDO",
            "PERMANENT_OPERATION_WARNING",
            "TITLE_ARQUEO_VENTA",
            "MESSAGE_ARQUEO_VENTA",
            "RESUMEN_ARQUEO",
            "RESUMEN_ARQUEO_MENSAJE",
            "NRO_CAJA_DESDE",
            "NRO_CAJA_HASTA",
            "ERROR_NRO_RUBRICA",
            "ERROR_LINEAS_DIFERENTES",
            "REENVIAR_CLIENTE",
            "REENVIAR_CLIENTE_MSG",
            "REENVIAR_CLIENTE_FAILED",
            "TIMBRADO",
            "FECHA_ANULACION",
            "FECHA_EMISION",
            "FECHA_RECEPCION",
            "INFORMANTE",
            "RUC_INFORMANTE",
            "COMPROBANTE_ASOCIADO",
            "MONTO_RETENCION",
            "RETENCION_CLIENTE",
            "IMPORTAR_RETENCION_CLIENTE",
            "IMPORTAR_RETENCIONES",
            "BLOQUEO_POR_DESCUENTO",
            "LISTA_CATALOGOS",
            "FAILED_DELETE_CATALOGOS",
            "NOMBRE_CLIENTE_DESCRIPTIVO",
            "NOMBRE_FANTASIA_CORTO",
            "NOMBRE_CATALOGO",
            "CODIGO_CATALOGO",
            "NOMBRE_CLIENTE_DESCRIPTIVO_3_CARACTERES",
            "IMPORTAR_REMISION",
            "IMPORTAR_REMISION_TITLE"
        ];

        return {
            getTranslations: function getTranslations() {
                $translatePartialLoader.addPart("venta");
                //$translateProvider.translationNotFoundIndicator('$');
                return $translate.refresh().then(function () {
                    return $translate(translationsNeeded);
                });
            }
        };
    }
]);

'use strict';

angular.module('qualita.venta')
  .controller('CajaIndexCtrl', CajaIndexCtrl);

CajaIndexCtrl.$inject = ['$scope', '$rootScope', 'cajaFactory', 'filterFactory',
  'ReportTicketFactory', '$window', 'VentasLangFactory', 'CsvFactory',
  'UtilFactory', 'baseurl', '$filter', '$modal', 'AuthorizationService',
  'puntoEmisionFactory', 'notify']

function CajaIndexCtrl($scope, $rootScope, cajaFactory, filterFactory, ReportTicketFactory,
  $window, VentasLangFactory, CsvFactory, UtilFactory, baseurl, $filter, $modal,
  AuthorizationService, puntoEmisionFactory, notify) {

  var vm = this;
  var defaultColumnOrder = ['id', 'nombre', 'numero', 'puntoEmision.numero', 'puntoEmision.descripcion'];

  var renderEmptyData = function (data) {
    if (data == undefined)
      return "";
    else
      return data;
  };

  VentasLangFactory.getTranslations().then(function (translations) {
    vm.options = {
      'resource': 'cajas',
      'isSelectable': true,
      'view': 'BaseList',
      'selection': {},
      'title': $filter('translate')('CAJAS_TITLE'),
      'factory': cajaFactory,
      'view': 'CajaList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('CAJAS_DELETE_ERROR'),
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('ID'), visible: false, 'render': renderEmptyData  },
        { 'data': 'nombre', 'title': $filter('translate')('NAME'), 'render': renderEmptyData },
        { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'render': renderEmptyData, 'class': 'dt-right'  },
        { 'data': 'puntoEmision.numero', 'title': $filter('translate')('PUNTO_EMISION_NUMERO'), 'render': renderEmptyData, 'class': 'dt-right' },
        { 'data': 'puntoEmision.descripcion', 'title': $filter('translate')('PUNTO_EMISION'), 'render': renderEmptyData }
      ],
      'hasOptions': true,
      'defaultOrderColumn': 1,
      'defaultOrderDir': "desc",
      'extraMenuOptions':
        [
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoCajas', filters, vm.options.tableAjaxParams,
                vm.options.currentColumnOrder).then(function (genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

              CsvFactory.csv("cajas", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
        ],
      'extraRowOptions': [
        /*{
          templateToRender: "<button class='btn btn-success' style='margin-right: 5px;'   title=" + $filter('translate')('REGISTRAR_CAJA') + " ng-click='registrarCaja(<%=dataId%>)' ng-class='{ hidden : !canRegistrar()}'> <span class='glyphicon glyphicon-map-marker'></span> </button>",
          conditionName: 'canRegistrar',
          conditionDef: function () {
            return AuthorizationService.hasPermission("gestionar_cajas");
          },
          functionName: "registrarCaja",
          functionDef: function (itemId) {
            $scope.tituloModal = translations.REGISTRAR_CAJA;
            $scope.mensajeModal = translations.REGISTRAR_CAJA_Q;
            $scope.modalInstanceBorrar1 = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });

            $scope.cancel = function () {
              $scope.modalInstanceBorrar1.dismiss("cancel");
            };

            $scope.ok = function () {
              registerCajas(itemId);
              $scope.modalInstanceBorrar1.dismiss();
            };
          }
        },*/
        {
          templateToRender: "<span ng-class='{ hidden : !canCajaSeleccionada(<%=dataCustom%>)}' class='glyphicon glyphicon-ok' style='font-size: 15px'></span>",
          conditionName: 'canCajaSeleccionada',
          customAttribute: "usuariosAsociados",
          conditionDef: function (usuariosAsociados) {
            var contador = 0;
            _.forEach(usuariosAsociados, function(usuarios) {
                if(usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id){
                  contador++;
                }
            });

            if(contador == 1){
              return true;
            }else{
              return false;
            }
          }
        }
      ]
    };
  });
  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }

  var getIDs = function () {
    var ids = [];
    _.forEach(vm.options.selection, function (n, key) {
      if (n)
        ids.push(key);
    });
    return ids;
  };

  function createAndRegisterNewCaja(puntoEmision) {
    var param = { idPE: puntoEmision.id }
    cajaFactory.createAndRegister(param).$promise.then(function (response) {
      localStorage.setItem("CAJA_ACTUAL", JSON.stringify(response));
      notify({ message: $filter('translate')('CAJA_REGISTRADA'), classes: 'alert-success', position: 'right' });
    }, function (err) {
      errorHandler(err);
    });
  }

  function registerCajas(cajaID) {
    cajaFactory.register({ id: cajaID }).$promise.then(function (response) {
      localStorage.setItem("CAJA_ACTUAL", JSON.stringify(response));
      localStorage.setItem("CAJA_ID", cajaID);
      notify({ message: $filter('translate')('CAJA_REGISTRADA'), classes: 'alert-success', position: 'right' });
    }, function (err) {
      errorHandler(err);
    });
  }

  function unregisterCajas(ids) {
    console.log("unregisterCajas ids", ids);
    if (ids == undefined || ids.length == 0 || ids == []) {
      console.log("error ids");
      notify({ message: $filter('translate')('DEBE_SELECCIONAR_CAJA'), classes: 'alert-danger', position: 'right' });
      return;
    }
    cajaFactory.unregister(ids).then(function (response) {
      notify({ message: $filter('translate')('CAJAS_DESREGISTRADAS'), classes: 'alert-success', position: 'right' });
    }, function (err) {
      errorHandler(err);
    });
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function (error) {
      if (error.message == "Not unique field: nombre") {
        msg += '\n\n' + "Ya existe un rol con el mismo nombre.\n"
      }
      else {
        msg += '\n\n' + error.message + '.'
      }
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }
};


'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:CajaFormCtrl
 * @description
 * # CajaFormCtrl
 * Controller of the rasApp
 */
angular.module('qualita.venta')
    .controller('CajaFormCtrl', CajaFormCtrl);

CajaFormCtrl.$inject = ['$scope', '$rootScope',
    '$location', '$state', 'ModelTrimmer', 'cajaPrepService', 'cajaFactory', 'VentasLangFactory', 'AccesosSistemaFactory', 'UtilFactory', 'formFactory',
    'notify', 'puntoEmisionFactory', '$filter', 'filterFactory', 'ParametrosFactory', 'AlmacenFactory', 'TiposTipoFactory'
];

function CajaFormCtrl($scope, $rootScope, $location, $state,
    ModelTrimmer, cajaPrepService, cajaFactory, VentasLangFactory, AccesosSistemaFactory, UtilFactory, formFactory,
    notify, puntoEmisionFactory, $filter, filterFactory, ParametrosFactory, AlmacenFactory, TiposTipoFactory) {

    var vm = this;

    vm.cancel = cancel;
    vm.submit = submit;

    activate();

    vm.searchPuntoEmision = searchPuntoEmision;

    function searchPuntoEmision(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1

            //item.id == criteria;
        };
    }

    function activate() {
        VentasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;

            vm.puntosEmision = puntoEmisionFactory.all(null, "CajaForm");

            vm.usuarios = [];
            vm.accesos = [];
            vm.accesos2 = [];

            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'usuario.activo',
                equals: true
            }]).value();

            AccesosSistemaFactory.all(staticFilter, null).$promise.then(function (response) {
                _.forEach(response, function (acceso) {
                    vm.usuarios.push(acceso.usuario);
                    vm.accesos2.push(acceso);
                });
            });

            vm.usuariosAsociados = [];

            vm.almacenes = AlmacenFactory.all();

            vm.transferencias = TiposTipoFactory.get("tipo_transferencia_datos_pos");

            //parametro para pos
            ParametrosFactory.getByCodigo('integrado_pos_tdn').then(function (response) {
                vm.habilitarPos = false;
                if (response.valorTipo.codigo === "si") {
                    vm.habilitarPos = true;
                }
            });

            if ($state.is("app.cajas.new")) {
                activateNew();
            } else if ($state.is("app.cajas.edit")) {
                activateEdit();
            } else if ($state.is("app.cajas.view")) {
                activateView();
            }

            $rootScope.isProcessing = false;
            vm.submited = false;

            vm.form = $scope.CajaForm;
        });
    }

    function activateNew() {
        vm.title = $filter('translate')('NEW_CAJA');
        vm.caja = { activo: true, cajaPos: false };
    }

    function activateEdit() {
        vm.title = $filter('translate')('EDIT_CAJA');
        vm.caja = cajaPrepService;
        console.log("caja ", vm.caja);
        $scope.entidad = 'caja';
        $scope.entidadId = vm.caja.id;
        vm.edit = true;
        _.forEach(vm.caja.usuariosAsociados, function (acceso) {
            vm.usuariosAsociados.push(acceso.usuario);
        });
    }

    function activateView() {
        vm.title = $filter('translate')('VIEW_CAJA');
        vm.caja = cajaPrepService;
        vm.entidadId = vm.caja.id;
        vm.entidad = "caja";
        vm.view = true;
        _.forEach(vm.caja.usuariosAsociados, function (acceso) {
            vm.usuariosAsociados.push(acceso.usuario);
        });
    }

    function cancel() {
        $location.path("/cajas");
    }

    function submit() {
        vm.submited = true;
        var trimmedSeccionesEmpresa = ModelTrimmer.trimDetails(vm.caja);
        vm.caja.usuariosAsociados = UtilFactory.getAccesosFromUsuarios(vm.accesos2, vm.usuariosAsociados);
        if ($scope.CajaForm.$valid) {
            submitCaja().then(function (response) {
                ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                    if (parametro.valorTipo.codigo == 'si') {
                        if (response.enviadoAContabilidad == false) {
                            notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                        }
                    }
                });
                $location.path('/cajas');
                return response;
            }, function (error) {
                $rootScope.isProcessing = false;
                var msg = error.data[0].message;
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            });
        } else {
            notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right' });
        }
    }

    function submitCaja() {
        var resource = cajaFactory.create(vm.caja);
        return cajaFactory.save(resource);
    }


    function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function (error) {
            if (error.constraint == 'nombre') {
                msg += '\n\n' + $filter('translate')('CAJA_EXIST') + "\n";
            } else {
                msg += '\n\n' + error.message;
            }
        });
        notify({
            message: msg,
            classes: 'alert-danger',
            position: 'right'
        });
    }

    $scope.resource = 'cajas'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.cajaFactory
 * @description
 * # cajaFactory
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('cajaFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var cajas = $resource(baseurl.getBaseUrl() + '/cajas/:id', { id: '@id', hash: '@hash' }, {
      update: {
        method: 'PUT'
      },
      createAndRegister: {
        method: 'POST',
        url: baseurl.getBaseUrl() + "/cajas/crearregistrar/:ids",
        params: { "idPE": "@idPE" }
      },
      register: {
        method: 'POST',
        url: baseurl.getBaseUrl() + "/cajas/registrar/:id"
      },
      getByHash: {
        method: 'GET',
        url: baseurl.getBaseUrl() + "/cajas/h/:hash",
      },
      getCaja: {
        method: 'GET',
        url: baseurl.getBaseUrl() + "/cajas/c/:id",
      }
    });
    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return cajas.query(params);
      },
      get: function (id, view) {
        return cajas.get({ id: id, view: view != undefined ? view : 'base' });
      },
      getByHash: function(hash, view) {
        return cajas.getByHash({hash: hash, view: view !== undefined ? view : 'BaseList'});
      },
      getCaja: function(id, view) {
        return cajas.getCaja({id: id, view: view !== undefined ? view : 'AccesoSistemaList'});
      },
      create: function (attrs) {
        return new cajas(attrs);
      },
      save: function (cajas) {
        return (cajas.id) ? cajas.$update() : cajas.$save();
      },
      remove: function (cajas) {
        return cajas.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      },
      schema: function () {
        return schema;
      },
      form: function (type) {
        return forms[type];
      },
      createAndRegister: function (params) {
        return cajas.createAndRegister(params);
      },
      register: function (param) {
        return cajas.register(param);
      },

      unregister: function (ids) {
        return $http({
          url: baseurl.getBaseUrl() + '/cajas/desregistrar',
          method: "POST",
          params: { cajas: ids }
        });
      },

      enviarCajaPos: function(searchParams){
        var params = {};
        if (searchParams) {
            params.query = decodeURIComponent($.param(searchParams));
        }

        return $http.post(baseurl.getBaseUrl() + "/cajas/enviopos?" + params.query);
      }
    };
  }]);

'use strict';


angular.module('qualita.venta')
  .controller('PuntoVentaIndexCtrl', PuntoVentaIndexCtrl);

PuntoVentaIndexCtrl.$inject = ["AuthorizationService", "$state",
  "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "puntoVentaFactory",
  "CsvFactory", "UtilFactory", "baseurl", "$filter"];

function PuntoVentaIndexCtrl(AuthorizationService, $state,
  filterFactory, ReportTicketFactory, $window, VentasLangFactory, puntoVentaFactory,
  CsvFactory, UtilFactory, baseurl, $filter) {
  var vm = this;

  var defaultColumnOrder = ['id', 'sucursal.nombre', 'codigoSucursal'];

  /*  var importeRender = function (data) {
          if (data == undefined)
            return "";
          else
            //var dataAux=parseFloat(data).toFixed(4);
            return (data).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };*/

  VentasLangFactory.getTranslations().then(function (translations) {
    vm.options = {
      'resource': 'puntosventa',
      'title': $filter('translate')('PUNTO_VENTA_TITLE'),
      'factory': puntoVentaFactory,
      'view': 'PuntoVentaList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('PUNTO_VENTA_DELETE_ERROR'),
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('ID'), visible: false  },
        { 'data': 'sucursal.nombre', 'title': $filter('translate')('PUNTO_VENTA_SUCURSAL') },
        { 'data': 'codigoSucursal', 'title': $filter('translate')('PUNTO_VENTA_COD_SUCURSAL') }
      ],
      'hasOptions': true,
      'hideAddMenu': false,
      'hideEditMenu': false,
      'hideViewMenu': false,
      'hideRemoveMenu': false,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'extraMenuOptions':
        [
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoPuntosVenta', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function (genTicket) {
                vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open(vm.pdfDownloadURL, '_blank');
              });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

              CsvFactory.csv("puntosventa", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
        ]
    };
  });

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.venta')
  .controller('PuntoVentaFormCtrl', PuntoVentaFormCtrl);

PuntoVentaFormCtrl.$inject = ['$scope','puntoVentaPrepService', '$location','$state', '$timeout',
    'puntoVentaFactory', 'ModelTrimmer', '$rootScope', 'VentasLangFactory', 'notify', 'filterFactory',
    'SucursalesFactory', 'puntoEmisionFactory', '$filter'];

function PuntoVentaFormCtrl($scope, puntoVentaPrepService, $location, $state, $timeout, puntoVentaFactory,
  ModelTrimmer, $rootScope, VentasLangFactory, notify, filterFactory, SucursalesFactory, puntoEmisionFactory, $filter) {

  var vm = this;

  vm.agregarPuntoEmision = agregarPuntoEmision;
  vm.cancel = cancel;
  vm.removeItemFromArray = removeItemFromArray;
  vm.submit = submit;
  vm.puntoEmisionChanged = puntoEmisionChanged;

  vm.removeItemFromArray=removeItemFromArray;


  vm.updateDescripcion = updateDescripcion;

  function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function(item) {
            return item === elemento;
        });
    }

  activate();

   function activate() {
      VentasLangFactory.getTranslations().then(function(translations) {
        vm.translations = translations;
        //vm.tiposDocumentos = tipoDocumentoFactory.all();
        vm.sucursales = SucursalesFactory.all();


      if($state.is('app.puntosventa.new')) {
        activateNew();
      }else if($state.is('app.puntosventa.edit')) {
        activateEdit();
      }else if($state.is('app.puntosventa.view')) {
        activateView();
      }
      $rootScope.isProcessing = false;
    });

  }

  function activateNew() {
    vm.title = $filter('translate')('NEW_PUNTO_VENTA');
    vm.puntoVenta = puntoVentaPrepService;
    vm.puntoVenta = {};
    //console.log("PRUEBA1: "+vm.puntoVenta);
    vm.puntoVenta.puntosEmision=[{}];
    $scope.entidad = "puntoVenta";
    $scope.entidadId = vm.puntoVenta.id;
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_PUNTO_VENTA');
    vm.puntoVenta = puntoVentaPrepService;
    $scope.entidadId = vm.puntoVenta.id;
    $scope.entidad = "puntoVenta";
    $scope.view = true;
    vm.view = true;
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_PUNTO_VENTA');
    vm.puntoVenta = puntoVentaPrepService;
    $scope.entidadId = vm.puntoVenta.id;
    $scope.entidad = "puntoVenta";
    vm.edit = true;
  }

  function agregarPuntoEmision() {
      vm.puntoVenta.puntosEmision.push({});
      $timeout(function(){
        $scope.$broadcast('newItemAdded');
      }, 20);
  }


  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function(item) {
      return item === elemento;
    });
    $timeout(function(){
      $scope.$broadcast('newItemRemoved');
    }, 20);
  }

  function puntoEmisionChanged(detalle) {
    if(!vm.puntoVenta.sucursal || !detalle.numero) {
      return;
    }
    detalle.descripcion = "Sucursal: " + vm.puntoVenta.codigoSucursal + " - " + vm.puntoVenta.sucursal.nombre + ", Punto de Emisión: " + detalle.numero
  }

  function updateDescripcion(){
    for (var i = 0; i < vm.puntoVenta.puntosEmision.length; i++) {
      if((vm.puntoVenta.codigoSucursal != undefined) && (vm.puntoVenta.sucursal != undefined)){
        vm.puntoVenta.puntosEmision[i].descripcion = "Sucursal: " + vm.puntoVenta.codigoSucursal + " - "
        + vm.puntoVenta.sucursal.nombre + ", Punto de Emisión: " + vm.puntoVenta.puntosEmision[i].numero;
      }
    }
  }

  function submit() {

     vm.submited = true;
    if($scope.PuntoVentaForm.$valid) {
      //var trimmedEmpleado = ModelTrimmer.trimDetails(vm.puntoVenta);
      $rootScope.isProcessing = true;
      var puntoVenta = puntoVentaFactory.create(vm.puntoVenta);
      submitPuntoVenta().then(function (puntoventa) {
          $location.path('/puntosventa');
      }, function(error) {
          $rootScope.isProcessing = false;
          console.log("Ha ocurrido un error");
          console.log(error);
          var msg = '';
          if (error.data[0].constraint == 'id_tipo_documento, numero') {
            msg += '\n\n' + $filter('translate')('DOCUMENT_NUMBER_EXIST')+"\n";
          } else if(error.data[0].constraint == 'codigosucursal') {
            msg += '\n\n' + $filter('translate')('COD_SUCURSAL_REPEAT')+"\n";
          } else if(error.data[0].message) {
            msg += '\n\n' + error.data[0].message;
          }

          if(vm.puntoVenta.puntosEmision.length == 0){
            msg = $filter('translate')('PUNTO_EMISION_VACIO')+"\n";
          }

          notify({ message: msg, classes: 'alert-danger', position: 'right'});
      });
    }else{
      notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }

    function submitPuntoVenta(){
        return puntoVentaFactory.save(puntoVenta);
    }
  }

  function cancel() {
    $location.path("/puntosventa");
  }

  $scope.resource = 'puntosventa'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.empleados
 * @description
 * # empleados
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('puntoVentaFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var puntoVenta = $resource(baseurl.getBaseUrl() + '/puntosventa/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return puntoVenta.query(params);
      },
      get: function(id,view) {
        //if (typeof(view)==='undefined') view = "base";
        return puntoVenta.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function (attrs) {
        return new puntoVenta(attrs);
      },
      save: function (puntoventa) {
        return (puntoventa.id) ? puntoventa.$update() : puntoventa.$save();
      },
      remove: function (puntoventa) {
        return puntoventa.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      }
    };
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.empleados
 * @description
 * # empleados
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('puntoEmisionFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var puntoEmision = $resource(baseurl.getBaseUrl() + '/puntosemision/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return puntoEmision.query(params);
      },
      get: function (id, view) {
        console.log(view);
        //if (typeof(view)==='undefined') view = "base";
        return puntoEmision.get({ id: id, view: view != undefined ? view : 'base' });
      },
      create: function (attrs) {
        return new puntoEmision(attrs);
      },
      save: function (puntoemision) {
        return (puntoemision.id) ? puntoemision.$update() : puntoemision.$save();
      },
      remove: function (puntoemision) {
        return puntoemision.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      }
    };
  }]);

angular.module("qualita.venta").factory("VentaFactory", VentaFactory);

VentaFactory.$inject = ["$resource", "baseurl", "$http", "$q"];

function VentaFactory($resource, baseurl, $http, $q) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove,
        getIdVenta: getIdVenta,
        setIdVenta: setIdVenta,
        getCodigoVenta: getCodigoVenta,
        setCodigoVenta: setCodigoVenta,
        getVenta: getVenta,
        setVenta: setVenta,
        getEnabledStates: getEnabledStates,
        setEnabledStates: setEnabledStates,
        checkPermissionForState: checkPermissionForState,
        getDiferencia: getDiferencia,
        getSugerenciasPV: getSugerenciasPV,
        cerrar: cerrar,
        anular: anular,
        actualizarEstado: actualizarEstado,
        getEstados: getEstados,
        getLibroVentaCSV: getLibroVentaCSV,
        getComprobantesPendientesCSV: getComprobantesPendientesCSV,
        getModoAcceso: getModoAcceso,
        setModoAcceso: setModoAcceso,
        reabrirVenta: reabrirVenta,
        getVentaVendedoresCSV: getVentaVendedoresCSV,
    };

    var Venta = $resource(
            baseurl.getBaseUrl() + "/venta/:id", {id: "@id"}, {
        update: {
            method: "PUT"
        },
        anular: {
            method: "PUT",
            url: baseurl.getBaseUrl() + "/venta/anular/:id"
        }
    }
    );

    var idVenta = 1;
    var codigoVenta;
    var venta;
    var enabledStates;
    var modo;

    return service;

    function all(params) {
        return Venta.query(params);
    }

    function get(id, view) {
        return Venta.get({id: id, view: view ? view : "base"});
    }

    function create(attrs) {
        return new Venta(attrs);
    }

    function save(venta) {
        return venta.id ? venta.$update() : venta.$save();
    }

    function remove(venta) {
        return venta.$remove();
    }

    function cerrar(id, cerrarPVParam) {
        return $http({
            url: baseurl.getBaseUrl() + "/venta/cerrar/" + id,
            params: {cerrarPV: cerrarPVParam},
            method: "PUT"
        });
    }

    function actualizarEstado(param, id) {
        return $http({
            url: baseurl.getBaseUrl() + "/venta/actualizarEstado/" + param + "/" + id,
//            params: { cerrarPV: cerrarPVParam },
            method: "GET"
        });
    }
    function getEstados(tipo) {
        return $http({
            url: baseurl.getBaseUrl() + "/venta/estados/" + tipo,
//            params: { cerrarPV: cerrarPVParam },
            method: "GET"
        });
    }

    function diferencia(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/venta/diferencia/" + id,
            method: "PUT"
        });
    }

    function anular(id) {
        return Venta.anular({id: id});
    }

    function getDiferencia(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/venta/diferencias/" + id,
            method: "GET"
        });
    }

    function getSugerenciasPV(id, componente) {
        return $http({
            url: baseurl.getBaseUrl() + "/venta/sugerenciaspv/" + id,
            method: "GET",
            params: {
                componente: componente,
                view: "ConsultaComprobanteVentaForm"
            }
        });
    }

    function getSugerenciasRemision(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/venta/sugerenciasremision/" + id,
            method: "GET"
        });
    }

    function getSugerenciasComprobante(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/venta/sugerenciascomprobante/" + id,
            method: "GET"
        });
    }

    function getIdVenta() {
        return idVenta;
    }

    function setIdVenta(id) {
        idVenta = id;
    }

    function getCodigoVenta() {
        return codigoVenta;
    }

    function setCodigoVenta(codigo) {
        codigoVenta = codigo;
    }

    function getVenta() {
        updateStates();
        return venta;
    }

    function getEnabledStates() {
        return enabledStates;
    }

    function setEnabledStates(states) {
        enabledStates = states;
    }

    function setVenta(vnt) {
        if (vnt.id) {
            venta = vnt;
        } else {
            venta = get(vnt, "BaseList").$promise;
        }
        updateStates();
    }

    function checkPermissionForState(estadoIndex) {
        // console.log("enabled states: ", enabledStates);
        return enabledStates[estadoIndex];
    }

    function getLibroVentaCSV(searchParams) {
        var params = {};
        if (searchParams) {
            params.query = decodeURIComponent($.param(searchParams));
        }

        return $http.post(baseurl.getBaseUrl() + "/libroventas/csv?" + params.query);
    }

    function getComprobantesPendientesCSV(searchParams) {
        var params = {};
        if (searchParams) {
            params.query = decodeURIComponent($.param(searchParams));
        }

        return $http.post(baseurl.getBaseUrl() + "/comprobanteventa/pendientes/csv?" + params.query);
    }

    function updateStates() {
        if (venta && venta.estado && venta.estado.codigo && venta.tipo) {
            var tipo = venta.tipo;
            enabledStates = _.map(tipo.tipoVentaDetalles, function (detalle) {
                return false;
            });
            var tipoVentaDetalle = undefined;
            if (venta.estado.codigo == "venta_abierta") {
                enabledStates[0] = true;
            } else if (venta.estado.codigo == "venta_con_pv") {
                // console.log(tipo.tipoVentaDetalles);
                var tipoVentaDetalle = _.find(tipo.tipoVentaDetalles, function (
                        detalle
                        ) {
                    return detalle.componente.codigo == "comp_tipo_venta_pedido_venta";
                });
            } else if (venta.estado.codigo == "venta_con_remision") {
                var tipoVentaDetalle = _.find(tipo.tipoVentaDetalles, function (
                        detalle
                        ) {
                    return detalle.componente.codigo == "comp_tipo_venta_remision";
                });
            } else if ((venta.estado.codigo == "venta_con_comprobante") || (venta.estado.codigo == "venta_anulada")) {
                var tipoVentaDetalle = _.find(tipo.tipoVentaDetalles, function (
                        detalle
                        ) {
                    return detalle.componente.codigo == "comp_tipo_venta_comprobante";
                });
            }
            if (tipoVentaDetalle) {
                enabledStates[tipoVentaDetalle.orden] = true;
                for (var i = 0; i < tipoVentaDetalle.orden; i++) {
                    enabledStates[i] = true;
                }
            } else if (venta.estado.codigo == "venta_cerrada") {
                enabledStates = _.map(enabledStates, function (e) {
                    return true;
                });
                enabledStates[enabledStates.length] = true;
            } else {
                //console.log("No se encontró tipoVentaDetalle");
            }
        }
    }

    function getModoAcceso() {
        updateStates();
        return modo;
    }

    function setModoAcceso(modoAcceso) {
        modo = modoAcceso;
        updateStates();
    }

    function reabrirVenta(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/venta/reabrir/" + id,
            method: "PUT"
        });
    }

    function getVentaVendedoresCSV(searchParams) {
        var params = {};
        if (searchParams) {
            params.query = decodeURIComponent($.param(searchParams));
        }

        return $http.post(baseurl.getBaseUrl() + "/ventavendedores/csv?" + params.query);
    }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.venta')
  .factory('TipoVentaFactory', TipoVentaFactory);

TipoVentaFactory.$inject = ['$resource', 'baseurl', '$http', 'VentasLangFactory'];

function TipoVentaFactory($resource, baseurl, $http, VentasLangFactory) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var TipoVentas = $resource( baseurl.getBaseUrl() + "/tiposventas/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });


  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'base';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'base';
    }
    return TipoVentas.query(params);
  }

  function get(id, view) {
    return TipoVentas.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new TipoVentas(attrs);
  }

  function save(tipoventa) {
    return (tipoventa.id) ? tipoventa.$update() : tipoventa.$save();
  }

  function remove(tipoventa) {
    return tipoventa.$remove();
  }
}

"use strict";

angular.module("qualita.venta").controller("TipoVentaFormCtrl", [
  "TipoVentaFactory",
  "filterFactory",
  "$modal",
  "$scope",
  "notify",
  "$state",
  "$rootScope",
  "tipoVentaPrepService",
  "$controller",
  "formFactory",
  "VentasLangFactory",
  "$location",
  "TiposFactory",
  "$filter",
  function(
    TipoVentaFactory,
    filterFactory,
    $modal,
    $scope,
    notify,
    $state,
    $rootScope,
    tipoVentaPrepService,
    $controller,
    formFactory,
    VentasLangFactory,
    $location,
    TiposFactory,
    $filter
  ) {
    var vm = this;

    vm.mostrarDetalle = true;

    VentasLangFactory.getTranslations().then(function(translations) {
      $scope.resource = "tiposventas";
      $scope.prepService = tipoVentaPrepService;
      $scope.submit = submit;
      $scope.cancel = cancel;
      $scope.submitTipoVenta = submitTipoVenta;
      vm.changeCheckPlanillaReparto = changeCheckPlanillaReparto;
      $scope.translations = translations;

      $scope.newProperties = {
        state: "app.tiposventas.new",
        title: translations.NEW_TIPO_VENTA
      };
      $scope.editProperties = {
        state: "app.tiposventas.edit",
        title: translations.EDIT_TIPO_VENTA,
        entidad: "TipoVenta"
      };

      $scope.viewProperties = {
        state: "app.tiposventas.view",
        title: translations.VIEW_TIPO_VENTA,
        entidad: "TipoVenta"
      };

      $scope.restoreList = function() {
        if(vm.mostrarDetalle){
          $scope.tipoVenta.tipoVentaDetalles = [];
          _.forEach($scope.componentes, function(val) {
            var detalle = {};
            if (val.codigo == "comp_tipo_venta_comprobante") {
              detalle.realizaEgresoStock = true;
            } else {
              detalle.realizaEgresoStock = false;
            }
            detalle.componente = val;
            $scope.tipoVenta.tipoVentaDetalles.push(detalle);
          });
        }
      };

      $scope.select = function(node) {
        _.forEach($scope.tipoVenta.tipoVentaDetalles, function(anode) {
          anode.realizaEgresoStock = false;
        });
        node.realizaEgresoStock = true;
      };

      $scope.activate = function() {
        if ($state.is($scope.newProperties.state)) {
          activateNew();
        } else if ($state.is($scope.editProperties.state)) {
          activateEdit();
        } else if ($state.is($scope.viewProperties.state)) {
          activateView();
        }

        $rootScope.isProcessing = false;
      };

      function activateNew() {
        $scope.title = $scope.newProperties.title;
        $scope.tipoVenta = { activo: true, fechaInicioVigencia: new Date() };
        $scope.tipoVenta.incluirPlanillaReparto = false;
        TiposFactory.componentesVenta().then(function(response) {
          $scope.componentes = $filter("orderBy")(response.data, "id");
          $scope.restoreList();
        });
      }

      function activateEdit() {
        $scope.tipoVenta = $scope.prepService;
        $scope.entidadId = $scope.tipoVenta.id;
        $scope.entidad = $scope.editProperties.entidad;
        $scope.title = $scope.editProperties.title;
        $scope.tipoVenta.tipoVentaDetalles = $filter("orderBy")(
          $scope.tipoVenta.tipoVentaDetalles,
          "orden"
        );
        TiposFactory.componentesVenta().then(function(response) {
          $scope.componentes = $filter("orderBy")(response.data, "id");
          if ($scope.tipoVenta.ventas.length > 0) {
            vm.mostrarDetalle = false;
          }
        });
      }

      function activateView() {
        $scope.tipoVenta = $scope.prepService;
        $scope.entidadId = $scope.tipoVenta.id;
        $scope.entidad = $scope.viewProperties.entidad;
        $scope.title = $scope.viewProperties.title;
        $scope.tipoVenta.tipoVentaDetalles = $filter("orderBy")(
          $scope.tipoVenta.tipoVentaDetalles,
          "orden"
        );
        $scope.view = true;
      }

      function cancel() {
        $location.path("/tipoventa");
      }

      $scope.activate();

      function submit() {
        $scope.submited = true;
        if ($scope.TipoVentaForm.$valid) {
          var detalles = [];
          var tieneGenerarMovimiento;
          _.forEach($scope.tipoVenta.tipoVentaDetalles, function(value, index) {
            if (value.realizaEgresoStock == true) {
              tieneGenerarMovimiento = value.realizaEgresoStock;
            }
            value.orden = index + 1;
          });
          if (tieneGenerarMovimiento) {
            $rootScope.isProcessing = true;
            submitTipoVenta().then(function(tipoVenta) {
              $location.path("/tipoventa");
            }, errorHandler);
          } else {
            var msg = translations.ERROR_NINGUN_COMP_GENERA_MOVIMIENTO;
            notify({
              message: msg,
              classes: "alert-danger",
              position: "right"
            });
          }
        }
      }

      function submitTipoVenta() {
        var tipoVenta = TipoVentaFactory.create($scope.tipoVenta);
        return TipoVentaFactory.save(tipoVenta, function(resp, headers) {
          //success callback
          console.log("RESPONSE: ", resp);
        });
      }

      function changeCheckPlanillaReparto() {
        if ($scope.tipoVenta.incluirPlanillaReparto) {
          var pedidoVentaExists = _.find($scope.tipoVenta.tipoVentaDetalles, function(atributo) {
            return atributo.componente.codigo == "comp_tipo_venta_pedido_venta";
          });
          if(!pedidoVentaExists){
            _.forEach($scope.componentes, function (val) {
              var detalle = {};
              if (val.codigo == "comp_tipo_venta_pedido_venta") {
                detalle.componente = val;
                $scope.tipoVenta.tipoVentaDetalles.push(detalle);
              }
  
            });
          }
        }
      }

      function errorHandler(err) {
        console.log("Error al guardar Tipo Venta: ", err);
        $rootScope.isProcessing = false;
        $scope.submited = false;
        var msg = "";
        _.forEach(err.data, function(error) {
          if (error.constraint == "orden, id_tipo_venta") {
            // TODO CAMBIAR esta condicion
            msg += "\n\n" + translations.TIPO_VENTA_ALREADY_EXISTS + "\n";
          } else {
            msg += "\n\n" + error.message + ".";
          }
        });
        notify({
          message: msg,
          classes: "alert-danger",
          position: "right"
        });
      }
    });
  }
]);

'use strict';

angular.module('qualita.venta')
  .controller('TipoVentaListCtrl', ["AuthorizationService", "TipoVentaFactory", "TiposFactory", "$log", "$modal", "$scope", "VentasLangFactory", "$state", "UtilFactory", "$window", "CsvFactory", "baseurl", function TipoVentaListCtrl(AuthorizationService, TipoVentaFactory,
    TiposFactory, $log, $modal, $scope, VentasLangFactory, $state, UtilFactory, $window, CsvFactory, baseurl) {

    VentasLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'nombre', 'fechaInicioVigencia', 'fechaFinVigencia', 'activo'];

      $scope.options = {
        'resource': 'tiposventas',
        'title': translations.TIPOS_VENTA,
        'factory': TipoVentaFactory,
        'view': 'BaseList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': translations.FAIL_DELETE_TIPO_VENTA,
        'columns': [
          { 'data': 'id', 'title': translations.CODE, 'visible': false  },
          { 'data': 'nombre', 'title': translations.NAME },
          { 'data': 'fechaInicioVigencia', 'title': translations.FECHA_INICIO_VIGENCIA, 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
          { 'data': 'fechaFinVigencia', 'title': translations.FECHA_FIN_VIGENCIA, 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
          { 'data': 'activo', 'title': translations.ACTIVE, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'hideRemoveMenu': true,
        'hideViewMenu': true,
        'extraMenuOptions': [
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

              CsvFactory.csv("tiposventas", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }

        ],
        'extraRowOptions': [
          {
            templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + translations.DELETE + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
            customAttribute: 'activo',
            conditionName: "canBorrar",
            conditionDef: function (activo) {
              return activo == 'Sí' && AuthorizationService.hasPermission('delete_tiposventas');
            },
            functionName: "borrar",
            functionDef: function (itemId) {
              $scope.selectedItemId = itemId;
              $scope.tituloModal = translations.DELETE_CONFIRMATION;
              $scope.mensajeModal = translations.DELETE_WARNING;
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              $scope.ok = function (itemId) {
                var model = TipoVentaFactory.create({ id: itemId });
                TipoVentaFactory.remove(model).then(function () {
                  modalInstance.close(itemId);
                  $state.go($state.current, {}, { reload: true });
                }, function (error) {
                  modalInstance.dismiss('cancel');
                  $scope.tituloModal = translations.DELETE_FAILED;
                  $scope.mensajeModal = error.data[0].message;

                  var modalInstance2 = $modal.open({
                    templateUrl: 'views/directives/information-modal.html',
                    scope: $scope
                  });
                  $scope.cancel = function () {
                    modalInstance2.dismiss('cancel');
                  };
                });
              }
            }
          },
          {
            templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" + translations.VIEW + "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
            conditionName: 'canView',
            conditionDef: function () {
              return AuthorizationService.hasPermission('index_tiposventas');
            },
            functionName: "view",
            functionDef: function (itemId) {
              return $state.go("app.tiposventas.view", { id: itemId });
            }
          }
        ]/*,
      'extraEditConditions': function(item) {
        return item.activo == 'Sí';
      },*/
      };
    });
  }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.venta')
  .controller('ClienteFormCtrl', ClienteFormCtrl);

ClienteFormCtrl.$inject = ['$rootScope', 'filterFactory', '$scope', 'ClienteFactory', '$location', '$state',
  'clientePrepService', 'notify', 'formFactory', 'VentasLangFactory', 'TiposFactory', 'TipoClienteFactory', '$timeout', '$filter', 'ParametrosFactory', 'TiposTipoFactory',
  'baseurl', 'SolicitudAutorizacionFactory', 'VendedorFactory', 'ZonaFactory', 'RutaFactory', 'TipoActividadRamoClienteFactory', 'FormaPagoFactory', 'CategoriaClienteFactory', 'ClienteSucursalFactory','AuthorizationService',
  '$modal', 'SegmentoClienteFactory', 'SucursalesFactory', 'UnidadesNegocioFactory', 'CentroCostosFactory'];

function ClienteFormCtrl($rootScope, filterFactory, $scope, ClienteFactory, $location, $state,
  clientePrepService, notify, formFactory, VentasLangFactory, TiposFactory, TipoClienteFactory, $timeout, $filter, ParametrosFactory, TiposTipoFactory,
  baseurl, SolicitudAutorizacionFactory, VendedorFactory, ZonaFactory, RutaFactory, TipoActividadRamoClienteFactory, FormaPagoFactory, CategoriaClienteFactory, ClienteSucursalFactory,AuthorizationService,
  $modal, SegmentoClienteFactory, SucursalesFactory, UnidadesNegocioFactory, CentroCostosFactory) {


  var vm = this;
  $scope.form ={};

  activate();
  //var $scope = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.changePersona = changePersona;
  vm.isAnySucursalEmpty = isAnySucursalEmpty;
  vm.isAnyContactoEmpty = isAnyContactoEmpty;
  vm.removeItemFromArray = removeItemFromArray;
  vm.agregarSucursal = agregarSucursal;
  vm.agregarContacto = agregarContacto;
  vm.isInAnyContacto = isInAnyContacto;
  vm.descargarAdjunto = descargarAdjunto;
  vm.removeItemFromArray = removeItemFromArray;
  vm.recalcularSaldo = recalcularSaldo;
  vm.volverSolicitud = volverSolicitud;
  vm.changeCondicionVenta = changeCondicionVenta;
  vm.changeTipoVenta = changeTipoVenta;
  vm.sucursalSelected = sucursalSelected;
  vm.validarDecimal = validarDecimal;

  function recalcularSaldo() {
    ClienteFactory.getSaldoCredito(vm.cliente.id).then(function (resp) {
      vm.cliente.saldoCredito = Math.round(vm.cliente.limiteCredito - resp.data);
    });
  }

  function adjuntarArchivos(cliente) {
    // se agregan los adjuntos de imagenes
    var flowAdjunto = vm.uploadOptions2.flow;
    if (flowAdjunto) {
      var flowFilesAdjunto = flowAdjunto.files;
      var index = 0;
      if (!cliente.adjuntos) {
        cliente.adjuntos = [];
      } else {
        index = cliente.adjuntos.length;
      }
      if (flowFilesAdjunto.length > 0) {
        angular.forEach(flowFilesAdjunto, function (file) {
          cliente.adjuntos[index] = {};
          cliente.adjuntos[index].path = file.uniqueIdentifier + '-' + file.name;
          index++;
        });
      }
    }
  }

  function descargarAdjunto(adjunto) {
    return baseurl.getPublicBaseUrl() + adjunto;
  }

  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function (item) {
      return item === elemento;
    });
  }

  function rucIsValid() {
    console.log("vm.cliente.numero", vm.cliente.numero);
    var p_numero = vm.cliente.numero.split('-')[0];
    var p_digit = vm.cliente.numero.split('-')[1];
    var p_basemax = 11;
    var v_total, v_resto, k, v_numero_aux, v_digit;
    var i;

    k = 2;
    v_total = 0;

    for (i = p_numero.length - 1; i >= 0; i--) {
      k = k > p_basemax ? 2 : k;
      v_numero_aux = p_numero.charAt(i);
      v_total += v_numero_aux * k++;
    }

    v_resto = v_total % 11;
    v_digit = v_resto > 1 ? 11 - v_resto : 0;

    return v_digit == p_digit;
  }

  function changePersona() {
    // if (vm.cliente.tipoPersona.codigo === "tipo_persona_cliente_juridica") {
    //   _.forEach(vm.tiposDocumentod, function (elem) {
    //     if (elem.codigo === "tipo_documento_cliente_ruc") {
    //       vm.cliente.tipoDocumento = elem;
    //     }
    //     vm.disableDocumento = true;
    //   });
    // } else {
    //   vm.cliente.tipoDocumento = undefined;
    //   vm.disableDocumento = false;
    // }

  }

  function isInAnyContacto(elemento) {
    var isAny = false;
    // si hay algun contacto asociado a la sucursal, se inhabilita la edicion de su nombre
    _.forEach(vm.cliente.contactos, function (contacto) {
      if (contacto.sucursal != undefined && contacto.sucursal.descripcion === elemento.descripcion) {
        isAny = true;
      }
    });
    return isAny;
  }

  function agregarContacto() {
    vm.cliente.contactos.push({});
    $timeout(function () {
      $scope.$broadcast('newItemContactoAdded');
    }, 20);
  }

  function isAnyContactoEmpty(arreglo) {
    var broken = false;
    _.forEach(arreglo, function (item) {
      if (!item.nombre) {
        broken = true;
      }

    });
    return broken;
  }

  function activate() {
    var filtroActivo = {};
    filtroActivo.search = filterFactory.and([
      {
        path: 'activo',
        equals: true
      }
    ]).value();
    vm.segmentos = SegmentoClienteFactory.all(filtroActivo);
    vm.auxEdit = 0;
    ParametrosFactory.getByCodigo('generacion_factura_electronica').then(function (response) {
      vm.datoVirtual = false;
      if (response.valorTipo.codigo === "si") {
        vm.datoVirtual = true;
      }
    });
    TiposFactory.ventaTipoOperacion().then(function (data) {
      vm.tipoOperaciones = data.data;
    });
    TiposFactory.condicionCobroPedidoVenta().then(function (data) {
      vm.tipoVentas = data.data;
    });
    vm.staticFilterDepartamento = [{ path: "tipoTipo.codigo", equals: "pais_departamento" }];
    vm.staticFilterDistrito = [{ path: "tipoTipo.codigo", equals: "pais_distrito" }];
    vm.staticFilterCiudad = [{ path: "tipoTipo.codigo", equals: "pais_ciudad" }];
    vm.staticFilterPais = [{ path: "tipoTipo.codigo", equals: "pais_pais" }];
    vm.staticFilterBarrio = [{ path: "tipoTipo.codigo", equals: "pais_barrio" }];
    vm.staticFilterSucursal = [];
    vm.staticFilterUnidadNegocio = [];
    vm.TiposFactory = TiposFactory;

    vm.disableDocumento = false;
    var filtroCobrador = {};
    filtroCobrador.search = filterFactory.and([
      {
        path: 'cobrador',
        equals: true
      },
      {
        path: 'activo',
        equals: true
      }
    ]).value();
    filtroCobrador.view = "VendedorList";
    vm.cobradores = VendedorFactory.all(filtroCobrador);
    var filtroGestor = {};
    filtroGestor.search = filterFactory.and([
      {
        path: 'gestor',
        equals: true
      },
      {
        path: 'activo',
        equals: true
      }
    ]).value();
    filtroCobrador.view = "VendedorList";
    vm.gestores = VendedorFactory.all(filtroGestor);
    var paramsZon = {};
    paramsZon.search = filterFactory.and([
      {
        path: 'activo',
        like: 'true'
      }]
    ).value();
    vm.zonas = ZonaFactory.all(paramsZon, "BaseForm");
    vm.rutas = RutaFactory.all(paramsZon, "BaseForm");
    vm.tipoActividadesRamosClientes = TipoActividadRamoClienteFactory.all(paramsZon, "BaseForm");
    vm.categoriasClientes = CategoriaClienteFactory.all();



    var filtroServicios = {};
    filtroServicios.search = filterFactory.and([{
      path: 'clase.codigo',
      equals: 'forma_pago_referencia'
    }]).value();
    filtroServicios.view = "ClienteForm";
    FormaPagoFactory.all(filtroServicios, "ClienteForm").$promise.then(function (data){
      vm.formasPagos = data;
      vm.formasPagosAux = angular.copy(data);
      filterFormasPago()
    });
    var filtroVendedor = {};
    filtroVendedor.search = filterFactory.and([
      {
        path: 'vendedor',
        equals: true
      },
      {
        path: 'activo',
        equals: true
      }
    ]).value();
    filtroVendedor.view = "VendedorList";
    vm.vendedores = VendedorFactory.all(filtroVendedor, "BaseForm");


    vm.cliente = clientePrepService;
    vm.showDatatable = false;
    vm.staticFilter;
    if (vm.cliente != undefined) {
      vm.staticFilter = {
        search: filterFactory.and([{
          path: "cliente.id",
          equals: vm.cliente.id
        }]).value()
      };
      vm.showDatatable = true;
    }



    VentasLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;

      var defaultColumnOrder = ['id','descripcion','telefono','direccion','codigoSucursal','ciudad.descripcion','vendedor.nombresyapellidos','departamento.descripcion'];
      if(vm.showDatatable){
        $scope.options = {
          'staticFilter': vm.staticFilter,
          'resource': 'clientesucursal',
          title: $filter("translate")("SUCRSALES"),
          'factory': ClienteSucursalFactory,
          'view': 'BaseList',
          'defaultColumnOrder': defaultColumnOrder,
          'columns': [
            { 'data': 'id', 'title': translations.ID, visible: false },
            { 'data': 'descripcion', 'title': $filter("translate")("DESCRIPCION"), 'render': renderEmptyData },
            { 'data': 'telefono', 'title': $filter("translate")("TELEFONO"), 'render': renderEmptyData },
            { 'data': 'direccion', 'title': $filter("translate")("DIRECCION"), 'render': renderEmptyData },
            { 'data': 'codigoSucursal', 'title': $filter("translate")("PUNTO_VENTA_COD_SUCURSAL"), 'render': renderEmptyData },
            { 'data': 'ciudad.descripcion', 'title': $filter("translate")("CIUDAD"), 'render': renderEmptyData },
            { 'data': 'vendedor.nombresyapellidos', 'title': $filter("translate")("VENDEDOR"), 'render': renderEmptyData },
            { 'data': 'departamento.descripcion', 'title': $filter("translate")("DEPARTAMENTO"), 'render': renderEmptyData },
            { 'data': 'gln', 'title': $filter("translate")("GLN"), 'render': renderEmptyData },
            { 'data': 'sucursal.nombre', 'title': $filter("translate")("SUCURSAL"), 'render': renderEmptyData },
            { 'data': 'unidadNegocio.nombre', 'title': $filter("translate")("UNIDAD_NEGOCIO"), 'render': renderEmptyData }
          ],
          'hasOptions': true,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "desc",
          'hideRemoveMenu': true,
          'hideAddMenu': true,
          'hideEditMenu': true,
          'hideViewMenu': true,
          'extraRowOptions': [{
            templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + translations.DELETE + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
            customAttribute: 'id',
            conditionName: "canBorrar",
            conditionDef: function (id) {
              return (AuthorizationService.hasPermission('delete_clientesucursal') && !vm.view);

            },
            functionName: "borrar",
            functionDef: function (itemId) {
              $scope.selectedItemId = itemId;
              $scope.tituloModal = translations.DELETE_CONFIRMATION;
              $scope.mensajeModal = translations.DELETE_WARNING;
              var modalInstance = $modal.open({
                templateUrl: 'views/directives/confirmation-modal.html',
                scope: $scope
              });

              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              $scope.ok = function (itemId) {
                var model = ClienteSucursalFactory.create({ id: itemId });
                ClienteSucursalFactory.remove(model).then(function () {
                  modalInstance.close(itemId);
                  $scope.options.reloadData();
                }, function (error) {
                  modalInstance.dismiss('cancel');
                  notify({ message: error.data[0].message, classes: "alert-danger", position: "right" });
                });
              }
            }
          },{
            templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' title='" + $filter('translate')('EDIT') + "' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
            conditionName: "canEditar",
            conditionDef: function () {
              return (AuthorizationService.hasPermission('update_clientesucursal') && !vm.view);
            },
            functionName: "editar",
            functionDef: function (itemId) {
              if(vm.auxEdit==0){
                vm.auxEdit++;
                ClienteSucursalFactory.get(itemId, "BaseList").$promise.then(function (clienteSucursal) {
                  abmClienteSucursal(clienteSucursal, false);
                });
              }

            }
          }, {
            templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" +
              $filter("translate")("VIEW") +
              "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
            conditionName: "canView",
            conditionDef: function () {
              return AuthorizationService.hasPermission("index_clientesucursal");
            },
            functionName: "view",
            functionDef: function (itemId) {
              ClienteSucursalFactory.get(itemId, "BaseList").$promise.then(function (clienteSucursal) {
                abmClienteSucursal(clienteSucursal, true);
              });
            }
          }],
          'extraMenuOptions':[{
            title: $filter("translate")("NEW"),
            icon: "glyphicon glyphicon-plus",
            showCondition: function () {
                var permission = AuthorizationService.hasPermission(
                    "create_clientesucursal"
                );
                return permission && !vm.view;
            },
            action: function () {
                var clienteSucursal = undefined;
                abmClienteSucursal(clienteSucursal, false);
            }
        }]
        };
      }

      if ($state.is("app.clientes.new")) {
        activateNew();
      } else if ($state.is("app.clientes.edit")) {
        activateEdit();
      } else if ($state.is("app.clientes.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;


      TiposFactory.tipoPersonaCliente().then(function (data) {
        vm.tiposPersonas = data.data;
      });


      vm.tiposClientes = TipoClienteFactory.all();
      TiposFactory.tipoDocumentoCliente().then(function (data) {
        vm.tiposDocumentod = data.data;

      });
      vm.diasEntregaFacturas = TiposTipoFactory.get("todos_dias_semana");
      vm.diasEntregaEstadoCuenta = TiposTipoFactory.get("todos_dias_semana");
      vm.diasCobro = TiposTipoFactory.get("todos_dias_semana");
    });

  }

  function activateNew() {
    vm.title = $scope.translations.NEW_CLIENTE;
    vm.cliente = {};
    vm.cliente.activo = true;
    vm.cliente.exoneradoIva = false;
    //vm.cliente.sucursales = [{}];
    vm.cliente.contactos = [{}];
    vm.uploadOptions2 = {
      imageOnly: false
    };
    vm.cliente.saldoCuentaCliente = 0;
    vm.cliente.entregaRemisiones = 0;
    vm.cliente.saldoCredito = 0;
    vm.cliente.pedidosVenta = 0;
    vm.cliente.chequeCartera = 0;
    vm.controlLimite = AuthorizationService.hasPermission('update_limite_credito');

    ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response) {
      vm.habilitarLimiteCredito = true;
      if (response.valorTipo.codigo === "si") {
        vm.habilitarLimiteCredito = false;
        vm.cliente.controlarLimiteCredito = true;
      }
    });
  }

  function activateEdit() {
    console.log("edit");
    vm.controlLimite = AuthorizationService.hasPermission('update_limite_credito');
    vm.title = $scope.translations.EDIT_CLIENTE;
    vm.cliente = clientePrepService;
    console.log("vm.cliente", vm.cliente);
    vm.entidadId = vm.cliente.id;
    vm.entidad = "Cliente";
    updateSucursalesDisponibles();
    vm.uploadOptions2 = {
      imageOnly: false
    };

    // if (vm.cliente.tipoPersona.codigo === "tipo_persona_cliente_juridica") {
    //   vm.disableDocumento = true;
    // }

    ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response) {
      vm.habilitarLimiteCredito = true;
      if (response.valorTipo.codigo === "si") {
        vm.habilitarLimiteCredito = false;
      }
    });

    ClienteFactory.getSaldoCuenta(vm.cliente.id).then(function (resp) {
      vm.cliente.saldoCuentaCliente = Math.round(resp.data);
    });

    ClienteFactory.getSaldoRemisiones(vm.cliente.id).then(function (resp) {
      vm.cliente.entregaRemisiones = Math.round(resp.data);
    });

    ParametrosFactory.getByCodigo('control_limite_credito_cliente_pedido_venta').then(function (response) {
      if (response.valorTipo.codigo === "si") {
        ClienteFactory.getSaldoPedido(vm.cliente.id).then(function (resp) {
          vm.cliente.pedidosVenta = Math.round(resp.data);
        });
      } else {
        vm.cliente.pedidosVenta = 0;
      }
    });

    ClienteFactory.getSaldoCheque(vm.cliente.id).then(function (resp) {
      vm.cliente.chequeCartera = Math.round(resp.data);
    });

    ClienteFactory.getSaldoCredito(vm.cliente.id).then(function (resp) {
      vm.cliente.saldoCredito = Math.round(vm.cliente.limiteCredito - resp.data);
    });

    changeCondicionVenta();

  }

  function activateView() {
    console.log("view");
    vm.title = $scope.translations.VIEW_CLIENTE;
    vm.cliente = clientePrepService;
    vm.entidadId = vm.cliente.id;
    vm.entidad = "Cliente";
    vm.view = true;
    ClienteFactory.getSaldoCuenta(vm.cliente.id).then(function (resp) {
      vm.cliente.saldoCuentaCliente = resp.data;
    });

    ClienteFactory.getSaldoRemisiones(vm.cliente.id).then(function (resp) {
      vm.cliente.entregaRemisiones = resp.data;
    });

    ParametrosFactory.getByCodigo('control_limite_credito_cliente_pedido_venta').then(function (response) {
      if (response.valorTipo.codigo === "si") {
        ClienteFactory.getSaldoPedido(vm.cliente.id).then(function (resp) {
          vm.cliente.pedidosVenta = resp.data;
        });
      } else {
        vm.cliente.pedidosVenta = 0;
      }
    });

    ClienteFactory.getSaldoCheque(vm.cliente.id).then(function (resp) {
      vm.cliente.chequeCartera = resp.data;
    });

    ClienteFactory.getSaldoCredito(vm.cliente.id).then(function (resp) {
      vm.cliente.saldoCredito = vm.cliente.limiteCredito - resp.data;
    });

    var staticFilter = {};
    staticFilter.search = filterFactory.and([{
      path: 'clienteSobregiro.id',
      equals: vm.cliente.id,
    }]).value();
    SolicitudAutorizacionFactory.all(staticFilter).$promise.then(function (response) {
      console.log("lll ", response)

      if (response.length > 0) {
        vm.mostrarVolver = true;
      } else {
        vm.mostrarVolver = false;
      }
      console.log("mos ", vm.mostrarVolver)
    });
    changeCondicionVenta();

  }

  function isAnySucursalEmpty(arreglo) {
    var broken = false;
    _.forEach(arreglo, function (item) {
      if (!item.descripcion) {
        broken = true;
      }

    });
    return broken;
  }

  function agregarSucursal() {
    vm.cliente.sucursales.push({});
    updateSucursalesDisponibles();
    $timeout(function () {
      $scope.$broadcast('newItemSucursalAdded');
    }, 20);
  }

  function updateSucursalesDisponibles() {
    vm.sucursalesDisponibles = vm.cliente.sucursales;
  }

  function removeItemFromArray(elemento, arreglo, esSucursal) {
    _.remove(arreglo, function (item) {
      return item === elemento;
    });
    if (esSucursal) {
      // si hay algun contacto asociado a la sucursal, se borra
      _.forEach(vm.proveedor.contactos, function (contacto) {
        if (contacto.sucursal.descripcion === elemento.descripcion) {
          contacto.sucursal = undefined;
        }
      })
    }
  }

  function clearAdjuntos() {
    var flow = $rootScope.flow;
    if (!flow) {
      return;
    }
    flow.files = [];
  };

  function submit() {
    console.log("submit");
    if(vm.cliente.segmentoCliente == undefined){
      vm.cliente.segmentoCliente = null;
    }
    console.log("SEGMENTO: ", vm.cliente.segmentoCliente);
    console.log("desc: ", vm.cliente.descuentoPorDefecto);

    vm.submited = true;
    if ($scope.ClienteForm.$valid) {
      adjuntarArchivos(vm.cliente);
      console.log("vm.cliente.tipoPersona.codigo", vm.cliente.tipoPersona.codigo);
      if (vm.cliente.tipoPersona.codigo === "tipo_persona_cliente_juridica" && vm.cliente.tipoDocumento.codigo === 'tipo_documento_cliente_ruc' && !rucIsValid()) {
        console.log("dv invalido");
        var msg = $scope.translations.ERROR_DIGITO_VERIFICADOR;
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
        return;
      }
     // vm.cliente.sucursales = _.filter(vm.cliente.sucursales, function (elem) { return elem.descripcion; });
      vm.cliente.contactos = _.filter(vm.cliente.contactos, function (elem) { return elem.nombre; });

      if (vm.cliente.tipoOperacion == undefined) {
        vm.cliente.tipoOperacion = null;
      }

      if (vm.cliente.id != undefined) {
        ClienteFactory.get(vm.cliente.id, "BaseForm").$promise.then(function (response) {
          vm.cliente.sucursales = response.sucursales;
          formFactory.defaultNSFSubmit($scope.ClienteForm, ClienteFactory, vm.cliente, errorHandler).then(function (response) {
            clearAdjuntos();
            $location.path('/clientes');
            ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
              if (parametro.valorTipo.codigo == 'si') {
                if (response.enviadoAContabilidad == false) {
                  notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                }
              }
            });
          }, function (error) {
            console.log(error);
          });
        });
      } else {
        formFactory.defaultNSFSubmit($scope.ClienteForm, ClienteFactory, vm.cliente, errorHandler).then(function (response) {
          clearAdjuntos();
          $location.path('/clientes');
          ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
            if (parametro.valorTipo.codigo == 'si') {
              if (response.enviadoAContabilidad == false) {
                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
              }
            }
          });
        }, function (error) {
          console.log(error);
        });
      }
    } else {
      //notify({ message: $scope.translations.WRONG_FIELDS, classes: 'alert-danger', position: 'right'});
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function (error) {
      if (error.constraint == 'codigo_sucursal, id_cliente') {
        msg += '\n\n' + $filter('translate')('SUCURSAL_EXISTS') + "\n"
      } else {
        msg += '\n\n' + error.message + '.';
      }

    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function cancel() {
    $location.path("/clientes");
  }

  function changeCondicionVenta() {
    if (vm.cliente.formaPago) {
      vm.cantidadCuotas = vm.cliente.formaPago.cantidadCuotas;
      vm.plazoDias = vm.cliente.formaPago.plazoDias;
      vm.descripcionAbreviada = vm.cliente.formaPago.descripcionAbreviada;
    }
  }
  function changeTipoVenta() {
    vm.cliente.formaPago = null;
    vm.cantidadCuotas = null;
    vm.plazoDias = null;
    vm.descripcionAbreviada = null;
    filterFormasPago();
  }
  function filterFormasPago() {
    if(vm.cliente.tipoVenta){
      if(vm.cliente.tipoVenta.codigo === "condicion_cobro_pedido_venta_contado"){
        vm.formasPagos = _.filter(vm.formasPagosAux, function (formaPago) {
          return formaPago.cantidadCuotas == null;
        });
      } else {
        vm.formasPagos = _.filter(vm.formasPagosAux, function (formaPago) {
          return formaPago.cantidadCuotas != null;
        });
      }
    }
  }

  var renderEmptyData = function (data) {
    if (data == undefined)
      return "";
    else
      return data;
  };

  function getCentroCostos() {
    CentroCostosFactory.all().$promise.then(function(response) {
      vm.centroCostos = response;
    });
  }

  function getUnidadesNegocio() {
    var sucursales = _.map($rootScope.AuthParams.locaciones, function(locacion) {
      return locacion.sucursal;
    });

    // Eliminar sucursales duplicadas,
    // el modelo de datos permite que un usuario este asociado varias veces a una misma sucursal.
    sucursales = _.unique(sucursales, function(value){
      return value.nombre
    });

    vm.sucursales = [];

    for (var i = 0; i < sucursales.length; i++) {
      SucursalesFactory.get(sucursales[i].id, 'SucursalList').$promise.then(function(response) {
        vm.sucursales.push(response);
      });
    }

    var unidadesNegocio = _.map(_.filter($rootScope.AuthParams.locaciones, function(locacion) {
      return vm.detalle ? (vm.detalle.sucursal ? locacion.sucursal.id == vm.detalle.sucursal.id : true) : true;
    }), function(locacion) {
      return locacion.unidadNegocio;
    });
    // Eliminar unidadesNegocio duplicadas,
    // el modelo de datos permite que un usuario este asociado varias veces a una misma sucursal.
    unidadesNegocio = _.unique(unidadesNegocio, function(value){
      return value.nombre
    });
    vm.unidadesNegocios = unidadesNegocio;

  }

  function volverSolicitud() {
    //traer la solicitud que corresponde al cliente
    var staticFilter = {};
    staticFilter.search = filterFactory.and([{
      path: 'clienteSobregiro.id',
      equals: vm.cliente.id,
    }]).value();
    SolicitudAutorizacionFactory.all(staticFilter).$promise.then(function (response) {
      var idSolicitud = response[0].id;
      $state.go("app.solicitudautorizacion.edit", { id: idSolicitud });
    });
  }

  function abmClienteSucursal(clientesucursal, view) {
    vm.viewCS = view;
    if (clientesucursal != undefined && !vm.viewCS) {
      vm.detalle = clientesucursal;
      $scope.tituloModal = $filter('translate')('EDIT_SUCURSAL_CLIENTE');
    } else if (clientesucursal != undefined && vm.viewCS) {
      $scope.tituloModal = $filter('translate')('VIEW_SUCURSAL_CLIENTE');
      vm.detalle = clientesucursal;
    } else {
      vm.detalle = {
        activo : true
      }
      $scope.tituloModal = $filter('translate')('EDIT_SUCURSAL_CLIENTE')
    }
    getUnidadesNegocio();
    getCentroCostos();
    var modalInstance = $modal.open({
      templateUrl: 'views/directives/sucursal-cliente-modal.html',
      scope: $scope,
      backdrop: 'static',
      bindToController: true,
      size : 'lg'
    });

    $scope.cancel = function () {
      vm.auxEdit = 0;
      vm.detalle = {};
      modalInstance.dismiss('cancel');
      $scope.options.reloadData();
    };

    $scope.ok = function () {
      if(vm.detalle.central){
        ClienteSucursalFactory.all(vm.staticFilter,"BaseList").$promise.then(function(sucursales){
          for(var i = 0; i < sucursales.length; i++){
            if(vm.detalle.id != sucursales[i].id && sucursales[i].central){
              notify({ message: "Ya existe otra Sucursal Central del Cliente: " + sucursales[i].descripcion, classes: "alert-danger", position: "right" });
              return;
            }
          }
          guardarSucursalCliente()
        });
      } else {
        guardarSucursalCliente()
      }

    }
    function guardarSucursalCliente(){
      vm.auxEdit = 0;
      vm.submited = true;
      if ($scope.form.ClienteSucursalForm.$valid) {
        if (vm.detalle.central == undefined) {
          vm.detalle.central = false;
        }
        if (vm.detalle.gln != undefined) {
          vm.detalle.gln = parseFloat(vm.detalle.gln);
        }
        if (vm.detalle.sucursal == undefined) {
          vm.detalle.sucursal = null;
        }
        if (vm.detalle.unidadNegocio == undefined) {
          vm.detalle.unidadNegocio = null;
        }
        vm.detalle.cliente = vm.cliente;
        var clientesucursal = ClienteSucursalFactory.create(vm.detalle);
        return ClienteSucursalFactory.save(clientesucursal).then(function (resp) {
          vm.detalle = {};
          modalInstance.close();
          $scope.options.reloadData();
        }, function (error) {
          notify({ message: error.data[0].message, classes: "alert-danger", position: "right" });
        });
      }else {
        //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
      }
    }
  }

  function sucursalSelected() {
    if (!vm.detalle.sucursal) {
      return;
    }
    var sucId = vm.detalle.sucursal.id;
    var unidadesNegocio = _.map(_.filter($rootScope.AuthParams.locaciones, function(locacion) {
      return locacion.sucursal.id == sucId;
    }), function(locacion) {
      return locacion.unidadNegocio;
    });
    vm.detalle.unidadNegocio = null;
    vm.unidadesNegocios = unidadesNegocio;
  };

  function actividadRamoSecundarioSelected(value){
    vm.tiposActividadesRamoCliente = _.filter(vm.tiposActividadesRamoCliente, function(unidad) {
      return unidad !== value;
    });
  }

  function actividadRamoSecundarioUnSelected(value){
    vm.tiposActividadesRamoCliente.push(value);
  }

   function validarDecimal (){
    var decimalCount = (vm.cliente.descuento.toString().split('.')[1] || []).length;
    $scope.showDecimalError = decimalCount > 2;
};

  $scope.resource = 'clientes'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';


angular.module('qualita.venta')
    .controller('ClienteIndexCtrl', ["$state", "$scope", "ClienteFactory", "filterFactory", "ParametrosFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "$modal", "notify", function ($state, $scope, ClienteFactory, filterFactory, ParametrosFactory,
        ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl, $modal, notify) {

        var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
        var enviarAContabilidad = '';

        promise1.then(function(response) {
            enviarAContabilidad = response.valorTipo.codigo;
        });

        var dateRender = function (data, type, full) {
            if (data) {
                return moment.utc(data).format('DD/MM/YYYY');
            } else {
                return "";
            }
        };

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var staticFilter = undefined;
            staticFilter = {};
            staticFilter.search = filterFactory
                        .and([
                            {
                                path: "id",
                                isNotNull: "true"
                            },
                            {
                              path: "id",
                              sortDesc: "true"
                          }
                        ])
                        .value();

            var defaultColumnOrder = ['id', 'categoriaCliente.descripcion','tipoPersona.descripcion','nombre','codigo', 'tipoDocumento.descripcion',
                'numero', 'direccion', 'correo','telefono','tipoActividadRamoCliente.nombre','nombreFantasia','activo'
            ];
            $scope.options = {
                'resource': 'clientes',
                'title': translations.CLIENTE,
                'factory': ClienteFactory,
                'view': 'BaseList',
                'staticFilter': staticFilter,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': translations.FAIL_DELETE_CLIENTE,
                'columns': [
                    { 'data': 'id', 'title': translations.ID, visible: false  },
                    { 'data': 'categoriaCliente.descripcion', 'title': translations.CATEGORIA_CLIENTE, renderWith: renderEmptyData, 'class': 'dt-left'},
                    { 'data': 'tipoPersona.descripcion', 'title': translations.TIPO_PERSONA, renderWith: renderEmptyData, 'class': 'dt-left',visible: false},
                    { 'data': 'nombre', 'title': translations.NOMBBRE_RAZON, 'class': 'dt-left' },
                    { 'data': 'codigo', 'title': translations.CODIGO_CLIENTE, renderWith: renderEmptyData, 'class': 'dt-left'},
                    { 'data': 'tipoDocumento.descripcion', 'title': translations.TIPO_DOCUMENTO, 'class': 'dt-left',visible:false },
                    { 'data': 'numero', 'title': translations.NUMERO_DOCUMENTO, 'class': 'dt-left' },
                    { 'data': 'direccion', 'title': translations.DIRECCION, renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'correo', 'title': translations.CORRE_ELECTRONICO, renderWith: renderEmptyData, 'class': 'dt-left',visible:false },
                    { 'data': 'telefono', 'title': translations.NUMERO_TELEFONO,renderWith: renderEmptyData, 'class': 'dt-left'},
                    { 'data': 'tipoActividadRamoCliente.nombre', 'title': translations.TIPO_ACTIVIDAD_RAMO_CLIENTE,renderWith: renderEmptyData, 'class': 'dt-left',visible: false },
                    { 'data': 'nombreFantasia', 'title': translations.NOMBRE_FANTASIA,renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'tipoCliente.descripcion', 'title': translations.TIPO_LISTA_PRECIO, renderWith: renderEmptyData, 'class': 'dt-left', visible: false },
                    { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'render': renderEmptyData, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }

                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraMenuOptions':
                    [
                        {
                            'data': $filter('translate')('IMPORT_CLIENTS'),
                            'title': "Carga Clientes",
                            'icon': 'glyphicon glyphicon-upload',
                            'showCondition': function () { return false; },
                            'action': function () {
                                $state.go("app.clientes.carga");
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("clientes", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoCliente', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }

                ],
                extraRowOptions: [
                    {
                        templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
                        functionName: 'reenviar',
                        customAttribute: ['enviadoAContabilidad', 'id'],
                        functionDef: function(atributo) {

                            var idCliente = atributo[1];
                            $scope.tituloModal = $filter('translate')('REENVIAR_CLIENTE');
                            $scope.mensajeModal = $filter('translate')('REENVIAR_CLIENTE_MSG');
                            var modalInstance = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function() {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function(id) {
                                ClienteFactory.reenviar(idCliente).then(function (response) {
                                    $state.go($state.current, {}, {reload: true});
                                }, function(error) {
                                    var msg = "";
                                    if (error.data && error.data.length > 0 && error.data[0].message) {
                                        msg = error.data[0].message;
                                    } else {
                                        msg = $filter('translate')('REENVIAR_CLIENTE_FAILED');
                                    }

                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                });
                                modalInstance.close();
                            }
                        },
                        conditionName: 'canReenviar',
                        conditionDef: function(atributo) {
                            var enviadoAContabilidad = atributo[0];

                            if(enviarAContabilidad == 'si'){
                                if(enviadoAContabilidad === "No"){
                                    return true;
                                }
                            }
                        }
                    }   
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if(search === 'No' && data === 'activo'){
                        search = 'false';
                    }
                    if(search === 'Sí' && data === 'activo'){
                        search = 'true';
                    }

                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.cuentabancaria
 * @description
 * # cuentabancaria
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('ClienteFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var Cliente = $resource(baseurl.getBaseUrl() + '/clientes/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    var schema = {
      type: 'object',
      title: 'Cliente',
      properties: {
        file: {
          type: 'object',
          format: 'fileupload',
          title: 'Archivo'
        }
      },
      required: []
    };

    var fileFormProperty = {
      key: 'file',
      fileName: 'file',
      uploadOptions: {
        imageOnly: false,
        target: baseurl.getBaseUrl() + '/clienteUpload'
      },
      uploader: {}
    };

    var form = [
      fileFormProperty
    ];

    var savedData = {};

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return Cliente.query(params);
      },

      get: function (id, view) {
        return Cliente.get({ id: id, view: view != undefined ? view : 'base' });
      },

      create: function (attrs) {
        return new Cliente(attrs);
      },

      save: function (cliente) {
        return (cliente.id) ? cliente.$update() : cliente.$save();
      },

      remove: function (cliente) {
        return cliente.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function () {
        return form;
      },

      getFileFormProperty: function () {
        return fileFormProperty;
      },

      setData: function set(data) {
        //$window.alert("set");
        savedData = data;
      },

      getData: function get() {
        //$window.alert("get");
        return savedData;
      },

      getSaldoCuenta: function(idCliente) {
        return $http({
          url: baseurl.getBaseUrl() + '/clientes/saldocuenta',
          method: "GET",
          params: {idCliente: idCliente}
        });
      },
      getSaldoRemisiones: function(idCliente) {
        return $http({
          url: baseurl.getBaseUrl() + '/clientes/saldoremisiones',
          method: "GET",
          params: {idCliente: idCliente}
        });
      },
      getSaldoPedido: function(idCliente) {
        return $http({
          url: baseurl.getBaseUrl() + '/clientes/saldopedido',
          method: "GET",
          params: {idCliente: idCliente}
        });
      },
      getSaldoCheque: function(idCliente) {
        return $http({
          url: baseurl.getBaseUrl() + '/clientes/saldocheque',
          method: "GET",
          params: {idCliente: idCliente}
        });
      },
      getSaldoCredito: function(idCliente) {
        return $http({
          url: baseurl.getBaseUrl() + '/clientes/saldocredito',
          method: "GET",
          params: {idCliente: idCliente}
        });
      },
      allForSelect: function(params, view){
        if (params) {
          params.view = view || "BaseList";
        } else {
          params = [];
          params.view = view || "BaseList";
        }
        return Cliente.query(params);
      },
      carga: function (name) {
        return $http({
          url: baseurl.getBaseUrl() + '/clientes/carga',
          method: "POST",
          params: { filename: name}
        });
      },
      cargaSucursales: function (name) {
        return $http({
          url: baseurl.getBaseUrl() + '/clientes/carga-sucursales',
          method: "POST",
          params: { filename: name}
        });
      },
      reenviar: function (idCliente) {
        return $http({
          method: "PUT",
          url: baseurl.getBaseUrl() + "/clientes/reenviar/"+idCliente,
        });
      }
    };
  }]);

angular.module('qualita.venta')
        .factory('ClienteSucursalFactory', ClienteSucursalFactory);

        ClienteSucursalFactory.$inject = ['$resource', 'baseurl', '$http'];

function ClienteSucursalFactory($resource, baseurl, $http) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove
    };

    var ClienteSucursal = $resource(baseurl.getBaseUrl() + "/clientesucursal/:id", {id: '@id'}, {
        update: {
            method: 'PUT'
        }
    });

    return service;

    function all(params, view) {
      if (params) {
        params.view = view != undefined ? view : 'base';
      } else {
        params = [];
        params.view = view != undefined ? view : 'base';
      }
      return ClienteSucursal.query(params);
    }

    function get(id, view) {
        return ClienteSucursal.get({id: id, view: view ? view : "base"});
    }

    function create(attrs) {
        return new ClienteSucursal(attrs);
    }

    function save(clienteSucursal) {
        return (clienteSucursal.id) ? clienteSucursal.$update() : clienteSucursal.$save();
    }

    function remove(clienteSucursal) {
        return clienteSucursal.$remove();
    }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.venta')
  .factory('ComprobanteVentaDetalleFactory', ComprobanteVentaDetalleFactory);

ComprobanteVentaDetalleFactory.$inject = ['$resource', 'baseurl', '$http', 'ParametrosFactory', '$q'];

function ComprobanteVentaDetalleFactory($resource, baseurl, $http, ParametrosFactory, $q) {
  var service = {
    all: all,
    get: get,
   // getReporteFamilia: getReporteFamilia
  };

  var ComprobanteDetalle = $resource( baseurl.getBaseUrl() + "/comprobanteventadetalle/:id", {id: '@id'});

  return service;

  function all(params, view) {
    params = params ? params : [];
    params.view = view != undefined ? view : "base";
    return ComprobanteDetalle.query(params);
  }

  function get(id, view) {
    return ComprobanteDetalle.get({id: id, view: view ? view : "base"});
  }

  function get(id, view) {
    return ComprobanteDetalle.get({id: id, view: view ? view : "base"});
  }

}

angular
  .module("qualita.venta")
  .factory("ProcesoCobroFactory", ProcesoCobroFactory);

ProcesoCobroFactory.$inject = ["$resource", "baseurl", "$http", "$q"];

function ProcesoCobroFactory($resource, baseurl, $http, $q) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    getSoloLectura: getSoloLectura,
    setSoloLectura: setSoloLectura,
    getIdProcesoCobro: getIdProcesoCobro,
    setIdProcesoCobro: setIdProcesoCobro,
    getProcesoCobro: getProcesoCobro,
    setProcesoCobro: setProcesoCobro,
    getEnabledStates: getEnabledStates,
    setEnabledStates: setEnabledStates,
    checkPermissionForState: checkPermissionForState,
    getDiferencia: getDiferencia,
    cerrar: cerrar,
    reabrir: reabrir,
    obtenerRecaudacion: obtenerRecaudacion,
  };

  var ProcesoCobro = $resource(
    baseurl.getBaseUrl() + "/procesocobro/:id",
    { id: "@id" },
    {
      update: {
        method: "PUT"
      }
    }
  );

  var idProcesoCobro = 1;
  var procesoCobro;
  var enabledStates;
  var soloLectura;

  return service;

  function setSoloLectura(flag){
    soloLectura = flag;
  }

  function getSoloLectura(){
    return soloLectura;
  }

  function all(params) {
    return ProcesoCobro.query(params);
  }

  function get(id, view) {
    return ProcesoCobro.get({ id: id, view: view ? view : "BaseList" });
  }

  function create(attrs) {
    return new ProcesoCobro(attrs);
  }

  //function save(procesoCobro) {
  //  return procesoCobro.id ? procesoCobro.$update() : procesoCobro.$save();
  //}

  function save(procesoCobro) {
    return (procesoCobro.id) ? ProcesoCobro.update(procesoCobro).$promise : ProcesoCobro.save(procesoCobro).$promise;

  }

  function remove(procesoCobro) {
    return procesoCobro.$remove();
  }

  function cerrar(id, idAcceso) {
    return $http({
      url: baseurl.getBaseUrl() + "/procesocobro/cerrar/" + id,
      method: "PUT",
      params: { user :  idAcceso }
    });
  }

  function reabrir(id) {
    return $http({
      url: baseurl.getBaseUrl() + "/procesocobro/reabrir/" + id,
      method: "PUT",
    });
  }

  function diferencia(id) {
    return $http({
      url: baseurl.getBaseUrl() + "/procesocobro/diferencia/" + id,
      method: "PUT"
    });
  }

  function anular(id) {
    return ProcesoCobro.anular({ id: id });
  }

  function getDiferencia(id) {
    return $http({
      url: baseurl.getBaseUrl() + "/procesocobro/diferencias/" + id,
      method: "GET"
    });
  }

  function getIdProcesoCobro() {
    return idProcesoCobro;
  }

  function setIdProcesoCobro(id) {
    idProcesoCobro = id;
  }

  function getProcesoCobro() {
    updateStates();
    return procesoCobro;
  }

  function getEnabledStates() {
    return enabledStates;
  }

  function setEnabledStates(states) {
    enabledStates = states;
  }

  function setProcesoCobro(pc) {
    if (pc.id) {
      procesoCobro = pc;
    } else {
      procesoCobro = get(pc, "ProcesoCobroList").$promise;
    }
    updateStates();
  }

  function checkPermissionForState(estadoIndex) {
    // console.log("enabled states: ", enabledStates);
    return enabledStates[estadoIndex];
  }

  function updateStates() {
    if (procesoCobro && procesoCobro.estado && procesoCobro.estado.codigo) {
      switch (procesoCobro.estado.codigo) {
        case "estado_procesocobro_abierto":
          enabledStates = [true, true, false];
          break;
        default:
          enabledStates = [true, true, true];
          break;
      }
    }
  }

  function obtenerRecaudacion(id) {
    return $http({
      url: baseurl.getBaseUrl() + "/procesocobro/obtenerrecaudacion/" + id,
      method: "GET"
    });
  }
}

"use strict";

angular.module("qualita.venta").controller("VentaIndexCtrl", VentaIndexCtrl);

VentaIndexCtrl.$inject = [
    "AuthorizationService",
    "VentaFactory",
    "TiposFactory",
    "PeriodoAdministrativoFactory",
    "PedidoVentaFactory",
    "filterFactory",
    "$log",
    "$modal",
    "$scope",
    "notify",
    "$state",
    "VentasLangFactory",
    "ParametrosFactory",
    "TiposTipoFactory",
    "WatchLocacionChangeFactory",
    "UtilFactory",
    "ProveedoresFactory",
    "$rootScope",
    "TipoVentaFactory",
    "ClienteFactory",
    "ComprobanteVentaFactory",
    "$filter",
    "$window",
    "CsvFactory",
    "baseurl",
    "PlanillaRepartoFactory",
    "TimbradoEmpresaPuntoEmisionVentaFactory",
    "puntoVentaFactory",
    "cajaFactory"
];

function VentaIndexCtrl(
    AuthorizationService,
    VentaFactory,
    TiposFactory,
    PeriodoAdministrativoFactory,
    PedidoVentaFactory,
    filterFactory,
    $log,
    $modal,
    $scope,
    notify,
    $state,
    VentasLangFactory,
    ParametrosFactory,
    TiposTipoFactory,
    WatchLocacionChangeFactory,
    UtilFactory,
    ProveedoresFactory,
    $rootScope,
    TipoVentaFactory,
    ClienteFactory,
    ComprobanteVentaFactory,
    $filter,
    $window,
    CsvFactory,
    baseurl,
    PlanillaRepartoFactory,
    TimbradoEmpresaPuntoEmisionVentaFactory,
    puntoVentaFactory,
    cajaFactory
) {
    var vm = this;
    vm.puntEmisionVenta=null;
    $scope.pr = {};
    vm.delayRequestCliente = delayRequestCliente;
    vm.searchClienteFilter2 = searchClienteFilter2;

    vm.addNewVenta = addNewVenta;
    vm.autoGenerateCode = ParametrosFactory.getByCodigo(
        "generacion_codigo_ventas"
    );
    vm.changeTimbrado = changeTimbrado;

    var defaultColumnOrder = [
        "id",
        "codigo",
        "estado.descripcion",
        "tipo.nombre",
        "pedidoVenta.id",
        "planillaReparto.numero",
        "fechaApertura",
        "fechaCierre",
        "diferencia",
    ];

    VentasLangFactory.getTranslations().then(function(translations) {
        $scope.translations = translations;
        var staticFilter = undefined;
        staticFilter = {};
        staticFilter.search = filterFactory
                        .and([
                            {
                                path: "id",
                                sortDesc: "true"
                            },
                            {
                                path: "id",
                                isNotNull: "true"
                            }
                        ])
                        .value();
        

        vm.options = {
            resource: "venta",
            staticFilter: staticFilter,
            title: $scope.translations.VENTAS,
            factory: VentaFactory,
            defaultColumnOrder: defaultColumnOrder,
            failedDeleteError: translations.ERROR_VENTA_DELETE,
            view: "VentaList",
            columns: [
                { data: "id", title: 'id', visible:false },
                { data: "codigo", title: translations.CODE },
                { data: "estado.descripcion", title: translations.STATUS, 'type' : 'combo', 'filterUrl': 'tipos/filtro/estados_venta' },
                { data: "tipo.nombre", title: translations.TIPO_VENTA },
                { data: "pedidoVenta.id", title: translations.PEDIDO_VENTA, renderWith: 'emptyRender' },
                { data: "planillaReparto.numero", title: 'Nro de Planilla de Reparto', renderWith: 'emptyRender' },
                {
                    data: "fechaApertura",
                    title: translations.DATE_APERTURA,
                    renderWith: "dateRender",
                    dateFormat: "DD/MM/YYYY",
                    class: "dt-center",
                    type: "date-range"
                },
                {
                    data: "fechaCierre",
                    title: translations.DATE_CIERRE,
                    renderWith: "dateRender",
                    dateFormat: "DD/MM/YYYY",
                    class: "dt-center",
                    type: "date-range"
                },
                {
                    data: "diferencia",
                    title: translations.TIENE_DIFERENCIA,
                    class: "dt-center",
                    type: "combo",
                    filterUrl: "tipos/filtro/boolean_values"
                }
            ],
            hasOptions: true,
            defaultOrderColumn: 0,
            defaultOrderDir: "desc",
            hideAddMenu: true,
            hideEditMenu: true,
            hideRemoveMenu: true,
            hideViewMenu: true,
            extraMenuOptions: [
                {
                    title: translations.NEW_VENTA,
                    icon: "glyphicon glyphicon-plus",
                    showCondition: function() {
                        var permission = AuthorizationService.hasPermission("create_venta");
                        return permission;
                    },
                    action: function() {
                        vm.addNewVenta();
                    }
                },
                {
                  'title': 'CSV',
                  'icon': 'glyphicon glyphicon-export',
                  'showCondition': function() { return true; },
                  'action': function() {
                      var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                      CsvFactory.csv("venta", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                      .then(function(response) {
                        console.log("response de csv: ", response);
                        var fileName = response.data.fileName;
                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                      });
                    }
                }
            ],
            extraRowOptions: [{
                    templateToRender: "<button class='btn btn-success' style='margin-right: 5px;'   title=" +
                        translations.EDIT +
                        " ng-click='editarProceso(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                    customAttribute: "estado",
                    conditionName: "canEditar",
                    conditionDef: function(estado) {
                        return (
                            estado.codigo != "venta_cerrada" &&
                            estado.codigo != "venta_anulada"
                        );
                    },
                    functionName: "editarProceso",
                    functionDef: function(itemId) {
                        redirectVenta(itemId, "");
                    }
                },
                {
                    templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                        translations.DELETE +
                        "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                    customAttribute: "estado",
                    conditionName: "canBorrar",
                    conditionDef: function(estado) {
                        return (
                            estado.codigo != "venta_cerrada" &&
                            estado.codigo != "venta_anulada"
                        );
                    },
                    functionName: "borrar",
                    functionDef: function(itemId) {
                        $scope.selectedItemId = itemId;
                        $scope.tituloModal = translations.DELETE_CONFIRMATION;
                        $scope.mensajeModal = translations.DELETE_WARNING;
                        var modalInstance = $modal.open({
                            templateUrl: "views/directives/confirmation-modal.html",
                            scope: $scope
                        });

                        $scope.cancel = function() {
                            modalInstance.dismiss("cancel");
                        };

                        $scope.ok = function(itemId) {
                            var model = VentaFactory.create({ id: itemId });
                            VentaFactory.remove(model).then(
                                function() {
                                    modalInstance.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                },
                                function(error) {
                                    modalInstance.dismiss("cancel");
                                    $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                    $scope.mensajeModal = $filter("translate")(
                                        "VENTA_DELETE_CHECK_COMPROBANTES"
                                    );

                                    var modalInstance2 = $modal.open({
                                        templateUrl: "views/directives/information-modal.html",
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance2.dismiss("cancel");
                                    };
                                }
                            );
                        };
                    }
                },
                {
                    templateToRender: "<button title='" +
                        $filter("translate")("ANULAR_VENTA") +
                        "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataId%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                    customAttribute: "estado",
                    conditionName: "canAnular",
                    conditionDef: function(estado) {
                        return (
                            AuthorizationService.hasPermission("update_venta") &&
                            estado.codigo != "venta_cerrada" &&
                            estado.codigo != "venta_anulada"
                        );
                    },
                    functionName: "anular",
                    functionDef: function(itemId) {
                        $scope.selectedItemId = itemId;
                        $scope.tituloModal = $filter("translate")("ANULAR_VENTA_Q");
                        $scope.mensajeModal = $filter("translate")("ANULAR_VENTA_MSG");
                        var modalInstance = $modal.open({
                            templateUrl: "views/directives/confirmation-modal.html",
                            scope: $scope
                        });

                        $scope.cancel = function() {
                            modalInstance.dismiss("cancel");
                        };

                        $scope.ok = function(itemId) {
                            VentaFactory.anular(itemId).$promise.then(
                                function() {
                                    modalInstance.close(itemId);
                                    $state.go($state.current, {}, { reload: true });
                                },
                                function(error) {
                                    modalInstance.dismiss("cancel");
                                    $scope.tituloModal = translations.ANULAR_VENTA_FAILED;
                                    $scope.mensajeModal = error.data[0].message + ".";

                                    var modalInstance2 = $modal.open({
                                        templateUrl: "views/directives/information-modal.html",
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance2.dismiss("cancel");
                                    };
                                }
                            );
                        };
                    }
                },
                {
                    templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" +
                        translations.VIEW +
                        "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                    conditionName: "canView",
                    conditionDef: function() {
                        return AuthorizationService.hasPermission("index_venta");
                    },
                    functionName: "view",
                    functionDef: function(itemId) {
                        redirectVenta(itemId, "1");
                    }
                },
                {
                    templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;'   title='" +
                        $filter("translate")("REABRIR_FLUJO") +
                  "' ng-click='reabrirFlujo(<%=dataId%>)' ng-class='{ hidden : !canReabrir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-folder-open'></span> </button>",
                    customAttribute: "estado",
                    conditionName: "canReabrir",
                    conditionDef: function(estado) {
                        return (
                            AuthorizationService.hasPermission("reabrir_venta") && estado.codigo == "venta_cerrada"
                        );
                    },
                    functionName: "reabrirFlujo",
                    functionDef: function(itemId) {
                        $scope.selectedItemId = itemId;
                        $scope.tituloModal = translations.REOPEN_CONFIRMATION;
                        $scope.mensajeModal = translations.REOPEN_WARNING;
                        var modalInstance = $modal.open({
                            templateUrl: "views/directives/confirmation-modal.html",
                            scope: $scope
                        });

                        $scope.cancel = function() {
                            modalInstance.dismiss("cancel");
                        };

                        $scope.ok = function(itemId) {
                            VentaFactory.reabrirVenta(itemId).then(
                                function() {
                                    modalInstance.close(itemId);
                                    $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });

                                },
                                function(error) {
                                    modalInstance.dismiss("cancel");
                                    $scope.tituloModal = translations.REOPEN_ERROR;
                                    $scope.mensajeModal = error.data[0].message + ".";

                                    var modalInstance2 = $modal.open({
                                        templateUrl: "views/directives/information-modal.html",
                                        scope: $scope
                                    });
                                    $scope.cancel = function() {
                                        modalInstance2.dismiss("cancel");
                                    };
                                }
                            );
                        };
                    }
                }
            ]
        };
        WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");
    });

    vm.submitNewVenta = false;

    function addNewVenta() {
        $scope.tituloModal = $scope.translations.NEW_VENTA;
        // agregar filtro de ordenes de compra de importacion
        var staticFilterVP = {};

        staticFilterVP.search = filterFactory
            .and([
                {
                    path: "id",
                    sortAsc: "true"
                },
                {
                    path: "estado.codigo",
                    equals: "pv_aprobado"
                },{
                    path: "ventasString",
                    isNull: "true"
                }
            ])
            .value();
        if (vm.watchPV) {
            vm.watcherPV();
        }
        WatchLocacionChangeFactory.executeWatching(function(
            locacionFilter,
            watcher
        ) {
            vm.watcherPV = watcher;
            PedidoVentaFactory.all(
                UtilFactory.createNestedFilters(
                    locacionFilter.search.filters,
                    staticFilterVP
                )
            ).$promise.then(function(response) {
                $scope.pedidosVenta = response;
                _.forEach($scope.pedidosVenta, function(oc) {
                    oc.fecha = moment(oc.fecha, "YYYY/MM/DD").format("DD/MM/YYYY");
                });
            });
        }, "locacion.id");


        var filterClienteActivo = {
          search: filterFactory.single({ path: "activo", equals: true }).value()
        };
        filterClienteActivo.view = "BaseList";

        var filtersTC = {
            search: filterFactory.single({ path: "activo", equals: true }).value()
        };

        TipoVentaFactory.all(filtersTC, "BaseList").$promise.then(function(response) {
            $scope.tiposVenta = _.map(response, function(tc) {
                if (
                    _.find(tc.tipoVentaDetalles, function(detalle) {
                        return detalle.componente.codigo == "comp_tipo_venta_pedido_venta";
                    })
                ) {
                    tc.tienePV = true;
                } else if (
                    _.find(tc.tipoVentaDetalles, function(detalle) {
                        return detalle.componente.codigo == "comp_tipo_venta_remision";
                    })
                ) {
                    tc.tieneRemisionAndNotPV = true;
                }
                return tc;
            });
        });

        //Recuperamos las planillas de reparto que no estén asociados a una venta
        var filterPlanillaReparto = [];
        var staticFilter2 = {};

        filterPlanillaReparto.push({
            path: "estado.codigo",
            equals: "planilla_preparada"
        }, {
            path: "locacion.sucursal.id",
            equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
        });

        var extraConditions = [{
            path: "ventas.id",
            isNull: "true"

        },
        {
            path: "ventas.estado.codigo",
            equals: "venta_anulada"
        }
        ]

        var finalAndFilters = _.map(extraConditions, function (cond) {
            var arr = [cond]
            arr = arr.concat(filterPlanillaReparto)
            return filterFactory.and(arr).value();
        })

        staticFilter2.search = filterFactory
            .or(finalAndFilters)
            .value();

        $scope.planillasReparto = PlanillaRepartoFactory.all(staticFilter2,"BaseList");

        // buscar periodo que coincida con la fecha actual
        var params = {};
        params.search = filterFactory
            .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(new Date()).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(new Date()).format("DD/MM/YYYY")
                }
            ])
            .value();

        var flagPeriodo = false;

        params.view = "BaseList";
        PeriodoAdministrativoFactory.all(params).$promise.then(function(responsePeriodo) {
            var periodos = responsePeriodo;

            if (periodos.length > 0) {
                flagPeriodo = true;
            } else {
                flagPeriodo = false;
            }

            if (flagPeriodo) {
                vm.autoGenerateCode.then(function(response) {
                    if (response.valorTipo.codigo == "si") {
                        $scope.pv = {};
                        $scope.pr = {};
                        //Seteamos la fecha actual
                        var hoy = new Date();
                        hoy.setHours(0, 0, 0, 0);
                        $scope.pr.fechaComprobante = hoy;
                        //Recuperamos los timbrados
                        getPuntosEmisionVenta();
                        //filterTimbrado();
                        var modalInstance = $modal.open({
                            templateUrl: "views/directives/venta-auto-modal.html",
                            scope: $scope
                        });

                        $scope.searchPVFilter = function(criteria) {
                            return function(item) {
                                if (!criteria) {
                                    return true;
                                }
                                //console.log("item",item);
                                return (
                                    (item.id + "").indexOf(criteria.toLowerCase()) >
                                    -1 ||
                                    item.cliente.nombre
                                    .toLowerCase()
                                    .indexOf(criteria.toLowerCase()) > -1
                                );
                            };
                        };

                        $scope.searchClienteFilter = function(criteria) {
                            return function(item) {
                                if (!criteria) {
                                    return true;
                                }
                                return (
                                    item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1
                                );
                            };
                        };

                        $scope.searchPRFilter = function(criteria) {
                            return function(item) {
                                if (!criteria) {
                                    return true;
                                }
                                return (
                                    item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1
                                );
                            };
                        };

                        $scope.searchTimbradoFilter = function(criteria) {
                            return function(item) {
                                if (!criteria) {
                                    return true;
                                }
                                return (
                                    String(item.timbradoEmpresa.numeroTimbrado).toLowerCase().indexOf(criteria.toLowerCase()) > -1
                                );
                            };
                        };

                        $scope.cancel = function() {
                            modalInstance.dismiss("cancel");
                        };

                        $scope.ok = function(tipoVenta, pedidoVenta, cliente, planilla) {
                            vm.submitNewVenta = true;
                            if (!tipoVenta) {
                                console.log("No hay tipoVenta");
                                vm.submitNewVenta = false;
                                return;
                            }
                            var containsPV = _.find(tipoVenta.tipoVentaDetalles, function(
                                detalle
                            ) {
                                return (
                                    detalle.componente.codigo == "comp_tipo_venta_pedido_venta"
                                );
                            });

                            if (containsPV && !pedidoVenta && !planilla.planillaReparto) {
                                console.log("pedidoVenta", pedidoVenta);
                                console.error(
                                    "El tipo es Pedido de Venta y no se especificó Pedido de Venta"
                                );
                                vm.submitNewVenta = false;
                                return;
                            }

                            if (!containsPV && pedidoVenta) {
                                console.error(
                                    "Hay Pedido de Venta y el tipo no es de Pedido de Venta"
                                );
                                vm.submitNewVenta = false;
                                return;
                            }

                            var containsRemision = _.find(
                                tipoVenta.tipoVentaDetalles,
                                function(detalle) {
                                    return (
                                        detalle.componente.codigo == "comp_tipo_venta_remision"
                                    );
                                }
                            );

                            if (containsRemision && !containsPV && !cliente) {
                                console.error(
                                    "El tipo es Con Remisión y no se especificó el Cliente"
                                );
                                vm.submitNewVenta = false;
                                return;
                            }

                            sendNewVenta(tipoVenta, pedidoVenta, undefined, cliente, planilla)
                                .then(function(data) {
                                    modalInstance.close();
                                    if (data != undefined){
                                        return VentaFactory.get(data.id, "BaseList").$promise.then(function(data) {
                                            VentaFactory.setIdVenta(data.id);
                                            VentaFactory.setCodigoVenta(data.codigo);
                                            VentaFactory.setVenta(data);
                                            redirectVenta(data.id, "");
                                        });
                                    }
                                });
                        };
                    } else {
                        var modalInstance = $modal.open({
                            templateUrl: "views/directives/venta-manual-modal.html",
                            scope: $scope
                        });

                        $scope.pv = {};

                        $scope.searchPVFilter = function(criteria) {
                            return function(item) {
                                if (!criteria) {
                                    return true;
                                }
                                //console.log("item",item);
                                return (
                                    (item.id + "").indexOf(criteria.toLowerCase()) >
                                    -1 ||
                                    item.cliente.nombre
                                    .toLowerCase()
                                    .indexOf(criteria.toLowerCase()) > -1
                                );
                            };
                        };

                        $scope.searchClienteFilter = function(criteria) {
                            return function(item) {
                                if (!criteria) {
                                    return true;
                                }
                                return (
                                    item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1
                                );
                            };
                        };

                        $scope.cancel = function() {
                            modalInstance.dismiss("cancel");
                        };

                        $scope.ok = function(codigo, tipo, pedidoVenta) {
                            if (!tipo) {
                                console.error("No hay tipoVenta");
                                return;
                            }
                            //Temporalmente se compara por el nombre
                            if (tipo.nombre == "Compra con OC" && !pedidoVenta) {
                                console.error(
                                    "El tipo es Orden de Compra y no se especificó Orden de Compra"
                                );
                                return;
                            }
                            //Temporalmente se compara por el nombre
                            if (tipo.codigo != "Compra con OC" && ordenCompra) {
                                console.error(
                                    "Hay Orden de Compra y el tipo no es de OrdenCompra"
                                );
                                return;
                            }

                            sendNewVenta(tipo, pedidoVenta, codigo).then(function(data) {
                                modalInstance.close();
                                VentaFactory.setIdVenta(data.id);
                                VentaFactory.setCodigoVenta(data.codigo);
                                data.tipo = TipoVentaFactory.get(data.tipo.id, "detail");
                                VentaFactory.setVenta(data);
                                redirectVenta(data.id, "");
                            });
                        };
                    }
                });
            } else {
                var msg = $scope.translations.NO_ADMINISTRATIVE_PERIOD;
                notify({ message: msg, classes: "alert-danger", position: "right" });
            }
        });
    }

    function sendNewVenta(tipoVenta, pedidoVenta, codigo, cliente, planilla) {
        return TiposFactory.estadosVenta()
            .then(
                function(response) {
                    var estados = response.data;

                    if (!estados) {
                        console.log(
                            "No se pudo guardar venta debido a que no se pudo traer los estados"
                        );
                        return;
                    }

                    var estadoActivo = _.find(estados, function(estado) {
                        return estado.codigo === "venta_abierta";
                    });
                    if (!estadoActivo) {
                        return;
                    }
                    var venta = {
                        estado: estadoActivo.id,
                        activo: true,
                        tipo: tipoVenta
                    };
                    if (codigo) {
                        venta.codigo = codigo;
                    }

                    if (pedidoVenta) {
                        venta.pedidoVenta = pedidoVenta;
                        venta.cliente = pedidoVenta.cliente;
                    }

                    if (planilla.planillaReparto) {
                        venta.planillaReparto = planilla.planillaReparto;
                        venta.timbradoEmpresaPuntoEmisionVenta = planilla.timbrado;
                        venta.fechaComprobante= planilla.fechaComprobante;
                    }
                    if (cliente) {
                        venta.cliente = cliente;
                    }
                    venta.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
                    var vnt = VentaFactory.create(venta);
                    return VentaFactory.save(vnt).then(function(responseVenta){
                        return responseVenta;
                    }, function(error){
                        vm.submitNewVenta=false;
                        notify({ message: error.data[0].message, classes: "alert-danger", position: "right" });
                    });
                },
                function(error) {
                    console.log("Error a traer los estados de venta: ");
                    console.log(error);
                });
    }

    function redirectVenta(itemId, modo) {
        VentaFactory.get(itemId, "BaseList").$promise.then(function(vnt) {
            VentaFactory.setIdVenta(vnt.id);
            VentaFactory.setCodigoVenta(vnt.codigo);
            VentaFactory.setVenta(vnt);
            VentaFactory.setModoAcceso(modo);
            var tipo = vnt.tipo;
            if (vnt.estado.codigo == "venta_anulada") {
                $state.go("app.ventas.proceso.comprobanteventa.list", {
                    codigoVenta: itemId,
                    modeAccess: modo
                });
            }
            if (vnt.estado.codigo == "venta_abierta") {
                var primerTipo = _.find(tipo.tipoVentaDetalles, function(detalle) {
                    return detalle.orden == 1;
                });
                if (
                    primerTipo &&
                    primerTipo.componente.codigo == "comp_tipo_venta_pedido_venta"
                ) {
                    $state.go("app.ventas.proceso.pedidosventa.list", {
                        codigoVenta: itemId
                    });
                }

                if (
                    primerTipo &&
                    primerTipo.componente.codigo == "comp_tipo_venta_remision"
                ) {
                    $state.go("app.ventas.proceso.remision.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                }

                if (
                    primerTipo &&
                    primerTipo.componente.codigo == "comp_tipo_venta_comprobante"
                ) {
                    $state.go("app.ventas.proceso.comprobanteventa.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                }
            }
            if (vnt.estado.codigo == "venta_con_pv") {
                var tipoPV = _.find(tipo.tipoVentaDetalles, function(detalle) {
                    return detalle.componente.codigo == "comp_tipo_venta_pedido_venta";
                });

                var tipoActual = _.find(tipo.tipoVentaDetalles, function(detalle) {
                    return detalle.orden == tipoPV.orden + 1;
                });

                if (!tipoActual) {
                    $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });
                } else if (tipoActual.componente.codigo == "comp_tipo_venta_remision") {
                    $state.go("app.ventas.proceso.remision.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                } else if (
                    tipoActual.componente.codigo == "comp_tipo_venta_comprobante"
                ) {
                    $state.go("app.ventas.proceso.comprobanteventa.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                }
            }
            if (vnt.estado.codigo == "venta_con_remision") {
                var tipoRemision = _.find(tipo.tipoVentaDetalles, function(detalle) {
                    return detalle.componente.codigo == "comp_tipo_venta_remision";
                });

                var tipoActual = _.find(tipo.tipoVentaDetalles, function(detalle) {
                    return detalle.orden == tipoRemision.orden + 1;
                });

                if (!tipoActual) {
                    $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });
                } else if (
                    tipoActual.componente.codigo == "comp_tipo_venta_pedido_venta"
                ) {
                    $state.go("app.ventas.proceso.pedidosventa.list", {
                        codigoVenta: itemId
                    });
                } else if (
                    tipoActual.componente.codigo == "comp_tipo_venta_comprobante"
                ) {
                    $state.go("app.ventas.proceso.comprobanteventa.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                }
            }
            if (vnt.estado.codigo == "venta_con_comprobante") {
                var tipoComprobante = _.find(tipo.tipoVentaDetalles, function(detalle) {
                    return detalle.componente.codigo == "comp_tipo_venta_comprobante";
                });

                var tipoActual = _.find(tipo.tipoVentaDetalles, function(detalle) {
                    return detalle.orden == tipoComprobante.orden + 1;
                });

                if (!tipoActual) {
                    $state.go("app.ventas.proceso.comprobanteventa.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                } else if (tipoActual.componente.codigo == "comp_tipo_venta_remision") {
                    $state.go("app.ventas.proceso.remision.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                } else if (
                    tipoActual.componente.codigo == "comp_tipo_venta_pedidos_venta"
                ) {
                    $state.go("app.ventas.proceso.pedidosventa.list", {
                        codigoVenta: itemId
                    });
                }
            }
            if (vnt.estado.codigo == "venta_cerrada") {
                $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });
            }
        });
    }

    function filterTimbrado() {

        if (vm.watcherTimbrado) {
            vm.watcherTimbrado();
        }
        WatchLocacionChangeFactory.executeWatching(function (
            locacionFilter,
            watcherTimbrado
        ) {
            vm.watcherTimbrado = watcherTimbrado;;

            var staticFilter2 = {};
            var filtersArray = [];

            if ($scope.pr.fechaComprobante) {
                filtersArray.push({
                    path: "timbradoEmpresa.fechaDesde",
                    equalOrBefore: moment($scope.pr.fechaComprobante).format("DD/MM/YYYY")
                });

                filtersArray.push({
                    path: "timbradoEmpresa.fechaHasta",
                    equalOrAfter: moment($scope.pr.fechaComprobante).format("DD/MM/YYYY")
                });
            }

            filtersArray.push({
                path: "timbradoEmpresa.claseTimbrado.codigo",
                notEquals: 'timbrado_manual'
            });

            filtersArray.push({
                path: "tipoComprobante.codigo",
                equals: 'factura'
            });


            // Filtro de estado
            filtersArray.push({
                path: "timbradoEmpresa.activo",
                equals: true
            });

            filtersArray.push({
                path: "puntoEmisionVenta.id",
                equals: vm.puntEmisionVenta.id
            });

            var extraConditions = [{
                path: "timbradoEmpresa.sucursal.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
            },
            {
                path: "timbradoEmpresa.sucursal.id",
                isNull: true
            }
            ]

            var finalAndFilters = _.map(extraConditions, function (cond) {
                var arr = [cond]
                arr = arr.concat(filtersArray)
                return filterFactory.and(arr).value();
            })

            staticFilter2.search = filterFactory
                .or(finalAndFilters)
                .value();

            $scope.timbrados = TimbradoEmpresaPuntoEmisionVentaFactory.all(staticFilter2, "ConsultaComprobanteVentaForm");
        }, "locacion.id");
    }

    function changeTimbrado(timbrado){
        if(timbrado){
            //var puntoEmisionVenta = getPuntosEmisionVenta();
            var codSucursal = timbrado.puntoEmisionVenta.puntoVenta.codigoSucursal;
            var nroPuntoEmision = timbrado.puntoEmisionVenta.numero;
            var numeroActual;
            if(timbrado.numeroActual){
                numeroActual = (timbrado.numeroActual).toString();
            }else{
                numeroActual = (timbrado.numeroInicial).toString();
            }
            var diff = 7 - numeroActual.length;
            if(diff >0){
                for (i = 0; i < diff; i++) {
                    numeroActual = '0' + numeroActual;
                }
                $scope.pr.numero= codSucursal + '-' + nroPuntoEmision + '-' + numeroActual;
            }

        }
    }

    $scope.limpiarCampos= function(){
        $scope.pr ={};
        //Seteamos la fecha actual
        var hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        $scope.pr.fechaComprobante = hoy;
    }

    function getPuntosEmisionVenta() {
        var caja = null;
        _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
            if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                caja = cajas;
            }
        });
        if (caja != null) {
            cajaFactory.getCaja(caja.id, "CajaList").$promise.then(function (caja) {
                puntoVentaFactory.get(caja.puntoEmision.puntoVenta.id, "ConsultaComprobanteVentaForm").$promise.then(function (puntoVenta) {
                    if (puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                        //vm.puntoEmisionVentaActual = caja.puntoEmision;
                        vm.puntEmisionVenta= caja.puntoEmision;
                        filterTimbrado();
                    } else {
                        var msg = "La sucursal del sistema no coincide con la sucursal de la caja registrada";
                        notify({ message: msg, classes: "alert-danger", position: "right" });
                    }
                });
            });
        } else {
            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
            notify({ message: msg, classes: "alert-danger", position: "right" });
        }
    }

    function delayRequestCliente(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
            var filterNombre =[];
            // var filterNombreFantasia =[];
            var filterCodigo =[];
            var filterNumero =[];
            var filterDireccion =[];
            var filterCiudad =[];

            filterNombre.push({
                path: "nombre",
                like: searchValue
            })
            filterNombre.push({
              path: "activo",
              equals: true
            })
            filterCodigo.push({
            path: "codigo",
            like: searchValue
            })
            filterCodigo.push({
              path: "activo",
              equals: true
            })
            filterNumero.push({
            path: "numero",
            like: searchValue
            })
            filterNumero.push({
              path: "activo",
              equals: true
            })
            filterDireccion.push({
            path: "direccion",
            like: searchValue
            })
            filterDireccion.push({
              path: "activo",
              equals: true
            })
            filterCiudad.push({
            path: "ciudad.descripcion",
            like: searchValue
            })
            filterCiudad.push({
              path: "activo",
              equals: true
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigo).value(),
                    filterFactory.and(filterNumero).value(),
                    filterFactory.and(filterDireccion).value(),
                    filterFactory.and(filterCiudad).value()
                ]).value()
            }

            recuperarCliente(userInputFilter)
        } else {
            vm.clientes = undefined
        }
    }

    function recuperarCliente(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)
    
          ClienteFactory.allForSelect(finalFilter).$promise.then(function (clientes) {
            var clie = [];
            for (var i = 0; i < clientes.length; i++) {
              if (clientes[i].bloquearVentas !== true) {
                clie.push(clientes[i])
              }
            }
            vm.clientes = clie;
          })
    }

    function searchClienteFilter2(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
            );
        }
    }
}

'use strict';
angular.module('qualita.venta')
  .controller('VentaProcesoCtrl', VentaProcesoCtrl);

VentaProcesoCtrl.$inject = ["AuthorizationService", "VentaFactory", "TiposFactory", "$log", "$modal",
"$scope", "notify", "VentasLangFactory"];

function VentaProcesoCtrl(AuthorizationService, VentaFactory, TiposFactory, $log, $modal, $scope,
  notify, $stateParams, VentasLangFactory) {

    var vm = this;
  vm.ventaId = VentaFactory.getIdVenta();
  vm.ventaCodigo = VentaFactory.getCodigoVenta();
  vm.venta = VentaFactory.getVenta();
  console.log("vm.venta: ", vm.venta);
  vm.enabledStates = VentaFactory.getEnabledStates();
  console.log("vm.enabledStates: ", vm.enabledStates);

  vm.statesMap = {
    comp_tipo_venta_pedido_venta: {
      state: ".pedidosventa.list",
      parentState: "app.ventas.proceso.pedidosventa",
      enabledStatesIndex: 0
    },
    comp_tipo_venta_remision: {
      state: ".remision.list",
      parentState: "app.ventas.proceso.remision",
      enabledStatesIndex: 2
    },
    comp_tipo_venta_comprobante: {
      state: ".comprobanteventa.list",
      parentState: "app.ventas.proceso.comprobanteventa",
      enabledStatesIndex: 1
    }
  }

  $scope.$on("updateVentaStates", function() {
    console.log("updateVentaStates listener triggered");
    vm.enabledStates = VentaFactory.getEnabledStates();
  });
}

'use strict';
angular.module('qualita.venta')
  .controller('ProcesoCobroProcesoCtrl', ProcesoCobroProcesoCtrl);

ProcesoCobroProcesoCtrl.$inject = ["AuthorizationService", "ProcesoCobroFactory", "TiposFactory", "$log", "$modal",
"$scope", "notify", "VentasLangFactory", "$state"];

function ProcesoCobroProcesoCtrl(AuthorizationService, ProcesoCobroFactory, TiposFactory, $log, $modal, $scope,
  notify, $stateParams, VentasLangFactory, $state) {

    var vm = this;
  vm.procesoCobroId = ProcesoCobroFactory.getIdProcesoCobro();
  vm.procesoCobro = ProcesoCobroFactory.getProcesoCobro();
  vm.enabledStates = ProcesoCobroFactory.getEnabledStates();
  console.log("vm.enabledStates: ", vm.enabledStates);

  $scope.$on("updateVentaStates", function() {
    console.log("updateVentaStates listener triggered");
    vm.enabledStates = VentaFactory.getEnabledStates();
  });
}
/**
 * Created by codiumsa on 19/10/15.
 */
angular
    .module("qualita.venta")
    .factory("ComprobanteVentaFactory", ComprobanteVentaFactory);

ComprobanteVentaFactory.$inject = [
    "$resource",
    "baseurl",
    "$http",
    "notify",
    "$window",
    "UtilFactory",
    "$filter",
    "$rootScope",
    "AuthorizationService",
    "VentaFactory",
    "filterFactory"
];

function ComprobanteVentaFactory(
    $resource,
    baseurl,
    $http,
    notify,
    $window,
    UtilFactory,
    $filter,
    $rootScope,
    AuthorizationService,
    VentaFactory,
    filterFactory
) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        saveWithPlanCobros: saveWithPlanCobros,
        remove: remove,
        aprobar: aprobar,
        reenviar: reenviar,
        aprobarPorId: aprobarPorId,
        recuperarPorId: recuperarPorId,
        anular: anular,
        inutilizar: inutilizar,
        getDiferencia: getDiferencia,
        generarJsonVirtual: generarJsonVirtual,
        getMontosMes: getMontosMes,
        validateMontos: validateMontos,
        igualarTipoImpuestoCabeceraADetealle: igualarTipoImpuestoCabeceraADetealle,
        downloadCSVHechaukaVentas: downloadCSVHechaukaVentas,
        aplicar: aplicar,
        desaplicar: desaplicar,
        validateNumeroComprobanteVenta: validateNumeroComprobanteVenta,
        validateSucursalEmpresaFromNumeroComprobante: validateSucursalEmpresaFromNumeroComprobante,
        validatePuntoEmisionFromNumeroComprobanteVenta: validatePuntoEmisionFromNumeroComprobanteVenta,
        completeNroFactura: completeNroFactura,
        validateNroFactura: validateNroFactura,
        hasPermisos: hasPermisos,
        getEstaAsentado: getEstaAsentado,
        getSaldoByCliente:getSaldoByCliente,
        solicitarAutorizacionComprobante: solicitarAutorizacionComprobante,
        verificarVencimiento: verificarVencimiento,
        solicitarAutorizacionBloqueo: solicitarAutorizacionBloqueo,
        downloadCSVArchivoVentas: downloadCSVArchivoVentas,
        aprobarComprobantesPorId: aprobarComprobantesPorId,
        actualizarEstadoRendicion: actualizarEstadoRendicion,
        verificarExistenciaTimbrado: verificarExistenciaTimbrado,
        actualizarEstadoImpresion: actualizarEstadoImpresion,
        getVentasBySuscripcion:getVentasBySuscripcion,
        importarCsv:importarCsv,
        reUtilizar:reUtilizar,
        generarJsonVirtualDescarga: generarJsonVirtualDescarga,
        nominacion: nominacion
    };

    var Comprobante = $resource(
        baseurl.getBaseUrl() + "/comprobanteventa/:id", { id: "@id" }, {
        update: {
            method: "PUT"
        },
        updateIgnoreEstado: {
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/vendedor/:id"
        },
        montosMes: {
            method: "GET",
            url: baseurl.getBaseUrl() +
                "/comprobanteventa/montos/:month/:year/:idProveedor"
        }/*,
        aprobar: {
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/aprobar/:id"
        }*/,
        reenviar: {
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/reenviar/:id"
        },
        aprobarPorId: {
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/aprobarPorId/:id"
        },
        anular: {
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/anular/:id"
        },
        inutilizar: {
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/inutilizar/:id"
        },
        aplicar: {
            method: "POST",
            url: baseurl.getBaseUrl() + "/comprobanteventa/aplicar/:id"
        },
        desaplicar: {
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/desaplicar/:id"
        },
        solicitarAutorizacionComprobante: {
            method: 'PUT',
            url: baseurl.getBaseUrl() + "/comprobanteventa/solicitarautorizacioncomprobante/:id/:idMotivo"
        },
        solicitarAutorizacionBloqueo: {
            method: 'PUT',
            url: baseurl.getBaseUrl() + "/comprobanteventa/solicitarautorizacionbloqueo/:id/:idMotivo"
        },
        aprobarComprobantesPorId: {
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/aprobarComprobantesPorId/:id1/:id2"
        },
        reUtilizar: {
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/reUtilizar/:id"
        },
        nominacion: {
            method: "POST",
            url: baseurl.getBaseUrl() + "/comprobanteventa/nominacion/:id"
        }
    }
    );

    return service;

    function all(params, view) {
        params = params ? params : [];
        params.view = view != undefined ? view : "base";
        return Comprobante.query(params);
    }

    function get(id, view) {
        return Comprobante.get({ id: id, view: view != undefined ? view : "base" });
    }

    function create(attrs) {
        return new Comprobante(attrs);
    }

    function save(comprobante) {
        return comprobante.id ? comprobante.$update() : comprobante.$save();
    }

    function actualizarEstadoImpresion(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/comprobanteventa/actualizarEstadoImpresion/" +  id,
            method: "GET"
        });
    }

    function getMontosMes(comprobante, month, year, idProveedor) {
        return comprobante.$montosMes({
            month: month,
            year: year,
            idProveedor: idProveedor
        });
    }

    function aplicar(comprobante, modo) {
        return comprobante.$aplicar({ mode: modo });
    }

    function desaplicar(id) {
        return Comprobante.desaplicar({ id: id }).$promise;
    }

    function aprobar(id) {
        return Comprobante.aprobar({ id: id }).$promise;
    }

    function reenviar(id) {
        return Comprobante.reenviar({ id: id }).$promise;
    }

    function reUtilizar(id, id2) {
        return $http({
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/reUtilizar/" + id + "/" + id2
        });
    }
    

    function aprobarPorId(id) {
        return Comprobante.aprobarPorId({ id: id }).$promise;
    }

    function aprobarComprobantesPorId(id1, id2) {
        return $http({
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/aprobarComprobantesPorId/" + id1 + "/" + id2
        });
    }

    function recuperarPorId(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/comprobanteventa/recuperarPorId/" + id,
            method: "GET"
        });
    }

    // function anular(id) {
    //     return Comprobante.anular({ id: id }).$promise;
    // }

    function anular(id, clonar) {
      return $http({
          url: baseurl.getBaseUrl() + '/comprobanteventa/anular/' + id + '?clonar=' + clonar,
          method: "PUT"
      });
    }

    function inutilizar(id) {
        return Comprobante.inutilizar({ id: id }).$promise;
    }

    function saveWithPlanCobros(
        comprobante,
        cantcuotas,
        plazocuotas,
        montoinicial,
        facturacionagil,
        hasViewEdit
    ) {
        var obj = {
            cantcuotas: cantcuotas,
            plazocuotas: plazocuotas,
            montoinicial: montoinicial,
            facturacionagil: facturacionagil
        };
        return comprobante.id ? (hasViewEdit ? comprobante.$updateIgnoreEstado(obj) : comprobante.$update(obj)) : comprobante.$save(obj);
    }

    function remove(comprobante) {
        return comprobante.$remove();
    }

    function getDiferencia(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/comprobante/diferencias/" + id,
            method: "GET"
        });
    }

    function generarJsonVirtual(id, generarCdc) {
        return $http({
            url: baseurl.getBaseUrl() + "/comprobanteventa/generarJsonVirtual/" + id + '?generarCdc=' + generarCdc,
            method: "GET"
        });
    }

    function generarJsonVirtualDescarga(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/comprobanteventa/generarJsonVirtualDescarga/" + id,
            method: "GET"
        });
    }

    function validateNumeroComprobanteVenta(comprobante, formatString) {
        var input = comprobante.numero;
        if (comprobante.tipo && comprobante.tipo.clase.codigo == "nota_credito") {
            console.log(
                "se salta validación de formato de nro. de comprobante por ser nota de crédito"
            );
            return true; //no se valida numero comprobante
        }
        var sinCaracteresEspeciales = /^\d+$/.test(input);

        if (sinCaracteresEspeciales) {
            formatString = formatString.replace(/-/g, "");
            return input.length == formatString.length;
        }

        var inputSegments = input.split("-");
        var formatSegments = formatString.split("-");

        if (inputSegments.length != formatSegments.length) {
            return false;
        }

        for (var i = 0; i < inputSegments.length; i++) {
            if (inputSegments[i].length != formatSegments[i].length) {
                return false;
            }

            if (!parseInt(inputSegments[i])) {
                return false;
            }
        }
        return true;
    }


    function validateSucursalEmpresaFromNumeroComprobante(
        comprobante,
        formatoString
    ) {
        var sucursal = comprobante.timbradoPuntoEmision.puntoEmisionVenta.puntoVenta.codigoSucursal;
        var numero = comprobante.numero;
        if (comprobante.tipo && comprobante.tipo.clase.codigo == "nota_credito") {
            console.log(
                "se salta validación de sucursal del proveedor en nro. de comprobante por ser nota de crédito"
            );
            return true; //no se valida sucursal de proveedor
        }
        var numeroSucursalFormat = formatoString.split("-")[0];

        var numeroSucursal = numero.substring(0, numeroSucursalFormat.length);
        return numeroSucursal == sucursal;
    }

    function validatePuntoEmisionFromNumeroComprobanteVenta(
        comprobante,
        formatoString
    ) {
        var numero = comprobante.numero;
        if (comprobante.tipo && comprobante.tipo.clase.codigo == "nota_credito") {
            console.log(
                "se salta validación de punto de emisión de sucursal del proveedor en nro. de comprobante por ser nota de crédito"
            );
            return true;
        }
        var splittedFormat = formatoString.split("-");
        var lengthNumeroSucursal = splittedFormat[0].length;
        numero = numero.replace(/-/g, "");
        var numeroPuntoEmision = numero.substring(
            lengthNumeroSucursal,
            lengthNumeroSucursal + splittedFormat[1].length
        );
        //console.log("numero punto de emisión: %o ", numeroPuntoEmision);
        return comprobante.timbradoPuntoEmision.puntoEmisionVenta.numero === numeroPuntoEmision;
    }

    function validateRangoFromNumeroComprobanteVenta(
        comprobante,
        formatoString
    ) {
        var numero = comprobante.numero;
        /*if (comprobante.tipo && comprobante.tipo.clase.codigo == "nota_credito") {
            console.log(
                    "se salta validación de punto de emisión de sucursal del proveedor en nro. de comprobante por ser nota de crédito"
                    );
            return true;
        }*/
        //si es factura virtual no controlar rango
        
        var splittedFormat = formatoString.split("-");
        var lengthNumeroSucursal = splittedFormat[0].length;
        var lengthPuntoEmision = splittedFormat[1].length
        numero = numero.replace(/-/g, "");
        var numeroFacturaActual = numero.substring(
            lengthNumeroSucursal + lengthPuntoEmision,
            lengthNumeroSucursal + lengthPuntoEmision + splittedFormat[2].length
        );
        console.log("numero factura actual: %o ", numeroFacturaActual);
        numeroFacturaActual = parseInt(numeroFacturaActual);
        var numeroInicial = comprobante.timbradoPuntoEmision.numeroInicial;
        var numeroFinal = comprobante.timbradoPuntoEmision.numeroFinal;
        if(comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo == "timbrado_virtual"){
            return numeroFacturaActual >= numeroInicial;
        }
        return numeroFacturaActual >= numeroInicial && numeroFacturaActual <= numeroFinal;
    }

    function validateMontos(
        comprobante,
        monedaLocal,
        diffMonedaLocal,
        diffMonedaExtranjera
    ) {
        var diffPermitida = 0;

        if (comprobante.moneda.id == monedaLocal.valorTipo.id) {
            diffPermitida = diffMonedaLocal.valor;
        } else {
            diffPermitida = diffMonedaExtranjera.valor;
        }
        if (
            comprobante.grupoCompra &&
            comprobante.grupoCompra.codigo === "comprobante_importacion" &&
            comprobante.fob <= 0
        ) {
            return [false, "fob_invalid"];
        }
        sumaImponible = 0;
        var sumaTotal = _.reduce(
            comprobante.comprobanteDetalle,
            function (suma, valor) {
                return suma + valor.totalItem;
            },
            0
        );

        var diffAux = Math.abs(sumaTotal - comprobante.montoTotal);
        var diff = 0;

        if (comprobante.moneda.id == monedaLocal.valorTipo.id) {
            diff = diffAux;
        } else {
            diff = parseFloat(diffAux).toFixed(2);
            //diffPermitida = parseFloat(diffPermitida).toFixed(2);
        }
        console.log("diff", diff);
        //validación de impuestos
        if (comprobante.comprobanteDetalle.length > 0) {
            var comprobantesTipoImpuesto = _.groupBy(
                comprobante.comprobanteDetalle,
                function (detalle) {
                    return detalle.montosImponibles[0].tipoImpuesto.id;
                }
            );

            var montosImpuestos = _.groupBy(comprobante.montosImponibles, function (
                mi
            ) {
                return mi.tipoImpuesto.id;
            });

            var totalesDetalles = _.map(comprobantesTipoImpuesto, function (
                valor,
                clave
            ) {
                var obj = {};
                obj[clave] = _.reduce(
                    valor,
                    function (total, v) {
                        return total + v.montosImponibles[0].valor;
                    },
                    0
                );
                return obj;
            });

            var totalesImpuestos = _.map(montosImpuestos, function (valor, clave) {
                var obj = {};
                obj[clave] = _.reduce(
                    valor,
                    function (total, v) {
                        return total + v.valor;
                    },
                    0
                );
                return obj;
            });

            var tiposImpuestoDetalleID = Object.keys(comprobantesTipoImpuesto);
            var tiposImpuestoCabeceraID = Object.keys(montosImpuestos);

            var diffAcumulada = 0;
            for (x in totalesDetalles) {
                var keysA = Object.keys(totalesDetalles[x])[0];
                for (y in totalesImpuestos) {
                    var keysB = Object.keys(totalesImpuestos[y])[0];
                    if (keysA === keysB) {
                        diffAcumulada += Math.abs(
                            totalesDetalles[x][keysA] - totalesImpuestos[y][keysB]
                        );
                    }
                }
            }
        } else {
            console.log("NO COINCIDEN LOS IMPUESTOS. 3");
            return [false, "impuestos_mismatch"];
        }
        if (!_.isEqual(
            tiposImpuestoDetalleID.sort(),
            tiposImpuestoCabeceraID.sort()
        ) ||
            totalesDetalles.length != totalesImpuestos.length ||
            diffAcumulada > diffPermitida
        ) {
            console.log("NO COINCIDEN LOS IMPUESTOS. 4");
            return [false, "impuestos_mismatch"];
        }

        //comprobamos montos totales
        if (diff <= diffPermitida) {
            return [true];
        } else {
            console.log("MONTOS MISMATCH");
            return [false, "montos_mismatch"];
        }
    }

    // TODO verificar por qué no esta igual al factory de compras
    function igualarTipoImpuestoCabeceraADetealle(comprobante, monedaLocal) {
        // console.log("igualarTipoImpuestoCabeceraADetealle");
        //validación de impuestos
        var comprobantesTipoImpuesto = _.groupBy(
            comprobante.comprobanteDetalle,
            function (detalle) {
                return detalle.montosImponibles[0].tipoImpuesto.id;
            }
        );
        var montosImpuestos = _.groupBy(comprobante.montosImponibles, function (mi) {
            return mi.tipoImpuesto.id;
        });
        var totalesDetalles = _.map(comprobantesTipoImpuesto, function (
            valor,
            clave
        ) {
            var obj = {};
            obj[clave] = _.reduce(
                valor,
                function (total, v) {
                    return ( total +
                        v.montosImponibles[0].valor +
                        v.montosImponibles[0].baseImponible
                       /*  UtilFactory.roundForMoneda(total +
                            v.montosImponibles[0].valor +
                            v.montosImponibles[0].baseImponible, comprobante.moneda) */
                    );
                },
                0
            );
            return obj;
        });
        var totalesImpuestos = _.map(montosImpuestos, function (valor, clave) {
            var obj = {};
            obj[clave] = _.reduce(
                valor,
                function (total, v) {
                    return UtilFactory.roundForMoneda(total + v.valor, comprobante.moneda);
                },
                0
            );
            return obj;
        });
        var diffAcumulada = 0;
        for (x in totalesDetalles) {
            var keysA = Object.keys(totalesDetalles[x])[0];
            for (y in comprobante.montosImponibles) {
                if (keysA === comprobante.montosImponibles[y].tipoImpuesto.id + "") {
                    comprobante.montosImponibles[y].total =  UtilFactory.roundForMoneda(totalesDetalles[x][keysA], comprobante.moneda);
                }
            }
        }
        return comprobante;
    }

    function downloadCSVHechaukaVentas(mes, anio, tiporeporte, error) {
        $http({
            url: baseurl.getBaseUrl() + "/hechauka/venta/csv",
            method: "GET",
            params: { mes: mes, anio: anio, tiporeporte: tiporeporte }
        }).then(
            function (response) {
                if (response.data.filename != undefined) {
                    $window.open(
                        baseurl.getPublicBaseUrl() + response.data.filename,
                        "_blank"
                    );
                } else {
                    notify({
                        message: "Error al generar el archivo.",
                        classes: "alert-danger",
                        position: "right"
                    });
                }
            },
            function (response) {
                var mensaje = "<span>";
                _.forEach(response.data.error, function (err) {
                    mensaje = mensaje + "<br/>" + err;
                });
                mensaje = mensaje + "</span>";
                notify({
                    messageTemplate: mensaje,
                    classes: "alert-danger",
                    position: "right",
                    templateUrl: ""
                });
            }
        );
    }


    // TODO
    function validateNroFactura(form, comprobante, monedaLocal, formatoComprobante) {
        form.numero.$setValidity("error", true);
        completeNroFactura(comprobante);
        if (!comprobante.cliente || !comprobante.tipo || !comprobante.timbradoPuntoEmision || !comprobante.fecha) {
            $rootScope.isProcessing = false;
            var msg = $filter("translate")(
                "REQUIRED_FIELDS_TO_VALIDATE_NUMBER_2"
            );
            notify({
                message: msg,
                classes: "alert-danger",
                position: "right"
            });
            // El campo queda invalido.
            form.numero.$error.pattern = true;
            form.numero.$setValidity("error", false);
        } else {

            if (!validateNumeroComprobanteVenta(comprobante, formatoComprobante)) {

                $rootScope.isProcessing = false;
                var msg = $filter("translate")(
                    "FORMATO_NUMERO_COMPROBANTE_INCORRECTO"
                );
                notify({
                    message: msg,
                    classes: "alert-danger",
                    position: "right"
                });
                // El campo queda invalido.
                form.numero.$error.pattern = true;
                form.numero.$setValidity("error", false);
                return comprobante;
            }

            if (!validateSucursalEmpresaFromNumeroComprobante(comprobante, formatoComprobante)) {
                $rootScope.isProcessing = false;
                var msg = $filter("translate")(
                    "DIGITOS_COMPROBANTE_SUURSAL_MAL"
                );
                notify({
                    message: msg,
                    classes: "alert-danger",
                    position: "right"
                });
                // El campo queda invalido.
                form.numero.$error.pattern = true;
                form.numero.$setValidity("error", false);
                return comprobante;
            }

            if (!validatePuntoEmisionFromNumeroComprobanteVenta(comprobante, formatoComprobante)) {
                $rootScope.isProcessing = false;
                var msg = $filter("translate")(
                    "DIGITOS_COMPROBANTE_NUMERO_EMISION_MAL"
                );
                notify({
                    message: msg,
                    classes: "alert-danger",
                    position: "right"
                });
                // El campo queda invalido.
                form.numero.$error.pattern = true;
                form.numero.$setValidity("error", false);
                return comprobante;
            }

            var numeroInicial = comprobante.timbradoPuntoEmision.numeroInicial;
            var numeroFinal = comprobante.timbradoPuntoEmision.numeroFinal;

            if (!validateRangoFromNumeroComprobanteVenta(comprobante, formatoComprobante)) {
                $rootScope.isProcessing = false;
                var msg = $filter("translate")(
                    "NUMERO_COMPROBANTE_FUERA_RANGO", {
                    inicial: numeroInicial,
                    final: numeroFinal
                }
                );
                notify({
                    message: msg,
                    classes: "alert-danger",
                    position: "right"
                });
                // El campo queda invalido.
                form.numero.$error.pattern = true;
                form.numero.$setValidity("error", false);
                return comprobante;
            }

            form.numero.$setValidity("error", true);
        }
        return comprobante;
    }

    function completeNroFactura(comprobante) {

        if (comprobante) {
            var nro = comprobante.numero;
        }

        if (nro) {
            var nroArr = nro.split("-");
            if (nroArr.length == 3) {
                if (nroArr[0].length < 3) {
                    var cantCeros = 3 - nroArr[0].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[0] = ceros + nroArr[0];
                }
                if (nroArr[1].length < 3) {
                    var cantCeros = 3 - nroArr[1].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[1] = ceros + nroArr[1];
                }
                if (nroArr[2].length < 7) {
                    var cantCeros = 7 - nroArr[2].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[2] = ceros + nroArr[2];
                }
            } else if (nroArr.length == 2) {
                if (nroArr[0].length < 3) {
                    var cantCeros = 3 - nroArr[0].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[0] = ceros + nroArr[0];
                }
                if (nroArr[1].length < 3) {
                    var cantCeros = 3 - nroArr[1].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[1] = ceros + nroArr[1];
                }
            } else if (nroArr.length == 1) {
                if (nroArr[0].length < 3) {
                    var cantCeros = 3 - nroArr[0].length;
                    var ceros = "";
                    for (i = 0; i < cantCeros; i++) {
                        ceros = ceros + "0";
                    }
                    nroArr[0] = ceros + nroArr[0];
                }
            }
            if (nroArr.length == 3) {
                comprobante.numero = nroArr[0] + "-" + nroArr[1] + "-" + nroArr[2];
            } else if (nroArr.length == 2) {
                comprobante.numero = nroArr[0] + "-" + nroArr[1];
            } else if (nroArr.length == 1) {
                comprobante.numero = nroArr[0];
            } else {
                comprobante.numero = "";
            }
        }
        return comprobante.numero;
    }

    function hasPermisos(fromOutSide, tipo, estado, tipoComprobante, opd, hasAplicaciones, hasComprobanteAplicaciones, venta, origen) {
        switch (tipo) {
            case "new":
                return AuthorizationService.hasPermission(
                    "create_comprobanteventa"
                ) && ((VentaFactory.getVenta() && VentaFactory.getVenta().estado.codigo != "venta_cerrada" && !VentaFactory.getVenta().planillaReparto) ||
                    !VentaFactory.getVenta());

            case "edit":
                if (fromOutSide) {
                    return AuthorizationService.hasPermission("update_comprobanteventa") &&
                        (!venta) && estado.codigo == "comp_venta_ingresado";
                }
                return AuthorizationService.hasPermission("update_comprobanteventa") &&
                    ((venta && venta.estado.codigo != "venta_cerrada" && !venta.planillaReparto) || !venta) &&
                    (estado.codigo == "comp_venta_ingresado" || estado.codigo == "comp_venta_preparado" || estado.codigo == "comp_venta_agendado")
            case "delete":
                if (fromOutSide) {
                    return AuthorizationService.hasPermission("delete_comprobante") &&
                        (!venta) &&
                        estado.codigo == "comp_venta_ingresado";
                }
                return AuthorizationService.hasPermission("delete_comprobante") &&
                    ((venta && venta.estado.codigo != "venta_cerrada" && !venta.planillaReparto) || !venta) &&
                    (estado.codigo == "comp_venta_ingresado" || estado.codigo == "comp_venta_preparado" || estado.codigo == "comp_venta_agendado");

            case "view":
                return AuthorizationService.hasPermission(
                    "index_comprobanteventa"
                );
            case "confirm":
                if (fromOutSide) {
                    return AuthorizationService.hasPermission("update_comprobanteventa") &&
                        (estado.codigo === "comp_venta_ingresado") && (!venta)
                }
                return AuthorizationService.hasPermission("update_comprobanteventa") &&
                    (estado.codigo === "comp_venta_ingresado") &&
                    VentaFactory.getVenta() && VentaFactory.getVenta().estado.codigo != "venta_cerrada"

            case "reprint":
                return AuthorizationService.hasPermission("update_comprobanteventa") &&
                    (estado.codigo === "comp_venta_emitido")
            case "anular":
                if (fromOutSide) {
                    return AuthorizationService.hasPermission("update_comprobanteventa") &&
                        (estado.codigo == "comp_venta_emitido" || estado.codigo == "comp_venta_aprobado_sifen") && !venta && (origen == null || origen.codigo != "comp_venta_pos");
                }
                return AuthorizationService.hasPermission("update_comprobanteventa") &&
                    ((venta && venta.estado.codigo != "venta_cerrada"));
            case "inutilizar":
                if (fromOutSide) {
                    if(origen == null || origen.codigo != "comp_venta_qualita_agil" && origen.codigo != "comp_venta_pos"){
                        // No es QUALITA AGIL o no tiene origen, no puede inutilizar fuera de flujo    
                        return false;
                    }
                }

                // Si tiene flujo de venta y esta cerrado, no puede inutilizar
                if(venta){
                   if(venta.estado.codigo == "venta_cerrada"){
                        return false;
                   }
                }

                // Si pasa los controles anteriores y tiene permiso, puede inutilizar
                return AuthorizationService.hasPermission("update_comprobanteventa");

            case "aplicar":
                return (AuthorizationService.hasPermission(
                    "update_comprobanteventa"
                ) &&
                    tipoComprobante.aplicable == true &&
                    (estado.codigo == "comp_venta_emitido" || estado.codigo == "comp_venta_aprobado_sifen"))
            case "viewPlanCobros":
                return AuthorizationService.hasPermission("update_comprobanteventa") &&
                    tipoComprobante.generarCuotas == true
            case "viewConsultaPagos":
                return AuthorizationService.hasPermission("index_comprobanteventa") &&
                    opd == "Sí"
            case "viewConsultaAplicaciones":
                return AuthorizationService.hasPermission("index_comprobanteventa") &&
                    (hasAplicaciones == "Sí" || hasComprobanteAplicaciones == "Sí")
        }
    }

    function getEstaAsentado(id) {
        return $http({
            url: baseurl.getBaseUrl() + '/comprobanteventa/estaAsentado/' + id,
            method: "GET"
        });
    }

    function getSaldoByCliente(idCliente, idCurrency, idSuc,
                               idSucursalCliente, idCategoriaCliente, idActividadRamo, idFormaPago, idVendedor,
                               checkFacturas, checkNotasCredito, checkNotasDebito, checkCheques) {

        return $http({
            url: baseurl.getBaseUrl() + '/comprobanteventa/saldo-cliente',
            method: "GET",
            params: {
              idCliente: idCliente,
              idSucursal:idSuc,
              idMoneda: idCurrency,
              idSucursalCliente: idSucursalCliente,
              idCategoriaCliente: idCategoriaCliente,
              idActividadRamo: idActividadRamo,
              idFormaPago: idFormaPago,
              idVendedor: idVendedor,
              checkFacturas: checkFacturas,
              checkNotasCredito: checkNotasCredito,
              checkNotasDebito: checkNotasDebito,
              checkCheques: checkCheques
            }
        });
    }

    function solicitarAutorizacionComprobante(id,idMotivo) {
        //return Comprobante.solicitarAutorizacionComprobante({ id: id }).$promise;
        return $http({
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/solicitarautorizacioncomprobante/" + id + "/" + idMotivo
        });
    }

    function verificarVencimiento(id) {
        return $http({
            url: baseurl.getBaseUrl() + '/comprobanteventa/verificarvencimiento/' + id,
            method: "GET"
        });
    }

    function solicitarAutorizacionBloqueo(id,idMotivo) {
        //return Comprobante.solicitarAutorizacionBloqueo({ id: id }).$promise;
        return $http({
            method: "PUT",
            url: baseurl.getBaseUrl() + "/comprobanteventa/solicitarautorizacionbloqueo/" + id + "/" + idMotivo
        });
    }

    function downloadCSVArchivoVentas(mes, anio, error) {
        $http({
            url: baseurl.getBaseUrl() + "/comprobanteventa/archivoventa/csv",
            method: "GET",
            params: { mes: mes, anio: anio }
        }).then(
            function (response) {
                if (response.data.filename != undefined) {
                    $window.open(
                        baseurl.getPublicBaseUrl() + response.data.filename,
                        "_blank"
                    );
                } else {
                    notify({
                        message: "Error al generar el archivo.",
                        classes: "alert-danger",
                        position: "right"
                    });
                }
            },
            function (response) {
                var mensaje = "<span>";
                _.forEach(response.data.error, function (err) {
                    mensaje = mensaje + "<br/>" + err;
                });
                mensaje = mensaje + "</span>";
                notify({
                    messageTemplate: mensaje,
                    classes: "alert-danger",
                    position: "right",
                    templateUrl: ""
                });
            }
        );
    }

    function actualizarEstadoRendicion(idComprobanteVenta, codigoEstadoRendicion) {
        return $http({
            url: baseurl.getBaseUrl() + '/comprobanteventa/actualizarcomprobante/' + idComprobanteVenta + "/" + codigoEstadoRendicion,
            method: "GET"
        });
    }

    function verificarExistenciaTimbrado(id) {
        return $http({
            url: baseurl.getBaseUrl() + '/comprobanteventa/verificarexistenciatimbrado/' + id,
            method: "GET"
        });
    }
    function getVentasBySuscripcion(idSuscripcion){
        var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
        path: 'comprobantes.suscripcionRecurrente.id',
        equals: idSuscripcion
        }]).value();
        return  all(filtroActivo);
    }
    function importarCsv (name, locacion) {
      return $http({
        url: baseurl.getBaseUrl() + '/comprobanteventa/importarCsv',
        method: "POST",
        params: { filename: name, locacion: locacion}
      });
    }

    function nominacion(id) {
        return Comprobante.nominacion({ id: id }).$promise;
    }
}


"use strict";

angular
    .module("qualita.venta")
    .controller("ComprobanteVentaListCtrl", ComprobanteVentaListCtrl);

ComprobanteVentaListCtrl.$inject = [
    "AuthorizationService",
    "AuthenticationService",
    "ComprobanteVentaFactory",
    "VentaFactory",
    "$modal",
    "$scope",
    "$state",
    "$rootScope",
    "filterFactory",
    "notify",
    "VentasLangFactory",
    "WatchLocacionChangeFactory",
    "$filter",
    "cajaFactory",
    "ReportTicketFactory",
    "$window",
    "UtilFactory",
    "CsvFactory",
    "baseurl",
    "CobroFactory",
    "ProcesoCobroFactory",
    "$q",
    "PeriodoAdministrativoFactory",
    "ParametrosFactory",
    "CONFIGURATION",
    "SolicitudAutorizacionFactory",
    "TiposTipoFactory",
    "ClienteFactory",
    "$timeout",
    "TipoComprobanteFactory",
    "TimbradoEmpresaPuntoEmisionVentaFactory",
    "puntoEmisionFactory",
    "$http",
    "suscripcionRecurrenteFactory",
    "agendaSuscripcionRecurrenteFactory",
    "TiposFactory"
];

function ComprobanteVentaListCtrl(
    AuthorizationService,
    AuthenticationService,
    ComprobanteVentaFactory,
    VentaFactory,
    $modal,
    $scope,
    $state,
    $rootScope,
    filterFactory,
    notify,
    VentasLangFactory,
    WatchLocacionChangeFactory,
    $filter,
    cajaFactory,
    ReportTicketFactory,
    $window,
    UtilFactory,
    CsvFactory,
    baseurl,
    CobroFactory,
    ProcesoCobroFactory,
    $q,
    PeriodoAdministrativoFactory,
    ParametrosFactory,
    CONFIGURATION,
    SolicitudAutorizacionFactory,
    TiposTipoFactory,
    ClienteFactory,
    $timeout,
    TipoComprobanteFactory,
    TimbradoEmpresaPuntoEmisionVentaFactory,
    puntoEmisionFactory,
    $http,
    suscripcionRecurrenteFactory,
    agendaSuscripcionRecurrenteFactory,
    TiposFactory
) {
    var vm = this;

    vm.formSuscripcion = false;
    vm.comprobanteProcesadoSuscripcion = false;

    vm.uiBlockuiConfig = {
        'bloquear': false
    };

    vm.comprobantes = comprobantes;
    vm.filterTimbrado = filterTimbrado;
    vm.searchTimbradoPuntoEmisionFilter = searchTimbradoPuntoEmisionFilter;
    vm.getPuntosEmisionVenta = getPuntosEmisionVenta;
    vm.puntoEmisionSelect = puntoEmisionSelect;
    vm.generarFechasPlantilla = generarFechasPlantilla;
    vm.generarFechas = generarFechas;
    vm.diaDeLaSemana = diaDeLaSemana;
    vm.comprobante = null;
    var agendasSuscripcionRecurrentes = [];
    vm.agendaSuscripcionRecurrente = {};
    vm.realizarTareasEspeciales = null;
    vm.fechaSelect = fechaSelect;


    activate();


    function diaDeLaSemana(dia) {
        switch (dia.toLowerCase()) {
          case 'lunes':
            return 1;
          case 'martes':
            return 2;
          case 'miercoles':
            return 3;
          case 'jueves':
            return 4;
          case 'viernes':
            return 5;
          case 'sabado':
            return 6;
          case 'domingo':
            return 0;
          default:
            throw new Error('Día inválido: ' + dia);
        }
      }

function generarFechas(fechaInicial, fechaFinal, periodicidad, dias, numeroDia) {

        var fechasValidas = [];
        var initialDateParts = fechaInicial.split("/");
        var finalDateParts = fechaFinal.split("/");
        var fechaActual = new Date(initialDateParts[2], initialDateParts[1] - 1, initialDateParts[0]);
        var fechaFinal = new Date(finalDateParts[2], finalDateParts[1] - 1, finalDateParts[0], 23, 59, 59);


        // Incluye la fecha inicial como una fecha válida
        fechasValidas.push(new Date(fechaActual));

        while (fechaActual < fechaFinal) {
          // Agrega el periodo a la fecha actual
          switch (periodicidad.toLowerCase()) {
            case 'diaria':
              fechaActual.setDate(fechaActual.getDate() + 1);
              break;
            case 'semanal':
              fechaActual.setDate(fechaActual.getDate() + 7);
              break;
            case 'mensual':
              if (numeroDia) {
                // Establece el día del mes al número de día especificado
                fechaActual.setDate(numeroDia);
              }
                // Agrega un mes a la fecha actual
              fechaActual.setMonth(fechaActual.getMonth() + 1);

              break;
            case 'semestral':
              if (numeroDia) {
                // Establece el día del mes al número de día especificado
                fechaActual.setDate(numeroDia);
                // Establece el mes en julio o enero dependiendo de si el número de día está en la primera o la segunda mitad del año
                var mesObjetivo = (numeroDia <= 15) ? 6 : 0; // 6 para julio, 0 para enero
                fechaActual.setMonth(mesObjetivo);
              }
                // Agrega 6 meses a la fecha actual
              fechaActual.setMonth(fechaActual.getMonth() + 6);

              break;
            case 'trimestral':
              if (numeroDia) {
                // Establece el día del mes al número de día especificado
                fechaActual.setDate(numeroDia);
                // Establece el mes al trimestre más cercano
                var trimestreObjetivo = Math.floor((fechaActual.getMonth() - 1) / 3) + 1;
                var mesObjetivo = trimestreObjetivo * 3;
                fechaActual.setMonth(mesObjetivo);
              }
              // Agrega 3 meses a la fecha actual
              fechaActual.setMonth(fechaActual.getMonth() + 3);

              break;
            case 'anual':
              fechaActual.setFullYear(fechaActual.getFullYear() + 1);
              break;
            default:
              throw new Error('Periodicidad inválida: ' + periodicidad);
          }

        // Verifica si la fecha actual es válida
        var esFechaValida = true;
        if (dias.length > 0) {
        esFechaValida = false;
        var diaDeLaSemana = fechaActual.getDay();
        for (var i = 0; i < dias.length; i++) {
            var dia = dias[i];
            if (dia.value && diaDeLaSemana === vm.diaDeLaSemana(dia.name)) {
            esFechaValida = true;
            break;
            }
        }
        }
        if (!esFechaValida) {
        if (dias) {
            // Obtiene la fecha válida más cercana
            var fechaValidaMasCercana = null;
            var diferenciaFechaValidaMasCercana = Infinity;
            for (var i = 0; i < dias.length; i++) {
            var dia = dias[i];
            if (dia.value) {
                var fechaObjetivo = new Date(fechaActual);
                var diaDeLaSemanaObjetivo = vm.diaDeLaSemana(dia.name);
                while (fechaObjetivo.getDay() != diaDeLaSemanaObjetivo) {
                fechaObjetivo.setDate(fechaObjetivo.getDate() + 1);
                }
                var diferencia = fechaObjetivo - fechaActual;
                if (diferencia < 0) {
                diferencia += 7 * 24 * 60 * 60 * 1000;
                }
                if (diferencia < diferenciaFechaValidaMasCercana) {
                fechaValidaMasCercana = fechaObjetivo;
                diferenciaFechaValidaMasCercana = diferencia;
                }
            }
            }
            if (fechaValidaMasCercana) {
            fechaActual = fechaValidaMasCercana;
            esFechaValida = true;
            }
        }
        if (!esFechaValida) {
            continue;
        }
    }

    // Agrega la fecha válida al resultado
    fechasValidas.push(new Date(fechaActual));
  }

  // Se filtran las fechas para no superar la fecha final
  fechasValidas = _.filter(fechasValidas, function(date) {
    return date <= fechaFinal;
  });

  return fechasValidas;
}

    function generarFechasPlantilla(){

        var fechas = generarFechas(vm.comprobante.primeraFacturacion, vm.comprobante.fechaFinal,
            vm.comprobante.tipoPeriodicidad.codigo, JSON.parse(vm.comprobante.diasDeLaSemana), vm.comprobante.fechaMes);

        console.log(fechas);


        $scope.mensajeModal = $filter("translate")("AGENDAR_PLANTILLA_SUSCRIPCION_MENSAJE");

        $scope.fechasArr = [];

        for (var i = 1; i < fechas.length +1; i++) {
            $scope.fechasArr.push( i.toString() + ': ' + moment(fechas[i-1]).format('DD/MM/YYYY'));
        }

        console.log(fechas);
        $scope.tituloModal = $filter("translate")("AGENDAR_PLANTILLA");
        var modalInstance = $modal.open({
                     template: '<div class="modal-header">\n' +
                     '  <h3 class="modal-title">{{::tituloModal}}</h3>\n' +
                     '</div>\n' +
                     '<div class="modal-body">{{::mensajeModal}} <div ng-repeat="str in fechasArr"> {{ str }}<br> </div> </div>\n' +
                     '<div class="modal-footer">\n' +
                     '  <button id="{{::selectedItemId}}" class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">{{si_no == true ? "Si" : "Aceptar"}}</button>\n' +
                     '  <button class="btn btn-warning" ng-click="cancel()">{{si_no == true ? "No" : "Cancelar"}}</button>\n' +
                     '</div>',
                     scope: $scope
                     });
        $scope.cancel = function() {
            modalInstance.dismiss("cancel");
        };

        $scope.ok = function() {
            if(vm.comprobante.estado.codigo == 'comp_venta_preparado'){

                 _.forEach(fechas, function (fecha) {

                        var agendaSuscripcion = {};
                        agendaSuscripcion.tipoOperacionGenerar = suscripcionRecurrenteFactory.getSuscripcion().tipoOperacionGenerar;
                        agendaSuscripcion.cliente = vm.comprobante.cliente;
                        agendaSuscripcion.activo = true;
                        agendaSuscripcion.importe = vm.comprobante.montoTotal;
                        agendaSuscripcion.comprobanteVenta = vm.comprobante;
                        agendaSuscripcion.fecha = suscripcionRecurrenteFactory.getSuscripcion().fecha;
                        agendaSuscripcion.fechaGeneracion = new Date(fecha.setHours(7,0,0));
                        agendaSuscripcion.suscripcionRecurrente = suscripcionRecurrenteFactory.getSuscripcion();
                        agendaSuscripcion.intervaloRepeticion = vm.agendaSuscripcionRecurrente.intervaloRepeticion;
                        agendaSuscripcion.accion = vm.agendaSuscripcionRecurrente.accion;
                        agendaSuscripcion.estado = vm.agendaSuscripcionRecurrente.estado;
                        agendasSuscripcionRecurrentes.push(agendaSuscripcionRecurrenteFactory.create(agendaSuscripcion));

                    });

                    return submitAgendaSuscripcionRecurrente().then(
                        function (agendasuscripcionrecurrente) {

                        console.log(agendasuscripcionrecurrente);
                        $state.go("app.suscripcionrecurrente.proceso.agendamiento.list", {
                            idSuscripcion: suscripcionRecurrenteFactory.getIdSuscripcion()
                        }, { reload: true });

                        },
                        function(error) {
                            $rootScope.isProcessing = false;
                            console.log("Ha ocurrido un error");
                            console.log(error);
                            var msg = 'Error al Generar Agendas';
                            notify({ message: msg, classes: 'alert-danger', position: 'right'});
                        }
                    );

            }
        }

    }

    function submitAgendaSuscripcionRecurrente() {

      return agendaSuscripcionRecurrenteFactory.saveArray(agendasSuscripcionRecurrentes);

    }


    function activate() {
        $scope.bloqueoConfirmar = false;
        vm.formSuscripcion = false;
        vm.comprobanteProcesadoSuscripcion = false;
        vm.valorTipo = "";
        console.log("Activate de Index Comprobante Venta");
        ParametrosFactory.getByCodigo('generacion_factura_electronica').then(function (response) {
            vm.valorTipo = response.valorTipo.codigo;
        });

        var monedaRender = function (data, type, row) {
            var campoMoneda = null;
            if (row.moneda != undefined) {
                campoMoneda = row.moneda;
            } else {
                if (row.cobro) {
                    campoMoneda = row.cobro.moneda;
                } else {
                    campoMoneda = row.comprobanteVentaAplicado.moneda
                }
            }

            if (data != undefined) {
                var moneda = "Gs. ";
                if (campoMoneda.codigo === "dolares") {
                    moneda = "Usd. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "pesos") {
                    moneda = "Pes. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "real") {
                    moneda = "Rel. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "euro") {
                    moneda = "Eur. ";
                    data = parseFloat(data).toFixed(2);
                } else {
                    data = parseFloat(data).toFixed(0);
                }
                // TODO hacer esto mismo en todos los renders de moneda
                return (
                    moneda +
                    data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            } else {
                return "";
            }
        };

        var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
        var enviarAContabilidad = '';

        promise1.then(function (response) {
            enviarAContabilidad = response.valorTipo.codigo;
        });

        var defaultColumnOrder = [
            "id",
            "cdc",
            "numero",
            "fecha",
            "cliente.codigo",
            "cliente.nombre",
            "razonSocial",
            "numeroDocumento",
            "tipo.descripcion",
            "cambio",
            "venta.planillaReparto.numero",
            "vendedor.nombresyapellidos",
            "pedidoVenta.id",
            "estado.descripcion",
            "estadoSifen.descripcion",
            "estadoRendicion.descripcion",
            "fechaModificacionRendicion",
            "proyecto.nombre",
            "timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
            "timbradoPuntoEmision.puntoEmisionVenta.numero",
            "montoTotal",
            "saldo",
            "listaPrecioVenta.descripcion",
            "moneda.descripcion",
            "totalImpuestos",
            "origen.descripcion",
            "reimprimir"

        ];

        var renderVendedorData = function (data) {
            if (data == undefined)
                return "";
            else
                return data.nombres + " " + data.apellidos;
        };

        var renderOrigen = function (data) {
            if (data == undefined)
                return "QUALITA";
            else
                return data;
        };

        ParametrosFactory.getByCodigo('realizar_tareas_especiales').then(function (response) {
            vm.realizarTareasEspeciales = response.valorTipo.codigo;
        });



        VentasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            var venta = undefined;
            var staticFilter = undefined;
            console.log("A punto de validar ruta: ", $state)

            if ($state.is("app.consultacomprobantesventa.list")) {
                console.log("From outside");
                vm.fromOutside = true;
                filterTipoComprobantes();
            } else if ($state.is("app.ventas.proceso.comprobanteventa.list")) {
                console.log("From Venta Process");
                vm.procesoVenta = true;
                venta = VentaFactory.getVenta();
                $scope.venta = VentaFactory.getVenta();
                console.log("tipoVenta: ", venta);
                var modo = VentaFactory.getModoAcceso();
                if (!venta) {
                    console.log("No se encontró ninguna venta.");
                    $state.go("app.main");
                } else {
                    filterTipoComprobantes(venta.id);
                }
                var tipo = venta.tipo;
                var tipoVentaDetalle = _.find(venta.tipo.tipoVentaDetalles, function (
                    detalle
                ) {
                    return detalle.componente.codigo == "comp_tipo_venta_comprobante";
                });
                if (!tipoVentaDetalle) {
                    console.log("No tiene permiso para revisar esta seccion");
                    $state.go("app.main");
                }
                var isStateEnabled = VentaFactory.checkPermissionForState(
                    tipoVentaDetalle.orden - 1
                );
                if (!isStateEnabled) {
                    console.log("No tiene permiso para revisar esta seccion");
                    $state.go("app.main");
                }

                // filtro estático
                staticFilter = {};
                staticFilter.search = filterFactory
                    .and([
                    {
                        path: "venta.id",
                        equals: venta.id
                    },
                    {
                        path: "id",
                        sortDesc: "true"
                    },
                    {
                        path: "id",
                        isNotNull: "true"
                    }
                ]).value();

            }else if($state.is("app.suscripcionrecurrente.proceso.comprobanteventa.list")){

                staticFilter = {};
                vm.formSuscripcion = true;

                staticFilter.search = filterFactory.or([

                    filterFactory.and([
                    {
                        path: "suscripcionRecurrente.id",
                        equals: suscripcionRecurrenteFactory.getIdSuscripcion()
                    },
                    {
                        path: "estado.codigo",
                        equals: "comp_venta_preparado"
                    }
                    ]).value(),
                    filterFactory.and([
                        {
                            path: "suscripcionRecurrente.id",
                            equals: suscripcionRecurrenteFactory.getIdSuscripcion()
                        },
                        {
                            path: "estado.codigo",
                            equals: "comp_venta_agendado"
                        }
                    ]).value()
                ]).value();

            }else if($state.is("app.suscripcionrecurrente.proceso.procesado.list")){

                staticFilter = {};
                vm.formSuscripcion = true;
                vm.comprobanteProcesadoSuscripcion = true;
                staticFilter.search = filterFactory
                    .and([
                    {
                        path: "suscripcionRecurrente.id",
                        equals: suscripcionRecurrenteFactory.getIdSuscripcion()
                    },
                    {
                        path: "estado.codigo",
                        notEquals: "comp_venta_preparado"
                    },
                    {
                        path: "estado.codigo",
                        notEquals: "comp_venta_agendado"
                    }
                ]).value();
            }

            if(staticFilter == undefined){
                staticFilter = {};
                staticFilter.search = filterFactory
                    .and([
                    {
                        path: "estado.codigo",
                        notEquals: "comp_venta_preparado"
                    },
                    {
                        path: "estado.codigo",
                        notEquals: "comp_venta_agendado"
                    },
                    {
                        path: "id",
                        sortDesc: "true"
                    },
                    {
                        path: "id",
                        isNotNull: "true"
                    }
                ]).value();
            }

            if (vm.fromOutside) {
                defaultColumnOrder.push("venta.codigo");
            }

            vm.options = {
                staticFilter: staticFilter,
                resource: "comprobanteventa",
                title: (!vm.formSuscripcion)? $filter("translate")("COMPROBANTES") : 'Plantillas',
                factory: ComprobanteVentaFactory,
                defaultColumnOrder: defaultColumnOrder,
                view: "ConsultaComprobanteVentaList",
                failedDeleteError: $filter("translate")("DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS"),
                columns: [
                    { data: "id", title: $filter("translate")("CODE"), visible: false },
                    { data: "cdc", title: $filter("translate")("CDC"), visible: false },
                    { data: "numero", title: $filter("translate")("NUMBER"), class: "dt-center" },
                    {
                        data: "fecha",
                        title: $filter("translate")("DATE"),
                        renderWith: "dateRender",
                        dateFormat: "DD/MM/YYYY",
                        class: "dt-center",
                        type: "date-range"
                    },
                    { data: "cliente.codigo", title: $filter("translate")("CODIGO_CLIENTE"), visible: false },
                    { data: "cliente.nombre", title: $filter("translate")("CLIENTE") },
                    {
                        data: "razonSocial",
                        title: "Cliente POS",
                        renderWith: "emptyRender",
                        visible: false
                    },
                    {
                        data: "numeroDocumento",
                        title: "Número Documento POS",
                        renderWith: "emptyRender",
                        visible: false
                    },
                    { data: "sucursalCliente.codigoSucursal", title: $filter("translate")("CODIGO_SUCURSAL"), renderWith: 'emptyRender' },
                    { data: "sucursalCliente.descripcion", title: $filter("translate")("DESCRIPCION_SUCURSAL"), renderWith: 'emptyRender' },
                    { data: "venta.planillaReparto.numero", title: $filter("translate")("NUMERO_REPARTO"), renderWith: 'emptyRender' },
                    { data: "vendedor.nombresyapellidos", title: $filter("translate")("VENDEDOR"), renderWith: 'emptyRender', searchable: false },
                    { data: "pedidoVenta.id", title: $filter("translate")("PEDIDO_VENTA"), renderWith: "emptyRender" },
                    { data: "tipo.descripcion", title: $filter("translate")("TIPO_COMPROBANTE") },
                    { data: "cambio", title: $filter("translate")("TIPO_CAMBIO") },
                    {
                        data: "comentario",
                        title: "Comentario",
                        class: "dt-right",
                        visible: false,
                        renderWith: "emptyRender"

                    },
                    {
                        data: "venta.codigo",
                        title: $filter("translate")("VENTA_REL"),
                        renderWith: "emptyRender",
                        class: "dt-left"

                    },
                    {
                        data: "estado.descripcion",
                        title: $filter("translate")("STATUS"),
                        renderWith: "emptyRender",
                        class: "dt-center",
                        type: "combo",
                        filterUrl: "tipos/filtro/estados_comprobante_venta"
                    },
                    {
                        data: "estadoSifen.descripcion",
                        title: "Estado Sifen",
                        renderWith: "emptyRender",
                        class: "dt-center",
                        type: "combo",
                        filterUrl: "tipos/filtro/estados_sifen"
                    },
                    {
                        data: "estadoRendicion.descripcion",
                        title: $filter("translate")("ESTADO_RENDICION"),
                        renderWith: "emptyRender",
                        class: "dt-center",
                        type: "combo",
                        filterUrl: "tipos/filtro/estados_rendicion",
                        visible: true
                    },
                    {
                        data: "fechaModificacionRendicion",
                        title: $filter("translate")("FECHA_MODIFICACION_RENDICION"),
                        renderWith: "dateRender",
                        dateFormat: "DD/MM/YYYY",
                        class: "dt-center",
                        type: "date-range"
                    },
                    {
                        data: "proyecto.nombre",
                        title: "Proyecto",
                        renderWith: "emptyRender",
                    },
                    {
                        data: "timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
                        title: $filter("translate")("NUMERO_TIMBRADO"),
                        class: "dt-right",
                        renderWith: "emptyRender"

                    },
                    {
                        data: "timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.descripcion",
                        title: $filter("translate")("CLASE_TIMBRADO"),
                        class: "dt-right",
                        visible: false,
                        renderWith: "emptyRender"

                    },
                    {
                        data: "timbradoPuntoEmision.puntoEmisionVenta.numero",
                        title: $filter("translate")("PUNTO_EMISION"),
                        class: "dt-right",
                        renderWith: "emptyRender"

                    },
                    {
                        data: "montoTotal",
                        title: $filter("translate")("MONTO"),
                        type: "number-range",
                        render: monedaRender,
                        class: "dt-right"
                    },
                    {
                        data: "saldo",
                        title: $filter("translate")("SALDO_PENDIENTE"),
                        type: "number-range",
                        render: monedaRender,
                        class: "dt-right"
                    },
                    {
                        data: "listaPrecioVenta.descripcion",
                        title: $filter("translate")("LISTA_PRECIO_VENTA"),
                        renderWith: "emptyRender",
                        class: "dt-right"
                    },
                    {
                        data: "moneda.descripcion",
                        visible: false,
                        title: $filter("translate")("MONEDA"),
                        renderWith: "emptyRender"
                    },
                    {
                        data: "totalImpuestos",
                        title: $filter("translate")("TOTAL_IMPUESTO"),
                        class: "dt-right",
                        render: monedaRender,
                        type: "number-range",
                        visible: false
                    }, {
                        data: "origen.descripcion",
                        title: "Origen",
                        type: "combo",
                        render: renderOrigen,
                        class: "dt-center",
                        filterUrl: "tipos/filtro/origen_comprobante_venta"
                    },
                    {
                        data: "reimprimir",
                        title: "Reimprimir",
                        renderWith: "emptyRender",
                        visible: false
                    }
                ],
                hasOptions: true,
                hideAddMenu: true,
                extraMenuOptions:
                    [
                        {
                            title: $filter("translate")("EMITIR_COMPROBANTES"),
                            icon: "glyphicon glyphicon-check",
                            showCondition: function () {
                                if(vm.formSuscripcion){
                                    return false;
                                }

                                if (!vm.fromOutside && venta.planillaReparto) {
                                    return true;
                                }
                                else {
                                    return false;
                                }

                            },
                            action: function () {
                                var staticFilter = {};
                                staticFilter.search = filterFactory.and([
                                {
                                    path: 'venta.id',
                                    equals: venta.id
                                }
                                , {
                                    path: 'estado.codigo',
                                    equals: 'comp_venta_ingresado'
                                },{
                                    path: "id",
                                    sortDesc: "true"
                                },
                                {
                                    path: "id",
                                    isNotNull: "true"
                                }]).value();

                                ComprobanteVentaFactory.all(staticFilter, "ComprobanteVentaList").$promise.then(function (response) {
                                    $scope.comprobantes = response;
                                });

                                $scope.tituloModal = $filter("translate")("CONFIRMAR_COMPROBANTES");
                                $scope.mensajeModal = translations.DELETE_WARNING;
                                var modalInstance = $modal.open({
                                    templateUrl: "views/directives/confirmar-comprobantes-modal.html",
                                    scope: $scope
                                });


                                $scope.cancel = function () {
                                    modalInstance.dismiss("cancel");
                                };


                                $scope.ok = function (comprobanteInicial, comprobanteFinal) {
                                    vm.uiBlockuiConfig.bloquear = true;
                                    ComprobanteVentaFactory.aprobarComprobantesPorId(comprobanteInicial.id, comprobanteFinal.id).then(function (response) {
                                        vm.uiBlockuiConfig.bloquear = false;
                                        if (response.data.listaComprobantesConVencimientoCuotas != null) {
                                            notify({ message: response.data.listaComprobantesConVencimientoCuotas, classes: 'alert-danger', position: 'right' });
                                        }

                                        var filtersArr = [];
                                        filtersArr.push("numero_comprobante_desde=" + comprobanteInicial.numero);
                                        filtersArr.push("numero_comprobante_hasta=" + comprobanteFinal.numero);
                                        filtersArr.push("comprobante_id=" + comprobanteInicial.id);
                                        filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);

                                        modalInstance.close();
                                        $state.go($state.current, {}, { reload: true });
                                        vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                            "comprobantesventa",
                                            "pdf",
                                            filtersArr
                                        );
                                        $window.open(vm.pdfDownloadURL, "_blank");
                                        $state.go($state.current, {}, { reload: true });

                                    },
                                        function (error) {
                                            vm.uiBlockuiConfig.bloquear = false;
                                            console.log(error);
                                            var mensaje = "<span>";
                                            _.forEach(error.data, function (err) {
                                                mensaje = mensaje + err.message + "<br/>";
                                            });
                                            mensaje = mensaje + "</span>";
                                            notify({
                                                messageTemplate: mensaje,
                                                classes: "alert-danger",
                                                position: "right",
                                                templateUrl: ""
                                            });
                                        });
                                }
                            }
                        },
                        {
                            title: $filter("translate")("NEW"),
                            icon: "glyphicon glyphicon-plus",

                            showCondition: function () {

                                if(vm.formSuscripcion && AuthorizationService.hasPermission("create_plantilla") == false) return false;

                                if(vm.formSuscripcion && suscripcionRecurrenteFactory.getSuscripcion().estado.codigo == 'preparado') return true;

                                if(vm.formSuscripcion && suscripcionRecurrenteFactory.getSuscripcion().estado.codigo != 'ingresado') return false;

                                if(vm.formSuscripcion && suscripcionRecurrenteFactory.getSuscripcion().estado.codigo == 'ingresado') return true;



                                if (modo != 1) {
                                    var permission = (vm.fromOutside ||
                                        (ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "new")) && venta.estado.codigo != "venta_anulada");
                                    return permission;
                                } else {
                                    return false;
                                }
                            },
                            action: function () {

                                checkPeriodoAdministrativo(null).then(function (resultado) {
                                    if (resultado) {
                                        if (vm.fromOutside) {
                                            $state.go("app.consultacomprobantesventa.new");
                                        } else {
                                            var contador = 0;
                                            var caja = null;
                                            _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                                if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                                    caja = cajas;
                                                }
                                            });
                                            if (caja != null) {
                                                cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                                    if (cajaDB != null) {
                                                        if (cajaDB.activo != false) {
                                                            _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                                if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                                    contador++;
                                                                }
                                                            });
                                                        }
                                                        if (contador == 1) {
                                                            if($state.is("app.suscripcionrecurrente.proceso.comprobanteventa.list"))
                                                            {
                                                                $state.go("app.suscripcionrecurrente.proceso.comprobanteventa.new");

                                                            }else{
                                                                $state.go("app.ventas.proceso.comprobanteventa.new");
                                                            }
                                                        } else {
                                                            if (cajaDB.activo != false) {
                                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                            } else {
                                                                var msg = $filter('translate')('CAJA_DESACTIVADA');
                                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                            }
                                                        }
                                                    } else {
                                                        var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    }
                                                });
                                            } else {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        }
                                    } else {
                                        var msg = $filter('translate')('NO_OPEN_PERIODS_NOW');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            }
                        },
                        {
                            data: $filter("translate")("ARCHIVO_SALDO"),
                            title: $filter("translate")("EXPORTAR_SALDO_VENTAS"),
                            icon: "glyphicon glyphicon-check",
                            showCondition: function () {

                                if(vm.formSuscripcion) return false;

                                return vm.fromOutside;
                            },
                            action: function (itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.obj = {};
                                // TODO translations
                                $scope.meses = [
                                    { id: 1, nombre: "Enero" },
                                    { id: 2, nombre: "Febrero" },
                                    { id: 3, nombre: "Marzo" },
                                    { id: 4, nombre: "Abril" },
                                    { id: 5, nombre: "Mayo" },
                                    { id: 6, nombre: "Junio" },
                                    { id: 7, nombre: "Julio" },
                                    { id: 8, nombre: "Agosto" },
                                    { id: 9, nombre: "Septiembre" },
                                    { id: 10, nombre: "Octubre" },
                                    { id: 11, nombre: "Noviembre" },
                                    { id: 12, nombre: "Diciembre" }
                                ];
                                $scope.tituloModal = $filter("translate")(
                                    "EXPORTAR_SALDO_VENTAS"
                                );
                                $scope.mensajeModal = "Indicar la fecha para generar el archivo";
                                var modalInstance = $modal.open({
                                    templateUrl: "views/directives/archivo-ventas-saldos-pendientes-modal.html",
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss("cancel");
                                };

                                $scope.ok = function (fecha) {
                                    if (fecha) {
                                        fecha = moment(fecha).format("YYYY/MM/DD");
                                        vm.uiBlockuiConfig.bloquear = true;
                                        $http({
                                            url: baseurl.getBaseUrl() + "/comprobanteventa/saldos-pendientes/csv",
                                            method: "GET",
                                            params: { fecha: fecha }
                                        }).then(
                                            function (response) {
                                                if (response.data.filename != undefined) {
                                                    $window.open(
                                                        baseurl.getPublicBaseUrl() + response.data.filename,
                                                        "_blank"
                                                    );
                                                    vm.uiBlockuiConfig.bloquear = false;
                                                    modalInstance.dismiss("cancel");

                                                } else {
                                                    notify({
                                                        message: "Error al generar el archivo.",
                                                        classes: "alert-danger",
                                                        position: "right"
                                                    });
                                                    vm.uiBlockuiConfig.bloquear = false;
                                                    modalInstance.dismiss("cancel");
                                                }
                                            },
                                            function (response) {

                                                var mensaje = "<span>";
                                                _.forEach(response.data.error, function (err) {
                                                    mensaje = mensaje + "<br/>" + err;
                                                });
                                                mensaje = mensaje + "</span>";
                                                notify({
                                                    messageTemplate: mensaje,
                                                    classes: "alert-danger",
                                                    position: "right",
                                                    templateUrl: ""
                                                });
                                                vm.uiBlockuiConfig.bloquear = false;
                                                modalInstance.dismiss("cancel");

                                            }
                                        );
                                    }
                                };
                            }
                        },
                        {
                            data: $filter("translate")("HECHAUKA_VENTAS"),
                            title: $filter("translate")("EXPORTAR_HECHAUKA_VENTAS"),
                            icon: "glyphicon glyphicon-check",
                            showCondition: function () {

                                if(vm.formSuscripcion) return false;

                                if (vm.fromOutside == true) {
                                    return true;
                                }
                            },
                            action: function (itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.obj = {};
                                $scope.obj.tipoReporte = { id: 1, nombre: "Original" };
                                // TODO translations
                                $scope.meses = [
                                    { id: 1, nombre: "Enero" },
                                    { id: 2, nombre: "Febrero" },
                                    { id: 3, nombre: "Marzo" },
                                    { id: 4, nombre: "Abril" },
                                    { id: 5, nombre: "Mayo" },
                                    { id: 6, nombre: "Junio" },
                                    { id: 7, nombre: "Julio" },
                                    { id: 8, nombre: "Agosto" },
                                    { id: 9, nombre: "Septiembre" },
                                    { id: 10, nombre: "Octubre" },
                                    { id: 11, nombre: "Noviembre" },
                                    { id: 12, nombre: "Diciembre" }
                                ];
                                $scope.tiposReporte = [
                                    { id: 1, nombre: "Original" },
                                    { id: 2, nombre: "Rectificativa" }
                                ];
                                $scope.tituloModal = $filter("translate")(
                                    "EXPORTAR_HECHAUKA_VENTAS"
                                );
                                $scope.mensajeModal = $filter("translate")(
                                    "SELECCION_RANGO_HECHAUKA"
                                );
                                var modalInstance = $modal.open({
                                    templateUrl: "views/directives/hechauka-modal.html",
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss("cancel");
                                };

                                $scope.ok = function (mes, anio, tipoReporte) {
                                    if (mes && anio) {
                                        ComprobanteVentaFactory.downloadCSVArchivoVentas(
                                            mes.id,
                                            anio,
                                            tipoReporte.id,
                                            $filter("translate")("EXPORTAR_HECHAUKA_ERROR")
                                        );
                                        modalInstance.dismiss("cancel");
                                    }
                                };
                            }
                        },
                        /**************************************** */
                        {
                            data: $filter("translate")("ARCHIVO_VENTAS"),
                            title: $filter("translate")("EXPORTAR_ARCHIVO_VENTAS"),
                            icon: "glyphicon glyphicon-check",
                            showCondition: function () {

                                if(vm.formSuscripcion) return false;

                                return vm.fromOutside;
                            },
                            action: function (itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.obj = {};
                                // TODO translations
                                $scope.meses = [
                                    { id: 1, nombre: "Enero" },
                                    { id: 2, nombre: "Febrero" },
                                    { id: 3, nombre: "Marzo" },
                                    { id: 4, nombre: "Abril" },
                                    { id: 5, nombre: "Mayo" },
                                    { id: 6, nombre: "Junio" },
                                    { id: 7, nombre: "Julio" },
                                    { id: 8, nombre: "Agosto" },
                                    { id: 9, nombre: "Septiembre" },
                                    { id: 10, nombre: "Octubre" },
                                    { id: 11, nombre: "Noviembre" },
                                    { id: 12, nombre: "Diciembre" }
                                ];
                                $scope.tituloModal = $filter("translate")(
                                    "EXPORTAR_ARCHIVO_VENTAS"
                                );
                                $scope.mensajeModal = $filter("translate")(
                                    "SELECCION_RANGO_HECHAUKA"
                                );
                                var modalInstance = $modal.open({
                                    templateUrl: "views/directives/archivo-ventas-modal.html",
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss("cancel");
                                };

                                $scope.ok = function (mes, anio) {
                                    if (mes && anio) {
                                        ComprobanteVentaFactory.downloadCSVArchivoVentas(
                                            mes.id,
                                            anio,
                                            $filter("translate")("EXPORTAR_HECHUAKA_ERROR")
                                        );
                                        modalInstance.dismiss("cancel");
                                    }
                                };
                            }
                        },
                        /**************************************** */

                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () {

                                if(vm.formSuscripcion) return false;

                                return true;
                            },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                                CsvFactory.csv("comprobanteventa", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () {

                                if(vm.formSuscripcion) return false;

                                return true;
                            },
                            'action': function () {
                                var filters;
                                if (vm.procesoVenta) {
                                    filters = createFilters(vm.options.getFilters(), vm.options.staticFilter);
                                } else {
                                    filters = createFilters(vm.options.getFilters());
                                }
                                ReportTicketFactory.ticket('listadoConsultaComprobanteVenta', filters, vm.options.tableAjaxParams,
                                    vm.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        },
                        {
                            title: $filter("translate")("REIMPRIMIR_COMPROBANTES"),
                            icon: "glyphicon glyphicon-print",
                            showCondition: function () {

                                if(vm.formSuscripcion){
                                    return false;
                                } else{
                                    return true;
                                }

                            },
                            action: function () {
                                if (AuthorizationService.hasPermission("reimprimir_comprobanteventa") == true) {

                                    $scope.tituloModal = $filter("translate")("CONFIRMAR_COMPROBANTES");
                                    $scope.mensajeModal = translations.DELETE_WARNING;
                                    var modalInstance = $modal.open({
                                        templateUrl: "views/directives/imprimir-comprobantes-modal.html",
                                        scope: $scope,
                                        backdrop: 'static',
                                        bindToController: true
                                    });
                                    if (!venta) {
                                        filterTipoComprobantes();
                                    }else{
                                        filterTipoComprobantes(venta.id);
                                    }


                                    $scope.cancel = function () {
                                        modalInstance.dismiss("cancel");
                                        vm.tipoComprobante = undefined;
                                        vm.timbrado = undefined;
                                        vm.numeroComprobanteInicialImprimir = undefined;
                                        vm.numeroComprobanteFinalImprimir = undefined;
                                        vm.fechaDesde = undefined;
                                        vm.fechaHasta = undefined;
                                        vm.puntoEmision = undefined;
                                    };


                                    $scope.ok = function (comprobanteInicial, comprobanteFinal, tipoComprobante, timbrado, puntoEmision) {
                                        /*  vm.uiBlockuiConfig.bloquear = true;
                                         vm.uiBlockuiConfig.bloquear = false; */

                                        var filtersArr = [];
                                        filtersArr.push("numero_comprobante_desde=" + comprobanteInicial.numero);
                                        filtersArr.push("numero_comprobante_hasta=" + comprobanteFinal.numero);
                                        filtersArr.push("tipoComprobanteId=" + tipoComprobante.id);
                                        filtersArr.push("timbradoId=" + timbrado.id);
                                        filtersArr.push("comprobante_id=" + comprobanteInicial.id);
                                        filtersArr.push("punto_emision_id=" + puntoEmision.id);
                                        filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);


                                        modalInstance.close();
                                        $state.go($state.current, {}, { reload: true });
                                        vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                            "comprobantesventa",
                                            "pdf",
                                            filtersArr
                                        );
                                        $window.open(vm.pdfDownloadURL, "_blank");
                                        $state.go($state.current, {}, { reload: true });

                                    }
                                } else {
                                    var msg = 'No posee permiso para realizar la reimpresión de comprobantes';
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }

                            }
                        }
                    ],
                hideViewMenu: true,
                hideEditMenu: true,
                hideRemoveMenu: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc",
                extraRowOptions: [{
                    templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" +
                        $filter("translate")("EDIT") +
                        "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                    functionName: "editar",
                    customAttribute: ["estado", "venta", "id", "timbradoPuntoEmision", "origen"],
                    functionDef: function (atributos) {
                        var itemId = atributos[2];
                        var timbradoPuntoEmision = atributos[3];
                        ComprobanteVentaFactory.recuperarPorId(itemId).then(function (response) {
                            if (response.data.estado.codigo!="comp_venta_ingresado" && response.data.estado.codigo != "comp_venta_preparado" && response.data.estado.codigo != "comp_venta_agendado") {
                                var msg = $filter("translate")("ESTADO_INCORRECTO_COMP_VENTA", { estado: response.data.estado.descripcion });
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            } else if (vm.fromOutside) {
                                if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo != null) {
                                    if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente"
                                        && response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                        notify({
                                            message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                        });
                                        return;
                                    } else {
                                        if (response.data.autorizadoSolicitud == true && response.data.autorizadoSolicitudBloqueo == true) {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        } else if (response.autorizadoSolicitud == true) {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        }
                                    }
                                } else if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo == null) {
                                    if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                        notify({
                                            message: 'El Comprobante cuenta con Solicitud de Sobregiro con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                        });
                                        return;
                                    } else {
                                        if (response.data.autorizadoSolicitud == true) {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        }
                                    }
                                } else if (response.data.solicitudAutorizacion == null && response.data.solicitudAutorizacionBloqueo != null) {
                                    if (response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                        notify({
                                            message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                        });
                                        return;
                                    } else {
                                        if (response.data.autorizadoSolicitudBloqueo == true) {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        }
                                    }
                                }
                                $state.go("app.consultacomprobantesventa.edit", { id: itemId });

                            } else if(vm.formSuscripcion){
                                $state.go("app.suscripcionrecurrente.proceso.comprobanteventa.edit", {
                                    id: itemId
                                });
                            }else
                            {
                                var contador = 0;
                                var caja = null;
                                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                    if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                        caja = cajas;
                                    }
                                });
                                if (caja != null) {
                                    cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                        if (cajaDB != null) {
                                            _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                    contador++;
                                                }
                                            });
                                            if (contador == 1) {
                                                if (!vm.formSuscripcion && cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                    if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo != null) {
                                                        if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente"
                                                            && response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                            notify({
                                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                            });
                                                            return;
                                                        } else {
                                                            if (response.data.autorizadoSolicitud == true && response.data.autorizadoSolicitudBloqueo == true) {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            } else if (response.autorizadoSolicitud == true) {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            }
                                                        }
                                                    } else if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo == null) {
                                                        if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                            notify({
                                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                            });
                                                            return;
                                                        } else {
                                                            if (response.data.autorizadoSolicitud == true) {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            }
                                                        }
                                                    } else if (response.data.solicitudAutorizacion == null && response.data.solicitudAutorizacionBloqueo != null) {
                                                        if (response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                            notify({
                                                                message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                            });
                                                            return;
                                                        } else {
                                                            if (response.data.autorizadoSolicitudBloqueo == true) {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            }
                                                        }
                                                    }

                                                    $state.go("app.ventas.proceso.comprobanteventa.edit", {
                                                        id: itemId
                                                    });

                                                } else if(!vm.formSuscripcion){
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                }
                                            } else {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    });
                                } else {
                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        });

                    },
                    conditionName: "canEditar",
                    conditionDef: function (atributos) {
                        var estado = atributos[0];
                        var venta = atributos[1];
                        var origen = atributos[4];

                        if((vm.formSuscripcion && estado.codigo != 'comp_venta_preparado')  ){
                            return false;
                        }

                        if(vm.formSuscripcion && AuthorizationService.hasPermission("update_plantilla") == false){
                            return false;
                        }


                        if (vm.fromOutside && venta != undefined) {
                            return false;
                        }/* else if(vm.fromOutside && venta == undefined){
                            return true;
                        } */
                        if (modo != 1) {
                            return (ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "edit", estado, null, null, null, null, venta, origen));
                        } else {
                            return false;
                        }
                    }
                },
                {
                    templateToRender: "<button title='" + $filter("translate")("AGENDAR_SUSCRIPCION_RECURRENTE") +
                        "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='agendarSuscripcion(<%=dataId%>)' ng-class='{  hidden : !canAgendarSuscripcion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-book'></span> </button>",
                    functionName: "agendarSuscripcion",
                    conditionName: "canAgendarSuscripcion",
                    customAttribute: "estado",
                    conditionDef: function (estado) {

                      return (estado.codigo == 'comp_venta_preparado');

                    },
                    functionDef: function(itemId) {
                        ComprobanteVentaFactory.recuperarPorId(itemId).then(function (response) {
                            vm.comprobante = response.data;
                            suscripcionRecurrenteFactory.setIdSuscripcion(response.data.suscripcionRecurrente.id);
                            suscripcionRecurrenteFactory.setSuscripcion(response.data.suscripcionRecurrente);

                            TiposFactory.tiposAgendaSuscripcionEstado().then(function (data) {
                                var defaultEstado = _.filter(data.data, function(val) {
                                    for (var i = 0; i < data.data.length; i++) {
                                      if (val.codigo == 'activo') {
                                        return true;
                                      }
                                    }
                                    return false;
                                  });

                                  vm.agendaSuscripcionRecurrente.estado = defaultEstado[0];

                                  TiposFactory.tiposAgendaSuscripcionAccion().then(function (data) {
                                    var defaultEstado = _.filter(data.data, function(val) {
                                        for (var i = 0; i < data.data.length; i++) {
                                          if (val.codigo == 'agendado') {
                                            return true;
                                          }
                                        }
                                        return false;
                                      });
                                      vm.agendaSuscripcionRecurrente.accion = defaultEstado[0];
                                });

                                TiposFactory.tiposAgendaSuscripcionIntervalo().then(function (data) {
                                    var defaultEstado = _.filter(data.data, function(val) {
                                        for (var i = 0; i < data.data.length; i++) {
                                          if (val.codigo == 'repetir') {
                                            return true;
                                          }
                                        }
                                        return false;
                                      });
                                      vm.agendaSuscripcionRecurrente.intervaloRepeticion = defaultEstado[0];
                                });
                            });

                            vm.generarFechasPlantilla();
                       });
                    }
                  },
                {
                    templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                        $filter("translate")("DELETE") +
                        "' ng-click='borrar(<%=dataCustom%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                    functionName: "borrar",
                    customAttribute: ["estado", "venta", "id", "timbradoPuntoEmision", "origen"],
                    functionDef: function (atributos) {
                        var itemId = atributos[2];
                        var timbradoPuntoEmision = atributos[3];


                        var contador = 0;
                        var caja = null;

                        if (vm.fromOutside || vm.formSuscripcion) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = translations.DELETE_CONFIRMATION;
                            $scope.mensajeModal = translations.DELETE_WARNING;
                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                $scope.modalInstanceBorrar1.dismiss("cancel");
                            };

                            $scope.ok = function (itemId) {
                                //verificar si se puede o no borrar el comprobante
                                ComprobanteVentaFactory.recuperarPorId(itemId).then(function (response) {
                                    if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo != null) {
                                        if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente"
                                            && response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        } else {
                                            if (response.data.autorizadoSolicitud == true && response.data.autorizadoSolicitudBloqueo == true) {
                                                notify({
                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                });
                                                return;
                                            }
                                        }
                                    } else if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo == null) {
                                        if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        } else {
                                            if (response.data.autorizadoSolicitud == true) {
                                                notify({
                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                });
                                                return;
                                            }
                                        }
                                    } else if (response.data.solicitudAutorizacion == null && response.data.solicitudAutorizacionBloqueo != null) {
                                        if (response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        } else {
                                            if (response.data.autorizadoSolicitudBloqueo == true) {
                                                notify({
                                                    message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                });
                                                return;
                                            }
                                        }
                                    }


                                    var model = ComprobanteVentaFactory.create({ id: itemId });
                                    ComprobanteVentaFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = vm.translations.DELETE_FAILED;
                                            $scope.mensajeModal =
                                                vm.translations.DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS;
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                            console.log("error al borrar: ");
                                            console.log(error);
                                        }

                                    );
                                });
                            };
                        } else {
                            _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                    caja = cajas;
                                }
                            });
                            if (caja != null) {
                                cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                    if (cajaDB != null) {
                                        _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                            if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                contador++;
                                            }
                                        });
                                        if (contador == 1) {
                                            if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                $scope.selectedItemId = itemId;
                                                $scope.tituloModal = translations.DELETE_CONFIRMATION;
                                                $scope.mensajeModal = translations.DELETE_WARNING;
                                                $scope.modalInstanceBorrar1 = $modal.open({
                                                    templateUrl: "views/directives/confirmation-modal.html",
                                                    scope: $scope
                                                });

                                                $scope.cancel = function () {
                                                    $scope.modalInstanceBorrar1.dismiss("cancel");
                                                };

                                                $scope.ok = function (itemId) {
                                                    ComprobanteVentaFactory.recuperarPorId(itemId).then(function (response) {
                                                        if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo != null) {
                                                            if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente"
                                                                && response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            } else {
                                                                if (response.data.autorizadoSolicitud == true && response.data.autorizadoSolicitudBloqueo == true) {
                                                                    notify({
                                                                        message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                    });
                                                                    return;
                                                                }
                                                            }
                                                        } else if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo == null) {
                                                            if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            } else {
                                                                if (response.data.autorizadoSolicitud == true) {
                                                                    notify({
                                                                        message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                    });
                                                                    return;
                                                                }
                                                            }
                                                        } else if (response.data.solicitudAutorizacion == null && response.data.solicitudAutorizacionBloqueo != null) {
                                                            if (response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            } else {
                                                                if (response.data.autorizadoSolicitudBloqueo == true) {
                                                                    notify({
                                                                        message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                    });
                                                                    return;
                                                                }
                                                            }
                                                        }


                                                        var model = ComprobanteVentaFactory.create({ id: itemId });
                                                        ComprobanteVentaFactory.remove(model).then(
                                                            function () {
                                                                $scope.modalInstanceBorrar1.close(itemId);
                                                                $state.go($state.current, {}, { reload: true });
                                                            },
                                                            function (error) {
                                                                $scope.modalInstanceBorrar1.dismiss("cancel");
                                                                $scope.tituloModal = vm.translations.DELETE_FAILED;
                                                                $scope.mensajeModal =
                                                                    vm.translations.DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS;
                                                                var modalInstance = $modal.open({
                                                                    template: '<div class="modal-header">' +
                                                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                                        "</div>" +
                                                                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                                        '<div class="modal-footer">' +
                                                                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                                        "</div>",
                                                                    scope: $scope
                                                                });
                                                                $scope.cancel = function () {
                                                                    modalInstance.dismiss("cancel");
                                                                };
                                                                console.log("error al borrar: ");
                                                                console.log(error);
                                                            }

                                                        );
                                                    });
                                                };
                                            } else if(!vm.formSuscripcion){
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    } else {
                                        var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            } else {
                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    conditionName: "canBorrar",
                    conditionDef: function (atributos) {

                        var estado = atributos[0];
                        var venta = atributos[1];
                        var origen = atributos[4];

                        if(vm.formSuscripcion && estado.codigo === 'comp_venta_preparado'
                        && AuthorizationService.hasPermission("eliminarplantilla_suscripcionrecurrente") == true) {
                            return true;
                        }

                        if(vm.formSuscripcion) return false;

                        if (vm.fromOutside && venta != undefined) {
                            return false;
                        }

                        if (modo != 1) {
                            return (ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "delete", estado, null, null, null, null, venta, origen));
                        } else {
                            return false;
                        }

                    }
                },
                {
                    templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                        translations.VIEW +
                        "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                    functionName: "ver",
                    functionDef: function (itemId) {
                        if (vm.fromOutside || vm.comprobanteProcesadoSuscripcion) {
                            $state.go("app.consultacomprobantesventa.view", { id: itemId });
                        } else if (vm.procesoVenta) {
                            $state.go("app.ventas.proceso.comprobanteventa.view", {
                                id: itemId
                            });
                        }else if(vm.formSuscripcion){
                            $state.go("app.suscripcionrecurrente.proceso.comprobanteventa.view", {
                                id: itemId
                            });
                        }
                    },
                    conditionName: "canVer",
                    conditionDef: function () {

                        if(vm.comprobanteProcesadoSuscripcion) return true;

                        if(vm.formSuscripcion && AuthorizationService.hasPermission("verplantilla_suscripcionrecurrente") == false)
                            return false;

                        return ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "view")
                    }
                },
                {
                    templateToRender: "<button title='" +
                        $filter("translate")("CONFIRMAR_COMPROBANTE") +
                        "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataCustom%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
                    functionName: "aprobar",
                    conditionName: "canAprobar",
                    customAttribute: ["estado", "venta", "id", "timbradoPuntoEmision"],
                    conditionDef: function (atributos) {

                        if(vm.formSuscripcion) return false;

                        var estado = atributos[0];
                        var venta = atributos[1];

                        if (modo != 1) {
                            return ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "confirm", estado, null, null, null, null, venta);
                        } else {
                            return false;
                        }
                    },
                    functionDef: function (atributos) {
                        console.log("bloqueo confirmar: ", $scope.bloqueoConfirmar);
                        if (!$scope.bloqueoConfirmar) {
                            $scope.bloqueoConfirmar = true;
                            var itemId = atributos[2];
                            var timbradoPuntoEmision = atributos[3];

                            var contador = 0;
                            var caja = null;
                            //fuera del flujo
                            if (vm.fromOutside) {
                                ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (response) {
                                    $scope.mensajeModal = $filter('translate')('CONFIRMAR_COMPROBANTE_Q');
                                    $scope.tituloModal = translations.CONFIRMAR_COMPROBANTE;

                                    if ((response.tipo.plantillaComprobanteFormatoEspecial != null && response.tipo.plantillaComprobanteFormatoEspecial.length != 0)) {
                                        $scope.config = "FORMATO_ESTANDAR";
                                        var modalInstance = $modal.open({
                                            templateUrl: 'qualita-components/ventas/views/comprobante/confirmation-modal.html',
                                            scope: $scope,
                                            backdrop: "static"
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss('cancel');
                                            $scope.bloqueoConfirmar = false;
                                        };

                                        $scope.ok = function (id, config) {
                                            ComprobanteVentaFactory.verificarExistenciaTimbrado(itemId).then(function (rr) {
                                                if (rr.data === true || rr.data === 'true') {
                                                    controlarVencimiento(response, itemId, modalInstance, config);
                                                }
                                            }, function (error) {
                                                var mensaje = "<span>";
                                                _.forEach(error.data, function (err) {
                                                    mensaje = mensaje + err.message + "<br/>";
                                                });
                                                mensaje = mensaje + "</span>";
                                                notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                            });
                                        };
                                    } else {
                                        var modalInstance = $modal.open({
                                            templateUrl: "views/directives/confirmation-modal.html",
                                            scope: $scope,
                                            backdrop: "static"
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss("cancel");
                                            $scope.bloqueoConfirmar = false;
                                        };

                                        $scope.ok = function (id) {
                                            ComprobanteVentaFactory.verificarExistenciaTimbrado(itemId).then(function (rr) {
                                                if (rr.data === true || rr.data === 'true') {
                                                    controlarVencimiento(response, itemId, modalInstance);
                                                }
                                            }, function (error) {
                                                var mensaje = "<span>";
                                                _.forEach(error.data, function (err) {
                                                    mensaje = mensaje + err.message + "<br/>";
                                                });
                                                mensaje = mensaje + "</span>";
                                                notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                            });
                                        };
                                    }
                                });
                            } else {
                                //dentro del flujo
                                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                    if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                        caja = cajas;
                                    }
                                });
                                if (caja != null) {
                                    cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                        if (cajaDB != null) {
                                            _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                    contador++;
                                                }
                                            });
                                            if (contador == 1) {
                                                if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                    ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (
                                                        response
                                                    ) {
                                                        var esCompGene = false;
                                                        _.forEach(venta.tipo.tipoVentaDetalles, function (det) {
                                                            if (det.componente.descripcion === "Comprobante" && det.realizaEgresoStock == true) {
                                                                esCompGene = true;
                                                            }
                                                        });
                                                        if (esCompGene == true && response.tipoTransaccion != null && !response.tipoTransaccion.codigo === "transaccion_anticipo") {
                                                            $scope.mensajeModal = "¿Está seguro de que desea confirmar el comprobante?. Una vez confirmado, se generaran los movimientos de stock correspondientes.";
                                                        } else {
                                                            $scope.mensajeModal = "¿Está seguro de que desea confirmar el comprobante?.";
                                                        }
                                                        $scope.selectedItemId = itemId;
                                                        $scope.tituloModal = translations.CONFIRMAR_COMPROBANTE;

                                                        if ((response.tipo.plantillaComprobanteFormatoEspecial != null && response.tipo.plantillaComprobanteFormatoEspecial.length != 0)) {
                                                            $scope.config = "FORMATO_ESTANDAR";
                                                            var modalInstance = $modal.open({
                                                                templateUrl: 'qualita-components/ventas/views/comprobante/confirmation-modal.html',
                                                                scope: $scope,
                                                                backdrop: "static"
                                                            });

                                                            $scope.cancel = function () {
                                                                modalInstance.dismiss('cancel');
                                                                $scope.bloqueoConfirmar = false;
                                                            };

                                                            $scope.ok = function (id, config) {
                                                                $('#' + $scope.selectedItemId).attr('disabled', 'disabled');
                                                                ComprobanteVentaFactory.verificarExistenciaTimbrado(itemId).then(function (rr) {
                                                                    if (rr.data === true || rr.data === 'true') {
                                                                        controlarVencimiento(response, itemId, modalInstance, config);
                                                                    }
                                                                }, function (error) {
                                                                    var mensaje = "<span>";
                                                                    _.forEach(error.data, function (err) {
                                                                        mensaje = mensaje + err.message + "<br/>";
                                                                    });
                                                                    mensaje = mensaje + "</span>";
                                                                    notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                                });
                                                            };
                                                        } else {
                                                            var modalInstance = $modal.open({
                                                                templateUrl: "views/directives/confirmation-modal.html",
                                                                scope: $scope,
                                                                backdrop: "static"
                                                            });

                                                            $scope.cancel = function () {
                                                                modalInstance.dismiss("cancel");
                                                                $scope.bloqueoConfirmar = false;
                                                            };

                                                            $scope.ok = function (id) {
                                                                $('#' + $scope.selectedItemId).attr('disabled', 'disabled');
                                                                ComprobanteVentaFactory.verificarExistenciaTimbrado(itemId).then(function (rr) {
                                                                    if (rr.data === true || rr.data === 'true') {
                                                                        controlarVencimiento(response, itemId, modalInstance, null);
                                                                    }
                                                                }, function (error) {
                                                                    var mensaje = "<span>";
                                                                    _.forEach(error.data, function (err) {
                                                                        mensaje = mensaje + err.message + "<br/>";
                                                                    });
                                                                    mensaje = mensaje + "</span>";
                                                                    notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                                });
                                                            };
                                                        }
                                                    });
                                                } else if(!vm.formSuscripcion) {
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    $scope.bloqueoConfirmar = false;
                                                }
                                            } else {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                $scope.bloqueoConfirmar = false;
                                            }
                                        } else {
                                            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            $scope.bloqueoConfirmar = false;
                                        }
                                    });
                                } else {
                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    $scope.bloqueoConfirmar = false;
                                }
                            }
                        }
                    }
                },
                {
                    templateToRender: "<button title='" +
                        $filter("translate")("REIMPRIMIR_COMPROBANTE") +
                        "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reimprimir(<%=dataCustom%>)' ng-class='{ hidden : !canReimprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-repeat'></span> </button>",
                    functionName: "reimprimir",
                    conditionName: "canReimprimir",
                    customAttribute: ["estado", "timbradoPuntoEmision", "id","reimprimir"],
                    conditionDef: function (atributos) {

                        if(vm.formSuscripcion) return false;

                        var estado = atributos[0];

                        if(vm.comprobanteProcesadoSuscripcion) return ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "reprint", estado);

                        var timbradoPuntoEmision = atributos[1];
                        if (timbradoPuntoEmision && timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                            return false;
                        } else {
                            return (
                                ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "reprint", estado)
                            );
                        }
                    },
                    functionDef: function (atributos) {
                        var reimpreso = atributos[3];

                        (reimpreso === 'Sí') ? reimpreso = true:reimpreso = false;

                        if (!reimpreso || AuthorizationService.hasPermission("reimprimir_comprobanteventa") == true) {

                            var timbradoPuntoEmision = atributos[1];
                            var itemId = atributos[2];

                            var contador = 0;
                            var caja = null;
                            if (vm.fromOutside) {
                                ComprobanteVentaFactory.recuperarPorId(itemId).success(function (responsec) {
                                    ComprobanteVentaFactory.get(itemId).$promise.then(function (
                                        response
                                    ) {
                                        if ((responsec.tipo.plantillaComprobanteFormatoEspecial != null && responsec.tipo.plantillaComprobanteFormatoEspecial.length != 0)) {
                                            $scope.mensajeModal = translations.REIMPRIMIR_COMPROBANTE_Q;
                                            $scope.tituloModal = translations.REIMPRIMIR_COMPROBANTE;
                                            $scope.config = "FORMATO_ESTANDAR";
                                            var modalInstance = $modal.open({
                                                templateUrl: 'qualita-components/ventas/views/comprobante/confirmation-modal.html',
                                                scope: $scope
                                            });

                                            $scope.cancel = function () {
                                                modalInstance.dismiss('cancel');
                                            };

                                            $scope.ok = function (id, config) {
                                                var filtersArr = [];
                                                filtersArr.push("idcomprobante=" + itemId);
                                                filtersArr.push("config=" + config);
                                                filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                modalInstance.close();
                                                $state.go($state.current, {}, { reload: true });
                                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                    "comprobanteventa",
                                                    "pdf",
                                                    filtersArr
                                                );
                                                $window.open(vm.pdfDownloadURL, "_blank");
                                                $state.go($state.current, {}, { reload: true });
                                            };
                                        } else {
                                            $scope.mensajeModal = translations.REIMPRIMIR_COMPROBANTE_Q;
                                            $scope.tituloModal = translations.REIMPRIMIR_COMPROBANTE;
                                            var modalInstance = $modal.open({
                                                templateUrl: "views/directives/confirmation-modal.html",
                                                scope: $scope
                                            });

                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };

                                            $scope.ok = function (id) {
                                                var filtersArr = [];
                                                filtersArr.push("idcomprobante=" + itemId);
                                                filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                modalInstance.close();
                                                $state.go($state.current, {}, { reload: true });
                                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                    "comprobanteventa",
                                                    "pdf",
                                                    filtersArr
                                                );
                                                $window.open(vm.pdfDownloadURL, "_blank");
                                                $state.go($state.current, {}, { reload: true });
                                            }
                                        }
                                    });
                                });
                            } else {
                                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                    if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                        caja = cajas;
                                    }
                                });
                                if (caja != null) {
                                    cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                        if (cajaDB != null) {
                                            _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                    contador++;
                                                }
                                            });
                                            if (contador == 1) {
                                                if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                    ComprobanteVentaFactory.recuperarPorId(itemId).success(function (responsec) {
                                                        ComprobanteVentaFactory.get(itemId).$promise.then(function (
                                                            response
                                                        ) {
                                                            if ((responsec.tipo.plantillaComprobanteFormatoEspecial != null && responsec.tipo.plantillaComprobanteFormatoEspecial.length != 0)) {
                                                                $scope.mensajeModal = translations.REIMPRIMIR_COMPROBANTE_Q;
                                                                $scope.tituloModal = translations.REIMPRIMIR_COMPROBANTE;
                                                                $scope.config = "FORMATO_ESTANDAR";
                                                                var modalInstance = $modal.open({
                                                                    templateUrl: 'qualita-components/ventas/views/comprobante/confirmation-modal.html',
                                                                    scope: $scope
                                                                });

                                                                $scope.cancel = function () {
                                                                    modalInstance.dismiss('cancel');
                                                                };

                                                                $scope.ok = function (id, config) {
                                                                    var filtersArr = [];
                                                                    filtersArr.push("idcomprobante=" + itemId);
                                                                    filtersArr.push("config=" + config);
                                                                    filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                                    modalInstance.close();
                                                                    $state.go($state.current, {}, { reload: true });
                                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                                        "comprobanteventa",
                                                                        "pdf",
                                                                        filtersArr
                                                                    );
                                                                    $window.open(vm.pdfDownloadURL, "_blank");
                                                                    $state.go($state.current, {}, { reload: true });
                                                                };
                                                            } else {
                                                                $scope.mensajeModal = translations.REIMPRIMIR_COMPROBANTE_Q;
                                                                $scope.tituloModal = translations.REIMPRIMIR_COMPROBANTE;
                                                                var modalInstance = $modal.open({
                                                                    templateUrl: "views/directives/confirmation-modal.html",
                                                                    scope: $scope
                                                                });

                                                                $scope.cancel = function () {
                                                                    modalInstance.dismiss("cancel");
                                                                };

                                                                $scope.ok = function (id) {
                                                                    var filtersArr = [];
                                                                    filtersArr.push("idcomprobante=" + itemId);
                                                                    filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                                    modalInstance.close();
                                                                    $state.go($state.current, {}, { reload: true });
                                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                                        "comprobanteventa",
                                                                        "pdf",
                                                                        filtersArr
                                                                    );
                                                                    $window.open(vm.pdfDownloadURL, "_blank");
                                                                    $state.go($state.current, {}, { reload: true });
                                                                }
                                                            }
                                                        });
                                                    });
                                                } else if(!vm.formSuscripcion) {
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                }
                                            } else {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    });
                                } else {
                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        } else if(reimpreso && AuthorizationService.hasPermission("reimprimir_comprobanteventa") == false) {
                            var msg = 'El comprobante ya ha sido impreso';
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        }else{
                            var msg = 'No posee permiso para realizar la reimpresión de comprobantes';
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        }
                    }
                },
                {
                    templateToRender: "<button title='" + $filter("translate")("ENVIAR_COMPROBANTE_VIRTUAL") +
                        "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='enviarVirtual(<%=dataId%>, <%=dataCustom%>)' ng-class='{ hidden : !enviarComprobante(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-send'></span> </button>",
                    functionName: "enviarVirtual",
                    conditionName: "enviarComprobante",
                    customAttribute: ["estado", "timbradoPuntoEmision", "tipo", "cdcNuevo"],
                    conditionDef: function (atributos) {

                        if(vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        var estado = atributos[0];
                        var timbradoPuntoEmision = atributos[1];

                        if (timbradoPuntoEmision && timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                            if (estado.codigo === "comp_venta_ingresado") {
                                return false;
                            } else if (estado.codigo === "comp_venta_anulado") {
                                return false;
                            } else if (estado.codigo === "comp_venta_emitido") {
                                if (vm.valorTipo === "si") {
                                    return true;
                                } else {
                                    return false;
                                }
                            } else if (estado.codigo === "comp_venta_enviado_sifen") {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    },
                    functionDef: function (itemId, atributos) {

                        var tipoComprobante = atributos[2];
                        if (tipoComprobante.clase.codigo === "nota_credito") {
                            $scope.mensajeModal = translations.ENVIO_NOTA_CREDITO_VIRTUAL_Q;
                            $scope.tituloModal = translations.ENVIO_NOTA_CREDITO_VIRTUAL;
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss("cancel");
                            };

                            $scope.ok = function (id) {
                                ComprobanteVentaFactory.generarJsonVirtual(itemId).then(function (data) {
                                    if (data === "" || data === null) {
                                        var mensaje = "<span>";
                                        mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                        console.log(mensaje);
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-danger",
                                            position: "right",
                                            templateUrl: ""
                                        });
                                    } else {
                                        console.log("*** La Respuesta *** --> ", data);
                                        var mensaje = "<span>" + $filter("translate")("DATOS_ENVIADOS_SIFEN") + "</span>";
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-success",
                                            position: "right",
                                            templateUrl: ""
                                        });

                                        $state.go($state.current, {}, { reload: true });
                                    }
                                }, function (error) {
                                    console.log(error);
                                    var mensaje = "<span>";
                                    _.forEach(error.data, function (err) {
                                        mensaje = mensaje + err.message + "<br/>";
                                    });
                                    mensaje = mensaje + "</span>";
                                    notify({
                                        messageTemplate: mensaje,
                                        classes: "alert-danger",
                                        position: "right",
                                        templateUrl: ""
                                    });
                                });
                            }
                        } else {
                            var nuevo = atributos[3];
                            if(nuevo != null){
                            $scope.mensajeModal = 'Desea generar un nuevo CDC??';
                            $scope.tituloModal = 'Generación de CDC';
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/confirmation-modal-virtual.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                ComprobanteVentaFactory.generarJsonVirtual(itemId, false).then(function (data) {
                                    if (data === "" || data === null) {
                                        var mensaje = "<span>";
                                        mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                        console.log(mensaje);
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-danger",
                                            position: "right",
                                            templateUrl: ""
                                        });
                                    } else {
                                        console.log("*** La Respuesta *** --> ", data);
                                        var mensaje = "<span>" + $filter("translate")("DATOS_ENVIADOS_SIFEN") + "</span>";
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-success",
                                            position: "right",
                                            templateUrl: ""
                                        });
    
                                        $state.go($state.current, {}, { reload: true });
                                    }
                                }, function (error) {
                                    console.log(error);
                                    var mensaje = "<span>";
                                    _.forEach(error.data, function (err) {
                                        mensaje = mensaje + err.message + "<br/>";
                                    });
                                    mensaje = mensaje + "</span>";
                                    notify({
                                        messageTemplate: mensaje,
                                        classes: "alert-danger",
                                        position: "right",
                                        templateUrl: ""
                                    });
                                });
                            };

                            $scope.ok = function (id) {
                            ComprobanteVentaFactory.generarJsonVirtual(itemId, true).then(function (data) {
                                if (data === "" || data === null) {
                                    console.log("1")
                                    var mensaje = "<span>";
                                    mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                    console.log(mensaje);
                                    notify({
                                        messageTemplate: mensaje,
                                        classes: "alert-danger",
                                        position: "right",
                                        templateUrl: ""
                                    });
                                } else {
                                    console.log("2")

                                    console.log("*** La Respuesta *** --> ", data);
                                    var mensaje = "<span>" + $filter("translate")("DATOS_ENVIADOS_SIFEN") + "</span>";
                                    notify({
                                        messageTemplate: mensaje,
                                        classes: "alert-success",
                                        position: "right",
                                        templateUrl: ""
                                    });

                                    $state.go($state.current, {}, { reload: true });
                                }
                            }, function (error) {
                                console.log("3")

                                console.log(error);
                                var mensaje = "<span>";
                                _.forEach(error.data, function (err) {
                                    mensaje = mensaje + err.message + "<br/>";
                                });
                                mensaje = mensaje + "</span>";
                                notify({
                                    messageTemplate: mensaje,
                                    classes: "alert-danger",
                                    position: "right",
                                    templateUrl: ""
                                });
                            });
                        }
                    }else{
                        ComprobanteVentaFactory.generarJsonVirtual(itemId, true).then(function (data) {
                            if (data === "" || data === null) {
                                var mensaje = "<span>";
                                mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                console.log(mensaje);
                                notify({
                                    messageTemplate: mensaje,
                                    classes: "alert-danger",
                                    position: "right",
                                    templateUrl: ""
                                });
                            } else {

                                console.log("*** La Respuesta *** --> ", data);
                                var mensaje = "<span>" + $filter("translate")("DATOS_ENVIADOS_SIFEN") + "</span>";
                                notify({
                                    messageTemplate: mensaje,
                                    classes: "alert-success",
                                    position: "right",
                                    templateUrl: ""
                                });

                                $state.go($state.current, {}, { reload: true });
                            }
                        }, function (error) {

                            console.log(error);
                            var mensaje = "<span>";
                            _.forEach(error.data, function (err) {
                                mensaje = mensaje + err.message + "<br/>";
                            });
                            mensaje = mensaje + "</span>";
                            notify({
                                messageTemplate: mensaje,
                                classes: "alert-danger",
                                position: "right",
                                templateUrl: ""
                            });
                        });
                    }
                        }
                    }
                },
                {
                    templateToRender: "<button title='" + $filter("translate")("DESCARGAR KuDE") +
                        "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='descargarKuDE(<%=dataCustom%>)' ng-class='{ hidden : !condicionDescarga(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-save-file'></span> </button>",
                    functionName: "descargarKuDE",
                    conditionName: "condicionDescarga",
                    customAttribute: ["estado", "cdc", "impreso", "id"],
                    conditionDef: function (atributos) {

                        if(vm.formSuscripcion) return false;

                        var estado = atributos[0];
                        var impreso = atributos[2];

                        if ((estado.codigo === "comp_venta_aprobado_sifen" || estado.codigo === "comp_venta_enviado_sifen") && impreso === "No") {
                            return true;
                        } else {
                            return false;
                        }

                    },
                    functionDef: function (atributos) {
                        var estado = atributos[0];
                        var cdc = atributos[1];
                        var idItem = atributos[3];
                        var usuario = $rootScope.AuthParams.accesoSistema.usuario.username;
                        ParametrosFactory.getByCodigo('imprimir_kude_sin_aprobar').then(function (response) {
                            if (response.valorTipo.codigo === "si" && estado.codigo === "comp_venta_enviado_sifen") {
                                ComprobanteVentaFactory.get(idItem, "ComprobanteVentaList").$promise.then(function (response) {
                                    ComprobanteVentaFactory.actualizarEstadoImpresion(response.id);
                                    $window.open(CONFIGURATION.serverFE + cdc+ "&usuarioemision="+usuario,"_blank");
                                    $state.go($state.current, {}, { reload: true });
                                })
                            }else if(response.valorTipo.codigo === "no" && estado.codigo === "comp_venta_enviado_sifen"){
                                notify({ message: 'No cuenta con el parámetro de imprimir kude sin aprobar', classes: "alert-danger", position: "right" });
                            }else if(estado.codigo === "comp_venta_aprobado_sifen"){
                                ComprobanteVentaFactory.get(idItem, "ComprobanteVentaList").$promise.then(function (response) {
                                    ComprobanteVentaFactory.actualizarEstadoImpresion(response.id);
                                    $window.open(CONFIGURATION.serverFE + cdc + "&usuarioemision="+usuario,"_blank");
                                    $state.go($state.current, {}, { reload: true });
                                })
                            }
                        });

                    }
                },
                {
                    templateToRender: "<button title='" + $filter("translate")("IMPRIMIR_TICKET") +
                        "' class='btn btn-default btn-dt' style='margin-right: 5px;' ng-click='imprimirTicket(<%=dataCustom%>)' ng-class='{ hidden : !condicionImprimirTicket(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-print'></span> </button>",
                    functionName: "imprimirTicket",
                    conditionName: "condicionImprimirTicket",
                    customAttribute: ["estado", "cdc", "impreso", "id"],
                    conditionDef: function (atributos) {

                        var estado = atributos[0];

                        if (estado.codigo === "comp_venta_aprobado_sifen") {
                            return true;
                        } else {
                            return false;
                        }

                    },
                    functionDef: function (atributos) {
                        var estado = atributos[0];
                        var cdc = atributos[1];
                        var idItem = atributos[3];
                        ParametrosFactory.getByCodigo('habilitar_impresion_tickets').then(function (response) {
                            if (response.valorTipo.codigo === "si") {
                                notify({ message: 'Impresión de Ticket en proceso', classes: "alert-success", position: "right" });
                            }else{
                                notify({ message: 'No cuenta con el parámetro de Imprimir Ticket', classes: "alert-danger", position: "right" });
                            }
                        });

                    }
                },
                {
                    templateToRender: "<button title='" + $filter("translate")("REIMPRIMIR KuDE") +
                        "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reimprimirKuDE(<%=dataCustom%>)' ng-class='{ hidden : !condicionDescargaReimpresion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-repeat'></span> </button>",
                    functionName: "reimprimirKuDE",
                    conditionName: "condicionDescargaReimpresion",
                    customAttribute: ["estado", "cdc", "impreso"],
                    conditionDef: function (atributos) {

                        if(vm.formSuscripcion) return false;

                        var estado = atributos[0];
                        var impreso = atributos[2];

                        if ((estado.codigo === "comp_venta_aprobado_sifen" || estado.codigo === "comp_venta_enviado_sifen") && impreso === "Sí") {
                            return true;
                        } else {
                            return false;
                        }
                    },
                    functionDef: function (atributos) {
                        var estado = atributos[0];
                        var cdc = atributos[1];
                        var usuario = $rootScope.AuthParams.accesoSistema.usuario.username;
                        ParametrosFactory.getByCodigo('imprimir_kude_sin_aprobar').then(function (response) {
                            if (response.valorTipo.codigo === "si" && estado.codigo === "comp_venta_enviado_sifen") {
                                $window.open(CONFIGURATION.serverFE + cdc + "&reimpreso=true"+ "&usuarioemision="+usuario, "_blank");
                                $state.go($state.current, {}, { reload: true });
                            }else if(response.valorTipo.codigo === "no" && estado.codigo === "comp_venta_enviado_sifen"){
                                notify({ message: 'No cuenta con el parámetro de imprimir kude sin aprobar', classes: "alert-danger", position: "right" });
                            }else if(estado.codigo === "comp_venta_aprobado_sifen"){
                                $window.open(CONFIGURATION.serverFE + cdc + "&reimpreso=true"+ "&usuarioemision="+usuario, "_blank");
                                $state.go($state.current, {}, { reload: true });
                            }
                        });
                    }
                },
                {
                    templateToRender: "<button title='" +
                        $filter("translate")("INUTILIZAR_COMPROBANTE") +
                        "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='inutilizar(<%=dataCustom%>)' ng-class='{ hidden : !condicionInutilizar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-floppy-remove'></span> </button>",
                    functionName: "inutilizar",
                    conditionName: "condicionInutilizar",
                    customAttribute: ["estado", "venta", "id", "timbradoPuntoEmision", "hasComprobanteAplicaciones", "hasAplicaciones", "origen"],
                    conditionDef: function (atributos) {

                        if(vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        var estado = atributos[0];
                        var venta = atributos[1];
                        var timbrado = atributos[3];

                        var hasComprobanteAplicaciones = atributos[4];
                        var hasAplicaciones = atributos[5];
                        var origen = atributos[6];
                        if(vm.valorTipo == "si" && estado.codigo === "comp_venta_emitido" && 
                        timbrado.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual" && 
                        ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "inutilizar", estado, null, null, null, null, venta, origen)){
                            return true;
                        }else if(vm.valorTipo == "si" && estado.codigo === "comp_venta_anulado" && origen.codigo == "comp_venta_pos"  && 
                        timbrado.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual" && 
                        ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "inutilizar", estado, null, null, null, null, venta, origen)){
                            return true;
                        }else{
                            return false;
                        }
                        //return hasAplicaciones == "No" && hasComprobanteAplicaciones == "No" &&

                    },
                    functionDef: function (atributos) {

                        var itemId = atributos[2];
                        var timbradoPuntoEmision = atributos[3];

                        var contador = 0;
                        var caja = null;

                        if (vm.fromOutside) {
                            ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (
                                response
                            ) {
                                console.log("AQUI FECHA: ", response.fecha);
                                checkPeriodoAdministrativo(response.fecha).then(function (resultado) {
                                    if (resultado == true) {
                                        $scope.mensajeModal = $filter("translate")("INUTILIZAR_COMPROBANTE_Q");
                                        $scope.tituloModal = $filter("translate")("INUTILIZAR_COMPROBANTE");
                                        var modalInstance = $modal.open({
                                            templateUrl: "views/directives/confirmation-modal.html",
                                            scope: $scope
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss("cancel");
                                        };

                                        $scope.ok = function (id) {
                                            ComprobanteVentaFactory.inutilizar(itemId).then(

                                                function (response) {
                                                    if (venta != undefined) {
                                                        var ordenRemision = 0, ordenComprobante = 0;

                                                        var remisionesEmitidas = _.find(venta.remisiones, function (remision) {
                                                            if (remision.estado.codigo === "estados_remision_emitido" || remision.estado.codigo === "estados_remision_aprobado_sifen") return remision;
                                                        });
                                                        if (remisionesEmitidas) {
                                                            var tipoRemision = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                return tipo.componente.codigo === "comp_tipo_venta_remision";
                                                            })
                                                            ordenRemision = tipoRemision.orden;
                                                        }

                                                        var comprobantesEmitidos = _.find(venta.comprobantes, function (comp) {
                                                            if ((comp.estado.codigo === "comp_venta_emitido" || comp.estado.codigo === "comp_venta_aprobado_sifen" || comp.estado.codigo === "comp_venta_enviado_sifen") && comp.id !== itemId) return comp;
                                                        });

                                                        if (comprobantesEmitidos) {
                                                            var tipoComprobante = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                return tipo.componente.codigo === "comp_tipo_venta_comprobante";
                                                            })
                                                            ordenComprobante = tipoComprobante.orden;
                                                        }

                                                        if (remisionesEmitidas) {
                                                            if (ordenRemision > ordenComprobante) {
                                                                VentaFactory.actualizarEstado('venta_con_remision', venta.id).success(function (data) {
                                                                    modalInstance.close();
                                                                    $state.go($state.current, {}, { reload: true });
                                                                });
                                                            } else {
                                                                VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                    modalInstance.close();
                                                                    $state.go($state.current, {}, { reload: true });
                                                                });
                                                            }
                                                        } else if (comprobantesEmitidos) {
                                                            VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                modalInstance.close();
                                                                $state.go($state.current, {}, { reload: true });
                                                            });
                                                        } else if (venta.pedidoVenta || venta.planillaReparto) {
                                                            VentaFactory.actualizarEstado('venta_con_pv', venta.id).success(function (data) {
                                                                modalInstance.close();
                                                                $state.go($state.current, {}, { reload: true });
                                                            });
                                                        } else {
                                                            VentaFactory.actualizarEstado('venta_abierta', venta.id).success(function (data) {
                                                                modalInstance.close();
                                                                $state.go($state.current, {}, { reload: true });
                                                            });
                                                        }
                                                    }
                                                    modalInstance.close();
                                                    $state.go($state.current, {}, { reload: true });
                                                },
                                                function (error) {
                                                    console.log(error);
                                                    var mensaje = "<span>";
                                                    _.forEach(error.data, function (err) {
                                                        mensaje = mensaje + err.message + "<br/>";
                                                    });
                                                    mensaje = mensaje + "</span>";
                                                    notify({
                                                        messageTemplate: mensaje,
                                                        classes: "alert-danger",
                                                        position: "right",
                                                        templateUrl: ""
                                                    });
                                                }
                                            );
                                        };
                                    } else if (resultado == false) {
                                        var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            });
                        } else {
                            _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                    caja = cajas;
                                }
                            });
                            if (caja != null) {
                                cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                    if (cajaDB != null) {
                                        _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                            if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                contador++;
                                            }
                                        });
                                        if (contador == 1) {
                                            if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (
                                                    response
                                                ) {
                                                    console.log("AQUI FECHA: ", response.fecha);
                                                    checkPeriodoAdministrativo(response.fecha).then(function (resultado) {
                                                        if (resultado == true) {
                                                            $scope.mensajeModal = $filter("translate")("INUTILIZAR_COMPROBANTE_Q");
                                                            $scope.tituloModal = $filter("translate")("INUTILIZAR_COMPROBANTE");
                                                            var modalInstance = $modal.open({
                                                                templateUrl: "views/directives/confirmation-modal.html",
                                                                scope: $scope
                                                            });

                                                            $scope.cancel = function () {
                                                                modalInstance.dismiss("cancel");
                                                            };

                                                            $scope.ok = function (id) {
                                                                ComprobanteVentaFactory.inutilizar(itemId).then(

                                                                    function (response) {
                                                                        if (venta != undefined) {
                                                                            var ordenRemision = 0, ordenComprobante = 0;

                                                                            var remisionesEmitidas = _.find(venta.remisiones, function (remision) {
                                                                                if (remision.estado.codigo === "estados_remision_emitido" || remision.estado.codigo === "estados_remision_aprobado_sifen") return remision;
                                                                            });
                                                                            if (remisionesEmitidas) {
                                                                                var tipoRemision = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                                    return tipo.componente.codigo === "comp_tipo_venta_remision";
                                                                                })
                                                                                ordenRemision = tipoRemision.orden;
                                                                            }

                                                                            var comprobantesEmitidos = _.find(venta.comprobantes, function (comp) {
                                                                                if ((comp.estado.codigo === "comp_venta_emitido" || comp.estado.codigo === "comp_venta_aprobado_sifen" || comp.estado.codigo === "comp_venta_enviado_sifen") && comp.id !== itemId) return comp;
                                                                            });

                                                                            if (comprobantesEmitidos) {
                                                                                var tipoComprobante = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                                    return tipo.componente.codigo === "comp_tipo_venta_comprobante";
                                                                                })
                                                                                ordenComprobante = tipoComprobante.orden;
                                                                            }

                                                                            if (remisionesEmitidas) {
                                                                                if (ordenRemision > ordenComprobante) {
                                                                                    VentaFactory.actualizarEstado('venta_con_remision', venta.id).success(function (data) {
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else {
                                                                                    VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                }
                                                                            } else if (comprobantesEmitidos) {
                                                                                VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            } else if (venta.pedidoVenta || venta.planillaReparto) {
                                                                                VentaFactory.actualizarEstado('venta_con_pv', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            } else {
                                                                                VentaFactory.actualizarEstado('venta_abierta', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            }

                                                                        }
                                                                        modalInstance.close();
                                                                        $state.go($state.current, {}, { reload: true });
                                                                    },
                                                                    function (error) {
                                                                        console.log(error);
                                                                        var mensaje = "<span>";
                                                                        _.forEach(error.data, function (err) {
                                                                            mensaje = mensaje + err.message + "<br/>";
                                                                        });
                                                                        mensaje = mensaje + "</span>";
                                                                        notify({
                                                                            messageTemplate: mensaje,
                                                                            classes: "alert-danger",
                                                                            position: "right",
                                                                            templateUrl: ""
                                                                        });
                                                                    }
                                                                );
                                                            };
                                                        } else if (resultado == false) {
                                                            var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                        }
                                                    });
                                                });
                                            } else if(!vm.formSuscripcion) {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    } else {
                                        var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            } else {
                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    }
                },
                {
                    templateToRender: "<button title='" +
                        $filter("translate")("ANULAR_COMPROBANTE") +
                        "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataCustom%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                    functionName: "anular",
                    conditionName: "canAnular",
                    customAttribute: ["estado", "venta", "canAnular", "hasAplicaciones", "hasComprobanteAplicaciones", "id", "timbradoPuntoEmision", "origen"],
                    conditionDef: function (atributos) {

                        if(vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        if(vm.comprobanteProcesadoSuscripcion) vm.fromOutside = true;

                        var estado = atributos[0];
                        var venta = atributos[1];
                        var canAnular = atributos[2];
                        var hasAplicaciones = atributos[3];
                        var hasComprobanteAplicaciones = atributos[4];
                        if(atributos[6] !=null){
                            var timbrado = atributos[6].timbradoEmpresa.claseTimbrado;
                        }
                        var origen = atributos[7];
                        //console.log(hasAplicaciones);
                        //console.log(hasComprobanteAplicaciones);

                        if (modo != 1) {
                            return ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "anular", estado, null, null, null, null, venta, origen)
                                && canAnular !== "No" && hasAplicaciones == "No" && hasComprobanteAplicaciones == "No" &&
                                ((timbrado.codigo == "timbrado_virtual" && estado.codigo == "comp_venta_aprobado_sifen") ||
                                    (timbrado.codigo != "timbrado_virtual" && estado.codigo == "comp_venta_emitido"));
                        } else {
                            return false;
                        }
                    },
                    functionDef: function (atributos) {

                        var itemId = atributos[5];
                        var timbradoPuntoEmision = atributos[6];

                        var contador = 0;
                        var caja = null;

                        if (vm.fromOutside) {
                            ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (
                                response
                            ) {
                                var numeroComprobante = response.numero;
                                console.log("AQUI FECHA: ", response.fecha);
                                checkPeriodoAdministrativo(response.fecha).then(function (resultado) {
                                    if (resultado == true) {
                                        $scope.mensajeModal = $filter("translate")("ANULAR_COMPROBANTE_Q");
                                        $scope.tituloModal = $filter("translate")("ANULAR_COMPROBANTE");
                                        var modalInstance = $modal.open({
                                            templateUrl: "views/directives/anular-op-modal.html",
                                            scope: $scope
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss("cancel");
                                        };
                                        $scope.ok = function (id, clonar) {

                                            ComprobanteVentaFactory.getEstaAsentado(itemId).then(
                                                function (response) {
                                                    if (response.data == "true") {
                                                        var msg = $filter("translate")("ERROR_ESTA_ASENTADO", { numero: numeroComprobante });
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    } else {

                                                        ComprobanteVentaFactory.anular(itemId, clonar).then(

                                                            function (response) {
                                                                if (venta != undefined) {
                                                                    var ordenRemision = 0, ordenComprobante = 0;

                                                                    var remisionesEmitidas = _.find(venta.remisiones, function (remision) {
                                                                        if (remision.estado.codigo === "estados_remision_emitido" || remision.estado.codigo === "estados_remision_aprobado_sifen") return remision;
                                                                    });
                                                                    if (remisionesEmitidas) {
                                                                        var tipoRemision = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                            return tipo.componente.codigo === "comp_tipo_venta_remision";
                                                                        })
                                                                        ordenRemision = tipoRemision.orden;
                                                                    }

                                                                    var comprobantesEmitidos = _.find(venta.comprobantes, function (comp) {
                                                                        if ((comp.estado.codigo === "comp_venta_emitido" || comp.estado.codigo === "comp_venta_aprobado_sifen" || comp.estado.codigo === "comp_venta_enviado_sifen") && comp.id !== itemId) return comp;
                                                                    });

                                                                    if (comprobantesEmitidos) {
                                                                        var tipoComprobante = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                            return tipo.componente.codigo === "comp_tipo_venta_comprobante";
                                                                        })
                                                                        ordenComprobante = tipoComprobante.orden;
                                                                    }

                                                                    if (remisionesEmitidas) {
                                                                        if (ordenRemision > ordenComprobante) {
                                                                            VentaFactory.actualizarEstado('venta_con_remision', venta.id).success(function (data) {
                                                                                modalInstance.close();
                                                                                $state.go($state.current, {}, { reload: true });
                                                                            });
                                                                        } else {
                                                                            VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                modalInstance.close();
                                                                                $state.go($state.current, {}, { reload: true });
                                                                            });
                                                                        }
                                                                    } else if (comprobantesEmitidos) {
                                                                        VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                            modalInstance.close();
                                                                            $state.go($state.current, {}, { reload: true });
                                                                        });
                                                                    } else if (venta.pedidoVenta || venta.planillaReparto) {
                                                                        VentaFactory.actualizarEstado('venta_con_pv', venta.id).success(function (data) {
                                                                            modalInstance.close();
                                                                            $state.go($state.current, {}, { reload: true });
                                                                        });
                                                                    } else {
                                                                        VentaFactory.actualizarEstado('venta_abierta', venta.id).success(function (data) {
                                                                            modalInstance.close();
                                                                            $state.go($state.current, {}, { reload: true });
                                                                        });
                                                                    }
                                                                }
                                                                modalInstance.close();
                                                                $state.go($state.current, {}, { reload: true });
                                                            },
                                                            function (error) {
                                                                console.log(error);
                                                                var mensaje = "<span>";
                                                                _.forEach(error.data, function (err) {
                                                                    mensaje = mensaje + err.message + "<br/>";
                                                                });
                                                                mensaje = mensaje + "</span>";
                                                                notify({
                                                                    messageTemplate: mensaje,
                                                                    classes: "alert-danger",
                                                                    position: "right",
                                                                    templateUrl: ""
                                                                });
                                                            }
                                                        );
                                                    }
                                                }
                                            );
                                        };
                                    } else if (resultado == false) {
                                        var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            });
                        } else {
                            _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                    caja = cajas;
                                }
                            });
                            if (caja != null) {
                                cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                    if (cajaDB != null) {
                                        _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                            if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                contador++;
                                            }
                                        });
                                        if (contador == 1) {
                                            if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (
                                                    response
                                                ) {
                                                    console.log("AQUI FECHA: ", response.fecha);
                                                    checkPeriodoAdministrativo(response.fecha).then(function (resultado) {
                                                        if (resultado == true) {
                                                            $scope.mensajeModal = $filter("translate")("ANULAR_COMPROBANTE_Q");
                                                            $scope.tituloModal = $filter("translate")("ANULAR_COMPROBANTE");
                                                            var modalInstance = $modal.open({
                                                                templateUrl: "views/directives/anular-op-modal.html",
                                                                scope: $scope
                                                            });

                                                            $scope.cancel = function () {
                                                                modalInstance.dismiss("cancel");
                                                            };

                                                            $scope.ok = function (id, clonar) {
                                                                ComprobanteVentaFactory.anular(itemId, clonar).then(

                                                                    function (response) {
                                                                        if (venta != undefined) {
                                                                            var ordenRemision = 0, ordenComprobante = 0;

                                                                            var remisionesEmitidas = _.find(venta.remisiones, function (remision) {
                                                                                if (remision.estado.codigo === "estados_remision_emitido" || remision.estado.codigo === "estados_remision_aprobado_sifen") return remision;
                                                                            });
                                                                            if (remisionesEmitidas) {
                                                                                var tipoRemision = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                                    return tipo.componente.codigo === "comp_tipo_venta_remision";
                                                                                })
                                                                                ordenRemision = tipoRemision.orden;
                                                                            }

                                                                            var comprobantesEmitidos = _.find(venta.comprobantes, function (comp) {
                                                                                if ((comp.estado.codigo === "comp_venta_emitido" || comp.estado.codigo === "comp_venta_aprobado_sifen" || comp.estado.codigo === "comp_venta_enviado_sifen") && comp.id !== itemId) return comp;
                                                                            });

                                                                            if (comprobantesEmitidos) {
                                                                                var tipoComprobante = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                                    return tipo.componente.codigo === "comp_tipo_venta_comprobante";
                                                                                })
                                                                                ordenComprobante = tipoComprobante.orden;
                                                                            }

                                                                            if (remisionesEmitidas) {
                                                                                if (ordenRemision > ordenComprobante) {
                                                                                    VentaFactory.actualizarEstado('venta_con_remision', venta.id).success(function (data) {
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else {
                                                                                    VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                }
                                                                            } else if (comprobantesEmitidos) {
                                                                                VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            } else if (venta.pedidoVenta || venta.planillaReparto) {
                                                                                VentaFactory.actualizarEstado('venta_con_pv', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            } else {
                                                                                VentaFactory.actualizarEstado('venta_abierta', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            }

                                                                        }
                                                                        modalInstance.close();
                                                                        $state.go($state.current, {}, { reload: true });
                                                                    },
                                                                    function (error) {
                                                                        console.log(error);
                                                                        var mensaje = "<span>";
                                                                        _.forEach(error.data, function (err) {
                                                                            mensaje = mensaje + err.message + "<br/>";
                                                                        });
                                                                        mensaje = mensaje + "</span>";
                                                                        notify({
                                                                            messageTemplate: mensaje,
                                                                            classes: "alert-danger",
                                                                            position: "right",
                                                                            templateUrl: ""
                                                                        });
                                                                    }
                                                                );
                                                            };
                                                        } else if (resultado == false) {
                                                            var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                        }
                                                    });
                                                });
                                            } else if(!vm.formSuscripcion) {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    } else {
                                        var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            } else {
                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    }
                },
                {
                    templateToRender: "<button class='btn btn-default' title='" +
                        $filter("translate")("APLICAR_COMPROBANTE") +
                        "' style='margin-right: 5px;' ng-click='aplicarComprobante(<%=dataId%>)' ng-class='{ hidden : !canAplicarComprobante(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                    customAttribute: ["tipo", "estado"],
                    functionName: "aplicarComprobante",
                    functionDef: function (itemId) {
                        ComprobanteVentaFactory.get(itemId, "AplicacionComprobanteForm").$promise.then(function (c) {

                            var saldo = c.saldo;
                            var montoTotal = c.montoTotal;
                            if (saldo != montoTotal) {
                                $scope.tituloModal = $filter("translate")("APLICACION_YA_EXISTENTE");
                                var templateTmp = '<div class="modal-header">' +
                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                    "</div>" +
                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                    '<div class="modal-footer">' +
                                    '<button class="btn btn-primary" ng-click="editarAplicacion()">' +
                                    $filter("translate")("EDITAR_APLICACION") +
                                    "</button>";
                                if (saldo > 0) {
                                    templateTmp += '<button class="btn btn-secondary" ng-click="agregarAplicacion()">' +
                                        $filter("translate")("AGREGAR_APLICACION") +
                                        "</button>"
                                }
                                templateTmp += '<button class="btn btn-danger" ng-click="desaplicar(' + itemId + ')">' +
                                    $filter("translate")("DESAPLICAR") +
                                    "</button>";
                                templateTmp += '<button class="btn btn-warning" ng-click="cancel()">' +
                                    $filter("translate")("CANCEL") +
                                    "</button>";
                                $scope.mensajeModal = $filter("translate")("QUE_DESEA_Q");
                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: templateTmp,
                                    scope: $scope
                                });
                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss("cancel");
                                };

                                $scope.desaplicar = function (itemId) {
                                    $scope.modalInstanceBorrar1.dismiss();
                                    $scope.mensajeModal = $filter("translate")("DESAPLICAR_Q");
                                    $scope.tituloModal = $filter("translate")("DESAPLICAR");
                                    var modalInstance = $modal.open({
                                        templateUrl: "views/directives/confirmation-modal.html",
                                        scope: $scope
                                    });
                                    $scope.cancel = function () {
                                        modalInstance.dismiss("cancel");
                                    };
                                    $scope.ok = function (id) {
                                        ComprobanteVentaFactory.desaplicar(itemId).then(
                                            function (response) {
                                                modalInstance.close();
                                                $state.go($state.current, {}, { reload: true });
                                            },
                                            function (error) {
                                                // console.log(error);
                                                var mensaje = "<span>";
                                                _.forEach(error.data, function (err) {
                                                    if (err.constraint == "id_producto, numero") {
                                                        mensaje =
                                                            mensaje +
                                                            $filter("translate")(
                                                                "NUMERO_GRUPO_REPETIDO_PRODUCTO"
                                                            ) +
                                                            "<br/>";
                                                    } else {
                                                        mensaje = mensaje + err.message + "<br/>";
                                                    }
                                                });
                                                mensaje = mensaje + "</span>";
                                                notify({
                                                    messageTemplate: mensaje,
                                                    classes: "alert-danger",
                                                    position: "right",
                                                    templateUrl: ""
                                                });
                                            }
                                        );
                                    }
                                }

                                $scope.editarAplicacion = function () {
                                    console.clear();
                                    console.log("AQUI editarAplicacion");
                                    if (vm.fromOutside) {
                                        console.log("AQUI vm.fromOutside: ", vm.fromOutside);
                                        $state.go("app.consultacomprobantesventa.applyedit", {
                                            id: itemId,
                                            mode: "edit"
                                        });
                                        $scope.modalInstanceBorrar1.dismiss();
                                    } else {
                                        $state.go("app.ventas.proceso.comprobanteventa.apply", {
                                            id: itemId,
                                            mode: "edit"
                                        });
                                        $scope.modalInstanceBorrar1.dismiss();
                                    }
                                };
                                $scope.agregarAplicacion = function () {
                                    if (vm.fromOutside) {
                                        $state.go("app.consultacomprobantesventa.applyadd", {
                                            id: itemId,
                                            mode: "add"
                                        });
                                        $scope.modalInstanceBorrar1.dismiss();
                                    } else {
                                        $state.go("app.ventas.proceso.comprobanteventa.apply", {
                                            id: itemId,
                                            mode: "add"
                                        });
                                        $scope.modalInstanceBorrar1.dismiss();
                                    }
                                };
                            } else {
                                if (vm.fromOutside) {
                                    // Con el id del item seleccionado se pasa al formulario de aplicacion
                                    $state.go("app.consultacomprobantesventa.applyadd", {
                                        id: itemId,
                                        mode: "add"
                                    });
                                } else {
                                    // Con el id del item seleccionado se pasa al formulario de aplicacion
                                    $state.go("app.ventas.proceso.comprobanteventa.apply", {
                                        id: itemId,
                                        mode: "add"
                                    });
                                }
                            }
                        });
                    },
                    conditionName: "canAplicarComprobante",
                    conditionDef: function (atributos) {

                        if(vm.formSuscripcion) return false;

                        var tipoComprobante = atributos[0];
                        var estado = atributos[1];

                        if (modo != 1) {
                            return (
                                ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "aplicar", estado, tipoComprobante) && estado.codigo != "comp_venta_aprobado_sifen"
                            );
                        } else {
                            return false;
                        }
                    }
                },
                {
                    templateToRender: "<button class='btn btn-primary' title='" +
                        translations.PLAN_COBROS +
                        "' style='margin-right: 5px;' ng-click='planCobros(<%=dataCustom%>)' ng-class='{ hidden : !canViewPlanCobros(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-tasks'></span> </button>",
                    //customAttribute: "tipo",
                    customAttribute: ["id", "planCobros", "tipo"],
                    functionName: "planCobros",
                    functionDef: function (item) {
                        console.log("item ", item)
                        if (vm.fromOutside) {
                            $state.go("app.plancobros.edit", {
                                id: item[1].id
                            });
                        } else {
                            $state.go("app.plancobros.edit", {
                                //idComprobanteVenta: item[0],
                                //Se recupera desde el customAttribute
                                id: item[1].id
                            });
                        }
                    },
                    conditionName: "canViewPlanCobros",
                    conditionDef: function (item) {

                        if(vm.formSuscripcion) return false;

                        if (modo != 1) {
                            return (
                                ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "viewPlanCobros", null, item[2])
                            );
                        } else {
                            return false;
                        }
                    }
                },
                {
                    templateToRender: "<button class='btn btn-primary' title='" +
                        $filter("translate")("CONSULTA_COBROS") +
                        "' style='margin-right: 5px;' ng-click='consultaCobros(<%=dataId%>)' ng-class='{ hidden : !canViewConsultaCobros(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-transfer'></span> </button>",
                    customAttribute: "hasCobroComprobantes",
                    view: "datatables",
                    functionName: "consultaCobros",
                    functionDef: function (itemId) {
                        var defaultColumnOrder = [
                            "cobro.id",
                            "cobro.numeroCobro",
                            "cuota.numero",
                            "cobro.fecha",
                            "cobro.estado.descripcion",
                            "cobro.cliente.nombre",
                            "importePagar"
                        ];
                        vm.optionsDTModal = {
                            resource: "cobroscomprobantes",
                            title: $filter("translate")("COBEROS"),
                            factory: CobroFactory,
                            view: 'CobroComprobanteList',
                            defaultColumnOrder: defaultColumnOrder,
                            columns: [{
                                data: "cobro.id",
                                title: $filter("translate")("NUMBER"),
                                type: "number-range",
                                visible: false
                            },
                            {
                                data: "cobro.numeroCobro",
                                title: $filter("translate")("NUMBER"),
                                type: "number-range"
                            },
                            {
                                data: "cuota.numero",
                                title: $filter("translate")("CUOTA"),
                                renderWith: "emptyRender"
                            },
                            {
                                data: "cobro.fecha",
                                title: $filter("translate")("DATE"),
                                renderWith: "dateRender",
                                dateFormat: "DD/MM/YYYY",
                                type: "date-range"
                            },
                            {
                                data: "cobro.estado.descripcion",
                                title: $filter("translate")("ESTADO")
                            },
                            {
                                data: "cobro.cliente.nombre",
                                title: $filter("translate")("CLIENTE"),
                                renderWith: "emptyRender"
                            },
                            {
                                data: "importePagar",
                                title: $filter("translate")("IMPORTE"),
                                renderWith: monedaRender,
                                type: "number-range"
                            }
                            ],
                            hasOptions: true,
                            defaultOrderColumn: 2,
                            defaultOrderDir: "desc",
                            hideAddMenu: true,
                            hideEditMenu: true,
                            hideRemoveMenu: true,
                            hideViewMenu: true,
                            hideHeader: true,
                            staticFilter: {
                                search: filterFactory
                                    .and([{
                                        path: "comprobanteVenta.id",
                                        equals: itemId
                                    }])
                                    .value()
                            },
                            extraRowOptions: [{
                                templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' ng-click='visualizar(<%=dataCustom%>)' ng-class='{ hidden : !canVisualizar(<%=dataId%>)}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                                customAttribute: "cobro",
                                conditionName: "canVisualizar",
                                conditionDef: function (itemId) {
                                    var permission = AuthorizationService.hasPermission("index_cobros");
                                    return permission;
                                },
                                functionName: "visualizar",
                                functionDef: function (cobro) {
                                    console.log("Cobro: ", cobro);
                                    $state.go("app.procesoscobro.proceso.cobro.view", { codigoProcesoCobro: cobro.procesoCobro.id, id: cobro.id });
                                }
                            }]
                        };
                        vm.modalInstance = $modal.open({
                            templateUrl: "views/directives/datatables-custom-modal.html",
                            scope: $scope,
                            size: "lg"
                        });
                    },
                    conditionName: "canViewConsultaCobros",
                    conditionDef: function (cobros) {

                        if(vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        //console.log("hasOrdenPagoDetalles: ", opd);
                        return (

                            AuthorizationService.hasPermission("index_comprobanteventa") &&
                            cobros == "Sí"
                        );
                    }
                },
                {
                    templateToRender: "<button class='btn btn-warning' title='" +
                        $filter("translate")("CONSULTA_APLICACIONES") +
                        "' style='margin-right: 5px;' ng-click='consultaAplicaciones(<%=dataCustom%>)' ng-class='{ hidden : !canViewConsultaAplicaciones(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-modal-window'></span> </button>",
                    customAttribute: ["id", "hasAplicaciones", "hasComprobanteAplicaciones"],
                    functionName: "consultaAplicaciones",
                    functionDef: function (attributes) {

                        var itemId = attributes[0];
                        var hasAplicaciones = attributes[1];
                        var hasComprobanteAplicaciones = attributes[2];
                        // Si se trata de una NC que se aplico sobre otros comprobantes
                        if (hasComprobanteAplicaciones === "Sí") {

                            var filters = {
                                search: filterFactory
                                    .single({
                                        path: "comprobanteVenta.id",
                                        equals: itemId
                                    })
                                    .value()
                            };
                            defaultColumnOrder = [
                                "comprobanteVentaAplicado.id",
                                "comprobanteVentaAplicado.numero",
                                "comprobanteVentaAplicado.fecha",
                                "comprobanteVentaAplicado.cliente.nombre",
                                "comprobanteVentaAplicado.tipo.descripcion",
                                "comprobanteVentaAplicado.estado.descripcion",
                                "comprobanteVentaAplicado.timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
                                "comprobanteVentaAplicado.montoTotal",
                                "comprobanteVentaAplicado.saldo",
                                "montoAplicado"
                            ];
                            columns = [{
                                data: "comprobanteVentaAplicado.id",
                                title: $filter("translate")("CODE"),
                                visible: false,
                                type: "number-range"
                            },
                            {
                                data: "comprobanteVentaAplicado.numero",
                                title: $filter("translate")("NUMBER"),
                                class: "dt-center"
                            },
                            {
                                data: "comprobanteVentaAplicado.fecha",
                                title: $filter("translate")("DATE"),
                                renderWith: "dateRender",
                                dateFormat: "DD/MM/YYYY",
                                class: "dt-center",
                                type: "date-range"
                            },
                            {
                                data: "comprobanteVentaAplicado.cliente.nombre",
                                title: $filter("translate")("CLIENTE")
                            },
                            {
                                data: "comprobanteVentaAplicado.tipo.descripcion",
                                title: $filter("translate")("TIPO_COMPROBANTE")
                            },
                            {
                                data: "comprobanteVentaAplicado.estado.descripcion",
                                title: $filter("translate")("STATUS"),
                                renderWith: "emptyRender",
                                class: "dt-center"
                            },
                            {
                                data: "comprobanteVentaAplicado.timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
                                title: $filter("translate")("TIMBRADO_PROVEEDOR"),
                                class: "dt-center",
                                renderWith: "emptyRender"
                            },
                            {
                                data: "comprobanteVentaAplicado.montoTotal",
                                title: $filter("translate")("MONTO"),
                                class: "dt-right",
                                renderWith: monedaRender,
                                type: "number-range"
                            },
                            {
                                data: "comprobanteVentaAplicado.saldo",
                                title: $filter("translate")("SALDO_PENDIENTE"),
                                class: "dt-right",
                                renderWith: monedaRender,
                                type: "number-range"
                            },
                            {
                                data: "montoAplicado",
                                title: $filter("translate")("MONTO_APLICADO"),
                                class: "dt-right",
                                renderWith: monedaRender,
                                type: "number-range"
                            }
                            ]

                            vm.optionsDTModal = {
                                resource: "comprobanteventaaplicacion",
                                title: $filter("translate")("COMPROBANTES"),
                                //factory: ComprobanteVentaFactory,
                                view: 'ComprobanteVentaAplicacionDatatableList',
                                factory: AplicacionComprobanteVentaFactory,
                                defaultColumnOrder: defaultColumnOrder,
                                columns: columns,
                                hasOptions: true,
                                defaultOrderColumn: 1,
                                defaultOrderDir: "asc",
                                hideAddMenu: true,
                                hideEditMenu: true,
                                hideRemoveMenu: true,
                                hideViewMenu: true,
                                hideHeader: true,
                                staticFilter: filters,

                                extraRowOptions: [{
                                    templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                                        $filter("translate")("VIEW") +
                                        "' ng-click='ver(<%=dataCustom%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                                    functionName: "ver",
                                    customAttribute: ["comprobanteVentaAplicado"],
                                    functionDef: function (attributes) {
                                        if (vm.fromOutside) {
                                            $state.go("app.consultacomprobantesventa.view", {
                                                id: attributes[0].id
                                            });
                                        } else if (vm.procesoVenta) {
                                            $state.go("app.ventas.proceso.comprobanteventa.view", {
                                                id: attributes[0].id
                                            });
                                        }
                                    },
                                    conditionName: "canVer",
                                    conditionDef: function () {
                                        return AuthorizationService.hasPermission(
                                            "index_comprobanteventa"
                                        );
                                    }
                                }]
                            };
                            vm.modalInstance = $modal.open({
                                templateUrl: "views/directives/datatables-custom-modal.html",
                                scope: $scope,
                                size: "lg"
                            });
                            //
                        } else
                            defaultColumnOrder = [
                                "comprobanteVenta.id",
                                "comprobanteVenta.numero",
                                "comprobanteVenta.fecha",
                                "comprobanteVenta.cliente.nombre",
                                "comprobanteVenta.tipo.descripcion",
                                // NO TRAE proyecto
                                "comprobanteVenta.proyecto.nombre",
                                "comprobanteVenta.estado.descripcion",
                                "comprobanteVenta.timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
                                "comprobanteVenta.montoTotal",
                                "comprobanteVenta.saldo",
                                "montoAplicado"
                            ];
                        var columns = [{
                            data: "comprobanteVenta.id",
                            title: $filter("translate")("CODE"),
                            visible: false,
                            type: "number-range"
                        },
                        {
                            data: "comprobanteVenta.numero",
                            title: $filter("translate")("NUMBER"),
                            class: "dt-center"
                        },
                        {
                            data: "comprobanteVenta.fecha",
                            title: $filter("translate")("DATE"),
                            renderWith: "dateRender",
                            dateFormat: "DD/MM/YYYY",
                            class: "dt-center",
                            type: "date-range"
                        },
                        {
                            data: "comprobanteVenta.cliente.nombre",
                            title: $filter("translate")("CLIENTE")
                        },
                        {
                            data: "comprobanteVenta.tipo.descripcion",
                            title: $filter("translate")("TIPO_COMPROBANTE")
                        },
                        {
                            data: "comprobanteVenta.estado.descripcion",
                            title: $filter("translate")("STATUS"),
                            renderWith: "emptyRender",
                            class: "dt-center"
                        },
                        {
                            data: "comprobanteVenta.timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
                            title: $filter("translate")("TIMBRADO_PROVEEDOR"),
                            class: "dt-center",
                            renderWith: "emptyRender"
                        },
                        {
                            data: "comprobanteVenta.montoTotal",
                            title: $filter("translate")("MONTO"),
                            class: "dt-right",
                            renderWith: monedaRender,
                            type: "number-range"
                        },
                        {
                            data: "comprobanteVenta.saldo",
                            title: $filter("translate")("SALDO_PENDIENTE"),
                            class: "dt-right",
                            renderWith: monedaRender,
                            type: "number-range"
                        },
                        {
                            data: "montoAplicado",
                            title: $filter("translate")("MONTO_APLICADO"),
                            class: "dt-right",
                            renderWith: monedaRender,
                            type: "number-range"
                        }
                        ]

                        // Si se trata de un comprobante sobre el cual se aplico un NC
                        if (hasAplicaciones === "Sí") {

                            var filters = {
                                search: filterFactory
                                    .single({
                                        path: "comprobanteVentaAplicado.id",
                                        equals: itemId
                                    })
                                    .value()
                            }

                            vm.optionsDTModal = {
                                resource: "comprobanteventaaplicacion",
                                title: $filter("translate")("COMPROBANTES"),
                                factory: AplicacionComprobanteVentaFactory,
                                view: 'ComprobanteVentaAplicacionDatatableList',
                                defaultColumnOrder: defaultColumnOrder,
                                columns: columns,
                                hasOptions: true,
                                defaultOrderColumn: 1,
                                defaultOrderDir: "asc",
                                hideAddMenu: true,
                                hideEditMenu: true,
                                hideRemoveMenu: true,
                                hideViewMenu: true,
                                hideHeader: true,
                                staticFilter: filters,
                                extraRowOptions: [{
                                    templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                                        $filter("translate")("VIEW") +
                                        "' ng-click='ver(<%=dataCustom%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                                    functionName: "ver",
                                    customAttribute: ["comprobanteVenta"],
                                    functionDef: function (attributes) {
                                        if (vm.fromOutside) {
                                            $state.go("app.consultacomprobantesventa.view", {
                                                id: attributes[0].id
                                            });
                                        } else if (vm.procesoVenta) {
                                            $state.go("app.ventas.proceso.comprobanteventa.view", {
                                                id: attributes[0].id
                                            });
                                        }
                                    },
                                    conditionName: "canVer",
                                    conditionDef: function () {
                                        return AuthorizationService.hasPermission(
                                            "index_comprobanteventa"
                                        );
                                    }
                                }]
                            };
                            vm.modalInstance = $modal.open({
                                templateUrl: "views/directives/datatables-custom-modal.html",
                                scope: $scope,
                                size: "lg"
                            });
                        }
                    },
                    conditionName: "canViewConsultaAplicaciones",
                    conditionDef: function (attributes) {

                        if(vm.formSuscripcion) return false;

                        var hasAplicaciones = attributes[1];
                        var hasComprobanteAplicaciones = attributes[2];


                        return (
                            ComprobanteVentaFactory.hasPermisos(vm.fromOutside,
                                "viewConsultaAplicaciones",
                                null,
                                null,
                                null,
                                hasAplicaciones,
                                hasComprobanteAplicaciones
                            )
                        );
                    }
                },
                {
                    templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
                    functionName: 'reenviar',
                    customAttribute: ['estado', 'enviadoAContabilidad', 'id'],
                    functionDef: function (atributo) {

                        var idVenta = atributo[2];

                        ComprobanteVentaFactory.get(idVenta, "ComprobanteVentaList").$promise.then(function (
                            response
                        ) {
                            $scope.tituloModal = $filter('translate')('REENVIAR_VENTA_Q');
                            $scope.mensajeModal = $filter('translate')('REENVIAR_VENTA_MSG');
                            var modalInstance = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function (id) {
                                ComprobanteVentaFactory.reenviar(response.id).then(function (response) {
                                    ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                                        if (parametro.valorTipo.codigo == 'si') {
                                            if (response.enviadoAContabilidad == false) {
                                                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                            }
                                            if (response.listaArchivosLogs != null) {
                                                if (response.listaArchivosLogs.length != 0) {
                                                    /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                        console.log('response.listaArchivosLogs: ');
                                                        console.log(response.listaArchivosLogs[i]);
                                                        $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                    }*/
                                                    notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                                }
                                            }
                                        }
                                    });
                                    $state.go($state.current, {}, { reload: true });
                                }, function (error) {
                                    var msg = "";
                                    if (error.data && error.data.length > 0 && error.data[0].message) {
                                        msg = error.data[0].message;
                                    } else {
                                        msg = $filter('translate')('REENVIAR_VENTA_FAILED');
                                    }

                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                });
                                modalInstance.close();
                            }
                        });
                    },
                    conditionName: 'canReenviar',
                    conditionDef: function (atributo) {
                        var estado = atributo[0];
                        var enviadoAContabilidad = atributo[1];
                        var id = atributo[2];
                        if(vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        if (enviarAContabilidad == 'si') {
                            if (estado.codigo === 'comp_venta_emitido' || estado.codigo === 'comp_venta_anulado' || estado.codigo === 'comp_venta_aprobado_sifen') {
                                if (enviadoAContabilidad === "No") {
                                    return true;
                                }
                            }
                        }
                    }
                },
                {
                    templateToRender: "<button title='" + $filter('translate')('DEFINIR_ESTADO_RENDICION') + "' class='btn btn-warning' style='margin-right: 5px;' ng-click='definirRendicionReparto(<%=dataCustom%>)' ng-class='{ hidden : !canDefinirRendicion(<%=dataId%>)}'> <span class='glyphicon glyphicon-bed'></span> </button>",
                    customAttribute: "id",
                    conditionName: "canDefinirRendicion",
                    conditionDef: function (itemId) {

                        if(vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        var permission = AuthorizationService.hasPermission("asignar_estado_rendicion");
                        //console.log("permiso: ", permission);
                        return permission;
                    },
                    functionName: "definirRendicionReparto",
                    functionDef: function (itemId) {
                        //console.log("itemId: ", itemId);

                        // recuperamos estados
                        $scope.estadosRendicion = TiposTipoFactory.get("estados_rendicion");

                        // creacion del modal
                        var modalInstance = $modal.open({
                            templateUrl: 'views/directives/estado-rendicion-modal.html',
                            scope: $scope
                        });

                        $scope.cancel = function () {
                            modalInstance.dismiss('cancel');
                        };

                        $scope.ok = function (estadoRendicion) {
                            $scope.submited = true;
                            if (estadoRendicion != undefined) {
                                //console.log("estadoRendicion: ", estadoRendicion.codigo);
                                ComprobanteVentaFactory.actualizarEstadoRendicion(itemId, estadoRendicion.codigo).then(function () {
                                    modalInstance.close();
                                    $state.go($state.current, {}, { reload: true });
                                });
                            }
                        }
                    }
                },

                {
                    templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='Modificar Comprobante'" +
                        " ng-click='modificarVendedor(<%=dataId%>)' ng-class='{ hidden : !canModificarVendedor(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                    functionName: "modificarVendedor",
                    functionDef: function (itemId) {
                        localStorage.setItem('habilitarVendedor', AuthorizationService.hasPermission("modificar_vendedor_comp_emitido"));
                        localStorage.setItem('modificarCompElectronico', AuthorizationService.hasPermission("modificar_comprobante_electronico_emitido"));
                        localStorage.setItem('modificarCompElectronicoAprobado', AuthorizationService.hasPermission("modificar_comprobante_electronico_aprobado"));
                        if (vm.fromOutside || vm.comprobanteProcesadoSuscripcion) {
                            $state.go("app.consultacomprobantesventa.view",
                            { id: itemId,
                                habilitarVendedor: AuthorizationService.hasPermission("modificar_vendedor_comp_emitido")
                            });
                        } else if (vm.procesoVenta) {
                            $state.go("app.ventas.proceso.comprobanteventa.view", {
                                id: itemId,
                                habilitarVendedor: AuthorizationService.hasPermission("modificar_vendedor_comp_emitido")
                            });
                        }
                    },
                    conditionName: "canModificarVendedor",
                    customAttribute: ["estado", "timbradoPuntoEmision"],
                    conditionDef: function (atributos) {

                        var estado = atributos[0];
                        var timbradoPuntoEmision = atributos[1];

                        if(vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        //if(AuthorizationService.hasPermission("modificar_vendedor_comp_emitido")){
                          //  return true;
                       // }

                        if (timbradoPuntoEmision && timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                            if (estado.codigo === "comp_venta_emitido") {
                                if(AuthorizationService.hasPermission("modificar_comprobante_electronico_emitido")){
                                    return true;
                                }
                            }else if(estado.codigo === "comp_venta_aprobado_sifen"){
                                if(AuthorizationService.hasPermission("modificar_comprobante_electronico_aprobado")){
                                    return true;
                                }
                            }
                        }else if(timbradoPuntoEmision){
                            if (estado.codigo === "comp_venta_emitido") {
                                if(AuthorizationService.hasPermission("modificar_vendedor_comp_emitido")){
                                    return true;
                                }
                            }
                        }

                        return false;
                    }
                },
                {
                    templateToRender: "<button title='" + "Reutilizar Numero Comprobante" + "' class='btn btn-primary btn-dt' style='margin-right: 5px;' ng-click='reutilizar(<%=dataCustom%>)' ng-class='{hidden : !canReutilizar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-duplicate'></span> </button>",
                    functionName: 'reutilizar',
                    customAttribute: ['estado', 'enviadoAContabilidad', 'id', 'usuarioReutilizacion'],
                    functionDef: function (atributo) {

                        var idVenta = atributo[2];

                        ComprobanteVentaFactory.get(idVenta, "ComprobanteVentaList").$promise.then(function (
                            response
                        ) {
                            $scope.tituloModal = "Reutilizar Numero de Comprobante";
                            $scope.mensajeModal = "Estás seguro que quieres realizar el proceso de reutilización de número de comprobante";
                            var modalInstance = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function (id) {

                                ComprobanteVentaFactory.reUtilizar(response.id, $rootScope.AuthParams.accesoSistema.usuario.id).then(function(respuesta){

                                    if(respuesta.status == 200){
                                        var msg = "Se realizó correctamente el proceso de reutilización.";
                                        notify({ message: msg, classes: 'alert-success', position: 'right' });
                                       $state.go($state.current, {}, { reload: true });
                                    } else {
                                        var msg = "";
                                        if (respuesta.data && respuesta.data.length > 0 && respuesta.data[0].message) {
                                            msg = respuesta.data[0].message;
                                        } else {
                                            msg = "Error en el proceso de reutilización";
                                        }
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                 }, function (error) {
                                    var mensaje = "<span>";
                                    _.forEach(error.data, function (err) {
                                        mensaje = mensaje + err.message + "<br/>";
                                    });
                                    mensaje = mensaje + "</span>";
                                    notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                    modalInstance.close();
                                });
                            }
                        });
                    },
                    conditionName: 'canReutilizar',
                    conditionDef: function (atributo) {

                        if(vm.realizarTareasEspeciales == "si" && AuthorizationService.hasPermission("update_reutilizar_numeracion") == true && atributo[0].codigo == "comp_venta_anulado" && atributo[3] == null){
                            return true;
                        }else{
                            return false;
                        }
                    }
                },
                {
                  templateToRender: "<button title='Generar Pagare' class='btn' style='margin-right: 5px; background-color: #7e3794; color: #fff' ng-click='generarPagare(<%=dataCustom%>)' ng-class='{ hidden : !condicionGenerarPagare(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-list-alt'></span> </button>",
                  functionName: "generarPagare",
                  conditionName: "condicionGenerarPagare",
                  customAttribute: ["estado", "tipo", "id"],
                  conditionDef: function (atributos) {
                    var estado = atributos[0];
                    var tipo = atributos[1];
                    var estadosPermitidos = ["comp_venta_emitido", "comp_venta_enviado_sifen", "comp_venta_aprobado_sifen"]
                    if (AuthorizationService.hasPermission("generar_pagare_fc") == true){
                      if(estadosPermitidos.includes(estado.codigo) && tipo.generarPagare){
                        return true;
                      }
                    }
                  },
                  functionDef: function (atributos) {
                    var itemId = atributos[2];
                    var filtersArr = [];
                    filtersArr.push("idcomprobante=" + itemId);
                    $state.go($state.current, {}, { reload: true });
                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                      "generarpagare",
                      "pdf",
                      filtersArr
                    );
                    $window.open(vm.pdfDownloadURL, "_blank");
                    $state.go($state.current, {}, { reload: true });
                  }
                },
                {
                    templateToRender: "<button title='" + 'Descargar Json' +
                        "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='descargarJson(<%=dataCustom%>)' ng-class='{ hidden : !condicionDescargaJson(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-download-alt'></span> </button>",
                    functionName: "descargarJson",
                    conditionName: "condicionDescargaJson",
                    customAttribute: ["estado", "timbradoPuntoEmision", "id"],
                    conditionDef: function (atributos) {
                        var timbradoPuntoEmision = atributos[1];

                        if(AuthorizationService.hasPermission("descargar_json") &&
                         timbradoPuntoEmision &&
                          timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual"){
                            return true;
                        }
                        if(vm.formSuscripcion) return false;
                    },
                    functionDef: function (atributos) {
                        var idItem = atributos[2];
                        ComprobanteVentaFactory.generarJsonVirtualDescarga(idItem).then(function (data) {
                            
                                var jsonData = JSON.stringify(data.data, null, 4);
                                var blob = new Blob([jsonData], { type: 'application/json' });
                                var url = window.URL.createObjectURL(blob);

                                var a = document.createElement('a');
                                a.href = url;
                                a.download = 'archivo.json';
                                a.click();

                                window.URL.revokeObjectURL(url);
                            
                        }, function (error) {
                            console.log(error);
                            var mensaje = "<span>";
                            _.forEach(error.data, function (err) {
                                mensaje = mensaje + err.message + "<br/>";
                            });
                            mensaje = mensaje + "</span>";
                            notify({
                                messageTemplate: mensaje,
                                classes: "alert-danger",
                                position: "right",
                                templateUrl: ""
                            });
                        });   
                    }
                },
                {
                    templateToRender: "<button title='" +
                        "Envío de Nominación" +
                        "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='nominacion(<%=dataCustom%>)' ng-class='{ hidden : !condicionNominacion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-send'></span> </button>",
                    functionName: "nominacion",
                    conditionName: "condicionNominacion",
                    customAttribute: ["inominado", "cliente", "id", "estado"],
                    conditionDef: function (atributos) {

                        if(vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        var inominado = atributos[0];
                        var cliente = atributos[1];
                        var estado = atributos[3];

                        //si es inominado pero ya se nomino
                        if(vm.fromOutside && inominado == "Sí" && cliente.tipoDocumento.codigo!="tipo_documento_cliente_innominado" && estado.codigo === "comp_venta_aprobado_sifen"){
                            return true;
                        }else{
                            return false;
                        }

                    },
                    functionDef: function (atributos) {

                        var itemId = atributos[2];

                        if (vm.fromOutside) {
                            $scope.mensajeModal = "¿Está seguro de que desea realizar el Envío de Nominación?";
                            $scope.tituloModal = "Envío de Nominación";
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss("cancel");
                            };

                            $scope.ok = function (id) {
                                ComprobanteVentaFactory.nominacion(itemId).then(function (data) {
                                    console.log("data ",data)
                                    if (data === "" || data === null) {
                                        var mensaje = "<span>";
                                        mensaje += "Error al Enviar Nominación" + "</span>";
                                        console.log(mensaje);
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-danger",
                                            position: "right",
                                            templateUrl: ""
                                        });
                                    } else {
                                        console.log("*** La Respuesta *** --> ", data);
                                        var mensaje = "<span>" + "Nominación enviada Correctamente" + "</span>";
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-success",
                                            position: "right",
                                            templateUrl: ""
                                        });

                                        $state.go($state.current, {}, { reload: true });
                                    }
                                }, function (error) {
                                    console.log(error);
                                    var mensaje = "<span>";
                                    _.forEach(error.data, function (err) {
                                        mensaje = mensaje + err.message + "<br/>";
                                    });
                                    mensaje = mensaje + "</span>";
                                    notify({
                                        messageTemplate: mensaje,
                                        classes: "alert-danger",
                                        position: "right",
                                        templateUrl: ""
                                    });
                                }); 
                            };
                        }
                    }
                }
                ]
            };
            WatchLocacionChangeFactory.watchWith(
                staticFilter,
                vm.options,
                "locacion.id"
            );
        });
        /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
        var checkPeriodoAdministrativo = function (fechaComprobante) {
            return $q(function (resolve, reject) {

                var params = {};
                var fecha;

                if (fechaComprobante == null) {
                    fecha = new Date();
                } else {
                    fecha = fechaComprobante;
                }

                params.search = filterFactory
                    .and([{
                        path: "estado.codigo",
                        equals: "per_adm_abierto"
                    },
                    {
                        path: "fechaDesde",
                        equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                    },
                    {
                        path: "fechaHasta",
                        equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                    }
                    ])
                    .value();

                PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {
                    if (responsePeriodo.length > 0) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            });
        }

        var getIDs = function () {
            var ids = [];
            _.forEach(vm.options.selection, function (n, key) {
                if (n)
                    ids.push(key);
            });
            return ids;
        };


        var createFilters = function (filters, staticFilters) {
            var filtersArr = [];
            var filtersC = _.cloneDeep(filters);
            var staticFiltersC = _.cloneDeep(staticFilters);
            _.each(filters, function (search, data) {
                search = search.split("~");
                if (search.length > 1) {
                    /* El parámetro es un rago */

                    var isDate = search[0].split("/");

                    if (isDate.length > 1) {
                        /* Es un rago de fechas */

                        filtersArr.push({ path: data, equalOrAfter: search[0] })
                        filtersArr.push({ path: data, equalOrBefore: search[1] })
                    } else {
                        /* El un rago numérco */

                        filtersArr.push({ path: data, greaterOrEqual: search[0] })
                        filtersArr.push({ path: data, lessOrEqual: search[1] })
                    }
                } else {
                    /* El parametro no es un rago */
                    filtersArr.push({ path: data, like: search[0] })
                }
            });

            if (staticFiltersC) {
                // entrar al OR y por cada AND agregar todos los filtros de datatable
                var entro = false;
                var staticFiltersAnds = staticFiltersC.search.filters;
                _.each(staticFiltersAnds, function (staticFilter) {
                    if (staticFilter.filters) {
                        _.each(filtersArr, function (dtFilter) {
                            staticFilter.filters.push(dtFilter);
                        });
                    } else {
                        if (staticFilter.equals != undefined) {
                            filtersArr.push({ path: staticFilter.path, equals: staticFilter.equals });
                        } else if (staticFilter.like != undefined) {
                            filtersArr.push({ path: staticFilter.path, like: staticFilter.like });
                        } else if (staticFilter.equalOrBefore != undefined) {
                            filtersArr.push({ path: staticFilter.path, equalOrBefore: staticFilter.equalOrBefore });
                        } else if (staticFilter.equalOrAfter != undefined) {
                            filtersArr.push({ path: staticFilter.path, equalOrAfter: staticFilter.equalOrAfter });
                        } else if (staticFilter.greaterThan != undefined) {
                            filtersArr.push({ path: staticFilter.path, greaterThan: staticFilter.greaterThan });
                        } else if (staticFilter.lessThan != undefined) {
                            filtersArr.push({ path: staticFilter.path, lessThan: staticFilter.lessThan });
                        } else if (staticFilter.sortDesc != undefined) {
                            filtersArr.push({ path: staticFilter.path, sortDesc: staticFilter.sortDesc });
                        } else if (staticFilter.sortAsc != undefined) {
                            filtersArr.push({ path: staticFilter.path, sortAsc: staticFilter.sortAsc });
                        } else if (staticFilter.isNull != undefined) {
                            filtersArr.push({ path: staticFilter.path, isNull: staticFilter.isNull });
                        } else if (staticFilter.isNotNull != undefined) {
                            filtersArr.push({ path: staticFilter.path, isNotNull: staticFilter.isNotNull });
                        } else if (staticFilter.notEquals != undefined) {
                            filtersArr.push({ path: staticFilter.path, notEquals: staticFilter.notEquals });
                        } else if (staticFilter.greaterOrEqual != undefined) {
                            filtersArr.push({ path: staticFilter.path, greaterOrEqual: staticFilter.greaterOrEqual });
                        } else if (staticFilter.lessOrEqual != undefined) {
                            filtersArr.push({ path: staticFilter.path, lessOrEqual: staticFilter.lessOrEqual });
                        }
                        entro = true;
                    }
                });
                if (entro == true) {
                    filtersC = filterFactory.and(filtersArr).value();
                } else {
                    filtersC = filterFactory.or(staticFiltersAnds).value();
                }
            } else {
                filtersC = filterFactory.and(filtersArr).value();
            }
            return filtersC;
        }

        //controlar el vencimiento de cuotas del cliente
        var controlarVencimiento = function (response, itemId, modalInstance, formato) {
            ParametrosFactory.getByCodigo('bloquear_ventas_por_venc_cuentas_cobrar').then(function (res) {
                if ((res.valorTipo.codigo === "bloquear_rem_fac" && response.tipo.clase.codigo != 'nota_credito') || (res.valorTipo.codigo === "bloquear_fac" && response.tipo.clase.codigo != 'nota_credito')) {
                    ComprobanteVentaFactory.verificarVencimiento(itemId).then(function (rr) {
                        //si el cliente tiene cuotas vencidas y sobrepasa la tolerancia continuar, si no, verificar sobregiro
                        if (rr.data === true || rr.data === 'true') {
                            //si no se tiene la solicitud de bloqueo y no tiene decision cotinuar si no, verificar los casos
                            if (response.solicitudAutorizacionBloqueo == null && response.autorizadoSolicitudBloqueo == null) {
                                $scope.mensajeModal = 'El cliente cuenta con cuotas vencidas.';
                                $scope.tituloModal = 'Información';
                                var modalInstance2 = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.bloqueoConfirmar = false;

                                $scope.cancel = function () {
                                    modalInstance2.dismiss('cancel');
                                    modalInstance.dismiss('cancel');
                                };

                                $scope.ok = function () {
                                    $scope.mensajeModal = 'Desea realizar una Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias?';
                                    $scope.tituloModal = 'Confirmación';
                                    var modalInstance3 = $modal.open({
                                        templateUrl: 'views/directives/confirmation-modal.html',
                                        scope: $scope
                                    });
                                    $scope.cancel = function () {
                                        modalInstance3.dismiss('cancel');
                                        modalInstance2.dismiss('cancel');
                                        modalInstance.dismiss('cancel');
                                    };

                                    $scope.ok = function () {
                                        //controlar que el usuario pueda crear solicitud de autorizacion
                                        if (AuthorizationService.hasPermission("create_solicitudautorizacion") === true) {
                                            $scope.mensajeModal = 'Motivo de la Solicitud: ';
                                            $scope.tituloModal = 'Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias';
                                            //obtener el tipo Ignorar Vencimiento y Tolerancia
                                            $scope.motivos = TiposTipoFactory.get("proceso_autorizar");
                                            $scope.motivos.$promise.then(function (estados) {
                                                var estadoActivo = _.find($scope.motivos, function (estado) {
                                                    return estado.codigo === "ignorar_venc_cuota_tolerancia";
                                                });
                                                if (estadoActivo) {
                                                    $scope.config = estadoActivo;
                                                }
                                                var modalInstance4 = $modal.open({
                                                    templateUrl: 'qualita-components/ventas/views/pedidoventa/autorizacion-modal.html',
                                                    scope: $scope
                                                });

                                                $scope.cancel = function () {
                                                    modalInstance4.dismiss('cancel');
                                                    modalInstance3.dismiss('cancel');
                                                    modalInstance2.dismiss('cancel');
                                                    modalInstance.dismiss('cancel');
                                                };

                                                $scope.ok = function (id, config) {
                                                    $('#' + config.id).attr('disabled', 'disabled');
                                                    ComprobanteVentaFactory.solicitarAutorizacionBloqueo(itemId,config.id).then(function () {
                                                        ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (res) {
                                                            console.log("ress mmm ", res);
                                                            res.solicitudAutorizacionBloqueo.motivoSolicitud = config;
                                                            SolicitudAutorizacionFactory.save(res.solicitudAutorizacionBloqueo);

                                                            var filtersArr = [];
                                                            filtersArr.push("idcomprobanteventa=" + itemId);
                                                            modalInstance4.close();
                                                            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                                "solicitudautorizacioncomprobantebloqueo",
                                                                "pdf",
                                                                filtersArr
                                                            );
                                                            $window.open(vm.pdfDownloadURL, "_blank");
                                                            $timeout(function () {
                                                                $('#' + config.id).removeAttr("disabled");
                                                            }, 1000)
                                                            //luego de imprimir el reporte de bloqueo, continuar con el sobregiro
                                                            controlSobregiro(res, itemId, modalInstance);
                                                        });
                                                    }, function (error) {
                                                        var mensaje = "<span>";
                                                        _.forEach(error.data, function (err) {
                                                            mensaje = mensaje + err.message + "<br/>";
                                                        });
                                                        mensaje = mensaje + "</span>";
                                                        notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                    });
                                                }
                                                return estados;
                                            });

                                        } else {
                                            $scope.tituloModal = 'Error';
                                            $scope.mensajeModal = 'Usted no posee permiso para realizar la operación';
                                            var modalInstance5 = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance5.dismiss("cancel");
                                                modalInstance3.dismiss('cancel');
                                                modalInstance2.dismiss('cancel');
                                                modalInstance.dismiss('cancel');
                                            };
                                        }
                                    }
                                }
                            } else {
                                verificarBloqueo(response, itemId, modalInstance, formato);
                            }
                        } else {
                            //control de sobregiro si el cliente no tiene cuota vencida
                            controlSobregiro(response, itemId, modalInstance, formato);
                        }
                    });
                } else {
                    //control de sobregiro si el parametro no se cumple
                    controlSobregiro(response, itemId, modalInstance, formato);
                }
            });
        }

        //verificar los casos del bloqueo
        var verificarBloqueo = function (response, itemId, modalInstance, formato) {
            //controlar el sobregiro si no se controlo antes
            ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                if (response1.valorTipo.codigo === "si") {
                    if (response.tipo.controlLimiteCredito == true) {
                        if (response.cliente.controlarLimiteCredito == true) {
                            if (response.solicitudAutorizacion == null && response.solicitarAutorizacion == null) {
                                controlSobregiro(response, itemId, modalInstance, formato);
                                modalInstance6.dismiss('cancel');
                            }
                        }
                    }
                }
            });

            if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == null) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias sobre este comprobante de venta';
                var modalInstance6 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance6.dismiss('cancel');
                    modalInstance.dismiss('cancel');
                };
            } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == false) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                var modalInstance7 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance7.dismiss('cancel');

                };
            } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == true) {
                //verificar que no exista solicitud de sobregiro
                if (response.solicitudAutorizacion == null) {
                    aprobarComprobante(itemId, modalInstance, formato);
                } else {
                    verificarSobregiro2(response, itemId, modalInstance, formato);
                }
            }
        }

        var verificarSobregiro = function (response, itemId, modalInstance, formato) {
            if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == null) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Sobregiro de Límite de Crédito sobre este comprobante de venta';
                var modalInstance6 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance6.dismiss('cancel');
                    modalInstance.dismiss('cancel');
                };
            } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == false) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                var modalInstance7 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance7.dismiss('cancel');

                };
            } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == true) {
                //verificar que no exista solicitud de bloqueo
                if (response.solicitudAutorizacionBloqueo == null) {
                    aprobarComprobante(itemId, modalInstance, formato);
                } else {
                    verificarBloqueo(response, itemId, modalInstance, formato);
                }
            }
        }

        var verificarSobregiro2 = function (response, itemId, modalInstance, formato) {
            if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == null) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Sobregiro de Limite de Credito sobre este comprobante de venta';
                var modalInstance6 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance6.dismiss('cancel');
                    modalInstance.dismiss('cancel');
                };
            } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == false) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                var modalInstance7 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance7.dismiss('cancel');

                };
            } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == true) {
                aprobarComprobante(itemId, modalInstance, formato);
            }
        }

        var aprobarComprobante = function (itemId, modalInstance, formato) {
            //aprobar del proceso normal en el flujo de ventas
            ComprobanteVentaFactory.aprobarPorId(itemId).then(function (response) {
                setTimeout(function () {
                    ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                        if (parametro.valorTipo.codigo == 'si') {
                            if (response.enviadoAContabilidad == false) {
                                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                            }
                        }
                    });
                }, 600);
                if (response.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                    $state.go($state.current, {}, { reload: true });
                } else {
                    var filtersArr = [];
                    filtersArr.push("idcomprobante=" + itemId);
                    filtersArr.push("config=" + formato);
                    filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                    modalInstance.close();
                    $state.go($state.current, {}, { reload: true });
                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                        "comprobanteventa",
                        "pdf",
                        filtersArr
                    );
                    $window.open(vm.pdfDownloadURL, "_blank");
                    $state.go($state.current, {}, { reload: true });
                }
            },
                function (error) {
                    console.log(error);
                    var mensaje = "<span>";
                    _.forEach(error.data, function (err) {
                        mensaje = mensaje + err.message + "<br/>";
                    });
                    mensaje = mensaje + "</span>";
                    notify({
                        messageTemplate: mensaje,
                        classes: "alert-danger",
                        position: "right",
                        templateUrl: ""
                    });
                });
            //hasta aca aprobar normal
        }

        var verificarBloqueo2 = function (response, itemId, modalInstance, formato) {
            //si tiene pv y tiene solicitud de autorizacion de sobregiro
            if (response.pedidoVenta != null && response.pedidoVenta.solicitudAutorizacion != null) {
                //si la solicitud de sobregiro en el pv No fue autorizado
                if (response.pedidoVenta.autorizadoSolicitud == false) {
                    //si no control de sobregiro
                    ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                        if (response1.valorTipo.codigo === "si") {
                            if (response.tipo.controlLimiteCredito == true) {
                                if (response.cliente.controlarLimiteCredito == true) {
                                    if (response.solicitudAutorizacion == null && response.solicitarAutorizacion == null && modalInstance6 != undefined) {
                                        modalInstance6.dismiss('cancel');
                                    }
                                } else if(modalInstance6 != undefined) {
                                    modalInstance6.dismiss('cancel');
                                }
                            } else if(modalInstance6 != undefined) {
                                modalInstance6.dismiss('cancel');
                            }
                        } else if(modalInstance6 != undefined) {
                            modalInstance6.dismiss('cancel');
                        }
                    });
                } else {
                    if (response.montoTotal > response.pedidoVenta.montoTotal) {
                        //si no control de sobregiro
                        ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                            if (response1.valorTipo.codigo === "si") {
                                if (response.tipo.controlLimiteCredito == true) {
                                    if (response.cliente.controlarLimiteCredito == true) {
                                        if (response.solicitudAutorizacion == null && response.solicitarAutorizacion == null && modalInstance6 != undefined) {
                                            modalInstance6.dismiss('cancel');
                                        }
                                    } else if(modalInstance6 != undefined) {
                                        modalInstance6.dismiss('cancel');
                                    }
                                } else if(modalInstance6 != undefined) {
                                    modalInstance6.dismiss('cancel');
                                }
                            } else if(modalInstance6 != undefined) {
                                modalInstance6.dismiss('cancel');
                            }
                        });
                    } else if(modalInstance6 != undefined){
                          modalInstance6.dismiss('cancel');
                    }
                }
            } else {
                //si no control de sobregiro
                ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                    if (response1.valorTipo.codigo === "si") {
                        if (response.tipo.controlLimiteCredito == true) {
                            if (response.cliente.controlarLimiteCredito == true) {
                                if (response.solicitudAutorizacion == null && response.solicitarAutorizacion == null && modalInstance6 != undefined) {
                                    modalInstance6.dismiss('cancel');
                                }
                            } else if(modalInstance6 != undefined) {
                                modalInstance6.dismiss('cancel');
                            }
                        } else if(modalInstance6 != undefined) {
                            modalInstance6.dismiss('cancel');
                        }
                    } else if(modalInstance6 != undefined) {
                        modalInstance6.dismiss('cancel');
                    }
                });
            }

            if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == null) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias sobre este comprobante de venta';
                var modalInstance6 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance6.dismiss('cancel');
                    modalInstance.dismiss('cancel');
                };
            } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == false) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                var modalInstance7 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance7.dismiss('cancel');

                };
            } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == true) {
                //este caso es cuando no se cumple el parametro de sobregiro y la solicitud de bloqueo se autorizo
                aprobarComprobante(itemId, modalInstance, formato);
            }
        }

        var calcularSaldo = function (response, itemId, modalInstance, cambio, resp, formato) {
            var limiteCredito = response.cliente.limiteCredito;
            // var saldos = resp.data + (response.montoTotal * cambio);
            var saldos = 0;
            var saldosRemisiones = 0;
            var saldosAux = response.montoTotal;
            if (response.pedidoVenta != null && response.remisiones.length == 0) {
                if ((response.montoTotal > response.pedidoVenta.montoTotal)) {
                    saldosAux = response.montoTotal - response.pedidoVenta.montoTotal;
                }
            }
            if (response.remisiones.length > 0) {
                for (var i = 0; i < response.remisiones.length; i++) {
                    saldosRemisiones = saldosRemisiones + response.remisiones[i].montoTotal;
                }
                if ((response.montoTotal > saldosRemisiones)) {
                    saldosAux = response.montoTotal - saldosRemisiones;
                } else {
                    saldosAux = 0;
                }
            }
            saldos = resp.data + (saldosAux * cambio);
            if (saldos > limiteCredito) {
                //se excedio se informa el importe de sobregiro y se crea la solicitud de autorizacion si tiene permiso y
                //verificar de que ya no exista una solicitud previa
                if (response.solicitudAutorizacion == null && response.autorizadoSolicitud == null) {
                    var valorSobregiro = ((saldosAux * cambio) + resp.data) - response.cliente.limiteCredito;
                    valorSobregiro = Math.round(valorSobregiro);
                    if (valorSobregiro < 0) {
                        valorSobregiro = valorSobregiro * (-1);
                    }
                    valorSobregiro = valorSobregiro.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

                    $scope.mensajeModal = 'Con el importe total del comprobante de ventas se sobrepasa el Límite de Crédito del Cliente. El importe excedido del Límite de Crédito del Cliente es: ' + valorSobregiro + ' Gs.';
                    $scope.tituloModal = 'Información';
                    var modalInstance2 = $modal.open({
                        templateUrl: 'views/directives/confirmation-modal.html',
                        scope: $scope
                    });
                    $scope.cancel = function () {
                        modalInstance2.dismiss('cancel');
                        modalInstance.dismiss('cancel');
                    };

                    $scope.ok = function () {
                        $scope.mensajeModal = 'Desea realizar una Solicitud de Autorización de Sobregiro de Límite de Crédito?';
                        $scope.tituloModal = 'Confirmación';
                        var modalInstance3 = $modal.open({
                            templateUrl: 'views/directives/confirmation-modal.html',
                            scope: $scope
                        });
                        $scope.cancel = function () {
                            modalInstance3.dismiss('cancel');
                            modalInstance2.dismiss('cancel');
                            modalInstance.dismiss('cancel');
                        };

                        $scope.ok = function () {
                            //controlar que el usuario pueda crear solicitud de autorizacion
                            if (AuthorizationService.hasPermission("create_solicitudautorizacion") === true) {
                                //solicitar el campo motivo
                                $scope.mensajeModal = 'Motivo de la Solicitud: ';
                                $scope.tituloModal = 'Solicitud de Autorización de Sobregiro de Límite de Crédito';
                                //obtener el tipo de Sobregiro
                                $scope.motivos = TiposTipoFactory.get("proceso_autorizar");
                                $scope.motivos.$promise.then(function (estados) {
                                    var estadoActivo = _.find($scope.motivos, function (estado) {
                                        return estado.codigo === "sobregiro_limite_credito";
                                    });
                                    if (estadoActivo) {
                                        $scope.config = estadoActivo;
                                    }
                                    var modalInstance4 = $modal.open({
                                        templateUrl: 'qualita-components/ventas/views/pedidoventa/autorizacion-modal.html',
                                        scope: $scope
                                    });

                                    $scope.cancel = function () {
                                        modalInstance4.dismiss('cancel');
                                        modalInstance3.dismiss('cancel');
                                        modalInstance2.dismiss('cancel');
                                        modalInstance.dismiss('cancel');
                                    };

                                    $scope.ok = function (id, config) {
                                        $('#' + config.id).attr('disabled', 'disabled');
                                        ComprobanteVentaFactory.solicitarAutorizacionComprobante(itemId, config.id).then(function () {
                                            ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (res) {
                                                res.solicitudAutorizacion.motivoSolicitud = config;
                                                SolicitudAutorizacionFactory.save(res.solicitudAutorizacion);
                                                ParametrosFactory.getByCodigo("nombre_empresa").then(function (data) {
                                                    var filtersArr = [];
                                                    filtersArr.push("idcomprobanteventa=" + itemId);
                                                    filtersArr.push("nombre_empresa=" + data.valor);
                                                    filtersArr.push("id_sucursal=" + $rootScope.AuthParams.locacionSeleccionada.sucursal.id);
                                                    filtersArr.push("id_unidadnegocio=" + $rootScope.AuthParams.locacionSeleccionada.unidadNegocio.id);

                                                    modalInstance3.close();
                                                    $state.go($state.current, {}, { reload: true });
                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                        "solicitudautorizacioncomprobante",
                                                        "pdf",
                                                        filtersArr
                                                    );
                                                    $window.open(vm.pdfDownloadURL, "_blank");
                                                    $timeout(function () {
                                                        $('#' + config.id).removeAttr("disabled");
                                                    }, 1000);
                                                    $state.go($state.current, {}, { reload: true });
                                                });
                                            });

                                        }, function (error) {
                                            var mensaje = "<span>";
                                            _.forEach(error.data, function (err) {
                                                mensaje = mensaje + err.message + "<br/>";
                                            });
                                            mensaje = mensaje + "</span>";
                                            notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                        });
                                    }
                                    return estados;
                                });

                            } else {
                                $scope.tituloModal = 'Error';
                                $scope.mensajeModal = 'Usted no posee permiso para realizar la operación';
                                var modalInstance5 = $modal.open({
                                    template: '<div class="modal-header">' +
                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                        "</div>" +
                                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                                        '<div class="modal-footer">' +
                                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                        "</div>",
                                    scope: $scope
                                });
                                $scope.cancel = function () {
                                    modalInstance5.dismiss("cancel");
                                    modalInstance3.dismiss('cancel');
                                    modalInstance2.dismiss('cancel');
                                    modalInstance.dismiss('cancel');
                                };
                            }
                        }
                    }
                } else {
                    verificarSobregiro(response, itemId, modalInstance, formato);
                }
            } else {
                //no excedio
                if (response.solicitudAutorizacionBloqueo == null) {
                    aprobarComprobante(itemId, modalInstance, formato);
                } else {
                    verificarBloqueo2(response, itemId, modalInstance, formato);
                }
            }
        }

        var controlSobregiro = function (response, itemId, modalInstance, formato) {
            //si tiene pv y tiene solicitud de autorizacion de sobregiro
            if (response.pedidoVenta != null && response.pedidoVenta.solicitudAutorizacion != null) {
                //si la solicitud de sobregiro en el pv No fue autorizado
                if (response.pedidoVenta.autorizadoSolicitud == false) {
                    ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                        if (response1.valorTipo.codigo === "si") {
                            if (response.tipo.controlLimiteCredito == true) {
                                if (response.cliente.controlarLimiteCredito == true) {
                                    //obtener el saldo del cliente actualizado
                                    ClienteFactory.getSaldoCredito(response.cliente.id).then(function (resp) {
                                        //verificar la moneda del comprobante
                                        var cambio = 1;
                                        if (response.moneda.codigo != "guaranies") {
                                            if (response.cambio > 1) {
                                                cambio = response.cambio;
                                                calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                            } else if (response.cambio == null || response.cambio == 1) {
                                                //obtener de la cotizacion del dia
                                                var params = {};
                                                params.search = filterFactory.and([{
                                                    path: 'monedaDesde.id',
                                                    equals: response.moneda.id
                                                },
                                                {
                                                    path: 'id',
                                                    sortDesc: 'true'
                                                }
                                                ]).value();
                                                params.view = 'CotizacionMonedaList';
                                                CotizacionMonedaFactory.all(params).$promise.then(function (responsec) {
                                                    if (responsec && responsec[0]) {
                                                        cambio = responsec[0].valorCompra;
                                                    }
                                                    calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                                });
                                            }
                                        } else {
                                            calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                        }
                                    });
                                } else {
                                    //verificar si existe solicitud de bloqueo
                                    if (response.solicitudAutorizacionBloqueo == null) {
                                        aprobarComprobante(itemId, modalInstance, formato);
                                    } else {
                                        verificarBloqueo2(response, itemId, modalInstance, formato);
                                    }
                                }
                            } else {
                                //verificar si existe solicitud de bloqueo
                                if (response.solicitudAutorizacionBloqueo == null) {
                                    aprobarComprobante(itemId, modalInstance, formato);
                                } else {
                                    verificarBloqueo2(response, itemId, modalInstance, formato);
                                }
                            }
                        } else {
                            //verificar si existe solicitud de bloqueo
                            if (response.solicitudAutorizacionBloqueo == null) {
                                aprobarComprobante(itemId, modalInstance, formato);
                            } else {
                                verificarBloqueo2(response, itemId, modalInstance, formato);
                            }
                        }
                    });
                } else {
                    if (response.montoTotal > response.pedidoVenta.montoTotal) {
                        ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                            if (response1.valorTipo.codigo === "si") {
                                if (response.tipo.controlLimiteCredito == true) {
                                    if (response.cliente.controlarLimiteCredito == true) {
                                        //obtener el saldo del cliente actualizado
                                        ClienteFactory.getSaldoCredito(response.cliente.id).then(function (resp) {
                                            //verificar la moneda del comprobante
                                            var cambio = 1;
                                            if (response.moneda.codigo != "guaranies") {
                                                if (response.cambio > 1) {
                                                    cambio = response.cambio;
                                                    calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                                } else if (response.cambio == null || response.cambio == 1) {
                                                    //obtener de la cotizacion del dia
                                                    var params = {};
                                                    params.search = filterFactory.and([{
                                                        path: 'monedaDesde.id',
                                                        equals: response.moneda.id
                                                    },
                                                    {
                                                        path: 'id',
                                                        sortDesc: 'true'
                                                    }
                                                    ]).value();
                                                    params.view = 'CotizacionMonedaList';
                                                    CotizacionMonedaFactory.all(params).$promise.then(function (responsec) {
                                                        if (responsec && responsec[0]) {
                                                            cambio = responsec[0].valorCompra;
                                                        }
                                                        calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                                    });
                                                }
                                            } else {
                                                calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                            }
                                        });
                                    } else {
                                        //verificar si existe solicitud de bloqueo
                                        if (response.solicitudAutorizacionBloqueo == null) {
                                            aprobarComprobante(itemId, modalInstance, formato);
                                        } else {
                                            verificarBloqueo2(response, itemId, modalInstance, formato);
                                        }
                                    }
                                } else {
                                    //verificar si existe solicitud de bloqueo
                                    if (response.solicitudAutorizacionBloqueo == null) {
                                        aprobarComprobante(itemId, modalInstance, formato);
                                    } else {
                                        verificarBloqueo2(response, itemId, modalInstance, formato);
                                    }
                                }
                            } else {
                                //verificar si existe solicitud de bloqueo
                                if (response.solicitudAutorizacionBloqueo == null) {
                                    aprobarComprobante(itemId, modalInstance, formato);
                                } else {
                                    verificarBloqueo2(response, itemId, modalInstance, formato);
                                }
                            }
                        });
                    } else {
                        //verificar si existe solicitud de bloqueo
                        if (response.solicitudAutorizacionBloqueo == null) {
                            aprobarComprobante(itemId, modalInstance, formato);
                        } else {
                            verificarBloqueo2(response, itemId, modalInstance, formato);
                        }
                    }
                }
            } else {
                if (response.pedidoVenta != null) {
                    ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                        if (response1.valorTipo.codigo === "si" && (response.montoTotal > response.pedidoVenta.montoTotal)) {
                            if (response.tipo.controlLimiteCredito == true) {
                                if (response.cliente.controlarLimiteCredito == true) {
                                    //obtener el saldo del cliente actualizado
                                    ClienteFactory.getSaldoCredito(response.cliente.id).then(function (resp) {
                                        //verificar la moneda del comprobante
                                        var cambio = 1;
                                        if (response.moneda.codigo != "guaranies") {
                                            if (response.cambio > 1) {
                                                cambio = response.cambio;
                                                calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                            } else if (response.cambio == null || response.cambio == 1) {
                                                //obtener de la cotizacion del dia
                                                var params = {};
                                                params.search = filterFactory.and([{
                                                    path: 'monedaDesde.id',
                                                    equals: response.moneda.id
                                                },
                                                {
                                                    path: 'id',
                                                    sortDesc: 'true'
                                                }
                                                ]).value();
                                                params.view = 'CotizacionMonedaList';
                                                CotizacionMonedaFactory.all(params).$promise.then(function (responsec) {
                                                    if (responsec && responsec[0]) {
                                                        cambio = responsec[0].valorCompra;
                                                    }
                                                    calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                                });
                                            }
                                        } else {
                                            calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                        }
                                    });
                                } else {
                                    //verificar si existe solicitud de bloqueo
                                    if (response.solicitudAutorizacionBloqueo == null) {
                                        aprobarComprobante(itemId, modalInstance, formato);
                                    } else {
                                        verificarBloqueo2(response, itemId, modalInstance, formato);
                                    }
                                }
                            } else {
                                //verificar si existe solicitud de bloqueo
                                if (response.solicitudAutorizacionBloqueo == null) {
                                    aprobarComprobante(itemId, modalInstance, formato);
                                } else {
                                    verificarBloqueo2(response, itemId, modalInstance, formato);
                                }
                            }
                        } else {
                            //verificar si existe solicitud de bloqueo
                            if (response.solicitudAutorizacionBloqueo == null) {
                                aprobarComprobante(itemId, modalInstance, formato);
                            } else {
                                verificarBloqueo2(response, itemId, modalInstance, formato);
                            }
                        }
                    });
                } else {
                    ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                        if (response1.valorTipo.codigo === "si") {
                            if (response.tipo.controlLimiteCredito == true) {
                                if (response.cliente.controlarLimiteCredito == true) {
                                    //obtener el saldo del cliente actualizado
                                    ClienteFactory.getSaldoCredito(response.cliente.id).then(function (resp) {
                                        //verificar la moneda del comprobante
                                        var cambio = 1;
                                        if (response.moneda.codigo != "guaranies") {
                                            if (response.cambio > 1) {
                                                cambio = response.cambio;
                                                calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                            } else if (response.cambio == null || response.cambio == 1) {
                                                //obtener de la cotizacion del dia
                                                var params = {};
                                                params.search = filterFactory.and([{
                                                    path: 'monedaDesde.id',
                                                    equals: response.moneda.id
                                                },
                                                {
                                                    path: 'id',
                                                    sortDesc: 'true'
                                                }
                                                ]).value();
                                                params.view = 'CotizacionMonedaList';
                                                CotizacionMonedaFactory.all(params).$promise.then(function (responsec) {
                                                    if (responsec && responsec[0]) {
                                                        cambio = responsec[0].valorCompra;
                                                    }
                                                    calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                                });
                                            }
                                        } else {
                                            calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                        }
                                    });
                                } else {
                                    //verificar si existe solicitud de bloqueo
                                    if (response.solicitudAutorizacionBloqueo == null) {
                                        aprobarComprobante(itemId, modalInstance, formato);
                                    } else {
                                        verificarBloqueo2(response, itemId, modalInstance, formato);
                                    }
                                }
                            } else {
                                //verificar si existe solicitud de bloqueo
                                if (response.solicitudAutorizacionBloqueo == null) {
                                    aprobarComprobante(itemId, modalInstance, formato);
                                } else {
                                    verificarBloqueo2(response, itemId, modalInstance, formato);
                                }
                            }
                        } else {
                            //verificar si existe solicitud de bloqueo
                            if (response.solicitudAutorizacionBloqueo == null) {
                                aprobarComprobante(itemId, modalInstance, formato);
                            } else {
                                verificarBloqueo2(response, itemId, modalInstance, formato);
                            }
                        }
                    });

                }
            }
        }
    }
    $scope.calcularNumerosComprobantesHasta = function (numeroInicial) {
        $scope.comprobantesFinales = [];
        vm.numeroComprobanteFinal = null;
        _.forEach($scope.comprobantes, function (comprobante) {
            if (comprobante.id >= numeroInicial.id) {
                $scope.comprobantesFinales.push(comprobante);
            }
        });
    }

    $scope.calcularNumerosComprobantesHastaImprimir = function (numeroInicial) {
        $scope.comprobantesFinalesImprimir = [];
        vm.numeroComprobanteFinalImprimir = null;
        _.forEach($scope.comprobantesImprimir, function (comprobante) {
            /* if(comprobante.id >= numeroInicial.id){
                $scope.comprobantesFinalesImprimir.push(comprobante);
            } */
            if (comprobante.numero >= numeroInicial.numero) {
                $scope.comprobantesFinalesImprimir.push(comprobante);
            }
        });
    }

    function filterTipoComprobantes(ventaId) {

        var staticFilterTC = {};
        vm.tipos = {descripcion:"SA"};
        //if (ventaId) {
            //TipoComprobanteFactory.getTiposComprobantes(ventaId).then(function (response) {
              //  vm.tipos = response.data;
            //});
            staticFilterTC = {
                search: filterFactory
                    .and([
                    {
                        path: "activo",
                        equals: true
                    },
                    {
                        path: "mostrarImpresionMasiva",
                        equals: true
                    }
                ])
                    .value()
            };
            getPuntosEmisionVenta();

        //} else {
            //TipoComprobanteFactory.getTiposComprobantes(0).then(function (response) {
              //  vm.tipos = response.data;
            //});            
          //  staticFilterTC = {
            //   search: filterFactory
             //       .and([{
               //         path: 'comprobantesVenta.id',
                 //       isNotNull: true
                   // },
                    //{
                      //  path: "activo",
                       // equals: true
                    //},
                    //{
                      //  path: "mostrarImpresionMasiva",
                        //equals: true
                    //}
               // ]).value()
            //}; 
            //getPuntosEmisionVenta();

        //}
        vm.tipos = TipoComprobanteFactory.all(staticFilterTC, "ConsultaComprobanteVentaForm");
    }

    function filterTimbrado(puntoEmision) {
        vm.timbrado = undefined;
        vm.numeroComprobanteInicialImprimir = undefined;
        vm.numeroComprobanteFinalImprimir = undefined;

        var staticFilter = {};
        if (vm.fromOutside) {
            staticFilter.search = filterFactory.and([{
                path: "timbradoEmpresa.activo",
                equals: true
            },{
                path: "tipoComprobante.id",
                equals: vm.tipoComprobante.clase.id
            },{
                path: "puntoEmisionVenta.id",
                equals: puntoEmision.id
            }]).value();
        } else {
            staticFilter.search = filterFactory.and([{
                path: "timbradoEmpresa.activo",
                equals: true
            },{
                path: "tipoComprobante.id",
                equals: tipoComprobante.clase.id
            },{
                path: "puntoEmisionVenta.id",
                equals: puntoEmision.id
            }]).value();
        }

        TimbradoEmpresaPuntoEmisionVentaFactory.all(staticFilter, "ConsultaComprobanteVentaForm").$promise.then(function (response) {

            if (response.length > 0) {
                $scope.timbrados = response;
            }
        });
    }

    function comprobantes(tipoComprobante, puntoEmision, fechaDesde, fechaHasta) {
        console.log("aca ", tipoComprobante)
        console.log("aca1 ", puntoEmision)
        console.log("aca2 ", fechaDesde)
        console.log("aca3 ", fechaHasta)

        var staticFilter = {};
        if (vm.fromOutside) {
            console.log("aca4 ",vm.timbrado)
            console.log("fechadesde ",moment(fechaDesde).format("DD/MM/YYYY"))
            console.log("fecha ",moment(fechaHasta).format("DD/MM/YYYY"))

            staticFilter.search = filterFactory.and([{
                path: 'estado.codigo',
                equals: 'comp_venta_emitido'
            },
            {
                path: 'timbradoPuntoEmision.id',
                equals: vm.timbrado.id
            }, {
                path: 'numero',
                sortAsc: true
            },
            {
                path: 'tipo.id',
                equals: tipoComprobante.id
            }, {
                path: 'fecha',
                equalOrBefore: moment(fechaHasta).format("DD/MM/YYYY")
            }, {
                path: 'fecha',
                equalOrAfter: moment(fechaDesde).format("DD/MM/YYYY")
            }, {
                path: 'timbradoPuntoEmision.puntoEmisionVenta.id',
                equals: puntoEmision.id
            }]).value();
        } else {
            staticFilter.search = filterFactory.and([{
                path: 'venta.id',
                equals: $scope.venta.id
            }, {
                path: 'estado.codigo',
                equals: 'comp_venta_emitido'
            },
            {
                path: 'timbradoPuntoEmision.id',
                equals: vm.timbrado.id
            }, {
                path: 'numero',
                sortAsc: true
            }, {
                path: 'tipo.id',
                equals: tipoComprobante.id
            }, {
                path: 'fecha',
                equalOrBefore: moment(fechaHasta).format("DD/MM/YYYY")
            }, {
                path: 'fecha',
                equalOrAfter: moment(fechaDesde).format("DD/MM/YYYY")
            }, {
                path: 'timbradoPuntoEmision.puntoEmisionVenta.id',
                equals: puntoEmision.id
            }]).value();
        }
        console.log("acastatic ", staticFilter)
        vm.uiBlockuiConfig.bloquear = true;
        ComprobanteVentaFactory.all(staticFilter, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
            console.log("Resp ", response)
            if (response.length > 0) {
                $scope.comprobantesImprimir = response;
            } else {
                var msg = "No existen comprobantes para este Timbrado y Punto de Emisión";
                notify({ message: msg, classes: 'alert-warning', position: 'right' });
            }
            vm.uiBlockuiConfig.bloquear = false;

        });
    }
    function searchTimbradoPuntoEmisionFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return String(item.timbradoEmpresa.numeroTimbrado).toLowerCase().indexOf(criteria.toLowerCase()) > -1;

        };
    }


    function getPuntosEmisionVenta() {
        if (vm.watchPE) {
            vm.watchPE();
        }
        WatchLocacionChangeFactory.executeWatching(function (
            locacionFilter,
            watcher
        ) {
            vm.watcherPE = watcher;
            var filters = {
                search: filterFactory.and([{
                    path: "puntoVenta.sucursal.id",
                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                }]).value()
            }
            $scope.puntosEmisionesVenta = puntoEmisionFactory.all(filters, "ConsultaComprobanteVentaForm");
        }, "locacion.id");
    }
    function puntoEmisionSelect() {
        vm.timbrado = undefined;
        vm.puntoEmision = undefined;
        vm.numeroComprobanteInicialImprimir = undefined;
        vm.numeroComprobanteFinalImprimir = undefined;
    }
    function fechaSelect() {
        vm.timbrado = undefined;
        vm.tipoComprobante =undefined;
        vm.puntoEmision = undefined;
        vm.numeroComprobanteInicialImprimir = undefined;
        vm.numeroComprobanteFinalImprimir = undefined;
    }
}

"use strict";

angular
    .module("qualita.venta")
    .controller("ComprobanteVentaFormCtrl", ComprobanteVentaFormCtrl);

ComprobanteVentaFormCtrl.$inject = [
    "ComprobanteVentaFactory",
    "comprobantePrepService",
    "ProductosFactory",
    "TiposFactory",
    "ClienteFactory",
    "TipoComprobanteFactory",
    "GruposCompraFactory",
    "CotizacionMonedaFactory",
    "ServicioFactory",
    "filterFactory",
    "ParametrosFactory",
    "PedidoVentaFactory",
    "$scope",
    "notify",
    "$state",
    "$rootScope",
    "$timeout",
    "formFactory",
    "TipoImpuestosFactory",
    "RecepcionFactory",
    "ProveedorSucursalesFactory",
    "RubroIvaFactory",
    "ModelTrimmer",
    "AlmacenFactory",
    "TransaccionStockFactory",
    "TimbradoEmpresaPuntoEmisionVentaFactory",
    "VentasLangFactory",
    "CompraFactory",
    "WatchLocacionChangeFactory",
    "UtilFactory",
    "ComprobanteVentaDetalleFactory",
    "OrdenesCompraDetalleFactory",
    "GruposProductosFactory",
    "$q",
    "SolicitudCompraFactory",
    "ProyectoFactory",
    "VentaFactory",
    "puntoEmisionFactory",
    "$filter",
    "cajaFactory",
    "puntoVentaFactory",
    "PeriodoAdministrativoFactory",
    "CambioPrecioFactory",
    "CambioPrecioServicioFactory",
    "GrupoProductoAlmacenFactory",
    "AuthorizationService",
    "ListaPrecioVentaFactory",
    "SolicitudAutorizacionFactory",
    "RemisionFactory",
    "VendedorFactory",
    "FormaPagoFactory",
    "SucursalesFactory",
    "CamionFactory",
    "ChoferFactory",
    "CentroCostosFactory",
    "observeOnScope",
    "ListaPrecioVentaDetalleFactory",
    "ConceptoMovimientoFactory",
    "MovimientoStockFactory",
    "suscripcionRecurrenteFactory",
    "agendaSuscripcionRecurrenteFactory",
    "$modal",
    "ProcesoCobroFactory",
    "CobroDetalleFactory",
    "TiposTipoFactory",
    "CuentaContableFactory",
    "EntidadBancariaFactory",
    "ProveedoresFactory",
    "DenominacionTarjetaFactory",
    "TipoCobroFactory",
    "CobroFactory",
    "CONFIGURATION",
    "$window"
];

function ComprobanteVentaFormCtrl(
    ComprobanteVentaFactory,
    comprobantePrepService,
    ProductosFactory,
    TiposFactory,
    ClienteFactory,
    TipoComprobanteFactory,
    GruposCompraFactory,
    CotizacionMonedaFactory,
    ServicioFactory,
    filterFactory,
    ParametrosFactory,
    PedidoVentaFactory,
    $scope,
    notify,
    $state,
    $rootScope,
    $timeout,
    formFactory,
    TipoImpuestosFactory,
    RecepcionFactory,
    ProveedorSucursalesFactory,
    RubroIvaFactory,
    ModelTrimmer,
    AlmacenFactory,
    TransaccionStockFactory,
    TimbradoEmpresaPuntoEmisionVentaFactory,
    VentasLangFactory,
    CompraFactory,
    WatchLocacionChangeFactory,
    UtilFactory,
    ComprobanteVentaDetalleFactory,
    OrdenesCompraDetalleFactory,
    GruposProductosFactory,
    $q,
    SolicitudCompraFactory,
    ProyectoFactory,
    VentaFactory,
    puntoEmisionFactory,
    $filter,
    cajaFactory,
    puntoVentaFactory,
    PeriodoAdministrativoFactory,
    CambioPrecioFactory,
    CambioPrecioServicioFactory,
    GrupoProductoAlmacenFactory,
    AuthorizationService,
    ListaPrecioVentaFactory,
    SolicitudAutorizacionFactory,
    RemisionFactory,
    VendedorFactory,
    FormaPagoFactory,
    SucursalesFactory,
    CamionFactory,
    ChoferFactory,
    CentroCostosFactory,
    observeOnScope,
    ListaPrecioVentaDetalleFactory,
    ConceptoMovimientoFactory,
    MovimientoStockFactory,
    suscripcionRecurrenteFactory,
    agendaSuscripcionRecurrenteFactory,
    $modal,
    ProcesoCobroFactory,
    CobroDetalleFactory,
    TiposTipoFactory,
    CuentaContableFactory,
    EntidadBancariaFactory,
    ProveedoresFactory,
    DenominacionTarjetaFactory,
    TipoCobroFactory,
    CobroFactory,
    CONFIGURATION,
    $window
) {

    var vm = this;
    vm.formSuscripcionRecurrente = false;
    vm.errores = [];
    vm.erroresDescuento = [];
    vm.precioMinimoMaximos = [];
    vm.agendaSuscripcionRecurrente = {};
    vm.uiBlockuiConfig = {
        'bloquear': false,
        'message': 'Cargando datos...'
    };

    $scope.diasSemana = [{
        name: "Lunes",
        value: true,
      }, {
        name: "Martes",
        value: true,
      }, {
        name: "Miercoles",
        value: true,
      }, {
        name: "Jueves",
        value: true,
      }, {
        name: "Viernes",
        value: true,
      }, {
        name: "Sabado",
        value: true,
      }, {
        name: "Domingo",
        value: true,
      }, ];

      $scope.dias = {
        diasSemana: []
      };



    activate();

    vm.agregarDetalle = agregarDetalle;
    vm.agregarDetalleServicio = agregarDetalleServicio;
    vm.agregarDetalleProducto = agregarDetalleProducto;
    vm.agregarMontoImponible = agregarMontoImponible;
    vm.calcularTotalDetalle = calcularTotalDetalle;
    vm.removeItemFromArray = removeItemFromArray;
    vm.searchProductoFilter = searchProductoFilter;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.changePV = changePV;
    vm.changeTipo = changeTipo;
    vm.changeProducto = changeProducto;
    vm.changeServicio = changeServicio;
    vm.relacionarFactura = relacionarFactura;
    vm.obtenerDetallesComprobante = obtenerDetallesComprobante;
    vm.changeCotizacion = changeCotizacion;
    vm.changeFecha = changeFecha;
    vm.changeFechaSuscripcion = changeFechaSuscripcion;
    vm.changeGrupoCompra = changeGrupoCompra;
    vm.changeRecepciones = changeRecepciones;
    vm.mostrarTodosProductos = mostrarTodosProductos;
    vm.totalImpuestoChanged = totalImpuestoChanged;
    vm.tipoImpuestoChanged = tipoImpuestoChanged;
    vm.changeCliente = changeCliente;
    vm.requireDeposito = requireDeposito;
    vm.totalItemChanged = totalItemChanged;
    // vm.completarNroFactura = completarNroFactura;
    vm.validarNroFactura = validarNroFactura;
    vm.searchSolicitudCompraFilter = searchSolicitudCompraFilter;
    vm.searchTimbradoPuntoEmisionFilter = searchTimbradoPuntoEmisionFilter;
    vm.addProductoGrupo = addProductoGrupo;
    vm.comprobanteGeneraMovimiento = false;
    vm.timbradoSelect = timbradoSelect;
    vm.puntoEmisionSelected = puntoEmisionSelected;
    vm.filterTimbrado = filterTimbrado;
    vm.changeUnidad = changeUnidad;
    vm.getProductoStockAlmacen = getProductoStockAlmacen;
    vm.almacenesConStock = null;
    vm.changeAlmacen = changeAlmacen;
    vm.changeTipoComprobante = changeTipoComprobante;
    vm.changeTipoAplicacion = changeTipoAplicacion;
    vm.advertenciaTipoTransaccionMostrada = false;
    var sumaImponible;
    $rootScope.isProcessing = false;
    vm.currencyDisabled = false;
    vm.lockPedidoVenta = false;
    vm.delayRequestProducto = delayRequestProducto;
    vm.plusClicked = plusClicked;
    vm.getListasPreciosVentasByMoneda = getListasPreciosVentasByMoneda;
    vm.changeListaPrecioVenta = changeListaPrecioVenta;
    vm.actualizarPreciosDeVentas = actualizarPreciosDeVentas;
    vm.viewTipoApli = true;
    vm.compararDescendente = compararDescendente;
    vm.changeTipoCambio = changeTipoCambio;
    vm.changePrecioCambioPactado = changePrecioCambioPactado;
    vm.volverSolicitud = volverSolicitud;
    vm.changeCienteSucursal = changeCienteSucursal;
    vm.changeConductor = changeConductor;
    vm.changeCamion = changeCamion;
    vm.delayRequestCliente = delayRequestCliente;
    vm.searchClienteFilter = searchClienteFilter;
    vm.buscarComprobantes = buscarComprobantes;
    vm.searchSucursalClienteFilter = searchSucursalClienteFilter;
    vm.changeUnidadAux = changeUnidadAux;
    vm.recuperarItemsComprobanteParaNc = recuperarItemsComprobanteParaNc;
    vm.buscarMovimientosStock = buscarMovimientosStock;
    vm.validarFechasSuscripcion = validarFechasSuscripcion;
    vm.validarFechaPrimeraFacturacion = validarFechaPrimeraFacturacion;
    vm.changeCondicionVenta = changeCondicionVenta;
    vm.fechaFromSuscripcion = false;
    vm.refreshReferencias = refreshReferencias;
    vm.changeComprobanteAnticipo = changeComprobanteAnticipo;
    vm.changeTipoTransaccion = changeTipoTransaccion;
    vm.codigoBarrasAdded = codigoBarrasAdded;
    vm.codigoBarrasRemoved = codigoBarrasRemoved;
    vm.removeItemFromArrayCobro = removeItemFromArrayCobro;
    vm.limpiarCampos = limpiarCampos;
    vm.updateTotalDetalles = updateTotalDetalles;
    vm.changeTipoComprobanteAgil = changeTipoComprobanteAgil;

    function changeTipoTransaccion() {
        if(vm.comprobante.tipoTransaccion.codigo === 'transaccion_anticipo'){
            vm.existeAnticipo = false;
            vm.comprobante.tipoAnticipo = null;
            vm.comprobante.porcentajeAnticipo = 0;
            vm.comprobante.comprobanteAnticipo = null;
            vm.comprobante.importeAnticipo = 0;
            vm.comprobantesAnticipo =[];
            if (vm.comprobantesProductos && vm.comprobantesProductos.length > 0) {
                _.forEach(vm.comprobantesProductos, function (elem) {
                    calcularTotalDetalle(elem);
                })
            }
            if (vm.comprobantesServicios && comprobantesServicios.length > 0) {
                _.forEach(vm.comprobantesServicios, function (elem) {
                    calcularTotalDetalle(elem);
                })
            }
        }else{
            //buscar si existen comprobantes de tipo anticipo para el cliente seleccionado
            var staticFilterComprobanteAnticipo = {};
            staticFilterComprobanteAnticipo.search = filterFactory
            .or([ filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_emitido"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                    filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_aprobado_sifen"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                ])
                .value();

            ComprobanteVentaFactory.all(staticFilterComprobanteAnticipo, "ComprobanteVentaList").$promise.then(function (anticipos) {
                vm.existeAnticipo = false;
                if(anticipos.length > 0){
                    vm.existeAnticipo = true;
                    vm.comprobante.tipoAnticipo = vm.tipoAnticipos[0];
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobantesAnticipo =[];
                    var staticFilterComprobante = {
                        search: filterFactory
                            .and([{
                                path: "cliente.id",
                                equals: vm.comprobante.cliente.id
                            }, {
                                path: "estado.codigo",
                                notEquals: "comp_venta_anulado"
                            }])
                            .value()
                    };
                    ComprobanteVentaFactory.all(staticFilterComprobante, "ComprobanteVentaList").$promise.then(function (comprobantes) {
                        _.forEach(anticipos, function (anticipo) {
                            var comprobanteExiste = comprobantes.find(function(compro) {
                                if(compro.comprobanteAnticipo != null){
                                    if(compro.comprobanteAnticipo.id === anticipo.id){
                                        return true;
                                    }
                                }
                            });
                            if(!comprobanteExiste){
                                vm.comprobantesAnticipo.push(anticipo);
                            }
                        })

                    });
                }else{
                    vm.existeAnticipo = false;
                    vm.comprobante.tipoAnticipo = null;
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobantesAnticipo =[];
                }
            });
        }
    }

    function changeComprobanteAnticipo() {
        vm.comprobante.importeAnticipo = vm.comprobante.comprobanteAnticipo.montoTotal;
        if(vm.existeAnticipo && vm.comprobante.comprobanteAnticipo){
            //sumar cantidad*precio tanto de productos como se servicios si existe
            var totalBruto = 0;
            var totalBrutoProducto = 0;
            var totalBrutoServicio = 0;
            if(vm.comprobantesProductos.length > 0){
                _.forEach(vm.comprobantesProductos, function (det) {
                    totalBrutoProducto += det.cantidad * det.precioVenta;
                });
            }
            if(vm.comprobantesServicios.length > 0){
                _.forEach(vm.comprobantesServicios, function (det) {
                    totalBrutoServicio += det.cantidad * det.precioVenta;
                });
            }
            totalBruto = totalBrutoProducto + totalBrutoServicio;
            vm.comprobante.porcentajeAnticipo = (vm.comprobante.importeAnticipo/totalBruto)*100;
            vm.comprobante.porcentajeAnticipo = parseFloat(vm.comprobante.porcentajeAnticipo.toFixed(8));
            if(vm.comprobantesProductos.length > 0){
                var sumCompProducto = 0;
                _.forEach(vm.comprobantesProductos, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta)/100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo*elem.cantidad;
                    elem.totalItem = (elem.precioVenta-elem.importeAnticipo)*elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompProducto += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);

                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }
        
                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralProductos = UtilFactory.roundForMoneda(sumCompProducto, vm.comprobante.moneda);
            }
            if(vm.comprobantesServicios.length > 0){
                var sumCompServicio = 0;

                _.forEach(vm.comprobantesServicios, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta)/100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo*elem.cantidad;
                    elem.totalItem = (elem.precioVenta-elem.importeAnticipo)*elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompServicio += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);
                    
                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }
        
                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralServicios = UtilFactory.roundForMoneda(sumCompServicio, vm.comprobante.moneda);
            }
        }
    }

    function validarFechasSuscripcion(){
        vm.fechaFromSuscripcion = true;
        if(vm.comprobante && vm.comprobante.fechaInicial && vm.comprobante.fechaFinal){

            if(vm.comprobante.fechaFinal < vm.comprobante.fechaInicial) {

              var msg = '';
              msg = $filter('translate')('SUSCRIPCION_FECHAS_INCORRECTAS')+"\n";
              notify({ message: msg, classes: 'alert-danger', position: 'right'});

            }else{
                vm.comprobante.primeraFacturacion = vm.comprobante.fechaInicial;
            }

        }
    }

    function validarFechaPrimeraFacturacion(){
        if(vm.comprobante && vm.comprobante.fechaInicial && vm.comprobante.primeraFacturacion){

            if(vm.comprobante.primeraFacturacion > vm.comprobante.fechaFinal || vm.comprobante.primeraFacturacion < vm.comprobante.fechaInicial){
              var msg = '';
              msg = $filter('translate')('SUSCRIPCION_FECHAS_INCORRECTAS')+"\n";
              notify({ message: msg, classes: 'alert-danger', position: 'right'});
            }
        }
    }






    function buscarMovimientosStock() {
        //controlar que se selecciono cliente
        if (vm.comprobante.cliente == undefined) {
            var msg = "Primero debe seleccionar un Cliente.";
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            return;
        }
        //var staticFilter = {};
        vm.optionsMovimientoStock.staticFilter = {
            search: filterFactory
                .and([{
                    path: "entidadPrincipal.id",
                    equals: vm.comprobante.cliente.id
                },
                {
                    path: "estado.codigo",
                    notEquals: "ms_reversado"
                },
                {
                    path: "notaCredito.id",
                    isNull: 'true'
                }])
                .value()
        };

        vm.showMovimientoStock = true;

    }

    var getIDs = function () {
        var ids = [];
        _.forEach(vm.optionsMovimientoStock.selection, function (n, key) {
            if (n)
                ids.push(key);
        });
        return ids;
    };

    function recuperarItemsMovimientoStockParaNc() {
        vm.comprobantesProductos = [];
        vm.comprobantesServicios = [];

        //Recuperar los movimientos de stock seleccionados
        _.forEach(getIDs(), function (det) {
            MovimientoStockFactory.get(det, "OperacionManualForm").$promise.then(function (ms) {
                _.forEach(ms.movimientoDetalle, function (detalle) {
                    if ((detalle.producto && detalle.grupo != null && detalle.generado == null) || (detalle.producto && detalle.grupo == null && detalle.generado == null)) {
                        var newProducto = {
                            montosImponibles: [{
                                tipoImpuesto: detalle.producto.tiposImpuesto[0],
                                porcentaje: detalle.producto.tiposImpuesto[0].porcentaje,
                                valor: 0
                            }],
                            producto: detalle.producto,
                            unidadMedida: detalle.unidadMedidaBase,
                            cantidad: detalle.cantidadReferencial,
                            grupoProducto: vm.comprobanteGeneraMovimiento && (detalle.producto.criterioControl.codigo ==
                                "series" || detalle.producto.criterioControl.codigo == "lotes") ? detalle.grupo : undefined,
                            tipo: getTipoProducto(vm.tiposDetalle),
                            conceptoMovimiento: detalle.conceptoMovimiento
                        };
                        newProducto.cantMaxima = null;
                        newProducto.numeroOrdenProduccion = detalle.nroOrdenProduccion;

                        if (vm.comprobante.listaPrecioVenta) {
                            var staticFilter = {};
                            staticFilter.search = filterFactory.and([{
                                path: 'listaPrecioVenta.id',
                                equals: vm.comprobante.listaPrecioVenta.id,
                            },
                            {
                                path: 'producto.id',
                                equals: detalle.producto.id,
                            }]).value();
                            staticFilter.view = "ComprobanteVentaForm";
                            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                                if ((response == undefined || response.length == 0) && vm.permitirProductosNotListaPrecio == 'No') {
                                    var msg = "Existen productos que no se encuentran en la lista de precio";
                                    notify({ message: msg, classes: "alert-warning", position: "right" })
                                    return;
                                } else {
                                    if (response) {
                                        var productoLista = [];
                                        _.forEach(response, function (elem) {
                                            if (detalle.unidadMedidaBase.id == elem.unidadMedida.id) {
                                                productoLista = elem;
                                            }
                                        });

                                        if (productoLista) {
                                            if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                                                //si el comprobante es en gs y la lista en moneda extranjera
                                                newProducto.precioVenta = productoLista.precioVenta * vm.comprobante.cambioPactado;
                                                newProducto.precioMinimo = productoLista.precioMinimo * vm.comprobante.cambioPactado;
                                                newProducto.precioMaximo = productoLista.precioMaximo * vm.comprobante.cambioPactado;
                                                newProducto.precioVenta = UtilFactory.roundForMoneda(newProducto.precioVenta, vm.comprobante.moneda)
                                            } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                                                //si el comprobante es en moneda extranjera y la lista en gs
                                                var precioFinal = productoLista.precioVenta / vm.comprobante.cambio;
                                                var precioMin = productoLista.precioMinimo / vm.comprobante.cambio;
                                                var precioMax = productoLista.precioMaximo / vm.comprobante.cambio;
                                                newProducto.precioVenta = Math.round(precioFinal * 100) / 100;
                                                newProducto.precioMinimo = Math.round(precioMin * 100) / 100;
                                                newProducto.precioMaximo = Math.round(precioMax * 100) / 100;
                                            } else {
                                                newProducto.precioVenta = productoLista.precioVenta;
                                                newProducto.precioMinimo = productoLista.precioMinimo;
                                                newProducto.precioMaximo = productoLista.precioMaximo;
                                            }
                                            vm.calcularTotalDetalle(newProducto);
                                            vm.productoPerteneceLista = true;
                                        } else {
                                            vm.productoPerteneceLista = false;
                                            newProducto.precioVenta = 0;
                                            CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                                                if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambioPactado) {
                                                    //si el comprobante es en gs y la lista en moneda extranjera
                                                    newProducto.precioVenta = precio.data * vm.comprobante.cambioPactado;
                                                    newProducto.precioVenta = UtilFactory.roundForMoneda(newProducto.precioVenta, vm.comprobante.moneda)
                                                } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                                                    //si el comprobante es en moneda extranjera y la lista en gs
                                                    var precioFinal = precio.data / vm.comprobante.cambio;
                                                    newProducto.precioVenta = Math.round(precioFinal * 100) / 100;
                                                } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambio) {
                                                    //si el comprobante es en moneda extranjera y la lista en moneda extranjera
                                                    var precioFinal = precio.data / vm.comprobante.cambio;
                                                    newProducto.precioVenta = Math.round(precioFinal * 100) / 100;
                                                } else {
                                                    newProducto.precioView = precio.data.toString().replace(/\./, ",");
                                                    newProducto.precioVenta = parseFloat(newProducto.precioView.replace(/,/, "."));
                                                }
                                                vm.calcularTotalDetalle(newProducto);
                                            });
                                        }
                                    } else {
                                        //El cliente está asociado a una lista de precios de ventas sin detalles
                                    }
                                }
                            });
                        } else {
                            CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedidaBase.id).then(function (precioRecuperado) {
                                if (precioRecuperado.data != null) {
                                    newProducto.precioVentaView = precioRecuperado.data.toString().replace(/\./, ",");
                                    newProducto.precioVenta = parseFloat(newProducto.precioVentaView.replace(/,/, "."));
                                }
                            });
                        }

                        if (vm.verPrecio == 'No') {
                            CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedidaBase.id).then(function (precioRecuperado) {
                                if (precioRecuperado.data != null) {
                                    newProducto.precioVentaView = precioRecuperado.data.toString().replace(/\./, ",");
                                    newProducto.precioVenta = parseFloat(newProducto.precioVentaView.replace(/,/, "."));
                                }
                            });
                        }
                        vm.comprobantesProductos.push(newProducto);
                        vm.calcularTotalDetalle(newProducto);
                    } else if (detalle.servicio) {
                        ServicioFactory.get(
                            detalle.servicio.id,
                            "BaseList"
                        ).$promise.then(function (response) {
                            var newServicio = {
                                montosImponibles: [{
                                    tipoImpuesto: response.tipoImpuesto,
                                    porcentaje: response.tipoImpuesto.porcentaje,
                                    valor: 0
                                }],
                                servicio: detalle.servicio,
                                cantidad: detalle.cantidad,
                                tipo: vm.tiposDetalle[1],
                                precioVenta: detalle.precioVenta
                            };

                            vm.comprobantesServicios.push(newServicio);
                            vm.calcularTotalDetalle(newServicio);
                        });
                    }
                });

            });


        });
    }

    function recuperarItemsComprobanteParaNc() {
        vm.comprobantesProductos = [];
        vm.comprobantesServicios = [];

        _.forEach(vm.comprobante.relacionarFacturas, function (det) {
            _.forEach(det.comprobanteDetalle, function (detalle) {
                if (detalle.producto) {
                    var newProducto = {
                        montosImponibles: [{
                            tipoImpuesto: detalle.producto.tiposImpuesto[0],
                            porcentaje: detalle.producto.tiposImpuesto[0].porcentaje,
                            valor: 0
                        }],
                        producto: detalle.producto,
                        unidadMedida: detalle.unidadMedida,
                        cantidad: (detalle.producto.criterioControl.codigo == "series" && vm.comprobanteGeneraMovimiento) ?
                            1 : detalle.cantidad,
                        grupoProducto: vm.comprobanteGeneraMovimiento && (detalle.producto.criterioControl.codigo ==
                            "series" || detalle.producto.criterioControl.codigo == "lotes") ? detalle.grupoProducto : undefined,
                        precioVenta: detalle.precioVenta,
                        tipo: getTipoProducto(vm.tiposDetalle),
                        descuento: detalle.descuento
                    };
                    newProducto.cantMaxima = null;
                    newProducto.numeroOrdenCompraCliente = detalle.numeroOrdenCompraCliente;
                    newProducto.numeroOrdenProduccion = detalle.numeroOrdenProduccion;
                    newProducto.fechaValidez = detalle.fechaValidez;

                    if (vm.verPrecio == 'No') {
                        CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precioRecuperado) {
                            if (precioRecuperado.data != null) {
                                newProducto.precioVentaView = precioRecuperado.data.toString().replace(/\./, ",");
                                newProducto.precioVenta = parseFloat(newProducto.precioVentaView.replace(/,/, "."));
                            }
                        });
                    }
                    vm.comprobantesProductos.push(newProducto);
                    vm.calcularTotalDetalle(newProducto);
                    if ((detalle.producto.criterioControl.codigo == 'series' || detalle.producto.criterioControl.codigo == 'lotes') && !estaEnPV(detalle)) {
                        getStockPorTipoComprobante(detalle);
                    }
                } else if (detalle.servicio) {
                    ServicioFactory.get(
                        detalle.servicio.id,
                        "BaseList"
                    ).$promise.then(function (response) {
                        var newServicio = {
                            montosImponibles: [{
                                tipoImpuesto: response.tipoImpuesto,
                                porcentaje: response.tipoImpuesto.porcentaje,
                                valor: 0
                            }],
                            servicio: detalle.servicio,
                            cantidad: detalle.cantidad,
                            tipo: vm.tiposDetalle[1],
                            precioVenta: detalle.precioVenta
                        };

                        vm.comprobantesServicios.push(newServicio);
                        vm.calcularTotalDetalle(newServicio);
                    });
                }

            });
        });
        copiarComprobantes();
        recuperarPreciosMinimosMaximos();
    }

    var monedaRenderDosDecimales = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.moneda.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }
            return (
                moneda +
                data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else return "";
    };
    function activate() {
        vm.isFacturacionAgil = false;
        vm.factoryComprobantes = ComprobanteVentaFactory;
        vm.factoryMovimientoStock = MovimientoStockFactory;
        vm.staticFilterComprobantes = [];
        vm.staticFilterComprobantes.push({
            path: 'tipo.clase.codigo',
            equals: 'factura'
        });
        vm.staticFilterComprobantes.push({
            path: "estado.codigo",
            equals: "comp_venta_emitido"
        });
        var staticFilterFinal = {
            search: filterFactory.and(vm.staticFilterComprobantes).value()
        };
        var defaultColumnOrder = [
            "numero",
            "fecha",
            "cliente.nombre",
            "montoTotal",
            "saldo"
        ];
        vm.opcionesRelacionarFacturas = {
            staticFilter: staticFilterFinal,
            resource: "comprobanteventa",
            title: "Comprobante Venta",
            view: "ComprobanteVentaList",
            factory: ComprobanteVentaFactory,
            defaultColumnOrder: defaultColumnOrder,
            columns: [{
                data: "id",
                title: $filter("translate")("CODE"),
                class: "dt-center",
                type: "number-range"
            },
            {
                data: "numero",
                title: $filter("translate")("NUMBER"),
                class: "dt-center"
            },
            {
                data: "fecha",
                title: $filter("translate")("DATE"),
                renderWith: 'dateRender',
                dateFormat: 'DD/MM/YYYY',
                class: "dt-center",
                type: "date-range"
            },
            {
                data: "cliente.nombre",
                title: "Cliente",
                class: "dt-center"
            },
            {
                data: "montoTotal",
                title: $filter("translate")("TOTAL_AMOUNT"),
                class: "dt-right",
                render: monedaRenderDosDecimales,
                type: "number-range"
            },
            {
                data: "saldo",
                title: $filter("translate")("SALDO"),
                class: "dt-right",
                render: monedaRenderDosDecimales,
                type: "number-range"
            }
            ],
            hasOptions: false,
            defaultOrderColumn: 2,
            defaultOrderDir: "desc"
        };

        var emptyDataRender = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        var defaultColumnOrder = ['id', 'numero', 'numeroSolicitud', 'descripcion', 'transaccion.descripcion', 'fecha','depositoOrigen.descripcion'];

        vm.optionsMovimientoStock = {
            'resource': 'movimientostock',
            'view': 'OperacionManualList',
            'title': 'Movimientos de Stock',
            'factory': MovimientoStockFactory,
            'defaultColumnOrder': defaultColumnOrder,
            'colReorder': true,
            'columns': [
                { 'data': 'id', 'title': 'Código', visible: true },
                { 'data': 'numero', 'title': 'Numero', renderWith: emptyDataRender },
                { 'data': 'numeroSolicitud', 'title': 'Nro. Solicitud', renderWith: emptyDataRender },
                { 'data': 'descripcion', 'title': 'Descripción', renderWith: emptyDataRender },
                { 'data': 'transaccion.descripcion', 'title': 'Transacción', renderWith: emptyDataRender },
                { 'data': 'fecha', 'title': 'Fecha', 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
                { 'data': 'depositoOrigen.descripcion', 'title': 'Almacén', renderWith: emptyDataRender }
            ],
            'isSelectable': true,
            'selection': {},
            'hasOptions': true,
            'hideEditMenu': true,
            'hideRemoveMenu': true,
            'hideViewMenu': true,
            'defaultOrderColumn': 1,
            'defaultOrderDir': "desc",
            'hideAddMenu': true,
            'filters': {},
            'extraMenuOptions':
                [
                    {
                        'data': "Recuperar Items de Movimiento de Stock",
                        'title': 'Recuperar Items de Movimiento de Stock',
                        'icon': 'glyphicon glyphicon-check',
                        'showCondition': function () {
                            if (vm.view) {
                                return false;
                            } else {
                                return true;
                            }
                        },
                        'action': function () {
                            if (getIDs().length === 0)
                                notify({ message: "Debe seleccionar al menos un detalle.", classes: 'alert-warning', position: 'right' });
                            else {
                                recuperarItemsMovimientoStockParaNc();
                            }
                        }
                    }
                ]
        };

        vm.habilitarTimbradoSelect = false;
        // Se verifica si es un flujo de ventas
        var venta = VentaFactory.getVenta();
        if (venta != undefined) {
            // Si NO tiene el permiso, mostrar mensaje
            if (!AuthorizationService.hasPermission("cant_mayor_pedido_venta") && venta.pedidoVenta) {
                var msg = $filter("translate")("CANT_MAYOR_PEDIDO_WARNING");
                notify({ message: msg, classes: "alert-warning", position: "right" });
            }
        }

        ParametrosFactory.getByCodigo('incluir_producto_not_lista_precio').then(function (parametro) {
            vm.permitirProductosNotListaPrecio = parametro.valor
        });

        // Recupera parametro para mostrar o no campo "precio"
        ParametrosFactory.getByCodigo('ver_precio_pedido').then(function (parametro) {
            vm.verPrecio = parametro.valor
        });

        //Recupera parametros para manejar los registros de ultimoCosto
        ParametrosFactory.getByCodigo('tipo_administracion_costo').then(function (response) {
          vm.parametroAdministracionCosto = response.valorTipo.codigo;
        });
        ParametrosFactory.getByCodigo('administrar_costo').then(function (response) {
          vm.parametroAdministrarCostoPor = response.valorTipo.codigo;
        });

        ParametrosFactory.filterByCodigo("formato_nro_comprobante").$promise.then(function (response) {
            vm.formatoComprobante = response[0].valor;
        });

        ParametrosFactory.getByCodigo('generacion_factura_electronica').then(function (response) {
            vm.datoVirtual = false;
            if (response.valorTipo.codigo === "si") {
                vm.datoVirtual = true;
            }
        });

        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            vm.habilitarFacturaRemision = false;
            if (response.valorTipo.codigo === "si") {
                vm.habilitarFacturaRemision = true;
            }
        });

        ParametrosFactory.getByCodigo('utilizar_centro_costo_ventas').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.utilizarCentroCostos = false;
            } else {
                vm.utilizarCentroCostos = true;
            }
        });
        ParametrosFactory.getByCodigo('centro_costo_obligatorio').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.centroCostoObligatorio = false;
            } else {
                vm.centroCostoObligatorio = true;
            }
        });

        ParametrosFactory.getByCodigo('relacionar_factura_nota_credito').then(function (response) {
            vm.habilitarRelacionarFactura = false;
            if (response.valorTipo.codigo === "si") {
                vm.habilitarRelacionarFactura = true;
            }
        });

        ParametrosFactory.getByCodigo('cantidad_factura_relacionar_nota_credito').then(function (parametro) {
            if (parametro.valor > 0 && parametro.valor < 11) {
                vm.cantidadFacturaRelacionar = parametro.valor
            } else if (parametro.valor > 10) {
                vm.cantidadFacturaRelacionar = 10
            } else if (parametro.valor < 1) {
                vm.cantidadFacturaRelacionar = 1
            }
        });

        ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
          vm.proyectoObligatorio = r.valorTipo.codigo == "si";
        });

        var filtroConcepto = {};
        filtroConcepto.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();
        filtroConcepto.view = "ComprobanteVentaForm";
        vm.conceptoMovimientos = ConceptoMovimientoFactory.all(filtroConcepto);

        var filtroVendedor = {};
        filtroVendedor.search = filterFactory.and([
            {
                path: 'activo',
                like: 'true'
            },
            {
                path: 'vendedor',
                like: 'true'
            }

        ]).value();
        filtroVendedor.view = "VendedorList";
        vm.vendedores = [];
        VendedorFactory.all(filtroVendedor).$promise.then(function (response) {
            _.forEach(response, function (detalle) {
                for (var i = 0; i < detalle.locaciones.length; i++) {
                    if ($rootScope.AuthParams.locacionSeleccionada && detalle.locaciones[i].locacion.id == $rootScope.AuthParams.locacionSeleccionada.id) {
                        vm.vendedores.push(detalle);
                        break;
                    }
                }
            })
        });

        TiposFactory.motivoTraslado().then(function (data) {
            vm.motivosTraslado = data.data;
        });

        var filtroServicios = {};
        filtroServicios.search = filterFactory.and([{
            path: 'clase.codigo',
            equals: 'forma_pago_referencia'
        }]).value();
        vm.promiseFormaPago = [];
        vm.promiseFormaPago.push(FormaPagoFactory.all(filtroServicios).$promise.then(function (data){
          vm.formasPagos = data;
          vm.formasPagosAux = angular.copy(data);
        }));
        var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();
        var filtroChofer = {};
        filtroChofer.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }, {
            path: 'transportista.id',
            isNotNull: "true"
        }]).value();
        vm.choferes = ChoferFactory.all(filtroChofer, "PlanillaRepartoForm");
        vm.camiones = CamionFactory.all(filtroActivo, "PlanillaRepartoForm");

        TiposFactory.ventaTipoOperacion().then(function (data) {
            vm.tipoOperaciones = data.data;
        });

        TiposFactory.tipoTransaccion().then(function (data) {
            vm.tipoTransacciones = data.data;
        });

        TiposFactory.motivoEmision().then(function (data) {
            vm.motivosEmisiones = data.data;
        });

        TiposFactory.tipoAnticipo().then(function (data) {
            vm.tipoAnticipos = data.data;
        });

        TiposFactory.condicionNegociacion().then(function (data) {
            vm.condicionesNegociacion = data.data;
        });

        TiposFactory.paisDestino().then(function (data) {
            vm.paisesDestino = data.data;
        });

        TiposFactory.operacionComprobante().then(function (data) {
            vm.operacionesComprobante = data.data;
        });

        TiposFactory.administraciones('modo_exoneracion_iva').then(function (data) {
            vm.modosExoneracionIva = data.data;
        });


        ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.utilizarPrecioBase = false;
            } else {
                vm.utilizarPrecioBase = true;
            }
        });

        ParametrosFactory.getByCodigo("recuperar_remision_facturada").then(function (response) {
            vm.recuperarRemisionFacturada = response.valor
        });

        //*** centro de costos */
        var paramsCentroCostos = {};
        paramsCentroCostos.search = filterFactory
            .and({
                path: "activo",
                equals: true
            }, {
                path: "descripcion",
                sortAsc: true
            }).value();

        paramsCentroCostos.view = "BaseList"

        vm.centrocostosList = CentroCostosFactory.all(paramsCentroCostos);

        vm.permisoAsignarListaPrecioVenta = AuthorizationService.hasPermission("asignar_listaprecioventa");

        vm.permisoEditarPrecioVenta = AuthorizationService.hasPermission("update_precio_venta_comp");

        vm.permisoEditarPrecioVentaNotaCredito = AuthorizationService.hasPermission("modificar_precio_nc");

        vm.permisoEditarPrecioFromLista = AuthorizationService.hasPermission("modificar_precio_min_max_lp_cmp");

        vm.permisoModificarPrecioAlmacenSelec = AuthorizationService.hasPermission("modificar_precio_almac_selec_cmp");

        vm.permisoFacturacionAgil = AuthorizationService.hasPermission("comprobanteventaagil");

        vm.permisoEditarDescuento = AuthorizationService.hasPermission("asignar_descuento_comprobanteventa");

        vm.permisoModificarCentroCostos = AuthorizationService.hasPermission("modificar_centro_costo_comprobanteventa");
        
        if( !vm.permisoModificarCentroCostos){
            vm.bloquearCentroCostos = true;
        }


        WatchLocacionChangeFactory.killAllWatchers();
        vm.ComprobanteForm = $scope.ComprobanteForm;
        VentasLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            vm.cotizacionRequerida = false;
            vm.valorFOBRequerido = false;
            var venta = undefined;
            var staticFilter = undefined;
            TiposFactory.tiposSuscripcionPeriodicidad().then(function (data){
                $scope.tiposPeriodicidad = data.data;
            });

            if ($state.includes("app.ventas.proceso.comprobanteventa")) {
                vm.isProcesoVenta = true;
                vm.fromOutside = false;
                venta = VentaFactory.getVenta();
                vm.venta = venta;

                vm.remisiones = vm.venta.remisiones;

                ParametrosFactory.getByCodigo("recuperar_remision_facturada").then(function (response) {
                    vm.recuperarRemisionFacturada = response.valor
                    vm.remisiones = vm.venta.remisiones.map(function (remision) {
                        if (remision.estado.codigo != 'estados_remision_anulado' && response.valor == 'Sí') {
                            return remision;
                        } else if (remision.estado.codigo != 'estados_remision_anulado' && response.valor == 'No' && remision.facturado === false) {
                             return remision;
                        }
                    });
                });

                vm.remisiones = vm.venta.remisiones.map(function (remision) {
                    if (remision.estado.codigo != 'estados_remision_anulado') {
                        return remision;
                    }
                });


                if (!venta) {
                    $state.go("app.main");
                }
                var tipo = venta.tipo;
                var tipoVentaDetalle = _.find(tipo.tipoVentaDetalles, function (
                    detalle
                ) {
                    return detalle.componente.codigo == "comp_tipo_venta_comprobante";
                });

                if (tipoVentaDetalle.realizaEgresoStock == true) {
                    vm.comprobanteGeneraMovimiento = true;
                }
                if (!tipoVentaDetalle) {
                    $state.go("app.main");
                }
                var isStateEnabled = VentaFactory.checkPermissionForState(
                    tipoVentaDetalle.orden - 1
                );
                if (!isStateEnabled) {
                    $state.go("app.main");
                }

                staticFilter = {};
                var staticFilterPV = {};
                staticFilterPV.search = filterFactory
                    .and([{
                        path: "estado.codigo",
                        equals: "pv_aprobado"
                    },
                    {
                        path: "ventas.id",
                        equals: venta.id
                    }
                    ])
                    .value();
            } else if (
                $state.is("app.consultacomprobantesventa.view") ||
                $state.is("app.consultacomprobantesventa.new") ||
                $state.is("app.consultacomprobantesventa.edit")
            ) {
                vm.fromOutside = true;
                vm.isProcesoVenta = false;
                vm.comprobanteGeneraMovimiento = true; //Los comprobantes fuera de flujo generan movimiento de stock
            }
            else if($state.includes("app.suscripcionrecurrente.proceso")){

                vm.fromOutside = false;
                vm.isProcesoVenta = false;
                vm.comprobanteGeneraMovimiento = true;
                vm.suscripcionRecurrente = suscripcionRecurrenteFactory.getSuscripcion();
                agendaSuscripcionRecurrenteFactory.setIdSuscripcion(suscripcionRecurrenteFactory.getIdSuscripcion());
                agendaSuscripcionRecurrenteFactory.setSuscripcion(suscripcionRecurrenteFactory.getSuscripcion());
                vm.formSuscripcionRecurrente = true;
            }else if($state.is("app.comprobanteventaagil.new")){
                if(!vm.permisoFacturacionAgil){
                    notify({ message: $filter("translate")("FORBIDDEN_ROUTE") , classes: 'alert-danger', position: 'right' });
                    $state.go("app.main");
                }
                vm.comprobanteGeneraMovimiento = true; //Los comprobantes fuera de flujo generan movimiento de stock
            }

            /* var filtroActivo = {};
            filtroActivo.search = filterFactory.and([{
                path: 'activo',
                like: 'true'
            }]).value();
            filtroActivo.view = "ConsultaComprobanteVentaForm";

            ClienteFactory.all(filtroActivo).$promise.then(function (response) {
                if (!vm.fromOutside && vm.venta.cliente != undefined && vm.venta.cliente != null) {
                    vm.venta.cliente = _.find(response, function (c) {
                        return c.id == vm.venta.cliente.id;
                    });
                    //vm.comprobante.cliente = vm.venta.cliente;
                }
                vm.clientes = response; */

            var params = {};
            params.search = filterFactory
                .single({
                    path: "codigo",
                    equals: "moneda_local"
                })
                .value();

            var params2 = {};
            params2.search = filterFactory
                .and([{
                    path: "activo",
                    equals: true
                }])
                .value();
            params.view = "Base";
            params2.view = "Base";

            vm.monedaLocal = ParametrosFactory.all(params);
            vm.transacciones = TransaccionStockFactory.all(params2);

            var staticFilterSC = {};
            staticFilterSC.search = filterFactory
                .and([{
                    path: "estado.codigo",
                    equals: "sc_aprobada"
                }])
                .value();

            if (
                $state.is("app.ventas.proceso.comprobanteventa.new") ||
                $state.is("app.consultacomprobantesventa.new") ||
                $state.is("app.suscripcionrecurrente.proceso.comprobanteventa.new")
            ) {
                activateNew();
            } else if (
                $state.is("app.ventas.proceso.comprobanteventa.edit") ||
                $state.is("app.consultacomprobantesventa.edit") ||
                $state.is("app.suscripcionrecurrente.proceso.comprobanteventa.edit")
            ) {
                activateEdit();
            } else if (
                $state.is("app.ventas.proceso.comprobanteventa.view") ||
                $state.is("app.consultacomprobantesventa.view") ||
                $state.is("app.suscripcionrecurrente.proceso.comprobanteventa.view")
            ) {
                activateView();
            } else if($state.is("app.comprobanteventaagil.new")){
                vm.isFacturacionAgil = true;
                activateNewAgil();
            }
            if (vm.watchPV) {
                vm.watcherPV();
            }
            /* WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcherPV = watcher;
                vm.pedidosVenta = PedidoVentaFactory.all(
                    UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        staticFilterPV
                    ),
                    "BaseList"
                );
            }, "locacion.id"); */

            vm.curDate = new Date();

            vm.factoryProductos = ProductosFactory;

            var renderEmptyData = function (data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            var defaultColumnOrderProducto = [
                "codigo",
                "nombre",
                "claseProducto.descripcion",
                "estado.descripcion",
                "lineaProducto.nombre",
                "marca.descripcion"
            ];

            vm.opcionesProductosDt = {
                resource: "productos",
                title: "Productos",
                factory: ProductosFactory,
                view: "ProductosDatatableList",
                defaultColumnOrder: defaultColumnOrderProducto,
                view: "ProductoList",
                columns: [
                    { data: "codigo", title: $filter("translate")("ID"), visible: false },
                    { data: "nombre", title: $filter("translate")("NOMBRE") },
                    { data: "claseProducto.descripcion", title: translations.KIND },
                    {
                        data: "estado.descripcion",
                        title: translations.STATUS,
                        type: "combo",
                        filterUrl: "tipos/tipo/producto_estados"
                    },
                    { data: "lineaProducto.nombre", title: translations.LINE, render: renderEmptyData },
                    {
                        data: "marca.descripcion",
                        title: translations.BRAND,
                        render: renderEmptyData
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc"
            };

            vm.factoryServicio = ServicioFactory;
            var defaultColumnOrderServicio = [
                "id",
                "codigo",
                "descripcion",
                "tipoImpuesto.descripcion"
            ];

            vm.opcionesServiciosDt = {
                resource: "servicios",
                title: "Servicio",
                view: "ServiciosDatatableList",
                factory: ServicioFactory,
                defaultColumnOrder: defaultColumnOrderServicio,
                columns: [
                    { data: "id", title: translations.CODE },
                    { data: "descripcion", title: translations.DESCRIPTION },
                    {
                        data: "tipoImpuesto.descripcion",
                        title: translations.TIPO_IMPUESTO
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc"
            };
            var filtroServiciosDt = {}
            filtroServiciosDt.search = filterFactory
                .and([{
                    path: 'venta',
                    like: 'true'
                }])
                .value();


            vm.opcionesServiciosDt.staticFilter = filtroServiciosDt;

            if (vm.watcher) {
                vm.watcher();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcher = watcher;

                var filterEstadoItemVenta = {
                    search: filterFactory
                        .and([
                            {
                                path: "estado.codigo",
                                equals: 'activo'
                            },
                            {
                                path: "itemVenta",
                                like: 'true'
                            }

                        ])
                        .value()
                };

                var filter = UtilFactory.createNestedFilters(
                    locacionFilter.search.filters,
                    filterEstadoItemVenta
                );

                if (
                    vm.fromOutside &&
                    vm.comprobante &&
                    vm.comprobante.tipo &&
                    vm.comprobante.tipo.clase.codigo != "nota_credito"
                ) {
                    var filterStockItemVenta = {
                        search: filterFactory
                            .and([{
                                path: "controlStock",
                                equals: false
                            }, {
                                path: "itemVenta",
                                like: 'true'
                            },
                            {
                                path: 'estado.codigo',
                                equals: 'activo'
                            }])
                            .value()
                    };
                    var filter = UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        filterStockItemVenta
                    );
                }

                if (vm.isProcesoVenta) {
                    // Recuperar parametro "Agregar productos que no se encuentran en el pedido"
                    ParametrosFactory.getByCodigo('agregar_productos_fuera_pedido').then(function (parametro) {

                        // Si el parametro tiene valor NO, debe procesarse las opciones (solo productos del pedido)
                        if (parametro.valor == "No" && vm.comprobante.pedidoVenta) {

                            // Realizar bloqueos de campos "Agregar" y "Producto"
                            vm.lockPedidoVenta = true;

                        } else {
                            // Si el parametro posee valor SI, se recuperan los productos como se hacia habitualmente
                            //vm.productos = ProductosFactory.allForSelect(filter, "ConsultaComprobanteVentaForm");
                            vm.opcionesProductosDt.staticFilter = filter;
                        }
                    });
                } else {
                    // Si no es flujo de ventas, recuperar todos
                    //vm.productos = ProductosFactory.allForSelect(filter, "ConsultaComprobanteVentaForm");
                    vm.opcionesProductosDt.staticFilter = filter;
                }

            }, "locaciones.id");

            var filtroServicios = {};
            filtroServicios.search = filterFactory.and([{
                path: 'venta',
                equals: true
            }]).value();

            vm.servicios = ServicioFactory.all(filtroServicios, "ConsultaComprobanteVentaForm");
            var staticFilterTC = {
                search: filterFactory
                    .or([
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "factura"
                            },
                            // Por el pedido de grafitec, se puede cambiar la condicion de cobro,
                            // ya no estira del pedido.
                            /*{
                                path: "condicion.descripcion",
                                equals: vm.venta.pedidoVenta.condicionCobro.descripcion
                            },*/
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "nota_credito"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "nota_credito"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "nota_debito"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "boleta_venta"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "pasaje_aereo"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "pasaje_aereo_electronico"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value()
                    ])
                    .value()
            };

            vm.tipos = TipoComprobanteFactory.all(staticFilterTC, "ConsultaComprobanteVentaForm");


            var paramsGC = {};
            paramsGC.search = filterFactory
                .single({
                    path: "activo",
                    equals: true
                })
                .value();
            GruposCompraFactory.all(paramsGC).$promise.then(function (response) {
                vm.gruposCompra = _.filter(response, function (grupoCompra) {
                    return !(
                        grupoCompra.codigo == "gasto_importacion" ||
                        grupoCompra.codigo == "comprobante_importacion"
                    );
                });
            });

            var staticFilter = {};
            staticFilter.search = filterFactory
                .and([{
                    path: "activo",
                    equals: true
                }]).value();

            TipoImpuestosFactory.all(staticFilter).$promise.then(function (response) {
              vm.tiposImpuesto = response;
              vm.tipoExento = _.filter(response, function (elem) {
                return elem.porcentaje === 0;
              });
            });
            var paramsRubroIva = {};
            paramsRubroIva.search = filterFactory
                .single({
                    path: "descripcion",
                    sortAsc: true
                })
                .value();
            vm.rubrosIva = RubroIvaFactory.all(paramsRubroIva);

            if (vm.watcherAlmacen) {
                vm.watcherAlmacen();
            } //hacemos unwatch de la expresion anterior
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcherAlmacen
            ) {
                vm.watcherAlmacen = watcherAlmacen;
                var filterSucursal = {
                    search: filterFactory
                        .and([
                            {
                                path: "sucursal.id",
                                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                            },
                            {
                                path: "habilitadoVenta",
                                equals: true
                            },
                            {
                                path: "abreviatura",
                                sortAsc: true
                            }]
                        )
                        .value()
                };
                vm.depositos = AlmacenFactory.all(filterSucursal);
                vm.depositos.$promise.then(function (response) {
                    if (response.length) {
                        if (vm.pedidoVenta && vm.pedidosVenta.length > 0) {
                            vm.pedidosVenta.$promise.then(function (response) {
                                vm.comprobante.deposito = response[0].almacen;
                                vm.comprobante.centroCostos = response[0].centroCostos;
                                vm.comprobante.comentario = response[0].comentario;
                            })
                        } else {
                            if (vm.comprobante && !vm.comprobante.deposito) {
                                vm.comprobante.deposito = response[0];
                            }
                        }
                    }
                })
            }, "locacion.id");

            vm.proyectos = ProyectoFactory.all("", "ComprobanteVentaForm");

            if (vm.isProcesoVenta) {
                if (vm.watchPV) {
                    vm.watcherPV();
                }
                WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcher
                ) {
                    vm.watcherPV = watcher;
                    vm.pedidosVenta = PedidoVentaFactory.all(
                        UtilFactory.createNestedFilters(
                            locacionFilter.search.filters,
                            staticFilterPV
                        ),
                        "BaseList"
                    );
                }, "locacion.id");
            }/*  else if(!vm.formSuscripcionRecurrente){
                var staticFilterRecepcion = {};
                staticFilterRecepcion.search = filterFactory
                    .and([{
                        path: "estadoRecepcion.codigo",
                        equals: "er_aprobado"
                    },
                    {
                        path: "comprobante.id",
                        isNull: true
                    } 
                    ])
                    .value();
                if (vm.watcherRecepcion) {
                    vm.watcherRecepcion();
                }
                WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcherRecepcion
                ) {
                    vm.watcherRecepcion = watcherRecepcion;
                    vm.recepciones = RecepcionFactory.all(
                        UtilFactory.createNestedFilters(
                            locacionFilter.search.filters,
                            staticFilterRecepcion
                        ),
                        "BaseList"
                    );
                }, "locacion.id");
            } */
            var staticFilter = {};
            staticFilter.search = filterFactory
                .and([{
                    path: "accion.codigo",
                    equals: "ingreso_almacen"
                }])
                .value();

            vm.monedas = {};
            TiposFactory.monedas().then(function (data) {
                vm.monedas = data.data;
            });
            getParamDiferenciaMonedaLocal();
            getParamDiferenciaMonedaExtranjera();
            /* }); */
        });
        vm.submited = false;
    }

    /* Este observer se activa cuando seleccionamos comprobanes desde el DataTableSearch */
    var obs1 = observeOnScope($scope, "vm.comprobantesFiltrados")
        .filter(function (data) {
            return data.newValue;
        })
        .map(function (data) {
            return data.newValue;
        });

    obs1.subscribe(function (change) {

        _.forEach(change, function (value) {
            value.$promise.then(function (value) {
                vm.comprobante.relacionarFacturas.push(value)
            })
        });

    });

    $scope.$watch('vm.comprobante.relacionarFacturas', function (newVal) {
        if (newVal) {
            for(var i = 0; i < newVal.length; i++){
              if(!newVal[i].comprobanteDetalle){
                obtenerDetallesComprobante(newVal[i].id)
              }
            }
            if (newVal.length > vm.cantidadFacturaRelacionar) {
                newVal.splice(newVal.length - 1, 1);
                var msg = "La cantidad máxima de facturas a relacionar es " + vm.cantidadFacturaRelacionar;
                notify({ message: msg, classes: "alert-danger", position: "right" });
            }
        }
    }, true)

    function activateNewAgil() {
        vm.tiposCobros = TiposTipoFactory.get("tipo_cobro");

        var filterFP = {
            search: filterFactory
                .single({
                    path: "utilizarEnCobros",
                    like: "true"
                })
                .value()
        };
        vm.formasPago = FormaPagoFactory.all(filterFP);
        vm.cuentasContables = CuentaContableFactory.all();

        var filterEB = {
            search: filterFactory
                .single({
                    path: "activo",
                    like: "true"
                })
                .value()
        };

        var filterFP = {
            search: filterFactory
                .single({
                    path: "utilizarEnCobros",
                    like: "true"
                })
                .value()
        };
        vm.formasPagoCobro = FormaPagoFactory.all(filterFP);

        vm.denominacionesTarjetas = DenominacionTarjetaFactory.all(filterEB);

        var proveedorFilter = {
            search: filterFactory.and([{
                path: "activarProcesadora",
                equals: true
            }]).value()
        }
        vm.tiposTarjeta = TiposTipoFactory.get("tipos_tarjetas");

        vm.tiposCobroEntidad = TipoCobroFactory.all(filterEB);

        vm.proveedores =  ProveedoresFactory.all(proveedorFilter);

        TiposFactory.tiposProcesamientosDetallesCobros().then(function(datos){
            vm.tiposProcesamientos = datos.data;
            var origenManual = _.find(vm.tiposProcesamientos, function(procesamiento){
            if(procesamiento.codigo === 'tipo_procesamiento_pos'){
                vm.auxProcesamiento = procesamiento;

            }
          });
        });
        vm.tiposCobros = TiposTipoFactory.get("tipo_cobro");

        vm.entidadesBancarias = EntidadBancariaFactory.all(filterEB);
        vm.hasPermisoObligaciones = AuthorizationService.hasPermission("update_obligaciones_asociadas_ventas");
        vm.new = true;
        vm.title = $filter("translate")("NEW_COMPROBANTE_VENTA");
        vm.tiposDetalle = {};
        vm.totalImpuestosGeneral = 0;
        vm.comprobante = {};
        vm.comprobante.fecha = new Date();

       
        
        TiposFactory.tipoTransaccion().then(function (data) {
            vm.tipoTransacciones = data.data;
            _.forEach(vm.tipoTransacciones, function (tt) {
                if ('venta_mercaderia' === tt.codigo) {
                    vm.comprobante.tipoTransaccion = tt;
                }
            });    
        });
       
        var staticFilterParametros = {};
        staticFilterParametros.search = filterFactory.or([{
            path: 'codigo',
            equals: 'imputa_iva'
        }, {
            path: 'codigo',
            equals: 'imputa_ire'
        }, {
            path: 'codigo',
            equals: 'imputa_irp_rsp'
        }, {
            path: 'codigo',
            equals: 'imputa_iva'
        },{
            path: 'codigo',
            equals: 'recuperar_total_depositar_apertura'
        }]).value();;

        
        staticFilterParametros.view = "BaseList";

        ParametrosFactory.all(staticFilterParametros).$promise.then(function (response) {
            response.forEach(function (param) {
                switch (param.codigo) {
                    case "imputa_iva":
                        vm.comprobante.imputarIvaGeneral = (param.valor === "Sí");
                        break;
                    case "imputa_ire":
                        vm.comprobante.imputarIre = (param.valor === "Sí");
                        break;
                    case "imputa_irp_rsp":
                        vm.comprobante.imputarIrpRsp = (param.valor === "Sí");
                        break;
                    case "recuperar_total_depositar_apertura":
                        vm.recuperarTotalDepositarApertura = (param.valor === "Sí");
                        break;
                }
            });
            TiposFactory.tiposDetalleComprobante().then(function (data) {
                vm.tiposDetalle = data.data;
                vm.comprobante.comprobanteDetalle = [];
                vm.comprobante.cambio = 1;
                vm.comprobante.montosImponibles = [{}];
                vm.tipoCom = false;
    
                getMonedaLocal(function () {
                    var staticFilterComprobanteVentas = {
                        search: filterFactory
                            .and([{
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },{
                                path: "clase.codigo",
                                equals: "factura"
                            },{
                                path: "factAgilDefault",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value()
                    };
                    TipoComprobanteFactory.all(staticFilterComprobanteVentas, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                        vm.tipos = undefined;
                        if (response.length > 0) {
                            vm.tipos = response;
                            _.forEach(response, function (tc) {
                                if (tc.condicion.codigo == 'tipo_comprobante_contado') {
                                    vm.comprobante.tipo = tc;
                                }
                            });
                            if(!vm.comprobante.tipo){
                                vm.comprobante.tipo = response[0];
                            }
                            var filtroCliente = {};
                            filtroCliente.search = filterFactory.and([{
                                path: 'activo',
                                equals: true
                            }, {
                                path: 'factAgilDefault',
                                equals: true,
                    
                            }]).value();
                    
                            recuperarTimbradoFacturacionAgil($scope.caja);
                            ClienteFactory.all(filtroCliente).$promise.then(function (responseCliente) {
                                if (responseCliente.length > 0) {
                                    vm.comprobante.cliente = responseCliente[0];
                                    var suc = [];
                                    for (var i = 0; i < vm.comprobante.cliente.sucursales.length; i++) {
                                        if (vm.comprobante.cliente.sucursales[i].activo === true) {
                                            suc.push(vm.comprobante.cliente.sucursales[i])
                                        }
                                    }
                                    vm.comprobante.sucursalCliente = suc[0];
                                    vm.comprobante.vendedor = vm.comprobante.sucursalCliente.vendedor;
                                    vm.comprobante.tipoOperacion = vm.comprobante.cliente.tipoOperacion;
                                    if(vm.comprobante.tipo.generarCuotas && vm.comprobante.cliente.formaPago) {
                                        vm.cantidadCuotas = vm.comprobante.cliente.formaPago.cantidadCuotas;
                                        vm.plazoCuotas = vm.comprobante.cliente.formaPago.plazoDias;
                                    }
                                    recuperarListaPrecio();
                                    vm.changeCotizacion(vm.comprobante.moneda);
                                }
                            });
                        } else {
                            var msg = "No se encontraron Tipos de Comprobantes para la Facturación Ágil";
                            notify({ message: msg, classes: "alert-danger", position: "right" });
                            $scope.ComprobanteForm["tipo"].$setValidity("error", false);
                            $scope.ComprobanteForm["tipo"].$error.pattern = true;
                            $scope.ComprobanteForm["tipo"].$touched = true;
                        }
                    });
                    
                });
                vm.comprobantesProductos = [];
                vm.comprobantesServicios = [];
                agregarDetalleProducto();
                agregarDetalleServicio();
            });
            vm.totalGeneralProductos = 0;
            vm.totalGeneralServicios = 0;
            vm.totalGeneral = 0;
            $timeout(function () {
                $scope.$broadcast("formOpened");
            }, 20);
    
            vm.mostrarCambioPactado = false;
            addNewProcesoCobro();
        });
    }


    function activateNew() {
        vm.hasPermisoVendedor = AuthorizationService.hasPermission("modificar_vendedor");
        vm.hasPermisoObligaciones = AuthorizationService.hasPermission("update_obligaciones_asociadas_ventas");
        vm.new = true;
        abortRouteIfNecessary();
        if (vm.isProcesoVenta === true && !ComprobanteVentaFactory.hasPermisos(false, "new")) {
            $state.go("app.main");
        }
        vm.title = $filter("translate")("NEW_COMPROBANTE_VENTA");
        vm.tiposDetalle = {};
        vm.totalImpuestosGeneral = 0;

        vm.comprobante = {};
        vm.comprobante.fecha = new Date();
        vm.comprobante.primeraFacturacion = new Date();
        vm.comprobante.respetarDias = false;
        vm.comprobante.relacionarFacturas = [];

        ParametrosFactory.filterByCodigo("imputa_iva").$promise.then(function (response) {
            if (response[0].valor == "Sí") {
                vm.comprobante.imputarIvaGeneral = true;
            } else if (response[0].valor == "No") {
                vm.comprobante.imputarIvaGeneral = false;
            }
        })
        ParametrosFactory.filterByCodigo("imputa_ire").$promise.then(function (response) {
            if (response[0].valor == "Sí") {
                vm.comprobante.imputarIre = true;
            } else if (response[0].valor == "No") {
                vm.comprobante.imputarIre = false;
            }
        })
        ParametrosFactory.filterByCodigo("imputa_irp_rsp").$promise.then(function (response) {
            if (response[0].valor == "Sí") {
                vm.comprobante.imputarIrpRsp = true;
            } else if (response[0].valor == "No") {
                vm.comprobante.imputarIrpRsp = false;
            }
        })
        ParametrosFactory.getByCodigo("recuperar_items_comprobante_asociado").then(function (r) {
        vm.recuperarItemsComprobanteAsociado = r.valorTipo.codigo === "si";
      });

        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;

            vm.comprobante.comprobanteDetalle = [];
            vm.comprobante.cambio = 1;

            if (vm.isProcesoVenta) {
                vm.comprobante.venta = VentaFactory.getIdVenta();
            }
            vm.comprobante.montosImponibles = [{}];

            vm.tipoCom = false;

            getMonedaLocal(function () {

                if (vm.venta && vm.venta.pedidoVenta) {
                    //vm.tipoCom = true;

                    if (vm.venta.pedidoVenta.condicionCobro.codigo === "condicion_cobro_pedido_venta_credito") {
                        var staticFilterCredito = {
                            search: filterFactory
                                .or([
                                    filterFactory
                                        .and([{
                                            path: "clase.codigo",
                                            equals: "factura"
                                        },
                                        {
                                            path: "utilizarEnProcesosVenta",
                                            equals: true
                                        },
                                        {
                                            path: "condicion.codigo",
                                            equals: "tipo_comprobante_credito"
                                        },
                                        {
                                            path: "activo",
                                            like: "true"
                                        }
                                        ])
                                        .value()
                                ])
                                .value()
                        };
                        TipoComprobanteFactory.all(staticFilterCredito, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                            vm.comprobante.tipo = response[0];
                        });
                        if (vm.venta.cliente.formaPago) {
                            vm.cantidadCuotas = vm.venta.cliente.formaPago.cantidadCuotas;
                            vm.plazoCuotas = vm.venta.cliente.formaPago.plazoDias;
                        } else {
                            if (vm.venta.pedidoVenta.formaPago) {
                                vm.cantidadCuotas = vm.venta.pedidoVenta.formaPago.cantidadCuotas;
                                vm.plazoCuotas = vm.venta.pedidoVenta.formaPago.plazoDias;
                            }
                        }
                    } else if (vm.venta.pedidoVenta.condicionCobro.codigo === "condicion_cobro_pedido_venta_contado") {
                        var staticFilterContado = {
                            search: filterFactory
                                .or([
                                    filterFactory
                                        .and([{
                                            path: "clase.codigo",
                                            equals: "factura"
                                        },
                                        {
                                            path: "utilizarEnProcesosVenta",
                                            equals: true
                                        },
                                        {
                                            path: "condicion.codigo",
                                            equals: "tipo_comprobante_contado"
                                        },
                                        {
                                            path: "activo",
                                            like: "true"
                                        }
                                        ])
                                        .value()
                                ])
                                .value()
                        };
                        TipoComprobanteFactory.all(staticFilterContado, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                            vm.comprobante.tipo = response[0];
                        });
                    }
                    // Al tener pedidoVenta se bloquea el campo cliente
                    vm.comprobante.pedidoVenta = vm.venta.pedidoVenta;
                    vm.comprobante.cliente = vm.venta.cliente;
                    vm.comprobante.listaPrecioVenta = vm.venta.pedidoVenta.listaPrecioVenta;
                    vm.comprobante.vendedor = vm.venta.pedidoVenta.vendedor;
                    vm.comprobante.formaPago = vm.venta.pedidoVenta.formaPago;
                    vm.comprobante.centroCostos = vm.venta.pedidoVenta.centroCostos;
                    vm.comprobante.comentario = vm.venta.pedidoVenta.comentario;
                    vm.comprobante.proyecto = vm.venta.pedidoVenta.proyecto;


                    if (!vm.fromOutside && vm.venta.pedidoVenta.listaPrecioVenta != null) {
                        if (vm.comprobante.listaPrecioVenta) {
                            vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                            PedidoVentaFactory.get(
                                vm.venta.pedidoVenta.id,
                                "PedidoVentaForm"
                            ).$promise.then(function (response) {
                                vm.comprobante.pedidoVenta = response;
                                vm.comprobante.listaPrecioVenta = vm.comprobante.pedidoVenta.listaPrecioVenta;
                                vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                            });
                        }

                    }
                    vm.precioBase = "Precio de Venta";
                    vm.porcRecargo = 0;
                    if (vm.comprobante.listaPrecioVenta && vm.utilizarPrecioBase && vm.comprobante.listaPrecioVenta.precioBase) {
                        vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                        vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                    }

                    PedidoVentaFactory.get(
                        vm.venta.pedidoVenta.id,
                        "PedidoVentaList"
                    ).$promise.then(function (response) {
                        vm.comprobante.pedidoVenta = response;
                        vm.comprobante.moneda = response.moneda;
                        vm.comprobante.cambio = response.cambio;
                        vm.currencyDisabled = true;

                        if (vm.comprobante.listaPrecioVenta && vm.utilizarPrecioBase) {
                            vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                            vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                            vm.comprobante.moneda = vm.comprobante.listaPrecioVenta.moneda;
                            vm.bloquearLista = true;
                        }
                        vm.getListasPreciosVentasByMoneda();
                        changePV();
                    });
                } else if (vm.venta && vm.venta.cliente) {
                    vm.comprobante.cliente = vm.venta.cliente;
                    vm.comprobante.sucursalesCliente = vm.venta.cliente.sucursales;
                    vm.uiBlockuiConfig.bloquear = true;
                    changeCliente();
                } else if(!vm.formSuscripcionRecurrente){
                    var staticFilterComprobanteVentas = {
                        search: filterFactory
                            .and([{
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            }
                            ])
                            .value()
                    };
                    /*TipoComprobanteFactory.all(staticFilterComprobanteVentas, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                        vm.comprobante.tipo = response[0];
                    });*/
                }else{
                    vm.comprobante.cliente = vm.suscripcionRecurrente.cliente;
                    vm.comprobante.sucursalCliente = vm.suscripcionRecurrente.sucursalCliente;
                    vm.comprobante.vendedor = vm.comprobante.sucursalCliente.vendedor;
                    vm.comprobante.fechaInicial = moment(vm.suscripcionRecurrente.fechaInicial, "DD/MM/YYYY").toDate();
                    vm.comprobante.fechaFinal = moment(vm.suscripcionRecurrente.fechaFinal, "DD/MM/YYYY").toDate();;
                    vm.comprobante.primeraFacturacion = moment(vm.suscripcionRecurrente.fechaInicial, "DD/MM/YYYY").toDate();
                    vm.comprobante.moneda = vm.suscripcionRecurrente.moneda;
                    vm.comprobante.formaPago = vm.comprobante.cliente.formaPago;
                    vm.habilitarTimbradoSelect = true;
                    changeCliente();

                }
            });
            //vm.comprobante.cambio=1;
            vm.comprobantesProductos = [/*{
             montosImponibles: [{}],
             tipo: getTipoProducto(vm.tiposDetalle)
             }*/];
            vm.comprobantesServicios = [/*{
             montosImponibles: [{}],
             tipo: vm.tiposDetalle[1]
             }*/];
        });
        vm.totalGeneralProductos = 0;
        vm.totalGeneralServicios = 0;
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);

        vm.transacciones.$promise.then(function (response) {
        });
        getPuntosEmisionVenta();
        filterTimbrado();
        vm.mostrarCambioPactado = false;

        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                SucursalesFactory.get($rootScope.AuthParams.locacionSeleccionada.sucursal.id,
                    "SucursalList"
                ).$promise.then(function (response) {
                    vm.comprobante.direccionOrigen = response.direccion;
                });
                //traslado venta
                TiposFactory.motivoTraslado().then(function (data) {
                    _.forEach(data.data, function (pp) {
                        if (pp.codigo == 'traslado_venta') {
                            vm.comprobante.motivoTranslado = pp;
                        }
                    });
                });
            }
        });

    }

    function setCantidadMax() {
        // Se recupera detalles del pedido para setear campo 'cantMaxima'
        PedidoVentaFactory.get(
            vm.venta.pedidoVenta.id,
            "PedidoVentaForm"
        ).$promise.then(function (response) {
            _.forEach(vm.comprobante.comprobanteDetalle, function (detalleComprobante) {
                if (detalleComprobante.producto != null) {
                    _.forEach(response.pedidoVentaDetalle, function (detallePedido) {
                        if (detallePedido.producto != null) {
                            if (detalleComprobante.producto.id == detallePedido.producto.id) {
                                detalleComprobante.cantMaxima = detallePedido.cantidad;
                                detalleComprobante.cantidadView = detalleComprobante.cantidad;
                            }
                        }
                    });
                }
            });
        });
    }

    function activateEdit() {
        vm.hasPermisoVendedor = AuthorizationService.hasPermission("modificar_vendedor");
        vm.hasPermisoObligaciones = AuthorizationService.hasPermission("update_obligaciones_asociadas_ventas");
        abortRouteIfNecessary();
        vm.title = $filter("translate")("EDIT_COMPROBANTE_VENTA");
        vm.tiposDetalle = {};
        vm.tipoCom = false;
        if (vm.venta && vm.venta.pedidoVenta) {
            vm.tipoCom = true;
        }

        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;
            vm.comprobante = comprobantePrepService;
            Promise.all(vm.promiseFormaPago)
              .then(function() {
                filterFormasPago();
              });
              vm.existeAnticipo = false;
              if(vm.comprobante.comprobanteAnticipo){
                  vm.existeAnticipo = true;
              }
            if (vm.comprobante.cliente) {
                vm.comprobante.relacionarFacturas = vm.comprobante.relacionarFacturas ? vm.comprobante.relacionarFacturas : [];
                vm.staticFilterComprobantes = [];
                vm.staticFilterComprobantes.push({
                    path: 'tipo.clase.codigo',
                    equals: 'factura'
                });
                vm.staticFilterComprobantes.push({
                    path: "estado.codigo",
                    equals: "comp_venta_emitido"
                });
                vm.staticFilterComprobantes.push({
                    path: "cliente.id",
                    equals: vm.comprobante.cliente.id
                });
                vm.opcionesRelacionarFacturas.staticFilter = {
                    search: filterFactory.and(vm.staticFilterComprobantes).value()
                };

            }
            //changeCliente();
            if (vm.comprobante.listaPrecioVenta && vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                vm.mostrarCambioPactado = true;
            }

            var suc = [];
            for (var i = 0; i < vm.comprobante.cliente.sucursales.length; i++) {
                if (vm.comprobante.cliente.sucursales[i].activo === true) {
                    suc.push(vm.comprobante.cliente.sucursales[i])
                }
            }
            vm.sucursalesCliente = suc;
            if (vm.isProcesoVenta === true &&
                //!ComprobanteVentaFactory.hasPermisos(false, "edit", vm.comprobante.estado)
                !ComprobanteVentaFactory.hasPermisos(false, "edit", vm.comprobante.estado, null, null, null, null, vm.comprobante.venta)
            ) {
                $state.go("app.main");
            }

            if(vm.formSuscripcionRecurrente){

                vm.comprobante.fechaInicial = moment(vm.comprobante.fechaInicial, "DD/MM/YYYY").toDate();
                vm.comprobante.fechaFinal =  moment(vm.comprobante.fechaFinal, "DD/MM/YYYY").toDate();
                vm.comprobante.primeraFacturacion =  moment(vm.comprobante.primeraFacturacion, "DD/MM/YYYY").toDate();
                $scope.dias.diasSemana = JSON.parse(vm.comprobante.diasDeLaSemana);
            }else {

                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY");
                vm.puntoEmisionVentaActual = vm.comprobante.timbradoPuntoEmision.puntoEmisionVenta;
            }

            vm.comprobante.fecha = new Date(vm.comprobante.fecha);
            if (vm.datoVirtual && !vm.fromOutside && vm.comprobante.timbradoPuntoEmision &&
                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") verificarTipoOperacion(vm.comprobante.cliente);

            if (vm.formSuscripcionRecurrente || !vm.fromOutside) {
                vm.getListasPreciosVentasByMoneda();
            }
            recuperarPreciosMinimosMaximos();
            vm.precioBase = "Precio de Venta";
            vm.porcRecargo = 0;
            if (vm.comprobante.listaPrecioVenta && vm.utilizarPrecioBase) {
                if(vm.comprobante.listaPrecioVenta.precioBase){
                    vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                }
                vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
            } else if (vm.comprobante.listaPrecioVenta && !vm.utilizarPrecioBase) {
                ListaPrecioVentaFactory.get(
                    vm.comprobante.listaPrecioVenta.id,
                    "ComprobanteVentaForm"
                ).$promise.then(function (response) {
                    vm.comprobante.listaPrecioVenta = response;
                });
            }
            getPlanCobros();
            vm.entidad = "ComprobanteVenta";
            vm.entidadId = vm.comprobante.id;
            if (vm.comprobante.comprobanteDetalle) {
                _.forEach(vm.comprobante.comprobanteDetalle, function (detalle) {

                    //permite desplegar más campos a ser cargados
                    detalle.mostrarMas = false;

                    ComprobanteVentaDetalleFactory.get(
                        detalle.id,
                        "ConsultaComprobanteVentaForm"
                    ).$promise.then(function (response) {
                        detalle.montosImponibles = response.montosImponibles;
                    });
                });
            }

            splitDetalles();

            // Redondeo en funcion de moneda
            var cantDecimales = 0;
            if (vm.comprobante.moneda.codigo != 'guaranies') {
                cantDecimales = 2;
            }

            vm.totalGeneralProductos = _.reduce(
                vm.comprobantesProductos,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            //vm.totalGeneralProductos = parseFloat(vm.totalGeneralProductos.toFixed(cantDecimales));
            vm.totalGeneralProductos = UtilFactory.roundForMoneda(vm.totalGeneralProductos, vm.comprobante.moneda);


            vm.totalGeneralServicios = _.reduce(
                vm.comprobantesServicios,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            //vm.totalGeneralServicios = parseFloat(vm.totalGeneralServicios.toFixed(cantDecimales));
            vm.totalGeneralServicios = UtilFactory.roundForMoneda(vm.totalGeneralServicios, vm.comprobante.moneda);

            vm.isEdit = true;
            if(!vm.formSuscripcionRecurrente){
                getPuntosEmisionVenta(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa);
                filterTimbrado();
            }else{
                getPuntosEmisionVenta();
            }

            //getProductoStockAlmacen();
            _.forEach(vm.comprobantesProductos, function (elem) {
                if ((elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') && !estaEnPV(elem)) {
                    /*ProductosFactory.get(elem.producto.id, 'BaseList').$promise.then(function (producto) {
                        elem.producto = producto;*/
                    getStockPorTipoComprobante(elem);
                    //});
                }
                //vm.calcularTotalDetalle();
            });
            if (vm.comprobante.cliente) {

                var staticFilterComprobante = {
                    search: filterFactory
                        .and([{
                            path: "cliente.id",
                            equals: vm.comprobante.cliente.id
                        }, {
                            path: "tipo.clase.codigo",
                            equals: "factura"
                        }, {
                            path: "estado.codigo",
                            equals: "comp_venta_emitido"
                        }])
                        .value()
                };
                ComprobanteVentaFactory.all(staticFilterComprobante, "ComprobanteVentaList").$promise.then(function (response) {
                    vm.relacionarFacturas = response;
                });
            }
            var filters = {
                search: filterFactory.single({
                    path: 'notaCredito.id',
                    equals: vm.comprobante.id
                }).value()
            };
            vm.optionsMovimientoStock.staticFilter = filters;
            vm.showMovimientoStock = true;

            formSelectionsArray();
        });
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);

        if (vm.isProcesoVenta === true && vm.venta.pedidoVenta != null && !AuthorizationService.hasPermission("cant_mayor_pedido_venta")) {
            // Se realiza el seteo de la cantidad maxima
            setCantidadMax();
        }

        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                if (vm.comprobante.fechaInicio)
                    vm.comprobante.fechaInicio = new Date(vm.comprobante.fechaInicio);
                if (vm.comprobante.fechaFin)
                    vm.comprobante.fechaFin = new Date(vm.comprobante.fechaFin);
            }
        });
    }

    function activateView() {
        vm.title = $filter("translate")("VIEW_COMPROBANTE_VENTA");
        vm.tiposDetalle = {};
        if(vm.formSuscripcionRecurrente) vm.fromOutside = true;

        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;
            vm.comprobante = comprobantePrepService;
            Promise.all(vm.promiseFormaPago)
              .then(function() {
                filterFormasPago();
              });
              vm.existeAnticipo = false;
              if(vm.comprobante.comprobanteAnticipo){
                  vm.existeAnticipo = true;
              }
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'numeroProcesoOrigen',
                equals: vm.comprobante.id + "",
            }]).value();
            SolicitudAutorizacionFactory.all(staticFilter).$promise.then(function (response) {

                if (response.length > 0) {
                    vm.mostrarVolver = true;
                } else {
                    vm.mostrarVolver = false;
                }
            });
            if (vm.comprobante.listaPrecioVenta && vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                vm.mostrarCambioPactado = true;
            }
            if (vm.isProcesoVenta === true && !ComprobanteVentaFactory.hasPermisos(false, "view")) {
                $state.go("app.main");
            }

            if(vm.formSuscripcionRecurrente){

                vm.comprobante.fechaInicial = moment(vm.comprobante.fechaInicial, "DD/MM/YYYY").toDate();
                vm.comprobante.fechaFinal =  moment(vm.comprobante.fechaFinal, "DD/MM/YYYY").toDate();
                //vm.comprobante.primeraFacturacion =  moment(vm.comprobante.primeraFacturacion, "DD/MM/YYYY").toDate();
                $scope.dias.diasSemana = JSON.parse(vm.comprobante.diasDeLaSemana);

            }else {

                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY");
                vm.puntoEmisionVentaActual = vm.comprobante.timbradoPuntoEmision.puntoEmisionVenta;
            }

            vm.comprobante.fecha = new Date(vm.comprobante.fecha);

            if (!vm.fromOutside && !vm.formSuscripcionRecurrente && vm.venta.pedidoVenta && vm.venta.pedidoVenta.listaPrecioVenta) {
                PedidoVentaFactory.get(
                    vm.venta.pedidoVenta.id,
                    "PedidoVentaForm"
                ).$promise.then(function (response) {
                    vm.comprobante.pedidoVenta = response;
                    vm.comprobante.listaPrecioVenta = vm.comprobante.pedidoVenta.listaPrecioVenta;
                    vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                });
            }

            ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
                if (parametro.valorTipo.codigo === 'no') {
                    vm.utilizarPrecioBase = false;
                    vm.precioBase = "Precio de Venta";
                    vm.porcRecargo = 0;
                } else {
                    vm.utilizarPrecioBase = true;
                    if(vm.comprobante.listaPrecioVenta && vm.comprobante.listaPrecioVenta.precioBase){
                        vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                        vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                    }
                }
            });

            getPlanCobros();
            vm.entidad = "ComprobanteVenta";
            vm.entidadId = vm.comprobante.id;
            _.forEach(vm.comprobante.montosImponibles, function (impo) {
                impo.baseImponible = parseFloat(impo.baseImponible.toFixed(4));
                impo.valor = parseFloat(impo.valor.toFixed(4));
            });
            if (vm.comprobante.comprobanteDetalle) {
                _.forEach(vm.comprobante.comprobanteDetalle, function (detalle) {

                    //permite desplegar más campos a ser cargados
                    detalle.mostrarMas = false;

                    ComprobanteVentaDetalleFactory.get(
                        detalle.id,
                        "ConsultaComprobanteVentaForm"
                    ).$promise.then(function (response) {
                        detalle.montosImponibles = response.montosImponibles;
                    });
                });

            }
            splitDetalles();
            // Redondeo en funcion de moneda
            var cantDecimales = 0;
            if (vm.comprobante.moneda.codigo != 'guaranies') {
                cantDecimales = 2;
            }
            vm.totalGeneralProductos = _.reduce(
                vm.comprobantesProductos,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            //vm.totalGeneralProductos = parseFloat(vm.totalGeneralProductos.toFixed(cantDecimales));
            vm.totalGeneralProductos = UtilFactory.roundForMoneda(vm.totalGeneralProductos, vm.comprobante.moneda);


            vm.totalGeneralServicios = _.reduce(
                vm.comprobantesServicios,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            //vm.totalGeneralServicios = parseFloat(vm.totalGeneralServicios.toFixed(cantDecimales));
            vm.totalGeneralServicios = UtilFactory.roundForMoneda(vm.totalGeneralServicios, vm.comprobante.moneda);

            if(vm.formSuscripcionRecurrente){
                getPuntosEmisionVenta();
                //
            }else {
                getPuntosEmisionVenta(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa);
            }


            var filters = {
                search: filterFactory.single({
                    path: 'notaCredito.id',
                    equals: vm.comprobante.id
                }).value()
            };
            vm.optionsMovimientoStock.staticFilter = filters;
            vm.showMovimientoStock = true;

            formSelectionsArray();

            // Habilitar edicion de campo Cliente y Sucursal
            vm.hasPermisoModificar = (localStorage.getItem('modificarCompElectronico') === "true");
            vm.hasPermisoModificarAprobado = (localStorage.getItem('modificarCompElectronicoAprobado') === "true");

            // ademas del permiso, se debe cumplir ciertas condiciones
            vm.hasPermisoModificar = vm.hasPermisoModificar && vm.comprobante.timbradoPuntoEmision
            && vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo == "timbrado_virtual"
            && vm.comprobante.estado.codigo === "comp_venta_emitido";

            vm.hasPermisoModificarAprobado = vm.hasPermisoModificarAprobado && vm.comprobante.timbradoPuntoEmision
            && vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo == "timbrado_virtual"
            && vm.comprobante.estado.codigo === "comp_venta_aprobado_sifen"

            if( vm.hasPermisoModificar && vm.comprobante.cliente && vm.comprobante.cliente.tipoDocumento &&
                vm.comprobante.cliente.tipoDocumento.codigo=="tipo_documento_cliente_innominado" ){
                vm.hasPermisoCliente = true;
            }else if(vm.hasPermisoModificarAprobado && vm.comprobante.cliente && vm.comprobante.cliente.tipoDocumento &&
                vm.comprobante.cliente.tipoDocumento.codigo=="tipo_documento_cliente_innominado"){
                    vm.hasPermisoCliente = true;
            }else{
                vm.hasPermisoCliente = false;
            }

            if(vm.comprobante.origen && vm.comprobante.origen.codigo === 'comp_venta_pos'){
                vm.origen = true;
            }else{
                vm.origen = false;
            }
            localStorage.removeItem("modificarCompElectronico");
            localStorage.removeItem("modificarCompElectronicoAprobado");
        });
        vm.view = true;
        vm.hasPermisoVendedorView = !!localStorage.getItem('habilitarVendedor');
        vm.hasPermisoVendedor = !!localStorage.getItem('habilitarVendedor');
        localStorage.removeItem("habilitarVendedor");

        vm.new = false;
        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                if (vm.comprobante.fechaInicio)
                    vm.comprobante.fechaInicio = new Date(vm.comprobante.fechaInicio);
                if (vm.comprobante.fechaFin)
                    vm.comprobante.fechaFin = new Date(vm.comprobante.fechaFin);
            }
        });
    }

    var formSelectionsArray = function () {
        $scope.$watch('vm.optionsMovimientoStock.selection', function (newValue, oldValue) {
            if (!vm.optionsMovimientoStock.selection || vm.selectedChecked) {
                return;
            }
            vm.selectedChecked = true;
            _.forEach(vm.comprobante.movimientoStock, function (op) {
                if (op.id) {
                    vm.optionsMovimientoStock.selection[op.id] = true;
                } else {
                    vm.optionsMovimientoStock.selection[op] = true;
                }
            });
        });
    };

    function abortRouteIfNecessary() {
        if (vm.isProcesoVenta === true && vm.venta.estado.codigo === "venta_cerrada") {
            $state.go("app.main");
        }
    }

    function addProductoGrupo(detalle) {
        detalle.grupoProducto.producto = detalle.producto.id;
    }

    function getPlanCobros() {
        if (vm.comprobante.planCobros) {
            vm.cantidadCuotas = vm.comprobante.planCobros.cantidadCuotas;
            vm.montoInicial = vm.comprobante.planCobros.montoInicial;
            vm.plazoCuotas = vm.comprobante.planCobros.plazoCuotas;
        }
    }

    function getTipoServicio(tipos) {
        var tipoServicio = _.filter(tipos, function (elem) {
            return elem.codigo === "detalle_comprobante_servicio";
        });
        return tipoServicio[0];
    }

    function getTipoProducto(tipos) {
        var tipoProducto = _.filter(tipos, function (elem) {
            return elem.codigo === "detalle_comprobante_producto";
        });
        return tipoProducto[0];
    }

    function getMonedaLocal(callback) {
        var moneda = ParametrosFactory.filterByCodigo("moneda_local");
        moneda.$promise.then(function (data) {
            if (data && data.length > 0) {
                if (vm.comprobante.moneda == undefined) {
                    vm.comprobante.moneda = data[0].valorTipo;
                    typeof callback === 'function' ? callback() : "";
                }
                vm.monedaDefault = data[0].valorTipo;
            }
        });
    }

    function recuperarItemsRecepcion() {
        if (vm.comprobante.recepciones && vm.comprobante.recepciones.length > 0) {
            vm.comprobantesProductos = [];
            for (var i = 0; i < vm.comprobante.recepciones.length; i++) {
                var recepcion = vm.comprobante.recepciones[i];
                CompraFactory.getSugerenciasRecepcion(recepcion.id).then(function (
                    response
                ) {
                    if (response.data && response.data.length > 0) {
                        _.forEach(response.data, function (detalle) {
                            if (detalle.producto) {
                                ProductosFactory.get(
                                    detalle.producto.id,
                                    "ConsultaComprobanteVentaForm"
                                ).$promise.then(function (response) {
                                    vm.comprobantesProductos.push({
                                        montosImponibles: [{
                                            tipoImpuesto: response.tiposImpuesto[0],
                                            porcentaje: response.tiposImpuesto[0].porcentaje,
                                            valor: 0
                                        }],
                                        producto: detalle.producto,
                                        unidadMedida: detalle.unidadMedida,
                                        cantidad: detalle.cantidadRecepcionada,
                                        tipo: getTipoProducto(vm.tiposDetalle)
                                    });
                                });
                            }
                        });
                    }
                });
            }
        }
    }

    function getParamDiferenciaMonedaLocal() {
        var params = {};
        params.search = filterFactory
            .single({
                path: "codigo",
                equals: "maximo_diferencia_cabecera_detalle_moneda_local"
            })
            .value();
        params.view = "Base";
        vm.diferenciaMonedaLocal = ParametrosFactory.all(params);
    }

    function getParamDiferenciaMonedaExtranjera() {
        var params = {};
        params.search = filterFactory
            .single({
                path: "codigo",
                equals: "maximo_diferencia_cabecera_detalle_moneda_extranjera"
            })
            .value();
        params.view = "Base";
        vm.diferenciaMonedaExtranjera = ParametrosFactory.all(params);
    }

    function splitDetalles() {
        vm.comprobantesServicios = [];
        vm.comprobantesProductos = [];
        _.forEach(vm.comprobante.comprobanteDetalle, function (elem) {
            if (elem.servicio) {
                vm.comprobantesServicios.push(elem);
            } else {
                vm.comprobantesProductos.push(elem);
            }
        });
    }

    function searchSolicitudCompraFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return (
                item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.numero == criteria
            );
        };
    }

    function searchTimbradoPuntoEmisionFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return String(item.timbradoEmpresa.numeroTimbrado).toLowerCase().indexOf(criteria.toLowerCase()) > -1;

        };
    }

    function changeGrupoCompra() {
        if (vm.comprobante.grupoCompra.codigo == "comprobante_importacion") {
            vm.valorFOBRequerido = true;
            delete vm.comprobante.montosImponibles;
        } else if (!vm.formSuscripcionRecurrente && !vm.isProcesoVenta) {
            vm.comprobante.montosImponibles = [{}];
            vm.valorFOBRequerido = false;
            vm.comprobantesProductos[0].montosImponibles = [{}];
            vm.comprobantesServicios[0].montosImponibles = [{}];
            vm.comprobante.fob = 0;
            vm.comprobante.cliente = undefined;
            vm.comprobante.pedidoVenta = undefined;
        }
        var filterAlmacenes = {
            search: filterFactory
                .and([{
                    path: "sucursal.id",
                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                },
                {
                    path: "clase.id",
                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                }
                ])
                .value()
        };
        vm.depositos = AlmacenFactory.all(filterAlmacenes);
        vm.comprobante.deposito = undefined;
    }

    function changePV() {
        vm.comprobante.cliente = vm.venta.cliente;
        vm.comprobante.cambio = vm.venta.pedidoVenta.cambio;
        //if (vm.datoVirtual && !vm.fromOutside) verificarTipoOperacion(vm.comprobante.cliente);
        vm.comprobante.deposito = vm.venta.pedidoVenta.almacen;
        changeCliente();
        // filterProductos();
        changeCotizacion(true);
        getRemisionesCurrentPV();
        ParametrosFactory.filterByModulo("ventas").$promise.then(function (
            params
        ) {
            var param = _.find(params, function (parametro) {
                return parametro.codigo == "recuperar_items_pv";
            });

            if (!param) {
                return;
            }

            if (param.valorTipo.codigo == "si") {
                recuperarItemsPV();
            }
        });

    }

    function changeRecepciones() {
        var newRecepcion =
            vm.comprobante.recepciones[vm.comprobante.recepciones.length - 1];

        ClienteFactory.get(newRecepcion.proveedor.id).$promise.then(function (
            response
        ) {


            if (
                vm.comprobante.proveedor &&
                vm.comprobante.proveedor.id != response.id
            ) {
                var msg = $scope.translations.TODAS_RECEPCIONES_MISMO_PROVEEDOR_WARNING;
                notify({ message: msg, classes: "alert-danger", position: "right" });
                vm.comprobante.recepciones.splice(
                    vm.comprobante.recepciones.length - 1,
                    1
                );
                return;
            }
            if (
                vm.comprobante.deposito &&
                vm.comprobante.deposito.id != newRecepcion.almacen.id
            ) {
                var msg = $scope.translations.TODAS_RECEPCIONES_MISMO_ALMACEN_WARNING;
                notify({ message: msg, classes: "alert-danger", position: "right" });
                vm.comprobante.recepciones.splice(
                    vm.comprobante.recepciones.length - 1,
                    1
                );
                return;
            }
            vm.comprobante.deposito = newRecepcion.almacen || vm.comprobante.deposito;
            if (!vm.comprobante.ordenCompra) {
                vm.comprobante.proveedor = response;
                changeCliente();
            }
            // filterProductos();
            changeCotizacion();
            if (!vm.comprobante.ordenCompra) {
                recuperarItemsRecepcion();
            }
        });
    }

    function getRemisionesCurrentPV() {
        //vm.remisiones = [];
        // var staticFilter = {};
        // var filters = [];

        // filters = [{
        //         path: "pedidoVenta.id",
        //         equals: vm.comprobante.pedidoVenta.id
        //     },
        //     {
        //         path: "estado.codigo",
        //         equals: "remision_emitida"
        //     }
        // ];

        // if (vm.isProcesoVenta) {
        //     filters.push({
        //         path: "venta.id",
        //         equals: vm.venta.id
        //     });

        //     filters.push({
        //         path: "comprobanteVenta.id",
        //         isNull: true
        //     });
        // }

        // staticFilter.search = filterFactory.and(filters).value();
        // if (vm.watcherRemision) { vm.watcherRemision(); }
        // WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcherRemision) {
        //     vm.watcherRemision = watcherRemision;
        //     vm.remisiones = RemisionFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter), "detail");
        // }, "locacion.id");
    }

    function recuperarItemsPV() {

      vm.uiBlockuiConfig.bloquear = true;
        VentaFactory.getSugerenciasPV(
            vm.comprobante.pedidoVenta.id,
            "comp_tipo_venta_comprobante"
        ).then(function (response) {
          vm.uiBlockuiConfig.bloquear = false;
            if (response.data && response.data.length > 0) {
                vm.comprobantesProductos = [];
                vm.comprobantesServicios = [];

                _.forEach(response.data, function (detalle) {
                    if (detalle.producto) {
                        //ProductosFactory.get(
                        ////    detalle.producto.id,
                        //    "ProductoList"
                        //  ).$promise.then(function (response) {
                        detalle.precio = vm.comprobante.cliente.exoneradoIva ?
                          calcularPrecioSinImpuestos(detalle.precio, detalle.producto.tiposImpuesto[0].porcentaje) :
                          detalle.precio;
                        var newProducto = {
                            montosImponibles: [{
                                tipoImpuesto: vm.comprobante.cliente.exoneradoIva ? vm.tipoExento[0] :
                                detalle.producto.tiposImpuesto[0],
                                porcentaje: vm.comprobante.cliente.exoneradoIva ? vm.tipoExento[0].porcentaje :
                                detalle.producto.tiposImpuesto[0].porcentaje,
                                valor: 0
                            }],
                            producto: detalle.producto,
                            unidadMedida: detalle.unidadMedida,
                            cantidad: (detalle.producto.criterioControl.codigo == "series" && vm.comprobanteGeneraMovimiento) ?
                                1 : detalle.cantidad,
                            grupoProducto: (detalle.producto.criterioControl.codigo ==
                                "series" || detalle.producto.criterioControl.codigo == "lotes") ? detalle.grupoProducto : undefined,
                            precioVenta: detalle.precio,
                            descuento: detalle.porcentajeDescuento,
                            tipo: getTipoProducto(vm.tiposDetalle),

                        };

                        // Si NO tiene el permiso, se debe restringir la cantidad
                        newProducto.cantMaxima = null;
                        if (!AuthorizationService.hasPermission("cant_mayor_pedido_venta")) {
                            newProducto.cantMaxima = newProducto.cantidad;
                        }

                        newProducto.numeroOrdenCompraCliente = detalle.numeroOrdenCompraCliente;
                        newProducto.numeroOrdenProduccion = detalle.numeroOrdenProduccion;
                        newProducto.fechaValidez = detalle.fechaValidez;
                        newProducto.cantidadUmPrincipal = detalle.cantidadUmPrincipal;
                        newProducto.precioVentaView = detalle.precio.toString().replace(/\./, ",");
                        newProducto.precioVenta = parseFloat(newProducto.precioVentaView.replace(/,/, "."));

                        // if (vm.verPrecio == 'No') {

                        //     CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precioRecuperado) {
                        //         if (precioRecuperado.data != null) {
                        //             newProducto.precioVentaView = precioRecuperado.data.toString().replace(/\./, ",");
                        //             newProducto.precioVenta = parseFloat(newProducto.precioVentaView.replace(/,/, "."));
                        //             //_.forEach(vm.comprobante.pedidoVenta.pedidoVentaDetalle, function (pedDet) {
                        //             //     if ((newProducto.producto.codigo == pedDet.producto.codigo) && (newProducto.producto.unidadMedida == pedDet.producto.unidadMedida)) {
                        //             //         newProducto.orden = pedDet.orden;
                        //             //     }
                        //             // });
                        //             //vm.comprobantesProductos.push(newProducto);
                        //             // vm.comprobantesProductos.sort(function (a, b) {
                        //             //   return a.orden - b.orden;
                        //             // });
                        //             // vm.calcularTotalDetalle(newProducto);
                        //         }
                        //     });
                        // } //else {
                        vm.comprobantesProductos.push(newProducto);
                        vm.calcularTotalDetalle(newProducto);
                        // }

                        //});
                    } else if (detalle.servicio) {
                        ServicioFactory.get(
                            detalle.servicio.id,
                            "BaseList"
                        ).$promise.then(function (response) {
                            var newServicio = {
                                montosImponibles: [{
                                    tipoImpuesto: response.tipoImpuesto,
                                    porcentaje: response.tipoImpuesto.porcentaje,
                                    valor: 0
                                }],
                                servicio: detalle.servicio,
                                cantidad: detalle.cantidad,
                                tipo: vm.tiposDetalle[1],
                                precioVenta: detalle.precio
                            };

                            vm.comprobantesServicios.push(newServicio);
                            vm.calcularTotalDetalle(newServicio);
                        });
                    }
                });
                copiarComprobantes();
                recuperarPreciosMinimosMaximos();
            }
        });
    }

    function puntoEmisionSelected() {

        vm.habilitarTimbradoSelect = false;

        // Esto se realiza porque el modelo de punto Emision no se encuentra en el formulario (se guarda en vm.puntoEmisionVentaActual)
        vm.comprobante.timbradoPuntoEmision = {};
        vm.comprobante.timbradoPuntoEmision.puntoEmisionVenta = vm.puntoEmisionVentaActual;

        if (vm.fromOutside || vm.formSuscripcionRecurrente) {
            filterTimbrado();
        }
    }

    function recuperarItemsRecepcion() {
        if (vm.comprobante.recepciones && vm.comprobante.recepciones.length > 0) {
            vm.comprobantesProductos = [];
            for (var i = 0; i < vm.comprobante.recepciones.length; i++) {
                var recepcion = vm.comprobante.recepciones[i];
                if (
                    recepcion.recepcionDetalle &&
                    recepcion.recepcionDetalle.length > 0
                ) {
                    _.forEach(recepcion.recepcionDetalle, function (detalle) {
                        if (detalle.producto) {
                            ProductosFactory.get(
                                detalle.producto.id,
                                "ConsultaComprobanteVentaForm"
                            ).$promise.then(function (response) {
                                vm.comprobantesProductos.push({
                                    montosImponibles: [{
                                        tipoImpuesto: response.tiposImpuesto[0],
                                        porcentaje: response.tiposImpuesto[0].porcentaje,
                                        valor: 0
                                    }],
                                    producto: detalle.producto,
                                    unidadMedida: detalle.unidadMedida,
                                    cantidad: detalle.cantidad,
                                    tipo: getTipoProducto(vm.tiposDetalle)
                                });
                            });
                        }
                    });
                }
            }
        }
    }

    function mostrarTodosProductos() {
        if (vm.todosProductos) {
            vm.opcionesProductosDt.staticFilter = null;
            if (vm.watcher) {
                vm.watcher();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcher = watcher;
                var filter = locacionFilter;
                if (
                    vm.fromOutside &&
                    vm.comprobante &&
                    vm.comprobante.tipo &&
                    vm.comprobante.tipo.clase.codigo != "nota_credito"
                ) {
                    var stockFilter = {
                        search: filterFactory
                            .single({
                                path: "controlStock",
                                equals: false
                            },
                                {
                                    path: 'estado.codigo',
                                    equals: 'activo'
                                })
                            .value()
                    };
                    var filter = UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        stockFilter
                    );
                }
                vm.productos = ProductosFactory.allForSelect(filter, "ConsultaComprobanteVentaForm");
                vm.opcionesProductosDt.staticFilter = filter;
            }, "locaciones.id");
        } else {
            // filterProductos();
        }
    }

    // function filterProductos() {
    //     var staticFilter = [{
    //             path: 'clientes.codigo',
    //             equals: vm.comprobante.proveedor.codigo
    //         },
    //         {
    //             path: 'itemCompra',
    //             like: 'true'
    //         }
    //     ];

    //     if (vm.watcher) { vm.watcher(); }
    //     WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
    //         vm.watcher = watcher;
    //         if (vm.fromOutside && vm.comprobante && vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo != "nota_credito") {
    //             var filterStock = {
    //                 path: "controlStock",
    //                 equals: false
    //             }
    //             staticFilter.push(filterStock);
    //         }
    //         var finalFilters = {
    //             search: filterFactory.and(staticFilter).value()
    //         };
    //         var filteredStructure = UtilFactory.createNestedFilters(locacionFilter.search.filters, finalFilters);
    //         console.log("Se traerán produtos con filtros: ", filteredStructure);
    //         vm.productos = ProductosFactory.allForSelect(filteredStructure);
    //         vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(filteredStructure);
    //     }, "locaciones.id");

    // }

    function agregarDetalle() {
        vm.comprobante.comprobanteDetalle.push({
            montosImponibles: [{}],
            tipo: getTipoServicio(vm.tiposDetalle)
        });
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function agregarDetalleProducto() {
        vm.comprobantesProductos.push({
            montosImponibles: [{}],
            tipo: getTipoProducto(vm.tiposDetalle),
            id: undefined
        });

        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function agregarDetalleServicio() {
        vm.comprobantesServicios.push({
            montosImponibles: [{}],
            tipo: vm.tiposDetalle[1]
        });
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function agregarMontoImponible() {
        vm.comprobante.montosImponibles.push({ esTotal: true });
        $timeout(function () {
            $scope.$broadcast("newImpuestoAdded");
        }, 20);
    }

    function calcularTotalDetalle(detalle, index) {

        if (index != undefined) {

            var name = "totalItem_" + index;
            if(detalle.descuento && detalle.descuento>0){
                
                var msg = $filter("translate")("BLOQUEO_POR_DESCUENTO");
                vm.erroresDescuento.splice(index, 1, msg);
                $scope.ComprobanteForm[name].$warning = true;
                $scope.ComprobanteForm[name].$touched = true;
            }else{
                msg = "";
                vm.erroresDescuento.splice(index, 1, msg);
                $scope.ComprobanteForm[name].$warning = false;
            }
        }

        if (vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo == 'nota_credito') {
            if (!vm.permisoEditarPrecioVentaNotaCredito) {
                controlPrecioUltimoCosto(detalle, index);
            }
        } else {
            if (vm.comprobante.deposito && !(vm.comprobante.deposito.modificarPrecioEspecial && vm.permisoModificarPrecioAlmacenSelec)) {
                controlPrecioUltimoCosto(detalle, index);
            }
        }


        if (detalle.descuento && detalle.descuento > 0) {
            controlLimiteDescuento(detalle, index);
        }

        if (detalle.producto != null) {

            if (detalle.cantidad == undefined) {
                detalle.cantidad = 0;
                detalle.totalItem = 0;
            }

            if (detalle.precioVenta == undefined) {
                detalle.ultimoPrecio = 0;
                detalle.totalItem = 0;
            }

            if (detalle.descuento == undefined) {
                detalle.descuento = 0
            }

            var totalNeto

            if (detalle.descuento && detalle.descuento > 0) {
                totalNeto = detalle.precioVenta - (detalle.precioVenta * (detalle.descuento / 100))
            } else {
                totalNeto = detalle.precioVenta;
            }

            detalle.totalItem = (detalle.cantidad * totalNeto);
            detalle.totalItem = Math.round(detalle.totalItem * 100) / 100;//UtilFactory.roundForMoneda(detalle.totalItem, vm.comprobante.moneda);
            /* if (vm.comprobante.moneda != undefined) {
                detalle.totalItem = UtilFactory.roundForMoneda(detalle.totalItem, vm.comprobante.moneda);
            }*/
            if (detalle.montosImponibles) {
                if (!vm.comprobante.listaPrecioVenta) {
                    if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                        detalle.montosImponibles[0].baseImponible =
                            100 *
                            detalle.totalItem /
                            (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje);
                    } else {
                        detalle.montosImponibles[0].baseImponible = detalle.totalItem;
                    }
                } else {
                    if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                        detalle.montosImponibles[0].baseImponible =
                            100 *
                            detalle.totalItem /
                            (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje);
                    } else {
                        detalle.montosImponibles[0].baseImponible = detalle.totalItem;
                    }
                }

                detalle.montosImponibles[0].baseImponible = parseFloat(detalle.montosImponibles[0].baseImponible.toFixed(4));
                detalle.montosImponibles[0].valor = parseFloat((detalle.totalItem - detalle.montosImponibles[0].baseImponible).toFixed(4));
            }
            vm.totalGeneralProductos =
                _.reduce(
                    vm.comprobantesProductos,
                    function (sum, detalle) {
                        return sum + detalle.totalItem;
                    },
                    0
                );

            vm.totalGeneralProductos = UtilFactory.roundForMoneda(vm.totalGeneralProductos, vm.comprobante.moneda);

        } else {
            if (detalle.cantidad == undefined) {
                detalle.cantidad = 0;
                detalle.totalItem = 0;
            }

            if (detalle.precioVenta == undefined) {
                detalle.ultimoPrecio = 0;
                detalle.totalItem = 0;
            }

            if (detalle.descuento == undefined) {
                detalle.descuento = 0
            }

            var totalNeto

            if (detalle.descuento && detalle.descuento > 0) {
                totalNeto = detalle.precioVenta - (detalle.precioVenta * (detalle.descuento / 100))
            } else {
                totalNeto = detalle.precioVenta;
            }

            detalle.totalItem = (detalle.cantidad * totalNeto);
            detalle.totalItem = Math.round(detalle.totalItem * 100) / 100;//UtilFactory.roundForMoneda(detalle.totalItem, vm.comprobante.moneda);
            if (detalle.montosImponibles) {
                if (!vm.comprobante.listaPrecioVenta) {
                    if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                        detalle.montosImponibles[0].baseImponible =
                            100 *
                            detalle.totalItem /
                            (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje);
                    } else {
                        detalle.montosImponibles[0].baseImponible = detalle.totalItem;
                    }
                } else {
                    if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                        detalle.montosImponibles[0].baseImponible =
                            100 *
                            detalle.totalItem /
                            (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje);
                    } else {
                        detalle.montosImponibles[0].baseImponible = detalle.totalItem;
                    }
                }

                detalle.montosImponibles[0].baseImponible = parseFloat(detalle.montosImponibles[0].baseImponible.toFixed(4));
                detalle.montosImponibles[0].valor = parseFloat((detalle.totalItem - detalle.montosImponibles[0].baseImponible).toFixed(4));
            }

            vm.totalGeneralServicios =
                _.reduce(
                    vm.comprobantesServicios,
                    function (sum, detalle) {
                        return sum + detalle.totalItem;
                    },
                    0
                );

            vm.totalGeneralServicios = UtilFactory.roundForMoneda(vm.totalGeneralServicios, vm.comprobante.moneda);
        }
        vm.totalGeneral = vm.totalGeneralProductos + vm.totalGeneralServicios;
        if(vm.existeAnticipo && vm.comprobante.comprobanteAnticipo){
            //sumar cantidad*precio tanto de productos como se servicios si existe
            var totalBruto = 0;
            var totalBrutoProducto = 0;
            var totalBrutoServicio = 0;
            if(vm.comprobantesProductos.length > 0){
                _.forEach(vm.comprobantesProductos, function (det) {
                    totalBrutoProducto += det.cantidad * det.precioVenta;
                });
            }
            if(vm.comprobantesServicios.length > 0){
                _.forEach(vm.comprobantesServicios, function (det) {
                    totalBrutoServicio += det.cantidad * det.precioVenta;
                });
            }
            totalBruto = totalBrutoProducto + totalBrutoServicio;
            vm.comprobante.porcentajeAnticipo = (vm.comprobante.importeAnticipo/totalBruto)*100;
            vm.comprobante.porcentajeAnticipo = parseFloat(vm.comprobante.porcentajeAnticipo.toFixed(8));
            if(vm.comprobantesProductos.length > 0){
                var sumCompProducto = 0;
                _.forEach(vm.comprobantesProductos, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta)/100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo*elem.cantidad;
                    elem.totalItem = (elem.precioVenta-elem.importeAnticipo)*elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompProducto += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);

                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }
        
                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralProductos = UtilFactory.roundForMoneda(sumCompProducto, vm.comprobante.moneda);
            }
            if(vm.comprobantesServicios.length > 0){
                var sumCompServicio = 0;

                _.forEach(vm.comprobantesServicios, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta)/100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo*elem.cantidad;
                    elem.totalItem = (elem.precioVenta-elem.importeAnticipo)*elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompServicio += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);
                    
                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }
        
                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralServicios = UtilFactory.roundForMoneda(sumCompServicio, vm.comprobante.moneda);
            }
        }

        // Seteo de campo  'Cantidad U.M. Principal'
        var contenida = null;
        if (detalle.unidadMedida != null && detalle.unidadMedida.cantidad != null) {
            contenida = detalle.unidadMedida.cantidad;
        }

        if (detalle.cantidad != null && detalle.cantidad > 0 && contenida != null) {
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidad * contenida).toFixed(4);
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidadUmPrincipal); // Esto elimina ceros despues de la coma
        } else {
            detalle.cantidadUmPrincipal = detalle.cantidad;
        }

    }

    function changeTipo(detalle) {
        if (detalle.tipo.codigo == "detalle_comprobante_producto") {
            detalle.servicio = null;
            detalle.cantidad = 0;
            detalle.precioVenta = 0;
            detalle.montosImponibles[0].tipoImpuesto = {};
            detalle.montosImponibles[0].valor = 0;
            detalle.totalItem = 0;
        } else if (detalle.tipo.codigo == "detalle_comprobante_servicio") {
            detalle.producto = null;
            detalle.cantidad = 0;
            detalle.precioVenta = 0;
            detalle.montosImponibles[0].tipoImpuesto = {};
            detalle.montosImponibles[0].valor = 0;
            detalle.totalItem = 0;
        }
    }

    function changeCliente() {
        if(vm.view == true){        // Si esta modificando CLIENTE en modo VER (se puede modificar con un permiso)

            // Entonces solo tocar la sucursal
            var suc = [];
            for (var i = 0; i < vm.comprobante.cliente.sucursales.length; i++) {
                if (vm.comprobante.cliente.sucursales[i].activo === true) {
                    suc.push(vm.comprobante.cliente.sucursales[i])
                }
            }
            vm.sucursalesCliente = suc;
            if (vm.sucursalesCliente.length == 1) {
                vm.comprobante.sucursalCliente = vm.sucursalesCliente[0];
            }
            vm.uiBlockuiConfig.bloquear = false;

        }else{
            vm.comprobante.modoExoneracionIva = vm.comprobante.cliente.exoneradoIva ? vm.modosExoneracionIva[0] : null;
            vm.comprobante.tipo = vm.comprobante.tipo ? vm.comprobante.tipo : vm.tipos[0];
            vm.comprobante.tipoOperacion = vm.comprobante.cliente.tipoOperacion;
            //vm.comprobante.formaPago = undefined;
            var suc = [];
            for (var i = 0; i < vm.comprobante.cliente.sucursales.length; i++) {
                if (vm.comprobante.cliente.sucursales[i].activo === true) {
                    suc.push(vm.comprobante.cliente.sucursales[i])
                }
            }
            vm.sucursalesCliente = suc;
            if (vm.sucursalesCliente.length == 1) {
                vm.comprobante.sucursalCliente = vm.sucursalesCliente[0];
                ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
                    if (response.valorTipo.codigo === "si") {
                        vm.comprobante.direccionDestino = vm.comprobante.sucursalCliente.direccion;
                    }
                });
                vm.comprobante.vendedor = vm.venta != undefined && vm.venta.pedidoVenta && vm.venta.pedidoVenta.vendedor ?
                vm.venta.pedidoVenta.vendedor :
                angular.copy(vm.comprobante.sucursalCliente.vendedor);
                if(vm.formSuscripcionRecurrente){
                    vm.comprobante.vendedor = vm.comprobante.sucursalCliente.vendedor;
                }
            } else if (vm.venta != undefined && vm.venta.pedidoVenta != undefined) {
                vm.comprobante.sucursalCliente = vm.venta.pedidoVenta.sucursalCliente;
                ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
                    if (response.valorTipo.codigo === "si") {
                        vm.comprobante.direccionDestino = vm.comprobante.sucursalCliente.direccion;
                    }
                });
            } else if (vm.sucursalesCliente.length > 1){
                vm.comprobante.sucursalCliente = vm.sucursalesCliente[0];
            }else{
                vm.comprobante.sucursalCliente = null;
            }

            if (vm.comprobante.cliente.descuento == undefined || vm.comprobante.cliente.descuento == null) {
                vm.comprobante.cliente.descuento = 0;
            }

            var i =0;
            _.forEach(vm.comprobante.comprobanteDetalle, function (elem) {
                elem.descuento = vm.comprobante.cliente.descuento != 0 ? vm.comprobante.cliente.descuento :elem.descuento ;
                if(vm.comprobante.cliente.exoneradoIva){
                  elem.montosImponibles[0].tipoImpuesto = vm.tipoExento
                } else {
                  if(elem.producto){
                    elem.montosImponibles[0].tipoImpuesto = elem.producto.tiposImpuesto[0]
                    changeUnidad(elem);
                  } else {
                    elem.montosImponibles[0].tipoImpuesto = elem.servicio.tipoImpuesto
                    changeServicio(elem)
                  }
                }
                i=i+1;
            });

            if (vm.comprobantesProductos.length > 0) {
                for (var i = 0; i < vm.comprobantesProductos.length; i++) {
                    if (Object.keys(vm.comprobantesProductos[0].montosImponibles[0]).length > 0) {
                        if (vm.comprobante.cliente.exoneradoIva) {
                            vm.comprobantesProductos[i].montosImponibles[0].tipoImpuesto = vm.tipoExento[0]
                        } else {
                            vm.comprobantesProductos[i].montosImponibles[0].tipoImpuesto = vm.comprobantesProductos[i].producto.tiposImpuesto[0]
                        }
                    }
                    vm.comprobantesProductos[i].descuento = vm.comprobante.cliente.descuento != 0 ? vm.comprobante.cliente.descuento : vm.comprobantesProductos[i].descuento;
                    if(vm.comprobantesProductos[i].id){
                        changeUnidad(vm.comprobantesProductos[i]);
                    }
                }
            }

            if (vm.comprobantesServicios.length > 0) {
                for (var i = 0; i < vm.comprobantesServicios.length; i++) {
                    if (Object.keys(vm.comprobantesServicios[0].montosImponibles[0]).length > 0) {

                        if (vm.comprobante.cliente.exoneradoIva) {
                            vm.comprobantesServicios[i].montosImponibles[0].tipoImpuesto = vm.tipoExento[0]
                        } else {
                            vm.comprobantesServicios[i].montosImponibles[0].tipoImpuesto = vm.comprobantesServicios[i].servicio.tipoImpuesto
                        }
                    }
                    vm.comprobantesServicios[i].descuento = vm.comprobante.cliente.descuento != 0?  vm.comprobante.cliente.descuento: vm.comprobantesServicios[i].descuento;
                    if (vm.comprobantesServicios[i].id) {
                        changeServicio(vm.comprobantesServicios[i]);
                    }
                }
            }
            vm.changeCotizacion();

            // if(vm.venta != undefined && vm.venta.pedidoVenta == undefined){
            var staticFilter = {};

            if (vm.comprobante.cliente.tipoVenta != null && vm.comprobante.cliente.tipoVenta.codigo === "condicion_cobro_pedido_venta_credito") {
            staticFilter = {
                search: filterFactory
                .or([
                    filterFactory
                    .and([{
                        path: "clase.codigo",
                        equals: "factura"
                    },
                        {
                        path: "utilizarEnProcesosVenta",
                        equals: true
                        },
                        {
                        path: "condicion.codigo",
                        equals: "tipo_comprobante_credito"
                        },
                        {
                        path: "activo",
                        like: "true"
                        }
                    ])
                    .value()
                ])
                .value()
            };
            } else {
            staticFilter = {
                search: filterFactory
                .or([
                    filterFactory
                    .and([{
                        path: "clase.codigo",
                        equals: "factura"
                    },
                        {
                        path: "utilizarEnProcesosVenta",
                        equals: true
                        },
                        {
                        path: "condicion.codigo",
                        equals: "tipo_comprobante_contado"
                        },
                        {
                        path: "activo",
                        like: "true"
                        }
                    ])
                    .value()
                ])
                .value()
            };
            }

            TipoComprobanteFactory.all(staticFilter, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                // vm.comprobante.tipo = vm.comprobante.tipo ? vm.comprobante.tipo : response[0];

                var tipoDefault = _.find(response, function (item) { return item.utilizarVentasDefecto === true });

                if (tipoDefault == undefined) {
                    tipoDefault = response[0];
                }


                if (vm.comprobante.pedidoVenta) {
                    vm.comprobante.tipo = vm.comprobante.tipo ? vm.comprobante.tipo : tipoDefault;
                } else {
                    if(vm.comprobante.tipo.codigo != tipoDefault.codigo){
                        vm.cantidadCuotas = undefined;
                        vm.plazoCuotas = undefined;
                        vm.montoInicial = undefined;
                    }
                    vm.comprobante.tipo = response.length > 0 ? tipoDefault : vm.comprobante.tipo;
                }
                filterFormasPago();
                // vm.comprobante.formaPago = vm.comprobante.formaPago ? vm.comprobante.formaPago : vm.comprobante.cliente.formaPago;
                if (vm.comprobante.pedidoVenta && vm.comprobante.formaPago) {
                    vm.comprobante.formaPago = vm.comprobante.formaPago;
                } else if (!vm.comprobante.pedidoVenta && vm.comprobante.cliente.formaPago) {
                    vm.comprobante.formaPago = vm.comprobante.cliente.formaPago;
                }
                if (vm.comprobante.tipo.generarCuotas && vm.comprobante.pedidoVenta) {
                    vm.cantidadCuotas = vm.comprobante.pedidoVenta.cantidadCuotas;

                    if(vm.comprobante.pedidoVenta.formaPago){
                        vm.plazoCuotas = vm.comprobante.pedidoVenta.formaPago.plazoDias;
                    }else if(vm.comprobante.formaPago){
                        vm.plazoCuotas = vm.comprobante.formaPago.plazoDias;
                    }else{
                        vm.plazoCuotas = 0;
                    }
                } else if (vm.comprobante.tipo.generarCuotas && vm.comprobante.cliente.formaPago) {
                    vm.cantidadCuotas = vm.comprobante.cliente.formaPago.cantidadCuotas;
                    vm.plazoCuotas = vm.comprobante.cliente.formaPago.plazoDias;
                }
                vm.uiBlockuiConfig.bloquear = false;

                if(vm.comprobante.tipo.clase.codigo == 'nota_debito'){
                   vm.relacionarFactura();
                }


                filterTimbrado();
            });

            //vm.comprobante.tipoOperacion = null;

            if (vm.datoVirtual && !vm.fromOutside && vm.comprobante.timbradoPuntoEmision &&
                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") verificarTipoOperacion(vm.comprobante.cliente);

            mostrarTodosProductos();

            if(vm.formSuscripcionRecurrente && !vm.view){
                vm.comprobante.formaPago = vm.comprobante.cliente.formaPago;
            }
            changeCienteSucursal();

            //buscar si existen comprobantes de tipo anticipo para el cliente seleccionado
            var staticFilterComprobanteAnticipo = {};
            staticFilterComprobanteAnticipo.search = filterFactory
            .or([ filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_emitido"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                    filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_aprobado_sifen"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                ])
                .value();

            ComprobanteVentaFactory.all(staticFilterComprobanteAnticipo, "ComprobanteVentaList").$promise.then(function (anticipos) {
                vm.existeAnticipo = false;
                if(anticipos.length > 0){
                    vm.existeAnticipo = true;
                    vm.comprobante.tipoAnticipo = vm.tipoAnticipos[0];
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobantesAnticipo =[];
                    var staticFilterComprobante = {
                        search: filterFactory
                            .and([{
                                path: "cliente.id",
                                equals: vm.comprobante.cliente.id
                            }, {
                                path: "estado.codigo",
                                notEquals: "comp_venta_anulado"
                            }])
                            .value()
                    };
                    ComprobanteVentaFactory.all(staticFilterComprobante, "ComprobanteVentaList").$promise.then(function (comprobantes) {
                        _.forEach(anticipos, function (anticipo) {
                            var comprobanteExiste = comprobantes.find(function(compro) {
                                if(compro.comprobanteAnticipo != null){
                                    if(compro.comprobanteAnticipo.id === anticipo.id){
                                        return true;
                                    }
                                }
                            });
                            if(!comprobanteExiste){
                                vm.comprobantesAnticipo.push(anticipo);
                            }
                        })

                    });
                }else{
                    vm.existeAnticipo = false;
                    vm.comprobante.tipoAnticipo = null;
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobantesAnticipo =[];
                }
            });
        }

        // Siempre que la sucursal del cliente tenga un Centro de Costos, este sera utilizado como el CC del comprobante
        if(vm.comprobante.sucursalCliente && vm.comprobante.sucursalCliente.centroCostos){
            vm.comprobante.centroCostos = vm.comprobante.sucursalCliente.centroCostos;
            //console.log("AQUI SETEO: ",vm.comprobante.centroCostos);
        }else if(vm.comprobante.sucursalCliente && !vm.comprobante.sucursalCliente.centroCostos){
            vm.comprobante.centroCostos = null;
        }
    }

    function changeCienteSucursal() {
        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                vm.comprobante.direccionDestino = vm.comprobante.sucursalCliente.direccion;
            }
        });
        vm.comprobante.vendedor = angular.copy(vm.comprobante.sucursalCliente.vendedor);
    }

    function changeConductor() {
        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                vm.comprobante.cedulaConductor = vm.comprobante.chofer.numeroDocumento;
                vm.comprobante.direccionConductor = vm.comprobante.chofer.transportista.direccion;
                vm.comprobante.nombreTransportista = vm.comprobante.chofer.transportista.nombre;
                vm.comprobante.rucTransportista = vm.comprobante.chofer.transportista.numeroDocumento;

            }
        });
    }

    function changeCamion() {
        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                vm.comprobante.nroChapa = vm.comprobante.vehiculo.numeroChapa;
            }
        });
    }

    function changeCienteSucursal() {
        if ((vm.comprobante.sucursalCliente && vm.comprobante.sucursalCliente.vendedor && !vm.comprobante.pedidoVenta) || (vm.comprobante.sucursalCliente && vm.comprobante.sucursalCliente.vendedor && vm.comprobante.pedidoVenta && !vm.venta.pedidoVenta.vendedor)) {
            vm.comprobante.vendedor = vm.comprobante.sucursalCliente.vendedor;
        }
        if(vm.comprobante.sucursalCliente){
            vm.comprobante.centroCostos = vm.comprobante.sucursalCliente.centroCostos;
        }
        if(vm.comprobante.tipo.clase.codigo === 'nota_credito'){
          vm.relacionarFactura();
        }
    }

    function changeTipoComprobante() {

        vm.cantidadCuotas = null;
        vm.plazoCuotas = null;
        vm.comprobante.formaPago = null;
        filterFormasPago();

        vm.montoInicial = undefined;

        vm.habilitarTimbradoSelect = false;

        vm.comprobante.motivoEmision = null;
        changeAlmacen();
        vm.comprobante.timbradoPuntoEmision = null;
        filterTimbrado();
        vm.changeCotizacion(vm.comprobante.moneda);

        vm.monedas = {};
        TiposFactory.monedas().then(function (data) {
            vm.monedas = data.data;
        });
        vm.comprobante.remisiones = undefined;
        if (vm.comprobante.tipo.clase.codigo == 'nota_credito' || vm.comprobante.tipo.clase.codigo == 'nota_debito') {
            vm.viewTipoApli = false;
            changeTipoAplicacion();
            vm.remisiones = vm.venta.remisiones.map(function (remision) {
                if (remision.estado.codigo != 'estados_remision_anulado') {
                    return remision;
                }
            });
        } else {
            vm.viewTipoApli = true;
            vm.tiposAplicaciones = {};
            vm.comprobante.tipoAplicacion = null;
            vm.remisiones = vm.venta.remisiones.map(function (remision) {
                if (remision.estado.codigo != 'estados_remision_anulado' && vm.recuperarRemisionFacturada == 'Sí') {
                    return remision;
                } else if (remision.estado.codigo != 'estados_remision_anulado' && vm.recuperarRemisionFacturada == 'No' && remision.facturado === false) {
                     return remision;
                }
            });
        }

        if (vm.isEdit && vm.comprobante.tipo.generarCuotas == false && vm.comprobante.planCobros != null) {
            vm.comprobante.planCobros = null;
        }

        if(vm.comprobante.tipo.clase.codigo === 'nota_credito' || vm.comprobante.tipo.clase.codigo == 'nota_debito'){
          vm.relacionarFactura();
        }

        if(vm.comprobante.tipo.clase.codigo == 'nota_debito'){
            vm.cantidadFacturaRelacionar = 1;
        }
    }

    function relacionarFactura(){
      if (vm.comprobante.cliente) {
        var filtersOr = [];
          var and1 = filterFactory.and([{
              path: "cliente.id",
              equals: vm.comprobante.cliente.id
          }, {
              path: "sucursalCliente.id",
              equals: vm.comprobante.sucursalCliente.id
          }, {
              path: "tipo.clase.codigo",
              equals: "factura"
          }, {
              path: "estado.codigo",
              equals: "comp_venta_emitido"
          }]).value();
          filtersOr.push(and1);

          var and2 = filterFactory.and([{
              path: "cliente.id",
              equals: vm.comprobante.cliente.id
          }, {
              path: "sucursalCliente.id",
              equals: vm.comprobante.sucursalCliente.id
          }, {
              path: "tipo.clase.codigo",
              equals: "factura"
          }, {
              path: "estado.codigo",
              equals: "comp_venta_aprobado_sifen"
          }]).value();
          filtersOr.push(and2);

          var staticFilterComprobante = { search: filterFactory.or(filtersOr).value() };

        ComprobanteVentaFactory.all(staticFilterComprobante, "RelacionarComprobanteVentaForm").$promise.then(function (response) {
          vm.relacionarFacturas = response;
          $timeout(function () {
            vm.comprobante.relacionarFacturas = vm.comprobante.relacionarFacturas ? vm.comprobante.relacionarFacturas : [];
            vm.staticFilterComprobantes = [];
            filtersOr = [];
              and1 = filterFactory.and([{
                  path: 'tipo.clase.codigo',
                  equals: 'factura'
              }, {
                  path: "estado.codigo",
                  equals: "comp_venta_emitido"
              }, {
                  path: "sucursalCliente.id",
                  equals: vm.comprobante.sucursalCliente.id
              }, {
                  path: "cliente.id",
                  equals: vm.comprobante.cliente.id
              }]).value();
              filtersOr.push(and1);
              and2 = filterFactory.and([{
                  path: 'tipo.clase.codigo',
                  equals: 'factura'
              }, {
                  path: "estado.codigo",
                  equals: "comp_venta_aprobado_sifen"
              }, {
                  path: "sucursalCliente.id",
                  equals: vm.comprobante.sucursalCliente.id
              }, {
                  path: "cliente.id",
                  equals: vm.comprobante.cliente.id
              }]).value();
              filtersOr.push(and2);

            vm.opcionesRelacionarFacturas.staticFilter = {
              search: filterFactory.or(filtersOr).value()
            };
          })
        });
      }
    }

    function obtenerDetallesComprobante(id){
        ComprobanteVentaFactory.get(id, "ComprobanteVentaList").$promise.then(function (response) {
          for(var i = 0; i < vm.comprobante.relacionarFacturas.length; i++){
            if(vm.comprobante.relacionarFacturas[i].id === id){
              vm.comprobante.relacionarFacturas[i] = response;
            }
          }
        });
    }

    function filterTimbrado(timbradoEmpresa) {
        if (vm.watcherTimbrado) {
            vm.watcherTimbrado();
        }
        WatchLocacionChangeFactory.executeWatching(function (
            locacionFilter,
            watcherTimbrado
        ) {
            vm.watcherTimbrado = watcherTimbrado;
            //if (vm.comprobante != undefined && vm.comprobante.tipo != undefined && vm.comprobante.fecha != undefined && vm.puntoEmisionVentaActual != undefined) {


            var staticFilter2 = {};
            var andFiltersArray = [{
                path: "timbradoEmpresa.fechaDesde",
                equalOrBefore: moment(vm.comprobante.fecha).format("DD/MM/YYYY")
            },
            {
                path: "timbradoEmpresa.fechaHasta",
                equalOrAfter: moment(vm.comprobante.fecha).format("DD/MM/YYYY")
            }
            ];

            if (vm.comprobante && vm.comprobante.tipo) {
                andFiltersArray.push({
                    path: "tipoComprobante.codigo",
                    equals: vm.comprobante.tipo.clase.codigo
                })
            }

            if (vm.comprobante && vm.puntoEmisionVentaActual) {
                andFiltersArray.push({
                    path: "puntoEmisionVenta.id",
                    equals: vm.puntoEmisionVentaActual.id
                })
            }

            if (vm.fromOutside === true) {
                andFiltersArray.push({
                    path: "timbradoEmpresa.claseTimbrado.codigo",
                    equals: "timbrado_manual"
                })
            } else {
                andFiltersArray.push({
                    path: "timbradoEmpresa.claseTimbrado.codigo",
                    notEquals: "timbrado_manual"
                })
            }

            andFiltersArray.push(
                {
                    path: "timbradoEmpresa.activo",
                    equals: true
                })

            var extraConditions = [{
                path: "timbradoEmpresa.sucursal.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
            },
            {
                path: "timbradoEmpresa.sucursal.id",
                isNull: true
            }
            ]

            var finalAndFilters = _.map(extraConditions, function (cond) {
                var arr = [cond]
                arr = arr.concat(andFiltersArray)
                return filterFactory.and(arr).value();
            })
            staticFilter2.search = filterFactory
                .or(finalAndFilters)
                .value();

            TimbradoEmpresaPuntoEmisionVentaFactory.all(staticFilter2, "ConsultaComprobanteVentaForm").$promise.then(function (data) {

                vm.habilitarTimbradoSelect = true;

                vm.timbradosPuntoEmision = data;
                _.forEach(vm.timbradosPuntoEmision, function (obj) {
                    obj.timbradoEmpresa.fechaDesde = moment(obj.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
                    obj.timbradoEmpresa.fechaHasta = moment(obj.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY");
                })


                if (vm.timbradosPuntoEmision == null || vm.timbradosPuntoEmision == undefined || vm.timbradosPuntoEmision.length == 0) {
                    var msg = $filter("translate")("NO_TIMBRADO_FOUND");
                    notify({ message: msg, classes: "alert-danger", position: "right" });
                }

                if (timbradoEmpresa != undefined) {
                    vm.comprobante.timbradoPuntoEmision = _.find(vm.timbradosPuntoEmision, function (timbrado) {
                        if (timbradoEmpresa.numeroTimbrado === timbrado.timbradoEmpresa.numeroTimbrado) {
                            return timbrado;
                        }
                    });
                }
                if(vm.timbradosPuntoEmision != null && vm.timbradosPuntoEmision != undefined && vm.timbradosPuntoEmision.length == 1 && vm.comprobante.cliente != null && vm.comprobante.cliente != undefined){
                  vm.comprobante.timbradoPuntoEmision = vm.timbradosPuntoEmision[0];
                  var timbradoAux = null;
                  if(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual"){
                    timbradoAux = _.find(vm.tipoTransacciones, function (tipo) {
                      if ('venta_mercaderia' === tipo.codigo) {
                          return tipo;
                      }
                    });
                    if((vm.comprobante.tipoTransaccion == undefined || vm.comprobante.tipoTransaccion == null) && timbradoAux != null){
                      vm.comprobante.tipoTransaccion = timbradoAux;
                    }
                  }else{
                    vm.comprobante.tipoTransaccion = null;
                  }
                  if (vm.datoVirtual && !vm.fromOutside && vm.comprobante.cliente &&
                    vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") verificarTipoOperacion(vm.comprobante.cliente);
                }

            });
            //}
            if (vm.comprobante) {
                vm.comprobante.numeroTimbrado = undefined;
            }

        }, "locacion.id");
    }

    function showNoPEVMessage() {
        var msg = $scope.translations.NO_PEV_CAJA_MESSAGE;
        notify({ message: msg, classes: "alert-danger", position: "right" });
    }

    function getPuntosEmisionVenta(timbradoEmpresa) {
        if (!vm.fromOutside && !vm.formSuscripcionRecurrente) {
            var caja = null;
            _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                    caja = cajas;
                }
            });
            if (vm.comprobante != undefined && vm.comprobante.timbradoPuntoEmision != undefined) {
                if (caja.puntoEmision.id != vm.comprobante.timbradoPuntoEmision.puntoEmisionVenta.id && !$state.is("app.ventas.proceso.comprobanteventa.view")) {
                    vm.cancel();
                    var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR4');
                    notify({ message: msg, classes: "alert-danger", position: "right" });
                }
            } else {

                if (caja != null) {
                    cajaFactory.getCaja(caja.id, "CajaList").$promise.then(function (caja) {
                        puntoVentaFactory.get(caja.puntoEmision.puntoVenta.id, "ConsultaComprobanteVentaForm").$promise.then(function (puntoVenta) {
                            if (puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                vm.puntoEmisionVentaActual = caja.puntoEmision;
                            } else {
                                var msg = "La sucursal del sistema no coincide con la sucursal de la caja registrada";
                                notify({ message: msg, classes: "alert-danger", position: "right" });
                            }
                        });
                    });
                } else {
                    var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                    notify({ message: msg, classes: "alert-danger", position: "right" });
                }
            }

        } else {
            if (vm.watchPE) {
                vm.watchPE();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcherPE = watcher;
                var filters = {
                    search: filterFactory.and([{
                        path: "puntoVenta.sucursal.id",
                        equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                    }]).value()
                }
                vm.puntosEmision = puntoEmisionFactory.all(filters, "ConsultaComprobanteVentaForm");
            }, "locacion.id");
        }
    }

    function changeProducto(detalle, index) {
        //Todos los comprobantes generan movimiento
        //vm.comprobanteGeneraMovimiento = true;

        var isPV = estaEnPV(detalle);

        if (!isPV && (vm.comprobante.deposito == undefined || vm.comprobante.deposito.id == undefined) && vm.comprobanteGeneraMovimiento) {
            var msg = $filter('translate')('VERIFIQUE_ALMACEN');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            vm.comprobantesProductos.pop();

            return;
        }

        if (!isPV && vm.comprobante.tipo == undefined && vm.comprobanteGeneraMovimiento) {
            var msg = $filter('translate')('VERIFIQUE_TIPO_COMPROBANTE');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            vm.comprobantesProductos.pop();

            return;
        }

        detalle.descuento = vm.comprobante.cliente.descuento;


        detalle.servicio = null;
        detalle.cantidad = 1;
        detalle.cantidadView = null;
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        detalle.codigoBarras = [detalle.producto.codigoBarrasPrincipal.codigoBarras];

        if (vm.comprobante.listaPrecioVenta) {
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'listaPrecioVenta.id',
                equals: vm.comprobante.listaPrecioVenta.id,
            },
            {
                path: 'producto.id',
                equals: detalle.producto.id,
            }]).value();
            staticFilter.view = "ComprobanteVentaForm";
            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                if ((response == undefined || response.length == 0) && vm.permitirProductosNotListaPrecio == 'No') {
                    var msg = $filter("translate")("PRODUCTO_NO_EXISTE_LISTA_PRECIO");
                    notify({ message: msg, classes: "alert-warning", position: "right" })
                    removeItemFromArray(detalle, vm.comprobantesProductos, index);
                    return;
                } else {
                    changeUnidad(detalle, index, response);

                }
            });
        } else {
            changeUnidad(detalle);
        }

        if (!vm.comprobante.grupoCompra ||
            vm.comprobante.grupoCompra.codigo != "comprobante_importacion"
        ) {
          if (vm.comprobante.cliente.exoneradoIva) {
            detalle.montosImponibles[0].tipoImpuesto =
                vm.tipoExento[0];
            detalle.montosImponibles[0].porcentaje =
                vm.tipoExento[0].porcentaje;
            detalle.montosImponibles[0].valor = 0;
          } else {
            detalle.montosImponibles[0].tipoImpuesto =
                detalle.producto.tiposImpuesto[0];
            detalle.montosImponibles[0].porcentaje =
                detalle.producto.tiposImpuesto[0].porcentaje;
            detalle.montosImponibles[0].valor = 0;
          }
        }
        detalle.totalItem = 0;
        detalle.grupoProducto = null;

        if (detalle.producto.criterioControl.codigo === "series") {
            detalle.cantidad = 1;
        }
        if (vm.comprobanteGeneraMovimiento && (detalle.producto.criterioControl.codigo === "series" || detalle.producto.criterioControl.codigo === "lotes") && !isPV) {
            getStockPorTipoComprobante(detalle);
        }
    }

    function estaEnPV(detalle) {
        if (vm.venta != undefined && vm.venta.pedidoVenta != undefined) {
            var pedidoVentaDetalle = _.find(vm.venta.pedidoVenta.pedidoVentaDetalle, function (pedido) {
                if (pedido.producto.id === detalle.producto.id) {
                    return pedido;
                }
            });
            if (pedidoVentaDetalle != null) return true;
        }
        return false;
    }

    function changeUnidad(detalle, index, productosFromListaPrecio) {
        //Si se utiliza el precio Base y el cliente está asociado a una lista de precios de ventas.
        if (vm.utilizarPrecioBase && vm.comprobante.listaPrecioVenta) {
            var precioBase = (vm.comprobante.listaPrecioVenta.precioBase) ? vm.comprobante.listaPrecioVenta.precioBase.codigo : '';
            if (precioBase == 'ultimo_costo') {

                if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambioPactado) {
                    //si el comprobante es en gs y la lista en moneda extranjera
                    detalle.precioVenta = parseFloat(vm.ultimoCostoIVA.replace(/,/, "."));
                } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                    //si el comprobante es en moneda extranjera y la lista en Gs
                    var precioFinal = vm.ultimoCostoIVA / vm.comprobante.cambio;
                    detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambio) {
                    //si el comprobante es en moneda extranjera y la lista en moneda extranjera
                    var precioFinal = vm.ultimoCostoIVA / vm.comprobante.cambio;
                    detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                } else {
                    detalle.precioView = vm.ultimoCostoIVA.toString().replace(/\./, ",");
                    detalle.precioVenta = parseFloat(detalle.precioView.replace(/,/, "."));
                }
                if (detalle.precioVenta > 0 && vm.comprobante.listaPrecioVenta.porcentajeRecargo > 0) {
                    vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                    var precioAux = detalle.precioVenta * (vm.porcRecargo / 100) + detalle.precioVenta;
                    detalle.precioVenta = UtilFactory.roundForMoneda(precioAux, vm.comprobante.moneda);
                }
                calcularTotalDetalle(detalle, index);
            }
            else {
                CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                    if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambioPactado) {
                        //si el comprobante es en gs y la lista en moneda extranjera
                        detalle.precioVenta = parseFloat(precio.data.replace(/,/, "."));
                    } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                        //si el comprobante es en moneda extranjera y la lista en Gs
                        var precioFinal = precio.data / vm.comprobante.cambio;
                        detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                    } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambio) {
                        //si el comprobante es en moneda extranjera y la lista en moneda extranjera
                        var precioFinal = precio.data / vm.comprobante.cambio;
                        detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                    } else {
                        detalle.precioView = precio.data.toString().replace(/\./, ",");
                        detalle.precioVenta = parseFloat(detalle.precioView.replace(/,/, "."));
                    }
                    if (detalle.precioVenta > 0 && vm.comprobante.listaPrecioVenta.porcentajeRecargo > 0) {
                        vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                        var precioAux = detalle.precioVenta * (vm.porcRecargo / 100) + detalle.precioVenta;
                        detalle.precioVenta = UtilFactory.roundForMoneda(precioAux, vm.comprobante.moneda);
                    }
                    calcularTotalDetalle(detalle, index);
                });
            }
        } else if (!vm.utilizarPrecioBase && vm.comprobante.listaPrecioVenta) {
            var productos = [];
            var productoLista = null;

            if (productosFromListaPrecio) {

                for(var i = 0; i < productosFromListaPrecio.length; i++){
                    if (detalle.unidadMedida.id == productosFromListaPrecio[i].unidadMedida.id) {
                        productoLista =  productosFromListaPrecio[i];
                    }
                }
                if (productoLista) {
                    if (vm.comprobante.cliente.exoneradoIva) {
                      productoLista.precioVenta = calcularPrecioSinImpuestos(productoLista.precioVenta, detalle.producto.tiposImpuesto[0].porcentaje)
                    }
                    if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                        //si el comprobante es en gs y la lista en moneda extranjera
                        detalle.precioVenta = productoLista.precioVenta * vm.comprobante.cambioPactado;
                        detalle.precioMinimo = productoLista.precioMinimo * vm.comprobante.cambioPactado;
                        detalle.precioMaximo = productoLista.precioMaximo * vm.comprobante.cambioPactado;
                        detalle.precioVenta = UtilFactory.roundForMoneda(detalle.precioVenta, vm.comprobante.moneda)
                    } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                        //si el comprobante es en moneda extranjera y la lista en gs
                        var precioFinal = productoLista.precioVenta / vm.comprobante.cambio;
                        var precioMin = productoLista.precioMinimo / vm.comprobante.cambio;
                        var precioMax = productoLista.precioMaximo / vm.comprobante.cambio;
                        detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                        detalle.precioMinimo = Math.round(precioMin * 100) / 100;
                        detalle.precioMaximo = Math.round(precioMax * 100) / 100;
                    } else {
                        detalle.precioVenta = productoLista.precioVenta;
                        detalle.precioMinimo = productoLista.precioMinimo;
                        detalle.precioMaximo = productoLista.precioMaximo;
                    }
                    var obj = {
                        "precioMinimo": detalle.precioMinimo,
                        "precioMaximo": detalle.precioMaximo
                    };
                    vm.precioMinimoMaximos.splice(index, 1, obj);

                    vm.productoPerteneceLista = true;
                } else {
                    vm.productoPerteneceLista = false;
                    detalle.precioVenta = 0;
                    CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                        if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambioPactado) {
                            //si el comprobante es en gs y la lista en moneda extranjera
                            detalle.precioVenta = precio.data * vm.comprobante.cambioPactado;
                            detalle.precioVenta = UtilFactory.roundForMoneda(detalle.precioVenta, vm.comprobante.moneda)
                        } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                            //si el comprobante es en moneda extranjera y la lista en gs
                            var precioFinal = precio.data / vm.comprobante.cambio;
                            detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                        } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambio) {
                            //si el comprobante es en moneda extranjera y la lista en moneda extranjera
                            var precioFinal = precio.data / vm.comprobante.cambio;
                            detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                        } else {
                            detalle.precioView = precio.data.toString().replace(/\./, ",");
                            detalle.precioVenta = parseFloat(detalle.precioView.replace(/,/, "."));
                        }
                        calcularTotalDetalle(detalle, index);
                    });
                }
                calcularTotalDetalle(detalle, index);
            } else {
                //El cliente está asociado a una lista de precios de ventas sin detalles
            }
        } else {
            CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                if (vm.comprobante.cliente.exoneradoIva) {
                  precio.data = calcularPrecioSinImpuestos(precio.data, detalle.producto.tiposImpuesto[0].porcentaje)
                }
                if(precio.data <= 0){
                  precio.data = detalle.precioVenta ? detalle.precioVenta : 0;
                }
                if (vm.comprobante.moneda.codigo == "guaranies") {
                    detalle.precioView = precio.data.toString().replace(/\./, ",");
                    detalle.precioVenta = parseFloat(detalle.precioView.replace(/,/, "."));
                } else {
                    if (vm.comprobante.cambio) {
                        var precioFinal = precio.data / vm.comprobante.cambio;
                        detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                    }
                }

                calcularTotalDetalle(detalle, index);
            });
        }

    }

    function changeServicio(detalle) {

        detalle.producto = null;
        detalle.cantidad = 1;
        if (vm.comprobante.cliente.exoneradoIva) {
          detalle.montosImponibles[0].tipoImpuesto = vm.tipoExento[0];
          detalle.montosImponibles[0].porcentaje = vm.tipoExento[0].porcentaje;
          detalle.montosImponibles[0].valor = 0;
        } else {
          detalle.montosImponibles[0].tipoImpuesto = detalle.servicio.tipoImpuesto;
          detalle.montosImponibles[0].porcentaje =
            detalle.servicio.tipoImpuesto.porcentaje;
          detalle.montosImponibles[0].valor = 0;
        }
        detalle.totalItem = 0;

        CambioPrecioServicioFactory.getUltimoPrecio(detalle.servicio.id).then(function (precio) {
            if (vm.comprobante.cliente.exoneradoIva) {
              precio.data = calcularPrecioSinImpuestos(precio.data, detalle.servicio.tipoImpuesto.porcentaje)
            }
            if(precio.data <= 0){
              precio.data = detalle.precioVenta ? detalle.precioVenta : 0;
            }
            var aux = parseFloat(precio.data).toFixed(2);
            detalle.precioVentaView = aux.toString().replace(/\./, ",");
            detalle.precioVenta = parseFloat(detalle.precioVentaView.replace(/,/, "."));

            vm.calcularTotalDetalle(detalle);
        });
    }

    function changeFecha() {
        vm.habilitarTimbradoSelect = false;
        vm.comprobante.timbradoPuntoEmision = undefined;
        //changeCotizacion();
        filterTimbrado();
        vm.comprobante.timbradoPuntoEmision = undefined;
        vm.changeCotizacion(vm.comprobante.moneda);
        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                if (vm.comprobante.fechaInicio)
                    vm.comprobante.fechaInicio = new Date(vm.comprobante.fechaInicio);
                if (vm.comprobante.fechaFin)
                    vm.comprobante.fechaFin = new Date(vm.comprobante.fechaFin);
            }
        });
    }

    function changeFechaSuscripcion() {

    }

    function changeCotizacion(isNew) {

        //if (vm.new == true) {
        // var hoy = new Date();
        // hoy.setHours(0, 0, 0, 0);
        // vm.comprobante.fecha = hoy;
        // }

        if (vm.comprobante.comprobanteDetalle && vm.comprobante.comprobanteDetalle.length > 0) {
            vm.comprobante.comprobanteDetalle.map(function (detalle) {
                calcularTotalDetalle(detalle);
            })
        }

        /* Solo cuando se selecciona la moneda se revisa si el cliente está
            asociado a una lista de precios de ventas. */
        if (!vm.comprobante.pedidoVenta && vm.comprobante.cliente && !vm.fromOutside) {

            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'cliente.id',
                equals: vm.comprobante.cliente.id
            }, {
                path: 'estado.codigo',
                equals: 'lista_activada'
            }]).value();

            ListaPrecioVentaFactory.all(staticFilter, "ComprobanteVentaForm").$promise.then(function (response) {
                /* Se controla si el cliente está asociado a una lista de precios por asociación directa
                con la moneda seleccionada */
                if (response.length > 0) {
                    vm.comprobante.listaPrecioVenta = response[0];
                    vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                    if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                        //si el comprobante es en gs y la lista en moneda extranjera
                        vm.mostrarCambioPactado = true;
                        vm.monedaLocal.$promise.then(function (data) {

                            if (vm.comprobante.monedaLista && vm.comprobante.monedaLista.descripcion != data[0].valorTipo.descripcion) {
                                vm.cotizacionRequerida = true;

                                var params = {};
                                params.search = filterFactory.and([{
                                    path: 'monedaDesde.id',
                                    equals: vm.comprobante.monedaLista.id
                                },
                                {
                                    path: 'monedaHasta.id',
                                    equals: vm.monedaLocal[0].valorTipo.id
                                },
                                {
                                    path: 'fecha',
                                    like: moment(vm.comprobante.fecha).format('DD/MM/YYYY')
                                    //equals:vm.comprobante.fecha
                                }
                                ]).value();
                                params.view = 'CotizacionMonedaList';
                                CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                                    if (response && response[0]) {
                                        vm.comprobante.cambioPactado = response[0].valorCompra;
                                    } else {
                                        vm.comprobante.cambioPactado = null;
                                    }
                                });
                            } else {
                                vm.cotizacionRequerida = false;
                                vm.comprobante.cambioPactado = 1;
                            }
                        });
                    } else {
                        vm.mostrarCambioPactado = false;
                    }
                    vm.getListasPreciosVentasByMoneda();
                    vm.actualizarPreciosDeVentas();
                    ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
                        if (parametro.valorTipo.codigo === 'no') {
                            vm.utilizarPrecioBase = false;
                        } else {
                            vm.utilizarPrecioBase = true;
                            if(vm.comprobante.listaPrecioVenta){
                                vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                                if(vm.comprobante.listaPrecioVenta.precioBase){
                                    vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                                }
                            }
                        }
                    });
                } else if (response.length === 0 && vm.comprobante.cliente.tipoCliente) {
                    /* Se controla si el cliente está asociado a una lista de precios por su tipo de cliente
                    con la moneda seleccionada */
                    $timeout(function () {
                        var staticFilter = {};
                        staticFilter.search = filterFactory.and([{
                            path: 'tipoCliente.id',
                            equals: vm.comprobante.cliente.tipoCliente.id
                        }, {
                            path: 'estado.codigo',
                            equals: 'lista_activada'
                        }, {
                            path: 'moneda.codigo',
                            equals: vm.comprobante.moneda.codigo
                        }]).value();
                        ListaPrecioVentaFactory.all(staticFilter, "ComprobanteVentaForm").$promise.then(function (response) {
                            if (response.length > 0) {
                                vm.uiBlockuiConfig.bloquear = false;
                                vm.comprobante.listaPrecioVenta = response[0];
                                vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                                vm.actualizarPreciosDeVentas();
                                vm.getListasPreciosVentasByMoneda();
                                ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
                                    if (parametro.valorTipo.codigo === 'no') {
                                        vm.utilizarPrecioBase = false;
                                    } else {
                                        vm.utilizarPrecioBase = true;
                                        if(vm.comprobante.listaPrecioVenta){
                                            vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                                            if(vm.comprobante.listaPrecioVenta.precioBase){
                                                vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                                            }
                                        }
                                    }
                                });
                            } else {
                                vm.comprobante.listaPrecioVenta = undefined;
                                // vm.comprobante.monedaLista = undefined;
                                vm.porcRecargo = 0;
                                vm.precioBase = 'Precio de Venta'
                                vm.actualizarPreciosDeVentas();
                                vm.getListasPreciosVentasByMoneda();
                                //vm.comprobante.monedaLista = undefined;
                                vm.comprobante.cambioPactado = undefined;
                            }
                        });
                    });
                } else {
                    /* Si el cliente está asociado a una Lista de Precios de Ventas, pero no con la moneda
                    seleccionada. */
                    vm.comprobante.listaPrecioVenta = undefined;
                    // vm.comprobante.monedaLista = undefined;
                    vm.porcRecargo = 0;
                    vm.precioBase = 'Precio de Venta'
                    vm.actualizarPreciosDeVentas();
                    vm.getListasPreciosVentasByMoneda();
                    //vm.comprobante.monedaLista = undefined;
                    vm.comprobante.cambioPactado = undefined;
                }
            });
        } else {
            vm.precioBase = 'Precio de Venta';
            vm.porcRecargo = 0;
        }


        vm.monedaLocal.$promise.then(function (data) {
            if (
                vm.comprobante.moneda &&
                vm.comprobante.moneda.descripcion != data[0].valorTipo.descripcion
            ) {
                vm.cotizacionRequerida = true;

                var params = {};
                var fechaFormateada = moment(vm.comprobante.fecha).format('DD/MM/YYYY');
                params.search = filterFactory
                    .or([
                        filterFactory
                            .and([{
                                path: "monedaDesde.id",
                                equals: vm.comprobante.moneda.id
                            },
                            {
                                path: "monedaHasta.id",
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: "fecha",
                                like: fechaFormateada
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "monedaDesde.id",
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: "monedaHasta.id",
                                equals: vm.comprobante.moneda.id
                            },
                            {
                                path: "fecha",
                                like: fechaFormateada
                            }
                            ])
                            .value()
                    ])
                    .value();
                CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                    if (response.length > 0) {
                        //los comprobantes de venta deben utilizar el valor compra. QUAL-11969

                        if (vm.venta && vm.venta.pedidoVenta) {
                            vm.comprobante.cambio = vm.venta.pedidoVenta.cambio;
                        } else {
                            vm.comprobante.cambio = response[0].valorCompra;
                        }
                    } else {
                        if (
                            vm.comprobante.ordenCompra != null &&
                            vm.comprobante.ordenCompra != undefined
                        ) {
                            vm.comprobante.cambio = vm.ordenCompra.tipoCambio;

                        } else if (vm.venta && vm.venta.pedidoVenta) {
                            vm.comprobante.cambio = vm.venta.pedidoVenta.cambio;
                        } else {
                            vm.comprobante.cambio = null;
                        }
                    }
                });
            } else {
                vm.cotizacionRequerida = false;
                vm.comprobante.cambio = 1;
            }
        });
        if(vm.existeAnticipo){
            //buscar si existen comprobantes de tipo anticipo para el cliente seleccionado
            var staticFilterComprobanteAnticipo = {};
            staticFilterComprobanteAnticipo.search = filterFactory
            .or([ filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_emitido"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                    filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_aprobado_sifen"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                ])
                .value();

            ComprobanteVentaFactory.all(staticFilterComprobanteAnticipo, "ComprobanteVentaList").$promise.then(function (anticipos) {
                if(anticipos.length > 0){
                    vm.comprobante.tipoAnticipo = vm.tipoAnticipos[0];
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobante.importeAnticipo = 0;
                    vm.comprobantesAnticipo =[];
                    var staticFilterComprobante = {
                        search: filterFactory
                            .and([{
                                path: "cliente.id",
                                equals: vm.comprobante.cliente.id
                            }, {
                                path: "estado.codigo",
                                notEquals: "comp_venta_anulado"
                            }])
                            .value()
                    };
                    ComprobanteVentaFactory.all(staticFilterComprobante, "ComprobanteVentaList").$promise.then(function (comprobantes) {
                        _.forEach(anticipos, function (anticipo) {
                            var comprobanteExiste = comprobantes.find(function(compro) {
                                if(compro.comprobanteAnticipo != null){
                                    if(compro.comprobanteAnticipo.id === anticipo.id){
                                        return true;
                                    }
                                }
                            });
                            if(!comprobanteExiste){
                                vm.comprobantesAnticipo.push(anticipo);
                            }
                        })

                    });
                }else{
                    vm.comprobante.tipoAnticipo = vm.tipoAnticipos[0];
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobante.importeAnticipo = 0;
                    vm.comprobantesAnticipo =[];
                    vm.comprobante.comprobanteAnticipo = null;
                }
            });
        }
    }

    function searchProductoFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.codigo == criteria ||
                (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0
            );
        };
    }

    function totalItemChanged(detalle, index) {

        if (detalle.cantidad == undefined) {
            detalle.cantidad = 1;
        }

        detalle.precioVenta = parseFloat((detalle.totalItem / detalle.cantidad).toFixed(3));
        //detalle.precioVenta = UtilFactory.roundForMoneda(detalle.precioVenta, vm.comprobante.moneda);
        controlPrecioUltimoCosto(detalle, index);

        if (detalle.montosImponibles) {
            if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0)
                detalle.montosImponibles[0].baseImponible = Math.round(
                    100 *
                    detalle.totalItem /
                    (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje)
                );
            else
                detalle.montosImponibles[0].baseImponible = detalle.totalItem;
            detalle.montosImponibles[0].baseImponible = UtilFactory.roundForMoneda(
                detalle.montosImponibles[0].baseImponible,
                vm.comprobante.moneda);
            detalle.montosImponibles[0].valor =
                detalle.totalItem - detalle.montosImponibles[0].baseImponible;
            detalle.montosImponibles[0].valor = UtilFactory.roundForMoneda(detalle.montosImponibles[0].valor, vm.comprobante.moneda);
        }
        vm.totalGeneralProductos = vm.comprobantesProductos ? _.reduce(
            vm.comprobantesProductos,
            function (sum, detalle) {
                return sum + (detalle.totalItem != undefined ? detalle.totalItem : 0);
            },
            0
        ) : 0;

        vm.totalGeneralProductos = UtilFactory.roundForMoneda(vm.totalGeneralProductos, vm.comprobante.moneda);
        vm.totalGeneralServicios = vm.comprobantesServicios ? _.reduce(
            vm.comprobantesServicios,
            function (sum, detalle) {
                return sum + (detalle.totalItem !== undefined ? detalle.totalItem : 0);
            },
            0
        ) : 0;
        vm.totalGeneralServicios = UtilFactory.roundForMoneda(vm.totalGeneralServicios, vm.comprobante.moneda);

        vm.totalGeneral = vm.totalGeneralProductos + vm.totalGeneralServicios;
    }

    function cargarImpuestos() {
        var sumBase10 = 0;
        var sumBase5 = 0;
        var sumBaseExe = 0;
        var total10 = 0;
        var total5 = 0;
        var totalExe = 0;
        var impuesto10 = 0;
        var impuesto5 = 0;
        var impuestoExe = 0;
        var tipoImpuesto10;
        var tipoImpuesto5;
        var tipoImpuestoExe;

        for (var i = 0; i < vm.comprobantesProductos.length; i++) {
            var elem = vm.comprobantesProductos[i];
            if(elem.montosImponibles[0].porcentaje != undefined){
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 10) {
                    sumBase10 += elem.montosImponibles[0].baseImponible;
                    total10 += elem.totalItem;
                    tipoImpuesto10 = elem.montosImponibles[0].tipoImpuesto;
                }
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 5) {
                    sumBase5 += elem.montosImponibles[0].baseImponible;
                    total5 += elem.totalItem;
                    tipoImpuesto5 = elem.montosImponibles[0].tipoImpuesto;
                }
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 0) {
                    sumBaseExe += elem.montosImponibles[0].baseImponible;
                    totalExe += elem.totalItem;
                    tipoImpuestoExe = elem.montosImponibles[0].tipoImpuesto;
                }
            }
        }
        impuesto10 = total10 - sumBase10;
        impuesto5 = total5 - sumBase5;
        impuestoExe = totalExe - sumBaseExe;

        for (var i = 0; i < vm.comprobantesServicios.length; i++) {
            var elem = vm.comprobantesServicios[i];
            if(elem.montosImponibles[0].porcentaje != undefined){
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 10) {
                    sumBase10 += elem.montosImponibles[0].baseImponible;
                    total10 += elem.totalItem;
                    tipoImpuesto10 = elem.montosImponibles[0].tipoImpuesto;
                }
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 5) {
                    sumBase5 += elem.montosImponibles[0].baseImponible;
                    total5 += elem.totalItem;
                    tipoImpuesto5 = elem.montosImponibles[0].tipoImpuesto;
                }
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 0) {
                    sumBaseExe += elem.montosImponibles[0].baseImponible;
                    totalExe += elem.totalItem;
                    tipoImpuestoExe = elem.montosImponibles[0].tipoImpuesto;
                }
            }
        }
        impuesto10 = total10 - sumBase10;
        impuesto5 = total5 - sumBase5;
        impuestoExe = totalExe - sumBaseExe;

        impuesto10 = parseFloat(impuesto10.toFixed(4));
        impuesto5 = parseFloat(impuesto5.toFixed(4));
        impuestoExe = parseFloat(impuestoExe.toFixed(4));

        sumBase10 = parseFloat(sumBase10.toFixed(4));
        sumBase5 = parseFloat(sumBase5.toFixed(4));
        sumBaseExe = parseFloat(sumBaseExe.toFixed(4));

        if (tipoImpuesto10 != undefined && tipoImpuesto5 != undefined && tipoImpuestoExe != undefined) {
            vm.comprobante.montosImponibles = [{
                tipoImpuesto: tipoImpuesto10,
                total: total10,
                baseImponible: sumBase10,
                valor: impuesto10
            },
            {
                tipoImpuesto: tipoImpuesto5,
                total: total5,
                baseImponible: sumBase5,
                valor: impuesto5
            },
            {
                tipoImpuesto: tipoImpuestoExe,
                total: totalExe,
                baseImponible: sumBaseExe,
                valor: impuestoExe
            }];
        } else

            if (tipoImpuesto10 != undefined && tipoImpuesto5 != undefined) {
                vm.comprobante.montosImponibles = [{
                    tipoImpuesto: tipoImpuesto10,
                    total: total10,
                    baseImponible: sumBase10,
                    valor: impuesto10
                },
                {
                    tipoImpuesto: tipoImpuesto5,
                    total: total5,
                    baseImponible: sumBase5,
                    valor: impuesto5
                }];
            } else

                if (tipoImpuesto5 != undefined && tipoImpuestoExe != undefined) {
                    vm.comprobante.montosImponibles = [
                        {
                            tipoImpuesto: tipoImpuesto5,
                            total: total5,
                            baseImponible: sumBase5,
                            valor: impuesto5
                        },
                        {
                            tipoImpuesto: tipoImpuestoExe,
                            total: totalExe,
                            baseImponible: sumBaseExe,
                            valor: impuestoExe
                        }];
                } else

                    if (tipoImpuesto10 != undefined && tipoImpuestoExe != undefined) {
                        vm.comprobante.montosImponibles = [{
                            tipoImpuesto: tipoImpuesto10,
                            total: total10,
                            baseImponible: sumBase10,
                            valor: impuesto10
                        },
                        {
                            tipoImpuesto: tipoImpuestoExe,
                            total: totalExe,
                            baseImponible: sumBaseExe,
                            valor: impuestoExe
                        }];
                    } else

                        if (tipoImpuesto10 != undefined) {
                            vm.comprobante.montosImponibles = [{
                                tipoImpuesto: tipoImpuesto10,
                                total: total10,
                                baseImponible: sumBase10,
                                valor: impuesto10
                            }];
                        } else

                            if (tipoImpuesto5 != undefined) {
                                vm.comprobante.montosImponibles = [
                                    {
                                        tipoImpuesto: tipoImpuesto5,
                                        total: total5,
                                        baseImponible: sumBase5,
                                        valor: impuesto5
                                    },
                                ];
                            } else

                                if (tipoImpuestoExe != undefined) {
                                    vm.comprobante.montosImponibles = [
                                        {
                                            tipoImpuesto: tipoImpuestoExe,
                                            total: totalExe,
                                            baseImponible: sumBaseExe,
                                            valor: impuestoExe
                                        }];
                                }


        vm.comprobante.montoTotal = total10 + total5 + totalExe;
    }

    function submit() {

        vm.submited = true;
        vm.submitedAgil = true;
        vm.comprobante.comprobanteDetalle = _.filter(
            vm.comprobante.comprobanteDetalle,
            function (elem) {
                return elem.producto || elem.servicio;
            }
        );


        /*
         Comprobacion de tener al menos un detalle en el comprobante.
         Se implemento de esta forma para evitar errores en la consola del explorador, no se puede consultar por el valor
         de 'vm.comprobantesProductos[0].producto' sin antes asegurarse de que 'vm.comprobantesProductos[0]' esta definido
         */
        var comprobarServicio = false;
        // Si no se tiene un detalle producto
        if (vm.comprobantesProductos[0] == undefined) {
            // Se debe comprobar si tiene un detalle servicio
            comprobarServicio = true;
        } else {
            // Aqui se tiene un detalle producto
            // Si no selecciono un producto, se debe verificar si tiene un detalle servicio
            if (vm.comprobantesProductos[0].producto == undefined) {
                comprobarServicio = true;
            }
        }

        //Comprobar si se tiene un detalle servicio
        if (comprobarServicio) {
            // Si servicios no tiene un detalle
            if (vm.comprobantesServicios[0] == undefined) {
                notify({
                    message: $filter("translate")("DETALLE_VACIO"),
                    classes: "alert-danger",
                    position: "right"
                });
                return;
            } else {
                // Aqui servicio tiene un detalle
                // Si no selecciono un servicio
                if (vm.comprobantesServicios[0].servicio == undefined) {
                    notify({
                        message: $filter("translate")("DETALLE_VACIO"),
                        classes: "alert-danger",
                        position: "right"
                    });
                    return;
                }
            }
        }
        if(vm.comprobante.remisiones && vm.comprobante.remisiones.length > 0){
            if (vm.comprobantesProductos && vm.comprobantesProductos.length > 0) {
                var i = 0;
                _.forEach(vm.comprobantesProductos, function (elem) {
                    calcularTotalDetalle(elem,i);
                    i++;
                })
            }
        }

        if ($scope.ComprobanteForm.$valid) {
          

            $rootScope.isProcessing = true;
            cargarImpuestos();
            // if (
            //     vm.comprobante.grupoCompra &&
            //     vm.comprobante.grupoCompra.codigo === "comprobante_importacion"
            // ) {
            //     delete vm.comprobante.moneda;
            // }
            if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.cambio != 1) {
                var msg = "La moneda del comprobante es Gs. El tipo de cambio no puede ser diferente de 1.";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                vm.submited = false;
                vm.submitedAgil = false;
                $rootScope.isProcessing = false;
                vm.cotizacionRequerida = true;
                return;
            }
            //Evaluacion de Periodo Administrativo

            var params = {
                search: filterFactory.single({
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                }).value()
            };

            vm.flagPeriod = false;
            vm.comprobante.fecha.setHours(0, 0, 0, 0);
            PeriodoAdministrativoFactory.all(params).$promise.then(function (response) {
                var periodos = response;
                _.forEach(periodos, function (value) {
                    var desde = new Date(value.fechaDesde);
                    var hasta = new Date(value.fechaHasta);

                    if (vm.comprobante.fecha >= desde && vm.comprobante.fecha <= hasta) {
                        vm.flagPeriod = true;
                    }
                })

                if (!vm.comprobante.cliente.activo) {
                    var msg = "El cliente no se encuentra en estado Activo.";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.submited = false;
                    vm.submitedAgil = false;
                    $rootScope.isProcessing = false;
                    return;
                }

                if (vm.comprobante.cliente.bloquearVentas) {
                    var msg = "El cliente se encuentra bloqueado para los procesos de ventas.";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.submited = false;
                    vm.submitedAgil = false;
                    $rootScope.isProcessing = false;
                    return;
                }

                if(vm.comprobante.sucursalCliente.centroCostos && (vm.comprobante.centroCostos==null || vm.comprobante.centroCostos==undefined)){
                    var msg = "La sucursal del cliente tiene centro de costo pero el comprobante no, favor verificar";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.submited = false;
                    vm.submitedAgil = false;
                    $rootScope.isProcessing = false;
                    return;
                }

                if (!vm.flagPeriod) {
                    $rootScope.isProcessing = false;
                    notify({
                        message: $filter("translate")("NO_ADMINISTRATIVE_PERIOD"),
                        classes: "alert-danger",
                        position: "right"
                    });
                } else {

                    copiarComprobantes();
                    vm.comprobante.comprobanteDetalle = _.filter(
                        vm.comprobante.comprobanteDetalle,
                        function (elem) {
                            return elem.producto || elem.servicio;
                        }
                    );
                    // se quita el atributo grupo de los detalles cuyos productos son de tipo normal
                    vm.comprobante.comprobanteDetalle = _.forEach(
                        vm.comprobante.comprobanteDetalle,
                        function (elem) {
                            if (
                                elem.producto != undefined &&
                                elem.producto.criterioControl.codigo !== "series" &&
                                elem.producto.criterioControl.codigo !== "lotes"
                            ) {
                                delete elem.grupo;
                            }
                        }
                    );

                    var errorSerieRepetida = false;
                    if (vm.comprobanteGeneraMovimiento) {

                        for (var i = 0; i < vm.comprobantesProductos.length; i++) {
                            var elem = vm.comprobantesProductos[i];
                            var cantRegistros = 0;
                            if (elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') {
                                _.forEach(vm.comprobantesProductos, function (elem2) {
                                    if ((elem2.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') && elem.producto && elem2.producto &&  elem.producto.id == elem2.producto.id && elem.grupoProducto && elem2.grupoProducto && elem.grupoProducto.id == elem2.grupoProducto.id) {
                                        cantRegistros++;
                                    }
                                });
                            }
                            if (cantRegistros > 1) {
                                var msg = $filter('translate')('NUMERO_GRUPO_REPETIDO') + " (Producto: " + elem.producto.nombre + ")";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                errorSerieRepetida = true;
                                break;
                            }
                        };

                    }

                    if (errorSerieRepetida) {
                        $rootScope.isProcessing = false;
                        $scope.isProcessing = false;
                        return;
                    }

                    var checkImpuestos = {};
                    var tieneImpuestoRepetido = false;
                    for (var i = 0; i < vm.comprobante.montosImponibles.length; i++) {
                        if (
                            checkImpuestos[
                            vm.comprobante.montosImponibles[i].tipoImpuesto.porcentaje
                            ] === undefined
                        ) {
                            checkImpuestos[
                                vm.comprobante.montosImponibles[i].tipoImpuesto.porcentaje
                            ] = true;
                        } else {
                            tieneImpuestoRepetido = true;
                        }
                    }
                    if (tieneImpuestoRepetido) {
                        $rootScope.isProcessing = false;
                        var msg = $scope.translations.IMPUESTO_CABECERA_REPETIDO;
                        notify({ message: msg, classes: "alert-danger", position: "right" });
                    } else {
                        /* Se quita la validacion de montos mientras, se volvera a agregar en proxima version */
                        var validationMontos = ComprobanteVentaFactory.validateMontos(
                            vm.comprobante,
                            vm.monedaLocal[0],
                            vm.diferenciaMonedaLocal[0],
                            vm.diferenciaMonedaExtranjera[0]
                        );
                        if (validationMontos[0]) {
                            vm.comprobante = ComprobanteVentaFactory.igualarTipoImpuestoCabeceraADetealle(
                                vm.comprobante,
                                vm.monedaLocal[0]
                            );
                            for (var montoImponible in vm.comprobante.montosImponibles) {
                                vm.totalImpuestoChanged(
                                    vm.comprobante.montosImponibles[montoImponible]
                                );
                            }
                            if(vm.formSuscripcionRecurrente && vm.comprobante.respetarDias && $scope.dias.diasSemana.length === 0){
                                var msg = "Debe seleccionar al menos un dia de la semana";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                 vm.submited = false;
                                 vm.submitedAgil = false;
                                $rootScope.isProcessing = false;
                                return;
                            }

                            sendSubmitComprobante();

                        } else {
                            var code = validationMontos[1];
                            if (code == "montos_mismatch") {
                                $rootScope.isProcessing = false;
                                notify({
                                    message: $scope.translations
                                        .MONTOS_TOTALES_SUM_DETALLES_WARNING_COMPRA_LOCAL,
                                    classes: "alert-danger",
                                    position: "right"
                                });
                            } else if (code == "impuestos_mismatch") {
                                $rootScope.isProcessing = false;

                                notify({
                                    message: $scope.translations
                                        .IMPUESTOS_TOTALES_SUM_DETALLES_WARNING_COMPRA_LOCAL,
                                    classes: "alert-danger",
                                    position: "right"
                                });
                            }
                        }
                    }
                }
            });
        }else{
            vm.submitedAgil = false;
        }
    }

    function sendSubmitComprobante() {
        return submitComprobante().then(
            function (response) {
                if (vm.fromOutside) {
                    $state.go("app.consultacomprobantesventa.list");
                } else if(vm.formSuscripcionRecurrente){
                    $state.go("app.suscripcionrecurrente.proceso.comprobanteventa.list", {}, { reload: true });
                } else if (vm.isFacturacionAgil) {
                    vm.comprobanteNew = response;
                    if (vm.comprobante.tipo.condicion.codigo === "tipo_comprobante_contado") {
                        vm.registrarPago = true;
                        vm.addItems = false;
                        $scope.form = {};
                        vm.isItemsEditable = true;
                        var tipoCobro;
                        var tipoCobroEntidad;
                        _.forEach(vm.tiposCobros, function (ele) {
                            if (ele.codigo === 'tipo_cobro_normal') {
                                tipoCobro = ele;
                            }
                        });
                        _.forEach(vm.tiposCobroEntidad, function (ele) {
                            if (ele.nombre === 'Contado') {
                                tipoCobroEntidad = ele;
                            }
                        });
                        vm.vuelto = 0;
                        vm.saldo = vm.totalGeneral;
                        vm.totalPagos = 0;

                        vm.cobro = {
                            caja: vm.caja,
                            cliente: vm.comprobanteNew.cliente,
                            cobroDetalle: [{
                                formaPago: null,
                                importeVueltoItem:0
                            }],
                            cobrosComprobantes: [{
                                comprobanteVenta: vm.comprobanteNew//,
                                //montoPagado: "0",
                                //importeNotasCredito: 0
                            }],
                            fecha: new Date(),
                            moneda: vm.comprobanteNew.moneda,
                            procesoCobro: vm.procesoCobro,
                            tipoCobro: tipoCobro,
                            tipoCobroEntidad: tipoCobroEntidad,
                            usuario: vm.usuario,
                            importeAnticipo: 0,
                            importeAnticipoView: "0"
                        };
                        vm.cobro.cobrosComprobantes[0].importePagar = vm.comprobanteNew.montoTotal;
                        vm.cobro.cobrosComprobantes[0].saldoConvertido = vm.comprobanteNew.saldo;
                        vm.cobro.cobrosComprobantes[0].tipoCambio = vm.comprobanteNew.cambio;

                        vm.totalGeneralDetalles = 0;
                        $scope.tituloModal = "Registrar Pago de Factura nro: " + vm.comprobanteNew.numero;
                        var modalInstance = $modal.open({
                            templateUrl: "views/directives/detallecobroagil-modal.html",
                            scope: $scope,
                            backdrop: 'static',
                            bindToController: true,
                            keyboard: false,     
                            size: 'lg'
                        });

                        $scope.cancel = function () {
                            modalInstance.dismiss("cancel");
                            $state.go($state.current, {}, { reload: true });
                            vm.submitedAgil = false;
                        };
                        $scope.ok = function () {
                            if ($scope.form.CobroForm.$valid) {
                                $rootScope.isProcessing = true;
                                for (var i = 0; i < vm.cobro.cobrosComprobantes.length; i++) {
                                    if (vm.cobro.cobrosComprobantes[i].cuota != null && vm.cobro.cobrosComprobantes[i].tipoCambio === 1) {
                                        if (vm.cobro.cobrosComprobantes[i].importePagar > vm.cobro.cobrosComprobantes[i].cuota.saldo) {
                                            notify({ message: "El importe a pagar de uno de los detalles es mayor al saldo.", classes: "alert-danger", position: "right" });
                                            $rootScope.isProcessing = false;
                                            return;
                                        }
                                    }
                                }
                                var existeComprobanteCero = false;
                                var existeCobroCero = false;

                                if (vm.habilitarAnticipo != false) {
                                    _.filter(vm.cobro.cobrosComprobantes, function (detalle) {
                                        if (detalle.importePagar == 0) {
                                            existeComprobanteCero = true;
                                        }
                                    });

                                    if (existeComprobanteCero) {
                                        $rootScope.isProcessing = false;
                                        notify({
                                            message: $filter("translate")("EXISTE_COMPROBANTE_CERO"),
                                            classes: "alert-danger",
                                            position: "right"
                                        })
                                        return;
                                    }
                                }

                                _.filter(vm.cobro.cobroDetalle, function (detalle) {
                                    if (detalle.importe == 0) {
                                        existeCobroCero = true;
                                    }
                                });

                                if (existeCobroCero) {
                                    $rootScope.isProcessing = false;
                                    notify({
                                        message: $filter("translate")("EXISTE_COBRO_CERO"),
                                        classes: "alert-danger",
                                        position: "right"
                                    })
                                    return;
                                }

                                evaluatePeriodoAdministrativo(vm.cobro.fecha).then(function (response) {
                                    if (response) {
                                        var fechaError = false;
                                        for (var i = 0; i < vm.cobro.cobroDetalle.length; i++) {
                                            if (vm.cobro.cobroDetalle[i].fechaPagoDiferido != undefined &&
                                                vm.cobro.cobroDetalle[i].fecha > vm.cobro.cobroDetalle[i].fechaPagoDiferido) {
                                                fechaError = true;
                                                break;
                                            }
                                            vm.cobro.cobroDetalle[i].importe -= vm.cobro.cobroDetalle[i].importeVueltoItem;
                                        }
                                        if (fechaError) {
                                            $rootScope.isProcessing = false;
                                            notify({
                                                message: $filter("translate")("FECHA_PAGO_ERROR"),
                                                classes: "alert-danger",
                                                position: "right"
                                            });
                                            return
                                        }
                                        var trimmedModel = ModelTrimmer.trimDetails(vm.cobro, [
                                            "cobroDetalle",
                                            "cobrosComprobantes"
                                        ]);
                                        if (trimmedModel.cobroDetalle) {
                                            trimmedModel.cobroDetalle = _.map(trimmedModel.cobroDetalle, function (
                                                detalle
                                            ) {
                                                return ModelTrimmer.trimDetails(detalle);
                                            });
                                        }
                                        if (trimmedModel.cobrosComprobantes) {
                                            trimmedModel.cobrosComprobantes = _.map(
                                                trimmedModel.cobrosComprobantes,
                                                function (detalle) {
                                                    return ModelTrimmer.trimDetails(detalle);
                                                }
                                            );
                                        }
                                        trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
                                        trimmedModel.sucursal = $rootScope.AuthParams.locacionSeleccionada.sucursal.id;
                                        trimmedModel.unidadNegocio = $rootScope.AuthParams.locacionSeleccionada.unidadNegocio.id;
                                        var resource = CobroFactory.create(trimmedModel);

                                        cleanUnused(trimmedModel);
                                        CobroFactory
                                            .save(resource, false, true)
                                            .then(
                                                function (response2) {
                                                    if (response2.tipoCobro.codigo == 'tipo_cobro_anticipo' && response2.estado.codigo == 'estado_cobro_emitido') {
                                                        ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                                                            if (parametro.valorTipo.codigo == 'si') {
                                                                if (response2.enviadoAContabilidad == false) {
                                                                    $rootScope.isProcessing = false;
                                                                    notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                                                }
                                                                if (response2.listaArchivosLogs != null) {
                                                                    if (response2.listaArchivosLogs.length != 0) {
                                                                        $rootScope.isProcessing = false;
                                                                        notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                                                    }
                                                                }
                                                            }
                                                        });
                                                    }
                                                    if (vm.comprobante.timbradoPuntoEmision && vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                                                        ComprobanteVentaFactory.generarJsonVirtual(vm.comprobanteNew.id).then(function (data) {
                                                            if (data === "" || data === null) {
                                                                var mensaje = "<span>";
                                                                mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                                                console.log(mensaje);
                                                                notify({
                                                                    messageTemplate: mensaje,
                                                                    classes: "alert-danger",
                                                                    position: "right",
                                                                    templateUrl: ""
                                                                });
                                                            } else {
                                                                console.log("*** La Respuesta *** --> ", data);
                                                                var mensaje = "<span>" + $filter("translate")("DATOS_ENVIADOS_SIFEN") + "</span>";
                                                                notify({
                                                                    messageTemplate: mensaje,
                                                                    classes: "alert-success",
                                                                    position: "right",
                                                                    templateUrl: ""
                                                                });
                                                                //obtener el cdc del comprobante utilizando cm.comprobanteN
                                                                ComprobanteVentaFactory.get(vm.comprobanteNew.id, "ComprobanteVentaList").$promise.then(function (comp) {
                                                                    var cdc = comp.cdc;
                                                                    console.log("cdc ", cdc, "  ",CONFIGURATION.serverFE+cdc)

                                                                    $window.open(CONFIGURATION.serverFE+cdc,"_blank");
                                                                    $state.go($state.current, {}, { reload: true });
                                                                });
                                                            }
                                                        }, function (error) {
                                                            console.log(error);
                                                            var mensaje = "<span>";
                                                            _.forEach(error.data, function (err) {
                                                                mensaje = mensaje + err.message + "<br/>";
                                                            });
                                                            mensaje = mensaje + "</span>";
                                                            notify({
                                                                messageTemplate: mensaje,
                                                                classes: "alert-danger",
                                                                position: "right",
                                                                templateUrl: ""
                                                            });
                                                        });
                                                    }
                                                    modalInstance.dismiss("cancel");
                                                    $state.go($state.current, {}, { reload: true });
                                                    vm.submitedAgil = false;
                                                },
                                                function (error) {
                                                    $rootScope.isProcessing = false;
                                                    if (error.data != undefined && error.data.length > 0) {
                                                        if (error.data[0].constraint != undefined && error.data[0].constraint === "numerorecibo") {
                                                            var msg = $filter('translate')('NUMERO_RECIBO_REPETIDO');
                                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                        }
                                                        if (error.data[0].codigoError === 300) {
                                                            notify({ message: "Error, no se puede tener detalles de cobro con el mismo nro de cheque, cuenta y entidad ", classes: 'alert-danger', position: 'right' });
                                                            $rootScope.isProcessing = false;

                                                        }
                                                        else {
                                                            notify({ message: error.data[0].message, classes: 'alert-danger', position: 'right' });
                                                            $rootScope.isProcessing = false;
                                                        }
                                                    }
                                                }
                                            );
                                    }
                                });
                            }
                        }
                    }else{
                        if (vm.comprobante.timbradoPuntoEmision && vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                            ComprobanteVentaFactory.generarJsonVirtual(vm.comprobanteNew.id).then(function (data) {
                                if (data === "" || data === null) {
                                    var mensaje = "<span>";
                                    mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                    console.log(mensaje);
                                    notify({
                                        messageTemplate: mensaje,
                                        classes: "alert-danger",
                                        position: "right",
                                        templateUrl: ""
                                    });
                                } else {
                                    console.log("*** La Respuesta *** --> ", data);
                                    var mensaje = "<span>" + $filter("translate")("DATOS_ENVIADOS_SIFEN") + "</span>";
                                    notify({
                                        messageTemplate: mensaje,
                                        classes: "alert-success",
                                        position: "right",
                                        templateUrl: ""
                                    });

                                     ComprobanteVentaFactory.get(vm.comprobanteNew.id, "ComprobanteVentaList").$promise.then(function (comp) {
                                        var cdc = comp.cdc;
                                        console.log("cdc ", cdc, "  ",CONFIGURATION.serverFE+cdc)

                                        $window.open(CONFIGURATION.serverFE+cdc,"_blank");
                                        $state.go($state.current, {}, { reload: true });
                                    });

                                    $state.go($state.current, {}, { reload: true });
                                }
                            }, function (error) {
                                console.log(error);
                                var mensaje = "<span>";
                                _.forEach(error.data, function (err) {
                                    mensaje = mensaje + err.message + "<br/>";
                                });
                                mensaje = mensaje + "</span>";
                                notify({
                                    messageTemplate: mensaje,
                                    classes: "alert-danger",
                                    position: "right",
                                    templateUrl: ""
                                });
                            });
                        }
                        $state.go($state.current, {}, { reload: true });
                    } 
                } else {
                    $state.go("app.ventas.proceso.comprobanteventa.list");
                }
                return response;
            },
            function (error) {
                $rootScope.isProcessing = false;
                vm.submitedAgil = false;
                errorHandler(error);
            }
        );
    }

    function copiarComprobantes() {
        vm.comprobante.comprobanteDetalle = [];
        _.forEach(vm.comprobantesProductos, function (elem) {
            if (!elem.totalItem) {
                return;
            }
            vm.comprobante.comprobanteDetalle.push(elem);
        });
        _.forEach(vm.comprobantesServicios, function (elem) {
            if(elem.servicio){
                if (!elem.totalItem) {
                    if (detalle.producto.criterioControl) {
                        if (detalle.producto.criterioControl.codigo === "lotes" || detalle.producto.criterioControl.codigo === "series") {
                            detalle.grupoProducto = { producto: detalle.producto.id };
                        }
                    }
                    return;
                }
                vm.comprobante.comprobanteDetalle.push(elem);
            }
        });
    }

    function cancel() {
        if (vm.fromOutside) {
            $state.go("app.consultacomprobantesventa.list");
        } else if (vm.isProcesoVenta) {
            $state.go("app.ventas.proceso.comprobanteventa.list");
        } else if(vm.formSuscripcionRecurrente){
            $state.go("app.suscripcionrecurrente.proceso.comprobanteventa.list");
        }
    }

    function removeItemFromArray(elemento, arreglo, index) {
        // Solo modifica la lista de PreciosMaximoMinimo si es un producto
        if (elemento.servicio == null) {
            for (var i = index; i < vm.precioMinimoMaximos.length - 1; i++) {
                vm.precioMinimoMaximos[i] = vm.precioMinimoMaximos[i + 1];
            }
            vm.precioMinimoMaximos.pop();
        }
        _.remove(arreglo, function (item) {
            return item === elemento;
        });

        updateTotalImpuestos();
       // Inicializar vm.totalGeneralProductos con 0 si vm.comprobantesProductos no está definido
        vm.totalGeneralProductos = vm.comprobantesProductos ? _.reduce(
            vm.comprobantesProductos,
            function (sum, detalle) {
                return sum + (detalle.totalItem != undefined ? detalle.totalItem : 0);
            },
            0
        ) : 0;

        vm.totalGeneralServicios = vm.comprobantesServicios ? _.reduce(
            vm.comprobantesServicios,
            function (sum, detalle) {
                return sum + (detalle.totalItem !== undefined ? detalle.totalItem : 0);
            },
            0
        ) : 0;
        
        vm.totalGeneralProductos = UtilFactory.roundForMoneda(vm.totalGeneralProductos, vm.comprobante.moneda);
        vm.totalGeneralServicios = UtilFactory.roundForMoneda(vm.totalGeneralServicios, vm.comprobante.moneda);
        //recalcular porcentaje anticipo e importes 
        if (vm.existeAnticipo && vm.comprobante.comprobanteAnticipo) {
            //sumar cantidad*precio tanto de productos como se servicios si existe
            var totalBruto = 0;
            var totalBrutoProducto = 0;
            var totalBrutoServicio = 0;
            if (vm.comprobantesProductos.length > 0) {
                _.forEach(vm.comprobantesProductos, function (det) {
                    totalBrutoProducto += det.cantidad * det.precioVenta;
                });
            }
            if (vm.comprobantesServicios.length > 0) {
                _.forEach(vm.comprobantesServicios, function (det) {
                    totalBrutoServicio += det.cantidad * det.precioVenta;
                });
            }
            totalBruto = totalBrutoProducto + totalBrutoServicio;
            vm.comprobante.porcentajeAnticipo = (vm.comprobante.importeAnticipo / totalBruto) * 100;
            vm.comprobante.porcentajeAnticipo = parseFloat(vm.comprobante.porcentajeAnticipo.toFixed(8));
            if (vm.comprobantesProductos.length > 0) {
                var sumCompProducto = 0;
                _.forEach(vm.comprobantesProductos, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta) / 100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo * elem.cantidad;
                    elem.totalItem = (elem.precioVenta - elem.importeAnticipo) * elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompProducto += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);

                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }

                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralProductos = UtilFactory.roundForMoneda(sumCompProducto, vm.comprobante.moneda);
            }
            if (vm.comprobantesServicios.length > 0) {
                var sumCompServicio = 0;

                _.forEach(vm.comprobantesServicios, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta) / 100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo * elem.cantidad;
                    elem.totalItem = (elem.precioVenta - elem.importeAnticipo) * elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompServicio += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);

                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }

                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralServicios = UtilFactory.roundForMoneda(sumCompServicio, vm.comprobante.moneda);
            }
        }
        vm.totalGeneral = vm.totalGeneralProductos + vm.totalGeneralServicios;
    }

    function submitComprobante() {
        delete vm.comprobante.numeroTimbrado;

        if(vm.formSuscripcionRecurrente)
        {
            //vm.generarFechasPlantilla();
            vm.comprobante.suscripcionRecurrente = vm.suscripcionRecurrente;
            vm.comprobante.diasDeLaSemana = JSON.stringify($scope.dias.diasSemana);
            vm.comprobante.fechaInicial = moment(vm.comprobante.fechaInicial).format('DD/MM/YYYY');
            vm.comprobante.fechaFinal = moment(vm.comprobante.fechaFinal).format('DD/MM/YYYY');
           // vm.comprobante.primeraFacturacion = moment(vm.comprobante.primeraFacturacion).format('DD/MM/YYYY');

        }

        vm.comprobante.clase = vm.comprobante.tipo.clase;

        var trimmedModel = ModelTrimmer.trimDetails(vm.comprobante, [
            "comprobanteDetalle",
            "montosImponibles",
            "movimientoStock"
        ]);
        var trimmedDetails = _.map(vm.comprobante.comprobanteDetalle, function (
            detalle
        ) {
            var result = ModelTrimmer.trimDetails(detalle, [
                "montosImponibles",
                "grupoProducto"
            ]);
            var montosImponiblesTrimmed = _.map(result.montosImponibles, function (
                monto
            ) {
                return ModelTrimmer.trimDetails(monto);
            });
            result.montosImponibles = montosImponiblesTrimmed;
            return result;
        });

        trimmedModel.comprobanteDetalle = trimmedDetails;
        var trimmedMontos = _.map(vm.comprobante.montosImponibles, function (monto) {
            return ModelTrimmer.trimDetails(monto);
        });
        trimmedModel.montosImponibles = trimmedMontos;

        var idsDetails = getIDs();
        trimmedModel.movimientoStock = _.map(idsDetails, function (id) {
            var idVal = parseInt(id);

            if (!idVal) {
                return;
            }
            return idVal;
        });

        if (!vm.isEdit) {
            trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        }
        trimmedModel.sucursal = $rootScope.AuthParams.locacionSeleccionada.sucursal.id;
        var resource = ComprobanteVentaFactory.create(trimmedModel);

        var montoInicial = vm.montoInicial ? vm.montoInicial : 0;
        if(vm.hasPermisoVendedorView){
          delete resource.fecha;
        }
         
        return ComprobanteVentaFactory.saveWithPlanCobros(
            resource,
            vm.cantidadCuotas,
            vm.plazoCuotas,
            montoInicial,
            vm.isFacturacionAgil,
            vm.hasPermisoVendedorView
        );

    }

    function errorHandler(e) {
        var msg = "";
        _.forEach(e.data, function (error) {
            if (error.constraint == "id_producto, numero") {
                msg +=
                    "\n\n" + $scope.translations.NUMERO_GRUPO_REPETIDO_PRODUCTO + ".\n";
            } else if (
                error.constraint ==
                "numero, id_proveedor, id_numero_timbrado, id_tipo_comprobante"
            ) {
                msg +=
                    "\n\n" +
                    $scope.translations.NROCOMP_PROVEEDOR_TIMBRADO_TIPO_REPETIDO +
                    ".\n";
            } else if (
                error.constraint ==
                "numero, id_timbrado_punto_emision, id_clase"
            ) {
                msg +=
                    "\n\n" +
                    $filter("translate")("NUMERO_TIMBRADO_TIPO_COMP_REPETIDO") +
                    ".\n";
            } else if (error.message == "Not unique field: id_tipo_clase, numero, id_timbrado_punto_emision") {
                msg += '\n\n' + "Ya existe un comprobante con el mismo numero, timbrado y clase\n"
            }else if (error.message == "Existe un proceso de confirmación del comprobante. No se puede editar.") {
                msg += "\n\n" + error.message;
                $state.go("app.ventas.proceso.comprobanteventa.list");
            } else if(error.constraint == "id_comprobante_anticipo"){
                msg +=
                    "\n\n" + 'Ya existe otro comprobante que tiene relacionado el anticipo seleccionado' + ".\n";
            }else {
                msg += "\n\n" + error.message + ".";
            }
        });

        notify({ message: msg, classes: "alert-danger", position: "right" });
    }


    function cancel() {
        if (vm.fromOutside) {
            $state.go("app.consultacomprobantesventa.list");
        } else if (vm.isProcesoVenta) {
            $state.go("app.ventas.proceso.comprobanteventa.list");
        } else if(vm.formSuscripcionRecurrente){
            $state.go("app.suscripcionrecurrente.proceso.comprobanteventa.list");
        }else if(vm.isFacturacionAgil){
            $state.go("app.main");
        }
    }

    function totalImpuestoChanged(montoImponible) {
        checkIfImpuestoSet(montoImponible);
        updateTotalImpuestos();
    }

    function tipoImpuestoChanged(montoImponible) {
        checkIfImpuestoSet(montoImponible);
        updateTotalImpuestos();
    }

    function checkIfImpuestoSet(montoImponible) {
        if (!montoImponible.tipoImpuesto) {
            delete montoImponible.valor;
            delete montoImponible.total;
            delete montoImponible.baseImponible;
            return false;
        }

        var porc = montoImponible.tipoImpuesto.porcentaje;
        if (porc != 0) {
            montoImponible.baseImponible =
                100 *
                montoImponible.total /
                (100 + montoImponible.tipoImpuesto.porcentaje);
        } else {
            montoImponible.baseImponible = montoImponible.total;
        }

        if (montoImponible.baseImponible != undefined) {
            montoImponible.baseImponible = parseFloat(montoImponible.baseImponible.toFixed(4));
            montoImponible.valor = parseFloat((montoImponible.total - montoImponible.baseImponible).toFixed(4));
        }
        return true;
    }

    function updateTotalImpuestos() {
        vm.totalImpuestosGeneral = _.reduce(
            vm.comprobante.montosImponibles,
            function (sum, monto) {
                return sum + monto.total;
            },
            0
        );

        vm.totalImpuestosGeneralMontoImponible = _.reduce(
            vm.comprobante.montosImponibles,
            function (sum, monto) {
                return sum + monto.valor;
            },
            0
        );
        vm.comprobante.totalImpuestos = vm.totalImpuestosGeneralMontoImponible;

        vm.totalImpuestosGeneral = UtilFactory.roundForMoneda(vm.totalImpuestosGeneral, vm.comprobante.moneda);
        vm.comprobante.montoTotal = vm.totalImpuestosGeneral;
    }

    function requireDeposito() {
        if (
            vm.fromOutside &&
            vm.comprobante &&
            vm.comprobante.tipo &&
            vm.comprobante.tipo.clase.codigo == "nota_credito"
        ) {
            return true;
        } else if (vm.fromOutside) {
            return false;
        }
        if (vm.comprobante && vm.comprobante.grupoCompra) {
            if (
                vm.comprobante.grupoCompra.permiteServicio === true &&
                vm.comprobante.grupoCompra.permiteProducto === false
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    function timbradoSelect() {
        // vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
        //   vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY")
        var timbradoAux = null;
        if(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual"){

          timbradoAux = _.find(vm.tipoTransacciones, function (tipo) {
            if ('venta_mercaderia' === tipo.codigo) {
                return tipo;
            }
          });
          if((vm.comprobante.tipoTransaccion == undefined || vm.comprobante.tipoTransaccion == null) && timbradoAux != null){
            vm.comprobante.tipoTransaccion = timbradoAux;
          }
        }else{
          vm.comprobante.tipoTransaccion = null;
        }
        if (vm.datoVirtual && !vm.fromOutside && vm.comprobante.cliente &&
            vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") verificarTipoOperacion(vm.comprobante.cliente);
        vm.comprobante.motivoEmision = null;
        if (vm.comprobante.numero != undefined && vm.comprobante.numero != null && vm.comprobante.numero != "") {
            validarNroFactura();
        }
    }

    function validarNroFactura() {
        if (vm.comprobante.numero != undefined && vm.comprobante.numero != null && vm.comprobante.numero != "") {
            vm.comprobante = ComprobanteVentaFactory.validateNroFactura($scope.ComprobanteForm, vm.comprobante, vm.monedaLocal[0], vm.formatoComprobante);
        }
    }

    function getStockPorTipoComprobante(detalle) {
        var filterGrupo = [];
        var filterNormal = [];
        vm.almacenesConStock = [];

        _.forEach(detalle.producto.gruposProducto, function (grupo) {
            var filter = [{
                path: "grupoProducto.id",
                equals: grupo.id
            },
            {
                path: 'almacen.id',
                equals: vm.comprobante.deposito.id
            }];
            filterGrupo.push(filterFactory.and(filter).value());
        });

        var filterFinal = {
            search: filterFactory.or(filterGrupo).value()
        }
        GrupoProductoAlmacenFactory.all(filterFinal, 'BaseList').$promise.then(function (response) {
            var grupoConStock = [];
            if (response.length > 0) {
                _.forEach(response, function (elem) {
                    var existente = _.find(vm.almacenesConStock, function (stock) {
                        if (stock.grupoProducto != undefined && elem.grupoProducto.id == stock.grupoProducto.id) {
                            return stock;
                        }
                    });
                    if (existente != undefined && existente != null) {
                        if (elem.dia > existente.dia) {
                            existente.dia = elem.dia;
                            existente.saldoReal = elem.saldoReal;
                        }
                    } else {
                        vm.almacenesConStock.push(elem);
                    }
                });

                _.forEach(detalle.producto.gruposProducto, function (grupo) {
                    var existe = false;
                    _.forEach(vm.almacenesConStock, function (stock) {
                        if (grupo.id == stock.grupoProducto.id) {
                            if (vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo === "nota_credito") {
                                existe = true;
                            } else if (vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo != "nota_credito" && stock.saldoReal > 0) {
                                existe = true;
                            }
                        }
                    });
                    if (existe) {
                        grupoConStock.push(grupo);
                    }
                });

            }
            detalle.producto.gruposProducto = grupoConStock;
            if (grupoConStock.length == 0) {
                if (vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo === "nota_credito") {
                    var msg = $filter('translate')('NO_EXISTE_GRUPO_SIN_STOCK') + " (Producto: " + detalle.producto.nombre + ")";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                } else {
                    var msg = $filter('translate')('NO_EXISTE_GRUPO_CON_STOCK') + " (Producto: " + detalle.producto.nombre + ")";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }
            }
        });
    }

    function verificarTipoOperacion(cliente) {
        vm.comprobante.tipoOperaciones = null;
        if (cliente.tipoOperacion != null) {
            vm.comprobante.tipoOperacion = _.find(vm.tipoOperaciones, function (t) {
                return t.codigo === cliente.tipoOperacion.codigo;
            });
        } else {
            var msg = $filter('translate')('CLIENTE_NO_TIPO_OPERACION');
            notify({ message: msg, classes: 'alert-warning', position: 'right' });
        }
    }

    function changeAlmacen() {
        if (vm.comprobanteGeneraMovimiento) {
            _.forEach(vm.comprobantesProductos, function (elem) {
                if ((elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') && !estaEnPV(elem)) {
                    ProductosFactory.get(elem.producto.id, 'BaseList').$promise.then(function (producto) {
                        elem.producto = producto;
                        elem.grupoProducto = null;
                        getStockPorTipoComprobante(elem);
                    });
                }
            });
        }
    }

    function changeTipoAplicacion() {
        vm.tiposAplicaciones = {};
        TiposFactory.condicionTipoAplicacion().then(function (data) {
            vm.tiposAplicaciones = data.data;
        });
    }

    function getProductoStockAlmacen(detalle) {
        vm.almacenesConStock = [];
        var filterGrupo = [];
        var filterNormal = [];
        if (vm.comprobantesProductos.length >= 1 && vm.comprobantesProductos[0].producto != undefined && vm.comprobante.deposito != undefined && vm.comprobante.deposito.id != undefined && vm.comprobanteGeneraMovimiento) {

            _.forEach(vm.comprobantesProductos, function (elem) {
                if (elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') {
                    _.forEach(elem.producto.gruposProducto, function (grupo) {
                        var filter = [{
                            path: "grupoProducto.id",
                            equals: grupo.id
                        },
                        {
                            path: 'almacen.id',
                            equals: vm.comprobante.deposito.id
                        }];
                        filterGrupo.push(filterFactory.and(filter).value());
                    });
                } else {
                    var filter = [{
                        path: "producto.id",
                        equals: elem.producto.id
                    },
                    {
                        path: 'almacen.id',
                        equals: vm.comprobante.deposito.id
                    }];
                    filterNormal.push(filterFactory.and(filter).value());
                }
            });

            if (filterGrupo.length > 0) {
                var filterFinal = {
                    search: filterFactory.or(filterGrupo).value()
                }
                GrupoProductoAlmacenFactory.all(filterFinal, 'BaseList').$promise.then(function (response) {
                    if (response.length > 0) {
                        _.forEach(response, function (elem) {
                            var existente = _.find(vm.almacenesConStock, function (stock) {
                                if (stock.grupoProducto != undefined && elem.grupoProducto.id == stock.grupoProducto.id) {
                                    return stock;
                                }
                            });
                            if (existente != undefined && existente != null) {
                                if (elem.dia > existente.dia) {
                                    existente.dia = elem.dia;
                                    existente.saldoReal = elem.saldoReal;
                                }
                            } else {
                                vm.almacenesConStock.push(elem);
                            }
                        });
                        if (detalle != undefined && (detalle.producto.criterioControl.codigo == 'series' || detalle.producto.criterioControl.codigo == 'lotes')) {
                            var grupoConStock = [];
                            _.forEach(detalle.producto.gruposProducto, function (grupo) {
                                var existe = false;
                                _.forEach(vm.almacenesConStock, function (stock) {
                                    if (grupo.id == stock.grupoProducto.id && stock.saldoReal > 0) {
                                        existe = true;
                                    }
                                });
                                if (existe) {
                                    grupoConStock.push(grupo);
                                }
                            });
                            detalle.producto.gruposProducto = grupoConStock;
                            if (grupoConStock.length == 0) {
                                var msg = $filter('translate')('NO_EXISTE_GRUPO_CON_STOCK') + " (Producto: " + detalle.producto.nombre + ")";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    }
                });
            }
            if (filterNormal.length > 0) {
                var filterFinal = {
                    search: filterFactory.or(filterNormal).value()
                }
                ProductoAlmacenFactory.all(filterFinal, 'BaseList').$promise.then(function (response) {
                    if (response.length > 0) {
                        _.forEach(response, function (elem) {
                            vm.almacenesConStock.push(elem);
                        });
                    }
                });
            }

        }
    }

    function delayRequestProducto(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {

            var filterNombre = vm.opcionesProductosDt.staticFilter.search.filters.slice()
            var filterCodigoBarras = vm.opcionesProductosDt.staticFilter.search.filters.slice()
            var filterCodigoInterno = vm.opcionesProductosDt.staticFilter.search.filters.slice()

            filterNombre.push({
                path: "nombre",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                })

            filterCodigoBarras.push({
                path: "codigoBarrasPrincipal.codigoBarras",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                })

            filterCodigoInterno.push({
                path: "codigo",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }

            recuperarProducto(userInputFilter)
        } else {
            vm.productos = undefined
        }
    }

    function recuperarProducto(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)

        vm.productos = ProductosFactory.allForSelect(finalFilter)
    }

    function plusClicked(detalle) {
        detalle.mostrarMas = !detalle.mostrarMas;
    }

    function getListasPreciosVentasByMoneda() {
        if (vm.comprobante.moneda) {
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'estado.codigo',
                equals: 'lista_activada'
            }]).value();
            ListaPrecioVentaFactory.all(staticFilter, "ComprobanteVentaForm").$promise.then(function (response) {
                if (response.length > 0) {
                    //ver que moneda es el comprobante
                    if (vm.comprobante.moneda.codigo == 'guaranies') {
                        //usa precio en Gs, Lista de precios en Gs y moneda extranjera
                        vm.listasPreciosVentas = response;
                    } else {
                        //si es moneda extranjera usa precio en Gs, Lista de precios en Gs y de la misma moneda extranjera de la operacion
                        var listaMoneda = [];
                        _.forEach(response, function (elem) {
                            if (elem.moneda.codigo == 'guaranies' || elem.moneda.codigo == vm.comprobante.moneda.codigo) {
                                listaMoneda.push(elem);

                            }
                        });
                        vm.listasPreciosVentas = listaMoneda;
                    }
                } else {
                    vm.listasPreciosVentas = undefined;
                }
            });
        }
    }

    function changeListaPrecioVenta() {
        vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;

        if (vm.permitirProductosNotListaPrecio == 'No') {
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'listaPrecioVenta.id',
                equals: vm.comprobante.listaPrecioVenta.id,
            }]).value();
            staticFilter.view = "ComprobanteVentaForm";
            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                var indexChangeListaPrecio = 0;
                _.forEach(vm.comprobantesProductos, function (detalle) {
                    if(detalle.producto){
                        vm.productoExisteInLP = _.find(response, function (detalleLP) { return detalle.producto.id === detalleLP.producto.id });
                        if (vm.productoExisteInLP == undefined) {
                            removeItemFromArray(detalle, vm.comprobantesProductos, indexChangeListaPrecio);
                        }
                        indexChangeListaPrecio = indexChangeListaPrecio + 1;
                    }
                })
            });
        }

        if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
            //si el comprobante es en gs y la lista en moneda extranjera
            vm.mostrarCambioPactado = true;
            vm.monedaLocal.$promise.then(function (data) {
                if (vm.comprobante.monedaLista && vm.comprobante.monedaLista.descripcion != data[0].valorTipo.descripcion) {
                    vm.cotizacionRequerida = true;

                    var params = {};
                    params.search = filterFactory.and([{
                        path: 'monedaDesde.id',
                        equals: vm.comprobante.monedaLista.id
                    },
                    {
                        path: 'monedaHasta.id',
                        equals: vm.monedaLocal[0].valorTipo.id
                    },
                    {
                        path: 'fecha',
                        like: moment(vm.comprobante.fecha).format('DD/MM/YYYY')
                        //equals:vm.comprobante.fecha
                    }
                    ]).value();
                    params.view = 'CotizacionMonedaList';
                    CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                        if (response && response[0]) {
                            vm.comprobante.cambioPactado = response[0].valorCompra;
                        } else {
                            vm.comprobante.cambioPactado = null;
                        }
                    });
                } else {
                    vm.cotizacionRequerida = false;
                    vm.comprobante.cambioPactado = 1;
                }
            });
        } else {
            vm.mostrarCambioPactado = false;
        }

        if (vm.comprobante.listaPrecioVenta && vm.utilizarPrecioBase) {
            if(vm.comprobante.listaPrecioVenta){
                vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                if(vm.comprobante.listaPrecioVenta.precioBase){
                  vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                }
            }
            vm.actualizarPreciosDeVentas();
        } else {
            vm.actualizarPreciosDeVentas();
        }
        copiarComprobantes();
        recuperarPreciosMinimosMaximos();
    }

    function actualizarPreciosDeVentas() {
        if (vm.comprobantesProductos.length > 0 && vm.comprobantesProductos[0].producto) {
            var indexAux = 0;
            _.forEach(vm.comprobantesProductos, function (elem) {
                vm.detalleEliminado = false;
                if (vm.permitirProductosNotListaPrecio == 'No' && vm.comprobante.listaPrecioVenta) {

                    var staticFilter = {};
                    staticFilter.search = filterFactory.and([{
                        path: 'listaPrecioVenta.id',
                        equals: vm.comprobante.listaPrecioVenta.id,
                    },
                    {
                        path: 'producto.id',
                        equals: elem.producto.id,
                    }]).value();
                    staticFilter.view = "PedidoVentaForm";
                    ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                        if (vm.permitirProductosNotListaPrecio == 'No' && vm.comprobante.listaPrecioVenta) {
                            if (response == undefined || response.length == 0) {
                                removeItemFromArray(elem, vm.comprobantesProductos, indexAux);
                                vm.detalleEliminado = true;
                            }
                        }
                        if (elem.precioVenta != undefined && !vm.detalleEliminado) {
                            changeUnidad(elem, null, response);
                        }
                    });
                } else {
                    if (elem.precioVenta != undefined) {
                        changeUnidad(elem,indexAux);
                    }
                };
                indexAux = indexAux + 1;
            });
        }
    }

    function changeTipoCambio() {
        vm.actualizarPreciosDeVentas();
    };

    function changePrecioCambioPactado() {
        actualizarPreciosDeVentas();
    }

    function volverSolicitud() {
        //traer la solicitud que corresponde al comprobante
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'numeroProcesoOrigen',
            equals: vm.comprobante.id + "",
        }]).value();
        SolicitudAutorizacionFactory.all(staticFilter).$promise.then(function (response) {
            var idSolicitud = response[0].id;
            $state.go("app.solicitudautorizacion.edit", { id: idSolicitud });
        });
    }

    function delayRequestCliente(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
            var filterNombre = [];
            var filterNombreFantasia = [];
            var filterCodigo = [];
            var filterNumero = [];
            var filterDireccion = [];
            var filterCiudad = [];

            filterNombre.push({
                path: "nombre",
                like: searchValue
            })
            filterNombre.push({
                path: "activo",
                equals: true
            })
            filterNombreFantasia.push({
                path: "nombreFantasia",
                like: searchValue
            })
            filterNombreFantasia.push({
                path: "activo",
                equals: true
            })
            filterCodigo.push({
                path: "codigo",
                like: searchValue
            })
            filterCodigo.push({
                path: "activo",
                equals: true
            })
            filterNumero.push({
                path: "numero",
                like: searchValue
            })
            filterNumero.push({
                path: "activo",
                equals: true
            })
            filterDireccion.push({
                path: "direccion",
                like: searchValue
            })
            filterDireccion.push({
                path: "activo",
                equals: true
            })
            filterCiudad.push({
                path: "ciudad.descripcion",
                like: searchValue
            })
            filterCiudad.push({
                path: "activo",
                equals: true
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterNombreFantasia).value(),
                    filterFactory.and(filterCodigo).value(),
                    filterFactory.and(filterNumero).value(),
                    filterFactory.and(filterDireccion).value(),
                    filterFactory.and(filterCiudad).value()
                ]).value()
            }

            recuperarCliente(userInputFilter)
        } else {
            vm.clientes = undefined
        }
    }

    function buscarComprobantes() {

        // // TODO: ver qué otras precondiciones controlar
        // if (vm.activoFijo.fechaDesde && vm.activoFijo.fechaDesde.getTime() >
        //   vm.activoFijo.fechaHasta && vm.activoFijo.fechaHasta.getTime()) {
        //   notify({ message: $filter('translate')('DATE_FROM_DATE_TO_ERROR'), classes: 'alert-danger', position: 'right' });
        //   return;
        // }
        //
        // var filtersArray = [{
        //   path: 'estado.codigo',
        //   equals: 'comp_aprobado'
        // }];

        // Filtro para excluir comprobantes ya seleccionados
        if (vm.opcionesRelacionarFacturas.staticFilter != undefined) {
            var filtrosRemover = [];
            _.forEach(vm.opcionesRelacionarFacturas.staticFilter.search.filters, function (filtro) {
                if (filtro.path === "id") {
                    filtrosRemover.push(filtro);
                }
            });
            _.forEach(filtrosRemover, function (filtro) {
                _.remove(vm.opcionesRelacionarFacturas.staticFilter.search.filters, function (filtro2) {
                    return filtro2 === filtro;
                });
            });

            _.forEach(vm.comprobante.relacionarFacturas, function (detalle) {
                var newFilter = { path: "id", notEquals: detalle.id };
                vm.staticFilterComprobantes.push(newFilter);
            });
        }


        // if (vm.activoFijo.proveedor) {
        //   filtersArray.push({
        //     path: 'proveedor.id',
        //     equals: vm.activoFijo.proveedor.id
        //   });
        // }
        //
        // if (vm.activoFijo.servicio) {
        //   filtersArray.push({
        //     path: 'comprobanteDetalle.servicio.id',
        //     equals: vm.activoFijo.servicio.id
        //   });
        // }
        //
        // if (vm.activoFijo.fechaDesde) {
        //   filtersArray.push({
        //     path: 'fecha',
        //     equalOrAfter: moment(vm.activoFijo.fechaDesde).format('DD/MM/YYYY')
        //   });
        // }
        //
        // if (vm.activoFijo.fechaHasta) {
        //   filtersArray.push({
        //     path: 'fecha',
        //     equalOrBefore: moment(vm.activoFijo.fechaHasta).format('DD/MM/YYYY')
        //   });
        // }
        //
        // if (vm.activoFijo.proyecto) {
        //   filtersArray.push({
        //     path: 'proyecto.id',
        //     equals: vm.activoFijo.proveedor.id
        //   });
        // }
        //
        //
        // var filters = { search: filterFactory.and(filtersArray).value() };
        //
        // vm.optionsDtComprobantes.staticFilter = filters;
        // vm.showDtComprobantes = true;
    }

    function controlLimiteDescuento(detalle, index) {
        var limiteDescuento = String(detalle.descuento);
        var cantidad;
        if (limiteDescuento.includes('.')) {
            cantidad = limiteDescuento.split('.')[1].length;
        }
        if (limiteDescuento.includes(',')) {
            cantidad = limiteDescuento.split(',')[1].length;
        }
        if (cantidad > 8) {
            detalle.porcentajeDescuento = 0;
            var msg = "El Límite de Descuento debe contener como máximo 8 decimales.";
            notify({ message: msg, classes: "alert-warning", position: "right" });
            return
        }
        if (vm.comprobante.listaPrecioVenta) {
            var productoLista = [];
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'listaPrecioVenta.id',
                equals: vm.comprobante.listaPrecioVenta.id,
            }, {
                path: 'producto.id',
                equals: detalle.producto.id,
            }]).value();
            staticFilter.view = "ComprobanteVentaForm";
            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                _.forEach(response, function (elem) {
                    if (detalle.unidadMedida.id == elem.unidadMedida.id) {
                        productoLista = elem;
                        return true;
                    }
                })
                if (productoLista.limiteDescuento && vm.comprobante.listaPrecioVenta) {
                    if (productoLista.limiteDescuento && detalle.descuento > productoLista.limiteDescuento) {
                        detalle.descuento = 0;
                        //detalle.totalItem = 0;
                        vm.calcularTotalDetalle(detalle, index);
                        var msg = $filter("translate")("PRECIO_VENTA_DESCUENTO_MAYOR") + productoLista.limiteDescuento + "%";
                        notify({ message: msg, classes: "alert-warning", position: "right" });
                    }
                }
            });
        }
    }

    function recuperarCliente(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)

        ClienteFactory.allForSelect(finalFilter).$promise.then(function (clientes) {
            var clie = [];
            for (var i = 0; i < clientes.length; i++) {
                if (clientes[i].bloquearVentas !== true) {
                    clie.push(clientes[i])
                }
            }
            vm.clientes = clie;
        })
    }

    function searchClienteFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.nombreFantasia && item.nombreFantasia.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
            );
        };
    }

    function searchSucursalClienteFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.codigoSucursal.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ) ||
                (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1)
            );
        };
    }

    function changeUnidadAux(detalle, index) {
        if (vm.comprobante.listaPrecioVenta) {
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'listaPrecioVenta.id',
                equals: vm.comprobante.listaPrecioVenta.id,
            },
            {
                path: 'producto.id',
                equals: detalle.producto.id,
            }]).value();
            staticFilter.view = "ComprobanteVentaForm";
            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                if (response.length > 0) {
                    changeUnidad(detalle, index, response);
                } else {
                    changeUnidad(detalle);
                }
            });
        } else {
            changeUnidad(detalle);
        }

        // Seteo de campo  'Cantidad U.M. Principal'
        var contenida = null;
        if (detalle.unidadMedida != null && detalle.unidadMedida.cantidad != null) {
            contenida = detalle.unidadMedida.cantidad;
        }

        if (detalle.cantidad != null && detalle.cantidad > 0 && contenida != null) {
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidad * contenida).toFixed(4);
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidadUmPrincipal); // Esto elimina ceros despues de la coma
        } else {
            detalle.cantidadUmPrincipal = detalle.cantidad;
        }
    }

    function controlPrecioMinimoMaximos(detalle, index) {
        var name = "precioVenta_" + index;
        if (vm.comprobante.listaPrecioVenta) {
            if (index != undefined) {
                var msg;
                $scope.ComprobanteForm[name].$setValidity("error", true);
                if (vm.precioMinimoMaximos[index] && vm.precioMinimoMaximos[index].precioMinimo && detalle.precioVenta < vm.precioMinimoMaximos[index].precioMinimo) {
                    msg = "El precio no puede ser menor al precio mínimo de la Lista " + vm.precioMinimoMaximos[index].precioMinimo;
                    vm.errores.splice(index, 1, msg);
                    $scope.ComprobanteForm[name].$error.pattern = true;
                    $scope.ComprobanteForm[name].$setValidity("error", false);
                    $scope.ComprobanteForm[name].$touched = true;

                } else if (vm.precioMinimoMaximos[index] && vm.precioMinimoMaximos[index].precioMaximo && detalle.precioVenta > vm.precioMinimoMaximos[index].precioMaximo) {
                    msg = "El precio no puede ser mayor al precio máximo de la Lista " + vm.precioMinimoMaximos[index].precioMaximo;
                    vm.errores.splice(index, 1, msg);
                    $scope.ComprobanteForm[name].$error.pattern = true;
                    $scope.ComprobanteForm[name].$setValidity("error", false);
                    $scope.ComprobanteForm[name].$touched = true;

                } else {
                    msg = "";
                    vm.errores.splice(index, 1, msg);
                    $scope.ComprobanteForm[name].$error = {};
                    $scope.ComprobanteForm[name].$invalid = false;
                }
            }
        }
    }

    function compararDescendente(a, b) {
      return b.fecha - a.fecha;
    }

    function controlPrecioUltimoCosto(detalle, index) {
        var filtroCosto = {};
        var name = "precioVenta_" + index;
        var precioVentaAux, unidadMedidaNew, cantidadContenida, costoActual, ultimoCostoAux,ultimoCostoAlmacen;
        var ultimoCostoSucursalList = [];
        if (detalle.producto) {

          if(vm.parametroAdministrarCostoPor == 'por_almacen'){
            ultimoCostoAlmacen = detalle.producto.ultimoCostoAlmacenList.find(function(ultimoCosto){
              return ultimoCosto.almacen.id == vm.comprobante.deposito.id;
            });
          }else if(vm.parametroAdministrarCostoPor == 'por_sucursal'){
            ultimoCostoAlmacen = detalle.producto.ultimoCostoAlmacenList.find(function(ultimoCosto){
              if(ultimoCosto.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id){
                ultimoCostoSucursalList.push(ultimoCosto);
              }
            });
            var listaUltimoCostoSucursalOrde = $filter('orderBy')(ultimoCostoSucursalList, ['-fecha', '-id']);
            if(listaUltimoCostoSucursalOrde.length > 0){
              ultimoCostoAlmacen = listaUltimoCostoSucursalOrde[0];
            }
          }else{
            var listaUltimosCostos = detalle.producto.ultimoCostoAlmacenList;
            listaUltimosCostos.sort(compararDescendente);
            var listaObjetosOrdenada = $filter('orderBy')(listaUltimosCostos, ['-fecha', '-id']);
            if(listaObjetosOrdenada.length > 0){
              ultimoCostoAlmacen = listaObjetosOrdenada[0];
            }
          }
          if(ultimoCostoAlmacen != undefined && ultimoCostoAlmacen != null){
            if(vm.parametroAdministracionCosto == 'costo_promedio_almacen'){
              ultimoCostoAux = ultimoCostoAlmacen.costoPromedio;
            }else{
              ultimoCostoAux = ultimoCostoAlmacen.ultimoCosto;
            }
          }else{
            ultimoCostoAux = 0;
          }

            precioVentaAux = detalle.precioVenta;
            // ultimoCostoAux = detalle.producto.ultimoCostoIva;

            if (ultimoCostoAux != undefined) {
                ultimoCostoAux = parseFloat(ultimoCostoAux).toFixed(2);
                unidadMedidaNew = detalle.unidadMedida;
                cantidadContenida = unidadMedidaNew.cantidad;
                costoActual = ultimoCostoAux;
                if (cantidadContenida != undefined && cantidadContenida != null) {
                    ultimoCostoAux = Math.round(costoActual * cantidadContenida);
                }
            }

            if (vm.comprobante.moneda.codigo != "guaranies") {
                vm.ultimoCostoIvaAux = ultimoCostoAux / vm.comprobante.cambio;
                vm.ultimoCostoIvaAux = Math.round(vm.ultimoCostoIvaAux * 100) / 100;
            } else {
                vm.ultimoCostoIvaAux = ultimoCostoAux;
            }

            if (detalle.descuento && detalle.descuento > 0) {
                precioVentaAux = precioVentaAux - (precioVentaAux * (detalle.descuento / 100));
            }

            if (index != undefined) {
                var msg;
                $scope.ComprobanteForm[name].$setValidity("error", true);
                if (precioVentaAux < vm.ultimoCostoIvaAux && vm.verPrecio != 'No') {
                    if (detalle.descuento && detalle.descuento > 0) {
                        msg = "El precio calculado no puede ser menor al último costo: " + vm.ultimoCostoIvaAux;
                        vm.errores.splice(index, 1, msg);
                    } else {
                        msg = "El precio no puede ser menor al último costo: " + vm.ultimoCostoIvaAux;
                        vm.errores.splice(index, 1, msg);
                    }
                    $scope.ComprobanteForm[name].$error.pattern = true;
                    $scope.ComprobanteForm[name].$setValidity("error", false);
                    $scope.ComprobanteForm[name].$touched = true;
                } else {
                    msg = "";
                    vm.errores.splice(index, 1, msg);
                    $scope.ComprobanteForm[name].$error = {};
                    $scope.ComprobanteForm[name].$invalid = false;
                    if (vm.comprobante.listaPrecioVenta) {
                        controlPrecioMinimoMaximos(detalle, index);
                    }
                }
            }
        }
    }

    function recuperarPreciosMinimosMaximos() {
        if (vm.comprobante.comprobanteDetalle.length > 0 && vm.comprobante.comprobanteDetalle[0].producto) {
            if (vm.comprobante.listaPrecioVenta) {
                var staticFilter = {};
                staticFilter.search = filterFactory.and([{
                    path: 'listaPrecioVenta.id',
                    equals: vm.comprobante.listaPrecioVenta.id,
                }]).value();
                staticFilter.view = "PrecioMinimoMaximoForm";
                vm.uiBlockuiConfig.bloquear = true;
                ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (lista) {
                    vm.uiBlockuiConfig.bloquear = false;
                    if (lista != undefined || lista.length > 0) {
                        for (var j = 0; j < vm.comprobante.comprobanteDetalle.length; j++) {
                            var productoLista;
                            productoLista = _.find(lista, function (detalleLP) {
                                return vm.comprobante.comprobanteDetalle[j].producto.id === detalleLP.producto.id &&
                                    vm.comprobante.comprobanteDetalle[j].unidadMedida.id === detalleLP.unidadMedida.id
                            });
                            var precioMinimo, precioMaximo;
                            if (productoLista) {
                                if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                                    precioMinimo = productoLista.precioMinimo * vm.comprobante.cambioPactado;
                                    precioMaximo = productoLista.precioMaximo * vm.comprobante.cambioPactado;
                                } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies") {
                                    var precioMin = productoLista.precioMinimo / vm.comprobante.cambio;
                                    var precioMax = productoLista.precioMaximo / vm.comprobante.cambio;
                                    precioMinimo = Math.round(precioMin * 100) / 100;
                                    precioMaximo = Math.round(precioMax * 100) / 100;
                                } else {
                                    precioMinimo = productoLista.precioMinimo;
                                    precioMaximo = productoLista.precioMaximo;
                                }
                                var obj = {
                                    "precioMinimo": precioMinimo,
                                    "precioMaximo": precioMaximo
                                };
                                vm.precioMinimoMaximos.splice(j, 1, obj);
                            }else{
                                var obj = {};
                                vm.precioMinimoMaximos.splice(j, 1, obj);
                            }
                        }
                    }
                });
            }
        }
    }

    function buscarUltimoCostoProducto(detalle, index) {
        var filtroCosto = {};
        var fecha = moment(new Date()).format('DD/MM/YYYY');
        filtroCosto.search = filterFactory
            .and([{
                path: 'producto.id',
                equals: detalle.producto.id
            }, {
                path: 'dia',
                equalOrBefore: fecha
            }, {
                path: 'dia',
                sortDesc: 'true'
            }]).value();

        ProductoAlmacenFactory.all(filtroCosto).$promise.then(function (productosAlmacen) {
            var unidadMedidaNew, cantidadContenida, costoActual;
            if (productosAlmacen != undefined && productosAlmacen.length > 0) {
                vm.ultimoCostoIva = parseFloat(productosAlmacen[0].ultimoCostoIVA).toFixed(2);
                unidadMedidaNew = detalle.unidadMedida;
                cantidadContenida = unidadMedidaNew.cantidad;
                costoActual = vm.ultimoCostoIva;
                if (cantidadContenida != undefined && cantidadContenida != null) {
                    vm.ultimoCostoIva = Math.round(costoActual * cantidadContenida);
                }
                controlPrecioUltimoCosto(detalle, index);
            }
        });
    }
    function activarControlPrecios(detalle, index) {
        if (detalle.producto)
            buscarUltimoCostoProducto(detalle, index);
    }

  $scope.$watch('vm.comprobante.remisiones',function(newValue){
    if (newValue){
      if(vm.recuperarItemsComprobanteAsociado){
        recuperarComprobantes()
      }
    }
  })
  function recuperarComprobantes() {
    var remision;
    vm.comprobantesProductos = [];
    for (var rem in vm.comprobante.remisiones) {
      remision = vm.comprobante.remisiones[rem];
      vm.comprobante.proyecto = remision.proyecto;
      for (var remDet in remision.remisionDetalle) {
        var contenida = null;
        var unidadMedidaPrincipal = null;
        if(remision.remisionDetalle[remDet].unidadMedida != null && remision.remisionDetalle[remDet].unidadMedida.cantidad != null){
          contenida = remision.remisionDetalle[remDet].unidadMedida.cantidad;
        }

        if(remision.remisionDetalle[remDet].cantidad != null && remision.remisionDetalle[remDet].cantidad > 0 && contenida != null){
          unidadMedidaPrincipal = remision.remisionDetalle[remDet].cantidad * contenida;
        }else{
          unidadMedidaPrincipal = remision.remisionDetalle[remDet].cantidad;
        }
        var notCharged = true;
        for (var i = 0; i < vm.comprobantesProductos.length; i++) {
          if(
            vm.comprobantesProductos[i].producto.codigo == remision.remisionDetalle[remDet].producto.codigo &&
            vm.comprobantesProductos[i].grupoProducto == remision.remisionDetalle[remDet].grupoProducto &&
            vm.comprobantesProductos[i].unidadMedida.id == remision.remisionDetalle[remDet].unidadMedida.id &&
            vm.comprobantesProductos[i].precioVenta == remision.remisionDetalle[remDet].precioVenta &&
            vm.comprobantesProductos[i].numeroOrdenProduccion == remision.remisionDetalle[remDet].numeroOrdenProduccion &&
            vm.comprobantesProductos[i].numeroOrdenCompraCliente == remision.remisionDetalle[remDet].numeroOrdenCompraCliente &&
            vm.comprobantesProductos[i].fechaValidez == remision.remisionDetalle[remDet].fechaValidez &&
            vm.comprobantesProductos[i].nombreCatalogo == remision.remisionDetalle[remDet].nombreCatalogo &&
            vm.comprobantesProductos[i].codigoCatalogo == remision.remisionDetalle[remDet].codigoCatalogo
          ){
            vm.comprobantesProductos[i].cantidad += remision.remisionDetalle[remDet].cantidad;
            vm.comprobantesProductos[i].cantidadUmPrincipal += unidadMedidaPrincipal;
            vm.calcularTotalDetalle(vm.comprobantesProductos[i]);
            notCharged = false;
          }
        }
        if(notCharged){
          vm.comprobantesProductos.push({
            montosImponibles: [{
              tipoImpuesto: vm.comprobante.cliente.exoneradoIva ? vm.tipoExento[0] :
                remision.remisionDetalle[remDet].producto.tiposImpuesto[0],
              porcentaje: vm.comprobante.cliente.exoneradoIva ? vm.tipoExento[0].porcentaje :
                remision.remisionDetalle[remDet].producto.tiposImpuesto[0].porcentaje,
              valor: 0
            }],
            producto: remision.remisionDetalle[remDet].producto,
            grupoProducto: (vm.remisionGeneraMovimiento && remision.remisionDetalle[remDet].producto.criterioControl.codigo === "series")
            || remision.remisionDetalle[remDet].producto.criterioControl.codigo === "lotes" ? remision.remisionDetalle[remDet].grupoProducto : undefined,
            unidadMedida: remision.remisionDetalle[remDet].unidadMedida,
            cantidad: remision.remisionDetalle[remDet].cantidad,
            cantidadUmPrincipal: unidadMedidaPrincipal,
            gruposProducto: remision.remisionDetalle[remDet].gruposProducto,
            numeroOrdenProduccion: remision.remisionDetalle[remDet].numeroOrdenProduccion,
            numeroOrdenCompraCliente: remision.remisionDetalle[remDet].numeroOrdenCompraCliente,
            fechaValidez: remision.remisionDetalle[remDet].fechaValidez,
            precioVenta: remision.remisionDetalle[remDet].precioVenta,
            totalItem: remision.remisionDetalle[remDet].totalItem,
            nombreCatalogo: remision.remisionDetalle[remDet].nombreCatalogo,
            codigoCatalogo: remision.remisionDetalle[remDet].codigoCatalogo
          });
          var lastIndex = vm.comprobantesProductos.length - 1;
          vm.calcularTotalDetalle(vm.comprobantesProductos[lastIndex])
        }
      }
      if(remision.catalogo){
        vm.comprobante.catalogo = remision.catalogo;
      }
    }

    copiarComprobantes();
    recuperarPreciosMinimosMaximos();
  }

    $scope.$watch("vm.comprobante.tipo",function(newVal){
    })

    function changeCondicionVenta() {
        if (vm.comprobante.formaPago) {
            vm.cantidadCuotas = vm.comprobante.formaPago.cantidadCuotas;
            vm.plazoCuotas = vm.comprobante.formaPago.plazoDias;
        }
    }

    function refreshReferencias () {
      recuperarPreciosMinimosMaximos();
    }

    function filterFormasPago() {
      if(vm.comprobante.cliente){
        if(vm.comprobante.tipo.condicion){
          if(vm.comprobante.tipo.condicion.codigo === "tipo_comprobante_contado"){
            vm.formasPagos = _.filter(vm.formasPagosAux, function (formaPago) {
              return formaPago.cantidadCuotas == null;
            });
          } else if(vm.comprobante.tipo.condicion.codigo === "tipo_comprobante_credito"){
            vm.formasPagos = _.filter(vm.formasPagosAux, function (formaPago) {
              return formaPago.cantidadCuotas != null;
            });
          }
        }
      }
    }

    // Función para calcular el precio sin impuestos
    function calcularPrecioSinImpuestos(precioConImpuestos, porcentajeImpuesto) {
      // Asegurarse de que el porcentaje de impuestos sea un número válido
      if (isNaN(porcentajeImpuesto) || porcentajeImpuesto < 0) {
        return null;
      }

      // Calcular el precio sin impuestos
      var precioSinImpuestos = Math.round(precioConImpuestos / (1 + porcentajeImpuesto / 100));

      return precioSinImpuestos;
    }
    
    function addNewProcesoCobro() {
        $scope.fechaHoraInicio = new Date();
        $scope.fechaHoraInicio.setHours(0, 0, 0, 0);
        if($rootScope.AuthParams === undefined){
            $state.go("login");
        }else if($rootScope.AuthParams.accesoSistema === undefined){
            $state.go("app.main");
        }else{
            $scope.usuario = $rootScope.AuthParams.accesoSistema.usuario;
            if($rootScope.AuthParams.accesoSistema.cajas.length > 0){
                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                    if (cajas !== undefined && cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                        $scope.caja = cajas;
                        //recuperarTimbradoFacturacionAgil($scope.caja)
                    }
                });
            }else{
                $state.go("app.main");
            }
            
            var filterProcesoCobro = {
                search: filterFactory
                    .and([
                        {
                            path: "caja.id",
                            equals: $scope.caja.id
                        },
                        {
                            path: "usuario.id",
                            equals: $rootScope.AuthParams.accesoSistema.usuario.id
                        },
                        {
                            path: "estado.codigo",
                            notEquals: "estado_procesocobro_cerrado"
                        },
                        {
                            path: "fechaHoraInicio",
                            like: moment($scope.fechaHoraInicio).format("DD/MM/YYYY")
                        }
                    ])
                    .value()
            };
            ProcesoCobroFactory.all(filterProcesoCobro).$promise.then(function (responseProcesoCobro) {
                if (responseProcesoCobro.length > 0) {
                    vm.procesoCobro = responseProcesoCobro[0];
                    vm.caja = $scope.caja;
                    vm.usuario = $rootScope.AuthParams.accesoSistema.usuario;
                } else {
                    if (vm.recuperarTotalDepositarApertura) {
                        var params = {};
                        params.search = filterFactory.and([{
                            path: 'monedaDesde.codigo',
                            equals: 'dolares'
                        },
                        {
                            path: 'monedaHasta.codigo',
                            equals: 'guaranies'
                        },
                        {
                            path: 'fecha',
                            like: moment($scope.fechaHoraInicio).format('DD/MM/YYYY')
                        }
                        ]).value();
                        params.view = 'CotizacionMonedaList';
                        CotizacionMonedaFactory.all(params).$promise.then(function (responseCotizacion) {
                            if (responseCotizacion && responseCotizacion[0]) {
                                $scope.cambio = responseCotizacion[0].valorCompra;
        
                                $scope.mostrarCampo = true;
                                var filterProceso = {
                                    search: filterFactory
                                        .and([
                                            {
                                                path: "caja.id",
                                                equals: $scope.caja.id
                                            },
                                            {
                                                path: 'id',
                                                sortDesc: true
                                            }
                                        ]).value()
                                }
                                ProcesoCobroFactory.all(filterProceso).$promise.then(function (rr) {
                                    $scope.recaudacionDepositar = (rr[0].recaudacionDepositarCierre != null && rr[0].recaudacionDepositarCierre != undefined) ? parseFloat(rr[0].recaudacionDepositarCierre).toFixed(0) : 0;
        
                                    var filterSucursal = {
                                        search: filterFactory
                                            .and([
                                                {
                                                    path: "cobro.sucursal.id",
                                                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                                                },
                                                {
                                                    path: "cobro.estado.codigo",
                                                    notEquals: "estado_cobro_anulado"
                                                },
                                                {
                                                    path: 'fechaPagoDiferido',
                                                    equalOrBefore: moment($scope.fechaHoraInicio).format('DD/MM/YYYY')
                                                },
                                                {
                                                    path: "depositado",
                                                    equals: false
                                                },
                                                {
                                                    path: "cobro.caja.id.",
                                                    equals: $scope.caja.id
                                                },
                                                {
                                                    path: "procesoCobroApertura.id",
                                                    isNull: "true"
                                                }
                                            ])
                                            .value()
                                    };
                                    CobroDetalleFactory.all(filterSucursal).$promise.then(function (response) {
                                        $scope.chequesDiferidosVencidos = 0;
                                        _.forEach(response, function (detalle) {
                                            if (detalle.moneda.codigo == 'dolares') {
                                                $scope.chequesDiferidosVencidos += detalle.importe * $scope.cambio;
                                            } else {
                                                $scope.chequesDiferidosVencidos += detalle.importe;
                                            }
                                        });
                                        $scope.totalDepositar = parseInt($scope.recaudacionDepositar) + parseInt($scope.chequesDiferidosVencidos);
                                    });
                                });
                            } else {
                                $scope.cambio = undefined;
                                $scope.mostrarCampo = true;
                                var filterProceso = {
                                    search: filterFactory
                                        .and([
                                            {
                                                path: "caja.id",
                                                equals: $scope.caja.id
                                            },
                                            {
                                                path: 'id',
                                                sortDesc: true
                                            }
                                        ]).value()
                                }
                                ProcesoCobroFactory.all(filterProceso).$promise.then(function (rr) {
                                    $scope.recaudacionDepositar = (rr[0].recaudacionDepositarCierre != null && rr[0].recaudacionDepositarCierre != undefined) ? parseFloat(rr[0].recaudacionDepositarCierre).toFixed(0) : 0;

                                    var filterSucursal = {
                                        search: filterFactory
                                            .and([
                                                {
                                                    path: "cobro.sucursal.id",
                                                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                                                },
                                                {
                                                    path: "cobro.estado.codigo",
                                                    notEquals: "estado_cobro_anulado"
                                                },
                                                {
                                                    path: 'fechaPagoDiferido',
                                                    equalOrBefore: moment($scope.fechaHoraInicio).format('DD/MM/YYYY')
                                                },
                                                {
                                                    path: "depositado",
                                                    equals: false
                                                },
                                                {
                                                    path: "cobro.caja.id.",
                                                    equals: $scope.caja.id
                                                },
                                                {
                                                    path: "procesoCobroApertura.id",
                                                    isNull: "true"
                                                }
                                            ])
                                            .value()
                                    };
                                    CobroDetalleFactory.all(filterSucursal).$promise.then(function (response) {
                                        $scope.chequesDiferidosVencidos = 0;
                                        _.forEach(response, function (detalle) {
                                            if (detalle.moneda.codigo == 'dolares') {
                                                $scope.chequesDiferidosVencidos += detalle.importe * 1;
                                            } else {
                                                $scope.chequesDiferidosVencidos += detalle.importe;
                                            }
                                        });
                                        $scope.totalDepositar = parseInt($scope.recaudacionDepositar) + parseInt($scope.chequesDiferidosVencidos);
                                    });
                                });
                            }
                        });
                    } else {
                        $scope.mostrarCampo = false;
                        $scope.cambio = 1;
                    }
                    
            
                    var params = {};
                    params.search = filterFactory
                        .or([{
                            path: "estado.codigo",
                            equals: "per_adm_abierto"
                        }])
                        .value();
            
                    var flagPeriodo = false;
            
                    PeriodoAdministrativoFactory.all(params).$promise.then(function (response) {
                        var periodos = response;
                        _.forEach(periodos, function (value) {
                            var desde = new Date(value.fechaDesde);
                            var hasta = new Date(value.fechaHasta);
                            var hoy = new Date().setHours(0, 0, 0, 0);
            
                            if (hoy >= desde && hoy <= hasta) {
                                flagPeriodo = true;
                            }
                        });
            
                        if (flagPeriodo) {
                            $scope.tituloModal = "Se requiere la apertura de un nuevo Proceso Cobro para la fecha";
                            $timeout(function () {
                                $scope.$broadcast('modalOpened');
                            }, 20);
                                var modalInstance = $modal.open({
                                    templateUrl: "views/directives/procesocobroagil-modal.html",
                                    scope: $scope,
                                    backdrop: 'static'
                                });
                                $scope.cancel = function () {
                                    modalInstance.dismiss("cancel");
                                    $state.go("app.main");
                                };
                            $scope.ok = function (caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio) {
                                //verificar si existe cambio para la fecha de inicio
                                var params = {};
                                params.search = filterFactory.and([{
                                    path: 'monedaDesde.codigo',
                                    equals: 'dolares'
                                },
                                {
                                    path: 'monedaHasta.codigo',
                                    equals: 'guaranies'
                                },
                                {
                                    path: 'fecha',
                                    like: moment(fecha).format('DD/MM/YYYY')
                                }
                                ]).value();
                                params.view = 'CotizacionMonedaList';
                                CotizacionMonedaFactory.all(params).$promise.then(function (response2) {
                                    if (response2 && response2[0]) {
                                        cambio = response2[0].valorCompra;
                                    } else {
                                        cambio = undefined;
                                    }
            
            
                                    vm.submitNewCobro = true;
                                    if (cambio != undefined) {
                                        ParametrosFactory.getByCodigo('validar_fecha_flujo_cobro').then(function (parametro) {
                                            if (parametro.valorTipo.codigo === 'no') {
                                                sendNewProcesoCobro($scope.caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio).then(function (data) {
                                                    modalInstance.close();
                                                    return ProcesoCobroFactory.get(
                                                        data.id,
                                                        "BaseList"
                                                    ).$promise;
                                                },
                                                    function (error) {
                                                        vm.submitNewCobro = false;
                                                        var msg = error.data[0].message;
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    })
                                                    .then(function (data) {
                                                        if (data != undefined) {
                                                            ProcesoCobroFactory.setIdProcesoCobro(data.id);
                                                            ProcesoCobroFactory.setProcesoCobro(data);
                                                            vm.procesoCobro = data;
                                                            vm.caja = $scope.caja;
                                                            vm.usuario = $rootScope.AuthParams.accesoSistema.usuario;
                                                        }
                                                    });
                                            } else {
                                                var fechah = new Date();
                                                var mesh;
                                                if (fechah.getMonth() < 9) {
                                                    mesh = "0" + (fechah.getMonth() + 1);
                                                } else {
                                                    mesh = fechah.getMonth() + 1;
                                                }
            
                                                var diah;
                                                if (fechah.getDate() < 9) {
                                                    diah = "0" + (fechah.getDate() + 1);
                                                } else {
                                                    diah = fechah.getDate() + 1;
                                                }
            
                                                var mesc;
                                                if (fecha.getMonth() < 9) {
                                                    mesc = "0" + (fecha.getMonth() + 1);
                                                } else {
                                                    mesc = fecha.getMonth() + 1;
                                                }
            
                                                var diac;
                                                if (fecha.getDate() < 9) {
                                                    diac = "0" + (fecha.getDate() + 1);
                                                } else {
                                                    diac = fecha.getDate() + 1;
                                                }
            
                                                var fechaHoy = fechah.getFullYear() + "/" + mesh + "/" + diah;
                                                var fechaC = fecha.getFullYear() + "/" + mesc + "/" + diac;
            
            
                                                if (fechaC < fechaHoy) {
                                                    vm.submitNewCobro = false;
                                                    var msg = "La fecha de apertura no puede ser menor a la fecha actual";
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                } else {
                                                    sendNewProcesoCobro(caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio).then(function (data) {
                                                        modalInstance.close();
                                                        return ProcesoCobroFactory.get(
                                                            data.id,
                                                            "BaseList"
                                                        ).$promise;
                                                    },
                                                        function (error) {
                                                            vm.submitNewCobro = false;
                                                            var msg = error.data[0].message;
                                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                        })
                                                        .then(function (data) {
                                                            if (data != undefined) {
                                                                ProcesoCobroFactory.setIdProcesoCobro(data.id);
                                                                ProcesoCobroFactory.setProcesoCobro(data);
                                                                vm.procesoCobro = data;
                                                                vm.caja = $scope.caja;
                                                                vm.usuario = $rootScope.AuthParams.accesoSistema.usuario;
                                                            }
                                                        });
            
                                                }
            
            
                                            }
                                        });
                                    } else {
                                        vm.submitNewCobro = false;
                                        var msg = "No se cuenta con una cotización para la fecha, no podrá iniciar un nuevo Proceso Cobro";
                                        notify({ message: msg, classes: "alert-danger", position: "right" });
                                    }
                                });
                            };
                        } else {
                            vm.submitNewCobro = false;
                            var msg = $scope.translations.NO_ADMINISTRATIVE_PERIOD;
                            notify({ message: msg, classes: "alert-danger", position: "right" });
                        }
                    });
                }
            });
        }
    }
    function sendNewProcesoCobro(caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio) {
        return TiposFactory.estadosProcesoCobro()
            .then(
                function (response) {
                    var estados = response.data;

                    if (!estados) {
                        console.log(
                            "No se pudo guardar el proceso de cobro debido a que no se pudo traer los estados"
                        );
                        return;
                    }

                    // console.log("Estados: ", estados);
                    var estadoActivo = _.find(estados, function (estado) {
                        return estado.codigo === "estado_procesocobro_abierto";
                    });
                    if (!estadoActivo) {
                        console.log("No hay estado abierto");
                        return;
                    }
                    var procesoCobro = {
                        estado: estadoActivo.id,
                        caja: caja,
                        fechaHoraInicio: fecha,
                        usuario: $rootScope.AuthParams.accesoSistema.usuario,
                        locacion: $rootScope.AuthParams.locacionSeleccionada.id
                    };

                    if (montoBaseEfectivo) {
                        procesoCobro.montoBaseEfectivo = montoBaseEfectivo;
                    }

                    if (recaudacionDepositar) {
                        procesoCobro.recaudacionDepositar = parseInt(recaudacionDepositar);
                    } else {
                        procesoCobro.recaudacionDepositar = 0;
                    }

                    if (chequesDiferidosVencidos) {
                        procesoCobro.chequesDiferidosVencidos = parseInt(chequesDiferidosVencidos);
                    } else {
                        procesoCobro.chequesDiferidosVencidos = 0;
                    }

                    if (totalDepositar) {
                        procesoCobro.totalDepositar = parseInt(totalDepositar);
                    } else {
                        procesoCobro.totalDepositar = 0;
                    }

                    if (cambio) {
                        procesoCobro.cambio = parseFloat(cambio);
                    }

                    var pc = ProcesoCobroFactory.create(procesoCobro);
                    return ProcesoCobroFactory.save(pc);
                }
            )
            .then(
                function (data) {
                    console.log("Se ha guardado el proceso de cobro.");
                    return data;
                }
            );
    }
    function recuperarTimbradoFacturacionAgil(caja){

        if(caja.puntoEmision != undefined){
            var filter = {};
            filter.search = filterFactory.and([{
                path: 'puntoEmisionVenta.id',
                equals: caja.puntoEmision.id
            },{
                path: 'timbradoEmpresa.factAgilDefault',
                equals: true
            },{
                path: "timbradoEmpresa.activo",
                equals: true
            },{
                path: "tipoComprobante.codigo",
                equals: vm.comprobante.tipo.clase.codigo
            }
            ]).value();
            TimbradoEmpresaPuntoEmisionVentaFactory.all(filter, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                if(response.length > 0){
                    vm.timbradoagil = response[0];
                    vm.comprobante.timbradoPuntoEmision = vm.timbradoagil;
                }else{
                    var msg = "No se encontró un Timbrado para la Facturación Ágil";
                    notify({ message: msg, classes: "alert-danger", position: "right" });
                }
            });
        }
    }
    function recuperarListaPrecio() {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'cliente.id',
            equals: vm.comprobante.cliente.id
        }, {
            path: 'estado.codigo',
            equals: 'lista_activada'
        }]).value();

        ListaPrecioVentaFactory.all(staticFilter, "ComprobanteVentaForm").$promise.then(function (response) {
            /* Se controla si el cliente está asociado a una lista de precios por asociación directa
            con la moneda seleccionada */
            if (response.length > 0) {
                vm.comprobante.listaPrecioVenta = response[0];
                vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                    //si el comprobante es en gs y la lista en moneda extranjera
                    vm.mostrarCambioPactado = true;
                    vm.monedaLocal.$promise.then(function (data) {

                        if (vm.comprobante.monedaLista && vm.comprobante.monedaLista.descripcion != data[0].valorTipo.descripcion) {
                            vm.cotizacionRequerida = true;

                            var params = {};
                            params.search = filterFactory.and([{
                                path: 'monedaDesde.id',
                                equals: vm.comprobante.monedaLista.id
                            },
                            {
                                path: 'monedaHasta.id',
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: 'fecha',
                                like: moment(vm.comprobante.fecha).format('DD/MM/YYYY')
                                //equals:vm.comprobante.fecha
                            }
                            ]).value();
                            params.view = 'CotizacionMonedaList';
                            CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                                if (response && response[0]) {
                                    vm.comprobante.cambioPactado = response[0].valorCompra;
                                } else {
                                    vm.comprobante.cambioPactado = null;
                                }
                            });
                        } else {
                            vm.cotizacionRequerida = false;
                            vm.comprobante.cambioPactado = 1;
                        }
                    });
                } else {
                    vm.mostrarCambioPactado = false;
                }
                vm.getListasPreciosVentasByMoneda();
                ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
                    if (parametro.valorTipo.codigo === 'no') {
                        vm.utilizarPrecioBase = false;
                    } else {
                        vm.utilizarPrecioBase = true;
                        if (vm.comprobante.listaPrecioVenta) {
                            vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                            if (vm.comprobante.listaPrecioVenta.precioBase) {
                                vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                            }
                        }
                    }
                });
            } else if (response.length === 0) {
                /* Se controla si el cliente está asociado a una lista de precios por su tipo de cliente
                con la moneda seleccionada */
                $timeout(function () {
                    var staticFilter = {};
                    staticFilter.search = filterFactory.and([{
                        path: 'tipoCliente.id',
                        equals: vm.comprobante.cliente.tipoCliente.id
                    }, {
                        path: 'estado.codigo',
                        equals: 'lista_activada'
                    }, {
                        path: 'moneda.codigo',
                        equals: vm.comprobante.moneda.codigo
                    }]).value();
                    ListaPrecioVentaFactory.all(staticFilter, "ComprobanteVentaForm").$promise.then(function (response) {
                        if (response.length > 0) {
                            vm.comprobante.listaPrecioVenta = response[0];
                            vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                            vm.getListasPreciosVentasByMoneda();
                            ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
                                if (parametro.valorTipo.codigo === 'no') {
                                    vm.utilizarPrecioBase = false;
                                } else {
                                    vm.utilizarPrecioBase = true;
                                    if (vm.comprobante.listaPrecioVenta) {
                                        vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                                        if (vm.comprobante.listaPrecioVenta.precioBase) {
                                            vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                                        }
                                    }
                                }
                            });
                        } else {
                            vm.comprobante.listaPrecioVenta = undefined;
                            vm.porcRecargo = 0;
                            vm.precioBase = 'Precio de Venta'
                            vm.getListasPreciosVentasByMoneda();
                            vm.comprobante.cambioPactado = undefined;
                        }
                    });
                });
            } else {
                /* Si el cliente está asociado a una Lista de Precios de Ventas, pero no con la moneda
                seleccionada. */
                vm.comprobante.listaPrecioVenta = undefined;
                vm.porcRecargo = 0;
                vm.precioBase = 'Precio de Venta'
                vm.getListasPreciosVentasByMoneda();
                vm.comprobante.cambioPactado = undefined;
            }
        });
    }
    function codigoBarrasAdded(tag, index) {

        if(vm.comprobantesProductos[index].codigoBarras != undefined && vm.comprobantesProductos[index].codigoBarras.length > 1){
            codigoBarrasRemoved(tag, index)
            return;
        }

        var pad = "0000000000000"
        tag.codigoBarras = pad.substring(0, pad.length - tag.codigoBarras.length) + tag.codigoBarras;
        var filterCodigoBarras = [];

        filterCodigoBarras.push({
            path: "codigoBarrasPrincipal.codigoBarras",
            like: tag.codigoBarras
        })

        var filter = {
            search: filterFactory.or([
                filterFactory.and(filterCodigoBarras).value()
            ]).value()
        }

        ProductosFactory.all(filter, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
            if (response.length > 0) {
                vm.comprobantesProductos[index].producto = response[0];
                vm.comprobantesProductos[index].unidadMedida = response[0].unidadMedidaBase;
                vm.comprobantesProductos[index].cantidad = 1;

                // changeProducto -> changeUnidad -> calcularTotalDetalle
                changeProducto(vm.comprobantesProductos[index]);

                $("#cantidad_" + index).focus();

                var listaDetalles = [];
                for (var i = 0; i < vm.comprobantesProductos.length; i++) {
                    if (vm.comprobantesProductos[i].producto !== undefined && vm.comprobantesProductos[i].producto !== null) {
                        listaDetalles.push(vm.comprobantesProductos[i]);
                    }
                }
                vm.comprobantesProductos = listaDetalles;
            } else {
                var msg = $filter('translate')('CODIGO_BARRAS_NO_ENCONTRADO') + " -> " + tag.codigoBarras;
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                codigoBarrasRemoved(tag, index)
                return;
            }
        })
    }
    function codigoBarrasRemoved(tag, index) {
        // Obtener el código de barras que se eliminó
        var codigoBarrasEliminado = tag.codigoBarras;
    
        // Iterar sobre los comprobantesProductos y eliminar el código de barras de cada uno si está presente
            var comprobanteProducto = vm.comprobantesProductos[index];
            // Verificar si el código de barras eliminado está presente en la lista de códigos de barras del comprobanteProducto
            for (var j = 0; j < comprobanteProducto.codigoBarras.length; j++) {
                if (comprobanteProducto.codigoBarras[j].codigoBarras === codigoBarrasEliminado) {
                    // Si se encontró el código de barras en la lista, eliminarlo
                    comprobanteProducto.codigoBarras.splice(j, 1);
                    // Salir del bucle interno una vez que se haya eliminado el código de barras
                    break;
                }
            }
        
    }
    $scope.agregarDetalleCobro = function () {
        vm.cobro.cobroDetalle.push({});
        var rowNumber = vm.cobro.cobroDetalle.length - 1;
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function removeItemFromArrayCobro(elemento, arreglo) {
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        updateTotalDetalles();
    }
    function updateTotalDetalles() {
        vm.totalGeneralDetalles = _.reduce(
            vm.cobro.cobroDetalle,
            function (suma, detalle) {
                var sumaTotal = suma + detalle.importe;
                if(detalle.importe > vm.totalGeneral){
                    detalle.importeVueltoItem = detalle.importe - vm.totalGeneral;
                }else if(sumaTotal > vm.totalGeneral){
                    detalle.importeVueltoItem = sumaTotal - vm.totalGeneral;
                }else{
                    detalle.importeVueltoItem = 0;
                }
                return sumaTotal;
            },
            0
        );
        // Redondeo en funcion de moneda
        var cantDecimales = 0;
        if (vm.cobro.moneda.codigo != 'guaranies') {
            cantDecimales = 2;
        }
        vm.totalGeneralDetalles = parseFloat(vm.totalGeneralDetalles.toFixed(cantDecimales));
        vm.vuelto = vm.totalGeneralDetalles - vm.totalGeneral;
        if(vm.vuelto < 0){
            vm.vuelto = 0;
        }

        vm.saldo =  vm.totalGeneral - vm.totalGeneralDetalles;

        // Asegurarse de que el saldo no sea negativo
        if (vm.saldo < 0) {
            vm.saldo = 0;
            vm.addItems = false;
            vm.registrarPago = false;
        }else if (vm.saldo == 0){
            vm.registrarPago = false;
            vm.addItems = true;
        }else{
            vm.registrarPago = true;
            vm.addItems = false;
        }
        vm.cobro.vuelto = vm.vuelto;
        vm.totalPagos = vm.totalGeneralDetalles;
    }
    function limpiarCampos(index){

        vm.cobro.cobroDetalle[index].entidadBancaria = null;
        vm.cobro.cobroDetalle[index].numeroTransaccion = null;
        vm.cobro.cobroDetalle[index].denominacionTarjeta = null;
        vm.cobro.cobroDetalle[index].numeroTarjeta = null;
        vm.cobro.cobroDetalle[index].numeroVoucher = null;
        vm.cobro.cobroDetalle[index].proveedor = null;
        vm.cobro.cobroDetalle[index].tipoProcesamiento = null;
        vm.cobro.cobroDetalle[index].tipoTarjeta = null;
        vm.cobro.cobroDetalle[index].titular = null;
        vm.cobro.cobroDetalle[index].codigoAutorizacion = null;
        vm.cobro.cobroDetalle[index].numeroCheque = null;
        vm.cobro.cobroDetalle[index].numeroCuenta = null;
        vm.cobro.cobroDetalle[index].fecha = null;
        vm.cobro.cobroDetalle[index].fechaPagoDiferido = null;
        vm.cobro.cobroDetalle[index].numeroRetencion = null;
        vm.cobro.cobroDetalle[index].numeroComprobanteRetencion = null;
        vm.cobro.cobroDetalle[index].numeroTimbrado = null;
        vm.cobro.cobroDetalle[index].numeroCuentaContable = null;

        if(vm.cobro.cobroDetalle[index].formaPago.clase.codigo === "forma_pago_tarjeta"){
            vm.cobro.cobroDetalle[index].titular =  vm.cobro.cliente.nombre;
            vm.cobro.cobroDetalle[index].tipoProcesamiento = vm.auxProcesamiento;
            vm.cobro.cobroDetalle[index].tipoTarjeta = vm.cobro.cobroDetalle[index].formaPago.tipoTarjeta;
        }
    }
    function cleanUnused(modelo) {
        if (!modelo.cobrosComprobantes) {
            return;
        }
        _.forEach(modelo.cobrosComprobantes, function (cobroComprobante) {
            delete cobroComprobante.montoCobrado;
            delete cobroComprobante.montoAplicado;
            //delete cobroComprobante.saldoMonedaCobro;
            delete cobroComprobante.monedaDesdeTranslation;
            delete cobroComprobante.monedaHastaTranslation;
        });
    }
    function evaluatePeriodoAdministrativo(fecha) {
        return $q(function (resolve, reject) {
            var params = {};
            params.search = filterFactory
                .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                }
                ])
                .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {

                if (responsePeriodo.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        });
    }

    function changeTipoComprobanteAgil() {

        vm.cantidadCuotas = null;
        vm.plazoCuotas = null;
        vm.comprobante.formaPago = null;
        filterFormasPago();
        vm.montoInicial = undefined;
        vm.habilitarTimbradoSelect = false;
        vm.comprobante.timbradoPuntoEmision = null;
        recuperarTimbradoFacturacionAgil($scope.caja);
        vm.changeCotizacion(vm.comprobante.moneda);
        vm.monedas = {};
        TiposFactory.monedas().then(function (data) {
            vm.monedas = data.data;
        });
    }

}

"use strict";

angular
    .module("qualita.venta")
    .controller(
        "AplicacionComprobanteVentaFormCtrl",
        AplicacionComprobanteVentaFormCtrl
    );

AplicacionComprobanteVentaFormCtrl.$inject = [
    "ComprobanteVentaFactory",
    "comprobantePrepService",
    "VentaFactory",
    "CotizacionMonedaFactory",
    "VentasLangFactory",
    "filterFactory",
    "ParametrosFactory",
    "$scope",
    "notify",
    "$state",
    "$rootScope",
    "$timeout",
    "$log",
    "$translate",
    "$q",
    "$modal",
    "$stateParams",
    "$filter",
    "PlanCobrosDetalleFactory",
    "observeOnScope"
];

function AplicacionComprobanteVentaFormCtrl(
    ComprobanteVentaFactory,
    comprobantePrepService,
    VentaFactory,
    CotizacionMonedaFactory,
    VentasLangFactory,
    filterFactory,
    ParametrosFactory,
    $scope,
    notify,
    $state,
    $rootScope,
    $timeout,
    $log,
    $translate,
    $q,
    $modal,
    $stateParams,
    $filter,
    PlanCobrosDetalleFactory,
    observeOnScope
) {
    var vm = this;

    vm.mode = $stateParams.mode ? $stateParams.mode : "add";

    validacionParametro().then(function(response){
        vm.paramDescontarNC = response;
    });

    var monedaRender = function(data, type, row) {

        var cantDecimales = 0;
        if (row.moneda) {
            var moneda = "Gs. ";
            if (row.moneda.codigo === 'dolares') {
                moneda = "Usd. ";
                cantDecimales = 2;
            } else if (row.moneda.codigo === 'pesos') {
                moneda = "Pes. ";
                cantDecimales = 2;
            } else if (row.moneda.codigo === 'real') {
                moneda = "Rel. ";
                cantDecimales = 2;
            } else if (row.moneda.codigo === 'euro') {
                moneda = "Eur. ";
                cantDecimales = 2;
            }

            if(data==null){
                return moneda + "0";
            }else{
                data = parseFloat(data).toFixed(cantDecimales);
                return moneda + data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
        }else{
            var moneda = "Gs. ";
            if (row.planCobros.comprobanteVenta.moneda.codigo === 'dolares') {
                moneda = "Usd. ";
                cantDecimales = 2;
            } else if (row.planCobros.comprobanteVenta.moneda.codigo === 'pesos') {
                moneda = "Pes. ";
                cantDecimales = 2;
            } else if (row.planCobros.comprobanteVenta.moneda.codigo === 'real') {
                moneda = "Rel. ";
                cantDecimales = 2;
            } else if (row.planCobros.comprobanteVenta.moneda.codigo === 'euro') {
                moneda = "Eur. ";
                cantDecimales = 2;
            }

            if(data==null){
                return moneda + "0";
            }else{
                data = parseFloat(data).toFixed(cantDecimales);
                return moneda + data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
        }
    };

    activate();

    vm.submit = submit;
    vm.cancel = cancel;
    vm.montoAplicadoChanged = montoAplicadoChanged;
    vm.previewAplicacion = previewAplicacion;
    vm.enableSave = false;
    vm.buscarComprobantes = buscarComprobantes;
    vm.clearFiltrosComprobantes = clearFiltrosComprobantes;
    vm.removeItemFromArray = removeItemFromArray;
    vm.fechaDesdeChanged = fechaDesdeChanged;
    vm.fechaHastaChanged = fechaHastaChanged;
    vm.tipoComprobanteChanged = tipoComprobanteChanged;
    vm.factoryComprobantes = ComprobanteVentaFactory;
    vm.factoryPlanCobroDetalle = PlanCobrosDetalleFactory;
    vm.actualizarSaldo = actualizarSaldo;
    vm.claseComprobanteChanged = claseComprobanteChanged;
    vm.claseTimbradoChanged = claseTimbradoChanged;

    $rootScope.isProcessing = false;

    function activate() {

        if ($state.includes("app.ventas.proceso.comprobanteventa")) {
            vm.fromOutSide = false;
        } else {
            vm.fromOutSide = true;
        }
        vm.showSpinner = false;
        vm.monedaLocal = CotizacionMonedaFactory.getMonedaLocal();
        vm.comprobanteAplicaciones = [];
        var defaultColumnOrder = [
            "numero",
            "fecha",
            "proveedor.nombre",
            "montoTotal",
            "saldo"
        ];
        vm.opcionesComprobantesDt = {
            resource: "comprobanteventa",
            title: "Comprobante Venta",
            view: "ComprobanteVentaDatatableList",
            factory: ComprobanteVentaFactory,
            defaultColumnOrder: defaultColumnOrder,
            columns: [{
                    data: "id",
                    title: $filter("translate")("CODE"),
                    class: "dt-center",
                    type: "number-range"
                },
                {
                    data: "numero",
                    title: $filter("translate")("NUMBER"),
                    class: "dt-center"
                },
                {
                    data: "fecha",
                    title: $filter("translate")("DATE"),
                    renderWith: "dateRender",
                    dateFormat: "DD/MM/YYYY",
                    class: "dt-center",
                    type: "date-range"
                },
                {
                    data: "cliente.nombre",
                    title: $filter("translate")("CLIENTE"),
                    class: "dt-center"
                },
                {
                    data: "estado.descripcion",
                    title: $filter("translate")("STATUS"),
                    class: "dt-center"
                },
                {
                    data: "montoTotal",
                    title: $filter("translate")("TOTAL_AMOUNT"),
                    class: "dt-right",
                    renderWith: monedaRender,
                    type: "number-range"
                },
                {
                    data: "saldo",
                    title: $filter("translate")("SALDO"),
                    class: "dt-right",
                    renderWith: monedaRender,
                    type: "number-range"
                }
            ],
            hasOptions: false,
            defaultOrderColumn: 2,
            defaultOrderDir: "desc"
        };

        var defaultColumnPlanCobroOrder = [
            "id",
            "numero",
            "tipo.descripcion",
            "monto",
            "saldo",
            "estado.descripcion",
            "fechaVencimiento",
            "planCobros.comprobanteVenta.numero"
        ];

        vm.opcionesPlanCobrosDetallesDt = {
            resource: "plancobrosdetalle",
            title: $filter("translate")("CUOTAS_COMPROBANTE"),
            view: "PlanCobroDetalleDatatableList",
            factory: PlanCobrosDetalleFactory,
            defaultColumnOrder: defaultColumnPlanCobroOrder,
            failedDeleteError: $filter("translate")("CUOTA_DELETE_FAILED"),
            columns: [
                { data: "id", title: $filter("translate")("CODE"), visible: false  },
                { data: "numero", title: $filter("translate")("NUMBER") },
                { data: "planCobros.comprobanteVenta.numero", title: $filter("translate")("NUMERO_COMPROBANTE") },
                {
                    data: "tipo.descripcion",
                    title: $filter("translate")("TYPE"),
                    renderWith: "emptyRender"
                },
                {
                    data: "monto",
                    title: $filter("translate")("MONTO"),
                    renderWith: monedaRender,
                    type: "number-range"
                }, //actualizar
                {
                    data: "saldo",
                    title: $filter("translate")("SALDO"),
                    renderWith: monedaRender,
                    type: "number-range"
                },
                {
                    data: "estado.descripcion",
                    title: $filter("translate")("STATUS"),
                    renderWith: "emptyRender"
                },
                {
                    data: "fechaVencimiento",
                    title: $filter("translate")("FECHA_VENCIMIENTO"),
                    renderWith: "dateRender",
                    dateFormat: "DD/MM/YYYY",
                    type: "date-range"
                } //actualizar
            ],
            hasOptions: false,
            defaultOrderColumn: 1,
            defaultOrderDir: "desc"
        };

        VentasLangFactory.getTranslations().then(function(translations) {
            vm.translations = translations;
            vm.traerComprobantes = false;
            vm.tiposComprobantes = [{
                id: 1,
                nombre: "Contado"
            }, {
                id: 2,
                nombre: "Crédito"
            }];
            vm.tipoComprobante = {
                id: 1,
                nombre: "Contado"
            };

            vm.clasesComprobantes = [{
                id: 1,
                nombre: "Factura",
                codigo: "factura"
            }, {
                id: 2,
                nombre: "Nota Débito",
                codigo: "nota_debito"
            }];

            vm.claseComprobante = {
                id: 1,
                nombre: "Factura",
                codigo: "factura"
            };

            vm.clasesTimbrados = [{
                id: 1,
                nombre: "Timbrado Virtual",
                codigo: "timbrado_virtual"
            }, {
                id: 2,
                nombre: "Timbrado Normal",
                codigo: "timbrado_normal"
            }];

            vm.claseTimbrado = {
                id: 1,
                nombre: "Timbrado Normal",
                codigo: "timbrado_normal"
            };
            var params = {};
            params.search = filterFactory
                .single({
                    path: "codigo",
                    equals: "moneda_local"
                })
                .value();
            vm.monedaLocal = ParametrosFactory.all(params);
            vm.curDate = new Date();

            vm.title = $filter("translate")("APLICACION_COMPROBANTE");
            vm.comprobante = comprobantePrepService;
            if (vm.mode == "edit") {
                getOriginalSaldo();
            }

            //AQUI
            actualizarSaldo();

            $timeout(function() {
                $scope.$broadcast("formOpened");
            }, 20);
            observeComprobanteSelected();
            observeCuotasSelected();
        });
    }

    function fechaDesdeChanged() {
        if (vm.fechaHasta != null) {
            if (!validarFechas()) {
                var msg = $filter("translate")("INVALID_DATES");
                notify({ message: msg, classes: "alert-danger", position: "right" });
                vm.fechaDesde = null;
            } else {
                buscarComprobantes();
                buscarCuotas();
            }
        }
    }

    function fechaHastaChanged() {
        if (vm.fechaDesde != null) {
            if (!validarFechas()) {
                var msg = $filter("translate")("INVALID_DATES");
                notify({ message: msg, classes: "alert-danger", position: "right" });
                vm.fechaHasta = null;
            } else {
                buscarComprobantes();
                buscarCuotas();
            }
        }
    }

    function validarFechas() {
        if (vm.fechaDesde <= vm.fechaHasta) {
            return true;
        } else { return false; }
    }

    function tipoComprobanteChanged() {
        if (vm.fechaDesde != null && vm.fechaHasta != null) {
            if (!validarFechas()) {
                var msg = $filter("translate")("INVALID_DATES");
                notify({ message: msg, classes: "alert-danger", position: "right" });
                vm.fechaHasta = null;
            } else {
                buscarComprobantes();
                buscarCuotas();
            }
        }
    }

    function claseComprobanteChanged() {
        if (vm.fechaDesde != null && vm.fechaHasta != null) {
            if (!validarFechas()) {
                var msg = $filter("translate")("INVALID_DATES");
                notify({ message: msg, classes: "alert-danger", position: "right" });
                vm.fechaHasta = null;
            } else {
                buscarComprobantes();
                buscarCuotas();
            }
        }
    }

    function claseTimbradoChanged() {
        if (vm.fechaDesde != null && vm.fechaHasta != null) {
            if (!validarFechas()) {
                var msg = $filter("translate")("INVALID_DATES");
                notify({ message: msg, classes: "alert-danger", position: "right" });
                vm.fechaHasta = null;
            } else {
                buscarComprobantes();
                buscarCuotas();
            }
        }
    }

    function buscarComprobantes() {
        if (vm.fechaDesde && vm.fechaHasta && vm.tipoComprobante) {
            getComprobantes();
        }
    }

    function buscarCuotas() {
        if (vm.fechaDesde && vm.fechaHasta && vm.tipoComprobante) {
            getCobros();
        }
    }

    vm.detalleAdded = false;

    function actualizarSaldo(){
        vm.comprobante.saldo = vm.comprobante.montoTotal;

        // Si tiene aplicaciones se calculara saldo (vm.mode = "add", vm.mode = "edit")
        if(vm.comprobante.comprobanteVentaAplicaciones && vm.comprobante.comprobanteVentaAplicaciones.length>0){

            console.log("AQUI aplicaciones: ", vm.comprobante.comprobanteVentaAplicaciones);

            var saldoCalculado = vm.comprobante.montoTotal;
            _.forEach(vm.comprobante.comprobanteVentaAplicaciones,function(aplicacion){
                saldoCalculado -= aplicacion.montoAplicado;
            })

            vm.comprobante.saldo = saldoCalculado;
            console.log("AQUI saldoCalculado: ", saldoCalculado);

        }
    }

    function observeComprobanteSelected() {

        var obs1 = observeOnScope($scope, "vm.comprobantesFiltrados")
            .filter(function(data) {
                return data.newValue;
            })
            .map(function(data) {
                return data.newValue;
            });

        obs1.subscribe(function(change) {
            vm.showSpinner = true;
            vm.detalleAdded = true;
            _.forEach(change, function(val) {
                val.$promise.then(function(comprobante) {
                    addComprobante(comprobante);
                });
            });
            $q.all(change).then(function() {
                vm.traerComprobantes = true;
                if (vm.mode === "add") {
                    vm.comprobante.comprobanteVentaAplicaciones = vm.comprobanteAplicaciones;
                }
                vm.showSpinner = false;
            });
        });
    }

    function observeCuotasSelected() {
        var obs1 = observeOnScope($scope, "vm.cuotasFiltradas")
            .filter(function(data) {
                return data.newValue;
            })
            .map(function(data) {
                return data.newValue;
            });

        obs1.subscribe(function(change) {
            vm.showSpinner = true;
            vm.detalleAdded = true;
            _.forEach(change, function(val) {
                val.$promise.then(function(cuota) {
                    addCuota(cuota);
                });
            });
            $q.all(change).then(function() {
                vm.traerComprobantes = true;
                if (vm.mode === "add") {
                    vm.comprobante.comprobanteVentaAplicaciones = vm.comprobanteAplicaciones;
                }
                vm.showSpinner = false;
            });
        });
    }

    function addComprobante(comprobanteA) {

        // armar una estructura donde ir metiendo los comprobantes
        // si el tipo del comprobante admite cuotas, meter un registro por cada cuota con su nro correspondiente
        // sino meter un unico registro con nro cuota = 1
        $log.debug("procesando comprobantes en comprobanteAplicacion");
        // probar comprobantes con cuota, por ahora no esta generando
        if (comprobanteA.tipo.generarCuotas == true) {
            //console.log("Comrpobante con cuotas:");
            //console.log(comprobanteA);
            _.forEach(_.filter(comprobanteA.planCobros.planCobrosDetalles,
                filterSaldoCuotaPositivo), function(
                cuotaA
            ) {
                var comprobanteAplicacion = {};
                //console.log("creando cuota");
                comprobanteAplicacion.comprobanteVentaAplicado = {};
                comprobanteAplicacion.cuota = {};
                comprobanteAplicacion.comprobanteVentaAplicado.id =
                    comprobanteA.id;
                comprobanteAplicacion.comprobanteVentaAplicado.numero =
                    comprobanteA.numero;
                comprobanteAplicacion.comprobanteVentaAplicado.tipo =
                    comprobanteA.tipo;
                comprobanteAplicacion.comprobanteVentaAplicado.saldoImpuestos =
                    comprobanteA.saldoImpuestos;
                comprobanteAplicacion.comprobanteVentaAplicado.totalImpuestos =
                    comprobanteA.totalImpuestos;
                comprobanteAplicacion.cuota.id = cuotaA.id;
                comprobanteAplicacion.cuota.numero = cuotaA.numero;
                comprobanteAplicacion.cuota.saldo = cuotaA.saldo;
                comprobanteAplicacion.comprobanteVentaAplicado.saldo =
                    comprobanteA.saldo;

                comprobanteAplicacion.comprobanteVentaAplicado.montosImponibles =
                    comprobanteA.saldo;

                comprobanteAplicacion.montoAplicado = cuotaA.saldo;
                checkSaldoCuotas(cuotaA.saldo, vm.comprobante.saldo);



                vm.comprobanteAplicaciones.push(comprobanteAplicacion);
            });
        } else {
            var comprobanteAplicacion = {};
            comprobanteAplicacion.comprobanteVentaAplicado = {};
            comprobanteAplicacion.comprobanteVentaAplicado.id = comprobanteA.id;
            comprobanteAplicacion.comprobanteVentaAplicado.numero =
                comprobanteA.numero;
            comprobanteAplicacion.comprobanteVentaAplicado.tipo =
                comprobanteA.tipo;
            comprobanteAplicacion.comprobanteVentaAplicado.saldo =
                comprobanteA.saldo;
            comprobanteAplicacion.comprobanteVentaAplicado.saldoImpuestos =
                comprobanteA.saldoImpuestos;
            comprobanteAplicacion.comprobanteVentaAplicado.totalImpuestos =
                comprobanteA.totalImpuestos;

            comprobanteAplicacion.comprobanteVentaAplicado.montosImponibles =
                comprobanteA.montosImponibles;

            var banInsertar = true;
            _.forEach(vm.comprobante.comprobanteVentaAplicaciones, function(comprobanteDetalle) {

                console.log(comprobanteDetalle.comprobanteVentaAplicado.numero);
                if(comprobanteAplicacion.comprobanteVentaAplicado.numero == comprobanteDetalle.comprobanteVentaAplicado.numero){
                    banInsertar = false;
                }
            });

            if(banInsertar){
                vm.comprobanteAplicaciones.push(comprobanteAplicacion);
            }
        }
    }

    function addCuota(cuotaA) {
        // armar una estructura donde ir metiendo los comprobantes
        // si el tipo del comprobante admite cuotas, meter un registro por cada cuota con su nro correspondiente
        // sino meter un unico registro con nro cuota = 1
        $log.debug("procesando comprobantes en comprobanteAplicacion");

        // probar comprobantes con cuota, por ahora no esta generando
        var comprobanteAplicacion = {};
        //console.log("creando cuota");
        comprobanteAplicacion.comprobanteVentaAplicado = {};
        comprobanteAplicacion.cuota = {};
        comprobanteAplicacion.comprobanteVentaAplicado.id =
            cuotaA.planCobros.comprobanteVenta.id;
        comprobanteAplicacion.comprobanteVentaAplicado.numero =
            cuotaA.planCobros.comprobanteVenta.numero;
        comprobanteAplicacion.comprobanteVentaAplicado.tipo =
            cuotaA.planCobros.comprobanteVenta.tipo;
        comprobanteAplicacion.comprobanteVentaAplicado.saldoImpuestos =
            cuotaA.planCobros.comprobanteVenta.saldoImpuestos;
        comprobanteAplicacion.comprobanteVentaAplicado.totalImpuestos =
            cuotaA.planCobros.comprobanteVenta.totalImpuestos;
        comprobanteAplicacion.cuota.id = cuotaA.id;
        comprobanteAplicacion.cuota.numero = cuotaA.numero;
        comprobanteAplicacion.cuota.saldo = cuotaA.saldo;
        comprobanteAplicacion.comprobanteVentaAplicado.saldo =
            cuotaA.planCobros.comprobanteVenta.saldo;

        comprobanteAplicacion.comprobanteVentaAplicado.montosImponibles =
        cuotaA.planCobros.comprobanteVenta.montosImponibles;

        comprobanteAplicacion.montoAplicado = cuotaA.saldo;

        checkSaldoCuotas(cuotaA.saldo, vm.comprobante.saldo);
        
        vm.comprobanteAplicaciones.push(comprobanteAplicacion);
    }

    function getOriginalSaldo() {
        var comprobanteAplicaciones = vm.comprobante.comprobanteVentaAplicaciones;

        vm.comprobante.saldo = vm.comprobante.montoTotal;
        vm.comprobante.saldoImpuestos = vm.comprobante.totalImpuestos;

        var group = _.groupBy(comprobanteAplicaciones, function(
            comprobanteAplicacion
        ) {
            return comprobanteAplicacion.comprobanteVentaAplicado.id;
        });
        _.forOwn(group, function(value, key) {
            var suma = 0;
            _.forEach(value, function(comprobanteAplicacion) {
                suma += comprobanteAplicacion.montoAplicado;
                if (comprobanteAplicacion.cuota) {
                    comprobanteAplicacion.cuota.saldo =
                        comprobanteAplicacion.cuota.saldo +
                        comprobanteAplicacion.montoAplicado;

                        comprobanteAplicacion.comprobanteVentaAplicado.planCobros.saldo =
                        comprobanteAplicacion.comprobanteVentaAplicado.planCobros.saldo +
                        comprobanteAplicacion.montoAplicado;
                }
            });
            var comprobanteAplicacion = value[0];
            comprobanteAplicacion.comprobanteVentaAplicado.saldo =
                comprobanteAplicacion.comprobanteVentaAplicado.saldo + suma;

            comprobanteAplicacion.comprobanteVentaAplicado.saldoImpuestos =
                comprobanteAplicacion.comprobanteVentaAplicado.saldoImpuestos +
                comprobanteAplicacion.impuestoAplicado;
        });
    }

    function buscarComprobantes() {
        if (vm.fechaDesde && vm.fechaHasta) {
            //vm.traerComprobantes = true;
            getComprobantes();
        }
    }

    function filterSaldoCuotaPositivo(cuota) {
        return cuota.saldo > 0;
    }

    function clearFiltrosComprobantes() {
        vm.fechaDesde = null;
        vm.fechaHasta = null;
        if (vm.comprobante && vm.comprobante.comprobanteVentaAplicaciones)
            vm.comprobante.comprobanteVentaAplicaciones = null;
    }

    function getComprobantes() {

        if (!vm.mode == "add") {
            return;
        }
        var tiposComprobantesAsociados = vm.comprobante.tipo.tiposComprobante;
        // console.log(tiposComprobantesAsociados);
        // if (vm.traerComprobantes === true) {
        if (!tiposComprobantesAsociados ||
            tiposComprobantesAsociados.length == 0
        ) {
            $translate("NO_COMPROBANTES_APLICABLES", {
                comprobante: vm.comprobante.tipo.descripcion
            }).then(function(response) {
                $scope.tituloModal = response;
            });

            $scope.modalInstanceBorrar1 = $modal.open({
                template: '<div class="modal-header">' +
                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                    "</div>" +
                    '<div class="modal-footer">' +
                    '<button class="btn btn-primary" ng-click="ok()">' +
                    $filter("translate")("OK") +
                    "</button>" +
                    "</div>",
                scope: $scope
            });

            $scope.ok = function() {
                $scope.modalInstanceBorrar1.dismiss("");
                $state.go("app.ventas.proceso.comprobanteventa.list", {
                    codigoVenta: VentaFactory.getIdVenta()
                });
            };
            return;
        }

        // vm.showSpinner = true;

        $log.debug("vm.traerComprobantes == true");
        var filters = [];
        var staticFilter = {};

        //_.forEach(tiposComprobantesAsociados, function (tipoComprobante) {
            // Filtro timbrado no virtual

            filters.push(
                {
                    path: "tipo.clase.codigo",
                    equals: vm.claseComprobante.codigo
                },
                {
                    path: "fecha",
                    equalOrAfter: vm.fechaDesde != undefined ?
                        moment(vm.fechaDesde).format("DD/MM/YYYY") : undefined
                },
                {
                    path: "fecha",
                    equalOrBefore: vm.fechaHasta != undefined ?
                        moment(vm.fechaHasta).format("DD/MM/YYYY") : undefined
                },
                {
                    path: "moneda.id",
                    equals: vm.comprobante.moneda.id
                },
                {
                    path: "cliente.id",
                    equals: vm.comprobante.cliente.id
                },
                {
                    path: "tipo.generarCuotas",
                    like: "false"
                },
                {
                    path: "saldo",
                    greaterThan: 0
                }
            )
            if (vm.claseTimbrado.codigo == 'timbrado_virtual') {
                filters.push({
                    path: "estado.codigo",
                    equals: "comp_venta_aprobado_sifen"
                },
                    {
                        path: "timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo",
                        equals: "timbrado_virtual"
                    })
            } else {
                filters.push({
                    path: "estado.codigo",
                    equals: "comp_venta_emitido"
                },
                    {
                        path: "timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo",
                        notEquals: "timbrado_virtual"
                    })
            }

       // }); 
       staticFilter.search = filterFactory.and(filters).value();


        vm.opcionesComprobantesDt.staticFilter = staticFilter;
        // }
    }

    function getCobros() {
        if (!vm.mode == "add") {
            return;
        }

        var tiposComprobantesAsociados = vm.comprobante.tipo.tiposComprobante;
        // console.log(tiposComprobantesAsociados);
        // if (vm.traerComprobantes === true) {
        if (!tiposComprobantesAsociados ||
            tiposComprobantesAsociados.length == 0
        ) {
            $translate("NO_COMPROBANTES_APLICABLES", {
                comprobante: vm.comprobante.tipo.descripcion
            }).then(function(response) {
                $scope.tituloModal = response;
            });

            $scope.modalInstanceBorrar1 = $modal.open({
                template: '<div class="modal-header">' +
                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                    "</div>" +
                    '<div class="modal-footer">' +
                    '<button class="btn btn-primary" ng-click="ok()">' +
                    $filter("translate")("OK") +
                    "</button>" +
                    "</div>",
                scope: $scope
            });

            $scope.ok = function() {
                $scope.modalInstanceBorrar1.dismiss("");
                $state.go("app.ventas.proceso.comprobanteventa.list", {
                    codigoVenta: VentaFactory.getIdVenta()
                });
            };

            return;
        }

        //  vm.showSpinner = true;

        $log.debug("vm.traerComprobantes == true");
        var staticFilter = {};
        var filters = [];
        //_.forEach(tiposComprobantesAsociados, function (tipoComprobante) { 
            //Filtro timbrado no virtual

            filters.push({
                path: "planCobros.comprobanteVenta.tipo.clase.codigo",
                equals: vm.claseComprobante.codigo
            },
                {
                    path: "planCobros.comprobanteVenta.fecha",
                    equalOrAfter: vm.fechaDesde != undefined ?
                        moment(vm.fechaDesde).format("DD/MM/YYYY") : undefined
                },
                {
                    path: "planCobros.comprobanteVenta.fecha",
                    equalOrBefore: vm.fechaHasta != undefined ?
                        moment(vm.fechaHasta).format("DD/MM/YYYY") : undefined
                },
                {
                    path: "planCobros.comprobanteVenta.moneda.id",
                    equals: vm.comprobante.moneda.id
                },
                {
                    path: "planCobros.comprobanteVenta.cliente.id",
                    equals: vm.comprobante.cliente.id
                },
                {
                    path: "planCobros.comprobanteVenta.tipo.generarCuotas",
                    like: "true"
                }, {
                path: "saldo",
                greaterThan: 0
            }, {
                path: 'planCobros.estado.codigo',
                notEquals: "plancobros_pagado"
            })

            if (vm.claseTimbrado.codigo == 'timbrado_virtual') {
                filters.push({
                    path: "planCobros.comprobanteVenta.estado.codigo",
                    equals: "comp_venta_aprobado_sifen"
                },
                    {
                        path: "planCobros.comprobanteVenta.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo",
                        equals: "timbrado_virtual"
                    })
            } else {
                filters.push({
                    path: "planCobros.comprobanteVenta.estado.codigo",
                    equals: "comp_venta_emitido"
                },
                    {
                        path: "planCobros.comprobanteVenta.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo",
                        notEquals: "timbrado_virtual"
                    })
            }

        //}); 
        staticFilter.search = filterFactory.and(filters).value();
        vm.opcionesPlanCobrosDetallesDt.staticFilter = staticFilter;
        // }
    }

    function montoAplicadoChanged(detalle) {
        vm.enableSave = false;
    }

    function submit() {
        $rootScope.isProcessing = true;
        vm.submited = true;

        if (vm.comprobante.saldo >= 0) {
            // guardar el comprobante con su lista de comprobantes aplicados
            vm.comprobante.comprobanteVentaAplicaciones = _.filter(
                vm.comprobante.comprobanteVentaAplicaciones,
                function(elem) {
                    return elem.montoAplicado;
                }
            );
            ComprobanteVentaFactory.aplicar(vm.comprobante, vm.mode).then(
                function(data) {
                    if (!vm.fromOutSide) {
                        $scope.$emit("updateImportacionStates");
                        $state.go("app.ventas.proceso.comprobanteventa.list", {
                            codigoVenta: VentaFactory.getIdVenta()
                        });
                    } else {
                        $state.go("app.consultacomprobantesventa.list");
                    }
                },
                function(error) {
                    $rootScope.isProcessing = false;
                    console.log(error);
                    var msgArray = _.map(error.data, function(e) {
                        return e.message;
                    });
                    var msg = msgArray.join("\n");
                    notify({ message: msg, classes: "alert-danger", position: "right" });
                    vm.comprobante.comprobanteVentaAplicaciones = _.filter(
                        vm.comprobante.comprobanteVentaAplicaciones,
                        function(ca) {
                            return !ca.id;
                        }
                    );
                }
            );
        } else {
            //console.log("saldo menor a 0");
            var msg = $filter("translate")("COMPROBANTE_SIN_SALDO");
            notify({ message: msg, classes: "alert-danger", position: "right" });
            vm.view = true;
        }
    }

    function validacionParametro(){
        return $q(function(resolve, reject) {
            var params = {};
            params.search = filterFactory
                    .single({
                        path: "codigo",
                        equals: "descontar_nc_calculo_impuesto"
                    })
                    .value();
            ParametrosFactory.all(params).$promise.then(function(data){
                if(data[0].valorTipo.codigo!="no"){
                    resolve(true);
                }else{
                    resolve(false);
                }
            });
        });
    }

    function previewAplicacion() {
        if (vm.comprobante.comprobanteVentaAplicaciones) {
            var saldoOriginal = vm.comprobante.saldo;
            var saldoImpuesto = vm.comprobante.saldoImpuestos;
            _.forEach(vm.comprobante.comprobanteVentaAplicaciones, function(comprobanteAplicado) {
                comprobanteAplicado.impuestoAplicado = 0;
            });
            vm.monedaLocal.$promise
                .then(function() {
                    return checkAmounts();
                })
                .then(function() {
                    vm.enableSave = true;
                })
                .catch(function(error) {
                    notify({
                        messageTemplate: "<span>" + error.join("<br/><br/>") + "</span>",
                        classes: "alert-danger",
                        position: "right"
                    });
                    vm.enableSave = false;
                })
                .finally(function() {
                    vm.comprobante.saldo = saldoOriginal;
                    vm.comprobante.saldoImpuestos = saldoImpuesto;
                });
        }
    }

    function checkAmounts() {
        return $q(function(resolve, reject) {

            var saldoAplicacion = vm.comprobante.saldo;
            var saldoImpuesto = vm.comprobante.saldoImpuestos;

            if(vm.mode=="edit"){
                var saldoAplicacion = vm.comprobante.montoTotal;
            }

            var mensajesPromises = [];
            var group = _.groupBy(
                vm.comprobante.comprobanteVentaAplicaciones,
                function(comprobanteAplicacion) {
                    return comprobanteAplicacion.comprobanteVentaAplicado.id;
                }
            );
            _.forOwn(group, function(value, key) {

                if (value[0].cuota) {
                    group[key] = _.sortBy(value, function(ca) {
                        return ca.cuota.numero;
                    });
                }
            });

            _.forOwn(group, function(value, key) {
                var saldoInicial = saldoAplicacion;
                var sumaAplicacion = 0;
                _.forEach(value, function(comprobanteAplicacion) {
                    var montoAplicado = comprobanteAplicacion.montoAplicado ?
                        comprobanteAplicacion.montoAplicado :
                        0;
                    if (montoAplicado <= 0) {
                        mensajesPromises.push(
                            $translate("MONTO_MENOR_IGUAL_CERO", {
                                comprobante: comprobanteAplicacion.comprobanteVentaAplicado.numero
                            })
                        );
                    }
                    if (!comprobanteAplicacion.cuota) {
                        var saldo = comprobanteAplicacion.comprobanteVentaAplicado.saldo;
                        if (montoAplicado > saldo) {
                            //console.log("El monto aplicado es mayor al sado de comprobante");
                            mensajesPromises.push(
                                $translate("MONTO_MAYOR_SALDO_COMPROBANTE", {
                                    comprobante: comprobanteAplicacion.comprobanteVentaAplicado.numero
                                })
                            );
                        }
                    } else {
                        var saldo = comprobanteAplicacion.cuota.saldo;
                        if (montoAplicado > saldo) {
                            //console.log("El monto aplicado es mayor al sado de la cuota");
                            mensajesPromises.push(
                                $translate("MONTO_MAYOR_SALDO_CUOTA", {
                                    comprobante: comprobanteAplicacion.comprobanteVentaAplicado.numero,
                                    cuota: comprobanteAplicacion.cuota.numero
                                })
                            );
                        }
                    }
                    var saldoPadre = saldoAplicacion;
                    if (montoAplicado > saldoPadre) {
                        //console.log("El montoAplicado es mayor a saldoAplicacion");
                        mensajesPromises.push(
                            $translate("MONTO_MAYOR_SALDO_ACTUAL", {
                                comprobante: comprobanteAplicacion.comprobanteVentaAplicado.numero
                            })
                        );
                    }

                    saldoAplicacion -= montoAplicado;
                    sumaAplicacion += montoAplicado;
                });

                var comprobanteAplicacion = value[0];

                var saldoInicialTrue = saldoInicial;
                var porcentajeAplicado = 100 * sumaAplicacion / saldoInicialTrue;

                if (porcentajeAplicado > 100) {
                    mensajesPromises.push(
                        $translate("APLICACION_MAYOR_TOTAL_ERROR", {
                            comprobante: comprobanteAplicacion.comprobanteVentaAplicado.numero
                        })
                    );
                }

                var impuestoAplicado = 0;
                
                if(vm.paramDescontarNC){
                    if (
                        comprobanteAplicacion &&
                        comprobanteAplicacion.comprobanteVentaAplicado.totalImpuestos != 0
                    ) {
                        impuestoAplicado = porcentajeAplicado * saldoImpuesto / 100;

                        if (
                            vm.comprobante.moneda &&
                            vm.comprobante.moneda.descripcion ==
                            vm.monedaLocal[0].valorTipo.descripcion
                        ) {
                            impuestoAplicado = Math.round(impuestoAplicado);
                        }
                        var sImpuesto =
                            comprobanteAplicacion.comprobanteVentaAplicado.saldoImpuestos;

                        if(sImpuesto==undefined){
                            sImpuesto=0;
                        }

                        var dif = impuestoAplicado - sImpuesto;

                        if (dif > 1) {
                            mensajesPromises.push(
                                $translate("APLICACION_IMPUESTO_MAYOR_SALDO", {
                                    comprobante: comprobanteAplicacion.comprobanteVentaAplicado.numero
                                })
                            );
                        } else if (dif == 1) {
                            impuestoAplicado = sImpuesto;
                        }
                    }
                    saldoImpuesto -= impuestoAplicado;
                    if (comprobanteAplicacion) {
                        comprobanteAplicacion.impuestoAplicado = impuestoAplicado;
                    }

                    if (mensajesPromises.length > 0) {
                        $q.all(mensajesPromises).then(function(response) {
                            reject(response);
                        });
                    } else {
                        console.log("sin errores");
                        resolve();
                    }
                }else{
                    if (mensajesPromises.length > 0) {
                        $q.all(mensajesPromises).then(function(response) {
                            reject(response);
                        });
                    } else {
                        console.log("sin errores");
                        resolve();
                    }
                }
            });
        });
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function(item) {
            return item === elemento;
        });
    }

    function cancel() {
        if (!vm.fromOutSide) {
            // $state.go("app.ventas.proceso.comprobanteventa.list");
            $scope.$emit("updateImportacionStates");
            $state.go("app.ventas.proceso.comprobanteventa.list", {
                codigoVenta: VentaFactory.getIdVenta()
            });
        } else {
            $state.go("app.consultacomprobantesventa.list");
        }
    }

    function checkSaldoCuotas(saldoCuota, saldoComprobante) {
        if (saldoCuota > saldoComprobante) {
            var msg = "El saldo de la cuota excede el saldo del comprobante." +
                         "Si necesita hacer una aplicación parcial, por favor modifique el plan de cobro asociado al comprobante.";
            notify({
                message: msg,
                classes: 'alert-warning',
                position: 'right',
                duration: 10000  // Duración en milisegundos (10 segundos)
            });
        }
    }
}
'use strict';

angular.module('qualita.venta')
    .controller('PedidoVentaFormController', PedidoVentaFormController);

PedidoVentaFormController.$inject = ['$rootScope', '$scope', 'PedidoVentaFactory', 'ProductosFactory',
    'formFactory', 'TiposFactory', '$location', '$state', 'pedidoVentaPrepService',
    '$timeout', 'notify', 'ModelTrimmer', 'filterFactory', 'VentasLangFactory', 'baseurl',
    'WatchLocacionChangeFactory', 'UtilFactory', 'ParametrosFactory', 'CotizacionMonedaFactory',
    'ClienteFactory', 'CambioPrecioFactory', '$filter', 'AuthorizationService', 'ListaPrecioVentaFactory', 'PresupuestoVentaFactory', 'ProductoAlmacenFactory',
    'VendedorFactory', 'AlmacenFactory','ProyectoFactory', 'SolicitudAutorizacionFactory', 'GrupoProductoAlmacenFactory', '$modal', 'UsuariosFactory',
    'FormaPagoFactory','CentroCostosFactory','ListaPrecioVentaDetalleFactory','ultimoCostoAlmacenFactory'
];

function PedidoVentaFormController($rootScope, $scope, PedidoVentaFactory, ProductosFactory,
    formFactory, TiposFactory, $location, $state, pedidoVentaPrepService,
    $timeout, notify, ModelTrimmer, filterFactory, VentasLangFactory, baseurl,
    WatchLocacionChangeFactory, UtilFactory, ParametrosFactory, CotizacionMonedaFactory, ClienteFactory,
    CambioPrecioFactory, $filter, AuthorizationService, ListaPrecioVentaFactory, PresupuestoVentaFactory, ProductoAlmacenFactory,
    VendedorFactory, AlmacenFactory,ProyectoFactory, SolicitudAutorizacionFactory, GrupoProductoAlmacenFactory, $modal, UsuariosFactory,
    FormaPagoFactory,CentroCostosFactory,ListaPrecioVentaDetalleFactory,ultimoCostoAlmacenFactory) {


    var vm = this;
    vm.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    vm.errores =[];
    vm.precioMinimoMaximos = [];
    vm.visualizar = true;


    vm.uiBlockuiConfig = {
      'bloquear': false,
      'message': 'Cargando detalles...'
   };

    // Recupera parametro para mostrar o no campo "precio"
    vm.cantidadDecimales = undefined;
    ParametrosFactory.getByCodigo('ver_precio_pedido').then(function (parametro) {
        vm.verPrecio = parametro.valor
    });
    ParametrosFactory.getByCodigo('ver_control_costo_pv').then(function (parametro){
      vm.verCostoControl = parametro.valor;
    });
    ParametrosFactory.getByCodigo('incluir_producto_not_lista_precio').then(function (parametro) {
      vm.permitirProductosNotListaPrecio = parametro.valor
  });

    ParametrosFactory.getByCodigo('asignar_lote_sin_criterio').then(function (parametro) {
        if (parametro.valorTipo.codigo === 'no') {
            vm.asignarLoteSinCriterio = false;
        } else {
            vm.asignarLoteSinCriterio = true;
        }
    });

    ParametrosFactory.getByCodigo('control_ventas_perdidas').then(function (parametro) {
        if (parametro.valorTipo.codigo === 'no') {
            vm.controlVentasPerdidas = false;
            vm.cantidadTitulo = "{{'CANTIDAD' | translate}}";
        } else {
            vm.controlVentasPerdidas = true;
            vm.cantidadTitulo = "{{'CANTIDAD_FACTURADA' | translate}}";
        }
    });

    ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
        if (parametro.valorTipo.codigo === 'no') {
            vm.utilizarPrecioBase = false;
        } else {
            vm.utilizarPrecioBase = true;
        }
    });
    ParametrosFactory.getByCodigo('asignar_grupo_pv').then(function (parametro) {
        if (parametro.valorTipo.codigo === 'no') {
            vm.asignarGrupo = false;
        } else {
            vm.asignarGrupo = true;
        }
    });

    ParametrosFactory.getByCodigo('vendedor_obligatorio_pv').then(function (parametro) {
        if (parametro.valorTipo.codigo === 'no') {
            vm.vendedorObligatorio = false;
        } else {
            vm.vendedorObligatorio = true;
        }
    });
    ParametrosFactory.getByCodigo('utilizar_centro_costo_ventas').then(function (parametro) {
      if (parametro.valorTipo.codigo === 'no') {
          vm.utilizarCentroCostos = false;
      } else {
          vm.utilizarCentroCostos = true;
      }
    });
    ParametrosFactory.getByCodigo('centro_costo_obligatorio').then(function (parametro) {
      if (parametro.valorTipo.codigo === 'no') {
          vm.centroCostoObligatorio = false;
      } else {
          vm.centroCostoObligatorio = true;
      }
    });
    ParametrosFactory.getByCodigo('asignacion_lista_precios_linea_producto_por_segmento').then(function (parametro) {
        if (parametro.valorTipo.codigo === 'no') {
            vm.precioSegmento = false;
        } else {
            vm.precioSegmento = true;
        }
    });

    ParametrosFactory.getByCodigo('ver_precio_pedido').then(function (parametro) {
        if (parametro.valorTipo.codigo === 'no') {
            vm.visualizar = false;
        }
    });

    activate();
    vm.agregarDetalle = agregarDetalle;
    vm.removeItemFromArray = removeItemFromArray;
    vm.removeItemFromArrayIndex = removeItemFromArrayIndex;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.changeProducto = changeProducto;
    vm.changeMoneda = changeCotizacion;
    vm.changeCondicion = changeCondicion;
    $rootScope.isProcessing = false;
    vm.nroOrden = false;
    vm.nroMov = false;
    vm.totalGeneral = 0;
    vm.calcularTotalDetalle = calcularTotalDetalle;
    vm.factoryProductos = ProductosFactory;
    vm.totalItemChanged = totalItemChanged;
    vm.changeUnidad = changeUnidad;
    vm.changeCliente = changeCliente;
    vm.changeGrupoProducto = changeGrupoProducto;
    vm.grupoRepetido = false;
    vm.plusClicked = plusClicked;
    vm.delayRequestProducto = delayRequestProducto;
    vm.compararDescendente = compararDescendente;
    vm.changeListaPrecioVenta = changeListaPrecioVenta;
    vm.searchPresupuestoFilter = searchPresupuestoFilter;
    vm.presupuestoSelect = presupuestoSelect;
    vm.getListasPreciosVentasByMoneda = getListasPreciosVentasByMoneda;
    vm.actualizarPreciosDeVentas = actualizarPreciosDeVentas;
    vm.changePrecioCambioPactado = changePrecioCambioPactado;
    vm.changePrecioCambio = changePrecioCambio;
    vm.copiarCantidad = copiarCantidad;
    vm.changePorcentajeDescuento = changePorcentajeDescuento;
    vm.volverSolicitud = volverSolicitud;
    vm.verModalGrupoProducto = verModalGrupoProducto;
    vm.distribuidoLotes = false;
    vm.changeSucCliente = changeSucCliente;
    vm.changeCondicionVenta = changeCondicionVenta;
    vm.delayRequestCliente = delayRequestCliente;
    vm.delayRequestDocumentoMobile = delayRequestDocumentoMobile;
    vm.delayRequestClienteMobile = delayRequestClienteMobile;
    vm.searchClienteFilter = searchClienteFilter;
    vm.searchSucursalClienteFilter = searchSucursalClienteFilter;
    vm.changeUnidadAux = changeUnidadAux;
    function changeCondicionVenta() {
        if (vm.pedidoVenta.formaPago) {
            vm.pedidoVenta.cantidadCuotas = vm.pedidoVenta.formaPago.cantidadCuotas;
        }
    }

    var unidadMedidaRenderCantidad = function (data) {
        if (data != undefined) {
            var digitosDesdePunto = data.toString().substr(data.toString().indexOf('.'));
            //console.log("digitos desde el punto decimal: ", digitosDesdePunto);
            if (data != 0 && digitosDesdePunto != 0) {
                data = parseFloat(data).toFixed(4);
            }
            // El calculo para convertir entre unidades consiste en la division por $scope.multiplicador
            $scope.multiplicador = 1;
            data = data / $scope.multiplicador;
            data = Math.round(data * 100) / 100;
            var parteEntera = data.toString().substr(0, data.toString().indexOf('.'));
            var parteDecimal = data.toString().substr(data.toString().indexOf('.') + 1);
            // Si tiene parte entera
            if (parteEntera != '') {
                if (digitosDesdePunto != 0) {
                    return (
                        parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
                        "," + parteDecimal.toString()
                    );
                } else {
                    parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            } else {
                return (
                    data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            }
        } else {
            return "";
        }
    };
    function verModalGrupoProducto(detalle) {
        console.log(detalle);
        var defaultColumnOrder = ['id', 'grupoProducto.numero', 'saldoReal', 'saldoTransito', 'ultimoCosto', 'costoPromedio'];
        var staticFilter = [];
        // El filtro almacen siempre tiene un valor definido (al cargar la pagina es 'Todos')
        staticFilter.push(
            {
                path: 'grupoProducto.producto.id',
                equals: detalle.producto.id
            }
        )
        staticFilter.push(
            {
                path: 'almacen.id',
                equals: vm.pedidoVenta.almacen.id
            }
        )
        staticFilter.push(
            {
                path: 'dia',
                sortDesc: 'true'
            }
        )
        // Si tiene fecha definida, se agrega el filtro
        if ($scope.fecha != undefined) {
            var fechaFormateada = moment($scope.fecha).format('DD/MM/YYYY');
            staticFilter.push(
                {
                    path: 'dia',
                    equalOrBefore: fechaFormateada
                }
            )
        }
        var filter = {
            search: filterFactory.and(staticFilter).value()
        };
        $scope.vm.options = {
            'resource': 'grupoproductoalmacen/saldogrupoproducto',
            'staticFilter': filter,
            'title': 'Grupo Producto',
            'view': 'BaseList',
            'factory': GrupoProductoAlmacenFactory,
            'defaultColumnOrder': defaultColumnOrder,
            'columns': [
                { 'data': 'id', 'title': $filter('translate')('CODE'), 'class': 'dt-center', 'searchable': false },
                { 'data': 'grupoProducto.numero', 'title': 'Nro. Grupo', 'class': 'dt-center', 'searchable': false },
                { 'data': 'saldoReal', 'title': 'Existencia (UM Base)', 'class': 'dt-center', 'render': unidadMedidaRenderCantidad, 'searchable': false },
                { 'data': 'saldoTransito', 'title': 'Existencia en tránsito (UM Base)', 'class': 'dt-center', 'render': unidadMedidaRenderCantidad, 'searchable': false },
                { 'data': 'ultimoCosto', 'title': $filter('translate')('COSTO_UNITARIO'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'searchable': false, 'orderable': false }
            ],
            'hasOptions': false,
            'defaultOrderColumn': 0,
            'defaultOrderDir': "desc",
            'hideHeader': true,
        };
        $scope.modalInstance = $modal.open({
            templateUrl: 'views/directives/datatables-modal.html',
            scope: $scope,
            size: 'lg'
        });
        $scope.vm.close = function () {
            if ($scope.modalInstance) {
                $scope.modalInstance.close();
            }
        }
    }

    function changePorcentajeDescuento() {
        if (vm.pedidoVenta.porcentajeDescuento != undefined) {
            var i =0;
            _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (elem) {
                elem.porcentajeDescuento = vm.pedidoVenta.porcentajeDescuento;
                calcularTotalDetalle(elem,i);
                i=i+1;
            });
        }

    }

    function copiarCantidad(detalle) {
        if (!vm.isEdit && !vm.view) {
            detalle.cantidad = detalle.cantidadPedida;
            vm.calcularTotalDetalle(detalle);
        }

        if (detalle.unidadMedida.esBase) {
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidad).toFixed(4);
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidadUmPrincipal);
        } else {
            //hacer calculo en um base
            var contenida = detalle.unidadMedida.cantidad;
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidad* contenida).toFixed(4);
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidadUmPrincipal);
        }
    }

    function searchPresupuestoFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                (item.id + "").indexOf(criteria.toLowerCase()) >
                -1 ||
                item.cliente.nombre
                    .toLowerCase()
                    .indexOf(criteria.toLowerCase()) > -1
            );
        };
    };

    function delayRequestProducto(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {

            var filterNombre = vm.opcionesProductosDt.staticFilter.search.filters.slice()
            var filterCodigoBarras = vm.opcionesProductosDt.staticFilter.search.filters.slice()
            var filterCodigoInterno = vm.opcionesProductosDt.staticFilter.search.filters.slice()

            filterNombre.push({
                path: "nombre",
                like: searchValue
            })

            filterCodigoBarras.push({
                path: "codigoBarrasPrincipal.codigoBarras",
                like: searchValue
            })

            filterCodigoInterno.push({
                path: "codigo",
                like: searchValue
            })

            filterNombre.push({
              path: "locaciones.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            filterCodigoBarras.push({
              path: "locaciones.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            filterCodigoInterno.push({
              path: "locaciones.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }

            recuperarProducto(userInputFilter)
        } else {
            vm.productos = undefined
        }
    }

    function presupuestoSelect() {
        vm.pedidoVenta.pedidoVentaDetalle = [];

        vm.tienePresupuesto = true;
        vm.pedidoVenta.cliente = vm.pedidoVenta.presupuestoVenta.cliente;
        vm.pedidoVenta.sucursalCliente = vm.pedidoVenta.presupuestoVenta.sucursalCliente;
        vm.pedidoVenta.moneda = vm.pedidoVenta.presupuestoVenta.moneda;
        vm.pedidoVenta.cambio = vm.pedidoVenta.presupuestoVenta.tipoCambio;
        vm.pedidoVenta.condicionCobro = vm.pedidoVenta.presupuestoVenta.condicionVenta;
        vm.pedidoVenta.cantidadCuotas = vm.pedidoVenta.presupuestoVenta.cantidadCuotas;
        vm.pedidoVenta.vendedor = vm.pedidoVenta.presupuestoVenta.vendedor;
        vm.pedidoVenta.formaPago = vm.pedidoVenta.presupuestoVenta.formaPago;
        vm.pedidoVenta.comentario = vm.pedidoVenta.presupuestoVenta.comentario;
        vm.pedidoVenta.centroCostos = vm.pedidoVenta.sucursalCliente.centroCostos;
        if(vm.pedidoVenta.presupuestoVenta.proyecto){
            vm.pedidoVenta.proyecto = vm.pedidoVenta.presupuestoVenta.proyecto;
        }

        var suc = [];
        for(var i = 0; i < vm.pedidoVenta.cliente.sucursales.length; i++){
          if(vm.pedidoVenta.cliente.sucursales[i].activo === true){
            suc.push(vm.pedidoVenta.cliente.sucursales[i])
          }
        }
        vm.sucursalesCliente = suc;

        if (vm.pedidoVenta.presupuestoVenta.listaPrecioVenta) {
            vm.pedidoVenta.monedaLista = vm.pedidoVenta.presupuestoVenta.listaPrecioVenta.moneda;
        }
        if (vm.pedidoVenta.presupuestoVenta.listaPrecioVenta) {
            ListaPrecioVentaFactory.get(vm.pedidoVenta.presupuestoVenta.listaPrecioVenta.id, 'PedidoVentaForm').$promise.then(function (lista) {
                vm.pedidoVenta.listaPrecioVenta = lista;
                recuperarPreciosMinimosMaximos();
                if (vm.pedidoVenta.presupuestoVenta.listaPrecioVenta) {
                    vm.pedidoVenta.monedaLista = vm.pedidoVenta.presupuestoVenta.listaPrecioVenta.moneda;
                }
                getCambio();
            });
        }

        vm.porcRecargo = 0;
        vm.precioVenta = 'Precio de Venta';
        var presuVent = vm.pedidoVenta.presupuestoVenta;
        var pvd;
        var cont = 0;
        for (pvd in presuVent.presupuestoVentaDetalle) {
            if (presuVent.presupuestoVentaDetalle[pvd].cantidadSobrante != 0) {
                vm.pedidoVenta.pedidoVentaDetalle.push({
                    producto: presuVent.presupuestoVentaDetalle[pvd].producto,
                    unidadMedida: presuVent.presupuestoVentaDetalle[pvd].unidadMedida,
                    cantidadPresupuesto: presuVent.presupuestoVentaDetalle[pvd].cantidadSobrante,
                    cantidadPresupuestoInicial: presuVent.presupuestoVentaDetalle[pvd].cantidad,
                    numeroOrdenProduccion:presuVent.presupuestoVentaDetalle[pvd].numeroOrdenProduccion,
                    numeroOrdenCompraCliente:presuVent.presupuestoVentaDetalle[pvd].numeroOrdenCompraCliente,
                    fechaValidez:presuVent.presupuestoVentaDetalle[pvd].fechaValidez,

                    precio: presuVent.presupuestoVentaDetalle[pvd].precio,
                    total: UtilFactory.roundForMoneda(presuVent.presupuestoVentaDetalle[pvd].precio * presuVent.presupuestoVentaDetalle[pvd].cantidadSobrante, vm.pedidoVenta.moneda),

                    id: presuVent.presupuestoVentaDetalle[pvd].id,
                    cantidad: (presuVent.presupuestoVentaDetalle[pvd].producto.criterioControl.codigo ==
                        "series") || presuVent.presupuestoVentaDetalle[pvd].producto.criterioControl.codigo == "lotes" ? presuVent.presupuestoVentaDetalle[pvd].cantidad : undefined,
                    cantidadPedida: (presuVent.presupuestoVentaDetalle[pvd].producto.criterioControl.codigo ==
                        "series") || presuVent.presupuestoVentaDetalle[pvd].producto.criterioControl.codigo == "lotes" ? presuVent.presupuestoVentaDetalle[pvd].cantidad : undefined,
                    porcentajeDescuento: vm.pedidoVenta.porcentajeDescuento != 0 ? vm.pedidoVenta.porcentajeDescuento : presuVent.presupuestoVentaDetalle[pvd].porcentajeDescuento,
                    incluir: true
                });
                calcularTotalDetalle(vm.pedidoVenta.pedidoVentaDetalle[cont++]);
            }
        }
        filterFormasPago();
    }


    function recuperarProducto(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)

        vm.productos = ProductosFactory.allForSelect(finalFilter, 'PedidoVentaForm')
    }

    function changeGrupoProducto(detalle) {
        vm.grupoRepetido = false;
        var cantidadGrupo = 0;
        _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (elem) {
            if (elem.grupoProducto != undefined && detalle.grupoProducto.id == elem.grupoProducto.id) {
                cantidadGrupo++;
            }
        });
        if (cantidadGrupo > 1) {
            vm.grupoRepetido = true;
            var msg = vm.translations.NUMERO_GRUPO_REPETIDO; //vm.translations.DETAILS_ERROR_OP_MANUAL_STOCK;
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
        }
    }

    function calcularTotalDetalle(detalle,index) {

        if(vm.pedidoVenta.almacen && !(vm.pedidoVenta.almacen.modificarPrecioEspecial && vm.permisoModificarPrecioAlmacenSelec) && vm.verPrecio=='Sí'){
            controlPrecioUltimoCosto(detalle,index);
        }
        if(detalle.porcentajeDescuento && detalle.porcentajeDescuento > 0){
          controlLimiteDescuento(detalle,index);
        }

        if (detalle.cantidad == undefined) {
            detalle.cantidad = 0;
            detalle.total = 0;
        }
        if (detalle.precio == undefined) {
            detalle.precio = 0;
            detalle.total = 0;
        }


        if (detalle.cantidad != undefined && detalle.precio != undefined) {
            detalle.total = detalle.cantidad * detalle.precio;
            if (vm.pedidoVenta.moneda != undefined) {
                detalle.total = UtilFactory.roundForMoneda(detalle.total, vm.pedidoVenta.moneda);
            }

            calcularImpuestosAndTotalGeneral(detalle);
        }

        if (detalle.porcentajeDescuento == undefined) {
            detalle.porcentajeDescuento = 0
        }
        var totalNeto = 0;
        if (detalle.porcentajeDescuento > 0) {
            totalNeto = detalle.precio - (detalle.precio * (detalle.porcentajeDescuento / 100));
        } else {
            totalNeto = detalle.precio;
        }

        detalle.total = detalle.cantidad * totalNeto;
        if (vm.pedidoVenta.moneda != undefined) {
            detalle.total = UtilFactory.roundForMoneda(detalle.total, vm.pedidoVenta.moneda);
        }

        calcularImpuestosAndTotalGeneral(detalle);

        if (detalle.unidadMedida.esBase) {
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidad).toFixed(4);
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidadUmPrincipal);
        } else {
            //hacer calculo en um base
            var contenida = detalle.unidadMedida.cantidad;
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidad * contenida).toFixed(4);
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidadUmPrincipal);
        }
    }

    function totalItemChanged(detalle,index) {

        if (detalle.cantidad == undefined) {
            detalle.cantidad = 1;
        }
        detalle.precio = UtilFactory.roundForMoneda(detalle.total / detalle.cantidad, vm.pedidoVenta.moneda);
        controlPrecioUltimoCosto(detalle,index);
        calcularImpuestosAndTotalGeneral(detalle);
    }

    function calcularImpuestosAndTotalGeneral(detalle) {
        vm.totalGeneral = 0;
        _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (elem1) {
            if (elem1.incluir) {
                vm.totalGeneral = vm.totalGeneral + elem1.total;
            } else {
                elem1.total = 0;
            }
        });

        detalle.baseImponible = (100 * detalle.total) / (100 + detalle.producto.tiposImpuesto[0].porcentaje);
        detalle.baseImponible = UtilFactory.roundForMoneda(detalle.baseImponible, vm.pedidoVenta.moneda);
        detalle.valorImpuesto = detalle.total - detalle.baseImponible;
        detalle.valorImpuesto = UtilFactory.roundForMoneda(detalle.valorImpuesto, vm.pedidoVenta.moneda);
        vm.totalGeneral = UtilFactory.roundForMoneda(vm.totalGeneral, vm.pedidoVenta.moneda);
    }

    function getMonedaLocal() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'moneda_local'
        }).value();
        vm.monedaLocal = ParametrosFactory.all(params);
        vm.monedaLocal.$promise.then(function (data) {
            if (data && data.length > 0) {
                if (vm.pedidoVenta != undefined && vm.pedidoVenta.moneda == undefined) {
                    vm.pedidoVenta.moneda = data[0].valorTipo;
                }
                vm.monedaDefault = data[0].valorTipo;
            }
        });
    }

    function changeCondicion() {
        vm.pedidoVenta.cantidadCuotas = null;
        changeTipoVenta();
    }

    function changeCotizacion() {
        vm.monedaLocal.$promise.then(function (data) {
            if (vm.pedidoVenta.moneda && vm.pedidoVenta.moneda.descripcion != data[0].valorTipo.descripcion) {
                vm.cotizacionRequerida = true;
                var params = {};
                params.search = filterFactory.and([{
                    path: 'monedaDesde.id',
                    equals: vm.pedidoVenta.moneda.id
                },
                {
                    path: 'monedaHasta.id',
                    equals: vm.monedaLocal[0].valorTipo.id
                },
                {
                    path: 'fecha',
                    like: moment(vm.pedidoVenta.fecha).format('DD/MM/YYYY')
                    //equals:vm.comprobante.fecha
                }
                ]).value();
                params.view = 'CotizacionMonedaList';
                CotizacionMonedaFactory.all(params).$promise.then(function (response) {

                    if (response && response[0])
                        vm.pedidoVenta.cambio = response[0].valorCompra;
                });
            } else {

                vm.cotizacionRequerida = false;
                vm.pedidoVenta.cambio = 1;
            }
            /* Solo cuando se selecciona la moneda se revisa si el cliente está
            asociado a una lista de precios de ventas. */
            if (vm.pedidoVenta.moneda && !vm.pedidoVenta.presupuestoVenta) {

                //Se bloquea el campo
                //vm.asignarListaManual = false;

                var staticFilter = {};
                staticFilter.search = filterFactory.and([{
                    path: 'cliente.id',
                    equals: vm.pedidoVenta.cliente.id
                }, {
                    path: 'estado.codigo',
                    equals: 'lista_activada'
                }]).value();
                ListaPrecioVentaFactory.all(staticFilter, "PedidoVentaForm").$promise.then(function (response) {

                    /* Se controla si el cliente está asociado a una lista de precios por asociación directa
                    con la moneda seleccionada */
                    if (response.length > 0 && response[0].moneda.codigo == vm.pedidoVenta.moneda.codigo) {

                        vm.pedidoVenta.listaPrecioVenta = response[0];
                        vm.pedidoVenta.monedaLista = vm.pedidoVenta.listaPrecioVenta.moneda;
                        vm.getListasPreciosVentasByMoneda();
                        vm.actualizarPreciosDeVentas();
                        if (vm.utilizarPrecioBase) {

                            vm.porcRecargo = vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo;
                            vm.precioBase = vm.pedidoVenta.listaPrecioVenta.precioBase.descripcion;
                        }
                    } else if (response.length === 0 && !vm.pedidoVenta.segmentoCliente) {

                        /* Se controla si el cliente está asociado a una lista de precios por su tipo de cliente
                        con la moneda seleccionada */
                        var staticFilter = {};
                        staticFilter.search = filterFactory.and([{
                            path: 'tipoCliente.id',
                            equals: vm.pedidoVenta.cliente.tipoCliente.id
                        }, {
                            path: 'estado.codigo',
                            equals: 'lista_activada'
                        }, {
                            path: 'moneda.codigo',
                            equals: vm.pedidoVenta.moneda.codigo
                        }]).value();
                        ListaPrecioVentaFactory.all(staticFilter, "PedidoVentaForm").$promise.then(function (response) {

                            if (response.length > 0) {

                                vm.pedidoVenta.listaPrecioVenta = response[0];
                                vm.pedidoVenta.monedaLista = vm.pedidoVenta.listaPrecioVenta.moneda;
                                vm.actualizarPreciosDeVentas();
                                vm.getListasPreciosVentasByMoneda();
                                if (vm.utilizarPrecioBase) {

                                    vm.porcRecargo = vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo;
                                    vm.precioBase = vm.pedidoVenta.listaPrecioVenta.precioBase.descripcion;
                                }
                            } else {

                                vm.pedidoVenta.listaPrecioVenta = undefined;
                                vm.pedidoVenta.monedaLista = undefined;
                                vm.pedidoVenta.cambioPactado = undefined;
                                vm.porcRecargo = 0;
                                vm.precioBase = 'Precio de Venta'
                                vm.actualizarPreciosDeVentas();
                                vm.getListasPreciosVentasByMoneda();
                            }
                        });
                    } else {

                        /* Si el cliente está asociado a una Lista de Precios de Ventas, pero no con la moneda
                        seleccionada. */
                        vm.pedidoVenta.listaPrecioVenta = undefined;
                        vm.pedidoVenta.monedaLista = undefined;
                        vm.pedidoVenta.cambioPactado = undefined;
                        vm.porcRecargo = 0;
                        vm.precioBase = 'Precio de Venta'
                        vm.getListasPreciosVentasByMoneda();
                        vm.actualizarPreciosDeVentas();
                    }
                });
            }

            //si tiene presupuesto
            if (vm.pedidoVenta.presupuestoVenta) {

                //si el pedido es en moneda extranjera y el presupuesto en gs
                if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo == "guaranies") {

                    vm.mostrarCambioPactado = false;
                    //cambiar el precio de los detalles que vengan en el presupuesto
                    vm.monedaLocal.$promise.then(function (data) {

                        if (vm.pedidoVenta.moneda && vm.pedidoVenta.moneda.descripcion != data[0].valorTipo.descripcion) {

                            vm.cotizacionRequerida = true;
                            var params = {};
                            params.search = filterFactory.and([{
                                path: 'monedaDesde.id',
                                equals: vm.pedidoVenta.moneda.id
                            },
                            {
                                path: 'monedaHasta.id',
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: 'fecha',
                                like: moment(vm.pedidoVenta.fecha).format('DD/MM/YYYY')
                                //equals:vm.comprobante.fecha
                            }
                            ]).value();
                            params.view = 'CotizacionMonedaList';
                            CotizacionMonedaFactory.all(params).$promise.then(function (response) {

                                if (response && response[0]) {

                                    vm.pedidoVenta.cambio = response[0].valorCompra;
                                    _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (elem) {

                                        var precioFinal = elem.precio / vm.pedidoVenta.cambio;
                                        elem.precio = Math.round(precioFinal * 100) / 100;
                                        var total = elem.total / vm.pedidoVenta.cambio;
                                        elem.total = Math.round(total * 100) / 100;
                                    });
                                }
                            });
                        } else {

                            vm.cotizacionRequerida = false;
                            vm.pedidoVenta.cambio = 1;
                        }
                    });
                } else if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo != "guaranies") {
                    //si el pedido es guaranies y el presupuesto en moneda extranjera
                    vm.mostrarCambioPactado = true;
                    vm.monedaLocal.$promise.then(function (data) {
                        if (vm.pedidoVenta.presupuestoVenta.moneda && vm.pedidoVenta.presupuestoVenta.moneda.descripcion != data[0].valorTipo.descripcion) {
                            vm.cotizacionRequerida = true;
                            var params = {};
                            params.search = filterFactory.and([{
                                path: 'monedaDesde.id',
                                equals: vm.pedidoVenta.presupuestoVenta.moneda.id
                            },
                            {
                                path: 'monedaHasta.id',
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: 'fecha',
                                like: moment(vm.pedidoVenta.fecha).format('DD/MM/YYYY')
                                //equals:vm.comprobante.fecha
                            }
                            ]).value();
                            params.view = 'CotizacionMonedaList';
                            CotizacionMonedaFactory.all(params).$promise.then(function (response) {

                                if (response && response[0]) {
                                    vm.pedidoVenta.cambioPactado = response[0].valorCompra;
                                    _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (elem) {
                                        elem.precio = UtilFactory.roundForMoneda(elem.precio * vm.pedidoVenta.cambioPactado, vm.pedidoVenta.moneda);
                                        elem.total = UtilFactory.roundForMoneda(elem.total * vm.pedidoVenta.cambioPactado, vm.pedidoVenta.moneda);
                                    });
                                }
                            });
                        } else {

                            vm.cotizacionRequerida = false;
                            vm.pedidoVenta.cambio = 1;
                        }
                    });

                } else {

                    //si el pedido es gs y el presupuesto gs o si el pedido es extranjera y el presupuesto es extranjero
                    vm.mostrarCambioPactado = false;
                    vm.pedidoVenta.cambioPactado = undefined;
                    if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo != "guaranies") {

                        vm.pedidoVenta.cambio = vm.pedidoVenta.presupuestoVenta.tipoCambio;
                    }
                    _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (elem) {

                        _.forEach(vm.pedidoVenta.presupuestoVenta.presupuestoVentaDetalle, function (elem1) {

                            if (elem.producto.id == elem1.producto.id) {

                                if (elem.unidadMedida.id == elem1.unidadMedida.id) {

                                    elem.precio = elem1.precio;
                                    elem.total = elem1.total;
                                }
                            } else {

                                vm.monedaLocal.$promise.then(function (data) {

                                    if (vm.pedidoVenta.moneda && vm.pedidoVenta.moneda.descripcion != data[0].valorTipo.descripcion) {

                                        vm.cotizacionRequerida = true;
                                        var params = {};
                                        params.search = filterFactory.and([{
                                            path: 'monedaDesde.id',
                                            equals: vm.pedidoVenta.moneda.id
                                        },
                                        {
                                            path: 'monedaHasta.id',
                                            equals: vm.monedaLocal[0].valorTipo.id
                                        },
                                        {
                                            path: 'fecha',
                                            like: moment(vm.pedidoVenta.fecha).format('DD/MM/YYYY')
                                            //equals:vm.comprobante.fecha
                                        }
                                        ]).value();
                                        params.view = 'CotizacionMonedaList';
                                        CotizacionMonedaFactory.all(params).$promise.then(function (response) {

                                            if (response && response[0]) {

                                                vm.pedidoVenta.cambio = response[0].valorCompra;
                                                elem.precio = Math.round((elem.precio / vm.pedidoVenta.cambio) * 100) / 100;
                                                elem.total = Math.round((elem.total / vm.pedidoVenta.cambio) * 100) / 100;
                                            }
                                        });
                                    } else {

                                        vm.cotizacionRequerida = false;
                                        vm.pedidoVenta.cambio = 1;
                                        changeUnidad(elem);
                                    }
                                });

                            }
                        });
                    });
                }
            }
        });
    }

    function activate() {
        WatchLocacionChangeFactory.killAllWatchers();
        vm.porcRecargo = 0;
        vm.precioBase = 'Precio de Venta';

        var filtroPresupuesto = {};
        filtroPresupuesto.search = filterFactory.or([
            {
                path: 'estado.codigo',
                like: 'prv_aprobado'
            },
            {
                path: 'estado.codigo',
                like: 'prv_procesado_parcial'
            }
        ]).value();
        //Recupera parametros para manejar los registros de ultimoCosto
        ParametrosFactory.getByCodigo('tipo_administracion_costo').then(function (response) {
          vm.parametroAdministracionCosto = response.valorTipo.codigo;
        });
        ParametrosFactory.getByCodigo('administrar_costo').then(function (response) {
          vm.parametroAdministrarCostoPor = response.valorTipo.codigo;
        });

        vm.presupuestosVenta = PresupuestoVentaFactory.all(filtroPresupuesto);

        vm.permisoEditarPrecioVenta = AuthorizationService.hasPermission("update_precio_venta_comp");
        vm.permisoEditarPrecioFromLista = AuthorizationService.hasPermission("modificar_precio_min_max_lp_pv");
        vm.permisoAsignarListaPrecioVenta = AuthorizationService.hasPermission("asignar_listaprecioventa");
        vm.noPermisoEditarVendedor = AuthorizationService.hasPermission("modificar_vendedor_pedidoventas");
        vm.noPermisoDescuento = AuthorizationService.hasPermission("asignar_descuento_pedidoventas");
        vm.noPermisoCambioCondicion = AuthorizationService.hasPermission("modificar_condicion_pedidoventas");
        vm.permisoModificarPrecioAlmacenSelec = AuthorizationService.hasPermission("modificar_precio_almac_selec_pv");
        vm.permisoCambioTipoPrecio = AuthorizationService.hasPermission("cambiar_tipoprecio");
        vm.proyectos = ProyectoFactory.all("", "PedidoVentaForm");

        vm.permisoModificarCentroCostos = AuthorizationService.hasPermission("modificar_centro_costo_pedidoventa");

        if(!vm.permisoModificarCentroCostos){
            vm.bloquearCentroCostos = true;
        }

        ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
          vm.proyectoObligatorio = r.valorTipo.codigo == "si";
        });
        //vm.asignarListaManual=false;
        var filtroServicios = {};
        filtroServicios.search = filterFactory.and([{
            path: 'clase.codigo',
            equals: 'forma_pago_referencia'
        }]).value();
        vm.promiseFormaPago = [];
        vm.promiseFormaPago.push(FormaPagoFactory.all(filtroServicios).$promise.then(function (data){
          vm.formasPagos = data;
          vm.formasPagosAux = angular.copy(data);
        }));

        getMonedaLocal();
        vm.monedas = {};
        TiposFactory.monedas().then(function (data) {
            vm.monedas = data.data;
        });
        TiposFactory.condicionCobroPedidoVenta().then(function (data) {
            vm.condiciones = data.data;
        });
        TiposFactory.origenesPV().then(function (data) {
            vm.origenes = data.data;
        });
        TiposFactory.tiposprecio().then(function (data) {
            vm.tiposprecio = data.data;
        });
        var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();
        filtroActivo.view = "BaseList";
        var filtroVendedor = {};
        filtroVendedor.search = filterFactory.and([
            {
                path: 'activo',
                like: 'true'
            },
            {
                path: 'vendedor',
                like: 'true'
            }

        ]).value();
        filtroVendedor.view = "VendedorList";
        vm.vendedores = [];
        VendedorFactory.all(filtroVendedor).$promise.then(function (response) {
            _.forEach(response, function (detalle) {
                for (var i = 0; i < detalle.locaciones.length; i++) {
                    if ($rootScope.AuthParams.locacionSeleccionada != undefined &&  detalle.locaciones[i].locacion.id == $rootScope.AuthParams.locacionSeleccionada.id) {
                        vm.vendedores.push(detalle);
                        break;
                    }
                }
            })
        });

        //*** centro de costos */
        var paramsCentroCostos = {};
        paramsCentroCostos.search = filterFactory
            .and({
                path: "activo",
                equals: true
            }, {
                path: "descripcion",
                sortAsc: true
            }).value();

        paramsCentroCostos.view = "BaseList"

        vm.centrocostosList = CentroCostosFactory.all(paramsCentroCostos);
        if (vm.watcherAlmacen) {
            vm.watcherAlmacen();
        } //hacemos unwatch de la expresion anterior
        WatchLocacionChangeFactory.executeWatching(function (
            locacionFilter,
            watcherAlmacen
        ) {
            vm.watcherAlmacen = watcherAlmacen;
            var filterSucursal = {
                search: filterFactory
                    .and([
                        {
                            path: "sucursal.id",
                            equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                        },
                        {
                            path: "activo",
                            equals: true
                        },
                        {
                            path: "habilitadoVenta",
                            equals: true
                        }
                    ])
                    .value()
            };
            vm.depositos = AlmacenFactory.all(filterSucursal);
        }, "locacion.id");
        VentasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            vm.curDate = new Date();
            var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
                'lineaProducto.nombre', 'marca.descripcion'
            ];
            // TODO agregar locacionFilter
            var staticFilterProductos = {};
            if ($rootScope.AuthParams.locacionSeleccionada != undefined) {
                staticFilterProductos.search = filterFactory.and([
                    {
                        path: 'itemVenta',
                        like: 'true'
                    },
                    {
                        path: 'estado.codigo',
                        equals: 'activo'
                    },
                    {
                        path: "locaciones.id",
                        equals: $rootScope.AuthParams.locacionSeleccionada.id
                    }
                ]).value();
            } else {
                staticFilterProductos.search = filterFactory.and([
                    {
                        path: 'itemVenta',
                        like: 'true'
                    },
                    {
                        path: 'estado.codigo',
                        equals: 'activo'
                    }
                ]).value();
            }
            vm.opcionesProductosDt = {
                'resource': 'productos',
                'title': 'ProductosPedidoVenta',
                'view': 'ProductoList',
                'staticFilter': staticFilterProductos,
                'factory': ProductosFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'codigo', 'title': 'Código', visible: false },
                    { 'data': 'nombre', 'title': translations.NAME },
                    { 'data': 'claseProducto.descripcion', 'title': 'Clase' },
                    { 'data': 'estado.descripcion', 'title': translations.STATUS, 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
                    { 'data': 'lineaProducto.nombre', 'title': 'Línea' },
                    { 'data': 'marca.descripcion', 'title': 'Marca', 'renderWith': 'emptyRender' },
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc"
            };
            WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
                vm.watcher = watcher;
            }, "locaciones.id");
            if (vm.isMobile) {
              $scope.isPopup = false;
              vm.opcionesProductosDt.defaultColumnOrder = ['codigo', 'nombre', 'marca.descripcion'];
              vm.opcionesProductosDt.columns = [
                { 'data': 'codigo', 'title': 'Código'},
                { 'data': 'nombre', 'title': translations.NAME },
                { 'data': 'claseProducto.descripcion', 'title': 'Clase'},
                { 'data': 'estado.descripcion', 'title': translations.STATUS, 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados'},
                { 'data': 'lineaProducto.nombre', 'title': 'Línea'},
                { 'data': 'marca.descripcion', 'title': 'Marca', 'renderWith': 'emptyRender' },
                { 'data': 'codigoBarrasPrincipal.codigoBarras', 'title': 'Código Barras'}
              ];
              vm.opcionesProductosDt.hideViewMenu = true;
              vm.opcionesProductosDt.extraRowOptions = [
                {
                  templateToRender: "<button class='btn btn-info' title='" + $filter('translate')('PICK') + "' style='margin-right: 5px;' ng-click='ver(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                  functionName: "ver",
                  functionDef: function (itemId) {
                    if(!$rootScope.isPopup){
                      verImagenProducto(itemId);
                    }
                  }
                }, {
                  templateToRender: "<button class='btn btn-primary' title='" + $filter('translate')('PICK') + "' style='margin-right: 5px;' ng-click='pick(<%=dataId%>)'> <span class='glyphicon glyphicon-ok'></span> </button>",
                  functionName: "pick",
                  functionDef: function (itemId) {
                    if(!$rootScope.isPopup){
                      $rootScope.pick(itemId);
                    }
                  }
                }];
            };
            if ($state.is("app.pedidoventas.new")) {
                activateNew();
            } else if ($state.is("app.pedidoventas.edit")) {
                activateEdit();
            } else if ($state.is("app.pedidoventas.view")) {
                activateView();
            } else {
                activateView();
            }
        });

        vm.submited = false;
    }

    /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
    vm.staticFilterClientes = [{ path: "activo", equals: true }];
    vm.clientesFactory = ClienteFactory;

    function activateNew() {
        if (vm.isMobile){
          popupBusquedaCliente()
        }
        vm.isNew = true;
        vm.title = vm.translations.NEW_PEDIDO_VENTA;
        vm.pedidoVenta = {
            pedidoVentaDetalle: [{}],
        };
        vm.pedidoVenta.hora = moment(vm.curDate).format("HH:mm:ss");
        var hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        vm.pedidoVenta.fecha = hoy;
        vm.pedidoVenta.porcentajeDescuento = 0;
        vm.pedidoVenta.fechaEntrega = hoy;
        ParametrosFactory.getByCodigo('cantidad_dias_valido_pv').then(function (parametro) {
            vm.cantidadDiasValido = parseInt(parametro.valor);
            var validoHasta = new Date();
            validoHasta.setDate(validoHasta.getDate() + vm.cantidadDiasValido);
            vm.pedidoVenta.validoHasta = validoHasta;
        })
        vm.pedidoVenta.estado = { descripcion: "Ingresado" };
        vm.mostrarCambioPactado = false;
        
        TiposFactory.origenesPV().then(function (data) {
            _.forEach(data.data, function(pp){
                if(pp.codigo == 'carga_directa_origen_pv'){
                    vm.pedidoVenta.origen = pp;
                };
              });
        });

        TiposFactory.tiposprecio().then(function (data) {
            vm.pedidoVenta.tipoPrecio = data.data[0];
        });
        var filterSucursal = {
            search: filterFactory
                .and([
                    {
                        path: "sucursal.id",
                        equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                    },
                    {
                        path: "activo",
                        equals: true
                    },
                    {
                        path: "habilitadoVenta",
                        equals: true
                    }
                ])
                .value()
        };
        vm.depositos = AlmacenFactory.all(filterSucursal);
        vm.depositos.$promise.then(function (data) {
            if (data && data.length > 0) {
                if (vm.pedidoVenta.almacen == undefined) {
                    vm.pedidoVenta.almacen = data[0];
                }
            }
        });
        getMonedaLocal();
    }

    function activateView() {
        vm.title = vm.translations.VIEW_PEDIDO_VENTA;
        vm.pedidoVenta = pedidoVentaPrepService;
        Promise.all(vm.promiseFormaPago)
          .then(function() {
            filterFormasPago();
          });
        checkPermisosFor("view");
        vm.pedidoVenta.fecha = new Date(vm.pedidoVenta.fecha);
        vm.pedidoVenta.fechaEntrega = new Date(vm.pedidoVenta.fechaEntrega);
        vm.pedidoVenta.validoHasta = new Date(vm.pedidoVenta.validoHasta);
        vm.entidadId = vm.pedidoVenta.id;
        vm.entidad = "PedidoVenta";
        vm.view = true;
        vm.totalGeneral = 0;
        if (vm.pedidoVenta.presupuestoVenta != null) {
            vm.tienePresupuesto = true;
        }
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'numeroProcesoOrigen',
            equals: vm.pedidoVenta.id + "",
        }]).value();
        SolicitudAutorizacionFactory.all(staticFilter).$promise.then(function (response) {

            if (response.length > 0) {
                vm.mostrarVolver = true;
            } else {
                vm.mostrarVolver = false;
            }
        });

        if (vm.utilizarPrecioBase) {
            vm.porcRecargo = vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo;
            vm.precioBase = vm.pedidoVenta.listaPrecioVenta.precioBase.descripcion;
        }
        vm.distribuidoLotes = false;
        _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (elem) {
            vm.totalGeneral = vm.totalGeneral + elem.total;
            elem.mostrarMas = false;
            if ((elem.producto.criterioControl.codigo === 'series' || elem.producto.criterioControl.codigo === 'lotes') && elem.grupoProducto != null) {
                vm.distribuidoLotes = true;
            }
            ProductosFactory.get(elem.producto.id, 'BaseList').$promise.then(function (producto) {
                //detalle.producto = producto;
                elem.producto = producto;
                calcularImpuestosAndTotalGeneral(elem);
                //unidadMedidaChanged(detalle, index);
                //pesoCalculadoChanged();
            });
        });

        if (vm.pedidoVenta.moneda.codigo == "guaranies" && (vm.pedidoVenta.listaPrecioVenta && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies" || vm.pedidoVenta.presupuestoVenta && vm.pedidoVenta.presupuestoVenta.moneda.codigo != "guaranies")) {
            //si el pedido es en gs y la lista o el presupuesto en moneda extranjera
            vm.mostrarCambioPactado = true;
        }
    }

    function activateEdit() {
        vm.isEdit = true;
        vm.title = vm.translations.EDIT_PEDIDO_VENTA;
        vm.pedidoVenta = pedidoVentaPrepService;
        Promise.all(vm.promiseFormaPago)
          .then(function() {
            filterFormasPago();
          });
        vm.disableNumOC = ["carga_cast_origen_pv", "carga_edi_origen_pv"].indexOf(vm.pedidoVenta.origen.codigo) !== -1;
        var suc = [];
        for(var i = 0; i < vm.pedidoVenta.cliente.sucursales.length; i++){
          if(vm.pedidoVenta.cliente.sucursales[i].activo === true){
            suc.push(vm.pedidoVenta.cliente.sucursales[i])
          }
        }
        vm.sucursalesCliente = suc;
        checkPermisosFor("edit", vm.pedidoVenta.estado);
        vm.pedidoVenta.fecha = new Date(vm.pedidoVenta.fecha);
        vm.pedidoVenta.fechaEntrega = new Date(vm.pedidoVenta.fechaEntrega);
        vm.pedidoVenta.validoHasta = new Date(vm.pedidoVenta.validoHasta);
        vm.entidadId = vm.pedidoVenta.id;
        vm.entidad = "PedidoVenta";
        vm.getListasPreciosVentasByMoneda();
        vm.totalGeneral = 0;
        if (vm.pedidoVenta.presupuestoVenta != null) {
            vm.tienePresupuesto = true;
        }
        if (vm.utilizarPrecioBase) {
            vm.porcRecargo = vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo;
            vm.precioBase = vm.pedidoVenta.listaPrecioVenta.precioBase.descripcion;
        }
        recuperarPreciosMinimosMaximos();
        vm.distribuidoLotes = false;
        _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (elem) {
            vm.totalGeneral = vm.totalGeneral + elem.total;
            elem.mostrarMas = false;
            if (elem.cantidadPedida == 0) elem.cantidadPedida = null;
            if ((elem.producto.criterioControl.codigo === 'series' || elem.producto.criterioControl.codigo === 'lotes') && elem.grupoProducto != null) {
                vm.distribuidoLotes = true;
            }
            ProductosFactory.get(elem.producto.id, 'PedidoVentaForm').$promise.then(function (producto) {
                //detalle.producto = producto;
                elem.producto = producto;
                calcularImpuestosAndTotalGeneral(elem);
                //unidadMedidaChanged(detalle, index);
                //pesoCalculadoChanged();
            });
        });

        if (vm.pedidoVenta.moneda.codigo == "guaranies" && ((vm.pedidoVenta.listaPrecioVenta != null && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") ||
            (vm.pedidoVenta.presupuestoVenta != null && vm.pedidoVenta.presupuestoVenta.moneda.codigo != "guaranies"))) {
            //si el pedido es en gs y la lista o el presupuesto en moneda extranjera
            vm.mostrarCambioPactado = true;
        }

    }

    function changeSucCliente() {
      if (vm.pedidoVenta.sucursalCliente) {
          vm.pedidoVenta.vendedor = vm.pedidoVenta.sucursalCliente.vendedor;
          vm.pedidoVenta.centroCostos = vm.pedidoVenta.sucursalCliente.centroCostos;
      }
   }

    function changeCliente(isNew) {
        var promises = [];
        if (vm.pedidoVenta.moneda) {
            promises.push(vm.changeMoneda());
        }
        if (vm.pedidoVenta.cliente) {
            var staticFilter = {};
            staticFilter.search = filterFactory
                .and([{
                    path: "cliente.id",
                    equals: vm.pedidoVenta.cliente.id
                }])
                .value();
        }
        var suc = [];
        for(var i = 0; i < vm.pedidoVenta.cliente.sucursales.length; i++){
          if(vm.pedidoVenta.cliente.sucursales[i].activo === true){
            suc.push(vm.pedidoVenta.cliente.sucursales[i])
          }
        }
        vm.sucursalesCliente = suc;
        if (vm.sucursalesCliente.length >= 1) {
            vm.pedidoVenta.sucursalCliente = vm.sucursalesCliente[0];
            vm.pedidoVenta.vendedor = vm.pedidoVenta.sucursalCliente.vendedor;
        } else {
            vm.pedidoVenta.sucursalCliente = null;
        }

        vm.pedidoVenta.condicionCobro = vm.pedidoVenta.cliente.tipoVenta;
        vm.pedidoVenta.formaPago = vm.pedidoVenta.cliente.formaPago;
        if (vm.pedidoVenta.cliente.formaPago != undefined) {
            vm.pedidoVenta.cantidadCuotas = vm.pedidoVenta.cliente.formaPago.cantidadCuotas;
        } else {
            vm.pedidoVenta.cantidadCuotas = undefined;
        }

        vm.pedidoVenta.segmentoCliente = vm.pedidoVenta.cliente.segmentoCliente;

        if (vm.pedidoVenta.cliente.descuento != undefined && vm.pedidoVenta.cliente.descuento > 0) {
            vm.pedidoVenta.porcentajeDescuento = vm.pedidoVenta.cliente.descuento;
        }else{
            vm.pedidoVenta.porcentajeDescuento = 0;
        }


        Promise.all(promises)
        .then(function() {
            filterFormasPago();
             // Este bloque se ejecutará cuando todas las promesas se resuelvan
            if (vm.pedidoVenta.pedidoVentaDetalle.length > 0) {

                for (var i = 0; i < vm.pedidoVenta.pedidoVentaDetalle.length; i++) {
                    vm.pedidoVenta.pedidoVentaDetalle[i].porcentajeDescuento = vm.pedidoVenta.porcentajeDescuento;
                    calcularTotalDetalle(vm.pedidoVenta.pedidoVentaDetalle[i],i)
                    //  changeProducto(vm.pedidoVenta.pedidoVentaDetalle[i],i);
                }

            }
        });

        // Siempre que la sucursal del cliente tenga un Centro de Costos, este sera utilizado como el CC del comprobante
        if(vm.pedidoVenta.sucursalCliente && vm.pedidoVenta.sucursalCliente.centroCostos){
            vm.pedidoVenta.centroCostos = vm.pedidoVenta.sucursalCliente.centroCostos;
        }else if(vm.pedidoVenta.sucursalCliente && !vm.pedidoVenta.sucursalCliente.centroCostos){
            vm.pedidoVenta.centroCostos = null;
        }
    }

    vm.searchProductoFilter = function (criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria) === 0);
        };
    }

    function agregarDetalle() {
        vm.pedidoVenta.pedidoVentaDetalle.push({});
        var rowNumber = vm.pedidoVenta.pedidoVentaDetalle.length - 1;
        $timeout(function () {
            $scope.$broadcast('newItemAdded');
        }, 20);

      if(vm.isMobile){
        $timeout(function(){
          window.scrollTo(0, document.body.scrollHeight);
        }, 200)
        $scope.indiceActual = rowNumber;
        $scope.detalleActual = vm.pedidoVenta.pedidoVentaDetalle[$scope.indiceActual];
      }
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        if (vm.pedidoVenta.presupuestoVenta != null) {
            PedidoVentaFactory.revertirCantidad(elemento.id);
        }
        $timeout(function () {
            $scope.$broadcast('newItemRemoved');
        }, 20);
        calcularTotalDetalle(elemento);
    }

    function removeItemFromArrayIndex(elemento, arreglo,index) {
        for(var i = index; i < vm.precioMinimoMaximos.length - 1; i++){
          vm.precioMinimoMaximos[i] = vm.precioMinimoMaximos[i+1];
        }
        vm.precioMinimoMaximos.pop();
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        if (vm.pedidoVenta.presupuestoVenta != null) {
            PedidoVentaFactory.revertirCantidad(elemento.id);
        }
        $timeout(function () {
            $scope.$broadcast('newItemRemoved');
        }, 20);
        calcularTotalDetalle(elemento);
    }
    function popupBusquedaCliente() {
      $scope.tituloModal = 'Búsqueda del Cliente';
      var modalInstance = $modal.open({
        templateUrl: 'qualita-components/ventas/views/pedidoventa/m-datos-cliente-modal.html',
        scope: $scope,
        size: "lg",
        backdrop: 'static'
      });
      modalInstance.rendered.then(function(){
        $timeout(function () {
          $scope.$broadcast("mobile_cliente");
          if($scope.indiceActual === undefined){
            $scope.indiceActual = 0;
            $scope.detalleActual = vm.pedidoVenta.pedidoVentaDetalle[$scope.indiceActual];
          }
        }, 1000)
      })

      $scope.cancel = function () {
        modalInstance.dismiss('cancel');
        vm.tienePresupuesto = false;
        vm.pedidoVenta.cliente = undefined;
        vm.pedidoVenta.sucursalCliente = undefined;
        vm.pedidoVenta.moneda = undefined;
        vm.pedidoVenta.cambio = undefined;
        vm.pedidoVenta.condicionCobro = undefined;
        vm.pedidoVenta.cantidadCuotas = undefined;
        vm.pedidoVenta.vendedor = undefined;
        vm.pedidoVenta.formaPago = undefined;
        vm.pedidoVenta.comentario = "";
        vm.sucursalesCliente = undefined;
        vm.pedidoVenta.monedaLista = undefined;
        vm.pedidoVenta.listaPrecioVenta = undefined;
        vm.pedidoVenta.pedidoVentaDetalle = [{}];
        vm.pedidoVenta.presupuestoVenta = undefined;
        vm.pedidoVenta.centroCostos = undefined;
        vm.pedidoVenta.moneda = vm.monedaLocal[0].valorTipo;
        vm.totalGeneral = 0;
      };

      $scope.ok = function () {
        if(vm.pedidoVenta.presupuestoVenta){
          modalInstance.dismiss('cancel');
        } else {
          $scope.agregarProducto()
        }
      }

      $scope.agregarProducto = function () {
        modalInstance.dismiss('cancel');
        if(vm.pedidoVenta.presupuestoVenta) {
          agregarDetalle();
        }
        $timeout(function () {
          $("#det_" + $scope.indiceActual + ">button").click()
        })
        $timeout(function () {
          $('#filtro_1').focus()
        }, 1000)
      }

    }
    function verImagenProducto(productoId) {
      $rootScope.isPopup = true;
      $scope.tituloModal = 'Imagen del Producto';
      ProductosFactory.get(productoId, 'ProductoForm').$promise.then(function (producto) {
        $scope.producto = producto;

        var imagePath = "images/placeholder.png";
        if ($scope.producto.imagen && $scope.producto.imagen !== false) {
          imagePath = baseurl.getPublicBaseUrl() + $scope.producto.imagen
        }
        $scope.uploadOptions1 = {
          imageOnly: true,
          title: 'Foto',
          imagePath: imagePath
        };
        var modalInstance = $modal.open({
          templateUrl: 'qualita-components/ventas/views/pedidoventa/m-imagen-producto-modal.html',
          scope: $scope
        });
        modalInstance.rendered.then(function(){
          $("#file .btn.btn-primary").hide()
          $("#file p").hide()
          $rootScope.isPopup = false;
        })
        $scope.ok = function () {
          modalInstance.dismiss('cancel');
        }
      });
    }
    function popupDetalleProducto() {
      $scope.tituloModal = 'Detalles del producto';
      var modalInstance = $modal.open({
        templateUrl: 'qualita-components/ventas/views/pedidoventa/m-detalle-producto-modal.html',
        scope: $scope,
        size: "lg",
        backdrop: 'static'
      });
      modalInstance.rendered.then(function(){
        $timeout(function () {
          $('#detalleProducto #cantidadPedida_' +$scope.indiceActual).focus().keypress(function (event) {
            if(event.which===13){
              if(!$scope.detalleActual.precio){
                event.preventDefault();
                var fields=$(this).parents('form:eq(0),body').find('input, textarea, select');
                var index=fields.index(event.target);
                if(index> -1&&(index+1)<fields.length)
                  fields.eq(index+1).focus();
              }
            }
          });
        }, 1000)
      });
      $scope.cancel = function () {
        modalInstance.dismiss('cancel');
        vm.removeItemFromArrayIndex($scope.detalleActual, vm.pedidoVenta.pedidoVentaDetalle,$scope.indiceActual)
      };

      $scope.agregarProducto = function () {
        modalInstance.dismiss('cancel');
        agregarDetalle();
        $timeout(function () {
          $("#det_" + $scope.indiceActual + ">button").click()
        })
        $timeout(function () {
          $('#filtro_1').focus()
        }, 1000)
      }

      $scope.ok = function () {
        modalInstance.dismiss('cancel');
      }
    }

    function changeProducto(detalle,index) {

       /*  // Verificar valor del parametro
        ParametrosFactory.getByCodigo('permitir_productos_fuera_segmento').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                // Verificar segmento del producto
                //console.log("AQUI producto segmento: ", detalle.producto);

                // Verificar segmento del cliente
                //console.log("AQUI cliente segmento: ", vm.pedidoVenta.segmentoCliente);
                
                var lineaCoincide = false;
                _.forEach(vm.pedidoVenta.segmentoCliente.segmentoDetalle, function (elem) {
                    //console.log(" ID Linea del Producto: ", detalle.producto.lineaProducto.id);
                    //console.log(" ID Linea del Cliente: ", elem.lineaProducto.id);
                    //console.log("\n");
                    if (detalle.producto.lineaProducto.id == elem.lineaProducto.id) {
                        lineaCoincide = true;
                        return;
                    }
                });

                if( !lineaCoincide ){            
                    var msg = $filter("translate")("ERROR_LINEAS_DIFERENTES", { producto: detalle.producto.nombre, segmento: vm.pedidoVenta.segmentoCliente.nombre});
                    notify({ message: msg, classes: "alert-warning", position: "right" });
                    detalle.producto = null;
                    detalle.unidadMedida = null;
                    detalle.cantidad = null;

                }
            }
        }); */

        detalle.grupoProducto = null;
        detalle.unidadMedidaBase = detalle.producto.unidadMedidaBase;
        detalle.unidadMedidaReferencial = null;
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        detalle.cantidad = null;
        detalle.cantidadView = null;
        detalle.incluir = true;
        detalle.listaPrecioVenta = null;
        // if (vm.pedidoVenta.porcentajeDescuento != undefined && vm.pedidoVenta.porcentajeDescuento > 0) {
        //     detalle.porcentajeDescuento = vm.pedidoVenta.porcentajeDescuento;
        // }

        detalle.porcentajeDescuento = vm.pedidoVenta.porcentajeDescuento;

        if (vm.pedidoVenta.listaPrecioVenta) {
          var staticFilter = {};
          staticFilter.search = filterFactory.and([{
            path: 'listaPrecioVenta.id',
            equals: vm.pedidoVenta.listaPrecioVenta.id,
          },
          {
            path: 'producto.id',
            equals: detalle.producto.id,
          }]).value();
          staticFilter.view = "PedidoVentaForm";
          ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
            if ((response == undefined || response.length == 0) && vm.permitirProductosNotListaPrecio == 'No') {
              var msg = $filter("translate")("PRODUCTO_NO_EXISTE_LISTA_PRECIO");
              notify({ message: msg, classes: "alert-warning", position: "right" })
              removeItemFromArray(detalle, vm.pedidoVenta.pedidoVentaDetalle);
              return;
            } else {
              //if (vm.verPrecio != 'No') {
                changeUnidad(detalle, index, response);
             // } else {
             //  detalle.precio = 0;
             //   detalle.totalGeneral = 0;
             // }
            }
            vm.isMobile ? popupDetalleProducto() : "";
          });
        } else {
          if (vm.verPrecio != 'No') {

            changeUnidad(detalle);
          } else {
            detalle.precio = 0;
            detalle.totalGeneral = 0;
          }
          vm.isMobile ? popupDetalleProducto() : "";
        }

        /*if (vm.verPrecio != 'No') {
            changeUnidad(detalle);
        } else {
            detalle.precio = 0;
            detalle.totalGeneral = 0;
        }*/
        if (detalle.producto.criterioControl.codigo === 'series') {
            detalle.cantidadPedida = 1;
            detalle.cantidad = 1;
        }

 	}

    function changeUnidad(detalle,index,productosFromListaPrecio) {
        if (detalle.cantidad) {
            if (detalle.unidadMedida.esBase) {
                detalle.cantidadUmPrincipal = parseFloat(detalle.cantidad).toFixed(4);
                detalle.cantidadUmPrincipal = parseFloat(detalle.cantidadUmPrincipal);
            } else {
                //hacer calculo en um base
                var contenida = detalle.unidadMedida.cantidad;
                detalle.cantidadUmPrincipal = parseFloat(detalle.cantidad * contenida).toFixed(4);
                detalle.cantidadUmPrincipal = parseFloat(detalle.cantidadUmPrincipal);
            }
        }

        //Si se utiliza el precio Base y el cliente está asociado a una lista de precios de ventas.
        if (vm.utilizarPrecioBase && vm.pedidoVenta.listaPrecioVenta && !vm.pedidoVenta.presupuestoVenta) {

            var precioBase = vm.pedidoVenta.listaPrecioVenta.precioBase.codigo;
            //ultimocostoIVA - tabla stk_producto_almacen
            if (precioBase == 'ultimo_costo') {

              if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                //si el pedido es en gs y la lista en moneda extranjera
                detalle.precio = parseFloat(vm.ultimoCostoIVA.toString().replace(/\./, ","));
              } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                //si el pedido es en moneda extranjera y la lista en Gs
                var precioFinal = vm.ultimoCostoIVA / vm.pedidoVenta.cambio;
                detalle.precio = Math.round(precioFinal * 100) / 100;
              } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                //si el pedido es en moneda extranjera y la lista en moneda extranjera
                var precioFinal = vm.ultimoCostoIVA / vm.pedidoVenta.cambio;
                detalle.precio = Math.round(precioFinal * 100) / 100;
              } else {
                detalle.precio = parseFloat(vm.ultimoCostoIVA.toString().replace(/\./, ","));
              }

              if (detalle.precio > 0 && vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo > 0) {
                vm.porcRecargo = vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo;
                var precioAux = detalle.precio * (vm.porcRecargo / 100) + detalle.precio;
                detalle.precio = UtilFactory.roundForMoneda(precioAux, vm.pedidoVenta.moneda);
              }
              calcularTotalDetalle(detalle, index);
            }
            else {
                //precio_actual - tabla vnt_cambio_precio_detalle
                CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {

                    if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                        //si el pedidoVenta es en gs y la lista en moneda extranjera
                        detalle.precio = parseFloat(precio.data.toString().replace(/\./, ","));
                    } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                        //si el pedidoVenta es en moneda extranjera y la lista en Gs
                        var precioFinal = precio.data / vm.pedidoVenta.cambio;
                        detalle.precio = Math.round(precioFinal * 100) / 100;
                    } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                        //si el pedidoVenta es en moneda extranjera y la lista en moneda extranjera
                        var precioFinal = precio.data / vm.pedidoVenta.cambio;
                        detalle.precio = Math.round(precioFinal * 100) / 100;
                    } else {
                        detalle.precio = parseFloat(precio.data.toString().replace(/\./, ","));
                    }

                    if (detalle.precio > 0 && vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo > 0) {
                        vm.porcRecargo = vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo;
                        var precioAux = detalle.precio * (vm.porcRecargo / 100) + detalle.precio;
                        detalle.precio = UtilFactory.roundForMoneda(precioAux, vm.pedidoVenta.moneda);
                    }
                    calcularTotalDetalle(detalle,index);
                });
            }
        } else if (!vm.utilizarPrecioBase && vm.pedidoVenta.listaPrecioVenta && !vm.pedidoVenta.presupuestoVenta) {
          if (productosFromListaPrecio) {
            var productoLista = [];
            _.forEach(productosFromListaPrecio, function (elem) {
                if (detalle.unidadMedida.id == elem.unidadMedida.id) {
                    productoLista = elem;
                }
            });
                if (productoLista) {

                    if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                        //si el pedidoVenta es en gs y la lista en moneda extranjera
                        detalle.precio = productoLista.precioVenta * vm.pedidoVenta.cambio;
                        detalle.precioMinimo = productoLista.precioMinimo * vm.pedidoVenta.cambio;
                        detalle.precioMaximo = productoLista.precioMaximo * vm.pedidoVenta.cambio;
                        detalle.precio = UtilFactory.roundForMoneda(detalle.precio, vm.pedidoVenta.moneda)
                    } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                        //si el presupuesto es en moneda extranjera y la lista en gs
                        var precioFinal = productoLista.precioVenta / vm.pedidoVenta.cambio;
                        var precioMin = productoLista.precioMinimo / vm.pedidoVenta.cambio;
                        var precioMax = productoLista.precioMaximo / vm.pedidoVenta.cambio;
                        detalle.precio = Math.round(precioFinal * 100) / 100;
                        detalle.precioMinimo = Math.round(precioMin * 100) / 100;
                        detalle.precioMaximo = Math.round(precioMax * 100) / 100;
                    } else {
                        detalle.precio = productoLista.precioVenta;
                        detalle.precioMinimo = productoLista.precioMinimo;
                        detalle.precioMaximo = productoLista.precioMaximo;
                    }
                    var obj = {
                      "precioMinimo": detalle.precioMinimo,
                      "precioMaximo": detalle.precioMaximo
                    };
                    vm.precioMinimoMaximos.splice(index,1,obj);
                    vm.productoPerteneceLista = true;
                } else {

                    vm.productoPerteneceLista = false;
                    detalle.precio = 0;
                    CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {

                        if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                            //si el pedidoVenta es en gs y la lista en moneda extranjera
                            detalle.precio = precio.data * vm.pedidoVenta.cambioPactado;
                        } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                            //si el presupuesto es en moneda extranjera y la lista en gs
                            var precioFinal = precio.data / vm.pedidoVenta.cambio;
                            detalle.precio = Math.round(precioFinal * 100) / 100;
                        } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                            //si el presupuesto es en moneda extranjera y la lista en moneda extranjera
                            var precioFinal = precio.data / vm.pedidoVenta.cambio;
                            detalle.precio = Math.round(precioFinal * 100) / 100;
                        } else {
                            detalle.precio = parseFloat(precio.data.toString().replace(/\./, ","));
                        }

                        calcularTotalDetalle(detalle,index);
                    });
                }
                calcularTotalDetalle(detalle,index);
            } else {
                //El cliente está asociado a una lista de precios de ventas sin detalles
            }
        } else if (!vm.pedidoVenta.presupuestoVenta) {

            CambioPrecioFactory.getUltimoPrecioCompleto(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {

                if(precio.data != null){
                    var precioAux = precio.data.precioActual;
                    if(vm.pedidoVenta.tipoPrecio.codigo == 'tipo_precio_anterior'){
                        precioAux = precio.data.precioAnterior;
                    }

                    if (vm.pedidoVenta.moneda.codigo == "guaranies") {
                        detalle.precio = parseFloat(precioAux.toString().replace(/\./, ","));
                    } else {
                        var precioFinal = precioAux / vm.pedidoVenta.cambio;
                        detalle.precio = Math.round(precioFinal * 100) / 100;
                    }
                }
                if(vm.precioSegmento && vm.pedidoVenta.segmentoCliente){
                    calcularPrecioVentaConSegmento(detalle, vm.pedidoVenta.segmentoCliente.nivel);

                    if (vm.pedidoVenta.moneda.codigo == "guaranies") {
                        detalle.precio = Math.round(detalle.precio);
                    } else {
                        detalle.precio = Math.round(detalle.precio * 100) / 100;
                    }
                }

                calcularTotalDetalle(detalle,index);
            });
        }else{
          if (productosFromListaPrecio) {
            var productoLista = [];
            _.forEach(productosFromListaPrecio, function (elem) {
                if (detalle.unidadMedida.id == elem.unidadMedida.id) {
                    productoLista = elem;
                }
            });
                if (productoLista) {

                    if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                        detalle.precioMinimo = productoLista.precioMinimo * vm.pedidoVenta.cambioPactado;
                        detalle.precioMaximo = productoLista.precioMaximo * vm.pedidoVenta.cambioPactado;
                    } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                        var precioMin = productoLista.precioMinimo / vm.pedidoVenta.cambio;
                        var precioMax = productoLista.precioMaximo / vm.pedidoVenta.cambio;
                        detalle.precioMinimo = Math.round(precioMin * 100) / 100;
                        detalle.precioMaximo = Math.round(precioMax * 100) / 100;
                    } else {
                        detalle.precioMinimo = productoLista.precioMinimo;
                        detalle.precioMaximo = productoLista.precioMaximo;
                    }
                    var obj = {
                      "precioMinimo": detalle.precioMinimo,
                      "precioMaximo": detalle.precioMaximo
                    };
                    vm.precioMinimoMaximos.splice(index,1,obj);
                    vm.productoPerteneceLista = true;
                  }
                }
        }

        //si tiene presupuesto recuperar los precios de la lista si tiene y controlar precio detalle con el pedido y el presupuesto
        if (vm.utilizarPrecioBase && vm.pedidoVenta.listaPrecioVenta && vm.pedidoVenta.presupuestoVenta) {

            var precioBase = vm.pedidoVenta.listaPrecioVenta.precioBase.codigo;
            //ultimocostoIVA - tabla stk_producto_almacen
            if (precioBase == 'ultimo_costo') {

                var filtroCosto = {};
                var fecha = moment(new Date()).format('DD/MM/YYYY');

                filtroCosto.search = filterFactory
                    .and([{
                        path: 'producto.id',
                        equals: detalle.producto.id
                    }, {
                        path: 'dia',
                        equalOrBefore: fecha
                    }, {
                        path: 'dia',
                        sortDesc: 'true'
                    }]).value();
                ProductoAlmacenFactory.all(filtroCosto).$promise.then(function (productosAlmacen) {

                    if (productosAlmacen != undefined && productosAlmacen.length > 0) {

                        vm.ultimoCostoIVA = parseFloat(productosAlmacen[0].ultimoCostoIVA).toFixed(2);

                        if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo != "guaranies") {
                            //si el pedido es en gs y la lista en moneda extranjera
                            detalle.precio = parseFloat(vm.ultimoCostoIVA.toString().replace(/\./, ","));
                        } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo == "guaranies") {
                            //si el pedido es en moneda extranjera y la lista en Gs
                            var precioFinal = vm.ultimoCostoIVA / vm.pedidoVenta.cambio;
                            detalle.precio = Math.round(precioFinal * 100) / 100;
                        } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo != "guaranies") {
                            //si el pedido es en moneda extranjera y la lista en moneda extranjera
                            var precioFinal = vm.ultimoCostoIVA / vm.pedidoVenta.cambio;
                            detalle.precio = Math.round(precioFinal * 100) / 100;
                        } else {
                            detalle.precio = parseFloat(vm.ultimoCostoIVA.toString().replace(/\./, ","));
                        }

                        if (detalle.precio > 0 && vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo > 0) {

                            vm.porcRecargo = vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo;
                            var precioAux = detalle.precio * (vm.porcRecargo / 100) + detalle.precio;
                            detalle.precio = UtilFactory.roundForMoneda(precioAux, vm.pedidoVenta.moneda);
                        }
                        calcularTotalDetalle(detalle,index);
                    }
                });
            }
            else {

                //precio_actual - tabla vnt_cambio_precio_detalle
                CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {

                    if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo != "guaranies") {
                        //si el pedidoVenta es en gs y la lista en moneda extranjera
                        detalle.precio = parseFloat(precio.data.toString().replace(/\./, ","));
                    } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo == "guaranies") {
                        //si el pedidoVenta es en moneda extranjera y la lista en Gs
                        var precioFinal = precio.data / vm.pedidoVenta.cambio;
                        detalle.precio = Math.round(precioFinal * 100) / 100;
                    } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo != "guaranies") {
                        //si el pedidoVenta es en moneda extranjera y la lista en moneda extranjera
                        var precioFinal = precio.data / vm.pedidoVenta.cambio;
                        detalle.precio = Math.round(precioFinal * 100) / 100;
                    } else {
                        detalle.precio = parseFloat(precio.data.toString().replace(/\./, ","));
                    }

                    if (detalle.precio > 0 && vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo > 0) {
                        vm.porcRecargo = vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo;
                        var precioAux = detalle.precio * (vm.porcRecargo / 100) + detalle.precio;
                        detalle.precio = UtilFactory.roundForMoneda(precioAux, vm.pedidoVenta.moneda);
                    }
                    calcularTotalDetalle(detalle,index);
                });
            }
        } else if (!vm.utilizarPrecioBase && vm.pedidoVenta.listaPrecioVenta && vm.pedidoVenta.presupuestoVenta) {

          if (productosFromListaPrecio) {

            var productoLista = [];
            _.forEach(productosFromListaPrecio, function (elem) {
              if (detalle.unidadMedida.id == elem.unidadMedida.id) {
                productoLista = elem;
              }
            });

                if (productoLista) {

                    if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo != "guaranies") {
                        _.forEach(vm.pedidoVenta.presupuestoVenta.presupuestoVentaDetalle, function (elem1) {

                            if (elem1.precio != productoLista.precioVenta) {

                                if (elem1.id == detalle.id) {

                                    if (elem1.producto.id == detalle.producto.id) {

                                        if (elem1.unidadMedida.id == detalle.unidadMedida.id) {
                                            if (detalle.cantidadPresupuesto == undefined) {
                                                detalle.precio = UtilFactory.roundForMoneda(elem1.precio * vm.pedidoVenta.cambioPactado, vm.pedidoVenta.moneda);
                                            } else {
                                                detalle.precio = UtilFactory.roundForMoneda(elem1.precio * vm.pedidoVenta.cambioPactado, vm.pedidoVenta.moneda);
                                                detalle.total = UtilFactory.roundForMoneda(detalle.precio * detalle.cantidadPresupuesto, vm.pedidoVenta.moneda);
                                            }
                                        }
                                    } else {
                                        detalle.precio = UtilFactory.roundForMoneda(detalle.precio * vm.pedidoVenta.cambioPactado, vm.pedidoVenta.moneda);
                                        detalle.total = UtilFactory.roundForMoneda(detalle.precio * detalle.cantidadPresupuesto, vm.pedidoVenta.moneda);
                                    }
                                }
                                else {
                                    if (elem1.unidadMedida.id == detalle.unidadMedida.id) {
                                        if (detalle.cantidadPresupuesto == undefined) {
                                            detalle.precio = UtilFactory.roundForMoneda(productoLista.precioVenta * vm.pedidoVenta.cambioPactado, vm.pedidoVenta.moneda);
                                        } else {
                                            detalle.precio = UtilFactory.roundForMoneda(productoLista.precioVenta * vm.pedidoVenta.cambioPactado, vm.pedidoVenta.moneda);
                                            detalle.total = UtilFactory.roundForMoneda(productoLista.precioVenta * detalle.cantidadPresupuesto, vm.pedidoVenta.moneda);
                                        }
                                    }
                                }
                            } else {

                                if (detalle.cantidadPresupuesto == undefined) {
                                    detalle.precio = UtilFactory.roundForMoneda(productoLista.precioVenta * vm.pedidoVenta.cambioPactado, vm.pedidoVenta.moneda);
                                    detalle.precioMinimo = productoLista.precioMinimo * vm.pedidoVenta.cambioPactado;
                                    detalle.precioMaximo = productoLista.precioMaximo * vm.pedidoVenta.cambioPactado;
                                } else {
                                    detalle.precio = UtilFactory.roundForMoneda(productoLista.precioVenta * vm.pedidoVenta.cambioPactado, vm.pedidoVenta.moneda);
                                    detalle.total = UtilFactory.roundForMoneda(detalle.precio * detalle.cantidadPresupuesto, vm.pedidoVenta.moneda);
                                    detalle.precioMinimo = productoLista.precioMinimo * vm.pedidoVenta.cambioPactado;
                                    detalle.precioMaximo = productoLista.precioMaximo * vm.pedidoVenta.cambioPactado;
                                }
                                var obj = {
                                  "precioMinimo": detalle.precioMinimo,
                                  "precioMaximo": detalle.precioMaximo
                                };
                                vm.precioMinimoMaximos.splice(index,1,obj);
                            }
                        });
                    } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo == "guaranies") {
                        _.forEach(vm.pedidoVenta.presupuestoVenta.presupuestoVentaDetalle, function (elem1) {

                            if (elem1.precio != productoLista.precioVenta) {

                                if (elem1.id == detalle.id) {

                                    if (elem1.producto.id == detalle.producto.id) {

                                        if (elem1.unidadMedida.id == detalle.unidadMedida.id) {
                                            var precioFinal = elem1.precio / vm.pedidoVenta.cambio;
                                            detalle.precio = Math.round(precioFinal * 100) / 100;
                                            if (detalle.cantidad == undefined) {
                                                detalle.total = detalle.precio * detalle.cantidadPresupuesto;
                                            } else {
                                                detalle.total = detalle.precio * detalle.cantidad;
                                            }
                                        }
                                    } else {
                                        var precioFinal = detalle.precio / vm.pedidoVenta.cambio;
                                        detalle.precio = Math.round(precioFinal * 100) / 100;
                                        detalle.total = Math.round(detalle.total * 100) / 100;
                                    }
                                } else {
                                    var precioFinal = productoLista.precioVenta / vm.pedidoVenta.cambio;
                                    detalle.precio = Math.round(precioFinal * 100) / 100;
                                    if (detalle.cantidad == undefined) {
                                        detalle.total = detalle.precio * detalle.cantidadPresupuesto;
                                    } else {
                                        detalle.total = detalle.precio * detalle.cantidad;
                                    }
                                }
                            } else {
                                //si el presupuesto es en moneda extranjera y la lista en gs
                                var precioFinal = productoLista.precioVenta / vm.pedidoVenta.cambio;
                                var precioMin = productoLista.precioMinimo / vm.pedidoVenta.cambio;
                                var precioMax = productoLista.precioMaximo / vm.pedidoVenta.cambio;
                                detalle.precio = Math.round(precioFinal * 100) / 100;
                                detalle.precioMinimo = Math.round(precioMin * 100) / 100;
                                detalle.precioMaximo = Math.round(precioMax * 100) / 100;
                                if (detalle.cantidad == undefined) {
                                    detalle.total = detalle.precio * detalle.cantidadPresupuesto;
                                } else {
                                    detalle.total = detalle.precio * detalle.cantidad;
                                }
                                var obj = {
                                  "precioMinimo": detalle.precioMinimo,
                                  "precioMaximo": detalle.precioMaximo
                                };
                                vm.precioMinimoMaximos.splice(index,1,obj);
                            }
                        });

                    } else {
                        _.forEach(vm.pedidoVenta.presupuestoVenta.presupuestoVentaDetalle, function (elem1) {
                            var precioAux;
                            if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                                precioAux = productoLista.precioVenta / vm.pedidoVenta.cambio;
                                precioAux = Math.round(precioAux * 100) / 100;
                            } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                                precioAux = productoLista.precioVenta;
                            } else if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                                precioAux = UtilFactory.roundForMoneda(productoLista.precioVenta * vm.pedidoVenta.cambioPactado, vm.pedidoVenta.moneda);
                            } else {
                                precioAux = productoLista.precioVenta;
                            }

                            if (elem1.precio != precioAux) {
                                if (elem1.producto.id == detalle.producto.id) {
                                    if (elem1.unidadMedida.id == detalle.unidadMedida.id) {
                                        var precioFinal = elem1.precio;
                                        if (vm.pedidoVenta.moneda.codigo != "guaranies") {
                                            detalle.precio = Math.round(precioFinal * 100) / 100;
                                        } else {
                                            detalle.precio = precioFinal;
                                        }
                                        if (detalle.cantidad == undefined && detalle.cantidadPresupuesto != undefined) {
                                            detalle.total = detalle.precio * detalle.cantidadPresupuesto;
                                        } else if (detalle.cantidad != undefined) {
                                            detalle.total = detalle.precio * detalle.cantidad;
                                        }
                                    } else {
                                        var precioFinal = precioAux;
                                        detalle.precio = precioFinal;
                                        if (detalle.cantidad == undefined && detalle.cantidadPresupuesto != undefined) {
                                            detalle.total = detalle.precio * detalle.cantidadPresupuesto;
                                        } else if (detalle.cantidad != undefined) {
                                            detalle.total = detalle.precio * detalle.cantidad;
                                        }
                                    }
                                } else {
                                    var precioFinal
                                    if (vm.pedidoVenta.moneda.codigo != "guaranies") {
                                        precioFinal = precioAux;
                                        detalle.precio = Math.round(precioFinal * 100) / 100;
                                        detalle.total = Math.round(detalle.total * 100) / 100;
                                    } else {
                                        precioFinal = productoLista.precioVenta;
                                        detalle.precio = precioAux;
                                    }
                                }
                            } else {
                                detalle.precio = precioAux;
                                detalle.precioMinimo = productoLista.precioMinimo;
                                detalle.precioMaximo = productoLista.precioMaximo;
                                var obj = {
                                  "precioMinimo": detalle.precioMinimo,
                                  "precioMaximo": detalle.precioMaximo
                                };
                                vm.precioMinimoMaximos.splice(index,1,obj);
                            }
                        });
                    }
                    vm.productoPerteneceLista = true;
                } else {
                    vm.productoPerteneceLista = false;
                    detalle.precio = 0;
                    CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                        if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo != "guaranies") {
                            if (detalle.cantidadPresupuesto != undefined) {
                                //si el pedidoVenta es en gs y la lista en moneda extranjera
                                detalle.precio = precio.data * vm.pedidoVenta.cambioPactado
                            } else {
                                detalle.precio = precio.data;
                            };
                        } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.presupuestoVenta.moneda.codigo == "guaranies") {
                            //si el presupuesto es en moneda extranjera y la lista en gs
                            var precioFinal = precio.data / vm.pedidoVenta.cambio;
                            detalle.precio = Math.round(precioFinal * 100) / 100;
                        } else {
                            if (detalle.cantidadPresupuesto != undefined) {
                                detalle.precio = parseFloat(precio.data.toString().replace(/\./, ","));
                            } else {
                                var precioFinal = precio.data / vm.pedidoVenta.cambio;
                                detalle.precio = Math.round(precioFinal * 100) / 100;
                            }
                        }

                        calcularTotalDetalle(detalle,index);
                    });
                }
                calcularTotalDetalle(detalle,index);
            } else {
                //El cliente está asociado a una lista de precios de ventas sin detalles
            }
        } else if (vm.pedidoVenta.presupuestoVenta) {
            //cuando tiene presupuesto y no lista
            CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                if (vm.pedidoVenta.moneda.codigo == "guaranies") {
                    if (precio.data != 0.0) {
                        detalle.precio = parseFloat(precio.data);
                        detalle.precio = UtilFactory.roundForMoneda(detalle.precio, vm.pedidoVenta.moneda);

                        if (detalle.cantidad == undefined) {
                            detalle.total = detalle.precio * detalle.cantidadPresupuesto;
                        }
                    } else {
                        _.forEach(vm.pedidoVenta.presupuestoVenta.presupuestoVentaDetalle, function (elem1) {
                            if (elem1.id == detalle.id) {
                                if (elem1.producto.id == detalle.producto.id) {
                                    if (elem1.unidadMedida.id == detalle.unidadMedida.id) {
                                        detalle.precio = UtilFactory.roundForMoneda(elem1.precio, vm.pedidoVenta.moneda);
                                        detalle.total = UtilFactory.roundForMoneda(elem1.total, vm.pedidoVenta.moneda);
                                    }
                                } else {
                                    detalle.precio = UtilFactory.roundForMoneda(detalle.precio, vm.pedidoVenta.moneda);
                                    detalle.total = UtilFactory.roundForMoneda(detalle.total, vm.pedidoVenta.moneda);
                                }
                            }
                        });
                    }
                } else {
                    if (precio.data != 0.0) {
                        var precioFinal = precio.data / vm.pedidoVenta.cambio;
                        detalle.precio = Math.round(precioFinal * 100) / 100;
                        if (detalle.cantidad == undefined) {
                            detalle.total = detalle.precio * detalle.cantidadPresupuesto;
                        } else {
                            detalle.total = detalle.precio * detalle.cantidad;
                        }
                    } else {
                        _.forEach(vm.pedidoVenta.presupuestoVenta.presupuestoVentaDetalle, function (elem1) {
                            if (elem1.id == detalle.id) {
                                if (elem1.producto.id == detalle.producto.id) {
                                    if (elem1.unidadMedida.id == detalle.unidadMedida.id) {
                                        var precioFinal = elem1.precio / vm.pedidoVenta.cambio;
                                        detalle.precio = Math.round(precioFinal * 100) / 100;
                                        var precioFinal2 = elem1.total / vm.pedidoVenta.cambio;
                                        detalle.total = Math.round(precioFinal2 * 100) / 100;
                                    }

                                } else {
                                    detalle.precio = Math.round((detalle.precio / vm.pedidoVenta.cambio) * 100) / 100;
                                    detalle.total = Math.round((detalle.total / vm.pedidoVenta.cambio) * 100) / 100;
                                }
                            }
                        });
                    }
                }

                calcularTotalDetalle(detalle,index);
            });
        }
    }

    function submit() {

        vm.submited = true;
        if ($scope.PedidoVentaForm.$valid && (vm.verPrecio == 'No' || controlMontosDetalles(vm.pedidoVenta))) {
            if (vm.pedidoVenta.pedidoVentaDetalle.length >= 1) {
                var existeTotal0 = _.filter(vm.pedidoVenta.pedidoVentaDetalle, function (elem) {
                    if (elem.incluir && elem.total == 0) return elem;
                });
                if (!vm.pedidoVenta.cliente.activo) {
                  var msg = "El cliente no se encuentra en estado Activo.";
                  notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  vm.submited = false;
                  $rootScope.isProcessing = false;
                  return;
                }

                if (vm.pedidoVenta.cliente.bloquearVentas) {
                  var msg = "El cliente se encuentra bloqueado para los procesos de ventas.";
                  notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  vm.submited = false;
                  $rootScope.isProcessing = false;
                  return;
                }
                if (vm.pedidoVenta.fechaEntrega < vm.pedidoVenta.fecha) {
                    var msg = vm.translations.FECHA_ENTREGA_MENOR; //vm.translations.DETAILS_ERROR_OP_MANUAL_STOCK;
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.submited = false;
                    return;
                }
                if (existeTotal0.length > 0 && vm.verPrecio != 'No') {
                    var msg = vm.translations.EXISTE_TOTAL_CERO; //vm.translations.DETAILS_ERROR_OP_MANUAL_STOCK;
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.submited = false;
                    return;
                }
                if (vm.grupoRepetido) {
                    var msg = vm.translations.NUMERO_GRUPO_REPETIDO; //vm.translations.DETAILS_ERROR_OP_MANUAL_STOCK;
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.submited = false;
                    return;
                }
                if(vm.pedidoVenta.sucursalCliente.centroCostos && (vm.pedidoVenta.centroCostos==null || vm.pedidoVenta.centroCostos==undefined)){
                    var msg = "La sucursal del cliente tiene centro de costo pero el pedido no, favor verificar";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.submited = false;
                    vm.submitedAgil = false;
                    $rootScope.isProcessing = false;
                    return;
                }
                $rootScope.isProcessing = true;
                vm.pedidoVenta.pedidoVentaDetalle = _.filter(vm.pedidoVenta.pedidoVentaDetalle, function (elem) { return elem.producto; });
                // se quita el atributo grupo de los detalles cuyos productos son de tipo normal
                var existeProductoLote = false;
                vm.pedidoVenta.pedidoVentaDetalle = _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (elem) {
                    if (elem.producto.criterioControl.codigo !== 'series' && elem.producto.criterioControl.codigo !== 'lotes') {
                        delete elem.grupo;
                    } else if (elem.producto.criterioControl.codigo == 'lotes') {
                        existeProductoLote = true;
                    }
                });

                var usersFilter = {};
                usersFilter.search = filterFactory.and([{
                    path: 'username',
                    equals: $rootScope.AuthParams.username
                }]).value();
                usersFilter.view = "BaseList"
                UsuariosFactory.all(usersFilter).$promise.then(function (data) {
                    vm.pedidoVenta.usuario = data[0];

                    submitPedidoVenta().then(function (response) {
                        $state.go('app.pedidoventas.list');
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        errorHandler(error);
                        vm.submited = true;
                    });
                });

            } else {
                var msg = vm.translations.ERROR_NO_DETALLES; //vm.translations.DETAILS_ERROR_OP_MANUAL_STOCK;
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
        }
    }

    function errorHandler(e) {
        var msg = "";
        _.forEach(e.data, function (error) {
            msg += "\n\n" + error.message + ".";
        });

        notify({ message: msg, classes: "alert-danger", position: "right" });
    }

    // se realiza un control de montos consistentes
    function controlMontosDetalles(pedidoVenta) {
        var consistente = true;
        pedidoVenta.pedidoVentaDetalle = _.forEach(pedidoVenta.pedidoVentaDetalle, function (elem) {
            if (elem.incluir && UtilFactory.roundForMoneda(elem.cantidad * (elem.precio - (elem.precio * (elem.porcentajeDescuento / 100))), vm.pedidoVenta.moneda) != elem.total) {
                //var msg = vm.translations.ERROR_PRECIO_INCONSISTENTE;
                notify({ message: $filter("translate")("ERROR_PRECIO_INCONSISTENTE", { producto: elem.producto.codigo }), classes: "alert-danger", position: "right" });
                consistente = false;
                return;
            }
        });
        return consistente;
    }

    function submitPedidoVenta() {

        _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (elem) {
            delete elem.mostrarMas;
        });

        var trimmedModel = ModelTrimmer.trimDetails(vm.pedidoVenta, ["pedidoVentaDetalle"]);
        var trimmedDetalles = _.map(vm.pedidoVenta.pedidoVentaDetalle, function (detalle) {
            return ModelTrimmer.trimDetails(detalle);
        });
        trimmedModel.pedidoVentaDetalle = trimmedDetalles;
        trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        var resource = PedidoVentaFactory.create(trimmedModel);
        return PedidoVentaFactory.save(resource);
    }

    function plusClicked(detalle) {
        detalle.mostrarMas = !detalle.mostrarMas;
    }

    function cancel() {
        if ($state.is("app.pedidoventas.new")) {
            $state.go('app.pedidoventas.list');
        } else if ($state.is("app.pedidoventas.edit")) {
            $state.go('app.pedidoventas.list');
        } else if ($state.is("app.pedidoventas.view")) {
            $state.go('app.pedidoventas.list');
        } else {
            $state.go('app.ventas.proceso.pedidosventa.list');
        }

    }

    function checkPermisosFor(action, estado) {
        if (!PedidoVentaFactory.hasPermisos(action, estado)) {
            $state.go("app.main");
        }
    }

    function changeListaPrecioVenta() {

        //Al cambiar o seleccionar una LP, limpiamos el campo de LP en los items
        _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (detalle) {
            detalle.listaPrecioVenta = null;
        })


        if (vm.permitirProductosNotListaPrecio == 'No') {
          var staticFilter = {};
          staticFilter.search = filterFactory.and([{
            path: 'listaPrecioVenta.id',
            equals: vm.pedidoVenta.listaPrecioVenta.id,
          }]).value();
          staticFilter.view = "PedidoVentaForm";
          ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
            _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (detalle) {
              vm.productoExisteInLP = _.find(response, function (detalleLP) { return detalle.producto.id === detalleLP.producto.id });
              if (vm.productoExisteInLP == undefined) {
                removeItemFromArray(detalle, vm.pedidoVenta.pedidoVentaDetalle);
              }
            })
          });
        }

        vm.pedidoVenta.monedaLista = vm.pedidoVenta.listaPrecioVenta.moneda;

        if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
            //si el pedidoVenta es en gs y la lista en moneda extranjera
            vm.mostrarCambioPactado = true;
            vm.monedaLocal.$promise.then(function (data) {
                if (vm.pedidoVenta.monedaLista && vm.pedidoVenta.monedaLista.descripcion != data[0].valorTipo.descripcion) {
                    vm.cotizacionRequerida = true;

                    var params = {};
                    params.search = filterFactory.and([{
                        path: 'monedaDesde.id',
                        equals: vm.pedidoVenta.monedaLista.id
                    },
                    {
                        path: 'monedaHasta.id',
                        equals: vm.monedaLocal[0].valorTipo.id
                    },
                    {
                        path: 'fecha',
                        like: moment(vm.pedidoVenta.fecha).format('DD/MM/YYYY')
                        //equals:vm.comprobante.fecha
                    }
                    ]).value();
                    params.view = 'CotizacionMonedaList';
                    CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                        if (response && response[0]) {
                            vm.pedidoVenta.cambioPactado = response[0].valorCompra;
                        } else {
                            vm.pedidoVenta.cambioPactado = null;
                        }
                    });
                } else {
                    vm.cotizacionRequerida = false;
                    vm.pedidoVenta.cambioPactado = 1;
                }
            });
        } else {
            vm.mostrarCambioPactado = false;
        }

        if (vm.pedidoVenta.listaPrecioVenta && vm.utilizarPrecioBase) {
            vm.porcRecargo = vm.pedidoVenta.listaPrecioVenta.porcentajeRecargo;
            vm.precioBase = vm.pedidoVenta.listaPrecioVenta.precioBase.descripcion;
        }
        vm.actualizarPreciosDeVentas();
    }

    function getListasPreciosVentasByMoneda() {
        if (vm.pedidoVenta.moneda) {
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'estado.codigo',
                equals: 'lista_activada'
            }]).value();
            ListaPrecioVentaFactory.all(staticFilter, "PedidoVentaForm").$promise.then(function (response) {
                if (response.length > 0) {
                    //ver que moneda es el pedidoVenta
                    if (vm.pedidoVenta.moneda.codigo == 'guaranies') {
                        //usa precio en Gs, Lista de precios en Gs y moneda extranjera
                        vm.listasPreciosVentas = response;
                    } else {
                        //si es moneda extranjera usa precio en Gs, Lista de precios en Gs y de la misma moneda extranjera de la operacion
                        var listaMoneda = [];
                        _.forEach(response, function (elem) {
                            if (elem.moneda.codigo == 'guaranies' || elem.moneda.codigo == vm.pedidoVenta.moneda.codigo) {
                                listaMoneda.push(elem);

                            }
                        });
                        vm.listasPreciosVentas = listaMoneda;
                    }
                } else {
                    vm.listasPreciosVentas = undefined;
                }
            });
        }
    }

    function actualizarPreciosDeVentas() {
      if (vm.pedidoVenta.pedidoVentaDetalle.length > 0 && vm.pedidoVenta.pedidoVentaDetalle[0].producto) {
      _.forEach(vm.pedidoVenta.pedidoVentaDetalle, function (elem) {
          vm.detalleEliminado = false;
          if (vm.pedidoVenta.listaPrecioVenta) {

              var staticFilter = {};
              staticFilter.search = filterFactory.and([{
                  path: 'listaPrecioVenta.id',
                  equals: vm.pedidoVenta.listaPrecioVenta.id,
              },
              {
                  path: 'producto.id',
                  equals: elem.producto.id,
              }]).value();
              staticFilter.view="PedidoVentaForm";
              ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                  if (vm.permitirProductosNotListaPrecio == 'No' && vm.pedidoVenta.listaPrecioVenta) {
                  if (response == undefined || response.length == 0) {
                      removeItemFromArray(elem, vm.pedidoVenta.pedidoVentaDetalle);
                      vm.detalleEliminado = true;
                  }
                  }
                  if (elem.precio != undefined && !vm.detalleEliminado) {
                  changeUnidad(elem, null, response);
                  }
              });
          } else {
              if (elem.precio != undefined) {
                  changeUnidad(elem);
              }
          };
      });
      }
    }

    function changePrecioCambioPactado() {
        actualizarPreciosDeVentas();
    }

    function changePrecioCambio() {
        actualizarPreciosDeVentas();
    }

    function volverSolicitud() {
        //traer la solicitud que corresponde al pedido
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'numeroProcesoOrigen',
            equals: vm.pedidoVenta.id + "",
        }]).value();
        SolicitudAutorizacionFactory.all(staticFilter).$promise.then(function (response) {
            var idSolicitud = response[0].id;
            $state.go("app.solicitudautorizacion.edit", { id: idSolicitud });
        });
    }

    function getCambio() {
        if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
            //si el pedidoVenta es en gs y la lista en moneda extranjera
            vm.mostrarCambioPactado = true;
            vm.monedaLocal.$promise.then(function (data) {
                var params = {};
                params.search = filterFactory.and([{
                    path: 'monedaDesde.id',
                    equals: vm.pedidoVenta.monedaLista.id
                },
                {
                    path: 'monedaHasta.id',
                    equals: vm.monedaLocal[0].valorTipo.id
                },
                {
                    path: 'fecha',
                    like: moment(vm.pedidoVenta.fecha).format('DD/MM/YYYY')
                }
                ]).value();
                params.view = 'CotizacionMonedaList';
                CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                    if (response && response[0]) {
                        vm.pedidoVenta.cambioPactado = response[0].valorCompra;
                    } else {
                        vm.pedidoVenta.cambioPactado = 1;
                    }
                });
            });
        }
    }

    function controlPrecioMinimoMaximos(detalle, index) {
        var name = "precio_" + index;
        if(vm.pedidoVenta.listaPrecioVenta){
          if (index != undefined) {
            var msg;
            $scope.PedidoVentaForm[name].$setValidity("error", true);
            if (vm.precioMinimoMaximos[index] && vm.precioMinimoMaximos[index].precioMinimo && detalle.precio < vm.precioMinimoMaximos[index].precioMinimo) {
              msg = "El precio no puede ser menor al precio mínimo de la Lista " + vm.precioMinimoMaximos[index].precioMinimo;
              vm.errores.splice(index, 1, msg);
              $scope.PedidoVentaForm[name].$error.pattern = true;
              $scope.PedidoVentaForm[name].$setValidity("error", false);
              $scope.PedidoVentaForm[name].$touched = true;
            } else if (vm.precioMinimoMaximos[index] && vm.precioMinimoMaximos[index].precioMaximo && detalle.precio > vm.precioMinimoMaximos[index].precioMaximo) {
              msg = "El precio no puede ser mayor al precio máximo de la Lista " + vm.precioMinimoMaximos[index].precioMaximo;
              vm.errores.splice(index, 1, msg);
              $scope.PedidoVentaForm[name].$error.pattern = true;
              $scope.PedidoVentaForm[name].$setValidity("error", false);
              $scope.PedidoVentaForm[name].$touched = true;
            } else {
              msg = "";
              vm.errores.splice(index, 1, msg);
              $scope.PedidoVentaForm[name].$error = {};
              $scope.PedidoVentaForm[name].$invalid = false;
            }
          }
        }
    }

    function controlLimiteDescuento(detalle,index) {
        var limiteDescuento = String(detalle.porcentajeDescuento);
        var cantidad;
        if (limiteDescuento.includes('.')) {
            cantidad = limiteDescuento.split('.')[1].length;
        }
        if (limiteDescuento.includes(',')) {
            cantidad = limiteDescuento.split(',')[1].length;
        }
        if (cantidad > 2) {
            detalle.porcentajeDescuento = 0;
            var msg = "El Límite de Descuento debe contener como máximo 2 decimales.";
            notify({ message: msg, classes: "alert-warning", position: "right" });
            return
        }
        if (vm.pedidoVenta.listaPrecioVenta) {
            var productoLista = [];
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
            path: 'listaPrecioVenta.id',
            equals: vm.pedidoVenta.listaPrecioVenta.id,
            }, {
            path: 'producto.id',
            equals: detalle.producto.id,
            }]).value();
            staticFilter.view = "PedidoVentaForm";
            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                _.forEach(response, function (elem) {
                    if (detalle.unidadMedida.id == elem.unidadMedida.id) {
                    productoLista = elem;
                    return true;
                    }
                })
                if (productoLista.limiteDescuento && vm.pedidoVenta.listaPrecioVenta && vm.permisoEditarPrecioFromLista) {
                    if (productoLista.limiteDescuento && detalle.porcentajeDescuento > productoLista.limiteDescuento) {
                    detalle.porcentajeDescuento = 0;
                    //detalle.total = 0;
                    vm.calcularTotalDetalle(detalle,index);
                    var msg = $filter("translate")("PRECIO_VENTA_DESCUENTO_MAYOR") + productoLista.limiteDescuento + "%";
                    notify({ message: msg, classes: "alert-warning", position: "right" });
                    }
                }
            });
        }
    }

    function delayRequestCliente(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
            var filterNombre =[];
            var filterCodigo =[];
            var filterNombreFantasia =[];
            var filterNumero =[];
            var filterDireccion =[];
            var filterCiudad =[];

            filterNombre.push({
                path: "nombre",
                like: searchValue
            })
            filterNombre.push({
              path: "activo",
              equals: true
            })
            filterNombreFantasia.push({
            path: "nombreFantasia",
            like: searchValue
            })
            filterNombreFantasia.push({
              path: "activo",
              equals: true
            })
            filterCodigo.push({
            path: "codigo",
            like: searchValue
            })
            filterCodigo.push({
              path: "activo",
              equals: true
            })
            filterNumero.push({
            path: "numero",
            like: searchValue
            })
            filterNumero.push({
              path: "activo",
              equals: true
            })
            filterDireccion.push({
            path: "direccion",
            like: searchValue
            })
            filterDireccion.push({
              path: "activo",
              equals: true
            })
            filterCiudad.push({
            path: "ciudad.descripcion",
            like: searchValue
            })
            filterCiudad.push({
              path: "activo",
              equals: true
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigo).value(),
                    filterFactory.and(filterNombreFantasia).value(),
                    filterFactory.and(filterNumero).value(),
                    filterFactory.and(filterDireccion).value(),
                    filterFactory.and(filterCiudad).value()
                ]).value()
            }

            recuperarCliente(userInputFilter)
        } else {
            vm.clientes = undefined
        }
    }
    function delayRequestClienteMobile(searchValue) {
      var userInputFilter = {}
      if (searchValue.length >= 3) {
        var filterNombre = [];
        var filterNombreFantasia = [];

        filterNombre.push({
          path: "nombre",
          like: searchValue
        })
        filterNombre.push({
          path: "activo",
          equals: true
        })
        filterNombreFantasia.push({
          path: "nombreFantasia",
          like: searchValue
        })
        filterNombreFantasia.push({
          path: "activo",
          equals: true
        })

        userInputFilter = {
          search: filterFactory.or([
            filterFactory.and(filterNombre).value(),
            filterFactory.and(filterNombreFantasia).value()
          ]).value()
        }

        recuperarCliente(userInputFilter)
      } else {
        vm.clientes = undefined
      }
    }
    function delayRequestDocumentoMobile(searchValue) {
      var userInputFilter = {}
      if (searchValue.length >= 6) {
        var filterNumero = [];

        filterNumero.push({
          path: "numero",
          like: searchValue
        })
        filterNumero.push({
          path: "activo",
          equals: true
        })

        userInputFilter = {
          search: filterFactory.or([
            filterFactory.and(filterNumero).value()
          ]).value()
        }

        recuperarCliente(userInputFilter)
      } else {
        vm.clientes = undefined
      }
    }

    function recuperarCliente(userInputFilter) {
    var finalFilter = UtilFactory.createNestedFilters(
        vm.finalFilters, userInputFilter)

      ClienteFactory.allForSelect(finalFilter).$promise.then(function (clientes) {
        var clie = [];
        for (var i = 0; i < clientes.length; i++) {
          if (clientes[i].bloquearVentas !== true) {
            clie.push(clientes[i])
          }
        }
        vm.clientes = clie;
      })
    }

    function searchClienteFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.nombreFantasia && item.nombreFantasia.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
            );
        }
    }

    function searchSucursalClienteFilter(criteria) {
        return function (item) {
        if (!criteria) {
            return true;
        }
        return (
            item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
            (item.codigoSucursal.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
        );
        };
    }

    function compararDescendente(a, b) {
      return b.id - a.id;
    }
    function controlPrecioUltimoCosto(detalle, index) {
        var name = "precio_" + index;
        var precioVentaAux, unidadMedidaNew, cantidadContenida, costoActual,ultimoCostoAux,ultimoCostoAlmacen;
        var ultimoCostoSucursalList = [];

        if(vm.parametroAdministrarCostoPor == 'por_almacen'){
          ultimoCostoAlmacen = detalle.producto.ultimoCostoAlmacenList.find(function(ultimoCosto){
            return ultimoCosto.almacen.id == vm.pedidoVenta.almacen.id;
          });
        }else if(vm.parametroAdministrarCostoPor == 'por_sucursal'){
          ultimoCostoAlmacen = detalle.producto.ultimoCostoAlmacenList.find(function(ultimoCosto){
            if(ultimoCosto.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id){
              ultimoCostoSucursalList.push(ultimoCosto);
            }
          });
          var listaUltimoCostoSucursalOrde = $filter('orderBy')(ultimoCostoSucursalList, ['-fecha', '-id']);
          if(listaUltimoCostoSucursalOrde.length > 0){
            ultimoCostoAlmacen = listaUltimoCostoSucursalOrde[0];
          }
        }else{
          var listaUltimosCostos = detalle.producto.ultimoCostoAlmacenList;
          listaUltimosCostos.sort(compararDescendente);
          var listaObjetosOrdenada = $filter('orderBy')(listaUltimosCostos, ['-fecha', '-id']);
          if(listaObjetosOrdenada.length > 0){
            ultimoCostoAlmacen = listaObjetosOrdenada[0];
          }
        }
        if(ultimoCostoAlmacen != undefined && ultimoCostoAlmacen != null){
          if(vm.parametroAdministracionCosto == 'costo_promedio_almacen'){
            ultimoCostoAux = ultimoCostoAlmacen.costoPromedio;
          }else{
            ultimoCostoAux = ultimoCostoAlmacen.ultimoCosto;
          }
        }else{
          ultimoCostoAux = 0;
        }

        precioVentaAux = detalle.precio;

        if (ultimoCostoAux != undefined) {
        ultimoCostoAux = parseFloat(ultimoCostoAux).toFixed(2);
        unidadMedidaNew = detalle.unidadMedida;
        cantidadContenida = unidadMedidaNew.cantidad;
        costoActual = ultimoCostoAux;
        if (cantidadContenida != undefined && cantidadContenida != null) {
            ultimoCostoAux = Math.round(costoActual * cantidadContenida);
        }
        }
        if (vm.pedidoVenta.moneda.codigo != "guaranies") {
        vm.ultimoCostoIvaAux = ultimoCostoAux/vm.pedidoVenta.cambio;
        vm.ultimoCostoIvaAux = Math.round(vm.ultimoCostoIvaAux * 100) / 100;
        } else {
        vm.ultimoCostoIvaAux = ultimoCostoAux;
        }

        if (detalle.porcentajeDescuento && detalle.porcentajeDescuento > 0) {
        precioVentaAux = precioVentaAux - (precioVentaAux * (detalle.porcentajeDescuento / 100));
        }

        if (index != undefined) {
        var msg;
        $scope.PedidoVentaForm[name].$setValidity("error", true);
        if (precioVentaAux < vm.ultimoCostoIvaAux && vm.verPrecio != 'No') {
            if (detalle.porcentajeDescuento && detalle.porcentajeDescuento > 0) {
            msg = "El precio calculado no puede ser menor al último costo";
            if(vm.verCostoControl != "No"){
              msg = msg + " : " + vm.ultimoCostoIvaAux;
            }
            vm.errores.splice(index, 1, msg);
            } else {
            msg = "El precio no puede ser menor al último costo";
            if(vm.verCostoControl != "No"){
              msg = msg + " : " + vm.ultimoCostoIvaAux;
            }
            vm.errores.splice(index, 1, msg);
            }
            $scope.PedidoVentaForm[name].$error.pattern = true;
            $scope.PedidoVentaForm[name].$setValidity("error", false);
            $scope.PedidoVentaForm[name].$touched = true;
        } else {
            msg = "";
            vm.errores.splice(index, 1, msg);
            $scope.PedidoVentaForm[name].$error = {};
            $scope.PedidoVentaForm[name].$invalid = false;
            if (vm.pedidoVenta.listaPrecioVenta) {
            controlPrecioMinimoMaximos(detalle, index);
            }
        }
        }
    }

    function changeUnidadAux(detalle, index) {
        if (vm.pedidoVenta.listaPrecioVenta) {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'listaPrecioVenta.id',
            equals: vm.pedidoVenta.listaPrecioVenta.id,
        },
        {
            path: 'producto.id',
            equals: detalle.producto.id,
        }]).value();
        staticFilter.view = "PedidoVentaForm";
        ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
            if (response.length > 0) {
            changeUnidad(detalle, index, response);
            } else {
            changeUnidad(detalle);
            }
        });
        } else {
        changeUnidad(detalle);
        }
    }

    function recuperarPreciosMinimosMaximos() {
        if (vm.pedidoVenta.pedidoVentaDetalle.length > 0 && vm.pedidoVenta.pedidoVentaDetalle[0].producto) {
            if (vm.pedidoVenta.listaPrecioVenta) {
                var staticFilter = {};
                staticFilter.search = filterFactory.and([{
                    path: 'listaPrecioVenta.id',
                    equals: vm.pedidoVenta.listaPrecioVenta.id,
                }]).value();
                staticFilter.view = "PrecioMinimoMaximoForm";
                vm.uiBlockuiConfig.bloquear=true;
                ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (lista) {
                  vm.uiBlockuiConfig.bloquear=false;
                    if (lista != undefined || lista.length > 0) {
                        for (var j = 0; j < vm.pedidoVenta.pedidoVentaDetalle.length; j++) {
                            var productoLista;
                            productoLista = _.find(lista, function (detalleLP) {
                                return vm.pedidoVenta.pedidoVentaDetalle[j].producto.id === detalleLP.producto.id &&
                                    vm.pedidoVenta.pedidoVentaDetalle[j].unidadMedida.id === detalleLP.unidadMedida.id
                            });
                            var precioMinimo, precioMaximo;
                            if (productoLista) {
                                if (vm.pedidoVenta.moneda.codigo == "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                                    precioMinimo = productoLista.precioMinimo * vm.pedidoVenta.cambioPactado;
                                    precioMaximo = productoLista.precioMaximo * vm.pedidoVenta.cambioPactado;
                                } else if (vm.pedidoVenta.moneda.codigo != "guaranies" && vm.pedidoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                                    var precioMin = productoLista.precioMinimo / vm.pedidoVenta.cambio;
                                    var precioMax = productoLista.precioMaximo / vm.pedidoVenta.cambio;
                                    precioMinimo = Math.round(precioMin * 100) / 100;
                                    precioMaximo = Math.round(precioMax * 100) / 100;
                                } else {
                                    precioMinimo = productoLista.precioMinimo;
                                    precioMaximo = productoLista.precioMaximo;
                                }
                                var obj = {
                                    "precioMinimo": precioMinimo,
                                    "precioMaximo": precioMaximo
                                };
                                vm.precioMinimoMaximos.splice(j, 1, obj);
                            }
                        }
                    }
                });
            }
        }
    }

    function calcularPrecioVentaConSegmento(detalle, nivel) {
        var nivelFieldMap = {
            1: detalle.producto.nivel1,
            2: detalle.producto.nivel2,
            3: detalle.producto.nivel3,
            4: detalle.producto.nivel4,
            5: detalle.producto.nivel5,
            6: detalle.producto.nivel6,
            7: detalle.producto.nivel7,
            8: detalle.producto.nivel8,
            9: detalle.producto.nivel9,
        };

        var nivelField = nivelFieldMap[nivel];

        var detalleSegmento = _.find(vm.pedidoVenta.segmentoCliente.segmentoDetalle, function(item) { return item.lineaProducto.id === nivelField.id});
        if(detalleSegmento != undefined){
            var porcentajeDecimal = detalleSegmento.listaPrecioVenta.porcentajeRecargo / 100; // Convertir el porcentaje a una proporción decimal
            detalle.precio = detalle.precio + detalle.precio*porcentajeDecimal;
            detalle.listaPrecioVenta = detalleSegmento.listaPrecioVenta;
        }else{
            ParametrosFactory.getByCodigo('permitir_productos_fuera_segmento').then(function (parametro) {
                if (parametro.valorTipo.codigo === 'no') {        
                    var msg = $filter("translate")("ERROR_LINEAS_DIFERENTES", { producto: detalle.producto.nombre, segmento: vm.pedidoVenta.segmentoCliente.nombre});
                    notify({ message: msg, classes: "alert-warning", position: "right" });
                    detalle.producto = null;
                    detalle.unidadMedida = null;
                    detalle.cantidad = null;
                }
            });
        }
    }

    function changeTipoVenta() {
      vm.pedidoVenta.formaPago = null;
      vm.pedidoVenta.cantidadCuotas = null;
      filterFormasPago();
    }

    function filterFormasPago() {
      if(vm.pedidoVenta.cliente){
        if(vm.pedidoVenta.condicionCobro.codigo === "condicion_cobro_pedido_venta_contado"){
          vm.formasPagos = _.filter(vm.formasPagosAux, function (formaPago) {
            return formaPago.cantidadCuotas == null;
          });
        } else if(vm.pedidoVenta.condicionCobro.codigo === "condicion_cobro_pedido_venta_credito"){
          vm.formasPagos = _.filter(vm.formasPagosAux, function (formaPago) {
            return formaPago.cantidadCuotas != null;
          });
        }
      }
    }
  $scope.resource = 'pedidoventas';
}

'use strict';


angular.module('qualita.venta')
    .controller('PedidosVentaListCtrl',
        ["$scope", "PedidoVentaFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "VentasLangFactory", "VentaFactory", "WatchLocacionChangeFactory", "$filter", "notify", "PeriodoAdministrativoFactory", "UtilFactory", "CsvFactory", "baseurl", "$q", "ParametrosFactory", "SolicitudAutorizacionFactory", "TiposTipoFactory", "ClienteFactory", "CotizacionMonedaFactory", "$timeout", "$rootScope", function ($scope, PedidoVentaFactory, filterFactory, AuthorizationService,
            ReportTicketFactory, $modal, $window, $state, VentasLangFactory, VentaFactory,
            WatchLocacionChangeFactory, $filter, notify, PeriodoAdministrativoFactory,
            UtilFactory, CsvFactory, baseurl, $q, ParametrosFactory, SolicitudAutorizacionFactory, TiposTipoFactory, ClienteFactory,
            CotizacionMonedaFactory, $timeout, $rootScope) {

            var vm = this;
            var staticFilter = {};
            vm.isProcesoVenta = false;
            vm.isProcesoVentaPlanillaReparto = false;
            vm.planillaRepartoId;
            vm.isProcessing = false;
            vm.visualizar = true;
            var pedidoVentaActual = PedidoVentaFactory.hasPermisos("listar_pv_usuario_actual");

            ParametrosFactory.getByCodigo('asignar_grupo_pv').then(function (parametro) {
                if (parametro.valorTipo.codigo === 'no') {
                    vm.asignarGrupo = false;
                } else {
                    vm.asignarGrupo = true;
                }
            });

            ParametrosFactory.getByCodigo('control_ventas_perdidas').then(function (parametro) {
                if (parametro.valorTipo.codigo === 'no') {
                    vm.controlVentasPerdidas = false;
                } else {
                    vm.controlVentasPerdidas = true;
                }
            });

            ParametrosFactory.getByCodigo('ver_precio_pedido').then(function (parametro) {
                if (parametro.valorTipo.codigo === 'no') {
                    vm.visualizar = false;
                } 
            });

            var monedaRender = function (data, type, row) {
                var campoMoneda = row.moneda;

                if (data != undefined) {

                    if(!vm.visualizar){
                        data = 0;
                    }

                    var moneda = "Gs. ";
                    if (campoMoneda.codigo === "dolares") {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "pesos") {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "real") {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "euro") {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    } else {
                        data = parseFloat(data).toFixed(0);
                    }
                          
                    // TODO hacer esto mismo en todos los renders de moneda
                    return (
                        moneda +
                        data
                            .toString()
                            .replace(".", ",")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                } else {
                    return "";
                }
            };
            var getIDs = function () {
                var ids = [];
                _.forEach(scope.options.selection, function (n, key) {
                    if (n)
                        ids.push(key);
                });
                return ids;
            }

            var renderEmptyData = function (data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            $scope.$watch('AuthParams.accesoSistema.usuario.id',function(newValue){
              if(newValue){
              VentasLangFactory.getTranslations().then(function (translations) {
                if ($state.is("app.pedidoventas.list") || $state.is("app.pedidoventas")) {
                  var proceso = false;
                  staticFilter = {
                    search: filterFactory.and([{
                      path: 'id',
                      sortDesc: 'true'
                    },{
                      path: 'usuario.id',
                      equals: pedidoVentaActual ? $rootScope.AuthParams.accesoSistema.usuario.id : null
                    }]).value()
                  };
                } else {
                  var proceso = true;
                  vm.isProcesoVenta = true;
                  var venta = VentaFactory.getVenta();
                  if (!venta) {
                    $state.go("app.main");
                  }
                  var isStateEnabled = VentaFactory.checkPermissionForState(0);
                  if (!isStateEnabled) {
                    $state.go("app.main");
                  }
                  var staticFilter = {};
                  if (venta.planillaReparto) {
                    vm.planillaRepartoId = venta.planillaReparto.id;
                    vm.isProcesoVentaPlanillaReparto = true;
                    staticFilter = {
                      search: filterFactory.and([{
                        path: 'planillaReparto.id',
                        equals: venta.planillaReparto.id
                      }]).value()
                    };
                  } else {
                    staticFilter = {
                      search: filterFactory.and([{
                        path: 'ventas.id',
                        equals: VentaFactory.getIdVenta(),
                      }]).value()
                    };
                  }

                }

                var defaultColumnOrder = ['id', 'fecha', 'cliente.nombreFantasia', 'vendedor.nombresyapellidos','cliente.codigo', 'cliente.nombre', 'sucursalCliente.codigoSucursal', 'sucursalCliente.descripcion',
                  'montoTotal', 'cantidadCuotas', 'estado.descripcion', 'ventasString', 'presupuestoVenta.id', 'planillaReparto.numero', 'listaPrecioVenta.descripcion',
                  'origen.descripcion'
                ];
                var renderVendedorData = function(data) {
                  if (data == undefined)
                    return "";
                  else
                    return data;
                };
                $scope.options = {
                  'staticFilter': staticFilter,
                  'resource': 'pedidoventas',
                  'title': 'Pedido Venta',
                  'factory': PedidoVentaFactory,
                  'defaultColumnOrder': defaultColumnOrder,
                  'view': 'PedidoVentaList',
                  'columns': [
                    //{'data': 'id', 'title': translations.CODE},
                    { 'data': 'id', 'title': 'Número', 'class': 'dt-center' },
                    { 'data': 'fecha', 'title': translations.DATE, 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'hora', 'title': 'Hora', 'renderWith': renderEmptyData },
                    { 'data': 'cliente.nombreFantasia', 'title': translations.NOMBRE_FANTASIA },
                    { 'data': 'vendedor.nombresyapellidos','title': 'Vendedor', renderWith: renderVendedorData },
                    { 'data': 'cliente.codigo', 'title': translations.CODIGO_CLIENTE },
                    { 'data': 'cliente.nombre', 'title': translations.CLIENTE },
                    { 'data': 'sucursalCliente.codigoSucursal', 'title': translations.CODIGO_SUCURSAL, renderWith: renderEmptyData },
                    { 'data': 'sucursalCliente.descripcion', 'title': translations.SUCURSAL_CLIENTE, renderWith: renderEmptyData }, { 'data': 'moneda.descripcion', 'title': translations.CURRENCY, 'renderWith': 'emptyRender' },
                    { 'data': 'condicionCobro.descripcion', 'title': translations.CONDICION_COBRO, 'renderWith': 'emptyRender' },
                    { 'data': "montoTotal", 'title': $filter("translate")("MONTO"), 'renderWith': monedaRender, class: "dt-right" },
                    { 'data': 'cantidadCuotas', 'title': translations.CANTIDAD_CUOTAS, class: "dt-right", 'renderWith': 'emptyRender' },
                    { 'data': 'estado.descripcion', 'title': translations.STATUS, 'renderWith': 'emptyRender', type: "combo", filterUrl: "tipos/filtro/estados_pedido_venta" },
                    { 'data': 'ventasString', 'title': translations.VENTAS, class: "dt-right", 'renderWith': 'emptyRender' },
                    { 'data': 'presupuestoVenta.id', 'title': 'Nro. de Presupuesto', 'class': 'dt-center', 'renderWith': 'emptyRender' },
                    { 'data': 'planillaReparto.numero', 'title': 'Nro. de Planilla', renderWith: renderEmptyData },
                    { 'data': 'listaPrecioVenta.descripcion', 'title': 'Lista de Precios', 'class': 'dt-center', 'renderWith': 'emptyRender' },
                    { 'data': 'origen.descripcion', 'title': 'Origen', 'class': 'dt-center', 'renderWith': 'emptyRender' }
                  ],
                  'isSelectable': true,
                  'selection': {},
                  'hasOptions': true,
                  'defaultOrderColumn': 1,
                  'defaultOrderDir': "desc",
                  'hideAddMenu': true,
                  'hideEditMenu': true,
                  'hideRemoveMenu': proceso,
                  'hideViewMenu': true,
                  'extraMenuOptions': [{
                    'title': $filter("translate")("NEW"),
                    'icon': "glyphicon glyphicon-plus",
                    'showCondition': function () {
                      return !proceso
                    },
                    'action': function () {
                      nuevoPedido();
                    }
                  },
                    {
                      'title': $filter('translate')('CERRAR_PEDIDO'),
                      'icon': 'glyphicon glyphicon-check',
                      'showCondition': function () {
                        return PedidoVentaFactory.hasPermisos("cerrar") && !proceso;
                      },
                      'action': function () {
                        var ids = getIDs();
                        $scope.tituloModal = $filter('translate')('CERRAR_PEDIDOS_VENTAS_Q');
                        $scope.mensajeModal = $filter('translate')('PERMANENT_OPERATION_WARNING');
                        var modalInstance = $modal.open({
                          templateUrl: 'views/directives/confirmation-modal.html',
                          scope: $scope
                        });
                        $scope.cancel = function () {
                          modalInstance.dismiss('cancel');
                        };
                        $scope.ok = function (id) {
                          modalInstance.dismiss('cancel');
                          //console.log(ids);
                          if (ids.length > 0) {
                            PedidoVentaFactory.cerrarMultiple(ids);
                          } else {
                            var msg = $filter('translate')('SELECCIONAR_PEIDOS_VENTA_WARNING');
                            notify({ message: msg, classes: 'alert-warning', position: 'right' });
                          }
                        }
                      }
                    },
                    {
                      'title': $filter("translate")("VIEW_PLANILLA_REPARTO"),
                      'icon': "glyphicon glyphicon-share-alt",
                      'showCondition': function () {
                        return vm.isProcesoVentaPlanillaReparto;
                      },
                      'action': function () {
                        $window.open('/#/planillasrepartos/' + vm.planillaRepartoId);
                      }
                    },
                    {
                      'title': 'CSV',
                      'icon': 'glyphicon glyphicon-export',
                      'showCondition': function () { return true; },
                      'action': function () {
                        var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                        CsvFactory.csv("pedidoventas", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                          .then(function (response) {
                            console.log("response de csv: ", response);
                            var fileName = response.data.fileName;
                            $window.open(baseurl.getPublicBaseUrl() + fileName);
                          });
                      }
                    },
                    {
                      'title': 'Reporte',
                      'icon': 'glyphicon glyphicon-file',
                      'showCondition': function () { return true; },
                      'action': function () {
                        var filters = createFilters($scope.options.getFilters());
                        ReportTicketFactory.ticket('listadoPedidoVenta', filters, $scope.options.tableAjaxParams,
                          $scope.options.currentColumnOrder).then(function (genTicket) {
                          $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                          $window.open($scope.pdfDownloadURL, '_blank');
                        });
                      }
                    },
                    {
                      'title': $filter('translate')('CONSOLIDAR'),
                      'icon': 'glyphicon glyphicon-resize-small',
                      'showCondition': function () {
                        return true;
                        //return !vm.isProcesoCompra && AuthorizationService.hasPermission('update_pedido_venta');
                      },
                      'action': function () {
                        var ids = getIDs();
                        if (ids.length < 2) {
                          notify({ message: $filter('translate')('SELECCIONAR_PEDIDO_VENTA_WARNING'), classes: 'alert-warning', position: 'right' });
                        } else {
                          $scope.tituloModal = $filter('translate')('CONSOLIDAR_PEDIDO_VENTA');
                          $scope.mensajeModal = $filter('translate')('PERMANENT_OPERATION_WARNING');
                          var modalInstance = $modal.open({
                            templateUrl: 'views/directives/confirmation-modal.html',
                            scope: $scope
                          });

                          $scope.cancel = function () {
                            modalInstance.dismiss('cancel');
                          };

                          $scope.ok = function (id) {
                            modalInstance.dismiss('cancel');
                            PedidoVentaFactory.consolidar(ids);
                          }
                        }
                      }
                    }
                    ],
                    'extraRowOptions': [{
                        templateToRender: "<button title='" + $filter('translate')('DISTRIBUIR_LOTES') + "' class='btn btn-primary btn-dt' style='margin-right: 5px;' ng-click='distribuir(<%=dataId%>)' ng-class='{ hidden : !canDistribuir(<%=dataCustom%>)}' ng-disabled='vm.isProcessing'> <span class='glyphicon glyphicon-random'></span> </button>",
                        functionName: "distribuir",
                        conditionName: "canDistribuir",
                        customAttribute: 'estado',
                        conditionDef: function (estado) {
                            return PedidoVentaFactory.hasPermisos("distribuir", estado) && !vm.asignarGrupo;
                        },
                        functionDef: function (itemId) {
                            if (!vm.isProcessing) {
                                vm.isProcessing = true;
                                PedidoVentaFactory.distribuir(itemId).then(function (response) {
                                    notify({ message: "Lotes distribuidos con éxito", classes: 'alert-success', position: 'right', templateUrl: "" });
                                    vm.isProcessing = false;
                                }, function (error) {
                                    //console.log(error);
                                    vm.isProcessing = false;
                                    var mensaje = "<span>";
                                    _.forEach(error.data, function (err) {
                                        mensaje = mensaje + err.message + "<br/>";
                                    });
                                    mensaje = mensaje + "</span>";
                                    notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                });
                            }
                        }
                    }, {
                            templateToRender: "<button id='aprobar<%=dataId%>' title='" + $filter('translate')('APROBAR_PEDIDO') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataId%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
                            functionName: "aprobar",
                            conditionName: "canAprobar",
                            customAttribute: 'estado',
                            conditionDef: function (estado) {
                                return PedidoVentaFactory.hasPermisos("confirm", estado) && !proceso;
                            },
                            functionDef: function (itemId) {
                                $('#aprobar' + itemId).attr('disabled', 'disabled');
                                PedidoVentaFactory.get(itemId, "PedidoVentaList").$promise.then(function (response) {
                                    checkPeriodoAdministrativo(response.fecha).then(function (resultado) {
                                        if (resultado) {
                                            $scope.mensajeModal = $filter('translate')('APROBAR_PEDIDO_MENSAJE');

                                            $scope.tituloModal = $filter('translate')('APROBAR_PEDIDO');
                                            $scope.config = "SIN_N_LOTE";
                                            var modalInstance = $modal.open({
                                                templateUrl: 'qualita-components/ventas/views/pedidoventa/confirmation-modal.html',
                                                scope: $scope
                                            });

                                            $scope.cancel = function () {
                                                $('#aprobar' + itemId).removeAttr("disabled");
                                                modalInstance.dismiss('cancel');
                                            };

                                            $scope.ok = function (id, config) {
                                                PedidoVentaFactory.verificarPrecioSegmento(itemId).then(function (responseSegmento) {
                                                    PedidoVentaFactory.verificarPrecio(itemId).then(function (responseVerificar) {
                                                        if (responseVerificar.data == 'true') {
                                                            var msg = "El precio de venta no puede ser menor o mayor al precio establecido en la Lista de Precios."
                                                            notify({ message: msg, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                            modalInstance.close();
                                                            return
                                                        }
                                                        //verificar si esta en si parametro de control stock
                                                        ParametrosFactory.getByCodigo('control_stock_pv').then(function (parametro) {
                                                            if (parametro.valorTipo.codigo === 'no') {
                                                                controlarVencimiento(response, itemId, modalInstance, config);
                                                            } else {
                                                                PedidoVentaFactory.verificarControlStock(itemId).then(function (rr) {
                                                                    controlarVencimiento(response, itemId, modalInstance, config);
                                                                }, function (error) {
                                                                    var mensaje = "<span>";
                                                                    _.forEach(error.data, function (err) {
                                                                        mensaje = mensaje + err.message + "<br/>";
                                                                    });
                                                                    mensaje = mensaje + "</span>";
                                                                    notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                                    modalInstance.close();
                                                                });
                                                            }
                                                        });
                                                    })
                                                }, function (error) {
                                                    console.log("error ", error);
                                                    if (error.data.filename) {
                                                        notify({
                                                            message: error.data.error,
                                                            classes: 'alert-danger',
                                                            position: 'right'
                                                        });
                                                        var fileName = error.data.filename;
                                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                                    } else {
                                                        var mensaje = "<span>";
                                                        //_.forEach(error.data, function (err) {
                                                            mensaje = mensaje + error.data.data + "<br/>";
                                                        //});
                                                        mensaje = mensaje + "</span>";
                                                        notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                    }
                                                    modalInstance.close();
                                                });
                                            };
                                            $timeout(function () {
                                                $('#aprobar' + itemId).removeAttr("disabled");
                                            }, 1000)
                                        } else if (resultado == false) {
                                            var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    });
                                });
                            }
                        },
                    {
                      templateToRender: "<button title='" + $filter('translate')('ANULAR_PEDIDO') + "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataId%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                      functionName: "anular",
                      conditionName: "canAnular",
                      customAttribute: ['canAnular', 'estado'],
                      conditionDef: function (atributo) {
                        if (atributo[1].codigo == "pv_ingresado") {
                          return PedidoVentaFactory.hasPermisos("anular", null, atributo[0]) && !proceso && vm.controlVentasPerdidas;
                        } else {
                          return PedidoVentaFactory.hasPermisos("anular", null, atributo[0]) && !proceso;
                        }
                      },
                      functionDef: function (itemId) {
                        PedidoVentaFactory.get(itemId).$promise.then(function (response) {
                          $scope.mensajeModal = $filter('translate')('ANULAR_PEDIDO_MENSAJE');

                          $scope.tituloModal = $filter('translate')('ANULAR_PEDIDO');
                          var modalInstance = $modal.open({
                            templateUrl: 'views/directives/confirmation-modal.html',
                            scope: $scope
                          });

                          $scope.cancel = function () {
                            modalInstance.dismiss('cancel');
                          };

                          $scope.ok = function (id) {
                            PedidoVentaFactory.anular(itemId).then(function (response) {
                              modalInstance.close();
                              $state.go($state.current, {}, { reload: true });
                            }, function (error) {
                              //console.log(error);
                              var mensaje = "<span>";
                              _.forEach(error.data, function (err) {
                                mensaje = mensaje + err.message + "<br/>";
                              });
                              mensaje = mensaje + "</span>";
                              notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                            });
                          };
                        });
                      }
                    },
                    {
                        templateToRender: "<button title='" +
                            $filter("translate")("REIMPRIMIR_PEDIDO") +
                            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reimprimir(<%=dataCustom%>)' ng-class='{ hidden : !canReimprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-repeat'></span> </button>",
                        functionName: "reimprimir",
                        conditionName: "canReimprimir",
                        customAttribute: ["estado", "id"],
                        conditionDef: function (atributos) {
                            var estado = atributos[0];
                            if (estado.codigo === "pv_aprobado" || estado.codigo === "pv_cerrado") {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        functionDef: function (atributos) {
                            var itemId = atributos[1];

                            $scope.mensajeModal = translations.REIMPRIMIR_COMPROBANTE_Q;
                            $scope.tituloModal = $filter("translate")("REIMPRIMIR_PEDIDO");
                            $scope.config = "SIN_N_LOTE";
                            var modalInstance = $modal.open({
                                templateUrl: 'qualita-components/ventas/views/pedidoventa/confirmation-modal.html',
                                scope: $scope
                            });

                        $scope.cancel = function () {
                          modalInstance.dismiss("cancel");
                        };

                            $scope.ok = function (id,config) {
                                var filtersArr = [];
                                filtersArr.push("idpedidoventa=" + itemId);
                                filtersArr.push("config=" + config);
                                modalInstance.close();
                                $state.go($state.current, {}, { reload: true });
                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                    "pedidoventa",
                                    "pdf",
                                    filtersArr
                                );
                                $window.open(vm.pdfDownloadURL, "_blank");
                                $state.go($state.current, {}, { reload: true });
                            }
                        }
                    },
                    {
                      templateToRender: "<button title='" + $filter('translate')('CERRAR_PEDIDO') + "' class='btn btn-primary btn-dt' style='margin-right: 5px;' ng-click='cerrar(<%=dataId%>)' ng-class='{ hidden : !aCerrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-check'></span> </button>",
                      functionName: "cerrar",
                      conditionName: "aCerrar",
                      customAttribute: 'canCerrar',
                      conditionDef: function (canCerrar) {
                        return PedidoVentaFactory.hasPermisos("cerrar", null, canCerrar) && !proceso;
                      },
                      functionDef: function (itemId) {
                        PedidoVentaFactory.get(itemId).$promise.then(function (response) {
                          $scope.mensajeModal = $filter('translate')('CERRAR_PEDIDO_MENSAJE');

                          $scope.tituloModal = $filter('translate')('CERRAR_PEDIDO');
                          var modalInstance = $modal.open({
                            templateUrl: 'views/directives/confirmation-modal.html',
                            scope: $scope
                          });

                          $scope.cancel = function () {
                            modalInstance.dismiss('cancel');
                          };

                          $scope.ok = function (id) {
                            PedidoVentaFactory.cerrar(itemId).then(function (response) {
                              modalInstance.close();
                              $state.go($state.current, {}, { reload: true });
                            }, function (error) {
                              //console.log(error);
                              var mensaje = "<span>";
                              _.forEach(error.data, function (err) {
                                mensaje = mensaje + err.message + "<br/>";
                              });
                              mensaje = mensaje + "</span>";
                              notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                            });
                          };
                        });
                      }
                    }, {
                      templateToRender: "<button title='" + $filter('translate')('DESAPROBAR_PEDIDO') + "' class='btn btn-light btn-dt' style='margin-right: 5px;' ng-click='desaprobar(<%=dataId%>)' ng-class='{ hidden : !canDesaprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                      functionName: "desaprobar",
                      conditionName: "canDesaprobar",
                      customAttribute: ['canDesaprobar', 'estado'],
                      conditionDef: function (atributo) {
                        return PedidoVentaFactory.hasPermisos("desaprobar", atributo[1], null) && !proceso;
                      },
                      functionDef: function (itemId) {
                        PedidoVentaFactory.get(itemId, "PedidoVentaList").$promise.then(function (response) {
                          if(response.autorizadoSolicitud){
                            $scope.mensajeModal = $filter('translate')('DESAPROBAR_PEDIDO_CON_SOLICITUD_MENSAJE');
                          }else{
                            $scope.mensajeModal = $filter('translate')('DESAPROBAR_PEDIDO_MENSAJE');
                          }


                          $scope.tituloModal = $filter('translate')('DESAPROBAR_PEDIDO');
                          var modalInstance = $modal.open({
                            templateUrl: 'views/directives/confirmation-modal.html',
                            scope: $scope
                          });

                          $scope.cancel = function () {
                            modalInstance.dismiss('cancel');
                          };

                          $scope.ok = function (id) {
                            PedidoVentaFactory.desaprobar(itemId).then(function (response) {
                              modalInstance.close();
                              $state.go($state.current, {}, { reload: true });
                            }, function (error) {
                              //console.log(error);
                              var mensaje = "<span>";
                              _.forEach(error.data, function (err) {
                                mensaje = mensaje + err.message + "<br/>";
                              });
                              mensaje = mensaje + "</span>";
                              notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                            });
                          };
                        });
                      }
                    },
                    {
                      templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                      functionName: "ver",
                      functionDef: function (itemId) {
                        if (vm.isProcesoVenta) {
                          $state.go("app.ventas.proceso.pedidosventa.view", { id: itemId });
                        } else {
                          $state.go("app.pedidoventas.view", { id: itemId });
                        }
                      },
                      conditionName: "canVer",
                      conditionDef: function () {
                        return PedidoVentaFactory.hasPermisos("view");
                      }
                    },
                    {
                      templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') +
                        "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                      customAttribute: ["id", "estado"],
                      conditionName: 'canEditar',
                      conditionDef: function (atributos) {
                        var item = atributos[0];
                        var estado = atributos[1];
                        if (item != undefined)
                          return PedidoVentaFactory.hasPermisos("edit", estado) && !proceso;
                      },
                      functionName: "editar",
                      functionDef: function (atributos) {
                        var itemId = atributos[0];
                        var estado = atributos[1];
                        if (PedidoVentaFactory.hasPermisos("edit", estado) && !proceso) {
                          PedidoVentaFactory.get(itemId, "PedidoVentaList").$promise.then(function (response) {
                            if (response.solicitudAutorizacion != null && response.solicitudAutorizacionBloqueo != null) {
                              if (response.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente"
                                && response.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                notify({
                                  message: 'El Pedido cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                });
                                return;
                              } else {
                                if (response.autorizadoSolicitud == true && response.autorizadoSolicitudBloqueo == true) {
                                  notify({
                                    message: 'El Pedido cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                  });
                                  return;
                                } else if (response.autorizadoSolicitud == true) {
                                  notify({
                                    message: 'El Pedido cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                  });
                                  return;
                                }
                              }
                            } else if (response.solicitudAutorizacion != null && response.solicitudAutorizacionBloqueo == null) {
                              if (response.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                notify({
                                  message: 'El Pedido cuenta con Solicitud de Sobregiro con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                });
                                return;
                              } else {
                                if (response.autorizadoSolicitud == true) {
                                  notify({
                                    message: 'El Pedido cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                  });
                                  return;
                                }
                              }
                            } else if (response.solicitudAutorizacion == null && response.solicitudAutorizacionBloqueo != null) {
                              if (response.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                notify({
                                  message: 'El Pedido cuenta con Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                });
                                return;
                              } else {
                                if (response.autorizadoSolicitudBloqueo == true) {
                                  notify({
                                    message: 'El Pedido cuenta con Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                  });
                                  return;
                                }
                              }
                            }
                            $state.go("app.pedidoventas.edit", { id: itemId });
                          });
                        } else {
                          notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                        }
                      }
                    }
                  ],
                  'extraRemoveConditions': function (item) {
                    if (item != undefined)
                      return PedidoVentaFactory.hasPermisos("remove", item.estado) && !vm.controlVentasPerdidas;
                  },
                  //'extraEditConditions': function (item) {
                  //    if (item != undefined)
                  //        return PedidoVentaFactory.hasPermisos("edit", item.estado);
                  // },
                };

                WatchLocacionChangeFactory.watchWith(staticFilter, $scope.options, "locacion.id");
              });
              }
            })

            var controlarVencimiento = function (response, itemId, modalInstance,config) {
                ParametrosFactory.getByCodigo('bloquear_ventas_por_venc_cuentas_cobrar').then(function (res) {
                    //si el parametro es 2. Bloquear en PV-Rem-Fac entonces continuar, si no, verificar el sobregiro
                    if (res.valorTipo.codigo === "bloquear_pv_rem_fac") {
                        PedidoVentaFactory.verificarVencimiento(itemId).then(function (rr) {
                            //si el cliente tiene cuotas vencidas y sobrepasa la tolerancia continuar, si no, verificar sobregiro
                            if (rr.data == 'true') {
                                //si no se tiene la solicitud de bloqueo y no tiene decision cotinuar si no, verificar los casos
                                if (response.solicitudAutorizacionBloqueo == null && response.autorizadoSolicitudBloqueo == null) {
                                    $scope.mensajeModal = 'El cliente cuenta con cuotas vencidas.';
                                    $scope.tituloModal = 'Información';
                                    var modalInstance2 = $modal.open({
                                        templateUrl: 'views/directives/confirmation-modal.html',
                                        scope: $scope
                                    });
                                    $scope.cancel = function () {
                                        modalInstance2.dismiss('cancel');
                                        modalInstance.dismiss('cancel');
                                    };

                                    $scope.ok = function () {
                                        $scope.mensajeModal = 'Desea realizar una Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias?';
                                        $scope.tituloModal = 'Confirmación';
                                        var modalInstance3 = $modal.open({
                                            templateUrl: 'views/directives/confirmation-modal.html',
                                            scope: $scope
                                        });
                                        $scope.cancel = function () {
                                            modalInstance3.dismiss('cancel');
                                            modalInstance2.dismiss('cancel');
                                            modalInstance.dismiss('cancel');
                                        };

                                        $scope.ok = function () {
                                            //controlar que el usuario pueda crear solicitud de autorizacion
                                            if (AuthorizationService.hasPermission("create_solicitudautorizacion") === true) {
                                                $scope.mensajeModal = 'Motivo de la Solicitud: ';
                                                $scope.tituloModal = 'Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias';
                                                //obtener el tipo Ignorar Vencimiento y Tolerancia
                                                $scope.motivos = TiposTipoFactory.get("proceso_autorizar");
                                                $scope.motivos.$promise.then(function (estados) {
                                                    var estadoActivo = _.find($scope.motivos, function (estado) {
                                                        return estado.codigo === "ignorar_venc_cuota_tolerancia";
                                                    });
                                                    if (estadoActivo) {
                                                        $scope.config = estadoActivo;
                                                    }
                                                    var modalInstance4 = $modal.open({
                                                        templateUrl: 'qualita-components/ventas/views/pedidoventa/autorizacion-modal.html',
                                                        scope: $scope
                                                    });

                                                    $scope.cancel = function () {
                                                        modalInstance4.dismiss('cancel');
                                                        modalInstance3.dismiss('cancel');
                                                        modalInstance2.dismiss('cancel');
                                                        modalInstance.dismiss('cancel');
                                                    };

                                                    $scope.ok = function (id, config) {
                                                        $('#' + config.id).attr('disabled', 'disabled');
                                                        PedidoVentaFactory.solicitarAutorizacionBloqueo(itemId, config.id).then(function () {
                                                            PedidoVentaFactory.get(itemId, "PedidoVentaList").$promise.then(function (res) {
                                                                res.solicitudAutorizacionBloqueo.motivoSolicitud = config;
                                                                SolicitudAutorizacionFactory.save(res.solicitudAutorizacionBloqueo);

                                                                var filtersArr = [];
                                                                filtersArr.push("idpedidoventa=" + itemId);
                                                                modalInstance4.close();
                                                                // $state.go($state.current, {}, { reload: true });
                                                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                                    "solicitudautorizacionbloqueo",
                                                                    "pdf",
                                                                    filtersArr
                                                                );
                                                                $window.open(vm.pdfDownloadURL, "_blank");
                                                                $timeout(function () {
                                                                    $('#' + config.id).removeAttr("disabled");
                                                                }, 1000);
                                                                //luego de imprimir el reporte de bloqueo, continuar con el sobregiro
                                                                controlSobregiro(res, itemId, modalInstance,config);
                                                                //$state.go($state.current, {}, { reload: true });
                                                            });
                                                        }, function (error) {
                                                            var mensaje = "<span>";
                                                            _.forEach(error.data, function (err) {
                                                                mensaje = mensaje + err.message + "<br/>";
                                                            });
                                                            mensaje = mensaje + "</span>";
                                                            notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                        });
                                                    }
                                                    return estados;
                                                });

                                            } else {
                                                $scope.tituloModal = 'Error';
                                                $scope.mensajeModal = 'Usted no posee permiso para realizar la operación';
                                                var modalInstance5 = $modal.open({
                                                    template: '<div class="modal-header">' +
                                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                        "</div>" +
                                                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                        '<div class="modal-footer">' +
                                                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                        "</div>",
                                                    scope: $scope
                                                });
                                                $scope.cancel = function () {
                                                    modalInstance5.dismiss("cancel");
                                                    modalInstance3.dismiss('cancel');
                                                    modalInstance2.dismiss('cancel');
                                                    modalInstance.dismiss('cancel');
                                                };
                                            }
                                        }
                                    }
                                } else {
                                    verificarBloqueo(response, itemId, modalInstance,config);
                                }
                            } else {
                                //control de sobregiro si el cliente no tiene cuota vencida
                                controlSobregiro(response, itemId, modalInstance,config);
                            }
                        });
                    } else {
                        //control de sobregiro si el parametro no se cumple
                        controlSobregiro(response, itemId, modalInstance,config);
                    }
                });
            }

            var verificarBloqueo = function (response, itemId, modalInstance,config) {
                //controlar el sobregiro si no se controlo antes
                ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                    if (response1.valorTipo.codigo === "si") {
                        ParametrosFactory.getByCodigo('control_limite_credito_cliente_pedido_venta').then(function (responsepv) {
                            if (responsepv.valorTipo.codigo === "si") {
                                if (response.cliente != null && response.cliente.controlarLimiteCredito == true) {
                                    if (response.solicitudAutorizacion == null && response.solicitarAutorizacion == null) {
                                        controlSobregiro(response, itemId, modalInstance,config);
                                        modalInstance6.dismiss('cancel');
                                    }
                                }
                            }
                        });
                    }
                });

                if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == null) {
                    $scope.tituloModal = 'Error';
                    $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias sobre este pedido de venta';
                    var modalInstance6 = $modal.open({
                        template: '<div class="modal-header">' +
                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                            "</div>" +
                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                            '<div class="modal-footer">' +
                            '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                            "</div>",
                        scope: $scope
                    });
                    $scope.cancel = function () {
                        modalInstance6.dismiss('cancel');
                        modalInstance.dismiss('cancel');
                    };
                } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == false) {
                    $scope.tituloModal = 'Error';
                    $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                    var modalInstance7 = $modal.open({
                        template: '<div class="modal-header">' +
                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                            "</div>" +
                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                            '<div class="modal-footer">' +
                            '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                            "</div>",
                        scope: $scope
                    });
                    $scope.cancel = function () {
                        modalInstance7.dismiss('cancel');

                    };
                } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == true) {
                    //verificar que no exista solicitud de sobregiro
                    if (response.solicitudAutorizacion == null) {
                        aprobarPedido(itemId, modalInstance,config);
                    } else {
                        verificarSobregiro2(response, itemId, modalInstance);
                    }
                }
            }

            var verificarSobregiro = function (response, itemId, modalInstance,config) {
                if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == null) {
                    $scope.tituloModal = 'Error';
                    $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Sobregiro de Límite de Crédito sobre este pedido de venta';
                    var modalInstance6 = $modal.open({
                        template: '<div class="modal-header">' +
                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                            "</div>" +
                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                            '<div class="modal-footer">' +
                            '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                            "</div>",
                        scope: $scope
                    });
                    $scope.cancel = function () {
                        modalInstance6.dismiss('cancel');
                        modalInstance.dismiss('cancel');
                    };
                } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == false) {
                    $scope.tituloModal = 'Error';
                    $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                    var modalInstance7 = $modal.open({
                        template: '<div class="modal-header">' +
                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                            "</div>" +
                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                            '<div class="modal-footer">' +
                            '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                            "</div>",
                        scope: $scope
                    });
                    $scope.cancel = function () {
                        modalInstance7.dismiss('cancel');

                    };
                } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == true) {
                    //verificar que no exista solicitud de bloqueo
                    if (response.solicitudAutorizacionBloqueo == null) {
                        aprobarPedido(itemId, modalInstance,config);
                    } else {
                        verificarBloqueo(response, itemId, modalInstance,config);
                    }
                }
            }

            var verificarSobregiro2 = function (response, itemId, modalInstance,config) {
                if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == null) {
                    $scope.tituloModal = 'Error';
                    $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Sobregiro de Limite de Credito sobre este pedido de venta';
                    var modalInstance6 = $modal.open({
                        template: '<div class="modal-header">' +
                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                            "</div>" +
                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                            '<div class="modal-footer">' +
                            '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                            "</div>",
                        scope: $scope
                    });
                    $scope.cancel = function () {
                        modalInstance6.dismiss('cancel');
                        modalInstance.dismiss('cancel');
                    };
                } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == false) {
                    $scope.tituloModal = 'Error';
                    $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                    var modalInstance7 = $modal.open({
                        template: '<div class="modal-header">' +
                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                            "</div>" +
                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                            '<div class="modal-footer">' +
                            '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                            "</div>",
                        scope: $scope
                    });
                    $scope.cancel = function () {
                        modalInstance7.dismiss('cancel');

                    };
                } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == true) {
                    aprobarPedido(itemId, modalInstance,config);
                }
            }

            var aprobarPedido = function (itemId, modalInstance,config) {
                PedidoVentaFactory.aprobar(itemId).then(function (response) {
                    var filtersArr = [];
                    filtersArr.push("idpedidoventa=" + itemId);
                    filtersArr.push("config=" + config);
                    modalInstance.close();
                    $state.go($state.current, {}, { reload: true });
                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                        "pedidoventa",
                        "pdf",
                        filtersArr
                    );
                    $window.open(vm.pdfDownloadURL, "_blank");
                    $state.go($state.current, {}, { reload: true });
                }, function (error) {
                    if (error.data.filename) {
                        notify({
                            message: error.data.error,
                            classes: 'alert-danger',
                            position: 'right'
                        });
                        var fileName = error.data.filename;
                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                    } else {
                        var mensaje = "<span>";
                        //_.forEach(error.data, function (err) {
                            mensaje = mensaje + error.data.data + "<br/>";
                        //});
                        mensaje = mensaje + "</span>";
                        notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                    }
                });
            }

            var verificarBloqueo2 = function (response, itemId, modalInstance,config) {
                //si no control de sobregiro
                ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                    if (response1.valorTipo.codigo === "si") {
                        ParametrosFactory.getByCodigo('control_limite_credito_cliente_pedido_venta').then(function (responsepv) {
                            if (responsepv.valorTipo.codigo === "si") {
                                if (response.cliente.controlarLimiteCredito == true) {
                                    if (response.solicitudAutorizacion == null && response.solicitarAutorizacion == null) {
                                        modalInstance6.dismiss('cancel');
                                    }
                                } else {
                                    modalInstance6.dismiss('cancel');
                                }
                            } else {
                                modalInstance6.dismiss('cancel');
                            }
                        });
                    } else {
                        modalInstance6.dismiss('cancel');
                    }
                });



                if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == null) {
                    $scope.tituloModal = 'Error';
                    $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias sobre este pedido de venta';
                    var modalInstance6 = $modal.open({
                        template: '<div class="modal-header">' +
                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                            "</div>" +
                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                            '<div class="modal-footer">' +
                            '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                            "</div>",
                        scope: $scope
                    });
                    $scope.cancel = function () {
                        modalInstance6.dismiss('cancel');
                        modalInstance.dismiss('cancel');
                    };
                } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == false) {
                    $scope.tituloModal = 'Error';
                    $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                    var modalInstance7 = $modal.open({
                        template: '<div class="modal-header">' +
                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                            "</div>" +
                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                            '<div class="modal-footer">' +
                            '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                            "</div>",
                        scope: $scope
                    });
                    $scope.cancel = function () {
                        modalInstance7.dismiss('cancel');

                    };
                } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == true) {
                    //este caso es cuando no se cumple el parametro de sobregiro y la solicitud de bloqueo se autorizo
                    aprobarPedido(itemId, modalInstance,config);
                }
            }

            var calcularSaldo = function (response, itemId, modalInstance, cambio, resp,config) {
                var limiteCredito = response.cliente.limiteCredito;
                var saldos = resp.data + (response.montoTotal * cambio);
                if (saldos > limiteCredito) {
                    //se excedio se informa el importe de sobregiro y se crea la solicitud de autorizacion si tiene permiso y
                    //verificar de que ya no exista una solicitud previa
                    if (response.solicitudAutorizacion == null && response.autorizadoSolicitud == null) {
                        var valorSobregiro = ((response.montoTotal * cambio) + resp.data) - response.cliente.limiteCredito;
                        if (valorSobregiro < 0) {
                            valorSobregiro = valorSobregiro * (-1);
                        }
                        if (response.moneda.codigo != "guaranies") {
                            valorSobregiro = UtilFactory.roundForMoneda(valorSobregiro, response.moneda);
                        }
                        valorSobregiro = valorSobregiro.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

                        $scope.mensajeModal = 'Con el importe total del pedido de ventas se sobrepasa el Límite de Crédito del Cliente. El importe excedido del Límite de Crédito del Cliente es: ' + valorSobregiro + ' Gs.';
                        $scope.tituloModal = 'Información';
                        var modalInstance2 = $modal.open({
                            templateUrl: 'views/directives/confirmation-modal.html',
                            scope: $scope
                        });
                        $scope.cancel = function () {
                            modalInstance2.dismiss('cancel');
                            modalInstance.dismiss('cancel');
                        };

                        $scope.ok = function () {
                            $scope.mensajeModal = 'Desea realizar una Solicitud de Autorización de Sobregiro de Límite de Crédito?';
                            $scope.tituloModal = 'Confirmación';
                            var modalInstance3 = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });
                            $scope.cancel = function () {
                                modalInstance3.dismiss('cancel');
                                modalInstance2.dismiss('cancel');
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function () {
                                //controlar que el usuario pueda crear solicitud de autorizacion
                                if (AuthorizationService.hasPermission("create_solicitudautorizacion") === true) {
                                    //solicitar el campo motivo
                                    $scope.mensajeModal = 'Motivo de la Solicitud: ';
                                    $scope.tituloModal = 'Solicitud de Autorización de Sobregiro de Límite de Crédito';
                                    //obtener el tipo de Sobregiro
                                    $scope.motivos = TiposTipoFactory.get("proceso_autorizar");
                                    $scope.motivos.$promise.then(function (estados) {
                                        var estadoActivo = _.find($scope.motivos, function (estado) {
                                            return estado.codigo === "sobregiro_limite_credito";
                                        });
                                        if (estadoActivo) {
                                            $scope.config = estadoActivo;
                                        }
                                        var modalInstance4 = $modal.open({
                                            templateUrl: 'qualita-components/ventas/views/pedidoventa/autorizacion-modal.html',
                                            scope: $scope
                                        });

                                        $scope.cancel = function () {
                                            modalInstance4.dismiss('cancel');
                                            modalInstance3.dismiss('cancel');
                                            modalInstance2.dismiss('cancel');
                                            modalInstance.dismiss('cancel');
                                        };

                                        $scope.ok = function (id, config) {
                                            $('#' + config.id).attr('disabled', 'disabled');
                                            PedidoVentaFactory.solicitarAutorizacion(itemId,config.id).then(function () {
                                                PedidoVentaFactory.get(itemId, "PedidoVentaList").$promise.then(function (res) {
                                                    res.solicitudAutorizacion.motivoSolicitud = config;
                                                    SolicitudAutorizacionFactory.save(res.solicitudAutorizacion);
                                                    ParametrosFactory.getByCodigo("nombre_empresa").then(function (data) {
                                                        var filtersArr = [];
                                                        filtersArr.push("idpedidoventa=" + itemId);
                                                        filtersArr.push("nombre_empresa=" + data.valor);
                                                        filtersArr.push("id_sucursal=" + $rootScope.AuthParams.locacionSeleccionada.sucursal.id);
                                                        filtersArr.push("id_unidadnegocio=" + $rootScope.AuthParams.locacionSeleccionada.unidadNegocio.id);

                                                        modalInstance3.close();
                                                        $state.go($state.current, {}, { reload: true });
                                                        vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                            "solicitudautorizacion",
                                                            "pdf",
                                                            filtersArr
                                                        );
                                                        $window.open(vm.pdfDownloadURL, "_blank");
                                                        $timeout(function () {
                                                            $('#' + config.id).removeAttr("disabled");
                                                        }, 1000);
                                                        $state.go($state.current, {}, { reload: true });
                                                    });
                                                });

                                            }, function (error) {
                                                var mensaje = "<span>";
                                                _.forEach(error.data, function (err) {
                                                    mensaje = mensaje + err.message + "<br/>";
                                                });
                                                mensaje = mensaje + "</span>";
                                                notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                            });
                                        }
                                        return estados;
                                    });

                                } else {
                                    $scope.tituloModal = 'Error';
                                    $scope.mensajeModal = 'Usted no posee permiso para realizar la operación';
                                    var modalInstance5 = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            "</div>" +
                                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                            "</div>",
                                        scope: $scope
                                    });
                                    $scope.cancel = function () {
                                        modalInstance5.dismiss("cancel");
                                        modalInstance3.dismiss('cancel');
                                        modalInstance2.dismiss('cancel');
                                        modalInstance.dismiss('cancel');
                                    };
                                }
                            }
                        }
                    } else {
                        verificarSobregiro(response, itemId, modalInstance,config);
                    }
                } else {
                    //no excedio
                    if (response.solicitudAutorizacionBloqueo == null) {
                        aprobarPedido(itemId, modalInstance,config);
                    } else {
                        verificarBloqueo2(response, itemId, modalInstance,config);
                    }
                }
            }

            var controlSobregiro = function (response, itemId, modalInstance,config) {
                //controlar si los parametros de Limite Credito corresponden o no
                ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                    if (response1.valorTipo.codigo === "si") {
                        ParametrosFactory.getByCodigo('control_limite_credito_cliente_pedido_venta').then(function (responsepv) {
                            if (responsepv.valorTipo.codigo === "si") {
                                if (response.cliente != null && response.cliente.controlarLimiteCredito == true) {
                                    //obtener el saldo del cliente actualizado
                                    ClienteFactory.getSaldoCredito(response.cliente.id).then(function (resp) {
                                        //verificar la moneda del pedido
                                        var cambio = 1;
                                        if (response.moneda.codigo != "guaranies") {
                                            if (response.cambio > 1) {
                                                cambio = response.cambio;
                                                calcularSaldo(response, itemId, modalInstance, cambio, resp,config);
                                            } else if (response.cambio == null || response.cambio == 1) {
                                                //obtener de la cotizacion del dia
                                                var params = {};
                                                params.search = filterFactory.and([{
                                                    path: 'monedaDesde.id',
                                                    equals: response.moneda.id
                                                },
                                                {
                                                    path: 'id',
                                                    sortDesc: 'true'
                                                }
                                                ]).value();
                                                params.view = 'CotizacionMonedaList';
                                                CotizacionMonedaFactory.all(params).$promise.then(function (responsec) {
                                                    if (responsec && responsec[0]) {
                                                        cambio = responsec[0].valorCompra;
                                                    }
                                                    calcularSaldo(response, itemId, modalInstance, cambio, resp,config);
                                                });
                                            }
                                        } else {
                                            calcularSaldo(response, itemId, modalInstance, cambio, resp,config);
                                        }
                                    });
                                } else {
                                    //verificar si existe solicitud de bloqueo
                                    if (response.solicitudAutorizacionBloqueo == null) {
                                        aprobarPedido(itemId, modalInstance,config);
                                    } else {
                                        verificarBloqueo2(response, itemId, modalInstance,config);
                                    }
                                }
                            } else {
                                //verificar si existe solicitud de bloqueo
                                if (response.solicitudAutorizacionBloqueo == null) {
                                    aprobarPedido(itemId, modalInstance,config);
                                } else {
                                    verificarBloqueo2(response, itemId, modalInstance,config);
                                }
                            }
                        });
                    } else {
                        //verificar si existe solicitud de bloqueo
                        if (response.solicitudAutorizacionBloqueo == null) {
                            aprobarPedido(itemId, modalInstance,config);
                        } else {
                            verificarBloqueo2(response, itemId, modalInstance,config);
                        }
                    }
                });
            }

            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    search = search.split("~");
                    if (search.length > 1) {
                        /* El parámetro es un rago */

                        var isDate = search[0].split("/");

                        if (isDate.length > 1) {
                            /* Es un rago de fechas */

                            filtersArr.push({ path: data, equalOrAfter: search[0] })
                            filtersArr.push({ path: data, equalOrBefore: search[1] })
                        } else {
                            /* El un rago numérco */

                            filtersArr.push({ path: data, greaterOrEqual: search[0] })
                            filtersArr.push({ path: data, lessOrEqual: search[1] })
                        }
                    } else {
                        /* El parametro no es un rago */


                        filtersArr.push({ path: data, like: search[0] })
                    }
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }

            var getIDs = function () {
                var ids = [];
                _.forEach($scope.options.selection, function (n, key) {
                    if (n)
                        ids.push(key);
                });
                return ids;
            };

            /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
            var checkPeriodoAdministrativo = function (fechaComprobante) {
                return $q(function (resolve, reject) {

                    var params = {};
                    var fecha;

                    if (fechaComprobante == null) {
                        fecha = new Date();
                    } else {
                        fecha = fechaComprobante;
                    }

                    params.search = filterFactory
                        .and([{
                            path: "estado.codigo",
                            equals: "per_adm_abierto"
                        },
                        {
                            path: "fechaDesde",
                            equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                        },
                        {
                            path: "fechaHasta",
                            equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                        }
                        ])
                        .value();

                    PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {
                        if (responsePeriodo.length > 0) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    })
                });
            }


            function nuevoPedido() {

                var params = {};
                params.search = filterFactory
                    .or([{
                        path: "estado.codigo",
                        equals: "per_adm_abierto"
                    }])
                    .value();

                var flagPeriodo = false;

                params.view = "BaseList";
                PeriodoAdministrativoFactory.all(params).$promise.then(function (response) {
                    var periodos = response;
                    _.forEach(periodos, function (value) {
                        var desde = new Date(value.fechaDesde);
                        var hasta = new Date(value.fechaHasta);
                        var hoy = new Date();

                        /*  Se fuerza a que las horas del dia esten en cero para poder realizar
                            la compraración sin errores.
                        */
                        hoy.setHours(0, 0, 0, 0);

                        if (hoy >= desde && hoy <= hasta) {
                            flagPeriodo = true;
                        }
                    });
                    if (flagPeriodo) {
                        $state.go("app.pedidoventas.new");
                    } else {
                        var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                        notify({ message: msg, classes: "alert-danger", position: "right" });
                    }
                });
            }
        }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module('qualita.stock')
    .factory('PedidoVentaFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
        '$translatePartialLoader', '$translate', '$state', '$stateParams', 'notify', 'AuthorizationService',
        function ($resource, baseurl, filterFactory, TiposFactory, $http, $translatePartialLoader,
            $translate, $state, $stateParams, notify, AuthorizationService) {

            var PeriodoVenta = $resource(baseurl.getBaseUrl() + '/pedidoventas/:id', { id: '@id' }, {
                update: {
                    method: 'PUT'
                },
                aprobar: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/pedidoventas/aprobar/:id"
                },
                anular: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/pedidoventas/anular/:id"
                },
                distribuir: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/pedidoventas/distribuir/:id"
                },
                cerrar: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/pedidoventas/cerrar/:id"
                },
                solicitarAutorizacion: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/pedidoventas/solicitarautorizacion/:id/:idMotivo"
                },
                revertirCantidad: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/pedidoventas/revertircantidad/:id"
                },
                solicitarAutorizacionBloqueo: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/pedidoventas/solicitarautorizacionbloqueo/:id/:idMotivo"
                },
                desaprobar: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/pedidoventas/desaprobar/:id"
                },
                verificarPrecioSegmento: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/pedidoventas/verificarpreciosegmento/:id"
                }
            });

            return {
                all: function (params, view) {
                    if (params) {
                        params.view = view != undefined ? view : 'PedidoVentaList';
                    } else {
                        params = [];
                        params.view = view != undefined ? view : 'PedidoVentaList';
                    }
                    return PeriodoVenta.query(params);
                },

                get: function (id, view) {
                    return PeriodoVenta.get({ id: id, view: view != undefined ? view : 'base' });
                },

                create: function (attrs) {
                    return new PeriodoVenta(attrs);
                },

                save: function (periodoVenta) {
                    return (periodoVenta.id) ? periodoVenta.$update() : periodoVenta.$save();
                },

                remove: function (periodoVenta) {
                    return periodoVenta.$remove();
                },

                schema: function () {
                    return schema;
                },

                form: function (type) {
                    return type === 'new' ? formNew : formEdit;
                },
                distribuir: function (id) {
                    return PeriodoVenta.distribuir({ id: id }).$promise;
                },
                aprobar: function (id) {
                    return PeriodoVenta.aprobar({ id: id }).$promise;
                },
                anular: function (id) {
                    return PeriodoVenta.anular({ id: id }).$promise;
                },
                cerrar: function (id) {
                    return PeriodoVenta.cerrar({ id: id }).$promise;
                },
                desaprobar: function (id) {
                    return PeriodoVenta.desaprobar({ id: id }).$promise;
                },
                cerrarMultiple: function (ids) {
                    $http({
                        url: baseurl.getBaseUrl() + '/pedidoventas/cerrar',
                        method: "POST",
                        params: { pedidos: ids }
                    }).then(function () {
                        $state.transitionTo($state.current, $stateParams, {
                            reload: true,
                            inherit: false,
                            notify: true
                        });
                    },
                        function (e) {
                            if (e && e.status === 500) {
                                var msg = '';
                                _.forEach(e.data, function (error) {
                                    msg += '\n\n' + error.message + ''
                                });
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        });
                },
                hasPermisos: function (action, estado, canAction) {
                    switch (action) {
                        case "confirm":
                            return AuthorizationService.hasPermission('update_pedidoventas') && estado.codigo == "pv_ingresado"
                        case "anular":
                            return AuthorizationService.hasPermission('update_pedidoventas') && canAction == "Sí";
                        case "cerrar":
                            return AuthorizationService.hasPermission('update_pedidoventas') && canAction == "Sí";
                        case "view":
                            return (AuthorizationService.hasPermission('index_pedidoventas'));
                        case "remove":
                            return AuthorizationService.hasPermission('delete_pedidoventas') && estado.codigo == "pv_ingresado";
                        case "edit":
                            return AuthorizationService.hasPermission('update_pedidoventas') && estado.codigo == "pv_ingresado";
                        case "distribuir":
                            return AuthorizationService.hasPermission('update_pedidoventas') && estado.codigo == "pv_ingresado";
                        case "desaprobar":
                                return AuthorizationService.hasPermission('desaprobar_pedidoventas') && estado.codigo == "pv_aprobado";
                        case "listar_pv_usuario_actual":
                                return AuthorizationService.hasPermission('listar_pv_usuario_actual');
                    }
                },
                solicitarAutorizacion: function (id, idMotivo) {
                    //return PeriodoVenta.solicitarAutorizacion({ id: id }).$promise;
                    return $http({
                        method: "PUT",
                        url: baseurl.getBaseUrl() + "/pedidoventas/solicitarautorizacion/" + id + "/" + idMotivo
                    });
                },

                consolidar: function (ids) {
                    $http({
                        url: baseurl.getBaseUrl() + '/pedidoventas/consolidar',
                        method: "POST",
                        params: { pedidos: ids }
                    }).then(function () {
                        $state.transitionTo($state.current, $stateParams, {
                            reload: true,
                            inherit: false,
                            notify: true
                        });
                        //$route.reload();
                    },
                        function (e) {
                            //console.log('error al marcar como consolidado');
                            //se establecen los errores del backend
                            if (e && e.status === 500) {
                                var msg = '';
                                _.forEach(e.data, function (error) {
                                    msg += '\n\n' + error.message + ''
                                });
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                // guardar en local storage
                            }
                        });
                },
                revertirCantidad: function (id) {
                    return PeriodoVenta.revertirCantidad({ id: id }).$promise;
                },
                verificarVencimiento: function (id) {
                    return $http({
                        url: baseurl.getBaseUrl() + '/pedidoventas/verificarvencimiento/' + id,
                        method: "GET"
                    });
                },
                solicitarAutorizacionBloqueo: function (id) {
                    //return PeriodoVenta.solicitarAutorizacionBloqueo({ id: id }).$promise;
                    return $http({
                        method: "PUT",
                        url: baseurl.getBaseUrl() + "/pedidoventas/solicitarautorizacionbloqueo/" + id + "/" + idMotivo
                    });
                },
                verificarControlStock: function (id) {
                    return $http({
                        url: baseurl.getBaseUrl() + '/pedidoventas/verificarcontrolstock/' + id,
                        method: "GET"
                    });
                },
                verificarPrecio: function (id) {
                    return $http({
                        url: baseurl.getBaseUrl() + '/pedidoventas/verificarprecio/' + id,
                        method: "GET"
                    });
                },
                allForSelect: function(params, view){
                  if (params) {
                    params.view = view || "BaseList";
                  } else {
                    params = [];
                    params.view = view || "BaseList";
                  }
                  return PeriodoVenta.query(params);
                },
                verificarPrecioSegmento: function (id) {
                    return PeriodoVenta.verificarPrecioSegmento({ id: id }).$promise;
                }
            };
        }
    ]);

'use strict';


angular.module('qualita.venta')
    .controller('ConsultaDiferenciaVentaIndexCtrl',
        ["$scope", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "VentasLangFactory", "VentaFactory", "$rootScope", "$filter", "notify", function ($scope, filterFactory, AuthorizationService, ReportTicketFactory,
            $modal, $window, $state, VentasLangFactory, VentaFactory, $rootScope, $filter,
            notify) {

            VentasLangFactory.getTranslations();
            var vm = this;
            vm.cancel = cancel;
            vm.submit = submit;
            vm.submitQuestion = submitQuestion;
            vm.generateReport = generateReport;
            vm.tieneDiferencias = tieneDiferencias;
            vm.diferencia = false;
            vm.submitQuestionPedidoVenta = submitQuestionPedidoVenta;

            vm.canCerrar = null;
            if (AuthorizationService.hasPermission("cerrar_venta")) {
                vm.canCerrar = true;
            } else {
                vm.canCerrar = false;
            }

            vm.venta = VentaFactory.getVenta();
            if (!vm.venta) {
                $state.go("app.main");
            }
            var tipo = vm.venta.tipo;

            // function tieneDiferencias(detalle) {

            //     if (detalle.cantidadRemision != undefined && detalle.cantidadRemision != 0) {
            //         detalle.tieneRecepcion = true;
            //     } else {
            //         detalle.tieneRecepcion = false;
            //     }
            //     if (detalle.cantidadPedidoVenta != undefined && detalle.cantidadPedidoVenta != 0) {
            //         detalle.tineneOC = true;
            //     } else {
            //         detalle.tineneOC = false;
            //     }
            //     if (!detalle.tieneRecepcion && !detalle.tineneOC) {
            //         return false;
            //     } else if (detalle.tieneRecepcion && detalle.tineneOC) {
            //         if (detalle.cantidadRemision != undefined && detalle.servicio == undefined && detalle.cantidadRemision != detalle.cantidadPedidoVenta) {
            //             vm.diferencia = true;
            //             return true;
            //         } else if (detalle.cantidadRemision != undefined && detalle.servicio == undefined && detalle.cantidadRemision != detalle.cantidadComprobante) {
            //             vm.diferencia = true;
            //             return true;
            //         } else if (detalle.cantidadPedidoVenta != detalle.cantidadComprobante) {
            //             vm.diferencia = true;
            //             return true; // cuando es con OC solo verifica la igual entre comprobante y OC
            //             // no recepcion porque no es obligatoria
            //         } else if (detalle.costoPedidoVenta != detalle.costoComprobante) {
            //             vm.diferencia = true;
            //             return true;
            //         }
            //         return false;
            //     } else if (detalle.tieneRecepcion && !detalle.tineneOC) {
            //         if (detalle.servicio == undefined && detalle.cantidadComprobante != detalle.cantidadRemision) {
            //             vm.diferencia = true;
            //             return true;
            //         }
            //         return false;
            //     } else if (!detalle.tieneRecepcion && detalle.tineneOC) {
            //         if (detalle.servicio == undefined && detalle.cantidadComprobante != detalle.cantidadPedidoVenta) {
            //             vm.diferencia = true;
            //             return true;
            //         }
            //         return false;
            //     } else {
            //         return false;
            //     }
            // }

            function tieneDiferencias(detalle) {
                var tieneRemision = false;
                var tienePV = false;
                var detalles = tipo.tipoVentaDetalles;
                _.forEach(tipo.tipoVentaDetalles, function (tipo) {
                    if (tipo.componente.codigo === 'comp_tipo_venta_remision') {
                        tieneRemision = true;
                    }
                    if (tipo.componente.codigo === 'comp_tipo_venta_pedido_venta') {
                        tienePV = true;
                    }
                });


                if (!tieneRemision &&
                    !tienePV &&
                    vm.venta.tipo.tipoVentaDetalles.length == 1
                ) {
                    return false;
                } else if (!tieneRemision &&
                    !tienePV &&
                    vm.venta.tipo.tipoVentaDetalles.length > 1
                ) {
                    return true;
                } else if (tieneRemision && tienePV) {
                    if (
                        detalle.cantidadRemision != undefined &&
                        detalle.servicio == undefined &&
                        detalle.cantidadRemision != detalle.cantidadPedidoVenta && detalle.cantidadPresupuestoVenta != 0
                    ) {
                        vm.diferencia = true;
                        return true;
                    } else if (
                        detalle.cantidadRemision != undefined &&
                        detalle.servicio == undefined &&
                        detalle.cantidadRemision != detalle.cantidadComprobante
                    ) {
                        vm.diferencia = true;
                        return true;
                    } else if (detalle.cantidadPedidoVenta != detalle.cantidadComprobante) {
                        vm.diferencia = true;
                        return true; // cuando es con OC solo verifica la igual entre comprobante y OC
                        // no recepcion porque no es obligatoria
                    } else if (detalle.costoPedidoVenta != detalle.costoComprobante) {
                        vm.diferencia = true;
                        return true;
                    } else if ((detalle.cantidadPedidoVenta != detalle.cantidadPresupuestoVenta || detalle.costoPedidoVenta != detalle.costoPresupuestoVenta) && detalle.cantidadPresupuestoVenta != 0) {
                        vm.diferencia = true;
                        return true;
                    } else
                        return false;
                } else if (tieneRemision && !tienePV) {
                    if (
                        detalle.servicio == undefined &&
                        detalle.cantidadComprobante != detalle.cantidadRemision
                    ) {
                        vm.diferencia = true;
                        return true;
                    }
                    return false;
                    // Cuando tiene Pedido Venta y Comprobante
                } else if (!tieneRemision && tienePV) {
                    if (
                        detalle.servicio == undefined &&
                        detalle.cantidadComprobante != detalle.cantidadPedidoVenta
                    ) {
                        vm.diferencia = true;
                        return true;
                    } else if (detalle.costoPedidoVenta != detalle.costoComprobante) {
                        vm.diferencia = true;
                        return true;
                    } else if ((detalle.cantidadPedidoVenta != detalle.cantidadPresupuestoVenta || detalle.costoPedidoVenta != detalle.costoPresupuestoVenta) && detalle.cantidadPresupuestoVenta != 0) {
                        vm.diferencia = true;
                        return true;
                    }
                    return false;
                } else {
                    return false;
                }
            }

            $rootScope.isProcessing = false;

            var dateRender = function (data, type, full) {
                if (data) {
                    return moment.utc(data).format('DD/MM/YYYY');
                } else {
                    return "";
                }
            };

            var emptyDataRender = function (data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            var venta = VentaFactory.getVenta();
            console.log(venta);
            vm.venta = venta;
            if (!venta) {
                $state.go("app.main");
            }
            var tipo = venta.tipo;
            var tipoVentaDetalle = _.max(tipo.tipoVentaDetalles, function (detalle) {
                return detalle.orden;
            });
            if (!tipoVentaDetalle) {
                console.log("No tiene permiso para revisar esta seccion");
                $state.go("app.main");
            }
            var isStateEnabled = VentaFactory.checkPermissionForState(tipoVentaDetalle.orden);
            if (!isStateEnabled) {
                //console.log("No tiene permiso para revisar esta seccion");
                $state.go("app.main");
            }


            $scope.totalPre = 0;
            $scope.totalPed = 0;
            $scope.totalComp = 0;

            VentaFactory.getDiferencia(venta.id).then(function (response) {
                console.log("reso "+ response.data)
                $scope.list = response.data;
                _.forEach($scope.list, function (detalle) {
                    $scope.totalPre = $scope.totalPre + detalle.costoPresupuestoVenta;
                    $scope.totalPed = $scope.totalPed + detalle.costoPedidoVenta;
                    $scope.totalComp = $scope.totalComp + detalle.costoComprobante;
                });
            });


            var PedidoVentaFactory = undefined;
            var comprobanteFiltro = undefined;
            var remisionFiltro = undefined;


            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }

            function cancel() {
                $state.go('app.ventas.list');
            }

            function generateReport() {
                var filtersArr = [];
                filtersArr.push('id_venta=' + venta.id);
                filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                filtersArr.push("id_un=" + $rootScope.AuthParams.un.id);
                filtersArr.push("id_s=" + $rootScope.AuthParams.suc.id);

                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('cierreventa', 'pdf', filtersArr);
                $window.open(vm.pdfDownloadURL, '_blank');
                $state.go($state.current, {}, { reload: true });
            }


            function submitQuestion() {

                $scope.tituloModal = $filter("translate")("CERRAR_VENTA_TITLE");
                $scope.mensajeModal = $filter("translate")("CERRAR_VENTA_MESSAGE");
                $scope.si_no = true;
                var modalInstance = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        '</div>' +
                        '<div class="modal-body">{{::mensajeModal}}' +
                        '<hr width="1%">' +
                        '{{::usuarioEliminar}}' +
                        '</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="ok(selectedItemId);">' + $filter('translate')('OK') + '</button>' +
                        '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                        '</div>',
                    scope: $scope
                });

                $scope.cancel = function () {
                    modalInstance.dismiss("cancel");
                };

                $scope.ok = function (itemId) {
                    modalInstance.close();
                    submitQuestionPedidoVenta();
                };
            }

            function submitQuestionPedidoVenta() {

                //console.log("vm.venta",vm.venta);
                if (
                    _.find(vm.venta.tipo.tipoVentaDetalles, function (tcdetalle) {
                        return tcdetalle.componente.codigo == "comp_tipo_venta_pedido_venta";
                    })
                ) {
                    if (!venta.planillaReparto) {
                        $scope.tituloModal = $filter("translate")("CERRAR_PEDIDO_VENTA");
                        $scope.mensajeModal = $filter("translate")("CERRAR_PEDIDO_VENTA_MSG");
                    } else {
                        $scope.tituloModal = $filter("translate")("CERRAR_PEDIDOS_VENTA");
                        $scope.mensajeModal = $filter("translate")("CERRAR_PEDIDOS_VENTA_MSG");
                    }

                    $scope.si_no = true;
                    var modalInstance = $modal.open({
                        template: '<div class="modal-header">' +
                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                            '</div>' +
                            '<div class="modal-body">{{::mensajeModal}}' +
                            '<hr width="1%">' +
                            '{{::usuarioEliminar}}' +
                            '</div>' +
                            '<div class="modal-footer">' +
                            '<button class="btn btn-primary" ng-click="ok(selectedItemId);">' + $filter('translate')('OK') + '</button>' +
                            '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                            '</div>',
                        scope: $scope
                    });

                    $scope.cancel = function () {
                        modalInstance.dismiss("cancel");
                        $scope.cerrarPV = false;
                        submit();
                    };

                    $scope.ok = function (itemId) {
                        $scope.cerrarPV = true;
                        modalInstance.close();
                        submit();
                    };
                } else {
                    $scope.cerrarPV = false;
                    submit();
                }



                /*if (vm.venta.tipo.codigo == 'tipo_oc') {
                    $scope.tituloModal = $filter('translate')('CERRAR_OC_CON_COMPRA');
                    $scope.mensajeModal = $filter('translate')('CERRAR_OC_CON_COMPRA_MSG');
                    $scope.si_no = true;
                    var modalInstance = $modal.open({
                        templateUrl: 'views/directives/confirmation-modal.html',
                        scope: $scope
                    });

                    $scope.cancel = function() {
                        modalInstance.dismiss('cancel');
                        $scope.cerrarOC = false;
                        submit();
                    };

                    $scope.ok = function(itemId) {
                        $scope.cerrarOC = true;
                        modalInstance.close();
                        submit();
                    }
                } else {
                    $scope.cerrarOC = false;
                    submit();
                }*/
            }

            function submit() {
                vm.submited = true;
                if ($scope.CierreVentaForm.$valid) {
                    $rootScope.isProcessing = true;
                    submitCierre().then(function (response) {
                        $state.go('app.ventas.list');
                        return response;
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        console.log("Error al persistir entidad: ");
                        console.log(error);
                        //var msg = $scope.translations.ERROR_CIERRE_IMPORTACION;
                        notify({ message: error.data[0].message, classes: 'alert-danger', position: 'right' });
                    });
                } else {
                    $rootScope.isProcessing = false;
                    console.log(errors);
                    notify({ messageTemplate: errors, classes: 'alert-danger', position: 'right' });
                }
            }

            function submitCierre() {
                //if (vm.diferencia) {
                // CompraFactory.diferencia(CompraFactory.getIdCompra());
                //}
                return VentaFactory.cerrar(VentaFactory.getIdVenta(), $scope.cerrarPV);
            }
        }]);

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.venta')
  .controller('ClienteCargaCtrl', ["$http", "$rootScope", "$location", "$scope", "ClienteCargaFactory", "formFactory", "$window", "baseurl", "usSpinnerService", "notify", function ($http ,$rootScope, $location,$scope, ClienteCargaFactory, formFactory, $window, baseurl, usSpinnerService,notify) {

    $scope.title = 'Cliente';

    $rootScope.isProcessing = false;

    $scope.schema = ClienteCargaFactory.schema();

    $scope.form = ClienteCargaFactory.form();

    $scope.options = formFactory.defaultOptions();

    $scope.resource = 'cliente';

    $scope.model = {};

    $scope.position = 0;

    $scope.submitButton = 'Actualizar datos';

    $scope.formSent = false;

    $scope.startSpin = function(){
        usSpinnerService.spin('spinner-1');
    }

    $scope.stopSpin = function(){
        usSpinnerService.stop('spinner-1');
    }

    $scope.submit = function(form) {

      var flow = ClienteCargaFactory.getFileFormProperty().uploader.flow;
      var flowFile = flow.files[0];
      if (flowFile)
        $scope.model.file = flowFile.name;
        $scope.$broadcast('schemaFormValidate');
        var model = ClienteCargaFactory.create($scope.model);
        $scope.formSent = true;
        $scope.startSpin();

        $rootScope.isProcessing = true;

        ClienteCargaFactory.carga($scope.model.file).then(function(data){
          console.log("succes");
          if(data.file === null || data.file === undefined) {
            ClienteCargaFactory.setData(data);
            $scope.stopSpin();
            $scope.formSent = false;
            //$location.path('/tesaka/errores-csv');
            $location.path('/clientes');
          } else {
              $scope.stopSpin();
              $scope.formSent = false;
              $location.path('/clientes');
          }
        }, function(error){
          $rootScope.isProcessing = false;
          $scope.stopSpin();
		      $scope.formSent = false;
          //console.log('error al importar el archivo',error);
          notify({
            message: error.data[0].message,
            classes: 'alert-danger',
            position: 'right'
          });
        });
    };

    $scope.cancel = function(form) {
      $location.path('/clientes');
    }
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.cuentabancaria
 * @description
 * # cuentabancaria
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('ClienteCargaFactory', ['$resource', 'baseurl','$http', function($resource,  baseurl,$http) {

  	var Cliente= $resource( baseurl.getBaseUrl() + '/cargaclientes/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      },
      cerrar: {
        method: 'POST',
        url: baseurl.getBaseUrl() + "/cargaclientes/carga"
      }
    });

    var schema = {
      type: 'object',
      title: 'Cliente',
      properties: {
        file: {
          type: 'object',
          format: 'fileupload',
          title: 'Archivo'
        }
      },
      required: []
    };

     var fileFormProperty = {
      key: 'file',
      fileName: 'file',
      uploadOptions: {
        imageOnly: false,
        target: baseurl.getBaseUrl() + '/clienteUpload'
      },
      uploader: {}
    };

    var form = [
      fileFormProperty
    ];

    var savedData = {};

    return {
      all: function(params) {
        return Cliente.query(params);
      },

      get: function(id) {
        return Cliente.get({id: id});
      },

      create: function(attrs) {
        return new Cliente(attrs);
      },

      save: function(cliente) {
        return (cliente.id) ? cliente.$update() : cliente.$save();
      },

      remove: function(cliente) {
        return cliente.$remove();
      },

      schema: function(){
        return schema;
      },

      form: function(){
        return form;
      },

      getFileFormProperty: function() {
        return fileFormProperty;
      },

      setData: function set(data) {
        //$window.alert("set");
          savedData = data;
      },

      getData: function get() {
        //$window.alert("get");
          return savedData;
      },
      carga: function(name) {
        return $http({
                url: baseurl.getBaseUrl() + '/cargaclientes/carga', 
                method: "POST",
                params: { filename:name }
              });
      }
    };
  }]);

'use strict';

angular.module('qualita.venta')
    .controller('RemisionFormCtrl', RemisionFormCtrl);

RemisionFormCtrl.$inject = ['$rootScope', '$scope', 'RemisionFactory', 'PedidoVentaFactory', 'ProductosFactory',
    'formFactory', 'TiposFactory', '$location', '$state', 'remisionPrepService',
    '$timeout', 'notify', 'ModelTrimmer', 'filterFactory', 'VentasLangFactory',
    'WatchLocacionChangeFactory', 'UtilFactory', 'ParametrosFactory',
    'ClienteFactory', 'VentaFactory', 'TimbradoEmpresaPuntoEmisionVentaFactory', 'AlmacenFactory',
    'puntoEmisionFactory', 'TipoComprobanteFactory', 'SucursalesFactory', 'cajaFactory', '$filter',
    'PeriodoAdministrativoFactory', "puntoVentaFactory", '$q', 'GrupoProductoAlmacenFactory', 'ProductoAlmacenFactory',
    'CambioPrecioFactory', 'AuthorizationService', 'CotizacionMonedaFactory','ProyectoFactory','ChoferFactory','CamionFactory','ComprobanteVentaFactory','CatalogoFactory',
    'CatalogoDetalleFactory'
];

function RemisionFormCtrl($rootScope, $scope, RemisionFactory, PedidoVentaFactory, ProductosFactory,
    formFactory, TiposFactory, $location, $state, remisionPrepService,
    $timeout, notify, ModelTrimmer, filterFactory, VentasLangFactory,
    WatchLocacionChangeFactory, UtilFactory, ParametrosFactory,
    ClienteFactory, VentaFactory, TimbradoEmpresaPuntoEmisionVentaFactory, AlmacenFactory,
    puntoEmisionFactory, TipoComprobanteFactory, SucursalesFactory, cajaFactory, $filter,
    PeriodoAdministrativoFactory, puntoVentaFactory, $q, GrupoProductoAlmacenFactory, ProductoAlmacenFactory,
    CambioPrecioFactory, AuthorizationService, CotizacionMonedaFactory,ProyectoFactory,ChoferFactory,CamionFactory,ComprobanteVentaFactory,CatalogoFactory,
    CatalogoDetalleFactory) {

    var vm = this;
    activate();
    vm.agregarDetalle = agregarDetalle;
    vm.removeItemFromArray = removeItemFromArray;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.changeProducto = changeProducto;
    vm.puntoEmisionSelected = puntoEmisionSelected;
    vm.tipoComprobanteSelected = tipoComprobanteSelected;
    vm.searchTimbradoPuntoEmisionFilter = searchTimbradoPuntoEmisionFilter;
    vm.remisionGeneraMovimiento = false;
    vm.changeCotizacion = changeCotizacion;
    //vm.changeCondicion = changeCondicion;
    $rootScope.isProcessing = false;
    vm.nroOrden = false;
    vm.nroMov = false;
    vm.totalGeneral = 0;
    vm.calcularTotalDetalle = calcularTotalDetalle;
    vm.changeFecha = changeFecha;
    vm.almacenesConStock = null;
    vm.getProductoStockAlmacen = getProductoStockAlmacen;
    vm.permiteStockNegativo = false;
    vm.changeAlmacen = changeAlmacen;
    vm.changeFechaFutura = changeFechaFutura;
    vm.timbradoSelect = timbradoSelect;
    vm.plusClicked = plusClicked;
    vm.delayRequestProducto = delayRequestProducto;
    vm.timbradosPuntoEmision = null;
    vm.habilitarTimbradoSelect = false;
    vm.totalItemChanged = totalItemChanged;
    vm.changeTipoCambio = changeTipoCambio;
    vm.lockPedidoVenta = false;
    vm.changeUnidad = changeUnidad;
    vm.changeChofer = changeChofer;
    vm.changeCamion = changeCamion;
    vm.changeTipoMotivo= changeTipoMotivo;
    vm.changeSucursalCliente = changeSucursalCliente;

    vm.permisoProductosFueraCatalogo = AuthorizationService.hasPermission("cargar_productos_fuera_catalogo");

    function changeSucursalCliente(){
      if(vm.remision.sucursalCliente.direccion != null){
        vm.remision.direccionDestino = vm.remision.sucursalCliente.direccion;
      }
    }

    function changeTipoCambio() {
        actualizarPreciosDeVentas();
    };

    function actualizarPreciosDeVentas() {
        _.forEach(vm.remision.remisionDetalle, function (elem) {
            if (elem.precioVenta != undefined) {
                changeUnidad(elem);
            }
        });
    }

    function changeTipoMotivo(){
        if (vm.remision.tipoMotivoTraslado.codigo == 'otro') {
            vm.remision.motivoTranslado = undefined;
        } else {
            vm.remision.motivoTranslado = vm.remision.tipoMotivoTraslado.descripcion;
        }
    }

    function changeUnidad(detalle) {
        detalle.precioVenta = '';
        // Seteo de campo  'Cantidad U.M. Principal'
        var contenida = null;
        if (detalle.unidadMedida != null && detalle.unidadMedida.cantidad != null) {
            contenida = detalle.unidadMedida.cantidad;
        }

        if (detalle.cantidad != null && detalle.cantidad > 0 && contenida != null) {
            detalle.cantidadUmPrincipal = detalle.cantidad * contenida;
        } else {
            detalle.cantidadUmPrincipal = detalle.cantidad;
        }
        CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
            if (vm.remision.moneda.codigo == "guaranies") {
                detalle.precioView = precio.data.toString().replace(/\./, ",");
                detalle.precioVenta = parseFloat(detalle.precioView.replace(/,/, "."));
            } else {
                if (vm.remision.cambio) {
                    var precioFinal = precio.data / vm.comprobante.cambio;
                    detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                }
            }

            calcularTotalDetalle(detalle);
        });
    }


    function changeCotizacion(isNew) {
        if (vm.remision.remisionDetalle && vm.remision.remisionDetalle.length > 0) {
            vm.remision.remisionDetalle.map(function (detalle) {
                calcularTotalDetalle(detalle);
            })
        }

        vm.monedaLocal.$promise.then(function (data) {
            if (
                vm.remision.moneda &&
                vm.remision.moneda.descripcion != data[0].valorTipo.descripcion
            ) {
                vm.cotizacionRequerida = true;

                var params = {};
                var fechaFormateada = moment(vm.remision.fecha).format('DD/MM/YYYY');
                params.search = filterFactory
                    .or([
                        filterFactory
                            .and([{
                                path: "monedaDesde.id",
                                equals: vm.remision.moneda.id
                            },
                            {
                                path: "monedaHasta.id",
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: "fecha",
                                like: fechaFormateada
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "monedaDesde.id",
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: "monedaHasta.id",
                                equals: vm.remision.moneda.id
                            },
                            {
                                path: "fecha",
                                like: fechaFormateada
                            }
                            ])
                            .value()
                    ])
                    .value();
                CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                    if (response.length > 0) {
                        //los comprobantes de venta deben utilizar el valor compra. QUAL-11969
                        if (vm.venta.pedidoVenta) {
                            vm.remision.cambio = vm.venta.pedidoVenta.cambio;
                        } else {
                            vm.remision.cambio = response[0].valorCompra;
                        }
                    } else {
                        if (vm.venta.pedidoVenta) {
                            vm.remision.cambio = vm.venta.pedidoVenta.cambio;
                        } else {
                            vm.comprobante.cambio = null;
                        }
                    }
                });
            } else {
                vm.cotizacionRequerida = false;
                vm.remision.cambio = 1;
            }
        });
    }

    function totalItemChanged(detalle) {
        if (detalle.cantidad == undefined) {
            detalle.cantidad = 1;
        }

        detalle.precioVenta = detalle.totalItem / detalle.cantidad;
        detalle.precioVenta = UtilFactory.roundForMoneda(detalle.precioVenta, vm.remision.moneda);

        vm.totalGeneral =
            _.reduce(
                vm.remision.remisionDetalle,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
        vm.totalGeneral = UtilFactory.roundForMoneda(vm.totalGeneral, vm.remision.moneda);

    }

    function delayRequestProducto(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {

            var filterNombre = vm.opcionesProductosDt.staticFilter.search.filters.slice()
            var filterCodigoBarras = vm.opcionesProductosDt.staticFilter.search.filters.slice()
            var filterCodigoInterno = vm.opcionesProductosDt.staticFilter.search.filters.slice()
            
            // Filtro catalogo que se aplicará a todos los conjuntos de filtros
            var filtroCatalogo = {}, filtroConNombre = {};
            if(vm.remision.catalogo && vm.remision.catalogo.id){
                filtroCatalogo = {
                    path: 'catalogoDetalles.catalogo.id',
                    equals: vm.remision.catalogo.id
                };
                filtroConNombre = {
                    path: 'catalogoDetalles.nombreCatalogo',
                    isNull: false
                };
            }

            filterNombre.push(
                {
                    path: "nombre",
                    like: searchValue
                },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                }, {
                path: "locaciones.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            if(vm.remision.catalogo && vm.remision.catalogo.id && !vm.permisoProductosFueraCatalogo){
                filterNombre.push(filtroCatalogo, filtroConNombre);
            }

            filterCodigoBarras.push(
                {
                    path: "codigoBarrasPrincipal.codigoBarras",
                    like: searchValue
                },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                }, {
                path: "locaciones.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            if(vm.remision.catalogo && vm.remision.catalogo.id && !vm.permisoProductosFueraCatalogo){
                filterCodigoBarras.push(filtroCatalogo, filtroConNombre);
            }

            filterCodigoInterno.push(
                {
                    path: "codigo",
                    like: searchValue
                },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                }, {
                path: "locaciones.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            if(vm.remision.catalogo && vm.remision.catalogo.id && !vm.permisoProductosFueraCatalogo){
                filterCodigoInterno.push(filtroCatalogo, filtroConNombre);
            }

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }

            recuperarProducto(userInputFilter)
        } else {
            vm.productos = undefined
        }
    }

    function recuperarProducto(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter);

        vm.productos = ProductosFactory.allForSelect(finalFilter)
    }

    function timbradoSelect() {
        console.log(vm.datoVirtual && vm.remision.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo == 'timbrado_virtual');
    }

    function changeFecha() {
        vm.habilitarTimbradoSelect = false;
        filterTimbrado();
    }

    function changeFechaFutura() {
        if (vm.remision.fechaFuturaEmision != undefined && vm.remision.fechaFuturaEmision != null) {
            var fecha = vm.remision.fecha;
            if (compareDates(vm.remision.fechaFuturaEmision, vm.remision.fecha) < 0) {
                var msg = $filter('translate')('ERROR_NO_FECHA_FUTURA');
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                vm.remision.fechaFuturaEmision = null;
                return
            }
        }
    }

    function compareDates(fecha1, fecha2) {
        var fecha1str = "" + anioReal(fecha1.getYear()) + rellenar(fecha1.getMonth()) + rellenar(fecha1.getDate());
        var fecha2str = "" + anioReal(fecha2.getYear()) + rellenar(fecha2.getMonth()) + rellenar(fecha2.getDate());
        if (fecha1str > fecha2str) return 1;
        if (fecha2str > fecha1str) return -1;
        return 0;
    }

    function anioReal(anio) {
        return 1900 + anio;
    }

    function rellenar(numero) {
        if (numero < 10) return "0" + numero;
        return numero;
    }

    function calcularTotalDetalleOLD() {
        vm.totalGeneral = 0;
        _.forEach(vm.remision.remisionDetalle, function (elem) {

            // Condicion negada del codigo html para ocultar, hide
            if (!((elem.producto.criterioControl.codigo === 'series' || elem.producto.criterioControl.codigo === 'lotes') && elem.grupoProducto == null)) {
                vm.totalGeneral = vm.totalGeneral + elem.cantidad;
            }
        });
    }

    function calcularTotalDetalle(detalle) {

        if (detalle.cantidad == undefined) {
            detalle.cantidad = 0;
            detalle.totalItem = 0;
        }

        if (detalle.precioVenta == undefined) {
            detalle.ultimoPrecio = 0;
            detalle.totalItem = 0;
        }

        if (detalle.descuento == undefined) {
            detalle.descuento = 0
        }

        var totalNeto

        if (detalle.descuento && detalle.descuento > 0) {
            totalNeto = detalle.precioVenta - (detalle.precioVenta * (detalle.descuento / 100))
        } else {
            totalNeto = detalle.precioVenta;
        }

        // Seteo de campo  'Cantidad U.M. Principal'
        var contenida = null;
        if(detalle.unidadMedida != null && detalle.unidadMedida.cantidad != null){
            contenida = detalle.unidadMedida.cantidad;
        }

        if(detalle.cantidad != null && detalle.cantidad > 0 && contenida != null){
            detalle.cantidadUmPrincipal = detalle.cantidad * contenida;
        }else{
            detalle.cantidadUmPrincipal = detalle.cantidad;
        }

        detalle.totalItem = (detalle.cantidad * totalNeto);
        detalle.totalItem = Math.round(detalle.totalItem * 100) / 100;//UtilFactory.roundForMoneda(detalle.totalItem, vm.comprobante.moneda);

        vm.totalGeneral =
            _.reduce(
                vm.remision.remisionDetalle,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
        vm.totalGeneral = UtilFactory.roundForMoneda(vm.totalGeneral, vm.remision.moneda);

    }


    function activate() {

        //console.log("+++++++++++++++++++activate");
        ParametrosFactory.getByCodigo('generacion_factura_electronica').then(function (response) {
            vm.datoVirtual = false;
            if (response.valorTipo.codigo === "si") {
                vm.datoVirtual = true;
            }
        });

        ParametrosFactory.getByCodigo('recuperar_items_pv').then(function (response) {
            vm.recuperarItemsPV = false;
            if (response.valorTipo.codigo === "si") {
                vm.recuperarItemsPV = true;
            }
        });

        ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
          vm.proyectoObligatorio = r.valorTipo.codigo == "si";
        });

        ParametrosFactory.getByCodigo("recuperar_items_comprobante_asociado").then(function (r) {
          vm.recuperarItemsComprobanteAsociado = r.valorTipo.codigo === "si";
        });

        vm.proyectos = ProyectoFactory.all("", "RemisionForm");

        var filtroCamion = {};
        filtroCamion.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();
        var filtroChofer = {};
        filtroChofer.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }, {
            path: 'transportista.id',
            isNotNull: "true"
        }]).value();
        vm.choferes = ChoferFactory.all(filtroChofer, "RemisionForm");
        vm.camiones = CamionFactory.all(filtroCamion, "RemisionForm");

        // TiposFactory.motivoTraslado().then(function (data) {
        //     vm.motivosTraslado = data.data;
        // });
        var filterMotivoTraslado = {
          search: filterFactory.and([{
              path: "tipoTipo.codigo",
              equals: "motivo_traslado"
          }]).value()};
        TiposFactory.all(filterMotivoTraslado).$promise.then(function (response) {
          vm.motivosTraslado = response;
          if ($state.is("app.ventas.proceso.remision.new")) {
            var trasladoVenta = _.find(vm.motivosTraslado, function (motivo) {
              if (motivo.codigo == 'traslado_venta') {
                  return motivo;
              }
            });
            if(trasladoVenta != null){
              vm.remision.tipoMotivoTraslado = trasladoVenta;
              vm.remision.motivoTranslado = trasladoVenta.descripcion;
            }
          }
        });

        TiposFactory.tipoTransporte().then(function (data) {
            vm.tiposTransporte = data.data;
        });

        TiposFactory.tipoVehiculo().then(function (data) {
            vm.tiposVehiculo = data.data;
        });

        TiposFactory.naturalezaTransportista().then(function (data) {
            vm.naturalezas = data.data;
        });

        var promise = ParametrosFactory.getByCodigo('generar_stock_negativo').then(function (response) {
            vm.permiteStockNegativo = response.valorTipo.codigo;
        });

        vm.permisoEditarPrecioVenta = AuthorizationService.hasPermission("update_precio_venta_comp");

        WatchLocacionChangeFactory.killAllWatchers();
        if (vm.watcherAlmacen) {
            vm.watcherAlmacen();
        } //hacemos unwatch de la expresion anterior
        WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcherAlmacen) {
            vm.watcherAlmacen = watcherAlmacen;
            var filterSucursal = {
                search: filterFactory.and([{
                    path: "sucursal.id",
                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                }, {
                    path: "activo",
                    equals: true
                }, {
                  path: "habilitadoVenta",
                  equals: true
              },{
                path: "abreviatura",
                sortAsc: true
            }
                ]).value()
            };
            // vm.depositos = AlmacenFactory.all(filterSucursal);
            AlmacenFactory.all(filterSucursal).$promise.then(function (response) {
              vm.depositos = response;
              if ($state.is("app.ventas.proceso.remision.new")) {
                vm.remision.deposito = vm.depositos[0];
              }
            });
        }, "locacion.id");

        VentasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            vm.curDate = new Date();
            //console.log("VentaFactory",VentaFactory);
            if (!$state.is("app.remision.view")) {
                vm.venta = VentaFactory.getVenta();
                var suc = [];
                for(var i = 0; i < vm.venta.cliente.sucursales.length; i++){
                  if(vm.venta.cliente.sucursales[i].activo === true){
                    suc.push(vm.venta.cliente.sucursales[i])
                  }
                }
                vm.sucursalesCliente = suc;
                var tipoVentaDetalle = _.find(vm.venta.tipo.tipoVentaDetalles, function (elem) {
                    if (elem.componente.codigo === "comp_tipo_venta_remision") {
                        vm.ordenRemision = elem.orden;
                        return elem;
                    }
                });
                if (tipoVentaDetalle.realizaEgresoStock == true) {
                    vm.remisionGeneraMovimiento = true;
                }
                var tipoVentaDetalleComprobante = _.find(vm.venta.tipo.tipoVentaDetalles, function (elem) {
                    if (elem.componente.codigo === "comp_tipo_venta_comprobante") {
                        vm.ordenComprobante = elem.orden;
                        return elem;
                    }
                });

                if (vm.ordenComprobante > vm.ordenRemision) vm.requerirFechaFutura = true;

            }
            //console.log("venta",vm.venta);

            vm.factoryProductos = ProductosFactory;

            var staticFilterTC = {
                search: filterFactory.and([{
                    path: "clase.codigo",
                    equals: "remision"
                },
                {
                    path: "utilizarEnProcesosVenta",
                    equals: true
                },
                {
                    path: "activo",
                    equals: true
                }
                ]).value()
            };
            // vm.tipos = TipoComprobanteFactory.all(staticFilterTC);
            TipoComprobanteFactory.all(staticFilterTC).$promise.then(function (response) {
              vm.tipos = response;
              if ($state.is("app.ventas.proceso.remision.new")) {
                if(vm.tipos.length >= 1){
                  vm.remision.tipoComprobante = vm.tipos[0];
                }
              }
            });
            //console.log("vm.puntosEmision",vm.puntosEmision);
            var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
                'lineaProducto.descripcion', 'marca.descripcion'
            ];

            // TODO agregar locacionFilter
            var staticFilterProductos = {};
            staticFilterProductos.search = filterFactory.and([
            {
                path: 'itemVenta',
                like: 'true'
            },
            {
                path: 'estado.codigo',
                equals: 'activo'
            },
            {
              path: "locaciones.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.id
            }
            ]).value();
            //console.log("staticFilterProductos",staticFilterProductos);
            vm.opcionesProductosDt = {
                'resource': 'productos',
                'title': 'Productos',
                'staticFilter': staticFilterProductos,
                'factory': ProductosFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'view': 'ProductoList',
                'columns': [
                    { 'data': 'codigo', 'title': 'Código', visible: false },
                    { 'data': 'nombre', 'title': translations.NAME },
                    { 'data': 'claseProducto.descripcion', 'title': 'Clase' },
                    { 'data': 'estado.descripcion', 'title': translations.STATUS, 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
                    { 'data': 'lineaProducto.descripcion', 'title': 'Línea' },
                    { 'data': 'marca.descripcion', 'title': 'Marca', 'renderWith': 'emptyRender' },
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc"
            };
            WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
                vm.watcher = watcher;
                //var staticFilterProductos = {};
                //staticFilterProductos.search = filterFactory.and([]).value();
                vm.productos = ProductosFactory.allForSelect(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterProductos));
            }, "locaciones.id");

            // Watcher para catalogo
            $scope.$watch('vm.remision.catalogo.id', function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    if(!vm.permisoProductosFueraCatalogo){
                        updateFilters();
                    }
                }
            });

            // var staticFilter2 = {};
            // staticFilter2.search = filterFactory.and([ {
            //     path: 'claseTimbrado.codigo',
            //     equals: 'timbrado_remision'
            //   }
            // ]).value();
            // vm.timbrados = timbradoEmpresaFactory.all(staticFilter2);
            if ($state.is("app.ventas.proceso.remision.new")) {
                activateNew();
            } else if ($state.is("app.ventas.proceso.remision.edit")) {
                activateEdit();
            } else if ($state.is("app.ventas.proceso.remision.view")) {
                activateView();
            } else {
                activateView();
            }
            vm.form = $scope.RemisionForm;

             // Recuperar parametro "Agregar productos que no se encuentran en el pedido"
             ParametrosFactory.getByCodigo('agregar_productos_fuera_pedido').then(function (parametro) {

              // Si el parametro tiene valor NO, debe procesarse las opciones (solo productos del pedido)
              if (parametro.valor == "No" && vm.venta.pedidoVenta) {

                  // Realizar bloqueos de campos "Agregar" y "Producto"
                  vm.lockPedidoVenta = true;
              }
            });
        });
        vm.monedas = {};
        TiposFactory.monedas().then(function (data) {
            vm.monedas = data.data;
        });

        var params = {};
        params.search = filterFactory
            .single({
                path: "codigo",
                equals: "moneda_local"
            })
            .value();

        var params2 = {};
        params2.search = filterFactory
            .and([{
                path: "activo",
                equals: true
            }])
            .value();
        params.view = "Base";
        params2.view = "Base";

        vm.monedaLocal = ParametrosFactory.all(params);
    }

    function updateFilters() {

        var filtrosBase = [
            {
                path: 'itemVenta',
                like: 'true'
            },
            {
                path: 'estado.codigo',
                equals: 'activo'
            },
            {
                path: 'locaciones.id',
                equals: $rootScope.AuthParams.locacionSeleccionada.id
            }
        ];
    
        // Filtro catálogo que se aplicará a todos los conjuntos de filtros
        if (vm.remision && vm.remision.catalogo && vm.remision.catalogo.id) {
            filtrosBase.push({
                path: 'catalogoDetalles.catalogo.id',
                equals: vm.remision.catalogo.id
            });
        }
    
        // Define los filtros estáticos utilizando filterFactory.and()
        var staticFilterProductos = {};
        staticFilterProductos.search = filterFactory.and(filtrosBase).value();
    
        // Combina los filtros dinámicos con los filtros estáticos
        var combinedFilters = UtilFactory.createNestedFilters(staticFilterProductos);

        // Actualiza las opciones del DataTable
        vm.opcionesProductosDt.staticFilter = staticFilterProductos;
    
        // Asigna los productos filtrados
        vm.productos = ProductosFactory.allForSelect(combinedFilters);
    }

    function activateNew() {
        abortRouteIfNecessary();
        //console.log("++++++++++++++++++++ActivateNew");
        vm.title = vm.translations.NEW_REMISION
        vm.remision = {
            remisionDetalle: [],
        };
        // var trasladoVenta = _.find(vm.motivosTraslado, function (motivo) {
        //   if (motivo.codigo == 'traslado_venta') {
        //       return motivo;
        //   }
        // });
        // if(trasladoVenta != null){
        //   vm.remision.tipoMotivoTraslado = trasladoVenta;
        //   vm.remision.motivoTranslado = trasladoVenta.descripcion;
        // }

        
        if(!vm.permisoProductosFueraCatalogo){
            vm.mensajeBusquedaCatalogo = "Busqueda en catalogo activo del cliente";
        }
        vm.showMessage = false;

        vm.remision.recorridoAproximado = 30;
        checkPermisosForAction("new");
        if (vm.venta.pedidoVenta != undefined && vm.ordenRemision === 2) {
            PedidoVentaFactory.get(vm.venta.pedidoVenta.id, "BaseList").$promise.then(function (pedidoVent) {
                vm.remision.pedidoVenta = pedidoVent;
                vm.remision.remisionDetalle = [];
                vm.remision.sucursalCliente = vm.venta.pedidoVenta.sucursalCliente;
                vm.remision.moneda = vm.venta.pedidoVenta.moneda;
                vm.remision.cambio = vm.venta.pedidoVenta.cambio;
                vm.remision.proyecto = vm.venta.pedidoVenta.proyecto;

                if (vm.recuperarItemsPV) {
                    var pvd;
                    for (pvd in pedidoVent.pedidoVentaDetalle) {
                        if(pedidoVent.pedidoVentaDetalle[pvd].incluir){
                          var contenida = null;
                          var unidadMedidaPrincipal = null;
                          if(pedidoVent.pedidoVentaDetalle[pvd].unidadMedida != null && pedidoVent.pedidoVentaDetalle[pvd].unidadMedida.cantidad != null){
                              contenida = pedidoVent.pedidoVentaDetalle[pvd].unidadMedida.cantidad;
                          }

                          if(pedidoVent.pedidoVentaDetalle[pvd].cantidad != null && pedidoVent.pedidoVentaDetalle[pvd].cantidad > 0 && contenida != null){
                              unidadMedidaPrincipal = pedidoVent.pedidoVentaDetalle[pvd].cantidad * contenida;
                          }else{
                              unidadMedidaPrincipal = pedidoVent.pedidoVentaDetalle[pvd].cantidad;
                          }

                          vm.remision.remisionDetalle.push({
                            producto: pedidoVent.pedidoVentaDetalle[pvd].producto,
                            grupoProducto: (vm.remisionGeneraMovimiento && pedidoVent.pedidoVentaDetalle[pvd].producto.criterioControl.codigo ==
                                "series") || pedidoVent.pedidoVentaDetalle[pvd].producto.criterioControl.codigo == "lotes" ? pedidoVent.pedidoVentaDetalle[pvd].grupoProducto : undefined,
                            unidadMedida: pedidoVent.pedidoVentaDetalle[pvd].unidadMedida,
                            cantidadUmPrincipal: unidadMedidaPrincipal,
                            cantidad: pedidoVent.pedidoVentaDetalle[pvd].cantidad,
                            gruposProducto: pedidoVent.pedidoVentaDetalle[pvd].gruposProducto,
                            numeroOrdenProduccion: pedidoVent.pedidoVentaDetalle[pvd].numeroOrdenProduccion,
                            numeroOrdenCompraCliente: pedidoVent.pedidoVentaDetalle[pvd].numeroOrdenCompraCliente,
                            fechaValidez: pedidoVent.pedidoVentaDetalle[pvd].fechaValidez,
                            //id: pedidoVent.pedidoVentaDetalle[pvd].id,
                            precioVenta: pedidoVent.pedidoVentaDetalle[pvd].precio,
                            totalItem: pedidoVent.pedidoVentaDetalle[pvd].total,
                          });
                        }
                    }


                    vm.calcularTotalDetalle(vm.remision.remisionDetalle);
                }

                //console.log("+++++++cliente",vm.venta.cliente);
                vm.remision.cliente = vm.venta.cliente;
                vm.remision.razonSocial = vm.venta.cliente.nombre;
                vm.remision.numeroDocumento = vm.venta.cliente.numero;
                vm.remision.venta = vm.venta;
                vm.remision.fecha = new Date();
                vm.remision.fechaFuturaEmision = new Date();
                vm.remision.fechaInicio = new Date();
                vm.remision.fechaFin = new Date();

                // Recuperar catalogo activo del cliente
                if(vm.remision.cliente && vm.remision.cliente.id){
                    recuperarCatalogoActivo(vm.remision.cliente.id);
                }

                vm.remision.sucursal = $rootScope.AuthParams.locacionSeleccionada.sucursal.id;
                vm.remision.direccionOrigen = $rootScope.AuthParams.locacionSeleccionada.sucursal.direccion;
                // vm.remision.direccionDestino = vm.venta.cliente.direccion;

                SucursalesFactory.get(vm.remision.sucursal).$promise.then(function (sucursal) {
                    console.log("sucursal", sucursal);
                    vm.remision.sucursal = sucursal;
                    filterTimbrado();
                });


            });
        } else if (vm.venta.pedidoVenta != undefined && vm.ordenRemision === 3) {
            console.log("1")
            PedidoVentaFactory.get(vm.venta.pedidoVenta.id, "BaseList").$promise.then(function (pedidoVent) {
                vm.remision.pedidoVenta = pedidoVent;
                vm.remision.remisionDetalle = [];
                vm.remision.sucursalCliente = vm.venta.pedidoVenta.sucursalCliente;
                vm.remision.moneda = vm.venta.pedidoVenta.moneda;
                vm.remision.cambio = vm.venta.pedidoVenta.cambio;
                vm.remision.proyecto = vm.venta.pedidoVenta.proyecto;

                if (vm.recuperarItemsPV) {
                    var pvd;
                    for (pvd in pedidoVent.pedidoVentaDetalle) {
                      if(pedidoVent.pedidoVentaDetalle[pvd].incluir){
                        var contenida = null;
                        var unidadMedidaPrincipal = null;
                        if(pedidoVent.pedidoVentaDetalle[pvd].unidadMedida != null && pedidoVent.pedidoVentaDetalle[pvd].unidadMedida.cantidad != null){
                            contenida = pedidoVent.pedidoVentaDetalle[pvd].unidadMedida.cantidad;
                        }

                        if(pedidoVent.pedidoVentaDetalle[pvd].cantidad != null && pedidoVent.pedidoVentaDetalle[pvd].cantidad > 0 && contenida != null){
                            unidadMedidaPrincipal = pedidoVent.pedidoVentaDetalle[pvd].cantidad * contenida;
                        }else{
                            unidadMedidaPrincipal = pedidoVent.pedidoVentaDetalle[pvd].cantidad;
                        }
                        vm.remision.remisionDetalle.push({
                            producto: pedidoVent.pedidoVentaDetalle[pvd].producto,
                            grupoProducto: (vm.remisionGeneraMovimiento && pedidoVent.pedidoVentaDetalle[pvd].producto.criterioControl.codigo ==
                                "series") || pedidoVent.pedidoVentaDetalle[pvd].producto.criterioControl.codigo == "lotes" ? pedidoVent.pedidoVentaDetalle[pvd].grupoProducto : undefined,
                            unidadMedida: pedidoVent.pedidoVentaDetalle[pvd].unidadMedida,
                            cantidad: pedidoVent.pedidoVentaDetalle[pvd].cantidad,
                            cantidadUmPrincipal: unidadMedidaPrincipal,
                            gruposProducto: pedidoVent.pedidoVentaDetalle[pvd].gruposProducto,
                            numeroOrdenProduccion: pedidoVent.pedidoVentaDetalle[pvd].numeroOrdenProduccion,
                            numeroOrdenCompraCliente: pedidoVent.pedidoVentaDetalle[pvd].numeroOrdenCompraCliente,
                            fechaValidez: pedidoVent.pedidoVentaDetalle[pvd].fechaValidez,
                           // id: pedidoVent.pedidoVentaDetalle[pvd].id,
                            precioVenta: pedidoVent.pedidoVentaDetalle[pvd].precio,
                            totalItem: pedidoVent.pedidoVentaDetalle[pvd].total,
                        });
                      }
                    }


                    vm.calcularTotalDetalle(vm.remision.remisionDetalle);
                }
                //console.log("+++++++cliente",vm.venta.cliente);
                vm.remision.cliente = vm.venta.cliente;
                vm.remision.razonSocial = vm.venta.cliente.nombre;
                vm.remision.numeroDocumento = vm.venta.cliente.numero;
                vm.remision.venta = vm.venta;
                vm.remision.fecha = new Date();
                vm.remision.fechaFuturaEmision = new Date();
                vm.remision.fechaInicio = new Date();
                vm.remision.fechaFin = new Date();

                // Recuperar catalogo activo del cliente
                if(vm.remision.cliente && vm.remision.cliente.id){
                    recuperarCatalogoActivo(vm.remision.cliente.id);
                }

                vm.remision.sucursal = $rootScope.AuthParams.locacionSeleccionada.sucursal.id;
                vm.remision.direccionOrigen = $rootScope.AuthParams.locacionSeleccionada.sucursal.direccion;
                // vm.remision.direccionDestino = vm.venta.cliente.direccion;

                SucursalesFactory.get(vm.remision.sucursal).$promise.then(function (sucursal) {
                    vm.remision.sucursal = sucursal;
                    filterTimbrado();
                });
            });
        } else {
            //console.log("---------------------cliente",vm.venta.cliente);
            vm.remision.cliente = vm.venta.cliente;
            vm.remision.razonSocial = vm.venta.cliente.nombre;
            vm.remision.numeroDocumento = vm.venta.cliente.numero;
            vm.remision.venta = vm.venta;
            vm.remision.fecha = new Date();
            vm.remision.fechaFuturaEmision = new Date();
            vm.remision.fechaInicio = new Date();
            vm.remision.fechaFin = new Date();

            TiposFactory.monedas().then(function (data) {
                _.forEach(data.data, function (elem) {
                    if (elem.codigo == 'guaranies') {
                        vm.remision.moneda = elem;
                    }
                });
            });

            // Recuperar catalogo activo del cliente
            if(vm.remision.cliente && vm.remision.cliente.id){
                recuperarCatalogoActivo(vm.remision.cliente.id);
            }

            vm.remision.cambio = 1;

            vm.remision.sucursal = $rootScope.AuthParams.locacionSeleccionada.sucursal.id;
            vm.remision.direccionOrigen = $rootScope.AuthParams.locacionSeleccionada.sucursal.direccion;
            // vm.remision.direccionDestino = vm.venta.cliente.direccion;

            SucursalesFactory.get(vm.remision.sucursal).$promise.then(function (sucursal) {

                vm.remision.sucursal = sucursal;
                filterTimbrado();

            });

        }
        getPuntosEmisionVenta();
    }

    function activateView() {
        vm.title = vm.translations.VIEW_REMISION;
        vm.remision = remisionPrepService;
        checkPermisosForAction("view");

        if (vm.remision.timbradoPuntoEmision) {
            TimbradoEmpresaPuntoEmisionVentaFactory.get(vm.remision.timbradoPuntoEmision.id, "TimbradoEmpresaForm").$promise.then(function (puntoEmision) {
                vm.puntoEmisionVentaActual = puntoEmision.puntoEmisionVenta;
                //console.log("AQUI PUNTO EMISION: ", vm.puntoEmisionVentaActual);
            });
        }
        vm.entidadId = vm.remision.id;
        vm.entidad = "Remision";
        vm.view = true;
        //vm.remision.sucursal = vm.remision.sucursal.id;


        vm.remision.fecha = new Date(vm.remision.fecha);
        vm.remision.timbradoPuntoEmision.timbradoEmpresa.fechaDesde = moment(vm.remision.timbradoPuntoEmision.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
        vm.remision.timbradoPuntoEmision.timbradoEmpresa.fechaHasta = moment(vm.remision.timbradoPuntoEmision.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY");

        if (vm.remision.fechaFuturaEmision != null) vm.remision.fechaFuturaEmision = new Date(vm.remision.fechaFuturaEmision);

        if (vm.remision.fechaInicio) {
            vm.remision.fechaInicio = new Date(vm.remision.fechaInicio);
        }

        if (vm.remision.fechaFin) {
            vm.remision.fechaFin = new Date(vm.remision.fechaFin);
        }

        _.forEach(vm.remision.remisionDetalle, function (elem) {
            ProductosFactory.get(elem.producto.id, 'BaseList').$promise.then(function (producto) {
                elem.producto = producto;
            });
            vm.calcularTotalDetalle(elem);
        });

        //getPuntosEmisionVenta();


    }

    function activateEdit() {
        abortRouteIfNecessary();
        vm.title = vm.translations.EDIT_REMISION;
        vm.remision = remisionPrepService;
        checkPermisosForAction("edit", vm.remision.estado);


        if (vm.remision.timbradoPuntoEmision) {
            TimbradoEmpresaPuntoEmisionVentaFactory.get(vm.remision.timbradoPuntoEmision.id, "TimbradoEmpresaForm").$promise.then(function (puntoEmision) {
                vm.puntoEmisionVentaActual = puntoEmision.puntoEmisionVenta;
                filterTimbrado(true);
                //console.log("AQUI PUNTO EMISION: ", vm.puntoEmisionVentaActual);
            });
        }

        vm.entidadId = vm.remision.id;
        vm.entidad = "Remision";

        vm.remision.fecha = new Date(vm.remision.fecha);
        vm.remision.timbradoPuntoEmision.timbradoEmpresa.fechaDesde = moment(vm.remision.timbradoPuntoEmision.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
        vm.remision.timbradoPuntoEmision.timbradoEmpresa.fechaHasta = moment(vm.remision.timbradoPuntoEmision.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY");
        if (vm.remision.fechaFuturaEmision != null) vm.remision.fechaFuturaEmision = new Date(vm.remision.fechaFuturaEmision);

        if (vm.remision.fechaInicio) {
            vm.remision.fechaInicio = new Date(vm.remision.fechaInicio);
        }

        if (vm.remision.fechaFin) {
            vm.remision.fechaFin = new Date(vm.remision.fechaFin);
        }

        //vm.remision.sucursal=vm.remision.sucursal;
        _.forEach(vm.remision.remisionDetalle, function (elem) {
            if ((elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') && !estaEnPV(elem)) {
                ProductosFactory.get(elem.producto.id, 'BaseList').$promise.then(function (producto) {
                    elem.producto = producto;
                    getStockMayorACero(elem);
                });
            }
            vm.calcularTotalDetalle(elem);
        });

        if(!vm.permisoProductosFueraCatalogo){
            vm.mensajeBusquedaCatalogo = "Busqueda en catalogo activo del cliente";
        }

        if(!vm.permisoProductosFueraCatalogo){
            updateFilters();
        }
    }
    vm.searchProductoFilter = function (criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            //console.log(item);
            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0);
        };
    }

    function recuperarCatalogoActivo(idCliente){
        if(idCliente){
          var filtroCliente = {};
          filtroCliente.search = filterFactory.and([
            {
              path: 'cliente.id',
              equals: idCliente
            },{
              path: 'activo',
              equals: true
            }
          ]).value();
          CatalogoFactory.all(filtroCliente, "CatalogoList").$promise.then(function (responseCatalogo) {
            if(responseCatalogo.length > 0) {
              vm.remision.catalogo = responseCatalogo[0];   // Seteo del catalogo (en la remision, a partir del cliente)
            }
          });
        }
    }

    function getStockMayorACero(detalle) {
        var filterGrupo = [];
        var filterNormal = [];
        vm.almacenesConStock = [];

        _.forEach(detalle.producto.gruposProducto, function (grupo) {
            var filter = [{
                path: "grupoProducto.id",
                equals: grupo.id
            },
            {
                path: 'almacen.id',
                equals: vm.remision.deposito.id
            }];
            filterGrupo.push(filterFactory.and(filter).value());
        });

        var filterFinal = {
            search: filterFactory.or(filterGrupo).value()
        }
        GrupoProductoAlmacenFactory.all(filterFinal, 'BaseList').$promise.then(function (response) {
            var grupoConStock = [];
            if (response.length > 0) {
                _.forEach(response, function (elem) {
                    var existente = _.find(vm.almacenesConStock, function (stock) {
                        if (stock.grupoProducto != undefined && elem.grupoProducto.id == stock.grupoProducto.id) {
                            return stock;
                        }
                    });
                    if (existente != undefined && existente != null) {
                        if (elem.dia > existente.dia) {
                            existente.dia = elem.dia;
                            existente.saldoReal = elem.saldoReal;
                        }
                    } else {
                        vm.almacenesConStock.push(elem);
                    }
                });

                _.forEach(detalle.producto.gruposProducto, function (grupo) {
                    var existe = false;
                    _.forEach(vm.almacenesConStock, function (stock) {
                        if (grupo.id == stock.grupoProducto.id && stock.saldoReal > 0) {
                            existe = true;
                        }
                    });
                    if (existe) {
                        grupoConStock.push(grupo);
                    }
                });

            }
            detalle.producto.gruposProducto = grupoConStock;
            if (grupoConStock.length == 0) {
                var msg = $filter('translate')('NO_EXISTE_GRUPO_CON_STOCK') + " (Producto: " + detalle.producto.nombre + ")";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
        });
    }

    function changeAlmacen() {
        if (vm.remisionGeneraMovimiento && vm.remision.remisionDetalle.length >= 1 && vm.remision.remisionDetalle[0].producto != undefined) {
            _.forEach(vm.remision.remisionDetalle, function (elem) {
                if ((elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') && !estaEnPV(elem)) {
                    ProductosFactory.get(elem.producto.id, 'BaseList').$promise.then(function (producto) {
                        elem.producto = producto;
                        elem.grupoProducto = null;
                        getStockMayorACero(elem);

                    });
                }
            });
        }
    }

    function changeChofer(){
      if(vm.remision.chofer.numeroDocumento != null && vm.remision.chofer.numeroDocumento != ""){
        vm.remision.nroDocumentoConductor = vm.remision.chofer.numeroDocumento;
      }else{
        vm.remision.nroDocumentoConductor = "";
      }
      if(vm.remision.chofer.nombresyapellidos != null && vm.remision.chofer.nombresyapellidos != ""){
        vm.remision.conductor = vm.remision.chofer.nombresyapellidos;
      }else{
        vm.remision.chofer.nombresyapellidos = "";
      }
      if(vm.remision.chofer.direccionConductor != null && vm.remision.chofer.direccionConductor != ""){
        vm.remision.direccionConductor = vm.remision.chofer.direccionConductor;
      }else{
        vm.remision.direccionConductor = "";
      }
      if(vm.remision.chofer.transportista != null){
        if(vm.remision.chofer.transportista.nombre != null && vm.remision.chofer.transportista.nombre != ""){
          vm.remision.transportista = vm.remision.chofer.transportista.nombre;
        }else{
          vm.remision.transportista = "";
        }
        if(vm.remision.chofer.transportista.numeroDocumento != null && vm.remision.chofer.transportista.numeroDocumento != ""){
          vm.remision.nroDocumentoTransportista = vm.remision.chofer.transportista.numeroDocumento;
        }else{
          vm.remision.nroDocumentoTransportista = "";
        }
        if(vm.remision.chofer.transportista.direccion != null && vm.remision.chofer.transportista.direccion != ""){
          vm.remision.direccionTransportista = vm.remision.chofer.transportista.direccion;
        }else{
          vm.remision.direccionTransportista = "";
        }
        if(vm.remision.chofer.transportista.tipoDocumento.codigo == 'ruc'){
          var naturaleza = _.find(vm.naturalezas, function (naturaleza) {
            if (naturaleza.codigo == 'contribuyente') {
                return naturaleza;
            }
          });
          vm.remision.naturalezaTransportista = naturaleza;
        }else{
          var naturaleza = _.find(vm.naturalezas, function (naturaleza) {
            if (naturaleza.codigo == 'no_contribuyente') {
                return naturaleza;
            }
          });
          vm.remision.naturalezaTransportista = naturaleza;
        }
      }
    }

    function changeCamion(){
      if(vm.remision.camion.marca != null && vm.remision.camion.marca != null != ""){
        vm.remision.marcaVehiculo = vm.remision.camion.marca;
      }else{
        vm.remision.marcaVehiculo = "";
      }
      if(vm.remision.camion.tipoTransporte != null){
        vm.remision.tipoTransporte = vm.remision.camion.tipoTransporte;
      }else{
        vm.remision.tipoTransporte = null;
      }
      if(vm.remision.camion.tipoVehiculo != null){
        vm.remision.tipoVehiculo = vm.remision.camion.tipoVehiculo;
      }else{
        vm.remision.tipoVehiculo = null;
      }
      if(vm.remision.camion.numeroChapa != null && vm.remision.camion.numeroChapa != null != ""){
        vm.remision.nroMatricula = vm.remision.camion.numeroChapa;
      }else{
        vm.remision.nroMatricula = "";
      }
      if(vm.remision.camion.chofer != null){
        vm.remision.chofer = vm.remision.camion.chofer;
        changeChofer();
      }
      $("#marcaVehiculo").focus()
    }

    function getProductoStockAlmacen(detalle) {
        vm.almacenesConStock = [];
        var filterGrupo = [];
        var filterNormal = [];
        if (vm.remision.remisionDetalle.length >= 1 && vm.remision.remisionDetalle[0].producto != undefined && vm.remision.deposito != undefined && vm.remision.deposito.id != undefined && vm.remisionGeneraMovimiento) {

            _.forEach(vm.remision.remisionDetalle, function (elem) {
                if (elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') {
                    _.forEach(elem.producto.gruposProducto, function (grupo) {
                        var filter = [{
                            path: "grupoProducto.id",
                            equals: grupo.id
                        },
                        {
                            path: 'almacen.id',
                            equals: vm.remision.deposito.id
                        }];
                        filterGrupo.push(filterFactory.and(filter).value());
                    });
                } else {
                    var filter = [{
                        path: "producto.id",
                        equals: elem.producto.id
                    },
                    {
                        path: 'almacen.id',
                        equals: vm.remision.deposito.id
                    }];
                    filterNormal.push(filterFactory.and(filter).value());
                }
            });

            if (filterGrupo.length > 0) {
                var filterFinal = {
                    search: filterFactory.or(filterGrupo).value()
                }
                GrupoProductoAlmacenFactory.all(filterFinal, 'BaseList').$promise.then(function (response) {
                    if (response.length > 0) {
                        _.forEach(response, function (elem) {
                            var existente = _.find(vm.almacenesConStock, function (stock) {
                                if (stock.grupoProducto != undefined && elem.grupoProducto.id == stock.grupoProducto.id) {
                                    return stock;
                                }
                            });
                            if (existente != undefined && existente != null) {
                                if (elem.dia > existente.dia) {
                                    existente.dia = elem.dia;
                                    existente.saldoReal = elem.saldoReal;
                                }
                            } else {
                                vm.almacenesConStock.push(elem);
                            }
                        });
                        if (detalle != undefined && (detalle.producto.criterioControl.codigo == 'series' || detalle.producto.criterioControl.codigo == 'lotes')) {
                            var grupoConStock = [];
                            _.forEach(detalle.producto.gruposProducto, function (grupo) {
                                var existe = false;
                                _.forEach(vm.almacenesConStock, function (stock) {
                                    if (grupo.id == stock.grupoProducto.id && stock.saldoReal > 0) {
                                        existe = true;
                                    }
                                });
                                if (existe) {
                                    grupoConStock.push(grupo);
                                }
                            });
                            detalle.producto.gruposProducto = grupoConStock;
                            if (grupoConStock.length == 0) {
                                var msg = $filter('translate')('NO_EXISTE_GRUPO_CON_STOCK') + " (Producto: " + detalle.producto.nombre + ")";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    }
                });
            }
            if (filterNormal.length > 0) {
                var filterFinal = {
                    search: filterFactory.or(filterNormal).value()
                }
                ProductoAlmacenFactory.all(filterFinal, 'BaseList').$promise.then(function (response) {
                    if (response.length > 0) {
                        _.forEach(response, function (elem) {
                            vm.almacenesConStock.push(elem);
                        });
                    }
                });
            }

        }
    }

    function abortRouteIfNecessary() {
        if (vm.venta.estado.codigo === "venta_cerrada") {
            $state.go("app.main");
        }
    }

    function puntoEmisionSelected() {
        vm.habilitarTimbradoSelect = false;
        filterTimbrado()
    }

    function tipoComprobanteSelected() {
        vm.habilitarTimbradoSelect = false;
        filterTimbrado()
    }

    function showNoPEVMessage() {
        var msg = vm.translations.NO_PEV_CAJA_MESSAGE;
        notify({ message: msg, classes: "alert-danger", position: "right" });
    }

    function getPuntosEmisionVenta() {
        var caja = null;
        _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
            if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                caja = cajas;
            }
        });
        if (caja != null) {
            cajaFactory.getCaja(caja.id, "CajaList").$promise.then(function (caja) {
                puntoVentaFactory.get(caja.puntoEmision.puntoVenta.id, "ConsultaComprobanteVentaForm").$promise.then(function (puntoVenta) {
                    if (puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                        vm.puntoEmisionVentaActual = caja.puntoEmision;
                        puntoEmisionSelected();
                        filterTimbrado();
                    } else {
                        var msg = "La sucursal del sistema no coincide con la sucursal de la caja registrada";
                        notify({ message: msg, classes: "alert-danger", position: "right" });
                    }
                });
            });
        } else {
            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
            notify({ message: msg, classes: "alert-danger", position: "right" });
        }
    }

    function filterTimbrado(edit) {
        if (edit != true) vm.remision.timbradoPuntoEmision = null;

        //vm.remision.timbradoPuntoEmision=undefined;
        if (vm.watcherTimbrado) {
            vm.watcherTimbrado();
        }
        WatchLocacionChangeFactory.executeWatching(function (
            locacionFilter,
            watcherTimbrado
        ) {
            vm.watcherTimbrado = watcherTimbrado;;
            if (vm.remision && vm.remision.fecha && vm.puntoEmisionVentaActual != undefined &&
                vm.puntoEmisionVentaActual.id != undefined && vm.remision.tipoComprobante) {

                vm.habilitarTimbradoSelect = true;

                var staticFilter2 = {};
                var filtersArray = [{
                    path: "timbradoEmpresa.fechaDesde",
                    equalOrBefore: moment(vm.remision.fecha).format("DD/MM/YYYY")
                },
                {
                    path: "timbradoEmpresa.fechaHasta",
                    equalOrAfter: moment(vm.remision.fecha).format("DD/MM/YYYY")
                }
                ];

                filtersArray.push({
                    path: "tipoComprobante.codigo",
                    equals: vm.remision.tipoComprobante.clase.codigo
                })

                filtersArray.push({
                    path: "timbradoEmpresa.activo",
                    equals: true
                })

                filtersArray.push({
                    path: "timbradoEmpresa.claseTimbrado.codigo",
                    notEquals: "timbrado_manual"
                })


                filtersArray.push({
                    path: "puntoEmisionVenta.id",
                    equals: vm.puntoEmisionVentaActual.id
                })

                var extraConditions = [{
                    path: "timbradoEmpresa.sucursal.id",
                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                },
                {
                    path: "timbradoEmpresa.sucursal.id",
                    isNull: true
                }
                ]

                var finalAndFilters = _.map(extraConditions, function (cond) {
                    var arr = [cond]
                    arr = arr.concat(filtersArray)
                    return filterFactory.and(arr).value();
                })

                staticFilter2.search = filterFactory
                    .or(finalAndFilters)
                    .value();

                TimbradoEmpresaPuntoEmisionVentaFactory.all(staticFilter2, "RemisionForm").$promise.then(function (response) {
                    vm.timbradosPuntoEmision = response;

                    _.forEach(vm.timbradosPuntoEmision, function (obj) {
                        obj.timbradoEmpresa.fechaDesde = moment(obj.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
                        obj.timbradoEmpresa.fechaHasta = moment(obj.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY");
                    })

                    if (response.length == 0) {
                        var msg = $filter('translate')('NO_TIMBRADO_FOUND');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    }else if(response.length == 1){
                      vm.remision.timbradoPuntoEmision = vm.timbradosPuntoEmision[0];
                    }
                });

            }
        }, "locacion.id");
    }

    function agregarDetalle() {
        vm.remision.remisionDetalle.push({ id: undefined });
        var rowNumber = vm.remision.remisionDetalle.length - 1;
        $timeout(function () {
            $scope.$broadcast('newItemAdded');
        }, 20);
    }

    function removeItemFromArray(elemento, arreglo) {
        //console.log("elemento",elemento);
        //console.log("arrelgo",arreglo);
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        $timeout(function () {
            $scope.$broadcast('newItemRemoved');
        }, 20);
        calcularTotalDetalle(elemento);
        //getProductoStockAlmacen();
    }

    function changeProducto(detalle) {
        //console.log("changeProducto");
        var isPV = estaEnPV(detalle);

        if (vm.venta != undefined && !isPV && (vm.remision.deposito == undefined || vm.remision.deposito.id == undefined) && vm.remisionGeneraMovimiento) {
            var msg = $filter('translate')('VERIFIQUE_ALMACEN');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            vm.remision.remisionDetalle.pop();
            return;
        }

        detalle.cantidad = null;
        detalle.cantidadView = null;
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        //changeUnidad(detalle);
        detalle.totalItem = 0;
        detalle.grupoProducto = null;

        if (detalle.producto.criterioControl.codigo === "series") {
            detalle.cantidad = 1;
        }
        if (vm.remisionGeneraMovimiento && (detalle.producto.criterioControl.codigo === "series" || detalle.producto.criterioControl.codigo === "lotes") && !isPV) {
            getStockMayorACero(detalle);
        }

        var filters = {
            search: filterFactory.and([{
                path: "producto.id",
                equals: detalle.producto.id
            },{
                path: "catalogo.id",
                equals: vm.remision.catalogo.id

            }]).value()
        }

        CatalogoDetalleFactory.all(filters, "CatalogoDetalleForm").$promise.then(function(responseDetalleCatalogo) {
            //console.log(responseDetalleCatalogo);
            if(responseDetalleCatalogo.length && responseDetalleCatalogo[0].id){
                
                //console.log(responseDetalleCatalogo[0].nombreCatalogo);
                //console.log(responseDetalleCatalogo[0].codigoCatalogo);

                detalle.nombreCatalogo = responseDetalleCatalogo[0].nombreCatalogo;
                detalle.codigoCatalogo = responseDetalleCatalogo[0].codigoCatalogo;
                
            }
        });
    }

    function estaEnPV(detalle) {
        if (vm.venta != undefined && vm.venta.pedidoVenta != undefined) {
            var pedidoVentaDetalle = _.find(vm.venta.pedidoVenta.pedidoVentaDetalle, function (pedido) {
                if (pedido.producto.id === detalle.producto.id) {
                    return pedido;
                }
            });
            if (pedidoVentaDetalle != null) return true;
        }
        return false;
    }

    function evaluatePeriodoAdministrativo(fecha) {
        return $q(function (resolve, reject) {
            var params = {};
            params.search = filterFactory
                .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                }
                ])
                .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {

                if (responsePeriodo.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        });
    }

    function validarStock() {
        if (vm.permiteStockNegativo === 'si') {
            return true;
        } else {
            var almacen = _.filter(vm.almacenesConStock, function (o) { return o.id == vm.fraccionamiento.depositoOrigen.id; });
            if (almacen.length >= 1) {
                return true;
            } else {
                return false;
            }
        }
    }

    function submit() {
        $rootScope.isProcessing = true;
        if ((vm.remision.fechaInicio <= vm.remision.fechaFin) || (vm.remision.fechaInicio == null && vm.remision.fechaFin == null)) {
            vm.submited = true;
            if ($scope.RemisionForm.$valid) {
                evaluatePeriodoAdministrativo(vm.remision.fecha).then(function (response) {
                    if (response) {
                        if (vm.remision.remisionDetalle.length >= 1) {
                            var existeTotal0 = _.filter(vm.remision.remisionDetalle, function (elem) {
                                if (elem.totalItem == 0) return elem;
                            });

                            if (existeTotal0.length > 0 && vm.verPrecio != 'No') {
                                var msg = vm.translations.EXISTE_TOTAL_CERO;
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                vm.submited = false;
                                $rootScope.isProcessing = false;
                                return;
                            }

                            if (!vm.venta.cliente.activo) {
                                var msg = "El cliente no se encuentra en estado Activo.";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                vm.submited = false;
                                $rootScope.isProcessing = false;
                                return;
                            }

                            if (vm.venta.cliente.bloquearVentas) {
                                var msg = "El cliente se encuentra bloqueado para los procesos de ventas.";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                vm.submited = false;
                                $rootScope.isProcessing = false;
                                return;
                            }

                            vm.remision.cliente = vm.venta.cliente;
                            vm.remision.remisionDetalle = _.filter(vm.remision.remisionDetalle, function (elem) {
                                return elem.producto;
                            });
                            // se quita el atributo grupo de los detalles cuyos productos son de tipo normal
                            vm.remision.remisionDetalle = _.forEach(vm.remision.remisionDetalle, function (elem) {
                                if (elem.producto.criterioControl.codigo !== 'series' && elem.producto.criterioControl.codigo !== 'lotes') {
                                    delete elem.grupo;
                                }
                            });

                            var errorSerieRepetida = false;
                            if (vm.remisionGeneraMovimiento) {

                                for (var i = 0; i < vm.remision.remisionDetalle.length; i++) {
                                    var elem = vm.remision.remisionDetalle[i];
                                    var cantRegistros = 0;
                                    if (elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') {
                                        _.forEach(vm.remision.remisionDetalle, function (elem2) {
                                            if ((elem2.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') && elem.producto.id == elem2.producto.id && elem.grupoProducto.id == elem2.grupoProducto.id) {
                                                cantRegistros++;
                                            }
                                        });
                                    }
                                    if (cantRegistros > 1) {
                                        var msg = $filter('translate')('NUMERO_GRUPO_REPETIDO') + " (Producto: " + elem.producto.nombre + ")";
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        errorSerieRepetida = true;
                                        break;
                                    }
                                };

                            }

                            if (!errorSerieRepetida) {
                                submitRemision().then(function (response) {
                                    $state.go('app.ventas.proceso.remision.list');
                                }, function (error) {
                                    $rootScope.isProcessing = false;
                                    var msg = "error"; //vm.translations.SAVE_ERROR_OP_MANUAL_STOCK;

                                    if (error.data[0].message) {
                                        msg = error.data[0].message;
                                    }
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                });
                            } else {
                                $rootScope.isProcessing = false;
                            }

                        } else {
                            var msg = vm.translations.ERROR_NO_DETALLES; //vm.translations.DETAILS_ERROR_OP_MANUAL_STOCK;
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            $rootScope.isProcessing = false;
                        }
                    } else {
                        var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        $rootScope.isProcessing = false;
                    }
                });
            } else {
                $rootScope.isProcessing = false;
                notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
            }
        } else {
            var msg = '';
            msg += '\n\n' + $filter('translate')('INVALID_DATES') + "\n";
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            $rootScope.isProcessing = false;
        }
    }

    function plusClicked(detalle) {
        detalle.mostrarMas = !detalle.mostrarMas;
    }

    function submitRemision() {
        var trimmedModel = ModelTrimmer.trimDetails(vm.remision, ["remisionDetalle"]);
        var trimmedDetalles = _.map(vm.remision.remisionDetalle, function (detalle) {
            return ModelTrimmer.trimDetails(detalle);
        });
        trimmedModel.remisionDetalle = trimmedDetalles;
        trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        var resource = RemisionFactory.create(trimmedModel);
        return RemisionFactory.save(resource);
    }

    function searchTimbradoPuntoEmisionFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return String(item.timbradoEmpresa.numeroTimbrado).toLowerCase().indexOf(criteria.toLowerCase()) > -1;

        };
    }

    function checkPermisosForAction(action, estado) {
        if (!RemisionFactory.hasPermisos(action, estado)) {
            $state.go("app.main");
        }
    }

    function cancel() {

        if ($state.current.ncyBreadcrumb.parent === "app.ventas.proceso.remision") {
            $state.go('app.ventas.proceso.remision.list');
        } else {
            $state.go("app.remision.list");
        }
    }

    $scope.$watch('vm.remision.comprobanteVenta',function(newValue){
      if (newValue){
        if(vm.recuperarItemsComprobanteAsociado){
          recuperarComprobantes()
        }
      }
    })
    function recuperarComprobantes() {
        console.log("remision ",vm.remision)
      var comprobante;
      vm.remision.remisionDetalle = [];
      for (var cmp in vm.remision.comprobanteVenta) {
        comprobante = vm.remision.comprobanteVenta[cmp];
        vm.remision.proyecto = comprobante.proyecto;
          ComprobanteVentaFactory.get(comprobante.id, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
              comprobante = response;
              for (var cmpDet in comprobante.comprobanteDetalle) {
                  var contenida = null;
                  var unidadMedidaPrincipal = null;
                  if (comprobante.comprobanteDetalle[cmpDet].unidadMedida != null && comprobante.comprobanteDetalle[cmpDet].unidadMedida.cantidad != null) {
                      contenida = comprobante.comprobanteDetalle[cmpDet].unidadMedida.cantidad;
                  }

                  if (comprobante.comprobanteDetalle[cmpDet].cantidad != null && comprobante.comprobanteDetalle[cmpDet].cantidad > 0 && contenida != null) {
                      unidadMedidaPrincipal = comprobante.comprobanteDetalle[cmpDet].cantidad * contenida;
                  } else {
                      unidadMedidaPrincipal = comprobante.comprobanteDetalle[cmpDet].cantidad;
                  }
                  var notCharged = true;
                  for (var i = 0; i < vm.remision.remisionDetalle.length; i++) {
                      if (
                          vm.remision.remisionDetalle[i].producto.codigo == comprobante.comprobanteDetalle[cmpDet].producto.codigo &&
                          vm.remision.remisionDetalle[i].grupoProducto == comprobante.comprobanteDetalle[cmpDet].grupoProducto &&
                          vm.remision.remisionDetalle[i].unidadMedida.id == comprobante.comprobanteDetalle[cmpDet].unidadMedida.id &&
                          vm.remision.remisionDetalle[i].precioVenta == comprobante.comprobanteDetalle[cmpDet].precioVenta &&
                          vm.remision.remisionDetalle[i].numeroOrdenProduccion == comprobante.comprobanteDetalle[cmpDet].numeroOrdenProduccion &&
                          vm.remision.remisionDetalle[i].numeroOrdenCompraCliente == comprobante.comprobanteDetalle[cmpDet].numeroOrdenCompraCliente &&
                          vm.remision.remisionDetalle[i].fechaValidez == comprobante.comprobanteDetalle[cmpDet].fechaValidez
                      ) {
                          vm.remision.remisionDetalle[i].cantidad += comprobante.comprobanteDetalle[cmpDet].cantidad;
                          vm.remision.remisionDetalle[i].cantidadUmPrincipal += unidadMedidaPrincipal;
                          vm.calcularTotalDetalle(vm.remision.remisionDetalle[i])
                          notCharged = false;
                      }
                  }
                  if (notCharged) {
                      vm.remision.remisionDetalle.push({
                          montosImponibles: [{
                              tipoImpuesto: comprobante.comprobanteDetalle[cmpDet].producto.tiposImpuesto[0],
                              porcentaje: comprobante.comprobanteDetalle[cmpDet].producto.tiposImpuesto[0].porcentaje,
                              valor: 0
                          }],
                          producto: comprobante.comprobanteDetalle[cmpDet].producto,
                          grupoProducto: (vm.remisionGeneraMovimiento && comprobante.comprobanteDetalle[cmpDet].producto.criterioControl.codigo === "series")
                              || comprobante.comprobanteDetalle[cmpDet].producto.criterioControl.codigo === "lotes" ? comprobante.comprobanteDetalle[cmpDet].grupoProducto : undefined,
                          unidadMedida: comprobante.comprobanteDetalle[cmpDet].unidadMedida,
                          cantidad: comprobante.comprobanteDetalle[cmpDet].cantidad,
                          cantidadUmPrincipal: unidadMedidaPrincipal,
                          gruposProducto: comprobante.comprobanteDetalle[cmpDet].gruposProducto,
                          numeroOrdenProduccion: comprobante.comprobanteDetalle[cmpDet].numeroOrdenProduccion,
                          numeroOrdenCompraCliente: comprobante.comprobanteDetalle[cmpDet].numeroOrdenCompraCliente,
                          fechaValidez: comprobante.comprobanteDetalle[cmpDet].fechaValidez,
                          precioVenta: comprobante.comprobanteDetalle[cmpDet].precioVenta,
                          totalItem: comprobante.comprobanteDetalle[cmpDet].totalItem,
                      });
                      var lastIndex = vm.remision.remisionDetalle.length - 1;
                      vm.calcularTotalDetalle(vm.remision.remisionDetalle[lastIndex])
                  }
              }
          });
      }
    }

    $scope.resource = 'remisiones';
}

'use strict';


angular.module('qualita.venta')
    .controller('RemisionListCtrl',
        ["$scope", "$rootScope", "RemisionFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "VentasLangFactory", "VentaFactory", "WatchLocacionChangeFactory", "$filter", "cajaFactory", "notify", "$q", "PeriodoAdministrativoFactory", "ParametrosFactory", "$timeout", "CONFIGURATION", "puntoVentaFactory", function ($scope, $rootScope, RemisionFactory, filterFactory, AuthorizationService,
            ReportTicketFactory, $modal, $window, $state, VentasLangFactory, VentaFactory,
            WatchLocacionChangeFactory, $filter, cajaFactory, notify, $q, PeriodoAdministrativoFactory, ParametrosFactory, $timeout, CONFIGURATION,
            puntoVentaFactory) {

            var vm = this;
            var staticFilter = {};
            vm.isProcesoVenta = false;
            $scope.bloqueoConfirmar = false;
            vm.bloqueoAnular = false;
            vm.valorTipo = "";
            ParametrosFactory.getByCodigo('generacion_factura_electronica').then(function (response) {
                vm.valorTipo = response.valorTipo.codigo;
            });

            var proceso = true;
            vm.isProcesoVenta = true;
            var venta = VentaFactory.getVenta();
            localStorage.setItem("flujoVenta", venta.id);
            localStorage.setItem("clienteFlujo", venta.cliente.id);


            // Aqui guardamos el punto de emision (esto para importacion por CSV)
            var caja = null;
            _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                    caja = cajas;
                }
            });
            if (caja != null) {
                cajaFactory.getCaja(caja.id, "CajaList").$promise.then(function (caja) {
                    puntoVentaFactory.get(caja.puntoEmision.puntoVenta.id, "ConsultaComprobanteVentaForm").$promise.then(function (puntoVenta) {
                        if (puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                            //vm.puntoEmisionVentaActual = caja.puntoEmision;

                            // Guardamos el punto de emision con localStorage
                            var objetString = JSON.stringify(caja.puntoEmision);
                            localStorage.setItem("puntoEmisionFlujo", objetString);
                            //console.log("AQUI PUNTO VENTA: ", caja.puntoEmision);

                            //puntoEmisionSelected();
                            //filterTimbrado();
                        } else {
                            var msg = "La sucursal del sistema no coincide con la sucursal de la caja registrada";
                            notify({ message: msg, classes: "alert-danger", position: "right" });
                        }
                    });
                });
            } else {
                var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                notify({ message: msg, classes: "alert-danger", position: "right" });
            }


            vm.tipoVentaDetalleComprobante = _.find(venta.tipo.tipoVentaDetalles, function (
                detalle
            ) {
                return detalle.componente.codigo == "comp_tipo_venta_comprobante";
            });

            vm.tipoVentaDetalleRemision = _.find(venta.tipo.tipoVentaDetalles, function (
                detalle
            ) {
                return detalle.componente.codigo == "comp_tipo_venta_remision";
            });

            var modo = VentaFactory.getModoAcceso();
            if (!venta) {
                $state.go("app.main");
            }
            var isStateEnabled = VentaFactory.checkPermissionForState(0);
            if (!isStateEnabled) {
                console.log("No tiene permiso para revisar esta seccion");
                $state.go("app.main");
            }
            // console.log("Venta: ", VentaFactory.getIdVenta());
            VentaFactory.get(VentaFactory.getIdVenta(), 'VentaList').$promise.then(function (resp) {
                staticFilter = {
                    search: filterFactory.and([{
                        path: 'venta.id',
                        equals: resp.id
                    }]).value()
                };

                VentasLangFactory.getTranslations().then(function (translations) {
                    var defaultColumnOrder = ['id', 'pedidoVenta.id', 'timbradoEmpresa.numeroTimbrado',
                        'pedidoVenta.cliente.nombre', 'deposito.descripcion', 'fecha', 'tipoComprobante.descripcion',
                        'sucursal.nombre', 'puntoEmision.puntoEmision', 'estado.descripcion', 'datoEnvio', 'razonSocial',
                        'numero', 'direccionOrigen', 'direccionDestino', 'fechaInicio', 'fechaFin', 'vehiculo',
                        'nroMatricula', 'transportista', 'nroDocumentoTransportista', 'conductor', 'nroDocumentoConductor',
                        'direccionConductor', 'motivoTranslado', 'timbradoPuntoEmision.puntoEmisionVenta.numero'
                    ];
                    $scope.options = {
                        'staticFilter': staticFilter,
                        'resource': 'remisiones',
                        'title': translations.REMISIONES,
                        'factory': RemisionFactory,
                        'defaultColumnOrder': defaultColumnOrder,
                        'view': 'RemisionList',
                        'columns': [
                            //{'data': 'id', 'title': translations.CODE},
                            { 'data': 'id', 'title': 'Código', 'class': 'dt-center', visible: false },
                            { 'data': 'numero', 'title': translations.NRO_REMISION, 'renderWith': 'emptyRender' },
                            { 'data': 'pedidoVenta.id', 'title': translations.NUMERO_PEDIDO, 'renderWith': 'emptyRender' },
                            { 'data': 'timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado', 'title': translations.NUMERO_TIMBRADO, 'renderWith': 'emptyRender' },
                            { 'data': 'deposito.descripcion', 'title': translations.ALMACEN, 'renderWith': 'emptyRender' },
                            { 'data': 'fecha', 'title': translations.DATE, 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                            { 'data': 'tipoComprobante.descripcion', 'title': translations.TIPO_COMPROBANTE, 'renderWith': 'emptyRender' },
                            { 'data': 'sucursal.nombre', 'title': translations.NOMBRE_SUCURSAL, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'timbradoPuntoEmision.puntoEmisionVenta.numero', 'title': translations.PUNTO_EMISION, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'estado.descripcion', 'title': translations.ESTADO, 'renderWith': 'emptyRender' },
                            { 'data': 'datoEnvio', 'title': translations.DATO_ENVIO, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'razonSocial', 'title': translations.RAZON_SOCIAL, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'numeroDocumento', 'title': translations.NUMERO_DOCUMENTO, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'direccionOrigen', 'title': translations.DIRECCION_ORIGEN, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'direccionDestino', 'title': translations.DIRECCION_DESTINO, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'fechaInicio', 'title': translations.FECHA_INICIO, 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range', visible: false },
                            { 'data': 'fechaFin', 'title': translations.FECHA_FIN, 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range', visible: false },
                            { 'data': 'vehiculo', 'title': translations.VEHICULO, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'nroMatricula', 'title': translations.NRO_MATRICULA, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'transportista', 'title': translations.TRANSPORTISTA, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'nroDocumentoTransportista', 'title': translations.NRO_TRANSPORTISTA, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'conductor', 'title': translations.CONDUCTOR, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'nroDocumentoConductor', 'title': translations.NRO_CONDUCTOR, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'direccionConductor', 'title': translations.DIRECCION_CONDUCTOR, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'motivoTranslado', 'title': translations.MOTIVO_TRANSLADO, 'renderWith': 'emptyRender', visible: false },
                            { 'data': 'reimprimir', 'title': 'reimprimir', 'renderWith': 'emptyRender', visible: false },
                        ],
                        'hasOptions': true,
                        'defaultOrderColumn': 0,
                        'defaultOrderDir': "asc",
                        'hideAddMenu': true,
                        'extraMenuOptions': [{
                            'title': $filter('translate')('NEW'),
                            'icon': 'glyphicon glyphicon-plus',
                            'showCondition': function () {
                                if (modo != 1) {
                                    var permission = vm.fromOutside || (AuthorizationService.hasPermission('create_remisiones') && VentaFactory.getVenta().estado.codigo != "venta_cerrada");
                                    return permission;
                                } else {
                                    return false;
                                }
                            },
                            'action': function () {
                                checkPeriodoAdministrativo(null).then(function (resultado) {
                                    if (resultado == true) {
                                        if (vm.fromOutside) {
                                            console.log("Yendo a app.consultacomprobantes.new");
                                            $state.go("app.ventas.proceso.remision.new");
                                        } else {
                                            console.log("Yendo a app.ventas.proceso.comprobanteventa.new");
                                            var contador = 0;
                                            var caja = null;
                                            _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                                if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                                    caja = cajas;
                                                }
                                            });
                                            if (caja != null) {
                                                cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                                    if (cajaDB != null) {
                                                        if (cajaDB.activo != false) {
                                                            _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                                if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                                    contador++;
                                                                }
                                                            });
                                                        }
                                                        if (contador == 1) {
                                                            $state.go("app.ventas.proceso.remision.new");
                                                        } else {
                                                            if (cajaDB.activo != false) {
                                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                            } else {
                                                                var msg = $filter('translate')('CAJA_DESACTIVADA');
                                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                            }
                                                        }
                                                    } else {
                                                        var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    }
                                                });
                                            } else {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        }
                                    } else if (resultado == false) {
                                        var msg = $filter('translate')('NO_OPEN_PERIODS_NOW');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            }
                        },{
                            'data': $filter('translate')('IMPORTAR_REMISION'),
                            'title': $filter('translate')('IMPORTAR_REMISION_TITLE'),
                            'icon': 'glyphicon glyphicon-download-alt',
                            'showCondition': function () { 
                                if(vm.isProcesoVenta){
                                    return true;
                                }else{
                                    return false;
                                }
                            },
                            'action': function () {
                                $state.go("app.importaremision");
                            }
                        }],
                        'hideEditMenu': true,
                        'hideRemoveMenu': true,
                        'hideViewMenu': true,
                        'extraRowOptions': [{
                            templateToRender: "<button title='" + $filter('translate')('EMITIR_REMISION') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataCustom%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
                            functionName: "aprobar",
                            conditionName: "canAprobar",
                            customAttribute: ["estado", "id", "timbradoPuntoEmision"],
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                if (modo != 1) {
                                    return RemisionFactory.hasPermisos("confirm", estado);
                                } else {
                                    return false;
                                }
                            },
                            functionDef: function (atributos) {
                                if (!$scope.bloqueoConfirmar) {
                                    $scope.bloqueoConfirmar = true;
                                    var itemId = atributos[1];
                                    var timbradoPuntoEmision = atributos[2];

                                    var contador = 0;
                                    var caja = null;
                                    _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                        if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                            caja = cajas;
                                        }
                                    });
                                    if (caja != null) {
                                        cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                            if (cajaDB != null) {
                                                _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                    if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                        contador++;
                                                    }
                                                });
                                                if (contador == 1) {
                                                    if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {


                                                        RemisionFactory.get(itemId, "RemisionList").$promise.then(function (response) {
                                                            checkPeriodoAdministrativo(response.fecha).then(function (checkPeriodo) {
                                                                if (checkPeriodo == true) {
                                                                    var esRemiGene = false;
                                                                    _.forEach(venta.tipo.tipoVentaDetalles, function (det) {
                                                                        if (det.componente.descripcion === "Remisión" && det.realizaEgresoStock == true) {
                                                                            esRemiGene = true;
                                                                        }
                                                                    });

                                                                    if (esRemiGene == true) {
                                                                        $scope.mensajeModal = $filter('translate')('EMITIR_REMISION_MENSAJE');
                                                                    } else {
                                                                        $scope.mensajeModal = $filter('translate')('EMITIR_REMISION_MENSAJE_CORTO');
                                                                    }

                                                                    $scope.tituloModal = $filter('translate')('EMITIR_REMISION');
                                                                    if ((response.tipoComprobante.plantillaComprobanteFormatoEspecial != null && response.tipoComprobante.plantillaComprobanteFormatoEspecial.length != 0)) {
                                                                        $scope.config = "FORMATO_ESTANDAR";
                                                                        var modalInstance = $modal.open({
                                                                            templateUrl: 'qualita-components/ventas/views/remision/confirmation-modal.html',
                                                                            scope: $scope
                                                                        });

                                                                        $scope.cancel = function () {
                                                                            modalInstance.dismiss('cancel');
                                                                        };

                                                                        $scope.ok = function (id, config) {
                                                                            var filtersArr = [];
                                                                            filtersArr.push("idremision=" + itemId);
                                                                            filtersArr.push("config=" + config);
                                                                            filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                                            RemisionFactory.emitir(itemId).then(function (response) {
                                                                                modalInstance.close();
                                                                                if (timbradoPuntoEmision && timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo !== "timbrado_virtual") {
                                                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('remision', 'pdf', filtersArr);
                                                                                    $window.open(vm.pdfDownloadURL, '_blank');
                                                                                }
                                                                                $state.go($state.current, {}, { reload: true });
                                                                            }, function (error) {
                                                                                console.log(error);
                                                                                var mensaje = "<span>";
                                                                                _.forEach(error.data, function (err) {
                                                                                    mensaje = mensaje + err.message + "<br/>";
                                                                                });
                                                                                mensaje = mensaje + "</span>";
                                                                                notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                                            });
                                                                        };
                                                                    } else {
                                                                        var modalInstance = $modal.open({
                                                                            templateUrl: 'views/directives/confirmation-modal.html',
                                                                            scope: $scope
                                                                        });

                                                                        $scope.cancel = function () {
                                                                            modalInstance.dismiss('cancel');
                                                                        };

                                                                        $scope.ok = function (id) {
                                                                            var filtersArr = [];
                                                                            filtersArr.push('idremision=' + itemId);
                                                                            filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                                            RemisionFactory.emitir(itemId).then(function (response) {
                                                                                modalInstance.close();
                                                                                if (timbradoPuntoEmision && timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo !== "timbrado_virtual") {
                                                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('remision', 'pdf', filtersArr);
                                                                                    $window.open(vm.pdfDownloadURL, '_blank');
                                                                                }
                                                                                $state.go($state.current, {}, { reload: true });
                                                                            }, function (error) {
                                                                                console.log(error);
                                                                                var mensaje = "<span>";
                                                                                _.forEach(error.data, function (err) {
                                                                                    mensaje = mensaje + err.message + "<br/>";
                                                                                });
                                                                                mensaje = mensaje + "</span>";
                                                                                notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                                            });
                                                                        };
                                                                    }
                                                                    vm.isProcesoVenta = false;
                                                                } else {
                                                                    var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                                }
                                                                vm.isProcesoVenta = false;
                                                                $scope.bloqueoConfirmar = false;
                                                            });
                                                        });

                                                    } else {
                                                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                        $scope.bloqueoConfirmar = false;
                                                    }
                                                } else {
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    $scope.bloqueoConfirmar = false;
                                                }
                                            } else {
                                                var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                $scope.bloqueoConfirmar = false;
                                            }
                                        });
                                    } else {
                                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        $scope.bloqueoConfirmar = false;
                                    }
                                }
                            }
                        },
                        {
                            templateToRender: "<button title='" + $filter("translate")("ENVIAR_REMISION_VIRTUAL") +
                                "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='enviarVirtual(<%=dataId%>, <%=dataCustom%>)' ng-class='{ hidden : !enviarComprobante(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-send'></span> </button>",
                            functionName: "enviarVirtual",
                            conditionName: "enviarComprobante",
                            customAttribute: ["estado", "timbradoPuntoEmision", "cdcNuevo"],
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                var timbradoPuntoEmision = atributos[1];
                                if (timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual" &&
                                    estado.codigo === "estados_remision_emitido" && vm.valorTipo === "si") {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                            functionDef: function (itemId, atributos) {
                                var nuevo = atributos[2];

                                if (nuevo != null) {
                                    $scope.mensajeModal = 'Desea generar un nuevo CDC??';
                                    $scope.tituloModal = 'Generación de CDC';
                                    var modalInstance = $modal.open({
                                        templateUrl: "views/directives/confirmation-modal-virtual.html",
                                        scope: $scope
                                    });

                                    $scope.cancel = function () {
                                        RemisionFactory.generarJsonVirtual(itemId, false).then(function (data) {
                                            if (data === "" || data === null) {
                                                var mensaje = "<span>";
                                                mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                                console.log(mensaje);
                                                notify({
                                                    messageTemplate: mensaje,
                                                    classes: "alert-danger",
                                                    position: "right",
                                                    templateUrl: ""
                                                });
                                            } else {
                                                console.log("*** La Respuesta *** --> ", data);
                                                var mensaje = "<span>" + $filter("translate")("DATOS__REMISION_ENVIADOS_SIFEN") + "</span>";
                                                notify({
                                                    messageTemplate: mensaje,
                                                    classes: "alert-success",
                                                    position: "right",
                                                    templateUrl: ""
                                                });

                                                $state.go($state.current, {}, { reload: true });
                                            }
                                        }, function (error) {
                                            console.log(error);
                                            var mensaje = "<span>";
                                            _.forEach(error.data, function (err) {
                                                mensaje = mensaje + err.message + "<br/>";
                                            });
                                            mensaje = mensaje + "</span>";
                                            notify({
                                                messageTemplate: mensaje,
                                                classes: "alert-danger",
                                                position: "right",
                                                templateUrl: ""
                                            });
                                        });
                                    }
                                    $scope.ok = function (id) {
                                        RemisionFactory.generarJsonVirtual(itemId, true).then(function (data) {
                                            if (data === "" || data === null) {
                                                var mensaje = "<span>";
                                                mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                                console.log(mensaje);
                                                notify({
                                                    messageTemplate: mensaje,
                                                    classes: "alert-danger",
                                                    position: "right",
                                                    templateUrl: ""
                                                });
                                            } else {
                                                console.log("*** La Respuesta *** --> ", data);
                                                var mensaje = "<span>" + $filter("translate")("DATOS__REMISION_ENVIADOS_SIFEN") + "</span>";
                                                notify({
                                                    messageTemplate: mensaje,
                                                    classes: "alert-success",
                                                    position: "right",
                                                    templateUrl: ""
                                                });

                                                $state.go($state.current, {}, { reload: true });
                                            }
                                        }, function (error) {
                                            console.log(error);
                                            var mensaje = "<span>";
                                            _.forEach(error.data, function (err) {
                                                mensaje = mensaje + err.message + "<br/>";
                                            });
                                            mensaje = mensaje + "</span>";
                                            notify({
                                                messageTemplate: mensaje,
                                                classes: "alert-danger",
                                                position: "right",
                                                templateUrl: ""
                                            });
                                        });
                                    }
                                }else{
                                    console.log("prueba2 ", nuevo)

                                    RemisionFactory.generarJsonVirtual(itemId, true).then(function (data) {
                                        if (data === "" || data === null) {
                                            var mensaje = "<span>";
                                            mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                            console.log(mensaje);
                                            notify({
                                                messageTemplate: mensaje,
                                                classes: "alert-danger",
                                                position: "right",
                                                templateUrl: ""
                                            });
                                        } else {
                                            console.log("*** La Respuesta *** --> ", data);
                                            var mensaje = "<span>" + $filter("translate")("DATOS__REMISION_ENVIADOS_SIFEN") + "</span>";
                                            notify({
                                                messageTemplate: mensaje,
                                                classes: "alert-success",
                                                position: "right",
                                                templateUrl: ""
                                            });

                                            $state.go($state.current, {}, { reload: true });
                                        }
                                    }, function (error) {
                                        console.log(error);
                                        var mensaje = "<span>";
                                        _.forEach(error.data, function (err) {
                                            mensaje = mensaje + err.message + "<br/>";
                                        });
                                        mensaje = mensaje + "</span>";
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-danger",
                                            position: "right",
                                            templateUrl: ""
                                        });
                                    });
                                }
                            }
                        },
                        {
                            templateToRender: "<button id='inutilizar<%=dataId%>' ng-disabled='disableInutilizar' title='" +
                                $filter("translate")("INUTILIZAR_REMISION") +
                                "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='inutilizar(<%=dataCustom%>)' ng-class='{ hidden : !condicionInutilizar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-floppy-remove'></span> </button>",
                            functionName: "inutilizar",
                            conditionName: "condicionInutilizar",
                            customAttribute: ["estado", "venta", "id", "timbradoPuntoEmision", "hasComprobanteAplicaciones", "hasAplicaciones"],
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                var timbrado = atributos[3];
                                return vm.valorTipo === "si" && VentaFactory.getVenta().estado.codigo != "venta_cerrada" && estado.codigo === "estados_remision_emitido" && timbrado.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual" && RemisionFactory.hasPermisos("inutilizar", estado);
                            },
                            functionDef: function (atributos) {
                                var itemId = atributos[2];
                                //$('#inutilizar' + itemId).attr('disabled', 'disabled');
                                $scope.inutilizar = this;
                                $scope.inutilizar.$$childHead.$parent.disableInutilizar = true;
                                var timbradoPuntoEmision = atributos[3];

                                var contador = 0;
                                var caja = null;

                                if (!vm.fromOutside) {
                                    _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                        if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                            caja = cajas;
                                        }
                                    });
                                    if (caja != null) {
                                        cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                            $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                            if (cajaDB != null) {
                                                $scope.inutilizar.$$childHead.$parent.disableInutilizar = true;
                                                _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                    if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                        contador++;
                                                    }
                                                });
                                                if (contador == 1) {
                                                    if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                        RemisionFactory.get(itemId, "RemisionList").$promise.then(function (
                                                            response
                                                        ) {
                                                            $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                            console.log("AQUI FECHA: ", response.fecha);
                                                            checkPeriodoAdministrativo(response.fecha).then(function (resultado) {
                                                                $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                                if (resultado == true) {
                                                                    $scope.mensajeModal = $filter("translate")("INUTILIZAR_REMISION_Q");
                                                                    $scope.tituloModal = $filter("translate")("INUTILIZAR_REMISION");
                                                                    var modalInstance = $modal.open({
                                                                        templateUrl: "views/directives/confirmation-modal.html",
                                                                        scope: $scope
                                                                    });

                                                                    $scope.cancel = function () {
                                                                        $('#inutilizar' + itemId).removeAttr("disabled");
                                                                        modalInstance.dismiss("cancel");
                                                                    };

                                                                    $scope.ok = function (id) {
                                                                        $scope.inutilizar.$$childHead.$parent.disableInutilizar = true;
                                                                        RemisionFactory.inutilizar(itemId).then(

                                                                            function (response) {
                                                                                if (venta != undefined) {
                                                                                    var ordenRemision = 0, ordenComprobante = 0;

                                                                                    var remisionesEmitidas = _.find(venta.remisiones, function (remision) {
                                                                                        if (remision.estado.codigo === "estados_remision_emitido" || remision.estado.codigo === "estados_remision_aprobado_sifen" || remision.estado.codigo === "estados_remision_enviado_sifen") return remision;
                                                                                    });
                                                                                    if (remisionesEmitidas) {
                                                                                        var tipoRemision = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                                            return tipo.componente.codigo === "comp_tipo_venta_remision";
                                                                                        })
                                                                                        ordenRemision = tipoRemision.orden;
                                                                                    }

                                                                                    var comprobantesEmitidos = _.find(venta.comprobantes, function (comp) {
                                                                                        if ((comp.estado.codigo === "comp_venta_emitido" || comp.estado.codigo === "comp_venta_aprobado_sifen" || comp.estado.codigo === "comp_venta_enviado_sifen") && comp.id !== itemId) return comp;
                                                                                    });

                                                                                    if (comprobantesEmitidos) {
                                                                                        var tipoComprobante = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                                            return tipo.componente.codigo === "comp_tipo_venta_comprobante";
                                                                                        })
                                                                                        ordenComprobante = tipoComprobante.orden;
                                                                                    }

                                                                                    if (remisionesEmitidas) {
                                                                                        if (ordenRemision > ordenComprobante) {
                                                                                            VentaFactory.actualizarEstado('venta_con_remision', venta.id).success(function (data) {
                                                                                                $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                                                                modalInstance.close();
                                                                                                $state.go($state.current, {}, { reload: true });
                                                                                            });
                                                                                        } else {
                                                                                            VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                                $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                                                                modalInstance.close();
                                                                                                $state.go($state.current, {}, { reload: true });
                                                                                            });
                                                                                        }
                                                                                    } else if (comprobantesEmitidos) {
                                                                                        VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                            $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                                                            modalInstance.close();
                                                                                            $state.go($state.current, {}, { reload: true });
                                                                                        });
                                                                                    } else if (venta.pedidoVenta) {
                                                                                        VentaFactory.actualizarEstado('venta_con_pv', venta.id).success(function (data) {
                                                                                            $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                                                            modalInstance.close();
                                                                                            $state.go($state.current, {}, { reload: true });
                                                                                        });
                                                                                    } else {
                                                                                        VentaFactory.actualizarEstado('venta_abierta', venta.id).success(function (data) {
                                                                                            $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                                                            modalInstance.close();
                                                                                            $state.go($state.current, {}, { reload: true });
                                                                                        });
                                                                                    }

                                                                                }
                                                                                //$scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                                                modalInstance.close();
                                                                                $state.go($state.current, {}, { reload: true });
                                                                            },
                                                                            function (error) {
                                                                                $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                                                console.log(error);
                                                                                var mensaje = "<span>";
                                                                                _.forEach(error.data, function (err) {
                                                                                    mensaje = mensaje + err.message + "<br/>";
                                                                                });
                                                                                mensaje = mensaje + "</span>";
                                                                                notify({
                                                                                    messageTemplate: mensaje,
                                                                                    classes: "alert-danger",
                                                                                    position: "right",
                                                                                    templateUrl: ""
                                                                                });
                                                                            }
                                                                        );
                                                                    };

                                                                } else if (resultado == false) {
                                                                    $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                                    var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                                }
                                                            });
                                                        });
                                                    } else {
                                                        $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    }
                                                } else {
                                                    $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                }
                                            } else {
                                                $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                                var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        });
                                    } else {
                                        $scope.inutilizar.$$childHead.$parent.disableInutilizar = false;
                                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                }
                            }
                        },
                        {
                            templateToRender: "<button title='" + $filter("translate")("DESCARGAR KuDE") +
                                "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='descargarKuDE(<%=dataCustom%>)' ng-class='{ hidden : !condicionDescarga(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-save-file'></span> </button>",
                            functionName: "descargarKuDE",
                            conditionName: "condicionDescarga",
                            customAttribute: ["estado", "cdc", "impreso", "id"],
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                var impreso = atributos[2];
                                var idItem = atributos[3];
                                console.log("El estado de impreso es: " + impreso + " y el id es: " + idItem)
                                if ((estado.codigo === "estados_remision_aprobado_sifen" || estado.codigo === "estados_remision_enviado_sifen") && impreso === "No") {
                                    return true;
                                } else {
                                    return false;
                                }

                            },
                            functionDef: function (atributos) {
                                var estado = atributos[0];
                                var cdc = atributos[1];
                                var idItem = atributos[3];
                                var usuario = $rootScope.AuthParams.accesoSistema.usuario.username;
                                ParametrosFactory.getByCodigo('imprimir_kude_sin_aprobar').then(function (response) {
                                    if (response.valorTipo.codigo === "si" && estado.codigo === "estados_remision_enviado_sifen") {
                                        RemisionFactory.get(idItem, "RemisionList").$promise.then(function (response) {
                                            RemisionFactory.actualizarEstadoImpresion(response.id);
                                            $window.open(CONFIGURATION.serverFE + cdc + "&usuarioemision=" + usuario, "_blank");
                                            $state.go($state.current, {}, { reload: true });
                                        })
                                    } else if (response.valorTipo.codigo === "no" && estado.codigo === "estados_remision_enviado_sifen") {
                                        notify({ message: 'No cuenta con el parámetro de imprimir kude sin aprobar', classes: "alert-danger", position: "right" });
                                    } else if (estado.codigo === "estados_remision_aprobado_sifen") {
                                        RemisionFactory.get(idItem, "RemisionList").$promise.then(function (response) {
                                            RemisionFactory.actualizarEstadoImpresion(response.id);
                                            $window.open(CONFIGURATION.serverFE + cdc + "&usuarioemision=" + usuario, "_blank");
                                            $state.go($state.current, {}, { reload: true });
                                        })
                                    }
                                });

                            }
                        },
                        {
                            templateToRender: "<button title='" + $filter("translate")("REIMPRIMIR KuDE") +
                                "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reimprimirKuDE(<%=dataCustom%>)' ng-class='{ hidden : !condicionDescargaReimpresion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-repeat'></span> </button>",
                            functionName: "reimprimirKuDE",
                            conditionName: "condicionDescargaReimpresion",
                            customAttribute: ["estado", "cdc", "impreso"],
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                var impreso = atributos[2];
                                if ((estado.codigo === "estados_remision_aprobado_sifen" || estado.codigo === "estados_remision_enviado_sifen") && impreso === "Sí") {
                                    return true;
                                } else {
                                    return false;
                                }

                            },
                            functionDef: function (atributos) {
                                var estado = atributos[0];
                                var cdc = atributos[1];
                                var usuario = $rootScope.AuthParams.accesoSistema.usuario.username;
                                ParametrosFactory.getByCodigo('imprimir_kude_sin_aprobar').then(function (response) {
                                    if (response.valorTipo.codigo === "si" && estado.codigo === "estados_remision_enviado_sifen") {
                                        $window.open(CONFIGURATION.serverFE + cdc + "&reimpreso=true" + "&usuarioemision=" + usuario, "_blank");
                                        $state.go($state.current, {}, { reload: true });
                                    } else if (response.valorTipo.codigo === "no" && estado.codigo === "estados_remision_enviado_sifen") {
                                        notify({ message: 'No cuenta con el parámetro de imprimir kude sin aprobar', classes: "alert-danger", position: "right" });
                                    } else if (estado.codigo === "estados_remision_aprobado_sifen") {
                                        $window.open(CONFIGURATION.serverFE + cdc + "&reimpreso=true" + "&usuarioemision=" + usuario, "_blank");
                                        $state.go($state.current, {}, { reload: true });
                                    }
                                });
                            }
                        },
                        {
                            templateToRender: "<button title='" +
                                $filter("translate")("REIMPRIMIR_COMPROBANTE") +
                                "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reimprimir(<%=dataCustom%>)' ng-class='{ hidden : !canReimprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-repeat'></span> </button>",
                            functionName: "reimprimir",
                            conditionName: "canReimprimir",
                            customAttribute: ["estado", "id", "timbradoPuntoEmision", "reimprimir"],
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                var timbradoPuntoEmision = atributos[2];
                                return (
                                    RemisionFactory.hasPermisos("reprint", estado) && timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo !== "timbrado_virtual"
                                );
                            },
                            functionDef: function (atributos) {
                                var reimpreso = atributos[3];

                                (reimpreso === 'Sí') ? reimpreso = true : reimpreso = false;

                                if (!reimpreso || AuthorizationService.hasPermission("reimprimir_remisiones") == true) {

                                    var itemId = atributos[1];
                                    var timbradoPuntoEmision = atributos[2];

                                    var contador = 0;
                                    var caja = null;
                                    _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                        if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                            caja = cajas;
                                        }
                                    });
                                    if (caja != null) {
                                        cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                            if (cajaDB != null) {
                                                _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                    if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                        contador++;
                                                    }
                                                });
                                                if (contador == 1) {
                                                    if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                        RemisionFactory.get(itemId).$promise.then(function (
                                                            response
                                                        ) {
                                                            $scope.mensajeModal = translations.REIMPRIMIR_COMPROBANTE_Q;
                                                            $scope.tituloModal = translations.REIMPRIMIR_COMPROBANTE;
                                                            if ((response.tipoComprobante.plantillaComprobanteFormatoEspecial != null && response.tipoComprobante.plantillaComprobanteFormatoEspecial.length != 0)) {
                                                                $scope.config = "FORMATO_ESTANDAR";
                                                                var modalInstance = $modal.open({
                                                                    templateUrl: 'qualita-components/ventas/views/remision/confirmation-modal.html',
                                                                    scope: $scope
                                                                });

                                                                $scope.cancel = function () {
                                                                    modalInstance.dismiss('cancel');
                                                                };

                                                                $scope.ok = function (id, config) {
                                                                    var filtersArr = [];
                                                                    filtersArr.push("idremision=" + itemId);
                                                                    filtersArr.push("config=" + config);
                                                                    filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                                    modalInstance.close();
                                                                    $state.go($state.current, {}, { reload: true });
                                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                                        "remision",
                                                                        "pdf",
                                                                        filtersArr
                                                                    );
                                                                    $window.open(vm.pdfDownloadURL, "_blank");
                                                                    $state.go($state.current, {}, { reload: true });
                                                                };
                                                            } else {
                                                                var modalInstance = $modal.open({
                                                                    templateUrl: "views/directives/confirmation-modal.html",
                                                                    scope: $scope
                                                                });

                                                                $scope.cancel = function () {
                                                                    modalInstance.dismiss("cancel");
                                                                };

                                                                $scope.ok = function (id) {
                                                                    var filtersArr = [];
                                                                    filtersArr.push("idremision=" + itemId);
                                                                    filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                                    modalInstance.close();
                                                                    $state.go($state.current, {}, { reload: true });
                                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                                        "remision",
                                                                        "pdf",
                                                                        filtersArr
                                                                    );
                                                                    $window.open(vm.pdfDownloadURL, "_blank");
                                                                    $state.go($state.current, {}, { reload: true });
                                                                };
                                                            }
                                                        });
                                                    } else {
                                                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    }
                                                } else {
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                }
                                            } else {
                                                var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        });
                                    } else {
                                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }

                                } else if (reimpreso && AuthorizationService.hasPermission("reimprimir_remisiones") == false) {
                                    var msg = 'El comprobante ya ha sido impreso';
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                                else {
                                    var msg = 'No posee permiso para realizar la reimpresión de comprobantes';
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            templateToRender: "<button id='anular<%=dataId%>' ng-disabled='disableAnular' title='" + $filter('translate')('ANULAR_REMISION') + "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataCustom%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                            functionName: "anular",
                            conditionName: "canAnular",
                            customAttribute: ["estado", "id", "timbradoPuntoEmision"],
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                var timbrado = atributos[2].timbradoEmpresa.claseTimbrado;
                                return RemisionFactory.hasPermisos("anular", estado) &&
                                    VentaFactory.getVenta().estado.codigo != "venta_cerrada" &&
                                    ((timbrado.codigo == "timbrado_virtual" && estado.codigo == "estados_remision_aprobado_sifen") ||
                                        (timbrado.codigo != "timbrado_virtual" && estado.codigo == "estados_remision_emitido"));

                            },
                            functionDef: function (atributos) {
                                var itemId = atributos[1];
                                //$('#anular' + itemId).attr('disabled', 'disabled');
                                $scope.anular = this;
                                $scope.anular.$$childHead.$parent.disableAnular = true;
                                var timbradoPuntoEmision = atributos[2];


                                var contador = 0;
                                var caja = null;
                                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                    if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                        caja = cajas;
                                    }
                                });
                                if (caja != null) {
                                    cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                        if (cajaDB != null) {
                                            $scope.anular.$$childHead.$parent.disableAnular = true;
                                            _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                    contador++;
                                                }
                                            });
                                            if (contador == 1) {
                                                if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                    RemisionFactory.get(itemId, "RemisionList").$promise.then(function (response) {
                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                        checkPeriodoAdministrativo(response.fecha).then(function (checkPeriodo) {
                                                            $scope.anular.$$childHead.$parent.disableAnular = false;
                                                            if (checkPeriodo == true) {
                                                                $scope.mensajeModal = $filter('translate')('ANULAR_REMISION_MENSAJE');

                                                                $scope.tituloModal = $filter('translate')('ANULAR_REMISION');
                                                                var modalInstance = $modal.open({
                                                                    templateUrl: 'views/directives/confirmation-anular-modal.html',
                                                                    scope: $scope
                                                                });

                                                                $scope.cancel = function () {
                                                                    $('#anular' + itemId).removeAttr("disabled");
                                                                    modalInstance.dismiss('cancel');
                                                                };

                                                                $scope.ok = function (id) {
                                                                    $scope.anular.$$childHead.$parent.disableAnular = true;
                                                                    //vm.bloqueoAnular= true;
                                                                    RemisionFactory.anular(itemId).then(function (response) {

                                                                        console.log("ESTADO", resp);

                                                                        var comprobantesEmitidos = _.find(venta.comprobantes, function (comp) {
                                                                            if (comp.estado.codigo === "comp_venta_emitido" || comp.estado.codigo === "comp_venta_aprobado_sifen" || comp.estado.codigo === "comp_venta_enviado_sifen") return comp;
                                                                        });

                                                                        var remisionesEmitidas = _.find(venta.remisiones, function (data) {
                                                                            if ((data.estado.codigo === "estados_remision_emitido" || data.estado.codigo === "estados_remision_aprobado_sifen" || data.estado.codigo === "estados_remision_enviado_sifen") && data.id !== itemId) return data;
                                                                        });

                                                                        if (resp.pedidoVenta === null) {
                                                                            var keepGoing = true;
                                                                            var num = 0;
                                                                            if (vm.tipoVentaDetalleRemision.orden > vm.tipoVentaDetalleComprobante.orden) {
                                                                                if (remisionesEmitidas != undefined) {
                                                                                    VentaFactory.actualizarEstado('venta_con_remision', resp.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else if (comprobantesEmitidos != undefined) {
                                                                                    VentaFactory.actualizarEstado('venta_con_comprobante', resp.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else {
                                                                                    VentaFactory.actualizarEstado('venta_abierta', venta.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                }
                                                                            } else {
                                                                                if (comprobantesEmitidos != undefined) {
                                                                                    VentaFactory.actualizarEstado('venta_con_comprobante', resp.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else if (remisionesEmitidas != undefined) {
                                                                                    VentaFactory.actualizarEstado('venta_con_remision', resp.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else {
                                                                                    VentaFactory.actualizarEstado('venta_abierta', venta.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                }
                                                                            }

                                                                        } else {
                                                                            if (vm.tipoVentaDetalleRemision.orden > vm.tipoVentaDetalleComprobante.orden) {
                                                                                if (remisionesEmitidas != undefined) {
                                                                                    VentaFactory.actualizarEstado('venta_con_remision', resp.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else if (comprobantesEmitidos != undefined) {
                                                                                    VentaFactory.actualizarEstado('venta_con_comprobante', resp.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else {
                                                                                    VentaFactory.actualizarEstado('venta_con_pv', venta.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                }
                                                                            } else {
                                                                                if (comprobantesEmitidos != undefined) {
                                                                                    VentaFactory.actualizarEstado('venta_con_comprobante', resp.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else if (remisionesEmitidas != undefined) {
                                                                                    VentaFactory.actualizarEstado('venta_con_remision', resp.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else {
                                                                                    VentaFactory.actualizarEstado('venta_con_pv', venta.id).success(function (data) {
                                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                }
                                                                            }
                                                                        }
                                                                        //vm.bloqueoAnular = false;

                                                                    }, function (error) {
                                                                        $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                        console.log(error);
                                                                        var mensaje = "<span>";
                                                                        _.forEach(error.data, function (err) {
                                                                            mensaje = mensaje + err.message + "<br/>";
                                                                        });
                                                                        mensaje = mensaje + "</span>";
                                                                        notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                                    });
                                                                };

                                                            } else {
                                                                $scope.anular.$$childHead.$parent.disableAnular = false;
                                                                var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                            }
                                                        });
                                                    });
                                                } else {
                                                    $scope.anular.$$childHead.$parent.disableAnular = false;
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                }
                                            } else {
                                                $scope.anular.$$childHead.$parent.disableAnular = false;
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            $scope.anular.$$childHead.$parent.disableAnular = false;
                                            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    });
                                } else {
                                    $scope.anular.$$childHead.$parent.disableAnular = false;
                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                            functionName: "ver",
                            functionDef: function (itemId) {
                                if (vm.isProcesoVenta) {
                                    $state.go("app.ventas.proceso.remision.view", { id: itemId });
                                } else {
                                    $state.go("app.ventas.proceso.remision", { id: itemId });
                                }
                            },
                            conditionName: "canVer",
                            conditionDef: function () {
                                return RemisionFactory.hasPermisos("view");
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                            functionName: "editar",
                            customAttribute: ["estado", "id", "timbradoPuntoEmision"],
                            functionDef: function (atributos) {
                                var itemId = atributos[1];
                                var timbradoPuntoEmision = atributos[2];

                                if (vm.isProcesoVenta) {
                                    var contador = 0;
                                    var caja = null;
                                    _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                        if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                            caja = cajas;
                                        }
                                    });
                                    if (caja != null) {
                                        cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                            if (cajaDB != null) {
                                                _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                    if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                        contador++;
                                                    }
                                                });
                                                if (contador == 1) {
                                                    if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                        $state.go("app.ventas.proceso.remision.edit", { id: itemId });
                                                    } else {
                                                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    }
                                                } else {
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                }
                                            } else {
                                                var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        });
                                    } else {
                                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                } else {
                                    $state.go("app.ventas.proceso.remision.edit", { id: itemId });
                                }


                            },
                            conditionName: "canEditar",
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                if (modo != 1) {
                                    return RemisionFactory.hasPermisos("edit", estado);
                                } else {
                                    return false;
                                }
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                                $filter("translate")("DELETE") +
                                "' ng-click='borrar(<%=dataCustom%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                            customAttribute: ["estado", "id", "timbradoPuntoEmision"],
                            conditionName: "canBorrar",
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                if (modo != 1) {
                                    return RemisionFactory.hasPermisos("remove", estado);;
                                } else {
                                    return false;
                                }
                            },
                            functionName: "borrar",
                            functionDef: function (atributos) {
                                var itemId = atributos[1];
                                var timbradoPuntoEmision = atributos[2];

                                var contador = 0;
                                var caja = null;
                                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                    if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                        caja = cajas;
                                    }
                                });
                                if (caja != null) {
                                    cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                        if (cajaDB != null) {
                                            _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                    contador++;
                                                }
                                            });
                                            if (contador == 1) {
                                                if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                    $scope.selectedItemId = itemId;
                                                    $scope.tituloModal = $filter("translate")("DELETE_CONFIRMATION");
                                                    $scope.mensajeModal = $filter("translate")("DELETE_WARNING");
                                                    var modalInstance = $modal.open({
                                                        templateUrl: "views/directives/confirmation-modal.html",
                                                        scope: $scope
                                                    });

                                                    $scope.cancel = function () {
                                                        modalInstance.dismiss("cancel");
                                                    };

                                                    $scope.ok = function (itemId) {
                                                        var model = RemisionFactory.create({ id: itemId });
                                                        RemisionFactory.remove(model).then(
                                                            function () {
                                                                modalInstance.close(itemId);
                                                                $state.go($state.current, {}, { reload: true });
                                                            },
                                                            function (error) {
                                                                modalInstance.dismiss("cancel");
                                                            }
                                                        );
                                                    };
                                                } else {
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                }
                                            } else {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    });
                                } else {
                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }

                        }
                        ]
                    };

                    WatchLocacionChangeFactory.watchWith(staticFilter, $scope.options, "locacion.id");
                });
            });

            /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
            var checkPeriodoAdministrativo = function (fechaComprobante) {
                return $q(function (resolve, reject) {

                    var params = {};
                    var fecha;

                    if (fechaComprobante == null) {
                        fecha = new Date();
                    } else {
                        fecha = fechaComprobante;
                    }

                    params.search = filterFactory
                        .and([{
                            path: "estado.codigo",
                            equals: "per_adm_abierto"
                        },
                        {
                            path: "fechaDesde",
                            equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                        },
                        {
                            path: "fechaHasta",
                            equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                        }
                        ])
                        .value();

                    PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {
                        if (responsePeriodo.length > 0) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    })
                });
            }

            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }

            var getIDs = function () {
                var ids = [];
                _.forEach(vm.options.selection, function (n, key) {
                    if (n)
                        ids.push(key);
                });
                return ids;
            };
        }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module('qualita.stock')
  .factory('RemisionFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
  '$translatePartialLoader', '$translate', 'VentaFactory', 'AuthorizationService',
    function($resource,  baseurl, filterFactory, TiposFactory, $http,
      $translatePartialLoader, $translate, VentaFactory, AuthorizationService) {

  	var Remision= $resource( baseurl.getBaseUrl() + '/remisiones/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      },
      emitir: {
        method: 'PUT',
        url: baseurl.getBaseUrl() + "/remisiones/emitir/:id"
      },
      anular: {
        method: 'PUT',
        url: baseurl.getBaseUrl() + "/remisiones/anular/:id"
      },
      inutilizar: {
        method: "PUT",
        url: baseurl.getBaseUrl() + "/remisiones/inutilizar/:id"
      }
    });

    return {
      all: function(params) {
        return Remision.query(params);
      },

      get: function(id, view) {
        return Remision.get({id: id, view: view!=undefined ? view : 'BaseList'});
      },

      create: function(attrs) {
        return new Remision(attrs);
      },

      save: function(remision) {
        return (remision.id) ? remision.$update() : remision.$save();
      },

      remove: function(remision) {
        return remision.$remove();
      },

      emitir: function(id) {
        return Remision.emitir({id: id}).$promise;
      },
      anular: function(id) {
        return Remision.anular({id: id}).$promise;
      },
      inutilizar: function(id) {
        return Remision.inutilizar({id: id}).$promise;
      },
      
      generarJsonVirtual: function(id, generarCdc) {
        console.log("generar")
        return $http({
            url: baseurl.getBaseUrl() + "/remisiones/generarJsonVirtual/" + id + "?generarCdc=" + generarCdc,
            method: "GET"
        });
      },

      hasPermisos: function(accion, estado, isMovimientoStock) {
        switch(accion) {
          case "new":
            return AuthorizationService.hasPermission('create_remisiones') && VentaFactory.getVenta().estado.codigo != "venta_cerrada";
          case "confirm":
            return AuthorizationService.hasPermission('update_remisiones') && (
              estado.codigo == "estados_remision_ingresado" || (isMovimientoStock && estado.codigo === "ms_confirmado")); // "'ms_confirmado' para remisiones cargadas por movimiento de stock
          case "reprint":
              return AuthorizationService.hasPermission('update_remisiones') && (
                estado.codigo == "estados_remision_emitido");
          case "anular":
            return AuthorizationService.hasPermission('update_remisiones');
          case "inutilizar":
            return AuthorizationService.hasPermission('update_remisiones');
          case "view":
            return AuthorizationService.hasPermission('index_remisiones');
          case "edit":
            return (AuthorizationService.hasPermission('update_remisiones') && estado.codigo == "estados_remision_ingresado");
          case "remove":
            return AuthorizationService.hasPermission('update_remisiones') && estado.codigo == "estados_remision_ingresado";
        }
      },
      actualizarEstadoImpresion: function(id) {
        return $http({
            url: baseurl.getBaseUrl() + "/remisiones/actualizarEstadoImpresion/" +  id,
            method: "GET"
        });
      },
      carga: function(archivo, idSucursalParam, idFlujoParam, idLocacionParam, idTimbradoParam, tienePermisoProductos, idClienteParam) {
        return $http({
            url: baseurl.getBaseUrl() + '/remisiones/importar',
            method: "POST",
            params: { filename: archivo,
                      idSucursal: idSucursalParam,
                      idFlujo: idFlujoParam,
                      idLocacion: idLocacionParam,
                      idTimbrado: idTimbradoParam,
                      permisoCatalogo: tienePermisoProductos,
                      idCliente: idClienteParam
                    }
        });
      }
    };
  }]);

"use strict";

angular
    .module("qualita.venta")
    .controller("ProcesoCobroIndexCtrl", ProcesoCobroIndexCtrl);

ProcesoCobroIndexCtrl.$inject = [
    "AuthorizationService",
    "ProcesoCobroFactory",
    "TiposFactory",
    "PeriodoAdministrativoFactory",
    "filterFactory",
    "$log",
    "$modal",
    "$scope",
    "notify",
    "$state",
    "VentasLangFactory",
    "ParametrosFactory",
    "TiposTipoFactory",
    "WatchLocacionChangeFactory",
    "UtilFactory",
    "$rootScope",
    "cajaFactory",
    "$window",
    "CsvFactory",
    "baseurl",
    "$filter",
    "CobroDetalleFactory",
    "CotizacionMonedaFactory"
];

function ProcesoCobroIndexCtrl(
    AuthorizationService,
    ProcesoCobroFactory,
    TiposFactory,
    PeriodoAdministrativoFactory,
    filterFactory,
    $log,
    $modal,
    $scope,
    notify,
    $state,
    VentasLangFactory,
    ParametrosFactory,
    TiposTipoFactory,
    WatchLocacionChangeFactory,
    UtilFactory,
    $rootScope,
    cajaFactory,
    $window,
    CsvFactory,
    baseurl,
    $filter,
    CobroDetalleFactory,
    CotizacionMonedaFactory
) {
    var vm = this;
    vm.addNewProcesoCobro = addNewProcesoCobro;
    var staticFilter = {};
    vm.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    vm.flujoAbierto = undefined;
    var flujoCobroActual = AuthorizationService.hasPermission("listar_fc_usuario_actual");

    var defaultColumnOrder = [
        "id",
        "estado.descripcion",
        "caja.nombre",
        "usuario.username",
        "fechaHoraInicio",
        "fechaHoraFin",
        "montoBaseEfectivo"
    ];

    var monedaRender = function (data, type, row) {
        var campoMoneda = row.montoBaseEfectivo;

        if (data != undefined) {
            var moneda = "Gs. ";
            if (campoMoneda.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (campoMoneda.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (campoMoneda.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (campoMoneda.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            } else {
                data = parseFloat(data).toFixed(0);
            }
            // TODO hacer esto mismo en todos los renders de moneda
            return (
                moneda +
                data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else {
            return "";
        }
    };
  $scope.$watch('AuthParams.accesoSistema.usuario.id',function(newVal){
   if(newVal){
    VentasLangFactory.getTranslations().then(function (translations) {
        $scope.translations = translations;

        vm.options = {
            resource: "procesocobro",
            // 'staticFilter': filtros,
            title: $scope.translations.PROCESOS_COBRO,
            factory: ProcesoCobroFactory,
            defaultColumnOrder: defaultColumnOrder,
            failedDeleteError: translations.ERROR_COMPRA_DELETE,
            view: "ProcesoCobroList",
            columns: [
                { data: "id", title: translations.CODIGO_PROC_COBRO },
                { data: "estado.descripcion", title: translations.STATUS, 'type': 'combo', 'filterUrl': 'tipos/filtro/estado_procesocobro' },
                { data: "caja.nombre", title: translations.CAJA, renderWith: 'emptyRender' },
                { data: "usuario.username", title: translations.CAJERO },
                {
                    data: "fechaHoraInicio",
                    title: translations.FECHA_INICIO,
                    renderWith: "dateRender",
                    dateFormat: "DD/MM/YYYY",
                    class: "dt-center",
                    type: "date-range"
                },
                {
                    data: "fechaHoraFin",
                    title: translations.FECHA_FIN,
                    renderWith: "dateRender",
                    dateFormat: "DD/MM/YYYY",
                    class: "dt-center",
                    type: "date-range"
                },
                {
                    data: "montoBaseEfectivo",
                    title: translations.MONTO_BASE_EFECTIVO,
                    render: monedaRender,
                    class: "dt-right",
                    type: "number-range"
                }
            ],
            hasOptions: true,
            defaultOrderColumn: 0,
            defaultOrderDir: "desc",
            hideAddMenu: true,
            hideEditMenu: true,
            hideRemoveMenu: true,
            hideViewMenu: true,
            extraMenuOptions: [
                {
                    title: translations.NEW_PROCESO_COBRO,
                    icon: "glyphicon glyphicon-plus",
                    showCondition: function () {
                        var permission = AuthorizationService.hasPermission(
                            "create_procesocobro"
                        );
                        return permission;
                    },
                    action: function () {
                        createProcesoCobro();
                    }
                },
                {
                    'title': 'CSV',
                    'icon': 'glyphicon glyphicon-export',
                    'showCondition': function () { return true; },
                    'action': function () {
                        var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);
                        CsvFactory.csv("procesocobro", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                            .then(function (response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                    }
                }

            ],
            extraRowOptions: [{
                templateToRender: "<button class='btn btn-success' style='margin-right: 5px;'   title=" +
                    translations.EDIT +
                    " ng-click='editarProceso(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                customAttribute: ["estado", "id", "caja"],
                conditionName: "canEditar",
                conditionDef: function (atributos) {
                    var estado = atributos[0];
                    return estado.codigo != "estado_procesocobro_cerrado";
                },
                functionName: "editarProceso",
                functionDef: function (atributos) {
                    editProcesoCobro(atributos);
                }
            },
            {
                templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                    translations.DELETE +
                    "' ng-click='borrar(<%=dataCustom%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                customAttribute: ["estado", "id", "caja"],
                conditionName: "canBorrar",
                conditionDef: function (atributos) {
                    var estado = atributos[0];
                    return estado.codigo === "estado_procesocobro_abierto";
                },
                functionName: "borrar",
                functionDef: function (atributos) {

                    var itemId = atributos[1];
                    var cajaItem = atributos[2];

                    var contador = 0;
                    var caja = null;
                    _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                        if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                            caja = cajas;
                        }
                    });
                    if (caja != null) {
                        cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                            if (cajaDB != null) {
                                _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                    if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                        contador++;
                                    }
                                });
                                if (contador == 1) {
                                    if (cajaDB.id == cajaItem.id) {
                                        $scope.selectedItemId = itemId;
                                        $scope.tituloModal = translations.DELETE_CONFIRMATION;
                                        $scope.mensajeModal = translations.DELETE_WARNING;
                                        var modalInstance = $modal.open({
                                            templateUrl: "views/directives/confirmation-modal.html",
                                            scope: $scope
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss("cancel");
                                        };

                                        $scope.ok = function (itemId) {
                                            var model = ProcesoCobroFactory.create({ id: itemId });
                                            ProcesoCobroFactory.remove(model).then(
                                                function () {
                                                    modalInstance.close(itemId);
                                                    $state.go($state.current, {}, { reload: true });
                                                },
                                                function (error) {
                                                    modalInstance.dismiss("cancel");
                                                    $scope.tituloModal = translations.DELETE_FAILED;
                                                    $scope.mensajeModal = translations.PROCESO_COBRO_FAILED;

                                                    var modalInstance2 = $modal.open({
                                                        templateUrl: "views/directives/information-modal.html",
                                                        scope: $scope
                                                    });
                                                    $scope.cancel = function () {
                                                        modalInstance2.dismiss("cancel");
                                                    };
                                                }
                                            );
                                        };
                                    } else {
                                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR5", { cajaUsuario: cajaDB.nombre, cajaCobro: cajaItem.nombre });
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                } else {
                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            } else {
                                var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        });
                    } else {
                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    }
                }
            },
            {
                templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" +
                    translations.VIEW +
                    "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                conditionName: "canView",
                conditionDef: function () {
                    return AuthorizationService.hasPermission("index_venta");
                },
                functionName: "view",
                functionDef: function (itemId) {
                    redirectProcesoCobro(itemId, true);
                }
            },
            {
              templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;'   title='" +
                  $filter("translate")("REABRIR_FLUJO") +
            "' ng-click='reabrirProcesoCobro(<%=dataId%>)' ng-class='{ hidden : !canReabrir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-folder-open'></span> </button>",
              customAttribute: "estado",
              conditionName: "canReabrir",
              conditionDef: function(estado) {
                  return (
                      AuthorizationService.hasPermission("reabrir_procesocobro") && estado.codigo == "estado_procesocobro_cerrado"
                  );
              },
              functionName: "reabrirProcesoCobro",
              functionDef: function(itemId) {
                  $scope.selectedItemId = itemId;
                  $scope.tituloModal = "Reabrir Proceso";
                  $scope.mensajeModal = "Esta seguro que desea reabrir el proceso cobro ?";
                  var modalInstance = $modal.open({
                      templateUrl: "views/directives/confirmation-modal.html",
                      scope: $scope
                  });

                  $scope.cancel = function() {
                      modalInstance.dismiss("cancel");
                  };

                  $scope.ok = function(itemId) {
                    ProcesoCobroFactory.reabrir(itemId).then(
                          function() {
                            modalInstance.close(itemId);
                            $state.go($state.current, {}, { reload: true });

                          },
                          function(error) {
                              modalInstance.dismiss("cancel");
                              $scope.tituloModal = "Error al reabrir el proceso cobro";
                              $scope.mensajeModal = error.data[0].message + ".";

                              var modalInstance2 = $modal.open({
                                  templateUrl: "views/directives/information-modal.html",
                                  scope: $scope
                              });
                              $scope.cancel = function() {
                                  modalInstance2.dismiss("cancel");
                              };
                          }
                      );
                  };
              }
          }

            ]

        };
       // se realiza el filtrado si el usuario tiene el permiso de listar fc por usuario actual
        if ($state.is("app.procesoscobro.list")) {

          if(flujoCobroActual){
            staticFilter.search = filterFactory.and([
                {
                path: 'id',
                sortDesc: 'true'
                }/*,
                {
                path: 'caja.cajaPos',
                  like: 'false'
                }*/,
                {
                path: 'usuario.id',
                equals: newVal
                }]).value();
            var filter = angular.copy(staticFilter);
            filter.view = "ProcesoCobroList";
            if(vm.isMobile){
              ProcesoCobroFactory.all(filter).$promise.then(function (response) {
                for(var i = 0; i < response.length; i++){
                  if(response[i].estado.codigo === "estado_procesocobro_abierto" || response[i].estado.codigo === "estado_procesocobro_con_cobranza"){
                    var date1 = new Date(response[i].fechaHoraInicio);
                    var date2 = new Date();
                    var diffTime = Math.abs(date2 - date1);
                    var diffDays = parseInt(diffTime / (1000 * 60 * 60 * 24));
                    if(diffDays === 0 && !vm.flujoAbierto){
                      vm.flujoAbierto = response[i];
                    }
                  }
                  var listCobros = response[i].cobros.sort(function(a,b) {
                    return b.id - a.id;
                  });
                }
                if(!vm.flujoAbierto){
                  createProcesoCobro();
                } else {
                  var atributos = [vm.flujoAbierto.estado, vm.flujoAbierto.id, vm.flujoAbierto.caja];
                  editProcesoCobro(atributos);
                }
              });
            }
            }else{
                staticFilter.search = filterFactory.and([
                    {
                    path: 'id',
                    sortDesc: 'true'
                    }/*,
                    {
                      path: 'caja.cajaPos',
                      like: 'false'
                    }*/]).value();
            }

            vm.options.staticFilter = staticFilter;
        }
        WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");
      });
     }
   })

    vm.submitNewCobro = false;

    function createProcesoCobro() {
      var caja = null;
      _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
        if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
          caja = cajas;
        }
      });
      if(caja == null){
        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { usuario: $rootScope.AuthParams.username });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      }else if(caja.cajaPos){
        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR6", { usuario: $rootScope.AuthParams.username });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      } else if (caja != null) {
        cajaFactory.getCaja(caja.id).$promise.then(function (caja) {
          if (caja.puntoEmision.puntoVenta.sucursal.nombre != $rootScope.AuthParams.locacionSeleccionada.sucursal.nombre) {

            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { usuario: $rootScope.AuthParams.username });
            notify({ message: msg, classes: 'alert-danger', position: 'right' });

            return;
          } else {
            if (caja.activo != false) {
              $scope.caja = caja;
              ParametrosFactory.getByCodigo('crear_flujo_misma_caja').then(function (parametro) {
                if (parametro.valorTipo.codigo === 'si') {
                  vm.addNewProcesoCobro();
                } else {
                  //verificar si la caja ya no se encuentra en otro flujo abierto
                  var filterPc = {
                    search: filterFactory
                      .and([
                        {
                          path: "caja.id",
                          equals: $scope.caja.id
                        },
                        {
                          path: "estado.codigo",
                          notEquals: "estado_procesocobro_cerrado"
                        }
                      ])
                      .value()
                  };
                  ProcesoCobroFactory.all(filterPc).$promise.then(function (response) {
                    if (response.length > 0) {
                      var msg = 'La caja tiene otro flujo abierto y no podrá crear uno nuevo';
                      notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    } else {
                      vm.addNewProcesoCobro();
                    }
                  });
                }
              });
            } else {
              var msg = $filter('translate')('CAJA_DESACTIVADA');
              notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
          }
        });
      }
    }
    function editProcesoCobro(atributos) {
      var itemId = atributos[1];
      var cajaItem = atributos[2];
      var contador = 0;
      var caja = null;
      _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
        if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
          caja = cajas;
        }
      });
      if (caja != null) {
        cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
          if (cajaDB != null) {
            _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
              if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                contador++;
              }
            });
            if (contador == 1) {
              if (cajaDB.id == cajaItem.id) {
                if (cajaDB.activo != false) {
                  redirectProcesoCobro(itemId);
                } else {
                  var msg = $filter('translate')('CAJA_DESACTIVADA');
                  notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }
              } else {
                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR5", { cajaUsuario: cajaDB.nombre, cajaCobro: cajaItem.nombre });
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
              }
            } else {
              var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
              notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
          } else {
            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
          }
        });
      } else {
        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      }
    }
    function addNewProcesoCobro() {
        $scope.tituloModal = $scope.translations.NEW_PROCESO_COBRO;
        var staticFilterCaja = {
            search: filterFactory
                .or([{
                    path: "procesosCobro.estado.codigo",
                    notEquals: "estado_procesocobro_abierto"
                },
                {
                    path: "procesosCobro.id",
                    isNull: "true"
                }
                ])
                .value()
        };

        cajaFactory.all(staticFilterCaja).$promise.then(function (response) {
            $scope.cajas = response;
        });

        $scope.usuario = $rootScope.AuthParams.accesoSistema.usuario;

        //recuperar permiso de fecha flujo
        if (AuthorizationService.hasPermission("modificar_fecha_flujo") === true) {
            $scope.cambiarFecha = true;
        } else {
            $scope.cambiarFecha = false;
        }

        $scope.fechaHoraInicio = new Date();

        ParametrosFactory.getByCodigo('recuperar_total_depositar_apertura').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'si') {
                //verificar si existe cambio para la fecha de inicio
                var params = {};
                params.search = filterFactory.and([{
                    path: 'monedaDesde.codigo',
                    equals: 'dolares'
                },
                {
                    path: 'monedaHasta.codigo',
                    equals: 'guaranies'
                },
                {
                    path: 'fecha',
                    like: moment($scope.fechaHoraInicio).format('DD/MM/YYYY')
                }
                ]).value();
                params.view = 'CotizacionMonedaList';
                CotizacionMonedaFactory.all(params).$promise.then(function (response2) {
                    if (response2 && response2[0]) {
                        $scope.cambio = response2[0].valorCompra;

                        $scope.mostrarCampo = true;
                        //obtener el ultimo proceso de cobro para obtener la recaudacion
                        var filterProceso = {
                            search: filterFactory
                                .and([
                                    {
                                        path: "caja.id",
                                        equals: $scope.caja.id
                                    },
                                    {
                                        path: 'id',
                                        sortDesc: true
                                    }
                                ]).value()
                        }
                        ProcesoCobroFactory.all(filterProceso).$promise.then(function (rr) {
                            $scope.recaudacionDepositar = parseFloat(rr[0].recaudacionDepositarCierre).toFixed(0);

                            //obtener los cheques diferidos vencidos a depositar
                            var filterSucursal = {
                                search: filterFactory
                                    .and([
                                        {
                                            path: "cobro.sucursal.id",
                                            equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                                        },
                                        {
                                            path: "cobro.estado.codigo",
                                            notEquals: "estado_cobro_anulado"
                                        },
                                        {
                                            path: 'fechaPagoDiferido',
                                            equalOrBefore: moment($scope.fechaHoraInicio).format('DD/MM/YYYY')
                                        },
                                        {
                                            path: "depositado",
                                            equals: false
                                        },
                                        {
                                            path: "cobro.caja.id.",
                                            equals: $scope.caja.id
                                        },
                                        {
                                            path: "procesoCobroApertura.id",
                                            isNull: "true"
                                        }
                                    ])
                                    .value()
                            };
                            CobroDetalleFactory.all(filterSucursal).$promise.then(function (response) {
                                $scope.chequesDiferidosVencidos = 0;
                                _.forEach(response, function (detalle) {
                                    if (detalle.moneda.codigo == 'dolares') {
                                        $scope.chequesDiferidosVencidos += detalle.importe * $scope.cambio;
                                    } else {
                                        $scope.chequesDiferidosVencidos += detalle.importe;
                                    }
                                });
                                $scope.totalDepositar = parseInt($scope.recaudacionDepositar) + parseInt($scope.chequesDiferidosVencidos);
                            });
                        });
                    } else {
                        $scope.cambio = undefined;
                        $scope.mostrarCampo = true;
                        //obtener el ultimo proceso de cobro para obtener la recaudacion
                        var filterProceso = {
                            search: filterFactory
                                .and([
                                    {
                                        path: "caja.id",
                                        equals: $scope.caja.id
                                    },
                                    {
                                        path: 'id',
                                        sortDesc: true
                                    }
                                ]).value()
                        }
                        ProcesoCobroFactory.all(filterProceso).$promise.then(function (rr) {
                            $scope.recaudacionDepositar = parseFloat(rr[0].recaudacionDepositarCierre).toFixed(0);

                            //obtener los cheques diferidos vencidos a depositar
                            var filterSucursal = {
                                search: filterFactory
                                    .and([
                                        {
                                            path: "cobro.sucursal.id",
                                            equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                                        },
                                        {
                                            path: "cobro.estado.codigo",
                                            notEquals: "estado_cobro_anulado"
                                        },
                                        {
                                            path: 'fechaPagoDiferido',
                                            equalOrBefore: moment($scope.fechaHoraInicio).format('DD/MM/YYYY')
                                        },
                                        {
                                            path: "depositado",
                                            equals: false
                                        },
                                        {
                                            path: "cobro.caja.id.",
                                            equals: $scope.caja.id
                                        },
                                        {
                                            path: "procesoCobroApertura.id",
                                            isNull: "true"
                                        }
                                    ])
                                    .value()
                            };
                            CobroDetalleFactory.all(filterSucursal).$promise.then(function (response) {
                                $scope.chequesDiferidosVencidos = 0;
                                _.forEach(response, function (detalle) {
                                    if (detalle.moneda.codigo == 'dolares') {
                                        $scope.chequesDiferidosVencidos += detalle.importe * 1;
                                    } else {
                                        $scope.chequesDiferidosVencidos += detalle.importe;
                                    }
                                });
                                $scope.totalDepositar = parseInt($scope.recaudacionDepositar) + parseInt($scope.chequesDiferidosVencidos);
                            });
                        });
                    }
                });
            } else {
                $scope.mostrarCampo = false;
                $scope.cambio = 1;
            }
        });

        // buscar periodo que coincida con la fecha actual
        var params = {};
        params.search = filterFactory
            .or([{
                path: "estado.codigo",
                equals: "per_adm_abierto"
            }])
            .value();

        var flagPeriodo = false;

        PeriodoAdministrativoFactory.all(params).$promise.then(function (response) {
            var periodos = response;
            _.forEach(periodos, function (value) {
                var desde = new Date(value.fechaDesde);
                var hasta = new Date(value.fechaHasta);
                var hoy = new Date().setHours(0, 0, 0, 0);

                if (hoy >= desde && hoy <= hasta) {
                    flagPeriodo = true;
                }
            });

            if (flagPeriodo) {
                var modalInstance = $modal.open({
                    templateUrl: "views/directives/procesocobro-modal.html",
                    scope: $scope
                });

                $scope.cancel = function () {
                    modalInstance.dismiss("cancel");
                };

                $scope.ok = function (caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio) {
                    //verificar si existe cambio para la fecha de inicio
                    var params = {};
                    params.search = filterFactory.and([{
                        path: 'monedaDesde.codigo',
                        equals: 'dolares'
                    },
                    {
                        path: 'monedaHasta.codigo',
                        equals: 'guaranies'
                    },
                    {
                        path: 'fecha',
                        like: moment(fecha).format('DD/MM/YYYY')
                    }
                    ]).value();
                    params.view = 'CotizacionMonedaList';
                    CotizacionMonedaFactory.all(params).$promise.then(function (response2) {
                        if (response2 && response2[0]) {
                            cambio = response2[0].valorCompra;
                        } else {
                            cambio = undefined;
                        }


                        vm.submitNewCobro = true;
                        if (cambio != undefined) {
                            ParametrosFactory.getByCodigo('validar_fecha_flujo_cobro').then(function (parametro) {
                                if (parametro.valorTipo.codigo === 'no') {
                                    sendNewProcesoCobro(caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio).then(function (data) {
                                        modalInstance.close();
                                        return ProcesoCobroFactory.get(
                                            data.id,
                                            "BaseList"
                                        ).$promise;
                                    },
                                        function (error) {
                                            vm.submitNewCobro = false;
                                            var msg = error.data[0].message;
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        })
                                        .then(function (data) {
                                            if (data != undefined) {
                                                ProcesoCobroFactory.setIdProcesoCobro(data.id);
                                                ProcesoCobroFactory.setProcesoCobro(data);
                                                redirectProcesoCobro(data.id, false);
                                            }
                                        });
                                } else {
                                    var fechah = new Date();
                                    var mesh;
                                    if (fechah.getMonth() < 9) {
                                        mesh = "0" + (fechah.getMonth() + 1);
                                    } else {
                                        mesh = fechah.getMonth() + 1;
                                    }

                                    var diah;
                                    if (fechah.getDate() < 9) {
                                        diah = "0" + (fechah.getDate() + 1);
                                    } else {
                                        diah = fechah.getDate() + 1;
                                    }

                                    var mesc;
                                    if (fecha.getMonth() < 9) {
                                        mesc = "0" + (fecha.getMonth() + 1);
                                    } else {
                                        mesc = fecha.getMonth() + 1;
                                    }

                                    var diac;
                                    if (fecha.getDate() < 9) {
                                        diac = "0" + (fecha.getDate() + 1);
                                    } else {
                                        diac = fecha.getDate() + 1;
                                    }

                                    var fechaHoy = fechah.getFullYear() + "/" + mesh + "/" + diah;
                                    var fechaC = fecha.getFullYear() + "/" + mesc + "/" + diac;


                                    if (fechaC < fechaHoy) {
                                        vm.submitNewCobro = false;
                                        var msg = "La fecha de apertura no puede ser menor a la fecha actual";
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    } else {
                                        sendNewProcesoCobro(caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio).then(function (data) {
                                            modalInstance.close();
                                            return ProcesoCobroFactory.get(
                                                data.id,
                                                "BaseList"
                                            ).$promise;
                                        },
                                            function (error) {
                                                vm.submitNewCobro = false;
                                                var msg = error.data[0].message;
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            })
                                            .then(function (data) {
                                                if (data != undefined) {
                                                    ProcesoCobroFactory.setIdProcesoCobro(data.id);
                                                    ProcesoCobroFactory.setProcesoCobro(data);
                                                    redirectProcesoCobro(data.id, false);
                                                }
                                            });

                                    }


                                }
                            });
                        } else {
                            vm.submitNewCobro = false;
                            var msg = "No existe cotización para la fecha del flujo, no podrá crear el nuevo flujo";
                            notify({ message: msg, classes: "alert-danger", position: "right" });
                        }
                    });
                };
            } else {
                vm.submitNewCobro = false;
                var msg = $scope.translations.NO_ADMINISTRATIVE_PERIOD;
                notify({ message: msg, classes: "alert-danger", position: "right" });
            }
        });
    }

    function sendNewProcesoCobro(caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio) {
        return TiposFactory.estadosProcesoCobro()
            .then(
                function (response) {
                    var estados = response.data;

                    if (!estados) {
                        console.log(
                            "No se pudo guardar el proceso de cobro debido a que no se pudo traer los estados"
                        );
                        return;
                    }

                    // console.log("Estados: ", estados);
                    var estadoActivo = _.find(estados, function (estado) {
                        return estado.codigo === "estado_procesocobro_abierto";
                    });
                    if (!estadoActivo) {
                        console.log("No hay estado abierto");
                        return;
                    }
                    var procesoCobro = {
                        estado: estadoActivo.id,
                        caja: caja,
                        fechaHoraInicio: fecha,
                        usuario: $rootScope.AuthParams.accesoSistema.usuario,
                        locacion: $rootScope.AuthParams.locacionSeleccionada.id
                    };

                    if (montoBaseEfectivo) {
                        procesoCobro.montoBaseEfectivo = montoBaseEfectivo;
                    }

                    if (recaudacionDepositar) {
                        procesoCobro.recaudacionDepositar = parseInt(recaudacionDepositar);
                    } else {
                        procesoCobro.recaudacionDepositar = 0;
                    }

                    if (chequesDiferidosVencidos) {
                        procesoCobro.chequesDiferidosVencidos = parseInt(chequesDiferidosVencidos);
                    } else {
                        procesoCobro.chequesDiferidosVencidos = 0;
                    }

                    if (totalDepositar) {
                        procesoCobro.totalDepositar = parseInt(totalDepositar);
                    } else {
                        procesoCobro.totalDepositar = 0;
                    }

                    if (cambio) {
                        procesoCobro.cambio = parseFloat(cambio);
                    }

                    var pc = ProcesoCobroFactory.create(procesoCobro);
                    return ProcesoCobroFactory.save(pc);
                }
            )
            .then(
                function (data) {
                    console.log("Se ha guardado el proceso de cobro.");
                    return data;
                }
            );
    }

    function redirectProcesoCobro(itemId, soloLectura) {
        console.log("redirectProcesoCobro");
        ProcesoCobroFactory.get(itemId, "BaseList").$promise.then(function (
            pc
        ) {
            console.log("En Callback: ", pc);
            ProcesoCobroFactory.setIdProcesoCobro(pc.id);
            ProcesoCobroFactory.setProcesoCobro(pc);
            console.log("Item ID: ", itemId);
            console.log("pc.estado.codigo: ", pc.estado.codigo);

            //pruebas
            if (soloLectura) {
                $state.go("app.procesoscobro.verproceso.cobro.list", {
                    codigoProcesoCobro: itemId,
                });
            } else {
                switch (pc.estado.codigo) {
                    case "estado_procesocobro_abierto":
                        console.log("app.procesocobro.proceso.cobro.list con id: ", itemId);
                        var flujo = ProcesoCobroFactory.getProcesoCobro();
                        if(vm.isMobile && flujo){
                          $state.go("app.procesoscobro.proceso.cobro.new", {
                            codigoProcesoCobro: flujo.id,
                          });
                        } else {
                          $state.go("app.procesoscobro.proceso.cobro.list", {
                            codigoProcesoCobro: itemId,
                          });
                        }
                        break;
                    case "estado_procesocobro_con_cobranza":
                        var flujo = ProcesoCobroFactory.getProcesoCobro();
                        // TODO: TEMPORALMENTE VAMOS DIRECTO A CIERRE
                        if(vm.isMobile && flujo){
                          $state.go("app.procesoscobro.proceso.cobro.new", {
                            codigoProcesoCobro: flujo.id,
                          });
                        } else {
                          $state.go("app.procesoscobro.proceso.cierre.list", {
                            codigoProcesoCobro: itemId,
                          });
                        }
                        // $state.go("app.procesoscobro.proceso.deposito.list", {
                        //   codigoProcesoCobro: itemId
                        // });
                        break;
                    case "estado_procesocobro_cerrado":
                        $state.go("app.procesoscobro.proceso.cierre.list", {
                            codigoProcesoCobro: itemId,
                        });
                        break;
                    default:
                        console.log("Estado distinto");
                }
            }
        });
    }

}

'use strict';

/**
 * @ngdoc service
 * @name qualita.venta
 * @description
 * # cobrosfactory
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('CobroFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
    '$translatePartialLoader', '$translate', 'AuthorizationService', 'ProcesoCobroFactory',
    function (
      $resource,
      baseurl,
      filterFactory,
      TiposFactory,
      $http,
      $translatePartialLoader,
      $translate,
      AuthorizationService,
      ProcesoCobroFactory) {

      var Cobro = $resource(baseurl.getBaseUrl() + '/cobros/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        },
        emitir: {
          method: "PUT",
          url: baseurl.getBaseUrl() + "/cobros/emitir/:id"
        },
        anular: {
          method: "PUT",
          url: baseurl.getBaseUrl() + "/cobros/anular/:id"
        },
        reenviar: {
          method: "PUT",
          url: baseurl.getBaseUrl() + "/cobros/reenviar/:id"
        }
      });

      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined || params.view != undefined ? (view || params.view) : 'BaseList';
          } else {
            params = [];
            params.view = view != undefined ? view : 'BaseList';
          }
          return Cobro.query(params);
        },

        get: function (id, view) {
          return Cobro.get({ id: id, view: view != undefined ? view : 'BaseList' });
        },

        create: function (attrs) {
          return new Cobro(attrs);
        },

        save: function (cobro, mantenimientocobro, facturacionagil, addanticipo) {
          var obj = {
            mantenimientocobro: mantenimientocobro,
            facturacionagil: facturacionagil,
            addanticipo: addanticipo ? addanticipo : false 
          };
          return (cobro.id) ? cobro.$update(obj) : cobro.$save(obj);
        },

        remove: function (cobro) {
          return cobro.$remove();
        },

        emitir: function (id) {
          return Cobro.emitir({ id: id }).$promise;
        },

        getEstaAsentado: function(id) {
          return $http({
                  url: baseurl.getBaseUrl() + '/cobros/estaAsentado/'+id,
                  method: "GET"
                });
        },

        getLastCobrador: function() {
          return $http({
                  url: baseurl.getBaseUrl() + '/cobros/ultimoCobrador/',
                  method: "GET"
                });
        },

        anular: function (id) {
          return Cobro.anular({ id: id }).$promise;
        },

        reenviar: function (id) {
          return Cobro.reenviar({ id: id }).$promise;
        },

        hasPermisos: function (action, estado) {
          switch (action) {
            case "edit":
              return AuthorizationService.hasPermission("update_cobros") &&
                estado.codigo !== "estado_cobro_emitido" && estado.codigo !== "estado_cobro_anulado" &&
                ProcesoCobroFactory.getProcesoCobro().estado.codigo !==
                "estado_procesocobro_cerrado";
            case "emitir":
              return AuthorizationService.hasPermission("aprobar_cobros") &&
                estado.codigo !== "estado_cobro_anulado" &&
              ProcesoCobroFactory.getProcesoCobro().estado.codigo !==
                "estado_procesocobro_cerrado";
            case "anular":
              return AuthorizationService.hasPermission("anular_cobros")
                && estado.codigo === "estado_cobro_emitido" &&
                ProcesoCobroFactory.getProcesoCobro().estado.codigo !==
                "estado_procesocobro_cerrado";
            case "remove":    
                return AuthorizationService.hasPermission("delete_cobros") &&
                  estado.codigo !== "estado_cobro_emitido" && estado.codigo !== "estado_cobro_anulado" &&
                  ProcesoCobroFactory.getProcesoCobro().estado.codigo !=
                  "estado_procesocobro_cerrado";    
            /*case "remove":
              return AuthorizationService.hasPermission("delete_cobros") &&
                estado.codigo !== "estado_cobro_emitido" && estado.codigo !== "estado_cobro_anulado" &&
                ProcesoCobroFactory.getProcesoCobro().estado.codigo !==
                "estado_procesocobro_cerrado";*/
            case "view":
              return AuthorizationService.hasPermission("index_cobros");
          }
        }
      };
    }]);

"use strict";

angular
    .module("qualita.venta")
    .controller("CobrosListCtrl", ["$scope", "$rootScope", "CobroFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "VentasLangFactory", "ProcesoCobroFactory", "WatchLocacionChangeFactory", "$filter", "cajaFactory", "notify", "ParametrosFactory", "$q", "PeriodoAdministrativoFactory", "HistoricoCobroDetalleFactory", function(
        $scope,
        $rootScope,
        CobroFactory,
        filterFactory,
        AuthorizationService,
        ReportTicketFactory,
        $modal,
        $window,
        $state,
        VentasLangFactory,
        ProcesoCobroFactory,
        WatchLocacionChangeFactory,
        $filter,
        cajaFactory,
        notify,
        ParametrosFactory,
        $q,
        PeriodoAdministrativoFactory,
        HistoricoCobroDetalleFactory 
    ) {
        var vm = this;
        vm.permisoMantenimientoCobro = AuthorizationService.hasPermission("mantenimiento_cobro");
        vm.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
        var enviarAContabilidad = '';

        promise1.then(function(response) {
            enviarAContabilidad = response.valorTipo.codigo;
        });

        if ($state.includes("app.procesoscobro.verproceso.cobro.list")){
            vm.soloLectura = true;
        }else{
            vm.soloLectura = false;
        }

        var staticFilter = {};
        vm.isProcesoCobro = true;
        var procesoCobro = ProcesoCobroFactory.getProcesoCobro();

        if (!procesoCobro) {
            $state.go("app.main");
        }
        var isStateEnabled = ProcesoCobroFactory.checkPermissionForState(0);
        if (!isStateEnabled) {
            console.log("No tiene permiso para revisar esta seccion");
            $state.go("app.main");
        }
        staticFilter = {
            search: filterFactory
                .and([{
                    path: "procesoCobro.id",
                    equals: ProcesoCobroFactory.getIdProcesoCobro()
                },
                {
                    path: "id",
                    sortDesc: "true"
                },
                {
                    path: "id",
                    isNotNull: "true"
                }])
                .value()
        };

        /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
        var checkPeriodoAdministrativo = function(fechaComprobante){
            return $q(function(resolve, reject) {

                var params = {};
                var fecha;

                if(fechaComprobante == null){
                    fecha = new Date();
                }else{
                    fecha = fechaComprobante;
                }

                params.search = filterFactory
                .and([{
                        path: "estado.codigo",
                        equals: "per_adm_abierto"
                    },
                    {
                        path: "fechaDesde",
                        equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                    },
                    {
                        path: "fechaHasta",
                        equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                    }
                ])
                .value();

                PeriodoAdministrativoFactory.all(params).$promise.then(function(responsePeriodo) {
                    if(responsePeriodo.length>0){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            });
        }

        VentasLangFactory.getTranslations().then(function(translations) {
            if(localStorage.getItem("cobroRealizado") && vm.isMobile){
              getCobros().$promise.then(function (cobros) {
                var listCobros = cobros.sort(function(a,b) {
                  return b.id - a.id;
                });
                $scope.mensajeModal = $filter("translate")("EMITIR_COBRO_MENSAJE");
                $scope.mensajeModal = $scope.mensajeModal + " N° " + listCobros[0].numeroCobro + "?";
                $scope.tituloModal = $filter("translate")("EMITIR_RECIBO_COBRO");
                var modalInstance = $modal.open({
                  templateUrl: "views/directives/confirmation-modal.html",
                  scope: $scope,
                  backdrop: 'static'
                });
                $scope.cancel = function() {
                  localStorage.removeItem("cobroRealizado")
                  modalInstance.dismiss("cancel");
                };
                $scope.ok = function() {
                  localStorage.removeItem("cobroRealizado");
                  emitir(listCobros[0].id);
                }
              })
            }
            $scope.translations = translations;

            var defaultColumnOrder = [
                "id",
                "numeroCobro",
                "fecha",
                "numeroRecibo",
                "caja.nombre",
                "cliente.nombre",
                "descripcion",
                "estado.descripcion"
            ];
            vm.options = {
                staticFilter: staticFilter,
                resource: "cobros",
                title: "Cobros",
                factory: CobroFactory,
                defaultColumnOrder: defaultColumnOrder,
                view: "CobroList",
                columns: [
                    { data: 'id', title: 'Código Interno', visible: false},
                    { data: "numeroCobro", title: translations.CODIGO_COBRO, class: "dt-center"},
                    {
                        data: "fecha",
                        title: translations.DATE,
                        renderWith: 'dateRender',
                        dateFormat: 'DD/MM/YYYY',
                        class: "dt-center",
                        type: "date-range"
                    },
                    {
                        data: "numeroRecibo",
                        title: translations.NUMERO_RECIBO,
                        class: "dt-center"
                        //type: "number-range"
                    },
                    {
                        data: "caja.nombre",
                        title: translations.CAJA,
                        renderWith: 'emptyRender'
                    },
                    {
                        data: "cliente.nombre",
                        title: translations.CLIENTE,
                        renderWith: 'emptyRender'
                    },
                    {
                        data: "descripcion",
                        title: "Descripción",
                        renderWith: 'emptyRender',
                        visible: false
                    },
                    {
                        data: "estado.descripcion",
                        title: translations.STATUS,
                        renderWith: 'emptyRender',
                        type : 'combo',
                        filterUrl: 'tipos/filtro/estado_cobro'
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc",
                hideAddMenu: true,
                hideEditMenu: true,
                hideRemoveMenu: true,
                hideViewMenu: true,
                extraMenuOptions: [{
                    title: translations.NEW,
                    icon: "glyphicon glyphicon-plus",
                    showCondition: function() {
                        var permission =
                            AuthorizationService.hasPermission("create_cobros") &&
                            ProcesoCobroFactory.getProcesoCobro().estado.codigo !=
                            "estado_procesocobro_cerrado";
                        return permission && !vm.soloLectura;
                    },
                    action: function() {

                        checkPeriodoAdministrativo(null).then(function(resultado) {
                            if(resultado == true){

                                var contador=0;
                                var caja = null;
                                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function(cajas) {
                                    if(cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id){
                                        caja = cajas;
                                    }
                                });
                                if(caja!=null){
                                    cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                        if(cajaDB != null){
                                            _.forEach(cajaDB.usuariosAsociados, function(usuarios) {
                                                if(usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id){
                                                    contador++;
                                                }
                                            });
                                            if(contador==1){
                                                vm.procesoCobro = ProcesoCobroFactory.getProcesoCobro();
                                                ParametrosFactory.getByCodigo('validar_fecha_flujo_cobro').then(function(parametro){
                                                    if(parametro.valorTipo.codigo === 'no'){
                                                        if(cajaDB.id == vm.procesoCobro.caja.id){
                                                            $state.go("app.procesoscobro.proceso.cobro.new");
                                                        }else{
                                                            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR5", { cajaUsuario: cajaDB.nombre, cajaCobro: vm.procesoCobro.caja.nombre});
                                                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                        }
                                                    }else {
                                                        var fecha = new Date();
                                                        var mes, dia;
                                                        if(fecha.getMonth() < 9){
                                                            mes = "0" + (fecha.getMonth() + 1);
                                                        }else{
                                                            mes = fecha.getMonth() + 1;
                                                        }
                                                        dia = (fecha.getDate() <= 9) ? ("0"+ fecha.getDate()) : (fecha.getDate());
                                                        var fechaHoy = fecha.getFullYear() + "/"+ mes + "/" + dia;

                                                        if(vm.procesoCobro.fechaHoraInicio === fechaHoy){
                                                            if(cajaDB.id == vm.procesoCobro.caja.id){
                                                                $state.go("app.procesoscobro.proceso.cobro.new");
                                                            }else{
                                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR5", { cajaUsuario: cajaDB.nombre, cajaCobro: vm.procesoCobro.caja.nombre});
                                                                notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                            }
                                                        }else{
                                                            var msg = $filter("translate")("VALIDAR_FECHA_FLUJO_COBRO");
                                                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                        }
                                                    }
                                                });
                                            }else{
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({message: msg, classes: 'alert-danger', position: 'right'});
                                            }
                                        }else{
                                            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                                        }
                                    });
                                }else{
                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({message: msg, classes: 'alert-danger', position: 'right'});
                                }
                            }else if(resultado == false){
                                var msg= $filter('translate')('NO_OPEN_PERIODS_NOW');
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        });
                    }
                }],
                extraRowOptions: [
                  editarRowOption,
                  borrarRowOption,
                  verRowOption,
                  emitirRowOption,
                  anularRowOption,
                  reenviarRowOption,
                  MantenimientoRowOption,
                  editarRowOptionMantenimiento
                ]
            };
            //   WatchLocacionChangeFactory.watchWith(staticFilter, $scope.options, "locacion.id");
        });

        var emitirRowOption = {
            templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='" +
                $filter("translate")("EMITIR_RECIBO_COBRO") +
                "' ng-click='emitir(<%=dataCustom%>)' ng-class='{ hidden : !canEmitir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
            conditionName: "canEmitir",
            customAttribute: ["estado","numeroCobro","caja", "id"],
            conditionDef: function(atributos) {
                var estado = atributos[0];
                return CobroFactory.hasPermisos("emitir", estado) && !vm.soloLectura;
            },
            functionName: "emitir",
            functionDef: function(atributos) {
                var itemId = atributos[3];
                var cajaItem = atributos[2];
                var numeroCobro = atributos[1];


                var contador=0;
                        var caja = null;
                        _.forEach($rootScope.AuthParams.accesoSistema.cajas, function(cajas) {
                            if(cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id){
                                caja = cajas;
                            }
                        });
                        if(caja!=null){
                            cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                if(cajaDB != null){
                                    _.forEach(cajaDB.usuariosAsociados, function(usuarios) {
                                        if(usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id){
                                            contador++;
                                        }
                                    });
                                    if(contador==1){
                                        if(cajaDB.id == cajaItem.id){
                                            CobroFactory.get(itemId, "CobroList").$promise.then(function(cobro) {

                                                checkPeriodoAdministrativo(cobro.fecha).then(function(resultado) {
                                                    if(resultado == true){
                                                         // showEmitirConfirmation();
                                                        //$scope.mensajeModal = $scope.translations.EMTIIR_COBRO_Q;
                                                        $scope.mensajeModal = $filter("translate")("EMITIR_COBRO_MENSAJE");
                                                        $scope.mensajeModal = $scope.mensajeModal+" N° "+numeroCobro+"?";
                                                        //$scope.tituloModal = $scope.translations.EMITIR_COBRO;
                                                        $scope.tituloModal = $filter("translate")("EMITIR_RECIBO_COBRO");
                                                        var modalInstance = $modal.open({
                                                            templateUrl: "views/directives/confirmation-modal.html",
                                                            scope: $scope
                                                        });
                                                        $scope.cancel = function() {
                                                            modalInstance.dismiss("cancel");
                                                        };
                                                        $scope.ok = function() { emitir(itemId); }

                                                    }else if(resultado == false){
                                                        var msg= $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    }
                                                });
                                            });
                                        }else{
                                            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR5", { cajaUsuario: cajaDB.nombre, cajaCobro: cajaItem.nombre});
                                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                                        }
                                    }else{
                                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                        notify({message: msg, classes: 'alert-danger', position: 'right'});
                                    }
                                }else{
                                    var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                }
                            });
                        }else{
                            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                        notify({message: msg, classes: 'alert-danger', position: 'right'});
                        }
            }
        };

        function emitir(itemId) {
            CobroFactory.emitir(itemId).then(function(response) {
                CobroFactory.get(itemId, "CobroList").$promise.then(function(cobro) {
                    ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                        if (parametro.valorTipo.codigo == 'si') {
                            if(cobro.enviadoAContabilidad == false){
                                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                            }
                            if(response.listaArchivosLogs != null){
                              if(response.listaArchivosLogs.length != 0){
                                /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                    console.log('response.listaArchivosLogs: ');
                                    console.log(response.listaArchivosLogs[i]);
                                    $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                }*/
                                notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                              }
                            }
                        }
                    });

                    var filtersArr = [];
                    filtersArr.push("id_cobro=" + itemId);

                    // Recupera parametro nombre empresa
                    ParametrosFactory.getByCodigo("nombre_empresa").then(function(data) {
                        if(data){
                            filtersArr.push("empresa_nombre=" + data.valor);
                        }else{
                            filtersArr.push("empresa_nombre=" + "SIN_NOMBRE");
                        }

                        // Recupera parametro direccion de la empresa
                        ParametrosFactory.getByCodigo("direccion_empresa").then(function(direccion) {
                            if(direccion){
                                filtersArr.push("empresa_direccion=" + direccion.valor);
                            }
                            // Recupera parametro telefono de la empresa
                            ParametrosFactory.getByCodigo("telefono_empresa").then(function(telefono) {

                                if(telefono){
                                    filtersArr.push("empresa_telefono=" + telefono.valor);
                                }
                                // Recupera parametro telefono de la empresa
                                ParametrosFactory.getByCodigo("ruc_empresa").then(function(ruc) {

                                    if(ruc){
                                        filtersArr.push("empresa_ruc=" + ruc.valor);
                                    }


                                    var filtroRecibo = {};
                                    filtroRecibo.search = filterFactory
                                    .single({
                                            path: "codigo",
                                            equals: "nro_recibo_autogenerado"
                                        })
                                    .value();

                                    ParametrosFactory.all(filtroRecibo).$promise.then(function(reciboAutomatico) {
                                        ParametrosFactory.getByCodigo('validar_fecha_flujo_cobro').then(function(parametro){
                                            if(parametro.valorTipo.codigo === 'no'){
                                                if(cobro.tipoCobroEntidad.imprimeRecibo == true){
                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport("cobro","pdf",filtersArr);
                                                    $window.open(vm.pdfDownloadURL, "_blank");
                                                }
                                                if(reciboAutomatico[0].valorTipo.codigo === 'si'){
                                                    $state.go($state.current, {}, { reload: true });
                                                }else{
                                                    var msg = $filter('translate')('TALONARIO_MANUAL');
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right'});
                                                    $state.go($state.current, {}, { reload: true });
                                                }
                                            }else {
                                                var fecha = new Date();
                                                var mes;
                                                if(fecha.getMonth() < 9){
                                                    mes = "0" + (fecha.getMonth() + 1);
                                                }else{
                                                    mes = fecha.getMonth() + 1;
                                                }
                                                var fechaHoy = fecha.getFullYear() + "/"+ mes + "/" + fecha.getDate();

                                                if(cobro.procesoCobro.fechaHoraInicio === fechaHoy){
                                                    if(cobro.tipoCobroEntidad.imprimeRecibo == true){
                                                        vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport("cobro","pdf",filtersArr);
                                                        $window.open(vm.pdfDownloadURL, "_blank");
                                                    }
                                                    if(reciboAutomatico[0].valorTipo.codigo === 'si'){
                                                        $state.go($state.current, {}, { reload: true });
                                                    }else{
                                                        var msg = $filter('translate')('TALONARIO_MANUAL');
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right'});
                                                        $state.go($state.current, {}, { reload: true });
                                                    }
                                                }else{
                                                    var msg = $filter("translate")("EMITIR_FECHA_FLUJO_COBRO");
                                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                }
                                            }
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            },
            function(error){
                notify({ message: error.data[0].message, classes: 'alert-danger', position: 'right'});
            });
        }

        var editarRowOption = {
            templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" +
                $filter("translate")("EDIT") +
                "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
            functionName: "editar",
            customAttribute: ["estado","id","caja"],
            functionDef: function(atributos) {
                var itemId = atributos[1];
                var cajaItem = atributos[2];
                var contador=0;
                var caja = null;
                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function(cajas) {
                    if(cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id){
                        caja = cajas;
                    }
                });
                if(caja!=null){
                    cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                        if(cajaDB != null){
                            _.forEach(cajaDB.usuariosAsociados, function(usuarios) {
                                if(usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id){
                                    contador++;
                                }
                            });
                            if(contador==1){
                                vm.procesoCobro = ProcesoCobroFactory.getProcesoCobro();
                                if(cajaDB.id == cajaItem.id){
                                    $state.go("app.procesoscobro.proceso.cobro.edit", { id: itemId });
                                }else{
                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR5", { cajaUsuario: cajaDB.nombre, cajaCobro: cajaItem.nombre});
                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                }
                            }else{
                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                notify({message: msg, classes: 'alert-danger', position: 'right'});
                            }
                        }else{
                            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                        }
                    });
                }else{
                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                notify({message: msg, classes: 'alert-danger', position: 'right'});
                }
            },
            conditionName: "canEditar",
            conditionDef: function(atributos) {
                var estado = atributos[0];
                return CobroFactory.hasPermisos("edit", estado) & !vm.soloLectura;
            }
        };

        var borrarRowOption = {
            templateToRender: "<button class='btn btn-danger' style='margin-right: 5px;' title='" +
                $filter("translate")("DELETE") +
                "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
            functionName: "borrar",
            functionDef: function(itemId) {
                $scope.selectedItemId = itemId;
                $scope.tituloModal = $scope.translations.DELETE_CONFIRMATION;
                $scope.mensajeModal = $scope.translations.PERMANENT_OPERATION_WARNING;
                $scope.modalInstanceBorrar1 = $modal.open({
                    templateUrl: "views/directives/confirmation-modal.html",
                    scope: $scope
                });

                $scope.cancel = function() {
                    $scope.modalInstanceBorrar1.dismiss("cancel");
                };

                $scope.ok = function(itemId) {
                    var model = CobroFactory.create({ id: itemId });
                    CobroFactory.remove(model).then(
                        function() {
                            $scope.modalInstanceBorrar1.close(itemId);
                            $state.go($state.current, {}, { reload: true });
                        },
                        function(error) {
                            $scope.modalInstanceBorrar1.dismiss("cancel");
                            $scope.tituloModal = $scope.translations.DELETE_FAILED;
                            $scope.mensajeModal =
                                $scope.translations.DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS;
                            var modalInstance = $modal.open({
                                template: '<div class="modal-header">' +
                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                    "</div>" +
                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                    '<div class="modal-footer">' +
                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                    "</div>",
                                scope: $scope
                            });
                            $scope.cancel = function() {
                                modalInstance.dismiss("cancel");
                            };
                            console.log("error al borrar: ");
                            console.log(error);
                        }
                    );
                };
            },
            conditionName: "canBorrar",
            customAttribute: "estado",
            conditionDef: function(estado) {
                return CobroFactory.hasPermisos("remove", estado) & !vm.soloLectura;
            }
        };

        var verRowOption = {
            templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                $filter("translate")("VIEW") +
                "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
            functionName: "ver",
            functionDef: function(itemId) {
                if (vm.soloLectura){
                    $state.go("app.procesoscobro.verproceso.cobro.view", { id: itemId });
                }else{
                    $state.go("app.procesoscobro.proceso.cobro.view", { id: itemId });
                }

            },
            conditionName: "canVer",
            conditionDef: function() {
                return CobroFactory.hasPermisos("view");
            }
        };
        
        var editarRowOptionMantenimiento= {
            templateToRender:
              "<button class='btn btn-warning' style='margin-right: 5px;' title='Editar Detalle' " +
              "ng-click='customEditarMant(<%=dataCustom%>)' ng-class='{ hidden : !canCustomEditarMant(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
            functionName: "customEditarMant",
            conditionName: "canCustomEditarMant",
            customAttribute: ["estado", "id"],
            conditionDef: function (attributes) {
              var estado = attributes[0];
              if (estado.codigo === "estado_cobro_emitido" && vm.permisoMantenimientoCobro) {
                return true;
              } else {
                return false;
              }
            },
            functionDef: function (attributes) {
              localStorage.setItem("customEditMant", true);
              var idCobro = attributes[1];
              $state.go("app.procesoscobro.proceso.cobro.edit", { id: idCobro });
            },
          };

        function showEmitirConfirmation() {
            CobroFactory.get(itemId).$promise.then(function(response) {
                $scope.mensajeModal = $filter("translate")(
                    "EMITIR_COBRO_MENSAJE"
                );

                $scope.tituloModal = $filter("translate")(
                    "EMITIR_RECIBO_COBRO"
                );
                var modalInstance = $modal.open({
                    templateUrl: "views/directives/confirmation-modal.html",
                    scope: $scope
                });

                $scope.cancel = function() {
                    modalInstance.dismiss("cancel");
                };

                $scope.ok = function(id) {
                    CobroFactory.emitir(itemId).then(
                        function(response) {
                            modalInstance.close();
                            var filtersArr = [];
                            filtersArr.push("id_cobro=" + itemId);
                            modalInstance.close();
                            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                "cobro",
                                "pdf",
                                filtersArr
                            );
                            $window.open(vm.pdfDownloadURL, "_blank");
                            $state.go($state.current, {}, { reload: true });
                        },
                        function(error) {
                            console.log(error);
                            var mensaje = "<span>";
                            _.forEach(error.data, function(err) {
                                mensaje = mensaje + err.message + "<br/>";
                            });
                            mensaje = mensaje + "</span>";
                            notify({
                                messageTemplate: mensaje,
                                classes: "alert-danger",
                                position: "right",
                                templateUrl: ""
                            });
                        }
                    );
                };
            });
        }

        var anularRowOption = {
            templateToRender: "<button class='btn btn-danger' style='margin-right: 5px;' title='" +
            $filter("translate")("ANULAR_COBRO") +
            "' ng-click='anular(<%=dataId%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
            conditionName: "canAnular",
            customAttribute: "estado",
            conditionDef: function(estado) {
                return CobroFactory.hasPermisos("anular", estado) & !vm.soloLectura;
            },
            functionName: "anular",
            functionDef: function(itemId) {
                $scope.selectedItemId = itemId;

               // $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
              //  $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
              //  var modalInstance = $modal.open({
              //      templateUrl: 'views/directives/confirmation-modal.html',
              //      scope: $scope
              //  });

              //  $scope.cancel = function() {
              //      modalInstance.dismiss('cancel');
              //  };

              //  $scope.ok = function(id) {
                    CobroFactory.get(itemId, "CobroList").$promise.then(function(cobro) {
                        var caja = null;
                        var contador=0;
                        _.forEach($rootScope.AuthParams.accesoSistema.cajas, function(cajas) {
                            if(cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id){
                                caja = cajas;
                            }
                        });
                        if(caja!=null){
                            cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                if(cajaDB != null){
                                    _.forEach(cajaDB.usuariosAsociados, function(usuarios) {
                                        if(usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id){
                                            contador++;
                                        }
                                    });
                                    if(contador==1){
                                        if(cajaDB.id == cobro.caja.id){
                                            if(cajaDB.activo != false){
                                                CobroFactory.getEstaAsentado(cobro.id).then(function(resp) {
                                                    if(resp.data == "true"){
                                                        var msg = $filter("translate")("ERROR_ESTA_ASENTADO_COBRO", { numero: cobro.numeroCobro });
                                                        notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                    }else{
                                                        checkPeriodoAdministrativo(cobro.fecha).then(function(resultado) {
                                                            if(resultado == true){
                                                                $scope.tituloModal = $scope.translations.ANULAR_COBRO;

                                                                $scope.mensajeModal = $filter('translate')('ANULAR_COBRO_Q');
                                                                $scope.mensajeModal = $scope.mensajeModal+" N° "+cobro.numeroCobro+"?";

                                                                var modalInstance = $modal.open({
                                                                    templateUrl: "views/directives/confirmation-modal.html",
                                                                    scope: $scope
                                                                });

                                                                $scope.cancel = function() {
                                                                modalInstance.dismiss("cancel");
                                                                };

                                                                $scope.ok = function() { anular(itemId); }

                                                            }else if(resultado == false){
                                                                var msg= $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                            }
                                                        });
                                                    }
                                                });
                                                //redirectProcesoCobro(itemId);
                                            }else{
                                                var msg = $filter('translate')('CAJA_DESACTIVADA');
                                                notify({message: msg, classes: 'alert-danger', position: 'right'});
                                            }
                                        }else{
                                            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR5", { cajaUsuario: cajaDB.nombre, cajaCobro: cobro.caja.nombre});
                                            notify({message: msg, classes: 'alert-danger', position: 'right'});
                                            return;
                                        }
                                    }else{
                                        var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                        notify({message: msg, classes: 'alert-danger', position: 'right'});
                                    }
                                }else{
                                    var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                    notify({message: msg, classes: 'alert-danger', position: 'right'});
                                }
                            });
                        }else{
                            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                        notify({message: msg, classes: 'alert-danger', position: 'right'});
                        }
                    })
                //}
            }
        };

        function anular(itemId) {
            CobroFactory.anular(itemId).then(function(response) {
              ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
                if (parametro.valorTipo.codigo == 'si') {
                    if(response.enviadoAContabilidad == false){
                        notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                    }
                    if(response.listaArchivosLogs != null){
                      if(response.listaArchivosLogs.length != 0){
                        /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                            console.log('response.listaArchivosLogs: ');
                            console.log(response.listaArchivosLogs[i]);
                            $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                        }*/
                        notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                      }
                    }
                }
              });
              $state.go($state.current, {}, { reload: true });
            }, function (error) {
                console.log(error);
                var mensaje = "<span>";
                _.forEach(error.data, function (err) {
                    mensaje = mensaje + err.message + "<br/>";
                });
                mensaje = mensaje + "</span>";
                notify({
                    messageTemplate: mensaje,
                    classes: "alert-danger",
                    position: "right",
                    templateUrl: ""
                });
            });
        }

        var reenviarRowOption = {
          templateToRender: "<button class='btn btn-warning btn-dt' style='margin-right: 5px;' title='" +
          $filter("translate")("REENVIAR_A_CONTABILIDAD") +
          "' ng-click='reenviar(<%=dataId%>)' ng-class='{ hidden : !canReenviar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-retweet'></span> </button>",
          conditionName: "canReenviar",
          customAttribute: ["estado","enviadoAContabilidad"],
          conditionDef: function(atributos) {
            var estado = atributos[0];
            var enviadoAContabilidad = atributos[1];
            if(enviarAContabilidad == 'si'){
              if(estado.codigo === 'estado_cobro_emitido' || estado.codigo === 'estado_cobro_anulado' ){
                if(enviadoAContabilidad === "No"){
                  return true;
                }
              }
            }
          },
          functionName: "reenviar",
          functionDef: function(itemId) {
              $scope.selectedItemId = itemId;

              $scope.tituloModal = $filter('translate')('REENVIAR_COBRO_Q');
              $scope.mensajeModal = $filter('translate')('REENVIAR_COBRO_MSG');
              var modalInstance = $modal.open({
                  templateUrl: 'views/directives/confirmation-modal.html',
                  scope: $scope
              });

              $scope.cancel = function() {
                  modalInstance.dismiss('cancel');
              };

              $scope.ok = function(id) {
                  CobroFactory.get(itemId, "CobroList").$promise.then(function(cobro) {
                      var caja = null;
                      var contador=0;
                      _.forEach($rootScope.AuthParams.accesoSistema.cajas, function(cajas) {
                          if(cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id){
                              caja = cajas;
                          }
                      });
                      if(caja!=null){
                          cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                              if(cajaDB != null){
                                  _.forEach(cajaDB.usuariosAsociados, function(usuarios) {
                                      if(usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id){
                                          contador++;
                                      }
                                  });
                                  if(contador==1){
                                      if(cajaDB.id == cobro.caja.id){
                                          if(cajaDB.activo != false){
                                              CobroFactory.getEstaAsentado(cobro.id).then(function(resp) {
                                                  if(resp.data == "true"){
                                                      var msg = $filter("translate")("ERROR_ESTA_ASENTADO_COBRO", { numero: cobro.numeroCobro });
                                                      notify({message: msg, classes: 'alert-danger', position: 'right'});
                                                  }else{
                                                      checkPeriodoAdministrativo(cobro.fecha).then(function(resultado) {
                                                          if(resultado == true){
                                                              $scope.tituloModal = $scope.translations.REENVIAR_COBRO;

                                                              $scope.mensajeModal = $filter('translate')('REENVIAR_COBRO_Q_2');
                                                              $scope.mensajeModal = $scope.mensajeModal+" N° "+cobro.numeroCobro+"?";

                                                              var modalInstance = $modal.open({
                                                                  templateUrl: "views/directives/confirmation-modal.html",
                                                                  scope: $scope
                                                              });

                                                              $scope.cancel = function() {
                                                              modalInstance.dismiss("cancel");
                                                              };

                                                              $scope.ok = function() { reenviar(itemId); }

                                                          }else if(resultado == false){
                                                              var msg= $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                              notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                          }
                                                      });
                                                  }
                                              });
                                              //redirectProcesoCobro(itemId);
                                          }else{
                                              var msg = $filter('translate')('CAJA_DESACTIVADA');
                                              notify({message: msg, classes: 'alert-danger', position: 'right'});
                                          }
                                      }else{
                                          var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR5", { cajaUsuario: cajaDB.nombre, cajaCobro: cobro.caja.nombre});
                                          notify({message: msg, classes: 'alert-danger', position: 'right'});
                                          return;
                                      }
                                  }else{
                                      var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                      notify({message: msg, classes: 'alert-danger', position: 'right'});
                                  }
                              }else{
                                  var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                  notify({message: msg, classes: 'alert-danger', position: 'right'});
                              }
                          });
                      }else{
                          var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                      notify({message: msg, classes: 'alert-danger', position: 'right'});
                      }
                  })
              }
          }
      };

      function reenviar(itemId) {
          CobroFactory.reenviar(itemId).then(function(response) {
            ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function(parametro){
              if (parametro.valorTipo.codigo == 'si') {
                  if(response.enviadoAContabilidad == false){
                      notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD_VERIFICAR'), classes: 'alert-danger', position: 'right'});
                  }
                  if(response.listaArchivosLogs != null){
                    if(response.listaArchivosLogs.length != 0){
                      /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                          console.log('response.listaArchivosLogs: ');
                          console.log(response.listaArchivosLogs[i]);
                          $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                      }*/
                      notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right'});
                    }
                  }
              }
            });
            $state.go($state.current, {}, { reload: true });
          }, function (error) {
              console.log(error);
              var mensaje = "<span>";
              _.forEach(error.data, function (err) {
                  mensaje = mensaje + err.message + "<br/>";
              });
              mensaje = mensaje + "</span>";
              notify({
                  messageTemplate: mensaje,
                  classes: "alert-danger",
                  position: "right",
                  templateUrl: ""
              });
          });
      }

        var createFilters = function(filters) {
            var filtersArr = [];
            _.each(filters, function(search, data) {
                filtersArr.push({ path: data, like: search });
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        };

        var getIDs = function() {
            var ids = [];
            _.forEach(vm.options.selection, function(n, key) {
                if (n) ids.push(key);
            });
            return ids;
        };

        function getCobros() {
          var filtros = {};
          filtros.search = filterFactory.and({"path":"procesoCobro.id","equals":ProcesoCobroFactory.getIdProcesoCobro()}).value();
          filtros.view = "CobroList";

          return CobroFactory.all(filtros);
        }
        
        var MantenimientoRowOption = {
            templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" +
                "Mantenimiento Cobro" +
                "' ng-click='mantenimiento(<%=dataId%>)' ng-class='{ hidden : !canMantenimiento()}'> <span class='glyphicon glyphicon-stats'></span> </button>",
            customAttribute: ['id'],
            functionName: "mantenimiento",
            functionDef: function(itemId) {
                var defaultColumnOrder = [
                    'cobroDetalle.id',
                    'usuario.username',
                    'fechaCambio',
                    'numeroTarjeta',
                    'tipoTarjeta.descripcion',
                    'numerVoucher',
                    'codigoAutorizacion',
                    'denominacionMarca.descripcion',
                    'procesadora.nombre',
                    'tipoProcesamiento.descripcion',
                    'nombreTitular',
                ];

                var staticFilter = [];
    
                // El filtro almacen siempre tiene un valor definido (al cargar la pagina es 'Todos')
                staticFilter.push(
                    {
                        path: 'cobroDetalle.cobro.id',
                        equals: itemId
                    }
                )
    
                 staticFilter.push(
                    {
                        path: 'fechaCambio',
                        sortDesc: 'true'
                    }
                ) 
    
                var filter = {
                    search: filterFactory.and(staticFilter).value()
                };
                
                $scope.vm.optionsDTModal = {
                    'resource': 'historicocobrodetalle',
                    'staticFilter': filter,
                    'title': 'Histórico Cobro Detalle',
                    'view': 'HistoricoCobroDetalleList',
                    'factory': HistoricoCobroDetalleFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'columns': [
                        { 'data': 'cobroDetalle.id', 'title': 'Cobro Detalle ID', 'class': 'dt-center', 'searchable': false ,'visible': false},
                        { 'data': 'usuario.username', 'title': 'Usuario', 'class': 'dt-center', 'searchable': false ,'visible': false},
                        { 'data': 'fechaCambio', 'title': 'Fecha Modificación', 'class': 'dt-center', 'searchable': false },
                        { 'data': 'numeroTarjeta', 'title': $filter('translate')('NUMERO_TARJETA'), 'class': 'dt-center', 'searchable': false },
                        { 'data': 'tipoTarjeta.descripcion', 'title': $filter('translate')('TIPO_TARJETA'), 'class': 'dt-center', 'searchable': false },
                        { 'data': 'numerVoucher', 'title': $filter('translate')('NUMERO_VOUCHER'), 'class': 'dt-center', 'searchable': false },
                        { 'data': 'codigoAutorizacion', 'title': 'Cod. Autorización', 'class': 'dt-center', 'searchable': false },
                        { 'data': 'denominacionMarca.descripcion', 'title': 'Denominación', 'class': 'dt-center', 'searchable': false },
                        { 'data': 'procesadora.nombre', 'title': 'Procesadora', 'class': 'dt-center', 'searchable': false },
                        { 'data': 'tipoProcesamiento.descripcion', 'title': 'Tipo Procesamiento', 'class': 'dt-center', 'searchable': false },
                        { 'data': 'nombreTitular', 'title': 'Nombre del Titular', 'class': 'dt-center', 'searchable': false }
                    ],
                    'hasOptions': false,
                    'defaultOrderColumn': 0,
                    'defaultOrderDir': "desc",
                    'hideHeader': true,
                };
                        
                $scope.modalInstance = $modal.open({
                    templateUrl: 'views/directives/datatables-custom-modal.html',
                    scope: $scope,
                    size: 'lg'
                });
        
                $scope.vm.close = function () {
                    if ($scope.modalInstance) {
                        $scope.modalInstance.close();
                    }
                }
            },
            conditionName: "canMantenimiento",
            conditionDef: function() {
                return vm.permisoMantenimientoCobro;
            }
        };
    }]);

"use strict";

angular
    .module("qualita.venta")
    .controller("CobrosFormController", CobrosFormController);

CobrosFormController.$inject = [
    "$rootScope",
    "$scope",
    "CobroFactory",
    "TiposFactory",
    "$state",
    "cobroPrepService",
    "$timeout",
    "notify",
    "ModelTrimmer",
    "filterFactory",
    "VentasLangFactory",
    "WatchLocacionChangeFactory",
    "UtilFactory",
    "ParametrosFactory",
    "ClienteFactory",
    "CotizacionMonedaFactory",
    "TiposTipoFactory",
    "ProcesoCobroFactory",
    "ComprobanteVentaFactory",
    "$filter",
    "observeOnScope",
    "FormaPagoFactory",
    "EntidadBancariaFactory",
    "PlanCobrosDetalleFactory",
    "$q",
    "PeriodoAdministrativoFactory",
    "CuentaContableFactory",
    "$stateParams",
    "TipoCobroFactory",
    "SucursalesFactory",
    "VendedorFactory",
    "ProyectoFactory",
    "DenominacionTarjetaFactory",
    "ProveedoresFactory",
    "AuthorizationService",
    "$modal"
];

function CobrosFormController(
    $rootScope,
    $scope,
    CobroFactory,
    TiposFactory,
    $state,
    cobroPrepService,
    $timeout,
    notify,
    ModelTrimmer,
    filterFactory,
    VentasLangFactory,
    WatchLocacionChangeFactory,
    UtilFactory,
    ParametrosFactory,
    ClienteFactory,
    CotizacionMonedaFactory,
    TiposTipoFactory,
    ProcesoCobroFactory,
    ComprobanteVentaFactory,
    $filter,
    observeOnScope,
    FormaPagoFactory,
    EntidadBancariaFactory,
    PlanCobrosDetalleFactory,
    $q,
    PeriodoAdministrativoFactory,
    CuentaContableFactory,
    $stateParams,
    TipoCobroFactory,
    SucursalesFactory,
    VendedorFactory,
    ProyectoFactory,
    DenominacionTarjetaFactory,
    ProveedoresFactory,
    AuthorizationService,
    $modal
) {
    var vm = this;
    console.log('customEditMant',vm.customEditMant);
    console.log('view',vm.view);
    vm.delayRequestCliente = delayRequestCliente;
    vm.searchClienteFilter2 = searchClienteFilter2;
    vm.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    $rootScope.isProcessing = false;

    vm.existeCuota = false;

    $rootScope.isProcessing = false;

    vm.comprobanteCuota = AuthorizationService.hasPermission("pago_parcial_cuota");
    vm.permisoMantenimientoCobro = false;


    var monedaRender = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.moneda.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }
            return (
                moneda +
                data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else return "";
    };

    var monedaCuotaRender = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.planCobros.comprobanteVenta.moneda.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.planCobros.comprobanteVenta.moneda.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.planCobros.comprobanteVenta.moneda.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.planCobros.comprobanteVenta.moneda.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }
            return (
                moneda +
                data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else return "";
    };

    var monedaCuotaRenderDosDecimales = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.planCobros.comprobanteVenta.moneda.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.planCobros.comprobanteVenta.moneda.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.planCobros.comprobanteVenta.moneda.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.planCobros.comprobanteVenta.moneda.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }
            return (
                moneda +
                data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else return "";
    };

    var monedaRenderDosDecimales = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.moneda.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }
            return (
                moneda +
                data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else return "";
    };

    var filtrarAgregados = function () {
        var filtrosRemover = [];
        _.forEach(vm.opcionesComprobantesDt.staticFilter.search.filters, function (filtro) {
            if (filtro.path === "id") {
                filtrosRemover.push(filtro);
            }
        });
        _.forEach(filtrosRemover, function (filtro) {
            _.remove(vm.opcionesComprobantesDt.staticFilter.search.filters, function (filtro2) {
                return filtro2 === filtro;
            });
        });

        var filtrosRemover2 = [];
        _.forEach(vm.opcionesPlanCobroDetalleDt.staticFilter.search.filters, function (filtro) {
            if (filtro.path === "id") {
                filtrosRemover2.push(filtro);
            }
        });

        _.forEach(filtrosRemover2, function (filtro) {
            _.remove(vm.opcionesPlanCobroDetalleDt.staticFilter.search.filters, function (filtro2) {
                return filtro2 === filtro;
            });
        });

        if (vm.cobro) {
            _.forEach(vm.cobro.cobrosComprobantes, function (detalle) {
                var filterComprobante, filterCuota

                if (detalle.cuota) {
                    filterCuota = { path: "id", notEquals: detalle.cuota.id }
                    vm.opcionesPlanCobroDetalleDt.staticFilter.search.filters.push(filterCuota)
                } else {
                    filterComprobante = { path: "id", notEquals: detalle.comprobanteVenta.id }
                    vm.opcionesComprobantesDt.staticFilter.search.filters.push(filterComprobante);
                }
            });
        }
    };

    var removeFiltrarAgregados = function () {
        var bandera1 = true;
        _.forEach(vm.cobro.cobroComprobante, function (detalle) {
            bandera1 = false;
            var newFilter = { path: "id", notEquals: detalle.comprobante.id }
            var bandera = true;
            _.forEach(vm.opcionesComprobantesDt.staticFilter.search.filters, function (filtro) {
                if (filtro.notEquals && filtro.notEquals === detalle.comprobante.id) {
                    bandera = false;
                }
            });
            if (bandera) {
                vm.opcionesComprobantesDt.staticFilter.search.filters.push(newFilter);
                vm.opcionesPlanCobroDetalleDt.staticFilter.search.filters.push(newFilter);
            }
        });
        if (bandera1) {

        }
    };

    activate();

    function activate() {

        /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
        vm.staticFilterProv = [{ path: "activo", equals: true }];
        vm.clienteFactory = ClienteFactory;

        var filtroCobrador = {};
        filtroCobrador.search = filterFactory.and([
            {
                path: 'cobrador',
                equals: true
            },
            {
                path: 'activo',
                equals: true
            }
        ]).value();
        filtroCobrador.view = "VendedorList";
        vm.cobradores = VendedorFactory.all(filtroCobrador);
        vm.cobradores.$promise.then(function (data) {
          if(vm.isMobile){
            var user = $rootScope.AuthParams;
              for(var i = 0; i < data.length; i++){
                if(data[i].usuarioAsignado){
                  if(data[i].usuarioAsignado.id == user.accesoSistema.usuario.id){
                    vm.cobro.cobrador = data[i]
                    return;
                  }
                }
              }
          }
        });
        vm.proyectos = ProyectoFactory.all("", "CobroForm");

        vm.mode = $stateParams.mode ? $stateParams.mode : "normal";
        vm.habilitarAnticipo = true;
        vm.importeAnticipo = 0;
        vm.cambio = 1;
        vm.totalComprobantes = 0;
        WatchLocacionChangeFactory.killAllWatchers();
        var filtertiposComprobante = {
            search: filterFactory
                .and([{
                    path: "utilizarEnProcesosVenta",
                    like: "true"
                },
                {
                    path: "clase.codigo",
                    notEquals: "nota_credito"
                }])
                .value()
        };

        var filtroRecibo = {};
        filtroRecibo.search = filterFactory
            .single({
                path: "codigo",
                equals: "nro_recibo_autogenerado"
            })
            .value();

        ParametrosFactory.all(filtroRecibo).$promise.then(function (reciboAutomatico) {
            if (reciboAutomatico[0].valorTipo.codigo == 'si') vm.reciboAutomatico = true;
        });

        ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
          vm.proyectoObligatorio = r.valorTipo.codigo == "si";
        });

        function reciboAutomatico() {
            return true;
        }

        var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();

        vm.monedas = TiposTipoFactory.get("monedas");
        vm.tiposCobros = TiposTipoFactory.get("tipo_cobro");
        vm.tiposTarjeta = TiposTipoFactory.get("tipos_tarjetas");
        vm.monedaLocal = CotizacionMonedaFactory.getMonedaLocal();
        vm.translationData = {};
        vm.tiposCobroEntidad = TipoCobroFactory.all(filtroActivo);
        vm.monedaLocal.then(function (response) {
            vm.translationData.moneda = response.valorTipo.descripcion;
        });
        vm.denominacionesTarjetas = DenominacionTarjetaFactory.all(filtroActivo);

        // var denominacionesTarjetas = {
        //     search: filterFactory
        //         .single({
        //             path: "activo",
        //             equals: true
        //         })
        //         .value()
        // };

        // vm.listaDenominacionesTarjetas = DenominacionTarjetaFactory.all(denominacionesTarjetas);

        var proveedorFilter = {
            search: filterFactory.and([{
                path: "activarProcesadora",
                equals: true
            }]).value()
        }

        vm.proveedores =  ProveedoresFactory.all(proveedorFilter);
        // vm.proveedores = [];
        // vm.p =  ProveedoresFactory.all(proveedorFilter).$promise.then(function(datos){
        //     var auxProv = _.find(datos, function(pro){
        //     pro.nombreDescripcion = pro.nombre + " " + pro.descripcionProcesadora;
        //     vm.proveedores.push(pro);

        //   });
        // });

        TiposFactory.tiposProcesamientosDetallesCobros().then(function(datos){
            vm.tiposProcesamientos = datos.data;
            var origenManual = _.find(vm.tiposProcesamientos, function(procesamiento){
            if(procesamiento.codigo === 'tipo_procesamiento_pos'){
                vm.auxProcesamiento = procesamiento;

            }
          });
        });

        TiposFactory.monedas().then(function (data) {
            vm.monedaLocal.then(function (response) {
                vm.codigoMonedaLocal = response.valorTipo.codigo;
                vm.monedasExtranjeras = _.filter(data.data, function (moneda) {
                    return moneda.codigo !== vm.codigoMonedaLocal;
                });
                vm.codigoMonedaLocal = response.valorTipo.id;
            });
        })
        vm.search = {};

        var filterFP = {
            search: filterFactory
                .single({
                    path: "utilizarEnCobros",
                    like: "true"
                })
                .value()
        };
        vm.formasPago = FormaPagoFactory.all(filterFP);
        vm.cuentasContables = CuentaContableFactory.all();

        var filterEB = {
            search: filterFactory
                .single({
                    path: "activo",
                    like: "true"
                })
                .value()
        };

        vm.entidadesBancarias = EntidadBancariaFactory.all(filterEB);

        VentasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            vm.placeHolderRecibo = $filter('translate')('NRO_RECIBO_AUTOGENERADO');
            vm.curDate = new Date();
        });

        vm.procesoCobro = ProcesoCobroFactory.getProcesoCobro();

        vm.factoryComprobantes = ComprobanteVentaFactory;
        vm.factoryPlanCobroDetalle = PlanCobrosDetalleFactory;

        var obs1 = observeOnScope($scope, "vm.comprobantesFiltrados")
            .filter(function (data) {
                return data.newValue;
            })
            .map(function (data) {
                return data.newValue;
            });

        obs1.subscribe(function (change) {
            if (!vm.cobro.cliente) {
                notify({
                    message: $filter("translate")("CLIENTE_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else if (change.length <= 0) {
                notify({
                    message: $filter("translate")("COMPROBANTE_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else {
                vm.cobro.cobrosComprobantes = _.reduce(
                    change,
                    function (response, val) {
                        val.$promise.then(storeComprobante(response));
                        return response;
                    }, vm.cobro.cobrosComprobantes || []
                );
            }
        });

        var obs1 = observeOnScope($scope, "vm.cuotasFiltradas")
            .filter(function (data) {
                return data.newValue;
            })
            .map(function (data) {
                return data.newValue;
            });

        obs1.subscribe(function (change) {
            if (!vm.cobro.cliente) {
                notify({
                    message: $filter("translate")("CLIENTE_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else if (change.length <= 0) {
                notify({
                    message: $filter("translate")("COMPROBANTE_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else if (!vm.search.tipoComprobante) {
                notify({
                    message: $filter("translate")("TIPO_COMPROBANTE_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else {
                vm.cobro.cobrosComprobantes = _.reduce(
                    change,
                    function (response, val) {
                        val.$promise.then(storeCuota(response));
                        return response;
                    }, vm.cobro.cobrosComprobantes || []
                );
            }
        });
        vm.staticFilterComprobantes = [];
        vm.staticFilterPlanCobroDetalle = [];

        vm.staticFilterComprobantes.push({
            path: 'tipo.clase.codigo',
            notEquals: 'nota_credito'
        });
        vm.staticFilterComprobantes.push({
            path: "estado.codigo",
          notEquals: "comp_venta_ingresado"
        });
        vm.staticFilterComprobantes.push({
            path: "estado.codigo",
          notEquals: "comp_venta_preparado"
        });
        vm.staticFilterComprobantes.push({
            path: "estado.codigo",
          notEquals: "comp_venta_agendado"
        });
        vm.staticFilterComprobantes.push({
            path: "estado.codigo",
          notEquals: "comp_venta_anulado"
        });
        vm.staticFilterComprobantes.push({
            path: "estado.codigo",
          notEquals: "comp_venta_enviado_sifen"
        });
        vm.staticFilterComprobantes.push({
            path: "saldo",
            greaterOrEqual: 1
        });

        vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.estado.codigo",
            notEquals: "comp_venta_ingresado"
        });
        vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.estado.codigo",
            notEquals: "comp_venta_anulado"
        });
        vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.estado.codigo",
            notEquals: "comp_venta_enviado_sifen"
        });
        vm.staticFilterPlanCobroDetalle.push({
            path: "saldo",
            greaterThan: 0.0
        });
        vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.tipo.clase.codigo",
            notEquals: "nota_credito"
        });
        vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.tipo.clase.codigo",
            notEquals: "nota_debito"
        });
        if (vm.watcherComprob) {
            vm.watcherComprob();
        }

        var defaultColumnOrder = [
            "numero",
            "fecha",
            "cliente.nombre",
            "montoTotal",
            "saldo"
        ];
        WatchLocacionChangeFactory.executeWatching(function (
            locacionFilter,
            watcher
        ) {
            vm.watcherComprob = watcher;
            //vm.ordenesCompra = OrdenesCompraFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilterOC));
            var staticFilterFinal = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };

            var staticFilterPlanCobroDetalleFinal = {
                search: filterFactory.and(vm.staticFilterPlanCobroDetalle).value()
            };
            var staticFilterPlanCobroDetalle = UtilFactory.createNestedFilters(
                locacionFilter.search.filters,
                staticFilterPlanCobroDetalleFinal
            );

            vm.opcionesComprobantesDt = {
                resource: "comprobanteventa",
                title: "Comprobante Venta",
                view: "ComprobanteVentaList",
                factory: ComprobanteVentaFactory,
                hasOptions: false,
                defaultOrderColumn: 2,
                defaultOrderDir: "desc"
            };
            console.log("opcionesComprobantesDT");

            vm.opcionesPlanCobroDetalleDt = {
                staticFilter: staticFilterPlanCobroDetalle,
                resource: "plancobrosdetalle",
                title: $filter("translate")("CUOTAS_COMPROBANTE"),
                view: "PlanCobroDetalleDatatableList",
                factory: PlanPagosDetalleFactory,
                hasOptions: false,
                defaultOrderColumn: 1,
                defaultOrderDir: "desc"
            };
            if(vm.isMobile){
              var defaultColumnOrderPlanPagoDetalle = [
                "id",
                "planCobros.comprobanteVenta.fecha",
                "numero",
                "tipo.descripcion",
                "monto",
                "saldo",
                "estado.descripcion",
                "fechaVencimiento",
                "planCobros.comprobanteVenta.numero",
                "planCobros.comprobanteVenta.cliente.nombre"
              ];
              vm.opcionesPlanCobroDetalleDt.defaultColumnOrder = defaultColumnOrderPlanPagoDetalle;
              vm.opcionesPlanCobroDetalleDt.columns = [
                { data: "id", title: $filter("translate")("CODE"), visible: false },
                { data: "planCobros.comprobanteVenta.fecha",
                  title: $filter("translate")("DATE"),
                  renderWith: 'dateRender',
                  dateFormat: 'DD/MM/YYYY',
                  class: "dt-center",
                  type: "date-range" },
                { data: "planCobros.comprobanteVenta.numero", title: $filter("translate")("NUMERO_COMPROBANTE") },
                { data: "numero", title: $filter("translate")("QUOTA_NUMBER") },
                { data: "planCobros.comprobanteVenta.cliente.nombre", title: $filter("translate")("CLIENTE"), class: "dt-center", visible: false  },
                { data: "tipo.descripcion", title: $filter("translate")("TYPE"), visible: false  },
                {
                  data: "monto",
                  title: $filter("translate")("TOTAL_AMOUNT"),
                  renderWith: monedaCuotaRenderDosDecimales,
                  type: "number-range"
                }, //actualizar
                {
                  data: "saldo",
                  title: $filter("translate")("SALDO"),
                  renderWith: monedaCuotaRenderDosDecimales,
                  type: "number-range"
                },
                {
                  data: "estado.descripcion",
                  title: $filter("translate")("STATUS"),
                  visible: false
                },
                {
                  data: "fechaVencimiento",
                  title: $filter("translate")("FECHA_VENCIMIENTO"),
                  renderWith: "dateRender",
                  dateFormat: "DD/MM/YYYY",
                  class: "dt-center",
                  type: "date-range",
                  visible: false
                }
              ];
              var defaultColumnOrder = [
                "fecha",
                "numero",
                "montoTotal",
                "saldo"
              ];
              vm.opcionesComprobantesDt.defaultColumnOrder = defaultColumnOrder;
              vm.opcionesComprobantesDt.columns = [
                {
                data: "id",
                title: $filter("translate")("CODE"),
                class: "dt-center",
                type: "number-range"
              },
                {
                  data: "fecha",
                  title: $filter("translate")("DATE"),
                  renderWith: 'dateRender',
                  dateFormat: 'DD/MM/YYYY',
                  class: "dt-center",
                  type: "date-range"
                },
                {
                  data: "numero",
                  title: $filter("translate")("NUMERO_COMPROBANTE"),
                  class: "dt-center"
                },
                {
                  data: "cliente.nombre",
                  title: $filter("translate")("CLIENTE"),
                  class: "dt-center"
                },
                {
                  data: "estado.descripcion",
                  title: $filter("translate")("STATUS"),
                  class: "dt-center"
                },
                {
                  data: "montoTotal",
                  title: $filter("translate")("TOTAL_AMOUNT"),
                  class: "dt-right",
                  render: monedaRenderDosDecimales,
                  type: "number-range"
                },
                {
                  data: "saldo",
                  title: $filter("translate")("SALDO"),
                  class: "dt-right",
                  render: monedaRenderDosDecimales,
                  type: "number-range"
                }
              ]
            } else {
              var defaultColumnOrderPlanPagoDetalle = [
                "id",
                "numero",
                "tipo.descripcion",
                "monto",
                "saldo",
                "estado.descripcion",
                "fechaVencimiento",
                "planCobros.comprobanteVenta.numero",
                "planCobros.comprobanteVenta.cliente.nombre"
              ];
              vm.opcionesPlanCobroDetalleDt.defaultColumnOrder = defaultColumnOrderPlanPagoDetalle;
              vm.opcionesPlanCobroDetalleDt.columns = [
                { data: "id", title: $filter("translate")("CODE"), visible: false },
                { data: "numero", title: $filter("translate")("QUOTA_NUMBER") },
                { data: "planCobros.comprobanteVenta.numero", title: $filter("translate")("NUMERO_COMPROBANTE") },
                { data: "planCobros.comprobanteVenta.cliente.nombre", title: $filter("translate")("CLIENTE"), class: "dt-center" },
                { data: "tipo.descripcion", title: $filter("translate")("TYPE") },
                {
                  data: "monto",
                  title: $filter("translate")("MONTO"),
                  renderWith: monedaCuotaRenderDosDecimales,
                  type: "number-range"
                }, //actualizar
                {
                  data: "saldo",
                  title: $filter("translate")("SALDO"),
                  renderWith: monedaCuotaRenderDosDecimales,
                  type: "number-range"
                },
                {
                  data: "estado.descripcion",
                  title: $filter("translate")("STATUS")
                },
                {
                  data: "fechaVencimiento",
                  title: $filter("translate")("FECHA_VENCIMIENTO"),
                  renderWith: "dateRender",
                  dateFormat: "DD/MM/YYYY",
                  class: "dt-center",
                  type: "date-range"
                }
              ]
              var defaultColumnOrder = [
                "numero",
                "fecha",
                "cliente.nombre",
                "montoTotal",
                "saldo"
              ];
              vm.opcionesComprobantesDt.defaultColumnOrder = defaultColumnOrder;
              vm.opcionesComprobantesDt.columns = [
                {
                  data: "id",
                  title: $filter("translate")("CODE"),
                  class: "dt-center",
                  type: "number-range"
                },
                {
                  data: "numero",
                  title: $filter("translate")("NUMBER"),
                  class: "dt-center"
                },
                {
                  data: "fecha",
                  title: $filter("translate")("DATE"),
                  renderWith: 'dateRender',
                  dateFormat: 'DD/MM/YYYY',
                  class: "dt-center",
                  type: "date-range"
                },
                {
                  data: "cliente.nombre",
                  title: $filter("translate")("CLIENTE"),
                  class: "dt-center"
                },
                {
                  data: "estado.descripcion",
                  title: $filter("translate")("STATUS"),
                  class: "dt-center"
                },
                {
                  data: "montoTotal",
                  title: $filter("translate")("TOTAL_AMOUNT"),
                  class: "dt-right",
                  render: monedaRenderDosDecimales,
                  type: "number-range"
                },
                {
                  data: "saldo",
                  title: $filter("translate")("SALDO"),
                  class: "dt-right",
                  render: monedaRenderDosDecimales,
                  type: "number-range"
                }
              ]
            }
            if ($state.is("app.procesoscobro.proceso.cobro.new")) {
                activateNew();
            } else if ($state.is("app.procesoscobro.proceso.cobro.edit") || $state.is("app.cobro.edit")) {
                activateEdit();
            } else if ($state.is("app.procesoscobro.proceso.cobro.view") || $state.is("app.cobro.view") || $state.is("app.procesoscobro.verproceso.cobro.view")) {
                activateView();
            } else if ($state.is("app.cobro.aplicaranticipo")) {
                activateAnticipo();
            }
            $rootScope.isProcessing = false;
            vm.submited = false;
            $rootScope.isProcessing = false;
        }, "locacion.id");

        function activateAnticipo() {
            vm.title = "APLICAR_ANTICIPO_COBRO";
            vm.cobro = cobroPrepService;
            vm.aplicarAnticipo = true;
            vm.cobro.fecha = new Date(vm.cobro.fecha);
            clienteSelected();
            activateOldDetails();
            vm.procesoCobro = false;

            if(vm.mode == 'edit'){
                _.forEach(vm.cobro.cobrosComprobantes, function (detalle) {
                    console.log("detalle ", detalle);
                    detalle.comprobanteVenta.saldo = detalle.importePagar;
                    if (detalle.cuota != null) {
                        detalle.cuota.saldo = detalle.importePagar;
                    }
                });
            }

            vm.search.moneda = vm.cobro.moneda;
            monedaFilterChanged();
            vm.montoTotalAplicado = vm.cobro.importeAnticipo - vm.cobro.saldoAnticipo;

            if (vm.cobro.moneda.codigo == "guaranies") {
                vm.cobro.saldoAnticipo = parseFloat(vm.cobro.saldoAnticipo.toFixed(0));
            } else {
                vm.cobro.saldoAnticipo = parseFloat(vm.cobro.saldoAnticipo.toFixed(4));
            }

        }

        vm.tiposComprobante = [{ "id": 1, "descripcion": "Sin Cuotas", "generarCuotas": false }, { "id": 2, "descripcion": "Con Cuotas", "generarCuotas": true }];

        // Filtro para contado
        vm.search.tipoComprobante = vm.tiposComprobante[0];
        // vm.comprobanteCuota = false;
        vm.permisoPagoParcial = AuthorizationService.hasPermission("pago_parcial_cuota");

        vm.staticFilterComprobantes.push({
            path: "tipo.generarCuotas",
            like: "false"
        });

    }

    function activateNew() {
        if (vm.isMobile){
          popupBusquedaCliente()
        }
        vm.isNew = true;
        abortRouteIfNecessary();
        vm.isItemsEditable = true;
        vm.title = "NEW_COBRO";
        vm.cobro = {
            procesoCobro: vm.procesoCobro,
            caja: vm.procesoCobro.caja,
            usuario: vm.procesoCobro.usuario,
            cobroDetalle: [{
                formaPago: null
            }]
        };
        vm.monedaLocal.then(function (response) {
            vm.cobro.moneda = response.valorTipo;
        });
        vm.tiposCobros.$promise.then(function (data) {
            _.forEach(data, function(pp){
                if(pp.codigo == 'tipo_cobro_normal'){
                    vm.cobro.tipoCobro = pp;
                };
              });
        })

        SucursalesFactory.all().$promise.then(function (response) {
            vm.listaSucursales = response;
            vm.listaSucursales.push({ 'id': '', 'nombre': 'Todos' });
            vm.search.sucursal = vm.listaSucursales[vm.listaSucursales.length - 1];
        });
        vm.cobro.fecha = new Date();
        vm.totalGeneralDetalles = 0;
    }

    function activateEdit() {
        vm.permisoMantenimientoCobro = AuthorizationService.hasPermission("mantenimiento_cobro");
        if($state.is("app.procesoscobro.proceso.cobro.edit")){
            abortRouteIfNecessary();
        }
        vm.disableCobroBan = true;
        vm.disableTipoCobroBan = true;
        vm.isItemsEditable = true;
        vm.isEdit = true;
        vm.title = "EDIT_COBRO";
        vm.cobro = cobroPrepService;
        
        if(vm.cobro.estado.codigo === "estado_cobro_emitido"){
            vm.view = true;
            vm.editarMantenimiento = false;
            if(localStorage.getItem("customEditMant")){
                vm.editarMantenimiento = true;
                $rootScope.customEditMant = localStorage.getItem("customEditMant");
                localStorage.removeItem("customEditMant");
            }
        }
        console.log("vm.cobro", vm.cobro);
        vm.cobro.fecha = new Date(vm.cobro.fecha);
        vm.cobro.tipoCobro = vm.cobro.tipoCobro;
        if (vm.cobro.tipoCobro.codigo == 'tipo_cobro_anticipo') {
            vm.habilitarAnticipo = false;
        }

        if (vm.cobro.tipoCobroEntidad.tipoCondicion.codigo === "tipo_condicion_contado") {
            vm.disableCobroBan = true;
            vm.search.tipoComprobante = vm.tiposComprobante[0];

        } else if (vm.cobro.tipoCobroEntidad.tipoCondicion.codigo === "tipo_condicion_credito") {
            vm.disableCobroBan = true;
            vm.search.tipoComprobante = vm.tiposComprobante[1];
        } else {
            // opcion TODOS
            vm.disableCobroBan = false;
        }
        // if(vm.cobro.cobrosComprobantes[0].comprobanteVenta.tipo.id === 3 && vm.permisoPagoParcial){
        //     vm.comprobanteCuota = true;

        // }

        clienteSelected();
        activateOldDetails();
        updateTotalComprobantes();
    }

    function activateView() {
        vm.view = true;
        vm.title = "VIEW_COBRO";
        vm.cobro = cobroPrepService;
        console.log("vm.cobro", vm.cobro);
        vm.cobro.fecha = new Date(vm.cobro.fecha);
        vm.cobro.tipoCobro = vm.cobro.tipoCobro;
        if (vm.cobro.tipoCobro.codigo == 'tipo_cobro_anticipo') {
            vm.habilitarAnticipo = false;
        }
        activateOldDetails();
        updateTotalComprobantes();
    }

    vm.cancel = cancel;
    vm.submit = submit;
    vm.clearFiltrosComprobantes = clearFiltrosComprobantes;
    vm.fechaDesdeChanged = fechaDesdeChanged;
    vm.fechaHastaChanged = fechaHastaChanged;
    vm.fechaVencimientoDesdeChanged = fechaVencimientoDesdeChanged;
    vm.fechaVencimientoHastaChanged = fechaVencimientoHastaChanged;
    vm.tipoComprobanteChanged = tipoComprobanteChanged;
    vm.monedaFilterChanged = monedaFilterChanged;
    vm.agregarDetalleCobro = agregarDetalleCobro;
    vm.updateTotalDetalles = updateTotalDetalles;
    vm.updateTotalComprobantes = updateTotalComprobantes;
    vm.clienteSelected = clienteSelected;
    vm.removeItemFromArray = removeItemFromArray;
    vm.changeMoneda = changeMoneda;
    vm.deleteComprobante = deleteComprobante;
    vm.importeCobrarDetalleChanged = importeCobrarDetalleChanged;
    vm.changeTipoCobro = changeTipoCobro;
    vm.changeTipoCobroEntidad = changeTipoCobroEntidad;
    vm.sucursalFilterChanged = sucursalFilterChanged;
    vm.sucursalClienteFilterChanged = sucursalClienteFilterChanged;
    vm.delayRequestCliente = delayRequestCliente;
    vm.delayRequestDocumentoMobile = delayRequestDocumentoMobile;
    vm.delayRequestClienteMobile = delayRequestClienteMobile;
    vm.searchClienteFilter = searchClienteFilter;
    vm.searchSucursalClienteFilter = searchSucursalClienteFilter;
    vm.limpiarCampos = limpiarCampos;
    vm.importePagarChanged = importePagarChanged;

    function searchSucursalClienteFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.codigoSucursal.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
            );
        };
    }

    function delayRequestCliente(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
            var filterNombre = [];
            var filterNombreFantasia = [];
            var filterCodigo = [];
            var filterNumero = [];
            var filterDireccion = [];
            var filterCiudad = [];

            filterNombre.push({
                path: "nombre",
                like: searchValue
            })
            filterNombreFantasia.push({
                path: "nombreFantasia",
                like: searchValue
            })
            filterCodigo.push({
                path: "codigo",
                like: searchValue
            })
            filterNumero.push({
                path: "numero",
                like: searchValue
            })
            filterDireccion.push({
                path: "direccion",
                like: searchValue
            })
            filterCiudad.push({
                path: "ciudad.descripcion",
                like: searchValue
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterNombreFantasia).value(),
                    filterFactory.and(filterCodigo).value(),
                    filterFactory.and(filterNumero).value(),
                    filterFactory.and(filterDireccion).value(),
                    filterFactory.and(filterCiudad).value()
                ]).value()
            }

            recuperarCliente(userInputFilter)
        } else {
            vm.clientes = undefined
        }
    }

    function recuperarCliente(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)

        vm.clientes = ClienteFactory.allForSelect(finalFilter)
    }

    function searchClienteFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.nombreFantasia && item.nombreFantasia.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
            );
        };
    }

    function importeCobrarDetalleChanged(detalle) {
        //calcularMontoTotal();
        updateTotalComprobantes();
    }

    function removeItemFromArray(elemento, arreglo) {
        filtrarAgregados();
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        updateTotalDetalles();
    }

    function abortRouteIfNecessary() {
        if (vm.procesoCobro.estado.codigo === "estado_procesocobro_cerrado") {
            $state.go("app.main");
        }
    }

    function agregarDetalleCobro() {
        vm.cobro.cobroDetalle.push({});
        var rowNumber = vm.cobro.cobroDetalle.length - 1;
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function changeMoneda() {
        if (vm.cobro.cobrosComprobantes && vm.cobro.cobrosComprobantes.length > 0) {
            _.forEach(vm.cobro.cobrosComprobantes, function (detalleComprobante) {
                detalleComprobante.monedaHastaTranslation = { moneda: vm.cobro.moneda.descripcion };
            });
        }

        //guaranies != moneda del cobro
        if (vm.codigoMonedaLocal != vm.cobro.moneda.id) {
            var paramsMoneda = {};
            paramsMoneda.search = filterFactory
                .or([
                    filterFactory
                        .and([{
                            path: "monedaDesde.id",
                            equals: vm.cobro.moneda.id
                        },
                        {
                            path: "monedaHasta.id",
                            equals: vm.codigoMonedaLocal
                        },
                        {
                            path: "fecha",
                            like: moment(new Date()).format("DD/MM/YYYY")
                        }
                        ])
                        .value(),
                    filterFactory
                        .and([{
                            path: "monedaDesde.id",
                            equals: vm.codigoMonedaLocal
                        },
                        {
                            path: "monedaHasta.id",
                            equals: vm.cobro.moneda.id
                        },
                        {
                            path: "fecha",
                            like: moment(new Date()).format("DD/MM/YYYY")
                        }
                        ])
                        .value()
                ])
                .value();
            CotizacionMonedaFactory.all(paramsMoneda).$promise.then(function (response) {
                if (response.length > 0) {
                    vm.cambio = response[0].valorCompra;
                } else {
                    vm.cambio = 1;
                }

                var suma = 0;
                _.forEach(vm.cobro.cobrosComprobantes, function (detalleComprobante) {
                    //moneda desde = moneda hasta = moneda cobro
                    if (detalleComprobante.monedaDesdeTranslation.moneda === vm.cobro.moneda.descripcion && detalleComprobante.monedaHastaTranslation.moneda === vm.cobro.moneda.descripcion) {
                        //si la moneda del cobro es dolar
                        if (vm.cobro.moneda.descripcion == "Dólares") {
                            //si es factura credito
                            if (detalleComprobante.comprobanteVenta.tipo.descripcion !== 'Factura Crédito') {
                                detalleComprobante.tipoCambio = 1;
                                suma = suma + detalleComprobante.comprobanteVenta.montoTotal;
                                detalleComprobante.saldo = detalleComprobante.comprobanteVenta.saldo;
                                detalleComprobante.importePagar = detalleComprobante.comprobanteVenta.montoTotal
                                detalleComprobante.saldoConvertido = parseFloat((detalleComprobante.comprobanteVenta.saldo / vm.cambio).toFixed(2));
                            } else {
                                //si es contado
                                detalleComprobante.tipoCambio = 1;
                                suma = suma + detalleComprobante.cuota.saldo;
                                detalleComprobante.saldo = detalleComprobante.cuota.saldo;
                                detalleComprobante.importePagar = detalleComprobante.cuota.monto;
                                detalleComprobante.saldoConvertido = parseFloat((detalleComprobante.cuota.saldo / vm.cambio).toFixed(2));
                            }
                        }
                    } else {
                        //moneda desde != moneda hasta != moneda cobro
                        // si es factura credito
                        if (detalleComprobante.comprobanteVenta.tipo.descripcion !== 'Factura Crédito') {
                            detalleComprobante.tipoCambio = vm.cambio;
                            suma = suma + (detalleComprobante.comprobanteVenta.saldo / vm.cambio);
                            detalleComprobante.saldo = detalleComprobante.comprobanteVenta.saldo;
                            detalleComprobante.importePagar = parseFloat((detalleComprobante.importePagar / vm.cambio).toFixed(2));
                            detalleComprobante.saldoConvertido = parseFloat((detalleComprobante.comprobanteVenta.saldo / vm.cambio).toFixed(2));
                        } else {
                            // factura contado
                            detalleComprobante.tipoCambio = vm.cambio;
                            suma = suma + (detalleComprobante.cuota.monto / vm.cambio);
                            detalleComprobante.saldo = detalleComprobante.cuota.saldo;
                            detalleComprobante.importePagar = parseFloat((detalleComprobante.cuota.monto / vm.cambio).toFixed(2));
                            detalleComprobante.saldoConvertido = parseFloat((detalleComprobante.cuota.monto / vm.cambio).toFixed(2));
                        }
                    }
                });
                vm.totalComprobantes = parseFloat(suma).toFixed(2);
            });
        } else {
            //guaranies == moneda del cobro
            var paramsMoneda = {};
            paramsMoneda.search = filterFactory
                .or([
                    filterFactory
                        .and([{
                            path: "monedaDesde.id",
                            equals: 57
                        },
                        {
                            path: "monedaHasta.id",
                            equals: vm.codigoMonedaLocal
                        },
                        {
                            path: "fecha",
                            like: moment(new Date()).format("DD/MM/YYYY")
                        }
                        ])
                        .value()
                ])
                .value();
            CotizacionMonedaFactory.all(paramsMoneda).$promise.then(function (response) {
                if (response.length > 0) {
                    vm.cambio = response[0].valorCompra;
                }
                var suma = 0;

                _.forEach(vm.cobro.cobrosComprobantes, function (detalleComprobante) {
                    // si la moneda desde = Dolar
                    if (detalleComprobante.monedaDesdeTranslation.moneda === "Dólares") {
                        //si es factura credito
                        if (detalleComprobante.comprobanteVenta.tipo.descripcion !== 'Factura Crédito') {
                            detalleComprobante.tipoCambio = vm.cambio;
                            suma = suma + (detalleComprobante.comprobanteVenta.montoTotal * vm.cambio);
                            detalleComprobante.saldo = (detalleComprobante.comprobanteVenta.montoTotal * vm.cambio);
                            detalleComprobante.importePagar = parseFloat((detalleComprobante.comprobanteVenta.montoTotal * vm.cambio).toFixed(2));
                            detalleComprobante.saldoConvertido = parseFloat((detalleComprobante.comprobanteVenta.montoTotal * vm.cambio).toFixed(2));
                        } else {
                            // si es factura contado
                            detalleComprobante.tipoCambio = vm.cambio;
                            suma = suma + (detalleComprobante.cuota.monto * vm.cambio);
                            detalleComprobante.saldo = detalleComprobante.cuota.saldo;
                            detalleComprobante.importePagar = parseFloat((detalleComprobante.cuota.monto * vm.cambio).toFixed(2));
                            detalleComprobante.saldoConvertido = parseFloat((detalleComprobante.cuota.monto * vm.cambio).toFixed(2));
                        }
                    } else {
                        // si la moneda desde != dolar
                        //si es factura credito
                        if (detalleComprobante.comprobanteVenta.tipo.descripcion !== 'Factura Crédito') {
                            vm.cambio = 1;
                            detalleComprobante.tipoCambio = vm.cambio;
                            suma = suma + (detalleComprobante.comprobanteVenta.saldo * vm.cambio);
                            detalleComprobante.saldo = (detalleComprobante.saldoConvertido * vm.cambio);
                            detalleComprobante.importePagar = parseFloat((detalleComprobante.comprobanteVenta.saldo * vm.cambio).toFixed(2));
                            detalleComprobante.saldoConvertido = parseFloat((detalleComprobante.saldoConvertido * vm.cambio).toFixed(2));
                        } else {
                            //si es factura contado
                            vm.cambio = 1;
                            detalleComprobante.tipoCambio = 1;
                            suma = suma + (detalleComprobante.cuota.monto * vm.cambio);
                            detalleComprobante.saldo = detalleComprobante.cuota.saldo;
                            detalleComprobante.importePagar = parseFloat((detalleComprobante.cuota.monto * vm.cambio).toFixed(2));
                            detalleComprobante.saldoConvertido = parseFloat((detalleComprobante.cuota.monto * vm.cambio).toFixed(2));
                        }
                    }
                });

                var cantDecimales = 0;
                if (vm.cobro.moneda.descripcion !== 'Guaraníes') {
                    cantDecimales = 2;
                }
                vm.totalComprobantes = parseFloat(suma).toFixed(cantDecimales);
            });
        }
    }

    function clienteSelected() {

        // Evita duplicacion de filtro cliente.id
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "cliente.id";
            }
        );
        vm.staticFilterComprobantes.push({
            path: "cliente.id",
            equals: vm.cobro.cliente.id
        });

        vm.staticFilterPlanCobroDetalle = _.filter(
            vm.staticFilterPlanCobroDetalle,
            function (filter) {
                return filter.path != "planCobros.comprobanteVenta.cliente.id";
            }
        );
        vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.cliente.id",
            equals: vm.cobro.cliente.id
        });

        var filtros;
        var filtrosPlanCobrosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanCobrosDetalle = prepareFilterVencimientoPlanCobroDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanCobrosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanCobroDetalle).value()
            };
        }


        if (vm.opcionesComprobantesDt) {
            var locacionesFilter = {};
            locacionesFilter.search = filterFactory.and([{
                path: "locacion.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.id
            }]).value();

            vm.opcionesComprobantesDt.staticFilter = filtros;

            vm.opcionesPlanCobroDetalleDt.staticFilter = filtrosPlanCobrosDetalle;
        }
        filtrarAgregados();
        if (vm.cobro.cobrosComprobantes) {
            vm.cobro.cobrosComprobantes = _.filter(
                vm.cobro.cobrosComprobantes,
                function (detalle) {
                    return detalle.comprobanteVenta.cliente.id == vm.cobro.cliente.id;
                }
            );
        }

        vm.sucursalesClientes = vm.cobro.cliente.sucursales;

    }

    function activateOldDetails() {
        if (vm.mode == "add") {

            /* Se esta intentando agregar una nueva aplicación de anticipo, se inicializa detalle, el detalle que esta
             siendo inicializado se vuelve a agregar en el backend */

            vm.cobro.cobrosComprobantes = []

        } else {
            _.forEach(vm.cobro.cobrosComprobantes, function (detalle) {
                setAdditionalDetailProperties(detalle, detalle.comprobanteVenta);
                if (vm.cobro.estado.codigo === "estado_cobro_emitido") {
                    detalle.montoPagado = detalle.importePagar;
                    detalle.comprobanteVenta.saldo = Math.round((detalle.comprobanteVenta.montoTotal - (detalle.comprobanteVenta.montoTotal / detalle.tipoCambio) * detalle.tipoCambio));
                }
            });
        }

        updateTotalDetalles();
    }

    function setAdditionalDetailProperties(obj, val) {
        if (vm.cobro.tipoCobro.codigo === "tipo_cobro_anticipo") {
            vm.isAnticipo = "anticipo";
        }

        if (obj.cuota) {
            obj.montoCobrado = parseFloat(
                (obj.cuota.monto - obj.cuota.saldo).toFixed(4)
            );
        } else {
            obj.montoCobrado = parseFloat((val.montoTotal - val.saldo).toFixed(4));
        }
        obj.montoAplicado = _.reduce(
            val.aplicaciones,
            function (val, aplicacion) {
                return val + aplicacion.montoAplicado;
            },
            0
        );

        calcularMontoTotal();
        obj.monedaHastaTranslation = { moneda: vm.cobro.moneda.descripcion };
        obj.monedaDesdeTranslation = { moneda: val.moneda.descripcion };

    }

    function evaluatePeriodoAdministrativo(fecha) {
        return $q(function (resolve, reject) {
            var params = {};
            params.search = filterFactory
                .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                }
                ])
                .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {

                if (responsePeriodo.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        });
    }

    function changeTipoCobro() {
        if (vm.isEdit != true) {
            if (vm.cobro.tipoCobro.codigo == 'tipo_cobro_anticipo') {
                vm.habilitarAnticipo = false;
                vm.cobro.cobrosComprobantes = [];
            } else {
                vm.habilitarAnticipo = true;
            }
            vm.cobro.cobroDetalle = [];
        } else {
            if (vm.cobro.tipoCobro.codigo == 'tipo_cobro_anticipo') {
                vm.habilitarAnticipo = false;
                vm.cobro.cobrosComprobantes = [];
                vm.cobro.cobroDetalle = [];
                vm.cobro.descripcion = "";
                vm.totalGeneralDetalles = 0.0;
            } else {
                vm.habilitarAnticipo = true;
                vm.cobro.importeAnticipo = 0;
                vm.cobro.descripcion = "";
                vm.cobro.cobroDetalle = [];
                vm.totalGeneralDetalles = 0.0;
            }
        }
    }

    function changeTipoCobroEntidad() {
        vm.cobro.importeAnticipo = 0;
        vm.cobro.cobrosComprobantes = [];

        // Seteo de campo filtro 'Comprobante'
        if (vm.cobro.tipoCobroEntidad.tipoCondicion.codigo === "tipo_condicion_contado") {
            vm.disableCobroBan = true;
            vm.search.tipoComprobante = vm.tiposComprobante[0];

        } else if (vm.cobro.tipoCobroEntidad.tipoCondicion.codigo === "tipo_condicion_credito") {
            vm.disableCobroBan = true;
            vm.search.tipoComprobante = vm.tiposComprobante[1];
        } else {
            // opcion TODOS
            vm.disableCobroBan = false;
        }

        // Seteo de campo tipo cobro (antiguo)
        if (vm.cobro.tipoCobroEntidad.tipoCobro.codigo === "tipo_cobro_anticipo") {
            vm.cobro.tipoCobro = vm.tiposCobros[0];
            vm.disableTipoCobroBan = true;

        } else if (vm.cobro.tipoCobroEntidad.tipoCobro.codigo === "tipo_cobro_normal") {
            vm.cobro.tipoCobro = vm.tiposCobros[1];
            vm.disableTipoCobroBan = true;
        }
        changeTipoCobro();
        $timeout(function(){
          if(vm.isMobile){
            $('#importeAnticipoMobile input').focus();
          }
        })
    }

    function submit() {
        vm.submited = true;
        var isAddAnticipo = false;
        if(vm.mode == "add"){
            isAddAnticipo = true;
        }
        if ($scope.CobroForm.$valid) {
            $rootScope.isProcessing = true;
            if (vm.mode == "add" && vm.cobro.cobrosComprobantes.length == 0) {
                $rootScope.isProcessing = false;
                notify({ message: $filter("translate")("DETALLES_NOT_LOADED_ANTICIPO"), classes: "alert-danger", position: "right" });
                return;
            }

            console.log("prueba ", vm.editarMantenimiento);
            if(!vm.editarMantenimiento){
                for (var i = 0; i < vm.cobro.cobrosComprobantes.length; i++) {
                    if(vm.cobro.cobrosComprobantes[i].cuota != null && vm.cobro.cobrosComprobantes[i].tipoCambio === 1 ){
                        if (vm.cobro.cobrosComprobantes[i].importePagar > vm.cobro.cobrosComprobantes[i].cuota.saldo) {
                            notify({ message: "El importe a pagar de uno de los detalles es mayor al saldo.", classes: "alert-danger", position: "right" });
                            $rootScope.isProcessing = false;
                            return;
                        }
                    }
                }
            }

            var existeComprobanteCero = false;
            var existeCobroCero = false;

            //Control de detalles comprobantes
            if (vm.habilitarAnticipo != false) {
                _.filter(vm.cobro.cobrosComprobantes, function (detalle) {
                    if (detalle.importePagar == 0) {
                        existeComprobanteCero = true;
                    }
                });

                if (existeComprobanteCero) {
                    $rootScope.isProcessing = false;
                    notify({
                        message: $filter("translate")("EXISTE_COMPROBANTE_CERO"),
                        classes: "alert-danger",
                        position: "right"
                    })
                    return;
                }
            }

            //Control de detalles cobro
            _.filter(vm.cobro.cobroDetalle, function (detalle) {
                if (detalle.importe == 0) {
                    existeCobroCero = true;
                }
            });

            if (existeCobroCero) {
                $rootScope.isProcessing = false;
                notify({
                    message: $filter("translate")("EXISTE_COBRO_CERO"),
                    classes: "alert-danger",
                    position: "right"
                })
                return;
            }

            evaluatePeriodoAdministrativo(vm.cobro.fecha).then(function (response) {
                if (response) {
                    if (vm.habilitarAnticipo != false && vm.procesoCobro) {
                        if (vm.cobro.cobrosComprobantes === undefined || vm.cobro.cobrosComprobantes.length < 1) {
                            $rootScope.isProcessing = false;
                            notify({
                                message: $filter("translate")("NO_COMPROBANTE_ERROR"),
                                classes: "alert-danger",
                                position: "right"
                            })
                            return;
                        }
                    }

                    var fechaError = false;
                    for (var i = 0; i < vm.cobro.cobroDetalle.length; i++) {

                        if (vm.cobro.cobroDetalle[i].fechaPagoDiferido != undefined &&
                            vm.cobro.cobroDetalle[i].fecha > vm.cobro.cobroDetalle[i].fechaPagoDiferido) {

                            fechaError = true;
                            break;
                        }
                    }

                    if (fechaError) {
                        $rootScope.isProcessing = false;
                        notify({
                            message: $filter("translate")("FECHA_PAGO_ERROR"),
                            classes: "alert-danger",
                            position: "right"
                        });
                        return
                    }

                    var trimmedModel = ModelTrimmer.trimDetails(vm.cobro, [
                        "cobroDetalle",
                        "cobrosComprobantes"
                    ]);
                    if (trimmedModel.cobroDetalle) {
                        trimmedModel.cobroDetalle = _.map(trimmedModel.cobroDetalle, function (
                            detalle
                        ) {
                            return ModelTrimmer.trimDetails(detalle);
                        });
                    }
                    if (trimmedModel.cobrosComprobantes) {
                        trimmedModel.cobrosComprobantes = _.map(
                            trimmedModel.cobrosComprobantes,
                            function (detalle) {
                                return ModelTrimmer.trimDetails(detalle);
                            }
                        );
                    }
                    console.log("$rootScope.AuthParams.locacionSeleccionada ", $rootScope.AuthParams.locacionSeleccionada);
                    if (!vm.isEdit) {
                        trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
                    }
                    trimmedModel.sucursal = $rootScope.AuthParams.locacionSeleccionada.sucursal.id;
                    trimmedModel.unidadNegocio = $rootScope.AuthParams.locacionSeleccionada.unidadNegocio.id;
                    var resource = CobroFactory.create(trimmedModel);

                    cleanUnused(trimmedModel);
                    CobroFactory
                        .save(resource,vm.permisoMantenimientoCobro,false, isAddAnticipo)
                        .then(
                            function (response2) {
                                if (response2.tipoCobro.codigo == 'tipo_cobro_anticipo' && response2.estado.codigo == 'estado_cobro_emitido') {
                                    ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                                        if (parametro.valorTipo.codigo == 'si') {
                                            if (response2.enviadoAContabilidad == false) {
                                                $rootScope.isProcessing = false;
                                                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                            }
                                            if (response2.listaArchivosLogs != null) {
                                                if (response2.listaArchivosLogs.length != 0) {
                                                    /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                        console.log('response.listaArchivosLogs: ');
                                                        console.log(response.listaArchivosLogs[i]);
                                                        $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                    }*/
                                                    $rootScope.isProcessing = false;
                                                    notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                                }
                                            }
                                        }
                                    });
                                }

                                vm.isMobile ? localStorage.setItem("cobroRealizado", true) : "";
                                if (vm.procesoCobro) {
                                    $state.go(
                                        "app.procesoscobro.proceso.cobro.list", {}, { reload: true }
                                    );
                                } else {
                                    $state.go(
                                        "app.cobro.list", {}, { reload: true });
                                }
                            },
                            function (error) {
                                $rootScope.isProcessing = false;
                                if (error.data != undefined && error.data.length > 0) {
                                    if (error.data[0].constraint != undefined && error.data[0].constraint === "numerorecibo") {
                                        var msg = $filter('translate')('NUMERO_RECIBO_REPETIDO');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                    if(error.data[0].codigoError === 300) {
                                        notify({ message: "Error, no se puede tener detalles de cobro con el mismo nro de cheque, cuenta y entidad ", classes: 'alert-danger', position: 'right' });
                                        $rootScope.isProcessing = false;

                                    }
                                    else {
                                        notify({ message: error.data[0].message, classes: 'alert-danger', position: 'right' });
                                        $rootScope.isProcessing = false;
                                    }
                                }
                            }
                        );
                } else {
                    $rootScope.isProcessing = false;
                    var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }
            });
        } else {
            $rootScope.isProcessing = false;
            notify({
                message: $filter("translate")("WRONG_FIELDS"),
                classes: "alert-danger",
                position: "right"
            });
        }
    }

    function cancel() {
        if ($state.is("app.procesoscobro.proceso.cobro.new") || $state.is("app.procesoscobro.proceso.cobro.edit") || $state.is("app.procesoscobro.proceso.cobro.view")) {
            $state.go("app.procesoscobro.proceso.cobro.list");
        } else {
            $state.go("app.cobro.list");
        }
    }

    function cleanUnused(modelo) {
        if (!modelo.cobrosComprobantes) {
            return;
        }
        _.forEach(modelo.cobrosComprobantes, function (cobroComprobante) {
            delete cobroComprobante.montoCobrado;
            delete cobroComprobante.montoAplicado;
            //delete cobroComprobante.saldoMonedaCobro;
            delete cobroComprobante.monedaDesdeTranslation;
            delete cobroComprobante.monedaHastaTranslation;
        });
    }

    function clearFiltrosComprobantes() {
        vm.search = {};
        // vm.staticFilterComprobantes = _.filter(vm.staticFilterComprobantes, function(filtro) {
        //     var result = !(filtro.path == "fecha" || filtro.path == "planPagos.planPagosDetalles.fechaVencimiento" ||
        //         filtro.path == "tipo.id" || filtro.path == "moneda1.id" || filtro.path == "importacion.despacho.numero");
        //     console.log("Para el path " + filtro.path);
        //     console.log(result);
        //     return result;
        // });
        // console.log(vm.staticFilterComprobantes);
        var filtros;
        filtros = {
            search: filterFactory.and(vm.staticFilterComprobantes).value()
        };
        var locacionesFilter = {};
        locacionesFilter.search = filterFactory.and([{
            path: "locacion.id",
            equals: $rootScope.AuthParams.locacionSeleccionada.id
        }]).value();

        vm.opcionesComprobantesDt.staticFilter = filtros;

        var filtertiposComprobante = {
            search: filterFactory
                .and([{
                    path: "utilizarEnProcesosVenta",
                    like: "true"
                },
                {
                    path: "clase.codigo",
                    notEquals: "nota_credito"
                }])
                .value()
        };

        vm.tiposComprobante = [{ "id": 1, "descripcion": "Sin Cuotas", "generarCuotas": false }, { "id": 2, "descripcion": "Con Cuotas", "generarCuotas": true }];

        if (vm.cobro.tipoCobroEntidad != null) {
            if (vm.cobro.tipoCobroEntidad.tipoCondicion.codigo === "tipo_comprobante_contado") {
                vm.search.tipoComprobante = vm.tiposComprobante[0];
            } else if (vm.cobro.tipoCobroEntidad.tipoCondicion.codigo === "tipo_comprobante_credito") {
                vm.search.tipoComprobante = vm.tiposComprobante[1];
            }
        } else {
            vm.search.tipoComprobante = null;
        }

    }

    function deleteComprobante(detalle) {

        _.remove(vm.cobro.cobrosComprobantes, function (d) {
            if (detalle.cuota && d.cuota) {
                return d.cuota.id == detalle.cuota.id;
            }
            return d.comprobanteVenta.id == detalle.comprobanteVenta.id;
        });
        vm.existeCuota = false;
        _.forEach(vm.cobro.cobrosComprobantes, function (d) {
            if (detalle.cuota != null) {
                vm.existeCuota = true;
                return;
            }
        });
        filtrarAgregados();
        vm.totalComprobantes -= detalle.importePagar;
        vm.cobro.saldoAnticipo = vm.cobro.saldoAnticipo + detalle.importePagar;

        // Redondeo de montoTotal
        if (vm.cobro.moneda.codigo != 'guaranies') {
            vm.totalComprobantes = parseFloat(vm.totalComprobantes.toFixed(4));
        } else {
            vm.totalComprobantes = Math.round(vm.totalComprobantes);
        }

         // También eliminar los detalles de cobro asociados a retenciones
         _.remove(vm.cobro.cobroDetalle, function (item) {
            return item.numeroComprobanteRetencion && item.numeroComprobanteRetencion === detalle.comprobanteVenta.numero && item.formaPago.clase.codigo === 'forma_pago_retención';
        });
        
    }

    function calcularMontoTotal() {
        filtrarAgregados();
        var cantDecimales = 0;
        if (vm.cobro.moneda.codigo != 'guaranies') {
            cantDecimales = 2;
        }
        vm.montoTotal = _.reduce(vm.cobro.cobrosComprobantes, function (sum, opDetalle) {
            // la moneda de la retencion es la moneda del comprobante, por tanto es la operacion contraria a importe a pagar
            return sum + opDetalle.importePagar;
        }, 0);
        vm.montoTotal = parseFloat(vm.montoTotal).toFixed(cantDecimales);
        vm.updateTotalDetalles()
    }

    function fechaDesdeChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return (
                    filter.path != "fecha" ||
                    (filter.path == "fecha" && !filter.equalOrAfter)
                );
            }
        );
        vm.staticFilterComprobantes.push({
            path: "fecha",
            equalOrAfter: vm.search.fechaDesde != undefined ?
                moment(vm.search.fechaDesde).format("DD/MM/YYYY") : undefined
        });
        vm.staticFilterPlanCobroDetalle = _.filter(
            vm.staticFilterPlanCobroDetalle,
            function (filter) {
                return (
                    filter.path != "planCobros.comprobanteVenta.fecha" ||
                    (filter.path == "planCobros.comprobanteVenta.fecha" && !filter.equalOrAfter)
                );
            }
        );
        vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.fecha",
            equalOrAfter: vm.search.fechaDesde != undefined ?
                moment(vm.search.fechaDesde).format("DD/MM/YYYY") : undefined
        });

        var filtros;
        var filtrosPlanCobrosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanCobrosDetalle = prepareFilterVencimientoPlanCobroDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanCobrosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanCobroDetalle).value()
            };
        }

        var locacionesFilter = {};
        locacionesFilter.search = filterFactory.and([{
            path: "locacion.id",
            equals: $rootScope.AuthParams.locacionSeleccionada.id
        }]).value();

        vm.opcionesComprobantesDt.staticFilter =
            filtros
            ;
        vm.opcionesPlanCobroDetalleDt.staticFilter = filtrosPlanCobrosDetalle;
        filtrarAgregados();
    }

    function fechaHastaChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return (
                    filter.path != "fecha" ||
                    (filter.path == "fecha" && !filter.equalOrBefore)
                );
            }
        );
        vm.staticFilterComprobantes.push({
            path: "fecha",
            equalOrBefore: vm.search.fechaHasta ?
                moment(vm.search.fechaHasta).format("DD/MM/YYYY") : undefined
        });
        vm.staticFilterPlanCobroDetalle = _.filter(
            vm.staticFilterPlanCobroDetalle,
            function (filter) {
                return (
                    filter.path != "planCobros.comprobanteVenta.fecha" ||
                    (filter.path == "planCobros.comprobanteVenta.fecha" && !filter.equalOrBefore)
                );
            }
        );
        vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.fecha",
            equalOrBefore: vm.search.fechaHasta ?
                moment(vm.search.fechaHasta).format("DD/MM/YYYY") : undefined
        });

        var filtros;
        var filtrosPlanCobrosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanCobrosDetalle = prepareFilterVencimientoPlanCobroDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanCobrosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanCobroDetalle).value()
            };
        }

        var locacionesFilter = {};
        locacionesFilter.search = filterFactory.and([{
            path: "locacion.id",
            equals: $rootScope.AuthParams.locacionSeleccionada.id
        }]).value();

        vm.opcionesComprobantesDt.staticFilter =
            filtros
            ;

        vm.opcionesPlanCobroDetalleDt.staticFilter = filtrosPlanCobrosDetalle;
        filtrarAgregados();
    }

    function fechaVencimientoDesdeChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return (
                    filter.path != "planCobros.planCobrosDetalles.fechaVencimiento" ||
                    (filter.path == "planCobros.planCobrosDetalles.fechaVencimiento" &&
                        !filter.equalOrBefore)
                );
            }
        );

        vm.staticFilterPlanCobroDetalle = _.filter(
            vm.staticFilterPlanCobroDetalle,
            function (filter) {
                return (
                    filter.path != "fechaVencimiento" ||
                    (filter.path == "fechaVencimiento" &&
                        !filter.equalOrBefore)
                );
            }
        );

        vm.staticFilterComprobantes.push({
            path: "planCobros.planCobrosDetalles.fechaVencimiento",
            equalOrAfter: vm.search.fechaVencimientoDesde != undefined ?
                moment(vm.search.fechaVencimientoDesde).format("DD/MM/YYYY") : undefined
        });

        vm.staticFilterPlanCobroDetalle.push({
            path: "fechaVencimiento",
            equalOrAfter: vm.search.fechaVencimientoDesde != undefined ?
                moment(vm.search.fechaVencimientoDesde).format("DD/MM/YYYY") : undefined
        });

        var filtros = prepareFilterVencimiento();
        var filtrosPlanCobrosDetalle = prepareFilterVencimientoPlanCobroDetalle();
        console.log("filtro final: ");
        console.log(filtros);
        var locacionesFilter = {};
        locacionesFilter.search = filterFactory.and([{
            path: "locacion.id",
            equals: $rootScope.AuthParams.locacionSeleccionada.id
        }]).value();

        vm.opcionesComprobantesDt.staticFilter =
            filtros
            ;
        vm.opcionesPlanCobroDetalleDt.staticFilter = filtros;
        filtrarAgregados();
    }

    function fechaVencimientoHastaChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return (
                    filter.path != "planCobros.planCobrosDetalles.fechaVencimiento" ||
                    (filter.path == "planCobros.planCobrosDetalles.fechaVencimiento" &&
                        !filter.equalOrAfter)
                );
            }
        );
        vm.staticFilterComprobantes.push({
            path: "planCobros.planCobrosDetalles.fechaVencimiento",
            equalOrBefore: vm.search.fechaVencimientoHasta != undefined ?
                moment(vm.search.fechaVencimientoHasta).format("DD/MM/YYYY") : undefined
        });
        vm.staticFilterPlanCobroDetalle = _.filter(
            vm.staticFilterPlanCobroDetalle,
            function (filter) {
                return (
                    filter.path != "fechaVencimiento" ||
                    (filter.path == "fechaVencimiento" &&
                        !filter.equalOrAfter)
                );
            }
        );
        vm.staticFilterPlanCobroDetalle.push({
            path: "fechaVencimiento",
            equalOrBefore: vm.search.fechaVencimientoHasta != undefined ?
                moment(vm.search.fechaVencimientoHasta).format("DD/MM/YYYY") : undefined
        });
        var filtros = prepareFilterVencimiento();
        var filtrosPlanCobrosDetalle = prepareFilterVencimientoPlanCobroDetalle();

        var locacionesFilter = {};
        locacionesFilter.search = filterFactory.and([{
            path: "locacion.id",
            equals: $rootScope.AuthParams.locacionSeleccionada.id
        }]).value();

        vm.opcionesComprobantesDt.staticFilter =
            filtros
            ;
        vm.opcionesPlanCobroDetalleDt.staticFilter = filtrosPlanCobrosDetalle;
        filtrarAgregados();
    }

    function prepareFilterVencimiento() {
        vm.otherFilters = _.filter(vm.staticFilterComprobantes, function (filter) {
            return filter.path != "planCobros.planCobrosDetalles.fechaVencimiento";
        });

        vm.otherFilters.push({
            path: "planCobros.id",
            isNull: "true"
        });

        var filtros = {
            search: filterFactory
                .or([
                    filterFactory.and(vm.staticFilterComprobantes).value(),
                    filterFactory.and(vm.otherFilters).value()
                ])
                .value()
        };
        return filtros;
    }

    function prepareFilterVencimientoPlanCobroDetalle() {
        vm.otherFilters = _.filter(vm.staticFilterPlanCobroDetalle, function (filter) {
            return filter.path != "fechaVencimiento";
        });

        vm.otherFilters.push({
            path: "planCobros.id",
            isNull: "true"
        });

        var filtros = {
            search: filterFactory
                .or([
                    filterFactory.and(vm.staticFilterPlanCobroDetalle).value(),
                    filterFactory.and(vm.otherFilters).value()
                ])
                .value()
        };
        return filtros;
    }

    function tipoComprobanteChanged() {
        // if(vm.search.tipoComprobante.id === 2 && vm.permisoPagoParcial){
        //     vm.comprobanteCuota = true;
        // }else{
        //     vm.comprobanteCuota = false;
        // }
        //Factura Contado

        // Evita repetir el filtro tipo.generaCuotas
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "tipo.generarCuotas";
            }
        );

        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "tipo.clase.codigo";
            }
        );

        vm.staticFilterComprobantes.push({
            path: "tipo.generarCuotas",
            like: "false"
        });

        vm.staticFilterComprobantes.push({
            path: 'tipo.clase.codigo',
            notEquals: 'nota_credito'
        });

        // Factura Credito
        vm.staticFilterPlanCobroDetalle = _.filter(
            vm.staticFilterPlanCobroDetalle,
            function (filter) {
                return filter.path != "tipo.generarCuotas";
            }
        );

        /*vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.tipo.id",
            equals: vm.search.tipoComprobante.id
        });*/

        /*vm.staticFilterPlanCobroDetalle.push({
            path: "tipo.generarCuotas",
            like: "true"
        });*/

        var filtros;
        var filtrosPlanCobrosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanCobrosDetalle = prepareFilterVencimientoPlanCobroDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanCobrosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanCobroDetalle).value()
            };
        }
        var locacionesFilter = {};
        locacionesFilter.search = filterFactory.and([{
            path: "locacion.id",
            equals: $rootScope.AuthParams.locacionSeleccionada.id
        }]).value();

        vm.opcionesComprobantesDt.staticFilter =
            filtros
            ;

        vm.opcionesPlanCobroDetalleDt.staticFilter = filtrosPlanCobrosDetalle;
        filtrarAgregados();

        //console.clear();
        //console.log("AQUI tipoComprobanteChanged filtro CONTADO: ", vm.staticFilterComprobantes);
        //console.log("AQUI tipoComprobanteChanged filtro CREDITO: ", vm.staticFilterPlanCobroDetalle);
    }

    function monedaFilterChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "moneda.id";
            }
        );
        vm.staticFilterComprobantes.push({
            path: "moneda.id",
            equals: vm.search.moneda.id
        });

        vm.staticFilterPlanCobroDetalle = _.filter(
            vm.staticFilterPlanCobroDetalle,
            function (filter) {
                return filter.path != "planCobros.comprobanteVenta.moneda.id";
            }
        );
        vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.moneda.id",
            equals: vm.search.moneda.id
        });

        var filtros;
        var filtrosPlanCobrosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanCobrosDetalle = prepareFilterVencimientoPlanCobroDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanCobrosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanCobroDetalle).value()
            };
        }
        var locacionesFilter = {};
        locacionesFilter.search = filterFactory.and([{
            path: "locacion.id",
            equals: $rootScope.AuthParams.locacionSeleccionada.id
        }]).value();

        vm.opcionesComprobantesDt.staticFilter =
            filtros
            ;
        vm.opcionesPlanCobroDetalleDt.staticFilter = filtrosPlanCobrosDetalle;
        filtrarAgregados();
    }

    function sucursalFilterChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "sucursal.id";
            }
        );
        vm.staticFilterComprobantes.push({
            path: "sucursal.id",
            equals: vm.search.sucursal.id
        });

        vm.staticFilterPlanCobroDetalle = _.filter(
            vm.staticFilterPlanCobroDetalle,
            function (filter) {
                return filter.path != "planCobros.comprobanteVenta.sucursal.id";
            }
        );
        vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.sucursal.id",
            equals: vm.search.sucursal.id
        });

        var filtros;
        var filtrosPlanCobrosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanCobrosDetalle = prepareFilterVencimientoPlanCobroDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanCobrosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanCobroDetalle).value()
            };
        }
        var locacionesFilter = {};
        locacionesFilter.search = filterFactory.and([{
            path: "locacion.id",
            equals: $rootScope.AuthParams.locacionSeleccionada.id
        }]).value();

        vm.opcionesComprobantesDt.staticFilter = filtros

        vm.opcionesPlanCobroDetalleDt.staticFilter = filtrosPlanCobrosDetalle;
        filtrarAgregados();
    }

    function sucursalClienteFilterChanged() {
        vm.staticFilterComprobantes = _.filter(
            vm.staticFilterComprobantes,
            function (filter) {
                return filter.path != "sucursalCliente.id";
            }
        );
        vm.staticFilterComprobantes.push({
            path: "sucursalCliente.id",
            equals: vm.search.sucursalCliente.id
        });

        vm.staticFilterPlanCobroDetalle = _.filter(
            vm.staticFilterPlanCobroDetalle,
            function (filter) {
                return filter.path != "planCobros.comprobanteVenta.sucursalCliente.id";
            }
        );
        vm.staticFilterPlanCobroDetalle.push({
            path: "planCobros.comprobanteVenta.sucursalCliente.id",
            equals: vm.search.sucursalCliente.id
        });

        var filtros;
        var filtrosPlanCobrosDetalle;

        if (vm.search.fechaVencimientoDesde || vm.search.fechaVencimientoHasta) {
            filtros = prepareFilterVencimiento();
            filtrosPlanCobrosDetalle = prepareFilterVencimientoPlanCobroDetalle();
        } else {
            filtros = {
                search: filterFactory.and(vm.staticFilterComprobantes).value()
            };
            filtrosPlanCobrosDetalle = {
                search: filterFactory.and(vm.staticFilterPlanCobroDetalle).value()
            };
        }
        var locacionesFilter = {};
        locacionesFilter.search = filterFactory.and([{
            path: "locacion.id",
            equals: $rootScope.AuthParams.locacionSeleccionada.id
        }]).value();

        vm.opcionesComprobantesDt.staticFilter = filtros

        vm.opcionesPlanCobroDetalleDt.staticFilter = filtrosPlanCobrosDetalle;
        filtrarAgregados();
    }

    function storeComprobante(response) {
        return function (val) {
            console.log(val);
            if (val.tipo.generarCuotas == true) {
                _.forEach(
                    _.filter(val.planCobros.planCobrosDetalles, function (cuotaA) {
                        var formato = "YYYY/MM/DD";
                        var fechaVencimientoCuota = moment(cuotaA.fechaVencimiento).format(
                            formato
                        );
                        var fechaVencimientoDesde = vm.search.fechaVencimientoDesde;
                        var fechaVencimientoHasta = vm.search.fechaVencimientoHasta;
                        if (fechaVencimientoDesde && fechaVencimientoHasta) {
                            return moment(fechaVencimientoCuota).isBetween(
                                fechaVencimientoDesde,
                                fechaVencimientoHasta,
                                null,
                                "[]"
                            );
                        }
                        return true;
                    }),
                    storeCuota(response, val)
                );
            } else {

                var obj = {
                    comprobanteVenta: val
                };
                setAdditionalDetailProperties(obj, val);
                var cantDecimales = 0;
                var operacion = "mult";
                if (vm.cobro.moneda.codigo != 'guaranies') {

                    cantDecimales = 2;
                    if (val.moneda.codigo == 'guaranies') {

                        operacion = "div";
                    }
                }

                if (val.moneda.id == vm.cobro.moneda.id) {

                    // console.log("La moneda del comprobante es la misma que la del cobro");
                    obj.tipoCambio = 1;
                    obj.saldoConvertido = obj.comprobanteVenta.saldo;

                    obj.saldoConvertido = parseFloat((obj.comprobanteVenta.saldo).toFixed(cantDecimales));
                    obj.importePagar = obj.saldoConvertido;

                    obj.comprobanteVenta.saldo = parseFloat(obj.comprobanteVenta.saldo).toFixed(cantDecimales);
                    obj.comprobanteVenta.montoTotal = parseFloat(obj.comprobanteVenta.montoTotal).toFixed(cantDecimales);
                    calcularMontoTotal();
                } else {

                    //console.log("La moneda del comprobante es otra que la del cobro");
                    obj.monedaDesdeTranslation = { moneda: val.moneda.descripcion };

                    var idMoneda1, idMoneda2;
                    if (val.moneda.codigo === "guaranies") {
                        idMoneda1 = val.moneda.id;
                        idMoneda2 = vm.cobro.moneda.id;
                    } else {
                        idMoneda2 = val.moneda.id;
                        idMoneda1 = vm.cobro.moneda.id;
                    }

                    CotizacionMonedaFactory.getCotizacionDiaOr(
                        idMoneda1,
                        idMoneda2,
                        vm.cobro.fecha
                    ).then(function (valor) {
                        /* Si no hay cotizacion del dia lanzar error */
                        if (!valor) {

                            // Si no hay cotización retornar error y no cargar la lista
                            notify({ message: $filter("translate")("ERROR_TIPO_CAMBIO", { comprobante: val.numero }), classes: "alert-danger", position: "right" });

                        } else {

                            /* Si hay cotizacion realizar calculos de campos */
                            obj.tipoCambio = valor;
                            obj.saldoConvertido = valor * obj.comprobanteVenta.saldo;

                            if (operacion == "mult") {

                                obj.saldoConvertido = parseFloat((obj.comprobanteVenta.saldo * valor).toFixed(cantDecimales));
                                obj.importePagar = obj.saldoConvertido;
                            } else {


                                /* Si la operacion es de division verificar que el tipo de cambio no sea cero */
                                if (valor != 0) {
                                    obj.saldoConvertido = parseFloat((obj.comprobanteVenta.saldo / valor).toFixed(cantDecimales));
                                    obj.importePagar = obj.saldoConvertido;
                                }
                            }
                        }
                        if (valor != null) {
                            parseFloat(obj.importePagar);
                            vm.totalComprobantes = parseFloat(vm.totalComprobantes);
                            vm.totalComprobantes += obj.importePagar;
                            vm.totalComprobantes = parseFloat(vm.totalComprobantes).toFixed(cantDecimales);
                        }

                        //NUEVO REDONDEO PARA QUE RECUPERE CON DOS DECIMALES COMO EL COMPROBANTE
                        var cantDecimalDato = 0;
                        if (obj.comprobanteVenta.moneda.codigo !== 'guaranies') {

                            cantDecimalDato = 2;
                        }
                        obj.comprobanteVenta.saldo = parseFloat(obj.comprobanteVenta.saldo).toFixed(cantDecimalDato);
                        //HASTA AQUI EL REDONDEO

                    }).catch(function (err) {
                        console.log("No se encontró cotización del día", err)
                    });
                }
                response.push(obj);
            }
            if (val.clienteRetenciones && val.clienteRetenciones.length > 0) {
                agregarRetencionesADetalleCobro(val.clienteRetenciones, obj);
            }
            filtrarAgregados();
        };
    }

    function setAdditionalDetailProperties(obj, val) {
        /*if (vm.cobro.tipoCobro.codigo === "tipo_cobro_anticipo") {
            vm.isAnticipo = "anticipo";
        }*/

        if (obj.cuota) {
            obj.montoPagado = parseFloat
                (obj.cuota.monto - obj.cuota.saldo).toFixed(4)
                ;
        } else {
            obj.montoPagado = parseFloat(val.montoTotal - val.saldo).toFixed(4);
        }
        obj.montoAplicado = _.reduce(
            val.aplicaciones,
            function (val, aplicacion) {
                return val + aplicacion.montoAplicado;
            },
            0
        );
        obj.monedaHastaTranslation = { moneda: vm.cobro.moneda.descripcion };
        obj.monedaDesdeTranslation = { moneda: val.moneda.descripcion };

        obj.importeNotasCredito = _.reduce(
            val.aplicaciones,
            function (val, aplicacion) {
                return val + aplicacion.montoAplicado;
            },
            0
        );

        calcularMontoTotal();
        obj.monedaHastaTranslation = { moneda: vm.cobro.moneda.descripcion };
        obj.monedaDesdeTranslation = { moneda: val.moneda.descripcion };

    }

    function storeCuota(array, val) {
        // Si es anticipo setear variable utilizada por directiva para mostrar campo 'Fecha Aplicacion'
        if (vm.cobro.tipoCobro.codigo === "tipo_cobro_anticipo") {
            vm.isAnticipo = "anticipo";
        }

        return function (cuotaA) {
            // console.log("Cuota A: ", cuotaA);
            var obj = {
                comprobanteVenta: cuotaA.planCobros.comprobanteVenta,
                cuota: cuotaA,
                montoPagado: parseFloat((cuotaA.monto - cuotaA.saldo).toFixed(4)),
                montoAplicado: _.reduce(
                    cuotaA.aplicaciones,
                    function (val, aplicacion) {
                        return val + aplicacion.montoAplicado;
                    },
                    0
                ),
                monedaHastaTranslation: { moneda: vm.cobro.moneda.descripcion },
                monedaDesdeTranslation: { moneda: cuotaA.planCobros.comprobanteVenta.moneda.descripcion },
                importePagar: cuotaA.saldo
            };

            var cantDecimales = 0;
            var operacion = "mult";
            if (vm.cobro.moneda.codigo != 'guaranies') {
                cantDecimales = 2;
                if (cuotaA.planCobros.comprobanteVenta.moneda.codigo == 'guaranies') {
                    operacion = "div";
                }
            }

            if (cuotaA.planCobros.comprobanteVenta.moneda.id == vm.cobro.moneda.id) {
                obj.tipoCambio = null;
                obj.cuota.saldo = parseFloat((obj.cuota.saldo).toFixed(cantDecimales));
                obj.cuota.monto = parseFloat((obj.cuota.monto).toFixed(cantDecimales));

                array.push(obj);
                vm.existeCuota = true;
            } else {
                var idMoneda1, idMoneda2;
                if (cuotaA.planCobros.comprobanteVenta.moneda.codigo === "guaranies") {
                    idMoneda1 = cuotaA.planCobros.comprobanteVenta.moneda.id,
                        idMoneda2 = vm.cobro.moneda.id;
                } else {
                    idMoneda2 = cuotaA.planCobros.comprobanteVenta.moneda.id,
                        idMoneda1 = vm.cobro.moneda.id;
                }

                CotizacionMonedaFactory.getCotizacionDiaOr(
                    idMoneda1,
                    idMoneda2,
                    vm.cobro.fecha
                ).then(function (valor) {
                    if (!valor) {
                        // valor = 1;
                        // si no hay cotización retornar error y no cargar la lista
                        notify({ message: $filter("translate")("ERROR_TIPO_CAMBIO_CUOTA", { cuota: cuotaA.numero, comprobante: cuotaA.planCobros.comprobanteVenta.numero }), classes: "alert-danger", position: "right" });
                        //return;
                        valor = null;
                    } else {

                    }
                    obj.tipoCambio = valor;
                    if (operacion == "mult") {
                        obj.saldoConvertido = parseFloat((obj.cuota.saldo * obj.tipoCambio).toFixed(cantDecimales));
                        /*obj.importePagar = parseFloat(
                        obj.importePagar = parseFloat(
                             (obj.saldoConvertido * obj.tipoCambio).toFixed(cantDecimales)
                        );
                        );*/
                        obj.importePagar = obj.saldoConvertido;
                    } else {
                        obj.saldoConvertido = parseFloat((obj.cuota.saldo / obj.tipoCambio).toFixed(cantDecimales));
                        /*obj.importePagar = parseFloat(
                        obj.importePagar = parseFloat(
                             (obj.saldoConvertido / obj.tipoCambio).toFixed(cantDecimales)
                        );
                        );*/
                        obj.importePagar = obj.saldoConvertido;
                    }

                    // obj.cuota.saldo = parseFloat((obj.cuota.saldo).toFixed(cantDecimales));
                    // obj.cuota.monto = parseFloat((obj.cuota.monto).toFixed(cantDecimales));


                    /* Si el comprobante seleccionado esta en dolares redondear a 2 decimales el saldo */
                    if (cuotaA.planCobros.comprobanteVenta.moneda.codigo == "dolares") {
                        obj.cuota.saldo = parseFloat((obj.cuota.saldo).toFixed(4));
                        obj.cuota.monto = parseFloat((obj.cuota.monto).toFixed(4));
                    }

                    array.push(obj);
                    vm.existeCuota = true;
                    calcularMontoTotal();
                    // if (valor) {
                    //     obj.tipoCambio = valor;
                    //     obj.saldoConvertido = parseFloat(
                    //         (valor * obj.cuota.saldo).toFixed(4)
                    //     );
                    // }
                });
            }
            filtrarAgregados();
            vm.totalComprobantes = parseFloat(vm.totalComprobantes);
            vm.totalComprobantes += obj.importePagar;
            vm.totalComprobantes = parseFloat(vm.totalComprobantes.toFixed(cantDecimales));

            obj.importeNotasCredito = _.reduce(
                cuotaA.aplicaciones,
                function (val, aplicacion) {
                    return val + aplicacion.montoAplicado;
                },
                0
            );
    
            if (cuotaA.planCobros.comprobanteVenta.clienteRetenciones && cuotaA.planCobros.comprobanteVenta.clienteRetenciones.length > 0) {
                agregarRetencionesADetalleCobro(cuotaA.planCobros.comprobanteVenta.clienteRetenciones, obj);
            }
            
        };
    }

    function updateTotalDetalles() {
        vm.totalGeneralDetalles = _.reduce(
            vm.cobro.cobroDetalle,
            function (suma, detalle) {
                return suma + detalle.importe;
            },
            0
        );
        // Redondeo en funcion de moneda
        var cantDecimales = 0;
        if (vm.cobro.moneda.codigo != 'guaranies') {
            cantDecimales = 2;
        }
        vm.totalGeneralDetalles = parseFloat(vm.totalGeneralDetalles.toFixed(cantDecimales));
    }

    function updateTotalComprobantes() {
        vm.totalComprobantes = _.reduce(
            vm.cobro.cobrosComprobantes,
            function (suma, detalle) {
                return suma + detalle.importePagar;
            },
            0
        );

        // Redondeo en funcion de moneda
        var cantDecimales = 0;
        if (vm.cobro.moneda.codigo != 'guaranies') {
            cantDecimales = 2;
        }
        vm.totalComprobantes = parseFloat(vm.totalComprobantes).toFixed(cantDecimales);
    }

    function delayRequestCliente(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
            var filterNombre =[];
            // var filterNombreFantasia =[];
            var filterCodigo =[];
            var filterNumero =[];
            var filterDireccion =[];
            var filterCiudad =[];

            filterNombre.push({
                path: "nombre",
                like: searchValue
            })
            filterNombre.push({
              path: "activo",
              equals: true
            })
            filterCodigo.push({
            path: "codigo",
            like: searchValue
            })
            filterCodigo.push({
              path: "activo",
              equals: true
            })
            filterNumero.push({
            path: "numero",
            like: searchValue
            })
            filterNumero.push({
              path: "activo",
              equals: true
            })
            filterDireccion.push({
            path: "direccion",
            like: searchValue
            })
            filterDireccion.push({
              path: "activo",
              equals: true
            })
            filterCiudad.push({
            path: "ciudad.descripcion",
            like: searchValue
            })
            filterCiudad.push({
              path: "activo",
              equals: true
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigo).value(),
                    filterFactory.and(filterNumero).value(),
                    filterFactory.and(filterDireccion).value(),
                    filterFactory.and(filterCiudad).value()
                ]).value()
            }

            recuperarCliente(userInputFilter)
        } else {
            vm.clientes = undefined
        }
    }

    function recuperarCliente(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)

          ClienteFactory.allForSelect(finalFilter).$promise.then(function (clientes) {
            var clie = [];
            for (var i = 0; i < clientes.length; i++) {
              if (clientes[i].bloquearVentas !== true) {
                clie.push(clientes[i])
              }
            }
            vm.clientes = clie;
          })
    }

    function searchClienteFilter2(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
            );
        }
    }

    function limpiarCampos(index){

        vm.cobro.cobroDetalle[index].entidadBancaria = null;
        vm.cobro.cobroDetalle[index].numeroTransaccion = null;
        vm.cobro.cobroDetalle[index].denominacionTarjeta = null;
        vm.cobro.cobroDetalle[index].numeroTarjeta = null;
        vm.cobro.cobroDetalle[index].numeroVoucher = null;
        vm.cobro.cobroDetalle[index].proveedor = null;
        vm.cobro.cobroDetalle[index].tipoProcesamiento = null;
        vm.cobro.cobroDetalle[index].tipoTarjeta = null;
        vm.cobro.cobroDetalle[index].titular = null;
        vm.cobro.cobroDetalle[index].codigoAutorizacion = null;
        vm.cobro.cobroDetalle[index].numeroCheque = null;
        vm.cobro.cobroDetalle[index].numeroCuenta = null;
        vm.cobro.cobroDetalle[index].fecha = null;
        vm.cobro.cobroDetalle[index].fechaPagoDiferido = null;
        vm.cobro.cobroDetalle[index].numeroRetencion = null;
        vm.cobro.cobroDetalle[index].numeroComprobanteRetencion = null;
        vm.cobro.cobroDetalle[index].numeroTimbrado = null;
        vm.cobro.cobroDetalle[index].numeroCuentaContable = null;

        if(vm.cobro.cobroDetalle[index].formaPago.clase.codigo === "forma_pago_tarjeta"){
            vm.cobro.cobroDetalle[index].titular =  vm.cobro.cliente.nombre;
            vm.cobro.cobroDetalle[index].tipoProcesamiento = vm.auxProcesamiento;
            vm.cobro.cobroDetalle[index].tipoTarjeta = vm.cobro.cobroDetalle[index].formaPago.tipoTarjeta;
        }
    }
    function importePagarChanged(detalle){
        var auxSaldo = detalle.saldoConvertido - detalle.importePagar;
        detalle.saldoRestante = auxSaldo;
        if(detalle.importePagar > detalle.saldoConvertido){
            vm.mayor = true;
        }else{
            vm.mayor = false;
        }
        // if(auxSaldo > 0){
        //     vm.saldoSobrante = true;
        // }else{
        //     vm.saldoSobrante = false;
        // }
        updateTotalComprobantes();

    }
    if($state.is("app.procesoscobro.proceso.cobro.new")){
      $scope.$watch('vm.tiposCobroEntidad', function(newVal){
        if(newVal && vm.isMobile){
          for(var i = 0; i < newVal.length; i++){
            if(newVal[i].tipoCondicion.codigo === "tipo_condicion_contado"){
              vm.cobro.tipoCobroEntidad = newVal[i];
              break;
            }
          }
        }
      }, true)
    }
    function popupBusquedaCliente() {
      $scope.tituloModal = 'Búsqueda del Cliente';
      var modalInstance = $modal.open({
        templateUrl: 'qualita-components/ventas/views/cobro/m-datos-cliente-modal.html',
        scope: $scope,
        size: "lg",
        backdrop: 'static'
      });

      modalInstance.rendered.then(function(){
        $timeout(function () {
          $scope.$broadcast("mobile_cliente");
          $('#mobile_numero input')[1].focus();
        }, 1000)
      })

      $scope.cancelBusqueda = function () {
        modalInstance.dismiss('cancel');
        vm.cobro.cliente = undefined;
        vm.cobro.moneda = undefined;
        vm.monedaLocal.then(function (response) {
          vm.cobro.moneda = response.valorTipo;
        });
        vm.cobro.tipoCobroEntidad = undefined;
        vm.cobro.tipoCobro = vm.tiposCobros[1];
        vm.cobro.importeAnticipo = 0;
        vm.cobro.cobrador = undefined;
      };

      $scope.okBusqueda = function () {
        modalInstance.dismiss('cancel');
        if (vm.cobro.tipoCobro.codigo != 'tipo_cobro_anticipo') {
          $timeout(function () {
            $("#searchComprobante>button").click()
          },500)
        }
        $timeout(function () {
          $('#filtro_3').focus();
        }, 1000)
      }

    }
    function delayRequestClienteMobile(searchValue) {
      var userInputFilter = {}
      if (searchValue.length >= 3) {
        var filterNombre = [];
        var filterNombreFantasia = [];

        filterNombre.push({
          path: "nombre",
          like: searchValue
        })
        filterNombre.push({
          path: "activo",
          equals: true
        })
        filterNombreFantasia.push({
          path: "nombreFantasia",
          like: searchValue
        })
        filterNombreFantasia.push({
          path: "activo",
          equals: true
        })

        userInputFilter = {
          search: filterFactory.or([
            filterFactory.and(filterNombre).value(),
            filterFactory.and(filterNombreFantasia).value()
          ]).value()
        }

        recuperarCliente(userInputFilter)
      } else {
        vm.clientes = undefined
      }
    }
    function delayRequestDocumentoMobile(searchValue) {
    var userInputFilter = {}
    if (searchValue.length >= 6) {
      var filterNumero = [];

      filterNumero.push({
        path: "numero",
        like: searchValue
      })
      filterNumero.push({
        path: "activo",
        equals: true
      })

      userInputFilter = {
        search: filterFactory.or([
          filterFactory.and(filterNumero).value()
        ]).value()
      }

      recuperarCliente(userInputFilter)
    } else {
      vm.clientes = undefined
    }
  }
  function agregarRetencionesADetalleCobro(retenciones, comprobanteObj) {
    var formaPagoRetencion = obtenerFormaPagoPorCodigo("forma_pago_retención");

    _.forEach(retenciones, function (retencion) {
        // Verificar si ya existe un detalle de cobro con el mismo número de retención
        var existeRetencion = _.some(vm.cobro.cobroDetalle, function (detalle) {
            return detalle.numeroRetencion === retencion.numero;
        });

        if (!existeRetencion) {
            var detalleCobro = {
                clienteRetencion: retencion,
                comprobante: comprobanteObj.comprobanteVenta,
                importe: retencion.montoRetencion,
                tipoCambio: comprobanteObj.tipoCambio,
                saldoConvertido: retencion.montoRetencion * comprobanteObj.tipoCambio,
                formaPago: formaPagoRetencion,
                numeroTimbrado: retencion.timbrado,
                numeroRetencion: retencion.numero,
                numeroComprobanteRetencion: comprobanteObj.comprobanteVenta.numero,
                deshabilitado: true
            };

            // Agrega el detalle del cobro a la lista de detalles del cobro
            if (!vm.cobro.cobroDetalle) {
                vm.cobro.cobroDetalle = [];
            }

            // Eliminar detalles de cobro vacíos antes de agregar nuevos detalles
            if(vm.isNew){
                eliminarDetallesCobroVacios();
            }
            vm.cobro.cobroDetalle.push(detalleCobro);
            vm.updateTotalDetalles();
        }
    });
}

    function obtenerFormaPagoPorCodigo(codigo) {
        return vm.formasPago.find(function (formaPago) {
            return formaPago.clase.codigo === codigo;
        });
    }

    // Función para eliminar detalles de cobro vacíos
    function eliminarDetallesCobroVacios() {
        _.remove(vm.cobro.cobroDetalle, function (detalle) {
            return !detalle.formaPago;
        });
    }

}

angular.module("qualita.venta").directive("detalleCobro", detalleCobro);

function detalleCobro() {
    var directive = {
        restrict: "E",
        scope: {
            modelo: "=",
            moneda: "=",
            view: "=",
            submited: "=",
            areItemsEditable: "=",
            viewModel: "=",
            onDelete: "&",
            form: "=",
            formasPago: "=",
            cuentasContables: "=",
            monedas: "=",
            entidadesBancarias: "=",
            tiposTarjeta: "=",
            onChange: "&",
            index: "=",
            estado: "=",
            onSelect: "&",
            denominacionesTarjetas: "=",
            proveedores: "=",
            tiposProcesamientos: "="
        },
        controllerAs: "vm",
        bindToController: true,
        templateUrl: "qualita-components/ventas/views/directives/detallecobro.directive.html",
        link: linkFunc,
        controller: DetalleCobroController
    };

    function linkFunc(scope, elem, attr) { }

    return directive;
}

DetalleCobroController.$inject = [
    "VentasLangFactory",
    "$log",
    "$scope",
    "$timeout",
    "AuthorizationService"
];

function DetalleCobroController(VentasLangFactory, $log, $scope, $timeout,AuthorizationService) {
    var vm = this;
    vm.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    vm.incluir = false;
    vm.deleteClicked = deleteClicked;
    vm.plusClicked = plusClicked;
    vm.updateListener = updateListener;
    vm.limpiarCampos = limpiarCampos;
    vm.permisoMantenimientoCobro = AuthorizationService.hasPermission("mantenimiento_cobro");

    activate();

    function activate() {

        //console.log("vm.moneda",vm.moneda);
        vm.mostrarAdicionales = true;
        VentasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            $scope.$watch('vm.moneda.moneda', function () {
                //console.log("vm.moneda",vm.moneda);
                vm.modelo.moneda = vm.moneda.moneda;
                //console.log("vm.modelo.moneda",vm.modelo.moneda);
            });

        });
    }

    function plusClicked() {
        vm.mostrarAdicionales = !vm.mostrarAdicionales;
    }

    function deleteClicked() {
        $timeout(vm.onDelete, 0);
    }

    function updateListener() {
        $timeout(vm.onChange, 0);
    }
    function limpiarCampos(){
        if(vm.isMobile && vm.modelo.importe === undefined){
          var i= 0;
          var totalImporteCobrar = 0;
          do {
            if(vm.form["importeCobrarDetalle_" + (i)].$modelValue){
              totalImporteCobrar += parseFloat(vm.form["importeCobrarDetalle_" + (i)].$modelValue.replace(",","."));
            }
            i++;
          } while (vm.form["importeCobrarDetalle_" + (i)]);
          if(vm.form.tipoCobroEntidad.$modelValue.tipoCobro.codigo === "tipo_cobro_anticipo"){
            vm.modelo.importe = Math.round((parseFloat(vm.form.importeAnticipo.$modelValue.replace(",",".")) - totalImporteCobrar) * 100) / 100;
          } else {
            vm.modelo.importe = Math.round((parseFloat(vm.form.totalComprobantes.$modelValue.replace(",",".")) - totalImporteCobrar) * 100) / 100;
          }
          vm.updateListener()
        }
        $timeout(vm.onSelect(), 0);
    }
}

angular
    .module("qualita.venta")
    .directive("detalleCobroComprobante", detalleCobroComprobante);

function detalleCobroComprobante() {
    var directive = {
        restrict: "E",
        scope: {
            modelo: "=",
            moneda: "=",
            view: "=",
            submited: "=",
            areItemsEditable: "=",
            viewModel: "=",
            onDelete: "&",
            form: "=",
            cambio: "=",
            onChange: "&",
            estado: "=",
            isEdit: "=",
            isView: "=",
            modoAnticipo: '=',
            isAnticipo: '=',
            arrayPosition: '=',
            onImportePagarChanged: '&',
            mayor: '=',
            saldoSobrante: '=',
            comprobanteCuota: '='
        },
        controllerAs: "vm",
        bindToController: true,
        templateUrl: "qualita-components/ventas/views/directives/detallecobrocomprobante.directive.html",
        link: linkFunc,
        controller: DetalleCobroComprobanteController
    };

    function linkFunc(scope, elem, attr) {}

    return directive;
}

DetalleCobroComprobanteController.$inject = [
    "VentasLangFactory",
    "$log",
    "$scope",
    "$timeout",
    "$state"
];

function DetalleCobroComprobanteController(
    VentasLangFactory,
    $log,
    $scope,
    $timeout,
    $state
) {
    var vm = this;
    vm.incluir = false;
    vm.importeCobrarChanged = importeCobrarChanged;
    vm.plusClicked = plusClicked;
    vm.tipoCambioChanged = tipoCambioChanged;
    vm.deleteClicked = deleteClicked;
    vm.updateListener = updateListener;
    vm.importePagarChanged = importePagarChanged;

    if ($state.is("app.procesoscobro.proceso.cobro.new")) {
      vm.hideFechaAplicacion = true;
    } else if ($state.is("app.procesoscobro.proceso.cobro.edit")) {
      vm.hideFechaAplicacion = true;
    } else if ($state.is("app.procesoscobro.proceso.cobro.view") || $state.is("app.procesoscobro.verproceso.cobro.view")) {
      vm.hideFechaAplicacion = true;
    }

    activate();

    function activate() {

        VentasLangFactory.getTranslations().then(function(translations) {
            vm.translations = translations;
        });
        vm.mostrarAdicionales = false;
        //vm.modelo.fechaAplicacion = new Date();
        if (!vm.modelo.tipoCambio) {
            if (vm.modelo.comprobanteVenta && vm.modelo.comprobanteVenta.$promise) {
                // ES COMPROBANTE CONTADO
                vm.modelo.comprobanteVenta.$promise.then(function(comprobante) {
                    vm.modelo.tipoCambio = vm.moneda.codigo == comprobante.moneda.codigo ?
                        1 : null
                        vm.cambio;
                    vm.modelo.importePagar = vm.modelo.importeConvertido;
                    vm.modelo.pagado = vm.modelo.comprobanteVenta.montoTotal - vm.modelo.comprobanteVenta.saldo;
                });
                console.log("1vm.modelo", vm.modelo);
            } else {
                // ES CREDITO, CUOTA
                var moneda = undefined;
                moneda = vm.modelo.cuota ?
                    vm.modelo.cuota.planCobros.comprobanteVenta.moneda.codigo :
                    vm.modelo.comprobanteVenta.moneda.codigo;
                vm.modelo.tipoCambio = vm.moneda.codigo ==
                    moneda ?
                    1 : null
                    vm.cambio;
                vm.modelo.importePagar = vm.modelo.importeConvertido;
                var montoTotal = undefined;
                var saldo = undefined;
                montoTotal = vm.modelo.cuota ?
                    vm.modelo.cuota.monto :
                    vm.modelo.comprobanteVenta.montoTotal;
                saldo = vm.modelo.cuota ?
                    vm.modelo.cuota.saldo :
                    vm.modelo.comprobanteVenta.saldo;
                vm.modelo.pagado = montoTotal - saldo;

                if(vm.modelo.tipoCambio==null){
                    vm.modelo.saldoConvertido = null;
                }
                if(vm.modelo.tipoCambio==1){
                    vm.modelo.importePagar = saldo;
                    vm.modelo.saldoConvertido = vm.modelo.cuota.saldo;
                }

                console.log("2vm.modelo", vm.modelo);
            }
        } else {
            tipoCambioChanged();
            // vm.modelo.importePagar=vm.modelo.importeConvertido;
            // vm.modelo.pagado=vm.modelo.comprobanteVenta.montoTotal-vm.modelo.comprobanteVenta.saldo;
            // console.log("vm.modelo",vm.modelo);
        }
    }

    function importeCobrarChanged() {
        //TODO: Validar mayor a 0 y menor a saldo
        vm.incluir = true;
        $timeout(vm.onImporteCobrarChanged, 0);
    }

    function plusClicked() {
        vm.mostrarAdicionales = !vm.mostrarAdicionales;
    }

    function deleteClicked() {
        $timeout(vm.onDelete, 0);
        $timeout(vm.onImporteCobrarChanged, 0);
    }

    function updateListener(){
        $timeout(vm.onChange, 0);
    }

    /*
       Esta funcion se invoca al modificar el 'tipo cambio' (en la vista) y tambien al cargar la pagina del formulario de cobro.
       changeUser = true               --->   Usuario modifico el campo tipoCambio
       changeUser = undefined, false   --->   Se esta cargando la pagina
    */
    function tipoCambioChanged(changeUser) {
        var saldo = vm.modelo.cuota ?
            vm.modelo.cuota.saldo :
            vm.modelo.comprobanteVenta.saldo;
        var moneda = undefined;
        // moneda = vm.modelo.cuota
        // ? vm.modelo.cuota.planCobros.comprobanteVenta.moneda.codigo
        // : vm.modelo.comprobanteVenta.moneda.codigo;
        moneda = vm.modelo.comprobanteVenta.moneda.codigo;
        var cantDecimales = 0;
        var operacion = "mult";
        if (vm.moneda.codigo !== 'guaranies') {
            cantDecimales = 2;

            if (moneda == 'guaranies') {
                operacion = "div";
            }
        }
        if((vm.modelo.tipoCambio != null) && (vm.modelo.tipoCambio != 0)){
            if (operacion === "mult") {
                vm.viewModel.saldoConvertido = parseFloat((saldo * vm.modelo.tipoCambio).toFixed(cantDecimales));

                // El importe pagado se muestra unicamente cuando el cobro esta emitido (ver en la vista del controlador)
                vm.importePagado = parseFloat((vm.modelo.montoPagado * vm.modelo.tipoCambio).toFixed(cantDecimales));

            } else {
                vm.viewModel.saldoConvertido = parseFloat((saldo / vm.modelo.tipoCambio).toFixed(cantDecimales));

                // El importe pagado se muestra unicamente cuando el cobro esta emitido (ver en la vista del controlador)
                vm.importePagado = parseFloat((vm.modelo.montoPagado / vm.modelo.tipoCambio).toFixed(cantDecimales));
            }

            /*
                Si el usuario modifico el campo tipoCambio, se debe calcular (actualizar el valor). De lo contrario no
                hacer nada (mostrara entonces el valor persistido en vez de calcularlo).

                El importePagar no siempre es igual al saldoConvertido, debido a los cobros parciales?. Ademas no tiene
                sentido calcular un valor que en realidad se persiste.
             */
            if (changeUser) {
                vm.modelo.importePagar = vm.modelo.saldoConvertido;
            }

        }else{
            vm.viewModel.saldoConvertido = "";
            vm.viewModel.importePagar = "";
        }

        $timeout(vm.onChange, 0);

        // if (
        //   vm.moneda.codigo != "guaranies" &&
        //   vm.modelo.comprobanteVenta.moneda.codigo == "guaranies"
        // ) {
        //   vm.viewModel.saldoConvertido = parseFloat(
        //     (saldo / vm.modelo.tipoCambio).toFixed(4)
        //   );
        // } else {
        //   vm.viewModel.saldoConvertido = parseFloat(
        //     (saldo * vm.modelo.tipoCambio).toFixed(4)
        //   );

        // }

        // if (
        //   vm.moneda.codigo != "guaranies" &&
        //   vm.modelo.comprobanteVenta.moneda.codigo == "guaranies"
        // ) {
        //   vm.viewModel.importeConvertido = parseFloat(
        //     (vm.modelo.importePagar / vm.modelo.tipoCambio).toFixed(4)
        //   );
        // } else {
        //   vm.viewModel.importeConvertido = parseFloat(
        //     (vm.modelo.importePagar * vm.modelo.tipoCambio).toFixed(4)
        //   );
        // }
    }
    function importePagarChanged() {
        //TODO: Validar mayor a 0 y menor a saldo
        // vm.incluir = true;
        $timeout(vm.onImportePagarChanged, 0);
    }
}

"use strict";

angular
  .module("qualita.venta")
  .controller("CierreCobroIndexCtrl", ["$scope", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "CobroFactory", "RecepcionFactory", "VentasLangFactory", "ProcesoCobroFactory", "$rootScope", "$filter", "notify", "ParametrosFactory", "CotizacionMonedaFactory", "CobroDetalleFactory", "MovimientoBancarioFactory", "$timeout", function (
    $scope,
    filterFactory,
    AuthorizationService,
    ReportTicketFactory,
    $modal,
    $window,
    $state,
    CobroFactory,
    RecepcionFactory,
    VentasLangFactory,
    ProcesoCobroFactory,
    $rootScope,
    $filter,
    notify,
    ParametrosFactory,
    CotizacionMonedaFactory,
    CobroDetalleFactory,
    MovimientoBancarioFactory,
    $timeout
  ) {
    VentasLangFactory.getTranslations();
    var vm = this;
    vm.cancel = cancel;
    vm.submit = submit;
    vm.generateReport = generateReport;
    vm.diferencia = false;
    vm.submitQuestion = submitQuestion;
    vm.soloLectura = ProcesoCobroFactory.getSoloLectura();

    vm.cargaDatos={};

    vm.cargaDatos.a = true;
    vm.cargaDatos.b = true;
    vm.cargaDatos.c = true;
    vm.cargaDatos.d = true;
    vm.cargaDatos.e = true;
    vm.cargaDatos.f = true;

    $rootScope.isProcessing = false;

  
    if ($state.includes("app.procesoscobro.verproceso.cierre.list")) {
      vm.soloLectura = true;
    } else {
      vm.soloLectura = false;
    }

    var dateRender = function (data, type, full) {
      if (data) {
        return moment.utc(data).format("DD/MM/YYYY");
      } else {
        return "";
      }
    };

    var emptyDataRender = function (data) {
      if (data == undefined) return "";
      else return data;
    };

    vm.procesoCobro = ProcesoCobroFactory.getProcesoCobro();

    if (vm.procesoCobro.totalDepositar) {
      vm.recaudaciones = parseFloat(vm.procesoCobro.totalDepositar).toFixed(0);
    } else {
      vm.recaudaciones = 0;
    }

    //cheques diferidos
    var filtersArray = [];
    filtersArray.push({
      path: "fechaPagoDiferido",
      isNotNull: true
    });
    filtersArray.push(
      {
        path: 'numeroCheque',
        notEquals: 'null'
      }
    );
    filtersArray.push({
      path: "cobro.estado.codigo",
      equals: "estado_cobro_emitido"
    });
    filtersArray.push({
      path: "cobro.procesoCobro.id",
      equals: vm.procesoCobro.id
    });
    var filters = { search: filterFactory.and(filtersArray).value() };
    vm.diferidos = [{}];
    CobroDetalleFactory.all(filters, "ProcesoCobroCierre").$promise.then(function (response) {
      vm.cargaDatos.a = false;

      vm.diferidos = response;
      vm.diferidosByMoneda = _.groupBy(vm.diferidos, function (
        detalle
      ) {
        return detalle.moneda.descripcion;
      });

      _.forEach(vm.diferidosByMoneda, function (detalle, index) {
        vm.diferidosByMoneda[index] = {
          moneda: detalle[0].moneda,
          importe: _.reduce(
            detalle,
            function (sum, montoDetalle) {
              return sum + montoDetalle.importe;
            },
            0
          )
        };
      });

      vm.diferidosByMoneda = _.map(vm.diferidosByMoneda, function (
        detalle
      ) {
        if (detalle.moneda.codigo === "dolares") {
          detalle.importe = parseFloat(detalle.importe).toFixed(2);
        } else if (detalle.moneda.codigo === "guaranies") {
          detalle.importe = parseFloat(detalle.importe).toFixed(0);
        }
        return detalle;
      });

      var sumatoriaDiferidoGuaranies = _.find(vm.diferidosByMoneda, function (elem) {
        return elem.moneda.codigo === 'guaranies';
      });

      var sumatoriaDiferidoDolares = _.find(vm.diferidosByMoneda, function (elem) {
        return elem.moneda.codigo === 'dolares';
      });

      sumatoriaDiferidoGuaranies = (sumatoriaDiferidoGuaranies != undefined) ? sumatoriaDiferidoGuaranies.importe : 0
      sumatoriaDiferidoDolares = (sumatoriaDiferidoDolares != undefined) ? sumatoriaDiferidoDolares.importe : 0

      vm.totalDiferidoGs = sumatoriaDiferidoGuaranies;
      vm.totalDiferidoUsd = sumatoriaDiferidoDolares;

      vm.diferidoValorizado = [];
      var sumatoriaValorizadoDif;
      if (vm.procesoCobro.cambio) {
        sumatoriaValorizadoDif = sumatoriaDiferidoDolares * vm.procesoCobro.cambio + parseInt(sumatoriaDiferidoGuaranies);
        sumatoriaValorizadoDif = parseFloat(sumatoriaValorizadoDif).toFixed(0);
        //para resumen
        vm.totalDiferidoCambio = vm.totalDiferidoUsd * vm.procesoCobro.cambio;
        vm.totalDiferidoCambio = parseFloat(vm.totalDiferidoCambio).toFixed(0);
        vm.totalConceptoMenosDiferido = parseInt(vm.totalDiferidoGs) + parseInt(vm.totalDiferidoCambio);

        vm.diferidoValorizado.push({ importe: sumatoriaValorizadoDif, totalDiferido: vm.totalConceptoMenosDiferido });
      }
    });

    //cheques al dia
    var filtersArray = [];
    filtersArray.push({
      path: "fechaPagoDiferido",
      isNull: true
    });
    filtersArray.push(
      {
        path: 'numeroCheque',
        notEquals: 'null'
      }
    );
    filtersArray.push({
      path: "cobro.estado.codigo",
      equals: "estado_cobro_emitido"
    });
    filtersArray.push({
      path: "cobro.procesoCobro.id",
      equals: vm.procesoCobro.id
    });
    var filters = { search: filterFactory.and(filtersArray).value() };
    vm.dia = [{}];
    CobroDetalleFactory.all(filters, "ProcesoCobroCierre").$promise.then(function (response) {
      vm.cargaDatos.b = false;
      vm.dia = response;
      vm.diaByMoneda = _.groupBy(vm.dia, function (
        detalle
      ) {
        return detalle.moneda.descripcion;
      });

      _.forEach(vm.diaByMoneda, function (detalle, index) {
        vm.diaByMoneda[index] = {
          moneda: detalle[0].moneda,
          importe: _.reduce(
            detalle,
            function (sum, montoDetalle) {
              return sum + montoDetalle.importe;
            },
            0
          )
        };
      });

      vm.diaByMoneda = _.map(vm.diaByMoneda, function (
        detalle
      ) {
        if (detalle.moneda.codigo === "dolares") {
          detalle.importe = parseFloat(detalle.importe).toFixed(2);
        } else if (detalle.moneda.codigo === "guaranies") {
          detalle.importe = parseFloat(detalle.importe).toFixed(0);
        }
        return detalle;
      });

      var sumatoriadiaGuaranies = _.find(vm.diaByMoneda, function (elem) {
        return elem.moneda.codigo === 'guaranies';
      });

      var sumatoriadiaDolares = _.find(vm.diaByMoneda, function (elem) {
        return elem.moneda.codigo === 'dolares';
      });

      sumatoriadiaGuaranies = (sumatoriadiaGuaranies != undefined) ? sumatoriadiaGuaranies.importe : 0
      sumatoriadiaDolares = (sumatoriadiaDolares != undefined) ? sumatoriadiaDolares.importe : 0

      vm.totaldiaGs = sumatoriadiaGuaranies;
      vm.totaldiaUsd = sumatoriadiaDolares;

      vm.diaValorizado = [];
      var sumatoriaValorizadodia;
      if (vm.procesoCobro.cambio) {
        sumatoriaValorizadodia = sumatoriadiaDolares * vm.procesoCobro.cambio + parseInt(sumatoriadiaGuaranies);
        sumatoriaValorizadodia = parseFloat(sumatoriaValorizadodia).toFixed(0);
        //para resumen
        vm.totaldiaCambio = vm.totaldiaUsd * vm.procesoCobro.cambio;
        vm.totaldiaCambio = parseFloat(vm.totaldiaCambio).toFixed(0);
        vm.totalConceptoMenosdia = parseInt(vm.totaldiaGs) + parseInt(vm.totaldiaCambio);

        vm.diaValorizado.push({ importe: sumatoriaValorizadodia, totaldia: vm.totalConceptoMenosdia });
      }
    });
    //hasta aca cheques al dia

    var cobrosIds = _.map(vm.procesoCobro.cobros, function (cobro) {
      return cobro.id;
    });

    var filterCobros = {
      search: filterFactory
        .or(
          _.map(cobrosIds, function (id) {
            return {
              path: "id",
              equals: id
            };
          })
        )
        .value()
    };
    vm.cobros = CobroFactory.all(filterCobros, "ProcesoCobroCierre").$promise.then(function (
      response
    ) {
      vm.cargaDatos.c = false;
      vm.cobros = response;
      vm.cobrosDetalles = _.flatten(
        _.map(vm.cobros, function (cobro) {
          return cobro.cobroDetalle;
        })
      );

      //Iterar sobre detalles de cobro para cambiar formato de fecha
      for (var i = 0; i < vm.cobrosDetalles.length; i++) {
        //console.log(moment.utc(vm.cobrosDetalles[i].fecha).format("DD/MM/YYYY"));
        if (vm.cobrosDetalles[i].fechaPagoDiferido) {
          vm.cobrosDetalles[i].fechaPagoDiferido = moment.utc(vm.cobrosDetalles[i].fechaPagoDiferido).format("DD/MM/YYYY");
        }
      }

      // Se eliminan los detalles de cobro anulados, tambien se agregan datos del cliente
      // a los detalles que no se encuentren anulados
      vm.cobrosDetalles2 = [{}];
      vm.cobrosDetalles2 = vm.cobrosDetalles;
      var k = 0;
      for (var i = 0; i < vm.cobros.length; i++) {
        for (var j = 0; j < vm.cobros[i].cobroDetalle.length; j++) {
          // Si no esta anulado el cobro, agregar datos del cliente
          if (vm.cobros[i].estado.codigo != 'estado_cobro_anulado') {
            vm.cobrosDetalles2[k].cobro.cliente = vm.cobros[i].cliente;
            k++;
          }
          //Si el cobro esta anulado, eliminar detalle cobro de la lista de detalles
          else {
            _.remove(vm.cobrosDetalles2, function (item) {
              return item === vm.cobrosDetalles2[k];
            });
          }
        }
      }

      // AGRUPACIÓN POR FORMA DE PAGO Y MONEDA
      vm.cobrosDetallesByFormaPago = _.groupBy(vm.cobrosDetalles, function (
        detalle
      ) {
        return detalle.formaPago.descripcion;
      });
      _.forEach(vm.cobrosDetallesByFormaPago, function (formaPago, index) {
        vm.cobrosDetallesByFormaPago[index] = _.groupBy(formaPago, function (
          detalle
        ) {
          return detalle.moneda.codigo;
        });
      });
      _.forEach(vm.cobrosDetallesByFormaPago, function (formaPago, index) {
        vm.cobrosDetallesByFormaPago[index] = _.map(
          vm.cobrosDetallesByFormaPago[index],
          function (detalle, index) {
            return {
              moneda: detalle[0].moneda,
              formaPago: detalle[0].formaPago,
              importe: _.reduce(
                detalle,
                function (sum, montoDetalle) {
                  return sum + montoDetalle.importe;
                },
                0
              )
            };
          }
        );
      });

      //aplanamos la estrcutura
      vm.cobrosDetallesByFormaPagoFlatten = [];
      _.forEach(vm.cobrosDetallesByFormaPago, function (formaPago, index) {
        vm.cobrosDetallesByFormaPagoFlatten.push(
          _.map(vm.cobrosDetallesByFormaPago[index], function (detalle) {
            return detalle;
          })
        );
      });
      vm.cobrosDetallesByFormaPagoFlatten = _.flatten(
        vm.cobrosDetallesByFormaPagoFlatten
      );

      //AGRUPACIÓN POR MONEDA
      vm.cobrosDetallesByMoneda = _.groupBy(vm.cobrosDetalles, function (
        detalle
      ) {
        return detalle.moneda.descripcion;
      });

      _.forEach(vm.cobrosDetallesByMoneda, function (detalle, index) {
        vm.cobrosDetallesByMoneda[index] = {
          moneda: detalle[0].moneda,
          importe: _.reduce(
            detalle,
            function (sum, montoDetalle) {
              return sum + montoDetalle.importe;
            },
            0
          )
        };
      });

      vm.cobrosDetallesByMoneda = _.map(vm.cobrosDetallesByMoneda, function (
        detalle
      ) {
        if (detalle.moneda.codigo === "dolares") {
          detalle.importe = parseFloat(detalle.importe).toFixed(2);
        } else if (detalle.moneda.codigo === "guaranies") {
          detalle.importe = parseFloat(detalle.importe).toFixed(0);
        }
        return detalle;
      });

      // Sumatoria de cobros
      var sumatoriaCobrosGuaranies = _.find(vm.cobrosDetallesByMoneda, function (elem) {
        return elem.moneda.codigo === 'guaranies';
      });

      var sumatoriaCobrosDolares = _.find(vm.cobrosDetallesByMoneda, function (elem) {
        return elem.moneda.codigo === 'dolares';
      });

      sumatoriaCobrosGuaranies = (sumatoriaCobrosGuaranies != undefined) ? sumatoriaCobrosGuaranies.importe : 0
      sumatoriaCobrosDolares = (sumatoriaCobrosDolares != undefined) ? sumatoriaCobrosDolares.importe : 0

      vm.totalCobroGs = sumatoriaCobrosGuaranies;
      vm.totalCobroUsd = sumatoriaCobrosDolares;

      vm.cobrosValorizado = [];

      var sumatoriaValorizado;
      if (vm.procesoCobro.cambio) {
        sumatoriaValorizado = sumatoriaCobrosDolares * vm.procesoCobro.cambio + parseInt(sumatoriaCobrosGuaranies);
        sumatoriaValorizado = parseFloat(sumatoriaValorizado).toFixed(0);
        vm.totalCobroValorizado = sumatoriaValorizado;
        //para resumen cobros
        vm.totalCobroCambio = vm.totalCobroUsd * vm.procesoCobro.cambio;
        vm.totalCobroCambio = parseFloat(vm.totalCobroCambio).toFixed(0);
        vm.totalConceptoMas = parseInt(vm.recaudaciones) + parseInt(vm.totalCobroGs) + parseInt(vm.totalCobroCambio);
        vm.cobrosValorizado.push({ importe: sumatoriaValorizado });
      }

      console.log("fin");
    });

    //depositos con y sin cobro
    var filterDeposito = {};
    filterDeposito.search = filterFactory.and([{
      path: 'procesoCobro.id',
      equals: vm.procesoCobro.id
    }
    ]).value();

    MovimientoBancarioFactory.all(filterDeposito).$promise.then(function (deposito) {
      vm.cargaDatos.d = false;
      vm.depositos = deposito;
      vm.depositosByMoneda = _.groupBy(vm.depositos, function (deposito) {
        return deposito.cuenta.moneda.descripcion;
      });
      _.forEach(vm.depositosByMoneda, function (deposito, index) {
        vm.depositosByMoneda[index] = {
          moneda: deposito[0].cuenta.moneda,
          importe: _.reduce(
            deposito,
            function (sum, depositoDetalle) {
              return sum + depositoDetalle.entrada;
            },
            0
          )
        };
      });

      vm.depositosByMoneda = _.map(vm.depositosByMoneda, function (deposito) {
        return deposito;
      });

      var sumatoriaDepositosGuaranies = _.find(vm.depositosByMoneda, function (elem) {
        return elem.moneda.codigo === 'guaranies';
      });

      var sumatoriaDepositosDolares = _.find(vm.depositosByMoneda, function (elem) {
        return elem.moneda.codigo === 'dolares';
      });

      sumatoriaDepositosGuaranies = (sumatoriaDepositosGuaranies != undefined) ? sumatoriaDepositosGuaranies.importe : 0
      sumatoriaDepositosDolares = (sumatoriaDepositosDolares != undefined) ? sumatoriaDepositosDolares.importe : 0

      vm.totalDepositoGs = sumatoriaDepositosGuaranies;
      vm.totalDepositoUsd = sumatoriaDepositosDolares;

      vm.depositoValorizado = [];
      var sumatoriaValorizadoDeposito;
      if (vm.procesoCobro.cambio) {
        sumatoriaValorizadoDeposito = sumatoriaDepositosDolares * vm.procesoCobro.cambio + parseInt(sumatoriaDepositosGuaranies);
        sumatoriaValorizadoDeposito = parseInt(sumatoriaValorizadoDeposito).toFixed(0);
        //para resumen
        vm.totalDepositoCambio = vm.totalDepositoUsd * vm.procesoCobro.cambio;
        vm.totalDepositoCambio = parseInt(vm.totalDepositoCambio).toFixed(0);
        //retenciones y cuenta contable
        CobroFactory.all(filterCobros, "ProcesoCobroCierre").$promise.then(function (
          response1
        ) {
          vm.cargaDatos.e = false;

          vm.cobros1 = response1;
          vm.cobrosDetalles1 = _.flatten(
            _.map(vm.cobros1, function (cobro) {
              return cobro.cobroDetalle;
            })
          );

          // Se eliminan los detalles de cobro anulados, tambien se agregan datos del cliente
          // a los detalles que no se encuentren anulados
          vm.cobrosDetallesr = [{}];
          vm.cobrosDetallesr = vm.cobrosDetalles1;
          var k = 0;
          for (var i = 0; i < vm.cobros1.length; i++) {
            for (var j = 0; j < vm.cobros1[i].cobroDetalle.length; j++) {
              // Si no esta anulado el cobro, agregar datos del cliente
              if (vm.cobros1[i].estado.codigo != 'estado_cobro_anulado') {
                vm.cobrosDetallesr[k].cobro.cliente = vm.cobros1[i].cliente;
                k++;
              }
              //Si el cobro esta anulado, eliminar detalle cobro de la lista de detalles
              else {
                _.remove(vm.cobrosDetallesr, function (item) {
                  return item === vm.cobrosDetallesr[k];
                });
              }
            }
          }

          //RETENCIONES
          vm.sumaRetencionesGs = 0;
          vm.sumaRetencionesUsd = 0;
          _.forEach(vm.cobrosDetallesr, function (detalle) {
            if (detalle.formaPago.clase.codigo == 'forma_pago_retención') {
              if (detalle.moneda.codigo == 'guaranies') {
                vm.sumaRetencionesGs += detalle.importe;
              } else if (detalle.moneda.codigo == 'dolares') {
                vm.sumaRetencionesUsd += detalle.importe;
              }
            }
          });
          vm.retenciones = [{ totalRetencionesGs: vm.sumaRetencionesGs + vm.sumaRetencionesUsd }];
          vm.totalRCambio = vm.sumaRetencionesUsd * vm.procesoCobro.cambio;
          vm.totalRCambio = parseFloat(vm.totalRCambio).toFixed(0);
          vm.totalConceptoMenosRetenciones = parseInt(vm.sumaRetencionesGs) + parseInt(vm.totalRCambio);
          //hasta aca retenciones

          //cuenta contable
          vm.sumaCuentasGs = 0;
          vm.sumaCuentasUsd = 0;
          _.forEach(vm.cobrosDetallesr, function (detalle) {
            if (detalle.formaPago.clase.codigo == 'forma_pago_cuenta_contable') {
              if (detalle.moneda.codigo == 'guaranies') {
                vm.sumaCuentasGs += detalle.importe;
              } else if (detalle.moneda.codigo == 'dolares') {
                vm.sumaCuentasUsd += detalle.importe;
              }
            }
          });
          vm.cuentas = [{ totalCuentasGs: vm.sumaCuentasGs + vm.sumaCuentasUsd }];
          vm.totalCcCambio = vm.sumaCuentasUsd * vm.procesoCobro.cambio;
          vm.totalCcCambio = parseFloat(vm.totalCcCambio).toFixed(0);
          vm.totalConceptoMenosCuentas = parseInt(vm.sumaCuentasGs) + parseInt(vm.totalCcCambio);
          //hasta aca cuenta contable

          vm.totalConceptoMenos = parseInt(vm.totalDepositoGs) + parseInt(vm.totalDepositoCambio) + vm.diferidoValorizado[0].totalDiferido + vm.totalConceptoMenosRetenciones + vm.totalConceptoMenosCuentas;
        })

        vm.depositoValorizado.push({ importe: sumatoriaValorizadoDeposito });
      }else{
        vm.cargaDatos.e = false;
      }
    });

    //obtener los cheques diferidos vencidos a depositar
    ParametrosFactory.getByCodigo('cheques_diferidos_cobro').then(function (parametro) {
      if (parametro.valorTipo.codigo === 'si') {
        vm.mostrarCampoChequeDiferido = true;
        //recuperar todos los cheques de la sucursal actual
        var filterSucursal = {
          search: filterFactory
            .and([
              {
                path: "cobro.sucursal.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
              },
              {
                path: "cobro.estado.codigo",
                notEquals: "estado_cobro_anulado"
              },
              {
                path: 'fechaPagoDiferido',
                equalOrBefore: moment(vm.procesoCobro.fechaHoraInicio).format('DD/MM/YYYY')
              }
            ])
            .value()
        };
        CobroDetalleFactory.all(filterSucursal).$promise.then(function (response) {
          vm.cargaDatos.f = false;


          vm.totalChequesVencidos = 0;
          if (vm.procesoCobro.cambio) {
            _.forEach(response, function (detalle) {
              if (detalle.moneda.codigo == 'dolares') {
                vm.totalChequesVencidos += detalle.importe * vm.procesoCobro.cambio;
              } else {
                vm.totalChequesVencidos += detalle.importe;
              }
            });
          }
        });
      } else {
        vm.mostrarCampoChequeDiferido = false;
        vm.totalChequesVencidos = 0;
        vm.cargaDatos.f = false;
      }
    });

    if (!vm.procesoCobro) {
      $state.go("app.main");
    }

    var isStateEnabled = ProcesoCobroFactory.checkPermissionForState(2);
    if (!isStateEnabled) {
      //console.log("No tiene permiso para revisar esta seccion");
      $state.go("app.main");
    }

    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({ path: data, like: search });
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    };

    function cancel() {
      $state.go("app.procesoscobro.list");
    }

    function generateReport() {
      var filtersArr = [];
      filtersArr.push("id_proceso_cobro=" + ProcesoCobroFactory.getIdProcesoCobro());
      filtersArr.push("recaudacion_depositar=" + vm.recaudaciones);
      //param cobro
      filtersArr.push("total_cobro_gs=" + vm.totalCobroGs);
      filtersArr.push("total_cobro_usd=" + vm.totalCobroUsd);
      filtersArr.push("cambio=" + vm.procesoCobro.cambio);
      filtersArr.push("total_cobro_usd_convertido=" + vm.totalCobroCambio);
      filtersArr.push("total_concepto=" + vm.totalConceptoMas);

      //param deposito
      filtersArr.push("total_deposito_gs=" + vm.totalDepositoGs);
      filtersArr.push("total_deposito_usd=" + vm.totalDepositoUsd);
      filtersArr.push("total_deposito_usd_convertido=" + vm.totalDepositoCambio);

      //param cheques
      filtersArr.push("total_diferido_gs=" + vm.totalDiferidoGs);
      filtersArr.push("total_diferido_usd=" + vm.totalDiferidoUsd);
      filtersArr.push("total_diferido_usd_convertido=" + vm.totalDiferidoCambio);

      //param cheques al dia
      filtersArr.push("total_dia_gs=" + vm.totaldiaGs);
      filtersArr.push("total_dia_usd=" + vm.totaldiaUsd);
      filtersArr.push("total_dia_usd_convertido=" + vm.totaldiaCambio);

      //param retencion
      filtersArr.push("total_retencion_gs=" + vm.sumaRetencionesGs);
      filtersArr.push("total_retencion_usd=" + vm.sumaRetencionesUsd);
      filtersArr.push("total_retencion_usd_convertido=" + vm.totalRCambio);

      //param compensacion
      filtersArr.push("total_cuenta_gs=" + vm.sumaCuentasGs);
      filtersArr.push("total_cuenta_usd=" + vm.sumaCuentasUsd);
      filtersArr.push("total_cuenta_usd_convertido=" + vm.totalCcCambio);

      filtersArr.push("total_valores=" + vm.totalConceptoMenos);
      var totalOperaciones = parseInt(vm.totalConceptoMas) - parseInt(vm.totalConceptoMenos);
      filtersArr.push("total_operaciones=" + totalOperaciones);

      filtersArr.push("total_cheques_vencidos=" + vm.totalChequesVencidos);
      var totalCierre = totalOperaciones + vm.totalChequesVencidos;
      filtersArr.push("total_cierre=" + totalCierre);

      var totalCobroValorizado = parseInt(vm.totalCobroGs) + parseInt(vm.totalCobroCambio);
      filtersArr.push("total_cobro_valorizado=" + totalCobroValorizado);
      filtersArr.push("total_fp_valorizado=" + totalCobroValorizado);
      var totalDepositoValorizado = parseInt(vm.totalDepositoGs) + parseInt(vm.totalDepositoCambio);
      filtersArr.push("total_deposito_valorizado=" + totalDepositoValorizado);
      var totalDiferidoValorizado = parseInt(vm.totalDiferidoGs) + parseInt(vm.totalDiferidoCambio);
      filtersArr.push("total_diferido_valorizado=" + totalDiferidoValorizado);
      var totalDiaValorizado = parseInt(vm.totaldiaGs) + parseInt(vm.totaldiaCambio);
      filtersArr.push("total_dia_valorizado=" + totalDiaValorizado);

      vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
        "cierrecobro",
        "pdf",
        filtersArr
      );
      $window.open(vm.pdfDownloadURL, "_blank");
      $state.go($state.current, {}, { reload: true });
    }

    function submitQuestion() {
      $scope.tituloModal = $filter("translate")("CERRAR_COBRO_TITLE");
      $scope.mensajeModal = $filter("translate")("CERRAR_COBRO_MSG");
      $scope.si_no = true;
      var modalInstance = $modal.open({
        template: '<div class="modal-header">' +
          '<h3 class="modal-title">{{::tituloModal}}</h3>' +
          '</div>' +
          '<div class="modal-body">{{::mensajeModal}}' +
          '</div>' +
          '<div class="modal-footer">' +
          '<button class="btn btn-primary" ng-click="ok(selectedItemId);">' + $filter('translate')('OK') + '</button>' +
          '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
          '</div>',
        scope: $scope
      });

      $scope.cancel = function () {
        modalInstance.dismiss("cancel");
      };

      $scope.ok = function (itemId) {
        var noEmitidos = false;
        for (var i = 0; i < vm.cobros.length; i++) {
          if (vm.cobros[i].estado.codigo != 'estado_cobro_emitido' && vm.cobros[i].estado.codigo != 'estado_cobro_anulado') {
            noEmitidos = true;
          }

          for (var j = 0; j < vm.cobros[i].depositos.length; j++) {
            if (vm.cobros[i].depositos[j].confirmado != true) {
              noEmitidos = true;
            }
          }
        }

        if (noEmitidos == false) {
          modalInstance.close();
          submit();
        } else {
          notify({
            message: "Existen cobros o depósitos sin confirmar",
            classes: "alert-danger",
            position: "right"
          });
        }
      };
    }

    function submit() {
      vm.submited = true;
      if ($scope.CierreCobroForm.$valid) {
        $rootScope.isProcessing = true;
        submitCierre().then(
          function (response) {
            //guardar la recaudacion del cierre
            ProcesoCobroFactory.get(vm.procesoCobro.id).$promise.then(function (response1) {
              response1.recaudacionDepositarCierre = (vm.totalConceptoMas - vm.totalConceptoMenos) + vm.totalChequesVencidos;
              ProcesoCobroFactory.save(response1);
            });

            console.log("response ", response)
            $state.go("app.procesoscobro.list");
            return response;
          },
          function (error) {
            $rootScope.isProcessing = false;
            console.log("Error al persistir entidad: ");
            console.log(error);
            //var msg = $scope.translations.ERROR_CIERRE_IMPORTACION;
            notify({
              message: error.data[0].message,
              classes: "alert-danger",
              position: "right"
            });
          }
        );
      } else {
        $rootScope.isProcessing = false;
        console.log(errors);
        notify({
          messageTemplate: errors,
          classes: "alert-danger",
          position: "right"
        });
      }
    }

    function submitCierre() {
      return ProcesoCobroFactory.cerrar(
        ProcesoCobroFactory.getIdProcesoCobro(),
        $rootScope.AuthParams.accesoSistema.usuario.id
      );
    }
  }]);

angular.module("qualita.venta").factory("PlanCobrosFactory", PlanCobrosFactory);

PlanCobrosFactory.$inject = ["$resource", "baseurl"];

function PlanCobrosFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var PlanCobro = $resource(
    baseurl.getBaseUrl() + "/plancobros/:id",
    { id: "@id" },
    {
      update: {
        method: "PUT"
      }
    }
  );

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : "base";
    } else {
      params = [];
      params.view = view != undefined ? view : "base";
    }
    return PlanCobro.query(params);
  }

  function get(id, view) {
    return PlanCobro.get({ id: id, view: view != undefined ? view : "base" });
  }

  function create(attrs) {
    return new PlanCobro(attrs);
  }

  function save(planCobro) {
    return planCobro.id ? planCobro.$update() : planCobro.$save();
  }

  function remove(planCobro) {
    return planCobro.$remove();
  }
}

angular
  .module("qualita.venta")
  .factory("PlanCobrosDetalleFactory", PlanCobrosDetalleFactory);

PlanCobrosDetalleFactory.$inject = ["$resource", "baseurl", "$http"];

function PlanCobrosDetalleFactory($resource, baseurl, $http) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    verificarcobrocuota: verificarcobrocuota,
  };

  var PlanCobro = $resource(
    baseurl.getBaseUrl() + "/plancobrosdetalle/:id",
    { id: "@id" },
    {
      update: {
        method: "PUT"
      }
    }
  );

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : "base";
    } else {
      params = [];
      params.view = view != undefined ? view : "base";
    }
    return PlanCobro.query(params);
  }

  function get(id, view) {
    return PlanCobro.get({ id: id, view: view != undefined ? view : "base" });
  }

  function create(attrs) {
    return new PlanCobro(attrs);
  }

  function save(planCobro) {
    return planCobro.id ? planCobro.$update() : planCobro.$save();
  }

  function remove(planCobro) {
    return planCobro.$remove();
  }

  function verificarcobrocuota(id) {
    return $http({
        url: baseurl.getBaseUrl() + '/plancobrosdetalle/verificarcobrocuota/' + id,
        method: "GET"
    });
}
}

"use strict";

angular
    .module("qualita.venta")
    .controller("PlanCobrosIndexCtrl", ["$scope", "AuthorizationService", "ServicioFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "ComprobanteFactory", "$state", "$stateParams", "$modal", "PlanCobrosFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", function(
        $scope,
        AuthorizationService,
        ServicioFactory,
        filterFactory,
        ReportTicketFactory,
        $window,
        VentasLangFactory,
        ComprobanteFactory,
        $state,
        $stateParams,
        $modal,
        PlanCobrosFactory,
        $filter,
        UtilFactory,
        CsvFactory,
        baseurl
    ) {
        var defaultColumnOrder = [
            "code",
            "fecha",
            "comprobanteVenta.numero",
            "comprobanteVenta.cliente.nombre",
            "comprobanteVenta.estado.descripcion",
            "comprobanteVenta.montoTotal",
            "fechaVencimiento",
            "montoInicial",
            "saldo",
            "estado.descripcion",
            "cantidadCuotas",
            "plazoCuotas"
        ];

        VentasLangFactory.getTranslations().then(function(translations) {
            var monedaRender = function(data, type, row) {
                if (data) {
                    var moneda = "Gs. ";
                    if (row.comprobanteVenta.moneda.codigo === "dolares") {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    }  else if (row.comprobanteVenta.moneda.codigo === "guaranies") {
                        data = parseFloat(data).toFixed(0);
                    }  else if (row.comprobanteVenta.moneda.codigo === "pesos") {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobanteVenta.moneda.codigo === "real") {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobanteVenta.moneda.codigo === "euro") {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    }
                    return (
                        moneda +
                        data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                } else {
                    return "";
                }
            };


            var staticFilter = {};
            staticFilter.search = filterFactory.and([
            {
                path: 'comprobanteVenta.estado.descripcion',
                notEquals: 'Ingresado'
            }
            ]).value();
            $scope.options = {
                staticFilter: staticFilter,
                resource: "plancobros",
                title: $filter("translate")("PLAN_COBROS"),
                view: "PlanCobrosList",
                factory: PlanCobrosFactory,
                defaultColumnOrder: defaultColumnOrder,
                failedDeleteError: $filter("translate")("PLAN_COBRO_DELETE_FAILED"),
                columns: [
                    { data: "id", title: $filter("translate")("CODE"), visible: false  },
                    {
                        data: "fecha",
                        title: $filter("translate")("FECHA_COMPROBANTE"),
                        renderWith: "dateRender",
                        class: 'dt-center',
                        dateFormat: "DD/MM/YYYY",
                        type: "date-range"
                    },
                    {
                        data: "comprobanteVenta.numero",
                        title: $filter("translate")("COMPROBANTE_NUMERO")
                    },
                    {
                        data: "comprobanteVenta.cliente.nombre",
                        title: $filter("translate")("COMPROBANTE_CLIENTE"),
                        renderWith: "emptyRender"
                    },
                    {
                        data: "comprobanteVenta.montoTotal",
                        title: $filter("translate")("MONTO_TOTAL_COMPROBANTE"),
                        renderWith: monedaRender,
                        class: "dt-right",
                        type: "number-range"
                    },
                    {
                        data: "comprobanteVenta.moneda.descripcion",
                        title: $filter("translate")("MONEDA"),
                        class: "dt-right",
                        visible: false
                    },
                    {
                        data: "comprobanteVenta.estado.descripcion",
                        title: $filter("translate")("COMPROBANTE_ESTADO"),
                        renderWith: "emptyRender",
                        type : 'combo',
                        filterUrl: 'tipos/filtro/estados_comprobante_venta'
                    },
                    {
                        data: "montoInicial",
                        title: $filter("translate")("MONTO_INICIAL"),
                        renderWith: monedaRender,
                        pathAttMoneda: "montoInicial",
                        class: "dt-right",
                        type: "number-range"
                    },
                    {
                        data: "fechaVencimiento",
                        title: $filter("translate")("FECHA_VENCIMIENTO"),
                        renderWith: "dateRender",
                        class: 'dt-center',
                        dateFormat: "DD/MM/YYYY",
                        type: "date-range"
                    },
                    {
                        data: "estado.descripcion",
                        title: $filter("translate")("STATUS"),
                        type : 'combo',
                        filterUrl: 'tipos/filtro/estados_plan_cobros'
                    },
                    {
                        data: "saldo",
                        title: $filter("translate")("SALDO"),
                        renderWith: monedaRender,
                        pathAttMoneda: "saldo",
                        class: "dt-right",
                        type: "number-range"
                    },
                    {
                        data: "cantidadCuotas",
                        title: $filter("translate")("CANTIDAD_CUOTAS"),
                        class: "dt-right",
                        type: "number-range"
                    },
                    {
                        data: "plazoCuotas",
                        title: $filter("translate")("PLAZO_CUOTAS"),
                        class: "dt-right",
                        type: "number-range"
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc",
                hideAddMenu: true,
                hideEditMenu: true,
                hideRemoveMenu: true,
                hideViewMenu: true,
                extraRowOptions: [{
                    templateToRender: "<button class='btn btn-primary' title='" +
                        $filter("translate")("VER_CUOTAS") +
                        "' style='margin-right: 5px;' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-tasks'></span> </button>",
                    customAttribute: "estado",
                    functionName: "ver",
                    functionDef: function(itemId) {
                        // redireccionar a plan de cobro detalle
                        $state.go("app.plancobros.edit", {
                            id: itemId
                        });
                    },
                    conditionName: "canVer",
                    conditionDef: function(estado) {
                        return AuthorizationService.hasPermission(
                            "index_plancobrosdetalle"
                        );
                    }
                }],
                extraMenuOptions:
                [
                    {
                      'title': 'CSV',
                      'icon': 'glyphicon glyphicon-export',
                      'showCondition': function() { return true; },
                      'action': function() {
                          var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                          CsvFactory.csv("plancobros", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                          .then(function(response) {
                            console.log("response de csv: ", response);
                            var fileName = response.data.fileName;
                            $window.open(baseurl.getPublicBaseUrl() + fileName);
                          });
                        }
                    },
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function() { return true; },
                        'action': function() {
                          var filters = createFilters($scope.options.getFilters());
                          ReportTicketFactory.ticket('listadoPlanCobro', filters, $scope.options.tableAjaxParams,
                            $scope.options.currentColumnOrder).then(function(genTicket) {
                            $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                            $window.open($scope.pdfDownloadURL, '_blank');
                          });
                        }
                    }
                ]

            };
        });

        
        var createFilters = function (filters) {
            var filtersArr = [];
            _.each(filters, function (search, data) {
                search = search.split("~");
                if (search.length > 1) {
                    /* El parámetro es un rago */

                    var isDate = search[0].split("/");

                    if (isDate.length > 1) {
                        /* Es un rago de fechas */

                        filtersArr.push({path: data, equalOrAfter: search[0]})
                        filtersArr.push({path: data, equalOrBefore: search[1]})
                    } else {
                        /* El un rago numérco */

                        filtersArr.push({path: data, greaterOrEqual: search[0]})
                        filtersArr.push({path: data, lessOrEqual: search[1]})
                    }
                } else {
                    /* El parametro no es un rago */
                    
        
                    filtersArr.push({path: data, like: search[0]})
                }
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        }
    }]);

"use strict";

angular
  .module("qualita.venta")
  .controller("PlanCobrosFormCtrl", PlanCobrosFormCtrl);

PlanCobrosFormCtrl.$inject = [
  "$rootScope",
  "$scope",
  "PlanCobrosFactory",
  "$location",
  "$state",
  "$stateParams",
  "planCobrosPrepService",
  "notify",
  "formFactory",
  "VentasLangFactory",
  "TiposFactory",
  "$timeout",
  "$filter",
  "UtilFactory",
  "PlanCobrosDetalleFactory"
];

function PlanCobrosFormCtrl(
  $rootScope,
  $scope,
  PlanCobrosFactory,
  $location,
  $state,
  $stateParams,
  planCobrosPrepService,
  notify,
  formFactory,
  VentasLangFactory,
  TiposFactory,
  $timeout,
  $filter,
  UtilFactory,
  PlanCobrosDetalleFactory
) {
  activate();

  $scope.cancel = cancel;
  $scope.submit = submit;
  $scope.changeMonto = changeMonto;
  $scope.removeItemFromArray = removeItemFromArray;
  $scope.agregarDetalle = agregarDetalle;
  $scope.calcularTotalDetalle = calcularTotalDetalle;

  function calcularTotalDetalle(detalle) {
    $scope.totalGeneral =
      _.reduce(
        $scope.planCobro.planCobrosDetalles,
        function (sum, detalle) {
          return sum + detalle.monto;
        },
        0
      );
    $scope.totalGeneral = Math.round($scope.totalGeneral * 100) / 100;
  }

  function agregarDetalle() {
    // Encuentra el número máximo existente en los detalles
    var maxNumero = 0;
    for (var i = 0; i < $scope.planCobro.planCobrosDetalles.length; i++) {
      if ($scope.planCobro.planCobrosDetalles[i].numero > maxNumero) {
        maxNumero = $scope.planCobro.planCobrosDetalles[i].numero;
      }
    }


    // Agrega un nuevo detalle con el número incrementado
    $scope.planCobro.planCobrosDetalles.push({
      id: undefined,
      numero: maxNumero + 1,
      estado: undefined
    });
    TiposFactory.estadosCuotaCobro().then(function (data) {
      var estadoGenerado = _.filter(data.data, function (elem) {
        return elem.codigo === "plancobros_generado";
      });
      $scope.planCobro.planCobrosDetalles[$scope.planCobro.planCobrosDetalles.length - 1].estado = estadoGenerado[0];
      //$scope.planCobroDetalle.estado = estadoGenerado[0];
    });
    var rowNumber = $scope.planCobro.planCobrosDetalles.length - 1;
    $timeout(function () {
      $scope.$broadcast('newItemAdded');
    }, 20);
  }


  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function (item) {
      return item === elemento;
    });
    $timeout(function () {
      $scope.$broadcast('newItemRemoved');
    }, 20);
    calcularTotalDetalle(elemento);
  }

  function activate() {
    $scope.curDate = new Date();
    VentasLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      if ($state.is("^.edit")) {
        activateEdit();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateEdit() {
    $scope.title = "Editar Plan de Cobros";
    $scope.planCobro = planCobrosPrepService;
    $scope.planCobro.comprobanteVenta.fecha = new Date($scope.planCobro.comprobanteVenta.fecha);
    $scope.planCobro.fechaVencimiento = new Date($scope.planCobro.fechaVencimiento);
    _.forEach($scope.planCobro.planCobrosDetalles, function (elem) {
      $scope.calcularTotalDetalle(elem);
    });
    $scope.entidadId = $scope.planCobro.id;
    $scope.entidad = "PlanCobros";
  }

  function changeMonto(detalle, index) {
    //verficar si la cuota tiene cobros asociados.
    if (detalle.id != undefined) {
      PlanCobrosDetalleFactory.verificarcobrocuota(detalle.id).then(function (rr) {
        if (rr.data === true || rr.data === 'true') {
          notify({ message: "La cuota Nro: " + detalle.numero + " que esta tratando de editar ya cuenta con cobros asociados. Favor refrescar su Navegador.", classes: "alert-warning", position: "right" });
          $scope.submited = false;
          $rootScope.isProcessing = true;
          return;
        }
      });
    }

    detalle.saldo = detalle.monto;
    calcularTotalDetalle(detalle);
  }

  function submit() {
    $scope.submited = true;
    if ($scope.PlanCobrosForm.$valid) {
      formFactory
        .defaultNSFSubmit(
          $scope.PlanCobrosForm,
          PlanCobrosFactory,
          $scope.planCobro,
          errorHandler
        )
        .then(
          function (response) {
            $state.go("^.list");
          },
          function (error) {
            console.log(error);
          }
        );
    } else {
      notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right' });
    }
  }

  function submitPlanCobros() {
    var trimmedModel = ModelTrimmer.trimDetails($scope.planCobro, ["planCobrosDetalles"]);
    var trimmedDetalles = _.map($scope.planCobro.planCobrosDetalles, function (detalle) {
      return ModelTrimmer.trimDetails(detalle);
    });
    trimmedModel.planCobrosDetalles = trimmedDetalles;
    var resource = PlanCobrosFactory.create(trimmedModel);
    return PlanCobrosFactory.save(resource);
  }


  function errorHandler(e) {
    var msg = "";
    _.forEach(e.data, function (error) {
      if (error.constraint == "nombre") {
        msg += "\n\n" + $filter("translate")("SAME_NAME_PRODUCT_CLASS") + "\n";
      } else {
        msg += "\n\n" + error.message + ".";
      }
    });
    notify({ message: msg, classes: "alert-danger", position: "right" });
  }

  function cancel() {
    $state.go("^.list");
  }

  $scope.resource = "plancobros";
}

"use strict";

angular
    .module("qualita.venta")
    .controller("PlanCobrosDetalleIndexCtrl", ["$scope", "AuthorizationService", "ServicioFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "ComprobanteVentaFactory", "$state", "$stateParams", "$modal", "PlanCobrosDetalleFactory", "PlanCobrosFactory", "$filter", function(
        $scope,
        AuthorizationService,
        ServicioFactory,
        filterFactory,
        ReportTicketFactory,
        $window,
        VentasLangFactory,
        ComprobanteVentaFactory,
        $state,
        $stateParams,
        $modal,
        PlanCobrosDetalleFactory,
        PlanCobrosFactory,
        $filter
    ) {
        var defaultColumnOrder = [
            "id",
            "numero",
            "tipo.descripcion",
            "monto",
            "saldo",
            "estado.descripcion",
            "fechaVencimiento",
            "comentario"
        ];

        $scope.totalPlanCobro = 0;
        $scope.totalActual = 0;
        var staticFilter = {};

        var staticFilterPath, staticFilterId;
        if ($stateParams.idPlanCobros) {
            staticFilterPath = "planCobros.id";
            staticFilterId = $stateParams.idPlanCobros;
            PlanCobrosFactory.get(
                $stateParams.idPlanCobros,
                "PlanCobrosList"
            ).$promise.then(function(p) {
                $scope.monedaPlan = p.comprobanteVenta.moneda;
                $scope.nroComprobanteVenta = p.comprobanteVenta.numero;
                $scope.totalPlanCobro = p.comprobanteVenta.montoTotal;
                _.forEach(p.planCobrosDetalles, function(d) {
                    $scope.totalActual = $scope.totalActual + d.monto;
                });
                loadDatatable();
            });
        } else if ($stateParams.idComprobanteVenta) {
            staticFilterPath = "planCobros.comprobanteVenta.id";
            staticFilterId = $stateParams.idComprobanteVenta;
            ComprobanteVentaFactory.get(
                $stateParams.idComprobanteVenta,
                "ComprobanteVentaList"
            ).$promise.then(function(c) {
                $scope.monedaPlan = c.moneda;
                $scope.nroComprobanteVenta = c.numero;
                $scope.totalPlanCobro = c.montoTotal;
                _.forEach(c.planCobros.planCobrosDetalles, function(d) {
                    $scope.totalActual = $scope.totalActual + d.monto;
                });
                loadDatatable();
            });
        }



        staticFilter.search = filterFactory
            .and([{
                path: staticFilterPath,
                equals: staticFilterId || 0
            }])
            .value();

        function loadDatatable() {
            VentasLangFactory.getTranslations().then(function(translations) {
                // ComprobanteFactory.get($stateParams.idComprobante).$promise.then(function(c) {
                var monedaRender = function(data, type, row) {
                    if (!data) {
                        data = 0;
                    }

                    var moneda = "Gs. ";
                    if ($scope.monedaPlan.codigo === 'dolares') {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if ($scope.monedaPlan.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if ($scope.monedaPlan.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if ($scope.monedaPlan.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    }else{
                        data = parseFloat(data).toFixed(0);
                    }
                    // TODO hacer esto mismo en todos los renders de moneda
                    return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                };

                $scope.options = {
                    resource: "plancobrosdetalle",
                    staticFilter: staticFilter,
                    title: $filter("translate")("CUOTAS_COMPROBANTE"),
                    view: "PlanCobrosDetalleList",
                    factory: PlanCobrosDetalleFactory,
                    defaultColumnOrder: defaultColumnOrder,
                    failedDeleteError: $filter("translate")("CUOTA_DELETE_FAILED"),
                    columns: [
                        { data: "id", title: $filter("translate")("CODE"), visible: false  },
                        { data: "numero", title: $filter("translate")("NUMBER")  },
                        {
                            data: "tipo.descripcion",
                            title: $filter("translate")("TYPE"),
                            renderWith: "emptyRender"
                        },
                        {
                            data: "monto",
                            title: $filter("translate")("MONTO"),
                            renderWith: monedaRender,
                            type: "number-range",
                            class: "dt-right"
                        }, //actualizar
                        {
                            data: "saldo",
                            title: $filter("translate")("SALDO"),
                            renderWith: monedaRender,
                            type: "number-range",
                            class: "dt-right"
                        },
                        {
                            data: "estado.descripcion",
                            title: $filter("translate")("STATUS")
                        },
                        {
                            data: "fechaVencimiento",
                            title: $filter("translate")("FECHA_VENCIMIENTO"),
                            renderWith: "dateRender",
                            dateFormat: "DD/MM/YYYY",
                            class: 'dt-center',
                            type: "date-range"
                        },
                        {
                            data: "comentario",
                            title: "Comentario"
                        } //actualizar
                    ],
                    hasOptions: true,
                    defaultOrderColumn: 1,
                    defaultOrderDir: "asc",
                    hideAddMenu: true,
                    hideEditMenu: true,
                    hideRemoveMenu: true,
                    hideViewMenu: true,
                    extraMenuOptions: [
                        // agregar condicion de cuando agregar nuevas cuotas
                        {
                            title: $filter("translate")("NEW"),
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function() {
                                return AuthorizationService.hasPermission("create_plancobros");
                            },
                            action: function() {
                                $state.go("^.new");
                            }
                        }
                    ],
                    extraRowOptions: [{
                            templateToRender: "<button class='btn btn-success' title='" +
                                $filter("translate")("EDIT") +
                                "' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                            customAttribute: ["estado", "monto", "saldo"],
                            functionName: "editar",
                            functionDef: function(itemId) {
                                $state.go("^.edit", { id: itemId });
                            },
                            conditionName: "canEditar",
                            conditionDef: function(atributos) {
                                var estado = atributos[0];
                                var monto = atributos[1];
                                var saldo = atributos[2];
                                return (
                                    AuthorizationService.hasPermission(
                                        "update_plancobrosdetalle"
                                    ) &&
                                    estado.codigo == "plancobros_generado" &&
                                    monto == saldo
                                );
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-danger btn-dt' title='" +
                                $filter("translate")("DELETE") +
                                "' style='margin-right: 5px;' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                            customAttribute: ["estado", "monto", "saldo"],
                            functionName: "borrar",
                            functionDef: function(itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.tituloModal = $filter("translate")(
                                    "DELETE_CONFIRMATION"
                                );
                                $scope.mensajeModal = $filter("translate")("DELETE_WARNING");
                                $scope.modalInstanceBorrar1 = $modal.open({
                                    templateUrl: "views/directives/confirmation-modal.html",
                                    scope: $scope
                                });

                                $scope.cancel = function() {
                                    $scope.modalInstanceBorrar1.dismiss("cancel");
                                };

                                $scope.ok = function(itemId) {
                                    var model = PlanCobrosDetalleFactory.create({ id: itemId });
                                    PlanCobrosDetalleFactory.remove(model).then(
                                        function() {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function(error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")(
                                                "DELETE_FAILED"
                                            );
                                            $scope.mensajeModal = $filter("translate")(
                                                "DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS"
                                            );
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">' +
                                                    $filter("translate")("OK") +
                                                    "</button>" +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function() {
                                                modalInstance.dismiss("cancel");
                                            };
                                            console.log("error al borrar: ");
                                            console.log(error);
                                        }
                                    );
                                };
                            },
                            conditionName: "canBorrar",
                            conditionDef: function(atributos) {
                                var estado = atributos[0];
                                var monto = atributos[1];
                                var saldo = atributos[2];
                                return (
                                    AuthorizationService.hasPermission(
                                        "delete_plancobrosdetalle"
                                    ) &&
                                    estado.codigo == "plancobros_generado" &&
                                    monto == saldo
                                );
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-info' title='" +
                                $filter("translate")("VIEW") +
                                "' style='margin-right: 5px;' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                            customAttribute: "estado",
                            functionName: "ver",
                            functionDef: function(itemId) {
                                $state.go("^.view", { id: itemId });
                            },
                            conditionName: "canVer",
                            conditionDef: function(estado) {
                                return AuthorizationService.hasPermission(
                                    "index_plancobrosdetalle"
                                );
                            }
                        }
                    ]
                };
            });
            //});
        }
    }]);

"use strict";

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular
  .module("qualita.venta")
  .controller("PlanCobrosDetalleFormCtrl", PlanCobrosDetalleFormCtrl);

PlanCobrosDetalleFormCtrl.$inject = [
  "$rootScope",
  "$scope",
  "PlanCobrosDetalleFactory",
  "$location",
  "$state",
  "$stateParams",
  "planCobrosDetallePrepService",
  "notify",
  "formFactory",
  "VentasLangFactory",
  "TiposFactory",
  "$timeout",
  "$filter"
];

function PlanCobrosDetalleFormCtrl(
  $rootScope,
  $scope,
  PlanCobrosDetalleFactory,
  $location,
  $state,
  $stateParams,
  planCobrosDetallePrepService,
  notify,
  formFactory,
  VentasLangFactory,
  TiposFactory,
  $timeout,
  $filter
) {
  activate();

  $scope.cancel = cancel;
  $scope.submit = submit;
  $scope.changeMonto = changeMonto;

  function activate() {
    $scope.curDate = new Date();
    VentasLangFactory.getTranslations().then(function(translations) {
      $scope.translations = translations;
      if ($state.is("^.new")) {
        activateNew();
      } else if ($state.is("^.edit")) {
        activateEdit();
      } else if ($state.is("^.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateNew() {
    $scope.title = $filter("translate")("NEW_CUOTA");
    $scope.planCobroDetalle = {
      planCobros: parseInt($stateParams.idPlanCobros)
    };
    TiposFactory.estadosCuotaCobro().then(function(data) {
      var estadoGenerado = _.filter(data.data, function(elem) {
        return elem.codigo === "plancobros_generado";
      });
      $scope.planCobroDetalle.estado = estadoGenerado[0];
    });
  }

  function activateEdit() {
    $scope.title = $filter("translate")("EDIT_CUOTA");
    $scope.planCobroDetalle = planCobrosDetallePrepService;
    if ($scope.planCobroDetalle.monto != $scope.planCobroDetalle.saldo) {
      $state.go("app.main");
    }
    $scope.planCobroDetalle.fechaVencimiento = new Date(
      $scope.planCobroDetalle.fechaVencimiento
    );
    $scope.entidadId = $scope.planCobroDetalle.id;
    $scope.entidad = "Cuota";
  }

  function activateView() {
    $scope.title = $filter("translate")("VIEW_CUOTA");
    $scope.planCobroDetalle = planCobrosDetallePrepService;
    $scope.planCobroDetalle.fechaVencimiento = new Date(
      $scope.planCobroDetalle.fechaVencimiento
    );
    $scope.entidadId = $scope.planCobroDetalle.id;
    $scope.entidad = "Cuota";
    $scope.view = true;
  }

  function changeMonto() {
    $scope.planCobroDetalle.saldo = $scope.planCobroDetalle.monto;
  }

  function submit() {
    $scope.submited = true;
    if ($scope.PlanCobrosDetalleForm.$valid) {
      formFactory
        .defaultNSFSubmit(
          $scope.PlanCobrosDetalleForm,
          PlanCobrosDetalleFactory,
          $scope.planCobroDetalle,
          errorHandler
        )
        .then(
          function(response) {
            $state.go("^.list");
          },
          function(error) {
            console.log(error);
          }
        );
    } else {
      //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }
  }

  function errorHandler(e) {
    var msg = "";
    _.forEach(e.data, function(error) {
      if (error.constraint == "nombre") {
        msg += "\n\n" + $filter("translate")("SAME_NAME_PRODUCT_CLASS") + "\n";
      } else {
        msg += "\n\n" + error.message + ".";
      }
    });
    notify({ message: msg, classes: "alert-danger", position: "right" });
  }

  function cancel() {
    $state.go("^.list");
  }

  $scope.resource = "plancobros"; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';


angular.module('qualita.venta')
    .controller('EstadoCuentaClienteIndexCtrl', ["$scope", "MovimientoCtaCteFactory", "filterFactory", "$window", "VentasLangFactory", "$modal", "TiposFactory", "ClienteFactory", "notify", "UtilFactory", "$filter", "CsvFactory", "baseurl", "AuthorizationService", function($scope, MovimientoCtaCteFactory, filterFactory,
        $window, VentasLangFactory, $modal, TiposFactory, ClienteFactory, notify, UtilFactory,
        $filter, CsvFactory, baseurl, AuthorizationService) {

            $scope.boolean = false;
        VentasLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;

            //Verificacion de permiso para recalculo
            $scope.recalculoPermission = AuthorizationService.hasPermission("recalcular_movimientobancario");

            var importeRender = function(data, type, row) {
                if (data) {
                    var moneda = "Gs. ";
                    if (row.moneda.codigo === 'dolares') {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.moneda.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    } else {
                        // si es guaranies no debe tener comas (,)
                        data = parseFloat(data).toFixed(0);
                    }
                    return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return 0;
                }
            };

            // TODO mas adelante agregar filtro para que traiga solo los de proveedores y  no clientes
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'cliente.id',
                equals: 0
            }]).value();
            var defaultColumnOrder = ['orden', 'descripcion', 'fecha', 'cliente', 'tipoMovimiento.descripcion'/*, 'comprobanteVenta.numero'*/,
                'moneda.descripcion', 'entrada', 'salida', 'saldo'
            ];
            $scope.options = {
                'staticFilter': staticFilter,
                'resource': 'movimientoctacte',
                'title': $filter('translate')('ESTADO_CUENTA_CLIENTE'),
                'view': 'EstadoCuentaClienteList',
                'factory': MovimientoCtaCteFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('FAIL_DELET_ENTIDAD'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('ID'), visible: false},
                    { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION'),'renderWith': 'emptyRender',  orderable: false },
                    { 'data': 'cliente', 'title': $filter('translate')('CLIENTE'), orderable: false },
                    { 'data': 'tipoMovimiento.descripcion', 'title': $filter('translate')('MOVEMENT_TYPE'), orderable: false },
                    { 'data': 'moneda.descripcion', 'title': $filter('translate')('CURRENCY'), 'renderWith': 'emptyRender', orderable: false },
                    { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', class: 'dt-center', 'type': 'date-range' },
                    //{ 'data': 'comprobanteVenta.numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-right', orderable: false },
                    { 'data': 'entrada', 'title': $filter('translate')('DEBITO'), 'renderWith': importeRender, 'class': 'dt-right', orderable: false  },
                    { 'data': 'salida', 'title': $filter('translate')('CREDITO'), 'renderWith': importeRender, 'class': 'dt-right', orderable: false  },
                    { 'data': 'saldo', 'title': $filter('translate')('SALDO'), 'renderWith': importeRender, 'class': 'dt-right', orderable: false  }
                ],
                'hasOptions': false,
                'hideAddMenu': true,
                'defaultOrderColumn': 5,
                'defaultOrderDir': "asc",
                'extraMenuOptions': [/*{
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function() { return true; },
                        'action': function() {
                            if ($scope.FiltrosForm.$valid) {
                                var filtersArr = [];
                                if ($scope.proveedor) {
                                    filtersArr.push("idCliente=" + $scope.cliente.id);
                                }
                                if ($scope.fechaDesde) {
                                    filtersArr.push("fechaDesde=" + $filter('date')($scope.fechaDesde, "MM/dd/yyyy"));
                                }
                                if ($scope.fechaHasta) {
                                    filtersArr.push("fechaHasta=" + $filter('date')($scope.fechaHasta, "MM/dd/yyyy"));
                                }
                                if ($scope.moneda) {
                                    filtersArr.push("moneda=" + $scope.moneda.id);
                                }
                                var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("estadocuentacliente", 'pdf', filtersArr);
                                $window.open(pdfDownloadURL, '_blank');
                            } else {
                                var msg = $filter('translate')('ERROR_FILTERING_ESTADO_CUENTA_PROVEEDOR');
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },*/
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);
                            console.log("Filters: ", filters);

                            CsvFactory.csv("movimientoctacte", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                .then(function(response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    }
                ]
            };

            $scope.boolean = true;
            var createFilters = function(filters) {
                var filtersArr = [];
                _.each(filters, function(search, data) {
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }

            $scope.saldoInicial = 0;
            $scope.cliente = undefined;
            $scope.moneda = undefined;
            $scope.fechaDesde = undefined;
            $scope.fechaHasta = undefined;

            //$scope.clientes = ClienteFactory.all({view: "BaseList"});
            /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
            $scope.staticFilterProv = [{path: "activo",equals: true}];
            $scope.clienteFactory = ClienteFactory;

            TiposFactory.monedas().then(function(response) {
                $scope.monedas = response.data;
            });

            $scope.limpiarFiltros = function() {
                $scope.saldoInicial = 0;
                $scope.cliente = undefined;
                $scope.moneda = undefined;
                $scope.fechaDesde = undefined;
                $scope.fechaHasta = undefined;
                $scope.cliente = undefined;
            }

            $scope.cambiarFiltro = function() {
                if ($scope.fechaDesde != undefined && $scope.fechaHasta != undefined && $scope.fechaDesde > $scope.fechaHasta) {
                    notify({ message: "La fecha desde de búsqueda no puede ser mayor a la fecha hasta.", classes: 'alert-danger', position: 'right' });
                } else {
                    if (($scope.fechaHasta == null) || ($scope.fechaDesde == null) || ($scope.cliente == null) || ($scope.moneda == null)){
                        var msg = $filter('translate')('ERROR_FILTERING_ESTADO_CUENTA_CLIENTE');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        return;
                    }

                    $scope.filtro = true;
                    var staticFilter = {};
                    var andFilters = [{
                        path: 'fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment($scope.fechaDesde).format('DD/MM/YYYY') : undefined
                    }, {
                        path: 'fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment($scope.fechaHasta).format('DD/MM/YYYY') : undefined
                    },{
                        path: 'orden',
                        sortAsc: 'true'
                      }];

                    if ($scope.cliente) {
                        andFilters.push({
                            path: 'cliente.id',
                            equals: $scope.cliente.id
                        });
                    }

                    if ($scope.moneda) {
                        andFilters.push({
                            path: 'moneda.id',
                            equals: $scope.moneda.id
                        });
                    }

                    staticFilter.search = filterFactory.and(andFilters).value();
                    $scope.options.staticFilter = staticFilter;

                }

                if($scope.fechaDesde!=undefined){
                    MovimientoCtaCteFactory.getSaldoInicial($scope.cliente.id,moment.utc($scope.fechaDesde).format('DD/MM/YYYY'), "cliente",$scope.moneda.id).then(function(resp) {
                    console.clear();
                    console.log($scope.moneda);
                    if($scope.moneda.codigo === 'guaranies'){
                        $scope.saldoInicial=parseFloat(resp.data).toFixed(0);
                    }else{
                        $scope.saldoInicial=parseFloat(resp.data).toFixed(2);
                    }
                    if($scope.saldoInicial===undefined) {
                      $scope.saldoInicial=0;}
                    });

                  }else{
                    $scope.saldoInicial=0;
                  }
            };

            $scope.recalcularSaldos = function () {

                $scope.mensajeModal = $filter('translate')('WARNING_RECALCULO_SALDO');
                $scope.tituloModal = $filter('translate')('WARNING_HEADER_RECALCULO');
                var modalInstance = $modal.open({
                    templateUrl: "views/directives/confirmation-modal.html",
                    scope: $scope
                });

                $scope.cancel = function() {
                    modalInstance.dismiss("cancel");
                };

                $scope.ok = function() {
                    if ($scope.cliente) {

                        $scope.mensajeModal = $filter('translate')('PROCESS');
                        var modalInstance1 = $modal.open({
                            templateUrl: "views/directives/information-modal.html",
                            scope: $scope
                        });

                        MovimientoCtaCteFactory.recalcularSaldo($scope.cliente.id,"cliente").then(function(){
                            // success
                            modalInstance1.close();

                            $scope.mensajeModal = "Proceso finalizado con éxito";
                            $scope.ok = function() {
                                modalInstance.close();
                            }

                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/information-modal.html",
                                scope: $scope
                            });

                        }, function () {

                            //error
                            modalInstance1.close();

                            $scope.mensajeModal = "ERROR al recalcular saldo";
                            $scope.ok = function() {
                                modalInstance.close();
                            }

                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/information-modal.html",
                                scope: $scope
                            });
                        })
                        return
                    }

                    var msg = $filter('translate')('ERROR_FILTER_PROVEEDOR');
                    notify({message: msg, classes: 'alert-danger', position: 'right' });
                }
            }
        })
    }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.empleados
 * @description
 * # empleados
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('TipoClienteFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var tipoCliente = $resource(baseurl.getBaseUrl() + '/tiposclientes/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return tipoCliente.query(params);
      },
      get: function (id, view) {
        return tipoCliente.get({ id: id, view: view != undefined ? view : 'base' });
      },
      create: function (attrs) {
        return new tipoCliente(attrs);
      },
      save: function (TipoCliente) {
        return (TipoCliente.id) ? TipoCliente.$update() : TipoCliente.$save();
      },
      remove: function (TipoCliente) {
        return TipoCliente.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      },
      reenviar: function (idTipoCliente) {
        return $http({
          url: baseurl.getBaseUrl() + '/tiposclientes/reenviar/' + idTipoCliente,
          method: "PUT"
        });
      }
    };
  }]);
"use strict";

angular.module("qualita.venta").controller("TipoClienteFormCtrl", [
  "TipoClienteFactory",
  "filterFactory",
  "$modal",
  "$scope",
  "notify",
  "$state",
  "$rootScope",
  "tipoClientePrepService",
  "$controller",
  "formFactory",
  "VentasLangFactory",
  "$location",
  "$filter",
  function(
    TipoClienteFactory,
    filterFactory,
    $modal,
    $scope,
    notify,
    $state,
    $rootScope,
    tipoClientePrepService,
    $controller,
    formFactory,
    VentasLangFactory,
    $location,
    $filter
  ) {
    console.log("Entro al controler");
    var self = this;
    VentasLangFactory.getTranslations().then(function(translations) {
      $scope.resource = "tiposclientes";
      $scope.prepService = tipoClientePrepService;
      $scope.submit = submit;
      $scope.cancel = cancel;
      $scope.submitTipoCliente = submitTipoCliente;
      $scope.translations = translations;

      $scope.newProperties = {
        state: "app.tiposclientes.new",
        title: translations.NEW_TIPO_LISTA_PRECIOS
      };
      $scope.editProperties = {
        state: "app.tiposclientes.edit",
        title: translations.EDIT_TIPO_LISTA_PRECIOS,
        entidad: "TipoCliente"
      };

      $scope.viewProperties = {
        state: "app.tiposclientes.view",
        title: translations.VIEW_TIPO_LISTA_PRECIOS,
        entidad: "TipoCliente"
      };



      $scope.activate = function() {
        console.log("Entro al controler");
        if ($state.is($scope.newProperties.state)) {
          activateNew();
        } else if ($state.is($scope.editProperties.state)) {
          activateEdit();
        } else if ($state.is($scope.viewProperties.state)) {
          activateView();
        }

        $rootScope.isProcessing = false;
      };

      function activateNew() {
        $scope.title = $scope.newProperties.title;
        $scope.tipoCliente = {};
      }

      function activateEdit() {
        $scope.tipoCliente = $scope.prepService;
        $scope.entidadId = $scope.tipoCliente.id;
        $scope.entidad = $scope.editProperties.entidad;
        $scope.title = $scope.editProperties.title;
      }

      function activateView() {
        $scope.tipoCliente = $scope.prepService;
        $scope.entidadId = $scope.tipoCliente.id;
        $scope.entidad = $scope.viewProperties.entidad;
        $scope.title = $scope.viewProperties.title;
        $scope.view = true;
      }

      function cancel() {
        $location.path("/tiposclientes");
      }

      $scope.activate();

      function submit() {
        $scope.submited = true;
        if ($scope.TipoClienteForm.$valid) {
          $rootScope.isProcessing = true;
          submitTipoCliente().then(function(tipoCliente) {
            $location.path("/tiposclientes");
          }, errorHandler);
        }
      }

      function submitTipoCliente() {
        var tipoCliente = TipoClienteFactory.create($scope.tipoCliente);
        return TipoClienteFactory.save(tipoCliente, function(resp, headers) {
          //success callback
          console.log("RESPONSE: ", resp);
        });
      }

      function errorHandler(err) {
        console.log("Error al guardar Tipo de Lista de Precios: ", err);
        $rootScope.isProcessing = false;
        $scope.submited = false;
        var msg = "";
        _.forEach(err.data, function(error) {
          msg += "\n\n" + error.message + ".";
        });
        notify({
          message: msg,
          classes: "alert-danger",
          position: "right"
        });
      }
    });
  }
]);

'use strict';

angular.module('qualita.venta')
  .controller('TipoClientesIndexCtrl', ["TipoClienteFactory", "TiposFactory", "$log", "$modal", "$scope", "VentasLangFactory", "$state", "UtilFactory", "$window", "CsvFactory", "baseurl", "ParametrosFactory", "$filter", "notify", function TipoClientesIndexCtrl(TipoClienteFactory,
    TiposFactory, $log, $modal, $scope, VentasLangFactory, $state, UtilFactory, $window, CsvFactory, baseurl, ParametrosFactory, $filter, notify) {

    var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
    var enviarAContabilidad = '';

    promise1.then(function(response) {
      enviarAContabilidad = response.valorTipo.codigo;
    });

    VentasLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'descripcion'];

      $scope.options = {
        'resource': 'tiposclientes',
        'title': translations.TIPOS_LISTA_PRECIOS,
        'factory': TipoClienteFactory,
        'view': 'BaseList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': translations.FAILED_DELETE_TIPO_CLIENTE,
        'columns': [
          { 'data': 'id', 'title': translations.CODE, 'visible': false },
          { 'data': 'descripcion', 'title': translations.DESCRIPCION }
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'hideRemoveMenu': false,
        'hideViewMenu': false,
        'extraMenuOptions':
          [
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("tiposclientes", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ],
          'extraRowOptions': [
            {
              templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
              functionName: 'reenviar',
              customAttribute: ['enviadoAContabilidad', 'id'],
              functionDef: function (atributo) {

                var idTipoCliente = atributo[1];

                TipoClienteFactory.get(idTipoCliente, "BaseList").$promise.then(function (response) {

                  console.log('Tipo Cliente');
                  console.log(response);

                  $scope.tituloModal = $filter('translate')('REENVIAR_TIPO_CLIENTE');
                  $scope.mensajeModal = $filter('translate')('REENVIAR_TIPO_CLIENTE_MSG');
                  var modalInstance = $modal.open({
                    templateUrl: 'views/directives/confirmation-modal.html',
                    scope: $scope
                  });

                  $scope.cancel = function () {
                    modalInstance.dismiss('cancel');
                  };

                  $scope.ok = function (id) {

                    ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {

                      if (parametro.valorTipo.codigo == 'si') {
                        TipoClienteFactory.reenviar(response.id).then(function (responseReenviar) {

                          console.log('Tipo Cliente responseReenviar');
                          console.log(responseReenviar);

                          if (responseReenviar.data.enviadoAContabilidad == false) {
                            notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                          }
                          $state.go("app.tiposclientes.list", { id: responseReenviar.id }, { reload: true });
                        }, function (error) {
                          var msg = "";
                          if (error.data && error.data.length > 0 && error.data[0].message) {
                            msg = error.data[0].message;
                          } else {
                            msg = $filter('translate')('REENVIAR_TIPO_CLIENTE_FAILED');
                          }

                          notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        });
                      } else {
                        var msg = "";
                        msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                      }
                    });
                    modalInstance.close();
                  }
                });
              },
              conditionName: 'canReenviar',
              conditionDef: function (atributo) {
                var enviadoAContabilidad = atributo[0];
                if (enviarAContabilidad == 'si') {
                  if (enviadoAContabilidad === "No") {
                    return true;
                  }
                }
              }
            }
          ]
      };
    });
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.timbradoEmpresas
 * @description
 * # timbradoEmpresas
 * Factory in the qualita.
 */
angular.module('qualita.authorization')
  .factory('TimbradoEmpresaPuntoEmisionVentaFactory', ['$resource', 'baseurl', '$http', function($resource,  baseurl, $http) {

    var TimbradoEmpresaPuntoEmision = $resource( baseurl.getBaseUrl() + '/timbradoempresapuntoemision/:id', {id: '@id'},
        {
        update: {
            method: 'PUT'
        }
        });

    return {
      all: function(params, view) {
        if (params) {
          params.view = view!=undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'BaseList';
        }
        return TimbradoEmpresaPuntoEmision.query(params);
      },
      get: function(id,view) {
        console.log(view);
        //if (typeof(view)==='undefined') view = "base";
        return TimbradoEmpresaPuntoEmision.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function(attrs) {
        return new TimbradoEmpresaPuntoEmision(attrs);
      },
      save: function(timbradoEmpresa) {
        return (timbradoEmpresa.id) ? timbradoEmpresa.$update() : timbradoEmpresa.$save();
      },
      remove: function(timbradoEmpresa) {
        return timbradoEmpresa.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/'+codigo);
      }
    };
  }]);

'use strict';


angular.module('qualita.venta')
  .controller('ConsultaLibroVentaIndexCtrl',
    ["$scope", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "VentasLangFactory", "ClienteFactory", "$filter", "notify", "$rootScope", "VentaFactory", "baseurl", "TiposTipoFactory", "TiposFactory", "SucursalesFactory", "UnidadesNegocioFactory", "TipoComprobanteFactory", "UtilFactory", function ($scope,filterFactory, AuthorizationService, ReportTicketFactory, $modal, $window, $state, VentasLangFactory,
      ClienteFactory, $filter, notify, $rootScope, VentaFactory, baseurl, TiposTipoFactory, TiposFactory, SucursalesFactory, UnidadesNegocioFactory,
      TipoComprobanteFactory, UtilFactory) {
    VentasLangFactory.getTranslations().then(function(translations) {
      $scope.translations = translations;

      TiposFactory.monedas().then(function(response) {
        $scope.monedas = response.data;
      });

      var staticFilter2 = {};
      staticFilter2.search = filterFactory.and([
          {
              path: 'activo',
              equals: true
          },
          {
              path: 'utilizarEnProcesosVenta',
              equals: true
          },
          {
            path: 'informeImpositivo',
            equals: true
          }
      ]).value();

      $scope.tiposComprobante = TipoComprobanteFactory.all(staticFilter2, "LibroIvaVentaList");

      $scope.limpiarFiltros = function() {
        $scope.filtros.cliente = undefined;
        $scope.moneda = undefined;
        $scope.filtros.fechaDesde = undefined;
        $scope.filtros.fechaHasta = undefined;
        $scope.filtros.tipoComprobante = undefined;
        $scope.filtros.fechaCargaDesde = undefined;
        $scope.filtros.fechaCargaHasta = undefined;
        $scope.filtros.sucursal_cliente = undefined;
        $scope.sucursalesClientes = undefined;
      }

    });

    var vm = this;
    vm.delayRequestCliente = delayRequestCliente;
    vm.searchClienteFilter2 = searchClienteFilter2;
    var staticFilter = {};
    staticFilter.view = "LibroIvaVentaList";
    $scope.mostrarCabecera = true;
    $scope.mostrarNroRubrica = false;
    this.tiposReporte = [
      { id:-1, codigo: "formato_simple", descripcion: "Formato Simple" },
      { id:0, codigo: "formato_impositivo", descripcion: "Formato Impositivo" }
    ];
    $scope.tipoReporte = {
      codigo: "formato_simple",
      descripcion: "Formato Simple"
    };
    //$scope.clientes = ClienteFactory.all(staticFilter);

    $scope.filtros={};

    /*$scope.changeProveedor = function(isNew) {
      //console.log('changeProveedor');
      var staticFilter = {};
      staticFilter.search = filterFactory.and([{
        path: 'proveedor.codigo',
        equals: $scope.filtros.proveedor.codigo
      }]).value();
      $scope.sucursalesProveedor = ProveedorSucursalesFactory.all(staticFilter);
      if (isNew && isNew != false)
        vm.comprobante.sucursalProveedor = null;
    }*/

    $scope.generarReporte = function() {
    //$scope.LibroVentaForm.$valid=true;
      if($scope.LibroVentaForm.$valid) {
        //console.log($scope.filtros);
        var filtersArr = [];

        if ( this.mostrarNroRubrica && (angular.isUndefined($scope.nroInicialRubrica) || $scope.nroInicialRubrica == null)) {
          notify({ message: $filter('translate')('ERROR_NRO_RUBRICA'), classes: 'alert-danger', position: 'right' });
          return;
        }

        if ($scope.filtros.cliente) {
          filtersArr.push("cliente=" + $scope.filtros.cliente.id);
        }
        if ($scope.filtros.fechaDesde) {
          var myDate = $filter('date')($scope.filtros.fechaDesde, "MM/dd/yyyy");
          filtersArr.push("fechaDesde=" + myDate);
        }
        if ($scope.filtros.fechaHasta) {
          var myDate = $filter('date')($scope.filtros.fechaHasta, "MM/dd/yyyy");
          filtersArr.push("fechaHasta=" + myDate);
        }

        if($scope.moneda){
          filtersArr.push("moneda="+$scope.moneda.id);
        }

        if ($scope.filtros.tipoComprobante) {
          filtersArr.push("tipocomprobante=" + $scope.filtros.tipoComprobante.id);
        }

        if ($scope.filtros.fechaDesde > $scope.filtros.fechaHasta) {
          notify({ message: $filter('translate')('FECHA_DESDE_MAYOR_FECHA_HASTA'), classes: 'alert-danger', position: 'right' });
          return;
        } else if ($scope.filtros.fechaCargaDesde > $scope.filtros.fechaCargaHasta) {
          notify({ message: $filter('translate')('FECHA_CARGA_DESDE_MAYOR_FECHA_CARGA_HASTA'), classes: 'alert-danger', position: 'right' });
          return;
        }

        if ($scope.filtros.fechaCargaDesde) {
          var myDate = $filter('date')($scope.filtros.fechaCargaDesde, "MM/dd/yyyy");
          filtersArr.push("fechaCargaDesde=" + myDate);
        }
        if ($scope.filtros.fechaCargaHasta) {
          var myDate = $filter('date')($scope.filtros.fechaCargaHasta, "MM/dd/yyyy");
          filtersArr.push("fechaCargaHasta=" + myDate);
        }

        if($scope.filtros.sucursal_cliente){
          filtersArr.push("clienteSucursal="+$scope.filtros.sucursal_cliente.id);
        }

        if ($rootScope.AuthParams.locacionSeleccionada) {
          filtersArr.push("unidadNegocioUsuario=" + $rootScope.AuthParams.locacionSeleccionada.unidadNegocio.id);
          filtersArr.push("locacion=" + $rootScope.AuthParams.locacionSeleccionada.id);
          filtersArr.push("sucursalUsuario=" + $rootScope.AuthParams.locacionSeleccionada.sucursal.id);
        }

        filtersArr.push("todas_sucu_unidad="+$scope.filtros.todas_sucu_unidades);

        if($scope.mostrarNroRubrica){
          filtersArr.push("nroInicialRubrica="+$scope.nroInicialRubrica);
        }

        filtersArr.push("tipoReporte="+$scope.tipoReporte.codigo);

        var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("libroventa", 'pdf', filtersArr);
        $window.open(pdfDownloadURL, '_blank');
      }else if ( this.mostrarNroRubrica && (angular.isUndefined($scope.nroInicialRubrica) || $scope.nroInicialRubrica == null)) {
        notify({ message: $filter('translate')('ERROR_NRO_RUBRICA'), classes: 'alert-danger', position: 'right' });
      } else {
        var msg = $filter('translate')('ERROR_FILTERING_LIBRO_VENTA');
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
      }

    };

    $scope.generarCSV = function() {

            if($scope.LibroVentaForm.$valid) {
              //console.log($scope.filtros);
              var filtersObj = {};
              if ($scope.filtros.cliente) {
                filtersObj.cliente = $scope.filtros.cliente.id;
                // filtersArr.push("proveedor=" + $scope.filtros.proveedor.id);
              }
              if ($scope.filtros.fechaDesde) {
                var myDate = $filter('date')($scope.filtros.fechaDesde, "MM/dd/yyyy");
                filtersObj.fechaDesde = myDate;
                // filtersArr.push("fechaDesde=" + myDate);
              }
              if ($scope.filtros.fechaHasta) {
                var myDate = $filter('date')($scope.filtros.fechaHasta, "MM/dd/yyyy");
                filtersObj.fechaHasta = myDate;
                // filtersArr.push("fechaHasta=" + myDate);
              }

              if ($scope.filtros.fechaCargaDesde) {
                var myDate = $filter('date')($scope.filtros.fechaCargaDesde, "MM/dd/yyyy");
                filtersObj.fechaCargaDesde = myDate;
              }
              if ($scope.filtros.fechaCargaHasta) {
                var myDate = $filter('date')($scope.filtros.fechaCargaHasta, "MM/dd/yyyy");
                filtersObj.fechaCargaHasta = myDate;
              }

              if($scope.filtros.sucursal_cliente){
                filtersObj.clienteSucursal = $scope.filtros.sucursal_cliente.id;
              }

              if($scope.moneda){
                filtersObj.moneda = $scope.moneda.id;
              }

              if ($scope.filtros.tipoComprobante) {
                filtersObj.tipocomprobante = $scope.filtros.tipoComprobante.id;
              }


              if ($rootScope.AuthParams.locacionSeleccionada) {
                filtersObj.unidadNegocioUsuario= $rootScope.AuthParams.locacionSeleccionada.id;
                filtersObj.sucursalUsuario= $rootScope.AuthParams.locacionSeleccionada.sucursal.id;
              }

              filtersObj.todas_sucu_unidad=$scope.filtros.todas_sucu_unidades;

              VentaFactory.getLibroVentaCSV(filtersObj).then(function(response) {
                var fileName = response.data.fileName;
                $window.open(baseurl.getPublicBaseUrl() + fileName);
              });
            } else {
              var msg = $filter('translate')('ERROR_FILTERING_LIBRO_VENTA');
              notify({ message: msg, classes: 'alert-danger', position: 'right'});
            }

    };

    $scope.validarNroRubrica = function() {
      if (
        $scope.mostrarCabecera &&
        $scope.tipoReporte !== null &&
        $scope.tipoReporte.codigo === "formato_impositivo"
      ) {
        $scope.mostrarNroRubrica = true;
      } else {
        $scope.nroInicialRubrica = null;
        $scope.mostrarNroRubrica = false;
      }
    };

    $scope.changeCliente= function(isNew) {
          $scope.filtros.sucursal_cliente = null;
          if ($scope.filtros.cliente.sucursales.length > 0) {
            $scope.sucursalesClientes = $scope.filtros.cliente.sucursales;
          }
      }

        function delayRequestCliente(searchValue) {
            var userInputFilter = {}
            if (searchValue.length >= 3) {
                var filterNombre =[];
                // var filterNombreFantasia =[];
                var filterCodigo =[];
                var filterNumero =[];
                var filterDireccion =[];
                var filterCiudad =[];

                filterNombre.push({
                    path: "nombre",
                    like: searchValue
                })
                filterNombre.push({
                  path: "activo",
                  equals: true
                })
                /* filterNombreFantasia.push({
                path: "nombreFantasia",
                like: searchValue
                })
                filterNombreFantasia.push({
                  path: "activo",
                  equals: true
                }) */
                filterCodigo.push({
                path: "codigo",
                like: searchValue
                })
                filterCodigo.push({
                  path: "activo",
                  equals: true
                })
                filterNumero.push({
                path: "numero",
                like: searchValue
                })
                filterNumero.push({
                  path: "activo",
                  equals: true
                })
                filterDireccion.push({
                path: "direccion",
                like: searchValue
                })
                filterDireccion.push({
                  path: "activo",
                  equals: true
                })
                filterCiudad.push({
                path: "ciudad.descripcion",
                like: searchValue
                })
                filterCiudad.push({
                  path: "activo",
                  equals: true
                })

                userInputFilter = {
                    search: filterFactory.or([
                        filterFactory.and(filterNombre).value(),
                        //filterFactory.and(filterNombreFantasia).value(),
                        filterFactory.and(filterCodigo).value(),
                        filterFactory.and(filterNumero).value(),
                        filterFactory.and(filterDireccion).value(),
                        filterFactory.and(filterCiudad).value()
                    ]).value()
                }

                recuperarCliente(userInputFilter)
            } else {
                vm.clientes = undefined
            }
        }

        function recuperarCliente(userInputFilter) {
            var finalFilter = UtilFactory.createNestedFilters(
                vm.finalFilters, userInputFilter)

              ClienteFactory.allForSelect(finalFilter).$promise.then(function (clientes) {
                var clie = [];
                for (var i = 0; i < clientes.length; i++) {
                  if (clientes[i].bloquearVentas !== true) {
                    clie.push(clientes[i])
                  }
                }
                vm.clientes = clie;
              })
        }
        function searchClienteFilter2(criteria) {
            return function (item) {
                if (!criteria) {
                    return true;
                }
                return (
                    item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                    //(item.nombreFantasia && item.nombreFantasia.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                    (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                    item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                    (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                    (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
                );
            }
        }

  }]);

'use strict';


angular.module('qualita.venta')
    .controller('ConsultaRemisionListCtrl',
        ["$scope", "RemisionFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "VentasLangFactory", "VentaFactory", "WatchLocacionChangeFactory", "$filter", "notify", "UtilFactory", "CsvFactory", "baseurl", function($scope, RemisionFactory, filterFactory, AuthorizationService,
            ReportTicketFactory, $modal, $window, $state, VentasLangFactory, VentaFactory,
            WatchLocacionChangeFactory, $filter, notify, UtilFactory, CsvFactory, baseurl) {

        VentasLangFactory.getTranslations().then(function(translations) {

            var defaultColumnOrder = ['id', 'pedidoVenta.id', 'timbradoEmpresa.numeroTimbrado',
                'pedidoVenta.cliente.nombre', 'deposito.descripcion', 'fecha', 'tipoComprobante.descripcion',
                'sucursal.nombre', 'timbradoPuntoEmision.puntoEmisionVenta.numero', 'estado.descripcion', 'datoEnvio', 
                'razonSocial', 'movimientoStock.numero',
                'numero', 'direccionOrigen', 'direccionDestino', 'fechaInicio', 'fechaFin', 'vehiculo',
                'nroMatricula', 'transportista', 'nroDocumentoTransportista', 'conductor', 'nroDocumentoConductor',
                'direccionConductor', 'motivoTranslado', 'venta.codigo'
            ];
            $scope.options = {
                'resource': 'remisiones',
                'title': translations.REMISIONES,
                'factory': RemisionFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'view': 'RemisionList',
                'columns': [
                    //{'data': 'id', 'title': translations.CODE},
                    { 'data': 'id', 'title': 'Número', 'class': 'dt-center' },
                    { 'data': 'venta.codigo', 'title': translations.ID_VENTA, 'renderWith': 'emptyRender' },
                    { 'data': 'movimientoStock.numero', 'title': translations.NUMERO_TRANSACCION_STOCK, 'renderWith': 'emptyRender' },
                    { 'data': 'numero', 'title': translations.NUMERO_REMISION, 'renderWith': 'emptyRender' },
                    { 'data': 'timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado', 'title': translations.NUMERO_TIMBRADO, 'renderWith': 'emptyRender' },
                    { 'data': 'deposito.descripcion', 'title': translations.ALMACEN, 'renderWith': 'emptyRender' },
                    { 'data': 'fecha', 'title': translations.DATE, 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'tipoComprobante.descripcion', 'title': translations.TIPO_COMPROBANTE, 'renderWith': 'emptyRender' },
                    { 'data': 'sucursal.nombre', 'title': translations.NOMBRE_SUCURSAL, 'renderWith': 'emptyRender', visible: false  },
                    { 'data': 'timbradoPuntoEmision.puntoEmisionVenta.numero', 'title': translations.PUNTO_EMISION, 'renderWith': 'emptyRender', visible: false },
                    { 'data': 'estado.descripcion', 'title': translations.ESTADO, 'renderWith': 'emptyRender', type: "combo", filterUrl: "tipos/filtro/estados_remision"},
                    { 'data': 'datoEnvio', 'title': translations.DATO_ENVIO, 'renderWith': 'emptyRender', visible: false },
                    { 'data': 'razonSocial', 'title': translations.RAZON_SOCIAL, 'renderWith': 'emptyRender', visible: false },
                    { 'data': 'numeroDocumento', 'title': translations.NUMERO_DOCUMENTO, 'renderWith': 'emptyRender', visible: false },
                    { 'data': 'direccionOrigen', 'title': translations.DIRECCION_ORIGEN, 'renderWith': 'emptyRender', visible: false },
                    { 'data': 'direccionDestino', 'title': translations.DIRECCION_DESTINO, 'renderWith': 'emptyRender', visible: false },
                    { 'data': 'fechaInicio', 'title': translations.FECHA_INICIO, 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range', visible: false },
                    { 'data': 'fechaFin', 'title': translations.FECHA_FIN, 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range', visible: false },
                    { 'data': 'vehiculo', 'title': translations.VEHICULO, 'renderWith': 'emptyRender', visible: false },
                    { 'data': 'nroMatricula', 'title': translations.NRO_MATRICULA, 'renderWith': 'emptyRender', visible: false },
                    { 'data': 'transportista', 'title': translations.TRANSPORTISTA, 'renderWith': 'emptyRender', visible: false },
                    { 'data': 'nroDocumentoTransportista', 'title': translations.NRO_TRANSPORTISTA, 'renderWith': 'emptyRender', visible: false  },
                    { 'data': 'conductor', 'title': translations.CONDUCTOR, 'renderWith': 'emptyRender', visible: false },
                    { 'data': 'nroDocumentoConductor', 'title': translations.NRO_CONDUCTOR, 'renderWith': 'emptyRender', visible: false  },
                    { 'data': 'direccionConductor', 'title': translations.DIRECCION_CONDUCTOR, 'renderWith': 'emptyRender', visible: false },
                    { 'data': 'motivoTranslado', 'title': translations.MOTIVO_TRANSLADO, 'renderWith': 'emptyRender', visible: false },
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-click='ver(<%=dataCustom%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        customAttribute: ["id","venta"],
                        functionDef: function(attributes) {
                            var remisionId = attributes[0];
                            var procesoVenta = attributes[1];
                            $state.go("app.remision.view", { id: remisionId });
                        },
                        conditionName: "canVer",
                        conditionDef: function() {
                            return RemisionFactory.hasPermisos("view");
                        }
                    }
                ],
                'extraMenuOptions':[
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("remisiones", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                            .then(function(response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                        }
                    }
                ]
            };
        });

        var createFilters = function(filters) {
            var filtersArr = [];
            _.each(filters, function(search, data) {
                filtersArr.push({ path: data, like: search })
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        }

        var getIDs = function() {
            var ids = [];
            _.forEach(vm.options.selection, function(n, key) {
                if (n)
                    ids.push(key);
            });
            return ids;
        };
    }]);
/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.venta')
  .factory('AplicacionComprobanteVentaFactory', AplicacionComprobanteVentaFactory);

AplicacionComprobanteVentaFactory.$inject = ['$resource', 'baseurl', '$http', 'ParametrosFactory', '$q'];

function AplicacionComprobanteVentaFactory($resource, baseurl, $http, ParametrosFactory, $q) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var AplicacionComprobanteVenta = $resource( baseurl.getBaseUrl() + "/comprobanteventaaplicacion/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params) {
    return AplicacionComprobanteVenta.query(params);
  }

  function get(id, view) {
    return AplicacionComprobanteVenta.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new AplicacionComprobanteVenta(attrs);
  }

  function save(aplicacionComprobanteVenta) {
    return (aplicacionComprobanteVenta.id) ? aplicacionComprobanteVenta.$update() : aplicacionComprobanteVenta.$save();
  }

  function remove(aplicacionComprobanteVenta) {
    return aplicacionComprobanteVenta.$remove();
  }

}

'use strict';

angular.module('qualita.venta')
    .controller('CambioPrecioListCtrl', CambioPrecioListCtrl);

CambioPrecioListCtrl.$inject = ["AuthorizationService", "CambioPrecioFactory", "VentasLangFactory", "$scope", "$filter", "filterFactory", "$state",
"UtilFactory", "CsvFactory", "baseurl", "$window"];

function CambioPrecioListCtrl(AuthorizationService, CambioPrecioFactory, VentasLangFactory, $scope, $filter, filterFactory, $state,
    UtilFactory, CsvFactory, baseurl, $window) {


    var vm = this;
    activate();

    var monedaRender = function(data, type, row) {
        if (data != undefined) {
            var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
            var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);

            // console.log("AQUI sub1: ",parteEntera);
            // console.log("AQUI sub2: ",parteDecimal);

            // Si tiene parte entera
            if(parteEntera != ''){
                return (
                    parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                    ","+parteDecimal.toString()
                );
            }else{
                return (
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            }

        } else {
            return "";
        }
    };



    function activate() {

        VentasLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;

            var staticFilter = {
                search: filterFactory.and([{
                    path: "cambioPrecio.servicio.id",
                    isNull: "true"
                }]).value()
            };

            var defaultColumnOrder = ['id', 'cambioPrecio.id', 'cambioPrecio.producto.codigo', 'cambioPrecio.producto.nombre',
             'cambioPrecio.producto.codigoBarrasPrincipal.codigoBarras', 'unidadMedida.descripcion', 'precioActual', 'fechaUltimoCambio', 'nombre', 'cambioPrecio.producto.marca.descripcion', 'cambioPrecio.producto.lineaProducto.nombre'];
            vm.options = {
                'resource': 'cambioprecio',
                'staticFilter': staticFilter,
                'title': $filter('translate')('CAMBIO_PRECIO'),
                'factory': CambioPrecioFactory,
                'view': 'CambioPrecioList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('CODE'), visible:false },
                    { 'data': 'cambioPrecio.id', 'title': 'Cod.Cabecera', visible:false },
                    { 'data': 'cambioPrecio.producto.codigo', 'title': $filter('translate')('CODIGO_INTERNO') },
                    { 'data': 'cambioPrecio.producto.nombre', 'title': $filter('translate')('NOMBRE') },
                    { 'data': 'cambioPrecio.producto.codigoBarrasPrincipal.codigoBarras', 'title': $filter('translate')('CODIGO_BARRAS') },
                    { 'data': 'unidadMedida.descripcion', 'title': $filter('translate')('UNIDAD_MEDIDA') },
                    { 'data': 'precioActual', 'title': $filter('translate')('PRECIO_ACTUAL'), 'renderWith': monedaRender, class: 'dt-right'},
                    { 'data': 'fechaUltimoCambio', 'title': $filter('translate')('HORA_MODIFICACION'), 'renderWith': "dateRender", 'dateFormat': "DD/MM/YYYY", class:'dt-center'},
                    { 'data': 'nombre', 'title': $filter('translate')('USUARIO')},
                    { 'data': 'cambioPrecio.producto.marca.descripcion', 'title': $filter('translate')('MARCA'),  'renderWith': "emptyRender" },
                    { 'data': 'cambioPrecio.producto.lineaProducto.nombre', 'title': $filter('translate')('LINE'),  'renderWith': "emptyRender" },
                ],
                'hasOptions': true,
                'hideRemoveMenu': true,
                'defaultOrderColumn': 1,
                'defaultOrderDir': "desc",
                'extraMenuOptions': [
                    {
                        'data': 'Actualización de Precios',
                        'title': 'Importar Precios',
                        'icon': 'glyphicon glyphicon-retweet',
                        'showCondition': function () { return true; },
                        'action': function () {
                            $state.go("app.importarprecio");
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                            CsvFactory.csv("cambioprecio", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                            .then(function(response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                        }
                    }
                ]
            };
        });
    }
}

"use strict";

angular
        .module("qualita.venta")
        .controller("CambioPrecioFormCtrl", CambioPrecioFormCtrl);

CambioPrecioFormCtrl.$inject = [
    "cambioPrecioPrepService",
    "VentasLangFactory",
    "$state",
    "$filter",
    "$scope",
    "$rootScope",
    "ModelTrimmer",
    "notify",
    "ProductosFactory",
    "filterFactory",
    "UsuariosFactory",
    "UtilFactory",
    "CambioPrecioFactory",
    "ProductoAlmacenFactory"

];

function CambioPrecioFormCtrl(
    cambioPrecioPrepService,
    VentasLangFactory,
    $state,
    $filter,
    $scope,
    $rootScope,
    ModelTrimmer,
    notify,
    ProductosFactory,
    filterFactory,
    UsuariosFactory,
    UtilFactory,
    CambioPrecioFactory,
    ProductoAlmacenFactory
    ) {


    var vm = this;

    activate();

    vm.submit = submit;
    vm.cancel = cancel;
    vm.agregarDetalle = agregarDetalle;
    vm.removeItemFromArray = removeItemFromArray;
    vm.changeProducto = changeProducto;
    vm.changePrecio = changePrecio;
    vm.delayRequestProducto = delayRequestProducto;
    vm.changeUnidadMedida = changeUnidadMedida;

    function activate() {

        VentasLangFactory.getTranslations().then(function (translations) {

            if ($state.is("app.cambioprecio.new")) {
                activateNew();
            } else if ($state.is("app.cambioprecio.edit")) {
                activateEdit();
            } else if ($state.is("app.cambioprecio.view")) {
                activateView();
            }
        });
        //vm.productos = ProductosFactory.allForSelect();

        // Se habilita boton guardar (sin esto se oscurece-bloquea)
        $rootScope.isProcessing = false;
        vm.submited = false;
    }

    function activateNew() {
        vm.title = $filter("translate")("NEW_CAMBIO_PRECIO_PRODUCTO");
        vm.cambioPrecio = {setear:true};
    }

    function activateEdit() {
        vm.edit = true;
        vm.title = $filter("translate")("EDIT_CAMBIO_PRECIO_PRODUCTO");
        vm.cambioPrecio = cambioPrecioPrepService.cambioPrecio;
        vm.cambioPrecio.cambiosPrecioDetalle = [{'id':cambioPrecioPrepService,
        'precioAnterior': cambioPrecioPrepService.precioAnterior,
        'precioActual': cambioPrecioPrepService.precioActual,
        'fechaUltimoCambio': cambioPrecioPrepService.fechaUltimoCambio,
        'nombre': cambioPrecioPrepService.nombre,
        'unidadMedida': cambioPrecioPrepService.unidadMedida }];
        getCosto();
        changeUnidadMedida(vm.cambioPrecio.cambiosPrecioDetalle[0]);
    }

    function activateView() {
        vm.view = true;
        vm.title = $filter("translate")("VIEW_CAMBIO_PRECIO_PRODUCTO");
        vm.cambioPrecio = cambioPrecioPrepService.cambioPrecio;
        vm.cambioPrecio.cambiosPrecioDetalle = [{'id':cambioPrecioPrepService,
        'precioAnterior': cambioPrecioPrepService.precioAnterior,
        'precioActual': cambioPrecioPrepService.precioActual,
        'fechaUltimoCambio': cambioPrecioPrepService.fechaUltimoCambio,
        'nombre': cambioPrecioPrepService.nombre,
        'unidadMedida': cambioPrecioPrepService.unidadMedida }];

        getCosto();
        changeUnidadMedida(vm.cambioPrecio.cambiosPrecioDetalle[0]);
    }

    function changeProducto(){
        vm.cambioPrecio.costo = null;
        vm.cambioPrecio.costoView = null;

        vm.cambioPrecio.cambiosPrecioDetalle = [{}];
        vm.cambioPrecio.cambiosPrecioDetalle[0].unidadMedida = vm.cambioPrecio.producto.unidadMedidaBase;

        getCosto();
    }

    function getCosto(){
        // Seteo de costo
        var filtroCosto = {};
        var fecha = moment(new Date()).format('DD/MM/YYYY');

        filtroCosto.search = filterFactory
        .and([{
            path: 'producto.id',
            equals: vm.cambioPrecio.producto.id
        },{
            path: 'dia',
            equalOrBefore: fecha
        },{
            path: 'dia',
            sortDesc: 'true'
        }]).value();

        ProductoAlmacenFactory.all(filtroCosto).$promise.then(function(productosAlmacen){
            if( productosAlmacen!= undefined && productosAlmacen.length > 0){
                vm.cambioPrecio.costo = parseFloat(productosAlmacen[0].ultimoCostoIVA).toFixed(2);
            }
        });
    }

    function changePrecio(detalle){
        detalle.setear = true;
        if(detalle.precioActual != cambioPrecioPrepService.precioActual){
            detalle.precioAnterior = cambioPrecioPrepService.precioActual;
        }
    }

    function agregarDetalle() {
        vm.cambioPrecio.cambiosPrecioDetalle.push({setear:true});
        var rowNumber = vm.cambioPrecio.cambiosPrecioDetalle.length - 1;
    }

    function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
    }

    function delayRequestProducto(searchValue){
        var userInputFilter = {};
        var staticFilter = [];
        if (searchValue.length >= 3) {
          
                var filterNombre = staticFilter.slice()
                var filterCodigoBarras = staticFilter.slice()
                var filterCodigoInterno = staticFilter.slice()

                filterNombre.push({
                    path: "nombre",
                    like: searchValue
                },
                {
                    path: "estado.codigo",
                    notEquals: 'inactivo'
                },
                {
                    path: "itemVenta",
                    equals: true
                }
                )

                filterCodigoBarras.push({
                    path: "codigoBarrasPrincipal.codigoBarras",
                    like: searchValue
                },
                {
                    path: "estado.codigo",
                    notEquals: 'inactivo'
                },
                {
                    path: "itemVenta",
                    equals: true
                })

                filterCodigoInterno.push( {
                    path: "codigo",
                    like: searchValue
                },
                {
                    path: "estado.codigo",
                    notEquals: 'inactivo'
                },
                {
                    path: "itemVenta",
                    equals: true
                })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }

            $scope.recuperarProducto(userInputFilter)
        } else if (searchValue.length > 0 && searchValue.length < 3)  {

            var filterCodigoInterno = staticFilter.slice()
            filterCodigoInterno.push( {
                path: "codigo",
                like: searchValue
            },
            {
                path: "itemVenta",
                equals: true
            })

             userInputFilter = {
                search:  filterFactory.or([
                     filterFactory.and(filterCodigoInterno).value()
                  ]).value()
            }
            $scope.recuperarProducto(userInputFilter)
        } else {
            vm.productos = undefined
        }
    }

    $scope.recuperarProducto = function (userInputFilter) { 
        var finalFilter = UtilFactory.createNestedFilters(
        $scope.finalFilters, userInputFilter);
        vm.productos = ProductosFactory.allForSelect(userInputFilter)
    };

    function submit() {

        vm.submited = true;

        if ($scope.CambioPrecioForm.$valid) {

            delete vm.cambioPrecio.costo;

            if (vm.cambioPrecio.cambiosPrecioDetalle.length >= 1) {
                if(vm.cambioPrecio.cambiosPrecioDetalle[0].precioActual == 0){

                    var msg = $filter("translate")("PRECIO_ERROR");
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    $rootScope.isProcessing = false;

                }else{
                    $rootScope.isProcessing = true;

                    // Se recupera el usuario asociado
                    var usersFilter = {};
                    usersFilter.search = filterFactory.and([{
                        path: 'username',
                        equals: $rootScope.AuthParams.username
                    }]).value();
                    usersFilter.view = "BaseList"
                    UsuariosFactory.all(usersFilter).$promise.then(function (data) {
                        // Se realiza el seteo del usuario, de acuerdo al campo 'setear'
                        _.forEach(vm.cambioPrecio.cambiosPrecioDetalle, function(detalle) {
                            
                            if(detalle.setear == true){
                                detalle.usuario = data[0];
                                delete detalle.setear;
                            }
                        });

                        submitCambioPrecio().then(function(response) {
                            $state.go('app.cambioprecio.list');
                        }, function(error) {
                            $rootScope.isProcessing = false;
                            var msg = "error";
                            if(error.data[0]!=null && error.data[0].message!=null){
                                msg = error.data[0].message;
                            }
        
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        });
                    });
                }

            } else {
                var msg = vm.translations.ERROR_NO_DETALLES;
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
        }else{
            notify({
                message: $filter("translate")("WRONG_FIELDS"),
                classes: "alert-danger",
                position: "right"
              });
        }
    }

    function submitCambioPrecio() {
        var trimmedModel = ModelTrimmer.trimDetails(vm.cambioPrecio, ["cambiosPrecioDetalle"]);
        var trimmedDetalles = _.map(vm.cambioPrecio.cambiosPrecioDetalle, function(detalle) {
            return ModelTrimmer.trimDetails(detalle);
        });
        trimmedModel.cambiosPrecioDetalle = trimmedDetalles;
        if($rootScope.AuthParams.locacionSeleccionada != undefined){
            trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        }
        var resource = CambioPrecioFactory.create(trimmedModel);
        return CambioPrecioFactory.save(resource);
    }

    function cancel() {
        $state.go("app.cambioprecio.list");
    }

    function changeUnidadMedida(detalle){
        var filtroCosto = {};
        var fecha = moment(new Date()).format('DD/MM/YYYY');

        filtroCosto.search = filterFactory
        .and([{
            path: 'producto.id',
            equals: vm.cambioPrecio.producto.id
        },{
            path: 'dia',
            equalOrBefore: fecha
        },{
            path: 'dia',
            sortDesc: 'true'
        }]).value();

        ProductoAlmacenFactory.all(filtroCosto).$promise.then(function(productosAlmacen){
            if( productosAlmacen!= undefined && productosAlmacen.length > 0){
                vm.cambioPrecio.costo = parseFloat(productosAlmacen[0].ultimoCostoIVA).toFixed(2);
                var unidadMedidaNew, cantidadContenida, costoActual;
                unidadMedidaNew = detalle.unidadMedida;
                cantidadContenida = unidadMedidaNew.cantidad;
                costoActual = vm.cambioPrecio.costo;
                if(cantidadContenida != undefined && cantidadContenida != null){
                    vm.cambioPrecio.costo = Math.round(costoActual * cantidadContenida);
                }
            }
        });
    }
}

'use strict';

angular.module('qualita.venta')
  .controller('CargaPrecioCtrl', CargaPrecioCtrl);

CargaPrecioCtrl.$inject = ['$scope', 'CambioPrecioFactory', '$location', '$state', 'VentasLangFactory', 'notify', '$filter', 'filterFactory', '$rootScope', 'UsuariosFactory'];

function CargaPrecioCtrl($scope, CambioPrecioFactory, $location, $state, VentasLangFactory, notify, $filter, filterFactory, $rootScope, UsuariosFactory) {
  var vm = this;
  vm.uploadOptions = {
    imageOnly: false
  };

  $rootScope.ocultar = true;  // Oculta boton Cancelar que sale luego de subir el archivo

  VentasLangFactory.getTranslations().then(function(translations) {
    $scope.translations = translations;
  });

  $scope.cancel = function() {
    $location.path("/cambioprecio");
  };

  $scope.submit = function() {
    var flow = vm.uploadOptions.flow;
    var flowFile = flow.files[0];

    if (flowFile) {
      var fileIdentifier = flowFile.uniqueIdentifier + "-" + flowFile.name;

      // Verificar si el archivo ya está en proceso de carga
      if (localStorage.getItem(fileIdentifier)) {
        notify({ message: "El archivo ya está en proceso de carga.", classes: 'alert-warning', position: 'right' });
        return;
      }

      // Marcar el archivo como en proceso de carga
      localStorage.setItem(fileIdentifier, 'uploading');
      $rootScope.isProcessing = true;

      // Recuperar el usuario asociado
      var usersFilter = {
        search: filterFactory.and([{
          path: 'username',
          equals: $rootScope.AuthParams.username
        }]).value(),
        view: "BaseList"
      };

      UsuariosFactory.all(usersFilter).$promise.then(function(data) {
        var userId = data[0].id;
        CambioPrecioFactory.carga(fileIdentifier, userId).then(function(response) {
          localStorage.removeItem(fileIdentifier);
          $rootScope.isProcessing = false;

          if (response.data.mensajes) {
            CambioPrecioFactory.setData(response.data);
            response.data.mensajes.forEach(function(mensaje) {
              notify({ message: mensaje, classes: 'alert-danger', position: 'right' });
            });
          } else {
            $state.go("app.cambioprecio.list");
          }
        }, function(error) {
          localStorage.removeItem(fileIdentifier);
          $rootScope.isProcessing = false;
          notify({ message: error.data[0].message, classes: 'alert-danger', position: 'right' });
          $state.go("app.cambioprecio.list");
        });
      }, function() {
        localStorage.removeItem(fileIdentifier);
        $rootScope.isProcessing = false;
        console.log('Error al importar el archivo');
      });
    }
  };
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.venta')
  .factory('CambioPrecioFactory', CambioPrecioFactory);

CambioPrecioFactory.$inject = ['$resource', 'baseurl', '$http', 'VentasLangFactory'];

function CambioPrecioFactory($resource, baseurl, $http, VentasLangFactory) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    getUltimoPrecio: getUltimoPrecio,
    carga: carga,
    getUltimoPrecioCompleto: getUltimoPrecioCompleto
  };

  var CambioPrecio = $resource( baseurl.getBaseUrl() + "/cambioprecio/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    },
    cerrar: {
      method: 'POST',
      url: baseurl.getBaseUrl() + "/cambioprecio/cargar"
    }
  });


  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'base';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'base';
    }
    return CambioPrecio.query(params);
  }

  function get(id, view) {
    return CambioPrecio.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new CambioPrecio(attrs);
  }

  function save(tipoventa) {
    return (tipoventa.id) ? tipoventa.$update() : tipoventa.$save();
  }

  function getUltimoPrecio(idProducto, idUnidad){
    return $http({
        method : "GET",
        url : baseurl.getBaseUrl()+"/cambioprecio/ultimoprecio/"+idProducto+"/"+idUnidad
    });
  }

  function carga(archivo, usuario) {
    console.log("usuario ", usuario)
    return $http({
      url: baseurl.getBaseUrl() + '/cambioprecio/cargar',
      method: "POST",
      params: { filename: archivo, usuario: usuario }
    });
  }

  function getUltimoPrecioCompleto(idProducto, idUnidad){
    return $http({
        method : "GET",
        url : baseurl.getBaseUrl()+"/cambioprecio/ultimopreciocompleto/"+idProducto+"/"+idUnidad
    });
  }
}

"use strict";

angular
        .module("qualita.venta")
        .controller("ConsultaCambioPrecioIndexCtrl", ["CambioPrecioFactory", "VentasLangFactory", "$filter", "$scope", "$rootScope", "notify", "ProductosFactory", "filterFactory", "CambioPrecioHistoricoFactory", "ReportTicketFactory", "$window", "UtilFactory", function(CambioPrecioFactory,
    VentasLangFactory,
    $filter,
    $scope,
    $rootScope,
    notify,
    ProductosFactory,
    filterFactory,
    CambioPrecioHistoricoFactory,
    ReportTicketFactory,
    $window,
    UtilFactory
){
    var vm = this;

    //vm.changeProducto = changeProducto;
    vm.cambiarFiltro = cambiarFiltro;
    vm.delayRequestProducto = delayRequestProducto;

    activate();

    var precioRender = function(data, type, row) {
        if (data != undefined) {
            var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
            var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);

            // console.log("AQUI sub1: ",parteEntera);
            // console.log("AQUI sub2: ",parteDecimal);

            // Si tiene parte entera
            if(parteEntera != ''){
                return (
                    parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                    ","+parteDecimal.toString()
                );
            }else{
                return (
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            }

        } else {
            return "";
        }
    };

    function setUpOptions() {
        //VentasLangFactory.getTranslations().then(function (translations) {
        //    $scope.translations = translations;

            // Se habilita boton guardar (sin esto se oscurece-bloquea)
            $rootScope.isProcessing = false;
            vm.submited = false;
    
            // Definicion del datatable de resultados
            var defaultColumnOrder = ['id', 'producto.codigo', 'producto.codigoBarrasPrincipal.codigoBarras', 'producto.nombre', 'unidadMedida.descripcion',
            'precioAnterior', 'precioNuevo', 'fechaCambio', 'nombre'];
            $scope.options = {
                'staticFilter': '',
                'resource': 'cambiopreciohistorico',
                'title': $filter('translate')('HISTORICO_CAMBIO_PRECIO'),
                'view': 'CambioPrecioHistoricoList',
                'factory': CambioPrecioHistoricoFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('FAIL_DELET_ENTIDAD'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('ID'), visible: false, searchable:false },
                    { 'data': 'producto.codigo', 'title': $filter('translate')('CODIGO_INTERNO'), 'renderWith': 'emptyRender', orderable: false, searchable:false },
                    { 'data': 'producto.codigoBarrasPrincipal.codigoBarras', 'title': $filter('translate')('CODIGO_BARRAS'), orderable: false, searchable:false },
                    { 'data': 'producto.nombre', 'title': $filter('translate')('PRODUCTO'), 'renderWith': 'emptyRender', orderable: false, searchable:false },
                    { 'data': 'unidadMedida.descripcion', 'title': $filter('translate')('UNIDAD_MEDIDA'), 'renderWith': 'emptyRender', orderable: false, searchable:false },
                    { 'data': 'precioAnterior', 'title': $filter('translate')('PRECIO_ANTERIOR'), 'render': precioRender, orderable: false, searchable:false, class: 'dt-right' },
                    { 'data': 'precioNuevo', 'title': $filter('translate')('PRECIO_NUEVO'), 'render': precioRender, orderable: false, searchable:false, class: 'dt-right' },
                    { 'data': 'fechaCambio', 'title': $filter('translate')('FECHA_MODIFICACION'), 'renderWith': 'emptyRender', orderable: false, searchable:false, class:'dt-center'},
                    { 'data': 'nombre', 'title': $filter('translate')('USUARIO'), 'renderWith': 'emptyRender', orderable: false, searchable:false },
                ],
                'hasOptions': false,
                'hideAddMenu': true,
                'defaultOrderColumn': 7,
                'defaultOrderDir': "asc",
                'extraMenuOptions': [{
                    'title': 'Reporte',
                    'icon': 'glyphicon glyphicon-file',
                    'showCondition': function () { return true; },
                    'action': function() {
                        var filtersArr = [];
                        if (vm.fechaDesde) {
                            filtersArr.push("fechaDesde=" + $filter('date')(vm.fechaDesde, "MM/dd/yyyy"));
                        }
                        if (vm.fechaHasta) {
                            filtersArr.push("fechaHasta=" + $filter('date')(vm.fechaHasta, "MM/dd/yyyy"));
                        }
                        if (vm.producto) {
                            filtersArr.push("idproducto=" + vm.producto.id);
                        }
                        
                        var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("listadoCambioPrecioHistorico", 'pdf', filtersArr);
                        $window.open(pdfDownloadURL, '_blank');
                        console.log("report");
                      
                      //  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);
                       // ReportTicketFactory.ticket('listadoCambioPrecioHistorico', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                         //   $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                           // $window.open($scope.pdfDownloadURL, '_blank');
                        //});
                    }
                }]
            };
       // });
    }

    function activate() {
       $scope.boolean = false;
       
        VentasLangFactory.getTranslations().then(function(translations){
            $scope.translations = translations;
            setUpOptions();
        });
    }

    function agregarDetalle() {
        vm.cambioPrecio.cambiosPrecioDetalle.push({setear:true});
        var rowNumber = vm.cambioPrecio.cambiosPrecioDetalle.length - 1;
    }

    function cambiarFiltro(){

        if(vm.producto == undefined){
            notify({ message: "Debe seleccionar un producto", classes: 'alert-danger', position: 'right' });
        } else{
            if(vm.fechaDesde != undefined && vm.fechaHasta != undefined && vm.fechaDesde > vm.fechaHasta){
                notify({ message: $filter('translate')('FECHA_DES_MAYOR_FECHA_HAS'), classes: 'alert-danger', position: 'right' });
            } else{
                $scope.boolean = true;
                var staticFilter = {};

                // Se suma 1 dia a la fecha hasta (Para que recupere los cambios hasta 23:59 de la fecha seleccionada)
                var fechaDate = new Date(vm.fechaHasta); 
                var fechaHastaIncrementado = moment(fechaDate).add(1, 'days');

                var andFilters = [{
                    path: 'fechaCambio',
                    equalOrAfter: (vm.fechaDesde != undefined) ? moment(vm.fechaDesde).format('DD/MM/YYYY') : undefined
                }, {
                    path: 'fechaCambio',
                    before: (vm.fechaHasta != undefined) ? moment(fechaHastaIncrementado).format('DD/MM/YYYY') : undefined
                }, {
                    path: 'producto.id',
                    equals: vm.producto.id
                }];

                staticFilter.search = filterFactory.and(andFilters).value();
                $scope.options.staticFilter = staticFilter;
            }
       }
    }

    function delayRequestProducto(searchValue){
        var userInputFilter = {};
        var staticFilter = [];
        if (searchValue.length >= 3) {
          
                var filterNombre = staticFilter.slice()
                var filterCodigoBarras = staticFilter.slice()
                var filterCodigoInterno = staticFilter.slice()

                filterNombre.push({
                    path: "nombre",
                    like: searchValue
                })

                filterCodigoBarras.push({
                    path: "codigoBarrasPrincipal.codigoBarras",
                    like: searchValue
                })

                filterCodigoInterno.push( {
                    path: "codigo",
                    like: searchValue
                })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }

            $scope.recuperarProducto(userInputFilter)
        } else if (searchValue.length > 0 && searchValue.length < 3)  {

            var filterCodigoInterno = staticFilter.slice()
            filterCodigoInterno.push( {
                path: "codigo",
                like: searchValue
            })

             userInputFilter = {
                search:  filterFactory.or([
                     filterFactory.and(filterCodigoInterno).value()
                  ]).value()
            }
            $scope.recuperarProducto(userInputFilter)
        } else {
            vm.productos = undefined
        }
    }

    $scope.recuperarProducto = function (userInputFilter) { 
        var finalFilter = UtilFactory.createNestedFilters(
        $scope.finalFilters, userInputFilter);
        vm.productos = ProductosFactory.allForSelect(userInputFilter)
    };

    var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({path: data, like: search})
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }
}]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.venta')
  .factory('CambioPrecioHistoricoFactory', CambioPrecioHistoricoFactory);

CambioPrecioHistoricoFactory.$inject = ['$resource', 'baseurl', '$http', 'VentasLangFactory'];

function CambioPrecioHistoricoFactory($resource, baseurl, $http, VentasLangFactory) {
  var service = {
    all: all,
    get: get
  };

  var CambioPrecioHistorico = $resource( baseurl.getBaseUrl() + "/cambiopreciohistorico/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });


  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'base';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'base';
    }
    return CambioPrecioHistorico.query(params);
  }

  function get(id, view) {
    return CambioPrecioHistorico.get({id: id, view: view ? view : "base"});
  }
}

'use strict';

angular.module('qualita.venta')
    .controller('CambioPrecioServicioListCtrl', CambioPrecioServicioListCtrl);

CambioPrecioServicioListCtrl.$inject = ["AuthorizationService", "CambioPrecioServicioFactory", "VentasLangFactory", "$scope", "$filter", 
    "filterFactory", "$state"];

function CambioPrecioServicioListCtrl(AuthorizationService, CambioPrecioServicioFactory, VentasLangFactory, $scope, $filter,
    filterFactory, $state) {


    var vm = this;
    activate();

    var monedaRender = function (data) {
        var dataStr = (data+"");
        if (data!=null) {
            if(dataStr.indexOf(".") == -1){
                data = parseFloat(data).toFixed(0);
                return (
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
            }else{
                data = parseFloat(data).toFixed(2);
                return (
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
            }
        } else {
            return "";
        }
    };

    function activate() {

        VentasLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;

            var staticFilter = {
                search: filterFactory.and([{
                    path: "cambioPrecio.producto.id",
                    isNull: "true"
                }]).value()
            };

            var defaultColumnOrder = ['id', 'cambioPrecio.id', 'cambioPrecio.servicio.descripcion', 'precioActual', 'fechaUltimoCambio', 
            'nombre'];
            vm.options = {
                'resource': 'cambioprecioservicio',
                'staticFilter': staticFilter,
                'title': $filter('translate')('CAMBIO_PRECIO_SERVICIO'),
                'factory': CambioPrecioServicioFactory,
                'view': 'CambioPrecioServicioList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('CODE'), visible:false },
                    { 'data': 'cambioPrecio.id', 'title': "Cod.Cabecera", visible:false },
                    { 'data': 'cambioPrecio.servicio.descripcion', 'title': $filter('translate')('NOMBRE') },
                    { 'data': 'precioActual', 'title': $filter('translate')('PRECIO_ACTUAL'), 'renderWith': monedaRender, class: 'dt-right'},
                    { 'data': 'fechaUltimoCambio', 'title': $filter('translate')('HORA_MODIFICACION'), 'renderWith': "dateRender", 'dateFormat': "DD/MM/YYYY", class:'dt-center'},
                    { 'data': 'nombre', 'title': $filter('translate')('USUARIO')}
                ],
                'hasOptions': true,
                'hideRemoveMenu': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "asc"
            };
        });
       
    }
}
"use strict";

angular
        .module("qualita.venta")
        .controller("CambioPrecioServicioFormCtrl", CambioPrecioServicioFormCtrl);

CambioPrecioServicioFormCtrl.$inject = [
    "cambioPrecioServicioPrepService",
    "VentasLangFactory",
    "$state",
    "$filter",
    "$scope",
    "$rootScope",
    "ModelTrimmer",
    "notify",
    "ProductosFactory",
    "filterFactory",
    "UsuariosFactory",
    "UtilFactory",
    "CambioPrecioServicioFactory",
    "ProductoAlmacenFactory",
    "ServicioFactory"

];

function CambioPrecioServicioFormCtrl(
    cambioPrecioServicioPrepService,
    VentasLangFactory,
    $state,
    $filter,
    $scope,
    $rootScope,
    ModelTrimmer,
    notify,
    ProductosFactory,
    filterFactory,
    UsuariosFactory,
    UtilFactory,
    CambioPrecioServicioFactory,
    ProductoAlmacenFactory,
    ServicioFactory
    ) {


    var vm = this;

    activate();

    vm.submit = submit;
    vm.cancel = cancel;
    vm.changeServicio = changeServicio;
    vm.changePrecio = changePrecio;

    function activate() {

        VentasLangFactory.getTranslations().then(function (translations) {

            if ($state.is("app.cambioprecioservicio.new")) {
                activateNew();
            } else if ($state.is("app.cambioprecioservicio.edit")) {
                activateEdit();
            } else if ($state.is("app.cambioprecioservicio.view")) {
                activateView();
            }
        });
        
        var filtroServicios = {};
        filtroServicios.search = filterFactory.and([{
                path: 'venta',
                equals: true
            }]).value();

        vm.servicios = ServicioFactory.all(filtroServicios, "ConsultaComprobanteVentaForm");

        // Se habilita boton guardar (sin esto se oscurece-bloquea)
        $rootScope.isProcessing = false;
        vm.submited = false;
    }

    function activateNew() {
        vm.title = $filter("translate")("NEW_CAMBIO_PRECIO_SERVICIO");
        vm.cambioPrecioServicio = {setear:true};
    }

    function activateEdit() {
        vm.edit = true;
        vm.title = $filter("translate")("EDIT_CAMBIO_PRECIO_SERVICIO");
        vm.cambioPrecioDetalleServicio = cambioPrecioServicioPrepService;
        vm.cambioPrecioServicio = vm.cambioPrecioDetalleServicio.cambioPrecio;
        vm.cambioPrecioServicio.cambiosPrecioDetalle = [{'id':vm.cambioPrecioDetalleServicio,
                                                        'precioActual': vm.cambioPrecioDetalleServicio.precioActual,
                                                        'fechaUltimoCambio': vm.cambioPrecioDetalleServicio.fechaUltimoCambio,
                                                        'nombre': vm.cambioPrecioDetalleServicio.nombre }];
    }

    function activateView() {
        vm.view = true;
        vm.title = $filter("translate")("VIEW_CAMBIO_PRECIO_SERVICIO");
        vm.cambioPrecioDetalleServicio = cambioPrecioServicioPrepService;
        vm.cambioPrecioServicio = vm.cambioPrecioDetalleServicio.cambioPrecio;
        vm.cambioPrecioServicio.cambiosPrecioDetalle = [{'id':vm.cambioPrecioDetalleServicio,
        'precioActual': vm.cambioPrecioDetalleServicio.precioActual,
        'fechaUltimoCambio': vm.cambioPrecioDetalleServicio.fechaUltimoCambio,
        'nombre': vm.cambioPrecioDetalleServicio.nombre }];
    }

    function changeServicio(){

        vm.cambioPrecioServicio.cambiosPrecioDetalle = [{}];

        // Verificar si ya existe un cambio de precio con el producto seleccionado
        var filtroCambioServicio = {};

        filtroCambioServicio.search = filterFactory
        .single({
                path: 'cambioPrecio.servicio.id',
                equals: vm.cambioPrecioServicio.servicio.id
        }).value();

        CambioPrecioServicioFactory.all(filtroCambioServicio, "CambioPrecioList").$promise.then(function(cambioPrecio){
            if(cambioPrecio!=null && cambioPrecio.length>0){
                $state.go("app.cambioprecioservicio.edit",{id:cambioPrecio[0].id});
            }
        });
    }

    function changePrecio(detalle){
        vm.setear = true;
    }

    function submit() {

        vm.submited = true;
        if ($scope.CambioPrecioServicioForm.$valid) {
            $rootScope.isProcessing = true;

            if(vm.cambioPrecioServicio.cambiosPrecioDetalle[0].precioActual == 0){
                var msg = $filter("translate")("PRECIO_ERROR");
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                $rootScope.isProcessing = false;
            }else{

                // Se recupera el usuario asociado
                var usersFilter = {};
                usersFilter.search = filterFactory.and([{
                    path: 'username',
                    equals: $rootScope.AuthParams.username
                }]).value();
                usersFilter.view = "BaseList"
                UsuariosFactory.all(usersFilter).$promise.then(function (data) {

                    if(vm.setear == true){
                        vm.cambioPrecioServicio.cambiosPrecioDetalle[0].usuario = data[0];
                    }

                    submitCambioPrecio().then(function(response) {
                        $state.go('app.cambioprecioservicio.list');
                    }, function(error) {
                        $rootScope.isProcessing = false;
                        var msg = "error";
                        if(error.data[0]!=null && error.data[0].message!=null){
                            msg = error.data[0].message;
                        }

                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    });
                });
            }
        }
    }

    function submitCambioPrecio() {
        var trimmedModel = ModelTrimmer.trimDetails(vm.cambioPrecioServicio, ["cambiosPrecioDetalle"]);
        var trimmedDetalles = _.map(vm.cambioPrecioServicio.cambiosPrecioDetalle, function(detalle) {
            return ModelTrimmer.trimDetails(detalle);
        });
        trimmedModel.cambiosPrecioDetalle = trimmedDetalles;
        trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        var resource = CambioPrecioServicioFactory.create(trimmedModel);
        return CambioPrecioServicioFactory.save(resource);
    }

    function cancel() {
        $state.go("app.cambioprecioservicio.list");
    }
}

/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.venta')
  .factory('CambioPrecioServicioFactory', CambioPrecioServicioFactory);

CambioPrecioServicioFactory.$inject = ['$resource', 'baseurl', '$http', 'VentasLangFactory'];

function CambioPrecioServicioFactory($resource, baseurl, $http, VentasLangFactory) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    getUltimoPrecio: getUltimoPrecio,
  };

  var CambioPrecio = $resource( baseurl.getBaseUrl() + "/cambioprecioservicio/:id", {id: '@id'}, {
    update: {
      method: 'PUT'
    }
  });


  return service;

  function all(params, view) {
    if (params) {
      params.view = view!=undefined ? view : 'base';
    } else {
      params = [];
      params.view = view!=undefined ? view : 'base';
    }
    return CambioPrecio.query(params);
  }

  function get(id, view) {
    return CambioPrecio.get({id: id, view: view ? view : "base"});
  }

  function create(attrs) {
    return new CambioPrecio(attrs);
  }

  function save(tipoventa) {
    return (tipoventa.id) ? tipoventa.$update() : tipoventa.$save();
  }

  function getUltimoPrecio(idServicio){
    return $http({
        method : "GET",
        url : baseurl.getBaseUrl()+"/cambioprecioservicio/ultimoprecio/"+idServicio
    });
  }
}

"use strict";

angular
        .module("qualita.venta")
        .controller("ConsultaCambioPrecioServicioIndexCtrl", ["CambioPrecioFactory", "VentasLangFactory", "$filter", "$scope", "$rootScope", "notify", "ServicioFactory", "filterFactory", "CambioPrecioHistoricoFactory", "ReportTicketFactory", "$window", "UtilFactory", function(CambioPrecioFactory,
    VentasLangFactory,
    $filter,
    $scope,
    $rootScope,
    notify,
    ServicioFactory,
    filterFactory,
    CambioPrecioHistoricoFactory,
    ReportTicketFactory,
    $window,
    UtilFactory
){
    var vm = this;

    //vm.changeProducto = changeProducto;
    vm.cambiarFiltro = cambiarFiltro;

    activate();

    var precioRender = function (data) {
        var dataStr = (data+"");
        if (data!=null) {
            if(dataStr.indexOf(".") == -1){
                data = parseFloat(data).toFixed(0);
                return (
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
            }else{
                data = parseFloat(data).toFixed(2);
                return (
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
            }
        } else {
            return "";
        }
    };

    function setUpOptions() {
        //VentasLangFactory.getTranslations().then(function (translations) {
        //    $scope.translations = translations;

            // Se habilita boton guardar (sin esto se oscurece-bloquea)
            $rootScope.isProcessing = false;
            vm.submited = false;
    
            // Definicion del datatable de resultados
            var defaultColumnOrder = ['id', 'servicio.descripcion', 'precioAnterior', 'precioNuevo', 'fechaCambio', 'nombre'];
            $scope.options = {
                'staticFilter': '',
                'resource': 'cambiopreciohistorico',
                'title': $filter('translate')('HISTORICO_CAMBIO_PRECIO'),
                'view': 'CambioPrecioHistoricoList',
                'factory': CambioPrecioHistoricoFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': $filter('translate')('FAIL_DELET_ENTIDAD'),
                'columns': [
                    { 'data': 'id', 'title': $filter('translate')('ID'), visible: false, searchable:false },
                    { 'data': 'servicio.descripcion', 'title': $filter('translate')('SERVICIO'), 'renderWith': 'emptyRender', orderable: false, searchable:false },                    
                    { 'data': 'precioAnterior', 'title': $filter('translate')('PRECIO_ANTERIOR'), 'render': precioRender, orderable: false, searchable:false, class: "dt-right" },
                    { 'data': 'precioNuevo', 'title': $filter('translate')('PRECIO_NUEVO'), 'render': precioRender, orderable: false, searchable:false, class: "dt-right" },
                    { 'data': 'fechaCambio', 'title': $filter('translate')('FECHA_MOD'), 'renderWith': 'emptyRender', orderable: false, searchable:false, class: "dt-center" },
                    { 'data': 'nombre', 'title': $filter('translate')('USUARIO'), 'renderWith': 'emptyRender', orderable: false, searchable:false },
                ],
                'hasOptions': false,
                'hideAddMenu': true,
                'defaultOrderColumn': 1,
                'defaultOrderDir': "asc",
                'extraMenuOptions': [{
                    'title': 'Reporte',
                    'icon': 'glyphicon glyphicon-file',
                    'showCondition': function () { return true; },
                    'action': function() {
                        var filtersArr = [];
                        if (vm.fechaDesde) {
                            filtersArr.push("fechaDesde=" + $filter('date')(vm.fechaDesde, "MM/dd/yyyy"));
                        }
                        if (vm.fechaHasta) {
                            filtersArr.push("fechaHasta=" + $filter('date')(vm.fechaHasta, "MM/dd/yyyy"));
                        }
                        if (vm.servicio) {
                            filtersArr.push("idservicio=" + vm.servicio.id);
                        }
                        var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("listadoCambioPrecioServicioHistorico", 'pdf', filtersArr);
                        $window.open(pdfDownloadURL, '_blank');
                    }
                }]
            };
       // });
    }

    function activate() {
       $scope.boolean = false;

       var filtroServicios = {};
        filtroServicios.search = filterFactory.and([{
                path: 'venta',
                equals: true
            }]).value();

       vm.servicios = ServicioFactory.all(filtroServicios, "ConsultaComprobanteVentaForm");

        VentasLangFactory.getTranslations().then(function(translations){
            $scope.translations = translations;
            setUpOptions();
        });
    }

    function agregarDetalle() {
        vm.cambioPrecio.cambiosPrecioDetalle.push({setear:true});
        var rowNumber = vm.cambioPrecio.cambiosPrecioDetalle.length - 1;
    }

    function cambiarFiltro(){
        if(vm.servicio == undefined){
            notify({ message: "Debe seleccionar un servicio", classes: 'alert-danger', position: 'right' });
        }else{
            if(vm.fechaDesde != undefined && vm.fechaHasta != undefined && vm.fechaDesde > vm.fechaHasta){
                notify({ message: $filter('translate')('FECHA_DES_MAYOR_FECHA_HAS'), classes: 'alert-danger', position: 'right' });
            }else{
                $scope.boolean = true;
                var staticFilter = {};
                // Se suma 1 dia a la fecha hasta (Para que recupere los cambios hasta 23:59 de la fecha seleccionada)
                var fechaDate = new Date(vm.fechaHasta); 
                var fechaHastaIncrementado = moment(fechaDate).add(1, 'days');
                
                var andFilters = [{
                    path: 'fechaCambio',
                    equalOrAfter: (vm.fechaDesde != undefined) ? moment(vm.fechaDesde).format('DD/MM/YYYY HH:mm') : undefined
                }, {
                    path: 'fechaCambio',
                    before: (vm.fechaHasta != undefined) ? moment(fechaHastaIncrementado).format('DD/MM/YYYY HH:mm') : undefined
                }, {
                    path: 'servicio.id',
                    equals: vm.servicio.id
                }];
                
                staticFilter.search = filterFactory.and(andFilters).value();
                $scope.options.staticFilter = staticFilter;
            }
        }
    }

    var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({path: data, like: search})
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }
}]);

"use strict";

angular
    .module("qualita.venta")
    .controller("ReporteProductoCtrl", ["$scope", "$filter", "notify", "filterFactory", "VentasLangFactory", "TiposTipoFactory", "ClienteFactory", "UtilFactory", "CsvFactory", "$window", "baseurl", "ReportTicketFactory", "ComprobanteVentaDetalleReporteFactory", function(
        $scope,
        $filter,
        notify,
        filterFactory,
        VentasLangFactory,
        TiposTipoFactory,
        ClienteFactory,
        UtilFactory,
        CsvFactory,
        $window,
        baseurl,
        ReportTicketFactory,
        ComprobanteVentaDetalleReporteFactory
    ) {
        var vm = this;
        vm.cambiarFiltro = cambiarFiltro;
        vm.changeMoneda = changeMoneda;
        vm.changeFecha = changeFecha;
        vm.changeTipoReporte = changeTipoReporte;
        vm.changeCliente = changeCliente;

        var idCabecera;

        // recupera monedas
        vm.monedas = TiposTipoFactory.get("monedas");

        // recupera clientes
        //vm.clientes = ClienteFactory.all();

        /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
        vm.staticFilterClientes = [{ path: "activo", equals: true }];
        vm.clientesFactory = ClienteFactory;

        function changeTipoReporte() {
            $scope.filtro = false;
            $scope.inicio = false;

            if(vm.tipoReporte.codigo == "reporte_venta_cantidad"){
                $scope.options.graphicReport = "ReporteVentaCantidad";
                $scope.options.defaultOrderColumn = 2;
            }else if(vm.tipoReporte.codigo == "reporte_venta_importe"){
                $scope.options.graphicReport = "ReporteVentaImporte";
                $scope.options.defaultOrderColumn = 1;
            }
            
            
        }

        function changeMoneda() {
            $scope.filtro = false;
            $scope.inicio = false;
        }

        function changeFecha(params) {
            $scope.filtro = false;
            $scope.inicio = false;
        }

        function changeCliente() {
            $scope.filtro = false;
            $scope.inicio = false;
        }

        function cambiarFiltro() {

            $scope.inicio = true;

            // Definicion de resource del datatable y pathparams        
            var fechaDesdeString=null;
            if(vm.fechaDesde != undefined){
                const fechaDesde = moment(vm.fechaDesde);
                fechaDesdeString = fechaDesde.format("YYYYMMDD");
            }

            var fechaHastaString=null;
            if(vm.fechaHasta != undefined){
                const fechaHasta = moment(vm.fechaHasta);
                fechaHastaString = fechaHasta.format("YYYYMMDD");
            }

            if(vm.tipoReporte.codigo == "reporte_venta_cantidad"){
                $scope.options.defaultOrderColumn = 2;
            }else if(vm.tipoReporte.codigo == "reporte_venta_importe"){
                $scope.options.defaultOrderColumn = 1;
            }

            var paramCliente = null;
            if(vm.cliente != undefined){
                paramCliente = vm.cliente.id;
            }

            if(vm.moneda!=undefined){

                $scope.showGraphic = true;

                ComprobanteVentaDetalleReporteFactory.getReporteProducto(fechaDesdeString, fechaHastaString, vm.moneda.id, vm.tipoReporte.codigo)
                .then(function(response){

                    ComprobanteVentaDetalleReporteFactory.drawGraphicReport(response.data.data, $scope);

                    if(response.data.data.length > 0){
                        
                        idCabecera = response.data.data[0].reporteVentaCabecera.id;

                        // filtro estático
                        var staticFilter = {};
                        staticFilter.search = filterFactory.and([{
                            path: 'reporteVentaCabecera.id',
                            equals: idCabecera,
                        }]).value();

                        $scope.options.staticFilter = staticFilter;

                        // Mostrar datatable solo si hay registros
                        $scope.filtro=true;
                    }
                });

            }else{
                //var msg = $filter('translate')('EMVIO_MESSAGE_SUCCESSFUL',{entidad:nombre});
                var msg = "Debe seleccionar una moneda";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
        }

        var monedaRender = function (data, type, row) {
            var campoMoneda = row.reporteVentaCabecera.moneda;

            if (data != undefined) {
                var moneda = "Gs. ";
                if (campoMoneda.codigo === "dolares") {
                    moneda = "Usd. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "pesos") {
                    moneda = "Pes. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "real") {
                    moneda = "Rel. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "euro") {
                    moneda = "Eur. ";
                    data = parseFloat(data).toFixed(2);
                } else {
                    data = parseFloat(data).toFixed(0);
                }
                return (
                        moneda +
                        data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        );
            } else {
                return "";
            }
        };

        initViz();

        function initViz() {
            
            $scope.inicio = false;
            vm.tiposReporte = [{id:1, codigo:"reporte_venta_cantidad", descripcion:"Reporte de Venta por Cantidad"},
                                {id:2, codigo:"reporte_venta_importe", descripcion:"Reporte de Venta por Importe"}];
            vm.tipoReporte = vm.tiposReporte[1];

            /****************************************** */
            VentasLangFactory.getTranslations().then(function(translations) {
              var defaultColumnOrder = ['producto.nombre', 'cantidad', 'totalItem', 'reporteVentaCabecera.id'];
  
                $scope.options = {
                  'resource': 'comprobanteventadetallereporte',
                  // 'title': $filter('translate')('TIPOS_COMPRA'),
                  'factory': ComprobanteVentaDetalleReporteFactory,
                  'defaultColumnOrder': defaultColumnOrder,
                  'failedDeleteError': $filter('translate')('FAIL_DELETE_TIPO_COMPRA'),
                  'view': 'ReporteVentaProductoList',
                  'columns': [
                      //{ 'data': 'id', 'title': $filter('translate')('CODE'), 'visible': true  },
                      { 'data': 'producto.nombre', 'title': $filter('translate')('PRODUCTO'), searchable:false, orderable:false},
                      { 'data': 'totalItem', 'title': $filter('translate')('TOTAL_ITEM'), render: monedaRender, searchable:false, orderable:false},
                      { 'data': 'cantidad', 'title': $filter('translate')('CANTIDAD'), searchable:false, orderable:false},
                      { 'data': 'reporteVentaCabecera.id', 'title': $filter('translate')('CANTIDAD'), searchable:false, orderable:false, visible:false}
                  ],
                  'hasOptions': true,
                  'defaultOrderDir': "desc",
                  'hideRemoveMenu': true,
                  'hideViewMenu': true,
                  'hasOptions': false,
                  'graphicReport': "ReporteVentaImporte",
                  'typeReport': "circular",
                  'extraMenuOptions':[
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = createFilters($scope.options.getFilters());
                            ReportTicketFactory.ticket('listadoReporteProducto', filters, $scope.options.tableAjaxParams,
                            $scope.options.currentColumnOrder).then(function (genTicket) {
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open($scope.pdfDownloadURL, '_blank');
                            });
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("comprobanteventadetallereporte", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                            .then(function (response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                        }
                    }
                  ]
               };

               var createFilters = function (filters) {
                    var filtersArr = [];
                    _.each(filters, function (search, data) {
                        if(data == "reporteVentaCabecera.id"){
                            search = String(idCabecera);
                        }
                        filtersArr.push({ path: data, like: search })
                    });
                    var filters = filterFactory.and(filtersArr).value();
                    return filters;
                }
          });
        }
    }]);
"use strict";

angular
    .module("qualita.venta")
    .controller("ReporteFamiliaProductoCtrl", ["$scope", "$filter", "notify", "VentasLangFactory", "TiposTipoFactory", "LineasProductoFactory", "filterFactory", "ComprobanteVentaDetalleReporteFactory", "ReportTicketFactory", "$window", "UtilFactory", "CsvFactory", "baseurl", function(
        $scope,
        $filter,
        notify,
        VentasLangFactory,
        TiposTipoFactory,
        LineasProductoFactory,
        filterFactory,
        ComprobanteVentaDetalleReporteFactory,
        ReportTicketFactory,
        $window,
        UtilFactory,
        CsvFactory,
        baseurl
    ) {
        var vm = this;
        vm.cambiarFiltro = cambiarFiltro;
        vm.changeMoneda = changeMoneda;
        vm.changeFecha = changeFecha;
        vm.changeFamilia = changeFamilia;

        var idCabecera;

        // recupera monedas
        vm.monedas = TiposTipoFactory.get("monedas");

        function changeFamilia() {
            $scope.filtro = false;
            $scope.showGraphic = false;
        }

        function changeMoneda() {
            $scope.filtro = false;
            $scope.showGraphic = false;
        }

        function changeFecha(params) {
            $scope.filtro = false;
            $scope.showGraphic = false;
        }

        function cambiarFiltro() {

            // Definicion de resource del datatable y pathparams        
            var fechaDesdeString=null;
            if(vm.fechaDesde != undefined){
                const fechaDesde = moment(vm.fechaDesde);
                fechaDesdeString = fechaDesde.format("YYYYMMDD");
            }

            var fechaHastaString=null;
            if(vm.fechaHasta != undefined){
                const fechaHasta = moment(vm.fechaHasta);
                fechaHastaString = fechaHasta.format("YYYYMMDD");
            }

            if(vm.moneda!=undefined && vm.familiasFilter!=undefined){

                $scope.showGraphic = true;

                ComprobanteVentaDetalleReporteFactory.getReporteFamilia(fechaDesdeString, fechaHastaString, vm.moneda.id, vm.familiasFilter.id)
                .then(function(response){

                    ComprobanteVentaDetalleReporteFactory.drawGraphicReport(response.data.data, $scope);

                    if(response.data.data.length > 0){
                        
                        idCabecera = response.data.data[0].reporteVentaCabecera.id;

                        // filtro estático
                        var staticFilter = {};
                        staticFilter.search = filterFactory.and([{
                            path: 'reporteVentaCabecera.id',
                            equals: idCabecera,
                        }]).value();

                        $scope.options.staticFilter = staticFilter;

                        // Mostrar datatable solo si hay registros
                        $scope.filtro=true;
                    }
                })
            }else{
                if(vm.moneda==undefined){
                    //var msg = $filter('translate')('EMVIO_MESSAGE_SUCCESSFUL',{entidad:nombre});
                    var msg = "Debe seleccionar una moneda";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }else if(vm.familiasFilter==undefined){
                    var msg = "Debe seleccionar una familia";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }
            } 
        }

        var monedaRender = function (data, type, row) {
            var campoMoneda = row.reporteVentaCabecera.moneda;

            if (data != undefined) {
                var moneda = "Gs. ";
                if (campoMoneda.codigo === "dolares") {
                    moneda = "Usd. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "pesos") {
                    moneda = "Pes. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "real") {
                    moneda = "Rel. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "euro") {
                    moneda = "Eur. ";
                    data = parseFloat(data).toFixed(2);
                } else {
                    data = parseFloat(data).toFixed(0);
                }
                return (
                        moneda +
                        data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        );
            } else {
                return "";
            }
        };

        initViz();

        function initViz() {

            /******************** RECUPERACION DE FAMILIAS *************** */
            var params = {};
            params.search = filterFactory.and([{
                    path: 'clase.codigo',
                    equals: 'producto'
                },{
                    path: 'codigo',
                    sortAsc: true
                }
            ]).value();
            LineasProductoFactory.getAllTree(params).$promise.then(function(response) {
                vm.familias = response;
            });
            
            $scope.showGraphic = false;

            /****************************************** */
            VentasLangFactory.getTranslations().then(function(translations) {
              var defaultColumnOrder = ['producto.lineaProducto.nombre', 'cantidad', 'totalItem', 'reporteVentaCabecera.id'];
  
                $scope.options = {
                  'resource': 'comprobanteventadetallereporte',
                  // 'title': $filter('translate')('TIPOS_COMPRA'),
                  'view': 'ReporteVentaProductoList',
                  'factory': ComprobanteVentaDetalleReporteFactory,
                  'defaultColumnOrder': defaultColumnOrder,
                  'defaultOrderColumn':1,
                  'defaultOrderDir': 'desc',
                  'columns': [
                        { 'data': 'producto.lineaProducto.nombre', 'title': $filter('translate')('PRODUCTO'), searchable:false, orderable:false},
                        { 'data': 'totalItem', 'title': $filter('translate')('TOTAL_ITEM'), render: monedaRender, searchable:false, orderable:false},
                        { 'data': 'cantidad', 'title': $filter('translate')('CANTIDAD'), searchable:false, orderable:false},
                        { 'data': 'reporteVentaCabecera.id', 'title': $filter('translate')('CANTIDAD'), searchable:false, orderable:false, visible:false}
                  ],
                  'hasOptions': true,
                  'defaultOrderDir': "desc",
                  'hideRemoveMenu': true,
                  'hideViewMenu': true,
                  'hasOptions': false,
                  'graphicReport': "ReporteFamilia",
                  'typeReport': "circular",
                  'extraMenuOptions':[
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = createFilters($scope.options.getFilters());
                            ReportTicketFactory.ticket('listadoReporteFamilia', filters, $scope.options.tableAjaxParams,
                            $scope.options.currentColumnOrder).then(function (genTicket) {
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open($scope.pdfDownloadURL, '_blank');
                            });
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("comprobanteventadetallereporte", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                            .then(function (response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                        }
                    }
                  ]
                };

                var createFilters = function (filters) {
                    var filtersArr = [];
                    _.each(filters, function (search, data) {
                        if(data == "reporteVentaCabecera.id"){
                            search = String(idCabecera);
                        }
                        filtersArr.push({ path: data, like: search })
                    });
                    var filters = filterFactory.and(filtersArr).value();
                    return filters;
                }
          });
        }
    }]);
"use strict";

angular
    .module("qualita.venta")
    .controller("ReporteClienteCtrl", ["$scope", "$filter", "notify", "filterFactory", "VentasLangFactory", "TiposTipoFactory", "UtilFactory", "CsvFactory", "$window", "baseurl", "ReportTicketFactory", "ComprobanteVentaDetalleReporteFactory", function(
        $scope,
        $filter,
        notify,
        filterFactory,
        VentasLangFactory,
        TiposTipoFactory,
        UtilFactory,
        CsvFactory,
        $window,
        baseurl,
        ReportTicketFactory,
        ComprobanteVentaDetalleReporteFactory
    ) {
        var vm = this;
        vm.cambiarFiltro = cambiarFiltro;
        vm.changeMoneda = changeMoneda;
        vm.changeFecha = changeFecha;

        var idCabecera;

        // recupera monedas
        vm.monedas = TiposTipoFactory.get("monedas");

        function changeMoneda() {
            $scope.inicio = false;
            $scope.filtro = false;
        }

        function changeFecha(params) {
            $scope.filtro = false;
            $scope.filtro = false;
        }

        function cambiarFiltro() {

            $scope.inicio = true;

            // Definicion de resource del datatable y pathparams        
            var fechaDesdeString=null;
            if(vm.fechaDesde != undefined){
                const fechaDesde = moment(vm.fechaDesde);
                fechaDesdeString = fechaDesde.format("YYYYMMDD");
            }

            var fechaHastaString=null;
            if(vm.fechaHasta != undefined){
                const fechaHasta = moment(vm.fechaHasta);
                fechaHastaString = fechaHasta.format("YYYYMMDD");
            }

            if(vm.moneda!=undefined){

                $scope.showGraphic = true;

                ComprobanteVentaDetalleReporteFactory.getReporteCliente(fechaDesdeString, fechaHastaString, vm.moneda.id)
                .then(function(response){

                    ComprobanteVentaDetalleReporteFactory.drawGraphicReport(response.data.data, $scope);

                    if(response.data.data.length > 0){
                        
                        idCabecera = response.data.data[0].reporteVentaCabecera.id;

                        // filtro estático
                        var staticFilter = {};
                        staticFilter.search = filterFactory.and([{
                            path: 'reporteVentaCabecera.id',
                            equals: idCabecera,
                        }]).value();

                        $scope.options.staticFilter = staticFilter;

                        // Mostrar datatable solo si hay registros
                        $scope.filtro=true;
                    }
                });

            }else if(vm.moneda==undefined){
                //var msg = $filter('translate')('EMVIO_MESSAGE_SUCCESSFUL',{entidad:nombre});
                var msg = "Debe seleccionar una moneda";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
        }

        var monedaRender = function (data, type, row) {
            var campoMoneda = row.reporteVentaCabecera.moneda;

            if (data != undefined) {
                var moneda = "Gs. ";
                if (campoMoneda.codigo === "dolares") {
                    moneda = "Usd. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "pesos") {
                    moneda = "Pes. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "real") {
                    moneda = "Rel. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "euro") {
                    moneda = "Eur. ";
                    data = parseFloat(data).toFixed(2);
                } else {
                    data = parseFloat(data).toFixed(0);
                }
                return (
                        moneda +
                        data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        );
            } else {
                return "";
            }
        };

        initViz();

        function initViz() {
            
            $scope.inicio = false;

            VentasLangFactory.getTranslations().then(function(translations) {
              var defaultColumnOrder = ['cliente.nombre', 'totalItem', 'reporteVentaCabecera.id'];
  
                $scope.options = {
                  'resource': 'comprobanteventadetallereporte',
                  // 'title': $filter('translate')('TIPOS_COMPRA'),
                  'factory': ComprobanteVentaDetalleReporteFactory,
                  'defaultColumnOrder': defaultColumnOrder,
                  'view': 'ReporteVentaProductoList',
                  'columns': [
                        { 'data': 'cliente.nombre', 'title': $filter('translate')('PRODUCTO'), searchable:false, orderable:false},
                        { 'data': 'totalItem', 'title': $filter('translate')('TOTAL_ITEM'), render: monedaRender, searchable:false, orderable:false},
                        { 'data': 'reporteVentaCabecera.id', 'title': $filter('translate')('CANTIDAD'), searchable:false, orderable:false, visible:false}
                  ],
                  'hasOptions': true,
                  'defaultOrderColumn': 1,
                  'defaultOrderDir': "desc",
                  'hideRemoveMenu': true,
                  'hideViewMenu': true,
                  'hasOptions': false,
                  'graphicReport': "ReporteCliente",
                  'typeReport': "circular",
                  'extraMenuOptions':[
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = createFilters($scope.options.getFilters());
                            ReportTicketFactory.ticket('listadoReporteCliente', filters, $scope.options.tableAjaxParams,
                            $scope.options.currentColumnOrder).then(function (genTicket) {
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open($scope.pdfDownloadURL, '_blank');
                            });
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("comprobanteventadetallereporte", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                            .then(function (response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                        }
                    }
                  ]
               };

                var createFilters = function (filters) {
                    var filtersArr = [];
                    _.each(filters, function (search, data) {
                        if(data == "reporteVentaCabecera.id"){
                            search = String(idCabecera);
                        }
                        filtersArr.push({ path: data, like: search })
                    });
                    var filters = filterFactory.and(filtersArr).value();
                    return filters;
                }
          });
        }
    }]);
"use strict";

angular
    .module("qualita.venta")
    .controller("ReporteSucursalCtrl", ["$scope", "$filter", "notify", "VentasLangFactory", "TiposTipoFactory", "SucursalesFactory", "ComprobanteVentaDetalleReporteFactory", "filterFactory", "CsvFactory", "baseurl", "ReportTicketFactory", "$window", "UtilFactory", function(
        $scope,
        $filter,
        notify,
        VentasLangFactory,
        TiposTipoFactory,
        SucursalesFactory,
        ComprobanteVentaDetalleReporteFactory,
        filterFactory,
        CsvFactory,
        baseurl,
        ReportTicketFactory,
        $window,
        UtilFactory
    ) {
        var vm = this;
        vm.cambiarFiltro = cambiarFiltro;
        vm.changeMoneda = changeMoneda;
        vm.changeFecha = changeFecha;
        vm.changeSucursal = changeSucursal;

        var idCabecera;

        // recupera monedas
        vm.monedas = TiposTipoFactory.get("monedas");

        function changeSucursal() {
            $scope.filtro = false;
            $scope.inicio = false;
        }

        function changeMoneda() {
            $scope.filtro = false;
            $scope.inicio = false;
        }

        function changeFecha(params) {
            $scope.filtro = false;
            $scope.inicio = false;
        }

        function cambiarFiltro() {

            // Definicion de resource del datatable y pathparams        
            var fechaDesdeString=null;
            if(vm.fechaDesde != undefined){
                const fechaDesde = moment(vm.fechaDesde);
                fechaDesdeString = fechaDesde.format("YYYYMMDD");
            }

            var fechaHastaString=null;
            if(vm.fechaHasta != undefined){
                const fechaHasta = moment(vm.fechaHasta);
                fechaHastaString = fechaHasta.format("YYYYMMDD");
            }

            if(vm.moneda!=undefined){

                $scope.inicio = true;
                
                ComprobanteVentaDetalleReporteFactory.getReporteSucursal(fechaDesdeString, fechaHastaString, vm.moneda.id)
                .then(function(response){

                    ComprobanteVentaDetalleReporteFactory.drawGraphicReport(response.data.data, $scope);

                    if(response.data.data.length > 0){

                        idCabecera = response.data.data[0].reporteVentaCabecera.id;

                        // filtro estático
                        var staticFilter = {};
                        staticFilter.search = filterFactory.and([{
                            path: 'reporteVentaCabecera.id',
                            equals: response.data.data[0].reporteVentaCabecera.id,
                        }]).value();

                        $scope.options.staticFilter = staticFilter;

                        // Mostrar datatable solo si hay registros
                        $scope.filtro=true;
                    }
                });

            }else{
                //var msg = $filter('translate')('EMVIO_MESSAGE_SUCCESSFUL',{entidad:nombre});
                var msg = "Debe seleccionar una moneda";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }     
        }

        var monedaRender = function (data, type, row) {
            var campoMoneda = row.reporteVentaCabecera.moneda;

            if (data != undefined) {
                var moneda = "Gs. ";
                if (campoMoneda.codigo === "dolares") {
                    moneda = "Usd. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "pesos") {
                    moneda = "Pes. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "real") {
                    moneda = "Rel. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "euro") {
                    moneda = "Eur. ";
                    data = parseFloat(data).toFixed(2);
                } else {
                    data = parseFloat(data).toFixed(0);
                }
                return (
                        moneda +
                        data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        );
            } else {
                return "";
            }
        };

        initViz();

        function initViz() {
            
            $scope.inicio = false;
            vm.sucursales = SucursalesFactory.all();

            VentasLangFactory.getTranslations().then(function(translations) {
              var defaultColumnOrder = ['totalItem', 'sucursal.nombre', 'reporteVentaCabecera.id'];
  
                $scope.options = {
                  'resource': 'comprobanteventadetallereporte',
                  // 'title': $filter('translate')('TIPOS_COMPRA'),
                  'view': 'ReporteVentaProductoList',
                  'factory': ComprobanteVentaDetalleReporteFactory,
                  'defaultColumnOrder': defaultColumnOrder,
                  'failedDeleteError': $filter('translate')('FAIL_DELETE_TIPO_COMPRA'),
                  'defaultOrderColumn':1,
                  'defaultOrderDir': 'desc',
                  'columns': [
                      { 'data': 'sucursal.nombre', 'title': $filter('translate')('PRODUCTO'), searchable:false, orderable:false},
                      { 'data': 'totalItem', 'title': $filter('translate')('TOTAL_ITEM'), render: monedaRender, searchable:false, orderable:false},
                      { 'data': 'reporteVentaCabecera.id', 'title': $filter('translate')('CANTIDAD'), searchable:false, orderable:false, visible:false}
                  ],
                  'hasOptions': true,
                  'defaultOrderDir': "desc",
                  'hideRemoveMenu': true,
                  'hideViewMenu': true,
                  'hasOptions': false,
                  'graphicReport': "ReporteSucursal",
                  'typeReport': "barras",
                  'extraMenuOptions':[
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = createFilters($scope.options.getFilters());
                            ReportTicketFactory.ticket('listadoReporteSucursal', filters, $scope.options.tableAjaxParams,
                            $scope.options.currentColumnOrder).then(function (genTicket) {
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open($scope.pdfDownloadURL, '_blank');
                            });
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("comprobanteventadetallereporte", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                            .then(function (response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                        }
                    }
                  ]
               };

                var createFilters = function (filters) {
                    var filtersArr = [];
                    _.each(filters, function (search, data) {
                        if(data == "reporteVentaCabecera.id"){
                            search = String(idCabecera);
                        }
                        filtersArr.push({ path: data, like: search })
                    });
                    var filters = filterFactory.and(filtersArr).value();
                    return filters;
                }
          });
        }
    }]);
/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.venta')
  .factory('ComprobanteVentaDetalleReporteFactory', ComprobanteVentaDetalleReporteFactory);

ComprobanteVentaDetalleReporteFactory.$inject = ['$resource', 'baseurl', '$http', 'ParametrosFactory', '$q', 'notify'];

function ComprobanteVentaDetalleReporteFactory($resource, baseurl, $http, ParametrosFactory, $q, notify) {
  var service = {
    all: all,
    get: get,
    getReporteFamilia: getReporteFamilia,
    drawGraphicReport: drawGraphicReport,
    getReporteSucursal: getReporteSucursal,
    getReporteProducto: getReporteProducto,
    getReporteCliente: getReporteCliente
  };

  var ComprobanteDetalle = $resource( baseurl.getBaseUrl() + "/comprobanteventadetallereporte/");

  return service;

  function all(params, view) {
    params = params ? params : [];
    params.view = view != undefined ? view : "base";
    return ComprobanteDetalle.query(params);
  }

  function getReporteFamilia(fechaDesde, fechaHasta, idMoneda, idFamilia) {
    return $http({
        url: baseurl.getBaseUrl() + '/comprobanteventa/reporte/familia/datatables/',
        method: "GET",
        params: { fechaDesde: fechaDesde,
                  fechaHasta: fechaHasta,
                  idMoneda: idMoneda,
                  idFamilia: idFamilia,
                }
    });
  }

  function getReporteProducto(fechaDesde, fechaHasta, idMoneda, tipoReporte) {
    return $http({
        url: baseurl.getBaseUrl() + '/comprobanteventa/reporte/producto/datatables/',
        method: "GET",
        params: { fechaDesde: fechaDesde,
                  fechaHasta: fechaHasta,
                  idMoneda: idMoneda,
                  tipoReporte: tipoReporte,
                }
    });
  }

  function getReporteCliente(fechaDesde, fechaHasta, idMoneda) {
    return $http({
        url: baseurl.getBaseUrl() + '/comprobanteventa/reporte/cliente/datatables/',
        method: "GET",
        params: { fechaDesde: fechaDesde,
                  fechaHasta: fechaHasta,
                  idMoneda: idMoneda
                }
    });
  }

  function getReporteSucursal(fechaDesde, fechaHasta, idMoneda) {
    return $http({
        url: baseurl.getBaseUrl() + '/comprobanteventa/reporte/sucursal/datatables/',
        method: "GET",
        params: { fechaDesde: fechaDesde,
                  fechaHasta: fechaHasta,
                  idMoneda: idMoneda
                }
    });
  }

  function get(id, view) {
    return ComprobanteDetalle.get({id: id, view: view ? view : "base"});
  }

  function drawGraphicReport(datos, $scope){

    /*******************AM CHARTS******************* */
    if(datos.length==0){
      notify({ message: "No existen resultados", classes: 'alert-warning', position: 'right' });
    }

    if($scope.options.graphicReport){

      am4core.ready(function() {

        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end
        
        // Create chart instance
        var chart;
        switch ($scope.options.typeReport) {
          
          case "circular":
            chart = am4core.create("chartdiv", am4charts.PieChart);
            break;

          case "barras":
            chart = am4core.create("chartdiv", am4charts.XYChart);
            break;
        }

        /********************* Seccion de datos *********************/

        /* En este switch se determina que campos se utilizaran en la grafica */
        var jsonData = [];
        var campoLabel = "item.producto.nombre";
        switch ($scope.options.graphicReport) {
          
          case "ReporteVentaImporte":
            _.forEach(datos, function(item){
              // Se agregan los valores de cantidad
              jsonData.push({label:item.producto.nombre, valor: item.totalItem});
            });
            break;

          case "ReporteVentaCantidad":
            _.forEach(datos, function(item){
              // Se agregan los valores de montos
              jsonData.push({label:item.producto.nombre, valor: item.cantidad});
            });  
            break;

          case "ReporteFamilia":
              _.forEach(datos, function(item){
                // Se agregan los valores de montos
                jsonData.push({label:item.producto.lineaProducto.nombre, valor: item.totalItem});
              });  
              break;
          
          case "ReporteSucursal":
            _.forEach(datos, function(item){
              // Se agregan los valores de montos
              jsonData.push({label:item.sucursal.nombre, valor: item.totalItem});
            });  
            break;

          case "ReporteCliente":
            _.forEach(datos, function(item){
              // Se agregan los valores de montos
              jsonData.push({label:item.cliente.nombre, valor: item.totalItem});
            });  
            break;

        }

        // Se debe cargar aqui el JSON
        chart.data = jsonData;


        switch ($scope.options.typeReport) {
          case "circular":
            var pieSeries = chart.series.push(new am4charts.PieSeries());
            
            // Campo valor y leyenda respectivamente
            pieSeries.dataFields.value = "valor";
            pieSeries.dataFields.category = "label";
            
            pieSeries.slices.template.stroke = am4core.color("#fff");
            pieSeries.slices.template.strokeWidth = 2;
            pieSeries.slices.template.strokeOpacity = 1;

            // This creates initial animation
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;
            break;
          
          case "barras":

            // Create axes
            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "label";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;
        
            categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
            if (target.dataItem && target.dataItem.index & 2 == 2) {
                return dy + 25;
            }
            return dy;
            });

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

            // Create series
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = "valor";
            series.dataFields.categoryX = "label";
            series.name = "Visits";
            series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
            series.columns.template.fillOpacity = .8;
        
            var columnTemplate = series.columns.template;
            columnTemplate.strokeWidth = 2;
            columnTemplate.strokeOpacity = 1;
            break;
        }
      });
    }
    /*************************************** */
  }

}

'use strict';


angular.module('qualita.venta')
  .controller('ComprobantePendienteClienteIndexCtrl',
    ["$scope", "filterFactory", "ReportTicketFactory", "$window", "ClienteFactory", "VentasLangFactory", "CategoriaClienteFactory", "TipoActividadRamoClienteFactory", "UtilFactory", "$filter", "CsvFactory", "baseurl", "SucursalesFactory", "FormaPagoFactory", "TiposTipoFactory", "ComprobanteVentaFactory", "notify", "VentaFactory", "VendedorFactory", "$rootScope", "AuthorizationService", function ($scope, filterFactory, ReportTicketFactory, $window,
      ClienteFactory, VentasLangFactory, CategoriaClienteFactory, TipoActividadRamoClienteFactory,
      UtilFactory, $filter, CsvFactory, baseurl, SucursalesFactory, FormaPagoFactory,
      TiposTipoFactory, ComprobanteVentaFactory, notify, VentaFactory, VendedorFactory, $rootScope, AuthorizationService) {

      //Inicio

      var vm = this;
      vm.changeCliente = changeCliente;
      vm.delayRequestCliente = delayRequestCliente;
      vm.searchClienteFilter = searchClienteFilter;
      vm.checkfacturas = true;
      vm.checknotascredito = true;
      vm.checknotasdebito = true;
      vm.checkcheques = true;

      $scope.sucursal = undefined;
      vm.clientes = [{ 'id': '', 'nombre': 'Todos' }];
      vm.cliente = vm.clientes[vm.clientes.length - 1]
      vm.sucursalesCliente = [{ 'id': '', 'descripcion': 'Todos' }];
      vm.sucursalCliente = vm.sucursalesCliente[vm.sucursalesCliente.length - 1]

      SucursalesFactory.all().$promise.then(function (response) {
        $scope.listaSucursales = response;
        $scope.listaSucursales.push({ 'id': '', 'nombre': 'Todos' });
        $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];
      });

      CategoriaClienteFactory.all().$promise.then(function (response) {
        vm.categoriasClientes = response;
        vm.categoriasClientes.push({ 'id': '', 'descripcion': 'Todos' });
        vm.categoriaCliente = vm.categoriasClientes[vm.categoriasClientes.length - 1]
      });
      var params = {};
      params.search = filterFactory.and([
        {
          path: 'activo',
          like: 'true'
        }]
      ).value();
      TipoActividadRamoClienteFactory.all(params, "BaseForm").$promise.then(function (response) {
        vm.tipoActividadesRamosClientes = response;
        vm.tipoActividadesRamosClientes.push({ 'id': '', 'nombre': 'Todos' });
        vm.tipoActividadRamoCliente = vm.tipoActividadesRamosClientes[vm.tipoActividadesRamosClientes.length - 1]
      });
      var filtroServicios = {};
      filtroServicios.search = filterFactory.and([{
        path: 'clase.codigo',
        equals: 'forma_pago_referencia'
      }]).value();
      filtroServicios.view = "ClienteForm";
      FormaPagoFactory.all(filtroServicios, "ClienteForm").$promise.then(function (response) {
        vm.formasPagos = response;
        vm.formasPagos.push({ 'id': '', 'descripcion': 'Todos' });
        vm.formaPago = vm.formasPagos[vm.formasPagos.length - 1]
      });

      $rootScope.$watch('AuthParams.accesoSistema', function (newValue, oldValue) {
        if (newValue != undefined) {
          var usuario = $rootScope.AuthParams.accesoSistema.usuario;
          var vendedoresActuales = AuthorizationService.hasPermission("listar_vendedores_actuales");

          var filtroVendedor = {};

          if (vendedoresActuales) {
            vm.habilitar = false;
            filtroVendedor.search = filterFactory.and([
              {
                path: 'activo',
                like: 'true'
              },
              {
                path: 'vendedor',
                like: 'true'
              }

            ]).value();
            filtroVendedor.view = "VendedorList";
            vm.vendedores = [];
            VendedorFactory.all(filtroVendedor).$promise.then(function (response) {
              _.forEach(response, function (detalle) {
                for (var i = 0; i < detalle.locaciones.length; i++) {
                  if (detalle.locaciones[i].locacion.id == $rootScope.AuthParams.locacionSeleccionada.id) {
                    vm.vendedores.push(detalle);
                    break;
                  }
                }
              })
            });
          } else {
            vm.habilitar = true;

            filtroVendedor.search = filterFactory.and([
              {
                path: 'activo',
                like: 'true'
              },
              {
                path: 'vendedor',
                like: 'true'
              },
              {
                path: 'usuarioAsignado.id',
                equals: usuario.id
              }

            ]).value();
            filtroVendedor.view = "VendedorList";
            vm.vendedores = [];
            VendedorFactory.all(filtroVendedor).$promise.then(function (response) {
              _.forEach(response, function (detalle) {
                for (var i = 0; i < detalle.locaciones.length; i++) {
                  if (detalle.locaciones[i].locacion.id == $rootScope.AuthParams.locacionSeleccionada.id) {
                    vm.vendedores.push(detalle);
                    break;
                  }
                }
                vm.vendedor = detalle;
              })
            });
          }
        }
      });

      // $scope.monedas = TiposTipoFactory.get("monedas");
      TiposTipoFactory.get("monedas").$promise.then(function (monedas) {
        $scope.monedas = monedas;
        _.forEach(monedas, function (moneda) {
          if (moneda.codigo == "guaranies") {
            $scope.moneda = moneda;
          }
        })
      })

      $scope.clean = function () {
        $scope.moneda = undefined;
        $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];
        vm.clientes = [];
        vm.clientes = [{ 'id': '', 'nombre': 'Todos' }];
        vm.cliente = vm.clientes[vm.clientes.length - 1]
        vm.sucursalesCliente = [];
        vm.sucursalesCliente.push({ 'id': '', 'descripcion': 'Todos' });
        vm.sucursalCliente = vm.sucursalesCliente[vm.sucursalesCliente.length - 1];
        vm.categoriaCliente = vm.categoriasClientes[vm.categoriasClientes.length - 1];
        vm.tipoActividadRamoCliente = vm.tipoActividadesRamosClientes[vm.tipoActividadesRamosClientes.length - 1];
        vm.formaPago = vm.formasPagos[vm.formasPagos.length - 1];
        $scope.filtro = false;
      };

      $scope.filtrar = function () {
        $scope.saldo = 0;
        var idCliente = null, idMoneda = null, idSucursal = null, idVendedor = null;

        /* definir como filtrar por cliente */
        var staticFilter = [];

        if ($scope.moneda) {
          staticFilter.push(
            {
              path: 'moneda.id',
              equals: $scope.moneda.id
            }
          )
          idMoneda = $scope.moneda.id;
        } else {
          return notify({ message: "Debe ingresar la moneda", classes: "alert-danger", position: "right" });
        }

        //Filtro de clientes
        if (vm.cliente && vm.cliente.id != '') {
          staticFilter.push(
            {
              path: 'cliente.id',
              equals: vm.cliente.id
            }
          )
          idCliente = vm.cliente.id;
        }

        //Filtro de sucursal del cliente
        if (vm.sucursalCliente && vm.sucursalCliente.id != '') {
          staticFilter.push(
            {
              path: 'sucursalCliente.id',
              equals: vm.sucursalCliente.id
            }
          )
          var idSucursalCliente = vm.sucursalCliente.id;
        }

        //Filtro de categoría del cliente
        if (vm.categoriaCliente && vm.categoriaCliente.id != '') {
          staticFilter.push(
            {
              path: 'cliente.categoriaCliente.id',
              equals: vm.categoriaCliente.id
            }
          )
          var idCategoriaCliente = vm.categoriaCliente.id;
        }

        //Filtro de tipo de actividad del ramo del cliente
        if (vm.tipoActividadRamoCliente && vm.tipoActividadRamoCliente.id != '') {
          staticFilter.push(
            {
              path: 'cliente.tipoActividadRamoCliente.id',
              equals: vm.tipoActividadRamoCliente.id
            }
          )
          var idActividadRamo = vm.tipoActividadRamoCliente.id;
        }

        //Filtro de forma de pago del cliente
        if (vm.formaPago && vm.formaPago.id != '') {
          staticFilter.push(
            {
              path: 'cliente.formaPago.id',
              equals: vm.formaPago.id
            }
          )
          var idFormaPago = vm.formaPago.id;
        }

        //Filtro de vendedor
        if (vm.vendedor && vm.vendedor.id != '') {
          staticFilter.push(
            {
              path: 'vendedor.id',
              equals: vm.vendedor.id
            }
          )
          idVendedor = vm.vendedor.id;
        }

        if ($scope.sucursal && $scope.sucursal.id != '') {
          staticFilter.push(
            {
              path: 'locacion.sucursal.id',
              equals: $scope.sucursal.id
            }
          )
          idSucursal = $scope.sucursal.id;
        }

        staticFilter.push(
          {
            path: 'checkfacturas',
            equals: vm.checkfacturas.toString()
          }
        )
        var checkFacturas = vm.checkfacturas;

        staticFilter.push(
          {
            path: 'checknotascredito',
            equals: vm.checknotascredito.toString()
          }
        )
        var checkNotasCredito = vm.checknotascredito;

        staticFilter.push(
          {
            path: 'checknotasdebito',
            equals: vm.checknotasdebito.toString()
          }
        )
        var checkNotasDebito = vm.checknotasdebito;

        staticFilter.push(
          {
            path: 'checkcheques',
            equals: vm.checkcheques.toString()
          }
        )
        var checkCheques = vm.checkcheques;

        // Invocacion del service para calculo del campo "Saldo"
        ComprobanteVentaFactory.getSaldoByCliente(idCliente, idMoneda, idSucursal,
          idSucursalCliente, idCategoriaCliente, idActividadRamo, idFormaPago, idVendedor,
          checkFacturas, checkNotasCredito, checkNotasDebito, checkCheques).then(function (data) {
            $scope.saldo = data.data;
          });

        // Se define el filtro final
        var filtros = {
          search: filterFactory.and(staticFilter).value()
        };

        console.log("AQUI FILTROS: ", filtros);

        $scope.options.staticFilter = filtros;
        $scope.filtro = true;
      };

      /*************** NUEVO ******************** */

      var monedaRender = function (data, type, row) {

        var campoMoneda = null;

        if (row.moneda != undefined) {
          campoMoneda = row.moneda;
        }

        if (data != undefined) {
          var moneda = "Gs. ";
          if (campoMoneda === "Dólares") {
            moneda = "Usd. ";
            data = parseFloat(data).toFixed(2);
          } else if (campoMoneda === "Pesos") {
            moneda = "Pes. ";
            data = parseFloat(data).toFixed(2);
          } else if (campoMoneda === "Real") {
            moneda = "Rel. ";
            data = parseFloat(data).toFixed(2);
          } else if (campoMoneda === "Euro") {
            moneda = "Eur. ";
            data = parseFloat(data).toFixed(2);
          } else {
            data = parseFloat(data).toFixed(0);
          }
          return (
            moneda +
            data
              .toString()
              .replace(".", ",")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          );
        } else {
          return "";
        }
      };

      var renderCuotas = function (data, type, row) {
        if (data == "0" || !row.cantidadCuotas)
          return "";
        else
          return row.cuota + "/" + row.cantidadCuotas;
      };

      var renderDiasAtraso = function (data, type, row) {
        return data > 0 ? data : "0";
      };

      function stringToDate(_date, _format, _delimiter) {
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf("mm");
        var dayIndex = formatItems.indexOf("dd");
        var yearIndex = formatItems.indexOf("yyyy");
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
        return formatedDate;
      }

      var defaultColumnOrder = ['codigoCliente', 'cliente',
        'sucursalCliente', 'tipoComprobante',
        'numero', 'cuota', 'fecha', 'fechaVencimiento', 'diasAtraso', 'saldoPendiente', 'saldoAcumulado'];

      VentasLangFactory.getTranslations().then(function (translations) {
        $scope.options = {
          'resource': 'comprobanteventa/comprobantespendientesclientes',
          'view': 'ConsultaComprobanteVentaList',
          'title': 'Comprobante',
          'factory': ComprobanteVentaFactory,
          'defaultColumnOrder': defaultColumnOrder,
          'columns': [
            { 'data': 'codigoCliente', 'title': $filter('translate')('CODIGO_CLIENTE'), searchable: false, 'renderWith': 'emptyRender' },
            { 'data': 'cliente', 'title': $filter('translate')('CLIENTE'), searchable: false, 'renderWith': 'emptyRender' },
            { 'data': 'sucursalCliente', 'title': $filter('translate')('SUCURSAL_CLIENTE'), searchable: false, 'renderWith': 'emptyRender' },
            { 'data': 'vendedor', 'title': $filter('translate')('VENDEDOR'), searchable: false, 'renderWith': 'emptyRender' },
            { 'data': 'tipoComprobante', 'title': $filter('translate')('TIPO_COMPROBANTE'), searchable: false, 'renderWith': 'emptyRender' },
            { 'data': 'numero', 'title': $filter('translate')('NUMBER'), 'class': 'dt-right', searchable: false, 'renderWith': 'emptyRender' },
            { 'data': 'cuota', 'title': $filter('translate')('QUOTA_NUMBER'), searchable: false, 'renderWith': renderCuotas, 'class': 'dt-center', "sortable": false },
            { 'data': 'fecha', 'title': $filter('translate')('FECHA'), searchable: false, 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY" },
            { 'data': 'fechaVencimiento', 'title': $filter('translate')('FECHA_VENCIMIENTO'), searchable: false, 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY" },
            { 'data': 'diasAtraso', 'title': $filter('translate')('DIAS_ATRASADOS'), searchable: false, 'renderWith': renderDiasAtraso, "sortable": false },
            { 'data': 'saldoPendiente', 'title': $filter('translate')('SALDO_PENDIENTE'), searchable: false, 'renderWith': monedaRender },
            { 'data': 'saldoAcumulado', 'title': $filter('translate')('SALDO_ACUMULADO'), searchable: false, 'renderWith': monedaRender, "sortable": false }

          ],
          'hasOptions': false,
          'hideAddMenu': true,
          'defaultOrderColumn': 6,
          'defaultOrderDir': "asc",
          'extraMenuOptions': [
            {
              'title': 'Reporte por Vendedor',
              'icon': 'glyphicon glyphicon-duplicate',
              'showCondition': function () { return true; },
              'action': function () {
                var filtersArr = [];
                filtersArr.push("moneda=" + $scope.moneda.id);
                //Filtro de clientes
                if (vm.cliente && vm.cliente.id != '') {
                  filtersArr.push("cliente=" + vm.cliente.id);
                }

                //Filtro de sucursal del cliente
                if (vm.sucursalCliente && vm.sucursalCliente.id != '') {
                  filtersArr.push("sucursalCliente=" + vm.sucursalCliente.id);
                }

                //Filtro de categoría del cliente
                if (vm.categoriaCliente && vm.categoriaCliente.id != '') {
                  filtersArr.push("categoriaCliente=" + vm.categoriaCliente.id);
                }

                //Filtro de tipo de actividad del ramo del cliente
                if (vm.tipoActividadRamoCliente && vm.tipoActividadRamoCliente.id != '') {
                  filtersArr.push("actividadRamo=" + vm.tipoActividadRamoCliente.id);
                }

                //Filtro de forma de pago del cliente
                if (vm.formaPago && vm.formaPago.id != '') {
                  filtersArr.push("condicionVenta=" + vm.formaPago.id);
                }

                if ($scope.sucursal && $scope.sucursal.id != '') {
                  filtersArr.push("idSucursal=" + $scope.sucursal.id);
                }
                if (vm.vendedor && vm.vendedor.id != '') {
                  filtersArr.push("vendedor=" + vm.vendedor.id);
                }
                filtersArr.push("checkFacturas=" + vm.checkfacturas);
                filtersArr.push("checkNotasCredito=" + vm.checknotascredito);
                filtersArr.push("checkNotasDebito=" + vm.checknotasdebito);
                filtersArr.push("checkCheques=" + vm.checkcheques);
                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                  "comprobantespendientesclientesvendedor",
                  "pdf",
                  filtersArr
                );
                $window.open(vm.pdfDownloadURL, "_blank");

              }
            },
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function () { return true; },
              'action': function () {
                var filtersArr = [];
                filtersArr.push("moneda=" + $scope.moneda.id);
                //Filtro de clientes
                if (vm.cliente && vm.cliente.id != '') {
                  filtersArr.push("cliente=" + vm.cliente.id);
                }

                //Filtro de sucursal del cliente
                if (vm.sucursalCliente && vm.sucursalCliente.id != '') {
                  filtersArr.push("sucursalCliente=" + vm.sucursalCliente.id);
                }

                //Filtro de categoría del cliente
                if (vm.categoriaCliente && vm.categoriaCliente.id != '') {
                  filtersArr.push("categoriaCliente=" + vm.categoriaCliente.id);
                }

                //Filtro de tipo de actividad del ramo del cliente
                if (vm.tipoActividadRamoCliente && vm.tipoActividadRamoCliente.id != '') {
                  filtersArr.push("actividadRamo=" + vm.tipoActividadRamoCliente.id);
                }

                //Filtro de forma de pago del cliente
                if (vm.formaPago && vm.formaPago.id != '') {
                  filtersArr.push("condicionVenta=" + vm.formaPago.id);
                }

                if ($scope.sucursal && $scope.sucursal.id != '') {
                  filtersArr.push("idSucursal=" + $scope.sucursal.id);
                }
                if (vm.vendedor && vm.vendedor.id != '') {
                  filtersArr.push("vendedor=" + vm.vendedor.id);
                }
                filtersArr.push("checkFacturas=" + vm.checkfacturas);
                filtersArr.push("checkNotasCredito=" + vm.checknotascredito);
                filtersArr.push("checkNotasDebito=" + vm.checknotasdebito);
                filtersArr.push("checkCheques=" + vm.checkcheques);
                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                  "comprobantespendientesclientes",
                  "pdf",
                  filtersArr
                );
                $window.open(vm.pdfDownloadURL, "_blank");

              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {

                var filtersObj = {};

                if ($scope.moneda) {
                  filtersObj.moneda = $scope.moneda.id;
                }

                //Filtro de clientes
                if (vm.cliente && vm.cliente.id != '') {
                  filtersObj.cliente = vm.cliente.id;
                }

                //Filtro de sucursal del cliente
                if (vm.sucursalCliente && vm.sucursalCliente.id != '') {
                  filtersObj.sucursalCliente = vm.sucursalCliente.id
                }

                //Filtro de categoría del cliente
                if (vm.categoriaCliente && vm.categoriaCliente.id != '') {
                  filtersObj.categoriaCliente = vm.categoriaCliente.id
                }

                //Filtro de tipo de actividad del ramo del cliente
                if (vm.tipoActividadRamoCliente && vm.tipoActividadRamoCliente.id != '') {
                  filtersObj.actividadRamo = vm.tipoActividadRamoCliente.id
                }

                //Filtro de forma de pago del cliente
                if (vm.formaPago && vm.formaPago.id != '') {
                  filtersObj.condicionVenta = vm.formaPago.id
                }

                if ($scope.sucursal && $scope.sucursal.id != '') {
                  filtersObj.idSucursal = $scope.sucursal.id;
                }

                if (vm.vendedor && vm.vendedor.id != '') {
                  filtersObj.vendedor = vm.vendedor.id;
                }

                filtersObj.checkFacturas = vm.checkfacturas.toString();
                filtersObj.checkNotasCredito = vm.checknotascredito.toString();
                filtersObj.checkNotasDebito = vm.checknotasdebito.toString();
                filtersObj.checkCheques = vm.checkcheques.toString();
                VentaFactory.getComprobantesPendientesCSV(filtersObj).then(function (response) {
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
            }
          ]
        };
      });

      function changeCliente() {
        vm.sucursalesCliente = vm.cliente.sucursales;
        vm.sucursalesCliente.push({ 'id': '', 'descripcion': 'Todos' });
        vm.sucursalCliente = vm.sucursalesCliente[vm.sucursalesCliente.length - 1]
      }


      function delayRequestCliente(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
          var filterNombre = [];
          var filterNombreFantasia = [];
          var filterCodigo = [];
          var filterNumero = [];
          var filterDireccion = [];
          var filterCiudad = [];

          filterNombre.push({
            path: "nombre",
            like: searchValue
          })
          filterNombreFantasia.push({
            path: "nombreFantasia",
            like: searchValue
          })
          filterCodigo.push({
            path: "codigo",
            like: searchValue
          })
          filterNumero.push({
            path: "numero",
            like: searchValue
          })
          filterDireccion.push({
            path: "direccion",
            like: searchValue
          })
          filterCiudad.push({
            path: "ciudad.descripcion",
            like: searchValue
          })

          userInputFilter = {
            search: filterFactory.or([
              filterFactory.and(filterNombre).value(),
              filterFactory.and(filterNombreFantasia).value(),
              filterFactory.and(filterCodigo).value(),
              filterFactory.and(filterNumero).value(),
              filterFactory.and(filterDireccion).value(),
              filterFactory.and(filterCiudad).value()
            ]).value()
          }

          recuperarCliente(userInputFilter)
        } else {
          vm.clientes = undefined
        }
      }

      function recuperarCliente(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
          vm.finalFilters, userInputFilter)

        ClienteFactory.allForSelect(finalFilter).$promise.then(function (clientes) {
          vm.clientes = clientes;
        })
      }

      function searchClienteFilter(criteria) {
        return function (item) {
          if (!criteria) {
            return true;
          }
          return (
            item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
            (item.nombreFantasia && item.nombreFantasia.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
            (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
          );
        };
      }

      $scope.searchSucursalClienteFilter = function (criteria) {
        return function (item) {
          if (!criteria) {
            return true;
          }
          return (
            item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
            (item.codigoSucursal.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
          );
        };
      }

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
    }]);

'use strict';

angular.module('qualita.venta')
    .controller('PresupuestoVentaFormController', PresupuestoVentaFormController);

PresupuestoVentaFormController.$inject = ['$rootScope', '$scope', 'PresupuestoVentaFactory', 'ProductosFactory',
    'formFactory', 'TiposFactory', '$location', '$state', 'presupuestoVentaPrepService','baseurl',
    '$timeout', 'notify','TipoPresupuestoFactory','ModelTrimmer','ProyectoFactory', 'filterFactory', 'VentasLangFactory',
    'WatchLocacionChangeFactory', 'UtilFactory', 'ParametrosFactory', 'CotizacionMonedaFactory','$modal',
    'FormaPagoFactory','VendedorFactory','ClienteFactory', 'CambioPrecioFactory', '$filter', 'AuthorizationService', 'ListaPrecioVentaFactory', 'UsuariosFactory','ListaPrecioVentaDetalleFactory'
];

function PresupuestoVentaFormController($rootScope, $scope, PresupuestoVentaFactory, ProductosFactory,
    formFactory, TiposFactory, $location, $state, presupuestoVentaPrepService,baseurl,
    $timeout, notify,TipoPresupuestoFactory, ModelTrimmer, ProyectoFactory,filterFactory, VentasLangFactory,
    WatchLocacionChangeFactory, UtilFactory, ParametrosFactory, CotizacionMonedaFactory,$modal,FormaPagoFactory,VendedorFactory, ClienteFactory,
    CambioPrecioFactory, $filter, AuthorizationService, ListaPrecioVentaFactory, UsuariosFactory,ListaPrecioVentaDetalleFactory) {
    var vm = this;
    vm.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    vm.errores =[];
    vm.precioMinimoMaximos = [];

    vm.uiBlockuiConfig = {
      'bloquear': false,
      'message': 'Cargando detalles...'
   };

    // Recupera parametro para mostrar o no campo "precio"
    vm.cantidadDecimales = undefined;
    vm.verPrecio = 'Si'
    /*ParametrosFactory.getByCodigo('ver_precio_presupuesto').then(function(parametro){
        vm.verPrecio = parametro.valor
    });*/

    ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
        if (parametro.valorTipo.codigo === 'no') {
            vm.utilizarPrecioBase = false;
        } else {
            vm.utilizarPrecioBase = true;
        }
    });
    ParametrosFactory.getByCodigo('datos_cliente_presupuesto').then(function (parametro) {
      if (parametro.valorTipo.codigo === 'no') {
        vm.datosClienteShow = false;
      } else {
        vm.datosClienteShow = true;
      }
    })
    ParametrosFactory.getByCodigo('ver_control_costo_presupuesto').then(function (parametro){
      vm.verCostoControl = parametro.valor;
    });

    activate();
    vm.agregarDetalle = agregarDetalle;
    vm.removeItemFromArray = removeItemFromArray;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.changeProducto = changeProducto;
    vm.changeMoneda = changeCotizacion;
    vm.changeCondicion = changeCondicion;
    vm.popupCliente = popupCliente;
    $rootScope.isProcessing = false;
    vm.nroOrden = false;
    vm.nroMov = false;
    vm.totalGeneral = 0;
    vm.calcularTotalDetalle = calcularTotalDetalle;
    vm.factoryProductos = ProductosFactory;
    vm.totalItemChanged = totalItemChanged;
    vm.changeUnidad = changeUnidad;
    vm.delayRequestCliente = delayRequestCliente;
    vm.delayRequestDocumentoMobile = delayRequestDocumentoMobile;
    vm.delayRequestClienteMobile = delayRequestClienteMobile;
    vm.searchClienteFilter = searchClienteFilter;
    vm.changeCliente = changeCliente;
    vm.changeSucCliente = changeSucCliente;
    vm.changeGrupoProducto = changeGrupoProducto;
    vm.grupoRepetido = false;
    vm.plusClicked = plusClicked;
    vm.compararDescendente = compararDescendente;
    vm.delayRequestProducto = delayRequestProducto;
    vm.getListasPreciosVentasByMoneda = getListasPreciosVentasByMoneda;
    vm.actualizarPreciosDeVentas = actualizarPreciosDeVentas;
    vm.changeListaPrecioVenta = changeListaPrecioVenta;
    vm.changePrecioCambioPactado = changePrecioCambioPactado;
    vm.changeUnidadAux = changeUnidadAux;
    vm.changeCondicionVenta = changeCondicionVenta;
    vm.getTiposPresupuestos= getTiposPresupuestos;

    function changeCondicionVenta() {
      if (vm.presupuestoVenta.formaPago) {
          vm.presupuestoVenta.cantidadCuotas = vm.presupuestoVenta.formaPago.cantidadCuotas;
      }
  }

    function delayRequestProducto(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
            var filterNombre = vm.opcionesProductosDt.staticFilter.search.filters.slice()
            var filterCodigoBarras = vm.opcionesProductosDt.staticFilter.search.filters.slice()
            var filterCodigoInterno = vm.opcionesProductosDt.staticFilter.search.filters.slice()

            filterNombre.push({
                path: "nombre",
                like: searchValue
            })

            filterCodigoBarras.push({
                path: "codigoBarrasPrincipal.codigoBarras",
                like: searchValue
            })

            filterCodigoInterno.push({
                path: "codigo",
                like: searchValue
            })

            filterNombre.push({
              path: "locaciones.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            filterCodigoBarras.push({
              path: "locaciones.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            filterCodigoInterno.push({
              path: "locaciones.id",
              equals: $rootScope.AuthParams.locacionSeleccionada.id
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }

            recuperarProducto(userInputFilter)
        } else {
            vm.productos = undefined
        }
    }

    function recuperarProducto(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)

        vm.productos = ProductosFactory.allForSelect(finalFilter)
    }

    function changeGrupoProducto(detalle) {
        vm.grupoRepetido = false;
        var cantidadGrupo = 0;
        _.forEach(vm.presupuestoVenta.presupuestoVentaDetalle, function (elem) {
            if (elem.grupoProducto != undefined && detalle.grupoProducto.id == elem.grupoProducto.id) {
                cantidadGrupo++;
            }
        });
        if (cantidadGrupo > 1) {
            vm.grupoRepetido = true;
            var msg = vm.translations.NUMERO_GRUPO_REPETIDO; //vm.translations.DETAILS_ERROR_OP_MANUAL_STOCK;
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
        }
    }

    function calcularTotalDetalle(detalle,index) {

      controlPrecioUltimoCosto(detalle,index);

      if(detalle.porcentajeDescuento && detalle.porcentajeDescuento > 0){
        controlLimiteDescuento(detalle,index);
      }

      if (detalle.cantidad != undefined && detalle.precio != undefined) {
          detalle.total = detalle.cantidad * detalle.precio;
          if (vm.presupuestoVenta.moneda != undefined) {
              detalle.total = UtilFactory.roundForMoneda(detalle.total, vm.presupuestoVenta.moneda);
          }

          calcularImpuestosAndTotalGeneral(detalle);
      }

      if (detalle.porcentajeDescuento == undefined) {
        detalle.porcentajeDescuento = 0
      }
      var totalNeto = 0;
      if (detalle.porcentajeDescuento > 0) {
          totalNeto = detalle.precio - (detalle.precio * (detalle.porcentajeDescuento / 100));
      } else {
          totalNeto = detalle.precio;
      }
      detalle.total = detalle.cantidad * totalNeto;

      if (vm.presupuestoVenta.moneda != undefined) {
        detalle.total = UtilFactory.roundForMoneda(detalle.total, vm.presupuestoVenta.moneda);
      }

      calcularImpuestosAndTotalGeneral(detalle);

      if (detalle.unidadMedida.esBase) {
        detalle.cantidadUmPrincipal = detalle.cantidad;
      } else {
          //hacer calculo en um base
          var contenida = detalle.unidadMedida.cantidad;
          detalle.cantidadUmPrincipal = parseFloat(detalle.cantidad * contenida).toFixed(4);
          detalle.cantidadUmPrincipal = parseFloat(detalle.cantidadUmPrincipal); // Esto elimina ceros despues de la coma
      }
    }


    function controlLimiteDescuento(detalle,index) {
      var limiteDescuento = String(detalle.porcentajeDescuento);
      var cantidad;
      if (limiteDescuento.includes('.')) {
          cantidad = limiteDescuento.split('.')[1].length;
      }
      if (limiteDescuento.includes(',')) {
          cantidad = limiteDescuento.split(',')[1].length;
      }
      if (cantidad > 2) {
          detalle.porcentajeDescuento = 0;
          var msg = "El Límite de Descuento debe contener como máximo 2 decimales.";
          notify({ message: msg, classes: "alert-warning", position: "right" });
          return
      }
      if (vm.presupuestoVenta.listaPrecioVenta) {
          var productoLista = [];
          var staticFilter = {};
          staticFilter.search = filterFactory.and([{
          path: 'listaPrecioVenta.id',
          equals: vm.presupuestoVenta.listaPrecioVenta.id,
          }, {
          path: 'producto.id',
          equals: detalle.producto.id,
          }]).value();
          staticFilter.view = "PresupuestoVentaForm";
          ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
              _.forEach(response, function (elem) {
                  if (detalle.unidadMedida.id == elem.unidadMedida.id) {
                  productoLista = elem;
                  return true;
                  }
              })
              if (productoLista.limiteDescuento && vm.presupuestoVenta.listaPrecioVenta) {
                  if (productoLista.limiteDescuento && detalle.porcentajeDescuento > productoLista.limiteDescuento) {
                  detalle.porcentajeDescuento = 0;
                  //detalle.total = 0;
                  vm.calcularTotalDetalle(detalle,index);
                  var msg = $filter("translate")("PRECIO_VENTA_DESCUENTO_MAYOR") + productoLista.limiteDescuento + "%";
                  notify({ message: msg, classes: "alert-warning", position: "right" });
                  }
              }
          });
      }
  }



    function totalItemChanged(detalle,index) {

        if (detalle.cantidad == undefined) {
            detalle.cantidad = 1;
        }

        detalle.precio = UtilFactory.roundForMoneda(detalle.total / detalle.cantidad, vm.presupuestoVenta.moneda);
        controlPrecioUltimoCosto(detalle,index);
        calcularImpuestosAndTotalGeneral(detalle);

    }

    function calcularImpuestosAndTotalGeneral(detalle) {
        vm.totalGeneral = 0;
        _.forEach(vm.presupuestoVenta.presupuestoVentaDetalle, function (elem) {
            vm.totalGeneral = vm.totalGeneral + elem.total;
        });

        detalle.baseImponible = (100 * detalle.total) / (100 + detalle.producto.tiposImpuesto[0].porcentaje);
        detalle.baseImponible = UtilFactory.roundForMoneda(detalle.baseImponible, vm.presupuestoVenta.moneda);
        detalle.valorImpuesto = detalle.total - detalle.baseImponible;
        detalle.valorImpuesto = UtilFactory.roundForMoneda(detalle.valorImpuesto, vm.presupuestoVenta.moneda);
        vm.totalGeneral = UtilFactory.roundForMoneda(vm.totalGeneral, vm.presupuestoVenta.moneda);
    }

    function getMonedaLocal() {
        var params = {};
        params.search = filterFactory.single({
            path: 'codigo',
            equals: 'moneda_local'
        }).value();
        vm.monedaLocal = ParametrosFactory.all(params);
        vm.monedaLocal.$promise.then(function (data) {
          if (data && data.length > 0) {
              if (vm.presupuestoVenta.moneda == undefined) {
                  vm.presupuestoVenta.moneda = data[0].valorTipo;
              }
              vm.monedaDefault = data[0].valorTipo;
          }
      });
    }

    function getTiposPresupuestos() {
      var filtroTipoPresupuesto = {};
        filtroTipoPresupuesto.search = filterFactory.and([
            {
                path: 'activo',
                like: 'true'
            }

        ]).value();
        filtroTipoPresupuesto.view = "TipoPresupuestoList";
        vm.tiposPresupuestos = [];
        TipoPresupuestoFactory.all(filtroTipoPresupuesto).$promise.then(function (response) {
            _.forEach(response, function (detalle) {
              if(detalle.sucursal == null){
                vm.tiposPresupuestos.push(detalle);
              }else if (detalle.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                vm.tiposPresupuestos.push(detalle);
              }
            })
            if(vm.tiposPresupuestos.length == 1){
              if(vm.presupuestoVenta.tipoPresupuesto == undefined){
                vm.presupuestoVenta.tipoPresupuesto = vm.tiposPresupuestos[0];
              }
            }
        });
  }

    function sumarDias(fecha, dias) {
        fecha.setDate(fecha.getDate() + dias);
        return fecha;
    }

    function changeCondicion() {
        vm.viewCantidadCuotas = vm.presupuestoVenta.condicionVenta.codigo === "condicion_cobro_pedido_venta_credito";
        if (vm.viewCantidadCuotas == false) {
            vm.presupuestoVenta.cantidadCuotas = null;
        }
        changeTipoVenta();
    }

    function changeCotizacion() {
        if (vm.new == true) {
            var hoy = new Date();
            hoy.setHours(0, 0, 0, 0);
            vm.presupuestoVenta.fecha = hoy;
        }
        vm.monedaLocal.$promise.then(function (data) {

            if (vm.presupuestoVenta.moneda && vm.presupuestoVenta.moneda.descripcion != data[0].valorTipo.descripcion) {
                vm.cotizacionRequerida = true;

                var params = {};
                params.search = filterFactory.and([{
                    path: 'monedaDesde.id',
                    equals: vm.presupuestoVenta.moneda.id
                },
                {
                    path: 'monedaHasta.id',
                    equals: vm.monedaLocal[0].valorTipo.id
                },
                {
                    path: 'fecha',
                    like: moment(vm.presupuestoVenta.fecha).format('DD/MM/YYYY')
                    //equals:vm.comprobante.fecha
                }
                ]).value();
                params.view = 'CotizacionMonedaList';
                CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                    if (response && response[0]) {
                        vm.presupuestoVenta.tipoCambio = response[0].valorCompra;
                    } else {
                        vm.presupuestoVenta.tipoCambio = null;
                    }
                });
            } else {
                vm.cotizacionRequerida = false;
                vm.presupuestoVenta.tipoCambio = 1;
            }

        });
        /* Solo cuando se selecciona la moneda se revisa si el cliente está
            asociado a una lista de precios de ventas. */
        if (vm.presupuestoVenta.moneda) {

            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'cliente.id',
                equals: vm.presupuestoVenta.cliente.id
            }, {
                path: 'estado.codigo',
                equals: 'lista_activada'
            }]).value();
            ListaPrecioVentaFactory.all(staticFilter,"PresupuestoVentaForm").$promise.then(function (response) {
                /* Se controla si el cliente está asociado a una lista de precios por asociación directa
                con la moneda seleccionada */
                if (response.length > 0 && response[0].moneda.codigo == vm.presupuestoVenta.moneda.codigo) {
                    vm.presupuestoVenta.listaPrecioVenta = response[0];
                    vm.presupuestoVenta.monedaLista = vm.presupuestoVenta.listaPrecioVenta.moneda;
                    vm.getListasPreciosVentasByMoneda();
                    vm.actualizarPreciosDeVentas();
                    ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {

                        if (parametro.valorTipo.codigo === 'no') {
                            vm.utilizarPrecioBase = false;
                        } else {
                            vm.utilizarPrecioBase = true;
                            vm.porcRecargo = vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo;
                            vm.precioBase = vm.presupuestoVenta.listaPrecioVenta.precioBase.descripcion;
                        }
                    });
                    /* if(vm.utilizarPrecioBase){
                        vm.porcRecargo = vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo;
                        vm.precioBase = vm.presupuestoVenta.listaPrecioVenta.precioBase.descripcion;
                    } */
                } else if (response.length === 0) {
                    /* Se controla si el cliente está asociado a una lista de precios por su tipo de cliente
                    con la moneda seleccionada */
                    var staticFilter = {};
                    staticFilter.search = filterFactory.and([{
                        path: 'tipoCliente.id',
                        equals: vm.presupuestoVenta.cliente.tipoCliente.id
                    }, {
                        path: 'estado.codigo',
                        equals: 'lista_activada'
                    }, {
                        path: 'moneda.codigo',
                        equals: vm.presupuestoVenta.moneda.codigo
                    }]).value();
                    ListaPrecioVentaFactory.all(staticFilter,"PresupuestoVentaForm").$promise.then(function (response) {
                        if (response.length > 0) {
                            vm.presupuestoVenta.listaPrecioVenta = response[0];
                            vm.presupuestoVenta.monedaLista = vm.presupuestoVenta.listaPrecioVenta.moneda;
                            vm.actualizarPreciosDeVentas();
                            vm.getListasPreciosVentasByMoneda();
                            ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
                                if (parametro.valorTipo.codigo === 'no') {
                                    vm.utilizarPrecioBase = false;
                                } else {
                                    vm.utilizarPrecioBase = true;
                                    vm.porcRecargo = vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo;
                                    vm.precioBase = vm.presupuestoVenta.listaPrecioVenta.precioBase.descripcion;
                                }
                            });
                            /*  if(vm.utilizarPrecioBase){
                                 vm.porcRecargo = vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo;
                                 vm.precioBase = vm.presupuestoVenta.listaPrecioVenta.precioBase.descripcion;
                             } */
                        } else {
                            vm.presupuestoVenta.listaPrecioVenta = undefined;
                            vm.presupuestoVenta.monedaLista = undefined;
                            vm.presupuestoVenta.cambioPactado = undefined;
                            vm.porcRecargo = 0;
                            vm.precioBase = 'Precio de Venta'
                            vm.actualizarPreciosDeVentas();
                            vm.getListasPreciosVentasByMoneda();
                        }
                    });
                } else {
                    /* Si el cliente está asociado a una Lista de Precios de Ventas, pero no con la moneda
                    seleccionada. */
                    vm.presupuestoVenta.listaPrecioVenta = undefined;
                    vm.presupuestoVenta.monedaLista = undefined;
                    vm.presupuestoVenta.cambioPactado = undefined;
                    vm.porcRecargo = 0;
                    vm.precioBase = 'Precio de Venta'
                    vm.getListasPreciosVentasByMoneda();
                    vm.actualizarPreciosDeVentas();
                }
            });
        }
    }

    function activate() {
        WatchLocacionChangeFactory.killAllWatchers();
        vm.porcRecargo = 0;
        vm.precioBase = 'Precio de Venta';

        vm.permisoEditarPrecioVenta = AuthorizationService.hasPermission("update_precio_venta_comp");
        console.log('AuthorizationService.hasPermission("updatefv_presupuestoventas") ', AuthorizationService.hasPermission("updatefv_presupuestoventas"));
        if (AuthorizationService.hasPermission("updatefv_presupuestoventas")) {
            vm.habilitarFechaVencimiento = false;
        } else {
            vm.habilitarFechaVencimiento = true;
        }


        vm.noPermisoEditarVendedor = AuthorizationService.hasPermission("modificar_vendedor_presupuestoventas");
        vm.permisoEditarPrecioVenta = AuthorizationService.hasPermission("update_precio_venta_comp");
        vm.permisoEditarPrecioFromLista = AuthorizationService.hasPermission("asignar_precioventa_distinto");
        vm.permisoAsignarListaPrecioVenta = AuthorizationService.hasPermission("asignar_listaprecioventa");

        vm.proyectos = ProyectoFactory.all("", "PresupuestoVentaForm");
        ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
          vm.proyectoObligatorio = r.valorTipo.codigo == "si";
        });

        //Recupera parametros para manejar los registros de ultimoCosto
        ParametrosFactory.getByCodigo('tipo_administracion_costo').then(function (response) {
          vm.parametroAdministracionCosto = response.valorTipo.codigo;
        });
        ParametrosFactory.getByCodigo('administrar_costo').then(function (response) {
          vm.parametroAdministrarCostoPor = response.valorTipo.codigo;
        });


        var filtroServicios = {};
        filtroServicios.search = filterFactory.and([{
            path: 'clase.codigo',
            equals: 'forma_pago_referencia'
        }]).value();
        vm.promiseFormaPago = [];
        vm.promiseFormaPago.push(FormaPagoFactory.all(filtroServicios).$promise.then(function (data){
          vm.formasPagos = data;
          vm.formasPagosAux = angular.copy(data);
        }));
        var filtroVendedor = {};
        filtroVendedor.search = filterFactory.and([
            {
                path: 'activo',
                like: 'true'
            },
            {
                path: 'vendedor',
                like: 'true'
            }

        ]).value();
        filtroVendedor.view = "VendedorList";
        vm.vendedores = [];
        VendedorFactory.all(filtroVendedor).$promise.then(function (response) {
            _.forEach(response, function (detalle) {
                for (var i = 0; i < detalle.locaciones.length; i++) {
                    if (detalle.locaciones[i].locacion.id == $rootScope.AuthParams.locacionSeleccionada.id) {
                        vm.vendedores.push(detalle);
                        break;
                    }
                }
            })
        });

        getMonedaLocal();
        vm.monedas = {};
        TiposFactory.monedas().then(function (data) {
            vm.monedas = data.data;
        });
        TiposFactory.condicionCobroPedidoVenta().then(function (data) {
            vm.condiciones = data.data;
        });
        TiposFactory.estadosPresupuestoVenta().then(function (data) {
            vm.estados = data.data;
        });
        var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();
        filtroActivo.view = "BaseList";
        //vm.clientes = ClienteFactory.all(filtroActivo);
        VentasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            vm.curDate = new Date();
            var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
                'lineaProducto.nombre', 'marca.descripcion'
            ];
            // TODO agregar locacionFilter
            var staticFilterProductos = {};
            staticFilterProductos.search = filterFactory.and([
                {
                    path: 'itemVenta',
                    like: 'true'
                },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                },
                {
                  path: "locaciones.id",
                  equals: $rootScope.AuthParams.locacionSeleccionada.id
                }
            ]).value();
            vm.opcionesProductosDt = {
                'resource': 'productos',
                'title': 'ProductosPresupuestoVenta',
                'view': 'ProductoList',
                'staticFilter': staticFilterProductos,
                'factory': ProductosFactory,
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'codigo', 'title': 'Código', visible: false },
                    { 'data': 'nombre', 'title': translations.NAME },
                    { 'data': 'claseProducto.descripcion', 'title': 'Clase' },
                    { 'data': 'estado.descripcion', 'title': translations.STATUS, 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
                    { 'data': 'lineaProducto.nombre', 'title': 'Línea' },
                    { 'data': 'marca.descripcion', 'title': 'Marca', 'renderWith': 'emptyRender' },
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc"
            };
            if (vm.isMobile) {
              $scope.isPopup = false;
              vm.opcionesProductosDt.defaultColumnOrder = ['codigo', 'nombre', 'marca.descripcion'];
              vm.opcionesProductosDt.columns = [
                { 'data': 'codigo', 'title': 'Código'},
                { 'data': 'nombre', 'title': translations.NAME },
                { 'data': 'claseProducto.descripcion', 'title': 'Clase'},
                { 'data': 'estado.descripcion', 'title': translations.STATUS, 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados'},
                { 'data': 'lineaProducto.nombre', 'title': 'Línea'},
                { 'data': 'marca.descripcion', 'title': 'Marca', 'renderWith': 'emptyRender' },
                { 'data': 'codigoBarrasPrincipal.codigoBarras', 'title': 'Código Barras'}
              ];
              vm.opcionesProductosDt.hideViewMenu = true;
              vm.opcionesProductosDt.extraRowOptions = [
                {
                  templateToRender: "<button class='btn btn-info' title='" + $filter('translate')('PICK') + "' style='margin-right: 5px;' ng-click='ver(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                  functionName: "ver",
                  functionDef: function (itemId) {
                    if(!$rootScope.isPopup){
                      verImagenProducto(itemId);
                    }
                  }
                }, {
                  templateToRender: "<button class='btn btn-primary' title='" + $filter('translate')('PICK') + "' style='margin-right: 5px;' ng-click='pick(<%=dataId%>)'> <span class='glyphicon glyphicon-ok'></span> </button>",
                  functionName: "pick",
                  functionDef: function (itemId) {
                    if(!$rootScope.isPopup){
                      $rootScope.pick(itemId);
                    }
                  }
                }];
            };
            if ($state.is("app.presupuestoventas.new")) {
                activateNew();
            } else if ($state.is("app.presupuestoventas.edit")) {
                activateEdit();
            } else if ($state.is("app.presupuestoventas.view")) {
                activateView();
            } else {
                activateView();
            }
        });
        vm.submited = false;
    }

    /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
    vm.staticFilterClientes = [{path: "activo", equals: true}];
    vm.clientesFactory = ClienteFactory;

    function activateNew() {
        if (vm.isMobile){
          popupBusquedaCliente()
        }
        vm.title = vm.translations.NEW_PRESUPUESTO_VENTA;
        vm.new = true;
        vm.presupuestoVenta = {
            presupuestoVentaDetalle: [{}],
        };
        getTiposPresupuestos();
        TiposFactory.estadosPresupuestoVenta().then(function (data) {
            var estadoIngresado = _.filter(data.data, function (elem) { return elem.codigo === 'prv_ingresado'; });
            vm.presupuestoVenta.estado = estadoIngresado[0];
        });
        var hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        vm.presupuestoVenta.fecha = hoy;

        ParametrosFactory.getByCodigo('dias_vencimiento_presupuesto_venta').then(function (parametro) {
            vm.diasVencimiento = parseInt(parametro.valor)
            vm.presupuestoVenta.fechaVencimiento = sumarDias(hoy, vm.diasVencimiento)
        });
        vm.mostrarCambioPactado = false;
        getMonedaLocal();
    }

    function activateView() {
        vm.title = vm.translations.VIEW_PRESUPUESTO_VENTA;
        vm.presupuestoVenta = presupuestoVentaPrepService;
        Promise.all(vm.promiseFormaPago)
          .then(function() {
            filterFormasPago();
          });
        if (vm.presupuestoVenta.condicionVenta != null) {
            vm.viewCantidadCuotas = vm.presupuestoVenta.condicionVenta.codigo === "condicion_cobro_pedido_venta_credito"
        }
        ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.utilizarPrecioBase = false;
            } else {
                vm.utilizarPrecioBase = true;
                vm.porcRecargo = vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo;
                vm.precioBase = vm.presupuestoVenta.listaPrecioVenta.precioBase.descripcion;
            }
        });

        checkPermisosFor("view");
        vm.presupuestoVenta.fecha = new Date(vm.presupuestoVenta.fecha);
        vm.presupuestoVenta.fechaVencimiento = new Date(vm.presupuestoVenta.fechaVencimiento);
        vm.entidadId = vm.presupuestoVenta.id;
        vm.entidad = "PresupuestoVenta";
        vm.view = true;
        vm.new = false;
        vm.totalGeneral = 0;
        _.forEach(vm.presupuestoVenta.presupuestoVentaDetalle, function (elem) {
            vm.totalGeneral = vm.totalGeneral + elem.total;
            elem.mostrarMas = false;
            ProductosFactory.get(elem.producto.id, 'BaseList').$promise.then(function (producto) {
                elem.producto = producto;
                calcularImpuestosAndTotalGeneral(elem);
            });
        });
        if (vm.presupuestoVenta.moneda.codigo == "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
            //si el presupuesto es en gs y la lista en moneda extranjera
            vm.mostrarCambioPactado = true;
        }
    }

    function activateEdit() {
        vm.title = vm.translations.EDIT_PRESUPUESTO_VENTA;
        vm.presupuestoVenta = presupuestoVentaPrepService;
        Promise.all(vm.promiseFormaPago)
          .then(function() {
            filterFormasPago();
          });
        if (vm.presupuestoVenta.condicionVenta != null) {
            vm.viewCantidadCuotas = vm.presupuestoVenta.condicionVenta.codigo === "condicion_cobro_pedido_venta_credito"
        }
        var suc = [];
        for(var i = 0; i < vm.presupuestoVenta.cliente.sucursales.length; i++){
          if(vm.presupuestoVenta.cliente.sucursales[i].activo === true){
            suc.push(vm.presupuestoVenta.cliente.sucursales[i])
          }
        }
        getTiposPresupuestos();
        vm.sucursalesCliente = suc;
        checkPermisosFor("edit", vm.presupuestoVenta.estado);
        vm.presupuestoVenta.fecha = new Date(vm.presupuestoVenta.fecha);
        vm.presupuestoVenta.fechaVencimiento = new Date(vm.presupuestoVenta.fechaVencimiento);
        vm.entidadId = vm.presupuestoVenta.id;
        vm.entidad = "PresupuestoVenta";
        vm.getListasPreciosVentasByMoneda();
        vm.view = false;
        vm.new = false;
        vm.totalGeneral = 0;

        _.forEach(vm.presupuestoVenta.presupuestoVentaDetalle, function (elem) {
            vm.totalGeneral = vm.totalGeneral + elem.total;
            ProductosFactory.get(elem.producto.id, 'BaseList').$promise.then(function (producto) {
                elem.producto = producto;
                calcularImpuestosAndTotalGeneral(elem);
            });
        });

        if (vm.presupuestoVenta.moneda.codigo == "guaranies" && vm.presupuestoVenta.listaPrecioVenta && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
            //si el presupuesto es en gs y la lista en moneda extranjera
            vm.mostrarCambioPactado = true;
        }
        recuperarPreciosMinimosMaximos();

        if (vm.presupuestoVenta.listaPrecioVenta && vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo) {
            vm.porcRecargo = vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo;
            vm.precioBase = vm.presupuestoVenta.listaPrecioVenta.precioBase.descripcion;
        }
    }

    function changeCliente(isNew) {
        if (vm.presupuestoVenta.moneda) {
            vm.changeMoneda();
        }
        if (vm.presupuestoVenta.cliente) {
            var staticFilter = {};
            staticFilter.search = filterFactory
                .and([{
                    path: "cliente.id",
                    equals: vm.presupuestoVenta.cliente.id
                }])
                .value();
        }
        var suc = [];
        for(var i = 0; i < vm.presupuestoVenta.cliente.sucursales.length; i++){
          if(vm.presupuestoVenta.cliente.sucursales[i].activo === true){
            suc.push(vm.presupuestoVenta.cliente.sucursales[i])
          }
        }
        vm.sucursalesCliente = suc;
        if (vm.sucursalesCliente.length >= 1) {
            vm.presupuestoVenta.sucursalCliente = vm.sucursalesCliente[0];
            vm.presupuestoVenta.vendedor = vm.presupuestoVenta.sucursalCliente.vendedor;
        } else {
            vm.presupuestoVenta.sucursalCliente = null;
            vm.presupuestoVenta.vendedor = null;
        }
        vm.presupuestoVenta.condicionVenta = vm.presupuestoVenta.cliente.tipoVenta;
        vm.presupuestoVenta.formaPago = vm.presupuestoVenta.cliente.formaPago;
        if (vm.presupuestoVenta.cliente.formaPago != undefined) {
            vm.presupuestoVenta.cantidadCuotas = vm.presupuestoVenta.cliente.formaPago.cantidadCuotas;
        } else {
            vm.presupuestoVenta.cantidadCuotas = undefined;
        }
        filterFormasPago();
    }

    function changeSucCliente() {
      if (vm.presupuestoVenta.sucursalCliente) {
          vm.presupuestoVenta.vendedor = vm.presupuestoVenta.sucursalCliente.vendedor;
      }
   }

    vm.searchProductoFilter = function (criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria) === 0);
        };
    }

    function agregarDetalle() {
        vm.presupuestoVenta.presupuestoVentaDetalle.push({});
        var rowNumber = vm.presupuestoVenta.presupuestoVentaDetalle.length - 1;
        $timeout(function () {
            $scope.$broadcast('newItemAdded');
        }, 20);

        if(vm.isMobile){
          $timeout(function(){
            window.scrollTo(0, document.body.scrollHeight);
          }, 200)
          $scope.indiceActual = rowNumber;
          $scope.detalleActual = vm.presupuestoVenta.presupuestoVentaDetalle[$scope.indiceActual];
        }
    }

    function removeItemFromArray(elemento, arreglo,index) {
        for(var i = index; i < vm.precioMinimoMaximos.length - 1; i++){
          vm.precioMinimoMaximos[i] = vm.precioMinimoMaximos[i+1];
        }
        vm.precioMinimoMaximos.pop();
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        $timeout(function () {
            $scope.$broadcast('newItemRemoved');
        }, 20);
        calcularTotalDetalle(elemento);
    }

    function popupCliente(){
      $scope.tituloModal = 'Datos del Cliente';
      $scope.marca = vm.presupuestoVenta.vehiculoMarcaCliente;
      $scope.idn = vm.presupuestoVenta.vehiculoIdnCliente;
      $scope.modelo = vm.presupuestoVenta.vehiculoModeloCliente;
      $scope.anho= vm.presupuestoVenta.vehiculoAnhoCliente;
      $scope.caja= vm.presupuestoVenta.vehiculoCajaCliente;
      $scope.motor = vm.presupuestoVenta.vehiculoMotorCliente;
      var modalInstance = $modal.open({
          templateUrl: 'qualita-components/ventas/views/presupuestoventa/datos-cliente-modal.html',
          scope: $scope
      });

      $scope.cancel = function () {
          modalInstance.dismiss('cancel');
      };

      $scope.ok = function (idn,marca,modelo,anho,caja,motor) {
        vm.presupuestoVenta.vehiculoMarcaCliente = marca;
        vm.presupuestoVenta.vehiculoIdnCliente = idn;
        vm.presupuestoVenta.vehiculoModeloCliente = modelo;
        vm.presupuestoVenta.vehiculoAnhoCliente = anho;
        vm.presupuestoVenta.vehiculoCajaCliente = caja;
        vm.presupuestoVenta.vehiculoMotorCliente = motor;
        modalInstance.dismiss('cancel');
      }
    }

    function popupBusquedaCliente() {
      $scope.tituloModal = 'Búsqueda del Cliente';
      var modalInstance = $modal.open({
        templateUrl: 'qualita-components/ventas/views/presupuestoventa/m-datos-cliente-modal.html',
        scope: $scope,
        size: "lg",
        backdrop: 'static'
      });

      modalInstance.rendered.then(function(){
        $timeout(function () {
          $scope.$broadcast("mobile_cliente");
        }, 1000)
      })

      $scope.cancelBusqueda = function () {
        modalInstance.dismiss('cancel');
        vm.presupuestoVenta.cliente = undefined;
        vm.presupuestoVenta.sucursalCliente = undefined;
        vm.presupuestoVenta.vendedor = undefined;
        vm.presupuestoVenta.moneda = undefined;
        vm.presupuestoVenta.tipoCambio = undefined;
        vm.presupuestoVenta.formaPago = undefined;
        vm.presupuestoVenta.condicionVenta = undefined;
        vm.presupuestoVenta.cantidadCuotas = undefined;
        vm.presupuestoVenta.listaPrecioVenta = undefined;
        vm.presupuestoVenta.monedaLista = undefined;
        vm.presupuestoVenta.presupuestoVentaDetalle = [{}];
        vm.sucursalesCliente = undefined;
        vm.presupuestoVenta.moneda = vm.monedaLocal[0].valorTipo;
        vm.presupuestoVenta.vehiculoMarcaCliente = undefined;
        vm.presupuestoVenta.vehiculoIdnCliente = undefined;
        vm.presupuestoVenta.vehiculoModeloCliente = undefined;
        vm.presupuestoVenta.vehiculoAnhoCliente = undefined
        vm.presupuestoVenta.vehiculoCajaCliente = undefined;
        vm.presupuestoVenta.vehiculoMotorCliente = undefined;
        vm.totalGeneral = 0;
      };

      $scope.okBusqueda = function () {
        modalInstance.dismiss('cancel');
        $scope.indiceActual = vm.presupuestoVenta.presupuestoVentaDetalle.length - 1;
        $scope.detalleActual = vm.presupuestoVenta.presupuestoVentaDetalle[$scope.indiceActual];
        $timeout(function () {
          $("#det_" + $scope.indiceActual + ">button").click()
        })
        $timeout(function () {
          $('#filtro_1').focus();
        }, 1000)
      }

    }
    function popupDetalleProducto() {
      $scope.tituloModal = 'Detalles del producto';
      var modalInstance = $modal.open({
        templateUrl: 'qualita-components/ventas/views/presupuestoventa/m-detalle-producto-modal.html',
        scope: $scope,
        size: "lg",
        backdrop: 'static'
      });

      modalInstance.rendered.then(function(){
        $timeout(function () {
          $('#detalleProducto #cantidad_' +$scope.indiceActual).focus().keypress(function (event) {
          if(event.which===13){
            if(!$scope.detalleActual.precio){
              event.preventDefault();
              var fields=$(this).parents('form:eq(0),body').find('input, textarea, select');
              var index=fields.index(event.target);
              if(index> -1&&(index+1)<fields.length)
                fields.eq(index+1).focus();
            }
          }
        });
        }, 1000)
      });

      $scope.cancel = function () {
        modalInstance.dismiss('cancel');
        vm.removeItemFromArray($scope.detalleActual, vm.presupuestoVenta.presupuestoVentaDetalle,$scope.indiceActual)
        if(!vm.presupuestoVenta.presupuestoVentaDetalle.length){
          vm.presupuestoVenta.presupuestoVentaDetalle.push({})
        }
      };

      $scope.agregarProducto = function () {
        modalInstance.dismiss('cancel');
        agregarDetalle();
        $timeout(function () {
          $("#det_" + $scope.indiceActual + ">button").click()
        })
        $timeout(function () {
          $('#filtro_1').focus()
        }, 1000)
      }

      $scope.ok = function () {
        modalInstance.dismiss('cancel');
      }
    }
    function verImagenProducto(productoId) {
      $rootScope.isPopup = true;
      $scope.tituloModal = 'Imagen del Producto';
      ProductosFactory.get(productoId, 'ProductoForm').$promise.then(function (producto) {
        $scope.producto = producto;

        var imagePath = "images/placeholder.png";
        if ($scope.producto.imagen && $scope.producto.imagen !== false) {
          imagePath = baseurl.getPublicBaseUrl() + $scope.producto.imagen
        }
        $scope.uploadOptions1 = {
          imageOnly: true,
          title: 'Foto',
          imagePath: imagePath
        };
        var modalInstance = $modal.open({
          templateUrl: 'qualita-components/ventas/views/presupuestoventa/m-imagen-producto-modal.html',
          scope: $scope
        });
        modalInstance.rendered.then(function(){
          $("#file .btn.btn-primary").hide()
          $("#file p").hide()
          $rootScope.isPopup = false;
        })
        $scope.ok = function () {
          modalInstance.dismiss('cancel');
        }
      });
    }

    function changeProducto(detalle,index) {
      vm.isMobile ? popupDetalleProducto() : "";
      detalle.grupoProducto = null;
      detalle.unidadMedidaBase = detalle.producto.unidadMedidaBase;
      detalle.unidadMedidaReferencial = null;
      detalle.unidadMedida = detalle.producto.unidadMedidaBase;
      detalle.cantidad = null;
      detalle.cantidadView = null;

      if (vm.presupuestoVenta.listaPrecioVenta) {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
          path: 'listaPrecioVenta.id',
          equals: vm.presupuestoVenta.listaPrecioVenta.id,
        },
        {
          path: 'producto.id',
          equals: detalle.producto.id,
        }]).value();
        staticFilter.view = "PresupuestoVentaForm";
        ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {

          if (vm.verPrecio != 'No') {
            changeUnidad(detalle, index, response);
          } else {
            detalle.precio = 0;
            detalle.totalGeneral = 0;
          }
        });
      } else {
        if (vm.verPrecio != 'No') {
          changeUnidad(detalle);
        } else {
          detalle.precio = 0;
          detalle.totalGeneral = 0;
        }
      }
    }

    function changeUnidad(detalle,index,productosFromListaPrecio) {
        //Si se utiliza el precio Base y el cliente está asociado a una lista de precios de ventas.
        if (vm.utilizarPrecioBase && vm.presupuestoVenta.listaPrecioVenta) {
            var precioBase = vm.presupuestoVenta.listaPrecioVenta.precioBase.codigo;
            //ultimocostoIVA - tabla stk_producto_almacen
            if (precioBase == 'ultimo_costo') {

              if (vm.presupuestoVenta.moneda.codigo == "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                //si el presupuesto es en gs y la lista en moneda extranjera
                detalle.precio = parseFloat(vm.ultimoCostoIVA.toString().replace(/\./, ","));
              } else if (vm.presupuestoVenta.moneda.codigo != "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                //si el presupuesto es en moneda extranjera y la lista en Gs
                var precioFinal = vm.ultimoCostoIVA / vm.presupuestoVenta.tipoCambio;
                detalle.precio = Math.round(precioFinal * 100) / 100;
              } else if (vm.presupuestoVenta.moneda.codigo != 'guaranies' && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                //si el presupuesto es en moneda extranjera y la lista en moneda extranjera
                var precioFinal = vm.ultimoCostoIVA / vm.presupuestoVenta.tipoCambio;
                detalle.precio = Math.round(precioFinal * 100) / 100;
              }
              else {
                detalle.precio = parseFloat(vm.ultimoCostoIVA.toString().replace(/\./, ","));
              }

              if (detalle.precio > 0 && vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo > 0) {
                vm.porcRecargo = vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo;
                var precioAux = detalle.precio * (vm.porcRecargo / 100) + detalle.precio;
                detalle.precio = UtilFactory.roundForMoneda(precioAux, vm.presupuestoVenta.moneda);
              }
              calcularTotalDetalle(detalle,index);
            } else {
                //precio_actual - tabla vnt_cambio_precio_detalle
                CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                    if (vm.presupuestoVenta.moneda.codigo == "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                        //si el presupuesto es en gs y la lista en moneda extranjera
                        detalle.precio = parseFloat(precio.data.toString().replace(/\./, ","));
                    } else if (vm.presupuestoVenta.moneda.codigo != "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                        //si el presupuesto es en moneda extranjera y la lista en Gs
                        var precioFinal = precio.data / vm.presupuestoVenta.tipoCambio;
                        detalle.precio = Math.round(precioFinal * 100) / 100;
                    } else if(vm.presupuestoVenta.moneda.codigo != 'guaranies' && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo != "guaranies"){
                        //si el presupuesto es en moneda extranjera y la lista en moneda extranjera
                        var precioFinal = precio.data / vm.presupuestoVenta.tipoCambio;
                        detalle.precio = Math.round(precioFinal * 100) / 100;
                    }
                    else {
                        detalle.precio = parseFloat(precio.data.toString().replace(/\./, ","));
                    }

                    if (detalle.precio > 0 && vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo > 0) {
                        vm.porcRecargo = vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo;
                        var precioAux = detalle.precio * (vm.porcRecargo / 100) + detalle.precio;
                        detalle.precio = UtilFactory.roundForMoneda(precioAux, vm.presupuestoVenta.moneda);
                    }
                    calcularTotalDetalle(detalle,index);
                });
            }
        } else if (!vm.utilizarPrecioBase && vm.presupuestoVenta.listaPrecioVenta) {
          if (productosFromListaPrecio) {
            var productoLista = [];
            _.forEach(productosFromListaPrecio, function (elem) {
              if (detalle.unidadMedida.id == elem.unidadMedida.id) {
                productoLista = elem;
              }
            });
                if (productoLista) {
                    if (vm.presupuestoVenta.moneda.codigo == "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                        //si el presupuesto es en gs y la lista en moneda extranjera
                        detalle.precio = UtilFactory.roundForMoneda(productoLista.precioVenta * vm.presupuestoVenta.cambioPactado, vm.presupuestoVenta.moneda);
                        detalle.precioMinimo = UtilFactory.roundForMoneda(productoLista.precioMinimo * vm.presupuestoVenta.cambioPactado, vm.presupuestoVenta.moneda);
                        detalle.precioMaximo = UtilFactory.roundForMoneda(productoLista.precioMaximo * vm.presupuestoVenta.cambioPactado, vm.presupuestoVenta.moneda);
                    } else if (vm.presupuestoVenta.moneda.codigo != "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                        //si el presupuesto es en moneda extranjera y la lista en gs
                        var precioFinal = productoLista.precioVenta / vm.presupuestoVenta.tipoCambio;
                        var precioMin = productoLista.precioMinimo / vm.presupuestoVenta.tipoCambio;
                        var precioMax = productoLista.precioMaximo / vm.presupuestoVenta.tipoCambio;
                        detalle.precio = Math.round(precioFinal * 100) / 100;
                        detalle.precioMinimo = Math.round(precioMin * 100) / 100;
                        detalle.precioMaximo = Math.round(precioMax * 100) / 100;
                    } else {

                        detalle.precio = productoLista.precioVenta;
                        detalle.precioMinimo = productoLista.precioMinimo;
                        detalle.precioMaximo = productoLista.precioMaximo;
                    }

                    var obj = {
                      "precioMinimo": detalle.precioMinimo,
                      "precioMaximo": detalle.precioMaximo
                    };
                    vm.precioMinimoMaximos.splice(index,1,obj);

                    vm.productoPerteneceLista = true;
                } else {
                    vm.productoPerteneceLista = false;
                    detalle.precio = 0;
                    CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                        if (vm.presupuestoVenta.moneda.codigo == "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                            //si el presupuesto es en gs y la lista en moneda extranjera
                            detalle.precio = precio.data * vm.presupuestoVenta.cambioPactado;
                        } else if (vm.presupuestoVenta.moneda.codigo != "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                            //si el presupuesto es en moneda extranjera y la lista en gs
                            var precioFinal = precio.data / vm.presupuestoVenta.tipoCambio;
                            detalle.precio = Math.round(precioFinal * 100) / 100;
                        }else if (vm.presupuestoVenta.moneda.codigo != "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                            //si el presupuesto es en moneda extranjera y la lista en moneda extranjera
                            var precioFinal = precio.data / vm.presupuestoVenta.tipoCambio;
                            detalle.precio = Math.round(precioFinal * 100) / 100;
                        } else {
                            detalle.precio = parseFloat(precio.data.toString().replace(/\./, ","));
                        }
                        calcularTotalDetalle(detalle,index);
                    });
                }
                calcularTotalDetalle(detalle,index);
            } else {

                //El cliente está asociado a una lista de precios de ventas sin detalles
            }
        } else {
            CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {

                if (vm.presupuestoVenta.moneda.codigo == "guaranies") {
                    detalle.precio = parseFloat(precio.data.toString().replace(/\./, ","));
                } else {

                    var precioFinal = precio.data / vm.presupuestoVenta.tipoCambio;
                    detalle.precio = Math.round(precioFinal * 100) / 100;
                }

                calcularTotalDetalle(detalle,index);
            });
        }
    }

    function submit() {

      for (var i = 0; i < vm.presupuestoVenta.presupuestoVentaDetalle.length; i++) {
            if (isNaN(vm.presupuestoVenta.presupuestoVentaDetalle[i].porcentajeDescuento)) {
                notify({ message: "El campo %Desc del detalle del presupuesto de venta debe ser numerico.", classes: "alert-danger", position: "right" });
                vm.disabledSubmite = false;
                return;
            }

            if (vm.presupuestoVenta.presupuestoVentaDetalle[i].porcentajeDescuento > 100) {
                notify({ message: "El campo %Desc del detalle del presupuesto de venta no puede ser mayor a 100.", classes: "alert-danger", position: "right" });
                vm.disabledSubmite = false;
                return;
            }
            if (vm.presupuestoVenta.presupuestoVentaDetalle[i].porcentajeDescuento == "") {
                vm.presupuestoVenta.presupuestoVentaDetalle[i].porcentajeDescuento = 0;
            }

            vm.presupuestoVenta.presupuestoVentaDetalle[i].porcentajeDescuento = +vm.presupuestoVenta.presupuestoVentaDetalle[i].porcentajeDescuento

      }
        vm.submited = true;
        if ($scope.PresupuestoVentaForm.$valid && (vm.verPrecio == 'No' || controlMontosDetalles(vm.presupuestoVenta))) {
            if (vm.presupuestoVenta.presupuestoVentaDetalle.length >= 1) {
                //controlar que fecha de vencimiento no sea menor a la fecha del presupuesto
                if (vm.presupuestoVenta.fecha != undefined && vm.presupuestoVenta.fechaVencimiento != undefined
                    && vm.presupuestoVenta.fecha > vm.presupuestoVenta.fechaVencimiento) {
                    notify({ message: $filter('translate')('FECHA_MAYOR_FECHA_VEN'), classes: 'alert-danger', position: 'right' });
                    return;
                }
                if (!vm.presupuestoVenta.cliente.activo) {
                  var msg = "El cliente no se encuentra en estado Activo.";
                  notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  vm.submited = false;
                  $rootScope.isProcessing = false;
                  return;
                }

                if (vm.presupuestoVenta.cliente.bloquearVentas) {
                  var msg = "El cliente se encuentra bloqueado para los procesos de ventas.";
                  notify({ message: msg, classes: 'alert-danger', position: 'right' });
                  vm.submited = false;
                  $rootScope.isProcessing = false;
                  return;
                }
                var existeTotal0 = _.filter(vm.presupuestoVenta.presupuestoVentaDetalle, function (elem) {
                    if (elem.total == 0) return elem;
                });
                if (existeTotal0.length > 0 && vm.verPrecio != 'No') {
                    var msg = vm.translations.EXISTE_TOTAL_CERO; //vm.translations.DETAILS_ERROR_OP_MANUAL_STOCK;
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.submited = false;
                    return;
                }
                if (vm.grupoRepetido) {
                    var msg = vm.translations.NUMERO_GRUPO_REPETIDO; //vm.translations.DETAILS_ERROR_OP_MANUAL_STOCK;
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.submited = false;
                    return;
                }
                $rootScope.isProcessing = true;
                vm.presupuestoVenta.presupuestoVentaDetalle = _.filter(vm.presupuestoVenta.presupuestoVentaDetalle, function (elem) { return elem.producto; });
                // se quita el atributo grupo de los detalles cuyos productos son de tipo normal
                vm.presupuestoVenta.presupuestoVentaDetalle = _.forEach(vm.presupuestoVenta.presupuestoVentaDetalle, function (elem) {
                    if (elem.producto.criterioControl.codigo !== 'series' && elem.producto.criterioControl.codigo !== 'lotes') {
                        delete elem.grupo;
                    }
                });
                var usersFilter = {};
                usersFilter.search = filterFactory.and([{
                    path: 'username',
                    equals: $rootScope.AuthParams.username
                }]).value();
                usersFilter.view = "BaseList"
                UsuariosFactory.all(usersFilter).$promise.then(function (data) {
                    vm.presupuestoVenta.usuario = data[0];

                    submitPresupuestoVenta().then(function (response) {
                        $state.go('app.presupuestoventas.list');
                    }, function (error) {
                        $rootScope.isProcessing = false;
                        errorHandler(error);
                    });
                });
            } else {
                var msg = vm.translations.ERROR_NO_DETALLES; //vm.translations.DETAILS_ERROR_OP_MANUAL_STOCK;
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
        }
    }

    function errorHandler(e) {
        var msg = "";
        _.forEach(e.data, function (error) {
            msg += "\n\n" + error.message + ".";
        });
        notify({ message: msg, classes: "alert-danger", position: "right" });
    }

    // se realiza un control de montos consistentes
    function controlMontosDetalles(presupuestoVenta) {
        var consistente = true;
        presupuestoVenta.presupuestoVentaDetalle = _.forEach(presupuestoVenta.presupuestoVentaDetalle, function (elem) {
            if (UtilFactory.roundForMoneda(elem.cantidad * (elem.precio - (elem.precio * (elem.porcentajeDescuento / 100))), vm.presupuestoVenta.moneda) != elem.total) {
                //var msg = vm.translations.ERROR_PRECIO_INCONSISTENTE;
                notify({ message: $filter("translate")("ERROR_PRECIO_INCONSISTENTE", { producto: elem.producto.codigo }), classes: "alert-danger", position: "right" });
                consistente = false;
                return;
            }
        });
        return consistente;
    }

    function submitPresupuestoVenta() {

        _.forEach(vm.presupuestoVenta.presupuestoVentaDetalle, function (elem) {
            delete elem.mostrarMas;
        });

        var trimmedModel = ModelTrimmer.trimDetails(vm.presupuestoVenta, ["presupuestoVentaDetalle"]);
        var trimmedDetalles = _.map(vm.presupuestoVenta.presupuestoVentaDetalle, function (detalle) {
            return ModelTrimmer.trimDetails(detalle);
        });
        trimmedModel.presupuestoVentaDetalle = trimmedDetalles;
        trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        var resource = PresupuestoVentaFactory.create(trimmedModel);
        return PresupuestoVentaFactory.save(resource);
    }

    function plusClicked(detalle) {
        detalle.mostrarMas = !detalle.mostrarMas;
    }

    function cancel() {
        $state.go('app.presupuestoventas.list');
    }

    function checkPermisosFor(action, estado) {
        if (!PresupuestoVentaFactory.hasPermisos(action, estado)) {
            $state.go("app.main");
        }
    }

    function getListasPreciosVentasByMoneda() {
        if (vm.presupuestoVenta.moneda) {
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'estado.codigo',
                equals: 'lista_activada'
            }]).value();
            ListaPrecioVentaFactory.all(staticFilter,"PresupuestoVentaFormView").$promise.then(function (response) {
                if (response.length > 0) {
                    //ver que moneda es el presupuesto
                    if (vm.presupuestoVenta.moneda.codigo == 'guaranies') {
                        //usa precio en Gs, Lista de precios en Gs y moneda extranjera
                        vm.listasPreciosVentas = response;
                    } else {
                        //si es moneda extranjera usa precio en Gs, Lista de precios en Gs y de la misma moneda extranjera de la operacion
                        var listaMoneda = [];
                        _.forEach(response, function (elem) {
                            if (elem.moneda.codigo == 'guaranies' || elem.moneda.codigo == vm.presupuestoVenta.moneda.codigo) {
                                listaMoneda.push(elem);

                            }
                        });
                        vm.listasPreciosVentas = listaMoneda;
                    }
                } else {
                    vm.listasPreciosVentas = undefined;
                }
            });
        }
    }

    function actualizarPreciosDeVentas() {
      if (vm.presupuestoVenta.presupuestoVentaDetalle.length > 0 && vm.presupuestoVenta.presupuestoVentaDetalle[0].producto) {
        _.forEach(vm.presupuestoVenta.presupuestoVentaDetalle, function (elem) {
          vm.detalleEliminado = false;
          if (vm.presupuestoVenta.presupuestoVentaDetalle) {

            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
              path: 'listaPrecioVenta.id',
              equals: vm.presupuestoVenta.listaPrecioVenta.id,
            },
            {
              path: 'producto.id',
              equals: elem.producto.id,
            }]).value();
            staticFilter.view = "PedidoVentaForm";
            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {

              if (elem.precio != undefined) {
                changeUnidad(elem, null, response);
              }
            });
          } else {
            if (elem.precio != undefined) {
              changeUnidad(elem);
            }
          };
        });
      }
    }

    function changeListaPrecioVenta() {
        vm.presupuestoVenta.monedaLista = vm.presupuestoVenta.listaPrecioVenta.moneda;

        ListaPrecioVentaFactory.get(vm.presupuestoVenta.listaPrecioVenta.id, 'PresupuestoVentaForm').$promise.then(function (lista) {
            vm.presupuestoVenta.listaPrecioVenta = lista;

            if (vm.presupuestoVenta.moneda.codigo == "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                //si el presupuesto es en gs y la lista en moneda extranjera
                vm.mostrarCambioPactado = true;
                vm.monedaLocal.$promise.then(function (data) {
                    if (vm.presupuestoVenta.monedaLista && vm.presupuestoVenta.monedaLista.descripcion != data[0].valorTipo.descripcion) {
                        vm.cotizacionRequerida = true;

                        var params = {};
                        params.search = filterFactory.and([{
                            path: 'monedaDesde.id',
                            equals: vm.presupuestoVenta.monedaLista.id
                        },
                        {
                            path: 'monedaHasta.id',
                            equals: vm.monedaLocal[0].valorTipo.id
                        },
                        {
                            path: 'fecha',
                            like: moment(vm.presupuestoVenta.fecha).format('DD/MM/YYYY')
                            //equals:vm.comprobante.fecha
                        }
                        ]).value();
                        params.view = 'CotizacionMonedaList';
                        CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                            if (response && response[0]) {
                                vm.presupuestoVenta.cambioPactado = response[0].valorCompra;
                            } else {
                                vm.presupuestoVenta.cambioPactado = null;
                            }
                        });
                    } else {
                        vm.cotizacionRequerida = false;
                        vm.presupuestoVenta.cambioPactado = 1;
                    }
                });
            } else {
                vm.mostrarCambioPactado = false;
            }

            if (vm.presupuestoVenta.listaPrecioVenta && vm.utilizarPrecioBase) {
                vm.porcRecargo = vm.presupuestoVenta.listaPrecioVenta.porcentajeRecargo;
                vm.precioBase = vm.presupuestoVenta.listaPrecioVenta.precioBase.descripcion;
            }
            vm.actualizarPreciosDeVentas();

        });
    }

    function changePrecioCambioPactado() {
        actualizarPreciosDeVentas();
    }

    function changeUnidadAux(detalle, index) {
      if (vm.presupuestoVenta.presupuestoVentaDetalle) {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
          path: 'listaPrecioVenta.id',
          equals: vm.presupuestoVenta.listaPrecioVenta.id,
        },
        {
          path: 'producto.id',
          equals: detalle.producto.id,
        }]).value();
        staticFilter.view="PresupuestoVentaForm";
        ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
          if (response.length > 0) {
            changeUnidad(detalle, index, response);
          }else{
            changeUnidad(detalle);
          }
        });
      } else {
        changeUnidad(detalle);
      }
    }

    function controlPrecioMinimoMaximos(detalle,index) {
      var productoLista = [];
      var precioMinimo, precioMaximo;
      var name = "precio_" + index;

      if(vm.presupuestoVenta.listaPrecioVenta){
        if (index != undefined) {
          var msg;
          $scope.PresupuestoVentaForm[name].$setValidity("error", true);
          if (vm.precioMinimoMaximos[index] && vm.precioMinimoMaximos[index].precioMinimo && detalle.precio < vm.precioMinimoMaximos[index].precioMinimo) {
            msg = "El precio no puede ser menor al precio mínimo de la Lista " + vm.precioMinimoMaximos[index].precioMinimo;
            vm.errores.splice(index,1,msg);
            $scope.PresupuestoVentaForm[name].$error.pattern = true;
            $scope.PresupuestoVentaForm[name].$setValidity("error", false);
            $scope.PresupuestoVentaForm[name].$touched = true;
          } else if (vm.precioMinimoMaximos[index] && vm.precioMinimoMaximos[index].precioMaximo && detalle.precio > vm.precioMinimoMaximos[index].precioMaximo) {
            msg = "El precio no puede ser mayor al precio máximo de la Lista " + vm.precioMinimoMaximos[index].precioMaximo;
            vm.errores.splice(index,1,msg);
            $scope.PresupuestoVentaForm[name].$error.pattern = true;
            $scope.PresupuestoVentaForm[name].$setValidity("error", false);
            $scope.PresupuestoVentaForm[name].$touched = true;
          } else {
            msg = "";
            vm.errores.splice(index,1,msg);
            $scope.PresupuestoVentaForm[name].$error = {};
            $scope.PresupuestoVentaForm[name].$invalid = false;
          }
        }
      }
    }

    function compararDescendente(a, b) {
      return b.id - a.id;
    }

    function controlPrecioUltimoCosto(detalle, index) {
      var filtroCosto = {};
      var name = "precio_" + index;
      var precioVentaAux,unidadMedidaNew,cantidadContenida,costoActual,ultimoCostoAux,ultimoCostoAlmacen;
      var ultimoCostoSucursalList = [];

      // if(vm.parametroAdministrarCostoPor == 'por_almacen'){
      //   ultimoCostoAlmacen = detalle.producto.ultimoCostoAlmacenList.find(function(ultimoCosto){
      //     return ultimoCosto.almacen.id == vm.comprobante.deposito.id;
      //   });
      // }else if(vm.parametroAdministrarCostoPor == 'por_sucursal'){
      //   ultimoCostoAlmacen = detalle.producto.ultimoCostoAlmacenList.find(function(ultimoCosto){
      //     if(ultimoCosto.almacen.id == vm.comprobante.deposito.id && ultimoCosto.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id){
      //       return ultimoCosto;
      //     }
      //   });
      // }else{
      //   var listaUltimosCostos = detalle.producto.ultimoCostoAlmacenList;
      //   listaUltimosCostos.sort(compararDescendente);
      //   var listaObjetosOrdenada = $filter('orderBy')(listaUltimosCostos, '-id');
      //   if(listaUltimosCostos.length > 0){
      //     ultimoCostoAlmacen = listaUltimosCostos[0];
      //   }
      // }
      //Se recupera el ultimo registro de ultimoCosto del producto , ya que no se tiene criterio para buscar por almacen
      if(vm.parametroAdministrarCostoPor == 'por_almacen' || vm.parametroAdministrarCostoPor == 'por_sucursal'){
        ultimoCostoAlmacen = detalle.producto.ultimoCostoAlmacenList.find(function(ultimoCosto){
          if(ultimoCosto.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id){
            ultimoCostoSucursalList.push(ultimoCosto);
          }
        });
        var listaUltimoCostoSucursalOrde = $filter('orderBy')(ultimoCostoSucursalList, ['-fecha', '-id']);
        if(listaUltimoCostoSucursalOrde.length > 0){
          ultimoCostoAlmacen = listaUltimoCostoSucursalOrde[0];
        }
      }else{
        var listaUltimosCostos = detalle.producto.ultimoCostoAlmacenList;
        listaUltimosCostos.sort(compararDescendente);
        var listaObjetosOrdenada = $filter('orderBy')(listaUltimosCostos, ['-fecha', '-id']);
        if(listaObjetosOrdenada.length > 0){
          ultimoCostoAlmacen = listaObjetosOrdenada[0];
        }
      }

      if(ultimoCostoAlmacen != undefined && ultimoCostoAlmacen != null){
        if(vm.parametroAdministracionCosto == 'costo_promedio_almacen'){
          ultimoCostoAux = ultimoCostoAlmacen.costoPromedio;
        }else{
          ultimoCostoAux = ultimoCostoAlmacen.ultimoCosto;
        }
      }else{
        ultimoCostoAux = 0;
      }

      precioVentaAux = detalle.precio;
      // ultimoCostoAux = detalle.producto.ultimoCostoIva;

      if (ultimoCostoAux != undefined) {
        ultimoCostoAux = parseFloat(ultimoCostoAux).toFixed(2);
        unidadMedidaNew = detalle.unidadMedida;
        cantidadContenida = unidadMedidaNew.cantidad;
        costoActual = ultimoCostoAux;
        if (cantidadContenida != undefined && cantidadContenida != null) {
          ultimoCostoAux = Math.round(costoActual * cantidadContenida);
        }
      }

      if (vm.presupuestoVenta.moneda.codigo != "guaranies") {
        vm.ultimoCostoIvaAux = ultimoCostoAux/vm.presupuestoVenta.tipoCambio;
        vm.ultimoCostoIvaAux = Math.round(vm.ultimoCostoIvaAux * 100) / 100;
      } else {
        vm.ultimoCostoIvaAux = ultimoCostoAux;
      }

      if (index != undefined) {
        var msg;
        $scope.PresupuestoVentaForm[name].$setValidity("error", true);
        if (precioVentaAux < vm.ultimoCostoIvaAux && vm.verPrecio != 'No') {
          msg = "El precio no puede ser menor al último costo";
          if(vm.verCostoControl != "No"){
            msg = msg + " : " + vm.ultimoCostoIvaAux;
          }
          vm.errores.splice(index,1,msg);
          $scope.PresupuestoVentaForm[name].$error.pattern = true;
          $scope.PresupuestoVentaForm[name].$setValidity("error", false);
          $scope.PresupuestoVentaForm[name].$touched = true;
        } else {
          msg = "";
          vm.errores.splice(index,1,msg);
          $scope.PresupuestoVentaForm[name].$error = {};
          $scope.PresupuestoVentaForm[name].$invalid = false;
          if (vm.presupuestoVenta.listaPrecioVenta) {
            controlPrecioMinimoMaximos(detalle, index);
          }
        }
      }
    }

    function delayRequestCliente(searchValue) {
      var userInputFilter = {}
      if (searchValue.length >= 3) {
        var filterNombre = [];
        var filterNombreFantasia = [];
        var filterCodigo = [];
        var filterNumero = [];
        var filterDireccion = [];
        var filterCiudad = [];

        filterNombre.push({
          path: "nombre",
          like: searchValue
        })
        filterNombre.push({
          path: "activo",
          equals: true
        })
        filterNombreFantasia.push({
          path: "nombreFantasia",
          like: searchValue
        })
        filterNombreFantasia.push({
          path: "activo",
          equals: true
        })
        filterCodigo.push({
          path: "codigo",
          like: searchValue
        })
        filterCodigo.push({
          path: "activo",
          equals: true
        })
        filterNumero.push({
          path: "numero",
          like: searchValue
        })
        filterNumero.push({
          path: "activo",
          equals: true
        })
        filterDireccion.push({
          path: "direccion",
          like: searchValue
        })
        filterDireccion.push({
          path: "activo",
          equals: true
        })
        filterCiudad.push({
          path: "ciudad.descripcion",
          like: searchValue
        })
        filterCiudad.push({
          path: "activo",
          equals: true
        })

        userInputFilter = {
          search: filterFactory.or([
            filterFactory.and(filterNombre).value(),
            filterFactory.and(filterNombreFantasia).value(),
            filterFactory.and(filterCodigo).value(),
            filterFactory.and(filterNumero).value(),
            filterFactory.and(filterDireccion).value(),
            filterFactory.and(filterCiudad).value()
          ]).value()
        }

        recuperarCliente(userInputFilter)
      } else {
        vm.clientes = undefined
      }
    }
    function delayRequestClienteMobile(searchValue) {
      var userInputFilter = {}
      if (searchValue.length >= 3) {
        var filterNombre = [];
        var filterNombreFantasia = [];

        filterNombre.push({
          path: "nombre",
          like: searchValue
        })
        filterNombre.push({
          path: "activo",
          equals: true
        })
        filterNombreFantasia.push({
          path: "nombreFantasia",
          like: searchValue
        })
        filterNombreFantasia.push({
          path: "activo",
          equals: true
        })

        userInputFilter = {
          search: filterFactory.or([
            filterFactory.and(filterNombre).value(),
            filterFactory.and(filterNombreFantasia).value()
          ]).value()
        }

        recuperarCliente(userInputFilter)
      } else {
        vm.clientes = undefined
      }
    }
    function delayRequestDocumentoMobile(searchValue) {
      var userInputFilter = {}
      if (searchValue.length >= 6) {
        var filterNumero = [];

        filterNumero.push({
          path: "numero",
          like: searchValue
        })
        filterNumero.push({
          path: "activo",
          equals: true
        })

        userInputFilter = {
          search: filterFactory.or([
            filterFactory.and(filterNumero).value()
          ]).value()
        }

        recuperarCliente(userInputFilter)
      } else {
        vm.clientes = undefined
      }
    }

    function recuperarCliente(userInputFilter) {
      var finalFilter = UtilFactory.createNestedFilters(
        vm.finalFilters, userInputFilter)

      ClienteFactory.allForSelect(finalFilter).$promise.then(function(clientes){
        var clie = [];
        for(var i = 0; i < clientes.length; i++){
          if(clientes[i].bloquearVentas !== true){
            clie.push(clientes[i])
          }
        }
        vm.clientes = clie;
      })
    }

    function searchClienteFilter(criteria) {
      return function (item) {
        if (!criteria) {
          return true;
        }
        return (
          item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
          (item.nombreFantasia && item.nombreFantasia.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
          (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
          item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
          (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
          (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
        );
      };
    }

  function recuperarPreciosMinimosMaximos() {
    if (vm.presupuestoVenta.presupuestoVentaDetalle.length > 0 && vm.presupuestoVenta.presupuestoVentaDetalle[0].producto) {
      if (vm.presupuestoVenta.listaPrecioVenta) {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
          path: 'listaPrecioVenta.id',
          equals: vm.presupuestoVenta.listaPrecioVenta.id,
        }]).value();
        staticFilter.view = "PrecioMinimoMaximoForm";
        vm.uiBlockuiConfig.bloquear=true;
        ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (lista) {
          vm.uiBlockuiConfig.bloquear=false;
          if (lista != undefined || lista.length > 0) {
            for (var j = 0; j < vm.presupuestoVenta.presupuestoVentaDetalle.length; j++) {
              var productoLista;
              productoLista = _.find(lista, function (detalleLP) {
                return vm.presupuestoVenta.presupuestoVentaDetalle[j].producto.id === detalleLP.producto.id &&
                  vm.presupuestoVenta.presupuestoVentaDetalle[j].unidadMedida.id === detalleLP.unidadMedida.id
              });
              var precioMinimo, precioMaximo;
              if (productoLista) {
                if (vm.presupuestoVenta.moneda.codigo == "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo != "guaranies") {
                  precioMinimo = UtilFactory.roundForMoneda(productoLista.precioMinimo * vm.presupuestoVenta.cambioPactado, vm.presupuestoVenta.moneda);
                  precioMaximo = UtilFactory.roundForMoneda(productoLista.precioMaximo * vm.presupuestoVenta.cambioPactado, vm.presupuestoVenta.moneda);
                } else if (vm.presupuestoVenta.moneda.codigo != "guaranies" && vm.presupuestoVenta.listaPrecioVenta.moneda.codigo == "guaranies") {
                  var precioMin = productoLista.precioMinimo / vm.presupuestoVenta.tipoCambio;
                  var precioMax = productoLista.precioMaximo / vm.presupuestoVenta.tipoCambio;
                  precioMinimo = Math.round(precioMin * 100) / 100;
                  precioMaximo = Math.round(precioMax * 100) / 100;
                } else {
                  precioMinimo = productoLista.precioMinimo;
                  precioMaximo = productoLista.precioMaximo;
                }
                var obj = {
                  "precioMinimo": precioMinimo,
                  "precioMaximo": precioMaximo
                };
                vm.precioMinimoMaximos.splice(j, 1, obj);
              }
            }
          }
        });
      }
    }
  }

  function changeTipoVenta() {
    vm.presupuestoVenta.formaPago = null;
    vm.presupuestoVenta.cantidadCuotas = null;
    filterFormasPago();
  }

  function filterFormasPago() {
    if(vm.presupuestoVenta.cliente){
      if(vm.presupuestoVenta.condicionVenta.codigo === "condicion_cobro_pedido_venta_contado"){
        vm.formasPagos = _.filter(vm.formasPagosAux, function (formaPago) {
          return formaPago.cantidadCuotas == null;
        });
      } else if(vm.presupuestoVenta.condicionVenta.codigo === "condicion_cobro_pedido_venta_credito"){
        vm.formasPagos = _.filter(vm.formasPagosAux, function (formaPago) {
          return formaPago.cantidadCuotas != null;
        });
      }
    }
  }
    $scope.resource = 'presupuestoventas';
}

'use strict';


angular.module('qualita.venta')
    .controller('PresupuestoVentaListCtrl',
        ["$scope", "PresupuestoVentaFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "VentasLangFactory", "VentaFactory", "WatchLocacionChangeFactory", "$filter", "notify", "PeriodoAdministrativoFactory", "UtilFactory", "CsvFactory", "baseurl", "$q", function ($scope, PresupuestoVentaFactory, filterFactory, AuthorizationService,
            ReportTicketFactory, $modal, $window, $state, VentasLangFactory, VentaFactory,
            WatchLocacionChangeFactory, $filter, notify, PeriodoAdministrativoFactory,
            UtilFactory, CsvFactory, baseurl, $q) {

            var vm = this;
            vm.isProcesoVenta = false;
            var proceso = false;
            // var staticFilter = {
            //     search: filterFactory.and([{
            //         path: 'id',
            //         sortDesc: 'true'
            //     }]).value()
            // };
            var staticFilter = {};
            var presupuestoVentaActual = AuthorizationService.hasPermission("listar_presuventa_usuario_actual");

            var getIDs = function () {
                var ids = [];
                _.forEach(scope.options.selection, function (n, key) {
                    if (n)
                        ids.push(key);
                });
                return ids;
            }

            var renderEmptyData = function (data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            var monedaRender = function (data, type, row) {
                var campoMoneda = row.moneda;

                if (data != undefined) {
                    var moneda = "Gs. ";
                    if (campoMoneda.codigo === "dolares") {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "pesos") {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "real") {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "euro") {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    } else {
                        data = parseFloat(data).toFixed(0);
                    }
                    // TODO hacer esto mismo en todos los renders de moneda
                    return (
                        moneda +
                        data
                            .toString()
                            .replace(".", ",")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                } else {
                    return "";
                }
            };

         $scope.$watch('AuthParams.accesoSistema.usuario.id',function(newVal){
          if(newVal){     
            VentasLangFactory.getTranslations().then(function (translations) {
                var defaultColumnOrder = ['id', 'fecha', 'fechaVencimiento', 'montoTotal', 'estado.descripcion', 'cliente.nombre',
                    'sucursalCliente.descripcion', 'moneda.descripcion', 'condicionVenta.descripcion', 'cantidadCuotas', 'aprobadoPor',
                    'motivoRechazo', 'rechazadoPor', 'motivoCierre', 'motivoAnulacion', 'listaPrecioVenta.descripcion'
                ];
                $scope.options = {
                    // 'staticFilter': staticFilter,
                    'resource': 'presupuestoventas',
                    'title': 'Presupuestos de Ventas',
                    'factory': PresupuestoVentaFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'view': 'PresupuestoVentaList',
                    'columns': [
                        { 'data': 'id', 'title': $filter('translate')('NUMERO'), 'class': 'dt-center' },
                        { 'data': 'fecha', 'title': $filter('translate')('FECHA'), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                        { 'data': 'fechaVencimiento', 'title': $filter('translate')('FECHA_VENCIMIENTO'), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                        { 'data': "montoTotal", 'title': $filter("translate")("MONTO"), 'renderWith': monedaRender, class: "dt-right" },
                        { 'data': 'estado.descripcion', 'title': translations.STATUS, 'renderWith': 'emptyRender', type: "combo", filterUrl: "tipos/filtro/estado_presupuesto_venta" },
                        { 'data': 'cliente.nombre', 'title': translations.CLIENTE },
                        { 'data': 'sucursalCliente.descripcion', 'title': translations.SUCURSAL_CLIENTE, renderWith: renderEmptyData },
                        { 'data': 'moneda.descripcion', 'title': translations.CURRENCY, 'renderWith': 'emptyRender' },
                        { 'data': 'condicionVenta.descripcion', 'title': $filter("translate")("CONDICION"), 'renderWith': 'emptyRender' },
                        { 'data': 'cantidadCuotas', 'title': translations.CANTIDAD_CUOTAS, class: "dt-right", 'renderWith': 'emptyRender' },
                        { 'data': 'aprobadoPor', 'title': 'Aprobado Por', renderWith: renderEmptyData },
                        { 'data': 'motivoRechazo', 'title': $filter("translate")("MOTIVO_RECHAZO"), 'renderWith': 'emptyRender', visible: true },
                        { 'data': 'rechazadoPor', 'title': 'Rechazado Por', renderWith: renderEmptyData },
                        { 'data': 'motivoCierre', 'title': $filter("translate")("MOTIVO_CIERRE"), 'renderWith': 'emptyRender', visible: false },
                        { 'data': 'motivoAnulacion', 'title': $filter("translate")("MOTIVO_ANULACION"), 'renderWith': 'emptyRender', visible: false },
                        { 'data': 'listaPrecioVenta.descripcion', 'title': 'Lista de Precios', 'class': 'dt-center', 'renderWith': 'emptyRender' }

                    ],
                    'hasOptions': true,
                    'defaultOrderColumn': 0,
                    'defaultOrderDir': "desc",
                    'hideAddMenu': true,
                    'hideEditMenu': true,
                    'hideRemoveMenu': true,
                    'hideViewMenu': true,
                    'extraMenuOptions':
                        [
                            {
                                title: $filter("translate")("NEW"),
                                icon: "glyphicon glyphicon-plus",
                                showCondition: function () {
                                    return true;
                                },
                                action: function () {
                                    if (AuthorizationService.hasPermission("create_presupuestoventas") == true) {
                                        $state.go("app.presupuestoventas.new");
                                    } else {
                                        var msg = 'No posee permiso para crear Presupuestos de Ventas';
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                }
                            },
                            {
                                'title': 'CSV',
                                'icon': 'glyphicon glyphicon-export',
                                'showCondition': function () { return true; },
                                'action': function () {
                                    var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                    CsvFactory.csv("presupuestoventas", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                        .then(function (response) {
                                            console.log("response de csv: ", response);
                                            var fileName = response.data.fileName;
                                            $window.open(baseurl.getPublicBaseUrl() + fileName);
                                        });
                                }
                            },
                            {
                                'title': 'Reporte',
                                'icon': 'glyphicon glyphicon-file',
                                'showCondition': function () { return true; },
                                'action': function () {
                                    var filters = createFilters($scope.options.getFilters());
                                    ReportTicketFactory.ticket('listadoPresupuestoVenta', filters, $scope.options.tableAjaxParams,
                                        $scope.options.currentColumnOrder).then(function (genTicket) {
                                            $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                            $window.open($scope.pdfDownloadURL, '_blank');
                                        });
                                }
                            }
                        ],
                    'extraRowOptions': [
                        {
                            templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                            customAttribute: ["id", "estado"],
                            conditionName: 'canEditar',
                            conditionDef: function (atributos) {
                                var estado = atributos[1];
                                if (AuthorizationService.hasPermission("update_presupuestoventas") === true && estado.codigo === 'prv_ingresado') {
                                    return true;

                                } else {
                                    return false;
                                }
                            },
                            functionName: "editar",
                            functionDef: function (atributos) {
                                var itemId = atributos[0];
                                if (AuthorizationService.hasPermission("update_presupuestoventas") === true) {
                                    $state.go('app.presupuestoventas.edit', { id: itemId });
                                } else {
                                    notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            templateToRender: "<button title='" +
                                'Rechazar' +
                                "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='rechazar(<%=dataId%>)' ng-class='{ hidden : !canRechazar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ban-circle'></span> </button>",
                            functionName: "rechazar",
                            conditionName: "canRechazar",
                            customAttribute: ["estado"],
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                if (AuthorizationService.hasPermission("rechazar_presupuestoventas") === true && estado.codigo === 'prv_ingresado') {
                                    return true;
                                } else {
                                    return false;
                                }
                            },

                            functionDef: function (itemId) {
                                if (AuthorizationService.hasPermission("rechazar_presupuestoventas") === true) {
                                    PresupuestoVentaFactory.get(itemId, "PresupuestoVentaForm").$promise.then(function (response) {
                                        if (response) {
                                            $scope.mensajeModal = 'Esta seguro de querer rechazar el Presupuesto de Venta?';
                                            $scope.tituloModal = 'Rechazar';
                                            var modalInstance = $modal.open({
                                                templateUrl: "views/directives/confirmation-modal.html",
                                                scope: $scope
                                            });

                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };

                                            $scope.ok = function (id) {
                                                $scope.mensajeModal = 'Motivo del Rechazo: ';
                                                $scope.tituloModal = 'Rechazo del Presupuesto';
                                                $scope.config = "";
                                                vm.rechazoVacio = false; 
                                                var modalInstance4 = $modal.open({
                                                    templateUrl: 'qualita-components/ventas/views/presupuestoventa/autorizacion-modal.html',
                                                    scope: $scope
                                                });

                                                $scope.cancel = function () {
                                                    modalInstance.dismiss('cancel');
                                                    modalInstance4.dismiss('cancel');
                                                    vm.rechazoVacio = false;
                                                };

                                                $scope.ok = function (id, config) {
                                                    if (config != ""){
                                                        PresupuestoVentaFactory.rechazar(itemId).then(function (response) {
                                                       
                                                            response.motivoRechazo = config;
                                                            PresupuestoVentaFactory.save(response);
                                                            vm.rechazoVacio = false; 
                                                            modalInstance.close();
                                                            modalInstance4.close();
                                                            $state.go($state.current, {}, { reload: true });
                                                        
                                                       
                                                        }, function (error) {
                                                            var mensaje = "<span>";
                                                            _.forEach(error.data, function (err) {
                                                                mensaje = mensaje + err.message + "<br/>";
                                                            });
                                                            mensaje = mensaje + "</span>";
                                                            notify({
                                                                messageTemplate: mensaje,
                                                                classes: "alert-danger",
                                                                position: "right",
                                                                templateUrl: ""
                                                            });
                                                            vm.rechazoVacio = false; 
                                                        });

                                                    }else{
                                                        vm.rechazoVacio = true; 
                                                    }
                                                    
                                                }
                                            };
                                        }
                                    });
                                } else {
                                    notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            templateToRender: "<button title='" +
                                'Anular' +
                                "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataId%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                            functionName: "anular",
                            conditionName: "canAnular",
                            customAttribute: ["estado"],
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                if (AuthorizationService.hasPermission("anular_presupuestoventas") === true && (estado.codigo === 'prv_aprobado' || estado.codigo === 'prv_procesado_parcial')) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                            functionDef: function (itemId) {
                                if (AuthorizationService.hasPermission("anular_presupuestoventas") === true) {
                                    PresupuestoVentaFactory.get(itemId).$promise.then(function (response) {
                                        $scope.mensajeModal = $filter('translate')('ANULAR_PRESUPUESTO_MENSAJE');

                                        $scope.tituloModal = $filter('translate')('ANULAR_PRESUPUESTO');
                                        var modalInstance = $modal.open({
                                            templateUrl: 'views/directives/confirmation-modal.html',
                                            scope: $scope
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss('cancel');
                                        };

                                        $scope.ok = function (id) {
                                            $scope.mensajeModal = 'Motivo del Anulación: ';
                                            $scope.tituloModal = 'Anulación del Presupuesto';
                                            $scope.config = "";
                                            vm.rechazoVacio = false;
                                            var modalInstance4 = $modal.open({
                                                templateUrl: 'qualita-components/ventas/views/presupuestoventa/autorizacion-modal.html',
                                                scope: $scope
                                            });

                                            $scope.cancel = function () {
                                                modalInstance.dismiss('cancel');
                                                modalInstance4.dismiss('cancel');
                                                vm.rechazoVacio = false;
                                            };

                                            $scope.ok = function (id, config) {
                                                if (config != ""){
                                                    vm.rechazoVacio = false; 
                                                    PresupuestoVentaFactory.anular(itemId).then(function (response) {
                                                        response.motivoAnulacion = config;
                                                        PresupuestoVentaFactory.save(response);
                                                        vm.rechazoVacio = false;
                                                        modalInstance.close();
                                                        $state.go($state.current, {}, { reload: true });
                                                    }, function (error) {
                                                        //console.log(error);
                                                        vm.rechazoVacio = false; 
                                                        var mensaje = "<span>";
                                                        _.forEach(error.data, function (err) {
                                                            mensaje = mensaje + err.message + "<br/>";
                                                        });
                                                        mensaje = mensaje + "</span>";
                                                        notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                    });
                                                }else{
                                                    vm.rechazoVacio = true;
                                                }
                                            };
                                        }
                                    });
                                } else {
                                    notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            templateToRender: "<button title='" + $filter('translate')('APROBAR_PRESUPUESTO') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataCustom%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
                            functionName: "aprobar",
                            conditionName: "canAprobar",
                            customAttribute: ["estado", "id"],
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                if (AuthorizationService.hasPermission("aprobar_presupuestoventas") === true && estado.codigo == "prv_ingresado") {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                            functionDef: function (atributos) {
                                var estado = atributos[0];
                                var itemId = atributos[1];

                                if (AuthorizationService.hasPermission("aprobar_presupuestoventas") === true) {
                                    PresupuestoVentaFactory.get(itemId).$promise.then(function (response) {
                                        $scope.mensajeModal = $filter('translate')('APROBAR_PRESUPUESTO_MENSAJE');
                                        $scope.tituloModal = $filter('translate')('APROBAR_PRESUPUESTO');

                                        var modalInstance = $modal.open({
                                            templateUrl: 'views/directives/confirmation-modal.html',
                                            scope: $scope
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss('cancel');
                                        };

                                        $scope.ok = function (id) {
                                            PresupuestoVentaFactory.aprobar(itemId).then(function (response) {
                                                var filtersArr = [];
                                                filtersArr.push("idpresupuestoventa=" + itemId);
                                                modalInstance.close();
                                                $state.go($state.current, {}, { reload: true });
                                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                    "presupuestoventa",
                                                    "pdf",
                                                    filtersArr
                                                );
                                                $window.open(vm.pdfDownloadURL, "_blank");
                                                $state.go($state.current, {}, { reload: true });
                                            }, function (error) {
                                                var mensaje = "<span>";
                                                _.forEach(error.data, function (err) {
                                                    mensaje = mensaje + err.message + "<br/>";
                                                });
                                                mensaje = mensaje + "</span>";
                                                notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                            });
                                        }
                                    });
                                } else {
                                    notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            templateToRender: "<button title='" +
                                $filter("translate")("IMPRIMIR_PRESUPUESTO") +
                                "' class='btn btn-info btn-dt' style='margin-right: 5px;' ng-click='imprimir(<%=dataCustom%>)' ng-class='{ hidden : !canImprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-print'></span> </button>",
                            functionName: "imprimir",
                            conditionName: "canImprimir",
                            customAttribute: ["estado", "id"],
                            conditionDef: function (atributos) {
                                return true;
                            },
                            functionDef: function (atributos) {
                                var itemId = atributos[1];

                                $scope.mensajeModal = $filter("translate")("IMPRIMIR_PRESUPUESTO_MSG");
                                $scope.tituloModal = $filter("translate")("IMPRIMIR_PRESUPUESTO");
                                var modalInstance = $modal.open({
                                    templateUrl: "views/directives/confirmation-modal.html",
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss("cancel");
                                };

                                $scope.ok = function (id) {
                                    var filtersArr = [];
                                    filtersArr.push("idpresupuestoventa=" + itemId);
                                    modalInstance.close();
                                    $state.go($state.current, {}, { reload: true });
                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                        "presupuestoventa",
                                        "pdf",
                                        filtersArr
                                    );
                                    $window.open(vm.pdfDownloadURL, "_blank");
                                    $state.go($state.current, {}, { reload: true });
                                }
                            }
                        },
                        {
                            templateToRender: "<button title='" + $filter('translate')('CERRAR_PRESUPUESTO') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='cerrar(<%=dataId%>)' ng-class='{ hidden : !aCerrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-check'></span> </button>",
                            functionName: "cerrar",
                            conditionName: "aCerrar",
                            customAttribute: ["estado"],
                            conditionDef: function (atributos) {
                                var estado = atributos[0];
                                if (AuthorizationService.hasPermission("cerrar_presupuestoventas") === true && estado.codigo === 'prv_procesado_parcial') {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                            functionDef: function (itemId) {
                                if (AuthorizationService.hasPermission("cerrar_presupuestoventas") === true) {
                                    PresupuestoVentaFactory.get(itemId).$promise.then(function (response) {
                                        $scope.mensajeModal = $filter('translate')('CERRAR_PRESUPUESTO_MENSAJE');

                                        $scope.tituloModal = $filter('translate')('CERRAR_PRESUPUESTO');
                                        var modalInstance = $modal.open({
                                            templateUrl: 'views/directives/confirmation-modal.html',
                                            scope: $scope
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss('cancel');
                                        };

                                        $scope.ok = function (id) {
                                            $scope.mensajeModal = 'Motivo del Cierre: ';
                                            $scope.tituloModal = 'Cierre del Presupuesto';
                                            $scope.config = "";
                                            vm.rechazoVacio = false;
                                            var modalInstance4 = $modal.open({
                                                templateUrl: 'qualita-components/ventas/views/presupuestoventa/autorizacion-modal.html',
                                                scope: $scope
                                            });

                                            $scope.cancel = function () {
                                                modalInstance.dismiss('cancel');
                                                modalInstance4.dismiss('cancel');
                                                vm.rechazoVacio = false;
                                            };

                                            $scope.ok = function (id, config) {
                                                if (config != ""){
                                                    vm.rechazoVacio = false; 
                                                    PresupuestoVentaFactory.cerrar(itemId).then(function (response) {
                                                        response.motivoCierre = config;
                                                        PresupuestoVentaFactory.save(response);
                                                        vm.rechazoVacio = false;
                                                        modalInstance.close();
                                                        $state.go($state.current, {}, { reload: true });
                                                    }, function (error) {
                                                        //console.log(error);
                                                        vm.rechazoVacio = false; 
                                                        var mensaje = "<span>";
                                                        _.forEach(error.data, function (err) {
                                                            mensaje = mensaje + err.message + "<br/>";
                                                        });
                                                        mensaje = mensaje + "</span>";
                                                        notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                    });
                                                }else{
                                                    vm.rechazoVacio = true;
                                                }
                                            };
                                        }
                                    });
                                } else {
                                    notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                                $filter("translate")("VIEW") +
                                "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                            functionName: "ver",
                            functionDef: function (itemId) {
                                if (AuthorizationService.hasPermission("index_presupuestoventas") === true) {
                                    $state.go("app.presupuestoventas.view", { id: itemId });
                                } else {
                                    notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                                }
                            },
                            conditionName: "canVer",
                            conditionDef: function () {
                                return true;
                            }
                        }
                    ],
                    'extraRemoveConditions': function (item) {
                        if (item != undefined)
                            return PresupuestoVentaFactory.hasPermisos("remove", item.estado);
                    },
                    'extraEditConditions': function (item) {
                        if (item != undefined)
                            return PresupuestoVentaFactory.hasPermisos("edit", item.estado);
                    },
                };

                if ($state.is("app.presupuestoventas.list")) {

                    if(presupuestoVentaActual){
                      staticFilter.search = filterFactory.and([
                          {
                          path: 'id',
                          sortDesc: 'true'
                          },
                          {
                          path: 'usuario.id',
                          equals: newVal
                          }]).value();
                      }else{
                          staticFilter.search = filterFactory.and([
                              {
                              path: 'id',
                              sortDesc: 'true'
                              }]).value();
                      }      
          
                      $scope.options.staticFilter = staticFilter;
                  }
          

                //WatchLocacionChangeFactory.watchWith(staticFilter, $scope.options, "locacion.id");
            });
         
           }
        })
 


            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    search = search.split("~");
                    if (search.length > 1) {
                        /* El parámetro es un rago */

                        var isDate = search[0].split("/");

                        if (isDate.length > 1) {
                            /* Es un rago de fechas */

                            filtersArr.push({ path: data, equalOrAfter: search[0] })
                            filtersArr.push({ path: data, equalOrBefore: search[1] })
                        } else {
                            /* El un rago numérco */

                            filtersArr.push({ path: data, greaterOrEqual: search[0] })
                            filtersArr.push({ path: data, lessOrEqual: search[1] })
                        }
                    } else {
                        /* El parametro no es un rago */


                        filtersArr.push({ path: data, like: search[0] })
                    }
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }

            var getIDs = function () {
                var ids = [];
                _.forEach($scope.options.selection, function (n, key) {
                    if (n)
                        ids.push(key);
                });
                return ids;
            };

            /* Aca podemos verificar el vencimiento.. creo .. Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
            var checkPeriodoAdministrativo = function (fechaComprobante) {
                return $q(function (resolve, reject) {

                    var params = {};
                    var fecha;

                    if (fechaComprobante == null) {
                        fecha = new Date();
                    } else {
                        fecha = fechaComprobante;
                    }

                    params.search = filterFactory
                        .and([{
                            path: "estado.codigo",
                            equals: "per_adm_abierto"
                        },
                        {
                            path: "fechaDesde",
                            equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                        },
                        {
                            path: "fechaHasta",
                            equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                        }
                        ])
                        .value();

                    PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {
                        if (responsePeriodo.length > 0) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    })
                });
            }


            function nuevoPresupuesto() {
                $state.go("app.presupuestoventas.new");
            }
        }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.presupuestoventa
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module('qualita.venta')
    .factory('PresupuestoVentaFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
        '$translatePartialLoader', '$translate', '$state', '$stateParams', 'notify', 'AuthorizationService',
        function($resource, baseurl, filterFactory, TiposFactory, $http, $translatePartialLoader,
            $translate, $state, $stateParams, notify, AuthorizationService) {

            var PresupuestoVenta = $resource(baseurl.getBaseUrl() + '/presupuestoventas/:id', { id: '@id' }, {
                update: {
                    method: 'PUT'
                },
                aprobar: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/presupuestoventas/aprobar/:id"
                },
                anular: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/presupuestoventas/anular/:id"
                },
                cerrar: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/presupuestoventas/cerrar/:id"
                },
                rechazar: {
                    method: 'PUT',
                    url: baseurl.getBaseUrl() + "/presupuestoventas/rechazar/:id"
                }
            });

            return {
                all: function(params, view) {
                    if (params) {
                        params.view = view!=undefined ? view : 'PresupuestoVentaList';
                    } else {
                        params = [];
                        params.view = view!=undefined ? view : 'PresupuestoVentaList';
                    }
                    return PresupuestoVenta.query(params);
                },

                get: function(id, view) {
                    return PresupuestoVenta.get({ id: id, view: view != undefined ? view : 'base' });
                },

                create: function(attrs) {
                    return new PresupuestoVenta(attrs);
                },

                save: function(presupuestoVenta) {
                    return (presupuestoVenta.id) ? presupuestoVenta.$update() : presupuestoVenta.$save();
                },

                remove: function(presupuestoVenta) {
                    return presupuestoVenta.$remove();
                },

                schema: function() {
                    return schema;
                },

                form: function(type) {
                    return type === 'new' ? formNew : formEdit;
                },

                aprobar: function(id) {
                    return PresupuestoVenta.aprobar({ id: id }).$promise;
                },
                anular: function(id) {
                    return PresupuestoVenta.anular({ id: id }).$promise;
                },
                cerrar: function(id) {
                    return PresupuestoVenta.cerrar({ id: id }).$promise;
                },
                cerrarMultiple: function(ids) {
                    $http({
                        url: baseurl.getBaseUrl() + '/presupuestoventas/cerrar',
                        method: "POST",
                        params: { presupuestos: ids }
                    }).then(function() {
                            $state.transitionTo($state.current, $stateParams, {
                                reload: true,
                                inherit: false,
                                notify: true
                            });
                        },
                        function(e) {
                            if (e && e.status === 500) {
                                var msg = '';
                                _.forEach(e.data, function(error) {
                                    msg += '\n\n' + error.message + ''
                                });
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        });
                },
                hasPermisos: function(action, estado, canAction) {
                    switch (action) {
                        case "confirm":
                            return AuthorizationService.hasPermission('update_presupuestoventas') && estado.codigo == "prv_ingresado"
                        case "anular":
                            return AuthorizationService.hasPermission('update_presupuestoventas') && canAction == "Sí";
                        case "cerrar":
                            return AuthorizationService.hasPermission('update_presupuestoventas') && canAction == "Sí";
                        case "view":
                            return (AuthorizationService.hasPermission('index_presupuestoventas'));
                        case "remove":
                            return AuthorizationService.hasPermission('delete_presupuestoventas') && estado.codigo == "prv_ingresado";
                        case "edit":
                            return AuthorizationService.hasPermission('update_presupuestoventas') && estado.codigo == "prv_ingresado";
                    }
                },
                rechazar: function(id) {
                    return PresupuestoVenta.rechazar({ id: id}).$promise;
                }
            };
        }
    ]);
'use strict';


angular.module('qualita.venta')
    .controller('ListaPrecioVentaIndexCtrl', ["$state", "$scope", "ListaPrecioVentaFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "$modal", "TiposFactory", "TiposTipoFactory", "ClienteFactory", "notify", "ModelTrimmer", "TipoClienteFactory", "ParametrosFactory", "$rootScope", function ($state, $scope, ListaPrecioVentaFactory, filterFactory,
        ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory,
        baseurl, AuthorizationService, $modal, TiposFactory, TiposTipoFactory, ClienteFactory, notify,
        ModelTrimmer, TipoClienteFactory, ParametrosFactory, $rootScope) {

        var vm = this;
        vm.listaPrecioVenta = {};
        vm.addNewListaPrecioVenta = addNewListaPrecioVenta;
        vm.sendNewListaPrecioVenta = sendNewListaPrecioVenta;
        vm.tiposEntidades = TiposTipoFactory.get("tipo_entidad");
        vm.monedas = TiposTipoFactory.get("monedas");
        vm.tipoEntidadSelected = tipoEntidadSelected;
        vm.preciosBase = TiposTipoFactory.get("precio_base");
        vm.factorRedondeo = TiposTipoFactory.get("factor_redondeo");
        vm.estados = TiposTipoFactory.get("estado_lista_precio_venta");
        vm.monedaSelected = monedaSelected;
        vm.validarCamposModal = validarCamposModal;
        vm.redirectListaPrecioVenta = redirectListaPrecioVenta;
        ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.utilizarPrecioBase = false;
            } else {
                vm.utilizarPrecioBase = true;
            }
        });
        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            var defaultColumnOrder = ['id', 'tipoEntidad.descripcion', 'tipoCliente.descripcion', 'cliente.nombre', 'moneda.descripcion',
                'precioBase.descripcion', 'porcentajeRecargo', 'factorRedondeo.descripcion', 'descripcion', 'estado.descripcion','pedidoVenta'
            ];
            $scope.options = {
                'resource': 'listaprecioventa',
                title: $filter("translate")("LISTA_PRECIO_VENTA"),
                'factory': ListaPrecioVentaFactory,
                'view': 'ListaPrecioVentaList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'id', 'title': translations.ID, visible: false },
                    { 'data': 'tipoEntidad.descripcion', title: $filter("translate")("TIPO_ENTIDAD"), 'class': 'dt-left', 'render': renderEmptyData },
                    { 'data': 'tipoCliente.descripcion', title: $filter("translate")("TIPO_LISTA_PRECIO"), 'render': renderEmptyData },
                    { 'data': 'cliente.nombre', title: $filter("translate")("CLIENTE"), 'render': renderEmptyData },
                    { 'data': 'moneda.descripcion', title: $filter("translate")("CURRENCY"), 'render': renderEmptyData },
                    { 'data': 'precioBase.descripcion', title: $filter("translate")("PRECIO_BASE"), 'render': renderEmptyData },
                    { 'data': 'porcentajeRecargo', title: $filter("translate")("PORC_RECARGO"),'class': 'dt-center' ,'render': renderEmptyData },
                    { 'data': 'factorRedondeo.descripcion', title: $filter("translate")("FACTOR_REDONDEO"), 'render': renderEmptyData },
                    { 'data': 'descripcion', title: $filter("translate")("DESCRIPCION"), renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'estado.descripcion', title: $filter("translate")("ESTADO"), 'class': 'dt-center', 'type': 'combo', 'filterUrl': "tipos/filtro/estado_lista_precio_venta", 'render': renderEmptyData }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideViewMenu': true,
                'hideRemoveMenu': true,
                'extraMenuOptions':
                    [{
                        title: $filter("translate")("NEW"),
                        icon: "glyphicon glyphicon-plus",
                        showCondition: function () {
                            var permission = AuthorizationService.hasPermission(
                                "create_listaprecioventa"
                            );
                            return permission;
                        },
                        action: function () {
                            var listaPrecioVenta = undefined;
                            vm.addNewListaPrecioVenta(listaPrecioVenta, false);
                        }
                    },
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = createFilters($scope.options.getFilters());
                            ReportTicketFactory.ticket('listadoPrecioVenta', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                                vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open(vm.pdfDownloadURL, '_blank');
                            });
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("listaprecioventa", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                .then(function (response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    }], 'extraRowOptions': [
                        {
                            templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                                $filter("translate")("DELETE") +
                                "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                            customAttribute: "estado",
                            conditionName: "canBorrar",
                            conditionDef: function (estado) {
                                return (AuthorizationService.hasPermission("delete_listaprecioventa") && estado.codigo=="lista_activada");
                            },
                            functionName: "borrar",
                            functionDef: function (itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.tituloModal = $filter("translate")("DELETE_CONFIRMATION");
                                $scope.mensajeModal = $filter("translate")("DELETE_WARNING");
                                var modalInstanceBorrar1 = $modal.open({
                                    templateUrl: "views/directives/confirmation-modal.html",
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstanceBorrar1.dismiss("cancel");
                                };

                                $scope.ok = function (itemId) {
                                    var model = ListaPrecioVentaFactory.create({ id: itemId });
                                    ListaPrecioVentaFactory.remove(model).then(
                                        function () {
                                            modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            //$scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = vm.translations.DELETE_FAILED;
                                            $scope.mensajeModal =vm.translations.LISTA_PRECIO_VENTA_DELETE_ERROR;
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                        "</div>" +
                                                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                        '<div class="modal-footer">' +
                                                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                        "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                            console.log("error al borrar: ");
                                            console.log(error);
                                        }
                                    );
                                };
                            }

                        },
                        {
                            templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' title='" + $filter('translate')('EDIT') + "' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                            customAttribute: "estado",
                            conditionName: "canEditar",
                            conditionDef: function (estado) {
                                return (AuthorizationService.hasPermission('update_listaprecioventa') && estado.codigo=="lista_activada");

                            },
                            functionName: "editar",
                            functionDef: function (itemId) {
                                ListaPrecioVentaFactory.get(itemId, "ListaPrecioVentaList").$promise.then(function (listaPrecioVenta) {
                                    if (listaPrecioVenta.utilizaPrecioBase) {
                                        vm.addNewListaPrecioVenta(listaPrecioVenta, false);
                                    } else {
                                        $state.go('app.listaprecioventa.edit', { id: itemId });
                                    }

                                });

                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" +
                                $filter("translate")("VIEW") +
                                "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                            conditionName: "canView",
                            conditionDef: function () {
                                return AuthorizationService.hasPermission("index_listaprecioventa");
                            },
                            functionName: "view",
                            functionDef: function (itemId) {
                                ListaPrecioVentaFactory.get(itemId, "ListaPrecioVentaList").$promise.then(function (listaPrecioVenta) {
                                    if (listaPrecioVenta.utilizaPrecioBase) {
                                        vm.addNewListaPrecioVenta(listaPrecioVenta, true);
                                    } else {
                                        $state.go('app.listaprecioventa.view', { id: itemId });
                                    }

                                });
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-warning btn-dt' style='margin-right: 5px;' title='" +
                                $filter("translate")("DESACTIVAR_LISTA") +
                                "' ng-click='desactivar(<%=dataId%>)' ng-class='{ hidden : !canDesactivar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ban-circle'></span> </button>",
                            customAttribute: "estado",
                            conditionName: "canDesactivar",
                            conditionDef: function (estado) {
                                return (AuthorizationService.hasPermission("desactivar_listaprecioventa") && estado.codigo=="lista_activada");
                            },
                            functionName: "desactivar",
                            functionDef: function (itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.tituloModal = $filter("translate")("DESACTIVATE_CONFIRMATION");
                                $scope.mensajeModal = $filter("translate")("DESACTIVATE_WARNING");
                                var modalInstance = $modal.open({
                                    templateUrl: "views/directives/confirmation-modal.html",
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss("cancel");
                                };

                                $scope.ok = function (itemId) {
                                    ListaPrecioVentaFactory.get(itemId, "ListaPrecioVentaList").$promise.then(function (listaPrecioVenta) {
                                        if (listaPrecioVenta != undefined) {
                                            var estadoInactivo = _.find(vm.estados, function (estado) {
                                                return estado.codigo === "lista_desactivada";
                                            });
                                            if (!estadoInactivo) {
                                                return;
                                            }
                                            listaPrecioVenta.estado = estadoInactivo.id;

                                            ListaPrecioVentaFactory.desactivar(itemId).then(
                                                function () {
                                                    modalInstance.close(itemId);
                                                    $state.go($state.current, {}, { reload: true });
                                                },
                                                function (error) {
                                                    //$scope.modalInstanceBorrar1.dismiss("cancel");
                                                    $scope.tituloModal = vm.translations.DESACTIVATE_FAILED;
                                                    $scope.mensajeModal =error.data[0].message;
                                                    var modalInstance = $modal.open({
                                                        template: '<div class="modal-header">' +
                                                                '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                                "</div>" +
                                                                '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                                '<div class="modal-footer">' +
                                                                '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                                "</div>",
                                                        scope: $scope
                                                    });
                                                    $scope.cancel = function () {
                                                        modalInstance.dismiss("cancel");
                                                    };
                                                    console.log("error al borrar: ");
                                                    console.log(error);
                                                }
                                            );
                                        }
                                    });
                                };
                            }

                        }
                    ]
            };
        });

        function addNewListaPrecioVenta(listaPrecioVenta, view) {
            vm.view = view;
            if (listaPrecioVenta != undefined && !vm.view) {
                vm.listaPrecioVenta = listaPrecioVenta;
                if (listaPrecioVenta.tipoEntidad != undefined) {
                    vm.tipoEntidadSelected();
                }
                vm.monedaSelected();
                $scope.tituloModal = $filter('translate')('EDIT_LISTA_PRECIO_VENTA');
                if (vm.listaPrecioVenta.tipoEntidad != undefined && vm.listaPrecioVenta.tipoEntidad.codigo == 'cliente') {
                    vm.listaPrecioVenta.entidad = vm.listaPrecioVenta.cliente;
                    vm.show = 'nombre';
                } else if (vm.listaPrecioVenta.tipoEntidad != undefined && vm.listaPrecioVenta.tipoEntidad.codigo == 'tipo_cliente') {
                    vm.listaPrecioVenta.entidad = vm.listaPrecioVenta.tipoCliente;
                    vm.show = 'descripcion';
                } else {

                }
            } else if (listaPrecioVenta != undefined && vm.view) {
                $scope.tituloModal = $filter('translate')('VIEW_LISTA_PRECIO_VENTA');
                vm.listaPrecioVenta = listaPrecioVenta;
                if (vm.listaPrecioVenta.tipoEntidad != undefined && vm.listaPrecioVenta.tipoEntidad.codigo == 'cliente') {
                    vm.listaPrecioVenta.entidad = vm.listaPrecioVenta.cliente;
                    vm.show = 'nombre';
                } else if (vm.listaPrecioVenta.tipoEntidad != undefined && vm.listaPrecioVenta.tipoEntidad.codigo == 'tipo_cliente') {
                    vm.listaPrecioVenta.entidad = vm.listaPrecioVenta.tipoCliente;
                    vm.show = 'descripcion';
                }
            } else {
                $scope.tituloModal = $filter('translate')('NEW_LISTA_PRECIO_VENTA');
            }

            var modalInstance = $modal.open({
                templateUrl: 'views/directives/listaprecioventa-new-modal.html',
                scope: $scope,
                backdrop: 'static',
                bindToController: true,
                size : 'lg',
            });

            $scope.cancel = function () {
                vm.listaPrecioVenta = {};
                modalInstance.dismiss('cancel');
                $state.go($state.current, {}, { reload: true });
            };

            $scope.ok = function () {
                vm.submited = true;
                if (vm.validarCamposModal()) {
                    if (vm.listaPrecioVenta.tipoEntidad != undefined && vm.listaPrecioVenta.tipoEntidad.codigo == 'tipo_cliente') {
                        vm.listaPrecioVenta.tipoCliente = vm.listaPrecioVenta.entidad;
                        vm.listaPrecioVenta.cliente = null;
                    } else if (vm.listaPrecioVenta.tipoEntidad != undefined && vm.listaPrecioVenta.tipoEntidad.codigo == 'cliente') {
                        vm.listaPrecioVenta.cliente = vm.listaPrecioVenta.entidad;
                        vm.listaPrecioVenta.tipoCliente = null;
                    }
                    if (vm.utilizarPrecioBase) {
                        vm.listaPrecioVenta.utilizaPrecioBase = true;
                        var estadoActivo = _.find(vm.estados, function (estado) {
                            return estado.codigo === "lista_activada";
                        });
                        if (!estadoActivo) {
                            return;
                        }
                        vm.listaPrecioVenta.estado = estadoActivo.id;
                        var trimmedModel = ModelTrimmer.trimDetails($scope.vm.listaPrecioVenta);
                        if (trimmedModel.valor != undefined) {
                            trimmedModel.valor = trimmedModel.valor.toString();
                        }
                        var listPrecVent = ListaPrecioVentaFactory.create(vm.listaPrecioVenta);

                        return ListaPrecioVentaFactory.save(listPrecVent).then(function (response) {
                            modalInstance.close();
                            $state.go($state.current, {}, { reload: true });
                        }, function (error) {
                            notify({ message: error.data[0].message, classes: "alert-danger", position: "right" });
                        });
                    } else {
                        sendNewListaPrecioVenta().then(function (data) {
                            if (data != undefined) {
                                modalInstance.close();
                                ListaPrecioVentaFactory.setIdListaPrecioVenta(data.id);
                                ListaPrecioVentaFactory.setListaPrecioVenta(data, 4);
                                redirectListaPrecioVenta(data.id);
                            }
                        }, function (error) {
                            notify({ message: error.data[0].message, classes: "alert-danger", position: "right" });
                        });
                    }
                }
            };
        }

        function sendNewListaPrecioVenta() {
            var estadoActivo = _.find(vm.estados, function (estado) {
                return estado.codigo === "lista_activada";
            });
            if (!estadoActivo) {
                return;
            }
            vm.listaPrecioVenta.utilizaPrecioBase = false;
            vm.listaPrecioVenta.estado = estadoActivo.id;
            vm.listaPrecioVenta.show = vm.show;
            var listPrecVent = ListaPrecioVentaFactory.create(vm.listaPrecioVenta);
            return ListaPrecioVentaFactory.save(listPrecVent).then(function (response) {
                return response;
            }, function (error) {
                notify({ message: error.data[0].message, classes: "alert-danger", position: "right" });
            });
        }

        function redirectListaPrecioVenta(itemId) {
            ListaPrecioVentaFactory.get(itemId, "ListaPrecioVentaList").$promise.then(function (listaPrecioVenta) {
                ListaPrecioVentaFactory.setIdListaPrecioVenta(listaPrecioVenta.id);
                ListaPrecioVentaFactory.setListaPrecioVenta(listaPrecioVenta, 5);
                $state.go('app.listaprecioventa.new', { id: itemId });

            });
        }

        function tipoEntidadSelected() {
            vm.entidadRequired = true;
            vm.listaPrecioVenta.entidad = undefined;
            switch (vm.listaPrecioVenta.tipoEntidad.codigo) {
                case "cliente":
                    vm.show = 'nombre';
                    /* var paramsClientes = {};
                    paramsClientes.search = filterFactory
                        .single({
                            path: "activo",
                            equals: true
                        })
                        .value();
                    vm.entidades = ClienteFactory.all(paramsClientes); */
                    /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
                    vm.staticFilter = [{ path: "activo", equals: true }];
                    vm.Factory = ClienteFactory;
                    break;
                case "tipo_cliente":
                    vm.show = 'descripcion'
                    /* vm.entidades = TipoClienteFactory.all(); */
                    vm.staticFilter = [];
                    vm.Factory = TipoClienteFactory;
                    break;
            }
        }

        function monedaSelected() {
            if (vm.listaPrecioVenta.moneda.codigo == 'guaranies') {
                vm.activarFactorRedondeo = true;
            } else {
                vm.listaPrecioVenta.factorRedondeo = null;
                vm.activarFactorRedondeo = false;
            }
        }

        function validarCamposModal() {
            if (vm.utilizarPrecioBase) {
                if (vm.listaPrecioVenta.moneda != undefined && vm.listaPrecioVenta.precioBase != undefined
                    && vm.listaPrecioVenta.porcentajeRecargo != undefined && vm.listaPrecioVenta.descripcion != undefined) {
                    if (vm.listaPrecioVenta.moneda.codigo == 'guaranies' && vm.listaPrecioVenta.factorRedondeo == undefined) {
                        return false;
                    } else {
                        if (vm.listaPrecioVenta.tipoEntidad != undefined && vm.listaPrecioVenta.entidad == undefined) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                } else {
                    return false;
                }

            } else {
                if (vm.listaPrecioVenta.moneda != undefined && vm.listaPrecioVenta.descripcion != undefined) {
                    if (vm.listaPrecioVenta.moneda.codigo == 'guaranies' && vm.listaPrecioVenta.factorRedondeo == undefined) {
                        return false;
                    } else {
                        if (vm.listaPrecioVenta.tipoEntidad != undefined && vm.listaPrecioVenta.entidad == undefined) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                } else {
                    return false;
                }
            }
        }
        var createFilters = function (filters) {
            var filtersArr = [];
            _.each(filters, function (search, data) {
                filtersArr.push({ path: data, like: search })
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        }
        $rootScope.isProcessing = false;
    }]);

angular.module('qualita.venta')
        .factory('ListaPrecioVentaFactory', ListaPrecioVentaFactory);

        ListaPrecioVentaFactory.$inject = ['$resource', 'baseurl', '$http'];

function ListaPrecioVentaFactory($resource, baseurl, $http) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove,
        getIdListaPrecioVenta: getIdListaPrecioVenta,
        setIdListaPrecioVenta: setIdListaPrecioVenta,
        getListaPrecioVenta: getListaPrecioVenta,
        setListaPrecioVenta: setListaPrecioVenta,
        getUltimosCosto: getUltimosCosto,
        getListasActivas: getListasActivas,
        desactivar: desactivar
    };

    var ListaPrecioVenta = $resource(baseurl.getBaseUrl() + "/listaprecioventa/:id", {id: '@id'}, {
        update: {
            method: 'PUT'
        }
    });

    var listaPrecioVenta;
    var idListaPrecioVenta;

    return service;

    function all(params,view) {
        if(params){
            params.view = view!=undefined ? view : 'BaseList';
        }
        return ListaPrecioVenta.query(params);
    }

    function get(id, view) {
        return ListaPrecioVenta.get({id: id, view: view ? view : "ListaPrecioventaForm"});
    }

    function create(attrs) {
        return new ListaPrecioVenta(attrs);
    }

    function save(listaPrecioVenta) {
        return (listaPrecioVenta.id) ? listaPrecioVenta.$update() : listaPrecioVenta.$save();
    }

    function remove(listaPrecioVenta) {
        return listaPrecioVenta.$remove();
    }

    function getIdListaPrecioVenta() {
        return idListaPrecioVenta;
    }

    function setIdListaPrecioVenta(id) {
        idListaPrecioVenta = id;
    }

    function getListaPrecioVenta() {
        return listaPrecioVenta;
    }

    function setListaPrecioVenta(cmp) {
        if (cmp.id) {
            listaPrecioVenta = cmp;
        } else {
          listaPrecioVenta = get(cmp, 'ListaPrecioVentaForm').$promise;
        }
    }

    function getUltimosCosto(id, moneda) {
        return $http({
            method: "GET",
            url: baseurl.getBaseUrl() + "/historicocosto/ultimospreciomoneda/" + id,
            params: { "codigoMoneda": moneda }
        });
    }

    function getListasActivas(){
        return $http({
            method : "GET",
            url : baseurl.getBaseUrl()+"/listaprecioventa/getlistasactivas"
        });
      }

    function desactivar(id) {
        return $http({
            method: "PUT",
            url: baseurl.getBaseUrl() + "/listaprecioventa/desactivar/" + id,
        })

}
}

'use strict';

angular.module('qualita.venta')
  .controller('ListaPrecioVentaFormController', ['$rootScope', '$scope', 'ListaPrecioVentaFactory', 'formFactory', '$location',
    '$state', 'listaPrecioVentaPrepService', 'notify', '$filter', 'VentasLangFactory', 'TiposTipoFactory',
    'TipoClienteFactory', 'ClienteFactory', 'filterFactory', 'ProductosFactory', 'TiposFactory', 'ListaPrecioVentaDetalleFactory',
    '$modal', 'AuthorizationService', 'UtilFactory','CsvFactory' ,'$window','baseurl','ReportTicketFactory','ProductoAlmacenFactory','CotizacionMonedaFactory','ParametrosFactory',
    function ($rootScope, $scope, ListaPrecioVentaFactory, formFactory, $location,
      $state, listaPrecioVentaPrepService, notify, $filter, VentasLangFactory, TiposTipoFactory,
      TipoClienteFactory, ClienteFactory, filterFactory, ProductosFactory, TiposFactory,
      ListaPrecioVentaDetalleFactory, $modal, AuthorizationService, UtilFactory,CsvFactory,$window,baseurl,ReportTicketFactory,ProductoAlmacenFactory,CotizacionMonedaFactory,ParametrosFactory) {

      var vm = this;
      vm.detalle = {};
      $scope.form ={};
      vm.addNewListaPrecioVentaDetalle = addNewListaPrecioVentaDetalle;
      vm.tipoEntidadSelected = tipoEntidadSelected;
      vm.factoryProductos = ProductosFactory;
      vm.monedaSelected = monedaSelected;
      vm.cancel = cancel;
      vm.changeProducto = changeProducto;
      vm.delayRequestProducto = delayRequestProducto;
      vm.goCargaProductos = goCargaProductos;
      vm.validarCamposModal = validarCamposModal;
      vm.submitLista = submitLista;
      vm.changePrecios = changePrecios;
      vm.changeUnidadMedida = changeUnidadMedida;
      vm.changeLimiteDescuento = changeLimiteDescuento;

      activate();
      function activate() {
        getMonedaLocal();
        vm.cotizacionRequerida = true;
        VentasLangFactory.getTranslations().then(function (translations) {

          if ($state.includes("app.listaprecioventa.view")) {
            vm.soloLectura = true;
          } else {
            vm.soloLectura = false;
          }

          $scope.translations = translations;
          vm.tiposEntidades = TiposTipoFactory.get("tipo_entidad");
          vm.tiposCliente = TipoClienteFactory.all();
          vm.monedas = TiposTipoFactory.get("monedas");
          vm.factorRedondeo = TiposTipoFactory.get("factor_redondeo");
          TiposFactory.estadosListaPrecioVenta().then(function (data) {
            vm.estados = data.data;
          });
          vm.listaPrecioVenta = listaPrecioVentaPrepService;

          if (vm.listaPrecioVenta.tipoEntidad != undefined && vm.listaPrecioVenta.tipoEntidad.codigo == 'cliente') {
            vm.listaPrecioVenta.entidad = vm.listaPrecioVenta.cliente;
            vm.show = 'nombre';
          } else if (vm.listaPrecioVenta.tipoEntidad != undefined && vm.listaPrecioVenta.tipoEntidad.codigo == 'tipo_cliente') {
            vm.listaPrecioVenta.entidad = vm.listaPrecioVenta.tipoCliente;
            vm.show = 'descripcion';
          }
          vm.staticFilter = {
            search: filterFactory.and([{
              path: "listaPrecioVenta.id",
              equals: vm.listaPrecioVenta.id
            }]).value()
          };
          VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id','producto.codigo','producto.codigoBarrasPrincipal.codigoBarras', 'producto.nombre', 'producto.marca.descripcion', 'unidadMedida.descripcion'
              , 'ultimoCostoIva', 'limiteDescuento','precioMinimo', 'precioMaximo', 'precioVenta'];
            $scope.options = {
              'staticFilter': vm.staticFilter,
              'resource': 'listaprecioventadetalle',
              title: $filter("translate")("DETALLES"),
              'factory': ListaPrecioVentaDetalleFactory,
              'view': 'ListaPrecioVentaDetalleList',
              'defaultColumnOrder': defaultColumnOrder,
              'columns': [
                { 'data': 'id', 'title': translations.ID, visible: false },
                { 'data': 'producto.codigo', 'title': $filter("translate")("CODIGO_INTERNO")},
                { 'data': 'producto.codigoBarrasPrincipal.codigoBarras', 'title': $filter("translate")("CODIGO_BARRAS")},
                { 'data': 'producto.nombre', 'title': $filter("translate")("PRODUCTO"), 'render': renderEmptyData },
                { 'data': 'producto.marca.descripcion', 'title': $filter("translate")("MARCA"), 'render': renderEmptyData },
                { 'data': 'unidadMedida.descripcion', 'title': $filter("translate")("MEASURE_UNIT"), 'render': renderEmptyData },
                { 'data': 'ultimoCostoIva', 'title': $filter("translate")("ULTIMO_COSTO_IVA"), 'type': "number-range", 'render': monedaRender },
                { 'data': 'precioMinimo', 'title': $filter("translate")("PRECIO_MIN"), 'type': "number-range", 'render': monedaRender },
                { 'data': 'precioMaximo', 'title': $filter("translate")("PRECIO_MAX"), 'type': "number-range", 'render': monedaRender },
                { 'data': 'precioVenta', 'title': $filter("translate")("PRECIO_VENTA"), 'type': "number-range", 'render': monedaRender },
                { 'data': 'limiteDescuento', 'title': $filter("translate")("LIMITE_DESCUENTO"), 'type': "number-range", 'render': renderEmptyData }
              ],
              'hasOptions': true,
              'defaultOrderColumn': 0,
              'defaultOrderDir': "desc",
              'hideRemoveMenu': true,
              'hideAddMenu': true,
              'hideEditMenu': true,
              'hideViewMenu': true,
              'extraRowOptions': [
                {
                  templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + translations.DELETE + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                  customAttribute: 'activo',
                  conditionName: "canBorrar",
                  conditionDef: function (activo) {
                    /* var permission = !vm.soloLectura;
                    return permission; */
                    return (AuthorizationService.hasPermission('delete_listaprecioventadetalle') && !vm.soloLectura);

                  },
                  functionName: "borrar",
                  functionDef: function (itemId) {
                    $scope.selectedItemId = itemId;
                    $scope.tituloModal = translations.DELETE_CONFIRMATION;
                    $scope.mensajeModal = translations.DELETE_WARNING;
                    var modalInstance = $modal.open({
                      templateUrl: 'views/directives/confirmation-modal.html',
                      scope: $scope
                    });

                    $scope.cancel = function () {
                      modalInstance.dismiss('cancel');
                    };

                    $scope.ok = function (itemId) {
                      var model = ListaPrecioVentaDetalleFactory.create({ id: itemId });
                      ListaPrecioVentaDetalleFactory.remove(model).then(function () {
                        modalInstance.close(itemId);
                        $scope.options.reloadData();
                        //$state.go($state.current, {}, { reload: true });
                      }, function (error) {
                        modalInstance.dismiss('cancel');
                        $scope.tituloModal = translations.DELETE_FAILED;
                        $scope.mensajeModal = error.data[0].message;

                        var modalInstance2 = $modal.open({
                          templateUrl: 'views/directives/information-modal.html',
                          scope: $scope
                        });
                        $scope.cancel = function () {
                          modalInstance2.dismiss('cancel');
                        };
                      });
                    }
                  }
                }, {
                  templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' title='" + $filter('translate')('EDIT') + "' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                  conditionName: "canEditar",
                  conditionDef: function () {
                    var permission = !vm.soloLectura;
                    if (permission) {
                      return (AuthorizationService.hasPermission('update_listaprecioventadetalle'));
                    } else {
                      return permission
                    }

                  },
                  functionName: "editar",
                  functionDef: function (itemId) {
                    ListaPrecioVentaDetalleFactory.get(itemId, "ListaPrecioVentaDetalleForm").$promise.then(function (listaPrecioVentaDetalle) {
                      vm.addNewListaPrecioVentaDetalle(listaPrecioVentaDetalle, false);
                    });

                  }
                }, {
                  templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" +
                    $filter("translate")("VIEW") +
                    "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                  conditionName: "canView",
                  conditionDef: function () {
                    return AuthorizationService.hasPermission("index_listaprecioventadetalle");
                  },
                  functionName: "view",
                  functionDef: function (itemId) {
                    ListaPrecioVentaDetalleFactory.get(itemId, "ListaPrecioVentaDetalleForm").$promise.then(function (listaPrecioVentaDetalle) {
                      vm.addNewListaPrecioVentaDetalle(listaPrecioVentaDetalle, true);
                    });
                  }
                }],
              'extraMenuOptions':
                [{
                  title: $filter("translate")("NEW"),
                  icon: "glyphicon glyphicon-plus",
                  showCondition: function () {
                    var permission = !vm.soloLectura;
                    return permission;
                  },
                  action: function () {
                    vm.addNewListaPrecioVentaDetalle();
                  }

                }, {
                    data: $filter('translate')('UPDATE_ITEMS'),
                    title: "Carga Productos",
                    icon: 'glyphicon glyphicon-retweet',
                    showCondition: function () {
                      var permission = !vm.soloLectura;
                      return permission;
                    },
                    action: function () {
                      vm.goCargaProductos();

                    }
                  },
                {
                  data: $filter('translate')('IMPORT_ITEMS'),
                  title: "Carga Productos",
                  icon: 'glyphicon glyphicon-upload',
                  showCondition: function () {
                    var permission = !vm.soloLectura;
                    return permission;
                  },
                  action: function () {
                    vm.goCargaProductos();

                  }
                  },
                  {
                    data: $filter('translate')('GENERAR_CSV'),
                    title: "CSV",
                    icon: 'glyphicon glyphicon-export',
                    showCondition: function () {
                      return true;
                    },
                    action: function () {
                      var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                      CsvFactory.csv("listaprecioventadetalle", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                        .then(function (response) {
                          console.log("response de csv: ", response);
                          var fileName = response.data.fileName;
                          $window.open(baseurl.getPublicBaseUrl() + fileName);
                        });
                    },

                  },
                  {
                    title: "Reporte",
                    icon: 'glyphicon glyphicon-file',
                    showCondition: function () {
                      return true;
                    },
                    action: function () {
                      var filters = createFilters($scope.options.getFilters());
                      ReportTicketFactory.ticket('listadoPrecioVentaDetalle', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                          vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                          $window.open(vm.pdfDownloadURL, '_blank');
                      });
                  }
                  }
                ]
            };
          });
          if ($state.is("app.listaprecioventa.new")) {
            activateNew();
          } else if ($state.is("app.listaprecioventa.edit")) {
            activateEdit();
          } else if ($state.is("app.listaprecioventa.view")) {
            activateView();
          }
          vm.submited = false;
          $rootScope.isProcessing = false;
        });
      }

      function activateNew() {
        vm.title = $filter("translate")("NEW_LISTA_PRECIO_VENTA");
        vm.new = true;
        vm.monedaSelected();

      }

      function activateEdit() {
        vm.edit = true;
        //vm.activateMoneda = true;
        vm.activateAll = true;
        //if (vm.listaPrecioVenta.listaPrecioVentaDetalles.length > 0) {
        vm.activateMoneda = false;
        if (vm.listaPrecioVenta.entidad != undefined) {
          vm.activateAll = false;
        }
       // }
        vm.monedaSelected();
        vm.tipoEntidadSelected();
      }

      function activateView() {
        vm.view = true;
      }

      function addNewListaPrecioVentaDetalle(listaPrecioVentaDetalle, view) {
        vm.view = view;
        if (listaPrecioVentaDetalle != undefined && !vm.view) {
          vm.detalle = listaPrecioVentaDetalle;
          $scope.tituloModal = $filter('translate')('EDIT_PRODUCTO');
        } else if (listaPrecioVentaDetalle != undefined && vm.view) {
          $scope.tituloModal = $filter('translate')('VIEW_PRODUCTO');
          vm.detalle = listaPrecioVentaDetalle;
        } else {
          $scope.tituloModal = $filter('translate')('NEW_PRODUCTO')
        }

        var modalInstance = $modal.open({
          templateUrl: 'views/directives/listaprecioventadetalle-new-modal.html',
          scope: $scope,
          backdrop: 'static',
          bindToController: true
        });

        $scope.cancel = function () {
          vm.detalle = {};
          modalInstance.dismiss('cancel');
          $scope.options.reloadData();
          //$state.go($state.current, {}, { reload: true });
        };

        $scope.ok = function () {
          vm.submited = true;
          changeLimiteDescuento();
          if ($scope.form.ListaPrecioVentaDetalleForm.$valid) {
            vm.detalle.listaPrecioVenta = vm.listaPrecioVenta;
            var listPrecVentDet = ListaPrecioVentaDetalleFactory.create(vm.detalle);
            return ListaPrecioVentaDetalleFactory.save(listPrecVentDet).then(function (resp) {
              //vm.detalle = {};
              modalInstance.close();
              $state.go($state.current, {}, { reload: true });
              //$scope.options.reloadData();
            }, function (error) {
              notify({ message: error.data[0].message, classes: "alert-danger", position: "right" });
            });
          }else {
            //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
          }
        }
      }
      function tipoEntidadSelected() {
        vm.entidadRequired = true;
        if (vm.listaPrecioVenta.tipoEntidad == undefined) {
          vm.listaPrecioVenta.entidad = undefined;
        } else {
          switch (vm.listaPrecioVenta.tipoEntidad.codigo) {
            case "cliente":
              vm.show = 'nombre';
              var paramsClientes = {};
              paramsClientes.search = filterFactory
                .single({
                  path: "activo",
                  equals: true
                })
                .value();
              vm.entidades = ClienteFactory.all(paramsClientes);
              break;
            case "tipo_cliente":
              vm.show = 'descripcion'
              vm.entidades = TipoClienteFactory.all();
              break;
          }
        }
      }



      function cancel() {
        $location.path('/listaprecioventa');
      }

      function monedaSelected() {
        if (vm.listaPrecioVenta.moneda.codigo == 'guaranies') {
          vm.activarFactorRedondeo = true;
          vm.cotizacionRequerida = false;
          vm.tipoCambio = 1;
        } else {
          vm.cotizacionRequerida = true;
          vm.monedaLocal.$promise.then(function (data) {
            vm.listaPrecioVenta.factorRedondeo = null;
            vm.activarFactorRedondeo = false;
            var params = {};
            var fecha = moment(new Date()).format('DD/MM/YYYY');
            params.search = filterFactory.and([{
              path: 'monedaDesde.id',
              equals: vm.listaPrecioVenta.moneda.id
            },
            {
              path: 'monedaHasta.id',
              equals: vm.monedaLocal[0].valorTipo.id
            },
            {
              path: 'fecha',
              like: fecha
            }
            ]).value();
            params.view = 'CotizacionMonedaList';
            CotizacionMonedaFactory.all(params).$promise.then(function (response) {
              if (response && response[0]) {
                vm.tipoCambio = response[0].valorCompra;
              } else {
                vm.tipoCambio = null;
              }
            });
          });
        }
      }

      function changeProducto(detalle, index) {
        if (vm.detalle.producto.id) {
          ProductosFactory.get(vm.detalle.producto.id, 'ListaPrecioVentaDetalleForm').$promise.then(function (producto) {
            vm.detalle.producto.unidadesMedida = producto.unidadesMedida;
            vm.detalle.producto.atributos = producto.atributos;
          });
          var filtroCosto = {};
          var fecha = moment(new Date()).format('DD/MM/YYYY');

          filtroCosto.search = filterFactory
            .and([{
              path: 'producto.id',
              equals: vm.detalle.producto.id
            }, {
              path: 'dia',
              equalOrBefore: fecha
            }, {
              path: 'dia',
              sortDesc: 'true'
            }]).value();
            var ultimoCostoAux;
            ProductoAlmacenFactory.all(filtroCosto).$promise.then(function (productosAlmacen) {
              ultimoCostoAux = parseFloat(productosAlmacen[0].ultimoCostoIVA).toFixed(2);
              if (vm.listaPrecioVenta.moneda.codigo == "guaranies") {
                vm.detalle.ultimoCostoIva = parseFloat(ultimoCostoAux.toString().replace(/\./, ","));
              }else{
                if(vm.tipoCambio != null && vm.tipoCambio != undefined){
                  ultimoCostoAux = ultimoCostoAux / vm.tipoCambio;
                  vm.detalle.ultimoCostoIva = Math.round(ultimoCostoAux * 100) / 100;
                } else{
                  vm.detalle.ultimoCostoIva = ultimoCostoAux;
                }

              }
            });
        }
        vm.detalle.precioVenta = '';
        vm.detalle.precioMinimo = '';
        vm.detalle.precioMaximo = '';
      }

      var renderEmptyData = function (data) {
        if (data == undefined)
          return "";
        else
          return data;
      };
      var monedaRender = function (data) {
        if (data == undefined) {
          return "";
        }
        var campoMoneda = vm.listaPrecioVenta.moneda;
        var moneda = "Gs. ";
        if (campoMoneda.codigo === "dolares") {
          moneda = "Usd. ";
          //data = parseFloat(data).toFixed(2);
        } else if (campoMoneda.codigo === "pesos") {
          moneda = "Pes. ";
         // data = parseFloat(data).toFixed(2);
        } else if (campoMoneda.codigo === "real") {
          moneda = "Rel. ";
         // data = parseFloat(data).toFixed(2);
        } else if (campoMoneda.codigo === "euro") {
          moneda = "Eur. ";
          //data = parseFloat(data).toFixed(2);
        } else {
          //data = parseFloat(data).toFixed(0);
        }

          var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
          var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);

          // Si tiene parte entera
          if(parteEntera != ''){
              return ( moneda +
                  parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                  ","+parteDecimal.toString()
              );
          }else{
              return (moneda +
                  data
                  .toString()
                  .replace(".", ",")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              );
          }
      };
      function delayRequestProducto(searchValue) {
        var userInputFilter = {};
        var staticFilter = [];
        if (searchValue.length >= 3) {

          var filterNombre = staticFilter.slice()
          var filterCodigoBarras = staticFilter.slice()
          var filterCodigoInterno = staticFilter.slice()

          filterNombre.push({
            path: "nombre",
            like: searchValue
          },
            {
              path: "estado.codigo",
              notEquals: 'inactivo'
            },
            {
              path: "itemVenta",
              equals: true
            }
          )

          filterCodigoBarras.push({
            path: "codigoBarrasPrincipal.codigoBarras",
            like: searchValue
          },
            {
              path: "estado.codigo",
              notEquals: 'inactivo'
            },
            {
              path: "itemVenta",
              equals: true
            })

          filterCodigoInterno.push({
            path: "codigo",
            like: searchValue
          },
            {
              path: "estado.codigo",
              notEquals: 'inactivo'
            },
            {
              path: "itemVenta",
              equals: true
            })

          userInputFilter = {
            search: filterFactory.or([
              filterFactory.and(filterNombre).value(),
              filterFactory.and(filterCodigoBarras).value(),
              filterFactory.and(filterCodigoInterno).value()
            ]).value()
          }

          $scope.recuperarProducto(userInputFilter)
        } else if (searchValue.length > 0 && searchValue.length < 3) {

          var filterCodigoInterno = staticFilter.slice()
          filterCodigoInterno.push({
            path: "codigo",
            like: searchValue
          },
            {
              path: "itemVenta",
              equals: true
            })

          userInputFilter = {
            search: filterFactory.or([
              filterFactory.and(filterCodigoInterno).value()
            ]).value()
          }
          $scope.recuperarProducto(userInputFilter)
        } else {
          vm.productos = undefined
        }
      }

      $scope.recuperarProducto = function (userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
          $scope.finalFilters, userInputFilter);
        vm.productos = ProductosFactory.allForSelect(userInputFilter)
      };

      function goCargaProductos() {
        $state.go("app.listaprecioventa.carga", { id: vm.listaPrecioVenta.id });
      }

      function validarCamposModal() {
        if (vm.detalle.producto != undefined && vm.detalle.unidadMedida != undefined && vm.detalle.precioVenta != undefined
          && vm.detalle.precioMaximo != undefined && vm.detalle.precioMinimo != undefined && vm.detalle.precioVenta != "" ) {
          return true;
        } else {
          return false;
        }

      }

      function submitLista() {
        vm.submited = true;
        if ($scope.ListaPrecioVentaForm.$valid) {
          if (vm.listaPrecioVenta.tipoEntidad.codigo == 'tipo_cliente') {
            vm.listaPrecioVenta.tipoCliente = vm.listaPrecioVenta.entidad;
            vm.listaPrecioVenta.cliente = null;
          } else if (vm.listaPrecioVenta.tipoEntidad.codigo == 'cliente') {
            vm.listaPrecioVenta.cliente = vm.listaPrecioVenta.entidad;
            vm.listaPrecioVenta.tipoCliente = null;
          }
          formFactory.defaultNSFSubmit($scope.ListaPrecioVentaForm, ListaPrecioVentaFactory, vm.listaPrecioVenta).then(function (response) {
            $location.path('/listaprecioventa');
          }, function (error) {
            console.log(error);
          });

        }
      }
      function changePrecios() {
        var permissionPrecioVentaMenor = AuthorizationService.hasPermission(
          "asignar_precioventa_menor_ultimocostoiva"
        );
        var permissionPrecioVentaIgual = AuthorizationService.hasPermission(
          "asignar_precioventa_igual_ultimocostoiva"
        );
        if (vm.detalle.precioVenta != undefined && vm.detalle.ultimoCostoIva != undefined && vm.detalle.precioVenta != '') {
          if (vm.detalle.precioVenta < vm.detalle.ultimoCostoIva) {
            if (!permissionPrecioVentaMenor) {
              vm.detalle.precioVenta = '';
              var msg = $filter("translate")("PRECIO_VENTA_MENOR");
              notify({ message: msg, classes: "alert-warning", position: "right" });
            }
          } else if (vm.detalle.precioVenta == vm.detalle.ultimoCostoIva) {
            if (!permissionPrecioVentaIgual) {
              vm.detalle.precioVenta = '';
              var msg = $filter("translate")("PRECIO_VENTA_IGUAL");
              notify({ message: msg, classes: "alert-warning", position: "right" });
            }
          }
        }
        if (vm.detalle.precioMinimo != undefined && vm.detalle.ultimoCostoIva != undefined && vm.detalle.precioMinimo != '') {
          if (vm.detalle.precioMinimo < vm.detalle.ultimoCostoIva) {
            if (!permissionPrecioVentaMenor) {
              vm.detalle.precioMinimo = '';
              var msg = $filter("translate")("PRECIO_MINIMO_MENOR");
              notify({ message: msg, classes: "alert-warning", position: "right" });
            }
          } else if (vm.detalle.precioMinimo == vm.detalle.ultimoCostoIva) {
            if (!permissionPrecioVentaIgual) {
              vm.detalle.precioMinimo = '';
              var msg = $filter("translate")("PRECIO_MINIMO_IGUAL");
              notify({ message: msg, classes: "alert-warning", position: "right" });
            }
          }
        }
        if (vm.detalle.precioMaximo != undefined && vm.detalle.ultimoCostoIva != undefined && vm.detalle.precioMaximo != '') {
          if (vm.detalle.precioMaximo < vm.detalle.ultimoCostoIva) {
            if (!permissionPrecioVentaMenor) {
              vm.detalle.precioMaximo = '';
              var msg = $filter("translate")("PRECIO_MAXIMO_MENOR");
              notify({ message: msg, classes: "alert-warning", position: "right" });
            }
          } else if (vm.detalle.precioMaximo == vm.detalle.ultimoCostoIva) {
            if (!permissionPrecioVentaIgual) {
              vm.detalle.precioMaximo = '';
              var msg = $filter("translate")("PRECIO_MAXIMO_IGUAL");
              notify({ message: msg, classes: "alert-warning", position: "right" });
            }
          }
        }
        if (vm.detalle.precioMaximo != undefined && vm.detalle.precioVenta != undefined && vm.detalle.precioMaximo != '' && vm.detalle.precioVenta != '') {
          if (vm.detalle.precioMaximo < vm.detalle.precioVenta) {
            vm.detalle.precioMaximo = '';
            var msg = $filter("translate")("PRECIO_MAXIMO_MENOR_PRECIO_VENTA");
            notify({ message: msg, classes: "alert-warning", position: "right" });
          }
        }
        if (vm.detalle.precioMinimo != undefined && vm.detalle.precioVenta != undefined && vm.detalle.precioMinimo != '' && vm.detalle.precioVenta != '') {
          if (vm.detalle.precioMinimo > vm.detalle.precioVenta) {
            vm.detalle.precioMinimo = '';
            var msg = $filter("translate")("PRECIO_MINIMO_MAYOR_PRECIO_VENTA");
            notify({ message: msg, classes: "alert-warning", position: "right" });
          }
        }
      }
      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }

    function changeUnidadMedida(unidadMedida){
      var filtroCosto = {};
      var fecha = moment(new Date()).format('DD/MM/YYYY');
      var ultimoCostoAux;
      filtroCosto.search = filterFactory
      .and([{
          path: 'producto.id',
          equals: vm.detalle.producto.id
      },{
          path: 'dia',
          equalOrBefore: fecha
      },{
          path: 'dia',
          sortDesc: 'true'
      }]).value();

      ProductoAlmacenFactory.all(filtroCosto).$promise.then(function(productosAlmacen){
          if( productosAlmacen!= undefined && productosAlmacen.length > 0){
            vm.detalle.ultimoCostoIva = parseFloat(productosAlmacen[0].ultimoCostoIVA).toFixed(2);
            var unidadMedidaNew, cantidadContenida, costoActual;
            unidadMedidaNew = unidadMedida;
            cantidadContenida = unidadMedidaNew.cantidad;
            costoActual = vm.detalle.ultimoCostoIva;
            if (cantidadContenida != undefined && cantidadContenida != null) {
              vm.detalle.ultimoCostoIva = Math.round(costoActual * cantidadContenida);
            }
            if (vm.listaPrecioVenta.moneda.codigo != "guaranies") {
              if(vm.tipoCambio != null && vm.tipoCambio != undefined){
                ultimoCostoAux = vm.detalle.ultimoCostoIva / vm.tipoCambio;
                vm.detalle.ultimoCostoIva = Math.round(ultimoCostoAux * 100) / 100;
              }
            }else{
              vm.detalle.ultimoCostoIva = parseFloat(vm.detalle.ultimoCostoIva.toString().replace(/\./, ","));
            }
          }
      });
  }

      function getMonedaLocal() {
        var params = {};
        params.search = filterFactory.single({
          path: 'codigo',
          equals: 'moneda_local'
        }).value();
        vm.monedaLocal = ParametrosFactory.all(params);
      }

      function changeLimiteDescuento() {
        if(vm.detalle.limiteDescuento != undefined){
          $scope.form.ListaPrecioVentaDetalleForm.limiteDescuento.$setValidity("error", true);
          var errorLimiteDescuento = false;
          var mensajeError;
          if (vm.detalle.limiteDescuento >= 100) {
            $scope.form.ListaPrecioVentaDetalleForm.limiteDescuento.$setValidity("error", false);
            errorLimiteDescuento = true;
            mensajeError="El Límite de Descuento no puede ser mayor a 99,99";
          }
          if(errorLimiteDescuento){
            notify({ message: mensajeError, classes: "alert-danger", position: "right" });
          }
        }
      }

    }]);

angular.module('qualita.venta')
        .factory('ListaPrecioVentaDetalleFactory', ListaPrecioVentaDetalleFactory);

        ListaPrecioVentaDetalleFactory.$inject = ['$resource', 'baseurl', '$http'];

function ListaPrecioVentaDetalleFactory($resource, baseurl, $http) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove,
        getIdListaPrecioVentaDetalle: getIdListaPrecioVentaDetalle,
        setIdListaPrecioVentaDetalle: getIdListaPrecioVentaDetalle,
        getListaPrecioVentaDetalle: getIdListaPrecioVentaDetalle,
        setListaPrecioVentaDetalle: getIdListaPrecioVentaDetalle,
    };

    var ListaPrecioVentaDetalle = $resource(baseurl.getBaseUrl() + "/listaprecioventadetalle/:id", {id: '@id'}, {
        update: {
            method: 'PUT'
        }
    });

    var listaPrecioVentaDetalle;
    var idListaPrecioVentaDetalle;

    return service;

    function all(params) {
        return ListaPrecioVentaDetalle.query(params);
    }

    function get(id, view) {
        return ListaPrecioVentaDetalle.get({id: id, view: view ? view : "ListaPrecioventaDetalleList"});
    }

    function create(attrs) {
        return new ListaPrecioVentaDetalle(attrs);
    }

    function save(listaPrecioVentaDetalle) {
        return (listaPrecioVentaDetalle.id) ? listaPrecioVentaDetalle.$update() : listaPrecioVentaDetalle.$save();
    }

    function remove(listaPrecioVentaDetalle) {
        return listaPrecioVentaDetalle.$remove();
    }

    function getIdListaPrecioVentaDetalle() {
        return idListaPrecioVentaDetalle;
    }

    function setIdListaPrecioVentaDetalle(id) {
        idListaPrecioVentaDetalle= id;
    }

    function getListaPrecioVentaDetalle() {
        return listaPrecioVentaDetalle;
    }

    function setListaPrecioVentaDetalle(cmp) {
        if (cmp.id) {
            listaPrecioVentaDetalle = cmp;
        } else {
          listaPrecioVentaDetalle = get(cmp, 'ListaPrecioVentaDetalleForm').$promise;
        }
    }
}

'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.venta')
  .controller('ProductosCargaCtrl', ["$http", "$rootScope", "$location", "$scope", "ProductoCargaFactory", "formFactory", "$window", "baseurl", "usSpinnerService", "notify", "listaPrecioVentaPrepService", "$state", "PreviousState", function ($http ,$rootScope, $location,$scope, ProductoCargaFactory, formFactory, $window, baseurl, usSpinnerService,notify, listaPrecioVentaPrepService,$state,PreviousState) {

    $scope.title = 'Producto';

    $rootScope.isProcessing = false;

    $scope.schema = ProductoCargaFactory.schema();

    $scope.form = ProductoCargaFactory.form();

    $scope.options = formFactory.defaultOptions();

    $scope.resource = 'producto';

    $scope.model = {};

    $scope.position = 0;

    $scope.submitButton = 'Actualizar datos';

    $scope.formSent = false;

    $scope.startSpin = function(){
        usSpinnerService.spin('spinner-1');
    }

    $scope.stopSpin = function(){
        usSpinnerService.stop('spinner-1');
    }
    var vm = this;
    vm.listaPrecioVenta = listaPrecioVentaPrepService;
    vm.previousState=PreviousState;
    $scope.submit = function(form) {
      var flow = ProductoCargaFactory.getFileFormProperty().uploader.flow;
      var flowFile = flow.files[0];
      if (flowFile)
        $scope.model.file = flowFile.name;
        $scope.$broadcast('schemaFormValidate');
        var model = ProductoCargaFactory.create($scope.model);
        $scope.formSent = true;
        $scope.startSpin();

        $rootScope.isProcessing = true;
        console.log(vm.listaPrecioVenta);
        ProductoCargaFactory.carga($scope.model.file, vm.listaPrecioVenta.id).then(function(data){
          console.log("succes");
          if(data.file === null || data.file === undefined) {
            ProductoCargaFactory.setData(data);
            $scope.stopSpin();
            $scope.formSent = false;
            $state.go(vm.previousState.Name, { id: vm.listaPrecioVenta.id });
          } else {
              $scope.stopSpin();
              $scope.formSent = false;
              $state.go(vm.previousState.Name, { id: vm.listaPrecioVenta.id });
          }
        }, function(error){
          $rootScope.isProcessing = false;
          $scope.stopSpin();
		      $scope.formSent = false;
          notify({
            message: error.data[0].message,
            classes: 'alert-danger',
            position: 'right'
          });
        });
    };

    $scope.cancel = function(form) {
      $state.go(vm.previousState.Name, { id: vm.previousState.Params.id });
    }
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.cuentabancaria
 * @description
 * # cuentabancaria
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('ProductoCargaFactory', ['$resource', 'baseurl','$http', function($resource,  baseurl,$http) {

  	var Producto= $resource( baseurl.getBaseUrl() + '/listaprecioventadetalle/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      },
      cerrar: {
        method: 'POST',
        url: baseurl.getBaseUrl() + "/listaprecioventadetalle/carga"
      }
    });

    var schema = {
      type: 'object',
      title: 'Producto',
      properties: {
        file: {
          type: 'object',
          format: 'fileupload',
          title: 'Archivo'
        }
      },
      required: []
    };

     var fileFormProperty = {
      key: 'file',
      fileName: 'file',
      uploadOptions: {
        imageOnly: false,
        target: baseurl.getBaseUrl() + '/clienteUpload'
      },
      uploader: {}
    };

    var form = [
      fileFormProperty
    ];

    var savedData = {};

    return {
      all: function(params) {
        return Producto.query(params);
      },

      get: function(id) {
        return Producto.get({id: id});
      },

      create: function(attrs) {
        return new Producto(attrs);
      },

      save: function(producto) {
        return (producto.id) ? producto.$update() : producto.$save();
      },

      remove: function(producto) {
        return producto.$remove();
      },

      schema: function(){
        return schema;
      },

      form: function(){
        return form;
      },

      getFileFormProperty: function() {
        return fileFormProperty;
      },

      setData: function set(data) {
        //$window.alert("set");
          savedData = data;
      },

      getData: function get() {
        //$window.alert("get");
          return savedData;
      },
      carga: function(name,id) {
        return $http({
                url: baseurl.getBaseUrl() + '/listaprecioventadetalle/carga', 
                method: "POST",
                params: { filename:name,idLista:id}
              });
      }
    };
  }]);

'use strict';


angular.module('qualita.venta')
    .controller('ConfiguracionAutorizacionesIndexCtrl', ["$state", "$scope", "ConfiguracionAutorizacionesFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "notify", "$modal", function ($state, $scope, ConfiguracionAutorizacionesFactory, 
        filterFactory, ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl, 
        AuthorizationService, notify, $modal) {

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        var monedaRender = function(data, type, row) {
            if (data != undefined) {
                var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
                var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);
    
                // console.log("AQUI sub1: ",parteEntera);
                // console.log("AQUI sub2: ",parteDecimal);
    
                // Si tiene parte entera
                if(parteEntera != ''){
                    return (
                        parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                        ","+parteDecimal.toString()
                    );
                }else{
                    return (
                        data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                }
    
            } else {
                return "";
            }
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'descripcion', 'usuarioAutorizador.username', 'procesoAutorizar.descripcion',
                'condicionar.descripcion', 'importeDesde', 'importeHasta', 'estado.descripcion'
            ];
            $scope.options = {
                'resource': 'configuracionautorizaciones',
                'title': 'Configuración Autorizaciones de Límite de Crédito',
                'factory': ConfiguracionAutorizacionesFactory,
                'view': 'ConfiguracionAutorizacionesList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': translations.FAIL_DELETE_CONFIGURACION_AUTORIZACION,
                'columns': [
                    { 'data': 'id', 'title': 'Código', visible: false  },
                    { 'data': 'descripcion', 'title': 'Descripción', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'usuarioAutorizador.username', 'title': 'Usuario Autorizador', 'class': 'dt-left' },
                    { 'data': 'procesoAutorizar.descripcion', 'title': 'Proceso Autorizar', 'class': 'dt-left' },
                    { 'data': 'condicionar.descripcion', 'title': 'Condicionar', 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
                    { 'data': 'importeDesde', 'title': 'Importe Desde', renderWith: monedaRender, 'class': 'dt-right' },
                    { 'data': 'importeHasta', 'title': 'Importe Hasta', renderWith: monedaRender, 'class': 'dt-right' },
                    { 'data': 'estado.descripcion', 'title': 'Estado', 'render': renderEmptyData, 'class': 'dt-center', type: 'combo', filterUrl: 'tipos/filtro/estado_autorizacion' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                [
                    {
                        title: translations.NEW,
                        icon: "glyphicon glyphicon-plus",
                        showCondition: function() {
                            return true;
                        },
                        action: function() {
                            if(AuthorizationService.hasPermission("create_configuracionautorizaciones") == true){
                                $state.go("app.configuracionautorizaciones.new");
                            }else{
                                var msg = 'No posee permiso para crear Autorizaciones de Procesos en Ejecución';
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("configuracionautorizaciones", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                .then(function (response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    },
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = createFilters($scope.options.getFilters());
                            ReportTicketFactory.ticket('listadoConfiguracionAutorizaciones', filters, $scope.options.tableAjaxParams,
                                $scope.options.currentColumnOrder).then(function (genTicket) {
                                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                    $window.open($scope.pdfDownloadURL, '_blank');
                                });
                        }
                    }
                ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>", 
                        customAttribute: ["id", "estado"],
                        conditionName: 'canEditar',
                        conditionDef: function(atributos) {
                            var estado = atributos[1];
                            if(AuthorizationService.hasPermission("update_configuracionautorizaciones") === true && estado.codigo === 'estado_autorizacion_activado'){
                                return true;

                            }else{
                                return false;
                            }
                        },
                        functionName: "editar",
                        functionDef: function(atributos) {
                        var itemId = atributos[0];
                        if(AuthorizationService.hasPermission("update_configuracionautorizaciones") === true){
                            $state.go('app.configuracionautorizaciones.edit', {id: itemId});
                        }else{
                            notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right'});
                        }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute : ["id", "estado"],
                        conditionName:"canBorrar",
                        conditionDef: function (atributos) {
                            var estado = atributos[1];
                            if(AuthorizationService.hasPermission("delete_configuracionautorizaciones") === true && estado.codigo === 'estado_autorizacion_activado'){
                                return true;

                            }else{
                                return false;
                            }
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            if(AuthorizationService.hasPermission("delete_configuracionautorizaciones") === true){
                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                          
                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: '<div class="modal-header">' +
                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                    '</div>' +
                                    '<div class="modal-body">{{::mensajeModal}}'+
                                    '<hr width="1%">'+
                                    '</div>'+
                                    '<div class="modal-footer">' +
                                    '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                    '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                    '</div>',
                                    scope: $scope
                                });
                            
                                $scope.cancel = function() {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                };
                            
                                $scope.ok = function(itemId) {
                                    var model = ConfiguracionAutorizacionesFactory.create({id: itemId});
                                    ConfiguracionAutorizacionesFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, {reload: true});
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                            $scope.mensajeModal = 'El registro se encuentra asociado a procesos de autorización ejecutados. Sólo puede ser desactivado';
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                        "</div>" +
                                                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                        '<div class="modal-footer">' +
                                                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                        "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                                $scope.modalInstanceBorrar1.dismiss('cancel');
                                            };
                                        }
                                    );
                                };
                            }else{
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right'});
                            }
                        }
                    },
                    {
                        templateToRender: "<button title='" +
                            $filter("translate")("ACTIVAR") +
                            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='activar(<%=dataId%>)' ng-class='{ hidden : !canActivar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ok'></span> </button>",
                        functionName: "activar",
                        conditionName: "canActivar",
                        customAttribute: ["estado"],
                        conditionDef: function (atributos) {
                            var estado = atributos[0];
                            
                            if(AuthorizationService.hasPermission("activar_configuracionautorizaciones") === true && estado.codigo === 'estado_autorizacion_desactivado'){
                                return false;
                            }else{
                                return false;
                            }
                        },
                    
                        functionDef: function (itemId){
                            if(AuthorizationService.hasPermission("activar_configuracionautorizaciones") === true){
                                ConfiguracionAutorizacionesFactory.get(itemId, "ConfiguracionAutorizacionesForm").$promise.then(function (response) {
                                    if (response) {
                                        $scope.mensajeModal = 'Esta seguro de querer activar el Registro de Cofiguración de Autorizaciones?';
                                        $scope.tituloModal = 'Activar';
                                        var modalInstance = $modal.open({
                                            templateUrl: "views/directives/confirmation-modal.html",
                                            scope: $scope
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss("cancel");
                                        };

                                        $scope.ok = function (id) {
                                            ConfiguracionAutorizacionesFactory.activar(itemId).then(function (response) {
                                                modalInstance.close();
                                                $state.go($state.current, {}, {reload: true});
                                            },function (error) {                                                                                
                                                var mensaje = "<span>";
                                                _.forEach(error.data, function (err) {
                                                    mensaje = mensaje + err.message + "<br/>";
                                                });
                                                mensaje = mensaje + "</span>";
                                                notify({
                                                    messageTemplate: mensaje,
                                                    classes: "alert-danger",
                                                    position: "right",
                                                    templateUrl: ""
                                                });
                                            });
                                        };                           
                                    }
                                });
                            }else{
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right'});
                            }
                        }
                    },
                    {
                        templateToRender: "<button title='" +
                            'Desactivar' +
                            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='desactivar(<%=dataId%>)' ng-class='{ hidden : !canDesactivar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                        functionName: "desactivar",
                        conditionName: "canDesactivar",
                        customAttribute: ["estado"],
                        conditionDef: function (atributos) {
                            var estado = atributos[0];
                            if(AuthorizationService.hasPermission("desactivar_configuracionautorizaciones") === true && estado.codigo === 'estado_autorizacion_activado'){
                                return true;
                            }else{
                                return false;
                            }
                        },
                    
                        functionDef: function (itemId){
                            if(AuthorizationService.hasPermission("desactivar_configuracionautorizaciones") === true){
                                ConfiguracionAutorizacionesFactory.get(itemId, "ConfiguracionAutorizacionesForm").$promise.then(function (response) {
                                    if (response) {
                                        $scope.mensajeModal = 'Esta seguro de querer desactivar el Registro de Configuración de Autorizaciones?';
                                        $scope.tituloModal = 'Desactivar';
                                        var modalInstance = $modal.open({
                                            templateUrl: "views/directives/confirmation-modal.html",
                                            scope: $scope
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss("cancel");
                                        };

                                        $scope.ok = function (id) {
                                            ConfiguracionAutorizacionesFactory.desactivar(itemId).then(function (response) {
                                                modalInstance.close();
                                                $state.go($state.current, {}, {reload: true});
                                            },function (error) {                                                                                
                                                var mensaje = "<span>";
                                                _.forEach(error.data, function (err) {
                                                    mensaje = mensaje + err.message + "<br/>";
                                                });
                                                mensaje = mensaje + "</span>";
                                                notify({
                                                    messageTemplate: mensaje,
                                                    classes: "alert-danger",
                                                    position: "right",
                                                    templateUrl: ""
                                                });
                                            });
                                        };                           
                                    }
                                });
                            }else{
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right'});
                            }
                        }
                    },
                    {
                      templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                              $filter("translate")("VIEW") +
                              "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                      functionName: "ver",
                      functionDef: function (itemId) {
                        if(AuthorizationService.hasPermission("index_configuracionautorizaciones") === true){
                            $state.go("app.configuracionautorizaciones.view", {id: itemId});
                        }else{
                            notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right'});
                        }
                      },
                      conditionName: "canVer",
                      conditionDef: function () {
                          return true;
                      }
                    },
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if(search === 'No' && data === 'activo'){
                        search = 'false';
                    }
                    if(search === 'Sí' && data === 'activo'){
                        search = 'true';
                    }
        
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);
'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ConfiguracionAutorizacionesFormCtrl
 * @description
 * # ConfiguracionAutorizacionesFormCtrl
 * 
 */
angular.module('qualita.venta')
  .controller('ConfiguracionAutorizacionesFormCtrl', ConfiguracionAutorizacionesFormCtrl);

  ConfiguracionAutorizacionesFormCtrl.$inject = ['$rootScope','filterFactory', '$scope', 'ConfiguracionAutorizacionesFactory', '$location', '$state',
'configuracionAutorizacionesPrepService', 'notify', 'formFactory', 'VentasLangFactory', 'TiposFactory','TiposTipoFactory','$timeout', '$filter', 'ParametrosFactory', 'UsuariosFactory'];

function ConfiguracionAutorizacionesFormCtrl($rootScope,filterFactory, $scope, ConfiguracionAutorizacionesFactory, $location, $state,
  configuracionAutorizacionesPrepService, notify, formFactory, VentasLangFactory, TiposFactory,TiposTipoFactory, $timeout, $filter, ParametrosFactory, UsuariosFactory) {


  var vm = this;

  activate();

  vm.cancel = cancel;
  vm.submit = submit;
  vm.changeVisibleImporte = changeVisibleImporte;

  function activate() {
    VentasLangFactory.getTranslations().then(function(translations) {
      //usuarios
      var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();
        vm.usuarios = UsuariosFactory.all(filtroActivo);
        vm.procesos = TiposTipoFactory.get("proceso_autorizar");
        vm.condicionales = TiposTipoFactory.get("condicionar");
        vm.estados = TiposTipoFactory.get("estado_autorizacion");

      $scope.translations=translations;
      if($state.is("app.configuracionautorizaciones.new")) {
        activateNew();
      } else if($state.is("app.configuracionautorizaciones.edit")) {
        activateEdit();
      } else if($state.is("app.configuracionautorizaciones.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited =false;
    });
  }

  function activateNew() {
    vm.title = 'Nuevo Configuración Autorizaciones de Límite de Crédito';
    vm.configuracionautorizaciones = {};
    vm.habilitarImportes = true;
    vm.procesos.$promise.then(function(data){
      _.forEach(data, function(pp){
        if(pp.codigo == 'sobregiro_limite_credito'){
          vm.configuracionautorizaciones.procesoAutorizar = pp;
        }
      }); 
    })
    vm.condicionales.$promise.then(function(data){
      _.forEach(data, function(pp){
        if(pp.codigo == 'condicionar_no'){
          vm.configuracionautorizaciones.condicionar = pp;
        };
      });
    })

    vm.estados.$promise.then(function(data){
      _.forEach(data, function(pp){
        if(pp.codigo == 'estado_autorizacion_activado'){
          vm.configuracionautorizaciones.estado = pp;
        };
      });
    })
  }

  function activateEdit() {
    vm.title = 'Editar Configuración Autorizaciones de Límite de Crédito';
    vm.configuracionautorizaciones = configuracionAutorizacionesPrepService;
    if(vm.configuracionautorizaciones.condicionar.codigo == 'condicionar_no'){
      vm.habilitarImportes = true;
    }
    vm.entidadId = vm.configuracionautorizaciones.id;
    vm.entidad = "ConfiguracionAutorizaciones";
  }

  function activateView() {
    vm.title = 'Ver Configuración Autorizaciones de Límite de Crédito';
    vm.configuracionautorizaciones = configuracionAutorizacionesPrepService;
    vm.entidadId = vm.configuracionautorizaciones.id;
    vm.entidad = "ConfiguracionAutorizaciones";
    vm.view = true;
  }

  function changeVisibleImporte(){
    vm.habilitarImportes = true;
    if(vm.configuracionautorizaciones.condicionar.codigo == 'condicionar_si'){
      vm.habilitarImportes = false;
    }

    if(vm.configuracionautorizaciones.condicionar.codigo == 'condicionar_no'){
      vm.habilitarImportes = true;
      vm.configuracionautorizaciones.importeDesde = 0;
      vm.configuracionautorizaciones.importeHasta = 0;
    }
  }

  function submit() {
    vm.submited = true;
    if(vm.configuracionautorizaciones.condicionar.codigo == 'condicionar_si' && vm.configuracionautorizaciones.importeHasta <= vm.configuracionautorizaciones.importeDesde) {
      notify({ message: "El Importe Hasta no puede ser menor ni igual al Importe Desde", classes: 'alert-danger', position: 'right' });
      $rootScope.isProcessing = false;
      return;
    }
    if($scope.ConfiguracionAutorizacionesForm.$valid) {
      $rootScope.isProcessing = true;

      submitConfiguracionAutorizaciones().then(function () {
        $state.go('app.configuracionautorizaciones.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        console.log(error);
        var msg = "";
        _.forEach(error.data, function(e) {
          msg += '\n\n' + e.message + '.';
        });
        notify({message: msg, classes: 'alert-danger', position: 'right'});
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right'});    }
  }

  function submitConfiguracionAutorizaciones() {
    var resource = ConfiguracionAutorizacionesFactory.create(vm.configuracionautorizaciones);
    return ConfiguracionAutorizacionesFactory.save(resource);
  }

  function cancel() {
    $state.go('app.configuracionautorizaciones.list');
  }

  $scope.resource = 'configuracionautorizaciones';
}
/**
 * Created by codiumsa on 19/10/15.
 */
 angular.module('qualita.venta')
 .factory('ConfiguracionAutorizacionesFactory', ConfiguracionAutorizacionesFactory);

 ConfiguracionAutorizacionesFactory.$inject = ['$resource', 'baseurl', '$http', 'VentasLangFactory'];

function ConfiguracionAutorizacionesFactory($resource, baseurl, $http, VentasLangFactory) {
 var service = {
   all: all,
   get: get,
   create: create,
   save: save,
   remove: remove,
   activar: activar,
   desactivar: desactivar
 };

 var ConfiguracionAutorizaciones = $resource( baseurl.getBaseUrl() + "/configuracionautorizaciones/:id", {id: '@id'}, {
   update: {
     method: 'PUT'
   },
   activar: {
       method: 'PUT',
       url: baseurl.getBaseUrl() + "/configuracionautorizaciones/activar/:id"
   },
   desactivar: {
       method: 'PUT',
       url: baseurl.getBaseUrl() + "/configuracionautorizaciones/desactivar/:id"
   }
 });


 return service;

 function all(params, view) {
   if (params) {
     params.view = view!=undefined ? view : 'base';
   } else {
     params = [];
     params.view = view!=undefined ? view : 'base';
   }
   return ConfiguracionAutorizaciones.query(params);
 }

 function get(id, view) {
   return ConfiguracionAutorizaciones.get({id: id, view: view ? view : "base"});
 }

 function create(attrs) {
   return new ConfiguracionAutorizaciones(attrs);
 }

 function save(configuracionautorizaciones) {
   return (configuracionautorizaciones.id) ? configuracionautorizaciones.$update() : configuracionautorizaciones.$save();
 }

 function remove(configuracionautorizaciones) {
   return configuracionautorizaciones.$remove();
 }

 function activar(id) {
   return ConfiguracionAutorizaciones.activar({ id: id }).$promise;
 }

 function desactivar(id) {
   return ConfiguracionAutorizaciones.desactivar({ id: id }).$promise;
 }
}
'use strict';

angular.module('qualita.venta')
    .controller('SolicitudAutorizacionIndexCtrl', ["$state", "$scope", "SolicitudAutorizacionFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "$modal", "VentaFactory", "$rootScope", "ConfiguracionAutorizacionesFactory", "notify", "ParametrosFactory", "ComprobanteVentaFactory", function ($state, $scope, SolicitudAutorizacionFactory, filterFactory,
        ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl, AuthorizationService, $modal, VentaFactory,
        $rootScope, ConfiguracionAutorizacionesFactory, notify, ParametrosFactory, ComprobanteVentaFactory) {

        var vm = this;

        var dateRender = function (data, type, full) {
            if (data) {
                return moment.utc(data).format('DD/MM/YYYY');
            } else {
                return "";
            }
        };

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        var monedaRender = function (data, type, row) {
            var campoMoneda = row.moneda;

            if (data != undefined) {
                data = Math.round(data);
                var moneda = "Gs. ";
                // TODO hacer esto mismo en todos los renders de moneda
                return (
                    moneda +
                    data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            } else {
                return "";
            }
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'fecha', 'solicitadoPor', 'clienteSobregiro.nombre',
                'origenSolicitud', 'numeroProcesoOrigen', 'sobregiroSolicitado',
                'motivoSolicitud.descripcion',
                'decision.descripcion', 'estado.descripcion', 'comentario',
                'procesadoPor', 'procesadoFecha', 'configuracionAutorizaciones.id'
            ];
            $scope.options = {
                'resource': 'solicitudautorizacion',
                'title': 'Solicitud de Autorización de Límite de Crédito',
                'factory': SolicitudAutorizacionFactory,
                'view': 'SolicitudAutorizacionList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': translations.FAIL_DELETE_CLIENTE,
                'columns': [
                    { 'data': 'id', 'title': 'Número', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'fecha', 'title': 'Fecha', renderWith: 'dateRender', dateFormat: 'DD/MM/YYYY', class: 'dt-center', type: 'date-range' },
                    { 'data': 'solicitadoPor', 'title': 'Solicitado Por', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'clienteSobregiro.nombre', 'title': 'Cliente', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'origenSolicitud', 'title': 'Origen', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'numeroProcesoOrigen', 'title': 'Nro. Proceso Origen', renderWith: renderEmptyData, 'class': 'dt-left', visible: false },
                    { 'data': "sobregiroSolicitado", 'title': 'Importe Solicitado', 'renderWith': monedaRender, class: "dt-right" },
                    { 'data': 'motivoSolicitud.descripcion', 'title': 'Motivo', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'decision.descripcion', 'title': 'Decisión', renderWith: renderEmptyData, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/decision_solicitud_autorizacion' },
                    { 'data': 'estado.descripcion', 'title': 'Estado', 'render': renderEmptyData, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/estado_solicitud_autorizacion' },
                    { 'data': 'comentario', 'title': 'Comentario', renderWith: renderEmptyData, 'class': 'dt-left', visible: false },
                    { 'data': 'procesadoPor', 'title': 'Procesado Por', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'procesadoFecha', 'title': 'Procesado en Fecha', renderWith: 'dateRender', dateFormat: 'DD/MM/YYYY', class: 'dt-center', type: 'date-range' },
                    { 'data': 'configuracionAutorizaciones.id', 'title': 'Nro. Configuración', renderWith: renderEmptyData, 'class': 'dt-left', visible: false },
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                    [
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("solicitudautorizacion", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoSolicitudAutorizacion', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }

                    ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') +
                            "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: ["id", "estado", "motivoSolicitud"],
                        conditionName: 'canEditar',
                        conditionDef: function (atributos) {
                            var estado = atributos[1];
                            if (AuthorizationService.hasPermission("update_solicitudautorizacion") === true && estado.codigo === 'estado_solicitud_autorizacion_pendiente') {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "editar",
                        functionDef: function (atributos) {
                            var itemId = atributos[0];
                            var idUsuarioActual = $rootScope.AuthParams.accesoSistema.usuario.id;
                            var idProceso = atributos[2];
                            //controlar que el usuario sea autorizador
                            var filtro = {};
                            filtro.search = filterFactory.and([{
                                path: 'usuarioAutorizador.id',
                                equals: idUsuarioActual
                            },
                            {
                                path: 'estado.codigo',
                                like: 'estado_autorizacion_activado'
                            },
                            {
                                path: 'procesoAutorizar.id',
                                equals: idProceso.id
                            }]).value();
                            ConfiguracionAutorizacionesFactory.all(filtro, "ConfiguracionAutorizacionesList").$promise.then(function (response) {
                                if (AuthorizationService.hasPermission("update_solicitudautorizacion") === true && response.length != 0) {
                                    $state.go('app.solicitudautorizacion.edit', { id: itemId });
                                } else {
                                    notify({ message: 'Error. No posee permiso para realizar la operación o no eres Usuario Autorizador', classes: 'alert-danger', position: 'right' });
                                }
                            });
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataCustom%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: ["id", "estado", "motivoSolicitud"],
                        conditionName: "canBorrar",
                        conditionDef: function (atributos) {
                            var estado = atributos[1];
                            if (AuthorizationService.hasPermission("delete_solicitudautorizacion") === true && estado.codigo === 'estado_solicitud_autorizacion_pendiente') {
                                return false;

                            } else {
                                return false;
                            }
                        },
                        functionName: "borrar",
                        functionDef: function (atributos) {
                            $scope.selectedItemId = atributos[0];
                            var idUsuarioActual = $rootScope.AuthParams.accesoSistema.usuario.id;
                            var idProceso = atributos[2];
                            //controlar que el usuario sea autorizador
                            var filtro = {};
                            filtro.search = filterFactory.and([{
                                path: 'usuarioAutorizador.id',
                                equals: idUsuarioActual
                            },
                            {
                                path: 'estado.codigo',
                                like: 'estado_autorizacion_activado'
                            },
                            {
                                path: 'procesoAutorizar.id',
                                equals: idProceso.id
                            }]).value();
                            ConfiguracionAutorizacionesFactory.all(filtro, "ConfiguracionAutorizacionesList").$promise.then(function (response) {
                                if (AuthorizationService.hasPermission("delete_solicitudautorizacion") === true && response.length != 0) {
                                    $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                    $scope.mensajeModal = $filter('translate')('DELETE_WARNING');

                                    $scope.modalInstanceBorrar1 = $modal.open({
                                        template: '<div class="modal-header">' +
                                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                            '</div>' +
                                            '<div class="modal-body">{{::mensajeModal}}' +
                                            '<hr width="1%">' +
                                            '</div>' +
                                            '<div class="modal-footer">' +
                                            '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                            '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                            '</div>',
                                        scope: $scope
                                    });

                                    $scope.cancel = function () {
                                        $scope.modalInstanceBorrar1.dismiss('cancel');
                                    };

                                    $scope.ok = function (itemId) {
                                        var model = SolicitudAutorizacionFactory.create({ id: itemId });
                                        SolicitudAutorizacionFactory.remove(model).then(
                                            function () {
                                                $scope.modalInstanceBorrar1.close(itemId);
                                                $state.go($state.current, {}, { reload: true });
                                            },
                                            function (error) {
                                                $scope.modalInstanceBorrar1.dismiss("cancel");
                                                $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                                $scope.mensajeModal = $filter("translate")(
                                                    "DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS"
                                                );
                                                var modalInstance = $modal.open({
                                                    template: '<div class="modal-header">' +
                                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                        "</div>" +
                                                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                        '<div class="modal-footer">' +
                                                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                        "</div>",
                                                    scope: $scope
                                                });
                                                $scope.cancel = function () {
                                                    modalInstance.dismiss("cancel");
                                                };
                                            }
                                        );
                                    };
                                } else {
                                    notify({ message: 'Error. No posee permiso para realizar la operación o no eres Usuario Autorizador', classes: 'alert-danger', position: 'right' });
                                }
                            });
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                            $filter("translate")("VIEW") +
                            "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function (itemId) {
                            if (AuthorizationService.hasPermission("index_solicitudautorizacion") === true) {
                                $state.go("app.solicitudautorizacion.view", { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        },
                        conditionName: "canVer",
                        conditionDef: function () {
                            return true;
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-secondary' style='margin-right: 5px;' title='" +
                            'Ver Proceso Origen' +
                            "' ng-click='verOrigen(<%=dataId%>)' ng-class='{ hidden : !canVerOrigen()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "verOrigen",
                        functionDef: function (itemId) {
                            var idUsuarioActual = $rootScope.AuthParams.accesoSistema.usuario.id;
                            //controlar que el usuario sea autorizador
                            var filtro = {};
                            filtro.search = filterFactory.and([{
                                path: 'usuarioAutorizador.id',
                                equals: idUsuarioActual
                            },
                            {
                                path: 'estado.codigo',
                                like: 'estado_autorizacion_activado'
                            },
                            {
                                path: 'procesoAutorizar.id',
                                equals: idProceso
                            }]).value();
                            ConfiguracionAutorizacionesFactory.all(filtro, "ConfiguracionAutorizacionesList").$promise.then(function (response) {
                                if (AuthorizationService.hasPermission("ver_solicitudautorizacion") === true && response.length != 0) {
                                    SolicitudAutorizacionFactory.get(itemId, "SolicitudAutorizacionForm").$promise.then(function (response) {
                                        if (response) {
                                            var origen = response.origenSolicitud;
                                            var nroOrigen = response.numeroProcesoOrigen;
                                            if (origen == 'Pedido Venta') {
                                                $state.go("app.pedidoventas.view", { id: nroOrigen });
                                            } else if (origen == 'Flujo Venta') {
                                                redirectVenta(nroOrigen, '1');
                                            }
                                        }
                                    });
                                } else {
                                    notify({ message: 'Error. No posee permiso para realizar la operación o no eres Usuario Autorizador', classes: 'alert-danger', position: 'right' });
                                }
                            });
                        },
                        conditionName: "canVerOrigen",
                        conditionDef: function () {
                            return false;
                        }
                    },
                    {
                        templateToRender: "<button title='" +
                            'Procesar' +
                            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='procesar(<%=dataCustom%>)' ng-class='{ hidden : !canProcesar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ok'></span> </button>",
                        functionName: "procesar",
                        conditionName: "canProcesar",
                        customAttribute: ["id", "estado", "motivoSolicitud"],
                        conditionDef: function (atributos) {
                            var estado = atributos[1];

                            if (AuthorizationService.hasPermission("procesar_solicitudautorizacion") === true && estado.codigo === 'estado_solicitud_autorizacion_pendiente') {
                                return true;
                            } else {
                                return false;
                            }
                        },

                        functionDef: function (atributos) {
                            var idUsuarioActual = $rootScope.AuthParams.accesoSistema.usuario.id;
                            var itemId = atributos[0];
                            var idProceso = atributos[2];
                            //controlar que el usuario sea autorizador
                            var filtro = {};
                            filtro.search = filterFactory.and([{
                                path: 'usuarioAutorizador.id',
                                equals: idUsuarioActual
                            },
                            {
                                path: 'estado.codigo',
                                like: 'estado_autorizacion_activado'
                            },
                            {
                                path: 'procesoAutorizar.id',
                                equals: idProceso.id
                            }]).value();
                            SolicitudAutorizacionFactory.get(itemId, "SolicitudAutorizacionForm").$promise.then(function (response) {
                                ConfiguracionAutorizacionesFactory.all(filtro, "ConfiguracionAutorizacionesList").$promise.then(function (response1) {

                                    if (AuthorizationService.hasPermission("procesar_solicitudautorizacion") === true && response1.length != 0) {
                                        if (response && response.decision != null) {
                                            $scope.mensajeModal = 'Esta seguro de querer procesar la Solicitud de Autorización?';
                                            $scope.tituloModal = 'Procesar';
                                            var modalInstance = $modal.open({
                                                templateUrl: "views/directives/confirmation-modal.html",
                                                scope: $scope
                                            });

                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };

                                            $scope.ok = function (id) {
                                                SolicitudAutorizacionFactory.procesar(itemId).then(function (response) {
                                                    modalInstance.close();
                                                    $state.go($state.current, {}, { reload: true });
                                                }, function (error) {
                                                    var mensaje = "<span>";
                                                    _.forEach(error.data, function (err) {
                                                        mensaje = mensaje + err.message + "<br/>";
                                                    });
                                                    mensaje = mensaje + "</span>";
                                                    notify({
                                                        messageTemplate: mensaje,
                                                        classes: "alert-danger",
                                                        position: "right",
                                                        templateUrl: ""
                                                    });
                                                });
                                            };
                                        } else {
                                            notify({ message: 'Error. Primero debe de tomar una decisión antes de procesar', classes: 'alert-danger', position: 'right' });
                                        }
                                    } else {
                                        notify({ message: 'Error. No posee permiso para realizar la operación o no eres Usuario Autorizador', classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            });
                        }
                    },
                    {
                        templateToRender: "<button title='" +
                            "Reimprimir Solicitud" +
                            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reimprimir(<%=dataCustom%>)' ng-class='{ hidden : !canReimprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-repeat'></span> </button>",
                        functionName: "reimprimir",
                        conditionName: "canReimprimir",
                        customAttribute: ["estado", "id"],
                        conditionDef: function (atributos) {
                            return true;
                        },
                        functionDef: function (atributos) {
                            var itemId = atributos[1];

                            $scope.mensajeModal = "Desea realizar una reimpresión de la Solicitud?";
                            $scope.tituloModal = "Reimpresión de Solicitud";
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss("cancel");
                            };

                            $scope.ok = function (id) {
                                SolicitudAutorizacionFactory.get(itemId, "SolicitudAutorizacionList").$promise.then(function (res) {
                                    console.log("res ", res)
                                    //obtener el id del pedido de venta
                                    var idProcesoOrigen = res.numeroProcesoOrigen;
                                    //obtener el motivo
                                    var motivo = res.motivoSolicitud.codigo;

                                    //verificar si es desde Pedido de Venta 
                                    if (res.origenSolicitud == "Pedido Venta") {
                                        //si el motivo es sobregiro imprimir la solicitud de sobregiro
                                        if (motivo == "sobregiro_limite_credito") {
                                            ParametrosFactory.getByCodigo("nombre_empresa").then(function (data) {
                                                var filtersArr = [];
                                                filtersArr.push("idpedidoventa=" + idProcesoOrigen);
                                                filtersArr.push("nombre_empresa=" + data.valor);
                                                filtersArr.push("id_sucursal=" + $rootScope.AuthParams.locacionSeleccionada.sucursal.id);
                                                filtersArr.push("id_unidadnegocio=" + $rootScope.AuthParams.locacionSeleccionada.unidadNegocio.id);
                                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                    "solicitudautorizacion",
                                                    "pdf",
                                                    filtersArr
                                                );
                                                $window.open(vm.pdfDownloadURL, "_blank");
                                                $state.go($state.current, {}, { reload: true });
                                            });

                                        } else if (motivo == "ignorar_venc_cuota_tolerancia") {
                                            //si el motivo es ignorar vencimientos imprimir la solicitud para ignorar vencimiento
                                            var filtersArr = [];
                                            filtersArr.push("idpedidoventa=" + idProcesoOrigen);
                                            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                "solicitudautorizacionbloqueo",
                                                "pdf",
                                                filtersArr
                                            );
                                            $window.open(vm.pdfDownloadURL, "_blank");
                                            $state.go($state.current, {}, { reload: true });
                                        }
                                    } else if (res.origenSolicitud == "Comprobante Venta") {
                                        //si el motivo es sobregiro imprimir la solicitud de sobregiro
                                        if (motivo == "sobregiro_limite_credito") {
                                            ParametrosFactory.getByCodigo("nombre_empresa").then(function (data) {
                                                var filtersArr = [];
                                                filtersArr.push("idcomprobanteventa=" + idProcesoOrigen);
                                                filtersArr.push("nombre_empresa=" + data.valor);
                                                filtersArr.push("id_sucursal=" + $rootScope.AuthParams.locacionSeleccionada.sucursal.id);
                                                filtersArr.push("id_unidadnegocio=" + $rootScope.AuthParams.locacionSeleccionada.unidadNegocio.id);
                                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                    "solicitudautorizacioncomprobante",
                                                    "pdf",
                                                    filtersArr
                                                );
                                                $window.open(vm.pdfDownloadURL, "_blank");
                                                $state.go($state.current, {}, { reload: true });
                                            });
                                        } else if (motivo == "ignorar_venc_cuota_tolerancia") {
                                            //luego de imprimir el reporte de bloqueo, continuar con el sobregiro
                                            var filtersArr = [];
                                            filtersArr.push("idcomprobanteventa=" + itemId);
                                            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                "solicitudautorizacioncomprobantebloqueo",
                                                "pdf",
                                                filtersArr
                                            );
                                            $window.open(vm.pdfDownloadURL, "_blank");
                                            $state.go($state.current, {}, { reload: true });
                                        }
                                    } else if (res.origenSolicitud == "Flujo Venta") {
                                        //si el motivo es sobregiro imprimir la solicitud de sobregiro
                                        if (motivo == "sobregiro_limite_credito") {
                                            //obtener el comprobante al cual se aplico la solicitud dentro del flujo
                                            var filtroComprobante = {};
                                            filtroComprobante.search = filterFactory.and([
                                                {
                                                    path: 'venta.id',
                                                    equals: idProcesoOrigen
                                                },
                                                {
                                                    path: 'solicitudAutorizacion.id',
                                                    equals: itemId
                                                }]).value();

                                            ComprobanteVentaFactory.all(filtroComprobante, "ComprobanteVentaList").$promise.then(function (response) {
                                                ParametrosFactory.getByCodigo("nombre_empresa").then(function (data) {
                                                    var filtersArr = [];
                                                    filtersArr.push("idcomprobanteventa=" + response[0].id);
                                                    filtersArr.push("nombre_empresa=" + data.valor);
                                                    filtersArr.push("id_sucursal=" + $rootScope.AuthParams.locacionSeleccionada.sucursal.id);
                                                    filtersArr.push("id_unidadnegocio=" + $rootScope.AuthParams.locacionSeleccionada.unidadNegocio.id);
                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                        "solicitudautorizacioncomprobante",
                                                        "pdf",
                                                        filtersArr
                                                    );
                                                    $window.open(vm.pdfDownloadURL, "_blank");
                                                    $state.go($state.current, {}, { reload: true });
                                                });
                                            });
                                        } else if (motivo == "ignorar_venc_cuota_tolerancia") {
                                            //obtener el comprobante al cual se aplico la solicitud dentro del flujo
                                            var filtroComprobante = {};
                                            filtroComprobante.search = filterFactory.and([
                                                {
                                                    path: 'venta.id',
                                                    equals: idProcesoOrigen
                                                },
                                                {
                                                    path: 'solicitudAutorizacionBloqueo.id',
                                                    equals: itemId
                                                }]).value();

                                            ComprobanteVentaFactory.all(filtroComprobante, "ComprobanteVentaList").$promise.then(function (response) {
                                                console.log("responseign ", response)
                                                var filtersArr = [];
                                                filtersArr.push("idcomprobanteventa=" + response[0].id);
                                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                    "solicitudautorizacioncomprobantebloqueo",
                                                    "pdf",
                                                    filtersArr
                                                );
                                                $window.open(vm.pdfDownloadURL, "_blank");
                                                $state.go($state.current, {}, { reload: true });
                                            });
                                        }
                                    }
                                });
                            }
                        }
                    }
                ]
            };

            function redirectVenta(itemId, modo) {
                VentaFactory.get(itemId, "BaseList").$promise.then(function (vnt) {
                    VentaFactory.setIdVenta(vnt.id);
                    VentaFactory.setCodigoVenta(vnt.codigo);
                    VentaFactory.setVenta(vnt);
                    VentaFactory.setModoAcceso(modo);
                    var tipo = vnt.tipo;
                    if (vnt.estado.codigo == "venta_anulada") {
                        $state.go("app.ventas.proceso.comprobanteventa.list", {
                            codigoVenta: itemId,
                            modeAccess: modo
                        });
                    }
                    if (vnt.estado.codigo == "venta_abierta") {
                        var primerTipo = _.find(tipo.tipoVentaDetalles, function (detalle) {
                            return detalle.orden == 1;
                        });
                        if (
                            primerTipo &&
                            primerTipo.componente.codigo == "comp_tipo_venta_pedido_venta"
                        ) {
                            $state.go("app.ventas.proceso.pedidosventa.list", {
                                codigoVenta: itemId
                            });
                        }

                        if (
                            primerTipo &&
                            primerTipo.componente.codigo == "comp_tipo_venta_remision"
                        ) {
                            $state.go("app.ventas.proceso.remision.list", {
                                codigoVenta: itemId,
                                modeAccess: modo
                            });
                        }

                        if (
                            primerTipo &&
                            primerTipo.componente.codigo == "comp_tipo_venta_comprobante"
                        ) {
                            $state.go("app.ventas.proceso.comprobanteventa.list", {
                                codigoVenta: itemId,
                                modeAccess: modo
                            });
                        }
                    }
                    if (vnt.estado.codigo == "venta_con_pv") {
                        var tipoPV = _.find(tipo.tipoVentaDetalles, function (detalle) {
                            return detalle.componente.codigo == "comp_tipo_venta_pedido_venta";
                        });

                        var tipoActual = _.find(tipo.tipoVentaDetalles, function (detalle) {
                            return detalle.orden == tipoPV.orden + 1;
                        });

                        if (!tipoActual) {
                            $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });
                        } else if (tipoActual.componente.codigo == "comp_tipo_venta_remision") {
                            $state.go("app.ventas.proceso.remision.list", {
                                codigoVenta: itemId,
                                modeAccess: modo
                            });
                        } else if (
                            tipoActual.componente.codigo == "comp_tipo_venta_comprobante"
                        ) {
                            $state.go("app.ventas.proceso.comprobanteventa.list", {
                                codigoVenta: itemId,
                                modeAccess: modo
                            });
                        }
                    }
                    if (vnt.estado.codigo == "venta_con_remision") {
                        var tipoRemision = _.find(tipo.tipoVentaDetalles, function (detalle) {
                            return detalle.componente.codigo == "comp_tipo_venta_remision";
                        });

                        var tipoActual = _.find(tipo.tipoVentaDetalles, function (detalle) {
                            return detalle.orden == tipoRemision.orden + 1;
                        });

                        if (!tipoActual) {
                            $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });
                        } else if (
                            tipoActual.componente.codigo == "comp_tipo_venta_pedido_venta"
                        ) {
                            $state.go("app.ventas.proceso.pedidosventa.list", {
                                codigoVenta: itemId
                            });
                        } else if (
                            tipoActual.componente.codigo == "comp_tipo_venta_comprobante"
                        ) {
                            $state.go("app.ventas.proceso.comprobanteventa.list", {
                                codigoVenta: itemId,
                                modeAccess: modo
                            });
                        }
                    }
                    if (vnt.estado.codigo == "venta_con_comprobante") {
                        var tipoComprobante = _.find(tipo.tipoVentaDetalles, function (detalle) {
                            return detalle.componente.codigo == "comp_tipo_venta_comprobante";
                        });

                        var tipoActual = _.find(tipo.tipoVentaDetalles, function (detalle) {
                            return detalle.orden == tipoComprobante.orden + 1;
                        });

                        if (!tipoActual) {
                            $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });
                        } else if (tipoActual.componente.codigo == "comp_tipo_venta_remision") {
                            $state.go("app.ventas.proceso.remision.list", {
                                codigoVenta: itemId,
                                modeAccess: modo
                            });
                        } else if (
                            tipoActual.componente.codigo == "comp_tipo_venta_pedidos_venta"
                        ) {
                            $state.go("app.ventas.proceso.pedidosventa.list", {
                                codigoVenta: itemId
                            });
                        }
                    }
                    if (vnt.estado.codigo == "venta_cerrada") {
                        $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });
                    }
                });
            }

            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if (search === 'No' && data === 'activo') {
                        search = 'false';
                    }
                    if (search === 'Sí' && data === 'activo') {
                        search = 'true';
                    }

                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);
'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:ProductosNewCtrl
 * @description
 * # ProductosNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.venta')
    .controller('SolicitudAutorizacionFormCtrl', SolicitudAutorizacionFormCtrl);

SolicitudAutorizacionFormCtrl.$inject = ['$rootScope', 'filterFactory', '$scope', 'SolicitudAutorizacionFactory', '$location', '$state',
    'solicitudAutorizacionPrepService', 'notify', 'formFactory', 'VentasLangFactory', 'TiposTipoFactory', 'TipoClienteFactory', '$timeout', '$filter', 'ParametrosFactory', 'VentaFactory'];

function SolicitudAutorizacionFormCtrl($rootScope, filterFactory, $scope, SolicitudAutorizacionFactory, $location, $state,
    solicitudAutorizacionPrepService, notify, formFactory, VentasLangFactory, TiposTipoFactory, TipoClienteFactory, $timeout, $filter, ParametrosFactory, VentaFactory) {

    var vm = this;

    activate();

    vm.cancel = cancel;
    vm.submit = submit;
    vm.verProcesoOrigen = verProcesoOrigen;
    vm.verCliente = verCliente;


    function activate() {
        VentasLangFactory.getTranslations().then(function (translations) {
            vm.decisiones = TiposTipoFactory.get("decision_solicitud_autorizacion");
            $scope.translations = translations;

            if ($state.is("app.solicitudautorizacion.new")) {
                activateNew();
            } else if ($state.is("app.solicitudautorizacion.edit")) {
                activateEdit();
            } else if ($state.is("app.solicitudautorizacion.view")) {
                activateView();
            }
            $rootScope.isProcessing = false;
            $scope.submited = false;
        });
    }

    function activateNew() {
        vm.title = 'Nueva Solicitud de Autorización de Límite de Crédito';
        vm.solicitudautorizacion = {};
    }

    function activateEdit() {
        console.log("edit");
        vm.title = 'Editar Solicitud de Autorización de Límite de Crédito';
        vm.solicitudautorizacion = solicitudAutorizacionPrepService;
        vm.solicitudautorizacion.sobregiroSolicitado = Math.round(vm.solicitudautorizacion.sobregiroSolicitado);
        vm.solicitudautorizacion.fecha = new Date(vm.solicitudautorizacion.fecha);
        vm.entidadId = vm.solicitudautorizacion.id;
        vm.entidad = "SolicitudAutorizacion";
    }

    function activateView() {
        console.log("view");
        vm.title = 'Ver Solicitud de Autorización de Límite de Crédito';
        vm.solicitudautorizacion = solicitudAutorizacionPrepService;
        vm.solicitudautorizacion.sobregiroSolicitado = Math.round(vm.solicitudautorizacion.sobregiroSolicitado);
        vm.solicitudautorizacion.fecha = new Date(vm.solicitudautorizacion.fecha);
        if (vm.solicitudautorizacion.procesadoFecha != null) {
            vm.solicitudautorizacion.procesadoFecha = new Date(vm.solicitudautorizacion.procesadoFecha);
        }
        vm.entidadId = vm.solicitudautorizacion.id;
        vm.entidad = "SolicitudAutorizacion";
        vm.view = true;
    }

    function verProcesoOrigen() {
        var origen = vm.solicitudautorizacion.origenSolicitud;
        var nroOrigen = vm.solicitudautorizacion.numeroProcesoOrigen;
        if (origen == 'Pedido Venta') {
            $state.go("app.pedidoventas.view", { id: nroOrigen });
        } else if (origen == 'Flujo Venta') {
            redirectVenta(nroOrigen, '1');
        } else if (origen == 'Comprobante Venta') {
            $state.go("app.consultacomprobantesventa.view", { id: nroOrigen });
        }
    }

    function verCliente() {
        var idCliente = vm.solicitudautorizacion.clienteSobregiro.id;
        $state.go("app.clientes.view", { id: idCliente });
    }

    function redirectVenta(itemId, modo) {
        VentaFactory.get(itemId, "BaseList").$promise.then(function (vnt) {
            VentaFactory.setIdVenta(vnt.id);
            VentaFactory.setCodigoVenta(vnt.codigo);
            VentaFactory.setVenta(vnt);
            VentaFactory.setModoAcceso(modo);
            var tipo = vnt.tipo;
            if (vnt.estado.codigo == "venta_anulada") {
                $state.go("app.ventas.proceso.comprobanteventa.list", {
                    codigoVenta: itemId,
                    modeAccess: modo
                });
            }
            if (vnt.estado.codigo == "venta_abierta") {
                var primerTipo = _.find(tipo.tipoVentaDetalles, function (detalle) {
                    return detalle.orden == 1;
                });
                if (
                    primerTipo &&
                    primerTipo.componente.codigo == "comp_tipo_venta_pedido_venta"
                ) {
                    $state.go("app.ventas.proceso.pedidosventa.list", {
                        codigoVenta: itemId
                    });
                }

                if (
                    primerTipo &&
                    primerTipo.componente.codigo == "comp_tipo_venta_remision"
                ) {
                    $state.go("app.ventas.proceso.remision.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                }

                if (
                    primerTipo &&
                    primerTipo.componente.codigo == "comp_tipo_venta_comprobante"
                ) {
                    $state.go("app.ventas.proceso.comprobanteventa.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                }
            }
            if (vnt.estado.codigo == "venta_con_pv") {
                var tipoPV = _.find(tipo.tipoVentaDetalles, function (detalle) {
                    return detalle.componente.codigo == "comp_tipo_venta_pedido_venta";
                });

                var tipoActual = _.find(tipo.tipoVentaDetalles, function (detalle) {
                    return detalle.orden == tipoPV.orden + 1;
                });

                if (!tipoActual) {
                    $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });
                } else if (tipoActual.componente.codigo == "comp_tipo_venta_remision") {
                    $state.go("app.ventas.proceso.remision.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                } else if (
                    tipoActual.componente.codigo == "comp_tipo_venta_comprobante"
                ) {
                    $state.go("app.ventas.proceso.comprobanteventa.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                }
            }
            if (vnt.estado.codigo == "venta_con_remision") {
                var tipoRemision = _.find(tipo.tipoVentaDetalles, function (detalle) {
                    return detalle.componente.codigo == "comp_tipo_venta_remision";
                });

                var tipoActual = _.find(tipo.tipoVentaDetalles, function (detalle) {
                    return detalle.orden == tipoRemision.orden + 1;
                });

                if (!tipoActual) {
                    $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });
                } else if (
                    tipoActual.componente.codigo == "comp_tipo_venta_pedido_venta"
                ) {
                    $state.go("app.ventas.proceso.pedidosventa.list", {
                        codigoVenta: itemId
                    });
                } else if (
                    tipoActual.componente.codigo == "comp_tipo_venta_comprobante"
                ) {
                    $state.go("app.ventas.proceso.comprobanteventa.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                }
            }
            if (vnt.estado.codigo == "venta_con_comprobante") {
                var tipoComprobante = _.find(tipo.tipoVentaDetalles, function (detalle) {
                    return detalle.componente.codigo == "comp_tipo_venta_comprobante";
                });

                var tipoActual = _.find(tipo.tipoVentaDetalles, function (detalle) {
                    return detalle.orden == tipoComprobante.orden + 1;
                });

                if (!tipoActual) {
                    $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });
                } else if (tipoActual.componente.codigo == "comp_tipo_venta_remision") {
                    $state.go("app.ventas.proceso.remision.list", {
                        codigoVenta: itemId,
                        modeAccess: modo
                    });
                } else if (
                    tipoActual.componente.codigo == "comp_tipo_venta_pedidos_venta"
                ) {
                    $state.go("app.ventas.proceso.pedidosventa.list", {
                        codigoVenta: itemId
                    });
                }
            }
            if (vnt.estado.codigo == "venta_cerrada") {
                $state.go("app.ventas.proceso.cierre.list", { codigoVenta: itemId });
            }
        });
    }

    function submit() {
        vm.submited = true;
        if ($scope.SolicitudAutorizacionForm.$valid) {
            $rootScope.isProcessing = true;

            submitSolicitudAutorizacion().then(function () {
                $state.go('app.solicitudautorizacion.list');
            }, function (error) {
                $rootScope.isProcessing = false;
                console.log(error);
                var msg = "";
                _.forEach(error.data, function (e) {
                    msg += '\n\n' + e.message + '.';
                });
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            });
        } else {
            notify({ message: $scope.translations.WRONG_FIELDS, classes: 'alert-danger', position: 'right' });
        }
    }

    function submitSolicitudAutorizacion() {
        var resource = SolicitudAutorizacionFactory.create(vm.solicitudautorizacion);
        return SolicitudAutorizacionFactory.save(resource);
    }

    function cancel() {
        $state.go('app.solicitudautorizacion.list');
    }

    $scope.resource = 'solicitudautorizacion';
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.cuentabancaria
 * @description
 * # cuentabancaria
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('SolicitudAutorizacionFactory', ['$resource', 'baseurl', function ($resource, baseurl) {

    var SolicitudAutorizacion = $resource(baseurl.getBaseUrl() + '/solicitudautorizacion/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      },
      procesar: {
        method: 'PUT',
        url: baseurl.getBaseUrl() + "/solicitudautorizacion/procesar/:id"
    }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return SolicitudAutorizacion.query(params);
      },

      get: function (id, view) {
        return SolicitudAutorizacion.get({ id: id, view: view != undefined ? view : 'base' });
      },

      create: function (attrs) {
        return new SolicitudAutorizacion(attrs);
      },

      save: function (solicitudautorizacion) {
        return (solicitudautorizacion.id) ? SolicitudAutorizacion.update(solicitudautorizacion).$promise : SolicitudAutorizacion.save(solicitudautorizacion).$promise;
      },

      remove: function (solicitudautorizacion) {
        return solicitudautorizacion.$remove();
      },

      procesar: function(id) {
        return SolicitudAutorizacion.procesar({ id: id }).$promise;
      }
    };
  }]);

'use strict';

angular.module('qualita.venta')
  .controller('ArchivosBalanzasIndexCtrl',
    ["$scope", "$window", "VentasLangFactory", "$filter", "notify", "TiposTipoFactory", "ProductosFactory", "filterFactory", "ParametrosFactory", "CambioPrecioFactory", function ($scope, $window, VentasLangFactory, $filter, notify, TiposTipoFactory, ProductosFactory, filterFactory, ParametrosFactory, CambioPrecioFactory) {

      var vm = this;

      VentasLangFactory.getTranslations().then(function (translations) {
        $scope.translations = translations;

        //traer entidades del tipo tipo
        $scope.tiposBalanzas = TiposTipoFactory.get("tipo_balanzas");
        $scope.tiposBalanzas.$promise.then(function (response) {
          if ($scope.tiposBalanzas.length == 1) {
            console.log($scope.tiposBalanzas);
            $scope.filtros.tipoBalanza = $scope.tiposBalanzas[0];
          }
        });


        vm.staticFilter = [];
        vm.staticFilter.push(
          {
            path: "claseProducto.tipoAdministracion.codigo", // unitario
            equals: "unitario"
          }
        );
        vm.staticFilter.push(
          {
            path: "claseProducto.tipoAdministracion.codigo", //pesable
            equals: "pesables"
          }
        )
        vm.staticFilter.push(
          {
            path: "codigoBalanza",
            sortAsc: true
          }
        )
        vm.finalFilters = {
          search: filterFactory.or(vm.staticFilter).value()
        };

        vm.productos = ProductosFactory.allForSelect(vm.finalFilters)

        $scope.limpiarFiltros = function () {
          $scope.filtros.tipoBalanza = undefined;
        }
      });

      $scope.filtros = {};

      $scope.$watch("filtros.tipoBalanza", function (newValue, oldValue) {
        if (newValue === oldValue) {
        } else {
          $scope.archivoBalanza = ParametrosFactory.getByCodigo('nombre_archivo_balanza_producto_' + $scope.filtros.tipoBalanza.codigo);
          $scope.archivoSector = ParametrosFactory.getByCodigo('nombre_archivo_balanza_sector_' + $scope.filtros.tipoBalanza.codigo);
        }
      });


      $scope.download = function (filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }

      $scope.productosLeidos = 0;
      $scope.CADTXT = "";
      $scope.SETORTXT = "";
      $scope.generarArchivos = function () {

        if ($scope.filtros.tipoBalanza != null && typeof $scope.filtros.tipoBalanza != "undefined") {

          if (vm.productos == null || typeof vm.productos == "undefined" || vm.productos.length <= 0) {
            var msg = "No existe ningún producto con Tipo de Administración PESABLE o UNITARIO";
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
          } else {
            var producto = vm.productos[$scope.productosLeidos];
            /* SE GENERA SETORTXT */
            $scope.SETORTXT += "GENERAL\t"
            if(producto.codigoBalanza == null || typeof producto.codigoBalanza == "undefined"){
              var msg = "El producto " + producto.codigo + " - " + producto.nombre + ", no cuenta con un código de balanza configurado. \n Por favor verifique los datos.";
              notify({ message: msg, classes: 'alert-danger', position: 'right' });

            }
            var codigoBalanza = $scope.rellenar(producto.codigoBalanza, 5, true, "0");
            $scope.SETORTXT += codigoBalanza;
            $scope.SETORTXT += $scope.rellenar(($scope.productosLeidos + 1), 4, true, "0");
            $scope.SETORTXT += "000\n";


            /* SE GENERA CADTXT */
            //tipo de producto "P" u "U"
            var tipoProducto = "U";
            if (producto.claseProducto.tipoAdministracion.codigo == "pesables") {
              tipoProducto = "P";
            }
            $scope.CADTXT += producto.claseProducto.segundoDigito;
            $scope.CADTXT += codigoBalanza;
            $scope.CADTXT += tipoProducto;

            $scope.CADTXT += $scope.rellenar(producto.nombre, 21, false, " ") + ".";


            CambioPrecioFactory.getUltimoPrecio(producto.id, producto.unidadMedidaBase.id).then(function (response) {
              if (response.data != null && response.data != 0.0) {
                console.log(Math.round(response.data));
                $scope.CADTXT += $scope.rellenar(Math.round(response.data), 7, true, "0") + "\n";
                $scope.productosLeidos++;
                if ($scope.productosLeidos < vm.productos.length) {
                  $scope.generarArchivos();
                } else {
                  $scope.download($scope.archivoBalanza.$$state.value.valor, $scope.CADTXT);
                  $scope.download($scope.archivoSector.$$state.value.valor, $scope.SETORTXT);
                }
              } else {
                var msg = "El producto " + producto.codigo + " - " + producto.nombre + ", no cuenta con un precio configurado";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                $scope.productosLeidos = 0;
                $scope.CADTXT = "";
                return;
              }
            });

          }
        } else {
          var msg = "Debe seleccionar un tipo de balanza para continuar";
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
        }
      };

      $scope.rellenar = function (text, max_length, direccion, caracter) {
        var largo = text.toString().length;
        if (largo < max_length) {
          var relleno = caracter.repeat(max_length - largo);
          if (direccion) {
            //izquierda
            return relleno + text;
          } else {
            //derecha
            return text + relleno;
          }
        } else {
          return String(text).substring(0, max_length);
        }
      }
    }]);

"use strict";

angular
    .module("qualita.venta")
    .controller("PlanillaCajaCtrl", ["$filter", "$window", "ReportTicketFactory", "cajaFactory", "$state", "$rootScope", "LocacionesFactory", "notify", function (
        $filter,
        $window,
        ReportTicketFactory,
        cajaFactory,
        $state,
        $rootScope,
        LocacionesFactory,
        notify
    ) {
        var vm = this;
        vm.reportePlanillaCaja = reportePlanillaCaja;
        vm.obtenerSucursales = obtenerSucursales;
        vm.changeFecha = changeFecha;
        activate();

        function activate() {
            vm.fecha = new Date();
            vm.fechaVencimiento = new Date().setDate(new Date().getDate() + 1);

            //unidad Negocio
            vm.unidadNegocio = undefined;
            var unidadesNegocio = _.map($rootScope.AuthParams.locaciones, function (locacion) {
                return locacion.unidadNegocio;
            });

            unidadesNegocio = _.unique(unidadesNegocio, function (value) {
                return value.nombre
            });

            vm.sucursal = undefined;

            vm.caja = undefined;
            cajaFactory.all().$promise.then(function (response) {
                vm.listaCajas = response;
            });
        }

        function changeFecha() {
            vm.fecha = vm.fecha;
            vm.fechaVencimiento = new Date(vm.fecha).setDate(new Date(vm.fecha).getDate() + 1);
        }

        function reportePlanillaCaja() {
            var filtersArr = [];
            var myDate = $filter('date')(vm.fecha, "MM/dd/yyyy");
            var myDateVencimiento = $filter('date')(vm.fechaVencimiento, "MM/dd/yyyy");

            if (myDateVencimiento <= myDate) {
                var msg = "La fecha de vencimiento no puede ser menor ni igual que la fecha.";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                vm.submited = false;
                return;
            }

            if (vm.unidadNegocio == undefined) {
                var msg = "La unidad de negocio no puede quedar vacio";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                vm.submited = false;
                return;
            }

            if (vm.sucursal == undefined) {
                var msg = "La sucursal no puede quedar vacio";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                vm.submited = false;
                return;
            }

            if (vm.caja == undefined) {
                var msg = "La caja no puede quedar vacio";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                vm.submited = false;
                return;
            }

            filtersArr.push("fecha=" + myDate);
            filtersArr.push("fecha_vencimiento=" + myDateVencimiento);
            filtersArr.push("id_unidadnegocio=" + vm.unidadNegocio.id);
            filtersArr.push("id_sucursal=" + vm.sucursal.id);
            filtersArr.push("id_caja=" + vm.caja.id);

            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                "planillacaja",
                "pdf",
                filtersArr
            );
            $window.open(vm.pdfDownloadURL, "_blank");
            $state.go($state.current, {}, { reload: true });
        }

        function obtenerSucursales() {
            LocacionesFactory.getByUnidadNegocio(vm.unidadNegocio.id).$promise.then(function (response) {
                vm.sucursales = _.map(response, function (locacion) {
                    return locacion.sucursal;
                });
            });
        }
    }]);


"use strict";

angular
    .module("qualita.venta")
    .controller("ComprobanteDiibitListCtrl", ComprobanteDiibitListCtrl);

ComprobanteDiibitListCtrl.$inject = [
    "AuthorizationService",
    "AuthenticationService",
    "ComprobanteVentaFactory",
    "VentaFactory",
    "$modal",
    "$scope",
    "$state",
    "$rootScope",
    "filterFactory",
    "notify",
    "VentasLangFactory",
    "WatchLocacionChangeFactory",
    "$filter",
    "cajaFactory",
    "ReportTicketFactory",
    "$window",
    "UtilFactory",
    "CsvFactory",
    "baseurl",
    "CobroFactory",
    "ProcesoCobroFactory",
    "$q",
    "PeriodoAdministrativoFactory",
    "ParametrosFactory",
    "CONFIGURATION",
    "SolicitudAutorizacionFactory",
    "TiposTipoFactory",
    "ClienteFactory",
    "$timeout"
];

function ComprobanteDiibitListCtrl(
    AuthorizationService,
    AuthenticationService,
    ComprobanteDiibitFactory,
    VentaFactory,
    $modal,
    $scope,
    $state,
    $rootScope,
    filterFactory,
    notify,
    VentasLangFactory,
    WatchLocacionChangeFactory,
    $filter,
    cajaFactory,
    ReportTicketFactory,
    $window,
    UtilFactory,
    CsvFactory,
    baseurl,
    CobroFactory,
    ProcesoCobroFactory,
    $q,
    PeriodoAdministrativoFactory,
    ParametrosFactory,
    CONFIGURATION,
    SolicitudAutorizacionFactory,
    TiposTipoFactory,
    ClienteFactory,
    $timeout
) {
    var vm = this;

    activate();

    function activate() {

        var monedaRender = function (data, type, row) {

            if (data != undefined) {
                
                data = parseFloat(data).toFixed(0);
                
                // TODO hacer esto mismo en todos los renders de moneda
                return (
                    data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            } else {
                return "";
            }
        };

        var defaultColumnOrder = [
            "id",
            "idComprobante",
            "idItems",
            "tipoComprobante",
            "numeroComprobante",
            "fechaVenta",
            "nombreCliente",
            "categoria",
            "codigoProducto",
            "codigoBarrasProducto",
            "unidadMedida",
            "descripcionProducto",
            "cantidad",
            "precioUnitario",
            "importeTotal",
            "descuento",
            "iva",
            "montoNeto",
            "formaPago",
            "procesadora",
            "descuentoProcesadora",
            "descuentoComision",
            "idMovimientoQualita",
            "numeroMovimientoQualita",
            "fechaProcesamiento",
            "errorProcesamiento",
            "sucursalDiibit"
        ];

        VentasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;

            vm.options = {
                resource: "comprobantediibit",
                title: "Comprobantes Diibit",
                factory: ComprobanteDiibitFactory,
                defaultColumnOrder: defaultColumnOrder,
                view: "ConsultaComprobanteDiibitList",
                columns: [
                    { data: "id", title: $filter("translate")("CODE"), visible: false },
                    { data: "idComprobante", title: $filter("translate")("ID_COMPROBANTE" ), visible: false  },
                    { data: "idItems", title: $filter("translate")("ID_ITEMS"), visible: false  },
                    { data: "tipoComprobante", title: $filter("translate")("TIPO_COMPROBANTE") },
                    { data: "numeroComprobante", title: $filter("translate")("NUMERO_COMPROBANTE") },
                    { data: "fechaVenta", title: $filter("translate")("FECHA"), renderWith: "dateRender", dateFormat: "DD/MM/YYYY", class: "dt-center", type: "date-range" },
                    { data: "nombreCliente", title: $filter("translate")("NOMBRE_CLIENTE") },
                    { data: "categoria", title: $filter("translate")("CATEGORIA"), visible: false },
                    { data: "codigoProducto", title: $filter("translate")("CODIGO_PRODUCTO"), visible: false },
                    { data: "codigoBarrasProducto", title: $filter("translate")("CODIGO_BARRAS") },
                    { data: "unidadMedida", title: $filter("translate")("UNIDAD_MEDIDA"), visible: false },
                    { data: "descripcionProducto", title: $filter("translate")("PRODUCTO") },
                    { data: "cantidad", title: $filter("translate")("CATIDAD"), type: "number-range", render: monedaRender, class: "dt-right" },
                    { data: "precioUnitario", title: $filter("translate")("PRECIO_UNITARIO"), type: "number-range", render: monedaRender, class: "dt-right" },
                    { data: "importeTotal", title: $filter("translate")("IMPORTE_TOTAL"), type: "number-range", render: monedaRender, class: "dt-right" },
                    { data: "descuento", title: $filter("translate")("DESCUENTO"), type: "number-range", render: monedaRender, class: "dt-right" },
                    { data: "iva", title: $filter("translate")("IVA"), type: "number-range", render: monedaRender, class: "dt-right" },
                    { data: "montoNeto", title: $filter("translate")("MONTO_NETO"), type: "number-range", render: monedaRender, class: "dt-right" },
                    { data: "formaPago", title: $filter("translate")("FORMA_PAGO"), visible: false },
                    { data: "procesadora", title: $filter("translate")("PROCESADORA"), visible: false },
                    { data: "descuentoProcesadora", title: $filter("translate")("DESCUENTO_PROCESADORA"), type: "number-range", render: monedaRender, class: "dt-right", visible: false },
                    { data: "descuentoComision", title: $filter("translate")("DESCUENTO_COMISION"), type: "number-range", render: monedaRender, class: "dt-right", visible: false },
                    { data: "idMovimientoQualita", title: $filter("translate")("ID_MOVIMIENTO_QUALITA") },
                    { data: "numeroMovimientoQualita", title: $filter("translate")("NUMERO_MOVIMIENTO_QUALITA") },
                    { data: "fechaProcesamiento", title: $filter("translate")("FECHA_PROCESAMIENTO"), renderWith: "dateRender", dateFormat: "DD/MM/YYYY", class: "dt-center", type: "date-range" },
                    { data: "errorProcesamiento", title: $filter("translate")("ERROR_PROCESAMIENTO") },
                    { data: "sucursalDiibit", title: 'Sucursal' } //se agrega sucursal proveniente del diibit
                ],
                extraMenuOptions:
                    [
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () {
                                return true;
                            },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                                CsvFactory.csv("comprobantediibit", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () {
                                return true;
                            },
                            'action': function () {
                                var filters = createFilters(vm.options.getFilters());

                                ReportTicketFactory.ticket('listadoConsultaComprobanteDiibit', filters, vm.options.tableAjaxParams,
                                    vm.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }

                    ],
                hideViewMenu: true,
                hideEditMenu: true,
                hideRemoveMenu: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc"
            };
        });

        var createFilters = function (filters, staticFilters) {
            var filtersArr = [];
            var filtersC = _.cloneDeep(filters);
            var staticFiltersC = _.cloneDeep(staticFilters);
            _.each(filters, function (search, data) {
                search = search.split("~");
                if (search.length > 1) {
                    /* El parámetro es un rago */

                    var isDate = search[0].split("/");

                    if (isDate.length > 1) {
                        /* Es un rago de fechas */

                        filtersArr.push({ path: data, equalOrAfter: search[0] })
                        filtersArr.push({ path: data, equalOrBefore: search[1] })
                    } else {
                        /* El un rago numérco */

                        filtersArr.push({ path: data, greaterOrEqual: search[0] })
                        filtersArr.push({ path: data, lessOrEqual: search[1] })
                    }
                } else {
                    /* El parametro no es un rago */
                    filtersArr.push({ path: data, like: search[0] })
                }
            });

            if (staticFiltersC) {
                // entrar al OR y por cada AND agregar todos los filtros de datatable
                var entro = false;
                var staticFiltersAnds = staticFiltersC.search.filters;
                _.each(staticFiltersAnds, function (staticFilter) {
                    if (staticFilter.filters) {
                        _.each(filtersArr, function (dtFilter) {
                            staticFilter.filters.push(dtFilter);
                        });
                    } else {
                        if (staticFilter.equals != undefined) {
                            filtersArr.push({ path: staticFilter.path, equals: staticFilter.equals });
                        } else if (staticFilter.like != undefined) {
                            filtersArr.push({ path: staticFilter.path, like: staticFilter.like });
                        } else if (staticFilter.equalOrBefore != undefined) {
                            filtersArr.push({ path: staticFilter.path, equalOrBefore: staticFilter.equalOrBefore });
                        } else if (staticFilter.equalOrAfter != undefined) {
                            filtersArr.push({ path: staticFilter.path, equalOrAfter: staticFilter.equalOrAfter });
                        } else if (staticFilter.greaterThan != undefined) {
                            filtersArr.push({ path: staticFilter.path, greaterThan: staticFilter.greaterThan });
                        } else if (staticFilter.lessThan != undefined) {
                            filtersArr.push({ path: staticFilter.path, lessThan: staticFilter.lessThan });
                        } else if (staticFilter.sortDesc != undefined) {
                            filtersArr.push({ path: staticFilter.path, sortDesc: staticFilter.sortDesc });
                        } else if (staticFilter.sortAsc != undefined) {
                            filtersArr.push({ path: staticFilter.path, sortAsc: staticFilter.sortAsc });
                        } else if (staticFilter.isNull != undefined) {
                            filtersArr.push({ path: staticFilter.path, isNull: staticFilter.isNull });
                        } else if (staticFilter.isNotNull != undefined) {
                            filtersArr.push({ path: staticFilter.path, isNotNull: staticFilter.isNotNull });
                        } else if (staticFilter.notEquals != undefined) {
                            filtersArr.push({ path: staticFilter.path, notEquals: staticFilter.notEquals });
                        } else if (staticFilter.greaterOrEqual != undefined) {
                            filtersArr.push({ path: staticFilter.path, greaterOrEqual: staticFilter.greaterOrEqual });
                        } else if (staticFilter.lessOrEqual != undefined) {
                            filtersArr.push({ path: staticFilter.path, lessOrEqual: staticFilter.lessOrEqual });
                        }
                        entro = true;
                    }
                });
                if (entro == true) {
                    filtersC = filterFactory.and(filtersArr).value();
                } else {
                    filtersC = filterFactory.or(staticFiltersAnds).value();
                }
            } else {
                filtersC = filterFactory.and(filtersArr).value();
            }
            return filtersC;
        }
    }
}
angular.module('qualita.venta')
 .factory('ConceptoMovimientoFactory', ConceptoMovimientoFactory);

 ConceptoMovimientoFactory.$inject = ['$resource', 'baseurl'];

function ConceptoMovimientoFactory($resource, baseurl) {
 var service = {
   all: all,
   get: get,
   create: create,
   save: save,
   remove: remove
 };

 var ConceptoMovimiento = $resource( baseurl.getBaseUrl() + "/conceptomovimiento/:id", {id: '@id'}, {
   update: {
     method: 'PUT'
   }
 });


 return service;

 function all(params, view) {
   if (params) {
     params.view = view!=undefined ? view : 'base';
   } else {
     params = [];
     params.view = view!=undefined ? view : 'base';
   }
   return ConceptoMovimiento.query(params);
 }

 function get(id, view) {
   return ConceptoMovimiento.get({id: id, view: view ? view : "base"});
 }

 function create(attrs) {
   return new ConceptoMovimiento(attrs);
 }

 function save(conceptomovimiento) {
   return (conceptomovimiento.id) ? conceptomovimiento.$update() : conceptomovimiento.$save();
 }

 function remove(conceptomovimiento) {
   return conceptomovimiento.$remove();
 }
}
'use strict';

angular.module('qualita.venta')
  .controller('ConceptoMovimientoFormCtrl', ConceptoMovimientoFormCtrl);

ConceptoMovimientoFormCtrl.$inject = ['$rootScope', '$scope', 'ConceptoMovimientoFactory', '$state',
  'conceptoMovimientoPrepService', 'notify', 'VentasLangFactory'];

function ConceptoMovimientoFormCtrl($rootScope, $scope, ConceptoMovimientoFactory, $state,
  conceptoMovimientoPrepService, notify, VentasLangFactory) {

  var vm = this;

  activate();

  vm.cancel = cancel;
  vm.submit = submit;

  function activate() {
    VentasLangFactory.getTranslations().then(function (translations) {

      $scope.translations = translations;
      if ($state.is("app.conceptomovimiento.new")) {
        activateNew();
      } else if ($state.is("app.conceptomovimiento.edit")) {
        activateEdit();
      } else if ($state.is("app.conceptomovimiento.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateNew() {
    vm.title = 'Nuevo Concepto de Movimiento';
    vm.conceptomovimiento = {};
    vm.conceptomovimiento.activo = true;
  }

  function activateEdit() {
    vm.title = 'Editar Concepto de Movimiento';
    vm.conceptomovimiento = conceptoMovimientoPrepService;
    vm.entidadId = vm.conceptomovimiento.id;
    vm.entidad = "ConceptoMovimiento";
  }

  function activateView() {
    vm.title = 'Ver Concepto de Movimiento';
    vm.conceptomovimiento = conceptoMovimientoPrepService;
    vm.entidadId = vm.conceptomovimiento.id;
    vm.entidad = "ConceptoMovimiento";
    vm.view = true;
  }

  function submit() {
    vm.submited = true;

    if ($scope.ConceptoMovimientoForm.$valid) {
      $rootScope.isProcessing = true;

      submitConceptoMovimiento().then(function () {
        $state.go('app.conceptomovimiento.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        if (error.data[0] && error.data[0].constraint == "codigo") {
          notify({ message: "Ya existe un concepto con el mismo código.", classes: 'alert-danger', position: 'right' });
        }else if (error.data[0] && error.data[0].constraint == "nombre") {
          notify({ message: "Ya existe un concepto con el mismo nombre.", classes: 'alert-danger', position: 'right' });
        }
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function submitConceptoMovimiento() {
    var resource = ConceptoMovimientoFactory.create(vm.conceptomovimiento);
    return ConceptoMovimientoFactory.save(resource);
  }

  function cancel() {
    $state.go('app.conceptomovimiento.list');
  }

  $scope.resource = 'conceptomovimiento';
}
'use strict';

angular.module('qualita.venta')
    .controller('ConceptoMovimientoIndexCtrl', ["$state", "$scope", "ConceptoMovimientoFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "notify", "$modal", function ($state, $scope, ConceptoMovimientoFactory, 
        filterFactory, ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl, 
        AuthorizationService, notify, $modal) {

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'codigo', 'nombre', 'descripcion', 'activo'
            ];
            $scope.options = {
                'resource': 'conceptomovimiento',
                'title': 'Definición de Conceptos de Movimientos',
                'factory': ConceptoMovimientoFactory,
                'view': 'ConceptoMovimientoList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'id', 'title': 'Código Interno', visible: false  },
                    { 'data': 'codigo', 'title': 'Código', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'nombre', 'title': 'Nombre', 'class': 'dt-left' },
                    { 'data': 'descripcion', 'title': 'Descripción', 'class': 'dt-left' },
                    { 'data': 'activo', 'title': 'Activo', 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                [
                    {
                        title: translations.NEW,
                        icon: "glyphicon glyphicon-plus",
                        showCondition: function() {
                            return true;
                        },
                        action: function() {
                            if(AuthorizationService.hasPermission("create_conceptomovimiento") == true){
                                $state.go("app.conceptomovimiento.new");
                            }else{
                                var msg = 'No posee permiso para crear Conceptos de Movimientos';
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("conceptomovimiento", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                .then(function (response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    },
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = createFilters($scope.options.getFilters());
                            ReportTicketFactory.ticket('listadoConceptoMovimiento', filters, $scope.options.tableAjaxParams,
                                $scope.options.currentColumnOrder).then(function (genTicket) {
                                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                    $window.open($scope.pdfDownloadURL, '_blank');
                                });
                        }
                    }
                ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>", 
                        customAttribute: ["id"],
                        conditionName: 'canEditar',
                        conditionDef: function(atributos) {
                            if(AuthorizationService.hasPermission("update_conceptomovimiento") === true){
                                return true;

                            }else{
                                return false;
                            }
                        },
                        functionName: "editar",
                        functionDef: function(atributos) {
                        var itemId = atributos[0];
                        if(AuthorizationService.hasPermission("update_conceptomovimiento") === true){
                            $state.go('app.conceptomovimiento.edit', {id: itemId});
                        }else{
                            notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right'});
                        }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute : ["id"],
                        conditionName:"canBorrar",
                        conditionDef: function (atributos) {
                            if(AuthorizationService.hasPermission("delete_conceptomovimiento") === true){
                                return true;
                            }else{
                                return false;
                            }
                        },
                        functionName: "borrar",
                        functionDef: function(itemId) {
                            $scope.selectedItemId = itemId;
                            if(AuthorizationService.hasPermission("delete_conceptomovimiento") === true){
                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING');
                          
                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: '<div class="modal-header">' +
                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                    '</div>' +
                                    '<div class="modal-body">{{::mensajeModal}}'+
                                    '<hr width="1%">'+
                                    '</div>'+
                                    '<div class="modal-footer">' +
                                    '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                    '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                    '</div>',
                                    scope: $scope
                                });
                            
                                $scope.cancel = function() {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                };
                            
                                $scope.ok = function(itemId) {
                                    var model = ConceptoMovimientoFactory.create({id: itemId});
                                    ConceptoMovimientoFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, {reload: true});
                                        },
                                        function (error) {
                                            console.log("error ", error);
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                            $scope.mensajeModal = error.data[0].message;
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                        }
                                    );
                                };
                            }else{
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right'});
                            }
                        }
                    },
                    {
                      templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                              $filter("translate")("VIEW") +
                              "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                      functionName: "ver",
                      functionDef: function (itemId) {
                        if(AuthorizationService.hasPermission("index_conceptomovimiento") === true){
                            $state.go("app.conceptomovimiento.view", {id: itemId});
                        }else{
                            notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right'});
                        }
                      },
                      conditionName: "canVer",
                      conditionDef: function () {
                          return true;
                      }
                    },
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if(search === 'No' && data === 'activo'){
                        search = 'false';
                    }
                    if(search === 'Sí' && data === 'activo'){
                        search = 'true';
                    }
        
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);
'use strict';


angular.module('qualita.venta')
  .controller('ReporteDetalleVentaPorVendedorIndexCtrl',
    ["$scope", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$window", "VentasLangFactory", "ClienteFactory", "$filter", "notify", "$rootScope", "VentaFactory", "baseurl", "SucursalesFactory", "UtilFactory", "ProductosFactory", "VendedorFactory", "TiposTipoFactory", function ($scope, filterFactory, AuthorizationService, ReportTicketFactory, $window, VentasLangFactory,
      ClienteFactory, $filter, notify, $rootScope, VentaFactory, baseurl, SucursalesFactory,
      UtilFactory, ProductosFactory, VendedorFactory, TiposTipoFactory) {
      VentasLangFactory.getTranslations().then(function (translations) {
        $scope.translations = translations;

        TiposTipoFactory.get("monedas").$promise.then(function (monedas) {
          $scope.monedas = monedas;
          _.forEach(monedas, function (moneda) {
            if (moneda.codigo == "guaranies") {
              $scope.moneda = moneda;
            }
          })
        })

        $scope.sucursal = undefined;
        SucursalesFactory.all().$promise.then(function (response) {
          $scope.listaSucursales = response;
          $scope.listaSucursales.push({ 'id': '', 'nombre': 'Todos' });
          $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];
        });

        vm.clientes = [{ 'id': '', 'nombre': 'Todos' }];
        $scope.filtros.cliente = vm.clientes[vm.clientes.length - 1]
        $scope.productos = [{ 'id': '', 'nombre': 'Todos' }];
        $scope.filtros.producto = $scope.productos[$scope.productos.length - 1]
        $scope.vendedores = [{ 'id': '', 'nombresyapellidos': 'Todos' }];
        $scope.vendedor = $scope.vendedores[$scope.vendedores.length - 1]

        $scope.limpiarFiltros = function () {
          $scope.filtros.fechaDesde = undefined;
          $scope.filtros.fechaHasta = undefined;
          vm.clientes = [];
          vm.clientes = [{ 'id': '', 'nombre': 'Todos' }];
          $scope.filtros.cliente = vm.clientes[vm.clientes.length - 1]
          $scope.moneda = undefined;
          $scope.filtros.tipoComprobante = undefined;
          $scope.filtros.fechaCargaDesde = undefined;
          $scope.filtros.fechaCargaHasta = undefined;
          $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1]
          $scope.productos = [];
          $scope.productos = [{ 'id': '', 'nombre': 'Todos' }];
          $scope.filtros.producto = $scope.productos[$scope.productos.length - 1];
          $scope.vendedor = $scope.vendedores[0];
          $scope.moneda = undefined;
        }
      });

      var vm = this;
      vm.delayRequestCliente = delayRequestCliente;
      vm.searchClienteFilter2 = searchClienteFilter2;
      $scope.changeVendedor = changeVendedor;

      $scope.filtros = {};

      $rootScope.$watch('AuthParams.accesoSistema', function (newValue, oldValue) {
        if (newValue != undefined) {
          var usuario = $rootScope.AuthParams.accesoSistema.usuario;
          var vendedoresActuales = AuthorizationService.hasPermission("listar_vendedores_actuales");

          var filtroVendedor = {};
          if (vendedoresActuales) {
            $scope.habilitar = false;
            filtroVendedor.search = filterFactory.and([
              {
                path: 'activo',
                like: 'true'
              },
              {
                path: 'vendedor',
                like: 'true'
              }

            ]).value();
            filtroVendedor.view = "VendedorList";
            $scope.vendedores = [];
            VendedorFactory.all(filtroVendedor).$promise.then(function (response) {
              _.forEach(response, function (detalle) {
                for (var i = 0; i < detalle.locaciones.length; i++) {
                  if (detalle.locaciones[i].locacion.id == $rootScope.AuthParams.locacionSeleccionada.id) {
                    $scope.vendedores.push(detalle);
                    break;
                  }
                }
              })
            });
          } else {
            $scope.habilitar = true;

            filtroVendedor.search = filterFactory.and([
              {
                path: 'activo',
                like: 'true'
              },
              {
                path: 'vendedor',
                like: 'true'
              },
              {
                path: 'usuarioAsignado.id',
                equals: usuario.id
              }

            ]).value();
            filtroVendedor.view = "VendedorList";
            $scope.vendedores = [];
            VendedorFactory.all(filtroVendedor).$promise.then(function (response) {
              _.forEach(response, function (detalle) {
                for (var i = 0; i < detalle.locaciones.length; i++) {
                  if (detalle.locaciones[i].locacion.id == $rootScope.AuthParams.locacionSeleccionada.id) {
                    $scope.vendedores.push(detalle);
                    break;
                  }
                }
                $scope.vendedor = detalle;
                changeVendedor($scope.vendedor);
              })
            });
          }
        }
      });

      $scope.delayRequestProducto = function (searchValue) {
        var userInputFilter = {};
        var staticFilter = [];
        if (searchValue.length >= 3) {
          var filterNombre = staticFilter.slice()
          var filterCodigoBarras = staticFilter.slice()
          var filterCodigoInterno = staticFilter.slice()

          filterNombre.push({
            path: "nombre",
            like: searchValue
          })

          filterCodigoBarras.push({
            path: "codigoBarrasPrincipal.codigoBarras",
            like: searchValue
          })

          filterCodigoInterno.push({
            path: "codigo",
            like: searchValue
          })

          userInputFilter = {
            search: filterFactory.or([
              filterFactory.and(filterNombre).value(),
              filterFactory.and(filterCodigoBarras).value(),
              filterFactory.and(filterCodigoInterno).value()
            ]).value()
          }

          $scope.recuperarProducto(userInputFilter)
        } else if (searchValue.length > 0 && searchValue.length < 3) {

          var filterCodigoInterno = staticFilter.slice()
          filterCodigoInterno.push({
            path: "codigo",
            like: searchValue
          })

          userInputFilter = {
            search: filterFactory.or([
              filterFactory.and(filterCodigoInterno).value()
            ]).value()
          }
          $scope.recuperarProducto(userInputFilter)
        } else {
          $scope.productos = undefined
        }
      }

      $scope.recuperarProducto = function (userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
          $scope.finalFilters, userInputFilter)

        $scope.productos = ProductosFactory.allForSelect(userInputFilter)
      };

      $scope.searchProductoFilter = function (criteria) {
        return function (item) {
          if (!criteria) {
            return true;
          }
          return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
        };
      }

      $scope.generarReporte = function () {
        if ($scope.VentaVendedorForm.$valid) {
          var filtersArr = [];

          if ($scope.filtros.fechaDesde > $scope.filtros.fechaHasta) {
            notify({ message: 'La Fecha Desde no puede ser mayor a la Fecha Hasta', classes: 'alert-danger', position: 'right' });
            return;
          }

          if ($scope.filtros.fechaDesde) {
            var myDate = $filter('date')($scope.filtros.fechaDesde, "MM/dd/yyyy");
            filtersArr.push("fechaDesde=" + myDate);
          }
          if ($scope.filtros.fechaHasta) {
            var myDate = $filter('date')($scope.filtros.fechaHasta, "MM/dd/yyyy");
            filtersArr.push("fechaHasta=" + myDate);
          }

          if ($scope.vendedor) {
            if($scope.vendedor.id != ''){
              filtersArr.push("vendedor=" + $scope.vendedor.id);
            }
          }

          if ($scope.filtros.cliente) {
            if($scope.filtros.cliente.id != ''){
              filtersArr.push("cliente=" + $scope.filtros.cliente.id);
            }
          }

          if ($scope.sucursal) {
            if($scope.sucursal.id != ''){
              filtersArr.push("sucursal=" + $scope.sucursal.id);
            }
          }

          if ($scope.filtros.producto) {
            if($scope.filtros.producto.id != ''){
              filtersArr.push("producto=" + $scope.filtros.producto.id);
            }
          }

          if ($scope.moneda) {
            filtersArr.push("moneda=" + $scope.moneda.id);
          }

          var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("ventasvendedor", 'pdf', filtersArr);
          $window.open(pdfDownloadURL, '_blank');
        } else {
          var msg = "El campo Fecha Desde, Fecha Hasta y Moneda son obligatorias";
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
        }

      };

      $scope.generarCSV = function () {

        if ($scope.VentaVendedorForm.$valid) {
          var filtersObj = {};
          if ($scope.filtros.fechaDesde) {
            var myDate = $filter('date')($scope.filtros.fechaDesde, "MM/dd/yyyy");
            filtersObj.fechaDesde = myDate;
          }
          if ($scope.filtros.fechaHasta) {
            var myDate = $filter('date')($scope.filtros.fechaHasta, "MM/dd/yyyy");
            filtersObj.fechaHasta = myDate;
          }
          if ($scope.vendedor && $scope.vendedor.id != '') {
            filtersObj.vendedor = $scope.vendedor.id;
          }
          if ($scope.filtros.cliente && $scope.filtros.cliente.id != '') {
            filtersObj.cliente = $scope.filtros.cliente.id;
          }

          if ($scope.sucursal && $scope.sucursal.id != '') {
            filtersObj.sucursal = $scope.sucursal.id;
          }

          if ($scope.filtros.producto  && $scope.filtros.producto.id != '') {
            filtersObj.producto = $scope.filtros.producto.id;
          }

          if ($scope.moneda) {
            filtersObj.moneda = $scope.moneda.id;
          }


          VentaFactory.getVentaVendedoresCSV(filtersObj).then(function (response) {
            var fileName = response.data.fileName;
            $window.open(baseurl.getPublicBaseUrl() + fileName);
          });
        } else {
          var msg = $filter('translate')('ERROR_FILTERING_LIBRO_VENTA');
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
        }
      };

      function changeVendedor(vendedor) {
        if (vendedor) {
          $scope.vendedor = vendedor;
        }
        //recuperar solo los clientes del vendedor
        var filtroVendedor = {};
        filtroVendedor.search = filterFactory.and([{
          path: 'cobrador.id',
          equals: $scope.vendedor.id
        },
        {
          path: 'activo',
          like: 'true'
        }]).value();

        vm.clientes = ClienteFactory.all(filtroVendedor, "BaseList");
      }

      function delayRequestCliente(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
          var filterNombre = [];
          var filterCodigo = [];
          var filterNumero = [];
          var filterDireccion = [];
          var filterCiudad = [];

          filterNombre.push({
            path: "nombre",
            like: searchValue
          })
          filterNombre.push({
            path: "activo",
            equals: true
          })
          filterCodigo.push({
            path: "codigo",
            like: searchValue
          })
          filterCodigo.push({
            path: "activo",
            equals: true
          })
          filterNumero.push({
            path: "numero",
            like: searchValue
          })
          filterNumero.push({
            path: "activo",
            equals: true
          })
          filterDireccion.push({
            path: "direccion",
            like: searchValue
          })
          filterDireccion.push({
            path: "activo",
            equals: true
          })
          filterCiudad.push({
            path: "ciudad.descripcion",
            like: searchValue
          })
          filterCiudad.push({
            path: "activo",
            equals: true
          })

          userInputFilter = {
            search: filterFactory.or([
              filterFactory.and(filterNombre).value(),
              filterFactory.and(filterCodigo).value(),
              filterFactory.and(filterNumero).value(),
              filterFactory.and(filterDireccion).value(),
              filterFactory.and(filterCiudad).value()
            ]).value()
          }

          recuperarCliente(userInputFilter)
        } else {
          vm.clientes = undefined
        }
      }

      function recuperarCliente(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
          vm.finalFilters, userInputFilter)

        ClienteFactory.allForSelect(finalFilter).$promise.then(function (clientes) {
          var clie = [];
          for (var i = 0; i < clientes.length; i++) {
            if (clientes[i].bloquearVentas !== true) {
              clie.push(clientes[i])
            }
          }
          vm.clientes = clie;
        })
      }
      function searchClienteFilter2(criteria) {
        return function (item) {
          if (!criteria) {
            return true;
          }
          return (
            item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
            (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
            (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
          );
        }
      }

    }]);

"use strict";

angular
    .module("qualita.venta")
    .controller("ComprobanteVentaPendienteFormCtrl", ComprobanteVentaPendienteFormCtrl);

ComprobanteVentaPendienteFormCtrl.$inject = [
    "ComprobanteVentaFactory",
    "comprobantePrepService",
    "ProductosFactory",
    "TiposFactory",
    "ClienteFactory",
    "TipoComprobanteFactory",
    "GruposCompraFactory",
    "CotizacionMonedaFactory",
    "ServicioFactory",
    "filterFactory",
    "ParametrosFactory",
    "PedidoVentaFactory",
    "$scope",
    "notify",
    "$state",
    "$rootScope",
    "$timeout",
    "formFactory",
    "TipoImpuestosFactory",
    "RecepcionFactory",
    "ProveedorSucursalesFactory",
    "RubroIvaFactory",
    "ModelTrimmer",
    "AlmacenFactory",
    "TransaccionStockFactory",
    "TimbradoEmpresaPuntoEmisionVentaFactory",
    "VentasLangFactory",
    "CompraFactory",
    "WatchLocacionChangeFactory",
    "UtilFactory",
    "ComprobanteVentaDetalleFactory",
    "OrdenesCompraDetalleFactory",
    "GruposProductosFactory",
    "$q",
    "SolicitudCompraFactory",
    "ProyectoFactory",
    "VentaFactory",
    "puntoEmisionFactory",
    "$filter",
    "cajaFactory",
    "puntoVentaFactory",
    "PeriodoAdministrativoFactory",
    "CambioPrecioFactory",
    "CambioPrecioServicioFactory",
    "GrupoProductoAlmacenFactory",
    "AuthorizationService",
    "ListaPrecioVentaFactory",
    "SolicitudAutorizacionFactory",
    "RemisionFactory",
    "VendedorFactory",
    "FormaPagoFactory",
    "SucursalesFactory",
    "CamionFactory",
    "ChoferFactory",
    "CentroCostosFactory",
    "observeOnScope",
    "ListaPrecioVentaDetalleFactory",
    "ConceptoMovimientoFactory",
    "MovimientoStockFactory",
    "suscripcionRecurrenteFactory",
    "agendaSuscripcionRecurrenteFactory",
    "$modal",
    "ProcesoCobroFactory",
    "CobroDetalleFactory",
    "TiposTipoFactory",
    "CuentaContableFactory",
    "EntidadBancariaFactory",
    "ProveedoresFactory",
    "DenominacionTarjetaFactory",
    "TipoCobroFactory",
    "CobroFactory",
    "CONFIGURATION",
    "$window"
];

function ComprobanteVentaPendienteFormCtrl(
    ComprobanteVentaFactory,
    comprobantePrepService,
    ProductosFactory,
    TiposFactory,
    ClienteFactory,
    TipoComprobanteFactory,
    GruposCompraFactory,
    CotizacionMonedaFactory,
    ServicioFactory,
    filterFactory,
    ParametrosFactory,
    PedidoVentaFactory,
    $scope,
    notify,
    $state,
    $rootScope,
    $timeout,
    formFactory,
    TipoImpuestosFactory,
    RecepcionFactory,
    ProveedorSucursalesFactory,
    RubroIvaFactory,
    ModelTrimmer,
    AlmacenFactory,
    TransaccionStockFactory,
    TimbradoEmpresaPuntoEmisionVentaFactory,
    VentasLangFactory,
    CompraFactory,
    WatchLocacionChangeFactory,
    UtilFactory,
    ComprobanteVentaDetalleFactory,
    OrdenesCompraDetalleFactory,
    GruposProductosFactory,
    $q,
    SolicitudCompraFactory,
    ProyectoFactory,
    VentaFactory,
    puntoEmisionFactory,
    $filter,
    cajaFactory,
    puntoVentaFactory,
    PeriodoAdministrativoFactory,
    CambioPrecioFactory,
    CambioPrecioServicioFactory,
    GrupoProductoAlmacenFactory,
    AuthorizationService,
    ListaPrecioVentaFactory,
    SolicitudAutorizacionFactory,
    RemisionFactory,
    VendedorFactory,
    FormaPagoFactory,
    SucursalesFactory,
    CamionFactory,
    ChoferFactory,
    CentroCostosFactory,
    observeOnScope,
    ListaPrecioVentaDetalleFactory,
    ConceptoMovimientoFactory,
    MovimientoStockFactory,
    suscripcionRecurrenteFactory,
    agendaSuscripcionRecurrenteFactory,
    $modal,
    ProcesoCobroFactory,
    CobroDetalleFactory,
    TiposTipoFactory,
    CuentaContableFactory,
    EntidadBancariaFactory,
    ProveedoresFactory,
    DenominacionTarjetaFactory,
    TipoCobroFactory,
    CobroFactory,
    CONFIGURATION,
    $window
) {

    var vm = this;
    vm.formSuscripcionRecurrente = false;
    vm.errores = [];
    vm.precioMinimoMaximos = [];
    vm.agendaSuscripcionRecurrente = {};
    vm.uiBlockuiConfig = {
        'bloquear': false,
        'message': 'Cargando datos...'
    };

    $scope.diasSemana = [{
        name: "Lunes",
        value: true,
    }, {
        name: "Martes",
        value: true,
    }, {
        name: "Miercoles",
        value: true,
    }, {
        name: "Jueves",
        value: true,
    }, {
        name: "Viernes",
        value: true,
    }, {
        name: "Sabado",
        value: true,
    }, {
        name: "Domingo",
        value: true,
    },];

    $scope.dias = {
        diasSemana: []
    };



    activate();

    vm.agregarDetalle = agregarDetalle;
    vm.agregarDetalleServicio = agregarDetalleServicio;
    vm.agregarDetalleProducto = agregarDetalleProducto;
    vm.agregarMontoImponible = agregarMontoImponible;
    vm.calcularTotalDetalle = calcularTotalDetalle;
    vm.removeItemFromArray = removeItemFromArray;
    vm.searchProductoFilter = searchProductoFilter;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.changePV = changePV;
    vm.changeTipo = changeTipo;
    vm.changeProducto = changeProducto;
    vm.changeServicio = changeServicio;
    vm.relacionarFactura = relacionarFactura;
    vm.obtenerDetallesComprobante = obtenerDetallesComprobante;
    vm.changeCotizacion = changeCotizacion;
    vm.changeFecha = changeFecha;
    vm.changeFechaSuscripcion = changeFechaSuscripcion;
    vm.changeGrupoCompra = changeGrupoCompra;
    vm.changeRecepciones = changeRecepciones;
    vm.mostrarTodosProductos = mostrarTodosProductos;
    vm.totalImpuestoChanged = totalImpuestoChanged;
    vm.tipoImpuestoChanged = tipoImpuestoChanged;
    vm.changeCliente = changeCliente;
    vm.requireDeposito = requireDeposito;
    vm.totalItemChanged = totalItemChanged;
    // vm.completarNroFactura = completarNroFactura;
    vm.validarNroFactura = validarNroFactura;
    vm.searchSolicitudCompraFilter = searchSolicitudCompraFilter;
    vm.searchTimbradoPuntoEmisionFilter = searchTimbradoPuntoEmisionFilter;
    vm.addProductoGrupo = addProductoGrupo;
    vm.comprobanteGeneraMovimiento = false;
    vm.timbradoSelect = timbradoSelect;
    vm.puntoEmisionSelected = puntoEmisionSelected;
    vm.filterTimbrado = filterTimbrado;
    vm.changeUnidad = changeUnidad;
    vm.getProductoStockAlmacen = getProductoStockAlmacen;
    vm.almacenesConStock = null;
    vm.changeAlmacen = changeAlmacen;
    vm.changeTipoComprobante = changeTipoComprobante;
    vm.changeTipoAplicacion = changeTipoAplicacion;
    vm.advertenciaTipoTransaccionMostrada = false;
    var sumaImponible;
    $rootScope.isProcessing = false;
    vm.currencyDisabled = false;
    vm.lockPedidoVenta = false;
    vm.delayRequestProducto = delayRequestProducto;
    vm.plusClicked = plusClicked;
    vm.getListasPreciosVentasByMoneda = getListasPreciosVentasByMoneda;
    vm.changeListaPrecioVenta = changeListaPrecioVenta;
    vm.actualizarPreciosDeVentas = actualizarPreciosDeVentas;
    vm.viewTipoApli = true;
    vm.compararDescendente = compararDescendente;
    vm.changeTipoCambio = changeTipoCambio;
    vm.changePrecioCambioPactado = changePrecioCambioPactado;
    vm.volverSolicitud = volverSolicitud;
    vm.changeCienteSucursal = changeCienteSucursal;
    vm.changeConductor = changeConductor;
    vm.changeCamion = changeCamion;
    vm.delayRequestCliente = delayRequestCliente;
    vm.searchClienteFilter = searchClienteFilter;
    vm.buscarComprobantes = buscarComprobantes;
    vm.searchSucursalClienteFilter = searchSucursalClienteFilter;
    vm.changeUnidadAux = changeUnidadAux;
    vm.recuperarItemsComprobanteParaNc = recuperarItemsComprobanteParaNc;
    vm.buscarMovimientosStock = buscarMovimientosStock;
    vm.validarFechasSuscripcion = validarFechasSuscripcion;
    vm.validarFechaPrimeraFacturacion = validarFechaPrimeraFacturacion;
    vm.changeCondicionVenta = changeCondicionVenta;
    vm.fechaFromSuscripcion = false;
    vm.refreshReferencias = refreshReferencias;
    vm.changeComprobanteAnticipo = changeComprobanteAnticipo;
    vm.changeTipoTransaccion = changeTipoTransaccion;
    vm.codigoBarrasAdded = codigoBarrasAdded;
    vm.codigoBarrasRemoved = codigoBarrasRemoved;
    vm.removeItemFromArrayCobro = removeItemFromArrayCobro;
    vm.limpiarCampos = limpiarCampos;
    vm.updateTotalDetalles = updateTotalDetalles;
    vm.changeTipoComprobanteAgil = changeTipoComprobanteAgil;


    function changeTipoTransaccion() {
        if (vm.comprobante.tipoTransaccion.codigo === 'transaccion_anticipo') {
            vm.existeAnticipo = false;
            vm.comprobante.tipoAnticipo = null;
            vm.comprobante.porcentajeAnticipo = 0;
            vm.comprobante.comprobanteAnticipo = null;
            vm.comprobante.importeAnticipo = 0;
            vm.comprobantesAnticipo = [];
            if (vm.comprobantesProductos && vm.comprobantesProductos.length > 0) {
                _.forEach(vm.comprobantesProductos, function (elem) {
                    calcularTotalDetalle(elem);
                })
            }
            if (vm.comprobantesServicios && comprobantesServicios.length > 0) {
                _.forEach(vm.comprobantesServicios, function (elem) {
                    calcularTotalDetalle(elem);
                })
            }
        } else {
            //buscar si existen comprobantes de tipo anticipo para el cliente seleccionado
            var staticFilterComprobanteAnticipo = {};
            staticFilterComprobanteAnticipo.search = filterFactory
                .or([filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_emitido"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_aprobado_sifen"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                ])
                .value();

            ComprobanteVentaFactory.all(staticFilterComprobanteAnticipo, "ComprobanteVentaList").$promise.then(function (anticipos) {
                vm.existeAnticipo = false;
                if (anticipos.length > 0) {
                    vm.existeAnticipo = true;
                    vm.comprobante.tipoAnticipo = vm.tipoAnticipos[0];
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobantesAnticipo = [];
                    var staticFilterComprobante = {
                        search: filterFactory
                            .and([{
                                path: "cliente.id",
                                equals: vm.comprobante.cliente.id
                            }, {
                                path: "estado.codigo",
                                notEquals: "comp_venta_anulado"
                            }])
                            .value()
                    };
                    ComprobanteVentaFactory.all(staticFilterComprobante, "ComprobanteVentaList").$promise.then(function (comprobantes) {
                        _.forEach(anticipos, function (anticipo) {
                            var comprobanteExiste = comprobantes.find(function (compro) {
                                if (compro.comprobanteAnticipo != null) {
                                    if (compro.comprobanteAnticipo.id === anticipo.id) {
                                        return true;
                                    }
                                }
                            });
                            if (!comprobanteExiste) {
                                vm.comprobantesAnticipo.push(anticipo);
                            }
                        })

                    });
                } else {
                    vm.existeAnticipo = false;
                    vm.comprobante.tipoAnticipo = null;
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobantesAnticipo = [];
                }
            });
        }
    }

    function changeComprobanteAnticipo() {
        vm.comprobante.importeAnticipo = vm.comprobante.comprobanteAnticipo.montoTotal;
        if (vm.existeAnticipo && vm.comprobante.comprobanteAnticipo) {
            //sumar cantidad*precio tanto de productos como se servicios si existe
            var totalBruto = 0;
            var totalBrutoProducto = 0;
            var totalBrutoServicio = 0;
            if (vm.comprobantesProductos.length > 0) {
                _.forEach(vm.comprobantesProductos, function (det) {
                    totalBrutoProducto += det.cantidad * det.precioVenta;
                });
            }
            if (vm.comprobantesServicios.length > 0) {
                _.forEach(vm.comprobantesServicios, function (det) {
                    totalBrutoServicio += det.cantidad * det.precioVenta;
                });
            }
            totalBruto = totalBrutoProducto + totalBrutoServicio;
            vm.comprobante.porcentajeAnticipo = (vm.comprobante.importeAnticipo / totalBruto) * 100;
            vm.comprobante.porcentajeAnticipo = parseFloat(vm.comprobante.porcentajeAnticipo.toFixed(8));
            if (vm.comprobantesProductos.length > 0) {
                var sumCompProducto = 0;
                _.forEach(vm.comprobantesProductos, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta) / 100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo * elem.cantidad;
                    elem.totalItem = (elem.precioVenta - elem.importeAnticipo) * elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompProducto += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);

                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }

                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralProductos = UtilFactory.roundForMoneda(sumCompProducto, vm.comprobante.moneda);
            }
            if (vm.comprobantesServicios.length > 0) {
                var sumCompServicio = 0;

                _.forEach(vm.comprobantesServicios, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta) / 100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo * elem.cantidad;
                    elem.totalItem = (elem.precioVenta - elem.importeAnticipo) * elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompServicio += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);

                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }

                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralServicios = UtilFactory.roundForMoneda(sumCompServicio, vm.comprobante.moneda);
            }
        }
    }

    function validarFechasSuscripcion() {
        vm.fechaFromSuscripcion = true;
        if (vm.comprobante && vm.comprobante.fechaInicial && vm.comprobante.fechaFinal) {

            if (vm.comprobante.fechaFinal < vm.comprobante.fechaInicial) {

                var msg = '';
                msg = $filter('translate')('SUSCRIPCION_FECHAS_INCORRECTAS') + "\n";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });

            } else {
                vm.comprobante.primeraFacturacion = vm.comprobante.fechaInicial;
            }

        }
    }

    function validarFechaPrimeraFacturacion() {
        if (vm.comprobante && vm.comprobante.fechaInicial && vm.comprobante.primeraFacturacion) {

            if (vm.comprobante.primeraFacturacion > vm.comprobante.fechaFinal || vm.comprobante.primeraFacturacion < vm.comprobante.fechaInicial) {
                var msg = '';
                msg = $filter('translate')('SUSCRIPCION_FECHAS_INCORRECTAS') + "\n";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }
        }
    }






    function buscarMovimientosStock() {
        //controlar que se selecciono cliente
        if (vm.comprobante.cliente == undefined) {
            var msg = "Primero debe seleccionar un Cliente.";
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            return;
        }
        //var staticFilter = {};
        vm.optionsMovimientoStock.staticFilter = {
            search: filterFactory
                .and([{
                    path: "entidadPrincipal.id",
                    equals: vm.comprobante.cliente.id
                },
                {
                    path: "estado.codigo",
                    notEquals: "ms_reversado"
                },
                {
                    path: "notaCredito.id",
                    isNull: 'true'
                }])
                .value()
        };

        vm.showMovimientoStock = true;

    }

    var getIDs = function () {
        var ids = [];
        _.forEach(vm.optionsMovimientoStock.selection, function (n, key) {
            if (n)
                ids.push(key);
        });
        return ids;
    };

    function recuperarItemsMovimientoStockParaNc() {
        vm.comprobantesProductos = [];
        vm.comprobantesServicios = [];

        //Recuperar los movimientos de stock seleccionados
        _.forEach(getIDs(), function (det) {
            MovimientoStockFactory.get(det, "OperacionManualForm").$promise.then(function (ms) {
                _.forEach(ms.movimientoDetalle, function (detalle) {
                    if ((detalle.producto && detalle.grupo != null && detalle.generado == null) || (detalle.producto && detalle.grupo == null && detalle.generado == null)) {
                        var newProducto = {
                            montosImponibles: [{
                                tipoImpuesto: detalle.producto.tiposImpuesto[0],
                                porcentaje: detalle.producto.tiposImpuesto[0].porcentaje,
                                valor: 0
                            }],
                            producto: detalle.producto,
                            unidadMedida: detalle.unidadMedidaBase,
                            cantidad: detalle.cantidadReferencial,
                            grupoProducto: vm.comprobanteGeneraMovimiento && (detalle.producto.criterioControl.codigo ==
                                "series" || detalle.producto.criterioControl.codigo == "lotes") ? detalle.grupo : undefined,
                            tipo: getTipoProducto(vm.tiposDetalle),
                            conceptoMovimiento: detalle.conceptoMovimiento
                        };
                        newProducto.cantMaxima = null;
                        newProducto.numeroOrdenProduccion = detalle.nroOrdenProduccion;

                        if (vm.comprobante.listaPrecioVenta) {
                            var staticFilter = {};
                            staticFilter.search = filterFactory.and([{
                                path: 'listaPrecioVenta.id',
                                equals: vm.comprobante.listaPrecioVenta.id,
                            },
                            {
                                path: 'producto.id',
                                equals: detalle.producto.id,
                            }]).value();
                            staticFilter.view = "ComprobanteVentaForm";
                            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                                if ((response == undefined || response.length == 0) && vm.permitirProductosNotListaPrecio == 'No') {
                                    var msg = "Existen productos que no se encuentran en la lista de precio";
                                    notify({ message: msg, classes: "alert-warning", position: "right" })
                                    return;
                                } else {
                                    if (response) {
                                        var productoLista = [];
                                        _.forEach(response, function (elem) {
                                            if (detalle.unidadMedidaBase.id == elem.unidadMedida.id) {
                                                productoLista = elem;
                                            }
                                        });

                                        if (productoLista) {
                                            if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                                                //si el comprobante es en gs y la lista en moneda extranjera
                                                newProducto.precioVenta = productoLista.precioVenta * vm.comprobante.cambioPactado;
                                                newProducto.precioMinimo = productoLista.precioMinimo * vm.comprobante.cambioPactado;
                                                newProducto.precioMaximo = productoLista.precioMaximo * vm.comprobante.cambioPactado;
                                                newProducto.precioVenta = UtilFactory.roundForMoneda(newProducto.precioVenta, vm.comprobante.moneda)
                                            } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                                                //si el comprobante es en moneda extranjera y la lista en gs
                                                var precioFinal = productoLista.precioVenta / vm.comprobante.cambio;
                                                var precioMin = productoLista.precioMinimo / vm.comprobante.cambio;
                                                var precioMax = productoLista.precioMaximo / vm.comprobante.cambio;
                                                newProducto.precioVenta = Math.round(precioFinal * 100) / 100;
                                                newProducto.precioMinimo = Math.round(precioMin * 100) / 100;
                                                newProducto.precioMaximo = Math.round(precioMax * 100) / 100;
                                            } else {
                                                newProducto.precioVenta = productoLista.precioVenta;
                                                newProducto.precioMinimo = productoLista.precioMinimo;
                                                newProducto.precioMaximo = productoLista.precioMaximo;
                                            }
                                            vm.calcularTotalDetalle(newProducto);
                                            vm.productoPerteneceLista = true;
                                        } else {
                                            vm.productoPerteneceLista = false;
                                            newProducto.precioVenta = 0;
                                            CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                                                if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambioPactado) {
                                                    //si el comprobante es en gs y la lista en moneda extranjera
                                                    newProducto.precioVenta = precio.data * vm.comprobante.cambioPactado;
                                                    newProducto.precioVenta = UtilFactory.roundForMoneda(newProducto.precioVenta, vm.comprobante.moneda)
                                                } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                                                    //si el comprobante es en moneda extranjera y la lista en gs
                                                    var precioFinal = precio.data / vm.comprobante.cambio;
                                                    newProducto.precioVenta = Math.round(precioFinal * 100) / 100;
                                                } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambio) {
                                                    //si el comprobante es en moneda extranjera y la lista en moneda extranjera
                                                    var precioFinal = precio.data / vm.comprobante.cambio;
                                                    newProducto.precioVenta = Math.round(precioFinal * 100) / 100;
                                                } else {
                                                    newProducto.precioView = precio.data.toString().replace(/\./, ",");
                                                    newProducto.precioVenta = parseFloat(newProducto.precioView.replace(/,/, "."));
                                                }
                                                vm.calcularTotalDetalle(newProducto);
                                            });
                                        }
                                    } else {
                                        //El cliente está asociado a una lista de precios de ventas sin detalles
                                    }
                                }
                            });
                        } else {
                            CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedidaBase.id).then(function (precioRecuperado) {
                                if (precioRecuperado.data != null) {
                                    newProducto.precioVentaView = precioRecuperado.data.toString().replace(/\./, ",");
                                    newProducto.precioVenta = parseFloat(newProducto.precioVentaView.replace(/,/, "."));
                                }
                            });
                        }

                        if (vm.verPrecio == 'No') {
                            CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedidaBase.id).then(function (precioRecuperado) {
                                if (precioRecuperado.data != null) {
                                    newProducto.precioVentaView = precioRecuperado.data.toString().replace(/\./, ",");
                                    newProducto.precioVenta = parseFloat(newProducto.precioVentaView.replace(/,/, "."));
                                }
                            });
                        }
                        vm.comprobantesProductos.push(newProducto);
                        vm.calcularTotalDetalle(newProducto);
                    } else if (detalle.servicio) {
                        ServicioFactory.get(
                            detalle.servicio.id,
                            "BaseList"
                        ).$promise.then(function (response) {
                            var newServicio = {
                                montosImponibles: [{
                                    tipoImpuesto: response.tipoImpuesto,
                                    porcentaje: response.tipoImpuesto.porcentaje,
                                    valor: 0
                                }],
                                servicio: detalle.servicio,
                                cantidad: detalle.cantidad,
                                tipo: vm.tiposDetalle[1],
                                precioVenta: detalle.precioVenta
                            };

                            vm.comprobantesServicios.push(newServicio);
                            vm.calcularTotalDetalle(newServicio);
                        });
                    }
                });

            });


        });
    }

    function recuperarItemsComprobanteParaNc() {
        vm.comprobantesProductos = [];
        vm.comprobantesServicios = [];

        _.forEach(vm.comprobante.relacionarFacturas, function (det) {
            _.forEach(det.comprobanteDetalle, function (detalle) {
                if (detalle.producto) {
                    var newProducto = {
                        montosImponibles: [{
                            tipoImpuesto: detalle.producto.tiposImpuesto[0],
                            porcentaje: detalle.producto.tiposImpuesto[0].porcentaje,
                            valor: 0
                        }],
                        producto: detalle.producto,
                        unidadMedida: detalle.unidadMedida,
                        cantidad: (detalle.producto.criterioControl.codigo == "series" && vm.comprobanteGeneraMovimiento) ?
                            1 : detalle.cantidad,
                        grupoProducto: vm.comprobanteGeneraMovimiento && (detalle.producto.criterioControl.codigo ==
                            "series" || detalle.producto.criterioControl.codigo == "lotes") ? detalle.grupoProducto : undefined,
                        precioVenta: detalle.precioVenta,
                        tipo: getTipoProducto(vm.tiposDetalle),
                        descuento: detalle.descuento
                    };
                    newProducto.cantMaxima = null;
                    newProducto.numeroOrdenCompraCliente = detalle.numeroOrdenCompraCliente;
                    newProducto.numeroOrdenProduccion = detalle.numeroOrdenProduccion;
                    newProducto.fechaValidez = detalle.fechaValidez;

                    if (vm.verPrecio == 'No') {
                        CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precioRecuperado) {
                            if (precioRecuperado.data != null) {
                                newProducto.precioVentaView = precioRecuperado.data.toString().replace(/\./, ",");
                                newProducto.precioVenta = parseFloat(newProducto.precioVentaView.replace(/,/, "."));
                            }
                        });
                    }
                    vm.comprobantesProductos.push(newProducto);
                    vm.calcularTotalDetalle(newProducto);
                    if ((detalle.producto.criterioControl.codigo == 'series' || detalle.producto.criterioControl.codigo == 'lotes') && !estaEnPV(detalle)) {
                        getStockPorTipoComprobante(detalle);
                    }
                } else if (detalle.servicio) {
                    ServicioFactory.get(
                        detalle.servicio.id,
                        "BaseList"
                    ).$promise.then(function (response) {
                        var newServicio = {
                            montosImponibles: [{
                                tipoImpuesto: response.tipoImpuesto,
                                porcentaje: response.tipoImpuesto.porcentaje,
                                valor: 0
                            }],
                            servicio: detalle.servicio,
                            cantidad: detalle.cantidad,
                            tipo: vm.tiposDetalle[1],
                            precioVenta: detalle.precioVenta
                        };

                        vm.comprobantesServicios.push(newServicio);
                        vm.calcularTotalDetalle(newServicio);
                    });
                }

            });
        });
        copiarComprobantes();
        recuperarPreciosMinimosMaximos();
    }

    var monedaRenderDosDecimales = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.moneda.codigo === "dolares") {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "pesos") {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "real") {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === "euro") {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }
            return (
                moneda +
                data.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
        } else return "";
    };
    function activate() {
        vm.isFacturacionAgil = false;
        vm.factoryComprobantes = ComprobanteVentaFactory;
        vm.factoryMovimientoStock = MovimientoStockFactory;
        vm.staticFilterComprobantes = [];
        vm.staticFilterComprobantes.push({
            path: 'tipo.clase.codigo',
            equals: 'factura'
        });
        vm.staticFilterComprobantes.push({
            path: "estado.codigo",
            equals: "comp_venta_emitido"
        });
        var staticFilterFinal = {
            search: filterFactory.and(vm.staticFilterComprobantes).value()
        };
        var defaultColumnOrder = [
            "numero",
            "fecha",
            "cliente.nombre",
            "montoTotal",
            "saldo"
        ];
        vm.opcionesRelacionarFacturas = {
            staticFilter: staticFilterFinal,
            resource: "comprobanteventa",
            title: "Comprobante Venta",
            view: "ComprobanteVentaList",
            factory: ComprobanteVentaFactory,
            defaultColumnOrder: defaultColumnOrder,
            columns: [{
                data: "id",
                title: $filter("translate")("CODE"),
                class: "dt-center",
                type: "number-range"
            },
            {
                data: "numero",
                title: $filter("translate")("NUMBER"),
                class: "dt-center"
            },
            {
                data: "fecha",
                title: $filter("translate")("DATE"),
                renderWith: 'dateRender',
                dateFormat: 'DD/MM/YYYY',
                class: "dt-center",
                type: "date-range"
            },
            {
                data: "cliente.nombre",
                title: "Cliente",
                class: "dt-center"
            },
            {
                data: "montoTotal",
                title: $filter("translate")("TOTAL_AMOUNT"),
                class: "dt-right",
                render: monedaRenderDosDecimales,
                type: "number-range"
            },
            {
                data: "saldo",
                title: $filter("translate")("SALDO"),
                class: "dt-right",
                render: monedaRenderDosDecimales,
                type: "number-range"
            }
            ],
            hasOptions: false,
            defaultOrderColumn: 2,
            defaultOrderDir: "desc"
        };

        var emptyDataRender = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        var defaultColumnOrder = ['id', 'numero', 'numeroSolicitud', 'descripcion', 'transaccion.descripcion', 'fecha', 'depositoOrigen.descripcion'];

        vm.optionsMovimientoStock = {
            'resource': 'movimientostock',
            'view': 'OperacionManualList',
            'title': 'Movimientos de Stock',
            'factory': MovimientoStockFactory,
            'defaultColumnOrder': defaultColumnOrder,
            'colReorder': true,
            'columns': [
                { 'data': 'id', 'title': 'Código', visible: true },
                { 'data': 'numero', 'title': 'Numero', renderWith: emptyDataRender },
                { 'data': 'numeroSolicitud', 'title': 'Nro. Solicitud', renderWith: emptyDataRender },
                { 'data': 'descripcion', 'title': 'Descripción', renderWith: emptyDataRender },
                { 'data': 'transaccion.descripcion', 'title': 'Transacción', renderWith: emptyDataRender },
                { 'data': 'fecha', 'title': 'Fecha', 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
                { 'data': 'depositoOrigen.descripcion', 'title': 'Almacén', renderWith: emptyDataRender }
            ],
            'isSelectable': true,
            'selection': {},
            'hasOptions': true,
            'hideEditMenu': true,
            'hideRemoveMenu': true,
            'hideViewMenu': true,
            'defaultOrderColumn': 1,
            'defaultOrderDir': "desc",
            'hideAddMenu': true,
            'filters': {},
            'extraMenuOptions':
                [
                    {
                        'data': "Recuperar Items de Movimiento de Stock",
                        'title': 'Recuperar Items de Movimiento de Stock',
                        'icon': 'glyphicon glyphicon-check',
                        'showCondition': function () {
                            if (vm.view) {
                                return false;
                            } else {
                                return true;
                            }
                        },
                        'action': function () {
                            if (getIDs().length === 0)
                                notify({ message: "Debe seleccionar al menos un detalle.", classes: 'alert-warning', position: 'right' });
                            else {
                                recuperarItemsMovimientoStockParaNc();
                            }
                        }
                    }
                ]
        };

        vm.habilitarTimbradoSelect = false;
        // Se verifica si es un flujo de ventas
        var venta = VentaFactory.getVenta();
        if (venta != undefined) {
            // Si NO tiene el permiso, mostrar mensaje
            if (!AuthorizationService.hasPermission("cant_mayor_pedido_venta") && venta.pedidoVenta) {
                var msg = $filter("translate")("CANT_MAYOR_PEDIDO_WARNING");
                notify({ message: msg, classes: "alert-warning", position: "right" });
            }
        }

        ParametrosFactory.getByCodigo('incluir_producto_not_lista_precio').then(function (parametro) {
            vm.permitirProductosNotListaPrecio = parametro.valor
        });

        // Recupera parametro para mostrar o no campo "precio"
        ParametrosFactory.getByCodigo('ver_precio_pedido').then(function (parametro) {
            vm.verPrecio = parametro.valor
        });

        //Recupera parametros para manejar los registros de ultimoCosto
        ParametrosFactory.getByCodigo('tipo_administracion_costo').then(function (response) {
            vm.parametroAdministracionCosto = response.valorTipo.codigo;
        });
        ParametrosFactory.getByCodigo('administrar_costo').then(function (response) {
            vm.parametroAdministrarCostoPor = response.valorTipo.codigo;
        });

        ParametrosFactory.filterByCodigo("formato_nro_comprobante").$promise.then(function (response) {
            vm.formatoComprobante = response[0].valor;
        });

        ParametrosFactory.getByCodigo('generacion_factura_electronica').then(function (response) {
            vm.datoVirtual = false;
            if (response.valorTipo.codigo === "si") {
                vm.datoVirtual = true;
            }
        });

        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            vm.habilitarFacturaRemision = false;
            if (response.valorTipo.codigo === "si") {
                vm.habilitarFacturaRemision = true;
            }
        });

        ParametrosFactory.getByCodigo('utilizar_centro_costo_ventas').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.utilizarCentroCostos = false;
            } else {
                vm.utilizarCentroCostos = true;
            }
        });
        ParametrosFactory.getByCodigo('centro_costo_obligatorio').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.centroCostoObligatorio = false;
            } else {
                vm.centroCostoObligatorio = true;
            }
        });

        ParametrosFactory.getByCodigo('relacionar_factura_nota_credito').then(function (response) {
            vm.habilitarRelacionarFactura = false;
            if (response.valorTipo.codigo === "si") {
                vm.habilitarRelacionarFactura = true;
            }
        });

        ParametrosFactory.getByCodigo('cantidad_factura_relacionar_nota_credito').then(function (parametro) {
            if (parametro.valor > 0 && parametro.valor < 11) {
                vm.cantidadFacturaRelacionar = parametro.valor
            } else if (parametro.valor > 10) {
                vm.cantidadFacturaRelacionar = 10
            } else if (parametro.valor < 1) {
                vm.cantidadFacturaRelacionar = 1
            }
        });

        ParametrosFactory.getByCodigo("proyecto_obligatorio").then(function (r) {
            vm.proyectoObligatorio = r.valorTipo.codigo == "si";
        });

        var filtroConcepto = {};
        filtroConcepto.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();
        filtroConcepto.view = "ComprobanteVentaForm";
        vm.conceptoMovimientos = ConceptoMovimientoFactory.all(filtroConcepto);

        var filtroVendedor = {};
        filtroVendedor.search = filterFactory.and([
            {
                path: 'activo',
                like: 'true'
            },
            {
                path: 'vendedor',
                like: 'true'
            }

        ]).value();
        filtroVendedor.view = "VendedorList";
        vm.vendedores = [];
        VendedorFactory.all(filtroVendedor).$promise.then(function (response) {
            _.forEach(response, function (detalle) {
                for (var i = 0; i < detalle.locaciones.length; i++) {
                    if ($rootScope.AuthParams.locacionSeleccionada && detalle.locaciones[i].locacion.id == $rootScope.AuthParams.locacionSeleccionada.id) {
                        vm.vendedores.push(detalle);
                        break;
                    }
                }
            })
        });

        TiposFactory.motivoTraslado().then(function (data) {
            vm.motivosTraslado = data.data;
        });

        var filtroServicios = {};
        filtroServicios.search = filterFactory.and([{
            path: 'clase.codigo',
            equals: 'forma_pago_referencia'
        }]).value();
        vm.promiseFormaPago = [];
        vm.promiseFormaPago.push(FormaPagoFactory.all(filtroServicios).$promise.then(function (data) {
            vm.formasPagos = data;
            vm.formasPagosAux = angular.copy(data);
        }));
        var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();
        var filtroChofer = {};
        filtroChofer.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }, {
            path: 'transportista.id',
            isNotNull: "true"
        }]).value();
        vm.choferes = ChoferFactory.all(filtroChofer, "PlanillaRepartoForm");
        vm.camiones = CamionFactory.all(filtroActivo, "PlanillaRepartoForm");

        TiposFactory.ventaTipoOperacion().then(function (data) {
            vm.tipoOperaciones = data.data;
        });

        TiposFactory.tipoTransaccion().then(function (data) {
            vm.tipoTransacciones = data.data;
        });

        TiposFactory.motivoEmision().then(function (data) {
            vm.motivosEmisiones = data.data;
        });

        TiposFactory.tipoAnticipo().then(function (data) {
            vm.tipoAnticipos = data.data;
        });

        TiposFactory.condicionNegociacion().then(function (data) {
            vm.condicionesNegociacion = data.data;
        });

        TiposFactory.paisDestino().then(function (data) {
            vm.paisesDestino = data.data;
        });

        TiposFactory.operacionComprobante().then(function (data) {
            vm.operacionesComprobante = data.data;
        });

        TiposFactory.administraciones('modo_exoneracion_iva').then(function (data) {
            vm.modosExoneracionIva = data.data;
        });


        ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
            if (parametro.valorTipo.codigo === 'no') {
                vm.utilizarPrecioBase = false;
            } else {
                vm.utilizarPrecioBase = true;
            }
        });

        ParametrosFactory.getByCodigo("recuperar_remision_facturada").then(function (response) {
            vm.recuperarRemisionFacturada = response.valor
        });

        //*** centro de costos */
        var paramsCentroCostos = {};
        paramsCentroCostos.search = filterFactory
            .and({
                path: "activo",
                equals: true
            }, {
                path: "descripcion",
                sortAsc: true
            }).value();

        paramsCentroCostos.view = "BaseList"

        vm.centrocostosList = CentroCostosFactory.all(paramsCentroCostos);

        vm.permisoAsignarListaPrecioVenta = AuthorizationService.hasPermission("asignar_listaprecioventa");

        vm.permisoEditarPrecioVenta = AuthorizationService.hasPermission("update_precio_venta_comp");

        vm.permisoEditarPrecioVentaNotaCredito = AuthorizationService.hasPermission("modificar_precio_nc");

        vm.permisoEditarPrecioFromLista = AuthorizationService.hasPermission("modificar_precio_min_max_lp_cmp");

        vm.permisoModificarPrecioAlmacenSelec = AuthorizationService.hasPermission("modificar_precio_almac_selec_cmp");

        vm.permisoFacturacionAgil = AuthorizationService.hasPermission("comprobanteventaagil");



        WatchLocacionChangeFactory.killAllWatchers();
        vm.ComprobanteForm = $scope.ComprobanteForm;
        VentasLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;
            vm.cotizacionRequerida = false;
            vm.valorFOBRequerido = false;
            var venta = undefined;
            var staticFilter = undefined;
            TiposFactory.tiposSuscripcionPeriodicidad().then(function (data) {
                $scope.tiposPeriodicidad = data.data;
            });

            if ($state.includes("app.ventas.proceso.comprobanteventa")) {
                vm.isProcesoVenta = true;
                vm.fromOutside = false;
                venta = VentaFactory.getVenta();
                vm.venta = venta;

                vm.remisiones = vm.venta.remisiones;

                ParametrosFactory.getByCodigo("recuperar_remision_facturada").then(function (response) {
                    vm.recuperarRemisionFacturada = response.valor
                    vm.remisiones = vm.venta.remisiones.map(function (remision) {
                        if (remision.estado.codigo != 'estados_remision_anulado' && response.valor == 'Sí') {
                            return remision;
                        } else if (remision.estado.codigo != 'estados_remision_anulado' && response.valor == 'No' && remision.facturado === false) {
                            return remision;
                        }
                    });
                });

                vm.remisiones = vm.venta.remisiones.map(function (remision) {
                    if (remision.estado.codigo != 'estados_remision_anulado') {
                        return remision;
                    }
                });


                if (!venta) {
                    $state.go("app.main");
                }
                var tipo = venta.tipo;
                var tipoVentaDetalle = _.find(tipo.tipoVentaDetalles, function (
                    detalle
                ) {
                    return detalle.componente.codigo == "comp_tipo_venta_comprobante";
                });

                if (tipoVentaDetalle.realizaEgresoStock == true) {
                    vm.comprobanteGeneraMovimiento = true;
                }
                if (!tipoVentaDetalle) {
                    $state.go("app.main");
                }
                var isStateEnabled = VentaFactory.checkPermissionForState(
                    tipoVentaDetalle.orden - 1
                );
                if (!isStateEnabled) {
                    $state.go("app.main");
                }

                staticFilter = {};
                var staticFilterPV = {};
                staticFilterPV.search = filterFactory
                    .and([{
                        path: "estado.codigo",
                        equals: "pv_aprobado"
                    },
                    {
                        path: "ventas.id",
                        equals: venta.id
                    }
                    ])
                    .value();
            } else if (
                $state.is("app.consultacomprobantesventa.view") ||
                $state.is("app.consultacomprobantesventa.new") ||
                $state.is("app.consultacomprobantesventa.edit") ||
                $state.is("app.comprobantesventapendientesnominacion.view")
            ) {
                vm.fromOutside = true;
                vm.isProcesoVenta = false;
                vm.comprobanteGeneraMovimiento = true; //Los comprobantes fuera de flujo generan movimiento de stock
            }
            else if ($state.includes("app.suscripcionrecurrente.proceso")) {

                vm.fromOutside = false;
                vm.isProcesoVenta = false;
                vm.comprobanteGeneraMovimiento = true;
                vm.suscripcionRecurrente = suscripcionRecurrenteFactory.getSuscripcion();
                agendaSuscripcionRecurrenteFactory.setIdSuscripcion(suscripcionRecurrenteFactory.getIdSuscripcion());
                agendaSuscripcionRecurrenteFactory.setSuscripcion(suscripcionRecurrenteFactory.getSuscripcion());
                vm.formSuscripcionRecurrente = true;
            } else if ($state.is("app.comprobanteventaagil.new")) {
                if (!vm.permisoFacturacionAgil) {
                    notify({ message: $filter("translate")("FORBIDDEN_ROUTE"), classes: 'alert-danger', position: 'right' });
                    $state.go("app.main");
                }
                vm.comprobanteGeneraMovimiento = true; //Los comprobantes fuera de flujo generan movimiento de stock
            }

            /* var filtroActivo = {};
            filtroActivo.search = filterFactory.and([{
                path: 'activo',
                like: 'true'
            }]).value();
            filtroActivo.view = "ConsultaComprobanteVentaForm";

            ClienteFactory.all(filtroActivo).$promise.then(function (response) {
                if (!vm.fromOutside && vm.venta.cliente != undefined && vm.venta.cliente != null) {
                    vm.venta.cliente = _.find(response, function (c) {
                        return c.id == vm.venta.cliente.id;
                    });
                    //vm.comprobante.cliente = vm.venta.cliente;
                }
                vm.clientes = response; */

            var params = {};
            params.search = filterFactory
                .single({
                    path: "codigo",
                    equals: "moneda_local"
                })
                .value();

            var params2 = {};
            params2.search = filterFactory
                .and([{
                    path: "activo",
                    equals: true
                }])
                .value();
            params.view = "Base";
            params2.view = "Base";

            vm.monedaLocal = ParametrosFactory.all(params);
            vm.transacciones = TransaccionStockFactory.all(params2);

            var staticFilterSC = {};
            staticFilterSC.search = filterFactory
                .and([{
                    path: "estado.codigo",
                    equals: "sc_aprobada"
                }])
                .value();

            if (
                $state.is("app.ventas.proceso.comprobanteventa.new") ||
                $state.is("app.consultacomprobantesventa.new") ||
                $state.is("app.suscripcionrecurrente.proceso.comprobanteventa.new")
            ) {
                activateNew();
            } else if (
                $state.is("app.ventas.proceso.comprobanteventa.edit") ||
                $state.is("app.consultacomprobantesventa.edit") ||
                $state.is("app.suscripcionrecurrente.proceso.comprobanteventa.edit")
            ) {
                activateEdit();
            } else if (
                $state.is("app.ventas.proceso.comprobanteventa.view") ||
                $state.is("app.consultacomprobantesventa.view") ||
                $state.is("app.suscripcionrecurrente.proceso.comprobanteventa.view") ||
                $state.is("app.comprobantesventapendientesnominacion.view")
            ) {
                activateView();
            } else if ($state.is("app.comprobanteventaagil.new")) {
                vm.isFacturacionAgil = true;
                activateNewAgil();
            }
            if (vm.watchPV) {
                vm.watcherPV();
            }
            /* WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcherPV = watcher;
                vm.pedidosVenta = PedidoVentaFactory.all(
                    UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        staticFilterPV
                    ),
                    "BaseList"
                );
            }, "locacion.id"); */

            vm.curDate = new Date();

            vm.factoryProductos = ProductosFactory;

            var renderEmptyData = function (data) {
                if (data == undefined)
                    return "";
                else
                    return data;
            };

            var defaultColumnOrderProducto = [
                "codigo",
                "nombre",
                "claseProducto.descripcion",
                "estado.descripcion",
                "lineaProducto.nombre",
                "marca.descripcion"
            ];

            vm.opcionesProductosDt = {
                resource: "productos",
                title: "Productos",
                factory: ProductosFactory,
                view: "ProductosDatatableList",
                defaultColumnOrder: defaultColumnOrderProducto,
                view: "ProductoList",
                columns: [
                    { data: "codigo", title: $filter("translate")("ID"), visible: false },
                    { data: "nombre", title: $filter("translate")("NOMBRE") },
                    { data: "claseProducto.descripcion", title: translations.KIND },
                    {
                        data: "estado.descripcion",
                        title: translations.STATUS,
                        type: "combo",
                        filterUrl: "tipos/tipo/producto_estados"
                    },
                    { data: "lineaProducto.nombre", title: translations.LINE, render: renderEmptyData },
                    {
                        data: "marca.descripcion",
                        title: translations.BRAND,
                        render: renderEmptyData
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc"
            };

            vm.factoryServicio = ServicioFactory;
            var defaultColumnOrderServicio = [
                "id",
                "codigo",
                "descripcion",
                "tipoImpuesto.descripcion"
            ];

            vm.opcionesServiciosDt = {
                resource: "servicios",
                title: "Servicio",
                view: "ServiciosDatatableList",
                factory: ServicioFactory,
                defaultColumnOrder: defaultColumnOrderServicio,
                columns: [
                    { data: "id", title: translations.CODE },
                    { data: "descripcion", title: translations.DESCRIPTION },
                    {
                        data: "tipoImpuesto.descripcion",
                        title: translations.TIPO_IMPUESTO
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc"
            };
            var filtroServiciosDt = {}
            filtroServiciosDt.search = filterFactory
                .and([{
                    path: 'venta',
                    like: 'true'
                }])
                .value();


            vm.opcionesServiciosDt.staticFilter = filtroServiciosDt;

            if (vm.watcher) {
                vm.watcher();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcher = watcher;

                var filterEstadoItemVenta = {
                    search: filterFactory
                        .and([
                            {
                                path: "estado.codigo",
                                equals: 'activo'
                            },
                            {
                                path: "itemVenta",
                                like: 'true'
                            }

                        ])
                        .value()
                };

                var filter = UtilFactory.createNestedFilters(
                    locacionFilter.search.filters,
                    filterEstadoItemVenta
                );

                if (
                    vm.fromOutside &&
                    vm.comprobante &&
                    vm.comprobante.tipo &&
                    vm.comprobante.tipo.clase.codigo != "nota_credito"
                ) {
                    var filterStockItemVenta = {
                        search: filterFactory
                            .and([{
                                path: "controlStock",
                                equals: false
                            }, {
                                path: "itemVenta",
                                like: 'true'
                            },
                            {
                                path: 'estado.codigo',
                                equals: 'activo'
                            }])
                            .value()
                    };
                    var filter = UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        filterStockItemVenta
                    );
                }

                if (vm.isProcesoVenta) {
                    // Recuperar parametro "Agregar productos que no se encuentran en el pedido"
                    ParametrosFactory.getByCodigo('agregar_productos_fuera_pedido').then(function (parametro) {

                        // Si el parametro tiene valor NO, debe procesarse las opciones (solo productos del pedido)
                        if (parametro.valor == "No" && vm.comprobante.pedidoVenta) {

                            // Realizar bloqueos de campos "Agregar" y "Producto"
                            vm.lockPedidoVenta = true;

                        } else {
                            // Si el parametro posee valor SI, se recuperan los productos como se hacia habitualmente
                            //vm.productos = ProductosFactory.allForSelect(filter, "ConsultaComprobanteVentaForm");
                            vm.opcionesProductosDt.staticFilter = filter;
                        }
                    });
                } else {
                    // Si no es flujo de ventas, recuperar todos
                    //vm.productos = ProductosFactory.allForSelect(filter, "ConsultaComprobanteVentaForm");
                    vm.opcionesProductosDt.staticFilter = filter;
                }

            }, "locaciones.id");

            var filtroServicios = {};
            filtroServicios.search = filterFactory.and([{
                path: 'venta',
                equals: true
            }]).value();

            vm.servicios = ServicioFactory.all(filtroServicios, "ConsultaComprobanteVentaForm");
            var staticFilterTC = {
                search: filterFactory
                    .or([
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "factura"
                            },
                            // Por el pedido de grafitec, se puede cambiar la condicion de cobro,
                            // ya no estira del pedido.
                            /*{
                                path: "condicion.descripcion",
                                equals: vm.venta.pedidoVenta.condicionCobro.descripcion
                            },*/
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "nota_credito"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "nota_credito"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "nota_debito"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "boleta_venta"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "pasaje_aereo"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "clase.codigo",
                                equals: "pasaje_aereo_electronico"
                            },
                            {
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value()
                    ])
                    .value()
            };

            vm.tipos = TipoComprobanteFactory.all(staticFilterTC, "ConsultaComprobanteVentaForm");


            var paramsGC = {};
            paramsGC.search = filterFactory
                .single({
                    path: "activo",
                    equals: true
                })
                .value();
            GruposCompraFactory.all(paramsGC).$promise.then(function (response) {
                vm.gruposCompra = _.filter(response, function (grupoCompra) {
                    return !(
                        grupoCompra.codigo == "gasto_importacion" ||
                        grupoCompra.codigo == "comprobante_importacion"
                    );
                });
            });

            var staticFilter = {};
            staticFilter.search = filterFactory
                .and([{
                    path: "activo",
                    equals: true
                }]).value();

            TipoImpuestosFactory.all(staticFilter).$promise.then(function (response) {
                vm.tiposImpuesto = response;
                vm.tipoExento = _.filter(response, function (elem) {
                    return elem.porcentaje === 0;
                });
            });
            var paramsRubroIva = {};
            paramsRubroIva.search = filterFactory
                .single({
                    path: "descripcion",
                    sortAsc: true
                })
                .value();
            vm.rubrosIva = RubroIvaFactory.all(paramsRubroIva);

            if (vm.watcherAlmacen) {
                vm.watcherAlmacen();
            } //hacemos unwatch de la expresion anterior
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcherAlmacen
            ) {
                vm.watcherAlmacen = watcherAlmacen;
                var filterSucursal = {
                    search: filterFactory
                        .and([
                            {
                                path: "sucursal.id",
                                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                            },
                            {
                                path: "habilitadoVenta",
                                equals: true
                            },
                            {
                                path: "abreviatura",
                                sortAsc: true
                            }]
                        )
                        .value()
                };
                vm.depositos = AlmacenFactory.all(filterSucursal);
                vm.depositos.$promise.then(function (response) {
                    if (response.length) {
                        if (vm.pedidoVenta && vm.pedidosVenta.length > 0) {
                            vm.pedidosVenta.$promise.then(function (response) {
                                vm.comprobante.deposito = response[0].almacen;
                                vm.comprobante.centroCostos = response[0].centroCostos;
                                vm.comprobante.comentario = response[0].comentario;
                            })
                        } else {
                            if (vm.comprobante && !vm.comprobante.deposito) {
                                vm.comprobante.deposito = response[0];
                            }
                        }
                    }
                })
            }, "locacion.id");

            vm.proyectos = ProyectoFactory.all("", "ComprobanteVentaForm");

            if (vm.isProcesoVenta) {
                if (vm.watchPV) {
                    vm.watcherPV();
                }
                WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcher
                ) {
                    vm.watcherPV = watcher;
                    vm.pedidosVenta = PedidoVentaFactory.all(
                        UtilFactory.createNestedFilters(
                            locacionFilter.search.filters,
                            staticFilterPV
                        ),
                        "BaseList"
                    );
                }, "locacion.id");
            }/*  else if(!vm.formSuscripcionRecurrente){
                var staticFilterRecepcion = {};
                staticFilterRecepcion.search = filterFactory
                    .and([{
                        path: "estadoRecepcion.codigo",
                        equals: "er_aprobado"
                    },
                    {
                        path: "comprobante.id",
                        isNull: true
                    } 
                    ])
                    .value();
                if (vm.watcherRecepcion) {
                    vm.watcherRecepcion();
                }
                WatchLocacionChangeFactory.executeWatching(function (
                    locacionFilter,
                    watcherRecepcion
                ) {
                    vm.watcherRecepcion = watcherRecepcion;
                    vm.recepciones = RecepcionFactory.all(
                        UtilFactory.createNestedFilters(
                            locacionFilter.search.filters,
                            staticFilterRecepcion
                        ),
                        "BaseList"
                    );
                }, "locacion.id");
            } */
            var staticFilter = {};
            staticFilter.search = filterFactory
                .and([{
                    path: "accion.codigo",
                    equals: "ingreso_almacen"
                }])
                .value();

            vm.monedas = {};
            TiposFactory.monedas().then(function (data) {
                vm.monedas = data.data;
            });
            getParamDiferenciaMonedaLocal();
            getParamDiferenciaMonedaExtranjera();
            /* }); */
        });
        vm.submited = false;
    }

    /* Este observer se activa cuando seleccionamos comprobanes desde el DataTableSearch */
    var obs1 = observeOnScope($scope, "vm.comprobantesFiltrados")
        .filter(function (data) {
            return data.newValue;
        })
        .map(function (data) {
            return data.newValue;
        });

    obs1.subscribe(function (change) {

        _.forEach(change, function (value) {
            value.$promise.then(function (value) {
                vm.comprobante.relacionarFacturas.push(value)
            })
        });

    });

    $scope.$watch('vm.comprobante.relacionarFacturas', function (newVal) {
        if (newVal) {
            for (var i = 0; i < newVal.length; i++) {
                if (!newVal[i].comprobanteDetalle) {
                    obtenerDetallesComprobante(newVal[i].id)
                }
            }
            if (newVal.length > vm.cantidadFacturaRelacionar) {
                newVal.splice(newVal.length - 1, 1);
                var msg = "La cantidad máxima de facturas a relacionar es " + vm.cantidadFacturaRelacionar;
                notify({ message: msg, classes: "alert-danger", position: "right" });
            }
        }
    }, true)

    function activateNewAgil() {
        vm.tiposCobros = TiposTipoFactory.get("tipo_cobro");

        var filterFP = {
            search: filterFactory
                .single({
                    path: "utilizarEnCobros",
                    like: "true"
                })
                .value()
        };
        vm.formasPago = FormaPagoFactory.all(filterFP);
        vm.cuentasContables = CuentaContableFactory.all();

        var filterEB = {
            search: filterFactory
                .single({
                    path: "activo",
                    like: "true"
                })
                .value()
        };

        var filterFP = {
            search: filterFactory
                .single({
                    path: "utilizarEnCobros",
                    like: "true"
                })
                .value()
        };
        vm.formasPagoCobro = FormaPagoFactory.all(filterFP);

        vm.denominacionesTarjetas = DenominacionTarjetaFactory.all(filterEB);

        var proveedorFilter = {
            search: filterFactory.and([{
                path: "activarProcesadora",
                equals: true
            }]).value()
        }
        vm.tiposTarjeta = TiposTipoFactory.get("tipos_tarjetas");

        vm.tiposCobroEntidad = TipoCobroFactory.all(filterEB);

        vm.proveedores = ProveedoresFactory.all(proveedorFilter);

        TiposFactory.tiposProcesamientosDetallesCobros().then(function (datos) {
            vm.tiposProcesamientos = datos.data;
            var origenManual = _.find(vm.tiposProcesamientos, function (procesamiento) {
                if (procesamiento.codigo === 'tipo_procesamiento_pos') {
                    vm.auxProcesamiento = procesamiento;

                }
            });
        });
        vm.tiposCobros = TiposTipoFactory.get("tipo_cobro");

        vm.entidadesBancarias = EntidadBancariaFactory.all(filterEB);
        vm.hasPermisoObligaciones = AuthorizationService.hasPermission("update_obligaciones_asociadas_ventas");
        vm.new = true;
        vm.title = $filter("translate")("NEW_COMPROBANTE_VENTA");
        vm.tiposDetalle = {};
        vm.totalImpuestosGeneral = 0;
        vm.comprobante = {};
        vm.comprobante.fecha = new Date();



        TiposFactory.tipoTransaccion().then(function (data) {
            vm.tipoTransacciones = data.data;
            _.forEach(vm.tipoTransacciones, function (tt) {
                if ('venta_mercaderia' === tt.codigo) {
                    vm.comprobante.tipoTransaccion = tt;
                }
            });
        });

        var staticFilterParametros = {};
        staticFilterParametros.search = filterFactory.or([{
            path: 'codigo',
            equals: 'imputa_iva'
        }, {
            path: 'codigo',
            equals: 'imputa_ire'
        }, {
            path: 'codigo',
            equals: 'imputa_irp_rsp'
        }, {
            path: 'codigo',
            equals: 'imputa_iva'
        }, {
            path: 'codigo',
            equals: 'recuperar_total_depositar_apertura'
        }]).value();;


        staticFilterParametros.view = "BaseList";

        ParametrosFactory.all(staticFilterParametros).$promise.then(function (response) {
            response.forEach(function (param) {
                switch (param.codigo) {
                    case "imputa_iva":
                        vm.comprobante.imputarIvaGeneral = (param.valor === "Sí");
                        break;
                    case "imputa_ire":
                        vm.comprobante.imputarIre = (param.valor === "Sí");
                        break;
                    case "imputa_irp_rsp":
                        vm.comprobante.imputarIrpRsp = (param.valor === "Sí");
                        break;
                    case "recuperar_total_depositar_apertura":
                        vm.recuperarTotalDepositarApertura = (param.valor === "Sí");
                        break;
                }
            });
            TiposFactory.tiposDetalleComprobante().then(function (data) {
                vm.tiposDetalle = data.data;
                vm.comprobante.comprobanteDetalle = [];
                vm.comprobante.cambio = 1;
                vm.comprobante.montosImponibles = [{}];
                vm.tipoCom = false;

                getMonedaLocal(function () {
                    var staticFilterComprobanteVentas = {
                        search: filterFactory
                            .and([{
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            }, {
                                path: "clase.codigo",
                                equals: "factura"
                            }, {
                                path: "factAgilDefault",
                                equals: true
                            },
                            {
                                path: "activo",
                                equals: true
                            }
                            ])
                            .value()
                    };
                    TipoComprobanteFactory.all(staticFilterComprobanteVentas, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                        vm.tipos = undefined;
                        if (response.length > 0) {
                            vm.tipos = response;
                            _.forEach(response, function (tc) {
                                if (tc.condicion.codigo == 'tipo_comprobante_contado') {
                                    vm.comprobante.tipo = tc;
                                }
                            });
                            if (!vm.comprobante.tipo) {
                                vm.comprobante.tipo = response[0];
                            }
                            var filtroCliente = {};
                            filtroCliente.search = filterFactory.and([{
                                path: 'activo',
                                equals: true
                            }, {
                                path: 'factAgilDefault',
                                equals: true,

                            }]).value();

                            recuperarTimbradoFacturacionAgil($scope.caja);
                            ClienteFactory.all(filtroCliente).$promise.then(function (responseCliente) {
                                if (responseCliente.length > 0) {
                                    vm.comprobante.cliente = responseCliente[0];
                                    var suc = [];
                                    for (var i = 0; i < vm.comprobante.cliente.sucursales.length; i++) {
                                        if (vm.comprobante.cliente.sucursales[i].activo === true) {
                                            suc.push(vm.comprobante.cliente.sucursales[i])
                                        }
                                    }
                                    vm.comprobante.sucursalCliente = suc[0];
                                    vm.comprobante.vendedor = vm.comprobante.sucursalCliente.vendedor;
                                    vm.comprobante.tipoOperacion = vm.comprobante.cliente.tipoOperacion;
                                    if (vm.comprobante.tipo.generarCuotas && vm.comprobante.cliente.formaPago) {
                                        vm.cantidadCuotas = vm.comprobante.cliente.formaPago.cantidadCuotas;
                                        vm.plazoCuotas = vm.comprobante.cliente.formaPago.plazoDias;
                                    }
                                    recuperarListaPrecio();
                                    vm.changeCotizacion(vm.comprobante.moneda);
                                }
                            });
                        } else {
                            var msg = "No se encontraron Tipos de Comprobantes para la Facturación Ágil";
                            notify({ message: msg, classes: "alert-danger", position: "right" });
                            $scope.ComprobanteForm["tipo"].$setValidity("error", false);
                            $scope.ComprobanteForm["tipo"].$error.pattern = true;
                            $scope.ComprobanteForm["tipo"].$touched = true;
                        }
                    });

                });
                vm.comprobantesProductos = [];
                vm.comprobantesServicios = [];
                agregarDetalleProducto();
                agregarDetalleServicio();
            });
            vm.totalGeneralProductos = 0;
            vm.totalGeneralServicios = 0;
            vm.totalGeneral = 0;
            $timeout(function () {
                $scope.$broadcast("formOpened");
            }, 20);

            vm.mostrarCambioPactado = false;
            addNewProcesoCobro();
        });
    }


    function activateNew() {
        vm.hasPermisoVendedor = AuthorizationService.hasPermission("modificar_vendedor");
        vm.hasPermisoObligaciones = AuthorizationService.hasPermission("update_obligaciones_asociadas_ventas");
        vm.new = true;
        abortRouteIfNecessary();
        if (vm.isProcesoVenta === true && !ComprobanteVentaFactory.hasPermisos(false, "new")) {
            $state.go("app.main");
        }
        vm.title = $filter("translate")("NEW_COMPROBANTE_VENTA");
        vm.tiposDetalle = {};
        vm.totalImpuestosGeneral = 0;

        vm.comprobante = {};
        vm.comprobante.fecha = new Date();
        vm.comprobante.primeraFacturacion = new Date();
        vm.comprobante.respetarDias = false;
        vm.comprobante.relacionarFacturas = [];

        ParametrosFactory.filterByCodigo("imputa_iva").$promise.then(function (response) {
            if (response[0].valor == "Sí") {
                vm.comprobante.imputarIvaGeneral = true;
            } else if (response[0].valor == "No") {
                vm.comprobante.imputarIvaGeneral = false;
            }
        })
        ParametrosFactory.filterByCodigo("imputa_ire").$promise.then(function (response) {
            if (response[0].valor == "Sí") {
                vm.comprobante.imputarIre = true;
            } else if (response[0].valor == "No") {
                vm.comprobante.imputarIre = false;
            }
        })
        ParametrosFactory.filterByCodigo("imputa_irp_rsp").$promise.then(function (response) {
            if (response[0].valor == "Sí") {
                vm.comprobante.imputarIrpRsp = true;
            } else if (response[0].valor == "No") {
                vm.comprobante.imputarIrpRsp = false;
            }
        })
        ParametrosFactory.getByCodigo("recuperar_items_comprobante_asociado").then(function (r) {
            vm.recuperarItemsComprobanteAsociado = r.valorTipo.codigo === "si";
        });

        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;

            vm.comprobante.comprobanteDetalle = [];
            vm.comprobante.cambio = 1;

            if (vm.isProcesoVenta) {
                vm.comprobante.venta = VentaFactory.getIdVenta();
            }
            vm.comprobante.montosImponibles = [{}];

            vm.tipoCom = false;

            getMonedaLocal(function () {

                if (vm.venta && vm.venta.pedidoVenta) {
                    //vm.tipoCom = true;

                    if (vm.venta.pedidoVenta.condicionCobro.codigo === "condicion_cobro_pedido_venta_credito") {
                        var staticFilterCredito = {
                            search: filterFactory
                                .or([
                                    filterFactory
                                        .and([{
                                            path: "clase.codigo",
                                            equals: "factura"
                                        },
                                        {
                                            path: "utilizarEnProcesosVenta",
                                            equals: true
                                        },
                                        {
                                            path: "condicion.codigo",
                                            equals: "tipo_comprobante_credito"
                                        },
                                        {
                                            path: "activo",
                                            like: "true"
                                        }
                                        ])
                                        .value()
                                ])
                                .value()
                        };
                        TipoComprobanteFactory.all(staticFilterCredito, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                            vm.comprobante.tipo = response[0];
                        });
                        if (vm.venta.cliente.formaPago) {
                            vm.cantidadCuotas = vm.venta.cliente.formaPago.cantidadCuotas;
                            vm.plazoCuotas = vm.venta.cliente.formaPago.plazoDias;
                        } else {
                            if (vm.venta.pedidoVenta.formaPago) {
                                vm.cantidadCuotas = vm.venta.pedidoVenta.formaPago.cantidadCuotas;
                                vm.plazoCuotas = vm.venta.pedidoVenta.formaPago.plazoDias;
                            }
                        }
                    } else if (vm.venta.pedidoVenta.condicionCobro.codigo === "condicion_cobro_pedido_venta_contado") {
                        var staticFilterContado = {
                            search: filterFactory
                                .or([
                                    filterFactory
                                        .and([{
                                            path: "clase.codigo",
                                            equals: "factura"
                                        },
                                        {
                                            path: "utilizarEnProcesosVenta",
                                            equals: true
                                        },
                                        {
                                            path: "condicion.codigo",
                                            equals: "tipo_comprobante_contado"
                                        },
                                        {
                                            path: "activo",
                                            like: "true"
                                        }
                                        ])
                                        .value()
                                ])
                                .value()
                        };
                        TipoComprobanteFactory.all(staticFilterContado, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                            vm.comprobante.tipo = response[0];
                        });
                    }
                    // Al tener pedidoVenta se bloquea el campo cliente
                    vm.comprobante.pedidoVenta = vm.venta.pedidoVenta;
                    vm.comprobante.cliente = vm.venta.cliente;
                    vm.comprobante.listaPrecioVenta = vm.venta.pedidoVenta.listaPrecioVenta;
                    vm.comprobante.vendedor = vm.venta.pedidoVenta.vendedor;
                    vm.comprobante.formaPago = vm.venta.pedidoVenta.formaPago;
                    vm.comprobante.centroCostos = vm.venta.pedidoVenta.centroCostos;
                    vm.comprobante.comentario = vm.venta.pedidoVenta.comentario;
                    vm.comprobante.proyecto = vm.venta.pedidoVenta.proyecto;


                    if (!vm.fromOutside && vm.venta.pedidoVenta.listaPrecioVenta != null) {
                        if (vm.comprobante.listaPrecioVenta) {
                            vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                            PedidoVentaFactory.get(
                                vm.venta.pedidoVenta.id,
                                "PedidoVentaForm"
                            ).$promise.then(function (response) {
                                vm.comprobante.pedidoVenta = response;
                                vm.comprobante.listaPrecioVenta = vm.comprobante.pedidoVenta.listaPrecioVenta;
                                vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                            });
                        }

                    }
                    vm.precioBase = "Precio de Venta";
                    vm.porcRecargo = 0;
                    if (vm.comprobante.listaPrecioVenta && vm.utilizarPrecioBase && vm.comprobante.listaPrecioVenta.precioBase) {
                        vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                        vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                    }

                    PedidoVentaFactory.get(
                        vm.venta.pedidoVenta.id,
                        "PedidoVentaList"
                    ).$promise.then(function (response) {
                        vm.comprobante.pedidoVenta = response;
                        vm.comprobante.moneda = response.moneda;
                        vm.comprobante.cambio = response.cambio;
                        vm.currencyDisabled = true;

                        if (vm.comprobante.listaPrecioVenta && vm.utilizarPrecioBase) {
                            vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                            vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                            vm.comprobante.moneda = vm.comprobante.listaPrecioVenta.moneda;
                            vm.bloquearLista = true;
                        }
                        vm.getListasPreciosVentasByMoneda();
                        changePV();
                    });
                } else if (vm.venta && vm.venta.cliente) {
                    vm.comprobante.cliente = vm.venta.cliente;
                    vm.comprobante.sucursalesCliente = vm.venta.cliente.sucursales;
                    vm.uiBlockuiConfig.bloquear = true;
                    changeCliente();
                } else if (!vm.formSuscripcionRecurrente) {
                    var staticFilterComprobanteVentas = {
                        search: filterFactory
                            .and([{
                                path: "utilizarEnProcesosVenta",
                                equals: true
                            }
                            ])
                            .value()
                    };
                    /*TipoComprobanteFactory.all(staticFilterComprobanteVentas, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                        vm.comprobante.tipo = response[0];
                    });*/
                } else {
                    vm.comprobante.cliente = vm.suscripcionRecurrente.cliente;
                    vm.comprobante.sucursalCliente = vm.suscripcionRecurrente.sucursalCliente;
                    vm.comprobante.vendedor = vm.comprobante.sucursalCliente.vendedor;
                    vm.comprobante.fechaInicial = moment(vm.suscripcionRecurrente.fechaInicial, "DD/MM/YYYY").toDate();
                    vm.comprobante.fechaFinal = moment(vm.suscripcionRecurrente.fechaFinal, "DD/MM/YYYY").toDate();;
                    vm.comprobante.primeraFacturacion = moment(vm.suscripcionRecurrente.fechaInicial, "DD/MM/YYYY").toDate();
                    vm.comprobante.moneda = vm.suscripcionRecurrente.moneda;
                    vm.comprobante.formaPago = vm.comprobante.cliente.formaPago;
                    vm.habilitarTimbradoSelect = true;
                    changeCliente();

                }
            });
            //vm.comprobante.cambio=1;
            vm.comprobantesProductos = [/*{
             montosImponibles: [{}],
             tipo: getTipoProducto(vm.tiposDetalle)
             }*/];
            vm.comprobantesServicios = [/*{
             montosImponibles: [{}],
             tipo: vm.tiposDetalle[1]
             }*/];
        });
        vm.totalGeneralProductos = 0;
        vm.totalGeneralServicios = 0;
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);

        vm.transacciones.$promise.then(function (response) {
        });
        getPuntosEmisionVenta();
        filterTimbrado();
        vm.mostrarCambioPactado = false;

        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                SucursalesFactory.get($rootScope.AuthParams.locacionSeleccionada.sucursal.id,
                    "SucursalList"
                ).$promise.then(function (response) {
                    vm.comprobante.direccionOrigen = response.direccion;
                });
                //traslado venta
                TiposFactory.motivoTraslado().then(function (data) {
                    _.forEach(data.data, function (pp) {
                        if (pp.codigo == 'traslado_venta') {
                            vm.comprobante.motivoTranslado = pp;
                        }
                    });
                });
            }
        });

    }

    function setCantidadMax() {
        // Se recupera detalles del pedido para setear campo 'cantMaxima'
        PedidoVentaFactory.get(
            vm.venta.pedidoVenta.id,
            "PedidoVentaForm"
        ).$promise.then(function (response) {
            _.forEach(vm.comprobante.comprobanteDetalle, function (detalleComprobante) {
                if (detalleComprobante.producto != null) {
                    _.forEach(response.pedidoVentaDetalle, function (detallePedido) {
                        if (detallePedido.producto != null) {
                            if (detalleComprobante.producto.id == detallePedido.producto.id) {
                                detalleComprobante.cantMaxima = detallePedido.cantidad;
                                detalleComprobante.cantidadView = detalleComprobante.cantidad;
                            }
                        }
                    });
                }
            });
        });
    }

    function activateEdit() {
        vm.hasPermisoVendedor = AuthorizationService.hasPermission("modificar_vendedor");
        vm.hasPermisoObligaciones = AuthorizationService.hasPermission("update_obligaciones_asociadas_ventas");
        abortRouteIfNecessary();
        vm.title = $filter("translate")("EDIT_COMPROBANTE_VENTA");
        vm.tiposDetalle = {};
        vm.tipoCom = false;
        if (vm.venta && vm.venta.pedidoVenta) {
            vm.tipoCom = true;
        }

        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;
            vm.comprobante = comprobantePrepService;
            Promise.all(vm.promiseFormaPago)
                .then(function () {
                    filterFormasPago();
                });
            vm.existeAnticipo = false;
            if (vm.comprobante.comprobanteAnticipo) {
                vm.existeAnticipo = true;
            }
            if (vm.comprobante.cliente) {
                vm.comprobante.relacionarFacturas = vm.comprobante.relacionarFacturas ? vm.comprobante.relacionarFacturas : [];
                vm.staticFilterComprobantes = [];
                vm.staticFilterComprobantes.push({
                    path: 'tipo.clase.codigo',
                    equals: 'factura'
                });
                vm.staticFilterComprobantes.push({
                    path: "estado.codigo",
                    equals: "comp_venta_emitido"
                });
                vm.staticFilterComprobantes.push({
                    path: "cliente.id",
                    equals: vm.comprobante.cliente.id
                });
                vm.opcionesRelacionarFacturas.staticFilter = {
                    search: filterFactory.and(vm.staticFilterComprobantes).value()
                };

            }
            //changeCliente();
            if (vm.comprobante.listaPrecioVenta && vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                vm.mostrarCambioPactado = true;
            }

            var suc = [];
            for (var i = 0; i < vm.comprobante.cliente.sucursales.length; i++) {
                if (vm.comprobante.cliente.sucursales[i].activo === true) {
                    suc.push(vm.comprobante.cliente.sucursales[i])
                }
            }
            vm.sucursalesCliente = suc;
            if (vm.isProcesoVenta === true &&
                //!ComprobanteVentaFactory.hasPermisos(false, "edit", vm.comprobante.estado)
                !ComprobanteVentaFactory.hasPermisos(false, "edit", vm.comprobante.estado, null, null, null, null, vm.comprobante.venta)
            ) {
                $state.go("app.main");
            }

            if (vm.formSuscripcionRecurrente) {

                vm.comprobante.fechaInicial = moment(vm.comprobante.fechaInicial, "DD/MM/YYYY").toDate();
                vm.comprobante.fechaFinal = moment(vm.comprobante.fechaFinal, "DD/MM/YYYY").toDate();
                vm.comprobante.primeraFacturacion = moment(vm.comprobante.primeraFacturacion, "DD/MM/YYYY").toDate();
                $scope.dias.diasSemana = JSON.parse(vm.comprobante.diasDeLaSemana);
            } else {

                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY");
                vm.puntoEmisionVentaActual = vm.comprobante.timbradoPuntoEmision.puntoEmisionVenta;
            }

            vm.comprobante.fecha = new Date(vm.comprobante.fecha);
            if (vm.datoVirtual && !vm.fromOutside && vm.comprobante.timbradoPuntoEmision &&
                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") verificarTipoOperacion(vm.comprobante.cliente);

            if (vm.formSuscripcionRecurrente || !vm.fromOutside) {
                vm.getListasPreciosVentasByMoneda();
            }
            recuperarPreciosMinimosMaximos();
            vm.precioBase = "Precio de Venta";
            vm.porcRecargo = 0;
            if (vm.comprobante.listaPrecioVenta && vm.utilizarPrecioBase) {
                if (vm.comprobante.listaPrecioVenta.precioBase) {
                    vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                }
                vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
            } else if (vm.comprobante.listaPrecioVenta && !vm.utilizarPrecioBase) {
                ListaPrecioVentaFactory.get(
                    vm.comprobante.listaPrecioVenta.id,
                    "ComprobanteVentaForm"
                ).$promise.then(function (response) {
                    vm.comprobante.listaPrecioVenta = response;
                });
            }
            getPlanCobros();
            vm.entidad = "ComprobanteVenta";
            vm.entidadId = vm.comprobante.id;
            if (vm.comprobante.comprobanteDetalle) {
                _.forEach(vm.comprobante.comprobanteDetalle, function (detalle) {

                    //permite desplegar más campos a ser cargados
                    detalle.mostrarMas = false;

                    ComprobanteVentaDetalleFactory.get(
                        detalle.id,
                        "ConsultaComprobanteVentaForm"
                    ).$promise.then(function (response) {
                        detalle.montosImponibles = response.montosImponibles;
                    });
                });
            }

            splitDetalles();

            // Redondeo en funcion de moneda
            var cantDecimales = 0;
            if (vm.comprobante.moneda.codigo != 'guaranies') {
                cantDecimales = 2;
            }

            vm.totalGeneralProductos = _.reduce(
                vm.comprobantesProductos,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            //vm.totalGeneralProductos = parseFloat(vm.totalGeneralProductos.toFixed(cantDecimales));
            vm.totalGeneralProductos = UtilFactory.roundForMoneda(vm.totalGeneralProductos, vm.comprobante.moneda);


            vm.totalGeneralServicios = _.reduce(
                vm.comprobantesServicios,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            //vm.totalGeneralServicios = parseFloat(vm.totalGeneralServicios.toFixed(cantDecimales));
            vm.totalGeneralServicios = UtilFactory.roundForMoneda(vm.totalGeneralServicios, vm.comprobante.moneda);

            vm.isEdit = true;
            if (!vm.formSuscripcionRecurrente) {
                getPuntosEmisionVenta(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa);
                filterTimbrado();
            } else {
                getPuntosEmisionVenta();
            }

            //getProductoStockAlmacen();
            _.forEach(vm.comprobantesProductos, function (elem) {
                if ((elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') && !estaEnPV(elem)) {
                    /*ProductosFactory.get(elem.producto.id, 'BaseList').$promise.then(function (producto) {
                        elem.producto = producto;*/
                    getStockPorTipoComprobante(elem);
                    //});
                }
                //vm.calcularTotalDetalle();
            });
            if (vm.comprobante.cliente) {

                var staticFilterComprobante = {
                    search: filterFactory
                        .and([{
                            path: "cliente.id",
                            equals: vm.comprobante.cliente.id
                        }, {
                            path: "tipo.clase.codigo",
                            equals: "factura"
                        }, {
                            path: "estado.codigo",
                            equals: "comp_venta_emitido"
                        }])
                        .value()
                };
                ComprobanteVentaFactory.all(staticFilterComprobante, "ComprobanteVentaList").$promise.then(function (response) {
                    vm.relacionarFacturas = response;
                });
            }
            var filters = {
                search: filterFactory.single({
                    path: 'notaCredito.id',
                    equals: vm.comprobante.id
                }).value()
            };
            vm.optionsMovimientoStock.staticFilter = filters;
            vm.showMovimientoStock = true;

            formSelectionsArray();
        });
        $timeout(function () {
            $scope.$broadcast("formOpened");
        }, 20);

        if (vm.isProcesoVenta === true && vm.venta.pedidoVenta != null && !AuthorizationService.hasPermission("cant_mayor_pedido_venta")) {
            // Se realiza el seteo de la cantidad maxima
            setCantidadMax();
        }

        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                if (vm.comprobante.fechaInicio)
                    vm.comprobante.fechaInicio = new Date(vm.comprobante.fechaInicio);
                if (vm.comprobante.fechaFin)
                    vm.comprobante.fechaFin = new Date(vm.comprobante.fechaFin);
            }
        });
    }

    function activateView() {
        vm.title = "Comprobantes de Venta Pendientes de Nominación";
        vm.tiposDetalle = {};
        if (vm.formSuscripcionRecurrente) vm.fromOutside = true;

        TiposFactory.tiposDetalleComprobante().then(function (data) {
            vm.tiposDetalle = data.data;
            vm.comprobante = comprobantePrepService;
            Promise.all(vm.promiseFormaPago)
                .then(function () {
                    filterFormasPago();
                });
            vm.existeAnticipo = false;
            if (vm.comprobante.comprobanteAnticipo) {
                vm.existeAnticipo = true;
            }
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'numeroProcesoOrigen',
                equals: vm.comprobante.id + "",
            }]).value();
            SolicitudAutorizacionFactory.all(staticFilter).$promise.then(function (response) {

                if (response.length > 0) {
                    vm.mostrarVolver = true;
                } else {
                    vm.mostrarVolver = false;
                }
            });
            if (vm.comprobante.listaPrecioVenta && vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                vm.mostrarCambioPactado = true;
            }
            if (vm.isProcesoVenta === true && !ComprobanteVentaFactory.hasPermisos(false, "view")) {
                $state.go("app.main");
            }

            if (vm.formSuscripcionRecurrente) {

                vm.comprobante.fechaInicial = moment(vm.comprobante.fechaInicial, "DD/MM/YYYY").toDate();
                vm.comprobante.fechaFinal = moment(vm.comprobante.fechaFinal, "DD/MM/YYYY").toDate();
                //vm.comprobante.primeraFacturacion =  moment(vm.comprobante.primeraFacturacion, "DD/MM/YYYY").toDate();
                $scope.dias.diasSemana = JSON.parse(vm.comprobante.diasDeLaSemana);

            } else {

                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY");
                vm.puntoEmisionVentaActual = vm.comprobante.timbradoPuntoEmision.puntoEmisionVenta;
            }

            vm.comprobante.fecha = new Date(vm.comprobante.fecha);

            if (!vm.fromOutside && !vm.formSuscripcionRecurrente && vm.venta && vm.venta.pedidoVenta && vm.venta.pedidoVenta.listaPrecioVenta) {
                PedidoVentaFactory.get(
                    vm.venta.pedidoVenta.id,
                    "PedidoVentaForm"
                ).$promise.then(function (response) {
                    vm.comprobante.pedidoVenta = response;
                    vm.comprobante.listaPrecioVenta = vm.comprobante.pedidoVenta.listaPrecioVenta;
                    vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                });
            }

            ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
                if (parametro.valorTipo.codigo === 'no') {
                    vm.utilizarPrecioBase = false;
                    vm.precioBase = "Precio de Venta";
                    vm.porcRecargo = 0;
                } else {
                    vm.utilizarPrecioBase = true;
                    if (vm.comprobante.listaPrecioVenta && vm.comprobante.listaPrecioVenta.precioBase) {
                        vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                        vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                    }
                }
            });

            getPlanCobros();
            vm.entidad = "ComprobanteVenta";
            vm.entidadId = vm.comprobante.id;
            _.forEach(vm.comprobante.montosImponibles, function (impo) {
                impo.baseImponible = parseFloat(impo.baseImponible.toFixed(4));
                impo.valor = parseFloat(impo.valor.toFixed(4));
            });
            if (vm.comprobante.comprobanteDetalle) {
                _.forEach(vm.comprobante.comprobanteDetalle, function (detalle) {

                    //permite desplegar más campos a ser cargados
                    detalle.mostrarMas = false;

                    ComprobanteVentaDetalleFactory.get(
                        detalle.id,
                        "ConsultaComprobanteVentaForm"
                    ).$promise.then(function (response) {
                        detalle.montosImponibles = response.montosImponibles;
                    });
                });

            }
            splitDetalles();
            // Redondeo en funcion de moneda
            var cantDecimales = 0;
            if (vm.comprobante.moneda.codigo != 'guaranies') {
                cantDecimales = 2;
            }
            vm.totalGeneralProductos = _.reduce(
                vm.comprobantesProductos,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            //vm.totalGeneralProductos = parseFloat(vm.totalGeneralProductos.toFixed(cantDecimales));
            vm.totalGeneralProductos = UtilFactory.roundForMoneda(vm.totalGeneralProductos, vm.comprobante.moneda);


            vm.totalGeneralServicios = _.reduce(
                vm.comprobantesServicios,
                function (sum, detalle) {
                    return sum + detalle.totalItem;
                },
                0
            );
            //vm.totalGeneralServicios = parseFloat(vm.totalGeneralServicios.toFixed(cantDecimales));
            vm.totalGeneralServicios = UtilFactory.roundForMoneda(vm.totalGeneralServicios, vm.comprobante.moneda);

            if (vm.formSuscripcionRecurrente) {
                getPuntosEmisionVenta();
                //
            } else {
                getPuntosEmisionVenta(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa);
            }


            var filters = {
                search: filterFactory.single({
                    path: 'notaCredito.id',
                    equals: vm.comprobante.id
                }).value()
            };
            vm.optionsMovimientoStock.staticFilter = filters;
            vm.showMovimientoStock = true;

            formSelectionsArray();

            // Habilitar edicion de campo Cliente y Sucursal
            vm.hasPermisoModificar = (localStorage.getItem('modificarCompElectronico') === "true");
            vm.hasPermisoModificarAprobado = (localStorage.getItem('modificarCompElectronicoAprobado') === "true");

            // ademas del permiso, se debe cumplir ciertas condiciones
            vm.hasPermisoModificar = vm.hasPermisoModificar && vm.comprobante.timbradoPuntoEmision
                && vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo == "timbrado_virtual"
                && vm.comprobante.estado.codigo === "comp_venta_emitido";

            vm.hasPermisoModificarAprobado = vm.hasPermisoModificarAprobado && vm.comprobante.timbradoPuntoEmision
                && vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo == "timbrado_virtual"
                && vm.comprobante.estado.codigo === "comp_venta_aprobado_sifen"

            if (vm.hasPermisoModificar && vm.comprobante.cliente && vm.comprobante.cliente.tipoDocumento &&
                vm.comprobante.cliente.tipoDocumento.codigo == "tipo_documento_cliente_innominado") {
                vm.hasPermisoCliente = true;
            } else if (vm.hasPermisoModificarAprobado && vm.comprobante.cliente && vm.comprobante.cliente.tipoDocumento &&
                vm.comprobante.cliente.tipoDocumento.codigo == "tipo_documento_cliente_innominado") {
                vm.hasPermisoCliente = true;
            } else {
                vm.hasPermisoCliente = false;
            }

            if (vm.comprobante.origen && vm.comprobante.origen.codigo === 'comp_venta_pos') {
                vm.origen = true;
            } else {
                vm.origen = false;
            }
            localStorage.removeItem("modificarCompElectronico");
            localStorage.removeItem("modificarCompElectronicoAprobado");
        });
        vm.view = true;
        vm.hasPermisoVendedorView = !!localStorage.getItem('habilitarVendedor');
        vm.hasPermisoVendedor = !!localStorage.getItem('habilitarVendedor');
        localStorage.removeItem("habilitarVendedor");

        vm.new = false;
        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                if (vm.comprobante.fechaInicio)
                    vm.comprobante.fechaInicio = new Date(vm.comprobante.fechaInicio);
                if (vm.comprobante.fechaFin)
                    vm.comprobante.fechaFin = new Date(vm.comprobante.fechaFin);
            }
        });
    }

    var formSelectionsArray = function () {
        $scope.$watch('vm.optionsMovimientoStock.selection', function (newValue, oldValue) {
            if (!vm.optionsMovimientoStock.selection || vm.selectedChecked) {
                return;
            }
            vm.selectedChecked = true;
            _.forEach(vm.comprobante.movimientoStock, function (op) {
                if (op.id) {
                    vm.optionsMovimientoStock.selection[op.id] = true;
                } else {
                    vm.optionsMovimientoStock.selection[op] = true;
                }
            });
        });
    };

    function abortRouteIfNecessary() {
        if (vm.isProcesoVenta === true && vm.venta.estado.codigo === "venta_cerrada") {
            $state.go("app.main");
        }
    }

    function addProductoGrupo(detalle) {
        detalle.grupoProducto.producto = detalle.producto.id;
    }

    function getPlanCobros() {
        if (vm.comprobante.planCobros) {
            vm.cantidadCuotas = vm.comprobante.planCobros.cantidadCuotas;
            vm.montoInicial = vm.comprobante.planCobros.montoInicial;
            vm.plazoCuotas = vm.comprobante.planCobros.plazoCuotas;
        }
    }

    function getTipoServicio(tipos) {
        var tipoServicio = _.filter(tipos, function (elem) {
            return elem.codigo === "detalle_comprobante_servicio";
        });
        return tipoServicio[0];
    }

    function getTipoProducto(tipos) {
        var tipoProducto = _.filter(tipos, function (elem) {
            return elem.codigo === "detalle_comprobante_producto";
        });
        return tipoProducto[0];
    }

    function getMonedaLocal(callback) {
        var moneda = ParametrosFactory.filterByCodigo("moneda_local");
        moneda.$promise.then(function (data) {
            if (data && data.length > 0) {
                if (vm.comprobante.moneda == undefined) {
                    vm.comprobante.moneda = data[0].valorTipo;
                    typeof callback === 'function' ? callback() : "";
                }
                vm.monedaDefault = data[0].valorTipo;
            }
        });
    }

    function recuperarItemsRecepcion() {
        if (vm.comprobante.recepciones && vm.comprobante.recepciones.length > 0) {
            vm.comprobantesProductos = [];
            for (var i = 0; i < vm.comprobante.recepciones.length; i++) {
                var recepcion = vm.comprobante.recepciones[i];
                CompraFactory.getSugerenciasRecepcion(recepcion.id).then(function (
                    response
                ) {
                    if (response.data && response.data.length > 0) {
                        _.forEach(response.data, function (detalle) {
                            if (detalle.producto) {
                                ProductosFactory.get(
                                    detalle.producto.id,
                                    "ConsultaComprobanteVentaForm"
                                ).$promise.then(function (response) {
                                    vm.comprobantesProductos.push({
                                        montosImponibles: [{
                                            tipoImpuesto: response.tiposImpuesto[0],
                                            porcentaje: response.tiposImpuesto[0].porcentaje,
                                            valor: 0
                                        }],
                                        producto: detalle.producto,
                                        unidadMedida: detalle.unidadMedida,
                                        cantidad: detalle.cantidadRecepcionada,
                                        tipo: getTipoProducto(vm.tiposDetalle)
                                    });
                                });
                            }
                        });
                    }
                });
            }
        }
    }

    function getParamDiferenciaMonedaLocal() {
        var params = {};
        params.search = filterFactory
            .single({
                path: "codigo",
                equals: "maximo_diferencia_cabecera_detalle_moneda_local"
            })
            .value();
        params.view = "Base";
        vm.diferenciaMonedaLocal = ParametrosFactory.all(params);
    }

    function getParamDiferenciaMonedaExtranjera() {
        var params = {};
        params.search = filterFactory
            .single({
                path: "codigo",
                equals: "maximo_diferencia_cabecera_detalle_moneda_extranjera"
            })
            .value();
        params.view = "Base";
        vm.diferenciaMonedaExtranjera = ParametrosFactory.all(params);
    }

    function splitDetalles() {
        vm.comprobantesServicios = [];
        vm.comprobantesProductos = [];
        _.forEach(vm.comprobante.comprobanteDetalle, function (elem) {
            if (elem.servicio) {
                vm.comprobantesServicios.push(elem);
            } else {
                vm.comprobantesProductos.push(elem);
            }
        });
    }

    function searchSolicitudCompraFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return (
                item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.numero == criteria
            );
        };
    }

    function searchTimbradoPuntoEmisionFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return String(item.timbradoEmpresa.numeroTimbrado).toLowerCase().indexOf(criteria.toLowerCase()) > -1;

        };
    }

    function changeGrupoCompra() {
        if (vm.comprobante.grupoCompra.codigo == "comprobante_importacion") {
            vm.valorFOBRequerido = true;
            delete vm.comprobante.montosImponibles;
        } else if (!vm.formSuscripcionRecurrente && !vm.isProcesoVenta) {
            vm.comprobante.montosImponibles = [{}];
            vm.valorFOBRequerido = false;
            vm.comprobantesProductos[0].montosImponibles = [{}];
            vm.comprobantesServicios[0].montosImponibles = [{}];
            vm.comprobante.fob = 0;
            vm.comprobante.cliente = undefined;
            vm.comprobante.pedidoVenta = undefined;
        }
        var filterAlmacenes = {
            search: filterFactory
                .and([{
                    path: "sucursal.id",
                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                },
                {
                    path: "clase.id",
                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                }
                ])
                .value()
        };
        vm.depositos = AlmacenFactory.all(filterAlmacenes);
        vm.comprobante.deposito = undefined;
    }

    function changePV() {
        vm.comprobante.cliente = vm.venta.cliente;
        vm.comprobante.cambio = vm.venta.pedidoVenta.cambio;
        //if (vm.datoVirtual && !vm.fromOutside) verificarTipoOperacion(vm.comprobante.cliente);
        vm.comprobante.deposito = vm.venta.pedidoVenta.almacen;
        changeCliente();
        // filterProductos();
        changeCotizacion(true);
        getRemisionesCurrentPV();
        ParametrosFactory.filterByModulo("ventas").$promise.then(function (
            params
        ) {
            var param = _.find(params, function (parametro) {
                return parametro.codigo == "recuperar_items_pv";
            });

            if (!param) {
                return;
            }

            if (param.valorTipo.codigo == "si") {
                recuperarItemsPV();
            }
        });

    }

    function changeRecepciones() {
        var newRecepcion =
            vm.comprobante.recepciones[vm.comprobante.recepciones.length - 1];

        ClienteFactory.get(newRecepcion.proveedor.id).$promise.then(function (
            response
        ) {


            if (
                vm.comprobante.proveedor &&
                vm.comprobante.proveedor.id != response.id
            ) {
                var msg = $scope.translations.TODAS_RECEPCIONES_MISMO_PROVEEDOR_WARNING;
                notify({ message: msg, classes: "alert-danger", position: "right" });
                vm.comprobante.recepciones.splice(
                    vm.comprobante.recepciones.length - 1,
                    1
                );
                return;
            }
            if (
                vm.comprobante.deposito &&
                vm.comprobante.deposito.id != newRecepcion.almacen.id
            ) {
                var msg = $scope.translations.TODAS_RECEPCIONES_MISMO_ALMACEN_WARNING;
                notify({ message: msg, classes: "alert-danger", position: "right" });
                vm.comprobante.recepciones.splice(
                    vm.comprobante.recepciones.length - 1,
                    1
                );
                return;
            }
            vm.comprobante.deposito = newRecepcion.almacen || vm.comprobante.deposito;
            if (!vm.comprobante.ordenCompra) {
                vm.comprobante.proveedor = response;
                changeCliente();
            }
            // filterProductos();
            changeCotizacion();
            if (!vm.comprobante.ordenCompra) {
                recuperarItemsRecepcion();
            }
        });
    }

    function getRemisionesCurrentPV() {
        //vm.remisiones = [];
        // var staticFilter = {};
        // var filters = [];

        // filters = [{
        //         path: "pedidoVenta.id",
        //         equals: vm.comprobante.pedidoVenta.id
        //     },
        //     {
        //         path: "estado.codigo",
        //         equals: "remision_emitida"
        //     }
        // ];

        // if (vm.isProcesoVenta) {
        //     filters.push({
        //         path: "venta.id",
        //         equals: vm.venta.id
        //     });

        //     filters.push({
        //         path: "comprobanteVenta.id",
        //         isNull: true
        //     });
        // }

        // staticFilter.search = filterFactory.and(filters).value();
        // if (vm.watcherRemision) { vm.watcherRemision(); }
        // WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcherRemision) {
        //     vm.watcherRemision = watcherRemision;
        //     vm.remisiones = RemisionFactory.all(UtilFactory.createNestedFilters(locacionFilter.search.filters, staticFilter), "detail");
        // }, "locacion.id");
    }

    function recuperarItemsPV() {

        vm.uiBlockuiConfig.bloquear = true;
        VentaFactory.getSugerenciasPV(
            vm.comprobante.pedidoVenta.id,
            "comp_tipo_venta_comprobante"
        ).then(function (response) {
            vm.uiBlockuiConfig.bloquear = false;
            if (response.data && response.data.length > 0) {
                vm.comprobantesProductos = [];
                vm.comprobantesServicios = [];

                _.forEach(response.data, function (detalle) {
                    if (detalle.producto) {
                        //ProductosFactory.get(
                        ////    detalle.producto.id,
                        //    "ProductoList"
                        //  ).$promise.then(function (response) {
                        detalle.precio = vm.comprobante.cliente.exoneradoIva ?
                            calcularPrecioSinImpuestos(detalle.precio, detalle.producto.tiposImpuesto[0].porcentaje) :
                            detalle.precio;
                        var newProducto = {
                            montosImponibles: [{
                                tipoImpuesto: vm.comprobante.cliente.exoneradoIva ? vm.tipoExento[0] :
                                    detalle.producto.tiposImpuesto[0],
                                porcentaje: vm.comprobante.cliente.exoneradoIva ? vm.tipoExento[0].porcentaje :
                                    detalle.producto.tiposImpuesto[0].porcentaje,
                                valor: 0
                            }],
                            producto: detalle.producto,
                            unidadMedida: detalle.unidadMedida,
                            cantidad: (detalle.producto.criterioControl.codigo == "series" && vm.comprobanteGeneraMovimiento) ?
                                1 : detalle.cantidad,
                            grupoProducto: (detalle.producto.criterioControl.codigo ==
                                "series" || detalle.producto.criterioControl.codigo == "lotes") ? detalle.grupoProducto : undefined,
                            precioVenta: detalle.precio,
                            descuento: detalle.porcentajeDescuento,
                            tipo: getTipoProducto(vm.tiposDetalle),

                        };

                        // Si NO tiene el permiso, se debe restringir la cantidad
                        newProducto.cantMaxima = null;
                        if (!AuthorizationService.hasPermission("cant_mayor_pedido_venta")) {
                            newProducto.cantMaxima = newProducto.cantidad;
                        }

                        newProducto.numeroOrdenCompraCliente = detalle.numeroOrdenCompraCliente;
                        newProducto.numeroOrdenProduccion = detalle.numeroOrdenProduccion;
                        newProducto.fechaValidez = detalle.fechaValidez;
                        newProducto.cantidadUmPrincipal = detalle.cantidadUmPrincipal;
                        newProducto.precioVentaView = detalle.precio.toString().replace(/\./, ",");
                        newProducto.precioVenta = parseFloat(newProducto.precioVentaView.replace(/,/, "."));

                        // if (vm.verPrecio == 'No') {

                        //     CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precioRecuperado) {
                        //         if (precioRecuperado.data != null) {
                        //             newProducto.precioVentaView = precioRecuperado.data.toString().replace(/\./, ",");
                        //             newProducto.precioVenta = parseFloat(newProducto.precioVentaView.replace(/,/, "."));
                        //             //_.forEach(vm.comprobante.pedidoVenta.pedidoVentaDetalle, function (pedDet) {
                        //             //     if ((newProducto.producto.codigo == pedDet.producto.codigo) && (newProducto.producto.unidadMedida == pedDet.producto.unidadMedida)) {
                        //             //         newProducto.orden = pedDet.orden;
                        //             //     }
                        //             // });
                        //             //vm.comprobantesProductos.push(newProducto);
                        //             // vm.comprobantesProductos.sort(function (a, b) {
                        //             //   return a.orden - b.orden;
                        //             // });
                        //             // vm.calcularTotalDetalle(newProducto);
                        //         }
                        //     });
                        // } //else {
                        vm.comprobantesProductos.push(newProducto);
                        vm.calcularTotalDetalle(newProducto);
                        // }

                        //});
                    } else if (detalle.servicio) {
                        ServicioFactory.get(
                            detalle.servicio.id,
                            "BaseList"
                        ).$promise.then(function (response) {
                            var newServicio = {
                                montosImponibles: [{
                                    tipoImpuesto: response.tipoImpuesto,
                                    porcentaje: response.tipoImpuesto.porcentaje,
                                    valor: 0
                                }],
                                servicio: detalle.servicio,
                                cantidad: detalle.cantidad,
                                tipo: vm.tiposDetalle[1],
                                precioVenta: detalle.precio
                            };

                            vm.comprobantesServicios.push(newServicio);
                            vm.calcularTotalDetalle(newServicio);
                        });
                    }
                });
                copiarComprobantes();
                recuperarPreciosMinimosMaximos();
            }
        });
    }

    function puntoEmisionSelected() {

        vm.habilitarTimbradoSelect = false;

        // Esto se realiza porque el modelo de punto Emision no se encuentra en el formulario (se guarda en vm.puntoEmisionVentaActual)
        vm.comprobante.timbradoPuntoEmision = {};
        vm.comprobante.timbradoPuntoEmision.puntoEmisionVenta = vm.puntoEmisionVentaActual;

        if (vm.fromOutside || vm.formSuscripcionRecurrente) {
            filterTimbrado();
        }
    }

    function recuperarItemsRecepcion() {
        if (vm.comprobante.recepciones && vm.comprobante.recepciones.length > 0) {
            vm.comprobantesProductos = [];
            for (var i = 0; i < vm.comprobante.recepciones.length; i++) {
                var recepcion = vm.comprobante.recepciones[i];
                if (
                    recepcion.recepcionDetalle &&
                    recepcion.recepcionDetalle.length > 0
                ) {
                    _.forEach(recepcion.recepcionDetalle, function (detalle) {
                        if (detalle.producto) {
                            ProductosFactory.get(
                                detalle.producto.id,
                                "ConsultaComprobanteVentaForm"
                            ).$promise.then(function (response) {
                                vm.comprobantesProductos.push({
                                    montosImponibles: [{
                                        tipoImpuesto: response.tiposImpuesto[0],
                                        porcentaje: response.tiposImpuesto[0].porcentaje,
                                        valor: 0
                                    }],
                                    producto: detalle.producto,
                                    unidadMedida: detalle.unidadMedida,
                                    cantidad: detalle.cantidad,
                                    tipo: getTipoProducto(vm.tiposDetalle)
                                });
                            });
                        }
                    });
                }
            }
        }
    }

    function mostrarTodosProductos() {
        if (vm.todosProductos) {
            vm.opcionesProductosDt.staticFilter = null;
            if (vm.watcher) {
                vm.watcher();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcher = watcher;
                var filter = locacionFilter;
                if (
                    vm.fromOutside &&
                    vm.comprobante &&
                    vm.comprobante.tipo &&
                    vm.comprobante.tipo.clase.codigo != "nota_credito"
                ) {
                    var stockFilter = {
                        search: filterFactory
                            .single({
                                path: "controlStock",
                                equals: false
                            },
                                {
                                    path: 'estado.codigo',
                                    equals: 'activo'
                                })
                            .value()
                    };
                    var filter = UtilFactory.createNestedFilters(
                        locacionFilter.search.filters,
                        stockFilter
                    );
                }
                vm.productos = ProductosFactory.allForSelect(filter, "ConsultaComprobanteVentaForm");
                vm.opcionesProductosDt.staticFilter = filter;
            }, "locaciones.id");
        } else {
            // filterProductos();
        }
    }

    // function filterProductos() {
    //     var staticFilter = [{
    //             path: 'clientes.codigo',
    //             equals: vm.comprobante.proveedor.codigo
    //         },
    //         {
    //             path: 'itemCompra',
    //             like: 'true'
    //         }
    //     ];

    //     if (vm.watcher) { vm.watcher(); }
    //     WatchLocacionChangeFactory.executeWatching(function(locacionFilter, watcher) {
    //         vm.watcher = watcher;
    //         if (vm.fromOutside && vm.comprobante && vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo != "nota_credito") {
    //             var filterStock = {
    //                 path: "controlStock",
    //                 equals: false
    //             }
    //             staticFilter.push(filterStock);
    //         }
    //         var finalFilters = {
    //             search: filterFactory.and(staticFilter).value()
    //         };
    //         var filteredStructure = UtilFactory.createNestedFilters(locacionFilter.search.filters, finalFilters);
    //         console.log("Se traerán produtos con filtros: ", filteredStructure);
    //         vm.productos = ProductosFactory.allForSelect(filteredStructure);
    //         vm.opcionesProductosDt.staticFilter = UtilFactory.createNestedFilters(filteredStructure);
    //     }, "locaciones.id");

    // }

    function agregarDetalle() {
        vm.comprobante.comprobanteDetalle.push({
            montosImponibles: [{}],
            tipo: getTipoServicio(vm.tiposDetalle)
        });
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function agregarDetalleProducto() {
        vm.comprobantesProductos.push({
            montosImponibles: [{}],
            tipo: getTipoProducto(vm.tiposDetalle),
            id: undefined
        });

        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function agregarDetalleServicio() {
        vm.comprobantesServicios.push({
            montosImponibles: [{}],
            tipo: vm.tiposDetalle[1]
        });
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function agregarMontoImponible() {
        vm.comprobante.montosImponibles.push({ esTotal: true });
        $timeout(function () {
            $scope.$broadcast("newImpuestoAdded");
        }, 20);
    }

    function calcularTotalDetalle(detalle, index) {
        if (vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo == 'nota_credito') {
            if (!vm.permisoEditarPrecioVentaNotaCredito) {
                controlPrecioUltimoCosto(detalle, index);
            }
        } else {
            if (vm.comprobante.deposito && !(vm.comprobante.deposito.modificarPrecioEspecial && vm.permisoModificarPrecioAlmacenSelec)) {
                controlPrecioUltimoCosto(detalle, index);
            }
        }


        if (detalle.descuento && detalle.descuento > 0) {
            controlLimiteDescuento(detalle, index);
        }

        if (detalle.producto != null) {

            if (detalle.cantidad == undefined) {
                detalle.cantidad = 0;
                detalle.totalItem = 0;
            }

            if (detalle.precioVenta == undefined) {
                detalle.ultimoPrecio = 0;
                detalle.totalItem = 0;
            }

            if (detalle.descuento == undefined) {
                detalle.descuento = 0
            }

            var totalNeto

            if (detalle.descuento && detalle.descuento > 0) {
                totalNeto = detalle.precioVenta - (detalle.precioVenta * (detalle.descuento / 100))
            } else {
                totalNeto = detalle.precioVenta;
            }

            detalle.totalItem = (detalle.cantidad * totalNeto);
            detalle.totalItem = Math.round(detalle.totalItem * 100) / 100;//UtilFactory.roundForMoneda(detalle.totalItem, vm.comprobante.moneda);
            /* if (vm.comprobante.moneda != undefined) {
                detalle.totalItem = UtilFactory.roundForMoneda(detalle.totalItem, vm.comprobante.moneda);
            }*/
            if (detalle.montosImponibles) {
                if (!vm.comprobante.listaPrecioVenta) {
                    if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                        detalle.montosImponibles[0].baseImponible =
                            100 *
                            detalle.totalItem /
                            (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje);
                    } else {
                        detalle.montosImponibles[0].baseImponible = detalle.totalItem;
                    }
                } else {
                    if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                        detalle.montosImponibles[0].baseImponible =
                            100 *
                            detalle.totalItem /
                            (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje);
                    } else {
                        detalle.montosImponibles[0].baseImponible = detalle.totalItem;
                    }
                }

                detalle.montosImponibles[0].baseImponible = parseFloat(detalle.montosImponibles[0].baseImponible.toFixed(4));
                detalle.montosImponibles[0].valor = parseFloat((detalle.totalItem - detalle.montosImponibles[0].baseImponible).toFixed(4));
            }
            vm.totalGeneralProductos =
                _.reduce(
                    vm.comprobantesProductos,
                    function (sum, detalle) {
                        return sum + detalle.totalItem;
                    },
                    0
                );

            vm.totalGeneralProductos = UtilFactory.roundForMoneda(vm.totalGeneralProductos, vm.comprobante.moneda);

        } else {
            if (detalle.cantidad == undefined) {
                detalle.cantidad = 0;
                detalle.totalItem = 0;
            }

            if (detalle.precioVenta == undefined) {
                detalle.ultimoPrecio = 0;
                detalle.totalItem = 0;
            }

            if (detalle.descuento == undefined) {
                detalle.descuento = 0
            }

            var totalNeto

            if (detalle.descuento && detalle.descuento > 0) {
                totalNeto = detalle.precioVenta - (detalle.precioVenta * (detalle.descuento / 100))
            } else {
                totalNeto = detalle.precioVenta;
            }

            detalle.totalItem = (detalle.cantidad * totalNeto);
            detalle.totalItem = Math.round(detalle.totalItem * 100) / 100;//UtilFactory.roundForMoneda(detalle.totalItem, vm.comprobante.moneda);
            if (detalle.montosImponibles) {
                if (!vm.comprobante.listaPrecioVenta) {
                    if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                        detalle.montosImponibles[0].baseImponible =
                            100 *
                            detalle.totalItem /
                            (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje);
                    } else {
                        detalle.montosImponibles[0].baseImponible = detalle.totalItem;
                    }
                } else {
                    if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                        detalle.montosImponibles[0].baseImponible =
                            100 *
                            detalle.totalItem /
                            (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje);
                    } else {
                        detalle.montosImponibles[0].baseImponible = detalle.totalItem;
                    }
                }

                detalle.montosImponibles[0].baseImponible = parseFloat(detalle.montosImponibles[0].baseImponible.toFixed(4));
                detalle.montosImponibles[0].valor = parseFloat((detalle.totalItem - detalle.montosImponibles[0].baseImponible).toFixed(4));
            }

            vm.totalGeneralServicios =
                _.reduce(
                    vm.comprobantesServicios,
                    function (sum, detalle) {
                        return sum + detalle.totalItem;
                    },
                    0
                );

            vm.totalGeneralServicios = UtilFactory.roundForMoneda(vm.totalGeneralServicios, vm.comprobante.moneda);
        }
        vm.totalGeneral = vm.totalGeneralProductos + vm.totalGeneralServicios;
        if (vm.existeAnticipo && vm.comprobante.comprobanteAnticipo) {
            //sumar cantidad*precio tanto de productos como se servicios si existe
            var totalBruto = 0;
            var totalBrutoProducto = 0;
            var totalBrutoServicio = 0;
            if (vm.comprobantesProductos.length > 0) {
                _.forEach(vm.comprobantesProductos, function (det) {
                    totalBrutoProducto += det.cantidad * det.precioVenta;
                });
            }
            if (vm.comprobantesServicios.length > 0) {
                _.forEach(vm.comprobantesServicios, function (det) {
                    totalBrutoServicio += det.cantidad * det.precioVenta;
                });
            }
            totalBruto = totalBrutoProducto + totalBrutoServicio;
            vm.comprobante.porcentajeAnticipo = (vm.comprobante.importeAnticipo / totalBruto) * 100;
            vm.comprobante.porcentajeAnticipo = parseFloat(vm.comprobante.porcentajeAnticipo.toFixed(8));
            if (vm.comprobantesProductos.length > 0) {
                var sumCompProducto = 0;
                _.forEach(vm.comprobantesProductos, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta) / 100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo * elem.cantidad;
                    elem.totalItem = (elem.precioVenta - elem.importeAnticipo) * elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompProducto += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);

                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }

                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralProductos = UtilFactory.roundForMoneda(sumCompProducto, vm.comprobante.moneda);
            }
            if (vm.comprobantesServicios.length > 0) {
                var sumCompServicio = 0;

                _.forEach(vm.comprobantesServicios, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta) / 100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo * elem.cantidad;
                    elem.totalItem = (elem.precioVenta - elem.importeAnticipo) * elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompServicio += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);

                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }

                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralServicios = UtilFactory.roundForMoneda(sumCompServicio, vm.comprobante.moneda);
            }
        }

        // Seteo de campo  'Cantidad U.M. Principal'
        var contenida = null;
        if (detalle.unidadMedida != null && detalle.unidadMedida.cantidad != null) {
            contenida = detalle.unidadMedida.cantidad;
        }

        if (detalle.cantidad != null && detalle.cantidad > 0 && contenida != null) {
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidad * contenida).toFixed(4);
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidadUmPrincipal); // Esto elimina ceros despues de la coma
        } else {
            detalle.cantidadUmPrincipal = detalle.cantidad;
        }

    }

    function changeTipo(detalle) {
        if (detalle.tipo.codigo == "detalle_comprobante_producto") {
            detalle.servicio = null;
            detalle.cantidad = 0;
            detalle.precioVenta = 0;
            detalle.montosImponibles[0].tipoImpuesto = {};
            detalle.montosImponibles[0].valor = 0;
            detalle.totalItem = 0;
        } else if (detalle.tipo.codigo == "detalle_comprobante_servicio") {
            detalle.producto = null;
            detalle.cantidad = 0;
            detalle.precioVenta = 0;
            detalle.montosImponibles[0].tipoImpuesto = {};
            detalle.montosImponibles[0].valor = 0;
            detalle.totalItem = 0;
        }
    }

    function changeCliente() {
        if (vm.view == true) {        // Si esta modificando CLIENTE en modo VER (se puede modificar con un permiso)

            // Entonces solo tocar la sucursal
            var suc = [];
            for (var i = 0; i < vm.comprobante.cliente.sucursales.length; i++) {
                if (vm.comprobante.cliente.sucursales[i].activo === true) {
                    suc.push(vm.comprobante.cliente.sucursales[i])
                }
            }
            vm.sucursalesCliente = suc;
            if (vm.sucursalesCliente.length == 1) {
                vm.comprobante.sucursalCliente = vm.sucursalesCliente[0];
            }
            vm.uiBlockuiConfig.bloquear = false;

        } else {
            vm.comprobante.modoExoneracionIva = vm.comprobante.cliente.exoneradoIva ? vm.modosExoneracionIva[0] : null;
            vm.comprobante.tipo = vm.comprobante.tipo ? vm.comprobante.tipo : vm.tipos[0];
            vm.comprobante.tipoOperacion = vm.comprobante.cliente.tipoOperacion;
            //vm.comprobante.formaPago = undefined;
            var suc = [];
            for (var i = 0; i < vm.comprobante.cliente.sucursales.length; i++) {
                if (vm.comprobante.cliente.sucursales[i].activo === true) {
                    suc.push(vm.comprobante.cliente.sucursales[i])
                }
            }
            vm.sucursalesCliente = suc;
            if (vm.sucursalesCliente.length == 1) {
                vm.comprobante.sucursalCliente = vm.sucursalesCliente[0];
                ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
                    if (response.valorTipo.codigo === "si") {
                        vm.comprobante.direccionDestino = vm.comprobante.sucursalCliente.direccion;
                    }
                });
                vm.comprobante.vendedor = vm.venta != undefined && vm.venta.pedidoVenta && vm.venta.pedidoVenta.vendedor ?
                    vm.venta.pedidoVenta.vendedor :
                    angular.copy(vm.comprobante.sucursalCliente.vendedor);
                if (vm.formSuscripcionRecurrente) {
                    vm.comprobante.vendedor = vm.comprobante.sucursalCliente.vendedor;
                }
            } else if (vm.venta != undefined && vm.venta.pedidoVenta != undefined) {
                vm.comprobante.sucursalCliente = vm.venta.pedidoVenta.sucursalCliente;
                ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
                    if (response.valorTipo.codigo === "si") {
                        vm.comprobante.direccionDestino = vm.comprobante.sucursalCliente.direccion;
                    }
                });
            } else if (vm.sucursalesCliente.length > 1) {
                vm.comprobante.sucursalCliente = vm.sucursalesCliente[0];
            } else {
                vm.comprobante.sucursalCliente = null;
            }

            if (vm.comprobante.cliente.descuento == undefined || vm.comprobante.cliente.descuento == null) {
                vm.comprobante.cliente.descuento = 0;
            }

            var i = 0;
            _.forEach(vm.comprobante.comprobanteDetalle, function (elem) {
                elem.descuento = vm.comprobante.cliente.descuento != 0 ? vm.comprobante.cliente.descuento : elem.descuento;
                if (vm.comprobante.cliente.exoneradoIva) {
                    elem.montosImponibles[0].tipoImpuesto = vm.tipoExento
                } else {
                    if (elem.producto) {
                        elem.montosImponibles[0].tipoImpuesto = elem.producto.tiposImpuesto[0]
                        changeUnidad(elem);
                    } else {
                        elem.montosImponibles[0].tipoImpuesto = elem.servicio.tipoImpuesto
                        changeServicio(elem)
                    }
                }
                i = i + 1;
            });

            if (vm.comprobantesProductos.length > 0) {
                for (var i = 0; i < vm.comprobantesProductos.length; i++) {
                    if (Object.keys(vm.comprobantesProductos[0].montosImponibles[0]).length > 0) {
                        if (vm.comprobante.cliente.exoneradoIva) {
                            vm.comprobantesProductos[i].montosImponibles[0].tipoImpuesto = vm.tipoExento[0]
                        } else {
                            vm.comprobantesProductos[i].montosImponibles[0].tipoImpuesto = vm.comprobantesProductos[i].producto.tiposImpuesto[0]
                        }
                    }
                    vm.comprobantesProductos[i].descuento = vm.comprobante.cliente.descuento != 0 ? vm.comprobante.cliente.descuento : vm.comprobantesProductos[i].descuento;
                    if (vm.comprobantesProductos[i].id) {
                        changeUnidad(vm.comprobantesProductos[i]);
                    }
                }
            }

            if (vm.comprobantesServicios.length > 0) {
                for (var i = 0; i < vm.comprobantesServicios.length; i++) {
                    if (Object.keys(vm.comprobantesServicios[0].montosImponibles[0]).length > 0) {

                        if (vm.comprobante.cliente.exoneradoIva) {
                            vm.comprobantesServicios[i].montosImponibles[0].tipoImpuesto = vm.tipoExento[0]
                        } else {
                            vm.comprobantesServicios[i].montosImponibles[0].tipoImpuesto = vm.comprobantesServicios[i].servicio.tipoImpuesto
                        }
                    }
                    vm.comprobantesServicios[i].descuento = vm.comprobante.cliente.descuento != 0 ? vm.comprobante.cliente.descuento : vm.comprobantesServicios[i].descuento;
                    if (vm.comprobantesServicios[i].id) {
                        changeServicio(vm.comprobantesServicios[i]);
                    }
                }
            }
            vm.changeCotizacion();

            // if(vm.venta != undefined && vm.venta.pedidoVenta == undefined){
            var staticFilter = {};

            if (vm.comprobante.cliente.tipoVenta != null && vm.comprobante.cliente.tipoVenta.codigo === "condicion_cobro_pedido_venta_credito") {
                staticFilter = {
                    search: filterFactory
                        .or([
                            filterFactory
                                .and([{
                                    path: "clase.codigo",
                                    equals: "factura"
                                },
                                {
                                    path: "utilizarEnProcesosVenta",
                                    equals: true
                                },
                                {
                                    path: "condicion.codigo",
                                    equals: "tipo_comprobante_credito"
                                },
                                {
                                    path: "activo",
                                    like: "true"
                                }
                                ])
                                .value()
                        ])
                        .value()
                };
            } else {
                staticFilter = {
                    search: filterFactory
                        .or([
                            filterFactory
                                .and([{
                                    path: "clase.codigo",
                                    equals: "factura"
                                },
                                {
                                    path: "utilizarEnProcesosVenta",
                                    equals: true
                                },
                                {
                                    path: "condicion.codigo",
                                    equals: "tipo_comprobante_contado"
                                },
                                {
                                    path: "activo",
                                    like: "true"
                                }
                                ])
                                .value()
                        ])
                        .value()
                };
            }

            TipoComprobanteFactory.all(staticFilter, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                // vm.comprobante.tipo = vm.comprobante.tipo ? vm.comprobante.tipo : response[0];

                var tipoDefault = _.find(response, function (item) { return item.utilizarVentasDefecto === true });

                if (tipoDefault == undefined) {
                    tipoDefault = response[0];
                }


                if (vm.comprobante.pedidoVenta) {
                    vm.comprobante.tipo = vm.comprobante.tipo ? vm.comprobante.tipo : tipoDefault;
                } else {
                    if (vm.comprobante.tipo.codigo != tipoDefault.codigo) {
                        vm.cantidadCuotas = undefined;
                        vm.plazoCuotas = undefined;
                        vm.montoInicial = undefined;
                    }
                    vm.comprobante.tipo = response.length > 0 ? tipoDefault : vm.comprobante.tipo;
                }
                filterFormasPago();
                // vm.comprobante.formaPago = vm.comprobante.formaPago ? vm.comprobante.formaPago : vm.comprobante.cliente.formaPago;
                if (vm.comprobante.pedidoVenta && vm.comprobante.formaPago) {
                    vm.comprobante.formaPago = vm.comprobante.formaPago;
                } else if (!vm.comprobante.pedidoVenta && vm.comprobante.cliente.formaPago) {
                    vm.comprobante.formaPago = vm.comprobante.cliente.formaPago;
                }
                if (vm.comprobante.tipo.generarCuotas && vm.comprobante.pedidoVenta) {
                    vm.cantidadCuotas = vm.comprobante.pedidoVenta.cantidadCuotas;

                    if (vm.comprobante.pedidoVenta.formaPago) {
                        vm.plazoCuotas = vm.comprobante.pedidoVenta.formaPago.plazoDias;
                    } else if (vm.comprobante.formaPago) {
                        vm.plazoCuotas = vm.comprobante.formaPago.plazoDias;
                    } else {
                        vm.plazoCuotas = 0;
                    }
                } else if (vm.comprobante.tipo.generarCuotas && vm.comprobante.cliente.formaPago) {
                    vm.cantidadCuotas = vm.comprobante.cliente.formaPago.cantidadCuotas;
                    vm.plazoCuotas = vm.comprobante.cliente.formaPago.plazoDias;
                }
                vm.uiBlockuiConfig.bloquear = false;

                if (vm.comprobante.tipo.clase.codigo == 'nota_debito') {
                    vm.relacionarFactura();
                }


                filterTimbrado();
            });

            //vm.comprobante.tipoOperacion = null;

            if (vm.datoVirtual && !vm.fromOutside && vm.comprobante.timbradoPuntoEmision &&
                vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") verificarTipoOperacion(vm.comprobante.cliente);

            mostrarTodosProductos();

            if (vm.formSuscripcionRecurrente && !vm.view) {
                vm.comprobante.formaPago = vm.comprobante.cliente.formaPago;
            }
            changeCienteSucursal();

            //buscar si existen comprobantes de tipo anticipo para el cliente seleccionado
            var staticFilterComprobanteAnticipo = {};
            staticFilterComprobanteAnticipo.search = filterFactory
                .or([filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_emitido"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_aprobado_sifen"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                ])
                .value();

            ComprobanteVentaFactory.all(staticFilterComprobanteAnticipo, "ComprobanteVentaList").$promise.then(function (anticipos) {
                vm.existeAnticipo = false;
                if (anticipos.length > 0) {
                    vm.existeAnticipo = true;
                    vm.comprobante.tipoAnticipo = vm.tipoAnticipos[0];
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobantesAnticipo = [];
                    var staticFilterComprobante = {
                        search: filterFactory
                            .and([{
                                path: "cliente.id",
                                equals: vm.comprobante.cliente.id
                            }, {
                                path: "estado.codigo",
                                notEquals: "comp_venta_anulado"
                            }])
                            .value()
                    };
                    ComprobanteVentaFactory.all(staticFilterComprobante, "ComprobanteVentaList").$promise.then(function (comprobantes) {
                        _.forEach(anticipos, function (anticipo) {
                            var comprobanteExiste = comprobantes.find(function (compro) {
                                if (compro.comprobanteAnticipo != null) {
                                    if (compro.comprobanteAnticipo.id === anticipo.id) {
                                        return true;
                                    }
                                }
                            });
                            if (!comprobanteExiste) {
                                vm.comprobantesAnticipo.push(anticipo);
                            }
                        })

                    });
                } else {
                    vm.existeAnticipo = false;
                    vm.comprobante.tipoAnticipo = null;
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobantesAnticipo = [];
                }
            });
        }
    }

    function changeCienteSucursal() {
        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                vm.comprobante.direccionDestino = vm.comprobante.sucursalCliente.direccion;
            }
        });
        vm.comprobante.vendedor = angular.copy(vm.comprobante.sucursalCliente.vendedor);
    }

    function changeConductor() {
        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                vm.comprobante.cedulaConductor = vm.comprobante.chofer.numeroDocumento;
                vm.comprobante.direccionConductor = vm.comprobante.chofer.transportista.direccion;
                vm.comprobante.nombreTransportista = vm.comprobante.chofer.transportista.nombre;
                vm.comprobante.rucTransportista = vm.comprobante.chofer.transportista.numeroDocumento;

            }
        });
    }

    function changeCamion() {
        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                vm.comprobante.nroChapa = vm.comprobante.vehiculo.numeroChapa;
            }
        });
    }

    function changeCienteSucursal() {
        if ((vm.comprobante.sucursalCliente && vm.comprobante.sucursalCliente.vendedor && !vm.comprobante.pedidoVenta) || (vm.comprobante.sucursalCliente && vm.comprobante.sucursalCliente.vendedor && vm.comprobante.pedidoVenta && !vm.venta.pedidoVenta.vendedor)) {
            vm.comprobante.vendedor = vm.comprobante.sucursalCliente.vendedor;
        }
        if (vm.comprobante.tipo.clase.codigo === 'nota_credito') {
            vm.relacionarFactura();
        }
    }

    function changeTipoComprobante() {

        vm.cantidadCuotas = null;
        vm.plazoCuotas = null;
        vm.comprobante.formaPago = null;
        filterFormasPago();

        vm.montoInicial = undefined;

        vm.habilitarTimbradoSelect = false;

        vm.comprobante.motivoEmision = null;
        changeAlmacen();
        vm.comprobante.timbradoPuntoEmision = null;
        filterTimbrado();
        vm.changeCotizacion(vm.comprobante.moneda);

        vm.monedas = {};
        TiposFactory.monedas().then(function (data) {
            vm.monedas = data.data;
        });
        vm.comprobante.remisiones = undefined;
        if (vm.comprobante.tipo.clase.codigo == 'nota_credito' || vm.comprobante.tipo.clase.codigo == 'nota_debito') {
            vm.viewTipoApli = false;
            changeTipoAplicacion();
            vm.remisiones = vm.venta.remisiones.map(function (remision) {
                if (remision.estado.codigo != 'estados_remision_anulado') {
                    return remision;
                }
            });
        } else {
            vm.viewTipoApli = true;
            vm.tiposAplicaciones = {};
            vm.comprobante.tipoAplicacion = null;
            vm.remisiones = vm.venta.remisiones.map(function (remision) {
                if (remision.estado.codigo != 'estados_remision_anulado' && vm.recuperarRemisionFacturada == 'Sí') {
                    return remision;
                } else if (remision.estado.codigo != 'estados_remision_anulado' && vm.recuperarRemisionFacturada == 'No' && remision.facturado === false) {
                    return remision;
                }
            });
        }

        if (vm.isEdit && vm.comprobante.tipo.generarCuotas == false && vm.comprobante.planCobros != null) {
            vm.comprobante.planCobros = null;
        }

        if (vm.comprobante.tipo.clase.codigo === 'nota_credito' || vm.comprobante.tipo.clase.codigo == 'nota_debito') {
            vm.relacionarFactura();
        }

        if (vm.comprobante.tipo.clase.codigo == 'nota_debito') {
            vm.cantidadFacturaRelacionar = 1;
        }
    }

    function relacionarFactura() {
        if (vm.comprobante.cliente) {
            var filtersOr = [];
            var and1 = filterFactory.and([{
                path: "cliente.id",
                equals: vm.comprobante.cliente.id
            }, {
                path: "sucursalCliente.id",
                equals: vm.comprobante.sucursalCliente.id
            }, {
                path: "tipo.clase.codigo",
                equals: "factura"
            }, {
                path: "estado.codigo",
                equals: "comp_venta_emitido"
            }]).value();
            filtersOr.push(and1);

            var and2 = filterFactory.and([{
                path: "cliente.id",
                equals: vm.comprobante.cliente.id
            }, {
                path: "sucursalCliente.id",
                equals: vm.comprobante.sucursalCliente.id
            }, {
                path: "tipo.clase.codigo",
                equals: "factura"
            }, {
                path: "estado.codigo",
                equals: "comp_venta_aprobado_sifen"
            }]).value();
            filtersOr.push(and2);

            var staticFilterComprobante = { search: filterFactory.or(filtersOr).value() };

            ComprobanteVentaFactory.all(staticFilterComprobante, "RelacionarComprobanteVentaForm").$promise.then(function (response) {
                vm.relacionarFacturas = response;
                $timeout(function () {
                    vm.comprobante.relacionarFacturas = vm.comprobante.relacionarFacturas ? vm.comprobante.relacionarFacturas : [];
                    vm.staticFilterComprobantes = [];
                    filtersOr = [];
                    and1 = filterFactory.and([{
                        path: 'tipo.clase.codigo',
                        equals: 'factura'
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_emitido"
                    }, {
                        path: "sucursalCliente.id",
                        equals: vm.comprobante.sucursalCliente.id
                    }, {
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }]).value();
                    filtersOr.push(and1);
                    and2 = filterFactory.and([{
                        path: 'tipo.clase.codigo',
                        equals: 'factura'
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_aprobado_sifen"
                    }, {
                        path: "sucursalCliente.id",
                        equals: vm.comprobante.sucursalCliente.id
                    }, {
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }]).value();
                    filtersOr.push(and2);

                    vm.opcionesRelacionarFacturas.staticFilter = {
                        search: filterFactory.or(filtersOr).value()
                    };
                })
            });
        }
    }

    function obtenerDetallesComprobante(id) {
        ComprobanteVentaFactory.get(id, "ComprobanteVentaList").$promise.then(function (response) {
            for (var i = 0; i < vm.comprobante.relacionarFacturas.length; i++) {
                if (vm.comprobante.relacionarFacturas[i].id === id) {
                    vm.comprobante.relacionarFacturas[i] = response;
                }
            }
        });
    }

    function filterTimbrado(timbradoEmpresa) {
        if (vm.watcherTimbrado) {
            vm.watcherTimbrado();
        }
        WatchLocacionChangeFactory.executeWatching(function (
            locacionFilter,
            watcherTimbrado
        ) {
            vm.watcherTimbrado = watcherTimbrado;
            //if (vm.comprobante != undefined && vm.comprobante.tipo != undefined && vm.comprobante.fecha != undefined && vm.puntoEmisionVentaActual != undefined) {


            var staticFilter2 = {};
            var andFiltersArray = [{
                path: "timbradoEmpresa.fechaDesde",
                equalOrBefore: moment(vm.comprobante.fecha).format("DD/MM/YYYY")
            },
            {
                path: "timbradoEmpresa.fechaHasta",
                equalOrAfter: moment(vm.comprobante.fecha).format("DD/MM/YYYY")
            }
            ];

            if (vm.comprobante && vm.comprobante.tipo) {
                andFiltersArray.push({
                    path: "tipoComprobante.codigo",
                    equals: vm.comprobante.tipo.clase.codigo
                })
            }

            if (vm.comprobante && vm.puntoEmisionVentaActual) {
                andFiltersArray.push({
                    path: "puntoEmisionVenta.id",
                    equals: vm.puntoEmisionVentaActual.id
                })
            }

            if (vm.fromOutside === true) {
                andFiltersArray.push({
                    path: "timbradoEmpresa.claseTimbrado.codigo",
                    equals: "timbrado_manual"
                })
            } else {
                andFiltersArray.push({
                    path: "timbradoEmpresa.claseTimbrado.codigo",
                    notEquals: "timbrado_manual"
                })
            }

            andFiltersArray.push(
                {
                    path: "timbradoEmpresa.activo",
                    equals: true
                })

            var extraConditions = [{
                path: "timbradoEmpresa.sucursal.id",
                equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
            },
            {
                path: "timbradoEmpresa.sucursal.id",
                isNull: true
            }
            ]

            var finalAndFilters = _.map(extraConditions, function (cond) {
                var arr = [cond]
                arr = arr.concat(andFiltersArray)
                return filterFactory.and(arr).value();
            })
            staticFilter2.search = filterFactory
                .or(finalAndFilters)
                .value();

            TimbradoEmpresaPuntoEmisionVentaFactory.all(staticFilter2, "ConsultaComprobanteVentaForm").$promise.then(function (data) {

                vm.habilitarTimbradoSelect = true;

                vm.timbradosPuntoEmision = data;
                _.forEach(vm.timbradosPuntoEmision, function (obj) {
                    obj.timbradoEmpresa.fechaDesde = moment(obj.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
                    obj.timbradoEmpresa.fechaHasta = moment(obj.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY");
                })


                if (vm.timbradosPuntoEmision == null || vm.timbradosPuntoEmision == undefined || vm.timbradosPuntoEmision.length == 0) {
                    var msg = $filter("translate")("NO_TIMBRADO_FOUND");
                    notify({ message: msg, classes: "alert-danger", position: "right" });
                }

                if (timbradoEmpresa != undefined) {
                    vm.comprobante.timbradoPuntoEmision = _.find(vm.timbradosPuntoEmision, function (timbrado) {
                        if (timbradoEmpresa.numeroTimbrado === timbrado.timbradoEmpresa.numeroTimbrado) {
                            return timbrado;
                        }
                    });
                }
                if (vm.timbradosPuntoEmision != null && vm.timbradosPuntoEmision != undefined && vm.timbradosPuntoEmision.length == 1 && vm.comprobante.cliente != null && vm.comprobante.cliente != undefined) {
                    vm.comprobante.timbradoPuntoEmision = vm.timbradosPuntoEmision[0];
                    var timbradoAux = null;
                    if (vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                        timbradoAux = _.find(vm.tipoTransacciones, function (tipo) {
                            if ('venta_mercaderia' === tipo.codigo) {
                                return tipo;
                            }
                        });
                        if ((vm.comprobante.tipoTransaccion == undefined || vm.comprobante.tipoTransaccion == null) && timbradoAux != null) {
                            vm.comprobante.tipoTransaccion = timbradoAux;
                        }
                    } else {
                        vm.comprobante.tipoTransaccion = null;
                    }
                    if (vm.datoVirtual && !vm.fromOutside && vm.comprobante.cliente &&
                        vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") verificarTipoOperacion(vm.comprobante.cliente);
                }

            });
            //}
            if (vm.comprobante) {
                vm.comprobante.numeroTimbrado = undefined;
            }

        }, "locacion.id");
    }

    function showNoPEVMessage() {
        var msg = $scope.translations.NO_PEV_CAJA_MESSAGE;
        notify({ message: msg, classes: "alert-danger", position: "right" });
    }

    function getPuntosEmisionVenta(timbradoEmpresa) {
        if (!vm.fromOutside && !vm.formSuscripcionRecurrente) {
            var caja = null;
            _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                    caja = cajas;
                }
            });
            if (vm.comprobante != undefined && vm.comprobante.timbradoPuntoEmision != undefined) {
                if (caja.puntoEmision.id != vm.comprobante.timbradoPuntoEmision.puntoEmisionVenta.id && !$state.is("app.ventas.proceso.comprobanteventa.view")) {
                    vm.cancel();
                    var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR4');
                    notify({ message: msg, classes: "alert-danger", position: "right" });
                }
            } else {

                if (caja != null) {
                    cajaFactory.getCaja(caja.id, "CajaList").$promise.then(function (caja) {
                        puntoVentaFactory.get(caja.puntoEmision.puntoVenta.id, "ConsultaComprobanteVentaForm").$promise.then(function (puntoVenta) {
                            if (puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                vm.puntoEmisionVentaActual = caja.puntoEmision;
                            } else {
                                var msg = "La sucursal del sistema no coincide con la sucursal de la caja registrada";
                                notify({ message: msg, classes: "alert-danger", position: "right" });
                            }
                        });
                    });
                } else {
                    var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                    notify({ message: msg, classes: "alert-danger", position: "right" });
                }
            }

        } else {
            if (vm.watchPE) {
                vm.watchPE();
            }
            WatchLocacionChangeFactory.executeWatching(function (
                locacionFilter,
                watcher
            ) {
                vm.watcherPE = watcher;
                var filters = {
                    search: filterFactory.and([{
                        path: "puntoVenta.sucursal.id",
                        equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                    }]).value()
                }
                vm.puntosEmision = puntoEmisionFactory.all(filters, "ConsultaComprobanteVentaForm");
            }, "locacion.id");
        }
    }

    function changeProducto(detalle, index) {
        //Todos los comprobantes generan movimiento
        //vm.comprobanteGeneraMovimiento = true;

        var isPV = estaEnPV(detalle);

        if (!isPV && (vm.comprobante.deposito == undefined || vm.comprobante.deposito.id == undefined) && vm.comprobanteGeneraMovimiento) {
            var msg = $filter('translate')('VERIFIQUE_ALMACEN');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            vm.comprobantesProductos.pop();

            return;
        }

        if (!isPV && vm.comprobante.tipo == undefined && vm.comprobanteGeneraMovimiento) {
            var msg = $filter('translate')('VERIFIQUE_TIPO_COMPROBANTE');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            vm.comprobantesProductos.pop();

            return;
        }

        detalle.descuento = vm.comprobante.cliente.descuento;


        detalle.servicio = null;
        detalle.cantidad = 1;
        detalle.cantidadView = null;
        detalle.unidadMedida = detalle.producto.unidadMedidaBase;
        detalle.codigoBarras = [detalle.producto.codigoBarrasPrincipal.codigoBarras];

        if (vm.comprobante.listaPrecioVenta) {
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'listaPrecioVenta.id',
                equals: vm.comprobante.listaPrecioVenta.id,
            },
            {
                path: 'producto.id',
                equals: detalle.producto.id,
            }]).value();
            staticFilter.view = "ComprobanteVentaForm";
            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                if ((response == undefined || response.length == 0) && vm.permitirProductosNotListaPrecio == 'No') {
                    var msg = $filter("translate")("PRODUCTO_NO_EXISTE_LISTA_PRECIO");
                    notify({ message: msg, classes: "alert-warning", position: "right" })
                    removeItemFromArray(detalle, vm.comprobantesProductos, index);
                    return;
                } else {
                    changeUnidad(detalle, index, response);

                }
            });
        } else {
            changeUnidad(detalle);
        }

        if (!vm.comprobante.grupoCompra ||
            vm.comprobante.grupoCompra.codigo != "comprobante_importacion"
        ) {
            if (vm.comprobante.cliente.exoneradoIva) {
                detalle.montosImponibles[0].tipoImpuesto =
                    vm.tipoExento[0];
                detalle.montosImponibles[0].porcentaje =
                    vm.tipoExento[0].porcentaje;
                detalle.montosImponibles[0].valor = 0;
            } else {
                detalle.montosImponibles[0].tipoImpuesto =
                    detalle.producto.tiposImpuesto[0];
                detalle.montosImponibles[0].porcentaje =
                    detalle.producto.tiposImpuesto[0].porcentaje;
                detalle.montosImponibles[0].valor = 0;
            }
        }
        detalle.totalItem = 0;
        detalle.grupoProducto = null;

        if (detalle.producto.criterioControl.codigo === "series") {
            detalle.cantidad = 1;
        }
        if (vm.comprobanteGeneraMovimiento && (detalle.producto.criterioControl.codigo === "series" || detalle.producto.criterioControl.codigo === "lotes") && !isPV) {
            getStockPorTipoComprobante(detalle);
        }
    }

    function estaEnPV(detalle) {
        if (vm.venta != undefined && vm.venta.pedidoVenta != undefined) {
            var pedidoVentaDetalle = _.find(vm.venta.pedidoVenta.pedidoVentaDetalle, function (pedido) {
                if (pedido.producto.id === detalle.producto.id) {
                    return pedido;
                }
            });
            if (pedidoVentaDetalle != null) return true;
        }
        return false;
    }

    function changeUnidad(detalle, index, productosFromListaPrecio) {
        //Si se utiliza el precio Base y el cliente está asociado a una lista de precios de ventas.
        if (vm.utilizarPrecioBase && vm.comprobante.listaPrecioVenta) {
            var precioBase = (vm.comprobante.listaPrecioVenta.precioBase) ? vm.comprobante.listaPrecioVenta.precioBase.codigo : '';
            if (precioBase == 'ultimo_costo') {

                if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambioPactado) {
                    //si el comprobante es en gs y la lista en moneda extranjera
                    detalle.precioVenta = parseFloat(vm.ultimoCostoIVA.replace(/,/, "."));
                } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                    //si el comprobante es en moneda extranjera y la lista en Gs
                    var precioFinal = vm.ultimoCostoIVA / vm.comprobante.cambio;
                    detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambio) {
                    //si el comprobante es en moneda extranjera y la lista en moneda extranjera
                    var precioFinal = vm.ultimoCostoIVA / vm.comprobante.cambio;
                    detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                } else {
                    detalle.precioView = vm.ultimoCostoIVA.toString().replace(/\./, ",");
                    detalle.precioVenta = parseFloat(detalle.precioView.replace(/,/, "."));
                }
                if (detalle.precioVenta > 0 && vm.comprobante.listaPrecioVenta.porcentajeRecargo > 0) {
                    vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                    var precioAux = detalle.precioVenta * (vm.porcRecargo / 100) + detalle.precioVenta;
                    detalle.precioVenta = UtilFactory.roundForMoneda(precioAux, vm.comprobante.moneda);
                }
                calcularTotalDetalle(detalle, index);
            }
            else {
                CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                    if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambioPactado) {
                        //si el comprobante es en gs y la lista en moneda extranjera
                        detalle.precioVenta = parseFloat(precio.data.replace(/,/, "."));
                    } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                        //si el comprobante es en moneda extranjera y la lista en Gs
                        var precioFinal = precio.data / vm.comprobante.cambio;
                        detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                    } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambio) {
                        //si el comprobante es en moneda extranjera y la lista en moneda extranjera
                        var precioFinal = precio.data / vm.comprobante.cambio;
                        detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                    } else {
                        detalle.precioView = precio.data.toString().replace(/\./, ",");
                        detalle.precioVenta = parseFloat(detalle.precioView.replace(/,/, "."));
                    }
                    if (detalle.precioVenta > 0 && vm.comprobante.listaPrecioVenta.porcentajeRecargo > 0) {
                        vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                        var precioAux = detalle.precioVenta * (vm.porcRecargo / 100) + detalle.precioVenta;
                        detalle.precioVenta = UtilFactory.roundForMoneda(precioAux, vm.comprobante.moneda);
                    }
                    calcularTotalDetalle(detalle, index);
                });
            }
        } else if (!vm.utilizarPrecioBase && vm.comprobante.listaPrecioVenta) {
            var productos = [];
            var productoLista = null;

            if (productosFromListaPrecio) {

                for (var i = 0; i < productosFromListaPrecio.length; i++) {
                    if (detalle.unidadMedida.id == productosFromListaPrecio[i].unidadMedida.id) {
                        productoLista = productosFromListaPrecio[i];
                    }
                }
                if (productoLista) {
                    if (vm.comprobante.cliente.exoneradoIva) {
                        productoLista.precioVenta = calcularPrecioSinImpuestos(productoLista.precioVenta, detalle.producto.tiposImpuesto[0].porcentaje)
                    }
                    if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                        //si el comprobante es en gs y la lista en moneda extranjera
                        detalle.precioVenta = productoLista.precioVenta * vm.comprobante.cambioPactado;
                        detalle.precioMinimo = productoLista.precioMinimo * vm.comprobante.cambioPactado;
                        detalle.precioMaximo = productoLista.precioMaximo * vm.comprobante.cambioPactado;
                        detalle.precioVenta = UtilFactory.roundForMoneda(detalle.precioVenta, vm.comprobante.moneda)
                    } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                        //si el comprobante es en moneda extranjera y la lista en gs
                        var precioFinal = productoLista.precioVenta / vm.comprobante.cambio;
                        var precioMin = productoLista.precioMinimo / vm.comprobante.cambio;
                        var precioMax = productoLista.precioMaximo / vm.comprobante.cambio;
                        detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                        detalle.precioMinimo = Math.round(precioMin * 100) / 100;
                        detalle.precioMaximo = Math.round(precioMax * 100) / 100;
                    } else {
                        detalle.precioVenta = productoLista.precioVenta;
                        detalle.precioMinimo = productoLista.precioMinimo;
                        detalle.precioMaximo = productoLista.precioMaximo;
                    }
                    var obj = {
                        "precioMinimo": detalle.precioMinimo,
                        "precioMaximo": detalle.precioMaximo
                    };
                    vm.precioMinimoMaximos.splice(index, 1, obj);

                    vm.productoPerteneceLista = true;
                } else {
                    vm.productoPerteneceLista = false;
                    detalle.precioVenta = 0;
                    CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                        if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambioPactado) {
                            //si el comprobante es en gs y la lista en moneda extranjera
                            detalle.precioVenta = precio.data * vm.comprobante.cambioPactado;
                            detalle.precioVenta = UtilFactory.roundForMoneda(detalle.precioVenta, vm.comprobante.moneda)
                        } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies" && vm.comprobante.cambio) {
                            //si el comprobante es en moneda extranjera y la lista en gs
                            var precioFinal = precio.data / vm.comprobante.cambio;
                            detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                        } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies" && vm.comprobante.cambio) {
                            //si el comprobante es en moneda extranjera y la lista en moneda extranjera
                            var precioFinal = precio.data / vm.comprobante.cambio;
                            detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                        } else {
                            detalle.precioView = precio.data.toString().replace(/\./, ",");
                            detalle.precioVenta = parseFloat(detalle.precioView.replace(/,/, "."));
                        }
                        calcularTotalDetalle(detalle, index);
                    });
                }
                calcularTotalDetalle(detalle, index);
            } else {
                //El cliente está asociado a una lista de precios de ventas sin detalles
            }
        } else {
            CambioPrecioFactory.getUltimoPrecio(detalle.producto.id, detalle.unidadMedida.id).then(function (precio) {
                if (vm.comprobante.cliente.exoneradoIva) {
                    precio.data = calcularPrecioSinImpuestos(precio.data, detalle.producto.tiposImpuesto[0].porcentaje)
                }
                if (precio.data <= 0) {
                    precio.data = detalle.precioVenta ? detalle.precioVenta : 0;
                }
                if (vm.comprobante.moneda.codigo == "guaranies") {
                    detalle.precioView = precio.data.toString().replace(/\./, ",");
                    detalle.precioVenta = parseFloat(detalle.precioView.replace(/,/, "."));
                } else {
                    if (vm.comprobante.cambio) {
                        var precioFinal = precio.data / vm.comprobante.cambio;
                        detalle.precioVenta = Math.round(precioFinal * 100) / 100;
                    }
                }

                calcularTotalDetalle(detalle, index);
            });
        }

    }

    function changeServicio(detalle) {

        detalle.producto = null;
        detalle.cantidad = 1;
        if (vm.comprobante.cliente.exoneradoIva) {
            detalle.montosImponibles[0].tipoImpuesto = vm.tipoExento[0];
            detalle.montosImponibles[0].porcentaje = vm.tipoExento[0].porcentaje;
            detalle.montosImponibles[0].valor = 0;
        } else {
            detalle.montosImponibles[0].tipoImpuesto = detalle.servicio.tipoImpuesto;
            detalle.montosImponibles[0].porcentaje =
                detalle.servicio.tipoImpuesto.porcentaje;
            detalle.montosImponibles[0].valor = 0;
        }
        detalle.totalItem = 0;

        CambioPrecioServicioFactory.getUltimoPrecio(detalle.servicio.id).then(function (precio) {
            if (vm.comprobante.cliente.exoneradoIva) {
                precio.data = calcularPrecioSinImpuestos(precio.data, detalle.servicio.tipoImpuesto.porcentaje)
            }
            if (precio.data <= 0) {
                precio.data = detalle.precioVenta ? detalle.precioVenta : 0;
            }
            var aux = parseFloat(precio.data).toFixed(2);
            detalle.precioVentaView = aux.toString().replace(/\./, ",");
            detalle.precioVenta = parseFloat(detalle.precioVentaView.replace(/,/, "."));

            vm.calcularTotalDetalle(detalle);
        });
    }

    function changeFecha() {
        vm.habilitarTimbradoSelect = false;
        vm.comprobante.timbradoPuntoEmision = undefined;
        //changeCotizacion();
        filterTimbrado();
        vm.comprobante.timbradoPuntoEmision = undefined;
        vm.changeCotizacion(vm.comprobante.moneda);
        ParametrosFactory.getByCodigo('factura_remisión').then(function (response) {
            if (response.valorTipo.codigo === "si") {
                if (vm.comprobante.fechaInicio)
                    vm.comprobante.fechaInicio = new Date(vm.comprobante.fechaInicio);
                if (vm.comprobante.fechaFin)
                    vm.comprobante.fechaFin = new Date(vm.comprobante.fechaFin);
            }
        });
    }

    function changeFechaSuscripcion() {

    }

    function changeCotizacion(isNew) {

        //if (vm.new == true) {
        // var hoy = new Date();
        // hoy.setHours(0, 0, 0, 0);
        // vm.comprobante.fecha = hoy;
        // }

        if (vm.comprobante.comprobanteDetalle && vm.comprobante.comprobanteDetalle.length > 0) {
            vm.comprobante.comprobanteDetalle.map(function (detalle) {
                calcularTotalDetalle(detalle);
            })
        }

        /* Solo cuando se selecciona la moneda se revisa si el cliente está
            asociado a una lista de precios de ventas. */
        if (!vm.comprobante.pedidoVenta && vm.comprobante.cliente && !vm.fromOutside) {

            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'cliente.id',
                equals: vm.comprobante.cliente.id
            }, {
                path: 'estado.codigo',
                equals: 'lista_activada'
            }]).value();

            ListaPrecioVentaFactory.all(staticFilter, "ComprobanteVentaForm").$promise.then(function (response) {
                /* Se controla si el cliente está asociado a una lista de precios por asociación directa
                con la moneda seleccionada */
                if (response.length > 0) {
                    vm.comprobante.listaPrecioVenta = response[0];
                    vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                    if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                        //si el comprobante es en gs y la lista en moneda extranjera
                        vm.mostrarCambioPactado = true;
                        vm.monedaLocal.$promise.then(function (data) {

                            if (vm.comprobante.monedaLista && vm.comprobante.monedaLista.descripcion != data[0].valorTipo.descripcion) {
                                vm.cotizacionRequerida = true;

                                var params = {};
                                params.search = filterFactory.and([{
                                    path: 'monedaDesde.id',
                                    equals: vm.comprobante.monedaLista.id
                                },
                                {
                                    path: 'monedaHasta.id',
                                    equals: vm.monedaLocal[0].valorTipo.id
                                },
                                {
                                    path: 'fecha',
                                    like: moment(vm.comprobante.fecha).format('DD/MM/YYYY')
                                    //equals:vm.comprobante.fecha
                                }
                                ]).value();
                                params.view = 'CotizacionMonedaList';
                                CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                                    if (response && response[0]) {
                                        vm.comprobante.cambioPactado = response[0].valorCompra;
                                    } else {
                                        vm.comprobante.cambioPactado = null;
                                    }
                                });
                            } else {
                                vm.cotizacionRequerida = false;
                                vm.comprobante.cambioPactado = 1;
                            }
                        });
                    } else {
                        vm.mostrarCambioPactado = false;
                    }
                    vm.getListasPreciosVentasByMoneda();
                    vm.actualizarPreciosDeVentas();
                    ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
                        if (parametro.valorTipo.codigo === 'no') {
                            vm.utilizarPrecioBase = false;
                        } else {
                            vm.utilizarPrecioBase = true;
                            if (vm.comprobante.listaPrecioVenta) {
                                vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                                if (vm.comprobante.listaPrecioVenta.precioBase) {
                                    vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                                }
                            }
                        }
                    });
                } else if (response.length === 0 && vm.comprobante.cliente.tipoCliente) {
                    /* Se controla si el cliente está asociado a una lista de precios por su tipo de cliente
                    con la moneda seleccionada */
                    $timeout(function () {
                        var staticFilter = {};
                        staticFilter.search = filterFactory.and([{
                            path: 'tipoCliente.id',
                            equals: vm.comprobante.cliente.tipoCliente.id
                        }, {
                            path: 'estado.codigo',
                            equals: 'lista_activada'
                        }, {
                            path: 'moneda.codigo',
                            equals: vm.comprobante.moneda.codigo
                        }]).value();
                        ListaPrecioVentaFactory.all(staticFilter, "ComprobanteVentaForm").$promise.then(function (response) {
                            if (response.length > 0) {
                                vm.uiBlockuiConfig.bloquear = false;
                                vm.comprobante.listaPrecioVenta = response[0];
                                vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                                vm.actualizarPreciosDeVentas();
                                vm.getListasPreciosVentasByMoneda();
                                ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
                                    if (parametro.valorTipo.codigo === 'no') {
                                        vm.utilizarPrecioBase = false;
                                    } else {
                                        vm.utilizarPrecioBase = true;
                                        if (vm.comprobante.listaPrecioVenta) {
                                            vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                                            if (vm.comprobante.listaPrecioVenta.precioBase) {
                                                vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                                            }
                                        }
                                    }
                                });
                            } else {
                                vm.comprobante.listaPrecioVenta = undefined;
                                // vm.comprobante.monedaLista = undefined;
                                vm.porcRecargo = 0;
                                vm.precioBase = 'Precio de Venta'
                                vm.actualizarPreciosDeVentas();
                                vm.getListasPreciosVentasByMoneda();
                                //vm.comprobante.monedaLista = undefined;
                                vm.comprobante.cambioPactado = undefined;
                            }
                        });
                    });
                } else {
                    /* Si el cliente está asociado a una Lista de Precios de Ventas, pero no con la moneda
                    seleccionada. */
                    vm.comprobante.listaPrecioVenta = undefined;
                    // vm.comprobante.monedaLista = undefined;
                    vm.porcRecargo = 0;
                    vm.precioBase = 'Precio de Venta'
                    vm.actualizarPreciosDeVentas();
                    vm.getListasPreciosVentasByMoneda();
                    //vm.comprobante.monedaLista = undefined;
                    vm.comprobante.cambioPactado = undefined;
                }
            });
        } else {
            vm.precioBase = 'Precio de Venta';
            vm.porcRecargo = 0;
        }


        vm.monedaLocal.$promise.then(function (data) {
            if (
                vm.comprobante.moneda &&
                vm.comprobante.moneda.descripcion != data[0].valorTipo.descripcion
            ) {
                vm.cotizacionRequerida = true;

                var params = {};
                var fechaFormateada = moment(vm.comprobante.fecha).format('DD/MM/YYYY');
                params.search = filterFactory
                    .or([
                        filterFactory
                            .and([{
                                path: "monedaDesde.id",
                                equals: vm.comprobante.moneda.id
                            },
                            {
                                path: "monedaHasta.id",
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: "fecha",
                                like: fechaFormateada
                            }
                            ])
                            .value(),
                        filterFactory
                            .and([{
                                path: "monedaDesde.id",
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: "monedaHasta.id",
                                equals: vm.comprobante.moneda.id
                            },
                            {
                                path: "fecha",
                                like: fechaFormateada
                            }
                            ])
                            .value()
                    ])
                    .value();
                CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                    if (response.length > 0) {
                        //los comprobantes de venta deben utilizar el valor compra. QUAL-11969

                        if (vm.venta && vm.venta.pedidoVenta) {
                            vm.comprobante.cambio = vm.venta.pedidoVenta.cambio;
                        } else {
                            vm.comprobante.cambio = response[0].valorCompra;
                        }
                    } else {
                        if (
                            vm.comprobante.ordenCompra != null &&
                            vm.comprobante.ordenCompra != undefined
                        ) {
                            vm.comprobante.cambio = vm.ordenCompra.tipoCambio;

                        } else if (vm.venta && vm.venta.pedidoVenta) {
                            vm.comprobante.cambio = vm.venta.pedidoVenta.cambio;
                        } else {
                            vm.comprobante.cambio = null;
                        }
                    }
                });
            } else {
                vm.cotizacionRequerida = false;
                vm.comprobante.cambio = 1;
            }
        });
        if (vm.existeAnticipo) {
            //buscar si existen comprobantes de tipo anticipo para el cliente seleccionado
            var staticFilterComprobanteAnticipo = {};
            staticFilterComprobanteAnticipo.search = filterFactory
                .or([filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_emitido"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                filterFactory
                    .and([{
                        path: "cliente.id",
                        equals: vm.comprobante.cliente.id
                    }, {
                        path: "tipoTransaccion.codigo",
                        equals: "transaccion_anticipo"
                    }, {
                        path: "estado.codigo",
                        equals: "comp_venta_aprobado_sifen"
                    }, {
                        path: "saldo",
                        equals: 0
                    }, {
                        path: "moneda.id",
                        equals: vm.comprobante.moneda.id
                    }])
                    .value(),
                ])
                .value();

            ComprobanteVentaFactory.all(staticFilterComprobanteAnticipo, "ComprobanteVentaList").$promise.then(function (anticipos) {
                if (anticipos.length > 0) {
                    vm.comprobante.tipoAnticipo = vm.tipoAnticipos[0];
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobante.importeAnticipo = 0;
                    vm.comprobantesAnticipo = [];
                    var staticFilterComprobante = {
                        search: filterFactory
                            .and([{
                                path: "cliente.id",
                                equals: vm.comprobante.cliente.id
                            }, {
                                path: "estado.codigo",
                                notEquals: "comp_venta_anulado"
                            }])
                            .value()
                    };
                    ComprobanteVentaFactory.all(staticFilterComprobante, "ComprobanteVentaList").$promise.then(function (comprobantes) {
                        _.forEach(anticipos, function (anticipo) {
                            var comprobanteExiste = comprobantes.find(function (compro) {
                                if (compro.comprobanteAnticipo != null) {
                                    if (compro.comprobanteAnticipo.id === anticipo.id) {
                                        return true;
                                    }
                                }
                            });
                            if (!comprobanteExiste) {
                                vm.comprobantesAnticipo.push(anticipo);
                            }
                        })

                    });
                } else {
                    vm.comprobante.tipoAnticipo = vm.tipoAnticipos[0];
                    vm.comprobante.porcentajeAnticipo = 0;
                    vm.comprobante.importeAnticipo = 0;
                    vm.comprobantesAnticipo = [];
                    vm.comprobante.comprobanteAnticipo = null;
                }
            });
        }
    }

    function searchProductoFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                item.codigo == criteria ||
                (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0
            );
        };
    }

    function totalItemChanged(detalle, index) {

        if (detalle.cantidad == undefined) {
            detalle.cantidad = 1;
        }

        detalle.precioVenta = parseFloat((detalle.totalItem / detalle.cantidad).toFixed(3));
        //detalle.precioVenta = UtilFactory.roundForMoneda(detalle.precioVenta, vm.comprobante.moneda);
        controlPrecioUltimoCosto(detalle, index);

        if (detalle.montosImponibles) {
            if (detalle.montosImponibles[0].tipoImpuesto.porcentaje != 0)
                detalle.montosImponibles[0].baseImponible = Math.round(
                    100 *
                    detalle.totalItem /
                    (100 + detalle.montosImponibles[0].tipoImpuesto.porcentaje)
                );
            else
                detalle.montosImponibles[0].baseImponible = detalle.totalItem;
            detalle.montosImponibles[0].baseImponible = UtilFactory.roundForMoneda(
                detalle.montosImponibles[0].baseImponible,
                vm.comprobante.moneda);
            detalle.montosImponibles[0].valor =
                detalle.totalItem - detalle.montosImponibles[0].baseImponible;
            detalle.montosImponibles[0].valor = UtilFactory.roundForMoneda(detalle.montosImponibles[0].valor, vm.comprobante.moneda);
        }
        vm.totalGeneralProductos = vm.comprobantesProductos ? _.reduce(
            vm.comprobantesProductos,
            function (sum, detalle) {
                return sum + (detalle.totalItem != undefined ? detalle.totalItem : 0);
            },
            0
        ) : 0;

        vm.totalGeneralProductos = UtilFactory.roundForMoneda(vm.totalGeneralProductos, vm.comprobante.moneda);
        vm.totalGeneralServicios = vm.comprobantesServicios ? _.reduce(
            vm.comprobantesServicios,
            function (sum, detalle) {
                return sum + (detalle.totalItem !== undefined ? detalle.totalItem : 0);
            },
            0
        ) : 0;
        vm.totalGeneralServicios = UtilFactory.roundForMoneda(vm.totalGeneralServicios, vm.comprobante.moneda);

        vm.totalGeneral = vm.totalGeneralProductos + vm.totalGeneralServicios;
    }

    function cargarImpuestos() {
        var sumBase10 = 0;
        var sumBase5 = 0;
        var sumBaseExe = 0;
        var total10 = 0;
        var total5 = 0;
        var totalExe = 0;
        var impuesto10 = 0;
        var impuesto5 = 0;
        var impuestoExe = 0;
        var tipoImpuesto10;
        var tipoImpuesto5;
        var tipoImpuestoExe;

        for (var i = 0; i < vm.comprobantesProductos.length; i++) {
            var elem = vm.comprobantesProductos[i];
            if (elem.montosImponibles[0].porcentaje != undefined) {
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 10) {
                    sumBase10 += elem.montosImponibles[0].baseImponible;
                    total10 += elem.totalItem;
                    tipoImpuesto10 = elem.montosImponibles[0].tipoImpuesto;
                }
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 5) {
                    sumBase5 += elem.montosImponibles[0].baseImponible;
                    total5 += elem.totalItem;
                    tipoImpuesto5 = elem.montosImponibles[0].tipoImpuesto;
                }
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 0) {
                    sumBaseExe += elem.montosImponibles[0].baseImponible;
                    totalExe += elem.totalItem;
                    tipoImpuestoExe = elem.montosImponibles[0].tipoImpuesto;
                }
            }
        }
        impuesto10 = total10 - sumBase10;
        impuesto5 = total5 - sumBase5;
        impuestoExe = totalExe - sumBaseExe;

        for (var i = 0; i < vm.comprobantesServicios.length; i++) {
            var elem = vm.comprobantesServicios[i];
            if (elem.montosImponibles[0].porcentaje != undefined) {
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 10) {
                    sumBase10 += elem.montosImponibles[0].baseImponible;
                    total10 += elem.totalItem;
                    tipoImpuesto10 = elem.montosImponibles[0].tipoImpuesto;
                }
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 5) {
                    sumBase5 += elem.montosImponibles[0].baseImponible;
                    total5 += elem.totalItem;
                    tipoImpuesto5 = elem.montosImponibles[0].tipoImpuesto;
                }
                if (elem.montosImponibles[0].tipoImpuesto.porcentaje === 0) {
                    sumBaseExe += elem.montosImponibles[0].baseImponible;
                    totalExe += elem.totalItem;
                    tipoImpuestoExe = elem.montosImponibles[0].tipoImpuesto;
                }
            }
        }
        impuesto10 = total10 - sumBase10;
        impuesto5 = total5 - sumBase5;
        impuestoExe = totalExe - sumBaseExe;

        impuesto10 = parseFloat(impuesto10.toFixed(4));
        impuesto5 = parseFloat(impuesto5.toFixed(4));
        impuestoExe = parseFloat(impuestoExe.toFixed(4));

        sumBase10 = parseFloat(sumBase10.toFixed(4));
        sumBase5 = parseFloat(sumBase5.toFixed(4));
        sumBaseExe = parseFloat(sumBaseExe.toFixed(4));

        if (tipoImpuesto10 != undefined && tipoImpuesto5 != undefined && tipoImpuestoExe != undefined) {
            vm.comprobante.montosImponibles = [{
                tipoImpuesto: tipoImpuesto10,
                total: total10,
                baseImponible: sumBase10,
                valor: impuesto10
            },
            {
                tipoImpuesto: tipoImpuesto5,
                total: total5,
                baseImponible: sumBase5,
                valor: impuesto5
            },
            {
                tipoImpuesto: tipoImpuestoExe,
                total: totalExe,
                baseImponible: sumBaseExe,
                valor: impuestoExe
            }];
        } else

            if (tipoImpuesto10 != undefined && tipoImpuesto5 != undefined) {
                vm.comprobante.montosImponibles = [{
                    tipoImpuesto: tipoImpuesto10,
                    total: total10,
                    baseImponible: sumBase10,
                    valor: impuesto10
                },
                {
                    tipoImpuesto: tipoImpuesto5,
                    total: total5,
                    baseImponible: sumBase5,
                    valor: impuesto5
                }];
            } else

                if (tipoImpuesto5 != undefined && tipoImpuestoExe != undefined) {
                    vm.comprobante.montosImponibles = [
                        {
                            tipoImpuesto: tipoImpuesto5,
                            total: total5,
                            baseImponible: sumBase5,
                            valor: impuesto5
                        },
                        {
                            tipoImpuesto: tipoImpuestoExe,
                            total: totalExe,
                            baseImponible: sumBaseExe,
                            valor: impuestoExe
                        }];
                } else

                    if (tipoImpuesto10 != undefined && tipoImpuestoExe != undefined) {
                        vm.comprobante.montosImponibles = [{
                            tipoImpuesto: tipoImpuesto10,
                            total: total10,
                            baseImponible: sumBase10,
                            valor: impuesto10
                        },
                        {
                            tipoImpuesto: tipoImpuestoExe,
                            total: totalExe,
                            baseImponible: sumBaseExe,
                            valor: impuestoExe
                        }];
                    } else

                        if (tipoImpuesto10 != undefined) {
                            vm.comprobante.montosImponibles = [{
                                tipoImpuesto: tipoImpuesto10,
                                total: total10,
                                baseImponible: sumBase10,
                                valor: impuesto10
                            }];
                        } else

                            if (tipoImpuesto5 != undefined) {
                                vm.comprobante.montosImponibles = [
                                    {
                                        tipoImpuesto: tipoImpuesto5,
                                        total: total5,
                                        baseImponible: sumBase5,
                                        valor: impuesto5
                                    },
                                ];
                            } else

                                if (tipoImpuestoExe != undefined) {
                                    vm.comprobante.montosImponibles = [
                                        {
                                            tipoImpuesto: tipoImpuestoExe,
                                            total: totalExe,
                                            baseImponible: sumBaseExe,
                                            valor: impuestoExe
                                        }];
                                }


        vm.comprobante.montoTotal = total10 + total5 + totalExe;
    }

    function submit() {

        vm.submited = true;
        vm.submitedAgil = true;
        vm.comprobante.comprobanteDetalle = _.filter(
            vm.comprobante.comprobanteDetalle,
            function (elem) {
                return elem.producto || elem.servicio;
            }
        );


        /*
         Comprobacion de tener al menos un detalle en el comprobante.
         Se implemento de esta forma para evitar errores en la consola del explorador, no se puede consultar por el valor
         de 'vm.comprobantesProductos[0].producto' sin antes asegurarse de que 'vm.comprobantesProductos[0]' esta definido
         */
        var comprobarServicio = false;
        // Si no se tiene un detalle producto
        if (vm.comprobantesProductos[0] == undefined) {
            // Se debe comprobar si tiene un detalle servicio
            comprobarServicio = true;
        } else {
            // Aqui se tiene un detalle producto
            // Si no selecciono un producto, se debe verificar si tiene un detalle servicio
            if (vm.comprobantesProductos[0].producto == undefined) {
                comprobarServicio = true;
            }
        }

        //Comprobar si se tiene un detalle servicio
        if (comprobarServicio) {
            // Si servicios no tiene un detalle
            if (vm.comprobantesServicios[0] == undefined) {
                notify({
                    message: $filter("translate")("DETALLE_VACIO"),
                    classes: "alert-danger",
                    position: "right"
                });
                return;
            } else {
                // Aqui servicio tiene un detalle
                // Si no selecciono un servicio
                if (vm.comprobantesServicios[0].servicio == undefined) {
                    notify({
                        message: $filter("translate")("DETALLE_VACIO"),
                        classes: "alert-danger",
                        position: "right"
                    });
                    return;
                }
            }
        }
        if (vm.comprobante.remisiones && vm.comprobante.remisiones.length > 0) {
            if (vm.comprobantesProductos && vm.comprobantesProductos.length > 0) {
                var i = 0;
                _.forEach(vm.comprobantesProductos, function (elem) {
                    calcularTotalDetalle(elem, i);
                    i++;
                })
            }
        }

        if ($scope.ComprobanteForm.$valid) {


            $rootScope.isProcessing = true;
            cargarImpuestos();
            // if (
            //     vm.comprobante.grupoCompra &&
            //     vm.comprobante.grupoCompra.codigo === "comprobante_importacion"
            // ) {
            //     delete vm.comprobante.moneda;
            // }
            if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.cambio != 1) {
                var msg = "La moneda del comprobante es Gs. El tipo de cambio no puede ser diferente de 1.";
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                vm.submited = false;
                vm.submitedAgil = false;
                $rootScope.isProcessing = false;
                vm.cotizacionRequerida = true;
                return;
            }
            //Evaluacion de Periodo Administrativo

            var params = {
                search: filterFactory.single({
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                }).value()
            };

            vm.flagPeriod = false;
            vm.comprobante.fecha.setHours(0, 0, 0, 0);
            PeriodoAdministrativoFactory.all(params).$promise.then(function (response) {
                var periodos = response;
                _.forEach(periodos, function (value) {
                    var desde = new Date(value.fechaDesde);
                    var hasta = new Date(value.fechaHasta);

                    if (vm.comprobante.fecha >= desde && vm.comprobante.fecha <= hasta) {
                        vm.flagPeriod = true;
                    }
                })

                if (!vm.comprobante.cliente.activo) {
                    var msg = "El cliente no se encuentra en estado Activo.";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.submited = false;
                    vm.submitedAgil = false;
                    $rootScope.isProcessing = false;
                    return;
                }

                if (vm.comprobante.cliente.bloquearVentas) {
                    var msg = "El cliente se encuentra bloqueado para los procesos de ventas.";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    vm.submited = false;
                    vm.submitedAgil = false;
                    $rootScope.isProcessing = false;
                    return;
                }
                if (!vm.flagPeriod) {
                    $rootScope.isProcessing = false;
                    notify({
                        message: $filter("translate")("NO_ADMINISTRATIVE_PERIOD"),
                        classes: "alert-danger",
                        position: "right"
                    });
                } else {

                    copiarComprobantes();
                    vm.comprobante.comprobanteDetalle = _.filter(
                        vm.comprobante.comprobanteDetalle,
                        function (elem) {
                            return elem.producto || elem.servicio;
                        }
                    );
                    // se quita el atributo grupo de los detalles cuyos productos son de tipo normal
                    vm.comprobante.comprobanteDetalle = _.forEach(
                        vm.comprobante.comprobanteDetalle,
                        function (elem) {
                            if (
                                elem.producto != undefined &&
                                elem.producto.criterioControl.codigo !== "series" &&
                                elem.producto.criterioControl.codigo !== "lotes"
                            ) {
                                delete elem.grupo;
                            }
                        }
                    );

                    var errorSerieRepetida = false;
                    if (vm.comprobanteGeneraMovimiento) {

                        for (var i = 0; i < vm.comprobantesProductos.length; i++) {
                            var elem = vm.comprobantesProductos[i];
                            var cantRegistros = 0;
                            if (elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') {
                                _.forEach(vm.comprobantesProductos, function (elem2) {
                                    if ((elem2.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') && elem.producto && elem2.producto && elem.producto.id == elem2.producto.id && elem.grupoProducto && elem2.grupoProducto && elem.grupoProducto.id == elem2.grupoProducto.id) {
                                        cantRegistros++;
                                    }
                                });
                            }
                            if (cantRegistros > 1) {
                                var msg = $filter('translate')('NUMERO_GRUPO_REPETIDO') + " (Producto: " + elem.producto.nombre + ")";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                errorSerieRepetida = true;
                                break;
                            }
                        };

                    }

                    if (errorSerieRepetida) {
                        $rootScope.isProcessing = false;
                        $scope.isProcessing = false;
                        return;
                    }

                    var checkImpuestos = {};
                    var tieneImpuestoRepetido = false;
                    for (var i = 0; i < vm.comprobante.montosImponibles.length; i++) {
                        if (
                            checkImpuestos[
                            vm.comprobante.montosImponibles[i].tipoImpuesto.porcentaje
                            ] === undefined
                        ) {
                            checkImpuestos[
                                vm.comprobante.montosImponibles[i].tipoImpuesto.porcentaje
                            ] = true;
                        } else {
                            tieneImpuestoRepetido = true;
                        }
                    }
                    if (tieneImpuestoRepetido) {
                        $rootScope.isProcessing = false;
                        var msg = $scope.translations.IMPUESTO_CABECERA_REPETIDO;
                        notify({ message: msg, classes: "alert-danger", position: "right" });
                    } else {
                        /* Se quita la validacion de montos mientras, se volvera a agregar en proxima version */
                        var validationMontos = ComprobanteVentaFactory.validateMontos(
                            vm.comprobante,
                            vm.monedaLocal[0],
                            vm.diferenciaMonedaLocal[0],
                            vm.diferenciaMonedaExtranjera[0]
                        );
                        if (validationMontos[0]) {
                            vm.comprobante = ComprobanteVentaFactory.igualarTipoImpuestoCabeceraADetealle(
                                vm.comprobante,
                                vm.monedaLocal[0]
                            );
                            for (var montoImponible in vm.comprobante.montosImponibles) {
                                vm.totalImpuestoChanged(
                                    vm.comprobante.montosImponibles[montoImponible]
                                );
                            }
                            if (vm.formSuscripcionRecurrente && vm.comprobante.respetarDias && $scope.dias.diasSemana.length === 0) {
                                var msg = "Debe seleccionar al menos un dia de la semana";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                vm.submited = false;
                                vm.submitedAgil = false;
                                $rootScope.isProcessing = false;
                                return;
                            }

                            sendSubmitComprobante();

                        } else {
                            var code = validationMontos[1];
                            if (code == "montos_mismatch") {
                                $rootScope.isProcessing = false;
                                notify({
                                    message: $scope.translations
                                        .MONTOS_TOTALES_SUM_DETALLES_WARNING_COMPRA_LOCAL,
                                    classes: "alert-danger",
                                    position: "right"
                                });
                            } else if (code == "impuestos_mismatch") {
                                $rootScope.isProcessing = false;

                                notify({
                                    message: $scope.translations
                                        .IMPUESTOS_TOTALES_SUM_DETALLES_WARNING_COMPRA_LOCAL,
                                    classes: "alert-danger",
                                    position: "right"
                                });
                            }
                        }
                    }
                }
            });
        } else {
            vm.submitedAgil = false;
        }
    }

    function sendSubmitComprobante() {
        return submitComprobante().then(
            function (response) {
                $state.go("app.comprobantesventapendientesnominacion.list");

                return response;
            },
            function (error) {
                $rootScope.isProcessing = false;
                vm.submitedAgil = false;
                errorHandler(error);
            }
        );
    }

    function copiarComprobantes() {
        vm.comprobante.comprobanteDetalle = [];
        _.forEach(vm.comprobantesProductos, function (elem) {
            if (!elem.totalItem) {
                return;
            }
            vm.comprobante.comprobanteDetalle.push(elem);
        });
        _.forEach(vm.comprobantesServicios, function (elem) {
            if (elem.servicio) {
                if (!elem.totalItem) {
                    if (detalle.producto.criterioControl) {
                        if (detalle.producto.criterioControl.codigo === "lotes" || detalle.producto.criterioControl.codigo === "series") {
                            detalle.grupoProducto = { producto: detalle.producto.id };
                        }
                    }
                    return;
                }
                vm.comprobante.comprobanteDetalle.push(elem);
            }
        });
    }

    function cancel() {
        $state.go("app.comprobantesventapendientesnominacion.list");
    }

    function removeItemFromArray(elemento, arreglo, index) {
        // Solo modifica la lista de PreciosMaximoMinimo si es un producto
        if (elemento.servicio == null) {
            for (var i = index; i < vm.precioMinimoMaximos.length - 1; i++) {
                vm.precioMinimoMaximos[i] = vm.precioMinimoMaximos[i + 1];
            }
            vm.precioMinimoMaximos.pop();
        }
        _.remove(arreglo, function (item) {
            return item === elemento;
        });

        updateTotalImpuestos();
        // Inicializar vm.totalGeneralProductos con 0 si vm.comprobantesProductos no está definido
        vm.totalGeneralProductos = vm.comprobantesProductos ? _.reduce(
            vm.comprobantesProductos,
            function (sum, detalle) {
                return sum + (detalle.totalItem != undefined ? detalle.totalItem : 0);
            },
            0
        ) : 0;

        vm.totalGeneralServicios = vm.comprobantesServicios ? _.reduce(
            vm.comprobantesServicios,
            function (sum, detalle) {
                return sum + (detalle.totalItem !== undefined ? detalle.totalItem : 0);
            },
            0
        ) : 0;

        vm.totalGeneralProductos = UtilFactory.roundForMoneda(vm.totalGeneralProductos, vm.comprobante.moneda);
        vm.totalGeneralServicios = UtilFactory.roundForMoneda(vm.totalGeneralServicios, vm.comprobante.moneda);
        //recalcular porcentaje anticipo e importes 
        if (vm.existeAnticipo && vm.comprobante.comprobanteAnticipo) {
            //sumar cantidad*precio tanto de productos como se servicios si existe
            var totalBruto = 0;
            var totalBrutoProducto = 0;
            var totalBrutoServicio = 0;
            if (vm.comprobantesProductos.length > 0) {
                _.forEach(vm.comprobantesProductos, function (det) {
                    totalBrutoProducto += det.cantidad * det.precioVenta;
                });
            }
            if (vm.comprobantesServicios.length > 0) {
                _.forEach(vm.comprobantesServicios, function (det) {
                    totalBrutoServicio += det.cantidad * det.precioVenta;
                });
            }
            totalBruto = totalBrutoProducto + totalBrutoServicio;
            vm.comprobante.porcentajeAnticipo = (vm.comprobante.importeAnticipo / totalBruto) * 100;
            vm.comprobante.porcentajeAnticipo = parseFloat(vm.comprobante.porcentajeAnticipo.toFixed(8));
            if (vm.comprobantesProductos.length > 0) {
                var sumCompProducto = 0;
                _.forEach(vm.comprobantesProductos, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta) / 100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo * elem.cantidad;
                    elem.totalItem = (elem.precioVenta - elem.importeAnticipo) * elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompProducto += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);

                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }

                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralProductos = UtilFactory.roundForMoneda(sumCompProducto, vm.comprobante.moneda);
            }
            if (vm.comprobantesServicios.length > 0) {
                var sumCompServicio = 0;

                _.forEach(vm.comprobantesServicios, function (elem) {
                    elem.importeAnticipo = (vm.comprobante.porcentajeAnticipo * elem.precioVenta) / 100;
                    elem.importeAnticipo = parseFloat(elem.importeAnticipo.toFixed(5));
                    elem.totalAnticipo = elem.importeAnticipo * elem.cantidad;
                    elem.totalItem = (elem.precioVenta - elem.importeAnticipo) * elem.cantidad;
                    elem.cdc = vm.comprobante.comprobanteAnticipo.cdc != null ? vm.comprobante.comprobanteAnticipo.cdc : "";
                    sumCompServicio += elem.totalItem;
                    elem.totalAnticipo = UtilFactory.roundForMoneda(elem.totalAnticipo, vm.comprobante.moneda);
                    elem.totalItem = UtilFactory.roundForMoneda(elem.totalItem, vm.comprobante.moneda);

                    if (elem.montosImponibles) {
                        if (!vm.comprobante.listaPrecioVenta) {
                            if (elem.montosImponibles[0].tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.montosImponibles[0].tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        } else {
                            if (elem.servicio.tipoImpuesto.porcentaje != 0) {
                                elem.montosImponibles[0].baseImponible =
                                    100 *
                                    elem.totalItem /
                                    (100 + elem.servicio.tipoImpuesto.porcentaje);
                            } else {
                                elem.montosImponibles[0].baseImponible = elem.totalItem;
                            }
                        }

                        elem.montosImponibles[0].baseImponible = parseFloat(elem.montosImponibles[0].baseImponible.toFixed(4));
                        elem.montosImponibles[0].valor = parseFloat((elem.totalItem - elem.montosImponibles[0].baseImponible).toFixed(4));
                    }
                });
                vm.totalGeneralServicios = UtilFactory.roundForMoneda(sumCompServicio, vm.comprobante.moneda);
            }
        }
        vm.totalGeneral = vm.totalGeneralProductos + vm.totalGeneralServicios;
    }

    function submitComprobante() {
        delete vm.comprobante.numeroTimbrado;

        if (vm.formSuscripcionRecurrente) {
            //vm.generarFechasPlantilla();
            vm.comprobante.suscripcionRecurrente = vm.suscripcionRecurrente;
            vm.comprobante.diasDeLaSemana = JSON.stringify($scope.dias.diasSemana);
            vm.comprobante.fechaInicial = moment(vm.comprobante.fechaInicial).format('DD/MM/YYYY');
            vm.comprobante.fechaFinal = moment(vm.comprobante.fechaFinal).format('DD/MM/YYYY');
            // vm.comprobante.primeraFacturacion = moment(vm.comprobante.primeraFacturacion).format('DD/MM/YYYY');

        }

        vm.comprobante.clase = vm.comprobante.tipo.clase;

        var trimmedModel = ModelTrimmer.trimDetails(vm.comprobante, [
            "comprobanteDetalle",
            "montosImponibles",
            "movimientoStock"
        ]);
        var trimmedDetails = _.map(vm.comprobante.comprobanteDetalle, function (
            detalle
        ) {
            var result = ModelTrimmer.trimDetails(detalle, [
                "montosImponibles",
                "grupoProducto"
            ]);
            var montosImponiblesTrimmed = _.map(result.montosImponibles, function (
                monto
            ) {
                return ModelTrimmer.trimDetails(monto);
            });
            result.montosImponibles = montosImponiblesTrimmed;
            return result;
        });

        trimmedModel.comprobanteDetalle = trimmedDetails;
        var trimmedMontos = _.map(vm.comprobante.montosImponibles, function (monto) {
            return ModelTrimmer.trimDetails(monto);
        });
        trimmedModel.montosImponibles = trimmedMontos;

        var idsDetails = getIDs();
        trimmedModel.movimientoStock = _.map(idsDetails, function (id) {
            var idVal = parseInt(id);

            if (!idVal) {
                return;
            }
            return idVal;
        });

        if (!vm.isEdit) {
            trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
        }
        trimmedModel.sucursal = $rootScope.AuthParams.locacionSeleccionada.sucursal.id;
        var resource = ComprobanteVentaFactory.create(trimmedModel);

        var montoInicial = vm.montoInicial ? vm.montoInicial : 0;
        if (vm.hasPermisoVendedorView) {
            delete resource.fecha;
        }

        return ComprobanteVentaFactory.saveWithPlanCobros(
            resource,
            vm.cantidadCuotas,
            vm.plazoCuotas,
            montoInicial,
            vm.isFacturacionAgil,
            vm.hasPermisoVendedorView
        );

    }

    function errorHandler(e) {
        var msg = "";
        _.forEach(e.data, function (error) {
            if (error.constraint == "id_producto, numero") {
                msg +=
                    "\n\n" + $scope.translations.NUMERO_GRUPO_REPETIDO_PRODUCTO + ".\n";
            } else if (
                error.constraint ==
                "numero, id_proveedor, id_numero_timbrado, id_tipo_comprobante"
            ) {
                msg +=
                    "\n\n" +
                    $scope.translations.NROCOMP_PROVEEDOR_TIMBRADO_TIPO_REPETIDO +
                    ".\n";
            } else if (
                error.constraint ==
                "numero, id_timbrado_punto_emision, id_clase"
            ) {
                msg +=
                    "\n\n" +
                    $filter("translate")("NUMERO_TIMBRADO_TIPO_COMP_REPETIDO") +
                    ".\n";
            } else if (error.message == "Not unique field: id_tipo_clase, numero, id_timbrado_punto_emision") {
                msg += '\n\n' + "Ya existe un comprobante con el mismo numero, timbrado y clase\n"
            } else if (error.message == "Existe un proceso de confirmación del comprobante. No se puede editar.") {
                msg += "\n\n" + error.message;
                $state.go("app.ventas.proceso.comprobanteventa.list");
            } else if (error.constraint == "id_comprobante_anticipo") {
                msg +=
                    "\n\n" + 'Ya existe otro comprobante que tiene relacionado el anticipo seleccionado' + ".\n";
            } else {
                msg += "\n\n" + error.message + ".";
            }
        });

        notify({ message: msg, classes: "alert-danger", position: "right" });
    }


    function cancel() {
        if (vm.fromOutside) {
            $state.go("app.comprobantesventapendientesnominacion.list");
        } else if (vm.isProcesoVenta) {
            $state.go("app.ventas.proceso.comprobanteventa.list");
        } else if (vm.formSuscripcionRecurrente) {
            $state.go("app.suscripcionrecurrente.proceso.comprobanteventa.list");
        } else if (vm.isFacturacionAgil) {
            $state.go("app.main");
        }
    }

    function totalImpuestoChanged(montoImponible) {
        checkIfImpuestoSet(montoImponible);
        updateTotalImpuestos();
    }

    function tipoImpuestoChanged(montoImponible) {
        checkIfImpuestoSet(montoImponible);
        updateTotalImpuestos();
    }

    function checkIfImpuestoSet(montoImponible) {
        if (!montoImponible.tipoImpuesto) {
            delete montoImponible.valor;
            delete montoImponible.total;
            delete montoImponible.baseImponible;
            return false;
        }

        var porc = montoImponible.tipoImpuesto.porcentaje;
        if (porc != 0) {
            montoImponible.baseImponible =
                100 *
                montoImponible.total /
                (100 + montoImponible.tipoImpuesto.porcentaje);
        } else {
            montoImponible.baseImponible = montoImponible.total;
        }

        if (montoImponible.baseImponible != undefined) {
            montoImponible.baseImponible = parseFloat(montoImponible.baseImponible.toFixed(4));
            montoImponible.valor = parseFloat((montoImponible.total - montoImponible.baseImponible).toFixed(4));
        }
        return true;
    }

    function updateTotalImpuestos() {
        vm.totalImpuestosGeneral = _.reduce(
            vm.comprobante.montosImponibles,
            function (sum, monto) {
                return sum + monto.total;
            },
            0
        );

        vm.totalImpuestosGeneralMontoImponible = _.reduce(
            vm.comprobante.montosImponibles,
            function (sum, monto) {
                return sum + monto.valor;
            },
            0
        );
        vm.comprobante.totalImpuestos = vm.totalImpuestosGeneralMontoImponible;

        vm.totalImpuestosGeneral = UtilFactory.roundForMoneda(vm.totalImpuestosGeneral, vm.comprobante.moneda);
        vm.comprobante.montoTotal = vm.totalImpuestosGeneral;
    }

    function requireDeposito() {
        if (
            vm.fromOutside &&
            vm.comprobante &&
            vm.comprobante.tipo &&
            vm.comprobante.tipo.clase.codigo == "nota_credito"
        ) {
            return true;
        } else if (vm.fromOutside) {
            return false;
        }
        if (vm.comprobante && vm.comprobante.grupoCompra) {
            if (
                vm.comprobante.grupoCompra.permiteServicio === true &&
                vm.comprobante.grupoCompra.permiteProducto === false
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    function timbradoSelect() {
        // vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
        //   vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta = moment(vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY")
        var timbradoAux = null;
        if (vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {

            timbradoAux = _.find(vm.tipoTransacciones, function (tipo) {
                if ('venta_mercaderia' === tipo.codigo) {
                    return tipo;
                }
            });
            if ((vm.comprobante.tipoTransaccion == undefined || vm.comprobante.tipoTransaccion == null) && timbradoAux != null) {
                vm.comprobante.tipoTransaccion = timbradoAux;
            }
        } else {
            vm.comprobante.tipoTransaccion = null;
        }
        if (vm.datoVirtual && !vm.fromOutside && vm.comprobante.cliente &&
            vm.comprobante.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") verificarTipoOperacion(vm.comprobante.cliente);
        vm.comprobante.motivoEmision = null;
        if (vm.comprobante.numero != undefined && vm.comprobante.numero != null && vm.comprobante.numero != "") {
            validarNroFactura();
        }
    }

    function validarNroFactura() {
        if (vm.comprobante.numero != undefined && vm.comprobante.numero != null && vm.comprobante.numero != "") {
            vm.comprobante = ComprobanteVentaFactory.validateNroFactura($scope.ComprobanteForm, vm.comprobante, vm.monedaLocal[0], vm.formatoComprobante);
        }
    }

    function getStockPorTipoComprobante(detalle) {
        var filterGrupo = [];
        var filterNormal = [];
        vm.almacenesConStock = [];

        _.forEach(detalle.producto.gruposProducto, function (grupo) {
            var filter = [{
                path: "grupoProducto.id",
                equals: grupo.id
            },
            {
                path: 'almacen.id',
                equals: vm.comprobante.deposito.id
            }];
            filterGrupo.push(filterFactory.and(filter).value());
        });

        var filterFinal = {
            search: filterFactory.or(filterGrupo).value()
        }
        GrupoProductoAlmacenFactory.all(filterFinal, 'BaseList').$promise.then(function (response) {
            var grupoConStock = [];
            if (response.length > 0) {
                _.forEach(response, function (elem) {
                    var existente = _.find(vm.almacenesConStock, function (stock) {
                        if (stock.grupoProducto != undefined && elem.grupoProducto.id == stock.grupoProducto.id) {
                            return stock;
                        }
                    });
                    if (existente != undefined && existente != null) {
                        if (elem.dia > existente.dia) {
                            existente.dia = elem.dia;
                            existente.saldoReal = elem.saldoReal;
                        }
                    } else {
                        vm.almacenesConStock.push(elem);
                    }
                });

                _.forEach(detalle.producto.gruposProducto, function (grupo) {
                    var existe = false;
                    _.forEach(vm.almacenesConStock, function (stock) {
                        if (grupo.id == stock.grupoProducto.id) {
                            if (vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo === "nota_credito") {
                                existe = true;
                            } else if (vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo != "nota_credito" && stock.saldoReal > 0) {
                                existe = true;
                            }
                        }
                    });
                    if (existe) {
                        grupoConStock.push(grupo);
                    }
                });

            }
            detalle.producto.gruposProducto = grupoConStock;
            if (grupoConStock.length == 0) {
                if (vm.comprobante.tipo && vm.comprobante.tipo.clase.codigo === "nota_credito") {
                    var msg = $filter('translate')('NO_EXISTE_GRUPO_SIN_STOCK') + " (Producto: " + detalle.producto.nombre + ")";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                } else {
                    var msg = $filter('translate')('NO_EXISTE_GRUPO_CON_STOCK') + " (Producto: " + detalle.producto.nombre + ")";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }
            }
        });
    }

    function verificarTipoOperacion(cliente) {
        vm.comprobante.tipoOperaciones = null;
        if (cliente.tipoOperacion != null) {
            vm.comprobante.tipoOperacion = _.find(vm.tipoOperaciones, function (t) {
                return t.codigo === cliente.tipoOperacion.codigo;
            });
        } else {
            var msg = $filter('translate')('CLIENTE_NO_TIPO_OPERACION');
            notify({ message: msg, classes: 'alert-warning', position: 'right' });
        }
    }

    function changeAlmacen() {
        if (vm.comprobanteGeneraMovimiento) {
            _.forEach(vm.comprobantesProductos, function (elem) {
                if ((elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') && !estaEnPV(elem)) {
                    ProductosFactory.get(elem.producto.id, 'BaseList').$promise.then(function (producto) {
                        elem.producto = producto;
                        elem.grupoProducto = null;
                        getStockPorTipoComprobante(elem);
                    });
                }
            });
        }
    }

    function changeTipoAplicacion() {
        vm.tiposAplicaciones = {};
        TiposFactory.condicionTipoAplicacion().then(function (data) {
            vm.tiposAplicaciones = data.data;
        });
    }

    function getProductoStockAlmacen(detalle) {
        vm.almacenesConStock = [];
        var filterGrupo = [];
        var filterNormal = [];
        if (vm.comprobantesProductos.length >= 1 && vm.comprobantesProductos[0].producto != undefined && vm.comprobante.deposito != undefined && vm.comprobante.deposito.id != undefined && vm.comprobanteGeneraMovimiento) {

            _.forEach(vm.comprobantesProductos, function (elem) {
                if (elem.producto.criterioControl.codigo == 'series' || elem.producto.criterioControl.codigo == 'lotes') {
                    _.forEach(elem.producto.gruposProducto, function (grupo) {
                        var filter = [{
                            path: "grupoProducto.id",
                            equals: grupo.id
                        },
                        {
                            path: 'almacen.id',
                            equals: vm.comprobante.deposito.id
                        }];
                        filterGrupo.push(filterFactory.and(filter).value());
                    });
                } else {
                    var filter = [{
                        path: "producto.id",
                        equals: elem.producto.id
                    },
                    {
                        path: 'almacen.id',
                        equals: vm.comprobante.deposito.id
                    }];
                    filterNormal.push(filterFactory.and(filter).value());
                }
            });

            if (filterGrupo.length > 0) {
                var filterFinal = {
                    search: filterFactory.or(filterGrupo).value()
                }
                GrupoProductoAlmacenFactory.all(filterFinal, 'BaseList').$promise.then(function (response) {
                    if (response.length > 0) {
                        _.forEach(response, function (elem) {
                            var existente = _.find(vm.almacenesConStock, function (stock) {
                                if (stock.grupoProducto != undefined && elem.grupoProducto.id == stock.grupoProducto.id) {
                                    return stock;
                                }
                            });
                            if (existente != undefined && existente != null) {
                                if (elem.dia > existente.dia) {
                                    existente.dia = elem.dia;
                                    existente.saldoReal = elem.saldoReal;
                                }
                            } else {
                                vm.almacenesConStock.push(elem);
                            }
                        });
                        if (detalle != undefined && (detalle.producto.criterioControl.codigo == 'series' || detalle.producto.criterioControl.codigo == 'lotes')) {
                            var grupoConStock = [];
                            _.forEach(detalle.producto.gruposProducto, function (grupo) {
                                var existe = false;
                                _.forEach(vm.almacenesConStock, function (stock) {
                                    if (grupo.id == stock.grupoProducto.id && stock.saldoReal > 0) {
                                        existe = true;
                                    }
                                });
                                if (existe) {
                                    grupoConStock.push(grupo);
                                }
                            });
                            detalle.producto.gruposProducto = grupoConStock;
                            if (grupoConStock.length == 0) {
                                var msg = $filter('translate')('NO_EXISTE_GRUPO_CON_STOCK') + " (Producto: " + detalle.producto.nombre + ")";
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    }
                });
            }
            if (filterNormal.length > 0) {
                var filterFinal = {
                    search: filterFactory.or(filterNormal).value()
                }
                ProductoAlmacenFactory.all(filterFinal, 'BaseList').$promise.then(function (response) {
                    if (response.length > 0) {
                        _.forEach(response, function (elem) {
                            vm.almacenesConStock.push(elem);
                        });
                    }
                });
            }

        }
    }

    function delayRequestProducto(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {

            var filterNombre = vm.opcionesProductosDt.staticFilter.search.filters.slice()
            var filterCodigoBarras = vm.opcionesProductosDt.staticFilter.search.filters.slice()
            var filterCodigoInterno = vm.opcionesProductosDt.staticFilter.search.filters.slice()

            filterNombre.push({
                path: "nombre",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                })

            filterCodigoBarras.push({
                path: "codigoBarrasPrincipal.codigoBarras",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                })

            filterCodigoInterno.push({
                path: "codigo",
                like: searchValue
            },
                {
                    path: 'estado.codigo',
                    equals: 'activo'
                })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()
                ]).value()
            }

            recuperarProducto(userInputFilter)
        } else {
            vm.productos = undefined
        }
    }

    function recuperarProducto(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)

        vm.productos = ProductosFactory.allForSelect(finalFilter)
    }

    function plusClicked(detalle) {
        detalle.mostrarMas = !detalle.mostrarMas;
    }

    function getListasPreciosVentasByMoneda() {
        if (vm.comprobante.moneda) {
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'estado.codigo',
                equals: 'lista_activada'
            }]).value();
            ListaPrecioVentaFactory.all(staticFilter, "ComprobanteVentaForm").$promise.then(function (response) {
                if (response.length > 0) {
                    //ver que moneda es el comprobante
                    if (vm.comprobante.moneda.codigo == 'guaranies') {
                        //usa precio en Gs, Lista de precios en Gs y moneda extranjera
                        vm.listasPreciosVentas = response;
                    } else {
                        //si es moneda extranjera usa precio en Gs, Lista de precios en Gs y de la misma moneda extranjera de la operacion
                        var listaMoneda = [];
                        _.forEach(response, function (elem) {
                            if (elem.moneda.codigo == 'guaranies' || elem.moneda.codigo == vm.comprobante.moneda.codigo) {
                                listaMoneda.push(elem);

                            }
                        });
                        vm.listasPreciosVentas = listaMoneda;
                    }
                } else {
                    vm.listasPreciosVentas = undefined;
                }
            });
        }
    }

    function changeListaPrecioVenta() {
        vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;

        if (vm.permitirProductosNotListaPrecio == 'No') {
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'listaPrecioVenta.id',
                equals: vm.comprobante.listaPrecioVenta.id,
            }]).value();
            staticFilter.view = "ComprobanteVentaForm";
            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                var indexChangeListaPrecio = 0;
                _.forEach(vm.comprobantesProductos, function (detalle) {
                    if (detalle.producto) {
                        vm.productoExisteInLP = _.find(response, function (detalleLP) { return detalle.producto.id === detalleLP.producto.id });
                        if (vm.productoExisteInLP == undefined) {
                            removeItemFromArray(detalle, vm.comprobantesProductos, indexChangeListaPrecio);
                        }
                        indexChangeListaPrecio = indexChangeListaPrecio + 1;
                    }
                })
            });
        }

        if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
            //si el comprobante es en gs y la lista en moneda extranjera
            vm.mostrarCambioPactado = true;
            vm.monedaLocal.$promise.then(function (data) {
                if (vm.comprobante.monedaLista && vm.comprobante.monedaLista.descripcion != data[0].valorTipo.descripcion) {
                    vm.cotizacionRequerida = true;

                    var params = {};
                    params.search = filterFactory.and([{
                        path: 'monedaDesde.id',
                        equals: vm.comprobante.monedaLista.id
                    },
                    {
                        path: 'monedaHasta.id',
                        equals: vm.monedaLocal[0].valorTipo.id
                    },
                    {
                        path: 'fecha',
                        like: moment(vm.comprobante.fecha).format('DD/MM/YYYY')
                        //equals:vm.comprobante.fecha
                    }
                    ]).value();
                    params.view = 'CotizacionMonedaList';
                    CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                        if (response && response[0]) {
                            vm.comprobante.cambioPactado = response[0].valorCompra;
                        } else {
                            vm.comprobante.cambioPactado = null;
                        }
                    });
                } else {
                    vm.cotizacionRequerida = false;
                    vm.comprobante.cambioPactado = 1;
                }
            });
        } else {
            vm.mostrarCambioPactado = false;
        }

        if (vm.comprobante.listaPrecioVenta && vm.utilizarPrecioBase) {
            if (vm.comprobante.listaPrecioVenta) {
                vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                if (vm.comprobante.listaPrecioVenta.precioBase) {
                    vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                }
            }
            vm.actualizarPreciosDeVentas();
        } else {
            vm.actualizarPreciosDeVentas();
        }
        copiarComprobantes();
        recuperarPreciosMinimosMaximos();
    }

    function actualizarPreciosDeVentas() {
        if (vm.comprobantesProductos.length > 0 && vm.comprobantesProductos[0].producto) {
            var indexAux = 0;
            _.forEach(vm.comprobantesProductos, function (elem) {
                vm.detalleEliminado = false;
                if (vm.permitirProductosNotListaPrecio == 'No' && vm.comprobante.listaPrecioVenta) {

                    var staticFilter = {};
                    staticFilter.search = filterFactory.and([{
                        path: 'listaPrecioVenta.id',
                        equals: vm.comprobante.listaPrecioVenta.id,
                    },
                    {
                        path: 'producto.id',
                        equals: elem.producto.id,
                    }]).value();
                    staticFilter.view = "PedidoVentaForm";
                    ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                        if (vm.permitirProductosNotListaPrecio == 'No' && vm.comprobante.listaPrecioVenta) {
                            if (response == undefined || response.length == 0) {
                                removeItemFromArray(elem, vm.comprobantesProductos, indexAux);
                                vm.detalleEliminado = true;
                            }
                        }
                        if (elem.precioVenta != undefined && !vm.detalleEliminado) {
                            changeUnidad(elem, null, response);
                        }
                    });
                } else {
                    if (elem.precioVenta != undefined) {
                        changeUnidad(elem, indexAux);
                    }
                };
                indexAux = indexAux + 1;
            });
        }
    }

    function changeTipoCambio() {
        vm.actualizarPreciosDeVentas();
    };

    function changePrecioCambioPactado() {
        actualizarPreciosDeVentas();
    }

    function volverSolicitud() {
        //traer la solicitud que corresponde al comprobante
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'numeroProcesoOrigen',
            equals: vm.comprobante.id + "",
        }]).value();
        SolicitudAutorizacionFactory.all(staticFilter).$promise.then(function (response) {
            var idSolicitud = response[0].id;
            $state.go("app.solicitudautorizacion.edit", { id: idSolicitud });
        });
    }

    function delayRequestCliente(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
            var filterNombre = [];
            var filterNombreFantasia = [];
            var filterCodigo = [];
            var filterNumero = [];
            var filterDireccion = [];
            var filterCiudad = [];

            filterNombre.push({
                path: "nombre",
                like: searchValue
            })
            filterNombre.push({
                path: "activo",
                equals: true
            })
            filterNombreFantasia.push({
                path: "nombreFantasia",
                like: searchValue
            })
            filterNombreFantasia.push({
                path: "activo",
                equals: true
            })
            filterCodigo.push({
                path: "codigo",
                like: searchValue
            })
            filterCodigo.push({
                path: "activo",
                equals: true
            })
            filterNumero.push({
                path: "numero",
                like: searchValue
            })
            filterNumero.push({
                path: "activo",
                equals: true
            })
            filterDireccion.push({
                path: "direccion",
                like: searchValue
            })
            filterDireccion.push({
                path: "activo",
                equals: true
            })
            filterCiudad.push({
                path: "ciudad.descripcion",
                like: searchValue
            })
            filterCiudad.push({
                path: "activo",
                equals: true
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterNombreFantasia).value(),
                    filterFactory.and(filterCodigo).value(),
                    filterFactory.and(filterNumero).value(),
                    filterFactory.and(filterDireccion).value(),
                    filterFactory.and(filterCiudad).value()
                ]).value()
            }

            recuperarCliente(userInputFilter)
        } else {
            vm.clientes = undefined
        }
    }

    function buscarComprobantes() {

        // // TODO: ver qué otras precondiciones controlar
        // if (vm.activoFijo.fechaDesde && vm.activoFijo.fechaDesde.getTime() >
        //   vm.activoFijo.fechaHasta && vm.activoFijo.fechaHasta.getTime()) {
        //   notify({ message: $filter('translate')('DATE_FROM_DATE_TO_ERROR'), classes: 'alert-danger', position: 'right' });
        //   return;
        // }
        //
        // var filtersArray = [{
        //   path: 'estado.codigo',
        //   equals: 'comp_aprobado'
        // }];

        // Filtro para excluir comprobantes ya seleccionados
        if (vm.opcionesRelacionarFacturas.staticFilter != undefined) {
            var filtrosRemover = [];
            _.forEach(vm.opcionesRelacionarFacturas.staticFilter.search.filters, function (filtro) {
                if (filtro.path === "id") {
                    filtrosRemover.push(filtro);
                }
            });
            _.forEach(filtrosRemover, function (filtro) {
                _.remove(vm.opcionesRelacionarFacturas.staticFilter.search.filters, function (filtro2) {
                    return filtro2 === filtro;
                });
            });

            _.forEach(vm.comprobante.relacionarFacturas, function (detalle) {
                var newFilter = { path: "id", notEquals: detalle.id };
                vm.staticFilterComprobantes.push(newFilter);
            });
        }


        // if (vm.activoFijo.proveedor) {
        //   filtersArray.push({
        //     path: 'proveedor.id',
        //     equals: vm.activoFijo.proveedor.id
        //   });
        // }
        //
        // if (vm.activoFijo.servicio) {
        //   filtersArray.push({
        //     path: 'comprobanteDetalle.servicio.id',
        //     equals: vm.activoFijo.servicio.id
        //   });
        // }
        //
        // if (vm.activoFijo.fechaDesde) {
        //   filtersArray.push({
        //     path: 'fecha',
        //     equalOrAfter: moment(vm.activoFijo.fechaDesde).format('DD/MM/YYYY')
        //   });
        // }
        //
        // if (vm.activoFijo.fechaHasta) {
        //   filtersArray.push({
        //     path: 'fecha',
        //     equalOrBefore: moment(vm.activoFijo.fechaHasta).format('DD/MM/YYYY')
        //   });
        // }
        //
        // if (vm.activoFijo.proyecto) {
        //   filtersArray.push({
        //     path: 'proyecto.id',
        //     equals: vm.activoFijo.proveedor.id
        //   });
        // }
        //
        //
        // var filters = { search: filterFactory.and(filtersArray).value() };
        //
        // vm.optionsDtComprobantes.staticFilter = filters;
        // vm.showDtComprobantes = true;
    }

    function controlLimiteDescuento(detalle, index) {
        var limiteDescuento = String(detalle.descuento);
        var cantidad;
        if (limiteDescuento.includes('.')) {
            cantidad = limiteDescuento.split('.')[1].length;
        }
        if (limiteDescuento.includes(',')) {
            cantidad = limiteDescuento.split(',')[1].length;
        }
        if (cantidad > 8) {
            detalle.porcentajeDescuento = 0;
            var msg = "El Límite de Descuento debe contener como máximo 8 decimales.";
            notify({ message: msg, classes: "alert-warning", position: "right" });
            return
        }
        if (vm.comprobante.listaPrecioVenta) {
            var productoLista = [];
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'listaPrecioVenta.id',
                equals: vm.comprobante.listaPrecioVenta.id,
            }, {
                path: 'producto.id',
                equals: detalle.producto.id,
            }]).value();
            staticFilter.view = "ComprobanteVentaForm";
            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                _.forEach(response, function (elem) {
                    if (detalle.unidadMedida.id == elem.unidadMedida.id) {
                        productoLista = elem;
                        return true;
                    }
                })
                if (productoLista.limiteDescuento && vm.comprobante.listaPrecioVenta) {
                    if (productoLista.limiteDescuento && detalle.descuento > productoLista.limiteDescuento) {
                        detalle.descuento = 0;
                        //detalle.totalItem = 0;
                        vm.calcularTotalDetalle(detalle, index);
                        var msg = $filter("translate")("PRECIO_VENTA_DESCUENTO_MAYOR") + productoLista.limiteDescuento + "%";
                        notify({ message: msg, classes: "alert-warning", position: "right" });
                    }
                }
            });
        }
    }

    function recuperarCliente(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter)

        ClienteFactory.allForSelect(finalFilter).$promise.then(function (clientes) {
            var clie = [];
            for (var i = 0; i < clientes.length; i++) {
                if (clientes[i].bloquearVentas !== true) {
                    clie.push(clientes[i])
                }
            }
            vm.clientes = clie;
        })
    }

    function searchClienteFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.nombreFantasia && item.nombreFantasia.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
            );
        };
    }

    function searchSucursalClienteFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.codigoSucursal.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase()) > -1) ||
                (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1)
            );
        };
    }

    function changeUnidadAux(detalle, index) {
        if (vm.comprobante.listaPrecioVenta) {
            var staticFilter = {};
            staticFilter.search = filterFactory.and([{
                path: 'listaPrecioVenta.id',
                equals: vm.comprobante.listaPrecioVenta.id,
            },
            {
                path: 'producto.id',
                equals: detalle.producto.id,
            }]).value();
            staticFilter.view = "ComprobanteVentaForm";
            ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (response) {
                if (response.length > 0) {
                    changeUnidad(detalle, index, response);
                } else {
                    changeUnidad(detalle);
                }
            });
        } else {
            changeUnidad(detalle);
        }

        // Seteo de campo  'Cantidad U.M. Principal'
        var contenida = null;
        if (detalle.unidadMedida != null && detalle.unidadMedida.cantidad != null) {
            contenida = detalle.unidadMedida.cantidad;
        }

        if (detalle.cantidad != null && detalle.cantidad > 0 && contenida != null) {
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidad * contenida).toFixed(4);
            detalle.cantidadUmPrincipal = parseFloat(detalle.cantidadUmPrincipal); // Esto elimina ceros despues de la coma
        } else {
            detalle.cantidadUmPrincipal = detalle.cantidad;
        }
    }

    function controlPrecioMinimoMaximos(detalle, index) {
        var name = "precioVenta_" + index;
        if (vm.comprobante.listaPrecioVenta) {
            if (index != undefined) {
                var msg;
                $scope.ComprobanteForm[name].$setValidity("error", true);
                if (vm.precioMinimoMaximos[index] && vm.precioMinimoMaximos[index].precioMinimo && detalle.precioVenta < vm.precioMinimoMaximos[index].precioMinimo) {
                    msg = "El precio no puede ser menor al precio mínimo de la Lista " + vm.precioMinimoMaximos[index].precioMinimo;
                    vm.errores.splice(index, 1, msg);
                    $scope.ComprobanteForm[name].$error.pattern = true;
                    $scope.ComprobanteForm[name].$setValidity("error", false);
                    $scope.ComprobanteForm[name].$touched = true;

                } else if (vm.precioMinimoMaximos[index] && vm.precioMinimoMaximos[index].precioMaximo && detalle.precioVenta > vm.precioMinimoMaximos[index].precioMaximo) {
                    msg = "El precio no puede ser mayor al precio máximo de la Lista " + vm.precioMinimoMaximos[index].precioMaximo;
                    vm.errores.splice(index, 1, msg);
                    $scope.ComprobanteForm[name].$error.pattern = true;
                    $scope.ComprobanteForm[name].$setValidity("error", false);
                    $scope.ComprobanteForm[name].$touched = true;

                } else {
                    msg = "";
                    vm.errores.splice(index, 1, msg);
                    $scope.ComprobanteForm[name].$error = {};
                    $scope.ComprobanteForm[name].$invalid = false;
                }
            }
        }
    }

    function compararDescendente(a, b) {
        return b.fecha - a.fecha;
    }

    function controlPrecioUltimoCosto(detalle, index) {
        var filtroCosto = {};
        var name = "precioVenta_" + index;
        var precioVentaAux, unidadMedidaNew, cantidadContenida, costoActual, ultimoCostoAux, ultimoCostoAlmacen;
        var ultimoCostoSucursalList = [];
        if (detalle.producto) {

            if (vm.parametroAdministrarCostoPor == 'por_almacen') {
                ultimoCostoAlmacen = detalle.producto.ultimoCostoAlmacenList.find(function (ultimoCosto) {
                    return ultimoCosto.almacen.id == vm.comprobante.deposito.id;
                });
            } else if (vm.parametroAdministrarCostoPor == 'por_sucursal') {
                ultimoCostoAlmacen = detalle.producto.ultimoCostoAlmacenList.find(function (ultimoCosto) {
                    if (ultimoCosto.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                        ultimoCostoSucursalList.push(ultimoCosto);
                    }
                });
                var listaUltimoCostoSucursalOrde = $filter('orderBy')(ultimoCostoSucursalList, ['-fecha', '-id']);
                if (listaUltimoCostoSucursalOrde.length > 0) {
                    ultimoCostoAlmacen = listaUltimoCostoSucursalOrde[0];
                }
            } else {
                var listaUltimosCostos = detalle.producto.ultimoCostoAlmacenList;
                listaUltimosCostos.sort(compararDescendente);
                var listaObjetosOrdenada = $filter('orderBy')(listaUltimosCostos, ['-fecha', '-id']);
                if (listaObjetosOrdenada.length > 0) {
                    ultimoCostoAlmacen = listaObjetosOrdenada[0];
                }
            }
            if (ultimoCostoAlmacen != undefined && ultimoCostoAlmacen != null) {
                if (vm.parametroAdministracionCosto == 'costo_promedio_almacen') {
                    ultimoCostoAux = ultimoCostoAlmacen.costoPromedio;
                } else {
                    ultimoCostoAux = ultimoCostoAlmacen.ultimoCosto;
                }
            } else {
                ultimoCostoAux = 0;
            }

            precioVentaAux = detalle.precioVenta;
            // ultimoCostoAux = detalle.producto.ultimoCostoIva;

            if (ultimoCostoAux != undefined) {
                ultimoCostoAux = parseFloat(ultimoCostoAux).toFixed(2);
                unidadMedidaNew = detalle.unidadMedida;
                cantidadContenida = unidadMedidaNew.cantidad;
                costoActual = ultimoCostoAux;
                if (cantidadContenida != undefined && cantidadContenida != null) {
                    ultimoCostoAux = Math.round(costoActual * cantidadContenida);
                }
            }

            if (vm.comprobante.moneda.codigo != "guaranies") {
                vm.ultimoCostoIvaAux = ultimoCostoAux / vm.comprobante.cambio;
                vm.ultimoCostoIvaAux = Math.round(vm.ultimoCostoIvaAux * 100) / 100;
            } else {
                vm.ultimoCostoIvaAux = ultimoCostoAux;
            }

            if (detalle.descuento && detalle.descuento > 0) {
                precioVentaAux = precioVentaAux - (precioVentaAux * (detalle.descuento / 100));
            }

            if (index != undefined) {
                var msg;
                $scope.ComprobanteForm[name].$setValidity("error", true);
                if (precioVentaAux < vm.ultimoCostoIvaAux && vm.verPrecio != 'No') {
                    if (detalle.descuento && detalle.descuento > 0) {
                        msg = "El precio calculado no puede ser menor al último costo: " + vm.ultimoCostoIvaAux;
                        vm.errores.splice(index, 1, msg);
                    } else {
                        msg = "El precio no puede ser menor al último costo: " + vm.ultimoCostoIvaAux;
                        vm.errores.splice(index, 1, msg);
                    }
                    $scope.ComprobanteForm[name].$error.pattern = true;
                    $scope.ComprobanteForm[name].$setValidity("error", false);
                    $scope.ComprobanteForm[name].$touched = true;
                } else {
                    msg = "";
                    vm.errores.splice(index, 1, msg);
                    $scope.ComprobanteForm[name].$error = {};
                    $scope.ComprobanteForm[name].$invalid = false;
                    if (vm.comprobante.listaPrecioVenta) {
                        controlPrecioMinimoMaximos(detalle, index);
                    }
                }
            }
        }
    }

    function recuperarPreciosMinimosMaximos() {
        if (vm.comprobante.comprobanteDetalle.length > 0 && vm.comprobante.comprobanteDetalle[0].producto) {
            if (vm.comprobante.listaPrecioVenta) {
                var staticFilter = {};
                staticFilter.search = filterFactory.and([{
                    path: 'listaPrecioVenta.id',
                    equals: vm.comprobante.listaPrecioVenta.id,
                }]).value();
                staticFilter.view = "PrecioMinimoMaximoForm";
                vm.uiBlockuiConfig.bloquear = true;
                ListaPrecioVentaDetalleFactory.all(staticFilter).$promise.then(function (lista) {
                    vm.uiBlockuiConfig.bloquear = false;
                    if (lista != undefined || lista.length > 0) {
                        for (var j = 0; j < vm.comprobante.comprobanteDetalle.length; j++) {
                            var productoLista;
                            productoLista = _.find(lista, function (detalleLP) {
                                return vm.comprobante.comprobanteDetalle[j].producto.id === detalleLP.producto.id &&
                                    vm.comprobante.comprobanteDetalle[j].unidadMedida.id === detalleLP.unidadMedida.id
                            });
                            var precioMinimo, precioMaximo;
                            if (productoLista) {
                                if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                                    precioMinimo = productoLista.precioMinimo * vm.comprobante.cambioPactado;
                                    precioMaximo = productoLista.precioMaximo * vm.comprobante.cambioPactado;
                                } else if (vm.comprobante.moneda.codigo != "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo == "guaranies") {
                                    var precioMin = productoLista.precioMinimo / vm.comprobante.cambio;
                                    var precioMax = productoLista.precioMaximo / vm.comprobante.cambio;
                                    precioMinimo = Math.round(precioMin * 100) / 100;
                                    precioMaximo = Math.round(precioMax * 100) / 100;
                                } else {
                                    precioMinimo = productoLista.precioMinimo;
                                    precioMaximo = productoLista.precioMaximo;
                                }
                                var obj = {
                                    "precioMinimo": precioMinimo,
                                    "precioMaximo": precioMaximo
                                };
                                vm.precioMinimoMaximos.splice(j, 1, obj);
                            } else {
                                var obj = {};
                                vm.precioMinimoMaximos.splice(j, 1, obj);
                            }
                        }
                    }
                });
            }
        }
    }

    function buscarUltimoCostoProducto(detalle, index) {
        var filtroCosto = {};
        var fecha = moment(new Date()).format('DD/MM/YYYY');
        filtroCosto.search = filterFactory
            .and([{
                path: 'producto.id',
                equals: detalle.producto.id
            }, {
                path: 'dia',
                equalOrBefore: fecha
            }, {
                path: 'dia',
                sortDesc: 'true'
            }]).value();

        ProductoAlmacenFactory.all(filtroCosto).$promise.then(function (productosAlmacen) {
            var unidadMedidaNew, cantidadContenida, costoActual;
            if (productosAlmacen != undefined && productosAlmacen.length > 0) {
                vm.ultimoCostoIva = parseFloat(productosAlmacen[0].ultimoCostoIVA).toFixed(2);
                unidadMedidaNew = detalle.unidadMedida;
                cantidadContenida = unidadMedidaNew.cantidad;
                costoActual = vm.ultimoCostoIva;
                if (cantidadContenida != undefined && cantidadContenida != null) {
                    vm.ultimoCostoIva = Math.round(costoActual * cantidadContenida);
                }
                controlPrecioUltimoCosto(detalle, index);
            }
        });
    }
    function activarControlPrecios(detalle, index) {
        if (detalle.producto)
            buscarUltimoCostoProducto(detalle, index);
    }

    $scope.$watch('vm.comprobante.remisiones', function (newValue) {
        if (newValue) {
            if (vm.recuperarItemsComprobanteAsociado) {
                recuperarComprobantes()
            }
        }
    })
    function recuperarComprobantes() {
        var remision;
        vm.comprobantesProductos = [];
        for (var rem in vm.comprobante.remisiones) {
            remision = vm.comprobante.remisiones[rem];
            vm.comprobante.proyecto = remision.proyecto;
            for (var remDet in remision.remisionDetalle) {
                var contenida = null;
                var unidadMedidaPrincipal = null;
                if (remision.remisionDetalle[remDet].unidadMedida != null && remision.remisionDetalle[remDet].unidadMedida.cantidad != null) {
                    contenida = remision.remisionDetalle[remDet].unidadMedida.cantidad;
                }

                if (remision.remisionDetalle[remDet].cantidad != null && remision.remisionDetalle[remDet].cantidad > 0 && contenida != null) {
                    unidadMedidaPrincipal = remision.remisionDetalle[remDet].cantidad * contenida;
                } else {
                    unidadMedidaPrincipal = remision.remisionDetalle[remDet].cantidad;
                }
                var notCharged = true;
                for (var i = 0; i < vm.comprobantesProductos.length; i++) {
                    if (
                        vm.comprobantesProductos[i].producto.codigo == remision.remisionDetalle[remDet].producto.codigo &&
                        vm.comprobantesProductos[i].grupoProducto == remision.remisionDetalle[remDet].grupoProducto &&
                        vm.comprobantesProductos[i].unidadMedida.id == remision.remisionDetalle[remDet].unidadMedida.id &&
                        vm.comprobantesProductos[i].precioVenta == remision.remisionDetalle[remDet].precioVenta &&
                        vm.comprobantesProductos[i].numeroOrdenProduccion == remision.remisionDetalle[remDet].numeroOrdenProduccion &&
                        vm.comprobantesProductos[i].numeroOrdenCompraCliente == remision.remisionDetalle[remDet].numeroOrdenCompraCliente &&
                        vm.comprobantesProductos[i].fechaValidez == remision.remisionDetalle[remDet].fechaValidez
                    ) {
                        vm.comprobantesProductos[i].cantidad += remision.remisionDetalle[remDet].cantidad;
                        vm.comprobantesProductos[i].cantidadUmPrincipal += unidadMedidaPrincipal;
                        vm.calcularTotalDetalle(vm.comprobantesProductos[i]);
                        notCharged = false;
                    }
                }
                if (notCharged) {
                    vm.comprobantesProductos.push({
                        montosImponibles: [{
                            tipoImpuesto: vm.comprobante.cliente.exoneradoIva ? vm.tipoExento[0] :
                                remision.remisionDetalle[remDet].producto.tiposImpuesto[0],
                            porcentaje: vm.comprobante.cliente.exoneradoIva ? vm.tipoExento[0].porcentaje :
                                remision.remisionDetalle[remDet].producto.tiposImpuesto[0].porcentaje,
                            valor: 0
                        }],
                        producto: remision.remisionDetalle[remDet].producto,
                        grupoProducto: (vm.remisionGeneraMovimiento && remision.remisionDetalle[remDet].producto.criterioControl.codigo === "series")
                            || remision.remisionDetalle[remDet].producto.criterioControl.codigo === "lotes" ? remision.remisionDetalle[remDet].grupoProducto : undefined,
                        unidadMedida: remision.remisionDetalle[remDet].unidadMedida,
                        cantidad: remision.remisionDetalle[remDet].cantidad,
                        cantidadUmPrincipal: unidadMedidaPrincipal,
                        gruposProducto: remision.remisionDetalle[remDet].gruposProducto,
                        numeroOrdenProduccion: remision.remisionDetalle[remDet].numeroOrdenProduccion,
                        numeroOrdenCompraCliente: remision.remisionDetalle[remDet].numeroOrdenCompraCliente,
                        fechaValidez: remision.remisionDetalle[remDet].fechaValidez,
                        precioVenta: remision.remisionDetalle[remDet].precioVenta,
                        totalItem: remision.remisionDetalle[remDet].totalItem,
                    });
                    var lastIndex = vm.comprobantesProductos.length - 1;
                    vm.calcularTotalDetalle(vm.comprobantesProductos[lastIndex])
                }
            }
        }

        copiarComprobantes();
        recuperarPreciosMinimosMaximos();
    }

    $scope.$watch("vm.comprobante.tipo", function (newVal) {
    })

    function changeCondicionVenta() {
        if (vm.comprobante.formaPago) {
            vm.cantidadCuotas = vm.comprobante.formaPago.cantidadCuotas;
            vm.plazoCuotas = vm.comprobante.formaPago.plazoDias;
        }
    }

    function refreshReferencias() {
        recuperarPreciosMinimosMaximos();
    }

    function filterFormasPago() {
        if (vm.comprobante.cliente) {
            if (vm.comprobante.tipo.condicion) {
                if (vm.comprobante.tipo.condicion.codigo === "tipo_comprobante_contado") {
                    vm.formasPagos = _.filter(vm.formasPagosAux, function (formaPago) {
                        return formaPago.cantidadCuotas == null;
                    });
                } else if (vm.comprobante.tipo.condicion.codigo === "tipo_comprobante_credito") {
                    vm.formasPagos = _.filter(vm.formasPagosAux, function (formaPago) {
                        return formaPago.cantidadCuotas != null;
                    });
                }
            }
        }
    }

    // Función para calcular el precio sin impuestos
    function calcularPrecioSinImpuestos(precioConImpuestos, porcentajeImpuesto) {
        // Asegurarse de que el porcentaje de impuestos sea un número válido
        if (isNaN(porcentajeImpuesto) || porcentajeImpuesto < 0) {
            return null;
        }

        // Calcular el precio sin impuestos
        var precioSinImpuestos = Math.round(precioConImpuestos / (1 + porcentajeImpuesto / 100));

        return precioSinImpuestos;
    }

    function addNewProcesoCobro() {
        $scope.fechaHoraInicio = new Date();
        $scope.fechaHoraInicio.setHours(0, 0, 0, 0);
        if ($rootScope.AuthParams === undefined) {
            $state.go("login");
        } else if ($rootScope.AuthParams.accesoSistema === undefined) {
            $state.go("app.main");
        } else {
            $scope.usuario = $rootScope.AuthParams.accesoSistema.usuario;
            if ($rootScope.AuthParams.accesoSistema.cajas.length > 0) {
                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                    if (cajas !== undefined && cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                        $scope.caja = cajas;
                        //recuperarTimbradoFacturacionAgil($scope.caja)
                    }
                });
            } else {
                $state.go("app.main");
            }

            var filterProcesoCobro = {
                search: filterFactory
                    .and([
                        {
                            path: "caja.id",
                            equals: $scope.caja.id
                        },
                        {
                            path: "usuario.id",
                            equals: $rootScope.AuthParams.accesoSistema.usuario.id
                        },
                        {
                            path: "estado.codigo",
                            notEquals: "estado_procesocobro_cerrado"
                        },
                        {
                            path: "fechaHoraInicio",
                            like: moment($scope.fechaHoraInicio).format("DD/MM/YYYY")
                        }
                    ])
                    .value()
            };
            ProcesoCobroFactory.all(filterProcesoCobro).$promise.then(function (responseProcesoCobro) {
                if (responseProcesoCobro.length > 0) {
                    vm.procesoCobro = responseProcesoCobro[0];
                    vm.caja = $scope.caja;
                    vm.usuario = $rootScope.AuthParams.accesoSistema.usuario;
                } else {
                    if (vm.recuperarTotalDepositarApertura) {
                        var params = {};
                        params.search = filterFactory.and([{
                            path: 'monedaDesde.codigo',
                            equals: 'dolares'
                        },
                        {
                            path: 'monedaHasta.codigo',
                            equals: 'guaranies'
                        },
                        {
                            path: 'fecha',
                            like: moment($scope.fechaHoraInicio).format('DD/MM/YYYY')
                        }
                        ]).value();
                        params.view = 'CotizacionMonedaList';
                        CotizacionMonedaFactory.all(params).$promise.then(function (responseCotizacion) {
                            if (responseCotizacion && responseCotizacion[0]) {
                                $scope.cambio = responseCotizacion[0].valorCompra;

                                $scope.mostrarCampo = true;
                                var filterProceso = {
                                    search: filterFactory
                                        .and([
                                            {
                                                path: "caja.id",
                                                equals: $scope.caja.id
                                            },
                                            {
                                                path: 'id',
                                                sortDesc: true
                                            }
                                        ]).value()
                                }
                                ProcesoCobroFactory.all(filterProceso).$promise.then(function (rr) {
                                    $scope.recaudacionDepositar = (rr[0].recaudacionDepositarCierre != null && rr[0].recaudacionDepositarCierre != undefined) ? parseFloat(rr[0].recaudacionDepositarCierre).toFixed(0) : 0;

                                    var filterSucursal = {
                                        search: filterFactory
                                            .and([
                                                {
                                                    path: "cobro.sucursal.id",
                                                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                                                },
                                                {
                                                    path: "cobro.estado.codigo",
                                                    notEquals: "estado_cobro_anulado"
                                                },
                                                {
                                                    path: 'fechaPagoDiferido',
                                                    equalOrBefore: moment($scope.fechaHoraInicio).format('DD/MM/YYYY')
                                                },
                                                {
                                                    path: "depositado",
                                                    equals: false
                                                },
                                                {
                                                    path: "cobro.caja.id.",
                                                    equals: $scope.caja.id
                                                },
                                                {
                                                    path: "procesoCobroApertura.id",
                                                    isNull: "true"
                                                }
                                            ])
                                            .value()
                                    };
                                    CobroDetalleFactory.all(filterSucursal).$promise.then(function (response) {
                                        $scope.chequesDiferidosVencidos = 0;
                                        _.forEach(response, function (detalle) {
                                            if (detalle.moneda.codigo == 'dolares') {
                                                $scope.chequesDiferidosVencidos += detalle.importe * $scope.cambio;
                                            } else {
                                                $scope.chequesDiferidosVencidos += detalle.importe;
                                            }
                                        });
                                        $scope.totalDepositar = parseInt($scope.recaudacionDepositar) + parseInt($scope.chequesDiferidosVencidos);
                                    });
                                });
                            } else {
                                $scope.cambio = undefined;
                                $scope.mostrarCampo = true;
                                var filterProceso = {
                                    search: filterFactory
                                        .and([
                                            {
                                                path: "caja.id",
                                                equals: $scope.caja.id
                                            },
                                            {
                                                path: 'id',
                                                sortDesc: true
                                            }
                                        ]).value()
                                }
                                ProcesoCobroFactory.all(filterProceso).$promise.then(function (rr) {
                                    $scope.recaudacionDepositar = (rr[0].recaudacionDepositarCierre != null && rr[0].recaudacionDepositarCierre != undefined) ? parseFloat(rr[0].recaudacionDepositarCierre).toFixed(0) : 0;

                                    var filterSucursal = {
                                        search: filterFactory
                                            .and([
                                                {
                                                    path: "cobro.sucursal.id",
                                                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                                                },
                                                {
                                                    path: "cobro.estado.codigo",
                                                    notEquals: "estado_cobro_anulado"
                                                },
                                                {
                                                    path: 'fechaPagoDiferido',
                                                    equalOrBefore: moment($scope.fechaHoraInicio).format('DD/MM/YYYY')
                                                },
                                                {
                                                    path: "depositado",
                                                    equals: false
                                                },
                                                {
                                                    path: "cobro.caja.id.",
                                                    equals: $scope.caja.id
                                                },
                                                {
                                                    path: "procesoCobroApertura.id",
                                                    isNull: "true"
                                                }
                                            ])
                                            .value()
                                    };
                                    CobroDetalleFactory.all(filterSucursal).$promise.then(function (response) {
                                        $scope.chequesDiferidosVencidos = 0;
                                        _.forEach(response, function (detalle) {
                                            if (detalle.moneda.codigo == 'dolares') {
                                                $scope.chequesDiferidosVencidos += detalle.importe * 1;
                                            } else {
                                                $scope.chequesDiferidosVencidos += detalle.importe;
                                            }
                                        });
                                        $scope.totalDepositar = parseInt($scope.recaudacionDepositar) + parseInt($scope.chequesDiferidosVencidos);
                                    });
                                });
                            }
                        });
                    } else {
                        $scope.mostrarCampo = false;
                        $scope.cambio = 1;
                    }


                    var params = {};
                    params.search = filterFactory
                        .or([{
                            path: "estado.codigo",
                            equals: "per_adm_abierto"
                        }])
                        .value();

                    var flagPeriodo = false;

                    PeriodoAdministrativoFactory.all(params).$promise.then(function (response) {
                        var periodos = response;
                        _.forEach(periodos, function (value) {
                            var desde = new Date(value.fechaDesde);
                            var hasta = new Date(value.fechaHasta);
                            var hoy = new Date().setHours(0, 0, 0, 0);

                            if (hoy >= desde && hoy <= hasta) {
                                flagPeriodo = true;
                            }
                        });

                        if (flagPeriodo) {
                            $scope.tituloModal = "Se requiere la apertura de un nuevo Proceso Cobro para la fecha";
                            $timeout(function () {
                                $scope.$broadcast('modalOpened');
                            }, 20);
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/procesocobroagil-modal.html",
                                scope: $scope,
                                backdrop: 'static'
                            });
                            $scope.cancel = function () {
                                modalInstance.dismiss("cancel");
                                $state.go("app.main");
                            };
                            $scope.ok = function (caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio) {
                                //verificar si existe cambio para la fecha de inicio
                                var params = {};
                                params.search = filterFactory.and([{
                                    path: 'monedaDesde.codigo',
                                    equals: 'dolares'
                                },
                                {
                                    path: 'monedaHasta.codigo',
                                    equals: 'guaranies'
                                },
                                {
                                    path: 'fecha',
                                    like: moment(fecha).format('DD/MM/YYYY')
                                }
                                ]).value();
                                params.view = 'CotizacionMonedaList';
                                CotizacionMonedaFactory.all(params).$promise.then(function (response2) {
                                    if (response2 && response2[0]) {
                                        cambio = response2[0].valorCompra;
                                    } else {
                                        cambio = undefined;
                                    }


                                    vm.submitNewCobro = true;
                                    if (cambio != undefined) {
                                        ParametrosFactory.getByCodigo('validar_fecha_flujo_cobro').then(function (parametro) {
                                            if (parametro.valorTipo.codigo === 'no') {
                                                sendNewProcesoCobro($scope.caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio).then(function (data) {
                                                    modalInstance.close();
                                                    return ProcesoCobroFactory.get(
                                                        data.id,
                                                        "BaseList"
                                                    ).$promise;
                                                },
                                                    function (error) {
                                                        vm.submitNewCobro = false;
                                                        var msg = error.data[0].message;
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    })
                                                    .then(function (data) {
                                                        if (data != undefined) {
                                                            ProcesoCobroFactory.setIdProcesoCobro(data.id);
                                                            ProcesoCobroFactory.setProcesoCobro(data);
                                                            vm.procesoCobro = data;
                                                            vm.caja = $scope.caja;
                                                            vm.usuario = $rootScope.AuthParams.accesoSistema.usuario;
                                                        }
                                                    });
                                            } else {
                                                var fechah = new Date();
                                                var mesh;
                                                if (fechah.getMonth() < 9) {
                                                    mesh = "0" + (fechah.getMonth() + 1);
                                                } else {
                                                    mesh = fechah.getMonth() + 1;
                                                }

                                                var diah;
                                                if (fechah.getDate() < 9) {
                                                    diah = "0" + (fechah.getDate() + 1);
                                                } else {
                                                    diah = fechah.getDate() + 1;
                                                }

                                                var mesc;
                                                if (fecha.getMonth() < 9) {
                                                    mesc = "0" + (fecha.getMonth() + 1);
                                                } else {
                                                    mesc = fecha.getMonth() + 1;
                                                }

                                                var diac;
                                                if (fecha.getDate() < 9) {
                                                    diac = "0" + (fecha.getDate() + 1);
                                                } else {
                                                    diac = fecha.getDate() + 1;
                                                }

                                                var fechaHoy = fechah.getFullYear() + "/" + mesh + "/" + diah;
                                                var fechaC = fecha.getFullYear() + "/" + mesc + "/" + diac;


                                                if (fechaC < fechaHoy) {
                                                    vm.submitNewCobro = false;
                                                    var msg = "La fecha de apertura no puede ser menor a la fecha actual";
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                } else {
                                                    sendNewProcesoCobro(caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio).then(function (data) {
                                                        modalInstance.close();
                                                        return ProcesoCobroFactory.get(
                                                            data.id,
                                                            "BaseList"
                                                        ).$promise;
                                                    },
                                                        function (error) {
                                                            vm.submitNewCobro = false;
                                                            var msg = error.data[0].message;
                                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                        })
                                                        .then(function (data) {
                                                            if (data != undefined) {
                                                                ProcesoCobroFactory.setIdProcesoCobro(data.id);
                                                                ProcesoCobroFactory.setProcesoCobro(data);
                                                                vm.procesoCobro = data;
                                                                vm.caja = $scope.caja;
                                                                vm.usuario = $rootScope.AuthParams.accesoSistema.usuario;
                                                            }
                                                        });

                                                }


                                            }
                                        });
                                    } else {
                                        vm.submitNewCobro = false;
                                        var msg = "No se cuenta con una cotización para la fecha, no podrá iniciar un nuevo Proceso Cobro";
                                        notify({ message: msg, classes: "alert-danger", position: "right" });
                                    }
                                });
                            };
                        } else {
                            vm.submitNewCobro = false;
                            var msg = $scope.translations.NO_ADMINISTRATIVE_PERIOD;
                            notify({ message: msg, classes: "alert-danger", position: "right" });
                        }
                    });
                }
            });
        }
    }
    function sendNewProcesoCobro(caja, montoBaseEfectivo, fecha, recaudacionDepositar, chequesDiferidosVencidos, totalDepositar, cambio) {
        return TiposFactory.estadosProcesoCobro()
            .then(
                function (response) {
                    var estados = response.data;

                    if (!estados) {
                        console.log(
                            "No se pudo guardar el proceso de cobro debido a que no se pudo traer los estados"
                        );
                        return;
                    }

                    // console.log("Estados: ", estados);
                    var estadoActivo = _.find(estados, function (estado) {
                        return estado.codigo === "estado_procesocobro_abierto";
                    });
                    if (!estadoActivo) {
                        console.log("No hay estado abierto");
                        return;
                    }
                    var procesoCobro = {
                        estado: estadoActivo.id,
                        caja: caja,
                        fechaHoraInicio: fecha,
                        usuario: $rootScope.AuthParams.accesoSistema.usuario,
                        locacion: $rootScope.AuthParams.locacionSeleccionada.id
                    };

                    if (montoBaseEfectivo) {
                        procesoCobro.montoBaseEfectivo = montoBaseEfectivo;
                    }

                    if (recaudacionDepositar) {
                        procesoCobro.recaudacionDepositar = parseInt(recaudacionDepositar);
                    } else {
                        procesoCobro.recaudacionDepositar = 0;
                    }

                    if (chequesDiferidosVencidos) {
                        procesoCobro.chequesDiferidosVencidos = parseInt(chequesDiferidosVencidos);
                    } else {
                        procesoCobro.chequesDiferidosVencidos = 0;
                    }

                    if (totalDepositar) {
                        procesoCobro.totalDepositar = parseInt(totalDepositar);
                    } else {
                        procesoCobro.totalDepositar = 0;
                    }

                    if (cambio) {
                        procesoCobro.cambio = parseFloat(cambio);
                    }

                    var pc = ProcesoCobroFactory.create(procesoCobro);
                    return ProcesoCobroFactory.save(pc);
                }
            )
            .then(
                function (data) {
                    console.log("Se ha guardado el proceso de cobro.");
                    return data;
                }
            );
    }
    function recuperarTimbradoFacturacionAgil(caja) {

        if (caja.puntoEmision != undefined) {
            var filter = {};
            filter.search = filterFactory.and([{
                path: 'puntoEmisionVenta.id',
                equals: caja.puntoEmision.id
            }, {
                path: 'timbradoEmpresa.factAgilDefault',
                equals: true
            }, {
                path: "timbradoEmpresa.activo",
                equals: true
            }, {
                path: "tipoComprobante.codigo",
                equals: vm.comprobante.tipo.clase.codigo
            }
            ]).value();
            TimbradoEmpresaPuntoEmisionVentaFactory.all(filter, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
                if (response.length > 0) {
                    vm.timbradoagil = response[0];
                    vm.comprobante.timbradoPuntoEmision = vm.timbradoagil;
                } else {
                    var msg = "No se encontró un Timbrado para la Facturación Ágil";
                    notify({ message: msg, classes: "alert-danger", position: "right" });
                }
            });
        }
    }
    function recuperarListaPrecio() {
        var staticFilter = {};
        staticFilter.search = filterFactory.and([{
            path: 'cliente.id',
            equals: vm.comprobante.cliente.id
        }, {
            path: 'estado.codigo',
            equals: 'lista_activada'
        }]).value();

        ListaPrecioVentaFactory.all(staticFilter, "ComprobanteVentaForm").$promise.then(function (response) {
            /* Se controla si el cliente está asociado a una lista de precios por asociación directa
            con la moneda seleccionada */
            if (response.length > 0) {
                vm.comprobante.listaPrecioVenta = response[0];
                vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                if (vm.comprobante.moneda.codigo == "guaranies" && vm.comprobante.listaPrecioVenta.moneda.codigo != "guaranies") {
                    //si el comprobante es en gs y la lista en moneda extranjera
                    vm.mostrarCambioPactado = true;
                    vm.monedaLocal.$promise.then(function (data) {

                        if (vm.comprobante.monedaLista && vm.comprobante.monedaLista.descripcion != data[0].valorTipo.descripcion) {
                            vm.cotizacionRequerida = true;

                            var params = {};
                            params.search = filterFactory.and([{
                                path: 'monedaDesde.id',
                                equals: vm.comprobante.monedaLista.id
                            },
                            {
                                path: 'monedaHasta.id',
                                equals: vm.monedaLocal[0].valorTipo.id
                            },
                            {
                                path: 'fecha',
                                like: moment(vm.comprobante.fecha).format('DD/MM/YYYY')
                                //equals:vm.comprobante.fecha
                            }
                            ]).value();
                            params.view = 'CotizacionMonedaList';
                            CotizacionMonedaFactory.all(params).$promise.then(function (response) {
                                if (response && response[0]) {
                                    vm.comprobante.cambioPactado = response[0].valorCompra;
                                } else {
                                    vm.comprobante.cambioPactado = null;
                                }
                            });
                        } else {
                            vm.cotizacionRequerida = false;
                            vm.comprobante.cambioPactado = 1;
                        }
                    });
                } else {
                    vm.mostrarCambioPactado = false;
                }
                vm.getListasPreciosVentasByMoneda();
                ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
                    if (parametro.valorTipo.codigo === 'no') {
                        vm.utilizarPrecioBase = false;
                    } else {
                        vm.utilizarPrecioBase = true;
                        if (vm.comprobante.listaPrecioVenta) {
                            vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                            if (vm.comprobante.listaPrecioVenta.precioBase) {
                                vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                            }
                        }
                    }
                });
            } else if (response.length === 0) {
                /* Se controla si el cliente está asociado a una lista de precios por su tipo de cliente
                con la moneda seleccionada */
                $timeout(function () {
                    var staticFilter = {};
                    staticFilter.search = filterFactory.and([{
                        path: 'tipoCliente.id',
                        equals: vm.comprobante.cliente.tipoCliente.id
                    }, {
                        path: 'estado.codigo',
                        equals: 'lista_activada'
                    }, {
                        path: 'moneda.codigo',
                        equals: vm.comprobante.moneda.codigo
                    }]).value();
                    ListaPrecioVentaFactory.all(staticFilter, "ComprobanteVentaForm").$promise.then(function (response) {
                        if (response.length > 0) {
                            vm.comprobante.listaPrecioVenta = response[0];
                            vm.comprobante.monedaLista = vm.comprobante.listaPrecioVenta.moneda;
                            vm.getListasPreciosVentasByMoneda();
                            ParametrosFactory.getByCodigo('utilizar_precio_base').then(function (parametro) {
                                if (parametro.valorTipo.codigo === 'no') {
                                    vm.utilizarPrecioBase = false;
                                } else {
                                    vm.utilizarPrecioBase = true;
                                    if (vm.comprobante.listaPrecioVenta) {
                                        vm.porcRecargo = vm.comprobante.listaPrecioVenta.porcentajeRecargo;
                                        if (vm.comprobante.listaPrecioVenta.precioBase) {
                                            vm.precioBase = vm.comprobante.listaPrecioVenta.precioBase.descripcion;
                                        }
                                    }
                                }
                            });
                        } else {
                            vm.comprobante.listaPrecioVenta = undefined;
                            vm.porcRecargo = 0;
                            vm.precioBase = 'Precio de Venta'
                            vm.getListasPreciosVentasByMoneda();
                            vm.comprobante.cambioPactado = undefined;
                        }
                    });
                });
            } else {
                /* Si el cliente está asociado a una Lista de Precios de Ventas, pero no con la moneda
                seleccionada. */
                vm.comprobante.listaPrecioVenta = undefined;
                vm.porcRecargo = 0;
                vm.precioBase = 'Precio de Venta'
                vm.getListasPreciosVentasByMoneda();
                vm.comprobante.cambioPactado = undefined;
            }
        });
    }
    function codigoBarrasAdded(tag, index) {

        if (vm.comprobantesProductos[index].codigoBarras != undefined && vm.comprobantesProductos[index].codigoBarras.length > 1) {
            codigoBarrasRemoved(tag, index)
            return;
        }

        var pad = "0000000000000"
        tag.codigoBarras = pad.substring(0, pad.length - tag.codigoBarras.length) + tag.codigoBarras;
        var filterCodigoBarras = [];

        filterCodigoBarras.push({
            path: "codigoBarrasPrincipal.codigoBarras",
            like: tag.codigoBarras
        })

        var filter = {
            search: filterFactory.or([
                filterFactory.and(filterCodigoBarras).value()
            ]).value()
        }

        ProductosFactory.all(filter, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
            if (response.length > 0) {
                vm.comprobantesProductos[index].producto = response[0];
                vm.comprobantesProductos[index].unidadMedida = response[0].unidadMedidaBase;
                vm.comprobantesProductos[index].cantidad = 1;

                // changeProducto -> changeUnidad -> calcularTotalDetalle
                changeProducto(vm.comprobantesProductos[index]);

                $("#cantidad_" + index).focus();

                var listaDetalles = [];
                for (var i = 0; i < vm.comprobantesProductos.length; i++) {
                    if (vm.comprobantesProductos[i].producto !== undefined && vm.comprobantesProductos[i].producto !== null) {
                        listaDetalles.push(vm.comprobantesProductos[i]);
                    }
                }
                vm.comprobantesProductos = listaDetalles;
            } else {
                var msg = $filter('translate')('CODIGO_BARRAS_NO_ENCONTRADO') + " -> " + tag.codigoBarras;
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                codigoBarrasRemoved(tag, index)
                return;
            }
        })
    }
    function codigoBarrasRemoved(tag, index) {
        // Obtener el código de barras que se eliminó
        var codigoBarrasEliminado = tag.codigoBarras;

        // Iterar sobre los comprobantesProductos y eliminar el código de barras de cada uno si está presente
        var comprobanteProducto = vm.comprobantesProductos[index];
        // Verificar si el código de barras eliminado está presente en la lista de códigos de barras del comprobanteProducto
        for (var j = 0; j < comprobanteProducto.codigoBarras.length; j++) {
            if (comprobanteProducto.codigoBarras[j].codigoBarras === codigoBarrasEliminado) {
                // Si se encontró el código de barras en la lista, eliminarlo
                comprobanteProducto.codigoBarras.splice(j, 1);
                // Salir del bucle interno una vez que se haya eliminado el código de barras
                break;
            }
        }

    }
    $scope.agregarDetalleCobro = function () {
        vm.cobro.cobroDetalle.push({});
        var rowNumber = vm.cobro.cobroDetalle.length - 1;
        $timeout(function () {
            $scope.$broadcast("newItemAdded");
        }, 20);
    }

    function removeItemFromArrayCobro(elemento, arreglo) {
        _.remove(arreglo, function (item) {
            return item === elemento;
        });
        updateTotalDetalles();
    }
    function updateTotalDetalles() {
        vm.totalGeneralDetalles = _.reduce(
            vm.cobro.cobroDetalle,
            function (suma, detalle) {
                var sumaTotal = suma + detalle.importe;
                if (detalle.importe > vm.totalGeneral) {
                    detalle.importeVueltoItem = detalle.importe - vm.totalGeneral;
                } else if (sumaTotal > vm.totalGeneral) {
                    detalle.importeVueltoItem = sumaTotal - vm.totalGeneral;
                } else {
                    detalle.importeVueltoItem = 0;
                }
                return sumaTotal;
            },
            0
        );
        // Redondeo en funcion de moneda
        var cantDecimales = 0;
        if (vm.cobro.moneda.codigo != 'guaranies') {
            cantDecimales = 2;
        }
        vm.totalGeneralDetalles = parseFloat(vm.totalGeneralDetalles.toFixed(cantDecimales));
        vm.vuelto = vm.totalGeneralDetalles - vm.totalGeneral;
        if (vm.vuelto < 0) {
            vm.vuelto = 0;
        }

        vm.saldo = vm.totalGeneral - vm.totalGeneralDetalles;

        // Asegurarse de que el saldo no sea negativo
        if (vm.saldo < 0) {
            vm.saldo = 0;
            vm.addItems = false;
            vm.registrarPago = false;
        } else if (vm.saldo == 0) {
            vm.registrarPago = false;
            vm.addItems = true;
        } else {
            vm.registrarPago = true;
            vm.addItems = false;
        }
        vm.cobro.vuelto = vm.vuelto;
        vm.totalPagos = vm.totalGeneralDetalles;
    }
    function limpiarCampos(index) {

        vm.cobro.cobroDetalle[index].entidadBancaria = null;
        vm.cobro.cobroDetalle[index].numeroTransaccion = null;
        vm.cobro.cobroDetalle[index].denominacionTarjeta = null;
        vm.cobro.cobroDetalle[index].numeroTarjeta = null;
        vm.cobro.cobroDetalle[index].numeroVoucher = null;
        vm.cobro.cobroDetalle[index].proveedor = null;
        vm.cobro.cobroDetalle[index].tipoProcesamiento = null;
        vm.cobro.cobroDetalle[index].tipoTarjeta = null;
        vm.cobro.cobroDetalle[index].titular = null;
        vm.cobro.cobroDetalle[index].codigoAutorizacion = null;
        vm.cobro.cobroDetalle[index].numeroCheque = null;
        vm.cobro.cobroDetalle[index].numeroCuenta = null;
        vm.cobro.cobroDetalle[index].fecha = null;
        vm.cobro.cobroDetalle[index].fechaPagoDiferido = null;
        vm.cobro.cobroDetalle[index].numeroRetencion = null;
        vm.cobro.cobroDetalle[index].numeroComprobanteRetencion = null;
        vm.cobro.cobroDetalle[index].numeroTimbrado = null;
        vm.cobro.cobroDetalle[index].numeroCuentaContable = null;

        if (vm.cobro.cobroDetalle[index].formaPago.clase.codigo === "forma_pago_tarjeta") {
            vm.cobro.cobroDetalle[index].titular = vm.cobro.cliente.nombre;
            vm.cobro.cobroDetalle[index].tipoProcesamiento = vm.auxProcesamiento;
            vm.cobro.cobroDetalle[index].tipoTarjeta = vm.cobro.cobroDetalle[index].formaPago.tipoTarjeta;
        }
    }
    function cleanUnused(modelo) {
        if (!modelo.cobrosComprobantes) {
            return;
        }
        _.forEach(modelo.cobrosComprobantes, function (cobroComprobante) {
            delete cobroComprobante.montoCobrado;
            delete cobroComprobante.montoAplicado;
            //delete cobroComprobante.saldoMonedaCobro;
            delete cobroComprobante.monedaDesdeTranslation;
            delete cobroComprobante.monedaHastaTranslation;
        });
    }
    function evaluatePeriodoAdministrativo(fecha) {
        return $q(function (resolve, reject) {
            var params = {};
            params.search = filterFactory
                .and([{
                    path: "estado.codigo",
                    equals: "per_adm_abierto"
                },
                {
                    path: "fechaDesde",
                    equalOrBefore: moment(new Date(fecha)).format("DD/MM/YYYY")
                },
                {
                    path: "fechaHasta",
                    equalOrAfter: moment(new Date(fecha)).format("DD/MM/YYYY")
                }
                ])
                .value();

            PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {

                if (responsePeriodo.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        });
    }

    function changeTipoComprobanteAgil() {

        vm.cantidadCuotas = null;
        vm.plazoCuotas = null;
        vm.comprobante.formaPago = null;
        filterFormasPago();
        vm.montoInicial = undefined;
        vm.habilitarTimbradoSelect = false;
        vm.comprobante.timbradoPuntoEmision = null;
        recuperarTimbradoFacturacionAgil($scope.caja);
        vm.changeCotizacion(vm.comprobante.moneda);
        vm.monedas = {};
        TiposFactory.monedas().then(function (data) {
            vm.monedas = data.data;
        });
    }

}

"use strict";

angular
    .module("qualita.venta")
    .controller("ComprobanteVentaPendienteListCtrl", ComprobanteVentaPendienteListCtrl);

ComprobanteVentaPendienteListCtrl.$inject = [
    "AuthorizationService",
    "AuthenticationService",
    "ComprobanteVentaFactory",
    "VentaFactory",
    "$modal",
    "$scope",
    "$state",
    "$rootScope",
    "filterFactory",
    "notify",
    "VentasLangFactory",
    "WatchLocacionChangeFactory",
    "$filter",
    "cajaFactory",
    "ReportTicketFactory",
    "$window",
    "UtilFactory",
    "CsvFactory",
    "baseurl",
    "CobroFactory",
    "ProcesoCobroFactory",
    "$q",
    "PeriodoAdministrativoFactory",
    "ParametrosFactory",
    "CONFIGURATION",
    "SolicitudAutorizacionFactory",
    "TiposTipoFactory",
    "ClienteFactory",
    "$timeout",
    "TipoComprobanteFactory",
    "TimbradoEmpresaPuntoEmisionVentaFactory",
    "puntoEmisionFactory",
    "$http",
    "suscripcionRecurrenteFactory",
    "agendaSuscripcionRecurrenteFactory",
    "TiposFactory"
];

function ComprobanteVentaPendienteListCtrl(
    AuthorizationService,
    AuthenticationService,
    ComprobanteVentaFactory,
    VentaFactory,
    $modal,
    $scope,
    $state,
    $rootScope,
    filterFactory,
    notify,
    VentasLangFactory,
    WatchLocacionChangeFactory,
    $filter,
    cajaFactory,
    ReportTicketFactory,
    $window,
    UtilFactory,
    CsvFactory,
    baseurl,
    CobroFactory,
    ProcesoCobroFactory,
    $q,
    PeriodoAdministrativoFactory,
    ParametrosFactory,
    CONFIGURATION,
    SolicitudAutorizacionFactory,
    TiposTipoFactory,
    ClienteFactory,
    $timeout,
    TipoComprobanteFactory,
    TimbradoEmpresaPuntoEmisionVentaFactory,
    puntoEmisionFactory,
    $http,
    suscripcionRecurrenteFactory,
    agendaSuscripcionRecurrenteFactory,
    TiposFactory
) {
    var vm = this;

    vm.formSuscripcion = false;
    vm.comprobanteProcesadoSuscripcion = false;

    vm.uiBlockuiConfig = {
        'bloquear': false
    };

    vm.comprobantes = comprobantes;
    vm.filterTimbrado = filterTimbrado;
    vm.searchTimbradoPuntoEmisionFilter = searchTimbradoPuntoEmisionFilter;
    vm.getPuntosEmisionVenta = getPuntosEmisionVenta;
    vm.puntoEmisionSelect = puntoEmisionSelect;
    vm.generarFechasPlantilla = generarFechasPlantilla;
    vm.generarFechas = generarFechas;
    vm.diaDeLaSemana = diaDeLaSemana;
    vm.comprobante = null;
    var agendasSuscripcionRecurrentes = [];
    vm.agendaSuscripcionRecurrente = {};
    vm.realizarTareasEspeciales = null;

    activate();


    function diaDeLaSemana(dia) {
        switch (dia.toLowerCase()) {
            case 'lunes':
                return 1;
            case 'martes':
                return 2;
            case 'miercoles':
                return 3;
            case 'jueves':
                return 4;
            case 'viernes':
                return 5;
            case 'sabado':
                return 6;
            case 'domingo':
                return 0;
            default:
                throw new Error('Día inválido: ' + dia);
        }
    }

    function generarFechas(fechaInicial, fechaFinal, periodicidad, dias, numeroDia) {

        var fechasValidas = [];
        var initialDateParts = fechaInicial.split("/");
        var finalDateParts = fechaFinal.split("/");
        var fechaActual = new Date(initialDateParts[2], initialDateParts[1] - 1, initialDateParts[0]);
        var fechaFinal = new Date(finalDateParts[2], finalDateParts[1] - 1, finalDateParts[0], 23, 59, 59);


        // Incluye la fecha inicial como una fecha válida
        fechasValidas.push(new Date(fechaActual));

        while (fechaActual < fechaFinal) {
            // Agrega el periodo a la fecha actual
            switch (periodicidad.toLowerCase()) {
                case 'diaria':
                    fechaActual.setDate(fechaActual.getDate() + 1);
                    break;
                case 'semanal':
                    fechaActual.setDate(fechaActual.getDate() + 7);
                    break;
                case 'mensual':
                    if (numeroDia) {
                        // Establece el día del mes al número de día especificado
                        fechaActual.setDate(numeroDia);
                    }
                    // Agrega un mes a la fecha actual
                    fechaActual.setMonth(fechaActual.getMonth() + 1);

                    break;
                case 'semestral':
                    if (numeroDia) {
                        // Establece el día del mes al número de día especificado
                        fechaActual.setDate(numeroDia);
                        // Establece el mes en julio o enero dependiendo de si el número de día está en la primera o la segunda mitad del año
                        var mesObjetivo = (numeroDia <= 15) ? 6 : 0; // 6 para julio, 0 para enero
                        fechaActual.setMonth(mesObjetivo);
                    }
                    // Agrega 6 meses a la fecha actual
                    fechaActual.setMonth(fechaActual.getMonth() + 6);

                    break;
                case 'trimestral':
                    if (numeroDia) {
                        // Establece el día del mes al número de día especificado
                        fechaActual.setDate(numeroDia);
                        // Establece el mes al trimestre más cercano
                        var trimestreObjetivo = Math.floor((fechaActual.getMonth() - 1) / 3) + 1;
                        var mesObjetivo = trimestreObjetivo * 3;
                        fechaActual.setMonth(mesObjetivo);
                    }
                    // Agrega 3 meses a la fecha actual
                    fechaActual.setMonth(fechaActual.getMonth() + 3);

                    break;
                case 'anual':
                    fechaActual.setFullYear(fechaActual.getFullYear() + 1);
                    break;
                default:
                    throw new Error('Periodicidad inválida: ' + periodicidad);
            }

            // Verifica si la fecha actual es válida
            var esFechaValida = true;
            if (dias.length > 0) {
                esFechaValida = false;
                var diaDeLaSemana = fechaActual.getDay();
                for (var i = 0; i < dias.length; i++) {
                    var dia = dias[i];
                    if (dia.value && diaDeLaSemana === vm.diaDeLaSemana(dia.name)) {
                        esFechaValida = true;
                        break;
                    }
                }
            }
            if (!esFechaValida) {
                if (dias) {
                    // Obtiene la fecha válida más cercana
                    var fechaValidaMasCercana = null;
                    var diferenciaFechaValidaMasCercana = Infinity;
                    for (var i = 0; i < dias.length; i++) {
                        var dia = dias[i];
                        if (dia.value) {
                            var fechaObjetivo = new Date(fechaActual);
                            var diaDeLaSemanaObjetivo = vm.diaDeLaSemana(dia.name);
                            while (fechaObjetivo.getDay() != diaDeLaSemanaObjetivo) {
                                fechaObjetivo.setDate(fechaObjetivo.getDate() + 1);
                            }
                            var diferencia = fechaObjetivo - fechaActual;
                            if (diferencia < 0) {
                                diferencia += 7 * 24 * 60 * 60 * 1000;
                            }
                            if (diferencia < diferenciaFechaValidaMasCercana) {
                                fechaValidaMasCercana = fechaObjetivo;
                                diferenciaFechaValidaMasCercana = diferencia;
                            }
                        }
                    }
                    if (fechaValidaMasCercana) {
                        fechaActual = fechaValidaMasCercana;
                        esFechaValida = true;
                    }
                }
                if (!esFechaValida) {
                    continue;
                }
            }

            // Agrega la fecha válida al resultado
            fechasValidas.push(new Date(fechaActual));
        }

        // Se filtran las fechas para no superar la fecha final
        fechasValidas = _.filter(fechasValidas, function (date) {
            return date <= fechaFinal;
        });

        return fechasValidas;
    }

    function generarFechasPlantilla() {

        var fechas = generarFechas(vm.comprobante.primeraFacturacion, vm.comprobante.fechaFinal,
            vm.comprobante.tipoPeriodicidad.codigo, JSON.parse(vm.comprobante.diasDeLaSemana), vm.comprobante.fechaMes);

        console.log(fechas);


        $scope.mensajeModal = $filter("translate")("AGENDAR_PLANTILLA_SUSCRIPCION_MENSAJE");

        $scope.fechasArr = [];

        for (var i = 1; i < fechas.length + 1; i++) {
            $scope.fechasArr.push(i.toString() + ': ' + moment(fechas[i - 1]).format('DD/MM/YYYY'));
        }

        console.log(fechas);
        $scope.tituloModal = $filter("translate")("AGENDAR_PLANTILLA");
        var modalInstance = $modal.open({
            template: '<div class="modal-header">\n' +
                '  <h3 class="modal-title">{{::tituloModal}}</h3>\n' +
                '</div>\n' +
                '<div class="modal-body">{{::mensajeModal}} <div ng-repeat="str in fechasArr"> {{ str }}<br> </div> </div>\n' +
                '<div class="modal-footer">\n' +
                '  <button id="{{::selectedItemId}}" class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">{{si_no == true ? "Si" : "Aceptar"}}</button>\n' +
                '  <button class="btn btn-warning" ng-click="cancel()">{{si_no == true ? "No" : "Cancelar"}}</button>\n' +
                '</div>',
            scope: $scope
        });
        $scope.cancel = function () {
            modalInstance.dismiss("cancel");
        };

        $scope.ok = function () {
            if (vm.comprobante.estado.codigo == 'comp_venta_preparado') {

                _.forEach(fechas, function (fecha) {

                    var agendaSuscripcion = {};
                    agendaSuscripcion.tipoOperacionGenerar = suscripcionRecurrenteFactory.getSuscripcion().tipoOperacionGenerar;
                    agendaSuscripcion.cliente = vm.comprobante.cliente;
                    agendaSuscripcion.activo = true;
                    agendaSuscripcion.importe = vm.comprobante.montoTotal;
                    agendaSuscripcion.comprobanteVenta = vm.comprobante;
                    agendaSuscripcion.fecha = suscripcionRecurrenteFactory.getSuscripcion().fecha;
                    agendaSuscripcion.fechaGeneracion = new Date(fecha.setHours(7, 0, 0));
                    agendaSuscripcion.suscripcionRecurrente = suscripcionRecurrenteFactory.getSuscripcion();
                    agendaSuscripcion.intervaloRepeticion = vm.agendaSuscripcionRecurrente.intervaloRepeticion;
                    agendaSuscripcion.accion = vm.agendaSuscripcionRecurrente.accion;
                    agendaSuscripcion.estado = vm.agendaSuscripcionRecurrente.estado;
                    agendasSuscripcionRecurrentes.push(agendaSuscripcionRecurrenteFactory.create(agendaSuscripcion));

                });

                return submitAgendaSuscripcionRecurrente().then(
                    function (agendasuscripcionrecurrente) {

                        console.log(agendasuscripcionrecurrente);
                        $state.go("app.suscripcionrecurrente.proceso.agendamiento.list", {
                            idSuscripcion: suscripcionRecurrenteFactory.getIdSuscripcion()
                        }, { reload: true });

                    },
                    function (error) {
                        $rootScope.isProcessing = false;
                        console.log("Ha ocurrido un error");
                        console.log(error);
                        var msg = 'Error al Generar Agendas';
                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    }
                );

            }
        }

    }

    function submitAgendaSuscripcionRecurrente() {

        return agendaSuscripcionRecurrenteFactory.saveArray(agendasSuscripcionRecurrentes);

    }


    function activate() {
        $scope.bloqueoConfirmar = false;
        vm.formSuscripcion = false;
        vm.comprobanteProcesadoSuscripcion = false;
        vm.valorTipo = "";
        console.log("Activate de Index Comprobante Venta");
        ParametrosFactory.getByCodigo('generacion_factura_electronica').then(function (response) {
            vm.valorTipo = response.valorTipo.codigo;
        });

        var monedaRender = function (data, type, row) {
            var campoMoneda = null;
            if (row.moneda != undefined) {
                campoMoneda = row.moneda;
            } else {
                if (row.cobro) {
                    campoMoneda = row.cobro.moneda;
                } else {
                    campoMoneda = row.comprobanteVentaAplicado.moneda
                }
            }

            if (data != undefined) {
                var moneda = "Gs. ";
                if (campoMoneda.codigo === "dolares") {
                    moneda = "Usd. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "pesos") {
                    moneda = "Pes. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "real") {
                    moneda = "Rel. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "euro") {
                    moneda = "Eur. ";
                    data = parseFloat(data).toFixed(2);
                } else {
                    data = parseFloat(data).toFixed(0);
                }
                // TODO hacer esto mismo en todos los renders de moneda
                return (
                    moneda +
                    data
                        .toString()
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            } else {
                return "";
            }
        };

        var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
        var enviarAContabilidad = '';

        promise1.then(function (response) {
            enviarAContabilidad = response.valorTipo.codigo;
        });

        var defaultColumnOrder = [
            "id",
            "cdc",
            "numero",
            "fecha",
            "cliente.codigo",
            "cliente.nombre",
            "razonSocial",
            "numeroDocumento",
            "tipo.descripcion",
            "cambio",
            "venta.planillaReparto.numero",
            "vendedor.nombresyapellidos",
            "pedidoVenta.id",
            "estado.descripcion",
            "estadoRendicion.descripcion",
            "fechaModificacionRendicion",
            "proyecto.nombre",
            "timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
            "timbradoPuntoEmision.puntoEmisionVenta.numero",
            "montoTotal",
            "saldo",
            "listaPrecioVenta.descripcion",
            "moneda.descripcion",
            "totalImpuestos",
            "origen.descripcion",
            "reimprimir"

        ];

        var renderVendedorData = function (data) {
            if (data == undefined)
                return "";
            else
                return data.nombres + " " + data.apellidos;
        };

        var renderOrigen = function (data) {
            if (data == undefined)
                return "QUALITA";
            else
                return data;
        };

        ParametrosFactory.getByCodigo('realizar_tareas_especiales').then(function (response) {
            vm.realizarTareasEspeciales = response.valorTipo.codigo;
        });



        VentasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            var venta = undefined;
            var staticFilter = undefined;
            console.log("A punto de validar ruta: ", $state)

            if ($state.is("app.consultacomprobantesventa.list")) {
                console.log("From outside");
                vm.fromOutside = true;
                //filterTipoComprobantes();
            } else if ($state.is("app.ventas.proceso.comprobanteventa.list")) {
                console.log("From Venta Process");
                vm.procesoVenta = true;
                venta = VentaFactory.getVenta();
                $scope.venta = VentaFactory.getVenta();
                console.log("tipoVenta: ", venta);
                var modo = VentaFactory.getModoAcceso();
                if (!venta) {
                    console.log("No se encontró ninguna venta.");
                    $state.go("app.main");
                } else {
                    //filterTipoComprobantes(venta.id);
                }
                var tipo = venta.tipo;
                var tipoVentaDetalle = _.find(venta.tipo.tipoVentaDetalles, function (
                    detalle
                ) {
                    return detalle.componente.codigo == "comp_tipo_venta_comprobante";
                });
                if (!tipoVentaDetalle) {
                    console.log("No tiene permiso para revisar esta seccion");
                    $state.go("app.main");
                }
                var isStateEnabled = VentaFactory.checkPermissionForState(
                    tipoVentaDetalle.orden - 1
                );
                if (!isStateEnabled) {
                    console.log("No tiene permiso para revisar esta seccion");
                    $state.go("app.main");
                }

                // filtro estático
                staticFilter = {};
                staticFilter.search = filterFactory
                    .and([
                        {
                            path: "venta.id",
                            equals: venta.id
                        },
                        {
                            path: "id",
                            sortDesc: "true"
                        },
                        {
                            path: "id",
                            isNotNull: "true"
                        }
                    ]).value();

            } else if ($state.is("app.suscripcionrecurrente.proceso.comprobanteventa.list")) {

                staticFilter = {};
                vm.formSuscripcion = true;

                staticFilter.search = filterFactory.or([

                    filterFactory.and([
                        {
                            path: "suscripcionRecurrente.id",
                            equals: suscripcionRecurrenteFactory.getIdSuscripcion()
                        },
                        {
                            path: "estado.codigo",
                            equals: "comp_venta_preparado"
                        }
                    ]).value(),
                    filterFactory.and([
                        {
                            path: "suscripcionRecurrente.id",
                            equals: suscripcionRecurrenteFactory.getIdSuscripcion()
                        },
                        {
                            path: "estado.codigo",
                            equals: "comp_venta_agendado"
                        }
                    ]).value()
                ]).value();

            } else if ($state.is("app.suscripcionrecurrente.proceso.procesado.list")) {

                staticFilter = {};
                vm.formSuscripcion = true;
                vm.comprobanteProcesadoSuscripcion = true;
                staticFilter.search = filterFactory
                    .and([
                        {
                            path: "suscripcionRecurrente.id",
                            equals: suscripcionRecurrenteFactory.getIdSuscripcion()
                        },
                        {
                            path: "estado.codigo",
                            notEquals: "comp_venta_preparado"
                        },
                        {
                            path: "estado.codigo",
                            notEquals: "comp_venta_agendado"
                        }
                    ]).value();
            }

            if (staticFilter == undefined) {
                staticFilter = {};
                staticFilter.search =
                    filterFactory.and([
                        {
                            path: "estado.codigo",
                            equals: "comp_venta_emitido"
                        },
                        {
                            path: "cliente.nombre",
                            equals: "SIN NOMBRE"
                        },
                        {
                            path: "razonSocial",
                            notEquals: "SIN NOMBRE"
                        },
                        {
                            path: "numeroDocumento",
                            notEquals: "XXX"
                        },
                        {
                            path: "origen.codigo",
                            equals: "comp_venta_pos"
                        },
                        {
                            path: "id",
                            sortDesc: "true"
                        },
                        {
                            path: "id",
                            isNotNull: "true"
                        }
                    ]).value()
            }

            if (vm.fromOutside) {
                defaultColumnOrder.push("venta.codigo");
            }

            vm.options = {
                staticFilter: staticFilter,
                resource: "comprobanteventa",
                title: "Comprobantes de Venta Pendientes de Nominación",
                factory: ComprobanteVentaFactory,
                defaultColumnOrder: defaultColumnOrder,
                view: "ConsultaComprobanteVentaList",
                failedDeleteError: $filter("translate")("DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS"),
                columns: [
                    { data: "id", title: $filter("translate")("CODE"), visible: false },
                    { data: "cdc", title: $filter("translate")("CDC"), visible: false },
                    { data: "numero", title: $filter("translate")("NUMBER"), class: "dt-center" },
                    {
                        data: "fecha",
                        title: $filter("translate")("DATE"),
                        renderWith: "dateRender",
                        dateFormat: "DD/MM/YYYY",
                        class: "dt-center",
                        type: "date-range"
                    },
                    { data: "cliente.codigo", title: $filter("translate")("CODIGO_CLIENTE"), visible: false },
                    { data: "cliente.nombre", title: $filter("translate")("CLIENTE") },
                    {
                        data: "razonSocial",
                        title: "Cliente POS",
                        renderWith: "emptyRender",
                        visible: true
                    },
                    {
                        data: "numeroDocumento",
                        title: "Número Documento POS",
                        renderWith: "emptyRender",
                        visible: true
                    },
                    { data: "sucursalCliente.codigoSucursal", title: $filter("translate")("CODIGO_SUCURSAL"), renderWith: 'emptyRender', visible: false },
                    { data: "sucursalCliente.descripcion", title: $filter("translate")("DESCRIPCION_SUCURSAL"), renderWith: 'emptyRender' },
                    { data: "venta.planillaReparto.numero", title: $filter("translate")("NUMERO_REPARTO"), renderWith: 'emptyRender', visible: false },
                    { data: "vendedor.nombresyapellidos", title: $filter("translate")("VENDEDOR"), renderWith: 'emptyRender', searchable: false, visible: false },
                    { data: "pedidoVenta.id", title: $filter("translate")("PEDIDO_VENTA"), renderWith: "emptyRender", visible: false },
                    { data: "tipo.descripcion", title: $filter("translate")("TIPO_COMPROBANTE") },
                    { data: "cambio", title: $filter("translate")("TIPO_CAMBIO"), visible: false },
                    {
                        data: "comentario",
                        title: "Comentario",
                        class: "dt-right",
                        visible: false,
                        renderWith: "emptyRender"

                    },
                    {
                        data: "venta.codigo",
                        title: $filter("translate")("VENTA_REL"),
                        renderWith: "emptyRender",
                        class: "dt-left"
                        , visible: false

                    },
                    {
                        data: "estado.descripcion",
                        title: $filter("translate")("STATUS"),
                        renderWith: "emptyRender",
                        class: "dt-center",
                        type: "combo",
                        filterUrl: "tipos/filtro/estados_comprobante_venta"
                    },
                    {
                        data: "estadoRendicion.descripcion",
                        title: $filter("translate")("ESTADO_RENDICION"),
                        renderWith: "emptyRender",
                        class: "dt-center",
                        type: "combo",
                        filterUrl: "tipos/filtro/estados_rendicion",
                        visible: false
                    },
                    {
                        data: "fechaModificacionRendicion",
                        title: $filter("translate")("FECHA_MODIFICACION_RENDICION"),
                        renderWith: "dateRender",
                        dateFormat: "DD/MM/YYYY",
                        class: "dt-center",
                        type: "date-range"
                        , visible: false
                    },
                    {
                        data: "proyecto.nombre",
                        title: "Proyecto",
                        renderWith: "emptyRender",
                        visible: false
                    },
                    {
                        data: "timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
                        title: $filter("translate")("NUMERO_TIMBRADO"),
                        class: "dt-right",
                        renderWith: "emptyRender"

                    },
                    {
                        data: "timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.descripcion",
                        title: $filter("translate")("CLASE_TIMBRADO"),
                        class: "dt-right",
                        visible: false,
                        renderWith: "emptyRender"

                    },
                    {
                        data: "timbradoPuntoEmision.puntoEmisionVenta.numero",
                        title: $filter("translate")("PUNTO_EMISION"),
                        class: "dt-right",
                        renderWith: "emptyRender"

                    },
                    {
                        data: "montoTotal",
                        title: $filter("translate")("MONTO"),
                        type: "number-range",
                        render: monedaRender,
                        class: "dt-right"
                    },
                    {
                        data: "saldo",
                        title: $filter("translate")("SALDO_PENDIENTE"),
                        type: "number-range",
                        render: monedaRender,
                        class: "dt-right"
                    },
                    {
                        data: "listaPrecioVenta.descripcion",
                        title: $filter("translate")("LISTA_PRECIO_VENTA"),
                        renderWith: "emptyRender",
                        class: "dt-right"
                        , visible: false
                    },
                    {
                        data: "moneda.descripcion",
                        visible: false,
                        title: $filter("translate")("MONEDA"),
                        renderWith: "emptyRender"
                    },
                    {
                        data: "totalImpuestos",
                        title: $filter("translate")("TOTAL_IMPUESTO"),
                        class: "dt-right",
                        render: monedaRender,
                        type: "number-range",
                        visible: false
                    }, {
                        data: "origen.descripcion",
                        title: "Origen",
                        type: "combo",
                        render: renderOrigen,
                        class: "dt-center",
                        filterUrl: "tipos/filtro/origen_comprobante_venta"
                    },
                    {
                        data: "reimprimir",
                        title: "Reimprimir",
                        renderWith: "emptyRender",
                        visible: false
                    }
                ],
                hasOptions: true,
                hideAddMenu: true,
                extraMenuOptions:
                    [
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () {

                                if (vm.formSuscripcion) return false;

                                return true;
                            },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                                CsvFactory.csv("comprobanteventa", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () {

                                if (vm.formSuscripcion) return false;

                                return true;
                            },
                            'action': function () {
                                var filters;
                                if (vm.procesoVenta) {
                                    filters = createFilters(vm.options.getFilters(), vm.options.staticFilter);
                                } else {
                                    filters = createFilters(vm.options.getFilters(), vm.options.staticFilter);
                                }
                                ReportTicketFactory.ticket('listadoConsultaComprobanteVenta', filters, vm.options.tableAjaxParams,
                                    vm.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }
                    ],
                hideViewMenu: true,
                hideEditMenu: true,
                hideRemoveMenu: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc",
                extraRowOptions: [{
                    templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" +
                        $filter("translate")("EDIT") +
                        "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                    functionName: "editar",
                    customAttribute: ["estado", "venta", "id", "timbradoPuntoEmision", "origen"],
                    functionDef: function (atributos) {
                        var itemId = atributos[2];
                        var timbradoPuntoEmision = atributos[3];
                        ComprobanteVentaFactory.recuperarPorId(itemId).then(function (response) {
                            if (response.data.estado.codigo != "comp_venta_ingresado" && response.data.estado.codigo != "comp_venta_preparado" && response.data.estado.codigo != "comp_venta_agendado") {
                                var msg = $filter("translate")("ESTADO_INCORRECTO_COMP_VENTA", { estado: response.data.estado.descripcion });
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            } else if (vm.fromOutside) {
                                if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo != null) {
                                    if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente"
                                        && response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                        notify({
                                            message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                        });
                                        return;
                                    } else {
                                        if (response.data.autorizadoSolicitud == true && response.data.autorizadoSolicitudBloqueo == true) {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        } else if (response.autorizadoSolicitud == true) {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        }
                                    }
                                } else if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo == null) {
                                    if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                        notify({
                                            message: 'El Comprobante cuenta con Solicitud de Sobregiro con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                        });
                                        return;
                                    } else {
                                        if (response.data.autorizadoSolicitud == true) {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        }
                                    }
                                } else if (response.data.solicitudAutorizacion == null && response.data.solicitudAutorizacionBloqueo != null) {
                                    if (response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                        notify({
                                            message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                        });
                                        return;
                                    } else {
                                        if (response.data.autorizadoSolicitudBloqueo == true) {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        }
                                    }
                                }
                                $state.go("app.consultacomprobantesventa.edit", { id: itemId });

                            } else if (vm.formSuscripcion) {
                                $state.go("app.suscripcionrecurrente.proceso.comprobanteventa.edit", {
                                    id: itemId
                                });
                            } else {
                                var contador = 0;
                                var caja = null;
                                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                    if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                        caja = cajas;
                                    }
                                });
                                if (caja != null) {
                                    cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                        if (cajaDB != null) {
                                            _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                    contador++;
                                                }
                                            });
                                            if (contador == 1) {
                                                if (!vm.formSuscripcion && cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                    if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo != null) {
                                                        if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente"
                                                            && response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                            notify({
                                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                            });
                                                            return;
                                                        } else {
                                                            if (response.data.autorizadoSolicitud == true && response.data.autorizadoSolicitudBloqueo == true) {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            } else if (response.autorizadoSolicitud == true) {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            }
                                                        }
                                                    } else if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo == null) {
                                                        if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                            notify({
                                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                            });
                                                            return;
                                                        } else {
                                                            if (response.data.autorizadoSolicitud == true) {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            }
                                                        }
                                                    } else if (response.data.solicitudAutorizacion == null && response.data.solicitudAutorizacionBloqueo != null) {
                                                        if (response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                            notify({
                                                                message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                            });
                                                            return;
                                                        } else {
                                                            if (response.data.autorizadoSolicitudBloqueo == true) {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            }
                                                        }
                                                    }

                                                    $state.go("app.ventas.proceso.comprobanteventa.edit", {
                                                        id: itemId
                                                    });

                                                } else if (!vm.formSuscripcion) {
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                }
                                            } else {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    });
                                } else {
                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        });

                    },
                    conditionName: "canEditar",
                    conditionDef: function (atributos) {
                        var estado = atributos[0];
                        var venta = atributos[1];
                        var origen = atributos[4];

                        if ((vm.formSuscripcion && estado.codigo != 'comp_venta_preparado')) {
                            return false;
                        }

                        if (vm.formSuscripcion && AuthorizationService.hasPermission("update_plantilla") == false) {
                            return false;
                        }


                        if (vm.fromOutside && venta != undefined) {
                            return false;
                        }/* else if(vm.fromOutside && venta == undefined){
                            return true;
                        } */
                        if (modo != 1) {
                            return false;//(ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "edit", estado, null, null, null, null, venta, origen));
                        } else {
                            return false;
                        }
                    }
                },
                {
                    templateToRender: "<button title='" + $filter("translate")("AGENDAR_SUSCRIPCION_RECURRENTE") +
                        "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='agendarSuscripcion(<%=dataId%>)' ng-class='{  hidden : !canAgendarSuscripcion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-book'></span> </button>",
                    functionName: "agendarSuscripcion",
                    conditionName: "canAgendarSuscripcion",
                    customAttribute: "estado",
                    conditionDef: function (estado) {

                        return false;//(estado.codigo == 'comp_venta_preparado');

                    },
                    functionDef: function (itemId) {
                        ComprobanteVentaFactory.recuperarPorId(itemId).then(function (response) {
                            vm.comprobante = response.data;
                            suscripcionRecurrenteFactory.setIdSuscripcion(response.data.suscripcionRecurrente.id);
                            suscripcionRecurrenteFactory.setSuscripcion(response.data.suscripcionRecurrente);

                            TiposFactory.tiposAgendaSuscripcionEstado().then(function (data) {
                                var defaultEstado = _.filter(data.data, function (val) {
                                    for (var i = 0; i < data.data.length; i++) {
                                        if (val.codigo == 'activo') {
                                            return true;
                                        }
                                    }
                                    return false;
                                });

                                vm.agendaSuscripcionRecurrente.estado = defaultEstado[0];

                                TiposFactory.tiposAgendaSuscripcionAccion().then(function (data) {
                                    var defaultEstado = _.filter(data.data, function (val) {
                                        for (var i = 0; i < data.data.length; i++) {
                                            if (val.codigo == 'agendado') {
                                                return true;
                                            }
                                        }
                                        return false;
                                    });
                                    vm.agendaSuscripcionRecurrente.accion = defaultEstado[0];
                                });

                                TiposFactory.tiposAgendaSuscripcionIntervalo().then(function (data) {
                                    var defaultEstado = _.filter(data.data, function (val) {
                                        for (var i = 0; i < data.data.length; i++) {
                                            if (val.codigo == 'repetir') {
                                                return true;
                                            }
                                        }
                                        return false;
                                    });
                                    vm.agendaSuscripcionRecurrente.intervaloRepeticion = defaultEstado[0];
                                });
                            });

                            vm.generarFechasPlantilla();
                        });
                    }
                },
                {
                    templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                        $filter("translate")("DELETE") +
                        "' ng-click='borrar(<%=dataCustom%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                    functionName: "borrar",
                    customAttribute: ["estado", "venta", "id", "timbradoPuntoEmision", "origen"],
                    functionDef: function (atributos) {
                        var itemId = atributos[2];
                        var timbradoPuntoEmision = atributos[3];


                        var contador = 0;
                        var caja = null;

                        if (vm.fromOutside || vm.formSuscripcion) {
                            $scope.selectedItemId = itemId;
                            $scope.tituloModal = translations.DELETE_CONFIRMATION;
                            $scope.mensajeModal = translations.DELETE_WARNING;
                            $scope.modalInstanceBorrar1 = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                $scope.modalInstanceBorrar1.dismiss("cancel");
                            };

                            $scope.ok = function (itemId) {
                                //verificar si se puede o no borrar el comprobante
                                ComprobanteVentaFactory.recuperarPorId(itemId).then(function (response) {
                                    if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo != null) {
                                        if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente"
                                            && response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        } else {
                                            if (response.data.autorizadoSolicitud == true && response.data.autorizadoSolicitudBloqueo == true) {
                                                notify({
                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                });
                                                return;
                                            }
                                        }
                                    } else if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo == null) {
                                        if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Sobregiro con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        } else {
                                            if (response.data.autorizadoSolicitud == true) {
                                                notify({
                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                });
                                                return;
                                            }
                                        }
                                    } else if (response.data.solicitudAutorizacion == null && response.data.solicitudAutorizacionBloqueo != null) {
                                        if (response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                            notify({
                                                message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                            });
                                            return;
                                        } else {
                                            if (response.data.autorizadoSolicitudBloqueo == true) {
                                                notify({
                                                    message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                });
                                                return;
                                            }
                                        }
                                    }


                                    var model = ComprobanteVentaFactory.create({ id: itemId });
                                    ComprobanteVentaFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = vm.translations.DELETE_FAILED;
                                            $scope.mensajeModal =
                                                vm.translations.DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS;
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                            console.log("error al borrar: ");
                                            console.log(error);
                                        }

                                    );
                                });
                            };
                        } else {
                            _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                    caja = cajas;
                                }
                            });
                            if (caja != null) {
                                cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                    if (cajaDB != null) {
                                        _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                            if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                contador++;
                                            }
                                        });
                                        if (contador == 1) {
                                            if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                $scope.selectedItemId = itemId;
                                                $scope.tituloModal = translations.DELETE_CONFIRMATION;
                                                $scope.mensajeModal = translations.DELETE_WARNING;
                                                $scope.modalInstanceBorrar1 = $modal.open({
                                                    templateUrl: "views/directives/confirmation-modal.html",
                                                    scope: $scope
                                                });

                                                $scope.cancel = function () {
                                                    $scope.modalInstanceBorrar1.dismiss("cancel");
                                                };

                                                $scope.ok = function (itemId) {
                                                    ComprobanteVentaFactory.recuperarPorId(itemId).then(function (response) {
                                                        if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo != null) {
                                                            if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente"
                                                                && response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            } else {
                                                                if (response.data.autorizadoSolicitud == true && response.data.autorizadoSolicitudBloqueo == true) {
                                                                    notify({
                                                                        message: 'El Comprobante cuenta con Solicitud de Sobregiro y Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                    });
                                                                    return;
                                                                }
                                                            }
                                                        } else if (response.data.solicitudAutorizacion != null && response.data.solicitudAutorizacionBloqueo == null) {
                                                            if (response.data.solicitudAutorizacion.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Sobregiro con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            } else {
                                                                if (response.data.autorizadoSolicitud == true) {
                                                                    notify({
                                                                        message: 'El Comprobante cuenta con Solicitud de Sobregiro Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                    });
                                                                    return;
                                                                }
                                                            }
                                                        } else if (response.data.solicitudAutorizacion == null && response.data.solicitudAutorizacionBloqueo != null) {
                                                            if (response.data.solicitudAutorizacionBloqueo.estado.codigo === "estado_solicitud_autorizacion_pendiente") {
                                                                notify({
                                                                    message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias con estado Pendiente, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                });
                                                                return;
                                                            } else {
                                                                if (response.data.autorizadoSolicitudBloqueo == true) {
                                                                    notify({
                                                                        message: 'El Comprobante cuenta con Solicitud de Ignorar Vencimientos y Tolerancias Autorizado, no puede realizar la operación.', classes: 'alert-danger', position: 'right'
                                                                    });
                                                                    return;
                                                                }
                                                            }
                                                        }


                                                        var model = ComprobanteVentaFactory.create({ id: itemId });
                                                        ComprobanteVentaFactory.remove(model).then(
                                                            function () {
                                                                $scope.modalInstanceBorrar1.close(itemId);
                                                                $state.go($state.current, {}, { reload: true });
                                                            },
                                                            function (error) {
                                                                $scope.modalInstanceBorrar1.dismiss("cancel");
                                                                $scope.tituloModal = vm.translations.DELETE_FAILED;
                                                                $scope.mensajeModal =
                                                                    vm.translations.DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS;
                                                                var modalInstance = $modal.open({
                                                                    template: '<div class="modal-header">' +
                                                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                                        "</div>" +
                                                                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                                        '<div class="modal-footer">' +
                                                                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                                        "</div>",
                                                                    scope: $scope
                                                                });
                                                                $scope.cancel = function () {
                                                                    modalInstance.dismiss("cancel");
                                                                };
                                                                console.log("error al borrar: ");
                                                                console.log(error);
                                                            }

                                                        );
                                                    });
                                                };
                                            } else if (!vm.formSuscripcion) {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    } else {
                                        var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            } else {
                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    conditionName: "canBorrar",
                    conditionDef: function (atributos) {

                        var estado = atributos[0];
                        var venta = atributos[1];
                        var origen = atributos[4];

                        if (vm.formSuscripcion && estado.codigo === 'comp_venta_preparado'
                            && AuthorizationService.hasPermission("eliminarplantilla_suscripcionrecurrente") == true) {
                            return false;
                        }

                        if (vm.formSuscripcion) return false;

                        if (vm.fromOutside && venta != undefined) {
                            return false;
                        }

                        if (modo != 1) {
                            return (ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "delete", estado, null, null, null, null, venta, origen));
                        } else {
                            return false;
                        }

                    }
                },
                {
                    templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                        translations.VIEW +
                        "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                    functionName: "ver",
                    functionDef: function (itemId) {
                        if (vm.fromOutside || vm.comprobanteProcesadoSuscripcion) {
                            $state.go("app.consultacomprobantesventa.view", { id: itemId });
                        } else if (vm.procesoVenta) {
                            $state.go("app.ventas.proceso.comprobanteventa.view", {
                                id: itemId
                            });
                        } else if (vm.formSuscripcion) {
                            $state.go("app.suscripcionrecurrente.proceso.comprobanteventa.view", {
                                id: itemId
                            });
                        }
                    },
                    conditionName: "canVer",
                    conditionDef: function () {

                        if (vm.comprobanteProcesadoSuscripcion) return true;

                        if (vm.formSuscripcion && AuthorizationService.hasPermission("verplantilla_suscripcionrecurrente") == false)
                            return false;

                        return false;//ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "view")
                    }
                },
                {
                    templateToRender: "<button title='" +
                        $filter("translate")("CONFIRMAR_COMPROBANTE") +
                        "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataCustom%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
                    functionName: "aprobar",
                    conditionName: "canAprobar",
                    customAttribute: ["estado", "venta", "id", "timbradoPuntoEmision"],
                    conditionDef: function (atributos) {

                        if (vm.formSuscripcion) return false;

                        var estado = atributos[0];
                        var venta = atributos[1];

                        if (modo != 1) {
                            return false;//ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "confirm", estado, null, null, null, null, venta);
                        } else {
                            return false;
                        }
                    },
                    functionDef: function (atributos) {
                        console.log("bloqueo confirmar: ", $scope.bloqueoConfirmar);
                        if (!$scope.bloqueoConfirmar) {
                            $scope.bloqueoConfirmar = true;
                            var itemId = atributos[2];
                            var timbradoPuntoEmision = atributos[3];

                            var contador = 0;
                            var caja = null;
                            //fuera del flujo
                            if (vm.fromOutside) {
                                ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (response) {
                                    $scope.mensajeModal = $filter('translate')('CONFIRMAR_COMPROBANTE_Q');
                                    $scope.tituloModal = translations.CONFIRMAR_COMPROBANTE;

                                    if ((response.tipo.plantillaComprobanteFormatoEspecial != null && response.tipo.plantillaComprobanteFormatoEspecial.length != 0)) {
                                        $scope.config = "FORMATO_ESTANDAR";
                                        var modalInstance = $modal.open({
                                            templateUrl: 'qualita-components/ventas/views/comprobante/confirmation-modal.html',
                                            scope: $scope,
                                            backdrop: "static"
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss('cancel');
                                            $scope.bloqueoConfirmar = false;
                                        };

                                        $scope.ok = function (id, config) {
                                            ComprobanteVentaFactory.verificarExistenciaTimbrado(itemId).then(function (rr) {
                                                if (rr.data === true || rr.data === 'true') {
                                                    controlarVencimiento(response, itemId, modalInstance, config);
                                                }
                                            }, function (error) {
                                                var mensaje = "<span>";
                                                _.forEach(error.data, function (err) {
                                                    mensaje = mensaje + err.message + "<br/>";
                                                });
                                                mensaje = mensaje + "</span>";
                                                notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                            });
                                        };
                                    } else {
                                        var modalInstance = $modal.open({
                                            templateUrl: "views/directives/confirmation-modal.html",
                                            scope: $scope,
                                            backdrop: "static"
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss("cancel");
                                            $scope.bloqueoConfirmar = false;
                                        };

                                        $scope.ok = function (id) {
                                            ComprobanteVentaFactory.verificarExistenciaTimbrado(itemId).then(function (rr) {
                                                if (rr.data === true || rr.data === 'true') {
                                                    controlarVencimiento(response, itemId, modalInstance);
                                                }
                                            }, function (error) {
                                                var mensaje = "<span>";
                                                _.forEach(error.data, function (err) {
                                                    mensaje = mensaje + err.message + "<br/>";
                                                });
                                                mensaje = mensaje + "</span>";
                                                notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                            });
                                        };
                                    }
                                });
                            } else {
                                //dentro del flujo
                                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                    if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                        caja = cajas;
                                    }
                                });
                                if (caja != null) {
                                    cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                        if (cajaDB != null) {
                                            _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                    contador++;
                                                }
                                            });
                                            if (contador == 1) {
                                                if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                    ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (
                                                        response
                                                    ) {
                                                        var esCompGene = false;
                                                        _.forEach(venta.tipo.tipoVentaDetalles, function (det) {
                                                            if (det.componente.descripcion === "Comprobante" && det.realizaEgresoStock == true) {
                                                                esCompGene = true;
                                                            }
                                                        });
                                                        if (esCompGene == true && response.tipoTransaccion != null && !response.tipoTransaccion.codigo === "transaccion_anticipo") {
                                                            $scope.mensajeModal = "¿Está seguro de que desea confirmar el comprobante?. Una vez confirmado, se generaran los movimientos de stock correspondientes.";
                                                        } else {
                                                            $scope.mensajeModal = "¿Está seguro de que desea confirmar el comprobante?.";
                                                        }
                                                        $scope.selectedItemId = itemId;
                                                        $scope.tituloModal = translations.CONFIRMAR_COMPROBANTE;

                                                        if ((response.tipo.plantillaComprobanteFormatoEspecial != null && response.tipo.plantillaComprobanteFormatoEspecial.length != 0)) {
                                                            $scope.config = "FORMATO_ESTANDAR";
                                                            var modalInstance = $modal.open({
                                                                templateUrl: 'qualita-components/ventas/views/comprobante/confirmation-modal.html',
                                                                scope: $scope,
                                                                backdrop: "static"
                                                            });

                                                            $scope.cancel = function () {
                                                                modalInstance.dismiss('cancel');
                                                                $scope.bloqueoConfirmar = false;
                                                            };

                                                            $scope.ok = function (id, config) {
                                                                $('#' + $scope.selectedItemId).attr('disabled', 'disabled');
                                                                ComprobanteVentaFactory.verificarExistenciaTimbrado(itemId).then(function (rr) {
                                                                    if (rr.data === true || rr.data === 'true') {
                                                                        controlarVencimiento(response, itemId, modalInstance, config);
                                                                    }
                                                                }, function (error) {
                                                                    var mensaje = "<span>";
                                                                    _.forEach(error.data, function (err) {
                                                                        mensaje = mensaje + err.message + "<br/>";
                                                                    });
                                                                    mensaje = mensaje + "</span>";
                                                                    notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                                });
                                                            };
                                                        } else {
                                                            var modalInstance = $modal.open({
                                                                templateUrl: "views/directives/confirmation-modal.html",
                                                                scope: $scope,
                                                                backdrop: "static"
                                                            });

                                                            $scope.cancel = function () {
                                                                modalInstance.dismiss("cancel");
                                                                $scope.bloqueoConfirmar = false;
                                                            };

                                                            $scope.ok = function (id) {
                                                                $('#' + $scope.selectedItemId).attr('disabled', 'disabled');
                                                                ComprobanteVentaFactory.verificarExistenciaTimbrado(itemId).then(function (rr) {
                                                                    if (rr.data === true || rr.data === 'true') {
                                                                        controlarVencimiento(response, itemId, modalInstance, null);
                                                                    }
                                                                }, function (error) {
                                                                    var mensaje = "<span>";
                                                                    _.forEach(error.data, function (err) {
                                                                        mensaje = mensaje + err.message + "<br/>";
                                                                    });
                                                                    mensaje = mensaje + "</span>";
                                                                    notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                                });
                                                            };
                                                        }
                                                    });
                                                } else if (!vm.formSuscripcion) {
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    $scope.bloqueoConfirmar = false;
                                                }
                                            } else {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                $scope.bloqueoConfirmar = false;
                                            }
                                        } else {
                                            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            $scope.bloqueoConfirmar = false;
                                        }
                                    });
                                } else {
                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    $scope.bloqueoConfirmar = false;
                                }
                            }
                        }
                    }
                },
                {
                    templateToRender: "<button title='" +
                        $filter("translate")("REIMPRIMIR_COMPROBANTE") +
                        "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reimprimir(<%=dataCustom%>)' ng-class='{ hidden : !canReimprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-repeat'></span> </button>",
                    functionName: "reimprimir",
                    conditionName: "canReimprimir",
                    customAttribute: ["estado", "timbradoPuntoEmision", "id", "reimprimir"],
                    conditionDef: function (atributos) {

                        if (vm.formSuscripcion) return false;

                        var estado = atributos[0];

                        if (vm.comprobanteProcesadoSuscripcion) return false;//ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "reprint", estado);

                        var timbradoPuntoEmision = atributos[1];
                        if (timbradoPuntoEmision && timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                            return false;
                        } else {
                            return false;//(
                            //ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "reprint", estado)
                            //);
                        }
                    },
                    functionDef: function (atributos) {
                        var reimpreso = atributos[3];

                        (reimpreso === 'Sí') ? reimpreso = true : reimpreso = false;

                        if (!reimpreso || AuthorizationService.hasPermission("reimprimir_comprobanteventa") == true) {

                            var timbradoPuntoEmision = atributos[1];
                            var itemId = atributos[2];

                            var contador = 0;
                            var caja = null;
                            if (vm.fromOutside) {
                                ComprobanteVentaFactory.recuperarPorId(itemId).success(function (responsec) {
                                    ComprobanteVentaFactory.get(itemId).$promise.then(function (
                                        response
                                    ) {
                                        if ((responsec.tipo.plantillaComprobanteFormatoEspecial != null && responsec.tipo.plantillaComprobanteFormatoEspecial.length != 0)) {
                                            $scope.mensajeModal = translations.REIMPRIMIR_COMPROBANTE_Q;
                                            $scope.tituloModal = translations.REIMPRIMIR_COMPROBANTE;
                                            $scope.config = "FORMATO_ESTANDAR";
                                            var modalInstance = $modal.open({
                                                templateUrl: 'qualita-components/ventas/views/comprobante/confirmation-modal.html',
                                                scope: $scope
                                            });

                                            $scope.cancel = function () {
                                                modalInstance.dismiss('cancel');
                                            };

                                            $scope.ok = function (id, config) {
                                                var filtersArr = [];
                                                filtersArr.push("idcomprobante=" + itemId);
                                                filtersArr.push("config=" + config);
                                                filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                modalInstance.close();
                                                $state.go($state.current, {}, { reload: true });
                                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                    "comprobanteventa",
                                                    "pdf",
                                                    filtersArr
                                                );
                                                $window.open(vm.pdfDownloadURL, "_blank");
                                                $state.go($state.current, {}, { reload: true });
                                            };
                                        } else {
                                            $scope.mensajeModal = translations.REIMPRIMIR_COMPROBANTE_Q;
                                            $scope.tituloModal = translations.REIMPRIMIR_COMPROBANTE;
                                            var modalInstance = $modal.open({
                                                templateUrl: "views/directives/confirmation-modal.html",
                                                scope: $scope
                                            });

                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };

                                            $scope.ok = function (id) {
                                                var filtersArr = [];
                                                filtersArr.push("idcomprobante=" + itemId);
                                                filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                modalInstance.close();
                                                $state.go($state.current, {}, { reload: true });
                                                vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                    "comprobanteventa",
                                                    "pdf",
                                                    filtersArr
                                                );
                                                $window.open(vm.pdfDownloadURL, "_blank");
                                                $state.go($state.current, {}, { reload: true });
                                            }
                                        }
                                    });
                                });
                            } else {
                                _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                    if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                        caja = cajas;
                                    }
                                });
                                if (caja != null) {
                                    cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                        if (cajaDB != null) {
                                            _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                                if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                    contador++;
                                                }
                                            });
                                            if (contador == 1) {
                                                if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                    ComprobanteVentaFactory.recuperarPorId(itemId).success(function (responsec) {
                                                        ComprobanteVentaFactory.get(itemId).$promise.then(function (
                                                            response
                                                        ) {
                                                            if ((responsec.tipo.plantillaComprobanteFormatoEspecial != null && responsec.tipo.plantillaComprobanteFormatoEspecial.length != 0)) {
                                                                $scope.mensajeModal = translations.REIMPRIMIR_COMPROBANTE_Q;
                                                                $scope.tituloModal = translations.REIMPRIMIR_COMPROBANTE;
                                                                $scope.config = "FORMATO_ESTANDAR";
                                                                var modalInstance = $modal.open({
                                                                    templateUrl: 'qualita-components/ventas/views/comprobante/confirmation-modal.html',
                                                                    scope: $scope
                                                                });

                                                                $scope.cancel = function () {
                                                                    modalInstance.dismiss('cancel');
                                                                };

                                                                $scope.ok = function (id, config) {
                                                                    var filtersArr = [];
                                                                    filtersArr.push("idcomprobante=" + itemId);
                                                                    filtersArr.push("config=" + config);
                                                                    filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                                    modalInstance.close();
                                                                    $state.go($state.current, {}, { reload: true });
                                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                                        "comprobanteventa",
                                                                        "pdf",
                                                                        filtersArr
                                                                    );
                                                                    $window.open(vm.pdfDownloadURL, "_blank");
                                                                    $state.go($state.current, {}, { reload: true });
                                                                };
                                                            } else {
                                                                $scope.mensajeModal = translations.REIMPRIMIR_COMPROBANTE_Q;
                                                                $scope.tituloModal = translations.REIMPRIMIR_COMPROBANTE;
                                                                var modalInstance = $modal.open({
                                                                    templateUrl: "views/directives/confirmation-modal.html",
                                                                    scope: $scope
                                                                });

                                                                $scope.cancel = function () {
                                                                    modalInstance.dismiss("cancel");
                                                                };

                                                                $scope.ok = function (id) {
                                                                    var filtersArr = [];
                                                                    filtersArr.push("idcomprobante=" + itemId);
                                                                    filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                                                                    modalInstance.close();
                                                                    $state.go($state.current, {}, { reload: true });
                                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                                        "comprobanteventa",
                                                                        "pdf",
                                                                        filtersArr
                                                                    );
                                                                    $window.open(vm.pdfDownloadURL, "_blank");
                                                                    $state.go($state.current, {}, { reload: true });
                                                                }
                                                            }
                                                        });
                                                    });
                                                } else if (!vm.formSuscripcion) {
                                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                }
                                            } else {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    });
                                } else {
                                    var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        } else if (reimpreso && AuthorizationService.hasPermission("reimprimir_comprobanteventa") == false) {
                            var msg = 'El comprobante ya ha sido impreso';
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        } else {
                            var msg = 'No posee permiso para realizar la reimpresión de comprobantes';
                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                        }
                    }
                },
                {
                    templateToRender: "<button title='" + $filter("translate")("ENVIAR_COMPROBANTE_VIRTUAL") +
                        "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='enviarVirtual(<%=dataId%>, <%=dataCustom%>)' ng-class='{ hidden : !enviarComprobante(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-send'></span> </button>",
                    functionName: "enviarVirtual",
                    conditionName: "enviarComprobante",
                    customAttribute: ["estado", "timbradoPuntoEmision", "tipo", "cdcNuevo"],
                    conditionDef: function (atributos) {

                        if (vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        var estado = atributos[0];
                        var timbradoPuntoEmision = atributos[1];

                        if (timbradoPuntoEmision && timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                            if (estado.codigo === "comp_venta_ingresado") {
                                return false;
                            } else if (estado.codigo === "comp_venta_anulado") {
                                return false;
                            } else if (estado.codigo === "comp_venta_emitido") {
                                if (vm.valorTipo === "si") {
                                    return true;
                                } else {
                                    return false;
                                }
                            } else if (estado.codigo === "comp_venta_enviado_sifen") {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    },
                    functionDef: function (itemId, atributos) {

                        var tipoComprobante = atributos[2];
                        if (tipoComprobante.clase.codigo === "nota_credito") {
                            $scope.mensajeModal = translations.ENVIO_NOTA_CREDITO_VIRTUAL_Q;
                            $scope.tituloModal = translations.ENVIO_NOTA_CREDITO_VIRTUAL;
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss("cancel");
                            };

                            $scope.ok = function (id) {
                                ComprobanteVentaFactory.generarJsonVirtual(itemId).then(function (data) {
                                    if (data === "" || data === null) {
                                        var mensaje = "<span>";
                                        mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                        console.log(mensaje);
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-danger",
                                            position: "right",
                                            templateUrl: ""
                                        });
                                    } else {
                                        console.log("*** La Respuesta *** --> ", data);
                                        var mensaje = "<span>" + $filter("translate")("DATOS_ENVIADOS_SIFEN") + "</span>";
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-success",
                                            position: "right",
                                            templateUrl: ""
                                        });

                                        $state.go($state.current, {}, { reload: true });
                                    }
                                }, function (error) {
                                    console.log(error);
                                    var mensaje = "<span>";
                                    _.forEach(error.data, function (err) {
                                        mensaje = mensaje + err.message + "<br/>";
                                    });
                                    mensaje = mensaje + "</span>";
                                    notify({
                                        messageTemplate: mensaje,
                                        classes: "alert-danger",
                                        position: "right",
                                        templateUrl: ""
                                    });
                                });
                            }
                        } else {
                            var nuevo = atributos[3];
                            if (nuevo != null) {
                                $scope.mensajeModal = 'Desea generar un nuevo CDC??';
                                $scope.tituloModal = 'Generación de CDC';
                                var modalInstance = $modal.open({
                                    templateUrl: "views/directives/confirmation-modal-virtual.html",
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    ComprobanteVentaFactory.generarJsonVirtual(itemId, false).then(function (data) {
                                        if (data === "" || data === null) {
                                            var mensaje = "<span>";
                                            mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                            console.log(mensaje);
                                            notify({
                                                messageTemplate: mensaje,
                                                classes: "alert-danger",
                                                position: "right",
                                                templateUrl: ""
                                            });
                                        } else {
                                            console.log("*** La Respuesta *** --> ", data);
                                            var mensaje = "<span>" + $filter("translate")("DATOS_ENVIADOS_SIFEN") + "</span>";
                                            notify({
                                                messageTemplate: mensaje,
                                                classes: "alert-success",
                                                position: "right",
                                                templateUrl: ""
                                            });

                                            $state.go($state.current, {}, { reload: true });
                                        }
                                    }, function (error) {
                                        console.log(error);
                                        var mensaje = "<span>";
                                        _.forEach(error.data, function (err) {
                                            mensaje = mensaje + err.message + "<br/>";
                                        });
                                        mensaje = mensaje + "</span>";
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-danger",
                                            position: "right",
                                            templateUrl: ""
                                        });
                                    });
                                };

                                $scope.ok = function (id) {
                                    ComprobanteVentaFactory.generarJsonVirtual(itemId, true).then(function (data) {
                                        if (data === "" || data === null) {
                                            console.log("1")
                                            var mensaje = "<span>";
                                            mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                            console.log(mensaje);
                                            notify({
                                                messageTemplate: mensaje,
                                                classes: "alert-danger",
                                                position: "right",
                                                templateUrl: ""
                                            });
                                        } else {
                                            console.log("2")

                                            console.log("*** La Respuesta *** --> ", data);
                                            var mensaje = "<span>" + $filter("translate")("DATOS_ENVIADOS_SIFEN") + "</span>";
                                            notify({
                                                messageTemplate: mensaje,
                                                classes: "alert-success",
                                                position: "right",
                                                templateUrl: ""
                                            });

                                            $state.go($state.current, {}, { reload: true });
                                        }
                                    }, function (error) {
                                        console.log("3")

                                        console.log(error);
                                        var mensaje = "<span>";
                                        _.forEach(error.data, function (err) {
                                            mensaje = mensaje + err.message + "<br/>";
                                        });
                                        mensaje = mensaje + "</span>";
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-danger",
                                            position: "right",
                                            templateUrl: ""
                                        });
                                    });
                                }
                            } else {
                                ComprobanteVentaFactory.generarJsonVirtual(itemId, true).then(function (data) {
                                    if (data === "" || data === null) {
                                        var mensaje = "<span>";
                                        mensaje += $filter("translate")("ERROR_ENVIO_SIFEN") + "</span>";
                                        console.log(mensaje);
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-danger",
                                            position: "right",
                                            templateUrl: ""
                                        });
                                    } else {

                                        console.log("*** La Respuesta *** --> ", data);
                                        var mensaje = "<span>" + $filter("translate")("DATOS_ENVIADOS_SIFEN") + "</span>";
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-success",
                                            position: "right",
                                            templateUrl: ""
                                        });

                                        $state.go($state.current, {}, { reload: true });
                                    }
                                }, function (error) {

                                    console.log(error);
                                    var mensaje = "<span>";
                                    _.forEach(error.data, function (err) {
                                        mensaje = mensaje + err.message + "<br/>";
                                    });
                                    mensaje = mensaje + "</span>";
                                    notify({
                                        messageTemplate: mensaje,
                                        classes: "alert-danger",
                                        position: "right",
                                        templateUrl: ""
                                    });
                                });
                            }
                        }
                    }
                },
                {
                    templateToRender: "<button title='" + $filter("translate")("DESCARGAR KuDE") +
                        "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='descargarKuDE(<%=dataCustom%>)' ng-class='{ hidden : !condicionDescarga(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-save-file'></span> </button>",
                    functionName: "descargarKuDE",
                    conditionName: "condicionDescarga",
                    customAttribute: ["estado", "cdc", "impreso", "id"],
                    conditionDef: function (atributos) {

                        if (vm.formSuscripcion) return false;

                        var estado = atributos[0];
                        var impreso = atributos[2];

                        if ((estado.codigo === "comp_venta_aprobado_sifen" || estado.codigo === "comp_venta_enviado_sifen") && impreso === "No") {
                            return false;
                        } else {
                            return false;
                        }

                    },
                    functionDef: function (atributos) {
                        var estado = atributos[0];
                        var cdc = atributos[1];
                        var idItem = atributos[3];
                        var usuario = $rootScope.AuthParams.accesoSistema.usuario.username;
                        ParametrosFactory.getByCodigo('imprimir_kude_sin_aprobar').then(function (response) {
                            if (response.valorTipo.codigo === "si" && estado.codigo === "comp_venta_enviado_sifen") {
                                ComprobanteVentaFactory.get(idItem, "ComprobanteVentaList").$promise.then(function (response) {
                                    ComprobanteVentaFactory.actualizarEstadoImpresion(response.id);
                                    $window.open(CONFIGURATION.serverFE + cdc + "&usuarioemision=" + usuario, "_blank");
                                    $state.go($state.current, {}, { reload: true });
                                })
                            } else if (response.valorTipo.codigo === "no" && estado.codigo === "comp_venta_enviado_sifen") {
                                notify({ message: 'No cuenta con el parámetro de imprimir kude sin aprobar', classes: "alert-danger", position: "right" });
                            } else if (estado.codigo === "comp_venta_aprobado_sifen") {
                                ComprobanteVentaFactory.get(idItem, "ComprobanteVentaList").$promise.then(function (response) {
                                    ComprobanteVentaFactory.actualizarEstadoImpresion(response.id);
                                    $window.open(CONFIGURATION.serverFE + cdc + "&usuarioemision=" + usuario, "_blank");
                                    $state.go($state.current, {}, { reload: true });
                                })
                            }
                        });

                    }
                },
                {
                    templateToRender: "<button title='" + $filter("translate")("IMPRIMIR_TICKET") +
                        "' class='btn btn-default btn-dt' style='margin-right: 5px;' ng-click='imprimirTicket(<%=dataCustom%>)' ng-class='{ hidden : !condicionImprimirTicket(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-print'></span> </button>",
                    functionName: "imprimirTicket",
                    conditionName: "condicionImprimirTicket",
                    customAttribute: ["estado", "cdc", "impreso", "id"],
                    conditionDef: function (atributos) {

                        var estado = atributos[0];

                        if (estado.codigo === "comp_venta_aprobado_sifen") {
                            return false;
                        } else {
                            return false;
                        }

                    },
                    functionDef: function (atributos) {
                        var estado = atributos[0];
                        var cdc = atributos[1];
                        var idItem = atributos[3];
                        ParametrosFactory.getByCodigo('habilitar_impresion_tickets').then(function (response) {
                            if (response.valorTipo.codigo === "si") {
                                notify({ message: 'Impresión de Ticket en proceso', classes: "alert-success", position: "right" });
                            } else {
                                notify({ message: 'No cuenta con el parámetro de Imprimir Ticket', classes: "alert-danger", position: "right" });
                            }
                        });

                    }
                },
                {
                    templateToRender: "<button title='" + $filter("translate")("REIMPRIMIR KuDE") +
                        "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reimprimirKuDE(<%=dataCustom%>)' ng-class='{ hidden : !condicionDescargaReimpresion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-repeat'></span> </button>",
                    functionName: "reimprimirKuDE",
                    conditionName: "condicionDescargaReimpresion",
                    customAttribute: ["estado", "cdc", "impreso"],
                    conditionDef: function (atributos) {

                        if (vm.formSuscripcion) return false;

                        var estado = atributos[0];
                        var impreso = atributos[2];

                        if ((estado.codigo === "comp_venta_aprobado_sifen" || estado.codigo === "comp_venta_enviado_sifen") && impreso === "Sí") {
                            return false;
                        } else {
                            return false;
                        }
                    },
                    functionDef: function (atributos) {
                        var estado = atributos[0];
                        var cdc = atributos[1];
                        var usuario = $rootScope.AuthParams.accesoSistema.usuario.username;
                        ParametrosFactory.getByCodigo('imprimir_kude_sin_aprobar').then(function (response) {
                            if (response.valorTipo.codigo === "si" && estado.codigo === "comp_venta_enviado_sifen") {
                                $window.open(CONFIGURATION.serverFE + cdc + "&reimpreso=true" + "&usuarioemision=" + usuario, "_blank");
                                $state.go($state.current, {}, { reload: true });
                            } else if (response.valorTipo.codigo === "no" && estado.codigo === "comp_venta_enviado_sifen") {
                                notify({ message: 'No cuenta con el parámetro de imprimir kude sin aprobar', classes: "alert-danger", position: "right" });
                            } else if (estado.codigo === "comp_venta_aprobado_sifen") {
                                $window.open(CONFIGURATION.serverFE + cdc + "&reimpreso=true" + "&usuarioemision=" + usuario, "_blank");
                                $state.go($state.current, {}, { reload: true });
                            }
                        });
                    }
                },
                {
                    templateToRender: "<button title='" +
                        $filter("translate")("INUTILIZAR_COMPROBANTE") +
                        "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='inutilizar(<%=dataCustom%>)' ng-class='{ hidden : !condicionInutilizar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-floppy-remove'></span> </button>",
                    functionName: "inutilizar",
                    conditionName: "condicionInutilizar",
                    customAttribute: ["estado", "venta", "id", "timbradoPuntoEmision", "hasComprobanteAplicaciones", "hasAplicaciones", "origen"],
                    conditionDef: function (atributos) {

                        if (vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        var estado = atributos[0];
                        var venta = atributos[1];
                        var timbrado = atributos[3];

                        var hasComprobanteAplicaciones = atributos[4];
                        var hasAplicaciones = atributos[5];
                        var origen = atributos[6];
                        if (vm.valorTipo == "si" && estado.codigo === "comp_venta_emitido" &&
                            timbrado.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual" &&
                            ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "inutilizar", estado, null, null, null, null, venta, origen)) {
                            return false;
                        } else if (vm.valorTipo == "si" && estado.codigo === "comp_venta_anulado" && origen.codigo == "comp_venta_pos" &&
                            timbrado.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual" &&
                            ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "inutilizar", estado, null, null, null, null, venta, origen)) {
                            return false;
                        } else {
                            return false;
                        }
                        //return hasAplicaciones == "No" && hasComprobanteAplicaciones == "No" &&

                    },
                    functionDef: function (atributos) {

                        var itemId = atributos[2];
                        var timbradoPuntoEmision = atributos[3];

                        var contador = 0;
                        var caja = null;

                        if (vm.fromOutside) {
                            ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (
                                response
                            ) {
                                console.log("AQUI FECHA: ", response.fecha);
                                checkPeriodoAdministrativo(response.fecha).then(function (resultado) {
                                    if (resultado == true) {
                                        $scope.mensajeModal = $filter("translate")("INUTILIZAR_COMPROBANTE_Q");
                                        $scope.tituloModal = $filter("translate")("INUTILIZAR_COMPROBANTE");
                                        var modalInstance = $modal.open({
                                            templateUrl: "views/directives/confirmation-modal.html",
                                            scope: $scope
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss("cancel");
                                        };

                                        $scope.ok = function (id) {
                                            ComprobanteVentaFactory.inutilizar(itemId).then(

                                                function (response) {
                                                    if (venta != undefined) {
                                                        var ordenRemision = 0, ordenComprobante = 0;

                                                        var remisionesEmitidas = _.find(venta.remisiones, function (remision) {
                                                            if (remision.estado.codigo === "estados_remision_emitido" || remision.estado.codigo === "estados_remision_aprobado_sifen") return remision;
                                                        });
                                                        if (remisionesEmitidas) {
                                                            var tipoRemision = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                return tipo.componente.codigo === "comp_tipo_venta_remision";
                                                            })
                                                            ordenRemision = tipoRemision.orden;
                                                        }

                                                        var comprobantesEmitidos = _.find(venta.comprobantes, function (comp) {
                                                            if ((comp.estado.codigo === "comp_venta_emitido" || comp.estado.codigo === "comp_venta_aprobado_sifen" || comp.estado.codigo === "comp_venta_enviado_sifen") && comp.id !== itemId) return comp;
                                                        });

                                                        if (comprobantesEmitidos) {
                                                            var tipoComprobante = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                return tipo.componente.codigo === "comp_tipo_venta_comprobante";
                                                            })
                                                            ordenComprobante = tipoComprobante.orden;
                                                        }

                                                        if (remisionesEmitidas) {
                                                            if (ordenRemision > ordenComprobante) {
                                                                VentaFactory.actualizarEstado('venta_con_remision', venta.id).success(function (data) {
                                                                    modalInstance.close();
                                                                    $state.go($state.current, {}, { reload: true });
                                                                });
                                                            } else {
                                                                VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                    modalInstance.close();
                                                                    $state.go($state.current, {}, { reload: true });
                                                                });
                                                            }
                                                        } else if (comprobantesEmitidos) {
                                                            VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                modalInstance.close();
                                                                $state.go($state.current, {}, { reload: true });
                                                            });
                                                        } else if (venta.pedidoVenta || venta.planillaReparto) {
                                                            VentaFactory.actualizarEstado('venta_con_pv', venta.id).success(function (data) {
                                                                modalInstance.close();
                                                                $state.go($state.current, {}, { reload: true });
                                                            });
                                                        } else {
                                                            VentaFactory.actualizarEstado('venta_abierta', venta.id).success(function (data) {
                                                                modalInstance.close();
                                                                $state.go($state.current, {}, { reload: true });
                                                            });
                                                        }
                                                    }
                                                    modalInstance.close();
                                                    $state.go($state.current, {}, { reload: true });
                                                },
                                                function (error) {
                                                    console.log(error);
                                                    var mensaje = "<span>";
                                                    _.forEach(error.data, function (err) {
                                                        mensaje = mensaje + err.message + "<br/>";
                                                    });
                                                    mensaje = mensaje + "</span>";
                                                    notify({
                                                        messageTemplate: mensaje,
                                                        classes: "alert-danger",
                                                        position: "right",
                                                        templateUrl: ""
                                                    });
                                                }
                                            );
                                        };
                                    } else if (resultado == false) {
                                        var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            });
                        } else {
                            _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                    caja = cajas;
                                }
                            });
                            if (caja != null) {
                                cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                    if (cajaDB != null) {
                                        _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                            if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                contador++;
                                            }
                                        });
                                        if (contador == 1) {
                                            if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (
                                                    response
                                                ) {
                                                    console.log("AQUI FECHA: ", response.fecha);
                                                    checkPeriodoAdministrativo(response.fecha).then(function (resultado) {
                                                        if (resultado == true) {
                                                            $scope.mensajeModal = $filter("translate")("INUTILIZAR_COMPROBANTE_Q");
                                                            $scope.tituloModal = $filter("translate")("INUTILIZAR_COMPROBANTE");
                                                            var modalInstance = $modal.open({
                                                                templateUrl: "views/directives/confirmation-modal.html",
                                                                scope: $scope
                                                            });

                                                            $scope.cancel = function () {
                                                                modalInstance.dismiss("cancel");
                                                            };

                                                            $scope.ok = function (id) {
                                                                ComprobanteVentaFactory.inutilizar(itemId).then(

                                                                    function (response) {
                                                                        if (venta != undefined) {
                                                                            var ordenRemision = 0, ordenComprobante = 0;

                                                                            var remisionesEmitidas = _.find(venta.remisiones, function (remision) {
                                                                                if (remision.estado.codigo === "estados_remision_emitido" || remision.estado.codigo === "estados_remision_aprobado_sifen") return remision;
                                                                            });
                                                                            if (remisionesEmitidas) {
                                                                                var tipoRemision = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                                    return tipo.componente.codigo === "comp_tipo_venta_remision";
                                                                                })
                                                                                ordenRemision = tipoRemision.orden;
                                                                            }

                                                                            var comprobantesEmitidos = _.find(venta.comprobantes, function (comp) {
                                                                                if ((comp.estado.codigo === "comp_venta_emitido" || comp.estado.codigo === "comp_venta_aprobado_sifen" || comp.estado.codigo === "comp_venta_enviado_sifen") && comp.id !== itemId) return comp;
                                                                            });

                                                                            if (comprobantesEmitidos) {
                                                                                var tipoComprobante = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                                    return tipo.componente.codigo === "comp_tipo_venta_comprobante";
                                                                                })
                                                                                ordenComprobante = tipoComprobante.orden;
                                                                            }

                                                                            if (remisionesEmitidas) {
                                                                                if (ordenRemision > ordenComprobante) {
                                                                                    VentaFactory.actualizarEstado('venta_con_remision', venta.id).success(function (data) {
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else {
                                                                                    VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                }
                                                                            } else if (comprobantesEmitidos) {
                                                                                VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            } else if (venta.pedidoVenta || venta.planillaReparto) {
                                                                                VentaFactory.actualizarEstado('venta_con_pv', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            } else {
                                                                                VentaFactory.actualizarEstado('venta_abierta', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            }

                                                                        }
                                                                        modalInstance.close();
                                                                        $state.go($state.current, {}, { reload: true });
                                                                    },
                                                                    function (error) {
                                                                        console.log(error);
                                                                        var mensaje = "<span>";
                                                                        _.forEach(error.data, function (err) {
                                                                            mensaje = mensaje + err.message + "<br/>";
                                                                        });
                                                                        mensaje = mensaje + "</span>";
                                                                        notify({
                                                                            messageTemplate: mensaje,
                                                                            classes: "alert-danger",
                                                                            position: "right",
                                                                            templateUrl: ""
                                                                        });
                                                                    }
                                                                );
                                                            };
                                                        } else if (resultado == false) {
                                                            var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                        }
                                                    });
                                                });
                                            } else if (!vm.formSuscripcion) {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    } else {
                                        var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            } else {
                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    }
                },
                {
                    templateToRender: "<button title='" +
                        $filter("translate")("ANULAR_COMPROBANTE") +
                        "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataCustom%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                    functionName: "anular",
                    conditionName: "canAnular",
                    customAttribute: ["estado", "venta", "canAnular", "hasAplicaciones", "hasComprobanteAplicaciones", "id", "timbradoPuntoEmision", "origen"],
                    conditionDef: function (atributos) {

                        if (vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        if (vm.comprobanteProcesadoSuscripcion) vm.fromOutside = true;

                        var estado = atributos[0];
                        var venta = atributos[1];
                        var canAnular = atributos[2];
                        var hasAplicaciones = atributos[3];
                        var hasComprobanteAplicaciones = atributos[4];
                        if (atributos[6] != null) {
                            var timbrado = atributos[6].timbradoEmpresa.claseTimbrado;
                        }
                        var origen = atributos[7];
                        //console.log(hasAplicaciones);
                        //console.log(hasComprobanteAplicaciones);

                        if (modo != 1) {
                            return false;//ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "anular", estado, null, null, null, null, venta, origen)
                            //&& canAnular !== "No" && hasAplicaciones == "No" && hasComprobanteAplicaciones == "No" &&
                            // ((timbrado.codigo == "timbrado_virtual" && estado.codigo == "comp_venta_aprobado_sifen") ||
                            // (timbrado.codigo != "timbrado_virtual" && estado.codigo == "comp_venta_emitido"));
                        } else {
                            return false;
                        }
                    },
                    functionDef: function (atributos) {

                        var itemId = atributos[5];
                        var timbradoPuntoEmision = atributos[6];

                        var contador = 0;
                        var caja = null;

                        if (vm.fromOutside) {
                            ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (
                                response
                            ) {
                                var numeroComprobante = response.numero;
                                console.log("AQUI FECHA: ", response.fecha);
                                checkPeriodoAdministrativo(response.fecha).then(function (resultado) {
                                    if (resultado == true) {
                                        $scope.mensajeModal = $filter("translate")("ANULAR_COMPROBANTE_Q");
                                        $scope.tituloModal = $filter("translate")("ANULAR_COMPROBANTE");
                                        var modalInstance = $modal.open({
                                            templateUrl: "views/directives/anular-op-modal.html",
                                            scope: $scope
                                        });

                                        $scope.cancel = function () {
                                            modalInstance.dismiss("cancel");
                                        };
                                        $scope.ok = function (id, clonar) {

                                            ComprobanteVentaFactory.getEstaAsentado(itemId).then(
                                                function (response) {
                                                    if (response.data == "true") {
                                                        var msg = $filter("translate")("ERROR_ESTA_ASENTADO", { numero: numeroComprobante });
                                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                    } else {

                                                        ComprobanteVentaFactory.anular(itemId, clonar).then(

                                                            function (response) {
                                                                if (venta != undefined) {
                                                                    var ordenRemision = 0, ordenComprobante = 0;

                                                                    var remisionesEmitidas = _.find(venta.remisiones, function (remision) {
                                                                        if (remision.estado.codigo === "estados_remision_emitido" || remision.estado.codigo === "estados_remision_aprobado_sifen") return remision;
                                                                    });
                                                                    if (remisionesEmitidas) {
                                                                        var tipoRemision = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                            return tipo.componente.codigo === "comp_tipo_venta_remision";
                                                                        })
                                                                        ordenRemision = tipoRemision.orden;
                                                                    }

                                                                    var comprobantesEmitidos = _.find(venta.comprobantes, function (comp) {
                                                                        if ((comp.estado.codigo === "comp_venta_emitido" || comp.estado.codigo === "comp_venta_aprobado_sifen" || comp.estado.codigo === "comp_venta_enviado_sifen") && comp.id !== itemId) return comp;
                                                                    });

                                                                    if (comprobantesEmitidos) {
                                                                        var tipoComprobante = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                            return tipo.componente.codigo === "comp_tipo_venta_comprobante";
                                                                        })
                                                                        ordenComprobante = tipoComprobante.orden;
                                                                    }

                                                                    if (remisionesEmitidas) {
                                                                        if (ordenRemision > ordenComprobante) {
                                                                            VentaFactory.actualizarEstado('venta_con_remision', venta.id).success(function (data) {
                                                                                modalInstance.close();
                                                                                $state.go($state.current, {}, { reload: true });
                                                                            });
                                                                        } else {
                                                                            VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                modalInstance.close();
                                                                                $state.go($state.current, {}, { reload: true });
                                                                            });
                                                                        }
                                                                    } else if (comprobantesEmitidos) {
                                                                        VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                            modalInstance.close();
                                                                            $state.go($state.current, {}, { reload: true });
                                                                        });
                                                                    } else if (venta.pedidoVenta || venta.planillaReparto) {
                                                                        VentaFactory.actualizarEstado('venta_con_pv', venta.id).success(function (data) {
                                                                            modalInstance.close();
                                                                            $state.go($state.current, {}, { reload: true });
                                                                        });
                                                                    } else {
                                                                        VentaFactory.actualizarEstado('venta_abierta', venta.id).success(function (data) {
                                                                            modalInstance.close();
                                                                            $state.go($state.current, {}, { reload: true });
                                                                        });
                                                                    }
                                                                }
                                                                modalInstance.close();
                                                                $state.go($state.current, {}, { reload: true });
                                                            },
                                                            function (error) {
                                                                console.log(error);
                                                                var mensaje = "<span>";
                                                                _.forEach(error.data, function (err) {
                                                                    mensaje = mensaje + err.message + "<br/>";
                                                                });
                                                                mensaje = mensaje + "</span>";
                                                                notify({
                                                                    messageTemplate: mensaje,
                                                                    classes: "alert-danger",
                                                                    position: "right",
                                                                    templateUrl: ""
                                                                });
                                                            }
                                                        );
                                                    }
                                                }
                                            );
                                        };
                                    } else if (resultado == false) {
                                        var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            });
                        } else {
                            _.forEach($rootScope.AuthParams.accesoSistema.cajas, function (cajas) {
                                if (cajas.puntoEmision.puntoVenta.sucursal.id == $rootScope.AuthParams.locacionSeleccionada.sucursal.id) {
                                    caja = cajas;
                                }
                            });
                            if (caja != null) {
                                cajaFactory.getCaja(caja.id, "CajaForm").$promise.then(function (cajaDB) {
                                    if (cajaDB != null) {
                                        _.forEach(cajaDB.usuariosAsociados, function (usuarios) {
                                            if (usuarios.usuario.id == $rootScope.AuthParams.accesoSistema.usuario.id) {
                                                contador++;
                                            }
                                        });
                                        if (contador == 1) {
                                            if (cajaDB.puntoEmision.id == timbradoPuntoEmision.puntoEmisionVenta.id) {
                                                ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (
                                                    response
                                                ) {
                                                    console.log("AQUI FECHA: ", response.fecha);
                                                    checkPeriodoAdministrativo(response.fecha).then(function (resultado) {
                                                        if (resultado == true) {
                                                            $scope.mensajeModal = $filter("translate")("ANULAR_COMPROBANTE_Q");
                                                            $scope.tituloModal = $filter("translate")("ANULAR_COMPROBANTE");
                                                            var modalInstance = $modal.open({
                                                                templateUrl: "views/directives/anular-op-modal.html",
                                                                scope: $scope
                                                            });

                                                            $scope.cancel = function () {
                                                                modalInstance.dismiss("cancel");
                                                            };

                                                            $scope.ok = function (id, clonar) {
                                                                ComprobanteVentaFactory.anular(itemId, clonar).then(

                                                                    function (response) {
                                                                        if (venta != undefined) {
                                                                            var ordenRemision = 0, ordenComprobante = 0;

                                                                            var remisionesEmitidas = _.find(venta.remisiones, function (remision) {
                                                                                if (remision.estado.codigo === "estados_remision_emitido" || remision.estado.codigo === "estados_remision_aprobado_sifen") return remision;
                                                                            });
                                                                            if (remisionesEmitidas) {
                                                                                var tipoRemision = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                                    return tipo.componente.codigo === "comp_tipo_venta_remision";
                                                                                })
                                                                                ordenRemision = tipoRemision.orden;
                                                                            }

                                                                            var comprobantesEmitidos = _.find(venta.comprobantes, function (comp) {
                                                                                if ((comp.estado.codigo === "comp_venta_emitido" || comp.estado.codigo === "comp_venta_aprobado_sifen" || comp.estado.codigo === "comp_venta_enviado_sifen") && comp.id !== itemId) return comp;
                                                                            });

                                                                            if (comprobantesEmitidos) {
                                                                                var tipoComprobante = _.find(venta.tipo.tipoVentaDetalles, function (tipo) {
                                                                                    return tipo.componente.codigo === "comp_tipo_venta_comprobante";
                                                                                })
                                                                                ordenComprobante = tipoComprobante.orden;
                                                                            }

                                                                            if (remisionesEmitidas) {
                                                                                if (ordenRemision > ordenComprobante) {
                                                                                    VentaFactory.actualizarEstado('venta_con_remision', venta.id).success(function (data) {
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                } else {
                                                                                    VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                        modalInstance.close();
                                                                                        $state.go($state.current, {}, { reload: true });
                                                                                    });
                                                                                }
                                                                            } else if (comprobantesEmitidos) {
                                                                                VentaFactory.actualizarEstado('venta_con_comprobante', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            } else if (venta.pedidoVenta || venta.planillaReparto) {
                                                                                VentaFactory.actualizarEstado('venta_con_pv', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            } else {
                                                                                VentaFactory.actualizarEstado('venta_abierta', venta.id).success(function (data) {
                                                                                    modalInstance.close();
                                                                                    $state.go($state.current, {}, { reload: true });
                                                                                });
                                                                            }

                                                                        }
                                                                        modalInstance.close();
                                                                        $state.go($state.current, {}, { reload: true });
                                                                    },
                                                                    function (error) {
                                                                        console.log(error);
                                                                        var mensaje = "<span>";
                                                                        _.forEach(error.data, function (err) {
                                                                            mensaje = mensaje + err.message + "<br/>";
                                                                        });
                                                                        mensaje = mensaje + "</span>";
                                                                        notify({
                                                                            messageTemplate: mensaje,
                                                                            classes: "alert-danger",
                                                                            position: "right",
                                                                            templateUrl: ""
                                                                        });
                                                                    }
                                                                );
                                                            };
                                                        } else if (resultado == false) {
                                                            var msg = $filter('translate')('NO_ADMINISTRATIVE_PERIOD');
                                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                                        }
                                                    });
                                                });
                                            } else if (!vm.formSuscripcion) {
                                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR4", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                            }
                                        } else {
                                            var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR2", { caja: caja.nombre, usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                            notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                        }
                                    } else {
                                        var msg = $filter('translate')('CAJAS_ASSIGNMENT_ERROR');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            } else {
                                var msg = $filter("translate")("CAJAS_ASSIGNMENT_ERROR3", { usuario: $rootScope.AuthParams.accesoSistema.usuario.username });
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    }
                },
                {
                    templateToRender: "<button class='btn btn-default' title='" +
                        $filter("translate")("APLICAR_COMPROBANTE") +
                        "' style='margin-right: 5px;' ng-click='aplicarComprobante(<%=dataId%>)' ng-class='{ hidden : !canAplicarComprobante(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                    customAttribute: ["tipo", "estado"],
                    functionName: "aplicarComprobante",
                    functionDef: function (itemId) {
                        ComprobanteVentaFactory.get(itemId, "AplicacionComprobanteForm").$promise.then(function (c) {

                            var saldo = c.saldo;
                            var montoTotal = c.montoTotal;
                            if (saldo != montoTotal) {
                                $scope.tituloModal = $filter("translate")("APLICACION_YA_EXISTENTE");
                                var templateTmp = '<div class="modal-header">' +
                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                    "</div>" +
                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                    '<div class="modal-footer">' +
                                    '<button class="btn btn-primary" ng-click="editarAplicacion()">' +
                                    $filter("translate")("EDITAR_APLICACION") +
                                    "</button>";
                                if (saldo > 0) {
                                    templateTmp += '<button class="btn btn-secondary" ng-click="agregarAplicacion()">' +
                                        $filter("translate")("AGREGAR_APLICACION") +
                                        "</button>"
                                }
                                templateTmp += '<button class="btn btn-danger" ng-click="desaplicar(' + itemId + ')">' +
                                    $filter("translate")("DESAPLICAR") +
                                    "</button>";
                                templateTmp += '<button class="btn btn-warning" ng-click="cancel()">' +
                                    $filter("translate")("CANCEL") +
                                    "</button>";
                                $scope.mensajeModal = $filter("translate")("QUE_DESEA_Q");
                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: templateTmp,
                                    scope: $scope
                                });
                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss("cancel");
                                };

                                $scope.desaplicar = function (itemId) {
                                    $scope.modalInstanceBorrar1.dismiss();
                                    $scope.mensajeModal = $filter("translate")("DESAPLICAR_Q");
                                    $scope.tituloModal = $filter("translate")("DESAPLICAR");
                                    var modalInstance = $modal.open({
                                        templateUrl: "views/directives/confirmation-modal.html",
                                        scope: $scope
                                    });
                                    $scope.cancel = function () {
                                        modalInstance.dismiss("cancel");
                                    };
                                    $scope.ok = function (id) {
                                        ComprobanteVentaFactory.desaplicar(itemId).then(
                                            function (response) {
                                                modalInstance.close();
                                                $state.go($state.current, {}, { reload: true });
                                            },
                                            function (error) {
                                                // console.log(error);
                                                var mensaje = "<span>";
                                                _.forEach(error.data, function (err) {
                                                    if (err.constraint == "id_producto, numero") {
                                                        mensaje =
                                                            mensaje +
                                                            $filter("translate")(
                                                                "NUMERO_GRUPO_REPETIDO_PRODUCTO"
                                                            ) +
                                                            "<br/>";
                                                    } else {
                                                        mensaje = mensaje + err.message + "<br/>";
                                                    }
                                                });
                                                mensaje = mensaje + "</span>";
                                                notify({
                                                    messageTemplate: mensaje,
                                                    classes: "alert-danger",
                                                    position: "right",
                                                    templateUrl: ""
                                                });
                                            }
                                        );
                                    }
                                }

                                $scope.editarAplicacion = function () {
                                    console.clear();
                                    console.log("AQUI editarAplicacion");
                                    if (vm.fromOutside) {
                                        console.log("AQUI vm.fromOutside: ", vm.fromOutside);
                                        $state.go("app.consultacomprobantesventa.applyedit", {
                                            id: itemId,
                                            mode: "edit"
                                        });
                                        $scope.modalInstanceBorrar1.dismiss();
                                    } else {
                                        $state.go("app.ventas.proceso.comprobanteventa.apply", {
                                            id: itemId,
                                            mode: "edit"
                                        });
                                        $scope.modalInstanceBorrar1.dismiss();
                                    }
                                };
                                $scope.agregarAplicacion = function () {
                                    if (vm.fromOutside) {
                                        $state.go("app.consultacomprobantesventa.applyadd", {
                                            id: itemId,
                                            mode: "add"
                                        });
                                        $scope.modalInstanceBorrar1.dismiss();
                                    } else {
                                        $state.go("app.ventas.proceso.comprobanteventa.apply", {
                                            id: itemId,
                                            mode: "add"
                                        });
                                        $scope.modalInstanceBorrar1.dismiss();
                                    }
                                };
                            } else {
                                if (vm.fromOutside) {
                                    // Con el id del item seleccionado se pasa al formulario de aplicacion
                                    $state.go("app.consultacomprobantesventa.applyadd", {
                                        id: itemId,
                                        mode: "add"
                                    });
                                } else {
                                    // Con el id del item seleccionado se pasa al formulario de aplicacion
                                    $state.go("app.ventas.proceso.comprobanteventa.apply", {
                                        id: itemId,
                                        mode: "add"
                                    });
                                }
                            }
                        });
                    },
                    conditionName: "canAplicarComprobante",
                    conditionDef: function (atributos) {

                        if (vm.formSuscripcion) return false;

                        var tipoComprobante = atributos[0];
                        var estado = atributos[1];

                        if (modo != 1) {
                            return false; //(
                            //ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "aplicar", estado, tipoComprobante) && estado.codigo != "comp_venta_aprobado_sifen"
                            //);
                        } else {
                            return false;
                        }
                    }
                },
                {
                    templateToRender: "<button class='btn btn-primary' title='" +
                        translations.PLAN_COBROS +
                        "' style='margin-right: 5px;' ng-click='planCobros(<%=dataCustom%>)' ng-class='{ hidden : !canViewPlanCobros(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-tasks'></span> </button>",
                    //customAttribute: "tipo",
                    customAttribute: ["id", "planCobros", "tipo"],
                    functionName: "planCobros",
                    functionDef: function (item) {
                        if (vm.fromOutside) {
                            $state.go("app.plancobros.detalles.list", {
                                idPlanCobros: item[1].id
                            });
                        } else {
                            $state.go("app.ventas.proceso.comprobanteventa.plancobros.list", {
                                idComprobanteVenta: item[0],
                                //Se recupera desde el customAttribute
                                idPlanCobros: item[1].id
                            });
                        }
                    },
                    conditionName: "canViewPlanCobros",
                    conditionDef: function (item) {

                        if (vm.formSuscripcion) return false;

                        if (modo != 1) {
                            return false; //(
                            //ComprobanteVentaFactory.hasPermisos(vm.fromOutside, "viewPlanCobros", null, item[2])
                            //);
                        } else {
                            return false;
                        }
                    }
                },
                {
                    templateToRender: "<button class='btn btn-primary' title='" +
                        $filter("translate")("CONSULTA_COBROS") +
                        "' style='margin-right: 5px;' ng-click='consultaCobros(<%=dataId%>)' ng-class='{ hidden : !canViewConsultaCobros(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-transfer'></span> </button>",
                    customAttribute: "hasCobroComprobantes",
                    view: "datatables",
                    functionName: "consultaCobros",
                    functionDef: function (itemId) {
                        var defaultColumnOrder = [
                            "cobro.id",
                            "cobro.numeroCobro",
                            "cuota.numero",
                            "cobro.fecha",
                            "cobro.estado.descripcion",
                            "cobro.cliente.nombre",
                            "importePagar"
                        ];
                        vm.optionsDTModal = {
                            resource: "cobroscomprobantes",
                            title: $filter("translate")("COBEROS"),
                            factory: CobroFactory,
                            view: 'CobroComprobanteList',
                            defaultColumnOrder: defaultColumnOrder,
                            columns: [{
                                data: "cobro.id",
                                title: $filter("translate")("NUMBER"),
                                type: "number-range",
                                visible: false
                            },
                            {
                                data: "cobro.numeroCobro",
                                title: $filter("translate")("NUMBER"),
                                type: "number-range"
                            },
                            {
                                data: "cuota.numero",
                                title: $filter("translate")("CUOTA"),
                                renderWith: "emptyRender"
                            },
                            {
                                data: "cobro.fecha",
                                title: $filter("translate")("DATE"),
                                renderWith: "dateRender",
                                dateFormat: "DD/MM/YYYY",
                                type: "date-range"
                            },
                            {
                                data: "cobro.estado.descripcion",
                                title: $filter("translate")("ESTADO")
                            },
                            {
                                data: "cobro.cliente.nombre",
                                title: $filter("translate")("CLIENTE"),
                                renderWith: "emptyRender"
                            },
                            {
                                data: "importePagar",
                                title: $filter("translate")("IMPORTE"),
                                renderWith: monedaRender,
                                type: "number-range"
                            }
                            ],
                            hasOptions: true,
                            defaultOrderColumn: 2,
                            defaultOrderDir: "desc",
                            hideAddMenu: true,
                            hideEditMenu: true,
                            hideRemoveMenu: true,
                            hideViewMenu: true,
                            hideHeader: true,
                            staticFilter: {
                                search: filterFactory
                                    .and([{
                                        path: "comprobanteVenta.id",
                                        equals: itemId
                                    }])
                                    .value()
                            },
                            extraRowOptions: [{
                                templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' ng-click='visualizar(<%=dataCustom%>)' ng-class='{ hidden : !canVisualizar(<%=dataId%>)}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                                customAttribute: "cobro",
                                conditionName: "canVisualizar",
                                conditionDef: function (itemId) {
                                    var permission = AuthorizationService.hasPermission("index_cobros");
                                    return permission;
                                },
                                functionName: "visualizar",
                                functionDef: function (cobro) {
                                    console.log("Cobro: ", cobro);
                                    $state.go("app.procesoscobro.proceso.cobro.view", { codigoProcesoCobro: cobro.procesoCobro.id, id: cobro.id });
                                }
                            }]
                        };
                        vm.modalInstance = $modal.open({
                            templateUrl: "views/directives/datatables-custom-modal.html",
                            scope: $scope,
                            size: "lg"
                        });
                    },
                    conditionName: "canViewConsultaCobros",
                    conditionDef: function (cobros) {

                        if (vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        //console.log("hasOrdenPagoDetalles: ", opd);
                        return false; //(

                        //AuthorizationService.hasPermission("index_comprobanteventa") &&
                        //cobros == "Sí"
                        //);
                    }
                },
                {
                    templateToRender: "<button class='btn btn-warning' title='" +
                        $filter("translate")("CONSULTA_APLICACIONES") +
                        "' style='margin-right: 5px;' ng-click='consultaAplicaciones(<%=dataCustom%>)' ng-class='{ hidden : !canViewConsultaAplicaciones(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-modal-window'></span> </button>",
                    customAttribute: ["id", "hasAplicaciones", "hasComprobanteAplicaciones"],
                    functionName: "consultaAplicaciones",
                    functionDef: function (attributes) {

                        var itemId = attributes[0];
                        var hasAplicaciones = attributes[1];
                        var hasComprobanteAplicaciones = attributes[2];
                        // Si se trata de una NC que se aplico sobre otros comprobantes
                        if (hasComprobanteAplicaciones === "Sí") {

                            var filters = {
                                search: filterFactory
                                    .single({
                                        path: "comprobanteVenta.id",
                                        equals: itemId
                                    })
                                    .value()
                            };
                            defaultColumnOrder = [
                                "comprobanteVentaAplicado.id",
                                "comprobanteVentaAplicado.numero",
                                "comprobanteVentaAplicado.fecha",
                                "comprobanteVentaAplicado.cliente.nombre",
                                "comprobanteVentaAplicado.tipo.descripcion",
                                "comprobanteVentaAplicado.estado.descripcion",
                                "comprobanteVentaAplicado.timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
                                "comprobanteVentaAplicado.montoTotal",
                                "comprobanteVentaAplicado.saldo",
                                "montoAplicado"
                            ];
                            columns = [{
                                data: "comprobanteVentaAplicado.id",
                                title: $filter("translate")("CODE"),
                                visible: false,
                                type: "number-range"
                            },
                            {
                                data: "comprobanteVentaAplicado.numero",
                                title: $filter("translate")("NUMBER"),
                                class: "dt-center"
                            },
                            {
                                data: "comprobanteVentaAplicado.fecha",
                                title: $filter("translate")("DATE"),
                                renderWith: "dateRender",
                                dateFormat: "DD/MM/YYYY",
                                class: "dt-center",
                                type: "date-range"
                            },
                            {
                                data: "comprobanteVentaAplicado.cliente.nombre",
                                title: $filter("translate")("CLIENTE")
                            },
                            {
                                data: "comprobanteVentaAplicado.tipo.descripcion",
                                title: $filter("translate")("TIPO_COMPROBANTE")
                            },
                            {
                                data: "comprobanteVentaAplicado.estado.descripcion",
                                title: $filter("translate")("STATUS"),
                                renderWith: "emptyRender",
                                class: "dt-center"
                            },
                            {
                                data: "comprobanteVentaAplicado.timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
                                title: $filter("translate")("TIMBRADO_PROVEEDOR"),
                                class: "dt-center",
                                renderWith: "emptyRender"
                            },
                            {
                                data: "comprobanteVentaAplicado.montoTotal",
                                title: $filter("translate")("MONTO"),
                                class: "dt-right",
                                renderWith: monedaRender,
                                type: "number-range"
                            },
                            {
                                data: "comprobanteVentaAplicado.saldo",
                                title: $filter("translate")("SALDO_PENDIENTE"),
                                class: "dt-right",
                                renderWith: monedaRender,
                                type: "number-range"
                            },
                            {
                                data: "montoAplicado",
                                title: $filter("translate")("MONTO_APLICADO"),
                                class: "dt-right",
                                renderWith: monedaRender,
                                type: "number-range"
                            }
                            ]

                            vm.optionsDTModal = {
                                resource: "comprobanteventaaplicacion",
                                title: $filter("translate")("COMPROBANTES"),
                                //factory: ComprobanteVentaFactory,
                                view: 'ComprobanteVentaAplicacionDatatableList',
                                factory: AplicacionComprobanteVentaFactory,
                                defaultColumnOrder: defaultColumnOrder,
                                columns: columns,
                                hasOptions: true,
                                defaultOrderColumn: 1,
                                defaultOrderDir: "asc",
                                hideAddMenu: true,
                                hideEditMenu: true,
                                hideRemoveMenu: true,
                                hideViewMenu: true,
                                hideHeader: true,
                                staticFilter: filters,

                                extraRowOptions: [{
                                    templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                                        $filter("translate")("VIEW") +
                                        "' ng-click='ver(<%=dataCustom%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                                    functionName: "ver",
                                    customAttribute: ["comprobanteVentaAplicado"],
                                    functionDef: function (attributes) {
                                        if (vm.fromOutside) {
                                            $state.go("app.consultacomprobantesventa.view", {
                                                id: attributes[0].id
                                            });
                                        } else if (vm.procesoVenta) {
                                            $state.go("app.ventas.proceso.comprobanteventa.view", {
                                                id: attributes[0].id
                                            });
                                        }
                                    },
                                    conditionName: "canVer",
                                    conditionDef: function () {
                                        return AuthorizationService.hasPermission(
                                            "index_comprobanteventa"
                                        );
                                    }
                                }]
                            };
                            vm.modalInstance = $modal.open({
                                templateUrl: "views/directives/datatables-custom-modal.html",
                                scope: $scope,
                                size: "lg"
                            });
                            //
                        } else
                            defaultColumnOrder = [
                                "comprobanteVenta.id",
                                "comprobanteVenta.numero",
                                "comprobanteVenta.fecha",
                                "comprobanteVenta.cliente.nombre",
                                "comprobanteVenta.tipo.descripcion",
                                // NO TRAE proyecto
                                "comprobanteVenta.proyecto.nombre",
                                "comprobanteVenta.estado.descripcion",
                                "comprobanteVenta.timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
                                "comprobanteVenta.montoTotal",
                                "comprobanteVenta.saldo",
                                "montoAplicado"
                            ];
                        var columns = [{
                            data: "comprobanteVenta.id",
                            title: $filter("translate")("CODE"),
                            visible: false,
                            type: "number-range"
                        },
                        {
                            data: "comprobanteVenta.numero",
                            title: $filter("translate")("NUMBER"),
                            class: "dt-center"
                        },
                        {
                            data: "comprobanteVenta.fecha",
                            title: $filter("translate")("DATE"),
                            renderWith: "dateRender",
                            dateFormat: "DD/MM/YYYY",
                            class: "dt-center",
                            type: "date-range"
                        },
                        {
                            data: "comprobanteVenta.cliente.nombre",
                            title: $filter("translate")("CLIENTE")
                        },
                        {
                            data: "comprobanteVenta.tipo.descripcion",
                            title: $filter("translate")("TIPO_COMPROBANTE")
                        },
                        {
                            data: "comprobanteVenta.estado.descripcion",
                            title: $filter("translate")("STATUS"),
                            renderWith: "emptyRender",
                            class: "dt-center"
                        },
                        {
                            data: "comprobanteVenta.timbradoPuntoEmision.timbradoEmpresa.numeroTimbrado",
                            title: $filter("translate")("TIMBRADO_PROVEEDOR"),
                            class: "dt-center",
                            renderWith: "emptyRender"
                        },
                        {
                            data: "comprobanteVenta.montoTotal",
                            title: $filter("translate")("MONTO"),
                            class: "dt-right",
                            renderWith: monedaRender,
                            type: "number-range"
                        },
                        {
                            data: "comprobanteVenta.saldo",
                            title: $filter("translate")("SALDO_PENDIENTE"),
                            class: "dt-right",
                            renderWith: monedaRender,
                            type: "number-range"
                        },
                        {
                            data: "montoAplicado",
                            title: $filter("translate")("MONTO_APLICADO"),
                            class: "dt-right",
                            renderWith: monedaRender,
                            type: "number-range"
                        }
                        ]

                        // Si se trata de un comprobante sobre el cual se aplico un NC
                        if (hasAplicaciones === "Sí") {

                            var filters = {
                                search: filterFactory
                                    .single({
                                        path: "comprobanteVentaAplicado.id",
                                        equals: itemId
                                    })
                                    .value()
                            }

                            vm.optionsDTModal = {
                                resource: "comprobanteventaaplicacion",
                                title: $filter("translate")("COMPROBANTES"),
                                factory: AplicacionComprobanteVentaFactory,
                                view: 'ComprobanteVentaAplicacionDatatableList',
                                defaultColumnOrder: defaultColumnOrder,
                                columns: columns,
                                hasOptions: true,
                                defaultOrderColumn: 1,
                                defaultOrderDir: "asc",
                                hideAddMenu: true,
                                hideEditMenu: true,
                                hideRemoveMenu: true,
                                hideViewMenu: true,
                                hideHeader: true,
                                staticFilter: filters,
                                extraRowOptions: [{
                                    templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                                        $filter("translate")("VIEW") +
                                        "' ng-click='ver(<%=dataCustom%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                                    functionName: "ver",
                                    customAttribute: ["comprobanteVenta"],
                                    functionDef: function (attributes) {
                                        if (vm.fromOutside) {
                                            $state.go("app.consultacomprobantesventa.view", {
                                                id: attributes[0].id
                                            });
                                        } else if (vm.procesoVenta) {
                                            $state.go("app.ventas.proceso.comprobanteventa.view", {
                                                id: attributes[0].id
                                            });
                                        }
                                    },
                                    conditionName: "canVer",
                                    conditionDef: function () {
                                        return AuthorizationService.hasPermission(
                                            "index_comprobanteventa"
                                        );
                                    }
                                }]
                            };
                            vm.modalInstance = $modal.open({
                                templateUrl: "views/directives/datatables-custom-modal.html",
                                scope: $scope,
                                size: "lg"
                            });
                        }
                    },
                    conditionName: "canViewConsultaAplicaciones",
                    conditionDef: function (attributes) {

                        if (vm.formSuscripcion) return false;

                        var hasAplicaciones = attributes[1];
                        var hasComprobanteAplicaciones = attributes[2];


                        return false;
                    }
                },
                {
                    templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
                    functionName: 'reenviar',
                    customAttribute: ['estado', 'enviadoAContabilidad', 'id'],
                    functionDef: function (atributo) {

                        var idVenta = atributo[2];

                        ComprobanteVentaFactory.get(idVenta, "ComprobanteVentaList").$promise.then(function (
                            response
                        ) {
                            $scope.tituloModal = $filter('translate')('REENVIAR_VENTA_Q');
                            $scope.mensajeModal = $filter('translate')('REENVIAR_VENTA_MSG');
                            var modalInstance = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function (id) {
                                ComprobanteVentaFactory.reenviar(response.id).then(function (response) {
                                    ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                                        if (parametro.valorTipo.codigo == 'si') {
                                            if (response.enviadoAContabilidad == false) {
                                                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                            }
                                            if (response.listaArchivosLogs != null) {
                                                if (response.listaArchivosLogs.length != 0) {
                                                    /*for(var i=0; i<response.listaArchivosLogs.length; i++) {
                                                        console.log('response.listaArchivosLogs: ');
                                                        console.log(response.listaArchivosLogs[i]);
                                                        $window.open(CONFIGURATION.serverCON+response.listaArchivosLogs[i],"_blank");
                                                    }*/
                                                    notify({ message: $filter('translate')('ERROR_GENERAR_ASIENTO_AUT_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                                }
                                            }
                                        }
                                    });
                                    $state.go($state.current, {}, { reload: true });
                                }, function (error) {
                                    var msg = "";
                                    if (error.data && error.data.length > 0 && error.data[0].message) {
                                        msg = error.data[0].message;
                                    } else {
                                        msg = $filter('translate')('REENVIAR_VENTA_FAILED');
                                    }

                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                });
                                modalInstance.close();
                            }
                        });
                    },
                    conditionName: 'canReenviar',
                    conditionDef: function (atributo) {
                        var estado = atributo[0];
                        var enviadoAContabilidad = atributo[1];
                        var id = atributo[2];
                        if (vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        if (enviarAContabilidad == 'si') {
                            if (estado.codigo === 'comp_venta_emitido' || estado.codigo === 'comp_venta_anulado' || estado.codigo === 'comp_venta_aprobado_sifen') {
                                if (enviadoAContabilidad === "No") {
                                    return false;
                                }
                            }
                        }
                    }
                },
                {
                    templateToRender: "<button title='" + $filter('translate')('DEFINIR_ESTADO_RENDICION') + "' class='btn btn-warning' style='margin-right: 5px;' ng-click='definirRendicionReparto(<%=dataCustom%>)' ng-class='{ hidden : !canDefinirRendicion(<%=dataId%>)}'> <span class='glyphicon glyphicon-bed'></span> </button>",
                    customAttribute: "id",
                    conditionName: "canDefinirRendicion",
                    conditionDef: function (itemId) {

                        if (vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        var permission = AuthorizationService.hasPermission("asignar_estado_rendicion");
                        //console.log("permiso: ", permission);
                        return false;//permission;
                    },
                    functionName: "definirRendicionReparto",
                    functionDef: function (itemId) {
                        //console.log("itemId: ", itemId);

                        // recuperamos estados
                        $scope.estadosRendicion = TiposTipoFactory.get("estados_rendicion");

                        // creacion del modal
                        var modalInstance = $modal.open({
                            templateUrl: 'views/directives/estado-rendicion-modal.html',
                            scope: $scope
                        });

                        $scope.cancel = function () {
                            modalInstance.dismiss('cancel');
                        };

                        $scope.ok = function (estadoRendicion) {
                            $scope.submited = true;
                            if (estadoRendicion != undefined) {
                                //console.log("estadoRendicion: ", estadoRendicion.codigo);
                                ComprobanteVentaFactory.actualizarEstadoRendicion(itemId, estadoRendicion.codigo).then(function () {
                                    modalInstance.close();
                                    $state.go($state.current, {}, { reload: true });
                                });
                            }
                        }
                    }
                },

                {
                    templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='Modificar Comprobante'" +
                        " ng-click='modificarVendedor(<%=dataId%>)' ng-class='{ hidden : !canModificarVendedor(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                    functionName: "modificarVendedor",
                    functionDef: function (itemId) {
                        localStorage.setItem('habilitarVendedor', AuthorizationService.hasPermission("modificar_vendedor_comp_emitido"));
                        localStorage.setItem('modificarCompElectronico', AuthorizationService.hasPermission("modificar_comprobante_electronico_emitido"));
                        localStorage.setItem('modificarCompElectronicoAprobado', AuthorizationService.hasPermission("modificar_comprobante_electronico_aprobado"));

                        $state.go("app.comprobantesventapendientesnominacion.view",
                            {
                                id: itemId,
                                habilitarVendedor: AuthorizationService.hasPermission("modificar_vendedor_comp_emitido")
                            });

                    },
                    conditionName: "canModificarVendedor",
                    customAttribute: ["estado", "timbradoPuntoEmision"],
                    conditionDef: function (atributos) {

                        var estado = atributos[0];
                        var timbradoPuntoEmision = atributos[1];

                        if (vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        if (AuthorizationService.hasPermission("modificar_vendedor_comp_emitido")) {
                            return true;
                        }

                        if (timbradoPuntoEmision && timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                            if (estado.codigo === "comp_venta_emitido") {
                                if (AuthorizationService.hasPermission("modificar_comprobante_electronico_emitido")) {
                                    return true;
                                }
                            }
                        }

                        return false;
                    }
                },
                {
                    templateToRender: "<button title='" + "Reutilizar Numero Comprobante" + "' class='btn btn-primary btn-dt' style='margin-right: 5px;' ng-click='reutilizar(<%=dataCustom%>)' ng-class='{hidden : !canReutilizar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-duplicate'></span> </button>",
                    functionName: 'reutilizar',
                    customAttribute: ['estado', 'enviadoAContabilidad', 'id', 'usuarioReutilizacion'],
                    functionDef: function (atributo) {

                        var idVenta = atributo[2];

                        ComprobanteVentaFactory.get(idVenta, "ComprobanteVentaList").$promise.then(function (
                            response
                        ) {
                            $scope.tituloModal = "Reutilizar Numero de Comprobante";
                            $scope.mensajeModal = "Estás seguro que quieres realizar el proceso de reutilización de número de comprobante";
                            var modalInstance = $modal.open({
                                templateUrl: 'views/directives/confirmation-modal.html',
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss('cancel');
                            };

                            $scope.ok = function (id) {

                                ComprobanteVentaFactory.reUtilizar(response.id, $rootScope.AuthParams.accesoSistema.usuario.id).then(function (respuesta) {

                                    if (respuesta.status == 200) {
                                        var msg = "Se realizó correctamente el proceso de reutilización.";
                                        notify({ message: msg, classes: 'alert-success', position: 'right' });
                                        $state.go($state.current, {}, { reload: true });
                                    } else {
                                        var msg = "";
                                        if (respuesta.data && respuesta.data.length > 0 && respuesta.data[0].message) {
                                            msg = respuesta.data[0].message;
                                        } else {
                                            msg = "Error en el proceso de reutilización";
                                        }
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                }, function (error) {
                                    var mensaje = "<span>";
                                    _.forEach(error.data, function (err) {
                                        mensaje = mensaje + err.message + "<br/>";
                                    });
                                    mensaje = mensaje + "</span>";
                                    notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                    modalInstance.close();
                                });
                            }
                        });
                    },
                    conditionName: 'canReutilizar',
                    conditionDef: function (atributo) {

                        if (vm.realizarTareasEspeciales == "si" && AuthorizationService.hasPermission("update_reutilizar_numeracion") == true && atributo[0].codigo == "comp_venta_anulado" && atributo[3] == null) {
                            return false;
                        } else {
                            return false;
                        }
                    }
                },
                {
                    templateToRender: "<button title='Generar Pagare' class='btn' style='margin-right: 5px; background-color: #7e3794; color: #fff' ng-click='generarPagare(<%=dataCustom%>)' ng-class='{ hidden : !condicionGenerarPagare(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-list-alt'></span> </button>",
                    functionName: "generarPagare",
                    conditionName: "condicionGenerarPagare",
                    customAttribute: ["estado", "tipo", "id"],
                    conditionDef: function (atributos) {
                        var estado = atributos[0];
                        var tipo = atributos[1];
                        var estadosPermitidos = ["comp_venta_emitido", "comp_venta_enviado_sifen", "comp_venta_aprobado_sifen"]
                        if (AuthorizationService.hasPermission("generar_pagare_fc") == true) {
                            if (estadosPermitidos.includes(estado.codigo) && tipo.generarPagare) {
                                return false;
                            }
                        }
                    },
                    functionDef: function (atributos) {
                        var itemId = atributos[2];
                        var filtersArr = [];
                        filtersArr.push("idcomprobante=" + itemId);
                        $state.go($state.current, {}, { reload: true });
                        vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                            "generarpagare",
                            "pdf",
                            filtersArr
                        );
                        $window.open(vm.pdfDownloadURL, "_blank");
                        $state.go($state.current, {}, { reload: true });
                    }
                },
                {
                    templateToRender: "<button title='" + 'Descargar Json' +
                        "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='descargarJson(<%=dataCustom%>)' ng-class='{ hidden : !condicionDescargaJson(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-download-alt'></span> </button>",
                    functionName: "descargarJson",
                    conditionName: "condicionDescargaJson",
                    customAttribute: ["estado", "timbradoPuntoEmision", "id"],
                    conditionDef: function (atributos) {
                        var timbradoPuntoEmision = atributos[1];

                        if (AuthorizationService.hasPermission("descargar_json") &&
                            timbradoPuntoEmision &&
                            timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                            return false;
                        }
                        if (vm.formSuscripcion) return false;
                    },
                    functionDef: function (atributos) {
                        var idItem = atributos[2];
                        ComprobanteVentaFactory.generarJsonVirtualDescarga(idItem).then(function (data) {

                            var jsonData = JSON.stringify(data.data, null, 4);
                            var blob = new Blob([jsonData], { type: 'application/json' });
                            var url = window.URL.createObjectURL(blob);

                            var a = document.createElement('a');
                            a.href = url;
                            a.download = 'archivo.json';
                            a.click();

                            window.URL.revokeObjectURL(url);

                        }, function (error) {
                            console.log(error);
                            var mensaje = "<span>";
                            _.forEach(error.data, function (err) {
                                mensaje = mensaje + err.message + "<br/>";
                            });
                            mensaje = mensaje + "</span>";
                            notify({
                                messageTemplate: mensaje,
                                classes: "alert-danger",
                                position: "right",
                                templateUrl: ""
                            });
                        });
                    }
                },
                {
                    templateToRender: "<button title='" +
                        "Envío de Nominación" +
                        "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='nominacion(<%=dataCustom%>)' ng-class='{ hidden : !condicionNominacion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-send'></span> </button>",
                    functionName: "nominacion",
                    conditionName: "condicionNominacion",
                    customAttribute: ["inominado", "cliente", "id", "estado"],
                    conditionDef: function (atributos) {

                        if (vm.formSuscripcion && !vm.comprobanteProcesadoSuscripcion) return false;

                        var inominado = atributos[0];
                        var cliente = atributos[1];
                        var estado = atributos[2];
                        //si es inominado pero ya se nomino
                        if (vm.fromOutside && inominado == "Sí" && cliente.tipoDocumento.codigo != "tipo_documento_cliente_innominado" && estado.codigo === "comp_venta_aprobado_sifen") {
                            return true;
                        } else {
                            return false;
                        }

                    },
                    functionDef: function (atributos) {

                        var itemId = atributos[2];

                        if (vm.fromOutside) {
                            $scope.mensajeModal = "¿Está seguro de que desea realizar el Envío de Nominación?";
                            $scope.tituloModal = "Envío de Nominación";
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss("cancel");
                            };

                            $scope.ok = function (id) {
                                ComprobanteVentaFactory.nominacion(itemId).then(function (data) {
                                    console.log("data ", data)
                                    if (data === "" || data === null) {
                                        var mensaje = "<span>";
                                        mensaje += "Error al Enviar Nominación" + "</span>";
                                        console.log(mensaje);
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-danger",
                                            position: "right",
                                            templateUrl: ""
                                        });
                                    } else {
                                        console.log("*** La Respuesta *** --> ", data);
                                        var mensaje = "<span>" + "Nominación enviada Correctamente" + "</span>";
                                        notify({
                                            messageTemplate: mensaje,
                                            classes: "alert-success",
                                            position: "right",
                                            templateUrl: ""
                                        });

                                        $state.go($state.current, {}, { reload: true });
                                    }
                                }, function (error) {
                                    console.log(error);
                                    var mensaje = "<span>";
                                    _.forEach(error.data, function (err) {
                                        mensaje = mensaje + err.message + "<br/>";
                                    });
                                    mensaje = mensaje + "</span>";
                                    notify({
                                        messageTemplate: mensaje,
                                        classes: "alert-danger",
                                        position: "right",
                                        templateUrl: ""
                                    });
                                });
                            };
                        }
                    }
                }
                ]
            };
            WatchLocacionChangeFactory.watchWith(
                staticFilter,
                vm.options,
                "locacion.id"
            );
        });
        /* Si recibe fechaComprobante!=null verifica la fecha de comprobante, sino fecha actual */
        var checkPeriodoAdministrativo = function (fechaComprobante) {
            return $q(function (resolve, reject) {

                var params = {};
                var fecha;

                if (fechaComprobante == null) {
                    fecha = new Date();
                } else {
                    fecha = fechaComprobante;
                }

                params.search = filterFactory
                    .and([{
                        path: "estado.codigo",
                        equals: "per_adm_abierto"
                    },
                    {
                        path: "fechaDesde",
                        equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                    },
                    {
                        path: "fechaHasta",
                        equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                    }
                    ])
                    .value();

                PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {
                    if (responsePeriodo.length > 0) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            });
        }

        var getIDs = function () {
            var ids = [];
            _.forEach(vm.options.selection, function (n, key) {
                if (n)
                    ids.push(key);
            });
            return ids;
        };


        var createFilters = function (filters, staticFilters) {
            var filtersArr = [];
            var filtersC = _.cloneDeep(filters);
            var staticFiltersC = _.cloneDeep(staticFilters);
            _.each(filters, function (search, data) {
                search = search.split("~");
                if (search.length > 1) {
                    /* El parámetro es un rago */

                    var isDate = search[0].split("/");

                    if (isDate.length > 1) {
                        /* Es un rago de fechas */

                        filtersArr.push({ path: data, equalOrAfter: search[0] })
                        filtersArr.push({ path: data, equalOrBefore: search[1] })
                    } else {
                        /* El un rago numérco */

                        filtersArr.push({ path: data, greaterOrEqual: search[0] })
                        filtersArr.push({ path: data, lessOrEqual: search[1] })
                    }
                } else {
                    /* El parametro no es un rago */
                    filtersArr.push({ path: data, like: search[0] })
                }
            });

            if (staticFiltersC) {
                // entrar al OR y por cada AND agregar todos los filtros de datatable
                var entro = false;
                var staticFiltersAnds = staticFiltersC.search.filters;
                _.each(staticFiltersAnds, function (staticFilter) {
                    if (staticFilter.filters) {
                        _.each(filtersArr, function (dtFilter) {
                            staticFilter.filters.push(dtFilter);
                        });
                    } else {
                        if (staticFilter.equals != undefined) {
                            filtersArr.push({ path: staticFilter.path, equals: staticFilter.equals });
                        } else if (staticFilter.like != undefined) {
                            filtersArr.push({ path: staticFilter.path, like: staticFilter.like });
                        } else if (staticFilter.equalOrBefore != undefined) {
                            filtersArr.push({ path: staticFilter.path, equalOrBefore: staticFilter.equalOrBefore });
                        } else if (staticFilter.equalOrAfter != undefined) {
                            filtersArr.push({ path: staticFilter.path, equalOrAfter: staticFilter.equalOrAfter });
                        } else if (staticFilter.greaterThan != undefined) {
                            filtersArr.push({ path: staticFilter.path, greaterThan: staticFilter.greaterThan });
                        } else if (staticFilter.lessThan != undefined) {
                            filtersArr.push({ path: staticFilter.path, lessThan: staticFilter.lessThan });
                        } else if (staticFilter.sortDesc != undefined) {
                            filtersArr.push({ path: staticFilter.path, sortDesc: staticFilter.sortDesc });
                        } else if (staticFilter.sortAsc != undefined) {
                            filtersArr.push({ path: staticFilter.path, sortAsc: staticFilter.sortAsc });
                        } else if (staticFilter.isNull != undefined) {
                            filtersArr.push({ path: staticFilter.path, isNull: staticFilter.isNull });
                        } else if (staticFilter.isNotNull != undefined) {
                            filtersArr.push({ path: staticFilter.path, isNotNull: staticFilter.isNotNull });
                        } else if (staticFilter.notEquals != undefined) {
                            filtersArr.push({ path: staticFilter.path, notEquals: staticFilter.notEquals });
                        } else if (staticFilter.greaterOrEqual != undefined) {
                            filtersArr.push({ path: staticFilter.path, greaterOrEqual: staticFilter.greaterOrEqual });
                        } else if (staticFilter.lessOrEqual != undefined) {
                            filtersArr.push({ path: staticFilter.path, lessOrEqual: staticFilter.lessOrEqual });
                        }
                        entro = true;
                    }
                });
                if (entro == true) {
                    filtersC = filterFactory.and(filtersArr).value();
                } else {
                    filtersC = filterFactory.or(staticFiltersAnds).value();
                }
            } else {
                filtersC = filterFactory.and(filtersArr).value();
            }
            return filtersC;
        }

        //controlar el vencimiento de cuotas del cliente
        var controlarVencimiento = function (response, itemId, modalInstance, formato) {
            ParametrosFactory.getByCodigo('bloquear_ventas_por_venc_cuentas_cobrar').then(function (res) {
                if ((res.valorTipo.codigo === "bloquear_rem_fac" && response.tipo.clase.codigo != 'nota_credito') || (res.valorTipo.codigo === "bloquear_fac" && response.tipo.clase.codigo != 'nota_credito')) {
                    ComprobanteVentaFactory.verificarVencimiento(itemId).then(function (rr) {
                        //si el cliente tiene cuotas vencidas y sobrepasa la tolerancia continuar, si no, verificar sobregiro
                        if (rr.data === true || rr.data === 'true') {
                            //si no se tiene la solicitud de bloqueo y no tiene decision cotinuar si no, verificar los casos
                            if (response.solicitudAutorizacionBloqueo == null && response.autorizadoSolicitudBloqueo == null) {
                                $scope.mensajeModal = 'El cliente cuenta con cuotas vencidas.';
                                $scope.tituloModal = 'Información';
                                var modalInstance2 = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.bloqueoConfirmar = false;

                                $scope.cancel = function () {
                                    modalInstance2.dismiss('cancel');
                                    modalInstance.dismiss('cancel');
                                };

                                $scope.ok = function () {
                                    $scope.mensajeModal = 'Desea realizar una Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias?';
                                    $scope.tituloModal = 'Confirmación';
                                    var modalInstance3 = $modal.open({
                                        templateUrl: 'views/directives/confirmation-modal.html',
                                        scope: $scope
                                    });
                                    $scope.cancel = function () {
                                        modalInstance3.dismiss('cancel');
                                        modalInstance2.dismiss('cancel');
                                        modalInstance.dismiss('cancel');
                                    };

                                    $scope.ok = function () {
                                        //controlar que el usuario pueda crear solicitud de autorizacion
                                        if (AuthorizationService.hasPermission("create_solicitudautorizacion") === true) {
                                            $scope.mensajeModal = 'Motivo de la Solicitud: ';
                                            $scope.tituloModal = 'Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias';
                                            //obtener el tipo Ignorar Vencimiento y Tolerancia
                                            $scope.motivos = TiposTipoFactory.get("proceso_autorizar");
                                            $scope.motivos.$promise.then(function (estados) {
                                                var estadoActivo = _.find($scope.motivos, function (estado) {
                                                    return estado.codigo === "ignorar_venc_cuota_tolerancia";
                                                });
                                                if (estadoActivo) {
                                                    $scope.config = estadoActivo;
                                                }
                                                var modalInstance4 = $modal.open({
                                                    templateUrl: 'qualita-components/ventas/views/pedidoventa/autorizacion-modal.html',
                                                    scope: $scope
                                                });

                                                $scope.cancel = function () {
                                                    modalInstance4.dismiss('cancel');
                                                    modalInstance3.dismiss('cancel');
                                                    modalInstance2.dismiss('cancel');
                                                    modalInstance.dismiss('cancel');
                                                };

                                                $scope.ok = function (id, config) {
                                                    $('#' + config.id).attr('disabled', 'disabled');
                                                    ComprobanteVentaFactory.solicitarAutorizacionBloqueo(itemId, config.id).then(function () {
                                                        ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (res) {
                                                            console.log("ress mmm ", res);
                                                            res.solicitudAutorizacionBloqueo.motivoSolicitud = config;
                                                            SolicitudAutorizacionFactory.save(res.solicitudAutorizacionBloqueo);

                                                            var filtersArr = [];
                                                            filtersArr.push("idcomprobanteventa=" + itemId);
                                                            modalInstance4.close();
                                                            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                                "solicitudautorizacioncomprobantebloqueo",
                                                                "pdf",
                                                                filtersArr
                                                            );
                                                            $window.open(vm.pdfDownloadURL, "_blank");
                                                            $timeout(function () {
                                                                $('#' + config.id).removeAttr("disabled");
                                                            }, 1000)
                                                            //luego de imprimir el reporte de bloqueo, continuar con el sobregiro
                                                            controlSobregiro(res, itemId, modalInstance);
                                                        });
                                                    }, function (error) {
                                                        var mensaje = "<span>";
                                                        _.forEach(error.data, function (err) {
                                                            mensaje = mensaje + err.message + "<br/>";
                                                        });
                                                        mensaje = mensaje + "</span>";
                                                        notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                                    });
                                                }
                                                return estados;
                                            });

                                        } else {
                                            $scope.tituloModal = 'Error';
                                            $scope.mensajeModal = 'Usted no posee permiso para realizar la operación';
                                            var modalInstance5 = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance5.dismiss("cancel");
                                                modalInstance3.dismiss('cancel');
                                                modalInstance2.dismiss('cancel');
                                                modalInstance.dismiss('cancel');
                                            };
                                        }
                                    }
                                }
                            } else {
                                verificarBloqueo(response, itemId, modalInstance, formato);
                            }
                        } else {
                            //control de sobregiro si el cliente no tiene cuota vencida
                            controlSobregiro(response, itemId, modalInstance, formato);
                        }
                    });
                } else {
                    //control de sobregiro si el parametro no se cumple
                    controlSobregiro(response, itemId, modalInstance, formato);
                }
            });
        }

        //verificar los casos del bloqueo
        var verificarBloqueo = function (response, itemId, modalInstance, formato) {
            //controlar el sobregiro si no se controlo antes
            ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                if (response1.valorTipo.codigo === "si") {
                    if (response.tipo.controlLimiteCredito == true) {
                        if (response.cliente.controlarLimiteCredito == true) {
                            if (response.solicitudAutorizacion == null && response.solicitarAutorizacion == null) {
                                controlSobregiro(response, itemId, modalInstance, formato);
                                modalInstance6.dismiss('cancel');
                            }
                        }
                    }
                }
            });

            if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == null) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias sobre este comprobante de venta';
                var modalInstance6 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance6.dismiss('cancel');
                    modalInstance.dismiss('cancel');
                };
            } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == false) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                var modalInstance7 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance7.dismiss('cancel');

                };
            } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == true) {
                //verificar que no exista solicitud de sobregiro
                if (response.solicitudAutorizacion == null) {
                    aprobarComprobante(itemId, modalInstance, formato);
                } else {
                    verificarSobregiro2(response, itemId, modalInstance, formato);
                }
            }
        }

        var verificarSobregiro = function (response, itemId, modalInstance, formato) {
            if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == null) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Sobregiro de Límite de Crédito sobre este comprobante de venta';
                var modalInstance6 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance6.dismiss('cancel');
                    modalInstance.dismiss('cancel');
                };
            } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == false) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                var modalInstance7 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance7.dismiss('cancel');

                };
            } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == true) {
                //verificar que no exista solicitud de bloqueo
                if (response.solicitudAutorizacionBloqueo == null) {
                    aprobarComprobante(itemId, modalInstance, formato);
                } else {
                    verificarBloqueo(response, itemId, modalInstance, formato);
                }
            }
        }

        var verificarSobregiro2 = function (response, itemId, modalInstance, formato) {
            if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == null) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Sobregiro de Limite de Credito sobre este comprobante de venta';
                var modalInstance6 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance6.dismiss('cancel');
                    modalInstance.dismiss('cancel');
                };
            } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == false) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                var modalInstance7 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance7.dismiss('cancel');

                };
            } else if (response.solicitudAutorizacion != null && response.autorizadoSolicitud == true) {
                aprobarComprobante(itemId, modalInstance, formato);
            }
        }

        var aprobarComprobante = function (itemId, modalInstance, formato) {
            //aprobar del proceso normal en el flujo de ventas
            ComprobanteVentaFactory.aprobarPorId(itemId).then(function (response) {
                setTimeout(function () {
                    ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
                        if (parametro.valorTipo.codigo == 'si') {
                            if (response.enviadoAContabilidad == false) {
                                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                            }
                        }
                    });
                }, 600);
                if (response.timbradoPuntoEmision.timbradoEmpresa.claseTimbrado.codigo === "timbrado_virtual") {
                    $state.go($state.current, {}, { reload: true });
                } else {
                    var filtersArr = [];
                    filtersArr.push("idcomprobante=" + itemId);
                    filtersArr.push("config=" + formato);
                    filtersArr.push("id_usuario=" + $rootScope.AuthParams.accesoSistema.usuario.id);
                    modalInstance.close();
                    $state.go($state.current, {}, { reload: true });
                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                        "comprobanteventa",
                        "pdf",
                        filtersArr
                    );
                    $window.open(vm.pdfDownloadURL, "_blank");
                    $state.go($state.current, {}, { reload: true });
                }
            },
                function (error) {
                    console.log(error);
                    var mensaje = "<span>";
                    _.forEach(error.data, function (err) {
                        mensaje = mensaje + err.message + "<br/>";
                    });
                    mensaje = mensaje + "</span>";
                    notify({
                        messageTemplate: mensaje,
                        classes: "alert-danger",
                        position: "right",
                        templateUrl: ""
                    });
                });
            //hasta aca aprobar normal
        }

        var verificarBloqueo2 = function (response, itemId, modalInstance, formato) {
            //si tiene pv y tiene solicitud de autorizacion de sobregiro
            if (response.pedidoVenta != null && response.pedidoVenta.solicitudAutorizacion != null) {
                //si la solicitud de sobregiro en el pv No fue autorizado
                if (response.pedidoVenta.autorizadoSolicitud == false) {
                    //si no control de sobregiro
                    ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                        if (response1.valorTipo.codigo === "si") {
                            if (response.tipo.controlLimiteCredito == true) {
                                if (response.cliente.controlarLimiteCredito == true) {
                                    if (response.solicitudAutorizacion == null && response.solicitarAutorizacion == null && modalInstance6 != undefined) {
                                        modalInstance6.dismiss('cancel');
                                    }
                                } else if (modalInstance6 != undefined) {
                                    modalInstance6.dismiss('cancel');
                                }
                            } else if (modalInstance6 != undefined) {
                                modalInstance6.dismiss('cancel');
                            }
                        } else if (modalInstance6 != undefined) {
                            modalInstance6.dismiss('cancel');
                        }
                    });
                } else {
                    if (response.montoTotal > response.pedidoVenta.montoTotal) {
                        //si no control de sobregiro
                        ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                            if (response1.valorTipo.codigo === "si") {
                                if (response.tipo.controlLimiteCredito == true) {
                                    if (response.cliente.controlarLimiteCredito == true) {
                                        if (response.solicitudAutorizacion == null && response.solicitarAutorizacion == null && modalInstance6 != undefined) {
                                            modalInstance6.dismiss('cancel');
                                        }
                                    } else if (modalInstance6 != undefined) {
                                        modalInstance6.dismiss('cancel');
                                    }
                                } else if (modalInstance6 != undefined) {
                                    modalInstance6.dismiss('cancel');
                                }
                            } else if (modalInstance6 != undefined) {
                                modalInstance6.dismiss('cancel');
                            }
                        });
                    } else if (modalInstance6 != undefined) {
                        modalInstance6.dismiss('cancel');
                    }
                }
            } else {
                //si no control de sobregiro
                ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                    if (response1.valorTipo.codigo === "si") {
                        if (response.tipo.controlLimiteCredito == true) {
                            if (response.cliente.controlarLimiteCredito == true) {
                                if (response.solicitudAutorizacion == null && response.solicitarAutorizacion == null && modalInstance6 != undefined) {
                                    modalInstance6.dismiss('cancel');
                                }
                            } else if (modalInstance6 != undefined) {
                                modalInstance6.dismiss('cancel');
                            }
                        } else if (modalInstance6 != undefined) {
                            modalInstance6.dismiss('cancel');
                        }
                    } else if (modalInstance6 != undefined) {
                        modalInstance6.dismiss('cancel');
                    }
                });
            }

            if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == null) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Ya existe una Solicitud de Autorización para Ignorar el Vencimiento de Cuotas y Tolerancias sobre este comprobante de venta';
                var modalInstance6 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance6.dismiss('cancel');
                    modalInstance.dismiss('cancel');
                };
            } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == false) {
                $scope.tituloModal = 'Error';
                $scope.mensajeModal = 'Usted no fue autorizado para realizar la operación.';
                var modalInstance7 = $modal.open({
                    template: '<div class="modal-header">' +
                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                        "</div>" +
                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                        '<div class="modal-footer">' +
                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                        "</div>",
                    scope: $scope
                });
                $scope.cancel = function () {
                    modalInstance7.dismiss('cancel');

                };
            } else if (response.solicitudAutorizacionBloqueo != null && response.autorizadoSolicitudBloqueo == true) {
                //este caso es cuando no se cumple el parametro de sobregiro y la solicitud de bloqueo se autorizo
                aprobarComprobante(itemId, modalInstance, formato);
            }
        }

        var calcularSaldo = function (response, itemId, modalInstance, cambio, resp, formato) {
            var limiteCredito = response.cliente.limiteCredito;
            // var saldos = resp.data + (response.montoTotal * cambio);
            var saldos = 0;
            var saldosRemisiones = 0;
            var saldosAux = response.montoTotal;
            if (response.pedidoVenta != null && response.remisiones.length == 0) {
                if ((response.montoTotal > response.pedidoVenta.montoTotal)) {
                    saldosAux = response.montoTotal - response.pedidoVenta.montoTotal;
                }
            }
            if (response.remisiones.length > 0) {
                for (var i = 0; i < response.remisiones.length; i++) {
                    saldosRemisiones = saldosRemisiones + response.remisiones[i].montoTotal;
                }
                if ((response.montoTotal > saldosRemisiones)) {
                    saldosAux = response.montoTotal - saldosRemisiones;
                } else {
                    saldosAux = 0;
                }
            }
            saldos = resp.data + (saldosAux * cambio);
            if (saldos > limiteCredito) {
                //se excedio se informa el importe de sobregiro y se crea la solicitud de autorizacion si tiene permiso y
                //verificar de que ya no exista una solicitud previa
                if (response.solicitudAutorizacion == null && response.autorizadoSolicitud == null) {
                    var valorSobregiro = ((saldosAux * cambio) + resp.data) - response.cliente.limiteCredito;
                    valorSobregiro = Math.round(valorSobregiro);
                    if (valorSobregiro < 0) {
                        valorSobregiro = valorSobregiro * (-1);
                    }
                    valorSobregiro = valorSobregiro.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

                    $scope.mensajeModal = 'Con el importe total del comprobante de ventas se sobrepasa el Límite de Crédito del Cliente. El importe excedido del Límite de Crédito del Cliente es: ' + valorSobregiro + ' Gs.';
                    $scope.tituloModal = 'Información';
                    var modalInstance2 = $modal.open({
                        templateUrl: 'views/directives/confirmation-modal.html',
                        scope: $scope
                    });
                    $scope.cancel = function () {
                        modalInstance2.dismiss('cancel');
                        modalInstance.dismiss('cancel');
                    };

                    $scope.ok = function () {
                        $scope.mensajeModal = 'Desea realizar una Solicitud de Autorización de Sobregiro de Límite de Crédito?';
                        $scope.tituloModal = 'Confirmación';
                        var modalInstance3 = $modal.open({
                            templateUrl: 'views/directives/confirmation-modal.html',
                            scope: $scope
                        });
                        $scope.cancel = function () {
                            modalInstance3.dismiss('cancel');
                            modalInstance2.dismiss('cancel');
                            modalInstance.dismiss('cancel');
                        };

                        $scope.ok = function () {
                            //controlar que el usuario pueda crear solicitud de autorizacion
                            if (AuthorizationService.hasPermission("create_solicitudautorizacion") === true) {
                                //solicitar el campo motivo
                                $scope.mensajeModal = 'Motivo de la Solicitud: ';
                                $scope.tituloModal = 'Solicitud de Autorización de Sobregiro de Límite de Crédito';
                                //obtener el tipo de Sobregiro
                                $scope.motivos = TiposTipoFactory.get("proceso_autorizar");
                                $scope.motivos.$promise.then(function (estados) {
                                    var estadoActivo = _.find($scope.motivos, function (estado) {
                                        return estado.codigo === "sobregiro_limite_credito";
                                    });
                                    if (estadoActivo) {
                                        $scope.config = estadoActivo;
                                    }
                                    var modalInstance4 = $modal.open({
                                        templateUrl: 'qualita-components/ventas/views/pedidoventa/autorizacion-modal.html',
                                        scope: $scope
                                    });

                                    $scope.cancel = function () {
                                        modalInstance4.dismiss('cancel');
                                        modalInstance3.dismiss('cancel');
                                        modalInstance2.dismiss('cancel');
                                        modalInstance.dismiss('cancel');
                                    };

                                    $scope.ok = function (id, config) {
                                        $('#' + config.id).attr('disabled', 'disabled');
                                        ComprobanteVentaFactory.solicitarAutorizacionComprobante(itemId, config.id).then(function () {
                                            ComprobanteVentaFactory.get(itemId, "ComprobanteVentaList").$promise.then(function (res) {
                                                res.solicitudAutorizacion.motivoSolicitud = config;
                                                SolicitudAutorizacionFactory.save(res.solicitudAutorizacion);
                                                ParametrosFactory.getByCodigo("nombre_empresa").then(function (data) {
                                                    var filtersArr = [];
                                                    filtersArr.push("idcomprobanteventa=" + itemId);
                                                    filtersArr.push("nombre_empresa=" + data.valor);
                                                    filtersArr.push("id_sucursal=" + $rootScope.AuthParams.locacionSeleccionada.sucursal.id);
                                                    filtersArr.push("id_unidadnegocio=" + $rootScope.AuthParams.locacionSeleccionada.unidadNegocio.id);

                                                    modalInstance3.close();
                                                    $state.go($state.current, {}, { reload: true });
                                                    vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                                        "solicitudautorizacioncomprobante",
                                                        "pdf",
                                                        filtersArr
                                                    );
                                                    $window.open(vm.pdfDownloadURL, "_blank");
                                                    $timeout(function () {
                                                        $('#' + config.id).removeAttr("disabled");
                                                    }, 1000);
                                                    $state.go($state.current, {}, { reload: true });
                                                });
                                            });

                                        }, function (error) {
                                            var mensaje = "<span>";
                                            _.forEach(error.data, function (err) {
                                                mensaje = mensaje + err.message + "<br/>";
                                            });
                                            mensaje = mensaje + "</span>";
                                            notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                        });
                                    }
                                    return estados;
                                });

                            } else {
                                $scope.tituloModal = 'Error';
                                $scope.mensajeModal = 'Usted no posee permiso para realizar la operación';
                                var modalInstance5 = $modal.open({
                                    template: '<div class="modal-header">' +
                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                        "</div>" +
                                        '<div class="modal-body">{{::mensajeModal}}</div>' +
                                        '<div class="modal-footer">' +
                                        '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                        "</div>",
                                    scope: $scope
                                });
                                $scope.cancel = function () {
                                    modalInstance5.dismiss("cancel");
                                    modalInstance3.dismiss('cancel');
                                    modalInstance2.dismiss('cancel');
                                    modalInstance.dismiss('cancel');
                                };
                            }
                        }
                    }
                } else {
                    verificarSobregiro(response, itemId, modalInstance, formato);
                }
            } else {
                //no excedio
                if (response.solicitudAutorizacionBloqueo == null) {
                    aprobarComprobante(itemId, modalInstance, formato);
                } else {
                    verificarBloqueo2(response, itemId, modalInstance, formato);
                }
            }
        }

        var controlSobregiro = function (response, itemId, modalInstance, formato) {
            //si tiene pv y tiene solicitud de autorizacion de sobregiro
            if (response.pedidoVenta != null && response.pedidoVenta.solicitudAutorizacion != null) {
                //si la solicitud de sobregiro en el pv No fue autorizado
                if (response.pedidoVenta.autorizadoSolicitud == false) {
                    ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                        if (response1.valorTipo.codigo === "si") {
                            if (response.tipo.controlLimiteCredito == true) {
                                if (response.cliente.controlarLimiteCredito == true) {
                                    //obtener el saldo del cliente actualizado
                                    ClienteFactory.getSaldoCredito(response.cliente.id).then(function (resp) {
                                        //verificar la moneda del comprobante
                                        var cambio = 1;
                                        if (response.moneda.codigo != "guaranies") {
                                            if (response.cambio > 1) {
                                                cambio = response.cambio;
                                                calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                            } else if (response.cambio == null || response.cambio == 1) {
                                                //obtener de la cotizacion del dia
                                                var params = {};
                                                params.search = filterFactory.and([{
                                                    path: 'monedaDesde.id',
                                                    equals: response.moneda.id
                                                },
                                                {
                                                    path: 'id',
                                                    sortDesc: 'true'
                                                }
                                                ]).value();
                                                params.view = 'CotizacionMonedaList';
                                                CotizacionMonedaFactory.all(params).$promise.then(function (responsec) {
                                                    if (responsec && responsec[0]) {
                                                        cambio = responsec[0].valorCompra;
                                                    }
                                                    calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                                });
                                            }
                                        } else {
                                            calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                        }
                                    });
                                } else {
                                    //verificar si existe solicitud de bloqueo
                                    if (response.solicitudAutorizacionBloqueo == null) {
                                        aprobarComprobante(itemId, modalInstance, formato);
                                    } else {
                                        verificarBloqueo2(response, itemId, modalInstance, formato);
                                    }
                                }
                            } else {
                                //verificar si existe solicitud de bloqueo
                                if (response.solicitudAutorizacionBloqueo == null) {
                                    aprobarComprobante(itemId, modalInstance, formato);
                                } else {
                                    verificarBloqueo2(response, itemId, modalInstance, formato);
                                }
                            }
                        } else {
                            //verificar si existe solicitud de bloqueo
                            if (response.solicitudAutorizacionBloqueo == null) {
                                aprobarComprobante(itemId, modalInstance, formato);
                            } else {
                                verificarBloqueo2(response, itemId, modalInstance, formato);
                            }
                        }
                    });
                } else {
                    if (response.montoTotal > response.pedidoVenta.montoTotal) {
                        ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                            if (response1.valorTipo.codigo === "si") {
                                if (response.tipo.controlLimiteCredito == true) {
                                    if (response.cliente.controlarLimiteCredito == true) {
                                        //obtener el saldo del cliente actualizado
                                        ClienteFactory.getSaldoCredito(response.cliente.id).then(function (resp) {
                                            //verificar la moneda del comprobante
                                            var cambio = 1;
                                            if (response.moneda.codigo != "guaranies") {
                                                if (response.cambio > 1) {
                                                    cambio = response.cambio;
                                                    calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                                } else if (response.cambio == null || response.cambio == 1) {
                                                    //obtener de la cotizacion del dia
                                                    var params = {};
                                                    params.search = filterFactory.and([{
                                                        path: 'monedaDesde.id',
                                                        equals: response.moneda.id
                                                    },
                                                    {
                                                        path: 'id',
                                                        sortDesc: 'true'
                                                    }
                                                    ]).value();
                                                    params.view = 'CotizacionMonedaList';
                                                    CotizacionMonedaFactory.all(params).$promise.then(function (responsec) {
                                                        if (responsec && responsec[0]) {
                                                            cambio = responsec[0].valorCompra;
                                                        }
                                                        calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                                    });
                                                }
                                            } else {
                                                calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                            }
                                        });
                                    } else {
                                        //verificar si existe solicitud de bloqueo
                                        if (response.solicitudAutorizacionBloqueo == null) {
                                            aprobarComprobante(itemId, modalInstance, formato);
                                        } else {
                                            verificarBloqueo2(response, itemId, modalInstance, formato);
                                        }
                                    }
                                } else {
                                    //verificar si existe solicitud de bloqueo
                                    if (response.solicitudAutorizacionBloqueo == null) {
                                        aprobarComprobante(itemId, modalInstance, formato);
                                    } else {
                                        verificarBloqueo2(response, itemId, modalInstance, formato);
                                    }
                                }
                            } else {
                                //verificar si existe solicitud de bloqueo
                                if (response.solicitudAutorizacionBloqueo == null) {
                                    aprobarComprobante(itemId, modalInstance, formato);
                                } else {
                                    verificarBloqueo2(response, itemId, modalInstance, formato);
                                }
                            }
                        });
                    } else {
                        //verificar si existe solicitud de bloqueo
                        if (response.solicitudAutorizacionBloqueo == null) {
                            aprobarComprobante(itemId, modalInstance, formato);
                        } else {
                            verificarBloqueo2(response, itemId, modalInstance, formato);
                        }
                    }
                }
            } else {
                if (response.pedidoVenta != null) {
                    ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                        if (response1.valorTipo.codigo === "si" && (response.montoTotal > response.pedidoVenta.montoTotal)) {
                            if (response.tipo.controlLimiteCredito == true) {
                                if (response.cliente.controlarLimiteCredito == true) {
                                    //obtener el saldo del cliente actualizado
                                    ClienteFactory.getSaldoCredito(response.cliente.id).then(function (resp) {
                                        //verificar la moneda del comprobante
                                        var cambio = 1;
                                        if (response.moneda.codigo != "guaranies") {
                                            if (response.cambio > 1) {
                                                cambio = response.cambio;
                                                calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                            } else if (response.cambio == null || response.cambio == 1) {
                                                //obtener de la cotizacion del dia
                                                var params = {};
                                                params.search = filterFactory.and([{
                                                    path: 'monedaDesde.id',
                                                    equals: response.moneda.id
                                                },
                                                {
                                                    path: 'id',
                                                    sortDesc: 'true'
                                                }
                                                ]).value();
                                                params.view = 'CotizacionMonedaList';
                                                CotizacionMonedaFactory.all(params).$promise.then(function (responsec) {
                                                    if (responsec && responsec[0]) {
                                                        cambio = responsec[0].valorCompra;
                                                    }
                                                    calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                                });
                                            }
                                        } else {
                                            calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                        }
                                    });
                                } else {
                                    //verificar si existe solicitud de bloqueo
                                    if (response.solicitudAutorizacionBloqueo == null) {
                                        aprobarComprobante(itemId, modalInstance, formato);
                                    } else {
                                        verificarBloqueo2(response, itemId, modalInstance, formato);
                                    }
                                }
                            } else {
                                //verificar si existe solicitud de bloqueo
                                if (response.solicitudAutorizacionBloqueo == null) {
                                    aprobarComprobante(itemId, modalInstance, formato);
                                } else {
                                    verificarBloqueo2(response, itemId, modalInstance, formato);
                                }
                            }
                        } else {
                            //verificar si existe solicitud de bloqueo
                            if (response.solicitudAutorizacionBloqueo == null) {
                                aprobarComprobante(itemId, modalInstance, formato);
                            } else {
                                verificarBloqueo2(response, itemId, modalInstance, formato);
                            }
                        }
                    });
                } else {
                    ParametrosFactory.getByCodigo('control_limite_credito_cliente').then(function (response1) {
                        if (response1.valorTipo.codigo === "si") {
                            if (response.tipo.controlLimiteCredito == true) {
                                if (response.cliente.controlarLimiteCredito == true) {
                                    //obtener el saldo del cliente actualizado
                                    ClienteFactory.getSaldoCredito(response.cliente.id).then(function (resp) {
                                        //verificar la moneda del comprobante
                                        var cambio = 1;
                                        if (response.moneda.codigo != "guaranies") {
                                            if (response.cambio > 1) {
                                                cambio = response.cambio;
                                                calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                            } else if (response.cambio == null || response.cambio == 1) {
                                                //obtener de la cotizacion del dia
                                                var params = {};
                                                params.search = filterFactory.and([{
                                                    path: 'monedaDesde.id',
                                                    equals: response.moneda.id
                                                },
                                                {
                                                    path: 'id',
                                                    sortDesc: 'true'
                                                }
                                                ]).value();
                                                params.view = 'CotizacionMonedaList';
                                                CotizacionMonedaFactory.all(params).$promise.then(function (responsec) {
                                                    if (responsec && responsec[0]) {
                                                        cambio = responsec[0].valorCompra;
                                                    }
                                                    calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                                });
                                            }
                                        } else {
                                            calcularSaldo(response, itemId, modalInstance, cambio, resp, formato);
                                        }
                                    });
                                } else {
                                    //verificar si existe solicitud de bloqueo
                                    if (response.solicitudAutorizacionBloqueo == null) {
                                        aprobarComprobante(itemId, modalInstance, formato);
                                    } else {
                                        verificarBloqueo2(response, itemId, modalInstance, formato);
                                    }
                                }
                            } else {
                                //verificar si existe solicitud de bloqueo
                                if (response.solicitudAutorizacionBloqueo == null) {
                                    aprobarComprobante(itemId, modalInstance, formato);
                                } else {
                                    verificarBloqueo2(response, itemId, modalInstance, formato);
                                }
                            }
                        } else {
                            //verificar si existe solicitud de bloqueo
                            if (response.solicitudAutorizacionBloqueo == null) {
                                aprobarComprobante(itemId, modalInstance, formato);
                            } else {
                                verificarBloqueo2(response, itemId, modalInstance, formato);
                            }
                        }
                    });

                }
            }
        }
    }
    $scope.calcularNumerosComprobantesHasta = function (numeroInicial) {
        $scope.comprobantesFinales = [];
        vm.numeroComprobanteFinal = null;
        _.forEach($scope.comprobantes, function (comprobante) {
            if (comprobante.id >= numeroInicial.id) {
                $scope.comprobantesFinales.push(comprobante);
            }
        });
    }

    $scope.calcularNumerosComprobantesHastaImprimir = function (numeroInicial) {
        $scope.comprobantesFinalesImprimir = [];
        vm.numeroComprobanteFinalImprimir = null;
        _.forEach($scope.comprobantesImprimir, function (comprobante) {
            /* if(comprobante.id >= numeroInicial.id){
                $scope.comprobantesFinalesImprimir.push(comprobante);
            } */
            if (comprobante.numero >= numeroInicial.numero) {
                $scope.comprobantesFinalesImprimir.push(comprobante);
            }
        });
    }

    function filterTipoComprobantes(ventaId) {

        var staticFilterTC = {};
        vm.tipos = { descripcion: "SA" };
        if (ventaId) {
            TipoComprobanteFactory.getTiposComprobantes(ventaId).then(function (response) {
                vm.tipos = response.data;
            });
            /*  staticFilterTC = {
                 search: filterFactory
                     .and([{
                         path: 'comprobantesVenta.venta.id',
                         equals: ventaId
                     },
                     {
                         path: "activo",
                         equals: true
                     }
                 ])
                     .value()
             }; */
            getPuntosEmisionVenta();

        } else {
            TipoComprobanteFactory.getTiposComprobantes(0).then(function (response) {
                vm.tipos = response.data;
            });            /* staticFilterTC = {
                search: filterFactory
                    .and([{
                        path: 'comprobantesVenta.id',
                        isNotNull: true
                    },
                    {
                        path: "activo",
                        equals: true
                    }
                ]).value()
            }; */
            getPuntosEmisionVenta();

        }
        //vm.tipos = TipoComprobanteFactory.all(staticFilterTC, "ConsultaComprobanteVentaForm");
    }

    function filterTimbrado(tipoComprobante) {
        vm.timbrado = undefined;
        vm.numeroComprobanteInicialImprimir = undefined;
        vm.numeroComprobanteFinalImprimir = undefined;
        vm.puntoEmision = undefined;


        var staticFilter = {};
        if (vm.fromOutside) {
            staticFilter.search = filterFactory.and([{
                path: "timbradoEmpresa.activo",
                equals: true
            }]).value();
        } else {
            staticFilter.search = filterFactory.and([{
                path: "timbradoEmpresa.activo",
                equals: true
            }]).value();
        }


        TimbradoEmpresaPuntoEmisionVentaFactory.all(staticFilter, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
            if (response.length > 0) {
                $scope.timbrados = response;
            }
        });

    }

    function comprobantes(tipoComprobante, puntoEmision, fechaDesde, fechaHasta) {
        var staticFilter = {};
        if (vm.fromOutside) {
            staticFilter.search = filterFactory.and([{
                path: 'estado.codigo',
                equals: 'comp_venta_emitido'
            },
            {
                path: 'timbradoPuntoEmision.id',
                equals: vm.timbrado.id
            }, {
                path: 'numero',
                sortAsc: true
            },
            {
                path: 'tipo.id',
                equals: tipoComprobante.id
            }, {
                path: 'fecha',
                equalOrBefore: moment(fechaDesde).format("DD/MM/YYYY")
            }, {
                path: 'fecha',
                equalOrAfter: moment(fechaHasta).format("DD/MM/YYYY")
            }, {
                path: 'timbradoPuntoEmision.puntoEmisionVenta.id',
                equals: puntoEmision.id
            }]).value();
        } else {
            staticFilter.search = filterFactory.and([{
                path: 'venta.id',
                equals: $scope.venta.id
            }, {
                path: 'estado.codigo',
                equals: 'comp_venta_emitido'
            },
            {
                path: 'timbradoPuntoEmision.id',
                equals: vm.timbrado.id
            }, {
                path: 'numero',
                sortAsc: true
            }, {
                path: 'tipo.id',
                equals: tipoComprobante.id
            }, {
                path: 'fecha',
                equalOrBefore: moment(fechaDesde).format("DD/MM/YYYY")
            }, {
                path: 'fecha',
                equalOrAfter: moment(fechaHasta).format("DD/MM/YYYY")
            }, {
                path: 'timbradoPuntoEmision.puntoEmisionVenta.id',
                equals: puntoEmision.id
            }]).value();
        }

        vm.uiBlockuiConfig.bloquear = true;
        ComprobanteVentaFactory.all(staticFilter, "ConsultaComprobanteVentaForm").$promise.then(function (response) {
            if (response.length > 0) {
                $scope.comprobantesImprimir = response;
            } else {
                var msg = "No existen comprobantes para este Timbrado y Punto de Emisión";
                notify({ message: msg, classes: 'alert-warning', position: 'right' });
            }
            vm.uiBlockuiConfig.bloquear = false;

        });
    }
    function searchTimbradoPuntoEmisionFilter(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }

            return String(item.timbradoEmpresa.numeroTimbrado).toLowerCase().indexOf(criteria.toLowerCase()) > -1;

        };
    }


    function getPuntosEmisionVenta() {
        if (vm.watchPE) {
            vm.watchPE();
        }
        WatchLocacionChangeFactory.executeWatching(function (
            locacionFilter,
            watcher
        ) {
            vm.watcherPE = watcher;
            var filters = {
                search: filterFactory.and([{
                    path: "puntoVenta.sucursal.id",
                    equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
                }]).value()
            }
            $scope.puntosEmisionesVenta = puntoEmisionFactory.all(filters, "ConsultaComprobanteVentaForm");
        }, "locacion.id");
    }
    function puntoEmisionSelect() {
        vm.timbrado = undefined;
    }
}

'use strict';


angular.module('qualita.venta')
  .controller('TipoCobroIndexCtrl', ["$scope", "TipoCobroFactory", "VentasLangFactory", "filterFactory", "ReportTicketFactory", "$window", "CsvFactory", "UtilFactory", "baseurl", "$filter", function ($scope, TipoCobroFactory, VentasLangFactory,
  filterFactory, ReportTicketFactory, $window, CsvFactory, UtilFactory, baseurl, $filter) {

    var defaultColumnOrder = ['id', 'nombre', 'tipoCobro.descripcion', 'tipoCondicion.descripcion', 'imprimeRecibo', 'activo'];


    VentasLangFactory.getTranslations().then(function(translations) {
      $scope.options = {
        'resource': 'tipocobro',
        'title': $filter('translate')('TIPOS_COBROS'),
        'factory': TipoCobroFactory,
        'view': 'BaseList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('TIPOS_COBROS_DELETE_FAILED'),
        'columns': [
          {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
          {'data': 'nombre', 'title': $filter('translate')('NOMBRE'), renderWith: 'emptyRender', class: "dt-center"},
          {'data': 'tipoCobro.descripcion', 'title': $filter('translate')('TIPO_COBRO'), renderWith: 'emptyRender', class: "dt-center"},
          {'data': 'tipoCondicion.descripcion', 'title': $filter('translate')('TIPO_CONDICION'), renderWith: 'emptyRender', class: "dt-center"},
          {'data': 'imprimeRecibo', 'title': $filter('translate')('IMPRIME_RECIBO'), 'class': 'dt-center', 'visible': true, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'},
          {'data': 'activo', 'title': $filter('translate')('ACTIVO'), 'class': 'dt-center', 'visible': true, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'}
        ],
        'hasOptions' : true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraMenuOptions':
          [
            {
              'title': $filter('translate')('REPORT'),
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoTipoCobro', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("tipocobro", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ]
      };
    });


    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({path: data, like: search})
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }
  }]);

'use strict';

angular.module('qualita.venta')
        .controller('TipoCobroFormCtrl', ['$rootScope', '$scope', 'TipoCobroFactory', 'VentasLangFactory', 'TiposFactory', 'formFactory', '$location',
            'tipoCobroPrepService', '$state', 'notify', '$filter', 'TiposTipoFactory',
            function ($rootScope, $scope, TipoCobroFactory, VentasLangFactory, TiposFactory, formFactory,
                    $location, tipoCobroPrepService, $state, notify, $filter, TiposTipoFactory) {

                var vm = this;

                activate();
                $scope.submit = submit;
                $scope.cancel = cancel;

                function activate() {
                    VentasLangFactory.getTranslations().then(function (translations) {
                        $scope.translations = translations;
                        $rootScope.isProcessing = false;

                        vm.tiposCobro = TiposTipoFactory.get("tipo_cobro");
                        TiposFactory.condicionTipoCobro().then(function (data) {
                            vm.condiciones = data.data;
                        });

                        if ($state.is("app.tipocobro.new")) {
                            activateNew();
                        } else if ($state.is("app.tipocobro.edit")) {
                            activateEdit();
                        } else if ($state.is("app.tipocobro.view")) {
                            activateView();
                        }
                    });
                }

                function activateNew() {
                    $scope.title = $filter('translate')('NUEVO_TIPO_COBRO');
                    $scope.model = {};
                }

                function activateEdit() {
                    $scope.title = $filter('translate')('EDIT_TIPO_COBRO');
                    $scope.model = tipoCobroPrepService;
                    $scope.entidadId = $scope.model.id;
                    $scope.entidad = "TipoCobro";
                    TiposFactory.condicionTipoComprobante().then(function (data) {
                        $scope.condiciones = data.data;
                    });
                }

                function activateView() {
                    $scope.title = $filter('translate')('VIEW_TIPO_COBRO');
                    $scope.model = tipoCobroPrepService;
                    $scope.entidadId = $scope.model.id;
                    $scope.entidad = "TipoCobro";
                    $scope.view = true;
                    
                }

                function submit(form) {
                    $scope.submited = true;
                    if ($scope.TipoCobroForm.$valid) {
                        formFactory.defaultNSFSubmit($scope.TipoCobroForm, TipoCobroFactory, $scope.model, errorHandler).then(function (response) {
                            $location.path('/tipocobro');
                        }, function (error) {
                            console.log(error);
                        });
                    } else {
                        //notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
                    }
                }

                function errorHandler(e) {
                    var msg = '';
                    _.forEach(e.data, function (error) {
                        /* if (error.constraint == 'descripcion') {
                            msg += '\n\n' + "Ya existe un tipo de comprobante con el mismo nombre.\n";
                        } else {
                            msg += '\n\n' + error.message + '.';
                        } */
                        msg += '\n\n' + error.message + '.';
                    });
                    notify({
                        message: msg,
                        classes: 'alert-danger',
                        position: 'right'
                    });
                }

                function cancel() {
                    $location.path('/tipocobro');
                }

            }
        ]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.tipoCobro
 * @description
 * # tipoCobro
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('TipoCobroFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory',
    function ($resource, baseurl, filterFactory, TiposFactory) {

      var TipoCobro = $resource(baseurl.getBaseUrl() + '/tipocobro/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        }
      });

      return {
        all: function(params, view) {
          if (params) {
            params.view = view != undefined ? view : 'BaseList';
          }else {
            params = [];
            params.view = view!=undefined ? view : 'BaseList';
          }
          return TipoCobro.query(params);
        },

        get: function (id, view) {
          return TipoCobro.get({ id: id, view: view ? view : "base" });
        },

        create: function (attrs) {
          return new TipoCobro(attrs);
        },

        save: function (tipoCobro) {
          return (tipoCobro.id) ? tipoCobro.$update() : tipoCobro.$save();
        },

        remove: function (tipoCobro) {
          return tipoCobro.$remove();
        },

        schema: function () {
          return schema;
        },

        form: function (type) {
          return forms[type];
        }
      };
    }]);

'use strict';


angular.module('qualita.venta')
    .controller('VendedoresIndexCtrl', ["$state", "$scope", "VendedorFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", function ($state, $scope, VendedorFactory, filterFactory,
        ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl,AuthorizationService) {

        var dateRender = function (data, type, full) {
            if (data) {
                return moment.utc(data).format('DD/MM/YYYY');
            } else {
                return "";
            }
        };

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        var renderSupervisor = function (data) {
            if (data == undefined)
                return "";
            else
                return data.nombres + " " + data.apellidos;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'codigo', 'nombres', 'apellidos', 'tipoDocumento.descripcion',
                'numero', 'direccion', 'ciudad.descripcion', 'supervisor', 'telefono', 'correo', 'activo'
            ];
            $scope.options = {
                'resource': 'vendedores',
                'title': translations.VENDEDORES,
                'factory': VendedorFactory,
                'view': 'VendedorList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': translations.FAIL_DELETE_VENDEDOR,
                'columns': [
                    { 'data': 'id', 'title': translations.ID, visible: false  },
                    { 'data': 'codigo', 'title': translations.CODIGO_VENDEDOR, 'class': 'dt-left' },
                    { 'data': 'nombres', 'title': translations.NOMBRES, 'class': 'dt-left' },
                    { 'data': 'apellidos', 'title': translations.APELLIDOS, 'class': 'dt-left' },
                    { 'data': 'tipoDocumento.descripcion', 'title': translations.TIPO_DOCUMENTO, 'class': 'dt-left' },
                    { 'data': 'numero', 'title': translations.NUMERO_DOCUMENTO, 'class': 'dt-left' },
                    { 'data': 'direccion', 'title': translations.DIRECCION, renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'ciudad.descricpcion', 'title': translations.CIUDAD, renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'supervisor', 'title': 'Supervisor', renderWith: renderSupervisor, 'class': 'dt-left', searchable: false},
                    { 'data': 'telefono', 'title': translations.NUMERO_TELEFONO, renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'correo', 'title': translations.CORRE_ELECTRONICO, renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'render': renderEmptyData, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }

                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraMenuOptions':[
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function() { return true; },
                        'action': function() {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("vendedores", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                            .then(function(response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                        }
                    }
                ]
            };
            /*var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if(search === 'No' && data === 'activo'){
                        search = 'false';
                    }
                    if(search === 'Sí' && data === 'activo'){
                        search = 'true';
                    }
        
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }*/
        });
    }]);
'use strict';

/**
 * @ngdoc function
 * @name controller:VendedorFormCtrl
 * @description
 * # VendedorFormCtrl
 * Controller 
 */
angular.module('qualita.venta')
  .controller('VendedorFormCtrl', VendedorFormCtrl);

VendedorFormCtrl.$inject = ['$rootScope','filterFactory', '$scope', 'VendedorFactory', '$location', '$state',
'vendedorPrepService', 'notify', 'formFactory', 'VentasLangFactory', 'TiposFactory','TipoClienteFactory','$timeout', '$filter', 'ParametrosFactory', 'TiposTipoFactory',
'baseurl','UsuariosFactory','ClienteFactory', 'PedidoVentaFactory', 'ComprobanteVentaFactory'];

function VendedorFormCtrl($rootScope,filterFactory, $scope, VendedorFactory, $location, $state,
  vendedorPrepService, notify, formFactory, VentasLangFactory, TiposFactory,TipoClienteFactory, $timeout, $filter, ParametrosFactory, TiposTipoFactory,
  baseurl,UsuariosFactory,ClienteFactory, PedidoVentaFactory, ComprobanteVentaFactory) {


  var vm = this;

  if($state.is("app.vendedores.new")) {
    vm.isAsync = false;
  } else if($state.is("app.vendedores.edit")) {
    vm.isAsync = true;
  } else if($state.is("app.vendedores.view")) {
    vm.isAsync = true;
    vm.isView = true;
  }

  activate();
  //var $scope = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.changeCheckVendedorOrSupervisor = changeCheckVendedorOrSupervisor;

  vm.grupos = [
    {id: 1, nombre: "Grupo 1"},
    {id: 2, nombre: "Grupo 2"},
    {id: 3, nombre: "Grupo 3"}
  ]

  function activate() {
    vm.staticFilterCiudad = [{path: "tipoTipo.codigo", equals: "pais_ciudad"}];
    TiposFactory.periodoMontoMeta().then(function (data) {
      vm.periodos = data.data;
  });
    vm.showUsuarios = false;
    vm.TiposFactory = TiposFactory;
    vm.disableDocumento=false;
    VentasLangFactory.getTranslations().then(function(translations) {
      $scope.translations=translations;
      if($state.is("app.vendedores.new")) {
        activateNew();
      } else if($state.is("app.vendedores.edit")) {
        activateEdit();
      } else if($state.is("app.vendedores.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited =false;


      TiposFactory.tipoPersonaCliente().then(function (data) {
        vm.tiposPersonas = data.data;
      });


      vm.tiposClientes =TipoClienteFactory.all();
      TiposFactory.tipoDocumentoCliente().then(function (data) {
        vm.tiposDocumentod = data.data;

      });
    });
    $scope.$watch('vm.vendedor.locaciones', function (newValue, oldValue) {
      if (newValue != undefined && newValue.length > 0) {
        vm.showUsuarios = true;
        var usersFilter = {};
        usersFilter.search = filterFactory.and([{
          path: 'activo',
          equals: true
        }]).value();
        usersFilter.view = "BaseList"
        UsuariosFactory.all(usersFilter).$promise.then(function (data) {
          vm.usuarios = data;
        });
      }

    }, true);

  }

  function activateNew() {
    vm.title = $scope.translations.NEW_VENDEDOR;
    vm.vendedor = {};
    vm.vendedor.vendedor = true;
    vm.vendedor.activo = true;
    var filtroVendedor = {};
    filtroVendedor.search = filterFactory.and([
      {
        path: 'isSupervisor',
        equals: true
      },
      {
        path: 'activo',
        equals: true
      },
      { path: 'id',
        notEquals: vm.vendedor.id
      }
    ]).value();
    filtroVendedor.view = "VendedorList";
    vm.supervisores = VendedorFactory.all(filtroVendedor);
  }

  function activateEdit() {
    vm.vendedorLoaded = false;
    vm.isGestor = false;
    vm.isCobrador = false;
    vm.isVendedor = false;
    vm.title = $scope.translations.EDIT_CLIENTE;
    vm.vendedor = vendedorPrepService;
    vm.vendedor.grupoVendedor = _.find(vm.grupos, function(grupo){
      return grupo.id === vm.vendedor.grupoVendedor;
    })
    vm.entidadId = vm.vendedor.id;
    vm.entidad = "Vendedor";

    //verificar si tiene asociacion con ventas o pedidos de ventas
    vm.tieneAsociacion = false;
    var filterVendedorPvC = {};
    filterVendedorPvC.search = filterFactory.and([
      {
        path: 'vendedor.id',
        equals: vm.vendedor.id
      }
    ]).value();
    PedidoVentaFactory.all(filterVendedorPvC).$promise.then(function (response) {
      if(response.length > 0){
        vm.tieneAsociacion = true;
      }      
    });
    //verificar en ventas
    ComprobanteVentaFactory.all(filterVendedorPvC).$promise.then(function (response) {
      if(response.length > 0){
        vm.tieneAsociacion = true;
      }    
    });

    var filtroVendedor = {};
    filtroVendedor.search = filterFactory.and([
      {
        path: 'isSupervisor',
        equals: true
      },
      {
        path: 'activo',
        equals: true
      },
      { path: 'id',
        notEquals: vm.vendedor.id
      }
    ]).value();
    filtroVendedor.view = "VendedorList";
    vm.supervisores = VendedorFactory.all(filtroVendedor);

    if(vm.vendedor.gestores.length > 0){
      vm.isGestor = true;
    }
    if(vm.vendedor.cobradores.length > 0){
      vm.isCobrador = true;
    }

    if(vm.vendedor.vendedores.length > 0){
      vm.isVendedor = true;
    }

    var filtroSupervisor = {};
    filtroSupervisor.search = filterFactory.and([
      {
        path: 'activo',
        equals: true
      },
      { path: 'supervisor.id',
        equals: vm.vendedor.id
      }
    ]).value();
    filtroSupervisor.view = "VendedorList";
    vm.super = VendedorFactory.all(filtroSupervisor).$promise.then(function (data) {
        if(data.length >0){
          vm.soySupervisor = true;
        }
    });

    vm.vendedorLoaded = true;
  }

  function activateView() {
    vm.vendedorLoaded = false;
    console.log("view");
    vm.title = $scope.translations.VIEW_CLIENTE;
    vm.vendedor = vendedorPrepService;
    vm.entidadId = vm.vendedor.id;
    vm.entidad = "Vendedor";
    vm.view = true;
    vm.vendedorLoaded = true;
  }

  function submit() {
    console.log("submit");
    vm.submited = true;
    if ($scope.VendedorForm.$valid) {
      if (vm.vendedor.vendedor || vm.vendedor.isSupervisor || vm.vendedor.cobrador || vm.vendedor.gestor) {
        var auxGrupoVendedor = null;
        if (vm.vendedor.locaciones === null || vm.vendedor.locaciones.length === 0) {
          notify({ message: $filter('translate')('ERROR_LOCACIONES_OBLIGATORIO'), classes: 'alert-danger', position: 'right' });
          return;
        }
        auxGrupoVendedor = vm.vendedor.grupoVendedor;
        if (vm.vendedor.isSupervisor || vm.vendedor.vendedor) {
          vm.vendedor.grupoVendedor = vm.vendedor.grupoVendedor.id;
        }

        formFactory.defaultNSFSubmit($scope.VendedorForm, VendedorFactory, vm.vendedor, errorHandler).then(function (response) {
          $location.path('/vendedores');
          ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
            if (parametro.valorTipo.codigo == 'si') {
              if (response.enviadoAContabilidad == false) {
                notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
              }
            }
          });
        }, function (error) {
          vm.vendedor.grupoVendedor = auxGrupoVendedor;
          console.log(error);
        });
      } else {
        notify({ message: $filter('translate')('CLASE_VENDEDOR_ERROR'), classes: 'alert-danger', position: 'right' });
      }
    } else {
      //notify({ message: $scope.translations.WRONG_FIELDS, classes: 'alert-danger', position: 'right'});
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
        if(error.constraint == 'codigo_sucursal, id_cliente'){
          msg += '\n\n' + $filter('translate')('SUCURSAL_EXISTS') + "\n"
        }else if(error.constraint == 'codigo'){
          msg += '\n\n' + $filter('translate')('CODIGO_EXISTS') + "\n"
        }else{
          msg += '\n\n' + error.message + '.';
        }

    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function cancel() {
    $location.path("/vendedores");
  }

  function changeCheckVendedorOrSupervisor(){
    if(!vm.vendedor.isSupervisor && !vm.vendedor.vendedor){
      vm.vendedor.maximoPorcentajeDescuentoPermitido= undefined;
      vm.vendedor.porcentajeComision = undefined;
      vm.vendedor.montoMeta = undefined;
      vm.vendedor.periodoMontoMeta = undefined;
    }
  }

  $scope.resource = 'vendedores'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.cuentabancaria
 * @description
 * # cuentabancaria
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('VendedorFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var Vendedor = $resource(baseurl.getBaseUrl() + '/vendedores/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    var savedData = {};

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'VendedorList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'VendedorList';
        }
        return Vendedor.query(params);
      },

      get: function (id, view) {
        return Vendedor.get({ id: id, view: view != undefined ? view : 'VendedorForm' });
      },

      create: function (attrs) {
        return new Vendedor(attrs);
      },

      save: function (vendedor) {
        return (vendedor.id) ? vendedor.$update() : vendedor.$save();
      },

      remove: function (vendedor) {
        return vendedor.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function () {
        return form;
      },

      setData: function set(data) {
        //$window.alert("set");
        savedData = data;
      },

      getData: function get() {
        //$window.alert("get");
        return savedData;
      },
    };
  }]);

'use strict';


angular.module('qualita.venta')
    .controller('CamionIndexCtrl', ["$state", "$scope", "CamionFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "notify", "$modal", function ($state, $scope, CamionFactory,
        filterFactory, ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl,
        AuthorizationService, notify, $modal) {

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'marca', 'modelo', 'anio',
                'numeroChapa', 'capacidad', 'activo', 'observacion'
            ];
            $scope.options = {
                'resource': 'camiones',
                'title': 'Camiones',
                'factory': CamionFactory,
                'view': 'CamionList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': translations.FAIL_DELETE_CAMION,
                'columns': [
                    { 'data': 'id', 'title': 'Código', visible: false },
                    { 'data': 'marca', 'title': 'Marca', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'modelo', 'title': 'Modelo', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'anio', 'title': 'Año', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'numeroChapa', 'title': 'Nro. Chapa', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'capacidad', 'title': 'Capacidad', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'activo', 'title': 'Activo', 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
                    { 'data': 'observacion', 'title': 'Observación', renderWith: renderEmptyData, 'class': 'dt-left' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                    [
                        {
                            title: translations.NEW,
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function () {
                                return true;
                            },
                            action: function () {
                                if (AuthorizationService.hasPermission("create_camiones") == true) {
                                    $state.go("app.camiones.new");
                                } else {
                                    var msg = 'No posee permiso para crear Camiones';
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("camiones", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoCamiones', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }
                    ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: 'canEditar',
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("update_camiones") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "editar",
                        functionDef: function (atributos) {
                            var itemId = atributos[0];
                            if (AuthorizationService.hasPermission("update_camiones") === true) {
                                $state.go('app.camiones.edit', { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: "canBorrar",
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("delete_camiones") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "borrar",
                        functionDef: function (itemId) {
                            $scope.selectedItemId = itemId;

                            if (AuthorizationService.hasPermission("delete_camiones") === true) {
                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING');

                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: '<div class="modal-header">' +
                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                        '</div>' +
                                        '<div class="modal-body">{{::mensajeModal}}' +
                                        '<hr width="1%">' +
                                        '</div>' +
                                        '<div class="modal-footer">' +
                                        '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                        '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                        '</div>',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                };

                                $scope.ok = function (itemId) {
                                    var model = CamionFactory.create({ id: itemId });
                                    CamionFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                            $scope.mensajeModal = $filter("translate")(
                                                "DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS"
                                            );
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                        }
                                    );
                                };
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                            $filter("translate")("VIEW") +
                            "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function (itemId) {
                            if (AuthorizationService.hasPermission("index_camiones") === true) {
                                $state.go("app.camiones.view", { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        },
                        conditionName: "canVer",
                        conditionDef: function () {
                            return true;
                        }
                    }
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if (search === 'No' && data === 'activo') {
                        search = 'false';
                    }
                    if (search === 'Sí' && data === 'activo') {
                        search = 'true';
                    }

                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);
'use strict';

angular.module('qualita.venta')
  .controller('CamionesFormCtrl', CamionesFormCtrl);

CamionesFormCtrl.$inject = ['$rootScope', '$scope', 'CamionFactory', '$state',
  'camionesPrepService', 'notify', 'VentasLangFactory', 'TiposFactory', 'ChoferFactory', 'filterFactory'];

function CamionesFormCtrl($rootScope, $scope, CamionFactory, $state,
  camionesPrepService, notify, VentasLangFactory,TiposFactory, ChoferFactory, filterFactory) {


  var vm = this;

  activate();

  vm.cancel = cancel;
  vm.submit = submit;

  function activate() {
    VentasLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      TiposFactory.tipoTransporte().then(function (data) {
        vm.tiposTransporte = data.data;
      });

      TiposFactory.tipoVehiculo().then(function (data) {
          vm.tiposVehiculo = data.data;
      });
      vm.choferes = [];
      var filtroChofer = {};
        filtroChofer.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();
        // ChoferFactory.all(filtroChofer, "CamionForm");
        ChoferFactory.all(filtroChofer, "ChoferForm").$promise.then(function (response) {
          _.forEach(response, function (chofer) {
              vm.choferes.push(chofer);
              // vm.accesos2.push(acceso);
          });
      });


      if ($state.is("app.camiones.new")) {
        activateNew();
      } else if ($state.is("app.camiones.edit")) {
        activateEdit();
      } else if ($state.is("app.camiones.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateNew() {
    vm.title = 'Nuevo Camión';
    vm.camion = {};
    vm.camion.activo = true;
  }

  function activateEdit() {
    vm.title = 'Editar Camión';
    vm.camion = camionesPrepService;
    vm.entidadId = vm.camion.id;
    vm.entidad = "Camion";
  }

  function activateView() {
    vm.title = 'Ver Camión';
    vm.camion = camionesPrepService;
    vm.entidadId = vm.camion.id;
    vm.entidad = "Camion";
    vm.view = true;
  }

  function submit() {
    vm.submited = true;

    if ($scope.CamionForm.$valid) {
      $rootScope.isProcessing = true;

      submitCamion().then(function () {
        $state.go('app.camiones.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        console.log(error);
        var msg = "";
        _.forEach(error.data, function (e) {
          msg += '\n\n' + e.message + '.';
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function submitCamion() {
    var resource = CamionFactory.create(vm.camion);
    return CamionFactory.save(resource);
  }

  function cancel() {
    $state.go('app.camiones.list');
  }

  $scope.resource = 'camiones';
}

angular.module('qualita.venta')
  .factory('CamionFactory', CamionFactory);

  CamionFactory.$inject = ['$resource', 'baseurl'];

function CamionFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var Camion = $resource(baseurl.getBaseUrl() + "/camiones/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'base';
    } else {
      params = [];
      params.view = view != undefined ? view : 'base';
    }
    return Camion.query(params);
  }

  function get(id, view) {
    return Camion.get({ id: id, view: view ? view : "base" });
  }

  function create(attrs) {
    return new Camion(attrs);
  }

  function save(camion) {
    return (camion.id) ? camion.$update() : camion.$save();
  }

  function remove(camion) {
    return camion.$remove();
  }
}
'use strict';


angular.module('qualita.venta')
    .controller('ChoferIndexCtrl', ["$state", "$scope", "ChoferFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "notify", "$modal", function ($state, $scope, ChoferFactory,
        filterFactory, ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl,
        AuthorizationService, notify, $modal) {

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'nombres', 'apellidos', 'tipoDocumento.descripcion',
                'numeroDocumento', 'transportista.nombre', 'activo'
            ];
            $scope.options = {
                'resource': 'choferes',
                'title': 'Choferes',
                'factory': ChoferFactory,
                'view': 'ChoferList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': translations.FAIL_DELETE_CHOFER,
                'columns': [
                    { 'data': 'id', 'title': 'Código', visible: false },
                    { 'data': 'nombres', 'title': 'Nombres', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'apellidos', 'title': 'Apellidos', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'tipoDocumento.descripcion', 'title': 'Tipo Documento', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'numeroDocumento', 'title': 'Nro. Documento', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'transportista.nombre', 'title': 'Transportista', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'activo', 'title': 'Activo', 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                    [
                        {
                            title: translations.NEW,
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function () {
                                return true;
                            },
                            action: function () {
                                if (AuthorizationService.hasPermission("create_choferes") == true) {
                                    $state.go("app.choferes.new");
                                } else {
                                    var msg = 'No posee permiso para crear Choferes';
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("choferes", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoChoferes', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }
                    ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: 'canEditar',
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("update_choferes") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "editar",
                        functionDef: function (atributos) {
                            var itemId = atributos[0];
                            if (AuthorizationService.hasPermission("update_choferes") === true) {
                                $state.go('app.choferes.edit', { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: "canBorrar",
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("delete_choferes") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "borrar",
                        functionDef: function (itemId) {
                            $scope.selectedItemId = itemId;

                            if (AuthorizationService.hasPermission("delete_choferes") === true) {
                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING');

                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: '<div class="modal-header">' +
                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                        '</div>' +
                                        '<div class="modal-body">{{::mensajeModal}}' +
                                        '<hr width="1%">' +
                                        '</div>' +
                                        '<div class="modal-footer">' +
                                        '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                        '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                        '</div>',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                };

                                $scope.ok = function (itemId) {
                                    var model = ChoferFactory.create({ id: itemId });
                                    ChoferFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                            $scope.mensajeModal = $filter("translate")(
                                                "DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS"
                                            );
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                        }
                                    );
                                };
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                            $filter("translate")("VIEW") +
                            "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function (itemId) {
                            if (AuthorizationService.hasPermission("index_choferes") === true) {
                                $state.go("app.choferes.view", { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        },
                        conditionName: "canVer",
                        conditionDef: function () {
                            return true;
                        }
                    }
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if (search === 'No' && data === 'activo') {
                        search = 'false';
                    }
                    if (search === 'Sí' && data === 'activo') {
                        search = 'true';
                    }

                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);
'use strict';

angular.module('qualita.venta')
  .controller('ChoferFormCtrl', ChoferFormCtrl);

ChoferFormCtrl.$inject = ['$rootScope', '$scope', 'ChoferFactory', '$state',
  'choferesPrepService', 'notify', 'VentasLangFactory', 'TiposDocumentosFactory', 'ProveedoresFactory', 'filterFactory'];

function ChoferFormCtrl($rootScope, $scope, ChoferFactory, $state,
  choferesPrepService, notify, VentasLangFactory, TiposDocumentosFactory, ProveedoresFactory, filterFactory) {


  var vm = this;

  activate();

  vm.cancel = cancel;
  vm.submit = submit;
  vm.verificarControlTimbradoyRuc = verificarControlTimbradoyRuc;
  vm.verificarRuc = verificarRuc;
  vm.changeNroTimbrado = changeNroTimbrado;
  vm.getDigitoVerificador = getDigitoVerificador;



  function activate() {
    VentasLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      if ($state.is("app.choferes.new")) {
        activateNew();
      } else if ($state.is("app.choferes.edit")) {
        activateEdit();
      } else if ($state.is("app.choferes.view")) {
        activateView();
      }
      vm.tiposDocumentos = TiposDocumentosFactory.all();

      var filtroActivo = {};
      filtroActivo.search = filterFactory.and([
        {
          path: 'activo',
          like: 'true'
        },
        {
          path: 'claseProveedor.codigo',
          like: 'transportista'
        }
      ]).value();
      filtroActivo.view = "ChoferForm";

      ProveedoresFactory.all(filtroActivo).$promise.then(function (response) {
        console.log('tras ', response);
        vm.transportistas = response;
      });

      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateNew() {
    vm.title = 'Nuevo Chófer';
    vm.chofer = {};
    vm.chofer.activo = true;
  }

  function activateEdit() {
    vm.title = 'Editar Chófer';
    vm.chofer = choferesPrepService;
    vm.entidadId = vm.chofer.id;
    vm.entidad = "Chofer";
  }

  function activateView() {
    vm.title = 'Ver Chófer';
    vm.chofer = choferesPrepService;
    vm.entidadId = vm.chofer.id;
    vm.entidad = "Chofer";
    vm.view = true;
  }

  function verificarControlTimbradoyRuc() {
    vm.chofer.numeroDocumento = ! null ? vm.chofer.numeroDocumento = null : null

    if (vm.chofer.tipoDocumento.codigo != "ruc") {
      $scope.ChoferForm.numeroDocumento.$setValidity("error", true);
    }
  }

  function verificarRuc() {
    if (vm.chofer.tipoDocumento && vm.chofer.tipoDocumento.codigo == "ruc" && vm.chofer.numeroDocumento) {
      var split = vm.chofer.numeroDocumento.split("-");
      var digito = getDigitoVerificador(vm.chofer.numeroDocumento);

      if (split[1] && split[1].length > 1) {
        $scope.ChoferForm.numeroDocumento.$error.pattern = true;
        $scope.ChoferForm.numeroDocumento.$setValidity("error", false);
      }
      else {
        var testCaracteresEspeciales = /^(\d*\-*)*$/.test(vm.chofer.numeroDocumento);

        if (!testCaracteresEspeciales) {
          $scope.ChoferForm.numeroDocumento.$error.pattern = true;
          $scope.ChoferForm.numeroDocumento.$setValidity("error", false);
        }
        else {
          if (split[1] == undefined) {
            vm.chofer.numeroDocumento = vm.chofer.numeroDocumento.concat("-" + digito);
          }
          if (split[1] == "") {
            vm.chofer.numeroDocumento = vm.chofer.numeroDocumento.concat(digito);
          }
          $scope.ChoferForm.numeroDocumento.$setValidity("error", true);
        }
      }
    }
  }

  function getDigitoVerificador(codigo) {
    var sumaPares = 0;
    var sumaImpares = 0;

    for (var i = 0; i < (codigo.length - 1); i++) {
      var caracter = codigo.charAt(i);
      var c = parseInt(caracter);
      if (!c) {
        console.log("!c");
        continue;
      }
      if ((i + 1) % 2 == 0) {
        sumaPares += c;
      } else {
        sumaImpares += c;
      }
    }

    var multPares = sumaPares * 13;

    var sumaTotal = multPares + sumaImpares;

    var multParesStr = sumaTotal.toString();

    var lengthStr = multParesStr.length;

    var digito = multParesStr.charAt(lengthStr - 1);

    var digitoInt = 10 - parseInt(digito);

    var resultado = digitoInt == 10 ? 0 : digitoInt;

    return resultado;
  }

  function changeNroTimbrado() {
    if (vm.chofer.numeroDocumento) {
      vm.chofer.numeroDocumento = vm.chofer.numeroDocumento.split(' ').join('');
    }
  }

  function submit() {
    vm.submited = true;

    if ($scope.ChoferForm.$valid) {
      $rootScope.isProcessing = true;

      submitChofer().then(function () {
        $state.go('app.choferes.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        console.log(error);
        var msg = "";
        _.forEach(error.data, function (e) {
          msg += '\n\n' + e.message + '.';
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function submitChofer() {
    var resource = ChoferFactory.create(vm.chofer);
    return ChoferFactory.save(resource);
  }

  function cancel() {
    $state.go('app.choferes.list');
  }

  $scope.resource = 'choferes';
}
angular.module('qualita.venta')
  .factory('ChoferFactory', ChoferFactory);

  ChoferFactory.$inject = ['$resource', 'baseurl'];

function ChoferFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var Chofer = $resource(baseurl.getBaseUrl() + "/choferes/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'base';
    } else {
      params = [];
      params.view = view != undefined ? view : 'base';
    }
    return Chofer.query(params);
  }

  function get(id, view) {
    return Chofer.get({ id: id, view: view ? view : "base" });
  }

  function create(attrs) {
    return new Chofer(attrs);
  }

  function save(chofer) {
    return (chofer.id) ? chofer.$update() : chofer.$save();
  }

  function remove(chofer) {
    return chofer.$remove();
  }
}
'use strict';


angular.module('qualita.venta')
    .controller('ZonaIndexCtrl', ["$state", "$scope", "ZonaFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "notify", "$modal", function ($state, $scope, ZonaFactory,
        filterFactory, ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl,
        AuthorizationService, notify, $modal) {

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'codigo', 'zona', 'descripcion',
                'ciudad.descripcion', 'departamento.descripcion', 'activo'
            ];
            $scope.options = {
                'resource': 'zonas',
                'title': 'Zonas',
                'factory': ZonaFactory,
                'view': 'ZonaList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': translations.FAIL_DELETE_ZONA,
                'columns': [
                    { 'data': 'id', 'title': 'Código Interno', visible: false },
                    { 'data': 'codigo', 'title': 'Código', visible: false },
                    { 'data': 'zona', 'title': 'Zona', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'descripcion', 'title': 'Descripción', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'ciudad.descripcion', 'title': 'Ciudad', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'departamento.descripcion', 'title': 'Departamento', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'activo', 'title': 'Activo', 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                    [
                        {
                            title: translations.NEW,
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function () {
                                return true;
                            },
                            action: function () {
                                if (AuthorizationService.hasPermission("create_zonas") == true) {
                                    $state.go("app.zonas.new");
                                } else {
                                    var msg = 'No posee permiso para crear Zonas';
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("zonas", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoZonas', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }
                    ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: 'canEditar',
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("update_zonas") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "editar",
                        functionDef: function (atributos) {
                            var itemId = atributos[0];
                            if (AuthorizationService.hasPermission("update_zonas") === true) {
                                $state.go('app.zonas.edit', { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: "canBorrar",
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("delete_zonas") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "borrar",
                        functionDef: function (itemId) {
                            $scope.selectedItemId = itemId;

                            if (AuthorizationService.hasPermission("delete_zonas") === true) {
                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING');

                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: '<div class="modal-header">' +
                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                        '</div>' +
                                        '<div class="modal-body">{{::mensajeModal}}' +
                                        '<hr width="1%">' +
                                        '</div>' +
                                        '<div class="modal-footer">' +
                                        '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                        '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                        '</div>',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                };

                                $scope.ok = function (itemId) {
                                    var model = ZonaFactory.create({ id: itemId });
                                    ZonaFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                            $scope.mensajeModal = $filter("translate")(
                                                "DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS"
                                            );
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                        }
                                    );
                                };
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                            $filter("translate")("VIEW") +
                            "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function (itemId) {
                            if (AuthorizationService.hasPermission("index_zonas") === true) {
                                $state.go("app.zonas.view", { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        },
                        conditionName: "canVer",
                        conditionDef: function () {
                            return true;
                        }
                    }
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if (search === 'No' && data === 'activo') {
                        search = 'false';
                    }
                    if (search === 'Sí' && data === 'activo') {
                        search = 'true';
                    }

                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);
'use strict';

angular.module('qualita.venta')
  .controller('ZonaFormCtrl', ZonaFormCtrl);

  ZonaFormCtrl.$inject = ['$rootScope', '$scope', 'ZonaFactory', '$state',
  'zonasPrepService', 'notify', 'VentasLangFactory', 'TiposFactory'];

function ZonaFormCtrl($rootScope, $scope, ZonaFactory, $state,
  zonasPrepService, notify, VentasLangFactory, TiposFactory) {


  var vm = this;

  activate();

  vm.cancel = cancel;
  vm.submit = submit;

  function activate() {
    VentasLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      if ($state.is("app.zonas.new")) {
        activateNew();
      } else if ($state.is("app.zonas.edit")) {
        activateEdit();
      } else if ($state.is("app.zonas.view")) {
        activateView();
      }

      vm.staticFilterCiudad = [{path: "tipoTipo.codigo", equals: "pais_ciudad"}];
      vm.staticFilterDepartamento = [{path: "tipoTipo.codigo", equals: "pais_departamento"}];
      vm.TiposFactory = TiposFactory;

      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateNew() {
    vm.title = 'Nueva Zona';
    vm.zona = {};
    vm.zona.activo = true;
  }

  function activateEdit() {
    vm.title = 'Editar Zona';
    vm.zona = zonasPrepService;
    vm.entidadId = vm.zona.id;
    vm.entidad = "Zona";
  }

  function activateView() {
    vm.title = 'Ver Zona';
    vm.zona = zonasPrepService;
    vm.entidadId = vm.zona.id;
    vm.entidad = "Zona";
    vm.view = true;
  }

  function submit() {
    vm.submited = true;

    if ($scope.ZonaForm.$valid) {
      $rootScope.isProcessing = true;

      submitZona().then(function () {
        $state.go('app.zonas.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        console.log(error);
        var msg = "";
        _.forEach(error.data, function (e) {
          msg += '\n\n' + e.message + '.';
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function submitZona() {
    var resource = ZonaFactory.create(vm.zona);
    return ZonaFactory.save(resource);
  }

  function cancel() {
    $state.go('app.zonas.list');
  }

  $scope.resource = 'zonas';
}
angular.module('qualita.venta')
  .factory('ZonaFactory', ZonaFactory);

  ZonaFactory.$inject = ['$resource', 'baseurl'];

function ZonaFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var Zona = $resource(baseurl.getBaseUrl() + "/zonas/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'base';
    } else {
      params = [];
      params.view = view != undefined ? view : 'base';
    }
    return Zona.query(params);
  }

  function get(id, view) {
    return Zona.get({ id: id, view: view ? view : "base" });
  }

  function create(attrs) {
    return new Zona(attrs);
  }

  function save(zona) {
    return (zona.id) ? zona.$update() : zona.$save();
  }

  function remove(zona) {
    return zona.$remove();
  }
}
/**
 * @ngdoc service
 * @name qualita.ultimocostoalmacen
 * @description
 * # arqueo caja
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('ultimoCostoAlmacenFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var ultimoCostoAlmacen = $resource(baseurl.getBaseUrl() + '/ultimocostoalmacen/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });
    var idUltimoCostoAlmacen;

    return {
      setIdUltimoCostoAlmacen : function(id){
        idUltimoCostoAlmacen = id;
      },
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return ultimoCostoAlmacen.query(params);
      },
      get: function(id,view) {
        //if (typeof(view)==='undefined') view = "base";
        return ultimoCostoAlmacen.get({id: id, view: view!=undefined ? view : 'UltimoCostoAlmacenForm'});
      },
      create: function (attrs) {
        return new ultimoCostoAlmacen(attrs);
      },
      save: function (ultimoCostoAlmacen) {
        return (ultimoCostoAlmacen.id) ? ultimoCostoAlmacen.$update() : ultimoCostoAlmacen.$save();
      },
      remove: function (ultimoCostoAlmacen) {
        return ultimoCostoAlmacen.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      }
    };
  }]);

'use strict';


angular.module('qualita.venta')
    .controller('RutaIndexCtrl', ["$state", "$scope", "RutaFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "notify", "$modal", function ($state, $scope, RutaFactory,
        filterFactory, ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl,
        AuthorizationService, notify, $modal) {

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'nombreRuta', 'ruta', 'sucursal.nombre', 'activo'
            ];
            $scope.options = {
                'resource': 'rutas',
                'title': 'Rutas',
                'factory': RutaFactory,
                'view': 'RutaList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': translations.FAIL_DELETE_RUTA,
                'columns': [
                    { 'data': 'id', 'title': 'Código', visible: false },
                    { 'data': 'nombreRuta', 'title': 'Nombre Ruta', renderWith: renderEmptyData, 'class': 'dt-left'},
                    { 'data': 'ruta', 'title': 'Ruta', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'sucursal.nombre', 'title': 'Sucursal', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'activo', 'title': 'Activo', 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                    [
                        {
                            title: translations.NEW,
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function () {
                                return true;
                            },
                            action: function () {
                                if (AuthorizationService.hasPermission("create_rutas") == true) {
                                    $state.go("app.rutas.new");
                                } else {
                                    var msg = 'No posee permiso para crear Rutas';
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("rutas", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoRutas', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }
                    ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: 'canEditar',
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("update_rutas") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "editar",
                        functionDef: function (atributos) {
                            var itemId = atributos[0];
                            if (AuthorizationService.hasPermission("update_rutas") === true) {
                                $state.go('app.rutas.edit', { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: "canBorrar",
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("delete_rutas") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "borrar",
                        functionDef: function (itemId) {
                            $scope.selectedItemId = itemId;

                            if (AuthorizationService.hasPermission("delete_rutas") === true) {
                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING');

                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: '<div class="modal-header">' +
                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                        '</div>' +
                                        '<div class="modal-body">{{::mensajeModal}}' +
                                        '<hr width="1%">' +
                                        '</div>' +
                                        '<div class="modal-footer">' +
                                        '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                        '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                        '</div>',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                };

                                $scope.ok = function (itemId) {
                                    var model = RutaFactory.create({ id: itemId });
                                    RutaFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                            $scope.mensajeModal = $filter("translate")(
                                                "DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS"
                                            );
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                        }
                                    );
                                };
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                            $filter("translate")("VIEW") +
                            "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function (itemId) {
                            if (AuthorizationService.hasPermission("index_rutas") === true) {
                                $state.go("app.rutas.view", { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        },
                        conditionName: "canVer",
                        conditionDef: function () {
                            return true;
                        }
                    }
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if (search === 'No' && data === 'activo') {
                        search = 'false';
                    }
                    if (search === 'Sí' && data === 'activo') {
                        search = 'true';
                    }

                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);
'use strict';

angular.module('qualita.venta')
  .controller('RutaFormCtrl', RutaFormCtrl);

RutaFormCtrl.$inject = ['$rootScope', '$scope', 'RutaFactory', '$state',
  'rutasPrepService', 'notify', 'VentasLangFactory', 'TiposFactory', 'SucursalesFactory', 'filterFactory', 'ZonaFactory'];

function RutaFormCtrl($rootScope, $scope, RutaFactory, $state,
  rutasPrepService, notify, VentasLangFactory, TiposFactory, SucursalesFactory, filterFactory, ZonaFactory) {


  var vm = this;

  activate();

  vm.cancel = cancel;
  vm.submit = submit;
  vm.delayRequestZonas = delayRequestZonas;


  function activate() {
    VentasLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      if ($state.is("app.rutas.new")) {
        activateNew();
      } else if ($state.is("app.rutas.edit")) {
        activateEdit();
      } else if ($state.is("app.rutas.view")) {
        activateView();
      }

      vm.sucursales = SucursalesFactory.all();

      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateNew() {
    vm.title = 'Nueva Ruta';
    vm.ruta = {};
    vm.ruta.activo = true;
  }

  function activateEdit() {
    vm.title = 'Editar Ruta';
    vm.ruta = rutasPrepService;
    vm.entidadId = vm.ruta.id;
    vm.entidad = "Ruta";
  }

  function activateView() {
    vm.title = 'Ver Ruta';
    vm.ruta = rutasPrepService;
    vm.entidadId = vm.ruta.id;
    vm.entidad = "Ruta";
    vm.view = true;
  }

  function delayRequestZonas(searchValue) {
    if (searchValue.length >= 3) {
      var paramsZon = {};
      paramsZon.search = filterFactory.and([
        {
          path: 'activo',
          like: 'true'
        },
        {
          path: 'zona',
          like: searchValue
        }]
      ).value();
      vm.zonas = ZonaFactory.all(paramsZon, "RutaForm");
    } else {
      vm.zonas = undefined
    }
  }

  function submit() {
    vm.submited = true;

    if ($scope.RutaForm.$valid) {
      $rootScope.isProcessing = true;

      submitRuta().then(function () {
        $state.go('app.rutas.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        console.log(error);
        var msg = "";
        _.forEach(error.data, function (e) {
          msg += '\n\n' + e.message + '.';
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function submitRuta() {
    var resource = RutaFactory.create(vm.ruta);
    return RutaFactory.save(resource);
  }

  function cancel() {
    $state.go('app.rutas.list');
  }

  $scope.resource = 'rutas';
}
angular.module('qualita.venta')
  .factory('RutaFactory', RutaFactory);

  RutaFactory.$inject = ['$resource', 'baseurl'];

function RutaFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var Ruta = $resource(baseurl.getBaseUrl() + "/rutas/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'base';
    } else {
      params = [];
      params.view = view != undefined ? view : 'base';
    }
    return Ruta.query(params);
  }

  function get(id, view) {
    return Ruta.get({ id: id, view: view ? view : "base" });
  }

  function create(attrs) {
    return new Ruta(attrs);
  }

  function save(ruta) {
    return (ruta.id) ? ruta.$update() : ruta.$save();
  }

  function remove(ruta) {
    return ruta.$remove();
  }
}
'use strict';


angular.module('qualita.venta')
    .controller('PlanillaRepartoIndexCtrl', ["$state", "$scope", "PlanillaRepartoFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "$modal", "TiposFactory", "TiposTipoFactory", "ClienteFactory", "notify", "ModelTrimmer", "TipoClienteFactory", "ParametrosFactory", "$rootScope", "WatchLocacionChangeFactory", "PeriodoAdministrativoFactory", "$q", "OrdenCargaFactory", function ($state, $scope, PlanillaRepartoFactory, filterFactory,
        ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory,
        baseurl, AuthorizationService, $modal, TiposFactory, TiposTipoFactory, ClienteFactory, notify,
        ModelTrimmer, TipoClienteFactory, ParametrosFactory, $rootScope, WatchLocacionChangeFactory,
        PeriodoAdministrativoFactory, $q, OrdenCargaFactory) {

        var vm = this;
        vm.reparto = {};

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        var dateRender = function (data, type, full) {
            if (data) {
                return moment.utc(data).format("DD/MM/YYYY");
            } else {
                return "";
            }
        };


        VentasLangFactory.getTranslations().then(function (translations) {
            vm.translations = translations;
            var defaultColumnOrder = ['id', 'numero', 'fechaPlanillaReparto', 'fechaSalida','estadoRendicion.descripcion', 'chofer.nombresyapellidos', 'camion.detalleCamion', 'descripcion', 'estado.descripcion'];
            $scope.options = {
                'resource': 'planillasrepartos',
                title: $filter("translate")("PLANILLA_REPARTO"),
                'factory': PlanillaRepartoFactory,
                'view': 'PlanillaRepartoList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'id', 'title': translations.ID, visible: false },
                    { 'data': 'numero', 'title': $filter('translate')('NUMERO_PLANILLA') },
                    { 'data': 'fechaPlanillaReparto', title: $filter("translate")("FECHA_PLANILLA_REPARTO"), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'fechaSalida', title: $filter("translate")("FECHA_SALIDA"), 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'estadoRendicion.descripcion', title: $filter("translate")("ESTADO_RENDICION"), 'class': 'dt-center', 'type': 'combo', 'filterUrl': "tipos/filtro/estados_rendicion", 'render': renderEmptyData },
                    { 'data': 'chofer.nombresyapellidos', title: $filter("translate")("CHOFER"), 'render': renderEmptyData, visible: true },
                    { 'data': 'camion.detalleCamion', title: $filter("translate")("VEHICULO"), 'render': renderEmptyData, visible: true },
                    { 'data': 'descripcion', title: $filter("translate")("DESCRIPCION"), 'render': renderEmptyData, visible: false },
                    { 'data': 'estado.descripcion', title: $filter("translate")("ESTADO"), 'class': 'dt-center', 'type': 'combo', 'filterUrl': "tipos/filtro/estado_planilla_reparto", 'render': renderEmptyData }],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideViewMenu': true,
                'hideRemoveMenu': true,
                'extraMenuOptions':
                    [{
                        title: $filter("translate")("NEW"),
                        icon: "glyphicon glyphicon-plus",
                        showCondition: function () {
                            return true;
                        },
                        action: function () {
                            if (AuthorizationService.hasPermission("create_planillasrepartos") == true) {
                                checkPeriodoAdministrativo().then(function (resultado) {
                                    if (resultado == true) {
                                        $state.go("app.planillasrepartos.new");
                                    } else if (resultado == false) {
                                        var msg = $filter('translate')('NO_OPEN_PERIODS_NOW');
                                        notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                    }
                                });
                            } else {
                                var msg = 'No posee permiso para crear Planillas de Reparto';
                                notify({ message: msg, classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = createFilters($scope.options.getFilters());
                            ReportTicketFactory.ticket('listadoPlanillaReparto', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                                vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                $window.open(vm.pdfDownloadURL, '_blank');
                            });
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                            CsvFactory.csv("planillasrepartos", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                .then(function (response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    }], 'extraRowOptions': [
                        {
                            templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
                                $filter("translate")("DELETE") +
                                "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                            customAttribute: "estado",
                            conditionName: "canBorrar",
                            conditionDef: function (estado) {
                                return (AuthorizationService.hasPermission("delete_planillasrepartos") && (estado.codigo === "planilla_ingresada"));
                            },
                            functionName: "borrar",
                            functionDef: function (itemId) {
                                $scope.selectedItemId = itemId;
                                $scope.tituloModal = $filter("translate")("DELETE_CONFIRMATION");
                                $scope.mensajeModal = $filter("translate")("DELETE_WARNING");
                                var modalInstanceBorrar1 = $modal.open({
                                    templateUrl: "views/directives/confirmation-modal.html",
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstanceBorrar1.dismiss("cancel");
                                };

                                $scope.ok = function (itemId) {
                                    var model = PlanillaRepartoFactory.create({ id: itemId });
                                    PlanillaRepartoFactory.remove(model).then(
                                        function () {
                                            modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            $scope.tituloModal = vm.translations.DELETE_FAILED;
                                            $scope.mensajeModal = "No se pudo borrar el registro. Verifique si hay operaciones asociadas.";
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                            console.log("error al borrar: ");
                                            console.log(error);
                                        }
                                    );
                                };
                            }

                        },
                        {
                            templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' title='" + $filter('translate')('EDIT') + "' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                            customAttribute: "estado",
                            conditionName: "canEditar",
                            conditionDef: function (estado) {
                                return (AuthorizationService.hasPermission('update_planillasrepartos') && (estado.codigo === "planilla_ingresada"));

                            },
                            functionName: "editar",
                            functionDef: function (itemId) {
                                PlanillaRepartoFactory.get(itemId, "PlanillaRepartoForm").$promise.then(function (planillaReparto) {
                                    //controlar que no exista ya una orden creada para la planilla en cuestion
                                    var filtersArray = [];
                                    filtersArray.push({
                                        path: "planillaReparto.id",
                                        equals: planillaReparto.id
                                    });
                                    filtersArray.push(
                                        {
                                            path: 'estado.codigo',
                                            notEquals: 'estado_orden_carga_anulado'
                                        }
                                    );
                                    var filters = { search: filterFactory.and(filtersArray).value() };
                                    OrdenCargaFactory.all(filters).$promise.then(function (response) {
                                        if (response.length > 0) {
                                            notify({ message: "La planilla de reparto ya cuenta con orden de carga, no se puede editar. Favor refrescar la página.", classes: 'alert-danger', position: 'right' });
                                        } else {
                                            $state.go('app.planillasrepartos.edit', { id: itemId });
                                        }
                                    });
                                });
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-info btn-dt' style='margin-right: 5px;' title='" +
                                $filter("translate")("VIEW") +
                                "' ng-class='{ hidden : !canView()}' ng-click='view(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                            conditionName: "canView",
                            conditionDef: function () {
                                return AuthorizationService.hasPermission("index_planillasrepartos");
                            },
                            functionName: "view",
                            functionDef: function (itemId) {
                                PlanillaRepartoFactory.get(itemId, "PlanillaRepartoForm").$promise.then(function (planillaReparto) {
                                    {
                                        $state.go('app.planillasrepartos.view', { id: itemId });
                                    }

                                });
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-warning' title='" +
                                'Generación de Orden de Carga' +
                                "' style='margin-right: 5px;' ng-click='ordenCarga(<%=dataCustom%>)' ng-class='{ hidden : !canOrdenCarga(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-bed'></span> </button>",
                            customAttribute: ["id", "estado"],
                            functionName: "ordenCarga",
                            functionDef: function (atributos) {
                                var itemId = atributos[0];
                                PlanillaRepartoFactory.get(itemId, "PlanillaRepartoForm").$promise.then(function (planillaReparto) {
                                    //controlar que no exista ya una orden creada para la planilla en cuestion
                                    var filtersArray = [];
                                    filtersArray.push({
                                        path: "planillaReparto.id",
                                        equals: planillaReparto.id
                                    });
                                    filtersArray.push(
                                        {
                                            path: 'estado.codigo',
                                            notEquals: 'estado_orden_carga_anulado'
                                        }
                                    );
                                    var filters = { search: filterFactory.and(filtersArray).value() };
                                    OrdenCargaFactory.all(filters).$promise.then(function (response) {
                                        if (response.length > 0) {
                                            notify({ message: "La planilla de reparto ya cuenta con orden de carga. Favor refrescar la página.", classes: 'alert-danger', position: 'right' });
                                        } else {
                                            $state.go("app.ordencarga.new", { id: itemId });
                                        }
                                    });
                                });
                            },
                            conditionName: "canOrdenCarga",
                            conditionDef: function (atributos) {
                                var estado = atributos[1];
                                if ((AuthorizationService.hasPermission("create_ordencarga") === true) && (estado.codigo === "planilla_ingresada")) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        },
                        {
                            templateToRender: "<button class='btn btn-warning' title='" +
                                'Consulta Orden de Carga' +
                                "' style='margin-right: 5px;' ng-click='consultaOrdenCarga(<%=dataCustom%>)' ng-class='{ hidden : !canConsultaOrdenCarga(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-share'></span> </button>",
                            customAttribute: ["id", "estado"],
                            functionName: "consultaOrdenCarga",
                            functionDef: function (atributos) {
                                var itemId = atributos[0];
                                var params = {};
                                params.search = filterFactory
                                    .single({
                                        path: "planillaReparto.id",
                                        equals: itemId
                                    })
                                    .value();

                                OrdenCargaFactory.all(params).$promise.then(function (response) {
                                    if (response && response[0]) {
                                        var itemId = response[0].id;
                                        $state.go("app.ordencarga.view", { id: itemId });
                                    }
                                });
                            },
                            conditionName: "canConsultaOrdenCarga",
                            conditionDef: function (atributos) {
                                var estado = atributos[1];
                                if ((AuthorizationService.hasPermission("index_ordencarga") === true) && (estado.codigo !== "planilla_ingresada")) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        }
                        ,{
                            templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='" +
                                    "Modificación de Chofer" +
                                    "' ng-click='editarChofer(<%=dataId%>)' ng-class='{ hidden : !canEditarChofer(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                            functionName: "editarChofer",
                            functionDef: function (itemId) {
                                $state.go('app.planillasrepartos.edit', { id: itemId, editChofer: true });
                            },
                            conditionName: "canEditarChofer",
                            customAttribute: "estado",
                            conditionDef: function (estado) {
                                console.log(estado.codigo != "planilla_ingresada");
                                return (AuthorizationService.hasPermission('modificar_chofer') && (estado.codigo != "planilla_ingresada"));
                            }
                        },{
                          templateToRender: "<button title='" + $filter('translate')('DEFINIR_ESTADO_RENDICION') + "' class='btn btn-warning' style='margin-right: 5px;' ng-click='definirRendicionReparto(<%=dataCustom%>)' ng-class='{ hidden : !canDefinirRendicion(<%=dataId%>)}'> <span class='glyphicon glyphicon-stats'></span> </button>",
                          customAttribute: "id",
                          conditionName: "canDefinirRendicion",
                          conditionDef: function (itemId) {
                              var permission = AuthorizationService.hasPermission("asignar_estado_rendicion");
                              return permission;
                          },
                          functionName: "definirRendicionReparto",
                          functionDef: function (itemId) {

                              $scope.estadosRendicion = TiposTipoFactory.get("estados_rendicion");

                              var modalInstance = $modal.open({
                                  templateUrl: 'views/directives/estado-rendicion-modal.html',
                                  scope: $scope
                              });

                              $scope.cancel = function () {
                                  modalInstance.dismiss('cancel');
                              };

                              $scope.ok = function (estadoRendicion) {
                                  $scope.submited = true;
                                  if(estadoRendicion != undefined){
                                      PlanillaRepartoFactory.actualizarEstadoRendicion(itemId, estadoRendicion.codigo).then(function(){
                                          modalInstance.close();
                                          $state.go($state.current, {}, { reload: true });
                                      });
                                  }
                              }
                          }
                      }

                    ]
            };
            WatchLocacionChangeFactory.watchWith(undefined, $scope.options, "locacion.id");
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });

        var createFilters = function (filters) {
            var filtersArr = [];
            _.each(filters, function (search, data) {
                filtersArr.push({ path: data, like: search })
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        }

        var checkPeriodoAdministrativo = function () {
            return $q(function (resolve, reject) {

                var params = {};
                var fecha;
                fecha = new Date();

                params.search = filterFactory
                    .and([{
                        path: "estado.codigo",
                        equals: "per_adm_abierto"
                    },
                    {
                        path: "fechaDesde",
                        equalOrBefore: moment(fecha).format("DD/MM/YYYY")
                    },
                    {
                        path: "fechaHasta",
                        equalOrAfter: moment(fecha).format("DD/MM/YYYY")
                    }
                    ])
                    .value();

                PeriodoAdministrativoFactory.all(params).$promise.then(function (responsePeriodo) {
                    if (responsePeriodo.length > 0) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            });
        }
        $rootScope.isProcessing = false;
    }]);

angular.module('qualita.venta')
  .factory('PlanillaRepartoFactory', PlanillaRepartoFactory);

  PlanillaRepartoFactory.$inject = ['$resource', 'baseurl','$http'];

function PlanillaRepartoFactory($resource, baseurl, $http) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    actualizarEstadoRendicion: actualizarEstadoRendicion
  };

  var PlanillaReporte = $resource(baseurl.getBaseUrl() + "/planillasrepartos/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'base';
    } else {
      params = [];
      params.view = view != undefined ? view : 'base';
    }
    return PlanillaReporte.query(params);
  }

  function get(id, view) {
    return PlanillaReporte.get({ id: id, view: view ? view : "base" });
  }

  function create(attrs) {
    return new PlanillaReporte(attrs);
  }

  function save(planillaReparto) {
    return (planillaReparto.id) ? planillaReparto.$update() : planillaReparto.$save();
  }

  function remove(planillaReparto) {
    return planillaReparto.$remove();
  }

  function actualizarEstadoRendicion(idPlanillaReparto, codigoEstadoRendicion) {
    return $http({
        url: baseurl.getBaseUrl() + '/planillasrepartos/updateplanillareparto/' + idPlanillaReparto + "/" + codigoEstadoRendicion,
        method: "GET"
    });
}
}

angular.module('qualita.venta')
    .controller('PlanillaRepartoFormCtrl', PlanillaRepartoFormCtrl);

    PlanillaRepartoFormCtrl.$inject = ['$rootScope', '$scope', '$state', 'CotizacionMonedaFactory', 'TiposTipoFactory',
    'ModelTrimmer', 'formFactory', '$location', 'notify', 'filterFactory', '$q', '$translate', '$rootScope','WatchLocacionChangeFactory',
    '$filter', 'TiposFactory','planillasRepartosPrepService',
    'RutaFactory','ClienteFactory','ChoferFactory','CamionFactory','VentasLangFactory','PedidoVentaFactory','PlanillaRepartoFactory',
    '$stateParams'
];

function PlanillaRepartoFormCtrl($rootScope, $scope, $state, CotizacionMonedaFactory, TiposTipoFactory
    ,ModelTrimmer, formFactory, $location, notify,filterFactory, $q, $translate, $rootScope, WatchLocacionChangeFactory,
    $filter, TiposFactory,planillasRepartosPrepService,
    RutaFactory,ClienteFactory,ChoferFactory,CamionFactory,VentasLangFactory,PedidoVentaFactory,PlanillaRepartoFactory,
    $stateParams) {

    var vm = this;

    vm.cancel = cancel;
    vm.submit = submit;
    vm.buscarPedidosVentas = buscarPedidosVentas;
    vm.clearFiltrosPedidosVentas = clearFiltrosPedidosVentas;
    $rootScope.isProcessing = false;
    vm.listaDistribucionIngresada = [];
    vm.fromModal = false;
    vm.chequesFraccionados = [];
    vm.ultimoNroCheque = 0;
    vm.cantidadTotalCheques=0;
    vm.ordenesPagoFraccionados = [];
    vm.numerosChequesFraccionados = [];
    vm.controlFechaSalida = controlFechaSalida;
    vm.rutasSelected = rutasSelected;
    vm.camionSelected = camionSelected;
    vm.staticFilterClientes = {};

    vm.editChofer = $stateParams.editChofer ? $stateParams.editChofer : false;

    activate();

    function activate() {

        vm.TiposFactory = TiposFactory;
        vm.staticFilterCiudad = [{path: "tipoTipo.codigo", equals: "pais_ciudad"}];
        WatchLocacionChangeFactory.killAllWatchers();

        var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();
        var filtroChofer = {};
        filtroChofer.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }, {
            path: 'transportista.id',
            isNotNull: "true"
        }]).value();
        vm.monedaLocal = CotizacionMonedaFactory.getMonedaLocal();
        vm.monedas = TiposTipoFactory.get("monedas");
        vm.planillaReparto = planillasRepartosPrepService;
        vm.rutas = RutaFactory.all(filtroActivo, "PlanillaRepartoForm");
        vm.clientes = ClienteFactory.all(filtroActivo);
        vm.choferes = ChoferFactory.all(filtroChofer, "PlanillaRepartoForm");
        vm.camiones = CamionFactory.all(filtroActivo, "PlanillaRepartoForm");

        vm.pesoSuperado = false;
        VentasLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;

            var defaultColumnOrder = ['id', 'cliente.nombre', 'sucursalCliente.descripcion', 'montoTotal', 'fecha', 'sucursalCliente.ciudad.descripcion',
            'sucursalCliente.direccion', 'kilos'];
            if($state.is("app.desembolsoordenpago.new")){
                vm.isSelectableOP = true;
            }else{
                vm.isSelectableOP = false;
            }
            vm.optionsDtPedidosVentas = {
                'resource': 'pedidoventas',
                'title': $filter('translate')('PEDIDO_VENTA_LIST'),
                'factory': PedidoVentaFactory,
                'view' : "PedidoVentaList",
                'defaultColumnOrder': defaultColumnOrder,
                'colReorder': true,
                'columns': [
                    { 'data': 'id', 'title': translations.ID, visible: true },
                    { 'data': 'cliente.nombre', 'title': translations.CLIENTE, renderWith: renderEmptyData },
                    { 'data': 'sucursalCliente.ciudad.descripcion', 'title': translations.CIUDAD, renderWith: renderEmptyData },
                    { 'data': 'sucursalCliente.direccion', 'title': translations.DIRECCION, renderWith: renderEmptyData },
                    { 'data': 'fecha', 'title': translations.FECHA, renderWith: "dateRender", dateFormat: "DD/MM/YYYY", class: "dt-center", type: "date-range"},
                    { 'data': 'sucursalCliente.descripcion', 'title': translations.SUCURSAL_CLIENTE, renderWith: renderEmptyData },
                    { 'data': "montoTotal", 'title': $filter("translate")("MONTO"), 'renderWith': monedaRender, class: "dt-left" },
                    { 'data': 'kilos', 'title': translations.KILOS, renderWith: renderEmptyData, orderable:false, searchable:false }
                ],
                'isSelectable': true,
                'selection': {},
                'hasOptions': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'defaultOrderColumn': 2,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'extraRowOptions': [{
                  templateToRender: "<a class='btn btn-info' style='margin-right: 5px;' title='" + $filter('translate')('VIEW') + "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </a>",
                  functionName: "ver",
                  functionDef: function (itemId) {
                    var base = $location.$$absUrl.substring(0, decodeURIComponent($location.$$absUrl).indexOf($location.$$path, $location.$$absUrl.indexOf("#")) + 1);
                     var url = base + "pedidoventas/"+itemId;
                     window.open(url, '_blank').focus();
                  },
                  conditionName: "canVer",
                  conditionDef: function () {
                    return PedidoVentaFactory.hasPermisos("view");
                  }
                }]
            };

            if ($state.is("app.planillasrepartos.new")) {
                activateNew();
            } else if ($state.is("app.planillasrepartos.edit")) {
                activateEdit();
            } else if ($state.is("app.planillasrepartos.view")) {
                activateView();
            }
            $rootScope.isProcessing = false;
            vm.submited = false;

            vm.form = $scope.PlanillaRepartoForm;
        });
        /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
        vm.staticFilterClientes = [{path: "activo", equals: true}];
        vm.clientesFactory = ClienteFactory;
    }

    $scope.$watch('vm.optionsDtPedidosVentas.selection', function (newValue, oldValue) {
        vm.cantidadPedidos = 0;
        vm.importeTotal = 0;
        vm.pesoTotal = 0;
        _.forEach(newValue, function (n, key) {
            if (n){
                vm.cantidadPedidos += 1;
                PedidoVentaFactory.get(
                    key,
                    "PlanillaRepartoForm"
                ).$promise.then(function (response) {
                    if(response){
                        var pedidoVenta = response;
                        vm.importeTotal += pedidoVenta.montoTotal;
                        _.forEach(pedidoVenta.pedidoVentaDetalle, function (n,key){
                            if (n) {
                                var detalle = n;
                                var pesoDetalle=0;
                                if(detalle.incluir){
                                    if (detalle.producto.pesoUnidadBase) {
                                        pesoDetalle = detalle.producto.pesoUnidadBase * detalle.cantidadUmPrincipal;
                                        pesoDetalle = parseFloat(pesoDetalle.toFixed(2));
                                        vm.pesoTotal += pesoDetalle;
                                        vm.pesoTotal = roundNumber(vm.pesoTotal,2);
                                        if (vm.pesoTotal > vm.planillaReparto.camion.capacidad) {
                                            if(!vm.pesoSuperado){
                                                notify({ message: $filter('translate')('CAPACIDAD_SUPERADA'), classes: 'alert-warning', position: 'right' });
                                            }
                                            vm.pesoSuperado = true;
                                            return;
                                        } else {
                                            vm.pesoSuperado = false;
                                        }
                                    }
                                }
                            }
                        })

                    }
                });
            }
        });
    }, true);


    var renderEmptyData = function (data) {
        if (data == undefined)
            return "";
        else
            return data;
    };
    var monedaRender = function(data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            if (row.moneda.codigo === 'dolares') {
                moneda = "Usd. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === 'pesos') {
                moneda = "Pes. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === 'real') {
                moneda = "Rel. ";
                data = parseFloat(data).toFixed(2);
            } else if (row.moneda.codigo === 'euro') {
                moneda = "Eur. ";
                data = parseFloat(data).toFixed(2);
            }  else if (row.moneda.codigo === 'guaranies') {
                data = parseFloat(data).toFixed(0);
            }
            // TODO hacer esto mismo en todos los renders de moneda
            return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
            return '';
        }
    };

    function activateNew() {
        vm.editar = false;
        vm.search = {};
        vm.title = $filter('translate')('NEW_PLANILLA_REPARTO');
        vm.planillaReparto = {};
        vm.monedaLocal.then(function (response) {
            vm.search.moneda = response.valorTipo;
        });
        var hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        vm.planillaReparto.fechaPlanillaReparto = hoy;
        TiposFactory.estadosPlanillasRepartos().then(function (data) {
            var estadoIngresado = _.filter(data.data, function (elem) { return elem.codigo === 'planilla_ingresada'; });
            vm.planillaReparto.estado = estadoIngresado[0];
        });
    }

    function activateEdit() {

        if(vm.editChofer){
           vm.view = true;
           vm.editar = false;
           vm.optionsDtPedidosVentas.isSelectable = false;
        }else{
            vm.editar = true;
        vm.isEdit = true;
        }

        vm.search = {};
        vm.title = $filter('translate')('EDIT_PLANILLA_REPARTO');
        vm.planillaReparto = planillasRepartosPrepService;
        vm.monedaLocal.then(function (response) {
            vm.search.moneda = response.valorTipo;
        });

                var filters = {
            search: filterFactory.single({
                path: 'planillaReparto.id',
                equals: vm.planillaReparto.id
            }).value()
        };
        vm.optionsDtPedidosVentas.staticFilter = filters;
        vm.showDtPedidosVentas = true;
        formSelectionsArray();
    }

    function activateView() {
        vm.editar = false;
        vm.title = $filter('translate')('VIEW_PLANILLA_REPARTO');
        vm.planillaReparto = planillasRepartosPrepService;
        vm.view = true;
        var filters = {
            search: filterFactory.single({
                path: 'planillaReparto.id',
                equals: vm.planillaReparto.id
            }).value()
        };
        vm.optionsDtPedidosVentas.staticFilter = filters;
        vm.showDtPedidosVentas = true;

        formSelectionsArray();

        vm.search = {};

    }

    function buscarPedidosVentas() {
        if (!vm.search) {
            vm.search = {};
        }
        //vm.optionsDtPedidosVentas.selection = {};

        var filtersArray = [];

        if (vm.search.fechaDesde) {
            filtersArray.push({
                path: "fecha",
                equalOrAfter: vm.search.fechaDesde != undefined ?
                    moment(vm.search.fechaDesde).format("DD/MM/YYYY") : undefined
            });
        }

        if (vm.search.fechaHasta) {
            filtersArray.push({
                path: "fecha",
                equalOrBefore: vm.search.fechaHasta != undefined ?
                    moment(vm.search.fechaHasta).format("DD/MM/YYYY") : undefined
            });
        }

        if (vm.search.moneda) {
            filtersArray.push({
                path: "moneda.id",
                equals: vm.search.moneda.id
            });
        }

        if (vm.search.cliente) {
            filtersArray.push({
                path: "cliente.id",
                equals: vm.search.cliente.id
            });
        }
        if (vm.search.importeMinimo) {
            filtersArray.push({
                path: "montoTotal",
                greaterOrEqual: vm.search.importeMinimo
            });
        }

        if (vm.search.ruta) {
            filtersArray.push({
                path: "sucursalCliente.ruta.id",
                equals: vm.search.ruta.id
            });
        }

        if (vm.search.ciudad) {
            filtersArray.push({
                path: "sucursalCliente.ciudad.id",
                equals: vm.search.ciudad.id
            });
        }

        filtersArray.push({
            path: "planillaReparto.id",
            isNull: "true"
        });
        filtersArray.push({
          path: "ventasString",
          isNull: "true"
        });
        filtersArray.push({
            path: "locacion.sucursal.id",
            equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
        });

        var topConditions = [{
                path: 'estado.codigo',
                equals: 'pv_aprobado'
            }
        ];


        var filtersOr = _.map(topConditions, function(condition) {
            return filterFactory.and(_.union([condition], filtersArray)).value();
        });

        if (vm.planillaReparto && vm.planillaReparto.id) {
            filtersOr.push({
                path: 'planillaReparto.id',
                equals: vm.planillaReparto.id
            });
        }
        var filters = { search: filterFactory.or(filtersOr).value() };
        vm.optionsDtPedidosVentas.staticFilter = filters;
        vm.showDtPedidosVentas = true;
    }

    function clearFiltrosPedidosVentas() {
        if(vm.search.ruta){
            _.remove(vm.staticFilterClientes, function(item) {
                return item.equals === vm.search.ruta.id;
              });
        }
        vm.search = {};
    }

    function submit() {
        vm.submited = true;

        if ($scope.PlanillaRepartoForm.$valid) {
            if(!vm.pesoSuperado){
                if (vm.pesoTotal > vm.planillaReparto.camion.capacidad) {
                  notify({ message: $filter('translate')('CAPACIDAD_SUPERADA'), classes: 'alert-danger', position: 'right' });
                  return;
                }
                var trimmedModel = ModelTrimmer.trimDetails(vm.planillaReparto);
                var idsDetails = getIDs();
                if (!vm.showDtPedidosVentas || !idsDetails || idsDetails.length == 0) {
                    notify({ message: "El detalle de la planilla no debe quedar vacía.", classes: 'alert-danger', position: 'right' });
                    return;
                }
                trimmedModel.pedidosVentas = _.map(idsDetails, function(id) {
                    var idVal = parseInt(id);

                    if (!idVal) {
                        console.error("No se pudo parsear Id");
                        return;
                    }
                    return idVal;
                });
                if (!vm.isEdit) {
                    trimmedModel.locacion = $rootScope.AuthParams.locacionSeleccionada.id;
                }
                formFactory.defaultNSFSubmit($scope.PlanillaRepartoForm, PlanillaRepartoFactory, trimmedModel).then(function (response) {
                    $location.path('/planillasrepartos');
                }, function (error) {
                    console.log(error);
                });
            }else{
                var msg ='El peso total ha superado la capacidad del camión seleccionado';
                notify({ message: msg, classes: 'alert-danger', position: 'right' });
            }

        } else {
            notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right' });
        }
    }

    function cancel() {
        $location.path('/planillasrepartos');
    }

    var getIDs = function() {
        var ids = [];
        _.forEach(vm.optionsDtPedidosVentas.selection, function(n, key) {
            if (n)
                ids.push(key);
        });
        return ids;
    };

    var formSelectionsArray = function() {
        console.log("forming selections array");
        $scope.$watch('vm.optionsDtPedidosVentas.selection', function(newValue, oldValue) {
            if (!vm.optionsDtPedidosVentas.selection || vm.selectedChecked) {
                return;
            }
            vm.selectedChecked = true;
            _.forEach(vm.planillaReparto.pedidosVentas, function(op) {
                if (op.id) {
                    vm.optionsDtPedidosVentas.selection[op.id] = true;
                } else {
                    vm.optionsDtPedidosVentas.selection[op] = true;
                }
            });
        });
    };

    function controlFechaSalida() {
        if (!validacionFechas()) {
            vm.planillaReparto.fechaSalida = undefined;
            var msg = "La Fecha de Salida no puede ser menor a la fecha de hoy";
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
        };
    }

    function validacionFechas() {
        if (vm.planillaReparto.fechaSalida != undefined) {
            var hoy = new Date();
            hoy.setHours(0, 0, 0, 0);
            if (vm.planillaReparto.fechaSalida < hoy) {
                return false;
            }
        }
        return true;
    }

    function rutasSelected(){
        if (vm.search.ruta) {
            vm.staticFilterClientes.push({
                path: 'sucursales.ruta.id',
                equals: vm.search.ruta.id
            });
        }
    }
    function camionSelected(){
        if(vm.pesoTotal > 0 && vm.pesoTotal < vm.planillaReparto.camion.capacidad){
            vm.pesoSuperado = false;
        }
    }

    function roundNumber(num, scale) {
      if(!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale)  + "e-" + scale);
      } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if(+arr[1] + scale > 0) {
          sig = "+";
        }
        var i = +arr[0] + "e" + sig + (+arr[1] + scale);
        var j = Math.round(i);
        var k = +(j + "e-" + scale);
        return k;
      }
    }
    $scope.resource = 'planillasrepartos';
}

'use strict';

angular.module('qualita.venta')
  .controller('ReferenciasIndexCtrl',
    ["$scope", "$window", "VentasLangFactory", "filterFactory", "cajaFactory", "$filter", "notify", "$rootScope", "VentaFactory", "baseurl", "TiposTipoFactory", "$modal", function ($scope, $window, VentasLangFactory, filterFactory,
      cajaFactory, $filter, notify, $rootScope, VentaFactory, baseurl, TiposTipoFactory, $modal) {
      VentasLangFactory.getTranslations().then(function (translations) {
        $scope.translations = translations;

        //traer entidades del tipo tipo
        $scope.referencias = TiposTipoFactory.get("referencias_pos");
        //traer todas las cajas
        var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }, {
          path: 'cajaPos',
          like: 'true'
        }]).value();
        $scope.cajas = cajaFactory.all(filtroActivo);

        $scope.limpiarFiltros = function () {
          $scope.filtros.referencias = undefined;
          $scope.filtros.cajas = undefined;
        }
      });

      $scope.filtros = {};

      $scope.enviarPos = function () {

        $scope.tituloModal = "Envio de Referencias al POS"
        $scope.mensajeModal = "Este proceso puede tomar mucho tiempo. ¿Está seguro?";
        var modalInstance = $modal.open({
          templateUrl: 'views/directives/confirmation-modal.html',
          scope: $scope
        });

        $scope.cancel = function () {
          modalInstance.close();
        }


        $scope.vm.uiBlockuiConfig = {
          'bloquear': false,
          'message': 'Enviando Referencias al POS..'
        };

        $scope.ok = function () {

          $scope.vm.uiBlockuiConfig.bloquear = true;

          if ($scope.ReferenciasForm.$valid) {
            var filtersObj = {};
            if ($scope.filtros.referencias) {
              filtersObj.referencias = $scope.filtros.referencias.id;
            }

            if ($scope.filtros.cajas) {
              filtersObj.cajas = $scope.filtros.cajas.id;
            }

            cajaFactory.enviarCajaPos(filtersObj).then(function (response) {

              $scope.vm.uiBlockuiConfig.bloquear = false;
              var msg = "Se realizó correctamente el envío de Referencias. " + response.data.message;
              notify({ message: msg, classes: 'alert-success', position: 'right' });
            }, function (error) {
              $scope.vm.uiBlockuiConfig.bloquear = false;
              var msg = "Error al enviar. Verifique que la dirección url de envio en la caja sea el correcto o no este vacio.";
              notify({ message: msg, classes: 'alert-danger', position: 'right' });
            });

          } else {
            var msg = "Error al enviar al Pos. Filtros invalidos";
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
          }
        }
      };
    }]);

'use strict';


angular.module('qualita.venta')
    .controller('CajeroIndexCtrl', ["$state", "$scope", "CajeroFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "notify", "$modal", function ($state, $scope, CajeroFactory,
        filterFactory, ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl,
        AuthorizationService, notify, $modal) {

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'nombreCompleto', 'abrirCaja', 'categoria.descripcion', 'activo'];
            $scope.options = {
                'resource': 'cajero',
                'title': 'Cajeros',
                'factory': CajeroFactory,
                'view': 'CajeroList',
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': translations.FAIL_DELETE_CONFIGURACION_AUTORIZACION,
                'columns': [
                    { 'data': 'id', 'title': 'Código', visible: false },
                    { 'data': 'nombreCompleto', 'title': 'Nombre', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'abrirCaja', 'title': 'Abrir Caja', 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
                    { 'data': 'categoria.descripcion', 'title': 'Categoría', 'render': renderEmptyData, 'class': 'dt-center', type: 'combo', filterUrl: 'tipos/filtro/categoria_cajeros_pos' },
                    { 'data': 'activo', 'title': 'Activo', 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' },
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                    [
                        {
                            title: translations.NEW,
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function () {
                                return true;
                            },
                            action: function () {
                                if (AuthorizationService.hasPermission("create_cajero") == true) {
                                    $state.go("app.cajero.new");
                                } else {
                                    var msg = 'No posee permiso para crear Cajeros';
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("cajero", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoCajeros', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }
                    ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: 'canEditar',
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("update_cajero") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "editar",
                        functionDef: function (atributos) {
                            var itemId = atributos[0];
                            if (AuthorizationService.hasPermission("update_cajero") === true) {
                                $state.go('app.cajero.edit', { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: ["id", "estado"],
                        conditionName: "canBorrar",
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("delete_cajero") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "borrar",
                        functionDef: function (itemId) {
                            $scope.selectedItemId = itemId;
                            if (AuthorizationService.hasPermission("delete_cajero") === true) {
                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING');

                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: '<div class="modal-header">' +
                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                        '</div>' +
                                        '<div class="modal-body">{{::mensajeModal}}' +
                                        '<hr width="1%">' +
                                        '</div>' +
                                        '<div class="modal-footer">' +
                                        '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                        '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                        '</div>',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                };

                                $scope.ok = function (itemId) {
                                    var model = CajeroFactory.create({ id: itemId });
                                    CajeroFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                            $scope.mensajeModal = 'El registro se encuentra asociado a procesos de autorización ejecutados. Sólo puede ser desactivado';
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                                $scope.modalInstanceBorrar1.dismiss('cancel');
                                            };
                                        }
                                    );
                                };
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                            $filter("translate")("VIEW") +
                            "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function (itemId) {
                            if (AuthorizationService.hasPermission("index_cajero") === true) {
                                $state.go("app.cajero.view", { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        },
                        conditionName: "canVer",
                        conditionDef: function () {
                            return true;
                        }
                    },
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if (search === 'No' && data === 'activo') {
                        search = 'false';
                    }
                    if (search === 'Sí' && data === 'activo') {
                        search = 'true';
                    }

                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);
/**
 * Created by codiumsa on 19/10/15.
 */
angular.module('qualita.venta')
  .factory('CajeroFactory', CajeroFactory);

CajeroFactory.$inject = ['$resource', 'baseurl', '$http', 'VentasLangFactory'];

function CajeroFactory($resource, baseurl, $http, VentasLangFactory) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var Cajero = $resource(baseurl.getBaseUrl() + "/cajero/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'base';
    } else {
      params = [];
      params.view = view != undefined ? view : 'base';
    }
    return Cajero.query(params);
  }

  function get(id, view) {
    return Cajero.get({ id: id, view: view ? view : "base" });
  }

  function create(attrs) {
    return new Cajero(attrs);
  }

  function save(cajero) {
    return (cajero.id) ? cajero.$update() : cajero.$save();
  }

  function remove(cajero) {
    return cajero.$remove();
  }
}
'use strict';

/**
 * @ngdoc function
 * @name rasApp.controller:CajeroFormCtrl
 * @description
 * # CajeroFormCtrl
 * 
 */
angular.module('qualita.venta')
  .controller('CajeroFormCtrl', CajeroFormCtrl);

CajeroFormCtrl.$inject = ['$rootScope', 'filterFactory', '$scope', 'CajeroFactory', '$location', '$state',
  'cajeroPrepService', 'notify', 'formFactory', 'VentasLangFactory', 'TiposFactory', 'TiposTipoFactory', '$timeout', '$filter', 'ParametrosFactory', 'UsuariosFactory'];

function CajeroFormCtrl($rootScope, filterFactory, $scope, CajeroFactory, $location, $state,
  cajeroPrepService, notify, formFactory, VentasLangFactory, TiposFactory, TiposTipoFactory, $timeout, $filter, ParametrosFactory, UsuariosFactory) {


  var vm = this;

  activate();

  vm.cancel = cancel;
  vm.submit = submit;
  vm.changeCategoria = changeCategoria;

  function activate() {
    VentasLangFactory.getTranslations().then(function (translations) {

      vm.categorias = TiposTipoFactory.get("categoria_cajeros_pos");
      var filtroActivo = {};
      filtroActivo.search = filterFactory.and([{
        path: 'activo',
        like: 'true'
      }]).value();
      vm.usuarios = UsuariosFactory.all(filtroActivo);

      vm.funciones = TiposTipoFactory.get("funciones_supervisor_pos");

      ParametrosFactory.getByCodigo('integrado_pos_tdn').then(function (response) {
        if(response.valor === "Sí"){
          $scope.parametroPos = true;
        }else{
          $scope.parametroPos = false;
        }
     });


      $scope.translations = translations;
      if ($state.is("app.cajero.new")) {
        activateNew();
      } else if ($state.is("app.cajero.edit")) {
        activateEdit();
      } else if ($state.is("app.cajero.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      vm.submited = false;
    });
  }

  function activateNew() {
    vm.title = 'Nuevo Cajero/Supervisor';
    vm.cajero = { abrirCaja: true, activo: true };
    var hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    vm.cajero.fecCre = hoy;
    vm.esSupervisor = false;
    vm.isNew = true;
  }

  function activateEdit() {
    vm.title = 'Editar Cajero/Supervisor';
    vm.cajero = cajeroPrepService;
    vm.entidadId = vm.cajero.id;
    vm.entidad = "Cajero";
    if(vm.cajero.categoria.codigo == "categoria_supervisor"){
      vm.esSupervisor = true;
    }else{
      vm.esSupervisor = false;
    }
    vm.isEdit=true;
  }

  function activateView() {
    vm.title = 'Ver Cajero/Supervisor';
    vm.cajero = cajeroPrepService;
    vm.entidadId = vm.cajero.id;
    vm.entidad = "Cajero";
    vm.view = true;
    if(vm.cajero.categoria.codigo == "categoria_supervisor"){
      vm.esSupervisor = true;
    }else{
      vm.esSupervisor = false;
    }
  }

  function submit() {
    vm.submited = true;

    if ($scope.CajeroForm.$valid) {
      $rootScope.isProcessing = true;

      submitCajero().then(function () {
        $state.go('app.cajero.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        console.log(error);
        var msg = "";
        _.forEach(error.data, function (e) {
          msg += '\n\n' + e.message + '.';
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function submitCajero() {
    var resource = CajeroFactory.create(vm.cajero);
    return CajeroFactory.save(resource);
  }

  function cancel() {
    $state.go('app.cajero.list');
  }

  function changeCategoria() {
    if(vm.cajero.categoria.codigo == "categoria_supervisor"){
      vm.esSupervisor = true;
    }else{
      vm.esSupervisor = false;
      vm.cajero.funciones = null;
    }
  }

  $scope.resource = 'cajero';
}
'use strict';

angular.module('qualita.venta')
  .controller('ImpresionFlejesPreciosIndexCtrl',
    ["$scope", "$window", "VentasLangFactory", "$filter", "notify", "TiposTipoFactory", "ProductosFactory", "filterFactory", "ParametrosFactory", "CambioPrecioFactory", "WatchLocacionChangeFactory", "ClienteFactory", "UtilFactory", "$modal", "ReportTicketFactory", function ($scope, $window, VentasLangFactory, $filter, notify, TiposTipoFactory, ProductosFactory,
      filterFactory, ParametrosFactory, CambioPrecioFactory, WatchLocacionChangeFactory, ClienteFactory, UtilFactory, $modal, ReportTicketFactory) {

      var vm = this;
      vm.flejesPrecios = {};
      vm.tiposImpresiones = [{ "id": "FLEJES_PRECIOS", "descripcion": "FLEJES" }, { "id": "ETIQUETA_PRODUCTOS", "descripcion": "ETIQUETAS DE PRODUCTOS" }];
      vm.tipoImpresion = null;
      activate();

      vm.view = false;
      vm.producto = null;
      vm.changeProducto = changeProducto;
      vm.delayRequestProducto = delayRequestProducto;
      vm.factoryProductos = ProductosFactory;
      vm.removeItemFromArray = removeItemFromArray;
      vm.changeUnidad = changeUnidad;

      var defaultColumnOrder = ['codigo', 'nombre', 'claseProducto.descripcion', 'estado.descripcion',
        'lineaProducto.nombre', 'marca.descripcion'
      ];
      // TODO agregar locacionFilter
      var staticFilterProductos = {};
      staticFilterProductos.search = filterFactory.and([
        {
          path: 'itemVenta',
          like: 'true'
        },
        {
          path: 'estado.codigo',
          equals: 'activo'
        }
      ]).value();
      vm.opcionesProductosDt = {
        'resource': 'productos',
        'title': 'ProductosPresupuestoVenta',
        'view': 'ProductoList',
        'staticFilter': staticFilterProductos,
        'factory': ProductosFactory,
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'codigo', 'title': 'Código', visible: false },
          { 'data': 'nombre', 'title': 'Nombre' },
          { 'data': 'claseProducto.descripcion', 'title': 'Clase' },
          { 'data': 'estado.descripcion', 'title': 'Estado', 'type': 'combo', 'filterUrl': 'tipos/tipo/producto_estados' },
          { 'data': 'lineaProducto.nombre', 'title': 'Línea' },
          { 'data': 'marca.descripcion', 'title': 'Marca', 'renderWith': 'emptyRender' },
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc"
      };



      function activate() {
        WatchLocacionChangeFactory.killAllWatchers();
        vm.porcRecargo = 0;
        vm.precioBase = 'Precio de Venta';
        var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
          path: 'activo',
          like: 'true'
        }]).value();
        filtroActivo.view = "BaseList";
        VentasLangFactory.getTranslations().then(function (translations) {
          vm.translations = translations;
        });
      }

      function changeProducto(detalle) {
        vm.producto = detalle;
        vm.cantidad = null;
        changeUnidad(detalle.unidadMedidaBase);
      }


      function changeUnidad(detalle) {
        vm.precio = '';
        vm.unidadMedida = detalle;

        CambioPrecioFactory.getUltimoPrecio(vm.producto.id, vm.unidadMedida.id).then(function (precio) {
          vm.precioView = $scope.separadoresMiles(precio.data);
          vm.precio = vm.precioView;
        });
      }


      function delayRequestProducto(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
          var filterNombre = vm.opcionesProductosDt.staticFilter.search.filters.slice()
          var filterCodigoBarras = vm.opcionesProductosDt.staticFilter.search.filters.slice()
          var filterCodigoInterno = vm.opcionesProductosDt.staticFilter.search.filters.slice()

          filterNombre.push({
            path: "nombre",
            like: searchValue
          })

          filterCodigoBarras.push({
            path: "codigoBarrasPrincipal.codigoBarras",
            like: searchValue
          })

          filterCodigoInterno.push({
            path: "codigo",
            like: searchValue
          })

          userInputFilter = {
            search: filterFactory.or([
              filterFactory.and(filterNombre).value(),
              filterFactory.and(filterCodigoBarras).value(),
              filterFactory.and(filterCodigoInterno).value()
            ]).value()
          }

          recuperarProducto(userInputFilter)
        } else {
          vm.productos = undefined
        }
      }

      function recuperarProducto(userInputFilter) {
        var finalFilter = UtilFactory.createNestedFilters(
          vm.finalFilters, userInputFilter)

        vm.productos = ProductosFactory.allForSelect(finalFilter)
      }



      vm.searchProductoFilter = function (criteria) {
        return function (item) {
          if (!criteria) {
            return true;
          }
          return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria) === 0);
        };
      }

      function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function (item) {
          return item === elemento;
        });
        $timeout(function () {
          $scope.$broadcast('newItemRemoved');
        }, 20);
      }


      $scope.generarArchivos = function () {

        if (vm.tipoImpresion == null || typeof vm.tipoImpresion == "undefined") {
          var msg = "Debe seleccionar el tipo de impresión";
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
          return
        }
        /* if (vm.cantidad != null && typeof vm.cantidad != "undefined") { */

        if (vm.producto == null || typeof vm.producto == "undefined") {
          var msg = "Debe seleccionar un producto";
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
        } else {
          if (vm.precio != null && vm.precio != "" && vm.precio != 0.0) {
            ParametrosFactory.getByCodigo("nombre_empresa").then(function (data) {
              vm.nombreEmpresa = data;

              var filtersArr = [];
              filtersArr.push("nombre_producto=" + vm.producto.nombre);
              filtersArr.push("unidad_medida=" + vm.unidadMedida.abreviatura.toUpperCase());
              filtersArr.push("precio_venta=" + vm.precio);
              filtersArr.push("codigo_barra=" + vm.producto.codigoBarrasPrincipal.codigoBarras.slice(0, -1));
              filtersArr.push("tipo_impresion=" + vm.tipoImpresion.id);
              filtersArr.push("nombre_empresa=" + vm.nombreEmpresa.valor);

              vm.precio = '';
              vm.unidadMedida = null;
              vm.tipoImpresion = null;
              vm.productoSelected = null;
              $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('flejesprecios', 'pdf', filtersArr);
              $window.open($scope.pdfDownloadURL, '_blank');
            });

            } else {
            var msg = "El producto " + vm.producto.codigo + " - " + vm.producto.nombre + ", no cuenta con un precio configurado";
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
            return;
          }

        }
        /* } else {
          var msg = "Debe ingresar la cantidad a imprimir";
          notify({ message: msg, classes: 'alert-danger', position: 'right' });
        } */
      };

      $scope.separadoresMiles = function (numero) {
        var reg = /\d{1,3}(?=(\d{3})+$)/g;
        return (Math.round(numero) + '').replace(reg, '$&.');
      };

    }]);

'use strict';


angular.module('qualita.venta')
    .controller('TipoActividadRamoClienteIndexCtrl', ["$state", "$scope", "TipoActividadRamoClienteFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "notify", "$modal", function ($state, $scope, TipoActividadRamoClienteFactory,
        filterFactory, ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl,
        AuthorizationService, notify, $modal) {

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'codigo', 'nombre', 'descripcion','activo'];
            $scope.options = {
                'resource': 'tipoactividadramocliente',
                'title': 'Tipo de Actividad o Ramo de Clientes',
                'factory': TipoActividadRamoClienteFactory,
                'view': 'TipoActividadRamoClienteList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'id', 'title': 'Código', visible: false },
                    { 'data': 'codigo', 'title': 'Código', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'nombre', 'title': 'Nombre', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'descripcion', 'title': 'Descripción', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'activo', 'title': 'Activo', 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values'}
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                    [
                        {
                            title: translations.NEW,
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function () {
                                return true;
                            },
                            action: function () {
                                if (AuthorizationService.hasPermission("create_tipoactividadramocliente") == true) {
                                    $state.go("app.tipoactividadramocliente.new");
                                } else {
                                    var msg = 'No posee permiso para crear un Tipo de Actividad o Ramo de Cliente';
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("tipoactividadramocliente", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoTipoActividadRamoClientes', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }
                    ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: 'canEditar',
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("update_tipoactividadramocliente") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "editar",
                        functionDef: function (atributos) {
                            var itemId = atributos[0];
                            if (AuthorizationService.hasPermission("update_tipoactividadramocliente") === true) {
                                $state.go('app.tipoactividadramocliente.edit', { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: "canBorrar",
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("delete_tipoactividadramocliente") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "borrar",
                        functionDef: function (itemId) {
                            $scope.selectedItemId = itemId;

                            if (AuthorizationService.hasPermission("delete_tipoactividadramocliente") === true) {
                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING');

                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: '<div class="modal-header">' +
                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                        '</div>' +
                                        '<div class="modal-body">{{::mensajeModal}}' +
                                        '<hr width="1%">' +
                                        '</div>' +
                                        '<div class="modal-footer">' +
                                        '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                        '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                        '</div>',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                };

                                $scope.ok = function (itemId) {
                                    var model = TipoActividadRamoClienteFactory.create({ id: itemId });
                                    TipoActividadRamoClienteFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                            $scope.mensajeModal =error.data[0].message;

                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                        }
                                    );
                                };
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                            $filter("translate")("VIEW") +
                            "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function (itemId) {
                            if (AuthorizationService.hasPermission("index_tipoactividadramocliente") === true) {
                                $state.go("app.tipoactividadramocliente.view", { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        },
                        conditionName: "canVer",
                        conditionDef: function () {
                            return true;
                        }
                    }
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if (search === 'No' && data === 'activo') {
                        search = 'false';
                    }
                    if (search === 'Sí' && data === 'activo') {
                        search = 'true';
                    }

                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);
'use strict';

angular.module('qualita.venta')
  .controller('TipoActividadRamoClienteFormCtrl', TipoActividadRamoClienteFormCtrl);

  TipoActividadRamoClienteFormCtrl.$inject = ['$rootScope', '$scope', 'TipoActividadRamoClienteFactory', '$state',
  'tipoActividadRamoClientePrepService', 'notify', 'VentasLangFactory'];

function TipoActividadRamoClienteFormCtrl($rootScope, $scope, TipoActividadRamoClienteFactory, $state,
    tipoActividadRamoClientePrepService, notify, VentasLangFactory) {


  var vm = this;

  activate();

  vm.cancel = cancel;
  vm.submit = submit;

  function activate() {
    VentasLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      if ($state.is("app.tipoactividadramocliente.new")) {
        activateNew();
      } else if ($state.is("app.tipoactividadramocliente.edit")) {
        activateEdit();
      } else if ($state.is("app.tipoactividadramocliente.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateNew() {
    vm.title = 'Nuevo Tipo de Actividad o Ramo de Cliente';
    vm.tipoActividadRamoCliente = {};
    vm.tipoActividadRamoCliente.activo = true;
  }

  function activateEdit() {
    vm.title = 'Editar Tipo de Actividad o Ramo de Cliente';
    vm.tipoActividadRamoCliente = tipoActividadRamoClientePrepService;
    vm.entidadId = vm.tipoActividadRamoCliente.id;
    vm.entidad = "TipoActividadRamoCliente";
    if(vm.tipoActividadRamoCliente.clientes && vm.tipoActividadRamoCliente.clientes.length >0){
      vm.isClienteAsociado = true;
    }else {
      vm.isClienteAsociado = false;
    }
  }

  function activateView() {
    vm.title = 'Ver Tipo de Actividad o Ramo de Cliente';
    vm.tipoActividadRamoCliente = tipoActividadRamoClientePrepService;
    vm.entidadId = vm.tipoActividadRamoCliente.id;
    vm.entidad = "TipoActividadRamoCliente";
    vm.view = true;
  }

  function submit() {
    vm.submited = true;

    if ($scope.TipoActividadRamoClienteForm.$valid) {
      $rootScope.isProcessing = true;

      submitTipoActividadRamoCliente().then(function () {
        $state.go('app.tipoactividadramocliente.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        console.log(error);
        var msg = "";
        _.forEach(error.data, function (e) {
          msg += '\n\n' + e.message + '.';
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function submitTipoActividadRamoCliente() {
    var resource = TipoActividadRamoClienteFactory.create(vm.tipoActividadRamoCliente);
    return TipoActividadRamoClienteFactory.save(resource);
  }

  function cancel() {
    $state.go('app.tipoactividadramocliente.list');
  }

  $scope.resource = 'tipoactividadramocliente';
}
angular.module('qualita.venta')
  .factory('TipoActividadRamoClienteFactory', TipoActividadRamoClienteFactory);

  TipoActividadRamoClienteFactory.$inject = ['$resource', 'baseurl'];

function TipoActividadRamoClienteFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var tipoActividadRamoCliente = $resource(baseurl.getBaseUrl() + "/tipoactividadramocliente/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'base';
    } else {
      params = [];
      params.view = view != undefined ? view : 'base';
    }
    return tipoActividadRamoCliente.query(params);
  }

  function get(id, view) {
    return tipoActividadRamoCliente.get({ id: id, view: view ? view : "base" });
  }

  function create(attrs) {
    return new tipoActividadRamoCliente(attrs);
  }

  function save(tipoActividadRamoCliente) {
    return (tipoActividadRamoCliente.id) ? tipoActividadRamoCliente.$update() : tipoActividadRamoCliente.$save();
  }

  function remove(tipoActividadRamoCliente) {
    return tipoActividadRamoCliente.$remove();
  }
}
'use strict';

angular.module('qualita.venta')
    .controller('OrdenCargaIndexCtrl', ["$state", "$scope", "OrdenCargaFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "AuthorizationService", "notify", "$modal", function ($state, $scope, OrdenCargaFactory,
        filterFactory, ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl,
        AuthorizationService, notify, $modal) {

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'fechaEmisionOrdenCarga', 'numeroPlanillaReparto','choferPlanillaReparto.nombresyapellidos', 'estado.descripcion'
            ];
            $scope.options = {
                'resource': 'ordencarga',
                'title': 'Ordenes de Carga',
                'factory': OrdenCargaFactory,
                'view': 'OrdenCargaList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'id', 'title': 'Código Interno', visible: true },
                    { 'data': 'fechaEmisionOrdenCarga', 'title': 'Fecha creación OC', 'renderWith': 'dateRender', 'dateFormat': "DD/MM/YYYY", 'class': 'dt-center', 'type': 'date-range' },
                    { 'data': 'numeroPlanillaReparto', 'title': 'Nro. Planilla Reparto', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'choferPlanillaReparto.nombresyapellidos', 'title': 'Chofer', renderWith: renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'estado.descripcion', 'title': 'Estado', 'renderWith': 'emptyRender', type: "combo", filterUrl: "tipos/filtro/estado_orden_carga" }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                    [
                        {
                            title: translations.NEW,
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function () {
                                return false;
                            },
                            action: function () {
                                if (AuthorizationService.hasPermission("create_ordencarga") == true) {
                                    $state.go("app.ordencarga.new");
                                } else {
                                    var msg = 'No posee permiso para crear Orden de Carga';
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("ordencarga", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoOrdenCarga', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }
                    ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: ["id", "estado"],
                        conditionName: 'canEditar',
                        conditionDef: function (atributos) {
                            var estado = atributos[1];
                            if (AuthorizationService.hasPermission("update_ordencarga") === true && (estado.codigo == "estado_orden_carga_ingresado" || estado.codigo == "estado_orden_carga_en_proceso")) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "editar",
                        functionDef: function (atributos) {
                            var itemId = atributos[0];
                            if (AuthorizationService.hasPermission("update_ordencarga") === true) {
                                $state.go('app.ordencarga.edit', { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: ["id", "estado"],
                        conditionName: "canBorrar",
                        conditionDef: function (atributos) {
                            var estado = atributos[1];
                            if (AuthorizationService.hasPermission("delete_ordencarga") === true && estado.codigo == "estado_orden_carga_ingresado") {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "borrar",
                        functionDef: function (itemId) {
                            $scope.selectedItemId = itemId;

                            if (AuthorizationService.hasPermission("delete_ordencarga") === true) {
                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING');

                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: '<div class="modal-header">' +
                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                        '</div>' +
                                        '<div class="modal-body">{{::mensajeModal}}' +
                                        '<hr width="1%">' +
                                        '</div>' +
                                        '<div class="modal-footer">' +
                                        '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                        '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                        '</div>',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                };

                                $scope.ok = function (itemId) {
                                    var model = OrdenCargaFactory.create({ id: itemId });
                                    OrdenCargaFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                            $scope.mensajeModal = $filter("translate")(
                                                "DELETE_FAILED_CHECK_ASSOCIATED_OPERATIONS"
                                            );
                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                        }
                                    );
                                };
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button title='" + "Confirmar" + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aprobar(<%=dataCustom%>)' ng-class='{ hidden : !canAprobar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
                        functionName: "aprobar",
                        conditionName: "canAprobar",
                        customAttribute: ["estado", "id"],
                        conditionDef: function (atributos) {
                            var estado = atributos[0];
                            if (AuthorizationService.hasPermission("confirmar_ordencarga") === true && estado.codigo == "estado_orden_carga_en_proceso") {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        functionDef: function (atributos) {
                            var estado = atributos[0];
                            var itemId = atributos[1];

                            if (AuthorizationService.hasPermission("confirmar_ordencarga") === true) {
                                $scope.mensajeModal = "¿Está seguro de que desea aprobar la Orden de Carga?";
                                $scope.tituloModal = "Aprobar Orden de Carga";

                                var modalInstance = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss('cancel');
                                };

                                $scope.ok = function (id) {
                                    OrdenCargaFactory.aprobar(itemId).then(function (response) {
                                        var filtersArr = [];
                                        filtersArr.push("idordencarga=" + itemId);
                                        modalInstance.close();
                                        $state.go($state.current, {}, { reload: true });
                                        vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                            "ordencarga",
                                            "pdf",
                                            filtersArr
                                        );
                                        $window.open(vm.pdfDownloadURL, "_blank");
                                        $state.go($state.current, {}, { reload: true });
                                    }, function (error) {
                                        var mensaje = "<span>";
                                        _.forEach(error.data, function (err) {
                                            mensaje = mensaje + err.message + "<br/>";
                                        });
                                        mensaje = mensaje + "</span>";
                                        notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                    });
                                }
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button title='" + "Anular" + "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='anular(<%=dataId%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
                        functionName: "anular",
                        conditionName: "canAnular",
                        customAttribute: ["estado", "id"],
                        conditionDef: function (atributos) {
                            var estado = atributos[0];
                            if (AuthorizationService.hasPermission("anular_ordencarga") === true && (estado.codigo == "estado_orden_carga_en_proceso" || estado.codigo == "estado_orden_carga_confirmado")) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        functionDef: function (itemId) {
                            if (AuthorizationService.hasPermission("anular_ordencarga") === true) {

                                $scope.mensajeModal = "¿Está seguro de que desea anular la Orden de Carga?";
                                $scope.tituloModal = "Anular Orden de Carga";
                                var modalInstance = $modal.open({
                                    templateUrl: 'views/directives/confirmation-modal.html',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    modalInstance.dismiss('cancel');
                                };

                                $scope.ok = function (id) {
                                    OrdenCargaFactory.anular(itemId).then(function (response) {
                                        modalInstance.close();
                                        $state.go($state.current, {}, { reload: true });
                                    }, function (error) {
                                        var mensaje = "<span>";
                                        _.forEach(error.data, function (err) {
                                            mensaje = mensaje + err.message + "<br/>";
                                        });
                                        mensaje = mensaje + "</span>";
                                        notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                    });
                                };
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                            $filter("translate")("VIEW") +
                            "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function (itemId) {
                            if (AuthorizationService.hasPermission("index_ordencarga") === true) {
                                $state.go("app.ordencarga.view", { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        },
                        conditionName: "canVer",
                        conditionDef: function () {
                            return true;
                        }
                    },
                    {
                        templateToRender: "<button title='" +
                            "Imprimir Orden de Carga" +
                            "' class='btn btn-info btn-dt' style='margin-right: 5px;' ng-click='imprimir(<%=dataCustom%>)' ng-class='{ hidden : !canImprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-print'></span> </button>",
                        functionName: "imprimir",
                        conditionName: "canImprimir",
                        customAttribute: ["estado", "id"],
                        conditionDef: function (atributos) {
                            return true;
                        },
                        functionDef: function (atributos) {
                            var itemId = atributos[1];

                            $scope.mensajeModal = "Desea imprimir la Orden de Carga";
                            $scope.tituloModal = "Imprimir Orden de Carga";
                            var modalInstance = $modal.open({
                                templateUrl: "views/directives/confirmation-modal.html",
                                scope: $scope
                            });

                            $scope.cancel = function () {
                                modalInstance.dismiss("cancel");
                            };

                            $scope.ok = function (id) {
                                var filtersArr = [];
                                filtersArr.push("idordencarga=" + itemId);
                                modalInstance.close();
                                $state.go($state.current, {}, { reload: true });
                                $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                                    "ordencarga",
                                    "pdf",
                                    filtersArr
                                );
                                $window.open($scope.pdfDownloadURL, "_blank");
                                $state.go($state.current, {}, { reload: true });
                            }
                        }
                    }
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if (search === 'No' && data === 'activo') {
                        search = 'false';
                    }
                    if (search === 'Sí' && data === 'activo') {
                        search = 'true';
                    }

                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);

'use strict';

angular.module('qualita.venta')
  .controller('OrdenCargaFormCtrl', OrdenCargaFormCtrl);

OrdenCargaFormCtrl.$inject = ['$rootScope', '$scope', 'OrdenCargaFactory', '$state',
  'ordenCargaPrepService', 'notify', 'VentasLangFactory', 'TiposFactory', '$stateParams', 'PlanillaRepartoFactory', 'filterFactory',
  'UsuariosFactory', 'ModelTrimmer', '$filter', 'AuthorizationService', 'UtilFactory', 'CsvFactory', '$window', 'baseurl', 'ReportTicketFactory',
  'OrdenCargaDetalleFactory', '$modal'];

function OrdenCargaFormCtrl($rootScope, $scope, OrdenCargaFactory, $state,
  ordenCargaPrepService, notify, VentasLangFactory, TiposFactory, $stateParams, PlanillaRepartoFactory, filterFactory,
  UsuariosFactory, ModelTrimmer, $filter, AuthorizationService, UtilFactory, CsvFactory, $window, baseurl, ReportTicketFactory,
  OrdenCargaDetalleFactory, $modal) {

  var vm = this;

  activate();
  vm.cancel = cancel;
  vm.submitOrdenDetalle = submitOc;
  vm.detalle = {};
  vm.editOrdenCargaDetalle = editOrdenCargaDetalle;
  vm.validarCamposModal = validarCamposModal;

  var renderEmptyData = function (data) {
    if (data == undefined)
      return "";
    else
      return data;
  };

  var monedaRender = function (data, type, row) {
    if (data != undefined) {
      var pp = parseFloat(data).toFixed(3);
      return pp.toString().replace(".", ",");
    } else {
      return "";
    }
  };

  function activate() {
    VentasLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      if ($state.is("app.ordencarga.new")) {
        activateNew();
      } else if ($state.is("app.ordencarga.edit")) {
        activateEdit();
      } else if ($state.is("app.ordencarga.view")) {
        activateView();
      }

      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  var createFilters = function (filters, ordenCarga) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    filtersArr.push({
      path: "ordenCarga.id",
      equals: ordenCarga
    });

    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }

  function activateNew() {
    vm.title = 'Nueva Orden de Carga';
    vm.ordencarga = {};
    var hoy = new Date();
    vm.ordencarga.fechaEmisionOrdenCarga = hoy;
    TiposFactory.estadosOrdenCarga().then(function (data) {
      var estadoIngresado = _.filter(data.data, function (elem) { return elem.codigo === 'estado_orden_carga_ingresado'; });
      vm.ordencarga.estado = estadoIngresado[0];
    });

    var idPlanillaReparto = $stateParams.id;
    PlanillaRepartoFactory.get(idPlanillaReparto, "PlanillaRepartoForm").$promise.then(function (elemento) {
      vm.ordencarga.planillaReparto = elemento;
      vm.ordencarga.numeroPlanillaReparto = elemento.numero;
      vm.ordencarga.fechaPlanillaReparto = new Date(elemento.fechaPlanillaReparto);
      vm.ordencarga.camionPlanillaReparto = elemento.camion;
      vm.ordencarga.choferPlanillaReparto = elemento.chofer;
      vm.ordencarga.capacidadCamion = elemento.camion.capacidad;
      vm.ordencarga.observacionPlanillaReparto = elemento.descripcion;
      // Se recupera el usuario asociado
      var usersFilter = {};
      usersFilter.search = filterFactory.and([{
        path: 'username',
        equals: $rootScope.AuthParams.username
      }]).value();
      usersFilter.view = "BaseList"
      UsuariosFactory.all(usersFilter).$promise.then(function (data) {
        vm.ordencarga.preparadoPor = data[0].username;
      });
    });
  }

  function activateEdit() {
    vm.edit = true;
    vm.title = 'Editar Orden de Carga';
    vm.ordencarga = ordenCargaPrepService;
    vm.ordencarga.fechaEmisionOrdenCarga = new Date(vm.ordencarga.fechaEmisionOrdenCarga);
    vm.ordencarga.fechaPlanillaReparto = new Date(vm.ordencarga.fechaPlanillaReparto);
    vm.entidadId = vm.ordencarga.id;
    vm.entidad = "OrdenCarga";
    vm.staticFilter = {
      search: filterFactory.and([{
        path: "ordenCarga.id",
        equals: vm.ordencarga.id
      }]).value()
    };

    var defaultColumnOrder = ['id', 'producto.codigo', 'almacen.descripcion', 'producto.codigoBarrasPrincipal.codigoBarras', 'producto.nombre', 'grupoProducto.numero',
      'cantidadPedidoPresentacion', 'unidadPresentacionPedido', 'cantidadPedidoBase', 'unidadBasePedido', 'cantidadPreparadoPresentacion', 'unidadPresentacionPreparado',
      'cantidadPreparadoBase', 'unidadBasePreparado', 'diferenciaPresentacion', 'diferenciaBase', 'totalKg'];
    $scope.options = {
      'resource': 'ordencargadetalle',
      'title': $filter("translate")("DETALLES"),
      'factory': OrdenCargaDetalleFactory,
      'view': 'OrdenCargaDetalleList',
      'staticFilter': vm.staticFilter,
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        { 'data': 'id', 'title': "Codigo", visible: false },
        { 'data': 'producto.codigo', 'title': "Cod. Producto", visible: false },
        { 'data': 'almacen.descripcion', 'title': 'Almacen' },
        { 'data': 'producto.codigoBarrasPrincipal.codigoBarras', 'title': $filter("translate")("CODIGO_BARRAS") },
        { 'data': 'producto.nombre', 'title': $filter("translate")("PRODUCTO"), 'render': renderEmptyData },
        { 'data': 'grupoProducto.numero', 'title': 'Lote', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'cantidadPedidoPresentacion', 'title': 'Cantidad', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'unidadPresentacionPedido', 'title': 'Presentacion', 'render': renderEmptyData },
        { 'data': 'cantidadPedidoBase', 'title': 'Can. Pedido Base', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'unidadBasePedido', 'title': 'Unidad Base', 'render': renderEmptyData },
        { 'data': 'cantidadPreparadoPresentacion', 'title': 'Can. Preparado Presentacion', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'unidadPresentacionPreparado', 'title': 'Presentacion', 'render': renderEmptyData },
        { 'data': 'cantidadPreparadoBase', 'title': 'Can. Preparado Base', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'unidadBasePreparado', 'title': 'Unidad Base', 'render': renderEmptyData },
        { 'data': 'diferenciaPresentacion', 'title': 'Diferencia Presentación', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'diferenciaBase', 'title': 'Diferencia Base', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'totalKg', 'title': 'Total Kg', 'renderWith': monedaRender, class: "dt-right" }
      ],
      'isSelectable': true,
      'selection': {},
      'hasOptions': true,
      'defaultOrderColumn': 1,
      'defaultOrderDir': "desc",
      'hideRemoveMenu': true,
      'hideAddMenu': true,
      'hideEditMenu': true,
      'hideViewMenu': true,
      'extraRowOptions': [
        {
          templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' ng-click='editar(<%=dataId%>)' title='" + $filter('translate')('EDIT') + "' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          conditionName: "canEditar",
          conditionDef: function () {
            return (AuthorizationService.hasPermission('update_ordencargadetalle'));
          },
          functionName: "editar",
          functionDef: function (itemId) {
            OrdenCargaDetalleFactory.get(itemId, "OrdenCargaDetalleForm").$promise.then(function (ordenCargaDetalle) {
              vm.editOrdenCargaDetalle(ordenCargaDetalle, false);
            });
          }
        }],
      'extraMenuOptions':
        [
          {
            'data': "Cargar Cant. Preparado",
            'title': 'Cargar Cantidades Preparado igual a Pedido',
            'icon': 'glyphicon glyphicon-check',
            'showCondition': function () { return true; },
            'action': function () {
              if (getIDs().length === 0)
                notify({ message: "Debe seleccionar al menos un detalle.", classes: 'alert-warning', position: 'right' });
              else {
                $scope.tituloModal = "Carga Automatica";
                $scope.mensajeModal = "El campo de los registros seleccionados tendran la Cantidad Preparado igual al valor que la Cantidad Pedido.";
                var modalInstance = $modal.open({
                  templateUrl: 'views/directives/confirmation-modal.html',
                  scope: $scope
                });

                $scope.cancel = function () {
                  modalInstance.dismiss('cancel');
                };

                $scope.ok = function () {
                  OrdenCargaDetalleFactory.actualizarCanPreparado(getIDs()).then(function (data) {
                    if (data.data.mensajes === undefined) {
                      $state.transitionTo($state.current, $stateParams, {
                        reload: true,
                        inherit: false,
                        notify: true
                      });
                    } else {
                      var mensaje = "";
                      $.each(data.data.mensajes, function (index, value) {
                        mensaje = mensaje + "\n" + value;
                      });
                      notify({ message: mensaje, classes: 'alert-danger', position: 'right' });
                    }
                  })
                  modalInstance.close();
                };
              }
            }
          },
          {
            data: $filter('translate')('GENERAR_CSV'),
            title: "CSV",
            icon: 'glyphicon glyphicon-export',
            showCondition: function () {
              return true;
            },
            action: function () {
              var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

              CsvFactory.csv("ordencargadetalle", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            },
          },
          {
            title: "Reporte",
            icon: 'glyphicon glyphicon-file',
            showCondition: function () {
              return true;
            },
            action: function () {
              var filters = createFilters($scope.options.getFilters(), vm.ordencarga.id);
              ReportTicketFactory.ticket('listadoOrdenCargaDetalle', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open(vm.pdfDownloadURL, '_blank');
              });
            }
          }
        ]
    };
    var getIDs = function () {
      var ids = [];
      _.forEach($scope.options.selection, function (n, key) {
        if (n)
          ids.push(key);
      });
      return ids;
    };
  }

  function editOrdenCargaDetalle(ordenCargaDetalle, view) {
    vm.view = view;
    if (ordenCargaDetalle != undefined && !vm.view) {
      vm.detalle = ordenCargaDetalle;
      $scope.tituloModal = 'Editar Cantidad Preparado';
    }

    var modalInstance = $modal.open({
      templateUrl: 'views/directives/ordencargadetalle-edit-modal.html',
      scope: $scope
    });

    $scope.cancel = function () {
      vm.detalle = {};
      modalInstance.dismiss('cancel');
      $state.go($state.current, {}, { reload: true });
    };

    $scope.ok = function () {
      vm.submited = true;
      if (vm.validarCamposModal()) {
        vm.detalle.ordencarga = vm.ordencarga;
        var OrdenCargaDet = OrdenCargaDetalleFactory.create(vm.detalle);
        return OrdenCargaDetalleFactory.save(OrdenCargaDet).then(function (resp) {
          modalInstance.close();
          $state.go($state.current, {}, { reload: true });
        }, function (error) {
          notify({ message: error.data[0].message, classes: "alert-danger", position: "right" });
        });

      }
    }
  }

  function validarCamposModal() {
    if (vm.detalle.cantidadPreparadoPresentacion != undefined && vm.detalle.cantidadPreparadoBase != undefined) {
      return true;
    } else {
      return false;
    }
  }

  function activateView() {
    vm.title = 'Ver Orden de Carga';
    vm.ordencarga = ordenCargaPrepService;
    vm.entidadId = vm.ordencarga.id;
    vm.entidad = "OrdenCarga";
    vm.view = true;
    vm.ordencarga.fechaEmisionOrdenCarga = new Date(vm.ordencarga.fechaEmisionOrdenCarga);
    vm.ordencarga.fechaPlanillaReparto = new Date(vm.ordencarga.fechaPlanillaReparto);
    vm.staticFilter = {
      search: filterFactory.and([{
        path: "ordenCarga.id",
        equals: vm.ordencarga.id
      }]).value()
    };

    var defaultColumnOrder = ['id', 'producto.codigo', 'almacen.descripcion', 'producto.codigoBarrasPrincipal.codigoBarras', 'producto.nombre', 'grupoProducto.numero',
      'cantidadPedidoPresentacion', 'unidadPresentacionPedido', 'cantidadPedidoBase', 'unidadBasePedido', 'cantidadPreparadoPresentacion', 'unidadPresentacionPreparado',
      'cantidadPreparadoBase', 'unidadBasePreparado', 'diferenciaPresentacion', 'diferenciaBase', 'totalKg'];
    $scope.options = {
      'resource': 'ordencargadetalle',
      'title': $filter("translate")("DETALLES"),
      'factory': OrdenCargaDetalleFactory,
      'view': 'OrdenCargaDetalleList',
      'staticFilter': vm.staticFilter,
      'defaultColumnOrder': defaultColumnOrder,
      'columns': [
        { 'data': 'id', 'title': "Codigo", visible: false },
        { 'data': 'producto.codigo', 'title': "Cod. Producto", visible: false },
        { 'data': 'almacen.descripcion', 'title': 'Almacen' },
        { 'data': 'producto.codigoBarrasPrincipal.codigoBarras', 'title': $filter("translate")("CODIGO_BARRAS") },
        { 'data': 'producto.nombre', 'title': $filter("translate")("PRODUCTO"), 'render': renderEmptyData },
        { 'data': 'grupoProducto.numero', 'title': 'Lote', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'cantidadPedidoPresentacion', 'title': 'Cantidad', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'unidadPresentacionPedido', 'title': 'Presentacion', 'render': renderEmptyData },
        { 'data': 'cantidadPedidoBase', 'title': 'Can. Pedido Base', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'unidadBasePedido', 'title': 'Unidad Base', 'render': renderEmptyData },
        { 'data': 'cantidadPreparadoPresentacion', 'title': 'Can. Preparado Presentacion', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'unidadPresentacionPreparado', 'title': 'Presentacion', 'render': renderEmptyData },
        { 'data': 'cantidadPreparadoBase', 'title': 'Can. Preparado Base', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'unidadBasePreparado', 'title': 'Unidad Base', 'render': renderEmptyData },
        { 'data': 'diferenciaPresentacion', 'title': 'Diferencia Presentación', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'diferenciaBase', 'title': 'Diferencia Base', class: "dt-right", 'renderWith': 'emptyRender' },
        { 'data': 'totalKg', 'title': 'Total Kg', 'renderWith': monedaRender, class: "dt-right" }

      ],
      'hasOptions': true,
      'defaultOrderColumn': 1,
      'defaultOrderDir': "desc",
      'hideRemoveMenu': true,
      'hideAddMenu': true,
      'hideEditMenu': true,
      'hideViewMenu': true,
      'extraMenuOptions':
        [
          {
            data: $filter('translate')('GENERAR_CSV'),
            title: "CSV",
            icon: 'glyphicon glyphicon-export',
            showCondition: function () {
              return true;
            },
            action: function () {
              var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

              CsvFactory.csv("ordencargadetalle", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            },
          },
          {
            title: "Reporte",
            icon: 'glyphicon glyphicon-file',
            showCondition: function () {
              return true;
            },
            action: function () {
              var filters = createFilters($scope.options.getFilters(), vm.ordencarga.id);
              ReportTicketFactory.ticket('listadoOrdenCargaDetalle', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open(vm.pdfDownloadURL, '_blank');
              });
            }
          }
        ]
    };
  }

  function submitOc() {
    vm.submited = true;

    if ($scope.OrdenCargaForm.$valid) {
      $rootScope.isProcessing = true;

      submitOrdenCarga().then(function () {
        if (!vm.edit && !vm.view) {
          var params = {};
          params.search = filterFactory
            .single({
              path: "planillaReparto.id",
              equals: vm.ordencarga.planillaReparto.id
            })
            .value();

          OrdenCargaFactory.all(params).$promise.then(function (response) {
            if (response) {
              _.forEach(response, function (detalle) {
                if (detalle.estado.codigo != 'estado_orden_carga_anulado') {
                  console.log("response ", detalle)
                  var itemId = detalle.id;
                  $state.go("app.ordencarga.edit", { id: itemId });
                }

              });
            }
          });
        } else {
          $state.go("app.ordencarga.list");
        }

      }, function (error) {
        $rootScope.isProcessing = false;
        console.log(error);
        var msg = "";
        _.forEach(error.data, function (e) {
          msg += '\n\n' + e.message + '.';
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function submitOrdenCarga() {
    var resource = OrdenCargaFactory.create(vm.ordencarga);
    return OrdenCargaFactory.save(resource);
  }

  function cancel() {
    $state.go('app.ordencarga.list');
  }

  $scope.resource = 'ordencarga';
}

angular.module('qualita.venta')
  .factory('OrdenCargaFactory', OrdenCargaFactory);

OrdenCargaFactory.$inject = ['$resource', 'baseurl'];

function OrdenCargaFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    aprobar: aprobar,
    anular: anular
  };

  var OrdenCarga = $resource(baseurl.getBaseUrl() + "/ordencarga/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    },
    aprobar: {
      method: 'PUT',
      url: baseurl.getBaseUrl() + "/ordencarga/aprobar/:id"
    },
    anular: {
      method: 'PUT',
      url: baseurl.getBaseUrl() + "/ordencarga/anular/:id"
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'base';
    } else {
      params = [];
      params.view = view != undefined ? view : 'base';
    }
    return OrdenCarga.query(params);
  }

  function get(id, view) {
    return OrdenCarga.get({ id: id, view: view ? view : "base" });
  }

  function create(attrs) {
    return new OrdenCarga(attrs);
  }

  function save(ordencarga) {
    return (ordencarga.id) ? ordencarga.$update() : ordencarga.$save();
  }

  function remove(ordencarga) {
    return ordencarga.$remove();
  }

  function aprobar(id) {
    return OrdenCarga.aprobar({ id: id }).$promise;
  }

  function anular(id) {
    return OrdenCarga.anular({ id: id }).$promise;
  }
}
angular.module('qualita.venta')
    .factory('OrdenCargaDetalleFactory', OrdenCargaDetalleFactory);

OrdenCargaDetalleFactory.$inject = ['$resource', 'baseurl', '$http'];

function OrdenCargaDetalleFactory($resource, baseurl, $http) {
    var service = {
        all: all,
        get: get,
        create: create,
        save: save,
        remove: remove,
        actualizarCanPreparado: actualizarCanPreparado
    };

    var OrdenCargaDetalle = $resource(baseurl.getBaseUrl() + "/ordencargadetalle/:id", { id: '@id' }, {
        update: {
            method: 'PUT'
        }
    });

    return service;

    function all(params) {
        return OrdenCargaDetalle.query(params);
    }

    function get(id, view) {
        return OrdenCargaDetalle.get({ id: id, view: view ? view : "OrdenCargaDetalleList" });
    }

    function create(attrs) {
        return new OrdenCargaDetalle(attrs);
    }

    function save(ordencargadetalle) {
        return (ordencargadetalle.id) ? ordencargadetalle.$update() : ordencargadetalle.$save();
    }

    function remove(ordencargadetalle) {
        return ordencargadetalle.$remove();
    }

    function actualizarCanPreparado(ids) {
        return $http({
            url: baseurl.getBaseUrl() + '/ordencargadetalle/actualizarpreparado',
            method: "GET",
            params: { listDetalles: ids }
        }).then(function (data) {
            return data;
        }, function (status) {
            notify({ message: 'Ha ocurrido un error en el servidor', classes: 'alert-danger', position: 'right' });
        });
    }
}

'use strict';


angular.module('qualita.venta')
    .controller('CategoriaClienteIndexCtrl', ["$state", "$scope", "CategoriaClienteFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "ParametrosFactory", "AuthorizationService", "notify", "$modal", function ($state, $scope, CategoriaClienteFactory,
        filterFactory, ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl, ParametrosFactory,
        AuthorizationService, notify, $modal) {

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        var promise1 = ParametrosFactory.getByCodigo('enviar_a_contabilidad');
        var enviarAContabilidad = '';

        promise1.then(function(response) {
        enviarAContabilidad = response.valorTipo.codigo;
        });

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id','descripcion'];
            $scope.options = {
                'resource': 'categoriacliente',
                'title': 'Categoría de Cliente',
                'factory': CategoriaClienteFactory,
                'view': 'CategoriaClienteList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'id', 'title': 'Código', visible: true },
                    { 'data': 'descripcion', 'title': 'Descripción', renderWith: renderEmptyData, 'class': 'dt-left' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'hideAddMenu': true,
                'hideEditMenu': true,
                'hideRemoveMenu': true,
                'hideViewMenu': true,
                'extraMenuOptions':
                    [
                        {
                            title: translations.NEW,
                            icon: "glyphicon glyphicon-plus",
                            showCondition: function () {
                                return true;
                            },
                            action: function () {
                                if (AuthorizationService.hasPermission("create_categoriacliente") == true) {
                                    $state.go("app.categoriacliente.new");
                                } else {
                                    var msg = 'No posee permiso para crear una Categoría de Cliente';
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("categoriacliente", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoCategoriaCliente', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }
                    ],
                'extraRowOptions': [
                    {
                        templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('EDIT') + "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: 'canEditar',
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("update_categoriacliente") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "editar",
                        functionDef: function (atributos) {
                            var itemId = atributos[0];
                            if (AuthorizationService.hasPermission("update_categoriacliente") === true) {
                                console.log('itemId');
                                console.log(itemId);
                                $state.go('app.categoriacliente.edit', { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" + $filter('translate')('DELETE') + "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
                        customAttribute: ["id"],
                        conditionName: "canBorrar",
                        conditionDef: function (atributos) {
                            if (AuthorizationService.hasPermission("delete_categoriacliente") === true) {
                                return true;

                            } else {
                                return false;
                            }
                        },
                        functionName: "borrar",
                        functionDef: function (itemId) {
                            $scope.selectedItemId = itemId;

                            if (AuthorizationService.hasPermission("delete_categoriacliente") === true) {
                                $scope.tituloModal = $filter('translate')('DELETE_CONFIRMATION');
                                $scope.mensajeModal = $filter('translate')('DELETE_WARNING');

                                $scope.modalInstanceBorrar1 = $modal.open({
                                    template: '<div class="modal-header">' +
                                        '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                        '</div>' +
                                        '<div class="modal-body">{{::mensajeModal}}' +
                                        '<hr width="1%">' +
                                        '</div>' +
                                        '<div class="modal-footer">' +
                                        '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                                        '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                                        '</div>',
                                    scope: $scope
                                });

                                $scope.cancel = function () {
                                    $scope.modalInstanceBorrar1.dismiss('cancel');
                                };

                                $scope.ok = function (itemId) {
                                    var model = CategoriaClienteFactory.create({ id: itemId });
                                    CategoriaClienteFactory.remove(model).then(
                                        function () {
                                            $scope.modalInstanceBorrar1.close(itemId);
                                            $state.go($state.current, {}, { reload: true });
                                        },
                                        function (error) {
                                            $scope.modalInstanceBorrar1.dismiss("cancel");
                                            $scope.tituloModal = $filter("translate")("DELETE_FAILED");
                                            $scope.mensajeModal =error.data[0].message;

                                            var modalInstance = $modal.open({
                                                template: '<div class="modal-header">' +
                                                    '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                                                    "</div>" +
                                                    '<div class="modal-body">{{::mensajeModal}}</div>' +
                                                    '<div class="modal-footer">' +
                                                    '<button class="btn btn-primary" ng-click="cancel()">Aceptar</button>' +
                                                    "</div>",
                                                scope: $scope
                                            });
                                            $scope.cancel = function () {
                                                modalInstance.dismiss("cancel");
                                            };
                                        }
                                    );
                                };
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        }
                    },
                    {
                        templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                            $filter("translate")("VIEW") +
                            "' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
                        functionName: "ver",
                        functionDef: function (itemId) {
                            if (AuthorizationService.hasPermission("index_categoriacliente") === true) {
                                $state.go("app.categoriacliente.view", { id: itemId });
                            } else {
                                notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                            }
                        },
                        conditionName: "canVer",
                        conditionDef: function () {
                            return true;
                        }
                    },
                    {
                        templateToRender: "<button title='" + $filter('translate')('REENVIAR_A_CONTABILIDAD') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reenviar(<%=dataCustom%>)' ng-class='{hidden : !canReenviar(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-retweet'></span> </button>",
                        functionName: 'reenviar',
                        customAttribute: ['enviadoAContabilidad', 'id'],
                        functionDef: function (atributo) {
              
                          var idCategoriaCliente = atributo[1];
              
                          CategoriaClienteFactory.get(idCategoriaCliente, "BaseList").$promise.then(function (response) {
              
                            $scope.tituloModal = $filter('translate')('REENVIAR_CATEGORIA_CLIENTE');
                            $scope.mensajeModal = $filter('translate')('REENVIAR_CATEGORIA_CLIENTE_MSG');
                            var modalInstance = $modal.open({
                              templateUrl: 'views/directives/confirmation-modal.html',
                              scope: $scope
                            });
              
                            $scope.cancel = function () {
                              modalInstance.dismiss('cancel');
                            };
              
                            $scope.ok = function (id) {
                              
                              ParametrosFactory.getByCodigo('enviar_a_contabilidad').then(function (parametro) {
              
                                if (parametro.valorTipo.codigo == 'si') {
                                  CategoriaClienteFactory.reenviar(response.id).then(function (responseReenviar) {
          
                                    console.log('Categoria Cliente responseReenviar');
                                    console.log(responseReenviar);
              
                                    if (responseReenviar.data.enviadoAContabilidad == false) {
                                      notify({ message: $filter('translate')('ERROR_ENVIO_A_CONTABILIDAD'), classes: 'alert-danger', position: 'right' });
                                    }
                                    $state.go("app.categoriacliente.list", { id: responseReenviar.id }, { reload: true });
                                  }, function (error) {
                                    var msg = "";
                                    if (error.data && error.data.length > 0 && error.data[0].message) {
                                      msg = error.data[0].message;
                                    } else {
                                      msg = $filter('translate')('REENVIAR_CATEGORIA_CLIENTE_FAILED');
                                    }
              
                                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                  });
                                } else {
                                  var msg = "";
                                  msg = $filter('translate')('PARAMETRO_ENVIO_CONTABILIDAD_MSG');
                                  notify({ message: msg, classes: 'alert-danger', position: 'right' });
                                }
                              });
                              modalInstance.close();
                            }
                          });
                        },
                        conditionName: 'canReenviar',
                        conditionDef: function (atributo) {
                          var enviadoAContabilidad = atributo[0];
                          
                          if (enviarAContabilidad == 'si') {
                            if (enviadoAContabilidad === "No") {
                              return true;
                            }
                          }
                        }
                    }
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if (search === 'No' && data === 'activo') {
                        search = 'false';
                    }
                    if (search === 'Sí' && data === 'activo') {
                        search = 'true';
                    }

                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);
'use strict';

angular.module('qualita.venta')
  .controller('CategoriaClienteFormCtrl', CategoriaClienteFormCtrl);

  CategoriaClienteFormCtrl.$inject = ['$rootScope', '$scope', 'CategoriaClienteFactory', '$state',
  'categoriaClientePrepService', 'notify', 'VentasLangFactory'];

function CategoriaClienteFormCtrl($rootScope, $scope, CategoriaClienteFactory, $state,
    categoriaClientePrepService, notify, VentasLangFactory) {


  var vm = this;

  activate();

  vm.cancel = cancel;
  vm.submit = submit;

  function activate() {
    VentasLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;
      if ($state.is("app.categoriacliente.new")) {
        activateNew();
      } else if ($state.is("app.categoriacliente.edit")) {
        activateEdit();
      } else if ($state.is("app.categoriacliente.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });
  }

  function activateNew() {
    vm.title = 'Nueva Categoría de Cliente';
    vm.categoriaCliente = {};
  }

  function activateEdit() {
    vm.title = 'Editar Categoría de Cliente';
    vm.categoriaCliente = categoriaClientePrepService;
    vm.entidadId = vm.categoriaCliente.id;
    vm.entidad = "CategoriaCliente";
  }

  function activateView() {
    vm.title = 'Ver Categoría de Cliente';
    vm.categoriaCliente = categoriaClientePrepService;
    vm.entidadId = vm.categoriaCliente.id;
    vm.entidad = "CategoriaCliente";
    vm.view = true;
  }

  function submit() {
    vm.submited = true;

    if ($scope.CategoriaClienteForm.$valid) {
      $rootScope.isProcessing = true;

      submitCategoriaCliente().then(function () {
        $state.go('app.categoriacliente.list');
      }, function (error) {
        $rootScope.isProcessing = false;
        console.log(error);
        var msg = "";
        _.forEach(error.data, function (e) {
          msg += '\n\n' + e.message + '.';
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
      });
    } else {
      notify({ message: "Campos incorrectos o vacíos. No se puede guardar.", classes: 'alert-danger', position: 'right' });
    }
  }

  function submitCategoriaCliente() {
    var resource = CategoriaClienteFactory.create(vm.categoriaCliente);
    return CategoriaClienteFactory.save(resource);
  }

  function cancel() {
    $state.go('app.categoriacliente.list');
  }

  $scope.resource = 'categoriacliente';
}
angular.module('qualita.venta')
  .factory('CategoriaClienteFactory', CategoriaClienteFactory);

  CategoriaClienteFactory.$inject = ['$resource', 'baseurl', '$http'];

function CategoriaClienteFactory($resource, baseurl, $http) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    reenviar: reenviar
  };

  var categoriaCliente = $resource(baseurl.getBaseUrl() + "/categoriacliente/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'CategoriaClienteForm';
    } else {
      params = [];
      params.view = view != undefined ? view : 'CategoriaClienteForm';
    }
    return categoriaCliente.query(params);
  }

  function get(id, view) {
    return categoriaCliente.get({ id: id, view: view ? view : "base" });
  }

  function create(attrs) {
    return new categoriaCliente(attrs);
  }

  function save(categoriaCliente) {
    return (categoriaCliente.id) ? categoriaCliente.$update() : categoriaCliente.$save();
  }

  function remove(categoriaCliente) {
    return categoriaCliente.$remove();
  }

  function reenviar(idCategoriaCliente) {
    return $http({
      url: baseurl.getBaseUrl() + '/categoriacliente/reenviar/' + idCategoriaCliente,
      method: "PUT"
    });
  }
}
'use strict';

angular.module('qualita.venta')
  .controller('ContribuyenteCargaCtrl', ["$http", "$rootScope", "$location", "$scope", "ContribuyenteCargaFactory", "formFactory", "$window", "baseurl", "usSpinnerService", "notify", function ($http ,$rootScope, $location,$scope, ContribuyenteCargaFactory, formFactory, $window, baseurl, usSpinnerService,notify) {

    $scope.title = 'Contribuyente';

    $rootScope.isProcessing = false;

    $scope.schema = ContribuyenteCargaFactory.schema();

    $scope.form = ContribuyenteCargaFactory.form();

    $scope.options = formFactory.defaultOptions();

    $scope.resource = 'contribuyente';

    $scope.model = {};

    $scope.position = 0;

    $scope.submitButton = 'Actualizar datos';

    $scope.formSent = false;

    $scope.startSpin = function(){
        usSpinnerService.spin('spinner-1');
    }

    $scope.stopSpin = function(){
        usSpinnerService.stop('spinner-1');
    }

    $scope.submit = function(form) {

      var flow = ContribuyenteCargaFactory.getFileFormProperty().uploader.flow;
      var flowFile = flow.files[0];
      if (flowFile)
        $scope.model.file = flowFile.name;
        $scope.$broadcast('schemaFormValidate');
        var model = ContribuyenteCargaFactory.create($scope.model);
        $scope.formSent = true;
        $scope.startSpin();

        $rootScope.isProcessing = true;

        ContribuyenteCargaFactory.carga($scope.model.file).then(function(data){
          console.log("succes");
          if(data.file === null || data.file === undefined) {
            ContribuyenteCargaFactory.setData(data);
            $scope.stopSpin();
            $scope.formSent = false;
            $location.path('/contribuyentes');
          } else {
              $scope.stopSpin();
              $scope.formSent = false;
              $location.path('/contribuyentes');
          }
        }, function(error){
          $rootScope.isProcessing = false;
          $scope.stopSpin();
		      $scope.formSent = false;
          notify({
            message: error.data[0].message,
            classes: 'alert-danger',
            position: 'right'
          });
        });
    };

    $scope.cancel = function(form) {
      $location.path('/contribuyentes');
    }
  }]);

'use strict';


angular.module('qualita.venta')
    .controller('ContribuyenteIndexCtrl', ["$state", "$scope", "ContribuyenteFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "$modal", function ($state, $scope, ContribuyenteFactory, filterFactory,
        ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl, $modal) {

        var dateRender = function (data, type, full) {
            if (data) {
                return moment.utc(data).format('DD/MM/YYYY');
            } else {
                return "";
            }
        };

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'ruc', 'razonSocial', 'rucAnterior', 'estado.descripcion', 'origen.descripcion'];
            $scope.options = {
                'resource': 'contribuyentes',
                'title': 'Contribuyente',
                'factory': ContribuyenteFactory,
                'view': 'BaseList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'id', 'title': translations.ID, visible: false },
                    { 'data': 'ruc', 'title': 'RUC', 'class': 'dt-left' },
                    { 'data': 'razonSocial', 'title': translations.NOMBBRE_RAZON, 'class': 'dt-left' },
                    { 'data': 'rucAnterior', 'title': 'RUC Anterior', 'class': 'dt-left' },
                    { 'data': 'estado.descripcion', 'title': 'Estado', 'render': renderEmptyData, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/estados_contribuyente' },
                    { 'data': 'origen.descripcion', 'title': 'Origen', 'render': renderEmptyData, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/origen_contribuyente' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraMenuOptions':
                    [
                        {
                            'data': 'Importar Contribuyentes',
                            'title': "Carga Contribuyentes",
                            'icon': 'glyphicon glyphicon-upload',
                            'showCondition': function () { return true; },
                            'action': function () {
                                //abrir ventanita para verificar si ya existe el ruc ingresado
                                $scope.tituloModal = 'Verificación Archivo';
                                $scope.mensajeModal = 'Favor ingresar el último dígito de los RUC a cargar:';
                                var modalInstance = $modal.open({
                                    templateUrl: 'qualita-components/ventas/views/contribuyente/confirmation-modal.html',
                                    scope: $scope
                                });
                                $scope.cancel = function () {
                                    modalInstance.dismiss('cancel');
                                };
                                $scope.ok = function (id, config) {
                                    ContribuyenteFactory.verificarExistenciaArchivo(config).then(function (rr) {
                                        if (rr.data === true || rr.data === 'true') {
                                            $state.go("app.contribuyentes.carga");
                                        }
                                    }, function (error) {
                                        var mensaje = "<span>";
                                        _.forEach(error.data, function (err) {
                                            mensaje = mensaje + err.message + "<br/>";
                                        });
                                        mensaje = mensaje + "</span>";
                                        notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                                    });
                                }
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("contribuyentes", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoContribuyentes', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }

                    ],
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);

'use strict';

angular.module('qualita.venta')
  .controller('ContribuyenteFormCtrl', ContribuyenteFormCtrl);

  ContribuyenteFormCtrl.$inject = ['$rootScope', '$scope', 'ContribuyenteFactory', '$location', '$state',
  'contribuyentePrepService', 'notify', 'formFactory', 'VentasLangFactory', 'TiposFactory', 'TiposTipoFactory'];

function ContribuyenteFormCtrl($rootScope, $scope, ContribuyenteFactory, $location, $state,
  contribuyentePrepService, notify, formFactory, VentasLangFactory, TiposFactory, TiposTipoFactory) {


  var vm = this;
  $scope.form = {};

  activate();
  vm.cancel = cancel;
  vm.submit = submit;

  function rucIsValid() {
    console.log("vm.contribuyente.ruc", vm.contribuyente.ruc);
    var p_numero = vm.contribuyente.ruc.split('-')[0];
    var p_digit = vm.contribuyente.ruc.split('-')[1];
    var p_basemax = 11;
    var v_total, v_resto, k, v_numero_aux, v_digit;
    var i;

    k = 2;
    v_total = 0;

    for (i = p_numero.length - 1; i >= 0; i--) {
      k = k > p_basemax ? 2 : k;
      v_numero_aux = p_numero.charAt(i);
      v_total += v_numero_aux * k++;
    }

    v_resto = v_total % 11;
    v_digit = v_resto > 1 ? 11 - v_resto : 0;

    return v_digit == p_digit;
  }

  function activate() {
    vm.TiposFactory = TiposFactory;
    vm.contribuyente = contribuyentePrepService;

    VentasLangFactory.getTranslations().then(function (translations) {
      $scope.translations = translations;

      vm.estados = TiposTipoFactory.get("estados_contribuyente");
      vm.origenes = TiposTipoFactory.get("origen_contribuyente");

      if ($state.is("app.contribuyentes.new")) {
        activateNew();
      } else if ($state.is("app.contribuyentes.edit")) {
        activateEdit();
      } else if ($state.is("app.contribuyentes.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      $scope.submited = false;
    });

  }

  function activateNew() {
    vm.title = 'Nuevo Contribuyente';
    vm.contribuyente = {};
    vm.origenes.$promise.then(function (response) {
      console.log("rep ", response)
      vm.contribuyente.origen = response[1];
  });
  }

  function activateEdit() {
    console.log("edit");
    vm.title = 'Editar Contribuyente';
    vm.contribuyente = contribuyentePrepService;
    console.log("vm.contribuyente", vm.contribuyente);
    vm.entidadId = vm.contribuyente.id;
    vm.entidad = "Contribuyente";
  }

  function activateView() {
    console.log("view");
    vm.title = 'Ver Contribuyente';
    vm.contribuyente = contribuyentePrepService;
    vm.entidadId = vm.contribuyente.id;
    vm.entidad = "Contribuyente";
    vm.view = true;
  }

  function submit() {
    console.log("submit");
    vm.submited = true;
    if ($scope.ContribuyenteForm.$valid) {
      if (!rucIsValid()) {
        console.log("dv invalido");
        var msg = $scope.translations.ERROR_DIGITO_VERIFICADOR;
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
        return;
      }
     // ContribuyenteFactory.get(vm.contribuyente.id, "BaseForm").$promise.then(function (response) {
        formFactory.defaultNSFSubmit($scope.ContribuyenteForm, ContribuyenteFactory, vm.contribuyente, errorHandler).then(function (response) {
          $location.path('/contribuyentes');
        }, function (error) {
          console.log(error);
        });
      //});

    } else {
      notify({ message: 'Campos Incorrectos', classes: 'alert-danger', position: 'right' });
    }
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function (error) {
        msg += '\n\n' + error.message + '.';
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function cancel() {
    $location.path("/contribuyentes");
  }

  $scope.resource = 'contribuyentes'; //MALA PRACTICA, necesario solo para que funcione offline-recovery
}

'use strict';

angular.module('qualita.venta')
  .factory('ContribuyenteFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var Contribuyente = $resource(baseurl.getBaseUrl() + '/contribuyentes/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    var schema = {
      type: 'object',
      title: 'Contribuyente',
      properties: {
        file: {
          type: 'object',
          format: 'fileupload',
          title: 'Archivo'
        }
      },
      required: []
    };

    var fileFormProperty = {
      key: 'file',
      fileName: 'file',
      uploadOptions: {
        imageOnly: false,
        target: baseurl.getBaseUrl() + '/contribuyenteUpload'
      },
      uploader: {}
    };

    var form = [
      fileFormProperty
    ];

    var savedData = {};

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return Contribuyente.query(params);
      },

      get: function (id, view) {
        return Contribuyente.get({ id: id, view: view != undefined ? view : 'base' });
      },

      create: function (attrs) {
        return new Contribuyente(attrs);
      },

      save: function (contribuyente) {
        return (contribuyente.id) ? contribuyente.$update() : contribuyente.$save();
      },

      remove: function (contribuyente) {
        return contribuyente.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function () {
        return form;
      },

      getFileFormProperty: function () {
        return fileFormProperty;
      },

      setData: function set(data) {
        savedData = data;
      },

      getData: function get() {
        return savedData;
      },
      allForSelect: function(params, view){
        if (params) {
          params.view = view || "BaseList";
        } else {
          params = [];
          params.view = view || "BaseList";
        }
        return Contribuyente.query(params);
      },

      verificarExistenciaArchivo: function(config) {
        return $http({
            url: baseurl.getBaseUrl() + '/contribuyentes/verificarexistenciaarchivo/' + config,
            method: "GET"
        });
      }
    };
  }]);

'use strict';

angular.module('qualita.venta')
  .factory('ContribuyenteCargaFactory', ['$resource', 'baseurl','$http', function($resource,  baseurl,$http) {

  	var Contribuyente= $resource( baseurl.getBaseUrl() + '/cargacontribuyentes/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      },
      cerrar: {
        method: 'POST',
        url: baseurl.getBaseUrl() + "/cargacontribuyentes/carga"
      }
    });

    var schema = {
      type: 'object',
      title: 'Contribuyente',
      properties: {
        file: {
          type: 'object',
          format: 'fileupload',
          title: 'Archivo'
        }
      },
      required: []
    };

     var fileFormProperty = {
      key: 'file',
      fileName: 'file',
      uploadOptions: {
        imageOnly: false,
        target: baseurl.getBaseUrl() + '/contribuyenteUpload'
      },
      uploader: {}
    };

    var form = [
      fileFormProperty
    ];

    var savedData = {};

    return {
      all: function(params) {
        return Contribuyente.query(params);
      },

      get: function(id) {
        return Contribuyente.get({id: id});
      },

      create: function(attrs) {
        return new Contribuyente(attrs);
      },

      save: function(contribuyente) {
        return (contribuyente.id) ? contribuyente.$update() : contribuyente.$save();
      },

      remove: function(contribuyente) {
        return contribuyente.$remove();
      },

      schema: function(){
        return schema;
      },

      form: function(){
        return form;
      },

      getFileFormProperty: function() {
        return fileFormProperty;
      },

      setData: function set(data) {
          savedData = data;
      },

      getData: function get() {
          return savedData;
      },
      carga: function(name) {
        return $http({
                url: baseurl.getBaseUrl() + '/cargacontribuyentes/carga', 
                method: "POST",
                params: { filename:name }
              });
      }
    };
  }]);

'use strict';

angular.module('qualita.venta')
  .factory('TipoPresupuestoFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var tipoPresupuesto = $resource(baseurl.getBaseUrl() + '/tipopresupuesto/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return tipoPresupuesto.query(params);
      },
      get: function (id, view) {
        return tipoPresupuesto.get({ id: id, view: view != undefined ? view : 'base' });
      },
      create: function (attrs) {
        return new tipoPresupuesto(attrs);
      },
      save: function (TipoPresupuesto) {
        return (TipoPresupuesto.id) ? TipoPresupuesto.$update() : TipoPresupuesto.$save();
      },
      remove: function (TipoPresupuesto) {
        return TipoPresupuesto.$remove();
      }
    };
  }]);
'use strict';

angular.module('qualita.venta')
  .controller('TipoPresupuestoFormCtrl', ['$rootScope', '$scope', 'TipoPresupuestoFactory', 'VentasLangFactory', 'TiposFactory', 'formFactory', '$location',
    'tipoPresupuestoPrepService', '$state', 'notify', '$filter', 'baseurl', 'ParametrosFactory', 'SucursalesFactory',

    function ($rootScope, $scope, TipoPresupuestoFactory, VentasLangFactory, TiposFactory, formFactory,
      $location, tipoPresupuestoPrepService, $state, notify, $filter, baseurl, ParametrosFactory, SucursalesFactory) {

      activate();
      $scope.submit = submit;
      $scope.cancel = cancel;
      var vm = this;

      function activate() {
        VentasLangFactory.getTranslations().then(function (translations) {
          $scope.translations = translations;
          $rootScope.isProcessing = false;
          getSucursales();

          if ($state.is("app.tipopresupuesto.new")) {
            activateNew();
          } else if ($state.is("app.tipopresupuesto.edit")) {
            activateEdit();
          } else if ($state.is("app.tipopresupuesto.view")) {
            activateView();
          }

        });
      }

      function getSucursales(){

        var params = {};

        params.view = "BaseList";

        SucursalesFactory.all(params).$promise.then(function (response) {
          $scope.listaSucursales = response;
          $scope.listaSucursales.push({ 'id': '', 'codigo': '', 'nombre': 'Todos' });
          $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];
        });
      }

      function activateNew() {
        $scope.title = 'Nuevo Tipo de Presupuesto de Venta';
        $scope.tipoPresupuesto = {};
        $scope.tipoPresupuesto.activo = true;
      }

      function activateEdit() {
        $scope.title = 'Editar Tipo de Presupuesto de Venta';
        $scope.tipoPresupuesto = tipoPresupuestoPrepService;
        $scope.entidadId = $scope.tipoPresupuesto.id;
        $scope.entidad = "TipoPresupuesto";
      }

      function activateView() {
        $scope.title = 'Ver Tipo de Presupuesto de Venta';
        $scope.tipoPresupuesto = tipoPresupuestoPrepService;
        $scope.entidadId = $scope.tipoPresupuesto.id;
        $scope.entidad = "TipoPresupuesto";
        $scope.view = true;
      }

      function submit() {
        console.log("submit");
        $scope.submited = true;
        if ($scope.TipoPresupuestoForm.$valid) {
          formFactory.defaultNSFSubmit($scope.TipoPresupuestoForm, TipoPresupuestoFactory, $scope.tipoPresupuesto, errorHandler).then(function (response) {
            $location.path('/tipopresupuesto');
          }, function (error) {
            console.log(error);
          });
        } else {
          notify({ message: 'Error en campos', classes: 'alert-danger', position: 'right'});
        }
      }

      function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function (error) {
          if (error.constraint == 'nombre') {
            msg += '\n\n' + "Ya existe un tipo de presupuesto con el mismo nombre.\n";
          } else {
            msg += '\n\n' + error.message + '.';
          }
        });
        notify({
          message: msg,
          classes: 'alert-danger',
          position: 'right'
        });
      }

      function cancel() {
        $location.path('/tipopresupuesto');
      }
      ;

    }
  ]);

'use strict';


angular.module('qualita.venta')
    .controller('TipoPresupuestoIndexCtrl', ["$state", "$scope", "TipoPresupuestoFactory", "filterFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "$modal", function ($state, $scope, TipoPresupuestoFactory, filterFactory,
        ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl, $modal) {

        var renderEmptyData = function (data) {
            if (data == undefined || data == null)
                return "Todos";
            else
                return data;
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var defaultColumnOrder = ['id', 'nombre', 'sucursal.nombre', 'activo'];
            $scope.options = {
                'resource': 'tipopresupuesto',
                'title': 'Tipo de Presupuesto de Venta',
                'factory': TipoPresupuestoFactory,
                'view': 'BaseList',
                'defaultColumnOrder': defaultColumnOrder,
                'columns': [
                    { 'data': 'id', 'title': translations.ID, visible: false },
                    { 'data': 'nombre', 'title': 'Nombre', 'class': 'dt-left' },
                    { 'data': 'sucursal.nombre', 'title': 'Sucursal', 'render': renderEmptyData, 'class': 'dt-left' },
                    { 'data': 'activo', 'title': $filter('translate')('ACTIVE'), 'render': renderEmptyData, 'class': 'dt-center', 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
                ],
                'hasOptions': true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraMenuOptions':
                    [
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("tipopresupuesto", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoTiposPresupuestos', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }

                    ],
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.empleados
 * @description
 * # empleados
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('denominacionMonedaDetalleFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var denominacionmonedaDetalle = $resource(baseurl.getBaseUrl() + '/denominacionmonedadetalle/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return denominacionmonedaDetalle.query(params);
      },
      get: function (id, view) {
        console.log(view);
        //if (typeof(view)==='undefined') view = "base";
        return denominacionmonedaDetalle.get({ id: id, view: view != undefined ? view : 'base' });
      },
      create: function (attrs) {
        return new denominacionmonedaDetalle(attrs);
      },
      save: function (denominacionmonedadetalle) {
        return (denominacionmonedadetalle.id) ? denominacionmonedadetalle.$update() : denominacionmonedadetalle.$save();
      },
      remove: function (denominacionmonedadetalle) {
        return denominacionmonedadetalle.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      }
    };
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.denominacionmoneda
 * @description
 * # 
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('denominacionMonedaFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var denominacionMoneda = $resource(baseurl.getBaseUrl() + '/denominacionmoneda/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return denominacionMoneda.query(params);
      },
      get: function(id,view) {
        //if (typeof(view)==='undefined') view = "base";
        return denominacionMoneda.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function (attrs) {
        return new denominacionMoneda(attrs);
      },
      save: function (denominacionmoneda) {
        return (denominacionmoneda.id) ? denominacionmoneda.$update() : denominacionmoneda.$save();
      },
      remove: function (denominacionmoneda) {
        return denominacionmoneda.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      }
    };
  }]);

'use strict';

/**
 * @ngdoc function
 * @name 
 * @description
 * # DenominacionMonedaNewCtrl
 * Controller of the rasApp
 */
angular.module('qualita.venta')
  .controller('DenominacionMonedaFormCtrl', DenominacionMonedaFormCtrl);

  DenominacionMonedaFormCtrl.$inject = ['$scope','denominacionMonedaPrepService', '$location','$state', '$timeout',
  'denominacionMonedaFactory', 'ModelTrimmer', '$rootScope', 'VentasLangFactory', 'notify', 'filterFactory', 'TiposFactory',
   '$filter', 'denominacionMonedaDetalleFactory'];

function DenominacionMonedaFormCtrl($scope, denominacionMonedaPrepService, $location, $state, $timeout, denominacionMonedaFactory,
  ModelTrimmer, $rootScope, VentasLangFactory, notify, filterFactory,TiposFactory, $filter, denominacionMonedaDetalleFactory) {

  var vm = this;

  vm.agregarDetalle = agregarDetalle;
  vm.cancel = cancel;
  vm.submit = submit;
  vm.descripcionChanged = descripcionChanged;
  vm.removeItemFromArray=removeItemFromArray;
  vm.valorDenominacionChanged = valorDenominacionChanged;
  vm.monedasActuales = [];

  function removeItemFromArray(elemento, arreglo) {
        _.remove(arreglo, function(item) {
            return item === elemento;
        });
    }

    activate();

    function activate() {
       VentasLangFactory.getTranslations().then(function(translations) {
         vm.translations = translations;
         

         denominacionMonedaFactory.all().$promise.then(function (data) { 
            _.forEach(data,function(moneda){
              vm.monedasActuales.push(moneda.moneda);
            });
        
            TiposFactory.monedas().then(function (data){
            
              if ($state.is("app.denominacionmoneda.new")){
                const datosFiltrados = _.filter(data.data, function(val) {
                  for (var i = 0; i < vm.monedasActuales.length; i++) {
                    if (val.id == vm.monedasActuales[i].id) {
                      return false;
                    }
                  }   
                  return true;
                });
              
                $scope.monedas = datosFiltrados;
              }
          });
        });

        if($state.is('app.denominacionmoneda.new')) {
          activateNew();
        }else if($state.is('app.denominacionmoneda.edit')) {
          activateEdit();
        }else if($state.is('app.denominacionmoneda.view')) {
          activateView();
        }

        $rootScope.isProcessing = false;

        
      });
 
   }

  function activateNew() {
    vm.title = $filter('translate')('NEW_DENOMINACION_MONEDA');
    vm.denominacionMoneda = denominacionMonedaPrepService;
    vm.denominacionMoneda = {};
    vm.denominacionMoneda.denominacionMonedaDetalle=[{}];
    $scope.entidad = "denominacionMoneda";
    $scope.entidadId = vm.denominacionMoneda.id;
    vm.denominacionMoneda.moneda = {};
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_DENOMINACION_MONEDA');
    vm.denominacionMoneda = denominacionMonedaPrepService;
    $scope.entidadId = vm.denominacionMoneda.id;
    $scope.entidad = "denominacionMoneda";
    $scope.view = true;
    vm.view = true;
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_DENOMINACION_MONEDA');
    vm.denominacionMoneda = denominacionMonedaPrepService;
    $scope.entidadId = vm.denominacionMoneda.id;
    $scope.entidad = "denominacionMoneda";
    vm.edit = true;
  }

  function agregarDetalle() {
      vm.denominacionMoneda.denominacionMonedaDetalle.push({});
      $timeout(function(){
        $scope.$broadcast('newItemAdded');
      }, 20);
  }


  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function(item) {
      return item === elemento;
    });
    $timeout(function(){
      $scope.$broadcast('newItemRemoved');
    }, 20);
  }

  function descripcionChanged(detalle) {

      var valueArr = vm.denominacionMoneda.denominacionMonedaDetalle.map(function(item){ return item.descripcion.toUpperCase()});
      var isDuplicateDescripcion = valueArr.some(function(item, idx){ 
      return valueArr.indexOf(item) != idx
      });
      if(isDuplicateDescripcion){
        var msg = ''
        msg = $filter('translate')('DENOMINACION_DETALLE_DESCRIPCION_DUPLICADO')+"\n";
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
      }
  }

  function valorDenominacionChanged(detalle){

      var valueArr = vm.denominacionMoneda.denominacionMonedaDetalle.map(function(item){ return item.valorDenominacion });
      
      var isDuplicateValor = valueArr.some(function(item, idx){ 
          return valueArr.indexOf(item) != idx 
      });

      if(isDuplicateValor){
        var msg = ''
        msg = $filter('translate')('DENOMINACION_DETALLE_VALOR_DUPLICADO')+"\n";
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
    
      }
  }

  function submit() {

     vm.submited = true;
    if($scope.DenominacionMonedaForm.$valid) {
      var valueArr = vm.denominacionMoneda.denominacionMonedaDetalle.map(function(item){ return item.descripcion.toUpperCase()});
      var isDuplicateDescripcion = valueArr.some(function(item, idx){ 
      return valueArr.indexOf(item) != idx 
      });
      var valueArr = vm.denominacionMoneda.denominacionMonedaDetalle.map(function(item){ return item.valorDenominacion });
      var isDuplicateValor = valueArr.some(function(item, idx){ 
      return valueArr.indexOf(item) != idx 
      });

      if(isDuplicateDescripcion){
          var msg = ''
          msg = $filter('translate')('DENOMINACION_DETALLE_DESCRIPCION_DUPLICADO')+"\n";
          notify({ message: msg, classes: 'alert-danger', position: 'right'});
              
      }else if(isDuplicateValor){

          var msg = ''
          msg = $filter('translate')('DENOMINACION_DETALLE_VALOR_DUPLICADO')+"\n";
          notify({ message: msg, classes: 'alert-danger', position: 'right'});

      }else if(vm.denominacionMoneda.denominacionMonedaDetalle.leng ==0){
    
            var msg = ''
            msg = $filter('translate')('DENOMINACION_DETALLE_VACIO')+"\n";
            notify({ message: msg, classes: 'alert-danger', position: 'right'});
      
      }else{

        $rootScope.isProcessing = true;
        var denominacionMoneda = denominacionMonedaFactory.create(vm.denominacionMoneda);
        submitDenominacionMoneda().then(function (denominacionmoneda) {
            $location.path('/denominacionmoneda');
        }, function(error) {
            $rootScope.isProcessing = false;
            console.log("Ha ocurrido un error");
            console.log(error);
            var msg = '';
            notify({ message: msg, classes: 'alert-danger', position: 'right'});
        });
      
      }
    }else{
      notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }

    function submitDenominacionMoneda(){
        return denominacionMonedaFactory.save(denominacionMoneda);
    }
  }

  function cancel() {
    $location.path("/denominacionmoneda");
  }

  $scope.resource = 'denominacionmoneda'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}
'use strict';


angular.module('qualita.venta')
  .controller('DenominacionMonedaIndexCtrl', DenominacionMonedaIndexCtrl);

  DenominacionMonedaIndexCtrl.$inject = ["AuthorizationService", "$state",
  "filterFactory",
  "ReportTicketFactory",
   "$window",
  "VentasLangFactory",
  "denominacionMonedaFactory",
  "CsvFactory", "UtilFactory", "baseurl", "$filter"];

function DenominacionMonedaIndexCtrl(AuthorizationService, $state,
  filterFactory,
  ReportTicketFactory,
   $window,
   VentasLangFactory, denominacionMonedaFactory,
  CsvFactory, UtilFactory, baseurl, $filter) {

  var vm = this;

  var defaultColumnOrder = ['id', 'moneda.descripcion', 'descripcion'];

  VentasLangFactory.getTranslations().then(function (translations) {
    vm.options = {
      'resource': 'denominacionmoneda',
      'title': $filter('translate')('DENOMINACION_MONEDA_TITLE'),
      'factory': denominacionMonedaFactory,
      'view': 'DenominacionMonedaList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': 'No es posible eliminar el registro, el mismo esta asociado a registros de Declaración de Efectivo de Arqueo Caja.',
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('ID'), visible: false  },
        { 'data': 'moneda.descripcion', 'title': 'Moneda', 'type': 'combo', 'filterUrl': 'tipos/filtro/monedas' },
        { 'data': 'descripcion', 'title': $filter('translate')('DENOMINACION_MONEDA_DESCRIPCION') }
      ],
      'hasOptions': true,
      'hideAddMenu': false,
      'hideEditMenu': false,
      'hideViewMenu': false,
      'hideRemoveMenu': false,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
     'extraMenuOptions':
        [
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoDenominacionMoneda', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function (genTicket) {
                vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open(vm.pdfDownloadURL, '_blank');
              });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

              CsvFactory.csv("denominacionmoneda", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
        ]
    };
  });

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.asignacionmoneda
 * @description
 * # asingacion monedas
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('asignacionMonedaFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var asignacionMoneda = $resource(baseurl.getBaseUrl() + '/asignacionmoneda/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return asignacionMoneda.query(params);
      },
      get: function(id,view) {
        //if (typeof(view)==='undefined') view = "base";
        return asignacionMoneda.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function (attrs) {
        return new asignacionMoneda(attrs);
      },
      save: function (asignacionmoneda) {
        return (asignacionmoneda.id) ? asignacionmoneda.$update() : asignacionmoneda.$save();
      },
      remove: function (asignacionmoneda) {
        return asignacionmoneda.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      }
    };
  }]);
'use strict';

/**
 * @ngdoc function
 * @name 
 * @description
 * # AsignacionMonedaFormCtrl
 * Controller of the rasApp
 */
angular.module('qualita.venta')
  .controller('AsignacionMonedaFormCtrl', AsignacionMonedaFormCtrl);

  AsignacionMonedaFormCtrl.$inject = ['$scope','asignacionMonedaPrepService', '$location','$state', '$timeout',
  'asignacionMonedaFactory', 'ModelTrimmer', '$rootScope', 'VentasLangFactory', 'notify', 'filterFactory', 'TiposFactory',
   '$filter'];

function AsignacionMonedaFormCtrl($scope, asignacionMonedaPrepService, $location, $state, $timeout, asignacionMonedaFactory,
  ModelTrimmer, $rootScope, VentasLangFactory, notify, filterFactory,TiposFactory, $filter) {

  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.ordenChanged = ordenChanged;
  vm.ordenActual = [];
  vm.monedasActuales = [];



    activate();

    function activate() {
       VentasLangFactory.getTranslations().then(function(translations) {
         vm.translations = translations;
         
         asignacionMonedaFactory.all().$promise.then(function (data) { 
            _.forEach(data,function(moneda){
              vm.monedasActuales.push(moneda.moneda);
              vm.ordenActual.push(moneda.orden);
            });

            TiposFactory.monedas().then(function (data){
            
              if ($state.is("app.asignacionmoneda.new")){
                  
                      const datosFiltrados = _.filter( data.data, function(val) {
                              for (var i = 0; i < vm.monedasActuales.length; i++) {
                                  if (val.id == vm.monedasActuales[i].id) {
                                      return false;
                                  }
                              }   
                              return true;
                          }
                      );
                      $scope.monedas = datosFiltrados;
                  
              }

          });
        });

        

        if($state.is('app.asignacionmoneda.new')) {
          activateNew();
        }else if($state.is('app.asignacionmoneda.edit')) {
          activateEdit();
        }else if($state.is('app.asignacionmoneda.view')) {
          activateView();
        }

        $rootScope.isProcessing = false;

        
      });
 
   }

  function activateNew() {
    vm.title = $filter('translate')('NEW_ASIGNACION_MONEDA');
    vm.asignacionMoneda = asignacionMonedaPrepService;
    vm.asignacionMoneda = {};
    $scope.entidad = "asignacionMoneda";
    $scope.entidadId = vm.asignacionMoneda.id;
    vm.asignacionMoneda.moneda = {};

  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_ASIGNACION_MONEDA');
    vm.asignacionMoneda = asignacionMonedaPrepService;
    $scope.entidadId = vm.asignacionMoneda.id;
    $scope.entidad = "asignacionMoneda";
    $scope.view = true;
    vm.view = true;
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_ASIGNACION_MONEDA');
    vm.asignacionMoneda = asignacionMonedaPrepService;
    $scope.entidadId = vm.asignacionMoneda.id;
    $scope.entidad = "asignacionMoneda";
    vm.edit = true;
  }

  
  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function(item) {
      return item === elemento;
    });
    $timeout(function(){
      $scope.$broadcast('newItemRemoved');
    }, 20);
  }

function ordenChanged(orden) {
      
  
    if(orden && vm.ordenActual.indexOf(orden) != -1){
        var msg = ''
        msg = $filter('translate')('ASIGNACION_MONEDA_ORDEN_DUPLICADO')+"\n";
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
    }

    if(orden && !Number.isInteger(orden)){
        var msg = ''
        msg = $filter('translate')('ASIGNACION_MONEDA_ORDEN_ENTERO')+"\n";
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
    }
}


  function submit() {

     vm.submited = true;
    if($scope.AsignacionMonedaForm.$valid) {
        
        if(vm.ordenActual.indexOf(vm.asignacionMoneda.orden) != -1){
            var msg = ''
            msg = $filter('translate')('ASIGNACION_MONEDA_ORDEN_DUPLICADO')+"\n";
            notify({ message: msg, classes: 'alert-danger', position: 'right'});


        }else if(!Number.isInteger(vm.asignacionMoneda.orden)){
            var msg = ''
            msg = $filter('translate')('ASIGNACION_MONEDA_ORDEN_ENTERO')+"\n";
            notify({ message: msg, classes: 'alert-danger', position: 'right'});
        }else{

            $rootScope.isProcessing = true;
            var asignacionMoneda = asignacionMonedaFactory.create(vm.asignacionMoneda);
            submitAsignacionMoneda().then(function (asignacionmoneda) {
                $location.path('/asignacionmoneda');
            }, function(error) {
                $rootScope.isProcessing = false;
                console.log("Ha ocurrido un error");
                console.log(error);
                var msg = '';
                notify({ message: msg, classes: 'alert-danger', position: 'right'});
            });
      
        }
    }else{
      notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }

    function submitAsignacionMoneda(){
        return asignacionMonedaFactory.save(asignacionMoneda);
    }
  }

  function cancel() {
    $location.path("/asignacionmoneda");
  }

  $scope.resource = 'asignacionmoneda'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';


angular.module('qualita.venta')
  .controller('AsignacionMonedaIndexCtrl', AsignacionMonedaIndexCtrl);

  AsignacionMonedaIndexCtrl.$inject = ["AuthorizationService", "$state",
  "filterFactory", 
  "ReportTicketFactory",
   "$window", 
  "VentasLangFactory", 
  "asignacionMonedaFactory",
  "CsvFactory", "UtilFactory", "baseurl", "$filter"];

function AsignacionMonedaIndexCtrl(AuthorizationService, $state,
  filterFactory, 
  ReportTicketFactory,
   $window, 
   VentasLangFactory, asignacionMonedaFactory,
  CsvFactory, UtilFactory, baseurl, $filter) {

  var vm = this;

  var defaultColumnOrder = ['id', 'moneda.descripcion', 'orden'];

  VentasLangFactory.getTranslations().then(function (translations) {
    vm.options = {
      'resource': 'asignacionmoneda',
      'title': $filter('translate')('ASIGNACION_MONEDA_TITLE'),
      'factory': asignacionMonedaFactory,
      'view': 'AsignacionMonedaList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('ASIGNACION_MONEDA_ERROR'),
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('ID'), visible: false },
        { 'data': 'moneda.descripcion', 'title': 'Moneda', 'type': 'combo', 'filterUrl': 'tipos/filtro/monedas' },
        { 'data': 'orden', 'title': $filter('translate')('ASIGNACION_MONEDA_ORDEN')}
      ],
      'hasOptions': true,
      'hideAddMenu': false,
      'hideEditMenu': false,
      'hideViewMenu': false,
      'hideRemoveMenu': false,
      'defaultOrderColumn': 2,
      'defaultOrderDir': "asc",
     'extraMenuOptions':
        [
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoAsignacionMoneda', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function (genTicket) {
                vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open(vm.pdfDownloadURL, '_blank');
              });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

              CsvFactory.csv("asignacionmoneda", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
        ]
    };
  });

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    filtersArr.push(
      {
        path: 'orden',
        sortAsc: 'true'
      }
    );
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}

/**
 * @ngdoc service
 * @name qualita.conceptoarqueo
 * @description
 * # concepto arqueo
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('conceptoArqueoFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var conceptoArqueo = $resource(baseurl.getBaseUrl() + '/conceptoarqueo/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return conceptoArqueo.query(params);
      },
      get: function(id,view) {
        //if (typeof(view)==='undefined') view = "base";
        return conceptoArqueo.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function (attrs) {
        return new conceptoArqueo(attrs);
      },
      save: function (conceptoarqueo) {
        return (conceptoarqueo.id) ? conceptoarqueo.$update() : conceptoarqueo.$save();
      },
      remove: function (conceptoarqueo) {
        return conceptoarqueo.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      }
    };
  }]);
'use strict';

/**
 * @ngdoc function
 * @name 
 * @description
 * # ConceptoArqueoFormCtrl
 * Controller of the rasApp
 */
angular.module('qualita.venta')
  .controller('ConceptoArqueoFormCtrl', ConceptoArqueoFormCtrl);

  ConceptoArqueoFormCtrl.$inject = ['$scope','conceptoArqueoPrepService', '$location','$state', '$timeout',
  'conceptoArqueoFactory', 'ModelTrimmer', '$rootScope', 'VentasLangFactory', 'notify', 'filterFactory', 'TiposFactory',
   '$filter', 'TipoCobroFactory', 'FormaPagoFactory', 'TiposTipoFactory'];

function ConceptoArqueoFormCtrl($scope, conceptoArqueoPrepService, $location, $state, $timeout, conceptoArqueoFactory,
  ModelTrimmer, $rootScope, VentasLangFactory, notify, filterFactory,TiposFactory, $filter, TipoCobroFactory, FormaPagoFactory, TiposTipoFactory) {

  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;

  vm.onChangeAbreviatura = onChangeAbreviatura;
  vm.onChangeDescripcion = onChangeDescripcion;
  vm.tipoConceptoSeleccionado = tipoConceptoSeleccionado;
  vm.tiposCobrosRequerido = false;
  vm.FormaPagoRequerido = false;
  vm.tiposCobroExistentes = [];
  vm.tiposPagoExistentes = [];
  vm.conceptosArqueosActuales = [];
  vm.descripcionesActuales = [];
  vm.abreviaturasActuales = [];
  vm.tiposCobrosAll = [];
  vm.tiposPagosAll = [];

    activate();

    function activate() {
       VentasLangFactory.getTranslations().then(function(translations) {

         vm.translations = translations;
         var filterFormaPago = {};
         filterFormaPago.view = "BaseList";
         filterFormaPago.search = filterFactory.single({
             path: 'clase.codigo',
             notEquals: "forma_pago_referencia"
         }).value();

         var filtroActivo = {};
        filtroActivo.search = filterFactory.and([{
            path: 'activo',
            like: 'true'
        }]).value();

  
         FormaPagoFactory.all(filterFormaPago).$promise.then(function (datos) { 
            _.forEach(datos,function(tipoPago){
              vm.tiposPagosAll.push(tipoPago);
            });

            TipoCobroFactory.all(filtroActivo).$promise.then(function (data){
              _.forEach(data,function(tipoCobro){
                vm.tiposCobrosAll.push(tipoCobro);
              });
            });
            
            $scope.formasPago = vm.tiposPagosAll;
            
            conceptoArqueoFactory.all().$promise.then(function (data){
              _.forEach(data,function(concepto){

                vm.conceptosArqueosActuales.push(concepto);
                if (concepto.formaPago) vm.tiposPagoExistentes.push(concepto.formaPago.id);
                if (concepto.tipoCobro) vm.tiposCobroExistentes.push(concepto.tipoCobro.id);
                vm.abreviaturasActuales.push(concepto.abreviatura.toUpperCase());
                vm.descripcionesActuales.push(concepto.descripcion.toUpperCase());
                    
              });
             
          });
          

          TiposFactory.tiposArqueoConcepto().then(function (data){

            $scope.conceptos = data.data;
            
            TiposFactory.tiposArqueoUtilizadoEn().then(function (data){
              $scope.utilizadoEn = data.data;
              
              TiposFactory.tiposArqueoSigno().then(function (data){
                $scope.signos = data.data;
        
                TiposFactory.tiposArqueoUtilizadoEn().then(function (data){
                  $scope.utilizadoEn = data.data;          
                
                  var datosFiltradosCobros = _.filter(vm.tiposCobrosAll, function(val) {
                    for (var i = 0; i < vm.tiposCobroExistentes.length; i++) {
                      if (val.id == vm.tiposCobroExistentes[i]) {
                        return false;
                      }
                    }   
                    return true;
                  });

                  $scope.tiposCobros = datosFiltradosCobros;

                  
                });
              });
            });
          });
        });

       
       

        if($state.is('app.conceptoarqueo.new')) {
          activateNew();
        }else if($state.is('app.conceptoarqueo.edit')) {
          activateEdit();
        }else if($state.is('app.conceptoarqueo.view')) {
          activateView();
        }

        $rootScope.isProcessing = false;

        
      });
 
   }

  function activateNew() {
    vm.title = $filter('translate')('NEW_CONCEPTO_ARQUEO');
    vm.conceptoArqueo = conceptoArqueoPrepService;
    vm.conceptoArqueo = {};
    $scope.entidad = "conceptoArqueo";
    $scope.entidadId = vm.conceptoArqueo.id;
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_CONCEPTO_ARQUEO');
    vm.conceptoArqueo = conceptoArqueoPrepService;
    $scope.entidadId = vm.conceptoArqueo.id;
    $scope.entidad = "conceptoArqueo";
    $scope.view = true;
    vm.view = true;
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_CONCEPTO_ARQUEO');
    vm.conceptoArqueo = conceptoArqueoPrepService;
    $scope.entidadId = vm.conceptoArqueo.id;
    $scope.entidad = "conceptoArqueo";
    vm.edit = true;
    vm.abreviaturaIncial = vm.conceptoArqueo.abreviatura;
    vm.descripcionInicial = vm.conceptoArqueo.descripcion;
  }

function onChangeAbreviatura(abreviatura) {
    
  if(vm.abreviaturasActuales.indexOf(abreviatura.toUpperCase())!=-1){
        var msg = ''
        msg = $filter('translate')('CONCEPTO_ARQUEO_ABREVIATURA_DUPLICADA')+"\n";
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
  }
}

function tipoConceptoSeleccionado(tipoConcepto){

  if(vm.conceptoArqueo.tipoArqueoConcepto){
      if('Concepto' == vm.conceptoArqueo.tipoArqueoConcepto.descripcion){
        vm.FormaPagoRequerido = false;
        vm.tiposCobrosRequerido = true;
        vm.conceptoArqueo.formaPago = {};
        vm.conceptoArqueo.formaPago = null;
      } else if('Valor' == vm.conceptoArqueo.tipoArqueoConcepto.descripcion){
        vm.tiposCobrosRequerido = false;
        vm.FormaPagoRequerido = true;
        vm.conceptoArqueo.tipoCobro = {};
        vm.conceptoArqueo.tipoCobro = null;
      }else {
        vm.tiposCobrosRequerido = false;
        vm.FormaPagoRequerido = false;
        vm.conceptoArqueo.formaPago = {};
        vm.conceptoArqueo.tipoCobro = {};
        vm.conceptoArqueo.formaPago = null;
        vm.conceptoArqueo.tipoCobro = null;
    }
  }
}


function onChangeDescripcion(descripcion) {

  if(vm.descripcionesActuales.indexOf(descripcion.toUpperCase())!=-1){
        var msg = ''
        msg = $filter('translate')('CONCEPTO_ARQUEO_DESCRIPCION_DUPLICADA')+"\n";
        notify({ message: msg, classes: 'alert-danger', position: 'right'});
  }
    
}


  function submit() {

     vm.submited = true;
    if($scope.ConceptoArqueoForm.$valid) {

          if(vm.descripcionInicial != vm.conceptoArqueo.descripcion && vm.descripcionesActuales.indexOf(vm.conceptoArqueo.descripcion.toUpperCase()) !=-1 ) {
              
              var msg = ''
              msg = $filter('translate')('CONCEPTO_ARQUEO_DESCRIPCION_DUPLICADA')+"\n";
              notify({ message: msg, classes: 'alert-danger', position: 'right'});

          }else if(vm.abreviaturaIncial != vm.conceptoArqueo.abreviatura && vm.abreviaturasActuales.indexOf(vm.conceptoArqueo.abreviatura.toUpperCase()) !=-1 ) {

              var msg = ''
              msg = $filter('translate')('CONCEPTO_ARQUEO_ABREVIATURA_DUPLICADA')+"\n";
              notify({ message: msg, classes: 'alert-danger', position: 'right'});
          
          }else{
            $rootScope.isProcessing = true;
            var conceptoArqueo = conceptoArqueoFactory.create(vm.conceptoArqueo);
            submitConceptoArqueo().then(function (conceptoarqueo) {
                  $location.path('/conceptoarqueo');
              }, function(error) {
                  $rootScope.isProcessing = false;
                  console.log("Ha ocurrido un error");
                  console.log(error);
                  var msg = '';
                  notify({ message: msg, classes: 'alert-danger', position: 'right'});
              });
          }
        
    }else{
      notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }

    function submitConceptoArqueo(){
        return conceptoArqueoFactory.save(conceptoArqueo);
    }
  }

  function cancel() {
    $location.path("/conceptoarqueo");
  }

  $scope.resource = 'conceptoarqueo'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';


angular.module('qualita.venta')
  .controller('ConceptoArqueoIndexCtrl', ConceptoArqueoIndexCtrl);

  ConceptoArqueoIndexCtrl.$inject = ["AuthorizationService", "$state",
  "filterFactory",
  "ReportTicketFactory",
   "$window",
  "VentasLangFactory",
  "conceptoArqueoFactory",
  "CsvFactory", "UtilFactory", "baseurl", "$filter"];

function ConceptoArqueoIndexCtrl(AuthorizationService, $state,
  filterFactory,
  ReportTicketFactory,
   $window,
   VentasLangFactory, conceptoArqueoFactory,
  CsvFactory, UtilFactory, baseurl, $filter) {

  var vm = this;

  var renderEmptyData = function (data) {
    if (data == undefined)
        return "";
    else
        return data;
  };

  var defaultColumnOrder = ['id', 'abreviatura', 'descripcion', 'tipoArqueoConcepto.descripcion',
                             'tipoArqueoUtilizadoEn.descripcion', 'tipoArqueoSigno.descripcion',
                             'activo', 'tipoCobro.nombre', 'formaPago.descripcion', 'codigoPOS'];


  VentasLangFactory.getTranslations().then(function (translations) {
    vm.options = {
      'resource': 'conceptoarqueo',
      'title': $filter('translate')('CONCEPTO_ARQUEO_TITLE'),
      'factory': conceptoArqueoFactory,
      'view': 'ConceptoArqueoList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': 'No es posible eliminar el registro, el mismo esta asociado a registros de Detalles Arqueo Caja.',
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('ID'), visible: false  },
        { 'data': 'abreviatura', 'title': $filter('translate')('CONCEPTO_ARQUEO_ABREVIATURA') },
        { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION') },
        { 'data': 'tipoArqueoConcepto.descripcion', 'title': 'Tipo Concepto', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipo_arqueo_concepto' },
        { 'data': 'tipoArqueoUtilizadoEn.descripcion', 'title': 'Utilizado En', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipo_arqueo_utilizado_en' },
        { 'data': 'tipoArqueoSigno.descripcion', 'title': 'Signo', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipo_arqueo_signo' },
        { 'data': 'activo', 'title': $filter('translate')('ACTIVO') },
        { 'data': 'tipoCobro.nombre', 'title': 'Tipo Cobro', renderWith: renderEmptyData },
        { 'data': 'formaPago.descripcion', 'title': 'Forma Pago', renderWith: renderEmptyData },
        { 'data': 'codigoPOS', 'title': 'Codigo POS' }
      ],
      'hasOptions': true,
      'hideAddMenu': false,
      'hideEditMenu': false,
      'hideViewMenu': false,
      'hideRemoveMenu': false,
      'defaultOrderColumn': 1,
      'defaultOrderDir': "asc",
     'extraMenuOptions':
        [
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = createFilters(vm.options.getFilters());
              ReportTicketFactory.ticket('listadoConceptoArqueo', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function (genTicket) {
                vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open(vm.pdfDownloadURL, '_blank');
              });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function () { return true; },
            'action': function () {
              var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

              CsvFactory.csv("conceptoarqueo", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function (response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
        ]
    };
  });

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}

/**
 * @ngdoc service
 * @name qualita.arqueocajaventa
 * @description
 * # arqueo caja
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('arqueoCajaVentaFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var arqueoCajaVenta = $resource(baseurl.getBaseUrl() + '/arqueocajaventa/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });
    var idArqueoCaja;

    return {
      setIdArqueoCaja : function(id){
        idArqueoCaja = id;
      },
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return arqueoCajaVenta.query(params);
      },
      get: function(id,view) {
        //if (typeof(view)==='undefined') view = "base";
        return arqueoCajaVenta.get({id: id, view: view!=undefined ? view : 'ArqueoCajaVentaForm'});
      },
      create: function (attrs) {
        return new arqueoCajaVenta(attrs);
      },
      save: function (arqueoCajaVenta) {
        return (arqueoCajaVenta.id) ? arqueoCajaVenta.$update() : arqueoCajaVenta.$save();
      },
      remove: function (arqueoCajaVenta) {
        return arqueoCajaVenta.$remove();
      },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      }
    };
  }]);

'use strict';

/**
 * @ngdoc function
 * @name
 * @description
 * # ArqueoCajaVentaFormCtrl
 * Controller of the rasApp
 */
angular.module('qualita.venta')
  .controller('ArqueoCajaVentaFormCtrl', ArqueoCajaVentaFormCtrl);

  ArqueoCajaVentaFormCtrl.$inject = ['$scope','arqueoCajaVentaPrepService', '$location','$state', '$timeout',
  'arqueoCajaVentaFactory', 'ModelTrimmer', '$rootScope', 'VentasLangFactory', 'notify', 'filterFactory', 'TiposFactory',
   '$filter', 'TipoCobroFactory', 'FormaPagoFactory', 'TiposTipoFactory' , 'cajaFactory','$modal',
  'UsuariosFactory','asignacionMonedaFactory','denominacionMonedaFactory'];

function ArqueoCajaVentaFormCtrl($scope, arqueoCajaVentaPrepService, $location, $state, $timeout, arqueoCajaVentaFactory,
  ModelTrimmer, $rootScope, VentasLangFactory, notify, filterFactory,TiposFactory, $filter, TipoCobroFactory, FormaPagoFactory, TiposTipoFactory,cajaFactory,
  $modal,UsuariosFactory,asignacionMonedaFactory,denominacionMonedaFactory) {

  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.cajas = [];

    activate();

    vm.agregarConceptoArqueo = agregarConceptoArqueo;
    vm.agregarValoresArqueo = agregarValoresArqueo;
    vm.removeItemFromArray = removeItemFromArray;
    vm.actualizarImporteDetalle = actualizarImporteDetalle;
    vm.actualizarImporteDetalleValor = actualizarImporteDetalleValor;


    vm.conceptoArqueos = [];
    vm.valoresArqueos = [];
    vm.denominacionesMoneda= [];
    vm.totalConceptoArqueos = 0;
    vm.totalValoresArqueos = 0;
    vm.declaraciones = [];

    function activate() {
       VentasLangFactory.getTranslations().then(function(translations) {

         vm.translations = translations;
         var filterCajas = {};
         filterCajas.view = "BaseList";
         filterCajas.search = filterFactory.single({
             path: 'activo',
             equals: true
         }).value();


         cajaFactory.all(filterCajas).$promise.then(function (datos) {
            _.forEach(datos,function(caja){
              vm.cajas.push(caja);
            });
        });

        if($state.is('app.arqueocajaventa.new')) {
          activateNew();
        }else if($state.is('app.arqueocajaventa.edit')) {
          activateEdit();
        }else if($state.is('app.arqueocajaventa.view')) {
          activateView();
        }

        $rootScope.isProcessing = false;

      });

   }

  function activateNew() {
    vm.title = $filter('translate')('NEW_ARQUEO_CAJA');
    vm.arqueoCajaVenta = arqueoCajaVentaPrepService;
    vm.arqueoCajaVenta = {};
    $scope.entidad = "arqueoCajaVenta";
    $scope.entidadId = vm.arqueoCajaVenta.id;
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_ARQUEO_CAJA');
    vm.arqueoCajaVenta = arqueoCajaVentaPrepService;
    for(var i = 0; i < vm.arqueoCajaVenta.detalleArqueos.length; i++){
      if(vm.arqueoCajaVenta.detalleArqueos[i].valor){
          vm.valoresArqueos.push(vm.arqueoCajaVenta.detalleArqueos[i]);
          vm.totalValoresArqueos = vm.totalValoresArqueos + vm.arqueoCajaVenta.detalleArqueos[i].importeCalc;
      }
      if(vm.arqueoCajaVenta.detalleArqueos[i].concepto){
          vm.conceptoArqueos.push(vm.arqueoCajaVenta.detalleArqueos[i])
          vm.totalConceptoArqueos = vm.totalConceptoArqueos + vm.arqueoCajaVenta.detalleArqueos[i].importeCalc;
      }
    }
    var denominacionGuaranies = null;
    denominacionGuaranies = vm.arqueoCajaVenta.declaracionEfecs.find(function(declaracion){
      if(declaracion.denominacionMoneda.moneda.codigo == 'guaranies'){
        return declaracion.denominacionMoneda;
      }
    });
    if(denominacionGuaranies != null){
      vm.denominacionesMoneda.push(denominacionGuaranies.denominacionMoneda);
    }
    angular.forEach(vm.arqueoCajaVenta.declaracionEfecs, function(declaracion) {
      var ban = false;
      for(var i = 0; i < vm.denominacionesMoneda.length; i++){
        if(vm.denominacionesMoneda[i].moneda.id === declaracion.denominacionMoneda.moneda.id){
          ban = true;
        }
      }
      if(!ban){
        vm.denominacionesMoneda.push(declaracion.denominacionMoneda);
      }
    });

    vm.totalValoresArqueos = parseFloat(vm.totalValoresArqueos).toFixed(2);
    vm.totalValoresArqueos = parseFloat(vm.totalValoresArqueos);
    vm.totalConceptoArqueos = parseFloat(vm.totalConceptoArqueos).toFixed(2);
    vm.totalConceptoArqueos = parseFloat(vm.totalConceptoArqueos);

    angular.forEach(vm.denominacionesMoneda,function(denominacion){
      var item = {};
      item.descripcion = denominacion.moneda.descripcion;
      var detalles = [];
      var total = 0;
      angular.forEach(vm.arqueoCajaVenta.declaracionEfecs, function(declaracion) {
        var itemDetalle = {};
        if(declaracion.denominacionMoneda.moneda.id == denominacion.moneda.id){
          itemDetalle.id = declaracion.id;
          itemDetalle.descripcion = declaracion.descripcion;
          itemDetalle.valor= declaracion.valor;
          itemDetalle.importe = declaracion.importe;
          itemDetalle.cantidad = declaracion.cantidad;
          total += declaracion.importe;
          detalles.push(itemDetalle);
        }
      });
      item.detalles = detalles;
      item.total = total;
      vm.declaraciones.push(item);
    });

    angular.forEach(vm.declaraciones,function(declaracion){
      declaracion.detalles = $filter('orderBy')(declaracion.detalles, '-valor');
    });

    $scope.entidadId = vm.arqueoCajaVenta.id;
    $scope.entidad = "arqueoCajaVenta";
    $scope.view = true;
    vm.view = true;
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_ARQUEO_CAJA');
    vm.arqueoCajaVenta = arqueoCajaVentaPrepService;
    for(var i = 0; i < vm.arqueoCajaVenta.detalleArqueos.length; i++){
      if(vm.arqueoCajaVenta.detalleArqueos[i].valor){
          vm.valoresArqueos.push(vm.arqueoCajaVenta.detalleArqueos[i]);
          vm.totalValoresArqueos = vm.totalValoresArqueos + vm.arqueoCajaVenta.detalleArqueos[i].importeCalc;
      }
      if(vm.arqueoCajaVenta.detalleArqueos[i].concepto){
          vm.conceptoArqueos.push(vm.arqueoCajaVenta.detalleArqueos[i])
          vm.totalConceptoArqueos = vm.totalConceptoArqueos + vm.arqueoCajaVenta.detalleArqueos[i].importeCalc;
      }
    }
    var denominacionGuaranies = null;
    denominacionGuaranies = vm.arqueoCajaVenta.declaracionEfecs.find(function(declaracion){
      if(declaracion.denominacionMoneda.moneda.codigo == 'guaranies'){
        return declaracion.denominacionMoneda;
      }
    });
    if(denominacionGuaranies != null){
      vm.denominacionesMoneda.push(denominacionGuaranies.denominacionMoneda);
    }
    angular.forEach(vm.arqueoCajaVenta.declaracionEfecs, function(declaracion) {
      var ban = false;
      for(var i = 0; i < vm.denominacionesMoneda.length; i++){
        if(vm.denominacionesMoneda[i].moneda.id === declaracion.denominacionMoneda.moneda.id){
          ban = true;
        }
      }
      if(!ban){
        vm.denominacionesMoneda.push(declaracion.denominacionMoneda);
      }
    });
    vm.totalValoresArqueos = parseFloat(vm.totalValoresArqueos).toFixed(2);
    vm.totalValoresArqueos = parseFloat(vm.totalValoresArqueos);
    vm.totalConceptoArqueos = parseFloat(vm.totalConceptoArqueos).toFixed(2);
    vm.totalConceptoArqueos = parseFloat(vm.totalConceptoArqueos);

    angular.forEach(vm.denominacionesMoneda,function(denominacion){
      var item = {};
      item.descripcion = denominacion.moneda.descripcion;
      var detalles = [];
      var total = 0;
      var totalRetiro = 0;
      angular.forEach(vm.arqueoCajaVenta.declaracionEfecs, function(declaracion) {
        var itemDetalle = {};
        if(declaracion.denominacionMoneda.moneda.id == denominacion.moneda.id){
          itemDetalle.id = declaracion.id;
          itemDetalle.descripcion = declaracion.descripcion;
          itemDetalle.valor= declaracion.valor;
          itemDetalle.importe = declaracion.importe;
          itemDetalle.cantidad = declaracion.cantidad;
          if(declaracion.importe > 0){
            total += declaracion.importe;
          }else{
            totalRetiro -= declaracion.importe;
          }

          detalles.push(itemDetalle);
        }
      });
      item.detalles = detalles;
      item.total = total;
      item.totalRetiro = totalRetiro;
      vm.declaraciones.push(item);
    });

    angular.forEach(vm.declaraciones,function(declaracion){
      declaracion.detalles = $filter('orderBy')(declaracion.detalles, '-valor');
    });

    vm.arqueoCajaVenta.fecha = new Date(vm.arqueoCajaVenta.fecha);
    $scope.entidadId = vm.arqueoCajaVenta.id;
    $scope.entidad = "arqueoCajaVenta";
    vm.edit = true;
  }

  function actualizarImporteDetalle(detalle){
    console.log(detalle);
    var totalDeclaraciones = 0;
    var totalRetiros = 0;
    for (var i = 0; i < vm.declaraciones.length; i++) {
      totalDeclaraciones = 0;
      totalRetiros = 0;
      angular.forEach(vm.declaraciones[i].detalles, function(itemDetalle) {
        if(itemDetalle.id == detalle.id){
          itemDetalle.importe = detalle.cantidad * detalle.valor;
        }
        if(itemDetalle.importe > 0){
          totalDeclaraciones += itemDetalle.importe;
        }else{
          totalRetiros -= itemDetalle.importe;
        }
      });
      vm.declaraciones[i].total = totalDeclaraciones;
      vm.declaraciones[i].totalRetiro = totalRetiros;
    }
  }

  function actualizarImporteDetalleValor(detalle){
    console.log(detalle);
    var totalValores = 0;
    for(var i = 0; i < vm.valoresArqueos.length; i++){
      totalValores+=vm.valoresArqueos[i].importeDecl;
    }
    vm.totalValoresArqueos = totalValores;
  }

  function agregarConceptoArqueo() {
    vm.conceptoArqueos.push({
        id: undefined
    });

    $timeout(function () {
        $scope.$broadcast("newItemAdded");
    }, 20);
  }

  function agregarValoresArqueo() {
    vm.valoresArqueos.push({
        id: undefined
    });

    $timeout(function () {
        $scope.$broadcast("newItemAdded");
    }, 20);
  }

  function removeItemFromArray(elemento, arreglo) {
    _.remove(arreglo, function (item) {
        return item === elemento;
    });

}


  function submit() {

    vm.submited = true;
    // var hoy = new Date();
    // vm.arqueoCajaVenta.fecCre = hoy;
    // vm.arqueoCajaVenta.fecha = new Date(vm.arqueoCajaVenta.fecha);
    // vm.arqueoCajaVenta.idEmpresa = 1;
    // vm.arqueoCajaVenta.idUnidad = 1;
    // vm.arqueoCajaVenta.vrTipoArqueo = 'tipo Generico';
    for (var i = 0; i < vm.arqueoCajaVenta.detalleArqueos.length; i++) {
      for (var j = 0; j < vm.valoresArqueos.length; j++) {
        if (vm.arqueoCajaVenta.detalleArqueos[i].id === vm.valoresArqueos[j].id) {
          vm.arqueoCajaVenta.detalleArqueos.importeDecl = vm.valoresArqueos.importeDecl;
          vm.arqueoCajaVenta.detalleArqueos.cantidad = vm.valoresArqueos.cantidad;
        }
      }
    }
    for (var i = 0; i < vm.arqueoCajaVenta.detalleArqueos.length; i++) {
      for (var j = 0; j < vm.conceptoArqueos.length; j++) {
        if (vm.arqueoCajaVenta.detalleArqueos[i].id === vm.conceptoArqueos[j].id) {
          vm.arqueoCajaVenta.detalleArqueos.importeDecl = vm.conceptoArqueos.importeDecl;
          vm.arqueoCajaVenta.detalleArqueos.cantidad = vm.conceptoArqueos.cantidad;
        }
      }
    }
    for (var i = 0; i < vm.declaraciones.length; i++) {
      angular.forEach(vm.declaraciones[i].detalles, function(detalle) {
        for (var j = 0; j < vm.arqueoCajaVenta.declaracionEfecs.length; j++) {
          if(vm.arqueoCajaVenta.declaracionEfecs[j].id == detalle.id){
            vm.arqueoCajaVenta.declaracionEfecs[j].cantidad = detalle.cantidad;
            vm.arqueoCajaVenta.declaracionEfecs[j].importe = detalle.importe;
          }
        }
      });
    }
    vm.arqueoCajaVenta.totalValores = vm.totalValoresArqueos;
    vm.arqueoCajaVenta.diferencia = vm.arqueoCajaVenta.totalValores - vm.arqueoCajaVenta.totalConceptos;
    if($scope.ArqueoCajaVentaForm.$valid) {

      $rootScope.isProcessing = true;
      var arqueoCajaVenta = arqueoCajaVentaFactory.create(vm.arqueoCajaVenta);
      submitArqueoCajaVenta().then(function (arqueoCajaVenta) {
            $location.path('/arqueocajaventa');
        }, function(error) {
            $rootScope.isProcessing = false;
            console.log("Ha ocurrido un error");
            console.log(error);
            var msg = '';
            notify({ message: msg, classes: 'alert-danger', position: 'right'});
        });

    }else{
      notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }

    function submitArqueoCajaVenta(){
        return arqueoCajaVentaFactory.save(arqueoCajaVenta);
    }
  }

  function cancel() {
    $location.path("/arqueocajaventa");
  }

  $scope.resource = 'arqueocajaventa'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}

'use strict';


angular.module('qualita.venta')
  .controller('ArqueoCajaVentaIndexCtrl', ArqueoCajaVentaIndexCtrl);

  ArqueoCajaVentaIndexCtrl.$inject = ["AuthorizationService", "$state",
  "filterFactory",
  "ReportTicketFactory",
   "$window",
  "VentasLangFactory",
  "arqueoCajaVentaFactory",
  "ParametrosFactory",
  "CsvFactory", "UtilFactory", "baseurl","notify", "$filter" , "cajaFactory" ,"UsuariosFactory", "$modal","$scope","ProcesoCobroFactory"];

function ArqueoCajaVentaIndexCtrl(AuthorizationService, $state,
  filterFactory,
  ReportTicketFactory,
   $window,
   VentasLangFactory,arqueoCajaVentaFactory,
   ParametrosFactory,
  CsvFactory, UtilFactory, baseurl,notify, $filter,cajaFactory,UsuariosFactory,$modal,$scope,ProcesoCobroFactory) {

  var vm = this;
  vm.addNewArqueoCaja = addNewArqueoCaja;
  // vm.cajaSeleccionada = cajaSeleccionada;

  var defaultColumnOrder = ['id', 'caja.nombre','procesoCobro.id', 'descripcion', 'vrTipoArqueo',
                             'fecha','totalConceptos','totalValores','diferencia'];

  VentasLangFactory.getTranslations().then(function (translations) {
    vm.options = {
      'resource': 'arqueocajaventa',
      'title': 'Arqueo Caja',
      'factory': arqueoCajaVentaFactory,
      'view': 'ArqueoCajaVentaList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('ARQUEO_CAJA_VENTA_ERROR'),
      'columns': [
        { 'data': 'id', 'title': 'N° Arqueo Caja' },
        { 'data': 'caja.nombre', 'title': 'Caja'},
        { 'data': 'procesoCobro.id', 'title': 'N° Flujo de Cobro' , 'renderWith': function(data, type, full, meta) {
            return data;}},
        { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION') },
        { 'data': 'vrTipoArqueo', 'title': 'Tipo Arqueo' },
        { 'data': 'totalConceptos', 'title': 'Total Conceptos' , 'renderWith': monedaRender, class: "dt-right" },
        { 'data': 'totalValores', 'title': 'Total Valores' , 'renderWith': monedaRender, class: "dt-right" },
        { 'data': "diferencia", 'title': 'Diferencia Total ', 'renderWith': monedaRender, class: "dt-right" },
        {
          data: "fecha",
          title: 'Fecha',
          renderWith: "dateRender",
          dateFormat: "DD/MM/YYYY",
          class: "dt-center",
          type: "date-range"
      }
      ],
      'hasOptions': true,
      'hideAddMenu': true,
      'hideEditMenu': false,
      'hideViewMenu': false,
      'hideRemoveMenu': false,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
      'extraMenuOptions':
        [
          {
            title: translations.NEW_ARQUEO_CAJA,
            icon: "glyphicon glyphicon-plus",
            showCondition: function () {
                var permission = AuthorizationService.hasPermission(
                    "create_arqueocajaventa"
                );
                return permission;
            },
            action: function () {
                vm.addNewArqueoCaja();
            }
          },
          {
            data: $filter("translate")("RESUMEN_ARQUEO"),
            title: $filter("translate")("RESUMEN_ARQUEO"),
            icon: "glyphicon glyphicon-check",
            showCondition: function () {
                return true;
            },
                action: function (itemId) {
                    $scope.selectedItemId = itemId;
                    $scope.obj = {};

                    $scope.tituloModal = $filter("translate")(
                            "RESUMEN_ARQUEO"
                            );
                    $scope.mensajeModal = $filter("translate")(
                            "RESUMEN_ARQUEO_MENSAJE"
                            );
                    var modalInstance = $modal.open({
                        templateUrl: "views/directives/arqueo-resumen-modal.html",
                        scope: $scope
                    });

                    $scope.cancel = function () {
                        modalInstance.dismiss("cancel");
                    };

                    $scope.ok = function (cajaDesde, cajaHasta, fechaDesde, fechaHasta) {
                      if (cajaDesde && cajaHasta && fechaDesde && fechaHasta) {
                        var filtersArr = [];
                        filtersArr.push("cajaDesde=" + cajaDesde);
                        filtersArr.push("cajaHasta=" + cajaHasta);

                        const fechaDesdeFinal= moment(fechaDesde).format("YYYYMMDD");
                        const fechaHastaFinal= moment(fechaHasta).format("YYYYMMDD");

                        filtersArr.push("fechaDesde=" + fechaDesdeFinal);
                        filtersArr.push("fechaHasta=" + fechaHastaFinal);

                        $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('resumenarqueosventa', 'pdf', filtersArr);
                        $window.open($scope.pdfDownloadURL, '_blank');
                        $state.go($state.current, {}, { reload: true });
                        modalInstance.close();
                      }
                    };
                }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                CsvFactory.csv("arqueocajaventa", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
        ],
        extraRowOptions: [{
          templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' ng-click='obtenerReporte(<%=dataCustom%>)' ng-class='{ hidden : !canObtenerReporte(<%=dataId%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
          customAttribute: "id",
          conditionName: "canObtenerReporte",
          conditionDef: function (itemId) {
              // var permission = AuthorizationService.hasPermission("index_cobros");
              return true;
          },
          functionName: "obtenerReporte",
          functionDef: function (itemId) {
              arqueoCajaVentaFactory.get(itemId).$promise.then(function(value) {
                $scope.tituloModal = $filter('translate')('TITLE_ARQUEO_VENTA');
                $scope.mensajeModal = $filter('translate')('MESSAGE_ARQUEO_VENTA');

                var modalInstance = $modal.open({
                    templateUrl: 'views/directives/confirmation-modal.html',
                    scope: $scope
                });

                $scope.cancel = function() {
                    modalInstance.dismiss('cancel');
                };

                $scope.ok = function(id, config) {
                    var filtersArr = [];
                    filtersArr.push("id_arqueo_venta=" + itemId);

                    $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('arqueoscajaventa', 'pdf', filtersArr);
                    $window.open($scope.pdfDownloadURL, '_blank');
                    $state.go($state.current, {}, { reload: true });
                    modalInstance.close();
                };
            });
          }
      }]
    };
  });

  var monedaRender = function (data, type, row) {
    var campoMoneda = row.moneda;
    var moneda = "Gs. ";

    // if (data != undefined) {
    //     var moneda = "Gs. ";
    //     if (campoMoneda.codigo === "dolares") {
    //         moneda = "Usd. ";
    //         data = parseFloat(data).toFixed(2);
    //     } else if (campoMoneda.codigo === "pesos") {
    //         moneda = "Pes. ";
    //         data = parseFloat(data).toFixed(2);
    //     } else if (campoMoneda.codigo === "real") {
    //         moneda = "Rel. ";
    //         data = parseFloat(data).toFixed(2);
    //     } else if (campoMoneda.codigo === "euro") {
    //         moneda = "Eur. ";
    //         data = parseFloat(data).toFixed(2);
    //     } else {
    //         data = parseFloat(data).toFixed(0);
    //     }
    //     // TODO hacer esto mismo en todos los renders de moneda
    //     return (
    //         moneda +
    //         data
    //             .toString()
    //             .replace(".", ",")
    //             .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    //     );
    // } else {
    //     return "";
    // }
    return (
      moneda +
      data
          .toString()
          .replace(".", ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  );
};

  function addNewArqueoCaja() {
      $scope.tituloModal = $filter('translate')('NEW_ARQUEO_CAJA');
      var filterCajas = {};
          filterCajas.view = "BaseList";
          filterCajas.search = filterFactory.single({
              path: 'activo',
              equals: true
          }).value();
      cajaFactory.all(filterCajas).$promise.then(function (response) {
        $scope.cajas = response;
      });
      var usersFilter = {};
      usersFilter.search = filterFactory.and([{
          path: 'activo',
          equals: true
        }]).value();
      usersFilter.view = "BaseList"
      UsuariosFactory.all(usersFilter).$promise.then(function (data) {
        $scope.usuarios = data;
      });
    $scope.procesoCobros = [];
    $scope.cajaSeleccionada = cajaSeleccionada;
      var modalInstance = $modal.open({
        templateUrl: "views/directives/arqueocaja-modal.html",
        scope: $scope
      });

      $scope.cancel = function () {
        modalInstance.dismiss("cancel");
      };



      $scope.ok = function (caja, usuario, fecha, procesoCobro) {
        if(procesoCobro != undefined){
          vm.arqueoCajaVenta = {};
          var hoy = new Date();
          vm.arqueoCajaVenta.fecCre = hoy;
          vm.arqueoCajaVenta.fecha = new Date(fecha);
          // Los siguientes valores se envian en duro ya que son requeridos en la tabla
          vm.arqueoCajaVenta.idEmpresa = 1;
          vm.arqueoCajaVenta.idUnidad = 1;
          vm.arqueoCajaVenta.vrTipoArqueo = 'Arqueo definitivo';
          vm.arqueoCajaVenta.caja = caja;
          vm.arqueoCajaVenta.usrCre= usuario.username;
          vm.arqueoCajaVenta.procesoCobro = procesoCobro;
          sendNewArqueoCajaVenta().then(function (data) {
            // modalInstance.close();
            // $state.go($state.current, {}, { reload: true });
            if (data != undefined) {
                modalInstance.close();
                arqueoCajaVentaFactory.setIdArqueoCaja(data.id);
                redirectArqueoCaja(data.id);
            }
          }, function (error) {
              notify({ message: error.data[0].message, classes: "alert-danger", position: "right" });
          });
        } else {
          var msg = "N° Flujo de Cobro no seleccionado";
          notify({ message: msg, classes: "alert-danger", position: "right" });
        }
      }
  }

  function cajaSeleccionada(cajaSeleccionada,fecha,usuario) {
    if(cajaSeleccionada != undefined && fecha != undefined && usuario != undefined){
      var filterArqueadoN = []
      var filterArqueadoNull = []
      filterArqueadoN=[{
        path: "caja.id",
        equals: cajaSeleccionada.id
        },
        {
            path: "estado.codigo",
            equals: "estado_procesocobro_cerrado"
        },
        {
          path: "usuario.id",
          equals: usuario.id
        },
        {
          path: "arqueado",
          equals: 'N'
        },
        {
          path: "fechaHoraInicio",
          like: moment(fecha).format("DD/MM/YYYY")
        }]

      filterArqueadoNull= [{
        path: "caja.id",
          equals: cajaSeleccionada.id
        },
        {
            path: "estado.codigo",
            equals: "estado_procesocobro_cerrado"
        },
        {
          path: "usuario.id",
          equals: usuario.id
        },
        {
          path: "arqueado",
          isNull: true
        },
        {
          path: "fechaHoraInicio",
          like: moment(fecha).format("DD/MM/YYYY")
        }]
        var filterPc = {
            search: filterFactory.or([
                filterFactory.and(filterArqueadoN).value(),
                filterFactory.and(filterArqueadoNull).value()
            ]).value()
        }
      ProcesoCobroFactory.all(filterPc).$promise.then(function (response) {
          $scope.procesoCobros = response;
      });
    }
  };

  function sendNewArqueoCajaVenta() {
    var arqueoCajaVenta = arqueoCajaVentaFactory.create(vm.arqueoCajaVenta);
    return arqueoCajaVentaFactory.save(arqueoCajaVenta).then(function (response) {
        return response;
    }, function (error) {
        notify({ message: error.data[0].message, classes: "alert-danger", position: "right" });
    });
}


  function redirectArqueoCaja(itemId) {
    arqueoCajaVentaFactory.get(itemId).$promise.then(function (data) {
        arqueoCajaVentaFactory.setIdArqueoCaja(data.id);
        $state.go('app.arqueocajaventa.edit', { id: itemId });
    });
  }


  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}



'use strict';

/**
 * @ngdoc service
 * @name qualita.suscripcionRecurrente
 * @description
 * # suscripcion recurrente
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('suscripcionRecurrenteFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var suscripcionRecurrente = $resource(baseurl.getBaseUrl() + '/suscripcionrecurrente/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      },
      aprobar: {
        method: "PUT",
        url: baseurl.getBaseUrl() + "/suscripcionrecurrente/aprobar/:id"
      },
      cancelar: {
        method: "PUT",
        url: baseurl.getBaseUrl() + "/suscripcionrecurrente/cancelar/:id"
      }
    });
    var suscripcion = null;
    var idSuscripcion = -1;
    var enabledStates = [false, true, true];

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return suscripcionRecurrente.query(params);
      },
      get: function(id,view) {
        //if (typeof(view)==='undefined') view = "base";
        return suscripcionRecurrente.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function (attrs) {
        return new suscripcionRecurrente(attrs);
      },
      save: function (suscripcionrecurrente) {
        return (suscripcionrecurrente.id) ? suscripcionrecurrente.$update() : suscripcionrecurrente.$save();
      },
      remove: function (suscripcionrecurrente) {
        return suscripcionrecurrente.$remove();
      },
      getIdSuscripcion: function() {
        return idSuscripcion;
      },

      setIdSuscripcion : function(id) {
        idSuscripcion = id;
      },

     getSuscripcion : function() {
        this.updateStates(suscripcion.estado.codigo);
        return suscripcion;
    },

    setSuscripcion : function(susc) {
        if (susc.id) {
            suscripcion = susc;
            console.log("Estado susc:", suscripcion.estado.codigo);
            this.updateStates(suscripcion.estado.codigo);
        }
        
    },
    tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
    }
    ,
    aprobar: function (id) {
        return suscripcionRecurrente.aprobar({ id: id }).$promise;
    },
    cancelar: function (id) {
      return suscripcionRecurrente.cancelar({ id: id }).$promise;
    },
    updateStates: function(estado) {

      if(estado){
        if(estado === 'agendado' || estado === 'autorizado'){

          enabledStates = [false, false, true]

        }else if( estado === 'procesando' || estado === 'ejecutado'){
          enabledStates = [false, false, false];
        } else{
          enabledStates = [false, true, true];
        }

      }
      

    },
    getEnabledStates:function(){
      return enabledStates;
    }
    };
  }]);
'use strict';

/**
 * @ngdoc function
 * @name 
 * @description
 * # SuscripcionRecurrenteFormCtrl
 * Controller of the rasApp
 */
angular.module('qualita.venta')
  .controller('SuscripcionRecurrenteFormCtrl', SuscripcionRecurrenteFormCtrl);

  SuscripcionRecurrenteFormCtrl.$inject = ['$scope','suscripcionRecurrentePrepService', '$location','$state', '$timeout',
  'suscripcionRecurrenteFactory', 'ModelTrimmer', '$rootScope', 'VentasLangFactory', 'notify', 'filterFactory', 'TiposFactory',
   '$filter', 'ClienteFactory','UtilFactory'];

function SuscripcionRecurrenteFormCtrl($scope, suscripcionRecurrentePrepService, $location, $state, $timeout, suscripcionRecurrenteFactory,
  ModelTrimmer, $rootScope, VentasLangFactory, notify, filterFactory,TiposFactory, $filter, ClienteFactory, UtilFactory) {

  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.validarFechas = validarFechas;
  vm.changeCliente = changeCliente;
  vm.delayRequestCliente = delayRequestCliente;
  vm.searchClienteFilter = searchClienteFilter;
  vm.changeCienteSucursal = changeCienteSucursal;
  vm.searchSucursalClienteFilter = searchSucursalClienteFilter;


  vm.correo1 = "";
  vm.correo2 = "";
  vm.correo3 = "";
  
  activate();
  
  function changeCienteSucursal(){

  }

  function searchSucursalClienteFilter(criteria) {
    return function (item) {
        if (!criteria) {
            return true;
        }
        return (
            item.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
            (item.codigoSucursal.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ) ||
            (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase()) > -1)
        );
    };
}

  function delayRequestCliente(searchValue) {
    var userInputFilter = {}
    if (searchValue.length >= 3) {
        var filterNombre = [];
        var filterNombreFantasia = [];
        var filterCodigo = [];
        var filterNumero = [];
        var filterDireccion = [];
        var filterCiudad = [];

        filterNombre.push({
            path: "nombre",
            like: searchValue
        })
        filterNombre.push({
            path: "activo",
            equals: true
        })
        filterNombreFantasia.push({
            path: "nombreFantasia",
            like: searchValue
        })
        filterNombreFantasia.push({
            path: "activo",
            equals: true
        })
        filterCodigo.push({
            path: "codigo",
            like: searchValue
        })
        filterCodigo.push({
            path: "activo",
            equals: true
        })
        filterNumero.push({
            path: "numero",
            like: searchValue
        })
        filterNumero.push({
            path: "activo",
            equals: true
        })
        filterDireccion.push({
            path: "direccion",
            like: searchValue
        })
        filterDireccion.push({
            path: "activo",
            equals: true
        })
        filterCiudad.push({
            path: "ciudad.descripcion",
            like: searchValue
        })
        filterCiudad.push({
            path: "activo",
            equals: true
        })

        userInputFilter = {
            search: filterFactory.or([
                filterFactory.and(filterNombre).value(),
                filterFactory.and(filterNombreFantasia).value(),
                filterFactory.and(filterCodigo).value(),
                filterFactory.and(filterNumero).value(),
                filterFactory.and(filterDireccion).value(),
                filterFactory.and(filterCiudad).value()
            ]).value()
        }

        recuperarCliente(userInputFilter)
    } else {
        vm.clientes = undefined
    }
}
  
  function recuperarCliente(userInputFilter) {
    var finalFilter = UtilFactory.createNestedFilters(
        vm.finalFilters, userInputFilter)

    ClienteFactory.allForSelect(finalFilter).$promise.then(function (clientes) {
        var clie = [];
        for (var i = 0; i < clientes.length; i++) {
            if (clientes[i].bloquearVentas !== true) {
                clie.push(clientes[i])
            }
        }
        vm.clientes = clie;
    })
  }

  function changeCliente(){
      var suc = [];
      for (var i = 0; i < vm.suscripcionRecurrente.cliente.sucursales.length; i++) {
           if (vm.suscripcionRecurrente.cliente.sucursales[i].activo === true) {
              suc.push(vm.suscripcionRecurrente.cliente.sucursales[i])
            }
      }
      vm.sucursalesCliente = suc;

      if (vm.sucursalesCliente.length >= 1) {
        vm.suscripcionRecurrente.sucursalCliente = vm.sucursalesCliente[0];
      }
  }

  function searchClienteFilter(criteria) {
    return function (item) {
        if (!criteria) {
            return true;
        }
        return (
            item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
            (item.nombreFantasia && item.nombreFantasia.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
            (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
            (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
        );
    };
}

  function activate() {
       VentasLangFactory.getTranslations().then(function(translations) {
         
        vm.translations = translations;
        $scope.clientesFactory = ClienteFactory;
        $scope.staticFilterClie = [{ path: "activo", equals: true }];
        
        TiposFactory.monedas().then(function (data){
          $scope.monedas = data.data;
          
          TiposFactory.tiposSuscripcionEstado().then(function (data){
              
              $scope.estado = data.data;
              
              
                
                var defaultEstado = _.filter(data.data, function(val) {
                  for (var i = 0; i < data.data.length; i++) {
                    if (val.codigo == 'ingresado') {
                      return true;
                    }
                  }   
                  return false;
                });
                vm.suscripcionRecurrente.estado = defaultEstado[0];
            
            TiposFactory.tiposSuscripcionOperacion().then(function (data){
                $scope.operaciones = data.data;

            });
        });
      });
        
        if($state.is('app.suscripcionrecurrente.new')) {
          activateNew();
        }else if($state.is('app.suscripcionrecurrente.edit')) {
          activateEdit();
        }else if($state.is('app.suscripcionrecurrente.view')) {
          activateView();
        }

        $rootScope.isProcessing = false;

        
      });
 
   }

  function activateNew() {
    vm.title = $filter('translate')('NEW_SUSCRIPCION_RECURRENTE');
    vm.suscripcionRecurrente = suscripcionRecurrentePrepService;
    vm.suscripcionRecurrente = {};
    $scope.entidad = "suscripcionRecurrente";
    $scope.entidadId = vm.suscripcionRecurrente.id;
    vm.suscripcionRecurrente.diasDeLaSemana="";
    vm.suscripcionRecurrente.fecha = vm.curDate = new Date();
  }

  function activateView() {
    vm.title = $filter('translate')('VIEW_SUSCRIPCION_RECURRENTE');
    vm.suscripcionRecurrente = suscripcionRecurrentePrepService;
    $scope.entidadId = vm.suscripcionRecurrente.id;
    $scope.entidad = "suscripcionRecurrente";
   // $scope.dias.diasSemana = JSON.parse(vm.suscripcionRecurrente.diasDeLaSemana);
    if(vm.suscripcionRecurrente.correos){
      var correos = vm.suscripcionRecurrente.correos.split(",");
      vm.correo1 = correos[0];
      vm.correo2 = (correos[1]) ? correos[1]: "";
      vm.correo3 = (correos[2]) ? correos[1]: "";
    }

    vm.suscripcionRecurrente.fechaInicial = moment(vm.suscripcionRecurrente.fechaInicial, "DD/MM/YYYY").toDate();
    vm.suscripcionRecurrente.fechaFinal =  moment(vm.suscripcionRecurrente.fechaFinal, "DD/MM/YYYY").toDate();
    vm.suscripcionRecurrente.fecha =  moment(vm.suscripcionRecurrente.fecha, "DD/MM/YYYY").toDate();
    
    $scope.view = true;
    vm.view = true;
  }

  function activateEdit() {
    vm.title = $filter('translate')('EDIT_SUSCRIPCION_RECURRENTE');
    vm.suscripcionRecurrente = suscripcionRecurrentePrepService;
    $scope.entidadId = vm.suscripcionRecurrente.id;
    $scope.entidad = "suscripcionRecurrente";
    vm.suscripcionRecurrente.fechaInicial = moment(vm.suscripcionRecurrente.fechaInicial, "DD/MM/YYYY").toDate();
    vm.suscripcionRecurrente.fechaFinal =  moment(vm.suscripcionRecurrente.fechaFinal, "DD/MM/YYYY").toDate();
    vm.suscripcionRecurrente.fecha =  moment(vm.suscripcionRecurrente.fecha, "DD/MM/YYYY").toDate();
    var suc = [];
    for (var i = 0; i < vm.suscripcionRecurrente.cliente.sucursales.length; i++) {
         if (vm.suscripcionRecurrente.cliente.sucursales[i].activo === true) {
            suc.push(vm.suscripcionRecurrente.cliente.sucursales[i])
          }
    }
    vm.sucursalesCliente = suc;


    //$scope.dias.diasSemana = JSON.parse(vm.suscripcionRecurrente.diasDeLaSemana);
    if(vm.suscripcionRecurrente.correos){
      var correos = vm.suscripcionRecurrente.correos.split(",");
      vm.correo1 = correos[0];
      vm.correo2 = (correos[1]) ? correos[1]: "";
      vm.correo3 = (correos[2]) ? correos[1]: "";
    }
   
    vm.edit = true;
  }

 
  function validarFechas(){
    if(vm.suscripcionRecurrente && vm.suscripcionRecurrente.fechaInicial && vm.suscripcionRecurrente.fechaFinal){
           
      if(vm.suscripcionRecurrente.fechaFinal < vm.suscripcionRecurrente.fechaInicial) {
        
        var msg = '';
        msg = $filter('translate')('SUSCRIPCION_FECHAS_INCORRECTAS')+"\n";
        notify({ message: msg, classes: 'alert-danger', position: 'right'});

      }

    }
  }

  
  function submit() {

     vm.submited = true;
    if($scope.SuscripcionRecurrenteForm.$valid) {
            $rootScope.isProcessing = true;
            var suscripcionRecurrente = suscripcionRecurrenteFactory.create(vm.suscripcionRecurrente);
            
           
            suscripcionRecurrente.correos = vm.correo1+","+ vm.correo2+","+ vm.correo3;
            
            
            suscripcionRecurrente.fecha = moment(vm.suscripcionRecurrente.fecha).format('DD/MM/YYYY');
            suscripcionRecurrente.fechaInicial = moment(vm.suscripcionRecurrente.fechaInicial).format('DD/MM/YYYY');
            suscripcionRecurrente.fechaFinal = moment(vm.suscripcionRecurrente.fechaFinal).format('DD/MM/YYYY');
            

            if(vm.suscripcionRecurrente.fechaFinal < vm.suscripcionRecurrente.fechaInicial){
                
                var msg = '';
                msg = $filter('translate')('SUSCRIPCION_FECHAS_INCORRECTAS')+"\n";
                notify({ message: msg, classes: 'alert-danger', position: 'right'});
           
            }else {
                  submitSuscripcionRecurrente().then(function (suscripcionrecurrente) {
                    
                    suscripcionRecurrenteFactory.get(suscripcionrecurrente.id, "BaseList").$promise.then(function(suscripcion) {
                      suscripcionRecurrenteFactory.setIdSuscripcion(suscripcion.id);
                      suscripcionRecurrenteFactory.setSuscripcion(suscripcion);
                      $state.go("app.suscripcionrecurrente.proceso.comprobanteventa.list", {
                              idSuscripcion: suscripcion.id,
                              modeAccess: ""
                          });
                     });
                   
                    //$location.path('/suscripcionrecurrente');
             
                }, function(error) {
                    $rootScope.isProcessing = false;
                    console.log("Ha ocurrido un error");
                    console.log(error);
                    var msg = '';
                    notify({ message: msg, classes: 'alert-danger', position: 'right'});
                });
            }
      
    }else{
      notify({ message: $filter('translate')('WRONG_FIELDS'), classes: 'alert-danger', position: 'right'});
    }

    function submitSuscripcionRecurrente(){
        return suscripcionRecurrenteFactory.save(suscripcionRecurrente);
    }
  }

  function cancel() {
    $location.path("/suscripcionrecurrente");
  }

  $scope.resource = 'suscripcionrecurrente'; //MALA PRACTICA, necesario solo para que funciona offline-recovery
}
'use strict';


angular.module('qualita.venta')
  .controller('SuscripcionRecurrenteIndexCtrl', SuscripcionRecurrenteIndexCtrl);

  SuscripcionRecurrenteIndexCtrl.$inject = ["AuthorizationService", "$state",
  "filterFactory", 
  "ReportTicketFactory",
   "$window", 
  "VentasLangFactory", 
  "suscripcionRecurrenteFactory",
  "CsvFactory", "UtilFactory", "baseurl", "$filter", "$modal", "$scope", "WatchLocacionChangeFactory"];

function SuscripcionRecurrenteIndexCtrl(AuthorizationService, $state,
  filterFactory, 
  ReportTicketFactory,
   $window, 
   VentasLangFactory, suscripcionRecurrenteFactory,
  CsvFactory, UtilFactory, baseurl, $filter, $modal, $scope, WatchLocacionChangeFactory) {

  var vm = this;

  var defaultColumnOrder = ['id', 'fecha','descripcion', 'cliente.nombre', 'fechaInicial', 'fechaFinal',
                           'tipoOperacionGenerar.descripcion', 'estado.descripcion'];

  function aprobar(itemId) {
    suscripcionRecurrenteFactory.aprobar(itemId).then(function(response) {
              $state.go($state.current, {}, { reload: true });
          },
          function(error){
              notify({ message: error, classes: 'alert-danger', position: 'right'});
          });
  }

  function eliminarSuscripcion(itemId){

    suscripcionRecurrenteFactory.get(itemId, "BaseList").$promise.then(function(suscripcion) {
                  
      suscripcionRecurrenteFactory.remove(suscripcion).then(
        function() {
          $state.go($state.current, {}, { reload: true });
           
        },
        function(error) {
          notify({ message: error, classes: 'alert-danger', position: 'right'});
        });
     });
  }


  function cancelar(itemId) {
    suscripcionRecurrenteFactory.cancelar(itemId).then(function(response) {

              $state.go($state.current, {}, { reload: true });
          },
          function(error){
              notify({ message: error, classes: 'alert-danger', position: 'right'});
          });
  }
              
  

  VentasLangFactory.getTranslations().then(function (translations) {
    var staticFilter = undefined;
        staticFilter = {};
        staticFilter.search = filterFactory
                        .and([
                            {
                                path: "id",
                                isNotNull: "true"
                            },
                            {
                              path: "id",
                              sortDesc: "true"
                          }
                        ])
                        .value();
    vm.options = {
      'resource': 'suscripcionrecurrente',
      'title': $filter('translate')('SUSCRIPCION_RECURRENTE_TITLE'),
      'factory': suscripcionRecurrenteFactory,
      'view': 'SuscripcionRecurrenteList',
      'staticFilter': staticFilter,
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('SUSCRIPCION_RECURRENTE_ERROR'),
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('NUMBER')},
        { 'data': 'fecha', 'title': $filter('translate')('DATE'), renderWith: "dateRender", dateFormat: 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
        { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPCION') },
        { 'data': 'cliente.nombre', 'title':  $filter('translate')('CLIENTE') },
        { 'data': 'fechaInicial', 'title': $filter('translate')('FECHA_INICIO_VIGENGIA'), renderWith: "dateRender", dateFormat: 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
        { 'data': 'fechaFinal', 'title': $filter('translate')('FECHA_FIN_VIGENGIA'), renderWith: "dateRender", dateFormat: 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
        //{ 'data': 'primeraFacturacion', 'title': 'Fecha Ejecución' },
        { 'data': 'tipoOperacionGenerar.descripcion', 'title': 'Tipo', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipo_suscripcion_operacion' },        
        //{ 'data': 'tipoPeriodicidad.descripcion', 'title': 'Periodicidad', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipo_suscripcion_periodicidad' },
        { 'data': 'estado.descripcion', 'title': 'Estado', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipo_suscripcion_estado' },

      ],
      'hasOptions': true,
      'hideAddMenu': false,
      'hideEditMenu': true,
      'hideViewMenu': false,
      'hideRemoveMenu': true,
      'defaultOrderColumn': 0,
      'defaultOrderDir': "desc",
     'extraMenuOptions':
        [
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                CsvFactory.csv("suscripcionrecurrente", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            }
          }
        ],
        extraRowOptions: [
          {
            templateToRender: "<button title='" + $filter("translate")("AGREGAR_DETALLE_SUSCRIPCION_RECURRENTE") +
                "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='agregarDetalle(<%=dataId%>)' ng-class='{ hidden : !canCreateDetalle(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-plus'></span> </button>",
            functionName: "agregarDetalle",
            conditionName: "canCreateDetalle",
            //customAttribute: [],
            conditionDef: function() {
              return AuthorizationService.hasPermission("create_plantilla");
            },
            functionDef: function(itemId) {
              suscripcionRecurrenteFactory.get(itemId, "BaseList").$promise.then(function(suscripcion) {
                suscripcionRecurrenteFactory.setIdSuscripcion(suscripcion.id);
                suscripcionRecurrenteFactory.setSuscripcion(suscripcion);
                $state.go("app.suscripcionrecurrente.proceso.comprobanteventa.list", {
                        idSuscripcion: itemId,
                        modeAccess: ""
                    });
               });
            }
          },  
          {
          templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter("translate")("EDIT") +
                        "' ng-click='editar(<%=dataId%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          customAttribute: "estado",
          conditionName: "canEditar",
          conditionDef: function (estado) {

            return (estado.codigo == 'ingresado') && AuthorizationService.hasPermission("update_suscripcionrecurrente");

          },
          functionName: "editar",
          functionDef: function (itemId) {
                $state.go('app.suscripcionrecurrente.edit', { id:  itemId });
          }
          },
          {
              templateToRender: "<button class='btn btn-danger btn-dt' style='margin-right: 5px;' title='" +
              $filter("translate")("DELETE") +
              "' ng-click='borrar(<%=dataId%>)' ng-class='{ hidden : !canBorrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-trash'></span> </button>",
              customAttribute: "estado",
              functionName: "borrar",
              functionDef: function (itemId) {

                  $scope.mensajeModal = $filter("translate")("ELIMINAR_SUSCRIPCION_MENSAJE");
                $scope.tituloModal = $filter("translate")("ELIMINAR_SUSCRIPCION");
                var modalInstance = $modal.open({
                                            templateUrl: "views/directives/confirmation-modal.html",
                                            scope: $scope
                });
                $scope.cancel = function() {
                  modalInstance.dismiss("cancel");
                };
                  $scope.ok = function() { 
                    eliminarSuscripcion(itemId); 
                  }
             
              },
              conditionName: "canBorrar",
              conditionDef: function (estado) {
                return (estado.codigo == 'ingresado') && AuthorizationService.hasPermission("delete_suscripcionrecurrente"); 
              }
          },
          {
            templateToRender: "<button title='" + $filter("translate")("AUTORIZAR_SUSCRIPCION_RECURRENTE") +
                "' class='btn btn-success btn-dt' style='margin-right: 5px;' ng-click='aprobarSuscripcion(<%=dataId%>)' ng-class='{ hidden : !canAprobarSuscripcion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-check'></span> </button>",
            functionName: "aprobarSuscripcion",
            customAttribute: ["estado"],
            conditionName: "canAprobarSuscripcion",
            conditionDef: function (atributos) {
             
              return (AuthorizationService.hasPermission("aprobar_suscripcionrecurrente") && (atributos[0].codigo == 'agendado')) ;
              

            },
            functionDef: function(itemId) {
             
              $scope.mensajeModal = $filter("translate")("APROBAR_SUSCRIPCION_MENSAJE");
              $scope.tituloModal = $filter("translate")("APROBAR_SUSCRIPCION");
              var modalInstance = $modal.open({
                                            templateUrl: "views/directives/confirmation-modal.html",
                                            scope: $scope
              });
              $scope.cancel = function() {
                  modalInstance.dismiss("cancel");
             };
             $scope.ok = function() { 
              aprobar(itemId); }
            }
          },
          {
            templateToRender: "<button title='" + $filter("translate")("CANCELAR_SUSCRIPCION_RECURRENTE") +
                "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='cancelarSuscripcion(<%=dataId%>)' ng-class='{ hidden : !canCancelarSuscripcion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
            functionName: "cancelarSuscripcion",
            conditionName: "canCancelarSuscripcion",
            customAttribute: "estado",
            conditionDef: function (estado) {
              return AuthorizationService.hasPermission("cancelar_suscripcionrecurrente") && estado.codigo == 'autorizado';
            },
            functionDef: function(itemId) {
             
              $scope.mensajeModal = $filter("translate")("CANCELAR_SUSCRIPCION_MENSAJE");
              $scope.tituloModal = $filter("translate")("CANCELAR_SUSCRIPCION");
              var modalInstance = $modal.open({
                                            templateUrl: "views/directives/confirmation-modal.html",
                                            scope: $scope
              });
              $scope.cancel = function() {
                  modalInstance.dismiss("cancel");
             };
             $scope.ok = function() { 
              cancelar(itemId); }
            }
          }
      ]
    };
    //WatchLocacionChangeFactory.watchWith(staticFilter, vm.options, "locacion.id");
  });

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}
'use strict';
angular.module('qualita.venta')
  .controller('SuscripcionProcesoCtrl', SuscripcionProcesoCtrl);

SuscripcionProcesoCtrl.$inject = ["AuthorizationService", "suscripcionRecurrenteFactory", "TiposFactory", "$log", "$modal",
"$scope", "notify", "VentasLangFactory"];

function SuscripcionProcesoCtrl(AuthorizationService, suscripcionRecurrenteFactory, TiposFactory, $log, $modal, $scope,
  notify, $stateParams, VentasLangFactory) {

    var vm = this;
    console.log("Suscripcion Controller:", $stateParams.codigoVenta);
  vm.idSuscripcion = suscripcionRecurrenteFactory.getIdSuscripcion();
  vm.suscripcion = suscripcionRecurrenteFactory.getSuscripcion();
  vm.enabledStates = suscripcionRecurrenteFactory.getEnabledStates();
  console.log("vm.suscripcion: ", vm.suscripcion);
  
  $scope.$on("updateSuscripcionStates", function() {
    console.log("updateSuscripcionStates listener triggered");
    vm.enabledStates = suscripcionRecurrenteFactory.getEnabledStates();
  });
}
'use strict';

/**
 * @ngdoc service
 * @name qualita.agendaSuscripcionRecurrenteFactory
 * @description
 * # suscripcion recurrente
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('agendaSuscripcionRecurrenteFactory', ['$resource', 'baseurl', '$http', function ($resource, baseurl, $http) {

    var agendaSuscripcionRecurrente = $resource(baseurl.getBaseUrl() + '/agendamiento/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      },
      saveArray: {
        method: 'POST',
        url: baseurl.getBaseUrl() + "/agendamiento/list"
      },
      cancelar: {
        method: "PUT",
        url: baseurl.getBaseUrl() + "/agendamiento/cancelar/:id"
      },
      ejecutarForzado: {
        method: "PUT",
        url: baseurl.getBaseUrl() + "/agendamiento/ejecutarForzado/:id"
      }
    });
    var suscripcion = null;
    var idSuscripcion = -1;

    return {
      all: function (params, view) {
        if (params) {
          params.view = view != undefined ? view : 'BaseList';
        } else {
          params = [];
          params.view = view != undefined ? view : 'BaseList';
        }
        return agendaSuscripcionRecurrente.query(params);
      },
      get: function(id,view) {
        //if (typeof(view)==='undefined') view = "base";
        return agendaSuscripcionRecurrente.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function (attrs) {
        return new agendaSuscripcionRecurrente(attrs);
      },
      save: function (agendasuscripcionrecurrente) {
        return (agendasuscripcionrecurrente.id) ? agendasuscripcionrecurrente.$update() : agendasuscripcionrecurrente.$save();
      },
      remove: function (agendasuscripcionrecurrente) {
        return agendasuscripcionrecurrente.$remove();
      },
      getIdSuscripcion: function() {
        return idSuscripcion;
      },

      setIdSuscripcion : function(id) {
        idSuscripcion = id;
      },

     getSuscripcion : function() {
       // updateStates();
        return suscripcion;
    },

    setSuscripcion : function(susc) {
        if (susc.id) {
            suscripcion = susc;
        }
        //updateStates();
    },
      tipos: function (codigo) {
        return $http.get(baseurl.getBaseUrl() + '/tipos/tipo/' + codigo);
      },
      saveArray: function (agendasuscripcionrecurrente) {
        return agendaSuscripcionRecurrente.saveArray(agendasuscripcionrecurrente).$promise;
      },
      cancelar: function (id) {
        return agendaSuscripcionRecurrente.cancelar({ id: id }).$promise;
      },
      ejecutarForzado: function (id) {
        return agendaSuscripcionRecurrente.ejecutarForzado({ id: id }).$promise;
      }
    };
  }]);
'use strict';


angular.module('qualita.venta')
  .controller('AgendamientoSuscripcionRecurrenteIndexCtrl', AgendamientoSuscripcionRecurrenteIndexCtrl);

AgendamientoSuscripcionRecurrenteIndexCtrl.$inject = ["AuthorizationService", "$state",
  "filterFactory",
  "ReportTicketFactory",
  "$window", "$scope", "$modal", "notify",
  "VentasLangFactory",
  "suscripcionRecurrenteFactory",
  "CsvFactory", "UtilFactory", "baseurl", "$filter", "ComprobanteVentaFactory", "agendaSuscripcionRecurrenteFactory"];

function AgendamientoSuscripcionRecurrenteIndexCtrl(AuthorizationService, $state,
  filterFactory,
  ReportTicketFactory,
  $window, $scope, $modal, notify,
  VentasLangFactory, suscripcionRecurrenteFactory,
  CsvFactory, UtilFactory, baseurl, $filter, ComprobanteVentaFactory, agendaSuscripcionRecurrenteFactory) {

  var vm = this;
  var staticFilter = undefined;

  vm.suscripcionRecurrente = suscripcionRecurrenteFactory.getSuscripcion();

  var defaultColumnOrder = ['id', 'comprobanteVenta.tipo.descripcion', 'fecha', 'cliente.nombre', 'importe',
    'estado.descripcion', 'activo', 'accion.descripcion', 'fechaGeneracion',
    , 'fechaUltimaEjecucion',  'numeroComprobante', 'intervaloRepeticion.descripcion'];

  VentasLangFactory.getTranslations().then(function (translations) {
    if (staticFilter == undefined) {
      staticFilter = {};
      staticFilter.search = filterFactory
        .and([
          {
            path: "suscripcionRecurrente.id",
            equals: suscripcionRecurrenteFactory.getIdSuscripcion()
          }
        ]).value();
    }

    var monedaRender = function (data, type, row) {

      if (data != undefined) {
        return (
          data
            .toString()
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        );
      } else {
        return "";
      }
    };

    
    vm.options = {
      'staticFilter': staticFilter,
      'resource': 'agendamiento',
      'title': $filter('translate')('SUSCRIPCION_RECURRENTE_AGENDAMIENTO_TITLE'),
      'factory': agendaSuscripcionRecurrenteFactory,
      'view': 'AgendaSuscripcionRecurrenteList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('SUSCRIPCION_RECURRENTE_ERROR'),
      'columns': [
        { 'data': 'id', 'title': $filter('translate')('ID'), visible: false },
        { 'data': 'comprobanteVenta.tipo.descripcion', 'title': 'Tipo', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipo_suscripcion_operacion' },
        { 'data': 'fecha', 'title': $filter('translate')('DATE'), renderWith: "dateRender", dateFormat: 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
        { 'data': 'cliente.nombre', 'title': $filter('translate')('CLIENTE') },
        { 'data': 'importe', 'title': $filter('translate')('IMPORTE'), render: monedaRender },
        { 'data': 'estado.descripcion', 'title': 'Estado', 'type': 'combo', 'filterUrl': 'tipos/filtro/tipo_agenda_suscripcion_estado' },
        { 'data': 'activo', 'title': $filter('translate')('ACTIVO') },
        { 'data': 'accion.descripcion', 'title': $filter('translate')('ACCION'), 'type': 'combo', 'filterUrl': 'tipos/filtro/tipo_agenda_suscripcion_accion', renderWith: 'emptyRender' },
        { 'data': 'fechaGeneracion', 'title': $filter('translate')('FECHA_EJECUCION'), renderWith: "dateRender", dateFormat: 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
        { 'data': 'fechaUltimaEjecucion', 'title': $filter('translate')('FECHA_ULTIMA_EJECUCION'), renderWith: "dateRender", dateFormat: 'DD/MM/YYYY', 'class': 'dt-center', 'type': 'date-range' },
        { 'data': 'numeroComprobante', 'title': 'Nro. de Comprobante', renderWith: "emptyRender", 'class': 'dt-center'},
        { 'data': 'intervaloRepeticion.descripcion', 'title': $filter('translate')('INTERVALO_REPETICION'), 'type': 'combo', 'filterUrl': 'tipos/filtro/tipo_agenda_suscripcion_intervalo', renderWith: 'emptyRender' }
      ],
      'hasOptions': true,
      'hideAddMenu': true,
      'hideEditMenu': false,
      'hideViewMenu': true,
      'hideRemoveMenu': false,
      'defaultOrderColumn': 8,
      'defaultOrderDir': "asc",
      'extraMenuOptions':
        [
          /* {
             'title': 'Reporte',
             'icon': 'glyphicon glyphicon-file',
             'showCondition': function () { return true; },
             'action': function () {
               var filters = createFilters(vm.options.getFilters());
               ReportTicketFactory.ticket('listadoSuscripcionRecurrente', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function (genTicket) {
                 vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                 $window.open(vm.pdfDownloadURL, '_blank');
               });
             }
           },
           {
             'title': 'CSV',
             'icon': 'glyphicon glyphicon-export',
             'showCondition': function () { return true; },
             'action': function () {
               var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);
 
               CsvFactory.csv("suscripcionrecurrente", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                 .then(function (response) {
                   console.log("response de csv: ", response);
                   var fileName = response.data.fileName;
                   $window.open(baseurl.getPublicBaseUrl() + fileName);
                 });
             }
           }*/
        ]
      ,
      extraRowOptions: [
        {
          templateToRender: "<button title='" + "Cancelar Agendamiento" +
            "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='cancelarAgendamiento(<%=dataId%>)' ng-class='{ hidden : !canCancelarAgendamiento(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
          functionName: "cancelarAgendamiento",
          conditionName: "canCancelarAgendamiento",
          customAttribute: "estado",
          conditionDef: function (estado) {
            return AuthorizationService.hasPermission("cancelaragendamiento_suscripcionrecurrente") && estado.codigo!="cancelado" && estado.codigo!="procesado";
          },
          functionDef: function (itemId) {

            $scope.mensajeModal = "Esta seguro que desea cancelar el Agendamiento";
            $scope.tituloModal = "Cancelar Agendamiento";
            var modalInstance = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });
            $scope.cancel = function () {
              modalInstance.dismiss("cancel");
            };
            $scope.ok = function () {
              agendaSuscripcionRecurrenteFactory.cancelar(itemId).then(function (response) {

                $state.go($state.current, {}, { reload: true });
              },
                function (error) {
                  notify({ message: error.data[0].message, classes: 'alert-danger', position: 'right' });
                });
            }
          }
        },
        {
          templateToRender: "<button title='" + "Forzar Ejecución" +
            "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='forzarEjecucion(<%=dataId%>)' ng-class='{ hidden : !canForzarEjecucion(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ok-circle'></span> </button>",
          functionName: "forzarEjecucion",
          conditionName: "canForzarEjecucion",
          customAttribute: "estado",
          conditionDef: function (estado) {
            return (AuthorizationService.hasPermission("forzarejecucion_suscripcionrecurrente") && estado.codigo != "cancelado" && estado.codigo!="procesado");
          },
          functionDef: function (itemId) {

            $scope.mensajeModal = "Esta seguro que desea Forzar la Ejecución del Agendamiento";
            $scope.tituloModal = "Forzar Ejecución";
            var modalInstance = $modal.open({
              templateUrl: "views/directives/confirmation-modal.html",
              scope: $scope
            });
            $scope.cancel = function () {
              modalInstance.dismiss("cancel");
            };
            $scope.ok = function () {
              agendaSuscripcionRecurrenteFactory.ejecutarForzado(itemId).then(function (response) {

                $state.go($state.current, {}, { reload: true });
              },
                function (error) {
                  notify({ message: error.data[0].message, classes: 'alert-danger', position: 'right' });
                });
            }
          }
        }
      ]
    };
  });

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({ path: data, like: search })
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}
'use strict';

/**
 * @ngdoc service
 * @name qualita.venta
 * @description
 * # HistoricoCobroDetalleFactory
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('HistoricoCobroDetalleFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
    '$translatePartialLoader', '$translate', 'AuthorizationService',
    function (
      $resource,
      baseurl
      ) {

      var HistoricoCobroDetalle = $resource(baseurl.getBaseUrl() + '/historicocobrodetalle/:id', { id: '@id' }, {
      });

      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined || params.view != undefined ? (view || params.view) : 'BaseList';
          } else {
            params = [];
            params.view = view != undefined ? view : 'BaseList';
          }
          return HistoricoCobroDetalle.query(params);
        },

        get: function (id, view) {
          return HistoricoCobroDetalle.get({ id: id, view: view != undefined ? view : 'BaseList' });
        }
      };
    }]);

angular
  .module("qualita.diferenciacambio", ["ngResource", "ui.tree", "ngFileUpload"])
  .config([
    "$stateProvider",
    function ($stateProvider) {
      $stateProvider
        .state("app.diferenciacambios", {
          url: "/diferenciacambios",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Diferencia de Cambio"
          }
        })
        .state("app.diferenciacambios.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "DiferenciaCambioIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.diferenciacambios",
            label: "Diferencia de Cambio",
            description: "Listado de Diferencia de Cambio",
            skip: true
          }
        })
        .state("app.diferenciacambios.new", {
          url: "/new",
          templateUrl: "qualita-components/diferenciacambio/views/diferenciacambios/form.html",
          controller: "DiferenciaCambioFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.diferenciacambios",
            label: "Nueva Diferencia de Cambio",
            description: "Creación de una nueva Diferencia de Cambio"
          },
          resolve: {
            diferenciaCambioPrepService: function () {
              return;
            }
          }
        })
        .state("app.diferenciacambios.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/diferenciacambio/views/diferenciacambios/form.html",
          controller: "DiferenciaCambioFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.diferenciacambios",
            label: "Editar Diferencia de Cambio",
            description: "Edición de la Diferencia de Cambio #"
          },
          resolve: {
            diferenciaCambioPrepService: diferenciaCambioPrepService
          }
        })
        .state("app.diferenciacambios.view", {
          url: "/:id",
          templateUrl: "qualita-components/diferenciacambio/views/diferenciacambios/form.html",
          controller: "DiferenciaCambioFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.diferenciacambios",
            label: "Ver Diferencia de Cambio",
            description: "Ver Diferencia de Cambio #"
          },
          resolve: {
            diferenciaCambioPrepService: diferenciaCambioPrepService
          }
        })
        .state("app.revaluos", {
          url: "/revaluo",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Revalúo"
          }
        })
        .state("app.revaluos.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "RevaluoIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.revaluos",
            label: "Revalúo",
            description: "Listado de Revalúo",
            skip: true
          }
        })
        .state("app.revaluos.new", {
          url: "/new",
          templateUrl: "qualita-components/diferenciacambio/views/revaluo/form.html",
          controller: "RevaluoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.revaluos",
            label: "Nuevo Revalúo",
            description: "Creación de un nuevo Revalúo"
          },
          resolve: {
            revaluoPrepService: function () {
              return;
            }
          }
        })
        .state("app.revaluos.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/diferenciacambio/views/revaluo/form.html",
          controller: "RevaluoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.revaluos",
            label: "Editar Revalúo",
            description: "Edición del Revalúo #"
          },
          resolve: {
            revaluoPrepService: revaluoPrepService
          }
        })
        .state("app.revaluos.view", {
          url: "/:id",
          templateUrl: "qualita-components/diferenciacambio/views/revaluo/form.html",
          controller: "RevaluoFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.revaluos",
            label: "Ver Revalúo",
            description: "Ver Revalúo #"
          },
          resolve: {
            revaluoPrepService: revaluoPrepService
          }
        })
    }
  ]);

function diferenciaCambioPrepService(DiferenciaCambioFactory, $stateParams) {
  return DiferenciaCambioFactory.get($stateParams.id, "DiferenciaCambioForm").$promise;
}
diferenciaCambioPrepService.$inject = ["DiferenciaCambioFactory", "$stateParams"];

function revaluoPrepService(RevaluoFactory, $stateParams) {
  return RevaluoFactory.get($stateParams.id, "RevaluoForm").$promise;
}
revaluoPrepService.$inject = ["RevaluoFactory", "$stateParams"];


'use strict';

/**
 * @ngdoc service
 * @name qualita.venta
 * @description
 * # cobrosfactory
 * Factory in the qualita.
 */
angular.module('qualita.diferenciacambio')
  .factory('DiferenciaCambioFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
    '$translatePartialLoader', '$translate',
    function ($resource, baseurl, filterFactory, TiposFactory, $http, $translatePartialLoader, $translate) {

      var DiferenciaCambio = $resource(baseurl.getBaseUrl() + '/diferenciacambios/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        },
        generarDiferencia: {
          method: 'GET',
          url: baseurl.getBaseUrl() + "/diferenciacambios/generardiferencia",
          isArray: true
        },
        getDiferenciaCSV: {
          method: 'POST',
          url: baseurl.getBaseUrl() + "/diferenciacambios/csvdiferencia/:id"
        }
      });

      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined || params.view != undefined ? (view || params.view) : 'BaseList';
          } else {
            params = [];
            params.view = view != undefined ? view : 'BaseList';
          }
          return DiferenciaCambio.query(params);
        },

        get: function (id, view) {
          return DiferenciaCambio.get({ id: id, view: view != undefined ? view : 'base' });
        },

        getDiferenciaCSV: function (id) {
          return DiferenciaCambio.getDiferenciaCSV({ id: id });
        },

        create: function (attrs) {
          return new DiferenciaCambio(attrs);
        },

        save: function (diferenciaCambio) {
          return (diferenciaCambio.id) ? diferenciaCambio.$update() : diferenciaCambio.$save();
        },

        remove: function (diferenciaCambio) {
          return diferenciaCambio.$remove();
        },

        generar: function (idTipo, fechaDesde, fechaHasta, idTipoSecundario, idMoneda) {
          var stringDesde = moment(fechaDesde).format("DD/MM/YYYY");
          var stringHasta = moment(fechaHasta).format("DD/MM/YYYY");
          var params = {
            tipo: idTipo,
            tipoSecundario: idTipoSecundario,
            moneda: idMoneda,
            fechaDesde: stringDesde,
            fechaHasta: stringHasta,
            view:"DiferenciaCambioForm"
          }
          return DiferenciaCambio.generarDiferencia(params);
        }
      };
    }]);

"use strict";

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module("qualita.diferenciacambio").factory("DiferenciaCambioLangFactory", [
  "$resource",
  "baseurl",
  "$http",
  "$translatePartialLoader",
  "$translate",
  function($resource, baseurl, $http, $translatePartialLoader, $translate) {
    var translationsNeeded = [
    "REVALUO",
    "FAILED_DELETE_REVALUO",
    "CODE",
    "DATE",
    "DATE_STARTS",
    "DATE_END",
    "TIPO",
    "TIPO_CLIENTE",
    "TIPO_PROVEEDOR",
    "NEW_REVALUO",
    "EDIT_REVALUO",
    "VIEW_REVALUO",
    "DATE",
    "FECHA_FIN",
    "FECHA_INICIO",
    "DIFERENCIA_CAMBIO",
    "TIPO",
    "TIPO_PROVEEDOR",
    "TIPO_CLIENTE",
    "REVALUO",
    "CODE",
    "DATE",
    "DATE_STARTS",
    "DATE_END",
    "TIPO",
    "DATE",
    "FECHA_FIN",
    "FECHA_INICIO",
    "DIFERENCIA_CAMBIO",
    "TIPO",
    "TIPO_PROVEEDOR",
    "TIPO_CLIENTE",
    "REVALUO",
    "CODE",
    "DATE",
    "DATE_STARTS",
    "DATE_END",
    "TIPO",
    "ERROR_GENERAR_DIFERENCIA_CAMBIO",
    "TIPO_COMPROBANTE",
    "RAZON_SOCIAL",
    "NUMBER",
    "FECHA_OPERACION",
    "COMPLETE_DIFERENCIA_CAMBIO_REQUIRED_FIELDS",
    "DATE_REVALUO",
    "TIPO_COMPROBANTE_REVALUO",
    "RAZON_SOCIAL_REVALUO",
    "NUMBER_REVALUO",
    "IMPORTE_REVALUO",
    "TIPO_CAMBIO_R",
    "IMPORTE_LOCAL_REVALUO",
    "TIPO_CAMBIO_REVALUO",
    "IMPORTE_REVALUO",
    "DIFERENCIA_CAMBIO_REVALUO",
    "DIFERENCIA_CAMBIO_VACIA",
    "DATOS_COMPROBANTE",
    "DATOS_PAGO",
    "RANGO_FECHAS_NO_VALIDO",
    "DETAIL_EMPTY",
    "VIEW_CSV"
    ];

    return {
      getTranslations: function() {
        $translatePartialLoader.addPart("diferenciacambio");
        return $translate.refresh().then(function() {
          return $translate(translationsNeeded);
        });
      }
    }
  }
]);

'use strict';


angular.module('qualita.diferenciacambio')
  .controller('DiferenciaCambioIndexCtrl', DiferenciaCambioIndexCtrl);

DiferenciaCambioIndexCtrl.$inject = ["AuthorizationService", "$state",
"filterFactory", "ReportTicketFactory", "$window", "DiferenciaCambioLangFactory",
"CsvFactory", "UtilFactory", "baseurl", "$filter", "DiferenciaCambioFactory"];

function DiferenciaCambioIndexCtrl(AuthorizationService, $state,
  filterFactory, ReportTicketFactory, $window, DiferenciaCambioLangFactory,
  CsvFactory, UtilFactory, baseurl, $filter, DiferenciaCambioFactory) {
  var vm = this;

  DiferenciaCambioLangFactory.getTranslations().then(function(translations){
      setUpOptions();
  });

  function setUpOptions() {
    var defaultColumnOrder = ['id','fecha', 'fechaInicio', 'fechaFin'];

    vm.options = {
      'resource': 'diferenciacambios',
      'title': $filter('translate')('DIFERENCIA_CAMBIO_TITLE'),
      'factory': DiferenciaCambioFactory,
      'defaultColumnOrder': defaultColumnOrder,
      'view': 'DiferenciaCambioList',
      'failedDeleteError': $filter('translate')('PUNTO_VENTA_DELETE_ERROR'),
      'columns': [
        {data: 'id', 'title': $filter('translate')('ID'), visible: false },
        {data: "fecha", title: $filter('translate')('DATE'), renderWith: 'dateRender', dateFormat: 'DD/MM/YYYY', class: "dt-center", type: "date-range"},
        {data: "fechaInicio", title: $filter('translate')('FECHA_INICIO'), renderWith: 'dateRender', dateFormat: 'DD/MM/YYYY', class: "dt-center", type: "date-range"},
        {data: "fechaFin", title: $filter('translate')('FECHA_FIN'), renderWith: 'dateRender', dateFormat: 'DD/MM/YYYY', class: "dt-center", type: "date-range"}
        ],
      'hasOptions' : true,
      'hideAddMenu': false,
      'hideEditMenu': false,
      'hideViewMenu': false,
      'hideRemoveMenu': false,
      'defaultOrderColumn' : 0,
      'defaultOrderDir' : "desc",
      'extraMenuOptions':
        [
            getReporteOption(),
            getCSVOption()
        ],
        'extraRowOptions':
        [
          getRowReporteOption(),
          getRowCSVOption()
        ]
    };
  }

  function getRowReporteOption() {
    return {
      templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='" +
          $filter("translate")("VIEW_REPORTE") +
          "' ng-click='viewReporte(<%=dataId%>)' ng-class='{ hidden : !canViewReporte(<%=dataId%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
      functionName: "viewReporte",
      functionDef: function(itemId) {
        var filtersArr = [];
        filtersArr.push("idDiferencia=" + itemId);
        vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
            "diferenciacambio",
            "pdf",
            filtersArr
        );
        $window.open(vm.pdfDownloadURL, "_blank");
        $state.go($state.current, {}, { reload: true });
      },
      conditionName: "canViewReporte",
      conditionDef: function(itemId) {
          return true;
      }
    }
  }

  function getRowCSVOption() {
    return {
      templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='" +
          $filter("translate")("VIEW_CSV") +
          "' ng-click='viewCSV(<%=dataId%>)' ng-class='{ hidden : !canViewCSV(<%=dataId%>)}'> <span class='glyphicon glyphicon-export'></span> </button>",
      functionName: "viewCSV",
      functionDef: function(itemId) {
          DiferenciaCambioFactory.getDiferenciaCSV(itemId).$promise.then(function(response) {
            var fileName = response.fileName;
            $window.open(baseurl.getPublicBaseUrl() + fileName);
          });
      },
      conditionName: "canViewCSV",
      conditionDef: function(itemId) {
          return true;
      }
    }
  }

  function getReporteOption() {
    return {
        'title': 'Reporte',
        'icon': 'glyphicon glyphicon-file',
        'showCondition': function() { return true; },
        'action': function() {
            var filters = createFilters(vm.options.getFilters());
            ReportTicketFactory.ticket('listadoPuntosVenta', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
            vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
            $window.open(vm.pdfDownloadURL, '_blank');
            });
        }
    }
  }

  function getCSVOption() {
    return {
        'title': 'CSV',
        'icon': 'glyphicon glyphicon-export',
        'showCondition': function() { return true; },
        'action': function() {
            var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

            CsvFactory.csv("diferenciacambios", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
            .then(function(response) {
            console.log("response de csv: ", response);
            var fileName = response.data.fileName;
            $window.open(baseurl.getPublicBaseUrl() + fileName);
            });
        }
    }

    vm.emptyDataRender = function(data) {
      //console.log(data);
      if (data == undefined) return "";
      else return data;
    };
  }

  
                 var createFilters = function (filters) {
                    var filtersArr = [];
                    _.each(filters, function (search, data) {
                        search = search.split("~");
                        if (search.length > 1) {
                            /* El parámetro es un rago */

                            var isDate = search[0].split("/");

                            if (isDate.length > 1) {
                                /* Es un rago de fechas */

                                filtersArr.push({path: data, equalOrAfter: search[0]})
                                filtersArr.push({path: data, equalOrBefore: search[1]})
                            } else {
                                /* El un rago numérco */

                                filtersArr.push({path: data, greaterOrEqual: search[0]})
                                filtersArr.push({path: data, lessOrEqual: search[1]})
                            }
                        } else {
                            /* El parametro no es un rago */
                            filtersArr.push({path: data, like: search[0]})
                        }
                    });
                    var filters = filterFactory.and(filtersArr).value();
                    return filters;
                }
}

"use strict";

angular
  .module("qualita.diferenciacambio")
  .controller("DiferenciaCambioFormCtrl", DiferenciaCambioFormCtrl);

DiferenciaCambioFormCtrl.$inject = [
  "$rootScope",
  "$scope",
  "DiferenciaCambioFactory",
  "formFactory",
  "TiposFactory",
  "$location",
  "$state",
  "diferenciaCambioPrepService",
  "$timeout",
  "notify",
  "ModelTrimmer",
  "filterFactory",
  "DiferenciaCambioLangFactory",
  "WatchLocacionChangeFactory",
  "UtilFactory",
  "ParametrosFactory",
  "cajaFactory",
  "CotizacionMonedaFactory",
  "TiposTipoFactory",
  "$filter",
  "observeOnScope",
  "TipoProveedorFactory",
  "TipoClienteFactory"
];

function DiferenciaCambioFormCtrl(
  $rootScope,
  $scope,
  DiferenciaCambioFactory,
  formFactory,
  TiposFactory,
  $location,
  $state,
  diferenciaCambioPrepService,
  $timeout,
  notify,
  ModelTrimmer,
  filterFactory,
  DiferenciaCambioLangFactory,
  WatchLocacionChangeFactory,
  UtilFactory,
  ParametrosFactory,
  cajaFactory,
  CotizacionMonedaFactory,
  TiposTipoFactory,
  $filter,
  observeOnScope,
  TipoProveedorFactory,
  TipoClienteFactory
) {
  var vm = this;
  $rootScope.isProcessing = false;

  activate();

  function refreshTiposDiferenciaCambio() {
    return TiposTipoFactory.get("tipos_diferencia_cambio").$promise.then(function(response) {
      vm.tiposDiferenciaCambio = response;
      return response;
    })
  }

  function makeAsyncRequests() {
    vm.tiposDiferenciaCambio = TiposTipoFactory.get("tipos_diferencia_cambio");
    vm.tiposProveedor = TipoProveedorFactory.all();
    vm.tiposCliente = TipoClienteFactory.all();
    vm.refreshTiposDiferenciaCambio = refreshTiposDiferenciaCambio;
    vm.translationData = {
        moneda: ""
    }
    vm.monedaLocal = CotizacionMonedaFactory.getMonedaLocal();
    vm.monedaLocal.then(function(response) {
      vm.translationData.moneda = response.valorTipo.descripcion;
    });
    TiposFactory.monedas().then(function(data) {
      vm.monedaLocal.then(function(response) {
        var codigoMonedaLocal = response.valorTipo.codigo;
        vm.monedasExtranjeras = _.filter(data.data, function(moneda) {
          return moneda.codigo !== codigoMonedaLocal;
        });
      });
    })
  }

  function activate() {
    WatchLocacionChangeFactory.killAllWatchers();

    makeAsyncRequests();

    vm.search = {};

    DiferenciaCambioLangFactory.getTranslations().then(function(translations) {
      vm.translations = translations;
      vm.curDate = new Date();
    });


    if ($state.is("app.diferenciacambios.new")) {
      activateNew();
    } else if ($state.is("app.diferenciacambios.edit")) {
      activateEdit();
    } else if ($state.is("app.diferenciacambios.view")) {
      activateView();
    }
    $rootScope.isProcessing = false;
    vm.submited = false;
  }

  function activateNew() {
    vm.title = $filter("translate")("NEW_DIFERENCIA_CAMBIO");
    vm.diferenciaCambio = {};
    vm.diferenciaCambio.fecha = new Date();
  }

  function activateEdit() {
    vm.title = $filter("translate")("EDIT_DIFERENCIA_CAMBIO");
    getPrepService();
    console.log("diferencia cambio: ", vm.diferenciaCambio);
    vm.diferenciaCambio.fecha = new Date(vm.diferenciaCambio.fecha);
  }

  function activateView() {
    vm.view = true;
    vm.title = $filter("translate")("VIEW_DIFERENCIA_CAMBIO");
    getPrepService();
  }

  function getPrepService() {
    vm.diferenciaCambio = diferenciaCambioPrepService;
    vm.diferenciaCambio.diferenciaCambioDetalle = _.map(vm.diferenciaCambio.diferenciaCambioDetalle, function(detalle) {
      detalle.fecha = moment(detalle.fecha, 'YYYY/MM/DD').toDate();
      detalle.fechaCobro = moment(detalle.fechaCobro, 'YYYY/MM/DD').toDate();
      return detalle;
    })
  }

  vm.cancel = cancel;
  vm.submit = submit;
  vm.generar = generar;
  vm.limpiarCabecera = limpiarCabecera;

  function limpiarCabecera() {
    vm.diferenciaCambio = {diferenciaCambioDetalle: []};
  }

  function generar() {
    if(!areDiferenciaCambioRequiredFieldsTruthy()) {
      notify({
            message: $filter("translate")("COMPLETE_DIFERENCIA_CAMBIO_REQUIRED_FIELDS"),
            classes: "alert-danger",
            position: "right"
            });
      return;
    }

    if(!areFechasValid()) {
      notify({
        message: $filter("translate")("RANGO_FECHAS_NO_VALIDO"),
        classes: "alert-danger",
        position: "right"
      });
      return;
    }
    var idTipo = vm.diferenciaCambio.tipo.id;
    var idMoneda = vm.diferenciaCambio.moneda.id;
    var fechaDesde = vm.diferenciaCambio.fechaInicio;
    var fechaHasta = vm.diferenciaCambio.fechaFin;
    var tipoSecundario = undefined;
    if(vm.diferenciaCambio.tipo.codigo === "tipos_diferencia_cambio_venta") {
      tipoSecundario = vm.diferenciaCambio.tipoCliente.id;
    } else if(vm.diferenciaCambio.tipo.codigo === "tipos_diferencia_cambio_compra") {
      tipoSecundario = vm.diferenciaCambio.tipoProveedor.id;
    }
    DiferenciaCambioFactory.generar(idTipo, fechaDesde, fechaHasta, tipoSecundario, idMoneda)
      .$promise
      .then(function(response) {
        if(response.length === 0) {
          notify({
          message: $filter("translate")("DIFERENCIA_CAMBIO_VACIA"),
          classes: "alert-danger",
          position: "right"
        });
        }
        vm.diferenciaCambio.diferenciaCambioDetalle = _.map(response, function(detalle) {
          detalle.fecha = moment(detalle.fecha, 'DD/MM/YYYY').toDate();
          detalle.fechaCobro = moment(detalle.fechaCobro, 'DD/MM/YYYY').toDate();
          return detalle;
        });
        console.log("vm.diferenciaCambio: ", vm.diferenciaCambio);
      })
      .catch(function(err) {
        var errData = err.data;
        if(errData.length > 0) {
          var msg = errData[0].message
          if(msg) {
            notify({
            message: msg,
            classes: "alert-danger",
            position: "right"
            });
            return
          }
        }
        notify({
          message: $filter("translate")("ERROR_GENERAR_DIFERENCIA_CAMBIO"),
          classes: "alert-danger",
          position: "right"
        });
      })
  }

  function submit() {
    vm.submited = true;
    if ($scope.DiferenciaCambioForm.$valid) {
      if(vm.diferenciaCambio.diferenciaCambioDetalle.length==0){
        notify({
          message: $filter("translate")("DETAIL_EMPTY"),
          classes: "alert-danger",
          position: "right"
        });
        return
      }

      formFactory
        .defaultNSFSubmit($scope.DiferenciaCambioForm, DiferenciaCambioFactory, vm.diferenciaCambio)
        .then(
          function(response) {
            redirectToList();
          },
          function(error) {
            console.log(error);
          }
        );
    } else {
      console.log("Form invalid");
      console.log($scope.DiferenciaCambioForm);
      notify({
        message: $filter("translate")("WRONG_FIELDS"),
        classes: "alert-danger",
        position: "right"
      });
    }
  }

  function cancel() {
    redirectToList();
  }

  function redirectToList() {
     $state.go(
        "app.diferenciacambios.list",
        {},
        { reload: true }
    );
  }

  function areDiferenciaCambioRequiredFieldsTruthy() {
    return vm.diferenciaCambio.tipo 
    && vm.diferenciaCambio.moneda 
    && vm.diferenciaCambio.fechaInicio 
    && vm.diferenciaCambio.fechaFin
    && ((vm.diferenciaCambio.tipo.codigo==="tipos_diferencia_cambio_compra" && vm.diferenciaCambio.tipoProveedor)
    || (vm.diferenciaCambio.tipo.codigo==="tipos_diferencia_cambio_venta" && vm.diferenciaCambio.tipoCliente)
    || vm.diferenciaCambio.tipo.codigo==="tipos_diferencia_cambio_anticipo"
    || vm.diferenciaCambio.tipo.codigo==="tipos_diferencia_cambio_retencion");
  }

  function areFechasValid() {
    return vm.diferenciaCambio.fechaInicio <= vm.diferenciaCambio.fechaFin;
  }
}

"use strict";

angular
  .module("qualita.diferenciacambio")
  .controller("RevaluoFormCtrl", RevaluoFormCtrl);

RevaluoFormCtrl.$inject = [
  "$rootScope",
  "$scope",
  "RevaluoFactory",
  "formFactory",
  "TiposFactory",
  "$location",
  "$state",
  "revaluoPrepService",
  "$timeout",
  "notify",
  "ModelTrimmer",
  "filterFactory",
  "DiferenciaCambioLangFactory",
  "WatchLocacionChangeFactory",
  "UtilFactory",
  "ParametrosFactory",
  "cajaFactory",
  "CotizacionMonedaFactory",
  "TiposTipoFactory",
  "$filter",
  "observeOnScope",
  "TipoProveedorFactory",
  "TipoClienteFactory"
];

function RevaluoFormCtrl(
  $rootScope,
  $scope,
  RevaluoFactory,
  formFactory,
  TiposFactory,
  $location,
  $state,
  revaluoPrepService,
  $timeout,
  notify,
  ModelTrimmer,
  filterFactory,
  DiferenciaCambioLangFactory,
  WatchLocacionChangeFactory,
  UtilFactory,
  ParametrosFactory,
  cajaFactory,
  CotizacionMonedaFactory,
  TiposTipoFactory,
  $filter,
  observeOnScope,
  TipoProveedorFactory,
  TipoClienteFactory
) {
  var vm = this;
  $rootScope.isProcessing = false;

  activate();

  function makeAsyncRequests() {
    vm.tiposDiferenciaCambio = TiposTipoFactory.get("tipos_diferencia_cambio");
    vm.tiposProveedor = TipoProveedorFactory.all();
    vm.tiposCliente = TipoClienteFactory.all();
    vm.translationData = {
        moneda: ""
    }
    vm.monedaLocal = CotizacionMonedaFactory.getMonedaLocal();
    vm.monedaLocal.then(function(response) {
      vm.translationData.moneda = response.valorTipo.descripcion;
    });
    TiposFactory.monedas().then(function(data) {
      vm.monedaLocal.then(function(response) {
        var codigoMonedaLocal = response.valorTipo.codigo;
        vm.monedasExtranjeras = _.filter(data.data, function(moneda) {
          return moneda.codigo !== codigoMonedaLocal;
        });
      });
    })
  }

  function activate() {
    WatchLocacionChangeFactory.killAllWatchers();

    makeAsyncRequests();

    vm.search = {};

    DiferenciaCambioLangFactory.getTranslations().then(function(translations) {
      vm.translations = translations;
      vm.curDate = new Date();
    });


    if ($state.is("app.revaluos.new")) {
      activateNew();
    } else if ($state.is("app.revaluos.edit")) {
      activateEdit();
    } else if ($state.is("app.revaluos.view")) {
      activateView();
    }
    $rootScope.isProcessing = false;
    vm.submited = false;
  }

  function activateNew() {
    vm.title = $filter("translate")("NEW_REVALUO");
    vm.revaluo = {};
    vm.revaluo.fecha = new Date();
    vm.cambio = 0;
  }

  function activateEdit() {
    vm.title = $filter("translate")("EDIT_REVALUO");
    vm.revaluo = revaluoPrepService;
    console.log("direvaluo: ", vm.revaluo);
    vm.revaluo.fecha = new Date(vm.revaluo.fecha);
  }

  function activateView() {
    vm.view = true;
    vm.title = $filter("translate")("VIEW_REVALUO");
    vm.revaluo = revaluoPrepService;
  }

  vm.cancel = cancel;
  vm.submit = submit;
  vm.generar = generar;
  vm.limpiarCabecera = limpiarCabecera;
  vm.changeTipo = changeTipo;
  vm.changeMoneda = changeMoneda;

  function areFechasValid() {
    return vm.revaluo.fechaInicio <= vm.revaluo.fechaFin;
  }

  function changeMoneda() {
    var params = {};
      params.search = filterFactory
        .or([
          filterFactory
          .and([{
              path: "monedaDesde.id",
              equals: vm.revaluo.moneda.id
            },
            {
              path: "monedaHasta.id",
              equals: vm.monedaLocal.id
            },
            {
              path: "fecha",
              like: moment(new Date()).format("DD/MM/YYYY")
            }
          ])
          .value(),
        ])
        .value();
        params.view  = "BaseList";
      CotizacionMonedaFactory.all(params).$promise.then(function(response) {
          if (response.length > 0) {
              vm.cambio = response[0].valorVenta;
          } else {
             vm.cambio=1;
          }
      });
  }

  function changeTipo() {
    vm.revaluo.tipoCliente=undefined;
    vm.revaluo.tipoProveedor=undefined;
    vm.revaluo.revaluoDetalle=[];
  }

  function limpiarCabecera() {
    vm.revaluo = {revaluoDetalle:[]};
  }

  function generar() {
    console.log("generar revaluo");
    if(!areDiferenciaCambioRequiredFieldsTruthy()) {
      notify({
            message: $filter("translate")("COMPLETE_DIFERENCIA_CAMBIO_REQUIRED_FIELDS"),
            classes: "alert-danger",
            position: "right"
            });
      return;
    }
    if(!areFechasValid()) {
      notify({
        message: $filter("translate")("RANGO_FECHAS_NO_VALIDO"),
        classes: "alert-danger",
        position: "right"
      });
      return;
    }
    var idTipo = vm.revaluo.tipo.id;
    var idMoneda = vm.revaluo.moneda.id;
    var fechaDesde = vm.revaluo.fechaInicio;
    var fechaHasta = vm.revaluo.fechaFin;
    var tipoSecundario = undefined;
    if(vm.revaluo.tipo.codigo === "tipos_diferencia_cambio_venta") {
      tipoSecundario = vm.revaluo.tipoCliente.id;
    } else if(vm.revaluo.tipo.codigo === "tipos_diferencia_cambio_compra"
                    || vm.revaluo.tipo.codigo==="tipos_diferencia_cambio_anticipo" 
                    || vm.revaluo.tipo.codigo==="tipos_diferencia_cambio_retencion") {
      tipoSecundario = vm.revaluo.tipoProveedor.id;
    }
    vm.revaluo.revaluoDetalle =[];
    RevaluoFactory.generar(idTipo, fechaDesde, fechaHasta, tipoSecundario
      , idMoneda, vm.cambio)
      .$promise
      .then(function(response) {
        vm.revaluo.revaluoDetalle = response.revaluoDetalle;
        //vm.revaluo.revaluoDetalle =response;
        console.log("vm.revaluo: ", vm.revaluo);
      })
      .catch(function(err) {
        var errData = err.data;
        if(errData.length > 0) {
          var msg = errData[0].message
          if(msg) {
            notify({
            message: msg,
            classes: "alert-danger",
            position: "right"
            });
            return
          }
        }
        notify({
          message: $filter("translate")("ERROR_GENERAR_DIFERENCIA_CAMBIO"),
          classes: "alert-danger",
          position: "right"
        });
      })

  }

  function submit() {
    vm.submited = true;
    if ($scope.RevaluoForm.$valid) {
        if(vm.revaluo.revaluoDetalle.length==0){
          notify({
            message: "No se recuperaron entidades para revaluar.",
            classes: "alert-danger",
            position: "right"
          });
          return;
        }
      formFactory
        .defaultNSFSubmit($scope.RevaluoForm, RevaluoFactory, vm.revaluo)
        .then(
          function(response) {
            redirectToList();
          },
          function(error) {
            console.log(error);
          }
        );
    } else {
      console.log("Form invalid");
      console.log($scope.RevaluoForm);
      notify({
        message: $filter("translate")("WRONG_FIELDS"),
        classes: "alert-danger",
        position: "right"
      });
    }
  }

  function cancel() {
    redirectToList();
  }

  function redirectToList() {
     $state.go(
        "app.revaluos.list",
        {},
        { reload: true }
    );
  }

  function areDiferenciaCambioRequiredFieldsTruthy() {
    return vm.revaluo.tipo && vm.revaluo.moneda && vm.revaluo.fechaInicio && vm.revaluo.fechaFin && vm.cambio;
  }
}

'use strict';


angular.module('qualita.diferenciacambio')
  .controller('RevaluoIndexCtrl', RevaluoIndexCtrl);

RevaluoIndexCtrl.$inject = ["AuthorizationService", "$state",
"filterFactory", "ReportTicketFactory", "$window", "DiferenciaCambioLangFactory",
"CsvFactory", "UtilFactory", "baseurl", "$filter", "RevaluoFactory"];

function RevaluoIndexCtrl(AuthorizationService, $state,
  filterFactory, ReportTicketFactory, $window, DiferenciaCambioLangFactory,
  CsvFactory, UtilFactory, baseurl, $filter, RevaluoFactory) {
  var vm = this;

  DiferenciaCambioLangFactory.getTranslations().then(function(translations){
      setUpOptions();
  });

  function setUpOptions() {
    var defaultColumnOrder = ['id','tipo.descripcion','fecha', 'fechaInicio', 'fechaFin'];

    vm.options = {
      'resource': 'revaluos',
      'title': $filter('translate')('REVALUO'),
      'factory': RevaluoFactory,
      'view': 'RevaluoList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('PUNTO_VENTA_DELETE_ERROR'),
      'columns': [
        {data: 'id', 'title': $filter('translate')('ID'), visible: false },
        {data: "tipo.descripcion", title: $filter('translate')('TIPO')},
        {data: "fecha", title: $filter('translate')('DATE'), renderWith: 'dateRender', dateFormat: 'DD/MM/YYYY', class: "dt-center", type: "date-range"},
        {data: "fechaInicio", title: $filter('translate')('FECHA_INICIO'), renderWith: 'dateRender', dateFormat: 'DD/MM/YYYY', class: "dt-center", type: "date-range"},
        {data: "fechaFin", title: $filter('translate')('FECHA_FIN'), renderWith: 'dateRender', dateFormat: 'DD/MM/YYYY', class: "dt-center", type: "date-range"}
        ],
      'hasOptions' : true,
      'hideAddMenu': false,
      'hideEditMenu': false,
      'hideViewMenu': false,
      'hideRemoveMenu': false,
      'defaultOrderColumn' : 0,
      'defaultOrderDir' : "desc",
      'extraMenuOptions':
        [
          {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                  CsvFactory.csv("revaluos", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
          }
        ],
      /*'extraMenuOptions':
        [
            getReporteOption(),
            getCSVOption()
        ],*/
        'extraRowOptions':
        [
          getRowReporteOption(),
          getRowCSVOption()
        ]
    };
  }


  function getRowReporteOption() {
    return {
      templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='" +
          $filter("translate")("VIEW_REPORTE") +
          "' ng-click='viewReporte(<%=dataId%>)' ng-class='{ hidden : !canViewReporte(<%=dataId%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
      functionName: "viewReporte",
      functionDef: function(itemId) {
        var filtersArr = [];
        filtersArr.push("idRevaluo=" + itemId);
        vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
            "revaluo",
            "pdf",
            filtersArr
        );
        $window.open(vm.pdfDownloadURL, "_blank");
        $state.go($state.current, {}, { reload: true });
      },
      conditionName: "canViewReporte",
      conditionDef: function(itemId) {
          return true;
      }
    }
  }

  function getRowCSVOption() {
    return {
      templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='" +
          $filter("translate")("VIEW_CSV") +
          "' ng-click='viewCSV(<%=dataId%>)' ng-class='{ hidden : !canViewCSV(<%=dataId%>)}'> <span class='glyphicon glyphicon-export'></span> </button>",
      functionName: "viewCSV",
      functionDef: function(itemId) {
          RevaluoFactory.getRevaluoCSV(itemId).$promise.then(function(response) {
            var fileName = response.fileName;
            $window.open(baseurl.getPublicBaseUrl() + fileName);
          });
      },
      conditionName: "canViewCSV",
      conditionDef: function(itemId) {
          return true;
      }
    }
  }



  function getReporteOption() {
    return {
        'title': 'Reporte',
        'icon': 'glyphicon glyphicon-file',
        'showCondition': function() { return true; },
        'action': function() {
            var filters = createFilters(vm.options.getFilters());
            ReportTicketFactory.ticket('listadoPuntosVenta', filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder).then(function(genTicket) {
            vm.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
            $window.open(vm.pdfDownloadURL, '_blank');
            });
        }
    }
  }

  function getCSVOption() {
    return {
        'title': 'CSV',
        'icon': 'glyphicon glyphicon-export',
        'showCondition': function() { return true; },
        'action': function() {
            var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

            CsvFactory.csv("puntosventa", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
            .then(function(response) {
            console.log("response de csv: ", response);
            var fileName = response.data.fileName;
            $window.open(baseurl.getPublicBaseUrl() + fileName);
            });
        }
    }

    vm.dateRender = function(data, type, full) {
      if (data) {
        return moment.utc(data).format("DD/MM/YYYY");
      } else {
        return "";
      }
    };

    vm.emptyDataRender = function(data) {
      //console.log(data);
      if (data == undefined) return "";
      else return data;
    };
  }

  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
      filtersArr.push({path: data, like: search})
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.empleados
 * @description
 * # empleados
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('RevaluoFactory', ['$resource', 'baseurl', '$http', function($resource,  baseurl, $http) {

    var revaluo = $resource( baseurl.getBaseUrl() + '/revaluos/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      },
      generarDiferencia: {
        method: 'GET',
        url: baseurl.getBaseUrl() + "/revaluos/generardiferencia",
        isArray:false
      },
      getRevaluoCSV: {
        method: 'POST',
        url: baseurl.getBaseUrl() + "/revaluos/csvdiferencia/:id"
      }
    });

    return {
      all: function(params, view) {
        if (params) {
          params.view = view != undefined || params.view != undefined ? (view || params.view) : 'base';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'base';
        }
        return revaluo.query(params);
      },
      get: function(id,view) {
        console.log(view);
        //if (typeof(view)==='undefined') view = "base";
        return revaluo.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function(attrs) {
        return new revaluo(attrs);
      },
      save: function(Revaluo) {
        return (Revaluo.id) ? Revaluo.$update() : Revaluo.$save();
      },
      remove: function(Revaluo) {
        return Revaluo.$remove();
      },

      generar: function(idTipo, fechaDesde, fechaHasta, idTipoSecundario, idMoneda, cambioMoneda) {
        var stringDesde = moment(fechaDesde).format("DD/MM/YYYY");
        var stringHasta = moment(fechaHasta).format("DD/MM/YYYY");
        var params = {
          tipo: idTipo,
          tipoSecundario: idTipoSecundario,
          moneda: idMoneda,
          fechaDesde: stringDesde,
          fechaHasta: stringHasta,
          view: "RevaluoDetalleList",
          cambio: cambioMoneda
        }
        console.log("params",params);
        return revaluo.generarDiferencia(params);
      },

      getRevaluoCSV: function(id) {
        return revaluo.getRevaluoCSV({id: id});
      },
    };
  }]);
'use strict';

/**
 * @ngdoc service
 * @name qualita.empleados
 * @description
 * # empleados
 * Factory in the qualita.
 */
angular.module('qualita.venta')
  .factory('RevaluoDetalleFactory', ['$resource', 'baseurl', '$http', function($resource,  baseurl, $http) {

    var revaluoDetalle = $resource( baseurl.getBaseUrl() + '/revaluosdetalles/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params, view) {
        if (params) {
          params.view = view!=undefined ? view : 'base';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'base';
        }
        return revaluoDetalle.query(params);
      },
      get: function(id,view) {
        console.log(view);
        //if (typeof(view)==='undefined') view = "base";
        return revaluoDetalle.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function(attrs) {
        return new revaluoDetalle(attrs);
      },
      save: function(RevaluoDetalle) {
        return (RevaluoDetalle.id) ? RevaluoDetalle.$update() : RevaluoDetalle.$save();
      },
      remove: function(Revaluo) {
        return RevaluoDetalle.$remove();
      }
    };
  }]);

angular.module('qualita.activofijo', ['ngResource'])
  .config(
    ['$stateProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$urlMatcherFactoryProvider',
      function ($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider, $urlMatcherFactoryProvider) {

        $stateProvider
          .state('app.coeficientesrevaluo', {
            url: '/coeficientesrevaluo',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Coeficiente Revaluo'
            }
          })
          .state('app.coeficientesrevaluo.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'CoeficienteRevaluoIndexCtrl',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.coeficientesrevaluo',
              label: 'Coeficiente Revaluo',
              description: 'Listado de Coeficiente Revaluo',
              skip: true
            }
          })
          .state('app.coeficientesrevaluo.new', {
            url: '/new',
            templateUrl: 'qualita-components/activofijo/views/coeficienterevaluo/form.html',
            controller: 'CoeficienteRevaluoFormCtrl',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.coeficientesrevaluo',
              label: 'Nuevo Coeficiente Revaluo',
              description: 'Creación de un Coeficiente Revaluo'
            },
            resolve: {
              coeficienteRevaluoPrepService: function () { return; }
            }
          })
          .state('app.coeficientesrevaluo.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/activofijo/views/coeficienterevaluo/form.html',
            controller: 'CoeficienteRevaluoFormCtrl',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.coeficientesrevaluo',
              label: 'Editar Coeficiente Revaluo',
              description: 'Edición de Coeficiente Revaluo #'
            },
            resolve: {
              coeficienteRevaluoPrepService: coeficienteRevaluoPrepService
            }
          })
          .state('app.coeficientesrevaluo.view', {
            url: '/:id',
            templateUrl: 'qualita-components/activofijo/views/coeficienterevaluo/form.html',
            controller: 'CoeficienteRevaluoFormCtrl',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.coeficientesrevaluo',
              label: 'Editar Coeficiente Revaluo',
              description: 'Edición de Coeficiente Revaluo #'
            },
            resolve: {
              coeficienteRevaluoPrepService: coeficienteRevaluoPrepService
            }
          })
          .state('app.activosfijos', {
            url: '/activosfijos',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Composición de Activo Fijo'
            }
          })
          .state('app.activosfijos.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'ActivoFijoIndexCtrl',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.activosfijos',
              label: 'Composición de Activo Fijo',
              description: 'Listado de Composición de Activo Fijo',
              skip: true
            }
          })
          .state('app.activosfijos.new', {
            url: '/new',
            templateUrl: 'qualita-components/activofijo/views/activofijo/form.html',
            controller: 'ActivoFijoFormController',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.activosfijos',
              label: 'Nueva Composición de Activo Fijo',
              description: 'Creación de Composición de Activo Fijo'
            },
            resolve: {
              activoFijoPrepService: function () { return; }
            }
          })
          .state('app.activosfijos.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/activofijo/views/activofijo/form.html',
            controller: 'ActivoFijoFormController',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.activosfijos',
              label: 'Editar Composición de Activo Fijo',
              description: 'Edición de Composición de Activo Fijo #'
            },
            resolve: {
              activoFijoPrepService: activoFijoPrepService
            }
          })
          .state('app.activosfijos.view', {
            url: '/:id',
            templateUrl: 'qualita-components/activofijo/views/activofijo/form.html',
            controller: 'ActivoFijoFormController',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.activosfijos',
              label: 'Ver Composición de Activo Fijo',
              description: 'Ver Composición de Activo Fijo #'
            },
            resolve: {
              activoFijoPrepService: activoFijoPrepService
            }
          })
          .state('app.planillasactivofijo', {
            url: '/planillasactivofijo',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Planillas de Activo Fijo'
            }
          })
          .state('app.planillasactivofijo.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'PlanillaActivoFijoIndexCtrl',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.planillasactivofijo',
              label: 'Planillas de Activo Fijo',
              description: 'Listado de Planillas de Activo Fijo',
              skip: true
            }
          })
          .state('app.planillasactivofijo.new', {
            url: '/new',
            templateUrl: 'qualita-components/activofijo/views/planillasactivofijo/form.html',
            controller: 'PlanillaActivoFijoFormController',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.planillasactivofijo',
              label: 'Nueva Planilla de Activo Fijo',
              description: 'Creación de una planilla de Activo Fijo'
            },
            resolve: {
              planillaActivoFijoPrepService: function () { return; }
            }
          })
          .state('app.planillasactivofijo.edit', {
            url: '/:id/edit',
            templateUrl: 'qualita-components/activofijo/views/planillasactivofijo/form.html',
            controller: 'PlanillaActivoFijoFormController',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.planillasactivofijo',
              label: 'Editar Planilla de Activo Fijo',
              description: 'Edición de Planilla de Activo Fijo #'
            },
            resolve: {
              planillaActivoFijoPrepService: planillaActivoFijoPrepService
            }
          })
          .state('app.planillasactivofijo.view', {
            url: '/:id',
            templateUrl: 'qualita-components/activofijo/views/planillasactivofijo/form.html',
            controller: 'PlanillaActivoFijoFormController',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.planillasactivofijo',
              label: 'Ver Planilla de Activo Fijo',
              description: 'Ver Planilla de Activo Fijo #'
            },
            resolve: {
              planillaActivoFijoPrepService: planillaActivoFijoPrepService
            }
          })
          .state('app.mantenimientoactivofijo', {
            url: '/mantenimientoactivofijo',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
              label: 'Mantenimiento de Activo Fijo'
            }
          })
          .state('app.mantenimientoactivofijo.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'MantenimientoActivoFijoIndexCtrl',
            controllerAs: "vm",
            ncyBreadcrumb: {
              parent: 'app.mantenimientoactivofijo',
              label: 'Mantenimiento de Activo Fijo',
              description: 'Mantenimiento de Activo Fijo',
              skip: true
            }
          })
      }]);

function coeficienteRevaluoPrepService(CoeficienteRevaluoFactory, $stateParams) {
  return CoeficienteRevaluoFactory.get($stateParams.id, 'BaseForm').$promise;
}
coeficienteRevaluoPrepService.$inject = ["CoeficienteRevaluoFactory", "$stateParams"];

function activoFijoPrepService(ActivoFijoFactory, $stateParams) {
  return ActivoFijoFactory.get($stateParams.id, 'ActivoFijoForm').$promise;
}
activoFijoPrepService.$inject = ["ActivoFijoFactory", "$stateParams"];

function planillaActivoFijoPrepService(PlanillaActivoFijoFactory, $stateParams) {
  return PlanillaActivoFijoFactory.get($stateParams.id, 'PlanillaActivoFijoForm').$promise;
}
planillaActivoFijoPrepService.$inject = ["PlanillaActivoFijoFactory", "$stateParams"];

'use strict';

/**
 * @ngdoc service
 * @name qualita.almacen
 * @description
 * # almacen
 * Factory in the qualita.
 */
angular.module('qualita.activofijo')
  .factory('CoeficienteRevaluoFactory', ['$resource', 'baseurl', '$http', '$filter','TiposFactory',
    function($resource, baseurl, $http, $filter,TiposFactory) {

    var CoeficienteRevaluo = $resource( baseurl.getBaseUrl() + '/coeficientesrevaluo/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params, view) {
        if (params) {
          params.view = view != undefined || params.view != undefined ? (view || params.view) : 'base';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'base';
        }
        return CoeficienteRevaluo.query(params);
      },
      get: function(id,view) {
        console.log(view);
        //if (typeof(view)==='undefined') view = "base";
        return CoeficienteRevaluo.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function(attrs) {
        return new CoeficienteRevaluo(attrs);
      },
      save: function(CoeficienteRevaluo) {
        return (CoeficienteRevaluo.id) ? CoeficienteRevaluo.$update() : CoeficienteRevaluo.$save();
      },
      remove: function(CoeficienteRevaluo) {
        return CoeficienteRevaluo.$remove();
      }
    };
  }]);
'use strict';


angular.module('qualita.activofijo')
    .controller('CoeficienteRevaluoIndexCtrl', CoeficienteRevaluoIndexCtrl);

CoeficienteRevaluoIndexCtrl.$inject = ["AuthorizationService", "$state",
    "filterFactory", "ReportTicketFactory", "$window", "ActivoFijoLangFactory",
    "CsvFactory", "UtilFactory", "baseurl", "$filter", "CoeficienteRevaluoFactory"
];

function CoeficienteRevaluoIndexCtrl(AuthorizationService, $state,
    filterFactory, ReportTicketFactory, $window, ActivoFijoLangFactory,
    CsvFactory, UtilFactory, baseurl, $filter, CoeficienteRevaluoFactory) {
    var vm = this;

    ActivoFijoLangFactory.getTranslations().then(function (translations) {
        setUpOptions();
    });

    function setUpOptions() {
        var defaultColumnOrder = ['id', 'anio', 'anual', 'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto',
            'septiembre', 'octubre', 'noviembre', 'diciembre'
        ];

        vm.options = {
            'resource': 'coeficientesrevaluo',
            'title': $filter('translate')('COEFICIENTE_REVALUO'),
            'factory': CoeficienteRevaluoFactory,
            'view': 'BaseList',
            'defaultColumnOrder': defaultColumnOrder,
            'failedDeleteError': $filter('translate')('PUNTO_VENTA_DELETE_ERROR'),
            'columns': [
                { data: 'id', 'title': $filter('translate')('ID'), visible: false},
                { data: 'anio', 'title': $filter('translate')('ANIO') },
                { data: 'anual', 'title': $filter('translate')('ANUAL')},
                { data: 'enero', 'title': $filter('translate')('ENERO') },
                { data: 'febrero', 'title': $filter('translate')('FEBRERO')},
                { data: 'marzo', 'title': $filter('translate')('MARZO')},
                { data: 'abril', 'title': $filter('translate')('ABRIL')},
                { data: 'mayo', 'title': $filter('translate')('MAYO')},
                { data: 'junio', 'title': $filter('translate')('JUNIO')},
                { data: 'julio', 'title': $filter('translate')('JULIO')},
                { data: 'agosto', 'title': $filter('translate')('AGOSTO')},
                { data: 'septiembre', 'title': $filter('translate')('SEPTIEMBRE')},
                { data: 'octubre', 'title': $filter('translate')('OCTUBRE')},
                { data: 'noviembre', 'title': $filter('translate')('NOVIEMBRE')},
                { data: 'diciembre', 'title': $filter('translate')('DICIEMBRE')}
            ],
            'hasOptions': true,
            'hideAddMenu': false,
            'hideEditMenu': false,
            'hideViewMenu': false,
            'hideRemoveMenu': false,
            'defaultOrderColumn': 0,
            'defaultOrderDir': "desc",
            'extraMenuOptions': [
                {
                    'title': 'CSV',
                    'icon': 'glyphicon glyphicon-export',
                    'showCondition': function () { return true; },
                    'action': function () {
                        var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                        CsvFactory.csv("coeficientesrevaluo", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                            .then(function (response) {
                                console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                    }
                }
            ]
        };
    }

    vm.emptyDataRender = function (data) {
        //console.log(data);
        if (data == undefined) return "";
        else return data;
    };


    var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }

}
"use strict";

angular
  .module("qualita.activofijo")
  .controller("CoeficienteRevaluoFormCtrl", CoeficienteRevaluoFormCtrl);

CoeficienteRevaluoFormCtrl.$inject = [
  "$rootScope",
  "$scope",
  "CoeficienteRevaluoFactory",
  "formFactory",
  "TiposFactory",
  "$location",
  "$state",
  "coeficienteRevaluoPrepService",
  "$timeout",
  "notify",
  "ModelTrimmer",
  "filterFactory",
  "ActivoFijoLangFactory",
  "WatchLocacionChangeFactory",
  "UtilFactory",
  "$filter"
];

function CoeficienteRevaluoFormCtrl(
  $rootScope,
  $scope,
  CoeficienteRevaluoFactory,
  formFactory,
  TiposFactory,
  $location,
  $state,
  coeficienteRevaluoPrepService,
  $timeout,
  notify,
  ModelTrimmer,
  filterFactory,
  ActivoFijoLangFactory,
  WatchLocacionChangeFactory,
  UtilFactory,
  $filter
) {
  var vm = this;
  $rootScope.isProcessing = false;

  activate();


  function activate() {

    ActivoFijoLangFactory.getTranslations().then(function(translations) {
      vm.translations = translations;
    });


    if ($state.is("app.coeficientesrevaluo.new")) {
      activateNew();
    } else if ($state.is("app.coeficientesrevaluo.edit")) {
      activateEdit();
    } else if ($state.is("app.coeficientesrevaluo.view")) {
      activateView();
    }
    $rootScope.isProcessing = false;
    vm.submited = false;
  }

  function activateNew() {
    vm.title = $filter("translate")("NEW_COEFICIENTE_REVALUO");
    vm.coeficienteRevaluo = {};
  }

  function activateEdit() {
    vm.title = $filter("translate")("EDIT_COEFICIENTE_REVALUO");
    vm.coeficienteRevaluo = coeficienteRevaluoPrepService;
  }

  function activateView() {
    vm.view = true;
    vm.title = $filter("translate")("VIEW_COEFICIENTE_REVALUO");
    vm.coeficienteRevaluo = coeficienteRevaluoPrepService;
  }

  vm.cancel = cancel;
  vm.submit = submit;
  function submit() {
    vm.submited = true;
    if ($scope.CoeficienteRevaluoForm.$valid) {
      console.log("vm.coeficienteRevaluo",vm.coeficienteRevaluo);
      formFactory
        .defaultNSFSubmit($scope.CoeficienteRevaluoForm, CoeficienteRevaluoFactory, vm.coeficienteRevaluo)
        .then(
          function(response) {
            redirectToList();
          },
          function(error) {
            console.log(error);
          }
        );
    } else {
      console.log("Form invalid");
      console.log($scope.CoeficienteRevaluoForm);
      notify({
        message: $filter("translate")("WRONG_FIELDS"),
        classes: "alert-danger",
        position: "right"
      });
    }
  }

  function cancel() {
    redirectToList();
  }

  function redirectToList() {
     $state.go(
        "app.coeficientesrevaluo.list",
        {},
        { reload: true }
    );
  }

}

"use strict";

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module("qualita.activofijo").factory("ActivoFijoLangFactory", [
    "$resource",
    "baseurl",
    "$http",
    "$translatePartialLoader",
    "$translate",
    function($resource, baseurl, $http, $translatePartialLoader, $translate) {
        var translationsNeeded = [
            "ACTIVO_FIJO",
            "COEFICIENTE_REVALUO",
            "ANIO",
            "ENERO",
            "FEBRERO",
            "MARZO",
            "ABRIL",
            "MAYO",
            "JUNIO",
            "JULIO",
            "AGOSTO",
            "SEPTIEMBRE",
            "OCTUBRE",
            "NOVIEMBRE",
            "DICIEMBRE",
            "NEW_COEFICIENTE_REVALUO",
            "EDIT_COEFICIENTE_REVALUO",
            "VIEW_COEFICIENTE_REVALUO",
            "ACTIVO_FIJO_TITLE",
            "PRODUCTO",
            "COSTO",
            "NEW_COMPOSICION_ACTIVO_FIJO",
            "EDIT_COMPOSICION_ACTIVO_FIJO",
            "VIEW_COMPOSICION_ACTIVO_FIJO",
            "UBICACION",
            "PROYECTO",
            "SERVICIO",
            "COMPROBANTES",
            "NUMERO_FACTURA",
            "FECHA_ADQUISICION",
            "ACTIVO_FIJO_DELETE_ERROR",
            "PLANILLA_ACTIVO_FIJO_TITLE",
            "PLANILLA_ACTIVO_FIJO_DELETE_ERROR",
            "CALCULO_DEPRECIACION_REVALUO_VENTA_ACTIVOS",
            "VALOR_COSTO",
            "YEAR_ADQUISICION",
            "YEARS_VIDA_UTIL_FISCAL",
            "YEARS_VIDA_UTIL_FISCAL_RESTANTE",
            "VALOR_FISCAL_REVALUADO_EJERCICIO_ANTERIOR",
            "VALOR_FISCAL_NETO_EJERCICIO_ANTERIOR",
            "VALOR_REVALUADO_FISCAL",
            "DIFERENCIA_REVALUO_CONTABILIZAR",
            "CUOTA_FISCAL_DEPRECIACION_ANUAL",
            "CUOTA_DEPRECIACION_ANUAL_NO_DEDUCIBLE",
            "DEPRECIACION_ACUMULADA_ANTERIOR",
            "DEPRECIACION_FISCAL_ACUMULADA",
            "VALOR_FISCAL_NETO_CIERRE",
            "FECHA_CREACION",
            "COMPLETE_PLANILLA_ACTIVO_FIJO_REQUIRED_FIELDS",
            "PLANILLA_ACTIVO_FIJO_VACIA",
            "ERROR_GENERAR_PLANILLA_ACTIVO_FIJO",
            "CONFIRMAR_PLANILLA_ACTIVO_FIJO",
            "CONFIRMAR_PLANILLA_ACTIVO_FIJO_Q",
            "ANULAR_PLANILLA_ACTIVO_FIJO",
            "ANULAR_PLANILLA_ACTIVO_FIJO_Q",
            "ANUAL",
            "PRESENTAR",
            "ANULAR",
            "VIEW_CSV",
            "PROVEEDOR_REQUIRED",
            "COMPROBANTE_REQUIRED"
        ];

        return {
            getTranslations: function() {
                $translatePartialLoader.addPart("activofijo");
                return $translate.refresh().then(function() {
                    return $translate(translationsNeeded);
                });
            }
        }
    }
]);
'use strict';

/**
 * @ngdoc service
 * @name qualita.almacen
 * @description
 * # almacen
 * Factory in the qualita.
 */
angular.module('qualita.activofijo')
  .factory('ActivoFijoFactory', ['$resource', 'baseurl', '$http', '$filter','TiposFactory',
    function($resource, baseurl, $http, $filter,TiposFactory) {

    var ActivoFijo = $resource( baseurl.getBaseUrl() + '/activosfijos/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params, view) {
        if (params) {
          params.view = view != undefined || params.view != undefined ? (view || params.view) : 'base';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'base';
        }
        return ActivoFijo.query(params);
      },
      get: function(id,view) {
        console.log(view);
        //if (typeof(view)==='undefined') view = "base";
        return ActivoFijo.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function(attrs) {
        return new ActivoFijo(attrs);
      },
      save: function(activoFijo) {
        return (activoFijo.id) ? activoFijo.$update() : activoFijo.$save();
      },
      remove: function(activoFijo) {
        return activoFijo.$remove();
      }
    };
  }]);
'use strict';


angular.module('qualita.activofijo')
  .controller('ActivoFijoIndexCtrl', ActivoFijoIndexCtrl);

ActivoFijoIndexCtrl.$inject = ["AuthorizationService", "$state",
"filterFactory", "ReportTicketFactory", "$window", "ActivoFijoLangFactory",
"CsvFactory", "UtilFactory", "baseurl", "$filter", "ActivoFijoFactory"];

function ActivoFijoIndexCtrl(AuthorizationService, $state,
  filterFactory, ReportTicketFactory, $window, ActivoFijoLangFactory,
  CsvFactory, UtilFactory, baseurl, $filter, ActivoFijoFactory) {
  var vm = this;

  ActivoFijoLangFactory.getTranslations().then(function(translations){
      setUpOptions();
  });

  function setUpOptions() {
    var defaultColumnOrder = ['id',
    'fechaAdquisicion',
    'producto.nombre',
    'costoActivo'
    ];

    vm.options = {
      'resource': 'activosfijos',
      'title': $filter('translate')('ACTIVO_FIJO_TITLE'),
      'factory': ActivoFijoFactory,
      'view': 'ActivoFijoList',
      'defaultColumnOrder': defaultColumnOrder,
      'failedDeleteError': $filter('translate')('ACTIVO_FIJO_DELETE_ERROR'),
      'columns': [
        {data: 'id', 'title': $filter('translate')('ID'), visible: false},
        {data: "fechaAdquisicion", title: $filter('translate')('DATE'), renderWith: 'dateRender', dateFormat: 'DD/MM/YYYY', class: "dt-center", type: "date-range"},
        {data: "producto.nombre", title: $filter('translate')('PRODUCTO'), renderWith: 'emptyRender', class: "dt-center"},
        {data: "costoActivo", title: $filter('translate')('COSTO'), renderWith: monedaRender, class: "numer-range"}
        ],
      'hasOptions' : true,
      'hideAddMenu': false,
      'hideEditMenu': false,
      'hideViewMenu': false,
      'hideRemoveMenu': false,
      'defaultOrderColumn' : 0,
      'defaultOrderDir' : "desc",
      'extraMenuOptions':
        [
            // getReporteOption(),
            // getCSVOption()

            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                  CsvFactory.csv("activosfijos", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }

        ],
        'extraRowOptions':
        [
        ]
    };
  }

  //Para que haga el filtro de fechas y los campos booleanos
  var createFilters = function (filters) {
    var filtersArr = [];
    _.each(filters, function (search, data) {
        search = search.split("~");
        if (search.length > 1) {
            /* El parámetro es un rago */

            var isDate = search[0].split("/");

            if (isDate.length > 1) {
                /* Es un rago de fechas */

                filtersArr.push({path: data, equalOrAfter: search[0]})
                filtersArr.push({path: data, equalOrBefore: search[1]})
            } else {
                /* El un rago numérco */

                filtersArr.push({path: data, greaterOrEqual: search[0]})
                filtersArr.push({path: data, lessOrEqual: search[1]})
            }
        } else {
            /* El parametro no es un rago */
            filtersArr.push({path: data, like: search[0]})
        }
    });
    var filters = filterFactory.and(filtersArr).value();
    return filters;
  }

  var monedaRender = function(data, type, row) {
    var campoMoneda = null;
    // obtener moneda local
    if (row.moneda != undefined) {
        campoMoneda = row.moneda;
    }
    // por ahora hardcodeamos guaranies
    campoMoneda = {codigo: "guaranies"};

    if (data != undefined) {
        var moneda = "Gs. ";
        if (campoMoneda.codigo === "dolares") {
            moneda = "Usd. ";
            data = parseFloat(data).toFixed(2);
        } else if (campoMoneda.codigo === "pesos") {
            moneda = "Pes. ";
            data = parseFloat(data).toFixed(2);
        } else if (campoMoneda.codigo === "real") {
            moneda = "Rel. ";
            data = parseFloat(data).toFixed(2);
        } else if (campoMoneda.codigo === "euro") {
            moneda = "Eur. ";
            data = parseFloat(data).toFixed(2);
        } else {
            data = parseFloat(data).toFixed(0);
        }
        // TODO hacer esto mismo en todos los renders de moneda
        return (
            moneda +
            data
            .toString()
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        );
    } else {
        return "";
    }
};
}

"use strict";

angular
    .module("qualita.activofijo")
    .controller("ActivoFijoFormController", ActivoFijoFormController);

ActivoFijoFormController.$inject = [
    "$rootScope",
    "$scope",
    "ActivoFijoFactory",
    "formFactory",
    "TiposFactory",
    "$location",
    "$state",
    "activoFijoPrepService",
    "$timeout",
    "notify",
    "ModelTrimmer",
    "filterFactory",
    "ActivoFijoLangFactory",
    "WatchLocacionChangeFactory",
    "UtilFactory",
    "ParametrosFactory",
    "cajaFactory",
    "CotizacionMonedaFactory",
    "TiposTipoFactory",
    "$filter",
    "observeOnScope",
    "TipoProveedorFactory",
    "TipoClienteFactory",
    "ComprobanteFactory",
    "ProductosFactory",
    "AlmacenFactory",
    "ProyectoFactory",
    "ProveedoresFactory",
    "ServicioFactory"
];

function ActivoFijoFormController(
    $rootScope,
    $scope,
    ActivoFijoFactory,
    formFactory,
    TiposFactory,
    $location,
    $state,
    activoFijoPrepService,
    $timeout,
    notify,
    ModelTrimmer,
    filterFactory,
    ActivoFijoLangFactory,
    WatchLocacionChangeFactory,
    UtilFactory,
    ParametrosFactory,
    cajaFactory,
    CotizacionMonedaFactory,
    TiposTipoFactory,
    $filter,
    observeOnScope,
    TipoProveedorFactory,
    TipoClienteFactory,
    ComprobanteFactory,
    ProductosFactory,
    AlmacenFactory,
    ProyectoFactory,
    ProveedoresFactory,
    ServicioFactory
) {
    var vm = this;

    vm.buscarComprobantes = buscarComprobantes;
    vm.clearFiltrosActivoFijo = clearFiltrosActivoFijo;
    vm.cancel = cancel;
    vm.submit = submit;
    vm.limpiarCabecera = limpiarCabecera;
    vm.deleteDetalle = deleteDetalle;

    $rootScope.isProcessing = false;

    activate();

    function makeAsyncRequests() {
        vm.tiposProveedor = TipoProveedorFactory.all();
        vm.tiposCliente = TipoClienteFactory.all();
        vm.translationData = {
            moneda: ""
        }
        vm.productos = getProductosActivoFijo();
        vm.almacenes = getAlmacenesActivoFijo();

        var staticFilter = {};
        staticFilter.view = "ActivoFijoForm";
        vm.proyectos = ProyectoFactory.all(staticFilter);

        //vm.proveedores = getProveedores();
        vm.staticFilterProv = [{path: "activo",equals: true}];
        vm.proveedoresFactory = ProveedoresFactory;

        //
        var staticFilter2 = {};
        staticFilter2.view = "ActivoFijoForm";
        vm.servicios = ServicioFactory.all(staticFilter2);


        vm.monedaLocal = CotizacionMonedaFactory.getMonedaLocal();
        vm.monedaLocal.then(function(response) {
            vm.translationData.moneda = response.valorTipo.descripcion;
        });
        TiposFactory.monedas().then(function(data) {
            vm.monedaLocal.then(function(response) {
                var codigoMonedaLocal = response.valorTipo.codigo;
                vm.monedasExtranjeras = _.filter(data.data, function(moneda) {
                    return moneda.codigo !== codigoMonedaLocal;
                });
            });
        })
    }

    function activate() {
        vm.mostrarAdicionales = false;
        WatchLocacionChangeFactory.killAllWatchers();

        makeAsyncRequests();

        // Se define el datatables
        vm.optionsDtComprobantes = getDatatablesComprobantes();

        vm.factoryComprobantes = ComprobanteFactory;

        var obs1 = observeOnScope($scope, "vm.comprobantesFiltrados")
            .filter(function(data) {
                return data.newValue;
            })
            .map(function(data) {
                return data.newValue;
            });

        // Cuando se selecciona el boton de check en el modal
        obs1.subscribe(function(change) {

            if (!vm.activoFijo.proveedor) {
                notify({
                    message: $filter("translate")("PROVEEDOR_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            } else if (change.length <= 0) {
                notify({
                    message: $filter("translate")("COMPROBANTE_REQUIRED"),
                    classes: "alert-danger",
                    position: "right"
                });
            }else {
                vm.activoFijo.comprobantes = _.reduce(
                    change,
                    function(response, val) {
                        response.push(val);
                        return response;
                    }, vm.activoFijo.comprobantes || []
                );
            }
        });

        vm.search = {};

        ActivoFijoLangFactory.getTranslations().then(function(translations) {
            vm.translations = translations;
            vm.curDate = new Date();
            if ($state.is("app.activosfijos.new")) {
                activateNew();
            } else if ($state.is("app.activosfijos.edit")) {
                activateEdit();
            } else if ($state.is("app.activosfijos.view")) {
                activateView();
            }
            $rootScope.isProcessing = false;
            vm.submited = false;
        });
    }

    function activateNew() {
        vm.title = $filter("translate")("NEW_COMPOSICION_ACTIVO_FIJO");
        vm.activoFijo = {};
        vm.activoFijo.fechaAdquisicion = new Date();
    }

    function activateEdit() {
        vm.title = $filter("translate")("EDIT_COMPOSICION_ACTIVO_FIJO");
        getPrepService();
        vm.activoFijo.fechaAdquisicion = new Date(vm.activoFijo.fechaAdquisicion);
        getCurrentComprobantes();
        formSelectionsArray();
        vm.isEdit = true;
    }

    function activateView() {
        vm.view = true;
        vm.title = $filter("translate")("VIEW_COMPOSICION_ACTIVO_FIJO");
        getPrepService();
        getCurrentComprobantes();
        formSelectionsArray();
    }

    function getPrepService() {
        vm.activoFijo = activoFijoPrepService;
    }

    function getCurrentComprobantes() {
        var filtersArray = _.map(vm.activoFijo.comprobantes, function(detalle) {
            return {
                path: 'id',
                equals: detalle
            };
        });

        var filters = {
            search: filterFactory.or(filtersArray).value()
        };

        vm.optionsDtComprobantes.staticFilter = filters;
        vm.showDtComprobantes = true;
    }

    function limpiarCabecera() {
        vm.activoFijo = { comprobantes: [] };
    }

    function deleteDetalle(detalle) {
        _.remove(vm.activoFijo.comprobantes, function(item) {
            return detalle.id == item.id;
        });
    }

    function submit() {
        vm.submited = true;
        if ($scope.ActivoFijoForm.$valid) {
            var trimmedModel = ModelTrimmer.trimDetails(vm.activoFijo);
            if (vm.activoFijo.comprobantes && vm.activoFijo.comprobantes.length==0) {
                notify({ message: $filter('translate')('COMPROBANTES_NEEDED'), classes: 'alert-danger', position: 'right' });
                return;
            }

            formFactory
                .defaultNSFSubmit($scope.ActivoFijoForm, ActivoFijoFactory, trimmedModel, errorHandler)
                .then(
                    function(response) {
                        redirectToList();
                    },
                    function(error) {
                        console.log(error);
                    }
                );
        } else {
            notify({
                message: $filter("translate")("WRONG_FIELDS"),
                classes: "alert-danger",
                position: "right"
            });
        }
    }

    function cancel() {
        redirectToList();
    }

    function errorHandler(e) {
        var msg = '';
        _.forEach(e.data, function(error) {
            if (error.constraint == 'nombre') {
                msg += '\n\n' + $filter('translate')('SAME_NAME_PRODUCT_CLASS') + "\n"
            } else {
                msg += '\n\n' + error.message + '.'
            }
        });
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
    }

    function redirectToList() {
        $state.go(
            "app.activosfijos.list", {}, { reload: true }
        );
    }

    var formSelectionsArray = function() {
        $scope.$watch('vm.optionsDtComprobantes.selection', function(newValue, oldValue) {
            if (!vm.optionsDtComprobantes.selection || vm.selectedChecked) {
                return;
            }
            vm.selectedChecked = true;
            _.forEach(vm.activoFijo.comprobantes, function(detalle) {
                vm.optionsDtComprobantes.selection[detalle] = true;
            });
            console.log(vm.optionsDtComprobantes.selection);
        });
    };

    function buscarComprobantes() {

        // TODO: ver qué otras precondiciones controlar
        if (vm.activoFijo.fechaDesde && vm.activoFijo.fechaDesde.getTime() >
            vm.activoFijo.fechaHasta && vm.activoFijo.fechaHasta.getTime()) {
            notify({ message: $filter('translate')('DATE_FROM_DATE_TO_ERROR'), classes: 'alert-danger', position: 'right' });
            return;
        }

        var filtersArray = [{
            path: 'estado.codigo',
            equals: 'comp_aprobado'
        }];

        // Filtro para excluir comprobantes ya seleccionados
        if(vm.optionsDtComprobantes.staticFilter != undefined){
            var filtrosRemover = [];
            _.forEach(vm.optionsDtComprobantes.staticFilter.search.filters, function(filtro) {
                if (filtro.path === "id") {
                    filtrosRemover.push(filtro);
                }
            });
            _.forEach(filtrosRemover, function(filtro) {
                _.remove(vm.optionsDtComprobantes.staticFilter.search.filters, function(filtro2) {
                    return filtro2 === filtro;
                });
            });

            _.forEach(vm.activoFijo.comprobantes, function(detalle) {
                var newFilter
                detalle.cuota ? newFilter = { path: "id", notEquals: detalle.cuota.id } : newFilter = { path: "id", notEquals: detalle.id }
                filtersArray.push(newFilter);
            });
        }


        if (vm.activoFijo.proveedor) {
            filtersArray.push({
                path: 'proveedor.id',
                equals: vm.activoFijo.proveedor.id
            });
        }

        if (vm.activoFijo.servicio) {
            filtersArray.push({
                path: 'comprobanteDetalle.servicio.id',
                equals: vm.activoFijo.servicio.id
            });
        }

        if (vm.activoFijo.fechaDesde) {
            filtersArray.push({
                path: 'fecha',
                equalOrAfter: moment(vm.activoFijo.fechaDesde).format('DD/MM/YYYY')
            });
        }

        if (vm.activoFijo.fechaHasta) {
            filtersArray.push({
                path: 'fecha',
                equalOrBefore: moment(vm.activoFijo.fechaHasta).format('DD/MM/YYYY')
            });
        }

        if (vm.activoFijo.proyecto) {
            filtersArray.push({
                path: 'proyecto.id',
                equals: vm.activoFijo.proveedor.id
            });
        }


        var filters = { search: filterFactory.and(filtersArray).value() };

        vm.optionsDtComprobantes.staticFilter = filters;
        vm.showDtComprobantes = true;
    }

    function clearFiltrosActivoFijo() {
        delete vm.activoFijo.proveedor;
        delete vm.activoFijo.servicio;
        delete vm.activoFijo.fechaDesde;
        delete vm.activoFijo.fechaHasta;
    }

    function getProductosActivoFijo() {

        var filters = {
            search: filterFactory.and([{
                path: "claseProducto.tipoProducto.nombre",
                equals: "Activo Fijo"
            }]).value()
        }
        return ProductosFactory.all(filters, "ActivoFijoForm");
    }

    function getAlmacenesActivoFijo() {
        var filters = {
            search: filterFactory.and([{
                path: "clase.codigo",
                equals: "activo_fijo"
            }]).value()
        }
        return AlmacenFactory.all(filters);
    }

    function getProveedores() {
        var filters = {
            search: filterFactory.and([{
                path: "activo",
                equals: true
            }]).value()
        }
        return ProveedoresFactory.all(filters);
    }

    function areActivoFijoRequiredFieldsTruthy() {
        return true;
    }

    /* Define el datatables para busqueda de comprobantes */
    function getDatatablesComprobantes() {
        var monedaRender = function(data, type, row) {

            var campoMoneda = row.moneda1;
            if (data != undefined) {
                var moneda = "Gs. ";
                if (campoMoneda.codigo === "dolares") {
                    moneda = "Usd. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "pesos") {
                    moneda = "Pes. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "real") {
                    moneda = "Rel. ";
                    data = parseFloat(data).toFixed(2);
                } else if (campoMoneda.codigo === "euro") {
                    moneda = "Eur. ";
                    data = parseFloat(data).toFixed(2);
                } else {
                    data = parseFloat(data).toFixed(0);
                }
                return (
                    moneda +
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            } else {
                return "";
            }
        };

        var defaultColumnOrder = ['id', 'numero', 'fecha', 'proveedor.nombre', 'montoTotal', 'saldo'];

        return {
            'resource': 'comprobante',
            'title': 'Comprobantes',
            'view': "ActivoFijoFormList",
            'factory': ComprobanteFactory,
            'defaultColumnOrder': defaultColumnOrder,
            'colReorder': true,
            'columns': [
                { 'data': 'id', 'title': $filter('translate')('ID'), visible: false},
                { 'data': 'numero', 'title': $filter('translate')('NUMERO_FACTURA'), 'class': 'dt-center' },
                { 'data': 'fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'type': 'date-range', 'class': 'dt-center' },
                { 'data': 'proveedor.nombre', 'title': $filter('translate')('PROVEEDOR'), 'class': 'dt-center' },
                { 'data': 'montoTotal', 'title': $filter('translate')('TOTAL_AMOUNT'), 'class': 'dt-center', 'renderWith': monedaRender },
                { 'data': 'saldo', 'title': $filter('translate')('SALDO'), 'class': 'dt-center', 'renderWith': monedaRender }
            ],
            'isSelectable': true,
            'selection': {},
            'hasOptions': false,
            'defaultOrderColumn': 3,
            'defaultOrderDir': "desc",
            'hideAddMenu': true,
            'filters': {}
        };
    }
}
'use strict';

/**
 * @ngdoc service
 * @name qualita.almacen
 * @description
 * # almacen
 * Factory in the qualita.
 */
angular.module('qualita.activofijo')
  .factory('PlanillaActivoFijoFactory', ['$resource', 'baseurl', '$http', '$filter','TiposFactory',
    function($resource, baseurl, $http, $filter,TiposFactory) {

    var PlanillaActivoFijo = $resource( baseurl.getBaseUrl() + '/planillasactivofijo/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      },
      generarPlanilla: {
        method: 'GET',
        url: baseurl.getBaseUrl() + "/planillasactivofijo/generarplanilla",
        isArray: true
      },
      presentar: {
        method: "PUT",
        url: baseurl.getBaseUrl() + "/planillasactivofijo/presentar/:id"
    },
      anular: {
          method: "PUT",
          url: baseurl.getBaseUrl() + "/planillasactivofijo/anular/:id"
      },
      getPlanillaCSV: {
        method: 'POST',
        url: baseurl.getBaseUrl() + "/planillasactivofijo/csvplanilla/:id"
      }
    });

    return {
      all: function(params, view) {
        if (params) {
          params.view = view != undefined || params.view != undefined ? (view || params.view) : 'base';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'base';
        }
        return PlanillaActivoFijo.query(params);
      },
      get: function(id,view) {
        console.log(view);
        //if (typeof(view)==='undefined') view = "base";
        return PlanillaActivoFijo.get({id: id, view: view!=undefined ? view : 'base'});
      },
      create: function(attrs) {
        return new PlanillaActivoFijo(attrs);
      },
      save: function(planillaActivoFijo) {
        return (planillaActivoFijo.id) ? planillaActivoFijo.$update() : planillaActivoFijo.$save();
      },
      remove: function(planillaActivoFijo) {
        return planillaActivoFijo.$remove();
      },
      generar: function(idTipo, year, month) {
        var params = {
          tipo: idTipo,
          year: year,
          month: month
        }
        return PlanillaActivoFijo.generarPlanilla(params);
      },
      presentar: function(id) {
        return PlanillaActivoFijo.presentar({ id: id }).$promise;
      },
      anular: function(id) {
        return PlanillaActivoFijo.anular({ id: id }).$promise;
      },
      getPlanillaCSV: function(id) {
        return PlanillaActivoFijo.getPlanillaCSV({id: id});
      }
    };
  }]);
'use strict';


angular.module('qualita.activofijo')
    .controller('PlanillaActivoFijoIndexCtrl', PlanillaActivoFijoIndexCtrl);

PlanillaActivoFijoIndexCtrl.$inject = ["AuthorizationService", "$state",
    "filterFactory", "ReportTicketFactory", "$window", "ActivoFijoLangFactory",
    "CsvFactory", "UtilFactory", "baseurl", "$filter", "PlanillaActivoFijoFactory",
    "$modal", "$scope"
];

function PlanillaActivoFijoIndexCtrl(AuthorizationService, $state,
    filterFactory, ReportTicketFactory, $window, ActivoFijoLangFactory,
    CsvFactory, UtilFactory, baseurl, $filter, PlanillaActivoFijoFactory,
    $modal, $scope) {
    var vm = this;

    ActivoFijoLangFactory.getTranslations().then(function(translations) {
        $scope.translations = translations;
        setUpOptions();
    });

    function setUpOptions() {
        var defaultColumnOrder = ['id',
            'tipo.descripcion',
            'estado.descripcion',
            'anho'
        ];

        vm.options = {
            'resource': 'planillasactivofijo',
            'view': 'PlanillaActivoFijoList',
            'title': $filter('translate')('PLANILLA_ACTIVO_FIJO_TITLE'),
            'factory': PlanillaActivoFijoFactory,
            'defaultColumnOrder': defaultColumnOrder,
            'failedDeleteError': $filter('translate')('PLANILLA_ACTIVO_FIJO_DELETE_ERROR'),
            'columns': [
                { data: 'id', 'title': $filter('translate')('ID'), visible: false },
                { data: "tipo.descripcion", title: $filter('translate')('TYPE'), renderWith: 'emptyRender', class: "dt-center" },
                { data: "estado.descripcion", title: $filter('translate')('STATUS'), renderWith: 'emptyRender', class: "dt-center" },
                { data: "anho", title: $filter('translate')('YEAR'), renderWith: 'emptyRender', class: "dt-center" }
            ],
            'hasOptions': true,
            'hideAddMenu': false,
            'hideEditMenu': false,
            'hideViewMenu': false,
            'hideRemoveMenu': false,
            'defaultOrderColumn': 0,
            'defaultOrderDir': "desc",
            'extraEditConditions': extraEditConditions,
            'extraRemoveConditions': extraRemoveConditions,
            'extraMenuOptions': [{
                    'title': 'CSV',
                    'icon': 'glyphicon glyphicon-export',
                    'showCondition': function() { return true; },
                    'action': function() {
                        var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                        CsvFactory.csv("planillasactivofijo", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                            .then(function(response) {
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                    }
                }

            ],
            'extraRowOptions': [
                getPresentarOption(),
                getAnularOption(),
                getRowCSVOption()
            ]
        };
    }

    function getPresentarOption() {
        return {
            templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='" +
                $filter("translate")("PRESENTAR") +
                "' ng-click='presentar(<%=dataId%>)' ng-class='{ hidden : !canPresentar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-file'></span> </button>",
            functionName: "presentar",
            functionDef: function(itemId) {
                $scope.mensajeModal = $scope.translations.CONFIRMAR_PLANILLA_ACTIVO_FIJO_Q;
                $scope.tituloModal = $scope.translations.CONFIRMAR_PLANILLA_ACTIVO_FIJO;
                var modalInstance = $modal.open({
                    templateUrl: "views/directives/confirmation-modal.html",
                    scope: $scope
                });

                $scope.cancel = function() {
                    modalInstance.dismiss("cancel");
                };

                $scope.ok = function() {
                    PlanillaActivoFijoFactory.presentar(itemId).then(function() {
                        $state.go($state.current, {}, { reload: true });
                    });
                }
            },
            conditionName: "canPresentar",
            customAttribute: "estado",
            conditionDef: function(estado) {
                return AuthorizationService.hasPermission("update_planillasactivofijo") &&
                    estado.codigo === 'estado_planilla_activo_fijo_generado';
            }
        }
    }

    function getAnularOption() {
        return {
            templateToRender: "<button class='btn btn-danger' style='margin-right: 5px;' title='" +
                $filter("translate")("ANULAR") +
                "' ng-click='anular(<%=dataId%>)' ng-class='{ hidden : !canAnular(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
            functionName: "anular",
            functionDef: function(itemId) {
                $scope.mensajeModal = $scope.translations.ANULAR_PLANILLA_ACTIVO_FIJO_Q;
                $scope.tituloModal = $scope.translations.ANULAR_PLANILLA_ACTIVO_FIJO;
                var modalInstance = $modal.open({
                    templateUrl: "views/directives/confirmation-modal.html",
                    scope: $scope
                });

                $scope.cancel = function() {
                    modalInstance.dismiss("cancel");
                };

                $scope.ok = function() {
                    PlanillaActivoFijoFactory.anular(itemId).then(function() {
                        $state.go($state.current, {}, { reload: true });
                    });
                }
            },
            conditionName: "canAnular",
            customAttribute: "estado",
            conditionDef: function(estado) {
                return AuthorizationService.hasPermission("update_planillasactivofijo") &&
                    estado.codigo === 'estado_planilla_activo_fijo_presentado';
            }
        }
    }

    function getRowCSVOption() {
        return {
            templateToRender: "<button class='btn btn-warning' style='margin-right: 5px;' title='" +
                $filter("translate")("VIEW_CSV") +
                "' ng-click='viewCSV(<%=dataId%>)' ng-class='{ hidden : !canViewCSV(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-export'></span> </button>",
            functionName: "viewCSV",
            functionDef: function(itemId) {
                PlanillaActivoFijoFactory.getPlanillaCSV(itemId).$promise.then(function(response) {
                    var fileName = response.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
            },
            conditionName: "canViewCSV",
            customAttribute: "estado",
            conditionDef: function(estado) {
                return estado.codigo === 'estado_planilla_activo_fijo_presentado';
            }
        }
    }

    function extraEditConditions(item) {
        return item.estado.codigo === 'estado_planilla_activo_fijo_generado';
    }

    function extraRemoveConditions(item) {
        return item.estado.codigo === 'estado_planilla_activo_fijo_generado';
    }

    var createFilters = function(filters) {
        var filtersArr = [];
        _.each(filters, function(search, data) {
            filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }
}

"use strict";

angular
  .module("qualita.activofijo")
  .controller("PlanillaActivoFijoFormController", PlanillaActivoFijoFormController);

PlanillaActivoFijoFormController.$inject = [
  "$rootScope",
  "$scope",
  "PlanillaActivoFijoFactory",
  "formFactory",
  "TiposFactory",
  "$location",
  "$state",
  "planillaActivoFijoPrepService",
  "$timeout",
  "notify",
  "ModelTrimmer",
  "filterFactory",
  "ActivoFijoLangFactory",
  "WatchLocacionChangeFactory",
  "UtilFactory",
  "ParametrosFactory",
  "TiposTipoFactory",
  "$filter",
  "ComprobanteFactory",
  "LineasProductoFactory",
  "$q"
];

function PlanillaActivoFijoFormController(
  $rootScope,
  $scope,
  PlanillaActivoFijoFactory,
  formFactory,
  TiposFactory,
  $location,
  $state,
  planillaActivoFijoPrepService,
  $timeout,
  notify,
  ModelTrimmer,
  filterFactory,
  ActivoFijoLangFactory,
  WatchLocacionChangeFactory,
  UtilFactory,
  ParametrosFactory,
  TiposTipoFactory,
  $filter,
  ComprobanteFactory,
  LineasProductoFactory,
  $q
) {
  var vm = this;

  vm.cancel = cancel;
  vm.submit = submit;
  vm.limpiarCabecera = limpiarCabecera;
  vm.generarPlanilla = generarPlanilla;
  vm.changeTypeFilter = changeTypeFilter;

  $rootScope.isProcessing = false;

  activate();

  function makeAsyncRequests() {
    vm.tipos = TiposTipoFactory.get("tipo_planilla_activo_fijo");
  }

  function activate() {
    WatchLocacionChangeFactory.killAllWatchers();

    makeAsyncRequests();

    vm.meses = [
      {
        id: 1,
        nombre: "Enero"
      },
      {
        id: 2,
        nombre: "Febrero"
      },
      {
        id: 3,
        nombre: "Marzo"
      },
      {
        id: 4,
        nombre: "Abril"
      },
      {
        id: 5,
        nombre: "Mayo"
      },
      {
        id: 6,
        nombre: "Junio"
      },
      {
        id: 7,
        nombre: "Julio"
      },
      {
        id: 8,
        nombre: "Agosto"
      },
      {
        id: 9,
        nombre: "Septiembre"
      },
      {
        id: 10,
        nombre: "Octubre"
      },
      {
        id: 11,
        nombre: "Noviembre"
      },
      {
        id: 12,
        nombre: "Dieicembre"
      }
    ];
    vm.search = {};

    ActivoFijoLangFactory.getTranslations().then(function(translations) {
      vm.translations = translations;
      vm.curDate = new Date();
      if ($state.is("app.planillasactivofijo.new")) {
        activateNew();
      } else if ($state.is("app.planillasactivofijo.edit")) {
        activateEdit();
      } else if ($state.is("app.planillasactivofijo.view")) {
        activateView();
      }
      $rootScope.isProcessing = false;
      vm.submited = false;
    });
  }

  function activateNew() {
    vm.title = $filter("translate")("NEW_PLANILLA_ACTIVO_FIJO");
    vm.planillaActivoFijo = {};
    vm.planillaActivoFijo.fechaCreacion = new Date();
  }

  function activateEdit() {
    vm.title = $filter("translate")("EDIT_PLANILLA_ACTIVO_FIJO");
    getPrepService();
    vm.planillaActivoFijo.mes = _.find(vm.meses, function(mes) { return mes.id === vm.planillaActivoFijo.mes });
    vm.planillaActivoFijo.fechaCreacion = new Date(vm.planillaActivoFijo.fechaCreacion);
    generateGroupsActivos(vm.planillaActivoFijo.planillaActivoFijoDetalles);
    vm.isEdit = true;
  }

  function activateView() {
    vm.view = true;
    vm.title = $filter("translate")("VIEW_PLANILLA_ACTIVO_FIJO");
    getPrepService();
    vm.planillaActivoFijo.mes = _.find(vm.meses, function(mes) { return mes.id === vm.planillaActivoFijo.mes });
    vm.planillaActivoFijo.fechaCreacion = new Date(vm.planillaActivoFijo.fechaCreacion);    
    generateGroupsActivos(vm.planillaActivoFijo.planillaActivoFijoDetalles);    
  }

  function getPrepService() {
    vm.planillaActivoFijo = planillaActivoFijoPrepService;
  }

  function limpiarCabecera() {
    vm.planillaActivoFijo = {};
  }

  function generarPlanilla() {
    if(!areActivoFijoRequiredFieldsTruthy()) {
      notify({
            message: $filter("translate")("COMPLETE_PLANILLA_ACTIVO_FIJO_REQUIRED_FIELDS"),
            classes: "alert-danger",
            position: "right"
            });
      return;
    }

    var idTipo = vm.planillaActivoFijo.tipo.id;
    var year = vm.planillaActivoFijo.anho;
    var month = vm.planillaActivoFijo.mes;

    PlanillaActivoFijoFactory.generar(idTipo, year, month && month.id || null)
      .$promise
      .then(function(response) {
        if(response.length === 0) {
          notify({
          message: $filter("translate")("PLANILLA_ACTIVO_FIJO_VACIA"),
          classes: "alert-danger",
          position: "right"
        });
        }

        // TODO: Agrupar por líneas de productos
        vm.planillaActivoFijo.planillaActivoFijoDetalles = response;
        generateGroupsActivos(response);
      })
      .catch(function(err) {
        var errData = err.data;
        if(errData.length > 0) {
          var msg = errData[0].message
          if(msg) {
            notify({
            message: msg,
            classes: "alert-danger",
            position: "right"
            });
            return
          }
        }
        notify({
          message: $filter("translate")("ERROR_GENERAR_PLANILLA_ACTIVO_FIJO"),
          classes: "alert-danger",
          position: "right"
        });
      })
  }

  function generateGroupsActivos(planillas) {
      vm.groupDetalles = generateGroupsTransformation(planillas)
  }

  function generateGroupsTransformation(planillas) {
    // agrupos los detalles por Linea de Producto y devolvemos un array de objetos
    return _.map(_.groupBy(planillas,
      function(detalle) {
        return detalle.producto.lineaProducto.nombre;
    }), function(array, clave) {
      return {
        linea: clave,
        activosFijo: array
      }
    });
  }

  function submit() {
    vm.submited = true;
    if ($scope.PlanillaActivoFijoForm.$valid) {
      var trimmedModel = ModelTrimmer.trimDetails(vm.planillaActivoFijo, ["planillaActivoFijoDetalles"]);

      formFactory
        .defaultNSFSubmit($scope.PlanillaActivoFijoForm, PlanillaActivoFijoFactory, trimmedModel, errorHandler)
        .then(
          function(response) {
            redirectToList();
          },
          function(error) {
            console.log(error);
          }
        );
    } else {
      notify({
        message: $filter("translate")("WRONG_FIELDS"),
        classes: "alert-danger",
        position: "right"
      });
    }
  }

  function cancel() {
    redirectToList();
  }

  function errorHandler(e) {
    var msg = '';
    _.forEach(e.data, function(error) {
        if (error.constraint == 'nombre') {
            msg += '\n\n' + $filter('translate')('SAME_NAME_PRODUCT_CLASS') + "\n"
        } else {
            msg += '\n\n' + error.message + '.'
        }
    });
    notify({ message: msg, classes: 'alert-danger', position: 'right' });
  }

  function redirectToList() {
     $state.go(
        "app.planillasactivofijo.list",
        {},
        { reload: true }
    );
  }

  function areActivoFijoRequiredFieldsTruthy() {
    return vm.planillaActivoFijo.tipo 
    && vm.planillaActivoFijo.anho 
    && ((vm.planillaActivoFijo.tipo.codigo === 'tipo_planilla_activo_fijo_mensual' && vm.planillaActivoFijo.mes)
        || vm.planillaActivoFijo.tipo.codigo !== 'tipo_planilla_activo_fijo_mensual');
  }

  function changeTypeFilter() {
    delete vm.planillaActivoFijo.mes;
  }
}

"use strict";

angular
    .module("qualita.activofijo")
    .controller("MantenimientoActivoFijoIndexCtrl", ["$scope", "AuthorizationService", "ServicioFactory", "filterFactory", "ReportTicketFactory", "$window", "CuentasAPagarLangFactory", "ComprobanteFactory", "$state", "$stateParams", "$modal", "PlanPagosFactory", "$filter", "CsvFactory", "UtilFactory", "baseurl", "ComprobanteDetalleFactory", "$location", "ActivoFijoLangFactory", "ValorfiscalcontableFactory", "WatchLocacionChangeFactory", function(
        $scope,
        AuthorizationService,
        ServicioFactory,
        filterFactory,
        ReportTicketFactory,
        $window,
        CuentasAPagarLangFactory,
        ComprobanteFactory,
        $state,
        $stateParams,
        $modal,
        PlanPagosFactory,
        $filter,
        CsvFactory,
        UtilFactory,
        baseurl,
        ComprobanteDetalleFactory,
        $location,
        ActivoFijoLangFactory,
        ValorfiscalcontableFactory,
        WatchLocacionChangeFactory
    ) {

        var vm = this;
        vm.registrarValorFiscalContable =  registrarValorFiscalContable;

        var defaultColumnOrder = [
            "comprobante.id",
            "producto.id",
            "producto.nombre",
            "numeroGrupo",
            "producto.codigoBarrasPrincipal.codigoBarras",
            "comprobante.numero",
            "comprobante.fecha",
            "totalItem",
            "comprobante.cambio",
            "producto.tieneDepreciacion",
            "producto.atributos",
            "producto.atributos",
            "producto.atributos",
            "producto.revaluar",
            "valorFiscalContable.valorFiscalNeto",
            "valorFiscalContable.valorContableNeto",
            "valorFiscalContable.depreciacionFiscal",
            "producto.claseProducto.activo"

            
        ];

        ActivoFijoLangFactory.getTranslations().then(function(translations) {
            var monedaRender = function(data, type, row) {
                if (data) {

                    if (!row.comprobante) {
                        return '';
                    }
                    var moneda = "Gs. ";
                    if (row.comprobante.moneda1.codigo === 'dolares') {
                        // moneda = "Usd. ";
                        data = parseFloat(data * row.comprobante.cambio).toFixed(0);
                    } else if (row.comprobante.moneda1.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobante.moneda1.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobante.moneda1.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    }
                    // TODO hacer esto mismo en todos los renders de moneda
                    return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return '';
                }
            };

            var vidaUtilFiscal = function(data, type, row, meta) {
                // Verificar si el valor es un array
                if (Array.isArray(data)) {
                    for(var i = 0; i < data.length; i++){
                        if(data[i].definicionAtributo.codigo == 'vida_util'){
                            return data[i].valor;
                        }
                    }
                } else {
                  // Si no es un array, devolver el valor original
                  return '';
                }
              };
              var vidaUtilContable = function(data, type, row, meta) {
                // Verificar si el valor es un array
                if (Array.isArray(data)) {
                    for(var i = 0; i < data.length; i++){
                        if(data[i].definicionAtributo.codigo == 'vida_util_contable'){
                            return data[i].valor;
                        }
                    }
                } else {
                  // Si no es un array, devolver el valor original
                  return '';
                }
              };
              var valorResidual = function(data, type, row, meta) {
                // Verificar si el valor es un array
                if (Array.isArray(data)) {
                    for(var i = 0; i < data.length; i++){
                        if(data[i].definicionAtributo.codigo == 'valor_residual'){
                            return data[i].valor;
                        }
                    }
                } else {
                  // Si no es un array, devolver el valor original
                  return '';
                }
              };

              var valorFiscalContableDepreciacion = function(data, type, row) {
                if (data) {
                    return data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return 0;
                }
            };




              var booleanRender = function(data, type, row, meta) {
                if (data == true) {
                    return 'SI';
                } else if(data == false) {
                    return 'NO';
                }else{
                    return '';
                }
              };
              
            var monedaRenderSaldo = function(data, type, row) {
                if (data) {

                    // data += row.montoInicial;

                    if (!row.comprobante) {
                        return '';
                    }
                    var moneda = "Gs. ";
                    if (row.comprobante.moneda1.codigo === 'dolares') {
                        moneda = "Usd. ";
                        // data = parseFloat(data).toFixed(2);
                    } else if (row.comprobante.moneda1.codigo === 'pesos') {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobante.moneda1.codigo === 'real') {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (row.comprobante.moneda1.codigo === 'euro') {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    }
                    // TODO hacer esto mismo en todos los renders de moneda
                    return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return '';
                }
            };

            var cambioRender = function(data, type, row) {
                if (data) {

                    // var moneda = "Gs. ";
                    // data += row.montoInicial;

                    // TODO hacer esto mismo en todos los renders de moneda
                    return data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return '';
                }
            };
            var staticFilter = {};
            staticFilter.search = filterFactory.and([
                {
                  path: 'producto.claseProducto.tipoProducto.descripcion',
                  equals: "Activo Fijo"
                }
                // ,
                // {
                //   path: 'servicio.id',
                //   isNull: "true"
                // }
        ]).value();
     
            $scope.options = {
                staticFilter: staticFilter,
                resource: "comprobante_detalle",
                title: "Productos Activo Fijo",
                view: 'MantenimientoActivoFijoList',
                factory: ComprobanteDetalleFactory,
                defaultColumnOrder: defaultColumnOrder,
                failedDeleteError: "Error Mantenimiento fijo",
                columns: [
                    { 
                        data: "comprobante.id", 
                        title: "ID", 
                        renderWith: "emptyRender",
                        visible: false
                    },
                    { 
                    data: "producto.codigo", 
                    title: "Cod. Producto", 
                    renderWith: "emptyRender",
                    visible: false
                    },
                    { 
                        data: "producto.nombre", 
                        title: "Producto",
                        renderWith: "emptyRender"
                    },
                    { 
                        data: "numeroGrupo", 
                        title: "Serie",
                        renderWith: "emptyRender"
                    },
                    { 
                        data: "producto.codigoBarrasPrincipal.codigoBarras", 
                        title: "Cod. Barra",
                        renderWith: "emptyRender",
                        visible: false
                    },
                    { 
                        data: "comprobante.numero", 
                        title: "Nro Comprobante",
                        renderWith: "emptyRender"
                    },
                    {
                        data: "comprobante.fecha",
                        title: "Fecha de Adquisición",
                        renderWith: "dateRender",
                        dateFormat: "DD/MM/YYYY",
                        type: "date-range",
                        // class: "dt-center"
                    },
                    {
                        data: "totalItem",
                        title: "Valor de Adquisición",
                        renderWith: monedaRenderSaldo,
                        // class: "dt-right",
                        type: "number-range"
                    },
                    {
                        data: "comprobante.cambio",
                        title: "Tipo de Cambio",
                        renderWith: cambioRender,
                        // class: "dt-right",
                        type: "number-range"
                    },
                    {
                        data: "producto.tieneDepreciacion",
                        title: "Depreciar",
                        renderWith: booleanRender
                    },
                    // {
                    //     data: "producto.atributos",
                    //     title: "Vida Util Fiscal",
                    //     renderWith: vidaUtilFiscal
                    // },
                    // {
                    //     data: "producto.atributos",
                    //     title: "Vida Util Contable",
                    //     renderWith: vidaUtilContable
                    // },
                    // {
                    //     data: "producto.atributos",
                    //     title: "Valor Residual",
                    //     renderWith: valorResidual
                    // },
                    {
                        data: "producto.revaluar",
                        title: "Revaluar",
                        renderWith: booleanRender
                    },
                    {
                        data: "valorFiscalContable.valorFiscalNeto",
                        title: "Valor Fiscal Neto",
                        renderWith: valorFiscalContableDepreciacion
                        // class: "dt-right"
                        // type: "number-range"
                    },
                    {
                        data: "valorFiscalContable.valorContableNeto",
                        title: "Valor Contable Neto",
                        renderWith: valorFiscalContableDepreciacion
                        // class: "dt-right"
                        // type: "number-range"
                    },
                    {
                        data: "valorFiscalContable.depreciacionFiscal",
                        title: "Depreciacion Fiscal Acumulada",
                        renderWith: valorFiscalContableDepreciacion
                        // class: "dt-right"
                        // type: "number-range"
                    },
                    {
                        data: "producto.claseProducto.activo",
                        title: "Activo",
                        renderWith: booleanRender
                    }
                    // {
                    //     data: "saldo",
                    //     title: $filter("translate")("SALDO"),
                    //     renderWith: monedaRenderSaldo,
                    //     class: "dt-right",
                    //     type: "number-range"
                    // },
            
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc",
                hideAddMenu: true,
                hideEditMenu: true,
                hideRemoveMenu: true,
                hideViewMenu: true,
                extraMenuOptions: [{
                        title: "CSV",
                        icon: "glyphicon glyphicon-export",
                        showCondition: function() {
                            return true;
                        },
                        action: function() {
                            var filters = UtilFactory.createNestedFilters(
                                $scope.options.getFilters(),
                                $scope.options.staticFilter
                            );

                            CsvFactory.csv(
                                "comprobante_detalle",
                                filters,
                                $scope.options.tableAjaxParams,
                                $scope.options.currentColumnOrder,
                                $scope.options.currentColumnOrderNames
                            ).then(function(response) {
                                // console.log("response de csv: ", response);
                                var fileName = response.data.fileName;
                                $window.open(baseurl.getPublicBaseUrl() + fileName);
                            });
                        }
                    },

                    // {
                    //     'title': 'Reporte',
                    //     'icon': 'glyphicon glyphicon-file',
                    //     'showCondition': function() { return true; },
                    //     'action': function() {
                    //         var filters = createFilters($scope.options.getFilters());
                    //         // filters.filters.push({"path":"comprobante.estado.codigo","equals":"comp_aprobado"})
                    //         ReportTicketFactory.ticket('listadoPlanPagos', filters, $scope.options.tableAjaxParams,
                    //             $scope.options.currentColumnOrder).then(function(genTicket) {
                    //             $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                    //             $window.open($scope.pdfDownloadURL, '_blank');
                    //         });
                    //     }
                    // }
                ],
                extraRowOptions: [{
                    templateToRender: "<button class='btn btn-success' title='" +
                        "Editar" +
                        "' style='margin-right: 5px;' ng-click='ver(<%=dataId%>)' ng-class='{ hidden : !canVer(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-edit'></span> </button>",
                    customAttribute: "estado",
                    functionName: "ver",
                    functionDef: function(item) {
                        // redireccionar a plan de pago detalle
                        // $state.go("^.detalles.list", { idPlanPago: itemId });
                        vm.registrarValorFiscalContable(item);
                    },
                    conditionName: "canVer",
                    conditionDef: function(estado) {
                        return AuthorizationService.hasPermission(
                            "delete_mantenimientoactivofijo"
                        );
                    }
                }]
            };
        //     var staticFilter = {};
        //     staticFilter.search = filterFactory.and([
        //         {
        //           path: 'producto.claseProducto.tipoProducto.descripcion',
        //           equals: "Activo Fijo"
        //         }
        //         // ,
        //         // {
        //         //   path: 'servicio.id',
        //         //   isNull: "true"
        //         // }
        // ]).value();

            // $scope.options.staticFilter = staticFilter;
            WatchLocacionChangeFactory.watchWith(
                staticFilter,
                vm.options,
                "locacion.id"
                );
        });



        var createFilters = function(filters) {
            var filtersArr = [];
            _.each(filters, function(search, data) {
                search =  search.split("~");
                if (search.length > 1) {
                    /* El parámetro es un rago */

                    var isDate =  search[0].split("/");

                    if (isDate.length > 1) {
                       /* Es un rago de fechas */

                       filtersArr.push({ path: data, equalOrAfter: search[0] })
                       filtersArr.push({ path: data, equalOrBefore: search[1] })
                    } else {
                       /* El un rago numérco */

                        filtersArr.push({ path: data, greaterOrEqual: search[0] })
                        filtersArr.push({ path: data, lessOrEqual: search[1] })
                    }
                } else {
                    /* El parametro no es un rago */

                    filtersArr.push({ path: data, like: search[0] })
                }
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        }

        function registrarValorFiscalContable(data){
            vm.comprobanteDetalle = data;
            var filtro = {};
            filtro.search = filterFactory.and([
                {
                  path: 'comprobanteDetalle.id',
                  equals: data
                }
          ]).value();
           $scope.valorEntidad = ValorfiscalcontableFactory.all(filtro);
           $scope.valorEntidad.$promise.then(function(dat) {
            if(dat[0]){
               ValorfiscalcontableFactory.get(dat[0].id, "ValorFiscalContableForm").$promise.then(function (valorFiscal) {
                vm.listaValorFiscal = valorFiscal;
                var modalInstance = $modal.open({
                    templateUrl: 'views/directives/activo-fijo-valor-fiscal-contable.html',
                    scope: $scope,
                    backdrop: 'static',
                    bindToController: true,
                    size : 'md',
                });        
              });
            } else{
                vm.listaValorFiscal = undefined;
                var modalInstance = $modal.open({
                    templateUrl: 'views/directives/activo-fijo-valor-fiscal-contable.html',
                    scope: $scope,
                    backdrop: 'static',
                    bindToController: true,
                    size : 'md',
                });
            }
          });

        }
        $scope.cancel = function () {
            console.log("entra");
            $state.go($state.current, {}, { reload: true });
        };
        $scope.limpiar = function () {
            console.log("entra");
            vm.listaValorFiscal.valorFiscalNeto = undefined;
            vm.listaValorFiscal.valorContableNeto = undefined;
            vm.listaValorFiscal.depreciacionFiscal = undefined;
        };

        $scope.submit = function () {
            console.log("entra");
            var data = ValorfiscalcontableFactory.create(vm.listaValorFiscal);
                ComprobanteDetalleFactory.get(vm.comprobanteDetalle, "ComprobanteForm").$promise.then(function (comp) {
                    data.comprobanteDetalle = comp;

                        if(data.valorFiscalNeto === undefined || data.valorFiscalNeto === null){
                            data.valorFiscalNeto = 0;
                        }
                        if(data.valorContableNeto === undefined || data.valorContableNeto === null){
                            data.valorContableNeto = 0;
                        }
                        if(data.depreciacionFiscal === undefined || data.depreciacionFiscal === null){
                            data.depreciacionFiscal = 0;
                        }

                        var respuesta = ValorfiscalcontableFactory.save(data);
                        $state.go($state.current, {}, { reload: true });

                    // if(data.valorFiscalNeto || data.valorContableNeto || data.depreciacionFiscal){
                    //     if(data.valorFiscalNeto === undefined || data.valorFiscalNeto === null){
                    //         data.valorFiscalNeto = 0;
                    //     }
                    //     if(data.valorContableNeto === undefined || data.valorContableNeto === null){
                    //         data.valorContableNeto = 0;
                    //     }
                    //     if(data.depreciacionFiscal === undefined || data.depreciacionFiscal === null){
                    //         data.depreciacionFiscal = 0;
                    //     }
                    //     var respuesta = ValorfiscalcontableFactory.save(data);
                    //     $state.go($state.current, {}, { reload: true });
                    //     return;
                    // } else if(data.id && data.valorFiscalNeto === undefined && data.valorContableNeto === undefined 
                    //       && data.depreciacionFiscal === undefined){
                    //       delete data.valorFiscalNeto;
                    //       delete data.valorContableNeto;
                    //       delete data.depreciacionFiscal;
                    //       var respuesta = ValorfiscalcontableFactory.remove(data);
                    //       $state.go($state.current, {}, { reload: true });
                    //       return;

                    // } else {
                    //     $state.go($state.current, {}, { reload: true });
                    //     return;
                    // }
                    
                  });

      };

    }]);
angular
  .module("qualita.contabilidad", ["ngResource", "ui.tree", "ngFileUpload"])
  .config([
    "$stateProvider",
    function ($stateProvider) {
      $stateProvider
        .state("app.cuentacontable", {
          url: "/cuentascontables",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Cuenta Contable"
          }
        })
        .state("app.cuentacontable.list", {
          url: "",
          templateUrl: "views/template_base/list.html",
          controller: "CuentaContableIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.cuentacontable",
            label: "Cuenta Contable",
            description: "Listado de Cuentas Contables",
            skip: true
          }
        })
        .state("app.cuentacontable.new", {
          url: "/new",
          templateUrl: "qualita-components/contabilidad/views/cuentacontable/form.html",
          controller: "CuentaContableFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.cuentacontable",
            label: "Nueva Cuenta Contable",
            description: "Creación de una nueva Cuenta Contable"
          },
          resolve: {
            cuentaContablePrepService: function () {
              return;
            }
          }
        })
        .state("app.cuentacontable.edit", {
          url: "/:id/edit",
          templateUrl: "qualita-components/contabilidad/views/cuentacontable/form.html",
          controller: "CuentaContableFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.cuentacontable",
            label: "Editar Cuenta Contable",
            description: "Edición de la Cuenta Contable #"
          },
          resolve: {
            cuentaContablePrepService: cuentaContablePrepService
          }
        })
        .state("app.cuentacontable.view", {
          url: "/:id",
          templateUrl: "qualita-components/contabilidad/views/cuentacontable/form.html",
          controller: "CuentaContableFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: "app.cuentacontable",
            label: "Ver Cuenta Contable",
            description: "Ver Cuenta Contable #"
          },
          resolve: {
            cuentaContablePrepService: cuentaContablePrepService
          }
        })

        .state("app.reenvio", {
          url: "/reenvio",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Reenvio costos"
          }
        })
        .state("app.reenvio.list", {
          url: "",
          templateUrl: "qualita-components/contabilidad/views/reenvio/form.html",
          controller: "ReEnvioFormCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            label: "Reenvio de costos",
            description: "Reenvio de costos"
          }
        })
        .state("app.enviocontabilidad", {
          url: "/enviocontabilidad",
          templateUrl: "views/template_base/index.html",
          abstract: true,
          ncyBreadcrumb: {
            label: "Consulta Envio Contabilidad"
          }
        })
        .state("app.enviocontabilidad.list", {
          url: "",
          templateUrl: "qualita-components/contabilidad/views/enviocontabilidad/enviocontabilidad.html",
          controller: "EnvioEntidadesContabilidadIndexCtrl",
          controllerAs: "vm",
          ncyBreadcrumb: {
            parent: 'app.enviocontabilidad',
            label: "Consulta Envio Contabilidad",
            description: "Consulta Envio Contabilidad",
            skip: true
          }
        })
    }
  ]);

function cuentaContablePrepService(CuentaContableFactory, $stateParams) {
  return CuentaContableFactory.get($stateParams.id, "CuentaContableList").$promise;
}
cuentaContablePrepService.$inject = ["CuentaContableFactory", "$stateParams"];
"use strict";

angular
  .module("qualita.contabilidad")
  .controller("CuentaContableFormCtrl", CuentaContableFormCtrl);

CuentaContableFormCtrl.$inject = [
  "$rootScope",
  "$scope",
  "CuentaContableFactory",
  "formFactory",
  "$state",
  "cuentaContablePrepService",
  "notify",
  "ContabilidadLangFactory",
  "$filter"
];

function CuentaContableFormCtrl(
  $rootScope,
  $scope,
  CuentaContableFactory,
  formFactory,
  $state,
  cuentaContablePrepService,
  notify,
  ContabilidadLangFactory,
  $filter
) {
  var vm = this;
  $rootScope.isProcessing = false;

  activate();


  function activate() {

    ContabilidadLangFactory.getTranslations().then(function(translations) {
      vm.translations = translations;
    });


    if ($state.is("app.cuentacontable.new")) {
      activateNew();
    } else if ($state.is("app.cuentacontable.edit")) {
      activateEdit();
    } else if ($state.is("app.cuentacontable.view")) {
      activateView();
    }
    $rootScope.isProcessing = false;
    vm.submited = false;
  }

  function activateNew() {
    vm.title = $filter("translate")("NEW_CUENTA_CONTABLE");
    vm.cuentaContable = {};
  }

  function activateEdit() {
    vm.title = $filter("translate")("EDIT_CUENTA_CONTABLE");
    vm.cuentaContable = cuentaContablePrepService;
  }

  function activateView() {
    vm.view = true;
    vm.title = $filter("translate")("VIEW_CUENTA_CONTABLE");
    vm.cuentaContable = cuentaContablePrepService;
  }

  vm.cancel = cancel;
  vm.submit = submit;
  function submit() {
    vm.submited = true;
    if ($scope.CuentaContableForm.$valid) {
      formFactory
        .defaultNSFSubmit($scope.CuentaContableForm, CuentaContableFactory, vm.cuentaContable)
        .then(
          function(response) {
            redirectToList();
          },
          function(error) {
            console.log(error);
          }
        );
    } else {
      console.log("Form invalid");
      console.log($scope.CuentaContableForm);
      notify({
        message: $filter("translate")("WRONG_FIELDS"),
        classes: "alert-danger",
        position: "right"
      });
    }
  }

  function cancel() {
    redirectToList();
  }

  function redirectToList() {
     $state.go(
        "app.cuentacontable.list",
        {},
        { reload: true }
    );
  }

}

'use strict';

angular.module('qualita.contabilidad')
  .controller('CuentaContableIndexCtrl', ["$scope", "ContabilidadLangFactory", "CuentaContableFactory", "filterFactory", "ReportTicketFactory", "$window", "CsvFactory", "UtilFactory", "baseurl", "$filter", function ($scope, ContabilidadLangFactory, CuentaContableFactory, filterFactory,
                                                       ReportTicketFactory, $window, CsvFactory, UtilFactory, baseurl, $filter) {

    var defaultColumnOrder = ['id','descripcion'];
    ContabilidadLangFactory.getTranslations().then(function(translations) {
      $scope.options = {
        'resource': 'cuentacontable',
        'title': $filter('translate')('CUENTAS_CONTABLES'),
        'factory': CuentaContableFactory,
        'view': 'CuentaContableList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': $filter('translate')('CUENTA_CONTABLE_DELETE_FAILED'),
        'columns': [
          {'data': 'id', 'title': $filter('translate')('CODE'), 'visible': false },
          {'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION')}
        ],
        'hasOptions' : true,
        'defaultOrderColumn' : 0,
        'defaultOrderDir' : "desc",
        'extraMenuOptions':
          [
            {
              'title': $filter('translate')('REPORT'),
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                var filters = createFilters($scope.options.getFilters());
                ReportTicketFactory.ticket('listadoCuentaContable', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                  $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                  $window.open($scope.pdfDownloadURL, '_blank');
                });
              }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                  CsvFactory.csv("cuentacontable", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function(response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
                }
            }
          ]
      };
    });

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({path: data, like: search})
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
  }]);

'use strict';

/**
 * @ngdoc service
 * @name qualita.CuentaContableFactory
 * @description
 * # CuentaContableFactory
 * Factory in the qualita.
 */
angular.module('qualita.contabilidad')
  .factory('CuentaContableFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
  '$translatePartialLoader', '$translate',
    function($resource,  baseurl, filterFactory, TiposFactory, $http, $translatePartialLoader, $translate) {

  	var CuentaContableFactory= $resource( baseurl.getBaseUrl() + '/cuentacontable/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params, view) {
        if (params) {
            params.view = view != undefined ? view : 'BaseList';
          } else {
            params = [];
            params.view = view != undefined ? view : 'BaseList';
          }
          return CuentaContableFactory.query(params);
        },

      get: function(id, view) {
        return CuentaContableFactory.get({id: id, view: view ? view : "base"});
      },

      create: function(attrs) {
        return new CuentaContableFactory(attrs);
      },

      save: function(chequera) {
        return (chequera.id) ? chequera.$update() : chequera.$save();
      },

      remove: function(chequera) {
        return chequera.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      },

    };
  }]);

angular
  .module("qualita.contabilidad")
  .factory("ContabilidadLangFactory", ContabilidadLangFactory);

ContabilidadLangFactory.$inject = [
  "$translate",
  "$translatePartialLoader"
];

function ContabilidadLangFactory(
  $translate,
  $translatePartialLoader
) {
  var translationsNeeded = [
    "CUENTAS_CONTABLES",
    "NEW_CUENTA_CONTABLE",
    "EDIT_CUENTA_CONTABLE",
    "VIEW_CUENTA_CONTABLE",
    "CUENTA_CONTABLE_DELETE_FAILED",
    "DATE_FROM_TO_ERROR",
    "DATE_RANGE_ERROR"
  ];

  var service = {
    getTranslations: getTranslations
  };

  function getTranslations() {
    $translatePartialLoader.addPart("contabilidad");
    return $translate.refresh().then(function() {
      return $translate(translationsNeeded);
    });
  }

  return service;
}

"use strict";

angular
    .module("qualita.venta")
    .controller("ConsultaCobrosListCtrl", ["$scope", "$rootScope", "CobroFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "VentasLangFactory", "ProcesoCobroFactory", "WatchLocacionChangeFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "ParametrosFactory", "notify", "HistoricoCobroDetalleFactory", function (
        $scope,
        $rootScope,
        CobroFactory,
        filterFactory,
        AuthorizationService,
        ReportTicketFactory,
        $modal,
        $window,
        $state,
        VentasLangFactory,
        ProcesoCobroFactory,
        WatchLocacionChangeFactory,
        $filter,
        UtilFactory,
        CsvFactory,
        baseurl,
        ParametrosFactory,
        notify,
        HistoricoCobroDetalleFactory
    ) {
        var vm = this;
        vm.permisoMantenimientoCobro = AuthorizationService.hasPermission("mantenimiento_cobro");

        VentasLangFactory.getTranslations().then(function (translations) {
            $scope.translations = translations;

            var monedaRender = function (data, type, row) {
                var campoMoneda = null;
                if (row.moneda != undefined) {
                    campoMoneda = row.moneda;
                }

                if (data != undefined) {
                    var moneda = "Gs. ";
                    if (campoMoneda.codigo === "dolares") {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "pesos") {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "real") {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "euro") {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    } else {
                        data = parseFloat(data).toFixed(0);
                    }
                    // TODO hacer esto mismo en todos los renders de moneda
                    return (
                        moneda +
                        data
                            .toString()
                            .replace(".", ",")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                } else {
                    return "";
                }
            };
            var staticFilter = {};
            staticFilter = {
                search: filterFactory
                    .and([
                    {
                        path: "id",
                        sortDesc: "true"
                    },
                    {
                        path: "id",
                        isNotNull: "true"
                    }])
                    .value()
            };

            var defaultColumnOrder = [
                "id",
                "numeroCobro",
                "fecha",
                "numeroRecibo",
                "caja.nombre",
                "cliente.nombre",
                "cliente.codigo",
                "descripcion",
                "estado.descripcion",
                "procesoCobro.id",
                "tipoCobro.descripcion",
                "tipoCobroEntidad.nombre",
                "importeTotal",
                "importeAnticipo",
                "saldoAnticipo"
            ];

            var renderCobrador = function (data) {
              if (data == undefined)
                return "";
              else
                return data.nombres + " " + data.apellidos;
            };

            vm.options = {
                resource: "cobros",
                title: "Cobros",
                view: "CobroList",
                factory: CobroFactory,
                defaultColumnOrder: defaultColumnOrder,
                columns: [
                    { data: 'id', title: 'Código Interno', visible: false },
                    { data: "numeroCobro", title: translations.CODIGO_COBRO, class: "dt-center" },
                    {
                        data: "procesoCobro.id",
                        title: translations.CODIGO_PROC_COBRO,
                        class: "dt-center"
                    },
                    {
                        data: "fecha",
                        title: $filter("translate")("DATE"),
                        renderWith: 'dateRender',
                        dateFormat: 'DD/MM/YYYY',
                        class: "dt-center",
                        type: "date-range"
                    },
                    {
                        data: "numeroRecibo",
                        title: $filter("translate")("NUMERO_RECIBO"),
                        class: "dt-center"
                    },
                    {
                        data: "caja.nombre",
                        title: $filter("translate")("CAJA"),
                        renderWith: 'emptyRender'
                    },
                    {
                        data: "cliente.nombre",
                        title: $filter("translate")("CLIENTE"),
                        renderWith: 'emptyRender'
                    },
                    {
                        data: "descripcion",
                        title: "Descripción",
                        renderWith: 'emptyRender',
                        visible: false
                    },
                    {
                        data: "estado.descripcion",
                        title: $filter("translate")("STATUS"),
                        renderWith: 'emptyRender',
                        type: "combo",
                        filterUrl: "tipos/filtro/estado_cobro"
                    },
                    {
                        data: "tipoCobro.descripcion",
                        title: $filter("translate")("TIPO_COBRO"),
                        renderWith: 'emptyRender',
                        type: "combo",
                        filterUrl: "tipos/filtro/tipo_cobro"
                    },
                    {
                        data: "tipoCobroEntidad.nombre",
                        title: "Tipo Cobro Entidad",
                        renderWith: 'emptyRender'
                    },
                    {
                        data: 'importeTotal',
                        title: "Importe Total",
                        class: 'dt-right',
                        render: monedaRender
                    },
                    {
                        data: 'importeAnticipo',
                        title: "Importe Anticipo",
                        class: 'dt-right',
                        render: monedaRender
                    },
                    {
                        data: 'saldoAnticipo',
                        title: "Saldo Anticipo",
                        class: 'dt-right',
                        render: monedaRender
                    },
                    {
                        data: 'cobrador',
                        title: "Cobrador",
                        render: renderCobrador,
                        searchable: false
                    },
                    {
                        data: 'usuario.username',
                        title: "Cajero"
                    },
                    {
                        data: 'cliente.codigo',
                        title: "Cód. Cliente"
                    }
                ],
                hasOptions: true,
                defaultOrderColumn: 0,
                defaultOrderDir: "desc",
                hideAddMenu: true,
                hideEditMenu: true,
                hideRemoveMenu: true,
                hideViewMenu: true,

                extraRowOptions: [
                    verRowOption,
                    editarRowOption,
                    aplicarRowOption,
                    reimprimirRowOption,
                    MantenimientoRowOption
                ],
                extraMenuOptions: [
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = UtilFactory.createNestedFilters(vm.options.getFilters(), vm.options.staticFilter);

                            CsvFactory.csv("cobros", filters, vm.options.tableAjaxParams, vm.options.currentColumnOrder, vm.options.currentColumnOrderNames)
                                .then(function (response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    },
                    {
                        'title': 'Reporte',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = createFilters(vm.options.getFilters());
                            ReportTicketFactory.ticket('listadoConsultaCobro', filters, vm.options.tableAjaxParams,
                                vm.options.currentColumnOrder).then(function (genTicket) {
                                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                    $window.open($scope.pdfDownloadURL, '_blank');
                                });
                        }
                    }
                ]
            };
        });


        var createFilters = function (filters) {
            var filtersArr = [];
            _.each(filters, function (search, data) {
                search = search.split("~");
                if (search.length > 1) {
                    /* El parámetro es un rago */

                    var isDate = search[0].split("/");

                    if (isDate.length > 1) {
                        /* Es un rago de fechas */

                        filtersArr.push({ path: data, equalOrAfter: search[0] })
                        filtersArr.push({ path: data, equalOrBefore: search[1] })
                    } else {
                        /* El un rago numérco */

                        filtersArr.push({ path: data, greaterOrEqual: search[0] })
                        filtersArr.push({ path: data, lessOrEqual: search[1] })
                    }
                } else {
                    /* El parametro no es un rago */


                    filtersArr.push({ path: data, like: search[0] })
                }
            });
            var filters = filterFactory.and(filtersArr).value();
            return filters;
        }
        var verRowOption = {
            templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
                $filter("translate")("VIEW") +
                "' ng-click='ver(<%=dataCustom%>)' ng-class='{ hidden : !canVer()}'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
            customAttribute: ["procesoCobro", "id"],
            functionName: "ver",
            functionDef: function (attributes) {
                var procesoCobro = attributes[0];
                var idCobro = attributes[1];
                $state.go("app.cobro.view", { id: idCobro });
            },
            conditionName: "canVer",
            conditionDef: function () {
                return CobroFactory.hasPermisos("view");
            }
        };
        var editarRowOption = {
          templateToRender:
            "<button class='btn btn-warning' style='margin-right: 5px;' title='" +
            $filter("translate")("EDIT") +
            "' ng-click='editar(<%=dataCustom%>)' ng-class='{ hidden : !canEditar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-pencil'></span> </button>",
          functionName: "editar",
          conditionName: "canEditar",
          customAttribute: ["estado", "id"],
          conditionDef: function (attributes) {
            var estado = attributes[0];
            if (estado.codigo === "estado_cobro_emitido" && vm.permisoMantenimientoCobro) {
              return true;
            } else {
              return false;
            }
          },
          functionDef: function (attributes) {
            localStorage.setItem("customEditMant", true);
            var idCobro = attributes[1];
            $state.go("app.cobro.edit", { id: idCobro });
          },
        };

        var reimprimirRowOption = {
            templateToRender: "<button title='" +
                'Reimprimir Cobro' +
                "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='reimprimir(<%=dataCustom%>)' ng-class='{ hidden : !canReimprimir(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-print'></span> </button>",
            functionName: "reimprimir",
            conditionName: "canReimprimir",
            customAttribute: ["estado", "id"],
            conditionDef: function (atributos) {
                var estado = atributos[0];
                if (estado.codigo === 'estado_cobro_emitido') {
                    return true;
                } else {
                    return false;
                }

            },
            functionDef: function (atributos) {
                var itemId = atributos[1];
                var estado = atributos[0];

                if (AuthorizationService.hasPermission("reimpresion_cobros") === true && estado.codigo === 'estado_cobro_emitido') {
                    $scope.mensajeModal = "Desea reimprimir el cobro?";
                    $scope.tituloModal = "Reimprimir Cobro";
                    var modalInstance = $modal.open({
                        templateUrl: "views/directives/confirmation-modal.html",
                        scope: $scope
                    });

                    $scope.cancel = function () {
                        modalInstance.dismiss("cancel");
                    };

                    $scope.ok = function (id) {
                        var filtersArr = [];
                        filtersArr.push("id_cobro=" + itemId);
                        filtersArr.push("ciudad=" + $rootScope.AuthParams.suc.ciudad);
                        CobroFactory.get(itemId, "CobroList").$promise.then(function (cobro) {

                            // Recupera parametro nombre empresa
                            ParametrosFactory.getByCodigo("nombre_empresa").then(function (data) {
                                if (data) {
                                    filtersArr.push("empresa_nombre=" + data.valor);
                                } else {
                                    filtersArr.push("empresa_nombre=" + "SIN_NOMBRE");
                                }

                                // Recupera parametro direccion de la empresa
                                ParametrosFactory.getByCodigo("direccion_empresa").then(function (direccion) {
                                    if (direccion) {
                                        filtersArr.push("empresa_direccion=" + direccion.valor);
                                    }
                                    // Recupera parametro telefono de la empresa
                                    ParametrosFactory.getByCodigo("telefono_empresa").then(function (telefono) {

                                        if (telefono) {
                                            filtersArr.push("empresa_telefono=" + telefono.valor);
                                        }
                                      // Recupera parametro telefono de la empresa
                                        ParametrosFactory.getByCodigo("ruc_empresa").then(function(ruc) {

                                            if(ruc){
                                              filtersArr.push("empresa_ruc=" + ruc.valor);
                                            }

                                            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport("cobro", "pdf", filtersArr);
                                            $window.open(vm.pdfDownloadURL, "_blank");
                                            $state.go($state.current, {}, { reload: true });

                                        });
                                    });
                                });
                            });
                        });
                    }
                } else {
                    var msg = "No posee el permiso para realizar la Reimpresión.";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                }
            }
        };

        var aplicarRowOption = {
            templateToRender: "<button title='" + "Aplicar Anticipo" + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='aplicarAnticipo(<%=dataId%>)' ng-class='{hidden : !canAplicarAnticipo(<%=dataCustom%>) }'> <span class='glyphicon glyphicon-piggy-bank'></span> </button>",
            functionName: 'aplicarAnticipo',
            functionDef: function (itemId) {
                CobroFactory.get(itemId, 'CobroForm').$promise.then(function (cobro) {

                    if (cobro.importeAnticipo != cobro.saldoAnticipo) {
                        $scope.tituloModal = $filter("translate")(
                            "APLICACION_YA_EXISTENTE"
                        );
                        var templateTmp = '<div class="modal-header">' +
                            '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                            "</div>" +
                            '<div class="modal-body">{{::mensajeModal}}</div>' +
                            '<div class="modal-footer">' +
                            '<button class="btn btn-primary" ng-click="editarAplicacion()">' +
                            $filter("translate")("EDITAR_APLICACION") +
                            "</button>";
                        if (cobro.saldoAnticipo > 0) {
                            templateTmp += '<button class="btn btn-secondary" ng-click="agregarAplicacion()">' +
                                $filter("translate")("AGREGAR_APLICACION ") +
                                "</button>";
                        }
                        templateTmp += '<button class="btn btn-warning" ng-click="cancel()">' +
                            $filter("translate")("CANCEL ") +
                            "</button>" +
                            "</div>";
                        $scope.mensajeModal = $filter("translate")("QUE_DESEA_Q");
                        $scope.modalInstanceBorrar1 = $modal.open({
                            template: templateTmp,
                            scope: $scope
                        });

                        $scope.cancel = function () {
                            $scope.modalInstanceBorrar1.dismiss("cancel");
                        };

                        $scope.editarAplicacion = function () {
                            $state.go(
                                "app.cobro.aplicaranticipo", {
                                id: itemId,
                                mode: "edit"
                            }
                            );
                            $scope.modalInstanceBorrar1.dismiss();
                        };

                        $scope.agregarAplicacion = function () {
                            $state.go(
                                "app.cobro.aplicaranticipo", {
                                id: itemId,
                                mode: "add"
                            }
                            );
                            $scope.modalInstanceBorrar1.dismiss();
                        };
                    } else {
                        $state.go("app.cobro.aplicaranticipo", { id: itemId });
                    }
                })

            },
            conditionName: 'canAplicarAnticipo',
            customAttribute: ['tipoCobro', 'estado'],
            conditionDef: function (atributos) {
                var tipoCobro = atributos[0];
                var estado = atributos[1];
                return (tipoCobro.codigo == "tipo_cobro_anticipo"
                    && (estado.codigo == "estado_cobro_emitido"));
            }
        }
        var MantenimientoRowOption = {
            templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" +
                "Mantenimiento Cobro" +
                "' ng-click='mantenimiento(<%=dataId%>)' ng-class='{ hidden : !canMantenimiento()}'> <span class='glyphicon glyphicon-stats'></span> </button>",
            customAttribute: ['id'],
            functionName: "mantenimiento",
            functionDef: function(itemId) {
                var defaultColumnOrder = [
                    'numeroTarjeta',
                    'tipoTarjeta.descripcion',
                    'numerVoucher',
                    'codigoAutorizacion',
                    'denominacionMarca.descripcion',
                    'procesadora.nombre',
                    'tipoProcesamiento.descripcion',
                    'nombreTitular',
                    'fechaCambio',
                ];

                var staticFilter = [];
    
                // El filtro almacen siempre tiene un valor definido (al cargar la pagina es 'Todos')
                staticFilter.push(
                    {
                        path: 'cobroDetalle.cobro.id',
                        equals: itemId
                    }
                )
    
                 staticFilter.push(
                    {
                        path: 'fechaCambio',
                        sortDesc: 'true'
                    }
                ) 
    
                var filter = {
                    search: filterFactory.and(staticFilter).value()
                };
    
                $scope.vm.optionsDTModal = {
                    'resource': 'historicocobrodetalle',
                    'staticFilter': filter,
                    'title': 'Histórico Cobro Detalle',
                    'view': 'HistoricoCobroDetalleList',
                    'factory': HistoricoCobroDetalleFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'columns': [
                        { 'data': 'numeroTarjeta', 'title': $filter('translate')('NUMERO_TARJETA'), 'class': 'dt-center', 'searchable': false },
                        { 'data': 'tipoTarjeta.descripcion', 'title': $filter('translate')('TIPO_TARJETA'), 'class': 'dt-center', 'searchable': false },
                        { 'data': 'numerVoucher', 'title': $filter('translate')('NUMERO_VOUCHER'), 'class': 'dt-center', 'searchable': false },
                        { 'data': 'codigoAutorizacion', 'title': 'Cod. Autorización', 'class': 'dt-center', 'searchable': false },
                        { 'data': 'denominacionMarca.descripcion', 'title': 'Denominación', 'class': 'dt-center', 'searchable': false },
                        { 'data': 'procesadora.nombre', 'title': 'Procesadora', 'class': 'dt-center', 'searchable': false },
                        { 'data': 'tipoProcesamiento.descripcion', 'title': 'Tipo Procesamiento', 'class': 'dt-center', 'searchable': false },
                        { 'data': 'nombreTitular', 'title': 'Nombre del Titular', 'class': 'dt-center', 'searchable': false },
                        { 'data': 'fechaCambio', 'title': 'Fecha de Cambio', 'class': 'dt-center', 'searchable': false }
                    ],
                    'hasOptions': false,
                    'defaultOrderColumn': 0,
                    'defaultOrderDir': "desc",
                    'hideHeader': true,
                };
                        
                $scope.modalInstance = $modal.open({
                    templateUrl: 'views/directives/datatables-custom-modal.html',
                    scope: $scope,
                    size: 'lg'
                });
        
                $scope.vm.close = function () {
                    if ($scope.modalInstance) {
                        $scope.modalInstance.close();
                    }
                }
            },
            conditionName: "canMantenimiento",
            conditionDef: function() {
                return vm.permisoMantenimientoCobro;
            }
        };
    }]);

'use strict';


angular.module('qualita.tesoreria')
    .controller('ConsultaChequeIndexCtrl',
        ["$scope", "ClienteFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "AlmacenFactory", "ProductosFactory", "notify", "GrupoProductoAlmacenFactory", "UtilFactory", "MovimientoStockFactory", "TesoreriaLangFactory", "WatchLocacionChangeFactory", "$filter", "CsvFactory", "baseurl", "CobroDetalleFactory", "TiposTipoFactory", "EntidadBancariaFactory", "cuentaBancariaFactory", "$timeout", function ($scope, ClienteFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal,
            $window, $state, AlmacenFactory, ProductosFactory, notify, GrupoProductoAlmacenFactory, UtilFactory,
            MovimientoStockFactory, TesoreriaLangFactory, WatchLocacionChangeFactory, $filter, CsvFactory, baseurl,
            CobroDetalleFactory, TiposTipoFactory, EntidadBancariaFactory, cuentaBancariaFactory, $timeout) {

            var vm = this;
            vm.searchCuentasFilter = searchCuentasFilter;
            /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
            $scope.staticFilterClie = [{ path: "activo", equals: true }];
            $scope.clientesFactory = ClienteFactory;
            $scope.monedas = TiposTipoFactory.get("monedas");
            $scope.entidadBancarias=EntidadBancariaFactory.all();
            $scope.cuentasBancarias = cuentaBancariaFactory.all();
            $scope.depositadotodos = true;
            function searchCuentasFilter(criteria) {
              return function (item) {
                if (!criteria) {
                  return true;
                }
                return (
                  (item.numeroCuenta + "").indexOf(criteria.toLowerCase()) >
                  -1 ||
                  item.entidad.nombre
                    .toLowerCase()
                    .indexOf(criteria.toLowerCase()) > -1
                );
              };
            };
            var monedaRender = function (data, type, row) {

                var campoMoneda = null;

                if (row.moneda != undefined) {
                    campoMoneda = row.moneda;
                }

                if (data != undefined) {
                    var moneda = "Gs. ";
                    if (campoMoneda.codigo === "dolares") {
                        moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "pesos") {
                        moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "real") {
                        moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "euro") {
                        moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    } else {
                        data = parseFloat(data).toFixed(0);
                    }
                    return (
                        moneda +
                        data
                            .toString()
                            .replace(".", ",")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                } else {
                    return "";
                }
            };

          $scope.changeDepositado = function (value) {
            $scope.depositadosi = false;
            $scope.depositadono = false;
            $scope.depositadotodos = false;
            if(value === "si"){
              $scope.depositadosi = true;
            } else
            if(value === "no"){
              $scope.depositadono = true;
            } else
            if(value === "todos"){
              $scope.depositadotodos = true;
            }
          };

            $scope.getCheques = function () {
                /* definir como filtrar por cliente */
                var staticFilter = [];

                // Verificacion de 'fecha desde' menor a 'fecha hasta
                if ($scope.fechaDiferidoDesde > $scope.fechaDiferidoHasta) {
                    var msg = '';
                    msg += '\n\n' + $filter('translate')('INVALID_DATES_DIFERIDO') + "\n";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    return
                }

                // Verificacion de 'fecha emision desde' menor a 'fecha emision hasta
                if ($scope.fechaEmisionDesde > $scope.fechaEmisionHasta) {
                    var msg = '';
                    msg += '\n\n' + 'La fecha de inicio (Fecha Emision Desde) debe ser menor o igual a la fecha de fin (Fecha Emision Hasta).' + "\n";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    return
                }

                if($scope.depositadosi){
                    $scope.depositadono = undefined;
                    $scope.depositadotodos = undefined;
                }
                if($scope.depositadono){
                    $scope.depositadosi = undefined;
                    $scope.depositadotodos = undefined;
                }
                if($scope.depositadotodos){
                    $scope.depositadono = undefined;
                    $scope.depositadosi = undefined;
                }

                $scope.filtro = true;

                //Filtro de clientes
                if ($scope.cliente) {
                    staticFilter.push(
                        {
                            path: 'cobro.cliente.id',
                            equals: $scope.cliente.id
                        }
                    )
                }

                if ($scope.moneda) {
                    staticFilter.push(
                        {
                            path: 'moneda.id',
                            equals: $scope.moneda.id
                        }
                    )
                }

                if ($scope.entidadBancaria) {
                    staticFilter.push(
                        {
                            path: 'entidadBancaria.id',
                            equals: $scope.entidadBancaria.id
                        }
                    )
                }

                if ($scope.depositadosi) {
                    staticFilter.push(
                        {
                            path: 'depositado',
                            like: 'true'
                        }
                    )
                }

                if ($scope.depositadono) {
                    staticFilter.push(
                        {
                            path: 'depositado',
                            like: 'false'
                        }
                    )
                }

                if ($scope.fechaDiferidoDesde) {
                    staticFilter.push(
                        {
                            path: 'fechaPagoDiferido',
                            equalOrAfter: moment($scope.fechaDiferidoDesde).format('DD/MM/YYYY')
                        }
                    )
                }

                if ($scope.fechaDiferidoHasta) {
                    staticFilter.push(
                        {
                            path: 'fechaPagoDiferido',
                            equalOrBefore: moment($scope.fechaDiferidoHasta).format('DD/MM/YYYY')
                        }
                    )
                }

                if ($scope.fechaEmisionDesde) {
                    staticFilter.push(
                        {
                            path: 'fecha',
                            equalOrAfter: moment($scope.fechaEmisionDesde).format('DD/MM/YYYY')
                        }
                    )
                }

                if ($scope.fechaEmisionHasta) {
                    staticFilter.push(
                        {
                            path: 'fecha',
                            equalOrBefore: moment($scope.fechaEmisionHasta).format('DD/MM/YYYY')
                        }
                    )
                }

                if (vm.cuentaBancaria) {
                  staticFilter.push(
                    {
                      path: 'movimientoBancario.cuenta.numeroCuenta',
                      like: vm.cuentaBancaria.numeroCuenta
                    }
                  )
                  staticFilter.push(
                    {
                      path: 'movimientoBancario.cuenta.entidad.id',
                      equals: vm.cuentaBancaria.entidad.id
                    }
                  )
                }

                // Filtro para especificar cheques utilizados en cobro
                staticFilter.push(
                    {
                        path: 'numeroCheque',
                        notEquals: 'null'
                    }
                )

                staticFilter.push(
                    {
                        path: 'cobro.estado.codigo',
                        notEquals: 'estado_cobro_anulado'
                    }
                )

                staticFilter.push(
                    {
                        path: 'formaPago.clase.codigo',
                        equals: 'forma_pago_cheque'
                    }
                )

                // Se define el filtro final
                var filtros = {
                    search: filterFactory.and(staticFilter).value()
                };

                console.log("filtros ", filtros);

                $scope.options.staticFilter = filtros;
            };


            var defaultColumnOrder = ['id', 'entidadBancaria.nombre', 'cobro.cliente.codigo', 'cobro.cliente.nombre',
              'movimientoBancario.cuenta.numeroCuenta', 'movimientoBancario.cuenta.entidad.nombre',
              'numeroCheque', 'fecha', 'fechaPagoDiferido', 'moneda.descripcion',
                'importe', 'cobro.numeroCobro', 'cobro.procesoCobro.id', 'depositado'];

            TesoreriaLangFactory.getTranslations().then(function (translations) {
                $scope.options = {
                    'resource': 'cobrosdetalles',
                    'title': $filter('translate')('CONSULTA_CHEQUES'),
                    // 'staticFilter': staticFilter,
                    'view': 'CobroDetalleList',
                    'factory': CobroDetalleFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'columns': [
                        { 'data': 'cobro.procesoCobro.id', 'title': $filter('translate')('CODIGO_PROC_COBRO'), renderWith: 'emptyRender', searchable: false },
                        { 'data': 'cobro.numeroCobro', 'title': $filter('translate')('CODIGO_COBRO'), renderWith: 'emptyRender', searchable: false },
                        { 'data': 'cobro.cliente.codigo', 'title': 'Código Cliente', renderWith: 'emptyRender', searchable: false },
                        { 'data': 'cobro.cliente.nombre', 'title': 'Cliente', renderWith: 'emptyRender', searchable: false },
                        { 'data': 'entidadBancaria.nombre', 'title': $filter('translate')('ENTITY_NAME_CHEQUE_COB'), renderWith: 'emptyRender', searchable: false },
                        { 'data': 'movimientoBancario.cuenta.numeroCuenta', 'title': "Cuenta de Depósito", renderWith: 'emptyRender', 'class': 'dt-right', searchable: false },
                        { 'data': 'movimientoBancario.cuenta.entidad.nombre', 'title': "Entidad Bancaria de Depósito", renderWith: 'emptyRender', 'class': 'dt-right', searchable: false },
                        { 'data': 'numeroCheque', 'title': $filter('translate')('NUMBER'), renderWith: 'emptyRender', 'class': 'dt-right', searchable: false },
                        { 'data': 'fecha', 'title': $filter('translate')('DATE'), renderWith: 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-right', searchable: false },
                        { 'data': 'fechaPagoDiferido', 'title': $filter('translate')('FECHA_PAGO'), renderWith: 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-right', searchable: false },
                        { 'data': 'moneda.descripcion', 'title': $filter('translate')('CURRENCY'), renderWith: 'emptyRender', 'class': 'dt-right', searchable: false },
                        { 'data': 'importe', 'title': $filter('translate')('MONTO_TOTAL'), renderWith: monedaRender, 'class': 'dt-right', searchable: false },
                        { 'data': 'depositado', 'title': 'Depósitado', renderWith: 'emptyRender', 'class': 'dt-center', searchable: false }
                    ],
                    'hasOptions': false,
                    'hideAddMenu': true,
                    'defaultOrderColumn': 0,
                    'defaultOrderDir': "asc",
                    'extraMenuOptions': [{
                        'title': 'Reporte de Cheques Recibidos',
                        'icon': 'glyphicon glyphicon-file',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filtersArr = [];
                            //cliente
                            if ($scope.cliente) {
                                filtersArr.push("idCliente=" + $scope.cliente.id);
                            }
                            //fecha diferido desde
                            if ($scope.fechaDiferidoDesde) {
                                filtersArr.push("fechaDesdeDiferido=" + $filter('date')($scope.fechaDiferidoDesde, "MM/dd/yyyy"));
                            }
                            //fecha diferido hasta
                            if ($scope.fechaDiferidoHasta) {
                                filtersArr.push("fechaHastaDiferido=" + $filter('date')($scope.fechaDiferidoHasta, "MM/dd/yyyy"));
                            }
                            //moneda
                            if ($scope.moneda) {
                                filtersArr.push("idmoneda=" + $scope.moneda.id);
                            }
                            //fecha emision desde
                            if ($scope.fechaEmisionDesde) {
                                filtersArr.push("fechaEmisionDesde=" + $filter('date')($scope.fechaEmisionDesde, "MM/dd/yyyy"));
                            }
                            //fecha emision hasta
                            if ($scope.fechaEmisionHasta) {
                                filtersArr.push("fechaEmisionHasta=" + $filter('date')($scope.fechaEmisionHasta, "MM/dd/yyyy"));
                            }
                            //entidad bancaria
                            if ($scope.entidadBancaria) {
                                filtersArr.push("identidadBancaria=" + $scope.entidadBancaria.id);
                            }
                            //depositado si
                            if ($scope.depositadosi) {
                                filtersArr.push("depositadosi=" + $scope.depositadosi);
                            }
                            //depositado no
                            if ($scope.depositadono) {
                                filtersArr.push("depositadono=" + $scope.depositadono);
                            }
                            if (vm.cuentaBancaria) {
                                filtersArr.push("numerocuenta=" + vm.cuentaBancaria.numeroCuenta);
                                filtersArr.push("entidadbancaria=" + vm.cuentaBancaria.entidad.id);
                            }

                            $scope.pdfDownloadURL = ReportTicketFactory.downloadCustomReport('chequesrecibidos', 'pdf', filtersArr);
                            $window.open($scope.pdfDownloadURL, '_blank');
                        }
                    },
                    {
                        'title': 'CSV',
                        'icon': 'glyphicon glyphicon-export',
                        'showCondition': function () { return true; },
                        'action': function () {
                            var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);
                            CsvFactory.csv("cobrosdetalles", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                .then(function (response) {
                                    console.log("response de csv: ", response);
                                    var fileName = response.data.fileName;
                                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                                });
                        }
                    }
                    ]
                };
            });

            $scope.limpiarFiltros = function() {
                $scope.cliente = undefined;
                $scope.fechaDiferidoDesde = undefined;
                $scope.fechaDiferidoHasta = undefined;
                $scope.moneda = undefined;
                $scope.fechaEmisionDesde = undefined;
                $scope.fechaEmisionHasta = undefined;
                $scope.entidadBancaria = undefined;
                $scope.numeroCuenta = undefined;
                $scope.depositadosi = undefined;
                $scope.depositadono = undefined;
                $scope.depositadotodos = undefined;
                vm.cuentaBancaria = undefined;
                $scope.changeDepositado("todos")
              }

            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    search = search.split("~");
                    if (search.length > 1) {
                        /* El parámetro es un rago */

                        var isDate = search[0].split("/");

                        if (isDate.length > 1) {
                            /* Es un rago de fechas */

                            filtersArr.push({ path: data, equalOrAfter: search[0] })
                            filtersArr.push({ path: data, equalOrBefore: search[1] })
                        } else {
                            /* El un rago numérco */

                            filtersArr.push({ path: data, greaterOrEqual: search[0] })
                            filtersArr.push({ path: data, lessOrEqual: search[1] })
                        }
                    } else {
                        /* El parametro no es un rago */
                        filtersArr.push({ path: data, like: search[0] })
                    }
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }

            $scope.$watch('filtro', function (newValue){
              if(newValue){
                $timeout(function(){
                  $("#DataTables_Table_0_filter").hide()
                })
              }
            })
        }]);

angular.module("qualita.analitica", []).config(
    [
        "$stateProvider",
        function ($stateProvider) {
            $stateProvider
            .state("app.tablerocompras", {
                url: "/tablerocompras",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tablerocompras.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tablerocompras/form.html",
                controller: "TableroCompraFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Compras",
                    description: "Ver Tablero de Compras"
                }
            })

            .state("app.tableroventas", {
                url: "/tableroventas",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroventas.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroventas/form.html",
                controller: "TableroVentaFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Ventas",
                    description: "Ver Tablero de Ventas"
                }
            })

            .state("app.tableroestadocuentaproveedores", {
                url: "/tableroestadocuentaproveedores",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroestadocuentaproveedores.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroestadocuentaproveedores/form.html",
                controller: "TableroEstadoCuentaProveedoresFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Estado de Cuenta de Proveedores",
                    description: "Ver Tablero de Estado de Cuenta de Proveedores"
                }
            })

            .state("app.tableroestadocuentaclientes", {
                url: "/tableroestadocuentaclientes",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroestadocuentaclientes.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroestadocuentaclientes/form.html",
                controller: "TableroEstadoCuentaClientesFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Estado de Cuenta de Clientes",
                    description: "Ver Tablero de Estado de Cuenta de Clientes"
                }
            })

            .state("app.tableroremisiones", {
                url: "/tableroremisiones",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroremisiones.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroremisiones/form.html",
                controller: "TableroRemisionesFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Remisiones",
                    description: "Ver Tablero de Remisiones"
                }
            })

            .state("app.tableropreciosexistencia", {
                url: "/tableropreciosexistencia",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableropreciosexistencia.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableropreciosexistencia/form.html",
                controller: "TableroPreciosExistenciaFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Precios y Existencia",
                    description: "Ver Tablero de Precios y Existencia"
                }
            })

            .state("app.tableroexistencia", {
                url: "/tableroexistencia",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroexistencia.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroexistencia/form.html",
                controller: "TableroExistenciaFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Existencia",
                    description: "Ver Tablero de Existencia"
                }
            })

            .state("app.tablerovendedoresclientes", {
                url: "/tablerovendedoresclientes",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tablerovendedoresclientes.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tablerovendedoresclientes/form.html",
                controller: "TableroVendedoresClientesFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Vendedores y sus clientes",
                    description: "Ver Tablero de Vendedores y sus clientes"
                }
            })

            .state("app.tableroretencionesrecibidas", {
                url: "/tableroretencionesrecibidas",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroretencionesrecibidas.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroretencionesrecibidas/form.html",
                controller: "TableroRetencionesRecibidasFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Retenciones Recibidas ",
                    description: "Ver Tablero de Retenciones Recibidas "
                }
            })

            .state("app.tableroruteo", {
                url: "/tableroruteo",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroruteo.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroruteo/form.html",
                controller: "TableroRuteoFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Ruteo",
                    description: "Ver Tablero de Ruteo"
                }
            })

            .state("app.tableroproductos", {
                url: "/tableroproductos",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroproductos.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroproductos/form.html",
                controller: "TableroProductosFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Productos",
                    description: "Ver Tablero de Productos"
                }
            })

            .state("app.tableroeerrcentrocosto", {
                url: "/tableroeerrcentrocosto",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroeerrcentrocosto.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroeerrcentrocosto/form.html",
                controller: "TableroEerrCentroCostoFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de EERR por Centro de Costo",
                    description: "Ver Tablero de EERR por Centro de Costo"
                }
            })

            .state("app.tablerocuotasvencimientoclientes", {
                url: "/tablerocuotasvencimientoclientes",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tablerocuotasvencimientoclientes.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tablerocuotasvencimientoclientes/form.html",
                controller: "TableroCuotasVencimientoClientesFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Cuotas con Vencimientos de Clientes",
                    description: "Ver Tablero de Cuotas con Vencimientos de Clientes"
                }
            })

            .state("app.tablerocuotasvencimientoproveedores", {
                url: "/tablerocuotasvencimientoproveedores",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tablerocuotasvencimientoproveedores.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tablerocuotasvencimientoproveedores/form.html",
                controller: "TableroCuotasVencimientoProveedoresFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Cuotas con Vencimiento de Proveedores",
                    description: "Ver Tablero de Cuotas con Vencimiento de Proveedores"
                }
            })

            .state("app.tablerosaldosbancarios", {
                url: "/tablerosaldosbancarios",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tablerosaldosbancarios.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tablerosaldosbancarios/form.html",
                controller: "TableroSaldosBancariosFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Saldos Bancarios",
                    description: "Ver Tablero de Saldos Bancarios"
                }
            })

            .state("app.tableroproyectos", {
                url: "/tableroproyectos",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroproyectos.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroproyectos/form.html",
                controller: "TableroProyectosFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Proyectos",
                    description: "Ver Tablero de Proyectos"
                }
            })

            .state("app.tableroejecucionproyecto", {
                url: "/tableroejecucionproyecto",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroejecucionproyecto.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroejecucionproyecto/form.html",
                controller: "TableroEjecucionProyectoFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Ejecución de Proyectos",
                    description: "Ver Tablero de Ejecución de Proyectos"
                }
            })

            .state("app.tableroejecucionocopproyecto", {
                url: "/tableroejecucionocopproyecto",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tableroejecucionocopproyecto.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroejecucionocopproyecto/form.html",
                controller: "TableroEjecucionOCOPProyectoFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Ejecución OC/OP por Proyecto",
                    description: "Ver Tablero de Ejecución OC/OP por Proyecto"
                }
            })

            .state("app.tablerostatuscomprobanteventa", {
                url: "/tablerostatuscomprobanteventa",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.tablerostatuscomprobanteventa.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tablerostatuscomprobanteventa/form.html",
                controller: "TableroStatusComprobanteVentaFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Status de Comprobantes de Venta",
                    description: "Ver Tablero de Status de Comprobantes de Venta"
                }
            })

            .state("app.vistasqualita", {
                url: "/vistasqualita",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })

            .state("app.vistasqualita.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/vistasqualita/consulta.html",
                // templateUrl: 'views/directives/vistas-qualita.html',
                controller: "VistasqualitaFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Vistas de Qualita",
                    description: "Descargar Vistas Qualita"
                }
            })
            .state("app.tablerosuscripcionrecurrente", {
                url: "/tablerosuscripcionrecurrente",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })
            .state("app.tablerosuscripcionrecurrente.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tablerosuscripcionesrecurrentes/form.html",
                controller: "TableroSuscripcionRecurrenteFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Suscripciones Recurrentes",
                    description: "Ver Tablero de Suscripciones Recurrentes"
                }
            })
            .state("app.conciliacionesqualita", {
                url: "/conciliacionesqualita",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica",
                    skip: true
                }
            })

            .state("app.conciliacionesqualita.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/conciliacionesqualita/consulta.html",
                controller: "ConciliacionesQualitaFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Conciliaciones de Qualita",
                    description: "Descargar reporetes de conciliaciones"
                }
            })

            .state("app.tableroprecioexitenciacostos", {
                url: "/tableroprecioexistenciacostos",
                templateUrl: "views/template_base/index.html",
                abstract: true,
                ncyBreadcrumb: {
                    label: "Analitica"
                }
            })
            .state("app.tableroprecioexitenciacostos.list", {
                url: "",
                templateUrl: "qualita-components/analitica/views/tableroprecioexistenciacostos/form.html",
                controller: "TableroPrecioExitenciaCostosFormCtrl",
                controllerAs: "vm",
                ncyBreadcrumb: {
                    label: "Tablero de Precios, Existencia y Costos",
                    description: "Ver Tablero de Precios, Existencia y Costos"
                }
            })
        }
    ]);

'use strict';

angular.module('qualita.analitica')
    .controller('TableroCompraFormCtrl', TableroCompraFormCtrl);

    TableroCompraFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroCompraFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Compras";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_compra').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroVentaFormCtrl', TableroVentaFormCtrl);

    TableroVentaFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroVentaFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Ventas";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_venta').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroEstadoCuentaProveedoresFormCtrl', TableroEstadoCuentaProveedoresFormCtrl);

    TableroEstadoCuentaProveedoresFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroEstadoCuentaProveedoresFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Estado de Cuenta de Proveedores";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_edc_proveedores').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroEstadoCuentaClientesFormCtrl', TableroEstadoCuentaClientesFormCtrl);

    TableroEstadoCuentaClientesFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroEstadoCuentaClientesFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Estado de Cuenta de Clientes";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_edc_clientes').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroRemisionesFormCtrl', TableroRemisionesFormCtrl);

    TableroRemisionesFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroRemisionesFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero Tablero de Remisiones";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_remisiones').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}

'use strict';

angular.module('qualita.analitica')
    .controller('TableroPreciosExistenciaFormCtrl', TableroPreciosExistenciaFormCtrl);

    TableroPreciosExistenciaFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroPreciosExistenciaFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Precios y Existencia";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_precios_existencia').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}

'use strict';

angular.module('qualita.analitica')
    .controller('TableroExistenciaFormCtrl', TableroExistenciaFormCtrl);

    TableroExistenciaFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroExistenciaFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Existencia";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_existencia').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}

'use strict';

angular.module('qualita.analitica')
    .controller('TableroVendedoresClientesFormCtrl', TableroVendedoresClientesFormCtrl);

    TableroVendedoresClientesFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroVendedoresClientesFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Vendedores y sus clientes";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_vendedores_clientes').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}

'use strict';

angular.module('qualita.analitica')
    .controller('TableroRetencionesRecibidasFormCtrl', TableroRetencionesRecibidasFormCtrl);

    TableroRetencionesRecibidasFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroRetencionesRecibidasFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Retenciones Recibidas";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_retenciones_recibidas').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}

'use strict';

angular.module('qualita.analitica')
    .controller('TableroRuteoFormCtrl', TableroRuteoFormCtrl);

    TableroRuteoFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroRuteoFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero Tablero de Ruteo";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_ruteo').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}

'use strict';

angular.module('qualita.analitica')
    .controller('TableroProductosFormCtrl', TableroProductosFormCtrl);

    TableroProductosFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroProductosFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Productos";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_tablero_productos').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroEerrCentroCostoFormCtrl', TableroEerrCentroCostoFormCtrl);

    TableroEerrCentroCostoFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroEerrCentroCostoFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de EERR por Centro de Costo";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_tablero_eerr_centro_costo').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroCuotasVencimientoClientesFormCtrl', TableroCuotasVencimientoClientesFormCtrl);

    TableroCuotasVencimientoClientesFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroCuotasVencimientoClientesFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Cuotas con Vencimientos de Clientes";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_tablero_cuota_vencimiento_clientes').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroCuotasVencimientoProveedoresFormCtrl', TableroCuotasVencimientoProveedoresFormCtrl);

    TableroCuotasVencimientoProveedoresFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroCuotasVencimientoProveedoresFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Cuotas con Vencimiento de Proveedores";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_tablero_cuota_vencimiento_proveedores').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroSaldosBancariosFormCtrl', TableroSaldosBancariosFormCtrl);

    TableroSaldosBancariosFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroSaldosBancariosFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Saldos Bancarios";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_tablero_saldo_bancario').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroProyectosFormCtrl', TableroProyectosFormCtrl);

    TableroProyectosFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroProyectosFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Proyectos";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_tablero_proyectos').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroEjecucionProyectoFormCtrl', TableroEjecucionProyectoFormCtrl);

TableroEjecucionProyectoFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroEjecucionProyectoFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Ejecución de Proyectos";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_ejecucion_proyecto').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
        });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroEjecucionOCOPProyectoFormCtrl', TableroEjecucionOCOPProyectoFormCtrl);

TableroEjecucionOCOPProyectoFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroEjecucionOCOPProyectoFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Ejecución OC/OP por Proyecto";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_ejecucion_oc_op_proyecto').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
        });
    }
}
'use strict';

angular.module('qualita.analitica')
    .controller('TableroStatusComprobanteVentaFormCtrl', TableroStatusComprobanteVentaFormCtrl);

TableroStatusComprobanteVentaFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroStatusComprobanteVentaFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Status de Comprobantes de Venta";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_status_comprobante_venta').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
        });
    }
}
'use strict';


angular.module('qualita.analitica')
  .controller('VistasqualitaFormCtrl',
    ["$scope", "ProductoAlmacenFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "notify", "UtilFactory", "WatchLocacionChangeFactory", "$filter", "$rootScope", "CsvFactory", "baseurl", "TiposTipoFactory", "VistaFactory", function ($scope, ProductoAlmacenFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal,
      $window, $state, notify, UtilFactory, WatchLocacionChangeFactory, $filter, $rootScope, CsvFactory, baseurl, TiposTipoFactory, VistaFactory) {

      var vm = this;
    //   vm.uiBlockuiConfig = {
    //     'bloquear': false,
    //     'message': 'Descargando Csv...'
    //   };

    vm.searchVistasFilter = searchVistasFilter;


    vm.vistasQualita = TiposTipoFactory.get("vistas_qualita");
    console.log("vistas: " + vm.vistasQualita);

    function searchVistasFilter(criteria) {
        return function (item) {
            if (!criteria) {
            return true;
            }
            return (
            (item.codigo + "").indexOf(criteria.toLowerCase()) >
            -1 ||
            item.descripcion
                .toLowerCase()
                .indexOf(criteria.toLowerCase()) > -1
            );
        };
    };

    $scope.generarCSV = function () {
      if (vm.nombreVista == undefined || vm.nombreVista == null) {
        notify({ message: 'Se debe elegir una vista para generar el CSV.', classes: 'alert-danger', position: 'right' });
       } 
      
        var filtersObj = {};
        // filtersObj.nombreVista = '';
        //marca
        filtersObj.nombreVista = vm.nombreVista.codigo;
        
        VistaFactory.getVistasCSV(filtersObj).then(function (response) {
          var fileName = response.data.fileName;
          $window.open(baseurl.getPublicBaseUrl() + fileName);
        });
      
    };
    $scope.limpiarFiltros = function() {
      vm.nombreVista = undefined;
    
    }

  }]);

'use strict';

angular.module('qualita.analitica')
    .controller('TableroSuscripcionRecurrenteFormCtrl', TableroSuscripcionRecurrenteFormCtrl);

    TableroSuscripcionRecurrenteFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroSuscripcionRecurrenteFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Sucripción Recurrente";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_suscripciones_recurrentes').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
         });
    }
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.transaccionimportacion
 * @description
 * # transaccionimportacion
 * Factory in the qualita.
 */
angular.module('qualita.analitica')
  .factory('VistaFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
    function($resource,  baseurl, filterFactory, TiposFactory, $http) {
      //console.log('dentro del factory ------------------------');

  	var vista = $resource( baseurl.getBaseUrl() + '/vistaService/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
      /*,getSaldos: {
        method: 'GET',
        url: baseurl.getBaseUrl() + "/saldos",
        isArray: true
      }*/
    });

    return {

      all: function(params, view) {
        if (params) {
          params.view = view != undefined || params.view != undefined ? (view || params.view) : 'BaseList';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'BaseList';
        }
        return vista.query(params);
      },

      get: function(id) {
        return vista.get({id: id});
      },

      create: function(attrs) {
        return new vista(attrs);
      },

      save: function(vista) {
        return (vista.id) ? vista.$update() : vista.$save();
      },

      remove: function(vista) {
        return vista.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      },

    //   obtenerSaldos: function(ids, date) {
    //     return $http({
    //             url: baseurl.getBaseUrl() + '/productoalmacen/saldos',
    //             method: "GET",
    //             params: {listDetalles: ids, fecha: date, view: 'importacionView'}
    //           }).then(function (data) {
    //             return data;
    //           },
    //           function (status) {
    //             console.log("Error");
    //             //notify({ message: 'Ha ocurrido un error en el servidor', classes: 'alert-danger', position: 'right' });
    //           });
    //   },
      getVistasCSV: function(searchParams) {
        var params = {};
        if (searchParams) {
            params.query = decodeURIComponent($.param(searchParams));
         }
        return $http.post(baseurl.getBaseUrl() + "/vistaService/vistas/csv?" + params.query);
      }
    };
  }]);

'use strict';


angular.module('qualita.analitica')
    .controller('ConciliacionesQualitaFormCtrl',
        ["$scope", "$window", "notify", "baseurl", "$filter", "TiposTipoFactory", "ConciliacionesQualitaFactory", function ($scope, $window, notify, baseurl, $filter, TiposTipoFactory, ConciliacionesQualitaFactory) {

            var vm = this;
            vm.curDate = new Date();
            vm.submited = false;
            vm.uiBlockuiConfig = {
                'bloquear': false,
                'message': 'Generando reporte de conciliación...'
              };

            vm.operaciones = TiposTipoFactory.get("conciliaciones_qualita");
            vm.tipoOperaciones = TiposTipoFactory.get("tipo_conciliaciones_qualita");

            $scope.generarCSV = function () {
                vm.submited = true;
                if ($scope.ConciliacionesForm.$valid) {
                    vm.uiBlockuiConfig.bloquear = true;
                    var filtersObj = {};
                    filtersObj.operacion = vm.operacion.codigo;
                    filtersObj.tipooperacion = vm.tipoOperacion.codigo; 
                    if(vm.fechaDesde){
                        filtersObj.fechadesde = $filter('date')(vm.fechaDesde, 'yyyy-MM-dd');
                    }
                    if(vm.fechaHasta){
                        filtersObj.fechahasta = $filter('date')(vm.fechaHasta, 'yyyy-MM-dd');
                    }

                    ConciliacionesQualitaFactory.getConciliacionCSV(filtersObj).then(function (response) {
                        vm.uiBlockuiConfig.bloquear = false;
                        var fileName = response.data.fileName;
                        if (fileName) {
                            $window.open(baseurl.getPublicBaseUrl() + fileName);
                        } else {
                            notify({ message: response.data.message, classes: 'alert-warning', position: 'right' });
                        }
                    }).catch(function (error) {
                        vm.uiBlockuiConfig.bloquear = false;
                        notify({ message: 'Se produjo un error al generar el CSV.', classes: 'alert-danger', position: 'right' });
                    });
                    
                } else {
                    vm.uiBlockuiConfig.bloquear = false;
                    var msg = $filter('translate')('ERROR_FILTERING');
                    notify({ message: msg, classes: 'alert-warning', position: 'right' });
                }
            };
            $scope.limpiarFiltros = function () {
                vm.operacion = undefined;
                vm.tipoOperacion = undefined;
                vm.fechaHasta = undefined;
                vm.fechaDesde = undefined;
            }
        }]);

'use strict';

angular.module('qualita.analitica')
  .factory('ConciliacionesQualitaFactory', ['$resource', 'baseurl', '$http',
    function($resource,  baseurl, $http) {

  	var conciliaciones = $resource( baseurl.getBaseUrl() + '/conciliacionesqualita/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {

      all: function(params, view) {
        if (params) {
          params.view = view != undefined || params.view != undefined ? (view || params.view) : 'BaseList';
        } else {
          params = [];
          params.view = view!=undefined ? view : 'BaseList';
        }
        return vista.query(params);
      },

      get: function(id) {
        return vista.get({id: id});
      },

      create: function(attrs) {
        return new vista(attrs);
      },

      save: function(vista) {
        return (vista.id) ? vista.$update() : vista.$save();
      },

      remove: function(vista) {
        return vista.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      },

      getConciliacionCSV: function(searchParams) {
        var queryString = '';
        if (searchParams) {
            Object.keys(searchParams).forEach(function(key) {
                var value = searchParams[key];
                if (value instanceof Date) {
                    // Formatear la fecha como 'yyyy-MM-dd'
                    value = value.toISOString().split('T')[0];
                }
                queryString += key + '=' + encodeURIComponent(value) + '&';
            });
            // Eliminar el último '&' de la cadena de consulta
            queryString = queryString.slice(0, -1);
        }
        return $http.post(baseurl.getBaseUrl() + "/conciliacionesqualita/conciliacion/csv?" + queryString);
    }
    
    };
  }]);

'use strict';

angular.module('qualita.analitica')
    .controller('TableroPrecioExitenciaCostosFormCtrl', TableroPrecioExitenciaCostosFormCtrl);

    TableroPrecioExitenciaCostosFormCtrl.$inject = ["ParametrosFactory", "$sce"];

function TableroPrecioExitenciaCostosFormCtrl(ParametrosFactory, $sce) {

    var vm = this;

    activate();

    function activate() {
        vm.title = "Tablero de Precios, Existencia y Costos";
        vm.width = screen.width;
        vm.height = screen.height;
        ParametrosFactory.getByCodigo('fuente_tablero_precio_existencia_costos').then(function (response) {
            vm.parametro = $sce.trustAsResourceUrl(response.valor);
        });
    }
}
angular.module('qualita.menujson', [])
  .config(
    ['$stateProvider', '$urlRouterProvider', '$httpProvider', '$translateProvider', '$urlMatcherFactoryProvider',
      function ($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider, $urlMatcherFactoryProvider) {
        $urlMatcherFactoryProvider.strictMode(false);
        $urlRouterProvider.otherwise('/main');
        $stateProvider
        .state('app.menujson', {
            url: '/configuracionmenu',
            templateUrl: 'views/template_base/index.html',
            abstract: true,
            ncyBreadcrumb: {
                label: 'Configuración'
            }
        })
        .state('app.menujson.list', {
            url: '',
            templateUrl: 'views/template_base/list.html',
            controller: 'MenuJsonIndexCtrl',
            ncyBreadcrumb: {
                parent: 'app.menujson',
                label: 'Administración del menú',
                description: 'Listado de Módulos y Formularios',
                skip: true
            }
        })
      }]);

function menuJsonPrepService(MenuJsonFactory, $stateParams) {
  return MenuJsonFactory.get($stateParams.id, "BaseForm").$promise;
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.parametros
 * @description
 * # parametros
 * Factory in the qualita.
 */
angular.module('qualita.menujson')
  .factory('MenuJsonFactory', ['TiposFactory', 'filterFactory', '$resource', 'baseurl',
    function (TiposFactory, filterFactory, $resource, baseurl) {
      var bandera = false;
      var MenuJson = $resource(baseurl.getBaseUrl() + '/menujson/:id', { id: '@id' }, {
        update: {
          method: 'PUT'
        },
        getMenuJson: {
          method: 'GET',
          url: baseurl.getBaseUrl() + "/menujson/getmenujson",
          isArray: true
        },
        cambiarVisibilidad: {
            method: "PUT",
            url: baseurl.getBaseUrl() + "/menujson/visibilidad/:id",
            isArray: true
        }
      });
 
      return {
        all: function (params, view) {
          if (params) {
            params.view = view != undefined ? view : 'BaseList';
          }
          return MenuJson.query(params);
        },

        getMenuJson: function() {
          return MenuJson.getMenuJson();
        },

        get: function (id, view) {
          if (typeof (view) === 'undefined') view = "MenuList";
          return MenuJson.get({ id: id, view: view });
        },

        create: function (attrs) {
          return new MenuJson(attrs);
        },

        save: function (menuJson) {
          return (menuJson.id) ? menuJson.$update() : menuJson.$save();
        },

        remove: function (menuJson) {
          return menuJson.$remove();
        },
        cambiarVisibilidad: function(id) {
          return MenuJson.cambiarVisibilidad({ id: id }).$promise;
        },
      };
    }]);

/**
 * Created by codiumsa on 19/10/15.
 */
angular
  .module("qualita.menujson")
  .factory("MenuJsonLangFactory", MenuJsonLangFactory);

  MenuJsonLangFactory.$inject = [
  "$resource",
  "baseurl",
  "$translatePartialLoader",
  "$translate"
];

function MenuJsonLangFactory(
  $resource,
  baseurl,
  $translatePartialLoader,
  $translate
) {
  var translationsNeeded = [
    "BACK",
    "NOMBRE",
    "ESTADO"
  ];

  var service = {
    getTranslations: getTranslations
  };

  return service;

  function getTranslations() {
    $translatePartialLoader.addPart("menujson");
    return $translate.refresh().then(function() {
      return $translate(translationsNeeded);
    });
  }
}

'use strict';


angular.module('qualita.menujson')
  .controller('MenuJsonIndexCtrl', ["$scope", "MenuJsonFactory", "filterFactory", "ReportTicketFactory", "$window", "MenuJsonLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "$modal", "$state", "$http", "$rootScope", function ($scope, MenuJsonFactory, filterFactory, ReportTicketFactory, $window,
    MenuJsonLangFactory, $filter, UtilFactory, CsvFactory, baseurl,$modal, $state,$http,$rootScope) {

    var defaultColumnOrder = ['id','nombre', 'estado','icono','clase','visible','ocultable'];
    MenuJsonLangFactory.getTranslations().then(function (translations) {
      $scope.options = {
        'resource': 'menujson',
        'title': 'Menú',
        'factory': MenuJsonFactory,
        'view': 'MenuList',
        'defaultColumnOrder': defaultColumnOrder,
        'columns': [
          { 'data': 'id', 'title': $filter('translate')('ID')},
          { 'data': 'nombre', 'title': $filter('translate')('NOMBRE') },
          { 'data': 'estado', 'title': $filter('translate')('ESTADO') },
          { 'data': 'icono', 'title': $filter('translate')('ICONO') },
          { 'data': 'clase', 'title': $filter('translate')('CLASE') },
          { 'data': 'visible', 'title': $filter('translate')('VISIBLE'),'searchable': false, 'type': 'combo', 'filterUrl': 'tipos/filtro/boolean_values' }
        ],
        //'detailRows': []
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "asc",
        'hideEditMenu': true,
        'hideRemoveMenu': true,
        'hideViewMenu': true,
        'hideAddMenu': true,
        'extraMenuOptions':
        [
          {
            'title': 'Reporte',
            'icon': 'glyphicon glyphicon-file',
            'showCondition': function() { return true; },
            'action': function() {
              var filters = createFilters($scope.options.getFilters());
              ReportTicketFactory.ticket('listadoMenuJson', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                $window.open($scope.pdfDownloadURL, '_blank');
              });
            }
          },
          {
            'title': 'CSV',
            'icon': 'glyphicon glyphicon-export',
            'showCondition': function() { return true; },
            'action': function() {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("menujson", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                .then(function(response) {
                  console.log("response de csv: ", response);
                  var fileName = response.data.fileName;
                  $window.open(baseurl.getPublicBaseUrl() + fileName);
                });
              }
          }
        ],
        'extraRowOptions':
        [
          {
            templateToRender: "<button class='btn btn-success' style='margin-right: 5px;' title='" + $filter('translate')('MOSTRAR') + "' ng-click='ocultar(<%=dataId%>)' ng-class='{ hidden : !canMostrar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-eye-open'></span> </button>"
            ,customAttribute : ["visible","ocultable"],
            conditionName:"canMostrar",
            conditionDef: function (estado) {
                if(estado[0] == 'No' && estado[1] == 'Sí'){
                    return true;
                }else{
                    return false;
                }
            },
            functionName: "ocultar",
            functionDef: function (itemId) {
              $scope.selectedItemId = itemId;
              $scope.tituloModal = $filter('translate')('MENU_SHOW_CONFIRMATION');
              $scope.mensajeModal = $filter('translate')('MENU_SHOW_WARNING');

              $scope.modalInstanceCambiarVisibilidad = $modal.open({
                template: '<div class="modal-header">' +
                  '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                  '</div>' +
                  '<div class="modal-body">{{::mensajeModal}}' +
                  '</div>' +
                  //'<div class="modal-body">{{::usuarioEliminar}}</div>'+
                  '<div class="modal-footer">' +
                  '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                  '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                  '</div>',
                scope: $scope
              });

              $scope.cancel = function () {
                $scope.modalInstanceCambiarVisibilidad.dismiss('cancel');
              };

              $scope.ok = function (itemId) {
                var model = MenuJsonFactory.create({ id: itemId });
                MenuJsonFactory.cambiarVisibilidad(model.id).then(function(response) {
                  $rootScope.mainMenu = response;
                  $scope.modalInstanceCambiarVisibilidad.close(itemId);
                  $state.go($state.current, {}, { reload: true });
                },function (error) {
                  $scope.modalInstanceCambiarVisibilidad.dismiss('cancel');
                  $scope.tituloModal = $filter('translate')('CAMBIAR_VISIBILIDA_FAILED');
                  $scope.mensajeModal = $scope.options.failedDeleteError;
                  var modalInstance = $modal.open({
                    template: '<div class="modal-header">' +
                      '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                      '</div>' +
                      '<div class="modal-body">{{::mensajeModal}}</div>' +
                      '<div class="modal-footer">' +
                      '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                      '</div>',
                    scope: $scope
                  });
                  $scope.cancel = function () {
                    modalInstance.dismiss('cancel');
                  };
                  console.log("error al borrar: ");
                  console.log(error);
                });
              }
            }
         },
          {
            templateToRender: "<button class='btn btn-danger' style='margin-right: 5px;' title='" + $filter('translate')('OCULTAR') + "'ng-click='mostrar(<%=dataId%>)' ng-class='{ hidden : !canOcultar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-eye-close'></span> </button>"
            ,customAttribute : ["visible","ocultable"],
            conditionName:"canOcultar",
            conditionDef: function (estado) {
                if(estado[0] == 'Sí' && estado[1] == 'Sí'){
                    return true;
                }else{
                    return false;
                }
            },
            functionName: "mostrar",
            functionDef: function (itemId) {
              $scope.selectedItemId = itemId;
              $scope.tituloModal = $filter('translate')('MENU_HIDE_CONFIRMATION');
              $scope.mensajeModal = $filter('translate')('MENU_HIDE_WARNING');

              $scope.modalInstanceCambiarVisibilidad = $modal.open({
                template: '<div class="modal-header">' +
                  '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                  '</div>' +
                  '<div class="modal-body">{{::mensajeModal}}' +
                  '</div>' +
                  //'<div class="modal-body">{{::usuarioEliminar}}</div>'+
                  '<div class="modal-footer">' +
                  '<button class="btn btn-primary" ng-click="ok(selectedItemId); cancel()">' + $filter('translate')('OK') + '</button>' +
                  '<button class="btn btn-warning" ng-click="cancel()">' + $filter('translate')('CANCEL') + '</button>' +
                  '</div>',
                scope: $scope
              });

              $scope.cancel = function () {
                $scope.modalInstanceCambiarVisibilidad.dismiss('cancel');
              };

              $scope.ok = function (itemId) {
                var model = MenuJsonFactory.create({ id: itemId });
                MenuJsonFactory.cambiarVisibilidad(model.id).then(function(response) {
                  $rootScope.mainMenu = response;
                  $scope.modalInstanceCambiarVisibilidad.close(itemId);
                  $state.go($state.current, {}, { reload: true });
                }, function (error) {
                  $scope.modalInstanceCambiarVisibilidad.dismiss('cancel');
                  $scope.tituloModal = $filter('translate')('CAMBIAR_VISIBILIDA_FAILED');
                  $scope.mensajeModal = $scope.options.failedDeleteError;
                  var modalInstance = $modal.open({
                    template: '<div class="modal-header">' +
                      '<h3 class="modal-title">{{::tituloModal}}</h3>' +
                      '</div>' +
                      '<div class="modal-body">{{::mensajeModal}}</div>' +
                      '<div class="modal-footer">' +
                      '<button class="btn btn-primary" ng-click="cancel()">' + $filter('translate')('OK') + '</button>' +
                      '</div>',
                    scope: $scope
                  });
                  $scope.cancel = function () {
                    modalInstance.dismiss('cancel');
                  };
                  console.log("error al borrar: ");
                  console.log(error);
                });
              }
            }
         }
         
        ]
      };
    });

    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        if(search === 'No'){
          search = 'false';
        }
        if(search === 'Sí'){
          search = 'true';
        }
        filtersArr.push({path: data, like: search})
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }
  }]);

'use strict';

angular.module('qualita.menujson')
  .controller('MenuFormController', ['$rootScope', '$scope', 'MenuJsonLangFactory',
    function($rootScope, $scope, MenuJsonLangFactory) {

      activate();
 

      function activate() {
        MenuJsonLangFactory.getTranslations().then(function(translations) {
          $scope.translations = translations;
          $rootScope.isProcessing = false;
        });

      }
    }
  ]);

"use strict";

angular
  .module("qualita.contabilidad")
  .controller("ReEnvioFormCtrl", ReEnvioFormCtrl);

ReEnvioFormCtrl.$inject = [
  "$rootScope",
  "$scope",
  "ReenvioFactory",
  "$state",
  "notify",
  "ContabilidadLangFactory",
  "$filter",
  "$modal"
];

function ReEnvioFormCtrl(
  $rootScope,
  $scope,
  ReenvioFactory,
  $state,
  notify,
  ContabilidadLangFactory,
  $filter,
  $modal
) {

  var vm = this;
  $rootScope.isProcessing = false;

  activate();

  function activate() {

    ContabilidadLangFactory.getTranslations().then(function(translations) {
      vm.translations = translations;
    });

    vm.title = $filter("translate")("REENVIO_COSTOS");
    vm.cuentaContable = {};

    $rootScope.isProcessing = false;
    vm.submited = false;
  }

  vm.cancel = cancel;
  vm.submit = submit;

  function submit() {
    vm.submited = true;
    if ($scope.ReenvioForm.$valid) {
      
    /* formFactory.defaultNSFSubmit($scope.ReenvioForm, CuentaContableFactory, vm.cuentaContable)
    .then( function(response) {
          // redirectToList();
        },
        function(error) {
          console.log(error);
        }
      );*/

      if(vm.fechaHasta < vm.fechaDesde ){
        notify({
          message: $filter("translate")("DATE_FROM_TO_ERROR"),
          classes: "alert-danger",
          position: "right"
        });
        return;
      }else{
        if(vm.fechaDesde.getUTCMonth() != vm.fechaHasta.getUTCMonth() ||
          vm.fechaDesde.getUTCFullYear() != vm.fechaHasta.getUTCFullYear()){
            notify({
              message: $filter("translate")("DATE_RANGE_ERROR"),
              classes: "alert-danger",
              position: "right"
          });
          return;
        }
      }

      // crear MODAL, mostrar mensaje PROCESANDO
      var modalInstance = $modal.open({
        templateUrl: 'views/directives/information-modal.html',
        scope: $scope
      });

      $scope.mensajeModal = "Procesando envio ...";


      ReenvioFactory.enviar(vm.fechaDesde, vm.fechaHasta).then( function(response) {
        // cambiar texto del modal, Envio Exitoso
        modalInstance.close();

        var modalInstance2 = $modal.open({
            templateUrl: "views/directives/notification-modal.html",
            scope: $scope
        });

        $scope.mensajeModal = "Proceso finalizado con éxito";
        $scope.ok = function () {
            modalInstance2.close();
            $state.go('app.reenvio.list');
        }
      },
      function(error) {
        // cambiar texto del modal, Envio Fallido
        console.log(error);

        modalInstance.close();

        var modalInstance2 = $modal.open({
            templateUrl: "views/directives/notification-modal.html",
            scope: $scope
        });

        $scope.mensajeModal = "ERROR. No se enviaron registros.";
        $scope.ok = function () {
            modalInstance2.close();
            $state.go('app.reenvio.list');
        }

      });

    }else {
      console.log("Form invalid");
      console.log($scope.ReenvioForm);
      notify({
        message: $filter("translate")("WRONG_FIELDS"),
        classes: "alert-danger",
        position: "right"
      });
    }
  }

  function cancel() {
    $state.go("app.main");
  }

}

'use strict';

/**
 * @ngdoc service
 * @name qualita.ReenvioFactory
 * @description
 * # ReenvioFactory
 * Factory in the qualita.
 */
angular.module('qualita.contabilidad')
  .factory('ReenvioFactory', ['baseurl', '$http', '$translatePartialLoader', '$translate',
    function(baseurl, $http) {

  	/*var ReenvioFactory= $resource( baseurl.getBaseUrl() + '/entidades/', null, {
      update: {
        method: 'PUT'
      },
    });*/

    return {

      enviar: function (fechaDesde, fechaHasta) {

        // Conversion final, antes de enviar las fechas al backend
        fechaDesde = moment(fechaDesde).format("YYYY-MM-DD");
        fechaHasta = moment(fechaHasta).format("YYYY-MM-DD");

        return $http({
          url: baseurl.getBaseUrl() + "/entidades/reenviar",
          method: "POST",
          params: { "fechaDesde": fechaDesde,
                    "fechaHasta": fechaHasta
          }
        });
      }
    };
  }]);

"use strict";

angular
        .module("qualita.venta")
        .controller("ConsultaPlanillaIndexCtrl", ["VentasLangFactory", "$scope", "filterFactory", "ReportTicketFactory", "$window", "notify", "$filter", "SucursalesFactory", function(VentasLangFactory,
    $scope,
    filterFactory,
    ReportTicketFactory,
    $window,
    notify,
    $filter,
    SucursalesFactory
){
    var vm = this;
    //vm.nroPlanillaChanged = nroPlanillaChanged;
    vm.obtenerReporte = obtenerReporte;
    vm.sucursales = SucursalesFactory.all();
    activate();

    function activate() {
        vm.fecha = null;
        vm.nroPlanilla = null;

        //vm.nroPlanillaDeshabilitar = false;
        VentasLangFactory.getTranslations().then(function(translations) {
            $scope.translations = translations;
        });
    }

    /*function nroPlanillaChanged(){
        if(vm.nroPlanilla == ""){
            vm.nroPlanillaDeshabilitar = false;
        }else{
            vm.fecha = null;
            vm.nroPlanillaDeshabilitar = true;
        }
    }*/

    function obtenerReporte(){

        vm.submited = true;
        if ($scope.ConsultaPlanillaForm.$valid) {

            var filtersArr = [];
            if(vm.nroPlanilla != null && vm.nroPlanilla != ""){
                filtersArr.push("numeroPlanilla=" + vm.nroPlanilla);
            }

            if(vm.sucursal != null && vm.sucursal != ""){
                filtersArr.push("sucursal=" + vm.sucursal.id);
            }

            if(vm.fecha != null){
                filtersArr.push("fecha=" + moment(vm.fecha).format("MM/DD/YYYY"));
            }

            if(filtersArr.length == 0){
                notify({
                    message: $filter("translate")("FILTRO_ERROR"),
                    classes: "alert-danger",
                    position: "right"
                });
                return;
            }

            vm.pdfDownloadURL = ReportTicketFactory.downloadCustomReport(
                "comprobantesplanilla",
                "pdf",
                filtersArr
            );
            $window.open(vm.pdfDownloadURL, '_blank');
        }
    }
}]);

'use strict';

angular.module('qualita.venta')
  .controller('SegmentoClienteIndexCtrl', ["SegmentoClienteFactory", "$scope", "VentasLangFactory", "UtilFactory", "$window", "CsvFactory", "baseurl", "filterFactory", "ReportTicketFactory", function SegmentoClienteIndexCtrl(SegmentoClienteFactory,
    $scope, VentasLangFactory, UtilFactory, $window, CsvFactory, baseurl, filterFactory, ReportTicketFactory) {


    VentasLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'descripcion', 'codigo', 'nombre', 'activo'];

      $scope.options = {
        'resource': 'segmentos',
        'title': translations.LISTA_SEGMENTOS_CLIENTES,
        'factory': SegmentoClienteFactory,
        'view': 'BaseList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': translations.FAILED_DELETE_SEGMENTOS_CLIENTES,
        'columns': [
          { 'data': 'id', 'title': translations.CODE, 'visible': false },
          { 'data': 'codigo', 'title': translations.CODIGO_SEGMENTO},
          { 'data': 'nombre', 'title': translations.NOMBRE },
          { 'data': 'descripcion', 'title': translations.DESCRIPCION },
          { 'data': 'activo', 'title': translations.ACTIVO }
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'hideRemoveMenu': false,
        'hideViewMenu': false,
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = createFilters($scope.options.getFilters());
                  ReportTicketFactory.ticket('listadoSegmentos', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                    $window.open($scope.pdfDownloadURL, '_blank');
                  });
                }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("segmentos", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ],
        };
    });

    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({path: data, like: search})
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }

  }]);

"use strict";

angular.module("qualita.venta").controller("SegmentoClienteFormCtrl", [
  "$scope",
  "notify",
  "$state",
  "$rootScope",
  "segmentoClientePrepService",
  "VentasLangFactory",
  "$location",
  'SegmentoClienteFactory',
  'filterFactory',
  'TipoActividadRamoClienteFactory',
  'ListaPrecioVentaFactory',
  'ParametrosFactory',
  '$modal',
  function(
    $scope,
    notify,
    $state,
    $rootScope,
    segmentoClientePrepService,
    VentasLangFactory,
    $location,
    SegmentoClienteFactory,
    filterFactory,
    TipoActividadRamoClienteFactory,
    ListaPrecioVentaFactory,
    ParametrosFactory,
    $modal
  ) {
    VentasLangFactory.getTranslations().then(function(translations) {
      $scope.listaPreciosSeleccionada = [];
      $scope.habilitarDetalles = false;
      $scope.listaNivel = [
        {value: 1},
        {value: 2},
        {value: 3},
        {value: 4},
        {value: 5},
        {value: 6},
        {value: 7},
        {value: 8},
        {value: 9},
        {value: 10}
      ]
      var staticFilter = {};
      staticFilter.search = filterFactory.and([{
        path: 'estado.codigo',
        equals: 'lista_activada'
      }]).value();
      $scope.listaPrecios = ListaPrecioVentaFactory.all(staticFilter, "SegmentoClienteList")
      var param = {};
      param.search = filterFactory.and([
        {
          path: 'activo',
          like: 'true'
        }]
      ).value();
      $scope.tipoActividadesRamosClientes = TipoActividadRamoClienteFactory.all(param, "BaseForm");
      ParametrosFactory.getByCodigo("asignacion_lista_precios_linea_producto_por_segmento").then(function (r) {
        $scope.habilitarDetalles = r.valorTipo.codigo == "si";
      });
      ParametrosFactory.getByCodigo("nivel_linea_producto_asociar_lista_precio").then(function (r) {
        $scope.segmento.nivel = parseInt(r.valor)
      });
      $scope.detalleSegmento = "Detalles de la asociación";
      var defaultColumnOrder = ['codigo', 'nombre', 'listaPrecioVenta.descripcion', 'listaPrecioVenta.porcentajeRecargo'];

      var setDescripcionLista = function (data, e, row) {
        if ($scope.listaPreciosSeleccionada.length) {
          for (var i = 0; i < $scope.listaPreciosSeleccionada.length; i++) {
            if ($scope.listaPreciosSeleccionada[i].lineaProducto.id === row.id) {
              return $scope.listaPreciosSeleccionada[i].listaPrecioVenta.descripcion
            }
          }
        }
        return ""
      };
      var setPorcentajeLista = function (data, e, row) {
        if ($scope.listaPreciosSeleccionada.length) {
          for (var i = 0; i < $scope.listaPreciosSeleccionada.length; i++) {
            if ($scope.listaPreciosSeleccionada[i].lineaProducto.id === row.id) {
              return $scope.listaPreciosSeleccionada[i].listaPrecioVenta.porcentajeRecargo
            }
          }
        }
        return ""
      };
      $scope.options = {
        resource : 'lineasproducto',
        title: 'Detalles de la asociación',
        view: 'SegmentoClienteList',
        factory: SegmentoClienteFactory,
        defaultColumnOrder: defaultColumnOrder,
        columns: [
          { 'data': 'id', visible: false},
          { 'data': 'codigo', 'title': 'Código de la línea', 'renderWith': 'emptyRender', orderable: false},
          { 'data': 'nombre', 'title': 'Descripción de la línea', 'renderWith': 'emptyRender', orderable: false},
          { 'data': 'listaPrecioVenta.descripcion', 'title': 'Descripción de la lista', 'renderWith': setDescripcionLista, orderable: false, searchable:false },
          { 'data': 'listaPrecioVenta.porcentajeRecargo', 'title': 'Porcentaje de la lista', 'renderWith': setPorcentajeLista, orderable: false, searchable:false }
        ],
        hasOptions: true,
        defaultOrderColumn: 0,
        defaultOrderDir: "desc",
        hideAddMenu: true,
        hideEditMenu: true,
        hideRemoveMenu: true,
        hideViewMenu: true,
        extraRowOptions: [
          {
            templateToRender: "<button class='btn btn-info' style='margin-right: 5px;' title='" +
              translations.VIEW + "' ng-click='ver(<%=dataId%>)'> <span class='glyphicon glyphicon-eye-open'></span> </button>",
            functionName: "ver",
            functionDef: function (itemId) {
            }
          },
          {
            templateToRender: "<button class='btn btn-info' style='margin-right: 5px; background-color: rebeccapurple !important; border-color: rebeccapurple' " +
              "title='Asignar Lista' ng-click='asignar(<%=dataCustom%>)'> <span class='glyphicon glyphicon-log-in'></span> </button>",
            customAttribute: ["id"],
            functionName: "asignar",
            functionDef: function (attr) {
              $scope.tituloModal = "Asignación de lista de precios a líneas de productos"
              var modalInstance = $modal.open({
                templateUrl: 'qualita-components/ventas/views/segmentocliente/lista-precios-modal.html',
                scope: $scope
              });
              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
                $scope.vm.listaPrecio = undefined;
              };

              $scope.ok = function () {
                if($scope.listaPreciosSeleccionada.length){
                  var flg = false;
                  for(var i = 0; i < $scope.listaPreciosSeleccionada.length; i++){
                    if($scope.listaPreciosSeleccionada[i].lineaProducto.id === attr[0]){
                      $scope.listaPreciosSeleccionada[i] = {
                        id : $scope.listaPreciosSeleccionada[i].id,
                        lineaProducto: {
                          id : attr[0]
                        },
                        listaPrecioVenta: $scope.vm.listaPrecio
                      }
                      flg = true;
                      break;
                    }
                  }
                  if(!flg){
                    $scope.listaPreciosSeleccionada.push({
                      lineaProducto: {
                        id : attr[0]
                      },
                      listaPrecioVenta: $scope.vm.listaPrecio
                    })
                  }
                } else {
                  $scope.listaPreciosSeleccionada.push({
                    lineaProducto: {
                      id : attr[0]
                    },
                    listaPrecioVenta: $scope.vm.listaPrecio
                  })
                }
                modalInstance.close();
                $scope.vm.listaPrecio = undefined;
                $scope.options.reloadData();
              }
            }
          },
        ],
      };
      $scope.$watch('segmento.nivel', function(newValue){
        if(newValue){
          setTimeout(function (){
            $scope.options.staticFilter = {
              search: filterFactory
                .and([{
                  path: "nivel",
                  equals: $scope.segmento.nivel
                }])
                .value()
            };
            $scope.filtro = true;
          })
        }
      })
      $scope.resource = "segmentos";
      $scope.prepService = segmentoClientePrepService;
      $scope.submit = submit;
      $scope.cancel = cancel;
      $scope.submitSegmento = submitSegmento;
      $scope.translations = translations;

      $scope.newProperties = {
        state: "app.segmentos.new",
        title: translations.NEW_SEGMENTO_CLIENTE
      };
      $scope.editProperties = {
        state: "app.segmentos.edit",
        title: translations.EDIT_SEGMENTO_CLIENTE,
        entidad: "Segmento"
      };

      $scope.viewProperties = {
        state: "app.segmentos.view",
        title: translations.VIEW_SEGMENTO_CLIENTE,
        entidad: "Segmento"
      };


      $scope.activate = function() {
        if ($state.is($scope.newProperties.state)) {
          activateNew();
        } else if ($state.is($scope.editProperties.state)) {
          activateEdit();
        } else if ($state.is($scope.viewProperties.state)) {
          activateView();
        }

        $rootScope.isProcessing = false;
      };

      function activateNew() {
        $scope.title = $scope.newProperties.title;
        $scope.segmento = {};
        $scope.segmento.activo = true;
      }

      function activateEdit() {
        $scope.segmento = $scope.prepService;
        $scope.entidadId = $scope.segmento.id;
        $scope.entidad = $scope.editProperties.entidad;
        $scope.title = $scope.editProperties.title;
        $scope.listaPreciosSeleccionada = $scope.segmento.segmentoDetalle;
      }

      function activateView() {
        $scope.segmento = $scope.prepService;
        $scope.entidadId = $scope.segmento.id;
        $scope.entidad = $scope.viewProperties.entidad;
        $scope.title = $scope.viewProperties.title;
        $scope.listaPreciosSeleccionada = $scope.segmento.segmentoDetalle;
        $scope.view = true;
      }

      function cancel() {
        $location.path("/segmentocliente");
      }

      $scope.activate();

      function submit() {
        $scope.submited = true;
        if ($scope.SegmentoClienteForm.$valid) {
          $rootScope.isProcessing = true;
          submitSegmento().then(function(segmento) {
            $location.path("/segmentocliente");
          }, errorHandler);
        }
      }

      function submitSegmento() {
        $scope.segmento.segmentoDetalle = $scope.listaPreciosSeleccionada;
        var segmentoCliente = SegmentoClienteFactory.create($scope.segmento);
        return SegmentoClienteFactory.save(segmentoCliente, function(resp, headers) {
          //success callback
          //console.log("RESPONSE: ", resp);
        });
      }

      function errorHandler(err) {
        console.log("Error al guardar Segmento de clientes: ", err);
        $rootScope.isProcessing = false;
        $scope.submited = false;
        var msg = "";
        _.forEach(err.data, function(error) {
          if (error.message == "Not unique field: codigo") {
            msg += '\n\n' + "Ya existe un segmento con el mismo codigo.\n"
          } else if (error.message == "Not unique field: nombre") {
            msg += '\n\n' + "Ya existe un segmento con el mismo nombre.\n"
          }else if (error.message == "Not unique field: descripcion") {
            msg += '\n\n' + "Ya existe un segmento con la misma descripcion.\n"
          }else {
            msg += '\n\n' + error.message + '.'
          }
        });
        notify({
          message: msg,
          classes: "alert-danger",
          position: "right"
        });
      }
    });
  }
]);

angular.module('qualita.venta')
  .factory('SegmentoClienteFactory', SegmentoClienteFactory);

  SegmentoClienteFactory.$inject = ['$resource', 'baseurl'];

function SegmentoClienteFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var SegmentoCliente = $resource(baseurl.getBaseUrl() + "/segmentos/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'BaseForm';
    } else {
      params = [];
      params.view = view != undefined ? view : 'BaseForm';
    }
    return SegmentoCliente.query(params);
  }

  function get(id, view) {
    return SegmentoCliente.get({ id: id, view: view ? view : "BaseForm" });
  }

  function create(attrs) {
    return new SegmentoCliente(attrs);
  }

  function save(segmento) {
    return (segmento.id) ? segmento.$update() : segmento.$save();
  }

  function remove(segmento) {
    return segmento.$remove();
  }
}

'use strict';

/**
 * @ngdoc service
 * @name qualita.enviosegundoplano
 * @description
 * # Envio Segundo Plano Contabilidad
 * Factory in the qualita.
 */
angular.module('qualita.contabilidad')
  .factory('EnvioEntidadesContabilidadFactory', ['$resource', 'baseurl', 'filterFactory', 'TiposFactory', '$http',
  '$translatePartialLoader', '$translate',
    function($resource,  baseurl, filterFactory, TiposFactory, $http, $translatePartialLoader, $translate) {

  	var EnvioEntidadesContabilidad= $resource( baseurl.getBaseUrl() + '/enviocontabilidad/:id', {id: '@id'}, {
      update: {
        method: 'PUT'
      }
    });

    return {
      all: function(params) {
        return EnvioEntidadesContabilidad.query(params);
      },

      get: function(id, view) {
        return EnvioEntidadesContabilidad.get({id: id, view: view!=undefined ? view : 'base'});
      },

      create: function(attrs) {
        return new EnvioEntidadesContabilidad(attrs);
      },

      save: function(envioentidadescontabilidad) {
        return (envioentidadescontabilidad.id) ? envioentidadescontabilidad.$update() : envioentidadescontabilidad.$save();
      },

      remove: function(envioentidadescontabilidad) {
        return envioentidadescontabilidad.$remove();
      },

      schema: function () {
        return schema;
      },

      form: function (type) {
        return type === 'new' ? formNew : formEdit;
      }
    };
  }]);

'use strict';


angular.module('qualita.contabilidad')
  .controller('EnvioEntidadesContabilidadIndexCtrl',
    ["$scope", "EnvioEntidadesContabilidadFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "notify", "TiposFactory", "UtilFactory", "ContabilidadLangFactory", "ParametrosFactory", "$q", "WatchLocacionChangeFactory", "$filter", "CsvFactory", "baseurl", "TiposTipoFactory", function ($scope, EnvioEntidadesContabilidadFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal,
      $window, $state, notify, TiposFactory, UtilFactory,
      ContabilidadLangFactory, ParametrosFactory, $q, WatchLocacionChangeFactory, $filter, CsvFactory, baseurl, TiposTipoFactory) {

      var vm = this;
      var emptyDataRender = function (data) {
        if (data == undefined)
          return "";
        else
          return data;
      };
      var enviadoDataRender = function (data) {
        if (data == "S")
          return "SI";
        else
          return "NO";
      };
      WatchLocacionChangeFactory.killAllWatchers();
      $scope.curDate = new Date();
      var tipoEntidadFiltro = undefined;
      var tipoEnvioFiltro = undefined;
      var enviadoFiltro = undefined;
      var idEntidadFiltro = undefined;

      vm.tipoEntidades = TiposTipoFactory.get("envios_contabilidad");
      vm.tipoEnvios = TiposTipoFactory.get("tipo_transferencia_de_datos");
      vm.enviados = TiposTipoFactory.get("boolean_values");

      vm.enviados.$promise.then(function (response) {
        _.forEach(response, function (detalle) {
          if (detalle.codigo && detalle.codigo.length > 0) {
            if (detalle.codigo == "no") {
              $scope.enviado = detalle;
              enviadoFiltro = 'N';
            }
        }
        })
       
    });
      

      if ($scope.watcher) { $scope.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
        $scope.watcher = watcher;
      }, "locaciones.id");
      $scope.changeTipoEntidad = function (tipoEntidad) {
        console.log(tipoEntidad);
        tipoEntidadFiltro = tipoEntidad;
      };

      $scope.changeTipoEnvio = function (tipoEnvio) {
        tipoEnvioFiltro = tipoEnvio;
      };

      $scope.changeEnviado = function (enviado) {
        if(enviado.descripcion == 'No'){
          enviadoFiltro = 'N';
        }else{
          enviadoFiltro = 'S';
        }
        
      };

      $scope.cambiarFiltro = function () {
        var staticFilter = {};
        $scope.filtro = true;
        var filtersArray = [{
          path: 'id',
          sortAsc: 'true'
        }];

        if (tipoEntidadFiltro) {
          filtersArray.push({
            path: 'tipoEntidad.codigo',
            equals: tipoEntidadFiltro.codigo
          });
        }

        if (tipoEnvioFiltro) {
          filtersArray.push({
            path: 'tipoEnvio.codigo',
            equals: tipoEnvioFiltro.codigo
          });
        }

        if (enviadoFiltro) {
          filtersArray.push({
            path: 'enviado',
            equals: enviadoFiltro
          });
        }

        if (vm.idEntidad) {
          filtersArray.push({
            path: 'idEntidad',
            equals: vm.idEntidad
          });
        }
        if (vm.numeroTransaccion) {
          filtersArray.push({
            path: 'numeroTransaccion',
            like: vm.numeroTransaccion
          });
        }

        staticFilter.search =  filterFactory.and(filtersArray).value();
      
        $scope.options.staticFilter = staticFilter;
      }
     

      var staticFilter = {};

      
      var filter = {};

      filter = {
        path: "enviado",
        equals: 'N'
      }

      vm.staticFilter = {
        search: filterFactory.and([{
          path: "enviado",
          equals: "N"
        }]).value()
      };
      ContabilidadLangFactory.getTranslations().then(function (translations) {
        var defaultColumnOrder = ['id', 'tipoEntidad.descripcion', 'tipoEnvio.descripcion', 'idEntidad','descripcion','numeroTransaccion', 'enviado', 'descripcionError','ordenEnvio','ordenIntentoEnvio'];
        $scope.options = {
          'staticFilter': vm.staticFilter,
          'resource': 'enviocontabilidad',
          'title': 'Envio Entidades Contabilidad',
          'view': 'EnvioEntidadesContabilidadList',
          'factory': EnvioEntidadesContabilidadFactory,
          'defaultColumnOrder': defaultColumnOrder,
          'columns': [
            { 'data': 'id', 'title': $filter('translate')('CODE'), 'searchable': false, 'orderable': true},
            { 'data': 'tipoEntidad.descripcion', 'title': 'Tipo de Entidad','class': 'dt-left','searchable': false, 'orderable': true },
            { 'data': 'tipoEnvio.descripcion', 'title': 'Tipo de Envio','class': 'dt-left','searchable': false, 'orderable': true,'visible': false},        
            { 'data': 'idEntidad', 'title':'ID Entidad','searchable': false, 'orderable': true},
            { 'data': 'descripcion', 'title':  'Descripción', 'renderWith': 'emptyRender','class': 'dt-left','searchable': false, 'orderable': true},
            { 'data': 'numeroTransaccion', 'title':  'Nro Transaccion', 'renderWith': 'emptyRender','class': 'dt-left','searchable': false, 'orderable': true},
            { 'data': 'enviado', 'title':  'Enviado a Contabilidad','render': enviadoDataRender,'class': 'dt-center','searchable': false, 'orderable': true},
            { 'data': 'descripcionError', 'title':  'Descripcion error', 'renderWith': 'emptyRender','class': 'dt-left','searchable': false, 'orderable': true},
            { 'data': 'ordenEnvio', 'title':  'Orden de Dependencia', 'renderWith': 'emptyRender','class': 'dt-left','searchable': false, 'orderable': true, 'visible': false},
            { 'data': 'ordenIntentoEnvio', 'title':  'Orden de Envio', 'renderWith': 'emptyRender','class': 'dt-left','searchable': false, 'orderable': true, 'visible': true}
          ],
          'hasOptions': false,
          'hideEditMenu': true,
          'hideRemoveMenu': true,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "desc",
          'extraMenuOptions':[{}],
          'extraRowOptions': [{
            templateToRender: "<button title='" + $filter('translate')('RE_GENERAR_ASIENTO') + "' class='btn btn-warning btn-dt' style='margin-right: 5px;' ng-click='distribuir(<%=dataId%>)' ng-class='{ hidden : !canDistribuir(<%=dataCustom%>)}' ng-disabled='vm.isProcessing'> <span class='glyphicon glyphicon-stats'></span> </button>",
            functionName: "distribuir",
            conditionName: "canDistribuir",
            customAttribute: 'estado',
            conditionDef: function (estado) {
              return true;
            },
            functionDef: function (itemId) {
              if (!vm.isProcessing) {
                vm.isProcessing = true;
                EnvioEntidadesContabilidadFactory.distribuir(itemId).then(function (response) {
                  notify({ message: "Lotes distribuidos con éxito", classes: 'alert-success', position: 'right', templateUrl: "" });
                  vm.isProcessing = false;
                }, function (error) {
                  //console.log(error);
                  vm.isProcessing = false;
                  var mensaje = "<span>";
                  _.forEach(error.data, function (err) {
                    mensaje = mensaje + err.message + "<br/>";
                  });
                  mensaje = mensaje + "</span>";
                  notify({ messageTemplate: mensaje, classes: 'alert-danger', position: 'right', templateUrl: "" });
                });
              }
            }
          }]
        };
      
      });
      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }
      $scope.limpiarFiltros = function() {
        $scope.tipoEntidad = undefined;
        $scope.tipoEnvio = undefined;
        $scope.enviado = undefined;
        vm.idEntidad = undefined;
        vm.numeroTransaccion = undefined;
        tipoEntidadFiltro = undefined;
        tipoEnvioFiltro = undefined;
        enviadoFiltro = undefined;
      }
    }]);

'use strict';


angular.module('qualita.tesoreria')
    .controller('ConsultaCobroCtrl',
        ["$scope", "ClienteFactory", "filterFactory", "AuthorizationService", "ReportTicketFactory", "$modal", "$window", "$state", "AlmacenFactory", "ProductosFactory", "notify", "GrupoProductoAlmacenFactory", "UtilFactory", "cajaFactory", "TesoreriaLangFactory", "FormaPagoFactory", "$filter", "CsvFactory", "baseurl", "CobroDetalleFactory", "TiposTipoFactory", "EntidadBancariaFactory", "cuentaBancariaFactory", "$timeout", function ($scope, ClienteFactory, filterFactory, AuthorizationService, ReportTicketFactory, $modal,
            $window, $state, AlmacenFactory, ProductosFactory, notify, GrupoProductoAlmacenFactory, UtilFactory,
            cajaFactory, TesoreriaLangFactory, FormaPagoFactory, $filter, CsvFactory, baseurl,
            CobroDetalleFactory, TiposTipoFactory, EntidadBancariaFactory, cuentaBancariaFactory, $timeout) {

            var vm = this;
            vm.searchCuentasFilter = searchCuentasFilter;
            /* Eso se usa en el delayRequest de la directiva validate-uiselet-input */
            $scope.staticFilterClie = [{ path: "activo", equals: true }];
            $scope.clientesFactory = ClienteFactory;
            $scope.monedas = TiposTipoFactory.get("monedas");
            $scope.entidadBancarias=EntidadBancariaFactory.all();
            $scope.cuentasBancarias = cuentaBancariaFactory.all();
            var filterFormaPago = {};
            filterFormaPago.search = filterFactory.and([{
                path: 'clase.codigo',
                notEquals: "forma_pago_referencia"
            }]).value();
            $scope.formasPago = FormaPagoFactory.all(filterFormaPago);

            $scope.cajas = cajaFactory.all();
            $scope.depositadotodos = true;
            function searchCuentasFilter(criteria) {
              return function (item) {
                if (!criteria) {
                  return true;
                }
                return (
                  (item.numeroCuenta + "").indexOf(criteria.toLowerCase()) >
                  -1 ||
                  item.entidad.nombre
                    .toLowerCase()
                    .indexOf(criteria.toLowerCase()) > -1
                );
              };
            };
            var monedaRender = function (data, type, row) {

                var campoMoneda = null;

                if (row.moneda != undefined) {
                    campoMoneda = row.moneda;
                }

                if (data != undefined) {
                    //var moneda = "Gs. ";
                    if (campoMoneda.codigo === "dolares") {
                        //moneda = "Usd. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "pesos") {
                       // moneda = "Pes. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "real") {
                       // moneda = "Rel. ";
                        data = parseFloat(data).toFixed(2);
                    } else if (campoMoneda.codigo === "euro") {
                        //moneda = "Eur. ";
                        data = parseFloat(data).toFixed(2);
                    } else {
                        data = parseFloat(data).toFixed(0);
                    }
                    return (
                        //moneda +
                        data
                            .toString()
                            .replace(".", ",")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
                } else {
                    return "";
                }
            };

          $scope.changeDepositado = function (value) {
            $scope.depositadosi = false;
            $scope.depositadono = false;
            $scope.depositadotodos = false;
            if(value === "si"){
              $scope.depositadosi = true;
            } else
            if(value === "no"){
              $scope.depositadono = true;
            } else
            if(value === "todos"){
              $scope.depositadotodos = true;
            }
          };

            $scope.getCheques = function () {
                /* definir como filtrar por cliente */
                var staticFilter = [];

                // Verificacion de 'fecha cobro desde' menor a 'fecha cobro hasta
                if ($scope.fechaCobroDesde > $scope.fechaCobroHasta) {
                    var msg = '';
                    msg += '\n\n' + 'La fecha de inicio (Fecha Cobro Desde) debe ser menor o igual a la fecha de fin (Fecha Cobro Hasta).' + "\n";
                    notify({ message: msg, classes: 'alert-danger', position: 'right' });
                    return
                }

                if($scope.depositadosi){
                    $scope.depositadono = undefined;
                    $scope.depositadotodos = undefined;
                }
                if($scope.depositadono){
                    $scope.depositadosi = undefined;
                    $scope.depositadotodos = undefined;
                }
                if($scope.depositadotodos){
                    $scope.depositadono = undefined;
                    $scope.depositadosi = undefined;
                }

                $scope.filtro = true;

                //Filtro de forma pago
                if ($scope.formaPago) {
                    staticFilter.push(
                        {
                            path: 'formaPago.id',
                            equals: $scope.formaPago.id
                        }
                    )
                }

                //Filtro de caja
                if ($scope.caja) {
                    staticFilter.push(
                        {
                            path: 'cobro.caja.id',
                            equals: $scope.caja.id
                        }
                    )
                }

                //Filtro de clientes
                if ($scope.cliente) {
                    staticFilter.push(
                        {
                            path: 'cobro.cliente.id',
                            equals: $scope.cliente.id
                        }
                    )
                }

                if ($scope.moneda) {
                    staticFilter.push(
                        {
                            path: 'moneda.id',
                            equals: $scope.moneda.id
                        }
                    )
                }

            
                if ($scope.depositadosi) {
                    staticFilter.push(
                        {
                            path: 'depositado',
                            like: 'true'
                        }
                    )
                }

                if ($scope.depositadono) {
                    staticFilter.push(
                        {
                            path: 'depositado',
                            like: 'false'
                        }
                    )
                }

                if ($scope.fechaCobroDesde) {
                    staticFilter.push(
                        {
                            path: 'cobro.fecha',
                            equalOrAfter: moment($scope.fechaCobroDesde).format('DD/MM/YYYY')
                        }
                    )
                }

                if ($scope.fechaCobroHasta) {
                    staticFilter.push(
                        {
                            path: 'cobro.fecha',
                            equalOrBefore: moment($scope.fechaCobroHasta).format('DD/MM/YYYY')
                        }
                    )
                }

                staticFilter.push(
                    {
                        path: 'cobro.estado.codigo',
                        notEquals: 'estado_cobro_anulado'
                    }
                )

                // Se define el filtro final
                var filtros = {
                    search: filterFactory.and(staticFilter).value()
                };

                console.log("filtros ", filtros);

                $scope.options.staticFilter = filtros;
            };


            var defaultColumnOrder = ['id', 'cobro.procesoCobro.id','cobro.numeroCobro','cobro.numeroRecibo','cobro.cliente.codigo','cobro.cliente.nombre',
            'cobro.tipoCobro.descripcion','formaPago.descripcion','numeroTransaccion','numeroCheque','entidadBancaria.nombre','movimientoBancario.cuenta.numeroCuenta','movimientoBancario.cuenta.entidad.nombre',
            'movimientoBancario.entrada','cobro.fecha','fechaPagoDiferido','moneda.descripcion','importe','depositado'];

            TesoreriaLangFactory.getTranslations().then(function (translations) {
                $scope.options = {
                    'resource': 'cobrosdetalles',
                    'title': $filter('translate')('CONSULTA_COBROS'),
                    'view': 'CobroDetalleList',
                    'factory': CobroDetalleFactory,
                    'defaultColumnOrder': defaultColumnOrder,
                    'columns': [
                        { 'data': "id", title: $filter("translate")("CODE"), visible: false},
                        { 'data': 'cobro.procesoCobro.id', 'title': 'N° Flujo', renderWith: 'emptyRender','class': 'dt-center' },
                        { 'data': 'cobro.numeroCobro', 'title': $filter('translate')('CODIGO_COBRO'), renderWith: 'emptyRender','class': 'dt-center' },
                        { 'data': 'cobro.numeroRecibo', 'title':"N° Recibo", renderWith: 'emptyRender', 'class': 'dt-center' },
                        { 'data': 'cobro.cliente.codigo', 'title': 'Código Cliente', renderWith: 'emptyRender','class': 'dt-center' },
                        { 'data': 'cobro.cliente.nombre', 'title': 'Cliente', renderWith: 'emptyRender','class': 'dt-left' },
                        { 'data': 'cobro.tipoCobro.descripcion', 'title': 'Tipo Cobro', renderWith: 'emptyRender', 'class': 'dt-left' },
                        { 'data': 'formaPago.descripcion', 'title': 'Forma de Pago', renderWith: 'emptyRender', 'class': 'dt-left' },
                        { 'data': 'numeroTransaccion', 'title': 'N° Transacción', renderWith: 'emptyRender','class': 'dt-center' },
                        { 'data': 'numeroCheque', 'title': 'N° Cheque', renderWith: 'emptyRender','class': 'dt-center' },
                        { 'data': 'entidadBancaria.nombre', 'title': 'Entidad Bancaria Cobro', renderWith: 'emptyRender', 'class': 'dt-left' },
                        { 'data': 'movimientoBancario.cuenta.numeroCuenta', 'title': "Cuenta de Depósito", renderWith: 'emptyRender', 'class': 'dt-right','class': 'dt-left' },
                        { 'data': 'movimientoBancario.cuenta.entidad.nombre', 'title': "Entidad Bancaria Depósito", renderWith: 'emptyRender', 'class': 'dt-left' },                        
                        { 'data': 'movimientoBancario.entrada', 'title': 'Importe Deposito', renderWith: monedaRender, 'class': 'dt-right', visible: false},
                        { 'data': 'cobro.fecha', 'title': $filter('translate')('DATE'), renderWith: 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-left' },
                        { 'data': 'fechaPagoDiferido', 'title': $filter('translate')('FECHA_PAGO'), renderWith: 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-left' },
                        { 'data': 'moneda.descripcion', 'title': $filter('translate')('CURRENCY'), renderWith: 'emptyRender', 'class': 'dt-left' },
                        { 'data': 'importe', 'title': $filter('translate')('MONTO_TOTAL'), renderWith: monedaRender, 'class': 'dt-right' },
                        { 'data': 'depositado', 'title': 'Depósitado', renderWith: 'emptyRender', 'class': 'dt-center' }
                    ],
                    'hasOptions': false,
                    'hideAddMenu': true,
                    'defaultOrderColumn': 14,
                    'defaultOrderDir': "desc"
                };
            });

            $scope.limpiarFiltros = function() {
                $scope.cliente = undefined;
                $scope.fechaCobroDesde = undefined;
                $scope.fechaCobroHasta = undefined;
                $scope.formaPago = undefined;
                $scope.caja = undefined;
                $scope.moneda = undefined;
                $scope.depositadosi = undefined;
                $scope.depositadono = undefined;
                $scope.depositadotodos = undefined;
                $scope.changeDepositado("todos")
              }

            $scope.$watch('filtro', function (newValue){
              if(newValue){
                $timeout(function(){
                  $("#DataTables_Table_0_filter").hide()
                })
              }
            })
        }]);

'use strict';


angular.module('qualita.venta')
    .controller('ClienteRetencionIndexCtrl', ["$state", "$scope", "ClienteRetencionFactory", "filterFactory", "ParametrosFactory", "ReportTicketFactory", "$window", "VentasLangFactory", "$filter", "UtilFactory", "CsvFactory", "baseurl", "$modal", "notify", "AuthorizationService", function ($state, $scope, ClienteRetencionFactory, filterFactory, ParametrosFactory,
        ReportTicketFactory, $window, VentasLangFactory, $filter, UtilFactory, CsvFactory, baseurl, $modal, notify, 
        AuthorizationService) {

        var dateRender = function (data, type, full) {
            if (data) {
                return moment.utc(data).format('DD/MM/YYYY');
            } else {
                return "";
            }
        };

        var renderEmptyData = function (data) {
            if (data == undefined)
                return "";
            else
                return data;
        };

        var monedaRender = function(data, type, row) {
            if (data != undefined) {
                var moneda = "Gs. ";
                data = parseFloat(data).toFixed(0);

                return (
                    moneda +
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            } else {
                return "";
            }
        };

        VentasLangFactory.getTranslations().then(function (translations) {
            var staticFilter = undefined;
            staticFilter = {};
            staticFilter.search = filterFactory
                        .and([
                            {
                                path: "id",
                                isNotNull: "true"
                            },
                            {
                              path: "id",
                              sortDesc: "true"
                          }
                        ])
                        .value();

            var defaultColumnOrder = ['id', 'numero', 'timbrado', 'estado.descripcion', 'fechaAnulacion', 'fechaEmision', 'fechaRecepcion', 
            'informante', 'rucInformante', 'comprobante.numero', 'montoRetencion'];
            $scope.options = {
                'resource': 'clienteretenciones',
                'title': translations.RETENCION_CLIENTE,
                'factory': ClienteRetencionFactory,
                'view': 'ClienteRetencionList',
                'staticFilter': staticFilter,
                'defaultColumnOrder': defaultColumnOrder,
                'failedDeleteError': translations.FAIL_DELETE_RETENCION_VENTA,
                'columns': [
                    { 'data': 'id', 'title': translations.ID, visible: false  },
                    { 'data': 'numero', 'title': translations.NUMERO, 'class': 'dt-left' },
                    { 'data': 'timbrado', 'title': translations.TIMBRADO, 'class': 'dt-left' },
                    { 'data': 'estado.descripcion', 'title': translations.ESTADO, 'class': 'dt-left' },
                    { 'data': 'fechaAnulacion', 'title': translations.FECHA_ANULACION, 'class': 'dt-left', 'dateFormat': 'DD/MM/YYYY', 'renderWith': 'dateRender', },
                    { 'data': 'fechaEmision', 'title': translations.FECHA_EMISION, 'class': 'dt-left', 'dateFormat': 'DD/MM/YYYY', 'renderWith': 'dateRender', },
                    { 'data': 'fechaRecepcion', 'title': translations.FECHA_RECEPCION, 'class': 'dt-left', 'dateFormat': 'DD/MM/YYYY', 'renderWith': 'dateRender', },
                    { 'data': 'informante', 'title': translations.INFORMANTE, 'class': 'dt-left', 'render': renderEmptyData },
                    { 'data': 'rucInformante', 'title': translations.RUC_INFORMANTE, 'class': 'dt-left', 'render': renderEmptyData },
                    { 'data': 'comprobante.numero', 'title': translations.COMPROBANTE_ASOCIADO, 'class': 'dt-left', 'render': renderEmptyData },
                    { 'data': 'montoRetencion', 'title': translations.MONTO_RETENCION, 'class': 'dt-left', 'render': monedaRender }
                ],
                'hasOptions': false,
                'hideAddMenu' : true,
                'defaultOrderColumn': 0,
                'defaultOrderDir': "desc",
                'extraMenuOptions':
                    [
                        {
                            data: $filter("translate")("IMPORTAR_RETENCIONES"),
                            title: $filter("translate")("IMPORTAR_RETENCION_CLIENTE"),
                            icon: "glyphicon glyphicon-check",
                            showCondition: function () {
                                return AuthorizationService.hasPermission("create_clienteretenciones");
                            },
                            action: function () {
                                $state.go('app.importaciondatos.list');
                            }
                        },
                        {
                            'title': 'CSV',
                            'icon': 'glyphicon glyphicon-export',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                                CsvFactory.csv("clienteretenciones", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                                    .then(function (response) {
                                        console.log("response de csv: ", response);
                                        var fileName = response.data.fileName;
                                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                                    });
                            }
                        },
                        {
                            'title': 'Reporte',
                            'icon': 'glyphicon glyphicon-file',
                            'showCondition': function () { return true; },
                            'action': function () {
                                var filters = createFilters($scope.options.getFilters());
                                ReportTicketFactory.ticket('listadoRetencionesClientes', filters, $scope.options.tableAjaxParams,
                                    $scope.options.currentColumnOrder).then(function (genTicket) {
                                        $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                                        $window.open($scope.pdfDownloadURL, '_blank');
                                    });
                            }
                        }

                ],
                extraRowOptions: [
                ]
            };
            var createFilters = function (filters) {
                var filtersArr = [];
                _.each(filters, function (search, data) {
                    if(search === 'No' && data === 'activo'){
                        search = 'false';
                    }
                    if(search === 'Sí' && data === 'activo'){
                        search = 'true';
                    }

                    filtersArr.push({ path: data, like: search })
                });
                var filters = filterFactory.and(filtersArr).value();
                return filters;
            }
        });
    }]);

angular.module('qualita.venta')
  .factory('ClienteRetencionFactory', ['$resource', 'baseurl', '$http', 'Upload', function ($resource, baseurl, $http, Upload) {

    var ClienteRetencion = $resource(baseurl.getBaseUrl() + '/clienteretenciones/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });

    var savedData = {};

    return {
      carga: function (name, locacion, file) {
        return Upload.upload({
          url: baseurl.getBaseUrl() + '/clienteretenciones/importar',
          method: "POST",
          params: { filename: name, locacion: locacion },
          data: { file: file }
        });
      },

      all: function (params) {
        return ClienteRetencion.query(params);
      },

      get: function (id) {
        return ClienteRetencion.get({ id: id });
      },

      create: function (attrs) {
        return new ClienteRetencion(attrs);
      },

      save: function (clienteretencion) {
        return (clienteretencion.id) ? clienteretencion.$update() : clienteretencion.$save();
      },

      remove: function (clienteretencion) {
        return clienteretencion.$remove();
      },

      setData: function (data) {
        savedData = data;
      },

      getData: function () {
        return savedData;
      }
    };
  }]);
'use strict';


angular.module('qualita.stock')
  .controller('MovimientoConsultaSimpleIndexCtrl',
    ["$scope", "MovimientoStockDetalleFactory", "filterFactory", "ReportTicketFactory", "$window", "AlmacenFactory", "ProductosFactory", "notify", "TiposFactory", "UtilFactory", "StockLangFactory", "ParametrosFactory", "$q", "WatchLocacionChangeFactory", "$filter", "CsvFactory", "baseurl", function ($scope, MovimientoStockDetalleFactory, filterFactory, ReportTicketFactory,
      $window, AlmacenFactory, ProductosFactory, notify, TiposFactory, UtilFactory,
      StockLangFactory, ParametrosFactory, $q, WatchLocacionChangeFactory, $filter, CsvFactory, baseurl) {

      var dateRender = function (data, type, full) {
        if (data) {
          return moment.utc(data).format('DD/MM/YYYY');
        } else {
          return "";
        }
      };

      var emptyDataRender = function (data) {
        if (data == undefined)
          return "";
        else
          return data;
      };
      WatchLocacionChangeFactory.killAllWatchers();
      $scope.curDate = new Date();
      $scope.saldoInicial = 0;
      $scope.producto = undefined;
      $scope.almacen = undefined;
      $scope.numeroGrupo = undefined;

      $scope.almacenes = AlmacenFactory.all();

      $scope.mensajeSinGrupo = false;
      $scope.mensajeEsBase = true;


      if ($scope.watcher) { $scope.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
        $scope.watcher = watcher;
        //$scope.productos = ProductosFactory.allForSelect(locacionFilter);
      }, "locaciones.id");
      $scope.grupos = undefined;
      $scope.estados = undefined;
      $scope.estado = undefined;

      StockLangFactory.getTranslations().then(function (translations) {

        TiposFactory.estadosMovimientoStock().then(function (data) {
          $scope.estados = data.data;
          $scope.estados.push({ 'id': 0, 'codigo': '', 'descripcion': 'Todo' });
          $scope.estado = $scope.estados[$scope.estados.length - 1];
        });

        $scope.unidad = undefined;
        $scope.multiplicador = 1;
        var unidadMedidaRenderCantidad = function (data) {
          if (data != undefined) {

            var digitosDesdePunto = data.toString().substr(data.toString().indexOf('.'));
            //console.log("digitos desde el punto decimal: ", digitosDesdePunto);

            if(data != 0 && digitosDesdePunto != 0){
                data = parseFloat(data).toFixed(4);
            }

            // El calculo para convertir entre unidades consiste en la division por $scope.multiplicador
            data = data / $scope.multiplicador;
            data = Math.round(data * 100)/100;

            var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
            var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);

            // Si tiene parte entera
            if(parteEntera != ''){
                if(digitosDesdePunto != 0){
                    return (
                        parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                        ","+parteDecimal.toString()
                    );
                }else{
                    parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            }else{
                return (
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            }

          } else {
              return "";
          }
        };

        var unidadMedidaRenderCosto = function (data) {
          if (data == undefined)
            return "";
          else
            var dataAux = parseFloat(data * $scope.multiplicador).toFixed(2);
          return (dataAux).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        };

        var importeRenderUnitario = function (data, type, row) {
          if (data!=null) {
              var moneda = "Gs. ";
              data = parseFloat(data * $scope.multiplicador).toFixed(0);
              // TODO hacer esto mismo en todos los renders de moneda
              return (
                      moneda +
                      data
                      .toString()
                      .replace(".", ",")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      );
          } else {
              return "";
          }
      };

      var importeRenderTotal = function (data, type, row) {
        if (data) {
            var moneda = "Gs. ";
            data = parseFloat(row.ultimoCosto * row.saldo).toFixed(0);
            // TODO hacer esto mismo en todos los renders de moneda
            return (
                    moneda +
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    );
        } else {
            return "";
        }
    };

        var saldoRender = function (data) {
          if (data != undefined) {

            var digitosDesdePunto = data.toString().substr(data.toString().indexOf('.'));
            //console.log("digitos desde el punto decimal: ", digitosDesdePunto);

            if(data != 0 && digitosDesdePunto != 0){
                data = parseFloat(data).toFixed(4);
            }

            // data = data * $scope.multiplicador;

            // El calculo para convertir entre unidades consiste en la division por $scope.multiplicador
            data = data / $scope.multiplicador;
            data = Math.round(data * 100)/100;

            var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
            var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);

            // Si tiene parte entera
            if(parteEntera != ''){
                if(digitosDesdePunto != 0){
                    return (
                        parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                        ","+parteDecimal.toString()
                    );
                }else{
                    parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            }else{
                return (
                    data
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                );
            }

          } else {
              return "";
          }
        };

        var almacenFiltro = undefined;
        var productoFiltro = undefined;
        var grupoFiltro = undefined;

        $scope.delayRequestProducto = function(searchValue){
          var userInputFilter = {};
          var staticFilter = [];
          if (searchValue.length >= 3) {

                  var filterNombre = staticFilter.slice()
                  var filterCodigoBarras = staticFilter.slice()
                  var filterCodigoInterno = staticFilter.slice()

                  filterNombre.push({
                      path: "nombre",
                      like: searchValue
                  })

                  filterCodigoBarras.push({
                      path: "codigoBarrasPrincipal.codigoBarras",
                      like: searchValue
                  })

                  filterCodigoInterno.push( {
                      path: "codigo",
                      like: searchValue
                  })

              userInputFilter = {
                  search: filterFactory.or([
                      filterFactory.and(filterNombre).value(),
                      filterFactory.and(filterCodigoBarras).value(),
                      filterFactory.and(filterCodigoInterno).value()
                  ]).value()
              }

              $scope.recuperarProducto(userInputFilter)
          } else if (searchValue.length > 0 && searchValue.length < 3)  {

              var filterCodigoInterno = staticFilter.slice()
              filterCodigoInterno.push( {
                  path: "codigo",
                  like: searchValue
              })

               userInputFilter = {
                  search:  filterFactory.or([
                       filterFactory.and(filterCodigoInterno).value()
                    ]).value()
              }
              $scope.recuperarProducto(userInputFilter)
          } else {
              $scope.productos = undefined
          }
        }

        $scope.recuperarProducto = function (userInputFilter) {
           var finalFilter = UtilFactory.createNestedFilters(
                $scope.finalFilters, userInputFilter)

            $scope.productos = ProductosFactory.allForSelect(userInputFilter)
        };

        $scope.changeAlmacen = function (almacen) {
          almacenFiltro = almacen;
        };

        $scope.changeProducto = function (producto) {
          productoFiltro = producto;
          $scope.grupos = _.sortBy(producto.gruposProducto, 'id');
          if ($scope.grupos) {
            $scope.grupos.push({ 'id': 0, 'codigo': '', 'numero': 'Sin Grupo' });
            $scope.numeroGrupo = $scope.grupos[$scope.grupos.length - 1];
          } else {
            $scope.numeroGrupo = undefined;
          }
          $scope.unidad = producto.unidadMedidaBase;
          $scope.multiplicador = 1;
        };

        $scope.changeGrupo = function (numeroGrupo) {
          grupoFiltro = numeroGrupo;
        };

        $scope.changeUnidad = function (unidadMedida) {
          var actual = unidadMedida;
          $scope.multiplicador = 1;
          if (!actual.esBase) {
            $scope.multiplicador = $scope.multiplicador * actual.cantidad;
            actual = actual.unidadContenida;
          }
        };

        $scope.searchProductoFilter = function (criteria) {
          return function (item) {
            if (!criteria) {
              return true;
            }

            return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria || (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
          };
        }

        $scope.cambiarFiltro = function () {

          // Actualizacion de banderas para "observaciones"

          if($scope.numeroGrupo){
            if($scope.numeroGrupo.numero == "Sin Grupo"){
              $scope.mensajeSinGrupo = true;
            }else{
              $scope.mensajeSinGrupo = false;
            }
          }

          if($scope.unidad && $scope.unidad.esBase){
            $scope.mensajeEsBase = true;
          }else{
            $scope.mensajeEsBase = false;
          }

          if ($scope.fechaDesde != undefined && $scope.fechaHasta != undefined && $scope.fechaDesde > $scope.fechaHasta) {
            notify({ message: $filter('translate')('FECHA_DESDE_MAYOR_FECHA_HASTA'), classes: 'alert-danger', position: 'right' });
          } else {
            if ($scope.almacen == undefined){
              notify({ message: $filter('translate')('FILTRO_ALMACEN_REQUERIDO'), classes: 'alert-danger', position: 'right' });
            } else if ($scope.producto == undefined) {
              notify({ message: $filter('translate')('FILTRO_PRODUCTO_REQUERIDO'), classes: 'alert-danger', position: 'right' });
            } else {
              if ($scope.fechaDesde == null) $scope.fechaDesde = undefined;
              if ($scope.fechaHasta == null) $scope.fechaHasta = undefined;

              if ($scope.producto.criterioControl.codigo === "normal") {
                console.log("1");
                $scope.filtro = true;
                var staticFilter = {};

                if ($scope.producto.claseProducto.tipoAdministracion.codigo != 'bobinas') {
                  console.log("2");

                  staticFilter.search = filterFactory.or([
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoOrigen.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'false'
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value(),
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoDestino.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'true',
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value()
                  ]).value();
                } else {
                  console.log("3");

                  staticFilter.search = filterFactory.or([
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoOrigen.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'false'
                      },
                      {
                        path: 'grupo.numero',
                        like: $scope.numeroGrupo.numero
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value(),
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoDestino.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'true',
                      },
                      {
                        path: 'grupo.numero',
                        like: $scope.numeroGrupo.numero
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value()
                  ]).value();
                }
                $scope.options.staticFilter = staticFilter;
              } else {
                console.log("4");

                if ($scope.numeroGrupo == undefined) {
                  console.log("5");

                  notify({ message: $filter('translate')('FILTRO_GRUPO_REQUERIDO'), classes: 'alert-danger', position: 'right' });
                } else {
                  console.log("6");

                  $scope.filtro = true;
                  var staticFilter = {};

                  console.log($scope.numeroGrupo);

                  staticFilter.search = filterFactory.or([
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoOrigen.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'grupo.numero',
                        equals: $scope.numeroGrupo.numero
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'false'
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value(),
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoDestino.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'grupo.numero',
                        equals: $scope.numeroGrupo.numero
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: 'true'
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value()

                  ]).value();
                  $scope.options.staticFilter = staticFilter;
                }
              }
              if ($scope.fechaDesde != undefined) {
                if ($scope.producto.criterioControl.codigo === "normal" || $scope.numeroGrupo.numero === 'Sin Grupo') {
                  MovimientoStockDetalleFactory.getSaldoInicial($scope.producto.id, $scope.almacen.id, moment($scope.fechaDesde).format('DD/MM/YYYY')).then(function (resp) {
                    $scope.saldoInicial = resp.data;
                    if ($scope.saldoInicial === undefined) {
                      $scope.saldoInicial = 0;
                    }
                  });
                } else {
                  MovimientoStockDetalleFactory.getSaldoInicialGrupo($scope.producto.id, $scope.almacen.id, moment($scope.fechaDesde).format('DD/MM/YYYY'), $scope.numeroGrupo.id).then(function (resp) {
                    $scope.saldoInicial = resp.data;
                    if ($scope.saldoInicial === undefined) {
                      $scope.saldoInicial = 0;
                    }
                  });
                }
              } else {
                $scope.saldoInicial = 0;
              }
            }
          }
        };

        // TODO segun parametros, incluir o no columnas de produccion y movimiento de materiales
        var promise1 = ParametrosFactory.getByCodigo('incluir_nro_orden_produccion');
        var promise2 = ParametrosFactory.getByCodigo('incluir_nro_mov_materiales');
        var promises = [promise1, promise2];

        $q.all(promises).then(function (responses) {
          console.log(responses[0]);
          console.log(responses[1]);
          var defaultColumnOrder = ['id', 'producto.nombre', 'grupo.numero', 'movimientoStock.fecha', 'movimientoStock.depositoOrigen.descripcion', 'movimientoStock.depositoDestino.descripcion', 'descripcion', 'tipoMovimiento.descripcion',
            'movimientoStock.estado.descripcion', 'ingreso', 'egreso', 'saldo'
          ];
          if (responses[0].valorTipo.codigo == 'si') {
            defaultColumnOrder.push('nroOrdenProduccion');
            defaultColumnOrder.push('nroApunteCorte');
          }
          if (responses[1].valorTipo.codigo == 'si') {
            defaultColumnOrder.push('nroMovMateriales');
          }
          $scope.options = {
            //'staticFilter': staticFilter,
            'resource': 'movimientostockdetalle',
            'title': 'Movimientos de Stock',
            'view': 'ConsultaMovimientoStockList',
            'factory': MovimientoStockDetalleFactory,
            'defaultColumnOrder': defaultColumnOrder,
            'columns': [
              { 'data': 'id', 'title': $filter('translate')('CODE'), 'searchable': false, 'orderable': false  },
              { 'data': 'producto.nombre', 'title': $filter('translate')('PRODUCT'), 'searchable': false, 'orderable': false },
              { 'data': 'grupo.numero', 'title': 'Grupo', 'render': emptyDataRender, 'class': 'dt-right', 'searchable': false, 'orderable': false  },
              { 'data': 'movimientoStock.fecha', 'title': $filter('translate')('DATE'), 'renderWith': 'dateRender', 'dateFormat': 'DD/MM/YYYY', 'class': 'dt-center', 'searchable': false, 'orderable': false, 'type': 'date-range' },
              { 'data': 'movimientoStock.depositoOrigen.descripcion', 'title': $filter('translate')('ALMACEN_ORIGEN'), 'class': 'dt-right', 'searchable': false, 'orderable': false, 'render': emptyDataRender, visible:false },
              { 'data': 'movimientoStock.depositoDestino.descripcion', 'title': $filter('translate')('ALMACEN_DESTINO'), 'class': 'dt-right', 'searchable': false, 'orderable': false, 'render': emptyDataRender, visible: false },
              { 'data': 'movimientoStock.numero', 'title': $filter('translate')('MOV_STOCK_NUMBER')  },
              { 'data': 'descripcion', 'title': $filter('translate')('NRO_COMPROBANTE'), 'searchable': false, 'class': 'dt-left', 'orderable': false },
              //{'data': 'movimientoStock.tipoReferencia.descripcion', 'title': 'Referencia'},
              { 'data': 'tipoMovimiento.descripcion', 'title': $filter('translate')('TIPO_MOVIMIENTO'), 'searchable': false, 'orderable': false },
              { 'data': 'movimientoStock.estado.descripcion', 'title': $filter('translate')('STATUS'), 'class': 'dt-center', 'searchable': false, 'orderable': false },
              { 'data': 'ingreso', 'title': $filter('translate')('ENTRADA'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'class': 'dt-right', 'searchable': false, 'orderable': false  },
              { 'data': 'egreso', 'title': $filter('translate')('SALIDA'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'class': 'dt-right', 'searchable': false, 'orderable': false  },
              { 'data': 'saldo', 'title': $filter('translate')('SALDO'), 'render': saldoRender, 'class': 'dt-right', 'searchable': false, 'class': 'dt-right', 'orderable': false  },
              { 'data': 'nroOrdenProduccion', 'title': $filter('translate')('NRO_ORDEN_PRODUCCION'), 'class': 'dt-right', 'searchable': false, 'orderable': false, visible: false  },
              { 'data': 'nroMovMateriales', 'title': $filter('translate')('NRO_MOV_MATERIALES'), 'class': 'dt-right', 'searchable': false, 'orderable': false, visible: false },
              { 'data': 'nroApunteCorte', 'title': $filter('translate')('NRO_APUNTE_CORTE'), 'class': 'dt-right', 'searchable': false, 'orderable': false, visible: false }

            ],
            'hasOptions': false,
            'hideEditMenu': true,
            'hideRemoveMenu': true,
            'defaultOrderColumn': 3,
            'defaultOrderDir': "asc",
            'extraMenuOptions':
              [
                {
                  'title': 'Reporte',
                  'icon': 'glyphicon glyphicon-file',
                  'showCondition': function () { return true; },
                  'action': function () {
                    if($scope.producto.criterioControl.codigo !== "normal" && $scope.numeroGrupo.numero === 'Sin Grupo'){
                      console.log("aca");
                      var staticFilterSinGrupo = {};

                    staticFilterSinGrupo.search = filterFactory.or([
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoOrigen.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'grupo.id',
                        isNull: "true"
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: false
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value(),
                    filterFactory.and([
                      {
                        path: 'movimientoStock.depositoDestino.id',
                        equals: $scope.almacen.id
                      },
                      {
                        path: 'producto.id',
                        equals: $scope.producto.id,
                      },
                      {
                        path: 'grupo.id',
                        isNull: "true"
                      },
                      {
                        path: 'movimientoStock.fecha',
                        equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                      }, {
                        path: 'movimientoStock.fecha',
                        equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                      },
                      {
                        path: 'movimientoStock.estado.codigo',
                        like: $scope.estado.codigo
                      },
                      {
                        path: 'movimientoStock.contrapartida',
                        equals: true
                      },
                      {
                        path: 'id',
                        sortAsc: 'true'
                      }
                    ]).value()

                  ]).value();
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), staticFilterSinGrupo);

                    }else{
                      var staticFilterReport = {};
                      staticFilterReport.search = filterFactory.or([
                        filterFactory.and([
                          {
                            path: 'movimientoStock.depositoOrigen.id',
                            equals: $scope.almacen.id
                          },
                          {
                            path: 'producto.id',
                            equals: $scope.producto.id,
                          },
                          {
                            path: 'movimientoStock.fecha',
                            equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                          }, {
                            path: 'movimientoStock.fecha',
                            equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                          },
                          {
                            path: 'movimientoStock.estado.codigo',
                            like: $scope.estado.codigo
                          },
                          {
                            path: 'movimientoStock.contrapartida',
                            equals: false
                          },
                          {
                            path: 'id',
                            sortAsc: 'true'
                          }
                        ]).value(),
                        filterFactory.and([
                          {
                            path: 'movimientoStock.depositoDestino.id',
                            equals: $scope.almacen.id
                          },
                          {
                            path: 'producto.id',
                            equals: $scope.producto.id,
                          },
                          {
                            path: 'movimientoStock.fecha',
                            equalOrAfter: ($scope.fechaDesde != undefined) ? moment.utc($scope.fechaDesde.setHours(0, 0)).format('DD/MM/YYYY HH:mm') : undefined
                          }, {
                            path: 'movimientoStock.fecha',
                            equalOrBefore: ($scope.fechaHasta != undefined) ? moment.utc($scope.fechaHasta.setHours(23, 59)).format('DD/MM/YYYY HH:mm') : undefined
                          },
                          {
                            path: 'movimientoStock.estado.codigo',
                            like: $scope.estado.codigo
                          },
                          {
                            path: 'movimientoStock.contrapartida',
                            equals: true,
                          },
                          {
                            path: 'id',
                            sortAsc: 'true'
                          }
                        ]).value()
                      ]).value();
                      var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), staticFilterReport);
                    }
                    ReportTicketFactory.ticket('consultaMovimientoStock', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function (genTicket) {
                      $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                      $window.open($scope.pdfDownloadURL, '_blank');
                    });
                  }
                },
                {
                  'title': 'CSV',
                  'icon': 'glyphicon glyphicon-export',
                  'showCondition': function () { return true; },
                  'action': function () {
                    var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                    CsvFactory.csv("movimientostockdetalle", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                      .then(function (response) {
                        console.log("response de csv: ", response);
                        var fileName = response.data.fileName;
                        $window.open(baseurl.getPublicBaseUrl() + fileName);
                      });
                  }
                }
              ]
          };

        });
      });
      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
            search = search.split("~");
            if (search.length > 1) {
                /* El parámetro es un rago */

                var isDate = search[0].split("/");

                if (isDate.length > 1) {
                    /* Es un rago de fechas */

                    filtersArr.push({path: data, equalOrAfter: search[0]})
                    filtersArr.push({path: data, equalOrBefore: search[1]})
                } else {
                    /* El un rago numérco */

                    filtersArr.push({path: data, greaterOrEqual: search[0]})
                    filtersArr.push({path: data, lessOrEqual: search[1]})
                }
            } else {
                /* El parametro no es un rago */
                filtersArr.push({path: data, like: search[0]})
            }
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
    }
    }]);

'use strict';


angular.module('qualita.stock')
  .controller('InventarioProductosIndexCtrl',
    ["$scope", "ProductoAlmacenFactory", "filterFactory", "ReportTicketFactory", "$window", "AlmacenFactory", "ProductosFactory", "notify", "GrupoProductoAlmacenFactory", "UtilFactory", "StockLangFactory", "WatchLocacionChangeFactory", "$filter", "CsvFactory", "baseurl", "TiposTipoFactory", "LineasProductoFactory", "MarcasFactory", "SucursalesFactory", function ($scope, ProductoAlmacenFactory, filterFactory, ReportTicketFactory,
      $window, AlmacenFactory, ProductosFactory, notify, GrupoProductoAlmacenFactory, UtilFactory,
      StockLangFactory, WatchLocacionChangeFactory, $filter,CsvFactory, baseurl, TiposTipoFactory, 
      LineasProductoFactory, MarcasFactory, SucursalesFactory) {

      var vm = this;
      vm.uiBlockuiConfig = {
        'bloquear': false,
        'message': 'Descargando Csv...'
      };

      $scope.curDate = new Date();
      $scope.producto = undefined;
      $scope.almacen = undefined;
      $scope.unidadMedida = undefined;

      $scope.filtroPersonalizado = false;
      $scope.mensajeEsBase = true;
      $scope.fecha = new Date();

      getAlmacenes();

      getMarcas();

      getSucursales();

      WatchLocacionChangeFactory.killAllWatchers();
      if ($scope.watcher) { $scope.watcher(); }
      WatchLocacionChangeFactory.executeWatching(function (locacionFilter, watcher) {
        $scope.watcher = watcher;
        $scope.productos = ProductosFactory.allForSelect(locacionFilter);
      }, "locaciones.id");
      $scope.unidades = undefined;
      $scope.multiplicador = 1;

      var almacenFiltro = undefined;
      var productoFiltro = undefined;
      var unidadMedidaFiltro = undefined;

      //Banderas para deshabilitar filtro producto
      $scope.disableLineaFilter = false;
      $scope.disableProductFilter = false;

      //filtros para existencias
      $scope.existenciapositiva = false;
      $scope.existencianegativa = false;
      $scope.existenciacero = false;
      $scope.existenciatodos = true;

      $scope.clasesProducto = TiposTipoFactory.get("clase_linea_producto");

      var params = {};
      params.search = filterFactory.and([{
          path: 'clase.codigo',
          equals: 'producto'
        },{
          path: 'codigo',
          sortAsc: true
        }
      ]);

      LineasProductoFactory.getAllTree(params).$promise.then(function(response) {
        $scope.lineasProducto = response;
      });

      function getAlmacenes(){

        var params = {};
       /* var filtros = [];
        if(filtro) {
          filtros.push(filtro);
        }*/

       // params.search = filterFactory.and(filtros).value();
        params.view = "BaseList";

        AlmacenFactory.all(params).$promise.then(function (response) {
          $scope.almacenes = response;
          $scope.almacenes.push({ 'id': '', 'codigo': '', 'descripcion': 'Todos' });
          $scope.almacen = $scope.almacenes[$scope.almacenes.length - 1];
        });
      }

      function getMarcas(){

        var params = {};
       /* var filtros = [];
        if(filtro) {
          filtros.push(filtro);
        }*/

       // params.search = filterFactory.and(filtros).value();
        params.view = "BaseList";

        MarcasFactory.all(params).$promise.then(function (response) {
          $scope.marcas = response;
          $scope.marcas.push({ 'id': '', 'codigo': '', 'descripcion': 'Todos' });
          $scope.marca = $scope.marcas[$scope.marcas.length - 1];
        });
      }

      function getSucursales(){

        var params = {};

        params.view = "BaseList";

        SucursalesFactory.all(params).$promise.then(function (response) {
          $scope.listaSucursales = response;
          $scope.listaSucursales.push({ 'id': '', 'codigo': '', 'nombre': 'Todos' });
          $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];
        });
      }
      function getLineasProducto(filtro) {
        var params = {};

        var filtros = [{
          path: 'ultimoNivel',
          equals: true
        }];
        if(filtro) {
          filtros.push(filtro);
        }

        params.search = filterFactory.and(filtros).value();
        return LineasProductoFactory.all(params);
      }

      $scope.changeLinea = function(){
        $scope.producto = undefined;
        $scope.disableProductFilter = true;

      };

      $scope.changeClase = function(){
        $scope.producto = undefined;
        $scope.almacen = undefined;
        $scope.unidadMedida = undefined;

        // Volver a filtrar lineas
        var params = {};
        if($scope.clase.codigo === "activo_fijo") {
          params.search = filterFactory.and([{
            path: 'clase.codigo',
            equals: 'activo_fijo'
          }]).value();
        } else {
          params.search = filterFactory.and([{
            path: 'clase.codigo',
            notEquals: 'activo_fijo'
          }]).value();
        }

        LineasProductoFactory.getAllTree(params).$promise.then(function(response) {
          $scope.lineasProducto = response;
        });

        // Volver a filtrar almacenes
        $scope.almacenes = getAlmacenes();
       /* if($scope.clase.codigo === "activo_fijo") {
           = getAlmacenes({
            path: "clase.codigo",
            equals: "activo_fijo"
          });
        } else {
          $scope.almacenes = getAlmacenes({
            path: "clase.codigo",
            notEquals: "activo_fijo"
          });
        }*/
      }

      $scope.cleanFilters = function(){

        $scope.almacen = $scope.almacenes[$scope.almacenes.length - 1];
        $scope.linea = undefined;
        $scope.producto = undefined;
        $scope.unidad = undefined;
        $scope.fecha = undefined;
        $scope.clase = undefined;
        $scope.marca = $scope.marcas[$scope.marcas.length - 1];
        $scope.sucursal = $scope.listaSucursales[$scope.listaSucursales.length - 1];

        $scope.disableLineaFilter = false;
        $scope.disableProductFilter = false;
      };


      $scope.delayRequestProducto = function(searchValue){
        var userInputFilter = {};
        var staticFilter = [];
        if (searchValue.length >= 3) {
                var filterNombre = staticFilter.slice()
                var filterCodigoBarras = staticFilter.slice()
                var filterCodigoInterno = staticFilter.slice()

                filterNombre.push({
                    path: "nombre",
                    like: searchValue
                })

                filterCodigoBarras.push({
                    path: "codigoBarrasPrincipal.codigoBarras",
                    like: searchValue
                })

                filterCodigoInterno.push( {
                    path: "codigo",
                    like: searchValue
                })

                //Si existe filtro de clase
                if($scope.clase){
                  var filterClaseSelected = {};

                  if($scope.clase.codigo === "activo_fijo"){
                    filterClaseSelected = {
                      path: "claseProducto.tipoProducto.nombre",
                      equals: "Activo Fijo"
                    }
                  }else{
                    filterClaseSelected = {
                      path: "claseProducto.tipoProducto.nombre",
                      notEquals: "Activo Fijo"
                    }
                  }

                  // Se asigna a los filtros independientes OR, el filtro de clase
                  filterNombre.push(filterClaseSelected);
                  filterCodigoBarras.push(filterClaseSelected);
                  filterCodigoInterno.push(filterClaseSelected);

                }

                //Si existe filtro de Almacen
                if($scope.almacen){
                  var filterAlmacenSelected = {};

                  filterAlmacenSelected = {
                    path: "almacenes.almacen.id",
                    equals: $scope.almacen.id
                  }

                  // Se asigna a los filtros independientes OR, el filtro de almacen
                  filterNombre.push(filterAlmacenSelected);
                  filterCodigoBarras.push(filterAlmacenSelected);
                  filterCodigoInterno.push(filterAlmacenSelected);

                }


                //Si existe filtro de Linea producto
                if($scope.linea){
                  var filterLineaProductoSelected = {};

                  filterLineaProductoSelected = {
                    path: "lineaProducto.id",
                    equals: $scope.linea.id
                  }

                  // Se asigna a los filtros independientes OR, el filtro de linea
                  filterNombre.push(filterLineaProductoSelected);
                  filterCodigoBarras.push(filterLineaProductoSelected);
                  filterCodigoInterno.push(filterLineaProductoSelected);

                }


            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterCodigoBarras).value(),
                    filterFactory.and(filterCodigoInterno).value()

                ]).value()
            }
            $scope.recuperarProducto(userInputFilter)

        }else if (searchValue.length > 0 && searchValue.length < 3)  {

          var filterCodigoInterno = staticFilter.slice()
          filterCodigoInterno.push( {
              path: "codigo",
              like: searchValue
          })

           userInputFilter = {
              search:  filterFactory.or([
                   filterFactory.and(filterCodigoInterno).value()
                ]).value()
          }
          $scope.recuperarProducto(userInputFilter)

        }else{
          $scope.productos = undefined;
        }
      }

      $scope.recuperarProducto = function (userInputFilter) {
         var finalFilter = UtilFactory.createNestedFilters(
              $scope.finalFilters, userInputFilter)

          $scope.productos = ProductosFactory.allForSelect(userInputFilter)
      };

      $scope.changeAlmacen = function (almacen) {
        almacenFiltro = almacen;
        $scope.producto = undefined;
      };

      $scope.changeProducto = function (producto) {

        productoFiltro = producto;
        $scope.unidades = producto.unidadesMedida;
        $scope.unidad = producto.unidadMedidaBase;
        $scope.multiplicador = 1;
        $scope.changeUnidad($scope.unidad);

        // Limpia filtro familia y lo deshabilita
        $scope.linea = undefined;
        $scope.disableLineaFilter=true;
      };

      $scope.changeUnidad = function (unidadMedida) {
        var actual = unidadMedida;
        $scope.multiplicador = 1;
        if (!actual.esBase) {
          $scope.multiplicador = $scope.multiplicador * actual.cantidad;
          actual = actual.unidadContenida;
        }
      };

      var unidadMedidaRenderCantidad = function (data) {
        if (data != undefined) {

          var digitosDesdePunto = data.toString().substr(data.toString().indexOf('.'));
          //console.log("digitos desde el punto decimal: ", digitosDesdePunto);

          if(data != 0 && digitosDesdePunto != 0){
              data = parseFloat(data).toFixed(4);
          }

          // El calculo para convertir entre unidades consiste en la division por $scope.multiplicador
          data = data / $scope.multiplicador;
          data = Math.round(data * 100)/100;

          var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
          var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);



          // Si tiene parte entera
          if(parteEntera != ''){
              if(digitosDesdePunto != 0){
                  return (
                      parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                      ","+parteDecimal.toString()
                  );
              }else{
                  parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              }
          }else{
              return (
                  data
                  .toString()
                  .replace(".", ",")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              );
          }

        } else {
            return "";
        }
      };

      var unidadMedidaRenderCantidad2 = function (data) {
        if (data != undefined) {

          var digitosDesdePunto = data.toString().substr(data.toString().indexOf('.'));
          //console.log("digitos desde el punto decimal: ", digitosDesdePunto);

          if(data != 0 && digitosDesdePunto != 0){
              data = parseFloat(data).toFixed(4);
          }

          // El calculo para convertir entre unidades consiste en la division por $scope.multiplicador
          data = Math.round(data * 100)/100;

          var parteEntera= data.toString().substr(0, data.toString().indexOf('.'));
          var parteDecimal= data.toString().substr(data.toString().indexOf('.')+1);



          // Si tiene parte entera
          if(parteEntera != ''){
              if(digitosDesdePunto != 0){
                  return (
                      parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")+
                      ","+parteDecimal.toString()
                  );
              }else{
                  parteEntera.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              }
          }else{
              return (
                  data
                  .toString()
                  .replace(".", ",")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              );
          }

        } else {
            return "";
        }
      };

      var unidadMedidaRenderCosto = function (data) {
        if (data != null) {
          var moneda = "Gs. ";
          data = parseFloat(data).toFixed(2);
          return moneda + data.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
          return '';
        }
      };

      var emptyRender = function (data) {
        if (data || data === 0) {
          return data;
        } else {
          return '';
        }
      };

      /*var costoTotalRender = function(data, type, row) {
        var moneda = "Gs. ";
        var dataAux = parseFloat(row.ultimoCosto * row.saldoReal * $scope.multiplicador).toFixed(2);
        return moneda + (dataAux).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      };*/

      var sumaTotalSaldos = function (data) {
        if (data == undefined)
          return "";
        else
          return data / $scope.multiplicador;
      };

      var renderCostoUnitario = function (data) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        var dataAux = parseFloat(data * $scope.multiplicador).toFixed(2);
        return moneda + (dataAux).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      var renderCostoTotal = function (data, type, row) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        var dataAux = parseFloat(row.ultimoCosto * row.saldoReal).toFixed(2);
        return moneda + (dataAux).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      var renderVentaTotal = function (data, type, row) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        return moneda + (data).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      var renderCostoPromedioTotal = function (data, type, row) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        return moneda + (data).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      var renderCostoUnitario2 = function (data) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        return moneda + (data).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };

      var renderCostoTotal2 = function (data, type, row) {
        if (data == undefined)
          return "";
        else
          var moneda = "Gs. ";
        return moneda + (data).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };


      $scope.searchProductoFilter = function (criteria) {
        return function (item) {
          if (!criteria) {
            return true;
          }
          return item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 || item.codigo == criteria ||
          (item.codigoBarrasPrincipal && item.codigoBarrasPrincipal.codigoBarras.indexOf(criteria)) === 0;
        };
      }

      $scope.cambiarFiltroPersonalizado = function () {

        // Se debe mostrar el datatable personalizado
        $scope.filtroPersonalizado = true;
        $scope.filtro = false;

        $scope.cambiarFiltro(false);
      };

      $scope.changeExistencia = function (value) {
        $scope.existenciapositiva = false;
        $scope.existencianegativa = false;
        $scope.existenciacero = false;
        $scope.existenciatodos = false;
        if(value === "positivo"){
          $scope.existenciapositiva = true;
        } else
        if(value === "negativo"){
          $scope.existencianegativa = true;
        } else
        if(value === "cero"){
          $scope.existenciacero = true;
        } else
        if(value === "todos"){
          $scope.existenciatodos = true;
        }
      };

      $scope.cambiarFiltro = function (byUser) {
          if($scope.fecha != undefined){
            $scope.filtro = true;
            // Si la funcion fue invocada por click del usuario, usar el datatable original
            if(byUser){
              $scope.filtro = true;
              $scope.filtroPersonalizado = false;
            }else{
              $scope.filtroPersonalizado = true;
              $scope.filtro = false;
            }

            // Actualizacion de banderas para "observaciones"
            if($scope.unidad && $scope.unidad.esBase){
              $scope.mensajeEsBase = true;
            }else{
              $scope.mensajeEsBase = false;
            }

            var staticFilter = [];

            if ($scope.almacen == undefined && $scope.producto == undefined) {
              staticFilter.push({
                path: $scope.filtro ? 'producto.lineaProducto.id' : 'dia',
                sortDesc: 'true'
              });
              if ($scope.linea) {
                staticFilter.push({
                  path: $scope.filtro ? "producto.lineaProducto.id" : "grupoProducto.producto.lineaProducto.id",
                  equals: $scope.linea.id
                });
              }
            } else if ($scope.almacen !== undefined && $scope.producto !== undefined) {

              staticFilter.push({
                path: $scope.filtro ? 'producto.id' : 'dia',
                sortDesc: 'true'
              },
                {
                  path: $scope.filtro ? 'producto.id' : 'grupoProducto.producto.id',
                  equals: $scope.producto.id
                });
              if ($scope.linea) {
                staticFilter.push({
                  path: $scope.filtro ? "producto.lineaProducto.id" : "grupoProducto.producto.lineaProducto.id",
                  equals: $scope.linea.id
                });
              }
            } else if (!$scope.almacen && $scope.producto) {

              staticFilter.push(
                {
                  path: $scope.filtro ? 'producto.id' : 'dia',
                  sortDesc: 'true'
                },
                {
                  path: 'producto.id',
                  equals: $scope.producto.id
                });
              if ($scope.linea) {
                staticFilter.push({
                  path: $scope.filtro ? "producto.lineaProducto.id" : "grupoProducto.producto.lineaProducto.id",
                  equals: $scope.linea.id
                });
              }
            } else if ($scope.almacen && !$scope.producto) {

              staticFilter.push({
                path: $scope.filtro ? 'producto.lineaProducto.nombre' : 'dia',
                sortDesc: 'true'
              });
              if ($scope.linea) {
                staticFilter.push({
                  path: $scope.filtro ? "producto.lineaProducto.id" : "grupoProducto.producto.lineaProducto.id",
                  equals: $scope.linea.id
                });
              }
            }

            if ($scope.fecha) {
              $scope.filtroPersonalizado ? staticFilter.push({
                path: 'dia',
                equalOrBefore: moment($scope.fecha).format('DD/MM/YYYY')
              }) : staticFilter.push({
                path: 'dia',
                equals: moment($scope.fecha).format('DD/MM/YYYY')
              });
            }
            //Si existe filtro de Clase
          if($scope.clase){

              var filterClaseSelected = {
                path: $scope.filtro ? "producto.claseProducto.tipoProducto.nombre" : "grupoProducto.producto.claseProducto.tipoProducto.nombre",
                equals: "Activo Fijo"
              }

              if($scope.clase.codigo === "activo_fijo"){
                filterClaseSelected = {
                    path: $scope.filtro ? "producto.claseProducto.tipoProducto.nombre" : "grupoProducto.producto.claseProducto.tipoProducto.nombre",
                      equals: "Activo Fijo"
                    }
              }else{
                $scope.filtroPersonalizado ? filterClaseSelected = {
                    path: $scope.filtro ? "producto.claseProducto.tipoProducto.nombre" : "grupoProducto.producto.claseProducto.tipoProducto.nombre",
                    notEquals: "Activo Fijo"
                } :
                filterClaseSelected = {
                  path: $scope.filtro ? "producto.claseProducto.tipoProducto.nombre" : "grupoProducto.producto.claseProducto.tipoProducto.nombre",
                  equals: "Producto"
                }
              }

              staticFilter.push(filterClaseSelected);
            }

            var existeFilterLineaProducto = _.find(staticFilter, function(filtro){
              return "producto.lineaProducto.id" != filtro.path;
            });

            //Si existe filtro de Linea producto
            if($scope.linea && !existeFilterLineaProducto){
              var filterLineaProductoSelected = {};

              filterLineaProductoSelected = {
                path: $scope.filtro ? "producto.lineaProducto.id" : "grupoProducto.producto.lineaProducto.id",
                equals: $scope.linea.id
              }

              staticFilter.push(filterLineaProductoSelected);
            }

            //Si existe filtro de Almacen
            if($scope.almacen){
              if($scope.almacen.id){
                var filterAlmacenSelected = {};

                filterAlmacenSelected = {
                  path: "almacen.id",
                  equals: $scope.almacen.id
                }

              /*if($scope.clase!=undefined){
                var filterAlmacenClase = {};

                if($scope.clase.codigo === "activo_fijo"){
                  filterAlmacenClase = {
                    path: "almacen.clase.codigo",
                    equals: "activo_fijo"
                  }
                }else{
                  filterAlmacenClase = {
                    path: "almacen.clase.codigo",
                    notEquals: "activo_fijo"
                  }
                }
                staticFilter.search.filters.push(filterAlmacenClase);
              }*/
                staticFilter.push(filterAlmacenSelected);
              }
            }

            //Si existe filtro unidad de medida
            if($scope.unidad){
              var filterUnidadSelected = {};

              filterUnidadSelected = {
                path: $scope.filtro ? "producto.unidadesMedida.id" : "grupoProducto.producto.unidadesMedida.id",
                equals: $scope.unidad.id
              }

              staticFilter.push(filterUnidadSelected);
            }

            //Si existe filtro unidad de medida
            if($scope.existenciapositiva){
              var filterPositivo = {};

              filterPositivo = {
                path: "saldoTotal",
                equals: "positivo"
              }

              staticFilter.push(filterPositivo);
            }

            //Si existe filtro unidad de medida
            if($scope.existencianegativa){
              var filterNegativo = {};

              filterNegativo = {
                path: "saldoTotal",
                equals: "negativo"
              }

              staticFilter.push(filterNegativo);
            }

            //Si existe filtro unidad de medida
            if($scope.existenciacero){
              var filterCero = {};

              filterCero = {
                path: "saldoTotal",
                equals: "cero"
              }

              staticFilter.push(filterCero);
            }

            if($scope.marca){
              if($scope.marca.id){
                var filter = {};
                if($scope.filtroPersonalizado){
                  filter = {
                    path: "grupoProducto.producto.marca.id",
                    equals: $scope.marca.id
                  }
                } else {
                  filter = {
                    path: "producto.marca.id",
                    equals: $scope.marca.id
                  }
                }

                staticFilter.push(filter);
              }
            }

            if($scope.sucursal){
              if($scope.sucursal.id){
                var filter = {};

                filter = {
                  path: "almacen.sucursal.id",
                  equals: $scope.sucursal.id
                }

                staticFilter.push(filter);
              }
            }

            // Se define el filtro final
            var filtros = {
              search: filterFactory.and(staticFilter).value()
            };
            $scope.options.staticFilter = filtros;
            $scope.optionsPersonalizado.staticFilter = filtros;
           // WatchLocacionChangeFactory.watchWith(staticFilter, $scope.options, "producto.locaciones.id");
           // WatchLocacionChangeFactory.watchWith(staticFilter, $scope.optionsPersonalizado, "grupoProducto.producto.locaciones.id");
          }else{
            var msg = $filter('translate')('FECHA_OBLIGATORIO');
            notify({ message: msg, classes: 'alert-danger', position: 'right' });
          }
      };

      /*****************************  Datatable Original  *******************************/

      var defaultColumnOrder = ['id', 'claseProducto', 'lineaProducto', 'almacen', 'codigoProducto', 'codigoBarras', 'descripcion',
      'producto.unidadMedidaBase.abreviatura', 'producto.marca.descripcion', 'existencia', 'existenciaTransito', 'existenciaTotal'
       /*'ultimoCosto','ultimoCostoIVA' , 'costoPromedio','costoPromedioIVA', 'precioVenta', 'totalUltimoCostoValorizado', 'totalPromedioValorizado', 'totalPrecioVenta',*/
      ];

      StockLangFactory.getTranslations().then(function (translations) {
        $scope.options = {
          'resource': 'productoalmacen/newdatatable',
          'title': 'Inventario de Productos',
          'factory': ProductoAlmacenFactory,
          'view': 'InventarioValorizadoList',
          'defaultColumnOrder': defaultColumnOrder,
          'columns': [
            { 'data': 'id', 'title': 'Código', 'searchable': false },
            { 'data': 'claseProducto', 'title': $filter('translate')('PRODUCT_CLASS'), 'searchable': false, 'orderable': false, visible: false },
            { 'data': 'lineaProducto', 'title': $filter('translate')('PRODUCT_LINE'), 'searchable': false, 'orderable': false, visible: false },
            { 'data': 'almacen', 'title': $filter('translate')('ALMACEN'), 'searchable': false, 'orderable': false },
            { 'data': 'codigoProducto', 'title': $filter('translate')('CODIGO_PRODUCTO'), 'searchable': false, 'orderable': true, 'class': 'dt-right' },
            { 'data': 'codigoBarras', 'title': $filter('translate')('CODIGO_BARRA_PRODUCTO'), 'searchable': false, 'orderable': false, 'class': 'dt-right', 'render': emptyRender, visible: false },
            { 'data': 'descripcion', 'title': $filter('translate')('DESCRIPTION'), 'searchable': false, 'orderable': true },
            { 'data': 'unidadMedida', 'title': $filter('translate')('UNIDAD_MEDIDA_ABREVIADO'), 'searchable': false, 'orderable': false, visible: false },
            { 'data': 'marca', 'title': $filter('translate')('MARCA'), 'render': emptyRender, 'searchable': false, 'orderable': false, visible: false },
            { 'data': 'existencia', 'title': $filter('translate')('EXISTENCIA'), 'render': unidadMedidaRenderCantidad2, 'class': 'dt-right', 'searchable': false, 'orderable': false },
            { 'data': 'existenciaTransito', 'title': $filter('translate')('EXISTENCIA_TRANSITO'), 'render': unidadMedidaRenderCantidad2, 'class': 'dt-right', searchable: false, 'orderable': false },
            { 'data': 'existenciaTotal', 'title': $filter('translate')('EXISTENCIA_TOTAL'), 'render': unidadMedidaRenderCantidad2, 'class': 'dt-right', searchable: false, 'orderable': false }
            /*{ 'data': 'ultimoCosto', 'title': $filter('translate')('ULTIMO_COSTO'), 'render': renderCostoUnitario2, 'class': 'dt-right', 'searchable': false, 'orderable': false },
            { 'data': 'ultimoCostoIVA', 'title': $filter('translate')('ULTIMO_COSTO_IVA'), visible: vm.mostrarIva, 'class': 'dt-right', 'renderWith': renderCostoUnitario, 'searchable': false, 'orderable': false },
            { 'data': 'costoPromedio', 'title': $filter('translate')('COSTO_PROMEDIO'), 'render': renderCostoUnitario2, 'class': 'dt-right', 'searchable': false, 'orderable': false },
            { 'data': 'costoPromedioIVA', 'title': $filter('translate')('COSTO_PROMEDIO_IVA'), visible: vm.mostrarIva, 'class': 'dt-right', 'renderWith': renderCostoUnitario, 'searchable': false, 'orderable': false },
            { 'data': 'precioVenta', 'title': $filter('translate')('PRECIO_VENTA'), 'render': unidadMedidaRenderCosto, 'class': 'dt-right', 'searchable': false, 'orderable': false },
            { 'data': 'totalUltimoCostoValorizado', 'title': $filter('translate')('TOTAL_ULTIMO_COSTO'), 'render': renderCostoTotal2, 'class': 'dt-right', 'searchable': false, 'orderable': false },
            { 'data': 'totalPromedioValorizado', 'title': $filter('translate')('TOTAL_COSTO_PROMEDIO'), 'render': renderCostoPromedioTotal, 'class': 'dt-right', 'searchable': false, 'orderable': false },
            { 'data': 'totalPrecioVenta', 'title': $filter('translate')('TOTAL_PRECIO_VENTA'), 'render': renderVentaTotal, 'class': 'dt-right', 'searchable': false, 'orderable': false } */
          ],
          'extraMenuOptions':
            [
              {
                'title': $filter('translate')('REPORT'),
                'icon': 'glyphicon glyphicon-file',
                'showCondition': function () { return true; },
                'action': function () {
                  var filtersArr = [];
                  if ($scope.clase) {
                    filtersArr.push("clase=" + $scope.clase.descripcion);
                  }
                  if ($scope.almacen && $scope.almacen.id) {
                    filtersArr.push("idalmacen=" + $scope.almacen.id);
                  }
                  if ($scope.producto && $scope.producto.id) {
                    filtersArr.push("idproducto=" + $scope.producto.id);
                  }
                  if ($scope.unidad && $scope.unidad.id) {
                    filtersArr.push("idunidadmedida=" + $scope.unidad.id);
                  }
                  if ($scope.fecha) {
                    filtersArr.push("fecha=" + moment($scope.fecha).format("MM/DD/YYYY"));
                  }
                  if ($scope.existenciapositiva) {
                    filtersArr.push("existencia=positivo");
                  }
                  if ($scope.existencianegativa) {
                    filtersArr.push("existencia=negativo");
                  }
                  if ($scope.existenciacero) {
                    filtersArr.push("existencia=cero");
                  }
                  if ($scope.marca && $scope.marca.id) {
                    filtersArr.push("idmarca=" + $scope.marca.id);
                  }
                  if ($scope.sucursal && $scope.sucursal.id) {
                    filtersArr.push("idsucursal=" + $scope.sucursal.id);
                  }
                  if ($scope.linea && $scope.linea.id) {
                    filtersArr.push("idlineaproducto=" + $scope.linea.id);
                  }

                  filtersArr.push("esConsultaInventarioProductos=true");

                  var pdfDownloadURL = ReportTicketFactory.downloadCustomReport("inventariovalorizado", 'pdf', filtersArr);
                  $window.open(pdfDownloadURL, '_blank');
                  console.log("report");
                }
              },
              {
                'title': 'CSV',
                'icon': 'glyphicon glyphicon-export',
                'showCondition': function () { return true; },
                'action': function () {
                  var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);
                  vm.uiBlockuiConfig.bloquear = true;
                  CsvFactory.csv("productoalmacen", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                    .then(function (response) {
                      vm.uiBlockuiConfig.bloquear = false;
                      console.log("response de csv: ", response);
                      var fileName = response.data.fileName;
                      $window.open(baseurl.getPublicBaseUrl() + fileName);
                    }, function (response) {
                      vm.uiBlockuiConfig.bloquear = false;
                    }
                    );
                }
              }
            ],
          'hasOptions': false,
          'hideEditMenu': false,
          'hideRemoveMenu': false,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "asc",
          'extraRowOptions': []
        };
      })
      


      /*****************************  Datatable Personalizado  *******************************/

      var defaultColumnOrderPersonalizado = ['id', 'grupoProducto.producto.proveedorPrincipal.nombre', 'grupoProducto.producto.codigo',
      'saldoReal', 'grupoProducto.producto.nombre', 'grupoProducto.numero', 'grupoProducto.fechaVencimiento', /*'ultimoCosto', 'costoTotal',*/ 
      'producto.unidadMedidaBase.abreviatura','producto.marca.descripcion'];

      StockLangFactory.getTranslations().then(function (translations) {
        $scope.optionsPersonalizado = {
          'resource': 'grupoproductoalmacen/saldogrupoproducto',
          'title': 'Inventario de Productos',
          'factory': GrupoProductoAlmacenFactory,
          'view': 'InventarioValorizadoList',
          'defaultColumnOrder': defaultColumnOrderPersonalizado,
          'columns': [
            { 'data': 'id', 'title': $filter('translate')('ID'), 'searchable': false, 'orderable': false, 'visible': false },
            { 'data': 'grupoProducto.producto.proveedorPrincipal.nombre', 'title': $filter('translate')('PROVEEDOR_INDEX'), 'searchable': false, 'orderable': false },
            { 'data': 'grupoProducto.producto.codigo', 'title': $filter('translate')('CODIGO_PRODUCTO'), 'searchable': false, 'orderable': false },
            { 'data': 'grupoProducto.producto.nombre', 'title': $filter('translate')('DESCRIPTION'), 'searchable': false, 'orderable': false },
            { 'data': 'grupoProducto.producto.unidadMedidaBase.abreviatura', 'title': $filter('translate')('UNIDAD_MEDIDA_ABREVIADO'), 'searchable': false, 'orderable': false, visible: false},
            { 'data': 'grupoProducto.producto.marca.descripcion', 'title': $filter('translate')('MARCA'), 'render': emptyRender, 'searchable': false, 'orderable': false, visible: false},
            { 'data': 'grupoProducto.numero', 'title': $filter('translate')('NRO_SERIE'), 'searchable': false, 'orderable': false },
            { 'data': 'grupoProducto.fechaVencimiento', 'title': $filter('translate')('FECHA_VENCIMIENTO'), 'searchable': false, 'orderable': false, 'render': emptyRender},
            { 'data': 'saldoReal', 'title': $filter('translate')('EXISTENCIA'), 'render': unidadMedidaRenderCantidad, 'class': 'dt-right', 'searchable': false, 'orderable': false  }
            /* { 'data': 'ultimoCosto', 'title': $filter('translate')('COSTO_UNITARIO'), 'render': renderCostoUnitario, 'class': 'dt-right', 'searchable': false, 'orderable': false  },
            { 'data': 'costoTotal', 'title': $filter('translate')('TOTAL_COSTO_UNITARIO'), 'searchable': false, 'orderable': false, 'render':renderCostoTotal } */
          ],
          'extraMenuOptions':
            [{
                'title': 'CSV',
                'icon': 'glyphicon glyphicon-export',
                'showCondition': function () { return true; },
                'action': function () {
                  var filters = UtilFactory.createNestedFilters($scope.optionsPersonalizado.getFilters(), $scope.optionsPersonalizado.staticFilter);

                  CsvFactory.csv("grupoproductoalmacen", filters, $scope.optionsPersonalizado.tableAjaxParams, $scope.optionsPersonalizado.currentColumnOrder, $scope.optionsPersonalizado.currentColumnOrderNames)
                    .then(function (response) {
                      console.log("response de csv: ", response);
                      var fileName = response.data.fileName;
                      $window.open(baseurl.getPublicBaseUrl() + fileName);
                    });
                }
              }
            ],
          'hasOptions': false,
          'hideEditMenu': false,
          'hideRemoveMenu': false,
          'defaultOrderColumn': 0,
          'defaultOrderDir': "desc",
          'extraRowOptions': []
        };
      });


      console.log($scope.options);

      var createFilters = function (filters) {
        var filtersArr = [];
        _.each(filters, function (search, data) {
          filtersArr.push({ path: data, like: search })
        });
        var filters = filterFactory.and(filtersArr).value();
        return filters;
      }

    }]);

'use strict';

angular.module('qualita.venta')
  .controller('CatalogoIndexCtrl', ["$scope", "CatalogoFactory", "$modal", "$state", "notify", "VentasLangFactory", "UtilFactory", "$window", "CsvFactory", "baseurl", "filterFactory", "ReportTicketFactory", function CatalogoIndexCtrl($scope, CatalogoFactory, $modal, $state, notify,
    VentasLangFactory, UtilFactory, $window, CsvFactory, baseurl, filterFactory, ReportTicketFactory) {


    VentasLangFactory.getTranslations().then(function (translations) {
      var defaultColumnOrder = ['id', 'cliente.nombre', 'cliente.nombreFantasia', 'cliente.codigo', 'nombre', 'activo'];

      $scope.options = {
        'resource': 'catalogos',
        'title': translations.LISTA_CATALOGOS,
        'factory': CatalogoFactory,
        'view': 'CatalogoList',
        'defaultColumnOrder': defaultColumnOrder,
        'failedDeleteError': translations.FAILED_DELETE_CATALOGOS,
        'columns': [
          { 'data': 'id', 'title': translations.CODE, 'visible': false },
          { 'data': 'cliente.nombre', 'title': translations.NOMBRE_CLIENTE_DESCRIPTIVO},
          { 'data': 'cliente.nombreFantasia', 'title': translations.NOMBRE_FANTASIA_CORTO},
          { 'data': 'cliente.codigo', 'title': translations.CODIGO_CLIENTE},
          { 'data': 'nombre', 'title': translations.NOMBRE_CATALOGO },
          { 'data': 'activo', 'title': translations.ACTIVO }
        ],
        'hasOptions': true,
        'defaultOrderColumn': 0,
        'defaultOrderDir': "desc",
        'extraRowOptions': [
          {
              templateToRender: "<button title='" +
                "Activar" +
                "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='activar(<%=dataId%>)' ng-class='{ hidden : !canActivar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-ok'></span> </button>",
              functionName: "activar",
              conditionName: "canActivar",
              customAttribute: ["activo"],
              conditionDef: function (atributos) {
                var campoActivo = atributos[0];
            
                if (/*AuthorizationService.hasPermission("activar_materiales") === true &&*/ campoActivo == 'No') {
                  return true;
                } else {
                  return false;
                }
              },
            
              functionDef: function (itemId) {
                //if (AuthorizationService.hasPermission("activar_materiales") === true) {

                  // Se verifica en el servidor que este catalogo aun existe ?
                  //CatalogoFactory.get(itemId, "CatalogoForm").$promise.then(function (response) {
                    //if (response) {
                      $scope.mensajeModal = 'Esta seguro de querer activar el catalogo?';
                      $scope.tituloModal = 'Activacion';
                      var modalInstance = $modal.open({
                        templateUrl: "views/directives/confirmation-modal.html",
                        scope: $scope
                      });
            
                      $scope.cancel = function () {
                        modalInstance.dismiss("cancel");
                      };
            
                      $scope.ok = function (id) {
                        CatalogoFactory.activar(itemId).then(function (response) {
                          modalInstance.close();
                          $state.go($state.current, {}, { reload: true });
                        }, function (error) {
                          var mensaje = "<span>";
                          _.forEach(error.data, function (err) {
                            mensaje = mensaje + err.message + "<br/>";
                          });
                          mensaje = mensaje + "</span>";
                          notify({
                            messageTemplate: mensaje,
                            classes: "alert-danger",
                            position: "right",
                            templateUrl: ""
                          });
                        });
                      };
                    //}
                  //});
              /*  } else {
                  notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
                } */
              }
          },

          {
          templateToRender: "<button title='" +
              'Desactivar' +
              "' class='btn btn-danger btn-dt' style='margin-right: 5px;' ng-click='desactivar(<%=dataId%>)' ng-class='{ hidden : !canDesactivar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
          functionName: "desactivar",
          conditionName: "canDesactivar",
          customAttribute: ["activo"],
          conditionDef: function (atributos) {
            var campoActivo = atributos[0];
              if (/*AuthorizationService.hasPermission("desactivar_materiales") === true &&*/ campoActivo === 'Sí' || campoActivo === 'Si') {
              return true;
              } else {
              return false;
              }
          },
          
          functionDef: function (itemId) {
              //if (AuthorizationService.hasPermission("desactivar_materiales") === true) {
                
                // Se verifica en el servidor que este catalogo aun existe ?
                //CatalogoFactory.get(itemId, "CatalogoForm").$promise.then(function (response) {
                    //if (response) {
                      $scope.mensajeModal = 'Esta seguro de querer desactivar el catalogo?';
                      $scope.tituloModal = 'Desactivacion';
                      var modalInstance = $modal.open({
                          templateUrl: "views/directives/confirmation-modal.html",
                          scope: $scope
                      });
              
                      $scope.cancel = function () {
                          modalInstance.dismiss("cancel");
                      };
              
                      $scope.ok = function (id) {
                          CatalogoFactory.desactivar(itemId).then(function (response) {
                          modalInstance.close();
                          $state.go($state.current, {}, { reload: true });
                          }, function (error) {
                          var mensaje = "<span>";
                          _.forEach(error.data, function (err) {
                              mensaje = mensaje + err.message + "<br/>";
                          });
                          mensaje = mensaje + "</span>";
                          notify({
                              messageTemplate: mensaje,
                              classes: "alert-danger",
                              position: "right",
                              templateUrl: ""
                          });
                          });
                      };
                    //}
                //});
              /*} else {
              notify({ message: 'Error. No posee permiso para realizar la operación', classes: 'alert-danger', position: 'right' });
              }*/
            }
          }
        ],
        'extraMenuOptions':
          [
            {
              'title': 'Reporte',
              'icon': 'glyphicon glyphicon-file',
              'showCondition': function() { return true; },
              'action': function() {
                  var filters = createFilters($scope.options.getFilters());
                  ReportTicketFactory.ticket('listadoCatalogos', filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder).then(function(genTicket) {
                    $scope.pdfDownloadURL = ReportTicketFactory.downloadURL(genTicket.ticket, 'pdf');
                    $window.open($scope.pdfDownloadURL, '_blank');
                  });
                }
            },
            {
              'title': 'CSV',
              'icon': 'glyphicon glyphicon-export',
              'showCondition': function () { return true; },
              'action': function () {
                var filters = UtilFactory.createNestedFilters($scope.options.getFilters(), $scope.options.staticFilter);

                CsvFactory.csv("catalogos", filters, $scope.options.tableAjaxParams, $scope.options.currentColumnOrder, $scope.options.currentColumnOrderNames)
                  .then(function (response) {
                    console.log("response de csv: ", response);
                    var fileName = response.data.fileName;
                    $window.open(baseurl.getPublicBaseUrl() + fileName);
                  });
              }
            }
          ],
        };
    });

    var createFilters = function (filters) {
      var filtersArr = [];
      _.each(filters, function (search, data) {
        filtersArr.push({path: data, like: search})
      });
      var filters = filterFactory.and(filtersArr).value();
      return filters;
    }

  }]);
"use strict";

angular.module("qualita.venta").controller("CatalogoFormCtrl", [
  "$scope",
  "notify",
  "$state",
  "$rootScope",
  "catalogoPrepService",
  "VentasLangFactory",
  "$location",
  'CatalogoFactory',
  'filterFactory',
  'UtilFactory',
  'ClienteFactory',
  '$modal',
  function(
    $scope,
    notify,
    $state,
    $rootScope,
    catalogoPrepService,
    VentasLangFactory,
    $location,
    CatalogoFactory,
    filterFactory,
    UtilFactory,
    ClienteFactory,
    $modal
  ) {
    var vm = this;
    VentasLangFactory.getTranslations().then(function(translations) {

      $scope.prepService = catalogoPrepService;

      function activateNew() {
        $scope.title = $scope.newProperties.title;
        $scope.catalogo = {};
        $scope.catalogo.activo = true;
      }

      function activateEdit() {
        $scope.catalogo = $scope.prepService;
        $scope.entidadId = $scope.catalogo.id;
        $scope.entidad = $scope.editProperties.entidad;
        $scope.title = $scope.editProperties.title;
        $scope.listaNombreCodigo = $scope.catalogo.catalogoDetalles;
        // console.log("AQUI detalles guardados: ", $scope.catalogo.catalogoDetalles);
      }

      function activateView() {
        $scope.catalogo = $scope.prepService;
        $scope.entidadId = $scope.catalogo.id;
        $scope.entidad = $scope.viewProperties.entidad;
        $scope.title = $scope.viewProperties.title;
        $scope.listaNombreCodigo = $scope.catalogo.catalogoDetalles;
        $scope.view = true;
      }

      $scope.newProperties = {
        state: "app.catalogos.new",
        title: translations.NEW_CATALOGO_CLIENTE
      };
      $scope.editProperties = {
        state: "app.catalogos.edit",
        title: translations.EDIT_CATALOGO_CLIENTE,
        entidad: "Catalogo"
      };

      $scope.viewProperties = {
        state: "app.catalogos.view",
        title: translations.VIEW_CATALOGO_CLIENTE,
        entidad: "Catalogo"
      };

      $scope.activate = function() {
        if ($state.is($scope.newProperties.state)) {
          activateNew();
        } else if ($state.is($scope.editProperties.state)) {
          activateEdit();
        } else if ($state.is($scope.viewProperties.state)) {
          activateView();
        }

        $rootScope.isProcessing = false;
      };

      function delayRequestCliente(searchValue) {
        var userInputFilter = {}
        if (searchValue.length >= 3) {
            var filterNombre = [];
            var filterNombreFantasia = [];
            var filterCodigo = [];
            var filterNumero = [];
            var filterDireccion = [];
            var filterCiudad = [];

            filterNombre.push({
                path: "nombre",
                like: searchValue
            })
            filterNombreFantasia.push({
                path: "nombreFantasia",
                like: searchValue
            })
            filterCodigo.push({
                path: "codigo",
                like: searchValue
            })
            filterNumero.push({
                path: "numero",
                like: searchValue
            })
            filterDireccion.push({
                path: "direccion",
                like: searchValue
            })
            filterCiudad.push({
                path: "ciudad.descripcion",
                like: searchValue
            })

            userInputFilter = {
                search: filterFactory.or([
                    filterFactory.and(filterNombre).value(),
                    filterFactory.and(filterNombreFantasia).value(),
                    filterFactory.and(filterCodigo).value(),
                    filterFactory.and(filterNumero).value(),
                    filterFactory.and(filterDireccion).value(),
                    filterFactory.and(filterCiudad).value()
                ]).value()
            }

            recuperarCliente(userInputFilter)
        } else {
          $scope.clientes = undefined;
        }
      }

      function recuperarCliente(userInputFilter) {
          var finalFilter = UtilFactory.createNestedFilters(
            vm.finalFilters, userInputFilter);
          $scope.clientes = ClienteFactory.allForSelect(finalFilter, "CatalogoList");
      }

      function searchClienteFilter2(criteria) {
        return function (item) {
            if (!criteria) {
                return true;
            }
            return (
                item.nombre.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.codigo && item.codigo.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                item.numero.toLowerCase().indexOf(criteria.toLowerCase()) > -1 ||
                (item.direccion && item.direccion.toLowerCase().indexOf(criteria.toLowerCase())) > -1 ||
                (item.ciudad && item.ciudad.descripcion.toLowerCase().indexOf(criteria.toLowerCase())) > -1
            );
        }
      }

      function validarCliente(){
        if($scope.catalogo.cliente){
          var filtroCliente = {};
          filtroCliente.search = filterFactory.and([
            {
              path: 'cliente.id',
              equals: $scope.catalogo.cliente
            },{
              path: 'activo',
              equals: true
            }
          ]).value();
          // console.log("AQUI GET DE CATALOGO: ");
          CatalogoFactory.get(filtroCliente).$promise.then(function (responseCatalogo) {
            // console.log("AQUI hay catalogo activo: ", responseCatalogo);
            if(responseCatalogo.length > 0) {
              var msg = "Ya existe un catalogo activo para el cliente seleccionado.";
              notify({
                message: msg,
                classes: "alert-danger",
                position: "right"
              });
            }
          });
        }
      }

      $scope.listaNombreCodigo = [];
      $scope.habilitarDetalles = true;

      var param = {};
      param.search = filterFactory.and([
        {
          path: 'activo',
          like: 'true'
        }]
      ).value();

      $scope.activate();
      //$scope.detalleCatalogo = "Detalles de la asociación";

      var setNombreLista = function (data, e, row) {
        if ($scope.listaNombreCodigo.length) {
          for (var i = 0; i < $scope.listaNombreCodigo.length; i++) {
            if ($scope.listaNombreCodigo[i].producto.id === row.id) {
              return $scope.listaNombreCodigo[i].nombreCatalogo
            }
          }
        }
        return ""
      };
      var setCodigoLista = function (data, e, row) {
        if ($scope.listaNombreCodigo.length) {
          for (var i = 0; i < $scope.listaNombreCodigo.length; i++) {
            if ($scope.listaNombreCodigo[i].producto.id === row.id) {
              if($scope.listaNombreCodigo[i].codigoCatalogo >0){
                return $scope.listaNombreCodigo[i].codigoCatalogo
              }
            }
          }
        }
        return ""
      };



      var staticFilterProductos = {};

      var locacionId = null;
      if ($rootScope.AuthParams && $rootScope.AuthParams.locacionSeleccionada) {
        locacionId = $rootScope.AuthParams.locacionSeleccionada.id;
      }

      var filters = [
        {
          path: 'itemVenta',
          equals: true
        },
        {
          path: 'estado.codigo',
          equals: 'activo'
        }
      ];

      if (locacionId !== null) {
        filters.push({
          path: 'locaciones.id',
          equals: locacionId
        });
      }

      staticFilterProductos.search = filterFactory.and(filters);
      
      var defaultColumnOrder = ['id', 'codigo', 'nombre', 'nombreCatalogo', 'codigoCatalogo', 'cargadoModal.nombreCatalogo', 'cargadoModal.codigoCatalogo'];
      $scope.options = {
        resource : 'productos',
        title: 'Productos a catalogar',
        view: 'BaseList',
        factory: CatalogoFactory,
        staticFilter: staticFilterProductos,
        defaultColumnOrder: defaultColumnOrder,
        columns: [
          { 'data': 'id','title': translations.CODE, visible: false},
          { 'data': 'codigo', 'title': 'Código de Producto', 'renderWith': 'emptyRender', orderable: false},
          { 'data': 'nombre', 'title': 'Nombre de Producto', 'renderWith': 'emptyRender', orderable: false},
          { 'data': 'cargadoModal.nombreCatalogo', 'title': translations.NOMBRE_CATALOGO, 'renderWith': setNombreLista, orderable: false, searchable:false },
          { 'data': 'cargadoModal.codigoCatalogo', 'title': translations.CODIGO_CATALOGO, 'renderWith': setCodigoLista, orderable: false, searchable:false }
        ],
        hasOptions: true,
        defaultOrderColumn: 0,
        defaultOrderDir: "desc",
        hideAddMenu: true,
        hideEditMenu: true,
        hideRemoveMenu: true,
        hideViewMenu: true,
        extraRowOptions: [
          {
            templateToRender: "<button class='btn btn-info' style='margin-right: 5px; background-color: green !important; border-color: rebeccapurple' " +
              "title='Catalogar producto' ng-click='asignar(<%=dataCustom%>)' ng-class='{ hidden : !canAsignar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-log-in'></span> </button>",
            customAttribute: ["id"],
            functionName: "asignar",
            conditionName: "canAsignar",
            conditionDef: function () {
              if($scope.view){
                return false;
              }else{
                return true;
              }
            },
            functionDef: function (attr) {

              var productoEnLista = $scope.listaNombreCodigo.find(function(item) {
                return item.producto.id === attr[0];
              });
              
              if(productoEnLista != null){
                var nombreExistente = productoEnLista.nombreCatalogo;
                var codigoExistente = productoEnLista.codigoCatalogo;

                //console.log("AQUI nombreExistente: ", nombreExistente);
                //console.log("AQUI codigoExistente: ", codigoExistente);
              }

              vm.detalleCatalogo = {nombreCatalogo: nombreExistente, codigoCatalogo: codigoExistente};
              $scope.tituloModal = "Definición de producto en el catálogo"
              var modalInstance = $modal.open({
                templateUrl: 'qualita-components/ventas/views/catalogo/catalogo-modal.html',
                scope: $scope
              });
              $scope.cancel = function () {
                modalInstance.dismiss('cancel');
                //$scope.vm.listaPrecio = undefined;
              };

              $scope.ok = function () {
                if (!vm.detalleCatalogo.nombreCatalogo || !vm.detalleCatalogo.codigoCatalogo) {
                //if (false) {
                  // Si falta alguno de los campos obligatorios, muestra una notificación de error
                  notify({
                    message: "Ambos campos son obligatorios",
                    classes: "alert-danger",
                    position: "right"
                  });
                } else {
                  // Verifica si los nuevos valores ya existen en otros elementos de la lista
                  var existeNombreCatalogo = $scope.listaNombreCodigo.some(function(item) {
                    return item.nombreCatalogo === vm.detalleCatalogo.nombreCatalogo && item.producto.id !== attr[0];
                  });
              
                  var existeCodigoCatalogo = $scope.listaNombreCodigo.some(function(item) {
                    return item.codigoCatalogo === vm.detalleCatalogo.codigoCatalogo && item.producto.id !== attr[0];
                  });
              
                  if (existeCodigoCatalogo) {
                    // Muestra una notificación de error si ya existe un registro con el mismo nombre o código de catálogo.
                    notify({
                      message: "Ya existe un registro con el mismo código de catálogo.",
                      classes: "alert-danger",
                      position: "right"
                    });
                  } else if (existeNombreCatalogo) {
                    // Muestra un mensaje de error si el nombre está duplicado
                    notify({
                      message: "Ya existe un registro con el mismo nombre de catálogo.",
                      classes: "alert-danger",
                      position: "right"
                    });
                  } else {
                    // Busca el elemento actual en listaNombreCodigo
                    var elementoActual = $scope.listaNombreCodigo.find(function(item) {
                      return item.producto.id === attr[0];
                    });
              
                    if (elementoActual) {
                      // Si el elemento actual existe, actualiza sus valores

                      /*if (vm.detalleCatalogo.nombreCatalogo !== undefined) {
                        elementoActual.nombreCatalogo = vm.detalleCatalogo.nombreCatalogo;
                      }else{
                        elementoActual.nombreCatalogo = "";
                      }

                      if (vm.detalleCatalogo.codigoCatalogo !== undefined) {
                        elementoActual.codigoCatalogo = vm.detalleCatalogo.codigoCatalogo;
                      }else{
                        elementoActual.codigoCatalogo  = 0;
                      }*/

                      elementoActual.nombreCatalogo = vm.detalleCatalogo.nombreCatalogo;
                      elementoActual.codigoCatalogo = vm.detalleCatalogo.codigoCatalogo;
                      
                      // console.log("AQUI elementoActual: ", elementoActual);
                    } else {
                      // Si el elemento no existe, agrega un nuevo elemento a listaNombreCodigo
                      $scope.listaNombreCodigo.push({
                          producto: { id: attr[0] },
                          nombreCatalogo: vm.detalleCatalogo.nombreCatalogo,
                          codigoCatalogo: vm.detalleCatalogo.codigoCatalogo
                      });

                      // console.log("AQUI listaNombreCodigo: ", $scope.listaNombreCodigo);
                  }
              
                    // Cierra el modal
                    modalInstance.close();
              
                    // Recarga los datos usando la función reloadDataAndMaintainPage()
                    $scope.options.reloadDataAndMaintainPage();
                  }
                }
              };
              
            }
          },
          {
            templateToRender: "<button class='btn btn-info' style='margin-right: 5px; background-color: red !important; border-color: rebeccapurple' " +
              "title='Borrar de catalogo' ng-click='eliminar(<%=dataCustom%>)' ng-class='{ hidden : !canEliminar(<%=dataCustom%>)}'> <span class='glyphicon glyphicon-remove'></span> </button>",
            customAttribute: ["id", "codigoCatalogo", "nombreCatalogo"],
            functionName: "eliminar",
            conditionName: "canEliminar",
            conditionDef: function () {
              if($scope.view){
                return false;
              }else{
                return true;
              }
            },
            functionDef: function (attr) {
              var productoEnListaIndex = $scope.listaNombreCodigo.findIndex(function (item) {
                return item.producto.id === attr[0];
              });

              if (productoEnListaIndex !== -1) {
                $scope.listaNombreCodigo.splice(productoEnListaIndex, 1);

                // Recarga los datos usando la función reloadDataAndMaintainPage()
                $scope.options.reloadDataAndMaintainPage();
              }
            }
          },
        ],
      };

      $scope.filtro = true;
      $scope.resource = "catalogos";
      $scope.submit = submit;
      $scope.cancelar = cancelar;
      $scope.submitCatalogo = submitCatalogo;
      $scope.translations = translations;

      vm.delayRequestCliente = delayRequestCliente;
      vm.searchClienteFilter2 = searchClienteFilter2;
      vm.validarCliente = validarCliente;

      function cancelar() {
        $location.path("/catalogos");
      }

      function submit() {
        $scope.submited = true;
        if ($scope.CatalogoForm.$valid) {
          $rootScope.isProcessing = true;
          submitCatalogo().then(function(catalogo) {
            $location.path("/catalogos");
          }, errorHandler);
        }
      }

      function submitCatalogo() {
        $scope.catalogo.catalogoDetalles = $scope.listaNombreCodigo;
        // console.log("AQUI SUBMIT: ", $scope.catalogo.catalogoDetalles);
        var catalogo = CatalogoFactory.create($scope.catalogo);
        return CatalogoFactory.save(catalogo, function(resp, headers) {
          //success callback
          //console.log("RESPONSE: ", resp);
        });
      }

      function errorHandler(err) {
        console.log("Error al guardar Catalogo de clientes: ", err);
        $rootScope.isProcessing = false;
        $scope.submited = false;
        var msg = "";

        if (err.data && err.data.propertyViolations && err.data.propertyViolations[0]  
          && err.data.propertyViolations[0].path == "catalogoDetalles" 
          && err.data.propertyViolations[0].message == "no puede ser null") {
          msg += '\n\n' + "Debe catalogar al menos un producto.\n"
        }else {
          msg += '\n\n' + err.data[0].message + '.'
        }

        /*_.forEach(err.data, function(error) {
          if (error.message == "Not unique field: codigo") {
            msg += '\n\n' + "Debe catalogar al menos un producto.\n"
          }else if (error.message == "Not unique field: nombre") {
            msg += '\n\n' + "Ya existe un catalogo con el mismo nombre.\n"
          }else {
            msg += '\n\n' + error.message + '.'
          }
        });*/
        notify({
          message: msg,
          classes: "alert-danger",
          position: "right"
        });
      }
    });
  }
]);

angular.module('qualita.venta')
  .factory('CatalogoFactory', CatalogoFactory);

  CatalogoFactory.$inject = ['$resource', 'baseurl'];

function CatalogoFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove,
    activar: activar,
    desactivar: desactivar
  };

  var Catalogo = $resource(baseurl.getBaseUrl() + "/catalogos/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    },
    activar: {
      method: 'PUT',
      url: baseurl.getBaseUrl() + "/catalogos/activar/:id"
    },
    desactivar: {
      method: 'PUT',
      url: baseurl.getBaseUrl() + "/catalogos/desactivar/:id"
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'BaseForm';
    } else {
      params = [];
      params.view = view != undefined ? view : 'BaseForm';
    }
    return Catalogo.query(params);
  }

  function get(id, view) {
    return Catalogo.get({ id: id, view: view ? view : "CatalogoForm" });
  }

  function create(attrs) {
    return new Catalogo(attrs);
  }

  function save(catalogo) {
    return (catalogo.id) ? catalogo.$update() : catalogo.$save();
  }

  function remove(catalogo) {
    return catalogo.$remove();
  }

  function activar(id) {
    return Catalogo.activar({ id: id }).$promise;
  }

  function desactivar(id) {
    return Catalogo.desactivar({ id: id }).$promise;
  }
}

angular.module('qualita.venta')
  .factory('CatalogoDetalleFactory', CatalogoDetalleFactory);

  CatalogoDetalleFactory.$inject = ['$resource', 'baseurl'];

function CatalogoDetalleFactory($resource, baseurl) {
  var service = {
    all: all,
    get: get,
    create: create,
    save: save,
    remove: remove
  };

  var Catalogo = $resource(baseurl.getBaseUrl() + "/catalogosdetalle/:id", { id: '@id' }, {
    update: {
      method: 'PUT'
    }
  });

  return service;

  function all(params, view) {
    if (params) {
      params.view = view != undefined ? view : 'BaseForm';
    } else {
      params = [];
      params.view = view != undefined ? view : 'BaseForm';
    }
    return Catalogo.query(params);
  }

  function get(id, view) {
    return Catalogo.get({ id: id, view: view ? view : "CatalogoForm" });
  }

  function create(attrs) {
    return new Catalogo(attrs);
  }

  function save(catalogo) {
    return (catalogo.id) ? catalogo.$update() : catalogo.$save();
  }

  function remove(catalogo) {
    return catalogo.$remove();
  }
}

'use strict';

angular.module('qualita.venta')
  .controller('ImportarRemisionFormCtrl', ImportarRemisionFormCtrl);


ImportarRemisionFormCtrl.$inject = ['$scope', 'RemisionFactory', '$location', '$state', 'VentasLangFactory','notify', '$window', 'baseurl', '$rootScope',
'$filter', 'TimbradoEmpresaPuntoEmisionVentaFactory', 'filterFactory', 'AuthorizationService'];

function ImportarRemisionFormCtrl($scope, RemisionFactory, $location, $state, VentasLangFactory, notify, $window, baseurl, $rootScope,
  $filter, TimbradoEmpresaPuntoEmisionVentaFactory, filterFactory, AuthorizationService) {

	var vm = this;

  vm.permisoProductosFueraCatalogo = AuthorizationService.hasPermission("cargar_productos_fuera_catalogo");

	vm.uploadOptions = {
    imageOnly: false
  };

  VentasLangFactory.getTranslations().then(function(translations) {
    vm.translations=translations;
  });


  var jsonPuntoEmision= localStorage.getItem("puntoEmisionFlujo");
  vm.puntoEmisionVentaActual = JSON.parse(jsonPuntoEmision);

  vm.fecha = new Date();

  // Filtro para timbrados
  var staticFilter2 = {};
  var filtersArray = [{
      path: "timbradoEmpresa.fechaDesde",
      equalOrBefore: moment(vm.fecha).format("DD/MM/YYYY")
  },
  {
      path: "timbradoEmpresa.fechaHasta",
      equalOrAfter: moment(vm.fecha).format("DD/MM/YYYY")
  }
  ];

  filtersArray.push({
      path: "tipoComprobante.codigo",
      equals: 'remision'
  })

  filtersArray.push({
      path: "timbradoEmpresa.activo",
      equals: true
  })

  filtersArray.push({
      path: "timbradoEmpresa.claseTimbrado.codigo",
      notEquals: "timbrado_manual"
  })


  filtersArray.push({
      path: "puntoEmisionVenta.id",
      equals: vm.puntoEmisionVentaActual.id
  })

  var extraConditions = [];
  if ($rootScope.AuthParams.locacionSeleccionada) {
      extraConditions.push({
          path: "timbradoEmpresa.sucursal.id",
          equals: $rootScope.AuthParams.locacionSeleccionada.sucursal.id
      });
  }
  extraConditions.push({
    path: "timbradoEmpresa.sucursal.id",
    isNull: true
  });

  var finalAndFilters = _.map(extraConditions, function (cond) {
      var arr = [cond]
      arr = arr.concat(filtersArray)
      return filterFactory.and(arr).value();
  })

  staticFilter2.search = filterFactory
      .or(finalAndFilters)
      .value();

  // Recuperamos timbrados
  TimbradoEmpresaPuntoEmisionVentaFactory.all(staticFilter2, "RemisionForm").$promise.then(function (response) {
    vm.timbradosPuntoEmision = response;

    _.forEach(vm.timbradosPuntoEmision, function (obj) {
        obj.timbradoEmpresa.fechaDesde = moment(obj.timbradoEmpresa.fechaDesde).format("DD/MM/YYYY");
        obj.timbradoEmpresa.fechaHasta = moment(obj.timbradoEmpresa.fechaHasta).format("DD/MM/YYYY");
    });

    if (response.length == 0) {
        var msg = $filter('translate')('NO_TIMBRADO_FOUND');
        notify({ message: msg, classes: 'alert-danger', position: 'right' });
    }
});


  $scope.cancel = function() {
    $state.go("app.ventas.proceso.remision.list", {
      codigoVenta: localStorage.getItem("flujoVenta")
      //modeAccess: modo
    });
  }

	$scope.submit = function() {

      var flow = vm.uploadOptions.flow;
      var flowFile = flow.files[0];
      if (flowFile)

        RemisionFactory.carga(flowFile.uniqueIdentifier + "-" + flowFile.name, 
          $rootScope.AuthParams.locacionSeleccionada.sucursal.id,
          localStorage.getItem("flujoVenta"),
          $rootScope.AuthParams.locacionSeleccionada.id,
          vm.timbrado.id,                             // Timbrado
          vm.permisoProductosFueraCatalogo,
          localStorage.getItem("clienteFlujo")
        ).then(function (data) {

          if (data.data==null || data.data.filename === null || data.data.filename === undefined) {
              notify({
                  message: 'Importacion realizada con exito!',
                  classes: 'alert-success',
                  position: 'right'
              });
          }else {
              notify({
                  message: 'Error en la importacion de la remision',
                  classes: 'alert-danger',
                  position: 'right'
              });
              $window.open(baseurl.getPublicBaseUrl() + data.data.filename, "_blank");
          }
          $state.go("app.ventas.proceso.remision.list", {
            codigoVenta: localStorage.getItem("flujoVenta")
            //modeAccess: modo
          });

        }, function (error) {
          notify({
              message: error.data[0].message,
              classes: 'alert-danger',
              position: 'right'
          });
        });
  };
};
